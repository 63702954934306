








import { mapGetters, mapMutations } from 'vuex'

export default {
    name: 'Toast',
    computed: {
        ...mapGetters('toast', ['toastText', 'isToastActive'])
    },
    methods: {
    }
}
