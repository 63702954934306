import logger from '../../utils/client-logger'
import { NengiClient } from '../../engine/client/nengi-client'
import { EndReason, PlayerEndRunCommand } from '../../player/shared/player-end-run-command'
import PlayerReputationCommand from '../../player/shared/player-reputation-command'

export const endRunModule = () => {
	logger.debug('Initializing End Run store module...')
	return {
		namespaced: true,
		state: {
			nearbyWormIsAvailable: false,
		},
		getters: {
			nearbyWormIsAvailable(state: any) {
				return state.nearbyWormIsAvailable
			},
		},
		actions: {
			endRun({commit, dispatch, rootState}){
				const panel = rootState.inGame.nextPanel === '' ? 'endRun' : rootState.inGame.nextPanel

				switch (panel) {
					case 'endRun':
						dispatch('genericYesNo/showMenu', {
							title: 'Attention!',
									description: ['Before you go, have you forgotten anything? Any unsent loot will be lost.'],
									noButtonText: 'Stay',
									yesButtonText: 'End Run',
									yesCallback: () => {
										commit('inGame/setPanelSwitchable', true, { root: true })
										NengiClient.getInstance().sendCommand(new PlayerEndRunCommand(EndReason.END_RUN))
										NengiClient.getInstance().sendCommand(new PlayerReputationCommand(rootState.user.id))		
										dispatch('ftueManager/completeFlagsFrom', 'endRunUsed', { root: true })
										commit('inGame/setActivePanel', 'runEnd', { root: true })
										commit('inGame/triggerEndRun',{}, { root: true })
										dispatch('inGame/setPanelSwitchable', false, { root: true })
									},
									noCallback: () => {},
						}, { root: true })
						break;
					case 'endOfWorld':
						dispatch('genericYesNo/showMenu', {
							title: `You've reached the end of the island. Do you want to end your run`,
									description: [`You can end your run now and advance to the next world tier. Would you like to toss all remaining loot and end your run?`],
									noButtonText: 'Stay',
									yesButtonText: 'End Run',
									yesCallback: () => {
										commit('inGame/setPanelSwitchable', true, { root: true })
										NengiClient.getInstance().sendCommand(new PlayerEndRunCommand(EndReason.WORLD_END))
										NengiClient.getInstance().sendCommand(new PlayerReputationCommand(rootState.user.id))		
										dispatch('ftueManager/completeFlagsFrom', 'endRunUsed', { root: true })
										commit('inGame/setActivePanel', 'worldTierComplete', { root: true })
										commit('inGame/triggerEndRun',{}, { root: true })
										dispatch('inGame/setPanelSwitchable', false, { root: true })
									},
									noCallback: () => {},
						}, { root: true })
						break;
				
				}
				
			}
		},
		mutations: {
			nearbyWormIsAvailable(state: any, available: boolean) {
				state.nearbyWormIsAvailable = available
			},
		},
	}
}
