import nengi from 'nengi'

export default class EnemyDiedMessage {
	static protocol = {
		entityId: { type: nengi.Number },
		x: { type: nengi.Float32 },
		y: { type: nengi.Float32 },
		playDeathPfx: { type: nengi.Boolean },
	}

	entityId: number
	x: number
	y: number
	playDeathPfx: boolean

	constructor(entityId: number, x: number, y: number, playDeathPfx: boolean) {
		this.entityId = entityId
		this.x = x
		this.y = y
		this.playDeathPfx = playDeathPfx
	}
}
