













import { MAXIMUM_INVENTORY_WEIGHT } from '../../engine/shared/game-data/inventory'
import { mapGetters } from 'vuex'
export default {
	name: 'InventoryBag',
	data() {
		return {}
	},
	computed: {
		...mapGetters('hud', ['inventoryWeight']),
		inventorySpace() {
			return Math.floor((this.inventoryWeight / MAXIMUM_INVENTORY_WEIGHT) * 100)
		},
		maxInventorySpace() {
			return MAXIMUM_INVENTORY_WEIGHT
		},
	},
	watch: {},
}
