import ItemRarity from './item-rarity'

// tslint:disable-next-line
export interface PrizeData {}

export class PetPrizeData implements PrizeData {
	petBaseType: PetBaseType
	petSubType: PetSubType

	constructor(baseType: PetBaseType, subType: PetSubType) {
		this.petBaseType = baseType
		this.petSubType = subType
	}
}

export enum PrizeSubType {
	PetEgg = 3001,
	WeaponSkin = 3002,
	Emote = 3004,
	PlayerSkin = 3003,
	FactionCosmetic = 3004, // For 'Give Me All Faction Cosmetics' in item roller. Includes Playerskins Pets and Weapons 
}

export enum PetTypeName {
	Bear = 'bear',
	Bird = 'bird',
	Cat = 'cat',
	Dog = 'dog',
	Dragon = 'dragon',
	Hamster = 'hamster',
	Plant = 'plant',
	Raccoon = 'raccoon',
	Whale = 'whale',
	Betta = 'betta',
	Griffin = 'griffin',
	Bat = 'bat',
	Horse = 'horse',
	Pig = 'pig',
	BabyCrab = 'pet-baby-crab',
	FairyHand = 'pet-fairy-hand',
	BabyGorgon = 'pet-baby-gorgon',
	RotSon = 'pet-rot-son',
	BabyDragon = 'pet-baby-dragon',
}

export enum PetBaseType {
	Bear = 1,
	Bird = 2,
	Cat = 3,
	Dog = 4,
	Dragon = 5,
	Hamster = 6,
	Plant = 7,
	Raccoon = 8,
	Whale = 9,
	Betta = 10,
	Griffin = 11,
	Bat = 12,
	Horse = 13,
	Pig = 14,
	BabyCrab = 15,
	FairyHand = 16,
	BabyGorgon = 17,
	RotSon = 18,
	BabyDragon = 19,
}

export function petBaseTypeToName(baseType: PetBaseType): PetTypeName {
	switch (baseType) {
		case PetBaseType.Bear:
			return PetTypeName.Bear
		case PetBaseType.Bird:
			return PetTypeName.Bird
		case PetBaseType.Cat:
			return PetTypeName.Cat
		case PetBaseType.Dog:
			return PetTypeName.Dog
		case PetBaseType.Dragon:
			return PetTypeName.Dragon
		case PetBaseType.Hamster:
			return PetTypeName.Hamster
		case PetBaseType.Plant:
			return PetTypeName.Plant
		case PetBaseType.Raccoon:
			return PetTypeName.Raccoon
		case PetBaseType.Whale:
			return PetTypeName.Whale
		case PetBaseType.Betta:
			return PetTypeName.Betta
		case PetBaseType.Griffin:
			return PetTypeName.Griffin
		case PetBaseType.Bat:
			return PetTypeName.Bat
		case PetBaseType.Horse:
			return PetTypeName.Horse
		case PetBaseType.Pig:
			return PetTypeName.Pig
		case PetBaseType.BabyCrab:
			return PetTypeName.BabyCrab
		case PetBaseType.FairyHand:
			return PetTypeName.FairyHand
		case PetBaseType.BabyGorgon:
			return PetTypeName.BabyGorgon
		case PetBaseType.RotSon:
			return PetTypeName.RotSon
		case PetBaseType.BabyDragon:
			return PetTypeName.BabyDragon
	}
}

// Make subtype enums not have overlap!
// the starting number % 100 should be the number of their base type!
// e.g. BirdType % 100 = 2
// PetBaseType.Bird = 2

//Also don't change existing numbers! (is possible with a migration though)
export enum BirdType {
	Robin = 200,
	Raven = 201,
	Chicken = 202,
	Cardinal = 203,
	Flamingo = 204,
	Budgie = 205,
	Scion = 206,
}

export enum BearType {
	Black = 100,
	Brown = 101,
	Koala = 102,
	Panda = 103,
	Polar = 104,
	Rainbow = 105,
	Teddy = 106,
}

export enum CatType {
	Calico = 300,
	Grey = 301,
	Rainbow = 302,
	Redd = 303,
	Siamese = 304,
	SpottedTabby = 305,
	TortiePoint = 306,
	Tuxedo = 307,
	Void = 308,
	White = 309,
	Aurum = 310,
}

export enum DogType {
	Bernese = 400,
	BorderCollie = 401,
	BostonTerrier = 402,
	Corgi = 403,
	Dalmatian = 404,
	GermanShepherd = 405,
	GoldenRetriever = 406,
	Greyhound = 407,
	Husky = 408,
	Pug = 409,
	Rainbow = 410,
	Sheltie = 411,
	ShibaInu = 412,
	Westie = 413,
	Yorkie = 414,
	Rottweiler = 415,
}

export enum DragonType {
	DragonBlack = 500,
	DragonBlue = 501,
	// Removed some griffins 502 - 506
	DragonPastel = 507,
	DragonRed = 508,
	DragonTeal = 509,
	DragonWhite = 510,
}

export enum HamsterType {
	Agouti = 600,
	Black = 601,
	Mint = 602,
	Mottled = 603,
	Rainbow = 604,
	Roborovski = 605,
	White = 606,
	Iron = 607,
}

export enum PlantType {
	Aloe = 700,
	Cactus = 701,
	Dead = 702,
	Leafy = 703,
	LeafyRed = 704,
	Palm = 705,
	Rainbow = 706,
}

export enum RaccoonType {
	Grey = 800,
	Pink = 801,
	Rainbow = 802,
	Red = 803,
	Teal = 804,
}

export enum WhaleType {
	Blue = 900,
	Dolphin = 901,
	Killer = 902,
	Narwhal = 903,
	Unicorn = 904,
}

export enum BettaType {
	Blue = 1000,
	Red = 1001,
}

export enum GriffinType {
	GriffinEagle = 1100,
	GriffinMidnight = 1101,
	GriffinPhoenix = 1102,
	GriffinRainbow = 1103,
	GriffinSnow = 1104,
}

export enum BatType {
	BatGildedAurum = 1200,
}

export enum HorseType {
	HorseGildedPegasusScion = 1300,
}

export enum PigType {
	PigGuildedBoarIron = 1400,
}

export enum BabyCrabType {
	BabyCrab = 1500,
}

export enum FairyHandType {
	FairyHand = 1600,
}

export enum BabyGorgonType {
	BabyGorgon = 1700,
}

export enum RotSonType {
	RotSon = 1800,
}

export enum BabyDragonType {
	BabyDragon = 1900,
}

export type PetSubType = BirdType | BearType | CatType | BettaType | DragonType | DogType | HamsterType | PlantType | RaccoonType | WhaleType | GriffinType | BatType | HorseType | PigType | BabyCrabType | FairyHandType | BabyGorgonType | RotSonType | BabyDragonType

export const BirdSubTypeValues = getEnumKeys(BirdType)
export const BearSubTypeValues = getEnumKeys(BearType)
export const CatSubTypeValues = getEnumKeys(CatType)
export const BettaSubTypeValues = getEnumKeys(BettaType)
export const DragonSubTypeValues = getEnumKeys(DragonType)
export const DogSubTypeValues = getEnumKeys(DogType)
export const HamsterSubTypeValues = getEnumKeys(HamsterType)
export const PlantSubTypeValues = getEnumKeys(PlantType)
export const RaccoonSubTypeValues = getEnumKeys(RaccoonType)
export const WhaleSubTypeValues = getEnumKeys(WhaleType)
export const GriffinSubTypeValues = getEnumKeys(GriffinType)
export const BatSubTypeValues = getEnumKeys(BatType)
export const HorseSubTypeValues = getEnumKeys(HorseType)
export const PigSubTypeValues = getEnumKeys(PigType)
export const BabyCrabSubTypeValues = getEnumKeys(BabyCrabType)
export const FairyHandSubTypeValues = getEnumKeys(FairyHandType)
export const BabyGorgonSubTypeValues = getEnumKeys(BabyGorgonType)
export const RotSonSubTypeValues = getEnumKeys(RotSonType)
export const BabyDragonSubTypeValues = getEnumKeys(BabyDragonType)

export const PetSubTypeValues = BirdSubTypeValues.concat(BearSubTypeValues)
	.concat(CatSubTypeValues)
	.concat(BettaSubTypeValues)
	.concat(DragonSubTypeValues)
	.concat(DogSubTypeValues)
	.concat(HamsterSubTypeValues)
	.concat(PlantSubTypeValues)
	.concat(RaccoonSubTypeValues)
	.concat(WhaleSubTypeValues)
	.concat(GriffinSubTypeValues)
	.concat(BatSubTypeValues)
	.concat(HorseSubTypeValues)
	.concat(PigSubTypeValues)
	.concat(BabyCrabSubTypeValues)
	.concat(FairyHandSubTypeValues)
	.concat(BabyGorgonSubTypeValues)
	.concat(RotSonSubTypeValues)
	.concat(BabyDragonSubTypeValues)

// ======== PRETTY NAMES ========
const PrizeSubTypePrettyName = new Map<PrizeSubType, string>()
PrizeSubTypePrettyName.set(PrizeSubType.PetEgg, 'Pet Egg')
PrizeSubTypePrettyName.set(PrizeSubType.WeaponSkin, 'Weapon Skin')
PrizeSubTypePrettyName.set(PrizeSubType.PlayerSkin, 'Player Skin')

const PetSubTypePrettyNames = new Map<PetSubType, string>()
// Bird
PetSubTypePrettyNames.set(BirdType.Robin, 'Robin')
PetSubTypePrettyNames.set(BirdType.Raven, 'Raven')
PetSubTypePrettyNames.set(BirdType.Chicken, 'Chicken')
PetSubTypePrettyNames.set(BirdType.Cardinal, 'Cardinal')
PetSubTypePrettyNames.set(BirdType.Flamingo, 'Flamingo')
PetSubTypePrettyNames.set(BirdType.Budgie, 'Budgie')
PetSubTypePrettyNames.set(BirdType.Scion, 'Scion Bird')
// Bear
PetSubTypePrettyNames.set(BearType.Black, 'Black Bear')
PetSubTypePrettyNames.set(BearType.Brown, 'Brown Bear')
PetSubTypePrettyNames.set(BearType.Koala, 'Koala')
PetSubTypePrettyNames.set(BearType.Panda, 'Panda')
PetSubTypePrettyNames.set(BearType.Polar, 'Polar Bear')
PetSubTypePrettyNames.set(BearType.Rainbow, 'Rainbow Bear')
PetSubTypePrettyNames.set(BearType.Teddy, 'Teddy Bear')
// Cat
PetSubTypePrettyNames.set(CatType.Calico, 'Calico Cat')
PetSubTypePrettyNames.set(CatType.Grey, 'Grey Cat')
PetSubTypePrettyNames.set(CatType.Rainbow, 'Rainbow Cat')
PetSubTypePrettyNames.set(CatType.Redd, 'Orange Cat')
PetSubTypePrettyNames.set(CatType.Siamese, 'Siamese Cat')
PetSubTypePrettyNames.set(CatType.SpottedTabby, 'Spotted Tabby')
PetSubTypePrettyNames.set(CatType.TortiePoint, 'Tortie Point Cat')
PetSubTypePrettyNames.set(CatType.Tuxedo, 'Tuxedo Cat')
PetSubTypePrettyNames.set(CatType.Void, 'Void Cat')
PetSubTypePrettyNames.set(CatType.White, 'White Cat')
PetSubTypePrettyNames.set(CatType.Aurum, 'Aurum Cat')
// Betta
PetSubTypePrettyNames.set(BettaType.Blue, 'Blue Betta')
PetSubTypePrettyNames.set(BettaType.Red, 'Red Betta')
// Dragon
PetSubTypePrettyNames.set(DragonType.DragonBlack, 'Black Dragon')
PetSubTypePrettyNames.set(DragonType.DragonBlue, 'Blue Dragon')
PetSubTypePrettyNames.set(DragonType.DragonPastel, 'Pastel Dragon')
PetSubTypePrettyNames.set(DragonType.DragonRed, 'Red Dragon')
PetSubTypePrettyNames.set(DragonType.DragonTeal, 'Teal Dragon')
PetSubTypePrettyNames.set(DragonType.DragonWhite, 'White Dragon')
// Dog
PetSubTypePrettyNames.set(DogType.Bernese, 'Bernese')
PetSubTypePrettyNames.set(DogType.BorderCollie, 'Border Collie')
PetSubTypePrettyNames.set(DogType.BostonTerrier, 'Boston Terrier')
PetSubTypePrettyNames.set(DogType.Corgi, 'Corgi')
PetSubTypePrettyNames.set(DogType.Dalmatian, 'Dalmation')
PetSubTypePrettyNames.set(DogType.GermanShepherd, 'German Shepherd')
PetSubTypePrettyNames.set(DogType.GoldenRetriever, 'Golden Retriever')
PetSubTypePrettyNames.set(DogType.Greyhound, 'Greyhound')
PetSubTypePrettyNames.set(DogType.Husky, 'Husky')
PetSubTypePrettyNames.set(DogType.Pug, 'Pug')
PetSubTypePrettyNames.set(DogType.Rainbow, 'Rainbow Dog')
PetSubTypePrettyNames.set(DogType.Rottweiler, 'Rottweiler')
PetSubTypePrettyNames.set(DogType.Sheltie, 'Sheltie')
PetSubTypePrettyNames.set(DogType.ShibaInu, 'Shiba Inu')
PetSubTypePrettyNames.set(DogType.Westie, 'Westie')
PetSubTypePrettyNames.set(DogType.Yorkie, 'Yorkshire Terrier')
// Griffin
PetSubTypePrettyNames.set(GriffinType.GriffinEagle, 'Plain Griffin')
PetSubTypePrettyNames.set(GriffinType.GriffinMidnight, 'Midnight Griffin')
PetSubTypePrettyNames.set(GriffinType.GriffinPhoenix, 'Phoenix-Griffin')
PetSubTypePrettyNames.set(GriffinType.GriffinRainbow, 'Rainbow Griffin')
PetSubTypePrettyNames.set(GriffinType.GriffinSnow, 'Snow Griffin')
// Hamster
PetSubTypePrettyNames.set(HamsterType.Agouti, 'Agouti Hamster')
PetSubTypePrettyNames.set(HamsterType.Black, 'Black Hamster')
PetSubTypePrettyNames.set(HamsterType.Mint, 'Mint Hamster')
PetSubTypePrettyNames.set(HamsterType.Mottled, 'Mottled Hamster')
PetSubTypePrettyNames.set(HamsterType.Rainbow, 'Rainbow Hamster')
PetSubTypePrettyNames.set(HamsterType.Roborovski, 'Roborovski Hamster')
PetSubTypePrettyNames.set(HamsterType.White, 'White Hamster')
PetSubTypePrettyNames.set(HamsterType.Iron, 'Iron Hamster')
// Plant
PetSubTypePrettyNames.set(PlantType.Aloe, 'Aloe Plant')
PetSubTypePrettyNames.set(PlantType.Cactus, 'Cactus Plant')
PetSubTypePrettyNames.set(PlantType.Dead, 'Dead Plant')
PetSubTypePrettyNames.set(PlantType.Leafy, 'Leafy Plant')
PetSubTypePrettyNames.set(PlantType.LeafyRed, 'Leafy Red Plant')
PetSubTypePrettyNames.set(PlantType.Palm, 'Palm Plant')
PetSubTypePrettyNames.set(PlantType.Rainbow, 'Rainbow Plant')
// Raccoon
PetSubTypePrettyNames.set(RaccoonType.Grey, 'Raccoon')
PetSubTypePrettyNames.set(RaccoonType.Pink, 'Pink Raccoon')
PetSubTypePrettyNames.set(RaccoonType.Rainbow, 'Rainbow Raccoon')
PetSubTypePrettyNames.set(RaccoonType.Red, 'Red Raccoon')
PetSubTypePrettyNames.set(RaccoonType.Teal, 'Teal Raccoon')
// Whale
PetSubTypePrettyNames.set(WhaleType.Blue, 'Blue Whale')
PetSubTypePrettyNames.set(WhaleType.Dolphin, 'Dolphin')
PetSubTypePrettyNames.set(WhaleType.Killer, 'Orca')
PetSubTypePrettyNames.set(WhaleType.Narwhal, 'Narwhal')
PetSubTypePrettyNames.set(WhaleType.Unicorn, 'Unicorn Whale')
// Bat
PetSubTypePrettyNames.set(BatType.BatGildedAurum, 'Gilded Aurum Bat')
// Horse
PetSubTypePrettyNames.set(HorseType.HorseGildedPegasusScion, 'Gilded Scion Pegasus')
// Pig
PetSubTypePrettyNames.set(PigType.PigGuildedBoarIron, 'Gilded Iron Boar')
// Boss
PetSubTypePrettyNames.set(BabyCrabType.BabyCrab, 'Baby Crab')
PetSubTypePrettyNames.set(FairyHandType.FairyHand, 'Fairy Hand')
PetSubTypePrettyNames.set(BabyGorgonType.BabyGorgon, 'Baby Gorgon')
PetSubTypePrettyNames.set(RotSonType.RotSon, 'Rot Son')
PetSubTypePrettyNames.set(BabyDragonType.BabyDragon, 'Baby Prism Dragon')

// ======== RARITIES ========
const PetRarities = new Map<PetSubType, ItemRarity>()
// Bird
PetRarities.set(BirdType.Robin, ItemRarity.UNCOMMON)
PetRarities.set(BirdType.Raven, ItemRarity.UNCOMMON)
PetRarities.set(BirdType.Chicken, ItemRarity.UNCOMMON)
PetRarities.set(BirdType.Cardinal, ItemRarity.RARE)
PetRarities.set(BirdType.Flamingo, ItemRarity.RARE)
PetRarities.set(BirdType.Budgie, ItemRarity.EPIC)
PetRarities.set(BirdType.Scion, ItemRarity.EPIC)
// Bear
PetRarities.set(BearType.Black, ItemRarity.UNCOMMON)
PetRarities.set(BearType.Brown, ItemRarity.UNCOMMON)
PetRarities.set(BearType.Polar, ItemRarity.UNCOMMON)
PetRarities.set(BearType.Koala, ItemRarity.RARE)
PetRarities.set(BearType.Panda, ItemRarity.RARE)
PetRarities.set(BearType.Teddy, ItemRarity.RARE)
PetRarities.set(BearType.Rainbow, ItemRarity.EPIC)
// Cats
PetRarities.set(CatType.Grey, ItemRarity.UNCOMMON)
PetRarities.set(CatType.Redd, ItemRarity.UNCOMMON)
PetRarities.set(CatType.SpottedTabby, ItemRarity.UNCOMMON)
PetRarities.set(CatType.Tuxedo, ItemRarity.UNCOMMON)
PetRarities.set(CatType.Void, ItemRarity.UNCOMMON)
PetRarities.set(CatType.White, ItemRarity.UNCOMMON)
PetRarities.set(CatType.Calico, ItemRarity.RARE)
PetRarities.set(CatType.Siamese, ItemRarity.RARE)
PetRarities.set(CatType.TortiePoint, ItemRarity.RARE)
PetRarities.set(CatType.Rainbow, ItemRarity.EPIC)
PetRarities.set(CatType.Aurum, ItemRarity.EPIC)
// Betta
PetRarities.set(BettaType.Blue, ItemRarity.EPIC)
PetRarities.set(BettaType.Red, ItemRarity.EPIC)
// Dragon
PetRarities.set(DragonType.DragonBlack, ItemRarity.RARE)
PetRarities.set(DragonType.DragonBlue, ItemRarity.RARE)
PetRarities.set(DragonType.DragonPastel, ItemRarity.EPIC)
PetRarities.set(DragonType.DragonWhite, ItemRarity.UNCOMMON)
PetRarities.set(DragonType.DragonRed, ItemRarity.UNCOMMON)
PetRarities.set(DragonType.DragonTeal, ItemRarity.UNCOMMON)
// Dog
PetRarities.set(DogType.GoldenRetriever, ItemRarity.UNCOMMON)
PetRarities.set(DogType.Westie, ItemRarity.UNCOMMON)
PetRarities.set(DogType.Pug, ItemRarity.UNCOMMON)
PetRarities.set(DogType.GermanShepherd, ItemRarity.UNCOMMON)
PetRarities.set(DogType.BostonTerrier, ItemRarity.UNCOMMON)
PetRarities.set(DogType.BorderCollie, ItemRarity.UNCOMMON)
PetRarities.set(DogType.Yorkie, ItemRarity.UNCOMMON)
PetRarities.set(DogType.Corgi, ItemRarity.UNCOMMON)
PetRarities.set(DogType.ShibaInu, ItemRarity.UNCOMMON)
PetRarities.set(DogType.Greyhound, ItemRarity.UNCOMMON)
PetRarities.set(DogType.Husky, ItemRarity.RARE)
PetRarities.set(DogType.Rottweiler, ItemRarity.RARE)
PetRarities.set(DogType.Sheltie, ItemRarity.RARE)
PetRarities.set(DogType.Bernese, ItemRarity.RARE)
PetRarities.set(DogType.Dalmatian, ItemRarity.RARE)
PetRarities.set(DogType.Rainbow, ItemRarity.EPIC)
// Griffin
PetRarities.set(GriffinType.GriffinEagle, ItemRarity.UNCOMMON)
PetRarities.set(GriffinType.GriffinSnow, ItemRarity.UNCOMMON)
PetRarities.set(GriffinType.GriffinPhoenix, ItemRarity.RARE)
PetRarities.set(GriffinType.GriffinMidnight, ItemRarity.RARE)
PetRarities.set(GriffinType.GriffinRainbow, ItemRarity.EPIC)
// Hamster
PetRarities.set(HamsterType.White, ItemRarity.UNCOMMON)
PetRarities.set(HamsterType.Black, ItemRarity.UNCOMMON)
PetRarities.set(HamsterType.Mint, ItemRarity.UNCOMMON)
PetRarities.set(HamsterType.Roborovski, ItemRarity.UNCOMMON)
PetRarities.set(HamsterType.Agouti, ItemRarity.RARE)
PetRarities.set(HamsterType.Mottled, ItemRarity.RARE)
PetRarities.set(HamsterType.Rainbow, ItemRarity.EPIC)
PetRarities.set(HamsterType.Iron, ItemRarity.EPIC)
// Plant
PetRarities.set(PlantType.Aloe, ItemRarity.UNCOMMON)
PetRarities.set(PlantType.Dead, ItemRarity.UNCOMMON)
PetRarities.set(PlantType.Leafy, ItemRarity.UNCOMMON)
PetRarities.set(PlantType.Palm, ItemRarity.UNCOMMON)
PetRarities.set(PlantType.Cactus, ItemRarity.RARE)
PetRarities.set(PlantType.LeafyRed, ItemRarity.RARE)
PetRarities.set(PlantType.Rainbow, ItemRarity.EPIC)
// Raccoon
PetRarities.set(RaccoonType.Grey, ItemRarity.UNCOMMON)
PetRarities.set(RaccoonType.Red, ItemRarity.UNCOMMON)
PetRarities.set(RaccoonType.Teal, ItemRarity.UNCOMMON)
PetRarities.set(RaccoonType.Pink, ItemRarity.RARE)
PetRarities.set(RaccoonType.Rainbow, ItemRarity.EPIC)
// Whale
PetRarities.set(WhaleType.Blue, ItemRarity.UNCOMMON)
PetRarities.set(WhaleType.Dolphin, ItemRarity.UNCOMMON)
PetRarities.set(WhaleType.Killer, ItemRarity.UNCOMMON)
PetRarities.set(WhaleType.Narwhal, ItemRarity.RARE)
PetRarities.set(WhaleType.Unicorn, ItemRarity.EPIC)
// Bat
PetRarities.set(BatType.BatGildedAurum, ItemRarity.LEGENDARY)
// Horse
PetRarities.set(HorseType.HorseGildedPegasusScion, ItemRarity.LEGENDARY)
// Pig
PetRarities.set(PigType.PigGuildedBoarIron, ItemRarity.LEGENDARY)
// Boss
PetRarities.set(BabyCrabType.BabyCrab, ItemRarity.ASTRONOMICAL)
PetRarities.set(FairyHandType.FairyHand, ItemRarity.ASTRONOMICAL)
PetRarities.set(BabyGorgonType.BabyGorgon, ItemRarity.ASTRONOMICAL)
PetRarities.set(RotSonType.RotSon, ItemRarity.ASTRONOMICAL)
PetRarities.set(BabyDragonType.BabyDragon, ItemRarity.ASTRONOMICAL)


// ======== SPINE NAMES ========
const PetSpineNames = new Map<PetBaseType, string>()
PetSpineNames.set(PetBaseType.Bear, 'pet-bear')
PetSpineNames.set(PetBaseType.Bird, 'pet-bird')
PetSpineNames.set(PetBaseType.Cat, 'pet-cat')
PetSpineNames.set(PetBaseType.Dog, 'pet-dog')
PetSpineNames.set(PetBaseType.Dragon, 'pet-dragon')
PetSpineNames.set(PetBaseType.Hamster, 'pet-hamster')
PetSpineNames.set(PetBaseType.Plant, 'pet-plant')
PetSpineNames.set(PetBaseType.Raccoon, 'pet-raccoon')
PetSpineNames.set(PetBaseType.Whale, 'pet-whale')
PetSpineNames.set(PetBaseType.Betta, 'pet-betta')
PetSpineNames.set(PetBaseType.Griffin, 'pet-dragon')
PetSpineNames.set(PetBaseType.Bat, 'pet-bat')
PetSpineNames.set(PetBaseType.Horse, 'pet-horse')
PetSpineNames.set(PetBaseType.Pig, 'pet-pig')
PetSpineNames.set(PetBaseType.BabyCrab, 'pet-baby-crab')
PetSpineNames.set(PetBaseType.FairyHand, 'pet-fairy-hand')
PetSpineNames.set(PetBaseType.BabyGorgon, 'pet-baby-gorgon')
PetSpineNames.set(PetBaseType.RotSon, 'pet-rot-son')
PetSpineNames.set(PetBaseType.BabyDragon, 'pet-baby-dragon')

const PetSkinSpineNames = new Map<PetSubType, string>()
// Bird
PetSkinSpineNames.set(BirdType.Robin, 'robin')
PetSkinSpineNames.set(BirdType.Raven, 'raven')
PetSkinSpineNames.set(BirdType.Chicken, 'chicken')
PetSkinSpineNames.set(BirdType.Cardinal, 'cardinal')
PetSkinSpineNames.set(BirdType.Flamingo, 'flamingo')
PetSkinSpineNames.set(BirdType.Budgie, 'colorful')
PetSkinSpineNames.set(BirdType.Scion, 'scion')
// Bear
PetSkinSpineNames.set(BearType.Black, 'black')
PetSkinSpineNames.set(BearType.Brown, 'brown')
PetSkinSpineNames.set(BearType.Koala, 'koala')
PetSkinSpineNames.set(BearType.Panda, 'panda')
PetSkinSpineNames.set(BearType.Polar, 'polar')
PetSkinSpineNames.set(BearType.Rainbow, 'rainbow')
PetSkinSpineNames.set(BearType.Teddy, 'teddy')
// Cats
PetSkinSpineNames.set(CatType.Calico, 'calico')
PetSkinSpineNames.set(CatType.Grey, 'grey')
PetSkinSpineNames.set(CatType.Rainbow, 'rainbow')
PetSkinSpineNames.set(CatType.Redd, 'redd')
PetSkinSpineNames.set(CatType.Siamese, 'siamese')
PetSkinSpineNames.set(CatType.SpottedTabby, 'spotted-tabby')
PetSkinSpineNames.set(CatType.TortiePoint, 'tortie-point')
PetSkinSpineNames.set(CatType.Tuxedo, 'tuxedo')
PetSkinSpineNames.set(CatType.Void, 'void')
PetSkinSpineNames.set(CatType.White, 'white')
PetSkinSpineNames.set(CatType.Aurum, 'aurum')
// Betta
PetSkinSpineNames.set(BettaType.Blue, 'blue')
PetSkinSpineNames.set(BettaType.Red, 'red')
// Dragon
PetSkinSpineNames.set(DragonType.DragonBlack, 'black')
PetSkinSpineNames.set(DragonType.DragonBlue, 'blue')
PetSkinSpineNames.set(DragonType.DragonPastel, 'pastel')
PetSkinSpineNames.set(DragonType.DragonRed, 'red')
PetSkinSpineNames.set(DragonType.DragonTeal, 'teal')
PetSkinSpineNames.set(DragonType.DragonWhite, 'white')
// Dog
PetSkinSpineNames.set(DogType.Bernese, 'bernese')
PetSkinSpineNames.set(DogType.BorderCollie, 'border-collie')
PetSkinSpineNames.set(DogType.BostonTerrier, 'boston-terrier')
PetSkinSpineNames.set(DogType.Corgi, 'corgi')
PetSkinSpineNames.set(DogType.Dalmatian, 'dalmatian')
PetSkinSpineNames.set(DogType.GermanShepherd, 'german-shepherd')
PetSkinSpineNames.set(DogType.GoldenRetriever, 'golden-retriever')
PetSkinSpineNames.set(DogType.Greyhound, 'greyhound')
PetSkinSpineNames.set(DogType.Husky, 'husky')
PetSkinSpineNames.set(DogType.Pug, 'pug')
PetSkinSpineNames.set(DogType.Rainbow, 'rainbow')
PetSkinSpineNames.set(DogType.Rottweiler, 'rottweiler')
PetSkinSpineNames.set(DogType.Sheltie, 'sheltie')
PetSkinSpineNames.set(DogType.ShibaInu, 'shiba-inu')
PetSkinSpineNames.set(DogType.Westie, 'westie')
PetSkinSpineNames.set(DogType.Yorkie, 'yorkie')
// Griffin
PetSkinSpineNames.set(GriffinType.GriffinEagle, 'griffin-eagle')
PetSkinSpineNames.set(GriffinType.GriffinMidnight, 'griffin-midnight')
PetSkinSpineNames.set(GriffinType.GriffinPhoenix, 'griffin-phoenix')
PetSkinSpineNames.set(GriffinType.GriffinRainbow, 'griffin-rainbow')
PetSkinSpineNames.set(GriffinType.GriffinSnow, 'griffin-snow')
// Hamster
PetSkinSpineNames.set(HamsterType.Agouti, 'agouti')
PetSkinSpineNames.set(HamsterType.Black, 'black')
PetSkinSpineNames.set(HamsterType.Mint, 'mint')
PetSkinSpineNames.set(HamsterType.Mottled, 'mottled')
PetSkinSpineNames.set(HamsterType.Rainbow, 'rainbow')
PetSkinSpineNames.set(HamsterType.Roborovski, 'roborovski')
PetSkinSpineNames.set(HamsterType.White, 'white')
PetSkinSpineNames.set(HamsterType.Iron, 'iron')
// Plant
PetSkinSpineNames.set(PlantType.Aloe, 'aloe')
PetSkinSpineNames.set(PlantType.Cactus, 'cactus')
PetSkinSpineNames.set(PlantType.Dead, 'dead')
PetSkinSpineNames.set(PlantType.Leafy, 'leafy')
PetSkinSpineNames.set(PlantType.LeafyRed, 'leafy red')
PetSkinSpineNames.set(PlantType.Palm, 'palm')
PetSkinSpineNames.set(PlantType.Rainbow, 'rainbow')
// Raccoon
PetSkinSpineNames.set(RaccoonType.Grey, 'grey')
PetSkinSpineNames.set(RaccoonType.Pink, 'pink')
PetSkinSpineNames.set(RaccoonType.Rainbow, 'rainbow')
PetSkinSpineNames.set(RaccoonType.Red, 'red')
PetSkinSpineNames.set(RaccoonType.Teal, 'teal')
// Whale
PetSkinSpineNames.set(WhaleType.Blue, 'blue')
PetSkinSpineNames.set(WhaleType.Dolphin, 'dolphin')
PetSkinSpineNames.set(WhaleType.Killer, 'killer')
PetSkinSpineNames.set(WhaleType.Narwhal, 'narwhal')
PetSkinSpineNames.set(WhaleType.Unicorn, 'unicorn')
// Bat
PetSkinSpineNames.set(BatType.BatGildedAurum, 'gilded-bat-aurum')
// Horse
PetSkinSpineNames.set(HorseType.HorseGildedPegasusScion, 'gilded-pegasus-scion')
// Pig
PetSkinSpineNames.set(PigType.PigGuildedBoarIron, 'gilded-boar-iron')
//Boss
PetSkinSpineNames.set(BabyCrabType.BabyCrab, 'default')
PetSkinSpineNames.set(FairyHandType.FairyHand, 'default')
PetSkinSpineNames.set(BabyGorgonType.BabyGorgon, 'default')
PetSkinSpineNames.set(RotSonType.RotSon, 'default')
PetSkinSpineNames.set(BabyDragonType.BabyDragon, 'default')

export { PrizeSubTypePrettyName, PetRarities, PetSpineNames, PetSkinSpineNames, PetSubTypePrettyNames }

export function petSubTypeToBaseType(petSubType: PetSubType) {
	return Math.floor(petSubType / 100) //If we ever have 100+ pets per category, may god have mercy on our souls
}

function getEnumKeys(enumType) {
	return Object.keys(enumType)
		.map(function(t) {
			return enumType[t]
		})
		.filter((val) => !isNaN(val))
}
