

























































import { mapMutations, mapActions, mapGetters } from 'vuex'
import ArrowButton from '../reusable-components/buttons/arrow-button.vue'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import TextInput from '../reusable-components/inputs/text-input.vue'
import ListErrors from '../reusable-components/inputs/list-errors.vue'
import { some, values } from 'lodash'
import EconomyText from '../main-menu/economy-text.vue'

export default {
	name: 'Login',
	components: {
		MainPanel,
		ArrowButton,
		TextInput,
		ListErrors,
		EconomyText,
	},
	data() {
		return {
			login: {
				email: '',
				password: '',
			},
		}
	},
	computed: {
		...mapGetters('user', ['username', 'getPasswordError', 'getEmailError']),
		...mapGetters('mainMenu', ['isJoiningParty']),

		panelHeight() {
			let height = 680
			if (this.getPasswordError.length > 0) {
				height += 40
			}

			if (this.getEmailError.length > 0) {
				height += 40
			}
			return height
		},
		startAnonymousTet() {
			if (this.isJoiningParty) {
				return 'Join Party As A Guest'
			} else {
				return 'Start Without an Account'
			}
		},
	},

	methods: {
		...mapMutations('mainMenu', ['changeMainMenuScreen']),
		...mapActions('user', ['submitLogin']),
		...mapMutations('user', ['updateEmailError', 'updatePasswordError', 'resetUserErrors']),

		updateEmail(textInputValue) {
			this.login.email = textInputValue
		},
		updatePassword(textInputValue) {
			this.login.password = textInputValue
		},
		loginFormSubmit() {
			this.resetUserErrors()

			let normalizedFormValues = {
				emailAddress: this.login.email.toLowerCase(),
				password: this.login.password,
			}

			if (normalizedFormValues.emailAddress === undefined || normalizedFormValues.emailAddress === '') {
				this.updateEmailError('Email field is required.')
			}
			if (normalizedFormValues.password === undefined || normalizedFormValues.password === '') {
				this.updatePasswordError('Password field is required.')
			}

			let normailizedErrorValues = {
				emailError: this.getEmailError,
				passwordError: this.getPasswordError,
			}

			if (
				some(values(normailizedErrorValues), (v) => {
					return v.length !== 0
				})
			) {
				return
			}

			this.submitLogin(normalizedFormValues)
		},
	},
}
