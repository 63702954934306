



























import { mapActions, mapGetters, mapMutations } from 'vuex'

import PaperDollHealth from '../../reusable-components/paper-doll/paper-doll-health.vue'
import PaperDollShield from '../../reusable-components/paper-doll/paper-doll-shield.vue'
import PaperdollStats from '../../reusable-components/paper-doll/paper-doll-stats.vue'
import PaperDollResistance from '../../reusable-components/paper-doll/paper-doll-resistance.vue'
import PaperdollListItemsContainer from '../../reusable-components/item-containers/paper-doll-list-item-container.vue'
import { UI } from '../../ui'
import { PLAYER_DEFAULT_MOVEMENT_SPEED } from '../../../engine/shared/game-data/player'

export default {
	name: 'PlayerPaperdoll',
	components: {
		PaperDollResistance,
		PaperDollShield,
		PaperDollHealth,
		PaperdollListItemsContainer,
		PaperdollStats,
	},
	data() {
		return {}
	},
	props: {},
	computed: {
		...mapGetters('itemContainers', ['itemsInContainer', 'itemDetails']),
		...mapGetters('paperdoll', ['playerStats']),
		...mapGetters('itemComparison', ['selectedItem']),
		selectedItemDetails() {
			return this.selectedItem
		},
		moveSpeed() {
			const speed = this.playerStats.movementSpeed

			return Math.round((speed / PLAYER_DEFAULT_MOVEMENT_SPEED) * 100).toString()
		},
	},
	methods: {
		...mapActions('inGame', ['closeActivePanel']),
		...mapMutations('itemComparison', ['deselectItem', 'resetSelectedItem', 'selectItem']),
		itemSelected(selectedItemId) {
			if (this.selectedItem && selectedItemId === this.selectedItem.id) {
				this.deselectItem()
				this.resetSelectedItem()

			} else {
				this.selectItem({ itemId: selectedItemId, container: 'paperdoll' })
			}
		},
		isItemSelected(id) {
			if (this.selectedItem) {
				return id === this.selectedItem.id
			} else {
				return false
			}
		},
	},
}
