interface Array<T> {
	remove(o: T): T[]
	pickRandom(mt?): T
	isUniqueOn(identify: (t: T) => any): boolean
	pushUnique(o: T)
	add(o: T | T[])
}

interface Math {
	clamp(num: number, lowerBound?: number, upperBound?: number): number
	getRandomFloat(min: number, max: number): number
	getRandomInt(min: number, max: number): number
	powSlope(value: number, power: number, slope: number): number
	lerp(a: number, b: number, t: number): number
	distanceBetweenPoints(sourceX: number, sourceY: number, destX: number, destY: number): number
}

interface String {
	toProperCase(): string
	toCamelCase(): string
	toSnakeCase(): string
	commafy(): string
	replaceAllSLOW(search: string, replace: string): string
}

interface Number {
	toFixedIfFloat(places: number): string
	toOrdinalNotation(): string
}

if (!('toJSON' in Error.prototype)) {
	Object.defineProperty(Error.prototype, 'toJSON', {
		value() {
			const alt = {}

			Object.getOwnPropertyNames(this).forEach(function(key) {
				alt[key] = this[key]
			}, this)

			return alt
		},
		configurable: true,
		writable: true,
	})
}

Array.prototype.remove = function(thingToRemove) {
	const indexOfItemToRemove = this.indexOf(thingToRemove)
	if (indexOfItemToRemove > -1) {
		return this.splice(indexOfItemToRemove, 1)
	} else {
		return this
	}
}

Array.prototype.pickRandom = function(mt?) {
	const r = mt ? mt.random() : Math.random()
	const idx = Math.floor(r * this.length)
	if (idx >= this.length) {
		throw new Error(`bad index ${idx} >= array length`)
	}
	return this[idx]
}

Array.prototype.isUniqueOn = function(identify: (element: any) => boolean): boolean {
	const tmpArr = []
	for (let i = 0; i < this.length; i++) {
		const obj = this[i]
		const id = identify(obj)
		if (tmpArr.indexOf(id) < 0) {
			tmpArr.push(id)
		} else {
			return false // Duplicate found
		}
	}
	return true // No duplicate found
}

Array.prototype.pushUnique = function(o) {
	if (this.indexOf(o) === -1) {
		this.push(o)
	}
}

//concat makes a new array, so here is this garbage
Array.prototype.add = function(o) {
	if(Array.isArray(o)) {
		this.push(...o)
	} else {
		this.push(o)
	}
}

Math.clamp = function(num: number, lowerBound: number = 0, upperBound: number = 1): number {
	return Math.min(upperBound, Math.max(num, lowerBound))
}

Math.distanceBetweenPoints = (sourceX: number, sourceY: number, destX: number, destY: number): number => {
	return Math.hypot(destX - sourceX, destY - sourceY)
}

Math.getRandomFloat = function(min: number, max: number): number {
	return Math.random() * (max - min) + min
}

Math.getRandomInt = function(min: number, max: number): number {
	min = Math.ceil(min)
	max = Math.floor(max)
	return Math.floor(Math.random() * (max - min + 1)) + min
}

Math.lerp = function(a, b, t): number {
	return a * (1.0 - t) + b * t
}

Math.powSlope = function(value: number, power: number, slope: number): number {
	return Math.pow(value, power) * slope
}

String.prototype.toProperCase = function() {
	return this.replace(/\w\S*/g, function(frag: string) {
		return frag.charAt(0).toUpperCase() + frag.substr(1).toLowerCase()
	})
}

String.prototype.toCamelCase = function() {
	return this.replace(/\s(.)/g, function($1) {
		return $1.toUpperCase()
	})
		.replace(/\s/g, '')
		.replace(/^(.)/, function($1) {
			return $1.toLowerCase()
		})
}

String.prototype.toSnakeCase = function() {
	return this.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
		.map((x) => x.toLowerCase())
		.join('_')
}

String.prototype.commafy = function() {
	return this.replace(/(^|[^\w.])(\d{4,})/g, function($0, $1, $2) {
		return $1 + $2.replace(/\d(?=(?:\d\d\d)+(?!\d))/g, '$&,')
	})
}

String.prototype.replaceAllSLOW = function(search: string, replace: string) {
	return this.split(search).join(replace)
}

Number.prototype.toOrdinalNotation = function(): string {
	return getOrdinal(this)
}

Number.prototype.toFixedIfFloat = function(places) {
	if (this % 1 !== 0) {
		return this.toFixed(places)
	} else {
		return this.toString()
	}
}

function getOrdinal(i) {
	const j = i % 10
	const k = i % 100
	if (j == 1 && k != 11) {
		return i + "st"
	}
	if (j == 2 && k != 12) {
		return i + "nd"
	}
	if (j == 3 && k != 13) {
		return i + "rd"
	}
	return i + "th"
}