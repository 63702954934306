import { Container, Graphics, Text } from 'pixi.js'
import { getClientColliderEntityCollidables, getClientPropCollidables } from '../../collision/client/client-collisions'
import { clientProjectileVsProps } from '../../collision/shared/collision-routines'
import { clientConfig } from '../../engine/client/client-config'
import { debugConfig } from '../../engine/client/debug-config'
import { GameClient } from '../../engine/client/game-client'
import Renderer from '../../engine/client/graphics/renderer'
import { NengiClient } from '../../engine/client/nengi-client'
import { PLAYER_WAVE_TRAVEL_TIME_DEFAULT_SPEED } from '../../engine/shared/game-data/player'
import { SERVER_DELTA_TIME } from '../../engine/shared/time'
import { ObjectPoolTyped, PoolableObject } from '../../third-party/object-pool'
import { shallowCompareChangedValues } from '../../utils/debug'
import { smoothDamp, sub, throwIfNotFinite, VectorXY } from '../../utils/math'
import { gameUnits, timeInSeconds } from '../../utils/primitive-types'
import { angleInRadsFromVector } from '../../utils/vector'
import { ProjectileConfigId } from '../shared/projectile-config-ids'
import { projectileCheckRange } from '../shared/projectile-functions'
import { ModType } from '../shared/projectile-types'
import Projectile, { copyProjectileState, IProjectileMovementState } from '../shared/projectile.shared'
import { projectileRegistry_clientGetConfig } from './projectile-config-registry.client'

// TODO3 Mike: to have particles draw in front of things that are hit, etc...
//  I add this offset. Need a better way of doing this
export const HACK_PARTICLE_ZOFFSET = 20
const CSP_CONFIG = debugConfig.cspConfig

interface CspInfo {
	/** the server is setting these properties for non-csp folk, so this backs up the non-server values so csp can do it's thing */
	stateBackup: IProjectileMovementState
	/** projectile predicted position at a rate of 20Hz to line up with server */
	visualPos: { x: gameUnits, y: gameUnits },
	/** accumulates delta time and then updates when enough is accumulated (to line up with server) */
	timeAccumulator: timeInSeconds
	/**
	 * Extra delta time to add to enemy projectile, which is initially set to the ping time of the client.  
	 * This is slowly added to the projectiles *time* so it smoothly moves from server pos to predicted position.  
	 * **Not used for players.** (set to 0)
	 */
	enemyProjectileCatchupTime: timeInSeconds
	/** this is just used in smoothDamp to smoothly add catchupTime to the predict ahead time */
	catchupTimeVel: number
}

export class ClientProjectile extends Projectile implements PoolableObject, IProjectileMovementState {
	static pool: ObjectPoolTyped<ClientProjectile>
	model: Container
	colliderVisual: Graphics
	debugModel: Container
	colliderRadius: number
	exemptFromModelCheck: boolean
	skipNextUpdate: boolean
	entitiesHit: any[]
	entityHitGroupCount: Map<number, number>
	reachedMaxRange: boolean
	angleToPeakOfArc: number
	/** csp is enabled, and this projectile belongs to the local player */
	isLocalMineCsp: boolean
	clearStatsForBoomerang() {
		// TODOCSP: will need filling out if we do collisions on client 
	}

	get visualPos(): VectorXY {
		return this._cspInfo ? this._cspInfo.visualPos : this
	}

	/** only set if csp is enabled, undefined otherwise */
	_cspInfo: CspInfo

	constructor() {
		super()
	}

	setDefaultValues(defaultValues: any, projectileEntityData?: Projectile) {
		if (projectileEntityData) {
			if (clientConfig.debug) {
				const { colliderRadius } = projectileEntityData
				this.colliderRadius = colliderRadius
				this.debugModel = new Container()
				this.debugModel.name = 'debug-projectile'
				this.drawColliderVisual()
				Renderer.getInstance().debugMiddleground.addChild(this.debugModel)
			}

			this.entityHitGroupCount = new Map<number, number>()

			this.nid = projectileEntityData.nid
			this.projectileId = projectileEntityData.projectileId
			this.x = projectileEntityData.x
			this.y = projectileEntityData.y
			this.speed = projectileEntityData.speed
			this.initialSpeed = this.speed
			this.randomMod = projectileEntityData.randomMod
			this.distanceMod = projectileEntityData.distanceMod
			this.particleEffect = projectileEntityData.particleEffect
			this.bulletTrailParticleEffect = projectileEntityData.bulletTrailParticleEffect
			this.aimAngleInRads = projectileEntityData.aimAngleInRads
			this.owningEntityId = projectileEntityData.owningEntityId
			this.lifespan = projectileEntityData.lifespan
			this.playerOwned = projectileEntityData.playerOwned
			const localPlayer = GameClient.getInstance().state.myEntity
			this.isLocalMineCsp = false
			if (localPlayer) {
				if (localPlayer.nid === this.owningEntityId) {
					this.owningEntity = localPlayer
					if (debugConfig.csp) {
						const cspProjectiles = localPlayer.cspProjectiles
						this.isLocalMineCsp = cspProjectiles?.isLocalMine(this)
					}
				}
			}

			if (projectileEntityData.projectileConfigId !== ProjectileConfigId.NONE) {
				const projectileConfig = projectileRegistry_clientGetConfig(projectileEntityData.projectileConfigId)
				this.mods = projectileConfig.modifiers
			} else {
				this.mods = projectileEntityData.mods || []
			}

			this.reversedTrajectory = false
			this.willReverseTrajectory = false
			for (let i = 0; i < this.mods.length; ++i) {
				if (this.mods[i].modType === ModType.ABS_ACCELERATION && this.parseModValue(this.mods[i].value) < 0) {
					this.willReverseTrajectory = true
				}
			}
			this.reachedMaxRange = false

			if (projectileEntityData.leftRightFlip) {
				this.leftRightFlip = projectileEntityData.leftRightFlip
			} else {
				this.leftRightFlip = false
			}

			if (debugConfig.csp) {
				if (CSP_CONFIG.drawServer || !this.isLocalMineCsp) {
					Renderer.getInstance().registerProjectile(this)
				}
			} else {
				Renderer.getInstance().registerProjectile(this)
			}
			
			this.spreadModifiersSet = false
			this.radiusSpreadModifier = 1
			this.waveSpreadOffset = 0

			this.travelTimeElapsedInSeconds = 0
			this.travelTimeElapsedForWaveFunctions = 0
			this.travelTimeElapsedForWaveFunctionsPrev = 0

			// @ts-ignore
			const ping: timeinMilliseconds = NengiClient.getInstance().getClient().averagePing // TODO3: don't grab this for every projectile
			const pingInSeconds: timeInSeconds = ping * 0.001

			// applyTrajectoryModInitialState is already called on server for enemy projectiles and values are already initialized here
			if (this.playerOwned) {
				for (let i = 0; i < this.mods.length; ++i) {
					this.applyTrajectoryModInitialState(this.mods[i])
				}
			}

			this._cspInfo = undefined
			if (debugConfig.csp) {
				if (!projectileEntityData.playerOwned) {
					console.assert(projectileEntityData.projectileConfigId, 'enemy projectile csp requires projectileConfigId on client')
				}

				// currently we treat other player projectiles the old non-csp way
				if (!this.playerOwned) {
					this.createCspData(pingInSeconds)
				} else if (localPlayer && this.owningEntityId === localPlayer.nid) {
					this.createCspData(pingInSeconds)
				}
			}

			if (this.debugModel) {
				const text = new Text('pe:' + this.particleEffect, {
					fontFamily: 'Arial',
					fontSize: 16,
					fill: 0xffffff,
					align: 'center',
				})
				text.rotation = -0.72
				text.x = -74
				text.y = -5
				this.debugModel.addChild(text)
			}

			this.exemptFromModelCheck = true
		}
	}

	createCspData(pingInSeconds: timeInSeconds) {
		this._cspInfo = {
			stateBackup: createProjectileState(this),
			visualPos: { x: this.x, y: this.y },
			timeAccumulator: SERVER_DELTA_TIME,
			enemyProjectileCatchupTime: !this.playerOwned ? pingInSeconds : 0,
			catchupTimeVel: 0,
		}
	}

	/**
	 * Under csp, player projectiles have 2 copies:
	 * 1) one they create themselves immediately
	 * 2) one created later by the server and is instantiated via nengi entities
	 * We do **not** want to call update via the nengi entity list, which is why we have callerIsCsp
	 */
	update(delta: timeInSeconds, callerIsCsp = false) {
		if (this._cspInfo && (!this.playerOwned || callerIsCsp)) {
			const stateBackup = this._cspInfo.stateBackup

			copyProjectileState(stateBackup, this)

			this.updateCsp(delta)

			copyProjectileState(this, stateBackup)

			if (this.playerOwned) {
				if (clientProjectileVsProps(this, getClientPropCollidables(), getClientColliderEntityCollidables())) {
					this.toBeDeleted = true
				}
			}
		}
	}

	updateCsp(delta: timeInSeconds) {
		const cspInfo = this._cspInfo

		if (cspInfo.enemyProjectileCatchupTime > 0) {
			const curTime = cspInfo.enemyProjectileCatchupTime
			// takes 1s to reach 0.03s left of 0.22s ping with 0.5 smoothTime
			const smoothTime = 0.5;
			[cspInfo.enemyProjectileCatchupTime, cspInfo.catchupTimeVel] = smoothDamp(cspInfo.enemyProjectileCatchupTime, cspInfo.catchupTimeVel, 0, delta, smoothTime);
			delta += curTime - cspInfo.enemyProjectileCatchupTime
		}

		cspInfo.timeAccumulator += delta

		if (cspInfo.timeAccumulator >= SERVER_DELTA_TIME) {
			cspInfo.timeAccumulator -= SERVER_DELTA_TIME

			this.innerUpdate(SERVER_DELTA_TIME)

			cspInfo.visualPos.x = this.x
			cspInfo.visualPos.y = this.y
		}

		this.predictPosition()
	}

	private predictPosition() {
		const cspInfo = this._cspInfo

		let preUpdateBackup = undefined
		if (CSP_CONFIG.logProjectileErrors) {
			preUpdateBackup = { ...this }
		}

		const backup = createProjectileState(this)

		const t = cspInfo.timeAccumulator / SERVER_DELTA_TIME
		this.innerUpdate(SERVER_DELTA_TIME)
		cspInfo.visualPos.x = Math.lerp(backup.x, this.x, t)
		cspInfo.visualPos.y = Math.lerp(backup.y, this.y, t)

		copyProjectileState(backup, this)

		if (CSP_CONFIG.logProjectileErrors) {
			const changes = shallowCompareChangedValues(this, preUpdateBackup)
			if (changes.length > 0) {
				console.error("projectile visual prediction has changed projectile", changes)
			}
		}
	}

	innerUpdate(delta: timeInSeconds) {
		throwIfNotFinite(this.x, 'proj.x')
		this.travelTimeElapsedInSeconds += delta
		if (this.travelTimeElapsedInSeconds > this.lifespan) {
			this.toBeDeleted = true
		}

		const defaultSpeed = PLAYER_WAVE_TRAVEL_TIME_DEFAULT_SPEED

		//this.distanceTravelled += this.speed * delta
		this.travelTimeElapsedForWaveFunctionsPrev = this.travelTimeElapsedForWaveFunctions
		this.travelTimeElapsedForWaveFunctions += (this.speed / defaultSpeed) * delta * (this.reversedTrajectory ? -1 : 1)
		if (this.travelTimeElapsedForWaveFunctions < 0) {
			this.toBeDeleted = true
		}

		if (this.playerOwned) {
			projectileCheckRange(this, this.visualPos, delta)

			if (this.toBeDeleted) {
				Renderer.getInstance().unregisterProjectile(this.nid)
			}
		}

		const a = this.aimAngleInRads
		this.x += Math.cos(a) * this.speed * delta
		this.y += Math.sin(a) * this.speed * delta

		const oldReversedTrajectory = this.reversedTrajectory

		for (let i = 0; i < this.mods.length; ++i) {
			this.applyTrajectoryMod(this.mods[i], delta)
		}

		if (this.reversedTrajectory !== oldReversedTrajectory) {
			// we flipped on this frame
			this.clearStatsForBoomerang()

			this.leftRightFlip = !this.leftRightFlip

			this.aimAngleInRads += Math.PI
			this.travelTimeElapsedForWaveFunctions -= (this.speed / defaultSpeed) * delta
			this.angleToPeakOfArc = angleInRadsFromVector(sub(this.position, this.startPos))
		}
	}

	cleanup() {
		if (this.debugModel) {
			Renderer.getInstance().debugMiddleground.removeChild(this.debugModel)
		}
		Renderer.getInstance().unregisterProjectile(this.nid)

		// clearing Projectile members
		this.nid = undefined
		this.projectileId = undefined
		this.projectileConfigId = undefined
		this.x = undefined
		this.y = undefined
		this._position.x = 0
		this._position.y = 0
		this.toBeDeleted = undefined
		this._speed = undefined
		this.owningEntity = undefined
		this.playerOwned = undefined
		this._aimAngleInRads = undefined
		this.collider = undefined
		this.colliderRadius = undefined
		this.particleEffect = undefined
		this.bulletTrailParticleEffect = undefined
		this.travelTimeElapsedInSeconds = 0
		this.maxRange = undefined
		this.randomMod = undefined
		this.distanceMod = undefined
		this.initialSpeed = undefined
		this.reversedTrajectory = false
		this.leftRightFlip = false
		this.travelTimeElapsedForWaveFunctions = undefined
		this.travelTimeElapsedForWaveFunctionsPrev = undefined
		this.startPos = undefined
		this.baseTrajectory = undefined
		this.lifespan = undefined
		this.willReverseTrajectory = false
		this.baseColliderRadius = undefined
		this.mods = undefined

		// clearing ProjectileClient members
		this._cspInfo = undefined
		this.debugModel = undefined
		this.colliderVisual = undefined
		this.entityHitGroupCount = undefined
		this.owningEntityId = undefined
		this.reachedMaxRange = undefined
		this.exemptFromModelCheck = undefined
		this.skipNextUpdate = undefined
		this.isLocalMineCsp = undefined
	}

	private drawColliderVisual() {
		this.colliderVisual = new Graphics()
		this.colliderVisual.lineStyle(3, 0x00ff00)
		this.colliderVisual.drawCircle(0, 0, this.colliderRadius)
		this.debugModel.addChild(this.colliderVisual)
	}
}

export const clientProjectileHooks = {
	// TODO3: this is low enough precision to cause client/server drift during csp, remove or rethink
	// aimAngleInRadsOpt: (entity: ClientProjectile, newValue: number) => {
	// 	entity.aimAngleInRads = newValue / ANGLE_MULT_OPT
	// },
}

function createProjectileState(from: IProjectileMovementState): IProjectileMovementState {
	const state = {} as IProjectileMovementState
	copyProjectileState(from, state)
	return state
}
