import { Vector } from 'sat'
import { BuffIdentifier } from '../../../../buffs/shared/buff.shared'
import { CameraShakeIntensities } from '../../../../engine/shared/camera.shared'
import { ParticleEffectType } from '../../../../engine/shared/game-data/particle-config'
import { BurstFireModes, ModType, ModValueType } from '../../../../projectiles/shared/projectile-types'
import { Colors } from '../../../../utils/colors'
import { degToRad } from '../../../../utils/math'
import { Ability, AbilityTargets, AbilityTargetSelectionStyle, AbilityType } from '../../action-types'
import { EnemyDefaults } from '../enemy-defaults'
import { deepClone } from '../../abilities.test'

export const crabSkeletonShortShootPlayerEasyAbility: Ability = {
	debugName: 'crabSkeletonShortShootPlayerAbility',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		speed: 700,
		lifespanInSeconds: 5,
		basePhysicalDamage: 40,
		baseIceDamage: 40,
		burstCount: 5,
		burstDelay: 0.1888,
		burstMode: BurstFireModes.TRACKING,
		burstAngle: degToRad(20),
		color: Colors.green,
		colliderRadius: 22,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_BEACHSKELEMAGEBOSS,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
	},
	shakeCamera: CameraShakeIntensities.MILD,
	attackOffset: new Vector(0, -450),
	ignoreAngleOnWeaponOffset: true,
}

export const crabSkeletonShortShootPlayerEnragedEasyAbility: Ability = {
	debugName: 'crabSkeletonShortShootPlayerEnragedAbility',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		speed: 900,
		lifespanInSeconds: 5,
		projectileCount: 3,
		basePhysicalDamage: 35,
		baseIceDamage: 30,
		spreadAngle: degToRad(32),
		burstCount: 5,
		burstDelay: 0.1888,
		burstMode: BurstFireModes.TRACKING,
		burstAngle: degToRad(20),
		color: Colors.red,
		colliderRadius: 22,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_BEACHSKELEMAGEBOSS,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
	},
	shakeCamera: CameraShakeIntensities.MILD,
	attackOffset: new Vector(0, -450),
	ignoreAngleOnWeaponOffset: true,
}

export const crabSkeletonLongShootPlayerEasyAbility: Ability = {
	debugName: 'crabSkeletonLongShootPlayerAbility',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		speed: 700,
		lifespanInSeconds: 5,
		basePhysicalDamage: 30,
		baseIceDamage: 25,
		burstCount: 15,
		burstDelay: 0.15,
		burstMode: BurstFireModes.SWEEPING,
		burstAngle: degToRad(110),
		color: Colors.green,
		colliderRadius: 18,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_BEACHSKELEMAGEBOSS,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
	},
	shakeCamera: CameraShakeIntensities.MILD,
	attackOffset: new Vector(0, -450),
	ignoreAngleOnWeaponOffset: true,
}

export const crabSkeletonLongShootPlayerEnragedEasyAbility: Ability = {
	debugName: 'crabSkeletonLongShootPlayerEnragedAbility',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		speed: 900,
		lifespanInSeconds: 5,
		projectileCount: 3,
		basePhysicalDamage: 25,
		baseIceDamage: 20,
		spreadAngle: degToRad(32),
		burstCount: 10,
		burstDelay: 0.2333,
		burstMode: BurstFireModes.SWEEPING,
		burstAngle: degToRad(48),
		color: Colors.red,
		colliderRadius: 18,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_BEACHSKELEMAGEBOSS,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
	},
	shakeCamera: CameraShakeIntensities.MILD,
	attackOffset: new Vector(0, -450),
	ignoreAngleOnWeaponOffset: true,
}

export const crabSkeletonShootWhileDefendingEasy: Ability = {
	debugName: 'crabSkeletonShootWhileDefending',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		speed: 700,
		lifespanInSeconds: 5,
		projectileCount: 6,
		basePhysicalDamage: 40,
		baseIceDamage: 40,
		spreadAngle: degToRad(300),
		burstCount: 5,
		burstDelay: 0.2333,
		burstMode: BurstFireModes.SWEEPING,
		burstAngle: degToRad(56),
		color: Colors.red,
		colliderRadius: 18,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_BEACHSKELEMAGEBOSS,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
	},
	shakeCamera: CameraShakeIntensities.MILD,
	attackOffset: new Vector(0, -450),
	ignoreAngleOnWeaponOffset: true,
}

export const crabMoveTo5or7oClockAbility: Ability = {
	debugName: 'moveTo5oClock',
	abilityType: AbilityType.MOVE_TOWARDS_LOCATION,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.RANDOM_POSITIONS_IN_SHAPE,
	shapeNames: ['5oclock', '7oclock'],
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
	moveRules: {
		movementSpeed: 480,
		minDistance: 100,
		maxDistance: 1600,
	},
}

export const crabMoveToPositionAbility: Ability = {
	debugName: 'moveToPosition',
	abilityType: AbilityType.MOVE_TOWARDS_LOCATION,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.RANDOM_POSITIONS_IN_SHAPE,
	shapeNames: ['1oclock', '3oclock', '5oclock', '7oclock', '9oclock', '11oclock'],
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
	moveRules: {
		movementSpeed: 480,
		minDistance: 100,
		maxDistance: 1200,
	},
}

export const crabMoveToPositionEnragedAbility: Ability = {
	debugName: 'moveToPosition',
	abilityType: AbilityType.MOVE_TOWARDS_LOCATION,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.RANDOM_POSITIONS_IN_SHAPE,
	shapeNames: ['1oclock', '3oclock', '5oclock', '7oclock', '9oclock', '11oclock'],
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
	moveRules: {
		movementSpeed: 640,
		minDistance: 100,
		maxDistance: 1200,
	},
}

export const crabMoveToPillarAbility: Ability = {
	debugName: 'moveToPillarAbility',
	abilityType: AbilityType.MOVE_TOWARDS_LOCATION,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.RANDOM_POSITIONS_IN_SHAPE,
	shapeNames: ['pillar'],
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
	moveRules: {
		movementSpeed: 400,
		minDistance: 10,
		maxDistance: 3000,
		ignoreIfInside: true,
	},
}

export const crabSlowMoveToPositionAbility: Ability = {
	debugName: 'slowMoveToPosition',
	abilityType: AbilityType.MOVE_TOWARDS_LOCATION,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.RANDOM_POSITIONS_IN_SHAPE,
	shapeNames: ['1oclock', '3oclock', '5oclock', '7oclock', '9oclock', '11oclock'],
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
	moveRules: {
		movementSpeed: 320,
		minDistance: 100,
		maxDistance: 1200,
	},
}

export const crabBlockAbility: Ability = {
	debugName: 'block',
	abilityType: AbilityType.INSTANTLY_APPLY_EFFECT,
	buffToApply: BuffIdentifier.BeachBossBlock,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.MYSELF,
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
}

export const crabClawSlamEasyAbility: Ability = {
	debugName: 'crabClawSlam',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.CLOSEST_PLAYER,
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		spreadAngle: degToRad(324),
		projectileCount: 10,
		pierceCount: 999,
		speed: 500,
		basePhysicalDamage: 200,
		modifiers: [{ modType: ModType.ACCELERATION, value: { modValueType: ModValueType.LIFETIME, min: -2, max: 7 } }],
		lifespanInSeconds: 2.0,
		colliderRadius: 10,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_BEACHCRABBOSS,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.BOSS_BEACH_DUST,
		color: Colors.brown,
		burstCount: 1,
	},
	attackOffset: new Vector(0, -50),
	ignoreAngleOnWeaponOffset: true,
}

export const crabClawSlamEnragedEasyAbility: Ability = {
	debugName: 'crabClawSlam',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.CLOSEST_PLAYER,
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		spreadAngle: degToRad(336),
		projectileCount: 16,
		pierceCount: 999,
		speed: 650,
		basePhysicalDamage: 200,
		modifiers: [{ modType: ModType.ACCELERATION, value: { modValueType: ModValueType.LIFETIME, min: -2, max: 7 } }],
		lifespanInSeconds: 2.0,
		colliderRadius: 10,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_BEACHCRABBOSS,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.BOSS_BEACH_DUST,
		color: Colors.red,
		burstCount: 1,
	},
	attackOffset: new Vector(0, -50),
	ignoreAngleOnWeaponOffset: true,
}



export const crabSkeletonShortShootPlayerMediumAbility: Ability = {
	debugName: 'crabSkeletonShortShootPlayerAbility',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		speed: 800,
		lifespanInSeconds: 6,
		basePhysicalDamage: 40,
		baseIceDamage: 40,
		burstCount: 6,
		burstDelay: 0.1888,
		burstMode: BurstFireModes.TRACKING,
		burstAngle: degToRad(35),
		modifiers: [
			{ modType: ModType.WAVE, amplitude: { modValueType: ModValueType.VALUE, value: 0.4 }, period: { modValueType: ModValueType.VALUE, value: 1.6 } },
		],
		color: Colors.green,
		colliderRadius: 26,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_BEACHSKELEMAGEBOSS,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
	},
	shakeCamera: CameraShakeIntensities.MILD,
	attackOffset: new Vector(0, -450),
	ignoreAngleOnWeaponOffset: true,
}


export const crabSkeletonShortShootPlayerEnragedMediumAbility: Ability = {
	debugName: 'crabSkeletonShortShootPlayerEnragedAbility',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		speed: 1050,
		lifespanInSeconds: 5,
		projectileCount: 3,
		basePhysicalDamage: 35,
		baseIceDamage: 30,
		spreadAngle: degToRad(48),
		burstCount: 5,
		burstDelay: 0.1888,
		burstMode: BurstFireModes.TRACKING,
		burstAngle: degToRad(30),
		modifiers: [
			{ modType: ModType.WAVE, amplitude: { modValueType: ModValueType.VALUE, value: 0.4 }, period: { modValueType: ModValueType.VALUE, value: 1.6 } },
		],
		color: Colors.red,
		colliderRadius: 26,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_BEACHSKELEMAGEBOSS,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
	},
	shakeCamera: CameraShakeIntensities.MILD,
	attackOffset: new Vector(0, -450),
	ignoreAngleOnWeaponOffset: true,
}

export const crabSkeletonLongShootPlayerMediumAbility: Ability = {
	debugName: 'crabSkeletonLongShootPlayerAbility',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		speed: 800,
		lifespanInSeconds: 5,
		basePhysicalDamage: 30,
		baseIceDamage: 25,
		burstCount: 15,
		burstDelay: 0.15,
		burstMode: BurstFireModes.SWEEPING,
		burstAngle: degToRad(165),
		modifiers: [
			{ modType: ModType.WAVE, amplitude: { modValueType: ModValueType.VALUE, value: 0.4 }, period: { modValueType: ModValueType.VALUE, value: 1.6 } },
		],
		color: Colors.green,
		colliderRadius: 24,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_BEACHSKELEMAGEBOSS,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
	},
	shakeCamera: CameraShakeIntensities.MILD,
	attackOffset: new Vector(0, -450),
	ignoreAngleOnWeaponOffset: true,
}

export const crabSkeletonLongShootPlayerEnragedMediumAbility: Ability = {
	debugName: 'crabSkeletonLongShootPlayerEnragedAbility',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		speed: 1050,
		lifespanInSeconds: 5,
		projectileCount: 3,
		basePhysicalDamage: 25,
		baseIceDamage: 20,
		spreadAngle: degToRad(48),
		burstCount: 10,
		burstDelay: 0.2333,
		burstMode: BurstFireModes.SWEEPING,
		burstAngle: degToRad(72),
		modifiers: [
			{ modType: ModType.WAVE, amplitude: { modValueType: ModValueType.VALUE, value: 0.4 }, period: { modValueType: ModValueType.VALUE, value: 1.6 } },
		],
		color: Colors.red,
		colliderRadius: 24,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_BEACHSKELEMAGEBOSS,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
	},
	shakeCamera: CameraShakeIntensities.MILD,
	attackOffset: new Vector(0, -450),
	ignoreAngleOnWeaponOffset: true,
}

export const crabSkeletonShootWhileDefendingMedium: Ability = {
	debugName: 'crabSkeletonShootWhileDefending',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		speed: 800,
		lifespanInSeconds: 5,
		projectileCount: 6,
		basePhysicalDamage: 40,
		baseIceDamage: 40,
		spreadAngle: degToRad(300),
		burstCount: 5,
		burstDelay: 0.2333,
		burstMode: BurstFireModes.SWEEPING,
		burstAngle: degToRad(56),
		modifiers: [
			{ modType: ModType.WAVE, amplitude: { modValueType: ModValueType.VALUE, value: 0.4 }, period: { modValueType: ModValueType.VALUE, value: 1.6 } },
		],
		color: Colors.red,
		colliderRadius: 24,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_BEACHSKELEMAGEBOSS,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
	},
	shakeCamera: CameraShakeIntensities.MILD,
	attackOffset: new Vector(0, -450),
	ignoreAngleOnWeaponOffset: true,
}

export const crabSkeletonGrandFireballBrutal: Ability = {
	debugName: 'crabSkeletonGrandFireball',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		speed: 250,
		lifespanInSeconds: 5,
		projectileCount: 3,
		basePhysicalDamage: 0,
		baseIceDamage: 120,
		spreadAngle: degToRad(90),
		burstCount: 1,
		burstDelay: 0.2333,
		burstMode: BurstFireModes.SWEEPING,
		burstAngle: degToRad(56),
		color: Colors.red,
		colliderRadius: 96,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_BEACHSKELEMAGEBOSS,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_ICE_TRAIL,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_ICE_SHOOT,
		splashDamage: 0.8,
		splashRadius: 500,
	},
	shakeCamera: CameraShakeIntensities.MILD,
	attackOffset: new Vector(0, -450),
	ignoreAngleOnWeaponOffset: true,
}

export const crabClawSlamMediumAbility: Ability = {
	debugName: 'crabClawSlam',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.CLOSEST_PLAYER,
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		spreadAngle: degToRad(324),
		projectileCount: 10,
		pierceCount: 999,
		speed: 500,
		basePhysicalDamage: 250,
		modifiers: [{ modType: ModType.ACCELERATION, value: { modValueType: ModValueType.LIFETIME, min: -1, max: 7 } },
			{ modType: ModType.TURN, value: { modValueType: ModValueType.LIFETIME, min: 2.5, max: 1.0 } }],
		lifespanInSeconds: 2.0,
		colliderRadius: 13,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_BEACHCRABBOSS,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.BOSS_BEACH_DUST,
		color: Colors.brown,
		burstCount: 1,
	},
	attackOffset: new Vector(0, -50),
	ignoreAngleOnWeaponOffset: true,
}

export const crabClawSlamEnragedMediumAbility: Ability = {
	debugName: 'crabClawSlam',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.CLOSEST_PLAYER,
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		spreadAngle: degToRad(336),
		projectileCount: 16,
		pierceCount: 999,
		speed: 650,
		basePhysicalDamage: 250,
		modifiers: [{ modType: ModType.ACCELERATION, value: { modValueType: ModValueType.LIFETIME, min: -1, max: 7 } }, 
			{ modType: ModType.TURN, value: { modValueType: ModValueType.LIFETIME, min: 2.5, max: 1.0 } }],
		lifespanInSeconds: 2.0,
		colliderRadius: 13,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_BEACHCRABBOSS,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.BOSS_BEACH_DUST,
		color: Colors.red,
		burstCount: 1,
	},
	attackOffset: new Vector(0, -50),
	ignoreAngleOnWeaponOffset: true,
}



export const crabClawSlamEnragedBrutalAbility = deepClone(crabClawSlamEnragedMediumAbility)
export const crabClawSlamEnragedNastyAbility = deepClone(crabClawSlamEnragedMediumAbility)
export const crabClawSlamNastyEnragedAbilityReverse = deepClone(crabClawSlamMediumAbility)
	crabClawSlamNastyEnragedAbilityReverse.projectileConfig.modifiers = [
		{ modType: ModType.ACCELERATION, value: { modValueType: ModValueType.LIFETIME, min: -1, max: 7 } }, 
			{ modType: ModType.TURN, value: { modValueType: ModValueType.LIFETIME, min: -2.5, max: -1.0 } },]

export const crabSkeletonShootWhileDefendingBrutal = deepClone(crabSkeletonShootWhileDefendingMedium)
	crabSkeletonShootWhileDefendingBrutal.projectileConfig.modifiers = [ { modType: ModType.WAVE, amplitude: { modValueType: ModValueType.VALUE, value: 0.3}, period: { modValueType: ModValueType.VALUE, value: 1.6 } },]
export const crabSkeletonShootWhileDefendingNasty = deepClone(crabSkeletonShootWhileDefendingMedium)
	crabSkeletonShootWhileDefendingNasty.projectileConfig.modifiers = [ { modType: ModType.WAVE, amplitude: { modValueType: ModValueType.VALUE, value: 0.55 }, period: { modValueType: ModValueType.VALUE, value: 1.4 } },]

export const crabClawSlamBrutalAbility = deepClone(crabClawSlamMediumAbility)
export const crabClawSlamNastyAbility = deepClone(crabClawSlamMediumAbility)
export const crabClawSlamNastyAbilityReverse = deepClone(crabClawSlamMediumAbility)
	crabClawSlamNastyAbilityReverse.projectileConfig.modifiers = [
		{ modType: ModType.ACCELERATION, value: { modValueType: ModValueType.LIFETIME, min: -1, max: 7 } }, 
			{ modType: ModType.TURN, value: { modValueType: ModValueType.LIFETIME, min: -2.5, max: -1.0 } },]
		


export const crabSkeletonLongShootPlayerEnragedBrutalAbility = deepClone(crabSkeletonLongShootPlayerEnragedMediumAbility)
	crabSkeletonLongShootPlayerEnragedBrutalAbility.projectileConfig.modifiers = [ { modType: ModType.WAVE, amplitude: { modValueType: ModValueType.VALUE, value: 0.3 }, period: { modValueType: ModValueType.VALUE, value: 1.6 } },]
export const crabSkeletonLongShootPlayerEnragedNastyAbility = deepClone(crabSkeletonLongShootPlayerEnragedMediumAbility)
	crabSkeletonLongShootPlayerEnragedNastyAbility.projectileConfig.modifiers = [ { modType: ModType.WAVE, amplitude: { modValueType: ModValueType.VALUE, value: 0.55 }, period: { modValueType: ModValueType.VALUE, value: 1.4 } },]

export const crabSkeletonLongShootPlayerBrutalAbility = deepClone(crabSkeletonLongShootPlayerMediumAbility)
crabSkeletonLongShootPlayerBrutalAbility.projectileConfig.modifiers = [ { modType: ModType.WAVE, amplitude: { modValueType: ModValueType.VALUE, value: 0.3 }, period: { modValueType: ModValueType.VALUE, value: 1.6 } },]
export const crabSkeletonLongShootPlayerNastyAbility = deepClone(crabSkeletonLongShootPlayerMediumAbility)
crabSkeletonLongShootPlayerNastyAbility.projectileConfig.modifiers = [ { modType: ModType.WAVE, amplitude: { modValueType: ModValueType.VALUE, value: 0.55 }, period: { modValueType: ModValueType.VALUE, value: 1.4 } },]

export const crabSkeletonShortShootPlayerEnragedBrutalAbility = deepClone(crabSkeletonShortShootPlayerEnragedMediumAbility)
crabSkeletonShortShootPlayerEnragedBrutalAbility.projectileConfig.modifiers = [ { modType: ModType.WAVE, amplitude: { modValueType: ModValueType.VALUE, value: 0.3 }, period: { modValueType: ModValueType.VALUE, value: 1.6 } },]
export const crabSkeletonShortShootPlayerEnragedNastyAbility = deepClone(crabSkeletonShortShootPlayerEnragedMediumAbility)
crabSkeletonShortShootPlayerEnragedNastyAbility.projectileConfig.modifiers = [ { modType: ModType.WAVE, amplitude: { modValueType: ModValueType.VALUE, value: 0.55 }, period: { modValueType: ModValueType.VALUE, value: 1.4 } },]

export const crabSkeletonShortShootPlayerBrutalAbility = deepClone(crabSkeletonShortShootPlayerMediumAbility)
crabSkeletonShortShootPlayerBrutalAbility.projectileConfig.modifiers = [ { modType: ModType.WAVE, amplitude: { modValueType: ModValueType.VALUE, value: 0.3 }, period: { modValueType: ModValueType.VALUE, value: 1.6 } },]
export const crabSkeletonShortShootPlayerNastyAbility = deepClone(crabSkeletonShortShootPlayerMediumAbility)
crabSkeletonShortShootPlayerNastyAbility.projectileConfig.modifiers = [ { modType: ModType.WAVE, amplitude: { modValueType: ModValueType.VALUE, value: 0.55 }, period: { modValueType: ModValueType.VALUE, value: 1.4 } },]

export const crabMoveToPositionAbilityBrutal = deepClone(crabMoveToPositionAbility)
		crabMoveToPositionAbilityBrutal.moveRules.movementSpeed = 610

export const crabMoveToPositionAbilityEnragedBrutal = deepClone(crabMoveToPositionAbility)
		crabMoveToPositionAbilityEnragedBrutal.moveRules.movementSpeed = 800

export const crabMoveToPositionAbilityNasty = deepClone(crabMoveToPositionAbility)
		crabMoveToPositionAbilityNasty.moveRules.movementSpeed = 680

export const crabMoveToPositionAbilityEnragedNasty = deepClone(crabMoveToPositionAbility)
		crabMoveToPositionAbilityEnragedNasty.moveRules.movementSpeed = 920

export const crabSkeletonGrandFireballNasty = deepClone(crabSkeletonGrandFireballBrutal)