import { EnemyAI, FightingTriggers, IdleBehaviours, FightingBehaviours, LeashTriggers, LeashingBehaviours, AttackTypes, DeadBehaviours, ShotLeadPrecision, EnemyType, EnemyTargetingStyles } from '../../shared/ai-types'
import { BurstFireModes, ModType, ModValueType, ProjectileTargetType } from '../../../projectiles/shared/projectile-types'
import { SpineDataName, EnemyBipedSkinType } from '../../../models-animations/shared/spine-config'
import { degToRad } from '../../../utils/math'
import { EnemyDefaults } from './enemy-defaults'
import { Colors } from '../../../utils/colors'
import { AnimationTrack } from '../../../models-animations/shared/animation-track'
import { ParticleEffectType } from '../../../engine/shared/game-data/particle-config'
import { ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { ENEMY_DEFAULT_HEALTH } from '../../../engine/shared/game-data/enemy-formulas'
import { Ability, AbilitySelectionStyles, AbilityTargets, AbilityTargetSelectionStyle, AbilityType, ActionCriteria, CriteriaSatisfactionQuantity, CriteriaTarget, CriteriaType } from '../action-types'
import { alwaysTrueCriteria } from './abilities/common-abilities'
import { worldDifficultyBrutalCriterion, worldDifficultyHardCriterion } from '../action-criteria/action-criteria-helpers'
import { attackRatedAbilityList } from '../action-criteria/ability-helpers'
import { Vector } from 'sat'
import { ENEMY_NAMES } from './enemy-names'
import { deepClone } from '../abilities.test'
import { WorldDifficulty } from '../../../engine/shared/game-data/world-difficulty'

const playerIsInAttackRangeCriteriaNormal: ActionCriteria = {
	satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
	criterias: [
		{
			criteriaTargets: CriteriaTarget.PLAYERS,
			criteriaType: CriteriaType.WITHIN_RANGE,
			criteriaValue: 1200,
			minimumTargetsThatSatisfy: 1,
		},
		{
			criteriaTargets: CriteriaTarget.SELF,
			criteriaType: CriteriaType.WORLD_DIFFICULTY_AT_OR_BELOW_THRESHOLD,
			criteriaValue: WorldDifficulty.Normal,
			minimumTargetsThatSatisfy: 1,
		},
	],
}
const playerIsInAttackRangeCriteriaHard: ActionCriteria = {
	satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
	criterias: [
		{
			criteriaTargets: CriteriaTarget.PLAYERS,
			criteriaType: CriteriaType.WITHIN_RANGE,
			criteriaValue: 1200,
			minimumTargetsThatSatisfy: 1,
		},
		worldDifficultyHardCriterion,
	],
}
const playerIsInAttackRangeCriteriaBrutal: ActionCriteria = {
	satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
	criterias: [
		{
			criteriaTargets: CriteriaTarget.PLAYERS,
			criteriaType: CriteriaType.WITHIN_RANGE,
			criteriaValue: 1200,
			minimumTargetsThatSatisfy: 1,
		},
		worldDifficultyBrutalCriterion,
	],
}

const longRangeAbility: Ability = {
	debugName: 'longRangeAbility',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		projectileCount: 1,
		spreadAngle: 0,
		speed: 500,
		colliderRadius: 15,
		lifespanInSeconds: 5,
		modifiers: [{ modType: ModType.STRAIGHT }],
		basePhysicalDamage: 60,
		burstCount: 8,
		burstDelay: 0.15,
		burstMode: BurstFireModes.SWEEPING,
		burstAngle: degToRad(120),
		color: Colors.red,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_DRAGONSKULL,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_TRAIL,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
	},

	attackOffset: new Vector(300, 0),
	ignoreAngleOnWeaponOffset: true,
}

export const longRangeAbilityNormal = deepClone(longRangeAbility)
longRangeAbilityNormal.projectileConfig.burstCount = 6

export const longRangeAbilityHard = deepClone(longRangeAbility)

export const longRangeAbilityBrutal = deepClone(longRangeAbility)
longRangeAbilityBrutal.projectileConfig.burstCount = 12

const shortRangeAbility: Ability = {
	debugName: 'shortRangeAbility',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.CLOSEST,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		projectileCount: 3,
		spreadAngle: degToRad(45),
		speed: 600,
		colliderRadius: 10,
		lifespanInSeconds: 0.6,
		modifiers: [{ modType: ModType.ACCELERATION, value: { modValueType: ModValueType.LIFETIME, min: 3, max: -4 } }],
		baseIceDamage: 100,
		burstCount: 3,
		burstDelay: 0.15,
		burstMode: BurstFireModes.STRAIGHT,
		color: Colors.red,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_DRAGONSKULL,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_ICE_TRAIL,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_ICE_SHOOT,
	},

	attackOffset: new Vector(0, 0),
	ignoreAngleOnWeaponOffset: true,
}

export const boneSpikeAbility: Ability = {
	debugName: 'boneSpikes',
	abilityType: AbilityType.SPAWN_GROUND_HAZARD_MULTITARGET,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
	durationInGameTicks: 30,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		colliderRadius: 150,
		speed: 0,
		lifespanInSeconds: 0.2, // how long the projectile lasts on the ground
		baseIceDamage: 80,
		basePhysicalDamage: 80,
		color: Colors.red,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
		pierceCount: 999,
	},
	groundHazardMultitargetRules: {
		spineAsset: 'highlands-golem-aoe',
		pfx: 'aoe-explosion-highlandsgolem',
		pfxScale: 1.5,
		pfxSecondsOffset: 1.3, // how long before the PFX is created?
		projectileSecondsOffset: 1.4, // how long before it creates the projectile
		baseQuantity: 4,
		perTargetQuantity: 0,
		totalTime: 3,
		accuratePercent: 0.35,
		maxStray: 300,
	},
}

const animationTimes = {
	[AnimationTrack.SHOOT]: 0.3, // PLACEHOLDER
}

const dragonSkull: EnemyAI = {
	name: ENEMY_NAMES.DRAGON_SKULL,
	type: EnemyType.BASIC,
	appearance: {
		asset: SpineDataName.DRAGON_SKULL,
		skin: EnemyBipedSkinType.DEFAULT,
		mixSettings: [
			{ from: AnimationTrack.SHOOT, to: AnimationTrack.SHOOT, duration: 0.2 },
			{ from: AnimationTrack.SHOOT, to: AnimationTrack.IDLE, duration: 0.2 },
		],
	},
	general: {
		enemyLevel: 1,
		experienceWhenKilled: 0.75,
	},
	baseAttributes: {
		...EnemyDefaults.baseAttributes,
		colliders: [
			{
				type: ColliderType.Ellipse,
				rX: 80,
				rY: 60,
				position: [0, -30],
				traits: ColliderTraits.BlockMovementAndProjectile,
			},
		],
		maxHealth: ENEMY_DEFAULT_HEALTH * 3,
		movementSpeed: 0,
		noFlip: true,
		colliderIsKinematic: true,

		physicalDamage: 0,
		fireDamage: 0,
		iceDamage: 0,
		lightningDamage: 0,
		poisonDamage: 0,
		attackRate: 600,
		critChance: 0,
		critDamage: 1.0,
		chillPotency: 1.5,
		chillChance: 0.5,
		ignitePotency: 1.0,
		igniteChance: 0,

		defense: 0,
		fireResist: 0,
		poisonResist: 0,
		iceResist: 0,
		lightningResist: 0,

		lootDropProbability: 0.8,
		lootDropQuantity: 2.5,
		lootDropRarity: 1.1,
	},
	soundEffects: {
		attack: 'Projectile_Fireball',
		impact: 'SFX_Impact_Enemy_Mush',
	},
	states: {
		idling: {
			behaviour: IdleBehaviours.STATIONARY,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			transitionToFighting: {
				trigger: FightingTriggers.AGGRESSIVE_PROXIMITY,
				radius: 900,
			},
			aggroOnHit: true,
		},
		fighting: {
			behaviour: FightingBehaviours.CHASE_AND_ATTACK,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			attackType: AttackTypes.PROJECTILE,
			engagementMaxDistance: 1200,
			engagementMinDistance: 0,
			movementMaxDistance: 1200,
			movementMinDistance: 0,
			brain: {
				actions: [
					{
						//  BLOCK/ATTACK
						priority: 0,
						actionCriteria: playerIsInAttackRangeCriteriaBrutal,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
							//abilityOptions: [[1, shortRangeAbility, 30]],
							abilityOptions: [
								attackRatedAbilityList(2, animationTimes, AnimationTrack.SHOOT, longRangeAbilityBrutal, 30), 
								attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, boneSpikeAbility, 20)],
						},
					},
					{
						//  BLOCK/ATTACK
						priority: 1,
						actionCriteria: playerIsInAttackRangeCriteriaHard,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
							//abilityOptions: [[1, shortRangeAbility, 30]],
							abilityOptions: [
								attackRatedAbilityList(2, animationTimes, AnimationTrack.SHOOT, longRangeAbilityHard, 40), 
								attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, boneSpikeAbility, 40)],
						},
					},
					{
						//  BLOCK/ATTACK
						priority: 2,
						actionCriteria: playerIsInAttackRangeCriteriaNormal,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
							//abilityOptions: [[1, shortRangeAbility, 30]],
							abilityOptions: [
								attackRatedAbilityList(2, animationTimes, AnimationTrack.SHOOT, longRangeAbilityNormal, 40), 
								attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, boneSpikeAbility, 40)],
						},
					},
					{
						// BLOCK/ATTACK
						priority: 4,
						actionCriteria: alwaysTrueCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							//abilityOptions: [[1, longRangeAbility, 50]],
							abilityOptions: [attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, shortRangeAbility, 30)],
						},
					},
				],
			},
			attackOptions: [],
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 1,
			transitionToLeashing: {
				trigger: LeashTriggers.RANGE_TO_TARGET_EXCEEDED,
				range: 1500,
			},
			targetType: ProjectileTargetType.PLAYER
		},
		leashing: {
			behaviour: LeashingBehaviours.MOVE,
			speedMultiplier: 4,
		},
		fleeing: {},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 1,
		},
	},
}

export default dragonSkull
