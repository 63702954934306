import nengi from 'nengi'

export class DebugLoadLoadoutCommand {
	static protocol = {
		data: { type: nengi.String },
	}
	
    data: string

	constructor(data: string) {
		this.data = data
	}
}
