import nengi from 'nengi'

class SpawnEnemyOnMeCommand {
	static protocol = {
		test: nengi.String,
	}
	test: string
	constructor() {
		this.test = 'test'
	}
}

export default SpawnEnemyOnMeCommand
