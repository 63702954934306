import { SKIN_SLOT } from '../../player/shared/set-skin.shared'
import ItemRarity from './item-rarity'
import { PrizeData } from './prize-sub-type'

export class PlayerSkinPrizeData implements PrizeData {
	playerSkinSlot: SKIN_SLOT
	playerSkinIdentifier: string

	constructor(skinSlot: SKIN_SLOT, identifier: string) {
		this.playerSkinSlot = skinSlot
		this.playerSkinIdentifier = identifier
	}
}

export interface PlayerSkinInformation {
	skinIdentifier: string
	skinSlot: SKIN_SLOT
	spineJsonName?: string
	spineSkin?: string
	prettyName: string
	description: string
	rarity: ItemRarity
}

export function skinSlotToPrettyName(slot: SKIN_SLOT): string {
	switch (slot) {
		case SKIN_SLOT.PLAYER_MAIN:
			return 'main skin'
		case SKIN_SLOT.PLAYER_TOP:
			return 'top accessory'
		case SKIN_SLOT.PLAYER_BACK:
			return 'back accessory'
		case SKIN_SLOT.PLAYER_MIDDLE:
			return 'middle accessory'
		case SKIN_SLOT.PLAYER_LEGS:
			return 'leg accessory'
		case SKIN_SLOT.PLAYER_FEET:
			return 'feet accessory'
		case SKIN_SLOT.PLAYER_FOOTPRINT:
			return 'footprint'
		case SKIN_SLOT.PLAYER_AURA:
			return 'aura'
		case SKIN_SLOT.PET_MAIN:
			return 'pet'
		case SKIN_SLOT.PET_HAT:
			return 'pet hat'
		case SKIN_SLOT.PET_NECK:
			return 'pet neck'
		case SKIN_SLOT.PET_FACE:
			return 'pet face'
		case SKIN_SLOT.WEAPON_WAND:
			return 'wand skin'
		case SKIN_SLOT.WEAPON_STAFF:
			return 'staff skin'
		case SKIN_SLOT.WEAPON_SWORD:
			return 'spellsword skin'
		case SKIN_SLOT.WEAPON_ARCANE:
			return 'arcane focus skin'
		case SKIN_SLOT.WEAPON_CROSSBOW:
			return 'crossbow skin'
		case SKIN_SLOT.WEAPON_SCYTHE:
			return 'scythe skin'
		default:
			return 'ERROR - NO PRETTY NAME FOR SLOT'
	}
}

export const PLAYER_SKIN_INFORMATIONS: PlayerSkinInformation[] = [
	{
		skinIdentifier: 'ranger',
		skinSlot: SKIN_SLOT.PLAYER_MAIN,
		prettyName: 'Ranger',
		description: `It's the Ranger!`,
		rarity: ItemRarity.COMMON,
	},
	{
		skinIdentifier: 'ranger-02',
		skinSlot: SKIN_SLOT.PLAYER_MAIN,
		prettyName: 'Ranger (Green)',
		description: `This ranger is dressed for a forest adventure!`,
		rarity: ItemRarity.COMMON,
	},
	{
		skinIdentifier: 'ranger-03',
		skinSlot: SKIN_SLOT.PLAYER_MAIN,
		prettyName: 'Ranger (Purple)',
		description: `Hey alright, you get the idea? Write some description for each of these for the mtx store.  Maybe work in some lore idk, I don't have time for this`,
		rarity: ItemRarity.COMMON,
	},
	{
		skinIdentifier: 'dwarf',
		skinSlot: SKIN_SLOT.PLAYER_MAIN,
		prettyName: 'Dwarf',
		description: `some flavor text`,
		rarity: ItemRarity.COMMON,
	},
	{
		skinIdentifier: 'dwarf-02',
		skinSlot: SKIN_SLOT.PLAYER_MAIN,
		prettyName: 'Dwarf (Red)',
		description: `Like the star`,
		rarity: ItemRarity.COMMON,
	},
	{
		skinIdentifier: 'dwarf-03',
		skinSlot: SKIN_SLOT.PLAYER_MAIN,
		prettyName: 'Dwarf (Purple)',
		description: `some flavor text`,
		rarity: ItemRarity.COMMON,
	},
	{
		skinIdentifier: 'animal-barbarian',
		skinSlot: SKIN_SLOT.PLAYER_MAIN,
		prettyName: 'Animal Barbarian (Purple/Black)',
		description: `Maybe this guy is from some far off land?!`,
		rarity: ItemRarity.COMMON,
	},
	{
		skinIdentifier: 'animal-barbarian-02',
		skinSlot: SKIN_SLOT.PLAYER_MAIN,
		prettyName: 'Animal Barbarian (Orange/Blue)',
		description: `Maybe this guy is from some far off land?!`,
		rarity: ItemRarity.COMMON,
	},
	{
		skinIdentifier: 'animal-barbarian-03',
		skinSlot: SKIN_SLOT.PLAYER_MAIN,
		prettyName: 'Animal Barbarian (White/Green)',
		description: `Maybe this guy is from some far off land?!`,
		rarity: ItemRarity.COMMON,
	},
	{
		skinIdentifier: 'sorceress',
		skinSlot: SKIN_SLOT.PLAYER_MAIN,
		prettyName: 'Sorceress (White/Blue)',
		description: `She's kinda edgy`,
		rarity: ItemRarity.COMMON,
	},
	{
		skinIdentifier: 'sorceress-02',
		skinSlot: SKIN_SLOT.PLAYER_MAIN,
		prettyName: 'Sorceress (Dark Brown/Red)',
		description: `She's kinda edgy`,
		rarity: ItemRarity.COMMON,
	},
	{
		skinIdentifier: 'sorceress-03',
		skinSlot: SKIN_SLOT.PLAYER_MAIN,
		prettyName: 'Sorceress (Pink)',
		description: `She's kinda edgy`,
		rarity: ItemRarity.COMMON,
	},
	{
		skinIdentifier: 'wizard',
		skinSlot: SKIN_SLOT.PLAYER_MAIN,
		prettyName: 'Wizard',
		description: `Wiiiizzaaard`,
		rarity: ItemRarity.COMMON,
	},
	{
		skinIdentifier: 'wizard-02',
		skinSlot: SKIN_SLOT.PLAYER_MAIN,
		prettyName: 'Wizard (Blue)',
		description: `Wiiiizzaaard`,
		rarity: ItemRarity.COMMON,
	},
	{
		skinIdentifier: 'wizard-03',
		skinSlot: SKIN_SLOT.PLAYER_MAIN,
		prettyName: 'Wizard (Red)',
		description: `kick`,
		rarity: ItemRarity.COMMON,
	},
	{
		skinIdentifier: 'human-knight',
		skinSlot: SKIN_SLOT.PLAYER_MAIN,
		prettyName: 'Knight',
		description: `What is he a knight of?`,
		rarity: ItemRarity.COMMON,
	},
	{
		skinIdentifier: 'human-knight-02',
		skinSlot: SKIN_SLOT.PLAYER_MAIN,
		prettyName: 'Knight (Blue)',
		description: `What is he a knight of?`,
		rarity: ItemRarity.COMMON,
	},
	{
		skinIdentifier: 'human-knight-03',
		skinSlot: SKIN_SLOT.PLAYER_MAIN,
		prettyName: 'Knight (Yellow/Purple)',
		description: `Haha get it? "Good Knight?"`,
		rarity: ItemRarity.COMMON,
	},
	{
		skinIdentifier: 'elf-ranger',
		skinSlot: SKIN_SLOT.PLAYER_MAIN,
		prettyName: 'Elf Ranger (Green/Red/Blue)',
		description: `My hair is Navy OK?`,
		rarity: ItemRarity.COMMON,
	},
	{
		skinIdentifier: 'elf-ranger-02',
		skinSlot: SKIN_SLOT.PLAYER_MAIN,
		prettyName: 'Elf Ranger (Black/White/Yellow)',
		description: `Drizzt Do'UrDave`,
		rarity: ItemRarity.COMMON,
	},
	{
		skinIdentifier: 'elf-ranger-03',
		skinSlot: SKIN_SLOT.PLAYER_MAIN,
		prettyName: 'Yellow Hair Elf',
		description: `A Soul Cycle of over 9000!`,
		rarity: ItemRarity.COMMON,
	},
	{
		skinIdentifier: 'aurum-alliance-soldier',
		skinSlot: SKIN_SLOT.PLAYER_MAIN,
		prettyName: 'Aurum Alliance Soldier',
		description: ``,
		rarity: ItemRarity.EPIC,
	},
	{
		skinIdentifier: 'scions-of-dawn-soldier',
		skinSlot: SKIN_SLOT.PLAYER_MAIN,
		prettyName: 'Scions of Dawn Soldier',
		description: ``,
		rarity: ItemRarity.EPIC,
	},
	{
		skinIdentifier: 'order-of-iron-soldier',
		skinSlot: SKIN_SLOT.PLAYER_MAIN,
		prettyName: 'Order of Iron Soldier',
		description: ``,
		rarity: ItemRarity.EPIC,
	},
	{
		skinIdentifier: 'aurum-alliance-blackmark',
		skinSlot: SKIN_SLOT.PLAYER_MAIN,
		prettyName: 'Aurum Alliance Blackmark',
		description: ``,
		rarity: ItemRarity.LEGENDARY,
	},
	{
		skinIdentifier: 'scions-of-dawn-solara',
		skinSlot: SKIN_SLOT.PLAYER_MAIN,
		prettyName: 'Scions of Dawn Solara',
		description: ``,
		rarity: ItemRarity.LEGENDARY,
	},
	{
		skinIdentifier: 'order-of-iron-rehkosh',
		skinSlot: SKIN_SLOT.PLAYER_MAIN,
		prettyName: 'Order of Iron Rehkosh',
		description: ``,
		rarity: ItemRarity.LEGENDARY,
	},
	// Back Cosmetics
	// @TODO: go through these and set the rarity to proper
	// any appearing in the mtx store is set correctly already
	{
		skinIdentifier: 'aurum-barrel',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'aurum-barrel',
		spineSkin: 'default',
		prettyName: 'Aurum Barrel',
		description: `A keg of the good stuff from the Aurum Alliance`,
		rarity: ItemRarity.EPIC
	},
	{
		skinIdentifier: 'gold-axe',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'axe',
		spineSkin: 'gold-axe',
		prettyName: 'Golden Axe',
		description: `Not a reference!`,
		rarity: ItemRarity.RARE
	},
	{
		skinIdentifier: 'silver-axe',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'axe',
		spineSkin: 'silver-axe',
		prettyName: 'Silver Axe',
		description: `Second place is pretty good too`,
		rarity: ItemRarity.UNCOMMON
	},
	{
		skinIdentifier: 'wood-axe',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'axe',
		spineSkin: 'wood-axe',
		prettyName: 'Wooden Axe',
		description: `idk if these descriptions will ever actually be used`,
		rarity: ItemRarity.UNCOMMON
	},
	{
		skinIdentifier: 'black-bow',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'bow',
		spineSkin: 'black-bow',
		prettyName: 'Black Bow',
		description: `Are those... bat feathers?`,
		rarity: ItemRarity.EPIC
	},
	{
		skinIdentifier: 'blue-bow',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'bow',
		spineSkin: 'blue-bow',
		prettyName: 'Blue Bow',
		description: ``,
		rarity: ItemRarity.RARE
	},
	{
		skinIdentifier: 'wood-bow',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'bow',
		spineSkin: 'wood-bow',
		prettyName: 'Wooden Bow',
		description: `Can you paint it?`,
		rarity: ItemRarity.UNCOMMON
	},
	{
		skinIdentifier: 'bronze-wings',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'bronze-wings',
		spineSkin: 'default',
		prettyName: 'Bronze Wings',
		description: ``,
		rarity: ItemRarity.COMMON
	},
	{
		skinIdentifier: 'blue-brush',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'brush',
		spineSkin: 'blue-brush',
		prettyName: 'Blue Paintbrush',
		description: `You gotta paint the sky first`,
		rarity: ItemRarity.UNCOMMON
	},
	{
		skinIdentifier: 'red-brush',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'brush',
		spineSkin: 'red-brush',
		prettyName: 'Red Paintbrush',
		description: `Next, add some red highlights for the sun`,
		rarity: ItemRarity.UNCOMMON
	},
	{
		skinIdentifier: 'yellow-brush',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'brush',
		spineSkin: 'yellow-brush',
		prettyName: 'Yellow Paintbrush',
		description: `Time for a bravery test`,
		rarity: ItemRarity.RARE
	},
	{
		skinIdentifier: 'butterfly-green',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'butterfly-wings',
		spineSkin: 'butterfly-green',
		prettyName: 'Green Butterfly Wings',
		description: `Are they supposed to be that color?`,
		rarity: ItemRarity.RARE
	},
	{
		skinIdentifier: 'butterfly-orange',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'butterfly-wings',
		spineSkin: 'butterfly-orange',
		prettyName: 'Monarch Butterfly Wings',
		description: `These bad boys are even bigger up close`,
		rarity: ItemRarity.EPIC
	},
	{
		skinIdentifier: 'butterfly-pastel',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'butterfly-wings',
		spineSkin: 'butterfly-pastel',
		prettyName: 'Pastel Butterfly Wings',
		description: `Dreamy`,
		rarity: ItemRarity.LEGENDARY
	},
	{
		skinIdentifier: 'cape-black',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'cape',
		spineSkin: 'cape-black',
		prettyName: 'Black Cape',
		description: `It has protected adventurers through the ages`,
		rarity: ItemRarity.RARE
	},
	{
		skinIdentifier: 'cape-red',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'cape',
		spineSkin: 'cape-red',
		prettyName: 'Red Cape',
		description: `The classic red cape.`,
		rarity: ItemRarity.UNCOMMON
	},
	{
		skinIdentifier: 'cape-magic',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'cape',
		spineSkin: 'cape-magic',
		prettyName: 'Magic Cape',
		description: `Don't sue us`,
		rarity: ItemRarity.UNCOMMON
	},
	{
		skinIdentifier: 'chaos-wings',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'chaos-wings',
		spineSkin: 'default',
		prettyName: 'Chaos Wings',
		description: `Umm actually, Balrogs don't have wings`,
		rarity: ItemRarity.COMMON
	},
	{
		skinIdentifier: 'diamond-wings',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'diamond-wings',
		spineSkin: 'default',
		prettyName: 'Diamond Wings',
		description: `No chance these get you airborne`,
		rarity: ItemRarity.COMMON
	},
	{
		skinIdentifier: 'green-dragon',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'dragon-wings',
		spineSkin: 'green-dragon',
		prettyName: 'Green Dragon Wings',
		description: `From the lair of a dragon`,
		rarity: ItemRarity.RARE
	},
	{
		skinIdentifier: 'rainbow-dragon',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'dragon-wings',
		spineSkin: 'rainbow-dragon',
		prettyName: 'Rainbow Dragon Wings',
		description: `From a prideful dragon`,
		rarity: ItemRarity.LEGENDARY
	},
	{
		skinIdentifier: 'red-dragon',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'dragon-wings',
		spineSkin: 'red-dragon',
		prettyName: 'Red Dragon Wings',
		description: `Edgy`,
		rarity: ItemRarity.EPIC
	},
	{
		skinIdentifier: 'prize-flag-aurum',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'faction-flags',
		spineSkin: 'prize-flag-aurum',
		prettyName: 'Aurum Flag',
		description: `Represent the Aurum Alliance`,
		rarity: ItemRarity.COMMON
	},
	{
		skinIdentifier: 'prize-flag-iron',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'faction-flags',
		spineSkin: 'prize-flag-iron',
		prettyName: 'Iron Flag',
		description: `Represent the Order of Iron`,
		rarity: ItemRarity.COMMON
	},
	{
		skinIdentifier: 'prize-flag-scion',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'faction-flags',
		spineSkin: 'prize-flag-scion',
		prettyName: 'Scion Flag',
		description: `Represent the Scions of Dawn`,
		rarity: ItemRarity.COMMON
	},
	{
		skinIdentifier: 'gilded-flag-aurum',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'faction-gilded-flags',
		spineSkin: 'gilded-flag-aurum',
		prettyName: 'Gilded Aurum Flag',
		description: `Reign of the Aurum Alliance`,
		rarity: ItemRarity.LEGENDARY
	},
	{
		skinIdentifier: 'gilded-flag-iron',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'faction-gilded-flags',
		spineSkin: 'gilded-flag-iron',
		prettyName: 'Gilded Iron Flag',
		description: `Reign of the Order of Iron`,
		rarity: ItemRarity.LEGENDARY
	},
	{
		skinIdentifier: 'gilded-flag-scion',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'faction-gilded-flags',
		spineSkin: 'gilded-flag-scion',
		prettyName: 'Gilded Scion Flag',
		description: `Reign of the Scions of Dawn`,
		rarity: ItemRarity.LEGENDARY
	},
	{
		skinIdentifier: 'feather-wings-black',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'feather-wings',
		spineSkin: 'feather-wings-black',
		prettyName: 'Black Feather Wings',
		description: `Ballet, anyone?`,
		rarity: ItemRarity.EPIC
	},
	{
		skinIdentifier: 'feather-wings-brown',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'feather-wings',
		spineSkin: 'feather-wings-brown',
		prettyName: 'Brown Feather Wings',
		description: `Hoot, hoot`,
		rarity: ItemRarity.UNCOMMON
	},
	{
		skinIdentifier: 'feather-wings-white',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'feather-wings',
		spineSkin: 'feather-wings-white',
		prettyName: 'White Feather Wings',
		description: ``,
		rarity: ItemRarity.RARE
	},
	{
		skinIdentifier: 'gold-wings',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'gold-wings',
		spineSkin: 'default',
		prettyName: 'Golden wings',
		description: `First place!`,
		rarity: ItemRarity.COMMON
	},
	{
		skinIdentifier: 'acoustic-guitar',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'guitar',
		spineSkin: 'acoustic',
		prettyName: 'Acoustic Guitar',
		description: `Anyway, here's Wonderwall`,
		rarity: ItemRarity.UNCOMMON
	},
	{
		skinIdentifier: 'bass-guitar',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'guitar',
		spineSkin: 'bass',
		prettyName: 'Bass Guitar',
		description: `Slap-pop, slap-pop`,
		rarity: ItemRarity.RARE
	},
	{
		skinIdentifier: 'electric-guitar',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'guitar',
		spineSkin: 'electric',
		prettyName: 'Electric Guitar',
		description: `Screeeeeech`,
		rarity: ItemRarity.EPIC
	},
	{
		skinIdentifier: 'iron-meteorite',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'iron-meteorite',
		spineSkin: 'default',
		prettyName: 'Iron Meteorite',
		description: `A gift from the Order of Iron`,
		rarity: ItemRarity.EPIC
	},
	{
		skinIdentifier: 'placeholder-back-cosmetic',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'placeholder-back-cosmetic',
		spineSkin: 'yellow',
		prettyName: 'Placeholder Back',
		description: `Placeholder Back Description`,
		rarity: ItemRarity.COMMON
	},
	{
		skinIdentifier: 'robot-wings-blue',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'robot-wings',
		spineSkin: 'robot-wings-blue',
		prettyName: 'Blue Thrusters',
		description: `Whatever you do, don't lean backwards`,
		rarity: ItemRarity.COMMON
	},
	{
		skinIdentifier: 'robot-wings-purple',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'robot-wings',
		spineSkin: 'robot-wings-purple',
		prettyName: 'Purple Thrusters',
		description: ``,
		rarity: ItemRarity.COMMON
	},
	{
		skinIdentifier: 'robot-wings-gold',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'robot-wings',
		spineSkin: 'robot-wings-yellow',
		prettyName: 'Yellow Thrusters',
		description: ``,
		rarity: ItemRarity.COMMON
	},
	{
		skinIdentifier: 'scion-wings',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'scion-wings',
		spineSkin: 'default',
		prettyName: 'Scions of Dawn Wings',
		description: `Wings representing the Scions of Dawn`,
		rarity: ItemRarity.EPIC
	},
	{
		skinIdentifier: 'silver-wings',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'silver-wings',
		spineSkin: 'default',
		prettyName: 'Silver Wings',
		description: `Careful, these have claimed a few fingers already`,
		rarity: ItemRarity.COMMON
	},
	{
		skinIdentifier: 'blue-sword',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'sword',
		spineSkin: 'blue-sword',
		prettyName: 'Blue Sword',
		description: ``,
		rarity: ItemRarity.UNCOMMON
	},
	{
		skinIdentifier: 'green-sword',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'sword',
		spineSkin: 'green-sword',
		prettyName: 'Green Sword',
		description: ``,
		rarity: ItemRarity.UNCOMMON
	},
	{
		skinIdentifier: 'purple-sword',
		skinSlot: SKIN_SLOT.PLAYER_BACK,
		spineJsonName: 'sword',
		spineSkin: 'purple-sword',
		prettyName: 'Purple Sword',
		description: `Does not increase side-step speed`,
		rarity: ItemRarity.RARE
	},
	// Face Cosmetics
	{
		skinIdentifier: '3d-black',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: '3d-black',
		prettyName: 'Black 3D Glasses',
		description: ``,
		rarity: ItemRarity.UNCOMMON
	},
	{
		skinIdentifier: '3d-green',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: '3d-green',
		prettyName: 'Green 3D Glasses',
		description: ``,
		rarity: ItemRarity.UNCOMMON
	},
	{
		skinIdentifier: '3d-white',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: '3d-white',
		prettyName: 'White 3D Glasses',
		description: ``,
		rarity: ItemRarity.COMMON
	},
	{
		skinIdentifier: 'aurum-plague-doctor',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'aurum-plague-doctor',
		prettyName: 'Aurum Plague Doctor',
		description: ``,
		rarity: ItemRarity.RARE
	},
	{
		skinIdentifier: 'aurum-silencer',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'aurum-silencer',
		prettyName: 'Aurum Silencer',
		description: ``,
		rarity: ItemRarity.RARE
	},
	{
		skinIdentifier: 'aurum-snakes-possession',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'aurum-snakes-possession',
		prettyName: 'Aurum Snakes',
		description: ``,
		rarity: ItemRarity.RARE
	},
	{
		skinIdentifier: 'butterfly-face-green',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'butterfly-green',
		prettyName: 'Green Butterfly Glasses',
		description: ``,
		rarity: ItemRarity.UNCOMMON
	},
	{
		skinIdentifier: 'butterfly-face-orange',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'butterfly-orange',
		prettyName: 'Orange Butterfly Glasses',
		description: ``,
		rarity: ItemRarity.UNCOMMON
	},
	{
		skinIdentifier: 'butterfly-face-purple',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'butterfly-purple',
		prettyName: 'Purple Butterfly Glasses',
		description: ``,
		rarity: ItemRarity.UNCOMMON
	},
	{
		skinIdentifier: 'circle-dark',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'circle-dark',
		prettyName: 'Dark Circular Glasses',
		description: ``,
		rarity: ItemRarity.COMMON
	},
	{
		skinIdentifier: 'circle-gold',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'circle-gold',
		prettyName: 'Gold Circular Glasses',
		description: ``,
		rarity: ItemRarity.UNCOMMON
	},
	{
		skinIdentifier: 'circle-pink',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'circle-pink',
		prettyName: 'Pink Circular Glasses',
		description: ``,
		rarity: ItemRarity.UNCOMMON
	},
	{
		skinIdentifier: 'founders-bronze-glasses',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'founders-bronze-glasses',
		prettyName: 'Bronze Founders Glasses',
		description: ``,
		rarity: ItemRarity.RARE
	},
	{
		skinIdentifier: 'founders-chaos-glasses',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'founders-chaos-glasses',
		prettyName: 'Chaos Founders Glasses',
		description: ``,
		rarity: ItemRarity.ASTRONOMICAL
	},
	{
		skinIdentifier: 'founders-diamond-glasses',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'founders-diamond-glasses',
		prettyName: 'Diamond Founders Glasses',
		description: ``,
		rarity: ItemRarity.ASTRONOMICAL
	},
	{
		skinIdentifier: 'founders-gold-glasses',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'founders-gold-glasses',
		prettyName: 'Gold Founders Glasses',
		description: ``,
		rarity: ItemRarity.EPIC
	},
	{
		skinIdentifier: 'founders-silver-glasses',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'founders-silver-glasses',
		prettyName: 'Silver Founders Glasses',
		description: ``,
		rarity: ItemRarity.RARE
	},
	{
		skinIdentifier: 'futuristic-black',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'futuristic-black',
		prettyName: 'Black Futuristic Visor',
		description: ``,
		rarity: ItemRarity.RARE
	},
	{
		skinIdentifier: 'futuristic-gold',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'futuristic-gold',
		prettyName: 'Gold Futuristic Visor',
		description: ``,
		rarity: ItemRarity.RARE
	},
	{
		skinIdentifier: 'futuristic-purple',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'futuristic-purple',
		prettyName: 'Purple Futuristic Visor',
		description: ``,
		rarity: ItemRarity.UNCOMMON
	},
	{
		skinIdentifier: 'gilded-aurum-alliance-mask',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'gilded-aurum-alliance-mask',
		prettyName: 'Gilded Aurum Alliance Mask',
		description: ``,
		rarity: ItemRarity.RARE
	},
	{
		skinIdentifier: 'gilded-order-of-iron-mask',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'gilded-order-of-iron-mask',
		prettyName: 'Gilded Order of Iron Mask',
		description: ``,
		rarity: ItemRarity.RARE
	},
	{
		skinIdentifier: 'gilded-scions-of-dawn-mask',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'gilded-scions-of-dawn-mask',
		prettyName: 'Gilded Scions of Dawn Mask',
		description: ``,
		rarity: ItemRarity.RARE
	},
	{
		skinIdentifier: 'glasses-black',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'glasses-black',
		prettyName: 'Black Beetlevisors',
		description: ``,
		rarity: ItemRarity.UNCOMMON
	},
	{
		skinIdentifier: 'glasses-blurple',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'glasses-blurple',
		prettyName: 'Green Beetlevisors',
		description: ``,
		rarity: ItemRarity.UNCOMMON
	},
	{
		skinIdentifier: 'glasses-red',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'glasses-red',
		prettyName: 'Red Beetlevisors',
		description: ``,
		rarity: ItemRarity.COMMON
	},
	{
		skinIdentifier: 'heart-pink',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'heart-pink',
		prettyName: 'Pink Heart Glasses',
		description: ``,
		rarity: ItemRarity.UNCOMMON
	},
	{
		skinIdentifier: 'heart-rainbow',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'heart-rainbow',
		prettyName: 'Rainbow Heart Glasses',
		description: ``,
		rarity: ItemRarity.EPIC
	},
	{
		skinIdentifier: 'heart-red',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'heart-red',
		prettyName: 'Red Heart Glasses',
		description: ``,
		rarity: ItemRarity.RARE
	},
	{
		skinIdentifier: 'iron-conqueror',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'iron-conqueror',
		prettyName: 'Order of Iron Conqueror Mask',
		description: ``,
		rarity: ItemRarity.RARE
	},
	{
		skinIdentifier: 'iron-warforge',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'iron-warforge',
		prettyName: 'Order of Iron Warforge Mask',
		description: ``,
		rarity: ItemRarity.RARE
	},
	{
		skinIdentifier: 'iron-wild-animal',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'iron-wild-animal',
		prettyName: 'Order of Iron Skull Mask',
		description: ``,
		rarity: ItemRarity.RARE
	},
	{
		skinIdentifier: 'monocle-gold',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'monocle-gold',
		prettyName: 'Gold Monocle',
		description: ``,
		rarity: ItemRarity.RARE
	},
	{
		skinIdentifier: 'monocle-purple',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'monocle-purple',
		prettyName: 'Purple Hypnocle',
		description: ``,
		rarity: ItemRarity.UNCOMMON
	},
	{
		skinIdentifier: 'monocle-silver',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'monocle-silver',
		prettyName: 'Silver Half-Monocle',
		description: ``,
		rarity: ItemRarity.EPIC
	},
	{
		skinIdentifier: 'placeholder-face-icon',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'placeholder-face-cosmetic',
		prettyName: 'Placeholder Face',
		description: `Placeholder Face Description`,
		rarity: ItemRarity.RARE
	},
	{
		skinIdentifier: 'scion-holy-sight',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'scion-holy-sight',
		prettyName: 'Scions of Dawn Holy Sight Mask',
		description: ``,
		rarity: ItemRarity.RARE
	},
	{
		skinIdentifier: 'scion-sun-guardian',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'scion-sun-guardian',
		prettyName: 'Scions of Dawn Sun Guardian Mask',
		description: ``,
		rarity: ItemRarity.RARE
	},
	{
		skinIdentifier: 'scion-winged-angel',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'scion-winged-angel',
		prettyName: 'Scions of Dawn Winged Angel Mask',
		description: ``,
		rarity: ItemRarity.RARE
	},
	{
		skinIdentifier: 'ski-green',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'ski-green',
		prettyName: 'Green Ski Goggles',
		description: ``,
		rarity: ItemRarity.UNCOMMON
	},
	{
		skinIdentifier: 'ski-pink',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'ski-pink',
		prettyName: 'Pink Ski Goggles',
		description: ``,
		rarity: ItemRarity.RARE
	},
	{
		skinIdentifier: 'ski-white',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'ski-white',
		prettyName: 'White Ski Goggles',
		description: ``,
		rarity: ItemRarity.UNCOMMON
	},
	{
		skinIdentifier: 'snorkel-blue',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'snorkel-blue',
		prettyName: 'Blue Snorkel',
		description: ``,
		rarity: ItemRarity.RARE
	},
	{
		skinIdentifier: 'snorkel-red',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'snorkel-red',
		prettyName: 'Red Snorkel',
		description: ``,
		rarity: ItemRarity.RARE
	},
	{
		skinIdentifier: 'snorkel-starfish',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'snorkel-starfish',
		prettyName: 'Lightly Used Snorkel',
		description: ``,
		rarity: ItemRarity.RARE
	},
	{
		skinIdentifier: 'sunglasses-black',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'sunglasses-black',
		prettyName: 'Black Sunglasses',
		description: ``,
		rarity: ItemRarity.COMMON
	},
	{
		skinIdentifier: 'sunglasses-blue',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'sunglasses-blue',
		prettyName: 'Blue Sunglasses',
		description: ``,
		rarity: ItemRarity.UNCOMMON
	},
	{
		skinIdentifier: 'sunglasses-tortoise',
		skinSlot: SKIN_SLOT.PLAYER_FACE,
		spineJsonName: 'face-cosmetics',
		spineSkin: 'sunglasses-tortoise',
		prettyName: 'Tortoise Sunglasses',
		description: `Not like the reptile!`,
		rarity: ItemRarity.UNCOMMON
	},
	// PFX trails
	{
		skinIdentifier: 'player-trail-breakfastcandy-01',
		skinSlot: SKIN_SLOT.PLAYER_FOOTPRINT,
		spineJsonName: 'player-trail-breakfastcandy-01',
		spineSkin: 'default',
		prettyName: 'Toaster Pastries',
		description: `Start your day off right`,
		rarity: ItemRarity.EPIC
	},
	{
		skinIdentifier: 'player-trail-bubbles-01',
		skinSlot: SKIN_SLOT.PLAYER_FOOTPRINT,
		spineJsonName: 'player-trail-bubbles-01',
		spineSkin: 'default',
		prettyName: 'Bubbles',
		description: `For those with a bubbly personality`,
		rarity: ItemRarity.UNCOMMON
	},
	{
		skinIdentifier: 'player-trail-butterfly-01',
		skinSlot: SKIN_SLOT.PLAYER_FOOTPRINT,
		spineJsonName: 'player-trail-butterfly-01',
		spineSkin: 'default',
		prettyName: 'Butterflies',
		description: `Flutter by`,
		rarity: ItemRarity.UNCOMMON
	},
	{
		skinIdentifier: 'player-trail-candy-01',
		skinSlot: SKIN_SLOT.PLAYER_FOOTPRINT,
		spineJsonName: 'player-trail-candy-01',
		spineSkin: 'default',
		prettyName: 'Lollipops',
		description: `I've heard of a sweet tooth, but this is ridiculous`,
		rarity: ItemRarity.LEGENDARY
	},
	{
		skinIdentifier: 'player-trail-cuteskulls-01',
		skinSlot: SKIN_SLOT.PLAYER_FOOTPRINT,
		spineJsonName: 'player-trail-cuteskulls-01',
		spineSkin: 'default',
		prettyName: 'Pretty Skulls',
		description: `Being dead doesn't mean you can't look cute`,
		rarity: ItemRarity.RARE
	},
	{
		skinIdentifier: 'player-trail-fireworks-01',
		skinSlot: SKIN_SLOT.PLAYER_FOOTPRINT,
		spineJsonName: 'player-trail-fireworks-01',
		spineSkin: 'default',
		prettyName: 'Fireworks',
		description: `Make every day a celebration`,
		rarity: ItemRarity.RARE
	},
	{
		skinIdentifier: 'player-trail-flowers-01',
		skinSlot: SKIN_SLOT.PLAYER_FOOTPRINT,
		spineJsonName: 'player-trail-flowers-01',
		spineSkin: 'default',
		prettyName: 'Lotus Flowers',
		description: `Delicate and graceful`,
		rarity: ItemRarity.RARE
	},
	{
		skinIdentifier: 'player-trail-ghosts-01',
		skinSlot: SKIN_SLOT.PLAYER_FOOTPRINT,
		spineJsonName: 'player-trail-ghosts-01',
		spineSkin: 'default',
		prettyName: 'Spooky Ghosts',
		description: `Boo!`,
		rarity: ItemRarity.EPIC
	},
	{
		skinIdentifier: 'player-trail-greenflames-01',
		skinSlot: SKIN_SLOT.PLAYER_FOOTPRINT,
		spineJsonName: 'player-trail-greenflames-01',
		spineSkin: 'default',
		prettyName: 'Arcane Runes',
		description: `Lost in translation`,
		rarity: ItemRarity.EPIC
	},
	{
		skinIdentifier: 'player-trail-hearts-01',
		skinSlot: SKIN_SLOT.PLAYER_FOOTPRINT,
		spineJsonName: 'player-trail-hearts-01',
		spineSkin: 'default',
		prettyName: 'Hearts',
		description: `Spread the love`,
		rarity: ItemRarity.EPIC
	},
	{
		skinIdentifier: 'player-trail-mapleleaf-01',
		skinSlot: SKIN_SLOT.PLAYER_FOOTPRINT,
		spineJsonName: 'player-trail-mapleleaf-01',
		spineSkin: 'default',
		prettyName: 'Maple Leaves',
		description: `Bet you can't guess where this game was made, eh?`,
		rarity: ItemRarity.RARE
	},
	{
		skinIdentifier: 'player-trail-notes-01',
		skinSlot: SKIN_SLOT.PLAYER_FOOTPRINT,
		spineJsonName: 'player-trail-notes-01',
		spineSkin: 'default',
		prettyName: 'Music Notes',
		description: `A song in your step`,
		rarity: ItemRarity.RARE
	},
	{
		skinIdentifier: 'player-trail-peace-01',
		skinSlot: SKIN_SLOT.PLAYER_FOOTPRINT,
		spineJsonName: 'player-trail-peace-01',
		spineSkin: 'default',
		prettyName: 'Peace Signs',
		description: `Kinda ironic given how this game works`,
		rarity: ItemRarity.RARE
	},
	{
		skinIdentifier: 'player-trail-pixels-01',
		skinSlot: SKIN_SLOT.PLAYER_FOOTPRINT,
		spineJsonName: 'player-trail-pixels-01',
		spineSkin: 'default',
		prettyName: 'Pixels',
		description: `Digital trail for a digital person`,
		rarity: ItemRarity.UNCOMMON
	},
	{
		skinIdentifier: 'player-trail-rainbow-01',
		skinSlot: SKIN_SLOT.PLAYER_FOOTPRINT,
		spineJsonName: 'player-trail-rainbow-01',
		spineSkin: 'default',
		prettyName: 'Nyantrail',
		description: `All the way across the sky`,
		rarity: ItemRarity.EPIC
	},
	{
		skinIdentifier: 'player-trail-rainbow-02',
		skinSlot: SKIN_SLOT.PLAYER_FOOTPRINT,
		spineJsonName: 'player-trail-rainbow-02',
		spineSkin: 'default',
		prettyName: 'Shimmery',
		description: `Somewhere between cosmic space dust, and a fart`,
		rarity: ItemRarity.RARE
	},
	{
		skinIdentifier: 'player-trail-spiders-01',
		skinSlot: SKIN_SLOT.PLAYER_FOOTPRINT,
		spineJsonName: 'player-trail-spiders-01',
		spineSkin: 'default',
		prettyName: 'Spiders',
		description: `Itty bitty creepy crawlies`,
		rarity: ItemRarity.EPIC
	},
	{
		skinIdentifier: 'player-trail-stars-01',
		skinSlot: SKIN_SLOT.PLAYER_FOOTPRINT,
		spineJsonName: 'player-trail-stars-01',
		spineSkin: 'default',
		prettyName: 'Stars',
		description: `Now you too can be a star`,
		rarity: ItemRarity.RARE
	},
	{
		skinIdentifier: 'player-trail-sushi-01',
		skinSlot: SKIN_SLOT.PLAYER_FOOTPRINT,
		spineJsonName: 'player-trail-sushi-01',
		spineSkin: 'default',
		prettyName: 'Conveyer Sushi',
		description: `Get your fill`,
		rarity: ItemRarity.LEGENDARY
	},
	{
		skinIdentifier: 'player-trail-watermelon-01',
		skinSlot: SKIN_SLOT.PLAYER_FOOTPRINT,
		spineJsonName: 'player-trail-watermellon-01',
		spineSkin: 'default',
		prettyName: 'Watermelons',
		description: `You'll lose your rind with this one`,
		rarity: ItemRarity.RARE
	},
	{
		skinIdentifier: 'player-trail-basic-01',
		skinSlot: SKIN_SLOT.PLAYER_FOOTPRINT,
		spineJsonName: 'player-trail-basic-01',
		spineSkin: 'default',
		prettyName: 'Basic Blue',
		description: `A basic blue trail`,
		rarity: ItemRarity.COMMON
	},
	{
		skinIdentifier: 'player-trail-basic-04',
		skinSlot: SKIN_SLOT.PLAYER_FOOTPRINT,
		spineJsonName: 'player-trail-basic-04',
		spineSkin: 'default',
		prettyName: 'Basic Neon Yellow',
		description: `A basic neon yellow trail`,
		rarity: ItemRarity.COMMON
	},
	{
		skinIdentifier: 'player-trail-basic-07',
		skinSlot: SKIN_SLOT.PLAYER_FOOTPRINT,
		spineJsonName: 'player-trail-basic-07',
		spineSkin: 'default',
		prettyName: 'Basic Red',
		description: `A basic red trail`,
		rarity: ItemRarity.COMMON
	},
	{
		skinIdentifier: 'player-trail-basic-08',
		skinSlot: SKIN_SLOT.PLAYER_FOOTPRINT,
		spineJsonName: 'player-trail-basic-08',
		spineSkin: 'default',
		prettyName: 'Basic Green',
		description: `A basic green trail`,
		rarity: ItemRarity.COMMON
	},
	{
		skinIdentifier: 'player-trail-basic-11',
		skinSlot: SKIN_SLOT.PLAYER_FOOTPRINT,
		spineJsonName: 'player-trail-basic-11',
		spineSkin: 'default',
		prettyName: 'Basic White',
		description: `A basic white trail`,
		rarity: ItemRarity.UNCOMMON
	},
	{
		skinIdentifier: 'player-trail-basic-12',
		skinSlot: SKIN_SLOT.PLAYER_FOOTPRINT,
		spineJsonName: 'player-trail-basic-12',
		spineSkin: 'default',
		prettyName: 'Basic Black',
		description: `A basic black trail`,
		rarity: ItemRarity.UNCOMMON
	},
]

const SkinInformationByIdentifier = new Map<string, PlayerSkinInformation>()

for (const skinInfo of PLAYER_SKIN_INFORMATIONS) {
	if(SkinInformationByIdentifier.get(skinInfo.skinIdentifier)) {
		console.error("Duplicate skin identifiers found for " + skinInfo.skinIdentifier)
		process.exit(1)
	}
	
	SkinInformationByIdentifier.set(skinInfo.skinIdentifier, skinInfo)
}

export { SkinInformationByIdentifier }

export const SKIN_SLOT_TO_SPINE_BONE = {
	[SKIN_SLOT.PLAYER_BACK]: 'back-cosmetics-attach',
	[SKIN_SLOT.PLAYER_FACE]: 'face-cosmetics-attach',
}
