































































































































import { mapGetters, mapMutations, mapActions, mapState } from 'vuex'
import CreateParty from './create-party.vue'
import PanelDivider from '../../reusable-components/panel-divider/panel-divider.vue'
import TextButton from '../../reusable-components/buttons/text-button.vue'
import TextInput from '../../reusable-components/inputs/text-input.vue'
import ArrowButton from '../../reusable-components/buttons/arrow-button.vue'
import PartyList from './departure-party-list.vue'
import PlayerStatIcons from '../../reusable-components/icons/icons.vue'
import SoulCycleProgressBar from '../../reusable-components/soul-cycle/soul-cycle-progress-bar.vue'
import { getBiomePrettyName } from '../../../biome/shared/biome-list'
import { GameModeType } from '../../../engine/shared/game-mode-type'
import { getDepartureText } from '../../reusable-components/v-tooltip/v-tooltip-functions'

import { MAX_ENABLED_DIFFICULTY, getWorldDifficultyPrettyString } from '../../../engine/shared/game-data/world-difficulty'
import { isOnSufficientDifficultyToProgress, PROGRESSION_MAX_TIER_FOR_BETA, maxAllowedWorldDifficultyFromMaxAllowedTier } from '../../../engine/shared/game-data/progression'

export default {
	name: 'Overview',
	data() {
		return {
			createPoloErrorsCodes: ['alreadyInParty', 'failedToCreateParty', 'defaultCreatePartyError'],
			joinPoloErrorsCodes: ['partyDoesNotExist', 'failedToJoinParty', 'defaultJoinPartyError', 'partyIsFull'],
		}
	},
	components: {
		PanelDivider,
		TextButton,
		TextInput,
		ArrowButton,
		CreateParty,
		PartyList,
		PlayerStatIcons,
		SoulCycleProgressBar,
	},
	props: {},
	computed: {
		...mapState('boatLaunch', ['ftueMode']),
		...mapGetters('miniPaperdoll', ['getMaxDps']),
		...mapGetters('party', ['getLeaderStatus', 'getCurrentlyPartiedStatus', 'getHasAdventureServer', 'getPartyMembers', 'getAllPartyMembersReady']),
		...mapGetters('boatLaunch', ['totalPointsSelected', 'totalPointsForNextWorldTier', 'maxAllowedTier', 'currentlySelectedWorldDifficulty', 'isValidPressureLoadout', 'currentlySelectedWorldTier', 'hasReachedBiomeOnTier', 'showSoulCycleContainer', 'getSelectedBiomeKey', 'isMeterFull']),
		...mapGetters('user', ['userId']),
		...mapGetters('hud', ['furthestBiome', 'furthestWorldTier']),
		maxUnlockedDifficulty() {
			return Math.min(MAX_ENABLED_DIFFICULTY, maxAllowedWorldDifficultyFromMaxAllowedTier(this.maxAllowedTier))
		},
		isMeterFull() {
			return this.totalPointsSelected >= this.totalPointsForNextWorldTier
		},
		maxTier() {
			return PROGRESSION_MAX_TIER_FOR_BETA
		},
		nextWorldTierMinLevel: function() {
			return Math.max(0, this.maxAllowedTier - 1) * 15 + 1
		},
		departureCheck() {
			if (this.getHasAdventureServer && this.isValidPressureLoadout) {
				return false
			}

			if (this.getSelectedBiomeKey === 0) {
				if (this.disableDepartButtonIfPartied(0) || this.ftueMode || !this.isValidPressureLoadout) {
					return true
				} else {
					return false
				}
			} else {
				if (this.disableDepartButtonIfPartied(this.getSelectedBiomeKey) || this.hasReachedBiomeOnTier(this.getSelectedBiomeKey, this.currentlySelectedWorldTier) === false || this.ftueMode || !this.isValidPressureLoadout) {
					return true
				} else {
					return false
				}
			}
		},
		getBiomeName() {
			return getBiomePrettyName(this.getSelectedBiomeKey, GameModeType.Adventure).replace('Carmine', '')
		},
		getWorldTierIcon() {
			const worldTierImgNumber = this.currentlySelectedWorldDifficulty === 1 ? this.currentlySelectedWorldDifficulty : (this.currentlySelectedWorldDifficulty - 1) * 3 + 1
			return worldTierImgNumber
		},
		departureDividerText() {
			if (this.getHasAdventureServer) {
				return `Departure - On Adventure`
			} else if (this.getCurrentlyPartiedStatus && this.getAllPartyMembersReady) {
				return `Departure - All <span style="color: #a6ff68;">&nbsp;Ready<span>`
			} else if (this.getCurrentlyPartiedStatus && !this.getAllPartyMembersReady) {
				return `Departure - Players <span style="color: #ff4e4e;">&nbsp;Not Ready<span>`
			} else {
				return `Departure`
			}
		},
	},
	methods: {
		...mapMutations('party', ['updatePartyId']),
		...mapActions('boatLaunch', ['goToAdventure']),
		getDepartureText,

		showJoinErrors() {
			return this.joinPoloErrorsCodes.includes(this.getErrors)
		},
		showCreateErrors() {
			return this.createPoloErrorsCodes.includes(this.getErrors)
		},
		isDifficultySufficientToProgress(difficulty) {
			return isOnSufficientDifficultyToProgress(difficulty, this.maxAllowedTier)
		},
		goOnAdventure() {
			this.goToAdventure({ biomeIdx: this.getSelectedBiomeKey, canProgress: this.isMeterFull && this.isDifficultySufficientToProgress(this.currentlySelectedWorldDifficulty) })
		},

		disableDepartButtonIfPartied(biomeButton) {
			const isUserLeader = this.getPartyMembers.find((user) => user.playerId === this.userId && user.leader === true)
			if (this.getCurrentlyPartiedStatus) {
				if (!isUserLeader) {
					return true
				}
				if (isUserLeader && biomeButton === 0) {
					if (this.getAllPartyMembersReady) {
						return false
					} else {
						return true
					}
				} else {
					return true
				}
			}
			return false
		},
		getDifficultyPrettyString(difficulty) {
			return getWorldDifficultyPrettyString(difficulty)
		},
	},
}
