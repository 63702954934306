































































import { mapGetters, mapMutations, mapActions, mapState } from 'vuex'
import ArrowButton from '../reusable-components/buttons/arrow-button.vue'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import PanelDivider from '../reusable-components/panel-divider/panel-divider.vue'
import ListErrors from '../reusable-components/inputs/list-errors.vue'
import Checkbox from '../reusable-components/inputs/checkbox.vue'
import { some, values } from 'lodash'

export default {
	name: 'AnonymousUser',
	components: {
		MainPanel,
		ArrowButton,
		PanelDivider,
		Checkbox,
		ListErrors,
	},
	data() {
		return {
			annonUser: {
				agreeToAge: false,
			},
		}
	},
	computed: {
		...mapGetters('mainMenu', ['loadingCurrentlyInProgress', 'loadedPercentage']),
		...mapGetters('user', ['getAgeErrors', 'userType']),
		...mapState('user', ['makingAnonUser']),
		panelHeightWith() {
			let height = 755
			if (this.getAgeErrors.length > 0) {
				height += 65
			}
			return height
		},
	},
	methods: {
		...mapActions('loginQueue', ['enterQueue']),
		...mapMutations('mainMenu', ['changeMainMenuScreen']),
		...mapMutations(['enterGame']),
		...mapMutations('user', ['updateAgeCheckError', 'resetUserErrors']),
		...mapActions('user', ['createAnonymousUserThenEnterQueue']),

		startGame() {
			this.resetUserErrors()

			let normalizedFormValues = {
				agreeToAge: this.annonUser.agreeToAge,
			}

			if (normalizedFormValues.agreeToAge !== true) {
				this.updateAgeCheckError(`You need to confirm that you're over the age of 16 or have consent`)
			}

			let normailizedErrorValues = {
				ageCheckError: this.getAgeErrors,
			}
			if (
				some(values(normailizedErrorValues), (v) => {
					return v.length !== 0
				})
			) {
				return
			}

			if(this.userType === 'none') {
				this.createAnonymousUserThenEnterQueue()
			} else {
				this.enterQueue()
			}
		},
	},
}
