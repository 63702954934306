import nengi from 'nengi'

export class SetServerValueCommand {
	static protocol = {
		objectName: nengi.String,
		dotNotation: nengi.String,
		value: nengi.String,
		valueType: nengi.String,
	}

	objectName: string
	dotNotation: string
	value: string
	valueType: string

	constructor(objName: string, dotNotation: string, value: any, valueType: string) {
		this.objectName = objName
		this.dotNotation = dotNotation
		this.value = value.toString()
		this.valueType = valueType
	}
}
