




































































































































































































































































// New and converted (or stubbed)
import EnergyBar from './hud/energy-bar.vue'
import HealthBar from './hud/health-bar.vue'
import Shields from './hud/shields.vue'
import BuffsAndDebuffs from './hud/buffs-and-debuffs.vue'
import GearAbilities from './hud/gear-abilities.vue'
import ActiveWeapons from './hud/active-weapons.vue'
import Chat from './hud/chat.vue'
import PartyMemberList from './hud/party-list.vue'
import MenuSwapper from './hud/menu-swapper.vue'
import OutpostWormMail from './panels/outpost-worm-mail.panel.vue'
import Tooltip from './reusable-components/inventory-item/tooltip.vue'
import EnchantmentBarTooltip from './reusable-components/inventory-item/enchantment-bar-tooltip.vue'
import Loading from './reusable-components/loading/loading-spinner.vue'
import LoadingTransition from './reusable-components/loading/loading-transition.vue'
import Inventory from './panels/inventory.panel.vue'
import DebugStats from './panels/debug-stats.vue'
import Stash from './panels/stash.panel.vue'
import HubWormDelivery from './panels/hub-worm-delivery.panel.vue'
import AugmentationStationUpdated from './panels/augmentation-station-updated.panel.vue'
import ShotCooldown from './hud/shot-cooldown.vue'
import PitOfChancesUpdated from './panels/pit-of-chances.panel.vue'
//
import Departure from './panels/departure.panel.vue'
import DepartureUpdate from './panels/departure/departure-panel.vue'
//
import LeaderboardUpdated from './panels/leaderboard.panel.vue'
import PlayerProfile from './panels/player-profile.panel.vue'
import Player from './panels/player/player.panel.vue'
import Customize from './panels/customize-player.panel.vue'
import MarketplaceUpdated from './panels/marketplace.panel.vue'
import UserMarketListing from './panels/marketplace-listing.panel.vue'
import GeneralStoreUpdated from './panels/general-store.panel.vue'
import PremiumStore from './panels/premium-store.panel.vue'
import NotLoggedIn from './panels/not-logged-in.panel.vue'
import FurnaceUpdate from './panels/furnace.panel.vue'
import EndRunUpdated from './panels/end-run.panel.vue'
import GameOver from './panels/game-over.panel.vue'
import WorldTierComplete from './panels/world-tier-complete.panel.vue'
import Error from './panels/error.panel.vue'
import NavigationHelper from './hud/navigation-helper.vue'
import PitOfChancesRewards from './panels/pit-of-chances-rewards.panel.vue'
import GenericYesNo from './panels/generic-yes-no.panel.vue'
import Rewards from './panels/rewards.panel.vue'
import EmailVerification from './panels/email-verification.panel.vue'
import GameSettings from './panels/setting.panel.vue'
import PaperDoll from './panels/paper-doll.panel.vue'
import ProgressionHUD from './panels/progression-hud.panel.vue'
import CurrencyHUD from './panels/currency-hud.panel.vue'
import WormDeliveryNotifier from './panels/worm-delivery-notifier.vue'
import PitRewardsNotifier from './panels/pit-rewards-notifier.vue'
import AddMarketplaceListing from './panels/marketplace-add-listing.panel.vue'
import PitOfChancesStorage from './panels/pit-of-chances-storage.panel.vue'
import ItemComparisonPanel from './panels/item-comparison.panel.vue'
import SkinPreviewPanel from './panels/skin-preview-panel.vue'
import Boss from './hud/boss.vue'
import PartyDepartureCountdown from './hud/party-departure-countdown.vue'
import InventoryBag from './hud/inventory.vue'
import VideoPlayer from './hud/video-player.vue'
import Subscription from './panels/subscription.panel.vue'
import GenericPrompt from './panels/generic-information-prompt.vue'
import ProximityMessage from './panels/proximity-message.panel.vue'
import MaintenanceMode from './panels/maintenance.vue'
import EnchantmentBar from './hud/enchantment.vue'
import ListItemPopUp from './panels/list-item-pop-up.panel.vue'
import FurnaceNotifier from './panels/furnace-notifier.vue'
import MtxItemDetails from './panels/mtx-item-details.panel.vue'
import FeaturedSales from './panels/featured-sales.panel.vue'
import DailyRewards from './panels/daily-login-rewards.vue'
import PartyPanel from './panels/party.panel.vue'
import ActivePartyPanel from './panels/active-party-panel.vue'
import DevTools from './dev-tools/dev-tools.vue'
import ItemTools from './dev-tools/item-tools.vue'
import CharacterSelection from './main-menu/character-selection.vue'
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex'
import WormHorn from './panels/worm-horn.panel.vue'
import FlyoutDeparture from './panels/flyout-departure.vue'
import FlyoutAugmentationStation from './panels/flyout-augmentation-station.vue'
import FlyoutPitOfChances from './panels/flyout-pit-of-chances.vue'
import BannerAlerts from './hud/banner-alerts.vue'
import StackableAmountPopUp from './panels/stackable-amount-pop-up.panel.vue'
import StormTimer from './panels/storm-timer-panel.vue'
import NotificationNotifier from './panels/notification-notifier.vue'
import FreeMarketplaceNotifier from './panels/free-marketplace-listing-notifier.vue'
import Faq from './panels/faq.panel.vue'
import NFTMap from './panels/nft-map.panel.vue'
import GenericRewards from './panels/generic-rewards-panel.vue'
import SlotAnimation from './reusable-components/inventory-item/slot-animation.vue'
import EmoteWheel from './hud/emote-wheel.vue'
import NotificationContainer from './panels/notification-container.vue'
import FactionAffiliation from './panels/faction-affiliation.panel.vue'
import FactionScoreboard from './panels/faction-scoreboard.panel.vue'
import FactionWinner from './panels/faction-winner-prompt.vue'
import EmoteCustomizationPanel from './panels/emote-customization.panel.vue'
import BiomeKey from './panels/biome-key.panel.vue'
import Toast from './hud/toast.vue'

//Main menu Screens
import Landing from './main-menu/landing.panel.vue'
import Login from './main-menu/login.panel.vue'
import LoggedIn from './main-menu/logged-in.panel.vue'
import AnonymousUser from './main-menu/anonymous-user.panel.vue'
import CreateAccount from './main-menu/create-account.panel.vue'
import Links from './main-menu/links.vue'
import SideMenu from './main-menu/side-menu-buttons.vue'
import GameNews from './panels/game-news.vue'
import StartUpError from './main-menu/startup-error.panel.vue'
import ForgotPassword from './main-menu/forgot-password.panel.vue'
import ResetPassword from './main-menu/reset-password.panel.vue'
import Enqueued from './main-menu/enqueued.panel.vue'

import TutorialTooltip from './reusable-components/tutorial-tooltip/tutorial-tooltip.vue'
import AdvancedTutorialTooltip from './reusable-components/tutorial-tooltip/advanced-tutorial-tooltip.vue'
import TutorialArrow from './reusable-components/tutorial-arrow/tutorial-arrow.vue'
import { playerPanelTabs } from './state/in-game.ui-state'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const gifs = require('../../assets/ui-assets/ftue/*.gif')

export default {
	name: 'UIOverlay',
	components: {
		// New and converted (or stubbed)
		SkinPreviewPanel,
		GameOver,
		WorldTierComplete,
		GameSettings,
		EnergyBar,
		HealthBar,
		Shields,
		BuffsAndDebuffs,
		GearAbilities,
		ActiveWeapons,
		Chat,
		MenuSwapper,
		NavigationHelper,
		OutpostWormMail,
		Tooltip,
		Inventory,
		DebugStats,
		HubWormDelivery,
		ProximityMessage,
		MaintenanceMode,
		TutorialTooltip,
		TutorialArrow,
		EnchantmentBar,
		EnchantmentBarTooltip,
		ItemTools,
		ListItemPopUp,
		AdvancedTutorialTooltip,
		FurnaceNotifier,
		FeaturedSales,
		DailyRewards,
		PartyMemberList,
		PartyDepartureCountdown,
		PartyPanel,
		Enqueued,
		ActivePartyPanel,
		WormHorn,
		BannerAlerts,
		StackableAmountPopUp,
		StormTimer,
		NotificationNotifier,
		FreeMarketplaceNotifier,
		EmoteWheel,
		NotificationContainer,
		FactionAffiliation,
		FactionScoreboard,
		FactionWinner,
		Toast,
		LoadingTransition,

		AugmentationStationUpdated,
		ShotCooldown,
		//
		Departure,
		DepartureUpdate,
		//
		PitOfChancesUpdated,
		PitOfChancesRewards,
		LeaderboardUpdated,
		PlayerProfile,
		Customize,
		UserMarketListing,
		MarketplaceUpdated,
		GeneralStoreUpdated,
		PremiumStore,
		NotLoggedIn,
		FurnaceUpdate,
		EndRunUpdated,
		EmailVerification,
		Rewards,
		Error,
		GenericYesNo,
		PaperDoll,
		ProgressionHUD,
		CurrencyHUD,
		AddMarketplaceListing,
		PitOfChancesStorage,
		GameNews,
		Boss,
		ItemComparisonPanel,
		InventoryBag,
		VideoPlayer,
		Subscription,
		GenericPrompt,
		GenericRewards,
		Stash,
		WormDeliveryNotifier,
		PitRewardsNotifier,
		MtxItemDetails,
		Faq,
		NFTMap,
		SlotAnimation,
		EmoteCustomizationPanel,
		Player,
		//FLyout Panels
		FlyoutAugmentationStation,
		FlyoutPitOfChances,
		FlyoutDeparture,
		BiomeKey,

		//Main Menu
		Loading,
		Landing,
		Login,
		LoggedIn,
		AnonymousUser,
		CreateAccount,
		SideMenu,
		Links,
		StartUpError,
		ForgotPassword,
		ResetPassword,
		CharacterSelection,

		// For development/debugging purposes
		DevTools,
	},
	data() {
		return {
			env: process.env.NODE_ENV,
			playerPanelTabs,
		}
	},
	computed: {
		...mapGetters(['currentUIMode', 'hideUI', 'showDevTools']),
		...mapGetters('mainMenu', ['currentMainMenuScreen']),
		...mapGetters('inGame', ['activePanel', 'currentGameMode', 'activeError', 'healthLow', 'flyoutPanel', 'ghostMode']),
		...mapGetters('errors', ['getErrorPromptVisible']),
		...mapGetters('mtxStore', ['getItemDetailsPrompt', 'getFeaturedSalesVisible']),
		...mapGetters('genericYesNo', ['getYesNoPromptVisible']),
		...mapGetters('genericPrompt', ['getPromptVisible', 'getPromptDimBackground']),
		...mapGetters('proximityMessage', ['getAnyVisible']),
		...mapGetters('itemContainers', ['containerIsEmpty']),
		...mapGetters('user', ['userType', 'userDataFetching', 'firstTimeLogIn']),
		...mapGetters('hud', ['playerWeaponOut', 'tutorialTooltips', 'partyDepartureCountdownVisible', 'alertVisible']),
		...mapGetters('UIScale', ['currentScale']),
		...mapGetters('loginQueue', ['currentlyInQueue']),
		...mapGetters(['maintenanceMode']),
		...mapGetters('ftueManager', ['startedSecondRun']),
		...mapGetters('tutTooltip', ['getAdvancedTooltipVisible']),
		...mapGetters('furnace', ['totalItemsInSmelter', 'furnacesAreCurrentlyFull']),
		...mapGetters('party', ['getCurrentlyPartiedStatus']),
		...mapGetters('outpostWormMail', ['getWormHornPrompt']),
		...mapGetters('activeGameWindows', ['isAGWEnabled']),
		...mapGetters('notifications', ['getNotificationCount']),
		...mapState('hud', ['tutorialArrows']),
		...mapState('marketplaceUpdated', ['showListItemPopUp', 'lastFreeMarketplaceDate']),
		...mapState('mainMenu', ['showStartupError']),
		...mapState('furnace', ['hasFurnaceNotInUse']),
		...mapState('stackableSelection', ['isStackableSelectionVisible']),
		...mapState('time', ['nowMoment']),
		...mapState('inGame', ['showNFTMap', 'playerTab', 'transitionOverlay']),
		...mapState('genericRewards', ['showGenericRewards']),
		...mapState('slotAnimation', ['showSlotAnimation']),
		...mapGetters('slotAnimation', ['getShowSlotAnimation']),
		...mapState('factions', ['showFactionRewardIntro']),

		showFreeListing() {
			if (this.lastFreeMarketplaceDate) {
				return !this.nowMoment.isSame(this.lastFreeMarketplaceDate, 'day')
			}
			return true
		},
	},
	watch: {
		currentUIMode(newValue) {
			if (newValue === 'in-game') {
				this.submitCampaignMetrics()
			}
		},
	},
	methods: {
		...mapMutations('hud', ['showTutorialTooltip']),
		...mapActions('user', ['submitCampaignMetrics']),

		testAddTooltip() {
			this.showTutorialTooltip({
				target: 'firstWeapon',
				text: 'Clicking on your weapon will show its stats.',
				title: 'Select Your Weapon',
				position: 'bottom',
			})
			this.showTutorialTooltip({
				target: 'shields',
				text: 'Each shield soaks 1 hit.',
				title: 'Shields',
				position: 'bottom',
			})

			this.showTutorialTooltip({
				target: 'health',
				text: '',
				title: 'Health Bar',
				position: 'bottom',
			})

			this.showTutorialTooltip({
				target: 'weaponsAndGear',
				text: '',
				title: 'Equipped Weapons & Gear',
				position: 'top',
			})
		},
		allFtueGifs: function () {
			return gifs
		},
	},
}
