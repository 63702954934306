import { NengiClient } from '../../engine/client/nengi-client'
import { AnonymousRegistrationResponseMessage } from '../../player/shared/anonymous-registration-response'
import { RegisterAnonymousPlayerCommand } from '../../player/shared/register-anonymous-player-command'
import logger from '../../utils/client-logger'
import { removeFromLocalStorage, saveToLocalStorage } from '../../utils/local-storage'

type MenuStates = 'main' | 'register'

export function notLoggedInUiState() {
	logger.debug('Initializing Not Logged In module:')

	return {
		namespaced: true,
		state: {
			menuState: 'main',
			registrationForm: {
				username: '',
				emailAddress: '',
				emailAddressConfirmation: '',
				password: '',
				passwordConfirmation: '',
				agreedToTerms: false,
				agreedToPromotion: true,
				agreedToage: false,
			},
			registrationErrors: {
				userName: [],
				password: [],
				agreeToTerms: [],
				email: [],
				registration: [],
				ageCheck: [],
			},
			registering: false,
		},
		getters: {
			isMain(state: any) {
				return state.menuState === 'main'
			},
			isRegister(state: any) {
				return state.menuState === 'register'
			},
			getUserNameError(state: any) {
				return state.registrationErrors.userName
			},
			getPasswordError(state: any) {
				return state.registrationErrors.password
			},
			getAgreeToTermsError(state: any) {
				return state.registrationErrors.agreeToTerms
			},
			getEmailError(state: any) {
				return state.registrationErrors.email
			},
			getRegistrationError(state: any) {
				return state.registrationErrors.registration
			},
			getAgeError(state: any) {
				return state.registrationErrors.ageCheck
			},
		},
		mutations: {
			changeMenuState(state: any, newState: MenuStates) {
				state.menuState = newState
			},
			updateRegistrationField(state: any, [fieldName, fieldValue]) {
				state.registrationForm[fieldName] = fieldValue
			},
			updateAgreeToPromotion(state: any, checkBoxState) {
				state.registrationForm.agreedToContact = checkBoxState
			},
			updateAgreeToTerms(state: any, checkBoxState) {
				state.registrationForm.agreedToTerms = checkBoxState
			},
			updateAgeCheckError(state: any, error) {
				state.registrationErrors.ageCheck.push(error)
			},
			clearAgeCheckError(state: any, error) {
				state.registrationErrors = { ageCheck: [] }
			},
			clearAllErrors(state: any) {
				state.registrationErrors = {
					userName: [],
					password: [],
					agreeToTerms: [],
					email: [],
					registration: [],
					ageCheck: []
				}
			},
			registrationResponse(state: any, response: AnonymousRegistrationResponseMessage) {
				if (response.status === 'error') {
					switch (response.errorCode) {
						case 'missingRequiredFields':
							break
						case 'emailAddressAlreadyTaken': {
							state.registrationErrors.email.push('This e-mail address is already registered.')
							break
						}
						case 'emailAndEmailConfirmationDontMatch': {
							state.registrationErrors.email.push('Email and e-mail confirmation do not match.')
							break
						}
						case 'emailDoesntSeemReal': {
							state.registrationErrors.email.push('Email seems a lil sus')
							break
						}
						case 'invalidEmailFormat': {
							state.registrationErrors.email.push('Email address doesn\'t match e-mail format')
							break
						}
						case 'invalidUsernameLength': {
							state.registrationErrors.userName.push('Invalid username length, must be between 3 and 14 characters')
							break
						}
						case 'itemPersistenceFailure': {
							state.registrationErrors.registration.push('Unable to register, please try again later')
							break
						}
						case 'missingRequiredFields': {
							state.registrationErrors.registration.push('Missing required fields')
							break
						}
						case 'mustAgreeToTermsOfService': {
							state.registrationErrors.agreeToTerms.push('Must agree to Terms of Service to register.')
							break
						}
						case 'noUserReturnedAfterCreation': {
							state.registrationErrors.registration.push('Unable to register, please try again later')
							break
						}
						case 'passwordAndPasswordConfirmationDontMatch': {
							state.registrationErrors.password.push('Password and password confirmation do not match.')
							break
						}
						case 'invalidPasswordLength': {
							state.registrationErrors.password.push('Your Password must be between 10 and 64 characters in length.')
							break
						}
						case 'usernameAlreadyTaken': {
							state.registrationErrors.userName.push('User name is already taken.')
							break
						}
					}
				} else {
					saveToLocalStorage('auth-token', response.authToken)
					removeFromLocalStorage('anonymous-user-id')
				}

				state.registering = false
			},
		},
		actions: {
			registerPlayer({ commit, state }, recaptchaPayload) {
				// Clear errors
				state.registrationErrors = {
					userName: [],
					password: [],
					agreeToTerms: [],
					email: [],
					registration: [],
					ageCheck: [],
				}
				state.registering = true
				NengiClient.getInstance().sendCommand(new RegisterAnonymousPlayerCommand(state.registrationForm, recaptchaPayload))
			},
			clearErrors({commit}) {
				commit('clearAllErrors')
			}
		},
	}
}
