export enum StackStyle {
	/** Buff cannot stack, further applications are ignored. */
	None,
	/** Duration of the existing buff refreshes up to the max of (currentDuration, reapplyDuration), and adds the reapplyStacks. */
	RefreshDuration,
	/** Duration of the existing buff is increased by the reapplyDuration, and adds the reapplyStacks. */
	IncreaseDuration,
	/** Adds the reapplyStacks with their own separate duration that is tracked independently, allowing stacks to fall-off independently. */
	RollingStackDurationSeparately,
}

/** When applying this buff, should it be instanced separately based on owner for a certain kind of entity? */
export enum InstancedPer {
	/** The default option, no special instancing, only one buff per entity */
	None,
	Player,
	Enemy,
	Everything,
}

