<template>
	<div
		id="proximity-message"
		:style="{
			transform: `perspective(1px) translateZ(0) scale(${currentScale}) rotate3d(0,1,0, 0.00000000001deg)`,
			'transform-origin': 'bottom left',
			width: '550px',
		}"
	>
		<div>
			<img id="base-image" src="../reusable-components/panels/assets/panel-badge-help.png" />
			<div id="proximity-message-border-image">
				<div id="proximity-message-contents">
					<div class="title">
						<p class="proximity-title-text">
							{{ getTitle }}
						</p>
					</div>
					<div class="description">
						<!-- eslint-disable-next-line vue/no-v-html -->
						<div v-for="name in getDescription" :key="name" class="proximity-message-text" v-html="name"></div>
					</div>
					<div v-show="getPromptVisible">
						<TextButton :button-label="getOkButtonText" :button-style="'default'" :on-click="closePromptPanel" class="item-button"></TextButton>
					</div>
				</div>
				<img id="tail-image" src="./assets/tutorial-bubble-tail.png" />
			</div>
		</div>
	</div>
</template>

<script lang="js">
import { mapGetters, mapMutations, mapActions } from 'vuex'
import TextButton from '../reusable-components/buttons/text-button.vue'

export default {
	name: 'ProximityMessage',
	components: {
		TextButton
	},
	computed: {
		...mapGetters('proximityMessage', ['getTitle', 'getDescription', 'getPromptVisible', 'getOkButtonText']),
		...mapGetters('UIScale', ['currentScale']),
	},
	methods: {
		...mapActions('proximityMessage', ['closePromptPanel'])
	}
}
</script>

<style lang="less" scoped>
@import url('../global/fonts/text.less');

#proximity-message-contents {
	pointer-events: all;
	padding: 0px 10px 0px 10px;
	text-align: center;
	max-width: 550px;
	perspective: 1px;

	.title {
		margin: -15px;
		.proximity-title-text();
	}

	.description {
		.proximity-message-text();
	}

	.item-button {
		display: inline-block;
		margin: 0px 0px -18px 0px;
		animation: glowing 2000ms infinite;
		.button-text {
			position: relative;
			top: -2px;
		}
	}
}

#base-image {
	position: absolute;

	height: 100px;
	width: 100px;

	left: 20px;
	bottom: 595px;
}
#tail-image {
	position: relative;
	margin: -60px;
	bottom: 25px;
	left: 150px;
}

#proximity-message-border-image {
	position: absolute;
	bottom: 690px;
	left: 20px;

	border-image-slice: 15 20 15 19 fill;
	border-image-width: 13px 13px 13px 13px;
	border-image-outset: 0px 0px 0px 0px;
	border-image-repeat: stretch stretch;
	border-image-source: url('./assets/tutorial-bubble.png');
}

@keyframes glowing {
	0% {
		filter: drop-shadow(0px 0px -10px #1dd2ff);
	}
	50% {
		filter: drop-shadow(0px 0px 20px #1dd2ff);
	}
	55% {
		filter: drop-shadow(0px 0px 20px #1dd2ff);
	}
	100% {
		filter: drop-shadow(0px 0px -10px #1dd2ff);
	}
}
</style>
