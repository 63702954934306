import { EnemyAI, FightingTriggers, IdleBehaviours, FightingBehaviours, LeashTriggers, LeashingBehaviours, AttackTypes, DeadBehaviours, ShotLeadPrecision, EnemyType, EnemyTargetingStyles } from '../ai-types'
import { BurstFireModes, ModType, ModValueType } from '../../../projectiles/shared/projectile-types'
import { SpineDataName, EnemyBipedSkinType } from '../../../models-animations/shared/spine-config'
import { degToRad } from '../../../utils/math'
import { EnemyDefaults } from './enemy-defaults'
import { Colors } from '../../../utils/colors'
import { ParticleEffectType } from '../../../engine/shared/game-data/particle-config'
import { Vector } from 'sat'
import { ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { ENEMY_DEFAULT_HEALTH } from '../../../engine/shared/game-data/enemy-formulas'
import { Ability, AbilitySelectionStyles, AbilityTargets, AbilityTargetSelectionStyle, AbilityType } from '../action-types'
import { alwaysTrueCriteria } from './abilities/common-abilities'
import { attackRatedAbilityList } from '../action-criteria/ability-helpers'
import { AnimationTrack } from '../../../models-animations/shared/animation-track'
import { ENEMY_NAMES } from './enemy-names'
import { worldDifficultyBrutalCriteria,  } from '../action-criteria/action-criteria-helpers'

const dreamerShootAbility: Ability = {
	debugName: 'shoot',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		speed: 500,
		colliderRadius: 20,
		lifespanInSeconds: 6,
		baseLightningDamage: 40,
		burstCount: 5,
		burstDelay: 0.15,
		burstMode: BurstFireModes.STRAIGHT,
		burstAngle: degToRad(40),
		spreadVariance: degToRad(40),
		color: Colors.green,
		modifiers: [
			{
				modType: ModType.ABS_ACCELERATION,
				value: { modValueType: ModValueType.LIFETIME, min: 2, max: 2.5 },
			},
		],
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_DREAMER,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_LIGHTNING_TRAIL,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_LIGHTNING_SHOOT,
		// applyBuffsOnHit: [BuffIdentifier.EnemyDreamerArmorBreak], < PENDING the implementation of proper player armor, that is why this isn't working, wow, brain dead.
	},

	attackOffset: new Vector(0, -80),
	ignoreAngleOnWeaponOffset: true,
}

const bigBoomShotAbility: Ability = {
	debugName: 'bigboomShot',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		projectileCount: 1,
		speed: 300,
		colliderRadius: 40,
		lifespanInSeconds: 1.5,
		modifiers: [{ modType: ModType.ACCELERATION, value: { modValueType: ModValueType.LIFETIME, min: -3, max: 16 } }],
		baseFireDamage: 120,
		baseLightningDamage: 120,
		splashDamage: 0.416,
		splashRadius: 150,
		burstCount: 0,
		pierceCount: 5,
		color: Colors.purple,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_DREAMER,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_LIGHTNING_TRAIL,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_FIRE_SHOOT,
	},

	attackOffset: new Vector(0, -80),
	ignoreAngleOnWeaponOffset: true,
}

const animationTimes = {
	[AnimationTrack.SHOOT]: 0.3, // PLACEHOLDER
}

const dreamerPrism: EnemyAI = {
	name: ENEMY_NAMES.DREAMER_PRISM,
	type: EnemyType.BASIC,
	appearance: {
		asset: SpineDataName.DREAMER_PRISM,
		skin: EnemyBipedSkinType.DEFAULT,
		mixSettings: [],
	},
	general: {
		enemyLevel: 1,
		experienceWhenKilled: 0.35,
	},
	baseAttributes: {
		...EnemyDefaults.baseAttributes,
		visibilityRadius: 1,
		colliders: [
			{
				type: ColliderType.Ellipse,
				rX: 45,
				rY: 65,
				position: [0, -45],
				traits: ColliderTraits.BlockMovementAndProjectile,
			},
		],
		movementSpeed: 200,
		decelerationRate: 10,
		turningRatePerSecondInDegrees: 300,
		attackOffset: new Vector(0, 0),
		lootDropOffset: new Vector(0, 0),
		damageConfig: null,

		physicalDamage: 0,
		fireDamage: 0,
		iceDamage: 0,
		lightningDamage: 0,
		poisonDamage: 0,
		critChance: 0,
		critDamage: 1.0,

		maxHealth: ENEMY_DEFAULT_HEALTH * 2,
		defense: 0,
		fireResist: 0,
		poisonResist: 0,
		iceResist: 0,
		lightningResist: 0,

		lootDropProbability: 1,
		lootDropQuantity: 2,
		lootDropRarity: 1,
	},
	soundEffects: {
		attack: 'Projectile_Zap',
		impact: 'SFX_Impact_Enemy_Mush',
	},
	states: {
		idling: {
			behaviour: IdleBehaviours.STATIONARY,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 2,
			transitionToFighting: {
				trigger: FightingTriggers.AGGRESSIVE_PROXIMITY,
				radius: 1100,
			},
			meander: {
				radius: 500,
				speedMultiplier: 1,
				minRestTime: 0.5,
				maxRestTime: 10,
			},
			aggroOnHit: true,
		},
		fighting: {
			behaviour: FightingBehaviours.STRAFE_WHILE_ATTACKING,
			targetingStyle: EnemyTargetingStyles.HIGH_HEALTH,
			brain: {
				actions: [
					{
						// ATTACK
						priority: 1,
						actionCriteria: worldDifficultyBrutalCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
							//abilityOptions: [[0, dreamerShootAbility, waitAbility(() => randomRange(30, 40))]],
							abilityOptions: [
								attackRatedAbilityList(5, animationTimes, AnimationTrack.SHOOT, dreamerShootAbility, 20), 
								attackRatedAbilityList(3, animationTimes, AnimationTrack.SHOOT, bigBoomShotAbility, 35),
								attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, bigBoomShotAbility, 15),
							],
						},
					},
					{
						// ATTACK
						priority: 2,
						actionCriteria: alwaysTrueCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
							//abilityOptions: [[0, dreamerShootAbility, waitAbility(() => randomRange(30, 40))]],
							abilityOptions: [
								attackRatedAbilityList(2, animationTimes, AnimationTrack.SHOOT, dreamerShootAbility, 30), 
								attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, bigBoomShotAbility, 50)],
						},
					},
				],
			},
			targetingCheckSeconds: 0,
			attackType: AttackTypes.PROJECTILE,
			engagementMaxDistance: 1200,
			engagementMinDistance: 0,
			modelCenterOffset: 0,
			movementMaxDistance: 600,
			movementMinDistance: 0,
			attackOptions: [],
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 0.1,
			transitionToLeashing: {
				trigger: LeashTriggers.RADIUS_EXCEEDED,
				radius: 2000,
				distanceTravelled: 4000,
			},
		},
		leashing: {
			behaviour: LeashingBehaviours.MOVE,
			speedMultiplier: 4,
		},
		fleeing: {},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 1,
		},
	},
}

export default dreamerPrism
