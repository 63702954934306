






export default {
	name: 'Range',
	props: {
		labelId: {
			type: String,
			required: false,
			default: 'default',
		},
		min: {
			type: String,
			required: false,
			default: 'default',
		},
		max: {
			type: String,
			required: false,
			default: 'default',
		},
		value: {
			type: String,
			required: false,
			default: '',
		},
	},
	data() {
		return {
			rangeInput: this.value,
		}
	},
	methods: {
		onChange() {
			this.$emit('update-range-input', this.rangeInput)
		},
	},
}
