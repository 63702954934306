import nengi from 'nengi'
import { jsonString } from '../../utils/primitive-types'

class UserListingsMetadataMessage {
	static protocol = {
		listings: { type: nengi.String },
	}

	listings: jsonString

	constructor(itemListings) {
		this.listings = JSON.stringify(itemListings)
	}
}

export default UserListingsMetadataMessage
