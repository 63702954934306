





















































import { mapActions, mapState, mapGetters } from 'vuex'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import PanelDivider from '../reusable-components/panel-divider/panel-divider.vue'
import ArrowButton from '../reusable-components/buttons/arrow-button.vue'
import CategoryFilter from '../reusable-components/filtering/category-filter.vue'
import TableData from '../reusable-components/detailed-item-view/table-data.vue'
import { startGame, endGame } from '../../engine/client/start-stop'
import { GameClient } from '../../engine/client/game-client'
import { NengiClient } from '../../engine/client/nengi-client'
import PartyExitGameCommand from '../../party-play/shared/party-exit-game-command'
import { UI } from '../ui'
import FactionTableView from '../reusable-components/faction/faction-table-data.vue'
import factionProgressBar from '../reusable-components/faction/faction-progress-bar.vue'
import SlotAnimation from '../reusable-components/inventory-item/slot-animation.vue'


export default {
	name: 'GameOver',
	components: {
		MainPanel,
		PanelDivider,
		ArrowButton,
		//InventoryItem,
		//GridItemContainer,
		TableData,
		CategoryFilter,
		FactionTableView,
		factionProgressBar,
		SlotAnimation
	},
	computed: {
		...mapState('gameOver', ['runStats', 'activeGameOverFilter']),
		...mapGetters('ftueManager', ['getFlag']),
		...mapGetters('inGame', ['activePanel']),
		...mapGetters('gameOver', ['getRunStats', 'getDamageTakenStats']),
		...mapGetters('party', ['getPartyId']),
		...mapState('factions', ['playerFactionPoints', 'affiliatedFaction']),
		...mapState('slotAnimation', ['showSlotAnimation']),
		returnToTownText() {
			const doneFirstTutorial = this.getFlag('openedWormMailNPC')
			if (doneFirstTutorial) {
				return 'Return to Town!'
			} else {
				return 'Retry Tutorial'
			}
		},
	},
	methods: {
		...mapActions('inGame', ['closeActivePanel', 'setPanelSwitchable']),
		goToHub: function() {
			const partyId = this.getPartyId
			if (partyId) {
				UI.getInstance().emitAsyncEvent('genericYesNo/showMenu', {
					title: 'Abandon your allies?',
					description: 'If your friends are nearby, they may yet be able to revive you. Are you sure you want to leave for the Hub?',
					noButtonText: 'Leave',
					yesButtonText: 'Stay',
					yesCallback: () => {},
					noCallback: () => {
						GameClient.getInstance().exitGameFunction = () => {
							this.setPanelSwitchable(true)
							endGame()
							// @ts-expect-error TODO2: store this not on the window
							startGame('hub', window.originalConnectionPayload, partyId)
						}

						NengiClient.getInstance().sendCommand(new PartyExitGameCommand(partyId))
					},
				})
			} else {
				this.setPanelSwitchable(true)
				endGame()
				// @ts-expect-error TODO2: store this not on the window
				startGame('hub', window.originalConnectionPayload)
			}
		},
	},
}
