import logger from '../../utils/client-logger'
import { highResolutionTimestamp } from '../../utils/debug'
import { NengiClient } from '../../engine/client/nengi-client'
import ClaimRewardCommand from '../../items/shared/claim-reward-command'
import { REWARD_LIFETIME_IN_SECONDS } from '../../engine/shared/game-data/rewards'
import { UI } from '../../ui/ui'
import { timeInMilliseconds, timeInSeconds } from '../../utils/primitive-types'

interface PoiBossRewardsUIState {
	rewardId: any
	rewards: any[]
	message: any
	timer: timeInSeconds
	timerInterval: any
	popTime: timeInMilliseconds
}

export const poiBossRewardsUIState = () => {
	logger.debug('Initializing rewards module...')
	return {
		namespaced: true,
		state: {
			rewards: [],
			popTime: -1,
			timer: REWARD_LIFETIME_IN_SECONDS,
			timerInterval: null,
			rewardId: null,
			message: '',
		} as PoiBossRewardsUIState,
		getters: {
			// not used: commenting out to be clear
			// timeToExpire(state: PoiBossRewardsUIState) {
			// 	state.timer = REWARD_LIFETIME_IN_SECONDS - Math.floor((highResolutionTimestamp() - state.popTime) / 1000)
			// 	return state.timer
			// },
			selectedReward(state: PoiBossRewardsUIState) {
				return state.rewardId
			},
			applyRewardButton(state: PoiBossRewardsUIState) {
				return state.rewardId !== null
			},
		},
		actions: {
			applyEnchantment(context) {
				const { state } = context as { state: PoiBossRewardsUIState }
				const selectedRewardId = state.rewardId
				if (selectedRewardId) {
					NengiClient.getInstance().sendCommand(new ClaimRewardCommand(selectedRewardId))
					UI.getInstance().emitEvent('inGame/setPanelSwitchable', true)
					UI.getInstance().emitAsyncEvent('inGame/closeActivePanel')
					clearInterval(state.timerInterval)
				}
			},

			//TODO2: Replace With Properfunctionality
			toggleSelectedReward(context, rewardData?: any) {
				const { state } = context

				if (state.rewardId === rewardData) {
					state.rewardId = null
				} else {
					state.rewardId = rewardData
				}
			},
		},
		mutations: {
			rewardsUpdated(state: PoiBossRewardsUIState, params) {
				const { message, items } = params
				state.timer = REWARD_LIFETIME_IN_SECONDS
				state.rewardId = null
				state.message = message
				state.rewards = items
				state.popTime = highResolutionTimestamp()
				UI.getInstance().emitEvent('inGame/setActivePanel', 'rewardSelection')
				UI.getInstance().emitEvent('inGame/setPanelSwitchable', false)
				if (state.timerInterval) {
					clearInterval(state.timerInterval)
				}
				state.timerInterval = setInterval(() => {
					if (state.timer <= 0) {
						clearInterval(state.timerInterval)
						if (UI.getInstance().store.getters['inGame/activePanel'] === 'rewardSelection') {
							UI.getInstance().emitEvent('inGame/setPanelSwitchable', true)
							UI.getInstance().emitAsyncEvent('inGame/closeActivePanel')
						}
					}

					state.timer -= 1
				}, 1000)
			},
			updateTimer(state) {
				state.timer -= 1
			},
		},
	}
}
