import nengi from 'nengi'
import { nengiId } from '../../utils/primitive-types'

class TeleportVisualsMessage {
	static protocol = {
		entityId: { type: nengi.UInt16 },
		x: { type: nengi.Float32 },
		y: { type: nengi.Float32 },
	}

	entityId: nengiId
	x: number
	y: number

	constructor(id: nengiId, x: number, y: number) {
		this.entityId = id
		this.x = x
		this.y = y
	}
}

export default TeleportVisualsMessage
