













import { mapGetters, mapState } from 'vuex'

export default {
	name: 'Shields',
	data: function () {
		return {
			shieldIndicatorTimer: null,
			showFlairOnSlot: null,
			flairToShowClass: null,
			interval: null
		}
	},
	computed: {
		...mapState('hud', ['maxShields', 'currentShields', 'shieldRegenPercent', 'shieldRegenRate']),
		...mapGetters('itemContainers', ['standardWormHornCount', 'premiumWormHornCount']),
	},
	mounted() {
		if(!this.interval) {
			this.interval = setInterval(() => {
				if (this.currentShields > 0 && Math.random() <= 0.3) {
					this.showFlairOnSlot = Math.getRandomInt(1, this.currentShields)
					this.flairToShowClass = `anim-${Math.getRandomInt(1, 4)}`
					setTimeout(() => {
						this.showFlairOnSlot = null
						this.flairToShowClass = null
					}, 9 * 16.6666 * 3)
				} else if (this.currentShields === 0) {
					this.showFlairOnSlot = null
					this.flairToShowClass = null
				}
			}, 1000)
		}
	},
	beforeDestroy() {
		if(this.interval) {
			clearInterval(this.interval)
		}
	},
	methods: {
		getAnimationStyle: function () {
			if (this.shieldRegenRate) {
				return {
					opacity: `${this.shieldRegenPercent}`,
					color: 'transparent',
				}
			} else {
				return {}
			}
		},
	},
}
