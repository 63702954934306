import nengi from 'nengi'
import { DrawShapeBase } from './draw-shape-base'

class DrawCircle extends DrawShapeBase {
	static protocol = {
		...DrawShapeBase.protocol,
		x: nengi.Number,
		y: nengi.Number,
		radius: nengi.Number,
	}

	x: number
	y: number
	radius: number

	constructor(x: number, y: number, radius: number, color: number, permanent, destroyAfterSeconds, scale: number) {
		super(color, permanent, destroyAfterSeconds, scale)
		this.x = x
		this.y = y
		this.radius = radius
	}
}

export default DrawCircle
