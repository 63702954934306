















































































































import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import PanelDivider from '../reusable-components/panel-divider/panel-divider.vue'
import PlayerPortrait from '../reusable-components/player-profile/player-portrait.vue'
import ArrowButton from '../reusable-components/buttons/arrow-button.vue'
import { FactionShortName, FactionShortNameToFullName, factionActions, factionBonus, factionMembers } from '../../factions/shared/faction-data'
import { joinFactionText } from '../reusable-components/v-tooltip/v-tooltip-functions'

const flagImages = require('./assets/*flag.png')
const numberImages = require('./assets/number-*.png')
const barImages = require('./assets/*_comparison_bar.png')

export default {
	name: 'FactionAffiliation',
	components: {
		MainPanel,
		PanelDivider,
		PlayerPortrait,
		ArrowButton,
	},
	data() {
		return {
			fullBarWidth: 182,
			barContainerWidthOffset: 8,
		}
	},
	computed: {
		...mapState('user', ['userType']),
		...mapState('factions', ['currentRecruitPanel', 'affiliatedFaction', 'affiliationMoment']),
		...mapGetters('factions', ['factionTotalPoints', 'factionMemberCounts', 'currentlyWinningFaction', 'factionMemberCounts', 'lastFactionWarEndMoment', 'factionMemberCountsPercentage']),
		...mapGetters('i18n', ['t']),
		panelIdentifier() {
			switch (this.currentRecruitPanel) {
				case FactionShortName.ORDER_OF_IRON:
					return 'ironRecruit'
				case FactionShortName.AURUM_ALLIANCE:
					return 'aurumRecruit'
				case FactionShortName.SCIONS_OF_DAWN:
					return 'dawnRecruit'
			}

			return 'dawnRecruit'
		},
		isLeadingFaction() {
			return this.currentRecruitPanel === this.currentlyWinningFaction
		},
		factionFullName() {
			return FactionShortNameToFullName.get(this.currentRecruitPanel)
		},
		factionFlagUrl() {
			switch (this.currentRecruitPanel) {
				case FactionShortName.ORDER_OF_IRON:
					return flagImages['iron-']
				case FactionShortName.AURUM_ALLIANCE:
					return flagImages['aurum-']
				case FactionShortName.SCIONS_OF_DAWN:
					return flagImages['scion-']
			}

			return flagImages['scion-']
		},
		factionPlacementUrl() {
			// what place is this faction in?
			let place = 3
			if (this.isLeadingFaction) {
				place = 1
			} else {
				for (const faction in this.factionTotalPoints) {
					if (Number.isFinite(this.factionTotalPoints[faction]) && faction !== this.currentRecruitPanel) {
						if (this.factionTotalPoints[faction] < this.factionPoints) {
							place = 2
							break
						}
					}
				}
			}

			return numberImages[place]
		},
		factionPoints() {
			return this.factionTotalPoints[this.currentRecruitPanel]
		},
		ourFactionBarWidth() {
			const ourPoints = this.factionPoints
			const leadingPoints = this.factionTotalPoints[this.currentlyWinningFaction]

			if (leadingPoints !== 0) {
				const percentage = ourPoints / leadingPoints
				return this.fullBarWidth * percentage
			}
			return this.fullBarWidth
		},
		isOurFaction() {
			return this.affiliatedFaction === this.currentRecruitPanel
		},
		canJoinFaction() {
			return !this.isOurFaction && (!this.affiliationMoment || this.affiliationMoment.isBefore(this.lastFactionWarEndMoment))
		},
		factionInfoBlurb() {
			switch (this.currentRecruitPanel) {
				case FactionShortName.ORDER_OF_IRON:
					return this.t('ironRecruitBlurb')
				case FactionShortName.AURUM_ALLIANCE:
					return this.t('aurumRecruitBlurb')
				case FactionShortName.SCIONS_OF_DAWN:
					return this.t('dawnRecruitBlurb')
			}
			return 'Something went wrong'
		},
	},
	methods: {
		...mapActions('factions', ['leaveFaction', 'joinFaction']),
		...mapActions('inGame', ['closeActivePanel']),
		...mapMutations('inGame', ['setActivePanel']),
		joinFactionText,
		factionActions,
		factionBonus,
		factionMembers,
		getFactionComparisonBarUrl(faction) {
			switch (faction) {
				case FactionShortName.ORDER_OF_IRON:
					return barImages['iron']
				case FactionShortName.AURUM_ALLIANCE:
					return barImages['aurum']
				case FactionShortName.SCIONS_OF_DAWN:
					return barImages['scion']
			}
			return barImages[faction]
		},
		joinClicked() {
			if(this.userType === 'anonymous') {
				this.setActivePanel('notLoggedIn')
			} else {
				if(!this.isOurFaction) {
					this.joinFaction(this.currentRecruitPanel)
				}
			}
		},
	},
}
