import { throwIfFalsy } from '../../../utils/math'

export enum WorldDifficulty {
	Hub = 0, // TODO2: pdd: polo assigns 0 for hub, could it be 1/Easy to simplify code?
	Normal = 1,
	Hard = 2,
	Brutal = 3,
	Nasty = 4,
}

export const MAX_ENABLED_DIFFICULTY = (process.env.NODE_ENV === 'loot-prod' || process.env.NODE_ENV === 'staging') ? WorldDifficulty.Hard : WorldDifficulty.Brutal

export function isDifficultyEnabled(worldDifficulty: WorldDifficulty) {
	return worldDifficulty <= MAX_ENABLED_DIFFICULTY
}

//** we have 4 difficulties, but this clamps to normal/hard until we release the rest (brutal/nasty) */
export function clampDifficultyToEnabled(worldDifficulty: WorldDifficulty) {
	return Math.clamp(worldDifficulty, WorldDifficulty.Hub, MAX_ENABLED_DIFFICULTY)
}

export function isValidWorldDifficulty(worldDifficulty: WorldDifficulty) {
	return worldDifficulty >= WorldDifficulty.Normal && worldDifficulty <= WorldDifficulty.Nasty
}

export function throwIfInvalidWorldDifficulty(worldDifficulty: WorldDifficulty) {
	throwIfFalsy(isValidWorldDifficulty(worldDifficulty), `bad WorldDifficulty of ${worldDifficulty}`)
}

export function getWorldDifficultyPrettyString(worldDifficulty: WorldDifficulty) {
	const difficultyStrings = ['Normal', 'Hard', 'Brutal', 'Nasty']
	const idx = worldDifficulty - 1
	return difficultyStrings[idx]
}
