import { WeaponSubTypeToResourceMap } from '../../loot/shared/weapon-sub-type'
import { Vectors } from '../../utils/math'
import { BuffIdentifier } from '../../buffs/shared/buff.shared'
import { IProjectileShooter, ProjectileConfig } from '../../projectiles/shared/projectile-types'
import { ClientPlayer } from './player.client'
import PlayerInput from '../../input/shared/player-input'
import { timeInSeconds } from '../../utils/primitive-types'
import { getAimOffsetFromSkin, getWeaponOffsetFromSkin, PLAYER_ENERGY_REGEN_DELAY_SECONDS } from '../../engine/shared/game-data/player'
import WeaponFiredMessage from '../../projectiles/shared/weapon-fired-message'
import { ResourceBarMode } from '../../loot/shared/resource-bar-mode'
import { IEnergyUser, updateEnergy, WeaponSlotKey } from '../shared/player-functions'
import { clientDebug } from '../../debug/client/debug-client'
import { throttle } from 'lodash'
import { UI } from '../../ui/ui'
import { ClientBuff } from '../../buffs/client/buff.client'

export class ClientPlayerProjectileShooter implements IProjectileShooter, IEnergyUser {
	private player: ClientPlayer

	// methods passed directly from player
	get nid() { return this.player.nid }
	get x() { return this.player.x }
	get y() { return this.player.y }
	get position() { return this.player.visualPos }
	get currentAttackCooldown() { return this.player.currentAttackCooldown }
	set currentAttackCooldown(value) { this.player.currentAttackCooldown = value }
	get inSafeZone() { return this.player.inSafeZone }
	isInSafeZone() { return this.inSafeZone }
	get isGhost() { return this.player.isGhost }
	get weaponOut() { return this.player.weaponOut }
	get aimVector() { return this.player.aimVector }
	get aimAngle() { return this.player.aimAngle }
	get mainDamageType() { return this.player.mainDamageType }

	energyRegenDelay: number = 0
	wasShooting: boolean
	ignoreAngleOnWeaponOffset?: boolean
	flipNextShot?: boolean

	madeProjectile(proj: any): void {
		// only used for projectile pool cleanup on server
	}

	get currentEnergy() { return this.player.currentEnergy }

	get resourceBarMode() {
		const currentWeapon = this.getCurrentWeapon()
		const weaponSubType = currentWeapon.itemSubType
		return WeaponSubTypeToResourceMap.get(weaponSubType)
	}

	weaponSlots = {
		one: {
			statList: {
				currentEnergy: 100,
				isOnCooldown: false
			},
			item: {
				resourceBarMode: ResourceBarMode.CHARGE_UP_SHOT
			},
		},
		two: {
			statList: {
				currentEnergy: 100,
				isOnCooldown: false
			},
			item: {
				resourceBarMode: ResourceBarMode.CHARGE_UP_SHOT
			},
		},
	}

	weaponOffset: any;

	getCurrentWeapon() { return { itemSubType: this.player.weaponSubType } }
	get activeWeaponSlot(): WeaponSlotKey { return this.player.activeWeaponSlot }

	useFixedAimVector = false;
	get fixedAimVector() { return Vectors.One }
	getStat(stat: string): number {
		return this.player.myPlayerData.getStat(stat)
	}
	
	get binaryFlagMap() {
		return this.player.myPlayerData.binaryFlagMap
	}

	hasMouseUp = false;
	isPlayer = true;
	isCharging = false;
	shooting = false;
	isDead(): boolean { return this.player.isDead() }

	get maxEnergy() { return this.player.maxEnergy }
	get maxEnergyOne() { return this.player.maxEnergyOne }
	get maxEnergyTwo() { return this.player.maxEnergyTwo }

	private _oldResourceBarMode = undefined

	constructor(player: ClientPlayer) {
		this.player = player
	}

	getPlayer() {
		return this.player
	}

	update(delta: timeInSeconds) {
		//this.updateDebug()

		const curWeapon = this.weaponSlots[this.activeWeaponSlot]

		if (this.resourceBarMode !== this._oldResourceBarMode) {
			this._oldResourceBarMode = this.resourceBarMode

			if (this.resourceBarMode === ResourceBarMode.CHARGE_UP_SHOT) {
				curWeapon.statList.currentEnergy = 1
			}
		}

		curWeapon.item.resourceBarMode = this.resourceBarMode

		updateEnergy(this, delta)

		const percentageOfMaxEnergy = curWeapon.statList.currentEnergy / this.maxEnergy
		throttledEnergyPercentageEventEmitter(percentageOfMaxEnergy)
	}

	updateDebug() {
		const canShoot = this.canShoot()
		const currentEnergy = this.currentEnergy
		const maxEnergy = this.maxEnergy
		const { isCharging } = { ...this }
		clientDebug.drawText(JSON.stringify({
			isCharging,
			maxEnergy,
			currentEnergy,
			canShoot,
		}, undefined, '  '), this, 0xffffff, false, 0)
	}

	canShoot() {
		if (this.isDead()) {
			return false
		}

		if (this.isGhost) {
			return false
		}
		
		const resourceBarMode = this.resourceBarMode

		const activeWeaponSlot = this.weaponSlots[this.activeWeaponSlot]
		if (resourceBarMode === ResourceBarMode.ENERGY_COST_SHOT) {
			if (activeWeaponSlot.statList.currentEnergy <= 1) {
				return false
			}
		} else if (resourceBarMode === ResourceBarMode.CHARGE_UP_SHOT) {
			if (activeWeaponSlot.statList.currentEnergy <= 1) {
				return false
			}
		}

		return true
	}

	onShot(shotSuccess: boolean) {
		// (us shooting)
		const curWeaponSlot = this.weaponSlots[this.activeWeaponSlot]

		if (shotSuccess) {
			curWeaponSlot.statList.isOnCooldown = true
			const buff = this.hasBuff(BuffIdentifier.OutpostGottaGoFast)
			if (buff) {
				buff.wearOff()
			}
		}

		const resourceBarMode = this.resourceBarMode
		if (resourceBarMode === ResourceBarMode.CHARGE_UP_SHOT) {
			curWeaponSlot.statList.currentEnergy = 1
		}
	}

	ownedProjectileDied(proj: any): void {

	}
	
	hasBuff(identifier: BuffIdentifier): ClientBuff { 
		return this.player.cspBuffs.find((buff) => buff.definition.identifier === identifier)
	}

	getCurrentActiveWeaponSlot(): ProjectileConfig {
		return this.player.myPlayerData.weaponSlots[this.activeWeaponSlot]
	}

	handleInput(curInput: PlayerInput, delta: timeInSeconds) {
		const skinId = this.player.skin

		const shooting = curInput.mouseLeftDown
		this.hasMouseUp = !shooting && this.shooting

		this.shooting = curInput.mouseLeftDown
		this.aimVector.x = curInput.aimX
		this.aimVector.y = curInput.aimY + getAimOffsetFromSkin(skinId)
		this.weaponOffset = getWeaponOffsetFromSkin(skinId)
	}

	handleWeaponFiredMessage(message: WeaponFiredMessage) {
		this.player.handleWeaponFiredMessage(message, true)
	}

	reduceEnergy(amount) {
		const statList = this.weaponSlots[this.activeWeaponSlot].statList
		const currentEnergy = statList.currentEnergy
		statList.currentEnergy = Math.max(0, currentEnergy - amount)
		if (statList.currentEnergy === 0) {
			this.energyRegenDelay = PLAYER_ENERGY_REGEN_DELAY_SECONDS
		}
	}
}

const throttledEnergyPercentageEventEmitter = throttle((percentageOfMaxEnergy) => {
	UI.getInstance().emitEvent('hud/updatedEnergyPercentage', percentageOfMaxEnergy * 100)
}, 100)
