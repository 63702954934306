import { EnemyAI, FightingTriggers, IdleBehaviours, FightingBehaviours, LeashTriggers, LeashingBehaviours, AttackTypes, DeadBehaviours, ShotLeadPrecision, EnemyType, EnemyTargetingStyles } from '../../shared/ai-types'
import { ModType } from '../../../projectiles/shared/projectile-types'
import { SpineDataName, EnemyBipedSkinType } from '../../../models-animations/shared/spine-config'
import { EnemyDefaults } from './enemy-defaults'
import { Colors } from '../../../utils/colors'
import { ParticleEffectType } from '../../../engine/shared/game-data/particle-config'
import { ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { ENEMY_DEFAULT_HEALTH } from '../../../engine/shared/game-data/enemy-formulas'
import { ENEMY_NAMES } from './enemy-names'

const skeletonKnightBalloon: EnemyAI = {
	name: ENEMY_NAMES.SKELETON_KNIGHT_BALLOON,
	type: EnemyType.BASIC,
	appearance: {
		asset: SpineDataName.SKELETON_KNIGHT_BALLOON,
		skin: EnemyBipedSkinType.DEFAULT,
		mixSettings: [],
	},
	general: {
		enemyLevel: 1,
		experienceWhenKilled: 1.25,
	},
	baseAttributes: {
		...EnemyDefaults.baseAttributes,
		physicalDamage: 0,
		movementSpeed: 200,
		maxHealth: ENEMY_DEFAULT_HEALTH * 2,

		fireDamage: 0,
		iceDamage: 0,
		lightningDamage: 0,
		poisonDamage: 0,
		attackRate: 900,
		critChance: 0,
		critDamage: 1.0,
		chillPotency: 1.0,
		chillChance: 0.6,
		ignitePotency: 1.0,
		igniteChance: 0,

		defense: 0,
		fireResist: 0,
		poisonResist: 0,
		iceResist: 0,
		lightningResist: 0,

		lootDropProbability: 100,
		lootDropQuantity: 2,
		lootDropRarity: 3,

		decelerationRate: 4.5,
		colliders: [
			{
				type: ColliderType.Ellipse,
				rX: 40,
				rY: 55,
				position: [0, -40],
				traits: ColliderTraits.BlockMovementAndProjectile,
			},
		],
	},
	soundEffects: {
		attack: 'Projectile_Zap',
		impact: 'SFX_Impact_Enemy_Mush',
	},
	states: {
		idling: {
			behaviour: IdleBehaviours.MEANDER,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 2,
			transitionToFighting: {
				trigger: FightingTriggers.AGGRESSIVE_PROXIMITY,
				radius: 900,
			},
			meander: {
				radius: 500,
				speedMultiplier: 1,
				minRestTime: 0.5,
				maxRestTime: 10,
			},
			aggroOnHit: true,
		},
		fighting: {
			behaviour: FightingBehaviours.CHASE_AND_ATTACK,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 5,
			attackType: AttackTypes.PROJECTILE,
			modelCenterOffset: 40,
			engagementMaxDistance: 400,
			engagementMinDistance: 0,
			movementMaxDistance: 225,
			movementMinDistance: 0,
			attackOptions: [
				{
					...EnemyDefaults.projectileConfig,
					projectileCount: 1,
					speed: 500,
					colliderRadius: 60,
					lifespanInSeconds: 0.55,
					modifiers: [{ modType: ModType.STRAIGHT }],
					baseIceDamage: 110,
					burstCount: 0,
					color: Colors.darkBlue,
					bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_THORNWOLF,
					bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_ICE_TRAIL,
					muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_ICE_SHOOT,
				},
			],
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 0,
			transitionToLeashing: {
				trigger: LeashTriggers.DISTANCE_TRAVELLED_OR_RADIUS_EXCEEDED,
				radius: 2000,
				distanceTravelled: 4000,
			},
		},
		leashing: {
			behaviour: LeashingBehaviours.MOVE,
			speedMultiplier: 4,
		},
		fleeing: {},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 1,
		},
	},
}

export default skeletonKnightBalloon
