import { Vector } from 'sat'
import { AssetManager } from '../../asset-manager/client/asset-manager'
import { getBiomeCount } from '../../biome/shared/biome-list'
import Renderer, { getVisibleWorldHeight, getVisibleWorldWidth } from '../../engine/client/graphics/renderer'
import { gameModeConfig } from '../../engine/shared/game-mode-configs'
import { GameModeType } from '../../engine/shared/game-mode-type'
import playAnimation from '../../models-animations/client/play-animation'
import { RiggedSpineModel } from '../../models-animations/client/spine-model'
import { AnimationTrack } from '../../models-animations/shared/animation-track'
import { add, scale, sub } from '../../utils/math'
import { WorldTier } from '../../utils/primitive-types'
import { PLAYER_BIOME_X_GRACE } from '../shared/world-consts'

const FOG_Y_HEIGHT = 1023
const FOG_X_OFFSET = 800 // to account for cloud anim poking out on left side
const FOG_Y_OFFSET = -512

interface FogBankEntry {
	base: RiggedSpineModel
	clouds: RiggedSpineModel
}

const fogConfig = {
	fadeOut: {
		testFadeOut: false,
		baseVelX: 10,
		cloudVelX: 5,
		baseAlphaMult: 0.9,
		cloudAlphaMult: 0.95,
	},
	visualXOffset: 512,
}

export default class FogBank {
	fadeOut = false
	private fogModels: FogBankEntry[] = []
	private assetName: string
	private xPos: number

	constructor(assetName: string, xPos: number) {
		this.assetName = assetName
		this.xPos = xPos
		this.updateSize()
	}

	setXPos(xPos: number) {
		this.xPos = xPos
	}

	updateSize() {
		const renderer: Renderer = Renderer.getInstance()
		const asset = AssetManager.getInstance().getAssetByName(this.assetName)
		const spineData = asset.spineData as PIXI.spine.core.SkeletonData

		const viewSize = getViewSize()

		const count = Math.ceil(viewSize.y / FOG_Y_HEIGHT) + 1
		for (let i = this.fogModels.length; i < count; i++) {
			const models = []
			const anims = [AnimationTrack.FOG_TILE, AnimationTrack.FOG_CLOUDS]
			for (let j = 0; j < 2; j++) {
				const model = new RiggedSpineModel(spineData)
				model.x = this.xPos
				model.y = i * FOG_Y_HEIGHT
				model.zIndex = Number.MAX_SAFE_INTEGER - 5 + j
				models.push(model)
				renderer.fgRenderer.addDisplayObjectToScene(model)
				playAnimation(model, anims[j])
			}

			this.fogModels.push({ base: models[0], clouds: models[1] })
		}

		while (this.fogModels.length > count) {
			const removed = this.fogModels.pop()
			renderer.fgRenderer.removeFromScene(removed.base)
			renderer.fgRenderer.removeFromScene(removed.clouds)
		}
	}

	cleanup() {
		const renderer: Renderer = Renderer.getInstance()
		this.fogModels.forEach((fm) => {
			renderer.fgRenderer.removeFromScene(fm.base)
			renderer.fgRenderer.removeFromScene(fm.clouds)
		})
		this.fogModels = []
	}

	update() {
		this.updateSize()

		const renderer = Renderer.getInstance()

		const cameraCenter = renderer.getCameraCenterWorldPos()
		const viewSize = getViewSize()
		const screenTopLeft = sub(cameraCenter, scale(viewSize, 0.5))
		const screenBottomRight = add(cameraCenter, scale(viewSize, 0.5))

		const fogModels = this.fogModels

		const lastIdx = fogModels.length - 1

		const addToTop = screenTopLeft.y < fogModels[0].base.y + FOG_Y_OFFSET
		const addToBottom = screenBottomRight.y > fogModels[lastIdx].base.y + FOG_Y_HEIGHT + FOG_Y_OFFSET

		if (addToTop !== addToBottom) {
			// above if to prevent thrashing
			if (addToTop) {
				fogModels.unshift(fogModels.pop())
				fogModels[0].clouds.y = fogModels[0].base.y = fogModels[1].base.y - FOG_Y_HEIGHT
			} else if (screenBottomRight.y > fogModels[lastIdx].base.y) {
				fogModels.push(fogModels.shift())
				fogModels[lastIdx].clouds.y = fogModels[lastIdx].base.y = fogModels[lastIdx - 1].base.y + FOG_Y_HEIGHT
			}
		}

		if (this.fadeOut || fogConfig.fadeOut.testFadeOut) {
			this.fogModels.forEach((fm) => {
				fm.base.position.x += fogConfig.fadeOut.baseVelX
				fm.clouds.position.x += fogConfig.fadeOut.cloudVelX
				fm.base.alpha *= fogConfig.fadeOut.baseAlphaMult
				fm.clouds.alpha *= fogConfig.fadeOut.cloudAlphaMult
			})
		} else {
			this.fogModels.forEach((fm) => {
				fm.base.position.x = this.xPos
				fm.clouds.position.x = this.xPos
				fm.base.alpha = 1
				fm.clouds.alpha = 1
			})
		}
	}
}

export function showFogBankAtPos(xPos) {
	const camPos = Renderer.getInstance().getCameraCenterWorldPos()
	const view = getViewSize()

	const visible = camPos.x + FOG_X_OFFSET + view.x * 0.5 > xPos
	if(visible) {
		if(!fogBank) {
			fogBank = new FogBank('fog', xPos)
		} else {
			fogBank.setXPos(xPos)
		}

		fogBank?.update()
	} else {
		hideFogBank()
	}
}

export function hideFogBank() {
	if(fogBank && !fogBank.fadeOut) {
		fogBank.fadeOut = true
		// TODO2: remove this setTimeout and replace with alternate system
		setTimeout(destroyFogBankRightNow, 1000)
	}

	fogBank?.update()
}

export function destroyFogBankRightNow() {
	if(fogBank) {
		fogBank.cleanup()
		fogBank = null
	}
}

export function updateFogBank(progressionLevel: number, activeWorldTier: WorldTier) {
	if(gameModeConfig.type === GameModeType.Hub) {
		return
	}

	const biomeCount = getBiomeCount(GameModeType.Adventure)
	const reachedWorldTier = Math.floor(progressionLevel / biomeCount) + 1
	const barredBiomeIndex = (progressionLevel % biomeCount) + 1

	const biomeBounds = Renderer.getInstance().biomeBounds

	if (biomeBounds && barredBiomeIndex < biomeBounds.length && activeWorldTier === reachedWorldTier) {
		const biomeRightSide = biomeBounds[barredBiomeIndex].min + fogConfig.visualXOffset + PLAYER_BIOME_X_GRACE

		showFogBankAtPos(biomeRightSide)
	} else {
		hideFogBank()
	}
}

let fogBank: FogBank

export function getViewSize() {
	const w = getVisibleWorldWidth()
	const h = getVisibleWorldHeight()

	return new Vector(w, h)
}
