import { Beam } from '../shared/beam.shared'

type ClientBeamType = {
	[K in keyof Beam]: Beam[K]
}
export class ClientBeam extends Beam {
	exemptFromModelCheck = true

	constructor(beam: ClientBeamType) {
		super()
		Object.assign(this, beam)
	}
}

export const clientBeamHooks = {}
