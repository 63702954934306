import { BinaryFlag, StatType } from "../../engine/shared/game-data/stat-type-mod-category"
import { percentage } from "../../utils/primitive-types"
import StatOperatorType from "./stat-operator-type"

export enum StatUnit {
	Number,
	Percentage,
}

/** A stat line that SETS a binary flag for the entity (e.g. your strikes automatically kill non-boss enemies below 20% health) */
export interface StatBinaryFlag {
	/** Specific binary flag to SET on an entity */
	flag: BinaryFlag
}

/** A stat line that will clamp a given stat to within a min and max value (e.g. 1-3 projectiles) */
export interface StatClampConstraint {
	/** Stat that will be clamped */
	statType: StatType
	/** Minimum amount of stat */
	clampMin?: number
	/** Maximum amount of stat */
	clampMax?: number
}

/** A stat line that will convert one stat to another, with ratios and reserves (e.g. convert 30% of all damage to fire damage) */
export interface StatConverter {
	/** Stat that will be converted */
	inputStatType: StatType
	/** Units that the input stat uses. Used for tooltip descriptions. */
	inputStatUnit: StatUnit
	/** Amount of stat that is reserved and NOT converted */
	inputMinReserve: number
	/** Ratio of how much of stat to convert */
	inputRatio: percentage
	/** Output stat that is converted to */
	outputStatType: StatType
	/** Units that the output stat uses. Used for tooltip descriptions. */
	outputStatUnit: StatUnit
	/** Ratio of how much output stat should be boosted per 1 input stat */
	outputRatio: percentage
	/** Operator to apply to output stat */
	outputStatOperator: StatOperatorType
}

/** A standard stat line that yields a given stat, with a dynamic roll between the min and max ranges */
export interface StatLine {
	/** Stat that will be mutated */
	statType: StatType
	/** Minimum value of roll for this stat in this tier. This should be the value that has the "worst" gameplay value for the entity */
	minValue: number
	/** Maximum value of roll for this stat in this tier. This should be the value that has the "best" gameplay value for the entity */
	maxValue: number
	/** Operator to apply to stat */
	statOperator: StatOperatorType
	allowInvertedValues?: boolean
}

/** A standard stat line that yields a given stat. */
export interface StaticStatLine {
	/** Stat that will be mutated */
	statType: StatType
	value: number

	/** Operator to apply to stat */
	statOperator: StatOperatorType
	allowInvertedValues?: boolean
}
