import nengi from 'nengi'
import { nengiId } from '../../utils/primitive-types'

export default class PlayerIgnoreColliderMessage {
	static protocol = {
		colliderId: { type: nengi.UInt16 },
	}

	colliderId: nengiId

	constructor(entityId: nengiId) {
		this.colliderId = entityId
	}
}
