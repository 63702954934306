





























































































import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import InventoryItem from '../reusable-components/inventory-item/inventory-item.vue'
import TextButton from '../reusable-components/buttons/text-button.vue'
import ArrowButton from '../reusable-components/buttons/arrow-button.vue'
import PanelDivider from '../reusable-components/panel-divider/panel-divider.vue'
import FilteredButtonedGridItemContainer from '../reusable-components/item-containers/filtered-buttoned-grid-item-container.vue'
import ButtonedGridItemContainer from '../reusable-components/item-containers/buttoned-grid-item-container.vue'
import { attachToolTipText, autoAugmentButtonTooltipText } from '../reusable-components/v-tooltip/v-tooltip-functions'
import PlainTextButton from '../reusable-components/buttons/plain-text-button.vue'
import {showPfxAnimation, SpineAssetNames} from '../state/slot-animation.ui-state'


export default {
	name: 'AugmentationStationUpdated',
	components: {
		MainPanel,
		InventoryItem,
		TextButton,
		FilteredButtonedGridItemContainer,
		ArrowButton,
		PanelDivider,
		ButtonedGridItemContainer,
		PlainTextButton,
	},
	data() {
		return {}
	},
	computed: {
		...mapState('hud', ['progressionLevel']),
		...mapGetters('itemContainers', ['itemByContainerIndex', 'itemsByItemTypeInContainer', 'itemDetails']),
		...mapGetters('miniPaperdoll', ['selectedMiniPaperdollIndex', 'miniPaperdollHasASelection', 'miniPaperdollHasASelection']),
		...mapGetters('augmentationStation', ['currentSelectedItem', 'hasSelectedItem']),
		...mapGetters('inGame', ['activePanel', 'flyoutPanel']),
		...mapState('augmentationStation', ['augmentsToApply', 'ftueMode', 'isAutoComputing', 'alreadyEquippedAugments', 'unlockAugmentCosts']),
		...mapGetters('augmentationStation', ['augmentCannotAttach', 'totalAugmentCost']),
		...mapGetters('hud', ['coinBalance']),
		...mapGetters('itemLocks', ['itemIsLocked']),
		...mapGetters('filter', ['getFilteredItems', 'getAugmentFilterStatus']),

		setColumnWidthStyle() {
			if (this.currentSelectedItem.augmentLimit <= 4) {
				return 'width: 253px'
			}
			if (this.currentSelectedItem.augmentLimit > 4) {
				return 'width: 370px'
			}
			return 'width: 0px'
		},
		attachButtonDisabled() {
			if (this.currentSelectedItem && this.currentSelectedItem.equippedAugmentIds && this.augmentsToApply && this.totalAugmentCost == 0 && this.currentSelectedItem.equippedAugmentIds.length == this.augmentsToApply.length) {
				// have to check if the augments are the same
				let allSame = true
				for(let i=0; i < this.currentSelectedItem.equippedAugmentIds.length; ++i) {
					const id = this.currentSelectedItem.equippedAugmentIds[i]
					if(!(this.augmentsToApply.find((applying) => applying.id === id))) {
						allSame = false
						break
					}
				}

				if(allSame) {
					return true
				}
			}

			const cantAfford = Number.parseInt(this.coinBalance, 10) < Number.parseInt(this.totalAugmentCost, 10)
			const isLocked = (this.currentSelectedItem && this.itemIsLocked(this.currentSelectedItem.id))
			return cantAfford || isLocked
		},
		selectedItem() {
			return this.currentSelectedItem
		},
		augmentsCanAdd() {
			if (this.getAugmentFilterStatus) {
				return this.getFilteredItems
			}

			return this.itemsByItemTypeInContainer('stash', 'Weapon Augment').concat(this.alreadyEquippedAugments)
		},
		maxAugmentSlotCount(){
			return this.selectedItem.augmentLimit
		},
		itemRarity(){
			if(this.currentSelectedItem !== undefined) {
				return this.currentSelectedItem.rarity.toLowerCase()
			}
		},
		showRarity() {
			if (this.currentSelectedItem) {
				if (this.currentSelectedItem.rarityEnum >= 4) {
					return true
				}
			}
			return false
		},
	},
	methods: {
		...mapMutations('augmentationStation', ['clearAugmentsToApply', 'addAugmentToApply', 'removeAugmentToApply', 'updateUnlockAugmentCosts']),
		...mapActions('inGame', ['closeActivePanel', 'openFlyoutPanel', 'closeFlyoutPanel']),
		...mapActions('augmentationStation', ['selectedWeaponToAugment', 'removeAugmentFromWeapon', 'commitAugmentsToWeapon', 'backToStash', 'applyAutoAugments', 'commitPurchaseAugmentSlot']),
		...mapActions('slotAnimation', ['showUnlockAnimation']),
		...mapMutations('proximityMessage', ['postWaitForCondition']),
		...mapMutations('filter', ['setFiltering']),
		showPfxAnimation,
		attachToolTipText,
		autoAugmentButtonTooltipText,
		
		attachAugment(itemId) {
			const paperdollItem = this.itemByContainerIndex('paperdoll', this.selectedMiniPaperdollIndex)
			console.log(`attaching augment ${itemId} to weapon ${paperdollItem.id}`)
			this.attachAugmentToWeapon({ augmentId: itemId, weaponId: paperdollItem.id })
		},
		shouldDisableRemoveAugButton() {
			return this.ftueMode
		},
		shouldDisableRemoveAllButton() {
			return this.ftueMode || this.augmentsToApply.length === 0
		},
		disableIndividualAttachBtn() {
			return false
		},
		// buttonShouldBeHiddenBasedOnCriteria(item) {
		// 	return item.unidentified
		// },
		activateFlyoutPanel() {
			if (!this.flyoutPanel) {
				this.openFlyoutPanel('augmentation-station')
			} else {
				this.setFiltering(false)
				this.closeFlyoutPanel()
			}
		},
		getItemAugmentCost(item) {
			if (this.alreadyEquippedAugments.find((i) => i.id === item.id)) {
				return 0
			}
			return item.augmentCost
		},

		unlockAugment(event){
			const elementIds = [event.target.parentElement.id]
			this.commitPurchaseAugmentSlot()
			showPfxAnimation(elementIds, 15, SpineAssetNames.AUGMENT_UNLOCK, undefined, 150, 150)
		}
	},
	updated(){
		this.updateUnlockAugmentCosts()
	}
}
