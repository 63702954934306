/* eslint-disable @typescript-eslint/no-var-requires */
import { UI } from '../../ui/ui'
import { setProximityMessageVisible, showFTUEPromptUI, showFTUEPromptUISequence, showProximityMessage } from '../../ui/state/proximity-message.ui-state'
import { showGenericInfoPromptUI, showGenericInfoPromptUISequence } from '../../ui/state/generic-information-prompt.ui-state'
import { clearAllTutorialTooltips, showTutorialTooltip } from '../../ui/state/hud.ui-state'
import { NengiClient } from '../../engine/client/nengi-client'
import { GameClient } from '../../engine/client/game-client'
import RequestArrowPositionCommand from '../shared/request-arrow-position-command'
import { ArrowPositionRequestValue } from '../shared/arrow-position-request-values'
import { showTutTooltipSequence, showTutTooltipWaitForMessage } from '../../ui/state/advanced-tutorial-tooltip.ui-state'
import { WaitCondition } from '../../ui/state/WaitCondition'
import { analyticsEventStartAugment, analyticsEventFinishAugment } from '../../ui/state/google-analyitics'
import { completeFlagsFromCriteria } from '../../ui/state/ftue-manager.state'
import { PRESSURE_MENU_UNLOCK_TIERS_DEFEATED } from '../../engine/shared/game-data/pressure-stat-info.shared'
import { FTUESequence } from '../shared/ftue.shared'
import { PanelTabs } from '../../ui/state/departure.ui-state'

export const FREE_LISTINGS_PER_DAY = 1

/* tslint:disable no-var-requires */
const gifs = require('../../../assets/ui-assets/ftue/*.gif')
/* tslint:disable no-var-requires */
const webps = require('../../../assets/ui-assets/ftue/*.webp')
//idk how to write that as an import statement and make it work, so it is a require with a disable
const WORM_MAIL_LIMIT_LABEL = 'TEN'

interface FTUEMessage {
	showMessage: () => void
}

export enum FTUEVideo {
	AGWOfflineIntro = `7w4m_XxOPP4`,
}

function setFlagCriteriaDone(criteria) {
	//TODO: Change this criteria parameter to accept something standardized like the enum or a string enum or something
	UI.getInstance().emitAsyncEvent('ftueManager/completeFlagsFrom', criteria)
}

function showUIArrow(target, direction) {
	UI.getInstance().emitEvent('hud/showTutorialArrow', { target, direction })
}

function hideUIArrow(target) {
	UI.getInstance().emitEvent('hud/clearTutorialArrow', target)
}

function hideAllUIArrows() {
	UI.getInstance().emitEvent('hud/clearAllTutorialArrows')
}

export const PROXIMITY_MESSAGES_CONFIG: Map<FTUESequence, FTUEMessage> = new Map()

export const DEFAULT_PROX_MESSAGE_TITLE = 'Tutorial'

const CONTROLS_MESSAGE = {
	title: 'Controls',
	description: [
		`<span class="ftue color gold">W, A, S, D</span> - Move`,
		`<span class="ftue color gold">Left Mouse Button</span> - Fire`,
		`<span class="ftue color gold">Q / Mouse Wheel</span> - Switch Weapon`,
		`<span class="ftue color gold">Space, E, R</span> - Activate Skills`,
		`<span class="ftue color gold">F</span> - Interact`,
		`<span class="ftue color gold">I</span> - Open Inventory/Stash`,
	],
	okButtonText: 'OK',
	dimBackground: false,
}

const tutFirstAdventureRunIntro = {
	firstMessage: {
		title: 'Welcome to LOOT!',
		description: [`In <span class="ftue color gold">LOOT</span> you embark on <span class="ftue color teal">Adventure Runs</span> to slay monsters and acquire <span class="ftue color gold">LOOT</span>. Explore the vast, mysterious island to find challenges and epic bosses to defeat!`],
		okButtonText: 'NEXT',
	},

	secondMessage: {
		title: '',
		description: [
			'In each <span class="ftue color teal">Adventure Run</span>, choose your weapons and gear to see how far you can go! Collect monster <span class="ftue color gold">LOOT</span> and mail it from an Outpost to town (using <span class="ftue colors rust">Worm Mail</span>) - to power up your NEXT <span class="ftue color teal">Run</span>!',
		],
		okButtonText: 'NEXT',
		dimBackground: false,
	},

	killMessage: {
		title: 'RISK',
		description: [
			`
			<div class="ftue-wrapper">
				<div class="ftue-flex">
					<img src="${gifs.killBones}"/>
				</div>
				<div class="ftue-flex">
					<h1>
						Kill Monsters!
					</h1>
				</div>
			</div>
		`,
		],
		okButtonText: 'NEXT',
		dimBackground: false,
	},
	lootMessage: {
		title: 'REWARDS',
		description: [
			`
			<div class="ftue-wrapper">
				<div class="ftue-flex">
					<img src="${gifs.lootPickup}"/>
				</div>
				<h1 class="ftue color gold">
					Acquire LOOT!
				</h1>
			</div>
			</div>
		`,
		],
		okButtonText: 'NEXT',
		dimBackground: false,
	},
	lastMessage: {
		title: 'RESPITE',
		description: [
			`
			<div class="ftue-wrapper">
				<div class="ftue-flex">
					<img src="${gifs.wormMail}"/>
				</div>
				<div class="ftue-flex">
					<h1>
						Visit the Outpost!
					</h1>
				</div>
			</div>
		`,
		],
		okButtonText: "Let's go!",
		dimBackground: false,
	},

	healthTooltip: {
		target: 'health',
		text: '',
		title: 'Health Bar',
		position: 'bottom',
	},

	shieldsTooltip: {
		target: 'shields',
		text: 'Each shield blocks 1 hit',
		title: 'Shields',
		position: 'bottom',
	},

	weaponsTooltip: {
		target: 'weaponsAndGear',
		text: '',
		title: 'Equipped Weapons & Gear/Skills',
		position: 'top',
	},

	//TODO2: tooltip for levels/enchants ?
	//TODO2: tooltip for charge/energy bar ?
	//TODO2: tooltip for hamburger menu ?

	showMessage() {
		showGenericInfoPromptUISequence([this.firstMessage, this.secondMessage], 'tutorial', () => {
			showTutorialTooltip(this.healthTooltip)
			showTutorialTooltip(this.shieldsTooltip)
			showTutorialTooltip(this.weaponsTooltip)

			showGenericInfoPromptUISequence([CONTROLS_MESSAGE], 'tutorial', () => {
				clearAllTutorialTooltips()
				showGenericInfoPromptUISequence([this.killMessage, this.lootMessage, this.lastMessage], 'tutorial')
			})
		})
	},
}

const tutLootDropped = {
	message: [`Destroyed monsters drop LOOT! Run over there to pick it up.`],
	shownOnce: false,

	showMessage() {
		if (!this.shownOnce) {
			showFTUEPromptUI('Loot!', this.message, 'OKAY')
			this.shownOnce = true
		}
	},
}

const tutLootInInventoryGoToOutpost = {
	message: [`That's awesome LOOT, But you can only <strong>use it when you're in town</strong>.`, `Find the nearest <span class="ftue color teal">Outpost</span> to send your LOOT back to town via <span class="ftue colors rust">Worm Mail</span>!`],
	shownOnce: false,

	showMessage() {
		if (!this.shownOnce) {
			showFTUEPromptUI('Loot!', this.message, 'OKAY')
			NengiClient.getInstance().sendCommand(new RequestArrowPositionCommand(ArrowPositionRequestValue.WORM_MAIL))
			this.shownOnce = true
		}
	},
}

const tutFullInventory = {
	message: [`Your inventory is <span class="ftue colors rust">full</span> and you can't collect any more LOOT!`, `You can toss any items from your inventory that you don't want to keep.`, `You can open up your inventory by pressing [I].`],
	shownOnce: false,

	showMessage() {
		if (!this.shownOnce) {
			showFTUEPromptUI('Uh-oh', this.message, 'OKAY')
			this.shownOnce = true
		}
	},
}

let playerVisitedOutpostBefore = false

export function setPlayerVisitedOutpost(visited) {
	playerVisitedOutpostBefore = visited
}

// Used in game-client when the worm mail is sent
export const DONE_WORM_MAIL_SEND_PROX_MESSAGE = [`Visit the <span class="ftue color teal">End Run</span> guy to go back to town and pick up your LOOT!`]
const HAS_LOOT_OUTPOST_PROX_MESSAGE = [`You should visit the <span class="ftue colors rust">Worm Mail</span> at the <span class="ftue color teal">Outpost</span>!`]
const NO_LOOT_OUTPOST_PROX_MESSAGE = [`You should destroy some monsters to get some LOOT`, `before visiting the <span class="ftue colors rust">Worm Mail</span> at the <span class="ftue color teal">Outpost</span>!`]

const tutNearOutpost = {
	noVisitMessage: [`Look! You're near an <span class="ftue color teal">Outpost</span>!`, `Someone there might be able to help you!`],

	showMessage() {
		if (!playerVisitedOutpostBefore) {
			showProximityMessage(DEFAULT_PROX_MESSAGE_TITLE, this.noVisitMessage)
		} else {
			const playerHasLoot = UI.getInstance().store.getters['hud/inventoryWeight'] > 0
			if (playerHasLoot) {
				showProximityMessage(DEFAULT_PROX_MESSAGE_TITLE, HAS_LOOT_OUTPOST_PROX_MESSAGE)
			} else {
				showProximityMessage(DEFAULT_PROX_MESSAGE_TITLE, NO_LOOT_OUTPOST_PROX_MESSAGE)
			}
		}
	},
}

const tutInsideOutpost = {
	firstMessage: {
		title: 'Tutorial',
		description: [`You're safe from monsters while at an <span class="ftue color teal">Outpost</span>. Phew!`],
		okButtonText: 'NEXT',
	},

	secondMessage: {
		title: 'Tutorial',
		description: [`You can't take your LOOT back to town personally, so use the <span class="ftue colors rust">Worm Mail</span> here to send your LOOT back to town.<br><br>You can also <span class="ftue color teal">end your run</span> and return to town from here.`],
		okButtonText: 'NEXT',
	},

	thirdMessage: {
		title: 'Tutorial',
		description: [`That hideous <span class="ftue colors rust">Worm</span> is actually your ally!<br><br>It can send up to ${WORM_MAIL_LIMIT_LABEL} pieces of LOOT to town for you. You can use that LOOT next time you visit town.`],
		okButtonText: 'NEXT',
	},

	noLootMessage: {
		title: 'Tutorial',
		description: [`You have no LOOT, go kill some monsters to get some, and then return here.`],
		okButtonText: 'OKAY',
	},

	hasLootMessage: {
		title: 'Tutorial',
		description: [`Move closer to the Worm to send your <span class="ftue colors rust">Worm Mail</span>. Don't worry, it doesn't bite...`],
		okButtonText: 'OKAY',
	},

	showMessage() {
		if (!playerVisitedOutpostBefore) {
			const playerHasLoot = UI.getInstance().store.getters['hud/inventoryWeight'] > 0
			const lootMessage = playerHasLoot ? this.hasLootMessage : this.noLootMessage

			showFTUEPromptUISequence([this.firstMessage, this.secondMessage, this.thirdMessage, lootMessage])

			if (playerHasLoot) {
				showProximityMessage(DEFAULT_PROX_MESSAGE_TITLE, HAS_LOOT_OUTPOST_PROX_MESSAGE)
			} else {
				showProximityMessage(DEFAULT_PROX_MESSAGE_TITLE, NO_LOOT_OUTPOST_PROX_MESSAGE)
			}

			playerVisitedOutpostBefore = true
		} else {
			const playerHasLoot = UI.getInstance().store.getters['hud/inventoryWeight'] > 0
			if (playerHasLoot) {
				showProximityMessage(DEFAULT_PROX_MESSAGE_TITLE, HAS_LOOT_OUTPOST_PROX_MESSAGE)
			} else {
				showProximityMessage(DEFAULT_PROX_MESSAGE_TITLE, NO_LOOT_OUTPOST_PROX_MESSAGE)
			}
		}
	},
}

export const DONE_SENDING_MAIL_MESSAGE = ['Good Job!', `Now head over to the <span class="ftue color teal">End Run</span> guy (he has a derpy purple dragon) to go to town and pick up your LOOT!`]

const tutFirstWormMail = {
	firstMessage: {
		title: 'Tutorial',
		description: [`You can send up to ${WORM_MAIL_LIMIT_LABEL} pieces of LOOT back to town using the <span class="ftue colors rust">Worm Mail</span>.<br><br>Once the worm departs, you can't send any more LOOT from here.`],
		okButtonText: 'NEXT',
	},

	noLootMessage: {
		title: 'Tutorial',
		description: [`You should come back here after killing monsters and gathering some LOOT!`],
	},

	hasLootMessage: {
		title: 'Tutorial',
		description: [`Try sending back some of the LOOT you have now!`],
	},

	showMessage() {
		const playerHasLoot = UI.getInstance().store.getters['hud/inventoryWeight'] > 0
		const lootMessage = playerHasLoot ? this.hasLootMessage : this.noLootMessage
		showFTUEPromptUISequence([this.firstMessage, lootMessage])
	},
}

const tutOutpostShop = {
	blockedMessage: [`In later runs you will be able to buy things from here using COINS, but don't worry about this for now.`],
	message: [`You can spend COINS here to buy items that will help you during this run.`],

	showMessage() {
		const ftueGetFlag = UI.getInstance().store.getters['ftueManager/getFlag']
		const doneAugmentTut = ftueGetFlag('openedStashNPC')

		if (!doneAugmentTut) {
			showGenericInfoPromptUI('Tutorial', this.blockedMessage, 'OKAY', 'tutorial')
		} else {
			showFTUEPromptUI('', this.message, 'OKAY', () => {
				setFlagCriteriaDone('outpostShopOpened')
			})
		}
	},
}

const tutEndRunGuyPriorToWormMail = {
	noLootMessage: [
		//I don't like this first sentence
		`This guy says you should first send some LOOT back to town using the <span class="ftue colors rust">Worm Mail</span> because he can send you back, but not any of the LOOT you carry.`,
		`He'll send you back to town once you've used the <span class="ftue colors rust">Worm Mail</span>.`,
	],

	hasLootMessage: [`Let's go back to town and pick up that LOOT!`],

	showMessage() {
		const ftueGetFlag = UI.getInstance().store.getters['ftueManager/getFlag']
		const doneWormMailTut = ftueGetFlag('openedWormMailNPC')
		const lootMessage = doneWormMailTut ? this.hasLootMessage : this.noLootMessage

		showGenericInfoPromptUI('', lootMessage, 'OKAY', 'tutorial')
	},
}

const tutBossFogWall = {
	message: [`Beyond this fog is the next area and better LOOT.`, `It clears once you've defeated a boss.<br><br>If you aren't strong enough to defeat the boss, collect some LOOT and send it back to town at an <span class="ftue color teal">Outpost</span>!`],

	beatBossButCantContinueMessage: [`Normally you could continue to the next area, but you need to collect some LOOT and visit an <span class="ftue color teal">Outpost</span> first.`],

	showMessage() {
		const ftueGetFlag = UI.getInstance().store.getters['ftueManager/getFlag']
		const doneFirstTut = ftueGetFlag('openedWormMailNPC')

		if (GameClient.getInstance().state.myEntity.progressionLevel < 1) {
			showProximityMessage(DEFAULT_PROX_MESSAGE_TITLE, this.message)
			if (doneFirstTut) {
				setFlagCriteriaDone('fogWallSeen')
			}
		} else {
			if (!doneFirstTut) {
				showProximityMessage(DEFAULT_PROX_MESSAGE_TITLE, this.beatBossButCantContinueMessage)
			}
		}
	},
}

const tutClearedFogWall = {
	message: [`Defeating the boss cleared the fog that was once here.`],

	showMessage() {
		showProximityMessage(DEFAULT_PROX_MESSAGE_TITLE, this.message)
		setFlagCriteriaDone('wentToNextBiome')
	},
}

const tutBossIntro = {
	message: [`This is a Boss!`, `It’s tough, so you’re better off teaming up with other players to destroy it.<br><br>Defeating a Boss rewards you with a choice of cool <span class="ftue color gold">Enchantment</span> and also clears the fog that prevents your progress to the next biome.`],

	notDoneTutMessage: `First though, you need to send some LOOT back home and return there via the <span class="ftue color teal">Outpost</span>.`,

	showMessage() {
		const ftueGetFlag = UI.getInstance().store.getters['ftueManager/getFlag']
		const doneFirstTut = ftueGetFlag('openedWormMailNPC')
		if (!doneFirstTut) {
			showProximityMessage(DEFAULT_PROX_MESSAGE_TITLE, [...this.message, '<br>', this.notDoneTutMessage])
		} else {
			showProximityMessage(DEFAULT_PROX_MESSAGE_TITLE, this.message)
		}
	},
}

const tutPOIIntro = {
	message: [`The monsters that appear in this area provide a tough challenge.`, `If you manage to defeat them your reward will be the choice of a cool <span class="ftue color gold">Enchantment</span> to power you up! These enchantments will be crucial to defeating bosses.`],

	showMessage() {
		showProximityMessage(DEFAULT_PROX_MESSAGE_TITLE, this.message)
	},
}

// HUB FTUE
const tutHubWorldIntro = {
	firstMessage: {
		title: 'Tutorial',
		description: [`Welcome to town!`, `This is where your LOOT will be delivered via Worm Mail.`],
		okButtonText: 'NEXT',
	},

	secondMessage: {
		title: 'Tutorial',
		description: [`Oh! Some kind soul has gifted you some cool LOOT and COINS!`, `Go to your Stash and take a look!`],
		okButtonText: 'OKAY',
	},

	showMessage() {
		showGenericInfoPromptUISequence([this.firstMessage, this.secondMessage], 'tutorial', () => {
			NengiClient.getInstance().sendCommand(new RequestArrowPositionCommand(ArrowPositionRequestValue.STASH))
		})
	},
}

const tutHubWorldServersOfflineIntro = {
	firstMessage: {
		title: 'Welcome to LOOT!',
		description: [`In <span class="ftue color gold">LOOT</span> you embark on <span class="ftue color teal">Adventure Runs</span> to slay monsters and acquire <span class="ftue color gold">LOOT</span>. Explore the vast, mysterious island to find challenges and epic bosses to defeat!`],
		okButtonText: 'AWESOME',
	},

	secondMessage: {
		title: 'The Storm',
		description: [`Currently, there is a <span class="ftue color rust">massive storm</span> just off the coast, so we cannot go on <span class="ftue color teal">Adventures</span>!<br>`, `(The Adventure servers are offline!)`],
		okButtonText: 'DARN',
	},

	thirdMessage: {
		title: 'Welcome to LOOT!',
		description: [`Check out this video to see what awaits you in LOOT!`],
		okButtonText: 'COOL',
	},

	shownOnce: true,

	showMessage() {
		showGenericInfoPromptUISequence([this.firstMessage, this.secondMessage, this.thirdMessage], 'tutorial', () => {
			const uiInst = UI.getInstance()
			console.log('emitting ftue video event')
			uiInst.emitAsyncEvent('ftueManager/showFTUEVideo', FTUEVideo.AGWOfflineIntro)
		})
	},
}

const tutNearStash = {
	message: [`To open your Stash, press 'F' when near the assistant. You can also access your Stash from the menu.`],

	showMessage() {
		showProximityMessage('', this.message)
	},
}

const tutAugmentWeapon = {
	openingMessage: [`Look! You have an <span class="ftue color gold">Augment</span> in your Stash. Let's use it to upgrade your weapon!`],

	augmentExplain1: {
		title: 'Tutorial',
		description: [`This weapon has 2 slots for <span class="ftue color rust">Augments</span>, but they're all empty at the moment. <span class="ftue color rust">Augments</span> change your weapon's behaviour.`],
		okButtonText: 'NEXT',
	},

	augmentExplain2: {
		title: 'Tutorial',
		description: [`Once you collect a few more <span class="ftue color rust">Augments</span> you'll be able to try different ones to find the most powerful combinations!`],
		okButtonText: 'NEXT',
	},

	augmentExplain3: {
		title: 'Tutorial',
		description: [`For now, let's go ahead and add your <span class="ftue color rust">Augment</span> to this weapon.`],
		okButtonText: 'OKAY',
	},

	showInfoMessage: [`Click on this weapon to show information about it.`],
	clickAugmentMessage: [`Click this weapon's <span class="ftue color rust">Augment</span> button now.`],
	clickAttachMessage: [`Click the ATTACH button on an augment to attach it to the weapon.`, `This costs COINS, but you have 1 extra.`],
	clickAddAugmentsMessage: [`Click the '<span class="ftue color rust">APPLY CHANGES</span>' button to confirm attaching this augment`],

	congrats1: {
		title: 'Tutorial',
		description: [`Congratulations! Your weapon has been augmented!`],
		okButtonText: 'NEXT',
	},

	congrats2: {
		title: 'Tutorial',
		description: [`You can check out the changes to your weapon by visiting the practice area just to the east of the Stash.`],
		okButtonText: 'NEXT',
	},

	congrats3: {
		title: 'Tutorial',
		description: [`Hey! The <span class="ftue colors rust">Worm Mail</span> has arrived with your LOOT!`, `You should head over to the <span class="ftue colors rust">Worm Mail</span> building to receive your items!`],
		okButtonText: 'OKAY',
	},

	showMessage() {
		const uiInst = UI.getInstance()
		// disable everything other than clicking on the player's staff
		uiInst.emitEvent('stash/setFtueMode', true)
		// disable switching UI
		uiInst.emitEvent('inGame/setPanelSwitchable', false)
		showGenericInfoPromptUI('Tutorial', this.openingMessage, 'OKAY', 'tutorial', () => {
			analyticsEventStartAugment()
			showTutTooltipWaitForMessage('Tutorial', this.showInfoMessage, 'weapon2', 'bottom', WaitCondition.PLAYER_INSPECTING_WEAPON, undefined, () => {
				showTutTooltipSequence([this.augmentExplain1, this.augmentExplain2, this.augmentExplain3], 'augmentRow', 'bottom', undefined, () => {
					// enable clicking the 'AUGMENT' button on the stash UI for the player's second weapon
					// enable switching to augment ui ONLY
					uiInst.emitEvent('inGame/setPanelSwitchable', true)
					uiInst.emitEvent('inGame/setPanelExclusiveSwitchable', { exclusiveSwitch: true, panelToSwitch: 'augmentationStation' })

					showTutTooltipWaitForMessage('Tutorial', this.clickAugmentMessage, 'weapon2AugmentButton', 'bottom', WaitCondition.OPENED_AUGMENT_UI, undefined, () => {
						//disable switching UI
						uiInst.emitEvent('inGame/setPanelExclusiveSwitchable', { exclusiveSwitch: false }) //not needed to disable switching, but this isn't wanted anymore
						uiInst.emitEvent('inGame/setPanelSwitchable', false)

						//disable everything other than the attach button on the newbie augment
						uiInst.emitEvent('augmentationStation/setFTUEMode', true)
						showTutTooltipWaitForMessage('Tutorial', this.clickAttachMessage, 'newbieAugment', 'bottom', WaitCondition.PLAYER_CLICKED_ATTACH_AUG, undefined, () => {
							showTutTooltipWaitForMessage('Tutorial', this.clickAddAugmentsMessage, 'addAugments', 'top', WaitCondition.PLAYER_ATTACHED_AUGMENT, 'right: 52px;', () => {
								//UI back to normal
								uiInst.emitEvent('augmentationStation/setFTUEMode', false)
								uiInst.emitEvent('stash/setFtueMode', false)
								uiInst.emitEvent('inGame/setPanelSwitchable', true)

								setFlagCriteriaDone('hasEquippedAnAugment')
								showGenericInfoPromptUISequence([this.congrats1, this.congrats2, this.congrats3], 'tutorial', () => {
									NengiClient.getInstance().sendCommand(new RequestArrowPositionCommand(ArrowPositionRequestValue.WORM_MAIL_HUB))
									analyticsEventFinishAugment()
								})
							})
						})
					})
				})
			})
		})
	},
}

const tutWormDeliveryNPCProximity = {
	message: [`To open your <span class="ftue colors rust">Worm Mail</span>, press 'F' when near the assistant. You can also access your <span class="ftue colors rust">Worm Mail</span> from the menu.`],

	showMessage() {
		const ftueGetFlag = UI.getInstance().store.getters['ftueManager/getFlag']
		const doneAugmentTut = ftueGetFlag('openedStashNPC')

		if (doneAugmentTut) {
			showProximityMessage('', this.message)
		}
	},
}

const tutWormDeliveryIntro = {
	blockedMessage: [`The assistant says that you should come back once your <span class="ftue colors rust">Worm Mail</span> has arrived.  You should check out your Stash first.`],

	introMessage: {
		title: 'Tutorial',
		description: [`Any LOOT sent through the Worm Mail during a run is delivered here.`],
		okButtonText: 'OH?',
	},

	stashMessage: {
		title: 'Tutorial',
		description: [`This button moves the selected item into your Stash.`],
		okButtonText: 'OKAY',
	},

	furnaceMessage: {
		title: 'Tutorial',
		description: [`This button places the item into one of your furnaces to smelt it down for COINS.`],
		okButtonText: 'GOT IT',
	},

	marketMessage: {
		title: 'Tutorial',
		description: [`This button lets you list the item on the Marketplace for <span class="ftue color gold">PRISM SCALES</span>.`],
		okButtonText: 'SWEET',
	},

	pitMessage: {
		title: 'Tutorial',
		description: [`You can also throw items into the Trash Pit of Chances.`, `Extra items you have no use for can be tossed into the pit. Throw in enough and you might just get something back! However, the inner workings of the pit are still a mystery...`],
		okButtonText: 'COOL',
	},

	finalMessage: {
		title: 'Tutorial',
		description: [`Now you're ready to customize your loadout and depart for another adventure in Fortuna!`],
		okButtonText: `LET'S GO!`,
	},

	showMessage() {
		const ftueGetFlag = UI.getInstance().store.getters['ftueManager/getFlag']
		const doneAugmentTut = ftueGetFlag('openedStashNPC')

		if (!doneAugmentTut) {
			showGenericInfoPromptUI('Tutorial', this.blockedMessage, 'OKAY', 'tutorial')
		} else {
			// disable switching UI
			const uiInst = UI.getInstance()
			uiInst.emitEvent('inGame/setPanelSwitchable', false)

			showGenericInfoPromptUISequence([this.introMessage], 'tutorial', () => {
				showTutTooltipSequence([this.stashMessage], 'wormDeliveryStash', 'top', 'right: 52px;', () => {
					showTutTooltipSequence([this.furnaceMessage], 'wormDeliveryFurnace', 'top', 'right: 52px;', () => {
						showTutTooltipSequence([this.marketMessage], 'wormDeliveryMarket', 'top', 'right: 52px;', () => {
							showTutTooltipSequence([this.pitMessage], 'wormDeliveryPit', 'top', 'right: 52px;', () => {
								showGenericInfoPromptUISequence([this.finalMessage], 'tutorial', () => {
									//enable switching UI
									uiInst.emitEvent('inGame/setPanelSwitchable', true)
									setProximityMessageVisible(false)

									setFlagCriteriaDone('openedWormDelivery')
									NengiClient.getInstance().sendCommand(new RequestArrowPositionCommand(ArrowPositionRequestValue.DEPARTURE_HUB))
								})
							})
						})
					})
				})
			})
		}
	},
}

const tutBoatLaunchIntro = {
	blockedMessage: [`This is where you'll depart for Fortuna!`, `But first you should head over to your Stash and check out your LOOT.`],
	message: [`If you're ready to depart for Fortuna, go ahead and click 'Depart'!`],

	showMessage() {
		const uiInst = UI.getInstance()
		uiInst.emitEvent('departure/updatedDepartureTab', PanelTabs.OVERVIEW)
		const ftueGetFlag = uiInst.store.getters['ftueManager/getFlag']
		const doneAugmentTut = ftueGetFlag('openedStashNPC')

		if (doneAugmentTut) {
			showGenericInfoPromptUI('Tutorial', this.message, 'OKAY', 'tutorial')
		} else {
			showGenericInfoPromptUI('Tutorial', this.blockedMessage, 'GOT IT', 'tutorial')
		}
	},
}

const tutSmeltingFurnaceIntro = {
	blockedMessage: [`The furnaces are where you can smelt LOOT that you don’t want to keep in return for COINS`, `but before you do any of that you should check out the free LOOT in your Stash!`],
	message: [`The furnaces are where you can smelt LOOT that you don't want to keep in return for COINS. You'll need COINS to do various things around town.`],

	showMessage() {
		const ftueGetFlag = UI.getInstance().store.getters['ftueManager/getFlag']
		const doneAugmentTut = ftueGetFlag('openedStashNPC')

		if (doneAugmentTut) {
			showGenericInfoPromptUI('Tutorial', this.message, 'OKAY', 'tutorial')
		} else {
			showGenericInfoPromptUI('Tutorial', this.blockedMessage, 'OKAY', 'tutorial')
		}
	},
}

const tutMarketplaceIntro = {
	blockedMessage: [`This is the Marketplace.`, `You can use <span class="ftue color gold">PRISM SCALES</span> here to buy and sell items with other players,`, `but first you should check out your Stash and your free LOOT!`],

	message1: {
		title: 'Marketplace',
		description: [`This is the Marketplace.<br><br>You can use <span class="ftue color gold">PRISM SCALES</span> here to buy and sell items with other players.`],
		okButtonText: 'OH?',
	},
	message2: {
		title: 'Marketplace',
		description: [`The Marketplace is a completely <span class="ftue color teal">player-driven economy</span>, where you can trade valuable LOOT.`],
		okButtonText: 'NICE',
	},
	message3: {
		title: 'Marketplace',
		description: [
			`You can list <span class="ftue color gold">${FREE_LISTINGS_PER_DAY}</span> item(s) per day <span class="ftue color teal">for free</span>, allowing you to acquire <span class="ftue color gold">PRISM SCALES</span> without spending any money. In this way, you can start trading items at no cost!`,
		],
		okButtonText: 'COOL!',
	},

	showMessage() {
		const ftueGetFlag = UI.getInstance().store.getters['ftueManager/getFlag']
		const doneAugmentTut = ftueGetFlag('openedStashNPC')

		if (doneAugmentTut) {
			showGenericInfoPromptUISequence([this.message1, this.message2, this.message3], 'tutorial', () => {
				setFlagCriteriaDone('hasOpenedMarketplace')
			})
			// showGenericInfoPromptUI('Tutorial', [this.message1, this.message2, this.message3], 'OKAY', 'tutorial')
		} else {
			showGenericInfoPromptUI('Tutorial', this.blockedMessage, 'OKAY', 'tutorial')
		}
	},
}

const tutMTXShopIntro = {
	message1: {
		title: 'Premium Store',
		description: [`This is the Premium Store. <br /><br /> Use COINS and PRISM SCALES to buy unique cosmetics, items, and other perks.`],
		okButtonText: 'NEXT',
	},
	message2: {
		title: 'Premium Store',
		description: [`Need more COINS? Head over to the furnace to smelt your LOOT, or slay some monsters in Fortuna!`],
		okButtonText: 'NEXT',
	},

	customizePlayerTooltip: {
		title: 'Tutorial',
		description: [`Change your look here.`],
		okButtonText: 'NEXT',
	},
	filterToolTip: {
		title: 'Tutorial',
		description: [`Use the drop down to <br /> sort items by category.`],
		okButtonText: 'NEXT',
	},
	nftToolTip: {
		title: 'Tutorial',
		description: [`For more information about NFTs and PRISM SCALES, click the "NFT?" button.`],
		okButtonText: 'NEXT',
	},

	showMessage() {
		showGenericInfoPromptUISequence([this.message1, this.message2], 'tutorial', () => {
			const uiInst = UI.getInstance()
			uiInst.emitEvent('inGame/setPanelSwitchable', false)
			showTutTooltipSequence([this.filterToolTip], 'mtxFilterDropDown', 'bottom', 'right: 52px;', () => {
				showTutTooltipSequence([this.customizePlayerTooltip], 'mtxCustomizeButton', 'top', 'right: 52px;', () => {
					uiInst.emitEvent('inGame/setPanelSwitchable', true)
					setFlagCriteriaDone('hasOpenedMtxStore')
				})
			})
		})
	},
}

const tutGeneralStoreIntro = {
	blockedMessage: [`This is the General Store.`, `You can spend your COINS here later, first let's check the Stash for your free LOOT!`],
	message: [`This is the General Store.`, `You can spend COINS to buy cool stuff here.<br><br>You can get more COINS by smelting unwanted LOOT over at the furnaces, or by slaying monsters in Fortuna.`],

	showMessage() {
		const ftueGetFlag = UI.getInstance().store.getters['ftueManager/getFlag']
		const doneAugmentTut = ftueGetFlag('openedStashNPC')

		if (!doneAugmentTut) {
			showGenericInfoPromptUI('Tutorial', this.blockedMessage, 'OKAY', 'tutorial')
		} else {
			showGenericInfoPromptUI('Tutorial', this.message, 'OKAY', 'tutorial')
			setFlagCriteriaDone('hasOpenedGeneralStore')
		}
	},
}

const tutIndentificationIntro = {
	blockedMessage: [`Some of the LOOT you find will be unidentified.`, `After you've checked out your Stash you will be able to identify that loot here.`],
	message: [`Some of the LOOT you find will be unidentified.`, `You can spend COINS here to identify such LOOT.`],

	showMessage() {
		const ftueGetFlag = UI.getInstance().store.getters['ftueManager/getFlag']
		const doneAugmentTut = ftueGetFlag('openedStashNPC')

		if (!doneAugmentTut) {
			showGenericInfoPromptUI('Tutorial', this.blockedMessage, 'OKAY', 'tutorial')
		} else {
			showGenericInfoPromptUI('Tutorial', this.message, 'OKAY', 'tutorial')
		}
	},
}

const tutTrashPitIntro = {
	blockedMessage: [`Ah, the Trash Pit of Chances!`, `Come back here when you’ve checked out your Stash and I’ll tell you all about it.`],
	message: [`This is the mysterious Trash Pit of Chances.`, `Excess loot which you have no use for can be tossed into the Trash Pit of Chances.<br><br>Throw in enough and you might just get something back! It's inner workings are still a mystery...`],

	showMessage() {
		const ftueGetFlag = UI.getInstance().store.getters['ftueManager/getFlag']
		const doneAugmentTut = ftueGetFlag('openedStashNPC')

		if (doneAugmentTut) {
			showGenericInfoPromptUI('Tutorial', this.message, 'OKAY', 'tutorial')
		} else {
			showGenericInfoPromptUI('Tutorial', this.blockedMessage, 'OKAY', 'tutorial')
		}
	},
}

const tutCosmeticIntro = {
	message: [`Fancy a change of appearance?`, `You can switch up your look here. If you'd like more variety, check out the Premium Store located elsewhere in town!`],

	showMessage() {
		showGenericInfoPromptUI('Tutorial', this.message, 'OKAY', 'tutorial')
		setFlagCriteriaDone('hasOpenedCosmeticsUI')
	},
}

const tutLeaderboardIntro = {
	message: [`The leaderboard ranks all adventurers in Fortuna, so you can see where you stand against your companions.`],

	showMessage() {
		showGenericInfoPromptUI('Tutorial', this.message, 'OKAY', 'tutorial')
		setFlagCriteriaDone('hasOpenedLeaderboardUI')
	},
}

// ======== Second Run =========

const tutSecondAdventureRunIntro = {
	message: [
		`Welcome back to <span class="ftue color teal">Fortuna</span>! Remember: `,
		`(1) Defeat <span class="ftue color teal">bosses</span> to progress to the next area,`,
		`(2) Each area has an <span class="ftue color teal">Outpost</span>. Each Outpost has a <span class="ftue colors rust">Worm Mail</span>.`,
		`(3) If you die or end your run, any unsent loot <span class="ftue color teal">will be lost</span>, and you will return to town.`,
	],

	showMessage() {
		const ftueGetFlag = UI.getInstance().store.getters['ftueManager/getFlag']
		const doneFirstTut = ftueGetFlag('openedWormMailNPC')

		if (doneFirstTut) {
			showGenericInfoPromptUI('Welcome back!', this.message, 'OKAY', 'tutorial')
			setFlagCriteriaDone('hasEnteredSecondRun')
			setFlagCriteriaDone('hasDepartedOnARun')
		}
	},
}

const tutEndWorldInaccessibleProximity = {
	message: [`The bridge is uncrossable until the final boss is defeated.`],

	showMessage() {
		const playerBeatBoss = UI.getInstance().store.getters['ftueManager/isFinalBossDefeated']
		if (!playerBeatBoss) {
			showProximityMessage(DEFAULT_PROX_MESSAGE_TITLE, this.message)
		}
	},
}

const tutEndWorldAccessibleProximity = {
	message: [`Defeating the final boss has allowed you to cross this bridge.`],

	showMessage() {
		showProximityMessage(DEFAULT_PROX_MESSAGE_TITLE, this.message)
	},
}

const tutPlayerLowHealth = {
	message: [`You are low on health! 'Instant Heal' items are available at the <span class="ftue color teal">Outpost</span> shop. Monsters will also drop hearts occasionally!`],

	showMessage() {
		const uiInst = UI.getInstance()

		const currentHealth = parseInt(uiInst.store.getters['hud/health'], 10)

		if (currentHealth < 50) {
			showProximityMessage(DEFAULT_PROX_MESSAGE_TITLE, this.message)
		}
	},
}

const tutPartyPlayIntro = {
	blockedMessage: [`Parties will be enabled once you've started your second adventure run.`],

	message1: {
		title: 'Good hustle!',
		description: [`Do you ever get lonely on your adventures? You can party up with other players!`],
		okButtonText: 'HOW?',
	},

	message2: {
		title: 'Parties',
		description: [`<div style="height: 400px;"><img src="${webps['party-create']}" align="left"/><div style="position:relative;top:50px; left: 11px;">Press the 'G' key to open the party menu, or select it through the hamburger menu at the top of the screen.<div><br>When you create a party, other adventurers can join you using your party ID!</div></div></div>`],
		okButtonText: 'SWEET',
	},

	message3: {
		title: 'Parties',
		description: [`While in a party, the leader may choose to depart when all party members are ready. The whole party will depart together.`, `<img src="${webps['party-depart']}"/>`],
		okButtonText: 'OKAY',
	},

	message4: {
		title: 'Parties',
		description: [`That's it! Stay safe out there.`, `<img src="${webps['party-code']}"/>`],
		okButtonText: 'ONWARD!',
	},

	showMessage() {
		const ftueGetFlag = UI.getInstance().store.getters['ftueManager/getFlag']
		const startedSecondRun = ftueGetFlag('startedSecondRun')

		if (startedSecondRun) {
			showGenericInfoPromptUISequence([this.message1, this.message2, this.message3, this.message4], 'tutorial', () => {
				completeFlagsFromCriteria('partyPlayDone')
			})
		} else {
			showGenericInfoPromptUI('Tutorial', this.blockedMessage, 'OKAY', 'tutorial')
		}
	},
}

const tutDifficultyIntro = {
	message1: {
		title: 'Difficulty',
		description: [`Have you seen other adventurers in <span class="ftue color teal">Fortuna</span>?<br/><br/>LOOT.io includes a unique system which allows players of any skill level to play alongside one another.`],
		okButtonText: 'NICE',
	},

	message2: {
		title: 'Difficulty',
		// swap to the desc when Brutal/Nasty enabled
		description: [[
			`<span class="ftue color teal">Normal</span> and <span class="ftue color teal">Hard</span> difficulty levels are different worlds.`,
			// `<span class="ftue color teal">Normal</span>, <span class="ftue color teal">Hard</span>, <span class="ftue color teal">Brutal</span>, and <span class="ftue color teal">Nasty</span> difficulty levels are different worlds`,
			`You may depart to any world, but will only be able to adventure with players in the same world as you.<br/><br/>`,
			`If you are used to playing on `,
			`<span class="ftue color teal">Hard</span>`,
			// `<span class="ftue color teal">Brutal</span>`,
			`you can drop down to <span class="ftue color teal">Normal</span> and keep earning the same loot you're used to -- but monsters will be easier, and items will drop less frequently.`,
		].join(' ')],
		okButtonText: 'COOL',
	},

	message3: {
		title: 'Difficulty',
		description: [`Once you've defeated the Prism Dragon on <span class="ftue color teal">Hard</span> difficulty you will unlock Soul Cycles! Soul Cycle modifiers let you customize how difficulty increases as you progress.<br/><br/><img src="${gifs.lootPickup}"/>`],
		okButtonText: 'OH?',
	},

	message4: {
		title: 'Difficulty',
		description: [`We'll tell you more when the time comes. For now, the Prism Dragon awaits on <span class="ftue color teal">Hard</span> difficulty!`],
		okButtonText: 'ONWARD!',
	},

	showMessage() {
		const startedScondRun = UI.getInstance().store.getters['ftueManager/getFlag']('startedSecondRun')
		if (startedScondRun) {
			showGenericInfoPromptUISequence([this.message1, this.message2, this.message3, this.message4], 'tutorial', () => {
				completeFlagsFromCriteria('prePPDDone')
			})
		}
	},
}

const tutSoulCyclesUnlocked = {
	introMessage: [`Congratulations, you've unlocked Soul Cycles!`],

	lootLevelIntroMessage: {
		title: 'Soul Cycles',
		description: [`New departure options are now available. Fill the Soul meter before departing to keep progressing and earning better loot.`],
		okButtonText: 'GOT IT',
	},

	lootLevelNotFull: {
		title: 'Soul Cycles',
		description: [`You can depart without filling it, but a full meter means higher quality loot.`],
		okButtonText: 'NICE',
	},

	heatOptionsMessage: {
		title: 'Soul Cycles',
		description: [`Fill the meter by selecting modifiers. Mix and match options to customize your run!`],
		okButtonText: 'OKAY',
	},

	outroMessage: [`The points required to progress will increase when you've defeated the Prism Dragon with a full meter. Remember, more points means better LOOT!`, `Have fun experimenting!`],

	showMessage() {
		const uiInst = UI.getInstance()
		const maxWorldTier = uiInst.store.getters['boatLaunch/maxAllowedTier']

		if (maxWorldTier > PRESSURE_MENU_UNLOCK_TIERS_DEFEATED) {
			uiInst.emitEvent('departure/updatedDepartureTab', PanelTabs.DIFFICULTY)
			// disable switching UI
			uiInst.emitEvent('inGame/setPanelSwitchable', false)
			uiInst.emitEvent('boatLaunch/setFtueMode', true)

			showGenericInfoPromptUI('Tutorial', this.introMessage, 'NEXT', 'tutorial', () => {
				showTutTooltipSequence([this.lootLevelIntroMessage, this.lootLevelNotFull], 'lootLevelMeter', 'bottom', undefined, () => {
					showTutTooltipSequence([this.heatOptionsMessage], 'baneOptions', 'nodangle', 'right: 52px;', () => {
						showGenericInfoPromptUI('Tutorial', this.outroMessage, 'OKAY', 'tutorial', () => {
							//enable switching UI
							uiInst.emitEvent('boatLaunch/setFtueMode', false)
							uiInst.emitEvent('inGame/setPanelSwitchable', true)

							setFlagCriteriaDone('ppdExplainedDone')
						})
					})
				})
			})
		}
	},
}

PROXIMITY_MESSAGES_CONFIG.set(FTUESequence.FirstAdventureRunIntro, tutFirstAdventureRunIntro)
PROXIMITY_MESSAGES_CONFIG.set(FTUESequence.LootDropped, tutLootDropped)
PROXIMITY_MESSAGES_CONFIG.set(FTUESequence.LootInInventoryGoToOutpost, tutLootInInventoryGoToOutpost)
PROXIMITY_MESSAGES_CONFIG.set(FTUESequence.FullInventory, tutFullInventory)
PROXIMITY_MESSAGES_CONFIG.set(FTUESequence.NearOutpost, tutNearOutpost)
PROXIMITY_MESSAGES_CONFIG.set(FTUESequence.InsideOutpost, tutInsideOutpost)
PROXIMITY_MESSAGES_CONFIG.set(FTUESequence.FirstWormMail, tutFirstWormMail)
PROXIMITY_MESSAGES_CONFIG.set(FTUESequence.OutpostShop, tutOutpostShop)
PROXIMITY_MESSAGES_CONFIG.set(FTUESequence.EndRunGuyPriorToWormMail, tutEndRunGuyPriorToWormMail)
PROXIMITY_MESSAGES_CONFIG.set(FTUESequence.BossFogWall, tutBossFogWall)
PROXIMITY_MESSAGES_CONFIG.set(FTUESequence.ClearedFogWall, tutClearedFogWall)
PROXIMITY_MESSAGES_CONFIG.set(FTUESequence.BossIntro, tutBossIntro)
PROXIMITY_MESSAGES_CONFIG.set(FTUESequence.POIIntro, tutPOIIntro)
PROXIMITY_MESSAGES_CONFIG.set(FTUESequence.HubWorldIntro, tutHubWorldIntro)
PROXIMITY_MESSAGES_CONFIG.set(FTUESequence.NearStash, tutNearStash)
PROXIMITY_MESSAGES_CONFIG.set(FTUESequence.AugmentWeapon, tutAugmentWeapon)
PROXIMITY_MESSAGES_CONFIG.set(FTUESequence.WormDeliveryNPCProximity, tutWormDeliveryNPCProximity)
PROXIMITY_MESSAGES_CONFIG.set(FTUESequence.WormDeliveryIntro, tutWormDeliveryIntro)
PROXIMITY_MESSAGES_CONFIG.set(FTUESequence.BoatLaunchIntro, tutBoatLaunchIntro)
PROXIMITY_MESSAGES_CONFIG.set(FTUESequence.SmeltingFurnaceIntro, tutSmeltingFurnaceIntro)
PROXIMITY_MESSAGES_CONFIG.set(FTUESequence.MarketplaceIntro, tutMarketplaceIntro)
PROXIMITY_MESSAGES_CONFIG.set(FTUESequence.MTXShopIntro, tutMTXShopIntro)
PROXIMITY_MESSAGES_CONFIG.set(FTUESequence.GeneralStoreIntro, tutGeneralStoreIntro)
PROXIMITY_MESSAGES_CONFIG.set(FTUESequence.IndentificationIntro, tutIndentificationIntro)
PROXIMITY_MESSAGES_CONFIG.set(FTUESequence.TrashPitIntro, tutTrashPitIntro)
PROXIMITY_MESSAGES_CONFIG.set(FTUESequence.CosmeticIntro, tutCosmeticIntro)
PROXIMITY_MESSAGES_CONFIG.set(FTUESequence.LeaderboardIntro, tutLeaderboardIntro)
PROXIMITY_MESSAGES_CONFIG.set(FTUESequence.SecondAdventureRunIntro, tutSecondAdventureRunIntro)
PROXIMITY_MESSAGES_CONFIG.set(FTUESequence.EndWorldInaccessibleProximity, tutEndWorldInaccessibleProximity)
PROXIMITY_MESSAGES_CONFIG.set(FTUESequence.EndWorldAccessibleProximity, tutEndWorldAccessibleProximity)
PROXIMITY_MESSAGES_CONFIG.set(FTUESequence.PlayerLowHealth, tutPlayerLowHealth)
PROXIMITY_MESSAGES_CONFIG.set(FTUESequence.PartyPlayIntro, tutPartyPlayIntro)
PROXIMITY_MESSAGES_CONFIG.set(FTUESequence.DifficultyIntro, tutDifficultyIntro)
PROXIMITY_MESSAGES_CONFIG.set(FTUESequence.SoulCyclesUnlocked, tutSoulCyclesUnlocked)
PROXIMITY_MESSAGES_CONFIG.set(FTUESequence.HubWorldServersOfflineIntro, tutHubWorldServersOfflineIntro)

export const RESET_SHOWN_ONCE_ON_RELOAD = [tutLootDropped, tutLootInInventoryGoToOutpost, tutFullInventory, tutHubWorldServersOfflineIntro]
