import nengi from 'nengi'

interface Player {
	partyId: string
	playerId: string
	ready: boolean
	dead: boolean
	disconnected: boolean
}

export default class PartyFlagsMessage {
	static protocol = {
		partyId: nengi.String,
		playerId: nengi.String,
		players: nengi.String,
	}

	partyId: string
	playerId: string
	players: string

	constructor(partyId: string, playerId: string, players: Player[]) {
		this.partyId = partyId
		this.playerId = playerId
		this.players = JSON.stringify(players)
	}
}
