import nengi from 'nengi'
import { uuid } from '../../utils/primitive-types'

class CancelListingCommand {
	static protocol = {
		listingId: nengi.String,
		itemId: nengi.String,
	}
	listingId: uuid
	itemId: uuid
	/**
	 * Creates an instance of CancelListingCommand. This will scan the Stash to find a matching item.
	 * @param {uuid} listingId Item ID
	 * @memberof CancelListingCommand
	 */
	constructor(listingId: uuid, itemId: uuid) {
		this.itemId = itemId
		this.listingId = listingId
	}
}

export default CancelListingCommand
