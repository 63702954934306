import { EnemyAI, FightingTriggers, IdleBehaviours, FightingBehaviours, LeashTriggers, LeashingBehaviours, AttackTypes, DeadBehaviours, ShotLeadPrecision, EnemyType, EnemyTargetingStyles } from '../../shared/ai-types'
import { BurstFireModes } from '../../../projectiles/shared/projectile-types'
import { SpineDataName, EnemyBipedSkinType } from '../../../models-animations/shared/spine-config'
import { EnemyDefaults } from './enemy-defaults'
import { Colors } from '../../../utils/colors'
import { ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { ParticleEffectType } from '../../../engine/shared/game-data/particle-config'
import { ENEMY_DEFAULT_HEALTH } from '../../../engine/shared/game-data/enemy-formulas'
import { Ability, AbilitySelectionStyles, AbilityTargets, AbilityTargetSelectionStyle, AbilityType, ActionCriteria, CriteriaSatisfactionQuantity, CriteriaTarget, CriteriaType } from '../action-types'
import { attackRatedAbilityList } from '../action-criteria/ability-helpers'
import { AnimationTrack } from '../../../models-animations/shared/animation-track'
import { alwaysTrueCriteria } from './abilities/common-abilities'
import { BuffIdentifier } from '../../../buffs/shared/buff.shared'
import { Vector } from 'sat'
import { ENEMY_NAMES } from './enemy-names'
import { deepClone } from '../abilities.test'
import { worldDifficultyBrutalCriterion } from '../action-criteria/action-criteria-helpers'

const playerIsInMeleeRangeCriteria: ActionCriteria = {
	satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
	criterias: [
		{
			criteriaTargets: CriteriaTarget.PLAYERS,
			criteriaType: CriteriaType.WITHIN_RANGE,
			criteriaValue: 140,
			minimumTargetsThatSatisfy: 1,
		},
	],
}
const playerIsInMeleeRangeBrutalCriteria: ActionCriteria = {
	satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
	criterias: [
		{
			criteriaTargets: CriteriaTarget.PLAYERS,
			criteriaType: CriteriaType.WITHIN_RANGE,
			criteriaValue: 200,
			minimumTargetsThatSatisfy: 1,
		},
		worldDifficultyBrutalCriterion,
	],
}

const animationTimes = {
	[AnimationTrack.SHOOT]: 0.3,
}

const batSqueakDebuff: Ability = {
	debugName: 'bat squeak',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.CLOSEST,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		speed: 10,
		colliderRadius: 120,
		baseLightningDamage: 75,
		lifespanInSeconds: 0.2,
		burstMode: BurstFireModes.STRAIGHT,
		color: Colors.darkRed,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_LIGHTNING_SHOOT,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_LIGHTNING_SHOOT,
		applyBuffsOnHit: [BuffIdentifier.EnemyCrabMovementSpeedSlowDifficult],
	},
}

export const batSqueakDebuffBrutal = deepClone(batSqueakDebuff)
batSqueakDebuffBrutal.projectileConfig.colliderRadius = 140
batSqueakDebuffBrutal.projectileConfig.baseLightningDamage = 100

const batBeam: Ability = {
	debugName: 'bat shot',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.CLOSEST,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		speed: 600,
		colliderRadius: 20,
		baseLightningDamage: 75,
		lifespanInSeconds: 1.1,
		burstMode: BurstFireModes.STRAIGHT,
		color: Colors.darkRed,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_LIGHTNING_HIT,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_LIGHTNING_TRAIL,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_LIGHTNING_SHOOT,
	},
	attackOffset: new Vector(0, -50),
	ignoreAngleOnWeaponOffset: true,
}

const eyebat: EnemyAI = {
	name: ENEMY_NAMES.EYEBAT,
	type: EnemyType.BASIC,
	appearance: {
		asset: SpineDataName.EYEBAT,
		skin: EnemyBipedSkinType.DEFAULT,
	},
	general: {
		enemyLevel: 1,
		experienceWhenKilled: 0.4,
	},
	baseAttributes: {
		...EnemyDefaults.baseAttributes,
		colliders: [
			{
				type: ColliderType.Circle,
				radius: 28,
				position: [0, -45],
				traits: ColliderTraits.BlockMovementAndProjectile,
			},
		],
		decelerationRate: 2.5,
		turningRatePerSecondInDegrees: 360,

		movementSpeed: 300,
		attackRate: 1666,

		maxHealth: (ENEMY_DEFAULT_HEALTH * 3) / 4,
	},
	soundEffects: {
		attack: 'Projectile_Blip',
		impact: 'SFX_Impact_Enemy_Flesh',
	},
	states: {
		idling: {
			behaviour: IdleBehaviours.MEANDER,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			transitionToFighting: {
				trigger: FightingTriggers.AGGRESSIVE_PROXIMITY,
				radius: 700,
			},
			meander: {
				radius: 500,
				speedMultiplier: 1,
				minRestTime: 0.5,
				maxRestTime: 10,
			},
			aggroOnHit: true,
		},
		fighting: {
			behaviour: FightingBehaviours.STRAFE_WHILE_ATTACKING,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			attackType: AttackTypes.PROJECTILE,
			engagementMaxDistance: 700,
			engagementMinDistance: 0,
			modelCenterOffset: 45,
			movementMaxDistance: 400,
			movementMinDistance: 130,
			brain: {
				actions: [
					{
						// debuff squeak
						priority: 1,
						actionCriteria: playerIsInMeleeRangeBrutalCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							//abilityOptions: [[1,  crabMeleeAttackAbility, 15]],
							abilityOptions: [attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, batSqueakDebuffBrutal, 10)],
						},
					},
					{
						// debuff squeak
						priority: 2,
						actionCriteria: playerIsInMeleeRangeCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							//abilityOptions: [[1,  crabMeleeAttackAbility, 15]],
							abilityOptions: [attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, batSqueakDebuff, 15)],
						},
					},
					{
						// attack
						priority: 5,
						actionCriteria: alwaysTrueCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							abilityOptions: [attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, batBeam, 40)],
						},
					},
				],
			},
			attackOptions: [],
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 0.5,
			transitionToLeashing: {
				trigger: LeashTriggers.RADIUS_EXCEEDED,
				radius: 2000,
				distanceTravelled: 4000,
			},
		},
		leashing: {
			behaviour: LeashingBehaviours.MOVE,
			speedMultiplier: 4,
		},
		fleeing: {},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 0.2,
		},
	},
}

export default eyebat
