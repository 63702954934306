import nengi from 'nengi'
import { AnyCollider } from '../../collision/shared/colliders'

class TerrainProp {
	static protocol = {
		propId: { type: nengi.String },
		x: { type: nengi.Float32 },
		y: { type: nengi.Float32 },
		visibilityRadius: { type: nengi.Float32 },
		skin: { type: nengi.String },
	}

	nid: number
	propId: string
	x: number = 0
	y: number = 0
	visibilityRadius: number = 0
	skin: string = ''

	colliders: AnyCollider[]
}

export default TerrainProp
