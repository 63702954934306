import nengi from 'nengi'

export default class PlayerMovedToBiome {
	static protocol = {
		biomeIdx: { type: nengi.Int10 },
	}

	biomeIdx: number

	constructor(biomeIdx: number) {
		this.biomeIdx = biomeIdx
	}
}
