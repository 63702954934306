/* eslint-disable @typescript-eslint/no-var-requires */
/* tslint:disable no-var-requires */
//export

const elementalImages = require('./assets/elemental*.png')

export const StatToTooltipName = {} as any
StatToTooltipName.skillSpeed = 'Speed'
StatToTooltipName.skillUsageSpeed = 'Usage Speed'
StatToTooltipName.skillDuration = 'Duration'
StatToTooltipName.skillCooldown = 'Cooldown'
StatToTooltipName.skillDuration = 'Cooldown'
StatToTooltipName.allDamageBoost = 'Damage'
StatToTooltipName.physicalDamage = 'Physical Damage'
StatToTooltipName.physicalDamageBoost = 'Physical Damage Boost'
StatToTooltipName.fireDamage = 'Fire Damage'
StatToTooltipName.fireDamageBoost = 'Fire Damage Boost'
StatToTooltipName.iceDamage = 'Ice Damage'
StatToTooltipName.iceDamageBoost = 'Ice Damage Boost'
StatToTooltipName.lightningDamage = 'Lightning Damage'
StatToTooltipName.lightningDamageBoost = 'Lightning Damage Boost'
StatToTooltipName.poisonDamage = 'Poison Damage'
StatToTooltipName.poisonDamageBoost = 'Poison Damage Boost'
StatToTooltipName.bleedChanceSum = 'Bleed Chance'
StatToTooltipName.bleedChance = 'Bleed Chance Boost'
StatToTooltipName.bleedChanceBoost = 'Bleed Chance Boost'
StatToTooltipName.bleedPotency = 'Bleed Potency'
StatToTooltipName.fireChance = 'Ignite Chance'
StatToTooltipName.fireChanceBoost = 'Ignite Chance Boost'
StatToTooltipName.igniteChance = 'Ignite Chance Boost'
StatToTooltipName.firePotency = 'Ignite Potency'
StatToTooltipName.ignitePotency = 'Ignite Potency'
StatToTooltipName.iceChance = 'Chill Chance'
StatToTooltipName.iceChanceBoost = 'Chill Chance Boost'
StatToTooltipName.chillChance = 'Chill Chance Boost'
StatToTooltipName.icePotency = 'Chill Potency'
StatToTooltipName.lightningChance = 'Shock Chance'
StatToTooltipName.lightningChanceBoost = 'Shock Chance Boost'
StatToTooltipName.shockChance = 'Shock Chance Boost'
StatToTooltipName.lightningPotency = 'Shock Potency'
StatToTooltipName.poisonChance = 'Poison Chance Boost'
StatToTooltipName.toxicChance = 'Poison Chance'
StatToTooltipName.poisonChanceBoost = 'Poison Chance Boost'
StatToTooltipName.poisonPotency = 'Poison Potency'
StatToTooltipName.attackSpeed = 'Attack Rate'
StatToTooltipName.attackRate = 'Attack Rate'
StatToTooltipName.chargeRate = 'Charge Rate'
StatToTooltipName.projectileCount = 'Projectiles'
StatToTooltipName.projectileCountBoost = 'Projectile Count Boost'
StatToTooltipName.volleySpread = 'Volley Spread'
StatToTooltipName.spread = 'Aim Variance'
StatToTooltipName.projectileSpeed = 'Projectile Speed'
StatToTooltipName.pierceCount = 'Pierce'
StatToTooltipName.chainCount = 'Chains'
StatToTooltipName.splashDamage = 'Splash Damage'
StatToTooltipName.splashRadius = 'Splash Radius'
StatToTooltipName.projectileSplashRadius = 'Splash Radius'
StatToTooltipName.beamLength = 'Beam Length'
StatToTooltipName.beamWidth = 'Beam Width'
StatToTooltipName.criticalChance = 'Critical Chance'
StatToTooltipName.criticalChanceBoost = 'Critical Chance Boost'
StatToTooltipName.criticalDamage = 'Critical Damage'
StatToTooltipName.critDamageBoost = 'Critical Damage'
StatToTooltipName.defense = 'Defense'
StatToTooltipName.fireResist = 'Fire Resist'
StatToTooltipName.iceResist = 'Ice Resist'
StatToTooltipName.lightningResist = 'Lightning Resist'
StatToTooltipName.poisonResist = 'Poison Resist'
StatToTooltipName.movementSpeed = 'Movement Speed'
StatToTooltipName.health = 'Health'
StatToTooltipName.shields = 'Shields'
StatToTooltipName.shieldRegenRate = 'Shield Regen Rate'
StatToTooltipName.maxRange = 'Max Range'
StatToTooltipName.allAilmentPotency = 'All Ailment Potency'
StatToTooltipName.damagingAilmentPotency = 'Damaging Ailment Potency'
StatToTooltipName.statusAilmentPotency = 'Status Ailment Potency'
StatToTooltipName.maxEnergyBoost = 'Max Energy Boost'
