import { throwIfNotFinite } from '../../utils/math'
import { getAbilityList } from './ability-selectors'
import { Ability, AbilityOptionallyWithWeightOrTiming, AbilityType, Action, getNumber } from './action-types'

export function checkActionDrivenEnemy(enemyName, actionDrivenEnemy) {
	if (actionDrivenEnemy.unengagedActions) {
		checkAllActionData(enemyName + '-unengagedActions', actionDrivenEnemy.unengagedActions)
	}
	checkAllActionData(enemyName + '-actions', actionDrivenEnemy.actions)
}

export function checkAllActionData(owner: string, actions: Action[]) {
	if (!actions.isUniqueOn((action) => action.priority)) {
		throw new Error(`actions of ${owner} do not have unique priorities: [${actions.map((a) => a.priority)}]`)
	}

	actions.forEach(checkActionData)
}

function checkActionData(action: Action) {
	action.actionAbilities.abilityOptions.forEach(checkAbilityData)
}

function checkAbilityData(abilityOrList: AbilityOptionallyWithWeightOrTiming) {
	const abilityList = getAbilityList(abilityOrList, typeof abilityOrList[0] === 'number')
	abilityList.forEach((ability) => {
		throwIfNotFinite(getNumber(ability.durationInGameTicks), `ability ${ability.debugName} has ${ability.durationInGameTicks} durationInGameTicks`)

		const errorMessage = getAbilityTypeError(ability)
		if (errorMessage) {
			throw new Error(`${errorMessage} in: ${JSON.stringify(ability)}`)
		}
	})
}

function getAbilityTypeError(ability: Ability) {
	// this is no longer required because of type-checking, however I'm keeping for future checks for each type
	switch (ability.abilityType) {
		case AbilityType.WAIT:
			break
		case AbilityType.FIRE_PROJECTILE:
			if (!ability.projectileConfig) {
				return `empty projectileConfig`
			}
			break
		case AbilityType.INSTANTLY_APPLY_EFFECT:
			if (!ability.buffToApply) {
				return `empty buffToApply`
			}
			break
		case AbilityType.SPAWN_ENEMY:
			if (!ability.spawningRules) {
				return `empty spawningRules`
			}
			break
		case AbilityType.SPAWN_GROUND_HAZARD:
			if (!ability.groundHazardRules) {
				return `empty groundHazardRules`
			}
			break
		case AbilityType.MOVE_TOWARDS_LOCATION:
			if (!ability.moveRules) {
				return `empty moveRules`
			}
			break
		case AbilityType.SET_COLLIDERS:
			if (!ability.colliderInfo) {
				return `empty colliderInfo`
			}
			break
		case AbilityType.BUFF_UNTIL_DAMAGE_RECEIVED_THRESHOLD:
			if (!ability.healRules) {
				return `empty healRules`
			}
			if (!ability.buffToApply) {
				return `empty buffToApply`
			}
			break
		case AbilityType.WAIT_FOR_COOLDOWN:
			break
		case AbilityType.TOGGLE_STATE:
			if (!ability.toggleStateRules) {
				return `empty toggleStateRules`
			}
			break
		case AbilityType.SPAWN_GROUND_HAZARD_MULTITARGET:
			if (!ability.groundHazardMultitargetRules) {
				return `empty groundHazardMultitargetRules`
			}
			break
		case AbilityType.SPAWN_ENEMIES_WHILE_BUFFED:
			if (!ability.spawnEnemiesWhileBuffedRules) {
				return `empty spawnEnemiesWhileBuffedRules`
			}
			break
		case AbilityType.AOE_PROJECTILE_AROUND_SELF:
			if (!ability.aoeProjectileAroundSelfRules) {
				return `empty aoeProjectileAroundSelfRules`
			}
			break
		default:
			return `📎 it looks like you're trying to create a new AbilityType. 📎 Why not consider adding a new case here to check for error?`
	}
	return undefined
}
