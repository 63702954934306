










import { mapState } from 'vuex'
import MainPanel from '../reusable-components/panels/main-panel.vue'

export default {
	name: 'StartUpError',
	components: {
		MainPanel,
	},
	computed: {
		...mapState('mainMenu', ['startUpError']),
	},
	methods: {},
}
