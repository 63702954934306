


























import { mapActions, mapGetters } from 'vuex'
import PanelDivider from '../panel-divider/panel-divider.vue'
import VueMarkdown from 'vue-markdown'

export default {
	name: 'GameNewsItem',
	components: {
		PanelDivider,
		VueMarkdown,
	},
	props: {
		gameNewsItems: {
			type: Array,
			required: true,
			default: () => {
				console.warn('Currently using the default array. Ensure the <GameNewsItem /> component has a custom attribute :game-news-items')
				return []
			},
		},
		onRowClickProp: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default on-row-button-click-prop handler for the <ListItemContainer /> component has been called. Did you forget to pass an :on-click handler to the component?')
			},
		},
		selectedArticle: {
			type: Number,
			required: false,
			default: null,
		},
	},
	computed: {
		...mapGetters('newsArticles', ['getLatestArticleId', 'getLastReadArticleId']),
	},
	methods: {
		checkIfLatestNewsRead(newsId) {
			const articleId = newsId.toString()
			if (this.getLatestArticleId === articleId) {
				if (this.getLastReadArticleId < this.getLatestArticleId) {
					return true
				} else if (this.getLastReadArticleId >= this.getLatestArticleId) {
					return false
				}
			}
		},
	},
}
