<template>
	<div id="text-input">
		<label class="label" :for="labelId">{{ labelText }}</label>
		<input ref="text-input" v-model="textInput" :type="inputType" :placeholder="placeHolder" :autocomplete="autoCompleteValue" :class="{ activeError: error, labelId }" :tabindex="tabIndex" @input="onChange()" />
	</div>
</template>

<script>
export default {
	name: 'TextInput',
	props: {
		labelId: {
			type: String,
			required: false,
			default: 'default',
		},
		placeHolder: {
			type: String,
			required: false,
			default: 'default',
		},
		inputType: {
			type: String,
			required: true,
			default: 'password',
		},
		autoCompleteValue: {
			type: String,
			required: false,
			default: 'off',
		},
		labelText: {
			type: String,
			required: false,
			default: 'default',
		},
		error: {
			type: [Boolean, String],
			required: false,
			default: false,
		},
		tabIndex: {
			type: Number,
			required: false,
		},
		clampInput: {
			type: Boolean,
			required: false,
			default: false,
		},
		maxValue: {
			type: Number,
			required: false,
		},
		minValue: {
			type: Number,
			required: false,
		}
	},
	data() {
		return {
			textInput: '',
		}
	},
	methods: {
		onChange() {
			if(this.clampInput) {
				let value = Math.round(parseInt(this.textInput, 10))

				if(isNaN(value) || value < this.minValue) {
					this.textInput = this.minValue.toString()
				}

				if(value > this.maxValue) {
					this.textInput = this.maxValue.toString()
				}
			}


			this.$emit('update-text-input', this.textInput)
		},
	},
}
</script>

<style lang="less" scoped>
@import '../../global/fonts/text.less';

#text-input {
	width: 100%;
	label {
		.text-input-label();
	}
	input {
		align-self: center;
		outline: none;
		padding: 6px 16px 6px 16px;
		height: 42px;
		border: none;
		width: 100%;
		background-color: #040e26 !important;
		.text-input();
		font-size: 24px;
		&.activeError {
			border: 2px red solid;
		}
		&:-webkit-autofill,
		&:-webkit-autofill:hover,
		&:-webkit-autofill:focus {
			border: 1px solid #e9e5ad;
			-webkit-text-fill-color: #e9e5ad;
			-webkit-box-shadow: 0 0 0px 1000px #040e26 inset;
			transition: background-color 5000s ease-in-out 0s;
			font-size: 24px;
			caret-color: white;
			
		}
	}
}
</style>
