import logger from '../../utils/client-logger'

export const subscriptionUIState = () => {
	logger.debug('Initializing subscription module')
	return {
		namespaced: true,
		state: {
			activeSubTier: 'silver',
		},
		getters: {
			getActiveSubTier(state) {
				return state.activeSubTier
			},
		},
		mutations: {},
		// Create mutation/action that will update the user activeSubTier 'silver', 'gold', 'diamond
		actions: {},
	}
}
