import { EnemyAI, FightingTriggers, IdleBehaviours, FightingBehaviours, LeashTriggers, LeashingBehaviours, AttackTypes, DeadBehaviours, ShotLeadPrecision, EnemyType, EnemyTargetingStyles } from '../../shared/ai-types'
import { ModType } from '../../../projectiles/shared/projectile-types'
import { SpineDataName, EnemyBipedSkinType } from '../../../models-animations/shared/spine-config'
import { EnemyDefaults } from './enemy-defaults'
import { Colors } from '../../../utils/colors'
import { ParticleEffectType } from '../../../engine/shared/game-data/particle-config'
import { Vector } from 'sat'
import { ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { ENEMY_DEFAULT_HEALTH } from '../../../engine/shared/game-data/enemy-formulas'
import { Ability, AbilitySelectionStyles, AbilityTargets, AbilityTargetSelectionStyle, AbilityType, CriteriaSatisfactionQuantity, CriteriaTarget, CriteriaType, } from '../action-types'
import { alwaysTrueCriteria, waitAbility } from './abilities/common-abilities'
import { ActionCriteriaHelpers, worldDifficultyBrutalCriterion,  } from '../action-criteria/action-criteria-helpers'
import { BuffIdentifier } from '../../../buffs/shared/buff.shared'
import { attackRatedAbilityList } from '../action-criteria/ability-helpers'
import { AnimationTrack } from '../../../models-animations/shared/animation-track'
import { ENEMY_NAMES } from './enemy-names'
import { deepClone } from '../abilities.test'



const animationTimes = {
	[AnimationTrack.SHOOT]: 0.3, // actual frame at 0.65 or so, but their attack is too long. TODO2 - needs different implementation
}

const shortRangeAbility: Ability = {
	debugName: 'shortRangeAbility',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.CLOSEST,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		projectileCount: 1,
		speed: 900,
		colliderRadius: 10,
		lifespanInSeconds: 0.27,
		modifiers: [{ modType: ModType.STRAIGHT }],
		baseLightningDamage: 30,
		burstCount: 0,
		color: Colors.yellow,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_SHAMBLINGMOUND,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
		pierceCount: 5,
	},
}

export const shortRangeAbilityBrutal = deepClone(shortRangeAbility)
	shortRangeAbilityBrutal.projectileConfig.lifespanInSeconds = 0.35
	shortRangeAbilityBrutal.projectileConfig.baseLightningDamage = 40
	shortRangeAbilityBrutal.projectileConfig.colliderRadius = 12


const meleeAbility: Ability = {
	debugName: 'meleeAbility',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.CLOSEST,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		projectileCount: 1,
		speed: 100,
		colliderRadius: 130,
		lifespanInSeconds: 0.2,
		modifiers: [{ modType: ModType.STRAIGHT }],
		burstCount: 0,
		color: Colors.yellow,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		pierceCount: 5,
	},
}

export const movementSpeedBuffAbility: Ability = {
	debugName: 'block',
	abilityType: AbilityType.INSTANTLY_APPLY_EFFECT,
	buffToApply: BuffIdentifier.EnemyMosquitoMovementSpeedBoost,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.MYSELF,
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
}

const mosquito: EnemyAI = {
	name: ENEMY_NAMES.MOSQUITO,
	type: EnemyType.BASIC,
	appearance: {
		asset: SpineDataName.MOSQUITO,
		skin: EnemyBipedSkinType.DEFAULT,
		flying: true,
		mixSettings: [],
	},
	general: {
		enemyLevel: 1,
		experienceWhenKilled: 0.2,
	},
	baseAttributes: {
		...EnemyDefaults.baseAttributes,
		visibilityRadius: 1,
		colliders: [
			{
				type: ColliderType.Circle,
				radius: 40,
				traits: ColliderTraits.BlockMovementAndProjectile,
				position: [0, -60],
			},
			/*{
				type: ColliderType.Circle,
				radius: 40,
				traits: ColliderTraits.BlockMovement,
				position: [0, 0],
			},
			*/
		],
		movementSpeed: 200,
		decelerationRate: 10,
		turningRatePerSecondInDegrees: 360,
		attackOffset: new Vector(0, -70),
		lootDropOffset: new Vector(0, 0),
		damageConfig: null,

		physicalDamage: 0,
		fireDamage: 0,
		iceDamage: 0,
		lightningDamage: 0,
		poisonDamage: 0,
		critChance: 0,
		critDamage: 2.0,

		maxHealth: (ENEMY_DEFAULT_HEALTH * 2) / 3,
		defense: 0,
		fireResist: 0,
		poisonResist: 0,
		iceResist: 0,
		lightningResist: 0,

		lootDropProbability: 1,
		lootDropQuantity: 0.75,
		lootDropRarity: 1,
	},
	soundEffects: {
		attack: 'Projectile_Zap',
		impact: 'SFX_Impact_Enemy_Insect',
	},
	states: {
		idling: {
			behaviour: IdleBehaviours.MEANDER,
			targetingStyle: EnemyTargetingStyles.HIGH_HEALTH,
			targetingCheckSeconds: 2,
			transitionToFighting: {
				trigger: FightingTriggers.AGGRESSIVE_PROXIMITY,
				radius: 800,
			},
			meander: {
				radius: 500,
				speedMultiplier: 1,
				minRestTime: 0.5,
				maxRestTime: 10,
			},
			aggroOnHit: true,
		},
		fighting: {
			behaviour: FightingBehaviours.CHASE_WHILE_ATTACKING,
			targetingStyle: EnemyTargetingStyles.HIGH_HEALTH,
			targetingCheckSeconds: 0,
			attackType: AttackTypes.PROJECTILE,
			engagementMaxDistance: 1300,
			engagementMinDistance: 0,
			modelCenterOffset: 60,
			movementMaxDistance: 120,
			movementMinDistance: 80,
			brain: {
				actions: [
					{
						priority: 0,
						actionCriteria: ActionCriteriaHelpers.generateDoesNotHaveBuffCriteria(CriteriaTarget.SELF, BuffIdentifier.EnemyMosquitoMovementSpeedBoost),
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
							abilityOptions: [[2, movementSpeedBuffAbility, 10]],
						},
					},
					
					{
						priority: 4,
						actionCriteria: {
							satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
							criterias: [
								worldDifficultyBrutalCriterion,
								{
									criteriaTargets: CriteriaTarget.PLAYERS,
									criteriaType: CriteriaType.WITHIN_RANGE,
									criteriaValue: 400,
									minimumTargetsThatSatisfy: 1,
								},
							],
						},
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
							//abilityOptions: [[5, shortRangeAbility, 12]],
							abilityOptions: [attackRatedAbilityList(5, animationTimes, AnimationTrack.SHOOT, shortRangeAbilityBrutal, 14)],
						},
					},
					{
						priority: 5,
						actionCriteria: ActionCriteriaHelpers.generatePlayerIsInMeleeRangeCriteria(350),
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
							//abilityOptions: [[5, shortRangeAbility, 12]],
							abilityOptions: [attackRatedAbilityList(5, animationTimes, AnimationTrack.SHOOT, shortRangeAbility, 14)],
						},
					},
					{
						// DO NOTHING
						priority: 6,
						actionCriteria: alwaysTrueCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							abilityOptions: [[0, waitAbility(20)]],
						},
					},
				],
			},
			attackOptions: [],
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 0.1,
			transitionToLeashing: {
				trigger: LeashTriggers.RADIUS_EXCEEDED,
				radius: 2000,
				distanceTravelled: 4000,
			},
		},
		leashing: {
			behaviour: LeashingBehaviours.MOVE,
			speedMultiplier: 4,
		},
		fleeing: {},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 1,
		},
	},
}

export default mosquito
