import { Vector } from "sat"
import { IPixiBone } from "../../ai/shared/enemy-model-data"
import WeaponSubType from "../../loot/shared/weapon-sub-type"
import { getBoneByName } from "../../utils/bones"
import { VectorXY, throwIfNotFinite, lengthV, sub, distanceVV } from "../../utils/math"
//import playerModelData from '../../../assets/sprites/player-skins/player.json'
import weaponStaffData from '../../../assets/sprites/weapons/player_staffs/player_staffs.json'
import weaponSpellswordData from '../../../assets/sprites/weapons/player_swords/player_swords.json'
import weaponScytheData from '../../../assets/sprites/weapons/player_scythes/player_scythes.json'
import weaponWandData from '../../../assets/sprites/weapons/player_crossbows/player_crossbows.json'
import weaponCrossbowData from '../../../assets/sprites/weapons/player_wands/player_wands.json'
import weaponArcaneFocusData from '../../../assets/sprites/weapons/player_arcane_focuses/player_arcane_focuses.json'
import { angleInRadsFromVector } from "../../utils/vector"
import { PLAYER_PROJECTILE_AND_BEAM_MAX_RANGE, PLAYER_WAVE_TRAVEL_TIME_DEFAULT_SPEED } from "../../engine/shared/game-data/player"
import { gameUnits, radians, timeInSeconds } from "../../utils/primitive-types"


export function getReleasePosition(shooter: { position: Vector, aimVector: VectorXY }, bones: IPixiBone[], weaponOffset: Vector, aimAngle: number, ignoreAngleOnWeaponOffset: boolean): Vector {
	const baseOffset = bones ? getBaseOffsetAndScale(bones) : { x: 0, y: 0, scaleX: 1, scaleY: 1 }
	// on a player, baseOffset is always (0, -player.aimOffset)
	const initialPos = shooter.position.clone().add((baseOffset as any) as Vector)

	if (!ignoreAngleOnWeaponOffset) {
		const cos = Math.cos(aimAngle)
		const sin = Math.sin(aimAngle)
		weaponOffset = new Vector(cos * weaponOffset.x, sin * weaponOffset.x)
	} else {
		// still want the X component to be facing the correct direction
		if (shooter.aimVector.x < 0) {
			baseOffset.scaleX *= -1
		}
	}

	initialPos.x += weaponOffset.x * baseOffset.scaleX
	initialPos.y += weaponOffset.y * baseOffset.scaleY

	// {
	// 	const position = shooter.position
	// 	const withWeaponOffset = add(position, weaponOffset)
	// 	const duration = 2
	// 	debugDrawCircle(position, 10, Colors.red, duration)
	// 	debugDrawLine(position, withWeaponOffset, Colors.red, duration)
	// 	debugDrawCircle(withWeaponOffset, 10, Colors.orange, duration)
	// 	debugDrawCircle(initialPos, 10, Colors.green, duration)
	// }

	return initialPos
}

function getBaseOffsetAndScale(bones: IPixiBone[]): { x: number; y: number; scaleX: number; scaleY: number } {
	const rootPositionBone = getBoneByName(bones, 'root-position')
	const rootAimBone = getBoneByName(bones, 'root-aim')
	const result = { x: 0, y: 0, scaleX: 0, scaleY: 0 }
	const rootAimBoneX = rootAimBone.x || 0
	const rootAimBoneY = rootAimBone.y || 0
	const rootBoneScaleX = rootPositionBone.scaleX || 1.0
	const rootBoneScaleY = rootPositionBone.scaleY || 1.0

	result.y = -rootAimBoneY * rootBoneScaleY

	result.scaleX = rootBoneScaleX
	result.scaleY = rootBoneScaleY

	throwIfNotFinite(result.x)
	throwIfNotFinite(result.y)
	throwIfNotFinite(result.scaleX)
	throwIfNotFinite(result.scaleY)

	return result
}

export function getWeaponOffset(bones: any, weapon: any): Vector {
	const result = new Vector(0, 0)

	if (weapon) {
		const weaponData = getWeaponData(weapon.itemSubType)

		const weaponPfxBone = getBoneByName(weaponData.bones, 'weapon-pfx')
		result.x = weaponPfxBone.x ? weaponPfxBone.x : 0
	}

	// TODO2 these values should REALLY be cached
	const lArmBone = getBoneByName(bones, 'l_arm')
	result.x += lengthV(lArmBone)
	const lHandBone = getBoneByName(bones, 'l_hand')
	result.x += lengthV(lHandBone)
	const weaponAttachementBone = getBoneByName(bones, 'weapon-attachment')
	result.x += lengthV(weaponAttachementBone)

	return result
}

export function getWeaponData(subType: WeaponSubType): any {
	switch (subType) {
		case WeaponSubType.Spellsword:
			return weaponSpellswordData
		case WeaponSubType.Scythe:
			return weaponScytheData
		case WeaponSubType.Wand:
			return weaponWandData
		case WeaponSubType.Staff:
			return weaponStaffData
		case WeaponSubType.ArcaneFocus:
			return weaponArcaneFocusData
		case WeaponSubType.Crossbow:
			return weaponCrossbowData
	}
}

export interface IRangedProjectile {
	playerOwned: boolean
	startPos: VectorXY
	position: VectorXY
	// owningEntity: any
	maxRange: gameUnits
	reachedMaxRange: boolean
	willReverseTrajectory: boolean
	reversedTrajectory: boolean
	clearStatsForBoomerang(clearShotgunningCount?: boolean)
	leftRightFlip: boolean
	aimAngleInRads: number
	travelTimeElapsedForWaveFunctions: number
	speed: any
	angleToPeakOfArc: radians
	toBeDeleted: boolean
}

export function projectileCheckRange(projectile: IRangedProjectile, position: VectorXY, delta: timeInSeconds) {
	const defaultSpeed = PLAYER_WAVE_TRAVEL_TIME_DEFAULT_SPEED

	//TODO2: change this distance function to something proper
	const distanceFromStart = distanceVV(projectile.startPos, position)
	if (projectile.playerOwned && distanceFromStart >= Math.min(PLAYER_PROJECTILE_AND_BEAM_MAX_RANGE, projectile.maxRange)) {
		if (!projectile.reachedMaxRange) {
			if (projectile.willReverseTrajectory && !projectile.reversedTrajectory) {
				projectile.reversedTrajectory = true
				projectile.willReverseTrajectory = false
				projectile.reachedMaxRange = true

				projectile.clearStatsForBoomerang(true)

				projectile.leftRightFlip = !projectile.leftRightFlip
				projectile.aimAngleInRads += Math.PI
				projectile.travelTimeElapsedForWaveFunctions -= (projectile.speed / defaultSpeed) * delta
				projectile.angleToPeakOfArc = angleInRadsFromVector(sub(projectile.position, projectile.startPos))
			} else {
				projectile.toBeDeleted = true
			}
		}
	} else {
		projectile.reachedMaxRange = false
	}
}
