import logger from '../../utils/client-logger'

export const debugModule = () => {
	logger.debug('Initializing Debug store module...')
	return {
		namespaced: true,
		state: {
			inspectEntity: {
				nid: null,
				x: null,
				y: null,
			},
			inspectInterval: null,
		},
		getters: {
			inspectedEntity(state) {
				return state.inspectedEntity
			},
		},
		mutations: {
			inspectEntityUpdatedAsEnemy(state, entity: any) {
				function update() {
					if (entity) {
						//TODO3 Dan: split out separate behavior for player vs enemy vs etc.
						state.inspectEntity.nid = entity.nid
						state.inspectEntity.x = entity.x
						state.inspectEntity.y = entity.y
						state.inspectEntity.currentState = entity.currentState
						state.inspectEntity.currentSubState = entity.currentSubState
						state.inspectEntity.aggroRadius = entity.aggroRadius
						state.inspectEntity.engagementDistance = entity.engagementDistance
						if (entity.colliders) {
							state.inspectEntity.colliderRadius = entity.colliders.map((c) => c.radius).join(',')
						}
					} else {
						clearInterval(state.inspectInterval)
					}
				}
				update()
				if (state.inspectInterval) {
					clearInterval(state.inspectInterval)
				}
				state.inspectInterval = setInterval(update, 50)
			},
		},
	}
}
