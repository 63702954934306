import nengi from 'nengi'
import { Vector } from 'sat'
import { AnyCollider } from '../../collision/shared/colliders'
import { AnimationTrack } from '../../models-animations/shared/animation-track'
import { radians } from '../../utils/primitive-types'
import { EnemyAI, EnemyType } from './ai-types'

class Enemy {
	static protocol = {
		enemyConfig: { type: nengi.String },
		x: { type: nengi.Float32, interp: true },
		y: { type: nengi.Float32, interp: true },
		enemyType: { type: nengi.UInt8 },
		facingDirection: { type: nengi.Int4, interp: false },
		visualAimAngle: { type: nengi.RotationFloat32, interp: true },
		aggroRadius: { type: nengi.Float32 },
		engagementMaxDistance: { type: nengi.Float32 },
		engagementMinDistance: { type: nengi.Float32 },
		currentState: { type: nengi.String },
		currentSubState: { type: nengi.String },
		levelOffset: { type: nengi.UInt16 },
		maxHealth: { type: nengi.Float32 },
		currentHealth: { type: nengi.Float32 },
		idleAnimation: { type: nengi.String },
		moveAnimation: { type: nengi.String },
		changeAnimation: { type: nengi.String },
		buff1: { type: nengi.String },
		buff2: { type: nengi.String },
		buff3: { type: nengi.String },
		buff4: { type: nengi.String },
		buff5: { type: nengi.String },
		buff6: { type: nengi.String },
		visible: { type: nengi.Boolean },
		spawnInTimer: { type: nengi.Number },
		mainBrainText: { type: nengi.String },
		subBrainText: { type: nengi.String },
	}

	nid: number
	config: EnemyAI
	levelOffset: number
	enemyType: EnemyType
	facingDirection: number = 1 // 1 (facing right) or -1 (facing left)
	visualAimAngle: radians = 0

	idleAnimation: AnimationTrack = AnimationTrack.IDLE
	moveAnimation: AnimationTrack = AnimationTrack.MOVEMENT
	changeAnimation: AnimationTrack = AnimationTrack.IDLE

	buff1: string = ''
	buff2: string = ''
	buff3: string = ''
	buff4: string = ''
	buff5: string = ''
	buff6: string = ''

	visible: boolean = true

	spawnInTimer: number = 0

	colliders: AnyCollider[]

	// Debug AI
	mainBrainText: string = ''
	subBrainText: string = ''

	isDead() {
		// this is overridden in both ClientEnemy and ServerEnemy
		return false
	}
	get persistentCorpse() {
		return this.config.states.dead.persistentCorpse
	}
}

//intention is to move all debug info (engagementMinDistance, currentState, currentSubState, etc...) to this so we can turn it all off easily
// (also allows adding debug info easily)
export interface EnemyDebugInfo {
	ownerPos: Vector // required for tweaker to work properly
}

export default Enemy
