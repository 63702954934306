import { Vector } from 'sat'
import { BuffIdentifier } from '../../../buffs/shared/buff.shared'
import { ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { ENEMY_DEFAULT_HEALTH } from '../../../engine/shared/game-data/enemy-formulas'
import { ParticleEffectType } from '../../../engine/shared/game-data/particle-config'
import { AnimationTrack } from '../../../models-animations/shared/animation-track'
import { EnemyBipedSkinType, SpineDataName } from '../../../models-animations/shared/spine-config'
import { ModType, ModValueType } from '../../../projectiles/shared/projectile-types'
import { Colors } from '../../../utils/colors'
import { attackRatedAbilityList } from '../action-criteria/ability-helpers'
import { ActionCriteriaHelpers } from '../action-criteria/action-criteria-helpers'
import { AttackTypes, DeadBehaviours, EnemyAI, EnemyTargetingStyles, EnemyType, FightingBehaviours, FightingTriggers, IdleBehaviours, LeashingBehaviours, LeashTriggers, ShotLeadPrecision } from '../../shared/ai-types'
import { Ability, AbilitySelectionStyles, AbilityTargets, AbilityTargetSelectionStyle, AbilityType } from '../action-types'
import { alwaysTrueCriteria, waitAbility } from './abilities/common-abilities'
import { EnemyDefaults } from './enemy-defaults'
import { ENEMY_NAMES } from './enemy-names'

const animationTimes = {
	[AnimationTrack.SHOOT]: 0.3, // actual frame at 0.65 or so, but their attack is too long. TODO2 - needs different implementation
}

/*
shard blast - Triple shot from short-ish range. Not a huge amount of damage, but the spread is pretty tight, so it breaks through shields easily.
Shard Guard - AoE Buff, that stacks up to 19 times, each one providing a 5% damage reduction. Hits all monsters nearby. Lasts longer than its cooldown by a wide margin. A single sliver could have up to 4 or 5 buffs active at once, so 5 slivers left alone could build up to a full 95% damage reduction on all nearby monsters.
*/

const shardBlastAbility: Ability = {
	debugName: 'shardBlastAbility',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.CLOSEST,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		projectileCount: 1,
		speed: 1300,
		colliderRadius: 5,
		lifespanInSeconds: 0.4,
		basePhysicalDamage: 10,
		burstCount: 2, 
		burstDelay: 0,
		color: Colors.yellow,
		modifiers: [{ modType: ModType.WAVE, amplitude: { modValueType: ModValueType.VALUE, value: 0.6 }, period: { modValueType: ModValueType.VALUE, value: 0.75 } }],
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_SHAMBLINGMOUND,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_TRAIL_ASP,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_NONE,
	},
}

const shardGuardAbility: Ability = {
	debugName: 'shardGuard',
	abilityType: AbilityType.INSTANTLY_APPLY_EFFECT,
	buffToApply: BuffIdentifier.EnemyShardGuard,
	durationInGameTicks: 20,
	validTargets: AbilityTargets.ALL_NEARBY_ENEMIES,
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
}

const sliver: EnemyAI = {
	name: ENEMY_NAMES.SLIVER_PRISM_BOSS_MINION,
	type: EnemyType.BASIC,
	appearance: {
		asset: SpineDataName.SLIVER,
		skin: EnemyBipedSkinType.DEFAULT,
		flying: true,
		mixSettings: [],
	},
	general: {
		enemyLevel: 1,
		experienceWhenKilled: 0.2,
	},
	baseAttributes: {
		...EnemyDefaults.baseAttributes,
		visibilityRadius: 1,
		colliders: [
			{
				type: ColliderType.Circle,
				radius: 40,
				traits: ColliderTraits.BlockMovementAndProjectile,
				position: [0, -60],
			},
		],
		movementSpeed: 150,
		decelerationRate: 10,
		turningRatePerSecondInDegrees: 360,
		attackOffset: new Vector(0, -70),
		lootDropOffset: new Vector(0, 0),
		damageConfig: null,

		physicalDamage: 0,
		fireDamage: 0,
		iceDamage: 0,
		lightningDamage: 0,
		poisonDamage: 0,
		critChance: 0,
		critDamage: 2.0,

		maxHealth: (ENEMY_DEFAULT_HEALTH * 1) / 4,
		defense: 0,
		fireResist: 0,
		poisonResist: 0,
		iceResist: 0,
		lightningResist: 0,

		lootDropProbability: 1,
		lootDropQuantity: 1,
		lootDropRarity: 1,
	},
	soundEffects: {
		attack: 'Projectile_Zap',
		impact: 'SFX_Impact_Enemy_Insect',
	},
	states: {
		idling: {
			behaviour: IdleBehaviours.MEANDER,
			targetingStyle: EnemyTargetingStyles.HIGH_HEALTH,
			targetingCheckSeconds: 2,
			transitionToFighting: {
				trigger: FightingTriggers.AGGRESSIVE_PROXIMITY,
				radius: 800,
			},
			meander: {
				radius: 500,
				speedMultiplier: 1,
				minRestTime: 0.5,
				maxRestTime: 10,
			},
			aggroOnHit: true,
		},
		fighting: {
			behaviour: FightingBehaviours.STRAFE_AND_ATTACK,
			targetingStyle: EnemyTargetingStyles.HIGH_HEALTH,
			targetingCheckSeconds: 0,
			attackType: AttackTypes.PROJECTILE,
			engagementMaxDistance: 1300,
			engagementMinDistance: 0,
			modelCenterOffset: 60,
			movementMaxDistance: 500,
			movementMinDistance: 300,
			brain: {
				actions: [
					{
						priority: 1,
						actionCriteria: ActionCriteriaHelpers.generatePlayerIsInMeleeRangeCriteria(500),
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
							abilityOptions: [attackRatedAbilityList(3, animationTimes, AnimationTrack.SHOOT, shardGuardAbility, 14), attackRatedAbilityList(5, animationTimes, AnimationTrack.SHOOT, shardBlastAbility, 14)],
						},
					},
					{
						// DO NOTHING
						priority: 2,
						actionCriteria: alwaysTrueCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							abilityOptions: [[0, waitAbility(20)]],
						},
					},
				],
			},
			attackOptions: [],
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 0.1,
			transitionToLeashing: {
				trigger: LeashTriggers.RADIUS_EXCEEDED,
				radius: 2000,
				distanceTravelled: 4000,
			},
		},
		leashing: {
			behaviour: LeashingBehaviours.MOVE,
			speedMultiplier: 4,
		},
		fleeing: {},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 1,
		},
	},
}

export default sliver
