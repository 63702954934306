import nengi from 'nengi'

class DismissNPCUI {
	static protocol = {
		npcId: nengi.Number,
	}

	npcId: number

	constructor(npcId: number) {
		this.npcId = npcId
	}
}

export default DismissNPCUI
