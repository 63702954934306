import { NengiClient } from '../../engine/client/nengi-client'
import SendItemToStashCommand from '../../items/shared/send-item-to-stash-command'
import logger from '../../utils/client-logger'

interface HubWormDeliveryUIState {
	selectedWormDeliveryItem: any
}

export const hubWormDeliveryUIState = () => {
	logger.debug('Initializing Outpost Worm Mail UI module')
	return {
		namespaced: true,
		state: {
			selectedWormDeliveryItem: null,
		},
		mutations: {
			selectHubWormDeliveryItem(state: HubWormDeliveryUIState, selectedItem?: any) {
				if (selectedItem && selectedItem !== state.selectedWormDeliveryItem) {
					this.commit(
						'itemComparison/selectItem',
						{
							itemId: selectedItem,
							container: 'wormDelivery',
						},
						{ root: true },
					)
					state.selectedWormDeliveryItem = selectedItem
				}
			},
		},
		getters: {
			selectedWormDeliveryItem(state: HubWormDeliveryUIState) {
				return state.selectedWormDeliveryItem
			},
		},
		actions: {
			toggleSelectHubWormDeliveryItem(context, selectedItem?: any) {
				const { state, commit, rootState } = context

				if (selectedItem && selectedItem !== state.selectedWormDeliveryItem) {
					if (rootState.itemLocks?.lockedItems.includes(selectedItem)) {
						console.log('cancelling selection; it\'s locked')
						return
					}

					commit(
						'itemComparison/selectItem',
						{
							itemId: selectedItem,
							container: 'wormDelivery',
						},
						{ root: true },
					)
					state.selectedWormDeliveryItem = selectedItem
				} else {
					commit('itemComparison/deselectItem', {}, { root: true })
					state.selectedWormDeliveryItem = null
				}
			},
			sendItemToStashFromWormDelivery(context, itemId) {
				const msg = new SendItemToStashCommand(itemId, 'worm_delivery')
				NengiClient.getInstance().sendCommand(msg)
			},
			// sendItemToMarketplace
			// sendItemToFurnace
			// sendItemToTrash
		},
	}
}
