import PlayerInput from "../../input/shared/player-input"
import ClientProjectileSystem, { getWeaponReleasePos } from "../../projectiles/client/client-projectile-system"
import { ClientProjectile } from "../../projectiles/client/projectile.client"
import { ProjectileConfigSlotMessage } from "../../projectiles/shared/projectile-config-message"
import { timeInSeconds } from "../../utils/primitive-types"
import { ClientPlayer } from "./player.client"
import { ClientPlayerProjectileShooter } from "./client-player-projectile-shooter"
import Renderer from "../../engine/client/graphics/renderer"
import { ClientBeam } from "../../beams/client/beam.client"
import { collideBeam } from "../../collision/shared/collision-routines"
import { VectorXY } from "../../utils/math"
import { getClientColliderEntityCollidables, getClientEnemyCollidables, getClientPropCollidables } from "../../collision/client/client-collisions"

export class ClientProjectilesCsp {
	private readonly player: ClientPlayer;
	private readonly shooter: ClientPlayerProjectileShooter
	private readonly projectileConfigs = {}
	private readonly projectileSystem: ClientProjectileSystem;
	private readonly projectilePairs: Map<ClientProjectile, ClientProjectile> = new Map()
	private readonly beamPairs: Map<ClientBeam, ClientBeam> = new Map()

	private projectiles: Map<number, ClientProjectile>
	private beams: Map<number, ClientBeam>

	constructor(player: ClientPlayer) {
		this.player = player
		this.shooter = new ClientPlayerProjectileShooter(player)
		this.projectiles = new Map()
		this.beams = new Map()
		this.projectileSystem = new ClientProjectileSystem(this.shooter, this.projectiles, this.beams)

		//DevToolsManager.getInstance().setDebugObject(debugConfig.cspConfig)
	}

	cleanup() {
		this.projectilePairs.forEach((clientProjectile, serverProjectile) => {
			Renderer.getInstance().unregisterProjectile(clientProjectile.nid)
		})

		/** remove all client and server beams added for this player */
		Renderer.getInstance().unregisterBeam(-1)
		this.beamPairs.forEach((clientBeam, serverBeam) => {
			Renderer.getInstance().unregisterBeam(serverBeam.nid)
		})
	}

	getWeaponReleasePos() {
		return getWeaponReleasePos(this.shooter)
	}

	handleProjectileConfig(projectileConfig: ProjectileConfigSlotMessage): void {
		//console.log(this.handleProjectileConfig.name, projectileConfig)
		this.projectileConfigs[projectileConfig.weaponSlot] = projectileConfig.config
	}

	isLocalMine(projectile: ClientProjectile | ClientBeam) {
		if (projectile.nid >= 0 && projectile.projectileId > 0) {
			return projectile.owningEntityId === this.player.nid
		}
		return false
	}

	handleInput(curInput: PlayerInput, delta: timeInSeconds): void {
		this.shooter.handleInput(curInput, delta)

		if (this.shooter.hasMouseUp || this.shooter.inSafeZone) {
			this.projectileSystem.removeBeam(this.shooter.nid)
			this.beamPairs.clear()
		}
	}

	handleMyProjectileCreate(entity: ClientProjectile): void {
		const projectile = this.getProjectileById(entity.projectileId)
		if (projectile) {
			this.projectilePairs.set(entity, projectile)
		} else {
			//console.warn(`can't find project with id: ${entity.projectileId}`)
		}
	}
	handleMyProjectileDestroy(entity: ClientProjectile): void {
		const clientProjectile = this.getProjectileById(entity.projectileId)
		if (clientProjectile) {
			clientProjectile.toBeDeleted = true
		}
	}
	handleMyBeamCreate(entity: ClientBeam): void {
		const beam = this.getBeamById(entity.projectileId)
		if (beam) {
			this.beamPairs.set(entity, beam)
		} else {
			//console.warn(`can't find project with id: ${entity.projectileId}`)
		}
	}
	handleMyBeamDestroy(entity: ClientBeam): void {
		const clientBeam = this.getBeamById(entity.projectileId)
		if (clientBeam) {
			// nothing to do here, handled on client directly
		}
	}

	update(delta: timeInSeconds): void {
		this.shooter.update(delta)

		this.projectileSystem.update(delta)

		this.projectilePairs.forEach((clientProjectile, serverProjectile) => {
			if (serverProjectile.toBeDeleted) {
				clientProjectile.toBeDeleted
				Renderer.getInstance().unregisterProjectile(clientProjectile.nid)
			}
		})

		this.beams.forEach(beam => {
			collideBeam(beam, this)
		})

		this.shooter.wasShooting = true
	}

	get spatialEnemies() {
		return {
			findNearToPos: (position: VectorXY, dist: number, optionalSkipCriteriaFn?: (e) => boolean) => {
				optionalSkipCriteriaFn = optionalSkipCriteriaFn || ((e) => false)
				return getClientEnemyCollidables().filter(e => !optionalSkipCriteriaFn(e))
			}
		}
	}
	get spatialProps() {
		return {
			findNearToPos: (position: VectorXY, dist: number, optionalSkipCriteriaFn?: (e) => boolean) => {
				optionalSkipCriteriaFn = optionalSkipCriteriaFn || ((e) => false)
				return getClientPropCollidables().filter(e => !optionalSkipCriteriaFn(e))
			}
		}
	}
	get spatialColliderEntities() {
		return {
			findNearToPos: (position: VectorXY, dist: number, optionalSkipCriteriaFn?: (e) => boolean) => {
				optionalSkipCriteriaFn = optionalSkipCriteriaFn || ((e) => false)
				return getClientColliderEntityCollidables().filter(e => !optionalSkipCriteriaFn(e))
			}
		}
	}

	private getProjectileById(projectileId: number): ClientProjectile {
		for (const [_, projectile] of this.projectiles) {
			if (projectile.projectileId === projectileId) {
				return projectile
			}
		}
		return null
	}

	private getBeamById(projectileId: number): ClientBeam {
		for (const [_, beam] of this.beams) {
			if (beam.projectileId === projectileId) {
				return beam
			}
		}
		return null
	}

	/** fake nid... renderer needs an nid to track the projectile (as an id) */
	static fakeNidForRender = -1
}