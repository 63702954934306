
export const genericRewardsUIModule = () => {
    return {
        namespaced: true,
        state: {
            showGenericRewards: false,
            isClaimingRewards: false,
            claimButtonText: 'OK',
            topText: null,
            bottomText: null,
            rewardItems: [],
            claimClickedCallback: null,
            panelIdentifier: 'generic'
        },
        getters: {
            
        },
        mutations: {
            showGenericRewardsModule(state, {claimButtonText, claimClickedCallback, topText, bottomText, rewardItems, panelIdentifier}) {
                state.showGenericRewards = true
                state.claimClickedCallback = claimClickedCallback
                if(claimButtonText) {
                    state.claimButtonText = claimButtonText
                } else {
                    claimButtonText = 'OK'
                }

                state.topText = topText
                state.bottomText = bottomText
                state.rewardItems = rewardItems
                state.panelIdentifier = panelIdentifier
            }
        },
        actions: {
            async claimRewards({state}) {
                const oldCallback = state.claimClickedCallback
                state.claimClickedCallback = null
                state.isClaimingRewards = true
                
                if(oldCallback) {
                    await oldCallback()
                }

                state.isClaimingRewards = false
                state.showGenericRewards = false                
            }
        }
    }
}
