import nengi from 'nengi'

export enum EndReason {
	UNSTICK = 0,
	END_RUN = 1,
	WORLD_END = 2,
}

export class PlayerEndRunCommand {
	static protocol = {
		reason: { type: nengi.Int4 },
	}
	reason: EndReason

	constructor(reason: EndReason) {
		this.reason = reason
	}
}
