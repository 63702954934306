import nengi from 'nengi'

class SendWormAwayCommand {
	static protocol = {
		wormId: nengi.Number,
	}

	wormId: number

	constructor(wormId: number) {
		this.wormId = wormId
	}
}

export default SendWormAwayCommand
