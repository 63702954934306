import { Vector } from "sat"
import { BinaryFlagData } from "../../engine/shared/game-data/binary-flag-data"
import { CooldownSlot, DODGE_ROLL_CONFIG, getSkillDurationMs, TUMBLE_ROLL_CONFIG } from "../../engine/shared/game-data/gear-skill-config"
import { PLAYER_DEFAULT_MOVEMENT_SPEED } from "../../engine/shared/game-data/player"
import { BinaryFlag, ModCategory, StatType } from "../../engine/shared/game-data/stat-type-mod-category"
import { timeInMilliseconds } from "../../utils/primitive-types"
import { ExternalPhysicsForce, IPhysicsObject } from "../shared/physics"
import { LocalPlayerData } from "./local-player-data"

const performSkillMap: Map<ModCategory, { (player: IPhysicsObject, localPlayerData: LocalPlayerData, slot: CooldownSlot, movement: Vector, acceleration: number) }> = new Map()
performSkillMap.set(ModCategory.SKILL_DODGE_ROLL, performDodgeRoll)
performSkillMap.set(ModCategory.SKILL_TUMBLE_ROLL, performTumbleRoll)


export function clientPerformSkill(player: IPhysicsObject, localPlayerData: LocalPlayerData, slot: CooldownSlot, skillType: ModCategory, movement: Vector, acceleration: number) {
	const skillFunc = performSkillMap.get(skillType)
	if (skillFunc) {
		skillFunc(player, localPlayerData, slot, movement, acceleration)
	}
}

function performDodgeRoll(player: IPhysicsObject, localPlayerData: LocalPlayerData, slot: CooldownSlot, movement: Vector, acceleration: number) {
	const agileRolls = localPlayerData.binaryFlagMap.has(BinaryFlag.AGILE_ROLLS) ? BinaryFlagData[BinaryFlag.AGILE_ROLLS].data.accelerationMulti : 1
	acceleration = Math.clamp(acceleration, PLAYER_DEFAULT_MOVEMENT_SPEED * DODGE_ROLL_CONFIG.minMovementSpeedMultiplier, PLAYER_DEFAULT_MOVEMENT_SPEED * DODGE_ROLL_CONFIG.maxMovementSpeedMultiplier)
	acceleration *= agileRolls
	const skillSpeed = localPlayerData.gearSlotstats[slot][StatType.SKILL_SPEED]
	const skillDurationMs: timeInMilliseconds = getSkillDurationMs(localPlayerData, slot, 1.0)
	const skillDurationSeconds = skillDurationMs * 0.001
	const force1 = new ExternalPhysicsForce(movement.clone().scale(acceleration * skillSpeed * DODGE_ROLL_CONFIG.physicsForce1.accelerationMultiplier), DODGE_ROLL_CONFIG.physicsForce1.durationMultiplier * skillDurationSeconds, null, true)
	player.externalForces.push(force1)
	const force2 = new ExternalPhysicsForce(movement.clone().scale(acceleration * skillSpeed * DODGE_ROLL_CONFIG.physicsForce2.accelerationMultiplier), DODGE_ROLL_CONFIG.physicsForce2.durationMultiplier * skillDurationSeconds, null, false)
	player.externalForces.push(force2)
}
function performTumbleRoll(player: IPhysicsObject, localPlayerData: LocalPlayerData, slot: CooldownSlot, movement: Vector, acceleration: number) {
	const agileRolls = localPlayerData.binaryFlagMap.has(BinaryFlag.AGILE_ROLLS) ? BinaryFlagData[BinaryFlag.AGILE_ROLLS].data.accelerationMulti : 1
	acceleration = Math.clamp(acceleration, PLAYER_DEFAULT_MOVEMENT_SPEED * TUMBLE_ROLL_CONFIG.minMovementSpeedMultiplier, PLAYER_DEFAULT_MOVEMENT_SPEED * TUMBLE_ROLL_CONFIG.maxMovementSpeedMultiplier)
	acceleration *= agileRolls
	const skillDurationMs: timeInMilliseconds = getSkillDurationMs(localPlayerData, slot, 1.0)
	const skillDurationSeconds = skillDurationMs * 0.001
	const skillSpeed = localPlayerData.getGearSlotPlusBaseStat(slot, 'skillSpeed')

	const force = new ExternalPhysicsForce(movement.clone().scale(acceleration * skillSpeed), TUMBLE_ROLL_CONFIG.physicsForceDurationMultiplier * skillDurationSeconds, null, true)
	player.externalForces.push(force)
}