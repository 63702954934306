import { EnemyAI, FightingTriggers, IdleBehaviours, FightingBehaviours, LeashTriggers, LeashingBehaviours, AttackTypes, DeadBehaviours, ShotLeadPrecision, EnemyType, EnemyTargetingStyles } from '../../shared/ai-types'
import { ModType } from '../../../projectiles/shared/projectile-types'
import { SpineDataName, EnemyBipedSkinType } from '../../../models-animations/shared/spine-config'
import { EnemyDefaults } from './enemy-defaults'
import { Colors } from '../../../utils/colors'
import { ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { ParticleEffectType } from '../../../engine/shared/game-data/particle-config'
import { ENEMY_DEFAULT_HEALTH } from '../../../engine/shared/game-data/enemy-formulas'
import { degToRad } from '../../../utils/math'
import { ENEMY_NAMES } from './enemy-names'
import { Ability, AbilitySelectionStyles, AbilityTargets, AbilityTargetSelectionStyle, AbilityType } from '../action-types'
import { attackRatedAbilityList } from '../action-criteria/ability-helpers'
import { AnimationTrack } from '../../../models-animations/shared/animation-track'
import { alwaysTrueCriteria } from './abilities/common-abilities'
import { worldDifficultyBrutalCriteria, worldDifficultyHardCriteria } from '../action-criteria/action-criteria-helpers'
import { deepClone } from '../abilities.test'

const animationTimes = {
	[AnimationTrack.SHOOT]: 0.25,
}

const waspShotAbility: Ability = {
	debugName: 'waspShotAbility',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		projectileCount: 1,
		speed: 800,
		spreadAngle: degToRad(20),
		colliderRadius: 10,
		lifespanInSeconds: 0.6,
		modifiers: [{ modType: ModType.STRAIGHT }],
		basePhysicalDamage: 20,
		baseLightningDamage: 40,
		burstCount: 0,
		color: Colors.purple,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_SHAMBLINGMOUND,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_LIGHTNING_TRAIL,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
	},
}

export const waspShotAbilityBrutal = deepClone(waspShotAbility)
 waspShotAbilityBrutal.projectileConfig.lifespanInSeconds = 0.75 

const wasp: EnemyAI = {
	name: ENEMY_NAMES.WASP,
	type: EnemyType.BASIC,
	appearance: {
		asset: SpineDataName.WASP,
		skin: EnemyBipedSkinType.DEFAULT,
	},
	general: {
		enemyLevel: 1,
		experienceWhenKilled: 0.4,
	},
	baseAttributes: {
		...EnemyDefaults.baseAttributes,
		colliders: [
			{
				type: ColliderType.Circle,
				radius: 30,
				position: [0, -30],
				traits: ColliderTraits.BlockMovementAndProjectile,
			},
		],
		maxHealth: ENEMY_DEFAULT_HEALTH / 2,

		movementSpeed: 220,
		decelerationRate: 2.5,
		turningRatePerSecondInDegrees: 360,
		physicalDamage: 0,

		attackRate: 2000,
		lootDropProbability: 0.8,
		lootDropQuantity: 0.75,
	},
	soundEffects: {
		attack: 'Projectile_Blip',
		impact: 'SFX_Impact_Enemy_Insect',
	},
	states: {
		idling: {
			behaviour: IdleBehaviours.MEANDER,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			transitionToFighting: {
				trigger: FightingTriggers.AGGRESSIVE_PROXIMITY,
				radius: 700,
			},
			meander: {
				radius: 500,
				speedMultiplier: 1,
				minRestTime: 0.5,
				maxRestTime: 10,
			},
			aggroOnHit: true,
		},
		fighting: {
			behaviour: FightingBehaviours.STRAFE_AND_ATTACK,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			attackType: AttackTypes.PROJECTILE,
			engagementMaxDistance: 550,
			engagementMinDistance: 0,
			modelCenterOffset: 30,
			movementMaxDistance: 400,
			movementMinDistance: 300,
			brain: {
				actions: [
					{
						//  BLOCK/ATTACK
						priority: 0,
						actionCriteria: worldDifficultyBrutalCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							//abilityOptions: [[1, shortRangeAbility, 30]],
							abilityOptions: [attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, waspShotAbilityBrutal, 25)],
						},
					},
					{
						//  BLOCK/ATTACK
						priority: 1,
						actionCriteria: worldDifficultyHardCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							//abilityOptions: [[1, shortRangeAbility, 30]],
							abilityOptions: [attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, waspShotAbility, 35)],
						},
					},
					{
						//  BLOCK/ATTACK
						priority: 2,
						actionCriteria: alwaysTrueCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							//abilityOptions: [[1, shortRangeAbility, 30]],
							abilityOptions: [attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, waspShotAbility, 40)],
						},
					},
				],
			},
			attackOptions: [
				{
					...EnemyDefaults.projectileConfig,
					projectileCount: 1,
					speed: 800,
					spreadAngle: degToRad(20),
					colliderRadius: 10,
					lifespanInSeconds: 0.6,
					modifiers: [{ modType: ModType.STRAIGHT }],
					basePhysicalDamage: 20,
					baseLightningDamage: 40,
					burstCount: 0,
					color: Colors.purple,
					bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_SHAMBLINGMOUND,
					bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_LIGHTNING_TRAIL,
					muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
				},
			],
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 0.1,
			transitionToLeashing: {
				trigger: LeashTriggers.RADIUS_EXCEEDED,
				radius: 2000,
				distanceTravelled: 4000,
			},
		},
		leashing: {
			behaviour: LeashingBehaviours.MOVE,
			speedMultiplier: 4,
		},
		fleeing: {},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 0.2,
		},
	},
}

export default wasp
