import { EnemyAI, FightingTriggers, IdleBehaviours, FightingBehaviours, LeashTriggers, LeashingBehaviours, AttackTypes, DeadBehaviours, ShotLeadPrecision, EnemyType, EnemyTargetingStyles } from '../../shared/ai-types'
import { ModType } from '../../../projectiles/shared/projectile-types'
import { SpineDataName, EnemyBipedSkinType } from '../../../models-animations/shared/spine-config'
import { EnemyDefaults } from './enemy-defaults'
import { Colors } from '../../../utils/colors'
import { ParticleEffectType } from '../../../engine/shared/game-data/particle-config'
import { Vector } from 'sat'
import { ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { ENEMY_DEFAULT_HEALTH } from '../../../engine/shared/game-data/enemy-formulas'
import { Ability, AbilitySelectionStyles, AbilityTargets, AbilityTargetSelectionStyle, AbilityType } from '../action-types'
import { attackRatedAbilityList } from '../action-criteria/ability-helpers'
import { AnimationTrack } from '../../../models-animations/shared/animation-track'
import { alwaysTrueCriteria } from './abilities/common-abilities'
import { ENEMY_NAMES } from './enemy-names'
import { deepClone } from '../abilities.test'
import { degToRad } from '../../../utils/math'
import { worldDifficultyBrutalCriteria } from '../action-criteria/action-criteria-helpers'

const animationTimes = {
	[AnimationTrack.SHOOT]: 0.25,
}

const spiderbite: Ability = {
	debugName: 'spiderbite',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.CLOSEST,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		projectileCount: 2,
		speed: 100,
		colliderRadius: 150,
		lifespanInSeconds: 0.2,
		modifiers: [{ modType: ModType.STRAIGHT }],
		basePoisonDamage: 80,
		burstCount: 0,
		color: Colors.yellow,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_POISON_TRAIL,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_NONE,
	},
	attackOffset: new Vector(-80, 0),
	ignoreAngleOnWeaponOffset: true,
}

export const spiderbiteBrutal = deepClone(spiderbite)
spiderbiteBrutal.projectileConfig.burstCount = 2
spiderbiteBrutal.projectileConfig.burstDelay = 0.6
spiderbiteBrutal.projectileConfig.spreadAngle = degToRad(30)
spiderbiteBrutal.projectileConfig.basePoisonDamage = 55

const spider: EnemyAI = {
	name: ENEMY_NAMES.SPIDER,
	type: EnemyType.BASIC,
	appearance: {
		asset: SpineDataName.SPIDER,
		skin: EnemyBipedSkinType.DEFAULT,
		mixSettings: [],
	},
	general: {
		enemyLevel: 1,
		experienceWhenKilled: 2,
	},
	baseAttributes: {
		...EnemyDefaults.baseAttributes,
		visibilityRadius: 1,
		colliders: [
			{
				type: ColliderType.Circle,
				radius: 75,
				traits: ColliderTraits.BlockMovementAndProjectile,
				position: [0, -45],
			},
		],
		movementSpeed: 275,
		decelerationRate: 10,
		turningRatePerSecondInDegrees: 300,
		attackOffset: new Vector(0, 0),
		lootDropOffset: new Vector(0, 0),
		damageConfig: null,

		physicalDamage: 0,
		fireDamage: 0,
		iceDamage: 0,
		lightningDamage: 0,
		poisonDamage: 0,
		attackRate: 1500,
		critChance: 0.05,
		critDamage: 1.5,

		maxHealth: (ENEMY_DEFAULT_HEALTH * 7) / 4,
		defense: 0,
		fireResist: 0,
		poisonResist: 0,
		iceResist: 0,
		lightningResist: 0,

		lootDropProbability: 1,
		lootDropQuantity: 1.25,
		lootDropRarity: 1,
	},
	soundEffects: {
		attack: 'Projectile_Blip',
		impact: 'SFX_Impact_Enemy_Insect',
	},
	states: {
		idling: {
			behaviour: IdleBehaviours.MEANDER,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 2,
			transitionToFighting: {
				trigger: FightingTriggers.AGGRESSIVE_PROXIMITY,
				radius: 800,
			},
			meander: {
				radius: 500,
				speedMultiplier: 1,
				minRestTime: 0.5,
				maxRestTime: 10,
			},
			aggroOnHit: true,
		},
		fighting: {
			behaviour: FightingBehaviours.CHASE_AND_ATTACK,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 0,
			attackType: AttackTypes.PROJECTILE,
			engagementMaxDistance: 160,
			engagementMinDistance: 0,
			modelCenterOffset: 60,
			movementMaxDistance: 160,
			movementMinDistance: 0,
			brain: {
				actions: [
										{
						// attack
						priority: 2,
						actionCriteria: worldDifficultyBrutalCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							abilityOptions: [attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, spiderbiteBrutal, 15)],
						},
					},
					{
						// attack
						priority: 5,
						actionCriteria: alwaysTrueCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							abilityOptions: [attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, spiderbite, 15)],
						},
					},
				],
			},
			attackOptions: [
				{
					...EnemyDefaults.projectileConfig,
					projectileCount: 2,
					speed: 10,
					colliderRadius: 150,
					lifespanInSeconds: 0.2,
					modifiers: [{ modType: ModType.STRAIGHT }],
					basePoisonDamage: 80,
					burstCount: 0,
					color: Colors.yellow,
					bulletParticleEffect: ParticleEffectType.PROJECTILE_NONE,
					bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_POISON_TRAIL,
					muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_POISON_SHOOT,
				},
			],
			shotLeadPrecision: ShotLeadPrecision.AVERAGE,
			visualAimLockSeconds: 0.1,
			transitionToLeashing: {
				trigger: LeashTriggers.RADIUS_EXCEEDED,
				radius: 2000,
				distanceTravelled: 4000,
			},
			// I feel like these guys should slow you (web) -Callum
		},
		leashing: {
			behaviour: LeashingBehaviours.MOVE,
			speedMultiplier: 4,
		},
		fleeing: {},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 2.5,
		},
	},
}

export default spider
