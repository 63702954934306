import PlayerRunEndDamageTakenMessage from '../../player/shared/player-run-end-damage-taken-message'
import PlayerRunEndStatsMessage from '../../player/shared/player-run-end-stats-message'
import logger from '../../utils/client-logger'
import { UI } from '../ui'

export const gameOverModule = () => {
	logger.debug('Initializing gameOver store module...')
	return {
		namespaced: true,
		state: {
			runStats: {
				runDuration: 0,
				enemiesKilled: 0,
				projectilesFired: 0,
				beamDamageTicks: 0,
				beamFiringTime: 0,
				damageDealt: 0,
				itemsLooted: 0,
				wormItemsSent: 0,
				damageTaken: 0,
				bossesKilled: 0,
				sumOfDamageDealt: 0,
			},
			damageTakenStats: {
				killedBy: '',

				physicalDamage: 0,
				fireDamage: 0,
				iceDamage: 0,
				lightningDamage: 0,
				poisonDamage: 0,

				igniteDamage: 0,
				poisonedDamage: 0,

				shieldHitsTaken: 0,
				totalHits: 0,

				skill1: 0,
				skill2: 0,
				skill3: 0,
			},
			activeGameOverFilter: 'Top Stats',
		},
		getters: {
			getRunStats(state) {
				return [
					{ metricName: 'Seconds Adventuring:', value: Math.round(state.runStats.runDuration) },
					{ metricName: 'Damage Dealt:', value: state.runStats.sumOfDamageDealt },
					{ metricName: 'Enemies Killed:', value: state.runStats.enemiesKilled },
					{ metricName: 'Bosses Killed:', value: state.runStats.bossesKilled },
					{ metricName: 'Projectiles Fired:', value: state.runStats.projectilesFired },
					{ metricName: 'Beam Damage Ticks:', value: state.runStats.beamDamageTicks},
					{ metricName: 'Seconds of Active Beam:', value: state.runStats.beamFiringTime},
					{ metricName: 'Damage Taken:', value: state.runStats.damageTaken },
					{ metricName: 'Worm Items Sent:', value: state.runStats.wormItemsSent },
				]
			},
			getDamageTakenStats(state) {
				return [
					{ metricName: 'Killed by: ', value: state.damageTakenStats.killedBy },
					{ metricName: 'Physical Damage Taken:', value: state.damageTakenStats.physicalDamage },
					{ metricName: 'Fire Damage Taken:', value: state.damageTakenStats.fireDamage },
					{ metricName: 'Ice Damage Taken:', value: state.damageTakenStats.iceDamage },
					{ metricName: 'Lightning Damage Taken:', value: state.damageTakenStats.lightningDamage },
					{ metricName: 'Poison Damage Taken:', value: state.damageTakenStats.poisonDamage },
					{ metricName: 'Damage Taken from Ignite:', value: state.damageTakenStats.igniteDamage },
					{ metricName: 'Damage Taken from Poisoned:', value: state.damageTakenStats.poisonedDamage },
					{ metricName: 'Number of Shield Hits Taken:', value: state.damageTakenStats.shieldHitsTaken },
					{ metricName: 'Total Number of Hits Taken:', value: state.damageTakenStats.totalHits },
					{ metricName: 'Skill Slot 1 Times Used:', value: state.damageTakenStats.skill1 },
					{ metricName: 'Skill Slot 2 Times Used:', value: state.damageTakenStats.skill2 },
					{ metricName: 'Skill Slot 3 Times Used:', value: state.damageTakenStats.skill3 },
				]
			},
		},
		actions: {
			resetGameOverFilter({state}){
				state.activeGameOverFilter = 'Top Stats'
			}
		},
		mutations: {
			updateRunEndStats(state, message: PlayerRunEndStatsMessage) {
				state.runStats = {
					runDuration: message.runDuration,
					enemiesKilled: message.enemiesKilled,
					projectilesFired: message.projectilesFired,
					beamDamageTicks: message.beamDamageTicks,
					beamFiringTime: message.beamFiringTime,
					damageDealt: message.damageDealt,
					itemsLooted: message.itemsLooted,
					wormItemsSent: message.wormItemsSent,
					damageTaken: message.damageTaken,
					bossesKilled: message.bossesKilled,
					sumOfDamageDealt: message.sumOfDamageDealt,
				}
			},
			updateRunEndDamageTaken(state, message: PlayerRunEndDamageTakenMessage) {
				const statObj = {
					killedBy: message.killedBy,

					physicalDamage: message.physicalDamage,
					fireDamage: message.fireDamage,
					iceDamage: message.iceDamage,
					lightningDamage: message.lightningDamage,
					poisonDamage: message.poisonDamage,

					igniteDamage: message.igniteDamage,
					poisonedDamage: message.poisonedDamage,

					shieldHitsTaken: message.shieldHitsTaken,
					totalHits: message.totalHits,

					skill1: message.skill1Used,
					skill2: message.skill2Used,
					skill3: message.skill3Used,
				}

				state.damageTakenStats = statObj
			},
			updateGameOverFilter(state, filter) {
				if(state.activeGameOverFilter === 'Factions'){
					UI.getInstance().store.dispatch('factions/skipFactionAnimations')
				}
				state.activeGameOverFilter = filter
			},
		},
	}
}
