




















































































































import { mapGetters, mapMutations, mapState } from 'vuex'
import EmptySkinGridItem from '../inventory-item/empty-skin-grid-item.vue'
import SkinIventoryItem from '../inventory-item/skin-grid-item.vue'
import FancySkinPreview from '../fancy-skin-preview/fancy-skin-preview.vue'

export default {
	name: 'CustomizeSkinPreviewDetail',
	components: {
		EmptySkinGridItem,
		SkinIventoryItem,
		FancySkinPreview,
	},
	props: {},
	data() {
		return {
			
		}
	},
	computed: {
		...mapGetters('itemContainers', ['activeWeapon', 'inactiveWeapon', 'equippedWeapons', 'getActiveWeaponSlot']),
		...mapState('cosmetics', ['selectedWand', 'selectedStaff', 'selectedSword', 'selectedArcane', 'selectedCrossbow', 'selectedScythe']),
		...mapState('itemContainers', ['activeWeaponSkinSlot']),
	},
	methods: {
		...mapMutations('itemContainers', ['updateWeaponSkinSlot']),
	},
}
