import nengi from 'nengi'

export default class BiomeBoundsMessage {
	static protocol = {
		biomeBounds_min: { type: nengi.Int32, indexType: nengi.UInt16 },
		biomeBounds_max: { type: nengi.Int32, indexType: nengi.UInt16 },
	}

	biomeBounds_min
	biomeBounds_max

	biomeBounds: string

	constructor(biomeBounds: string) {
		this.biomeBounds = biomeBounds
	}
}
