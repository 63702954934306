import { Vector } from 'sat'
import { LEVELS_PER_BIOME } from '../../../biome/shared/biome-constants'
import { BuffIdentifier } from '../../../buffs/shared/buff.shared'
import { AnyColliderConfig, ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { ProjectileEffectType } from '../../../projectiles/shared/projectile-types'
import { throwIfNotFinite } from '../../../utils/math'
import { percentage, timeInMilliseconds } from '../../../utils/primitive-types'
import { ParticleEffectType } from './particle-config'

export interface IGearUser {
	getGearSlotPlusBaseStat(cooldownType: CooldownSlot, statName: string): number
}

export function getSkillUsageMultiplier(player: IGearUser, slot: CooldownSlot): percentage {
	const skillUsageSpeed = player.getGearSlotPlusBaseStat(slot, 'skillUsageSpeed')
	return 1 / Math.max(0.1, skillUsageSpeed)
}

export function getSkillDurationMs(player: IGearUser, slot: CooldownSlot, durationMulti: percentage): timeInMilliseconds {
	let skillDuration = player.getGearSlotPlusBaseStat(slot, 'skillDuration')
	const skillUsageMultiplier = getSkillUsageMultiplier(player, slot)
	skillDuration *= skillUsageMultiplier
	skillDuration *= durationMulti
	throwIfNotFinite(skillDuration)
	//_logDebug(skillDuration > 0, `skill in slot ${slot} has no duration`)
	return skillDuration
}

export enum CooldownSlot {
	NONE = 0,
	SKILL_SLOT_1 = 1,
	SKILL_SLOT_2 = 2,
	SKILL_SLOT_3 = 3,
	SKILL_SLOT_4 = 4,
	SKILL_SLOT_5 = 5,
	SEND_PING_AT_LOCATION = 6,
	// SHOOT = 10,
}
export const allCooldownTypes = [CooldownSlot.SKILL_SLOT_1, CooldownSlot.SKILL_SLOT_2, CooldownSlot.SKILL_SLOT_3, CooldownSlot.SKILL_SLOT_4, CooldownSlot.SKILL_SLOT_5]

// 🐕
export const DODGE_ROLL_CONFIG = {
	attackCooldownMultiplier: 0.7,
	physicsForce1: {
		durationMultiplier: 0.6,
		accelerationMultiplier: 0.6,
	},
	physicsForce2: {
		durationMultiplier: 0.6,
		accelerationMultiplier: 0.2,
	},
	minMovementSpeedMultiplier: 0.8,
	maxMovementSpeedMultiplier: 1.3,
}

export const TUMBLE_ROLL_CONFIG = {
	attackCooldownMultiplier: 0.7,
	physicsForceDurationMultiplier: 0.6,
	minMovementSpeedMultiplier: 0.8,
	maxMovementSpeedMultiplier: 1.3,
}

export const BATTERY_CONFIG = {
	minRateOfFire: 1.6,
	maxRateOfFire: 2.25,
	minDefenseBonus: 0.5,
	maxDefenseBonus: 0.8,
	strengthPerBiome: 0.02,
	turnLimit: 2,
	energyRegenRofDivisor: 2, //energy regen bonus = rate of fire / (this number)
}

const BATTERY_ROF_DIFF = BATTERY_CONFIG.maxRateOfFire - BATTERY_CONFIG.minRateOfFire
const BATTERY_DEF_DIFF = BATTERY_CONFIG.maxDefenseBonus - BATTERY_CONFIG.minDefenseBonus

export function getBatteryAttackSpeedBoost(level: number) {
	const bonusStrength = Math.clamp(Math.floor(level / LEVELS_PER_BIOME) * BATTERY_CONFIG.strengthPerBiome, 0, 1)
	return BATTERY_CONFIG.minRateOfFire + bonusStrength * BATTERY_ROF_DIFF
}

export function getBatteryDamageReduction(level: number) {
	const bonusStrength = Math.clamp(Math.floor(level / LEVELS_PER_BIOME) * BATTERY_CONFIG.strengthPerBiome, 0, 1)
	return 1 - (BATTERY_CONFIG.minDefenseBonus + bonusStrength * BATTERY_DEF_DIFF)
}

export const SICKENING_NOVA_CONFIG = {
	SICKENING_NOVA_RADIUS: 450,
	windupDelay: 9,
}

export const BATTLE_CRY_CONFIG = {
	BATTLE_CRY_RADIUS: 900,
	windupDelay: 15,
}

export const MAX_BULWARK_DIST = 200
export const MAX_BULWARK_DIST_2 = MAX_BULWARK_DIST * MAX_BULWARK_DIST
export const BULWARK_VISUALS = { isPfx: false, name: 'skill-bulwark-model', rotation: 0 }
export const BULWARK_ROTATION_INCREMENT = Math.PI / 4
export const BULWARK_COLLIDER_OFFSET = new Vector(50, 150)
export const BULWARK_COLLIDER_CONFIG: AnyColliderConfig[] = [
	{
		type: ColliderType.Box,
		width: 100, // when you change width/height, make sure you change the position and offset as well!
		height: 300,
		position: [50, 150],
		angle: 0,
		traits: ColliderTraits.BlockMovementAndProjectile,
	},
]
export const BULWARK_CONFIG = {
	MAX_BULWARK_DIST,
	MAX_BULWARK_DIST_2,
	BULWARK_VISUALS,
	BULWARK_COLLIDER_OFFSET,
	BULWARK_COLLIDER_CONFIG,
	MAX_BULWARK_HITS: 10,
	HITS_REMAIN_TO_SHOW_DAMAGED: 4,
}

export const GRAVITY_WELL_PROJECTILE_CONFIG = {
	projectileCount: 1,
	maxRange: 1000,
	spreadAngle: 0,
	spreadVariance: 0,
	noDamage: true,
	pierceCount: Number.MAX_SAFE_INTEGER, //Can maybe do some neat balnce stuff with this; if the number is '20' then it will have 20 ticks of pulling (divided by the number of enemies being pulled)
	splashRadius: 0,
	splashDamage: 0,
	refreshTickRateInSeconds: 0.32, //tick rate
	knockback: -200, // Pull strength
	creatorItemLevel: 0,
	damageBypassesShield: false,
	energyCost: 0,
	colliderRadius: 220, // projectile size
	lifespanInSeconds: 5, //lifespan
	speed: 0,
	modifiers: [],
	bulletParticleEffect: ParticleEffectType.PROJECTILE_GRAVITY_WELL,
	color: 0x010101,
	effectType: ProjectileEffectType.Damage,
	applyBuffsOnHit: [BuffIdentifier.DelayedKnockbackResist],
}

export const GRAVITY_WELL_MAX_RANGE = 800
export const GRAVITY_WELL_MAX_LEN2_RANGE = GRAVITY_WELL_MAX_RANGE * GRAVITY_WELL_MAX_RANGE
export const GRAVITY_WELL_INPUT_MOVEMENT_PREVENTED_DURATION_MULTIPLIER = 0.15

export const GRAVITY_WELL_CONFIG = {
	projectileConfig: GRAVITY_WELL_PROJECTILE_CONFIG,
	GRAVITY_WELL_MAX_RANGE,
	GRAVITY_WELL_MAX_LEN2_RANGE,
	GRAVITY_WELL_INPUT_MOVEMENT_PREVENTED_DURATION_MULTIPLIER,
}
