import nengi from 'nengi'

export default class ProfileMessage {
	static protocol = {
		profileString: nengi.String
	}

	profileString: string

	constructor(profileString) {
		this.profileString = profileString
	}
}
