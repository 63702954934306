import { EnemyAI, FightingTriggers, IdleBehaviours, FightingBehaviours, LeashTriggers, LeashingBehaviours, AttackTypes, DeadBehaviours, ShotLeadPrecision, EnemyType, EnemyTargetingStyles } from '../../shared/ai-types'
import { ModType, BurstFireModes } from '../../../projectiles/shared/projectile-types'
import { SpineDataName, EnemyBipedSkinType } from '../../../models-animations/shared/spine-config'
import { degToRad } from '../../../utils/math'
import { EnemyDefaults } from './enemy-defaults'
import { Colors } from '../../../utils/colors'
import { ParticleEffectType } from '../../../engine/shared/game-data/particle-config'
import { Vector } from 'sat'
import { ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { ENEMY_DEFAULT_HEALTH } from '../../../engine/shared/game-data/enemy-formulas'
import { ENEMY_NAMES } from './enemy-names'
import { Ability, AbilitySelectionStyles, AbilityTargets, AbilityTargetSelectionStyle, AbilityType, ActionCriteria, CriteriaSatisfactionQuantity, CriteriaTarget, CriteriaType } from '../action-types'
import { deepClone } from '../abilities.test'
import { alwaysTrueCriteria } from './abilities/common-abilities'
import { attackRatedAbilityList } from '../action-criteria/ability-helpers'
import { AnimationTrack } from '../../../models-animations/shared/animation-track'
import { worldDifficultyNormalCriterion, worldDifficultyHardCriterion, worldDifficultyBrutalCriterion } from '../action-criteria/action-criteria-helpers'

const whelpShotAbility: Ability = {
	debugName: 'whelpShot',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.CLOSEST,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		projectileCount: 3,
		spreadAngle: degToRad(20),
		speed: 600,
		colliderRadius: 30,
		lifespanInSeconds: 0.8,
		modifiers: [{ modType: ModType.STRAIGHT }],
		baseFireDamage: 20,
		baseIceDamage: 35,
		burstCount: 1,
		burstDelay: 0,
		burstMode: BurstFireModes.STRAIGHT,
		color: Colors.red,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_SKELETALWHELP,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_ICE_TRAIL,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_FIRE_SHOOT,
	},
	attackOffset: new Vector(40, -75),
	ignoreAngleOnWeaponOffset: true,
}

export const whelpShotAbilityNormal = deepClone(whelpShotAbility)
whelpShotAbilityNormal.projectileConfig.projectileCount = 2
whelpShotAbilityNormal.projectileConfig.speed = 450
whelpShotAbilityNormal.projectileConfig.baseFireDamage = 15
whelpShotAbilityNormal.projectileConfig.baseIceDamage = 25

export const whelpShotAbilityHard = deepClone(whelpShotAbility)


export const whelpShotAbilityBrutal = deepClone(whelpShotAbility)
whelpShotAbilityBrutal.projectileConfig.colliderRadius = 40

const animationTimes = {
	[AnimationTrack.SHOOT]: 0.2,
}



const playerIsInRangeCriteriaBrutal: ActionCriteria = {
	satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
	criterias: [
		{
			criteriaTargets: CriteriaTarget.PLAYERS,
			criteriaType: CriteriaType.WITHIN_RANGE,
			criteriaValue: 1000,
			minimumTargetsThatSatisfy: 1,
		},
		worldDifficultyBrutalCriterion,
	],
}
const playerIsInRangeCriteriaHard: ActionCriteria = {
	satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
	criterias: [
		{
			criteriaTargets: CriteriaTarget.PLAYERS,
			criteriaType: CriteriaType.WITHIN_RANGE,
			criteriaValue: 1000,
			minimumTargetsThatSatisfy: 1,
		},
		worldDifficultyHardCriterion,
	],
}
const playerIsInRangeCriteriaNormal: ActionCriteria = {
	satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
	criterias: [
		{
			criteriaTargets: CriteriaTarget.PLAYERS,
			criteriaType: CriteriaType.WITHIN_RANGE,
			criteriaValue: 1000,
			minimumTargetsThatSatisfy: 1,
		},
		worldDifficultyNormalCriterion,
	],
}

const skeletalWhelp: EnemyAI = {
	name: ENEMY_NAMES.SKELETAL_WHELP,
	type: EnemyType.BASIC,
	appearance: {
		asset: SpineDataName.SKELETAL_WHELP,
		skin: EnemyBipedSkinType.DEFAULT,
		flying: true,
		mixSettings: [],
	},
	general: {
		enemyLevel: 1,
		experienceWhenKilled: 1,
	},
	baseAttributes: {
		...EnemyDefaults.baseAttributes,
		visibilityRadius: 1,
		colliders: [
			{
				type: ColliderType.Circle,
				radius: 75,
				traits: ColliderTraits.BlockMovementAndProjectile,
				position: [0, -55],
			},
		],
		movementSpeed: 300,
		decelerationRate: 10,
		turningRatePerSecondInDegrees: 300,
		damageConfig: null,
		attackOffset: new Vector(40, -75),
		lootDropOffset: new Vector(0, 0),

		physicalDamage: 0,
		fireDamage: 0,
		iceDamage: 0,
		lightningDamage: 0,
		poisonDamage: 0,
		attackRate: 600,
		critChance: 0,
		critDamage: 1.0,
		chillPotency: 2.0,
		ignitePotency: 2.0,
		igniteChance: 1,

		maxHealth: (ENEMY_DEFAULT_HEALTH * 2) / 3,
		defense: 0,
		fireResist: 0,
		poisonResist: 0,
		iceResist: 0,
		lightningResist: 0,

		lootDropProbability: 0.75,
		lootDropQuantity: 1,
		lootDropRarity: 1,
	},
	soundEffects: {
		attack: 'Projectile_Fireball',
		impact: 'SFX_Impact_Enemy_Mush',
	},
	states: {
		idling: {
			behaviour: IdleBehaviours.MEANDER,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 2,
			transitionToFighting: {
				trigger: FightingTriggers.AGGRESSIVE_PROXIMITY,
				radius: 800,
			},
			meander: {
				radius: 500,
				speedMultiplier: 1,
				minRestTime: 0.5,
				maxRestTime: 6,
			},
			aggroOnHit: true,
		},
		fighting: {
			behaviour: FightingBehaviours.STRAFE_WHILE_ATTACKING,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 5,
			attackType: AttackTypes.PROJECTILE,
			engagementMaxDistance: 500,
			engagementMinDistance: 0,
			modelCenterOffset: 60,
			movementMaxDistance: 400,
			movementMinDistance: 300,
			brain: {
				actions: [
					{
						//  BLOCK/ATTACK
						priority: 2,
						actionCriteria: playerIsInRangeCriteriaBrutal,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							//abilityOptions: [[1, shortRangeAbility, 30]],
							abilityOptions: [attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, whelpShotAbilityBrutal, 15)],
						},
					},
					{
						//  BLOCK/ATTACK
						priority: 3,
						actionCriteria: playerIsInRangeCriteriaHard,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							//abilityOptions: [[1, shortRangeAbility, 30]],
							abilityOptions: [attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, whelpShotAbilityHard, 15)],
						},
					},
					{
						//  BLOCK/ATTACK
						priority: 4,
						actionCriteria: playerIsInRangeCriteriaNormal,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							//abilityOptions: [[1, shortRangeAbility, 30]],
							abilityOptions: [attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, whelpShotAbilityNormal, 20)],
						},
					},

					{
						//  BLOCK/ATTACK
						priority: 5,
						actionCriteria: alwaysTrueCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							//abilityOptions: [[1, shortRangeAbility, 30]],
							abilityOptions: [attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, whelpShotAbilityNormal, 30)],
						},
					},
				],
			},

			attackOptions: [
				{
					...EnemyDefaults.projectileConfig,
					projectileCount: 3,
					spreadAngle: degToRad(20),
					speed: 600,
					colliderRadius: 30,
					lifespanInSeconds: 0.8,
					modifiers: [{ modType: ModType.STRAIGHT }],
					baseFireDamage: 40,
					baseIceDamage: 40,
					burstCount: 1,
					burstDelay: 0,
					burstMode: BurstFireModes.STRAIGHT,
					color: Colors.red,
					bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_SKELETALWHELP,
					bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_ICE_TRAIL,
					muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_FIRE_SHOOT,
				},
			],
			shotLeadPrecision: ShotLeadPrecision.AVERAGE,
			visualAimLockSeconds: 1,
			transitionToLeashing: {
				trigger: LeashTriggers.DISTANCE_TRAVELLED_OR_RADIUS_EXCEEDED,
				radius: 2000,
				distanceTravelled: 5000,
			},
		},
		leashing: {
			behaviour: LeashingBehaviours.MOVE,
			speedMultiplier: 4,
		},
		fleeing: {},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 1,
		},
	},
}

export default skeletalWhelp
