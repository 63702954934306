









export default {
	name: 'Checkbox',
	props: {
		defaultValue: {
			type: Boolean,
			required: false,
			default: false,
		},
		labelId: {
			type: String,
			required: false,
			default: 'default',
		},
		error: {
			type: [Boolean, String],
			required: false,
			default: false,
		},
		tabIndex: {
			type: Number,
			required: false,
		},
	},
	data() {
		return {
			checkboxInput: this.defaultValue,
		}
	},
	methods: {
		onChangeFn() {
			this.$emit('update-checkbox-input', this.checkboxInput)
		},
	},
}
