














import { mapGetters, mapMutations } from 'vuex'
export default {
	name: 'EnchantmentBarTooltip',
	computed: {
		...mapGetters('UIScale', ['currentHorizontalValue', 'currentVerticalValue', 'currentHorizontalProp', 'currentVerticalProp', 'currentHoveredEchantment']),
	},
	mounted() {
		window.addEventListener('mousemove', this.moveTooltip)
	},
	destroyed() {
		window.removeEventListener('mousemove', this.moveTooltip)
	},
	methods: {
		...mapMutations('UIScale', ['updateToolTipPosition']),
	},
}
