import nengi from 'nengi'

class ForegroundProp {
	static protocol = {
		x: { type: nengi.Int32 },
		y: { type: nengi.Int32 },
		propId: { type: nengi.String },
		scale: { type: nengi.UInt16 },
		r: { type: nengi.UInt8 },
		g: { type: nengi.UInt8 },
		b: { type: nengi.UInt8 },
		isGodRay: { type: nengi.Boolean },
	}
	nid: number
	x: number = 0
	y: number = 0
	propId: string = ''
	scale: number = 1
	r: number = 1
	g: number = 1
	b: number = 1
	isGodRay: boolean = false
}

export default ForegroundProp
