import { StatName } from "../../engine/shared/game-data/stat-type-mod-category"
import WeaponAugmentSubType from "../../loot/shared/weapon-augment-sub-type"

export enum FactionShortName {
    ORDER_OF_IRON = 'iron',
    AURUM_ALLIANCE = 'aurum',
    SCIONS_OF_DAWN = 'dawn',
}

export const ORDER_OF_IRON_PRETTY_NAME = "Order of Iron"
export const AURUM_ALLIANCE_PRETTY_NAME = "Aurum Alliance"
export const SCIONS_OF_DAWN_PRETTY_NAME = "Scions of Dawn"

const FactionShortNameToFullName: Map<FactionShortName, string> = new Map()

FactionShortNameToFullName.set(FactionShortName.ORDER_OF_IRON, ORDER_OF_IRON_PRETTY_NAME)
FactionShortNameToFullName.set(FactionShortName.AURUM_ALLIANCE, AURUM_ALLIANCE_PRETTY_NAME)
FactionShortNameToFullName.set(FactionShortName.SCIONS_OF_DAWN, SCIONS_OF_DAWN_PRETTY_NAME)

export type FactionAugmentBonus = {
    augmentType: WeaponAugmentSubType
    stats: StatName[]
    bonusAmounts: number[]
}

const FactionAugmentBonuses: Map<FactionShortName, FactionAugmentBonus[]> = new Map()

FactionAugmentBonuses.set(FactionShortName.SCIONS_OF_DAWN, [
    {
        augmentType: WeaponAugmentSubType.SplashDamage,
        stats: ['projectileSplashRadius'],
        bonusAmounts: [0.15]
    },
    {
        augmentType: WeaponAugmentSubType.SlowAndStrong,
        stats: ['fireDamage'],
        bonusAmounts: [0.1]
    },
    {
        augmentType: WeaponAugmentSubType.Flamethrower,
        stats: ['ignitePotency'],
        bonusAmounts: [0.2]
    },
    {
        augmentType: WeaponAugmentSubType.FieryOvercharge,
        stats: ['igniteChance'],
        bonusAmounts: [0.2]
    },
    {
        augmentType: WeaponAugmentSubType.FireUp,
        stats: ['fireDamage'],
        bonusAmounts: [0.1]
    }
])

FactionAugmentBonuses.set(FactionShortName.ORDER_OF_IRON, [
    {
        augmentType: WeaponAugmentSubType.ClusterShot,
        stats: ['attackRate', 'chargeRate'],
        bonusAmounts: [0.05, 0.05]
    },
    {
        augmentType: WeaponAugmentSubType.CreepingDeath,
        stats: ['allDamage'],
        bonusAmounts: [0.1]
    },
    {
        augmentType: WeaponAugmentSubType.FastAndWeak,
        stats: ['attackRate', 'chargeRate'],
        bonusAmounts: [0.05, 0.05]
    },
    {
        augmentType: WeaponAugmentSubType.BasicStatUp,
        stats: ['igniteChance', 'chillChance', 'shockChance', 'poisonChance', 'bleedChance'],
        bonusAmounts: [0.1, 0.1, 0.1, 0.1, 0.1]
    },
    {
        augmentType: WeaponAugmentSubType.PhysicalUp,
        stats: ['physicalDamage'],
        bonusAmounts: [0.05]
    }
])

FactionAugmentBonuses.set(FactionShortName.AURUM_ALLIANCE, [
    {
        augmentType: WeaponAugmentSubType.Pierce,
        stats: ['attackRate', 'chargeRate'],
        bonusAmounts: [0.05, 0.05],
    },
    {
        augmentType: WeaponAugmentSubType.CritSwitch,
        stats: ['critDamage'],
        bonusAmounts: [0.15]
    },
    {
        augmentType: WeaponAugmentSubType.Sniper,
        stats: ['allDamage'],
        bonusAmounts: [0.1]
    },
    {
        augmentType: WeaponAugmentSubType.Debilitator,
        stats: ['poisonPotency'],
        bonusAmounts: [0.15]
    },
    {
        augmentType: WeaponAugmentSubType.PoisonUp,
        stats: ['poisonDamage'],
        bonusAmounts: [0.05]
    }
])

export { FactionShortNameToFullName, FactionAugmentBonuses }

export function getFactionHudIconAssetName(faction: FactionShortName) {
    return faction + '-hud-icon'
}

export function factionActions(factionName) {
    switch (factionName) {
        case FactionShortName.ORDER_OF_IRON:
            return ['- Take <span style="color: #e9e5ad">damage</span>']
        case FactionShortName.AURUM_ALLIANCE:
            return ['- Send back <span style="color: #e9e5ad">epic items</span>']
        case FactionShortName.SCIONS_OF_DAWN:
            return ['- Light <span style="color: #e9e5ad">enemies</span> on <span style="color: #e9e5ad">fire</span>']
    }

    return []
}
export function factionBonus(factionName) {
    switch (factionName) {
        case FactionShortName.ORDER_OF_IRON:
            return ['- <span style="color: #e9e5ad">Physical</span> damage increase', '- <span style="color: #e9e5ad">Heart drop</span> rate increase']
        case FactionShortName.AURUM_ALLIANCE:
            return ['- <span style="color: #e9e5ad">Critical damage</span> increase', '- <span style="color: #e9e5ad">Critical chance</span> increase']
        case FactionShortName.SCIONS_OF_DAWN:
            return ['- Bonus to <span style="color: #e9e5ad">fire</span> potency', '- Reduced <span style="color: #e9e5ad">shield</span> recharge time']
    }

    return []
}
export function factionFlagUrl(affiliatedFaction, flagImages) {
    switch (affiliatedFaction) {
        case FactionShortName.ORDER_OF_IRON:
            return flagImages['iron-']
        case FactionShortName.AURUM_ALLIANCE:
            return flagImages['aurum-']
        case FactionShortName.SCIONS_OF_DAWN:
            return flagImages['scion-']
    }

    return flagImages['scion-']
}

export function factionMembers(factionName) {
    return (this.factionMemberCountsPercentage[factionName]).toLocaleString()
}

export const FactionIconDimensions = {
    [FactionShortName.AURUM_ALLIANCE]: {
        width: 27,
        height: 21
    },
    [FactionShortName.ORDER_OF_IRON]: {
        width: 20,
        height: 28
    },
    [FactionShortName.SCIONS_OF_DAWN]: {
        width: 27,
        height: 27
    }
}

export function augmentFactionIcon(faction) {
    switch(faction) {
        case FactionShortName.ORDER_OF_IRON:
            return '<i class="loot-factions-iron-icon-compound" style="color:#ff0000;"></i>'
        case FactionShortName.SCIONS_OF_DAWN:
            return '<i class="loot-factions-scions-icon-compound" style="color:#5cbbff;"></i>'
        case FactionShortName.AURUM_ALLIANCE:
            return '<i class="loot-factions-aurum-icon-compound" style="color:#c284f5;"></i>'
    }
    return '⁉'
}

export function factionTextColor(affiliatedFaction) {
    switch (affiliatedFaction) {
        case FactionShortName.ORDER_OF_IRON:
            return `#ff0000`
        case FactionShortName.AURUM_ALLIANCE:
            return `#c284f5`
        case FactionShortName.SCIONS_OF_DAWN:
            return `#5cbbff`
    }
    return `#e9e5ad`
}

export const FACTION_AFFILIATION_PRIZES = {
    aurum: 'prize-flag-aurum',
    iron: 'prize-flag-iron',
    dawn: 'prize-flag-scion',
}