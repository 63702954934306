import { RiggedSpineModel } from './spine-model'
import { AnimationTrack, getAnimationTrackData as getAnimationTrackConfig } from '../shared/animation-track'
import logger from '../../utils/client-logger'

export type EmptyAnimationMix = [number, number]
export type AnimationList = Array<AnimationTrack | number>

/**
 * Plays an animation on a rigged model or multiple rigged models
 * @param model single model or array of models, if array will play on ALL models
 * @param animationName
 * @param appendEmptyAnimation
 * @param timeScale
 */
function playAnimation(model: RiggedSpineModel | RiggedSpineModel[], animationName: AnimationTrack, appendEmptyAnimation?: EmptyAnimationMix, timeScale?: number) {
	if (Array.isArray(model)) {
		let result: PIXI.spine.core.TrackEntry = null
		model.forEach((m) => {
			const track = playAnimationOnModel(m, animationName, appendEmptyAnimation, timeScale)
			if (result === null) {
				result = track
			}
		})
		return result
	} else {
		return playAnimationOnModel(model, animationName, appendEmptyAnimation, timeScale)
	}
}

function playAnimationOnModel(model: RiggedSpineModel, animationName: AnimationTrack, appendEmptyAnimation?: EmptyAnimationMix, timeScale?: number) {
	try {
		const trackConfig = getAnimationTrackConfig(animationName)

		const current = model.state.getCurrent(trackConfig.trackIndex)
		if (current?.animation?.name === animationName) {
			if (isFinite(timeScale)) {
				current.timeScale = timeScale
			}

			return current
		}

		//logger.debug(`playing ${animationName} on ${model.name}`)

		let trackEntry = null

		if (isFinite(trackConfig.mixInDuration)) {
			// if we're blending in from nothing, we need to set an empty animation to blend from, see:
			//  http://esotericsoftware.com/spine-applying-animations#Empty-animations
			if (!model.state.expandToIndex(trackConfig.trackIndex)) {
				model.state.setEmptyAnimation(trackConfig.trackIndex, 0)
			}
			trackEntry = model.state.addAnimation(trackConfig.trackIndex, animationName, trackConfig.loop, 0)
			trackEntry.mixDuration = trackConfig.mixInDuration
		} else {
			trackEntry = model.state.setAnimation(trackConfig.trackIndex, animationName, trackConfig.loop)
		}

		if (isFinite(timeScale)) {
			trackEntry.timeScale = timeScale
		}

		if (trackConfig.next) {
			const nextAnim = getAnimationTrackConfig(trackConfig.next)
			model.state.addAnimation(trackConfig.trackIndex, trackConfig.next, nextAnim.loop, 0)
		}

		if (!trackConfig.loop) {
			if (trackConfig.animationDuration !== undefined) {
				trackEntry.trackEnd = trackConfig.animationDuration / trackEntry.timeScale
			} else if (!trackConfig.holdLastFrame) {
				trackEntry.trackEnd = trackEntry.animationEnd / trackEntry.timeScale
			}
		}

		if (appendEmptyAnimation && appendEmptyAnimation.length === 2) {
			model.state.addEmptyAnimation(trackConfig.trackIndex, appendEmptyAnimation[0], appendEmptyAnimation[1])
		}

		return trackEntry
	} catch (e) {
		logger.error(`Caught an error attempting to play animation: ${animationName} on model:${model.name}`, e)
		return
	}
}

export function playAnimationList(model: RiggedSpineModel, animations: AnimationList, timeScale: number = 1) {
	let animStartTime = 0
	const timeouts = []
	for (let i = 0; i < animations.length; i++) {
		const animOrNumber = animations[i]
		if (typeof animOrNumber === 'number') {
			animStartTime += animOrNumber
		} else {
			if (animStartTime === 0) {
				playAnimation(model, animOrNumber, undefined, timeScale)
			} else {
				timeouts.push(setTimeout(() => {
					playAnimation(model, animOrNumber, undefined, timeScale)
				}, animStartTime * 1000))
			}
			const anim = model.skeleton.data.findAnimation(animOrNumber)
			if (i === animations.length - 1 || typeof animations[i + 1] !== 'number') {
				animStartTime += anim.duration / timeScale
			}
		}
	}
	return timeouts
}

function stopAnimation(model: RiggedSpineModel | RiggedSpineModel[], animationName: AnimationTrack) {
	if (Array.isArray(model)) {
		let result: PIXI.spine.core.TrackEntry = null
		model.forEach((m) => {
			const track = stopAnimationOnModel(m, animationName)
			if (result === null) {
				result = track
			}
		})
	} else {
		stopAnimationOnModel(model, animationName)
	}
}

function stopAnimationOnModel(model: RiggedSpineModel, animationName: AnimationTrack) {
	const trackConfig = getAnimationTrackConfig(animationName)
	const current = model.state.getCurrent(trackConfig.trackIndex)
	if (current) {
		model.state.addEmptyAnimation(trackConfig.trackIndex, trackConfig.mixOutDuration, 0)
		return current
	} else {
		return model.state.setEmptyAnimation(trackConfig.trackIndex, trackConfig.mixOutDuration)
	}
}

function playDeathAnimation(model: RiggedSpineModel, timeScale?: number) {
	model.state.clearTracks()
	playAnimation(model, AnimationTrack.DEATH, null, timeScale)
}

function playOneOffAnimation(model: RiggedSpineModel, track: AnimationTrack, timeScale?: number) {
	model.state.clearTracks()
	playAnimation(model, track, null, timeScale)
}

export default playAnimation
export { playAnimation, stopAnimation, playDeathAnimation, playOneOffAnimation }
