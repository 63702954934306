import nengi from 'nengi'
import { uuid } from '../../utils/primitive-types'

class ItemSuccessfullyPurchasedMessage {
	static protocol = {
		itemId: nengi.String,
		listingId: nengi.String,
	}
	itemId: uuid
	listingId: uuid
	/**
	 * Creates an instance of ItemSuccessfullyPurchasedMessage. This will scan the Stash to find a matching item.
	 * @param {uuid} itemId Item ID
	 * @memberof ItemSuccessfullyPurchasedMessage
	 */
	constructor(itemId: uuid, listingId: uuid) {
		this.itemId = itemId
		this.listingId = listingId
	}
}

export default ItemSuccessfullyPurchasedMessage
