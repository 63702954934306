import nengi from 'nengi'
import { DrawShapeBase } from './draw-shape-base'

class DrawLine extends DrawShapeBase {
	static protocol = {
		...DrawShapeBase.protocol,
		sourceX: nengi.Number,
		sourceY: nengi.Number,
		destX: nengi.Number,
		destY: nengi.Number,
	}

	sourceX: number
	sourceY: number
	destX: number
	destY: number

	constructor(sourceX: number, sourceY: number, destX: number, destY: number, color: number, permanent: boolean, destroyAfterSeconds: number, scale: number) {
		super(color, permanent, destroyAfterSeconds, scale)
		this.sourceX = sourceX
		this.sourceY = sourceY
		this.destX = destX
		this.destY = destY
	}
}

export default DrawLine
