import logger from '../../utils/client-logger'
import { UI } from '../ui'
import { Audio } from '../../audio/client/audio'
import { PromptMessage } from './prompt-message'
import { WaitCondition } from './WaitCondition'

export const showProximityMessage = (title: string, description: string[]) => {
	UI.getInstance().emitEvent('proximityMessage/showProximityMessage', { title, description })
}

export const setProximityMessageVisible = (visible: boolean) => {
	UI.getInstance().emitEvent('proximityMessage/setProximityMessageVisible', visible)
}

export const clearAllProximityAndFTUEMessages = () => {
	UI.getInstance().emitEvent('proximityMessage/clearAllMessages')
}

export const showFTUEPromptUI = (title: string, description: string[], okButtonText?: string, callback?: () => void) => {
	UI.getInstance().emitEvent('proximityMessage/showDismissableMessage', { title, description, callback, okButtonText })
}

export const showFTUEPromptUISequence = (prompts: PromptMessage[], callback?: () => void) => {
	UI.getInstance().emitEvent('proximityMessage/showDismissableMessageSequence', { prompts, callback })
}

export const showWaitForMessage = (title: string, description: string[], waitForCondition: WaitCondition, callback?: () => void) => {
	UI.getInstance().emitEvent('proximityMessage/showWaitForMessage', { title, description, waitForCondition, callback })
}

// export const postWaitForCondition = (condition: WaitCondition) => { //make event manager so this and advanced-tutorial-tooltip can use the same thing?
// 	UI.getInstance().emitEvent('proximityMessage/postWaitForCondition', condition) // This is currently unused though, so maybe doesn't matter
// }                    															// but would be cool if it still functioned

export const proximityMessageUIModule = () => {
	logger.debug('Initializing proximity message module...')
	return {
		namespaced: true,
		state: {
			proximityMessageTitle: 'Proximity Message',
			proximityMessageDescription: ['DISPLAYS WHEN PLAYERS ARE IN RANGE OF AN OBJECT', 'Description line 2', 'LINE 3; THE FORBBIDEN ONE'],
			waitForMessageTitle: 'Wait Message',
			waitForMessageDescription: ['Shown until waitForCondition is given to the state'],
			proximityMessageVisible: false,
			promptVisible: false,
			waitForMessageVisible: false,
			waitForCondition: null,
			waitCallback: () => {
				console.log('default wait callback')
			},
			prompts: [],
			callback: () => {
				console.log(`default prompt handler`)
			},
		},
		getters: {
			getTitle(state: any) {
				if (state.waitForMessageVisible) {
					return state.waitForMessageTitle
				}

				if (state.prompts.length > 0) {
					return state.prompts[0].title
				}
				return state.proximityMessageTitle
			},
			getDescription(state: any) {
				if (state.waitForMessageVisible) {
					return state.waitForMessageDescription
				}

				if (state.prompts.length > 0) {
					return state.prompts[0].description
				}
				return state.proximityMessageDescription
			},
			getProximityMessageVisible(state: any) {
				return state.proximityMessageVisible
			},
			getPromptVisible(state: any) {
				return state.promptVisible
			},
			getWaitForMessageVisible(state: any) {
				return state.waitForMessageVisible
			},
			getAnyVisible(state: any) {
				return state.promptVisible || state.proximityMessageVisible || state.waitForMessageVisible
			},
			getOkButtonText(state: any) {
				if (state.prompts.length > 0) {
					const text = state.prompts[0].okButtonText
					if (text && text.length > 0) {
						return text
					}
				}
				return 'OKAY'
			},
		},
		mutations: {
			showProximityMessage(state: any, param: any) {
				state.proximityMessageTitle = param.title
				state.proximityMessageDescription = param.description
				state.proximityMessageVisible = true
			},
			setProximityMessageVisible(state, isVisible) {
				//Probably only used to turn off
				state.proximityMessageVisible = isVisible
			},
			clearAllMessages(state) {
				state.proximityMessageVisible = false
				state.promptVisible = false
				state.prompts = []
			},
			showDismissableMessage(state: any, param: any) {
				Audio.getInstance().playSfx('UI_Dialog_Minor_Open')
				const newPrompt = { title: param.title, description: param.description, okButtonText: param.okButtonText ?? 'OKAY' }
				state.prompts = [newPrompt]

				state.callback = param.callback
				state.promptVisible = true
			},
			showDismissableMessageSequence(state: any, param: any) {
				Audio.getInstance().playSfx('UI_Dialog_Minor_Open')
				state.prompts = param.prompts
				state.callback = param.callback

				state.promptVisible = true
			},
			showWaitForMessage(state: any, param: any) {
				//unused
				console.error('requesting wait message without post conditions hooked up')
				const { waitForCondition, title, description, callback } = param

				state.waitForCondition = waitForCondition
				state.waitForMessageTitle = title
				state.waitForMessageDescription = description
				state.waitCallback = callback
				state.waitForMessageVisible = true
			},
			postWaitForCondition(state: any, condition: WaitCondition) {
				//unused
				console.error('bad post condition; probably wanted advanced-tooltip; use the exported function instead of vue to avoid mistakes')
				if (state.waitForCondition === condition) {
					state.waitForMessageVisible = false
					state.waitForCondition = null
					const callback = state.waitCallback
					state.waitCallback = null
					if (callback) {
						callback()
					}
				}
			},
		},
		actions: {
			closePromptPanel({ state }) {
				state.prompts.shift()

				state.promptVisible = state.prompts.length > 0
				if (!state.promptVisible) {
					const oldCallback = state.callback
					state.callback = null
					if (oldCallback) {
						oldCallback()
					}
				}
			},
		},
	}
}
