




































import InventoryItem from '../inventory-item/inventory-item.vue'
import TextButton from '../buttons/text-button.vue'
import EmptyInventorySlot from '../inventory-item/empty-inventory-slot.vue'
import { mapGetters, mapMutations, mapState } from 'vuex'
import { NEWBIE_3_PIECE_SHOOT_AUGMENT_NAME } from '../../../engine/shared/game-data/inventory'


export default {
	name: 'FilteredButtonedGridItemContainer',
	components: {
		InventoryItem,
		TextButton,
		EmptyInventorySlot,
	},
	props: {
		items: {
			type: Array,
			required: true,
			default: () => {
				console.warn('Currently using the default array. Ensure the <ButtonedGridItemContainer /> component has a custom attribute items')
				return []
			},
		},
		showSlot: {
			type: Boolean,
			required: false,
			default: true,
		},
		showCostOverlay: {
			type: Boolean,
			required: false,
			default: true,
		},
		costOverlayAmount: {
			type: Function,
			required: false,
			default: () => 0,
		},
		selectable: {
			type: Boolean,
			required: false,
			default: true,
		},
		onSelectFn: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default on-select handler for the <ButtonedGridItemContainer  /> component has been called. Did you forget to pass an :on-click handler to the component?')
			},
		},
		selectedFn: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default selected fn for the <ButtonedGridItemContainer  /> component has been called. Did you forget to pass an :selected-fn handler to the component?')
			},
		},
		tooltipable: {
			type: Boolean,
			required: false,
			default: true,
		},
		showButtons: {
			type: Boolean,
			required: false,
			default: false,
		},
		onButtonClickFn: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default on-button-click-fn handler for the <ButtonedGridItemContainer /> component has been called. Did you forget to pass an :on-click handler to the component?')
			},
		},
		buttonLabel: {
			type: String,
			required: true,
		},
		buttonToolTipTextFn: {
			type: Function,
			required: false,
			default: () => {
				return ''
			},
		},
		disabledFn: {
			type: Function,
			required: false,
			default: () => {
				return false
			},
		},
		enableFilters: {
			type: Boolean,
			required: false,
			default: false,
		},
		disableAllButtons: {
			type: Boolean,
			required: false,
			default: false,
		}
	},
	data() {
		return {
			MAX_EMPTY_SLOTS: 10,
			numberOfEmptySlots: 0,
			NEWBIE_3_PIECE_SHOOT_AUGMENT_NAME,
		}
	},
	computed: {
		...mapGetters('itemLocks', ['itemIsLocked']),
		...mapGetters('inGame', ['activePanel']),
		...mapState('itemContainers', ['activeRarityFilter']),
	},
	methods: {
		...mapMutations('itemContainers', ['updateRarityFilter']),

		shouldHighlightItem(item) {
			return item.name.toUpperCase().includes(NEWBIE_3_PIECE_SHOOT_AUGMENT_NAME.toUpperCase())
		},

		itemListFiltered(items) {
			const rarityFilter = this.activeRarityFilter
			const hasRarityFilter = rarityFilter !== 'all'
			const numberOfItems = items.length
			let calculatedNumberOfEmptySlots = 0

			if (hasRarityFilter) {
				let numberOfFilteredItems = Object.keys(
					items.filter((item) => {
						return item.rarity.toLowerCase() === rarityFilter
					}),
				).length
				calculatedNumberOfEmptySlots = this.MAX_EMPTY_SLOTS - numberOfFilteredItems
				if (calculatedNumberOfEmptySlots >= 0) {
					this.numberOfEmptySlots = calculatedNumberOfEmptySlots
				} else {
					this.numberOfEmptySlots = 0
				}
				return items.filter((item) => {
					return item.rarity.toLowerCase() === rarityFilter
				})
			} else {
				calculatedNumberOfEmptySlots = this.MAX_EMPTY_SLOTS - numberOfItems
				if (calculatedNumberOfEmptySlots >= 0) {
					this.numberOfEmptySlots = calculatedNumberOfEmptySlots
				} else {
					this.numberOfEmptySlots = 0
				}
				return items
			}
		},
	},
}
