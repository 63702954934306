import { SkinInformationByIdentifier } from '../../loot/shared/player-skin-sub-type'
import { PetSpineNames } from '../../loot/shared/prize-sub-type'
import { SKIN_SLOT } from '../../player/shared/set-skin.shared'

const ModelPaths: Map<string, string> = new Map()
const OnDemandModelPaths: Map<string, string> = new Map()

ModelPaths.set('player-skins', 'sprites/player-skins/player.json')
ModelPaths.set('biped-enemy', 'sprites/biped-enemy/biped-enemy.json')
ModelPaths.set('bubba', 'sprites/npcs/bubba/bubba.json')
ModelPaths.set('merchant-npc', 'sprites/npcs/merchant-01-npc/merchant.json')
ModelPaths.set('merchant-npc-b', 'sprites/npcs/merchant-02-npc/merchant-02-npc.json')
ModelPaths.set('merchant-npc-c', 'sprites/npcs/merchant-03-npc/merchant-03-npc.json')
ModelPaths.set('stash-npc', 'sprites/npcs/stash-npc/stash-npc.json')
ModelPaths.set('wisp-npc', 'sprites/npcs/wisp-npc/wisp-npc.json')
ModelPaths.set('worm', 'sprites/npcs/worm/worm.json')
ModelPaths.set('furnace-npc-a', 'sprites/npcs/furnace-01-npc/furnace-npc.json')
ModelPaths.set('furnace-npc-b', 'sprites/npcs/furnace-02-npc/furnace-02-npc.json')

OnDemandModelPaths.set('weapon-staff', 'sprites/weapons/player_staffs/player_staffs.json')
OnDemandModelPaths.set('weapon-spellsword', 'sprites/weapons/player_swords/player_swords.json')
OnDemandModelPaths.set('weapon-scythe', 'sprites/weapons/player_scythes/player_scythes.json')
OnDemandModelPaths.set('weapon-crossbow', 'sprites/weapons/player_crossbows/player_crossbows.json')
OnDemandModelPaths.set('weapon-wand', 'sprites/weapons/player_wands/player_wands.json')
OnDemandModelPaths.set('weapon-arcane-focus', 'sprites/weapons/player_arcane_focuses/player_arcane_focuses.json')

ModelPaths.set('dwarf', 'sprites/heroes/export_player_dwarf_idlerundodgebouncy/player.json')
ModelPaths.set('skeleton_mage', 'sprites/enemies/skeleton_mage/skelemage.json')
ModelPaths.set('ent', 'sprites/enemies/ent/ent.json')
ModelPaths.set('ent-beard', 'sprites/enemies/ent-beard/ent-beard.json')
ModelPaths.set('shrieker', 'sprites/enemies/shrieker/shrieker.json')
ModelPaths.set('shrieker-vines', 'sprites/enemies/shrieker-vines/shrieker-vines.json')
ModelPaths.set('wasp', 'sprites/enemies/wasp/wasp.json')
ModelPaths.set('sporekid', 'sprites/enemies/sporekid/sporekid.json')
ModelPaths.set('mushie', 'sprites/enemies/mushie/mushie.json')
ModelPaths.set('blimpie', 'sprites/enemies/blimpie/blimpie.json')
ModelPaths.set('shambling-mound', 'sprites/enemies/shambling-mound/shambling-mound.json')
ModelPaths.set('shambling-mound-aoe', 'sprites/enemies/shambling-mound/shambling-mound-aoe.json')
ModelPaths.set('beach-boss', 'sprites/enemies/beach-boss/crab_boss.json')
ModelPaths.set('fungi-boss', 'sprites/enemies/fungi-boss/fungi-boss.json')
ModelPaths.set('fungi-aoe', 'sprites/enemies/fungi-boss/fungi-aoe.json')
ModelPaths.set('eyebat', 'sprites/enemies/eyebat/eyebat.json')
ModelPaths.set('asp', 'sprites/enemies/asp/asp.json')
ModelPaths.set('skeleton-beach', 'sprites/enemies/skeleton-beach/skeleton-beach.json')
ModelPaths.set('beach-aoe', 'sprites/enemies/skeleton-beach/beach-aoe.json')
ModelPaths.set('bee', 'sprites/enemies/bee/bee.json')
ModelPaths.set('mosquito', 'sprites/enemies/mosquito/mosquito.json')
ModelPaths.set('sliver', 'sprites/enemies/sliver/sliver.json')
ModelPaths.set('sliver-cracked', 'sprites/enemies/sliver-cracked/sliver-cracked.json')
ModelPaths.set('crab', 'sprites/enemies/crab/crab.json')
ModelPaths.set('wisp', 'sprites/enemies/wisp/wisp.json')
ModelPaths.set('goblin', 'sprites/enemies/goblin/goblin.json')
ModelPaths.set('goblin-jester', 'sprites/enemies/goblin-jester/goblin-jester.json')
ModelPaths.set('thorn-wolf', 'sprites/enemies/thorn-wolf/thorn-wolf.json')
ModelPaths.set('thorn-wolf-cone', 'sprites/enemies/thorn-wolf-cone/thorn-wolf-cone.json')
ModelPaths.set('spider', 'sprites/enemies/spider/spider.json')
ModelPaths.set('bone-spirit', 'sprites/enemies/bone-spirit/bone-spirit.json')
ModelPaths.set('death-drake', 'sprites/enemies/death-drake/death-drake.json')
ModelPaths.set('skeletal-whelp', 'sprites/enemies/skeletal-whelp/skeletal-whelp.json')
ModelPaths.set('skeletal-whelp-hatched', 'sprites/enemies/skeletal-whelp-hatched/skeletal-whelp-hatched.json')
ModelPaths.set('skeleton-knight', 'sprites/enemies/skeleton-knight/skeleton-knight.json')
ModelPaths.set('skeleton-knight-balloon', 'sprites/enemies/skeleton-knight-balloon/skeleton-knight-balloon.json')
ModelPaths.set('dreamer-prism', 'sprites/enemies/dreamer-prism/dreamer.json')
ModelPaths.set('dreamer-fancy', 'sprites/enemies/dreamer-fancy/dreamer-fancy.json')
ModelPaths.set('dreamer-fungi', 'sprites/enemies/dreamer-fungi/dreamer-fungi.json')
ModelPaths.set('crab-nest-a', 'sprites/enemies/crab-nest/poi-01-a.json')
ModelPaths.set('crab-nest-b', 'sprites/enemies/crab-nest/poi-01-b.json')
ModelPaths.set('crab-nest-c', 'sprites/enemies/crab-nest/poi-01-c.json')
ModelPaths.set('crab-nest-d', 'sprites/enemies/crab-nest/poi-01-d.json')
ModelPaths.set('crab-nest-e', 'sprites/enemies/crab-nest/poi-01-e.json')
ModelPaths.set('crab-nest-f', 'sprites/enemies/crab-nest/poi-01-f.json')
ModelPaths.set('crab-top-hat', 'sprites/enemies/crab-top-hat/crab-top-hat.json')
ModelPaths.set('hermit-crab', 'sprites/enemies/hermit-crab/hermit-crab.json')
ModelPaths.set('shambling-tower', 'sprites/enemies/shambling-tower/poi-01-mound.json')
ModelPaths.set('stranglevine', './sprites/enemies/stranglevine/stranglevine.json')
ModelPaths.set('dragon-skull', 'sprites/enemies/dragon-skull/dragon-skull.json')
ModelPaths.set('golem-prism', 'sprites/enemies/golem-prism/golem-prism.json')
ModelPaths.set('prism-golem-aoe', 'sprites/enemies/golem-prism/prism-golem-aoe.json')
ModelPaths.set('crystal-spider', 'sprites/enemies/crystal-spider/crystal-spider.json')
ModelPaths.set('forest-boss', 'sprites/enemies/forest-boss/forest-boss.json')
ModelPaths.set('mtx-npc', 'sprites/npcs/mtx-npc/mtx-npc.json')
ModelPaths.set('docks-npc', 'sprites/npcs/docks-npc/docks-npc.json')
ModelPaths.set('weatherman-npc', 'sprites/npcs/weatherman-npc/weatherman-npc.json')
ModelPaths.set('identification-npc', 'sprites/npcs/identification-npc/identification-npc.json')
ModelPaths.set('ant-npc-a', 'sprites/npcs/ant-01-npc/ant-npc-01.json')
ModelPaths.set('ant-npc-b', 'sprites/npcs/ant-02-npc/ant-npc-02.json')
ModelPaths.set('island-npc', 'sprites/npcs/island-npc/island-npc.json')
ModelPaths.set('balloon-npc', 'sprites/npcs/balloon-npc/balloon-npc.json')
ModelPaths.set('pit-of-chances-npc', 'sprites/npcs/pit-of-chances-npc/pit-of-chances-npc.json')
ModelPaths.set('highlands-boss', 'sprites/enemies/highlands-boss/highlands-boss.json')
ModelPaths.set('prism-boss', 'sprites/enemies/prism-boss/prism-boss.json')
ModelPaths.set('prism-aoe', 'sprites/enemies/prism-boss/prism-aoe.json')
ModelPaths.set('prism-aoe-md', 'sprites/enemies/golem-prism/prism-aoe-md.json')
ModelPaths.set('prism-aoe-lg', 'sprites/enemies/golem-prism/prism-aoe-lg.json')
ModelPaths.set('fungi-wolf', 'sprites/enemies/fungi-wolf/fungi-wolf.json')
ModelPaths.set('prism-wisp', 'sprites/enemies/prism-wisp/prism-wisp.json')
ModelPaths.set('prismfly', 'sprites/enemies/prismfly/prismfly.json')
ModelPaths.set('wasp-forest', 'sprites/enemies/wasp-forest/wasp-forest.json')
ModelPaths.set('highlands-aoe', 'sprites/enemies/highlands-boss/highlands-aoe.json')
ModelPaths.set('golem-highlands', 'sprites/enemies/golem-highlands/golem-highlands.json')
ModelPaths.set('highlands-golem-aoe', 'sprites/enemies/golem-highlands/highlands-golem-aoe.json')
ModelPaths.set('alter-spawn-animation', 'sprites/misc/altar-resurrection/altar-resurrection.json')
ModelPaths.set('test-range-animated10', 'sprites/enemies/test-dummy-01(target-ground)/test-range-animated10.json')
ModelPaths.set('test-range-animated9', 'sprites/enemies/test-dummy-02(target-standing)/test-range-animated9.json')
ModelPaths.set('test-range-animated8', 'sprites/enemies/test-dummy-03(goblin-sword)/test-range-animated8.json')
ModelPaths.set('test-range-animated7', 'sprites/enemies/test-dummy-04(goblin)/test-range-animated7.json')
ModelPaths.set('test-range-animated6', 'sprites/enemies/test-dummy-05(coconuts)/test-range-animated6.json')
ModelPaths.set('test-range-animated5', 'sprites/enemies/test-dummy-06(crab)/test-range-animated5.json')
ModelPaths.set('test-range-animated3', 'sprites/enemies/test-dummy-07(dummy)/test-range-animated3.json')
ModelPaths.set('test-range-animated2', 'sprites/enemies/test-dummy-08(dummy-sword)/test-range-animated2.json')
ModelPaths.set('generic-aurum', 'sprites/npcs/factions/generic-npc/aurum/generic-aurum.json')
ModelPaths.set('generic-iron', 'sprites/npcs/factions/generic-npc/iron/generic-iron.json')
ModelPaths.set('generic-scion', 'sprites/npcs/factions/generic-npc/scion/generic-scion.json')
ModelPaths.set('recruitment-aurum', 'sprites/npcs/factions/recruitment-npc/aurum/recruitment-aurum.json')
ModelPaths.set('recruitment-iron', 'sprites/npcs/factions/recruitment-npc/iron/recruitment-iron.json')
ModelPaths.set('recruitment-scion', 'sprites/npcs/factions/recruitment-npc/scion/recruitment-scion.json')
ModelPaths.set('beach-crab-barrel', 'biomes/beach/destructible-props/beach-crab-barrel/beach-crab-barrel.json')
ModelPaths.set('beach-mermaid-mast','biomes/beach/destructible-props/beach-mermaid-mast/beach-mermaid-mast.json')
ModelPaths.set('forest-lantern-post','biomes/forest/destructible-props/forest-lantern-post/forest-lantern-post.json')
ModelPaths.set('forest-tanning-rack','biomes/forest/destructible-props/forest-tanning-rack/forest-tanning-rack.json')
ModelPaths.set('fungi-skeleton','biomes/fungi/destructible-props/fungi-skeleton/fungi-skeleton.json')
ModelPaths.set('fungi-spore-sack','biomes/fungi/destructible-props/fungi-spore-sack/fungi-spore-sack.json')
ModelPaths.set('highlands-massive-skull-pile','biomes/highlands/destructible-props/highlands-massive-skull-pile/highlands-massive-skull-pile.json')
ModelPaths.set('highlands-mysterious-gem','biomes/highlands/destructible-props/highlands-mysterious-gem/highlands-mysterious-gem.json')
ModelPaths.set('prism-corrupted-lamp','biomes/prism/destructible-props/prism-corrupted-lamp/prism-corrupted-lamp.json')
ModelPaths.set('prism-crystal-hand','biomes/prism/destructible-props/prism-crystal-hand/prism-crystal-hand.json')

PetSpineNames.forEach((name) => {
	ModelPaths.set(name, `./sprites/pets/${name}.json`)
})

PetSpineNames.forEach((name) => {
	OnDemandModelPaths.set(name, `./sprites/pets/${name}.json`)
})

const ClientModelPaths: Map<string, string> = new Map()
ModelPaths.forEach((value, key) => {
	ClientModelPaths.set(key, './' + value)
})

const ServerModelPaths: Map<string, string> = new Map()
ModelPaths.forEach((value, key) => {
	ServerModelPaths.set(key, './assets/' + value)
})

const OnDemandClientModelPaths: Map<string, string> = new Map()
OnDemandModelPaths.forEach((value, key) => {
	OnDemandClientModelPaths.set(key, './' + value)
})

// This will cause the same skinSlot to be set multiple times,
// but it is a map so it doesn't really matter
SkinInformationByIdentifier.forEach((value, key) => {
	if(value.spineJsonName) {
		switch(value.skinSlot) {
			case SKIN_SLOT.PLAYER_BACK:
				OnDemandClientModelPaths.set(value.spineJsonName, `./sprites/cosmetics/back-cosmetics/${value.spineJsonName}.json`)
				break
		}
	}
})

OnDemandClientModelPaths.set('face-cosmetics', './sprites/cosmetics/face-cosmetics/face-cosmetics.json')

export { ClientModelPaths, ServerModelPaths, OnDemandClientModelPaths }
