import nengi from 'nengi'

class DeleteChunk {
	static protocol = {
		x: nengi.Int32,
		y: nengi.Int32,
	}
	x: number
	y: number

	constructor(x, y) {
		this.x = x
		this.y = y
	}
}

export default DeleteChunk
