import logger from '../../utils/client-logger'

// TODO2: Is this file necessary? I guess keep it in case we add more functionality to the module
export const endOfWorldModule = () => {
	logger.debug('Initializing End of World store module...')
	return {
		namespaced: true,
		state: {},
		getters: {},
		actions: {},
		mutations: {},
	}
}
