import nengi from 'nengi'
import ItemType, { ItemTypePrettyName } from '../../loot/shared/item-type'
import ItemRarity, { ItemRarityPrettyName } from '../../loot/shared/item-rarity'
import { jsonString, uuid } from '../../utils/primitive-types'
// TODO2 push cost calculation out of the shared module
import { getIdentifyCost } from './calculate-identify-cost'
// TODO2 push cost calculation out of the shared module
import { costToAdjustAugment } from '../../utils/economy-data'
import { ContainerType } from './send-item-container-message'
import moment from 'moment'

class AddItemToContainerMessage {
	static protocol = {
		containerType: nengi.String,

		item_biome: nengi.Number,
		item_biome_tier: nengi.Number,

		item_id: nengi.String,
		item_level: nengi.Number,
		item_unidentified: nengi.Boolean,
		item_identify_cost: nengi.Number,
		item_augment_cost: nengi.Number,

		item_paperdollSlotIndex: nengi.Number,
		item_itemType: nengi.UInt16,
		item_itemSubType: nengi.UInt16,
		item_name: nengi.String,
		item_rarity: nengi.UInt16,
		//item_tooltip: nengi.String,
		item_stats: nengi.String,
		item_statsWithAugments: nengi.String,
		item_attuned: nengi.Boolean,

		item_component1: nengi.UInt16,
		item_component2: nengi.UInt16,
		item_component3: nengi.UInt16,

		item_augmentLimit: nengi.UInt16,
		item_equippedAugmentIds: { type: nengi.String, indexType: nengi.UInt16 },

		item_extraData: nengi.String,

		item_createdAt: nengi.String,
		item_stackSize: nengi.UInt16
	}

	containerType: string
	item_id: string
	item_level: number
	item_unidentified: boolean
	item_identify_cost: number

	item_biome: number
	item_biome_tier: number

	item_paperdollSlotIndex: number
	item_itemType: ItemType
	item_itemSubType: number
	item_name: string
	item_rarity: ItemRarity
	//item_tooltip: string
	item_stats: string
	item_statsWithAugments: string
	item_attuned: boolean

	item_component1: number
	item_component2: number
	item_component3: number

	item_augmentLimit: number
	item_augment_cost: number
	item_equippedAugmentIds: uuid[]

	item_extraData: jsonString

	item_createdAt: string

	item_stackSize: number

	// can change this to | item: Item | while coding, but don't import server item into shared message
	constructor(containerType: ContainerType, item: any) {
		this.containerType = containerType
		this.item_paperdollSlotIndex = item.getPaperdollSlot()
		this.item_id = item.id
		this.item_level = item.level
		this.item_unidentified = item.unidentified
		this.item_identify_cost = getIdentifyCost(item.rarity, item.level)
		this.item_augment_cost = costToAdjustAugment(item)

		this.item_biome = item.biome ?? 0
		this.item_biome_tier = item.biomeTier ?? 0

		this.item_itemType = item.itemType
		this.item_itemSubType = item.itemSubType
		this.item_name = item.getPrettyName()

		this.item_rarity = item.rarity
		//this.item_tooltip =  item.prettyPrint()
		if (item.unidentified) {
			this.item_stats = '{}'
			this.item_statsWithAugments = '{}'
		} else {
			this.item_stats = JSON.stringify(item.getStatsJson(false))
			this.item_statsWithAugments = JSON.stringify(item.getStatsJson(true))
		}
		this.item_attuned = item.attuned

		this.item_component1 = item.component1 ?? 0
		this.item_component2 = item.component2 ?? 0
		this.item_component3 = item.component3 ?? 0

		this.item_augmentLimit = item.augmentLimit
		const ids = item.equippedAugments.map((aug) => aug.id)
		this.item_equippedAugmentIds = ids

		if (item.itemType === ItemType.Prize) {
			this.item_extraData = JSON.stringify(item.prizeData)
		} else {
			this.item_extraData = ''
		}


		if (item.createdAt) {
			this.item_createdAt = item.createdAt.toISOString()
		} else {
			this.item_createdAt = ''
		}

		if (item.stackAmount) {
			this.item_stackSize = item.stackAmount
		} else {
			this.item_stackSize = 0
		}
	}
}

export function assembleItemFromMessage(message: AddItemToContainerMessage) {
	return {
		id: message.item_id,
		level: message.item_level,
		unidentified: message.item_unidentified,
		identifyCost: message.item_identify_cost,
		paperdollSlotIndex: message.item_paperdollSlotIndex,
		itemType: ItemTypePrettyName.get(message.item_itemType).toProperCase(),
		itemTypeEnum: message.item_itemType,
		itemSubType: message.item_itemSubType,
		name: message.item_name,
		rarity: ItemRarityPrettyName.get(message.item_rarity).toProperCase(),
		rarityEnum: message.item_rarity,
		//tooltip: message.item_tooltip.replace(/\n/g, '<br>'),
		stats: JSON.parse(message.item_stats),
		statsWithAugments: JSON.parse(message.item_statsWithAugments),
		biome: message.item_biome,
		biomeTier: message.item_biome_tier,
		augmentCost: message.item_augment_cost,
		augmentLimit: message.item_augmentLimit,
		equippedAugmentIds: message.item_equippedAugmentIds,
		// equippedEnchantmentIds: JSON.parse(message.item_equippedEnchantmentIds),
		component1: message.item_component1,
		component2: message.item_component2,
		component3: message.item_component3,
		attuned: message.item_attuned,
		extraData: message.item_extraData.length > 0 ? JSON.parse(message.item_extraData) : null,
		createdAt: message.item_createdAt.length > 0 ? moment(message.item_createdAt).utc() : null,
		stackAmount: message.item_stackSize > 0 ? message.item_stackSize : undefined,
	}
}

export default AddItemToContainerMessage
