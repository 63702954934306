import { Vector } from 'sat'
import Renderer, { getVisibleWorldHeight, getVisibleWorldWidth, getZoomLevelFromWindowWidth, NENGI_VIEW_X_PADDING, NENGI_VIEW_Y_PADDING } from './renderer'
import { ClientPlayer } from '../../../player/client/player.client'
import { Colors } from '../../../utils/colors'
import Noise from '../../../third-party/perlin'
import { add, mapToRange, scale, smoothDamp, smoothDampV, sub, VectorXY, withinDistanceVV } from '../../../utils/math'
import { timeInSeconds, gameUnits, nengiId } from '../../../utils/primitive-types'
import { debugConfig } from '../debug-config'
import logger from '../../../utils/client-logger'
import { clientConfig } from '../client-config'
import { CameraShakeIntensities } from '../../shared/camera.shared'
import DevToolsManager from '../../../ui/dev-tools/dev-tools-manager'
import { getClientPOIFromLocalPlayer } from '../../../world/client/poi.client'
import { GameClient } from '../game-client'
import { NengiClient } from '../nengi-client'
import NengiViewUpdatedCommand from '../../shared/nengi-view-updated-command'
import { clientDebug } from '../../../debug/client/debug-client'
import { POIType } from '../../../world/shared/poi-data-types'
import { pressureGetZoomModifier } from '../../shared/game-data/pressure-stat-functions.shared'
import { getPressureLoadoutOnClient } from '../pressure.client'
import { ENEMY_BIG_HIT_ON_PLAYER_DAMAGE, ENEMY_SMALL_HIT_ON_PLAYER_DAMAGE } from '../../shared/game-data/enemy-formulas'

// the most the camera translates while shaking
const SHAKE_OFFSET_MAX: gameUnits = 30
const SHAKE_TRAUMA_MAX = 1
// how many times trauma is multiplied to get shake amount
const SHAKE_EXPONENT = 3
const SHAKE_DURATION_MAX: timeInSeconds = 2

const SHAKE_DAMAGE_LOG_MIN = Math.log(ENEMY_SMALL_HIT_ON_PLAYER_DAMAGE)
const SHAKE_DAMAGE_LOG_MAX = Math.log(ENEMY_BIG_HIT_ON_PLAYER_DAMAGE)
// how fast we move through our perlin-noise field
const SHAKE_TIME_MULT = 25
// only allow this much 'additional' trauma above the newly added trauma
//  ie: if you're standing in front of a firing machine-gun, do not go to trauma:1, as that is earthquake level
const SHAKE_TRAUMA_MAX_CUMULATIVE_ADD = 0.1

const RECOIL_DURATION = 0.1
// if a recoil has finsished within this time, reduce the amount of recoil on subsequent recoils
const RECOIL_MAG_ADJUST_TIME = 0.5

const normalSettings: CamSettings = {
	smoothTime: 0.2,
	playerFocus: 1,
	poiFocus: 0,
	bossFocus: 0,
	zoom: 1,
}
const poiBossSettings: CamSettings = {
	smoothTime: 0.5,
	bossFocus: 0.5,
	playerFocus: 0.7,
	poiFocus: 0.5,
	zoom: 0.8,
}
const poiBossBetweenRoundsSettings: CamSettings = {
	smoothTime: 0.5,
	bossFocus: 0.5,
	playerFocus: 0.7,
	poiFocus: 0.2,
	zoom: 0.8,
}
const poiOutpostSettings: CamSettings = {
	smoothTime: 0.5,
	bossFocus: 0,
	playerFocus: 0.3,
	poiFocus: 1,
	zoom: 1.1,
}

const cameraSettings = {
	maxDistanceFromPlayer: 500,
	normalSettings,
	poiBossSettings,
	poiBossBetweenRoundsSettings,
	poiOutpostSettings,
}

interface CamSettings {
	smoothTime: timeInSeconds
	playerFocus: number
	poiFocus: number
	bossFocus: number
	zoom: number
}

if (process.env.NODE_ENV !== 'beta' && process.env.NODE_ENV !== 'loot-prod') {
	DevToolsManager.getInstance().addObjectByName('cameraSettings', cameraSettings)
}

export function updateCamera(entity: ClientPlayer, delta: number) {
	const camera = Camera.getInstance()
	const renderer = Renderer.getInstance()

	if (!camera.worldSpaceCamPos) {
		camera.worldSpaceCamPos = renderer.getCameraCenterWorldPos()
	}

	const entityVisualPos = entity.visualPos
	let targetPos = entityVisualPos.clone()

	let settings = normalSettings

	const poi = getClientPOIFromLocalPlayer()
	if (poi) {
		settings = getSettingsFromPOIType(poi.type, poi.bossId)

		let w = settings.playerFocus + settings.poiFocus
		targetPos = add(scale(targetPos, settings.playerFocus), scale(new Vector(poi.x, poi.y), settings.poiFocus))

		if (poi.bossId !== -1) {
			const boss = GameClient.getInstance().entities.get(poi.bossId)
			if (boss) {
				targetPos = add(targetPos, scale(new Vector(boss.x, boss.y), settings.bossFocus))
				w += settings.bossFocus
			}
		}

		targetPos = scale(targetPos, 1 / w)
	}

	if (cameraSettings.maxDistanceFromPlayer && !withinDistanceVV(targetPos, entityVisualPos, cameraSettings.maxDistanceFromPlayer)) {
		const diff = sub(targetPos, entityVisualPos)
		targetPos = add(scale(diff.normalize(), cameraSettings.maxDistanceFromPlayer), entityVisualPos)
	}

	const targetZoom = settings.zoom * getZoomLevelFromWindowWidth() * pressureGetZoomModifier(getPressureLoadoutOnClient())

	smoothDampV(camera.worldSpaceCamPos, camera.worldSpaceCameraVel, targetPos, delta, settings.smoothTime)

	const out = smoothDamp(renderer.zoomLevel / renderer.debugZoomLevel, camera.cameraZoomVel, targetZoom, delta, settings.smoothTime)
	renderer.updateZoomLevel(out[0])
	camera.cameraZoomVel = out[1]

	renderer.centerCameraOnPoint(camera.worldSpaceCamPos.x, camera.worldSpaceCamPos.y, 1)

	const newWidth = getVisibleWorldWidth(targetZoom) + NENGI_VIEW_X_PADDING
	const newHeight = getVisibleWorldHeight(targetZoom) + NENGI_VIEW_Y_PADDING

	NengiClient.getInstance().sendCommand(new NengiViewUpdatedCommand(camera.worldSpaceCamPos.x, camera.worldSpaceCamPos.y, newWidth, newHeight))

	if (!clientConfig.disableCameraShake) {
		const cameraPosAfterMouse = renderer.getCameraCenterWorldPos()

		let offset = camera.updateShake(entityVisualPos, delta)
		cameraPosAfterMouse.add(offset)

		offset = camera.updateRecoil(entityVisualPos, delta)
		cameraPosAfterMouse.add(offset)
		renderer.centerCameraOnPoint(cameraPosAfterMouse.x, cameraPosAfterMouse.y, 1)
	}
}

function getSettingsFromPOIType(poiType: POIType, bossId: nengiId) {
	if (poiType === POIType.Outpost) {
		return poiOutpostSettings
	} else if (poiType === POIType.Boss) {
		if (bossId !== -1) {
			return poiBossSettings
		}
		return poiBossBetweenRoundsSettings
	}
	return normalSettings
}

// for explanation of trauma vs shake see:
// https://youtu.be/tu-Qe66AvtY?t=220
export class Camera {
	static getInstance(): Camera {
		if (!Camera.instance) {
			Camera.instance = new Camera()
		}

		return Camera.instance
	}

	static shutdown() {
		Camera.instance = null
	}

	worldSpaceCamPos = null
	worldSpaceCameraVel = new Vector(0, 0)
	cameraZoomVel = 0

	private static instance

	private shakeTime = 0
	private trauma = 0
	private noise = [new Noise(), new Noise()]
	private recoilTime = 0
	private recoilAngle = 0
	private recoilOffset = new Vector(0, 0)
	private recoilMagMult = 1
	private recoilMag = 5

	private constructor() {
		for (let i = 0; i < this.noise.length; i++) {
			this.noise[i].seed(i)
		}

		if (debugConfig.camera.debug) {
			document.addEventListener('keydown', (event) => {
				const amount = [0.5, 0.6, 1]

				if (event.code.includes('Numpad')) {
					const idx = Number(event.code.replace('Numpad', ''))
					Camera.getInstance().triggerShake(amount[idx])
				}
			})
		}
	}

	triggerShake(traumaAmount: number) {
		const cumul = this.trauma > 0
		if (debugConfig.camera.debug && cumul) {
			debugMessage(`can add cumulative trauma:${this.trauma}+${traumaAmount}`)
		}

		if (traumaAmount > 0 && traumaAmount <= SHAKE_TRAUMA_MAX) {
			this.trauma += traumaAmount
		} else {
			logger.error(`bad trauma amount passed to triggerShake:${traumaAmount}`)
		}

		this.trauma = Math.clamp(this.trauma, 0, traumaAmount + SHAKE_TRAUMA_MAX_CUMULATIVE_ADD)

		if (debugConfig.camera.debug && !cumul) {
			debugMessage(`trauma:${this.trauma}`)
		}
	}

	// this is a special version of shake that maps damage to trauma
	triggerShakeWithDamage(damage: number) {
		const ldam = Math.log(damage)
		const trauma = mapToRange(
			ldam,
			SHAKE_DAMAGE_LOG_MIN,
			SHAKE_DAMAGE_LOG_MAX, // input range
			CameraShakeIntensities.MILD,
			CameraShakeIntensities.INTENSE, // output range
			true,
		)

		// logger.debug(`ldam: ${ldam}, damage: ${damage}, trauma: ${trauma}`)

		this.triggerShake(trauma)
	}

	triggerRecoil(angle: number, recoilMagnitude: number) {
		//logger.debug(`triggerRecoil t:${this.recoilTime} m:${this.recoilMagMult}`)
		this.recoilMag = recoilMagnitude
		this.recoilAngle = angle
		this.recoilMagMult = mapToRange(this.recoilTime, -RECOIL_MAG_ADJUST_TIME, RECOIL_DURATION, 1, 0, true)
		this.recoilTime = RECOIL_DURATION
		// logger.debug(`triggerRecoil:${this.recoilTime} magMult:${this.recoilMagMult}, passed magnitude: ${recoilMagnitude}`)
	}

	getShakeAmount() {
		return this.trauma ** SHAKE_EXPONENT
	}

	updateShake(entityVisualPos: VectorXY, delta: number): Vector {
		this.shakeTime += delta

		const shakeAmount = this.getShakeAmount()

		const randx = this.getRandomFloatNegOneToOne(0, this.shakeTime * SHAKE_TIME_MULT)
		const randy = this.getRandomFloatNegOneToOne(1, this.shakeTime * SHAKE_TIME_MULT)
		const offset = new Vector()
		offset.x = SHAKE_OFFSET_MAX * shakeAmount * randx
		offset.y = SHAKE_OFFSET_MAX * shakeAmount * randy

		this.trauma -= delta / SHAKE_DURATION_MAX
		this.trauma = Math.clamp(this.trauma, 0, SHAKE_TRAUMA_MAX)

		if (debugConfig.camera.debug) {
			this.debugDrawMeters(entityVisualPos, shakeAmount)
		}

		return offset
	}

	updateRecoil(entityVisualPos: VectorXY, delta: number) {
		this.recoilTime -= delta

		let xoffset = 0

		if (this.recoilTime > 0) {
			const x = (RECOIL_DURATION - this.recoilTime) / RECOIL_DURATION
			// see camera.md to view this formula
			xoffset = Math.sin((x * 10) ** 0.5)
			xoffset *= -this.recoilMag * this.recoilMagMult
			//logger.debug(this.recoilTime, xoffset, this.recoilAngle)
		}

		this.recoilOffset.x = xoffset
		this.recoilOffset.y = 0

		// if (this.recoilTime > 0) {
		// 	console.log('before', this.recoilOffset)
		// }

		this.recoilOffset.rotate(this.recoilAngle)
		// if (this.recoilTime > 0) {
		// 	console.log('after', this.recoilOffset)
		// }

		return this.recoilOffset
	}

	private getRandomFloatNegOneToOne(channel: number, t: number) {
		const n = this.noise[channel].perlin2(t, 0) * 2
		return n
	}

	private debugDrawMeters(worldPos: VectorXY, shakeAmount: number) {
		const pos = new Vector(worldPos.x - 520, worldPos.y + 300)
		const w = 20
		const h = 500
		const renderer = Renderer.getInstance()
		clientDebug.drawRectangle(pos.x - 1, pos.y, w * 2 + 2, -h, Colors.white, false, 0, 1)
		clientDebug.drawRectangle(pos.x, pos.y, w, -h * this.trauma, Colors.green, false, 0, 1)
		clientDebug.drawRectangle(pos.x + w, pos.y, w, -h * shakeAmount, Colors.blue, false, 0, 1)
	}
}

function debugMessage(s: string) {
	logger.debug(s)
}
