import nengi from 'nengi'
import { uuid } from '../../utils/primitive-types'
import { ContainerType } from './send-item-container-message'

class ItemStackSizeChangedMessage {
	static protocol = {
		itemId: nengi.String,
		stackSize: nengi.UInt16,
        container: nengi.String,
	}
	
    itemId: uuid
	stackSize: number
    container: ContainerType

	constructor(itemId: uuid, stackSize: number, container: ContainerType) {
		this.itemId = itemId
		this.stackSize = stackSize
        this.container = container
	}
}

export default ItemStackSizeChangedMessage
