import _ from 'lodash'
import MersenneTwister from 'mersenne-twister'

export interface WeightedRandom {
	weight: number
}

/**
 * Copies the values of `source` to `array`.
 *
 * @private
 * @param {Array} source The array to copy values from.
 * @param {Array} [array=[]] The array to copy values to.
 * @returns {Array} Returns `array`.
 */
function copyArray(source, array?) {
	let index = -1
	const length = source.length

	if (!array) {
		array = new Array(length)
	}

	while (++index < length) {
		array[index] = source[index]
	}
	return array
}

export function sampleSize<T extends WeightedRandom>(options: T[], n: number, mt: MersenneTwister): T[] {
	let picks: T[] = copyArray(options)
	const results: T[] = []
	if (options.length === 0) {
		return results
	}
	n = Math.min(n, options.length)
	while (n > 0) {
		const pick = rollWeightedRandom(picks, mt)
		picks = _.filter(picks, (p) => p !== pick)
		results.push(pick)
		n--
	}
	return results
}

export function rollWeightedRandom<T extends WeightedRandom>(options: T[], mt?: MersenneTwister): T {
	let totalWeight = 0

	for (let i = 0; i < options.length; ++i) {
		totalWeight += options[i].weight
	}

	let randomValue = (mt ? mt.random() : Math.random()) * totalWeight

	for (let i = 0; i < options.length; ++i) {
		if (randomValue <= options[i].weight) {
			return options[i]
		} else {
			randomValue -= options[i].weight
		}
	}

	return null // should not be possible
}

export function rollCurvedRandom(options: WeightedRandom[], counts: number[], mt?: MersenneTwister): { result: WeightedRandom; index: number } {
	let totalCounts = 0
	for (let i = 0; i < counts.length; ++i) {
		totalCounts += counts[i]
	}

	let totalWeight = 0
	for (let i = 0; i < options.length; ++i) {
		totalWeight += options[i].weight * pseudorandomFactor(counts[i], totalCounts)
	}

	let randomValue = (mt ? mt.random() : Math.random()) * totalWeight

	for (let i = 0; i < options.length; ++i) {
		const prngFactor = pseudorandomFactor(counts[i], totalCounts)
		if (randomValue <= options[i].weight * prngFactor) {
			return { result: options[i], index: i }
		} else {
			randomValue -= options[i].weight * prngFactor
		}
	}

	return null // should not be possible
}

function pseudorandomFactor(count: number, totalCounts: number): number {
	if (totalCounts === 0) {
		return 1
	}

	return ((totalCounts - count) / totalCounts) ** 2
}
