import nengi from 'nengi'

export default class PartyExitGameCommand {
	static protocol = {
		partyId: nengi.String,
	}

	partyId: string

	constructor(partyId: string) {
		this.partyId = partyId
	}
}
