import { timeInSeconds } from '../../utils/primitive-types'
import { ClientBuff } from './buff.client'
import { highResolutionTimestamp } from '../../utils/debug'

export class ClientBuffSystem {
	static buffs: ClientBuff[] = []

	static update(delta: timeInSeconds) {
		const now = highResolutionTimestamp()
		ClientBuffSystem.buffs.forEach((buff) => {
			buff.update(delta, now)
		})
	}
}
