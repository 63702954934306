































































































































































import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import ConditionalButtonedGridItemContainer from '../reusable-components/item-containers/conditional-buttoned-grid-item-container.vue'
import PanelDivider from '../reusable-components/panel-divider/panel-divider.vue'
import ArrowButton from '../reusable-components/buttons/arrow-button.vue'
import { NengiClient } from '../../engine/client/nengi-client'
import { MAX_ITEM_LISTINGS } from '../state/marketplace.ui-state'
import { FURNACE_SLOT_COUNT } from '../state/furnace-ui-state'
import { furnaceTooltipText, marketTooltipText, pitStorageFullTooltipText, stashFullTooltipText } from '../reusable-components/v-tooltip/v-tooltip-functions'
import ItemType from '../../loot/shared/item-type'
import { PrizeSubType } from '../../loot/shared/prize-sub-type'
import { FilterType } from '../state/cosmetics-ui-state'
import { containerWillStackItems } from '../state/item-containers.ui-state'
import OpenCurrencyBagCommand from '../../items/shared/open-currency-bag-command'

export default {
	name: 'HubWormDelivery',
	components: {
		MainPanel,
		ConditionalButtonedGridItemContainer,
		PanelDivider,
		ArrowButton,
	},
	data() {
		return {
			MAX_LISTINGS: MAX_ITEM_LISTINGS,
			MAX_FURNACE: FURNACE_SLOT_COUNT,
			FilterType,
			PrizeSubType,
			NengiClient,
		}
	},
	computed: {
		...mapGetters('itemContainers', ['itemsInContainer', 'numberOfItemsInContainer', 'itemDetails', 'isStashFull', 'isPitStorageFull']),
		...mapGetters('itemLocks', ['itemIsLocked']),
		...mapGetters('hubWormDelivery', ['selectedWormDeliveryItem']),
		...mapGetters('furnace', ['hasFreeFurnace', 'countOfOccupiedFurnaceSlots']),
		...mapGetters('marketplaceUpdated', ['getMyListings']),
		...mapGetters('hud', ['coinBalance']),
		...mapState('marketplaceUpdated', ['isListingItem']),
		...mapState('cosmetics', ['ownedCosmetics']),

		isSelectedItemLocked() {
			return !this.selectedWormDeliveryItem || (this.selectedWormDeliveryItem && this.itemIsLocked(this.selectedWormDeliveryItem))
		},
		isSelectedItemBiomeKey() {
			const selectedDetails = this.itemDetails('wormDelivery', this.selectedWormDeliveryItem)
			return selectedDetails && selectedDetails.itemTypeEnum === ItemType.BiomeKey
		},
		isSelectedItemCoins() {
			const itemDetails = this.itemDetails('wormDelivery', this.selectedWormDeliveryItem)
			return itemDetails && itemDetails.itemType === 'Currency Bag'
		},
		topButtonText() {
			if(this.isSelectedItemCoins) {
				return 'Send Coins to Wallet!'
			}
			return 'Put in stash!'
		},
		topButtonIcon() {
			if(this.isSelectedItemCoins) {
				return 'coin'
			}
			return 'stash'
		},
		isSelectedItemSmeltable() {
			const selectedDetails = this.itemDetails('wormDelivery', this.selectedWormDeliveryItem)
			return selectedDetails && selectedDetails.itemTypeEnum !== ItemType.Prize && !selectedDetails.stackAmount
		},
		isSelecteditemStackable() {
			const selectedDetails = this.itemDetails('wormDelivery', this.selectedWormDeliveryItem)
			return selectedDetails && Boolean(selectedDetails.stackAmount)
		},
		stashHasStackableSpace() {
			const selectedItemDetails = this.itemDetails('wormDelivery', this.selectedWormDeliveryItem)
			if(!selectedItemDetails || !selectedItemDetails.stackAmount) {
				return !this.isStashFull
			}

			const stashItems = this.itemsInContainer('stash')
			if(containerWillStackItems(stashItems, selectedItemDetails)) {
				return true
			}

			return !this.isStashFull
		}
	},
	methods: {
		...mapActions('inGame', ['closeActivePanel']),
		...mapActions('hubWormDelivery', ['toggleSelectHubWormDeliveryItem', 'sendItemToStashFromWormDelivery']),
		...mapMutations('itemLocks', ['lockItem', 'unlockItem']),
		...mapActions('furnace', ['startSmeltingItem']),
		...mapActions('identify', ['identifyDeliveryItem', 'deselectIdentifiedItem']),
		...mapActions('cosmetics', ['hatchDeliveryEgg']),
		...mapMutations('marketplaceUpdated', ['showListingPopUp']),
		...mapActions('pitOfChances', ['tossDirectlyIntoPit']),
		marketTooltipText,
		furnaceTooltipText,
		stashFullTooltipText,
		pitStorageFullTooltipText,

		itemIsSelected(itemId) {
			return itemId === this.selectedWormDeliveryItem
		},
		throwItemToPit(itemId) {
			this.tossDirectlyIntoPit({itemId, fromContainer: 'wormDelivery', sendingContainer: 'worm_delivery'})
			this.selectNextItem()
		},
		putItemInFurnace(itemId) {
			this.lockItem(itemId)
			this.startSmeltingItem({ itemId, fromContainer: 'wormDelivery' })

			this.selectNextItem()
		},
		openCurrencyBag(itemId) {
			NengiClient.getInstance().sendCommand(new OpenCurrencyBagCommand(itemId, 'wormDelivery'))
		},
		selectNextItem() {
			const container: any[] = this.itemsInContainer('wormDelivery')
			const currentSelected = this.selectedWormDeliveryItem
			const currentIndex = container.findIndex((i) => i.id === currentSelected)

			if (currentIndex > -1) {
				let nextIndex = currentIndex + 1
				if (nextIndex >= container.length) {
					nextIndex = 0
				}

				const nextItem = container[nextIndex]
				this.toggleSelectHubWormDeliveryItem(nextItem.id)

				if (currentSelected === this.selectedWormDeliveryItem) {
					// deselect (above call was for a different item, but it was locked)
					this.toggleSelectHubWormDeliveryItem(currentSelected)
				}
			}
		},
		listItem() {
			this.showListingPopUp({ itemId: this.selectedWormDeliveryItem, container: 'wormDelivery' })
			this.toggleSelectHubWormDeliveryItem(null)
		},
	},
}
