

export const getPremiumStoreIconHexCode = (filterId: string) => {
	switch (filterId) {
		case 'weapon':
			return '&#xe80e;'
		case 'skin':
			return '&#xe820;'
		case 'pet':
			return '&#xe81d;'
		case 'attachment':
			return '&#xe809;'
		case 'consumable':
			return '&#xe807;'
		case 'account':
			return '&#xe800;'
		case 'featured':
			return '&#xe80a;'
		case 'bundle':
			return '&#xe805;'
	}
}

export const getCustomizeSkinIconHexCode = (filterId: string) => {
	switch (filterId) {
		case 'Player Skin':
			return '&#xe820;'
		case 'Pet Skin':
			return '&#xe81d;'
		case 'Weapon Skins':
			return '&#xe80e;'
		case 'Trail':
			return '&#xe81e;'
		case 'Back':
			return '&#xe804;'
		case 'Face':
			return '&#xe809;'
	}
}