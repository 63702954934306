import { ModType, ProjectileEffectType } from '../../../projectiles/shared/projectile-types'
import { Colors } from '../../../utils/colors'
import { Vector } from 'sat'
import { ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { EnemyAIBaseAttributes } from 'src/ai/shared/ai-types'
import { ENEMY_DEFAULT_HEALTH } from '../../../engine/shared/game-data/enemy-formulas'

export class EnemyDefaults {
	static baseAttributes: EnemyAIBaseAttributes = {
		colliders: [
			{
				type: ColliderType.Circle,
				radius: 50,
				position: [0, -60],
				traits: ColliderTraits.BlockMovementAndProjectile,
			},
		],
		movementSpeed: 100,
		visibilityRadius: 1,
		decelerationRate: 1,
		knockbackDecelerationRate: 4,
		turningRatePerSecondInDegrees: 1000,

		allDamage: 1.0,
		projectileDamage: 1.0,
		physicalDamage: 0,
		fireDamage: 0,
		iceDamage: 0,
		lightningDamage: 0,
		poisonDamage: 0,
		attackRate: 1000,
		critChance: 0.05,
		critDamage: 1.5,
		cooldownDuration: 0,
		cooldownSpeed: 1.0,
		debuffPotency: 1.0,

		maxHealth: ENEMY_DEFAULT_HEALTH,
		healthRegen: 0,
		healthRegenDelay: 8000,
		maxShields: 0,
		shieldRegenRate: 0,
		shieldRegenDelay: 0,
		maxEnergy: 0,
		energyRegen: 0,
		primaryFireEnergyCost: 0,
		defense: 0,
		fireResist: 0,
		lightningResist: 0,
		iceResist: 0,
		poisonResist: 0,
		buffDuration: 1.0,
		skillUsageSpeed: 1.0,
		attackOffset: new Vector(0, 0),
		lootDropOffset: new Vector(0, 0),
		damageConfig: null, //basicEnemyDamageConfig, // some enemies don't work with this, see: https://cdn.discordapp.com/attachments/657291315955892224/763828397654147133/unknown.png

		lootDropProbability: 1,
		lootDropQuantity: 1,
		lootDropRarity: 1,

		damageTaken: 1,
		damageTakenFromPhysical: 1,
		damageTakenFromFire: 1,
		damageTakenFromIce: 1,
		damageTakenFromLightning: 1,
		damageTakenFromPoison: 1,

		healingReceived: 1,

		bleedChance: 0,
		igniteChance: 0.25,
		chillChance: 0.4,
		shockChance: 0.125,
		poisonChance: 0.5,

		bleedPotency: 0.25,
		ignitePotency: 0.5,
		chillPotency: 0.5,
		shockPotency: 2,
		poisonPotency: 0.25,
	}

	static projectileConfig /*:ProjectileConfig*/ = {
		projectileCount: 1,
		maxRange: 2000,
		/** In radians */
		spreadAngle: 0,
		spreadVariance: 0,
		burstAngle: 0,
		pierceCount: 0,
		splashDamage: 0,
		splashRadius: 0,
		speed: 350,
		colliderRadius: 15,
		lifespanInSeconds: 5,
		modifiers: [{ modType: ModType.STRAIGHT }],
		// bulletParticleEffect: ParticleEffectType.PROJECTILE_ENEMY0,
		// bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_ENEMY_TRAIL,
		// muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
		color: Colors.white,
		burstCount: 0,
		effectType: ProjectileEffectType.Damage,
	}

	static spawningRules = {
		spawnLimit: 0,
		spawnEnemy: '',
		spawnRate: 0,
		spawnRadius: 100,
		spawnlingsGiveXP: false,
		spawnlingsGiveLoot: false,
		killSpawnlingsWhenIDie: false,
	}
}

Object.freeze(EnemyDefaults)
