import nengi from 'nengi'
import { WorldDifficulty } from '../../engine/shared/game-data/world-difficulty'

class WorldDifficultyChangedMessage {
	static protocol = {
		worldDifficulty: nengi.Int8,
	}
	worldDifficulty: WorldDifficulty

	constructor(worldDifficulty) {
		this.worldDifficulty = worldDifficulty
	}
}

export default WorldDifficultyChangedMessage
