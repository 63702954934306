






















import { PlayerSkinValues, PlayerSkinType } from '../../../models-animations/shared/spine-config'
import playerPortraitsJson from '../../reusable-components/assets/player-skins-atlas.json'

export default {
	name: 'PlayerPortrait',
	props: {
		portraitSize: {
			type: String,
			required: true,
			default: 'small',
		},
		mainSkin: {
			type: String,
			required: false, //this should be true but I don't want to change everywhere it's used right now
			validator: function(value) {
				return !value || PlayerSkinValues.includes(value as PlayerSkinType)
			},
			default: '',
		},
	},
	computed: {
		getLargePortraitStyle() {
			return this.getStyleForPortrait(this.mainSkin, 233, 262)
		},
		getSmallPortraitStyle() {
			return this.getStyleForPortrait(this.mainSkin, 180, 215)
		}
	},
	methods: {
		getStyleForPortrait(spriteSheetName, intendedWidth: number, intendedHeight: number) {
			const spriteSheet = playerPortraitsJson

			const spriteData = spriteSheet.frames[spriteSheetName]
			if (spriteData) {
				const frame = spriteData.frame

				const rotated = spriteData.rotated
				const originX = spriteData.pivot.x * 100
				const originY = spriteData.pivot.y * 100

				const width = rotated ? frame.h : frame.w
				const height = rotated ? frame.w : frame.h
				const xOffset = frame.x
				const yOffset = frame.y

				const finalScale = Math.min(intendedWidth / frame.w, intendedHeight / frame.h, 0.9)
				// console.log(finalScale)
				return {
					'background-position': `-${xOffset}px -${yOffset}px`,
					width: `${width}px`,
					height: `${height}px`,
					'transform-origin': `${originX}% ${originY}%`,
					transform: `scale(${finalScale}) rotate(${rotated ? -90 : 0}deg)`,
				}
			} else {
				console.error('Could not find spritesheet data for subtype: ' + spriteSheetName)
				return {}
			}
		}
	}
}
