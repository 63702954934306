import { debugAddClientToggle } from "../../utils/debug"
import { debugConfig } from "./debug-config"

export function debugConfigMike() {
	debugConfig.debug = true
	// debugConfig.enableDebugVisualLayer = true
	debugConfig.cspConfig.logProjectileErrors = true
	//debugConfig.cspConfig.throwIfCheating = true
	//debugConfig.projectiles.playerProjectileCount = 0

	//debugConfig.cheating.extraProjectiles = 2

	debugAddClientToggle(debugConfig, 'enableDebugVisualLayer', 'numpad1')
	debugAddClientToggle(debugConfig, 'csp', 'numpad2')
	debugAddClientToggle(debugConfig.graph, 'draw', 'numpad3')
	debugAddClientToggle(debugConfig, 'drawInput', 'numpad4')
	debugAddClientToggle(debugConfig, 'cspConfig.disableServerAdd', 'numpad5')
	debugAddClientToggle(debugConfig, 'cspConfig.disableClientAdd', 'numpad6')
	debugAddClientToggle(debugConfig, 'cspConfig.enableCheatDetection', 'numpad7')
}
