import nengi from 'nengi'

export default class FileChangedMessage {
	static protocol = {
		id: { type: nengi.String },
		jsonContents: { type: nengi.String },
	}
	id: string
	jsonContents: string

	constructor(id: string, json: string) {
		this.id = id
		this.jsonContents = json
	}
}
