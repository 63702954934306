import nengi from 'nengi'
import { uuid } from '../../utils/primitive-types'
import { ContainerType } from './send-item-container-message'

class SendItemToStashCommand {
	static protocol = {
		itemId: nengi.String,
		fromContainer: nengi.String,
	}

	itemId: uuid
	fromContainer: ContainerType

	constructor(itemId: uuid, fromContainer: ContainerType) {
		this.itemId = itemId
		this.fromContainer = fromContainer
	}
}

export default SendItemToStashCommand
