




















import SkinIventoryItem from '../inventory-item/skin-grid-item.vue'
import EmptySkinGridItem from '../inventory-item/empty-skin-grid-item.vue'

export default {
	name: 'SkinGridItemContainer',
	components: {
		SkinIventoryItem,
		EmptySkinGridItem,
	},
	props: {
		items: {
			type: Array,
			required: true,
			default: () => {
				console.warn('Currently using the default array. Ensure the <SkinsGridItemContainer /> component has a custom attribute items')
				return []
			},
		},
		showSlot: {
			type: Boolean,
			required: false,
			default: true,
		},
		maxSlots: {
			type: Number,
			required: true,
			default: () => {
				console.warn('Currently using the default max skin item slots. Ensure the <SkinGridItemContainer /> component has a custom attribute :max-slots')
				return 20
			},
		},
		selectable: {
			type: Boolean,
			required: false,
			default: true,
		},
		onSelectFn: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default on-select handler for the <SkinGridItemContainer /> component has been called. Did you forget to pass an :on-click handler to the component?')
			},
		},
		selectedFn: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default selected fn for the <SkinGridItemContainer /> component has been called. Did you forget to pass an :selected-fn handler to the component?')
			},
		},
		tooltipable: {
			type: Boolean,
			required: false,
			default: true,
		},
		showTooltipFn: {
			type: Function,
			required: false,
			default: () => {
				return false
			},
		},
		overrideSkinHeight: {
			type: Number,
			required: false,
			default: 0
		},
		overrideSkinWidth: {
			type: Number,
			required: false,
			default: 0
		},
	},
	data() {
		return {}
	},
	methods: {},
}
