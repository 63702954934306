import logger from '../../utils/client-logger'
import { getTop50UsersByMetricRequest } from '../../utils/api/atlas-requests.client'
import moment from 'moment'

export const leaderboardUIState = () => {
	logger.debug('Initializing leaderboard module')
	return {
		namespaced: true,
		state: {
			top50byUserMetrics: [],
			timeRange: 'week',
			metric: 'enemies-killed',
		},
		getters: {
			top50RankingByMetric(state) {
				return state.top50byUserMetrics
			},
			getTimeRange(state) {
				return state.timeRange
			},
			getMetric(state) {
				return state.metric
			},
		},
		mutations: {
			mutateTop50RankingByMetric(state, payload) {
				state.top50byUserMetrics = payload
			},
			updateTimeRange(state, range) {
				state.timeRange = range
			},
			updateMetric(state, metric) {
				state.metric = metric
			},
		},
		// Created additional rankings
		actions: {
			async getTop50RankingByMetric({ commit, state }) {
				const metric = state.metric
				let timeRange = ''

				if (state.timeRange === 'week') {
					timeRange = getCurrentWeekString()
				} else if (state.timeRange === 'season') {
					timeRange = 'season-1'
				} else {
					timeRange = 'all-time'
				}

				try {
					const results = await getTop50UsersByMetricRequest(metric, timeRange)
					commit('mutateTop50RankingByMetric', results)
				} catch (e) {
					console.log('Some sort of error occurred')
				}
			},
		},
	}
}

// WARNING: this is same procedure as is in ./Atlas/database/queries/soti-ts.ts
export function getCurrentWeekString() {
	const date = moment().utc()
	return `week-${date.year()}-${getCurrentWeekNumber()}`
}

// WARNING: this is same code as is in ./Atlas/utils/dates.js
//  keep them the same or rethink this
function getCurrentWeekNumber() {
	let d: any = new Date()
	d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()))
	d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7))
	const yearStart: any = new Date(Date.UTC(d.getUTCFullYear(), 0, 1))
	const weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7)
	return weekNo
}
