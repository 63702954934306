import { EnemyAI, FightingTriggers, IdleBehaviours, FightingBehaviours, LeashTriggers, LeashingBehaviours, AttackTypes, DeadBehaviours, ShotLeadPrecision, EnemyType, EnemyTargetingStyles } from '../../shared/ai-types'
import { ModType, ModValueType } from '../../../projectiles/shared/projectile-types'
import { SpineDataName, EnemyBipedSkinType } from '../../../models-animations/shared/spine-config'
import { degToRad } from '../../../utils/math'
import { EnemyDefaults } from './enemy-defaults'
import { Colors } from '../../../utils/colors'
import { ParticleEffectType } from '../../../engine/shared/game-data/particle-config'
import { Vector } from 'sat'
import { ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { basicEnemyDamageConfig, ENEMY_DEFAULT_HEALTH } from '../../../engine/shared/game-data/enemy-formulas'
import { BuffIdentifier } from '../../../buffs/shared/buff.shared'
import { ENEMY_NAMES } from './enemy-names'

const blimpie: EnemyAI = {
	name: ENEMY_NAMES.BLIMPIE,
	type: EnemyType.BASIC,
	appearance: {
		asset: SpineDataName.BLIMPIE,
		skin: EnemyBipedSkinType.DEFAULT,
		flying: true,
		mixSettings: [],
	},
	general: {
		enemyLevel: 1,
		experienceWhenKilled: 1,
	},
	baseAttributes: {
		...EnemyDefaults.baseAttributes,
		visibilityRadius: 1,
		colliders: [
			{
				type: ColliderType.Circle,
				radius: 75,
				traits: ColliderTraits.BlockProjectile,
				position: [0, -150],
			},
			{
				type: ColliderType.Circle,
				radius: 40,
				traits: ColliderTraits.BlockMovement,
				position: [0, 0],
			},
		],
		movementSpeed: 200,
		decelerationRate: 1.0,
		turningRatePerSecondInDegrees: 60,
		attackOffset: new Vector(60, -40),
		lootDropOffset: new Vector(0, 0),
		damageConfig: basicEnemyDamageConfig,

		physicalDamage: 0,
		attackRate: 1000,
		critChance: 0.3,
		critDamage: 1.5,

		maxHealth: (ENEMY_DEFAULT_HEALTH * 4) / 5,
		defense: 0,
		fireResist: 0,
		poisonResist: 0,
		iceResist: 0,
		lightningResist: 0,

		lootDropProbability: 1,
		lootDropQuantity: 1,
		lootDropRarity: 1,
	},
	soundEffects: {
		attack: 'Projectile_Zap',
		impact: 'SFX_Impact_Enemy_Mush',
	},
	states: {
		idling: {
			behaviour: IdleBehaviours.MEANDER,
			targetingStyle: EnemyTargetingStyles.HIGH_HEALTH,
			targetingCheckSeconds: 2,
			transitionToFighting: {
				trigger: FightingTriggers.AGGRESSIVE_PROXIMITY,
				radius: 800,
			},
			meander: {
				radius: 500,
				speedMultiplier: 1,
				minRestTime: 0.5,
				maxRestTime: 10,
			},
			aggroOnHit: true,
		},
		fighting: {
			behaviour: FightingBehaviours.CHASE_AND_ATTACK,
			targetingStyle: EnemyTargetingStyles.HIGH_HEALTH,
			targetingCheckSeconds: 0,
			attackType: AttackTypes.PROJECTILE,
			engagementMaxDistance: 250,
			engagementMinDistance: 0,
			modelCenterOffset: 150,
			movementMaxDistance: 250,
			movementMinDistance: 0,
			attackOptions: [
				{
					...EnemyDefaults.projectileConfig,
					spreadAngle: degToRad(300),
					projectileCount: 6,
					pierceCount: 10,
					speed: 350,
					lifespanInSeconds: 0.8,
					colliderRadius: 15,
					modifiers: [{ modType: ModType.STRAIGHT }, { modType: ModType.ACCELERATION, value: { modValueType: ModValueType.RANDOM, min: -1.0, max: -0.4 } }],
					basePoisonDamage: 70,
					bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_BLIMPIE_GAS,
					bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_POISON_TRAIL,
					muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_POISON_SHOOT,
					color: Colors.yellow,
					burstCount: 1,
					applyBuffsOnHit: [BuffIdentifier.Poison],
				},
			],
			shotLeadPrecision: ShotLeadPrecision.AVERAGE,
			visualAimLockSeconds: 0.5,
			transitionToLeashing: {
				trigger: LeashTriggers.RADIUS_EXCEEDED,
				radius: 2000,
				distanceTravelled: 4000,
			},
		},
		leashing: {
			behaviour: LeashingBehaviours.MOVE,
			speedMultiplier: 4,
		},
		fleeing: {},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 1,
		},
	},
}

export default blimpie
