import { StampData } from './biome-data-structures'

export const defaultStamps: StampData[] = [
	{ name: 'grass_01', weight: 100, alwaysOnTop: false },
	{ name: 'grass_02', weight: 100, alwaysOnTop: false },
	{ name: 'sand_01', weight: 100, alwaysOnTop: false },
	{ name: 'sand_02', weight: 100, alwaysOnTop: false },
	{ name: 'sand_03', weight: 100, alwaysOnTop: false },
	{ name: 'sand_rare_01', weight: 10, alwaysOnTop: false },
]

export const townStamps: StampData[] = [
	{ name: 'grass_01', weight: 100, alwaysOnTop: false },
	{ name: 'grass_02', weight: 100, alwaysOnTop: false },
	{ name: 'sand_01', weight: 100, alwaysOnTop: false },
	{ name: 'sand_02', weight: 100, alwaysOnTop: true },
	{ name: 'sand_03', weight: 100, alwaysOnTop: true },
	{ name: 'sand_rare_01', weight: 10, alwaysOnTop: true },
]

export const highlandsStamps: StampData[] = [
	{ name: 'grass_01', weight: 100, alwaysOnTop: false },
	{ name: 'grass_02', weight: 100, alwaysOnTop: false },
	{ name: 'sand_01', weight: 100, alwaysOnTop: false },
	{ name: 'sand_02', weight: 100, alwaysOnTop: false },
	{ name: 'sand_03', weight: 100, alwaysOnTop: false },
	{ name: 'sand_rare_01', weight: 10, alwaysOnTop: true },
]
