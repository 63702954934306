<template>
	<MainPanel id="depart" :on-close-btn-click="closeActivePanel" panel-identifier="depart" :show-gold-currency="false" :show-scale-currency="false">
		<template #panel-content>
			<div v-show="getHasAdventureServer">
				<PanelDivider :divider-text="'Your party is still out there!'"></PanelDivider>
				<ArrowButton
					v-tooltip=""
					button-text="Return to Party"
					button-icon-name="boat"
					:on-click="
						() => {
							goToAdventure({ biomeIdx: 0, canProgress: (isMeterFull && isDifficultySufficientToProgress(currentlySelectedWorldDifficulty))})
						}
					"
					:disabled="false"
					:loading="false"
				></ArrowButton>
				<ArrowButton
					v-tooltip=""
					button-text="Leave Party"
					button-icon-name="party"
					:on-click="leaveParty"
					:disabled="false"
					:loading="false"
				></ArrowButton>
			</div>
			<div v-show="!getHasAdventureServer">
				<div class="world-tier-container">
					<PanelDivider :divider-text="'Choose a Difficulty'"></PanelDivider>
					<div class="grid-world-tiers">
						<div
							v-for="n in 4"
							:key="n"
							v-tooltip="{
								content: departureToWorldDifficultyTooltip(maxAllowedTier < n, n),
								placement: 'bottom-center',
								offset: 0,
								delay: {
									show: 500,
									hide: 300,
								},
							}"
							class="world-tier-icon"
							:class="{ selected: currentlySelectedWorldDifficulty === n, disabled: maxUnlockedDifficulty < n }"
							@click="selectNewWorldDifficulty(n)"
						>
							<div class="item-selected-indicator"></div>
							<div class="item-hovered-indicator"></div>
							<img :src="worldTierImages[(n - 1) * 3]" :alt="'world-tier-' + n" />
							<div v-show="maxUnlockedDifficulty >= n" class="progression-image">
								<img :src="progressionImageForDifficulty(n, maxAllowedTier)" />
							</div>
						</div>
					</div>
				</div>
				<!-- <div class="unlocks-container">
					<PanelDivider :divider-text="'Unlocks'"></PanelDivider>
					<div class="text-container">- Wow look, nothing. (TODO2)</div>
				</div> -->
				<div class="biome-key-container">
					<PanelDivider :divider-text="'Use a biome key'"></PanelDivider>
					<div class="biome-keys">
						<ArrowButton
							v-tooltip="{
								content: departureWithInvalidPressureTooltip(!isValidPressureLoadout, totalPointsForPreviousWorldTier) || departureWithPartyTooltip(0),
								placement: 'bottom-center',
								offset: 0,
								delay: {
									show: 500,
									hide: 300,
								},
							}"
							button-text="Isle Beach"
							:button-text-middle="worldTierHasRecommendedDPS(currentlySelectedWorldTier) ? `Recommended DPS: ${getRecommendedDPSforBiomeAndWorldTier(currentlySelectedWorldTier, 0)}` : ''"
							:gray-text-middle="true"
							button-icon-name="boat"
							:on-click="
								() => {
									goToAdventure({ biomeIdx: 0, canProgress: (isMeterFull && isDifficultySufficientToProgress(currentlySelectedWorldDifficulty))})
								}
							"
							:disabled="disableDepartButtonIfPartied(0) || ftueMode || !isValidPressureLoadout"
							:loading="false"
						></ArrowButton>
						<ArrowButton
							v-tooltip="{
								content: departureWithBiomeKeyTooltip(numberOfBiomeKeysInStashByBiomeIndex(1) === 0 || hasReachedBiomeOnTier(1, currentlySelectedWorldTier) === false) || departureWithInvalidPressureTooltip(!isValidPressureLoadout, totalPointsForPreviousWorldTier) || departureWithPartyTooltip(1),
								placement: 'bottom-center',
								offset: 0,
								delay: {
									show: 500,
									hide: 300,
								},
							}"
							button-text="Terila Forest"
							:button-text-middle="worldTierHasRecommendedDPS(currentlySelectedWorldTier) ? `Recommended DPS: ${getRecommendedDPSforBiomeAndWorldTier(currentlySelectedWorldTier, 1)}` : ''"
							:gray-text-middle="true"
							:button-text-two="`x${numberOfBiomeKeysInStashByBiomeIndex(1)}`"
							button-icon-name="key"
							:on-click="
								() => {
									goToAdventure({ biomeIdx: 1, canProgress: (isMeterFull && isDifficultySufficientToProgress(currentlySelectedWorldDifficulty))})
								}
							"
							:disabled="numberOfBiomeKeysInStashByBiomeIndex(1) === 0 || hasReachedBiomeOnTier(1, currentlySelectedWorldTier) === false || disableDepartButtonIfPartied(1) || ftueMode || !isValidPressureLoadout"
							:loading="false"
						></ArrowButton>
						<ArrowButton
							v-tooltip="{
								content: departureWithBiomeKeyTooltip(numberOfBiomeKeysInStashByBiomeIndex(2) === 0 || hasReachedBiomeOnTier(2, currentlySelectedWorldTier) === false) || departureWithInvalidPressureTooltip(!isValidPressureLoadout, totalPointsForPreviousWorldTier) || departureWithPartyTooltip(2),
								placement: 'bottom-center',
								offset: 0,
								delay: {
									show: 500,
									hide: 300,
								},
							}"
							button-text="Onyx Highlands"
							:button-text-middle="worldTierHasRecommendedDPS(currentlySelectedWorldTier) ? `Recommended DPS: ${getRecommendedDPSforBiomeAndWorldTier(currentlySelectedWorldTier, 2)}` : ''"
							:gray-text-middle="true"
							:button-text-two="`x${numberOfBiomeKeysInStashByBiomeIndex(2)}`"
							button-icon-name="key"
							:on-click="
								() => {
									goToAdventure({ biomeIdx: 2, canProgress: (isMeterFull && isDifficultySufficientToProgress(currentlySelectedWorldDifficulty))})
								}
							"
							:disabled="numberOfBiomeKeysInStashByBiomeIndex(2) === 0 || hasReachedBiomeOnTier(2, currentlySelectedWorldTier) === false || disableDepartButtonIfPartied(2) || ftueMode || !isValidPressureLoadout"
							:loading="false"
						></ArrowButton>
						<ArrowButton
							v-tooltip="{
								content: departureWithBiomeKeyTooltip(numberOfBiomeKeysInStashByBiomeIndex(3) === 0 || hasReachedBiomeOnTier(3, currentlySelectedWorldTier) === false) || departureWithInvalidPressureTooltip(!isValidPressureLoadout, totalPointsForPreviousWorldTier) || departureWithPartyTooltip(3),
								placement: 'bottom-center',
								offset: 0,
								delay: {
									show: 500,
									hide: 300,
								},
							}"
							button-text="Fungi Steppes"
							:button-text-middle="worldTierHasRecommendedDPS(currentlySelectedWorldTier) ? `Recommended DPS: ${getRecommendedDPSforBiomeAndWorldTier(currentlySelectedWorldTier, 3)}` : ''"
							:gray-text-middle="true"
							:button-text-two="`x${numberOfBiomeKeysInStashByBiomeIndex(3)}`"
							button-icon-name="key"
							:on-click="
								() => {
									goToAdventure({ biomeIdx: 3, canProgress: (isMeterFull && isDifficultySufficientToProgress(currentlySelectedWorldDifficulty))})
								}
							"
							:disabled="numberOfBiomeKeysInStashByBiomeIndex(3) === 0 || hasReachedBiomeOnTier(3, currentlySelectedWorldTier) === false || disableDepartButtonIfPartied(3) || ftueMode || !isValidPressureLoadout"
							:loading="false"
						></ArrowButton>
						<ArrowButton
							v-tooltip="{
								content: departureWithBiomeKeyTooltip(numberOfBiomeKeysInStashByBiomeIndex(4) === 0 || hasReachedBiomeOnTier(4, currentlySelectedWorldTier) === false) || departureWithInvalidPressureTooltip(!isValidPressureLoadout, totalPointsForPreviousWorldTier) || departureWithPartyTooltip(4),
								placement: 'bottom-center',
								offset: 0,
								delay: {
									show: 500,
									hide: 300,
								},
							}"
							button-text="Prism Sunset Cliffs"
							:button-text-middle="worldTierHasRecommendedDPS(currentlySelectedWorldTier) ? `Recommended DPS: ${getRecommendedDPSforBiomeAndWorldTier(currentlySelectedWorldTier, 4)}` : ''"
							:gray-text-middle="true"
							:button-text-two="`x${numberOfBiomeKeysInStashByBiomeIndex(4)}`"
							button-icon-name="key"
							:on-click="
								() => {
									goToAdventure({ biomeIdx: 4, canProgress: (isMeterFull && isDifficultySufficientToProgress(currentlySelectedWorldDifficulty))})
								}
							"
							:disabled="numberOfBiomeKeysInStashByBiomeIndex(4) === 0 || hasReachedBiomeOnTier(4, currentlySelectedWorldTier) === false || disableDepartButtonIfPartied(4) || ftueMode || !isValidPressureLoadout"
							:loading="false"
						></ArrowButton>
					</div>
				</div>
				<div class="progression-message">
					<div v-if="maxAllowedTier >= maxTier">
						<span style="color: #1ebff0">You're facing the toughest challenges available, but</span>
						<span style="color: #febb01">more are coming!</span>
						<span style="color: #1ebff0">Be prepared!</span>
					</div>
					<div v-else-if="maxAllowedTier <= 1 || (isMeterFull && isDifficultySufficientToProgress(currentlySelectedWorldDifficulty))">
						<span style="color: #a6ff68">You are ready to embark and advance.<br>Good Luck!</span>
					</div>
					<div v-else-if="!isMeterFull && isDifficultySufficientToProgress(currentlySelectedWorldDifficulty)">
						<span style="color: #1ebff0">You can advance to Soul Cycle {{ maxAllowedTier }} by</span>
						<span style="color: #febb01">filling the meter</span>
					</div>
					<div v-else-if="isMeterFull && !isDifficultySufficientToProgress(currentlySelectedWorldDifficulty) && !isDifficultySufficientToProgress(maxUnlockedDifficulty - 1)">
						<span style="color: #1ebff0">You can advance to Soul Cycle {{ maxAllowedTier }} by embarking on difficulty</span>
						<span style="color: #febb01">{{ getDifficultyPrettyString(maxUnlockedDifficulty) }}</span>
					</div>
					<div v-else-if="isMeterFull && !isDifficultySufficientToProgress(currentlySelectedWorldDifficulty) && isDifficultySufficientToProgress(maxUnlockedDifficulty - 1)">
						<span style="color: #1ebff0">You can advance to Soul Cycle {{ maxAllowedTier }} by embarking on difficulty</span>
						<span style="color: #febb01">{{ getDifficultyPrettyString(maxUnlockedDifficulty - 1) }}</span>
						<span style="color: #1ebff0">or</span>
						<span style="color: #febb01">{{ getDifficultyPrettyString(maxUnlockedDifficulty) }}</span>
					</div>
					<div v-else-if="!isMeterFull && !isDifficultySufficientToProgress(currentlySelectedWorldDifficulty) && !isDifficultySufficientToProgress(maxUnlockedDifficulty - 1)">
						<span style="color: #1ebff0">You can advance to Soul Cycle {{ maxAllowedTier }} by</span>
						<span style="color: #febb01">filling the meter</span>
						<span style="color: #1ebff0">and embarking on difficulty</span>
						<span style="color: #febb01">{{ getDifficultyPrettyString(maxUnlockedDifficulty) }}</span>
					</div>
					<div v-else-if="!isMeterFull && !isDifficultySufficientToProgress(currentlySelectedWorldDifficulty) && isDifficultySufficientToProgress(maxUnlockedDifficulty - 1)">
						<span style="color: #1ebff0">You can advance to Soul Cycle {{ maxAllowedTier }} by</span>
						<span style="color: #febb01">filling the meter</span>
						<span style="color: #1ebff0">and embarking on difficulty</span>
						<span style="color: #febb01">{{ getDifficultyPrettyString(maxUnlockedDifficulty - 1) }}</span>
						<span style="color: #1ebff0">or</span>
						<span style="color: #febb01">{{ getDifficultyPrettyString(maxUnlockedDifficulty) }}</span>
					</div>
				</div>
			</div>
		</template>
	</MainPanel>
</template>

<script>
import ArrowButton from '../reusable-components/buttons/arrow-button.vue'
import PanelDivider from '../reusable-components/panel-divider/panel-divider.vue'
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import worldTier1Image from './assets/world-tier-1.png'
import worldTier2Image from './assets/world-tier-2.png'
import worldTier3Image from './assets/world-tier-3.png'
import worldTier4Image from './assets/world-tier-4.png'
import worldTier5Image from './assets/world-tier-5.png'
import worldTier6Image from './assets/world-tier-6.png'
import worldTier7Image from './assets/world-tier-7.png'
import worldTier8Image from './assets/world-tier-8.png'
import worldTier9Image from './assets/world-tier-9.png'
import worldTier10Image from './assets/world-tier-10.png'
import progressionImage from './assets/difficulty-progression.png'
import noProgressionImage from './assets/difficulty-no-progression.png'
import { departureToWorldDifficultyTooltip, departureWithBiomeKeyTooltip, departureWithPartyTooltip, departureWithInvalidPressureTooltip } from '../reusable-components/v-tooltip/v-tooltip-functions'
import { MAX_ENABLED_DIFFICULTY, getWorldDifficultyPrettyString } from '../../engine/shared/game-data/world-difficulty'
import { PROGRESSION_MAX_TIER_FOR_BETA, maxAllowedWorldDifficultyFromMaxAllowedTier, isOnSufficientDifficultyToProgress } from '../../engine/shared/game-data/progression'

export default {
	name: 'Departure',
	components: {
		MainPanel,
		PanelDivider,
		ArrowButton,
	},
	data() {
		return {
			worldTierImages: [worldTier1Image, worldTier2Image, worldTier3Image, worldTier4Image, worldTier5Image, worldTier6Image, worldTier7Image, worldTier8Image, worldTier9Image, worldTier10Image],
			dpsTable: {
				1: [20, 35, 45, 55, 65],
				2: [100, 120, 160, 250, 500],
				3: [600, 950, 1275, 1500, 2200],
			},
		}
	},
	computed: {
		maxUnlockedDifficulty() {
			return Math.min(MAX_ENABLED_DIFFICULTY, maxAllowedWorldDifficultyFromMaxAllowedTier(this.maxAllowedTier))
		},
		...mapState('boatLaunch', ['ftueMode']),
		...mapGetters('boatLaunch', ['selectedBiome', 'biomeIndex', 'currentlySelectedWorldDifficulty', 'currentlySelectedWorldTier', 'hasReachedBiomeOnTier', 'maxAllowedTier', 'totalPointsSelected', 'totalPointsForNextWorldTier', 'totalPointsForPreviousWorldTier', 'isValidPressureLoadout']),
		...mapGetters('itemContainers', ['hasBiomeKey', 'containerIsEmpty', 'itemsInContainer', 'numberOfBiomeKeysInStashByBiomeIndex']),
		...mapGetters('inGame', ['activePanel', 'flyoutPanel']),
		...mapGetters('party', ['getPartyMembers', 'getCurrentlyPartiedStatus', 'isPartyLeader', 'getAllPartyMembersReady', 'getHasAdventureServer']),
		...mapGetters('user', ['userId']),
		isMeterFull() {
			return this.totalPointsSelected >= this.totalPointsForNextWorldTier
		},
		maxTier() {
			return PROGRESSION_MAX_TIER_FOR_BETA
		}
	},
	methods: {
		...mapActions('inGame', ['closeActivePanel', 'openFlyoutPanel', 'closeFlyoutPanel']),
		...mapActions('party', ['leaveParty']),
		...mapMutations('boatLaunch', ['selectNextBiome', 'selectPreviousBiome', 'goToAdventure', 'selectNewWorldDifficulty']),
		departureWithBiomeKeyTooltip,
		departureToWorldDifficultyTooltip,
		departureWithPartyTooltip,
		departureWithInvalidPressureTooltip,
		getRecommendedDPSforBiomeAndWorldTier(currentlySelectedWorldTier, biomeIndex) {
			return this.dpsTable[currentlySelectedWorldTier][biomeIndex] || 0
		},
		worldTierHasRecommendedDPS(currentlySelectedWorldTier) {
			if (this.dpsTable[currentlySelectedWorldTier] === undefined) {
				return false
			}
			return this.dpsTable[currentlySelectedWorldTier][0] !== undefined
		},
		disableDepartButtonIfPartied(biomeButton) {
			const isUserLeader = this.getPartyMembers.find((user) => user.playerId === this.userId && user.leader === true)
			if (this.getCurrentlyPartiedStatus) {
				if (!isUserLeader) {
					return true
				}
				if (isUserLeader && biomeButton === 0) {
					if (this.getAllPartyMembersReady) {
						return false
					} else {
						return true
					}
				} else {
					return true
				}
			}
			return false
		},
		activateFlyoutPanel() {
			if (!this.flyoutPanel) {
				this.openFlyoutPanel('depart')
			} else {
				this.closeFlyoutPanel()
			}
		},
		progressionImageForDifficulty(difficulty, currentlySelectedWorldTier) {
			if (this.maxAllowedTier >= PROGRESSION_MAX_TIER_FOR_BETA) {
				return noProgressionImage
			}

			if (isOnSufficientDifficultyToProgress(difficulty, currentlySelectedWorldTier)) {
				return progressionImage
			}

			return noProgressionImage
		},
		isDifficultySufficientToProgress(difficulty) {
			return isOnSufficientDifficultyToProgress(difficulty, this.maxAllowedTier)
		},
		getDifficultyPrettyString(difficulty) {
			return getWorldDifficultyPrettyString(difficulty)
		}
	},
}
</script>

<style lang="less" scoped>
@import url('../global/bg-gradients.less');
@import '../global/fonts/text.less';

#depart {
	.world-tier-container {
		.bg-gradient();
		.grid-world-tiers {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			width: 606px;
			padding-bottom: 10px;
			.world-tier-icon {
				height: 104px;
				width: 109px;
				display: inline-block;
				position: relative;
				margin: 3px;
				background-image: url('../reusable-components/inventory-item/assets/item-slot-a.png');
				.item-selected-indicator {
					animation: shrink-grow 1s ease-in-out infinite alternate;
				}
				&:hover {
					&:not(.selected, .disabled) {
						.item-hovered-indicator {
							display: block;
						}
					}
				}
				.item-selected-indicator,
				.item-hovered-indicator {
					display: none;
					position: absolute;
					width: 113px;
					height: 108px;
					background-repeat: no-repeat;
				}
				.item-selected-indicator {
					background-image: url('../reusable-components/inventory-item/assets/highlight-selected.png');
					top: -3px;
					left: -2px;
				}
				.item-hovered-indicator:not(&.disabled) {
					background-image: url('../reusable-components/inventory-item/assets/highlight-hover.png');
					left: 0px;
					top: 0px;
				}
				.progression-image {
					position: absolute;
					left: 75px;
					top: 68px;

					img {
						width: 30px;
						vertical-align: middle;
					}
				}
				&.selected {
					.item-selected-indicator {
						display: block;
					}
				}
				&.disabled {
					opacity: 0.5;
					img {
						filter: grayscale(100%);
					}
				}
				img {
					position: relative;
					top: -4px;
					left: -4px;
				}
			}
		}
	}
}
.unlocks-container {
	height: 250px;
	width: 100%;
	margin-top: 10px;
	.bg-gradient();

	.text-container {
		padding-left: 20px;
		.unlocks-bullets();
	}
}
.biome-key-container {
	height: 510px;
	width: 606px;
	margin: 12px 0px 10px 0px;
	padding: 5px 0px 5px 0px;
	.biome-keys {
		height: 460px;
		overflow-y: scroll;
		margin-top: 5px;
	}
	.bg-gradient();
}
.progression-message {
	padding-top: 20px;
	max-width: 606px;
	text-align: center;
	color: white;
	font-size: 26px;
	font-weight: 600;
	font-family: 'Do Hyeon';
}
</style>
