import DevToolsManager from '../../ui/dev-tools/dev-tools-manager'
import { debugConfigMike } from './debug-config.mike'

//TODO2: move this to /shared, or break out into separate files for server and client settings

export enum NengiLogType {
	None,
	LightMessagesOnly,
	MessagesOnly,
	All,
}

export enum DebugLogLevel {
	Off,
	Light,
	Verbose,
}

class DebugConfig {
	/** some of these options below require this, like enableDebugVisualLayer, so might as well enable it if setting any of these */
	debug = false
	/** draws colliders for props and projectiles */
	drawColliders = false
	/** scales client and server dt to speed up or slow down the game, 0 to pause-ish */
	timeScale: number = 1
	/** slow the game loop down to 5hz */
	enableSlowGameLoop = false
	/** enables some hacks to get you in game without a connection */
	offline = false
	/** makes the server update loop run even when no players have joined */
	disableNoPlayersOpt = false
	/** disable cliffs from spawning on the map */
	disableCliffPlacement = false
	/** disable 'can enter biome' checks. Useful for random spawning of bots */
	disableMists = false
	/** disable safe areas from spawning on the map */
	disableSafeAreas = false
	/** disable whiny messages when using lots of debugging draw* calls */
	disableExpensiveDrawCallWarnings = false
	/** disables throw an assert if certain properties ever get NaN or undefined */
	disableThrowIfNotFinite = false
	/** Zooms the camera out, and increases the network view and terrain network view size. Useful for visualizing large segments of the world. NOT FOR NON-LOCAL ENVIRONMENTS. */
	enableBigBiomeView = false
	bigBiomeViewMultiplier = 3
	/** enables grids overlay, enemy AI circle indicators, and much more */
	enableDebugVisualLayer = false
	/** enable invisible to AI mode for all players */
	invisibleToAIByDefault = false
	/** enable god mode for all players */
	enableGodMode = false
	/** enable "if a tree falls in the forest" update method for enemies -- broadphase only right now */
	enablePlayerGridCounts = false
	/** creates a WebSocketServer to listen for file changes, only pfx-editor uses this right now */
	createFileChangeServer = true
	/** Whether or not to draw a grid */
	drawGrid = false
	/** Draws a controller of movement input on screen */
	drawInput = false
	/** The client can ask the server to send an entity */
	enableEntityLookup = true
	/** The client can ask the server to send an entity */
	enableMassEntityHistory = false
	/** There is a crash in updating entities that don't yet seem to exist. We have a band-aid fix, which this removes */
	disableUpdateEntityCrashFix = false
	/** enables right clicking to select the closest server enemy and inspects them */
	enableRightClickToInspectEnemy = false
	

	/** player movement and enemy projectiles csp */
	csp = false
	cspConfig = {
		/** disables the server adding projectiles/beams for players, so they can be created via client */
		disableServerAdd: true,
		/** disables the client adding local projectiles/beams for players */
		disableClientAdd: false,
		/** disables the client requesting the server to create a projectile */
		disableClientAddMessage: false,
		/** draws all server projectiles, even copies of local ones */
		drawServer: false,
		/** does client update their aim? */
		clientSideAim: true,
		/** modifier to client side projectiles speed (testing) */
		clientSpeedAdjust: 1,
		/** If your ping is 0, there is still a 150ms delay between a mouse button press and
		 * a projectile being released. Some of this is explainable by frame time (server dt is 50ms)
		 * and the time of receiving commands and when those commands are processed.  
		 * I've not yet accounted for the full 150ms though. 
		 */
		serverCatchupAddMs: 150,
		/** prints out if our prediction changes any members on the real projectile (this is an error, prediction should not change projectile) */
		logProjectileErrors: false,
		/** DEBUG ONLY: crashes server if anybody attempts to cheat, for debugging, not for prod */
		throwIfCheating: false,
		/** turns on projectile creation cheating detection */
		enableCheatDetection: true,
	}

	/** reloads the page when you are disconnected */
	reloadOnDisconnect = false

	/** Whether to use a trailing nengi view or whether to always snap the view 1:1 */
	useTrailingNengiView = false

	/** Whether or not to show the FPS/Memory meter in the top left (stats.js) */
	showStatsMeter = false

	/** Whether to visualize mark and sweep events */
	showGCEvents = true

	/** Shows a message after unlocking a cosmetic, disable when adding a lot to your account */
	showUnlockedMessage = true

	analytics = {
		logging: DebugLogLevel.Light,
	}

	assets = {
		/** enables extra logging on client to diagnose loading errors */
		logging: true,
		logEachAsset: false,
	}

	audio = {
		/** disables... audio */
		disableAudio: false,
	}

	bgRender = {
		/** enables rendering aids and other debugging info in the background renderer */
		debug: false,
		/** enables verbose logging for background renderer */
		logging: false,
	}

	biome = {
		/** disable all natural enemy spawning */
		disableEnemySpawning: false,
	}

	boss = {
		debug: false,
		/** turns only debug only for the boss containing this string in it's name */
		debugBossName: '',
		/** draws lines and distances between all target colliders - noisy as heck */
		drawColliderPaths: false,
		/** enables prints on different logging levels - 0=off, 1=normal 2=verbose */
		logLevel: 0,
		/** enabled this bosses actions even when no players are around */
		disableNoPlayersOpt: false,
	}

	camera = {
		/** shows debug information as well as binds some keys to trigger trauma */
		debug: false,
		/** allows scroll wheel to zoom viewport in/out */
		allowScrollWheelZoom: false,
		/** makes the camera use zoom levels to fake a different resolution */
		enableFakeResolution: false,
		fakeResolutionWidth: 3840,
		fakeResolutionHeight: 2160,
		fakeResolutionZoomLevel: 0.4,
		/** disables camera shake and recoil */
		disableShake: false,
	}

	/** Sends these chat messages when you spawn in */
	chatMessagesOnSpawn = [
		// '/devmode'
	]

	cheating = {
		/** DEBUG ONLY: (default:0) reduces client-side cooldown for testing cheating */
		cooldownReduction: 0,
		/** DEBUG ONLY: (default:0) adds projectiles on client for testing cheating */
		extraProjectiles: 0,
		/** DEBUG ONLY: (default:0) adds a position offset to players shots to attempt spawning away from player */
		shotDistOffset: 0,
		/** DEBUG ONLY: (default:1) multiply timestamps on PlayerInput commands to speed your player up */
		speedHack: 1
	}

	damage = {
		/** Show damage taken in chat */
		showInChat: false,
		/** Minimum amount needed to show, if above is true */
		showInChatMinimumAmount: 0.0,
		/** Causes your critChance+critDamage to be factored as an averaged multiplier to damage, instead of a random chance (for balance testing) */
		noRandomizedCriticalStrikes: false,
		/** Will completely remove critDamage from damage equations */
		noCriticalDamage: false,
	}

	collisions = {
		sampling: false,
	}

	enemy = {
		/** Whether to disable enemies entirely */
		absolutelyUnderNoCircumstanceSpawnEnemies: false,
		/** Spawns a training dummy near the beach spawn point */
		spawnTrainingDummy: false,
		/** Spawn a collection of debugging enemies near the beach spawn point */
		spawnDebugEnemies: false,
		/** Spawns every tenemy type near the beach spawn point, REQUIRES:spawnDebugEnemies */
		spawnDebugAllEnemies: false,
		alwaysShowHealthBars: false,
		/** shows enemy level and champion/boss text */
		showHealthAndLevelText: false,
		alwaysShowChampionVisuals: false,

		/** Shows some information about enemy state on screen */
		enableBrainDebugger: false,
		debuggingEnemy: null, //hacky global variable for brain debugger
	}

	graph = {
		/** WIP draws an onscreen graph of logged data */
		draw: false,
		movement: false,
		projectiles: false,
		gc: false
	}

	items = {
		/** every enemy spawns lots of loot */
		enableLootFountains: false,
		/** all item identifications are free */
		freeIdentification: false,
		/** all augment equips are free */
		freeAugmentation: false,
		/** all shop items are free */
		freeGeneralStore: false,
		overrideWeaponSubtypeOnDrops: null, // WeaponSubType.Wand
		fakeBiomeKeys: false,
		/** adds extra checkmark to item roller to send items to marketplace */
		allowBulkMarketplaceListing: false,
	}

	log = {
		/** logs all nengi entity creates */
		clientEntityCreates: false,
		/** logs all nengi entity updates */
		clientEntityUpdates: false,
		/** logs all nengi entity deletes */
		clientEntityDeletes: false,
		/** logs all (non-spammy) nengi messages to console */
		nengiMessages: NengiLogType.None,
		damage: false,
		outOfBounds: true,
		colliderIssues: true,
		chat: false,
	}

	metrics = {
		trackPotentiallyExpensiveDistanceTravelled: false,
		enableLogging: false,
	}

	pfx = {
		/** logs the pfx name every time they're created */
		logNames: false,
		/** draws the pfx name on the screen with debug text */
		drawNames: false,
		/** overrides the elemental pfx visuals to be this element */
		overrideElementalDamage: undefined,
		/** batches enemy projectiles into 2: heads and trails, to prevent thrashing of blend modes */
		batchEnemyProjectiles: true,
	}

	pois = {
		debug: false,
		logLevel: 0,
		disableUpdate: false,
		disableRangeParticipationCheck: false,
		//TODO1: Set showAdvancedParticipationDetails to FALSE, it's only on for QA now
		/** When the POI participation report displays in chat, should this show ALL players with advanced details, or just the player-facing standard report? */
		showAdvancedParticipationDetails: false,
	}

	pooling = {
		/** enables pool debugging reports/printing, also double-free checking */
		debug: false,
		/** disables spine sprite pools */
		disableSpinePools: false,
		/** checks certain allocated objects against canonical/pure reference objects */
		checkSomeCanonicals: false,
		/** disables EntityStatList pools */
		disableEntityStatListPools: true,
	}

	projectiles = {
		drawReleasePoint: false,
		/** Sets a players count of projectiles in their projectile config to this number.  
		 * Still capped at 9 though.  
		 * **disabled: 0**  
		 */
		playerProjectileCount: 0,
		/** Sets a players projectile config lifetime to this number  
		 *  **disabled: 0** */
		overridePlayerProjectileLifespanInSeconds: 0
	}

	props = {
		logging: false,
		disable: false,
		/** lays out all props in `debugPlacementBiomeIdx` in a grid */
		debugPlacement: false,
		/** used with debugPlacement to choose which biomes props to debug place */
		debugPlacementBiomeIdx: 0,
		/** used with debugPlacement to ignore massive props (like arenas) */
		debugPlacementMaxVisibility: 1000,
		/** force all props to use spine instead of instanced sprites */
		forcePropRig: false,
		debugUpdate: false,
		/** USE WITH debugPlacement ONLY!!!
		 * This increases visibility so *every* prop is create on client (chrome go boom)
		 * It then prints out the calculated visibilty radius, which you can dutifully copy over to the propConfig
		 *  like a good little code-monkey
		 */
		calculateAndPrintVisibility: false,
		/** Render the visibility radius of each prop */
		debugRenderVisibilityRadius: false,
		/** creates movable/scalable collider shapes to help size colliders */
		tweaker: false,
		/** updates the vis rad of all large props (props with vis rad >= 1000) */
		overrideLargeVisibilityRadius: -1,
	}

	render = {
		debugVisuals: false,
		aiDebug: false,
		animDebug: false,
		spineDebug: false,
		disableBackground: false,
		disableMiddleground: false,
		disableForeground: false,
		disableWater: false,
		/** Disables screen filters for debuffs, low health warning, etc. */
		disableScreenFilters: false,
		logShaderCreates: DebugLogLevel.Light,
	}

	UI = {
		hideUI: false,
		showMinimap: false,
		/** Shows the DevTools by default. */
		showDevTools: false,
	}

	factions = {
		/** disable checking (server) if you're a valid faction member for weekly faction prizes */
		noServerCheckFactionPrize: false,
		/** What faction won last week (for prizes ONLY -- if you want augments/props use /set-last-winning-faction) */
		forcedFactionWinner: 'aurum',
		/** Did you """win""" with the faction this week? */
		forcedFactionWin: false,
		/** How much rep did you gain in your forced faction win? */
		forcedFactionRep: 0,
		/** What top % of player were you for the forced faction win? */
		forcedFactionTopPercent: 100,
	}
}

export const debugConfig: DebugConfig = new DebugConfig()

if (process.env.NODE_ENV === 'local' && process.env.USERNAME === 'michaels') {
	debugConfigMike()
}

DevToolsManager.getInstance().addModuleExports(module)
