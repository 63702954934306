



















import Vue from 'vue'
import RecursiveObjectTree from '../../ui/reusable-components/recursive-object-tree/recursive-object-tree.vue'
import DevToolsManager, { devToolsSettings } from './dev-tools-manager'
import { mapGetters, mapState } from 'vuex'

export default {
	name: 'DevTools',
	components: {
		RecursiveObjectTree,
	},
	data() {
		return {
			renderComponent: true,
			settings: devToolsSettings,
			watchObject: {
				welcome: `Welcome to Dev Tools! (F9). Use [DevToolsManager] to begin watching objects.`,
				toToggle: 'Press F9 to show/hide',

				// Below is a dummy object to test all the functionality of this property editor
				strProp: 'strPropOut',
				numProp: 15,
				floatProp: 3.14,
				booleanProp: true,
				subObject: {
					subObjStrProp: 'subObjStrProp',
					anotherNestedObj: {
						nesty: true,
						testy: 'nope',
					},
				},
				strArray: ['strArray1', 'array2', 'array3'],
				numArray: [1, 3, 4, 5, 6],
				floatArray: [0.5, 0.1, 0.2, 0.4, 0.6],
				mixedArray: [1, 'two', 3.01, 'four', 5, true],
				dummyItem: {
					id: 'guidly-looking-thing',
					level: 3,
					slotIndex: 0,
					itemType: 'Gear',
					itemSubType: 'Trinket',
					name: 'Yeet',
					rarity: 'yes, rare',
					biome: null,
					biomeTier: null,
					augmentLimit: 2,
					equippedAugmentIds: [],
					equippedEnchantmentIds: [],
				},
			},
		}
	},
	mounted() {
		const debugObject = DevToolsManager.getInstance().debugObject
		//logger.debug(`showing dev-tools.vue debugObject:`, debugObject)
		if (this.settings.nonreactive) {
			// @ts-expect-error I assume this is a plugin without type annotations
			Vue.nonreactive(debugObject)
		}
		this.watchObject = debugObject

		DevToolsManager.getInstance().eventEmitter.on('WATCH_OBJECT', (data) => {
			console.log('Received WATCH_OBJECT event, setting watch to', data)
			// @ts-expect-error I assume this is a plugin without type annotations
			Vue.nonreactive(data)
			this.watchObject = null
			this.watchObject = data
		})
	},
	computed: {
		...mapGetters(['showAIDebugger']),
		...mapState(['aiDebugString']),
		aiDebugStringHTML() {

			const split = this.aiDebugString.split('\n')
			let reconstructed = '<div>'

			for(let i =0; i < split.length; ++i) {
				let line = split[i]
				line = line.replaceAll(' ', '&nbsp;')
				line = line.replaceAll('\t', 'emsp;')
				reconstructed += `<div>${line}</div>`
			}

			reconstructed += '</div>'
			return reconstructed
		}
	},
	methods: {
		onPropertyChanged(rootObject) {
			if (this.settings.nonreactive) {
				// @ts-expect-error I assume this is a plugin without type annotations
				Vue.nonreactive(rootObject)
			}
			if (this.settings.autoRefresh) {
				this.refreshWatchObject()
			}
		},
		refreshWatchObject() {
			this.renderComponent = false
			this.$nextTick(() => {
				this.renderComponent = true
			})
		},
	},
}
