import { debounce } from 'lodash'
import { UI } from '../ui'
import logger from '../../utils/client-logger'
import { mapToRange } from '../../utils/math'

const rescale = debounce(() => {
	UI.getInstance().emitEvent('UIScale/updateScale', getScaleAmount())
}, 250)

export function getPanelWidth(panelIdentifier) {
	if (panelIdentifier === 'close') {
		return 0
	}
	return document.getElementById(panelIdentifier).offsetWidth
}

const getScaleAmount = () => {
	const MAX_SCALE_AMOUNT = 0.85
	const MAX_PANEL_HEIGHT = 1080
	const MAX_PANEL_WIDTH = 650

	let scaleAmount

	if (window.innerHeight < window.innerWidth) {
		const scaling = {
			from: { min: 600, max: 1009 },
			to: { min: 0.45, max: 0.85 },
			clamped: true,
		}
		scaleAmount = mapToRange(window.innerHeight, scaling.from.min, scaling.from.max, scaling.to.min, scaling.to.max, scaling.clamped)
	} else {
		// Aspect ratio is portrait so *if* any scaling is necessary, it'll be horizontal
		if (MAX_PANEL_WIDTH <= window.innerWidth) {
			// Panel doesn't need to be shrunk horizontally
			scaleAmount = MAX_SCALE_AMOUNT
		} else {
			scaleAmount = Math.min(parseFloat((window.innerWidth / MAX_PANEL_WIDTH).toFixed(2)), MAX_SCALE_AMOUNT)
		}
	}

	// TODO2: Scale down based on horizontal aspect ratio constraint?
	return scaleAmount
}

export const getBuffHeightAndScaling = () => {
	const buffHeight = Math.round(document.getElementById('buffs-and-debuffs').getBoundingClientRect().height)
	const buffTopPosition = Math.round(document.getElementById('buffs-and-debuffs').getBoundingClientRect().top)
	return { 'buffHeight': buffHeight, 'buffTop': buffTopPosition }
}

window.addEventListener('resize', rescale)

interface ScaleUIState {
	scale: number
	hoveredItem: any
	hoveredEnchantmentBar: string
	quadrantString: string
	verticalProp: string
	horizontalProp: string
	horizontalValue: number
	verticalValue: number
	tooltipDebug: boolean
	componentCalledFrom: string
	panelWidth: number
}

export const UIScale = () => {
	logger.debug('Initializing Scale UI module')
	return {
		namespaced: true,
		state: {
			scale: getScaleAmount(),
			hoveredItem: null,
			hoveredEnchantmentBar: null,
			horizontalValue: 0,
			verticalValue: 0,
			quadrantString: 'top-left',
			verticalProp: 'top',
			horizontalProp: 'left',
			tooltipDebug: false,
			componentCalledFrom: null,
			panelWidth: 0,
		} as ScaleUIState,
		mutations: {
			updateScale(state: ScaleUIState, newScaleAmount: number) {
				// console.log('Updating scale to:', newScaleAmount)
				state.scale = newScaleAmount
			},
			updatePanelWidth(state: ScaleUIState, panelId: string) {
				state.panelWidth = getPanelWidth(panelId)
			},
			showTooltip(state: ScaleUIState, item: any) {
				if (item === null) {
					state.hoveredItem = null
				} else {
					state.hoveredItem = item
					state.tooltipDebug = false
				}
			},
			showTooltipDebug(state: ScaleUIState, item: any) {
				if (item === null) {
					state.hoveredItem = null
				} else {
					state.hoveredItem = item
					if (process.env.NODE_ENV === 'loot-prod' || process.env.NODE_ENV === 'beta') {
						state.tooltipDebug = false
					} else {
						state.tooltipDebug = true
					}
				}
			},
			currentComponentHovered(state: ScaleUIState, componenet: string) {
				state.componentCalledFrom = componenet
			},
			showEnchantmentTooltip(state: ScaleUIState, tooltip: any) {
				state.hoveredEnchantmentBar = tooltip
			},
			updateToolTipPosition(state: ScaleUIState, mouseEvent: any) {
				const CURSOR_TOOLTIP_ZIGZAG_WIDTH_DIFF = 12

				//Getting the offset based off the zigzag image.
				// 56 = height of the zigzag. 44 is half the width of the zigzag.
				// There has to be a cleaner way to do all this
				const STATIC_TOOLTIP_VERTICAL_OFFSET = 56 - 56 * (1 - state.scale)
				const STATIC_TOOLTIP_HORIZONTAL_OFFSET = 44 - 44 * (1 - state.scale)

				const getVerticalCssPropFromMouseYPosition = (mouseYPosition) => {
					return mouseYPosition >= window.innerHeight / 2 ? 'bottom' : 'top'
				}
				const getHorizontalCssPropFromMouseXPosition = (mouseXPosition) => {
					return mouseXPosition >= window.innerWidth / 2 ? 'right' : 'left'
				}
				const { clientX: mouseX, clientY: mouseY } = mouseEvent
				state.verticalProp = getVerticalCssPropFromMouseYPosition(mouseY)
				state.horizontalProp = getHorizontalCssPropFromMouseXPosition(mouseX)
				state.quadrantString = `${state.verticalProp}-${state.horizontalProp}`

				if (state.verticalProp === 'top') {
					state.verticalValue = mouseY + STATIC_TOOLTIP_VERTICAL_OFFSET
				} else {
					state.verticalValue = Math.abs(window.innerHeight - mouseY + STATIC_TOOLTIP_VERTICAL_OFFSET)
				}

				if (state.horizontalProp === 'left') {
					state.horizontalValue = mouseX - STATIC_TOOLTIP_HORIZONTAL_OFFSET
				} else {
					state.horizontalValue = Math.abs(window.innerWidth - mouseX - STATIC_TOOLTIP_HORIZONTAL_OFFSET - CURSOR_TOOLTIP_ZIGZAG_WIDTH_DIFF)
				}
			},
		},
		getters: {
			currentHoveredComponent(state: ScaleUIState) {
				return state.componentCalledFrom
			},
			currentHoveredEchantment(state: ScaleUIState) {
				return state.hoveredEnchantmentBar
			},
			currentHoveredItem(state: ScaleUIState) {
				return state.hoveredItem
			},
			currentScale(state: ScaleUIState) {
				return state.scale
			},
			currentHorizontalValue(state: ScaleUIState) {
				return state.horizontalValue
			},
			currentVerticalValue(state: ScaleUIState) {
				return state.verticalValue
			},
			currentHorizontalProp(state: ScaleUIState) {
				return state.horizontalProp
			},
			currentVerticalProp(state: ScaleUIState) {
				return state.verticalProp
			},
			currentQuadrantString(state: ScaleUIState) {
				return state.quadrantString
			},
			currentTooltipDebug(state: ScaleUIState) {
				return state.tooltipDebug
			},
			panelWidth(state: ScaleUIState) {
				return state.panelWidth
			},
		},
		actions: {},
	}
}
