import { clone, groupBy, reduce } from 'lodash'
import { percentage, timeInMilliseconds, radians, timeInSeconds, gameUnits } from '../../utils/primitive-types'
import StatOperatorType from '../../loot/shared/stat-operator-type'
import { AliasStatName, BinaryFlag, NormalStatName, StatName, StatType, StatTypeToPropName } from '../../engine/shared/game-data/stat-type-mod-category'
import { StatCompoundFormulas } from '../../engine/shared/game-data/player-formulas'
import { mapToRange, throwIfNotFinite } from '../../utils/math'
import { ObjectPool, PoolableObject } from '../../third-party/object-pool'
import { debugConfig } from '../../engine/client/debug-config'
import { IFreeable } from '../../utils/pool-deallocation-list'
import { BinaryFlagData } from '../../engine/shared/game-data/binary-flag-data'
import { StatClampConstraint, StatConverter } from '../../loot/shared/stat-interfaces'

// if we exceed this number of bonuses, there's probably a mistake somewhere
// it also increases our list sorting time which could cause a performance issue
const MAX_BONUSES_PER_STAT_TYPE_WARNING = 150

const ALL_AILMENT_STATS = ['ignitePotency', 'chillPotency', 'shockPotency', 'poisonPotency', 'bleedPotency']
const DAMAGING_AILMENT_POTENCY = ['ignitePotency', 'poisonPotency', 'bleedPotency']
const STATUS_AILMENTS_POTENCY = ['chillPotency', 'shockPotency']

type PhasedStatTransform = [StatType, number, StatOperatorType]

const StatNames = reduce(
	StatTypeToPropName,
	function (acc, statName) {
		acc.push(statName)
		return acc
	},
	[],
)

enum StatBonusTag {
	UNTAGGED = 0,
	LEVEL_SCALING = 1,
	PLAYERCOUNT_SCALING = 2,
}

class StatBonus implements PoolableObject, IFreeable {
	private _statList: EntityStatList = undefined
	private _statName: StatName = undefined
	private _value: number | percentage = undefined
	private _operatorType: StatOperatorType = undefined
	private _tag: StatBonusTag = undefined
	private static pool = new ObjectPool(() => new StatBonus(), undefined, 500, 10, 'StatBonus', debugConfig.pooling.checkSomeCanonicals)

	get statList() {
		return this._statList
	}
	get statName() {
		return this._statName
	}
	get value() {
		return this._value
	}
	set value(newValue: number | percentage) {
		this._value = newValue
		this._statList.markAsDirty()
	}
	get operatorType() {
		return this._operatorType
	}
	get tag() {
		return this._tag
	}

	private constructor() {
	}

	setDefaultValues(defaultValues: any, overrideValues?: any) {
	}

	cleanup() {
		this._statList = undefined
		this._statName = undefined
		this._value = undefined
		this._operatorType = undefined
		this._tag = undefined
	}

	static alloc(statList: EntityStatList, statName: string, value: number | percentage, operatorType: StatOperatorType, tag?: StatBonusTag): StatBonus {
		const statBonus = StatBonus.pool.alloc()
		statBonus._statList = statList
		statBonus._statName = statName
		statBonus._value = value
		statBonus._operatorType = operatorType
		statBonus._tag = tag || StatBonusTag.UNTAGGED
		return statBonus
	}

	free() {
		StatBonus.pool.free(this)
	}

	/**
	 * Updates the value of a StatBonus. Identical to simply setting .value
	 *
	 * @param {(number | percentage)} newValue
	 * @memberof StatBonus
	 */
	update(newValue: number | percentage) {
		this._value = newValue
		this._statList.markAsDirty()
	}

	/**
	 * Removes this bonus entirely, causing the owning StatList to be marked as dirty.
	 *
	 * @returns
	 * @memberof StatBonus
	 */
	remove(): void {
		this._statList?.removeStatBonus(this)
	}
}

class EntityStatList implements PoolableObject, IFreeable {
	//end changing things

	private static defaultPropertyList
	protected static pool = new ObjectPool(() => new EntityStatList(), undefined, 1000, 10, 'EntityStatList', debugConfig.pooling.checkSomeCanonicals)

	/** Quickly inspect the multipliers for every stat */
	get statBonusMultiplier() {
		return this._statBonusMultiplier
	}
	/** Generic multiplier to all damage dealt */
	allDamage: percentage = 0
	projectileDamage: percentage = 0
	physicalDamage: number = 0
	fireDamage: number = 0
	iceDamage: number = 0
	lightningDamage: number = 0
	poisonDamage: number = 0
	/** Rate of attacking, where 1000 = 1 attack per second, 2000 = 2 attacks per second, etc. */
	attackRate: number = 0
	/** Chance to land a critical strike */
	critChance: percentage = 0
	/** Bonus damage dealt by a critical strike */
	critDamage: percentage = 0
	/** Rate at which cooldowns expire (faster = sooner/better) */
	cooldownSpeed: percentage = 0
	/** Total duration of a given cooldown */
	cooldownDuration: timeInMilliseconds = 0
	/** (NOT IMPLEMENTED) Multiplier to the potency of values applied by a debuff (varies per debuff) */
	debuffPotency: percentage = 0

	/** Velocity at which projectiles move */
	projectileSpeed: number = 0
	/** Number of projectiles that are fired when you pull the trigger */
	projectileCount: number = 0
	/** Size of projectile hitboxes and VFX */
	projectileSize: number = 0
	/** Duration a projectile exists before disappearing */
	projectileDuration: timeInSeconds = 0
	/** Maximum distance a projectile can travel. Still adheres to any hard caps specified in the projectile system. */
	projectileMaxRange: gameUnits = 0
	/** The amount that projectiles are varied within the SpreadAngle per shot. 1.0 = normal behavior. */
	projectileSpreadVariance: percentage = 0
	/** Angle that projectiles are fanned out and distributed in. */
	projectileSpreadAngle: radians = 0
	/** Width of beam weapons. */
	projectileBeamWidth: gameUnits = 0
	/** Length of beam weapons. */
	projectileBeamLength: gameUnits = 0
	/** Bonus damage multiplier the closer the target is to the shooter */
	projectilePointBlankDamage: number = 0
	/** Bonus damage multiplier the further the target is from the shooter */
	projectileFarShotDamage: number = 0
	/** Number of targets the projectile can pierce through before being destroyed */
	projectilePierceCount: number = 0
	/** Number of targets the projectile can chain to before being destroyed */
	projectileChainCount: number = 0
	/** Radius in which the projectile will deal splash damage around the point of collision */
	projectileSplashRadius: number = 0
	/** Percent of the weapon's damage that will be applied to the splash radius */
	projectileSplashDamage: percentage = 0
	/** Radius of a pool that is left on the ground that deals a damage-over-time effect */
	projectilePoolRadius: number = 0
	/** Knockback caused when the projectile collides with a target. Negative numbers cause the target to be pulled toward the shooter */
	projectileKnockback: number = 0
	/** (NOT IMPLEMENTED) Number of times the projectile can bounce off terrain before being destroyed */
	projectileRicochetCount: number = 0

	// TODO2: I added these for pooling purposes, but they should be properly commented
	none = undefined // TODO2: none gets added during runtime as it's one of the enums
	projectileTrajectoryAmplitude = undefined
	projectileTrajectoryAmplitudeMax = undefined
	projectileTrajectoryAmplitudeMin = undefined
	projectileTrajectoryPeriod = undefined
	projectileTrajectoryPeriodMax = undefined
	projectileTrajectoryPeriodMin = undefined
	projectileTrajectoryRadius = undefined
	projectileTrajectoryRadiusMax = undefined
	projectileTrajectoryRadiusMin = undefined
	projectileTrajectoryRange = undefined
	projectileTrajectoryRangeMax = undefined
	projectileTrajectoryRangeMin = undefined
	projectileTrajectoryValue = undefined
	projectileTrajectoryValueMax = undefined
	projectileTrajectoryValueMin = undefined

	/** Don't die */
	maxHealth: number = 0
	/** Amount of health (raw units) restored per regeneration tick */
	healthRegen: number = 0
	/** Delay before health regeneration starts */
	healthRegenDelay: timeInMilliseconds = 0
	/** Amount of shields that will block damage before taking health damage */
	maxShields: number = 0
	/** Duration you must not get hit before your first shield recharges */
	shieldRegenDelay: timeInMilliseconds = 0
	/** Duration you must not get hit for every subsequent shield to recharge */
	shieldRegenRate: timeInMilliseconds = 0
	/** Energy to power weapons that consume energy */
	maxEnergy: number = 0
	/** Amount of energy restored per regeneration tick */
	energyRegen: number = 0
	/** Amount of energy consumed to use the primary fire on a weapon */
	primaryFireEnergyCost: number = 0
	/** Rate at which a charge-up weapon will charge its energy */
	chargeRate: number = 0
	/** Multiplier to the MAX value of a charge weapon's damage */
	chargedWeaponDamage: number = 1
	/** How much extra damage the beam ramp up buffs gives per stack */
	beamRampUpDamage: number = BinaryFlagData[BinaryFlag.SPELLSWORD_SUPER_BEAM_RAMP_UP].data.damageBoost

	/** Chance (0-1) of igniting hit opponent */
	igniteChance: number = 0.25
	/** Chance (0-1) of chilling hit opponent */
	chillChance: number = 0.4
	/** Chance (0-1) of shocking hit opponent */
	shockChance: number = 0.125
	/** Chance (0-1) of poisoning hit opponent */
	poisonChance: number = 0.5
	/** Chance (0-1) of bleeding hit opponent */
	bleedChance: number = 1

	/** how potent ignite will be if you cause it */
	ignitePotency: number = 1
	/** how potent chill will be if you cause it */
	chillPotency: number = 0.5
	/** how potent shock will be if you cause it */
	shockPotency: number = 2
	/** how potent poison will be if you cause it */
	poisonPotency: number = 0.5
	/** how potent bleed will be if you cause it */
	bleedPotency: number = 0.25

	/** bonus / alias for all above potency stats */
	allAilmentPotency: number = 1
	/** alias for damaging potency stats */
	damagingAilmentPotency: number = 1
	/** alias for non-damaging potency stats */
	statusAilmentPotency: number = 1 

	/** Damage reduction against physical damage */
	defense: number = 0
	/** Damage reduction against fire damage */
	fireResist: number = 0
	/** Damage reduction against ice damage */
	iceResist: number = 0
	/** Damage reduction against lightning damage */
	lightningResist: number = 0
	/** Damage reduction against poison damage */
	poisonResist: number = 0
	/** Movement speed */
	movementSpeed: number = 0
	/** (NOT IMPLEMENTED) Multiplier to the duration that buffs applied to this entity will last */
	buffDuration: percentage = 0
	/** Reduced animation speed for using skills */
	skillUsageSpeed: percentage = 0
	/** Arbitrary parameter for skills, often used for velocity/momentum/etc. */
	skillSpeed: number = 0
	/** Length of time a skill is engaged */
	skillDuration: timeInMilliseconds = 0

	damageTaken: percentage = undefined /** From all sources of damage */
	damageTakenFromPhysical: percentage = undefined
	damageTakenFromFire: percentage = undefined
	damageTakenFromIce: percentage = undefined
	damageTakenFromLightning: percentage = undefined
	damageTakenFromPoison: percentage = undefined

	healingReceived: percentage = undefined
	health = undefined

	lootDropQuantity: number = 1
	coinDropQuantity: number = 1
	lootDropRarity: percentage = 1

	//These things are dynamic; idk where else to put them, and I don't want to think about it atm
	currentEnergy: number = 0
	isOnCooldown: boolean = false

	skillAttackSpeedBoost = undefined
	skillBattery = undefined
	skillBattleCry = undefined
	skillBulwark = undefined
	skillDodgeRoll = undefined
	skillGravityWell = undefined
	skillMovementSpeed = undefined
	skillOverchargedShot = undefined
	skillOvershield = undefined
	skillSickeningNova = undefined
	skillStoneForm = undefined
	skillTumbleRoll = undefined

	heartDropRate: percentage = undefined

	_converters: StatConverter[] = []
	_clamps: StatClampConstraint[] = []
	private _statBonusMultiplier = {}
	// this could be performance optimized so that this marks whether an individual stat is dirty, then recalcs only that stat
	private _isDirty: boolean = true
	private _resetStatsCb: (statList: EntityStatList) => {} = undefined
	// this could be performance optimized/memory optimized so that instead of being an object of N arrays, this could be a single array that is sorted by statName (or statType enum)
	_statBonuses = {}

	/**
	 * Allocs an instance of EntityStatList.
	 * @param {*} resetStatsCb Defines the stat reset behavior for this type of entity. Useful if you want to set default values for a stat prior to recalculation.
	 * @memberof EntityStatList
	 */
	static alloc(resetStatsCb) {
		const entityStatList = debugConfig.pooling.disableEntityStatListPools
			? new EntityStatList()
			: EntityStatList.pool.alloc()

		entityStatList._resetStatsCb = resetStatsCb

		StatNames.forEach((name) => {
			entityStatList._statBonuses[name] = []
		})

		return entityStatList
	}

	free() {
		if (!debugConfig.pooling.disableEntityStatListPools) {
			EntityStatList.pool.free(this)
		}
	}

	setDefaultValues(defaultValues: any, overrideValues?: any) {
	}

	cleanup() {
		for (const key in this._statBonuses) {
			if (Object.prototype.hasOwnProperty.call(this._statBonuses, key)) {
				const statBonuses = this._statBonuses[key];
				statBonuses.forEach(statBonus => statBonus.free())
			}
		}

		StatNames.forEach((name) => {
			this[name] = undefined
		})

		Object.assign(this, EntityStatList.defaultPropertyList)

		this._resetStatsCb = undefined
		this._converters = []
		this._clamps = []
		this._statBonusMultiplier = {}
		this._statBonuses = {}
	}

	protected constructor() {
		if (!EntityStatList.defaultPropertyList) {
			EntityStatList.defaultPropertyList = clone(this)
		}
	}

	markAsDirty() {
		this._isDirty = true
	}

	// The order these are in is important
	addStatBonus(statName: AliasStatName, statOperator: StatOperatorType, newValue: number | percentage, tag?: StatBonusTag): StatBonus[];
	addStatBonus(statName: NormalStatName, statOperator: StatOperatorType, newValue: number | percentage, tag?: StatBonusTag): StatBonus;
	addStatBonus(statName: StatName, statOperator: StatOperatorType, newValue: number | percentage, tag?: StatBonusTag): StatBonus | StatBonus[];
	addStatBonus(statName: StatName, statOperator: StatOperatorType, newValue: number | percentage, tag?: StatBonusTag): StatBonus | StatBonus[] {
		if (statName === 'allAilmentPotency' || statName === 'damagingAilmentPotency' || statName === 'statusAilmentPotency') {
			let statNamesArray
			switch(statName) {
				case 'allAilmentPotency':
					statNamesArray = ALL_AILMENT_STATS
					break
				case 'damagingAilmentPotency':
					statNamesArray = DAMAGING_AILMENT_POTENCY
					break
				case 'statusAilmentPotency':
					statNamesArray = STATUS_AILMENTS_POTENCY
					break
			}

			const bonuses = []
			for(let i=0; i < statNamesArray.length; ++i) {
				const bonus = StatBonus.alloc(this, statNamesArray[i], newValue, statOperator, tag)
				bonuses.push(bonus)
				this._statBonuses[statNamesArray[i]].push(bonus)
			}
			
			const aliasBonus = StatBonus.alloc(this, statName, newValue, statOperator, tag)
			this._statBonuses[statName].push(aliasBonus)
			bonuses.push(aliasBonus)

			this._isDirty = true
			return bonuses
		} else {
			const bonus = StatBonus.alloc(this, statName, newValue, statOperator, tag)
			this._statBonuses[statName].push(bonus)
			this._isDirty = true
			return bonus
		}
	}

	addConverter(converter: StatConverter) {
		this._converters.push(converter)
		this._isDirty = true
	}

	removeConverter(converter: StatConverter) {
		this._converters.remove(converter)
		this._isDirty = true
	}

	addClamp(clamp: StatClampConstraint) {
		this._clamps.push(clamp)
		this._isDirty = true
	}

	removeClamp(clamp: StatClampConstraint) {
		this._clamps.remove(clamp)
		this._isDirty = true
	}

	getStat(statName: string, ignoreMissingStat?: boolean): number | percentage | timeInMilliseconds | gameUnits {
		if (this._isDirty) {
			for (const name in this._statBonuses) {
				if (this._statBonuses.hasOwnProperty(name)) {
					if (this._statBonuses[name].length > MAX_BONUSES_PER_STAT_TYPE_WARNING) {
						if (process.env.NODE_ENV === 'local') {
							console.trace(`Stat bonuses for ${name} exceeded max bonuses of ${MAX_BONUSES_PER_STAT_TYPE_WARNING}.`)
						}
					}
					this._statBonuses[name].sort(sortByStatNameThenOperator)
				}
			}

			if (this._resetStatsCb) {
				this._resetStatsCb(this)
			}

			this._recalculateStats()
			if (this._converters.length) {
				this._converters.sort(sortByInputStatTypeThenOperator)
				this._applyConverters()
			}
			if (this._clamps.length) {
				this._clamps.sort(sortByStatType)
				this._applyClamps()
			}
			this._applyStatCompoundFormulas()
			this._isDirty = false
		}

		const statValue = this[statName]
		if (!ignoreMissingStat) {
			throwIfNotFinite(statValue, `no stat called ${statName}`)
		}
		return statValue
	}

	getStatBonusesCount() {
		let count = 0
		for (const name in this._statBonuses) {
			if (this._statBonuses.hasOwnProperty(name)) {
				count += this._statBonuses[name].length
			}
		}
		return count
	}

	getStatBonusesString(statName: string) {
		const bonuses = this._statBonuses[statName] as StatBonus[]
		let toReturn = ``

		for(let i =0; i < bonuses.length; ++i) {
			if(i < bonuses.length - 1) {
				toReturn += `${bonuses[i].operatorType} ${bonuses[i].value},`
			} else {
				toReturn += `${bonuses[i].operatorType} ${bonuses[i].value}`
			}
		}

		return toReturn
	}

	addStatBonusesFromString(statName: StatName, statString: string) {
		const splitString = statString.split(',')
		for(let i =0; i < splitString.length; ++i) {
			const stat = splitString[i]
			const operator = Number.parseInt(stat[0])
			const value = Number.parseFloat(stat.substring(2))

			this.addStatBonus(statName, operator, value)
		}
	}

	resetStats() {
		if (this._resetStatsCb) {
			this._resetStatsCb(this)
		}
	}

	removeStatBonus(statBonus: StatBonus): StatBonus {
		this._statBonuses[statBonus.statName].remove(statBonus)
		statBonus.free()
		this._isDirty = true
		return statBonus
	}

	clearAllState() {
		this.clearAllStatBonuses()
		this._converters.length = 0
		this._clamps.length = 0
		this._isDirty = true
	}

	clearAllStatBonuses() {
		for (const name in this._statBonuses) {
			if (this._statBonuses.hasOwnProperty(name)) {
				for (let i = this._statBonuses[name].length - 1; i >= 0; i--) {
					this.removeStatBonus(this._statBonuses[name][i])
				}
			}
		}
		this._isDirty = true
	}

	clearAllStatBonusesOfStat(statName: string) {
		if(this._statBonuses[statName]) {
			for (let i = this._statBonuses[statName].length - 1; i >= 0; i--) {
				this.removeStatBonus(this._statBonuses[statName][i])
			}
			this._isDirty = true
		}
	}

	clearAllStatBonusesWithTag(tag: StatBonusTag) {
		for (const name in this._statBonuses) {
			if (this._statBonuses.hasOwnProperty(name)) {
				for (let i = this._statBonuses[name].length - 1; i >= 0; i--) {
					if (this._statBonuses[name][i].tag === tag) {
						this.removeStatBonus(this._statBonuses[name][i])
					}
				}
			}
		}
	}

	getStatBonusWithTag(tag: StatBonusTag, statName: string, statOperator: StatOperatorType): StatBonus {
		for (let i = this._statBonuses[statName].length - 1; i >= 0; i--) {
			if (this._statBonuses[statName][i].tag === tag && this._statBonuses[statName][i].operatorType === statOperator) {
				return this._statBonuses[statName][i]
			}
		}
	}

	getAllStatsDebug(): string {
		this._resetStatsCb(this)
		let result = ''

		for (const name in this._statBonuses) {
			if (this._statBonuses.hasOwnProperty(name)) {
				this._statBonusMultiplier[name] = 1
				result += `${name}:\n  Base value: ${this[name]}\n`
				let multi = 0
				this._statBonuses[name].forEach((stat) => {
					if (stat.operatorType === StatOperatorType.SUM) {
						result += `   ${stat.statName}: ${stat.value} (sum)\n`
						this[stat.statName] += stat.value
					}
					if (stat.operatorType === StatOperatorType.SUM_THEN_MULTIPLY) {
						result += `   ${stat.statName}: ${stat.value} (sum then multiply)\n`
						multi += stat.value
					}
					if (stat.operatorType === StatOperatorType.MULTIPLY) {
						result += `   ${stat.statName}: ${stat.value} (multipy)\n`
						this[stat.statName] = this[stat.statName] * (1 + stat.value)
						this._statBonusMultiplier[name] *= 1 + stat.value
					}
				})
				this[name] = this[name] * (1 + multi)
				this._statBonusMultiplier[name] *= 1 + multi
				result += `  Result: ${this[name]}\n`
			}
		}

		this._isDirty = true

		return result
	}

	private _recalculateStats() {
		//console.log(`***_recalculateStats***`)
		const dirtyStats = []
		for (const name in this._statBonuses) {
			if (this._statBonuses.hasOwnProperty(name)) {
				this._statBonusMultiplier[name] = 1
				//console.assert(this[name] !== undefined, `${name} does not exist in EntityStatList, but is being assigned a value.`)
				//console.log(` modifying ${name}:${this[name]}`)
				dirtyStats.push(name)
				let multi = 0
				this._statBonuses[name].forEach((stat) => {
					//console.log(`  ${name}:${stat.value} ${stat.statName}`)
					if (stat.operatorType === StatOperatorType.SUM) {
						this[stat.statName] += stat.value
					} else if (stat.operatorType === StatOperatorType.SUM_THEN_MULTIPLY) {
						multi += stat.value
					} else if (stat.operatorType === StatOperatorType.MULTIPLY) {
						this[stat.statName] = this[stat.statName] * (1 + stat.value)
						this._statBonusMultiplier[name] *= 1 + stat.value
					}
				})
				this[name] = this[name] * (1 + multi)
				this._statBonusMultiplier[name] *= 1 + multi
				//console.log(` result: ${this[name]}`)
			}
		}
		this._applyStatCompoundFormulasToStats(dirtyStats)
	}

	private _applyConverters() {
		let transformsToApply: PhasedStatTransform[] = []

		const groupedConverters = groupBy(this._converters, 'inputStatType')
		for (const inputStatType in groupedConverters) {
			if (groupedConverters.hasOwnProperty(inputStatType)) {
				transformsToApply.push(...this._applyConvertersToSpecificStat(groupedConverters[inputStatType], groupedConverters[inputStatType][0].inputStatType))
			}
		}

		transformsToApply = transformsToApply.sort(sortPhasedStatTransformsByStatTypeAndOperatorType)
		this._applyStatTransforms(transformsToApply)
	}

	private _applyStatTransforms(transforms) {
		let multi = 0
		let lastStatType
		//TODO2: share this with the recalculateStats bit?
		transforms.forEach(([statType, value, operatorType]) => {
			const name = StatTypeToPropName[statType]
			if (multi && lastStatType && lastStatType !== statType) {
				const lastStatName = StatTypeToPropName[lastStatType]
				this[lastStatName] = this[lastStatName] * (1 + multi)
				multi = 0
			}
			if (operatorType === StatOperatorType.SUM) {
				this[name] += value
			} else if (operatorType === StatOperatorType.SUM_THEN_MULTIPLY) {
				multi += value
			} else if (operatorType === StatOperatorType.MULTIPLY) {
				this[name] = this[name] * (1 + value)
			}
			lastStatType = statType
		})
		if (multi && lastStatType) {
			const lastStatName = StatTypeToPropName[lastStatType]
			this[lastStatName] = this[lastStatName] * (1 + multi)
			multi = 0
		}
	}

	/**
	 *
	 * @param converters
	 * @param statType
	 * @param transformsToApply By-reference `out` array of transforms to pass back to caller
	 */
	private _applyConvertersToSpecificStat(converters: StatConverter[], statType: StatType): PhasedStatTransform[] {
		const transformsToApply: PhasedStatTransform[] = []
		const sumInputRatio = converters.reduce((prev, curr) => {
			return prev + curr.inputRatio
		}, 0)
		const inputStatValue = this[StatTypeToPropName[statType]]
		converters.forEach((converter) => {
			const inputStatAvailable = inputStatValue - converter.inputMinReserve
			// map an input ratio that could exceed 1.0 (100%) back to a range between 0-1.0
			const scaledInputRatio = sumInputRatio > 1 ? mapToRange(converter.inputRatio, 0, sumInputRatio, 0, 1) : converter.inputRatio
			const statValueToConvertToOutput = inputStatAvailable * scaledInputRatio

			const newInputValue = statValueToConvertToOutput
			const newOutputValue = statValueToConvertToOutput * converter.outputRatio

			transformsToApply.push([statType, -newInputValue, StatOperatorType.SUM])
			transformsToApply.push([converter.outputStatType, newOutputValue, converter.outputStatOperator])
		})

		return transformsToApply
	}

	private _applyClamps() {
		const groupedClamps = groupBy(this._clamps, 'statType')
		for (const statType in groupedClamps) {
			if (groupedClamps.hasOwnProperty(statType)) {
				let min = Number.MIN_SAFE_INTEGER
				let max = Number.MAX_SAFE_INTEGER
				const clamps = groupedClamps[statType]
				clamps.forEach((clamp) => {
					if (clamp.clampMin !== undefined) {
						min = Math.max(min, clamp.clampMin)
					}
					if (clamp.clampMax !== undefined) {
						max = Math.min(max, clamp.clampMax)
					}
				})
				const statName = StatTypeToPropName[statType]
				this[statName] = Math.clamp(this[statName], min, max)
			}
		}
	}

	private _applyStatCompoundFormulas() {
		for (const name in this._statBonuses) {
			if (this._statBonuses.hasOwnProperty(name)) {
				if (StatCompoundFormulas[name]) {
					this[name] = StatCompoundFormulas[name](0, this[name])
				}
			}
		}
	}

	private _applyStatCompoundFormulasToStats(statList: string[]) {
		statList.forEach((name) => {
			if (StatCompoundFormulas[name]) {
				this[name] = StatCompoundFormulas[name](0, this[name])
			}
		})
	}
}

function sortByStatNameThenOperator(a, b) {
	if (a.statName === b.statName) {
		return a.operatorType > b.operatorType ? 1 : -1
	} else {
		return a.statName > b.statName ? 1 : -1
	}
}

function sortByStatType(a, b) {
	return a.statType > b.statType ? 1 : -1
}

function sortByInputStatTypeThenOperator(a, b) {
	if (a.inputStatType === b.inputStatType) {
		return a.outputStatOperator > b.outputStatOperator ? 1 : -1
	} else {
		return a.inputStatType > b.inputStatType ? 1 : -1
	}
}

/** This is gross cause its a tuple, but potentially cheaper than destructuring all these params */
function sortPhasedStatTransformsByStatTypeAndOperatorType(a: PhasedStatTransform, b: PhasedStatTransform) {
	if (a[0] === b[0]) {
		return a[2] > b[2] ? 1 : -1
	} else {
		return a[0] > b[0] ? 1 : -1
	}
}

export default EntityStatList
export { EntityStatList, StatBonus, StatNames, StatBonusTag }
