














































































import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
import { currencyTooltip, prismScalesTooltip, pitBarHudTooltipText, pingTooltip, genericTooltip } from '../reusable-components/v-tooltip/v-tooltip-functions'
import { HALF_PIT_REWARD_COIN_SEND, MAX_PIT_REWARD_COIN_SEND } from '../../engine/shared/game-data/player-formulas'
import { to4DigitTruncatedString } from '../../utils/math'

export default {
	name: 'CurrencyHUD',
	data() {
		return {
			maxPitCoinItems: MAX_PIT_REWARD_COIN_SEND,
			halfPitCoinItems: HALF_PIT_REWARD_COIN_SEND,
		}
	},
	computed: {
		...mapState('pitOfChances', ['itemsTossedForCoins']),
		...mapGetters('user', ['isCspEnabled', 'userType']),
		...mapGetters('hud', ['coinBalance', 'scalesBalance', 'ping']),
		...mapGetters('inGame', ['currentGameMode']),
		rightOffset() {
			if (this.currentGameMode === 'hub') {
				return '74px'
			} else {
				return '100px'
			}
		},
		backgroundImage() {
			if (this.currentGameMode === 'hub') {
				return 'linear-gradient(to left, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));'
			} else {
				return 'none;'
			}
		},
	},
	methods: {
		currencyTooltip,
		prismScalesTooltip,
		pitBarHudTooltipText,
		to4DigitTruncatedString,
		pingTooltip,
		genericTooltip,
		...mapActions('mtxStore', ['openForteExchange']),
		...mapMutations('inGame', ['setActivePanel']),
		prismScalesClicked() {
			if(this.userType === 'registered') {
				this.openForteExchange()
			} else {
				this.setActivePanel('notLoggedIn')
			}
		}
	},
}
