import nengi from "nengi"

export default class FactionPrizeClaimResultMessage {
	static protocol = {
        claimed: nengi.Boolean
	}

    claimed: boolean

	constructor(claimed: boolean) {
        this.claimed = claimed
	}
}
