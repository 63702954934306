import { EnemyAI, FightingTriggers, IdleBehaviours, FightingBehaviours, LeashTriggers, LeashingBehaviours, AttackTypes, DeadBehaviours, ShotLeadPrecision, EnemyType, EnemyTargetingStyles } from '../../shared/ai-types'
import { ModType } from '../../../projectiles/shared/projectile-types'
import { SpineDataName, EnemyBipedSkinType } from '../../../models-animations/shared/spine-config'
import { EnemyDefaults } from './enemy-defaults'
import { Colors } from '../../../utils/colors'
import { ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { ParticleEffectType } from '../../../engine/shared/game-data/particle-config'
import { ENEMY_NAMES } from './enemy-names'

const distancer: EnemyAI = {
	name: ENEMY_NAMES.DISTANCER,
	type: EnemyType.BASIC,
	appearance: {
		asset: SpineDataName.BIPED_ENEMY,
		skin: EnemyBipedSkinType.SKELEMAGE,
		color: [0.5, 1, 0.5],
	},
	general: {
		enemyLevel: 1,
		experienceWhenKilled: 1,
	},
	baseAttributes: {
		...EnemyDefaults.baseAttributes,
		colliders: [
			{
				type: ColliderType.Circle,
				radius: 32,
				position: [0, -32],
				traits: ColliderTraits.BlockMovementAndProjectile,
			},
			{
				type: ColliderType.Circle,
				radius: 32,
				position: [0, -64],
				traits: ColliderTraits.BlockProjectile,
			},
		],
		movementSpeed: 120,
		decelerationRate: 2.5,
		turningRatePerSecondInDegrees: -1,

		attackRate: 1000,
	},
	soundEffects: {
		attack: 'Projectile_Pulse',
		impact: 'SFX_Impact_Enemy_Bone',
	},
	states: {
		idling: {
			behaviour: IdleBehaviours.STATIONARY,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			transitionToFighting: {
				trigger: FightingTriggers.AGGRESSIVE_PROXIMITY,
				radius: 700,
			},
			aggroOnHit: true,
		},
		fighting: {
			behaviour: FightingBehaviours.CHASE_AND_ATTACK,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 5,
			attackType: AttackTypes.PROJECTILE,
			engagementMaxDistance: 1100,
			engagementMinDistance: 0,
			movementMaxDistance: 700,
			movementMinDistance: 500,
			attackOptions: [
				{
					...EnemyDefaults.projectileConfig,
					projectileCount: 1,
					spreadAngle: 0,
					speed: 750,
					colliderRadius: 10,
					lifespanInSeconds: 5,
					modifiers: [{ modType: ModType.STRAIGHT }],
					burstCount: 0,
					bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_DISTANCER,
					bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_TRAIL,
					muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
					color: Colors.orange,
				},
			],
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 0.5,
			transitionToLeashing: {
				trigger: LeashTriggers.DISTANCE_TRAVELLED_OR_RADIUS_EXCEEDED,
				radius: 2000,
				distanceTravelled: 4000,
			},
		},
		leashing: {
			behaviour: LeashingBehaviours.MOVE,
			speedMultiplier: 4,
		},
		fleeing: {},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 0.2,
		},
	},
}

export default distancer
