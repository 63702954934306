import nengi from 'nengi'

export default class InspectObjectMessage {
	static protocol = {
		objectName: { type: nengi.String },
		objectJson: { type: nengi.String },
		enumJson: { type: nengi.String },
	}
	objectName: string
	objectJson: string
	enumJson: string

	/**
	 * Returns to client the state of the requested object named `objectName`
	 * @param objectName name of the object
	 * @param objectJson json'd state of the object
	 * @param enumJson json data of registered enums within the object, if any
	 */
	constructor(objectName: string, objectJson: string, enumJson?: string) {
		this.objectName = objectName
		this.objectJson = objectJson
		this.enumJson = enumJson
	}
}
