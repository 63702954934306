import { debugConfig } from '../../engine/client/debug-config'
import ItemRarity from '../../loot/shared/item-rarity'
import { costToIdentifyItem } from '../../utils/economy-data'

export function getIdentifyCost(rarity: ItemRarity, level: number) {
	if (debugConfig.items.freeIdentification) {
		return 0
	}

	if (rarity === ItemRarity.COMMON || rarity === ItemRarity.UNCOMMON) {
		// This item shouldn't be unidentified, but if it somehow is, let people fix that for free
		return 0
	}

	return costToIdentifyItem(rarity, level)
}
