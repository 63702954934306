





















import MainPanel from '../reusable-components/panels/main-panel.vue'
import TextButton from '../reusable-components/buttons/text-button.vue'
import MtxInventoryItem from '../reusable-components/inventory-item/mtx-item.vue'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
    name: 'GenericRewards',
    components: {
        MainPanel,
        TextButton,
        MtxInventoryItem
    },
    computed: {
        ...mapState('genericRewards', ['isClaimingRewards', 'claimButtonText', 'topText', 'bottomText', 'rewardItems', 'panelIdentifier']),
    },
    methods: {
        ...mapActions('genericRewards', ['claimRewards'])
    }
}
