import { debugConfig } from '../engine/client/debug-config'
import ItemRarity from '../loot/shared/item-rarity'

// === Shared stuff ===

export const VALUE_SCALE = 100

const RARITY_VALUES = {
	[ItemRarity.COMMON]: 0.7,
	[ItemRarity.UNCOMMON]: 0.85,
	[ItemRarity.RARE]: 1,
	[ItemRarity.EPIC]: 1.2,
	[ItemRarity.LEGENDARY]: 1.4,
	[ItemRarity.ASTRONOMICAL]: 1.7,
}

const NEXT_SLOT_COST = {
    0: 1,
    1: 1, // weapons never drop with -1 or 0 slots
    2: 10 * VALUE_SCALE,
    3: 25 * VALUE_SCALE,
    4: 75 * VALUE_SCALE,
    5: 150 * VALUE_SCALE,
    6: 500 * VALUE_SCALE,
}


export function valueDerivative(itemLevel): number {
	return Math.atan(itemLevel / 15) * (2 / Math.PI)
}

export function timeDerivative(x): number {
	return 0.036 + 0.00114 * x + 0.00582 * (x * x) + 0.000000229 * (x * x * x)
}

// === Worm Mail ===
export const HOURS_FOR_WORM_DELIVERY_TO_EXPIRE = 110 // If you change this, change it in Plutus items.ts::moveAllExpiredWormDeliveryItemsToTrash
export const MS_FOR_WORM_DELIVERY_TO_EXPIRE = HOURS_FOR_WORM_DELIVERY_TO_EXPIRE * 60 * 60 * 1000

// === Furnace ===
const BASE_ITEM_VALUE = 10 //coins

// returns in hours
export function hoursToBurnInFurnace(item): number {
	return timeDerivative(item.level)
}

export function coinReturnOfFurnace(item): number {
	const rarityValue = RARITY_VALUES[item.rarity]
	return Math.round(timeDerivative(item.level) * valueDerivative(item.level) * rarityValue * VALUE_SCALE) + BASE_ITEM_VALUE
}

// === Coin drop in adventure ===
const EXPECTED_ENEMY_KILLS_PER_HOUR = 520
export const EXPECTED_ENEMY_KILLS_FOR_MAX_COINS = 2 * EXPECTED_ENEMY_KILLS_PER_HOUR
export const COIN_DROP_CHANCE_PER_ENEMY = 0.15
export const CHAMPION_COIN_DROP_BONUS = 5
export const PROP_COIN_DROP_BONUS = 3

export function getCoinDropHardCap(worldTier: number): number {
	const maxItemLevelCanAttain = worldTier * 15
	const coinRewardOfItem = coinReturnOfFurnace({rarity: ItemRarity.RARE, level: maxItemLevelCanAttain})
	const hoursToBurnItem = hoursToBurnInFurnace({level: maxItemLevelCanAttain})
	const hourlyCoinOfItem = coinRewardOfItem / hoursToBurnItem
	return hourlyCoinOfItem * 54
}

export function getCoinDropOfEnemy(enemyWorldTier: number, enemyExperienceWhenKilled: number) {
	const hardCapOfTier = getCoinDropHardCap(enemyWorldTier)
	return Math.round(0.01 * hardCapOfTier * enemyExperienceWhenKilled)
}

// === Identification ===
const IDENTIFICATION_COST_SCALAR = 0.65

export function costToIdentifyItem(rarity: ItemRarity, level: number): number {
	const rarityValue = RARITY_VALUES[rarity]
	return Math.round(valueDerivative(level) * rarityValue * rarityValue * VALUE_SCALE * IDENTIFICATION_COST_SCALAR)
}

// === Augment adjustments ===
const ADJUSTMENT_COST = 0.15
const SLOT_COST = 5

export function costToAdjustAugment(item): number {
	if (debugConfig.items.freeAugmentation) {
		return 0
	}

	// newbies get free adjustments
	if (item.level <= 3) {
		return 0
	}

	const rarityValue = RARITY_VALUES[item.rarity]
	const returnValue = Math.round(valueDerivative(item.level) * rarityValue * VALUE_SCALE * ADJUSTMENT_COST)

	if (returnValue <= 1) {
		return 1
	}
	return returnValue
}

export function getLevelMultiplierForUser(progressionLevel: number) {
	return valueDerivative(progressionLevel) * VALUE_SCALE
}

// not hooked up to anything
export function costToOpenAugmentSlot(item): number | boolean {
    const rarityValue = RARITY_VALUES[item.rarity] ** (1 + RARITY_VALUES[item.rarity])
    const nextSlot = item.augmentLimit + 1

    if(nextSlot >= 7) {
        return false
    }
	
    const nextSlotCost = NEXT_SLOT_COST[nextSlot]
    const valueScalar = valueDerivative(item.level) ** (1 + valueDerivative(item.level))
    return Math.round(valueScalar * rarityValue * nextSlotCost)
}


// === Marketplace ===
export const LISTING_FEE_PERCENT = 0.05
export const PROFIT_FEE_PERCENT = 0.1

export const MINIMUM_LISTING_FEE = 1
export const MINIMUM_PROFIT_FEE = 1

// === Pit of Chances ===
export function getPitCoinRewards(item) : number {
	return valueDerivative(item.level) * VALUE_SCALE * PIT_COIN_REWARD_SCALARS[item.rarity] + BASE_ITEM_VALUE
}

export const PIT_COIN_REWARD_SCALARS = {
	[ItemRarity.COMMON]: 1.5375,
	[ItemRarity.UNCOMMON]: 1.875,
	[ItemRarity.RARE]: 2.2125,
	[ItemRarity.EPIC]: 2.6625,
	[ItemRarity.LEGENDARY]: 3.1125,
	[ItemRarity.ASTRONOMICAL]: 3.825
}

// Code stuff for testing,
// if you want to run this uncomment where it is called in hub.ts::constructor(), near the bottom of the function
// and the output will be printed in the console where you are running the hub

export function runEconomyTestTables() {
	const valueDTable = []
	const timeDTable = []

	const burnTable = []
	const coinReturnTable = []

	const identifyTable = []

	const augAdjustTable = []
	const augOpenTable = []

	for (let rarity = ItemRarity.COMMON; rarity <= ItemRarity.ASTRONOMICAL; ++rarity) {
		valueDTable[rarity] = []
		timeDTable[rarity] = []

		burnTable[rarity] = []
		coinReturnTable[rarity] = []

		identifyTable[rarity] = []

		augAdjustTable[rarity] = []
		augOpenTable[rarity] = []

		for (let level = 1; level <= 40; ++level) {
			const item = { rarity, level }

			valueDTable[rarity][level] = valueDerivative(item.level)
			timeDTable[rarity][level] = timeDerivative(item.level)

			burnTable[rarity][level] = hoursToBurnInFurnace(item)
			coinReturnTable[rarity][level] = coinReturnOfFurnace(item)

			identifyTable[rarity][level] = costToIdentifyItem(rarity, level)

			augAdjustTable[rarity][level] = costToAdjustAugment(item)
			augOpenTable[rarity][level] = costToOpenAugmentSlot(item)
		}
	}

	console.log(' ============ Economy Testing ============')
	console.log('ValueDerivative 5: ' + valueDTable[1][5] + ' 15: ' + valueDTable[1][15] + ' 40: ' + valueDTable[1][40])
	console.log('TimeDerivative 5: ' + timeDTable[1][5] + ' 15: ' + timeDTable[1][15] + ' 40: ' + timeDTable[1][40])
	console.log('Identify rare 5: ' + identifyTable[3][5] + ' 15: ' + identifyTable[3][15] + ' 40: ' + identifyTable[3][40] + ' 2: ' + identifyTable[3][2])
	console.log('Identify epic 5: ' + identifyTable[4][5] + ' 15: ' + identifyTable[4][15] + ' 40: ' + identifyTable[4][40] + ' 2: ' + identifyTable[4][2])
	console.log('Augment Adjust rare 5: ' + augAdjustTable[3][5] + ' 15: ' + augAdjustTable[3][15] + ' 40: ' + augAdjustTable[3][40] + ' 2: ' + augAdjustTable[3][2])
	console.log('Augment Adjust epic 5: ' + augAdjustTable[4][5] + ' 15: ' + augAdjustTable[4][15] + ' 40: ' + augAdjustTable[4][40] + ' 2: ' + augAdjustTable[4][2])
	console.log('==========================================')
}
