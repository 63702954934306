<template>
	<div class="notification-border-image">
		<div class="notification-notifier notification-body" @click="followNotification">
			<h3 class="notif-hud-text">Your marketplace listing has been sold! {{ getNotificationCount > 1 ? ` x${getNotificationCount}` : '' }}</h3>
		</div>
	</div>
</template>

<script lang="js">
import { mapGetters, mapActions } from 'vuex'
export default {
	name: 'NotificationNotifier',
	computed: {
		...mapGetters('UIScale', ['currentScale']),
		...mapGetters('notifications', ['getFirstNotificationSubject', 'getNotificationCount']),
	},
	methods:{
		...mapActions('notifications', ['followNotification']),
	}
}
</script>

<style lang="less" scoped>
@import url('../global/fonts/text.less');

.notification-border-image {

	.notification-notifier {
		display: flex;
		pointer-events: all;
		cursor: pointer;
		height: 65px;
		justify-content: flex-end;
		align-items: center;
		border-image-slice: 4 38 9 6 fill;
		border-image-width: 4px 38px 9px 6px;
		border-image-outset: 0px 0px 0px 0px;
		border-image-repeat: repeat stretch;
		border-image-source: url('../hud/assets/toast-generic.png');
		z-index: 2;

		.notif-hud-text {
			color: rgb(233, 229, 173);
			margin: 0;
			padding: 0;
			.notif-hud-text();
			font-size: 26px;
		}
	}
}
</style>
