import logger from '../../utils/client-logger'
import { NengiClient } from '../../engine/client/nengi-client'
import SendItemToPitAndActivateCommand from '../../items/shared/send-item-to-pit-and-activate-command'
import { uuid } from '../../utils/primitive-types'
import { Audio } from '../../audio/client/audio'
import { showGenericYesNoUI } from './generic-yes-no.ui-state'
import { isHighRarityItem, rarityPromptText, isPrize, isShard } from './rarity-helper'
import { MAX_PIT_SEND } from '../../engine/shared/game-data/player-formulas'
import { showStackableSelection } from './stackable-amount-selection.ui-state'

export const pitOfChancesModule = () => {
	logger.debug('Initializing Pit of Chances store module...')
	return {
		namespaced: true,
		state: {
			itemsInPit: [],
			maxItemsSentToPit: MAX_PIT_SEND,
			selectedPitItem: null,
			itemsTossedForCoins: 0,
			ItemStackAmounts: [],
		},
		getters: {
			itemIsInPit(state) {
				return state.itemsInPit.length > 0
			},
			getItemsInPit(state) {
				return state.itemsInPit
			},
			maxItemsInPit(state) {
				return state.maxItemsSentToPit
			},
			pitIsFull(state) {
				return state.itemsInPit.length >= state.maxItemsSentToPit
			},
		},
		actions: {
			sendItemToPit({ commit, rootGetters, state }, itemDetails, showWarning: boolean = false) {
				if (state.itemsInPit.length >= state.maxItemsSentToPit) {
					return
				}

				const itemId = itemDetails.id
				function sendIt() {
					const index = state.itemsInPit.findIndex((x) => x.id === itemId)
					if (index === -1) {
						state.itemsInPit.push(itemDetails)
						if (itemId === state.selectedPitItem) {
							commit('itemComparison/deselectItem', {}, { root: true })
							state.selectedPitItem = null
						}
					}
				}

				if (itemDetails.stackAmount) {
					const stackAmountById = state.ItemStackAmounts.find((i) => i.itemId === itemId)
					const selectedAmount = stackAmountById ? stackAmountById.selectedAmount : 1
					
					const index = state.itemsInPit.findIndex((x) => x.id === itemId)
					if (index === -1) {
						const cloneItem = Object.assign({}, itemDetails)
						itemDetails = cloneItem
						itemDetails.stackAmount = selectedAmount

						state.itemsInPit.push(itemDetails)
						if (itemId === state.selectedPitItem) {
							commit('itemComparison/deselectItem', {}, { root: true })
							state.selectedPitItem = null
						}
					} else {
						state.itemsInPit[index].stackAmount += selectedAmount
					}
				} else {
					if ((isPrize(itemDetails) || isHighRarityItem(itemDetails)) && isShard(itemDetails) === false && showWarning) {
						const questionText = rarityPromptText(itemDetails, 'pitOfChances')
						showGenericYesNoUI('Toss this item?', questionText, '', 'Yes', 'No', () => {
							//yes
							sendIt()
						})
					} else {
						sendIt()
					}
				}
			},
			removeItemFromPit({ commit, state, rootState }, item) {
				const itemId = item.id
				const index = state.itemsInPit.findIndex((x) => x.id === itemId)
				const stackIndex = state.ItemStackAmounts.findIndex((x) => x.itemId === itemId)

				if (index > -1) {
					state.itemsInPit.splice(index, 1)
				}

				if(stackIndex > -1){
					state.ItemStackAmounts.splice(index, 1)
				}
			},
			activatePit({ commit, rootState, state }) {
				let warningItems = 0
				if (state.itemsInPit.length > 0) {
					state.itemsInPit.forEach((itemDetails) => {
						if ((isPrize(itemDetails) || isHighRarityItem(itemDetails)) && isShard(itemDetails) === false) {
							warningItems++
						}
					})
					function sendIt() {
						const itemIds = itemsToPitCommandJSON(state.itemsInPit)

						NengiClient.getInstance().sendCommand(new SendItemToPitAndActivateCommand(itemIds, 'stash'))
						state.itemsInPit = []
					}
					if(warningItems) {
						showGenericYesNoUI('Toss this item?', `Are you sure you want to toss all of these? There are ${warningItems} fancy items.`, '', 'Toss', 'Cancel', () => {
							sendIt()
						})
					} else {
						sendIt()
						Audio.getInstance().playSfx('UI_Throw_In_Pit')
					}
				}
			},
			selectPitItem({ state, commit }, selectedItem?: uuid) {
				if (selectedItem && selectedItem !== state.selectedPitItem) {
					commit(
						'itemComparison/selectItem',
						{
							itemId: selectedItem,
							container: 'stash',
						},
						{ root: true },
					)
					state.selectedPitItem = selectedItem
				} else {
					commit('itemComparison/deselectItem', {}, { root: true })
					state.selectedPitItem = null
				}
			},
			tossDirectlyIntoPit({state, commit, rootGetters}, {itemId, fromContainer, sendingContainer}) {
				const selectedDetails = rootGetters['itemContainers/itemDetails'](fromContainer, itemId)

				if(selectedDetails.stackAmount) {
					function stackableSelectionCallback(stacks) {
						if (stacks) {
							const cloneItem = Object.assign({}, selectedDetails)
							cloneItem.stackAmount = stacks
							const itemJson = itemsToPitCommandJSON([cloneItem])
							NengiClient.getInstance().sendCommand(new SendItemToPitAndActivateCommand(itemJson, sendingContainer))
						}
					}

					showStackableSelection(selectedDetails, stackableSelectionCallback)
				} else {
					if ((isPrize(selectedDetails) || isHighRarityItem(selectedDetails)) && isShard(selectedDetails) === false) {
						const questionText = rarityPromptText(selectedDetails, 'pitOfChances')

						showGenericYesNoUI('Toss this item?', questionText, '', 'Toss', 'Cancel', () => {
							//Yes
							const itemIds = itemsToPitCommandJSON([selectedDetails])

							NengiClient.getInstance().sendCommand(new SendItemToPitAndActivateCommand(itemIds, sendingContainer))
						})

						return
					}

					const itemIds = itemsToPitCommandJSON([selectedDetails])
					NengiClient.getInstance().sendCommand(new SendItemToPitAndActivateCommand(itemIds, sendingContainer))
				}
			},
			openFlyoutPanel({commit}) {
				setTimeout(() => {
					const flyoutPanelName = 'pit-of-chances-panel'
					commit('inGame/updateFlyoutPanel', flyoutPanelName, { root: true })
					commit('UIScale/updatePanelWidth', flyoutPanelName, { root: true })
				}, 250)
			},
		},
		mutations: {
			pitContentsUpdated(state, updatedPitItemList) {
				state.itemsInPit = [...updatedPitItemList]
			},
			clearPitItems(state) {
				state.itemsInPit = []
			},
			setItemsTossedForCoins(state, numItems) {
				state.itemsTossedForCoins = numItems
			},
			updateItemStackAmounts(state, payload) {
				const { itemId, selectedAmount } = payload
				const itemIndex = state.ItemStackAmounts.findIndex((i) => i.itemId === itemId)

				if (itemIndex > -1) {
					state.ItemStackAmounts[itemIndex].selectedAmount = selectedAmount
				} else {
					state.ItemStackAmounts.push({ itemId: itemId, selectedAmount: selectedAmount })
				}
			},
		},
	}
}

function itemsToPitCommandJSON(items) {
	const ids = []
	for(let i =0; i < items.length; ++i) {
		const obj: any = {id: items[i].id}
		if(items[i].stackAmount) {
			obj.stacks = items[i].stackAmount
		}

		ids.push(obj)
	}

	return JSON.stringify(ids)
}