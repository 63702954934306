import { EnemyAI, FightingTriggers, IdleBehaviours, FightingBehaviours, LeashTriggers, LeashingBehaviours, AttackTypes, DeadBehaviours, ShotLeadPrecision, EnemyType, EnemyTargetingStyles } from '../../shared/ai-types'
import { ModType, ModValueType, BurstFireModes } from '../../../projectiles/shared/projectile-types'
import { SpineDataName, EnemyBipedSkinType } from '../../../models-animations/shared/spine-config'
import { EnemyDefaults } from './enemy-defaults'
import { Colors } from '../../../utils/colors'
import { ParticleEffectType } from '../../../engine/shared/game-data/particle-config'
import { Ability, AbilitySelectionStyles, AbilityTargets, AbilityTargetSelectionStyle, AbilityType, ActionCriteria, CriteriaSatisfactionQuantity, CriteriaTarget, CriteriaType } from '../action-types'
import { Vector } from 'sat'
import { ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { ENEMY_DEFAULT_HEALTH } from '../../../engine/shared/game-data/enemy-formulas'
import { attackRatedAbilityList } from '../action-criteria/ability-helpers'
import { AnimationTrack } from '../../../models-animations/shared/animation-track'
import { BuffIdentifier } from '../../../buffs/shared/buff.shared'
import { alwaysTrueCriteria, waitAbility } from './abilities/common-abilities'
import { ENEMY_NAMES } from './enemy-names'
import { worldDifficultyNormalCriterion, worldDifficultyHardCriterion, worldDifficultyBrutalCriterion } from '../action-criteria/action-criteria-helpers'
import { deepClone } from '../abilities.test'

const playerIsInAttackRangeCriteria: ActionCriteria = {
	satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
	criterias: [
		{
			criteriaTargets: CriteriaTarget.PLAYERS,
			criteriaType: CriteriaType.WITHIN_RANGE,
			criteriaValue: 650,
			minimumTargetsThatSatisfy: 1,
		},
	],
}

const playerIsInAttackRangeBrutalCriteria: ActionCriteria = {
	satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
	criterias: [
		worldDifficultyBrutalCriterion,
		{
			criteriaTargets: CriteriaTarget.PLAYERS,
			criteriaType: CriteriaType.WITHIN_RANGE,
			criteriaValue: 650,
			minimumTargetsThatSatisfy: 1,
		},
	],
}

const monsterInRangeIsDamagedLowTierCriteria: ActionCriteria = {
	satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
	criterias: [
		worldDifficultyNormalCriterion,
		{
			criteriaTargets: CriteriaTarget.AI_UNITS,
			criteriaType: CriteriaType.WITHIN_RANGE,
			criteriaValue: 650,
			minimumTargetsThatSatisfy: 1,
		},
		{
			criteriaTargets: CriteriaTarget.AI_UNITS,
			criteriaType: CriteriaType.HEALTH_BELOW_PERCENTAGE_THRESHOLD,
			criteriaValue: 75,
			minimumTargetsThatSatisfy: 1,
		},
		{
			criteriaTargets: CriteriaTarget.SELF,
			criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
			criteriaValue: BuffIdentifier.EnemyCooldown5000,
			minimumTargetsThatSatisfy: 1,
		},
	],
}
const monsterInRangeIsDamagedHighTierCriteria: ActionCriteria = {
	satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
	criterias: [
		worldDifficultyHardCriterion,
		{
			criteriaTargets: CriteriaTarget.AI_UNITS,
			criteriaType: CriteriaType.WITHIN_RANGE,
			criteriaValue: 650,
			minimumTargetsThatSatisfy: 1,
		},
		{
			criteriaTargets: CriteriaTarget.AI_UNITS,
			criteriaType: CriteriaType.HEALTH_BELOW_PERCENTAGE_THRESHOLD,
			criteriaValue: 75,
			minimumTargetsThatSatisfy: 1,
		},
	],
}

const monsterInRangeIsDamagedBrutalTierCriteria: ActionCriteria = {
	satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
	criterias: [
		worldDifficultyBrutalCriterion,
		{
			criteriaTargets: CriteriaTarget.AI_UNITS,
			criteriaType: CriteriaType.WITHIN_RANGE,
			criteriaValue: 650,
			minimumTargetsThatSatisfy: 1,
		},
		{
			criteriaTargets: CriteriaTarget.AI_UNITS,
			criteriaType: CriteriaType.HEALTH_BELOW_PERCENTAGE_THRESHOLD,
			criteriaValue: 75,
			minimumTargetsThatSatisfy: 1,
		},
	],
}

export const cooldownEnemyCooldown5000: Ability = {
	debugName: 'cooldown5000',
	abilityType: AbilityType.INSTANTLY_APPLY_EFFECT,
	buffToApply: BuffIdentifier.EnemyCooldown5000,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.MYSELF,
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
}

const animationTimes = {
	[AnimationTrack.SHOOT]: 0.3,
}

const boneSpiritRangedAttackAbility: Ability = {
	debugName: 'spiritShot',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.CLOSEST,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		projectileCount: 1,
		speed: 640,
		colliderRadius: 22,
		lifespanInSeconds: 2,
		modifiers: [
			{ modType: ModType.WAVE, amplitude: { modValueType: ModValueType.VALUE, value: 0.8 }, period: { modValueType: ModValueType.VALUE, value: 1.3 } },
			{ modType: ModType.ACCELERATION, value: { modValueType: ModValueType.RANDOM, min: -0.35, max: 0.0 } },
		],
		baseIceDamage: 60,
		burstCount: 3,
		burstDelay: 0.3,
		burstMode: BurstFireModes.STRAIGHT,
		color: Colors.red,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_BONESPIRIT,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_TRAIL,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
	},
}

export const boneSpiritRangedAttackBrutalAbility = deepClone(boneSpiritRangedAttackAbility)
boneSpiritRangedAttackBrutalAbility.projectileConfig.speed = 700
boneSpiritRangedAttackBrutalAbility.projectileConfig.colliderRadius = 26
boneSpiritRangedAttackBrutalAbility.projectileConfig.burstCount = 4

export const boneSpiritHealAbility: Ability = {
	debugName: 'block',
	abilityType: AbilityType.INSTANTLY_APPLY_EFFECT,
	buffToApply: BuffIdentifier.BoneSpiritHealing,
	durationInGameTicks: 20,
	validTargets: AbilityTargets.ALL_NEARBY_ENEMIES,
	validTargetSelection: AbilityTargetSelectionStyle.LOWEST_HEALTH,
}

const boneSpirit: EnemyAI = {
	name: ENEMY_NAMES.BONE_SPIRIT,
	type: EnemyType.BASIC,
	appearance: {
		asset: SpineDataName.BONE_SPIRIT,
		skin: EnemyBipedSkinType.DEFAULT,
		mixSettings: [],
	},
	general: {
		enemyLevel: 1,
		experienceWhenKilled: 1,
	},
	baseAttributes: {
		...EnemyDefaults.baseAttributes,
		visibilityRadius: 1,
		colliders: [
			{
				type: ColliderType.Circle,
				radius: 75,
				traits: ColliderTraits.BlockMovementAndProjectile,
				position: [0, -45],
			},
		],
		movementSpeed: 200,
		decelerationRate: 10,
		turningRatePerSecondInDegrees: 300,
		damageConfig: null,
		attackOffset: new Vector(0, -20),
		lootDropOffset: new Vector(0, 0),

		physicalDamage: 0,
		fireDamage: 0,
		iceDamage: 0,
		lightningDamage: 0,
		poisonDamage: 0,
		attackRate: 500,
		critChance: 0,
		critDamage: 1.0,

		maxHealth: (ENEMY_DEFAULT_HEALTH * 1) / 2,
		defense: 0,
		fireResist: 0,
		poisonResist: 0,
		iceResist: 0,
		lightningResist: 0,

		lootDropProbability: 0.6,
		lootDropQuantity: 1,
		lootDropRarity: 1,
	},
	soundEffects: {
		attack: 'Projectile_Zap',
		impact: 'SFX_Impact_Enemy_Mush',
	},
	states: {
		idling: {
			behaviour: IdleBehaviours.MEANDER,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 2,
			transitionToFighting: {
				trigger: FightingTriggers.AGGRESSIVE_PROXIMITY,
				radius: 800,
			},
			meander: {
				radius: 500,
				speedMultiplier: 1,
				minRestTime: 0.5,
				maxRestTime: 10,
			},
			aggroOnHit: true,
		},
		fighting: {
			behaviour: FightingBehaviours.STRAFE_AND_ATTACK,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 5,
			attackType: AttackTypes.PROJECTILE,
			engagementMaxDistance: 650,
			engagementMinDistance: 0,
			movementMaxDistance: 650,
			movementMinDistance: 0,
			brain: {
				actions: [
					{
						// Healing at Brutal
						priority: 3,
						actionCriteria: monsterInRangeIsDamagedBrutalTierCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							abilityOptions: [attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, boneSpiritHealAbility, 20)],
						},
					},
					{
						// Healing projectile at Hard
						priority: 4,
						actionCriteria: monsterInRangeIsDamagedHighTierCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							abilityOptions: [attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, boneSpiritHealAbility, 30)],
						},
					},
					{
						// Healing projectile at Normal
						priority: 5,
						actionCriteria: monsterInRangeIsDamagedLowTierCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SEQUENCE,
							abilityOptions: [
								//[1, crabRangedSlowingAbility, 40]
								attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, boneSpiritHealAbility, 0),
								attackRatedAbilityList(1, animationTimes, AnimationTrack.IDLE, cooldownEnemyCooldown5000, 30),
							],
						},
					},
					{
						// Attack at Brutal
						priority: 12,
						actionCriteria: playerIsInAttackRangeBrutalCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							//abilityOptions: [[1,  crabMeleeAttackAbility, 15]],
							abilityOptions: [attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, boneSpiritRangedAttackBrutalAbility, 20)],
						},
					},
					{
						// BLOCK/ATTACK
						priority: 13,
						actionCriteria: playerIsInAttackRangeCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							//abilityOptions: [[1,  crabMeleeAttackAbility, 15]],
							abilityOptions: [attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, boneSpiritRangedAttackAbility, 30)],
						},
					},
					{
						// Wait
						priority: 20,
						actionCriteria: alwaysTrueCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							//abilityOptions: [[1, longRangeAbility, 50]],
							abilityOptions: [[0, waitAbility(10)]],
						},
					},
				],
			},
			attackOptions: [
				{
					...EnemyDefaults.projectileConfig,
					projectileCount: 1,
					speed: 480,
					colliderRadius: 22,
					lifespanInSeconds: 2,
					modifiers: [
						{ modType: ModType.WAVE, amplitude: { modValueType: ModValueType.VALUE, value: 5.5 }, period: { modValueType: ModValueType.VALUE, value: 0.75 } },
						{ modType: ModType.ACCELERATION, value: { modValueType: ModValueType.RANDOM, min: -0.75, max: 0.0 } },
					],
					burstCount: 3,
					burstDelay: 0.3,
					burstMode: BurstFireModes.STRAIGHT,
					color: Colors.red,
					bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_BONESPIRIT,
					bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_ICE_TRAIL,
					muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_ICE_SHOOT,
				},
			],
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 1,
			transitionToLeashing: {
				trigger: LeashTriggers.DISTANCE_TRAVELLED_OR_RADIUS_EXCEEDED,
				radius: 2000,
				distanceTravelled: 4000,
			},
		},
		leashing: {
			behaviour: LeashingBehaviours.MOVE,
			speedMultiplier: 4,
		},
		fleeing: {},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 1,
		},
	},
}

export default boneSpirit
