import nengi from 'nengi'
import { timeInSeconds } from '../../utils/primitive-types'

export interface GateIdentifier {
	biomeIdx: number
	gateIdx: number
}

class ArenaTeleportCommand implements GateIdentifier {
	static protocol = {
		biomeIdx: nengi.Int8,
		gateIdx: nengi.Int8,
		delaySeconds: nengi.Float32,
	}

	biomeIdx: number
	gateIdx: number
	delaySeconds: timeInSeconds

	constructor(biomeIdx: number, gateIdx: number, delaySeconds: timeInSeconds) {
		this.biomeIdx = biomeIdx
		this.gateIdx = gateIdx
		this.delaySeconds = delaySeconds
	}
}

export default ArenaTeleportCommand
