export const enum ArrowPositionRequestValue {
	OUTPOST = -8,
	WORM_MAIL = -7,
	END_RUN = -6,
	POI = -5,
	BOSS = -4,
	STASH = -3,
	WORM_MAIL_HUB = -2,
	DEPARTURE_HUB = -1,
	NONE = 0,
	ALTAR = 1,
	END_OF_WORLD = 2,
	//If this goes over 7, have to change request-arrow-position-command to be a different int type
}
