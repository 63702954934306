















import CustomizeSkinPreviewDetail from '../reusable-components/skin-preview/customize-skins-details.vue'
import { mapGetters } from 'vuex'

export default {
	name: 'SkinPreviewPanel',
	components: {
		CustomizeSkinPreviewDetail,
	},
	props: {},
	data() {
		return {
			rightPx: 740,
		}
	},
	computed: {
		...mapGetters('UIScale', ['currentScale']),
		...mapGetters('inGame', ['activePanel']),
		getRight() {
			return this.rightPx * this.currentScale
		},
	},
	methods: {},
}
