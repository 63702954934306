import { BinaryFlag, StatType, StatTypeToPropName } from './stat-type-mod-category'
import EntityStatList from '../../../stat-system/shared/entity-stat-list'
import { PLAYER_DEFAULT_MOVEMENT_SPEED, PLAYER_MAX_BEAM_WIDTH, PLAYER_PROJECTILE_AND_BEAM_MAX_RANGE } from './player'
import { percentage } from '../../../utils/primitive-types'
import WeaponSubType from '../../../loot/shared/weapon-sub-type'
import { BinaryFlagData } from './binary-flag-data'

export const HEART_DROP_CHANCE: percentage = 0.05

export const SMALL_HEART_HEAL_PERCENT: percentage = 0.05
export const SMALL_HEARTS_IN_BIG_HEART = 5

const HEARTS_PER_WEAPON_NORMAL_ENEMY = new Map<WeaponSubType, number>()

HEARTS_PER_WEAPON_NORMAL_ENEMY.set(WeaponSubType.Crossbow, 3)
HEARTS_PER_WEAPON_NORMAL_ENEMY.set(WeaponSubType.Staff, 3)

HEARTS_PER_WEAPON_NORMAL_ENEMY.set(WeaponSubType.Wand, 4)
HEARTS_PER_WEAPON_NORMAL_ENEMY.set(WeaponSubType.ArcaneFocus, 4)

HEARTS_PER_WEAPON_NORMAL_ENEMY.set(WeaponSubType.Scythe, 6)
HEARTS_PER_WEAPON_NORMAL_ENEMY.set(WeaponSubType.Spellsword, 6)

//bosses
const HEARTS_PER_WEAPON_BOSS = new Map<WeaponSubType, number>()

HEARTS_PER_WEAPON_BOSS.set(WeaponSubType.Crossbow, 13)
HEARTS_PER_WEAPON_BOSS.set(WeaponSubType.Staff, 13)

HEARTS_PER_WEAPON_BOSS.set(WeaponSubType.Wand, 16)
HEARTS_PER_WEAPON_BOSS.set(WeaponSubType.ArcaneFocus, 16)

HEARTS_PER_WEAPON_BOSS.set(WeaponSubType.Scythe, 20)
HEARTS_PER_WEAPON_BOSS.set(WeaponSubType.Spellsword, 20)

export { HEARTS_PER_WEAPON_NORMAL_ENEMY, HEARTS_PER_WEAPON_BOSS }

export const STANDARD_WORM_HORN_ITEMS = 1
export const PREMIUM_WORM_HORN_ITEMS = 3

export const STASH_DEFAULT_MAX_SIZE = 100
export const STASH_SIZE_PER_INCREASE = 20
export const MAX_MTX_INCREASES = 10
export const PIT_STORAGE_DEFAULT_MAX_SIZE = 20

export const MAX_PIT_SEND = 35
export const HALF_PIT_REWARD_COIN_SEND = 12
export const MAX_PIT_REWARD_COIN_SEND = 24

export const PLAYER_SHOTGUN_ROLLOFF_TIME_MS = 1000
export const PLAYER_SHOTGUN_BASE_NUMBER = 0.4

const SPLASH_RADIUS_PENALTY_FLAT_THRESHOLD = 90
const SPLASH_RADIUS_PENALTY_SCALAR = 0.867

export function getNewbieDamageReductionMultiplier(worldTier: number) {
	if (worldTier <= 1) {
		return 0.25
	} else if (worldTier === 2) {
		return 0.50
	} else if (worldTier === 3) {
		return 0.75
	}
	return 1.0000
}

export function getStashLimit(increases: number) {
	return STASH_DEFAULT_MAX_SIZE + (STASH_SIZE_PER_INCREASE * increases)
}

export const StatCompoundFormulas = {}
StatCompoundFormulas[StatTypeToPropName[StatType.ATTACK_RATE]] = (base: number, current: number) => {
	if (current > 7500) {
		current -= 7500
		current = Math.min(5000, current ** 0.95 * 1.2)
		current += 7500
	}
	return current
}

StatCompoundFormulas[StatTypeToPropName[StatType.PROJECTILE_COUNT]] = (base: number, current: number) => {
	return Math.clamp(Math.floor(current), 1, 9)
}

StatCompoundFormulas[StatTypeToPropName[StatType.PROJECTILE_SPLASH_DAMAGE]] = (base: number, current: number) => {
	return Math.min(1.0, current)
}

StatCompoundFormulas[StatTypeToPropName[StatType.PROJECTILE_SPLASH_RADIUS]] = (base: number, current: number) => {
	if (current > SPLASH_RADIUS_PENALTY_FLAT_THRESHOLD) {
		current = Math.pow(current - SPLASH_RADIUS_PENALTY_FLAT_THRESHOLD, SPLASH_RADIUS_PENALTY_SCALAR) + SPLASH_RADIUS_PENALTY_FLAT_THRESHOLD
	}
	return Math.clamp(current, 0, 300)
}
export const SPLASH_RADIUS_PROJECTILE_COUNT_PENALTY: percentage = 0.07 // Math.pow(1 - this value, # projectiles over 1)

StatCompoundFormulas[StatTypeToPropName[StatType.PROJECTILE_MAX_RANGE]] = (base: number, current: number) => {
	return Math.clamp(current, 0, PLAYER_PROJECTILE_AND_BEAM_MAX_RANGE)
}

StatCompoundFormulas[StatTypeToPropName[StatType.PROJECTILE_BEAM_LENGTH]] = (base: number, current: number) => {
	return Math.clamp(current, 1, PLAYER_PROJECTILE_AND_BEAM_MAX_RANGE)
}

StatCompoundFormulas[StatTypeToPropName[StatType.PROJECTILE_BEAM_WIDTH]] = (base: number, current: number) => {
	return Math.clamp(current, 1, PLAYER_MAX_BEAM_WIDTH)
}


export function playerStatListResetFn(statList: EntityStatList) {
	statList.maxHealth = 250
	statList.healthRegen = 0
	statList.healthRegenDelay = 0
	statList.maxShields = 1
	statList.shieldRegenRate = 4000
	statList.shieldRegenDelay = 10000
	statList.maxEnergy = 100
	statList.energyRegen = 100
	statList.primaryFireEnergyCost = 0
	statList.movementSpeed = PLAYER_DEFAULT_MOVEMENT_SPEED

	statList.allDamage = 1.0
	statList.projectileDamage = 1.0
	statList.physicalDamage = 0
	statList.fireDamage = 0
	statList.iceDamage = 0
	statList.lightningDamage = 0
	statList.poisonDamage = 0
	statList.attackRate = 0
	statList.critChance = 0.05
	statList.critDamage = 1.5

	statList.cooldownDuration = 0
	statList.cooldownSpeed = 1.0
	statList.debuffPotency = 1.0
	statList.buffDuration = 1.0
	statList.skillUsageSpeed = 1.0
	statList.skillSpeed = 0.0
	statList.skillDuration = 1.0
	statList.chargeRate = 0
	statList.chargedWeaponDamage = 1
	statList.beamRampUpDamage = BinaryFlagData[BinaryFlag.SPELLSWORD_SUPER_BEAM_RAMP_UP].data.damageBoost

	statList.bleedChance = 0
	statList.igniteChance = 0.25
	statList.chillChance = 0.4
	statList.shockChance = 0.125
	statList.poisonChance = 0.5

	statList.bleedPotency = 1.0
	statList.ignitePotency = 2
	statList.chillPotency = 1.25
	statList.shockPotency = 4
	statList.poisonPotency = 1

	statList.allAilmentPotency = 1
	statList.damagingAilmentPotency = 1
	statList.statusAilmentPotency = 1

	statList.defense = 0
	statList.fireResist = 0
	statList.iceResist = 0
	statList.lightningResist = 0
	statList.poisonResist = 0

	statList.damageTaken = 1
	statList.damageTakenFromPhysical = 1
	statList.damageTakenFromFire = 1
	statList.damageTakenFromIce = 1
	statList.damageTakenFromLightning = 1
	statList.damageTakenFromPoison = 1

	statList.healingReceived = 1

	statList.projectileCount = 0
	statList.projectileSize = 20
	statList.projectileSpeed = 1200
	statList.projectileDuration = 2
	statList.projectileMaxRange = 0
	statList.projectileSpreadAngle = 0
	statList.projectileSpreadVariance = 0
	statList.projectilePointBlankDamage = 0
	statList.projectileFarShotDamage = 0
	statList.projectilePierceCount = 0
	statList.projectileChainCount = 0
	statList.projectileSplashRadius = 0
	statList.projectileSplashDamage = 0
	statList.projectilePoolRadius = 0
	statList.projectileKnockback = 0
	statList.projectileRicochetCount = 0

	statList.projectileBeamLength = 0
	statList.projectileBeamWidth = 0
	statList.lootDropQuantity = 1
	statList.coinDropQuantity = 1
	statList.lootDropRarity = 1

	statList.heartDropRate = 1
}

export function playerIndividualEquipmentSlotResetFn(statList: EntityStatList) {
	statList.maxHealth = 0
	statList.healthRegen = 0
	statList.healthRegenDelay = 0
	statList.maxShields = 0
	statList.shieldRegenRate = 0
	statList.shieldRegenDelay = 0
	statList.maxEnergy = 100
	statList.energyRegen = 100
	statList.primaryFireEnergyCost = 0
	statList.movementSpeed = 0

	statList.allDamage = 1.0
	statList.projectileDamage = 1.0
	statList.physicalDamage = 0
	statList.fireDamage = 0
	statList.iceDamage = 0
	statList.lightningDamage = 0
	statList.poisonDamage = 0
	statList.attackRate = 0
	statList.critChance = 0
	statList.critDamage = 0

	statList.cooldownDuration = 0
	statList.cooldownSpeed = 1.0
	statList.debuffPotency = 1.0
	statList.buffDuration = 1.0
	statList.skillUsageSpeed = 0.0
	statList.skillSpeed = 1.0
	statList.skillDuration = 1.0
	statList.chargeRate = 0

	statList.defense = 0
	statList.fireResist = 0
	statList.iceResist = 0
	statList.lightningResist = 0
	statList.poisonResist = 0

	statList.damageTaken = 1
	statList.damageTakenFromPhysical = 1
	statList.damageTakenFromFire = 1
	statList.damageTakenFromIce = 1
	statList.damageTakenFromLightning = 1
	statList.damageTakenFromPoison = 1

	statList.healingReceived = 1

	statList.projectileCount = 0
	statList.projectileSize = 0
	statList.projectileSpeed = 0
	statList.projectileDuration = 0
	statList.projectileMaxRange = 0
	statList.projectileSpreadAngle = 0
	statList.projectilePointBlankDamage = 0
	statList.projectileFarShotDamage = 0
	statList.projectilePierceCount = 0
	statList.projectileChainCount = 0
	statList.projectileSplashRadius = 0
	statList.projectileSplashDamage = 0
	statList.projectilePoolRadius = 0
	statList.projectileKnockback = 0
	statList.projectileRicochetCount = 0

	statList.projectileBeamLength = 0
	statList.projectileBeamWidth = 0
}