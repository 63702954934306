
























import { mapGetters, mapActions, mapMutations } from 'vuex'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import GridItemContainer from '../reusable-components/item-containers/grid-item-container.vue'
import PanelDivider from '../reusable-components/panel-divider/panel-divider.vue'
import ArrowButton from '../reusable-components/buttons/arrow-button.vue'

export default {
	name: 'PitOfChancesRewards',
	components: {
		MainPanel,
		GridItemContainer,
		PanelDivider,
		ArrowButton,
	},
	data() {
		return {}
	},
	computed: {
		...mapGetters('itemContainers', ['itemsInContainer', 'numberOfItemsInContainer', 'itemDetails']),
		...mapGetters('inGame', ['activePanel']),
		numberOfItemsRewarded() {
			return this.itemsInContainer('pitRewards').length
		},
	},
	methods: {
		...mapActions('inGame', ['closeActivePanel']),
		...mapMutations('inGame', ['setActivePanel']),
		openPitStorage() {
			this.setActivePanel('pitStorage')
		},
		backToPitOfChances() {
			this.setActivePanel('pitOfChancesUpdated')
		},
	},
}
