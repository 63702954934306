import nengi from 'nengi'

class PurchaseMtx {
	static protocol = {
		sku: nengi.String,
	}

	sku: string

	constructor(sku: string) {
		this.sku = sku
	}
}

export default PurchaseMtx
