





























































































































































import ButtonedSkinGridItemContainer from './button-skin-grid-item-container.vue'
import DropDown from '../inputs/dropdown.vue'
import CategoryFilter from '../filtering/category-filter.vue'
import { mapState, mapGetters } from 'vuex'
import { getPremiumStoreIconHexCode } from '../inputs/drop-down-icon-helper'
import { MtxSubTypes, MtxSubTypeCategoryPrettyName } from '../../../mtx/shared/mtx-item-types'
import { forEach, groupBy } from 'lodash'
import { FactionShortNameToFullName } from '../../../factions/shared/faction-data'

const MIN_NUM_MTX = 12
const EMPTY_MTX = {
	id: null,
	sku: null,
	type: null,
	itemCost: 'N/A',
}

export default {
	name: 'MtxListItemContainer',
	components: {
		ButtonedSkinGridItemContainer,
		CategoryFilter,
		DropDown,
	},
	data() {
		return {
			orderedSortedMtxItems: {},
		}
	},
	props: {
		items: {
			type: [Array, Object],
			required: true,
			default: () => {
				console.warn('Currently using the default array. Ensure the <MtxListItemContainer /> component has a custom attribute items')
				return []
			},
		},
		showSlot: {
			type: Boolean,
			required: false,
			default: true,
		},
		onSelectFn: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default on-select handler for the <MtxListItemContainer /> component has been called. Did you forget to pass an :on-click handler to the component?')
			},
		},
		selectedFn: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default selected fn for the <MtxListItemContainer /> component has been called. Did you forget to pass an :selected-fn handler to the component?')
			},
		},
		selectable: {
			type: Boolean,
			required: false,
			default: true,
		},
		buttonLabel: {
			type: String,
			required: true,
		},
		purchaseClickFn: {
			type: Function,
			required: true,
		},
		dropDownFilter: {
			type: Array,
			required: false,
		},
	},
	computed: {
		...mapState('itemContainers', ['activeMtxFilter']),
		...mapState('mainMenu', ['isChrome']),
		...mapState('factions', ['affiliatedFaction', 'lastWinningFaction']),
		...mapGetters('factions', ['currentlyWinningFaction']),
		getGroupedItems() {
			let groupedByType = groupBy(this.items, function(item) {
				return item.mtxType
			})
			let itemGroupedBySubtype = {}
			forEach(groupedByType, function(value, key) {
				itemGroupedBySubtype[key] = groupBy(groupedByType[key], function(item) {
					return item.mtxSubType
				})
			})
			this.orderedSortedMtxItems = itemGroupedBySubtype
			return this.orderedSortedMtxItems[this.activeMtxFilter]
		},
		getFactionItems() {
			let items = this.items
			items = items.filter((offer) => offer.sku.includes('faction-iron') || offer.sku.includes('faction-aurum') || offer.sku.includes('faction-scion'))
			return items
		},
		getLto() {
			let items = this.items
			items = items.filter((offer) => offer.offerStart !== null)
			return items
		},
		getLqo() {
			let items = this.items
			items = items.filter((offer) => offer.offerStart === null && offer.quantityRemaining !== null)
			return items
		},
	},
	methods: {
		getPremiumStoreIconHexCode,
		getBannerTitle(subType) {
			return MtxSubTypeCategoryPrettyName.get(subType)
		},
		getFactionFullName() {
			return FactionShortNameToFullName.get(this.lastWinningFaction)
		},
	},
	beforeUpdate(){
		this.$refs.mtxItemList.scrollTop = 0;
	}
}
