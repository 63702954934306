import { Container, Sprite, Texture, utils } from 'pixi.js'
import { AssetManager } from '../../asset-manager/client/asset-manager'
import { getBiomeList } from '../../biome/shared/biome-list'
import Renderer, { getVisibleWorldHeight, getVisibleWorldWidth } from '../../engine/client/graphics/renderer'
import { gameModeConfig } from '../../engine/shared/game-mode-configs'
import { basicNumberLerp } from '../../utils/math'

export default class GradientRenderer extends Container {
	private gradient: Container = new Container()
	private gradientTexture: Texture
	private gradientWidth: number
	private gradientHeight: number

	private currentBiome: number = 0
	private targetBiome: number = -1

	private biomeColors: number[][] = []

	constructor() {
		super()
		this.gradientWidth = getVisibleWorldWidth()
		this.gradientHeight = getVisibleWorldHeight()

		const biomeList = getBiomeList(gameModeConfig.type)
		biomeList.forEach((biome) => {
			this.biomeColors.push(biome.gradientColor)
		})
	}

	loadAssets(): void {
		this.gradientTexture = AssetManager.getInstance().getAssetByName('bwGradient').texture
		this.setupGradient()
	}

	update(): void {
		const cameraPos = Renderer.getInstance().getCameraCenterWorldPos()
		this.gradient.position.set(cameraPos.x - this.gradientWidth / 2, cameraPos.y - this.gradientHeight / 2)
		const renderer = Renderer.getInstance()

		if (renderer.biomeBounds) {
			if (this.targetBiome !== -1) {
				const dist = this.targetBiome > this.currentBiome ? renderer.biomeBounds[this.currentBiome].max - cameraPos.x : cameraPos.x - renderer.biomeBounds[this.currentBiome].min
				if (dist > this.gradientWidth / 2) {
					this.targetBiome = -1
					return
				}
				if (dist <= 0) {
					this.currentBiome = this.targetBiome
					this.targetBiome = -1
					return
				}
				const lerpRate = this.mapValue(dist, 0, this.gradientWidth / 2, 0, 1)
				const color = [
					basicNumberLerp(this.biomeColors[this.targetBiome][0], this.biomeColors[this.currentBiome][0], lerpRate) / 255,
					basicNumberLerp(this.biomeColors[this.targetBiome][1], this.biomeColors[this.currentBiome][1], lerpRate) / 255,
					basicNumberLerp(this.biomeColors[this.targetBiome][2], this.biomeColors[this.currentBiome][2], lerpRate) / 255,
				]
				this.gradient.children.forEach((child) => {
					const sprite = child as Sprite
					sprite.tint = utils.rgb2hex(color)
				})
			} else {
				const posDiff = cameraPos.x - renderer.biomeBounds[this.currentBiome].min
				const halfGradientWidth = this.gradientWidth / 2

				if (this.currentBiome !== 0 && posDiff < halfGradientWidth) {
					this.targetBiome = this.currentBiome - 1
				} else if (this.currentBiome < renderer.biomeBounds.length - 1 && renderer.biomeBounds[this.currentBiome].max - cameraPos.x < halfGradientWidth) {
					this.targetBiome = this.currentBiome + 1
				}
			}
		}
	}

	resize(zoomLevel): void {
		this.gradientWidth = getVisibleWorldWidth(zoomLevel)
		this.gradientHeight = getVisibleWorldHeight(zoomLevel)
		this.gradient.removeChildren()
		if (this.gradientTexture) {
			this.setupGradient()
		}
	}

	setBiome(biome: number, zoomLevel) {
		this.currentBiome = biome
		this.targetBiome = biome
		this.resize(zoomLevel)
	}

	private setupGradient(): void {
		const xScale: number = this.gradientWidth / this.gradientTexture.width
		let totalHeight: number = 0
		while (totalHeight < this.gradientHeight) {
			const sprite: Sprite = new Sprite(this.gradientTexture)
			sprite.scale.x = xScale
			sprite.tint = utils.rgb2hex([this.biomeColors[this.currentBiome][0] / 255, this.biomeColors[this.currentBiome][1] / 255, this.biomeColors[this.currentBiome][2] / 255])
			sprite.name = 'grad-tex'
			this.gradient.addChild(sprite)
			sprite.position.set(0, totalHeight)
			totalHeight += this.gradientTexture.height
		}
		this.gradient.name = 'gradiant'
		this.addChild(this.gradient)
	}

	private mapValue(value: number, min1: number, max1: number, min2: number, max2: number): number {
		return ((value - min1) * (max2 - min2)) / (max1 - min1) + min2
	}
}
