import logger from '../../utils/client-logger'
import { Audio } from '../../audio/client/audio'
import DismissNPCUI from '../../player/shared/dismiss-npc-ui'
import { NengiClient } from '../../engine/client/nengi-client'
import { UI } from '../ui'
import { tryShowTutorial } from './ftue-manager.state'
import { postWaitForCondition } from './advanced-tutorial-tooltip.ui-state'
import { WaitCondition } from './WaitCondition'
import { analyticsEventPanelOpen, analyticsEventPanelClosed } from './google-analyitics'
import moment from 'moment'
import { showGenericInfoPromptUI } from './generic-information-prompt.ui-state'
import { FTUESequence } from '../../ftue/shared/ftue.shared'

export enum Panel {
	USER_MARKETPLACE = 'userMarketListing',
	ADD_MARKETPLACE_LISTING = 'addMarketplaceListing',
	FURNACE = 'furnaceUpdate',
	STASH = 'stash',
	INVENTORY = 'inventory',
	PAPERDOLL = 'paperDoll',
	MAP = 'map',
	SETTINGS = 'gameSettings',
	RUN_SUMMARY = 'runSummary',
	BOAT_LAUNCH = 'boatLaunch',
	DEPARTURE = 'departure',
	DEPARTURE_OLD = 'departureOld',
	REWARD_SELECTION = 'rewardSelection',
	OUTPOST_WORM_MAIL = 'outpostWormMail',
	WORM_MAIL_DELIVERY = 'wormDelivery',
	PIT_OF_CHANCES = 'pitOfChancesUpdated',
	LEADERBOARD = 'leaderboard',
	END_RUN = 'runEnd',
	GENERAL_STORE = 'generalStoreUpdated',
	OUTPOST_STORE = 'outpostStore',
	MTX_STORE = 'premiumStore',
	END_OF_WORLD = 'endOfWorld',
	PLAYER_PROFILE = 'playerProfile',
	CUSTOMIZE_PLAYER = 'customize',
	COSMETICS = 'cosmetics',
	AUGMENTATION_STATION = 'augmentationStation',
	MARKETPLACE_UPDATE = 'marketplaceUpdated',
	PIT_REWARDS = 'pitRewards',
	PIT_STORAGE = 'pitStorage',
	GAME_OVER = 'gameOver',
	WORLD_TIER_COMPLETE = 'worldTierComplete',
	IDENTIFY = 'identify',
	DAILY_REWARDS = 'dailyRewards',
	FAQ = 'faq',
	FACTION_RECRUIT = 'factionAffil',
	FACTION_SCOREBOARD = 'factionScore',
	BIOME_KEY = 'biomeKey',
	NOT_LOGGED_IN ='notLoggedIn',
}

export enum playerPanelTabs {
	PROFILE = 'profile',
	PAPERDOLL = 'paperdoll',
	CUSTOMIZE = 'customize'
}

const CLOSE_ACTIONS = new Map<Panel, string[]>()
CLOSE_ACTIONS.set(Panel.STASH, ['miniPaperdoll/resetMiniPaperdollIndex', 'stash/toggleSelectStashItem', 'itemContainers/resetFilters'])
CLOSE_ACTIONS.set(Panel.INVENTORY, ['miniPaperdoll/resetMiniPaperdollIndex', 'itemContainers/resetFilters', 'itemComparison/resetAll', 'inventory/toggleSelectInventoryItem'])
CLOSE_ACTIONS.set(Panel.OUTPOST_WORM_MAIL, ['itemContainers/resetFilters', 'outpostWormMail/resetAll'])
CLOSE_ACTIONS.set(Panel.PIT_OF_CHANCES, ['pitOfChances/selectPitItem'])
CLOSE_ACTIONS.set(Panel.REWARD_SELECTION, ['itemContainers/resetFilters'])
CLOSE_ACTIONS.set(Panel.AUGMENTATION_STATION, ['itemContainers/resetFilters', 'itemComparison/resetAll', 'miniPaperdoll/resetMiniPaperdollIndex', 'slotAnimation/resetSlotState'])
CLOSE_ACTIONS.set(Panel.MARKETPLACE_UPDATE, ['itemContainers/resetFilters', 'itemComparison/resetAll', 'miniPaperdoll/resetMiniPaperdollIndex', 'marketplaceUpdated/deselectSellItems', 'marketplaceUpdated/resetPageNum'])
CLOSE_ACTIONS.set(Panel.USER_MARKETPLACE, ['itemContainers/resetFilters'])
CLOSE_ACTIONS.set(Panel.ADD_MARKETPLACE_LISTING, ['itemContainers/resetFilters', 'marketplaceUpdated/deselectSellItems'])
CLOSE_ACTIONS.set(Panel.COSMETICS, ['cosmetics/resetSelectedSkins'])
CLOSE_ACTIONS.set(Panel.FURNACE, ['itemContainers/resetFilters', 'furnace/selectFurnaceItem'])
CLOSE_ACTIONS.set(Panel.PAPERDOLL, ['itemComparison/resetAll'])
CLOSE_ACTIONS.set(Panel.WORM_MAIL_DELIVERY, ['itemComparison/resetAll'])
CLOSE_ACTIONS.set(Panel.DEPARTURE, ['inGame/closeFlyoutPanel'])
CLOSE_ACTIONS.set(Panel.NOT_LOGGED_IN, ['notLoggedIn/clearErrors'])
CLOSE_ACTIONS.set(Panel.BIOME_KEY, ['inGame/resetNextPanel'])

CLOSE_ACTIONS.set(Panel.END_RUN, ['factions/skipFactionAnimations', 'gameOver/resetGameOverFilter'])
CLOSE_ACTIONS.set(Panel.GAME_OVER, ['factions/skipFactionAnimations', 'gameOver/resetGameOverFilter'])
CLOSE_ACTIONS.set(Panel.WORLD_TIER_COMPLETE, ['factions/skipFactionAnimations', 'gameOver/resetGameOverFilter'])

const OPEN_ACTIONS = new Map<Panel, string[]>()
OPEN_ACTIONS.set(Panel.REWARD_SELECTION, ['itemContainers/resetFilters'])
OPEN_ACTIONS.set(Panel.MARKETPLACE_UPDATE, ['marketplaceUpdated/fetchListings'])
OPEN_ACTIONS.set(Panel.PIT_STORAGE, ['marketplaceUpdated/fetchUserListings']) //to update the 'my listings' counter on the 'Send to Marketplace!' btn
OPEN_ACTIONS.set(Panel.WORM_MAIL_DELIVERY, ['marketplaceUpdated/fetchUserListings'])
OPEN_ACTIONS.set(Panel.DEPARTURE, ['factions/getAllFactionMembers', 'factions/updateFactionScores'])
OPEN_ACTIONS.set(Panel.DEPARTURE_OLD, ['boatLaunch/openDepartureFlyoutPanelIfValid'])
OPEN_ACTIONS.set(Panel.GENERAL_STORE, [])
OPEN_ACTIONS.set(Panel.OUTPOST_STORE, [])
OPEN_ACTIONS.set(Panel.PIT_OF_CHANCES, ['pitOfChances/openFlyoutPanel'])
OPEN_ACTIONS.set(Panel.GAME_OVER, [])
OPEN_ACTIONS.set(Panel.END_RUN, [])
OPEN_ACTIONS.set(Panel.WORLD_TIER_COMPLETE, [])
OPEN_ACTIONS.set(Panel.SETTINGS, [])
OPEN_ACTIONS.set(Panel.DAILY_REWARDS, [])
OPEN_ACTIONS.set(Panel.MTX_STORE, ['mtxStore/getMtxOffers'])
OPEN_ACTIONS.set(Panel.USER_MARKETPLACE, ['marketplaceUpdated/fetchUserListings'])
OPEN_ACTIONS.set(Panel.FACTION_SCOREBOARD, ['factions/getAllFactionMembers', 'factions/updateFactionScores'])
OPEN_ACTIONS.set(Panel.FACTION_RECRUIT, ['factions/updateFactionScores', 'factions/getAllFactionMembers'])

function panelFTUEBlockedCheck(screen: string, gameMode): boolean {
	if (screen === 'premiumStore' && process.env.NODE_ENV === 'beta') {
		return true //just in case I forgot to remove some references to the mtx store somewhere for beta
	}

	if (screen === 'wormDelivery' || screen === 'departure' || screen === 'furnaceUpdate' || screen === 'marketplaceUpdated' || screen === 'pitOfChancesUpdated' || screen === 'generalStoreUpdated' || screen === 'outpostStore' || screen === 'identify') {
		const ftueGetFlag = UI.getInstance().store.getters['ftueManager/getFlag']
		const doneAugmentTut = ftueGetFlag('openedStashNPC')

		switch (screen) {
			case 'wormDelivery':
				tryShowTutorial(FTUESequence.WormDeliveryIntro)
				break
			case 'departure':
				if (UI.getInstance().store.getters['activeGameWindows/isInAGW'](moment().utc())) {
					tryShowTutorial(FTUESequence.BoatLaunchIntro)
					tryShowTutorial(FTUESequence.DifficultyIntro)
					tryShowTutorial(FTUESequence.SoulCyclesUnlocked)
				} else {
					showGenericInfoPromptUI('Ahoy!', ['The STORM is making the seas too rough to sail right now. We can set sail once it passes.', '&nbsp;', 'During <span class="ftue color teal">Early Access</span>, game servers are <span class="ftue color gold">OPEN</span> two days a week:', '<span class="ftue color teal">Tuesday</span>: 9 AM EST til 9 PM EST', '<span class="ftue color teal">Friday</span>: 12 noon EST til 12 midnight EST', '&nbsp;', '<a href="https://discord.gg/KsC29FkhAu" target="_blank">Join our Discord for more info!</a>'])
					return true
				}
				break
			case 'furnaceUpdate':
				tryShowTutorial(FTUESequence.SmeltingFurnaceIntro)
				break
			case 'marketplaceUpdated':
				tryShowTutorial(FTUESequence.MarketplaceIntro)
				break
			case 'pitOfChancesUpdated':
				tryShowTutorial(FTUESequence.TrashPitIntro)
				break
			case 'generalStoreUpdated':
				tryShowTutorial(FTUESequence.GeneralStoreIntro)
				break
			case 'outpostStore':
				tryShowTutorial(FTUESequence.OutpostShop)
				break
			case 'identify':
				tryShowTutorial(FTUESequence.IndentificationIntro)
				break
		}

		return !doneAugmentTut
	}

	if (screen === 'party') {
		const ftueGetFlag = UI.getInstance().store.getters['ftueManager/getFlag']
		const startedSecondRun = ftueGetFlag('startedSecondRun')

		tryShowTutorial(FTUESequence.PartyPlayIntro)

		return !startedSecondRun
	}

	if (gameMode === 'hub') {
		switch (screen) {
			case 'stash':
				tryShowTutorial(FTUESequence.AugmentWeapon)
				return false
			case 'premiumStore':
				tryShowTutorial(FTUESequence.MTXShopIntro)
				return false
			case 'cosmetics':
				tryShowTutorial(FTUESequence.CosmeticIntro)
				return false
			case 'leaderboardUpdated':
				tryShowTutorial(FTUESequence.LeaderboardIntro)
				return false
		}
	} else {
		switch (screen) {
			case 'outpostWormMail':
				tryShowTutorial(FTUESequence.FirstWormMail)
				return false
		}
	}
}

export const inGameModule = () => {
	logger.debug('Initializing In Game UI store module:')

	return {
		namespaced: true,
		state: {
			gameMode: 'loading',
			UIPaneIsCurrentlyActive: false,
			activePanel: false,
			menuDropdownVisible: false,
			npcId: -1,
			panelSwitchable: true,
			exclusivePanelSwitchable: false,
			exclusivePanelToSwitch: '',
			healthLow: false,
			flyoutPanel: false,
			ghostMode: false,
			runOver: false,
			showNFTMap: false,
			playerTab: 'profile',
			transitionOverlay: false,
			nextPanel: ''
		},
		mutations: {
			
			updateShowNFTMap(state: any, param: boolean) {
				state.showNFTMap = param
			},
			triggerHealthLow(state: any) {
				state.healthLow = true
			},
			triggerHealthStable(state: any) {
				state.healthLow = false
			},
			triggerGhostMode(state: any) {
				state.ghostMode = true
			},
			triggerPlayerRes(state: any) {
				state.ghostMode = false
			},
			triggerEndRun(state: any) {
				state.endRun = true
			},
			resetEndRun(state: any) {
				state.endRun = false
			},
			gameMode(state: any, gameMode: string) {
				state.gameMode = gameMode
			},
			toggleSound(state: any) {
				Audio.getInstance().toggleMute()
			},
			updateFlyoutPanel(state: any, selectedFlyoutPanel: string) {
				state.flyoutPanel = selectedFlyoutPanel
			},
			closeFlyoutPanel(state: any) {
				state.flyoutPanel = false
			},
			setActivePanel(state: any, newActivePanel: Panel) {
				const username = UI.getInstance().store.getters['user/username']
				const userId = UI.getInstance().store.getters['user/userId']

				if (!state.panelSwitchable) {
					return
				}
				if (state.exclusivePanelSwitchable && newActivePanel !== state.exclusivePanelToSwitch) {
					return
				}

				state.menuDropdownVisible = false

				if (panelFTUEBlockedCheck(newActivePanel, state.gameMode)) {
					return
				}

				if (state.activePanel === newActivePanel) {
					if (newActivePanel === Panel.INVENTORY) {
						UI.getInstance().emitEvent('itemContainers/updateInventoryBadgeList')
					}
					const closeActions = CLOSE_ACTIONS.get(state.activePanel)
					if (closeActions) {
						closeActions.forEach((a) => {
							UI.getInstance().emitAsyncEvent(a)
						})
					}
					state.activePanel = false
					const uiMode = UI.getInstance().store.state.UIMode
					if (uiMode !== 'main-menu') {
						Audio.getInstance().playSfx('UI_Menu_Close_Major')
					}
				} else {
					// These aren't typically triggered by ui pop messages
					if (newActivePanel === 'paperDoll' || newActivePanel === 'inventory' || newActivePanel === 'map') {
						Audio.getInstance().playSfx('UI_Menu_Open_Major')
					}

					if (state.activePanel) {
						const closeActions = CLOSE_ACTIONS.get(state.activePanel)
						if (closeActions) {
							closeActions.forEach((a) => {
								UI.getInstance().emitAsyncEvent(a)
							})
						}
					}

					state.activePanel = newActivePanel

					const openActions = OPEN_ACTIONS.get(newActivePanel)
					if (openActions) {
						openActions.forEach((a) => {
							UI.getInstance().emitAsyncEvent(a)
						})
					}

					if (newActivePanel === 'augmentationStation') {
						setTimeout(() => {
							// idk why this is needed to be delayed; I can only assume it is animation shenanigans or something stupid like that
							// without delay the arrow that gets created because of this is in the wrong spot (top left of screen ?!)

							// UPDATE: the animation length is 150ms.  This waits 200, but if there is lag it could fire before the anim is done?
							// so this should be 200ms + 1 more tick
							setTimeout(() => postWaitForCondition(WaitCondition.OPENED_AUGMENT_UI), 0)
						}, 200)
					}
				}
				state.flyoutPanel = false
				UI.getInstance().emitEvent('filter/resetFilters')
				state.UIPaneIsCurrentlyActive = state.activePanel !== false

				UI.getInstance().emitEvent('UIScale/showTooltip', null)

				if (newActivePanel === Panel.GENERAL_STORE || newActivePanel === Panel.DEPARTURE || newActivePanel === Panel.OUTPOST_STORE) {
					analyticsEventPanelOpen(newActivePanel, username, userId)
				}
			},
			toggleMenuDropdownVisible(state: any) {
				state.menuDropdownVisible = !state.menuDropdownVisible
			},
			showNPCPane(state: any, params: any) {
				const username = UI.getInstance().store.getters['user/username']
				const userId = UI.getInstance().store.getters['user/userId']

				if (!state.panelSwitchable) {
					return
				}

				if (state.exclusivePanelSwitchable && params.screen !== state.exclusivePanelToSwitch) {
					return
				}

				if (panelFTUEBlockedCheck(params.screen, state.gameMode)) {
					return
				}

				if (state.activePanel !== params.screen) {
					const closeActions = CLOSE_ACTIONS.get(state.activePanel)
					if (closeActions) {
						closeActions.forEach((a) => {
							UI.getInstance().emitAsyncEvent(a)
						})
					}
				}

				state.menuDropdownVisible = false
				state.npcId = params.npcId
				state.activePanel = params.screen
				state.UIPaneIsCurrentlyActive = true

				const openActions = OPEN_ACTIONS.get(params.screen)
				if (openActions) {
					openActions.forEach((a) => {
						UI.getInstance().emitAsyncEvent(a)
					})
				}

				UI.getInstance().emitEvent('UIScale/showTooltip', null)

				if (params.screen === Panel.GENERAL_STORE || params.screen === Panel.DEPARTURE || params.screen === Panel.OUTPOST_STORE) {
					analyticsEventPanelOpen(params.screen, username, userId)
				}
			},
			setPanelSwitchable(state: any, switchable: boolean) {
				state.panelSwitchable = switchable
			},
			setPanelExclusiveSwitchable(state: any, params) {
				const { exclusiveSwitch, panelToSwitch } = params

				state.exclusivePanelSwitchable = exclusiveSwitch
				state.exclusivePanelToSwitch = panelToSwitch
			},
			updatedPlayerTab(state, tab){
				UI.getInstance().store.commit('itemContainers/updateSkinTypeFilter', 'Player Skin')
				UI.getInstance().store.commit('itemComparison/deselectItem')
				state.playerTab = tab
			},
			updateNextPanel(state, panel){
				state.nextPanel = panel
			},
			
		},
		actions: {
			dismissNPCPane({ state, commit }) {
				const npcId = state.npcId
				NengiClient.getInstance().sendCommand(new DismissNPCUI(npcId))
				state.npcId = -1
				commit('UIScale/showTooltip', null, { root: true })
			},
			closeActivePanel(context: any) {
				const username = UI.getInstance().store.getters['user/username']
				const userId = UI.getInstance().store.getters['user/userId']
				const { state, dispatch, rootState, commit } = context
				const currentUIMode = rootState.UIMode
				const currentOpenedPanel = state.activePanel

				UI.getInstance().emitEvent('itemContainers/updateInventoryBadgeList')

				if (currentOpenedPanel === Panel.GENERAL_STORE || currentOpenedPanel === Panel.DEPARTURE || currentOpenedPanel === Panel.OUTPOST_STORE) {
					analyticsEventPanelClosed(currentOpenedPanel, username, userId)
				}

				if (!state.panelSwitchable) {
					return
				}

				if (state.exclusivePanelSwitchable && state.activePanel !== state.exclusivePanelToSwitch) {
					return
				}

				if (currentUIMode !== 'main-menu') {
					const closeActions = CLOSE_ACTIONS.get(state.activePanel)
					Audio.getInstance().playSfx('UI_Menu_Close_Major')
					if (closeActions) {
						closeActions.forEach((a) => {
							UI.getInstance().emitAsyncEvent(a)
						})
					}
					dispatch('dismissNPCPane', state.npcId)
					dispatch('inventory/toggleSelectInventoryItem', null, { root: true })
					commit('UIScale/showTooltip', null, { root: true })
				}
				state.menuDropdownVisible = false
				state.activePanel = false
				state.npcId = -1
				state.UIPaneIsCurrentlyActive = false
				commit('closeFlyoutPanel')
				UI.getInstance().emitEvent('filter/resetFilters')
			},
			setPanelSwitchable({ state }, switchable: boolean) {
				state.panelSwitchable = switchable
			},
			openFlyoutPanel({ state, commit }, newFlyoutPanel: 'depart' | 'augmentation-station') {
				commit('updateFlyoutPanel', newFlyoutPanel)
				UI.getInstance().emitEvent('UIScale/updatePanelWidth', newFlyoutPanel)
			},
			closeFlyoutPanel({ commit }) {
				commit('updateFlyoutPanel', false)
				UI.getInstance().emitEvent('UIScale/updatePanelWidth', 'close')
			},
			updateTransitionOverlay({state}, param: boolean){
				state.transitionOverlay = param
			},
			resetNextPanel({state}){
				state.nextPanel = ''
			},
		},
		getters: {
			healthLow(state: any) {
				return state.healthLow
			},
			ghostMode(state: any) {
				return state.ghostMode
			},
			currentGameMode(state: any) {
				return state.gameMode
			},
			activePanel(state: any) {
				return state.activePanel
			},
			menuDropdownVisible(state: any) {
				return state.menuDropdownVisible
			},
			flyoutPanel(state: any) {
				return state.flyoutPanel
			},
		},
	}
}
