import logger from '../../utils/client-logger'
import { clientConfig } from './client-config'
import { gameModeConfig, setGameModeConfig } from '../shared/game-mode-configs'
import * as PIXI from 'pixi.js'
window.PIXI = PIXI
import 'pixi-spine'
import nengiConfig from '../shared/nengi-config'
import Renderer, { getVisibleWorldWidth, getVisibleWorldHeight, NENGI_VIEW_X_PADDING, NENGI_VIEW_Y_PADDING } from './graphics/renderer'
import ClientPlayerInput from '../../input/client/client-player-input'
import { Audio } from '../../audio/client/audio'
import '../../utils/standard-library-additions'
import { highResolutionTimestamp } from '../../utils/debug'
import { ClientPlayer, clientPlayerUpdateHooks } from '../../player/client/player.client'
import { ClientEnemy, clientEnemyUpdateHooks } from '../../ai/client/enemy.client'
import { ClientProjectile, clientProjectileHooks, HACK_PARTICLE_ZOFFSET } from '../../projectiles/client/projectile.client'
import { ClientGroundItemEntity, clientGroundItemEntityHooks } from '../../items/client/ground-item-entity.client'
import EquippedNewGearMessage from '../../items/shared/equipped-new-gear-message'
import { ClientTerrainProp, clientTerrainPropHooks } from '../../world/client/terrain-prop.client'
import { ClientForegroundProp, clientForegroundPropHooks } from '../../world/client/foreground-prop.client'
import WeaponFiredMessage from '../../projectiles/shared/weapon-fired-message'
import PlayerDiedMessage from '../../player/shared/player-died-message'
import { ProjectileHitMessage } from '../../projectiles/shared/projectile-hit-message'
import EnemyDiedMessage from '../../ai/shared/enemy-died-message'
import { ClientGenericEntity, clientGenericHooks } from '../../ai/client/generic-entity.client'
import { clearObjectPoolArray, ObjectPool } from '../../third-party/object-pool'
import { ClientPOI, clientPOIHooks } from '../../world/client/poi.client'
import SkillUsedMessage from '../../gear-skills/shared/skill-used-message'
import { playAnimation } from '../../models-animations/client/play-animation'
import { AnimationTrack } from '../../models-animations/shared/animation-track'
import { InstancedSprite } from '../../world/client/instanced-sprite'
import { ClientNPC, clientNPCHooks } from '../../ai/client/npc.client'
import { NengiClient } from './nengi-client'
import SendItemContainerMessage, { assembleItemFromMessageIndex } from '../../items/shared/send-item-container-message'
import FurnaceStateMessage from '../../items/shared/furnace-state-message'
import { ClientColliderEntity, clientColliderEntityHooks } from '../../world/client/collider-entity.client'
import UpdateDebugOnloadCommandsMessage, { CommandOp } from '../../debug/shared/update-debug-onload-commands-message'
import ChatCommand from '../../chat/shared/chat-command'
import { updateCamera, Camera } from './graphics/camera-logic'
import GameModeMessage from '../shared/game-mode-message'
import HideEntityMessage from '../shared/hide-entity-message'
import { debounce } from 'lodash'
import ClientResizedCommand from '../shared/client-resized-command'
import BiomeTransitionMessage from '../../biome/shared/biome-transition-message'
import { getBiomeList } from '../../biome/shared/biome-list'
import PlayFanfareMessage from '../../audio/shared/play-fanfare-message'
import PlayAnimationMessage from '../../ai/shared/play-animation-message'
import ShakeCameraMessage from '../../ai/shared/shake-camera-message'
import BiomeBoundsMessage from '../../biome/shared/biome-bounds-message'
import SendMinimapUpdateMessage from '../../player/shared/send-minimap-update-message'
import { debugConfig, NengiLogType } from './debug-config'
import FileChangedMessage from '../shared/file-changed-message'
import { AssetManager } from '../../asset-manager/client/asset-manager'
import { CreatePfxOneOffMessage } from '../shared/create-pfx-one-off-message'
import TutorialFlagsMessage from '../../ftue/shared/tutorial-flags-message'
import { getHitAnimAlpha } from '../shared/game-data/enemy-formulas'
import SendStoreContentsMessage from '../../items/shared/send-store-contents-message'
import { GameModeType } from '../shared/game-mode-type'
import { TestMessage } from '../shared/test-message'
import { findGameServer } from '../../utils/api/polo-requests.client'
import LookupEntityByNidCommand from '../../debug/shared/lookup-entity-by-nid'
import LogEntityMessage from '../../debug/shared/log-entity'
import ItemLockedMessage from '../../items/shared/item-locked'
import ItemUnlockedMessage from '../../items/shared/item-unlocked'
import UnhideEntityMessage from '../shared/unhide-entity-message'
import DevToolsManager from '../../ui/dev-tools/dev-tools-manager'
import WormDoneSendingMessage from '../shared/worm-done-sending-message'
import { nengiId, WorldTier, timeInMilliseconds, timeInSeconds } from '../../utils/primitive-types'
import { average } from '../../utils/math'
import { ClientBeam, clientBeamHooks } from '../../beams/client/beam.client'
import InspectObjectMessage from '../../debug/shared/inspect-object-message'
import { UI } from '../../ui/ui'
import { DamageNumberStyle, DamageSeverity, DamageType } from '../../combat/shared/damage.shared'
import { callbacks_shutdown, callbacks_update } from '../../utils/callback-system'
import { simpleAnimation_shutdown, simpleAnimation_update } from '../../utils/simple-animation-system'
import { attachments_shutdown, attachments_update } from '../../utils/attachments-system'
import BroadcastMessage from '../../chat/shared/broadcast-message'
import Time from '../shared/time'
import HideWormMessage from '../shared/hide-worm-message'
import WaterBoundsMessage from '../../world/shared/water-bounds-message'
import ChatMessage from '../../chat/shared/chat-message'
import PopUIScreen from '../../player/shared/pop-ui-screen'
import Identity from '../shared/identity'
import DismissUIScreen from '../../player/shared/dismiss-ui-screen'
import ArenaTeleportCommand, { GateIdentifier } from '../../world/shared/arena-teleport-command'
import { createTeleportFilter } from '../../player/client/teleport-filter'
import DismissNPCUI from '../../player/shared/dismiss-npc-ui'
import TeleportVisualsMessage from '../../world/shared/teleport-visual-message'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
import { AnonymousRegistrationResponseMessage } from '../../player/shared/anonymous-registration-response'
import PlayerIsPurchasingMessage from '../../items/shared/player-is-purchasing-message'
import ItemIdentifiedMessage from '../../items/shared/item-identified'
import PlayerStatsMessage from '../../player/shared/player-stats-message'
import { BuffUpdateMessage } from '../../buffs/shared/buff-update-message'
import { buffType, getBuffIconByIdentifier, getLocalizedBuffText } from '../../buffs/shared/buff.shared'
import PlayerRunEndStatsMessage from '../../player/shared/player-run-end-stats-message'
import { UpdatedPlayerSettingsMessage } from '../../player/shared/player-settings'
import { showGenericInfoPromptUI } from '../../ui/state/generic-information-prompt.ui-state'
import ShowGenericPromptUIMessage from 'src/world/shared/show-generic-prompt-ui-message'
import ItemListingsMetadataMessage from '../../items/shared/item-listings-message'
import UserListingsMetadataMessage from '../../items/shared/user-listings-message'
import MarketplaceItemCountMessage from '../../items/shared/marketplace-item-count-message'
import NavigationArrow from '../../world/client/navigation-arrow'
import UpdateNavArrowDestination from '../../player/shared/update-nav-arrow-destination-message'
import UpdateNavArrowVisibility from '../../player/shared/update-nav-arrow-visibility-message'
import { GroundItemDropMessage } from '../../items/shared/ground-item-drop-message'
import ItemRarity from '../../loot/shared/item-rarity'
import { ModCategory, StatTypeToPropName } from '../shared/game-data/stat-type-mod-category'
import { spawnDamageNumber, spawnFloatingText } from './damage-numbers'
import UpdateFTUEMessageIndexMessage from '../../player/shared/update-ftue-message-index.message'
import { DamageNumberMessage } from '../../projectiles/shared/damage-dealt-message'
import { HealthBar } from '../../ai/client/health-bar'
import GenericErrorMessage from '../shared/generic-error-message'
import { completeFlagsFromCriteria, tryShowTutorial } from '../../ui/state/ftue-manager.state'
import FinishedPOIMessage from '../../world/shared/finished-poi-message'
import { setProximityMessageVisible, showProximityMessage } from '../../ui/state/proximity-message.ui-state'
import { DEFAULT_PROX_MESSAGE_TITLE, DONE_SENDING_MAIL_MESSAGE, DONE_WORM_MAIL_SEND_PROX_MESSAGE } from '../../ftue/client/ftue-message-configs'
import WorldBiomeGridMessage from '../../biome/shared/world-biome-grid-message'
import PlayerBeatFinalBossMessage from '../shared/player-beat-final-boss-message'
import { ArrowPositionRequestValue } from '../../ftue/shared/arrow-position-request-values'
import RequestArrowPositionCommand from '../../ftue/shared/request-arrow-position-command'
import FurnaceNotCollectingMessage from '../../items/shared/furnace-not-collecting-message'
import WorldDifficultyChangedMessage from '../../world/shared/world-difficulty-changed-message'
import PreviewAugmentsDataMessage from '../../items/shared/preview-augments-data-message'
import { InteractionPromptManager } from '../../ftue/client/interaction-prompt-manager'
import { NONLOCAL_PLAYER_ALPHA } from './graphics/projectile-effect-manager'
import PlayerBoughtHealingMessage from '../../items/shared/player-bought-healing-message'
import { showGenericYesNoUI } from '../../ui/state/generic-yes-no.ui-state'
import GCPause from 'src/debug/shared/gc-pause'
import { RiggedSpineModel } from '../../models-animations/client/spine-model'
import { clearObjectRefs } from '../../utils/object-util'
import ManyItemsUnlockedMessage from '../../items/shared/many-items-unlocked-message'
import AddItemToContainerMessage, { assembleItemFromMessage } from '../../items/shared/add-item-to-container-message'
import RemoveItemsFromContainerMessage from '../../items/shared/remove-items-from-container-message'
import { initClientTools } from '../../debug/shared/dev-debug-inspector-tools.client'
import UnlockedMtxMessage from '../../items/shared/unlocked-mtx-message'
import PlayerRunEndDamageTakenMessage from '../../player/shared/player-run-end-damage-taken-message'
import DailyLoginInfoMessage from '../../daily-login/shared/daily-login-info-message'
import DailyLoginClaimResultMessage from '../../daily-login/shared/daily-login-claim-result-message'
import NengiViewSizeUpdatedMessage from '../shared/nengi-view-size-updated-message'
import { handleClearPartyMessage, handlePartyFlagsMessage, handlePartyMessage } from '../../party-play/client/party.client'
import PartyDepartureMessage from '../../party-play/shared/party-departure-message'
import { countdownToDeparture, endGame, modeStartCount, startGame } from './start-stop'
import { analyticsEventStartAdventure } from '../../ui/state/google-analyitics'
import WormHornResultMessage from '../../items/shared/worm-horn-result-message'
import { ConnectionPayload } from '../shared/connection-payload'
import { /*getWorldDifficultyPrettyString, */WorldDifficulty } from '../shared/game-data/world-difficulty'
import { PROGRESSION_MAX_TIER_FOR_BETA, throwIfInvalidWorldTier } from '../shared/game-data/progression'
import GotExchangeURLMessage from '../../mtx/shared/got-exchange-url-message'
import PurchaseMtxResultMessage from '../../mtx/shared/purchase-mtx-result-message'
import UpdatePartyPositionsMessage from '../../player/shared/update-party-positions-message'
import PartyArrows from '../../world/client/party-arrows'
import PlayerResurrected from '../../player/shared/player-resurrected-message'
import TriggerPartyDepartureCountdownMessage from '../../party-play/shared/trigger-party-departure-countdown-message'
import { ClientConnectResponse } from '../shared/shared-helpers'
import PartyExitGameMessage from '../../party-play/shared/party-exit-game-message'
import PingAtLocationMessage from '../../player/shared/ping-at-location-message'
import ProfileMessage from '../../debug/shared/profile-message'
import SaveFileMessage from '../shared/save-file-message'
import AlertMessage from '../../chat/shared/alert-message'
import { BossCreditState } from '../../ai/shared/npc.shared'
import TryShowTutorialMessage from '../../player/shared/try-show-tutorial-message'
import FactionSetResultMessage from '../../factions/shared/faction-set-result-message'
import LockedIntoOutpostCommand from '../../ftue/shared/locked-into-outpost-command'
import SendItemModsMessage, { assembleModSetFromMessageIndex } from '../../items/shared/send-item-mods-message'
import PartyInvitationMessage from '../../chat/shared/party-invitation-message'
import UpdateCurrencyMessage from '../../items/shared/update-currency-message'
import StashIncreasesMessage from '../../items/shared/stash-increases-message'
import PitStatsMessage from '../../items/shared/pit-stats-message'
import MetricMessage from './metrics-message'
import { drawGraph, logData } from '../../debug/client/debug-graph'
import PlayerStatChangeMessage from '../../player/shared/player-stat-change-message'
import { LocalPlayerData } from '../../player/client/local-player-data'
import ItemStackSizeChangedMessage from '../../items/shared/item-stack-size-changed-message'
import DumpFileMessage from '../../world/shared/dump-file-message'
import { saveFileInBrowser } from '../../utils/file-util'
import { addDebugListenersIfNotAdded } from '../../debug/client/debug-client'
import FakeRegisterMessage from '../../debug/shared/fake-register-message'
import { ClientEmote } from '../../chat/client/emote.client'
import { FTUESequence } from '../../ftue/shared/ftue.shared'
import { ProjectileConfigSlotMessage } from '../../projectiles/shared/projectile-config-message'
import { ProjectileConfig } from '../../projectiles/shared/projectile-types'
import { hideFogBank, showFogBankAtPos } from '../../world/client/fog-bank'
import moment from 'moment'
import { addClientCollidable, removeClientCollidable } from '../../collision/client/client-collisions'
import PlayerIgnoreColliderMessage from '../../player/shared/player-ignore-collider-message'
import PlayerNotificationMessage from '../shared/player-notification-message'
import { NPCClientEmote } from '../../ai/client/npc.client.emote'
import SetFreeListingDateMessage from '../../marketplace/shared/set-free-listing-date-message'
import { ProjectileConfigIdMessage } from '../../projectiles/shared/projectile-config-id-message'
import { projectileRegistry_clientAddConfig } from '../../projectiles/client/projectile-config-registry.client'
import { ProjectileSplashMessage } from '../../projectiles/shared/projectile-splash-message'
import FactionPrizeClaimResultMessage from '../../factions/shared/faction-prize-claim-result-message'
import ItemSlotNotificationMessage from '../../items/shared/item-slot-notification-message'
import weaponAugmentSlotUpdateMessage from '../../items/shared/weapon-augment-slot-update-message'
import { FactionShortName, FACTION_AFFILIATION_PRIZES } from '../../factions/shared/faction-data'
import SkillCooldownUpdatedMessage from '../../gear-skills/shared/skill-updated-message'
import PlayerReputationCommand from '../../player/shared/player-reputation-command'
import SendPlayerReputationPointsMessage from '../../player/shared/player-reputation-points-message'
import PlayerMovedToBiomeMessage from '../../player/shared/player-moved-to-biome-message'
import DisconnectReasonMessage from '../shared/disconnect-reason-message'
import AIDebugStringMessage from '../../world/shared/ai-debug-string-message'
import PlayerGearStatChangeMessage from '../../player/shared/player-gear-stat-change-message'
import PlayerBinaryFlagChangedMessage from '../../player/shared/player-binary-flag-change-message'
import BuffData from '../../buffs/shared/buff-map'
import { ClientBuff } from '../../buffs/client/buff.client'

BuffData.initializeBuffMapData()

dayjs.extend(relativeTime)
dayjs.extend(updateLocale)
dayjs.updateLocale('en', {
	relativeTime: {
		future: 'in %s',
		past: '%s ago',
		s: '%d seconds',
		m: 'a minute',
		mm: '%d minutes',
		h: 'an hour',
		hh: '%d hours',
		d: 'a day',
		dd: '%d days',
		M: 'a month',
		MM: '%d months',
		y: 'a year',
		yy: '%d years',
	},
})

// cap at min 10 FPS
const MAX_DELTA_TIME = 1 / 10
const IGNORE_OLD_PFX_SECONDS = 0.5

addDebugListenersIfNotAdded()


export class GameClient {
	nengiClient: NengiClient
	state: {
		myId: number
		myEntity: ClientPlayer
	}
	entities: Map<number, any>
	entityHookMappings: any
	renderer: Renderer
	input: ClientPlayerInput
	interactionPrompts: InteractionPromptManager
	isConnected: boolean
	disconnectReason: string

	// Level up system and therefore experience spawner is nixed; commenting this out but leaving code around in case we want it later
	// experienceSpawner: ExperienceSpawner

	ui: any
	connectionPayload: ConnectionPayload
	hiddenEntityIDs: nengiId[] = []
	hiddenWormIDs: nengiId[] = []
	gameMode: GameModeType
	animationFrameId: number
	previousFrameStart: number
	activeWorldTier: WorldTier
	serverWorldDifficulty: WorldDifficulty // TODO2: the servers world difficulty may differ from worldDifficulty (as worldDifficulty is the tier we want, not the one we got)
	initialpartyId: string
	localPlayerData: LocalPlayerData

	checkedHubFogOnce: boolean = false
	oldHubFogWallEnabled: boolean = false

	exitGameFunction: () => void

	static getInstance(): GameClient {
		return GameClient.instance
	}
	private static instance: GameClient

	constructor(gameMode: GameModeType, connectionPayload: ConnectionPayload, partyId: string, region: string, predeterminedGameAddress: string = '') {
		GameClient.instance = this

		if (gameMode === GameModeType.Adventure) {
			throwIfInvalidWorldTier(connectionPayload.worldDifficulty)
		}

		setGameModeConfig(gameMode)

		this.nengiClient = NengiClient.getInstance({ nengiConfig, interpDelay: 100 })
		this.entities = new Map()
		this.connectionPayload = connectionPayload
		this.gameMode = gameMode
		this.initialpartyId = partyId
		this.state = {
			myId: null,
			myEntity: null,
		}

		if (!(region === 'na' || region === 'eu' || region === 'ap')) {
			logger.error(`Invalid region '${region}'', defaulting to 'na'`)
			region = 'na'
		}

		UI.getInstance().emitEvent('itemContainers/clearContainers')
		UI.getInstance().store.state.chat.chatFocused = false

		if (debugConfig.showStatsMeter) {
			const s = document.createElement('script')
			s.onload = function() {
				// @ts-expect-error
				const stats = new Stats()
				document.body.appendChild(stats.dom)
				requestAnimationFrame(function loop() {
					stats.update()
					requestAnimationFrame(loop)
				})
			}
			s.src = '//mrdoob.github.io/stats.js/build/stats.min.js'
			document.head.appendChild(s)
		}

		this.renderer = Renderer.getInstance({ gameClientState: this.state })
		this.interactionPrompts = InteractionPromptManager.createInstance()

		// Mike: I'm disabling this as it's building up the data from all messages received
		//  The paremeter `name` below is the data from the message (not a name) so can be a considerable amount of memory potentially
		//  I'll keep this around as it IS interesting to look at all the messages received.
		// const messageCounter = new Map<string, number>()
		// let logCounter = 0
		// NengiClient.getInstance().listen('message', function(name) {
		// 	messageCounter.set(name, messageCounter.get(name) + 1 || 1)
		// 	logCounter++
		// 	if (logCounter >= 50) {
		// 		logCounter =0
		// 	}
		// })

		const poolProjectile = new ObjectPool(() => new ClientProjectile(), undefined, 256, 64, 'projectile')
		ClientProjectile.pool = poolProjectile
		// const poolPlayer = new ObjectPool(() => new ClientPlayer(), undefined, 20, 10)
		// const poolEnemy = new ObjectPool(() => new ClientEnemy(), undefined, 50, 10)
		// const poolGroundItemEntity = new ObjectPool(() => new ClientGroundItemEntity(), undefined, 100, 10)
		const poolTerrainProp = new ObjectPool(() => new ClientTerrainProp(), undefined, 256, 64, 'prop')
		ClientTerrainProp.pool = poolTerrainProp
		const poolForegroundProp = new ObjectPool(() => new ClientForegroundProp(), undefined, 128, 32, 'f-prop')
		ClientForegroundProp.pool = poolForegroundProp
		// const poolCorpse = new ObjectPool(() => ClientCorpse(), undefined, 50, 10)
		HealthBar.pool = new ObjectPool(() => new HealthBar(), undefined, 30, 1, 'healthbar')
		ClientBuff.pool = new ObjectPool(() => new ClientBuff(), undefined, 20, 1)
		RiggedSpineModel.pools = null

		ClientEmote.pool = new ObjectPool(() => new ClientEmote(), undefined, 6, 1, 'emote')
		NPCClientEmote.pool = new ObjectPool(() => new NPCClientEmote(), undefined, 6, 1, 'npc-emote')
		this.entityHookMappings = {
			Projectile: {
				pool: poolProjectile,
				hooks: clientProjectileHooks,
			},
			Player: {
				constructor: ClientPlayer,
				hooks: clientPlayerUpdateHooks,
			},
			Enemy: {
				constructor: ClientEnemy,
				hooks: clientEnemyUpdateHooks,
			},
			GroundItemEntity: {
				constructor: ClientGroundItemEntity,
				hooks: clientGroundItemEntityHooks,
			},
			TerrainProp: {
				pool: poolTerrainProp,
				hooks: clientTerrainPropHooks,
			},
			ForegroundProp: {
				pool: poolForegroundProp,
				hooks: clientForegroundPropHooks,
			},
			NPC: {
				constructor: ClientNPC,
				hooks: clientNPCHooks,
			},
			Generic: {
				constructor: ClientGenericEntity,
				hooks: clientGenericHooks,
			},
			Beam: {
				constructor: ClientBeam,
				registrationFn: () => registerBeam,
				hooks: clientBeamHooks,
			},
			POI: {
				constructor: ClientPOI,
				hooks: clientPOIHooks,
			},
			ColliderEntity: {
				constructor: ClientColliderEntity,
				hooks: clientColliderEntityHooks,
			},
		}

		this.input = ClientPlayerInput.getInstance(this.state)
		NavigationArrow.createInstance()
		PartyArrows.createInstance()

		NengiClient.getInstance().onClose(this.handleDisconnectedFromServer.bind(this))
		NengiClient.getInstance().onConnect(this.handleServerConnectionSuccessful.bind(this))
		NengiClient.getInstance().listen('message::Identity', this.handlePlayerIdentityMessage.bind(this))
		NengiClient.getInstance().listen('create', this.handleServerEntityCreate.bind(this))
		NengiClient.getInstance().listen('update', this.handleServerEntityUpdate.bind(this))
		NengiClient.getInstance().listen('delete', this.handleServerEntityDelete.bind(this))

		NengiClient.getInstance().listen('message::DisconnectReason', this.handleDisconnectReasonMessage.bind(this))
		NengiClient.getInstance().listen('message::FileChanged', this.handleFileChanged.bind(this))
		NengiClient.getInstance().listen('message::SaveFile', this.handleSaveFile.bind(this))
		NengiClient.getInstance().listen('message::Metrics', this.handleMetricsMessage.bind(this))
		NengiClient.getInstance().listen('message::HideEntity', this.handleHideEntity.bind(this))
		NengiClient.getInstance().listen('message::HideWormMessage', this.handleHideWormMessage.bind(this))
		NengiClient.getInstance().listen('message::UnhideEntity', this.handleUnhideEntity.bind(this))
		NengiClient.getInstance().listen('message::UpdateDebugOnloadCommandsMessage', this.handleDebugOnloadCommands.bind(this))
		NengiClient.getInstance().listen('message::PlayerStatChange', this.handlePlayerStatChangeMessage.bind(this))
		NengiClient.getInstance().listen('message::PlayerGearStatChange', this.handlePlayerGearStatChangeMessage.bind(this))
		NengiClient.getInstance().listen('message::PlayerBinaryFlagChange', this.handlePlayerBinaryFlagChangedMessage.bind(this))
		NengiClient.getInstance().listen('message::PlayerDied', this.handlePlayerDied.bind(this))
		NengiClient.getInstance().listen('message::PlayerIgnoreCollider', this.handlePlayerIgnoreCollider.bind(this))
		NengiClient.getInstance().listen('message::PingAtLocation', this.handlePingAtLocationMessage.bind(this))
		NengiClient.getInstance().listen('message::PopUIScreen', this.handlePopUIScreen.bind(this))
		NengiClient.getInstance().listen('message::DismissUIScreen', this.handleDismissUIScreen.bind(this))
		NengiClient.getInstance().listen('message::SkillUsed', this.handleSkillUsed.bind(this))
		NengiClient.getInstance().listen('message::SkillCooldownUpdated', this.handleSkillCooldownUpdated.bind(this))
		NengiClient.getInstance().listen('message::GameMode', this.handleGameModeMessage.bind(this))
		NengiClient.getInstance().listen('message::CreatePfx', this.handleCreatePfx.bind(this))
		NengiClient.getInstance().listen('message::Test', this.handleTest.bind(this))
		NengiClient.getInstance().listen('message::InspectObject', this.handleInspectObject.bind(this))
		NengiClient.getInstance().listen('message::TeleportVisuals', this.handleTeleportVisuals.bind(this))

		NengiClient.getInstance().listen('message::ItemLocked', this.handleItemLocked.bind(this))
		NengiClient.getInstance().listen('message::ItemUnlocked', this.handleItemUnlocked.bind(this))
		NengiClient.getInstance().listen('message::ManyItemsUnlocked', this.handleManyItemsUnlocked.bind(this))
		NengiClient.getInstance().listen('message::PlayerResurrected', this.handlePlayerResurrected.bind(this))

		NengiClient.getInstance().listen('message::GCPause', this.handleGCPause.bind(this))
		NengiClient.getInstance().listen('message::PlayerMovedToBiomeMessage', this.handlePlayerMovedToBiome.bind(this))

		//TODO2: move to appropriate system
		NengiClient.getInstance().listen('message::ProjectileConfigSlot', this.handleProjectileSlotConfig.bind(this))
		NengiClient.getInstance().listen('message::ProjectileConfigId', this.handleProjectileIdConfig.bind(this))
		NengiClient.getInstance().listen('message::WeaponFired', this.handleWeaponFired.bind(this))
		NengiClient.getInstance().listen('message::ProjectileHit', this.handleProjectileHit.bind(this))
		NengiClient.getInstance().listen('message::ProjectileSplash', this.handleProjectileSplash.bind(this))
		NengiClient.getInstance().listen('message::DamageNumber', this.handleDamageNumber.bind(this))

		//TODO2: move to appropriate system
		NengiClient.getInstance().listen('message::EquippedNewGear', this.handlePlayerEquippedNewGear.bind(this))
		NengiClient.getInstance().listen('message::EnemyDied', this.handleEnemyDied.bind(this))
		NengiClient.getInstance().listen('message::PlayAnimation', this.handlePlayAnimation.bind(this))
		NengiClient.getInstance().listen('message::ShakeCamera', this.handleShakeCamera.bind(this))

		NengiClient.getInstance().listen('message::GroundItemDropped', this.handleItemDrop.bind(this))
		NengiClient.getInstance().listen('message::UnlockedMtxMessage', this.handleMtxUnlocked.bind(this))

		// Handle buff/debuff messages
		NengiClient.getInstance().listen('message::BuffUpdate', this.handleBuffUpdate.bind(this))

		NengiClient.getInstance().listen('message::ChatMessage', (msg: ChatMessage) => {
			if (debugConfig.log.chat) {
				logger.debug(msg)
			}
			UI.getInstance().emitEvent('chat/receivedNewMessage', msg)
		})
		NengiClient.getInstance().listen('message::PartyInvitationMessage', (msg: PartyInvitationMessage) => {
			if (debugConfig.log.chat) {
				logger.debug(msg)
			}
			UI.getInstance().emitEvent('chat/receivedPartyInvitation', msg)
		})

		NengiClient.getInstance().listen('message::UpdateCurrencyMessage', (msg: UpdateCurrencyMessage) => {
			if (msg.isScales) {
				UI.getInstance().emitEvent('hud/updateScalesBalance', msg.amount)
			} else {
				UI.getInstance().emitEvent('hud/updateCoinBalance', msg.amount)
			}
		})

		NengiClient.getInstance().listen('message::PartyMessage', handlePartyMessage.bind(this))
		NengiClient.getInstance().listen('message::PartyFlagsMessage', handlePartyFlagsMessage.bind(this))
		NengiClient.getInstance().listen('message::ClearPartyMessage', handleClearPartyMessage.bind(this))

		NengiClient.getInstance().listen('message::BroadcastMessage', (msg: BroadcastMessage) => {
			//logger.debug('BroadcastMessage:', msg.message)
			UI.getInstance().emitEvent('chat/receivedNewBroadcast', msg)
		})

		NengiClient.getInstance().listen('message::AlertMessage', (msg: AlertMessage) => {
			UI.getInstance().emitEvent('hud/receivedAlert', msg)
		})

		NengiClient.getInstance().listen('message::BiomeTransition', (msg: BiomeTransitionMessage) => {
			const { oldBiomeIndex, newBiomeIndex } = msg
			const gameConfigType = gameModeConfig.type
			const biomeList = getBiomeList(gameConfigType)
			Audio.getInstance().playBgm(biomeList[newBiomeIndex].bgm)
		})

		NengiClient.getInstance().listen('message::BiomeBounds', (msg: BiomeBoundsMessage) => {
			const { biomeBounds_min, biomeBounds_max } = msg
			const bounds: Array<{ min: number; max: number }> = []
			for (let i = 0; i < biomeBounds_min.length; ++i) {
				bounds.push({ min: biomeBounds_min[i], max: biomeBounds_max[i] })
			}
			this.renderer.setBiomeBounds(bounds)
		})

		NengiClient.getInstance().listen('message::WorldDifficultyChanged', (msg: WorldDifficultyChangedMessage) => {
			UI.getInstance().emitEvent('hud/changedServerWorldDifficulty', msg.worldDifficulty)
			this.serverWorldDifficulty = msg.worldDifficulty
		})

		NengiClient.getInstance().listen('message::WaterBounds', (msg: WaterBoundsMessage) => {
			this.renderer.setWaterBounds(msg.north, msg.south)
		})

		NengiClient.getInstance().listen('message::WorldBiomeGrid', (msg: WorldBiomeGridMessage) => {
			// only bots use this right now
		})

		NengiClient.getInstance().listen('message::PlayFanfare', (msg: PlayFanfareMessage) => {
			Audio.getInstance().playFanfare(msg.bgm)
		})

		NengiClient.getInstance().listen('message::PlayerStatsMessage', (msg: PlayerStatsMessage) => {
			UI.getInstance().emitEvent('paperdoll/playerStatsUpdated', msg)
		})

		NengiClient.getInstance().listen('message::ItemListedSuccessfully', (msg: any) => {
			UI.getInstance().emitEvent('marketplaceUpdated/itemListedSuccessfully', msg.itemId)
		})

		NengiClient.getInstance().listen('message::ItemSuccessfullyPurchased', (msg: any) => {
			UI.getInstance().emitEvent('marketplaceUpdated/itemPurchasedSuccessfully', { itemId: msg.itemId, listingId: msg.listingId })
		})

		NengiClient.getInstance().listen('message::ItemFailedToList', (msg: any) => {
			UI.getInstance().emitEvent('marketplaceUpdated/failedToListItem', msg.itemId)
		})

		NengiClient.getInstance().listen('message::ItemListingsMetadata', (msg: ItemListingsMetadataMessage) => {
			UI.getInstance().emitAsyncEvent('marketplaceUpdated/receivedItemListingsMetadata', JSON.parse(msg.listings))
		})

		NengiClient.getInstance().listen('message::UserListingsMetadata', (msg: UserListingsMetadataMessage) => {
			UI.getInstance().emitAsyncEvent('marketplaceUpdated/receivedUserListingsMetadata', JSON.parse(msg.listings))
		})

		NengiClient.getInstance().listen('message::PitStatsMessage', (msg: PitStatsMessage) => {
			UI.getInstance().emitEvent('pitOfChances/setItemsTossedForCoins', msg.itemsWithCoins)
		})

		NengiClient.getInstance().listen('message::WormDoneSending', (msg: WormDoneSendingMessage) => {
			UI.getInstance().emitEvent('outpostWormMail/wormDoneSending', {wormId: msg.wormId, biomeIndex: msg.biomeIndex})
		})

		NengiClient.getInstance().listen('message::MarketplaceItemCount', (msg: MarketplaceItemCountMessage) => {
			UI.getInstance().emitEvent('marketplaceUpdated/updateTotalItemCount', msg.marketplacePageCount)
		})

		NengiClient.getInstance().listen('message::weaponAugmentSlotUpdateMessage', (msg: weaponAugmentSlotUpdateMessage) => {
			UI.getInstance().emitEvent('itemComparison/updateAugmentSlot', msg.weaponId)
		})

		NengiClient.getInstance().listen('message::ItemSlotNotification', (msg: ItemSlotNotificationMessage) => {
			if(msg.toggleOutro){
				UI.getInstance().emitAsyncEvent('slotAnimation/updateOutro')
			} else if(msg.disableSlotAnimation) {
				UI.getInstance().emitEvent('slotAnimation/updateSlotAnimations', false)
			} else {
				UI.getInstance().emitEvent('slotAnimation/updateSlotAnimations', true)
			}
			
		})

		NengiClient.getInstance().listen('message::WormHornResult', (msg: WormHornResultMessage) => {
			const uiInst = UI.getInstance()
			uiInst.emitEvent('outpostWormMail/wormDoneSendingNoCheck')
			const userId = uiInst.store.getters['user/userId']

			if (msg.wormSentSuccesfully) {
				uiInst.emitEvent('inGame/setPanelSwitchable', true)
				uiInst.emitEvent('inGame/setActivePanel', 'gameOver')
				uiInst.emitEvent('itemContainers/setUsedWormHorn', true)
				NengiClient.getInstance().sendCommand(new PlayerReputationCommand(userId))
			} else {
				showGenericYesNoUI(
					'Error',
					'An error occurred when using the worm horn.  Would you like to try again?',
					undefined,
					'Retry',
					'Cancel',
					() => {
						//yes
					},
					() => {
						//no
						uiInst.emitEvent('inGame/setPanelSwitchable', true)
						uiInst.emitEvent('inGame/setActivePanel', 'gameOver')
					},
				)
			}
		})

		NengiClient.getInstance().listen('message::PlayerStartShieldRegenMessage', (msg: any) => {
			UI.getInstance().emitAsyncEvent('hud/startShieldRegen', msg)
		})

		NengiClient.getInstance().listen('message::PlayerUpdateShieldMessage', (msg: any) => {
			UI.getInstance().emitEvent('hud/updatedShields', msg)
		})

		NengiClient.getInstance().listen('message::FailedToBuyItemFromMarketplace', (msg: any) => {
			UI.getInstance().emitEvent('marketplaceUpdated/failedToBuyItem', msg.errorCode)
		})

		NengiClient.getInstance().listen('message::SendMinimapUpdateMessage', (msg: SendMinimapUpdateMessage) => {
			UI.getInstance().emitEvent('hud/updatedMinimap', msg)
		})

		NengiClient.getInstance().listen('message::SendItemContainerMessage', (msg: SendItemContainerMessage) => {
			const allItemsDeserialized = []

			for (let i = 0; i < msg.items_slotIndex.length; i++) {
				const itemData = assembleItemFromMessageIndex(msg, i)
				allItemsDeserialized.push(itemData)
			}

			const containerType = msg.containerType //this is so hard to read holy moly
			if (containerType === 'stash') {
				UI.getInstance().emitEvent('itemContainers/stashUpdated', allItemsDeserialized)
			} else if (containerType === 'paperdoll') {
				UI.getInstance().emitEvent('itemContainers/paperdollUpdated', allItemsDeserialized)
			} else if (containerType === 'inventory') {
				UI.getInstance().emitEvent('itemContainers/inventoryUpdated', allItemsDeserialized)
				if (allItemsDeserialized.length > 0) {
					tryShowTutorial(FTUESequence.LootInInventoryGoToOutpost) //first time player picks up loot
				}
			} else if (containerType === 'embedded') {
				UI.getInstance().emitEvent('itemContainers/embeddedUpdated', allItemsDeserialized)
			} else if (containerType === 'wormMail') {
				UI.getInstance().emitEvent('itemContainers/outpostWormMailUpdated', { wormId: msg.containerId, items: allItemsDeserialized })
			} else if (containerType === 'wormDelivery' || containerType === 'worm_delivery') {
				UI.getInstance().emitEvent('itemContainers/wormDeliveryUpdated', allItemsDeserialized)
			} else if (containerType === 'wormMailInFlight') {
				UI.getInstance().emitEvent('itemContainers/wormMailInFlightUpdated', allItemsDeserialized)
			} else if (containerType === 'pitRewards' || containerType === 'pit_rewards') {
				UI.getInstance().emitEvent('itemContainers/pitRewardsUpdated', allItemsDeserialized)
				completeFlagsFromCriteria('hasThrownAnItemIntoThePit')
				const activePanel = UI.getInstance().store.getters['inGame/activePanel']
				if (allItemsDeserialized.length === 0) return

				// Don't pop the rewards panel if they're already there, or if they're on the worm delivery panel
				if (activePanel !== 'pitRewards' && activePanel !== 'wormDelivery') {
					UI.getInstance().emitEvent('inGame/setActivePanel', 'pitRewards')
				}
			} else if (containerType === 'pitStorage') {
				UI.getInstance().emitEvent('itemContainers/pitStorageUpdated', allItemsDeserialized)
			} else if (containerType === 'rewards') {
				UI.getInstance().emitEvent('rewards/rewardsUpdated', { message: msg.containerMessage, items: allItemsDeserialized })
				UI.getInstance().emitAsyncEvent('hud/updateShowEnchantments', true)
			} else if (containerType === 'itemListings') {
				UI.getInstance().emitAsyncEvent('itemContainers/marketplaceListingsUpdated', allItemsDeserialized)
			} else if (containerType === 'furnace') {
				UI.getInstance().emitEvent('itemContainers/furnaceUpdated', allItemsDeserialized)
				UI.getInstance().emitAsyncEvent('furnace/relinkFurnaceData', allItemsDeserialized)

				if (allItemsDeserialized.length > 0) {
					completeFlagsFromCriteria('hasSmeltedAnyItem')
				}
			} else if (containerType === 'enchantments') {
				// Emit event to the enchantment tracker UI
				UI.getInstance().emitEvent('itemContainers/playerEnchantmentUpdate', allItemsDeserialized)
			} else if (containerType === 'inventoryBadgeList') {
				UI.getInstance().emitEvent('itemContainers/addItemtoNewBadgeList', allItemsDeserialized)
			} else if (containerType === 'usersListings') {
				UI.getInstance().emitAsyncEvent('itemContainers/userMarketplaceListingsUpdated', allItemsDeserialized)
			} else if (containerType === 'pendingMarketplace') {
				UI.getInstance().emitEvent('itemContainers/pendingMarketplaceUpdated', allItemsDeserialized)
			} else {
				logger.error('Received an unhandled SendItemContainerMessage:', containerType)
				throw new Error(`Received an unhandled SendItemContainerMessage ${containerType}`)
			}
		})

		NengiClient.getInstance().listen('message::AddItemToContainerMessage', (msg: AddItemToContainerMessage) => {
			const item = assembleItemFromMessage(msg)
			UI.getInstance().emitEvent('itemContainers/addItemToContainer', { item, containerName: msg.containerType })
		})

		NengiClient.getInstance().listen('message::RemoveItemsFromContainerMessage', (msg: RemoveItemsFromContainerMessage) => {
			UI.getInstance().emitEvent('itemContainers/removeItemsFromContainer', { itemIds: msg.item_ids, containerName: msg.container })
		})

		NengiClient.getInstance().listen('message::ItemStackSizeChangedMessage', (msg: ItemStackSizeChangedMessage) => {
			UI.getInstance().emitEvent('itemContainers/updateItemStackSize', msg)
		})

		NengiClient.getInstance().listen('message::SendStoreContentsMessage', (msg: SendStoreContentsMessage) => {
			const deserializedStoreContents = JSON.parse(msg.contents)
			UI.getInstance().emitEvent('generalStore/updateStoreContents', deserializedStoreContents)
		})

		NengiClient.getInstance().listen('message::StashIncreasesMessage', (msg: StashIncreasesMessage) => {
			UI.getInstance().emitEvent('itemContainers/updateStashIncreases', { mtxIncreases: msg.stashMtxIncreases, loginIncreases: msg.stashLoginIncreases, factionIncreases: msg.stashFactionIncreases })
		})

		NengiClient.getInstance().listen('message::NengiViewSizeUpdated', (msg: NengiViewSizeUpdatedMessage) => {
			if (this.state.myEntity) {
				this.state.myEntity.drawDebugClientViewBoxVisual(msg.newWidth, msg.newHeight)
			}
		})

		NengiClient.getInstance().listen('message::AnonymousRegistrationResponse', (msg: AnonymousRegistrationResponseMessage) => {
			if (msg.status === 'error') {
				UI.getInstance().emitEvent('notLoggedIn/registrationResponse', msg)
				// TODO1 handle the error in player-ui and in the registration form.
			} else {
				// TODO1 send an event to the registration form indicating success
				UI.getInstance().emitEvent('notLoggedIn/registrationResponse', msg)
				const authentication = { token: msg.authToken }
				UI.getInstance().emitEvent('user/setUserRegistered', { username: msg.userName, userId: msg.userId, authentication })
				// Update the connection payload so that the player has their new ID on adventure
				// @ts-expect-error
				window.originalConnectionPayload.userType = 'registered'
				// @ts-expect-error
				window.originalConnectionPayload.identifier = msg.authToken
				UI.getInstance().emitAsyncEvent('inGame/closeActivePanel', 'notLoggedIn')
			}
		})

		NengiClient.getInstance().listen('message::FurnaceState', (msg: FurnaceStateMessage) => {
			UI.getInstance().emitAsyncEvent('furnace/updatedFurnaceStateFromServer', { newFurnaceState: msg.state, unixTime: msg.unixTime })
		})

		NengiClient.getInstance().listen('message::GenericError', (msg: GenericErrorMessage) => {
			showGenericInfoPromptUI(msg.title, msg.description)
		})

		NengiClient.getInstance().listen('message::TutorialFlagsMessage', (msg: TutorialFlagsMessage) => {
			const flags = JSON.parse(msg.flagsJson)
			UI.getInstance().emitEvent('ftueManager/loadAllFlags', flags)

			if (flags.bossArenaProximity || flags.poiProximity) {
				UI.getInstance().emitAsyncEvent('hud/updateShowEnchantments', true)
			}
		})

		NengiClient.getInstance().listen('message::LogEntity', (msg: LogEntityMessage) => {
			console.log(`Logging requested entity nid ${msg.nid}:`)
			console.log(msg.entity)
			console.log(msg.history)
		})

		NengiClient.getInstance().listen('message::PlayerIsPurchasing', (msg: PlayerIsPurchasingMessage) => {
			UI.getInstance().emitEvent('generalStore/updatePlayerPurchasing', msg.purchasing)
		})

		NengiClient.getInstance().listen('message::ItemIdentified', this.handleItemIdentified.bind(this))

		NengiClient.getInstance().listen('message::PreviewAugmentsDataMessage', this.handlePreviewAugmentsData.bind(this))

		NengiClient.getInstance().listen('message::PlayerRunEndStatsMessage', (msg: PlayerRunEndStatsMessage) => {
			const userId = UI.getInstance().store.getters['user/userId']
			NengiClient.getInstance().sendCommand(new PlayerReputationCommand(userId))
			UI.getInstance().emitEvent('gameOver/updateRunEndStats', msg)
		})

		NengiClient.getInstance().listen('message::PlayerRunEndDamageTakenMessage', (msg: PlayerRunEndDamageTakenMessage) => {
			UI.getInstance().emitEvent('gameOver/updateRunEndDamageTaken', msg)
		})

		NengiClient.getInstance().listen('message::UpdatedPlayerSettingsMessage', (msg: UpdatedPlayerSettingsMessage) => {
			if (msg.updateStatus === 'ok') {
				showGenericInfoPromptUI('Settings Saved Successfully', ['Settings Saved Successfully'])
			} else {
				showGenericInfoPromptUI('Settings did NOT Save', ['Something went wrong saving your settings'])
			}
		})

		NengiClient.getInstance().listen('message::TriggerPartyDepartureCountdownMessage', (msg: TriggerPartyDepartureCountdownMessage) => {
			countdownToDeparture()
		})

		NengiClient.getInstance().listen('message::UpdateNavArrowDestination', (msg: UpdateNavArrowDestination) => {
			NavigationArrow.getInstance().setDestination(msg.x, msg.y)
		})
		NengiClient.getInstance().listen('message::UpdateNavArrowVisibility', (msg: UpdateNavArrowVisibility) => {
			NavigationArrow.getInstance().setIsShowing(msg.visible)
		})

		NengiClient.getInstance().listen('message::ShowGenericPromptUI', (msg: ShowGenericPromptUIMessage) => {
			// Not sure if this nengi message will end up being used, but here it is, in case it's useful for something
			const description = msg.description.split('\n')
			if (msg.okButtonText === '') {
				msg.okButtonText = null
			}
			if (msg.overridePanelId === '') {
				msg.overridePanelId = null
			}
			showGenericInfoPromptUI(msg.title, description, msg.okButtonText, msg.overridePanelId)
		})

		NengiClient.getInstance().listen('message::UpdateFTUEMessageIndexMessage', (msg: UpdateFTUEMessageIndexMessage) => {
			setProximityMessageVisible(false)
			UI.getInstance().emitAsyncEvent('ftueManager/showTutorialMessage', msg.index)
		})

		NengiClient.getInstance().listen('message::TryShowTutorialMessage', (msg: TryShowTutorialMessage) => {
			tryShowTutorial(msg.index)
		})

		NengiClient.getInstance().listen('message::FinishedPOIMessage', (msg: FinishedPOIMessage) => {
			UI.getInstance().emitAsyncEvent('ftueManager/completeFlagsFrom', 'defeatedPOI')
		})

		NengiClient.getInstance().listen('message::PlayerBeatFinalBossMessage', (msg: PlayerBeatFinalBossMessage) => {
			UI.getInstance().emitEvent('ftueManager/setFinalBossDefeated', true)
		})

		NengiClient.getInstance().listen('message::FurnaceNotCollectingMessage', (msg: FurnaceNotCollectingMessage) => {
			UI.getInstance().emitEvent('furnace/setIsCollecting', false)
		})

		NengiClient.getInstance().listen('message::PlayerBoughtHealingMessage', (msg: PlayerBoughtHealingMessage) => {
			completeFlagsFromCriteria('boughtHealing')
			setProximityMessageVisible(false)
		})

		NengiClient.getInstance().listen('message::DailyLoginInfoMessage', (msg: DailyLoginInfoMessage) => {
			UI.getInstance().emitEvent('dailyRewards/setDailyLoginRewards', msg)
		})

		NengiClient.getInstance().listen('message::DailyLoginClaimResultMessage', (msg: DailyLoginClaimResultMessage) => {
			UI.getInstance().emitEvent('dailyRewards/updateDailyRewardCollected', msg)
		})

		NengiClient.getInstance().listen('message::PartyDepartureMessage', (msg: PartyDepartureMessage) => {
			this.getSentToServer(msg.dns, msg.worldDifficulty)
		})

		NengiClient.getInstance().listen('message::GotExchangeURLMessage', (msg: GotExchangeURLMessage) => {
			UI.getInstance().emitAsyncEvent('mtxStore/gotForteExchangeURL', msg)
		})

		NengiClient.getInstance().listen('message::PurchaseMtxResultMessage', (msg: PurchaseMtxResultMessage) => {
			if (!msg.success) {
				showGenericInfoPromptUI('Purchase Error', [msg.message])
			} else {
				showGenericInfoPromptUI('Success', [msg.message])
			}
			UI.getInstance().emitEvent('mtxStore/setUsingStore', false)
			UI.getInstance().emitAsyncEvent('mtxStore/getMtxOffers')
		})

		NengiClient.getInstance().listen('message::UpdatePartyPositionsMessage', (msg: UpdatePartyPositionsMessage) => {
			PartyArrows.getInstance().updateDestinationPositions(msg.xPositions, msg.yPositions)
		})

		NengiClient.getInstance().listen('message::PartyExitGameMessage', (msg: PartyExitGameMessage) => {
			if (this.exitGameFunction) {
				this.exitGameFunction()
			}
		})

		NengiClient.getInstance().listen('message::ProfileMessage', (msg: ProfileMessage) => {
			const date = new Date()
			const name = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}:${date.getTime()}_profile`
			download(`${name}.cpuprofile`, msg.profileString)

			//@TODO2 move this somewhere to use in other places
			//shamelessly copied from stackoverflow
			function download(filename, text) {
				const pom = document.createElement('a');
				pom.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
				pom.setAttribute('download', filename);
				if (document.createEvent) {
					const event = document.createEvent('MouseEvents');
					event.initEvent('click', true, true);
					pom.dispatchEvent(event);
				}
				else {
					pom.click();
				}
			}
		})

		NengiClient.getInstance().listen('message::FactionSetResultMessage', (msg: FactionSetResultMessage) => {
			UI.getInstance().emitEvent('factions/setIsJoiningFaction', false)
			if (msg.factionSet) {
				UI.getInstance().emitEvent('factions/setAffiliatedFaction', msg.faction)

				if(msg.gotPrize) {
					const prizeId = FACTION_AFFILIATION_PRIZES[msg.faction]
					UI.getInstance().emitEvent('genericRewards/showGenericRewardsModule', {
						claimButtonText: 'OK',
						topText: `<span style="color: #5cbbff;">"Hark! Before you go, you may take this. Show the world your pride."</span>`,
						bottomText: `All items are automatically added to your account.`,
						rewardItems: [{mtxId: prizeId}],
						panelIdentifier: 'factionRewards',
						claimClickedCallback: () => {
							UI.getInstance().emitEvent('cosmetics/unlockedMtx', prizeId)
						}
					})
				} else {
					let factionJoinedMessage

					switch(msg.faction) {
						case FactionShortName.AURUM_ALLIANCE:
							factionJoinedMessage = 'A wise choice.'
							break
						case FactionShortName.ORDER_OF_IRON:
							factionJoinedMessage = 'For the Order!'
							break
						case FactionShortName.SCIONS_OF_DAWN:
							factionJoinedMessage = 'Go forth, and bring glory to Dawn!'
							break
					}

					showGenericInfoPromptUI('Faction Joined!', [factionJoinedMessage], 'OK', 'factionJoined')
				}
			} else {
				showGenericInfoPromptUI('Error', ['Something went wrong, please try again later.'])
			}
		})

		NengiClient.getInstance().listen('message::SendItemModsMessage', (msg: SendItemModsMessage) => {
			if (process.env.NODE_ENV !== 'beta' && process.env.NODE_ENV !== 'loot-prod') {
				const modsDeserialized = []
				for (let i = 0; i < msg.itemModSet.length; i++) {
					const itemData = assembleModSetFromMessageIndex(msg, i)
					modsDeserialized.push(itemData.itemMods)
				}
				const modPoolTypeWithMods = { poolType: msg.poolType, itemModSet: modsDeserialized }
				UI.getInstance().emitEvent('itemTools/setWeaponMods', modPoolTypeWithMods)
			}
		})

		NengiClient.getInstance().listen('message::DumpFileMessage', (msg: DumpFileMessage) => {
			const data = JSON.parse(msg.data)
			data.meta.url = UI.getInstance().store.getters['hud/serverYoureOn']
			data.meta.modeStartCount = modeStartCount
			const fileSaveArgs: any = { filename: 'debugDumpFile.txt', contents: JSON.stringify(data, undefined, 4) }
			saveFileInBrowser(fileSaveArgs)
		})

		NengiClient.getInstance().listen('message::FakeRegisterMessage', (msg: FakeRegisterMessage) => {
			UI.getInstance().emitEvent('user/setUserFakeRegistered')
		})

		NengiClient.getInstance().listen('message::PlayerNotificationMessage', (msg: PlayerNotificationMessage) => {
			UI.getInstance().emitEvent('notifications/addNotification', { subject: msg.subject, id: msg.id })
		})

		NengiClient.getInstance().listen('message::SetFreeListingDateMessage', (msg: SetFreeListingDateMessage) => {
			UI.getInstance().emitEvent('marketplaceUpdated/setLastFreeMarketplaceDate', msg.freeListDate)
		})

		NengiClient.getInstance().listen('message::FactionPrizeClaimResultMessage', (msg: FactionPrizeClaimResultMessage) => {
			UI.getInstance().emitEvent('factions/gotFactionClaimPrizeResult', msg)
		})

		NengiClient.getInstance().listen('message::SendPlayerReputationPointsMessage', (msg: SendPlayerReputationPointsMessage) => {
			UI.getInstance().emitAsyncEvent('factions/setPlayerEndRunFactionPoints', msg)
		})

		NengiClient.getInstance().listen('message::AIDebugStringMessage', (msg: AIDebugStringMessage) => {
			UI.getInstance().emitEvent('setAIDebugString', msg.info)
		})

		if (debugConfig.log.nengiMessages !== NengiLogType.None) {
			addLogToAllMessages(debugConfig.log.nengiMessages)
		}

		window.onresize = debounce(
			() => {
				NengiClient.getInstance().sendCommand(new ClientResizedCommand(getVisibleWorldWidth() + NENGI_VIEW_X_PADDING, getVisibleWorldHeight() + NENGI_VIEW_Y_PADDING))
				//Adding the two lines below solves the following nengi view issue https://sculpin.atlassian.net/browse/SOTI-2893
				this.connectionPayload.visibleWorldWidth = getVisibleWorldWidth() + NENGI_VIEW_X_PADDING
				this.connectionPayload.visibleWorldHeight = getVisibleWorldHeight() + NENGI_VIEW_Y_PADDING
				Renderer.getInstance().bgRenderer?.handleWindowResize(getVisibleWorldWidth(), getVisibleWorldHeight())
				if (this.state.myEntity) {
					this.state.myEntity.handleWindowResize(getVisibleWorldWidth(), getVisibleWorldHeight())
				}
			},
			2500,
			{ leading: true },
		)

		this.sendDebugOnloadCommands()

		this.connectToServer(this.gameMode, this.connectionPayload.userType, this.connectionPayload.identifier, this.connectionPayload.worldDifficulty, this.initialpartyId, region, predeterminedGameAddress)

		return this
	}

	shutdown() {
		UI.shutdown()
		Renderer.shutdown()
		Audio.shutdown()
		Camera.shutdown()
		ClientPlayerInput.shutdown()
		NengiClient.shutdown()

		simpleAnimation_shutdown()
		attachments_shutdown()
		callbacks_shutdown()

		if (this.animationFrameId) {
			window.cancelAnimationFrame(this.animationFrameId)
		}

		clearObjectPoolArray()

		// TODO2: currently state is accessed during reload flow, so keep this around for now
		const state = this.state
		clearObjectRefs(this)
		this.state = state
		clearObjectRefs(this.state)
	}

	sendDebugOnloadCommands() {
		// poll until we have our entity and our ID, then send the message
		const interval = setInterval(() => {
			if (this.state.myId && this.state.myEntity) {
				clearInterval(interval)

				const onloadCommands = localStorage.getItem('debug-onload-commands')
				if (onloadCommands) {
					logger.debug('Sending onload commands: ' + onloadCommands)
					NengiClient.getInstance().sendCommand(new ChatCommand(onloadCommands))
				}
			}
		}, 1000)
	}

	async connectToServer(gameMode, userType, userIdentifier, worldDifficulty: WorldDifficulty, partyId: string, region: string, predeterminedGameAddress: string = '') {
		UI.getInstance().emitEvent('hud/setIsConnectingToServer', true)
		try {
			const { serverUrl, serverWorldDifficulty } = await findGameServer(gameMode, userType, userIdentifier, worldDifficulty, partyId, region, predeterminedGameAddress)

			if (serverUrl) {
				logger.info(`found game server url: ${serverUrl}`)

				const uiInst = UI.getInstance()
				uiInst.emitEvent('hud/joinedServer', serverUrl)

				uiInst.emitEvent('ftueManager/setFinalBossDefeated', false)
				uiInst.emitEvent('hud/setNavigationMenuVisible', false)
				uiInst.emitEvent('inGame/resetEndRun')
				uiInst.emitEvent('itemLocks/unlockAllItems')
				uiInst.emitEvent('mtxStore/setUsingStore', false)
				uiInst.emitEvent('furnace/setIsCollecting', false)
				uiInst.emitEvent('marketplaceUpdated/resetProgressState', false)
				uiInst.emitEvent('itemContainers/setUsedWormHorn', false)
				uiInst.emitEvent('factions/resetTallyAnimation')
				uiInst.emitEvent('outpostWormMail/resetWormSentFrom')
				

				NengiClient.getInstance().connect(serverUrl, this.connectionPayload)

				// TODO This error prompt was added as part of an investigation into players' actual world difficulty not being the same as the one they queued for
				// That issue appears to be fixed, however this error was occasionally appearing, claiming to have placed the player in "undefined" difficulty
				// No actual problems seemed to stem from this, so I'm just taking out the error for now
				// I suspect the cause is polo sending the findGameServer reply before fully allocating the server
				/*if (gameMode === GameModeType.Adventure && worldDifficulty !== serverWorldDifficulty) {
					showGenericInfoPromptUI(
						`Matchmaking Error`,
						[`Unfortunately, a server of your requested difficulty was not available. Please try again later. In the meantime, we found you a place in a ${getWorldDifficultyPrettyString(serverWorldDifficulty)} world.`],
						`OK`, `error`)
				}*/
			} else {
				logger.error(`Failed to find game server`)
				location.reload()
			}
		} catch (e) {
			const errorCode = e.response?.data?.errorCode
			const reqConfigParsed = JSON.parse(e.config?.data)
			const serverType = reqConfigParsed?.gameMode
			const worldTier = reqConfigParsed?.worldTier
			const title = "Matchmaking Error"
			let description

			switch (errorCode) {
				case 'noServersAvailable':
					description = `There are no active game servers. We might be deploying a patch right now; check on our Discord for more information.`
					break;

				case 'noServersAvailableOfGivenGameMode':
					description = `There are no free game servers available of type: ${serverType} for world tier ${worldTier}! Try again in a few minutes.`
					break;

				default:
					description = 'An unknown matchmaking error occurred'
					break;
			}

			UI.getInstance().emitEvent('errors/setErrorDescriptions', {
				title,
				description
			}, { root: true })
			UI.getInstance().emitEvent('errors/setActiveError', undefined, { root: true })
		}
	}

	getSentToServer(dns: string, worldDifficulty: WorldDifficulty) {

		const username = UI.getInstance().store.getters['user/username']
		const userId = UI.getInstance().store.getters['user/userId']
		const partyId = UI.getInstance().store.getters['party/getPartyId']

		// TODO2: Handle the host using biome keys, if we want that
		const biomeIndex = 0

		analyticsEventStartAdventure(worldDifficulty, biomeIndex, username, userId)
		logger.info(`Attempting to travel to world difficulty: ${worldDifficulty}`)

		console.log(`GOING TO ADVENTURE WITH DETAILS: world difficulty ${worldDifficulty}, biome index ${biomeIndex}`)

		endGame()

		// @ts-expect-error TODO2: better connection payload storage in upcoming PR
		const connectionPayload = window.originalConnectionPayload

		connectionPayload.biomeIndex = biomeIndex
		connectionPayload.pressureLoadout = UI.getInstance().store.getters['boatLaunch/pressureLoadout']

		// @ts-expect-error TODO2: store this not on the window
		startGame('adventure', connectionPayload, partyId, dns)
	}

	start() {
		this.previousFrameStart = highResolutionTimestamp()

		const now = highResolutionTimestamp()
		let delta = ((now - this.previousFrameStart) / 1000) * debugConfig.timeScale
		this.previousFrameStart = now

		Time.unclampedDeltaTimeInSeconds = delta
		delta = Math.clamp(delta, 0, MAX_DELTA_TIME)
		Time.clampedDeltaTimeInSeconds = delta

		this.animationFrameId = window.requestAnimationFrame(() => {
			this.update(delta, now)
		})
	}

	update(delta: timeInSeconds, now: timeInMilliseconds): void {
		if (debugConfig.graph.draw) {
			drawGraph()
		}

		Time.timestampOfCurrentFrameStartInMs = now

		Time.timeElapsedSinceStartupInSeconds += delta
		Time.timeElapsedSinceStartupInMs += delta * 1000
		Time.timeElapsedSinceModeStartInSeconds += delta
		Time.timeElapsedSinceModeStartInMs += delta * 1000

		this.debugUpdateFpsEmits(delta, now)

		NengiClient.getInstance().readNetworkAndEmit()

		if (this.state.myEntity) {
			this.input.update(delta)
			updateCamera(this.state.myEntity, delta)
		}

		this.entities.forEach((value, key) => {
			if (value.update) {
				value.update(delta)
			}
		})

		this.interactionPrompts.update(delta)

		callbacks_update(delta, now)
		simpleAnimation_update(delta)
		attachments_update(delta)

		// I want to leave this commented out log for now
		//logger.debug(`systems:  callbacks:${callbacks_numCallbacks()} animations:${simpleAnimation_numAnimations()} attachments:${attachments_numCallbacks()}`)

		// Level up system and therefore experience spawner is nixed; commenting this out but leaving code around in case we want it later
		// if (this.experienceSpawner) {
		// 	this.experienceSpawner.update(delta)
		// }

		this.renderer.update(delta)
		NengiClient.getInstance().update()
		this.input.releaseKeys()

		const nextNow = highResolutionTimestamp()
		let nextDelta = (now - this.previousFrameStart) * 0.001 * debugConfig.timeScale
		Time.timestampOfPreviousFrameStartInMs = this.previousFrameStart
		const prev = this.previousFrameStart
		this.previousFrameStart = now

		Time.unclampedDeltaTimeInSeconds = nextDelta
		nextDelta = Math.clamp(nextDelta, 0, MAX_DELTA_TIME * debugConfig.timeScale)
		Time.clampedDeltaTimeInSeconds = nextDelta

		Time.currentGameFrameNumber++

		if (gameModeConfig.type === GameModeType.Hub) {
			if (!this.checkedHubFogOnce) {
				const nowMoment = moment().utc()
				this.oldHubFogWallEnabled = !(UI.getInstance().store.getters['activeGameWindows/isInAGW'](nowMoment))
				this.checkedHubFogOnce = true
			} else if (Time.currentGameFrameNumber % 200 === 0) {
				const nowMoment = moment().utc()
				this.oldHubFogWallEnabled = !(UI.getInstance().store.getters['activeGameWindows/isInAGW'](nowMoment))
			}

			if (this.oldHubFogWallEnabled) {
				showFogBankAtPos(10000)
			} else {
				hideFogBank()
			}
		}

		this.animationFrameId = window.requestAnimationFrame(() => {
			const newNow = highResolutionTimestamp()
			Time.realUnclampedDeltaTimeInSeconds = (newNow - now) * 0.001
			this.update(nextDelta, nextNow)
		})
	}

	private handleServerEntityUpdate(updatedEntityData: any): void {
		const entity = this.entities.get(updatedEntityData.nid)

		if (debugConfig.log.clientEntityUpdates) {
			logger.info(`handleServerEntityUpdate: ${entity?.name}`, updatedEntityData)
		}

		if (!entity) {
			if (!debugConfig.disableUpdateEntityCrashFix) {
				logger.error(`Failed to update entity with nid: ${updatedEntityData.nid}`)
				return // EARLY RETURN UNTIL WE FIX THIS BUG
			}

			NengiClient.getInstance().sendCommand(new LookupEntityByNidCommand(updatedEntityData.nid))
			logger.error(`Failed to update entity with nid: ${updatedEntityData.nid}`)
		}

		const oldValue = entity[updatedEntityData.prop]

		// Immediately assign the data update to the entity itself
		entity[updatedEntityData.prop] = updatedEntityData.value

		const model = entity.model

		if (model) {
			// Immediately assign the data update to the entities model
			if (Array.isArray(model)) {
				model.forEach((m) => {
					m[updatedEntityData.prop] = updatedEntityData.value
				})
			} else {
				model[updatedEntityData.prop] = updatedEntityData.value
			}
		}

		const debugModel = entity.debugModel

		if (debugModel) {
			// Immediately assign the data update to the entities debug model
			debugModel[updatedEntityData.prop] = updatedEntityData.value
		}

		const entityName: string = entity.protocol.name
		this.runEntityUpdateHooks(entityName, updatedEntityData, entity, oldValue)
	}

	private handleGameModeMessage(msg: GameModeMessage) {
		const uiInst = UI.getInstance()
		uiInst.emitEvent('inGame/gameMode', msg.gameMode)
		if (msg.gameMode === 'adventure') {
			tryShowTutorial(FTUESequence.SecondAdventureRunIntro) //startedSecondRun
		} else {
			const nowMoment = moment().utc()
			// console.log(`AGW: ${uiInst.store.getters['activeGameWindows/isInAGW'](nowMoment)}, hasn't done AGW intro: ${!uiInst.store.getters['ftueManager/getFlag']('hubWorldServersOfflineIntro')}, hasn't done normal intro: ${!uiInst.store.getters['ftueManager/getFlag']('enteredHubWorld')} `)
			if (uiInst.store.getters['activeGameWindows/isInAGW'](nowMoment)) {
				if (!uiInst.store.getters['ftueManager/getFlag']('enteredHubWorld')) {
					tryShowTutorial(FTUESequence.HubWorldIntro) //enteredHubWorld
				} else {
					tryShowTutorial(FTUESequence.PartyPlayIntro)
				}
			} else {
				if (!uiInst.store.getters['ftueManager/getFlag']('hubWorldServersOfflineIntro')) {
					tryShowTutorial(FTUESequence.HubWorldServersOfflineIntro)
					uiInst.emitAsyncEvent('ftueManager/completeFlagsFrom', 'enteredHubWorld')
					uiInst.emitAsyncEvent('ftueManager/completeFlagsFrom', 'openedStashNPC')
					uiInst.emitAsyncEvent('ftueManager/completeFlagsFrom', 'hasEquippedAnAugment')
				}
			}
		}
	}

	private handleHideEntity(msg: HideEntityMessage): void {
		this.handleServerEntityDelete(msg.entityId)
		this.hiddenEntityIDs.push(msg.entityId)
	}

	private handleHideWormMessage(msg: HideWormMessage): void {
		const entityId = msg.wormId
		const entity = this.entities.get(entityId)
		if (entity) {
			this.hiddenWormIDs.push(entityId)
			entity.hideWorm()
		}

		// we sent loot!
		const uiInst = UI.getInstance()
		const sentMailBefore = uiInst.store.getters['ftueManager/getFlag']('openedWormMailNPC')
		if (!sentMailBefore) {
			uiInst.emitAsyncEvent('ftueManager/completeFlagsFrom', 'wormMailSend')
			showGenericInfoPromptUI('Tutorial', DONE_SENDING_MAIL_MESSAGE, 'OKAY', 'tutorial', () => {
				showProximityMessage(DEFAULT_PROX_MESSAGE_TITLE, DONE_WORM_MAIL_SEND_PROX_MESSAGE)
				NengiClient.getInstance().sendCommand(new RequestArrowPositionCommand(ArrowPositionRequestValue.END_RUN))
				NengiClient.getInstance().sendCommand(new LockedIntoOutpostCommand())
			})
		}
	}

	private handleUnhideEntity(msg: UnhideEntityMessage): void {
		this.hiddenEntityIDs.remove(msg.entityId)
	}

	private handleFileChanged(msg: FileChangedMessage): void {
		if (process.env.NODE_ENV !== 'beta' && process.env.NODE_ENV !== 'loot-prod') {
			const data = JSON.parse(msg.jsonContents)
			AssetManager.getInstance().replaceAssetByNameOrAlias(msg.id, data)
			Renderer.getInstance().onFileChange(msg.id, data)
		}
	}

	private handleSaveFile(msg: SaveFileMessage): void {
		if (process.env.NODE_ENV !== 'beta' && process.env.NODE_ENV !== 'loot-prod') {
			saveFileInBrowser(msg)
		}
	}

	private handleMetricsMessage(msg: MetricMessage): void {
		if (process.env.NODE_ENV !== 'beta' && process.env.NODE_ENV !== 'loot-prod') {
			logData('client.frameTimeMs', Time.unclampedDeltaTimeInSeconds * 1000)
			for (let i = 0; i < msg.metricNames.length; i++) {
				const name = msg.metricNames[i];
				const value = msg.metricValues[i];
				logData(name, value)
			}
		}
	}

	private handleCreatePfx(msg: CreatePfxOneOffMessage): void {
		if (Time.realUnclampedDeltaTimeInSeconds > IGNORE_OLD_PFX_SECONDS) {
			return
		}

		if (msg.ownerNid >= 0) {
			const isMyPfx = msg.ownerNid === this.state.myId
			const alpha = isMyPfx ? 1 : NONLOCAL_PLAYER_ALPHA
			const foreground = isMyPfx ? msg.foreground : false
			this.renderer.addOneOffEffect(msg.pfxName, msg.x, msg.y, msg.y + msg.zOffset, msg.scale, msg.duration, false, foreground, alpha)
		} else {
			this.renderer.addOneOffEffect(msg.pfxName, msg.x, msg.y, msg.y + msg.zOffset, msg.scale, msg.duration, false)
		}
	}

	private handleTest(msg: TestMessage): void {
		switch (msg.testName) {
			case 'spawn-all-pfx':
				Renderer.getInstance().debugSpawnAllPfx(this.state.myEntity)
				break
		}
	}

	private handleTeleportVisuals(msg: TeleportVisualsMessage) {
		const entity = this.entities.get(msg.entityId) as ClientPlayer | ClientEnemy
		if (entity) {
			createTeleportFilter(entity)
		}
	}

	private handleInspectObject(msg: InspectObjectMessage) {
		const obj = JSON.parse(msg.objectJson)
		const enumData = JSON.parse(msg.enumJson)
		DevToolsManager.getInstance().setDebugObject({ obj }, msg.objectName, enumData)
		UI.getInstance().toggleDevTools()
	}

	private handleServerEntityCreate(entityData: any): void {
		const entityName = entityData.protocol.name

		if (debugConfig.log.clientEntityCreates) {
			logger.info(`handleServerEntityCreate: ${entityName}-${entityData.enemyConfig || entityData.propId}`, entityData)
		}

		// This entity is flagged as hidden, don't re-create it
		if (this.hiddenEntityIDs.includes(entityData.nid)) {
			return
		}

		if (this.entityHookMappings[entityName]) {
			let entity

			const constructor = this.entityHookMappings[entityName].constructor
			if (constructor) {
				entityData.isMyEntity = entityData.nid === this.state.myId
				entityData.localPlayerData = this.localPlayerData
				entity = new constructor(entityData)
			}

			const pool: ObjectPool = this.entityHookMappings[entityName].pool
			if (pool) {
				entity = pool.alloc(entityData)
			}

			if (entity?.colliders) {
				addClientCollidable(entity)
			}

			const registrationFn = this.entityHookMappings[entityName].registrationFn

			if (registrationFn) {
				// Force the singleton lookup so we don't have to worry about initialization order
				const regFun = registrationFn()
				regFun(entity)
			}

			if (entity) {
				entity.nid = entityData.nid
				entity.protocol = entityData.protocol
				//TODO2: stop using Object.assign
				Object.assign(entity, entityData)
				this.entities.set(entity.nid, entity)

				if (this.state.myId === entity.nid) {
					this.state.myEntity = entity

					if (entity.createAmbience) {
						entity.createAmbience()
					}

					for (const m of debugConfig.chatMessagesOnSpawn) {
						NengiClient.getInstance().sendCommand(new ChatCommand(m))
					}
				}

				const model = entity.model
				if (model) {
					//TODO2: stop using Object.assign and potentially find a better way to handle scale
					//Object.assign(model, entityData)
					const models = Array.isArray(model) ? model : [model]
					models.forEach((m) => {
						for (const prop of Object.keys(entityData)) {
							if (prop !== 'scale') {
								m[prop] = entityData[prop]
							}
						}
					})
				} else {
					if (!clientConfig.renderDebugVisuals && !entity.exemptFromModelCheck) {
						logger.info(`No 'model' found for created entity ${entityName} - did you forget to set up the model so that your entity would render?`)
					}
				}

				const debugModel = entity.debugModel
				if (debugModel) {
					//TODO2: stop using Object.assign
					//Object.assign(entity.debugModel, entityData)
					for (const prop of Object.keys(entityData)) {
						if (prop !== 'scale') {
							entity.debugModel[prop] = entityData[prop]
						}
					}
				} else {
					if (clientConfig.renderDebugVisuals && !entity.exemptFromModelCheck) {
						logger.info(`No 'debugModel' found for created entity ${entityName} - did you forget to set up the debug model so that your entity would render?`)
					}
				}

				this.runEntityCreateHooks(entityName, entityData, entity)

				if (entity.config?.appearance?.spawnAnim) {
					playAnimation(entity.riggedModel, AnimationTrack.SPAWN)
				}

				this.state.myEntity?.handleEntityCreate(entity)
			} else {
				logger.info(`Got create event for an entity type (${entityName}) that has no constructor hook configured. Did you forget to the constructor to the GameClients entityHookMappings?`, this.entityHookMappings)
			}
		} else {
			logger.info(`Got create event for an entity type (${entityName}) that has no constructor hook configured. Did you forget to the constructor to the GameClients entityHookMappings?`, this.entityHookMappings)
		}
	}

	private handleGCPause(message: GCPause) {
		if (debugConfig.graph.gc) {
			logData(`server.gc.${message.type}`, message.duration)
		}

		if (message.type === 'major') {
			UI.getInstance().emitEvent('hud/setGCPause', message)
		}
	}

	private handleItemLocked(message: ItemLockedMessage) {
		UI.getInstance().emitEvent('itemLocks/lockItem', message.itemId)
	}

	private handleItemUnlocked(message: ItemUnlockedMessage) {
		UI.getInstance().emitEvent('itemLocks/unlockItem', message.itemId)
	}

	private handleManyItemsUnlocked(message: ManyItemsUnlockedMessage) {
		UI.getInstance().emitEvent('itemLocks/unlockManyItems', message.itemIds)
	}

	private handleItemIdentified(message: ItemIdentifiedMessage) {
		UI.getInstance().emitEvent('identify/setIdentifiedItem', { itemId: message.itemId, identifiedFromDelivery: message.identifiedFromDelivery })
		UI.getInstance().emitEvent('identify/identificationComplete')
		completeFlagsFromCriteria('hasIdentifiedAnItem')
	}

	private handlePreviewAugmentsData(message: PreviewAugmentsDataMessage) {
		UI.getInstance().emitEvent('augmentationStation/setPreviewDetails', { itemId: message.itemId, tooltipData: message.tooltipData })
	}

	private handleServerEntityDelete(entityId: nengiId, ignoreHiddenIds: boolean = false): void {
		// This entity was already deleted when it was flagged as hidden
		if (!ignoreHiddenIds && this.hiddenEntityIDs.includes(entityId)) {
			return
		}

		const entity = this.entities.get(entityId)
		if (entity) {
			removeClientCollidable(entity)

			this.state.myEntity?.handleEntityDestroy(entity)

			if (debugConfig.log.clientEntityDeletes) {
				logger.info(`handleServerEntityDelete: ${entity.name}`)
			}

			if (entity.onDelete) {
				entity.onDelete()
			}

			if (entity.model) {
				const parent = entity.model.parent
				if (parent) {
					parent.removeChild(entity.model)
					parent.removeFromScene(entity.model)
				}
				if (entity.model instanceof RiggedSpineModel) {
					const rsm = entity.model as RiggedSpineModel
					rsm.returnToPoolIfPooled()
				}
				entity.model = null
			}

			if (entity.debugModel) {
				const debugModel = entity.debugModel as PIXI.Container
				const removed = debugModel.parent.removeChild(debugModel)
				console.assert(removed, `debug model of entity ${entity.constructor.name} was not removed`)
				entity.debugModel = null
			}

			const instancedSprite: InstancedSprite = entity.instancedSprite
			if (instancedSprite) {
				this.renderer.mgRenderer.removeFromScene(instancedSprite)
				entity.instancedSprite = null
			}

			const instancedSprites: InstancedSprite[] = entity.instancedSprites
			if (instancedSprites) {
				instancedSprites.forEach((is) => {
					this.renderer.mgRenderer.removeFromScene(is)
				})
				entity.instancedSprites = null
			}

			const sprite = entity.sprite
			if (sprite) {
				this.renderer.fgRenderer.removePixiObjectFromScene(sprite)
				entity.sprite = null
			}

			this.renderer.unregisterProjectile(entityId)
			this.renderer.unregisterBeam(entityId)
			this.entities.delete(entityId)
			const pool: ObjectPool = this.entityHookMappings[entity.protocol.name]?.pool
			if (pool) {
				pool.free(entity)
			} else if (entity.cleanup) {
				entity.cleanup()
			}
		} else {
			logger.debug('Got a delete event for an entity doesnt exist on your client - probably a bug, nid:', entityId)
		}
	}

	private handleDebugOnloadCommands(message: UpdateDebugOnloadCommandsMessage) {
		switch (message.commandOp) {
			case CommandOp.Update:
				logger.debug('setting onload commands to', message.commandString)
				localStorage.setItem('debug-onload-commands', message.commandString)
				break
			case CommandOp.Append: {
				logger.debug('appending onload commands:', message.commandString)
				let onload = localStorage.getItem('debug-onload-commands')
				onload += message.commandString
				logger.debug(onload)
				localStorage.setItem('debug-onload-commands', onload)
				break
			}
			case CommandOp.Print: {
				const onload = localStorage.getItem('debug-onload-commands')
				logger.debug(onload)
				NengiClient.getInstance().sendCommand(new ChatCommand('commands:' + onload))
				break
			}
			default:
				break
		}
	}

	private handlePingAtLocationMessage(msg: PingAtLocationMessage) {
		this.renderer.addOneOffEffect('hit-ice', msg.x, msg.y, msg.y + 100, 3, 2.5, false, true, 0.8)
		spawnFloatingText(msg.pingerName, msg.x, msg.y, DamageNumberStyle.Critical)
		Audio.getInstance().playSfx('UI_Dialog_Major_Open')
	}

	private handlePlayerStatChangeMessage(statChange: PlayerStatChangeMessage) {
		const statString = StatTypeToPropName[statChange.statType]
		this.localPlayerData.stats.clearAllStatBonusesOfStat(statString)
		this.localPlayerData.stats.addStatBonusesFromString(statString, statChange.value)
	}

	private handlePlayerGearStatChangeMessage(statChange: PlayerGearStatChangeMessage) {
		const gearStats = this.localPlayerData.gearSlotstats
		gearStats[statChange.slot] = gearStats[statChange.slot] || {}
		gearStats[statChange.slot][statChange.statType] = statChange.value
	}

	private handlePlayerBinaryFlagChangedMessage(flagMessage: PlayerBinaryFlagChangedMessage) {
		this.localPlayerData.binaryFlagMap = new Set(flagMessage.flags)
	}

	private handlePlayerDied(died: PlayerDiedMessage) {
		const player = this.entities.get(died.entityId) as ClientPlayer
		if (player) {
			const isMyEntity = this.state.myEntity === player
			if (isMyEntity) {
				const uiInst = UI.getInstance()
				uiInst.emitEvent('inGame/setPanelSwitchable', true) // screw whatever you are doing, you died!
				const activePanel = uiInst.store.getters['inGame/activePanel']
				if (activePanel !== 'runEnd' && activePanel !== 'worldTierComplete') {
					const hasWormHorn = uiInst.store.getters['itemContainers/hasWormHorn']
					const hasInventory = !uiInst.store.getters['itemContainers/containerIsEmpty']('inventory')
					const usedWormHorn = uiInst.store.getters['itemContainers/usedWormHorn']
					if (hasWormHorn && hasInventory && !usedWormHorn) {
						uiInst.emitEvent('outpostWormMail/toggleWormHornPrompt')
						uiInst.emitEvent('inGame/setActivePanel', 'outpostWormMail')
					} else {
						showGenericInfoPromptUI('Attention!', ['Any unsent loot left in Worm Mail has been lost.'], 'Dismiss', 'wormMail')
						uiInst.emitEvent('inGame/setActivePanel', 'gameOver')
					}
					UI.getInstance().emitEvent('inGame/triggerGhostMode')
					Renderer.getInstance().addGhostFilter()
				}
			}
			player.die(isMyEntity)
		}
	}

	private handlePlayerIgnoreCollider(message: PlayerIgnoreColliderMessage) {
		this.localPlayerData.ignoreColliders.push(message.colliderId)
	}

	private handlePlayerResurrected(message: PlayerResurrected) {
		const entity = this.entities.get(message.entityId)
		if (entity instanceof ClientPlayer) {
			entity.handlePlayerResurrectedMessage(message)
			if (this.state.myEntity === entity) {
				UI.getInstance().emitEvent('inGame/triggerPlayerRes')
				Renderer.getInstance().removeGhostFilter()
			}
		}
	}

	static playSkillUsedSound(skillId: ModCategory) {
		switch (skillId) {
			case ModCategory.SKILL_DODGE_ROLL:
				Audio.getInstance().playSfx('SFX_Player_Dodge')
				break
			case ModCategory.SKILL_MOVEMENT_SPEED_BOOST:
				Audio.getInstance().playSfx('SFX_Player_Area_Buff')
				break
			case ModCategory.SKILL_ATTACK_SPEED_BOOST:
				Audio.getInstance().playSfx('SFX_Player_Speed_Buff')
				break
			case ModCategory.SKILL_TUMBLE_ROLL:
				Audio.getInstance().playSfx('SFX_Player_Roll')
				break
			default:
			// Skill doesn't have an associated sound.
		}
	}

	private handleSkillUsed(skillUsed: SkillUsedMessage): void {
		const player = this.entities.get(skillUsed.entityId) as ClientPlayer
		if (player) {
			if (!player.isLocalPlayer || !debugConfig.csp) {
				player.handleSkillUsed(skillUsed)
			}
		}
	}

	private handleSkillCooldownUpdated(message: SkillCooldownUpdatedMessage): void {
		const player = this.entities.get(message.entityId) as ClientPlayer
		if (player && player.isLocalPlayer) {
			player.handleSkillCooldownUpdated(message)
		}
	}

	private handleEnemyDied(died: EnemyDiedMessage) {
		const enemy = this.entities.get(died.entityId) as ClientEnemy
		if (enemy) {
			enemy.die(died.playDeathPfx)
		}
	}

	private handlePlayerEquippedNewGear(message: EquippedNewGearMessage) {
		this.localPlayerData.gearSlots[message.slot] = message.skillType
		UI.getInstance().emitEvent('hud/updatedGearSkill', message)
	}

	private handleMtxUnlocked(message: UnlockedMtxMessage) {
		UI.getInstance().emitEvent('cosmetics/unlockedMtx', message.id)
	}

	private runEntityUpdateHooks(entityName, entityUpdateEvent, entity, oldValue) {
		const hooks = this.entityHookMappings[entityName].hooks
		if (hooks) {
			const propThatChanged = entityUpdateEvent.prop
			const newValue = entityUpdateEvent.value
			if (hooks[propThatChanged]) {
				hooks[propThatChanged](entity, newValue, this.state.myEntity === entity, oldValue)
			}
		} else {
			logger.info(`Got a hook event for an entity type (${entityName}) that has no hooks configured. Did you forget to add your hooks to the game clients entityHookMappings ?`)
		}
	}

	private runEntityCreateHooks(entityName, entityCreateData, entity) {
		const hooks = this.entityHookMappings[entityName].hooks
		if (hooks) {
			for (const prop in entityCreateData) {
				if (entityCreateData.hasOwnProperty(prop)) {
					if (hooks[prop]) {
						hooks[prop](entity, entityCreateData[prop], this.state.myEntity === entity)
					}
				}
			}
		} else {
			logger.info(`Got a hook event for an entity type (${entityName}) that has no hooks configured. Did you forget to add your hooks to the game clients entityHookMappings ?`)
		}
	}

	private handleServerConnectionSuccessful(res: any): void {
		if (res.accepted) {
			if (process.env.NODE_ENV !== 'beta' && process.env.NODE_ENV !== 'loot-prod') {
				logger.info('[STARTUP] Connected to nengi server with response:', res)
			} else {
				logger.info('[STARTUP] Connected to nengi server')
			}
			this.isConnected = true
			const renderer: Renderer = this.renderer as Renderer
			const responseData: ClientConnectResponse = JSON.parse(res.text)

			this.localPlayerData = new LocalPlayerData()

			renderer.setWorldSize(responseData.worldWidth, responseData.minWorldWidth, responseData.worldHeight, responseData.chunkSize, responseData.chunksPerRow, responseData.chunksPerColumn)

			this.serverWorldDifficulty = responseData.serverWorldDifficulty

			const uiInst = UI.getInstance()
			uiInst.emitEvent('hud/changedServerWorldDifficulty', responseData.serverWorldDifficulty)
			uiInst.emitEvent('hud/resetHudBiome')
			uiInst.emitEvent('cosmetics/setOwnedCosmetics', responseData.skinOwnership)

			uiInst.emitAsyncEvent('user/getAllTimeMetrics')
			uiInst.emitAsyncEvent('user/getSeasonMetrics')
			uiInst.emitAsyncEvent('ftueManager/onReload')
			uiInst.emitEvent('hud/setIsConnectedToServer', true)
			uiInst.emitEvent('hud/setWorldSeed', responseData.worldSeed)
			uiInst.emitEvent('activeGameWindows/setActiveGameWindows', responseData.activeGameWindows)
			uiInst.emitEvent('notifications/clearNotifications')
			uiInst.emitEvent('factions/setLastWinningFaction', responseData.lastWinningFaction)

			if(uiInst.store.getters['party/failedToDeepLinkToParty']) {
				const alreadyPartied = uiInst.store.getters['party/failedDeepLinkBecauseAlreadyPartied']
				const firstMessage = alreadyPartied ? 'Oops! You are already in a different party.' : 'Oops! It looks like the party is full OR the party no longer exists.'
				const doneFirstTutorialPhase = uiInst.store.getters['ftueManager/getFlag']('openedWormMailNPC')
				if(doneFirstTutorialPhase) {
					showGenericInfoPromptUI('Party Error', [firstMessage], 'DISMISS', 'party', () => {
						uiInst.emitEvent('party/setFailedToJoinDeepLink', { failed: false })
						tryShowTutorial(FTUESequence.FirstAdventureRunIntro) //startedFirstRunControls
						uiInst.emitAsyncEvent('factions/checkShowFactionRewardIntro')
					})
				} else {
					showGenericYesNoUI('Party Error', [firstMessage,
						'We see that this is your first time playing. Please feel free to stick around the adventure world for info on how to play. If not, feel free to skip the tutorial and explore on your own!'],
						undefined, 'TELL ME HOW TO PLAY!', 'SKIP TUTORIAL',
						() => {
							// Yes
							tryShowTutorial(FTUESequence.FirstAdventureRunIntro) //startedFirstRunControls
							uiInst.emitEvent('party/setFailedToJoinDeepLink', { failed: false })
							uiInst.emitAsyncEvent('factions/checkShowFactionRewardIntro')
						}, () => {
							// No tutorial
							uiInst.emitEvent('party/setFailedToJoinDeepLink', { failed: false })
							uiInst.emitAsyncEvent('ftueManager/clearAllFlags')
						}, undefined, undefined, undefined, undefined, 'party'
					)
				}
				
			} else {
				tryShowTutorial(FTUESequence.FirstAdventureRunIntro) //startedFirstRunControls
				uiInst.emitAsyncEvent('factions/checkShowFactionRewardIntro')
			}

			this.start()
		} else {
			this.handleDisconnectedFromServer(res.text)
		}
	}

	private handlePlayerIdentityMessage(message: Identity): void {
		logger.info('[STARTUP] Got identity:', message.entityId)
		this.state.myId = message.entityId
		UI.getInstance().setUIMode('in-game')
	}

	private handlePopUIScreen(message: PopUIScreen): void {
		//Pop = show?? you guys are crazy
		const uiInst = UI.getInstance()

		const userId = uiInst.store.getters['user/userId']

		if (this.hiddenEntityIDs.includes(message.npcId) || this.hiddenWormIDs.includes(message.npcId)) {
			return
		}

		const screen = message.screen

		//logger.info('Got screen pop msg:', message.screen, message.npcId, message.poiId)
		const npc = this.entities.get(message.npcId) as ClientNPC
		if (npc) {
			npc.greeting()
		}
		Audio.getInstance().playSfx('UI_Menu_Open_Major')

		//boy it would be great if these were consts somewhere
		if (screen === 'enterArena' || screen === 'leaveArena') {
			const entering = screen === 'enterArena'
			const title = entering ? 'Enter Arena?' : 'Leave Arena?'
			const yesButtonText = entering ? 'Enter Arena' : 'Leave Arena'
			let description
			let yesButtonColor = 'default'
			const noButtonColor = 'default'

			if (entering) {
				yesButtonColor = 'destroy'
				if (npc && npc.bossCredit === BossCreditState.NO_CREDIT) {
					description = ['The boss is already majorly damaged. You will NOT receive completion credit, but you may receive loot.', '&nbsp', 'Enter the arena to fight the boss. You will not be able to exit until the fight is ended.', 'Proceed?']
				} else {
					description = ['Enter the arena to fight the boss. You will not be able to exit until the fight is ended.', 'Proceed?']
				}
			} else {
				description = ['Exit Arena']
			}

			uiInst.emitAsyncEvent('genericYesNo/showMenu', {
				title,
				description,
				noButtonText: 'Stay Put',
				yesButtonText,
				yesButtonColor,
				noButtonColor,
				yesCallback: () => {
					const messageParam = JSON.parse(message.param) as GateIdentifier
					NengiClient.getInstance().sendCommand(new ArenaTeleportCommand(messageParam.biomeIdx, messageParam.gateIdx, 1))
					NengiClient.getInstance().sendCommand(new DismissNPCUI(message.npcId))
				},
				noCallback: () => {
					NengiClient.getInstance().sendCommand(new DismissNPCUI(message.npcId))
				},
			})

			return
		} else if (screen === 'endOfWorld') {
			if(UI.getInstance().store.getters['user/userType'] === 'anonymous') {
				UI.getInstance().emitEvent('inGame/setActivePanel', 'notLoggedIn')
				return
			}

			const myEntity = this.state.myEntity

			let desc = 'You can end your run now and advance to the next world tier. Do you want to end your run?'
			if (myEntity.activeWorldTier >= PROGRESSION_MAX_TIER_FOR_BETA) {
				desc = `You have completed this Soul Cycle. This is the final Cycle that has currently been discovered by adventurers. Do you want to end your run?`
			}

		uiInst.emitEvent('inGame/setActivePanel', 'biomeKey')
		uiInst.emitEvent('inGame/updateNextPanel', screen)
		} else if (screen === 'outpostWormMail' || screen === 'endRun') {
			const poi = this.entities.get(message.poiId) as ClientPOI
			if (poi) {
				const wormAvailable = !this.hiddenWormIDs.includes(poi.wormMailId)
				if (message.screen === 'outpostWormMail' && wormAvailable) {
					uiInst.emitEvent('outpostWormMail/interactingWithWormId', message.npcId)
					uiInst.emitEvent('inGame/showNPCPane', { screen: message.screen, npcId: message.npcId })
				} else if (message.screen === 'endRun') {
					uiInst.emitEvent('endRun/nearbyWormIsAvailable', wormAvailable)

					const ftueGetFlag = uiInst.store.getters['ftueManager/getFlag']
					const doneWormMailTut = ftueGetFlag('openedWormMailNPC')
					tryShowTutorial(FTUESequence.EndRunGuyPriorToWormMail)
					if (doneWormMailTut) {
						uiInst.emitEvent('inGame/setActivePanel', 'biomeKey')
						uiInst.emitEvent('inGame/updateNextPanel', screen)
					}
				}
			} else {
				logger.error('No POI found for Worm Mail EndRun NPC')
			}

			return
		} else if (screen === 'generalStore' || screen === 'generalStoreUpdated' || screen === 'outpostStore') {
			uiInst.emitEvent('generalStore/interactingWithPOIId', message.poiId)
			uiInst.emitEvent('inGame/setActivePanel', screen)

			return
		} else if (screen === 'weatherMan') {
			
			const now = uiInst.store.getters['time/nowMoment']
			if (uiInst.store.getters['activeGameWindows/isInAGW'](now)) {
				showGenericInfoPromptUI('Slightly calm ramblings', [`Storm's lifted. She's like the glass out there now, b'y. Best get goin' -- yer burnin' daylight!`], 'Huh?', 'weather')
			} else {
				showGenericInfoPromptUI('Incomprehensible ramblings', [`There's some chop out there, wa? Ye b'ys're best off stayin' where yer at til she clears up.`], 'Huh?!', 'weather')
			}
		} else if (screen === 'aurumRecruit' || screen === 'ironRecruit' || screen === 'dawnRecruit') {
			uiInst.emitAsyncEvent('factions/setCurrentRecruitPanel', (screen.substring(0, screen.length - 7)))
			uiInst.emitEvent('inGame/showNPCPane', { screen: 'factionAffil', npcId: message.npcId })
		} else {
			// Reset identify screen state
			// Clear items from pit staging area
			uiInst.emitEvent('pitOfChances/clearPitItems')
			uiInst.emitEvent('identify/resetState')
			uiInst.emitEvent('inGame/showNPCPane', { screen, npcId: message.npcId })
		}
	}

	private handleDismissUIScreen(message: DismissUIScreen): void {
		//logger.info('Got screen dismiss msg:', message.screen, message.npcId)
		if (!this.hiddenEntityIDs.includes(message.npcId) && !this.hiddenWormIDs.includes(message.npcId)) {
			const npc = this.entities.get(message.npcId) as ClientNPC
			if (npc) {
				npc.valediction()
			}
			if (message.screen === 'outpostWormMail') {
				const wormBeingInteractedWith = UI.getInstance().store.getters['outpostWormMail/wormCurrentlyBeingInteractedWith']
				const hasWormItems = UI.getInstance().store.getters['itemContainers/containerIsEmpty']('outpostWormMail', wormBeingInteractedWith)
				if (!hasWormItems) {
					showGenericInfoPromptUI('Attention!', ['Remember: If you die or bail, any unsent loot will be lost.'])
				}
			}
			UI.getInstance().emitAsyncEvent('inGame/closeActivePanel', message.screen)
			UI.getInstance().emitEvent('genericYesNo/closeActiveYesNoPanel', message.screen)
		}
	}

	private handleDisconnectReasonMessage(message: DisconnectReasonMessage) {
		logger.info(`Received disconnect reason: ${message.message}`)
		this.disconnectReason = message.message
	}

	private handleDisconnectedFromServer(reason: any): void {
		logger.error('Disconnected from nengi server with reason', reason)
		UI.getInstance().emitEvent('hud/setIsConnectedToServer', false)

		if (UI.getInstance().store.getters.currentUIMode !== 'loading') {
			if (debugConfig.reloadOnDisconnect) {
				location.reload()
				return
			}
			let reasonString = this.disconnectReason || 'unknown'
			if (reason.code) {
				switch (reason.code) {
					case 1000:
						reasonString = 'AFK'
						break
				}
			}

			showGenericYesNoUI('Disconnected', `You have been disconnected for reason: ${reasonString}`, '', 'Reload Page', 'I want a screenshot', () => {
				location.reload()
			})
		} else {
			// Disconnected while loading, at least reload the page so that people aren't stuck there waiting for it forever
			if (!this.state.myId) {
				// If we already have an ID, then we're probably switching between hub and adventure; don't want to throw a reload in there
				// location.reload()

				if (reason === 'invalidBiomeKey') {
					UI.getInstance().emitEvent('mainMenu/showStartupError', 'Biome keys are currently unavailable, sorry about that.  Please reload the page.')
				}
			}
		}
	}

	private handleItemDrop(message: GroundItemDropMessage): void {
		switch (message.rarity) {
			// Don't play for common and uncommon
			case ItemRarity.COMMON:
				break
			case ItemRarity.UNCOMMON:
				break
			case ItemRarity.RARE:
				Audio.getInstance().playSfx('SFX_Item_Drop_Default')
				break
			case ItemRarity.EPIC:
				Audio.getInstance().playSfx('SFX_Item_Drop_Epic')
				break
			case ItemRarity.LEGENDARY:
				Audio.getInstance().playSfx('SFX_Item_Drop_Legendary')
				break
			case ItemRarity.ASTRONOMICAL:
				Audio.getInstance().playSfx('SFX_Item_Drop_Astronomical')
				break
			case ItemRarity.CHAOS:
				// We probably don't see chaos but handle it anyways
				Audio.getInstance().playSfx('SFX_Item_Drop_Astronomical')
				break
			default: {
				logger.error('Received invalid drop rarity')
			}
		}
	}

	private handleWeaponFired(message: WeaponFiredMessage) {
		if (Time.realUnclampedDeltaTimeInSeconds > IGNORE_OLD_PFX_SECONDS) {
			return
		}

		const entity = this.entities.get(message.owningEntityId)
		if (entity != null) {
			entity.handleWeaponFiredMessage(message, this.state.myId === message.owningEntityId)
		}
		this.renderer.handleWeaponFiredMessage(message, entity)
	}

	private handleBuffUpdate(message: BuffUpdateMessage) {
		const localizedBuffText = getLocalizedBuffText(message.buffIdentifier)
		const type = buffType(message.buffIdentifier)
		const appliedAtTime = parseInt(message.appliedAtTime, 10)
		const expiresAtTime = parseInt(message.expiresAtTime, 10)

		switch (message.updateType) {
			case 'create':
				UI.getInstance().emitEvent('buffAndDebuff/addStatusEffectToState', {
					statusEffect: {
						name: localizedBuffText.name,
						effect: localizedBuffText.effect,
						iconName: getBuffIconByIdentifier(message.buffIdentifier),
						// TODO2 rename this key
						iconBackgroundName: type,
						appliedAtTime,
						expiresAtTime,
						stacks: message.stacks,
					},
					statusEffectId: message.id,
					type,
				})
				break
			case 'remove':
				UI.getInstance().emitEvent('buffAndDebuff/removeStatusEffect', { id: message.id, type })
				break
			case 'update':
				UI.getInstance().emitEvent('buffAndDebuff/updateStatusEffect', {
					statusEffect: {
						name: localizedBuffText.name,
						effect: localizedBuffText.effect,
						iconName: getBuffIconByIdentifier(message.buffIdentifier),
						// TODO2 rename this key
						iconBackgroundName: type,
						appliedAtTime,
						expiresAtTime,
						stacks: message.stacks,
					},
					statusEffectId: message.id,
					type,
				})
				break
		}
	}

	private handleDamageNumber(message: DamageNumberMessage) {
		if (Time.realUnclampedDeltaTimeInSeconds > IGNORE_OLD_PFX_SECONDS) {
			return
		}

		const hitEntity = this.entities.get(message.hitEntityId)
		if (hitEntity) {
			spawnDamageNumber(message.effectAmount, message.x, message.y, message.damageNumberStyle)
		}
	}

	private handleProjectileSlotConfig(message: ProjectileConfigSlotMessage) {
		const config = JSON.parse(message.config) as ProjectileConfig
		this.localPlayerData.weaponSlots[message.weaponSlot] = config
	}

	private handleProjectileIdConfig(message: ProjectileConfigIdMessage) {
		const config = JSON.parse(message.config) as ProjectileConfig
		projectileRegistry_clientAddConfig(config, message.id)
	}

	private handleProjectileHit(message: ProjectileHitMessage) {
		if (Time.realUnclampedDeltaTimeInSeconds > IGNORE_OLD_PFX_SECONDS) {
			return
		}

		const hitEntity = this.entities.get(message.hitEntityId)
		const projectileOwner = this.entities.get(message.owningEntityId)

		if (hitEntity && (hitEntity.currentHealth === undefined || hitEntity.currentHealth > 0)) {
			if (hitEntity.riggedModel && hitEntity.riggedModel.state && hitEntity.riggedModel.state.hasAnimation(AnimationTrack.HIT)) {
				const trackEntry = playAnimation(hitEntity.riggedModel, AnimationTrack.HIT)
				if (hitEntity.damageConfig) {
					const alpha = getHitAnimAlpha(hitEntity.damageConfig, hitEntity.maxHealth, message.effectAmount)
					trackEntry.mixBlend = PIXI.spine.core.MixBlend.add
					trackEntry.alpha = alpha
				}
			}

			let sfxName = null
			switch (message.severity) {
				case DamageSeverity.Hit:
					sfxName = 'impact'
					break
				case DamageSeverity.ShieldHit:
					sfxName = 'impactShield'
					break
				case DamageSeverity.CriticalHit:
					sfxName = 'impactCritical'
					break
				case DamageSeverity.SevereHit:
					sfxName = 'impactInstaDeath'
					break
			}

			if (hitEntity.soundEffects && hitEntity.soundEffects[sfxName]) {
				Audio.getInstance().playSfx(hitEntity.soundEffects[sfxName])
			} else if (hitEntity.soundEffects && hitEntity.soundEffects.impact) {
				Audio.getInstance().playSfx(hitEntity.soundEffects.impact)
			}

			if (hitEntity.soundEffects && (hitEntity.soundEffects[sfxName] || hitEntity.soundEffects.impact)) {
				switch (message.damageType) {
					case DamageType.FIRE:
						Audio.getInstance().playSfx('SFX_Elemental_Fire')
						break
					case DamageType.ICE:
						Audio.getInstance().playSfx('SFX_Elemental_Ice')
						break
					case DamageType.LIGHTNING:
						Audio.getInstance().playSfx('SFX_Elemental_Thunder')
						break
					case DamageType.POISON:
						Audio.getInstance().playSfx('SFX_Elemental_Poison')
						break
				}
			}
		} else {
			//happens all the time
			//logger.error(`Received on hit message for entity that doesn't exist on our client - probably a big prop (at ${message.x}, ${message.y})`)
		}

		let z = message.y
		if (projectileOwner) {
			z = projectileOwner.zOffset + HACK_PARTICLE_ZOFFSET
		}

		if (hitEntity) {
			z = Math.max(z, hitEntity.y + HACK_PARTICLE_ZOFFSET)
		}

		if (hitEntity === this.state.myEntity && message.effectAmount > 0) {
			Camera.getInstance().triggerShakeWithDamage(message.effectAmount)
		}

		// TODO2: this is removing z offset from hit pfx
		//const pfx = getProjectileAssetFromEnum(message.particleEffect)
		//this.renderer.addOneOffEffect(pfx, message.x, message.y, z)
	}

	private handleProjectileSplash(message: ProjectileSplashMessage) {
		switch (message.damageType) {
			case DamageType.PHYSICAL:
				Audio.getInstance().playSfx('SFX_Impact_Enemy_Rock', { volume: 0.46 })
				break
			case DamageType.FIRE:
				Audio.getInstance().playSfx('SFX_Elemental_Fire', { volume: 0.46 })
				break
			case DamageType.ICE:
				Audio.getInstance().playSfx('SFX_Elemental_Ice', { volume: 0.46 })
				break
			case DamageType.LIGHTNING:
				Audio.getInstance().playSfx('SFX_Elemental_Thunder', { volume: 0.46 })
				break
			case DamageType.POISON:
				Audio.getInstance().playSfx('SFX_Elemental_Poison', { volume: 0.46 })
				break
		}
	}

	private handlePlayAnimation(message: PlayAnimationMessage) {
		const entity = this.entities.get(message.entityId) as ClientEnemy | ClientPlayer
		if (entity) {
			playAnimation(entity.riggedModel, message.animation as AnimationTrack) //, [0.3, 0])
		} else {
			console.warn(`handlePlayAnimation: no entity with nid:${message.entityId}`)
		}
	}

	private handleShakeCamera(message: ShakeCameraMessage) {
		Camera.getInstance().triggerShake(message.trauma)
	}

	//tslint:disable
	private fpsConfig = {
		showSpikeSeconds: 5,
		spikeThreasholdSeconds: 1 / 30,
		fpsAverageSeconds: 2,
	}
	private fpsVars = {
		lastSpikeTime: 0,
		lastSpikeSeconds: 0,
		lastFpsTime: 0,
		fpsHistory: [],
	}

	//tslint:enable
	private debugUpdateFpsEmits(delta: number, nowMs: number) {
		const nowSeconds = nowMs * 0.001
		const fpsVars = this.fpsVars

		if (delta > 0.001) {
			fpsVars.fpsHistory.push(1 / delta)
		}

		if (nowSeconds > fpsVars.lastFpsTime + this.fpsConfig.fpsAverageSeconds || fpsVars.lastFpsTime < 1) {
			fpsVars.lastFpsTime = nowSeconds
			const averageFps = average(fpsVars.fpsHistory)
			UI.getInstance().emitEvent('hud/latestAvgFPS', averageFps.toFixed(3))
			fpsVars.fpsHistory = []
		}

		if (delta > fpsVars.lastSpikeSeconds && delta > this.fpsConfig.spikeThreasholdSeconds) {
			fpsVars.lastSpikeSeconds = delta
			fpsVars.lastSpikeTime = nowSeconds
			UI.getInstance().emitEvent('hud/fpsSpiked', (delta * 1000).toFixed(2))
		}

		if (fpsVars.lastSpikeTime && nowSeconds > fpsVars.lastSpikeTime + this.fpsConfig.showSpikeSeconds) {
			fpsVars.lastSpikeSeconds = 0
			fpsVars.lastSpikeTime = 0
			UI.getInstance().emitEvent('hud/fpsSpiked', 0)
		}
	}

	private handlePlayerMovedToBiome(message: PlayerMovedToBiomeMessage) {
		NengiClient.getInstance().sendCommand(new ClientResizedCommand(getVisibleWorldWidth() + NENGI_VIEW_X_PADDING, getVisibleWorldHeight() + NENGI_VIEW_Y_PADDING))
		Renderer.getInstance().bgRenderer?.handleWindowResize(getVisibleWorldWidth(), getVisibleWorldHeight())
		if (this.state.myEntity) {
			this.state.myEntity.handleWindowResize(getVisibleWorldWidth(), getVisibleWorldHeight())
		}
		UI.getInstance().emitAsyncEvent('inGame/updateTransitionOverlay', false)
		UI.getInstance().emitEvent('hud/updateCurrentBiome', message.biomeIdx)
	}

}

function addLogToAllMessages(nengiLogType: NengiLogType) {
	const negniClientEventListener = NengiClient.getInstance().getClient() as any // this is a node EventListener
	negniClientEventListener.eventNames().forEach((eventName) => {
		const ignorableMessageTypes = ['update', 'message', 'create', 'delete']
		if (nengiLogType !== NengiLogType.All && ignorableMessageTypes.includes(eventName)) {
			return
		}
		NengiClient.getInstance().listen(eventName, (msg) => {
			if (nengiLogType === NengiLogType.LightMessagesOnly) {
				logger.debug(`received message<${eventName}>`)
			} else {
				logger.debug(
					`received message<${eventName}>:`,
					JSON.stringify(msg, (key, value) => {
						if (key === 'protocol') {
							return 'protocol'
						} else {
							return value
						}
					}),
				)
			}
		})
	})
}

function registerBeam(beam: ClientBeam) {
	if (debugConfig.csp) {
		if (debugConfig.cspConfig.drawServer || !GameClient.getInstance().state.myEntity?.cspProjectiles?.isLocalMine(beam)) {
			Renderer.getInstance().registerBeam(beam)
		}
	} else {
		Renderer.getInstance().registerBeam(beam)
	}
}

initClientTools()
