

















import { mapGetters, mapState } from 'vuex'
import WormDeliveryNotifier from '../panels/worm-delivery-notifier.vue'
import PitRewardsNotifier from '../panels/pit-rewards-notifier.vue'
import FurnaceNotifier from '../panels/furnace-notifier.vue'
import FactionsNotifier from '../panels/faction-notifier.vue'
import NotificationNotifier from '../panels/notification-notifier.vue'
import FreeMarketplaceNotifier from '../panels/free-marketplace-listing-notifier.vue'
import { chatSize } from '../state/chat.ui-state'

export default {
	name: 'NotificationContainer',
	data() {
		return {
			chatSize: chatSize,
		}
	},
	components: {
		WormDeliveryNotifier,
		PitRewardsNotifier,
		FurnaceNotifier,
		NotificationNotifier,
		FreeMarketplaceNotifier,
		FactionsNotifier
	},
	props: {},
	computed: {
		...mapGetters('notifications', ['getNotificationCount']),
		...mapGetters('furnace', ['furnacesAreCurrentlyFull']),
		...mapGetters('itemContainers', ['containerIsEmpty']),
		...mapGetters('UIScale', ['currentScale']),
		...mapState('factions', ['affiliatedFaction']),
		...mapState('marketplaceUpdated', ['lastFreeMarketplaceDate']),
		...mapState('furnace', ['hasFurnaceNotInUse']),
		...mapState('UIScale', ['notificationPosition']),
		...mapGetters('chat', ['chatVisible']),
		...mapState('time', ['nowMoment']),
		...mapState('party', ['partyMembers']),

		showFreeListing() {
			if (this.lastFreeMarketplaceDate) {
				return !this.nowMoment.isSame(this.lastFreeMarketplaceDate, 'day')
			}
			return true
		},
		hideEnvelop(){
			if(!this.affiliatedFaction || this.containerIsEmpty('wormDelivery') === false || this.containerIsEmpty('pitStorage') === false || this.hasFurnaceNotInUse || !this.furnacesAreCurrentlyFull || this.getNotificationCount > 0 || (this.getNotificationCount === 0 && this.showFreeListing)) return true
			return false
		}
	},
	methods: {},
}
