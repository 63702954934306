import nengi from 'nengi'
import { uuid } from '../../utils/primitive-types'

class MergeStackablesCommand {
	static protocol = {
		removeStacksFromItemId: nengi.String,
        putStacksOnItemId: nengi.String
	}

    removeStacksFromItemId: uuid
    putStacksOnItemId: uuid

	constructor(removeStacksFromItemId: uuid, putStacksOnItemId: uuid) {
        this.removeStacksFromItemId = removeStacksFromItemId
        this.putStacksOnItemId = putStacksOnItemId
	}
}

export default MergeStackablesCommand
