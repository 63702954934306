import { Container, Texture, WRAP_MODES } from 'pixi.js'
import { AssetManager } from '../../asset-manager/client/asset-manager'
import Renderer, { getMaxVisibleWorldHeight, getMaxVisibleWorldWidth, getVisibleWorldHeight, getVisibleWorldWidth } from '../../engine/client/graphics/renderer'
import logger from '../../utils/client-logger'
import { gameUnits } from '../../utils/primitive-types'
import WaterBlendTile from './water-blend-tile'
import WaterTile from './water-tile'

const BIOME_LOOKAHEAD_DIST: gameUnits = 5000

export default class WaterRenderer extends Container {
	static waterTexture: Texture
	static waveTexture: Texture
	static blendTexture: Texture
	static Instance: WaterRenderer

	waterContainer: Container = new Container()

	private static waterVert: string
	private static waveFrag: string

	private static waterBlendVert: string
	private static waveBlendFrag: string
	// TODO2 - Just hard coding enough tiles to fill 4k resolution for now
	private readonly TILE_SIZE: number = 512
	private xTiles: number = Math.floor(getMaxVisibleWorldWidth() / this.TILE_SIZE) + 1
	private yTiles: number = Math.floor(getMaxVisibleWorldHeight() / this.TILE_SIZE) + 1

	private waterTiles: WaterTile[][] = new Array<WaterTile[]>()
	private blendTiles: WaterBlendTile[] = new Array<WaterBlendTile>()

	constructor() {
		super()
		WaterRenderer.Instance = this
		this.addChild(this.waterContainer)

		WaterRenderer.waterVert = AssetManager.getInstance().getAssetByName('water_shader_vert').data
		WaterRenderer.waveFrag = AssetManager.getInstance().getAssetByName('wave_shader_frag').data

		WaterRenderer.waterBlendVert = AssetManager.getInstance().getAssetByName('water_blend_shader_vert').data
		WaterRenderer.waveBlendFrag = AssetManager.getInstance().getAssetByName('wave_blend_shader_frag').data

		WaterRenderer.waterTexture = AssetManager.getInstance().getAssetByName('ambient_water').texture
		WaterRenderer.waterTexture.baseTexture.wrapMode = WRAP_MODES.REPEAT

		WaterRenderer.waveTexture = AssetManager.getInstance().getAssetByName('waves_water').texture
		WaterRenderer.waveTexture.baseTexture.wrapMode = WRAP_MODES.REPEAT

		WaterRenderer.blendTexture = AssetManager.getInstance().getAssetByName('water_blend').texture
		WaterRenderer.blendTexture.baseTexture.wrapMode = WRAP_MODES.CLAMP
	}

	setWorldSize(worldWidth: number, worldHeight: number, tilesPerRow: number, tilesPerColumn: number) {
		this.createWaterTiles()
		this.waterContainer.position.set(0, 0)
		logger.debug(`setWorldSize yTiles:${this.yTiles}`)
	}

	update(delta: number): void {
		//const player: ClientPlayer = this.gameClientState.myEntity
		const renderer = Renderer.getInstance()
		const cameraPos = renderer.getCameraCenterWorldPos()
		const halfScreenHeight = getVisibleWorldHeight() * 0.5
		const halfScreenWidth = getVisibleWorldWidth() * 0.5
		const screenLeft = cameraPos.x - halfScreenWidth
		const screenRight = cameraPos.x + halfScreenWidth

		const waterBounds = renderer.waterBounds
		if (waterBounds) {
			const waterVisible = cameraPos.y - halfScreenHeight <= waterBounds.north || cameraPos.y + halfScreenHeight >= waterBounds.south || cameraPos.x - halfScreenWidth <= 512 * 2 || cameraPos.x + halfScreenWidth >= 80000
			this.waterContainer.visible = waterVisible
		}

		let leftBiome = 0
		let rightBiome = 0

		{
			// leaving this block here for this PR to stop whitespace changes
			leftBiome = renderer.getBiomeCurrentBiome(cameraPos.x - BIOME_LOOKAHEAD_DIST)
			rightBiome = renderer.getBiomeCurrentBiome(cameraPos.x + BIOME_LOOKAHEAD_DIST)

			const biomeBounds = Renderer.getInstance().biomeBounds

			const firstWaterTile = this.waterTiles[0]
			const lastWaterTile = this.waterTiles[this.waterTiles.length - 1]
			const firstBlendTile = this.blendTiles[0]

			const xStartPos = firstWaterTile[0].getPosition().x
			const xEndPos = lastWaterTile[0].getPosition().x + this.TILE_SIZE

			// If there are any blend tiles that no longer need to rendered, stop rendering them
			{
				const lx = firstBlendTile.getPosition().x
				const rx = lx + this.TILE_SIZE * 4
				const offScreen = rx < screenLeft || lx > screenRight
				this.setBlendTileVisibility(!offScreen && leftBiome !== rightBiome)
			}

			const blendVisible = firstBlendTile.isVisible()

			if (xEndPos - cameraPos.x < halfScreenWidth) {
				// If we have a biome transition, show the blend column and hide any normal water under it
				const endBiome1 = renderer.getBiomeCurrentBiome(cameraPos.x + halfScreenWidth - this.TILE_SIZE)
				const endBiome2 = renderer.getBiomeCurrentBiome(cameraPos.x + halfScreenWidth)
				if (endBiome1 !== endBiome2) {
					this.blendTiles.forEach((tile) => {
						tile.setVisible(true)
						tile.setPosition(xEndPos, tile.getPosition().y)
					})
				}
				const tileColumn = this.waterTiles.shift()
				const endBiome = renderer.getBiomeCurrentBiome(xEndPos)
				tileColumn.forEach((tile) => {
					tile.updateWaterColor(endBiome)
					tile.setVisible(blendVisible && this.insideBlend(xEndPos) ? false : true)
					tile.setPosition(xEndPos, tile.getPosition().y)
				})
				this.waterTiles.push(tileColumn)
			}

			if (cameraPos.x - xStartPos < halfScreenWidth) {
				// If we have a biome transition, show the blend column and hide any normal water under it
				const startBiome1 = renderer.getBiomeCurrentBiome(cameraPos.x - halfScreenWidth - this.TILE_SIZE * 5)
				const startBiome2 = renderer.getBiomeCurrentBiome(cameraPos.x - halfScreenWidth - this.TILE_SIZE * 4)
				if (startBiome1 !== startBiome2) {
					this.blendTiles.forEach((tile) => {
						tile.setPosition(xStartPos - this.TILE_SIZE * 5, tile.getPosition().y)
						tile.setVisible(true)
					})
				}
				const tileColumn = this.waterTiles.pop()
				const startBiome = renderer.getBiomeCurrentBiome(xStartPos)
				//console.log(`shifting to ${xEndPos} biome:${startBiome}`)
				tileColumn.forEach((tile) => {
					tile.updateWaterColor(startBiome)
					tile.setVisible(blendVisible && this.insideBlend(xStartPos - this.TILE_SIZE) ? false : true)
					tile.setPosition(xStartPos - this.TILE_SIZE, tile.getPosition().y)
				})
				this.waterTiles.unshift(tileColumn)
			}

			if (this.waterContainer.visible) {
				const yPos = this.waterContainer.position.y + this.yTiles * this.TILE_SIZE

				if (yPos - cameraPos.y < halfScreenHeight) {
					this.waterContainer.position.y += this.TILE_SIZE
				}
			}
			if (this.waterContainer.visible) {
				if (cameraPos.y - this.waterContainer.position.y < halfScreenHeight) {
					this.waterContainer.position.y -= this.TILE_SIZE
				}
			}
		}

		this.waterTiles.forEach((tiles) => {
			tiles.forEach((tile) => {
				tile.update(delta)
			})
		})
		this.blendTiles.forEach((tile) => {
			if (rightBiome !== leftBiome) {
				tile.setBlendColors(leftBiome, rightBiome)
			}
			tile.update(delta)
		})
	}

	private createWaterTiles(): void {
		for (let x = 0; x <= this.xTiles; ++x) {
			this.waterTiles.push(new Array<WaterTile>())
			for (let y = 0; y < this.yTiles; ++y) {
				// TODO3 - Currently making all tiles, wave tiles, need to determine if there is a good way to only make cliff side tiles wave tiles
				const tile = new WaterTile(WaterRenderer.waterVert, WaterRenderer.waveFrag, true)
				tile.addToContainer(this.waterContainer)
				tile.setPosition(x * this.TILE_SIZE, y * this.TILE_SIZE)
				this.waterTiles[x].push(tile)
			}
		}

		for (let y = 0; y < this.yTiles; ++y) {
			// TODO3 - Currently making all tiles, wave tiles, need to determine if there is a good way to only make cliff side tiles wave tiles
			const tile = new WaterBlendTile(WaterRenderer.waterBlendVert, WaterRenderer.waveBlendFrag, true)
			tile.addToContainer(this.waterContainer)
			tile.setVisible(false)
			tile.setPosition(0, y * this.TILE_SIZE)
			this.blendTiles.push(tile)
		}
	}

	private setBlendTileVisibility(visible: boolean): void {
		this.blendTiles.forEach((tile) => {
			tile.setVisible(visible)
		})
	}

	private insideBlend(pos: number): boolean {
		if (pos >= this.blendTiles[0].getPosition().x && pos <= this.blendTiles[0].getPosition().x + this.blendTiles[0].getWidth()) {
			return true
		}
		return false
	}
}
