import { cloneDeep, isEqual } from 'lodash'
import { debugPrintHistory } from '../debug/shared/debug-history'
import { debugConfig } from '../engine/client/debug-config'
import logger from '../utils/client-logger'
import { printDiff } from '../utils/compare'

/**
 * An object that can be pooled with ObjectPool. Recommend having a static pool variable on the where you store the ObjectPool itself.
 */
interface PoolableObject {
	setDefaultValues: (defaultValues: any, overrideValues?: any) => void
	cleanup: () => void
}

class PoolableJsObject implements PoolableObject {
	setDefaultValues(defaultValues: any, overrideValues?: any) { }
	cleanup() { }
}


class ObjectPool {
	status = {
		debugName: 'anon',
		totalAllocated: 0,
		totalFree: 0,
		minTotalFreeReached: 999999,
	}
	private _objectCreateFn: any
	private _startingSize: number = 0
	private _growthSize: number = 0
	private _objectPool: PoolableObject[] = []
	private _defaultValues: any
	private _checkCanonical: boolean
	private _canonicalObject: any

	/**
	 * Create a new object pool for a given object constructor. Returns this pool, which can be set as the static.pool property on that object type.
	 * @param objectCreateFn Constructor for the PoolableObject
	 * @param defaultValues Parameters to be passed to the setDefaultValues function of the PoolableObject
	 * @param startingSize How many objects should be allocated when this pool is constructed
	 * @param growthSize How many objects should this pool grow by if it has no more free objects remaining. Value of 0 will cause the pool not to grow and throw an error if you attempt to allocate more objects than are available.
	 */
	constructor(objectCreateFn: any, defaultValues: any, startingSize: number, growthSize?: number, debugName?: string, checkCanonical?: boolean) {
		// logger.debug(`Creating new pool (size: ${startingSize}, growth: ${growthSize})`, objectCreateFn)
		this._objectCreateFn = objectCreateFn
		this._defaultValues = defaultValues
		Object.freeze(this._defaultValues)
		this._startingSize = startingSize
		this._growthSize = growthSize
		this._checkCanonical = checkCanonical

		for (let i = 0; i < this._startingSize; i++) {
			const obj = this._objectCreateFn()
			if (obj.setDefaultValues) {
				obj.setDefaultValues(this._defaultValues)
			}
			this._objectPool.push(obj)
		}
		this.status.debugName = debugName
		this.status.totalAllocated = this._objectPool.length
		this.status.totalFree = this.status.totalAllocated
		this._updateMinTotalFree()
		if (debugConfig.pooling.debug) {
			debugAllObjectPools.push(this)
		}
	}

	/**
	 * Allocate a new object from the pool. Will attempt to grow the pool if none are remaining.
	 */
	alloc(overrideValues?: any): any {
		if (this._objectPool.length === 0) {
			this._growPool()
		}
		this.status.totalFree--
		this._updateMinTotalFree()
		const obj = this._objectPool.pop()

		if (this._checkCanonical) {
			if (!this._canonicalObject) {
				console.log('cloning', obj)
				this._canonicalObject = cloneDeep(obj)
			}
			this.checkIsCanonical(obj)
		}

		if (obj.setDefaultValues) {
			obj.setDefaultValues(this._defaultValues, overrideValues)
		}

		return obj
	}

	private checkIsCanonical(obj: any) {
		const equal = isEqual(obj, this._canonicalObject)
		if (!equal) {
			console.warn(obj)
			console.warn(this._canonicalObject)
			isEqual(obj, this._canonicalObject)
			const diffString = printDiff(obj, this._canonicalObject)
			console.log(diffString)
			throw new Error('pooling sucks but is better than GC')
		}
	}

	/**
	 * Free an existing object back into the pool.
	 * @param obj
	 */
	free(obj: PoolableObject) {
		obj.cleanup()
		this.status.totalFree++
		if (debugConfig.pooling.debug && this._objectPool.includes(obj)) {
			debugPrintHistory(obj)
			throw new Error('object freed twice, this will cause bad sharing')
		}
		this._objectPool.push(obj)
	}

	/** this is slow, so debug only */
	debugIsFree(obj: PoolableObject) {
		const free = this._objectPool.includes(obj)
		return free
	}

	private _growPool() {
		if (this._growthSize > 0) {
			//logger.warn(`Growing pool ${this.status.debugName} size from ${this.status.totalAllocated} to ${this.status.totalAllocated + this._growthSize}`)
			if (debugConfig.pooling.debug) {
				console.log(`growing pool ${this.status.debugName} by ${this._growthSize} totalAllocated:${this.status.totalAllocated}`)
			}
			for (let i = 0; i < this._growthSize; i++) {
				const obj = this._objectCreateFn()
				obj.setDefaultValues(this._defaultValues)
				this._objectPool.push(obj)
				this.status.totalAllocated++
				this.status.totalFree++
			}
		} else {
			throw new Error('Attempted to grow a pool that is configured not to grow')
		}
	}

	private _updateMinTotalFree() {
		const start = this.status.minTotalFreeReached
		const total = this.status.totalAllocated
		this.status.minTotalFreeReached = Math.min(this.status.totalFree, this.status.minTotalFreeReached)
		if (this.status.minTotalFreeReached < start) {
			//logger.debug(`new min reached: ${this.status.minTotalFreeReached}/${total} (${this.status.debugName})`)
		}
	}
}

export class ObjectPoolTyped<C> extends ObjectPool {
	alloc(overrideValues?): C {
		return super.alloc(overrideValues)
	}
}

export function printObjectPoolReport() {
	if (process.env.NODE_ENV === 'local') {
		if (debugAllObjectPools.length > 0) {
			debugAllObjectPools.forEach((op) => {
				logger.debug(`ObjectPool:`, op.status, op)
			})
		} else {
			logger.debug(`to use printObjectPoolReport, enabled debugConfig.pooling.debug`)
		}
	}
}

export function printObjectPoolSizes() {
	if (process.env.NODE_ENV === 'local') {
		if (debugAllObjectPools.length > 0) {
			let s = ''

			debugAllObjectPools.forEach((op) => {
				const maxAllocated = op.status.totalAllocated - op.status.minTotalFreeReached
				s += `['${op.status.debugName}']: ${maxAllocated},\n`
			})
			console.log(s)
		} else {
			logger.debug(`to use printObjectPoolReport, enabled debugConfig.pooling.debug`)
		}
	}
}

export function clearObjectPoolArray() {
	debugAllObjectPools = []
}

let debugAllObjectPools: ObjectPool[] = []

export { ObjectPool, PoolableObject, PoolableJsObject }
