import nengi from 'nengi'
import { uuid } from '../../utils/primitive-types'
import { ContainerType } from './send-item-container-message'

class RemoveItemsFromContainerMessage {
	static protocol = {
		item_ids: { type: nengi.String, indexType: nengi.UInt16 },
		container: nengi.String,
	}

	item_ids: uuid[]
	container: string

	constructor(itemIds, container: ContainerType) {
		this.item_ids = itemIds
		this.container = container
	}
}

export default RemoveItemsFromContainerMessage
