












export const VALID_STAT_ICONS = ['buff', 'debuff', 'cd-speed', 'move-speed']

export default {
	name: 'PaperdollStats',
	props: {
		value: {
			type: String,
			required: false,
			default: '100',
		},
		iconName: {
			type: String,
			required: false,
			default: 'buff',
			validator: function(value) {
				return VALID_STAT_ICONS.includes(value)
			},
		},
		statName: {
			type: String,
			required: false,
			default: 'Default Name',
		},
	},
}
