import { Texture, Geometry, Buffer, Program, Shader, Mesh, State, TYPES, BLEND_MODES } from 'pixi.js'
import { BaseGround } from './base-ground'

const FLOATS_PER_VERT = 4
const VERTS_PER_QUAD = 4
const GROUND_WIDTH = 512
const MIN_GROUND_HEIGHT = 256
const MAX_GROUND_HEIGHT = 512
const VERT_UVS: number[] = [0, 0, 1, 0, 1, 1, 0, 1]
const QUAD_INDICES: number[] = [0, 1, 2, 0, 2, 3]

export class Cliffs extends BaseGround {
	private static cliffGeom: Geometry
	private static cliffVBO: Buffer
	private static cliffIndexBuffer: Buffer
	private static vertPosition: number[]
	private static prevYOffset: number = 0
	private static prevHeight: number = 0
	private readonly CLIFF_TEXTURE_INDEX = 1

	private yOffset: number

	constructor(vertSrc: string, fragSrc: string, textures: Texture[], yOffset: number, blendRotation: number = 0) {
		super(vertSrc, fragSrc, textures, blendRotation)
		this.yOffset = yOffset
	}

	init(): void {
		if (this.textures[this.CLIFF_TEXTURE_INDEX].height !== MIN_GROUND_HEIGHT && this.yOffset === 0) {
			super.init()
		} else {
			const program = Program.from(this.vertSrc, this.fragSrc, 'cliffs')

			if (!Cliffs.cliffVBO || this.yOffset !== Cliffs.prevYOffset || this.textures[this.CLIFF_TEXTURE_INDEX].height !== Cliffs.prevHeight) {
				Cliffs.prevYOffset = this.yOffset
				Cliffs.prevHeight = this.textures[this.CLIFF_TEXTURE_INDEX].height
				Cliffs.cliffGeom = new Geometry()
				const floats = new Float32Array(FLOATS_PER_VERT * VERTS_PER_QUAD)
				const indexData = new Uint16Array(QUAD_INDICES)

				const groundHeight: number = this.textures[this.CLIFF_TEXTURE_INDEX].height === MIN_GROUND_HEIGHT ? MIN_GROUND_HEIGHT : MAX_GROUND_HEIGHT
				Cliffs.vertPosition = [0, 0 + this.yOffset, GROUND_WIDTH, 0 + this.yOffset, GROUND_WIDTH, groundHeight + this.yOffset, 0, groundHeight + this.yOffset]

				let ofs = 0
				for (let i = 0; i < 4; ++i) {
					floats[ofs++] = Cliffs.vertPosition[i * 2 + 0]
					floats[ofs++] = Cliffs.vertPosition[i * 2 + 1]
					floats[ofs++] = VERT_UVS[i * 2 + 0]
					floats[ofs++] = VERT_UVS[i * 2 + 1]
				}

				Cliffs.cliffVBO = new Buffer(floats, true, false)
				Cliffs.cliffIndexBuffer = new Buffer(indexData, true, true)

				Cliffs.cliffGeom.addAttribute('aPosition', Cliffs.cliffVBO, 2, false, TYPES.FLOAT, FLOATS_PER_VERT * 4, 0)
				Cliffs.cliffGeom.addAttribute('aUV', Cliffs.cliffVBO, 2, false, TYPES.FLOAT, FLOATS_PER_VERT * 4, 8)
				Cliffs.cliffGeom.addIndex(Cliffs.cliffIndexBuffer)
			}

			const uniforms = this.setupUniforms()

			this.shader = new Shader(program, uniforms)
			this.state = new State()

			this.state.blend = false

			// @ts-expect-error breaks contract with pixi, wontfix
			this.mesh = new Mesh(Cliffs.cliffGeom, this.shader, this.state)
			this.mesh.blendMode = BLEND_MODES.NONE
		}
	}

	protected setupUniforms(): any {
		let uniforms = null
		const uvTransformA: number[] = [
			this.textures[0]._frame.x / this.textures[0].baseTexture.width,
			this.textures[0]._frame.y / this.textures[0].baseTexture.height,
			this.textures[0]._frame.width / this.textures[0].baseTexture.width,
			this.textures[0]._frame.height / this.textures[0].baseTexture.height,
		]
		const uvTransformB: number[] = [
			this.textures[1]._frame.x / this.textures[1].baseTexture.width,
			this.textures[1]._frame.y / this.textures[1].baseTexture.height,
			this.textures[1]._frame.width / this.textures[1].baseTexture.width,
			this.textures[1]._frame.height / this.textures[1].baseTexture.height,
		]
		const uvTransformMix: number[] = [
			this.textures[2]._frame.x / this.textures[2].baseTexture.width,
			this.textures[2]._frame.y / this.textures[2].baseTexture.height,
			this.textures[2]._frame.width / this.textures[2].baseTexture.width,
			this.textures[2]._frame.height / this.textures[2].baseTexture.height,
		]

		uniforms = {
			u_biomeA: this.textures[0],
			u_biomeB: this.textures[1],
			u_biomeMix: this.textures[2],
			u_uvTransformA: uvTransformA,
			u_uvTransformB: uvTransformB,
			u_uvTransformMix: uvTransformMix,
			u_blendRot: this.blendRotation,
		}

		return uniforms
	}
}
