import { BinaryFlag } from "./stat-type-mod-category";

export const BinaryFlagData: {
	[k in BinaryFlag]: {
		description: string,
		data?: any,
	}
} = {
	[BinaryFlag.CULL_ENEMIES_AT_HEALTH_THRESHOLD_1]: {
		description: 'Instantly kill enemies below 10% health.',
		data: {
			threshold: 0.1,
		},
	},
	[BinaryFlag.CULL_ENEMIES_AT_HEALTH_THRESHOLD_2]: {
		description: 'Instantly kill enemies below 20% health.',
		data: {
			threshold: 0.2,
		},
	},
	[BinaryFlag.MOVEMENT_SPEED_1_ON_KILL]: {
		description: 'Gain 10% Movement Speed on Kill.',
		data: {
			movementSpeedBuffStacks: 1,
		},
	},
	[BinaryFlag.MOVEMENT_SPEED_2_ON_KILL]: {
		description: 'Gain 20% Movement Speed on Kill.',
		data: {
			movementSpeedBuffStacks: 2,
		},
	},
	[BinaryFlag.IF_SHIELDS_THEN_IMMUNE_TO_ELEMENTAL_DOTS]: {
		description: 'If you have Shields, you are immune to Poison and Ignite.',
	},
	[BinaryFlag.IF_SHIELDS_THEN_IMMUNE_TO_ELEMENTAL_AILMENTS]: {
		description: 'If you have Shields, you are immune to Elemental Ailments.',
	},
	[BinaryFlag.SHIELD_CONDITIONAL_CHAOS_DAMAGE_1]: {
		description: 'If you have NO Shields, gain 20% Damage, otherwise lose 10% Damage.',
		data: {
			damageBoost: 0.2,
			damagePenalty: -0.1,
		},
	},
	[BinaryFlag.ON_SHIELD_BREAK_GAIN_MOVEMENT_SPEED_1]: {
		description: 'When your Shields are broken, gain a burst of Movement Speed.',
		data: {
			movementSpeedBoost: 1.0,
		},
	},
	[BinaryFlag.ON_SHOOT_VARY_PROJECTILE_SPEED]: {
		description: 'Projectiles disperse when firing.',
	},
	[BinaryFlag.ON_SHOOT_DOUBLE_TAP]: {
		description: 'Double Tap, fire twice quickly.',
	},
	[BinaryFlag.ON_SHOOT_TRIPLE_TAP]: {
		description: 'Triple Tap, fire thrice quickly.',
	},
	[BinaryFlag.SPELLSWORD_SUPER_BEAM_RAMP_UP]: {
		description: 'Beam damage ramps up 4% per hit, lasting 2s each.',
		data: {
			damageBoost: 0.04,
		},
	},
	[BinaryFlag.CHAMPION_SLAYER]: {
		description: 'Champion Slayer - Deal 20% more damage to champions.',
		data: {
			damageBoost: 0.2,
		},
	},
	[BinaryFlag.BOSS_KILLER]: {
		description: 'Boss Killer - Deal 10% more damage to bosses.',
		data: {
			damageBoost: 0.1,
		},
	},
	[BinaryFlag.ENEMY_KILLS_REDUCE_COOLDOWN_1]: {
		description: 'Each enemy kill reduces skill cooldowns.',
	},
	[BinaryFlag.GEAR_SKILL_OVERCHARGE_1]: {
		description: 'Gear skills are overcharged with longer duration and cooldown.',
	},
	[BinaryFlag.AGILE_ROLLS]: {
		description: 'Your roll skills have 33% more acceleration.',
		data: {
			accelerationMulti: 1.333333,
		},
	},
	[BinaryFlag.HEART_DROPPER_1]: {
		description: 'Enemies drop 25% more hearts.',
		data: {
			heartDropBoost: 0.25,
		},
	},
	[BinaryFlag.HEART_DROPPER_2]: {
		description: 'Enemies drop 50% more hearts.',
		data: {
			heartDropBoost: 0.50,
		},
	},

	[BinaryFlag.TRAJECTORY_ACCELERATE]: {
		description: '',
	},
	[BinaryFlag.TRAJECTORY_DECCELERATE]: {
		description: '',
	},
	[BinaryFlag.TRAJECTORY_RANGE_ACCELERATE]: {
		description: '',
	},
	[BinaryFlag.TRAJECTORY_BOOMERANG]: {
		description: '',
	},
	[BinaryFlag.TRAJECTORY_SINE]: {
		description: '',
	},
	[BinaryFlag.TRAJECTORY_ZIGZAG]: {
		description: '',
	},
	[BinaryFlag.TRAJECTORY_CIRCULAR]: {
		description: '',
	},
	[BinaryFlag.TRAJECTORY_ORBIT]: {
		description: '',
	},
	[BinaryFlag.TRAJECTORY_SPIRAL]: {
		description: '',
	},
	[BinaryFlag.TRAJECTORY_LOOPING]: {
		description: '',
	},
	[BinaryFlag.TRAJECTORY_RANGE_CIRCULAR]: {
		description: '',
	},
	[BinaryFlag.TRAJECTORY_RANGE_ORBIT]: {
		description: '',
	},
	[BinaryFlag.TRAJECTORY_FLIP_EVERY_OTHER_SHOT]: {
		description: '',
	},

}