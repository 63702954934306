<template>
	<div id="party-departure-ui" :class="{ visible: true }">
		<div class="party-departure-countdown">
			<div class="party-departure-text">Party Departing {{ partyDepartureTimeRemaining }}</div>
		</div>
	</div>
</template>

<script lang="js">
import { mapGetters } from 'vuex'
export default {
    name: 'PartyDepartureCountdown',
    computed: {
        ...mapGetters('hud', ['partyDepartureTimeRemaining'])
    }
}
</script>

<style lang="less" scoped>
@import url('../global/fonts/text.less');
#party-departure-ui {
	position: relative;
	display: flex;
	justify-content: center;
	width: 500px;
	background-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 20%, rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 0.7) 80%, rgba(0, 0, 0, 0) 100%);

	&.visible {
		transition: height 300ms ease, opacity 300ms ease;
		opacity: 1;
	}

	.party-departure-countdown {
		display: flex;
		flex-direction: column;
		text-align: center;
		padding: 15px 0px;
		margin: 0px 0px;
		.party-departure-text {
			flex-wrap: nowrap;
			.party-departure-ui-text();
		}
	}
}
</style>
