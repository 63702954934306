





































import { mapGetters } from 'vuex'
import InventoryItem from '../inventory-item/inventory-item.vue'
import PanelDivider from '../panel-divider/panel-divider.vue'
import { getItemNameByItemType } from '../inventory-item/inventory-item-helpers'

export default {
	name: 'PaperdollListItemsContainer',
	components: {
		InventoryItem,
		PanelDivider,
	},
	props: {
		items: {
			type: Array,
			required: true,
			default: () => [],
		},

		tooltipable: {
			type: Boolean,
			required: false,
			default: true,
		},
		selectable: {
			type: Boolean,
			required: false,
			default: true,
		},
		onSelectFn: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default on-select handler for the <PaperdollListItemsContainer /> component has been called. Did you forget to pass an :on-click handler to the component?')
			},
		},
		selectedFn: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default selected fn for the <PaperdollListItemsContainer /> component has been called. Did you forget to pass an :selected-fn handler to the component?')
			},
		},
	},
	computed: {
		...mapGetters('itemContainers', ['itemDetails']),
	},
	methods: {
		itemType(item) {
			return getItemNameByItemType(item)
		},
	},
}
