import { AnyColliderConfig, ColliderTraits, ColliderType } from '../../collision/shared/colliders'
import { degToRad } from '../../utils/math'
import { makeBlockPropConfig, PropConfigs } from './prop-configs'

const beachLargeUncommonColliders: AnyColliderConfig[] = [
	{ type: ColliderType.Ellipse, position: [-36, 8], rX: 378, rY: 170, traits: ColliderTraits.BlockMovementAndItem },
	{ type: ColliderType.Box, position: [-335, -144], width: 634, height: 150, traits: ColliderTraits.BlockProjectile },
	{ type: ColliderType.Circle, position: [360, 97], radius: 58, traits: ColliderTraits.BlockMovementAndItem },
]
const beachLargeRare: [AnyColliderConfig] = [{ type: ColliderType.Ellipse, position: [-37, 54], rX: 377, rY: 200, traits: ColliderTraits.BlockAll }]

export const beachPropConfigs: PropConfigs = {
	'boss-arena': {
		colliders: [
			{ type: ColliderType.Ellipse, position: [-56, -429], rX: 70, rY: 35, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [8, -621], rX: 70, rY: 35, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-10, -789], rX: 66, rY: 59, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-62, -915], rX: 70, rY: 35, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-127, -1092], rX: 83, rY: 44, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [550, -267], rX: 70, rY: 35, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [787, -406], rX: 70, rY: 35, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [1009, -465], rX: 70, rY: 35, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [1231, -587], rX: 83, rY: 44, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [1509, -642], rX: 70, rY: 35, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [587, 179], rX: 70, rY: 35, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [804, 266], rX: 70, rY: 35, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [931, 377], rX: 83, rY: 44, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [1254, 430], rX: 70, rY: 35, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [1453, 547], rX: 70, rY: 35, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [1611, 677], rX: 70, rY: 35, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-90, 260], rX: 83, rY: 44, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-44, 479], rX: 70, rY: 35, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-69, 640], rX: 83, rY: 44, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-56, 849], rX: 70, rY: 35, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-34, 1086], rX: 70, rY: 35, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-611, 125], rX: 83, rY: 44, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-842, 217], rX: 70, rY: 35, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-1064, 330], rX: 70, rY: 35, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-1420, 431], rX: 83, rY: 44, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-1674, 558], rX: 83, rY: 44, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-535, -308], rX: 70, rY: 35, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-762, -382], rX: 70, rY: 35, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-1021, -389], rX: 70, rY: 35, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-1106, -516], rX: 70, rY: 35, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-1460, -603], rX: 83, rY: 44, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [7, -65], rX: 491, rY: 277, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [-136, -66], radius: 170, traits: ColliderTraits.BlockProjectile },
			{ type: ColliderType.Circle, position: [130, -92], radius: 170, traits: ColliderTraits.BlockProjectile },
			{ type: ColliderType.Box, position: [194, -1402], width: 1332, height: 135, traits: ColliderTraits.BlockAll, angle: 0.21 },
			{ type: ColliderType.Box, position: [164, -1271], width: 1332, height: 50, traits: ColliderTraits.BlockMovementAndItem, angle: 0.21 },
			{ type: ColliderType.Box, position: [-1552, -1087], width: 1110, height: 100, traits: ColliderTraits.BlockAll, angle: -0.17 },
			{ type: ColliderType.Box, position: [-1524, -992], width: 1110, height: 40, traits: ColliderTraits.BlockMovementAndItem, angle: -0.17 },
			{ type: ColliderType.Box, position: [1920, -656], width: 301, height: 424, traits: ColliderTraits.BlockAll, angle: 0.0 },
			{ type: ColliderType.Box, position: [1920, -256], width: 266, height: 388, traits: ColliderTraits.BlockAll, angle: 0.0 },
			{ type: ColliderType.Box, position: [1807, 22], width: 380, height: 125, traits: ColliderTraits.BlockAll, angle: 0.0 },
			{ type: ColliderType.Ellipse, position: [1877, 149], rX: 70, rY: 35, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [1860, 149], width: 333, height: 427, traits: ColliderTraits.BlockAll, angle: 0.0 },
			{ type: ColliderType.Box, position: [-2185, -636], width: 325, height: 302, traits: ColliderTraits.BlockAll, angle: 0.0 },
			{ type: ColliderType.Ellipse, position: [-1930, -333.41], rX: 70, rY: 31.42, traits:ColliderTraits.BlockMovementAndItem},
			{ type: ColliderType.Box, position: [-2306, -334], width: 345, height: 134, traits: ColliderTraits.BlockAll, angle: 0.0 },
			{ type: ColliderType.Ellipse, position: [-2237, -192], rX: 70, rY: 44, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-2212, -201], width: 300, height: 448, traits: ColliderTraits.BlockAll, angle: 0.0 },
			{ type: ColliderType.Box, position: [-2200, 247], width: 184, height: 122, traits: ColliderTraits.BlockAll, angle: 0.0 },
			{ type: ColliderType.Box, position: [404, 1138], width: 1332, height: 137, traits: ColliderTraits.BlockAll, angle: -0.21 },
			{ type: ColliderType.Box, position: [433, 1268], width: 1332, height: 42, traits: ColliderTraits.BlockMovementAndItem, angle: -0.21 },
			{ type: ColliderType.Box, position: [485, 1164], width: 1026, height: 116, traits: ColliderTraits.BlockAll, angle: -0.12 },
			{ type: ColliderType.Box, position: [499, 1280], width: 1026, height: 50, traits: ColliderTraits.BlockMovementAndItem, angle: -0.12 },
			{ type: ColliderType.Box, position: [-1522, 854], width: 983, height: 122, traits: ColliderTraits.BlockAll, angle: 0.17 },
			{ type: ColliderType.Box, position: [-1545, 996], width: 983, height: 40, traits: ColliderTraits.BlockMovementAndItem, angle: 0.17 },
			{ type: ColliderType.Box, position: [-1405, 769], width: 274, height: 105, traits: ColliderTraits.BlockAll, angle: 0.17 },
			{ type: ColliderType.Box, position: [-2019, 146], width: 1000, height: 259, angle: 0.76, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-2199, 335], width: 1000, height: 40, angle: 0.76, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-594, 1100], width: 1094, height: 194, angle: 0.0, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-594, 1294], width: 1094, height: 50, angle: 0.0, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-2178, -655], width: 800, height: 166, angle: -0.6, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-2084, -517], width: 800, height: 57, angle: -0.6, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-1416, -865], rX: 125, rY: 40, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-1040, -1345], width: 1329, height: 182, angle: -0.1, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-1024, -1162], width: 1329, height: 40, angle: -0.1, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [1494, -1127], width: 800, height: 181, angle: 0.58, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [1391, -975], width: 800, height: 40, angle: 0.58, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [1421, 786], width: 700, height: 306, angle: -0.74, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [1627, 1013], width: 700, height: 50, angle: -0.74, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [1387, 708], width: 260, height: 242, angle: 0.0, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-1395, -1233], width: 359, height: 153, traits: ColliderTraits.BlockAll, angle: -0.17 },
			{ type: ColliderType.Box, position: [0, 0], width: 100, height: 100, angle: 0.0, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [-724, 1192], rX: 244, rY: 149, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [429, -1334], rX: 165, rY: 150, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [1070, -1160], rX: 180, rY: 127, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [2051, -314], width: 266, height: 243, traits: ColliderTraits.BlockAll, angle: 0.0 },
			{ type: ColliderType.Box, position: [2051, -69], width: 266, height: 50, traits: ColliderTraits.BlockMovementAndItem, angle: 0.0 },
			{ type: ColliderType.Box, position: [-1015, -1432], width: 229, height: 153, traits: ColliderTraits.BlockAll, angle: -0.17 },
			{ type: ColliderType.Ellipse, position: [2090, 661], rX: 134, rY: 73, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-1470, -1050], width: 184, height: 164, traits: ColliderTraits.BlockAll, angle: 0.05 },
		],
		rigged: false,
		visibilityRadius: 2500,
	},
	'outpost-base': {
		disableFileOrdering: true,
		colliders: [
			{ type: ColliderType.Box, position: [-835, -635], width: 70, height: 1135, traits: ColliderTraits.BlockAll }, // west wall
			{ type: ColliderType.Box, position: [520, -635], width: 70, height: 1135, traits: ColliderTraits.BlockAll }, // east wall
			{ type: ColliderType.Box, position: [-835, -635], width: 1425, height: 100, traits: ColliderTraits.BlockAll }, // north wall
			{ type: ColliderType.Box, position: [-836, 400], width: 225, height: 100, traits: ColliderTraits.BlockAll }, // south-west wall
			{ type: ColliderType.Box, position: [150, 400], width: 440, height: 100, traits: ColliderTraits.BlockAll }, // south-east wall
			{ type: ColliderType.Box, position: [-139, -473], width: 666, height: 240, traits: ColliderTraits.BlockAll }, // main building
			{ type: ColliderType.Box, position: [354, -93], width: 193, height: 230, traits: ColliderTraits.BlockMovementAndItem }, // campfire
			{ type: ColliderType.Box, position: [-632, -165], width: 160, height: 74, traits: ColliderTraits.BlockAll }, // worm sign
			// trees
			{ type: ColliderType.Circle, position: [424, -395], radius: 50, traits: ColliderTraits.BlockAll }, // inside top right
			{ type: ColliderType.Circle, position: [653, -567], radius: 50, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [-505, -624], radius: 50, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [-972, 418], radius: 50, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [-987, 686], radius: 50, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [-740, 636], radius: 50, traits: ColliderTraits.BlockAll },
			// rocks
			{ type: ColliderType.Circle, position: [763, -502], radius: 87, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [766, -120], radius: 87, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [740, 73], radius: 87, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [-966, -433], radius: 87, traits: ColliderTraits.BlockMovementAndItem },

			// wooden things - boats, crates
			{ type: ColliderType.Circle, position: [676, -40], radius: 87, traits: ColliderTraits.BlockMovementAndItem }, //crate
			{ type: ColliderType.Circle, position: [-986, -619], radius: 124, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [-844, -671], radius: 124, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [684, 489], radius: 124, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [459, 506], radius: 124, traits: ColliderTraits.BlockAll },
		],
		rigged: false,
		visibilityRadius: 1400,
	},
	'outpost-campfire': {
		rigged: true,
		visibilityRadius: 1400,
		zOffset: Number.MAX_SAFE_INTEGER,
	},
	'outpost-torches': {
		rigged: true,
		visibilityRadius: 1400,
		zOffset: 575,
	},
	'outpost-bubba': {
		rigged: true,
		visibilityRadius: 1400,
		zOffset: -1000,
	},
	'poi-01': {
		// Crab Nest POI
		colliders: [
			// big rocks
			{ type: ColliderType.Ellipse, position: [600, -530], rX: 150, rY: 100, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-1150, 310], width: 610, height: 210, traits: ColliderTraits.BlockAll, angle: degToRad(20) },
			{ type: ColliderType.Box, position: [-1100, -250], width: 420, height: 180, traits: ColliderTraits.BlockAll, angle: degToRad(-45) },
			{ type: ColliderType.Box, position: [200, 70], width: 520, height: 230, traits: ColliderTraits.BlockAll, angle: degToRad(-25) },

			// small rocks
			{ type: ColliderType.Circle, position: [1120, 0], radius: 80, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [1080, -420], radius: 80, traits: ColliderTraits.BlockMovementAndItem },

			// trees
			{ type: ColliderType.Circle, position: [-1200, -160], radius: 50, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [500, 1040], radius: 50, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [620, 920], radius: 50, traits: ColliderTraits.BlockAll },

			// boat
			{ type: ColliderType.Box, position: [270, 880], width: 200, height: 60, traits: ColliderTraits.BlockAll },
		],
		rigged: false,
		visibilityRadius: 1400,
	},
	'poi-02-ground': {
		// Monster Raid POI
		colliders: [
			// Lighthouse
			{ type: ColliderType.Ellipse, position: [-177, -231], rX: 346, rY: 187, traits: ColliderTraits.BlockAll },
			// Rocks around lighthouse
			{ type: ColliderType.Box, position: [-672, 8], width: 411, height: 109, traits: ColliderTraits.BlockAll, angle: degToRad(30) },
			{ type: ColliderType.Box, position: [-734, 10], width: 55, height: 86, traits: ColliderTraits.BlockAll, angle: degToRad(30) },
			{ type: ColliderType.Box, position: [354, -170], width: 131, height: 255, traits: ColliderTraits.BlockAll, angle: degToRad(0) },
			{ type: ColliderType.Box, position: [483, -93], width: 45, height: 53, traits: ColliderTraits.BlockAll, angle: degToRad(0) },
			{ type: ColliderType.Box, position: [42, -443], width: 445, height: 115, traits: ColliderTraits.BlockAll, angle: degToRad(20) },
			{ type: ColliderType.Box, position: [-820, -213], width: 348, height: 81, traits: ColliderTraits.BlockAll, angle: degToRad(-30) },
			{ type: ColliderType.Box, position: [-762, -173], width: 170, height: 84, traits: ColliderTraits.BlockAll, angle: degToRad(-30) },
			// Other props
			{ type: ColliderType.Circle, position: [499, -520], radius: 65, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [905, -171], radius: 60, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [816, -109], radius: 54, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [869, -8], radius: 70, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [844, 304], radius: 98, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [-948, -478], radius: 61, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [-1192, -149], radius: 38, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [-1120, 429], radius: 76, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [-1279, 499], radius: 54, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [-1053, 569], radius: 43, traits: ColliderTraits.BlockMovementAndItem },
		],
		rigged: false,
		visibilityRadius: 1400,
	},
	'poi-02-lighthouse': {
		rigged: true,
		visibilityRadius: 900,
		zOffset: -200,
	},
	'poi-03-shipwreck': {
		colliders: [	
					{ type: ColliderType.Box, position: [679, 27], width: 170, height: 45, traits: ColliderTraits.BlockAll, angle: degToRad(60) },
					{ type: ColliderType.Circle, position: [-86, 279], radius: 96, traits: ColliderTraits.BlockAll },
					{ type: ColliderType.Box, position: [252, 207], width: 274, height: 145, traits: ColliderTraits.BlockAll, angle: degToRad(0) },
					{ type: ColliderType.Circle, position: [-622, 275], radius: 110, traits: ColliderTraits.BlockAll },
					{ type: ColliderType.Circle, position: [-786, -80], radius: 62, traits: ColliderTraits.BlockAll },
					{ type: ColliderType.Circle, position: [-542, -132], radius: 51, traits: ColliderTraits.BlockAll },
					{ type: ColliderType.Circle, position: [-453, 295], radius: 51, traits: ColliderTraits.BlockAll },
					{ type: ColliderType.Circle, position: [-641, -90], radius: 51, traits: ColliderTraits.BlockAll },
					{ type: ColliderType.Ellipse, position: [334, -159], rX: 205, rY: 59, traits: ColliderTraits.BlockAll, angle: degToRad(25) },
					{ type: ColliderType.Box, position: [787, 93], width: 55, height: 285, traits: ColliderTraits.BlockAll, angle: degToRad(0) },
					{ type: ColliderType.Circle, position: [-6, 373], radius: 70, traits: ColliderTraits.BlockAll },

				],
		rigged: true,
		visibilityRadius: 900,
		zOffset: -200,
	},
	'poi-04-crabpost': {
		colliders:[
			{ type: ColliderType.Circle, position: [-350,-70], radius: 47, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Circle, position: [-625,75], radius: 85, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Ellipse, position: [203,174], rX: 160, rY: 105, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Ellipse, position: [347,310], rX: 145, rY: 90, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Circle, position: [506,370], radius: 67, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Circle, position: [157,401], radius: 87, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Circle, position: [-509,429], radius: 33, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Circle, position: [-669,-480], radius: 75, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Box, position: [-170,-590], width: 754, height: 346, traits: ColliderTraits.BlockAll, angle: 0.00,},
			{ type: ColliderType.Circle, position: [-181,-391], radius: 54, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Circle, position: [750,84], radius: 54, traits: ColliderTraits.BlockMovementAndItem,},
			{ type: ColliderType.Box, position: [-808,-512], width: 45, height: 181, traits: ColliderTraits.BlockAll, angle: 0.00,},
			{ type: ColliderType.Box, position: [-807,-568], width: 219, height: 92, traits: ColliderTraits.BlockAll, angle: 0.00,},
			{ type: ColliderType.Box, position: [-754,69], width: 61, height: 333, traits: ColliderTraits.BlockAll, angle: 0.00,},
			{ type: ColliderType.Box, position: [-717,317], width: 163, height: 80, traits: ColliderTraits.BlockAll, angle: 0.00,},
			{ type: ColliderType.Box, position: [542,-64], width: 38, height: 266, traits: ColliderTraits.BlockAll, angle: 0.00,},
			{ type: ColliderType.Circle, position: [-275,-597], radius: 37, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Box, position: [-682,179], width: 77, height: 56, traits: ColliderTraits.BlockAll, angle: 0.00,},
			{ type: ColliderType.Box, position: [-388,-155], width: 77, height: 56, traits: ColliderTraits.BlockAll, angle: 0.00,}
		],
		visibilityRadius: 900,
		disableFileOrdering: true,
	},
	'poi-04-bubba-mound': {
		dontLoadAsset: true,
		zOffset: -5000
	},
	'pipe': {
		dontLoadAsset: true,
		zOffset: 30
	},
	'poi-eyes-web-top-03': {
		dontLoadAsset: true,
		zOffset: 100 // this is rotated wrong for some eldritch reason in the crabpost poi, so not using proper offset to hide it
	},
	'poi-05-mosquito-pools': {
		colliders: [	
					{ type: ColliderType.Circle, position: [8, 201], radius: 89, traits: ColliderTraits.BlockMovementAndItem },
					{ type: ColliderType.Circle, position: [-294, -139], radius: 161, traits: ColliderTraits.BlockMovementAndItem },
					{ type: ColliderType.Ellipse, position: [ 270, -28], rX: 97, rY: 55, traits: ColliderTraits.BlockMovementAndItem },
					{ type: ColliderType.Ellipse, position: [ -109, -240], rX: 110, rY: 77, traits: ColliderTraits.BlockMovementAndItem },
					{ type: ColliderType.Ellipse, position: [ -72, 286], rX: 137, rY: 77, traits: ColliderTraits.BlockMovementAndItem },
					{ type: ColliderType.Circle, position: [439, 47], radius: 75, traits: ColliderTraits.BlockMovementAndItem },
					{ type: ColliderType.Circle, position: [-130, -364], radius: 53, traits: ColliderTraits.BlockMovementAndItem },
					

				],
		rigged: true,
		visibilityRadius: 900,
		zOffset: -200,
	},
	'Lighthouse/poi-02': {
		zOffset: -185,
		dontLoadAsset: true,
	},
	'Lighthouse/poi-03': {
		zOffset: -201,
		dontLoadAsset: true,
	},
	'Lighthouse/poi-04': {
		zOffset: -201,
		dontLoadAsset: true,
	},
	'Lighthouse/poi-05': {
		zOffset: -200,
		
		dontLoadAsset: true,
	},
	// START blocker pieces //
	'low-blocker-01': {
		colliders: [{ type: ColliderType.Ellipse, position: [0, 0], rX: 150, rY: 80, traits: ColliderTraits.BlockMovementAndItem }],
		visibilityRadius: 150,
		dontLoadAsset: true,
		zOffset: -500
	},
	'low-blocker-02': {
		colliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 90, traits: ColliderTraits.BlockMovementAndItem }],
		visibilityRadius: 125,
		zOffset: -600,
		dontLoadAsset: true,
	},
	'low-blocker-03': {
		colliders: [{ type: ColliderType.Ellipse, position: [0, 0], rX: 150, rY: 80, traits: ColliderTraits.BlockMovementAndItem }],
		visibilityRadius: 150,
		dontLoadAsset: true,
	},
	'mid-blocker-01': {
		colliders: [{ type: ColliderType.Box, angle: degToRad(30), position: [49, -77], height: 219, width: 55, traits: ColliderTraits.BlockAll }],
		visibilityRadius: 170,
		dontLoadAsset: true,
	},
	'mid-blocker-02': {
		zOffset: -150,
		colliders: [{ type: ColliderType.Box, position: [-50, -100], height: 250, width: 40, traits: ColliderTraits.BlockAll }],
		visibilityRadius: 170,
		dontLoadAsset: true,
	},
	'mid-blocker-03': {
		zOffset: -60,
		colliders: [{ type: ColliderType.Box, angle: degToRad(-30), position: [-80, -30], height: 150, width: 50, traits: ColliderTraits.BlockAll }],
		visibilityRadius: 150,
		dontLoadAsset: true,
	},
	'high-blocker-01': {
		colliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 80, traits: ColliderTraits.BlockAll }],
		visibilityRadius: 110,
		dontLoadAsset: true,
	},
	'high-blocker-02': {
		colliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 80, traits: ColliderTraits.BlockAll }],
		visibilityRadius: 110,
		dontLoadAsset: true,
	},
	// END blocker pieces //
	'high-blocker-01-var01': makeBlockPropConfig(),
	'high-blocker-01-var02': makeBlockPropConfig(),
	'high-blocker-01-var03': makeBlockPropConfig(),
	'high-blocker-01-var04': makeBlockPropConfig(),
	'mid-blocker-01-var01': makeBlockPropConfig(),
	'mid-blocker-01-var02': makeBlockPropConfig(),
	'mid-blocker-01-var03': makeBlockPropConfig(),
	'mid-blocker-01-var04': makeBlockPropConfig(),
	'mid-blocker-01-var05': makeBlockPropConfig(),
	'mid-blocker-01-var06': makeBlockPropConfig(),
	'low-blocker-01-var01': makeBlockPropConfig(),
	'low-blocker-01-var02': makeBlockPropConfig(),
	'low-blocker-01-var03': makeBlockPropConfig(),
	'low-blocker-01-var04': makeBlockPropConfig(),
	'low-blocker-01-var05': makeBlockPropConfig(),
	'low-blocker-01-var06': makeBlockPropConfig(),
	'small-common-01': {
		//colliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 20, traits: ColliderTraits.BlockMovementAndItem }],
		visibilityRadius: 50,
		zOffset: -60,
	},
	'small-common-02': {
		//colliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 20, traits: ColliderTraits.BlockMovementAndItem }],
		visibilityRadius: 50,
		zOffset: -60,
	},
	'small-common-03': {
		//colliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 20, traits: ColliderTraits.BlockMovementAndItem }],
		visibilityRadius: 40,
		zOffset: -60,
	},

	// rocks
	'small-uncommon-01': {
		colliders: [{ type: ColliderType.Circle, position: [19, -14], radius: 67, traits: ColliderTraits.BlockMovementAndItem }],
		zOffset: -60,
		visibilityRadius: 120,
	},
	// box
	'small-uncommon-02': {
		colliders: [{ type: ColliderType.Circle, position: [6, -20], radius: 53, traits: ColliderTraits.BlockAll }],
		visibilityRadius: 110,
	},
	// grass
	'small-uncommon-03': {
		zOffset: -3,
		//colliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 20, traits: ColliderTraits.BlockMovementAndItem }],
		visibilityRadius: 115,
	},
	// coconuts
	'small-uncommon-04': {
		//colliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 20, traits: ColliderTraits.BlockMovementAndItem }],
		visibilityRadius: 70,
		zOffset: -60,
	},

	'small-rare-01': {
		colliders: [{ type: ColliderType.Circle, position: [-46, -54], radius: 75, traits: ColliderTraits.BlockAll }],
		visibilityRadius: 160,
		zOffset: -60,
	},
	'small-rare-02': {
		colliders: [{ type: ColliderType.Circle, position: [0, -10], radius: 36, traits: ColliderTraits.BlockMovementAndItem }],
		visibilityRadius: 110,
	},
	// flowers with bottle
	'small-rare-03': {
		//colliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 20, traits: ColliderTraits.BlockMovementAndItem }],
		visibilityRadius: 130,
		zOffset: -25,
	},

	// palm tree
	'med-common-01': {
		colliders: [{ type: ColliderType.Circle, position: [-6, -26], radius: 50, traits: ColliderTraits.BlockAll }],
		visibilityRadius: 440,
	},
	// palm tree
	'med-common-02': {
		colliders: [{ type: ColliderType.Circle, position: [1, -19], radius: 40, traits: ColliderTraits.BlockAll }],
		visibilityRadius: 385,
	},
	// palm tree
	'med-common-03': {
		colliders: [{ type: ColliderType.Circle, position: [4, -17], radius: 40, traits: ColliderTraits.BlockAll }],
		visibilityRadius: 340,
		zOffset: -13,
	},
	// palm tree
	'med-common-04': {
		colliders: [{ type: ColliderType.Circle, position: [6, -29], radius: 36, traits: ColliderTraits.BlockAll }],
		visibilityRadius: 365,
		zOffset: -30,
	},

	// med wreck
	'med-uncommon-01': {
		colliders: [{ type: ColliderType.Circle, position: [26, -43], radius: 93, traits: ColliderTraits.BlockAll }],
		visibilityRadius: 220,
		zOffset: -60,
	},
	// small wreck
	'med-uncommon-02': {
		colliders: [{ type: ColliderType.Box, position: [-85, -57], width: 206, height: 73, angle: degToRad(-15), traits: ColliderTraits.BlockAll }],
		visibilityRadius: 165,
	},
	// anchor
	'med-uncommon-03': {
		colliders: [{ type: ColliderType.Box, position: [-85, -57], width: 206, height: 73, traits: ColliderTraits.BlockAll }],
		visibilityRadius: 160,
	},

	'large-uncommon-01-var01': {
		colliders: [...beachLargeUncommonColliders, { type: ColliderType.Circle, position: [272, 157], radius: 59, traits: ColliderTraits.BlockAll }],
		//zeroHeight: ['large-uncommon-01'],
		visibilityRadius: 645,
	},
	'large-uncommon-01-var02': {
		colliders: beachLargeUncommonColliders,
		//zeroHeight: ['large-uncommon-01'],
		visibilityRadius: 440,
	},
	'large-uncommon-01-var03': {
		colliders: [...beachLargeUncommonColliders, { type: ColliderType.Circle, position: [334, 181], radius: 50, traits: ColliderTraits.BlockAll }, { type: ColliderType.Circle, position: [-218, 175], radius: 50, traits: ColliderTraits.BlockAll }],
		//zeroHeight: ['large-uncommon-01'],
		visibilityRadius: 525,
	},
	'large-rare-01-var01': {
		colliders: beachLargeRare,
		visibilityRadius: 640,
	},
	'large-rare-01-var02': {
		colliders: beachLargeRare,
		visibilityRadius: 600,
	},
	'large-rare-01-var03': {
		colliders: [...beachLargeRare, { type: ColliderType.Circle, position: [395, 152], radius: 100, traits: ColliderTraits.BlockAll }],
		zOffset: 150,
		visibilityRadius: 580,
	},
	'large-rare-02-var01': {
		colliders: [
			{ type: ColliderType.Ellipse, position: [-117, 21], rX: 301, rY: 154, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [206, 118], radius: 125, traits: ColliderTraits.BlockAll },
		],
		zOffset: -100,
		visibilityRadius: 460,
	},
	'large-rare-02-var02': {
		colliders: [
			{ type: ColliderType.Box, position: [-343, -5], width: 549, height: 154, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [-278, 194], radius: 59, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [47, 182], radius: 59, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [264, 169], radius: 50, traits: ColliderTraits.BlockAll },
		],
		zOffset: -100,
		visibilityRadius: 470,
	},
	'large-rare-02-var03': {
		colliders: [{ type: ColliderType.Ellipse, position: [-79, 36], rX: 339, rY: 154, traits: ColliderTraits.BlockAll }],
		zOffset: -100,
		visibilityRadius: 450,
	},
	// Outpost
	outpost: {
		zOffset: -175,
		dontLoadAsset: true,
	},
	'outpost-window': {
		zOffset: -5000,
		dontLoadAsset: true,
	},
	'outpost-front': {
		zOffset: -5000,
		dontLoadAsset: true,
	},
	worm_crates: {
		zOffset: -25,
		dontLoadAsset: true,
	},
	worm_sign: {
		zOffset: -25,
		dontLoadAsset: true,
	},
	campfire: {
		zOffset: -25,
		dontLoadAsset: true,
	},
	worm_crates02: {
		zOffset: -35,
		dontLoadAsset: true,
	},
	'outpost-roof': {
		zOffset: -25,
		dontLoadAsset: true,
	},
	'fence-horizontal': {
		zOffset: -5,
		dontLoadAsset: true,
	},
	'fence-vert': {
		zOffset: -20,
		dontLoadAsset: true,
	},

	// Boss Arena
	'Boss/boss-01': {
		zOffset: -325,
		dontLoadAsset: true,
	},
	'Boss/boss-02': {
		// Right Wall
		zOffset: -1000,
		dontLoadAsset: true,
	},
	'Boss/boss-03': {
		// Top Right Wall
		zOffset: -350,
		dontLoadAsset: true,
	},
	'Boss/boss-03-b': {
		// Top Right Wall
		zOffset: -150,
		dontLoadAsset: true,
	},
	'Boss/boss-03-c': {
		// Top Right Wall
		zOffset: -100,
		dontLoadAsset: true,
	},
	'Boss/boss-04': {
		// Top Left Wall
		zOffset: -150,
		dontLoadAsset: true,
	},
	'Boss/boss-04-b': {
		// Top Left Wall
		zOffset: -240,
		dontLoadAsset: true,
	},
	'Boss/boss-04-c': {
		// Top Left Wall
		zOffset: -300,
		dontLoadAsset: true,
	},
	'Boss/boss-05': {
		// Left Wall
		zOffset: -1000,
		dontLoadAsset: true,
	},
	'Boss/boss-06': {
		// Bottom Left Wall
		zOffset: -300,
		dontLoadAsset: true,
	},
	'Boss/boss-06-b': {
		// Bottom Left Wall
		zOffset: -250,
		dontLoadAsset: true,
	},
	'Boss/boss-06-c': {
		// Bottom Left Wall
		zOffset: -150,
		dontLoadAsset: true,
	},
	'Boss/boss-07': {
		// Bottom Right Wall
		zOffset: -150,
		dontLoadAsset: true,
	},
	'Boss/boss-07-b': {
		// Bottom Right Wall
		zOffset: -250,
		dontLoadAsset: true,
	},
	'Boss/boss-07-c': {
		// Bottom Right Wall
		zOffset: -250,
		dontLoadAsset: true,
	},
	'Boss/ground-boss': {
		zOffset: -80,
		dontLoadAsset: true,
	},
	// Crab nest POI
	'CrabNestPOI/poi-07': {
		// Bottom left rock
		zOffset: -100,
		dontLoadAsset: true,
	},
	'CrabNestPOI/poi-08': {
		// Bottom right rock
		zOffset: -200,
		dontLoadAsset: true,
	},
	'CrabNestPOI/poi-09': {
		// Top right rock
		zOffset: -100,
		dontLoadAsset: true,
	},
	'CrabNestPOI/poi-10': {
		// Top left rock
		zOffset: -300,
		dontLoadAsset: true,
	},
	'edge-tile-west': {
		dontLoadAsset: true,
	},
	'edge-tile-west-posts': {
		dontLoadAsset: true,
	},
	docks: {
		dontLoadAsset: true,
	},
	'beach-alter': {
		overrideAtlas: 'beach-alter',
		colliders: [
			{ type: ColliderType.Ellipse, position: [-18,-315], rX: 95, rY: 99, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [-181,-274], rX: 72, rY: 79, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [129,-276], rX: 72, rY: 79, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [-276,-178], rX: 51, rY: 48, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [260,-183], rX: 51, rY: 56, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [-249,-50], rX: 24, rY: 21, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [224,-34], rX: 24, rY: 21, traits: ColliderTraits.BlockMovementAndItem },
		],
		rigged: false,
		zOffset: -350,
		visibilityRadius: 1400,
	},
	'party-alter-animated': {
		overrideAtlas: 'beach-alter',
		zOffset: -200,
		visibilityRadius: 1400,
	},
}
