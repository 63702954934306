import { BuffDefinition } from './buff-definition'
import { InstancedPer, StackStyle } from './buff-enums'
import { BuffIdentifier } from './buff.shared'

export const IGNITE_DURATION = 2500
export const IGNITE_TICK_INTERVAL = 500
export const POISON_DURATION = 8000
export const POISON_TICK_INTERVAL = 320

export const GenericBuffs: BuffDefinition[] = [
	new BuffDefinition({
		identifier: BuffIdentifier.Invulnerable,
		duration: 3000,
		startingStacks: 1,
		stackStyle: StackStyle.RollingStackDurationSeparately,
		reapplyStacks: 1,
		reapplyDuration: 3000,
		showToClient: true,
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.NoCollideWithProjectile,
		duration: 3000,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyStacks: 1,
		reapplyDuration: 3000,
		showToClient: true,
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.Poison,
		duration: POISON_DURATION,
		instancedPerOwner: InstancedPer.Player,
		startingStacks: 1,
		stackStyle: StackStyle.RollingStackDurationSeparately,
		reapplyStacks: 1,
		reapplyDuration: POISON_DURATION,
		tickInterval: POISON_TICK_INTERVAL,
		showToClient: true,
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.Ignite,
		duration: IGNITE_DURATION,
		instancedPerOwner: InstancedPer.Player,
		startingStacks: 1,
		stackStyle: StackStyle.IncreaseDuration,
		reapplyStacks: 1,
		reapplyDuration: IGNITE_DURATION / 2,
		capDuration: 12000,
		tickInterval: IGNITE_TICK_INTERVAL,
		showToClient: true,
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.Bleed,
		duration: 6000,
		instancedPerOwner: InstancedPer.Player,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyStacks: 1,
		reapplyDuration: 6000,
		showToClient: true,
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.BleedStackImmune,
		duration: 1000,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		reapplyStacks: 1,
		reapplyDuration: 160,
		showToClient: false,
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.Chill,
		duration: 4000,
		instancedPerOwner: InstancedPer.Player,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyStacks: 1,
		reapplyDuration: 4000,
		showToClient: true,
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.ChilledResistPlayer,
		duration: 12000,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyStacks: 1,
		reapplyDuration: 12000,
		showToClient: false,
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.ChilledResistEnemy,
		duration: 30000,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyStacks: 1,
		reapplyDuration: 30000,
		showToClient: false,
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.Chilled,
		duration: 4000,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyStacks: 1,
		reapplyDuration: 4000,
		showToClient: true,
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.Shock,
		duration: 6000,
		instancedPerOwner: InstancedPer.Player,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyStacks: 1,
		reapplyDuration: 6000,
		showToClient: true,
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.StunResistPlayer,
		duration: 25000,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyStacks: 1,
		reapplyDuration: 25000,
		showToClient: false,
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.StunResistEnemy,
		duration: 50000,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyStacks: 1,
		reapplyDuration: 50000,
		showToClient: false,
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.Stun,
		duration: 1500,
		startingStacks: 1,
		stackStyle: StackStyle.IncreaseDuration,
		reapplyStacks: 1,
		reapplyDuration: 1000,
		showToClient: true,
	}),
]

Object.freeze(GenericBuffs)
