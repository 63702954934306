import nengi from 'nengi'
import { uuid } from '../../utils/primitive-types'

class PlayerReputationCommand {
	static protocol = {
		userId: nengi.String,
	}
	userId: uuid
	/**
	 * Creates an instance of PlayerReputationCommand.
	 * @param {uuid} userId User Id
	 * @memberof PlayerReputationCommand
	 */
	constructor(userId: uuid) {
		this.userId = userId
	}
}

export default PlayerReputationCommand