












import { mapActions, mapGetters } from 'vuex'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import SubscriptionItemContainer from '../reusable-components/item-containers/subscription-item-container.vue'

export default {
	name: 'Subscription',
	components: {
		MainPanel,
		SubscriptionItemContainer,
	},
	data() {
		return {}
	},
	computed: {
		...mapGetters('subscription', ['getActiveSubTier']),
	},
	methods: {
		...mapActions('inGame', ['closeActivePanel']),
	},
}
