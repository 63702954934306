export enum SpineDataName {
	PLAYER_SKINS = 'player-skins',
	BIPED_ENEMY = 'biped-enemy',
	NPC_DEFAULT = 'player-skins',
	BUBBA = 'bubba',
	MERCHANT_A = 'merchant-npc',
	MERCHANT_B = 'merchant-npc-b',
	MERCHANT_C = 'merchant-npc-c',
	STASH_NPC = 'stash-npc',
	WISP_NPC = 'wisp-npc',
	WORM = 'worm',
	FURNACE_NPC_A = 'furnace-npc-a',
	FURNACE_NPC_B = 'furnace-npc-b',
	ENT = 'ent',
	ENT_BEARD = 'ent-beard',
	WASP = 'wasp',
	BEE = 'bee',
	SHRIEKER = 'shrieker',
	SHRIEKER_VINES = 'shrieker-vines',
	SPOREKID = 'sporekid',
	MUSHIE = 'mushie',
	BLIMPIE = 'blimpie',
	SHAMBLING_MOUND = 'shambling-mound',
	SHAMBLING_MOUND_AOE = 'shambling-mound-aoe',
	EYEBAT = 'eyebat',
	FUNGI_BOSS = 'fungi-boss',
	FUNGI_SWAMP = 'fungi-swamp',
	FUNGI_AOE = 'fungi-aoe',
	BEACH_BOSS = 'beach-boss',
	ASP = 'asp',
	SKELE_BEACH = 'skeleton-beach',
	SKELE_AOE = 'beach-aoe',
	MOSQUITO = 'mosquito',
	SLIVER = 'sliver',
	SLIVER_CRACKED = 'sliver-cracked',
	CRAB = 'crab',
	CRAB_TOP_HAT = 'crab-top-hat',
	SPIDER = 'spider',
	THORN_WOLF = 'thorn-wolf',
	THORN_WOLF_CONE = 'thorn-wolf-cone',
	WISP = 'wisp',
	GOBLIN = 'goblin',
	GOBLIN_JESTER = 'goblin-jester',
	BONE_SPIRIT = 'bone-spirit',
	DEATH_DRAKE = 'death-drake',
	SKELETAL_WHELP = 'skeletal-whelp',
	SKELETAL_WHELP_HATCHED = 'skeletal-whelp-hatched',
	SKELETON_KNIGHT = 'skeleton-knight',
	SKELETON_KNIGHT_BALLOON = 'skeleton-knight-balloon',
	DREAMER_FUNGI = 'dreamer-fungi',
	DREAMER_PRISM = 'dreamer-prism',
	DREAMER_FANCY = 'dreamer-fancy',
	CRAB_NEST_A = 'crab-nest-a',
	CRAB_NEST_B = 'crab-nest-b',
	CRAB_NEST_C = 'crab-nest-c',
	CRAB_NEST_D = 'crab-nest-d',
	CRAB_NEST_E = 'crab-nest-e',
	CRAB_NEST_F = 'crab-nest-f',
	HERMIT_CRAB = 'hermit-crab',
	SHAMBLING_TOWER = 'shambling-tower',
	STRANGLEVINE = 'stranglevine',
	DRAGON_SKULL = 'dragon-skull',
	GOLEM_PRISM = 'golem-prism',
	GOLEM_PRISM_AOE = 'prism-golem-aoe',
	CRYSTAL_SPIDER = 'crystal-spider',
	FOREST_BOSS = 'forest-boss',
	MTX_NPC = 'mtx-npc',
	DOCKS_NPC = 'docks-npc',
	WEATHERMAN_NPC = 'weatherman-npc',
	IDENTIFICATION_NPC = 'identification-npc',
	ANT_NPC_A = 'ant-npc-a',
	ANT_NPC_B = 'ant-npc-b',
	ISLAND_NPC = 'island-npc',
	BALLOON_NPC = 'balloon-npc',
	PIT_OF_CHANCES_NPC = 'pit-of-chances-npc',
	HIGHLANDS_BOSS = 'highlands-boss',
	HIGHLANDS_AOE = 'highlands-aoe',
	PRISM_BOSS = 'prism-boss',
	PRISM_AOE = 'prism-aoe',
	PRISM_AOE_MD = 'prism-aoe-md',
	PRISM_AOE_LG = 'prism-aoe-lg',
	FUNGI_WOLF = 'fungi-wolf',
	PRISM_WISP = 'prism-wisp',
	PRISMFLY = 'prismfly',
	WASP_FOREST = 'wasp-forest',
	GOLEM_HIGHLANDS = 'golem-highlands',
	GOLEM_HIGHLANDS_AOE = 'highlands-golem-aoe',
	DUMMY_A = 'test-range-animated10',
	DUMMY_B = 'test-range-animated9',
	DUMMY_C = 'test-range-animated8',
	DUMMY_D = 'test-range-animated7',
	DUMMY_E = 'test-range-animated6',
	DUMMY_F = 'test-range-animated5',
	DUMMY_G = 'test-range-animated3',
	DUMMY_H = 'test-range-animated2',
	BULWARK = 'skill-bulwark-model',
	ALTER_SPAWN = 'alter-spawn-animation',
	PLAYER_EMOTE = 'emote-typing',
	PET_EMOTE = 'emote-pet',
	PET_BEAR = 'pet-bear',
	PET_RACCOON = 'pet-raccoon',
	PET_WHALE = 'pet-whale',
	PET_DOG = 'pet-dog',
	PET_BIRD = 'pet-bird',
	PET_CAT = 'pet-cat',
	AURUM_GENERIC_NPC = 'generic-aurum',
	IRON_GENERIC_NPC = 'generic-iron',
	DAWN_GENERIC_NPC = 'generic-scion',
	AURUM_RECRUITMENT_NPC = 'recruitment-aurum',
	IRON_RECRUITMENT_NPC = 'recruitment-iron',
	DAWN_RECRUITMENT_NPC = 'recruitment-scion',
	CRAB_BARREL = 'beach-crab-barrel',
	MERMAID_MAST = 'beach-mermaid-mast',
	LANTERN_POST = 'forest-lantern-post',
	TANNING_RACK = 'forest-tanning-rack',
	FUNGI_SKELETON = 'fungi-skeleton',
	SPORE_SACK = 'fungi-spore-sack',
	MASSIVE_SKULL_PILE = 'highlands-massive-skull-pile',
	MYSTERIOUS_GEM  = 'highlands-mysterious-gem',
	CORRUPTED_LAMP = 'prism-corrupted-lamp',
	CRYSTAL_HAND = 'prism-crystal-hand',

}

export enum PlayerSkinType {
	RANGER = 'ranger',
	RANGER_02 = 'ranger-02',
	RANGER_03 = 'ranger-03',

	DWARF = 'dwarf',
	DWARF_02 = 'dwarf-02',
	DWARF_03 = 'dwarf-03',

	BARBARIAN = 'animal-barbarian',
	BARBARIAN_02 = 'animal-barbarian-02',
	BARBARIAN_03 = 'animal-barbarian-03',

	SORCERESS = 'sorceress',
	SORCERESS_02 = 'sorceress-02',
	SORCERESS_03 = 'sorceress-03',

	WIZARD = 'wizard',
	WIZARD_02 = 'wizard-02',
	WIZARD_03 = 'wizard-03',

	KNIGHT = 'human-knight',
	KNIGHT_02 = 'human-knight-02',
	KNIGHT_03 = 'human-knight-03',

	ELF = 'elf-ranger',
	ELF_02 = 'elf-ranger-02',
	ELF_03 = 'elf-ranger-03',

	AURUM_SOLDIER = "aurum-alliance-soldier",
	SCION_SOLDIER = "scions-of-dawn-soldier",
	ORDER_SOLDIER = "order-of-iron-soldier",
	
	AURUM_BLACKMARK = "aurum-alliance-blackmark",
	SCION_SOLARA = "scions-of-dawn-solara",
	ORDER_REHKOSH = "order-of-iron-rehkosh",

}

export const PlayerSkinValues = Object.values(PlayerSkinType)

export enum NPCSkinType {
	// TODO3: Update this with proper NPC skins when we have them
	DEBUG_NPC = 'dwarf',
	BEAR_BROWN = 'brown',
	RACCOON_GREY = 'grey',
	DOG_GOLDEN_RETREIVER = 'golden-retriever',
	CAT_CALICO = 'calico',
	WHALE_KILLER = 'killer',
	BIRD_ROBIN = 'robin',
	BIRD_CHICKEN = 'chicken',

}

export enum EnemyBipedSkinType {
	DEFAULT = 'default',
	SKELEMAGE = 'skelemage',
}
