import { isEqual } from "lodash";
import { prismPOIs } from "../../biome/shared/biome-data/poi-data";
import { BoxColliderConfig, ColliderTraits, ColliderType } from "../../collision/shared/colliders";
import { roundToDecimals } from "../../utils/math";

export const CLIFF_EXTRA_WIDTH = 20

export enum ColliderEntityType {
	Bulwark,
	NoShoot,
	EndRun,
	Cliff,
	Cliff1,
	Cliff2,
	Cliff3,
	Cliff4 // 3 and 4 are not used currently, see cliffConfigs
}

/** **cliffConfigs:**  
 * although cliffs are created dynamically, there are only 3 different configs used currently.
 * ColliderEntityType.CliffX is sent to client, and this is treated as:
 *  index 0 for Cliff
 *  index 1 for Cliff1, etc...
 * so the client can now these colliders for csp
 */
const cliffConfigs = [
	{ width: 512 + CLIFF_EXTRA_WIDTH, height: 128, rotation: 0 },
	{ width: 629.7 + CLIFF_EXTRA_WIDTH, height: 114.5, rotation: 0.46 },
	{ width: 629.7 + CLIFF_EXTRA_WIDTH, height: 114.5, rotation: -0.46 },
]

const endOfRunBlocker = prismPOIs.alwaysPlaced.filter(poiData => poiData.endOfRunBlocker)[0].endOfRunBlocker as BoxColliderConfig

export function createColliderConfigFromEnum(colliderEntityType: ColliderEntityType) {
	if (colliderEntityType === ColliderEntityType.EndRun) {
		return [endOfRunBlocker]
	}

	const idx = colliderEntityType - ColliderEntityType.Cliff
	if (idx < 0) {
		return null
	}
	const cliffConfig = cliffConfigs[idx]
	const { width, height, rotation } = cliffConfig
	const colliderConfig: BoxColliderConfig[] = [
		{
			width,
			height,
			position: [0, 0],
			angle: rotation,
			type: ColliderType.Box,
			traits: ColliderTraits.BlockMovementAndItem,
		},
	]
	return colliderConfig
}

export function getCliffShapeIdx(width: number, height: number, rotation: number): number {
	const shapeId = { width: roundToDecimals(width, 1), height: roundToDecimals(height, 1), rotation: roundToDecimals(rotation, 2) }
	for (let i = 0; i < cliffConfigs.length; i++) {
		const element = cliffConfigs[i];
		if (isEqual(element, shapeId)) {
			return i
		}
	}
	throw new Error('no config for' + JSON.stringify(shapeId))
}
