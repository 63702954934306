import nengi from 'nengi'
import { ModCategory } from '../../engine/shared/game-data/stat-type-mod-category'
import { timeInSeconds } from '../../utils/primitive-types'

class EquippedNewGearMessage {
	static protocol = {
		itemType: { type: nengi.UInt16 },
		itemSubType: { type: nengi.UInt16 },
		cooldownDuration: { type: nengi.Float32 },
		slot: { type: nengi.String },
		skillType: { type: nengi.UInt16 },
	}

	itemType: string
	itemSubType: string
	cooldownDuration: timeInSeconds
	slot: string
	skillType: ModCategory

	constructor(params: EquippedNewGearMessage) {
		this.itemType = params.itemType
		this.itemSubType = params.itemSubType
		this.cooldownDuration = params.cooldownDuration
		this.slot = params.slot
		this.skillType = params.skillType
	}
}

export default EquippedNewGearMessage
