























































































import { mapGetters, mapActions, mapMutations } from 'vuex'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import PanelDivider from '../reusable-components/panel-divider/panel-divider.vue'
import { getEmoteIconStyle } from '../state/emotes-ui-state'
import EmotesGridItemContainer from '../reusable-components/item-containers/emotes-grid-item-container.vue'
import EmoteInventoryItem from '../reusable-components/inventory-item/emote-grid-item.vue'

export default {
	name: 'EmoteCustomizationPanel',
	components: {
		MainPanel,
		PanelDivider,
		EmotesGridItemContainer,
		EmoteInventoryItem,
	},
	props: {},
	data() {
		return {
            mouseX: 0,
            mouseY: 0,
			mouseBelowHalf: false
		}
	},
	computed: {
		...mapGetters('emotes', ['wheelActive', 'wheelXPos', 'wheelYPos', 'equipedEmotes', 'ownedEmotes', 'selectedCustomizationEmote']),
	},
    created: function () {
        window.addEventListener('mousemove', this.updateMousePos)
    },
    destroyed: function () {
        window.removeEventListener('mousemove', this.updateMousePos)
    },
	methods: {
		...mapMutations('emotes', ['setSelectedCustomizationEmote']),
		...mapActions('inGame', ['closeActivePanel']),
		updateMousePos(event) {
			this.mouseX = event.clientX
			this.mouseY = event.clientY
			this.mouseBelowHalf = (this.mouseY >= this.$refs.sliceOne.getBoundingClientRect().y)
        },
		emoteIconStyle(index) {
			return getEmoteIconStyle(this.equipedEmotes[index], 60, 71)
		},
		isSelected(index) {
			return this.selectedCustomizationEmote === index
		},
		setSelected(index) {
			if (this.selectedCustomizationEmote === index) {
				this.setSelectedCustomizationEmote(-1)
			} else {
				this.setSelectedCustomizationEmote(index)
			}
		}
	},
}
