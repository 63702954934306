<template>
	<div
		class="side-panel"
		:style="{
			transform: `perspective(1px) rotate3d(0,1,0,0.00000000001deg) translateY(-50%) translateZ(0) scale(${currentScale})`,
			'transform-origin': 'center right',
			right: `${getRight}px`,
		}"
	>
		<div class="title-container">
			<h4 class="title-text">{{ headerName }}</h4>
		</div>
		<div :class="showContentBackground ? 'flyout-content' : 'flyout-content-no-border-image'">
			<slot name="side-panel-content"> </slot>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import {} from '../../state/ui-scale.ui-state'
export default {
	name: 'SidePanel',
	computed: {
		...mapGetters('UIScale', ['currentScale', 'panelWidth']),
		getRight() {
			return this.panelWidth * this.currentScale - 34
		},
	},
	props: {
		headerName: {
			type: String,
			required: true,
			default: 'Default Header Name',
		},
		showContentBackground: {
			type: Boolean,
			required: false,
			default: true,
		},
	},
}
</script>

<style lang="less" scoped>
@import '../../global/fonts/text.less';
@import '../../global/bg-gradients';

.side-panel {
	padding: 35px 45px 70px 30px;
	border-image: url('../panels/assets/side-panel-bg.png') 92 1 108 108 fill / 92px 1px 108px 108px;
	min-width: 325px;
	min-height: 325px;
	position: relative;
	display: inline-block;
	position: absolute;
	height: 1125px;
	top: 50%;
	z-index: 100;
	perspective: 1px;
	.title-container {
		display: flex;
		justify-content: center;
		.title-text {
			.panel-label();
			margin: 0;
			width: 390px;
			position: relative;
			text-align: center;
			border-image: url('../../../../assets/ui-assets/item-details-panel/base-title-box-sliced.png') 37 59 37 59 fill;
			border-image-width: 37px 59px 37px 59px;
		}
	}
	.flyout-content {
		position: relative;
		height: 100%;
		border-image-slice: 18 70 470 70 fill;
		border-image-width: 18px 70px 470px 70px;
		border-image-outset: 0px 0px 0px 0px;
		border-image-repeat: stretch stretch;
		border-image-source: url('../detailed-item-view/assets/item-details-bg-blue.png');
	}
	.flyout-content-no-border-image {
		position: relative;
		height: 100%;
	}
}
</style>
