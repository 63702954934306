































import MainPanel from '../reusable-components/panels/main-panel.vue'
import ArrowButton from '../reusable-components/buttons/arrow-button.vue'
import TextButton from '../reusable-components/buttons/text-button.vue'
import InventoryItem from '../reusable-components/inventory-item/inventory-item.vue'
import PanelDivider from '../reusable-components/panel-divider/panel-divider.vue'
import { mapGetters, mapMutations } from 'vuex'
import { WormHornSubtype } from '../../loot/shared/worm-horn-sub-type'

export default {
	name: 'WormHorn',
	components: {
		MainPanel,
		ArrowButton,
		TextButton,
		InventoryItem,
		PanelDivider,
	},
	props: {},
	data() {
		return {
			standardHorn: {
				id: 1,
				unidentified: false,
				itemType: 'Worm Horn',
				itemTypeEnum: 4000,
				itemSubType: 4001,
				name: 'Standard Worm Horn',
				rarity: 'Legendary',
				attuned: true,
			},
			premiumHorn: {
				id: 2,
				unidentified: false,
				itemType: 'Worm Horn',
				itemTypeEnum: 4000,
				itemSubType: 4002,
				name: 'Premium Worm Horn',
				rarity: 'Legendary',
				attuned: true,
			},
		}
	},
	computed: {
		...mapGetters('itemContainers', ['standardWormHornCount', 'premiumWormHornCount']),
	},
	methods: {
		goToGameOver() {
			this.$store.dispatch('outpostWormMail/dismissWormHornPrompt')
		},
		useWormHornStandardBtn() {
			this.$store.dispatch('outpostWormMail/useWormHorn', WormHornSubtype.Standard)
		},
		useWormHornPremiumBtn() {
			this.$store.dispatch('outpostWormMail/useWormHorn', WormHornSubtype.Premium)
		},
	},
}
