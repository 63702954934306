import moment, { Moment } from "moment"
import { highResolutionTimestamp } from "../../utils/debug"
import { beginningOfDayFromMoment, beginningOfWeekFromMoment } from "../../utils/timing"

let nowInterval = null

export const timeUIStore = () => {
    return {
        namespaced: true,
        state: {
            nowMoment: moment().utc(),
            unixDiff: 0,
            sharedHighResolutionTimestamp: 0,
        },
        getters: {
            nowMoment(state) {
                return state.nowMoment
            },
            sharedHighResolutionTimestamp(state) {
                return state.sharedHighResolutionTimestamp
            },
            beginningOfDay(state) {
                return beginningOfDayFromMoment(state.nowMoment)
            },
            beginningOfWeek(state) {
                return beginningOfWeekFromMoment(state.nowMoment)
            },
            endOfWeek(state) {
                return (state.nowMoment.clone()).isoWeekday(7).hour(23).minute(59).second(59)
            }
        },
        mutations: {
            updateNow(state, nowMoment: Moment) {
                nowMoment.subtract(state.unixDiff)
                state.nowMoment = nowMoment
            },
            updateSharedHighResolutionTimestamp(state, timestamp) {
                state.sharedHighResolutionTimestamp = timestamp
            },
            setUnixDiff(state, unixDiff: number) {
                state.unixDiff = unixDiff
            }
        },
        actions: {

        }
    }
}

export function startTimeUpdateInterval(uiInst) {
    if(nowInterval) {
        clearInterval(nowInterval)
    }

    let counter = 0 
    nowInterval = setInterval(() => {
        if(counter % 10 === 0) {
            uiInst.emitEvent('time/updateNow', moment().utc())
        }

        uiInst.emitEvent('time/updateSharedHighResolutionTimestamp', highResolutionTimestamp())
        uiInst.emitAsyncEvent('furnace/getCollectableCoins')

        counter++
    }, 100)
}
