import { flatten } from 'lodash'
import { Vector } from 'sat'
import { EnemyType } from '../../../ai/shared/ai-types'
import { END_OF_WORLD_ISLAND_BONUS_SIZE } from '../../../engine/shared/game-data/end-of-world-config'
import { ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { SpineDataName } from '../../../models-animations/shared/spine-config'
import { ParticipationMetric, POIData, POIType, WeightedPOIData, POIPropData} from '../../../world/shared/poi-data-types'
import { LEVELS_PER_BIOME } from '../biome-constants'
import { ENEMY_NAMES } from '../../../ai/shared/enemies/enemy-names'

const BOSS_POI_RESPAWN = 60
const NORMAL_POI_RESPAWN = 40
const SAFE_ZONE_STRING = 'you are in a safe zone'
const BOSS_RESPAWN_STRING = 'Boss will Respawn in'
const ALTAR_RESPAWN_STRING = 'The Altar is active! Guardians will return in'
const DOCK_Y_POSITION = 2205 + 1024 * 3 + 512 * 2

const TOWN_POI_STATE = {
	allowProjectiles: false,
	allowRandomSpawns: false,
	safeFromEnemies: true,
}

const WORLD_EDGE_ACTIVE_STATE = {
	allowProjectiles: false,
	allowRandomSpawns: false,
	safeFromEnemies: true,
}

const DOCKS_ACTIVE_STATE = {
	allowProjectiles: true,
	allowRandomSpawns: false,
	safeFromEnemies: false,
}

const END_ISLAND_ACTIVE_STATE = {
	allowProjectiles: false,
	allowRandomSpawns: false,
	safeFromEnemies: true,
}

const WORLD_EDGE_SAFETY_ACTIVE_STATE = {
	allowProjectiles: true,
	allowRandomSpawns: false,
	safeFromEnemies: false,
}

const OUTPOST_ACTIVE_STATE = {
	allowProjectiles: false,
	allowRandomSpawns: false,
	safeFromEnemies: true,
}

const BOSS_ACTIVE_STATE = {
	allowProjectiles: true,
	allowRandomSpawns: false,
	safeFromEnemies: false,
}

const BOSS_RESET_STATE = {
	allowProjectiles: true,
	allowRandomSpawns: false,
	safeFromEnemies: false,
}

const CONQUER_ACTIVE_STATE = {
	allowProjectiles: true,
	allowRandomSpawns: false,
	safeFromEnemies: false,
}

const CONQUER_RESET_STATE = {
	allowProjectiles: true,
	allowRandomSpawns: false,
	safeFromEnemies: false,
}

const MONSTER_RAID_ACTIVE_STATE = {
	allowProjectiles: true,
	allowRandomSpawns: true,
	safeFromEnemies: false,
}

const MONSTER_RAID_RESET_STATE = {
	allowProjectiles: true,
	allowRandomSpawns: true,
	safeFromEnemies: false,
}

const WORLD_END_STATE = {
	allowProjectiles: true,
	allowRandomSpawns: false,
	safeFromEnemies: false,
}


export type BiomePOIs = {
	alwaysPlaced: POIData[]
	randomlyPlaced: WeightedPOIData[]
}

export const townPOIs: BiomePOIs = {
	randomlyPlaced: [],
	alwaysPlaced: [
		{
			text: '',
			debugName: 'town',
			shape: { type: ColliderType.Circle, position: [0, 0], radius: 100, traits: ColliderTraits.BlockProjectile },
			positioningShape: { type: ColliderType.Circle, position: [0, 0], radius: 100, traits: ColliderTraits.Trigger },
			count: 1,
			absoluteLocation: true,
			x: 5120,
			y: 5120,
			activeState: { ...TOWN_POI_STATE },
			props: [
				{
					name: 'augmentation',
					x: 0,
					y: 0,
				},
				{
					name: 'docks',
					x: 0,
					y: 0,
				},
				{
					name: 'docks-animated',
					x: 0,
					y: 0,
				},
				{
					name: 'furnace',
					x: 0,
					y: 0,
				},
				{
					name: 'furnace-animated',
					x: 0,
					y: 0,
				},
				{
					name: 'marketplace',
					x: 0,
					y: 0,
				},
				{
					name: 'pit-of-chances',
					x: 0,
					y: 0,
				},
				{
					name: 'pit-of-chances-animated',
					x: 0,
					y: 0,
				},
				{
					name: 'stash',
					x: 0,
					y: 0,
				},
				{
					name: 'test-range',
					x: 0,
					y: 0,
				},
				{
					name: 'test-range-animated',
					x: 0,
					y: 0,
				},
				{
					name: 'test-range-animated4',
					x: 0,
					y: 0,
				},
				{
					name: 'vip',
					x: 0,
					y: 0,
				},
				{
					name: 'water',
					x: 0,
					y: 0,
				},
				{
					name: 'worm-mail',
					x: 0,
					y: 0,
				},
				{
					name: 'Aurum-Alliance-base',
					x: 0,
					y: 0,
				},
				{
					name: 'Aurum-Alliance-base-animated',
					x: 0,
					y: 0,
				},
				{
					name: 'Scions-of-Dawn-base',
					x: 0,
					y: 0,
				},
				{
					name: 'Scions-of-Dawn-animated',
					x: 0,
					y: 0,
				},
				{
					name: 'Order-of-Iron-base',
					x: 0,
					y: 0,
				},
				{
					name: 'Order-of-Iron-animated',
					x: 0,
					y: 0,
				},
				{
					name: 'plaza',
					x: 0,
					y: 0,
				},
				{
					name: 'plaza-animated',
					x: 0,
					y: 0,
				},
				{
					name: 'bg',
					x: 0,
					y: 0,
				},
				{
					name: 'bg-animated',
					x: 0,
					y: 0,
				},
			],
			type: POIType.TownPOI,
		},
	]
}

export const BEACH_LEFT_X = 1000

export const beachPOIs: BiomePOIs = {
	randomlyPlaced: [
		{
			text: 'Conquer the crab nests!',
			respawnText: 'Nests respawn in',
			debugName: 'beach-conquer',
			shape: { type: ColliderType.Circle, position: [0, 0], radius: 1500, traits: ColliderTraits.BlockProjectile },
			positioningShape: { type: ColliderType.Circle, position: [0, 0], radius: 1500, traits: ColliderTraits.Trigger },
			rezAreaColliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 1500, traits: ColliderTraits.Trigger }],
			count: 1,
			absoluteLocation: false,
			xPlacement: [0.15, 0.8875],
			yPlacement: [0, 1],
			activeState: { ...CONQUER_ACTIVE_STATE },
			props: [
				{
					name: 'poi-01',
					x: 0,
					y: 0,
				},
			],
			type: POIType.Conquer,
			resettingState: { ...CONQUER_RESET_STATE },
			participationMetric: ParticipationMetric.DamageDealt,
			participationThreshold: 200,
			lootLevel: LEVELS_PER_BIOME * 1,
			eventResetTime: NORMAL_POI_RESPAWN,
			enemySpawns: [
				{
					x: -620.67,
					y: -374.28,
					enemy: ENEMY_NAMES.CRAB_NEST_A,
				},
				{
					x: -1199.14,
					y: 597.28,
					enemy: ENEMY_NAMES.CRAB_NEST_B,
				},
				{
					x: -706.7,
					y: 274.19,
					enemy: ENEMY_NAMES.CRAB_NEST_C,
				},
				{
					x: -87.67,
					y: -308.11,
					enemy: ENEMY_NAMES.CRAB_NEST_D,
				},
				{
					x: 22.81,
					y: 309.76,
					enemy: ENEMY_NAMES.CRAB_NEST_E,
				},
				{
					x: 330.87,
					y: -124.18,
					enemy: ENEMY_NAMES.CRAB_NEST_F,
				},
				{
					x: 764.27,
					y: -652.32,
					enemy: ENEMY_NAMES.CRAB_NEST_A,
				},
				{
					x: 860.72,
					y: 313.86,
					enemy: ENEMY_NAMES.CRAB_NEST_F,
				},
			],
			weight: 50
		},
		{
			text: 'Kill all the crabs!',
			respawnText: 'Crabs respawn in',
			debugName: 'beach-monster-raid',
			shape: { type: ColliderType.Circle, position: [0, 750], radius: 1000, traits: ColliderTraits.BlockProjectile },
			positioningShape: { type: ColliderType.Circle, position: [0, 750], radius: 1200, traits: ColliderTraits.Trigger },
			count: 1,
			absoluteLocation: false,
			xPlacement: [0.15, 0.8875],
			yPlacement: [0, 1],
			activeState: { ...MONSTER_RAID_ACTIVE_STATE },
			props: [
				{
					name: 'poi-02-ground',
					x: 0,
					y: 0,
				},
				{
					name: 'poi-02-lighthouse',
					x: 0,
					y: 0,
				},
			],
			type: POIType.MonsterRaid,
			resettingState: { ...MONSTER_RAID_RESET_STATE },
			triggerRadius: 1700,
			completionPercent: 0.8,
			completionMessage: 'The remaining crabs burrow back into hiding...',
			participationMetric: ParticipationMetric.KillsTagged,
			participationThreshold: 0.04,
			lootLevel: LEVELS_PER_BIOME * 1,
			eventResetTime: NORMAL_POI_RESPAWN,
			waves: [
				{
					delayInSeconds: 0,
					spawns: [
						{ enemy: ENEMY_NAMES.CRAB, count: 3 },
						{ enemy: ENEMY_NAMES.HERMIT_CRAB, count: 2 },
						{ enemy: ENEMY_NAMES.MOSQUITO, count: 1 },
					],
					message: 'Crabs are assaulting the Beach Lighthouse!',
				},
				{
					completionForNextWave: 0.8,
					spawns: [
						{ enemy: ENEMY_NAMES.CRAB, count: 3 },
						{ enemy: ENEMY_NAMES.HERMIT_CRAB, count: 4 },
						{ enemy: ENEMY_NAMES.MOSQUITO, count: 1 },
					],
				},
				{
					completionForNextWave: 0.8,
					spawns: [
						{ enemy: ENEMY_NAMES.CRAB, count: 3 },
						{ enemy: ENEMY_NAMES.HERMIT_CRAB, count: 4 },
						{ enemy: ENEMY_NAMES.MOSQUITO, count: 2 },
					],
				},
				{
					completionForNextWave: 0.8,
					spawns: [
						{ enemy: ENEMY_NAMES.HERMIT_CRAB, count: 5 },
						{ enemy: ENEMY_NAMES.CRAB, count: 2, enemyType: EnemyType.CHAMPION },
					],
				},
			],
			weight: 50
		},
		{
			text: 'Augh, Mosquitos!',
			respawnText: 'Mosquitos respawn in',
			debugName: 'beach-mosquito-pools',
			shape: { type: ColliderType.Circle, position: [0, 0], radius: 1000, traits: ColliderTraits.BlockProjectile },
			positioningShape: { type: ColliderType.Circle, position: [0, 0], radius: 1000, traits: ColliderTraits.Trigger },
			count: 1,
			absoluteLocation: false,
			xPlacement: [0.15, 0.8875],
			yPlacement: [0, 1],
			activeState: { ...MONSTER_RAID_ACTIVE_STATE },
			// dark magic using a SEAF to dynamically change the prop-set at server startup
			// could also use this to dynamically change the *waves* that spawn, so a given prop-set has dynamic gameplay
			// props: [],
			props: (function(): POIPropData[] {
				return [
					[
						{
							name: 'poi-05-mosquito-pools',
							x: 0,
							y: 0,
						},
						// {
						// 	name: 'poi-05-mosquito-pools-base',
						// 	x: 0,
						// 	y: 0,
						// },
					],
				].pickRandom()
			})(),
			type: POIType.MonsterRaid,
			resettingState: { ...MONSTER_RAID_RESET_STATE },
			triggerRadius: 1200,
			completionPercent: 1.0,
			completionMessage: 'The freshly hatched horde of mosquitoes has been dispatched… for now.',
			participationMetric: ParticipationMetric.KillsTagged,
			participationThreshold: 0.04,
			lootLevel: LEVELS_PER_BIOME * 1,
			eventResetTime: NORMAL_POI_RESPAWN,
			// TO BE CLEARED - NEED DAN'S BLACK MAGIC from 860442b377a914b26e0aa1d0c6cdfcc948fa6d8f
			// newEnemySpawnedFn: (enemy, currentWave: number) => {
			// 	if (enemy.enemyType === EnemyType.CHAMPION) {
			// 		enemy.lootDropQuantity /= 2
			// 	}
			// },
			waves: [
				{
					delayInSeconds: 0,
					spawns: [
						{ enemy: ENEMY_NAMES.MOSQUITO, count: 12 },
					],
				},
				{
					completionForNextWave: 1.0,
					spawns: [
						{ enemy: ENEMY_NAMES.MOSQUITO, count: 13 },
					],
				},
				{
					completionForNextWave: 1.0,
					spawns: [
						{ enemy: ENEMY_NAMES.MOSQUITO, count: 13 },
						{ enemy: ENEMY_NAMES.MOSQUITO, count: 1, enemyType: EnemyType.CHAMPION },
					],
				},
				{
					completionForNextWave: 1.0,
					spawns: [
						{ enemy: ENEMY_NAMES.MOSQUITO, count: 14 },
						{ enemy: ENEMY_NAMES.MOSQUITO, count: 1, enemyType: EnemyType.CHAMPION },
					],
				},
				{
					completionForNextWave: 1.0,
					spawns: [
						{ enemy: ENEMY_NAMES.MOSQUITO, count: 14 },
						{ enemy: ENEMY_NAMES.MOSQUITO, count: 2, enemyType: EnemyType.CHAMPION },
					],
				},
				{
					completionForNextWave: 1.0,
					spawns: [
						{ enemy: ENEMY_NAMES.MOSQUITO, count: 15 },
						{ enemy: ENEMY_NAMES.MOSQUITO, count: 2, enemyType: EnemyType.CHAMPION },
					],
				},
				{
					completionForNextWave: 1.0,
					spawns: [
						{ enemy: ENEMY_NAMES.MOSQUITO, count: 16 },
						{ enemy: ENEMY_NAMES.MOSQUITO, count: 2, enemyType: EnemyType.CHAMPION },
					],
				},
			],
			weight:50
		},
	
		{
			text: 'Lost sailors have risen!',
			respawnText: 'Something mysterious happens in',
			debugName: 'beach-lost-sailors',
			shape: { type: ColliderType.Circle, position: [0, 0], radius: 800, traits: ColliderTraits.BlockProjectile },
			positioningShape: { type: ColliderType.Circle, position: [0, 0], radius: 800, traits: ColliderTraits.Trigger },
			rezAreaColliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 800, traits: ColliderTraits.Trigger }],
			count: 1,
			absoluteLocation: false,
			xPlacement: [0, 0.8875],
			yPlacement: [0, 1],
			activeState: { ...CONQUER_ACTIVE_STATE },
			props: [
				{
					name: 'poi-03-shipwreck',
					x: 0,
					y: 0,
				},
				// {
				// 	name: 'poi-03-shipwreck-base',
				// 	x: 0,
				// 	y: 0,
				// },
			],
			type: POIType.Conquer,
			resettingState: { ...CONQUER_RESET_STATE },
			participationMetric: ParticipationMetric.DamageDealt,
			participationThreshold: 200,
			lootLevel: LEVELS_PER_BIOME * 2,
			eventResetTime: NORMAL_POI_RESPAWN,
			enemySpawns: [
				{
					x: 0,
					y: 0,
					enemy: ENEMY_NAMES.SKELETON_BEACH,
					enemyType: EnemyType.CHAMPION,
				},
				{
					x: -150,
					y: -150,
					enemy: ENEMY_NAMES.SKELETON_BEACH,
					enemyType: EnemyType.CHAMPION,
				},
				{
					x: 150,
					y: 150,
					enemy: ENEMY_NAMES.SKELETON_BEACH,
					enemyType: EnemyType.CHAMPION,
				},
				{
					x: -150,
					y: 150,
					enemy: ENEMY_NAMES.SKELETON_BEACH,
				},
				{
					x: 150,
					y: -150,
					enemy: ENEMY_NAMES.SKELETON_BEACH,
				},
				{
					x: 300,
					y: 300,
					enemy: ENEMY_NAMES.SKELETON_BEACH,
				},
				{
					x: -300,
					y: -300,
					enemy: ENEMY_NAMES.SKELETON_BEACH,
				},
				{
					x: 150,
					y: 300,
					enemy: ENEMY_NAMES.ASP,
				},
				{
					x: -150,
					y: -300,
					enemy: ENEMY_NAMES.ASP,
				},
				{
					x: 150,
					y: -300,
					enemy: ENEMY_NAMES.ASP,
				},
				{
					x: -150,
					y: 300,
					enemy: ENEMY_NAMES.ASP,
				},
				{
					x: -400,
					y: -400,
					enemy: ENEMY_NAMES.CRAB_TOP_HAT,
				},
				{
					x: 400,
					y: 400,
					enemy: ENEMY_NAMES.CRAB_TOP_HAT,
				},
			],
			weight:50
		},
	
	
		{
			text: 'Capitalism, ho!',
			respawnText: 'The CraBourgeois return in',
			debugName: 'beach-crabpost',
			shape: { type: ColliderType.Circle, position: [0, 0], radius: 1000, traits: ColliderTraits.BlockProjectile },
			positioningShape: { type: ColliderType.Circle, position: [0, 0], radius: 1000, traits: ColliderTraits.Trigger },
			count: 1,
			absoluteLocation: false,
			xPlacement: [0.15, 0.8875],
			yPlacement: [0, 1],
			activeState: { ...MONSTER_RAID_ACTIVE_STATE },
			props: [
				{
					name: 'poi-04-crabpost',
					x: 0,
					y: 0,
				},
				// {
				// 	name: 'poi-04-crabpost-base',
				// 	x: 0,
				// 	y: 0,
				// },
			],
			type: POIType.MonsterRaid,
			resettingState: { ...MONSTER_RAID_RESET_STATE },
			triggerRadius: 1200,
			completionPercent: 1.0,
			completionMessage: 'You\'ve prevented crab-xtortion for now.',
			participationMetric: ParticipationMetric.KillsTagged,
			participationThreshold: 0.04,
			lootLevel: LEVELS_PER_BIOME * 1,
			eventResetTime: NORMAL_POI_RESPAWN,
			waves: [
				{
					delayInSeconds: 0,
					spawns: [
						{ enemy: ENEMY_NAMES.MOSQUITO, count: 1 },
					],
				},
				{
					completionForNextWave: 1.0,
					spawns: [
						{ enemy: ENEMY_NAMES.CRAB, count: 6 },
						{ enemy: ENEMY_NAMES.WEAK_TINY_CRAB, count: 10 },
					],
				},
				{
					completionForNextWave: 1.0,
					spawns: [
						{ enemy: ENEMY_NAMES.CRAB_TOP_HAT, count: 5 },
					],
				},
				{
					completionForNextWave: 1.0,
					spawns: [
						{ enemy: ENEMY_NAMES.CRAB, count: 6 },
						{ enemy: ENEMY_NAMES.CRAB_TOP_HAT, count: 1, enemyType: EnemyType.CHAMPION },
					],
				},
			],
			weight:50
		},
	
	],
	alwaysPlaced: [
		{
			text: SAFE_ZONE_STRING,
			debugName: 'beach-dock',
			shape: { type: ColliderType.Box, position: [0, 0], width: 1000, height: 12288, traits: ColliderTraits.BlockProjectile },
			positioningShape: { type: ColliderType.Box, position: [0, 0], width: 2000, height: 12288, traits: ColliderTraits.Trigger },
			count: 1,
			absoluteLocation: true,
			x: 0,
			y: 0,
			activeState: { ...WORLD_EDGE_ACTIVE_STATE },
			lootLevel: LEVELS_PER_BIOME * 1,
			props: [
				//top to bottom
				{
					name: 'west-edge-top-cap',
					x: BEACH_LEFT_X - 342,
					y: 2205 - 620,
					shared: true,
				},
				{
					name: 'edge-tile-west',
					x: BEACH_LEFT_X,
					y: 2205,
					shared: true,
				},
				{
					name: 'water',
					x: BEACH_LEFT_X,
					y: 2205,
					shared: true,
				},
				{
					name: 'edge-tile-west',
					x: BEACH_LEFT_X,
					y: 2205 + 1024 * 1,
					shared: true,
				},
				{
					name: 'water',
					x: BEACH_LEFT_X,
					y: 2205 + 1024 * 1,
					shared: true,
				},
				{
					name: 'edge-tile-west',
					x: BEACH_LEFT_X,
					y: 2205 + 1024 * 2,
					shared: true,
				},
				{
					name: 'water',
					x: BEACH_LEFT_X,
					y: 2205 + 1024 * 2,
					shared: true,
				},
				{
					name: 'edge-tile-west',
					x: BEACH_LEFT_X,
					y: 2205 + 1024 * 3,
					shared: true,
				},
				{
					name: 'water',
					x: BEACH_LEFT_X,
					y: 2205 + 1024 * 3,
					shared: true,
				},
				{
					name: 'docks',
					x: BEACH_LEFT_X,
					y: DOCK_Y_POSITION,
					shared: true,
				},
				{
					name: 'seagull',
					x: BEACH_LEFT_X,
					y: DOCK_Y_POSITION,
					shared: true,
				},
				{
					name: 'boat',
					x: BEACH_LEFT_X,
					y: DOCK_Y_POSITION,
					shared: true,
				},
				{
					name: 'lights',
					x: BEACH_LEFT_X,
					y: DOCK_Y_POSITION,
					shared: true,
				},
				{
					name: 'water',
					x: BEACH_LEFT_X,
					y: DOCK_Y_POSITION,
					shared: true,
				},
				// {
				// 	name: 'edge-tile-west',
				// 	x: BEACH_LEFT_X,
				// 	y: 2205 + 1024 * 4,
				// 	shared: true,
				// },
				// {
				// 	name: 'water',
				// 	x: BEACH_LEFT_X,
				// 	y: 2205 + 1024 * 4,
				// 	shared: true,
				// },
				{
					name: 'edge-tile-west',
					x: BEACH_LEFT_X,
					y: 2205 + 1024 * 5,
					shared: true,
				},
				{
					name: 'water',
					x: BEACH_LEFT_X,
					y: 2205 + 1024 * 5,
					shared: true,
				},
				{
					name: 'edge-tile-west',
					x: BEACH_LEFT_X,
					y: 2205 + 1024 * 6,
					shared: true,
				},
				{
					name: 'water',
					x: BEACH_LEFT_X,
					y: 2205 + 1024 * 6,
					shared: true,
				},
				{
					name: 'edge-tile-west',
					x: BEACH_LEFT_X,
					y: 2205 + 1024 * 7,
					shared: true,
				},
				{
					name: 'water',
					x: BEACH_LEFT_X,
					y: 2205 + 1024 * 7,
					shared: true,
				},
				{
					name: 'edge-tile-west',
					x: BEACH_LEFT_X,
					y: 2205 + 1024 * 8,
					shared: true,
				},
				{
					name: 'water',
					x: BEACH_LEFT_X,
					y: 2205 + 1024 * 8,
					shared: true,
				}, {
					name: 'west-edge-bottom-cap',
					x: BEACH_LEFT_X - 332,
					y: 2205 + 1024 * 8 + 450,
					shared: true,
				},
			],
			type: POIType.Dock,
		},
		{
			text: '', //Beach World Edge Docks Safe Zone; don't want giant text here
			debugName: 'beach-world-edge',
			shape: { type: ColliderType.Box, position: [0, -512], width: 750, height: 800, traits: ColliderTraits.None },
			positioningShape: { type: ColliderType.Box, position: [0, -512], width: 750, height: 800, traits: ColliderTraits.Trigger },
			count: 1,
			absoluteLocation: true,
			x: 1000,
			y: 2205 + 1024 * 3,
			activeState: { ...DOCKS_ACTIVE_STATE },
			lootLevel: LEVELS_PER_BIOME * 1,
			props: [],
			type: POIType.WorldEdge,
		},
		{
			text: SAFE_ZONE_STRING,
			debugName: 'beach-outpost',
			shape: { type: ColliderType.Box, position: [-667, -480], width: 1353, height: 1039, traits: ColliderTraits.BlockProjectile },
			positioningShape: { type: ColliderType.Box, position: [-1150 + 120, -950 + 200], width: 2400, height: 2200, traits: ColliderTraits.Trigger },
			count: 1,
			absoluteLocation: false,
			xPlacement: [0.1, 0.33],
			yPlacement: [0.35, 0.65],
			activeState: { ...OUTPOST_ACTIVE_STATE },
			lootLevel: LEVELS_PER_BIOME * 1,
			props: [
				{
					name: 'outpost-base',
					x: 120,
					y: 80,
				},
				{
					name: 'outpost-campfire',
					x: 120,
					y: 80,
				},
				{
					name: 'outpost-torches',
					x: 120,
					y: 80,
				},
				{
					name: 'outpost-bubba',
					x: 120,
					y: 80,
				},
			],
			type: POIType.Outpost,
		},
		{
			text: 'The B.F.C.',
			respawnText: BOSS_RESPAWN_STRING,
			debugName: 'beach-boss-arena',
			shape: { type: ColliderType.Ellipse, position: [-6, -27], rX: 2204, rY: 1332, traits: ColliderTraits.BlockProjectile },
			positioningShape: { type: ColliderType.Box, position: [-2250, -1250], width: 4500, height: 3000, traits: ColliderTraits.Trigger },
			activationColliders: [{ type: ColliderType.Ellipse, position: [-6, -27], rX: 2204, rY: 1332, traits: ColliderTraits.Trigger }],
			deactivationSeconds: 5,
			count: 1,
			absoluteLocation: false,
			xPlacement: [0.55, 0.8475],
			yPlacement: [0, 1],
			activeState: { ...BOSS_ACTIVE_STATE },
			props: [
				{
					name: 'boss-arena',
					x: 0,
					y: 0,
				},
			],
			bossDroppedLootOriginX: 600,
			bossDroppedLootOriginY: 700,
			type: POIType.Boss,
			resettingState: { ...BOSS_RESET_STATE },
			participationMetric: ParticipationMetric.PlayersParticipatingScaledDamage,
			eventResetTime: BOSS_POI_RESPAWN,
			lootLevel: LEVELS_PER_BIOME * 1,
			enemySpawns: [
				{
					x: 0,
					y: 250,
					enemy: ENEMY_NAMES.BEACH_BOSS,
					enemyType: EnemyType.BOSS,
				},
			],
			arenaGates: {
				insideArenaShape: [{ type: ColliderType.Ellipse, traits: ColliderTraits.Trigger, position: [-6, -27], rX: 2104, rY: 1239 }],

				gates: [
					{
						// 6 o'clock
						npcOffset: new Vector(-57, 1280),
						npcRadius: 400,
						teleportInDestination: [{ type: ColliderType.Circle, position: [155, 1000], radius: 100, traits: ColliderTraits.None }],
						teleportOutDestination: [{ type: ColliderType.Circle, position: [-100, 1400], radius: 100, traits: ColliderTraits.None }],
					},
				],
			},
		},
		{
			text: 'Beach Resurrection Altar',
			respawnText: ALTAR_RESPAWN_STRING,
			debugName: 'beach-resurrection',
			shape: { type: ColliderType.Circle, position: [0, 0], radius: 500, traits: ColliderTraits.BlockProjectile },
			positioningShape: { type: ColliderType.Circle, position: [0, 0], radius: 500, traits: ColliderTraits.Trigger },
			rezAreaColliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 500, traits: ColliderTraits.Trigger }],
			count: 1,
			shouldResurrectDeadPlayersInBoundsWhenBetweenEvents: true,
			noEnchantmentReward: true,
			absoluteLocation: false,
			xPlacement: [0.45, 0.8875],
			yPlacement: [0, 1],
			activeState: { ...CONQUER_ACTIVE_STATE },
			props: [
				{
					name: 'beach-alter',
					x: 0,
					y: 0,
				},
				{
					name: 'party-alter-animated',
					x: 0,
					y: 0,
				},
			],
			type: POIType.Conquer,
			resettingState: { ...CONQUER_RESET_STATE },
			participationMetric: ParticipationMetric.DamageDealt,
			participationThreshold: 200,
			lootLevel: LEVELS_PER_BIOME * 1,
			eventResetTime: NORMAL_POI_RESPAWN,
			enemySpawns: [
				{
					x: 0,
					y: 150,
					enemy: ENEMY_NAMES.CRAB,
					enemyType: EnemyType.CHAMPION,
				},
				{
					x: 175,
					y: 100,
					enemy: ENEMY_NAMES.HERMIT_CRAB,
					enemyType: EnemyType.LOOTLESS_CHAMPION,
				},
				{
					x: 100,
					y: 100,
					enemy: ENEMY_NAMES.ASP,
					enemyType: EnemyType.LOOTLESS_CHAMPION,
				},
				{
					x: -100,
					y: 100,
					enemy: ENEMY_NAMES.ASP,
					enemyType: EnemyType.LOOTLESS_CHAMPION,
				},
			],
		},

	]
}

export const forestPOIs: BiomePOIs = {
	randomlyPlaced: [
		{
			text: 'The Eyes',
			respawnText: 'respawn in',
			debugName: 'forest-conquer',
			shape: { type: ColliderType.Circle, position: [0, 0], radius: 800, traits: ColliderTraits.BlockProjectile },
			positioningShape: { type: ColliderType.Circle, position: [0, 0], radius: 800, traits: ColliderTraits.Trigger },
			rezAreaColliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 800, traits: ColliderTraits.Trigger }],
			count: 1,
			absoluteLocation: false,
			xPlacement: [0, 0.8875],
			yPlacement: [0, 1],
			activeState: { ...CONQUER_ACTIVE_STATE },
			props: [
				{
					name: 'poi-01',
					x: 0,
					y: 0,
				},
				{
					name: 'poi-01-cocoon-01',
					x: 0,
					y: 0,
				},
				{
					name: 'poi-01-cocoon-02',
					x: 0,
					y: 0,
				},
				{
					name: 'poi-01-cocoon-03',
					x: 0,
					y: 0,
				},
			],
			type: POIType.Conquer,
			resettingState: { ...CONQUER_RESET_STATE },
			participationMetric: ParticipationMetric.DamageDealt,
			participationThreshold: 200,
			lootLevel: LEVELS_PER_BIOME * 2,
			eventResetTime: NORMAL_POI_RESPAWN,
			enemySpawns: [
				{
					x: 0,
					y: 0,
					enemy: ENEMY_NAMES.SPIDER,
					enemyType: EnemyType.CHAMPION,
				},
				{
					x: -150,
					y: -150,
					enemy: ENEMY_NAMES.SPIDER,
					enemyType: EnemyType.CHAMPION,
				},
				{
					x: 150,
					y: 150,
					enemy: ENEMY_NAMES.SPIDER,
					enemyType: EnemyType.CHAMPION,
				},
				{
					x: -150,
					y: 150,
					enemy: ENEMY_NAMES.EYEBAT,
				},
				{
					x: 150,
					y: -150,
					enemy: ENEMY_NAMES.EYEBAT,
				},
				{
					x: 300,
					y: 300,
					enemy: ENEMY_NAMES.EYEBAT,
				},
				{
					x: -300,
					y: -300,
					enemy: ENEMY_NAMES.EYEBAT,
				},
				{
					x: 150,
					y: 300,
					enemy: ENEMY_NAMES.SPIDER,
				},
				{
					x: -150,
					y: -300,
					enemy: ENEMY_NAMES.SPIDER,
				},
			],
			weight: 100
		},
		
	

	/*{
		text: 'The Village Rises Against',
		respawnText: 'The goblins will return to their homes in',
		debugName: 'forest-goblin-village',
		shape: { type: ColliderType.Circle, position: [0, 0], radius: 800, traits: ColliderTraits.BlockProjectile },
		positioningShape: { type: ColliderType.Circle, position: [0, 0], radius: 800, traits: ColliderTraits.Trigger },
		rezAreaColliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 800, traits: ColliderTraits.Trigger }],
		count: 1,
		absoluteLocation: false,
		xPlacement: [0, 0.8875],
		yPlacement: [0, 1],
		activeState: { ...CONQUER_ACTIVE_STATE },
		// dark magic using a SEAF to dynamically change the prop-set at server startup
		// could also use this to dynamically change the *waves* that spawn, so a given prop-set has dynamic gameplay
		...(function(): { props: POIPropData[], enemySpawns: EnemySpawnData[] } {
			return [
				{ 
					props: [
						// {
						// 	name: 'poi-02-goblin-camp-base',
						// 	x: 0,
						// 	y: 0,
						// },
						
						// {
						// 	name: 'poi-02-b',
						// 	x: 0,
						// 	y: 0,
						// },
						
						// {
						// 	name: 'poi-02-base',
						// 	x: 0,
						// 	y: 0,
						// },
						{
							name: 'poi-02-fire-back',
							x: 0,
							y: 0,
						},
						{
							name: 'poi-02-fire-top',
							x: 0,
							y: 0,
						},
						{
							name: 'poi-02-flag',
							x: 0,
							y: 0,
						},
					],
					enemySpawns: [
						{
							x: 0,
							y: 0,
							enemy: ENEMY_NAMES.CRAB_NEST_A,
						},
						{
							x: -200,
							y: -150,
							enemy: ENEMY_NAMES.GOBLIN,
							enemyType: EnemyType.CHAMPION,
						},
						{
							x: 200,
							y: -150,
							enemy: ENEMY_NAMES.THORN_WOLF,
							enemyType: EnemyType.CHAMPION,
						},
					],
				}
			].pickRandom()
		})(),
		type: POIType.Conquer,
		resettingState: { ...CONQUER_RESET_STATE },
		participationMetric: ParticipationMetric.DamageDealt,
		participationThreshold: 200,
		lootLevel: LEVELS_PER_BIOME * 2,
		eventResetTime: NORMAL_POI_RESPAWN,
		weight: 50 
	},*/

	{
		text: 'The Darkened Garden',
		respawnText: 'The flowers will sleep for another',
		debugName: 'forest-darkened-garden',
		shape: { type: ColliderType.Circle, position: [0, 0], radius: 1000, traits: ColliderTraits.BlockProjectile },
		positioningShape: { type: ColliderType.Circle, position: [0, 0], radius: 1000, traits: ColliderTraits.Trigger },
		count: 1,
		absoluteLocation: false,
		xPlacement: [0.15, 0.8875],
		yPlacement: [0, 1],
		activeState: { ...MONSTER_RAID_ACTIVE_STATE },
		props: [
			// {
			// 	name: 'poi-03-darkened-garden-base',
			// 	x: 0,
			// 	y: 0,
			// },
			{
				name: 'poi-03-darkened-garden',
				x: 0,
				y: 0,
			},
		],
		type: POIType.MonsterRaid,
		resettingState: { ...MONSTER_RAID_RESET_STATE },
		triggerRadius: 1200,
		completionPercent: 1.0,
		completionMessage: 'The forest itself quiets and sleeps.',
		participationMetric: ParticipationMetric.KillsTagged,
		participationThreshold: 0.04,
		lootLevel: LEVELS_PER_BIOME * 1,
		eventResetTime: NORMAL_POI_RESPAWN,
		waves: [
			{
				delayInSeconds: 0,
				spawns: [
					{ enemy: ENEMY_NAMES.STRANGLEVINE, count: 2 },
					{ enemy: ENEMY_NAMES.WISP, count: 4 },
					{ enemy: ENEMY_NAMES.WISP, count: 1, enemyType: EnemyType.CHAMPION },
				],
			},
			{
				completionForNextWave: 1.0,
				spawns: [
					{ enemy: ENEMY_NAMES.STRANGLEVINE, count: 4 },
					{ enemy: ENEMY_NAMES.WISP, count: 4 },
					{ enemy: ENEMY_NAMES.WISP, count: 1, enemyType: EnemyType.CHAMPION },
				],
			},
			{
				completionForNextWave: 1.0,
				spawns: [
					{ enemy: ENEMY_NAMES.STRANGLEVINE, count: 2 },
					{ enemy: ENEMY_NAMES.WISP, count: 6 },
					{ enemy: ENEMY_NAMES.WISP, count: 1, enemyType: EnemyType.CHAMPION },
				],
			},
			{
				completionForNextWave: 1.0,
				spawns: [
					{ enemy: ENEMY_NAMES.STRANGLEVINE, count: 2 },
					{ enemy: ENEMY_NAMES.WISP, count: 5 },
					{ enemy: ENEMY_NAMES.WISP, count: 1, enemyType: EnemyType.CHAMPION },
				],
			},
			{
				completionForNextWave: 1.0,
				spawns: [
					{ enemy: ENEMY_NAMES.STRANGLEVINE, count: 3 },
					{ enemy: ENEMY_NAMES.WISP, count: 6 },
					{ enemy: ENEMY_NAMES.WISP, count: 1, enemyType: EnemyType.CHAMPION },
				],
			},
		],
		weight:50
	},
	],
	alwaysPlaced: [
		{
			text: SAFE_ZONE_STRING,
			debugName: 'forest-outpost',
			shape: { type: ColliderType.Box, position: [-675, -480], width: 1294, height: 1046, traits: ColliderTraits.BlockProjectile },
			positioningShape: { type: ColliderType.Box, position: [-1150 + 220, -950 + 200], width: 2400, height: 2200, traits: ColliderTraits.Trigger },
			count: 1,
			absoluteLocation: false,
			xPlacement: [0, 0.33],
			yPlacement: [0.35, 0.65],
			activeState: { ...OUTPOST_ACTIVE_STATE },
			props: [
				{
					name: 'outpost-base',
					x: 210,
					y: 80,
				},
				{
					name: 'outpost-smoke',
					x: 215,
					y: 75,
				},
				{
					name: 'outpost-campfire',
					x: 210,
					y: 80,
				},
				{
					name: 'outpost-lanterns',
					x: 225,
					y: 100,
				},
				{
					name: 'outpost-bubba',
					x: 210,
					y: 80,
				},
				{
					name: 'outpost-bat',
					x: 210,
					y: 80,
				},
			],
			type: POIType.Outpost,
		},
		{
			text: 'The Fearie Queen',
			respawnText: BOSS_RESPAWN_STRING,
			debugName: 'forest-boss-arena',
			shape: { type: ColliderType.Ellipse, position: [-23, 57], rX: 1461, rY: 1146, traits: ColliderTraits.BlockProjectile },
			positioningShape: { type: ColliderType.Circle, position: [0, 0], radius: 2000, traits: ColliderTraits.Trigger },
			count: 1,
			absoluteLocation: false,
			xPlacement: [0.66, 0.8475],
			yPlacement: [0, 1],
			activeState: { ...BOSS_ACTIVE_STATE },
			bossDroppedLootOriginX: 0,
			bossDroppedLootOriginY: 0,
			props: [
				{
					name: 'boss-arena',
					x: 0,
					y: 0,
				},
				{
					name: 'boss-arena-glow-particle',
					x: 0,
					y: 0,
				},
				{
					name: 'boss-arena-tree-animations',
					x: 0,
					y: 0,
				},
				{
					name: 'boss-arena-mushroom-a',
					x: 0,
					y: 0,
				},
				{
					name: 'boss-arena-mushroom-b',
					x: 0,
					y: 0,
				},
				{
					name: 'boss-arena-mushroom-c',
					x: 0,
					y: 0,
				},
			],
			lootLevel: LEVELS_PER_BIOME * 2,
			type: POIType.Boss,
			resettingState: { ...BOSS_RESET_STATE },
			participationMetric: ParticipationMetric.PlayersParticipatingScaledDamage,
			eventResetTime: BOSS_POI_RESPAWN,
			enemySpawns: [
				{
					x: 0,
					y: 0,
					enemy: ENEMY_NAMES.FOREST_BOSS,
					enemyType: EnemyType.BOSS,
				},
			],
			arenaGates: {
				insideArenaShape: [{ type: ColliderType.Ellipse, traits: ColliderTraits.Trigger, position: [54, 106], rX: 1557, rY: 1115 }],

				gates: [
					{
						// 6 o'clock
						npcOffset: new Vector(308, 1375),
						npcRadius: 400,
						teleportInDestination: [{ type: ColliderType.Circle, position: [375, 898], radius: 100, traits: ColliderTraits.None }],
						teleportOutDestination: [{ type: ColliderType.Circle, position: [213, 1518], radius: 100, traits: ColliderTraits.None }],
					},
				],
			},
		},
		{
			text: 'Forest Resurrection Altar',
			respawnText: ALTAR_RESPAWN_STRING,
			debugName: 'forest-resurrection',
			shape: { type: ColliderType.Circle, position: [0, 0], radius: 500, traits: ColliderTraits.BlockProjectile },
			positioningShape: { type: ColliderType.Circle, position: [0, 0], radius: 500, traits: ColliderTraits.Trigger },
			rezAreaColliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 500, traits: ColliderTraits.Trigger }],
			count: 1,
			shouldResurrectDeadPlayersInBoundsWhenBetweenEvents: true,
			noEnchantmentReward: true,
			absoluteLocation: false,
			xPlacement: [0.15, 0.8875],
			yPlacement: [0, 1],
			activeState: { ...CONQUER_ACTIVE_STATE },
			props: [
				{
					name: 'forest-alter',
					x: 0,
					y: 0,
				},
				{
					name: 'party-alter-animated',
					x: 0,
					y: 0,
				},
			],
			type: POIType.Conquer,
			resettingState: { ...CONQUER_RESET_STATE },
			participationMetric: ParticipationMetric.DamageDealt,
			participationThreshold: 200,
			lootLevel: LEVELS_PER_BIOME * 1,
			eventResetTime: NORMAL_POI_RESPAWN,
			enemySpawns: [
				{
					x: 0,
					y: 200,
					enemy: ENEMY_NAMES.GOBLIN,
					enemyType: EnemyType.LOOTLESS_CHAMPION,
				},
				{
					x: 0,
					y: 250,
					enemy: ENEMY_NAMES.THORN_WOLF,
					enemyType: EnemyType.CHAMPION,
				},
				{
					x: 225,
					y: 100,
					enemy: ENEMY_NAMES.WISP,
					enemyType: EnemyType.LOOTLESS_CHAMPION,
				},
				{
					x: -225,
					y: 100,
					enemy: ENEMY_NAMES.WISP,
					enemyType: EnemyType.LOOTLESS_CHAMPION,
				},
			],
		},
	]
}

export const highlandsPOIs: BiomePOIs = {
	randomlyPlaced: [
		{
			text: 'The Boneyard',
			respawnText: 'respawn in',
			debugName: 'highlands-conquer',
			shape: { type: ColliderType.Box, position: [-800, -600], width: 1600, height: 1200, traits: ColliderTraits.BlockProjectile },
			positioningShape: { type: ColliderType.Box, position: [-1100, -800], width: 2200, height: 1800, traits: ColliderTraits.Trigger },
			rezAreaColliders: [{ type: ColliderType.Box, position: [-1100, -800], width: 2200, height: 1800, traits: ColliderTraits.Trigger }],
			count: 1,
			absoluteLocation: false,
			xPlacement: [0.1, 0.9], // best guess
			yPlacement: [0.1, 0.9], // best guess
			activeState: { ...CONQUER_ACTIVE_STATE },
			props: [
				{
					name: 'poi-02',
					x: 0,
					y: 0,
				},
			],
			type: POIType.Conquer,
			resettingState: { ...CONQUER_RESET_STATE },
			participationMetric: ParticipationMetric.DamageDealt,
			participationThreshold: 200,
			lootLevel: LEVELS_PER_BIOME * 3,
			eventResetTime: NORMAL_POI_RESPAWN,
			enemySpawns: [
				{
					x: 0,
					y: 0,
					enemy: ENEMY_NAMES.SKELETON_KNIGHT,
					enemyType: EnemyType.CHAMPION,
				},
				{
					x: 200,
					y: 100,
					enemy: ENEMY_NAMES.SKELETON_KNIGHT,
					enemyType: EnemyType.CHAMPION,
				},
				{
					x: -200,
					y: 100,
					enemy: ENEMY_NAMES.SKELETON_KNIGHT,
					enemyType: EnemyType.CHAMPION,
				},
				{
					x: 0,
					y: 300,
					enemy: ENEMY_NAMES.BONE_SPIRIT,
				},
				{
					x: -400,
					y: -100,
					enemy: ENEMY_NAMES.BONE_SPIRIT,
				},
				{
					x: 400,
					y: -100,
					enemy: ENEMY_NAMES.BONE_SPIRIT,
				},
				{
					x: -600,
					y: 400,
					enemy: ENEMY_NAMES.BONE_SPIRIT,
				},
				{
					x: 600,
					y: 400,
					enemy: ENEMY_NAMES.BONE_SPIRIT,
				},
			],
			weight: 50
		},
		{
			text: 'Death Dealers',
			respawnText: 'respawn in',
			debugName: 'highlands-death-dealers',
			shape: { type: ColliderType.Circle, position: [0, 0], radius: 900, traits: ColliderTraits.BlockProjectile },
			positioningShape: { type: ColliderType.Circle, position: [0, 0], radius: 1200, traits: ColliderTraits.Trigger },
			count: 1,
			absoluteLocation: false,
			xPlacement: [0, 0.8875],
			yPlacement: [0, 1],
			activeState: { ...MONSTER_RAID_ACTIVE_STATE },
			props: [
				{
					name: 'large-rare-02-var03',
					x: 0,
					y: -200,
				},
			],
			type: POIType.MonsterRaid,
			resettingState: { ...MONSTER_RAID_RESET_STATE },
			triggerRadius: 1800,
			completionPercent: 0.8,
			completionMessage: 'The Death Dealers have been laid to rest.',
			participationMetric: ParticipationMetric.KillsTagged,
			participationThreshold: 0.04,
			lootLevel: LEVELS_PER_BIOME * 3,
			eventResetTime: NORMAL_POI_RESPAWN,
			waves: [
				{
					delayInSeconds: 0,
					spawns: [
						{ enemy: ENEMY_NAMES.DEATH_DRAKE, count: 2 },
						{ enemy: ENEMY_NAMES.SKELETAL_WHELP, count: 5 },
						{ enemy: ENEMY_NAMES.DRAGON_SKULL, count: 1 },
					],
				},
				{
					completionForNextWave: 0.8,
					spawns: [
						{ enemy: ENEMY_NAMES.DEATH_DRAKE, count: 2 },
						{ enemy: ENEMY_NAMES.SKELETAL_WHELP, count: 3 },
						{ enemy: ENEMY_NAMES.SKELETAL_WHELP, count: 1, enemyType: EnemyType.CHAMPION },
						{ enemy: ENEMY_NAMES.DRAGON_SKULL, count: 1 },
					],
				},
				{
					completionForNextWave: 0.8,
					spawns: [
						{ enemy: ENEMY_NAMES.DEATH_DRAKE, count: 1, enemyType: EnemyType.CHAMPION },
						{ enemy: ENEMY_NAMES.SKELETAL_WHELP, count: 5 },
						{ enemy: ENEMY_NAMES.DRAGON_SKULL, count: 1, enemyType: EnemyType.CHAMPION },
					],
				},
			],
			weight: 50
		},
		{
			text: 'Progenitor\'s Resting Place',
			respawnText: 'More of the dragon\'s children will come in',
			debugName: 'highlands-progenitors',
			shape: { type: ColliderType.Circle, position: [0, 0], radius: 800, traits: ColliderTraits.BlockProjectile },
			positioningShape: { type: ColliderType.Circle, position: [0, 0], radius: 800, traits: ColliderTraits.Trigger },
			rezAreaColliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 800, traits: ColliderTraits.Trigger }],
			count: 1,
			absoluteLocation: false,
			xPlacement: [0, 0.8875],
			yPlacement: [0, 1],
			activeState: { ...CONQUER_ACTIVE_STATE },
			props: [
				{
					name: 'poi-03-long-dead-dragon',
					x: 0,
					y: 0,
				},
				// {
				// 	name: 'poi-03-long-dead-dragon-base',
				// 	x: 0,
				// 	y: 0,
				// },
			],
			type: POIType.Conquer,
			resettingState: { ...CONQUER_RESET_STATE },
			participationMetric: ParticipationMetric.DamageDealt,
			participationThreshold: 200,
			lootLevel: LEVELS_PER_BIOME * 2,
			eventResetTime: NORMAL_POI_RESPAWN,
			enemySpawns: [
				{
					x: 0,
					y: 0,
					enemy: ENEMY_NAMES.DEATH_DRAKE,
					enemyType: EnemyType.CHAMPION,
				},
				{
					x: 350,
					y: 0,
					enemy: ENEMY_NAMES.DRAGON_SKULL,
					enemyType: EnemyType.CHAMPION,
				},
				{
					x: -350,
					y: 0,
					enemy: ENEMY_NAMES.DRAGON_SKULL,
					enemyType: EnemyType.CHAMPION,
				},
				{
					x: -500,
					y: -200,
					enemy: ENEMY_NAMES.SKELETAL_WHELP,
				},
				{
					x: -400,
					y: -200,
					enemy: ENEMY_NAMES.SKELETAL_WHELP,
				},
				{
					x: -300,
					y: -200,
					enemy: ENEMY_NAMES.SKELETAL_WHELP,
				},
				{
					x: -200,
					y: -200,
					enemy: ENEMY_NAMES.SKELETAL_WHELP,
				},
				{
					x: 200,
					y: -200,
					enemy: ENEMY_NAMES.SKELETAL_WHELP,
				},
				{
					x: 300,
					y: -200,
					enemy: ENEMY_NAMES.SKELETAL_WHELP,
				},
				{
					x: 400,
					y: -200,
					enemy: ENEMY_NAMES.SKELETAL_WHELP,
				},
				{
					x: 500,
					y: -200,
					enemy: ENEMY_NAMES.SKELETAL_WHELP,
				},
				{
					x: 300,
					y: -300,
					enemy: ENEMY_NAMES.SKELETAL_WHELP,
				},
				{
					x: -300,
					y: -300,
					enemy: ENEMY_NAMES.SKELETAL_WHELP,
				},
			],
			weight:50
		},
		
		
	],
	alwaysPlaced: [
		{
			text: SAFE_ZONE_STRING,
			debugName: 'highlands-outpost',
			shape: { type: ColliderType.Box, position: [-649, -380], width: 1401, height: 902, traits: ColliderTraits.BlockProjectile },
			positioningShape: { type: ColliderType.Box, position: [-1150 + 230, -950 + 200], width: 2400, height: 2200, traits: ColliderTraits.Trigger },
			count: 1,
			absoluteLocation: false,
			xPlacement: [0, 0.33],
			yPlacement: [0.35, 0.65],
			activeState: { ...OUTPOST_ACTIVE_STATE },
			props: [
				{
					name: 'outpost-base',
					x: 320,
					y: 80,
				},
				{
					name: 'outpost-lanterns',
					x: 320,
					y: 80,
				},
				{
					name: 'outpost-campfire',
					x: 320,
					y: 80,
				},
				{
					name: 'outpost-bubba',
					x: 320,
					y: 80,
				},
				{
					name: 'outpost-sign',
					x: 320,
					y: 80,
				},
				{
					name: 'outpost-bottom-rock-glow',
					x: 320,
					y: 80,
				},
				{
					name: 'outpost-top-rock-glow',
					x: 320,
					y: 80,
				},
			],
			type: POIType.Outpost,
		},
		{
			text: 'The Gorgon',
			respawnText: BOSS_RESPAWN_STRING,
			debugName: 'highlands-boss-arena',
			shape: { type: ColliderType.Ellipse, position: [28, 83], rX: 2260, rY: 1727, traits: ColliderTraits.BlockProjectile },
			positioningShape: { type: ColliderType.Circle, position: [0, 0], radius: 2650, traits: ColliderTraits.Trigger },
			count: 1,
			absoluteLocation: false,
			xPlacement: [0.66, 0.8475],
			yPlacement: [0, 1],
			bossDroppedLootOriginX: 0,
			bossDroppedLootOriginY: 500,
			activeState: { ...BOSS_ACTIVE_STATE },
			props: [
				{
					name: 'boss-arena',
					x: 0,
					y: 0,
				},
				{
					name: 'boss-arena-animated',
					x: 0,
					y: 0,
				},
				{
					name: 'boss-arena-flag',
					x: 0,
					y: 0,
				},
				{
					name: 'boss-arena-glow-01',
					x: 0,
					y: 0,
				},
				{
					name: 'boss-arena-glow-02',
					x: 0,
					y: 0,
				},
				{
					name: 'boss-arena-glow-03',
					x: 0,
					y: 0,
				},
				{
					name: 'boss-arena-glow-04',
					x: 0,
					y: 0,
				},
				{
					name: 'boss-arena-glow-05',
					x: 0,
					y: 0,
				},
				{
					name: 'boss-arena-glow-06',
					x: 0,
					y: 0,
				},
				{
					name: 'boss-arena-glow-07',
					x: 0,
					y: 0,
				},
			],
			type: POIType.Boss,
			lootLevel: LEVELS_PER_BIOME * 3,
			resettingState: { ...BOSS_RESET_STATE },
			participationMetric: ParticipationMetric.PlayersParticipatingScaledDamage,
			eventResetTime: BOSS_POI_RESPAWN,
			enemySpawns: [
				{
					x: 0,
					y: 500,
					enemy: ENEMY_NAMES.HIGHLANDS_BOSS,
					enemyType: EnemyType.BOSS,
				},
			],
			arenaGates: {
				insideArenaShape: [
					{ type: ColliderType.Ellipse, traits: ColliderTraits.Trigger, position: [0, 0], rX: 2170, rY: 1750 },
					{ type: ColliderType.Ellipse, traits: ColliderTraits.Trigger, position: [1500, 1250], rX: 400, rY: 200 }
				],

				gates: [
					{
						// 6 o'clock
						npcOffset: new Vector(0, 1850),
						npcRadius: 400,
						teleportInDestination: [{ type: ColliderType.Circle, position: [0, 1450], radius: 100, traits: ColliderTraits.None }],
						teleportOutDestination: [{ type: ColliderType.Circle, position: [0, 1950], radius: 100, traits: ColliderTraits.None }],
					},
				],
			},
		},
		{
			text: 'Highlands Resurrection Altar',
			respawnText: ALTAR_RESPAWN_STRING,
			debugName: 'onyx-resurrection',
			shape: { type: ColliderType.Circle, position: [0, 0], radius: 500, traits: ColliderTraits.BlockProjectile },
			positioningShape: { type: ColliderType.Circle, position: [0, 0], radius: 500, traits: ColliderTraits.Trigger },
			rezAreaColliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 500, traits: ColliderTraits.Trigger }],
			count: 1,
			shouldResurrectDeadPlayersInBoundsWhenBetweenEvents: true,
			noEnchantmentReward: true,
			absoluteLocation: false,
			xPlacement: [0.15, 0.8875],
			yPlacement: [0, 1],
			activeState: { ...CONQUER_ACTIVE_STATE },
			props: [
				{
					name: 'highlands-alter',
					x: 0,
					y: 0,
				},
				{
					name: 'party-alter-animated',
					x: 0,
					y: 0,
				},
			],
			type: POIType.Conquer,
			resettingState: { ...CONQUER_RESET_STATE },
			participationMetric: ParticipationMetric.DamageDealt,
			participationThreshold: 200,
			lootLevel: LEVELS_PER_BIOME * 1,
			eventResetTime: NORMAL_POI_RESPAWN,
			enemySpawns: [
				{
					x: 0,
					y: 250,
					enemy: ENEMY_NAMES.DEATH_DRAKE,
					enemyType: EnemyType.CHAMPION,
				},
				{
					x: 175,
					y: 50,
					enemy: ENEMY_NAMES.BONE_SPIRIT,
					enemyType: EnemyType.LOOTLESS_CHAMPION,
				},
				{
					x: -175,
					y: 50,
					enemy: ENEMY_NAMES.BONE_SPIRIT,
					enemyType: EnemyType.LOOTLESS_CHAMPION,
				},
			],
		},

	]
}

export const fungiPOIs: BiomePOIs = {
	randomlyPlaced: [
		{
			text: 'Ack, Shriekers!',
			respawnText: 'respawn in',
			debugName: 'fungi-monster-raid',
			shape: { type: ColliderType.Circle, position: [0, 0], radius: 1300, traits: ColliderTraits.BlockProjectile },
			positioningShape: { type: ColliderType.Circle, position: [0, 0], radius: 1300, traits: ColliderTraits.Trigger },
			count: 1,
			absoluteLocation: false,
			xPlacement: [0, 0.8875],
			yPlacement: [0, 1],
			activeState: { ...MONSTER_RAID_ACTIVE_STATE },
			props: [],
			type: POIType.MonsterRaid,
			resettingState: { ...MONSTER_RAID_RESET_STATE },
			triggerRadius: 1800,
			completionPercent: 0.8,
			completionMessage: 'The shrieks subside, for now...',
			participationMetric: ParticipationMetric.KillsTagged,
			participationThreshold: 0.04,
			lootLevel: LEVELS_PER_BIOME * 4,
			eventResetTime: NORMAL_POI_RESPAWN,
			waves: [
				{
					delayInSeconds: 0,
					spawns: [{ enemy: ENEMY_NAMES.SHRIEKER, count: 10 }],
				},
				{
					completionForNextWave: 0.8,
					spawns: [
						{ enemy: ENEMY_NAMES.SHRIEKER, count: 8 },
						{ enemy: ENEMY_NAMES.ENT, count: 2 },
					],
				},
				{
					completionForNextWave: 0.8,
					spawns: [
						{ enemy: ENEMY_NAMES.SHRIEKER, count: 12 },
						{ enemy: ENEMY_NAMES.ENT, count: 2 },
					],
				},
				{
					completionForNextWave: 0.9,
					spawns: [
						{ enemy: ENEMY_NAMES.SHRIEKER, count: 10 },
						{ enemy: ENEMY_NAMES.SHRIEKER, count: 2, enemyType: EnemyType.CHAMPION },
						{ enemy: ENEMY_NAMES.ENT, count: 2 },
					],
				},
			],
			weight: 50
		},
		{
			text: 'The Towering Mass',
			respawnText: 'towering mass respawns in',
			debugName: 'fungi-tower-mass',
			shape: { type: ColliderType.Circle, position: [0, 0], radius: 1400, traits: ColliderTraits.BlockProjectile },
			positioningShape: { type: ColliderType.Circle, position: [0, 0], radius: 1400, traits: ColliderTraits.Trigger },
			rezAreaColliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 1400, traits: ColliderTraits.Trigger }],
			count: 1,
			absoluteLocation: false,
			xPlacement: [0, 0.8875],
			yPlacement: [0, 1],
			activeState: { ...CONQUER_ACTIVE_STATE },
			props: [
				{
					name: 'poi-01-base',
					x: 0,
					y: 0,
				},
			],
			type: POIType.Conquer,
			resettingState: { ...CONQUER_RESET_STATE },
			participationMetric: ParticipationMetric.DamageDealt,
			participationThreshold: 200,
			lootLevel: LEVELS_PER_BIOME * 4,
			eventResetTime: NORMAL_POI_RESPAWN,
			enemySpawns: [
				{
					x: 0,
					y: 0,
					enemy: ENEMY_NAMES.SHAMBLING_TOWER,
				},
			],
			weight: 50
		},
		// {
		// 	text: 'Ancient Ent\'s Ritual',
		// 	respawnText: 'The ritual begins anew in',
		// 	debugName: 'fungi-ent-ritual',
		// 	shape: { type: ColliderType.Circle, position: [0, 0], radius: 800, traits: ColliderTraits.BlockProjectile },
		// 	positioningShape: { type: ColliderType.Circle, position: [0, 0], radius: 800, traits: ColliderTraits.Trigger },
		// 	rezAreaColliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 800, traits: ColliderTraits.Trigger }],
		// 	count: 1,
		// 	absoluteLocation: false,
		// 	xPlacement: [0, 0.8875],
		// 	yPlacement: [0, 1],
		// 	activeState: { ...CONQUER_ACTIVE_STATE },
			
		// 				props: [
		// 					{
		// 						name: 'poi-04-ent-ritual',
		// 						x: 0,
		// 						y: 0,
		// 					},
		// 				],
		// 				enemySpawns: [
		// 					{
		// 						x: 100,
		// 						y: 0,
		// 						enemy: ENEMY_NAMES.ENT_BEARD,
		// 						enemyType: EnemyType.CHAMPION,
		// 					},
		// 					{
		// 						x: -100,
		// 						y: 0,
		// 						enemy: ENEMY_NAMES.ENT_BEARD,
		// 						enemyType: EnemyType.CHAMPION,
		// 					},
		// 					{
		// 						x: 	0,
		// 						y: 650,
		// 						enemy: ENEMY_NAMES.DREAMER_FUNGI,
		// 					},
		// 					{
		// 						x: 0,
		// 						y: -650,
		// 						enemy: ENEMY_NAMES.SPORE_KID,
		// 					},
		// 					{
		// 						x: -618,
		// 						y: 201,
		// 						enemy: ENEMY_NAMES.DREAMER_FUNGI,
		// 					},
		// 					{
		// 						x: -618,
		// 						y: -201,
		// 						enemy: ENEMY_NAMES.SPORE_KID,
		// 					},
		// 					{
		// 						x: 382,
		// 						y: -526,
		// 						enemy: ENEMY_NAMES.DREAMER_FUNGI,
		// 					},
		// 					{
		// 						x: 382,
		// 						y: 526,
		// 						enemy: ENEMY_NAMES.SPORE_KID,
		// 					},
		// 					{
		// 						x: -382,
		// 						y: -526,
		// 						enemy: ENEMY_NAMES.DREAMER_FUNGI,
		// 					},
		// 					{
		// 						x: -382,
		// 						y: 526,
		// 						enemy: ENEMY_NAMES.SPORE_KID,
		// 					},
		// 					{
		// 						x: 618,
		// 						y: 201,
		// 						enemy: ENEMY_NAMES.DREAMER_FUNGI,
		// 					},
		// 					{
		// 						x: 618,
		// 						y: -201,
		// 						enemy: ENEMY_NAMES.SPORE_KID,
		// 					},
		// 				],
		// 	type: POIType.Conquer,
		// 	resettingState: { ...CONQUER_RESET_STATE },
		// 	participationMetric: ParticipationMetric.DamageDealt,
		// 	participationThreshold: 200,
		// 	lootLevel: LEVELS_PER_BIOME * 2,
		// 	eventResetTime: NORMAL_POI_RESPAWN,
		// 	weight:50
		// },
		{
			text: 'Thorned Maze',
			respawnText: 'The Maze blooms again in ',
			debugName: 'fungi-maze',
			shape: { type: ColliderType.Circle, position: [0, 0], radius: 800, traits: ColliderTraits.BlockProjectile },
			positioningShape: { type: ColliderType.Circle, position: [0, 0], radius: 800, traits: ColliderTraits.Trigger },
			rezAreaColliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 800, traits: ColliderTraits.Trigger }],
			count: 1,
			absoluteLocation: false,
			xPlacement: [0, 0.8875],
			yPlacement: [0, 1],
			activeState: { ...CONQUER_ACTIVE_STATE },
			props: [
				{
					name: 'poi-03-thorn-maze',
					x: 0,
					y: 0,
				},
				// {
				// 	name: 'poi-03-thorn-maze-base',
				// 	x: 0,
				// 	y: 0,
				// },
			],
			type: POIType.Conquer,
			resettingState: { ...CONQUER_RESET_STATE },
			participationMetric: ParticipationMetric.DamageDealt,
			participationThreshold: 200,
			lootLevel: LEVELS_PER_BIOME * 2,
			eventResetTime: NORMAL_POI_RESPAWN,
			enemySpawns: [
				{
					x: 608,
					y: 495,
					enemy: ENEMY_NAMES.ENT_BEARD,
					enemyType: EnemyType.CHAMPION,
				},
				{
					x: -389,
					y: -595,
					enemy: ENEMY_NAMES.SHAMBLING_MOUND,
					enemyType: EnemyType.CHAMPION,
				},
				{
					x: 	-474,
					y: 276,	
					enemy: ENEMY_NAMES.MUSHIE,
					enemyType: EnemyType.CHAMPION,
				},
				{
					x: 160,
					y: -355,
					enemy: ENEMY_NAMES.MUSHIE,
					enemyType: EnemyType.CHAMPION,
				},
			],
			weight:50
		},
	],
	alwaysPlaced: [
		{
			text: SAFE_ZONE_STRING,
			debugName: 'fungi-outpost',
			shape: { type: ColliderType.Box, position: [-677, -554], width: 1296, height: 1114, traits: ColliderTraits.BlockProjectile },
			positioningShape: { type: ColliderType.Box, position: [-1150 + 210, -950 + 200], width: 2400, height: 2200, traits: ColliderTraits.Trigger },
			count: 1,
			absoluteLocation: false,
			xPlacement: [0, 0.33],
			yPlacement: [0.35, 0.65],
			activeState: { ...OUTPOST_ACTIVE_STATE },
			props: [
				{
					name: 'outpost-base',
					x: 210,
					y: 80,
				},
				{
					name: 'outpost-smoke',
					x: 215,
					y: 75,
				},
				{
					name: 'outpost-campfire',
					x: 160,
					y: 80,
				},
				{
					name: 'outpost-lanterns',
					x: 225,
					y: 100,
				},
				{
					name: 'outpost-bubba',
					x: 210,
					y: 80,
				},
			],
			type: POIType.Outpost,
		},
		{
			text: 'The Rotfather',
			respawnText: BOSS_RESPAWN_STRING,
			debugName: 'fungi-boss-arena',
			shape: { type: ColliderType.Ellipse, position: [-496, -181], rX: 1351, rY: 908, traits: ColliderTraits.BlockProjectile },
			positioningShape: { type: ColliderType.Box, position: [-1500, -1500], width: 3000, height: 3000, traits: ColliderTraits.Trigger },
			count: 1,
			absoluteLocation: false,
			xPlacement: [0.66, 0.8875],
			yPlacement: [0, 1],
			bossDroppedLootOriginX: -1000,
			bossDroppedLootOriginY: -100,
			activeState: { ...BOSS_ACTIVE_STATE },
			props: [
				{
					name: 'boss-arena-ground',
					x: 0,
					y: 0,
				},
				{
					name: 'boss-arena-spores',
					x: 0,
					y: 0,
				},
				{
					name: 'boss-arena-spores-02',
					x: 0,
					y: 0,
				},
				{
					name: SpineDataName.FUNGI_SWAMP,
					x: 0,
					y: 0,
				},
			],
			type: POIType.Boss,
			lootLevel: LEVELS_PER_BIOME * 4,
			resettingState: { ...BOSS_RESET_STATE },
			participationMetric: ParticipationMetric.PlayersParticipatingScaledDamage,
			eventResetTime: BOSS_POI_RESPAWN,
			enemySpawns: [
				{
					x: 0,
					y: 0,
					enemy: ENEMY_NAMES.FUNGI_BOSS,
					enemyType: EnemyType.BOSS,
				},
			],
			arenaGates: {
				insideArenaShape: [{ type: ColliderType.Ellipse, traits: ColliderTraits.Trigger, position: [-497, -234], rX: 1361, rY: 877 }],

				gates: [
					{
						// 6 o'clock
						npcOffset: new Vector(-760, 775),
						npcRadius: 400,
						teleportInDestination: [{ type: ColliderType.Circle, position: [-760, 300], radius: 100, traits: ColliderTraits.None }],
						teleportOutDestination: [{ type: ColliderType.Circle, position: [-760, 850], radius: 100, traits: ColliderTraits.None }],
					},
				],
			},
		},
		{
			text: 'Steppes Resurrection Altar',
			respawnText: ALTAR_RESPAWN_STRING,
			debugName: 'fungi-resurrection',
			shape: { type: ColliderType.Circle, position: [0, 0], radius: 500, traits: ColliderTraits.BlockProjectile },
			positioningShape: { type: ColliderType.Circle, position: [0, 0], radius: 500, traits: ColliderTraits.Trigger },
			rezAreaColliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 500, traits: ColliderTraits.Trigger }],
			count: 1,
			shouldResurrectDeadPlayersInBoundsWhenBetweenEvents: true,
			noEnchantmentReward: true,
			absoluteLocation: false,
			xPlacement: [0.15, 0.8875],
			yPlacement: [0, 1],
			activeState: { ...CONQUER_ACTIVE_STATE },
			props: [
				{
					name: 'fungi-alter',
					x: 0,
					y: 0,
				},
				{
					name: 'party-alter-animated',
					x: 0,
					y: 0,
				},
			],
			type: POIType.Conquer,
			resettingState: { ...CONQUER_RESET_STATE },
			participationMetric: ParticipationMetric.DamageDealt,
			participationThreshold: 200,
			lootLevel: LEVELS_PER_BIOME * 1,
			eventResetTime: NORMAL_POI_RESPAWN,
			enemySpawns: [
				{
					x: 0,
					y: 285,
					enemy: ENEMY_NAMES.ENT,
					enemyType: EnemyType.CHAMPION,
				},
				{
					x: -200,
					y: 50,
					enemy: ENEMY_NAMES.SHAMBLING_MOUND,
					enemyType: EnemyType.LOOTLESS_CHAMPION,
				},
				{
					x: 0,
					y: 50,
					enemy: ENEMY_NAMES.SHAMBLING_MOUND,
					enemyType: EnemyType.LOOTLESS_CHAMPION,
				},
				{
					x: 200,
					y: 50,
					enemy: ENEMY_NAMES.SHAMBLING_MOUND,
					enemyType: EnemyType.LOOTLESS_CHAMPION,
				},
			],
		},
	]
}

// Used (once on startup) to get the position of these POIs for FTUE zones
export const EDGE_OF_WORLD_PROP_SAFEZONE_NAME = 'prism-edge-no-prop-zone'

export const prismPOIs: BiomePOIs = {
	randomlyPlaced: [
		{
			text: 'Gathering of Wisps',
			respawnText: 'respawn in',
			debugName: 'prism-gathering-wisps',
			shape: { type: ColliderType.Circle, position: [0, 0], radius: 800, traits: ColliderTraits.BlockProjectile },
			positioningShape: { type: ColliderType.Circle, position: [0, 0], radius: 1200, traits: ColliderTraits.Trigger },
			count: 1,
			absoluteLocation: false,
			xPlacement: [0, 0.8875],
			yPlacement: [0, 1],
			activeState: { ...MONSTER_RAID_ACTIVE_STATE },
			props: [
				{
					name: 'low-blocker-01-var02',
					x: 0,
					y: 0,
				},
				{
					name: 'small-rare-03',
					x: 120,
					y: 0,
				},
				{
					name: 'small-rare-03',
					x: -190,
					y: 0,
				},
				{
					name: 'small-rare-01',
					x: -20,
					y: 110,
				},
				{
					name: 'small-rare-01',
					x: -20,
					y: -140,
				},
			],
			type: POIType.MonsterRaid,
			resettingState: { ...MONSTER_RAID_RESET_STATE },
			triggerRadius: 1800,
			completionPercent: 0.9,
			completionMessage: 'The ominous magic of the wisps dissipates.',
			participationMetric: ParticipationMetric.KillsTagged,
			participationThreshold: 0.04,
			lootLevel: LEVELS_PER_BIOME * 5,
			eventResetTime: NORMAL_POI_RESPAWN,
			waves: [
				{
					delayInSeconds: 0,
					spawns: [
						{ enemy: ENEMY_NAMES.PRISM_WISP, count: 6 },
						{ enemy: ENEMY_NAMES.PRISMFLY, count: 2 },
					],
				},
				{
					completionForNextWave: 0.9,
					spawns: [
						{ enemy: ENEMY_NAMES.PRISM_WISP, count: 6 },
						{ enemy: ENEMY_NAMES.PRISMFLY, count: 2 },
					],
				},
				{
					completionForNextWave: 0.9,
					spawns: [
						{ enemy: ENEMY_NAMES.PRISM_WISP, count: 4 },
						{ enemy: ENEMY_NAMES.PRISMFLY, count: 2 },
						{ enemy: ENEMY_NAMES.PRISM_WISP, count: 2, enemyType: EnemyType.CHAMPION },
					],
				},
			],
			weight: 50,
		},
		{
			text: 'The Grand Prism',
			respawnText: 'respawn in',
			debugName: 'prism-conquer',
			shape: { type: ColliderType.Box, position: [-1650, -1200], width: 3300, height: 2400, traits: ColliderTraits.BlockProjectile },
			positioningShape: { type: ColliderType.Box, position: [-2000, -1400], width: 3800, height: 3400, traits: ColliderTraits.Trigger },
			rezAreaColliders: [{ type: ColliderType.Box, position: [-2000, -1400], width: 3800, height: 3400, traits: ColliderTraits.Trigger }],
			count: 1,
			absoluteLocation: false,
			xPlacement: [0, 1],
			yPlacement: [0, 1],
			activeState: { ...CONQUER_ACTIVE_STATE },
			props: [
				{
					name: 'poi-02-base',
					x: 0,
					y: 0,
				},
				{
					name: 'poi-02-crystal-glow',
					x: 0,
					y: 0,
				},
				{
					name: 'poi-02-glints',
					x: 0,
					y: 0,
				},
				{
					name: 'poi-02-lanterns',
					x: 0,
					y: 0,
				},
			],
			type: POIType.Conquer,
			resettingState: { ...CONQUER_RESET_STATE },
			participationMetric: ParticipationMetric.DamageDealt,
			participationThreshold: 200,
			lootLevel: LEVELS_PER_BIOME * 5,
			eventResetTime: NORMAL_POI_RESPAWN,
			enemySpawns: [
				{
					x: 0,
					y: -200,
					enemy: ENEMY_NAMES.DREAMER_PRISM,
					enemyType: EnemyType.CHAMPION,
				},
				{
					x: 200,
					y: -300,
					enemy: ENEMY_NAMES.DREAMER_PRISM,
					enemyType: EnemyType.CHAMPION,
				},
				{
					x: -200,
					y: -300,
					enemy: ENEMY_NAMES.DREAMER_PRISM,
					enemyType: EnemyType.CHAMPION,
				},
				{
					x: 150,
					y: 0,
					enemy: ENEMY_NAMES.DREAMER_PRISM,
					enemyType: EnemyType.CHAMPION,
				},
				{
					x: -150,
					y: 0,
					enemy: ENEMY_NAMES.DREAMER_PRISM,
					enemyType: EnemyType.CHAMPION,
				},
				{
					x: 1000,
					y: 0,
					enemy: ENEMY_NAMES.DREAMER_PRISM,
				},
				{
					x: -1000,
					y: 0,
					enemy: ENEMY_NAMES.DREAMER_PRISM,
				},
				{
					x: 600,
					y: 200,
					enemy: ENEMY_NAMES.DREAMER_PRISM,
				},
				{
					x: -600,
					y: 200,
					enemy: ENEMY_NAMES.DREAMER_PRISM,
				},
				{
					x: 150,
					y: 600,
					enemy: ENEMY_NAMES.DREAMER_PRISM,
				},
				{
					x: -150,
					y: 600,
					enemy: ENEMY_NAMES.DREAMER_PRISM,
				},
			],
			weight: 50,
		},
		{
			text: 'A Dream-Encrusted Tower, Forsaken',
			respawnText: 'The invaders return in',
			debugName: 'prism-watch-tower',
			shape: { type: ColliderType.Circle, position: [0, 0], radius: 1300, traits: ColliderTraits.BlockProjectile },
			positioningShape: { type: ColliderType.Circle, position: [0, 0], radius: 1300, traits: ColliderTraits.Trigger },
			count: 1,
			absoluteLocation: false,
			xPlacement: [0, 0.8875],
			yPlacement: [0, 1],
			activeState: { ...MONSTER_RAID_ACTIVE_STATE },
			props: [
				{
					name: 'poi-03-ruins',
					x: 0,
					y: 0,
				},
				
				{
					name: 'poi-03-glints',
					x: 0,
					y: 0,
				},
				{
					name: 'poi-03-lantern',
					x: 0,
					y: 0,
				},
			],
			type: POIType.MonsterRaid,
			resettingState: { ...MONSTER_RAID_RESET_STATE },
			triggerRadius: 1200,
			completionPercent: 0.8,
			completionMessage: 'This place is safe for now. Stay on watch.',
			participationMetric: ParticipationMetric.KillsTagged,
			participationThreshold: 0.04,
			lootLevel: LEVELS_PER_BIOME * 4,
			eventResetTime: NORMAL_POI_RESPAWN,
			waves: [
				{
					delayInSeconds: 0,
					spawns: [
						{ enemy: ENEMY_NAMES.SLIVER, count: 12 },
						{ enemy: ENEMY_NAMES.SLIVER, count: 1, enemyType: EnemyType.CHAMPION },
					],
				},
				{
					completionForNextWave: 1.0,
					spawns: [
						{ enemy: ENEMY_NAMES.DREAMER_PRISM, count: 5 },
						{ enemy: ENEMY_NAMES.DREAMER_FANCY, count: 2 },
						{ enemy: ENEMY_NAMES.DREAMER_PRISM, count: 1, enemyType: EnemyType.CHAMPION },
					],
				},
				{
					completionForNextWave: 1.0,
					spawns: [
						{ enemy: ENEMY_NAMES.GOLEM_PRISM, count: 9 },
						{ enemy: ENEMY_NAMES.GOLEM_PRISM, count: 1, enemyType: EnemyType.CHAMPION },
					],
				},
			],
			weight:50
		},
	
		{
			text: 'The Glistering Pool',
			respawnText: 'A glittering hum comes from the crystals in',
			debugName: 'prism-geode-pools',
			shape: { type: ColliderType.Circle, position: [0, 0], radius: 1300, traits: ColliderTraits.BlockProjectile },
			positioningShape: { type: ColliderType.Circle, position: [0, 0], radius: 1300, traits: ColliderTraits.Trigger },
			count: 1,
			absoluteLocation: false,
			xPlacement: [0, 0.8875],
			yPlacement: [0, 1],
			activeState: { ...MONSTER_RAID_ACTIVE_STATE },
			props: [
				{
					name: 'poi-04-geode',
					x: 0,
					y: 0,
				},
				
				{
					name: 'poi-04-glints',
					x: 0,
					y: 0,
				},
				{
					name: 'poi-04-crystal-glow',
					x: 0,
					y: 0,
				},
			],
			type: POIType.MonsterRaid,
			resettingState: { ...MONSTER_RAID_RESET_STATE },
			triggerRadius: 1200,
			completionPercent: 0.8,
			completionMessage: 'Peace settles on this beautiful place.',
			participationMetric: ParticipationMetric.KillsTagged,
			participationThreshold: 0.04,
			lootLevel: LEVELS_PER_BIOME * 4,
			eventResetTime: NORMAL_POI_RESPAWN,
			// TO BE CLEARED - NEED DAN'S BLACK MAGIC from 860442b377a914b26e0aa1d0c6cdfcc948fa6d8f
			// newEnemySpawnedFn: (enemy, currentWave: number) => {
			// 	if (currentWave === 6) {
			// 		enemy.lootDropProbability *= 3
			// 		enemy.lootDropRarity *= 1.25
			// 	}
			// },
			waves: [
				{
					delayInSeconds: 0,
					spawns: [
						{ enemy: ENEMY_NAMES.CRYSTAL_SPIDER, count: 5 },
						{ enemy: ENEMY_NAMES.PRISM_WISP, count: 3 },
						{ enemy: ENEMY_NAMES.PRISMFLY, count: 2 },
					],
				},
				{
					completionForNextWave: 1.0,
					spawns: [
						{ enemy: ENEMY_NAMES.CRYSTAL_SPIDER, count: 4 },
						{ enemy: ENEMY_NAMES.PRISM_WISP, count: 4 },
						{ enemy: ENEMY_NAMES.PRISMFLY, count: 2 },
					],
				},
				{
					completionForNextWave: 1.0,
					spawns: [
						{ enemy: ENEMY_NAMES.CRYSTAL_SPIDER, count: 5 },
						{ enemy: ENEMY_NAMES.PRISM_WISP, count: 3 },
						{ enemy: ENEMY_NAMES.PRISMFLY, count: 2 },
					],
				},
				{
					completionForNextWave: 1.0,
					spawns: [
						{ enemy: ENEMY_NAMES.CRYSTAL_SPIDER, count: 2 },
						{ enemy: ENEMY_NAMES.PRISM_WISP, count: 5 },
						{ enemy: ENEMY_NAMES.PRISMFLY, count: 2 },
					],
				},
				{
					completionForNextWave: 1.0,
					spawns: [
						{ enemy: ENEMY_NAMES.CRYSTAL_SPIDER, count: 2 },
						{ enemy: ENEMY_NAMES.PRISM_WISP, count: 3 },
						{ enemy: ENEMY_NAMES.PRISMFLY, count: 5 },
					],
				},
				{
					completionForNextWave: 1.0,
					spawns: [
						{ enemy: ENEMY_NAMES.CRYSTAL_SPIDER, count: 2 },
						{ enemy: ENEMY_NAMES.PRISM_WISP, count: 5 },
						{ enemy: ENEMY_NAMES.PRISMFLY, count: 3 },
					],
				},
			],
			weight:50
		},
	],
	alwaysPlaced: [
		{
			text: '',
			debugName: 'prism-world-edge',
			shape: { type: ColliderType.Box, position: [0, 0], width: 0, height: 0, traits: ColliderTraits.BlockProjectile },
			positioningShape: { type: ColliderType.Box, position: [0, 0], width: 100, height: 12288, traits: ColliderTraits.Trigger },
			count: 1,
			absoluteLocation: true,
			x: 81919 + 6144 - 50 + 512 * 6,
			y: (10240 + 512 * 4) / 2,
			activeState: { ...WORLD_EDGE_ACTIVE_STATE },
			lootLevel: LEVELS_PER_BIOME * 1,
			props: [
				//top to bottom
				{
					name: 'edge-tile-east',
					x: 0,
					y: -4233,
					shared: true,
				},
				{
					name: 'edge-tile-east',
					x: 0,
					y: -3211,
					shared: true,
				},
				{
					name: 'edge-tile-east',
					x: 0,
					y: -2189,
					shared: true,
				},
				{
					name: 'edge-tile-east',
					x: 0,
					y: -1167,
					shared: true,
				},
				{
					name: 'edge-tile-east',
					x: 0,
					y: -145,
					shared: true,
				},
				//bridge to end island
				{
					name: 'edge-tile-east',
					x: 0,
					y: 1087,
					shared: true,
				},
				{
					name: 'edge-tile-east',
					x: 0,
					y: 2109,
					shared: true,
				},
				{
					name: 'edge-tile-east',
					x: 0,
					y: 3131,
					shared: true,
				},
				{
					name: 'edge-tile-east',
					x: 0,
					y: 4153,
					shared: true,
				},
				{
					name: 'edge-tile-east',
					x: 0,
					y: 5175,
					shared: true,
				},
			],
			type: POIType.WorldEdge,
		},
		{
			text: '',
			debugName: 'prism-zen-garden',
			shape: { type: ColliderType.Box, position: [-(END_OF_WORLD_ISLAND_BONUS_SIZE + 300) / 2, -12288 / 2], width: END_OF_WORLD_ISLAND_BONUS_SIZE + 300, height: 12288, traits: ColliderTraits.BlockProjectile },
			positioningShape: { type: ColliderType.Box, position: [-(END_OF_WORLD_ISLAND_BONUS_SIZE + 300) / 2, -12288 / 2], width: END_OF_WORLD_ISLAND_BONUS_SIZE + 300, height: 12288, traits: ColliderTraits.Trigger },
			endOfRunBlocker: { type: ColliderType.Box, position: [-(END_OF_WORLD_ISLAND_BONUS_SIZE + 200) / 2, -12288 / 2], width: END_OF_WORLD_ISLAND_BONUS_SIZE + 200, height: 12288, traits: ColliderTraits.BlockAll },
			count: 1,
			absoluteLocation: true,
			x: 81919 + 6144 + 3072 + 512 * 6,
			y: (10240 + 512 * 4) / 2,
			activeState: { ...END_ISLAND_ACTIVE_STATE },
			lootLevel: LEVELS_PER_BIOME * 1,
			props: [
				{
					name: 'Connection-Road',
					x: 0,
					y: 0,
					shared: true,
				},
				{
					name: 'End-Island',
					x: 0,
					y: 0,
					shared: true,
				},
				{
					name: 'End-Island-Tree',
					x: 0,
					y: 0,
					shared: true,
				},
				{
					name: 'Waterfall-animated',
					x: 0,
					y: 0,
					shared: true,
				},
			],
			type: POIType.WorldEdge,
		},
		{
			text: '',
			debugName: EDGE_OF_WORLD_PROP_SAFEZONE_NAME,
			shape: { type: ColliderType.Box, position: [-350, -350], width: 700, height: 700, traits: ColliderTraits.BlockProjectile },
			positioningShape: { type: ColliderType.Box, position: [-350, -350], width: 700, height: 700, traits: ColliderTraits.Trigger },
			count: 1,
			absoluteLocation: true,
			x: 81919 + 6144 - 350 + 512 * 6,
			y: (10240 + 512 * 4) / 2 + 500,
			activeState: { ...WORLD_EDGE_SAFETY_ACTIVE_STATE },
			lootLevel: LEVELS_PER_BIOME * 1,
			props: [],
			type: POIType.WorldEdge,
		},
		{
			text: 'Enter the portal to conquer the island and unlock the next world tier!',
			debugName: 'prism-end-world',
			shape: { type: ColliderType.Box, position: [-400, -400], width: 800, height: 800, traits: ColliderTraits.BlockProjectile },
			positioningShape: { type: ColliderType.Box, position: [-400, -400], width: 800, height: 800, traits: ColliderTraits.BlockProjectile },
			count: 1,
			absoluteLocation: true,
			x: 81919 + 6144 + 3072 + 50 + 512 * 6,
			y: (10240 + 512 * 4) / 2 - 300,
			activeState: { ...WORLD_END_STATE },
			props: [],
			type: POIType.EndOfWorld,
		},
		{
			text: SAFE_ZONE_STRING,
			debugName: 'prism-outpost',
			shape: { type: ColliderType.Box, position: [-700, -550], width: 1600, height: 1100, traits: ColliderTraits.BlockProjectile },
			positioningShape: { type: ColliderType.Box, position: [-1150 + 90, -950 + 200], width: 2400, height: 2200, traits: ColliderTraits.Trigger },
			count: 1,
			absoluteLocation: false,
			xPlacement: [0, 0.33],
			yPlacement: [0.35, 0.65],
			activeState: { ...OUTPOST_ACTIVE_STATE },
			props: [
				{
					name: 'outpost-base',
					x: 120,
					y: 80,
				},
				{
					name: 'outpost-lanterns',
					x: 120,
					y: 80,
				},
				{
					name: 'outpost-fence',
					x: 120,
					y: 80,
				},
				{
					name: 'outpost-props',
					x: 120,
					y: 80,
				},
				{
					name: 'outpost-bubba',
					x: 120,
					y: 80,
				},
			],
			type: POIType.Outpost,
		},
		{
			text: 'The Mirrored Dragon',
			respawnText: BOSS_RESPAWN_STRING,
			debugName: 'prism-boss-arena',
			shape: { type: ColliderType.Box, position: [-1771, -1227], width: 3609, height: 2388, traits: ColliderTraits.BlockProjectile },
			positioningShape: { type: ColliderType.Box, position: [-2200, -1500], width: 4400, height: 4000, traits: ColliderTraits.Trigger },
			count: 1,
			absoluteLocation: false,
			xPlacement: [0.4, 0.7],
			yPlacement: [0.4, 0.5], // raising this min along with reducing positioningShape size to give more room for other pois
			bossDroppedLootOriginX: 0,
			bossDroppedLootOriginY: 0,
			activeState: { ...BOSS_ACTIVE_STATE },
			props: [
				{
					name: 'boss-arena',
					x: 0,
					y: 0,
				},
				{
					name: 'boss-arena-front',
					x: 0,
					y: 0,
				},
				{
					name: 'boss-arena-glints',
					x: 0,
					y: 0,
				},
				{
					name: 'boss-arena-lanterns',
					x: 0,
					y: 0,
				},
				{
					name: 'boss-arena-main-hut',
					x: 0,
					y: 0,
				},
				{
					name: 'boss-arena-side-pieces',
					x: 0,
					y: 0,
				},
			],
			type: POIType.Boss,
			lootLevel: LEVELS_PER_BIOME * 5,
			resettingState: { ...BOSS_RESET_STATE },
			participationMetric: ParticipationMetric.PlayersParticipatingScaledDamage,
			eventResetTime: BOSS_POI_RESPAWN,
			enemySpawns: [
				{
					x: 0,
					y: 0,
					enemy: ENEMY_NAMES.PRISM_BOSS,
					enemyType: EnemyType.BOSS,
				},
			],
			arenaGates: {
				insideArenaShape: [{ type: ColliderType.Box, traits: ColliderTraits.Trigger, position: [-1732, -1273], width: 3533, height: 2460 }],

				gates: [
					{
						// 6 o'clock
						npcOffset: new Vector(-17, 1303),
						npcRadius: 400,
						teleportInDestination: [{ type: ColliderType.Circle, position: [13, 858], radius: 100, traits: ColliderTraits.None }],
						teleportOutDestination: [{ type: ColliderType.Circle, position: [-16, 1408], radius: 100, traits: ColliderTraits.None }],
					},
				],
			},
		},
		{
			text: 'Cliffs Resurrection Altar',
			respawnText: ALTAR_RESPAWN_STRING,
			debugName: 'prism-resurrection',
			shape: { type: ColliderType.Circle, position: [0, 0], radius: 500, traits: ColliderTraits.BlockProjectile },
			positioningShape: { type: ColliderType.Circle, position: [0, 0], radius: 500, traits: ColliderTraits.Trigger },
			rezAreaColliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 500, traits: ColliderTraits.Trigger }],
			count: 1,
			shouldResurrectDeadPlayersInBoundsWhenBetweenEvents: true,
			noEnchantmentReward: true,
			absoluteLocation: false,
			xPlacement: [0.15, 0.8875],
			yPlacement: [0, 1],
			activeState: { ...CONQUER_ACTIVE_STATE },
			props: [
				{
					name: 'prism-alter',
					x: 0,
					y: 0,
				},
				{
					name: 'party-alter-animated',
					x: 0,
					y: 0,
				},
			],
			type: POIType.Conquer,
			resettingState: { ...CONQUER_RESET_STATE },
			participationMetric: ParticipationMetric.DamageDealt,
			participationThreshold: 200,
			lootLevel: LEVELS_PER_BIOME * 1,
			eventResetTime: NORMAL_POI_RESPAWN,
			enemySpawns: [
				{
					x: 225,
					y: 150,
					enemy: ENEMY_NAMES.CRYSTAL_SPIDER,
					enemyType: EnemyType.LOOTLESS_CHAMPION,
				},
				{
					x: -225,
					y: 150,
					enemy: ENEMY_NAMES.CRYSTAL_SPIDER,
					enemyType: EnemyType.CHAMPION,
				},
				{
					x: 275,
					y: 100,
					enemy: ENEMY_NAMES.PRISMFLY,
					enemyType: EnemyType.LOOTLESS_CHAMPION,
				},
				{
					x: -275,
					y: 100,
					enemy: ENEMY_NAMES.PRISMFLY,
					enemyType: EnemyType.LOOTLESS_CHAMPION,
				},
			],
		},
	]
}

export const testRealmPois: BiomePOIs = {
	randomlyPlaced: [],
	alwaysPlaced: [
		{
			text: '',
			debugName: 'Test Realm Hub POI',
			absoluteLocation: true,
			x: 5120,
			y: 5120,
			count: 1,
			type: POIType.Conquer,
			resettingState: { ...CONQUER_RESET_STATE },
			participationMetric: ParticipationMetric.DamageDealt,
			participationThreshold: 200,
			lootLevel: LEVELS_PER_BIOME * 5,
			eventResetTime: 10,
			shape: { type: ColliderType.Circle, position: [0, 0], radius: 100, traits: ColliderTraits.None },
			positioningShape: { type: ColliderType.Circle, position: [0, 0], radius: 100, traits: ColliderTraits.Trigger },
			activeState: {
				allowProjectiles: true,
				allowRandomSpawns: false,
				safeFromEnemies: false
			},
			props: [
				{
					name: 'marketplace',
					x: 0,
					y: 0,
				},
			],
			enemySpawns: [
				{
					x: 300,
					y: -1_000,
					enemy: ENEMY_NAMES.DREAMER_PRISM,
					enemyType: EnemyType.CHAMPION,
				},
			],
		}
	]
}

export const allPOIs: POIData[] = flatten([townPOIs.alwaysPlaced, townPOIs.randomlyPlaced, beachPOIs.alwaysPlaced, beachPOIs.randomlyPlaced, forestPOIs.alwaysPlaced, forestPOIs.randomlyPlaced, highlandsPOIs.alwaysPlaced, highlandsPOIs.randomlyPlaced, fungiPOIs.alwaysPlaced, fungiPOIs.randomlyPlaced, prismPOIs.alwaysPlaced, prismPOIs.randomlyPlaced])
