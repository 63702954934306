import { Container } from 'pixi.js'
import { IParticleRendererCamera } from '../../engine/client/graphics/pfx/sprite-particle-renderer'
import { RenderQueue } from '../../engine/client/graphics/render-queue'
import { LayerRenderer } from './layer-renderer'

class UpdatableContainer extends Container {
	update(delta: number) {}
}

class MiddlegroundRenderer extends LayerRenderer {
	constructor(renderQueue: RenderQueue, cameraState: IParticleRendererCamera) {
		super(renderQueue, cameraState)
	}

	loadAtlases(): void {}
}

export { MiddlegroundRenderer, UpdatableContainer }
