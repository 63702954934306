


















import { mapActions, mapGetters } from 'vuex'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import TextButton from '../reusable-components/buttons/text-button.vue'
import PanelDivider from '../reusable-components/panel-divider/panel-divider.vue'

export default {
	name: 'Error',
	components: {
		MainPanel,
		TextButton,
		PanelDivider,
	},
	computed: {
		...mapGetters('errors', ['getErrorDescription', 'getErrorTitle']),
	},
	methods: {
		...mapActions('errors', ['closeActiveErrorPanel']),
	},
}
