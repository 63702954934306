import { NPCConfigs } from '../../ai/client/npc-model-configs'
import { createColliders } from '../../collision/shared/colliders'
import { Effect } from '../../engine/client/graphics/pfx/effect'
import Renderer from '../../engine/client/graphics/renderer'
import { BULWARK_CONFIG } from '../../engine/shared/game-data/gear-skill-config'
import { createModel } from '../../models-animations/client/model-setup'
import playAnimation from '../../models-animations/client/play-animation'
import { RiggedSpineModel } from '../../models-animations/client/spine-model'
import { AnimationTrack } from '../../models-animations/shared/animation-track'
import { createColliderConfigFromEnum } from '../shared/collider-entity-config'
import ColliderEntity from '../shared/collider-entity.shared'

export class ClientColliderEntity extends ColliderEntity {
	exemptFromModelCheck: boolean

	pfx: Effect
	spineModel: RiggedSpineModel

	isBulwark: boolean = false
	bulwarkHP: number
	bulwarkRotSuffix: string

	constructor(colliderEntity: ColliderEntity) {
		super()

		this.x = colliderEntity.x
		this.y = colliderEntity.y

		const config = createColliderConfigFromEnum(colliderEntity.colliderEntityType)
		if (config) {
			this.colliders = createColliders(this, config)
		}

		this.visuals = colliderEntity.visuals
		this.isPfx = colliderEntity.isPfx

		if (this.visuals.length > 0) {
			if (this.isPfx) {
				this.pfx = Renderer.getInstance().addOneOffEffect(this.visuals, this.x, this.y, this.y + 1, 1, Number.MAX_SAFE_INTEGER, true)
				this.pfx.rot = colliderEntity.rotation
			} else {
				this.spineModel = createModel(NPCConfigs.get(this.visuals), 'collider-', 0, colliderEntity.rotation)
				this.spineModel.x = colliderEntity.x
				this.spineModel.y = colliderEntity.y
				Renderer.getInstance().mgRenderer.addDisplayObjectToScene(this.spineModel)

				if (this.visuals === BULWARK_CONFIG.BULWARK_VISUALS.name) {
					this.isBulwark = true
					const rotatedTimes = Math.abs(colliderEntity.rotation) / (Math.PI / 4)
					const rotIndex = rotatedTimes % 4
					this.bulwarkRotSuffix = BULWARK_SUFFIXES[rotIndex]
				}
			}
		}

		this.exemptFromModelCheck = true
	}

	update(delta: number, now: number) { }

	onDelete() {
		if (this.pfx) {
			Renderer.getInstance().removeEffectFromScene(this.pfx)
		} else if (this.spineModel) {
			Renderer.getInstance().mgRenderer.removeFromScene(this.spineModel)
		}
	}
}

const BULWARK_SUFFIXES = ['vertical', '45', 'horizontal', '45']

export const clientColliderEntityHooks = {
	bulwarkHP: (entity: ClientColliderEntity, newValue: number) => {
		if (!entity.isBulwark) {
			return
		}

		if (newValue === 0) {
			playAnimation(entity.spineModel, ('death-' + entity.bulwarkRotSuffix) as AnimationTrack, [0, 999])
		} else if (newValue < BULWARK_CONFIG.MAX_BULWARK_HITS) {
			const isDamaged = newValue <= BULWARK_CONFIG.HITS_REMAIN_TO_SHOW_DAMAGED
			if (isDamaged) {
				playAnimation(entity.spineModel, ('hit-damaged-' + entity.bulwarkRotSuffix) as AnimationTrack)
			} else {
				playAnimation(entity.spineModel, ('hit-' + entity.bulwarkRotSuffix) as AnimationTrack)
			}
		}
	},
}
