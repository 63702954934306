import { EnemyAI, FightingTriggers, IdleBehaviours, FightingBehaviours, LeashTriggers, LeashingBehaviours, AttackTypes, DeadBehaviours, ShotLeadPrecision, EnemyType, EnemyTargetingStyles } from '../../shared/ai-types'
import { ModType, ModValueType, BurstFireModes } from '../../../projectiles/shared/projectile-types'
import { SpineDataName, EnemyBipedSkinType } from '../../../models-animations/shared/spine-config'
import { degToRad } from '../../../utils/math'
import { EnemyDefaults } from './enemy-defaults'
import { Colors } from '../../../utils/colors'
import { ParticleEffectType } from '../../../engine/shared/game-data/particle-config'
import { Vector } from 'sat'
import { ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { ENEMY_DEFAULT_HEALTH } from '../../../engine/shared/game-data/enemy-formulas'
import { Ability, AbilitySelectionStyles, AbilityTargets, AbilityTargetSelectionStyle, AbilityType, ActionCriteria, CriteriaSatisfactionQuantity, CriteriaTarget, CriteriaType } from '../action-types'
import { AnimationTrack } from '../../../models-animations/shared/animation-track'
import { worldDifficultyHardCriterion , worldDifficultyBrutalCriterion} from '../action-criteria/action-criteria-helpers'
import { attackRatedAbilityList } from '../action-criteria/ability-helpers'
import { alwaysTrueCriteria } from './abilities/common-abilities'
import { ENEMY_NAMES } from './enemy-names'
import { deepClone } from '../abilities.test'
import { WorldDifficulty } from '../../../engine/shared/game-data/world-difficulty'

const animationTimesStomp = {
	[AnimationTrack.STOMP]: 0.33, // Timed
}
const animationTimesShoot = {
	[AnimationTrack.SHOOT]: 0.6, // PLACEHOLDER
}

const playerIsInMeleeRangeHardCriteria: ActionCriteria = {
	satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
	criterias: [
		{
			criteriaTargets: CriteriaTarget.PLAYERS,
			criteriaType: CriteriaType.WITHIN_RANGE,
			criteriaValue: 350,
			minimumTargetsThatSatisfy: 1,
		},
		worldDifficultyHardCriterion,
	],
}
const playerIsInMeleeRangeNormalCriteria: ActionCriteria = {
	satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
	criterias: [
		{
			criteriaTargets: CriteriaTarget.PLAYERS,
			criteriaType: CriteriaType.WITHIN_RANGE,
			criteriaValue: 350,
			minimumTargetsThatSatisfy: 1,
		},
		{
			criteriaTargets: CriteriaTarget.SELF,
			criteriaType: CriteriaType.WORLD_DIFFICULTY_AT_OR_BELOW_THRESHOLD,
			criteriaValue: WorldDifficulty.Normal,
			minimumTargetsThatSatisfy: 1,
		},
	],
}
const playerIsInMeleeRangeBrutalCriteria: ActionCriteria = {
	satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
	criterias: [
		{
			criteriaTargets: CriteriaTarget.PLAYERS,
			criteriaType: CriteriaType.WITHIN_RANGE,
			criteriaValue: 350,
			minimumTargetsThatSatisfy: 1,
		},
		worldDifficultyBrutalCriterion,
	],
}

const playerIsInAttackRangeHardCriteria: ActionCriteria = {
	satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
	criterias: [
		{
			criteriaTargets: CriteriaTarget.PLAYERS,
			criteriaType: CriteriaType.WITHIN_RANGE,
			criteriaValue: 1100,
			minimumTargetsThatSatisfy: 1,
		},
		{
			criteriaTargets: CriteriaTarget.SELF,
			criteriaType: CriteriaType.WORLD_DIFFICULTY_AT_OR_ABOVE_THRESHOLD,
			criteriaValue: WorldDifficulty.Hard,
			minimumTargetsThatSatisfy: 1,
		},
	],
}
const playerIsInAttackRangeNormalCriteria: ActionCriteria = {
	satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
	criterias: [
		{
			criteriaTargets: CriteriaTarget.PLAYERS,
			criteriaType: CriteriaType.WITHIN_RANGE,
			criteriaValue: 1100,
			minimumTargetsThatSatisfy: 1,
		},
		{
			criteriaTargets: CriteriaTarget.SELF,
			criteriaType: CriteriaType.WORLD_DIFFICULTY_AT_OR_BELOW_THRESHOLD,
			criteriaValue: WorldDifficulty.Normal,
			minimumTargetsThatSatisfy: 1,
		},
	],
}
const playerIsInAttackRangeBrutalCriteria: ActionCriteria = {
	satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
	criterias: [
		{
			criteriaTargets: CriteriaTarget.PLAYERS,
			criteriaType: CriteriaType.WITHIN_RANGE,
			criteriaValue: 1100,
			minimumTargetsThatSatisfy: 1,
		},
		worldDifficultyBrutalCriterion,
	],
}

const novaStompAbility: Ability = {
	debugName: 'novaStomp',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.CLOSEST,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		projectileCount: 8,
		spreadAngle: degToRad(325),
		speed: 600,
		colliderRadius: 30,
		lifespanInSeconds: 1.5,
		modifiers: [{ modType: ModType.TURN, value: { modValueType: ModValueType.LIFETIME, min: 6.0, max: 1.0 } }],
		basePhysicalDamage: 200,
		burstCount: 1,
		burstDelay: 0.15,
		burstMode: BurstFireModes.STRAIGHT,
		color: Colors.red,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_DEATHDRAKE,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_TRAIL,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
	},
	attackOffset: new Vector(75, 300),
	ignoreAngleOnWeaponOffset: true,
}

export const novaStompAbilityNormal = deepClone(novaStompAbility)
novaStompAbilityNormal.projectileConfig.basePhysicalDamage = 120

export const novaStompAbilityHard = deepClone(novaStompAbility)
novaStompAbilityHard.projectileConfig.basePhysicalDamage = 160

export const novaStompAbilityBrutal = deepClone(novaStompAbility)

const drakeBreathAbility: Ability = {
	debugName: 'drakeBreath',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 60,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		projectileCount: 7,
		spreadAngle: degToRad(35),
		speed: 800,
		colliderRadius: 15,
		lifespanInSeconds: 1.5,
		modifiers: [{ modType: ModType.STRAIGHT }],
		basePhysicalDamage: 0,
		baseIceDamage: 55,
		baseFireDamage: 25,
		burstCount: 5,
		burstDelay: 0.2,
		burstMode: BurstFireModes.STRAIGHT,
		color: Colors.red,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_DEATHDRAKE,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_FIRE_TRAIL,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_ICE_SHOOT,
	},
	attackOffset: new Vector(550, -450),
	ignoreAngleOnWeaponOffset: true,
}

export const drakeBreathAbilityNormal = deepClone(drakeBreathAbility)
drakeBreathAbilityNormal.projectileConfig.burstCount = 2
drakeBreathAbilityNormal.projectileConfig.burstDelay = 0.4
drakeBreathAbilityNormal.projectileConfig.speed = 600
drakeBreathAbilityNormal.projectileConfig.baseIceDamage = 30
drakeBreathAbilityNormal.projectileConfig.baseFireDamage = 20

export const drakeBreathAbilityHard = deepClone(drakeBreathAbility)
drakeBreathAbilityHard.projectileConfig.burstCount = 3
drakeBreathAbilityHard.projectileConfig.burstDelay = 0.3
drakeBreathAbilityHard.projectileConfig.speed = 700
drakeBreathAbilityHard.projectileConfig.baseIceDamage = 45
drakeBreathAbilityHard.projectileConfig.baseFireDamage = 20

export const drakeBreathAbilityBrutal = deepClone(drakeBreathAbility)

const deathDrake: EnemyAI = {
	name: ENEMY_NAMES.DEATH_DRAKE,
	type: EnemyType.BASIC,
	appearance: {
		asset: SpineDataName.DEATH_DRAKE,
		skin: EnemyBipedSkinType.DEFAULT,
		mixSettings: [],
	},
	general: {
		enemyLevel: 1,
		experienceWhenKilled: 3,
	},
	baseAttributes: {
		...EnemyDefaults.baseAttributes,
		visibilityRadius: 1,
		colliders: [
			{
				// head; I'd like this to have an X offset, but they don't flip when the models do
				type: ColliderType.Circle,
				radius: 75,
				traits: ColliderTraits.BlockMovementAndProjectile,
				position: [0, -255],
			},
			{
				// body
				type: ColliderType.Ellipse,
				rX: 60,
				rY: 90,
				traits: ColliderTraits.BlockProjectile,
				position: [0, -140],
			},
			{
				// feet
				type: ColliderType.Circle,
				radius: 60,
				traits: ColliderTraits.BlockMovementAndProjectile,
				position: [0, -15],
			},
		],
		movementSpeed: 200,
		decelerationRate: 10,
		turningRatePerSecondInDegrees: 300,
		damageConfig: null,
		attackOffset: new Vector(0, 0),
		lootDropOffset: new Vector(0, 0),

		physicalDamage: 0,
		fireDamage: 0,
		iceDamage: 0,
		lightningDamage: 0,
		poisonDamage: 0,
		attackRate: 700,
		critChance: 0,
		critDamage: 1.0,

		maxHealth: ENEMY_DEFAULT_HEALTH * 3,
		defense: 0,
		fireResist: 0,
		poisonResist: 0,
		iceResist: 0,
		lightningResist: 0,

		lootDropProbability: 2,
		lootDropQuantity: 1.5,
		lootDropRarity: 1.1,
	},
	soundEffects: {
		attack: 'Projectile_Zap',
		impact: 'SFX_Impact_Enemy_Mush',
	},
	states: {
		idling: {
			behaviour: IdleBehaviours.MEANDER,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 2,
			transitionToFighting: {
				trigger: FightingTriggers.AGGRESSIVE_PROXIMITY,
				radius: 800,
			},
			meander: {
				radius: 500,
				speedMultiplier: 1,
				minRestTime: 0.5,
				maxRestTime: 10,
			},
			aggroOnHit: true,
		},
		fighting: {
			behaviour: FightingBehaviours.CHASE_AND_ATTACK,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 5,
			attackType: AttackTypes.PROJECTILE,
			engagementMaxDistance: 650,
			engagementMinDistance: 0,
			movementMaxDistance: 650,
			movementMinDistance: 0,
			brain: {
				actions: [
					{
						//  Nova
						priority: 0,
						actionCriteria: playerIsInMeleeRangeBrutalCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							//abilityOptions: [[1, shortRangeAbility, 30]],
							abilityOptions: [attackRatedAbilityList(2, animationTimesStomp, AnimationTrack.STOMP, novaStompAbilityBrutal, 25)],
						},
					},
					{
						//  Nova for tier 2
						priority: 1,
						actionCriteria: playerIsInMeleeRangeHardCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							//abilityOptions: [[1, shortRangeAbility, 30]],
							abilityOptions: [attackRatedAbilityList(2, animationTimesStomp, AnimationTrack.STOMP, novaStompAbilityHard, 25)],
						},
					},
					{
						//  Nova for tier 1
						priority: 2,
						actionCriteria: playerIsInMeleeRangeNormalCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							//abilityOptions: [[1, shortRangeAbility, 30]],
							abilityOptions: [attackRatedAbilityList(2, animationTimesStomp, AnimationTrack.STOMP, novaStompAbilityNormal, 25)],
						},
					},

					{
						// BLOCK/ATTACK
						priority: 3,
						actionCriteria: playerIsInAttackRangeBrutalCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							//abilityOptions: [[1, longRangeAbility, 50]],
							abilityOptions: [drakeBreathAbilityBrutal],
						},
					},
					{
						// BLOCK/ATTACK
						priority: 4,
						actionCriteria: playerIsInAttackRangeHardCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							//abilityOptions: [[1, longRangeAbility, 50]],
							abilityOptions: [drakeBreathAbilityHard],
						},
					},
					{
						// BLOCK/ATTACK
						priority: 5,
						actionCriteria: playerIsInAttackRangeNormalCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							//abilityOptions: [[1, longRangeAbility, 50]],
							abilityOptions: [drakeBreathAbilityNormal],
						},
					},
					{
						// BLOCK/ATTACK
						priority: 6,
						actionCriteria: alwaysTrueCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							//abilityOptions: [[1, longRangeAbility, 50]],
							abilityOptions: [drakeBreathAbilityNormal],
						},
					},
				],
			},
			attackOptions: [
				{
					...EnemyDefaults.projectileConfig,
					projectileCount: 13,
					spreadAngle: degToRad(360),
					colliderRadius: 10,
					speed: 400,
					lifespanInSeconds: 2,
					modifiers: [{ modType: ModType.ACCELERATION, value: { modValueType: ModValueType.RANDOM, min: -0.3, max: 0.0 } }],
					burstCount: 1,
					color: Colors.red,
					bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_DEATHDRAKE,
					bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_TRAIL_DEATHDRAKE,
					muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
				},
				{
					...EnemyDefaults.projectileConfig,
					projectileCount: 1,
					colliderRadius: 10,
					speed: 550,
					lifespanInSeconds: 2,
					modifiers: [
						{ modType: ModType.TURN, value: { modValueType: ModValueType.RANDOM, min: -1.0, max: 1.0 } },
						{ modType: ModType.ACCELERATION, value: { modValueType: ModValueType.RANDOM, min: -0.5, max: 0.0 } },
					],
					burstCount: 8,
					burstDelay: 0.15,
					burstMode: BurstFireModes.STRAIGHT,
					color: Colors.red,
					bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_DEATHDRAKE,
					bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_TRAIL_DEATHDRAKE,
					muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
				},
			],
			shotLeadPrecision: ShotLeadPrecision.AVERAGE,
			visualAimLockSeconds: 1,
			transitionToLeashing: {
				trigger: LeashTriggers.DISTANCE_TRAVELLED_OR_RADIUS_EXCEEDED,
				radius: 2000,
				distanceTravelled: 4000,
			},
		},
		leashing: {
			behaviour: LeashingBehaviours.MOVE,
			speedMultiplier: 4,
		},
		fleeing: {},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 1,
		},
	},
}

export default deathDrake
