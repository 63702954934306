import logger from '../../utils/client-logger'
import { t } from '../../utils/i18n'

export const internationalizationModule = () => {
	const browserLanguage = navigator.language.substring(0, 2)

	// TODO2: Grab preferred lang from saved user preferences

	const preferredLanguage = browserLanguage

	logger.debug('Initializing I18N store module with initial browser language:', preferredLanguage)

	return {
		namespaced: true,
		state: {
			currentLocale: preferredLanguage,
		},
		mutations: {
			explicitlyChangeLocale(state: any, newLocale: string) {
				state.currentLocale = newLocale
			},
		},
		getters: {
			t: (state) => (stringKey: string) => {
				return t(state.currentLocale || 'en', stringKey)
			},
		},
	}
}
