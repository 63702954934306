<template>
	<div class="resistance-container">
		<div class="resistance-header">
			<div class="squiggle-icon left"></div>
			<div class="header-text">Resistance</div>
			<div class="squiggle-icon right"></div>
		</div>
		<div class="resistances">
			<div class="resistance-name">
				<div class="resistance-icon damage"></div>
				<div class="resistance-text">{{ resistantStatText('physicalResist') }}</div>
			</div>
			<div class="resistance-name">
				<div class="resistance-icon fire"></div>
				<div class="resistance-text">{{ resistantStatText('fireResist') }}</div>
			</div>
			<div class="resistance-name">
				<div class="resistance-icon lightning"></div>
				<div class="resistance-text">{{ resistantStatText('lightningResist') }}</div>
			</div>
			<div class="resistance-name">
				<div class="resistance-icon ice"></div>
				<div class="resistance-text">{{ resistantStatText('iceResist') }}</div>
			</div>
			<div class="resistance-name">
				<div class="resistance-icon poison"></div>
				<div class="resistance-text">{{ resistantStatText('poisonResist') }}</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { getDamageReduction, ENEMY_ARMOR_PENETRATION_MULTIPLIER } from '../../../engine/shared/game-data/damage-formulas'
import { gameModeConfig } from '../../../engine/shared/game-mode-configs'
import { GameModeType } from '../../../engine/shared/game-mode-type'
import { getBiomeCount } from '../../../biome/shared/biome-list'

export default {
	name: 'PaperDollResistance',
	computed: {
		...mapGetters('paperdoll', ['playerStats']),
		...mapGetters('hud', ['progressionLevel', 'activeWorldTier']),
		...mapState('hud', ['currentBiome']),
	},
	methods: {
		resistantStatText(statName) {
			const stat = this.playerStats[statName]

			let biomeLevel
			if (gameModeConfig.type === GameModeType.Hub) {
				biomeLevel = this.progressionLevel + 1
			} else {
				const activeWorldTier = this.activeWorldTier
				biomeLevel = this.currentBiome + 1 + (activeWorldTier - 1) * getBiomeCount(gameModeConfig.type)
			}

			const armorPen = Math.pow(ENEMY_ARMOR_PENETRATION_MULTIPLIER, biomeLevel - 1)

			const reduction = getDamageReduction(stat, armorPen)
			return Math.round(100 - reduction * 100) + '%'
		},
	},
}
</script>

<style lang="less" scoped>
@import '../../global/fonts/text.less';

.resistance-container {
	.resistance-header {
		display: flex;
		align-items: center;
		justify-content: center;
		.paper-doll-resistances();
		.squiggle-icon {
			background-image: url('./assets/squiggle.png');
			width: 43px;
			height: 18px;
			background-repeat: no-repeat;
			background-size: cover;
			margin: 0px 15px;

			&.left {
				transform: scaleX(-1);
			}
			&.right {
			}
		}
	}
	.resistances {
		display: flex;
		align-items: center;
		justify-content: center;
		.resistance-name {
			display: flex;
			flex-flow: column;
			align-items: center;
			.paper-doll-resistances();
			.resistance-icon {
				width: 71px;
				height: 69px;
				background-repeat: no-repeat;
				background-size: cover;
				&.damage {
					background-image: url('./assets/resistance-icon-damage.png');
				}
				&.fire {
					background-image: url('./assets/resistance-icon-fire.png');
				}
				&.lightning {
					background-image: url('./assets/resistance-icon-lightning.png');
				}
				&.ice {
					background-image: url('./assets/resistance-icon-ice.png');
				}
				&.poison {
					background-image: url('./assets/resistance-icon-poison.png');
				}
			}
			.resistance-text {
				position: relative;
				top: -5px;
			}
		}
	}
}
</style>
