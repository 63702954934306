import { ItemSubType } from "../../loot/shared/item-type"
import { PersistentBuffSubType } from "../../loot/shared/persistent-buff-sub-type"

export enum AccountBuffIdentifier {
	MoveSpeed = 'moveSpeed',
	EnchantmentPotion = 'enchantmentPotion',
	LifeBloom = 'lifeBloom',
	RegenerativeHealing = 'regenerativeHealing',
	QueensRansom = 'queensRansom',
	QueensRansomLegendary = 'queensRansomLegendary',
	FinalCountdown = 'finalCountdown',
	IronMan = 'ironman',
	Pauper = 'pauper',
	RagsToRiches = 'ragsToRiches'
}

export interface AccountPersistedBuff {
	identifier: AccountBuffIdentifier
	uses: number
	createdAt: string
	expiresAt: string
}

export const UsableItemSubTypeToAccountBuff = new Map<ItemSubType, [AccountBuffIdentifier, any]>()

UsableItemSubTypeToAccountBuff.set(PersistentBuffSubType.Enchanting, [AccountBuffIdentifier.EnchantmentPotion, { type: 'duration', duration: '0020' }])

UsableItemSubTypeToAccountBuff.set(PersistentBuffSubType.LegendaryEnchanting, [AccountBuffIdentifier.EnchantmentPotion, { type: 'duration', duration: '2400' }])

UsableItemSubTypeToAccountBuff.set(PersistentBuffSubType.LifeBloom, [AccountBuffIdentifier.LifeBloom, { type: 'duration', duration: '0020' }])

UsableItemSubTypeToAccountBuff.set(PersistentBuffSubType.RegenerativeHealing, [AccountBuffIdentifier.RegenerativeHealing, { type: 'duration', duration: '0200' }])

UsableItemSubTypeToAccountBuff.set(PersistentBuffSubType.QueensRansom, [AccountBuffIdentifier.QueensRansom, { type: 'duration', duration: '0020' }])

UsableItemSubTypeToAccountBuff.set(PersistentBuffSubType.LegendaryQueensRansom, [AccountBuffIdentifier.QueensRansomLegendary, { type: 'duration', duration: '0020' }])

UsableItemSubTypeToAccountBuff.set(PersistentBuffSubType.FinalCountdown, [AccountBuffIdentifier.FinalCountdown, { type: 'duration', duration: '0020' }])

UsableItemSubTypeToAccountBuff.set(PersistentBuffSubType.IronMan, [AccountBuffIdentifier.IronMan, { type: 'duration', duration: '0020' }])

UsableItemSubTypeToAccountBuff.set(PersistentBuffSubType.Pauper, [AccountBuffIdentifier.Pauper, {type: 'duration', duration: '0200'}])

UsableItemSubTypeToAccountBuff.set(PersistentBuffSubType.RagsToRiches, [AccountBuffIdentifier.RagsToRiches, {type: 'duration', duration: '0200'}])