













































































import { mapGetters, mapMutations } from 'vuex'
import CloseButton from '../buttons/close-button.vue'
import { currencyTooltip, prismScalesTooltip } from '../../reusable-components/v-tooltip/v-tooltip-functions'
import { to4DigitTruncatedString } from '../../../utils/math'
import { maxEquipableLevel } from '../../../engine/shared/game-data/player'

const rarityShardImages = require('../inventory-item/assets/rarity-shard-*.png')

const PANEL_DETAILS = [
	{ panelId: 'emailVerification', name: 'Verify Email', badge: 'confirm', panelStyle: 'main-panel' },
	{ panelId: 'wormMail', name: 'Worm Mail', badge: 'worm-mail', panelStyle: 'main-panel' },
	{ panelId: 'wormDelivery', name: 'Worm Delivery', badge: 'worm-mail', panelStyle: 'main-panel' },
	{ panelId: 'pitOfChances', name: 'Pit Of Chances', badge: 'pit-of-chances', panelStyle: 'main-panel' },
	{ panelId: 'pitRewards', name: 'Pit Of Chances', badge: 'pit-of-chances', panelStyle: 'main-panel' },
	{ panelId: 'pitStorage', name: 'Pit Storage', badge: 'pit-of-chances', panelStyle: 'main-panel' },
	{ panelId: 'market', name: 'Market', badge: 'market', panelStyle: 'main-panel' },
	{ panelId: 'userListing', name: 'My Listings', badge: 'market', panelStyle: 'main-panel' },
	{ panelId: 'addListing', name: 'Add Listing', badge: 'market', panelStyle: 'main-panel' },
	{ panelId: 'listItemPopUp', name: 'Add Listing', badge: 'market', panelStyle: 'main-panel' },
	{ panelId: 'leaderboard', name: 'Leaderboard', badge: 'leaderboard', panelStyle: 'main-panel' },
	{ panelId: 'furnace', name: 'Furnace', badge: 'furnace', panelStyle: 'main-panel' },
	{ panelId: 'stash', name: 'Stash', badge: 'stash', panelStyle: 'main-panel' },
	{ panelId: 'enqueued', name: 'Finding Game...', badge: 'generic', panelStyle: 'main-panel' },
	{ panelId: 'inventory', name: 'Inventory', badge: 'inventory', panelStyle: 'main-panel' },
	{ panelId: 'depart', name: 'Departure', badge: 'depart', panelStyle: 'main-panel' },
	{ panelId: 'augmentationStation', name: 'Augments', badge: 'augmentationStation', panelStyle: 'main-panel' },
	{ panelId: 'confirm', name: 'Confirm', badge: 'confirm', panelStyle: 'main-panel' },
	{ panelId: 'customize', name: 'Customize', badge: 'customize', panelStyle: 'main-panel' },
	{ panelId: 'dead', name: 'Dead', badge: 'dead', panelStyle: 'main-panel' },
	{ panelId: 'error', name: 'Something went wrong!', badge: 'error', panelStyle: 'main-panel' },
	{ panelId: 'genericYesNo', name: 'confirm', badge: 'error', panelStyle: 'main-panel' },
	{ panelId: 'genericPrompt', name: 'Alert', badge: 'generic', panelStyle: 'main-panel' },
	{ panelId: 'genericRewards', name: 'Alert', badge: 'generic', panelStyle: 'main-panel' },
	{ panelId: 'weather', name: 'Weatherman', badge: 'weatherman', panelStyle: 'main-panel' },
	{ panelId: 'tutorial', name: 'Tutorial', badge: 'question-mark', panelStyle: 'main-panel' },
	{ panelId: 'videoPlayer', name: 'Video', badge: 'generic', panelStyle: 'main-panel' },
	{ panelId: 'welcome', name: '-Welcome-', badge: 'worm-mail-color', panelStyle: 'main-panel' },
	{ panelId: 'news', name: 'News', badge: 'news', panelStyle: 'main-panel' },
	{ panelId: 'generic', name: 'Generic', badge: 'generic', panelStyle: 'main-panel' },
	{ panelId: 'startUpError', name: 'Error', badge: 'generic', panelStyle: 'main-panel' },
	{ panelId: 'map', name: 'Map', badge: 'map', panelStyle: 'main-panel' },
	{ panelId: 'paperdoll', name: 'Paperdoll', badge: 'paperdoll', panelStyle: 'main-panel' },
	{ panelId: 'playerprofile', name: 'Player Profile', badge: 'playerprofile', panelStyle: 'main-panel' },
	{ panelId: 'mtxStore', name: 'MTX Store', badge: 'mtx-store', panelStyle: 'main-panel' },
	{ panelId: 'reward', name: 'Rewards', badge: 'reward', panelStyle: 'main-panel-no-x' },
	{ panelId: 'settings', name: 'Settings', badge: 'settings', panelStyle: 'main-panel' },
	{ panelId: 'generalStore', name: 'General Store', badge: 'store', panelStyle: 'main-panel' },
	{ panelId: 'outpostStore', name: `Bubba's Shop`, badge: 'store', panelStyle: 'main-panel' },
	{ panelId: 'subscription', name: 'Subscription', badge: 'subscription', panelStyle: 'mtx-panel' },
	{ panelId: 'premiumStore', name: 'Premium Store', badge: 'mtx-store', panelStyle: 'mtx-panel' },
	{ panelId: 'itemDetails', name: 'Item Details', badge: 'mtx-store', panelStyle: 'mtx-panel' },
	{ panelId: 'logIn', name: 'Not Logged In', badge: 'settings', panelStyle: 'main-panel' },
	{ panelId: 'identify', name: 'Identify', badge: 'identify', panelStyle: 'main-panel' },
	{ panelId: 'error303', name: 'Error 303', badge: 'error', panelStyle: 'main-panel' },
	{ panelId: 'maintenance', name: 'Maintenance', badge: 'dead', panelStyle: 'main-panel-no-x' },
	{ panelId: 'welcomeScreen', name: 'Welcome', badge: 'playerprofile', panelStyle: 'main-panel-no-x' },
	{ panelId: 'anonymousAccount', name: 'Start Without Account', badge: 'playerprofile', panelStyle: 'main-panel-no-x' },
	{ panelId: 'login', name: 'Log In', badge: 'playerprofile', panelStyle: 'main-panel-no-x' },
	{ panelId: 'loggedIn', name: '', badge: 'playerprofile', panelStyle: 'main-panel-no-x' },
	{ panelId: 'createAccount', name: 'Create Account', badge: 'playerprofile', panelStyle: 'main-panel-no-x' },
	{ panelId: 'forgotPassword', name: 'Forgot Password', badge: 'playerprofile', panelStyle: 'main-panel-no-x' },
	{ panelId: 'resetPassword', name: 'Reset Password', badge: 'playerprofile', panelStyle: 'main-panel-no-x' },
	{ panelId: 'pitOfChancesStorage', name: 'Pit Of Chances Storage', badge: 'pit-of-chances', panelStyle: 'main-panel' },
	{ panelId: 'stackableSelection', name: 'Transfer Items', badge: 'pit-of-chances', panelStyle: 'main-panel-no-x' },
	{ panelId: 'endRun', name: 'End Run', badge: 'depart', panelStyle: 'main-panel-no-x' },
	{ panelId: 'gameOver', name: 'Game Over', badge: 'dead', panelStyle: 'main-panel' },
	{ panelId: 'worldTierComplete', name: 'You Completed This World Tier', badge: 'depart', panelStyle: 'main-panel-no-x' },
	{ panelId: 'featuredSales', name: 'Featured Sales', badge: 'mtx-store', panelStyle: 'mtx-panel' },
	{ panelId: 'DailyLogIn', name: 'Daily Login Rewards', badge: 'daily-reward', panelStyle: 'main-panel' },
	{ panelId: 'party', name: 'Party', badge: 'party', panelStyle: 'main-panel' },
	{ panelId: 'wormHorn', name: 'Worm Horns', badge: 'worm-horn', panelStyle: 'main-panel-no-x' },
	{ panelId: 'characterSelection', name: 'Select Avatar', badge: 'playerprofile', panelStyle: 'main-panel-no-x' },
	{ panelId: 'faq', name: 'FAQ', badge: 'question-mark', panelStyle: 'main-panel' },
	{ panelId: 'ironRecruit', name: 'Order of Iron', badge: 'factions', panelStyle: 'main-panel' },
	{ panelId: 'dawnRecruit', name: 'Scions of Dawn', badge: 'factions', panelStyle: 'main-panel' },
	{ panelId: 'aurumRecruit', name: 'Aurum Alliance', badge: 'factions', panelStyle: 'main-panel' },
	{ panelId: 'factionScoreboard', name: 'Faction Standings', badge: 'factions', panelStyle: 'main-panel' },
	{ panelId: 'factionRewards', name: 'Faction Rewards', badge: 'factions', panelStyle: 'main-panel' },
	{ panelId: 'factionJoined', name: 'Factions', badge: 'factions', panelStyle: 'main-panel' },
	{ panelId: 'emoteCustomization', name: 'Emotes', badge: 'emotes', panelStyle: 'main-panel' },
	{ panelId: 'paperdoll', name: 'PaperDoll', badge: 'paperdoll', panelStyle: 'main-panel' },
	{ panelId: 'biomeKey', name: 'Biome Keys & End Run', badge: 'depart', panelStyle: 'main-panel' },

]

export default {
	name: 'MainPanel',
	components: {
		CloseButton,
	},
	props: {
		height: {
			type: Number,
			required: false,
			default: 1150,
		},
		underConstruction: {
			type: Boolean,
		},
		onCloseBtnClick: {
			type: Function,
			required: true,
			default: () => {
				console.warn('The default on-close-btn-click handler for the <MainPanel /> component has been called. Did you forget to pass an :on-click handler to the component?')
			},
		},
		panelIdentifier: {
			type: String,
			required: true,
			validator: function(value) {
				return PANEL_DETAILS.some(({ panelId }) => panelId === value)
			},
		},
		showGoldCurrency: {
			type: Boolean,
			required: false,
			default: false,
		},
		showScaleCurrency: {
			type: Boolean,
			required: false,
			default: false,
		},
		itemComparisonEquippedItem: {
			type: Object,
			required: false,
			default: () => {
				// Default value is an empty object
			},
			validator(item) {
				return item.id !== undefined
			},
		},
		itemComparisonSelectedItem: {
			type: Object,
			required: false,
			default: () => {
				// Default value is an empty object
			},
			validator(item) {
				return item.id !== undefined
			},
		},
		removableAugments: {
			type: Boolean,
			required: false,
			default: false,
		},
		hideCloseButton: {
			type: Boolean,
			required: false,
			default: false,
		},
		customTitle: {
			type: String,
			required: false,
			default: '',
		},
		showFaqIcon: {
			type: Boolean,
			required: false,
			default: false,
		},
		showRarityShards: {
			type: Boolean,
			required: false,
			default: false,
		},
		rarity: {
			type: String,
			required: false,
			default: '',
		},
	},
	computed: {
		...mapGetters('UIScale', ['currentScale']),
		...mapGetters('user', ['username']),
		...mapGetters('hud', ['coinBalance', 'scalesBalance', 'progressionLevel']),
		...mapGetters('augmentationStation', ['currentSelectedItem', 'getRarityShardsBasedOnItem']),
		panel() {
			return PANEL_DETAILS.find(({ panelId }) => panelId === this.panelIdentifier)
		},
		selectedItem() {
			return rarityShardImages[this.rarity]
		},
		level() {
			return maxEquipableLevel(this.progressionLevel)
		},
	},
	methods: {
		...mapMutations('UIScale', ['updatePanelWidth']),
		currencyTooltip,
		prismScalesTooltip,
		to4DigitTruncatedString,
	},
}
