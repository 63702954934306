import logger from '../../utils/client-logger'
import { UI } from '../ui'

interface ItemComparisonUIState {
	selectedEquippedItemId: any
	selectedItemId: any
	equippedItemId: any
	selectedItem: any
	selectedItemContainer: string
	showingAugments: boolean
}

export const itemComparisonUIState = () => {
	logger.debug('Initializing Item Comparison UI module')
	return {
		namespaced: true,
		state: {
			// TODO3: Cleanup - this is messy and kinda dumb, but it works so there's that
			selectedEquippedItemId: null,
			selectedItemId: null,
			equippedItemId: null,
			selectedItem: null,
			selectedItemContainer: null,
			showingAugments: true,
		},
		mutations: {
			deselectItem(state: ItemComparisonUIState) {
				state.selectedItemId = null
				state.selectedItemContainer = null
			},
			resetSelectedItem(state: ItemComparisonUIState) {
				state.selectedItem = null
			},
			selectItem(state: ItemComparisonUIState, selectedItemPayload: any) {
				const { itemId, container } = selectedItemPayload
				state.selectedItemId = itemId
				state.selectedItemContainer = container

				const itemDetails = UI.getInstance().store.getters['itemContainers/itemDetails']
				const selectedDetails = itemDetails(container, itemId)
				state.selectedItem = selectedDetails

				if (state.selectedEquippedItemId) {
					const equippedDetails = itemDetails('paperdoll', state.selectedEquippedItemId)
					if (equippedDetails.itemType !== selectedDetails.itemType) {
						state.selectedEquippedItemId = null
					}
				}
			},
			deselectEquippedItem(state: ItemComparisonUIState) {
				state.selectedEquippedItemId = null
			},
			selectEquippedItem(state: ItemComparisonUIState, selectedItem: string) {
				state.selectedEquippedItemId = selectedItem

				state.equippedItemId = selectedItem

				if (state.selectedItemId) {
					const itemDetails = UI.getInstance().store.getters['itemContainers/itemDetails']
					const equippedDetails = itemDetails('paperdoll', selectedItem)
					const selectedDetails = itemDetails(state.selectedItemContainer, state.selectedItemId)
					if (selectedDetails && equippedDetails.itemType !== selectedDetails.itemType) {
						state.selectedItemId = null
						state.selectedItemContainer = null
					}
				}
			},
			setShowingAugments(state: ItemComparisonUIState, showing: boolean) {
				state.showingAugments = showing
			},
			setBothItemsDirectly(state: ItemComparisonUIState, payload: any) {
				const { item1Id, item2 } = payload

				state.equippedItemId = item1Id
				state.selectedItem = item2
			},
			updateAugmentSlot(state: ItemComparisonUIState, weaponId:any){
				if(weaponId === state.selectedEquippedItemId){
					state.selectedItem.augmentLimit += 1
				}
			}
		},
		getters: {
			selectedEquippedItem(state: ItemComparisonUIState) {
				return state.equippedItemId
			},
			selectedItem(state: ItemComparisonUIState) {
				return state.selectedItem
			},
			selectedItemContainer(state: ItemComparisonUIState) {
				return state.selectedItemContainer
			},
			showingAugments(state: ItemComparisonUIState) {
				return state.showingAugments
			},
			shouldShowComparisonPanel(state: ItemComparisonUIState, getters, rootState, rootGetters) {
				return getters.showEquippedItemComparison || getters.showSelectedItemComparison
			},
			showEquippedItemComparison(state, getters, rootState, rootGetters) {
				const itemDetails = rootGetters['itemContainers/itemDetails']
				const equippedDetails = itemDetails('paperdoll', state.selectedEquippedItemId)
				return equippedDetails && (equippedDetails.itemType === 'Weapon' || equippedDetails.itemType === 'Gear' || equippedDetails.itemType === 'Weapon Augment')
			},
			showSelectedItemComparison(state, getters, rootState, rootGetters) {
				const itemDetails = rootGetters['itemContainers/itemDetails']
				const selectedDetails = itemDetails(state.selectedItemContainer, state.selectedItemId)
				return selectedDetails && (selectedDetails.itemType === 'Weapon' || selectedDetails.itemType === 'Gear' || selectedDetails.itemType === 'Weapon Augment')
			},
		},
		actions: {
			resetAll({ state }: { state: ItemComparisonUIState }) {
				state.selectedEquippedItemId = null
				state.selectedItemId = null
				state.selectedItemContainer = null
				state.equippedItemId = null
				state.selectedItem = null
			},
		},
	}
}
