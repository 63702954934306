import nengi from 'nengi'
import { gameUnits } from '../../utils/primitive-types'

const ARRAY_INDEX_TYPE = nengi.UInt16
const POSITION_TYPE = nengi.Float32

class DrawShapesMessage {
	static protocol = {
		commands: { type: nengi.UInt16, indexType: nengi.UInt4 },
		n1: { type: POSITION_TYPE, indexType: ARRAY_INDEX_TYPE },
		n2: { type: POSITION_TYPE, indexType: ARRAY_INDEX_TYPE },
		n3: { type: POSITION_TYPE, indexType: ARRAY_INDEX_TYPE },
		n4: { type: POSITION_TYPE, indexType: ARRAY_INDEX_TYPE },
		color: { type: nengi.Int32, indexType: ARRAY_INDEX_TYPE },
		permanent: { type: nengi.Boolean, indexType: ARRAY_INDEX_TYPE },
		destroyAfterSeconds: { type: nengi.Float32, indexType: ARRAY_INDEX_TYPE },
	}

	commands: number[]
	n1: gameUnits[]
	n2: gameUnits[]
	n3: gameUnits[]
	n4: gameUnits[]
	color: number[]
	permanent: boolean[]
	destroyAfterSeconds: number[]

	constructor(commands: number[], n1: gameUnits[], n2: gameUnits[], n3: gameUnits[], n4: gameUnits[], color: gameUnits[], permanent: boolean[], destroyAfterSeconds: number[]) {
		this.commands = commands
		this.n1 = n1
		this.n2 = n2
		this.n3 = n3
		this.n4 = n4
		this.color = color
		this.permanent = permanent
		this.destroyAfterSeconds = destroyAfterSeconds
	}
}

export default DrawShapesMessage
