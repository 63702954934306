import { EnemyAI, EnemyType } from '../shared/ai-types'
import { checkActionDrivenEnemy } from './action-checks'
import DevToolsManager from '../../ui/dev-tools/dev-tools-manager'
import trainingDummy from './enemies/training-dummy'
import basic from './enemies/basic'
import blimpie from './enemies/blimpie'
import wasp from './enemies/wasp'
import distancer from './enemies/distancer'
import strafer from './enemies/strafer'
import ent from './enemies/ent'
import sporekid from './enemies/sporekid'
import mushie from './enemies/mushie'
import boss from './enemies/boss'
import shrieker from './enemies/shrieker'
import spawner from './enemies/spawner'
import shamblingMound from './enemies/shambling-mound'
import fungiBoss from './enemies/fungi-boss'
import eyebat from './enemies/eyebat'
import beachBoss from './enemies/beach-boss'
import skeletonBeach from './enemies/skeleton-beach'
import asp from './enemies/asp'
import dreamerPrism from './enemies/dreamer-prism'
import mosquito from './enemies/mosquito'
import crab from './enemies/crab'
import goblin from './enemies/goblin'
import spider from './enemies/spider'
import thornWolf from './enemies/thorn-wolf'
import wisp from './enemies/wisp'
import skeletonKnight from './enemies/skeleton-knight'
import skeletalWhelp from './enemies/skeletal-whelp'
import deathDrake from './enemies/death-drake'
import boneSpirit from './enemies/bone-spirit'
import weakTinyCrab from './enemies/weak-tiny-crab'
import crabNestA from './enemies/crab-nest-a'
import crabNestB from './enemies/crab-nest-b'
import crabNestC from './enemies/crab-nest-c'
import crabNestD from './enemies/crab-nest-d'
import crabNestE from './enemies/crab-nest-e'
import crabNestF from './enemies/crab-nest-f'
import hermitCrab from './enemies/hermit-crab'
import shamblingTower from './enemies/shambling-tower'
import stranglevine from './enemies/stranglevine'
import dragonSkull from './enemies/dragon-skull'
import golemPrism from './enemies/golem-prism'
import crystalSpider from './enemies/crystal-spider'
import dreamerFungi from './enemies/dreamer-fungi'
import forestBoss from './enemies/forest-boss'
import highlandsBoss from './enemies/highlands-boss'
import prismBoss from './enemies/prism-boss'
import fungiWolf from './enemies/fungi-wolf'
import prismWisp from './enemies/prism-wisp'
import prismfly from './enemies/prismfly'
import waspForest from './enemies/wasp-forest'
import sliver from './enemies/sliver'
import sliverPrismBossMinion from './enemies/sliver-prism-boss-minion'
import golemHighlands from './enemies/golem-highlands'
import dummyA from './enemies/dummy-a'
import dummyB from './enemies/dummy-b'
import dummyC from './enemies/dummy-c'
import dummyD from './enemies/dummy-d'
import dummyE from './enemies/dummy-e'
import dummyF from './enemies/dummy-f'
import dummyG from './enemies/dummy-g'
import dummyH from './enemies/dummy-h'
import bee from './enemies/bee'
import crabTopHat from './enemies/crab-top-hat'
import goblinJester from './enemies/goblin-jester'
import thornWolfCone from './enemies/thorn-wolf-cone'
import dreamerFancy from './enemies/dreamer-fancy'
import entBeard from './enemies/ent-beard'
import shriekerVines from './enemies/shrieker-vines'
import skeletonKnightBalloon from './enemies/skeleton-knight-balloon'
import skeletalWhelpHatched from './enemies/skeletal-whelp-hatched'
import sliverCracked from './enemies/sliver-cracked'
import crabBarrel from './enemies/crab-barrel'
import { findInObject } from '../../utils/object-util'
import mermaidMast from './enemies/mermaid-mast'
import lanternPost from './enemies/lantern-post'
import tanningRack from './enemies/tanning-rack'
import fungiSkeleton from './enemies/fungi-skeleton'
import sporeSack from './enemies/spore-sack'
import massiveSkullPile from './enemies/massive-skull-pile'
import mysteriousGem from './enemies/mysterious-gem'
import corruptedLamp from './enemies/corrupted-lamp'
import crystalHand from './enemies/crystal-hand'

const runAndLogEnemyValidation = false

// Map(enemy-name -> enemy)
const EnemyDefinitions: Map<string, EnemyAI> = new Map()

const enemies = [
	trainingDummy,
	eyebat,
	basic,
	blimpie,
	bee,
	wasp,
	dreamerPrism,
	dreamerFungi,
	dreamerFancy,
	distancer,
	strafer,
	ent,
	entBeard,
	boss,
	shrieker,
	shriekerVines,
	shamblingMound,
	spawner,
	sporekid,
	mushie,
	fungiBoss,
	beachBoss,
	skeletonBeach,
	asp,
	mosquito,
	sliver,
	sliverPrismBossMinion,
	sliverCracked,
	crab,
	crabTopHat,
	goblin,
	goblinJester,
	spider,
	thornWolf,
	thornWolfCone,
	wisp,
	skeletonKnight,
	skeletonKnightBalloon,
	skeletalWhelp,
	skeletalWhelpHatched,
	deathDrake,
	boneSpirit,
	weakTinyCrab,
	crabNestA,
	crabNestB,
	crabNestC,
	crabNestD,
	crabNestE,
	crabNestF,
	hermitCrab,
	shamblingTower,
	stranglevine,
	dragonSkull,
	golemPrism,
	crystalSpider,
	forestBoss,
	highlandsBoss,
	prismBoss,
	fungiWolf,
	prismWisp,
	prismfly,
	waspForest,
	golemHighlands,
	dummyA,
	dummyB,
	dummyC,
	dummyD,
	dummyE,
	dummyF,
	dummyG,
	dummyH,
	crabBarrel,
	mermaidMast,
	lanternPost,
	tanningRack,
	fungiSkeleton,
	sporeSack,
	massiveSkullPile,
	mysteriousGem,
	corruptedLamp,
	crystalHand,
]

enemies.forEach((enemy) => {
	EnemyDefinitions.set(enemy.name, enemy)
})

Object.freeze(EnemyDefinitions)

EnemyDefinitions.forEach((enemy, key) => {
	if (process.env.NODE_ENV === 'beta' || process.env.NODE_ENV === 'loot-prod') {
		Object.freeze(enemy)
	} else {
		DevToolsManager.getInstance().addNamedObject(enemy)

		const actionDrivenEnemy = enemy.actionDrivenEnemyData || enemy.states.fighting.brain
		if (actionDrivenEnemy) {
			checkActionDrivenEnemy(enemy.name, actionDrivenEnemy)

			// add all enemy Ability's
			findInObject(actionDrivenEnemy, (name: string, o: any) => {
				return o.abilityType
			}).forEach((foundObject) => {
				DevToolsManager.getInstance().addObjectByName(`${enemy.name}.${foundObject.obj.debugName}`, foundObject.obj)
			})

			DevToolsManager.getInstance().addObjectByName(`${enemy.name}.actions`, actionDrivenEnemy.actions)
			for (let i = 0; i < actionDrivenEnemy.actions.length; i++) {
				const element = actionDrivenEnemy.actions[i]
				DevToolsManager.getInstance().addObjectByName(`${enemy.name}.action[${i}]`, element)
			}
		}

		if (runAndLogEnemyValidation) {
			validateEnemyBalance(enemy)
		}
	}
})

function validateEnemyBalance(enemy: EnemyAI) {
	// ensure enemies have a good health-to-loot ratio
	if ( enemy.baseAttributes.lootDropQuantity > 0 && enemy.type !== EnemyType.BOSS) {
		let healthToLootRatio 
		if (enemy.baseAttributes.lootDropProbability !== 100) {
			healthToLootRatio = enemy.baseAttributes.maxHealth / (enemy.baseAttributes.lootDropQuantity * enemy.baseAttributes.lootDropProbability)
		} else {
			healthToLootRatio = enemy.baseAttributes.maxHealth / enemy.baseAttributes.lootDropQuantity
		}
		if (healthToLootRatio <= 70 || healthToLootRatio >= 175) {
			const isLow = healthToLootRatio <= 70
			console.warn(`${(`${enemy.name} has a ${(isLow ? 'LOW' : 'HIGH')} health-to-loot ratio`).padEnd(55, ' ')}: probability ${enemy.baseAttributes.lootDropProbability}, quantity ${enemy.baseAttributes.lootDropQuantity}, health ${enemy.baseAttributes.maxHealth}, ratio ${~~healthToLootRatio}`)
		}
	}
}

export const AllBosses = [boss, fungiBoss, beachBoss, forestBoss, prismBoss, highlandsBoss]

export default EnemyDefinitions
