import { ColliderTraits, ColliderType } from '../../collision/shared/colliders'
import { PropConfigs } from './prop-configs'

export const sharedPropConfigs: PropConfigs = {
	docks: {
		overrideAtlas: 'east-west-edges-faction-decorations',
		zOffset: -675,
		visibilityRadius: 1025,
		colliders: [
			{ type: ColliderType.Box, position: [-121, 158], width: 200, height: 363, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-460, -582], width: 581, height: 357, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [49, -217], radius: 28, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-1026, -291], width: 923, height: 100, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-1026, 126], width: 923, height: 100, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-992, -294], width: 113, height: 183, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-1051, -198], width: 53, height: 319, traits: ColliderTraits.BlockAll },
		],
	},
	'edge-tile-east': {
		overrideAtlas: 'east-west-edges',
		zOffset: -Number.MAX_SAFE_INTEGER,
		visibilityRadius: 1025,
		colliders: [{ type: ColliderType.Box, position: [-55, -503], width: 777, height: 1024, traits: ColliderTraits.BlockMovementAndItem }],
	},
	'edge-tile-west': {
		overrideAtlas: 'east-west-edges',
		zOffset: -1100,
		visibilityRadius: 1025,
		colliders: [{ type: ColliderType.Box, position: [-633, -585], width: 777, height: 1024, traits: ColliderTraits.BlockMovementAndItem }],
	},
	'edge-tile-west-posts': {
		overrideAtlas: 'east-west-edges',
		zOffset: 675,
	},
	water: {
		overrideAtlas: 'east-west-edges',
		zOffset: -11000,
		visibilityRadius: 1025,
	},
	'west-edge-bottom-cap': {
		overrideAtlas: 'east-west-edges',
		visibilityRadius: 1025,
		colliders: [{ type: ColliderType.Circle, position: [330, -13], radius: 133, traits: ColliderTraits.BlockAll }],
	},
	'west-edge-top-cap': {
		overrideAtlas: 'east-west-edges',
		visibilityRadius: 1025,
		colliders: [{ type: ColliderType.Circle, position: [338, 13], radius: 163, traits: ColliderTraits.BlockAll }],
	},
	'edge-tile-west-dock': {
		zOffset: -1000,
		dontLoadAsset: true,
	},
	'waves-thin': {
		dontLoadAsset: true,
	},
	'waves-thick': {
		dontLoadAsset: true,
	},
	'water-west': {
		dontLoadAsset: true,
	},
	seagull: {
		overrideAtlas: 'east-west-edges',
	},
	boat: {
		overrideAtlas: 'east-west-edges-faction-decorations',
		zOffset: 1000,
	},
	'med-common-4': {
		dontLoadAsset: true,
		zOffset: 675,
	},
	'med-common-03': {
		dontLoadAsset: true,
		zOffset: 20,
	},
	'med-common-04': {
		dontLoadAsset: true,
		zOffset: 675,
	},
	'med-uncommon-04': {
		dontLoadAsset: true,
		zOffset: 675,
	},
	lights: {
		overrideAtlas: 'east-west-edges',
	},
	'dock-lamp': {
		dontLoadAsset: true,
		zOffset: 25,
	},
	'dock-barrelfungi': {
		dontLoadAsset: true,
		zOffset: 55,
	},
	'dock-cratecoconut': {
		dontLoadAsset: true,
		zOffset: 25,
	},
	'faction-plant-aurum-01': {
		overrideAtlas: 'hub-props-factions-decorations',
		dontLoadAsset: true,
		zOffset: 25,
	},
	'faction-plant-iron-01': {
		dontLoadAsset: true,
		zOffset: 25,
	},
	'faction-plant-iron-03': {
		dontLoadAsset: true,
		zOffset: 25,
	},
	'faction-plant-scion-01': {
		dontLoadAsset: true,
		zOffset: 25,
	},
	'faction-plant-scion-03': {
		dontLoadAsset: true,
		zOffset: 25,
	},
	'faction-short-bunting': {
		dontLoadAsset: true,
		zOffset: 200,
	},
	'flag-lamp-right-neutral': {
		dontLoadAsset: true,
		zOffset: 25,
	},
	'Connection-Road': {
		overrideAtlas: 'end-island-props',
		zOffset: -99999,
		visibilityRadius: 3500,
		colliders: [
			{ type: ColliderType.Box, position: [-2386, 545], width: 252, height: 269, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-3128, 106], width: 1218, height: 269, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-2153, 533], width: 345, height: 269, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-2883, 545], width: 504, height: 275, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-3129, 573], width: 252, height: 269, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-1909, 620], width: 1013, height: 141, angle: -0.75, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-2013, 270], width: 987, height: 141, angle: -0.75, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-1185, -85], width: 233, height: 269, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-1233, -470], width: 289, height: 191, traits: ColliderTraits.BlockMovementAndItem },
		],
	},
	'End-Island': {
		overrideAtlas: 'end-island-props',
		disableFileOrdering: true,
		visibilityRadius: 3000,
		colliders: [
			{ type: ColliderType.Box, position: [58, -99], width: 354, height: 71, angle: -0.14, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-258, -197], width: 354, height: 89, angle: 0.29, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-368, -302], width: 175, height: 90, angle: 0.67, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-413, -282], width: 234, height: 51, angle: -0.57, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-524, 185], width: 256, height: 163, angle: -0.57, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-337, 115], width: 221, height: 89, angle: -0.33, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-243, 78], width: 213, height: 82, angle: -1.18, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-114, -129], width: 86, height: 89, angle: 0.88, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-364, -178], width: 249, height: 76, angle: 0.23, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-426, -245], width: 138, height: 58, angle: 0.84, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-532, -287], width: 168, height: 65, angle: 0.27, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-668, -323], width: 436, height: 62, angle: -0.28, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-775, -495], width: 436, height: 62, angle: -0.28, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-347, -336], width: 137, height: 73, angle: -0.87, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-589, -305], width: 137, height: 91, angle: -0.44, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-889, -530], width: 145, height: 142, angle: -1.14, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-1042, -322], width: 293, height: 101, angle: -1.28, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-962, -69], width: 241, height: 76, angle: 0.37, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-762, 50], width: 343, height: 76, angle: 0.45, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [400, -93], width: 550, height: 137, angle: -0.65, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [142, -885], width: 831, height: 120, angle: 0.54, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-103, -559], width: 352, height: 71, angle: -0.56, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-315, -748], width: 352, height: 71, angle: 0.53, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-411, -742], width: 109, height: 113, angle: 0.53, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [-264, 81], radius: 110, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [-404, 31], radius: 76, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [171, -138], width: 175, height: 71, angle: -0.14, traits: ColliderTraits.BlockMovementAndItem },
		],
		rigged: false,
	},
	base: {
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},
	ground: {
		zOffset: -8000,
		dontLoadAsset: true,
	},
	stairs: {
		zOffset: -7000,
		dontLoadAsset: true,
	},
	'bridge-fence-lower': {
		zOffset: 50,
		dontLoadAsset: true,
	},
	'bridge-fence-upper': {
		zOffset: -200,
		dontLoadAsset: true,
	},
	bridge: {
		zOffset: -5000,
		dontLoadAsset: true,
	},
	temple: {
		zOffset: -100,
		dontLoadAsset: true,
	},
	'wood-pillars-04': {
		zOffset: -1000,
		dontLoadAsset: true,
	},
	'wood-pillars-02': {
		zOffset: 500,
		dontLoadAsset: true,
	},
	'wood-pillars-03': {
		zOffset: 35,
		dontLoadAsset: true,
	},
	'Waterfall-animated': {
		overrideAtlas: 'end-island-props',
		zOffset: -6000,
		visibilityRadius: 3000,
	},
	'End-Island-Tree': {
		overrideAtlas: 'end-island-props',
		colliders: [{ type: ColliderType.Circle, position: [472, -431], radius: 56, traits: ColliderTraits.BlockMovementAndItem }],
		zOffset: -435,
	},
}
