











































import { mapActions, mapGetters, mapMutations } from 'vuex'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import DropDown from '../reusable-components/inputs/dropdown.vue'
import TextInput from '../reusable-components/inputs/text-input.vue'
export default {
	name: 'LeaderboardUpdated',
	components: {
		MainPanel,
		DropDown,
		TextInput,
	},
	data() {
		return {
			timeRangesPrettyName: [
				{ id: 'all-time', name: 'Top 50 All Time' },
				{ id: 'season', name: 'Top 50 Seasonally' },
				{ id: 'week', name: 'Top 50 Weekly' },
			],
			metricsPrettyName: [
				{ id: 'enemies-killed', name: 'Enemies Killed' },
				{ id: 'bosses-killed', name: 'Bosses Killed' },
				{ id: 'damage-dealt', name: 'Damage Dealt' },
				{ id: 'items-looted', name: 'Items Looted' },
				{ id: 'enchantments-equipped', name: 'Enchantments Equipped' },
				{ id: 'worm-items-deposited', name: 'Worm Items Deposited' },
				{ id: 'points-of-interest-defeated', name: 'Points of Interest Defeated' },
				{ id: 'max-critical-strike-hit', name: 'Max Critical Strike Damage' },
			],
			payload: [],
			filterTableByName: '',
		}
	},
	computed: {
		...mapGetters('leaderboardUpdated', ['top50RankingByMetric', 'getTimeRange', 'getMetric']),
		...mapGetters('user', ['username']),
	},
	created() {
		this.$store.dispatch({
			type: 'leaderboardUpdated/getTop50RankingByMetric',
			payload: { metric: 'enemies-killed', timeRange: 'all-time' },
		})
	},
	methods: {
		...mapActions('inGame', ['closeActivePanel']),
		...mapActions('leaderboardUpdated', ['getTop50RankingByMetric']),
		...mapMutations('leaderboardUpdated', ['updateTimeRange', 'updateMetric']),

		updateTimeRangeValue(event) {
			let selectedTimeRange = event
			if (selectedTimeRange === 'all-time') {
				this.updateTimeRange('all-time')
			} else if (selectedTimeRange === 'season') {
				this.updateTimeRange('season')
			} else if (selectedTimeRange === 'week') {
				this.updateTimeRange('week')
			} else {
				this.updateTimeRange('all-time')
			}
			this.$store.dispatch('leaderboardUpdated/getTop50RankingByMetric')
		},
		updateMetricValue(event) {
			let selectedMetric = event || 'enemies-killed'
			this.updateMetric(selectedMetric)
			this.$store.dispatch('leaderboardUpdated/getTop50RankingByMetric')
		},
		updatePlayerSearch(FilterText) {
			this.filterTableByName = FilterText.toLowerCase()
		},
		filterByName(itemArray, playerSearchFilter) {
			if (itemArray) {
				return itemArray.filter((player) => {
					return player.username.toLowerCase().includes(playerSearchFilter)
				})
			} else {
				return []
			}
		},
		getPlayerRank(playerName) {
			const rank = this.top50RankingByMetric.findIndex((r) => r.username === playerName) + 1
			return rank
		},
	},
}
