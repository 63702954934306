







































import { mapMutations, mapGetters, mapActions } from 'vuex'
import { getEmoteIconStyle } from '../state/emotes-ui-state'
import TextButton from '../reusable-components/buttons/text-button.vue'

export default {
	name: 'EmoteWheel',
	components: {
		TextButton,
	},
	data() {
        return {
            mouseX: 0,
            mouseY: 0,
            mouseDistanceSquared: 0,
            mouseAngle: 0
        }
	},
	computed: {
		...mapGetters('emotes', ['wheelActive', 'wheelXPos', 'wheelYPos', 'equipedEmotes']),
		...mapGetters('inGame', ['currentGameMode']),
	},
    created: function () {
        window.addEventListener('mousemove', this.updateMousePos)
    },
    destroyed: function () {
        window.removeEventListener('mousemove', this.updateMousePos)
    },
	methods:{
        ...mapMutations('emotes', ['setSelectedWheelEmote']),
        ...mapActions('emotes', ['openCustomizationPanel']),
		updateMousePos(event) {
            if (this.wheelActive) {
                this.mouseX = event.clientX
                this.mouseY = event.clientY
                this.mouseDistanceSquared = (this.wheelXPos - this.mouseX) * (this.wheelXPos - this.mouseX) + (this.wheelYPos - this.mouseY) * (this.wheelYPos - this.mouseY)
                this.mouseAngle = Math.atan2(this.wheelYPos - this.mouseY, -this.wheelXPos + this.mouseX) * 180 / Math.PI
                if (this.mouseAngle < 0) {
                    this.mouseAngle += 360
                }
            } else {
                this.mouseDistanceSquared = 0
            }
        },
        isHovered(index, minAngle, maxAngle) {
            // 3600 = Radius of the wheel's "inner circle" squared
            if (this.mouseDistanceSquared < 3600) {
                this.setSelectedWheelEmote(-1)
                return false
            }

            if (this.mouseAngle < minAngle || this.mouseAngle > maxAngle) {
                return false
            }

            this.setSelectedWheelEmote(index)

            return true
        },
		emoteIconStyle(index) {
			return getEmoteIconStyle(this.equipedEmotes[index], 85, 102)
		},
	},
}
