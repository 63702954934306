export enum GeneralStoreItemsSubType {
	Health = 5001,
	HunterHunted = 5002,
	GoFast = 5003,
	PoisonApple = 5004,
	PhysicalArmor = 5005,
	FireArmor = 5006,
	IceArmor = 5007,
	PoisonArmor = 5008,
	LightningArmor = 5009,
	EnchantmentPotion = 5010
}
