import nengi from 'nengi'
import { uuid } from '../../utils/primitive-types'

class DetachAugmentFromWeaponCommand {
	static protocol = {
		augmentId: nengi.String,
		weaponId: nengi.String,
	}
	augmentId: uuid
	weaponId: uuid
	/**
	 * Creates an instance of DetachWeaponFromAugment.
	 * @param {uuid} augmentId Augment ID
	 * @param {uuid} weaponId Weapon ID
	 * @memberof DetachWeaponFromAugment
	 */
	constructor(augmentId: uuid, weaponId: uuid) {
		this.augmentId = augmentId
		this.weaponId = weaponId
	}
}

export default DetachAugmentFromWeaponCommand
