<template>
	<div id="worm-delivery-border-image">
		<div id="worm-delivery-notifier" class="notification-body" @click="setActivePanel('wormDelivery')">
			<h3 id="notif-hud-text">Items Delivered! &emsp; &emsp; {{ numberOfItemsInContainer('wormDelivery') }} Items</h3>
		</div>
	</div>
</template>

<script lang="js">
import { mapGetters, mapMutations } from 'vuex'
export default {
	name: 'WormDeliveryNotifier',
	computed: {
		...mapGetters('itemContainers', ['numberOfItemsInContainer']),
		...mapGetters('UIScale', ['currentScale']),
	},
	methods:{
		...mapMutations('inGame', ['setActivePanel']),
	}
}
</script>

<style lang="less" scoped>
@import url('../global/fonts/text.less');

#worm-delivery-notifier {
	display: flex;
	pointer-events: all;
	text-align: right;
	cursor: pointer;

	h3 {
		color: rgb(233, 229, 173);
		margin: 0;
		padding: 0;
	}

	.notif-hud-text();
}

#worm-delivery-border-image {
	height: 65px;
	justify-content: center;
	align-items: center;
	perspective: 1px;

	border-image-slice: 4 38 9 6 fill;
	border-image-width: 4px 38px 9px 6px;
	border-image-outset: 0px 0px 0px 0px;
	border-image-repeat: repeat stretch;
	border-image-source: url('../hud/assets/toast-generic.png');
}
</style>
