import nengi from 'nengi'
import { jsonString } from '../../utils/primitive-types'

class FurnaceStateMessage {
	static protocol = {
		state: { type: nengi.String },
		unixTime: { type: nengi.Number },
	}

	state: jsonString
	unixTime: number

	constructor(furnaceState, unixTime) {
		this.state = JSON.stringify(furnaceState)
		this.unixTime = unixTime
	}
}

export default FurnaceStateMessage
