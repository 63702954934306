import { get, set } from 'lodash'
import { NengiClient } from '../../../engine/client/nengi-client'
import { SetServerValueCommand } from '../../../engine/shared/set-server-value-command'
import DevToolsManager from '../../../ui/dev-tools/dev-tools-manager'
import clientLogger from '../../../utils/client-logger'

export function logToConsole() {
	console.log({
		key: this.propertyKey,
		value: this.propertyValue,
		rootObject: this.rootObject,
		propertySeekDotNotation: this.propertySeekDotNotation,
	})
}

export function getComponentByPropType(prop) {
	if (typeof prop === 'string') {
		return 'StringProperty'
	}
	if (typeof prop === 'boolean') {
		return 'BooleanProperty'
	}
	if (typeof prop === 'undefined') {
		return 'UndefinedOrNullProperty'
	}
	if (typeof prop === 'number') {
		if (prop % 1 > 0) {
			return 'FloatProperty'
		} else {
			return 'NumberProperty'
		}
	}
	if (typeof prop === 'object') {
		if (Array.isArray(prop)) {
			return 'ArrayProperty'
		} else {
			return 'RecursiveObjectTree'
		}
	}
	if (typeof prop === 'function') {
		return 'FunctionProperty'
	}
}

export function onPropertyChangedHandler(rootObject: object, setterDotNotation: string, newValue: any) {
	setterDotNotation = setterDotNotation.slice(1) // remove '.' prefix: '.obj.timeScale' -> 'obj.timeScale'
	const value = get(rootObject, setterDotNotation)
	set(rootObject, setterDotNotation, newValue)
	clientLogger.debug(`Changed value [${setterDotNotation}] from: ${value} to ${newValue}`) //, rootObject)

	setterDotNotation = setterDotNotation // remove 'obj' prefix: 'obj.timeScale' -> 'timeScale'
		.split('.')
		.slice(1)
		.join('.')
	const objName = DevToolsManager.getInstance().serverDebugObjectName
	if (objName) {
		NengiClient.getInstance().sendCommand(new SetServerValueCommand(objName, setterDotNotation, newValue, typeof newValue))
	}
}
