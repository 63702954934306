

























import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import PanelDivider from '../../reusable-components/panel-divider/panel-divider.vue'
import MainPanel from '../../reusable-components/panels/main-panel.vue'
import PlainTextButton from '../../reusable-components/buttons/plain-text-button.vue'
import { PanelTabs } from '../../state/departure.ui-state'

import Overview from './overview.vue'
import Difficulty from './difficulty.vue'
import Factions from './factions.vue'

export default {
	name: 'DepartureUpdate',
	components: {
		MainPanel,
		PlainTextButton,
		PanelDivider,
		Overview,
		Difficulty,
		Factions,
		
	},
	data() {
		return {
			PanelTabs,
		}
	},
	props: {},
	computed: {
		...mapState('factions', ['affiliatedFaction']),
		...mapState('departure', ['departureTab']),
	},
	methods: {
		...mapActions('inGame', ['closeActivePanel', 'openFlyoutPanel', 'closeFlyoutPanel']),
		...mapMutations('departure', ['updatedDepartureTab']),

		changeTab(tab) {
			this.updatedDepartureTab(tab)
		},
	},
}
