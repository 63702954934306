














































import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import PanelDivider from '../reusable-components/panel-divider/panel-divider.vue'
import GridItemContainer from '../reusable-components/item-containers/grid-item-container.vue'
import TextButton from '../reusable-components/buttons/text-button.vue'

export default {
	name: 'DailyRewards',
	components: {
		MainPanel,
		PanelDivider,
		GridItemContainer,
		TextButton,
	},
	props: {},
	computed: {
		...mapState('dailyRewards', ['dailyLoginsClaimed', 'errorClaiming', 'isClaiming']),
		...mapGetters('dailyRewards', ['getClaimedToday', 'getTodayRewards', 'getTomorrowRewards', 'getNextMilestoneRewards', 'getNumDaysToNextMilestone', 'todayIsMilestone', 'tomorrowIsMilestone']),
	},
	methods: {
		...mapActions('inGame', ['closeActivePanel']),
		...mapMutations('dailyRewards', ['claimDailyReward']),
	},
}
