import nengi from 'nengi'
import { StatType } from '../../engine/shared/game-data/stat-type-mod-category'

export default class PlayerStatChangeMessage {
	static protocol = {
		statType: { type: nengi.UInt16 },
		value: { type: nengi.String },
	}

	statType: StatType
	value: string

	constructor(param: PlayerStatChangeMessage) {
		this.statType = param.statType
		this.value = param.value
	}
}
