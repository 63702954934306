import nengi from 'nengi'
import { GenericEntityConfig } from './generic-entity-config'

class GenericEntity {
	static protocol = {
		name: { type: nengi.String },
		x: { type: nengi.Float32 },
		y: { type: nengi.Float32 },
		scale: { type: nengi.Float32 },
		spriteIndex: { type: nengi.String },
		lifetime: { type: nengi.Number }
	}
	nid: number
	name: string
	x: number = 0
	y: number = 0
	scale: number = 1
	lifetime: number = 0
	config: GenericEntityConfig
}

export default GenericEntity
