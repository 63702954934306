import { Vector } from 'sat'
import { gameUnits } from '../../utils/primitive-types'
import { GameModeType } from './game-mode-type'

export interface ServerGameModeConfig {
	type: GameModeType
	serverPort: number
	fileChangePort: number
	worldWidth: gameUnits
	minWorldWidth: gameUnits
	worldHeight: gameUnits
	chunkSize: gameUnits
	playerSpawnPosition: Vector
	playerRandomSpawnRadius: number
	safeZoneDeterminesWeaponVisibiltiy: boolean
	clientSideUpdateGroundPos: boolean
}

export const GameModeConfigs: Map<GameModeType, ServerGameModeConfig> = new Map()

GameModeConfigs.set(GameModeType.Hub, {
	type: GameModeType.Hub,
	serverPort: 8000,
	fileChangePort: 7777,
	worldWidth: 10240,
	minWorldWidth: 0,
	worldHeight: 10240,
	chunkSize: 512,
	playerSpawnPosition: new Vector(5120 - 2700, 5120 + 240),
	playerRandomSpawnRadius: 150,
	safeZoneDeterminesWeaponVisibiltiy: true,
	clientSideUpdateGroundPos: true
})

GameModeConfigs.set(GameModeType.Adventure, {
	type: GameModeType.Adventure,
	serverPort: 8001,
	fileChangePort: 7778,
	minWorldWidth: 1000,
	worldWidth: 91136, // 81920 + 512*6 + 6144 (end island)
	worldHeight: 12288, // 10240 + 512*4
	chunkSize: 512,
	// Biome spawns x values 1000, 18944, 44544, 58880, 75264
	playerSpawnPosition: new Vector(500, (10240 + 4 * 512) / 2 + 100),
	playerRandomSpawnRadius: 100,
	safeZoneDeterminesWeaponVisibiltiy: true,
	clientSideUpdateGroundPos: false
})

GameModeConfigs.set(GameModeType.TestRealm, {
	type: GameModeType.TestRealm,
	serverPort: 8002,
	fileChangePort: 7779,
	worldWidth: 10240,
	minWorldWidth: 0,
	worldHeight: 10240,
	chunkSize: 512,
	playerSpawnPosition: new Vector(10240/2, 10240/2),
	playerRandomSpawnRadius: 0,
	safeZoneDeterminesWeaponVisibiltiy: true,
	clientSideUpdateGroundPos: false
})

export let gameModeConfig: ServerGameModeConfig = null

export function setGameModeConfig(serverType: GameModeType) {
	gameModeConfig = GameModeConfigs.get(serverType)
}
