import nengi from 'nengi'
import { DrawShapeBase } from './draw-shape-base'

class DrawText extends DrawShapeBase {
	static protocol = {
		...DrawShapeBase.protocol,
		text: nengi.String,
		x: nengi.Number,
		y: nengi.Number,
	}

	text: string
	x: number
	y: number

	constructor(text: string, x: number, y: number, color: number, permanent: boolean, destroyAfterSeconds: number, scale: number) {
		super(color, permanent, destroyAfterSeconds, scale)
		this.text = text || ''
		this.x = x
		this.y = y
	}
}

export default DrawText
