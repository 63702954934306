


















import { autoDetectRenderer, Container, Renderer as PIXIRenderer, Sprite } from 'pixi.js'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import EmptyAugmentSlot from '../inventory-item/empty-augment-slot.vue'
import InventoryItem from '../inventory-item/inventory-item.vue'
import { RiggedSpineModel } from '../../../models-animations/client/spine-model'
import WeaponSubType, { WeaponSubTypeAssetName } from '../../../loot/shared/weapon-sub-type'
import { AnimationTrack } from '../../../models-animations/shared/animation-track'
import playAnimation from '../../../models-animations/client/play-animation'
import { getSpineAsset } from '../../../utils/pixi-util'
import { AssetManager } from '../../../asset-manager/client/asset-manager'
import { getDataStringFromVisualComponent } from '../../../engine/shared/game-data/stat-type-mod-category'
import clientLogger from '../../../utils/client-logger'
import { degToRad } from '../../../utils/math'
import { debugConfig } from '../../../engine/client/debug-config'
import { WaitCondition } from '../../../ui/state/WaitCondition'

import { LayerRenderer } from '../../../world/client/layer-renderer'
import { Effect } from '../../../engine/client/graphics/pfx/effect'
import { EffectConfig } from '../../../engine/client/graphics/pfx/effectConfig'
import { RenderQueue } from '../../../engine/client/graphics/render-queue'
import { InstancedSpriteBatcher } from '../../../engine/client/graphics/pfx/instanced-sprite-batcher'
import { IParticleRendererCamera } from '../../../engine/client/graphics/pfx/sprite-particle-renderer'
import ItemType, { ItemSubType } from '../../../loot/shared/item-type'
import GearSubType, { GearSubTypePrettyName } from '../../../loot/shared/gear-sub-type'
import { WeaponAugmentSubType } from '../../../loot/shared/weapon-augment-sub-type'
import { timeInSeconds, uuid } from '../../../utils/primitive-types'
import { KawaseBlurFilter } from '@pixi/filter-kawase-blur'
import Time from '../../../engine/shared/time'

const weaponCardOffsetsByRarity = {
	Common: { x: 225, y: 455 },
	Uncommon: { x: 230, y: 455 },
	Rare: { x: 225, y: 455 },
	Epic: { x: 225, y: 455 },
	Legendary: { x: 230, y: 455 },
	Astronomical: { x: 230, y: 465 },
}

const gearCardOffsetsByRarity = {
	Common: { x: 225, y: 455 },
	Uncommon: { x: 230, y: 455 },
	Rare: { x: 225, y: 455 },
	Epic: { x: 225, y: 455 },
	Legendary: { x: 230, y: 480 },
	Astronomical: { x: 230, y: 470 },
}

export default {
	name: 'FancyWeaponCard',
	components: {
		EmptyAugmentSlot,
		InventoryItem,
	},
	props: {
		item: {
			type: Object,
			required: true,
			validator(item) {
				return item.id !== undefined
			},
		},
		removableAugments: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data() {
		return {
			id: '',
		}
	},
	computed: {
		...mapGetters('itemContainers', ['itemDetails']),
		...mapGetters('identify', ['isIdentifying']),
	},
	created() {
		this.id = this._uid
	},
	beforeUpdate() {
		const weaponCard = this.weaponCard as WeaponCard
		if (this.weaponCard && this.item.id !== weaponCard.itemId) {
			window.clearInterval(this.intervalID)
			this.weaponCard.destroy()
			this.weaponCard = null

			this.renderWeapon()
		}
	},
	mounted() {
		this.renderWeapon()
		this.postWaitForCondition(WaitCondition.PLAYER_INSPECTING_WEAPON)
	},
	beforeDestroy() {
		window.clearInterval(this.intervalID)
		this.weaponCard.destroy()
	},
	methods: {
		...mapActions('augmentationStation', ['removeAugmentFromWeapon']),
		...mapMutations('UIScale', ['showTooltip']),
		...mapMutations('tutTooltip', ['postWaitForCondition']),

		removeAugment(augmentId: string) {
			if (this.removableAugments === false) return
			console.log(`Removing augment ${augmentId} from weapon ${this.item.id}`)
			this.showTooltip(null)
			this.removeAugmentFromWeapon({ augmentId, weaponId: this.item.id })
		},

		renderWeapon() {
			// currently we're loading weapons on demand (but not other items like gear/augments)
			const spineAssetName = WeaponSubTypeAssetName.get(this.item.itemSubType)
			if (spineAssetName) {
				AssetManager.getInstance().getAssetByNameAsync(spineAssetName, (_asset) => {
					this.renderWeaponAssetLoaded()
				})
			} else {
				this.renderWeaponAssetLoaded()
			}
		},

		renderWeaponAssetLoaded() {
			let cardType
			if (this.item.itemTypeEnum === ItemType.Weapon) {
				cardType = getWeaponCardFromRarity(this.item.rarity)
			} else {
				cardType = getGearCardFromRarity(this.item.rarity)
			}

			this.weaponCard = new WeaponCard('ui-canvas' + this.id, cardType, this.item.itemType, this.item.itemSubType, this.item.id, [this.item.component1, this.item.component2, this.item.component3], this.item.unidentified, this.item.rarity)
			this.weaponCard.isIdentifying = () => this.isIdentifying(this.item.id)

			let lastTick = Time.timestampOfCurrentFrameStartInMs

			this.intervalID = window.setInterval(() => {
				const currentTick = Time.timestampOfCurrentFrameStartInMs
				const delta = ((currentTick - lastTick) / 1000) * debugConfig.timeScale
				lastTick = currentTick
				if (this.weaponCard) {
					this.weaponCard.update(delta)
				}
			}, 60)
		},

		augmentSlotPositionStyle(currentIndex: number, maxIndex: number, empty: boolean) {
			let x = 0
			let y = 0

			if (maxIndex === 1) {
				// nothing!
			} else if (maxIndex === 2) {
				switch (currentIndex) {
					case 1:
						x = -45
						break
					case 2:
						x = 45
						break
				}
			} else if (maxIndex === 3) {
				switch (currentIndex) {
					case 1:
						x = -90
						break
					case 2:
						break
					case 3:
						x = 90
						break
				}
			} else if (maxIndex === 4) {
				switch (currentIndex) {
					case 1:
						x = -115
						y = -10
						break
					case 2:
						x = -45
						y = 35
						break
					case 3:
						x = 45
						y = 35
						break
					case 4:
						x = 115
						y = -10
						break
				}
			} else if (maxIndex === 5) {
				switch (currentIndex) {
					case 1:
						x = -90
						y = -15
						break
					case 2:
						x = -45
						y = 45
						break
					case 3:
						y = -15
						break
					case 4:
						x = 45
						y = 45
						break
					case 5:
						x = 90
						y = -15
						break
				}
			} else if (maxIndex === 6) {
				switch (currentIndex) {
					case 1:
						x = -130
						y = -15
						break
					case 2:
						x = -95
						y = 50
						break
					case 3:
						x = -45
						y = -5
						break
					case 4:
						x = 45
						y = -5
						break
					case 5:
						x = 95
						y = 50
						break
					case 6:
						x = 130
						y = -15
						break
				}
			}

			// some adjustments
			if (!empty) {
				x -= 24
				y -= 24
			}

			x -= 20
			y += 5

			return { position: 'absolute', top: y + 'px', left: x + 'px' }
		},
	},
}

function itemUnidentifiedFilter() {
	return new KawaseBlurFilter()
}

function getWeaponCardFromRarity(rarity: string) {
	switch (rarity) {
		default:
			console.error(`Unrecognized item rarity (${rarity}) in fancy-weapon-card.vue`)
		case 'Common':
			return 'weapon-card-common'
		case 'Uncommon':
			return 'weapon-card-uncommon'
		case 'Rare':
			return 'weapon-card-rare'
		case 'Epic':
			return 'weapon-card-epic'
		case 'Legendary':
			return 'weapon-card-legendary'
		case 'Astronomical':
			return 'weapon-card-astronomical'
	}
}

function getGearCardFromRarity(rarity: string) {
	switch (rarity) {
		default:
			console.error(`Unrecognized item rarity (${rarity}) in fancy-weapon-card.vue`)
		case 'Common':
			return 'gear-card-common'
		case 'Uncommon':
			return 'gear-card-uncommon'
		case 'Rare':
			return 'gear-card-rare'
		case 'Epic':
			return 'gear-card-epic'
		case 'Legendary':
			return 'gear-card-legendary'
		case 'Astronomical':
			return 'gear-card-astronomical'
	}
}

class WeaponCard {
	private pixiRenderer: PIXIRenderer
	private pfxRenderer: LayerRenderer
	private stage: Container
	private card: RiggedSpineModel
	private unveil: RiggedSpineModel
	private instancedSpriteBatcher: InstancedSpriteBatcher
	private renderQueue: RenderQueue
	private cameraState: IParticleRendererCamera
	private playedUnveilOutroVisuals: boolean
	private itemModel: PIXI.Container

	itemId: uuid
	isIdentifying = () => false

	constructor(canvasId: string, cardType: string, itemType, subType: ItemSubType, itemId: uuid, components: number[], unidentified: boolean, rarity: string) {
		this.itemId = itemId

		const canvas: HTMLCanvasElement = document.getElementById(canvasId) as HTMLCanvasElement

		this.pixiRenderer = autoDetectRenderer({
			width: 450,
			height: 510,
			view: canvas,
			antialias: false,
			transparent: true,
			resolution: 1,
		})

		this.stage = new Container()
		this.stage.name = 'Weapon Card'

		const asset = getSpineAsset(cardType)
		const spineData = asset.spineData as PIXI.spine.core.SkeletonData
		this.card = new RiggedSpineModel(spineData)
		this.card.skeleton.setSkinByName('default')
		this.card.skeleton.setToSetupPose()
		this.card.name = cardType

		let offSet
		if (itemType === 'Weapon') {
			offSet = weaponCardOffsetsByRarity[rarity]
		} else {
			offSet = gearCardOffsetsByRarity[rarity]
		}
		this.card.position.set(offSet.x, offSet.y)

		this.card.visible = true
		playAnimation(this.card, AnimationTrack.IDLE)
		this.stage.addChild(this.card)
		this.stage.position.set(0, 0)

		if (itemType === 'Weapon') {
			this.itemModel = this.makeWeaponVisuals(subType as WeaponSubType, components)
		} else if (itemType === 'Gear') {
			this.itemModel = this.makeGearVisuals(subType as GearSubType)
		} else {
			this.itemModel = this.makeAugmentVisuals(subType as WeaponAugmentSubType, cardType.replace('gear-card-', ''))
		}

		if (unidentified) {
			this.itemModel.filters = [itemUnidentifiedFilter()]
		}

		const MAX_TEXTURES = this.pixiRenderer.plugins.batch.MAX_TEXTURES
		this.instancedSpriteBatcher = new InstancedSpriteBatcher(this.pixiRenderer, MAX_TEXTURES)
		this.renderQueue = new RenderQueue(this.pixiRenderer, this.instancedSpriteBatcher)

		this.cameraState = {
			x: 0,
			y: 0,
			zoom: 1.0,
			halfWidth: 201,
			halfHeight: 231,
		}

		this.pfxRenderer = new LayerRenderer(this.renderQueue, this.cameraState)
		this.stage.addChild(this.pfxRenderer)
		this.pfxRenderer.position.set(0, 0)

		switch (cardType) {
			case 'weapon-card-common':
				// TODO2: add pfx for common cards whenever they're made
				break
			case 'weapon-card-uncommon':
				// TODO2: add pfx for uncommon cards whenever they're made
				break
			case 'weapon-card-rare':
				// TODO2: add pfx for rare cards whenever they're made
				break
			case 'weapon-card-epic':
				this.addEffectToScene('card-pfx-epic', 225, 280)
				break
			case 'weapon-card-legendary':
				this.addEffectToScene('card-pfx-legendary', 225, 280)
				break
			case 'weapon-card-astronomical':
				this.addEffectToScene('card-pfx-astronomical', 225, 280)
				break
			default:
				break
		}
	}

	unveilIntroVisuals() {
		this.unveil?.destroy({ children: true })

		const asset = getSpineAsset('card-unveil')
		const spineData = asset.spineData as PIXI.spine.core.SkeletonData
		this.unveil = new RiggedSpineModel(spineData)
		this.unveil.name = 'unveil'
		this.unveil.visible = true
		this.unveil.skeleton.setSkinByName('default')
		this.unveil.skeleton.setToSetupPose()
		playAnimation(this.unveil, AnimationTrack.INTRO)
		this.stage.addChild(this.unveil)
		this.unveil.position.set(weaponCardOffsetsByRarity.Common.x, weaponCardOffsetsByRarity.Common.y)
	}

	unveilOutroVisuals() {
		playAnimation(this.unveil, AnimationTrack.OUTRO)
		this.addOneOffEffect('cardeffect-unveiling', 225, 350)
		this.itemModel.filters = []
	}

	addEffectToScene(particleEffect: string, x: number, y: number, z?: number, scale = 1) {
		const pfxAsset = AssetManager.getInstance().getAssetByName(particleEffect).data as EffectConfig
		const pfx = new Effect(pfxAsset, this.cameraState)
		pfx.x = x
		pfx.y = y
		pfx.zIndex = z ?? y
		pfx.scale = scale
		this.pfxRenderer.addEffectToScene(pfx)
		return pfx
	}

	addOneOffEffect(particleEffect: string, x: number, y: number, z?: number, scale = 1, duration = 1, prewarm = false) {
		const pfxAsset = AssetManager.getInstance().getAssetByName(particleEffect).data as EffectConfig
		const pfx = new Effect(pfxAsset, this.cameraState)
		pfx.x = x
		pfx.y = y
		pfx.zIndex = z ? z : y + 1
		pfx.scale = scale
		this.pfxRenderer.addOneOffEffectToScene(pfx, duration, prewarm)
		return pfx
	}

	destroy() {
		this.card.destroy({ children: true })
		this.unveil?.destroy({ children: true })
		this.stage.destroy({ children: true })
		this.pixiRenderer.destroy()
	}

	update(delta: timeInSeconds): void {
		if (this.card && this.stage && this.pixiRenderer && this.pfxRenderer) {
			this.pfxRenderer.update(delta)
			this.card.update(delta)
			this.updateUnVeil(delta)
			this.pixiRenderer.render(this.stage)
		}
	}

	updateUnVeil(delta: timeInSeconds) {
		const isIdentifying = this.isIdentifying()

		if (!this.unveil && isIdentifying) {
			this.unveilIntroVisuals()
		}
		if (this.unveil && !this.playedUnveilOutroVisuals && !isIdentifying) {
			this.playedUnveilOutroVisuals = true
			this.unveilOutroVisuals()
		}
		this.unveil?.update(delta)
	}

	makeWeaponVisuals(subType: WeaponSubType, components: number[]) {
		const spineAssetName = WeaponSubTypeAssetName.get(subType)
		const weaponAsset = AssetManager.getInstance().getAssetByName(spineAssetName).spineData
		const weapon = new RiggedSpineModel(weaponAsset)

		const handle = getDataStringFromVisualComponent(components[0])
		console.assert(handle, `no handle of type:${components[0]}`)
		const ornament = getDataStringFromVisualComponent(components[1])
		console.assert(ornament, `no ornament of type:${components[1]}`)
		const head = getDataStringFromVisualComponent(components[2])
		console.assert(head, `no head of type:${components[2]}`)

		weapon.name = 'weapon'

		const skin = new PIXI.spine.core.Skin('myWeapon')
		const handleSkin = weapon.skeleton.data.findSkin(handle)
		const ornamentSkin = weapon.skeleton.data.findSkin(ornament)
		const headSkin = weapon.skeleton.data.findSkin(head)
		if (handleSkin) {
			skin.addSkin(handleSkin)
		} else {
			clientLogger.error(`Couldn't find handle skin ${handle}! Something probably wasn't hooked up right!`)
		}
		if (ornamentSkin) {
			skin.addSkin(ornamentSkin)
		} else {
			clientLogger.error(`Couldn't find ornament skin ${ornament}! Something probably wasn't hooked up right!`)
		}
		if (headSkin) {
			skin.addSkin(headSkin)
		} else {
			clientLogger.error(`Couldn't find head skin ${head}! Something probably wasn't hooked up right!`)
		}

		weapon.skeleton.setSkin(skin)
		weapon.visible = true
		this.card.attachSpineSprite('weapon_placeholder', weapon)
		// TODO2 - This is pretty hacky but I am unsure how to do it better at this time
		switch (subType) {
			case WeaponSubType.ArcaneFocus:
				weapon.rotation = degToRad(180)
				weapon.position.x = 5
				weapon.scale.set(1.3)
				break
			case WeaponSubType.Spellsword:
				weapon.rotation = degToRad(90)
				weapon.position.x = 10
				weapon.scale.set(1.3)
				break
			case WeaponSubType.Scythe:
				weapon.rotation = degToRad(135)
				weapon.position.x = 100
				weapon.position.y = 40
				weapon.scale.set(1.3)
				break
			case WeaponSubType.Staff:
				weapon.rotation = degToRad(90)
				weapon.position.x = 10
				weapon.scale.set(1.3)
				break
			case WeaponSubType.Wand:
				weapon.rotation = degToRad(90)
				weapon.position.x = -25
				weapon.position.y = 40
				weapon.scale.set(1.3)
				break
			case WeaponSubType.Crossbow:
				weapon.rotation = degToRad(135)
				weapon.position.x = 55
				weapon.position.y = 40
				weapon.scale.set(1.15)
				break
		}
		weapon.update(0)

		return weapon
	}

	makeGearVisuals(subType: GearSubType) {
		const name = 'gear-' + GearSubTypePrettyName.get(subType)
		const gearSprite = Sprite.from(name)

		gearSprite.anchor.x = 0.5
		gearSprite.anchor.y = 0.5

		gearSprite.scale.x = 2.5
		gearSprite.scale.y = -2.5

		gearSprite.position.y = 120
		gearSprite.zIndex = Number.MAX_SAFE_INTEGER

		gearSprite.visible = true

		const attachIndex = this.card.skeleton.slots.findIndex((s) => s.data.name === 'weapon_placeholder')
		const slot = this.card.skeleton.slots[attachIndex]
		const slotContainer = this.card.slotContainers[attachIndex]
		slot.spineSprite = gearSprite
		slotContainer.visible = true
		slotContainer.addChild(gearSprite)

		gearSprite.updateTransform()

		return gearSprite
	}

	makeAugmentVisuals(subType: WeaponAugmentSubType, rarity) {
		const mod = this.getModTypeFromSubType(subType)
		const container = new Container()
		container.name = 'augment'
		const bgname = `augment-bg-${rarity}`
		const iconname = `mod-icon-${mod}-${rarity}`
		const bgSprite = Sprite.from(bgname)
		const iconSprite = Sprite.from(iconname)
		container.addChild(bgSprite)
		container.addChild(iconSprite)

		bgSprite.anchor.x = iconSprite.anchor.x = 0.5
		bgSprite.anchor.y = iconSprite.anchor.y = 0.5

		bgSprite.scale.x = iconSprite.scale.x = 3.5
		bgSprite.scale.y = iconSprite.scale.y = -3.5

		iconSprite.position.x = -25
		iconSprite.position.y = 130

		bgSprite.position.y = 120

		const attachIndex = this.card.skeleton.slots.findIndex((s) => s.data.name === 'weapon_placeholder')
		const slot = this.card.skeleton.slots[attachIndex]
		const slotContainer = this.card.slotContainers[attachIndex]
		slot.spineSprite = container
		slotContainer.addChild(container)

		return container
	}
	getModTypeFromSubType(subType: WeaponAugmentSubType) {
		switch (subType) {
			case WeaponAugmentSubType.CreepingDeath:
				return 'creepingdeath'

			case WeaponAugmentSubType.NarrowSpreadExtraProjectiles:
				return 'narrowspreadextraprojectiles'

			case WeaponAugmentSubType.Pierce:
				return 'pierce'

			case WeaponAugmentSubType.TightenSpread:
				return 'tightenspread'

			case WeaponAugmentSubType.Sine:
				return 'sine'

			case WeaponAugmentSubType.Zigzag:
				return 'trajectory'

			case WeaponAugmentSubType.FastAndWeak:
				return 'fastandweak'

			case WeaponAugmentSubType.SlowAndStrong:
				return 'slowandstrong'

			case WeaponAugmentSubType.SplashDamage:
				return 'splashdamage'

			case WeaponAugmentSubType.LightningBurst:
				return 'lightningburst'

			case WeaponAugmentSubType.CritSwitch:
				return 'critswitch'

			case WeaponAugmentSubType.BasicStatUp:
				return 'extra-damage'

			case WeaponAugmentSubType.ClusterShot:
				return 'projectiles'

			case WeaponAugmentSubType.Sniper:
				return 'aim'

			case WeaponAugmentSubType.Flamethrower:
				return 'fire'

			case WeaponAugmentSubType.Icebound:
				return 'ice'

			case WeaponAugmentSubType.Taser:
				return 'lightning'

			case WeaponAugmentSubType.StraightBoomerang:
				return 'straightboomerang'

			case WeaponAugmentSubType.Debilitator:
				return 'poison'

			case WeaponAugmentSubType.DiffusionBeam:
				return 'diffusionbeam'

			case WeaponAugmentSubType.FocusedBeam:
				return 'beam'

			case WeaponAugmentSubType.StarLaser:
				return 'starlaser'

			case WeaponAugmentSubType.QuickCharge:
				return 'quickcharge'

			case WeaponAugmentSubType.PhysicalUp:
				return 'physicalup'

			case WeaponAugmentSubType.FireUp:
				return 'fireup'

			case WeaponAugmentSubType.IceUp:
				return 'iceup'

			case WeaponAugmentSubType.LightningUp:
				return 'lightningup'

			case WeaponAugmentSubType.PoisonUp:
				return 'poisonup'

			case WeaponAugmentSubType.FieryOvercharge:
				return 'fire'

			case WeaponAugmentSubType.WideSpreadExtraProjectiles:
				return 'widespreadextraprojectiles'

			// case WeaponAugmentSubType.ChainLightning:
			// 	return 'lightning'

			// case WeaponAugmentSubType.PhysicalConversion:
			// 	return 'physicalconversion'

			// case WeaponAugmentSubType.FireConversion:
			// 	return 'fireconversion'

			// case WeaponAugmentSubType.IceConversion:
			// 	return 'iceconversion'

			// case WeaponAugmentSubType.LightningConversion:
			// 	return 'lightningconversion'

			// case WeaponAugmentSubType.PoisonConversion:
			// 	return 'poisonconversion'

			case WeaponAugmentSubType.Accelerate:
				return 'speed'

			case WeaponAugmentSubType.Deccelerate:
				return 'deccelerate'

			case WeaponAugmentSubType.RangeAdjustingAccelerate:
				return 'rangeadjustingaccelerate'

			case WeaponAugmentSubType.ElementalDevotion:
				return 'elemental-devotion'

			case WeaponAugmentSubType.EnergyBooster:
				return 'energetic-reinforcement'
		}
	}
}
