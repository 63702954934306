import { level, percentage, timeInSeconds, WorldTier } from '../../../utils/primitive-types'
import { ColliderTraits, CircleColliderConfig, isSet, ColliderType } from '../../../collision/shared/colliders'
import { PlayerSkinType } from '../../../models-animations/shared/spine-config'
import { Vector } from 'sat'
import { PersistentBuffSubType } from '../../../loot/shared/persistent-buff-sub-type'

export const PLAYER_DEFAULT_MOVEMENT_SPEED = 400
export const PLAYER_MOVEMENT_SPEED_MULTIPLIER: percentage = 1.0
export const PLAYER_NETWORK_CULLED_VIEW_WIDTH = 2200
export const PLAYER_NETWORK_CULLED_VIEW_HEIGHT = 1400
export const PLAYER_PROJECTILE_DEFAULT_RADIUS = 20
export const PLAYER_PROJECTILE_AND_BEAM_MAX_RANGE = 1200
export const PLAYER_MAX_BEAM_WIDTH = 150
export const PLAYER_POI_PARTICIPATION_DISTANCE = 3000
export const PLAYER_EQUIP_LEVEL_THRESHOLD = 9
export const PLAYER_DEFAULT_ZOOM_LEVEL = 0.7

// allow slight over-aim before flipping
export const PLAYER_OVER_AIM_DEGREES = 40
export const ENABLE_PVP = false

const MODEL_SCALE_Y = 0.25

//                               Arms + Weapon, height
const DWARF_WEAPON_OFFSET = new Vector(263.206 + 308.28, 0)
const RANGER_WEAPON_OFFSET = new Vector(263.206 + 308.28, -42.7 * MODEL_SCALE_Y) // 42.7 => height-control-hips  constraint offset

const DWARF_AIM_OFFSET = 79.465
const RANGER_AIM_OFFSET = DWARF_AIM_OFFSET // + (42.7 * MODEL_SCALE_Y) // (height-control-hips) // <- above is just in the weapon offset

export const PLAYER_ENERGY_REGEN_DELAY_SECONDS: timeInSeconds = 2.0

export const PLAYER_WAVE_TRAVEL_TIME_DEFAULT_SPEED = 500 // Used for travelTimeElapsedForWaveFunctions, completely arbitrary


export const PLAYER_MOVEMENT_COLLIDER_INDEX = 1
export const PLAYER_COLLIDER_CONFIG: CircleColliderConfig[] = [
	{
		type: ColliderType.Circle,
		traits: ColliderTraits.BlockProjectile,
		position: [0, -50],
		radius: 35,
	},
	{
		type: ColliderType.Circle,
		traits: ColliderTraits.BlockMovement,
		position: [0, -30],
		radius: 35,
	},
]

const movementCollider = PLAYER_COLLIDER_CONFIG[PLAYER_MOVEMENT_COLLIDER_INDEX]
console.assert(isSet(movementCollider.traits, ColliderTraits.BlockMovement), `collider at index"${PLAYER_MOVEMENT_COLLIDER_INDEX} is not movement collider`)

export function canEquipItemOfLevel(progressionLevel, itemLevel) {
	const equipableLevel = maxEquipableLevel(progressionLevel)
	return itemLevel <= equipableLevel
}

export function maxEquipableLevel(progressionLevel: number) {
	const equipableLevel = progressionLevel * 3 + PLAYER_EQUIP_LEVEL_THRESHOLD
	return equipableLevel
}

export interface PlayerProgression {
	progressionLevel?: level
	personalDifficulty?: level

	activeWorldTier?: WorldTier
	furthestWorldTierEver?: WorldTier
	
	activeSoulCycle?: number
	furthestSoulCycleEver?: number

	furthestBiomeLevelEver?: level
}

export function playerCanUseItem(itemType, itemSubType, playerProg?: PlayerProgression) {
	const minimumItemLevel = 0
	let minimumSoulCycleUnlocked = 0
	const minimumCurrentSoulCycle = 0 // soul cycle they are on in the current run
	// let minimumBiomeUnlockedInCurrentSoulCycle = 0 // which bosses they've defeated for the currently selected soul cycle
	// let minimumCurrentWorldDifficulty = WorldDifficulty.Hub // world difficulty of current server
	if (itemType === 6000) { //TODO: once ItemType is cleaned up to not violate "shared" rules, this should be changed to the real enums
		switch (itemSubType as PersistentBuffSubType) {
			case PersistentBuffSubType.LegendaryEnchanting:
			case PersistentBuffSubType.LegendaryQueensRansom:
			case PersistentBuffSubType.FinalCountdown:
			case PersistentBuffSubType.Pauper:
				minimumSoulCycleUnlocked = 1
				break
		}
	}
	//TODO: it'd be nice if Biome Keys used this someday... and maybe unify with equipment?
	// add logic for other restrictions in the future

	// console.log({
	// 	itemType,
	// 	itemSubType,
	// 	level,
	// 	minimumSoulCycleUnlocked,
	// 	minimumItemLevel,
	// 	minimumCurrentSoulCycle,
	// })

	if (minimumSoulCycleUnlocked && minimumSoulCycleUnlocked > playerProg.furthestSoulCycleEver) {
		return false
	}

	if (minimumCurrentSoulCycle && minimumCurrentSoulCycle > playerProg.activeSoulCycle) {
		return false
	}

	if (minimumItemLevel && !canEquipItemOfLevel(playerProg.progressionLevel, minimumItemLevel)) {
		return false
	}

	return true
}

export function getWeaponOffsetFromSkin(skinId: PlayerSkinType) {
	switch (skinId) {
		case PlayerSkinType.RANGER:
		case PlayerSkinType.RANGER_02:
		case PlayerSkinType.RANGER_03:
			return RANGER_WEAPON_OFFSET
		case PlayerSkinType.DWARF:
		case PlayerSkinType.DWARF_02:
		case PlayerSkinType.DWARF_03:
			return DWARF_WEAPON_OFFSET
		case PlayerSkinType.BARBARIAN:
		case PlayerSkinType.BARBARIAN_02:
		case PlayerSkinType.BARBARIAN_03:
			return RANGER_WEAPON_OFFSET
		case PlayerSkinType.WIZARD:
		case PlayerSkinType.WIZARD_02:
		case PlayerSkinType.WIZARD_03:
			return RANGER_WEAPON_OFFSET
		case PlayerSkinType.SORCERESS:
		case PlayerSkinType.SORCERESS_02:
		case PlayerSkinType.SORCERESS_03:
			return RANGER_WEAPON_OFFSET
		case PlayerSkinType.KNIGHT:
		case PlayerSkinType.KNIGHT_02:
		case PlayerSkinType.KNIGHT_03:
			return RANGER_WEAPON_OFFSET
		case PlayerSkinType.ELF:
		case PlayerSkinType.ELF_02:
		case PlayerSkinType.ELF_03:
			return RANGER_WEAPON_OFFSET
		case PlayerSkinType.AURUM_SOLDIER:
		case PlayerSkinType.SCION_SOLDIER:
		case PlayerSkinType.ORDER_SOLDIER:
		case PlayerSkinType.SCION_SOLARA:
			return RANGER_WEAPON_OFFSET
		case PlayerSkinType.ORDER_REHKOSH:
		case PlayerSkinType.AURUM_BLACKMARK:
			return DWARF_WEAPON_OFFSET
		default:
			return
	}
}

export function getAimOffsetFromSkin(skinId: PlayerSkinType) {
	switch (skinId) {
		case PlayerSkinType.RANGER:
		case PlayerSkinType.RANGER_02:
		case PlayerSkinType.RANGER_03:
			return RANGER_AIM_OFFSET
		case PlayerSkinType.DWARF:
		case PlayerSkinType.DWARF_02:
		case PlayerSkinType.DWARF_03:
			return DWARF_AIM_OFFSET
		case PlayerSkinType.BARBARIAN:
		case PlayerSkinType.BARBARIAN_02:
		case PlayerSkinType.BARBARIAN_03:
			return RANGER_AIM_OFFSET
		case PlayerSkinType.WIZARD:
		case PlayerSkinType.WIZARD_02:
		case PlayerSkinType.WIZARD_03:
			return RANGER_AIM_OFFSET
		case PlayerSkinType.SORCERESS:
		case PlayerSkinType.SORCERESS_02:
		case PlayerSkinType.SORCERESS_03:
			return RANGER_AIM_OFFSET
			break
		case PlayerSkinType.KNIGHT:
		case PlayerSkinType.KNIGHT_02:
		case PlayerSkinType.KNIGHT_03:
			return RANGER_AIM_OFFSET
		case PlayerSkinType.ELF:
		case PlayerSkinType.ELF_02:
		case PlayerSkinType.ELF_03:
			return RANGER_AIM_OFFSET
		case PlayerSkinType.AURUM_SOLDIER:
		case PlayerSkinType.SCION_SOLDIER:
		case PlayerSkinType.ORDER_SOLDIER:
		case PlayerSkinType.SCION_SOLARA:
			return RANGER_AIM_OFFSET
		case PlayerSkinType.ORDER_REHKOSH:
		case PlayerSkinType.AURUM_BLACKMARK:
			return DWARF_AIM_OFFSET
		default:
			return
	}
}
