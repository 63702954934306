import { parse } from 'query-string'
import { debugConfig } from './debug-config'
import { GameModeType } from '../shared/game-mode-type'
import logger from '../../utils/client-logger'
import { convertStringToType, setObjectPropWithString } from '../../utils/debug'

// Which ones to allow on live deployments
const productionParams = ['zoomLevel']

class ClientConfig {
	// WARNING: these must all be initialized so populate can iterate over them
	connectTo: GameModeType = GameModeType.Hub
	skipToGame: boolean = false
	renderDebugVisuals: boolean = debugConfig.render.debugVisuals
	renderSpineDebugVisuals: boolean = debugConfig.render.spineDebug
	renderAnimDebugVisuals: boolean = debugConfig.render.animDebug
	zoomLevel: number = 0.8
	debug: boolean = debugConfig.debug
	drawColliders: boolean = debugConfig.drawColliders
	forcePropRig: boolean = debugConfig.props.forcePropRig
	disableCameraShake: boolean = debugConfig.camera.disableShake
	biomeIndex: number = 0
	filter1: string = ''
	filter2: string = ''
	playerFilter: string = ''
	enemyFilter: string = ''
	filterParams: string = ''

	populate(locationSearchString: string) {
		const urlParams = parse(locationSearchString)
		Object.keys(this).forEach((configKey) => {
			const paramType = typeof this[configKey]
			const urlParam = urlParams[configKey]
			if (urlParam === undefined) {
				// property not in url, keep default above ^
			} else if ((process.env.NODE_ENV === 'loot-prod' || process.env.NODE_ENV === 'beta') && !productionParams.includes(configKey)) {
				// debug property, ignore
			} else {
				this[configKey] = convertStringToType(urlParam as string, paramType, logger)
				logger.debug(`client config: ${configKey}: ${this[configKey]}`)
			}
		})

		const obj = debugConfig

		// look for debugConfig vars being set in url
		if (process.env.NODE_ENV !== 'loot-prod' && process.env.NODE_ENV !== 'beta') {
			Object.keys(urlParams).forEach((propertyPath) => {
				const value = urlParams[propertyPath] as string
				setObjectPropWithString(obj, propertyPath, value)
			})
		}
	}
}

export const clientConfig: ClientConfig = new ClientConfig()
clientConfig.populate(window.location.search)
