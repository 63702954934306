import { AnyColliderConfig, ColliderTraits, ColliderType } from '../../collision/shared/colliders'
import { degToRad } from '../../utils/math'
import { makeBlockPropConfig, PropConfig, PropConfigs } from './prop-configs'

const fungiLargeUncommon1Colliders: AnyColliderConfig[] = [
	{ type: ColliderType.Ellipse, position: [13, 10], rX: 220, rY: 140, traits: ColliderTraits.BlockMovementAndItem },
	{ type: ColliderType.Circle, position: [37, -48], radius: 80, traits: ColliderTraits.BlockAll },
]
const fungiLargeUncommon2Colliders: AnyColliderConfig[] = [{ type: ColliderType.Ellipse, position: [13, 10], rX: 229, rY: 140, traits: ColliderTraits.BlockMovementAndItem }]
const fungiLargeUncommon3Colliders: AnyColliderConfig[] = [
	{ type: ColliderType.Ellipse, position: [13, 10], rX: 220, rY: 140, traits: ColliderTraits.BlockMovementAndItem },
	{ type: ColliderType.Ellipse, position: [-27, -59], rX: 126, rY: 62, traits: ColliderTraits.BlockAll },
]

const fungiLargeRareVar01Colliders: AnyColliderConfig[] = [
	{ type: ColliderType.Ellipse, position: [-57, 40], rX: 300, rY: 230, traits: ColliderTraits.BlockAll },
	{ type: ColliderType.Box, position: [-101, -345], width: 413, height: 415, traits: ColliderTraits.BlockAll },
	{ type: ColliderType.Ellipse, position: [-186, 311], rX: 123, rY: 68, traits: ColliderTraits.BlockMovementAndItem },
	{ type: ColliderType.Ellipse, position: [223, 226], rX: 114, rY: 65, traits: ColliderTraits.BlockMovementAndItem },
]

const fungiLargeRare2Colliders: AnyColliderConfig[] = [
	{ type: ColliderType.Ellipse, position: [81.28, 167.7], rX: 293, rY: 163, traits: ColliderTraits.BlockMovementAndItem },
	{ type: ColliderType.Ellipse, position: [-116.68, -44.2], rX: 239, rY: 300, traits: ColliderTraits.BlockMovementAndItem },
	// { type: ColliderType.Circle, position: [-318, -50], radius: 45, traits: ColliderTraits.BlockAll },
]

// beach and fungi are sharing this, for now
const fungiSwampPropConfig: PropConfig = {
	colliders: [
		{
			type: ColliderType.Box,
			position: [220, 0],
			width: 300,
			height: 130,
			angle: degToRad(-35),
			traits: ColliderTraits.BlockMovementAndItem,
		},
		{
			type: ColliderType.Box,
			position: [-480, -100],
			width: 200,
			height: 340,
			angle: degToRad(-35),
			traits: ColliderTraits.BlockMovementAndItem,
		},
		{
			type: ColliderType.Ellipse,
			rX: 300,
			rY: 200,
			position: [30, -50],
			traits: ColliderTraits.BlockMovementAndItem,
		},
	],
	zOffset: -1000,
	visibilityRadius: 540,
	ignoreSharedAtlas: true,
}

export const fungiPropConfigs: PropConfigs = {
	'boss-arena-ground': {
		colliders: [
			{ type: ColliderType.Box, position: [-1418, 540], width: 1840, height: 142, traits: ColliderTraits.BlockAll, angle: 0.0 },
			{ type: ColliderType.Ellipse, position: [-748, 657], rX: 476, rY: 129, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-198, 614], rX: 230, rY: 132, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [159, 616], rX: 315, rY: 132, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [272, 695], radius: 50, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [752, 239], radius: 74, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [885, -27], radius: 74, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [516, 647], radius: 74, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [42, -1107], radius: 163, traits: ColliderTraits.BlockMovementAndItem },
			{type: ColliderType.Box, position: [-1300, -942], width: 849, height: 134, angle: -0.33, traits: ColliderTraits.BlockAll},
			{type: ColliderType.Box, position: [-808, -1176], width: 984, height: 134, angle: 0.0, traits: ColliderTraits.BlockAll},
			{ type: ColliderType.Circle, position: [-455, -1089], radius: 140, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [-213, -1118], radius: 163, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [-695, -1078], radius: 151, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-991, -961], rX: 282, rY: 145, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-1576, -583], rX: 208, rY: 164, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [-1347, -800], rX: 240, rY: 169, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [-1264, 607], rX: 183, rY: 145, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-1484, 500], rX: 149, rY: 128, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [-1634, 394], rX: 145, rY: 145, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [-1793, 68], rX: 201, rY: 357, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-1795, -551], width: 171, height: 1020, traits: ColliderTraits.BlockAll, angle: 0.0 },
			{ type: ColliderType.Ellipse, position: [-1815, -136], rX: 75, rY: 53, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [-1725, -333], rX: 181, rY: 233, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [81, -1035], width: 134, height: 936, traits: ColliderTraits.BlockAll, angle: -0.89 },
			{ type: ColliderType.Box, position: [50, -996], width: 50, height: 936, traits: ColliderTraits.BlockMovementAndItem, angle: -0.89 },
			{ type: ColliderType.Box, position: [730, -546], width: 228, height: 510, traits: ColliderTraits.BlockAll, angle: 0.0 },
			{ type: ColliderType.Box, position: [740, -171], width: 212, height: 838, traits: ColliderTraits.BlockAll, angle: 0.52 },
			{ type: ColliderType.Box, position: [579, 422], width: 125, height: 194, traits: ColliderTraits.BlockAll, angle: 0.52 },
			{ type: ColliderType.Circle, position: [-1135, 645], radius: 118, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-363, 633], rX: 137, rY: 122, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-1747, 295], rX: 159, rY: 231, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-1033, 575], rX: 122, rY: 88, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [-427, 603], rX: 122, rY: 88, traits: ColliderTraits.BlockAll },
		],
		rigged: false,
		visibilityRadius: 1980,
	},
	'boss-arena-spores': {
		rigged: true,
		visibilityRadius: 1980,
		zOffset: -1050,
	},
	'boss-arena-spores-02': {
		rigged: true,
		visibilityRadius: 1980,
		zOffset: 725,
	},
	'poi-01-base': {
		colliders: [
			{ type: ColliderType.Circle, position: [1092, 335], radius: 60, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [857, -908], radius: 35, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [-733, -842], radius: 51, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [-230, -803], rX: 107, rY: 73, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [-935, 371], rX: 107, rY: 73, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [992, 426], rX: 92, rY: 67, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [-944, -380], rX: 77, rY: 157, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [913, -461], rX: 107, rY: 73, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [1041, -539], rX: 68, rY: 41, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [1396, -189], rX: 214, rY: 114, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-538, 19], width: 332, height: 99, traits: ColliderTraits.BlockAll, angle: degToRad(30) },
			{ type: ColliderType.Box, position: [202, -933], width: 638, height: 126, traits: ColliderTraits.BlockAll, angle: degToRad(0) },
			{ type: ColliderType.Box, position: [-194, 647], width: 446, height: 100, traits: ColliderTraits.BlockAll, angle: degToRad(0) },
			{ type: ColliderType.Box, position: [-1172, -541], width: 194, height: 511, traits: ColliderTraits.BlockAll, angle: degToRad(0) },
			{ type: ColliderType.Box, position: [-1240, -30], width: 221, height: 211, traits: ColliderTraits.BlockAll, angle: degToRad(0) },
		],
		rigged: false,
		visibilityRadius: 1500,
	},
	'poi-03': {
		zOffset: -20,
		dontLoadAsset: true,
	},
	'poi-02': {
		zOffset: -10,
		dontLoadAsset: true,
	},
	'poi-04': {
		zOffset: -25,
		dontLoadAsset: true,
	},
	'poi-05': {
		zOffset: -25,
		dontLoadAsset: true,
	},
	'poi-06': {
		zOffset: -20,
		dontLoadAsset: true,
	},
	'poi-07': {
		zOffset: -20,
		dontLoadAsset: true,
	},
	'poi-08': {
		zOffset: -10,
		dontLoadAsset: true,
	},
	'poi-03-thorn-maze': {
		colliders: [
			{ type: ColliderType.Box, position: [-1281, -708], width: 1250, height: 189, traits: ColliderTraits.BlockAll, angle: degToRad(15) },
			{ type: ColliderType.Box, position: [-954, -50], width: 971, height: 140, traits: ColliderTraits.BlockAll, angle: degToRad(10) },
			{ type: ColliderType.Box, position: [-426, 571], width: 546, height: 115, traits: ColliderTraits.BlockAll, angle: degToRad(-65) },
			{ type: ColliderType.Box, position: [230, 35], width: 635, height: 155, traits: ColliderTraits.BlockAll, angle: degToRad(0) },
			{ type: ColliderType.Box, position: [757, 38], width: 475, height: 163, traits: ColliderTraits.BlockAll, angle: degToRad(45) },
			{ type: ColliderType.Box, position: [698, 838], width: 593, height: 178, traits: ColliderTraits.BlockAll, angle: degToRad(-70) },
			{ type: ColliderType.Box, position: [260, 504], width: 707, height: 163, traits: ColliderTraits.BlockAll, angle: degToRad(20) },
			{ type: ColliderType.Circle, position: [1086, 298], radius: 120, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [263, -557], width: 651, height: 163, traits: ColliderTraits.BlockAll, angle: degToRad(20) },
			{ type: ColliderType.Box, position: [-1201, 653], width: 631, height: 174, traits: ColliderTraits.BlockAll, angle: degToRad(-70) },
			{ type: ColliderType.Circle, position: [949, -373], radius: 120, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-1185, 615], width: 867, height: 163, traits: ColliderTraits.BlockAll, angle: degToRad(20) },
			{ type: ColliderType.Box, position: [196, -400], width: 808, height: 270, traits: ColliderTraits.BlockAll, angle: degToRad(-70) },
			{ type: ColliderType.Box, position: [-338, -290], width: 762, height: 232, traits: ColliderTraits.BlockAll, angle: degToRad(-70) },
			{ type: ColliderType.Circle, position: [716, -647], radius: 48, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [-422, -753], radius: 48, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [207, 481], width: 159, height: 134, traits: ColliderTraits.BlockAll, angle: degToRad(1.22) },
			{ type: ColliderType.Box, position: [-685, 947], width: 111, height: 185, traits: ColliderTraits.BlockAll, angle: degToRad(1.22) },
			{ type: ColliderType.Box, position: [-939, 867], width: 214, height: 120, traits: ColliderTraits.BlockAll, angle: degToRad(0.52) },
			{ type: ColliderType.Box, position: [409, -1172], width: 100, height: 136, traits: ColliderTraits.BlockAll, angle: degToRad(0) },
			{ type: ColliderType.Box, position: [-1217, -549], width: 100, height: 100, traits: ColliderTraits.BlockAll, angle: degToRad(0) },
			{ type: ColliderType.Box, position: [-1100, -561], width: 155, height: 150, traits: ColliderTraits.BlockAll, angle: degToRad(0) },
			{ type: ColliderType.Box, position: [-170, 236], width: 124, height: 97, traits: ColliderTraits.BlockAll, angle: degToRad(0) },
			{ type: ColliderType.Box, position: [-262, 316], width: 170, height: 141, traits: ColliderTraits.BlockAll, angle: degToRad(0.5) },
			{ type: ColliderType.Box, position: [536, -460], width: 423, height: 100, traits: ColliderTraits.BlockAll, angle: degToRad(0) },
			{ type: ColliderType.Box, position: [-528, -520], width: 319, height: 100, traits: ColliderTraits.BlockAll, angle: degToRad(0) },
			{ type: ColliderType.Box, position: [-856, 91], width: 123, height: 104, traits: ColliderTraits.BlockAll, angle: degToRad(0) },
			{ type: ColliderType.Box, position: [1029, 395], width: 79, height: 356, traits: ColliderTraits.BlockAll, angle: degToRad(20) },
			{ type: ColliderType.Box, position: [-1251, 551], width: 100, height: 100, traits: ColliderTraits.BlockAll, angle: degToRad(20) },

		],
		rigged: false,
		visibilityRadius: 1200,
	},
	// 'poi-04-ent-ritual': {
	// 	colliders: [
	// 		{ type: ColliderType.Circle, position: [8, -491], radius: 120, traits: ColliderTraits.BlockMovementAndItem },
	// 		{ type: ColliderType.Ellipse, position: [ 283, -35], rX: 120, rY: 77, traits: ColliderTraits.BlockMovementAndItem },
	// 		{ type: ColliderType.Box, position: [-1015, -535], width: 1475, height: 80, traits: ColliderTraits.BlockAll },

	// 	],
	// 	zOffset: -500,
	// 	rigged: false,
	// 	dontLoadAsset: true,
	// 	visibilityRadius: 1200,
	// },
	'outpost-base': {
		disableFileOrdering: true,
		colliders: [
			{ type: ColliderType.Box, position: [-955, -635], width: 70, height: 1135, traits: ColliderTraits.BlockAll }, // west wall
			{ type: ColliderType.Box, position: [380, -635], width: 70, height: 1135, traits: ColliderTraits.BlockAll }, // east wall
			{ type: ColliderType.Box, position: [-955, -705], width: 1405, height: 100, traits: ColliderTraits.BlockAll }, // north wall
			{ type: ColliderType.Box, position: [-955, 400], width: 200, height: 100, traits: ColliderTraits.BlockAll }, // south-west wall
			{ type: ColliderType.Box, position: [50, 400], width: 400, height: 100, traits: ColliderTraits.BlockAll }, // south-east wall
			{ type: ColliderType.Box, position: [-260, -303], width: 550, height: 152, traits: ColliderTraits.BlockAll }, // main building 1
			{ type: ColliderType.Box, position: [-195, -592], width: 420, height: 310, traits: ColliderTraits.BlockAll }, // main building 2
			{ type: ColliderType.Box, position: [-891, 127], width: 308, height: 150, traits: ColliderTraits.BlockMovementAndItem }, // campfire
			{ type: ColliderType.Box, position: [-736, -143], width: 160, height: 62, traits: ColliderTraits.BlockAll }, // worm sign
			{ type: ColliderType.Circle, position: [236, -477], radius: 50, traits: ColliderTraits.BlockAll }, // tree 1
			{ type: ColliderType.Circle, position: [314, -349], radius: 40, traits: ColliderTraits.BlockAll }, // tree 2
			{ type: ColliderType.Circle, position: [581, 495], radius: 137, traits: ColliderTraits.BlockAll }, // bottom right prop 1
			{ type: ColliderType.Circle, position: [336, 583], radius: 123, traits: ColliderTraits.BlockAll }, // bottom right prop 2
			{ type: ColliderType.Circle, position: [693, 629], radius: 50, traits: ColliderTraits.BlockAll }, // bottom right prop 3
			{ type: ColliderType.Circle, position: [-976, 577], radius: 143, traits: ColliderTraits.BlockAll }, // bottom left trees
			{ type: ColliderType.Ellipse, position: [539, -63], rX: 94, rY: 46, traits: ColliderTraits.BlockAll }, // right props 1
			{ type: ColliderType.Ellipse, position: [709, 41], rX: 56, rY: 101, traits: ColliderTraits.BlockAll }, // right props 2
			{ type: ColliderType.Ellipse, position: [-1084, -350], rX: 84, rY: 108, traits: ColliderTraits.BlockAll }, // left props
			{ type: ColliderType.Circle, position: [-954, -756], radius: 106, traits: ColliderTraits.BlockAll }, // top left prop 1
			{ type: ColliderType.Circle, position: [-1129, -630], radius: 118, traits: ColliderTraits.BlockAll }, // top left prop 2
			{ type: ColliderType.Circle, position: [-1284, -569], radius: 50, traits: ColliderTraits.BlockAll }, // top left prop 2b
			{ type: ColliderType.Circle, position: [-519, -733], radius: 43, traits: ColliderTraits.BlockAll }, // top tree
			{ type: ColliderType.Ellipse, position: [417, -708], rX: 99, rY: 48, traits: ColliderTraits.BlockAll }, // top right trees
		],
		rigged: false,
		visibilityRadius: 1400,
	},
	'outpost-campfire': {
		rigged: true,
		visibilityRadius: 1400,
		zOffset: Number.MAX_SAFE_INTEGER,
	},
	'outpost-smoke': {
		rigged: true,
		visibilityRadius: 1400,
		zOffset: Number.MAX_SAFE_INTEGER,
	},
	'outpost-lanterns': {
		rigged: true,
		visibilityRadius: 1400,
		zOffset: 575,
	},
	outpost: {
		zOffset: -250,
		dontLoadAsset: true,
	},
	'outpost-window': {
		zOffset: -5000,
		dontLoadAsset: true,
	},
	ground_patch: {
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},
	worm_sign: {
		zOffset: -25,
		dontLoadAsset: true,
	},
	campfire: {
		zOffset: -25,
		dontLoadAsset: true,
	},
	'outpost-bubba': {
		rigged: true,
		visibilityRadius: 1400,
		zOffset: -1000,
	},
	'dirt-path-01': {
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},
	'dirt-path-02': {
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},
	'dirt-stones-01': {
		zOffset: -200,
		dontLoadAsset: true,
	},
	
	'dirt-stones-02': {
		zOffset: -200,
		dontLoadAsset: true,
	},
	'fungi-swamp': fungiSwampPropConfig,
	// START blocker pieces //
	'low-blocker-01': {
		colliders: [{ type: ColliderType.Ellipse, position: [0, 0], rX: 150, rY: 80, traits: ColliderTraits.BlockMovementAndItem }],
		dontLoadAsset: true,
		visibilityRadius: 150,
	},
	'low-blocker-02': {
		colliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 90, traits: ColliderTraits.BlockMovementAndItem }],
		dontLoadAsset: true,
		visibilityRadius: 125,
	},
	'low-blocker-03': {
		colliders: [{ type: ColliderType.Ellipse, position: [0, 0], rX: 150, rY: 80, traits: ColliderTraits.BlockMovementAndItem }],
		dontLoadAsset: true,
		visibilityRadius: 150,
	},
	'mid-blocker-01': {
		colliders: [{ type: ColliderType.Box, angle: degToRad(30), position: [24, -37], height: 150, width: 50, traits: ColliderTraits.BlockAll }],
		dontLoadAsset: true,
		visibilityRadius: 170,
	},
	'mid-blocker-02': {
		zOffset: -150,
		colliders: [{ type: ColliderType.Box, position: [-33, -101], height: 250, width: 40, traits: ColliderTraits.BlockAll }],
		dontLoadAsset: true,
		visibilityRadius: 170,
	},
	'mid-blocker-03': {
		zOffset: -60,
		colliders: [{ type: ColliderType.Box, angle: degToRad(-30), position: [-80, -30], height: 185, width: 50, traits: ColliderTraits.BlockAll }],
		dontLoadAsset: true,
		visibilityRadius: 150,
	},
	'high-blocker-01': {
		colliders: [{ type: ColliderType.Circle, position: [-6, -5], radius: 78, traits: ColliderTraits.BlockAll }],
		dontLoadAsset: true,
		visibilityRadius: 110,
	},
	'high-blocker-02': {
		colliders: [{ type: ColliderType.Circle, position: [-10, 6], radius: 60, traits: ColliderTraits.BlockAll }],
		dontLoadAsset: true,
		visibilityRadius: 120,
	},
	// END blocker pieces //
	'high-blocker-01-var01': makeBlockPropConfig(),
	'high-blocker-01-var02': makeBlockPropConfig(),
	'high-blocker-01-var03': makeBlockPropConfig(),
	'high-blocker-01-var04': makeBlockPropConfig(),
	'mid-blocker-01-var01': makeBlockPropConfig(),
	'mid-blocker-01-var02': makeBlockPropConfig(),
	'mid-blocker-01-var03': makeBlockPropConfig(),
	'mid-blocker-01-var04': makeBlockPropConfig(),
	'mid-blocker-01-var05': makeBlockPropConfig(),
	'mid-blocker-01-var06': makeBlockPropConfig(),
	'low-blocker-01-var01': makeBlockPropConfig(),
	'low-blocker-01-var02': makeBlockPropConfig(),
	'low-blocker-01-var03': makeBlockPropConfig(),
	'low-blocker-01-var04': makeBlockPropConfig(),
	'low-blocker-01-var05': makeBlockPropConfig(),
	'low-blocker-01-var06': makeBlockPropConfig(),
	'poi-ground-01': {
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},
	'poi-ground-02': {
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},
	'sand-rare-01': {
		zOffset: -6000,
		dontLoadAsset: true,
	},
	'sand-01': {
		zOffset: -6000,
		dontLoadAsset: true,
	},
	'sand-03': {
		zOffset: -6000,
		dontLoadAsset: true,
	},
	'sand-02': {
		zOffset: -6000,
		dontLoadAsset: true,
	},
	'boss-area-03': {
		zOffset: -10,
		dontLoadAsset: true,
	},
	'boss-area-04': {
		zOffset: -10,
		dontLoadAsset: true,
	},
	'boss-area-05': {
		zOffset: -10,
		dontLoadAsset: true,
	},
	'small-common-01': {
		zOffset: -50000,
		visibilityRadius: 50,
	},
	'small-common-02': {
		zOffset: -50000,
		visibilityRadius: 95,
	},
	'small-common-03': {
		zOffset: -50000,
		visibilityRadius: 65,
	},

	'small-uncommon-01': {
		colliders: [{ type: ColliderType.Ellipse, position: [-4, -50], rX: 25, rY: 45, traits: ColliderTraits.BlockMovementAndItem }],
		visibilityRadius: 165,
		zOffset: -10,
	},
	'small-uncommon-02': {
		visibilityRadius: 120,
		zOffset: -10,
	},
	'small-uncommon-03': {
		visibilityRadius: 110,
		zOffset: -10,
	},
	'small-uncommon-04': {
		visibilityRadius: 95,
		zOffset: -10,
	},

	'small-rare-01': {
		colliders: [{ type: ColliderType.Circle, position: [11, -53], radius: 58, traits: ColliderTraits.BlockAll }],
		visibilityRadius: 305,
	},
	'small-rare-02': {
		colliders: [{ type: ColliderType.Ellipse, position: [20, -26], rX: 80, rY: 56, traits: ColliderTraits.BlockMovementAndItem }],
		visibilityRadius: 255,
	},
	'small-rare-03': {
		colliders: [{ type: ColliderType.Circle, position: [0, -20], radius: 48, traits: ColliderTraits.BlockMovementAndItem }],
		visibilityRadius: 160,
		zOffset: -50,
	},

	'med-common-01': {
		colliders: [{ type: ColliderType.Circle, position: [3, -40], radius: 60, traits: ColliderTraits.BlockAll }],
		visibilityRadius: 320,
		zOffset: -20,
	},
	'med-common-02': {
		colliders: [{ type: ColliderType.Circle, position: [0, -45], radius: 43, traits: ColliderTraits.BlockAll }],
		visibilityRadius: 245,
		zOffset: -20,
	},
	'med-common-03': {
		colliders: [{ type: ColliderType.Circle, position: [5, -45], radius: 43, traits: ColliderTraits.BlockAll }],
		visibilityRadius: 235,
		zOffset: -20,
	},
	'med-common-04': {
		colliders: [{ type: ColliderType.Circle, position: [3, -48], radius: 50, traits: ColliderTraits.BlockAll }],
		visibilityRadius: 195,
	},

	'med-uncommon-01': {
		colliders: [
			{ type: ColliderType.Box, position: [-88.05, -91], width: 181, height: 85, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-41, -171], width: 208.5, height: 81, traits: ColliderTraits.BlockAll },
		],
		visibilityRadius: 305,
		zOffset: -50,
	},
	'med-uncommon-02': {
		colliders: [
			{ type: ColliderType.Box, position: [-156.6, -79.5], width: 181, height: 76.5, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-105, -128], width: 208.5, height: 81, traits: ColliderTraits.BlockAll },
		],
		visibilityRadius: 265,
		zOffset: -50,
	},
	'med-uncommon-03': {
		colliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 50, traits: ColliderTraits.BlockAll }], // placeholder
		visibilityRadius: 195,
	},

	'large-uncommon-01-var01': {
		colliders: fungiLargeUncommon1Colliders,
		zeroHeight: ['large-uncommon-01'],
		visibilityRadius: 330,
	},
	'large-uncommon-01-var02': {
		colliders: fungiLargeUncommon2Colliders,
		zeroHeight: ['large-uncommon-01'],
		visibilityRadius: 280,
	},
	'large-uncommon-01-var03': {
		colliders: fungiLargeUncommon3Colliders,
		zeroHeight: ['large-uncommon-01'],
		visibilityRadius: 370,
	},
	'large-rare-01-var01': {
		colliders: fungiLargeRareVar01Colliders,
		visibilityRadius: 550,
	},
	'large-rare-01-var02': {
		colliders: fungiLargeRareVar01Colliders,
		visibilityRadius: 560,
	},
	'large-rare-01-var03': {
		colliders: fungiLargeRareVar01Colliders,
		visibilityRadius: 550,
	},
	'large-rare-02-var01': {
		colliders: fungiLargeRare2Colliders,
		visibilityRadius: 515,
		zOffset: -350,
		disableSubPropZOffs: true,
	},
	'large-rare-02-var02': {
		colliders: fungiLargeRare2Colliders,
		visibilityRadius: 515,
		zOffset: -50,
	},
	'large-rare-02-var03': {
		colliders: fungiLargeRare2Colliders,
		visibilityRadius: 515,
		zOffset: -350,
	},
	'fungi-alter': {
		overrideAtlas: 'fungi-alter',
		colliders: [
			{ type: ColliderType.Ellipse, position: [-18,-315], rX: 95, rY: 99, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [-181,-274], rX: 72, rY: 79, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [129,-276], rX: 72, rY: 79, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [-276,-178], rX: 51, rY: 48, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [260,-183], rX: 51, rY: 56, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [-249,-50], rX: 24, rY: 21, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [224,-34], rX: 24, rY: 21, traits: ColliderTraits.BlockMovementAndItem },
		],
		rigged: false,
		zOffset: -350,
		visibilityRadius: 1400,
	},
	'party-alter-animated': {
		overrideAtlas: 'fungi-alter',
		zOffset: -200,
		visibilityRadius: 1400,
	},
}
