import nengi from 'nengi'

export default class PlayerUpdateShieldMessage {
	static protocol = {
		entityId: { type: nengi.Number },
		currentShields: { type: nengi.Number },
		maxShields: { type: nengi.Number },
	}

	entityId: number
	currentShields: number
	maxShields: number

	constructor(entityId: number, currentShields: number, maxShields: number) {
		this.entityId = entityId
		this.currentShields = currentShields
		this.maxShields = maxShields
	}
}
