import logger from '../../utils/client-logger'
import { uuid } from '../../utils/primitive-types'

interface ItemLockUIState {
	lockedItems: string[]
}

export const itemLocks = () => {
	logger.debug('Initializing Item Locks UI module')
	return {
		namespaced: true,
		state: {
			lockedItems: [],
		} as ItemLockUIState,
		mutations: {
			lockItem(state: ItemLockUIState, itemId: uuid) {
				if (state.lockedItems.includes(itemId) === false) {
					state.lockedItems = [...state.lockedItems, itemId]
				}
			},
			unlockItem(state: ItemLockUIState, itemId: uuid) {
				if (state.lockedItems.includes(itemId)) {
					state.lockedItems = [...state.lockedItems.filter((existingItemId) => existingItemId !== itemId)]
				} else {
					console.warn('Received an item unlock for an item that wasnt flagged as locked on our client. Possibly a bug/desync?')
				}
			},
			unlockManyItems(state: ItemLockUIState, itemIds: uuid[]) {
				state.lockedItems = state.lockedItems.filter((existingId) => !itemIds.includes(existingId))
			},
			unlockAllItems(state: ItemLockUIState) {
				state.lockedItems.length = 0
			}
		},
		getters: {
			itemIsLocked(state: ItemLockUIState) {
				return (itemId: uuid) => {
					return state.lockedItems.includes(itemId)
				}
			},
		},
		actions: {},
	}
}
