<template>
	<div v-show="shouldDisplayShotCooldownIndicator" id="shot-cooldown" :style="{ opacity: `${isOnShotCooldown}`, transform: `translate(${playerX - 40}px, ${playerY}px)` }">
		<div class="background"></div>
		<div class="energy-fill">
			<div class="fill" :style="{ width: `${100-shotCooldown}%` }"></div>
		</div>
		<div class="gold-trim-left"></div>
		<div class="gold-trim-right"></div>
	</div>
</template>

<script lang="js" scoped>
import { mapGetters } from 'vuex'

export default {
	name: 'ShotCooldown',
	computed: {
		...mapGetters('hud', ["shotCooldown", "isOnShotCooldown", "shouldDisplayShotCooldownIndicator", "playerX", "playerY"])
    }
}
</script>

<style lang="less">
#shot-cooldown {
    position: absolute;
	width: 85px;
    height: 15px;
    top: 3px;
    left: 0px;
    opacity: 0;

	.background {
		position: absolute;
		background-image: url('./assets/charge-bar-bg.png');
		width: 78px;
		height: 7px;
	}

	.energy-fill {
		position: absolute;
		width: 78px;

		.fill {
			background-image: linear-gradient(to right, #673AB7, #724eb3);
			box-shadow: 0px 0px 5px 0px #673AB7;
			height: 7px;
		}
	}

    .gold-trim-left {
        position: absolute;
        background-image: url('./assets/charge-bar-embellishment.png');
        width: 17px;
        height: 15px;
        top: -4px;
        left: -11px;
        transform: scaleX(-1);
    }

    .gold-trim-right {
        position: absolute;
        background-image: url('./assets/charge-bar-embellishment.png');
        width: 17px;
        height: 15px;
        top: -4px;
        right: -5px;
    }
}
</style>
