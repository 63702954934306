<template>
	<div class="health-container">
		<div class="background"></div>
		<div class="heart-icon"></div>
		<div class="health-text">{{ Math.ceil(playerStats.currentHealth) }} / {{ Math.ceil(playerStats.maxHealth) }}</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'PaperDollHealth',

	computed: {
		...mapGetters('paperdoll', ['playerStats']),
	},
}
</script>

<style lang="less" scoped>
@import '../../global/fonts/text.less';

.health-container {
	width: 243px;
	height: 52px;
	display: flex;
	align-items: center;
	position: relative;

	.background {
		background-image: url('./assets/paperdoll-health-shield-bg.png');
		background-repeat: no-repeat;
		background-size: cover;
		width: 243px;
		height: 52px;
		position: absolute;
	}
	.heart-icon {
		background-image: url('./assets/paperdoll-health-crystal.png');
		background-repeat: no-repeat;
		background-size: cover;
		width: 100px;
		height: 89px;
		position: absolute;
		top: -16px;
		left: -43px;
	}
	.health-text {
		position: absolute;
		left: 55px;
		.base-stats-and-addition-stats-title ();
	}
}
</style>
