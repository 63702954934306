import { Buffer, Geometry, Mesh } from 'pixi.js'

export default class WaterTileBase {
	currentBiome: number = 0
	// TODO2: WARNING, statics here will have 3 copies, in each of WaterTileBase/WaterTile/WaterBlendTile
	//  I did not notice this during implementation, but this is odd behaviour (coming from other languages)
	//  and we may want to move them back to child classes so there are only 2, and it's more clear
	protected static geometry: Geometry
	protected static vbo: Buffer
	protected static indexBuffer: Buffer
	protected readonly CURRENT_SPEED: number = 0.001
	protected readonly WAVES_SPEED: number = 0.002
	protected readonly WAVES_FADE_RATE: number = 0.005
	protected readonly TIME_BETWEEN_WAVES: number = 5
	protected readonly NORTH_SOUTH_BOUNDARY = 7000 // a few tiles below the dock

	protected vertSrc: string
	protected fragSrc: string
	protected mesh: Mesh
	protected waterColors: number[][] = []

	protected currentOffsetTimer: number = 0
	protected waveOffsetTimer: number = 0
	protected isWaveTile: boolean = false
	protected waveTimer: number = 0
	protected fadeInWave: boolean = false
}
