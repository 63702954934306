import { get, set } from "lodash"
import Time from "../../engine/shared/time"
import DevToolsManager from "../../ui/dev-tools/dev-tools-manager"
import { visitObject } from "../../utils/debug"
import { mapToRange, throwIfNotFinite, VectorXY } from "../../utils/math"

let DEBUG_CANVAS_CTX: CanvasRenderingContext2D
let mousePos = { x: 0, y: 0 }
const curves: Map<string, VectorXY[]> = new Map()
const colors = ['red', 'green', 'blue', 'cyan', 'teal', 'yellow', 'orange', 'purple', 'pink', 'brown', 'grey', 'black']

const graphConfig = {
	clear: () => curves.clear(),
	disabled: false,
	pauseLogging: false,
	view: {
		minx: 0,
		maxx: 0,
	},
	canvas: {
		pos: { x: 0, y: 0 },
		size: { x: 800, y: 400 },
		backAlpha: 0.1,
		backgroundColor: 'black'
	},
	curves: {},
	smoothing: 0.5,
	lineWidth: 0.5,
	boldLineWidth: 1.5,
	legend: {
		ySpacing: 12,
		font: '12px Arial',
		toFixed: 1,
		shadow: {
			enabled: true,
			topColor: 'white',
			bottomColor: 'black'
		}
	}
}
DevToolsManager.getInstance().addObjectByName('graphConfig', graphConfig)
//DevToolsManager.getInstance().setDebugObject(graphConfig)

function destroyDebugCanvasIf() {
	const ctx = DEBUG_CANVAS_CTX
	if (ctx) {
		ctx.canvas.remove()
		DEBUG_CANVAS_CTX = null
	}
}

function createDebugCanvas() {
	const canvas = document.createElement('canvas')
	canvas.style.position = 'absolute'
	const w = graphConfig.canvas.size.x
	const h = graphConfig.canvas.size.y
	canvas.width = w
	canvas.height = h
	canvas.onmousemove = mouseMove
	canvas.onmousedown = (e) => { DevToolsManager.getInstance().setDebugObject(graphConfig) }
	canvas.style.left = (window.innerWidth * 0.5 - w * 0.5) + 'px'
	canvas.style.top = (window.innerHeight * 0.5 - h * 0.5) + 'px'
	const ctx = canvas.getContext('2d')
	document.body.appendChild(canvas)

	return ctx
}

export function logData(name: string, value: number, smoothed: boolean = false): void {
	if (graphConfig.pauseLogging) {
		return
	}
	if (!curves.has(name)) {
		curves.set(name, [])
	}
	const t = Time.timeElapsedSinceModeStartInMs / 10

	throwIfNotFinite(value)
	throwIfNotFinite(t)

	const curve = curves.get(name)
	curve.push({ x: t, y: value })
	if (smoothed && curve.length > 1) {
		curve[curve.length - 1].x = Math.lerp(curve[curve.length - 2].x, curve[curve.length - 1].x, graphConfig.smoothing)
	}

	updateGraphCurvesUI(name)
}

function updateCreation() {
	const ctx = DEBUG_CANVAS_CTX
	if (ctx) {
		const canvas = ctx.canvas
		const size = graphConfig.canvas.size
		if (canvas.width !== size.x ||
			canvas.height !== size.y
		) {
			canvas.width = size.x
			canvas.height = size.y
			canvas.style.left = (window.innerWidth * 0.5 - size.x * 0.5) + 'px'
			canvas.style.top = (window.innerHeight * 0.5 - size.y * 0.5) + 'px'
		}
	}
	if (!DEBUG_CANVAS_CTX) {
		DEBUG_CANVAS_CTX = createDebugCanvas()
	}
}

export function updateGraphCurvesUI(name) {
	if (!get(graphConfig.curves, name)) {
		set(graphConfig.curves, name, { enabled: true, globaly: true })
		// this set
		visitObject(graphConfig.curves, (key, member) => {
			if (typeof member === 'object') {
				member.enabled = true
			}
		}, console, true)
	}
}

export function debugDrawText(s: string, c: string = 'white') {
	if (!DEBUG_CANVAS_CTX) {
		DEBUG_CANVAS_CTX = createDebugCanvas()
	}
	const ctx = DEBUG_CANVAS_CTX
	ctx.fillStyle = c
	ctx.font = '50px Arial'
	ctx.fillText(s, 0, ctx.canvas.height - 30)
	ctx.strokeText(s, 0, ctx.canvas.height - 30)
}

export function drawGraph() {
	if (graphConfig.disabled) {
		destroyDebugCanvasIf()
		return
	}

	updateCreation()
	calcMaxRanges()

	const ctx = DEBUG_CANVAS_CTX
	ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
	ctx.globalAlpha = graphConfig.canvas.backAlpha
	ctx.fillStyle = graphConfig.canvas.backgroundColor
	ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height)
	ctx.globalAlpha = 1
	drawLegend()
	let i = 0
	const maxh = Math.max(...Array.from(curves.values()).map(v => Math.max(...v.map(v => v.y))))
	curves.forEach((curve, name) => {
		if (get(graphConfig.curves, name).enabled) {
			if (isEnabled(graphConfig.curves, name)) {
				ctx.lineWidth = graphConfig.lineWidth
				drawCurve(curve, colors[i % colors.length], maxh, name === 'tick')
			}
		}
		i++
	})
}

const ranges = {
	minx: 0,
	maxx: 0,
	miny: 0,
	maxy: 0,
}

function calcMaxRanges() {
	// const enabledCurves = new Map(
	// 	[...curves]
	// 		.filter(([k, v]) => isEnabled(curves, k))
	// );

	const curveValues = Array.from(curves.values())
	ranges.minx = Math.min(...curveValues.map(v => Math.min(...v.map(v => v.x))))
	ranges.maxx = Math.max(...curveValues.map(v => Math.max(...v.map(v => v.x))))
	ranges.miny = Math.min(...curveValues.map(v => Math.min(...v.map(v => v.y))))
	ranges.maxy = Math.max(...curveValues.map(v => Math.max(...v.map(v => v.y))))
	if (!graphConfig.pauseLogging) {
		graphConfig.view.minx = ranges.minx
		graphConfig.view.maxx = ranges.maxx
	}
}

function drawCurve(curve: VectorXY[], color: string, _maxy: number, drawTicks: boolean) {
	const maxy = ranges.maxy
	const ctx = DEBUG_CANVAS_CTX
	ctx.strokeStyle = color
	const canvasHeight = ctx.canvas.height
	const canvasWidth = ctx.canvas.width
	const my = canvasHeight / maxy
	if (drawTicks) {
		ctx.beginPath()
		for (let i = 0; i < curve.length; i++) {
			const x = mapToRange(curve[i].x, graphConfig.view.minx, graphConfig.view.maxx, 0, canvasWidth)
			ctx.moveTo(x, canvasHeight - 0 * my)
			ctx.lineTo(x, canvasHeight - curve[i].y * my)
		}
		ctx.stroke()
	} else {
		ctx.beginPath()
		ctx.moveTo(mapToRange(curve[0].x, graphConfig.view.minx, graphConfig.view.maxx, 0, canvasWidth), canvasHeight - curve[0].y * my)
		for (let i = 1; i < curve.length; i++) {
			ctx.lineTo(mapToRange(curve[i].x, graphConfig.view.minx, graphConfig.view.maxx, 0, canvasWidth), canvasHeight - curve[i].y * my)
		}
		ctx.stroke()
	}
}

function drawLegend() {
	const ctx = DEBUG_CANVAS_CTX
	const legendConfig = graphConfig.legend
	ctx.font = legendConfig.font
	let i = 0
	const s = legendConfig.ySpacing
	const shadowConfig = legendConfig.shadow
	const topShadowColor = shadowConfig.topColor
	const bottomShadowColor = shadowConfig.bottomColor

	for (const [key, curve] of curves.entries()) {
		const yValues = curve.map(v => v.y)
		const miny = Math.min(...yValues)
		const maxy = Math.max(...yValues)
		const cury = yValues[yValues.length - 1]
		const color = colors[i++ % colors.length]
		const tf = legendConfig.toFixed
		const msg = `${key} (${miny.toFixed(tf)}, ${maxy.toFixed(tf)}, ${cury.toFixed(tf)})`
		if (shadowConfig.enabled) {
			ctx.fillStyle = topShadowColor
			ctx.fillText(msg, 9, i * s - 1)
			ctx.fillStyle = bottomShadowColor
			ctx.fillText(msg, 11, i * s + 1)
		}
		ctx.fillStyle = color
		ctx.fillText(msg, 10, i * s)
	}
}

function mouseMove(e) {
	const values = Array.from(curves.values())
	const maxt = Math.max(...values.map(v => Math.max(...v.map(v => v.x))))
	const maxh = Math.max(...values.map(v => Math.max(...v.map(v => v.y))))
	const ctx = DEBUG_CANVAS_CTX
	const ay = ctx.canvas.height
	const my = maxh / ay
	const mx = maxt / ctx.canvas.width
	mousePos = {
		x: e.offsetX * mx, y: (ay - e.offsetY) * my
	}
}

function isEnabled(curves: {}, name: string) {
	const words = name.split('.')
	let partName = ''
	let enabled = true
	words.forEach(word => {
		partName += partName ? `.${word}` : word
		if (!get(curves, partName).enabled) {
			enabled = false
		}
	})
	return enabled
}
