import nengi from 'nengi'

class GenericErrorMessage {
	static protocol = {
		title: { type: nengi.String },
		description: { type: nengi.String, indexType: nengi.UInt16 },
	}

	title: string
	description: string[]

	constructor(title: string, description: string | string[]) {
		this.title = title
		this.description = typeof description === 'string' ? [description] : description
	}
}

export default GenericErrorMessage
