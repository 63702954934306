import nengi from 'nengi'

export default class RequestArrowPositionCommand {
	static protocol = {
		positionToGet: { type: nengi.Int4 },
	}

	positionToGet: number

	constructor(requesting) {
		this.positionToGet = requesting
	}
}
