

























































































































































































import { mapMutations, mapGetters, mapActions, mapState } from 'vuex'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import CheckBox from '../reusable-components/inputs/checkbox.vue'
import Range from '../reusable-components/inputs/range.vue'
import ArrowButton from '../reusable-components/buttons/arrow-button.vue'
import PlainTextButton from '../reusable-components/buttons/plain-text-button.vue'
import DropDown from '../reusable-components/inputs/dropdown.vue'
import { UI } from '../ui'

export default {
	name: 'GameSettings',
	components: {
		MainPanel,
		Range,
		CheckBox,
		ArrowButton,
		PlainTextButton,
		DropDown,
	},
	data() {
		return {
			generalActive: true,
			controllerActive: false,
			hardCodedLanguages: [{ id: 'en', language: 'English' }],
			hardCodedRarity: [
				{ id: 'common', name: 'Common' },
				{ id: 'uncommon', name: 'Uncommon' },
				{ id: 'rare', name: 'Rare' },
				{ id: 'epic', name: 'Epic' },
				{ id: 'legendary', name: 'Legendary' },
				{ id: 'astronomical', name: 'Astronomical' },
				{ id: 'chaos', name: 'Chaos' },
			],
			hardCodedRegions: [
				{ id: 'na', name: 'North America' },
				{ id: 'eu', name: 'Europe' },
				{ id: 'ap', name: 'Asia-Pacific' },
			],
			hideWeaponDrops: false,
			hideGeanDrops: false,
			hideAugmentnDrops: false,
			ShowItemNames: false,
		}
	},
	computed: {
		...mapGetters('user', ['userType', 'bgmVolume', 'sfxVolume', 'region', 'csp', 'timestamps', 'showNameplates']),
		...mapGetters('inGame', ['activePanel']),
		...mapGetters(['isFullScreen']),
		...mapState('ftueManager', ['flags']),
		hideSkipFTUEBtn() {
			return false //Object.values(this.flags).some((val) => val) //I don't know why this was added and the commit message does not help
		},
	},
	methods: {
		...mapActions('inGame', ['closeActivePanel']),
		...mapMutations('user', ['updateBGMVolume', 'updateSFXVolume', 'updateFullScreen', 'updateRegion', 'updateCsp', 'updateTimestamps', 'changeShowNameplates']),
		...mapActions('user', ['updateUserPreferences', 'logout']),
		...mapMutations(['toggleFullScreen']),
		...mapActions('ftueManager', ['clearAllFlags']),
		hideWeaponDropsCheckbox(val) {
			this.hideWeaponDrops = val
		},
		hideGearDropsCheckbox(val) {
			this.hideGeanDrops = val
		},
		hideAugmentnDropsCheckbox(val) {
			this.hideAugmentnDrops = val
		},
		ShowItemNamesCheckbox(val) {
			this.ShowItemNames = val
		},
		changeFullScreen() {
			const newFullScreenVal = !this.isFullScreen
			this.toggleFullScreen() // actually changing from fullscreen to windowed
			this.updateFullScreen(newFullScreenVal) //settings related
		},
		logOutOfSession() {
			UI.getInstance().emitAsyncEvent('genericYesNo/showMenu', {
				title: 'Session Log Out',
				description: 'Are you sure you would like to log out?',
				noButtonText: 'Stay Logged In',
				yesButtonText: 'Log Out',
				noButtonColor: 'default',
				yesButtonColor: 'destroy',
				yesCallback: () => {
					this.logout()
				},
			})
		},
	},
}
