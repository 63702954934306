<template>
	<MainPanel id="market" :on-close-btn-click="closeActivePanel" :show-gold-currency="false" :show-scale-currency="true" panel-identifier="market">
		<template #panel-content>
			<PaperdollQuickViewRow :items="itemsInContainer('paperdoll')"></PaperdollQuickViewRow>
			<ListItemContainer
				class="full-container"
				:template-identifier="'marketplace'"
				:style="{ height: '620px' }"
				:items="itemsInContainer('marketplaceItemListings')"
				:selectable="true"
				:tooltipable="true"
				:show-button="true"
				:disable-all-buttons="isStashFull"
				:is-marketplace-list="true"
				:disable-individual-btn-fn="itemIsLocked"
				:button-text="'Purchase'"
				:on-select-fn="selectItem"
				:selected-fn="itemIsSelected"
				:on-row-button-click-prop="tryBuyItem"
				:highlight-button-fn="() => {}"
				:buttonToolTipTextFn="stashFullTooltipText"
				:isMarketplaceList="true"
			></ListItemContainer>
			<ArrowButton button-text="My Listings!" button-icon-name="page" :disabled="false" :loading="false" :on-click="viewMyListings"></ArrowButton>
			<ArrowButton button-text="Refresh Listings" button-icon-name="refresh" :disabled="getLoadingInProgress" :loading="getLoadingInProgress" :on-click="fetchListings"></ArrowButton>
		</template>
	</MainPanel>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
import ArrowButton from '../reusable-components/buttons/arrow-button.vue'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import PaperdollQuickViewRow from '../reusable-components/item-containers/mini-paperdoll.vue'
import ListItemContainer from '../reusable-components/item-containers/list-item-container.vue'
import { canEquipItemOfLevel, maxEquipableLevel } from '../../engine/shared/game-data/player'
import { PrizeSubType } from '../../loot/shared/prize-sub-type'
import { stashFullTooltipText } from '../reusable-components/v-tooltip/v-tooltip-functions'
import { FilterType } from '../state/cosmetics-ui-state'

const scaleImg = require('../reusable-components/item-containers/assets/*.png')['prism-scale']

export default {
	name: 'MarketplaceUpdated',
	components: {
		MainPanel,
		PaperdollQuickViewRow,
		ListItemContainer,
		ArrowButton,
	},
	computed: {
		...mapState('user', ['userType']),
		...mapGetters('itemLocks', ['itemIsLocked']),
		...mapState('hud', ['progressionLevel']),
		...mapGetters('marketplaceUpdated', ['listingsMetadata', 'getLoadingInProgress', 'selectedListingItem', 'getItemPriceFromMetadataByItemId']),
		...mapGetters('itemContainers', ['itemsInContainer', 'itemDetails', 'isStashFull']),
		...mapState('cosmetics', ['ownedCosmetics']),
	},
	methods: {
		stashFullTooltipText,
		itemIsSelected(itemId) {
			return itemId === this.selectedListingItem
		},
		...mapActions('inGame', ['closeActivePanel']),
		...mapActions('marketplaceUpdated', ['fetchListings', 'toggleSelectMarketplaceListingItem', 'buyItem']),
		...mapActions('genericYesNo', ['showMenu']),
		...mapMutations('inGame', ['setActivePanel']),
		selectItem(itemId) {
			this.toggleSelectMarketplaceListingItem({itemId})
		},
		viewMyListings() {
			if(this.userType === 'registered') {
				this.setActivePanel('userMarketListing')
			} else {
				this.setActivePanel('notLoggedIn')
			}
		},
		tryBuyItem(itemId) {
			if(this.userType === 'anonymous') {
				this.setActivePanel('notLoggedIn')
				return
			}

			const item = this.itemDetails('marketplaceItemListings', itemId)
			const price = this.getItemPriceFromMetadataByItemId(itemId)
			this.showMenu({
				title: 'Confirm',
				description: `Please spend your money responsibly.  Purchase ${item.name} for ${price} <img src="${scaleImg}" style="width:34.6px; height:41.3px; position:relative; top:7px;"/> ?`,
				noButtonText: 'Hold On',
				yesButtonText: 'Purchase',
				yesCallback: () => {
					const maxEqLevel = maxEquipableLevel(this.progressionLevel)
					if (item.itemSubType === PrizeSubType.PetEgg) {
						const petType = item.extraData.petSubType
						if (this.ownedCosmetics.some((c) => c.cosmeticType === FilterType.PET_SKIN && c.identifier === petType)) {
							this.showMenu({
								title: 'Pet already owned',
								description: 'This pet is already in your collection. Are you sure you want to purchase it?',
								noButtonText: 'Cancel',
								yesButtonText: 'Buy Anyway',
								yesCallback: () => {
									this.buyItem(itemId)
								},
							})
						} else {
							this.buyItem(itemId)
						}
					} else if (item.itemSubType === PrizeSubType.WeaponSkin) {
						const weaponSkinType = item.extraData.weaponSkinType
						if (this.ownedCosmetics.some((c) => (c.cosmeticType === FilterType.ALL_WEAPONS) && c.identifier === weaponSkinType)) {
							this.showMenu({
								title: 'Weapon skin already owned',
								description: 'This weapon skin is already in your collection. Are you sure you want to purchase it?',
								noButtonText: 'Cancel',
								yesButtonText: 'Buy Anyway',
								yesCallback: () => {
									this.buyItem(itemId)
								},
							})
						} else {
							this.buyItem(itemId)
						}
					} else if (!canEquipItemOfLevel(this.progressionLevel, item.level)) {
						const description = [`You must unlock level ${item.level} to equip this. You have currently unlocked level ${maxEqLevel}.`, `Kill new bosses to unlock more loot.`]

						this.showMenu({
							title: 'You cannot equip this loot',
							description: description,
							noButtonText: 'Cancel',
							yesButtonText: 'Buy Anyway',
							yesCallback: () => {
								this.buyItem(itemId)
							},
						})
					} else {
						this.buyItem(itemId)
					}
				}
			})
		},
	},
}
</script>

<style lang="less" scoped>
@import '../global/fonts/text.less';
@import '../global/bg-gradients.less';

#market {
	width: 700px;
	.full-container {
		.bg-gradient();
	}
	::v-deep .currency {
		right: -25px;
	}
}
</style>
