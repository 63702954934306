import nengi from 'nengi'
import { uuid } from '../../utils/primitive-types'

class RemoveItemFromWormMailCommand {
	static protocol = {
		itemId: nengi.String,
		wormId: nengi.Number,
	}
	itemId: uuid
	wormId: number
	constructor(itemId: uuid, wormId: number) {
		this.itemId = itemId
		this.wormId = wormId
	}
}

export default RemoveItemFromWormMailCommand
