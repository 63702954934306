




export default {
	name: 'FunctionProperty',
	props: {
		rootObject: {
			type: Object,
			required: true,
		},
		propertySeekDotNotation: {
			type: String,
			required: true,
		},
		propertyKey: {
			type: String,
			required: true,
		},
		propertyValue: {
			type: Function,
			required: true,
		},
		propertyChangedFn: {
			type: Function,
			default: undefined,
		},
	},
	data() {
		return {
			liveValue: this.propertyValue,
		}
	},
	methods: {
		onClick() {
			if (this.liveValue) {
				this.liveValue()
			}
			//onPropertyChangedHandler(this.rootObject, this.propertySeekDotNotation, this.liveValue)
			this.propertyChangedFn(this.rootObject, this.propertySeekDotNotation, this.liveValue)
		},
	},
}
