import { cloneDeep, forIn } from "lodash"
import { Vector } from "sat"
import { ColliderType } from "../../collision/shared/colliders"
import { debugConfig } from "../../engine/client/debug-config"
import { getAttachment, getSpineAsset } from "../../utils/pixi-util"
import { BiomeId, getPropConfig, makePropId, PropConfig, propConfigs, PropName } from "../shared/prop-configs"

function logIfEnabled(message?: any, ...optionalParams: any[]): void {
	if (debugConfig.props.logging) {
		console.log(message, optionalParams)
	}
}

export function addColliderConfigsToLookupCollidersClient() {
	logIfEnabled(`########### addColliderConfigsToLookupColliders ###########`)
	forIn(propConfigs, (biome, biomeId: string) => {
		logIfEnabled(`### addColliderConfigsToLookupColliders ${biomeId} ###`)
		forIn(biome, (propConfig: PropConfig, propName: string) => {
			logIfEnabled(`# addColliderConfigsToLookupColliders ${biomeId}/${propName} #`)
			propConfig.colliders?.forEach((colliderConfig) => {
				if (colliderConfig.type === ColliderType.Lookup) {
					propConfig.colliders.remove(colliderConfig)
					addColliderConfigsByRefClient(biomeId, propName, propConfig)
				}
			})
		})
	})
	logIfEnabled(`################################################`)
}

// Iterates all slots/attachments in biomeId/propName, and uses attachmentName to lookup the same named ColliderConfigs in PropConfigs
// ie: addColliderConfigsByRefClient('forest', 'high-blocker-01-var01')
//  biomes/forest/props/high-blocker-01-var01.json
//   has attachment: high-blocker-01
//  so we:
//	 getPropConfig('forest', 'high-blocker-01')
//   clone all colliderConfigs of high-blocker-01
//   and add those clones to the colliderConfigs of 'forest/high-blocker-01-var01'
function addColliderConfigsByRefClient(biomeId: BiomeId, propName: PropName, propConfig: PropConfig) {
	const propId = makePropId(biomeId, propName)
	if (debugConfig.assets.logEachAsset) {
		console.log('addColliderConfigsByRefClient:', propId)
	}
	const asset = getSpineAsset(propId)
	const spineData = asset.spineData as PIXI.spine.core.SkeletonData

	const slots = spineData.slots

	slots.forEach((slot) => {
		const name = slot.name
		const attachment = getAttachment(spineData, slot)
		logIfEnabled(`${propName} has attachment: ${name}`, attachment)
		const offset = new Vector(attachment.x, -attachment.y) // note: pixi and world have y flipped
		const otherPropConfig = getPropConfig(biomeId, slot.attachmentName)
		if (!otherPropConfig) {
			console.warn(`prop ${makePropId(biomeId, propName)} requires ${makePropId(biomeId, slot.attachmentName)} propConfig which does not exist`)
			return
		}
		otherPropConfig.colliders?.forEach((colliderConfig) => {
			const clone = cloneDeep(colliderConfig)
			clone.position[0] += offset.x
			clone.position[1] += offset.y
			propConfig.colliders.push(clone)
		})
	})
}