import nengi from 'nengi'

export default class PlayerMovingToBiomeCommand {
	static protocol = {
		biomeIdx: { type: nengi.Number },
	}

	biomeIdx: number

	constructor(biomeIdx: number) {
		this.biomeIdx = biomeIdx
	}
}
