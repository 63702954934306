




















import { getComponentByPropType } from './helpers'

import ArrayProperty from './array-property.vue'
import StringProperty from './string-property.vue'
import NumberProperty from './number-property.vue'
import FloatProperty from './float-property.vue'
import BooleanProperty from './boolean-property.vue'
import UndefinedOrNullProperty from './undefined-or-null-property.vue'
import RecursiveObjectTree from './recursive-object-tree.vue'
import FunctionProperty from './function-property.vue'
import SelectProperty from './select-property.vue'
import DevToolsManager from '../../dev-tools/dev-tools-manager'

export default {
	name: 'RecursiveObjectTree',
	components: {
		ArrayProperty,
		StringProperty,
		NumberProperty,
		FloatProperty,
		BooleanProperty,
		UndefinedOrNullProperty,
		RecursiveObjectTree,
		FunctionProperty,
		SelectProperty,
	},
	props: {
		rootObject: {
			type: Object,
			required: true,
		},
		depth: {
			type: Number,
			default: 0,
		},
		propertySeekDotNotation: {
			type: String,
			default: '',
		},
		propertyKey: {
			type: String,
			required: true,
		},
		// eslint-disable-next-line vue/require-prop-types
		propertyValue: {
			default: this.rootObject,
		},
		propertyChangedFn: {
			type: Function,
			default: undefined,
		},
	},
	data() {
		return {
			isExpanded: this.depth < 3,
		}
	},
	computed: {
		filteredPropertyValue: function() {
			const obj = {}
			//console.log('filteredPropertyValue:', this.propertyValue)
			for (let key in this.propertyValue) {
				//console.log(`  ${key}`)
				if (this.propertyValue.hasOwnProperty(key)) {
					obj[key] = this.propertyValue[key]
				}
			}
			return obj
		},
	},
	methods: {
		getComponentByPropType,
		toggleExpansion() {
			this.isExpanded = !this.isExpanded
		},
		keyIsRegisteredEnum(key) {
			return DevToolsManager.getInstance().propertyEnumMap.has(key)
		},
		getPropertyOptionsFromEnumData(propertyKey: string) {
			const map = DevToolsManager.getInstance().propertyEnumMap
			let data
			if (map.has(propertyKey)) {
				data = map.get(propertyKey)
			} else {
				data = DevToolsManager.getInstance().enumData[propertyKey]
			}
			return data
		},
	},
}
