import nengi from 'nengi'
import { VectorXY } from '../../utils/math'
import { nengiId, radians } from '../../utils/primitive-types'
import { BeamConfig } from './projectile-types'

export class BeamCreateUpdateCommand {
	static protocol = {
		projectileId: { type: nengi.UInt32 },
		beamOriginX: { type: nengi.Number },
		beamOriginY: { type: nengi.Number },
		aimAngle: { type: nengi.RotationFloat32 },
		beamConfig: { type: nengi.String },
		width: { type: nengi.Number },
	}

	projectileId: number
	beamOriginX: nengiId
	beamOriginY: nengiId
	aimAngle: radians
	beamConfig: string
	width: number

	constructor(projectileId: number, beamOrigin: VectorXY, aimAngle: radians, beamConfig: BeamConfig, width) {
		this.projectileId = projectileId
		this.beamOriginX = beamOrigin.x
		this.beamOriginY = beamOrigin.y
		this.aimAngle = aimAngle
		this.beamConfig = JSON.stringify(beamConfig)
		this.width = width
	}
}
