
























































import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import ListItemContainer from '../reusable-components/item-containers/list-item-container.vue'
import FurnaceButtonedGridItemContainer from '../reusable-components/item-containers/furnace-buttoned-grid-item-container.vue'
import TextButton from '../reusable-components/buttons/text-button.vue'
import ItemType from '../../loot/shared/item-type'
import { isBiomeKey } from '../state/rarity-helper'
import { to4DigitTruncatedString } from '../../utils/math'

const smeltableFilter = (item) => {
	return isBiomeKey(item) === false && !item.stackAmount
}

export default {
	name: 'FurnaceUpdate',
	components: {
		MainPanel,
		ListItemContainer,
		FurnaceButtonedGridItemContainer,
		TextButton,
	},
	computed: {
		smeltableItems() {
			return this.itemsInContainer('stash').filter(smeltableFilter)
		},
		...mapGetters('itemContainers', ['itemsInContainer', 'itemDetails']),
		...mapGetters('furnace', ['hasFreeFurnace', 'itemsInFurnace', 'countOfOccupiedFurnaceSlots', 'hasNonSmeltingSlot']),
		...mapState('furnace', ['selectedFurnaceItem', 'coinsClaimable', 'isCollecting', 'canCollect']),
	},
	methods: {
		...mapActions('inGame', ['closeActivePanel']),
		...mapMutations('inGame', ['setActivePanel']),
		...mapActions('furnace', ['startSmeltingItem', 'collectCurrencyRewards', 'selectFurnaceItem', 'getCollectableCoins']),
		...mapMutations('furnace', ['addedItemToTotalSmelting', 'removeItemFromTotalSmelting']),
		to4DigitTruncatedString,
		furnaceButtonClicked(item) {
			//@TODO2 remove item that's not done smelting?
			this.removeItemFromTotalSmelting(item)
			this.collectCurrencyRewards(item)
		},
		stashItemSelected(itemId) {
			this.startSmeltingItem({ itemId, fromContainer: 'stash' })
		},
		shouldDisableTrySmelt(itemId) {
			const itemDetails = this.itemDetails('stash', itemId)
			return itemDetails.itemTypeEnum === ItemType.Prize
		},
		isItemSelected(itemId) {
			return itemId === this.selectedFurnaceItem
		},
	},
}
