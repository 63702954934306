import { partial, every } from 'lodash'
import Time from '../engine/shared/time'
import { nonSensitiveEnvVarsMapping } from './env'

const logLevelsNumberHierarchy = {
	none: 5,
	debug: 4,
	info: 3,
	warn: 2,
	error: 1,
}

const logLevelPrefixes = {
	debug: '%c [DEBUG] ',
	info: '%c [INFO]  ',
	warn: '%c [WARN]  ',
	error: '%c [ERROR] ',
}
const clientLogLevelCssPrefixes = {
	debug: '',
	info: 'color: green;',
	warn: 'color: orange;',
	error: 'color: red;',
}

const log = (messageLogLevel, ...data) => {
	if (data.length === 0) {
		return
	}

	const messageLogLevelNumber = logLevelsNumberHierarchy[messageLogLevel]
	const messageLogLevelPrefix = logLevelPrefixes[messageLogLevel]
	const messageGameFrame = ` [${Time.currentGameFrameNumber}]`
	const limitLogLevelNumber = logLevelsNumberHierarchy[nonSensitiveEnvVarsMapping[process.env.NODE_ENV].LOG_LEVEL]
	const messageLogLevelColor = clientLogLevelCssPrefixes[messageLogLevel]
	const messagePrefix = messageLogLevelPrefix + messageGameFrame

	if (limitLogLevelNumber === 5) {
		return
	}
	if (messageLogLevelNumber > limitLogLevelNumber) {
		return
	}

	if (
		every(data, (logDatum) => {
			return typeof logDatum === 'string' || typeof logDatum === 'number' || typeof logDatum === 'undefined'
		})
	) {
		let concatenated = ``
		for (const logDatum of data) {
			concatenated = concatenated.concat(` ${logDatum}`)
		}
		console.log(messagePrefix + concatenated, messageLogLevelColor)
	} else {
		for (const logDatum of data) {
			if (typeof logDatum === 'string' || typeof logDatum === 'number' || typeof logDatum === 'undefined') {
				console.log(messagePrefix + logDatum, messageLogLevelColor)
			} else {
				try {
					const formatted = JSON.stringify(logDatum)
					console.log(messagePrefix + formatted, messageLogLevelColor)
				} catch (e) {
					console.log(messagePrefix, messageLogLevelColor)
					console.log(logDatum)
				}
			}
		}
	}
}

export default {
	debug: partial(log, 'debug'),
	info: partial(log, 'info'),
	warn: partial(log, 'warn'),
	error: partial(log, 'error'),
}
