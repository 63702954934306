
























import { mapMutations } from 'vuex'
import { weaponSkinSubTypeToThemeString } from '../../../loot/shared/weapon-skin-sub-type'
import { SKIN_SLOT, SKIN_SLOT_TO_PREFIX } from '../../../player/shared/set-skin.shared'
import { FilterType, getPetStyleForSpriteSheet, getStyleForSpriteSheet } from '../../state/cosmetics-ui-state'
import { getClassNameFromItem } from '../inventory-item/inventory-item-helpers'

export default {
	name: 'SkinIventoryItem',
	props: {
		item: {
			type: Object,
			required: true,
		},
		showSlot: {
			type: Boolean,
			required: false,
			default: true,
		},
		onClickFn: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default on-click handler for the <SkinIventoryItem /> component has been called. Did you forget to pass an :on-click handler to the component?')
			},
		},
		selected: {
			type: Boolean,
			required: false,
			default: false,
		},
		selectable: {
			type: Boolean,
			required: false,
			default: true,
		},
		tooltipable: {
			type: Boolean,
			required: false,
			default: true,
		},
		overrideSkinHeight: {
			type: Number,
			required: false,
			default: 0
		},
		overrideSkinWidth: {
			type: Number,
			required: false,
			default: 0
		}
	},
	data() {
		let returnData = {
			filterType: FilterType,
			style: null,
		}

		return returnData
	},
	computed: {
		getClassNameFromItem() {
			return getClassNameFromItem(this.item)
		},
		sheetClass() {
			switch(this.item.skinSlot) {
				case SKIN_SLOT.PET_MAIN:
					return 'pet-skin-sheet'
				case SKIN_SLOT.PLAYER_MAIN:
					return 'player-main-sheet'
				case SKIN_SLOT.PLAYER_FACE:
					return 'face-cosmetics-sheet'
				case SKIN_SLOT.PLAYER_BACK:
					return 'back-cosmetics-sheet'
				case SKIN_SLOT.PLAYER_FOOTPRINT:
					return 'pfx-trails-sheet'
				case SKIN_SLOT.WEAPON_ARCANE:
				case SKIN_SLOT.WEAPON_CROSSBOW:
				case SKIN_SLOT.WEAPON_SCYTHE:
				case SKIN_SLOT.WEAPON_STAFF:
				case SKIN_SLOT.WEAPON_SWORD:
				case SKIN_SLOT.WEAPON_WAND:
					return 'weapon-skins-sheet'
			}

			return ''
		},
		getStyle() {
			const width = this.overrideSkinWidth ? this.overrideSkinWidth : 85
			const height = this.overrideSkinHeight ? this.overrideSkinHeight : 111

			switch(this.item.skinSlot) {
				case SKIN_SLOT.PET_MAIN:
					return getPetStyleForSpriteSheet(this.item.identifier, width, height)
				case SKIN_SLOT.PLAYER_MAIN:
					return getStyleForSpriteSheet(this.item.identifier, width*1.25, height*1.25, this.item.skinSlot)
				case SKIN_SLOT.PLAYER_BACK:
					return getStyleForSpriteSheet(this.item.identifier, width, height, this.item.skinSlot)
				case SKIN_SLOT.PLAYER_FACE:
					return getStyleForSpriteSheet(this.item.identifier, width, height, this.item.skinSlot)
				case SKIN_SLOT.PLAYER_FOOTPRINT:
					return getStyleForSpriteSheet(this.item.identifier, width, height, this.item.skinSlot)
				case SKIN_SLOT.WEAPON_ARCANE:
				case SKIN_SLOT.WEAPON_CROSSBOW:
				case SKIN_SLOT.WEAPON_SCYTHE:
				case SKIN_SLOT.WEAPON_STAFF:
				case SKIN_SLOT.WEAPON_SWORD:
				case SKIN_SLOT.WEAPON_WAND:
					const id = this.item.identifier

					const weaponTheme = weaponSkinSubTypeToThemeString(id)
					let weaponName = this.item.skinSlot === SKIN_SLOT.WEAPON_ARCANE ? 'arcane-focus-' : SKIN_SLOT_TO_PREFIX[this.item.skinSlot]
					const name = `${weaponName}${weaponTheme}`
					return getStyleForSpriteSheet(name, width, height, this.item.skinSlot)
			}

			return 'garbage-bad-type'
		},
	},
	methods: {
		...mapMutations('UIScale', ['showTooltip', 'updateToolTipPosition']),

		onClick(itemId) {
			if (this.selectable) {
				this.onClickFn(itemId)
			}
		},
		onMouseEnter(event) {
			if (this.tooltipable === false) return
			this.updateToolTipPosition(event)
			this.showTooltip(this.item)
		},
		onMouseLeave() {
			if (this.tooltipable === false) return
			this.showTooltip(null)
		},
	},
}
