


























































import EmptyInventorySlot from '../inventory-item/empty-inventory-slot.vue'
import InventoryItem from '../inventory-item/inventory-item.vue'
import TextButton from '../buttons/text-button.vue'
import { mapGetters, mapMutations, mapState } from 'vuex'
import { isFurnaceDoneSmelting } from '../../state/furnace-ui-state'
import { toCountdownString, to3DigitTruncatedString } from '../../../utils/math'

export default {
	name: 'FurnaceButtonedGridItemContainer',
	components: {
		InventoryItem,
		EmptyInventorySlot,
		TextButton,
	},
	props: {
		items: {
			type: Array,
			required: true,
			default: () => {
				console.warn('Currently using the default array. Ensure the <FurnaceButtonedGridItemContainer /> component has a custom attribute items')
				return []
			},
		},
		showSlot: {
			type: Boolean,
			required: false,
			default: true,
		},
		selectable: {
			type: Boolean,
			required: false,
			default: true,
		},
		onSelectFn: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default on-select fn handler for the <FurnaceButtonedGridItemContainer /> component has been called. Did you forget to pass an :on-click-fn handler to the component?')
			},
		},
		selectedFn: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default selected fn handler for the <FurnaceButtonedGridItemContainer /> component has been called. Did you forget to pass an :selected-fn handler to the component?')
			},
		},
		tooltipable: {
			type: Boolean,
			required: false,
			default: true,
		},
		maxItemSlotsOwned: {
			type: Number,
			required: true,
			default: () => {
				console.warn('Currently using the default max item slots. Ensure the <FurnaceButtonedGridItemContainer /> component has a custom attribute max-item-count')
				return 10
			},
		},
		onButtonClickFn: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default on-button-click-fn handler for the <FurnaceButtonedGridItemContainer /> component has been called. Did you forget to pass an :on-button-click handler to the component?')
			},
		},
		onPremiumButtonClickFn: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default on-premium-button-click-fn handler for the <FurnaceButtonedGridItemContainer /> component has been called. Did you forget to pass an :on-premium-button-click-fn handler to the component?')
			},
		},
		buttonLabel: {
			type: String,
			required: true,
		},
		premiumButtonLabel: {
			type: String,
			required: true,
		},
		showSmeltingProgressBar: {
			type: Boolean,
			required: false,
			default: false,
		},
		premiumSlot: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data() {
		return {
			howManyPurchaseSlotsToShow: 0,
		}
	},
	beforeDestroy() {
		clearInterval(this.timerInterval)
	},
	computed: {
		...mapGetters('itemLocks', ['itemIsLocked']),
		...mapState('time', ['nowMoment']),
		...mapGetters('furnace', ['itemsInFurnace', 'unixDiff', 'totalItemsInSmelter', 'lockAddItemToNotifierFn', 'lockItems']),
	},
	methods: {
		...mapMutations('furnace', ['addedItemToTotalSmelting', 'removeItemFromTotalSmelting']),
		to3DigitTruncatedString,
		isSlotSmelting(furnaceItem) {
			const smeltingDetails = furnaceItem.smeltingDetails
			const isSmeltingCompleted = isFurnaceDoneSmelting(smeltingDetails, this.nowMoment)
			const itemsInSmelter = this.totalItemsInSmelter
			const doesSmeltingItemExist = itemsInSmelter.includes(furnaceItem.id)

			if (isSmeltingCompleted && !doesSmeltingItemExist && !furnaceItem.smeltingDetails.isCollecting) {
				this.AddItemToFurnaceNotifier(furnaceItem)
			}
			return !isFurnaceDoneSmelting(smeltingDetails, this.nowMoment)
		},
		getTimeRemaining(furnaceItem) {
			const smeltingDetails = furnaceItem.smeltingDetails
			const totalSecs = smeltingDetails.completionTime.diff(this.nowMoment, 'second')

			return toCountdownString(totalSecs, false)
		},
		getReturnCurrency(furnaceItem) {
			const smeltingDetails = furnaceItem.smeltingDetails
			let returnVal = smeltingDetails.returnValue - smeltingDetails.claimedCoins
			if (returnVal < 0) {
				return 0
			}
			return to3DigitTruncatedString(returnVal)
		},
		getBonusReturnCurrency(furnaceItem) {
			const smeltingDetails = furnaceItem.smeltingDetails
			let remainingNormalCoins = smeltingDetails.returnValue - smeltingDetails.claimedCoins
			if (remainingNormalCoins < 0) {
				return smeltingDetails.bonusReturnValue + remainingNormalCoins //remainingNormalCoins is negative, so it subtracts
			} else {
				return smeltingDetails.bonusReturnValue
			}
		},
		isCollecting(furnaceItem) {
			const smeltingDetails = furnaceItem.smeltingDetails
			return smeltingDetails.isCollecting
		},
		getInverseSmeltProgress(furnaceItem) {
			const smeltingDetails = furnaceItem.smeltingDetails
			const endMoment = smeltingDetails.completionTime
			const originalSecs = smeltingDetails.createdAt.diff(endMoment, 'second', true)
			const secsRemaining = this.nowMoment.diff(endMoment, 'second', true)

			return Math.max(100 - (secsRemaining / originalSecs) * 100, 0)
		},
		AddItemToFurnaceNotifier(furnaceItem) {
			this.addedItemToTotalSmelting(furnaceItem.id)
		},
	},
}
