import { BuffIdentifier } from '../../buffs/shared/buff.shared'
import { AnyColliderConfig, ColliderTraits } from '../../collision/shared/colliders'
import { AnimationTrack } from '../../models-animations/shared/animation-track'
import { ProjectileConfig } from '../../projectiles/shared/projectile-types'
import { gameUnits, gameUnitsPerSecond, healthPercentage, level, timeInSeconds, timeInServerTicks } from '../../utils/primitive-types'
import { Vector } from 'sat'
import { WorldDifficulty } from '../../engine/shared/game-data/world-difficulty'

type FireProjectileConfig = ProjectileConfig
type timing = number
type weight = number

interface SpawnEnemyConfig {
	identifier: string
	quantity: number
	limit: number
	dropXP: boolean
	dropLoot: boolean
	spawnInTime?: number
}

interface GroundHazardRules {
	spineAsset: string
	projectileSecondsOffset: timeInSeconds
	pfx: string
	pfxScale?: number
	pfxSecondsOffset: timeInSeconds
	quantity: number
	limit: number
}

interface MoveRules {
	movementSpeed: gameUnitsPerSecond
	minDistance: gameUnits
	maxDistance: gameUnits
	ignoreIfInside?: boolean
	destinationPfx?: string
	pfxScale?: number
	pfxLingerDuration?: number
}

interface HealRules {
	damageThresholdAsPercentOfMax: number
}

// could use this for similar state toggles in the future, if we need them
interface ToggleStateRules {
	visible?: boolean
}

interface GroundHazardMultitargetRules {
	spineAsset: string
	projectileSecondsOffset: timeInSeconds
	pfx: string
	pfxScale?: number
	pfxSecondsOffset: timeInSeconds
	baseQuantity: number
	perTargetQuantity: number
	totalTime: number
	accuratePercent: number
	maxStray: number
}

interface SpawnEnemiesWhileBuffedRules {
	identifiers: string[]
	quantity: number
	dropXP: boolean
	dropLoot: boolean
	minSpawnRadius?: number
	maxSpawnRadius: number
	spawnTime?: number
	delayBetweenSpawns: number
	buffToApply: BuffIdentifier
	buffStacksToApply?: number
	customAngleFormula?: () => number,
	customRadiusFormula?: () => number,
}

interface AOEProjectileAroundSelfRules {
	pfx: string
	pfxScale: number
}

interface SetColliderConfig {
	enabled?: boolean
	traits?: ColliderTraits
}

type AbilityOrTiming = Ability | timing

export type AbilityOptionallyWithWeightOrTiming =
	| [
		timing | weight,
		AbilityOrTiming,
		AbilityOrTiming?,
		AbilityOrTiming?,
		AbilityOrTiming?,
		AbilityOrTiming?,
		AbilityOrTiming?,
		AbilityOrTiming?,
		AbilityOrTiming?,
		AbilityOrTiming?,
		AbilityOrTiming?,
		AbilityOrTiming?,
		AbilityOrTiming?,
		AbilityOrTiming?,
		AbilityOrTiming?,
		AbilityOrTiming?,
		AbilityOrTiming?,
		AbilityOrTiming?,
		AbilityOrTiming?,
		AbilityOrTiming?,
	]
	| Ability

export interface NamedColliders {
	name: string
	colliders: AnyColliderConfig[]
}

export interface ActionDrivenEnemyData {
	engagementColliders?: AnyColliderConfig[]
	targetColliders?: NamedColliders[]
	unengagedActions?: Action[]
	actions: Action[]
	resetOnDisengage?: boolean
}

export enum AbilitySelectionStyles {
	SINGLE,
	WEIGHTED_RANDOM,
	SEQUENCE,
}

export interface AbilityBase {
	debugName?: string
	/** WAIT - FIRE_PROJECTILE - INSTANTLY_APPLY_EFFECT - SPAWN_ENEMY - SPAWN_GROUND_HAZARD - MOVE_TOWARDS_LOCATION - DISABLE_COLLIDERS - ENABLE_COLLIDERS, */
	abilityType: AbilityType
	durationInGameTicks: number | (() => number)
	/** MYSELF - RESULTS_OF_THE_CRITERIA_CHECK - ALL_NEARBY_PLAYERS - ALL_NEARBY_ENEMIES - CLOSEST_PLAYER - RANDOM_POSITIONS_NEARBY - RANDOM_POSITIONS_AROUND_RESULTS_OF_THE_CRITERIA_CHECK - */
	validTargets: AbilityTargets
	/** CENTER_OF_ENCLOSING_CIRCLE - FIRST - RANDOM - CLOSEST - FARTHEST - LOWEST_HEALTH - ALL - */
	validTargetSelection: AbilityTargetSelectionStyle
	playsAnimation?: false | AnimationTrack
	cancelAnimation?: false | AnimationTrack
	shakeCamera?: number
	shapeNames?: string | string[]
	criteriaName?: string
	setPropertyName?: string
	setPropertyValue?: any
	attackOffset?: Vector
	ignoreAngleOnWeaponOffset?: boolean
}

export interface AbilityWait extends AbilityBase {
	abilityType: AbilityType.WAIT
}

export interface AbilityFireProjectile extends AbilityBase {
	abilityType: AbilityType.FIRE_PROJECTILE
	projectileConfig: FireProjectileConfig
}

export interface AbilityInstantlyApplyEffect extends AbilityBase {
	abilityType: AbilityType.INSTANTLY_APPLY_EFFECT
	buffToApply: BuffIdentifier
	buffStacksToApply?: number
}

export interface AbilitySpawnEnemy extends AbilityBase {
	abilityType: AbilityType.SPAWN_ENEMY
	/** identifier - quantity */
	spawningRules: SpawnEnemyConfig
}

export interface AbilitySpawnGroundHazard extends AbilityBase {
	abilityType: AbilityType.SPAWN_GROUND_HAZARD
	groundHazardRules: GroundHazardRules
	projectileConfig: FireProjectileConfig
}

export interface AbilityMoveTowardsLocation extends AbilityBase {
	abilityType: AbilityType.MOVE_TOWARDS_LOCATION
	/** identifier - quantity */
	moveRules: MoveRules
}

export interface AbilitySetColliders extends AbilityBase {
	abilityType: AbilityType.SET_COLLIDERS
	colliderInfo?: SetColliderConfig
}

export interface AbilityBuffUntilDamageReceivedThreshold extends AbilityBase {
	abilityType: AbilityType.BUFF_UNTIL_DAMAGE_RECEIVED_THRESHOLD
	healRules: HealRules
	buffToApply: BuffIdentifier
	buffStacksToApply?: number
}

export interface AbilityWaitForCooldown extends AbilityBase {
	abilityType: AbilityType.WAIT_FOR_COOLDOWN
	timeAlready: timeInSeconds
	baseDuration: timeInServerTicks
}

export interface AbilityToggleState extends AbilityBase {
	abilityType: AbilityType.TOGGLE_STATE
	toggleStateRules: ToggleStateRules
}

export interface AbilitySpawnGroundHazardMultitarget extends AbilityBase {
	abilityType: AbilityType.SPAWN_GROUND_HAZARD_MULTITARGET
	groundHazardMultitargetRules: GroundHazardMultitargetRules
	projectileConfig: FireProjectileConfig
}

export interface AbilitySpawnEnemiesWhileBuffed extends AbilityBase {
	abilityType: AbilityType.SPAWN_ENEMIES_WHILE_BUFFED
	spawnEnemiesWhileBuffedRules: SpawnEnemiesWhileBuffedRules
}

export interface AbilityAOEProjectileAroundSelf extends AbilityBase {
	abilityType: AbilityType.AOE_PROJECTILE_AROUND_SELF
	projectileConfig: FireProjectileConfig
	aoeProjectileAroundSelfRules: AOEProjectileAroundSelfRules
}

export type Ability =
	| AbilityWait
	| AbilityWaitForCooldown
	| AbilityFireProjectile
	| AbilityInstantlyApplyEffect
	| AbilitySpawnEnemy
	| AbilityMoveTowardsLocation
	| AbilitySpawnGroundHazard
	| AbilitySetColliders
	| AbilityBuffUntilDamageReceivedThreshold
	| AbilityToggleState
	| AbilitySpawnGroundHazardMultitarget
	| AbilitySpawnEnemiesWhileBuffed
	| AbilityAOEProjectileAroundSelf

export interface ActionAbilities {
	/** How should I choose which of these abilities to do? Either RANDOM -- picks a random one (pure random), or WEIGHTED -- picks a weighted random, or SEQUENCE -- executes them all in sequence */
	abilitySelectionStyle: AbilitySelectionStyles
	/** A list of abilities that can occur if this action is chosen
	 */
	abilityOptions: AbilityOptionallyWithWeightOrTiming[]
}

export enum AbilityType {
	WAIT, // performs no ability
	FIRE_PROJECTILE,
	INSTANTLY_APPLY_EFFECT,
	SPAWN_ENEMY,
	SPAWN_GROUND_HAZARD,
	MOVE_TOWARDS_LOCATION,
	SET_COLLIDERS,
	BUFF_UNTIL_DAMAGE_RECEIVED_THRESHOLD,
	WAIT_FOR_COOLDOWN,
	TOGGLE_STATE,
	SPAWN_GROUND_HAZARD_MULTITARGET,
	SPAWN_ENEMIES_WHILE_BUFFED,
	AOE_PROJECTILE_AROUND_SELF,
}

export enum AbilityTargets {
	MYSELF,
	RESULTS_OF_THE_CRITERIA_CHECK,
	ALL_NEARBY_ALIVE_PLAYERS,
	ALL_NEARBY_ENEMIES,
	ALL_NEARBY_ENEMIES_AND_MYSELF,
	CLOSEST_PLAYER,
	RANDOM_POSITIONS_NEARBY,
	RANDOM_POSITIONS_AROUND_RESULTS_OF_THE_CRITERIA_CHECK,
	RANDOM_POSITIONS_IN_SHAPE,
}

export enum AbilityTargetSelectionStyle {
	CENTER_OF_ENCLOSING_CIRCLE,
	FIRST,
	RANDOM,
	CLOSEST,
	FARTHEST,
	LOWEST_HEALTH,
	ALL,
}

export enum CriteriaSatisfactionQuantity {
	NONE = 'NONE',
	AT_LEAST_ONE = 'AT_LEAST_ONE',
	ALL = 'ALL',
}

export enum CriteriaTarget {
	SELF,
	AI_UNITS,
	PLAYERS,
}

export enum CriteriaType {
	WITHIN_RANGE,
	OUTSIDE_OF_RANGE,
	/** health% > criteriaValue */
	HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
	/** health% < criteriaValue */
	HEALTH_BELOW_PERCENTAGE_THRESHOLD,
	WORLD_DIFFICULTY_AT_OR_ABOVE_THRESHOLD,
	WORLD_DIFFICULTY_AT_OR_BELOW_THRESHOLD,
	PRESENCE_OF_BUFF_OR_DEBUFF,
	NO_PRESENCE_OF_BUFF_OR_DEBUFF,
	/** not implemented */
	PRESENCE_OF_COOLDOWN,
	/** is inside any of the shapes listed in criteriaValue */
	INSIDE_SHAPES,
	IS_BASIC_ENEMY,
	IS_CHAMPION_ENEMY,
	IS_BOSS_ENEMY,
}

export type CriteriaValue = level | gameUnits | healthPercentage | BuffIdentifier | WorldDifficulty | string[]

export interface ActionCriterion {
	criteriaTargets: CriteriaTarget
	criteriaType: CriteriaType
	criteriaValue: CriteriaValue
	minimumTargetsThatSatisfy: number
	/** makes the criteria pass if the criteria fails, and vice-versa */
	negateCriteria?: boolean
	criteriaId?: string
}

export interface ActionCriteria {
	/** How many of the below criteria need to be satisfied before I am choosing this action, all of them or only one of them? */
	satisfactionQuantity: CriteriaSatisfactionQuantity
	/** A list of criteria to check against, see ActionCriterion */
	criterias: ActionCriterion[]
}

export interface Action {
	/** priority of this action. Lowest goes first */
	priority: number
	/** this actions abilities are only performed once per boss reset */
	shouldOccurOnlyOnce?: boolean
	/** criteria required to meet for this actions abilities to run */
	actionCriteria: ActionCriteria
	/** abilities to be run if this is the highest priority action whose criteria are met */
	actionAbilities: ActionAbilities
	/** optional abilities to be run on exit of this action */
	exitActionAbilities?: ActionAbilities

	/** A name for debugging purposes */
	name?: string
}

export function getNumber(n: number | (() => number)): number {
	return typeof n === 'number' ? n : n()
}
