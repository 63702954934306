
































import { mapGetters, mapMutations, mapActions } from 'vuex'
import PanelDivider from '../../reusable-components/panel-divider/panel-divider.vue'
import TextButton from '../../reusable-components/buttons/text-button.vue'
import TextInput from '../../reusable-components/inputs/text-input.vue'
import ArrowButton from '../../reusable-components/buttons/arrow-button.vue'

export default {
	name: '',
	data() {
		return {
			createPoloErrorsCodes: ['alreadyInParty', 'failedToCreateParty', 'defaultCreatePartyError'],
			joinPoloErrorsCodes: ['partyDoesNotExist', 'failedToJoinParty', 'defaultJoinPartyError', 'partyIsFull'],
		}
	},
	components: {
		PanelDivider,
		TextButton,
		TextInput,
		ArrowButton,
	},
	props: {},
	computed: {
		...mapGetters('party', ['getErrors', 'getFormattedErrorString']),
		showJoinErrors() {
			return this.joinPoloErrorsCodes.includes(this.getErrors)
		},
		showCreateErrors() {
			return this.createPoloErrorsCodes.includes(this.getErrors)
		},
	},
	methods: {
		...mapActions('party', ['joinParty', 'createParty']),
		...mapMutations('party', ['updatePartyId']),
	},
}
