import nengi from 'nengi'
import { jsonString } from '../../utils/primitive-types'

class SendStoreContentsMessage {
	static protocol = {
		contents: { type: nengi.String },
	}

	contents: jsonString

	constructor(storeContents) {
		this.contents = JSON.stringify(storeContents)
	}
}

export default SendStoreContentsMessage
