import { ProjectileConfigId } from "../shared/projectile-config-ids";
import { ProjectileConfig } from "../shared/projectile-types";

const projectileConfigRegistry: ProjectileConfig[] = []

export function projectileRegistry_clientAddConfig(projectileConfig: ProjectileConfig, id: ProjectileConfigId) {
	projectileConfigRegistry[id] = projectileConfig
}

export function projectileRegistry_clientGetConfig(id: ProjectileConfigId) {
	if (id !== ProjectileConfigId.NONE) {
		console.assert(projectileConfigRegistry[id], 'missing config for id:', id)
		return projectileConfigRegistry[id]
	} else {
		return undefined
	}
}
