import logger from '../../utils/client-logger'
import { UI } from '../ui'

export enum SpineAssetNames {
	AUGMENT_UNLOCK = 'augment-unlock-pfx',
	GENERIC_UNVEIL = 'generic-ui-unveil',
}

export const SLOT_PFX = { instance: null }

// There is a case where the PFX may have to spill outside of the container
// it's in. In those cases override the width and height but use the
// difference between you override and the default container size as the padding value
// 150px override - 135px default container = 15px padding
// This should make the container center.. else just use 0
export const showPfxAnimation = (targets: string[], padding: number, assetName: SpineAssetNames, event?: any, overrideWidth?: number, overrideHeight?: number) => {
	UI.getInstance().emitAsyncEvent('slotAnimation/showUnlockAnimation', { targets, padding, event, overrideWidth, overrideHeight, assetName })
}

export interface Position {
	top: number
	bottom: number
	left: number
	right: number
	width: number
	height: number
}

export const slotAnimationState = () => {
	logger.debug('Initializing Unlock Animation Module...')
	return {
		namespaced: true,
		state: {
			showSlotAnimation: false,
			targets: [],
			positioning: [],
			triggerOutro: false,
			animationType: '',
			padding: 0,
			assetName: '',
		},
		getters: {
			getShowSlotAnimation(state) {
				return state.showSlotAnimation
			},
		},
		mutations: {
			updatePadding(state: any, padding: number) {
				state.padding = padding
			},
			updateTargets(state: any, targets) {
				state.targets = targets
			},
			updatePositioning(state: any, params: any) {
				const { elementDomRect, element, overrideWidth, overrideHeight } = params
				if (overrideWidth !== undefined || overrideHeight !== undefined) {
					state.positioning.push({ top: elementDomRect.top, bottom: elementDomRect.bottom, left: elementDomRect.left, right: elementDomRect.right, width: overrideWidth, height: overrideHeight })
				} else {
					state.positioning.push({ top: elementDomRect.top, bottom: elementDomRect.bottom, left: elementDomRect.left, right: elementDomRect.right, width: element.offsetWidth, height: element.offsetHeight })
				}
			},
			updateSlotAnimations(state: any, toggle: boolean) {
				state.showSlotAnimation = toggle
			},
			updateAssetName(state: any, assetName: SpineAssetNames) {
				state.assetName = assetName
			},
			resetOutro(state: any) {
				state.triggerOutro = false
			},
			resetPositioning(state: any) {
				state.positioning = []
			},
			resetSlotAnimation(state: any) {
				state.showSlotAnimation = false
			},
		},
		actions: {
			async showUnlockAnimation({ state, commit, dispatch }, params: any) {
				await dispatch('resetSlotState')
				for (let i = 0; i < params['targets'].length; i++) {
					const element = document.getElementById(`${params.targets[i]}`)
					const elementDomRect = document.getElementById(`${params.targets[i]}`).getBoundingClientRect()

					if (params['targets'] === undefined || params['targets'] === null) {
						console.log(`Unknown Element IDs for ${params.targets[i]} within the DOM element`)
						return
					}
					commit('updatePositioning', { elementDomRect, element, overrideWidth: params.overrideWidth, overrideHeight: params.overrideHeight })
				}
				commit('updateAssetName', params.assetName)
				commit('updateTargets', params['targets'])
				commit('updatePadding', params.padding)
				commit('updateSlotAnimations', true)
			},
			rebuildPositioning({ state, commit }) {
				state.positioning = []
				for (let i = 0; i < state.targets.length; i++) {
					const element = document.getElementById(`${state.targets[i]}`)
					const elementDomRect = document.getElementById(`${state.targets[i]}`).getBoundingClientRect()
					if (state.targets[i] === undefined || state.targets[i] === null) {
						console.log(`Unknown Element IDs for ${state.targets[i]} within the DOM element`)
						return
					}
					commit('updatePositioning', { elementDomRect, element })
				}
			},
			async resetSlotState({ commit }) {
				commit('updateTargets', [])
				commit('resetPositioning')
				commit('resetOutro')
				commit('resetSlotAnimation')
			},
			async updateOutro({ state }) {
				state.triggerOutro = true
			},
		},
	}
}
