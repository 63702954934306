import nengi from 'nengi'

class PartyInvitationMessage {
	static protocol = {
		authorsName: nengi.String,
		message: nengi.String,
		partyId: nengi.String,
	}

	authorsName: string
	message: string
	partyId: string
	authorColor: string // this is set on client after receiving the message

	constructor(authorsName: string, message: string, partyId) {
		this.authorsName = authorsName
		this.message = message
		this.partyId = partyId
		this.authorColor = '#ffffff'
	}
}

export default PartyInvitationMessage
