









import { mapGetters } from 'vuex'

export default {
	name: 'Loading',
	props: {
		showPercentage: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	computed: {
		...mapGetters('mainMenu', ['loadedPercentage']),
		...mapGetters('hud', ['isConnectingToServer', 'isConnectedToServer']),
	},
	methods: {
		reloadPage() {
			location.reload()
		},
	},
}
