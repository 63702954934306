import { Container, Sprite, Text } from 'pixi.js'
import { ClientNPC } from '../../ai/client/npc.client'
import { BossCreditState } from '../../ai/shared/npc.shared'
import { NPCEmoteType } from '../../chat/shared/emote-enums'
import Renderer from '../../engine/client/graphics/renderer'

const INTERACTION_TEXT_OFFSET = -10
const BOSS_CREDIT_ICON_Y_OFFSET = -11
const BOSS_CREDIT_ICON_SCALE = 0.25

const FADE_LERP_TIME = 0.25
const BOSS_CREDIT_SWAP_TIME = 0.7

export class InteractionPromptManager {
	static getInstance(): InteractionPromptManager {
		return InteractionPromptManager._instance
	}

	static createInstance(): InteractionPromptManager {
		InteractionPromptManager._instance = new InteractionPromptManager()
		return InteractionPromptManager._instance
	}

	private static _instance: InteractionPromptManager

	private _interactionPrompt: Container
	private _interactionText: Text
	private _bossCreditIcon: Sprite
	private _bossNoCreditIcon: Sprite

	// could be made into not NPC specific pretty easily
	private _interactableEntities: Map<number, ClientNPC> = new Map() //should be kept small b/c client entities are hidden when offscreen

	private _attachedNPC: ClientNPC

	private _fadeElapsedTime: number = 0
	private _bossCreditElapsedTime: number = 0

	private _isBossCreditVisible: boolean = false
	private _isVisible: boolean = false

	private _isNPCEmoteVisible: boolean = false


	private constructor() {
		// make interaction prompt
		this._interactionPrompt = new Container()

		const bubble = Sprite.from('letter_bubble')
		bubble.anchor.x = 0.5
		bubble.anchor.y = 0.5

		this._bossCreditIcon = Sprite.from('boss_credit_icon')
		this._bossCreditIcon.anchor.x = 0.5
		this._bossCreditIcon.anchor.y = 0.5
		this._bossCreditIcon.scale.x = BOSS_CREDIT_ICON_SCALE
		this._bossCreditIcon.scale.y = BOSS_CREDIT_ICON_SCALE
		this._bossCreditIcon.y = BOSS_CREDIT_ICON_Y_OFFSET
		this._bossCreditIcon.visible = false
		bubble.addChild(this._bossCreditIcon)

		this._bossNoCreditIcon = Sprite.from('boss_no_credit_icon')
		this._bossNoCreditIcon.anchor.x = 0.5
		this._bossNoCreditIcon.anchor.y = 0.5
		this._bossNoCreditIcon.scale.x = BOSS_CREDIT_ICON_SCALE
		this._bossNoCreditIcon.scale.y = BOSS_CREDIT_ICON_SCALE
		this._bossNoCreditIcon.y = BOSS_CREDIT_ICON_Y_OFFSET
		this._bossNoCreditIcon.visible = false
		bubble.addChild(this._bossNoCreditIcon)

		this._interactionText = new Text('F', {
			fontFamily: 'Do Hyeon',
			fontSize: 28,
			fontWeight: 900,
			align: 'center',
		})

		this._interactionText.anchor.x = 0.5
		this._interactionText.anchor.y = 0.5

		this._interactionText.y = INTERACTION_TEXT_OFFSET

		bubble.addChild(this._interactionText)
		this._interactionPrompt.addChild(bubble)

		this._interactionPrompt.visible = false

		Renderer.getInstance().fgRenderer.addChild(this._interactionPrompt)
	}

	update(deltaTime) {

		if (this._attachedNPC && this._attachedNPC.isPet){
			this._interactionPrompt.visible = false
			
			// Making sure we don't try to attach an emote client to a null npc model
			if (this._attachedNPC.model === null && this._attachedNPC.currentEmote === NPCEmoteType.PetGreeting){
				this._attachedNPC.currentEmote = NPCEmoteType.None
			}

			if (this._attachedNPC.currentEmote === NPCEmoteType.PetGreeting){
				if (!this._attachedNPC.petGreeting){
					this._attachedNPC.petGreeting = this._attachedNPC.getEmote()
					this._attachedNPC.petGreeting.setEmote(NPCEmoteType.PetGreeting, this._attachedNPC.petGreeting.getRandomEmoteSkin())
				}
				this._attachedNPC.petGreeting.update(deltaTime)
			}
			else if (this._attachedNPC.currentEmote === NPCEmoteType.None) {
				if (this._attachedNPC.petGreeting) {
					this._attachedNPC.petGreeting.returnToPool()
					this._attachedNPC.petGreeting = null
				}
			}
		}

		if (this._interactionPrompt.visible) {
			//could turn this on if we ever have to interact with a moving npc for some reason
			// this._interactionPrompt.x = this._attachedNPC.x
			// this._interactionPrompt.y = this._attachedNPC.y - this._attachedNPC.modelHeight

			if (this._attachedNPC) {
				if (this._isBossCreditVisible) {
					if (this._attachedNPC.bossCredit === BossCreditState.NO_BOSS) {
						this.hideBossCreditVisible()
					} else {
						if (this._attachedNPC.bossCredit === BossCreditState.BOSS_CREDIT) {
							this._bossCreditIcon.visible = true
							this._bossNoCreditIcon.visible = false
						} else {
							this._bossCreditIcon.visible = false
							this._bossNoCreditIcon.visible = true
						}
					}

					this._bossCreditElapsedTime += deltaTime
					if (this._bossCreditElapsedTime >= BOSS_CREDIT_SWAP_TIME) {
						this.hideBossCreditVisible()
					}

				} else if (this._attachedNPC.bossCredit !== BossCreditState.NO_BOSS) {
					this._bossCreditElapsedTime += deltaTime

					if (this._bossCreditElapsedTime >= BOSS_CREDIT_SWAP_TIME) {
						if (this._attachedNPC.bossCredit === BossCreditState.BOSS_CREDIT) {
							this._bossCreditIcon.visible = true
						} else {
							this._bossNoCreditIcon.visible = true
						}

						this._interactionText.visible = false
						this._isBossCreditVisible = true

						this._bossCreditElapsedTime = 0
					}
				}
			}

			this._fadeElapsedTime += deltaTime
			if (this._isVisible ) {
				//fading in
				this._interactionPrompt.alpha = Math.clamp(this._fadeElapsedTime / FADE_LERP_TIME, 0, 1)
			} else {
				//fading out
				const newAlpha = 1 - this._fadeElapsedTime / FADE_LERP_TIME
				if (newAlpha <= 0) {
					this._interactionPrompt.visible = false
					this._attachedNPC = null
					return
				}

				this._interactionPrompt.alpha = newAlpha
			}
		}
	}

	updatePlayerPosition(x: number, y: number) {
		for (const npc of this._interactableEntities.values()) {
			const xDiff = npc.x - x
			const yDiff = npc.y - y

			const distSquared = xDiff * xDiff + yDiff * yDiff
			if (distSquared < npc.colliderRadiusSquared) {
				if (!this._attachedNPC || this._attachedNPC.nid !== npc.nid) {
					//enable the prompt at npc's position
					this._interactionPrompt.x = npc.x
					this._interactionPrompt.y = npc.y - npc.modelHeight
					this._interactionPrompt.alpha = 0
					this._interactionPrompt.visible = true

					this._attachedNPC = npc
					this._fadeElapsedTime = 0
					this._isVisible = true
				} else if (this._attachedNPC && this._attachedNPC.nid === npc.nid && !this._isVisible) {
					this._isVisible = true
					this._fadeElapsedTime = FADE_LERP_TIME * this._interactionPrompt.alpha
				}

				return
			}
		}

		//disable the prompt
		this._isVisible = false
		this._fadeElapsedTime = FADE_LERP_TIME * (1 - this._interactionPrompt.alpha)

		//remove the _attachedNPC reference if no longer needed (prevents pet emote failure in the rare cases when the npc model has been culled but the reference to the npc object remains here in the prompt manager)
		if (this._attachedNPC && this._attachedNPC.model === null && this._attachedNPC.currentEmote === NPCEmoteType.None && !this._attachedNPC.petGreeting){
			this._attachedNPC = null
		}
	}

	hideBossCreditVisible() {
		this._bossCreditIcon.visible = false
		this._bossNoCreditIcon.visible = false
		this._interactionText.visible = true
		this._isBossCreditVisible = false

		this._bossCreditElapsedTime = 0
	}

	addInteractable(npc: ClientNPC) {
		this._interactableEntities.set(npc.nid, npc)
	}

	removeInteractable(npc: ClientNPC) {
		this._interactableEntities.delete(npc.nid)

		if (this._attachedNPC && npc.nid === this._attachedNPC.nid) {
			this._isVisible = false
			this._fadeElapsedTime = FADE_LERP_TIME * (1 - this._interactionPrompt.alpha)
		}
	}
}
