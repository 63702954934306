export enum WormHornSubtype {
	Standard = 4001,
	Premium = 4002,
}

const WormHornSubTypePrettyName = new Map<WormHornSubtype, string>()
WormHornSubTypePrettyName.set(WormHornSubtype.Standard, 'Standard Worm Horn')
WormHornSubTypePrettyName.set(WormHornSubtype.Premium, 'Premium Worm Horn')

export { WormHornSubTypePrettyName }
