import nengi from 'nengi'

export default class LookupEntityByNidCommand {
    nid: number

    static protocol = {
        listingId: nengi.UInt16
    }

    constructor(nid: number) {
        this.nid = nid
    }
}