






































































































import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import DropDown from '../reusable-components/inputs/dropdown.vue'
import TextInput from '../reusable-components/inputs/text-input.vue'
import ArrowButton from '../reusable-components/buttons/arrow-button.vue'
import { FactionShortName, FactionShortNameToFullName } from '../../factions/shared/faction-data'
import PanelDivider from '../reusable-components/panel-divider/panel-divider.vue'

const flagImages = require('./assets/*flag.png')
const numberImages = require('./assets/number-*.png')
const barImages = require('./assets/*_comparison_bar.png')

export default {
	name: 'FactionScoreboard',
	components: {
		MainPanel,
		DropDown,
		TextInput,
		ArrowButton,
		PanelDivider,
	},
	data() {
		return {
			timeRangesPrettyName: [{ id: 'week', name: 'This Week' }],
			fullScoreBarWidth: 475,
			dividerWidth: 22,

			fullComparisonBarWidth: 182,
			comparisonBarContainerWidthOffset: 8,
		}
	},
	computed: {
		...mapState('factions', ['scoreboardTimeRange', 'aurumScore', 'ironScore', 'dawnScore', 'currentRecruitPanel']),
		...mapGetters('factions', ['factionTotalPoints', 'factionMemberCounts', 'currentlyWinningFaction', 'factionMemberCounts', 'factionMemberCountsPercentage']),
		scoreBarUsableWidth() {
			return this.fullScoreBarWidth
		},
		aurumBarWidth() {
			let score = this.aurumScore
			if (this.currentlyWinningFaction === FactionShortName.AURUM_ALLIANCE) {
				score = Math.max(1, score)
			}
			return this.scoreBarUsableWidth * (score / this.totalFactionScore)
		},
		ironBarWidth() {
			return this.scoreBarUsableWidth * (this.ironScore / this.totalFactionScore)
		},
		dawnBarWidth() {
			return this.scoreBarUsableWidth * (this.dawnScore / this.totalFactionScore)
		},
		leadingFactionScore() {
			return this.factionTotalPoints[this.currentlyWinningFaction]
		},
		totalFactionScore() {
			return Math.max(1, this.ironScore + this.aurumScore + this.dawnScore)
		},
		lastFactionPageFullName() {
			return FactionShortNameToFullName.get(this.currentRecruitPanel)
		},
		factionEntries() {
			let first = FactionShortName.AURUM_ALLIANCE
			let second = FactionShortName.ORDER_OF_IRON
			let third = FactionShortName.SCIONS_OF_DAWN

			if (this.factionTotalPoints[first] < this.factionTotalPoints[second]) {
				const temp = first
				first = second
				second = temp
			}

			if (this.factionTotalPoints[first] < this.factionTotalPoints[third]) {
				const temp = first
				first = third
				third = temp
			}

			if (this.factionTotalPoints[second] < this.factionTotalPoints[third]) {
				const temp = second
				second = third
				third = temp
			}

			return [
				{ faction: first, place: 1 },
				{ faction: second, place: 2 },
				{ faction: third, place: 3 },
			]
		},
		winningFactionFlavorText() {
			switch (this.currentlyWinningFaction) {
				case FactionShortName.ORDER_OF_IRON:
					return 'The Order of Iron stands strong!'
				case FactionShortName.AURUM_ALLIANCE:
					return 'Things are looking gold!'
				case FactionShortName.SCIONS_OF_DAWN:
					return 'Towards Dawn!'
			}
			return 'Boy are they winning!'
		},
	},
	methods: {
		...mapMutations('factions', ['setScoreboardTimeRange']),
		...mapMutations('inGame', ['setActivePanel']),
		...mapActions('inGame', ['closeActivePanel']),
		getFactionFullName(faction) {
			return FactionShortNameToFullName.get(faction)
		},
		getFactionFlag(faction) {
			switch (faction) {
				case FactionShortName.ORDER_OF_IRON:
					return flagImages['iron-']
				case FactionShortName.AURUM_ALLIANCE:
					return flagImages['aurum-']
				case FactionShortName.SCIONS_OF_DAWN:
					return flagImages['scion-']
			}

			return flagImages['scion-']
		},
		getPlacementNumber(place) {
			return numberImages[place]
		},
		getfactionBonus(faction) {
			switch (faction) {
				case FactionShortName.ORDER_OF_IRON:
					return '- <span style="color: #e9e5ad"> Physical </span> damage increase'
				case FactionShortName.AURUM_ALLIANCE:
					return '- <span style="color: #e9e5ad"> Critical damage </span> increase'
				case FactionShortName.SCIONS_OF_DAWN:
					return '- Bonus to <span style="color: #e9e5ad"> fire </span> potency'
			}

			return []
		},
		getPointsToLead(faction) {
			return this.leadingFactionScore - this.factionTotalPoints[faction]
		},
		getFactionComparisonBarWidth(faction) {
			const ourPoints = this.factionTotalPoints[faction]
			const leadingPoints = this.factionTotalPoints[this.currentlyWinningFaction]

			if (leadingPoints !== 0) {
				const percentage = ourPoints / leadingPoints
				return this.fullComparisonBarWidth * percentage
			}
			return this.fullComparisonBarWidth
		},
		getFactionComparisonBarUrl(faction) {
			switch (faction) {
				case FactionShortName.ORDER_OF_IRON:
					return barImages['iron']
				case FactionShortName.AURUM_ALLIANCE:
					return barImages['aurum']
				case FactionShortName.SCIONS_OF_DAWN:
					return barImages['scion']
			}
			return barImages[faction]
		},
	},
}
