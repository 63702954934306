import fs from 'fs'

export function loadJson(path: string) {
	const data = fs.readFileSync(path, 'utf8')
	return JSON.parse(data)
}

export function writeTimestampedIgnoredFile(name: string, data: any) {
	if (!fs.existsSync('ignored')) {
		fs.mkdirSync('ignored')
	}
	const filename = `ignored/${name}-${new Date().getTime()}.txt`
	fs.writeFileSync(filename, data)
	return filename
}

export function saveFileInBrowser({filename, contents}) {
	const a = document.createElement("a");
	a.download =filename;
	a.href = window.URL.createObjectURL(new Blob([contents], { type: "text/plain" }));
	a.click();
}