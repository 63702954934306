import { Container, Graphics, Sprite, Texture } from 'pixi.js'
import { ObjectPool, PoolableObject } from '../../third-party/object-pool'

const HEALTHBAR_WIDTH = 121
const HALF_HEALTHBAR_WIDTH = HEALTHBAR_WIDTH / 2

const FADE_MAX_TIME = 0.5

export class HealthBar implements PoolableObject {
	static pool: ObjectPool

	parentContainer: Container

	private _healthbar: Container
	private _healthbarBg: Sprite
	private _healthbarCap: Sprite
	private _healthbarForeground: Graphics
	private _healthbarMask: Sprite

	private _isFadingOut: boolean
	private _fadeTime: number

	constructor(defaultValues?: any, overrideValues?: any) {
		this._healthbarBg = Sprite.from('enemy_hp_bar')
		this._healthbarCap = Sprite.from('enemy_hp_bar_cap')

		this._healthbarBg.anchor.x = 0.5
		this._healthbarBg.anchor.y = 0.5

		this._healthbarCap.anchor.x = 0.5
		this._healthbarCap.anchor.y = 0.5

		this._healthbarCap.x = -HALF_HEALTHBAR_WIDTH

		this._healthbarForeground = new Graphics()
		this._healthbarForeground.beginTextureFill({ texture: Texture.from('enemy_hp_gradient'), matrix: new PIXI.Matrix().translate(-HALF_HEALTHBAR_WIDTH, 0) })
		const _healthbarHeight = 10
		this._healthbarForeground.drawRect(-HALF_HEALTHBAR_WIDTH, -_healthbarHeight / 2, HEALTHBAR_WIDTH, _healthbarHeight)
		this._healthbarForeground.endFill()

		this._healthbarMask = Sprite.from('enemy_hp_bar_mask')
		this._healthbarMask.anchor.x = 0.5
		this._healthbarMask.anchor.y = 0.5

		this._healthbarForeground.mask = this._healthbarMask

		this._healthbar = new Container()
		this._healthbar.name = '_healthbar'

		this._healthbar.addChild(this._healthbarBg)
		this._healthbarBg.addChild(this._healthbarMask)
		this._healthbar.addChild(this._healthbarForeground)
		this._healthbar.addChild(this._healthbarCap)

		this._healthbar.visible = false
	}

	setDefaultValues(defaultValues: any, overrideValues?: any) {
		this.setFillPercent(1)
		this._healthbar.visible = false
		this._fadeTime = 0
		this._isFadingOut = false
		this._healthbar.alpha = 1
	}

	cleanup() {}

	getContainer(): Container {
		return this._healthbar
	}

	setParentContainer(container: Container) {
		this.parentContainer = container
		container.addChild(this._healthbar)
	}

	setFillPercent(percent) {
		this._healthbarMask.scale.x = percent
		this._healthbarMask.x = -HALF_HEALTHBAR_WIDTH * (1 - percent)
	}

	fadeOut() {
		this._isFadingOut = true
	}

	removeFade() {
		this._isFadingOut = false
		this._fadeTime = 0
		this._healthbar.alpha = 1
	}

	update(delta) {
		if (this._isFadingOut) {
			this._fadeTime += delta
			this._healthbar.alpha = Math.lerp(1, 0, this._fadeTime / FADE_MAX_TIME)
		}
	}

	returnToPool() {
		this.parentContainer.removeChild(this._healthbar)
		HealthBar.pool.free(this)
	}
}
