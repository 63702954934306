import { EnemyAI, FightingTriggers, IdleBehaviours, FightingBehaviours, LeashTriggers, LeashingBehaviours, AttackTypes, DeadBehaviours, ShotLeadPrecision, EnemyType, EnemyTargetingStyles } from '../ai-types'

import { SpineDataName, EnemyBipedSkinType } from '../../../models-animations/shared/spine-config'
import { EnemyDefaults } from './enemy-defaults'
import { ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { ENEMY_DEFAULT_HEALTH } from '../../../engine/shared/game-data/enemy-formulas'
import { AnimationTrack } from '../../../models-animations/shared/animation-track'
import { ENEMY_NAMES } from './enemy-names'

const crabNestA: EnemyAI = {
	name: ENEMY_NAMES.CRAB_NEST_A,
	type: EnemyType.BASIC,
	appearance: {
		asset: SpineDataName.CRAB_NEST_A,
		skin: EnemyBipedSkinType.DEFAULT,
		color: [1, 0.75, 0.5],
		mixSettings: [
			{ from: AnimationTrack.IDLE, to: AnimationTrack.IDLE, duration: 0.0 },
			{ from: AnimationTrack.IDLE, to: AnimationTrack.DEATH, duration: 0.5333 },
			{ from: AnimationTrack.SPAWN, to: AnimationTrack.IDLE, duration: 0.2 },
		],
		spawnAnim: true,
		showHealthBarWhenFull: true,
	},
	general: {
		enemyLevel: 1,
		experienceWhenKilled: 6,
	},
	baseAttributes: {
		...EnemyDefaults.baseAttributes,
		colliders: [
			{
				type: ColliderType.Ellipse,
				rX: 120,
				rY: 80,
				position: [0, -30],
				traits: ColliderTraits.BlockMovementAndProjectileAndItem,
			},
		],
		maxHealth: ENEMY_DEFAULT_HEALTH * 3.5,
		movementSpeed: 0,
		attackRate: 200,
		noFlip: true,
		colliderIsKinematic: true,

		lootDropProbability: 2,
		lootDropQuantity: 2,
	},
	soundEffects: {
		attack: 'Projectile_Blip',
		impact: 'SFX_Impact_Enemy_Rock',
	},
	states: {
		idling: {
			behaviour: IdleBehaviours.STATIONARY,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			transitionToFighting: {
				trigger: FightingTriggers.AGGRESSIVE_PROXIMITY,
				radius: 1200,
			},
			aggroOnHit: true,
		},
		fighting: {
			behaviour: FightingBehaviours.SPAWNER,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			attackType: AttackTypes.NONE,
			engagementMaxDistance: 1200,
			engagementMinDistance: 0,
			movementMaxDistance: 1200,
			movementMinDistance: 0,
			attackOptions: [],
			shotLeadPrecision: ShotLeadPrecision.AVERAGE,
			visualAimLockSeconds: 0.5,
			transitionToLeashing: {
				trigger: LeashTriggers.NONE,
			},
			spawningRules: {
				...EnemyDefaults.spawningRules,
				spawnLimit: 2,
				spawnEnemies: [
					{ name: ENEMY_NAMES.WEAK_TINY_CRAB, weight: 90 },
					{ name: ENEMY_NAMES.HERMIT_CRAB, weight: 10 },
				],
				spawnRate: 6,
				spawnRadius: 180,
				spawnInTime: 0,
				killSpawnlingsWhenIDie: false,
			},
		},
		leashing: {
			behaviour: LeashingBehaviours.MOVE,
			speedMultiplier: 4,
			decelerateMultiplier: 0,
		},
		fleeing: {},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 0,
			persistentCorpse: true,
		},
	},
}

export default crabNestA
