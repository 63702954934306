import nengi from 'nengi'

class UpdateNavArrowVisibility {
	static protocol = {
		visible: nengi.Boolean,
	}

	visible: boolean

	constructor(visible: boolean) {
		this.visible = visible
	}
}

export default UpdateNavArrowVisibility
