








































































import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import GridItemContainer from '../reusable-components/item-containers/grid-item-container.vue'
import PanelDivider from '../reusable-components/panel-divider/panel-divider.vue'
import ArrowButton from '../reusable-components/buttons/arrow-button.vue'
import { MAX_ITEM_LISTINGS } from '../state/marketplace.ui-state'
import { FURNACE_SLOT_COUNT } from '../state/furnace-ui-state'
import { furnaceTooltipText, marketTooltipText, pitStorageFullTooltipText } from '../reusable-components/v-tooltip/v-tooltip-functions'
import { PIT_STORAGE_DEFAULT_MAX_SIZE } from '../../engine/shared/game-data/player-formulas'
import { NengiClient } from '../../engine/client/nengi-client'
import OpenCurrencyBagCommand from '../../items/shared/open-currency-bag-command'
import { containerWillStackItems } from '../state/item-containers.ui-state'

export default {
	name: 'PitOfChancesStorage',
	components: {
		MainPanel,
		GridItemContainer,
		PanelDivider,
		ArrowButton,
	},
	data() {
		return {
			MAX_LISTINGS: MAX_ITEM_LISTINGS,
			MAX_FURNACE: FURNACE_SLOT_COUNT,
			MAX_STORAGE: PIT_STORAGE_DEFAULT_MAX_SIZE,
		}
	},
	computed: {
		...mapGetters('itemContainers', ['itemsInContainer', 'numberOfItemsInContainer', 'itemDetails', 'isStashFull', 'isPitStorageFull']),
		...mapGetters('itemLocks', ['itemIsLocked']),
		...mapGetters('pitStorage', ['selectedItem']),
		...mapGetters('furnace', ['hasFreeFurnace', 'countOfOccupiedFurnaceSlots']),
		...mapGetters('marketplaceUpdated', ['getMyListings']),
		...mapState('marketplaceUpdated', ['isListingItem']),
		isSelectedItemLocked() {
			return !this.selectedItem || (this.selectedItem && this.itemIsLocked(this.selectedItem))
		},
		isSelectedItemCoins() {
			const itemDetails = this.itemDetails('pitStorage', this.selectedItem)
			return itemDetails && itemDetails.itemType === 'Currency Bag'
		},
		topButtonText() {
			if(this.isSelectedItemCoins) {
				return 'Send Coins to Wallet!'
			}
			return 'Put in stash!'
		},
		topButtonIcon() {
			if(this.isSelectedItemCoins) {
				return 'coin'
			}
			return 'stash'
		},
		isSelecteditemStackable() {
			const selectedDetails = this.itemDetails('pitStorage', this.selectedItem)
			return Boolean(selectedDetails.stackAmount)
		},
		stashHasStackableSpace() {
			const selectedItemDetails = this.itemDetails('pitStorage', this.selectedItem)
			if(!selectedItemDetails || !selectedItemDetails.stackAmount) {
				return !this.isStashFull
			}

			const stashItems = this.itemsInContainer('stash')
			if(containerWillStackItems(stashItems, selectedItemDetails)) {
				return true
			}

			return !this.isStashFull
		}
	},
	methods: {
		...mapActions('inGame', ['closeActivePanel']),
		...mapMutations('inGame', ['setActivePanel']),
		...mapActions('pitStorage', ['toggleSelectedItem', 'sendItemToStashFromPitStorage']),
		...mapMutations('itemLocks', ['lockItem']),
		...mapActions('furnace', ['startSmeltingItem']),
		...mapMutations('marketplaceUpdated', ['showListingPopUp']),
		...mapActions('pitOfChances', ['tossDirectlyIntoPit']),
		furnaceTooltipText,
		marketTooltipText,
		pitStorageFullTooltipText,
		
		itemIsSelected(itemId) {
			return itemId === this.selectedItem
		},
		openPitOfChances() {
			this.setActivePanel('pitOfChancesUpdated')
		},
		throwItemToPit(itemId) {
			this.tossDirectlyIntoPit({itemId, fromContainer: 'pitStorage', sendingContainer: 'pitStorage'})

			this.selectNextItem()
		},
		putItemInFurnace(itemId) {
			this.lockItem(itemId)
			this.startSmeltingItem({ itemId, fromContainer: 'pitStorage' })

			this.selectNextItem()
		},
		openCurrencyBag(itemId) {
			NengiClient.getInstance().sendCommand(new OpenCurrencyBagCommand(itemId, 'pitRewards'))
		},
		selectNextItem() {
			const container: any[] = this.itemsInContainer('pitStorage')
			const currentSelected = this.selectedItem
			const currentIndex = container.findIndex((i) => i.id === this.selectedItem)

			if (currentIndex > -1) {
				let nextIndex = currentIndex + 1
				if (nextIndex >= container.length) {
					nextIndex = 0
				}

				const nextItem = container[nextIndex]
				if (nextItem.id !== this.selectedItem) {
					this.toggleSelectedItem(nextItem.id)

					if (this.selectedItem === currentSelected) {
						this.toggleSelectedItem(null)
					}
				} else {
					this.toggleSelectedItem(null)
				}
			}
		},
		listItem() {
			this.showListingPopUp({ itemId: this.selectedItem, container: 'pitStorage' })
			this.toggleSelectedItem(null)
		},
	},
}
