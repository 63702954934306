import { Container, Graphics } from 'pixi.js'

class BackgroundGridVisual extends Container {
	constructor(worldWidth: number, worldHeight: number) {
		super()

		// Draw vertical lines every 128px
		for (let i = 0; i < worldWidth / 128 + 1; i++) {
			const line = new Graphics()
			line.name = 'v-line'
			line.lineStyle(2, 0x333333)
			line.alpha = 0.3
			line.moveTo(i * 128, 0)
			line.lineTo(i * 128, worldHeight)
			this.addChild(line)
		}

		// Draw horizontal lines every 128px
		for (let i = 0; i < worldHeight / 128 + 1; i++) {
			const line = new Graphics()
			line.name = 'h-line'
			line.lineStyle(2, 0x333333)
			line.alpha = 0.3
			line.moveTo(0, i * 128)
			line.lineTo(worldWidth, i * 128)
			this.addChild(line)
		}
	}
}

export default BackgroundGridVisual
