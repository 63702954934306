import nengi from 'nengi'

class GotExchangeURLMessage {
	static protocol = {
		url: { type: nengi.String },
		success: { type: nengi.Boolean },
	}

	url: string
	success: boolean

	constructor(url: string, success: boolean) {
		this.url = url
		this.success = success
	}
}

export default GotExchangeURLMessage
