import nengi from 'nengi'

class FailedToBuyItemFromMarketplaceMessage {
	static protocol = {
		errorCode: nengi.String,
	}
	errorCode: string
	/**
	 * Creates an instance of FailedToBuyItemFromMarketplaceMessage
	 * @param {string} errorCode string
	 * @memberof FailedToBuyItemFromMarketplaceMessage
	 */
	constructor(errorCode: string) {
		this.errorCode = errorCode
	}
}

export default FailedToBuyItemFromMarketplaceMessage
