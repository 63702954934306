







































import { mapActions, mapMutations, mapState } from 'vuex'
import ArrowButton from '../reusable-components/buttons/arrow-button.vue'
import MainPanel from '../reusable-components/panels/main-panel.vue'

export default {
	name: 'Landing',
	components: {
		MainPanel,
		ArrowButton,
	},
	mounted() {
		this.checkIsBrave()
	},
	computed: {
		...mapState('mainMenu', ['isChrome']),
		getHeight() {
			if (this.isChrome) {
				return 375 
			}
			return 440
		}
	},
	methods: {
		...mapMutations('mainMenu', ['changeMainMenuScreen']),
		...mapActions('mainMenu', ['checkIsBrave']),
	},
}
