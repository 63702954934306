import { EnemyAI, FightingTriggers, IdleBehaviours, FightingBehaviours, LeashTriggers, LeashingBehaviours, AttackTypes, DeadBehaviours, EnemyType, ShotLeadPrecision, EnemyTargetingStyles } from '../../shared/ai-types'
import { EnemyDefaults } from './enemy-defaults'
import { SpineDataName, EnemyBipedSkinType } from '../../../models-animations/shared/spine-config'
import { degToRad } from '../../../utils/math'
import { Colors } from '../../../utils/colors'
import { ModType, ModValueType } from '../../../projectiles/shared/projectile-types'
import { AnimationTrack } from '../../../models-animations/shared/animation-track'
import { ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { ParticleEffectType } from '../../../engine/shared/game-data/particle-config'
import { ENEMY_DEFAULT_HEALTH } from '../../../engine/shared/game-data/enemy-formulas'
import { Vector } from 'sat'
import { ENEMY_NAMES } from './enemy-names'

const mushie: EnemyAI = {
	name: ENEMY_NAMES.MUSHIE,
	type: EnemyType.BASIC,
	general: {
		enemyLevel: 1,
		experienceWhenKilled: 1,
	},
	appearance: {
		asset: SpineDataName.MUSHIE,
		skin: EnemyBipedSkinType.DEFAULT,
		mixSettings: [
			{ from: AnimationTrack.IDLE, to: AnimationTrack.SHOOT, duration: 0.1 },
			{ from: AnimationTrack.SHOOT, to: AnimationTrack.IDLE, duration: 0.2 },
		],
	},
	baseAttributes: {
		...EnemyDefaults.baseAttributes,
		attackOffset: new Vector(0, -30),
		colliders: [
			{
				type: ColliderType.Ellipse,
				rX: 25,
				rY: 50,
				position: [0, -40],
				traits: ColliderTraits.BlockProjectile,
			},
			{
				type: ColliderType.Ellipse,
				rX: 40,
				rY: 15,
				position: [0, 5],
				traits: ColliderTraits.BlockMovement,
			},
		],
		colliderIsKinematic: true,
		maxHealth: ENEMY_DEFAULT_HEALTH * 2,
		movementSpeed: 0,
		attackRate: 400,
		lootDropProbability: 1.3,
	},
	soundEffects: {
		attack: 'Projectile_Fireball',
		impact: 'SFX_Impact_Enemy_Mush',
	},
	states: {
		idling: {
			behaviour: IdleBehaviours.STATIONARY,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			transitionToFighting: {
				trigger: FightingTriggers.AGGRESSIVE_PROXIMITY,
				radius: 900,
			},
			aggroOnHit: true,
		},
		fighting: {
			behaviour: FightingBehaviours.CHASE_AND_ATTACK,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			attackType: AttackTypes.PROJECTILE,
			engagementMaxDistance: 1500,
			engagementMinDistance: 0,
			movementMaxDistance: 1500,
			movementMinDistance: 0,
			attackOptions: [
				{
					...EnemyDefaults.projectileConfig,
					projectileCount: 7,
					spreadAngle: degToRad(90),
					colliderRadius: 10,
					speed: 500,
					lifespanInSeconds: 2,
					modifiers: [
						{ modType: ModType.TURN, value: { modValueType: ModValueType.RANDOM, min: -1.0, max: 1.0 } },
						{ modType: ModType.ACCELERATION, value: { modValueType: ModValueType.RANDOM, min: -0.5, max: 0.0 } },
					],
					burstCount: 1,
					basePhysicalDamage: 45,
					basePoisonDamage: 45,
					color: Colors.red,
					bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_MUSHIE,
					bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_POISON_TRAIL,
					muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_POISON_SHOOT,
				},
				/*{
					...EnemyDefaults.projectileConfig,
					projectileCount: 1,
					colliderRadius: 10,
					speed: 500,
					lifespanInSeconds: 2,
					modifiers: [
						{ modType: ModType.TURN, value: { modValueType: ModValueType.RANDOM, min: -1.0, max: 1.0 } },
						{ modType: ModType.ACCELERATION, value: { modValueType: ModValueType.RANDOM, min: -0.5, max: 0.0 } },
					],
					burstCount: 7,
					burstDelay: 0.19,
					burstMode: BurstFireModes.STRAIGHT,
					color: Colors.red,
					bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_MUSHIE,
					bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_TRAIL_MUSHIE,
					muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
				},*/
			],
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 0.1,
			transitionToLeashing: {
				trigger: LeashTriggers.RANGE_TO_TARGET_EXCEEDED,
				range: 1500,
			},
		},
		leashing: {
			behaviour: LeashingBehaviours.MOVE,
			speedMultiplier: 4,
		},
		fleeing: {},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 0.5,
		},
	},
}

export default mushie
