




























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import FancyWeaponCard from '../fancy-weapon-card/fancy-weapon-card.vue'
import StoredStateCheckbox from './stored-state-checkbox.vue'
import ItemType, { getItemSubTypePrettyName } from '../../../loot/shared/item-type'
import {
	getSpreadAngleDescriptor,
	getProjectileSpeedDescriptor,
	getTrajectoryDescriptor,
	getBeamLengthDescriptor,
	getBeamWidthDescriptor,
	getSplashRadiusDescriptorForWeapons,
	getKnockbackDescriptor,
	getStatStringForTooltip,
	getConverterDescriptor,
	getClampDescriptor,
	getBinaryFlagDescriptor,
} from '../../../loot/shared/item-stat-descriptors'
import { mapGetters, mapMutations, mapState } from 'vuex'
import { StatToTooltipName } from '../../../items/shared/tool-tip-info'
import { pickBy } from 'lodash'
import { to3DigitTruncatedString, to4DigitTruncatedString } from '../../../utils/math'
import { FactionAugmentBonuses, FactionShortName, augmentFactionIcon, factionTextColor } from '../../../factions/shared/faction-data'
import { factionAugmentBonusText } from '../v-tooltip/v-tooltip-functions'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-var-requires
const elementalImages = require('./assets/elemental-*.png')

export default {
	name: 'DetailedItemView',
	components: {
		FancyWeaponCard,
		StoredStateCheckbox,
	},
	props: {
		item: {
			type: Object,
			required: true,
			validator(item) {
				return item.id !== undefined
			},
		},
		comparisonItem: {
			type: Object,
			required: false,
			default: null,
			validator(comparisonItem) {
				return comparisonItem.id !== undefined
			},
		},
		removableAugments: {
			type: Boolean,
			required: false,
			default: false,
		},
		titleText: {
			type: String,
			required: true,
			default: function() {
				console.warn('Currently using default title text. Ensure <DetailedItemView /> component has a title-text attribute')
				return 'DEFAULT LABEL'
			},
			validator: function(value) {
				return value.trim().length !== 0
			},
		},
	},
	data() {
		return {
			//showingAugments: false
		}
	},
	computed: {
		...mapGetters('itemComparison', ['showingAugments']),
		...mapGetters('inGame', ['activePanel']),
		...mapState('factions', ['lastWinningFaction']),
		augmentFactionBonuses() {
			if(this.item.itemTypeEnum === ItemType.WeaponAugment) {
				const bonuses = FactionAugmentBonuses.get(this.lastWinningFaction)
				if(bonuses) {
					for(let i =0; i < bonuses.length; ++i) {
						const bonus = bonuses[i]
						if(bonus.augmentType === this.item.itemSubType) {
							const toReturn = {}
							
							for(let o =0; o < bonus.stats.length; ++o) {
								let statName = bonus.stats[o]
								if(statName.endsWith('Damage')) {
									statName += 'Boost'
								}
								toReturn[statName] = 1 +  bonus.bonusAmounts[o]
							}

							return toReturn
						}
					}
				}
			}
			return false
		},
		trajectoryDPS() {
			let total = 0
			const trajectories = this.stats(this.item).trajectory
			for(let i=0; i < trajectories.length; ++i) {
				total += trajectories[i].dps
			}
			return total
		},
		itemNameFontSize() {
			if(this.item.name.length > 25) {
				return (36 - ((this.item.name.length / 25) - 1) * 25) + 'px'
			} else {
				return '36px'
			}
		}
	},
	methods: {
		...mapMutations('itemComparison', ['setShowingAugments']),

		getItemSubTypePrettyName,
		getSpreadAngleDescriptor,
		getProjectileSpeedDescriptor,
		getTrajectoryDescriptor,
		getBeamLengthDescriptor,
		getBeamWidthDescriptor,
		getSplashRadiusDescriptorForWeapons,
		getKnockbackDescriptor,
		getConverterDescriptor,
		getClampDescriptor,
		getBinaryFlagDescriptor,
		to3DigitTruncatedString,
		to4DigitTruncatedString,
		factionAugmentBonusText,
		augmentFactionIcon,
		factionTextColor,
		
		compoundDamage(stats) {
			let result = 0
			if (stats.physicalDamage) result += stats.physicalDamage
			if (stats.fireDamage) result += stats.fireDamage
			if (stats.iceDamage) result += stats.iceDamage
			if (stats.lightningDamage) result += stats.lightningDamage
			if (stats.poisonDamage) result += stats.poisonDamage
			return result
		},
		numberOfDamageTypes(stats) {
			let result = 0
			if (stats.physicalDamage) result += 1
			if (stats.fireDamage) result += 1
			if (stats.iceDamage) result += 1
			if (stats.lightningDamage) result += 1
			if (stats.poisonDamage) result += 1
			return result
		},
		numberOfResists(stats) {
			let result = 0
			if (stats.defense) result += 1
			if (stats.fireResist) result += 1
			if (stats.iceResist) result += 1
			if (stats.lightningResist) result += 1
			if (stats.poisonResist) result += 1
			return result
		},
		showAugments() {
			if (this.activePanel === 'augmentationStation') {
				return true
			} else {
				return this.showingAugments
			}
		},
		stats(item) {
			if (this.showAugments()) {
				return item.statsWithAugments
			} else {
				return item.stats
			}
		},
		getAugmentStats(item) {
			let stats1 = pickBy(item.stats, (statValue, statName) => {
				return this.getStatPrettyName(statName) !== undefined
			})

			const order = Object.keys(StatToTooltipName)
			const stats2 = {}
			order.forEach((key) => {
				if (key in stats1) {
					stats2[key] = stats1[key]
				}
			})

			return stats2
		},
		getStatPrettyName(stat) {
			const pretty = StatToTooltipName[stat]
			return pretty
		},
		getStatString(stat, value) {
			return getStatStringForTooltip(stat, value)
		},
		getImage(stat) {
			if (stat === 'iceDamage') {
				return elementalImages.ice
			} else if (stat === 'fireDamage') {
				return elementalImages.fire
			} else if (stat === 'physicalDamage') {
				return elementalImages.physical
			} else if (stat === 'poisonDamage') {
				return elementalImages.poison
			} else if (stat === 'lightningDamage') {
				return elementalImages.lightning
			}
			return undefined
		},
		getFactionIconAndStatPrettyName(stat){
			return this.augmentFactionIcon(this.lastWinningFaction) + " " + this.getStatPrettyName(stat)
		}
	},
}
