import { getLevelWorldTier1 } from '../../biome/shared/biome-list'
import { GameModeType } from '../../engine/shared/game-mode-type'
import StatModPoolType from './stat-mod-pool-type'

const LEVEL_WORLD_TIER_1 = getLevelWorldTier1(GameModeType.Adventure)

export enum WeaponEnchantmentSubType {
	ExtraProjectiles = 101,
	FasterAttacks = 104,
	StrongerAttacks = 105,
	Pierce = 107,
	TightenSpread = 110,
	SplashDamage = 112,
	CritStrike = 114,
	BigAndBad = 115,
	WildShots = 117,
	ChargeOverload = 118,
	PointBlankBoom = 119,
	NoFinesse = 120,
	CritDamage = 121,

	PhysicalUp = 128,
	FireUp = 129,
	IceUp = 130,
	LightningUp = 131,
	PoisonUp = 132,
	AilmentPotency = 133,

	KnockBack = 134,
	Vacuum = 135,
	LongRange = 136,
	BurstShot = 137,
	ShockingFirestorm = 138,
	FastShields = 139,
	Shielded = 140,
	QuickSkills = 141,
	FissionEnergy = 142,
	GreviousWounds = 143,

	CatastropheBlast = 144,
	GreaterElements = 145,
	HeavyShielding = 146,

	HordeBreaker = 147,
	BloodiedGear = 148,
	FastFeet = 149,
	GearOvercharge = 150,
}

export const WeaponEnchantmentSubTypeValues = []
for(const val in WeaponEnchantmentSubType) {
	if(!isNaN(Number(val))) {
		WeaponEnchantmentSubTypeValues.push(Number(val))
	}
}
 
export const WeaponEnchantmentConfigData: Map<
	WeaponEnchantmentSubType,
	{
		prettyName: string
		description: string
		availableLevel: number
		modPool: StatModPoolType
	}
> = new Map()
{
	WeaponEnchantmentConfigData.set(WeaponEnchantmentSubType.ExtraProjectiles, {
		prettyName: 'Extra Projectiles',
		description: 'Add 2 projectiles, but all projectiles deal a bit less damage.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.ENCHANTMENT_EXTRA_PROJECTILES,
	})
	WeaponEnchantmentConfigData.set(WeaponEnchantmentSubType.FasterAttacks, {
		prettyName: 'Faster Attacks',
		description: 'Shoot much faster, at the cost of some damage per shot.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.ENCHANTMENT_FASTER_ATTACKS,
	})
	WeaponEnchantmentConfigData.set(WeaponEnchantmentSubType.StrongerAttacks, {
		prettyName: 'Stronger Attacks',
		description: 'Hit HARD. More damage of any type.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.ENCHANTMENT_STRONGER_ATTACKS,
	})
	WeaponEnchantmentConfigData.set(WeaponEnchantmentSubType.Pierce, {
		prettyName: 'Pierce',
		description: 'Cause your projectiles to pierce through enemies and deal a bit more damage.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.ENCHANTMENT_PIERCE,
	})
	WeaponEnchantmentConfigData.set(WeaponEnchantmentSubType.SplashDamage, {
		prettyName: 'Explosive Shots',
		description: 'Causes your projectiles to explode for significant damage, and deal a bit more damage.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.ENCHANTMENT_SPLASH_DAMAGE,
	})
	WeaponEnchantmentConfigData.set(WeaponEnchantmentSubType.CritStrike, {
		prettyName: 'Critical Strikes',
		description: 'Increases your chance to deal critical damage.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.ENCHANTMENT_CRIT_STRIKE,
	})
	WeaponEnchantmentConfigData.set(WeaponEnchantmentSubType.BigAndBad, {
		prettyName: 'Big & Bad',
		description: 'Makes your beams and shots bigger, badder and stronger, but fire slower.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.ENCHANTMENT_BIG_AND_BAD,
	})
	WeaponEnchantmentConfigData.set(WeaponEnchantmentSubType.TightenSpread, {
		prettyName: 'Tighten Spread',
		description: 'Tightens your weapon\'s spread and accuracy, and deal a bit more damage.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.ENCHANTMENT_TIGHTEN_SPREAD,
	})
	WeaponEnchantmentConfigData.set(WeaponEnchantmentSubType.WildShots, {
		prettyName: 'Wild Shots',
		description: 'Your shots are a little more inaccurate, but they deal a lot more damage.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.ENCHANTMENT_WILD_SHOTS,
	})
	WeaponEnchantmentConfigData.set(WeaponEnchantmentSubType.ChargeOverload, {
		prettyName: 'Charge Overload',
		description: 'Charge up sloooowly to unleash massive damage.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.ENCHANTMENT_CHARGE_OVERLOAD,
	})
	WeaponEnchantmentConfigData.set(WeaponEnchantmentSubType.PointBlankBoom, {
		prettyName: 'Point Blank Boom',
		description: 'Big damage, big boom, low range.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.ENCHANTMENT_POINT_BLANK_BOOM,
	})
	WeaponEnchantmentConfigData.set(WeaponEnchantmentSubType.NoFinesse, {
		prettyName: 'No Finesse',
		description: 'Deal more damage, but lose ailment potency.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.ENCHANTMENT_NO_FINESSE,
	})
	WeaponEnchantmentConfigData.set(WeaponEnchantmentSubType.CritDamage, {
		prettyName: 'Critical Damage',
		description: 'Greatly increases the damage of your critical hits.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.ENCHANTMENT_CRIT_DAMAGE,
	})
	WeaponEnchantmentConfigData.set(WeaponEnchantmentSubType.PhysicalUp, {
		prettyName: 'Physical Damage+',
		description: 'Greatly increase your Physical damage.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.ENCHANTMENT_PHYSICAL_UP,
	})
	WeaponEnchantmentConfigData.set(WeaponEnchantmentSubType.FireUp, {
		prettyName: 'Fire Damage+',
		description: 'Greatly increase your Fire damage.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.ENCHANTMENT_FIRE_UP,
	})
	WeaponEnchantmentConfigData.set(WeaponEnchantmentSubType.IceUp, {
		prettyName: 'Ice Damage+',
		description: 'Greatly increase your Ice damage.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.ENCHANTMENT_ICE_UP,
	})
	WeaponEnchantmentConfigData.set(WeaponEnchantmentSubType.LightningUp, {
		prettyName: 'Lightning Damage+',
		description: 'Greatly increase your Lightning damage.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.ENCHANTMENT_LIGHTNING_UP,
	})
	WeaponEnchantmentConfigData.set(WeaponEnchantmentSubType.PoisonUp, {
		prettyName: 'Poison Damage+',
		description: 'Greatly increase your Poison damage.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.ENCHANTMENT_POISON_UP,
	})
	WeaponEnchantmentConfigData.set(WeaponEnchantmentSubType.AilmentPotency, {
		prettyName: 'Ailment Potency',
		description: 'Greatly increases the potency of your Ailments, but lowers direct damage.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.ENCHANTMENT_AILMENT_POTENCY,
	})
	WeaponEnchantmentConfigData.set(WeaponEnchantmentSubType.KnockBack, {
		prettyName: 'Knockback',
		description: 'Adds knockback to your attacks, and makes them hit harder.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.ENCHANTMENT_KNOCKBACK,
	})
	WeaponEnchantmentConfigData.set(WeaponEnchantmentSubType.Vacuum, {
		prettyName: 'Vacuum',
		description: 'Adds a vacuum effect to your attacks, and makes them hit harder.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.ENCHANTMENT_VACUUM,
	})
	WeaponEnchantmentConfigData.set(WeaponEnchantmentSubType.LongRange, {
		prettyName: 'Long Range',
		description: 'Extends the range of your weapons, and deal a bit more damage.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.ENCHANTMENT_LONG_RANGE,
	})
	WeaponEnchantmentConfigData.set(WeaponEnchantmentSubType.BurstShot, {
		prettyName: 'Burst Shot',
		description: 'Fire 3 round bursts, but each shot deals less damage.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.ENCHANTMENT_BURST_SHOT,
	})
	WeaponEnchantmentConfigData.set(WeaponEnchantmentSubType.ShockingFirestorm, {
		prettyName: 'Shocking Firestorm',
		description: 'Increase your chance to Shock and Ignite and their strength.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.ENCHANTMENT_SHOCKING_FIRESTORM,
	})
	WeaponEnchantmentConfigData.set(WeaponEnchantmentSubType.FastShields, {
		prettyName: 'Fast Shields',
		description: 'Your shields regenerate much faster, but your health is reduced by 25%.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.ENCHANTMENT_FAST_SHIELDS,
	})
	WeaponEnchantmentConfigData.set(WeaponEnchantmentSubType.Shielded, {
		prettyName: 'Shielded',
		description: 'Gain an additional shield.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.ENCHANTMENT_SHIELDED,
	})
	WeaponEnchantmentConfigData.set(WeaponEnchantmentSubType.QuickSkills, {
		prettyName: 'Quick Skills',
		description: 'Lowers the cooldown on your gear skills.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.ENCHANTMENT_QUICK_SKILLS,
	})
	WeaponEnchantmentConfigData.set(WeaponEnchantmentSubType.FissionEnergy, {
		prettyName: 'Fission Energy',
		description: 'Your shots consume much less energy.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.ENCHANTMENT_FISSION_ENERGY,
	})
	WeaponEnchantmentConfigData.set(WeaponEnchantmentSubType.GreviousWounds, {
		prettyName: 'Grevious Wounds',
		description: 'Greatly increase your chance to Bleed, and convert some damage to Physical.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.ENCHANTMENT_GREVIOUS_WOUNDS,
	})
	WeaponEnchantmentConfigData.set(WeaponEnchantmentSubType.CatastropheBlast, {
		prettyName: 'Catastrophe Blast',
		description: 'Your explosions grow in power dramatically.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.ENCHANTMENT_CATASTROPHE_BLAST,
	})
	WeaponEnchantmentConfigData.set(WeaponEnchantmentSubType.GreaterElements, {
		prettyName: 'Greater Elements',
		description: 'The potency of  your elements increases significantly.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.ENCHANTMENT_GREATER_ELEMENTS,
	})
	WeaponEnchantmentConfigData.set(WeaponEnchantmentSubType.HeavyShielding, {
		prettyName: 'Heavy Shielding',
		description: 'You are slowed, but gain 2 shields and ignore damaging elements while you have shields.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.ENCHANTMENT_HEAVY_SHIELDING,
	})
	WeaponEnchantmentConfigData.set(WeaponEnchantmentSubType.HordeBreaker, {
		prettyName: 'Horde Breaker',
		description: 'Your projectiles pierce and explode, but have wider spread.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.ENCHANTMENT_HORDE_BREAKER,
	})
	WeaponEnchantmentConfigData.set(WeaponEnchantmentSubType.BloodiedGear, {
		prettyName: 'Bloodied Gear',
		description: 'Each enemy kill reduces the cooldown on your Gear Skills.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.ENCHANTMENT_BLOODIED_GEAR,
	})
	WeaponEnchantmentConfigData.set(WeaponEnchantmentSubType.FastFeet, {
		prettyName: 'Fast Feet',
		description: 'You move & roll more quickly.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.ENCHANTMENT_FAST_FEET,
	})
	WeaponEnchantmentConfigData.set(WeaponEnchantmentSubType.GearOvercharge, {
		prettyName: 'Gear Overcharge',
		description: 'Your Gear skills have a much longer duration, but also much longer cooldowns.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.ENCHANTMENT_GEAR_OVERCHARGE,
	})
}

export const WeaponEnchantmentSubTypes = [
	WeaponEnchantmentSubType.ExtraProjectiles,
	WeaponEnchantmentSubType.FasterAttacks,
	// WeaponEnchantmentSubType.StrongerAttacks,
	WeaponEnchantmentSubType.Pierce,
	WeaponEnchantmentSubType.TightenSpread,
	WeaponEnchantmentSubType.SplashDamage,
	WeaponEnchantmentSubType.CritStrike,
	WeaponEnchantmentSubType.BigAndBad,
	WeaponEnchantmentSubType.WildShots,
	WeaponEnchantmentSubType.ChargeOverload,
	WeaponEnchantmentSubType.PointBlankBoom,
	WeaponEnchantmentSubType.NoFinesse,
	// WeaponEnchantmentSubType.CritDamage,
	WeaponEnchantmentSubType.PhysicalUp,
	WeaponEnchantmentSubType.FireUp,
	WeaponEnchantmentSubType.IceUp,
	WeaponEnchantmentSubType.LightningUp,
	WeaponEnchantmentSubType.PoisonUp,
	WeaponEnchantmentSubType.AilmentPotency,
	WeaponEnchantmentSubType.KnockBack,
	WeaponEnchantmentSubType.Vacuum,
	WeaponEnchantmentSubType.LongRange,
	WeaponEnchantmentSubType.BurstShot,
	WeaponEnchantmentSubType.ShockingFirestorm,
	WeaponEnchantmentSubType.FastShields,
	WeaponEnchantmentSubType.Shielded,
	WeaponEnchantmentSubType.QuickSkills,
	WeaponEnchantmentSubType.FissionEnergy,
	WeaponEnchantmentSubType.GreviousWounds,
	WeaponEnchantmentSubType.CatastropheBlast,
	WeaponEnchantmentSubType.GreaterElements,
	WeaponEnchantmentSubType.HeavyShielding,
	WeaponEnchantmentSubType.HordeBreaker,
	WeaponEnchantmentSubType.BloodiedGear,
	WeaponEnchantmentSubType.FastFeet,
	WeaponEnchantmentSubType.GearOvercharge,

	// These exist as enchantments for debug purposes, uncomment if wanted to appear as reward enchantments
	// WeaponEnchantmentSubType.SineWave,
	// WeaponEnchantmentSubType.Zigzag,
	// WeaponEnchantmentSubType.Circular,
	// WeaponEnchantmentSubType.Orbit,
	// WeaponEnchantmentSubType.Spiral,
	// WeaponEnchantmentSubType.Looping,
	// WeaponEnchantmentSubType.Grow,
]

export default WeaponEnchantmentSubType
