import { Container, Graphics, Text } from 'pixi.js'
import { Vector } from 'sat'
import Enemy from '../../ai/shared/enemy.shared'
import { Audio, EnemySoundEffects } from '../../audio/client/audio'
import { AppliedBuffVisualsArray, clearAttachedPfx, handleBuffChange } from '../../buffs/client/buff.client'
import { BuffIdentifier, MAX_VISIBLE_BUFFS } from '../../buffs/shared/buff.shared'
import { createColliders, updateColliderPositions } from '../../collision/shared/colliders'
import { drawColliderVisuals, getAnimTrackString } from '../../debug/client/debug-client'
import { clientConfig } from '../../engine/client/client-config'
import { debugConfig } from '../../engine/client/debug-config'
import { GameClient } from '../../engine/client/game-client'
import { filterFromString } from '../../engine/client/graphics/filters'
import Renderer from '../../engine/client/graphics/renderer'
import { getPressureLoadoutOnClient } from '../../engine/client/pressure.client'
import { BOSS_HEALTH_MULTIPLIER, DamageConfig, ENEMY_DEFAULT_HEALTH, getMaximumHealth } from '../../engine/shared/game-data/enemy-formulas'
import { getDifficultyLevelFromWorldTier } from '../../engine/shared/game-data/progression'
import { createModel } from '../../models-animations/client/model-setup'
import { playAnimation, playDeathAnimation, stopAnimation } from '../../models-animations/client/play-animation'
import { RiggedSpineModel } from '../../models-animations/client/spine-model'
import { AnimationTrack } from '../../models-animations/shared/animation-track'
import WeaponFiredMessage from '../../projectiles/shared/weapon-fired-message'
import { attachments_removeAttachments } from '../../utils/attachments-system'
import logger from '../../utils/client-logger'
import { assertLight } from '../../utils/debug'
import { updateEntityHealthVisuals } from '../../utils/entity-util'
import { gameUnits, levelOffset } from '../../utils/primitive-types'
import { simpleAnimation_addPropertyAnimation } from '../../utils/simple-animation-system'
import { MiddlegroundRenderer } from '../../world/client/middleground-renderer'
import { getLocalPlayer } from '../../world/client/poi.client'
import { EnemyType, getMainAppearance } from '../shared/ai-types'
import { updateAimFacing, updateAimRotation } from '../shared/anim-util'
import EnemyDefinitions from '../shared/enemy-definitions'
import { championFilterUpdate } from './champion-filter'
import { HealthBar } from './health-bar'

// TODO3 Mike: is there a good spot for generic game config values?
const MOVEMENT_TRACK_END_ADJUST = 0.15
const MOVEMENT_EPSILON = 0.1
const SPAWN_EFFECT_FADE_TIME = 0.2
const SPAWN_TIME_FOR_LOOP_SMOKE = 0.175

const DEBUG_BRAIN_MAIN_TEXT_STYLE = {
	fontFamily: 'Arial',
	fontSize: 20,
	fill: 0xffffff,
	stroke: 0x000000,
	strokeThickness: 3,
	align: 'left',
}

const DEBUG_BRAIN_SUB_TEXT_STYLE = {
	fontFamily: 'Arial',
	fontSize: 20,
	fill: 0x7de9ff,
	stroke: 0x000000,
	strokeThickness: 3,
	align: 'left',
}

export class ClientEnemy extends Enemy {
	readonly enemyConfig: string
	readonly debugModel: Container
	model: Container
	riggedModel: RiggedSpineModel
	aggroRadiusVisual: Graphics
	debugStringText: Text
	colliderVisual: Graphics
	currentStateText: Text
	engagementDistanceVisual: Graphics
	engagementMinDistanceVisual: Graphics
	currentSubStateText: Text
	engagementDistanceText: Text
	engagementMinDistanceText: Text
	aggroRadiusText: Text
	currentState: string
	currentSubState: string
	aggroRadius: gameUnits
	engagementMaxDistance: gameUnits
	engagementMinDistance: gameUnits
	maxHealth: number
	currentHealth: number
	damageConfig: DamageConfig
	healthbar: HealthBar
	levelText: Text
	levelOffset: levelOffset
	debugHealthbarVisualWidth: number = 100
	debugHealthbarVisualHeight: number = 15
	alwaysShowHealthbar: boolean = false
	x: gameUnits
	y: gameUnits
	oldX: gameUnits
	oldY: gameUnits
	zOffset: gameUnits
	soundEffects: EnemySoundEffects
	attachedBuffEffects: AppliedBuffVisualsArray = [null, null, null]

	mainBrainPixiText: PIXI.Text
	subBrainPixiText: PIXI.Text

	get visualPos() {
		return this
	}
	get zIndex(): number {
		return this.model.zIndex
	}

	private prevVisible = true
	static healthBarOffsets: Map<string, number> = new Map()

	constructor(entityData) {
		super()

		this.enemyConfig = entityData.enemyConfig
		this.config = EnemyDefinitions.get(entityData.enemyConfig)

		this.x = entityData.x
		this.y = entityData.y
		this.enemyType = entityData.enemyType
		this.soundEffects = this.config.soundEffects
		this.damageConfig = this.config.baseAttributes.damageConfig

		this.model = new Container()
		this.model.name = `enemy`
		this.model.filters = []
		// tslint:disable-next-line
		this.model['update'] = (dt) => {
			this.riggedModel.update(dt)
		}
		Renderer.getInstance().mgRenderer.addDisplayObjectToScene(this.model)

		this.riggedModel = createModel(this.config.appearance, 'enemy-')
		this.riggedModel.filters = []
		this.model.addChild(this.riggedModel)

		if (debugConfig.enemy.enableBrainDebugger) {
			this.mainBrainPixiText = new PIXI.Text(entityData.mainBrainPixiText, DEBUG_BRAIN_MAIN_TEXT_STYLE)
			this.subBrainPixiText = new PIXI.Text(entityData.subBrainPixiText, DEBUG_BRAIN_SUB_TEXT_STYLE)
			this.subBrainPixiText.y = 25

			this.model.addChild(this.mainBrainPixiText)
			this.model.addChild(this.subBrainPixiText)
		}

		this.colliders = createColliders(this, this.config.baseAttributes.colliders)

		if (clientConfig.enemyFilter) {
			this.riggedModel.filters = [filterFromString(clientConfig.enemyFilter, clientConfig.filterParams)]
		}

		const modelConfig = getMainAppearance(this.config)
		this.alwaysShowHealthbar = modelConfig.showHealthBarWhenFull || debugConfig.enemy.alwaysShowHealthBars

		this.zOffset = modelConfig.zOffset ?? 0

		const renderer = Renderer.getInstance()

		const alreadyDead = entityData.currentHealth <= 0

		// TODO2: this happens offscreen for *most* enemies, so could be cost we don't need
		if (!alreadyDead) {
			const isChampion = this.enemyType === EnemyType.CHAMPION || this.enemyType === EnemyType.LOOTLESS_CHAMPION

			if (!this.config.appearance.spawnAnim) {
				if (entityData.spawnInTimer >= SPAWN_TIME_FOR_LOOP_SMOKE) {
					const effect = renderer.addOneOffEffect('smoke-explosion-loop', entityData.x, entityData.y, entityData.y + 1, 1, entityData.spawnInTimer)
					const timeToStartFading = Math.max(0, entityData.spawnInTimer - SPAWN_EFFECT_FADE_TIME)
					const fadeTime = Math.min(entityData.spawnInTimer, SPAWN_EFFECT_FADE_TIME)
					simpleAnimation_addPropertyAnimation(effect, 'alpha', (time, delta) => {
						if (time >= timeToStartFading) {
							const smallTime = time - timeToStartFading
							return Math.max(0, 1 - smallTime / fadeTime)
						}
						return 1
					}, entityData.spawnInTimer)

					setTimeout(() => {
						championFilterUpdate(this, isChampion || debugConfig.enemy.alwaysShowChampionVisuals)
						renderer.addOneOffEffect('smoke-explosion', entityData.x, entityData.y, entityData.y + 1, 1)
					}, entityData.spawnInTimer * 1000);
				} else {
					renderer.addOneOffEffect('smoke-explosion', entityData.x, entityData.y, entityData.y + 1, 1)
					championFilterUpdate(this, isChampion || debugConfig.enemy.alwaysShowChampionVisuals)
				}
			} else {
				championFilterUpdate(this, isChampion || debugConfig.enemy.alwaysShowChampionVisuals)
			}

			if (this.config.type !== EnemyType.BOSS || debugConfig.enemy.showHealthAndLevelText) {
				let healthbarYOffset = ClientEnemy.healthBarOffsets.get(this.config.name)
				if (!healthbarYOffset) {
					const offset = new PIXI.spine.core.Vector2(0, 0)
					const size = new PIXI.spine.core.Vector2(0, 0)

					this.riggedModel.skeleton.setToSetupPose()
					this.riggedModel.skeleton.update(0)

					this.riggedModel.skeleton.getBounds(offset, size) // mutates passed vectors
					healthbarYOffset = -size.y - 10

					ClientEnemy.healthBarOffsets.set(this.config.name, healthbarYOffset)
				}

				this.getHealthbar(healthbarYOffset)
			}

			if (this.config.type !== EnemyType.BOSS || debugConfig.enemy.showHealthAndLevelText) {
				this.drawLevelVisuals()
			}

			playAnimation(this.riggedModel, entityData.idleAnimation as AnimationTrack, null, 1)
		}

		if (clientConfig.debug) {
			const pos = new Vector(entityData.x, entityData.y)
			renderer.addTweakerCollidersIfEnabled(entityData.enemyConfig, pos, this.config.baseAttributes.colliders)

			this.debugModel = new Container()
			this.debugModel.name = 'debug-enemy'

			if (clientConfig.renderAnimDebugVisuals) {
				this.drawGenericDebugVisuals()
			}

			if (debugConfig.render.aiDebug) {
				const { aggroRadius, currentState, currentSubState, engagementMaxDistance, engagementMinDistance, currentHealth, maxHealth } = entityData
				this.currentState = currentState
				this.maxHealth = Math.floor(maxHealth)
				this.currentHealth = Math.floor(currentHealth)
				this.currentSubState = currentSubState
				this.aggroRadius = aggroRadius
				this.engagementMaxDistance = engagementMaxDistance
				this.engagementMinDistance = engagementMinDistance
				this.drawAggroRadiusDebugVisuals()
				this.drawEngagementRadiusDebugVisuals()
				this.drawEngagementMinRadiusDebugVisuals()
				this.drawColliderVisuals()
				this.drawAIStateDebugVisuals()
			}
			renderer.debugMiddleground.addChild(this.debugModel)
		}

		if (alreadyDead) {
			this.model.removeChild(this.levelText)

			playDeathAnimation(this.riggedModel, 100) // quickly get to the end of the death animation
		}
	}

	onDelete() {
		const model = this.model
		const parent = model.parent as MiddlegroundRenderer
		parent.removeChild(model)
		parent.removeFromScene(model)
		this.riggedModel.returnToPoolIfPooled()
		this.model = null

		this.healthbar?.returnToPool()

		attachments_removeAttachments(this)

		clearAttachedPfx(this)
	}

	update(delta: number): void {
		if (clientConfig.debug) {
			if (clientConfig.renderAnimDebugVisuals) {
				_updateDebugString(this)
			}
			if (this.currentStateText) {
				this.currentStateText.text = this.getDebugStateString()
			}
		}

		if (this.prevVisible !== this.visible) {
			this.model.visible = this.visible
			this.prevVisible = this.visible

			this.attachedBuffEffects.forEach((buffEffect) => {
				if (buffEffect) {
					buffEffect.effects.forEach((effect) => {
						effect.visible = this.visible
					})
				}
			})
		}

		this.updateColliderPositions()

		this.healthbar?.update(delta)
	}

	updateColliderPositions() {
		updateColliderPositions(this.config.baseAttributes.colliders, this.colliders, this, this.facingDirection)
	}

	handleWeaponFiredMessage(message: WeaponFiredMessage) {
		// TODO2: need better way of controlling boss animations
		if (!this.config.actionDrivenEnemyData && !this.config.states.fighting.brain) {
			//TODO3: add networked attackCooldowns
			// const frames = 20
			// const frameLength = 0.016666
			// const attackCooldown = 1
			// let timeScale = 1 / (attackCooldown / (frames * frameLength))
			const timeScale = 1
			playAnimation(this.riggedModel, AnimationTrack.SHOOT, null, timeScale)
		}
		Audio.getInstance().playSfx(this.soundEffects.attack)
	}

	die(playDeathPfx: boolean) {
		this.healthbar?.fadeOut() //could be null with dead enemies attacking bug
		this.model.removeChild(this.levelText)

		playDeathAnimation(this.riggedModel)
	}

	drawLevelVisuals() {
		if (this.healthbar) {
			this.levelText = new Text('', {
				fontFamily: 'Yanone Kaffeesatz',
				fontSize: 24,
				fontWeight: '900',
				dropShadow: true,
				dropShadowAngle: 2,
				dropShadowColor: '#2a313e',
				dropShadowDistance: 4,
				stroke: '#2a313e',
				strokeThickness: 2,
				fill: 0xffffff,
				align: 'center',
			})
			this.levelText.x = -this.healthbar.getContainer().width / 2
			this.levelText.y = -26
			this.model.addChild(this.levelText)
		}
	}

	getHealthbar(yOffset) {
		this.healthbar = HealthBar.pool.alloc()

		const container = this.healthbar.getContainer()
		container.visible = this.alwaysShowHealthbar

		container.y = yOffset

		this.healthbar.setParentContainer(this.model)
	}

	getDebugStateString(): string {
		let stateString: string = ``
		let buffString = `[BUFF]`
		for (let i = 0; i < MAX_VISIBLE_BUFFS; i++) {
			buffString += this[`buff${i + 1}`] + ', '
		}
		stateString += [this.enemyConfig, this.currentState, this.currentSubState, buffString].join('\n')
		//stateString += `\nidle:${this.idleAnimation}, move:${this.moveAnimation}`
		//stateString += '\n---------\n' + getAnimTrackString(this.model[0])
		return stateString
	}

	private drawColliderVisuals() {
		const enemyConfig = this.config
		this.colliderVisual = drawColliderVisuals(enemyConfig.baseAttributes.colliders)
		this.debugModel.addChild(this.colliderVisual)
	}

	private drawAggroRadiusDebugVisuals() {
		this.aggroRadiusVisual = new Graphics()
		this.aggroRadiusVisual.lineStyle(4, 0xff0000)
		this.aggroRadiusVisual.drawCircle(0, this.config.states.fighting.modelCenterOffset === undefined ? 0 : -this.config.states.fighting.modelCenterOffset, this.aggroRadius)
		this.aggroRadiusVisual.endFill()
		this.aggroRadiusText = new PIXI.Text(`aggro radius (${this.aggroRadius})`, {
			fontFamily: 'Arial',
			fontSize: 16,
			fill: 0xffffff,
			align: 'center',
		})
		this.aggroRadiusText.y = -this.aggroRadius - 25
		this.aggroRadiusText.x = -45
		this.debugModel.addChild(this.aggroRadiusVisual)
		this.debugModel.addChild(this.aggroRadiusText)
	}

	private drawEngagementRadiusDebugVisuals() {
		this.engagementDistanceVisual = new Graphics()
		this.engagementDistanceVisual.lineStyle(4, 0x9400d3)
		this.engagementDistanceVisual.drawCircle(0, this.config.states.fighting.modelCenterOffset === undefined ? 0 : -this.config.states.fighting.modelCenterOffset, this.engagementMaxDistance)
		this.engagementDistanceVisual.endFill()
		this.engagementDistanceText = new PIXI.Text(`engagement distance (${this.engagementMaxDistance})`, {
			fontFamily: 'Arial',
			fontSize: 16,
			fill: 0xffffff,
			align: 'center',
		})
		this.engagementDistanceText.y = -this.engagementMaxDistance - 25
		this.engagementDistanceText.x = -75
		this.debugModel.addChild(this.engagementDistanceText)
		this.debugModel.addChild(this.engagementDistanceVisual)
	}

	private drawEngagementMinRadiusDebugVisuals() {
		this.engagementMinDistanceVisual = new Graphics()
		this.engagementMinDistanceVisual.lineStyle(4, 0x9400d3)
		this.engagementMinDistanceVisual.drawCircle(0, this.config.states.fighting.modelCenterOffset === undefined ? 0 : -this.config.states.fighting.modelCenterOffset, this.engagementMinDistance)
		this.engagementMinDistanceVisual.endFill()
		this.engagementMinDistanceText = new PIXI.Text(`engagement min distance (${this.engagementMinDistance})`, {
			fontFamily: 'Arial',
			fontSize: 16,
			fill: 0xffffff,
			align: 'center',
		})
		this.engagementMinDistanceText.y = -this.engagementMinDistance - 25
		this.engagementMinDistanceText.x = -75
		this.debugModel.addChild(this.engagementMinDistanceText)
		this.debugModel.addChild(this.engagementMinDistanceVisual)
	}

	private drawAIStateDebugVisuals() {
		const stateString: string = this.getDebugStateString()

		this.currentStateText = new PIXI.Text(stateString, {
			fontFamily: 'Arial',
			fontSize: 20,
			fill: [0xcccccc, 0xffffff],
			stroke: 0x000000,
			strokeThickness: 3,
			align: 'left',
		})
		this.currentStateText.x -= 15
		this.currentStateText.y += 15
		this.debugModel.addChild(this.currentStateText)
	}

	private drawGenericDebugVisuals() {
		this.debugStringText = new PIXI.Text('', {
			fontFamily: 'Arial',
			fontSize: 16,
			fill: 0xffffff,
			align: 'left',
		})
		this.debugStringText.x = 60
		this.debugStringText.y = -65
		this.debugModel.addChild(this.debugStringText)
	}
}

export const clientEnemyUpdateHooks = {
	currentState(entity: ClientEnemy, newStateString): void {
		if (clientConfig.debug && entity.currentStateText) {
			entity.currentStateText.text = entity.getDebugStateString()
		}
	},
	currentSubState(entity: ClientEnemy, newValue): void {
		if (clientConfig.debug && entity.currentStateText) {
			entity.currentStateText.text = entity.getDebugStateString()
		}
	},
	currentHealth(entity: ClientEnemy, newValue): void {
		if (!clientConfig.renderDebugVisuals) {
			updateEntityHealthVisuals(entity, newValue, entity.maxHealth, entity.alwaysShowHealthbar)
		}
		_updateEntityLevelHealthText(entity)
	},
	maxHealth(entity: ClientEnemy, newValue): void {
		if (!clientConfig.renderDebugVisuals) {
			updateEntityHealthVisuals(entity, entity.currentHealth, newValue, entity.alwaysShowHealthbar)
		}
	},
	levelOffset(entity: ClientEnemy, newValue): void {
		_updateEntityLevelHealthText(entity)
	},
	x(entity: ClientEnemy, newValue): void {
		if (Math.abs(entity.x - entity.oldX) > MOVEMENT_EPSILON) {
			_playMovementAnim(entity)
		}
		entity.oldX = newValue
	},
	y(entity: ClientEnemy, newValue): void {
		if (Math.abs(entity.y - entity.oldY) > MOVEMENT_EPSILON) {
			_playMovementAnim(entity)
		}
		entity.model.zIndex = newValue + entity.zOffset
		entity.oldY = newValue
	},
	facingDirection(entity: ClientEnemy, newValue: number): void {
		// CURRENTLY NOT SUPPORTING FLIPPING OF COLLIDERS
		// const colliderVisual = entity.colliderVisual as Graphics
		// if (colliderVisual) {
		// 	colliderVisual.scale.x = newValue
		// }
	},
	visualAimAngle(entity: ClientEnemy, newValue: number): void {
		const rotBone: PIXI.spine.core.Bone = entity.riggedModel.skeleton.findBone('root-aim')
		updateAimFacing(entity.riggedModel, entity.facingDirection, true)
		if (rotBone) {
			updateAimRotation(rotBone, newValue + Math.PI, entity.facingDirection)
		}
	},
	idleAnimation(entity: ClientEnemy, newValue: AnimationTrack): void {
		playAnimation(entity.riggedModel, newValue as AnimationTrack)
	},
	changeAnimation(entity: ClientEnemy, newValue: AnimationTrack, _: any, oldValue: AnimationTrack) {
		if (oldValue) {
			stopAnimation(entity.riggedModel, oldValue)
		}
		playAnimation(entity.riggedModel, newValue as AnimationTrack)
	},
	buff1(entity: ClientEnemy, newValue: BuffIdentifier) {
		handleBuffChange(entity, newValue, 0)
	},
	buff2(entity: ClientEnemy, newValue: BuffIdentifier) {
		handleBuffChange(entity, newValue, 1)
	},
	buff3(entity: ClientEnemy, newValue: BuffIdentifier) {
		handleBuffChange(entity, newValue, 2)
	},
	buff4(entity: ClientEnemy, newValue: BuffIdentifier) {
		handleBuffChange(entity, newValue, 3)
	},
	buff5(entity: ClientEnemy, newValue: BuffIdentifier) {
		handleBuffChange(entity, newValue, 4)
	},
	buff6(entity: ClientEnemy, newValue: BuffIdentifier) {
		handleBuffChange(entity, newValue, 5)
	},
	mainBrainText(entity: ClientEnemy, newValue: string) {
		if(entity.mainBrainPixiText) {
			entity.mainBrainPixiText.text = newValue
		}
	},
	subBrainText(entity: ClientEnemy, newValue: string) {
		if(entity.subBrainPixiText) {
			entity.subBrainPixiText.text = newValue
		}
	}
}

function _updateDebugString(entity: ClientEnemy) {
	const animString = getAnimTrackString(entity.riggedModel)
	entity.debugStringText.text = animString
}

function _playMovementAnim(entity: ClientEnemy) {
	const trackEntry = playAnimation(entity.riggedModel, entity.moveAnimation)
	assertLight(trackEntry, 'no track entry for movement', logger)
	if (trackEntry) {
		trackEntry.trackEnd = trackEntry.trackTime + MOVEMENT_TRACK_END_ADJUST
	}
}

/**
 * Tries to get the local players world tier
 * @returns players tier if local player is available, otherwise 1
 */
function debug_tryGetLocalPlayerDifficultyLevel() {
	const player = getLocalPlayer()
	if (player) {
		const tier = player.activeWorldTier
		const difficultyLevel = getDifficultyLevelFromWorldTier(tier)
		return difficultyLevel
	}

	return 1
}

function _updateEntityLevelHealthText(entity: ClientEnemy) {
	if (debugConfig.enemy.showHealthAndLevelText) {
		if (!entity.levelText) {
			entity.drawLevelVisuals()
		}
		if (!entity.levelText) {
			return // TODO2 fix this properly after playtest Mike
		}
		const difficultyLevel = debug_tryGetLocalPlayerDifficultyLevel()
		const biomeIdx = Math.floor(difficultyLevel / 3)
		const difficultyHealth = getMaximumHealth(biomeIdx, entity.levelOffset, entity.enemyType, getPressureLoadoutOnClient(), GameClient.getInstance().serverWorldDifficulty)
		let baseHealth = ENEMY_DEFAULT_HEALTH
		if (entity.enemyType === EnemyType.BOSS) {
			baseHealth *= BOSS_HEALTH_MULTIPLIER
		}
		const differenceFromBaseHealthMultiplier = difficultyHealth / baseHealth

		const visualHealth = entity.currentHealth * differenceFromBaseHealthMultiplier
		const visualMaxHealth = entity.maxHealth * differenceFromBaseHealthMultiplier

		const champion = entity.enemyType === EnemyType.CHAMPION || entity.enemyType === EnemyType.LOOTLESS_CHAMPION
		const boss = entity.enemyType === EnemyType.BOSS
		const visualHealthString = `${Math.floor(visualHealth).toString().commafy()}/${Math.floor(visualMaxHealth).toString().commafy()}`
		const debugHealthString = '' // `(${Math.floor(entity.currentHealth)}/${Math.floor(entity.maxHealth)})`
		entity.levelText.text = `L${entity.levelOffset + difficultyLevel}${boss ? '👑 Boss' : ''}${champion ? '🏆 Champion' : ''}  ${visualHealthString}${debugHealthString ? ' | ' : ''}${debugHealthString}`
	}
}
