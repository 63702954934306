import { cloneDeep } from 'lodash'
import { AnyColliderConfig, ColliderType } from '../../collision/shared/colliders'

export type BiomeId = string // 'forest
export type PropName = string // 'small-uncommon-03'
export type PropId = string // 'forest/small-uncommon-03'

export interface PropConfig {
	/** WARNING: only for non-animated props right now */
	zeroHeight?: string[]
	/** adds this to the zIndex of the prop (changes rendering order based on y coord) */
	zOffset?: number
	/**
	 * one of the 5 collider types:
	 *
	 * **Circle**: type:'circle, position:[0,0], radius:30, traits:ColliderTraits.All
	 *
	 * **Ellipse**: type:'ellipse, position:[0,0], rX:50, rY:30, traits:ColliderTraits.All
	 *
	 * **Box**: type:'box, position:[0,0], width:100, height:120, traits:ColliderTraits.All
	 *
	 * **Polygon**: type:'polygon', position:[0,0], vertices:[0,0, 100,0, 100,100, 0,100], traits:ColliderTraits.All
	 *
	 * **Lookup**: type:'lookup', position:[0,0], traits:ColliderTraits.All
	 */
	colliders?: AnyColliderConfig[]
	rigged?: boolean
	dontLoadAsset?: boolean
	/** props assume their atlas comes from ${biome}PropAtls, but some have their own atlas */
	ignoreSharedAtlas?: boolean
	/** like ignoreSharedAtlas, but can specify the atlas' name */
	overrideAtlas?: string
	/** nengi visibilityRadius for this prop, will use PROP_DEFAULT_VISIBILITY_RADIUS if not supplied */
	visibilityRadius?: number
	disableFileOrdering?: boolean
	disableSubPropZOffs?: boolean
}

export const makePropId = function(biome: BiomeId, propName: PropName) {
	return `${biome}/${propName}`
}
export const getBiomeFromPropId = function(propId: PropId) {
	const words = propId.split('/')
	return words[0]
}
export const getPropNameFromPropId = function(propId: PropId) {
	const words = propId.split('/')
	return words[1]
}

/**
 * getPropConfig(fullPropId)
 * getPropConfig(biomeId, propName)
 * @param fullPropIdOrBiome id of prop in full form, eg: beach/mid-blocker-01-var01
 * @param optionalPropName
 */
export const getPropConfig = function(fullPropIdOrBiome: PropId | BiomeId, optionalPropName?: PropName): PropConfig {
	if (optionalPropName) {
		const propConfig = propConfigs[fullPropIdOrBiome][optionalPropName]
		return propConfig
	} else {
		const words = fullPropIdOrBiome.split('/')
		if (words.length !== 2) {
			throw new Error(`bad fullPropId in getPropConfig:${fullPropIdOrBiome}. fullPropId must be of form biome/propId, eg: 'beach/mid-blocker-01-var01'`)
		}
		const propConfig = propConfigs[words[0]][words[1]]
		return propConfig
	}
}

export interface PropConfigs {
	[index: string]: PropConfig
}

const blockerColliders = [{ type: ColliderType.Lookup, position: [0, 0] }]
Object.freeze(blockerColliders)

export function makeBlockPropConfig() {
	return {
		zeroHeight: ['low-blocker-01', 'low-blocker-02', 'low-blocker-03'],
		colliders: makeBlockerColliders(),
	}
}

function makeBlockerColliders(): AnyColliderConfig[] {
	return cloneDeep(blockerColliders) as AnyColliderConfig[]
}

import { beachPropConfigs } from './prop-configs-beach'
import { forestPropConfigs } from './prop-configs-forest'
import { fungiPropConfigs } from './prop-configs-fungi'
import { highlandsPropConfigs } from './prop-configs-highlands'
import { prismPropConfigs } from './prop-configs-prism'
import { townPropConfigs } from './prop-configs-town'
import { sharedPropConfigs } from './prop-configs-shared'

export const propConfigs = {
	beach: beachPropConfigs,
	forest: forestPropConfigs,
	fungi: fungiPropConfigs,
	prism: prismPropConfigs,
	highlands: highlandsPropConfigs,
	town: townPropConfigs,
	shared: sharedPropConfigs,
}
