import { Vector, Circle, Polygon, Box } from 'sat'
import { flatten, minBy, maxBy } from 'lodash'
import { makeSatPolygons, VectorXY } from '../../utils/math'
import { wetzls } from '../../utils/wetzls'
import { ColorUtil } from '../../utils/colors'
import { radians, gameUnits } from '../../utils/primitive-types'
import { Bounds } from './bounds'

// unfortunately, type has no type-safety, so these are more dangerous than they should be
export type SatCollider = Circle | Polygon | Box | Ellipse
//export type ColliderWithTraits = (CircleWithTraits | PolygonWithTraits | BoxWithTraits | EllipseWithTraits)
interface ColliderWithTraits {
	type: ColliderType
	pos: Vector
	traits: ColliderTraits
	disabled?: boolean
}

// SAT does not have ellipse so we define it here
export class Ellipse {
	pos: Vector
	rX: number
	rY: number
	constructor(pos: Vector, rX: number, rY: number) {
		this.pos = pos
		this.rX = rX
		this.rY = rY
	}
}

export class Line {
	pos: Vector
	pos2: Vector
	constructor(pos: Vector, p2: Vector) {
		this.pos = pos
		this.pos2 = p2
	}
}

export class Capsule {
	pos: Vector
	pos2: Vector
	r: number
	constructor(pos: Vector, p2: Vector, r: number) {
		this.pos = pos
		this.pos2 = p2
		this.r = r
	}
}

export enum ColliderType {
	Circle,
	Ellipse,
	Box,
	Polygon,
	Capsule,
	Lookup,
}
export interface ColliderConfig {
	type: ColliderType
	position: gameUnits[]
	/** angle in radians - only 'box' supported right now */
	angle?: radians
	traits: ColliderTraits
}

export interface CircleColliderConfig extends ColliderConfig {
	type: ColliderType.Circle
	radius: number
}

export interface PolygonColliderConfig extends ColliderConfig {
	type: ColliderType.Polygon
	vertices: number[]
}

export interface BoxColliderConfig extends ColliderConfig {
	type: ColliderType.Box
	width: number
	height: number
}

export interface EllipseColliderConfig extends ColliderConfig {
	type: ColliderType.Ellipse
	rX: number
	rY: number
}

export interface LookupColliderConfig extends ColliderConfig {
	type: ColliderType.Lookup
}

export type AnyColliderConfig = CircleColliderConfig | PolygonColliderConfig | BoxColliderConfig | EllipseColliderConfig | LookupColliderConfig
export type AnyCollider = CircleCollider | PolygonCollider | BoxCollider | EllipseCollider | CapsuleCollider

export class CircleCollider extends Circle implements ColliderWithTraits {
	type: ColliderType.Circle = ColliderType.Circle
	traits: ColliderTraits
	disabled?: boolean
	prevPosition?: Vector
	ignorePropThisFrame?: boolean

	constructor(traits: ColliderTraits, pos?: Vector, r?: number) {
		super(pos, r)
		this.traits = traits
	}
}

export class PolygonCollider extends Polygon implements ColliderWithTraits {
	traits: ColliderTraits
	type: ColliderType.Polygon = ColliderType.Polygon
	disabled?: boolean
	boundingCircle: Circle

	constructor(traits: ColliderTraits, pos?: Vector, points?: Vector[]) {
		super(pos, points)
		this.traits = traits
		const boundingCircle = wetzls(points)
		this.boundingCircle = new Circle(new Vector(boundingCircle.x + pos.x, boundingCircle.y + pos.y), boundingCircle.r)
	}
}
export class BoxCollider extends Box implements ColliderWithTraits {
	traits: ColliderTraits
	/** The box is inscribed with a polygon where collision and intersection checks would be more efficient. */
	polygon: Polygon
	type: ColliderType.Box = ColliderType.Box
	disabled?: boolean
	angle: radians

	constructor(traits: ColliderTraits, pos?: Vector, width?: number, height?: number, angle?: number) {
		super(pos, width, height)
		this.angle = angle
		this.traits = traits
		const points = [new Vector(0, 0), new Vector(width, 0), new Vector(width, height), new Vector(0, height)]

		this.polygon = new Polygon(pos, points)
		if (angle) {
			this.polygon.setAngle(angle)
		}
	}
}

export class EllipseCollider extends Ellipse implements ColliderWithTraits {
	traits: ColliderTraits
	disabled?: boolean
	type: ColliderType.Ellipse = ColliderType.Ellipse

	constructor(traits: ColliderTraits, pos: Vector, rX: number, rY: number) {
		super(pos, rX, rY)
		this.traits = traits
	}
}

export class CapsuleCollider extends Capsule implements ColliderWithTraits {
	traits: ColliderTraits
	type: ColliderType.Capsule = ColliderType.Capsule
	disabled?: boolean

	constructor(traits: ColliderTraits, pos: Vector, p2: Vector, r: number) {
		super(pos, p2, r)
		this.traits = traits
	}
}

/* tslint:disable:no-bitwise */
export enum ColliderTraits {
	None = 0, // probably error-case or debug only (why have collider that doesn't collide? trigger area?)
	BlockMovement = 1 << 0,
	BlockProjectile = 1 << 1,
	BlockItem = 1 << 2,
	BlockMovementAndProjectile = BlockMovement | BlockProjectile,
	BlockMovementAndItem = BlockMovement | BlockItem,
	BlockMovementAndProjectileAndItem = BlockMovementAndProjectile | BlockItem,
	BlockAll = BlockMovement | BlockProjectile | BlockItem,
	Trigger = 1 << 3,
	Movable = 1 << 4,
	All = ~(~0 << 5),
}

export function isSet(bitset: ColliderTraits, flag: ColliderTraits) {
	return (bitset & flag) !== 0
}
/* tslint:enable:no-bitwise */

// returns *array* of colliders, most configs have just 1 entry
//  polygon is one
export function createSubColliders(position: VectorXY, colliderConfig: AnyColliderConfig): AnyCollider[] {
	const posArray = colliderConfig.position
	const pos = new Vector(posArray[0] + position.x, posArray[1] + position.y)
	switch (colliderConfig.type) {
		case ColliderType.Circle: {
			const circle = new CircleCollider(colliderConfig.traits, pos, colliderConfig.radius)
			return [circle]
		}
		case ColliderType.Ellipse: {
			const ellipse = new EllipseCollider(colliderConfig.traits, pos, colliderConfig.rX, colliderConfig.rY)
			// TODO2: capsule (ie projectiles) VS ellipse doesn't work. We have a lot of these, see testCapsuleEllipse for problem
			//console.assert(!isSet(ellipseConfig.traits, ColliderTraits.BlockProjectile), `ellipse is not supported vs projectiles: ${JSON.stringify(ellipseConfig)}`)
			return [ellipse]
		}
		case ColliderType.Box: {
			const box = new BoxCollider(colliderConfig.traits, pos, colliderConfig.width, colliderConfig.height, colliderConfig.angle)
			return [box]
		}
		case ColliderType.Polygon: {
			const polygons = makeSatPolygons(position, colliderConfig.vertices, colliderConfig.traits)
			return polygons
		}
	}
}

export function updateColliderPositions(colliderConfigs: AnyColliderConfig[], colliders: ColliderWithTraits[], ownerPos: VectorXY, facingDirection: number) {
	for (let i = 0; i < colliders.length; i++) {
		const collider = colliders[i]
		const config = colliderConfigs[i]
		facingDirection = 1 //		// CURRENTLY NOT SUPPORTING FLIPPING OF COLLIDERS
		collider.pos.x = ownerPos.x + config.position[0] * facingDirection
		collider.pos.y = ownerPos.y + config.position[1]
	}
}

export function createColliders(position: VectorXY, colliderConfigs: AnyColliderConfig[]): AnyCollider[] {
	return flatten(colliderConfigs.map((cc) => createSubColliders(position, cc)))
}

export function colliderConfigsBounds(colliderConfigs: ColliderConfig[]): Bounds {
	const bounds = new Bounds()
	colliderConfigs.forEach((colliderConfig) => {
		const b = colliderConfigBounds(colliderConfig)
		bounds.addBounds(b)
	})
	return bounds
}

export function colliderConfigBounds(colliderConfig: ColliderConfig): Bounds {
	const posArray = colliderConfig.position
	const pos = new Vector(posArray[0], posArray[1])
	const bounds = new Bounds()
	switch (colliderConfig.type) {
		case ColliderType.Circle: {
			const circleConfig = colliderConfig as CircleColliderConfig
			bounds.minX = pos.x - circleConfig.radius
			bounds.maxX = pos.x + circleConfig.radius
			bounds.minY = pos.y - circleConfig.radius
			bounds.maxY = pos.y + circleConfig.radius
			break
		}
		case ColliderType.Ellipse: {
			console.assert(!colliderConfig.angle)
			const ellipseConfig = colliderConfig as EllipseColliderConfig
			bounds.minX = pos.x - ellipseConfig.rX
			bounds.maxX = pos.x + ellipseConfig.rX
			bounds.minY = pos.y - ellipseConfig.rY
			bounds.maxY = pos.y + ellipseConfig.rY
			break
		}
		case ColliderType.Box: {
			const boxConfig = colliderConfig as BoxColliderConfig
			const angle = boxConfig.angle || 0
			const TL = new Vector(pos.x, pos.y)
			const TR = new Vector(pos.x + boxConfig.width, pos.y).rotate(angle)
			const BL = new Vector(pos.x, pos.y + boxConfig.height).rotate(angle)
			const BR = new Vector(pos.x + boxConfig.width, pos.y + boxConfig.height).rotate(angle)
			const corners = [TL, TR, BL, BR]
			bounds.minX = minBy(corners, (c) => c.x).x
			bounds.maxX = maxBy(corners, (c) => c.x).x
			bounds.minY = minBy(corners, (c) => c.y).y
			bounds.maxY = maxBy(corners, (c) => c.y).y
			break
		}
		case ColliderType.Polygon: {
			console.assert(!colliderConfig.angle)
			const polygonConfig = colliderConfig as PolygonColliderConfig
			const fa = Float32Array.from(polygonConfig.vertices)
			bounds.addVertexData(fa, 0, fa.length)
			break
		}
		default: {
			console.error(`unhandled collider type: ${colliderConfig.type}`)
		}
	}

	return bounds
}

export function collidersBounds(colliders: AnyCollider[]): Bounds {
	const bounds = new Bounds()
	colliders.forEach((collider) => {
		const b = colliderBounds(collider)
		bounds.addBounds(b)
	})
	return bounds
}

export function colliderBounds(collider: AnyCollider): Bounds {
	const pos = collider.pos
	const bounds = new Bounds()
	switch (collider.type) {
		case ColliderType.Circle: {
			const circle = collider as Circle
			bounds.minX = pos.x - circle.r
			bounds.maxX = pos.x + circle.r
			bounds.minY = pos.y - circle.r
			bounds.maxY = pos.y + circle.r
			break
		}
		case ColliderType.Ellipse: {
			const ellipse = collider as Ellipse
			bounds.minX = pos.x - ellipse.rX
			bounds.maxX = pos.x + ellipse.rX
			bounds.minY = pos.y - ellipse.rY
			bounds.maxY = pos.y + ellipse.rY
			break
		}
		case ColliderType.Box: {
			const box = collider as BoxCollider
			const angle = box.angle || 0
			const TL = new Vector(pos.x, pos.y)
			const TR = new Vector(pos.x + box.w, pos.y).rotate(angle)
			const BL = new Vector(pos.x, pos.y + box.h).rotate(angle)
			const BR = new Vector(pos.x + box.w, pos.y + box.h).rotate(angle)
			const corners = [TL, TR, BL, BR]
			bounds.minX = minBy(corners, (c) => c.x).x
			bounds.maxX = maxBy(corners, (c) => c.x).x
			bounds.minY = minBy(corners, (c) => c.y).y
			bounds.maxY = maxBy(corners, (c) => c.y).y
			break
		}
		default: {
			throw new Error(`unhandled collider type: ${collider.type}`)
		}
	}

	return bounds
}

// assert that the collider type has the correct members
export function checkColliderConfig(colliderConfig: ColliderConfig, name: string) {
	switch (colliderConfig.type) {
		case ColliderType.Circle: {
			const circleConfig = colliderConfig as CircleColliderConfig
			console.assert(circleConfig.radius !== undefined, `no radius in ${name}`)
			break
		}
		case ColliderType.Ellipse: {
			const ellipseConfig = colliderConfig as EllipseColliderConfig
			console.assert(ellipseConfig.rX !== undefined, `no rX in ${name}`)
			console.assert(ellipseConfig.rY !== undefined, `no rY in ${name}`)
			break
		}
		case ColliderType.Box: {
			const boxConfig = colliderConfig as BoxColliderConfig
			console.assert(boxConfig.width !== undefined, `no width in ${name}`)
			console.assert(boxConfig.height !== undefined, `no height in ${name}`)
			break
		}
		case ColliderType.Polygon: {
			const polygonConfig = colliderConfig as PolygonColliderConfig
			console.assert(polygonConfig.vertices !== undefined, `no vertices in ${name}`)
			break
		}
		default: {
			throw new Error(`unhandled collider type: ${colliderConfig.type}`)
		}
	}
}

export function colliderDebugColor(traits: ColliderTraits): number {
	let r
	let g
	let b
	r = g = b = 0.2 // make a little lighter
	if (isSet(traits, ColliderTraits.BlockMovement)) {
		r = 1
	}
	if (isSet(traits, ColliderTraits.BlockProjectile)) {
		b = 1
	}
	return ColorUtil.toHex(r, g, b)
}

export function setPolygonFromBox(b: BoxCollider, polygon: Polygon, points: Vector[]) {
	polygon.pos = b.pos
	const w = b.w
	const h = b.h
	points[0].x = 0
	points[0].y = 0
	points[1].x = w
	points[1].y = 0
	points[2].x = w
	points[2].y = h
	points[3].x = 0
	points[3].y = h
	const boxAngle = (b as any).angle
	if (boxAngle) {
		polygon.setAngle(boxAngle)
	}
	polygon.setPoints(points)
}
