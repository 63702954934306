



























import MtxIventoryItem from '../inventory-item/mtx-item.vue'
import TextButton from '../buttons/text-button.vue'
import ItemType from '../../../loot/shared/item-type'
import { PrizeSubType } from '../../../loot/shared/prize-sub-type'
import { mapGetters, mapState, mapMutations } from 'vuex'
import { SKIN_SLOT, SKIN_SLOT_TO_PREFIX } from '../../../player/shared/set-skin.shared'
import { itemIsInOwnedCosmetics, MtxTypes } from '../../state/cosmetics-ui-state'
import { to3DigitTruncatedString } from '../../../utils/math'
import { EmoteMtxBundles } from '../../../chat/shared/emote-enums'

export default {
	name: 'ButtonedSkinGridItemContainer',
	components: {
		MtxIventoryItem,
		TextButton,
	},
	props: {
		items: {
			type: Array,
			required: true,
			default: () => {
				console.warn('Currently using the default array. Ensure the <ButtonedSkinGridItemContainer /> component has a custom attribute items')
				return []
			},
		},
		showSlot: {
			type: Boolean,
			required: false,
			default: true,
		},
		selectable: {
			type: Boolean,
			required: false,
			default: true,
		},
		onSelectFn: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default on-select handler for the <ButtonedSkinGridItemContainer /> component has been called. Did you forget to pass an :on-click handler to the component?')
			},
		},
		selectedFn: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default selected fn for the <ButtonedSkinGridItemContainer /> component has been called. Did you forget to pass an :selected-fn handler to the component?')
			},
		},
		buttonLabel: {
			type: String,
			required: true,
		},
		onBtnClickFn: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default onBtnClickFn for the <ButtonedSkinGridItemContainer /> component has been called. Did you forget to pass an :onBtnClickFn handler to the component?')
			},
		},
	},
	computed: {
		...mapGetters('itemLocks', ['itemIsLocked']),
		...mapGetters('itemContainers', ['itemsInContainer']),
		...mapState('cosmetics', ['ownedCosmetics']),
		...mapState('mtxStore', ['usingStore']),
	},
	methods: {
		...mapMutations('mtxStore', ['showItemDetails']),
		to3DigitTruncatedString,
		getCostString(amount) {
			if(amount > 9999) {
				return to3DigitTruncatedString(amount)
			}
			return amount
		},
		itemIsOwned(item) {
			return itemIsInOwnedCosmetics(item, this.ownedCosmetics)
		},
		itemInStorageCount(item) {
			if (!item.mtxId || item.mtxType === MtxTypes.CONSUMABLE) {
				//@TODO2: consider showing this for consumables (keys)
				return 0
			}
			// Special case to handle bundes since they are added to stash as loose items
			if (item.mtxType === MtxTypes.BUNDLE) {
				const isEmoteBundle = item.mtxId.includes('emotes')
				if (isEmoteBundle) {
					const emoteList = EmoteMtxBundles.get(item.mtxId)
					if (emoteList) {
						const stashContents = this.itemsInContainer('stash')
						// Create a tally object so we can count how many individual emotes from this bundle are in stash
						const emoteTally = emoteList.reduce((acc,curr)=> (acc[curr] = 0,acc),{})

						for (let i = 0; i < stashContents.length; ++i) {
							const stashItem = stashContents[i]
							if (stashItem.itemTypeEnum === ItemType.Prize && stashItem.itemSubType === PrizeSubType.Emote) {
								const emoteId = stashItem.extraData.emoteId
								if (emoteList.includes(emoteId)) {
									emoteTally[emoteId]++
								}
							}
						}
						let completeBundleCount = 0
						let counting = true
						while (counting) {
							for (let i = 0; i < emoteList.length; ++i) {
								
								const emoteId = emoteList[i]
								
								if (emoteTally[emoteId] <= 0){
									// Not enough for a complete bundle, finish counting
									counting = false;
									break;
								}
								else 
								{
									emoteTally[emoteId]--
								}
							}
							// We've got enough for one complete bundle
							if (counting) {
								completeBundleCount++
							}
						}
						return completeBundleCount
					}
				}
			}

			let count = 0
			const stashContents = this.itemsInContainer('stash')
			const isPet = item.mtxId.startsWith(SKIN_SLOT_TO_PREFIX[SKIN_SLOT.PET_MAIN])
			const itemId = item.mtxId
			let mtxPrizeSubType
			//@TODO remove dupe code
			if (itemId.startsWith(SKIN_SLOT_TO_PREFIX[SKIN_SLOT.PET_MAIN])) {
				mtxPrizeSubType = Number.parseInt(item.mtxId.substring(SKIN_SLOT_TO_PREFIX[SKIN_SLOT.PET_MAIN].length))
			} else if (itemId.startsWith(SKIN_SLOT_TO_PREFIX[SKIN_SLOT.WEAPON_SWORD])) {
				mtxPrizeSubType = Number.parseInt(item.mtxId.substring(SKIN_SLOT_TO_PREFIX[SKIN_SLOT.WEAPON_SWORD].length))
			} else if (itemId.startsWith(SKIN_SLOT_TO_PREFIX[SKIN_SLOT.WEAPON_WAND])) {
				mtxPrizeSubType = Number.parseInt(item.mtxId.substring(SKIN_SLOT_TO_PREFIX[SKIN_SLOT.WEAPON_WAND].length))
			} else if (itemId.startsWith(SKIN_SLOT_TO_PREFIX[SKIN_SLOT.WEAPON_STAFF])) {
				mtxPrizeSubType = Number.parseInt(item.mtxId.substring(SKIN_SLOT_TO_PREFIX[SKIN_SLOT.WEAPON_STAFF].length))
			} else if (itemId.startsWith(SKIN_SLOT_TO_PREFIX[SKIN_SLOT.WEAPON_SCYTHE])) {
				mtxPrizeSubType = Number.parseInt(item.mtxId.substring(SKIN_SLOT_TO_PREFIX[SKIN_SLOT.WEAPON_SCYTHE].length))
			} else if (itemId.startsWith(SKIN_SLOT_TO_PREFIX[SKIN_SLOT.WEAPON_CROSSBOW])) {
				mtxPrizeSubType = Number.parseInt(item.mtxId.substring(SKIN_SLOT_TO_PREFIX[SKIN_SLOT.WEAPON_CROSSBOW].length))
			} else if (itemId.startsWith(SKIN_SLOT_TO_PREFIX[SKIN_SLOT.WEAPON_ARCANE])) {
				mtxPrizeSubType = Number.parseInt(item.mtxId.substring(SKIN_SLOT_TO_PREFIX[SKIN_SLOT.WEAPON_ARCANE].length))
			}

			for (let i = 0; i < stashContents.length; ++i) {
				const stashItem = stashContents[i]
				if (stashItem.itemTypeEnum === ItemType.Prize) {
					if (!isPet && stashItem.itemSubType === PrizeSubType.PlayerSkin) {
						if (stashItem.extraData.playerSkinIdentifier === item.mtxId) {
							count++
						} 
					} else if (isPet && stashItem.itemSubType === PrizeSubType.PetEgg) {
						if (stashItem.extraData.petSubType === mtxPrizeSubType) {
							count++
						}
					} else if (!isPet && stashItem.itemSubType === PrizeSubType.WeaponSkin) {
						if (stashItem.extraData.weaponSkinType === mtxPrizeSubType) {
							count++
						}
					}
				}
			}

			return count
		},
		itemIsPurchasable(item) {
			if (item.mtxId) {
				if (!Number.isInteger(item.quantityRemaining)) {
					return true
				}
				return item.quantityRemaining > 0
			}
			return false
		},
	},
}
