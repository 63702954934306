























import EmptyInventorySlot from '../inventory-item/empty-inventory-slot.vue'
import InventoryItem from '../inventory-item/inventory-item.vue'
import TextButton from '../buttons/text-button.vue'
import { mapGetters } from 'vuex'

export default {
	name: 'ButtonedGridItemContainer',
	components: {
		InventoryItem,
		EmptyInventorySlot,
		TextButton,
	},
	props: {
		items: {
			type: Array,
			required: true,
			default: () => {
				console.warn('Currently using the default array. Ensure the <ButtonedGridItemContainer /> component has a custom attribute items')
				return []
			},
		},
		showSlot: {
			type: Boolean,
			required: false,
			default: true,
		},
		selectable: {
			type: Boolean,
			required: false,
			default: true,
		},
		onSelectFn: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default on-select handler for the <ListItemContainer /> component has been called. Did you forget to pass an :on-click handler to the component?')
			},
		},
		selectedFn: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default selected fn for the <ListItemContainer /> component has been called. Did you forget to pass an :selected-fn handler to the component?')
			},
		},
		tooltipable: {
			type: Boolean,
			required: false,
			default: true,
		},
		maxItemCount: {
			type: Number,
			required: true,
			default: () => {
				console.warn('Currently using the default max item slots. Ensure the <ButtonedGridItemContainer /> component has a custom attribute max-item-count')
				return 10
			},
		},
		showButtons: {
			type: Boolean,
			required: false,
			default: false,
		},
		onButtonClickFn: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default on-button-click-fn handler for the <ButtonedGridItemContainer /> component has been called. Did you forget to pass an :on-click handler to the component?')
			},
		},
		buttonLabel: {
			type: String,
			required: true,
		},
		disabledFn: {
			type: Function,
			required: false,
			default: () => {
				return false
			},
		},
		buttonStyle: {
			type: String,
			required: false,
			default: 'default',
		},
		fadeIn: {
			type: Boolean,
			required: false,
			default: false,
		},
		optionalSlotDetails: {
			type: Array,
			required: false,
			default: () => {
				return []
			},
		},
		optionalSlotBtnLabel: {
			type: String,
			required: false,
		},
		optionalSlotFn: {
			type: Function,
			required: false,
		},
		maxOptionalSlots: {
			type: Number,
			required: false,
		},
		optionalSlotButtonStyle: {
			type: String,
			required: false,
			default: 'default',
		},
		itemRarity: {
			type: String,
			required: false,
		},
		slotsId: {
			type: String,
			required: false,
		},
		hideOptionalSlots: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data() {
		return {}
	},
	computed: {
		...mapGetters('itemLocks', ['itemIsLocked']),
		...mapGetters('inGame', ['activePanel']),
		itemEntryClass() {
			if (this.fadeIn) {
				return 'fade-in'
			}
			return ''
		},
	},
	methods: {},
}
