import { Vector } from 'sat'
import { BuffIdentifier } from '../../../../buffs/shared/buff.shared'
import { ParticleEffectType } from '../../../../engine/shared/game-data/particle-config'
import { BurstFireModes, ModType, ModValueType } from '../../../../projectiles/shared/projectile-types'
import { Colors } from '../../../../utils/colors'
import { degToRad } from '../../../../utils/math'
import { Ability, AbilityTargets, AbilityTargetSelectionStyle, AbilityType } from '../../action-types'
import { EnemyDefaults } from '../enemy-defaults'
import { deepClone } from '../../abilities.test'



export const cooldownEnemyCooldown15000: Ability = {
	debugName: 'cooldown15000',
	abilityType: AbilityType.INSTANTLY_APPLY_EFFECT,
	buffToApply: BuffIdentifier.EnemyCooldown15000,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.MYSELF,
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
}

export const moveToPositionAbility: Ability = {
	debugName: 'moveToPosition',
	abilityType: AbilityType.MOVE_TOWARDS_LOCATION,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.RANDOM_POSITIONS_IN_SHAPE,
	shapeNames: ['1oclock', '3oclock', '5oclock', '7oclock', '9oclock', '11oclock'],
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
	moveRules: {
		movementSpeed: 300,
		minDistance: 100,
		maxDistance: 1000,
	},
}

export const moveToRunePositionAbility: Ability = {
	debugName: 'moveToPosition',
	abilityType: AbilityType.MOVE_TOWARDS_LOCATION,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.RANDOM_POSITIONS_IN_SHAPE,
	shapeNames: ['2oclockRune', '4oclockRune', '6oclockRune', '8oclockRune', '10oclockRune', '12oclockRune'],
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
	moveRules: {
		movementSpeed: 300,
		minDistance: 100,
		maxDistance: 1200,
		destinationPfx: 'boss-gorgon-rune-glow',
		pfxScale: 1,
		pfxLingerDuration: 4,
	},
}

export const explosiveShardsAbility: Ability = {
	debugName: 'explosiveShards',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		speed: 500,
		lifespanInSeconds: 6,
		projectileCount: 7,
		spreadAngle: degToRad(70),
		basePhysicalDamage: 100,
		burstCount: 3,
		burstDelay: 0.2,
		burstMode: BurstFireModes.STRAIGHT,
		burstAngle: degToRad(10),
		pierceCount: 10,
		modifiers: [
			{ modType: ModType.STRAIGHT },
			{ modType: ModType.ACCELERATION, value: { modValueType: ModValueType.RANDOM, min: 0.0, max: 1.0 } },
			{ modType: ModType.ZIGZAG, amplitude: { modValueType: ModValueType.RANDOM, min: 1, max: 10 }, period: { modValueType: ModValueType.RANDOM, min: 1.0, max: 3.0 } },
		],
		color: Colors.red,
		colliderRadius: 33,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_BOSSHIGHLANDSSHARD,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		splashDamage: 0.8,
		splashRadius: 400,
	},
	attackOffset: new Vector(0, -600),
	ignoreAngleOnWeaponOffset: true,
}

export const stoneRainAbility: Ability = {
	debugName: 'stoneRain',
	abilityType: AbilityType.SPAWN_GROUND_HAZARD_MULTITARGET,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
	durationInGameTicks: 0,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		colliderRadius: 310,
		speed: 0,
		basePhysicalDamage: 125,
		lifespanInSeconds: 0.2, // how long the projectile lasts on the ground
		color: Colors.red,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		pierceCount: 999,
		damageMultiplier: 1,
	},
	groundHazardMultitargetRules: {
		spineAsset: 'highlands-aoe',
		pfx: 'boss-highlands-stone',
		pfxScale: 1,
		pfxSecondsOffset: 2, // how long before the PFX is created?
		projectileSecondsOffset: 2.2, // how long before it creates the projectile
		baseQuantity: 3,
		perTargetQuantity: 1,
		totalTime: 1.3,
		accuratePercent: 0.5,
		maxStray: 800,
	},
}

export const onyxShieldAbility: Ability = {
	debugName: 'onyxShield',
	abilityType: AbilityType.INSTANTLY_APPLY_EFFECT,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.MYSELF,
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
	buffToApply: BuffIdentifier.EnemyHighlandsBossOnyxShield,
	buffStacksToApply: 10, // 5% damage reduction per stack
}

export const runeInvulnerability: Ability = {
	debugName: 'runeInvulnerability',
	abilityType: AbilityType.INSTANTLY_APPLY_EFFECT,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.MYSELF,
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
	buffToApply: BuffIdentifier.EnemyHighlandsBossInvulnerable,
}

export const petrifyingWaveAbility: Ability = {
	debugName: 'petrifyingWave',
	abilityType: AbilityType.AOE_PROJECTILE_AROUND_SELF,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.MYSELF,
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		colliderRadius: 980,
		speed: 0,
		lifespanInSeconds: 0.2,
		color: Colors.red,
		basePhysicalDamage: 20,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		pierceCount: 999,
		damageMultiplier: 0,
		applyBuffsOnHit: [BuffIdentifier.EnemyHighlandsBossPetrifyingWave],
	},
	attackOffset: new Vector(0, -200),
	aoeProjectileAroundSelfRules: {
		pfx: 'boss-highlands-petrifyingwave',
		pfxScale: 1,
	},
}


export const moveToPositionAbilityEasy = deepClone(moveToPositionAbility)
export const moveToPositionAbilityMedium = deepClone(moveToPositionAbility)
moveToPositionAbilityMedium.moveRules.movementSpeed = 400
export const moveToPositionAbilityBrutal = deepClone(moveToPositionAbilityMedium)
moveToPositionAbilityBrutal.moveRules.movementSpeed = 450
export const moveToPositionAbilityNasty = deepClone(moveToPositionAbilityBrutal)

export const moveToRunePositionAbilityEasy = deepClone(moveToRunePositionAbility)
export const moveToRunePositionAbilityMedium = deepClone(moveToRunePositionAbility)
moveToRunePositionAbilityMedium.moveRules.movementSpeed = 400
export const moveToRunePositionAbilityBrutal = deepClone(moveToRunePositionAbilityMedium)
moveToRunePositionAbilityBrutal.moveRules.movementSpeed = 450
export const moveToRunePositionAbilityNasty = deepClone(moveToRunePositionAbilityBrutal)

export const explosiveShardsAbilityEasy = deepClone(explosiveShardsAbility)
export const explosiveShardsAbilityMedium = deepClone(explosiveShardsAbility)
explosiveShardsAbilityMedium.projectileConfig.modifiers = [
	{ modType: ModType.STRAIGHT },
	{ modType: ModType.ACCELERATION, value: { modValueType: ModValueType.RANDOM, min: 0.3, max: 1.0 } },
	{ modType: ModType.ZIGZAG, amplitude: { modValueType: ModValueType.RANDOM, min: 1, max: 8 }, period: { modValueType: ModValueType.RANDOM, min: 1.0, max: 2.5 } },
]
explosiveShardsAbilityMedium.projectileConfig.speed = 640
export const explosiveShardsAbilityBrutal = deepClone(explosiveShardsAbilityMedium)
export const explosiveShardsAbilityNasty = deepClone(explosiveShardsAbilityBrutal)

export const stoneRainAbilityEasy = deepClone(stoneRainAbility)
export const stoneRainAbilityMedium = deepClone(stoneRainAbility)
stoneRainAbilityMedium.groundHazardMultitargetRules.baseQuantity = 5
stoneRainAbilityMedium.groundHazardMultitargetRules.perTargetQuantity = 1
stoneRainAbilityMedium.groundHazardMultitargetRules.maxStray = 700
stoneRainAbilityMedium.groundHazardMultitargetRules.accuratePercent = 0.34
export const stoneRainAbilityBrutal = deepClone(stoneRainAbilityMedium)
stoneRainAbilityBrutal.groundHazardMultitargetRules.perTargetQuantity = 2
export const stoneRainAbilityNasty = deepClone(stoneRainAbilityBrutal)

export const onyxShieldAbilityEasy = deepClone(onyxShieldAbility)
export const onyxShieldAbilityMedium = deepClone(onyxShieldAbility)
onyxShieldAbilityMedium.buffStacksToApply = 15 // 75% DR
export const onyxShieldAbilityBrutal = deepClone(onyxShieldAbilityMedium)
onyxShieldAbilityBrutal.buffStacksToApply = 18 // 90% DR
export const onyxShieldAbilityNasty = deepClone(onyxShieldAbilityBrutal)

export const runeInvulnerabilityEasy = deepClone(runeInvulnerability)
export const runeInvulnerabilityMedium = deepClone(runeInvulnerability)
export const runeInvulnerabilityBrutal = deepClone(runeInvulnerability)
export const runeInvulnerabilityNasty = deepClone(runeInvulnerability)

export const petrifyingWaveAbilityEasy = deepClone(petrifyingWaveAbility)
export const petrifyingWaveAbilityMedium = deepClone(petrifyingWaveAbility)
petrifyingWaveAbilityMedium.projectileConfig.colliderRadius = 999
petrifyingWaveAbilityMedium.aoeProjectileAroundSelfRules.pfxScale = 1.1
export const petrifyingWaveAbilityBrutal = deepClone(petrifyingWaveAbilityMedium)
export const petrifyingWaveAbilityNasty = deepClone(petrifyingWaveAbilityBrutal)








