import logger from '../../utils/client-logger'
import { pressureFillOutPressureLoadout as pressureFillEmptyPressureRanks } from '../shared/game-data/pressure-stat-functions.shared'
import { PressureLoadout, PressureStat } from '../shared/game-data/pressure-stat-info.shared'
import { GameClient } from './game-client'

const PRESSURE_RANK_CHOICES_FILENAME = 'pressure-ranks'

/* eslint-disable @typescript-eslint/no-var-requires */
/* tslint:disable no-var-requires */
const heatImages = require('../reusable-components/inventory-item/assets/heat*.png')

export function getHeatImageFile(stat:PressureStat): string {
	switch (stat) {
		case PressureStat.Harsh: return 'harsh'
		case PressureStat.Savagery: return 'savagery'
		case PressureStat.Brutality: return 'brutality'
		case PressureStat.Tanky: return 'tanky'
		case PressureStat.Armored: return 'armored'
		case PressureStat.SupremeBarrier: return 'supreme-barrier'
		case PressureStat.WeakPoint: return 'weak-point'
		case PressureStat.FoamWeapons: return 'foam-weapons'
		case PressureStat.HotGear: return 'hot-gear'
		case PressureStat.WiltedVitality: return 'wilting' 
		case PressureStat.Heartless: return 'heartless'
		case PressureStat.Frailty: return 'frailty'
		case PressureStat.ShieldsDown: return 'shield-down'
		case PressureStat.TinyWorm: return 'tiny-worm'
		case PressureStat.Pauper: return 'pauper'
		case PressureStat.CursedArtifacts: return 'cursed-artifacts'
		case PressureStat.FinalDestination: return 'final-destination'
		case PressureStat.BadEyes: return 'bad-eyes'
		case PressureStat.ThirstyVampire: return 'thirsty-vampire'
		case PressureStat.EnragePhase: return 'enrage-phase'
		case PressureStat.LostWanderer: return 'lost-wanderer'
		case PressureStat.CreepingWeakness: return 'creeping-weakness'
		default: break
	}
}

interface ISavedPressureSettings {
	userId: string
	pressureLoadout: PressureLoadout
}

export function getPressureLoadoutOnClient(): PressureLoadout {
	const loadout = GameClient.getInstance().connectionPayload.pressureLoadout
	return loadout ?? {}
}

export function savePressureLoadoutToLocalStorage(userId: string, pressureLoadout: PressureLoadout) {
	const savedPressureSettings: ISavedPressureSettings = {
		userId,
		pressureLoadout,
	}
	localStorage.setItem(PRESSURE_RANK_CHOICES_FILENAME, JSON.stringify(savedPressureSettings))
}

export function loadPressureLoadoutFromLocalStorage(userId: string): PressureLoadout {
	//localStorage.removeItem(PRESSURE_RANK_CHOICES_FILENAME) // testing no data flow
	const data = localStorage.getItem(PRESSURE_RANK_CHOICES_FILENAME)
	let pressureLoadout: PressureLoadout = {}
	try {
		const savedPressureSettings: ISavedPressureSettings = JSON.parse(data)
		if (savedPressureSettings && savedPressureSettings.userId === userId) {
			pressureLoadout = savedPressureSettings.pressureLoadout || {}
		}
	} catch {
		logger.warn(`error parsing ${PRESSURE_RANK_CHOICES_FILENAME}, emptying pressure loadout`)
	}

	pressureFillEmptyPressureRanks(pressureLoadout)

	return pressureLoadout
}

export function getEmptyLoadout(): PressureLoadout {
	const pressureLoadout = {}
	pressureFillEmptyPressureRanks(pressureLoadout)
	return pressureLoadout
}
