import nengi from 'nengi'
import { VectorXY } from '../../utils/math'
import { DrawShapeBase } from './draw-shape-base'
class DrawPolygon extends DrawShapeBase {
	static protocol = {
		...DrawShapeBase.protocol,
		points: { type: nengi.Int32, indexType: nengi.UInt16 },
	}

	points: number[]

	constructor(x: number, y: number, points: VectorXY[], color: number = 0xffffff, permanent: boolean = false, destroyAfterSeconds: number = 0.5, scale = 1) {
		super(color, permanent, destroyAfterSeconds, scale)
		this.points = []
		points.forEach((v) => {
			this.points.push(v.x + x)
			this.points.push(v.y + y)
		})
	}
}

export default DrawPolygon
