






















































import MainPanel from '../reusable-components/panels/main-panel.vue'
import PanelDivider from '../reusable-components/panel-divider/panel-divider.vue'
import MtxIventoryItem from '../reusable-components/inventory-item/mtx-item.vue'
import TextButton from '../reusable-components/buttons/text-button.vue'
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex'
import { itemIsInOwnedCosmetics } from '../state/cosmetics-ui-state'
import AnimatedMtxItem from '../reusable-components/inventory-item/animated-mtx-item.vue'
import { stashFullMtxItemTooltipText } from '../reusable-components/v-tooltip/v-tooltip-functions'

export default {
	name: 'MtxItemDetails',
	components: {
		MainPanel,
		PanelDivider,
		TextButton,
		MtxIventoryItem,
		AnimatedMtxItem,
	},
	props: {},
	data() {
		return {}
	},
	computed: {
		...mapState('user', ['userType']),
		...mapGetters('hud', ['scalesBalance']),
		...mapState('cosmetics', ['ownedCosmetics']),
		...mapGetters('mtxStore', ['getSelectedItem']),
		...mapGetters('itemContainers', ['isStashFull']),
		bottomText() {
			if(this.getSelectedItem.mtxType === 'account' || this.getSelectedItem.mtxType === 'consumable') {
				return 'Quality of Life Item'
			}
			return 'Cosmetic Item'
		},
		isAccountRelated() {
			return this.getSelectedItem.mtxType === 'account'
		}
	},
	methods: {
		stashFullMtxItemTooltipText,
		...mapActions('mtxStore', ['purchaseMtx', 'openForteExchange']),
		...mapMutations('mtxStore', ['closeItemDetailsPrompt']),
		...mapMutations('inGame', ['setActivePanel']),
		itemIsOwned(item) {
			return itemIsInOwnedCosmetics(item, this.ownedCosmetics)
		},
		purchase(item) {
			if(this.userType === 'registered') {
				const cost = item.prismScales
				const scalesBalance = parseInt(this.scalesBalance)

				if (cost > scalesBalance) {
					this.openForteExchange()
				} else {
					this.closeItemDetailsPrompt()
					this.purchaseMtx(item)
				}
			} else {
				this.closeItemDetailsPrompt()
				this.setActivePanel('notLoggedIn')
			}
		},
		getItemCost(item) {
			if (item.prismScales) {
				return item.prismScales
			}
			return item.coins
		},
	},
}
