import nengi from 'nengi'

class DismissUIScreen {
	static protocol = {
		screen: nengi.String,
		npcId: nengi.Number,
	}

	screen: string
	npcId: number

	constructor(screen: string, npcId: number) {
		this.screen = screen
		this.npcId = npcId
	}
}

export default DismissUIScreen
