import { Container, Graphics, LoaderResource } from 'pixi.js'
import { Vector } from 'sat'
import { createColliders } from '../../collision/shared/colliders'
import { addDebugModel, clientDebug, drawColliderVisuals } from '../../debug/client/debug-client'
import { clientConfig } from '../../engine/client/client-config'
import { debugConfig } from '../../engine/client/debug-config'
import Renderer from '../../engine/client/graphics/renderer'
import playAnimation from '../../models-animations/client/play-animation'
import { RiggedSpineModel } from '../../models-animations/client/spine-model'
import { AnimationTrack } from '../../models-animations/shared/animation-track'
import { ObjectPool, PoolableObject } from '../../third-party/object-pool'
import { Colors, pickRandomColor } from '../../utils/colors'
import { distanceVV, VectorXY } from '../../utils/math'
import { createInstancedSprites, getAttachmentOffsets, getSpineAsset } from '../../utils/pixi-util'
import { getPropConfig, PropConfig } from '../shared/prop-configs'
import TerrainProp from '../shared/terrain-prop.shared'
import { InstancedSprite } from './instanced-sprite'

export class ClientTerrainProp extends TerrainProp implements PoolableObject {
	static pool: ObjectPool
	instancedSprites: InstancedSprite[]
	model: RiggedSpineModel
	zIndex: number
	exemptFromModelCheck: boolean = true
	debugModel: Container
	colliderVisual: Graphics

	constructor() {
		super()
	}

	setDefaultValues(defaultValues: any, entityData?: any) {
		if (entityData) {
			const propConfig: PropConfig = getPropConfig(entityData.propId)
			
			this.x = entityData.x
			this.y = entityData.y
			this.zIndex = this.y

			const renderer: Renderer = Renderer.getInstance()

			const asset = getSpineAsset(entityData.propId)
			const spineData = asset.spineData as PIXI.spine.core.SkeletonData

			const animCount = spineData.animations.length

			const zOffset = propConfig.zOffset || 0
			// for now, setup animated props as rigged spines
			if (animCount > 0 || clientConfig.forcePropRig || propConfig.rigged) {
				this.model = RiggedSpineModel.allocFromPool(entityData.propId, entityData.skin.length > 0 ? entityData.skin : 'default')
				
				this.model.name = entityData.propId
				this.model.zIndex = this.y + zOffset

				if (animCount > 0) {
					playAnimation(this.model, AnimationTrack.IDLE)
				}
				renderer.mgRenderer.addDisplayObjectToScene(this.model)
			} else {
				const zOffsets = getAttachmentOffsets(spineData, entityData.propId)

				this.instancedSprites = createInstancedSprites(asset, this.x, this.y, propConfig.zeroHeight, zOffsets, propConfig.disableFileOrdering, propConfig.disableSubPropZOffs, entityData.skin)
				this.instancedSprites.forEach((instancedSprite) => {
					renderer.mgRenderer.addPropToScene(instancedSprite)
					instancedSprite.zOffset += zOffset
				})
			}

			if (propConfig.colliders) {
				// TODO2: this doesn't handle lookup colliders
				// also todo2, this is only for csp so can be removed if we don't need it
				this.colliders = createColliders({ x: this.x, y: this.y }, propConfig.colliders)
			}

			if (debugConfig.props.calculateAndPrintVisibility) {
				const visibility = this.computeVisiblity(spineData, entityData, asset, propConfig)
				console.log(`${entityData.propId} visibility:${visibility.toFixed(0)}`)
			}

			// currently these are noisy
			if (clientConfig.drawColliders) {
				const attachments = spineData.slots.map((slot) => slot.attachmentName).join()
				const info = `prop:${entityData.propId}\n${entityData.x.toFixed(1)}, ${entityData.y.toFixed(1)}\n${attachments}`
				addDebugModel(this, info, 0)
				this.drawDebugColliderVisual(propConfig)
			}

			if (propConfig.colliders) {
				renderer.addTweakerCollidersIfEnabled(entityData.propId, new Vector(this.x, this.y), propConfig.colliders, this.instancedSprites)
			}

			if (debugConfig.props.debugRenderVisibilityRadius) {
				const c = pickRandomColor()
				addDebugModel(this, `${entityData.propId}\nr ${entityData.visibilityRadius}`, entityData.visibilityRadius, false, c, 30, c)
			}
		}
	}

	cleanup() {
		this.nid = -1
		this.model = undefined
		this.instancedSprites = undefined
		this.debugModel = undefined
		this.colliderVisual = undefined
	}

	private drawDebugColliderVisual(propConfig: PropConfig) {
		this.colliderVisual = drawColliderVisuals(propConfig.colliders)
		this.debugModel.addChild(this.colliderVisual)
	}

	private computeVisiblity(spineData: PIXI.spine.core.SkeletonData, entityData: any, asset: LoaderResource, propConfig: PropConfig) {
		const zOffsets = getAttachmentOffsets(spineData, entityData.propId)

		const instancedSprites = createInstancedSprites(asset, this.x, this.y, propConfig.zeroHeight, zOffsets, propConfig.disableFileOrdering, propConfig.disableSubPropZOffs)
		let maxDistance = 0
		instancedSprites.forEach((instancedSprite) => {
			const w = instancedSprite.width
			const h = instancedSprite.height
			const x = instancedSprite.x - w * 0.5
			const y = instancedSprite.y - h * 0.5
			const corners: VectorXY[] = [
				{ x, y },
				{ x: x + w, y },
				{ x: x + w, y: y + h },
				{ x, y: y + h },
			]
			corners.forEach((corner) => {
				const dist = distanceVV(this, corner)
				maxDistance = Math.max(dist, maxDistance)
			})
			clientDebug.drawRectangle(x, y, w, h, Colors.yellow, true, 0)
		})

		return maxDistance
	}
}

export const clientTerrainPropHooks = {}
