import nengi from 'nengi'

class SetFreeListingDateMessage {
	static protocol = {
		freeListDate: nengi.String,
	}
	
    freeListDate: string	

	constructor(freeListDate: string) {
		this.freeListDate = freeListDate
	}
}

export default SetFreeListingDateMessage
