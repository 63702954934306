






















import { getComponentByPropType } from './helpers'

import ArrayProperty from './array-property.vue'
import StringProperty from './string-property.vue'
import NumberProperty from './number-property.vue'
import FloatProperty from './float-property.vue'
import BooleanProperty from './boolean-property.vue'
import RecursiveObjectTree from './recursive-object-tree.vue'
import UndefinedOrNullProperty from './undefined-or-null-property.vue'
import SelectProperty from './select-property.vue'
import FunctionProperty from './function-property.vue'
import DevToolsManager from '../../dev-tools/dev-tools-manager'

export default {
	name: 'ArrayProperty',
	components: {
		ArrayProperty,
		StringProperty,
		NumberProperty,
		FloatProperty,
		BooleanProperty,
		UndefinedOrNullProperty,
		RecursiveObjectTree,
		FunctionProperty,
		SelectProperty,
	},
	props: {
		rootObject: {
			type: Object,
			required: true,
		},
		depth: {
			type: Number,
			required: true,
		},
		propertySeekDotNotation: {
			type: String,
			required: true,
		},
		propertyKey: {
			type: String,
			required: true,
		},
		propertyValue: {
			type: Array,
			required: true,
		},
		propertyChangedFn: {
			type: Function,
			default: undefined,
		},
	},
	data() {
		return {
			isExpanded: this.depth < 2,
		}
	},
	methods: {
		getComponentByPropType,
		toggleExpansion() {
			this.isExpanded = !this.isExpanded
		},
		keyIsRegisteredEnum(key) {
			return DevToolsManager.getInstance().propertyEnumMap.has(key)
		},
		getPropertyOptionsFromEnumData(propertyKey: string) {
			const map = DevToolsManager.getInstance().propertyEnumMap
			let data
			if (map.has(propertyKey)) {
				data = map.get(propertyKey)
			} else {
				data = DevToolsManager.getInstance().enumData[propertyKey]
			}
			return data
		},
		// addEntry() {
		// 	this.propertyValue.push(0)
		// },
		// removeEntry() {
		// 	this.propertyValue.pop()
		// },
	},
}
