<template>
	<div class="category-filter">
		<div class="filters">
			<div v-show="categoryType == 'disable'" class="filter-set"></div>
			<div v-show="categoryType == 'sortOrder'" class="filter-set">
				<div :class="{ activeSortCategory: activeFilterCategory === 'name', disabled: isMarketplaceLoading}" class="filter all" @click="updateSortOrder('name')">
					<span class="filter-text">Item Name</span>
					<div v-show="activeFilterCategory == 'name'" class="filter-sort-arrow-container">
						<span class="filter-sort-direction"><img src="../item-containers/assets/sorting-arrow.png" alt="" :class="categorySortOrderName === 'asc'? 'asc' : 'desc'" /></span>
					</div>
				</div>
				<div :class="{ activeSortCategory: activeFilterCategory === 'level', disabled: isMarketplaceLoading}" class="filter all" @click="updateSortOrder('level')">
					<span class="filter-text">Level</span>
					<div v-show="activeFilterCategory == 'level'" class="filter-sort-arrow-container">
						<span class="filter-sort-direction"><img src="../item-containers/assets/sorting-arrow.png" alt="" :class="categorySortOrderLevel === 'asc'? 'asc' : 'desc'" /></span>
					</div>
				</div>
				<div :class="{ activeSortCategory: activeFilterCategory === 'price', disabled: isMarketplaceLoading}" class="filter all" @click="updateSortOrder('price')">
					<span class="filter-text">Price</span>
					<div v-show="activeFilterCategory == 'price'" class="filter-sort-arrow-container">
						<span class="filter-sort-direction"><img src="../item-containers/assets/sorting-arrow.png" alt="" :class="categorySortOrderPrice === 'asc'? 'asc' : 'desc'" /></span>
					</div>
				</div>
			</div>
			<div v-show="categoryType == 'item'" class="filter-set">
				<div :class="{ activeItemType: activeItemTypeFilter === 'all' }" class="filter all" @click="updateItemTypeFilter('all')">
					<span class="filter-icon-item filter-panel-equipment">All</span>
				</div>
				<div :class="{ activeItemType: activeItemTypeFilter === 'Weapon' }" class="filter" @click="updateItemTypeFilter('Weapon')">
					<div class="filter-icon-item weapons" :class="{ active: activeItemTypeFilter === 'Weapon' }"></div>
					<span class="filter-name filter-panel-equipment">Weapons</span>
				</div>
				<div :class="{ activeItemType: activeItemTypeFilter === 'Gear' }" class="filter" @click="updateItemTypeFilter('Gear')">
					<span class="filter-icon-item gear" :class="{ active: activeItemTypeFilter === 'Gear' }"></span>
					<span class="filter-name filter-panel-equipment">Gear</span>
				</div>
				<div :class="{ activeItemType: activeItemTypeFilter === 'Weapon Augment' }" class="filter" @click="updateItemTypeFilter('Weapon Augment')">
					<span class="filter-icon-item augments" :class="{ active: activeItemTypeFilter === 'Weapon Augment' }"></span>
					<span class="filter-name filter-panel-equipment">Augments</span>
				</div>
				<div v-if="activePanel !== 'furnaceUpdate'" :class="{ activeItemType: activeItemTypeFilter === 'Prize' }" class="filter" @click="updateItemTypeFilter('Prize')">
					<span class="filter-icon-item cosmetics" :class="{ active: activeItemTypeFilter === 'Prize' }"></span>
					<span class="filter-name filter-panel-equipment">Cosmetics</span>
				</div>
			</div>
			<div v-show="categoryType === 'gameOver'" class="filter-set">
				<div :class="{ activeItemType: activeGameOverFilter === 'Top Stats' }" class="filter" @click="updateGameOverFilter('Top Stats')">
					<span class="filter-text">Top Stats</span>
				</div>
				<div :class="{ activeItemType: activeGameOverFilter === 'Damage Taken' }" class="filter" @click="updateGameOverFilter('Damage Taken')">
					<span class="filter-text">Damage Taken</span>
				</div>
				<div v-if="affiliatedFaction" :class="{ activeItemType: activeGameOverFilter === 'Factions' }" class="filter" @click="updateGameOverFilter('Factions')">
					<span class="filter-text">Factions</span>
				</div>
			</div>
			<div v-show="categoryType === 'endOfRun'" class="filter-set">
				<div :class="{ activeItemType: activeGameOverFilter === 'Top Stats' }" class="filter" @click="updateGameOverFilter('Top Stats')">
					<span class="filter-text">Top Stats</span>
				</div>
				<div  v-if="hasUnlockedSoul" :class="{ activeItemType: activeGameOverFilter === 'Soul Bonds' }" class="filter" @click="updateGameOverFilter('Soul Bonds')">
					<span class="filter-text">Soul Bonds</span>
				</div>
				<div v-if="affiliatedFaction" :class="{ activeItemType: activeGameOverFilter === 'Factions' }" class="filter" @click="updateGameOverFilter('Factions')">
					<span class="filter-text">Factions</span>
				</div>
			</div>
			<div v-show="categoryType == 'customizePlayer'" class="filter-set">
				<div class="top-row">
					<div :class="{ activeItemType: activeSkinFilter === 'Player Skin' }" class="filter" @click="updateSkinTypeFilter('Player Skin')">
						<div class="filter-icon-item player-skins" :class="{ active: activeSkinFilter === 'Player Skin' }"></div>
						<span class="filter-name filter-panel-equipment">Player</span>
					</div>
					<div :class="{ activeItemType: activeSkinFilter === 'Pet Skin' }" class="filter" @click="updateSkinTypeFilter('Pet Skin')">
						<div class="filter-icon-item pet-skins" :class="{ active: activeSkinFilter === 'Pet Skin' }"></div>
						<span class="filter-name filter-panel-equipment">Pets</span>
					</div>
					<div :class="{ activeItemType: activeSkinFilter === 'Weapon Skins' }" class="filter" @click="updateSkinTypeFilter('Weapon Skins')">
						<div class="filter-icon-item weapons" :class="{ active: activeSkinFilter === 'Weapon Skins' }"></div>
						<span class="filter-name filter-panel-equipment">Weapon Skins</span>
					</div>
					<div :class="{ activeItemType: activeSkinFilter === 'Trail' }" class="filter" @click="updateSkinTypeFilter('Trail')">
						<div class="filter-icon-item trails" :class="{ active: activeSkinFilter === 'Trail' }"></div>
						<span class="filter-name filter-panel-equipment">Trails</span>
					</div>
					<div :class="{ activeItemType: activeSkinFilter === 'Back' }" class="filter" @click="updateSkinTypeFilter('Back')">
						<div class="filter-icon-item wings" :class="{ active: activeSkinFilter === 'Back' }"></div>
						<span class="filter-name filter-panel-equipment">Back</span>
					</div>
					<div :class="{ activeItemType: activeSkinFilter === 'Face' }" class="filter" @click="updateSkinTypeFilter('Face')">
						<div class="filter-icon-item face" :class="{ active: activeSkinFilter === 'Face' }"></div>
						<span class="filter-name filter-panel-equipment">Face</span>
					</div>
				</div>
				<div class="bottom-row">
					<!-- <div :class="{ activeItemType: activeSkinFilter === 'Wand' }" class="filter" @click="updateSkinTypeFilter('Wand')">
						<div class="filter-icon-item wand" :class="{ active: activeSkinFilter === 'Wand' }"></div>
						<span class="filter-name filter-panel-equipment">wand</span>
					</div>
					<div :class="{ activeItemType: activeSkinFilter === 'Staff' }" class="filter" @click="updateSkinTypeFilter('Staff')">
						<div class="filter-icon-item staff" :class="{ active: activeSkinFilter === 'Staff' }"></div>
						<span class="filter-name filter-panel-equipment">staff</span>
					</div>
					<div :class="{ activeItemType: activeSkinFilter === 'Sword' }" class="filter" @click="updateSkinTypeFilter('Sword')">
						<div class="filter-icon-item sword" :class="{ active: activeSkinFilter === 'Sword' }"></div>
						<span class="filter-name filter-panel-equipment">Sword</span>
					</div>
					<div :class="{ activeItemType: activeSkinFilter === 'Arcane' }" class="filter" @click="updateSkinTypeFilter('Arcane')">
						<div class="filter-icon-item arcane-focus" :class="{ active: activeSkinFilter === 'Arcane' }"></div>
						<span class="filter-name filter-panel-equipment">Arcane</span>
					</div>
					<div :class="{ activeItemType: activeSkinFilter === 'Crossbow' }" class="filter" @click="updateSkinTypeFilter('Crossbow')">
						<div class="filter-icon-item crossbow" :class="{ active: activeSkinFilter === 'Crossbow' }"></div>
						<span class="filter-name filter-panel-equipment">Crossbow</span>
					</div>
					<div :class="{ activeItemType: activeSkinFilter === 'Scythe' }" class="filter" @click="updateSkinTypeFilter('Scythe')">
						<div class="filter-icon-item scythe" :class="{ active: activeSkinFilter === 'Scythe' }"></div>
						<span class="filter-name filter-panel-equipment">Scythe</span>
					</div> -->
				</div>
			</div>
			<div v-show="categoryType === 'premiumStore'" class="filter-set">
				<div class="top-row">
					<div :class="{ activeItemType: activeMtxFilter === 'featured' }" class="filter" @click="updateMtxItemFilter('featured')">
						<div class="filter-icon-item featured" :class="{ active: activeMtxFilter === 'featured' }"></div>
						<div class="filter-name filter-panel-equipment">Featured</div>
					</div>
					<div :class="{ activeItemType: activeMtxFilter === 'bundle' }" class="filter" @click="updateMtxItemFilter('bundle')">
						<div class="filter-icon-item bundle" :class="{ active: activeMtxFilter === 'bundle' }"></div>
						<div class="filter-name filter-panel-equipment">Bundles</div>
					</div>
					<div :class="{ activeItemType: activeMtxFilter === 'skin' }" class="filter" @click="updateMtxItemFilter('skin')">
						<div class="filter-icon-item player-skins" :class="{ active: activeMtxFilter === 'skin' }"></div>
						<span class="filter-name filter-panel-equipment">Player</span>
					</div>
					<div :class="{ activeItemType: activeMtxFilter === 'attachment' }" class="filter" @click="updateMtxItemFilter('attachment')">
						<div class="filter-icon-item cosmetics" :class="{ active: activeMtxFilter === 'attachment' }"></div>
						<span class="filter-name filter-panel-equipment">Attachments</span>
					</div>
					<div :class="{ activeItemType: activeMtxFilter === 'pet' }" class="filter" @click="updateMtxItemFilter('pet')">
						<div class="filter-icon-item pet-skins" :class="{ active: activeMtxFilter === 'pet' }"></div>
						<span class="filter-name filter-panel-equipment">Pets</span>
					</div>
					<div :class="{ activeItemType: activeMtxFilter === 'weapon' }" class="filter" @click="updateMtxItemFilter('weapon')">
						<div class="filter-icon-item weapons" :class="{ active: activeMtxFilter === 'weapon' }"></div>
						<span class="filter-name filter-panel-equipment">Weapons</span>
					</div>
					<!-- <div :class="{ activeItemType: activeMtxFilter === 'consumable' }" class="filter" @click="updateMtxItemFilter('consumable')">
						<div class="filter-icon-item consumable" :class="{ active: activeMtxFilter === 'consumable' }"></div>
						<div class="filter-name filter-panel-equipment">Consumables</div>
					</div> -->
					<div :class="{ activeItemType: activeMtxFilter === 'account' }" class="filter" @click="updateMtxItemFilter('account')">
						<div class="filter-icon-item account" :class="{ active: activeMtxFilter === 'account' }"></div>
						<div class="filter-name filter-panel-equipment">Account</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex'

export default {
	name: 'CategoryFilter',
	components: {},
	props: {
		categoryType: {
			type: String,
			required: true,
			default: 'disable',
		},
	},
	computed: {
		...mapGetters('inGame', ['activePanel']),
		...mapState('itemContainers', ['activeItemTypeFilter', 'activeFilterCategory', 'itemSortOrder', 'activeSkinFilter', 'activeMtxFilter', 'categorySortOrderName', 'categorySortOrderLevel', 'categorySortOrderPrice']),
		...mapState('gameOver', ['activeGameOverFilter']),
		...mapState('factions', ['affiliatedFaction']),
		...mapGetters('marketplaceUpdated', ['getLoadingInProgress']),
		...mapGetters('boatLaunch', ['hasUnlockedSoul']),

		isMarketplaceLoading(){
			if(this.activePanel === 'marketplaceUpdated'){
				return this.getLoadingInProgress
			}
		}
	},
	methods: {
		...mapMutations('itemContainers', ['updateItemTypeFilter', 'updateSortOrder', 'updateSkinTypeFilter', 'updateMtxItemFilter']),
		...mapMutations('gameOver', ['updateGameOverFilter']),
	},
}
</script>

<style lang="less" scoped>
@import '../../global/fonts/text.less';
@import '../../global/bg-gradients.less';

.category-filter {
	.filters {
		display: flex;
		flex-direction: column;
		.filter-set {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			align-items: center;
			.top-row,
			.bottom-row {
				display: flex;
				flex-flow: row;
				flex-basis: 100%;
			}

			.filter {
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 1px;
				flex: 1;
				color: #446ef6;
				white-space: nowrap;
				.button-bg-gradient();
				&.activeSortCategory {
					background: unset;
				}
				&.disabled {
							opacity: 0.5;
				}

				.filter-text {
					width: 100%;
					height: 52px;
					background-size: cover;
					background-repeat: no-repeat;
					display: flex;
					align-items: center;
					justify-content: center;
					flex: auto;
					.filter-panel-equipment();
				}
				&.activeSortCategory {
					color: white;
				}
				.filter-sort-arrow-container {
					.filter-sort-direction {
						img {
							filter: brightness(6);
							&.desc {
								transform: rotate(180deg);
							}
						}
					}
				}
				.filter-icon-item {
					width: 83px;
					height: 52px;
					background-size: cover;
					background-repeat: no-repeat;
					display: flex;
					align-items: center;
					justify-content: center;
					&.weapons {
						background-image: url('../item-containers/assets/weapons-icon.png');
					}
					&.gear {
						background-image: url('../item-containers/assets/gear-icon.png');
					}
					&.augments {
						background-image: url('../item-containers/assets/augment-icon.png');
					}
					&.player-skins {
						background-image: url('../item-containers/assets/skins-icon.png');
					}
					&.pet-skins {
						background-image: url('../item-containers/assets/pet-icon.png');
					}
					&.trails {
						background-image: url('../item-containers/assets/trail-icon.png');
					}
					&.wings {
						background-image: url('../item-containers/assets/wings-icon.png');
					}
					&.face {
						background-position-x: 15px;
						background-size: 49px 52px;
						background-image: url('../item-containers/assets/face-cosmetics-icon.png');
					}
					&.wand {
						background-image: url('../item-containers/assets/wand-icon.png');
					}
					&.staff {
						background-image: url('../item-containers/assets/staff-icon.png');
					}
					&.sword {
						background-image: url('../item-containers/assets/sword-icon.png');
					}
					&.arcane-focus {
						background-image: url('../item-containers/assets/arcane-icon.png');
					}
					&.scythe {
						background-image: url('../item-containers/assets/scythe-icon.png');
					}
					&.crossbow {
						background-image: url('../item-containers/assets/crossbow-icon.png');
					}
					&.egg {
						background-image: url('../item-containers/assets/eggs-icon.png');
					}
					&.cosmetics {
						background-image: url('../item-containers/assets/cosmetics-icon.png');
					}
					&.consumable {
						background-image: url('../item-containers/assets/new-icon.png');
					}
					&.account {
						background-image: url('../item-containers/assets/account-tab-icon.png');
					}
					&.featured {
						background-image: url('../item-containers/assets/favorite-icon.png');
					}
					&.bundle {
						background-image: url('../item-containers/assets/bundle-tab-icon.png');
						// The other images are 83x52
						// 17px offset is (83-49) / 2
						background-size: 49px 52px; 
						background-position: 17px;
					}
					&.active {
						filter: brightness(6);
					}
				}
				.filter-name {
					display: none;
					align-items: center;
					justify-content: center;
					margin-left: 0px;
					padding: 0px 27px 0px 0px;
				}

				&.active {
					color: white;
					background-color: rgba(0, 0, 0, 0);
					.filter-name {
						display: flex;
					}
				}
				&.activeItemType {
					color: white;
					background-color: rgba(0, 0, 0, 0);
					background: rgba(0, 0, 0, 0);
					.filter-name {
						display: flex;
						.filter-panel-equipment();
					}
				}
				&.activeRarity {
					color: white;
					background-color: rgba(0, 0, 0, 0);
					background: rgba(0, 0, 0, 0);
					.filter-name {
						display: flex;
						.filter-panel-rarity();
					}
				}
			}
		}
	}
}
</style>
