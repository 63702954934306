import { EnemyAI, FightingTriggers, IdleBehaviours, FightingBehaviours, LeashTriggers, LeashingBehaviours, AttackTypes, DeadBehaviours, ShotLeadPrecision, EnemyType, EnemyTargetingStyles } from '../ai-types'
import { SpineDataName, EnemyBipedSkinType } from '../../../models-animations/shared/spine-config'
import { EnemyDefaults } from './enemy-defaults'
import { ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { bossEnemyDamageConfig, BOSS_HEALTH_MULTIPLIER, BOSS_LOOT_DROP_QUANTITY, BOSS_LOOT_DROP_RARITY, ENEMY_DEFAULT_HEALTH } from '../../../engine/shared/game-data/enemy-formulas'
import { alwaysTrueCriteria, HardDifficultyCriteria, BrutalDifficultyCriteria, NastyDifficultyCriteria, playAnimationAbility, shakeCameraAbility } from './abilities/common-abilities'
import { AbilityOptionallyWithWeightOrTiming, AbilitySelectionStyles, CriteriaSatisfactionQuantity, CriteriaTarget, CriteriaType } from '../action-types'
import {
	flyToPositionAbility,
	walkToPositionAbility,
	setInvisibleAbility,
	setVisibleAbility,
	disableCollidersAbility,
	enableCollidersAbility,
	flyToCenterAbility,
	flyToPositionIncludingEdgesAbility,
	cooldownEnemyCooldown60000,
	cooldownEnemyCooldown10000,
	crystalBreathAbilityEasy,
	wingGustAbilityEasy,
	skyRaidAbilityEasy,
	skyRaidAbilityV2Easy,
	crystalCocoonAbilityEasy,
	crystalBreathAbilitySpawningEasy,
	crystalBreathAbilityMedium,
	wingGustAbilityMedium,
	skyRaidAbilityMedium,
	skyRaidAbilityV2Medium,
	crystalCocoonAbilityMedium,
	crystalBreathAbilitySpawningMedium,

	crystalBreathAbilityBrutal,
	wingGustAbilityBrutal,
	skyRaidAbilityBrutal,
	skyRaidAbilityV2Brutal,
	crystalCocoonAbilityBrutal,
	crystalBreathAbilitySpawningBrutal,

	crystalBreathAbilityNasty,
	wingGustAbilityNasty,
	skyRaidAbilityNasty,
	skyRaidAbilityV2Nasty,
	crystalCocoonAbilityNasty,
	crystalBreathAbilitySpawningNasty,


} from './abilities/prism-boss-abilities'
import { AnimationTrack } from '../../../models-animations/shared/animation-track'
import { Vector } from 'sat'
import { CameraShakeIntensities } from '../../../engine/shared/camera.shared'
import { BuffIdentifier } from '../../../buffs/shared/buff.shared'
import { ENEMY_NAMES } from './enemy-names'

const PHASE_1_2_HEALTH_BOUNDARY = 50
const EASY_TIME_MULTI = (weight): number => { return Math.ceil(weight * 1.8) }

const JUST_FLYING_AROUND_INTERIOR: AbilityOptionallyWithWeightOrTiming = [
	1,
	playAnimationAbility(AnimationTrack.SKY_RAID_IN),
	3,
	disableCollidersAbility,
	setInvisibleAbility,
	flyToPositionAbility,
	5,
	playAnimationAbility(AnimationTrack.SKY_RAID_LANDING, 1),
	setVisibleAbility,
	4,
	enableCollidersAbility,
	shakeCameraAbility(CameraShakeIntensities.INTENSE, 0),
	10,
	enableCollidersAbility,
	10,
]
const JUST_FLYING_AROUND_EVERYWHERE: AbilityOptionallyWithWeightOrTiming = [
	1,
	playAnimationAbility(AnimationTrack.SKY_RAID_IN),
	3,
	disableCollidersAbility,
	setInvisibleAbility,
	flyToPositionIncludingEdgesAbility,
	5,
	playAnimationAbility(AnimationTrack.SKY_RAID_LANDING, 1),
	setVisibleAbility,
	4,
	enableCollidersAbility,
	shakeCameraAbility(CameraShakeIntensities.INTENSE, 0),
	10,
	enableCollidersAbility,
	10,
]

const boss: EnemyAI = {
	actionDrivenEnemyData: {
		targetColliders: [
			{ name: 'centerTop', colliders: [{ type: ColliderType.Circle, traits: ColliderTraits.Trigger, position: [-51, -264], radius: 10 }] },
			{ name: 'centerHighTop', colliders: [{ type: ColliderType.Circle, traits: ColliderTraits.Trigger, position: [-51, -404], radius: 10 }] },
			{ name: 'centerTopLeft', colliders: [{ type: ColliderType.Circle, traits: ColliderTraits.Trigger, position: [-617, -242], radius: 10 }] },
			{ name: 'centerTopRight', colliders: [{ type: ColliderType.Circle, traits: ColliderTraits.Trigger, position: [657, -244], radius: 10 }] },
			{ name: 'centerRight', colliders: [{ type: ColliderType.Circle, traits: ColliderTraits.Trigger, position: [532, 160], radius: 10 }] },
			{ name: 'centerLeft', colliders: [{ type: ColliderType.Circle, traits: ColliderTraits.Trigger, position: [-434, 223], radius: 10 }] },
			{ name: 'centerMiddle', colliders: [{ type: ColliderType.Circle, traits: ColliderTraits.Trigger, position: [1, 227], radius: 10 }] },
			{ name: 'centerBottom', colliders: [{ type: ColliderType.Circle, traits: ColliderTraits.Trigger, position: [20, 514], radius: 10 }] },
			{ name: 'rightTop', colliders: [{ type: ColliderType.Circle, traits: ColliderTraits.Trigger, position: [1302, -849], radius: 10 }] },
			// { name: 'rightMiddle', colliders: [{ type: ColliderType.Circle, traits: ColliderTraits.Trigger, position: [1414, -22], radius: 10 }] },
			{ name: 'rightBottom', colliders: [{ type: ColliderType.Circle, traits: ColliderTraits.Trigger, position: [1455, 797], radius: 10 }] },
			{ name: 'leftTop', colliders: [{ type: ColliderType.Circle, traits: ColliderTraits.Trigger, position: [-1319, -810], radius: 10 }] },
			// { name: 'leftMiddle', colliders: [{ type: ColliderType.Circle, traits: ColliderTraits.Trigger, position: [-1438, 81], radius: 10 }] },
			{ name: 'leftBottom', colliders: [{ type: ColliderType.Circle, traits: ColliderTraits.Trigger, position: [-1407, 875], radius: 10 }] },
		],

		engagementColliders: [{ type: ColliderType.Box, traits: ColliderTraits.Trigger, position: [-1771, -1227], width: 3609, height: 2388 }],
		resetOnDisengage: true,

		unengagedActions: [
			{
				priority: 0,
				actionCriteria: alwaysTrueCriteria,
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
					abilityOptions: [[1, walkToPositionAbility, 10]],
				},
			},
		],
		actions: [



			{
				// phase 1 - Minions Present, Sky Raid Available - Nasty Difficulty
				priority: 101,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						NastyDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_1_2_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
						{
							criteriaTargets: CriteriaTarget.AI_UNITS,
							criteriaType: CriteriaType.WITHIN_RANGE,
							criteriaValue: 20000,
							minimumTargetsThatSatisfy: 10,
						},
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown10000,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[2, walkToPositionAbility, 10],
						[
							// just flying around
							...JUST_FLYING_AROUND_INTERIOR,
						],
						[
							// sky raid v 1
							2,
							playAnimationAbility(AnimationTrack.SKY_RAID_IN),
							3,
							disableCollidersAbility,
							setInvisibleAbility,
							10,
							skyRaidAbilityNasty,
							10,
							flyToPositionAbility,
							playAnimationAbility(AnimationTrack.SKY_RAID_LANDING, 1),
							setVisibleAbility,
							4,
							enableCollidersAbility,
							shakeCameraAbility(CameraShakeIntensities.INTENSE, 0),
							cooldownEnemyCooldown10000,
							10,
							enableCollidersAbility,
							10,
						],
						[4, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilityNasty, 20],
						[6, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityNasty, 20],
					],
				},
			},
			{
				// phase 1 - Minions Present, No Sky Raid.
				priority: 102,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						NastyDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_1_2_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
						{
							criteriaTargets: CriteriaTarget.AI_UNITS,
							criteriaType: CriteriaType.WITHIN_RANGE,
							criteriaValue: 20000,
							minimumTargetsThatSatisfy: 10,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[2, walkToPositionAbility, 10],
						[
							// just flying around
							...JUST_FLYING_AROUND_INTERIOR,
						],
						[4, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilityNasty, 20],
						[6, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityNasty, 20],
					],
				},
			},
			{
				// phase 1 - No Minions, Sky Raid Available
				priority: 103,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						NastyDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_1_2_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown10000,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[2, walkToPositionAbility, 10],
						[
							// just flying around
							...JUST_FLYING_AROUND_INTERIOR,
						],
						[
							// sky raid v 1
							2,
							playAnimationAbility(AnimationTrack.SKY_RAID_IN),
							3,
							disableCollidersAbility,
							setInvisibleAbility,
							10,
							skyRaidAbilityNasty,
							10,
							flyToPositionAbility,
							playAnimationAbility(AnimationTrack.SKY_RAID_LANDING, 1),
							setVisibleAbility,
							4,
							enableCollidersAbility,
							shakeCameraAbility(CameraShakeIntensities.INTENSE, 0),
							cooldownEnemyCooldown10000,
							10,
							enableCollidersAbility,
							10,
						],
						[4, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilitySpawningNasty, 20],
						[6, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityNasty, 20],
					],
				},
			},
			{
				// phase 1 - No Minions, No Sky Raid
				priority: 104,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						NastyDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_1_2_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[2, walkToPositionAbility, 10],
						[
							// just flying around
							...JUST_FLYING_AROUND_INTERIOR,
						],
						[4, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilitySpawningNasty, 20],
						[6, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityNasty, 20],
					],
				},
			},
			{
				// phase 2 - Minions Present, Sky Raid Available, Cocoon Available
				priority: 105,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						NastyDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.AI_UNITS,
							criteriaType: CriteriaType.WITHIN_RANGE,
							criteriaValue: 20000,
							minimumTargetsThatSatisfy: 10,
						},
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown10000,
							minimumTargetsThatSatisfy: 1,
						},
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown1000PerStack,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[
							// just flying around phase 2
							...JUST_FLYING_AROUND_EVERYWHERE,
						],
						[
							// sky raid v2
							2,
							playAnimationAbility(AnimationTrack.SKY_RAID_IN),
							3,
							disableCollidersAbility,
							setInvisibleAbility,
							10,
							skyRaidAbilityV2Nasty,
							10,
							flyToPositionIncludingEdgesAbility,
							playAnimationAbility(AnimationTrack.SKY_RAID_LANDING, 1),
							setVisibleAbility,
							4,
							enableCollidersAbility,
							shakeCameraAbility(CameraShakeIntensities.INTENSE, 0),
							cooldownEnemyCooldown10000,
							10,
							enableCollidersAbility,
							10,
						],
						[
							// crystal coccon
							1,
							playAnimationAbility(AnimationTrack.SKY_RAID_IN),
							3,
							disableCollidersAbility,
							setInvisibleAbility,
							flyToCenterAbility,
							5,
							playAnimationAbility(AnimationTrack.SKY_RAID_LANDING, 1),
							setVisibleAbility,
							4,
							enableCollidersAbility,
							shakeCameraAbility(CameraShakeIntensities.INTENSE, 0),
							10,
							enableCollidersAbility,
							playAnimationAbility(AnimationTrack.CRYSTAL_COCOON_IN),
							crystalCocoonAbilityNasty,
							shakeCameraAbility(CameraShakeIntensities.DEAR_LORD, 0),
							cooldownEnemyCooldown60000,
							playAnimationAbility(AnimationTrack.CRYSTAL_COCOON_OUT),
							100,
						],
						[4, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilityNasty, 20],
						[6, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityNasty, 20],
					],
				},
			},
			{
				// phase 2 - Minions Present, Cocoon Available, No Sky Raid
				priority: 106,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						NastyDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.AI_UNITS,
							criteriaType: CriteriaType.WITHIN_RANGE,
							criteriaValue: 20000,
							minimumTargetsThatSatisfy: 10,
						},
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown1000PerStack,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[
							// just flying around phase 2
							...JUST_FLYING_AROUND_EVERYWHERE,
						],
						[
							// crystal coccon
							1,
							playAnimationAbility(AnimationTrack.SKY_RAID_IN),
							3,
							disableCollidersAbility,
							setInvisibleAbility,
							flyToCenterAbility,
							5,
							playAnimationAbility(AnimationTrack.SKY_RAID_LANDING, 1),
							setVisibleAbility,
							4,
							enableCollidersAbility,
							shakeCameraAbility(CameraShakeIntensities.INTENSE, 0),
							10,
							enableCollidersAbility,
							playAnimationAbility(AnimationTrack.CRYSTAL_COCOON_IN),
							crystalCocoonAbilityNasty,
							shakeCameraAbility(CameraShakeIntensities.DEAR_LORD, 0),
							cooldownEnemyCooldown60000,
							playAnimationAbility(AnimationTrack.CRYSTAL_COCOON_OUT),
							100,
						],
						[4, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilityNasty, 20],
						[6, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityNasty, 20],
					],
				},
			},
			{
				// phase 2 - Minions Present, Sky Raid Available, No Cocoon
				priority: 107,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						NastyDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.AI_UNITS,
							criteriaType: CriteriaType.WITHIN_RANGE,
							criteriaValue: 20000,
							minimumTargetsThatSatisfy: 10,
						},
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown10000,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[
							// just flying around phase 2
							...JUST_FLYING_AROUND_EVERYWHERE,
						],
						[
							// sky raid v2
							2,
							playAnimationAbility(AnimationTrack.SKY_RAID_IN),
							3,
							disableCollidersAbility,
							setInvisibleAbility,
							10,
							skyRaidAbilityV2Nasty,
							10,
							flyToPositionIncludingEdgesAbility,
							playAnimationAbility(AnimationTrack.SKY_RAID_LANDING, 1),
							setVisibleAbility,
							4,
							enableCollidersAbility,
							shakeCameraAbility(CameraShakeIntensities.INTENSE, 0),
							cooldownEnemyCooldown10000,
							10,
							enableCollidersAbility,
							10,
						],
						[4, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilityNasty, 20],
						[6, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityNasty, 20],
					],
				},
			},
			{
				// phase 2 - No Minions, Sky Raid Available, Cocoon Available
				priority: 108,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						NastyDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown10000,
							minimumTargetsThatSatisfy: 1,
						},
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown1000PerStack,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[
							// just flying around phase 2
							...JUST_FLYING_AROUND_EVERYWHERE,
						],
						[
							// sky raid v2
							2,
							playAnimationAbility(AnimationTrack.SKY_RAID_IN),
							3,
							disableCollidersAbility,
							setInvisibleAbility,
							10,
							skyRaidAbilityV2Nasty,
							10,
							flyToPositionIncludingEdgesAbility,
							playAnimationAbility(AnimationTrack.SKY_RAID_LANDING, 1),
							setVisibleAbility,
							4,
							enableCollidersAbility,
							shakeCameraAbility(CameraShakeIntensities.INTENSE, 0),
							cooldownEnemyCooldown10000,
							10,
							enableCollidersAbility,
							10,
						],
						[
							// crystal coccon
							1,
							playAnimationAbility(AnimationTrack.SKY_RAID_IN),
							3,
							disableCollidersAbility,
							setInvisibleAbility,
							flyToCenterAbility,
							5,
							playAnimationAbility(AnimationTrack.SKY_RAID_LANDING, 1),
							setVisibleAbility,
							4,
							enableCollidersAbility,
							shakeCameraAbility(CameraShakeIntensities.INTENSE, 0),
							10,
							enableCollidersAbility,
							playAnimationAbility(AnimationTrack.CRYSTAL_COCOON_IN),
							crystalCocoonAbilityNasty,
							shakeCameraAbility(CameraShakeIntensities.DEAR_LORD, 0),
							cooldownEnemyCooldown60000,
							playAnimationAbility(AnimationTrack.CRYSTAL_COCOON_OUT),
							100,
						],
						[6, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilitySpawningNasty, 20],
						[4, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityNasty, 20],
					],
				},
			},
			{
				// phase 2 - No Minions, Cocoon Available, No Sky Raid
				priority: 109,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						NastyDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown1000PerStack,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[
							// just flying around phase 2
							...JUST_FLYING_AROUND_EVERYWHERE,
						],
						[
							// crystal coccon
							1,
							playAnimationAbility(AnimationTrack.SKY_RAID_IN),
							3,
							disableCollidersAbility,
							setInvisibleAbility,
							flyToCenterAbility,
							5,
							playAnimationAbility(AnimationTrack.SKY_RAID_LANDING, 1),
							setVisibleAbility,
							4,
							enableCollidersAbility,
							shakeCameraAbility(CameraShakeIntensities.INTENSE, 0),
							10,
							enableCollidersAbility,
							playAnimationAbility(AnimationTrack.CRYSTAL_COCOON_IN),
							crystalCocoonAbilityNasty,
							shakeCameraAbility(CameraShakeIntensities.DEAR_LORD, 0),
							cooldownEnemyCooldown60000,
							playAnimationAbility(AnimationTrack.CRYSTAL_COCOON_OUT),
							100,
						],
						[6, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilitySpawningNasty, 20],
						[4, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityNasty, 20],
					],
				},
			},
			{
				// phase 2 - No Minions, Sky Raid Available, No Cocoon
				priority: 110,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						NastyDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown10000,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[
							// just flying around phase 2
							...JUST_FLYING_AROUND_EVERYWHERE,
						],
						[
							// sky raid v2
							2,
							playAnimationAbility(AnimationTrack.SKY_RAID_IN),
							3,
							disableCollidersAbility,
							setInvisibleAbility,
							10,
							skyRaidAbilityV2Nasty,
							10,
							flyToPositionIncludingEdgesAbility,
							playAnimationAbility(AnimationTrack.SKY_RAID_LANDING, 1),
							setVisibleAbility,
							4,
							enableCollidersAbility,
							shakeCameraAbility(CameraShakeIntensities.INTENSE, 0),
							cooldownEnemyCooldown10000,
							10,
							enableCollidersAbility,
							10,
						],
						[6, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilitySpawningNasty, 20],
						[4, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityNasty, 20],
					],
				},
			},
			{
				// phase phase 2 - No Minions, No Sky Raid, No Cocoon
				priority: 111,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						NastyDifficultyCriteria,
						alwaysTrueCriteria,
					]
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[
							// just flying around phase 2
							...JUST_FLYING_AROUND_EVERYWHERE,
						],
						[4, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilitySpawningNasty, 20],
						[6, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityNasty, 20],
					],
				},
			},


















			{
				// phase 1 - Minions Present, Sky Raid Available - Brutal Difficulty
				priority: 201,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						BrutalDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_1_2_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
						{
							criteriaTargets: CriteriaTarget.AI_UNITS,
							criteriaType: CriteriaType.WITHIN_RANGE,
							criteriaValue: 20000,
							minimumTargetsThatSatisfy: 10,
						},
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown10000,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[2, walkToPositionAbility, 10],
						[
							// just flying around
							...JUST_FLYING_AROUND_INTERIOR,
						],
						[
							// sky raid v 1
							2,
							playAnimationAbility(AnimationTrack.SKY_RAID_IN),
							3,
							disableCollidersAbility,
							setInvisibleAbility,
							10,
							skyRaidAbilityBrutal,
							10,
							flyToPositionAbility,
							playAnimationAbility(AnimationTrack.SKY_RAID_LANDING, 1),
							setVisibleAbility,
							4,
							enableCollidersAbility,
							shakeCameraAbility(CameraShakeIntensities.INTENSE, 0),
							cooldownEnemyCooldown10000,
							10,
							enableCollidersAbility,
							10,
						],
						[4, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilityBrutal, 20],
						[6, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityBrutal, 20],
					],
				},
			},
			{
				// phase 1 - Minions Present, No Sky Raid.
				priority: 202,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						BrutalDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_1_2_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
						{
							criteriaTargets: CriteriaTarget.AI_UNITS,
							criteriaType: CriteriaType.WITHIN_RANGE,
							criteriaValue: 20000,
							minimumTargetsThatSatisfy: 10,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[2, walkToPositionAbility, 10],
						[
							// just flying around
							...JUST_FLYING_AROUND_INTERIOR,
						],
						[4, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilityBrutal, 20],
						[6, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityBrutal, 20],
					],
				},
			},
			{
				// phase 1 - No Minions, Sky Raid Available
				priority: 203,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						BrutalDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_1_2_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown10000,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[2, walkToPositionAbility, 10],
						[
							// just flying around
							...JUST_FLYING_AROUND_INTERIOR,
						],
						[
							// sky raid v 1
							2,
							playAnimationAbility(AnimationTrack.SKY_RAID_IN),
							3,
							disableCollidersAbility,
							setInvisibleAbility,
							10,
							skyRaidAbilityBrutal,
							10,
							flyToPositionAbility,
							playAnimationAbility(AnimationTrack.SKY_RAID_LANDING, 1),
							setVisibleAbility,
							4,
							enableCollidersAbility,
							shakeCameraAbility(CameraShakeIntensities.INTENSE, 0),
							cooldownEnemyCooldown10000,
							10,
							enableCollidersAbility,
							10,
						],
						[4, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilitySpawningBrutal, 20],
						[6, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityBrutal, 20],
					],
				},
			},
			{
				// phase 1 - No Minions, No Sky Raid
				priority: 204,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						BrutalDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_1_2_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[2, walkToPositionAbility, 10],
						[
							// just flying around
							...JUST_FLYING_AROUND_INTERIOR,
						],
						[4, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilitySpawningBrutal, 20],
						[6, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityBrutal, 20],
					],
				},
			},
			{
				// phase 2 - Minions Present, Sky Raid Available, Cocoon Available
				priority: 205,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						BrutalDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.AI_UNITS,
							criteriaType: CriteriaType.WITHIN_RANGE,
							criteriaValue: 20000,
							minimumTargetsThatSatisfy: 10,
						},
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown10000,
							minimumTargetsThatSatisfy: 1,
						},
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown1000PerStack,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[
							// just flying around phase 2
							...JUST_FLYING_AROUND_EVERYWHERE,
						],
						[
							// sky raid v2
							2,
							playAnimationAbility(AnimationTrack.SKY_RAID_IN),
							3,
							disableCollidersAbility,
							setInvisibleAbility,
							10,
							skyRaidAbilityV2Brutal,
							10,
							flyToPositionIncludingEdgesAbility,
							playAnimationAbility(AnimationTrack.SKY_RAID_LANDING, 1),
							setVisibleAbility,
							4,
							enableCollidersAbility,
							shakeCameraAbility(CameraShakeIntensities.INTENSE, 0),
							cooldownEnemyCooldown10000,
							10,
							enableCollidersAbility,
							10,
						],
						[
							// crystal coccon
							1,
							playAnimationAbility(AnimationTrack.SKY_RAID_IN),
							3,
							disableCollidersAbility,
							setInvisibleAbility,
							flyToCenterAbility,
							5,
							playAnimationAbility(AnimationTrack.SKY_RAID_LANDING, 1),
							setVisibleAbility,
							4,
							enableCollidersAbility,
							shakeCameraAbility(CameraShakeIntensities.INTENSE, 0),
							10,
							enableCollidersAbility,
							playAnimationAbility(AnimationTrack.CRYSTAL_COCOON_IN),
							crystalCocoonAbilityBrutal,
							shakeCameraAbility(CameraShakeIntensities.DEAR_LORD, 0),
							cooldownEnemyCooldown60000,
							playAnimationAbility(AnimationTrack.CRYSTAL_COCOON_OUT),
							100,
						],
						[4, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilityBrutal, 20],
						[6, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityBrutal, 20],
					],
				},
			},
			{
				// phase 2 - Minions Present, Cocoon Available, No Sky Raid
				priority: 206,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						BrutalDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.AI_UNITS,
							criteriaType: CriteriaType.WITHIN_RANGE,
							criteriaValue: 20000,
							minimumTargetsThatSatisfy: 10,
						},
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown1000PerStack,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[
							// just flying around phase 2
							...JUST_FLYING_AROUND_EVERYWHERE,
						],
						[
							// crystal coccon
							1,
							playAnimationAbility(AnimationTrack.SKY_RAID_IN),
							3,
							disableCollidersAbility,
							setInvisibleAbility,
							flyToCenterAbility,
							5,
							playAnimationAbility(AnimationTrack.SKY_RAID_LANDING, 1),
							setVisibleAbility,
							4,
							enableCollidersAbility,
							shakeCameraAbility(CameraShakeIntensities.INTENSE, 0),
							10,
							enableCollidersAbility,
							playAnimationAbility(AnimationTrack.CRYSTAL_COCOON_IN),
							crystalCocoonAbilityBrutal,
							shakeCameraAbility(CameraShakeIntensities.DEAR_LORD, 0),
							cooldownEnemyCooldown60000,
							playAnimationAbility(AnimationTrack.CRYSTAL_COCOON_OUT),
							100,
						],
						[4, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilityBrutal, 20],
						[6, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityBrutal, 20],
					],
				},
			},
			{
				// phase 2 - Minions Present, Sky Raid Available, No Cocoon
				priority: 207,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						BrutalDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.AI_UNITS,
							criteriaType: CriteriaType.WITHIN_RANGE,
							criteriaValue: 20000,
							minimumTargetsThatSatisfy: 10,
						},
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown10000,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[
							// just flying around phase 2
							...JUST_FLYING_AROUND_EVERYWHERE,
						],
						[
							// sky raid v2
							2,
							playAnimationAbility(AnimationTrack.SKY_RAID_IN),
							3,
							disableCollidersAbility,
							setInvisibleAbility,
							10,
							skyRaidAbilityV2Brutal,
							10,
							flyToPositionIncludingEdgesAbility,
							playAnimationAbility(AnimationTrack.SKY_RAID_LANDING, 1),
							setVisibleAbility,
							4,
							enableCollidersAbility,
							shakeCameraAbility(CameraShakeIntensities.INTENSE, 0),
							cooldownEnemyCooldown10000,
							10,
							enableCollidersAbility,
							10,
						],
						[4, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilityBrutal, 20],
						[6, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityBrutal, 20],
					],
				},
			},
			{
				// phase 2 - No Minions, Sky Raid Available, Cocoon Available
				priority: 208,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						BrutalDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown10000,
							minimumTargetsThatSatisfy: 1,
						},
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown1000PerStack,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[
							// just flying around phase 2
							...JUST_FLYING_AROUND_EVERYWHERE,
						],
						[
							// sky raid v2
							2,
							playAnimationAbility(AnimationTrack.SKY_RAID_IN),
							3,
							disableCollidersAbility,
							setInvisibleAbility,
							10,
							skyRaidAbilityV2Brutal,
							10,
							flyToPositionIncludingEdgesAbility,
							playAnimationAbility(AnimationTrack.SKY_RAID_LANDING, 1),
							setVisibleAbility,
							4,
							enableCollidersAbility,
							shakeCameraAbility(CameraShakeIntensities.INTENSE, 0),
							cooldownEnemyCooldown10000,
							10,
							enableCollidersAbility,
							10,
						],
						[
							// crystal coccon
							1,
							playAnimationAbility(AnimationTrack.SKY_RAID_IN),
							3,
							disableCollidersAbility,
							setInvisibleAbility,
							flyToCenterAbility,
							5,
							playAnimationAbility(AnimationTrack.SKY_RAID_LANDING, 1),
							setVisibleAbility,
							4,
							enableCollidersAbility,
							shakeCameraAbility(CameraShakeIntensities.INTENSE, 0),
							10,
							enableCollidersAbility,
							playAnimationAbility(AnimationTrack.CRYSTAL_COCOON_IN),
							crystalCocoonAbilityBrutal,
							shakeCameraAbility(CameraShakeIntensities.DEAR_LORD, 0),
							cooldownEnemyCooldown60000,
							playAnimationAbility(AnimationTrack.CRYSTAL_COCOON_OUT),
							100,
						],
						[6, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilitySpawningBrutal, 20],
						[4, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityBrutal, 20],
					],
				},
			},
			{
				// phase 2 - No Minions, Cocoon Available, No Sky Raid
				priority: 209,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						BrutalDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown1000PerStack,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[
							// just flying around phase 2
							...JUST_FLYING_AROUND_EVERYWHERE,
						],
						[
							// crystal coccon
							1,
							playAnimationAbility(AnimationTrack.SKY_RAID_IN),
							3,
							disableCollidersAbility,
							setInvisibleAbility,
							flyToCenterAbility,
							5,
							playAnimationAbility(AnimationTrack.SKY_RAID_LANDING, 1),
							setVisibleAbility,
							4,
							enableCollidersAbility,
							shakeCameraAbility(CameraShakeIntensities.INTENSE, 0),
							10,
							enableCollidersAbility,
							playAnimationAbility(AnimationTrack.CRYSTAL_COCOON_IN),
							crystalCocoonAbilityBrutal,
							shakeCameraAbility(CameraShakeIntensities.DEAR_LORD, 0),
							cooldownEnemyCooldown60000,
							playAnimationAbility(AnimationTrack.CRYSTAL_COCOON_OUT),
							100,
						],
						[6, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilitySpawningBrutal, 20],
						[4, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityBrutal, 20],
					],
				},
			},
			{
				// phase 2 - No Minions, Sky Raid Available, No Cocoon
				priority: 210,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						BrutalDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown10000,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[
							// just flying around phase 2
							...JUST_FLYING_AROUND_EVERYWHERE,
						],
						[
							// sky raid v2
							2,
							playAnimationAbility(AnimationTrack.SKY_RAID_IN),
							3,
							disableCollidersAbility,
							setInvisibleAbility,
							10,
							skyRaidAbilityV2Brutal,
							10,
							flyToPositionIncludingEdgesAbility,
							playAnimationAbility(AnimationTrack.SKY_RAID_LANDING, 1),
							setVisibleAbility,
							4,
							enableCollidersAbility,
							shakeCameraAbility(CameraShakeIntensities.INTENSE, 0),
							cooldownEnemyCooldown10000,
							10,
							enableCollidersAbility,
							10,
						],
						[6, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilitySpawningBrutal, 20],
						[4, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityBrutal, 20],
					],
				},
			},
			{
				// phase phase 2 - No Minions, No Sky Raid, No Cocoon
				priority: 211,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						BrutalDifficultyCriteria,
						alwaysTrueCriteria,
					]
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[
							// just flying around phase 2
							...JUST_FLYING_AROUND_EVERYWHERE,
						],
						[4, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilitySpawningBrutal, 20],
						[6, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityBrutal, 20],
					],
				},
			},



















			{
				// phase 1 - Minions Present, Sky Raid Available - Hard Difficulty
				priority: 301,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						HardDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_1_2_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
						{
							criteriaTargets: CriteriaTarget.AI_UNITS,
							criteriaType: CriteriaType.WITHIN_RANGE,
							criteriaValue: 20000,
							minimumTargetsThatSatisfy: 10,
						},
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown10000,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[2, walkToPositionAbility, 10],
						[
							// just flying around
							...JUST_FLYING_AROUND_INTERIOR,
						],
						[
							// sky raid v 1
							2,
							playAnimationAbility(AnimationTrack.SKY_RAID_IN),
							3,
							disableCollidersAbility,
							setInvisibleAbility,
							10,
							skyRaidAbilityMedium,
							10,
							flyToPositionAbility,
							playAnimationAbility(AnimationTrack.SKY_RAID_LANDING, 1),
							setVisibleAbility,
							4,
							enableCollidersAbility,
							shakeCameraAbility(CameraShakeIntensities.INTENSE, 0),
							cooldownEnemyCooldown10000,
							10,
							enableCollidersAbility,
							10,
						],
						[4, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilityMedium, 20],
						[6, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityMedium, 20],
					],
				},
			},
			{
				// phase 1 - Minions Present, No Sky Raid.
				priority: 302,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						HardDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_1_2_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
						{
							criteriaTargets: CriteriaTarget.AI_UNITS,
							criteriaType: CriteriaType.WITHIN_RANGE,
							criteriaValue: 20000,
							minimumTargetsThatSatisfy: 10,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[2, walkToPositionAbility, 10],
						[
							// just flying around
							...JUST_FLYING_AROUND_INTERIOR,
						],
						[4, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilityMedium, 20],
						[6, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityMedium, 20],
					],
				},
			},
			{
				// phase 1 - No Minions, Sky Raid Available
				priority: 303,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						HardDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_1_2_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown10000,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[2, walkToPositionAbility, 10],
						[
							// just flying around
							...JUST_FLYING_AROUND_INTERIOR,
						],
						[
							// sky raid v 1
							2,
							playAnimationAbility(AnimationTrack.SKY_RAID_IN),
							3,
							disableCollidersAbility,
							setInvisibleAbility,
							10,
							skyRaidAbilityMedium,
							10,
							flyToPositionAbility,
							playAnimationAbility(AnimationTrack.SKY_RAID_LANDING, 1),
							setVisibleAbility,
							4,
							enableCollidersAbility,
							shakeCameraAbility(CameraShakeIntensities.INTENSE, 0),
							cooldownEnemyCooldown10000,
							10,
							enableCollidersAbility,
							10,
						],
						[4, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilitySpawningMedium, 20],
						[6, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityMedium, 20],
					],
				},
			},
			{
				// phase 1 - No Minions, No Sky Raid
				priority: 304,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						HardDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_1_2_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[2, walkToPositionAbility, 10],
						[
							// just flying around
							...JUST_FLYING_AROUND_INTERIOR,
						],
						[4, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilitySpawningMedium, 20],
						[6, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityMedium, 20],
					],
				},
			},
			{
				// phase 2 - Minions Present, Sky Raid Available, Cocoon Available
				priority: 305,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						HardDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.AI_UNITS,
							criteriaType: CriteriaType.WITHIN_RANGE,
							criteriaValue: 20000,
							minimumTargetsThatSatisfy: 10,
						},
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown10000,
							minimumTargetsThatSatisfy: 1,
						},
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown1000PerStack,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[
							// just flying around phase 2
							...JUST_FLYING_AROUND_EVERYWHERE,
						],
						[
							// sky raid v2
							2,
							playAnimationAbility(AnimationTrack.SKY_RAID_IN),
							3,
							disableCollidersAbility,
							setInvisibleAbility,
							10,
							skyRaidAbilityV2Medium,
							10,
							flyToPositionIncludingEdgesAbility,
							playAnimationAbility(AnimationTrack.SKY_RAID_LANDING, 1),
							setVisibleAbility,
							4,
							enableCollidersAbility,
							shakeCameraAbility(CameraShakeIntensities.INTENSE, 0),
							cooldownEnemyCooldown10000,
							10,
							enableCollidersAbility,
							10,
						],
						[
							// crystal coccon
							1,
							playAnimationAbility(AnimationTrack.SKY_RAID_IN),
							3,
							disableCollidersAbility,
							setInvisibleAbility,
							flyToCenterAbility,
							5,
							playAnimationAbility(AnimationTrack.SKY_RAID_LANDING, 1),
							setVisibleAbility,
							4,
							enableCollidersAbility,
							shakeCameraAbility(CameraShakeIntensities.INTENSE, 0),
							10,
							enableCollidersAbility,
							playAnimationAbility(AnimationTrack.CRYSTAL_COCOON_IN),
							crystalCocoonAbilityMedium,
							shakeCameraAbility(CameraShakeIntensities.DEAR_LORD, 0),
							cooldownEnemyCooldown60000,
							playAnimationAbility(AnimationTrack.CRYSTAL_COCOON_OUT),
							100,
						],
						[4, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilityMedium, 20],
						[6, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityMedium, 20],
					],
				},
			},
			{
				// phase 2 - Minions Present, Cocoon Available, No Sky Raid
				priority: 306,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						HardDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.AI_UNITS,
							criteriaType: CriteriaType.WITHIN_RANGE,
							criteriaValue: 20000,
							minimumTargetsThatSatisfy: 10,
						},
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown1000PerStack,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[
							// just flying around phase 2
							...JUST_FLYING_AROUND_EVERYWHERE,
						],
						[
							// crystal coccon
							1,
							playAnimationAbility(AnimationTrack.SKY_RAID_IN),
							3,
							disableCollidersAbility,
							setInvisibleAbility,
							flyToCenterAbility,
							5,
							playAnimationAbility(AnimationTrack.SKY_RAID_LANDING, 1),
							setVisibleAbility,
							4,
							enableCollidersAbility,
							shakeCameraAbility(CameraShakeIntensities.INTENSE, 0),
							10,
							enableCollidersAbility,
							playAnimationAbility(AnimationTrack.CRYSTAL_COCOON_IN),
							crystalCocoonAbilityMedium,
							shakeCameraAbility(CameraShakeIntensities.DEAR_LORD, 0),
							cooldownEnemyCooldown60000,
							playAnimationAbility(AnimationTrack.CRYSTAL_COCOON_OUT),
							100,
						],
						[4, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilityMedium, 20],
						[6, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityMedium, 20],
					],
				},
			},
			{
				// phase 2 - Minions Present, Sky Raid Available, No Cocoon
				priority: 307,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						HardDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.AI_UNITS,
							criteriaType: CriteriaType.WITHIN_RANGE,
							criteriaValue: 20000,
							minimumTargetsThatSatisfy: 10,
						},
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown10000,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[
							// just flying around phase 2
							...JUST_FLYING_AROUND_EVERYWHERE,
						],
						[
							// sky raid v2
							2,
							playAnimationAbility(AnimationTrack.SKY_RAID_IN),
							3,
							disableCollidersAbility,
							setInvisibleAbility,
							10,
							skyRaidAbilityV2Medium,
							10,
							flyToPositionIncludingEdgesAbility,
							playAnimationAbility(AnimationTrack.SKY_RAID_LANDING, 1),
							setVisibleAbility,
							4,
							enableCollidersAbility,
							shakeCameraAbility(CameraShakeIntensities.INTENSE, 0),
							cooldownEnemyCooldown10000,
							10,
							enableCollidersAbility,
							10,
						],
						[4, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilityMedium, 20],
						[6, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityMedium, 20],
					],
				},
			},
			{
				// phase 2 - No Minions, Sky Raid Available, Cocoon Available
				priority: 308,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						HardDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown10000,
							minimumTargetsThatSatisfy: 1,
						},
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown1000PerStack,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[
							// just flying around phase 2
							...JUST_FLYING_AROUND_EVERYWHERE,
						],
						[
							// sky raid v2
							2,
							playAnimationAbility(AnimationTrack.SKY_RAID_IN),
							3,
							disableCollidersAbility,
							setInvisibleAbility,
							10,
							skyRaidAbilityV2Medium,
							10,
							flyToPositionIncludingEdgesAbility,
							playAnimationAbility(AnimationTrack.SKY_RAID_LANDING, 1),
							setVisibleAbility,
							4,
							enableCollidersAbility,
							shakeCameraAbility(CameraShakeIntensities.INTENSE, 0),
							cooldownEnemyCooldown10000,
							10,
							enableCollidersAbility,
							10,
						],
						[
							// crystal coccon
							1,
							playAnimationAbility(AnimationTrack.SKY_RAID_IN),
							3,
							disableCollidersAbility,
							setInvisibleAbility,
							flyToCenterAbility,
							5,
							playAnimationAbility(AnimationTrack.SKY_RAID_LANDING, 1),
							setVisibleAbility,
							4,
							enableCollidersAbility,
							shakeCameraAbility(CameraShakeIntensities.INTENSE, 0),
							10,
							enableCollidersAbility,
							playAnimationAbility(AnimationTrack.CRYSTAL_COCOON_IN),
							crystalCocoonAbilityMedium,
							shakeCameraAbility(CameraShakeIntensities.DEAR_LORD, 0),
							cooldownEnemyCooldown60000,
							playAnimationAbility(AnimationTrack.CRYSTAL_COCOON_OUT),
							100,
						],
						[6, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilitySpawningMedium, 20],
						[4, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityMedium, 20],
					],
				},
			},
			{
				// phase 2 - No Minions, Cocoon Available, No Sky Raid
				priority: 309,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						HardDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown1000PerStack,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[
							// just flying around phase 2
							...JUST_FLYING_AROUND_EVERYWHERE,
						],
						[
							// crystal coccon
							1,
							playAnimationAbility(AnimationTrack.SKY_RAID_IN),
							3,
							disableCollidersAbility,
							setInvisibleAbility,
							flyToCenterAbility,
							5,
							playAnimationAbility(AnimationTrack.SKY_RAID_LANDING, 1),
							setVisibleAbility,
							4,
							enableCollidersAbility,
							shakeCameraAbility(CameraShakeIntensities.INTENSE, 0),
							10,
							enableCollidersAbility,
							playAnimationAbility(AnimationTrack.CRYSTAL_COCOON_IN),
							crystalCocoonAbilityMedium,
							shakeCameraAbility(CameraShakeIntensities.DEAR_LORD, 0),
							cooldownEnemyCooldown60000,
							playAnimationAbility(AnimationTrack.CRYSTAL_COCOON_OUT),
							100,
						],
						[6, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilitySpawningMedium, 20],
						[4, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityMedium, 20],
					],
				},
			},
			{
				// phase 2 - No Minions, Sky Raid Available, No Cocoon
				priority: 310,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						HardDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown10000,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[
							// just flying around phase 2
							...JUST_FLYING_AROUND_EVERYWHERE,
						],
						[
							// sky raid v2
							2,
							playAnimationAbility(AnimationTrack.SKY_RAID_IN),
							3,
							disableCollidersAbility,
							setInvisibleAbility,
							10,
							skyRaidAbilityV2Medium,
							10,
							flyToPositionIncludingEdgesAbility,
							playAnimationAbility(AnimationTrack.SKY_RAID_LANDING, 1),
							setVisibleAbility,
							4,
							enableCollidersAbility,
							shakeCameraAbility(CameraShakeIntensities.INTENSE, 0),
							cooldownEnemyCooldown10000,
							10,
							enableCollidersAbility,
							10,
						],
						[6, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilitySpawningMedium, 20],
						[4, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityMedium, 20],
					],
				},
			},
			{
				// phase phase 2 - No Minions, No Sky Raid, No Cocoon
				priority: 311,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						HardDifficultyCriteria,
						alwaysTrueCriteria,
					]
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[
							// just flying around phase 2
							...JUST_FLYING_AROUND_EVERYWHERE,
						],
						[4, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilitySpawningMedium, 20],
						[6, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityMedium, 20],
					],
				},
			},


















			{
				// phase 1 - Minions Present, Sky Raid Available  - Easy Difficulty
				priority: 401,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_1_2_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
						{
							criteriaTargets: CriteriaTarget.AI_UNITS,
							criteriaType: CriteriaType.WITHIN_RANGE,
							criteriaValue: 20000,
							minimumTargetsThatSatisfy: 10,
						},
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown10000,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[3, walkToPositionAbility, 10],
						[
							// just flying around
							...JUST_FLYING_AROUND_INTERIOR,
						],
						[
							// sky raid v 1
							1,
							playAnimationAbility(AnimationTrack.SKY_RAID_IN),
							3,
							disableCollidersAbility,
							setInvisibleAbility,
							10,
							skyRaidAbilityEasy,
							10,
							flyToPositionAbility,
							playAnimationAbility(AnimationTrack.SKY_RAID_LANDING, 1),
							setVisibleAbility,
							4,
							enableCollidersAbility,
							shakeCameraAbility(CameraShakeIntensities.INTENSE, 0),
							cooldownEnemyCooldown10000,
							10,
							enableCollidersAbility,
							EASY_TIME_MULTI(20),
						],
						[3, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilityEasy, EASY_TIME_MULTI(20)],
						[5, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityEasy, EASY_TIME_MULTI(20)],
					],
				},
			},
			{
				// phase 1 - Minions Present, No Sky Raid. - Easy Difficulty
				priority: 402,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_1_2_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
						{
							criteriaTargets: CriteriaTarget.AI_UNITS,
							criteriaType: CriteriaType.WITHIN_RANGE,
							criteriaValue: 20000,
							minimumTargetsThatSatisfy: 10,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[3, walkToPositionAbility, 10],
						[
							// just flying around
							...JUST_FLYING_AROUND_INTERIOR,
						],
						[3, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilityEasy, EASY_TIME_MULTI(20)],
						[5, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityEasy, EASY_TIME_MULTI(20)],
					],
				},
			},
			{
				// phase 1 - No Minions, Sky Raid Available - Easy Difficulty
				priority: 403,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_1_2_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown10000,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[3, walkToPositionAbility, 10],
						[
							// just flying around
							...JUST_FLYING_AROUND_INTERIOR,
						],
						[
							// sky raid v 1
							3,
							playAnimationAbility(AnimationTrack.SKY_RAID_IN),
							3,
							disableCollidersAbility,
							setInvisibleAbility,
							10,
							skyRaidAbilityEasy,
							10,
							flyToPositionAbility,
							playAnimationAbility(AnimationTrack.SKY_RAID_LANDING, 1),
							setVisibleAbility,
							4,
							enableCollidersAbility,
							shakeCameraAbility(CameraShakeIntensities.INTENSE, 0),
							cooldownEnemyCooldown10000,
							10,
							enableCollidersAbility,
							EASY_TIME_MULTI(20),
						],
						[5, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilitySpawningEasy, EASY_TIME_MULTI(20)],
						[3, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityEasy, EASY_TIME_MULTI(20)],
					],
				},
			},
			{
				// phase 1 - No Minions, No Sky Raid - Easy Difficulty
				priority: 404,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_1_2_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[3, walkToPositionAbility, 10],
						[
							// just flying around
							...JUST_FLYING_AROUND_INTERIOR,
						],
						[5, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilitySpawningEasy, EASY_TIME_MULTI(20)],
						[3, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityEasy, EASY_TIME_MULTI(20)],
					],
				},
			},
			{
				// phase 2 - Minions Present, Sky Raid Available, Cocoon Available - Easy Difficulty
				priority: 405,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						{
							criteriaTargets: CriteriaTarget.AI_UNITS,
							criteriaType: CriteriaType.WITHIN_RANGE,
							criteriaValue: 20000,
							minimumTargetsThatSatisfy: 10,
						},
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown10000,
							minimumTargetsThatSatisfy: 1,
						},
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown1000PerStack,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[
							// just flying around phase 2
							...JUST_FLYING_AROUND_EVERYWHERE,
						],
						[
							// sky raid v2
							3,
							playAnimationAbility(AnimationTrack.SKY_RAID_IN),
							3,
							disableCollidersAbility,
							setInvisibleAbility,
							10,
							skyRaidAbilityV2Easy,
							10,
							flyToPositionIncludingEdgesAbility,
							playAnimationAbility(AnimationTrack.SKY_RAID_LANDING, 1),
							setVisibleAbility,
							4,
							enableCollidersAbility,
							shakeCameraAbility(CameraShakeIntensities.INTENSE, 0),
							cooldownEnemyCooldown10000,
							10,
							enableCollidersAbility,
							EASY_TIME_MULTI(20),
						],
						[
							// crystal coccon
							200,
							playAnimationAbility(AnimationTrack.SKY_RAID_IN),
							3,
							disableCollidersAbility,
							setInvisibleAbility,
							flyToCenterAbility,
							5,
							playAnimationAbility(AnimationTrack.SKY_RAID_LANDING, 1),
							setVisibleAbility,
							4,
							enableCollidersAbility,
							shakeCameraAbility(CameraShakeIntensities.INTENSE, 0),
							10,
							enableCollidersAbility,
							playAnimationAbility(AnimationTrack.CRYSTAL_COCOON_IN),
							crystalCocoonAbilityEasy,
							shakeCameraAbility(CameraShakeIntensities.DEAR_LORD, 0),
							cooldownEnemyCooldown60000,
							playAnimationAbility(AnimationTrack.CRYSTAL_COCOON_OUT),
							100,
						],
						[5, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilityEasy, EASY_TIME_MULTI(20)],
						[5, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityEasy, EASY_TIME_MULTI(20)],
					],
				},
			},
			{
				// phase 2 - Minions Present, Cocoon Available, No Sky Raid - Easy Difficulty
				priority: 406,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						{
							criteriaTargets: CriteriaTarget.AI_UNITS,
							criteriaType: CriteriaType.WITHIN_RANGE,
							criteriaValue: 20000,
							minimumTargetsThatSatisfy: 10,
						},
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown1000PerStack,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[
							// just flying around phase 2
							...JUST_FLYING_AROUND_EVERYWHERE,
						],
						[
							// crystal coccon
							200,
							playAnimationAbility(AnimationTrack.SKY_RAID_IN),
							3,
							disableCollidersAbility,
							setInvisibleAbility,
							flyToCenterAbility,
							5,
							playAnimationAbility(AnimationTrack.SKY_RAID_LANDING, 1),
							setVisibleAbility,
							4,
							enableCollidersAbility,
							shakeCameraAbility(CameraShakeIntensities.INTENSE, 0),
							10,
							enableCollidersAbility,
							playAnimationAbility(AnimationTrack.CRYSTAL_COCOON_IN),
							crystalCocoonAbilityEasy,
							shakeCameraAbility(CameraShakeIntensities.DEAR_LORD, 0),
							cooldownEnemyCooldown60000,
							playAnimationAbility(AnimationTrack.CRYSTAL_COCOON_OUT),
							100,
						],
						[3, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilityEasy, EASY_TIME_MULTI(20)],
						[5, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityEasy, EASY_TIME_MULTI(20)],
					],
				},
			},
			{
				// phase 2 - Minions Present, Sky Raid Available, No Cocoon - Easy Difficulty
				priority: 407,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						{
							criteriaTargets: CriteriaTarget.AI_UNITS,
							criteriaType: CriteriaType.WITHIN_RANGE,
							criteriaValue: 20000,
							minimumTargetsThatSatisfy: 10,
						},
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown10000,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[
							// just flying around phase 2
							...JUST_FLYING_AROUND_EVERYWHERE,
						],
						[
							// sky raid v2
							2,
							playAnimationAbility(AnimationTrack.SKY_RAID_IN),
							3,
							disableCollidersAbility,
							setInvisibleAbility,
							10,
							skyRaidAbilityV2Easy,
							10,
							flyToPositionIncludingEdgesAbility,
							playAnimationAbility(AnimationTrack.SKY_RAID_LANDING, 1),
							setVisibleAbility,
							4,
							enableCollidersAbility,
							shakeCameraAbility(CameraShakeIntensities.INTENSE, 0),
							cooldownEnemyCooldown10000,
							10,
							enableCollidersAbility,
							EASY_TIME_MULTI(20),
						],
						[3, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilityEasy, EASY_TIME_MULTI(20)],
						[5, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityEasy, EASY_TIME_MULTI(20)],
					],
				},
			},
			{
				// phase 2 - No Minions, Sky Raid Available, Cocoon Available - Easy Difficulty
				priority: 408,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown10000,
							minimumTargetsThatSatisfy: 1,
						},
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown1000PerStack,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[
							// just flying around phase 2
							...JUST_FLYING_AROUND_EVERYWHERE,
						],
						[
							// sky raid v2
							3,
							playAnimationAbility(AnimationTrack.SKY_RAID_IN),
							3,
							disableCollidersAbility,
							setInvisibleAbility,
							10,
							skyRaidAbilityV2Easy,
							10,
							flyToPositionIncludingEdgesAbility,
							playAnimationAbility(AnimationTrack.SKY_RAID_LANDING, 1),
							setVisibleAbility,
							4,
							enableCollidersAbility,
							shakeCameraAbility(CameraShakeIntensities.INTENSE, 0),
							cooldownEnemyCooldown10000,
							10,
							enableCollidersAbility,
							EASY_TIME_MULTI(20),
						],
						[
							// crystal coccon
							2,
							playAnimationAbility(AnimationTrack.SKY_RAID_IN),
							3,
							disableCollidersAbility,
							setInvisibleAbility,
							flyToCenterAbility,
							5,
							playAnimationAbility(AnimationTrack.SKY_RAID_LANDING, 1),
							setVisibleAbility,
							4,
							enableCollidersAbility,
							shakeCameraAbility(CameraShakeIntensities.INTENSE, 0),
							10,
							enableCollidersAbility,
							playAnimationAbility(AnimationTrack.CRYSTAL_COCOON_IN),
							crystalCocoonAbilityEasy,
							shakeCameraAbility(CameraShakeIntensities.DEAR_LORD, 0),
							cooldownEnemyCooldown60000,
							playAnimationAbility(AnimationTrack.CRYSTAL_COCOON_OUT),
							100,
						],
						[6, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilitySpawningEasy, EASY_TIME_MULTI(20)],
						[3, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityEasy, EASY_TIME_MULTI(20)],
					],
				},
			},
			{
				// phase 2 - No Minions, Cocoon Available, No Sky Raid - Easy Difficulty
				priority: 409,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown1000PerStack,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[
							// just flying around phase 2
							...JUST_FLYING_AROUND_EVERYWHERE,
						],
						[
							// crystal coccon
							2,
							playAnimationAbility(AnimationTrack.SKY_RAID_IN),
							3,
							disableCollidersAbility,
							setInvisibleAbility,
							flyToCenterAbility,
							5,
							playAnimationAbility(AnimationTrack.SKY_RAID_LANDING, 1),
							setVisibleAbility,
							4,
							enableCollidersAbility,
							shakeCameraAbility(CameraShakeIntensities.INTENSE, 0),
							10,
							enableCollidersAbility,
							playAnimationAbility(AnimationTrack.CRYSTAL_COCOON_IN),
							crystalCocoonAbilityEasy,
							shakeCameraAbility(CameraShakeIntensities.DEAR_LORD, 0),
							cooldownEnemyCooldown60000,
							playAnimationAbility(AnimationTrack.CRYSTAL_COCOON_OUT),
							100,
						],
						[6, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilitySpawningEasy, EASY_TIME_MULTI(20)],
						[3, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityEasy, EASY_TIME_MULTI(20)],
					],
				},
			},
			{
				// phase 2 - No Minions, Sky Raid Available, No Cocoon - Easy Difficulty
				priority: 410,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown10000,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[
							// just flying around phase 2
							...JUST_FLYING_AROUND_EVERYWHERE,
						],
						[
							// sky raid v2
							3,
							playAnimationAbility(AnimationTrack.SKY_RAID_IN),
							3,
							disableCollidersAbility,
							setInvisibleAbility,
							10,
							skyRaidAbilityV2Easy,
							10,
							flyToPositionIncludingEdgesAbility,
							playAnimationAbility(AnimationTrack.SKY_RAID_LANDING, 1),
							setVisibleAbility,
							4,
							enableCollidersAbility,
							shakeCameraAbility(CameraShakeIntensities.INTENSE, 0),
							cooldownEnemyCooldown10000,
							10,
							enableCollidersAbility,
							EASY_TIME_MULTI(20),
						],
						[6, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilitySpawningEasy, EASY_TIME_MULTI(20)],
						[3, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityEasy, EASY_TIME_MULTI(20)],
					],
				},
			},
			{
				// phase phase 2 - No Minions, No Sky Raid, No Cocoon - Easy Difficulty
				priority: 411,
				actionCriteria: alwaysTrueCriteria,
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[
							// just flying around phase 2
							...JUST_FLYING_AROUND_EVERYWHERE,
						],
						[3, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.CRYSTAL_BREATH, 5), crystalBreathAbilitySpawningEasy, EASY_TIME_MULTI(20)],
						[6, enableCollidersAbility, setVisibleAbility, playAnimationAbility(AnimationTrack.WING_GUST, 15), wingGustAbilityEasy, EASY_TIME_MULTI(20)],
					],
				},
			},
		],
	},

	name: ENEMY_NAMES.PRISM_BOSS,
	type: EnemyType.BOSS,
	appearance: {
		asset: SpineDataName.PRISM_BOSS,
		skin: EnemyBipedSkinType.DEFAULT,
		defaultMix: 0.3,
		mixSettings: [],
		zOffset: 100,
	},
	general: {
		enemyLevel: 1,
		experienceWhenKilled: 20,
	},
	baseAttributes: {
		awarenessCollisionRadiusInGrids: 10,
		colliderIsKinematic: true,
		...EnemyDefaults.baseAttributes,
		colliders: [
			{
				type: ColliderType.Circle,
				radius: 150,
				position: [0, -100],
				traits: ColliderTraits.BlockMovementAndProjectile,
			},
		],
		attackOffset: new Vector(0, 0),
		lootDropOffset: new Vector(0, 0),
		damageConfig: bossEnemyDamageConfig,
		movementSpeed: 100,
		visibilityRadius: 5000,
		decelerationRate: 0,
		attackRate: 750,
		turningRatePerSecondInDegrees: 200,
		physicalDamage: 20,
		fireDamage: 0,
		iceDamage: 0,
		lightningDamage: 0,
		poisonDamage: 0,
		ignitePotency: 0.4,
		critChance: 0.05,
		critDamage: 1.5,
		cooldownSpeed: 300,
		debuffPotency: 1.0,
		maxHealth: ENEMY_DEFAULT_HEALTH * BOSS_HEALTH_MULTIPLIER, // Boss health
		maxShields: 0,
		shieldRegenDelay: 0,
		shieldRegenRate: 0,
		healthRegen: 0,
		healthRegenDelay: 0,
		maxEnergy: 100,
		energyRegen: 100,
		primaryFireEnergyCost: 0,
		defense: 0,
		fireResist: 0,
		poisonResist: 0,
		iceResist: 0,
		lightningResist: 0,
		buffDuration: 0,
		skillUsageSpeed: 0,

		lootDropProbability: 100,
		lootDropQuantity: BOSS_LOOT_DROP_QUANTITY + 3,
		lootDropRarity: BOSS_LOOT_DROP_RARITY + 3,
	},
	soundEffects: {
		attack: 'Projectile_Fireball',
		impact: 'SFX_Impact_Enemy_Flesh',
	},
	states: {
		idling: {
			behaviour: IdleBehaviours.STATIONARY,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			transitionToFighting: {
				trigger: FightingTriggers.AGGRESSIVE_PROXIMITY,
				radius: 2500,
			},
			aggroOnHit: true,
		},
		fighting: {
			behaviour: FightingBehaviours.CHASE_AND_ATTACK,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			attackType: AttackTypes.PROJECTILE,
			engagementMaxDistance: 1600,
			engagementMinDistance: 0,
			movementMaxDistance: 1600,
			movementMinDistance: 0,
			attackOptions: [],
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 0.5,
			transitionToLeashing: {
				trigger: LeashTriggers.NONE,
			},
		},
		leashing: {
			behaviour: LeashingBehaviours.MOVE,
			speedMultiplier: 4,
			decelerateMultiplier: 0,
		},
		fleeing: {},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 4,
		},
	},
}

export default boss
