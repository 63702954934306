import nengi from 'nengi'
import { timeInMilliseconds } from '../../utils/primitive-types'

class SkillCooldownUpdatedMessage {
	static protocol = {
		entityId: { type: nengi.Number },
		skillSlot: { type: nengi.Int8 },
		cooldownStartTime: { type: nengi.Number },
		cooldownDuration: { type: nengi.Number },
	}

	entityId: number
	skillSlot: number
	cooldownStartTime: timeInMilliseconds
	cooldownDuration: timeInMilliseconds

	constructor(params: SkillCooldownUpdatedMessage) {
		this.entityId = params.entityId
		this.skillSlot = params.skillSlot
		this.cooldownStartTime = params.cooldownStartTime
		this.cooldownDuration = params.cooldownDuration
	}
}

export default SkillCooldownUpdatedMessage
