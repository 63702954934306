import { EnemyAI, FightingTriggers, IdleBehaviours, FightingBehaviours, LeashTriggers, LeashingBehaviours, AttackTypes, DeadBehaviours, ShotLeadPrecision, EnemyType, EnemyTargetingStyles } from '../../shared/ai-types'
import { ModType, ProjectileTargetType } from '../../../projectiles/shared/projectile-types'
import { SpineDataName, EnemyBipedSkinType } from '../../../models-animations/shared/spine-config'
import { degToRad } from '../../../utils/math'
import { EnemyDefaults } from './enemy-defaults'
import { Colors } from '../../../utils/colors'
import { ParticleEffectType } from '../../../engine/shared/game-data/particle-config'
import { Vector } from 'sat'
import { ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { basicEnemyDamageConfig, ENEMY_DEFAULT_HEALTH } from '../../../engine/shared/game-data/enemy-formulas'
import { Ability, AbilitySelectionStyles, AbilityTargets, AbilityTargetSelectionStyle, AbilityType, ActionCriteria, CriteriaSatisfactionQuantity, CriteriaTarget, CriteriaType } from '../action-types'
import { attackRatedAbilityList } from '../action-criteria/ability-helpers'
import { AnimationTrack } from '../../../models-animations/shared/animation-track'
import { alwaysTrueCriteria } from './abilities/common-abilities'
import { ENEMY_NAMES } from './enemy-names'
import { worldDifficultyBrutalCriteria, worldDifficultyBrutalCriterion,  } from '../action-criteria/action-criteria-helpers'


const spineBurstAoeAbility: Ability = {
	debugName: 'spineBurstAoeAbility',
	abilityType: AbilityType.SPAWN_GROUND_HAZARD,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	durationInGameTicks: 40,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		colliderRadius: 100,
		speed: 0,
		lifespanInSeconds: 0.2, // how long the projectile lasts on the ground
		basePhysicalDamage: 110,
		damageMultiplier: 1.0,
		pierceCount: 999,
		color: Colors.green,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_NONE,
	},
	groundHazardRules: {
		spineAsset: 'shambling-mound-aoe',
		pfx: 'aoe-explosion-mound',
		pfxScale: 1.1,
		pfxSecondsOffset: 1.3, // how long before the PFX is created?
		projectileSecondsOffset: 1.4, // how long before it creates the projectile
		quantity: 1,
		limit: 2,
	},
}

export const spineBurstAoeAbilityBrutal: Ability = {
	debugName: 'spineBurstAoeAbilityBrutal',
	abilityType: AbilityType.SPAWN_GROUND_HAZARD_MULTITARGET,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
	durationInGameTicks: 40,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		colliderRadius: 100,
		speed: 0,
		lifespanInSeconds: 0.2, // how long the projectile lasts on the ground
		basePhysicalDamage: 110,
		color: Colors.green,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
		pierceCount: 999,
	},
	groundHazardMultitargetRules: {
		spineAsset: 'shambling-mound-aoe',
		pfx: 'aoe-explosion-mound',
		pfxScale: 1.1,
		pfxSecondsOffset: 1.3, // how long before the PFX is created?
		projectileSecondsOffset: 1.4, // how long before it creates the projectile
		baseQuantity: 3,
		perTargetQuantity: 0,
		totalTime: 2,
		accuratePercent: 0.8,
		maxStray: 150,
	},
}




const thornyNovaAbility: Ability = {
	debugName: 'thornyNovaAbility',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.CLOSEST_PLAYER,
	validTargetSelection: AbilityTargetSelectionStyle.CLOSEST,
	projectileConfig: {
		// big damage AOE at short range, thorny nova
		...EnemyDefaults.projectileConfig,
		projectileCount: 16,
		spreadAngle: degToRad(337), //TODO2: convert to degToRad(360) replacement
		speed: 600,
		colliderRadius: 20,
		pierceCount: 999,
		lifespanInSeconds: 0.65,
		maxRange: 250,
		modifiers: [{ modType: ModType.STRAIGHT }],
		basePoisonDamage: 60,
		burstCount: 0,
		color: Colors.yellow,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_SHAMBLINGMOUND,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_POISON_TRAIL,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_POISON_SHOOT,
	},
}

const playerIsInMeleeRangeCriteria: ActionCriteria = {
	satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
	criterias: [
		{
			criteriaTargets: CriteriaTarget.PLAYERS,
			criteriaType: CriteriaType.WITHIN_RANGE,
			criteriaValue: 400,
			minimumTargetsThatSatisfy: 1,
		},
	],
}

const playerIsInMeleeRangeCriteriaBrutal: ActionCriteria = {
	satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
	criterias: [
		worldDifficultyBrutalCriterion,
		{
			criteriaTargets: CriteriaTarget.PLAYERS,
			criteriaType: CriteriaType.WITHIN_RANGE,
			criteriaValue: 400,
			minimumTargetsThatSatisfy: 1,
		},
	],
}

const animationTimes = {
	[AnimationTrack.SHOOT]: 0.5,
}

const shamblingMound: EnemyAI = {
	name: ENEMY_NAMES.SHAMBLING_MOUND,
	type: EnemyType.BASIC,
	appearance: {
		asset: SpineDataName.SHAMBLING_MOUND,
		skin: EnemyBipedSkinType.DEFAULT,
		mixSettings: [],
	},
	general: {
		enemyLevel: 1,
		experienceWhenKilled: 3,
	},
	baseAttributes: {
		...EnemyDefaults.baseAttributes,
		colliders: [
			{
				type: ColliderType.Circle,
				radius: 55,
				position: [-25, -29],
				traits: ColliderTraits.BlockProjectile,
			},
			{
				type: ColliderType.Circle,
				radius: 55,
				position: [15, -29],
				traits: ColliderTraits.BlockProjectile,
			},
			{
				type: ColliderType.Ellipse,
				rX: 70,
				rY: 30,
				position: [0, -10],
				traits: ColliderTraits.BlockMovement,
			},
		],
		movementSpeed: 60,
		visibilityRadius: 1,
		decelerationRate: 2.5,
		turningRatePerSecondInDegrees: 200,
		attackOffset: new Vector(0, 0),
		lootDropOffset: new Vector(0, 0),
		damageConfig: basicEnemyDamageConfig,

		physicalDamage: 0,
		fireDamage: 0,
		iceDamage: 0,
		lightningDamage: 0,
		poisonDamage: 0,
		attackRate: 500,
		critChance: 0.05,
		critDamage: 1.5,

		maxHealth: ENEMY_DEFAULT_HEALTH * 2,
		defense: 0,
		fireResist: 0,
		poisonResist: 0,
		iceResist: 0,
		lightningResist: 0,

		lootDropProbability: 1,
		lootDropQuantity: 1.25,
		lootDropRarity: 1,
	},
	soundEffects: {
		attack: 'Projectile_Blip',
		impact: 'SFX_Impact_Enemy_Plant',
	},
	states: {
		idling: {
			behaviour: IdleBehaviours.STATIONARY,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			transitionToFighting: {
				trigger: FightingTriggers.AGGRESSIVE_PROXIMITY,
				radius: 900,
			},
			aggroOnHit: true,
		},
		fighting: {
			behaviour: FightingBehaviours.CHASE_AND_ATTACK,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			attackType: AttackTypes.PROJECTILE,
			engagementMaxDistance: 900,
			engagementMinDistance: 0,
			movementMaxDistance: 900,
			movementMinDistance: 0,
			brain: {
				actions: [
					{
						name: 'Brutal-SpineBurst&Thorny',
						priority: 1.1,
						actionCriteria: playerIsInMeleeRangeCriteriaBrutal,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
							abilityOptions: [
								attackRatedAbilityList(2, animationTimes, AnimationTrack.SHOOT, spineBurstAoeAbilityBrutal, 40), 
								attackRatedAbilityList(5, animationTimes, AnimationTrack.SHOOT, thornyNovaAbility, 30)],
						},
					},
					{
						name: 'SpineBurst&Thorny',
						priority: 1,
						actionCriteria: playerIsInMeleeRangeCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
							abilityOptions: [
								attackRatedAbilityList(2, animationTimes, AnimationTrack.SHOOT, spineBurstAoeAbility, 40), 
								attackRatedAbilityList(5, animationTimes, AnimationTrack.SHOOT, thornyNovaAbility, 30)],
						},
					},
					{
						name: 'Brutal-SpineBurst',
						priority: 2,
						actionCriteria: worldDifficultyBrutalCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
							abilityOptions: [attackRatedAbilityList(4, animationTimes, AnimationTrack.SHOOT, spineBurstAoeAbilityBrutal, 40)],
						},
					},
					{
						name: 'SpineBurst',
						priority: 3,
						actionCriteria: alwaysTrueCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
							abilityOptions: [attackRatedAbilityList(4, animationTimes, AnimationTrack.SHOOT, spineBurstAoeAbility, 40)],
						},
					},
				],
			},
			//TODO2: add "attack while moving" behavior once available
			attackOptions: [],
			//TODO2: add alternating buffs, where we get more movement speed for a short duration, then less movement speed for a short duration, to better convey "shamble" behavior
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 0.5,
			transitionToLeashing: {
				trigger: LeashTriggers.RANGE_TO_TARGET_EXCEEDED,
				range: 1250
			},
			targetType: ProjectileTargetType.PLAYER
		},
		leashing: {
			behaviour: LeashingBehaviours.MOVE,
			speedMultiplier: 4,
			decelerateMultiplier: 0,
		},
		fleeing: {},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 1,
		},
	},
}

export default shamblingMound
