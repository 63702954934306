import nengi from 'nengi'
import { uuid } from '../../utils/primitive-types'

class SendItemToPitAndActivateCommand {
	static protocol = {
		itemsJson: nengi.String,
		container: nengi.String,
	}

	itemsJson: uuid
	container: string

	constructor(itemIds: string, fromContainer: string) {
		this.itemsJson = itemIds
		this.container = fromContainer
	}
}

export default SendItemToPitAndActivateCommand
