import { Container, Sprite } from 'pixi.js'
import { AssetManager } from '../../asset-manager/client/asset-manager'
import playAnimation from '../../models-animations/client/play-animation'
import { RiggedSpineModel } from '../../models-animations/client/spine-model'
import { AnimationTrack } from '../../models-animations/shared/animation-track'
import { SpineDataName } from '../../models-animations/shared/spine-config'
import { ObjectPool, PoolableObject } from '../../third-party/object-pool'
import { EmoteAssets, EmoteType } from '../shared/emote-enums'

const EMOTE_TO_ANIMATION_TRACK = {
	[EmoteType.None]: AnimationTrack.EMOTE_NONE,
	[EmoteType.Typing]: AnimationTrack.EMOTE_TYPING,
	[EmoteType.Angry]: AnimationTrack.EMOTE_LOOP,
	[EmoteType.CardinalCry]: AnimationTrack.EMOTE_LOOP,
	[EmoteType.CorgiThink]: AnimationTrack.EMOTE_LOOP,
	[EmoteType.Cry]: AnimationTrack.EMOTE_LOOP,
	[EmoteType.Excited]: AnimationTrack.EMOTE_LOOP,
	[EmoteType.Happy]: AnimationTrack.EMOTE_LOOP,
	[EmoteType.Love]: AnimationTrack.EMOTE_LOOP,
	[EmoteType.Neutral]: AnimationTrack.EMOTE_LOOP,
	[EmoteType.PalmExcited]: AnimationTrack.EMOTE_LOOP,
	[EmoteType.RaccoonLove]: AnimationTrack.EMOTE_LOOP,
	[EmoteType.RottweilerPopcorn]: AnimationTrack.EMOTE_LOOP,
	[EmoteType.Sad]: AnimationTrack.EMOTE_LOOP,
	[EmoteType.Scared]: AnimationTrack.EMOTE_LOOP,
	[EmoteType.Surprised]: AnimationTrack.EMOTE_LOOP,
	[EmoteType.Thumbsup]: AnimationTrack.EMOTE_LOOP,
	[EmoteType.Easter_DissapointBun]: AnimationTrack.EMOTE_ANIMATION,
	[EmoteType.Easter_LaughBun]: AnimationTrack.EMOTE_ANIMATION,
	[EmoteType.Easter_LoveBun]: AnimationTrack.EMOTE_ANIMATION,
	[EmoteType.Easter_MuscleEgg]: AnimationTrack.EMOTE_ANIMATION,
	[EmoteType.Easter_SusEgg]: AnimationTrack.EMOTE_ANIMATION,
	[EmoteType.Easter_WinkChick]: AnimationTrack.EMOTE_ANIMATION,
	[EmoteType.AyyRanger]: AnimationTrack.EMOTE_ANIMATION,
	[EmoteType.ChaosCrab]: AnimationTrack.EMOTE_ANIMATION,
	[EmoteType.IGetThatBubba]: AnimationTrack.EMOTE_ANIMATION,
	[EmoteType.MadToTheBone]: AnimationTrack.EMOTE_ANIMATION,
	[EmoteType.NervousRanger]: AnimationTrack.EMOTE_ANIMATION,
	[EmoteType.RageKnight]: AnimationTrack.EMOTE_ANIMATION,
	[EmoteType.SoSugoiSorceress]: AnimationTrack.EMOTE_ANIMATION,
	[EmoteType.SquintElf]: AnimationTrack.EMOTE_ANIMATION,
	[EmoteType.SusWizard]: AnimationTrack.EMOTE_ANIMATION,
	[EmoteType.ThisIsFineDwarf]: AnimationTrack.EMOTE_ANIMATION,
}

const SCALE_IN_TIME = 0.3
const MAX_EMOTE_SCALE = 0.5

export class ClientEmote implements PoolableObject {
	static pool: ObjectPool

	parentContainer: Container

	private _emoteContainer: Container
	private _riggedEmote: RiggedSpineModel
	private _emoteBubble: Sprite

	private _isScalingIn: Boolean = false
	private _scaleInTime: number = 0
	private _useBubble: boolean = false

	constructor(defaultValues?: any, overrideValues?: any) {
		this._emoteContainer = new Container()
		this._emoteContainer.name = 'player-emote'

		const bubbleTexture = AssetManager.getInstance().getAssetByName('emote-container').texture
		this._emoteBubble = new Sprite(bubbleTexture)

		this._emoteBubble.scale.x = 0
		this._emoteBubble.scale.y = 0

		this._emoteContainer.addChild(this._emoteBubble)

		this._useBubble = false

		const asset = AssetManager.getInstance().getAssetByName(SpineDataName.PLAYER_EMOTE)
		this._riggedEmote = RiggedSpineModel.allocFromPool(SpineDataName.PLAYER_EMOTE, 'default')

		this._riggedEmote.scale.x = 0
		this._riggedEmote.scale.y = 0

		this._emoteContainer.addChild(this._riggedEmote)

		this._emoteContainer.visible = false
	}

	setDefaultValues(defaultValues: any, overrideValues?: any) {
		this._riggedEmote.skeleton.setToSetupPose()
		this._emoteContainer.visible = false

		this._useBubble = false

		this._emoteBubble.scale.x = 0
		this._emoteBubble.scale.y = 0
		this._riggedEmote.scale.x = 0
		this._riggedEmote.scale.y = 0
		this._scaleInTime = 0
	}

	cleanup() {}

	setEmote(emote: EmoteType) {
		this._emoteContainer.removeChild(this._riggedEmote)
		this._riggedEmote = null

		if (emote === EmoteType.Typing) {
			this._riggedEmote = RiggedSpineModel.allocFromPool(SpineDataName.PLAYER_EMOTE, 'default')
			this._riggedEmote.y = 0
			this._useBubble = false;
		} else {
			const assetName = EmoteAssets.get(emote)
			this._riggedEmote = RiggedSpineModel.allocFromPool(assetName, 'default')
			this._riggedEmote.y = -50
			this._useBubble = true
		}

		this._emoteBubble.scale.x = 0
		this._emoteBubble.scale.y = 0
		this._riggedEmote.scale.x = 0
		this._riggedEmote.scale.y = 0

		this._emoteContainer.addChild(this._riggedEmote)

		playAnimation(this._riggedEmote, EMOTE_TO_ANIMATION_TRACK[emote])
		this._isScalingIn = true
	}

	getContainer(): Container {
		return this._emoteContainer
	}

	setParentContainer(container: Container) {
		this.parentContainer = container
		container.addChild(this._emoteContainer)
	}

	update(delta: number) {
		this._riggedEmote.update(delta)

		if(this._isScalingIn) {
			this._scaleInTime += delta
			const scale = Math.lerp(0, MAX_EMOTE_SCALE, Math.clamp((this._scaleInTime/SCALE_IN_TIME), 0, 1))
			this._riggedEmote.scale.x = scale
			this._riggedEmote.scale.y = scale

			if (this._useBubble) {
				const bubbleScale = scale * (1.0 / MAX_EMOTE_SCALE)
				this._emoteBubble.scale.x = bubbleScale
				this._emoteBubble.scale.y = bubbleScale
				
				this._emoteBubble.x = -(this._emoteBubble.width / 2)
				// The 20 is a magic number, I just eyeballed it
				this._emoteBubble.y = 20 * (1 - bubbleScale)
			}

			if(this._scaleInTime > SCALE_IN_TIME) {
				this._scaleInTime = 0
				this._isScalingIn = false
			}
		}
	}

	returnToPool() {
		this.parentContainer.removeChild(this._emoteContainer)
		this.parentContainer.removeChild(this._emoteBubble)
		ClientEmote.pool.free(this)
	}
}