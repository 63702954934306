import { AnyColliderConfig, ColliderTraits, ColliderType } from '../../collision/shared/colliders'
import { degToRad } from '../../utils/math'
import { makeBlockPropConfig, PropConfigs } from './prop-configs'

const forestLargeRareColliders: AnyColliderConfig[] = [
	{
		type: ColliderType.Polygon,
		position: [0, 0],
		vertices: [
			239.01,
			-447.92,
			359.54,
			-254.43,
			364.3,
			-21.28,
			177.15,
			65.95,
			72.48,
			261.02,
			45.51,
			448.17,
			-44.89,
			497.34,
			-171.77,
			465.62,
			-344.64,
			245.16,
			-355.74,
			10.44,
			-306.58,
			-19.7,
			-311.34,
			-146.58,
			-219.35,
			-230.64,
			-219.35,
			-282.97,
			-125.77,
			-325.8,
			-32.2,
			-332.14,
			78.82,
			-381.31,
			132.5,
			-455.76,
		],
		traits: ColliderTraits.BlockAll,
	},
]

export const forestPropConfigs: PropConfigs = {
	'outpost-base': {
		disableFileOrdering: true,
		colliders: [
			{ type: ColliderType.Box, position: [-935, -635], width: 70, height: 1135, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [390, -635], width: 70, height: 1135, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-935, -635], width: 1395, height: 100, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-935, 400], width: 225, height: 100, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [50, 400], width: 410, height: 100, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-184, -353], width: 500, height: 200, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-184, -203], width: 430, height: 100, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-745, 141], width: 75, height: 75, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-736, -158], width: 160, height: 75, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-173, -112], width: 43, height: 100, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [200, -110], width: 44, height: 100, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-883, 611], width: 40, height: 75, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-1082, 623], width: 40, height: 75, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [72, 494], width: 475, height: 108, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [575, 385], width: 41, height: 100, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [608, -359], width: 47, height: 100, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [717, -551], width: 47, height: 100, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [580, -686], width: 47, height: 100, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-935, -765], width: 47, height: 100, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-1099, -532], width: 31, height: 100, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [488, 493], width: 184, height: 314, traits: ColliderTraits.BlockMovementAndItem },
		],
		rigged: false,
		visibilityRadius: 1400,
	},
	'health-sign': {
		zOffset: 40,
		dontLoadAsset: true,
	},
	'outpost-campfire': {
		rigged: true,
		visibilityRadius: 1400,
		zOffset: -50,
	},
	'outpost-smoke': {
		rigged: true,
		visibilityRadius: 1400,
		zOffset: Number.MAX_SAFE_INTEGER,
	},
	'outpost-lanterns': {
		rigged: true,
		visibilityRadius: 1400,
		zOffset: 575,
	},
	'outpost-bubba': {
		rigged: true,
		visibilityRadius: 1400,
		zOffset: -1000,
	},
	'outpost-bat': {
		rigged: true,
		visibilityRadius: 1400,
		zOffset: 100,
	},
	'poi-01': {
		disableFileOrdering: true,
		colliders: [
			{ type: ColliderType.Ellipse, position: [-187, 314], rX: 164, rY: 141, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [256, -301], radius: 163, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [-452, 82], radius: 269, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [-276, -20], radius: 135, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [427, 643], rX: 127, rY: 185, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [628, 458], rX: 127, rY: 185, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [795, 259], rX: 123, rY: 132, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [433, -203], radius: 88, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [572, 34], radius: 43, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [817, 5], radius: 37, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [50, -449], radius: 32, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [-122, -477], radius: 37, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [-284, -491], radius: 37, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [-649, -423], radius: 37, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [71, -270], radius: 64, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [-805, 45], radius: 37, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [313, -145], radius: 64, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [-712, 523], radius: 36, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [-130, 15], radius: 75, traits: ColliderTraits.BlockAll },
		],
		rigged: false,
		visibilityRadius: 1400,
	},
	sand_rare_01: {
		rigged: true,
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},
	'poi-01-cocoon-01': {
		disableFileOrdering: true,
		zOffset: Number.MAX_SAFE_INTEGER,
		rigged: false,
		visibilityRadius: 1400,
	},
	'poi-01-cocoon-02': {
		disableFileOrdering: true,
		zOffset: Number.MAX_SAFE_INTEGER,
		rigged: false,
		visibilityRadius: 1400,
	},
	'poi-01-cocoon-03': {
		disableFileOrdering: true,
		zOffset: Number.MAX_SAFE_INTEGER,
		rigged: false,
		visibilityRadius: 1000,
	},
	'poi-eyes-01': {
		zOffset: -250,
		rigged: false,
		visibilityRadius: 1400,
		dontLoadAsset: true,
	},
	'poi-eyes-02': {
		zOffset: -500,
		rigged: false,
		visibilityRadius: 1000,
		dontLoadAsset: true,
	},
	'poi-eyes-03': {
		zOffset: -500,
		rigged: false,
		visibilityRadius: 1000,
		dontLoadAsset: true,
	},
	'poi-eyes-web-top': {
		zOffset: -250,
		rigged: false,
		visibilityRadius: 1000,
		dontLoadAsset: true,
	},
	'poi-eyes-web-bottom-01': {
		zOffset: -250,
		rigged: false,
		visibilityRadius: 1000,
		dontLoadAsset: true,
	},
	'poi-eyes-web-bottom-02': {
		zOffset: -250,
		rigged: false,
		visibilityRadius: 1000,
		dontLoadAsset: true,
	},
	'poi-eyes-web-bottom-03': {
		zOffset: -500,
		rigged: false,
		visibilityRadius: 1000,
		dontLoadAsset: true,
	},
	// 'poi-02-base': {
	// 	colliders: [	
	// 				{ type: ColliderType.Circle, position: [0, 0], radius: 65, traits: ColliderTraits.BlockMovementAndItem },
	// 			],
	// 	zOffset: -500,
	// 	rigged: true,
	// 	visibilityRadius: 1000,
	// },
	// 'poi-02-b': {
	// 	zOffset: -500,
	// 	rigged: true,
	// 	visibilityRadius: 1000,
	// },
	'poi-02-fire-back': {
		zOffset: -500,
		rigged: true,
		visibilityRadius: 1000,
	},
	'poi-02-fire-top': {
		zOffset: -500,
		rigged: true,
		visibilityRadius: 1000,
	},
	'poi-02-flag': {
		zOffset: -500,
		rigged: true,
		visibilityRadius: 1000,
	},
	'poi-03-darkened-garden': {
		colliders: [	
					// { type: ColliderType.Circle, position: [0, 0], radius: 65, traits: ColliderTraits.BlockMovementAndItem },
					{ type: ColliderType.Circle, position: [8, -491], radius: 120, traits: ColliderTraits.BlockAll },
					{ type: ColliderType.Ellipse, position: [ 283, -35], rX: 120, rY: 77, traits: ColliderTraits.BlockMovementAndItem },
					{ type: ColliderType.Circle, position: [-256, 233], radius: 120, traits: ColliderTraits.BlockAll },
					{ type: ColliderType.Circle, position: [110, 510], radius: 60, traits: ColliderTraits.BlockAll },
					{ type: ColliderType.Circle, position: [348, 321], radius: 43, traits: ColliderTraits.BlockAll },
					{ type: ColliderType.Box, position: [-664, 451], width: 175, height: 45, traits: ColliderTraits.BlockAll, angle: degToRad(30) },
					{ type: ColliderType.Circle, position: [-988, 239], radius: 75, traits: ColliderTraits.BlockAll },
					{ type: ColliderType.Box, position: [-953, -7], width: 758, height: 160, traits: ColliderTraits.BlockAll, angle: degToRad(-52) },
					{ type: ColliderType.Circle, position: [748, -82], radius: 245, traits: ColliderTraits.BlockAll },
					{ type: ColliderType.Box, position: [363, 240], width: 730, height: 220, traits: ColliderTraits.BlockAll, angle: degToRad(-80) },



				],
		zOffset: -500,
		rigged: true,
		visibilityRadius: 1200,
	},
	'boss-stones-01': {
		zOffset: -5000,
		dontLoadAsset: true,
	},
	'boss-path-01': {
		zOffset: -5000,
		dontLoadAsset: true,
	},
	'boss-path-02': {
		zOffset: -5000,
		dontLoadAsset: true,
	},
	'boss-path-straight': {
		zOffset: -5000,
		dontLoadAsset: true,
	},
	'boss-arena': {
		disableFileOrdering: true,
		colliders: [
			{ type: ColliderType.Ellipse, position: [-140, -671], rX: 888, rY: 407, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [1324, -651], rX: 347, rY: 380, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [-1084, 867], rX: 414, rY: 194, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [-1075, 900], rX: 588, rY: 265, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-365, 1252], rX: 312, rY: 253, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [1291, 98], rX: 367, rY: 133, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [1291, 98], rX: 367, rY: 222, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [1240, 744], rX: 447, rY: 387, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [645, 1282], rX: 154, rY: 237, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [769, 1309], rX: 76, rY: 141, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [799, 1258], rX: 83, rY: 80, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-93, 1048], width: 800, height: 200, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-93, 1248], width: 800, height: 50, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-1608, -678], width: 383, height: 1521, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-1378, -819], width: 897, height: 208, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-1378, -611], width: 897, height: 50, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-1352, 97], width: 423, height: 715, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-1112, 847], width: 1320, height: 253, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-1112, 1100], width: 1320, height: 60, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [580, 940], width: 605, height: 250, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [580, 1190], width: 605, height: 50, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [1273, -762], width: 395, height: 1392, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-26, -1079], width: 1383, height: 339, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-26, -740], width: 1383, height: 50, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-1194, 76], rX: 269, rY: 184, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-19, -874], rX: 555, rY: 309, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [-1194, 76], rX: 269, rY: 184, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-1374, -633], rX: 233, rY: 184, traits: ColliderTraits.BlockMovementAndItem },
		],
		rigged: false,
		visibilityRadius: 2000,
	},
	'boss-ground': {
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},
	'boss-grass-01': {
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},
	'boss-grass-02': {
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},
	'boss-grass-03': {
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},
	'boss-grass-04': {
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},

	// 'boss-01-a': {
	// 	zOffset: -1000,
	// 	dontLoadAsset: true,
	// },
	'boss-01c': {
		zOffset: -350,
		dontLoadAsset: true,
	},
	'boss-04': {
		zOffset: -10,
		dontLoadAsset: true,
	},
	'boss-05': {
		zOffset: -10,
		dontLoadAsset: true,
	},
	'boss-07': {
		zOffset: -10,
		dontLoadAsset: true,
	},
	'boss-08': {
		zOffset: -10,
		dontLoadAsset: true,
	},
	'boss-09': {
		zOffset: -10,
		dontLoadAsset: true,
	},
	'boss-10': {
		zOffset: -10,
		dontLoadAsset: true,
	},
	'boss-11': {
		zOffset: -10,
		dontLoadAsset: true,
	},
	'boss-12': {
		zOffset: -10,
		dontLoadAsset: true,
	},
	'boss-13': {
		zOffset: -10,
		dontLoadAsset: true,
	},
	'boss-14': {
		zOffset: -100,
		dontLoadAsset: true,
	},
	'boss-15': {
		zOffset: -100,
		dontLoadAsset: true,
	},
	'boss-16': {
		zOffset: -100,
		dontLoadAsset: true,
	},
	'boss-arena-glow-particle': {
		zOffset: 100,
		rigged: true,
		visibilityRadius: 1400,
	},

	'boss-arena-tree-animations': {
		zOffset: 100,
		rigged: true,
		visibilityRadius: 1400,
	},
	'boss-arena-mushroom-a': {
		zOffset: -25,
		rigged: true,
		visibilityRadius: 1400,
	},
	'boss-arena-mushroom-b': {
		zOffset: -25,
		rigged: true,
		visibilityRadius: 1400,
	},
	'boss-arena-mushroom-c': {
		zOffset: -25,
		rigged: true,
		visibilityRadius: 1400,
	},
	'poi-11': {
		zOffset: -25,
		dontLoadAsset: true,
	},
	// START blocker pieces //
	'low-blocker-01': {
		colliders: [{ type: ColliderType.Ellipse, position: [0, 0], rX: 150, rY: 80, traits: ColliderTraits.BlockMovementAndItem }],
		dontLoadAsset: true,
		visibilityRadius: 150,
	},
	'low-blocker-02': {
		colliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 90, traits: ColliderTraits.BlockMovementAndItem }],
		dontLoadAsset: true,
		visibilityRadius: 125,
	},
	'low-blocker-03': {
		colliders: [{ type: ColliderType.Ellipse, position: [0, 0], rX: 150, rY: 80, traits: ColliderTraits.BlockMovementAndItem }],
		dontLoadAsset: true,
		visibilityRadius: 150,
	},
	'mid-blocker-01': {
		colliders: [{ type: ColliderType.Box, angle: degToRad(30), position: [-3, -37], height: 160, width: 52, traits: ColliderTraits.BlockAll }],
		dontLoadAsset: true,
		visibilityRadius: 170,
	},
	'mid-blocker-02': {
		zOffset: -150,
		colliders: [{ type: ColliderType.Box, position: [-50, -100], height: 250, width: 40, traits: ColliderTraits.BlockAll }],
		dontLoadAsset: true,
		visibilityRadius: 170,
	},
	'mid-blocker-03': {
		zOffset: -60,
		colliders: [{ type: ColliderType.Box, angle: degToRad(-32), position: [-73, -9], height: 160, width: 50, traits: ColliderTraits.BlockAll }],
		dontLoadAsset: true,
		visibilityRadius: 150,
	},
	'high-blocker-01': {
		zOffset: -50,
		colliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 75, traits: ColliderTraits.BlockAll }],
		dontLoadAsset: true,
		visibilityRadius: 110,
	},
	'high-blocker-02': {
		zOffset: -50,
		colliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 55, traits: ColliderTraits.BlockAll }],
		dontLoadAsset: true,
		visibilityRadius: 110,
	},
	// END blocker pieces //
	'high-blocker-01-var01': makeBlockPropConfig(),
	'high-blocker-01-var02': makeBlockPropConfig(),
	'high-blocker-01-var03': makeBlockPropConfig(),
	'high-blocker-01-var04': makeBlockPropConfig(),
	'mid-blocker-01-var01': makeBlockPropConfig(),
	'mid-blocker-01-var02': makeBlockPropConfig(),
	'mid-blocker-01-var03': makeBlockPropConfig(),
	'mid-blocker-01-var04': makeBlockPropConfig(),
	'mid-blocker-01-var05': {
		colliders:[
			{ type: ColliderType.Box, position: [-26,-306], height: 250, width: 40, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Box, position: [-27,-125], height: 250, width: 40, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Box, angle: -0.56, position: [-42,132], height: 210, width: 48, traits: ColliderTraits.BlockAll,}
		],
	},
	'mid-blocker-01-var06': {
		colliders:[
			{ type: ColliderType.Box, angle: 0.52, position: [43,-219], height: 160, width: 52, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Box, position: [-27,-125], height: 250, width: 40, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Box, angle: -0.56, position: [-37,128], height: 207, width: 48, traits: ColliderTraits.BlockAll,}
		]
	},
	'low-blocker-01-var01': makeBlockPropConfig(),
	'low-blocker-01-var02': makeBlockPropConfig(),
	'low-blocker-01-var03': makeBlockPropConfig(),
	'low-blocker-01-var04': makeBlockPropConfig(),
	'low-blocker-01-var05': makeBlockPropConfig(),
	'low-blocker-01-var06': makeBlockPropConfig(),
	'small-common-01': {
		//colliders: [{ type: ColliderType.Circle, position: [0, -5], radius: 20, traits: ColliderTraits.None }],
		visibilityRadius: 55,
	},
	'small-common-02': {
		//colliders: [{ type: ColliderType.Circle, position: [0, -6], radius: 20, traits: ColliderTraits.None }],
		visibilityRadius: 55,
	},
	'small-common-03': {
		//colliders: [{ type: ColliderType.Circle, position: [0, -7], radius: 20, traits: ColliderTraits.None }],
		visibilityRadius: 55,
	},
	'small-uncommon-01': {
		visibilityRadius: 70,
	},
	'small-uncommon-02': {
		visibilityRadius: 75,
		zOffset: -50,
	},
	'small-uncommon-03': {
		zOffset: -50,
		visibilityRadius: 75,
	},
	'small-uncommon-04': {
		zOffset: -50,
		colliders: [{ type: ColliderType.Circle, position: [4, -13], radius: 31, traits: ColliderTraits.BlockMovementAndItem }],
		visibilityRadius: 75, // not sure on this one
	},
	'small-rare-01': {
		colliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 34, traits: ColliderTraits.BlockMovementAndItem }],
		visibilityRadius: 310,
		zOffset: -50,
	},
	'small-rare-02': {
		colliders: [{ type: ColliderType.Ellipse, position: [20, -21], rX: 78, rY: 51, traits: ColliderTraits.BlockAll }],
		visibilityRadius: 200,
		zOffset: -50,
	},
	'small-rare-03': {
		colliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 50, traits: ColliderTraits.BlockMovementAndItem }],
		visibilityRadius: 240,
		zOffset: -50,
	},
	'med-common-01': {
		zOffset: -10,
		colliders: [{ type: ColliderType.Circle, position: [4, -30], radius: 35, traits: ColliderTraits.BlockAll }],
		visibilityRadius: 330,
	},
	'med-common-02': {
		zOffset: -10,
		colliders: [{ type: ColliderType.Circle, position: [-2, -22], radius: 33, traits: ColliderTraits.BlockMovementAndItem }],
		visibilityRadius: 350,
	},
	'med-common-03': {
		zOffset: -10,
		colliders: [{ type: ColliderType.Circle, position: [15, -26], radius: 40, traits: ColliderTraits.BlockAll }],
		visibilityRadius: 355,
	},
	'med-common-04': {
		zOffset: -10,
		colliders: [{ type: ColliderType.Circle, position: [0, -25], radius: 40, traits: ColliderTraits.BlockAll }],
		visibilityRadius: 510,
	},
	'med-uncommon-01': {
		zOffset: -10,
		colliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 50, traits: ColliderTraits.BlockAll }], // placeholder
	},
	'med-uncommon-02': {
		zOffset: -10,
		colliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 50, traits: ColliderTraits.BlockAll }], // placeholder
	},
	'med-uncommon-03': {
		zOffset: -10,
		colliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 50, traits: ColliderTraits.BlockAll }], // placeholder
	},
	'large-uncommon-01-var01': {
		colliders: [
			{ type: ColliderType.Circle, position: [95, 6], radius: 35, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [-100, 0], radius: 35, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [-6, -3], rX: 219, rY: 124, traits: ColliderTraits.BlockMovementAndItem },
		],
		zeroHeight: ['large-uncommon-01'],
		visibilityRadius: 450,
	},
	'large-uncommon-01-var02': {
		colliders: [{ type: ColliderType.Ellipse, position: [-6, -3], rX: 219, rY: 124, traits: ColliderTraits.BlockMovementAndItem }],
		zeroHeight: ['large-uncommon-01'],
		visibilityRadius: 297,
	},
	'large-uncommon-01-var03': {
		colliders: [
			{ type: ColliderType.Ellipse, position: [-49, -41], rX: 97, rY: 81, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [-6, -3], rX: 219, rY: 124, traits: ColliderTraits.BlockMovementAndItem },
		],
		zeroHeight: ['large-uncommon-01'],
		visibilityRadius: 390,
	},
	'large-rare-01-var01': {
		colliders: forestLargeRareColliders,
		visibilityRadius: 615,
	},
	'large-rare-01-var02': {
		colliders: forestLargeRareColliders,
		visibilityRadius: 590,
	},
	'large-rare-01-var03': {
		colliders: forestLargeRareColliders,
		visibilityRadius: 615,
	},
	'large-rare-02-var01': {
		colliders: [
			{ type: ColliderType.Ellipse, position: [77, 172], rX: 293, rY: 152, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-111, -40], rX: 219, rY: 300, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [-216, -68], radius: 40, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [-1, 21], radius: 40, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [-201, 167], radius: 40, traits: ColliderTraits.BlockAll },
		],
		visibilityRadius: 522,
	},
	'large-rare-02-var02': {
		colliders: [
			{ type: ColliderType.Ellipse, position: [72, 165], rX: 294, rY: 166, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-111, -40], rX: 219, rY: 300, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [-66, -210], radius: 40, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [-68, 130], rX: 87, rY: 61, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [167, 206], radius: 40, traits: ColliderTraits.BlockAll },
		],
		visibilityRadius: 562,
		zOffset: -50,
	},
	'large-rare-02-var03': {
		colliders: [
			{ type: ColliderType.Ellipse, position: [77, 172], rX: 307, rY: 174, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-109, -43], rX: 230, rY: 328, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [-127, 30], radius: 38, traits: ColliderTraits.BlockAll },
		],
		visibilityRadius: 522,
		zOffset: -100,
	},
	outpost: {
		zOffset: -175,
		dontLoadAsset: true,
	},
	'outpost-window': {
		zOffset: -5000,
		dontLoadAsset: true,
	},
	ground_patch: {
		zOffset: -50000,
		dontLoadAsset: true,
	},
	worm_sign: {
		zOffset: -25,
		dontLoadAsset: true,
	},
	campfire: {
		zOffset: -25,
		dontLoadAsset: true,
	},
	'forest-alter': {
		overrideAtlas: 'forest-alter',
		colliders: [
			{ type: ColliderType.Ellipse, position: [-18,-315], rX: 95, rY: 99, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [-181,-274], rX: 72, rY: 79, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [129,-276], rX: 72, rY: 79, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [-276,-178], rX: 51, rY: 48, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [260,-183], rX: 51, rY: 56, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [-249,-50], rX: 24, rY: 21, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [224,-34], rX: 24, rY: 21, traits: ColliderTraits.BlockMovementAndItem },
		],
		rigged: false,
		zOffset: -350,
		visibilityRadius: 1400,
	},
	'party-alter-animated': {
		overrideAtlas: 'forest-alter',
		zOffset: -200,
		visibilityRadius: 1400,
	},
}
