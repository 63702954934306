import { SKIN_SLOT } from '../../player/shared/set-skin.shared'
import { ItemRarity } from './item-rarity'
import { PrizeData } from './prize-sub-type'
import WeaponSubType from './weapon-sub-type'

export class WeaponSkinPrizeData implements PrizeData {
	weaponSkinType: WeaponSkinType

	constructor(weaponSkinType: WeaponSkinType) {
		this.weaponSkinType = weaponSkinType
	}
}

export enum WeaponSkinSubType {
	WeaponSkin = 4001,
}

export enum BeachWeaponType {
	Arcane = 100,
	Crossbow = 101,
	Staff = 102,
	Sword = 103,
	Wand = 104,
	Scythe = 105,
}

export enum GoofyWeaponType {
	Arcane = 200,
	Crossbow = 201,
	Staff = 202,
	Sword = 203,
	Wand = 204,
	Scythe = 205,
}

export enum HalloweenWeaponType {
	Arcane = 300,
	Crossbow = 301,
	Staff = 302,
	Sword = 303,
	Wand = 304,
	Scythe = 305,
}

export enum PrehistoricWeaponType {
	Arcane = 400,
	Crossbow = 401,
	Staff = 402,
	Sword = 403,
	Wand = 404,
	Scythe = 405,
}

export enum LaunchWeaponType {
	Arcane = 500,
	Crossbow = 501,
	Staff = 502,
	Sword = 503,
	Wand = 504,
	Scythe = 505,
}

export enum FactionWeaponType {
	Arcane = 600, // Unused currently
	Crossbow = 601, //Unused currently
	Staff = 602,
	Sword = 603,
	Wand = 604, // Unused currently
	Scythe = 605,
}

export type WeaponSkinType = BeachWeaponType | GoofyWeaponType | HalloweenWeaponType | PrehistoricWeaponType | LaunchWeaponType | FactionWeaponType

export const BeachWeaponTypeValues = getEnumKeys(BeachWeaponType)
export const GoofyWeaponTypeValues = getEnumKeys(GoofyWeaponType)
export const HalloweenWeaponTypeValues = getEnumKeys(HalloweenWeaponType)
export const PrehistoricWeaponTypeValues = getEnumKeys(PrehistoricWeaponType)
export const LaunchWeaponTypeValues = getEnumKeys(LaunchWeaponType)
export const FactionWeaponTypeValues = getEnumKeys(FactionWeaponType)

export const WeaponSkinSubTypeValues = BeachWeaponTypeValues.concat(GoofyWeaponTypeValues)
	.concat(HalloweenWeaponTypeValues)
	.concat(PrehistoricWeaponTypeValues)
	.concat(LaunchWeaponTypeValues)
	.concat(FactionWeaponTypeValues)

const WeaponSkinSubTypePrettyName = new Map<WeaponSkinSubType, string>()
WeaponSkinSubTypePrettyName.set(WeaponSkinSubType.WeaponSkin, 'Weapon Skin')

const WeaponSkinPrettyNames = new Map<WeaponSkinType, string>()
//Beach Weapon Skins
WeaponSkinPrettyNames.set(BeachWeaponType.Arcane, 'Beach Arcane')
WeaponSkinPrettyNames.set(BeachWeaponType.Crossbow, 'Beach Crossbow')
WeaponSkinPrettyNames.set(BeachWeaponType.Staff, 'Beach Staff')
WeaponSkinPrettyNames.set(BeachWeaponType.Sword, 'Beach Sword')
WeaponSkinPrettyNames.set(BeachWeaponType.Wand, 'Beach Wand')
WeaponSkinPrettyNames.set(BeachWeaponType.Scythe, 'Beach Scythe')
//Goofy Weapon Skins
WeaponSkinPrettyNames.set(GoofyWeaponType.Arcane, 'Goofy Arcane')
WeaponSkinPrettyNames.set(GoofyWeaponType.Crossbow, 'Goofy Crossbow')
WeaponSkinPrettyNames.set(GoofyWeaponType.Staff, 'Goofy Staff')
WeaponSkinPrettyNames.set(GoofyWeaponType.Sword, 'Goofy Sword')
WeaponSkinPrettyNames.set(GoofyWeaponType.Wand, 'Goofy Wand')
WeaponSkinPrettyNames.set(GoofyWeaponType.Scythe, 'Goofy Scythe')
//Halloween Weapon Skins
WeaponSkinPrettyNames.set(HalloweenWeaponType.Arcane, 'Halloween Arcane')
WeaponSkinPrettyNames.set(HalloweenWeaponType.Crossbow, 'Halloween Crossbow')
WeaponSkinPrettyNames.set(HalloweenWeaponType.Staff, 'Halloween Staff')
WeaponSkinPrettyNames.set(HalloweenWeaponType.Sword, 'Halloween Sword')
WeaponSkinPrettyNames.set(HalloweenWeaponType.Wand, 'Halloween Wand')
WeaponSkinPrettyNames.set(HalloweenWeaponType.Scythe, 'Halloween Scythe')
//Prehistoric Weapon Skins
WeaponSkinPrettyNames.set(PrehistoricWeaponType.Arcane, 'Prehistoric Arcane')
WeaponSkinPrettyNames.set(PrehistoricWeaponType.Crossbow, 'Prehistoric Crossbow')
WeaponSkinPrettyNames.set(PrehistoricWeaponType.Staff, 'Prehistoric Staff')
WeaponSkinPrettyNames.set(PrehistoricWeaponType.Sword, 'Prehistoric Sword')
WeaponSkinPrettyNames.set(PrehistoricWeaponType.Wand, 'Prehistoric Wand')
WeaponSkinPrettyNames.set(PrehistoricWeaponType.Scythe, 'Prehistoric Scythe')

//Launch (Content Update 01) Weapon Skins
WeaponSkinPrettyNames.set(LaunchWeaponType.Arcane, 'Golden Arcane')
WeaponSkinPrettyNames.set(LaunchWeaponType.Crossbow, 'Bone Crossbow')
WeaponSkinPrettyNames.set(LaunchWeaponType.Staff, 'Eye Bat Staff')
WeaponSkinPrettyNames.set(LaunchWeaponType.Sword, 'FMC Sword')
WeaponSkinPrettyNames.set(LaunchWeaponType.Wand, 'Snake Skull Wand')
WeaponSkinPrettyNames.set(LaunchWeaponType.Scythe, 'Future Scythe')

WeaponSkinPrettyNames.set(FactionWeaponType.Sword, 'Gilded Order of Iron Sword')
WeaponSkinPrettyNames.set(FactionWeaponType.Staff, 'Gilded Scions of Dawn Staff')
WeaponSkinPrettyNames.set(FactionWeaponType.Scythe, 'Gilded Aurum Alliance Scythe')

const WeaponSkinRarities = new Map<WeaponSkinType, ItemRarity>()
//Beach Weapon Skins
WeaponSkinRarities.set(BeachWeaponType.Arcane, ItemRarity.EPIC)
WeaponSkinRarities.set(BeachWeaponType.Crossbow, ItemRarity.EPIC)
WeaponSkinRarities.set(BeachWeaponType.Staff, ItemRarity.EPIC)
WeaponSkinRarities.set(BeachWeaponType.Sword, ItemRarity.EPIC)
WeaponSkinRarities.set(BeachWeaponType.Wand, ItemRarity.EPIC)
WeaponSkinRarities.set(BeachWeaponType.Scythe, ItemRarity.EPIC)
//Goofy Weapon Skins
WeaponSkinRarities.set(GoofyWeaponType.Arcane, ItemRarity.EPIC)
WeaponSkinRarities.set(GoofyWeaponType.Crossbow, ItemRarity.EPIC)
WeaponSkinRarities.set(GoofyWeaponType.Staff, ItemRarity.EPIC)
WeaponSkinRarities.set(GoofyWeaponType.Sword, ItemRarity.EPIC)
WeaponSkinRarities.set(GoofyWeaponType.Wand, ItemRarity.EPIC)
WeaponSkinRarities.set(GoofyWeaponType.Scythe, ItemRarity.EPIC)
//Halloween Weapon Skins
WeaponSkinRarities.set(HalloweenWeaponType.Arcane, ItemRarity.EPIC)
WeaponSkinRarities.set(HalloweenWeaponType.Crossbow, ItemRarity.EPIC)
WeaponSkinRarities.set(HalloweenWeaponType.Staff, ItemRarity.EPIC)
WeaponSkinRarities.set(HalloweenWeaponType.Sword, ItemRarity.EPIC)
WeaponSkinRarities.set(HalloweenWeaponType.Wand, ItemRarity.EPIC)
WeaponSkinRarities.set(HalloweenWeaponType.Scythe, ItemRarity.EPIC)
//Prehistoric Weapon Skins
WeaponSkinRarities.set(PrehistoricWeaponType.Arcane, ItemRarity.EPIC)
WeaponSkinRarities.set(PrehistoricWeaponType.Crossbow, ItemRarity.EPIC)
WeaponSkinRarities.set(PrehistoricWeaponType.Staff, ItemRarity.EPIC)
WeaponSkinRarities.set(PrehistoricWeaponType.Sword, ItemRarity.EPIC)
WeaponSkinRarities.set(PrehistoricWeaponType.Wand, ItemRarity.EPIC)
WeaponSkinRarities.set(PrehistoricWeaponType.Scythe, ItemRarity.EPIC)
//Launch (Content Update 01) Weapon Skins
WeaponSkinRarities.set(LaunchWeaponType.Arcane, ItemRarity.EPIC)
WeaponSkinRarities.set(LaunchWeaponType.Crossbow, ItemRarity.EPIC)
WeaponSkinRarities.set(LaunchWeaponType.Staff, ItemRarity.EPIC)
WeaponSkinRarities.set(LaunchWeaponType.Sword, ItemRarity.EPIC)
WeaponSkinRarities.set(LaunchWeaponType.Wand, ItemRarity.EPIC)
WeaponSkinRarities.set(LaunchWeaponType.Scythe, ItemRarity.EPIC)
// Faction Weapon Skins
WeaponSkinRarities.set(FactionWeaponType.Sword, ItemRarity.LEGENDARY)
WeaponSkinRarities.set(FactionWeaponType.Staff, ItemRarity.LEGENDARY)
WeaponSkinRarities.set(FactionWeaponType.Scythe, ItemRarity.LEGENDARY)

const WeaponSkinSlots = new Map<WeaponSkinType, SKIN_SLOT>()
//Beach Weapon Skins
WeaponSkinSlots.set(BeachWeaponType.Arcane, SKIN_SLOT.WEAPON_ARCANE)
WeaponSkinSlots.set(BeachWeaponType.Crossbow, SKIN_SLOT.WEAPON_CROSSBOW)
WeaponSkinSlots.set(BeachWeaponType.Staff, SKIN_SLOT.WEAPON_STAFF)
WeaponSkinSlots.set(BeachWeaponType.Sword, SKIN_SLOT.WEAPON_SWORD)
WeaponSkinSlots.set(BeachWeaponType.Wand, SKIN_SLOT.WEAPON_WAND)
WeaponSkinSlots.set(BeachWeaponType.Scythe, SKIN_SLOT.WEAPON_SCYTHE)
//Goofy Weapon Skins
WeaponSkinSlots.set(GoofyWeaponType.Arcane, SKIN_SLOT.WEAPON_ARCANE)
WeaponSkinSlots.set(GoofyWeaponType.Crossbow, SKIN_SLOT.WEAPON_CROSSBOW)
WeaponSkinSlots.set(GoofyWeaponType.Staff, SKIN_SLOT.WEAPON_STAFF)
WeaponSkinSlots.set(GoofyWeaponType.Sword, SKIN_SLOT.WEAPON_SWORD)
WeaponSkinSlots.set(GoofyWeaponType.Wand, SKIN_SLOT.WEAPON_WAND)
WeaponSkinSlots.set(GoofyWeaponType.Scythe, SKIN_SLOT.WEAPON_SCYTHE)
//Halloween Weapon Skins
WeaponSkinSlots.set(HalloweenWeaponType.Arcane, SKIN_SLOT.WEAPON_ARCANE)
WeaponSkinSlots.set(HalloweenWeaponType.Crossbow, SKIN_SLOT.WEAPON_CROSSBOW)
WeaponSkinSlots.set(HalloweenWeaponType.Staff, SKIN_SLOT.WEAPON_STAFF)
WeaponSkinSlots.set(HalloweenWeaponType.Sword, SKIN_SLOT.WEAPON_SWORD)
WeaponSkinSlots.set(HalloweenWeaponType.Wand, SKIN_SLOT.WEAPON_WAND)
WeaponSkinSlots.set(HalloweenWeaponType.Scythe, SKIN_SLOT.WEAPON_SCYTHE)
//Prehistoric Weapon Skins
WeaponSkinSlots.set(PrehistoricWeaponType.Arcane, SKIN_SLOT.WEAPON_ARCANE)
WeaponSkinSlots.set(PrehistoricWeaponType.Crossbow, SKIN_SLOT.WEAPON_CROSSBOW)
WeaponSkinSlots.set(PrehistoricWeaponType.Staff, SKIN_SLOT.WEAPON_STAFF)
WeaponSkinSlots.set(PrehistoricWeaponType.Sword, SKIN_SLOT.WEAPON_SWORD)
WeaponSkinSlots.set(PrehistoricWeaponType.Wand, SKIN_SLOT.WEAPON_WAND)
WeaponSkinSlots.set(PrehistoricWeaponType.Scythe, SKIN_SLOT.WEAPON_SCYTHE)
//Launch (Content Update 01) Weapon Skins
WeaponSkinSlots.set(LaunchWeaponType.Arcane, SKIN_SLOT.WEAPON_ARCANE)
WeaponSkinSlots.set(LaunchWeaponType.Crossbow, SKIN_SLOT.WEAPON_CROSSBOW)
WeaponSkinSlots.set(LaunchWeaponType.Staff, SKIN_SLOT.WEAPON_STAFF)
WeaponSkinSlots.set(LaunchWeaponType.Sword, SKIN_SLOT.WEAPON_SWORD)
WeaponSkinSlots.set(LaunchWeaponType.Wand, SKIN_SLOT.WEAPON_WAND)
WeaponSkinSlots.set(LaunchWeaponType.Scythe, SKIN_SLOT.WEAPON_SCYTHE)
//Faction Weapon Skins
WeaponSkinSlots.set(FactionWeaponType.Sword, SKIN_SLOT.WEAPON_SWORD)
WeaponSkinSlots.set(FactionWeaponType.Staff, SKIN_SLOT.WEAPON_STAFF)
WeaponSkinSlots.set(FactionWeaponType.Scythe, SKIN_SLOT.WEAPON_SCYTHE)

export { WeaponSkinPrettyNames, WeaponSkinRarities, WeaponSkinSlots }

export function weaponSkinSubTypeToThemeString(subType: number) {
	if(subType < 200) {
		return 'beach'
	} else if(subType < 300) {
		return 'goofy'
	} else if (subType < 400) {
		return 'halloween'
	} else if (subType < 500) {
		return 'prehistoric'
	}
	else if (subType < 600) {
		if (typeof subType === 'string') {
			subType = parseInt(subType)
		}
		switch (subType) {
			case 500:
				return 'crystal'
				break
			case 501:
				return 'bones'
				break
			case 502:
				return 'eyebat'
				break
			case 503:
				return 'feed-me-claymore'
				break
			case 504: 
				return 'snake-skull'
				break
			case 505:
				return 'future'
				break
		}
	}
	else if (subType < 700) {
		if (typeof subType === 'string') {
			subType = parseInt(subType)
		}
		switch (subType){
			case 602:
				return 'scions-of-dawn'
				break
			case 603:
				return 'order-of-iron'
				break
			case 605:
				return 'aurum-alliance'
				break
		}
	}
}

export function weaponSkinSubTypeToThemeSpineString(subType: number) {
	if(subType < 200) {
		return 'beach-01'
	} else if(subType < 300) {
		return 'goofy-01'
	} else if (subType < 400) {
		return 'halloween-01'
	} else if (subType < 500) {
		return 'prehistoric-01'
	} else if (subType < 600) {
		if (typeof subType === 'string') {
			subType = parseInt(subType)
		}
		switch (subType) {
			case 500:
				return 'golden-01'
				break
			case 501:
				return 'bones-01'
				break
			case 502:
				return 'eyebat-01'
				break
			case 503:
				return 'feed-me-claymore-01'
				break
			case 504: 
				return 'snake-skull-01'
				break
			case 505:
				return 'future-01'
				break
		}
	}
	else if (subType < 700) {
		if (typeof subType === 'string') {
			subType = parseInt(subType)
		}
		switch (subType){
			case 602:
				return 'scions-of-dawn-01'
				break
			case 603:
				return 'order-of-iron-01'
				break
			case 605:
				return 'aurum-alliance-01'
				break
		}
	}
}

export function weaponSkinSubTypeToWeaponSubType(subType: WeaponSkinType) : WeaponSubType {
	const val = subType % 100
	switch(val) {
		case 0:
			return WeaponSubType.ArcaneFocus
		case 1:
			return WeaponSubType.Crossbow
		case 2:
			return WeaponSubType.Staff
		case 3:
			return WeaponSubType.Spellsword
		case 4:
			return WeaponSubType.Wand
		case 5:
			return WeaponSubType.Scythe
	}
}

function getEnumKeys(enumType) {
	return Object.keys(enumType)
		.map(function(t) {
			return enumType[t]
		})
		.filter((val) => !isNaN(val))
}
