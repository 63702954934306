import nengi from 'nengi'
import { uuid } from '../../utils/primitive-types'

class EquipWeaponToSlotCommand {
	static protocol = {
		weaponId: nengi.String,
		slotIndex: nengi.Number,
	}
	weaponId: uuid
	slotIndex: number
	/**
	 * Creates an instance of EquipWeaponToSlotCommand. This will scan the Stash to find a matching item.
	 * @param {uuid} weaponId Weapon ID
	 * @param {number} slotIndex Slot index
	 * @memberof EquipWeaponToSlotCommand
	 */
	constructor(weaponId: uuid, slot: number) {
		this.weaponId = weaponId
		this.slotIndex = slot
	}
}

export default EquipWeaponToSlotCommand
