import nengi from 'nengi'
import { gameUnits } from '../../utils/primitive-types'

class NengiViewSizeUpdatedMessage {
	static protocol = {
		newX: nengi.Float32,
		newY: nengi.Float32,
		newWidth: nengi.Float32,
		newHeight: nengi.Float32,
	}
	newX: gameUnits
	newY: gameUnits
	newWidth: gameUnits
	newHeight: gameUnits
	constructor(newX: gameUnits, newY: gameUnits, newWidth: gameUnits, newHeight: gameUnits) {
		this.newX = newX
		this.newY = newY
		this.newWidth = newWidth
		this.newHeight = newHeight
	}
}

export default NengiViewSizeUpdatedMessage
