import { EnemyAI, FightingTriggers, IdleBehaviours, FightingBehaviours, LeashTriggers, LeashingBehaviours, AttackTypes, DeadBehaviours, ShotLeadPrecision, EnemyType, EnemyTargetingStyles } from '../ai-types'
import { ModType } from '../../../projectiles/shared/projectile-types'
import { SpineDataName, EnemyBipedSkinType } from '../../../models-animations/shared/spine-config'
import { degToRad } from '../../../utils/math'
import { EnemyDefaults } from './enemy-defaults'
import { Colors } from '../../../utils/colors'
import { ParticleEffectType } from '../../../engine/shared/game-data/particle-config'
import { Vector } from 'sat'
import { ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { AnimationTrack } from '../../../models-animations/shared/animation-track'
import { ENEMY_DEFAULT_HEALTH } from '../../../engine/shared/game-data/enemy-formulas'
import { ENEMY_NAMES } from './enemy-names'
import { Ability, AbilitySelectionStyles, AbilityTargets, AbilityTargetSelectionStyle, AbilityType, ActionCriteria, CriteriaSatisfactionQuantity, CriteriaTarget, CriteriaType } from '../action-types'
import { attackRatedAbilityList } from '../action-criteria/ability-helpers'
import { alwaysTrueCriteria } from './abilities/common-abilities'
import { WorldDifficulty } from '../../../engine/shared/game-data/world-difficulty'

const entSlap: Ability = {
	debugName: 'entSlap',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.CLOSEST,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		projectileCount: 1,
		spreadAngle: degToRad(90),
		speed: 0.1,
		// the melee animation of the Ent doesn't work well with targets above or below itself
		// needs either another animation, or a design configuration that leaves it vulnerable
		// in the Y axis
		colliderRadius: 210,
		lifespanInSeconds: 0.5,
		modifiers: [{ modType: ModType.STRAIGHT }],
		basePhysicalDamage: 300,
		burstCount: 0,
		color: Colors.purple,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_NONE,
	},
	attackOffset: new Vector(40, -75),
	ignoreAngleOnWeaponOffset: true,
}

const entCannon: Ability = {
	debugName: 'entCannon',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.LOWEST_HEALTH,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		projectileCount: 1,
		speed: 180,
		colliderRadius: 180,
		lifespanInSeconds: 5.5,
		modifiers: [{ modType: ModType.STRAIGHT }],
		baseLightningDamage: 150,
		color: Colors.yellow,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_WISP,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_LIGHTNING_TRAIL,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_LIGHTNING_SHOOT,
	},
	attackOffset: new Vector(40, -75),
	ignoreAngleOnWeaponOffset: true,
}

const playerIsInLongRangeCriteriaT3: ActionCriteria = {
	satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
	criterias: [
		{
			criteriaTargets: CriteriaTarget.PLAYERS,
			criteriaType: CriteriaType.WITHIN_RANGE,
			criteriaValue: 800,
			minimumTargetsThatSatisfy: 1,
		},
		{
			criteriaTargets: CriteriaTarget.SELF,
			criteriaType: CriteriaType.WORLD_DIFFICULTY_AT_OR_ABOVE_THRESHOLD,
			criteriaValue: WorldDifficulty.Brutal,
			minimumTargetsThatSatisfy: 1,
		},
	],
}

const playerIsInShortRangeCriteriaT3: ActionCriteria = {
	satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
	criterias: [
		{
			criteriaTargets: CriteriaTarget.PLAYERS,
			criteriaType: CriteriaType.WITHIN_RANGE,
			criteriaValue: 260,
			minimumTargetsThatSatisfy: 1,
		},
		{
			criteriaTargets: CriteriaTarget.SELF,
			criteriaType: CriteriaType.WORLD_DIFFICULTY_AT_OR_ABOVE_THRESHOLD,
			criteriaValue: WorldDifficulty.Brutal,
			minimumTargetsThatSatisfy: 1,
		},
	],
}

const animationTimes = {
	[AnimationTrack.SHOOT]: 0.5,
}

const entBeard: EnemyAI = {
	name: ENEMY_NAMES.ENT_BEARD,
	type: EnemyType.BASIC,
	appearance: {
		asset: SpineDataName.ENT_BEARD,
		skin: EnemyBipedSkinType.DEFAULT,
		mixSettings: [
			{ from: AnimationTrack.IDLE, to: AnimationTrack.MOVEMENT, duration: 0.25 },
			{ from: AnimationTrack.MOVEMENT, to: AnimationTrack.IDLE, duration: 0.25 },
			{ from: AnimationTrack.IDLE, to: AnimationTrack.SHOOT, duration: 0.25 },
			{ from: AnimationTrack.SHOOT, to: AnimationTrack.MOVEMENT, duration: 0.25 },
		],
	},
	general: {
		enemyLevel: 1,
		experienceWhenKilled: 3,
	},
	baseAttributes: {
		...EnemyDefaults.baseAttributes,
		colliders: [
			{
				type: ColliderType.Circle,
				radius: 100,
				position: [0, -60],
				traits: ColliderTraits.BlockProjectile,
			},
			{
				type: ColliderType.Circle,
				radius: 100,
				position: [0, -140],
				traits: ColliderTraits.BlockProjectile,
			},
			{
				type: ColliderType.Ellipse,
				rX: 100,
				rY: 60,
				position: [0, -15],
				traits: ColliderTraits.BlockMovement,
			},
		],
		movementSpeed: 200,
		decelerationRate: 1.2,
		turningRatePerSecondInDegrees: 90,

		attackRate: 500,
		physicalDamage: 0,

		maxHealth: ENEMY_DEFAULT_HEALTH * 6,
		attackOffset: new Vector(450, 0),

		lootDropProbability: 100,
		lootDropQuantity: 5,
		lootDropRarity: 3,
	},
	soundEffects: {
		attack: 'Projectile_Zap',
		impact: 'SFX_Impact_Enemy_Generic',
	},
	states: {
		idling: {
			behaviour: IdleBehaviours.MEANDER,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			transitionToFighting: {
				trigger: FightingTriggers.AGGRESSIVE_PROXIMITY,
				radius: 900,
			},
			meander: {
				radius: 500,
				speedMultiplier: 1,
				minRestTime: 0.5,
				maxRestTime: 10,
			},
			aggroOnHit: true,
		},
		fighting: {
			behaviour: FightingBehaviours.CHASE_WHILE_ATTACKING,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			attackType: AttackTypes.PROJECTILE,
			engagementMaxDistance: 260,
			engagementMinDistance: 0,
			modelCenterOffset: 100,
			movementMaxDistance: 260,
			movementMinDistance: 0,
			brain: {
				actions: [
					{
						//  BLOCK/ATTACK
						priority: 0,
						actionCriteria: playerIsInShortRangeCriteriaT3,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							//abilityOptions: [[1, shortRangeAbility, 30]],
							abilityOptions: [attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, entSlap, 20)],
						},
					},
					{
						//  BLOCK/ATTACK
						priority: 1,
						actionCriteria: playerIsInLongRangeCriteriaT3,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							//abilityOptions: [[1, shortRangeAbility, 30]],
							abilityOptions: [attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, entCannon, 30)],
						},
					},
					{
						//  BLOCK/ATTACK
						priority: 2,
						actionCriteria: alwaysTrueCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							//abilityOptions: [[1, shortRangeAbility, 30]],
							abilityOptions: [attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, entSlap, 20)],
						},
					},
				],
			},
			attackOptions: [
				{
					...EnemyDefaults.projectileConfig,
					projectileCount: 1,
					spreadAngle: degToRad(90),
					speed: 0.1,
					// the melee animation of the Ent doesn't work well with targets above or below itself
					// needs either another animation, or a design configuration that leaves it vulnerable
					// in the Y axis
					colliderRadius: 210,
					lifespanInSeconds: 0.5,
					modifiers: [{ modType: ModType.STRAIGHT }],
					basePhysicalDamage: 300,
					burstCount: 0,
					color: Colors.purple,
					bulletParticleEffect: ParticleEffectType.PROJECTILE_NONE,
					bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
					muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_NONE,
				},
			],
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 0.5,
			transitionToLeashing: {
				trigger: LeashTriggers.DISTANCE_TRAVELLED_OR_RADIUS_EXCEEDED,
				radius: 2000,
				distanceTravelled: 6000,
			},
		},
		leashing: {
			behaviour: LeashingBehaviours.MOVE,
			speedMultiplier: 3,
		},
		fleeing: {},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 1,
		},
	},
}

export default entBeard
