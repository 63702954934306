import nengi from 'nengi'

class GameModeMessage {
	static protocol = {
		gameMode: { type: nengi.String },
	}

	gameMode: string

	constructor(gameMode) {
		this.gameMode = gameMode
	}
}

export default GameModeMessage
