import moment from 'moment'
import { debugConfig } from '../../engine/client/debug-config'
import { NengiClient } from '../../engine/client/nengi-client'
import { gameModeConfig } from '../../engine/shared/game-mode-configs'
import { GameModeType } from '../../engine/shared/game-mode-type'
import ClaimFactionPrizeCommand from '../../factions/shared/claim-faction-prize-command'
import { FactionShortName } from '../../factions/shared/faction-data'
import { getAllRewardsForContribution } from '../../factions/shared/faction-prize-data'
import SetFactionAffiliationCommand from '../../factions/shared/set-faction-affiliation-command'
import { anyRewardToMtxItems } from '../../items/shared/reward-types'
import { getAllFactionMembersCounts, getFactionMemberCount } from '../../utils/api/atlas-requests.client'
import { getFactionScores } from '../../utils/api/plutus-requests.client'
import logger from '../../utils/client-logger'
import { LAST_LOGGED_IN_CLIENT_KEY, loadFromLocalStorage, saveToLocalStorage } from '../../utils/local-storage'
import { UI } from '../ui'
import { showGenericInfoPromptUI } from './generic-information-prompt.ui-state'
import {showPfxAnimation, SpineAssetNames} from './slot-animation.ui-state'

let gotClaimResult = false

export enum FactionAnimations {
	KILLS = 'kills',
	POIS = 'pois',
	CHAMPIONS = 'champions',
	BOSSES = 'bosses',
	RARE_ITEMS = 'rareItems',
	DAMAGE_TAKEN = 'damageTaken',
	ENEMIES_IGNITED = 'enemiesIgnited',
	EPIC_ITEMS = 'epicItems',
	GENERIC_ACTIONS = 'GenericActions',
	FINAL_SCORE = 'finalScore',
}

export const factionsModule = () => {
    logger.debug('Initializing Factions module...')
    return {
        namespaced: true,
        state: {
            affiliatedFaction: null,
            lastAffiliatedFaction: null,
            affiliationMoment: null,
            lastWinningFaction: null,
            lastClaimPrizeMoment: null,

            partOfLastWinningFaction: false,

            aurumScore: 0,
            ironScore: 0,
            dawnScore: 0,

            aurumMembers: 0,
            ironMembers: 0,
            dawnMembers: 0,

            isJoiningFaction: false,

            lastFactionWarScore: 0,
            lastFactionWarTopPercent: 100,
            
            currentRecruitPanel: FactionShortName.SCIONS_OF_DAWN,
            showFactionRewardIntro: false,
            queueShowFactionRewardIntro: false,
            firstTimeShowFactionRewardIntro: false,

            scoreboardTimeRange: 'week',
            playerFactionPoints:  {},
            tallyAnimationsPlayed: false,
            tallyTotal: 0,

            showFactionBadge: false,
            showGenericFactionActionBadge: false,
            showFinalScoreBadge: false

        },
        getters: {
            currentlyWinningFaction(state) {
                if (state.aurumScore >= state.ironScore && state.aurumScore >= state.dawnScore) {
                    return FactionShortName.AURUM_ALLIANCE
                }

                if (state.ironScore >= state.aurumScore && state.ironScore >= state.dawnScore) {
                    return FactionShortName.ORDER_OF_IRON
                }

                return FactionShortName.SCIONS_OF_DAWN
            },
            factionTotalPoints(state) {
                return {
                    [FactionShortName.AURUM_ALLIANCE]: state.aurumScore,
                    [FactionShortName.ORDER_OF_IRON]: state.ironScore,
                    [FactionShortName.SCIONS_OF_DAWN]: state.dawnScore,
                }
            },
            factionMemberCounts(state) {
                return {
                    [FactionShortName.AURUM_ALLIANCE]: state.aurumMembers,
                    [FactionShortName.ORDER_OF_IRON]: state.ironMembers,
                    [FactionShortName.SCIONS_OF_DAWN]: state.dawnMembers,
                }
            },
            factionMemberCountsPercentage(state) {
                const aurumMembers = parseInt(state.aurumMembers, 10)
                const ironMembers = parseInt(state.ironMembers, 10)
                const dawnMembers = parseInt(state.dawnMembers, 10)

                const total = aurumMembers + ironMembers + dawnMembers

                return {
                    [FactionShortName.AURUM_ALLIANCE]: total === 0 ? 0 : Math.floor(aurumMembers / total * 100),
                    [FactionShortName.ORDER_OF_IRON]: total === 0 ? 0 : Math.floor(ironMembers / total * 100),
                    [FactionShortName.SCIONS_OF_DAWN]: total === 0 ? 0 : Math.floor(state.dawnMembers / total * 100),
                }
            },
            expectedRewards(state) {
                let earnedRep = state.lastFactionWarScore
                let topPercent = state.lastFactionWarTopPercent

                if (debugConfig.factions.noServerCheckFactionPrize) {
                    earnedRep = debugConfig.factions.forcedFactionRep
                    topPercent = debugConfig.factions.forcedFactionTopPercent
                }

                const allRewards = getAllRewardsForContribution(earnedRep, topPercent, state.lastWinningFaction)
                return anyRewardToMtxItems(allRewards)
            },
            lastFactionWarEndMoment(state, getters, rootState, rootGetters) {
                const env = process.env.NODE_ENV
                if(env === 'local' || env === 'alpha' || env === 'staging') {
                    return rootGetters['time/beginningOfDay']
                } else {
                    return rootGetters['time/beginningOfWeek']
                }
            }
        },
        mutations: {
            resetTallyAnimation(state){
                state.tallyAnimationsPlayed = false
                state.showFactionBadge = false,
                state.showGenericFactionActionBadge = false,
                state.showFinalScoreBadge = false
            },
            updateTallyTotal(state, tallyValue){
                state.tallyTotal = tallyValue
            },
            updateTallyAnimation(state){
                state.tallyAnimationsPlayed = true
                state.showFactionBadge = true,
                state.showGenericFactionActionBadge = true,
                state.showFinalScoreBadge = true
            },
            setInitialState(state, { affiliated, lastWinningFaction, factionScores, lastFactionWarScore }) {
                state.lastWinningFaction = lastWinningFaction

                if (affiliated) {
                    state.affiliatedFaction = affiliated.faction
                    state.affiliationMoment = moment(affiliated.affiliatedAt).utc()

                    state.lastAffiliatedFaction = affiliated.lastFaction

                    if (affiliated.lastClaimDate) {
                        state.lastClaimPrizeMoment = moment(affiliated.lastClaimDate).utc()
                    }
                }

                if(lastFactionWarScore) {
                    state.lastFactionWarScore = lastFactionWarScore.score
                    state.lastFactionWarTopPercent = lastFactionWarScore.topPercent
                }

                state.aurumScore = factionScores.aurum
                state.ironScore = factionScores.iron
                state.dawnScore = factionScores.dawn
            },
            setLastWinningFaction(state, lastWinningFaction) {
                state.lastWinningFaction = lastWinningFaction

                const uiInst = UI.getInstance()
                const lastFactionWarEndMoment = uiInst.store.getters['factions/lastFactionWarEndMoment']

                let loggedInThisWeek = false
                const userId = uiInst.store.getters['user/userId']
                const lastLoggedInString = loadFromLocalStorage(LAST_LOGGED_IN_CLIENT_KEY + userId)
                if (lastLoggedInString) {
                    try {
                        const lastLoggedInMomement = moment(lastLoggedInString).utc()
                        loggedInThisWeek = lastLoggedInMomement.isAfter(lastFactionWarEndMoment)
                    } catch (err) {
                        console.error(`Error in last logged in data ${lastLoggedInString} ${err}`)
                    }
                }

                // @TODO should probably have a system or something with this in a better place
                try {
                    saveToLocalStorage(LAST_LOGGED_IN_CLIENT_KEY + userId, moment().utc().toISOString())
                } catch (err) {
                    console.error(`Error saving last logged in data ${err}`)
                }

                if (lastWinningFaction && lastWinningFaction !== 'neutral') {
                    if ((state.lastAffiliatedFaction === lastWinningFaction && state.affiliationMoment.isAfter(lastFactionWarEndMoment)) || (state.affiliatedFaction === lastWinningFaction && state.affiliationMoment.isBefore(lastFactionWarEndMoment))) {
                        let canClaimPrizes = true

                        if (state.lastClaimPrizeMoment) {
                            canClaimPrizes = state.lastClaimPrizeMoment.isBefore(lastFactionWarEndMoment)
                            if (!canClaimPrizes) {
                                loggedInThisWeek = true // protect local storage clear showing the prizes, if you've claimed them already
                            }
                        }

                        if (canClaimPrizes) {
                            const rewards = uiInst.store.getters['factions/expectedRewards']
                            if(rewards.length > 0 || !loggedInThisWeek) {
                                state.partOfLastWinningFaction = true
                            }
                        }
                    }

                    const ftueGetFlag = uiInst.store.getters['ftueManager/getFlag']
                    // This flag salad is needed to make sure users on newly created accounts see the faction popup
                    const hasSeenWinningFactionPopUp = loadFromLocalStorage('hasSeenWinningFactionPopUp' + userId)
                    if (hasSeenWinningFactionPopUp) {
                        if (hasSeenWinningFactionPopUp.toLowerCase() === 'false'){
                            state.firstTimeShowFactionRewardIntro = true
                        } else {
                            state.firstTimeShowFactionRewardIntro = false
                        }
                    } else {
                        try {
                            saveToLocalStorage('hasSeenWinningFactionPopUp' + userId, 'false')
                        } catch (err) {
                            console.error(`Error saving faction winner popup data ${err}`)
                        }
                    }
                    
                    const inHub = gameModeConfig.type === GameModeType.Hub
                    if (ftueGetFlag('openedStashNPC') && inHub && (!loggedInThisWeek || state.firstTimeShowFactionRewardIntro)) {
                        state.queueShowFactionRewardIntro = true
                        state.firstTimeShowFactionRewardIntro = false
                        try {
                            saveToLocalStorage('hasSeenWinningFactionPopUp' + userId, 'true')
                        } catch (err) {
                            console.error(`Error saving faction winner popup data ${err}`)
                        }
                    }
                }
            },
            gotFactionClaimPrizeResult(state, message) {
                gotClaimResult = true
                if (message.claimed) {
                    showGenericInfoPromptUI('Cool', ['Claimed!'], 'OK', 'factionRewards')
                } else {
                    showGenericInfoPromptUI('Error', ['Could not claim prize, try again later'])
                }
            },
            setIsJoiningFaction(state, isJoining) {
                state.isJoiningFaction = isJoining
            },
            setAffiliatedFaction(state, faction) {
                state.lastAffiliatedFaction = state.affiliatedFaction
                state.affiliatedFaction = faction
                state.affiliationMoment = UI.getInstance().store.getters['time/nowMoment']
                state[faction + 'Members'] = state[faction + 'Members'] + 1
            },
            setScoreboardTimeRange(state, time) {
                state.factionScoreboardTimeRange = time
            },
            debugJustSetLastWinningFaction(state, faction) {
                state.lastWinningFaction = faction
            },
            setShowFactionRewardIntro(state, show) {
                state.showFactionRewardIntro = show
            },
            
        },
        actions: {
            joinFaction({ state }, faction) {
                if (!state.isJoiningFaction) {
                    state.isJoiningFaction = true
                    NengiClient.getInstance().sendCommand(new SetFactionAffiliationCommand(faction))
                }
            },
            async setCurrentRecruitPanel({ state }, faction) {
                state.currentRecruitPanel = faction
                state[faction + 'Members'] = await getFactionMemberCount(faction)
            },
            async getAllFactionMembers({ state }) {
                const members = await getAllFactionMembersCounts()
                state.aurumMembers = members.aurum
                state.ironMembers = members.iron
                state.dawnMembers = members.dawn
            },
            async updateFactionScores({ state }) {
                const scores = await getFactionScores()
                state.aurumScore = scores.aurum
                state.ironScore = scores.iron
                state.dawnScore = scores.dawn
            },
            checkShowFactionRewardIntro({state}) {
                if(state.queueShowFactionRewardIntro) {
                    state.showFactionRewardIntro = true
                    state.queueShowFactionRewardIntro = false
                }
            },
            setPlayerEndRunFactionPoints({state}, points){
                state.playerFactionPoints = points
            },
            skipFactionAnimations({commit}){
                commit('updateTallyAnimation')
            },
            async updateFactionBadge({state, dispatch}){
                showPfxAnimation(['progress-faction-Badge'], 7, SpineAssetNames.GENERIC_UNVEIL, undefined, 110, 110)
                state.showFactionBadge = true
            },
            async updateGenericFactionBadge({state, dispatch}){
                await showPfxAnimation(['progress-faction-action-Badge'], 7, SpineAssetNames.GENERIC_UNVEIL, undefined, 110, 110)
                state.showGenericFactionActionBadge = true
            },
            async updateFinalScoreBadge({state, dispatch}){
                await showPfxAnimation(['progress-faction-final-Badge'], 7, SpineAssetNames.GENERIC_UNVEIL, undefined, 110, 110)
                state.showFinalScoreBadge = true
            },
        },
    }
}

export async function claimFactionRewards() {
    gotClaimResult = false
    const factionDebug = debugConfig.factions
    NengiClient.getInstance().sendCommand(new ClaimFactionPrizeCommand(factionDebug.noServerCheckFactionPrize, factionDebug.forcedFactionWinner as FactionShortName, factionDebug.forcedFactionRep, factionDebug.forcedFactionTopPercent))

    const maxWaitTimeMs = 15_000

    const startOfWait = new Date().getTime()

    while (!gotClaimResult) {
        await new Promise(r => setTimeout(r, 200));
        const now = new Date().getTime()

        if(now - maxWaitTimeMs >= maxWaitTimeMs) {
            // something went wrong, bail out
            gotClaimResult = true
        }
    }
}
