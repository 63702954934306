import { Sprite, Texture, DisplayObject, Container, RenderTexture, LoaderResource, Point, Graphics, Text, Rectangle } from 'pixi.js'
import { BaseGround } from './base-ground'
import { Cliffs } from './cliffs'
import GradientRenderer from './gradient-renderer'
import { gameUnits } from '../../utils/primitive-types'
import { NengiClient } from '../../engine/client/nengi-client'
import Renderer from '../../engine/client/graphics/renderer'
import { WorldDataMessage } from '../shared/world-data-response-message'
import { Colors } from '../../utils/colors'
import { debugConfig } from '../../engine/client/debug-config'
import { AssetManager } from '../../asset-manager/client/asset-manager'
import logger from '../../utils/client-logger'
import { assert } from 'console'
import { getBiomeList } from '../../biome/shared/biome-list'
import { gameModeConfig } from '../../engine/shared/game-mode-configs'
import { END_OF_WORLD_ISLAND_BONUS_SIZE } from '../../engine/shared/game-data/end-of-world-config'
import { distanceSquared } from '../../utils/math'
import { worldStringTable } from '../shared/world-consts'

const logging = debugConfig.bgRender.logging

enum InitState {
	IDLE,
	START,
	END,
}

interface SpriteCacheEntry {
	sprite: Sprite
	alwaysOnTop: boolean
	refCount: number
}

interface SpriteTransform {
	x: gameUnits
	y: gameUnits
	rotation: number
	scale: number
	id: number
}

interface ChunkData {
	biome: number
	sprites: SpriteCacheEntry[]
	spriteTransforms: SpriteTransform[]
	blends: BlendDescription[]
	cliffs: CliffDescription[]
	complete: boolean
}

interface Bounds {
	minX: gameUnits
	minY: gameUnits
	maxX: gameUnits
	maxY: gameUnits
}
// Server Data is for Debug purposes

interface ServerSprites {
	biome: number
	spriteDescriptions: SpriteDescription[]
}

interface SpriteDescription {
	id: number
	index: number
	x: gameUnits
	y: gameUnits
	rotation: number
	scale: number
	alwaysOnTop: boolean
}

interface BlendDescription {
	biome1: number
	biome2: number
	idx: number
	x: gameUnits
	y: gameUnits
	rot: number
}

interface CliffDescription {
	biome: number
	idx: number
	blendIdx: number
	x: gameUnits
	y: gameUnits
	rot: number
}

let allRenderTargetsForDebugging: RenderTexture[] = new Array<RenderTexture>()
let lastRenderTargetsCount = 0

export default class BackgroundRenderer extends Container {
	// Statics
	static groundShaders: string[]
	static groundMixShaders: string[]
	static cliffShaders: string[]
	private rectangleGraphics: Graphics
	private rectangleGraphicsPrem: Graphics

	// Constants
	private readonly SCREEN_WIDTH: number = window.innerWidth
	private readonly SCREEN_HEIGHT: number = window.innerHeight
	private readonly HALF_SCREEN_WIDTH: number = this.SCREEN_WIDTH / 2
	private readonly HALF_SCREEN_HEIGHT: number = this.SCREEN_HEIGHT / 2
	private readonly TICKS_BETWEEN_CHUNKS: number = 1
	private readonly CHUNKS_BETWEEN_ACTIVE_INACTIVE: number = 1
	private WORLD_BOUNDS: Bounds
	private readonly CHUNK_SIZE: gameUnits = 512

	private chunkSize: gameUnits
	private totalChunks: number
	private activeChunksPerRow: number
	private activeChunksPerColumn: number

	// World data
	private lastWorldData: WorldDataMessage
	private atlases: LoaderResource[] = new Array<LoaderResource>()
	private cliffAtlases: LoaderResource[] = new Array<LoaderResource>()
	private groundSpritesCache: Map<string, SpriteCacheEntry> = new Map<string, SpriteCacheEntry>()
	private chunks: Map<string, ChunkData> = new Map<string, ChunkData>()
	private chunksToRebuild: Point[] = new Array<Point>()
	private activeRenderTargets: Map<string, RenderTexture> = new Map<string, RenderTexture>()
	private inactiveRenderTargets: Map<string, RenderTexture> = new Map<string, RenderTexture>()
	private unusedRenderTargets: RenderTexture[] = new Array<RenderTexture>()

	private activeBounds: Bounds = { minX: 0, minY: 0, maxX: 0, maxY: 0 }
	private inactiveBounds: Bounds = this.activeBounds
	private generateNewChunks: boolean = false
	private serverResponded: boolean = false
	private ticks: number = 0
	private chunksToGenerate: Set<string> = new Set<string>()

	private gameClientState: any
	private initState: InitState = InitState.IDLE

	private currPlayerPos: Point
	private lastPlayerPos: Point

	private baseGroundTextures: Texture[] = new Array<Texture>()
	private baseGroundSprites: Sprite[] = new Array<Sprite>()
	private mixTexture: Texture[] = new Array<Texture>()
	private biomeStamps: Texture[][] = new Array<Texture[]>()
	private cliffTextures: Texture[][] = new Array<Texture[]>()

	private gradient: GradientRenderer = new GradientRenderer()
	private pixiObjects: Container = new Container()

	// Debug
	private debug: boolean = debugConfig.bgRender.debug
	private failedChunkCount: number = 0

	constructor(gameClientState: any) {
		super()
		this.gameClientState = gameClientState
		NengiClient.getInstance().listen('message::WorldData', this.handleServerResponse.bind(this))

		this.pixiObjects.name = 'pixiObjects'
		this.addChild(this.pixiObjects)
		this.gradient.name = 'gradient'
		this.addChild(this.gradient)
	}

	createRenderTexture() {
		const rt: RenderTexture = RenderTexture.create({
			width: this.chunkSize,
			height: this.chunkSize,
			scaleMode: PIXI.SCALE_MODES.LINEAR,
			resolution: 1,
		})

		if (process.env.NODE_ENV === 'local') {
			allRenderTargetsForDebugging.push(rt)
			this.rtreport()
		}

		return rt
	}

	rtreport() {
		if (process.env.NODE_ENV === 'local') {
			const beforeFilter = allRenderTargetsForDebugging.length
			allRenderTargetsForDebugging = allRenderTargetsForDebugging.filter((rt2) => rt2.baseTexture && rt2.framebuffer)

			if (allRenderTargetsForDebugging.length >= lastRenderTargetsCount + 100) {
				lastRenderTargetsCount = allRenderTargetsForDebugging.length
				// logger.info(`rtcount:${allRenderTargetsForDebugging.length} (${beforeFilter})`)
			}
		}
	}

	setWorldSize(worldWidth: gameUnits, minWorldWidth: gameUnits, worldHeight: gameUnits, chunkSize: gameUnits, chunksPerRow: number, chunksPerColumn: number) {
		logger.debug(`setWorldSize:${worldWidth},${worldHeight}`)
		this.WORLD_BOUNDS = {
			minX: minWorldWidth,
			minY: 0,
			maxX: worldWidth - END_OF_WORLD_ISLAND_BONUS_SIZE,
			maxY: worldHeight,
		}

		this.totalChunks = chunksPerRow * chunksPerColumn * 2
		this.chunkSize = chunkSize
		this.activeChunksPerRow = chunksPerRow - this.CHUNKS_BETWEEN_ACTIVE_INACTIVE * 2
		this.activeChunksPerColumn = chunksPerColumn - this.CHUNKS_BETWEEN_ACTIVE_INACTIVE * 2

		for (let i = 0; i < this.totalChunks; ++i) {
			const rt = this.createRenderTexture()
			this.unusedRenderTargets.push(rt)
		}

		// Now that we know our chunk size we can build the default ground textures
		this.buildTextures()
	}

	handleWindowResize(newWidth: number, newHeight: number): void {
		const rowPadding = 3
		const columnPadding = 3
		const chunksPerRow = Math.ceil(newWidth / this.chunkSize) * rowPadding
		const chunksPerColumn = Math.ceil(newHeight / this.chunkSize) * columnPadding

		this.totalChunks = chunksPerRow * chunksPerColumn * 2
		this.activeChunksPerRow = chunksPerRow - this.CHUNKS_BETWEEN_ACTIVE_INACTIVE * 2
		this.activeChunksPerColumn = chunksPerColumn - this.CHUNKS_BETWEEN_ACTIVE_INACTIVE * 2

		this.rebuildGround()
	}

	debugDrawRect(topLeftX, topLeftY, width, height, matt, color, permanent = false) {
		const renderer: Renderer = Renderer.getInstance()
		if (!this.rectangleGraphics) {
			this.rectangleGraphics = new Graphics()
			renderer.debugMiddleground.addChild(this.rectangleGraphics)
			this.rectangleGraphicsPrem = new Graphics()
			renderer.debugMiddleground.addChild(this.rectangleGraphicsPrem)
		}
		const rectangleGraphics = permanent ? this.rectangleGraphicsPrem : this.rectangleGraphics
		rectangleGraphics.name = 'rect'
		rectangleGraphics.lineStyle(8, color)
		rectangleGraphics.drawRect(topLeftX + matt, topLeftY + matt, width - 2 * matt, height - 2 * matt)
	}

	drawBounds(bounds: Bounds, color) {
		this.debugDrawRect(bounds.minX, bounds.minY, bounds.maxX - bounds.minX, bounds.maxY - bounds.minY, 10, color)
	}

	clientSideGroundUpdate() {
		if (this.lastWorldData && this.currPlayerPos) {
			const lastPos = { x: Math.floor(this.lastPlayerPos.x / 512), y: Math.floor(this.lastPlayerPos.y / 512) }
			const curPos = { x: Math.floor(this.currPlayerPos.x / 512), y: Math.floor(this.currPlayerPos.y / 512) }
			if (lastPos.x !== curPos.x || lastPos.y !== curPos.y) {
				const worldData = this.lastWorldData
				const width = worldData.boundsMaxX - worldData.boundsMinX
				const height = worldData.boundsMaxY - worldData.boundsMinY
				worldData.boundsMinX = this.currPlayerPos.x - width * 0.5
				worldData.boundsMinY = this.currPlayerPos.y - height * 0.5
				worldData.boundsMaxX = this.currPlayerPos.x + width * 0.5
				worldData.boundsMaxY = this.currPlayerPos.y + height * 0.5
				this.handleServerResponse(worldData)
				this.lastPlayerPos.set(this.gameClientState.myEntity.x, this.gameClientState.myEntity.y)
			}
		}
	}

	update(delta: number): void {
		if (gameModeConfig.clientSideUpdateGroundPos) {
			this.clientSideGroundUpdate()
		}

		if (this.debug) {
			if (this.rectangleGraphics) {
				this.rectangleGraphics.clear()
			}

			this.drawBounds(this.inactiveBounds, Colors.yellow)
			this.drawBounds(this.inactiveBounds, Colors.green)

			this.chunks.forEach((chunkData, position) => {
				const pos: number[] = position.split(',').map(Number)
				this.debugDrawRect(pos[0], pos[1], 512, 512, 10, Colors.green)
			})

			this.activeRenderTargets.forEach((rt, position) => {
				const pos: number[] = position.split(',').map(Number)
				this.debugDrawRect(pos[0], pos[1], 512, 512, 30, Colors.orange)
			})

			this.inactiveRenderTargets.forEach((rt, position) => {
				const pos: number[] = position.split(',').map(Number)
				this.debugDrawRect(pos[0], pos[1], 512, 512, 40, Colors.darkBlue)
			})
		}

		if (this.WORLD_BOUNDS == null) {
			return
		}

		if (this.initState === InitState.IDLE) {
			if (this.gameClientState.myEntity != null) {
				this.initStart()
			}
		} else if (this.initState === InitState.END) {
			this.currPlayerPos.set(this.gameClientState.myEntity.x, this.gameClientState.myEntity.y)

			this.updateBounds()

			this.cleanUpChunks()

			this.chunks.forEach((chunkData, position) => {
				const pos: number[] = position.split(',').map(Number)
				const inActiveBounds = this.chunkInsideBounds(pos[0], pos[1], this.activeBounds)
				const inInactiveBounds = this.chunkInsideBounds(pos[0], pos[1], this.inactiveBounds)

				if (inInactiveBounds && !this.activeRenderTargets.has(position) && !this.inactiveRenderTargets.has(position)) {
					this.requestMakeChunk(pos[0], pos[1])
				}

				// if (inActiveBounds && !this.activeRenderTargets.has(position)) {
				// 	this.addChunkToStage(pos[0], pos[1])
				// }
			})

			this.activeRenderTargets.forEach((rt, position) => {
				const pos: number[] = position.split(',').map(Number)
				if (!this.chunkInsideBounds(pos[0], pos[1], this.activeBounds)) {
					this.removeChunkFromStage(pos[0], pos[1])
					this._validate()
				}
			})

			this.inactiveRenderTargets.forEach((rt, position) => {
				const pos: number[] = position.split(',').map(Number)
				if (!this.chunkInsideBounds(pos[0], pos[1], this.inactiveBounds)) {
					this.unusedRenderTargets.push(rt)
					this.inactiveRenderTargets.delete(position)
					this._validate()
				}

				if (this.chunkInsideBounds(pos[0], pos[1], this.activeBounds)) {
					this.addChunkToStage(pos[0], pos[1])
					this._validate()
				}
			})

			this.cleanUpChunks()

			if (this.generateNewChunks) {
				this.ticks--
				if (this.ticks <= 0) {
					const chunksPerFrame = 1

					const orderedChunks = Array.from(this.chunksToGenerate)
					// this sorts largest to smallest so we can pop the smallest
					orderedChunks.sort((a, b) => this.keyDistToPlayer(b) - this.keyDistToPlayer(a))

					for (let i = 0; i < chunksPerFrame && this.chunksToGenerate.size > 0;) {
						const key = orderedChunks.pop()
						this.chunksToGenerate.delete(key)
						const pos: number[] = key.split(',').map(Number)
						if (this.makeChunk(pos[0], pos[1])) {
							i++
						}
						this.ticks = this.TICKS_BETWEEN_CHUNKS
					}

					if (this.chunksToGenerate.size <= 0) {
						this.generateNewChunks = false
					}
				}
			}
		}

		if (this.chunksToRebuild.length > 0 && this.serverResponded) {
			this.serverResponded = false
			const chunkCount: number = this.chunksToRebuild.length
			for (let i = 0; i < chunkCount; ++i) {
				const chunk: Point = this.chunksToRebuild.shift()
				if (this.chunkInsideBounds(chunk.x, chunk.y, this.inactiveBounds)) {
					this.makeChunk(chunk.x, chunk.y, true)
				}
			}
		}

		this.gradient.update()

		assert(!(this.activeRenderTargets.size + this.inactiveRenderTargets.size + this.unusedRenderTargets.length < this.totalChunks), 'LESS RTs THAN ALLOWED CHUNKS')
		assert(!(this.activeRenderTargets.size + this.inactiveRenderTargets.size + this.unusedRenderTargets.length > this.totalChunks), 'MORE RTs THAN ALLOWED CHUNKS')
	}

	loadAtlases(): void {
		const assetManager = AssetManager.getInstance()

		const biomeList = getBiomeList(gameModeConfig.type)
		biomeList.forEach((biome) => {
			this.atlases.push(assetManager.getAssetByName(biome.groundAtlas))
			this.cliffAtlases.push(assetManager.getAssetByName(biome.cliffAtlas))
		})

		this.mixTexture.push(assetManager.getAssetByName('mixTextureCheckers').texture)
		this.mixTexture.push(assetManager.getAssetByName('mixTextureCorner').texture)
		this.mixTexture.push(assetManager.getAssetByName('mixTextureHalf').texture)
		this.mixTexture.push(assetManager.getAssetByName('mixTextureInvertedCorner').texture)

		this.gradient.loadAssets()

		if (BackgroundRenderer.groundShaders === undefined) {
			BackgroundRenderer.groundShaders = [assetManager.getAssetByName('baseGroundShaderVert').data, assetManager.getAssetByName('baseGroundShaderFrag').data]
			BackgroundRenderer.groundMixShaders = [assetManager.getAssetByName('baseGroundMixShaderVert').data, assetManager.getAssetByName('baseGroundMixShaderFrag').data]
			BackgroundRenderer.cliffShaders = [assetManager.getAssetByName('cliffShaderVert').data, assetManager.getAssetByName('cliffShaderFrag').data]
		}
	}

	resizeGradient(zoomLevel): void {
		this.gradient.resize(zoomLevel)
	}

	addPixiObjectToScene(dispObj: DisplayObject): void {
		this.pixiObjects.addChild(dispObj)
	}

	removePixiObjectFromScene(dispObj: DisplayObject): void {
		this.pixiObjects.removeChild(dispObj)
	}

	getGradientRenderer(): GradientRenderer {
		return this.gradient
	}

	shutdown() {
		// Remove all ground from the scene
		this.pixiObjects.removeChildren()

		// destroy all created RenderTexture
		this.rtreport()
		this.unusedRenderTargets.forEach((rt) => rt.destroy(true))
		this.activeRenderTargets.forEach((rt) => rt.destroy(true))
		this.inactiveRenderTargets.forEach((rt) => rt.destroy(true))

		this.rtreport()

		this.activeRenderTargets.clear()
		this.inactiveRenderTargets.clear()
		this.unusedRenderTargets = []
		this.chunksToGenerate.clear()
	}

	private rebuildGround(): void {
		this.shutdown()

		// Build the required amount of RTs
		for (let i = 0; i < this.totalChunks; ++i) {
			const rt = this.createRenderTexture()
			this.unusedRenderTargets.push(rt)
		}
	}

	private buildTextures() {
		// Now that are atlases are loaded, lets get all of the ground textures
		for (let i = 0; i < this.atlases.length; ++i) {
			this.biomeStamps.push(new Array<Texture>())
			const spineData: PIXI.spine.core.SkeletonData = this.atlases[i].spineData
			const spineAtlas: PIXI.spine.core.TextureAtlas = this.atlases[i].spineAtlas
			spineData.slots.forEach((slot) => {
				const region = spineAtlas.findRegion(slot.name)
				if (slot.name === 'grass_tile') {
					this.baseGroundTextures.push(region.texture)
					this.baseGroundSprites.push(new Sprite(region.texture))
				} else {
					this.biomeStamps[i].push(region.texture)
				}
			})
		}
		// Cliff textures
		for (let i = 0; i < this.cliffAtlases.length; ++i) {
			this.cliffTextures.push(new Array<Texture>())
			const spineData: PIXI.spine.core.SkeletonData = this.cliffAtlases[i].spineData
			const spineAtlas: PIXI.spine.core.TextureAtlas = this.cliffAtlases[i].spineAtlas
			spineData.slots.forEach((slot) => {
				const region = spineAtlas.findRegion(slot.name)
				this.cliffTextures[i].push(region.texture)
			})
		}
	}

	private initStart(): void {
		this.initState = InitState.START
		// Update our active world bounds to where the camera currently is
		const origin = Renderer.getInstance().mouseCoordinatesToWorldCoordinates(this.HALF_SCREEN_WIDTH, this.HALF_SCREEN_HEIGHT)
		origin.x = (Math.floor((origin.x - this.HALF_SCREEN_WIDTH) / this.chunkSize) - 1) * this.chunkSize
		origin.y = (Math.floor((origin.y - this.HALF_SCREEN_HEIGHT) / this.chunkSize) - 1) * this.chunkSize
		if (origin.x < this.WORLD_BOUNDS.minX) {
			origin.x = this.WORLD_BOUNDS.minX
		}
		if (origin.y < this.WORLD_BOUNDS.minY) {
			origin.y = this.WORLD_BOUNDS.minY
		}

		this.activeBounds = {
			minX: origin.x,
			minY: origin.y,
			maxX: origin.x + this.activeChunksPerRow * this.chunkSize,
			maxY: origin.y + this.activeChunksPerColumn * this.chunkSize,
		}

		if (this.generateNewChunks) {
			this.initFinish()
		}
	}

	private initFinish(): void {
		this.lastPlayerPos = new Point(this.gameClientState.myEntity.x, this.gameClientState.myEntity.y)
		this.currPlayerPos = new Point(this.gameClientState.myEntity.x, this.gameClientState.myEntity.y)
		this.initState = InitState.END
	}

	private updateBounds(): void {
		this.activeBounds.minX = this.inactiveBounds.minX + this.chunkSize
		this.activeBounds.minY = this.inactiveBounds.minY + this.chunkSize
		this.activeBounds.maxX = this.inactiveBounds.maxX - this.chunkSize
		this.activeBounds.maxY = this.inactiveBounds.maxY - this.chunkSize
	}

	private chunkInsideBounds(x: number, y: number, bounds: Bounds): boolean {
		if (x >= bounds.minX && x < bounds.maxX && y >= bounds.minY && y < bounds.maxY) {
			return true
		}
		return false
	}

	private requestMakeChunk(chunkX: number, chunkY: number, retry: boolean = false) {
		const key = `${chunkX},${chunkY}`
		if (this.chunkInsideBounds(chunkX, chunkY, this.WORLD_BOUNDS)) {
			if (!(this.inactiveRenderTargets.has(key) && this.activeRenderTargets.has(key))) {
				this.chunksToGenerate.add(key)
			}
		}
	}

	/** returns true if this created a chunk */
	private makeChunk(chunkX: number, chunkY: number, retry: boolean = false): boolean {
		const renderer = Renderer.getInstance()

		if (retry) {
			this.recycleChunkForRebuild(chunkX, chunkY)
		} else if (this.inactiveRenderTargets.has(`${chunkX},${chunkY}`) || this.activeRenderTargets.has(`${chunkX},${chunkY}`)) {
			// We've already made this chunk so don't try to make it again
			return false
		}

		if (!this.chunkInsideBounds(chunkX, chunkY, this.WORLD_BOUNDS)) {
			// Don't make chunks outside of the world bounds
			return false
		}

		const container: Container = new Container()
		container.name = 'chunkContainer'
		container.width = this.chunkSize
		container.height = this.chunkSize
		const chunk: ChunkData = this.chunks.get(`${chunkX},${chunkY}`)

		if (chunk === undefined || chunk.sprites === undefined) {
			if (logging) {
				//this.chunkScreenPrint(chunkX, chunkY, 'NO DATA FOR CHUNK', Colors.red)
				logger.warn(`NO DATA FOR CHUNK ${chunkX}, ${chunkY}`)
				if (this.debug) {
					this.debugDrawRect(chunkX, chunkY, 512, 512, 20, 0xff0000)
				}
			}
			return false
		} else {
			if (chunk.cliffs.length === 0) {
				// Always add the base just in case a chunk only has data due to bleed over from another chunk unless it's cliffs
				container.addChild(this.baseGroundSprites[chunk.biome])
			}

			let biomeBlend: boolean = false
			if (chunk.blends.length > 0) {
				for (let i = 0; i < chunk.blends.length; ++i) {
					let ground: BaseGround
					const blend = chunk.blends[i]

					if (blend.biome2 === -1 || blend.idx === -1) {
						ground = new BaseGround(BackgroundRenderer.groundShaders[0], BackgroundRenderer.groundShaders[1], [this.baseGroundTextures[blend.biome1]])
					} else {
						biomeBlend = true
						ground = new BaseGround(BackgroundRenderer.groundMixShaders[0], BackgroundRenderer.groundMixShaders[1], [this.baseGroundTextures[blend.biome1], this.baseGroundTextures[blend.biome2], this.mixTexture[blend.idx]], blend.rot)
					}
					ground.init()
					ground.addToContainer(container)

					ground.setPosition(blend.x - chunkX, blend.y - chunkY)
				}
			} else if (chunk.cliffs.length === 0) {
				const ground = new BaseGround(BackgroundRenderer.groundShaders[0], BackgroundRenderer.groundShaders[1], [this.baseGroundTextures[chunk.biome]])
				ground.init()
				ground.addToContainer(container)
			}

			for (let i = 0; i < chunk.cliffs.length; ++i) {
				const ithCliff = chunk.cliffs[i]
				let textureA = this.baseGroundTextures[ithCliff.biome]
				const textureB = this.cliffTextures[ithCliff.biome][ithCliff.idx]
				const blendTexture = this.cliffTextures[ithCliff.biome][ithCliff.blendIdx]
				for (const child of container.children) {
					if (biomeBlend && child.x === ithCliff.x - chunkX && (child.y === ithCliff.y - chunkY || child.y === ithCliff.y - chunkY - this.chunkSize / 2 || child.y === ithCliff.y - chunkY + this.chunkSize / 2)) {
						textureA = renderer.pixiRenderer.generateTexture(child, PIXI.SCALE_MODES.LINEAR, 1)
					}

					// Since we will be placing a cliff, remove anything ground that might be visible in the chunk if the cliffs y is offset
					if ((ithCliff.y < this.WORLD_BOUNDS.maxY / 2 && ithCliff.y - chunkY >= this.chunkSize / 2) || (ithCliff.y > this.WORLD_BOUNDS.maxY / 2 && ithCliff.y - chunkY < this.chunkSize / 2)) {
						container.removeChildren()
					}
				}

				// A cliff of height half chunk size needs to blends with a blended ground texture, however, the vertex position will be set to half chunk size
				// causing the wrong half of the blend texture to be sampled. To get around this we'll generate new ground texture dependant on whether it is the north
				// or south edge of the world
				if (biomeBlend && !(textureB.height === this.chunkSize && ithCliff.y - chunkY === 0)) {
					// NORTH EDGE - sample the upper half of the chunks ground texture so we'll copy
					// the upper half of the ground texture to the bottom half and make a new texture from the result
					if (ithCliff.y < this.WORLD_BOUNDS.maxY / 2 && ithCliff.y - chunkY <= 0) {
						const cont = new Container()
						cont.addChild(PIXI.Sprite.from(textureA))
						const tex = renderer.pixiRenderer.generateTexture(cont, PIXI.SCALE_MODES.LINEAR, 1, new Rectangle(0, 0, this.chunkSize, this.chunkSize / 2))
						cont.addChild(PIXI.Sprite.from(tex))
						cont.getChildAt(cont.children.length - 1).position.set(0, this.chunkSize / 2)
						textureA = renderer.pixiRenderer.generateTexture(cont, PIXI.SCALE_MODES.LINEAR, 1, new Rectangle(0, 0, this.chunkSize, this.chunkSize))
					}
					// SOUTH EDGE - sample the bottom half of the chunks ground texture so we'll copy
					// the top bottom of the ground texture to the upper half and make a new texture from the result
					else if (ithCliff.y > this.WORLD_BOUNDS.maxY / 2 && ithCliff.y - chunkY > 0) {
						const cont = new Container()
						cont.addChild(PIXI.Sprite.from(textureA))
						const tex = renderer.pixiRenderer.generateTexture(cont, PIXI.SCALE_MODES.LINEAR, 1, new Rectangle(0, this.chunkSize / 2, this.chunkSize, this.chunkSize / 2))
						cont.addChild(PIXI.Sprite.from(tex))
						textureA = renderer.pixiRenderer.generateTexture(cont, PIXI.SCALE_MODES.LINEAR, 1, new Rectangle(0, 0, this.chunkSize, this.chunkSize))
					}
				}

				const cliff: Cliffs = new Cliffs(BackgroundRenderer.cliffShaders[0], BackgroundRenderer.cliffShaders[1], [textureA, textureB, blendTexture], ithCliff.y - chunkY, chunk.cliffs[i].rot)
				cliff.init()
				cliff.addToContainer(container)
			}

			const onTopSprites: Sprite[] = []
			for (let i = 0; i < chunk.sprites.length; ++i) {
				const spriteX = chunk.spriteTransforms[i].x
				const spriteY = chunk.spriteTransforms[i].y
				const sprite: Sprite = PIXI.Sprite.from(chunk.sprites[i].sprite.texture)
				if (chunk.sprites[i].alwaysOnTop) {
					onTopSprites.push(sprite)
				} else {
					container.addChild(sprite)
				}
				sprite.position.set(spriteX - chunkX, spriteY - chunkY)
				sprite.scale.set(chunk.spriteTransforms[i].scale)
				sprite.rotation = chunk.spriteTransforms[i].rotation
				if (!chunk.complete) {
					if (!this.chunksToRebuild.some((point) => point.x === chunkX && point.y === chunkY)) {
						this.chunksToRebuild.push(new Point(chunkX, chunkY))
					}
				}
			}

			for (const sprite of onTopSprites) {
				container.addChild(sprite)
			}
		}

		if (this.unusedRenderTargets.length === 0) {
			logger.warn('EMERGENCY NO UNUSED RTs LEFT!!!')
			//this.chunkScreenPrint(chunkX, chunkY, 'NO UNUSED RTs LEFT', Colors.red)
			this.failedChunkCount++
			return false
		}

		const rt: RenderTexture = this.unusedRenderTargets.shift()
		if (rt === undefined) {
			logger.warn('EMERGENCY TRYING TO CREATE CHUNK WITH UNDEFINED RT!!!')
			//this.chunkScreenPrint(chunkX, chunkY, 'UNDEFINED RT', Colors.red)
			return false
		}
		renderer.pixiRenderer.render(container, rt)
		container.destroy()
		this.inactiveRenderTargets.set(`${chunkX},${chunkY}`, rt)
		this._validate()

		return true
	}

	private recycleChunkForRebuild(chunkX: number, chunkY: number): void {
		//this.chunkScreenPrint(chunkX, chunkY, 'recycle', Colors.white)
		if (this.inactiveRenderTargets.has(`${chunkX},${chunkY}`)) {
			const rendTex: RenderTexture = this.inactiveRenderTargets.get(`${chunkX},${chunkY}`)
			this.unusedRenderTargets.push(rendTex)
			this.inactiveRenderTargets.delete(`${chunkX},${chunkY}`)
		} else if (this.activeRenderTargets.has(`${chunkX},${chunkY}`)) {
			const rendTex: RenderTexture = this.activeRenderTargets.get(`${chunkX},${chunkY}`)
			this.unusedRenderTargets.push(rendTex)
			this.activeRenderTargets.delete(`${chunkX},${chunkY}`)
		}
	}

	private addChunkToStage(chunkX: number, chunkY: number): void {
		if (this.activeRenderTargets.get(`${chunkX},${chunkY}`)) {
			// We've already added this chunk so don't try to add it again
			return
		}

		if (!this.chunkInsideBounds(chunkX, chunkY, this.WORLD_BOUNDS)) {
			// Don't add chunks outside of the world bounds
			return
		}
		const rt: RenderTexture = this.inactiveRenderTargets.get(`${chunkX},${chunkY}`)
		if (rt === undefined) {
			//this.chunkScreenPrint(chunkX, chunkY, 'TRYING TO ADD CHUNK THAT HASN\'T BEEN MADE!!!`', Colors.red)
			logger.warn(`TRYING TO ADD CHUNK ${chunkX}, ${chunkY} THAT HASN'T BEEN MADE!!!`)
			return
		}

		if (this.debug) {
			//this.chunkScreenPrint(chunkX, chunkY, 'addChunkToStage', Colors.white)

			const container: Container = new Container()
			container.position.set(chunkX, chunkY)
			const chunkText = new Text(`CHUNK: x${chunkX} y${chunkY}`, {
				fontSize: 12,
				fill: '#ffffff',
				align: 'left',
			})
			chunkText.position.set(this.chunkSize / 2, this.chunkSize / 2)
			const sprite: Sprite = new Sprite(rt)
			container.name = `rt-chunk(${Math.floor(chunkX / this.CHUNK_SIZE)}, ${Math.floor(chunkY / this.CHUNK_SIZE)})`
			container.addChild(sprite)
			container.addChild(chunkText)
			this.addPixiObjectToScene(container)
		} else {
			const sprite: Sprite = new Sprite(rt)
			sprite.name = `rt-chunk(${Math.floor(chunkX / this.CHUNK_SIZE)}, ${Math.floor(chunkY / this.CHUNK_SIZE)})`
			sprite.position.set(chunkX, chunkY)
			this.addPixiObjectToScene(sprite)
		}
		this.activeRenderTargets.set(`${chunkX},${chunkY}`, rt)
		this.inactiveRenderTargets.delete(`${chunkX},${chunkY}`)
		this._validate()
	}

	private _validate() {
		this.activeRenderTargets.forEach((rt, position) => {
			const isInactive = this.inactiveRenderTargets.has(position)
			console.assert(!isInactive, `${position} INVALID!!!`)
			if (isInactive) {
				const pos: number[] = position.split(',').map(Number)
				//this.chunkScreenPrint(pos[0], pos[1], 'INVALID!!!', Colors.red)
				this._validate = function() { } // one assertion is fine
			}
		})
	}

	private removeChunkFromStage(chunkX: number, chunkY: number): void {
		//this.chunkScreenPrint(chunkX, chunkY, 'removeChunkFromStage', Colors.white)
		const sprites: DisplayObject[] = this.pixiObjects.children.filter((sprite) => sprite.x === chunkX && sprite.y === chunkY)
		for (const sprite of sprites) {
			this.removePixiObjectFromScene(sprite)
			sprite.destroy()
		}

		if (this.inactiveRenderTargets.get(`${chunkX},${chunkY}`)) {
			// We've already removed this chunk so don't try to remove it again
			return
		}
		if (!this.chunkInsideBounds(chunkX, chunkY, this.WORLD_BOUNDS)) {
			// Don't remove chunks outside of the world bounds
			return
		}

		// Recycle renter texture and clean up chunk data and sprite cache
		const rt: RenderTexture = this.activeRenderTargets.get(`${chunkX},${chunkY}`)
		if (rt === undefined) {
			logger.warn(`EMERGENCY TRYING TO RECYCLE CHUNK ${chunkX}, ${chunkY} WITH UNDEFINED RT!!!`)
			return
		}
		this.inactiveRenderTargets.set(`${chunkX},${chunkY}`, rt)
		this.activeRenderTargets.delete(`${chunkX},${chunkY}`)
	}

	private cleanUpChunks(): void {
		if (gameModeConfig.clientSideUpdateGroundPos) {
			return
		}

		// Put any RTs that don't need to be in the inactive buffer to the unused buffer
		// this.inactiveRenderTargets.forEach((renderTexture, position) => {
		// 	const pos: number[] = position.split(',').map(Number)
		// 	if (pos[0] < this.inactiveBounds.minX || pos[0] >= this.inactiveBounds.maxX || pos[1] < this.inactiveBounds.minY || pos[1] >= this.inactiveBounds.maxY) {
		// 		//this.chunkScreenPrint(pos[0], pos[1], "-RT", Colors.white)
		// 		this.unusedRenderTargets.push(renderTexture)
		// 		this.inactiveRenderTargets.delete(position)
		// 	}
		// })

		// Remove chunk data and update sprite cache
		this.chunks.forEach((data, position) => {
			const pos: number[] = position.split(',').map(Number)
			if (!this.chunkInsideBounds(pos[0], pos[1], this.inactiveBounds)) {
				//this.chunkScreenPrint(pos[0], pos[1], '-chunk data', Colors.white)
				data.sprites.forEach((sprite) => {
					sprite.refCount--
					if (sprite.refCount === 0) {
						// Rmove from sprite cache
						let spriteKey: string
						for (const [key, value] of this.groundSpritesCache.entries()) {
							if (value === sprite) {
								spriteKey = key
								break
							}
						}
						if (spriteKey !== undefined) {
							this.groundSpritesCache.delete(spriteKey)
						}
					}
				})
				this.chunks.delete(position)
			}
		})
	}

	private handleServerResponse(data: WorldDataMessage): void {
		this.lastWorldData = data

		const stringTable = worldStringTable

		this.inactiveBounds.minX = data.boundsMinX
		this.inactiveBounds.minY = data.boundsMinY
		this.inactiveBounds.maxX = data.boundsMaxX
		this.inactiveBounds.maxY = data.boundsMaxY

		let stamps: ServerSprites[] = []

		let blends: BlendDescription[] = []

		let cliffs: CliffDescription[] = []

		if (data.cliffSprites) {
			cliffs = JSON.parse(data.cliffSprites) as CliffDescription[]
		}

		const bounds: Bounds = { minX: data.minX, minY: data.minY, maxX: data.maxX, maxY: data.maxY }

		if (data.stampSprites_x) {
			stamps = []

			const biomeIsArray = data.stampSprites_biome instanceof Array

			for (let i = 0; i < data.stampSprites_x.length; i++) {
				const biome = i < data.stampSprites_biome.length ? data.stampSprites_biome[i] : data.stampSprites_biome[0]
				const scale = i < data.stampSprites_scale.length ? data.stampSprites_scale[i] : data.stampSprites_scale[0]
				const rotation = i < data.stampSprites_rotation.length ? data.stampSprites_rotation[i] : data.stampSprites_rotation[0]

				while (stamps.length <= biome) {
					stamps.push({ biome, spriteDescriptions: [] })
				}

				stamps[biome].spriteDescriptions.push({
					x: data.stampSprites_x[i],
					y: data.stampSprites_y[i],
					index: data.stampSprites_index[i],
					rotation: rotation,
					scale: scale,
					id: data.stampSprites_id[i],
					alwaysOnTop: data.stampSprites_alwaysOnTop[i],
				})
			}
		}

		// for now blends can get sent as JSON (blendSprites) or
		//  as parallel arrays(blendSprites_biome1, blendSprites_biome2, el al.)
		// I'm keeping blendSprites for quicker iteration and also for comparing optimizations
		if (data.blendSprites_biome1) {
			blends = []
			for (let i = 0; i < data.blendSprites_biome1.length; i++) {
				blends.push({
					biome1: data.blendSprites_biome1[i],
					biome2: data.blendSprites_biome2[i],
					idx: data.blendSprites_idx[i],
					x: data.blendSprites_x[i],
					y: data.blendSprites_y[i],
					rot: data.blendSprites_rot[i],
				})
			}
		}

		for (let i = 0; i < stamps.length; ++i) {
			const sprites: SpriteDescription[] = stamps[i].spriteDescriptions
			for (let j = 0; j < sprites.length; ++j) {
				// Make Sprite Entry
				// TEMP HACK - If we are asking for a biome greater than our atlas count default it to the last one
				if (stamps[i].biome >= this.biomeStamps.length) {
					stamps[i].biome = this.biomeStamps.length - 1
				}
				const texName: string = stringTable[sprites[j].index]
				let spriteEntry: SpriteCacheEntry = this.groundSpritesCache.get(`${stamps[i].biome},${texName}`)
				if (spriteEntry === undefined) {
					const sprite: Sprite = new Sprite(this.biomeStamps[stamps[i].biome][sprites[j].index])
					spriteEntry = { sprite, alwaysOnTop: sprites[j].alwaysOnTop, refCount: 0 }
					this.groundSpritesCache.set(`${stamps[i].biome},${texName}`, spriteEntry)
				}
				// Determine which chunks the sprite is in
				const transform: SpriteTransform = {
					x: sprites[j].x,
					y: sprites[j].y,
					rotation: sprites[j].rotation,
					scale: sprites[j].scale,
					id: sprites[j].id,
				}
				this.organizeIntoChunk(transform, bounds, stamps[i].biome, spriteEntry)
			}
		}

		for (let i = 0; i < blends.length; ++i) {
			const transform: SpriteTransform = {
				x: blends[i].x,
				y: blends[i].y,
				rotation: blends[i].rot,
				scale: 1,
				id: -1,
			}
			this.organizeIntoChunk(transform, bounds, blends[i].biome1, undefined, blends[i])
		}

		for (let i = 0; i < cliffs.length; ++i) {
			const transform: SpriteTransform = {
				x: cliffs[i].x,
				y: cliffs[i].y,
				rotation: cliffs[i].rot,
				scale: 1,
				id: -1,
			}
			this.organizeIntoChunk(transform, bounds, cliffs[i].biome, undefined, undefined, cliffs[i])
		}

		this.serverResponded = true
		this.generateNewChunks = true
		this.ticks = this.TICKS_BETWEEN_CHUNKS

		if (this.initState === InitState.START) {
			this.initFinish()
			return
		}

		if (this.initState === InitState.END) {
			const xSteps = (bounds.maxX - bounds.minX) / this.chunkSize
			const ySteps = (bounds.maxY - bounds.minY) / this.chunkSize
			for (let i = 0; i < xSteps; ++i) {
				for (let j = 0; j < ySteps; ++j) {
					this.requestMakeChunk(bounds.minX + i * this.chunkSize, bounds.minY + j * this.chunkSize)
				}
			}
		}
	}

	private organizeIntoChunk(spriteTransform: SpriteTransform, bounds: Bounds, biome: number, spriteEntry?: SpriteCacheEntry, blend?: BlendDescription, cliff?: CliffDescription): void {
		let xSteps: number = 0
		let ySteps: number = 0
		const chunkSize = this.chunkSize
		const oneOverChunkSize = 1 / chunkSize

		const minChunkX: number = Math.floor(spriteTransform.x * oneOverChunkSize) * chunkSize
		const minChunkY: number = Math.floor(spriteTransform.y * oneOverChunkSize) * chunkSize
		if (spriteEntry !== undefined) {
			const maxChunkX: number = Math.floor((spriteTransform.x + spriteEntry.sprite.width * spriteTransform.scale) * oneOverChunkSize) * chunkSize
			const maxChunkY: number = Math.floor((spriteTransform.y + spriteEntry.sprite.height * spriteTransform.scale) * oneOverChunkSize) * chunkSize
			xSteps = (maxChunkX - minChunkX) * oneOverChunkSize
			ySteps = (maxChunkY - minChunkY) * oneOverChunkSize
		} else if (cliff !== undefined) {
			const maxChunkY: number = Math.floor((cliff.y + this.cliffTextures[cliff.biome][cliff.idx].height - 1) * oneOverChunkSize) * chunkSize
			ySteps = (maxChunkY - minChunkY) * oneOverChunkSize
		}

		for (let x = 0; x <= xSteps; ++x) {
			for (let y = 0; y <= ySteps; ++y) {
				const chunkX: number = minChunkX + x * chunkSize
				const chunkY: number = minChunkY + y * chunkSize
				const chunkKey = `${chunkX},${chunkY}`
				let chunk: ChunkData = this.chunks.get(chunkKey)
				if (chunk === undefined) {
					chunk = {
						biome,
						sprites: new Array<SpriteCacheEntry>(),
						spriteTransforms: new Array<SpriteTransform>(),
						blends: new Array<BlendDescription>(),
						cliffs: new Array<CliffDescription>(),
						complete: false,
					}

					this.chunks.set(chunkKey, chunk)
					//this.chunkScreenPrint(chunkX, chunkY, `+chunk data ${chunkX},${chunkY}`, Colors.white)
				}

				if (!this.chunkHasSprite(chunk, spriteTransform)) {
					if (blend !== undefined) {
						chunk.biome = biome
						chunk.blends.push(blend)
					} else if (cliff !== undefined) {
						if (!this.chunkHasCliff(chunk, cliff)) {
							chunk.biome = biome
							chunk.cliffs.push(cliff)
						}
					} else {
						chunk.sprites.push(spriteEntry)
						chunk.spriteTransforms.push(spriteTransform)
						spriteEntry.refCount++
					}
				}

				// Check to see if the chunk is in the requested bounds, if it mark it as complete
				if (this.chunkInsideBounds(chunkX, chunkY, bounds)) {
					chunk.complete = true
				}
			}
		}
	}

	private chunkHasSprite(chunk: ChunkData, transform: SpriteTransform): boolean {
		for (let i = 0; i < chunk.sprites.length; ++i) {
			if (chunk.spriteTransforms[i].id === transform.id) {
				return true
			}
		}
		return false
	}

	private chunkHasCliff(chunk: ChunkData, cliff: CliffDescription): boolean {
		for (let i = 0; i < chunk.cliffs.length; ++i) {
			if (chunk.cliffs[i].x === cliff.x && chunk.cliffs[i].y === cliff.y) {
				return true
			}
		}
		return false
	}

	private keyDistToPlayer(key: string) {
		const pos = keyToPosition(key)
		const playerPos = this.currPlayerPos
		return distanceSquared(playerPos.x, playerPos.y, pos[0], pos[1])
	}

	// private chunkScreenPrint(chunkX: number, chunkY: number, s: string, color: number) {
	// 	DebugScreenPrint(chunkX, chunkY, s, color, true)
	// }
}

function keyToPosition(key) {
	return key.split(',').map(Number)
}
