














export default {
	name: 'ValueButtons',
	components: {},
	props: {
		leftIconString: {
			type: String,
			required: false,
			default: '-',
		},
		rightIconString: {
			type: String,
			required: false,
			default: '+',
		},
		valueString: {
			type: String,
			required: false,
			default: '0',
		},
		onLeftButtonClick: {
			type: Function,
			required: true,
		},
		onRightButtonClick: {
			type: Function,
			required: true,
		},
	},
	methods: {},
}
