export enum ENEMY_NAMES {
	ASP = 'Asp',
	BASIC = 'Basic', //old
	BEACH_BOSS = 'The B.F.C.',
	BEE = 'Bee',
	BLIMPIE = 'Blimpie',
	BONE_SPIRIT = 'Bone Spirit',
	BOSS = 'Boss', //old
	CRAB_NEST_A = 'crabNestA', //shouldn't ever be shown to player, so nest names don't matter
	CRAB_NEST_B = 'crabNestB',
	CRAB_NEST_C = 'crabNestC',
	CRAB_NEST_D = 'crabNestD',
	CRAB_NEST_E = 'crabNestE',
	CRAB_NEST_F = 'crabNestF',
	CRAB = 'Crab',
	CRAB_TOP_HAT = 'Top Hat Crab',
	CRYSTAL_SPIDER = 'Crystal Spider',
	DEATH_DRAKE = 'Death Drake',
	DISTANCER = 'Distancer', //old
	DRAGON_SKULL = 'Dragon Skull',
	DREAMER_FUNGI = 'Fungi Dreamer',
	DREAMER_PRISM = 'Prism Dreamer',
	DREAMER_FANCY = 'Fancy Dreamer',
	ENT = 'Ent',
	ENT_BEARD = 'Bearded Ent',
	EYEBAT = 'Eye-Bat',
	FOREST_BOSS = 'The Fearie Queen',
	FUNGI_BOSS = 'The Rotfather',
	FUNGI_WOLF = 'Fungi Wolf',
	GOBLIN = 'Goblin',
	GOBLIN_JESTER = 'Jester Goblin',
	GOLEM_HIGHLANDS = 'Highlands Golem',
	GOLEM_PRISM = 'Prism Golem',
	HERMIT_CRAB = 'Hermit Crab',
	HIGHLANDS_BOSS = 'The Gorgon',
	MOSQUITO = 'Mosquito',
	MUSHIE = 'Mushie',
	PRISM_BOSS = 'The Mirrored Dragon',
	PRISM_WISP = 'Prism Wisp',
	PRISMFLY = 'Prismfly',
	SHAMBLING_MOUND = 'Shambling Mound',
	SHAMBLING_TOWER = 'Shambling Tower',
	SHRIEKER = 'Shrieker',
	SHRIEKER_VINES = 'Vine Shrieker',
	SKELETAL_WHELP = 'Skeletal Whelp',
	SKELETAL_WHELP_HATCHED = 'Hatched Skeletal Whelp',
	SKELETON_BEACH = 'Beach Skeleton',
	SKELETON_KNIGHT = 'Skeleton Knight',
	SKELETON_KNIGHT_BALLOON = 'Balloon Knight',
	SLIVER_PRISM_BOSS_MINION = 'Sliver Minion',
	SLIVER = 'Sliver',
	SLIVER_CRACKED = 'Cracked Sliver',
	SPAWNER = 'Spawner', //old
	SPIDER = 'Spider',
	SPORE_KID = 'Spore Kid',
	SQUISHIE = 'Squishie', //old
	STRAFER = 'Strafer', //old
	STRANGLEVINE = 'Stranglevine',
	THORN_WOLF = 'Thorn Wolf',
	THORN_WOLF_CONE = 'Cone Thorn Wolf',
	TRAINING_DUMMY = 'Training Dummy',
	WASP_FOREST = 'Forest Wasp',
	WASP = 'Wasp',
	WEAK_TINY_CRAB = 'Tiny Crab',
	WISP = 'Wisp',
	DUMMY_A = 'Training Dummy A',
	DUMMY_B = 'Training Dummy B',
	DUMMY_C = 'Training Dummy C',
	DUMMY_D = 'Training Dummy D',
	DUMMY_E = 'Training Dummy E',
	DUMMY_F = 'Training Dummy F',
	DUMMY_G = 'Training Dummy G',
	DUMMY_H = 'Training Dummy H',
	// Destructible props -- Names not shown to player
	CRAB_BARREL = 'crabBarrel',
	MERMAID_MAST = 'mermaidMast',
	LANTERN_POST = 'lanternPost',
	TANNING_RACK = 'tanningRack',
	FUNGI_SKELETON = 'fungiSkeleton',
	SPORE_SACK = 'sporeSack',
	MASSIVE_SKULL_PILE = 'massiveSkullPile',
	MYSTERIOUS_GEM  = 'mysteriousGem',
	CORRUPTED_LAMP = 'corruptedLamp',
	CRYSTAL_HAND = 'crystalHand',

}

export const ALL_ENEMY_NAMES_VALUES = Object.values(ENEMY_NAMES)
export const BOSS_ENEMY_NAMES = [ENEMY_NAMES.BEACH_BOSS, ENEMY_NAMES.FOREST_BOSS, ENEMY_NAMES.HIGHLANDS_BOSS, ENEMY_NAMES.FUNGI_BOSS, ENEMY_NAMES.PRISM_BOSS]
export const NORMAL_ENEMY_NAMES = ALL_ENEMY_NAMES_VALUES.filter((name) => !BOSS_ENEMY_NAMES.includes(name))
