import { Graphics, Polygon, Text, Container } from 'pixi.js'
import { Vector, Polygon as PolygonSat } from 'sat'
import Renderer from '../../engine/client/graphics/renderer'
import { ObjectPool } from '../../third-party/object-pool'
import { UpdatableContainer } from '../../world/client/middleground-renderer'
import { CircleColliderConfig, ColliderConfig, colliderDebugColor, EllipseColliderConfig, BoxColliderConfig, PolygonColliderConfig, ColliderType, AnyCollider, CircleCollider, BoxCollider } from '../../collision/shared/colliders'
import { add, makeTangent, sub, VectorXY, withinDistanceVXY } from '../../utils/math'
import { debugConfig } from '../../engine/client/debug-config'
import { timeInSeconds } from '../../utils/primitive-types'
import { RiggedSpineModel } from '../../models-animations/client/spine-model'
import { NengiClient } from '../../engine/client/nengi-client'
import InspectNearestEnemyCommand from '../shared/inspect-nearest-entity-command'
import { debugToggles } from '../../utils/debug'
import ChatCommand from '../../chat/shared/chat-command'
import { get, set } from 'lodash'

export const clientDebug = {
	drawCircle(p: VectorXY, r: number, color: number, permanent = false, destroyAfterSeconds = 0.1, scale = 1) {
		Renderer.getInstance().drawCircle({ x: p.x, y: p.y, radius: r, color, permanent, destroyAfterSeconds, scale })
	},

	drawLine(p1: VectorXY, p2: VectorXY, color: number, permanent = false, destroyAfterSeconds = 0.1) {
		Renderer.getInstance().drawLine({ sourceX: p1.x, sourceY: p1.y, destX: p2.x, destY: p2.y, color, permanent, destroyAfterSeconds })
	},

	drawRectangle(x, y, w: number, h: number, color: number, permanent = false, destroyAfterSeconds = 0.1, scale = 1, rotation = 0, lineWidth = 2) {
		Renderer.getInstance().drawRectangle({ topLeftX: x, topLeftY: y, width: w, height: h, color, permanent, destroyAfterSeconds, scale, rotation, lineWidth })
	},

	drawPolygon(polygon: PolygonSat, color: number, permanent = false, destroyAfterSeconds = 0.1, scale = 1) {
		Renderer.getInstance().drawPolygon({ points: polygon.calcPoints, color, permanent, destroyAfterSeconds, scale })
	},

	drawCapsule(p1: VectorXY, p2: VectorXY, cr: number, color: number, permanent = false, destroyAfterSeconds: number = 0.1, scale = 1) {
		const dir = sub(p2, p1).normalize()
		makeTangent(dir)
		dir.scale(cr)
		clientDebug.drawLine(add(p1, dir), add(p2, dir), color, permanent, destroyAfterSeconds)
		clientDebug.drawLine(sub(p1, dir), sub(p2, dir), color, permanent, destroyAfterSeconds)
		clientDebug.drawCircle(p1, cr, color, permanent, destroyAfterSeconds, scale)
		clientDebug.drawCircle(p2, cr, color, permanent, destroyAfterSeconds, scale)
	},

	drawText(text: string, p: VectorXY, color: number, permanent = false, destroyAfterSeconds = 0.1, scale = 1) {
		Renderer.getInstance().drawText({ text, x: p.x, y: p.y, color, permanent, destroyAfterSeconds, scale })
	},

	drawTextDirect(text: string, p: VectorXY, color: number, permanent = false, destroyAfterSeconds = 0.1, parentContainer: Container) {
		Renderer.getInstance().drawTextDirect(text, p.x, p.y, color, permanent, destroyAfterSeconds, parentContainer)
	},

	debugDrawShape(shape: AnyCollider, color: number, permanent = false, destroyAfterSeconds = 0.1, scale = 1) {
		if (shape instanceof CircleCollider) {
			clientDebug.drawCircle(shape.pos, shape.r, color, permanent, destroyAfterSeconds, scale)
		}
		if (shape instanceof BoxCollider) {
			clientDebug.drawRectangle(shape.pos.x, shape.pos.y, shape.w, shape.h, color, permanent, destroyAfterSeconds, scale)
		}
	}
}

export const drawColliderVisuals = function(colliders: ColliderConfig[]) {
	const colliderVisual = new Graphics()
	colliderVisual.name = 'col-vis'
	colliders?.forEach((colliderConfig) => {
		const childVisual = new Graphics()
		childVisual.lineStyle(2, colliderDebugColor(colliderConfig.traits))

		colliderVisual.addChild(childVisual)
		childVisual.position.set(colliderConfig.position[0], colliderConfig.position[1])
		childVisual.name = 'col-vis'
		childVisual.rotation = colliderConfig.angle ?? 0
		switch (colliderConfig.type) {
			case ColliderType.Circle: {
				const circleConfig = colliderConfig as CircleColliderConfig
				const rad = circleConfig.radius
				childVisual.drawCircle(0, 0, rad)
				break
			}
			case ColliderType.Ellipse: {
				const ellipseConfig = colliderConfig as EllipseColliderConfig
				childVisual.drawEllipse(0, 0, ellipseConfig.rX, ellipseConfig.rY)
				break
			}
			case ColliderType.Box: {
				const boxConfig = colliderConfig as BoxColliderConfig
				childVisual.drawRect(0, 0, boxConfig.width, boxConfig.height)
				break
			}
			case ColliderType.Polygon: {
				const polygonConfig = colliderConfig as PolygonColliderConfig
				//colliderVisual.drawPolygon(new Polygon([0, 0, 50, 0, 50, 50, 0, 50]))
				let i = 0 // Polygon() accepts a list of numbers in x,y,x,y,x,y order, if i%2 == 0 add pos.x, else pos.y
				const path = polygonConfig.vertices.map((n) => {
					const isY = i++ % 2
					const m = isY ? -1 : 1
					return n * m
				})
				childVisual.drawPolygon(new Polygon(path))
				break
			}
		}
	})
	return colliderVisual
}

export const addDebugModel = function(o, name: string, radius: number, debug: boolean = true, colliderColor = 0xffffff, textSize = 20, textColor = 0xffffff) {
	o.debugModel = new UpdatableContainer()
	o.debugModel.name = name
	o.debugModel.x = o.x
	o.debugModel.y = o.y
	o.textLabel = new Text(name, {
		fontFamily: 'Arial',
		fontSize: textSize,
		//fill: 0xffffff,
		align: 'center',
		//		dropShadow: true
		fill: [0xcccccc, textColor],
		stroke: 0x000000,
		strokeThickness: 3,
	})
	o.textLabel.rotation = -0.72
	o.textLabel.x = -74
	o.textLabel.y = -5

	const g = new Graphics()
	g.zIndex = o.y + 100
	o.colliderVisual = g
	o.colliderVisual.name = 'collider'
	o.colliderVisual.clear()
	o.colliderVisual.lineStyle(4, colliderColor)
	o.colliderVisual.drawCircle(0, 0, radius)
	o.colliderVisual.endFill()

	o.debugModel.addChild(o.colliderVisual, o.textLabel)

	const renderer: Renderer = Renderer.getInstance()
	if (debug) {
		renderer.debugMiddleground.addChild(o.debugModel)
	} else {
		renderer.mgRenderer.addChild(o.debugModel)
	}
}

export const addDebugPoint = function(message: string, pos: Vector, debug: boolean = true, color = 0xffffff) {
	const container = new UpdatableContainer()
	container.x = pos.x
	container.y = pos.y
	const text = new Text(message, {
		fontFamily: 'Arial',
		fontSize: 16,
		fill: 0xffffff,
		align: 'center',
	})

	const point = new Graphics()
	point.zIndex = pos.y + 100
	point.name = 'point'
	point.clear()
	point.lineStyle(2, color)
	point.drawCircle(0, 0, 10)
	point.endFill()

	container.addChild(text, point)

	const renderer: Renderer = Renderer.getInstance()
	if (debug) {
		renderer.debugMiddleground.addChild(container)
	} else {
		renderer.mgRenderer.addChild(container)
	}
}

class PoolableText extends Text {
	setDefaultValues() {
		this.text = ''
	}
}

class DebugScreenPrintC extends UpdatableContainer {
	texts: Map<number, Map<number, Text>> = new Map()
	textContainer: Container = new Container()
	permanentTexts: Map<number, Map<number, Text>> = new Map()
	permanentTextContainer: Container = new Container()
	pool: ObjectPool = null
	mouseX: number
	mouseY: number

	constructor() {
		super()
		this.name = 'screenPrinter'
		this.pool = new ObjectPool(
			() =>
				new PoolableText('', {
					fontFamily: 'Arial',
					fontSize: 20,
					align: 'left',
					fill: [0xcccccc, 0xffffff],
					stroke: 0x000000,
					strokeThickness: 3,
				}),
			null,
			100,
			50,
			'debug-text',
		)

		document.addEventListener('mousemove', (event) => {
			this.mouseX = event.clientX
			this.mouseY = event.clientY
		})

		this.addChild(this.textContainer)
		this.addChild(this.permanentTextContainer)
	}

	beginFrame() {
		this.texts.forEach((map, x) => {
			map.forEach((text, y) => {
				this.freeToPool(text)
			})
		})
		this.texts.clear()
		this.textContainer.removeChildren()

		const worldCoords = Renderer.getInstance().mouseCoordinatesToWorldCoordinates(this.mouseX, this.mouseY)
		this.permanentTexts.forEach((map, x) => {
			map.forEach((text: Text, y) => {
				if (withinDistanceVXY(worldCoords, text.position.x, text.position.y, 150)) {
					text.scale.set(3, 3)
				} else {
					text.scale.set(1, 1)
				}
			})
		})
	}

	print(x: number, y: number, s: any, color: number, permanent: boolean, destroyAfterSeconds: timeInSeconds) {
		const texts = permanent || destroyAfterSeconds ? this.permanentTexts : this.texts
		const container = permanent || destroyAfterSeconds ? this.permanentTextContainer : this.textContainer

		if (texts.get(x) == null) {
			texts.set(x, new Map())
		}

		let text: Text = texts.get(x).get(y)

		if (text == null) {
			text = this.pool.alloc()
			text.x = x + 50
			text.y = y + 50
			texts.get(x).set(y, text)
			container.addChild(text)
		}

		text.tint = color

		if (destroyAfterSeconds) {
			setTimeout(() => {
				this.freeToPool(text)
				container.removeChild(text)
				texts.get(x).delete(y)
			}, destroyAfterSeconds)
		}

		text.text += s + '\n'
	}

	clear(x: number, y: number, permanent: boolean) {
		const texts = permanent ? this.permanentTexts : this.texts
		const container = permanent ? this.permanentTextContainer : this.textContainer

		const text2 = texts.get(x)

		if (text2) {
			const text: Text = text2.get(y)
			if (text) {
				this.freeToPool(text)
				container.removeChild(text)
				texts.get(x).delete(y)
			}
		}
	}

	private freeToPool(text) {
		this.pool.free(text)
	}
}

let screenPrinter: DebugScreenPrintC = null

export const DebugScreenPrintBeginFrame = function() {
	if (debugConfig.debug && screenPrinter == null && Renderer.getInstance()) {
		screenPrinter = new DebugScreenPrintC()
		Renderer.getInstance().debugMiddleground.addChild(screenPrinter)
	}
	if (screenPrinter) {
		screenPrinter.beginFrame()
	}
}

export const DebugScreenPrint = function(x: number, y: number, s: any, color: number = 0xffffff, permanent: boolean = false, destroyAfterSeconds: timeInSeconds = 0) {
	if (screenPrinter) {
		screenPrinter.print(x, y, s, color, permanent, destroyAfterSeconds)
	}
}

export const DebugClearText = function(x: number, y: number, permanent: boolean) {
	if (screenPrinter) {
		screenPrinter.clear(x, y, permanent)
	}
}

export function getAnimTrackString(model: RiggedSpineModel) {
	let trackIdx = 0
	const s: string = model.state.tracks
		.map((track) => {
			if (track) {
				return `track${trackIdx++}: ${track.animation.name}:${track.trackTime.toFixed(3)}`
			}
			return `track${trackIdx++}: null`
		})
		.join('\n')
	return s
}

// add right-click to inspect if enabled
if (process.env.NODE_ENV !== 'beta' && process.env.NODE_ENV !== 'loot-prod') {
	if (debugConfig.enableRightClickToInspectEnemy) {
		document.addEventListener('pointerdown', (event: MouseEvent) => {
			if (event.button === 2) {
				const worldCoords = Renderer.getInstance().mouseCoordinatesToWorldCoordinates(event.x, event.y)
				NengiClient.getInstance().sendCommand(new InspectNearestEnemyCommand(worldCoords.x, worldCoords.y))
			}
		})
	}
}

const debugClientKeyboard: Map<string, boolean> = new Map<string, boolean>()
export function debugKeyDown(code: string) {
	if (process.env.NODE_ENV === 'beta' || process.env.NODE_ENV === 'loot-prod') {
		return false
	}

	return debugClientKeyboard.get(code.toLowerCase())
}

let addedDebugClientKeyboardListener = false
export function addDebugListenersIfNotAdded() {
	if (process.env.NODE_ENV === 'beta' || process.env.NODE_ENV === 'loot-prod') {
		return false
	}

	if (!addedDebugClientKeyboardListener) {
		addedDebugClientKeyboardListener = true
		window.addEventListener('keydown', (event: KeyboardEvent) => {
			//console.log(event.code)

			if (!debugClientKeyboard.get(event.code.toLowerCase())) {
				updateDebugToggles(event)
			}

			debugClientKeyboard.set(event.code.toLowerCase(), true)
		})
		window.addEventListener('keyup', (event: KeyboardEvent) => {
			debugClientKeyboard.set(event.code.toLowerCase(), false)
		})
	}
}

/** checks for registered debug toggles that should respond to `event`, and toggle them on client and server */
function updateDebugToggles(event: KeyboardEvent) {
	debugToggles.forEach(toggle => {
		if (toggle.code.toLowerCase() === event.code.toLowerCase()) {
			const newValue = !get(toggle.obj, toggle.prop)
			console.log(`toggling ${toggle.prop} to ${newValue} because ${toggle.code}`)
			set(toggle.obj, toggle.prop, newValue)

			if (toggle.changeOnServerAndClient) {
				NengiClient.getInstance().sendCommand(new ChatCommand(`/set-debug ${toggle.prop} ${newValue}`))
			}
		}
	})
}
