














































import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import ListItemContainer from '../reusable-components/item-containers/list-item-container.vue'
import ButtonedGridItemContainer from '../reusable-components/item-containers/buttoned-grid-item-container.vue'
import ArrowButton from '../reusable-components/buttons/arrow-button.vue'
import PanelDivider from '../reusable-components/panel-divider/panel-divider.vue'
import { pitOfChancesTooltipText, pitStorageFullTooltipText } from '../reusable-components/v-tooltip/v-tooltip-functions'

export default {
	name: 'PitOfChancesUpdated',
	components: {
		MainPanel,
		ArrowButton,
		ButtonedGridItemContainer,
		ListItemContainer,
		PanelDivider,
	},
	computed: {
		...mapGetters('itemContainers', ['itemsInContainer', 'itemDetails', 'isPitStorageFull']),
		...mapGetters('pitOfChances', ['itemIsInPit', 'getItemsInPit', 'maxItemsInPit', 'pitIsFull']),
		...mapState('pitOfChances', ['selectedPitItem']),

		numberOfItemsPrettyString() {
			if (this.getItemsInPit.length === 1) {
				return `${this.getItemsInPit.length} item Selected`
			} else if (this.getItemsInPit.length > 1) {
				return `${this.getItemsInPit.length} items Selected`
			} else {
				return `No Items Selected`
			}
		},
		itemsInStashFiltered() {
			const toReturn = []
			const items = this.itemsInContainer('stash')

			for(const item of items) {
				const foundIndex = this.getItemsInPit.findIndex((i) => i.id === item.id)
				if(foundIndex !== -1) {
					if(item.stackAmount) {
						const pitItem = this.getItemsInPit[foundIndex]
						const remainingStacks = item.stackAmount - pitItem.stackAmount
						if(remainingStacks > 0) {
							const clone = Object.assign({}, item)
							clone.stackAmount = remainingStacks
							toReturn.push(clone)
						}
					} 
				} else {
					toReturn.push(item)
				}
			}
			
			return toReturn
		}
	},
	methods: {
		...mapActions('inGame', ['closeActivePanel']),
		...mapMutations('inGame', ['setActivePanel']),
		...mapActions('pitOfChances', ['sendItemToPit', 'removeItemFromPit', 'activatePit', 'selectPitItem']),
		...mapMutations('pitOfChances', ['updateItemStackAmounts']),

		pitOfChancesTooltipText,
		pitStorageFullTooltipText,
		stackAmountChange(payload){
			this.updateItemStackAmounts(payload)
		},
		itemIsStackable(item) {
			return Boolean(item.stackAmount)
		},
		openPitStorage() {
			this.setActivePanel('pitStorage')
		},
		shouldDisableSendToPit(itemId) {
			const itemDetails = this.itemDetails('stash', itemId)
			return itemDetails.equippedAugmentIds.length > 0
		},
		isItemSelected(itemId) {
			return itemId === this.selectedPitItem
		},
		toPitContainer(itemId) {
			const itemDetails = this.itemsInStashFiltered.find((i) => i.id === itemId)
			if(itemDetails) {
				this.sendItemToPit(itemDetails)
			} else {
				console.error("Could not match item id " + itemId)
			}
		}
	},
}
