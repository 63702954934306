import nengi from 'nengi'
import { uuid } from '../../utils/primitive-types'
import { ContainerType } from './send-item-container-message'

class OpenPrizeCommand {
	static protocol = {
		prizeId: nengi.String,
		fromContainer: nengi.String,
	}

	prizeId: uuid
	fromContainer: ContainerType

	constructor(prizeId: uuid, fromContainer: ContainerType) {
		this.prizeId = prizeId
		this.fromContainer = fromContainer
	}
}

export default OpenPrizeCommand
