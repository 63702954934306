import { BinaryFlagData } from '../../engine/shared/game-data/binary-flag-data'
import { BinaryFlag, StatToPrettyName } from '../../engine/shared/game-data/stat-type-mod-category'
import { radToDeg } from '../../utils/math'
import { StatUnit } from './stat-interfaces'

export enum TrajectoryShapes {
	STRAIGHT,
	ACCELERATING,
	DECCELERATING,
	WAVE,
	ZIGZAG,
	BOOMERANG,
	RANGEFINDER,
	CIRCULAR,
	ORBIT,
	SPIRAL,
	LOOPING,
	RANGECIRCLE,
	RANGEORBIT,
}

const statValueFormat = {} as any
statValueFormat.skillSpeed = (v) => `x${v}`
statValueFormat.skillUsageSpeed = (v) => `${v}`
statValueFormat.damage = (v) => `x${v}`
statValueFormat.allDamageBoost = (v) => `x${v}`
statValueFormat.physicalDamageBoost = (v) => `x${v}`
statValueFormat.fireDamage = (v) => `${v}`
statValueFormat.fireDamageBoost = (v) => `x${v}`
statValueFormat.iceDamage = (v) => `${v}`
statValueFormat.iceDamageBoost = (v) => `x${v}`
statValueFormat.lightningDamage = (v) => `${v}`
statValueFormat.lightningDamageBoost = (v) => `x${v}`
statValueFormat.poisonDamage = (v) => `${v}`
statValueFormat.poisonDamageBoost = (v) => `x${v}`

statValueFormat.bleedChanceSum = (v) => `+${v}%`
statValueFormat.bleedChance = (v) => `x${v}`
statValueFormat.bleedChanceBoost = (v) => `x${v}`
statValueFormat.bleedPotency = (v) => `x${v}`
statValueFormat.fireChance = (v) => `+${v}%`
statValueFormat.fireChanceBoost = (v) => `x${v}`
statValueFormat.igniteChance = (v) => `x${v}`
statValueFormat.firePotency = (v) => `x${v}`
statValueFormat.ignitePotency = (v) => `x${v}`
statValueFormat.iceChance = (v) => `+${v}%`
statValueFormat.iceChanceBoost = (v) => `x${v}`
statValueFormat.chillChance = (v) => `x${v}`
statValueFormat.icePotency = (v) => `x${v}`
statValueFormat.lightningChance = (v) => `+${v}%`
statValueFormat.lightningChanceBoost = (v) => `x${v}`
statValueFormat.shockChance = (v) => `x${v}`
statValueFormat.lightningPotency = (v) => `x${v}`
statValueFormat.toxicChance = (v) => `+${v}%`
statValueFormat.poisonChance = (v) => `x${v}`
statValueFormat.poisonChanceBoost = (v) => `x${v}`
statValueFormat.poisonPotency = (v) => `x${v}`

statValueFormat.attackSpeed = (v) => `x${v}`
statValueFormat.attackRate = (v) => `x${v}`
statValueFormat.chargeRate = (v) => `x${v}`
statValueFormat.projectileCount = (v) => `+ ${v}`
statValueFormat.projectileCountBoost = (v) => `x${v}`
statValueFormat.volleySpread = getSpreadDescriptorForTooltip
statValueFormat.spread = getVarianceDescriptorForTooltip

statValueFormat.projectileSpeed = (v) => `x${v}`
statValueFormat.pierceCount = (v) => `+${v}`
statValueFormat.chainCount = (v) => `+${v}`
statValueFormat.splashDamage = (v) => `x${v}`
statValueFormat.splashRadius = getSplashRadiusDescriptorForAugments
statValueFormat.projectileSplashRadius = (v) => `x${v}`
statValueFormat.beamLength = (v) => `x${v}`
statValueFormat.beamWidth = (v) => `x${v}`
statValueFormat.criticalChance = (v) => `+${v}%`
statValueFormat.criticalChanceBoost = (v) => `x${v}`
statValueFormat.criticalDamage = (v) => `x${v}`
statValueFormat.critDamageBoost = (v) => `x${v}`
statValueFormat.defense = (v) => `+${v}`
statValueFormat.fireResist = (v) => `+${v}`
statValueFormat.iceResist = (v) => `+${v}`
statValueFormat.lightningResist = (v) => `+${v}`
statValueFormat.poisonResist = (v) => `+${v}`
statValueFormat.movementSpeed = (v) => `x${v}`
statValueFormat.health = (v) => `+${v}`
statValueFormat.shields = (v) => `+${v}`
statValueFormat.shieldRegenRate = (v) => `x${v}`
statValueFormat.shieldRegenDelay = (v) => `x${v}`
statValueFormat.maxRange = (v) => `x${v}`

statValueFormat.allAilmentPotency = (v) => `x${1+v}`
statValueFormat.damagingAilmentPotency = (v) => `x${1+v}`
statValueFormat.statusAilmentPotency = (v) => `x${1+v}`

statValueFormat.maxEnergyBoost = (v) => `x${1+v}`

export function getStatStringForTooltip(stat, value) {
	if (stat in statValueFormat) {
		return statValueFormat[stat](value)
	}

	return value
}

export function getConverterDescriptor(converter) {
	const inputExtra = converter.inputStatUnit === StatUnit.Number ? `extra ` : ``
	const inputValue = converter.inputStatUnit === StatUnit.Number ? converter.inputRatio : `${converter.inputRatio * 100}%`
	const outputValue = converter.outputStatUnit === StatUnit.Number ? converter.outputRatio : `${converter.outputRatio * 100}%`
	if (converter.inputRatio !== 1 && converter.outputRatio !== 1) {
		return `Converts ${inputValue} of ${inputExtra}${StatToPrettyName[converter.inputStatType]} to ${outputValue} ${StatToPrettyName[converter.outputStatType]}`
	} else if (converter.inputRatio !== 1) {
		return `Converts ${inputValue} of ${inputExtra}${StatToPrettyName[converter.inputStatType]} to ${StatToPrettyName[converter.outputStatType]}`
	} else {
		return `Converts ${inputExtra}${StatToPrettyName[converter.inputStatType]} to ${outputValue} ${StatToPrettyName[converter.outputStatType]}`
	}
}

export function getClampDescriptor(clamp) {
	if (clamp.clampMin) {
		return `Minimum of ${clamp.clampMin} ${StatToPrettyName[clamp.statType]}`
	} else if (clamp.clampMax) {
		return `Maximum of ${clamp.clampMax} ${StatToPrettyName[clamp.statType]}`
	}
}

export function getBinaryFlagDescriptor(flag: BinaryFlag) {
	return BinaryFlagData[flag].description
}

export function getSpreadAngleDescriptor(spreadAngleInRads) {
	const spreadAngleInDegrees = radToDeg(spreadAngleInRads)
	if (spreadAngleInDegrees < 2) {
		return 'Minimal'
	}
	if (spreadAngleInDegrees < 5) {
		return 'Narrow'
	}
	if (spreadAngleInDegrees < 15) {
		return 'Moderate'
	}
	if (spreadAngleInDegrees < 45) {
		return 'Wide'
	}
	if (spreadAngleInDegrees < 85) {
		return 'Very Wide'
	}
	return 'Worldwide'
}

export function getProjectileSpeedDescriptor(projectileSpeed) {
	if (projectileSpeed < 400) {
		return 'Glacial'
	}
	if (projectileSpeed < 800) {
		return 'Very Slow'
	}
	if (projectileSpeed < 1100) {
		return 'Slow'
	}
	if (projectileSpeed < 1500) {
		return 'Moderate'
	}
	if (projectileSpeed < 2200) {
		return 'Fast'
	}
	if (projectileSpeed < 3500) {
		return 'Very Fast'
	}
	return 'Ludicrous'
}

export function getTrajectoryDescriptor(trajectory) {
	const result = []

	for (let i = 0; i < trajectory.length; ++i) {
		switch (trajectory[i].shape) {
			case TrajectoryShapes.STRAIGHT:
				result.push('Straight')
				break
			case TrajectoryShapes.ACCELERATING:
				result.push('Accelerating')
				break
			case TrajectoryShapes.DECCELERATING:
				result.push('Deccelerating')
				break
			case TrajectoryShapes.WAVE:
				result.push('Wave')
				break
			case TrajectoryShapes.ZIGZAG:
				result.push('Zigzag')
				break
			case TrajectoryShapes.BOOMERANG:
				result.push('Boomerang')
				break
			case TrajectoryShapes.RANGEFINDER:
				result.push('Rangefinder')
				break
			case TrajectoryShapes.CIRCULAR:
				result.push('Circular')
				break
			case TrajectoryShapes.ORBIT:
				result.push('Orbit')
				break
			case TrajectoryShapes.SPIRAL:
				result.push('Spiral')
				break
			case TrajectoryShapes.LOOPING:
				result.push('Looping')
				break
			case TrajectoryShapes.RANGECIRCLE:
				result.push('Range Circular')
				break
			case TrajectoryShapes.RANGEORBIT:
				result.push('Range Orbit')
				break
		}
	}

	return result.join(' + ')
}

export function getBeamLengthDescriptor(beamLength) {
	if (beamLength < 200) {
		return 'Touch'
	}
	if (beamLength < 275) {
		return 'Very Short'
	}
	if (beamLength < 350) {
		return 'Short'
	}
	if (beamLength < 500) {
		return 'Moderate'
	}
	if (beamLength < 700) {
		return 'Far'
	}
	if (beamLength < 900) {
		return 'Very Far'
	}
	if (beamLength < 1000) {
		return 'Sniper'
	}
	return 'Galactic'
}

export function getBeamWidthDescriptor(beamWidth) {
	if (beamWidth < 12) {
		return 'Laser Pointer'
	}
	if (beamWidth < 20) {
		return 'Narrow'
	}
	if (beamWidth < 30) {
		return 'Ample'
	}
	if (beamWidth < 40) {
		return 'Moderate'
	}
	if (beamWidth < 55) {
		return 'Broad'
	}
	if (beamWidth < 70) {
		return 'Immense'
	}
	return 'Dragon Slayer'
}

export function getSplashRadiusDescriptorForWeapons(splashRadius) {
	if (splashRadius < 30) {
		return 'Super Teeny'
	}
	if (splashRadius < 50) {
		return 'Teeny'
	}
	if (splashRadius < 100) {
		return 'Small'
	}
	if (splashRadius < 140) {
		return 'Moderate'
	}
	if (splashRadius < 180) {
		return 'Big'
	}
	if (splashRadius < 220) {
		return 'Very Big'
	}
	if (splashRadius < 260) {
		return 'Huge'
	}
	if (splashRadius < 300) {
		return 'Massive'
	}
	return 'Planet-Buster'
}

export function getSplashRadiusDescriptorForAugments(splashRadius) {
	if (splashRadius < 30) {
		return 'Super Teeny'
	}
	if (splashRadius < 40) {
		return 'Teeny'
	}
	if (splashRadius < 50) {
		return 'Small'
	}
	if (splashRadius < 60) {
		return 'Moderate'
	}
	if (splashRadius < 70) {
		return 'Big'
	}
	if (splashRadius < 80) {
		return 'Very Big'
	}
	if (splashRadius < 90) {
		return 'Huge'
	}
	if (splashRadius < 100) {
		return 'Massive'
	}
	return 'Planet-Buster'
}

export function getKnockbackDescriptor(knockback) {
	if (knockback < -50) {
		return 'Black Hole'
	}
	if (knockback < -20) {
		return 'Vacuum'
	}
	if (knockback < -10) {
		return 'Big Pull'
	}
	if (knockback < -5) {
		return 'Pull'
	}
	if (knockback < 5) {
		return 'Light Breeze'
	}
	if (knockback < 10) {
		return 'Push'
	}
	if (knockback < 20) {
		return 'Big Push'
	}
	if (knockback < 50) {
		return 'Smite'
	}
	return 'Yeet'
}

export function getSpreadDescriptorForTooltip(angleInDegs) {
	if (angleInDegs < 5) {
		return 'Barely Any Wider'
	}
	if (angleInDegs < 10) {
		return 'A Tiny Bit Wider'
	}
	if (angleInDegs < 20) {
		return 'Somewhat Wider'
	}
	if (angleInDegs < 40) {
		return 'Wider'
	}
	if (angleInDegs < 60) {
		return 'Much Wider'
	}
	if (angleInDegs < 80) {
		return 'Turbo Wide'
	}
	return 'Off The Charts'
}

export function getVarianceDescriptorForTooltip(angleInDegs) {
	if (angleInDegs < 2) {
		return 'Minimal'
	}
	if (angleInDegs < 5) {
		return 'A Little'
	}
	if (angleInDegs < 8) {
		return 'Unsteady'
	}
	if (angleInDegs < 15) {
		return 'Wobbly'
	}
	if (angleInDegs < 25) {
		return 'Very Wobbly'
	}
	if (angleInDegs < 40) {
		return 'All Over'
	}
	return 'You\'re Basically Drunk'
}
