import { PropClumpData, PropData } from './biome-data-structures'

const smallCommonProps: PropData[] = [
	{ name: 'small-common-01', bufferRadius: 10, weight: 100 },
	{ name: 'small-common-02', bufferRadius: 40, weight: 100 },
	{ name: 'small-common-03', bufferRadius: 40, weight: 100 },
]

const smallUncommonProps: PropData[] = [
	{ name: 'small-uncommon-01', bufferRadius: 60, weight: 50 },
	{ name: 'small-uncommon-02', bufferRadius: 60, weight: 50 },
	{ name: 'small-uncommon-03', bufferRadius: 60, weight: 50 },
	{ name: 'small-uncommon-04', bufferRadius: 60, weight: 50 },
]

const smallRareProps: PropData[] = [
	{ name: 'small-rare-01', bufferRadius: 100, weight: 30 },
	{ name: 'small-rare-02', bufferRadius: 100, weight: 30 },
	{ name: 'small-rare-03', bufferRadius: 100, weight: 10 },
]

const mediumCommonProps: PropData[] = [
	{ name: 'med-common-01', bufferRadius: 100, weight: 100 },
	{ name: 'med-common-02', bufferRadius: 100, weight: 100 },
	{ name: 'med-common-03', bufferRadius: 100, weight: 100 },
	{ name: 'med-common-04', bufferRadius: 100, weight: 100 },
]

const mediumUncommonProps: PropData[] = [
	{ name: 'med-uncommon-02', bufferRadius: 100, weight: 30 },
	{ name: 'med-uncommon-01', bufferRadius: 100, weight: 30 },
	{ name: 'med-uncommon-03', bufferRadius: 100, weight: 30 },
]

const largeUncommonProps: PropData[] = [
	{ name: 'large-uncommon-01-var01', bufferRadius: 300, weight: 100 },
	{ name: 'large-uncommon-01-var02', bufferRadius: 300, weight: 100 },
	{ name: 'large-uncommon-01-var03', bufferRadius: 300, weight: 100 },
]

const largeRareProps1: PropData[] = [
	{ name: 'large-rare-01-var01', bufferRadius: 400, weight: 60 },
	{ name: 'large-rare-01-var02', bufferRadius: 400, weight: 60 },
	{ name: 'large-rare-01-var03', bufferRadius: 400, weight: 60 },
]

const largeRareProps2: PropData[] = [
	{ name: 'large-rare-02-var01', bufferRadius: 400, weight: 30 },
	{ name: 'large-rare-02-var02', bufferRadius: 400, weight: 30 },
	{ name: 'large-rare-02-var03', bufferRadius: 400, weight: 30 },
]

const lowBlockerProps: PropData[] = [
	{ name: 'low-blocker-01-var01', bufferRadius: 100, weight: 100 },
	{ name: 'low-blocker-01-var02', bufferRadius: 100, weight: 100 },
	{ name: 'low-blocker-01-var03', bufferRadius: 100, weight: 100 },
	{ name: 'low-blocker-01-var04', bufferRadius: 100, weight: 100 },
	{ name: 'low-blocker-01-var05', bufferRadius: 100, weight: 100 },
	{ name: 'low-blocker-01-var06', bufferRadius: 100, weight: 100 },
]

const midBlockerProps: PropData[] = [
	{ name: 'mid-blocker-01-var01', bufferRadius: 100, weight: 100 },
	{ name: 'mid-blocker-01-var02', bufferRadius: 100, weight: 100 },
	{ name: 'mid-blocker-01-var03', bufferRadius: 100, weight: 100 },
	{ name: 'mid-blocker-01-var04', bufferRadius: 100, weight: 100 },
	{ name: 'mid-blocker-01-var05', bufferRadius: 100, weight: 100 },
	{ name: 'mid-blocker-01-var06', bufferRadius: 100, weight: 100 },
]

const highBlockerProps: PropData[] = [
	{ name: 'high-blocker-01-var01', bufferRadius: 100, weight: 100 },
	{ name: 'high-blocker-01-var02', bufferRadius: 100, weight: 100 },
	{ name: 'high-blocker-01-var03', bufferRadius: 100, weight: 100 },
	{ name: 'high-blocker-01-var04', bufferRadius: 100, weight: 100 },
]

export const beachPropClumps: PropClumpData[] = [
	// Terraces
	{
		weight: 50,
		propCounts: [
			{ count: 3, weight: 20 },
			{ count: 6, weight: 30 },
			{ count: 8, weight: 80 },
		],
		bufferRadius: 600,
		centerpieceProps: [...largeUncommonProps, ...largeRareProps1, ...largeRareProps2],
		clumpProps: [...mediumCommonProps, ...smallCommonProps, ...smallUncommonProps, ...mediumUncommonProps],
	},
	// Trees
	{
		weight: 100,
		propCounts: [
			{ count: 2, weight: 80 },
			{ count: 3, weight: 60 },
			{ count: 4, weight: 40 },
			{ count: 5, weight: 20 },
		],
		bufferRadius: 400,
		centerpieceProps: [...mediumCommonProps],
		clumpProps: [...mediumCommonProps, ...smallCommonProps, ...smallUncommonProps, ...mediumUncommonProps],
	},
	// Campfires? stuff like that
	{
		weight: 10,
		propCounts: [
			{ count: 1, weight: 100 },
			{ count: 3, weight: 100 },
			{ count: 6, weight: 100 },
			{ count: 10, weight: 100 },
		],
		bufferRadius: 50,
		centerpieceProps: [...smallRareProps],
		clumpProps: [...smallCommonProps, ...smallUncommonProps],
	},
	// Blockers
	{
		weight: 100,
		propCounts: [{ count: 0, weight: 100 }],
		bufferRadius: 400,
		centerpieceProps: [...lowBlockerProps, ...midBlockerProps, ...highBlockerProps],
		clumpProps: [],
	},
	// grass
	{
		weight: 100,
		propCounts: [{ count: 0, weight: 100 }],
		bufferRadius: 10,
		centerpieceProps: [...smallCommonProps],
		clumpProps: [],
	},
]

export const forestPropClumps: PropClumpData[] = [
	// Terraces
	{
		weight: 100,
		propCounts: [{ count: 0, weight: 100 }],
		bufferRadius: 600,
		centerpieceProps: [...largeUncommonProps, ...largeRareProps1, ...largeRareProps2],
		clumpProps: [],
	},
	// Trees
	{
		weight: 200,
		propCounts: [
			{ count: 4, weight: 60 },
			{ count: 5, weight: 50 },
			{ count: 6, weight: 40 },
			{ count: 7, weight: 30 },
			{ count: 8, weight: 20 },
		],
		bufferRadius: 400,
		centerpieceProps: [...mediumCommonProps],
		clumpProps: [...mediumCommonProps],
	},
	// Campfires? stuff like that
	{
		weight: 50,
		propCounts: [
			{ count: 1, weight: 100 },
			{ count: 2, weight: 100 },
			{ count: 3, weight: 100 },
		],
		bufferRadius: 300,
		centerpieceProps: [...smallRareProps],
		clumpProps: [...smallCommonProps, ...smallUncommonProps],
	},
	// Blockers
	{
		weight: 100,
		propCounts: [{ count: 0, weight: 100 }],
		bufferRadius: 400,
		centerpieceProps: [...lowBlockerProps, ...midBlockerProps, ...highBlockerProps],
		clumpProps: [],
	},
	// grass
	{
		weight: 100,
		propCounts: [
			{ count: 1, weight: 10 },
			{ count: 3, weight: 10 },
			{ count: 5, weight: 30 },
			{ count: 6, weight: 100 },
		],
		bufferRadius: 100,
		centerpieceProps: [...smallCommonProps],
		clumpProps: [...smallCommonProps],
	},
]

export const fungiPropClumps: PropClumpData[] = [
	// Terraces
	{
		weight: 50,
		propCounts: [
			{ count: 3, weight: 20 },
			{ count: 6, weight: 30 },
			{ count: 8, weight: 80 },
		],
		bufferRadius: 600,
		centerpieceProps: [...largeUncommonProps, ...largeRareProps1, ...largeRareProps2],
		clumpProps: [...mediumCommonProps, ...smallCommonProps, ...smallUncommonProps, ...mediumUncommonProps],
	},
	// Trees
	{
		weight: 100,
		propCounts: [
			{ count: 2, weight: 80 },
			{ count: 4, weight: 60 },
			{ count: 7, weight: 40 },
			{ count: 8, weight: 20 },
		],
		bufferRadius: 400,
		centerpieceProps: [...mediumCommonProps],
		clumpProps: [...mediumCommonProps, ...smallCommonProps, ...smallUncommonProps, ...mediumUncommonProps],
	},
	// Campfires? stuff like that
	{
		weight: 10,
		propCounts: [
			{ count: 1, weight: 100 },
			{ count: 3, weight: 100 },
			{ count: 6, weight: 100 },
			{ count: 10, weight: 100 },
		],
		bufferRadius: 50,
		centerpieceProps: [...smallRareProps],
		clumpProps: [...smallCommonProps, ...smallUncommonProps],
	},
	// Blockers
	{
		weight: 100,
		propCounts: [{ count: 0, weight: 100 }],
		bufferRadius: 400,
		centerpieceProps: [...lowBlockerProps, ...midBlockerProps, ...highBlockerProps],
		clumpProps: [],
	},
	// grass
	{
		weight: 100,
		propCounts: [
			{ count: 1, weight: 10 },
			{ count: 2, weight: 10 },
			{ count: 3, weight: 30 },
			{ count: 4, weight: 100 },
		],
		bufferRadius: 100,
		centerpieceProps: [...smallCommonProps],
		clumpProps: [...smallCommonProps],
	},
]

export const highlandsPropClumps: PropClumpData[] = [
	// Terraces
	{
		weight: 50,
		propCounts: [
			{ count: 3, weight: 20 },
			{ count: 6, weight: 30 },
			{ count: 8, weight: 80 },
		],
		bufferRadius: 600,
		centerpieceProps: [...largeUncommonProps, ...largeRareProps2],
		clumpProps: [...mediumCommonProps, ...smallCommonProps, ...smallUncommonProps, ...mediumUncommonProps],
	},
	// Pit
	{
		weight: 20,
		propCounts: [{ count: 0, weight: 100 }],
		bufferRadius: 900,
		centerpieceProps: [...largeRareProps1],
		clumpProps: [],
	},
	// Trees
	{
		weight: 100,
		propCounts: [
			{ count: 2, weight: 80 },
			{ count: 4, weight: 60 },
			{ count: 7, weight: 40 },
			{ count: 8, weight: 20 },
		],
		bufferRadius: 400,
		centerpieceProps: [...mediumCommonProps],
		clumpProps: [...mediumCommonProps, ...smallCommonProps, ...smallUncommonProps, ...mediumUncommonProps],
	},
	// Campfires? stuff like that
	{
		weight: 10,
		propCounts: [
			{ count: 1, weight: 100 },
			{ count: 3, weight: 100 },
			{ count: 6, weight: 100 },
			{ count: 10, weight: 100 },
		],
		bufferRadius: 50,
		centerpieceProps: [...smallRareProps],
		clumpProps: [...smallCommonProps, ...smallUncommonProps],
	},
	// Blockers
	{
		weight: 100,
		propCounts: [{ count: 0, weight: 100 }],
		bufferRadius: 400,
		centerpieceProps: [...lowBlockerProps, ...midBlockerProps, ...highBlockerProps],
		clumpProps: [],
	},
	// grass
	{
		weight: 100,
		propCounts: [
			{ count: 1, weight: 10 },
			{ count: 2, weight: 10 },
			{ count: 3, weight: 30 },
			{ count: 4, weight: 100 },
		],
		bufferRadius: 100,
		centerpieceProps: [...smallCommonProps],
		clumpProps: [...smallCommonProps],
	},
]
export const prismPropClumps: PropClumpData[] = [
	// Terraces
	{
		weight: 50,
		propCounts: [
			{ count: 3, weight: 20 },
			{ count: 6, weight: 30 },
			{ count: 8, weight: 80 },
		],
		bufferRadius: 600,
		centerpieceProps: [...largeUncommonProps, ...largeRareProps1, ...largeRareProps2],
		clumpProps: [...mediumCommonProps, ...smallCommonProps, ...smallUncommonProps, ...mediumUncommonProps],
	},
	// Trees
	{
		weight: 100,
		propCounts: [
			{ count: 2, weight: 80 },
			{ count: 4, weight: 60 },
			{ count: 7, weight: 40 },
			{ count: 8, weight: 20 },
		],
		bufferRadius: 400,
		centerpieceProps: [...mediumCommonProps],
		clumpProps: [...mediumCommonProps, ...smallCommonProps, ...smallUncommonProps, ...mediumUncommonProps],
	},
	// Campfires? stuff like that
	{
		weight: 10,
		propCounts: [
			{ count: 1, weight: 100 },
			{ count: 3, weight: 100 },
			{ count: 6, weight: 100 },
			{ count: 10, weight: 100 },
		],
		bufferRadius: 50,
		centerpieceProps: [...smallRareProps],
		clumpProps: [...smallCommonProps, ...smallUncommonProps],
	},
	// Blockers
	{
		weight: 100,
		propCounts: [{ count: 0, weight: 100 }],
		bufferRadius: 400,
		centerpieceProps: [...lowBlockerProps, ...midBlockerProps, ...highBlockerProps],
		clumpProps: [],
	},
	// grass
	{
		weight: 100,
		propCounts: [
			{ count: 1, weight: 10 },
			{ count: 2, weight: 10 },
			{ count: 3, weight: 30 },
			{ count: 4, weight: 100 },
		],
		bufferRadius: 100,
		centerpieceProps: [...smallCommonProps],
		clumpProps: [...smallCommonProps],
	},
]
