import logger from '../../utils/client-logger'
import { NengiClient } from '../../engine/client/nengi-client'
import FetchItemListingsCommand from '../../items/shared/fetch-item-listings-command'
import FetchUserListingsCommand from '../../items/shared/fetch-user-listings-command'
import moment from 'moment'
import BuyItemCommand from '../../items/shared/buy-item-command'
import SubmitItemListingCommand from '../../items/shared/submit-item-listing-command'
import CancelListingCommand from '../../items/shared/cancel-listing-command'
import { UI } from '../ui'
import Vue from 'vue'
import RequestStashUpdate from '../../items/shared/request-stash-update-command'
import RequestWalletUpdate from '../../items/shared/request-wallet-update-command'
import { ItemRarityPrettyName } from '../../loot/shared/item-rarity'
import { CategoryFilterTypes } from './item-containers.ui-state'
import { PAGINATION_LIMIT } from '../../marketplace/shared/marketplace-pagination-constants'

// Some of these are shared in Plutus post-list-item-on-marketplace
export const MAX_ITEM_LISTINGS = 5
export const LISTING_FEE = 5 //percent
export const PROFIT_FEE = 10 //percent

export const MAX_LISTING_PRICE = 1000000

export const LISTING_FEE_PERCENT = LISTING_FEE / 100
export const PROFIT_FEE_PERCENT = PROFIT_FEE / 100

export const marketplaceUIState = () => {
	logger.debug('Initializing marketplace module')
	return {
		namespaced: true,
		state: {
			selectedListingItem: null,
			loadingInProgress: false,
			isListingItem: false,
			listingsMetadata: [],
			userListingsMetadata: [],
			selectedInventoryItemToSell: [],
			cancelledListings: [],
			selectInventoryItemID: null,
			itemPrice: 0,
			timeOnMarket: 168,
			numberOfStacks: 1,
			showListItemPopUp: false,
			listItemPopId: null,
			listItemPopDetails: null,
			listItemPopContainer: null,
			marketplacePage: 1,
			fetchingMarketPlaceData: false,
			totalMarketplacePages: 0,
			lastFreeMarketplaceDate: null,
			useFreeMarketplaceListing: false
		},
		getters: {
			selectedListingItem(state) {
				return state.selectedListingItem
			},
			listingsMetadata(state) {
				return state.listingsMetadata
			},
			cancelledListings(state) {
				return state.cancelledListings
			},
			currentlySelectedItem(state) {
				return state.selectedInventoryItemToSell
			},
			currentlySelectedItemID(state) {
				return state.selectInventoryItemID
			},
			getItemExpirationFromMetadataByItemId(state) {
				return (itemId) => {
					const item = state.listingsMetadata.find((item) => {
						return item.itemId === itemId
					})
					return item.expiresAtFriendlyDescription
				}
			},
			getUserItemExpirationFromMetadataByItemId(state) {
				return (itemId) => {
					const item = state.userListingsMetadata.find((item) => {
						return item.itemId === itemId
					})

					if (item && !item.isPendingMarketplace) {
						return item.expiresAtFriendlyDescription
					}

					return null
				}
			},
			getItemPriceFromMetadataByItemId(state) {
				return (itemId) => {
					return state.listingsMetadata.find((item) => {
						return item.itemId === itemId
					}).price
				}
			},
			getUserItemPriceFromMetadataByItemId(state) {
				return (itemId) => {
					const item = state.userListingsMetadata.find((item) => {
						return item.itemId === itemId
					})

					if (item) {
						return item.price
					}

					return null
				}
			},
			getLoadingInProgress(state) {
				return state.loadingInProgress
			},
			getMyListings(state, getters, rootState, rootGetters) {
				const userId = rootState.user.id
				const allUserListings = rootGetters['itemContainers/itemsInContainer']('userMarketplaceListings')
				const pendingList = rootGetters['itemContainers/itemsInContainer']('pendingMarketplace')
				return [...allUserListings, ...pendingList]
			},
		},
		mutations: {
			deselectItemToSell(state) {
				state.selectedInventoryItemToSell.splice(0, state.selectedInventoryItemToSell.length)
				state.selectInventoryItemID = null
				state.itemPrice = 0
			},
			showListingPopUp(state, { itemId, container }) {
				state.showListItemPopUp = true
				state.listItemPopId = itemId
				state.listItemPopContainer = container
				state.listItemPopDetails = UI.getInstance().store.getters['itemContainers/itemDetails'](container, itemId)
			},
			closeListingPopUp(state) {
				state.showListItemPopUp = false
			},
			itemListedSuccessfully(state) {
				Vue.toasted.success('Item listed on marketplace!', {
					position: 'top-center',
					duration: 3000,
				})
				state.isListingItem = false
			},
			failedToListItem(state) {
				Vue.toasted.error('Item failed to list, try again later!', {
					position: 'top-center',
					duration: 3000,
				})
				state.isListingItem = false
			},
			failedToBuyItem(state, errorCode: string) {
				state.loadingInProgress = false
				console.error('failed to buy for reason:', errorCode)
				Vue.toasted.error(`Failed to buy with error code: ${errorCode}`, {
					position: 'top-center',
					duration: 3000,
				})
			},
			itemPurchasedSuccessfully(state) {
				Vue.toasted.success('Item purchased!', {
					position: 'top-center',
					duration: 3000,
				})
			},
			resetProgressState(state) {
				state.loadingInProgress = false
				state.isListingItem = false
			},
			setItemPrice(state, itemPrice) {
				state.itemPrice = itemPrice
			},
			increaseMarketPage(state) {
				state.marketplacePage += 1
			},
			decreaseMarketPage(state) {
				state.marketplacePage -= 1
			},
			changeToSpecificPage(state, pageNum) {
				//TODO condition to check that the page isn't > then the total (or < then 1)
				state.marketplacePage = pageNum
			},
			setNumberOfStacks(state, numStacks) {
				state.numberOfStacks = numStacks
			},
			updateTotalItemCount(state, itemCount: any) {
				const count = JSON.parse(itemCount)
				state.totalMarketplacePages = Math.ceil(parseInt(count[0]['count']) / PAGINATION_LIMIT)
			},
			setLastFreeMarketplaceDate(state, lastFreeMarketplaceDate) {
				state.lastFreeMarketplaceDate = moment(lastFreeMarketplaceDate).utc()
			},
			setUseFreeMarketplaceListing(state, useFree) {
				state.useFreeMarketplaceListing = useFree
			}
		},
		actions: {
			toggleSelectMarketplaceListingItem(context, { itemId, myListing }) {
				const { state, commit, rootGetters } = context
				if (itemId && itemId !== state.selectedListingItem) {

					let container = 'marketplaceItemListings'
					if (myListing) {
						container = 'userMarketplaceListings'
						const pendingList = rootGetters['itemContainers/itemsInContainer']('pendingMarketplace')
						if (pendingList.find(item => item.id === itemId)) {
							container = 'pendingMarketplace'
						}
					}

					commit(
						'itemComparison/selectItem',
						{
							itemId,
							container,
						},
						{ root: true },
					)
					state.selectedListingItem = itemId
				} else {
					commit('itemComparison/deselectItem', {}, { root: true })
					state.selectedListingItem = null
				}
			},
			selectInventoryItemToSell({ state, rootGetters }, itemId) {
				const itemDetails = rootGetters['itemContainers/itemDetails']('stash', itemId)
				state.selectedInventoryItemToSell.push(itemDetails)
				state.selectInventoryItemID = itemId
			},
			fetchListings({ commit, state, rootState }, newPane: string) {
				if (!state.loadingInProgress) {
					state.loadingInProgress = true
					
					const { rarity, orderBy, page, sortBy } = getPaginationDetails()

					NengiClient.getInstance().sendCommand(new FetchItemListingsCommand(rootState.user.id, page, sortBy, orderBy, rarity))
				}
			},
			fetchUserListings({ commit, state, rootState }) {
				if (!state.loadingInProgress) {
					state.loadingInProgress = true
					NengiClient.getInstance().sendCommand(new FetchUserListingsCommand())
				}
			},
			receivedItemListingsMetadata({ state, getters }, listingsMetadata: any) {
				const oldMyListingsLen = getters.getMyListings.length
				listingsMetadata.forEach((listing) => {
					const listingExpiresAt = moment.utc(listing.expiresAt).subtract(30, 'seconds')
					const nowMoment = moment(new Date()).utc()
					listing.expiresAtFriendlyDescription = nowMoment.to(listingExpiresAt, true)
				})
				state.listingsMetadata = [...listingsMetadata]
				state.loadingInProgress = false

				const newMyListingsLen = getters.getMyListings.length
				if (newMyListingsLen < oldMyListingsLen) {
					// We sold an item (maybe)
					NengiClient.getInstance().sendCommand(new RequestWalletUpdate())
					// or a listing expired
					NengiClient.getInstance().sendCommand(new RequestStashUpdate())
				}
			},
			receivedUserListingsMetadata({ state, getters }, userListingsMetadata: any) {
				const oldMyListingsLen = state.userListingsMetadata.length

				userListingsMetadata.forEach((listing) => {
					const listingExpiresAt = moment.utc(listing.expiresAt).subtract(30, 'seconds')
					const nowMoment = moment(new Date()).utc()
					listing.expiresAtFriendlyDescription = nowMoment.to(listingExpiresAt, true)
				})

				state.userListingsMetadata = [...userListingsMetadata]

				const newMyListingsLen = state.userListingsMetadata.length

				state.loadingInProgress = false

				if (newMyListingsLen < oldMyListingsLen) {
					// We sold an item (maybe)
					NengiClient.getInstance().sendCommand(new RequestWalletUpdate())
					// or a listing expired
					NengiClient.getInstance().sendCommand(new RequestStashUpdate())
				}
			},
			buyItem({ commit, state, rootState }, itemId) {
				const listingId = state.listingsMetadata.find((listing) => {
					return listing.itemId === itemId
				}).listingId
				state.loadingInProgress = true

				const { rarity, orderBy, page, sortBy } = getPaginationDetails()

				NengiClient.getInstance().sendCommand(new BuyItemCommand(listingId, itemId, rootState.user.id, page, sortBy, orderBy, rarity))
			},
			submitItemListing({ commit, state }, payload) {
				const { price, expiry, stacks } = payload
				const selectInventoryItemID = state.showListItemPopUp ? state.listItemPopId : state.selectInventoryItemID
				let container = state.showListItemPopUp ? state.listItemPopContainer : 'stash'
				if (container === 'pitStorage') {
					container = 'pitRewards'
				}

				commit('deselectItemToSell')
				state.isListingItem = true
				NengiClient.getInstance().sendCommand(new SubmitItemListingCommand(selectInventoryItemID, price, expiry, container, stacks, state.useFreeMarketplaceListing))
			},
			cancelItemListing({ commit, state }, payload) {
				const { listingId, itemId } = payload
				state.loadingInProgress = true
				NengiClient.getInstance().sendCommand(new CancelListingCommand(listingId, itemId))
			},
			deselectSellItems({ commit }) {
				commit('deselectItemToSell')
			},
			resetPageNum({state, commit, dispatch}) {
				if (state.marketplacePage === 1) return
				commit('decreaseMarketPage')
				dispatch('fetchListings')
			}
		},
	}
}

export function getRarityFromStringName(rarityValue) {
	for (const [key, value] of ItemRarityPrettyName.entries()) {
		if (value === rarityValue) {
			return key
		}
	}
	return 0
}

export function getPaginationDetails() {
	const activeFilter = UI.getInstance().store.state.itemContainers.activeFilterCategory

	let sortBy
	if (activeFilter === CategoryFilterTypes.NAME) {
		sortBy = UI.getInstance().store.state.itemContainers.categorySortOrderName.toUpperCase()
	} else if (activeFilter === CategoryFilterTypes.LEVEL) {
		sortBy = UI.getInstance().store.state.itemContainers.categorySortOrderLevel.toUpperCase()
	} else if (activeFilter === CategoryFilterTypes.PRICE) {
		sortBy = UI.getInstance().store.state.itemContainers.categorySortOrderPrice.toUpperCase()
	}

	const page = UI.getInstance().store.state.marketplaceUpdated.marketplacePage
	const orderBy = activeFilter === CategoryFilterTypes.PRICE ? 'listing_price' : activeFilter
	const rarityString = UI.getInstance().store.state.itemContainers.activeRarityFilter
	const rarity = getRarityFromStringName(rarityString)

	return { rarity: rarity, orderBy: orderBy, page: page, sortBy: sortBy, }
}
