

























import EmptyInventorySlot from '../inventory-item/empty-inventory-slot.vue'
import InventoryItem from '../inventory-item/inventory-item.vue'

export default {
	name: 'GridItemContainer',
	components: {
		InventoryItem,
		EmptyInventorySlot,
	},
	props: {
		items: {
			type: Array,
			required: true,
			default: () => {
				console.warn('Currently using the default array. Ensure the <GridItemContainer /> component has a custom attribute items')
				return []
			},
		},
		showSlot: {
			type: Boolean,
			required: false,
			default: true,
		},
		selectable: {
			type: Boolean,
			required: false,
			default: true,
		},
		onSelectFn: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default on-select handler for the <ListItemContainer /> component has been called. Did you forget to pass an :on-click handler to the component?')
			},
		},
		selectedFn: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default selected fn for the <ListItemContainer /> component has been called. Did you forget to pass an :selected-fn handler to the component?')
			},
		},
		tooltipable: {
			type: Boolean,
			required: false,
			default: true,
		},
		maxItemCount: {
			type: Number,
			required: true,
			default: () => {
				console.warn('Currently using the default max item slots. Ensure the <GridItemContainer /> component has a custom attribute max-item-count')
				return 6
			},
		},
		showButtons: {
			type: Boolean,
			required: false,
			default: false,
		},
		onButtonClickFn: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default on-button-click-fn handler for the <GridItemContainer /> component has been called. Did you forget to pass an :on-click handler to the component?')
			},
		},
		isMilestoneDay: {
			type: Boolean,
			required: false,
			default: true,
		},
		dailyRewardsCollecting: {
			type: Boolean,
			required: false,
			default: false,
		},
		dailyRewardCollected: {
			type: Boolean,
			required: false,
			default: false,
		},
		showUnknown: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data() {
		return {}
	},
	methods: {},
}
