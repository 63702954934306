import { EnemyAI, FightingTriggers, IdleBehaviours, FightingBehaviours, LeashTriggers, LeashingBehaviours, AttackTypes, DeadBehaviours, ShotLeadPrecision, EnemyType, EnemyTargetingStyles } from '../../shared/ai-types'
import { BurstFireModes, ModType, ModValueType, ProjectileTargetType } from '../../../projectiles/shared/projectile-types'
import { SpineDataName, EnemyBipedSkinType } from '../../../models-animations/shared/spine-config'
import { degToRad } from '../../../utils/math'
import { EnemyDefaults } from './enemy-defaults'
import { Colors } from '../../../utils/colors'
import { ParticleEffectType } from '../../../engine/shared/game-data/particle-config'
import { Vector } from 'sat'
import { ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { ENEMY_DEFAULT_HEALTH } from '../../../engine/shared/game-data/enemy-formulas'
import { Ability, AbilityType, AbilityTargets, AbilityTargetSelectionStyle, AbilitySelectionStyles } from '../action-types'
import { alwaysTrueCriteria, playAnimationAbility } from './abilities/common-abilities'
import { AnimationTrack } from '../../../models-animations/shared/animation-track'
import { attackRatedAbilityList } from '../action-criteria/ability-helpers'
import { ENEMY_NAMES } from './enemy-names'

const magicalAoeAbility: Ability = {
	debugName: 'magicalAoeAbility',
	abilityType: AbilityType.SPAWN_GROUND_HAZARD,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	durationInGameTicks: 10,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		colliderRadius: 100,
		speed: 0,
		lifespanInSeconds: 0.2, // how long the projectile lasts on the ground
		baseFireDamage: 60,
		baseIceDamage: 60,
		color: Colors.green,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		pierceCount: 999,
	},
	groundHazardRules: {
		spineAsset: 'highlands-golem-aoe',
		pfx: 'aoe-explosion-highlandsgolem',
		pfxScale: 1.03,
		pfxSecondsOffset: 1.4, // how long before the PFX is created?
		projectileSecondsOffset: 1.4, // how long before it creates the projectile
		quantity: 1,
		limit: 2,
	},
}
const magicalAoe2Ability: Ability = {
	debugName: 'magicalAoeAbility',
	abilityType: AbilityType.SPAWN_GROUND_HAZARD,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	durationInGameTicks: 10,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		colliderRadius: 150,
		speed: 0,
		lifespanInSeconds: 0.2, // how long the projectile lasts on the ground
		baseFireDamage: 60,
		baseIceDamage: 60,
		color: Colors.green,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		pierceCount: 999,
	},
	groundHazardRules: {
		spineAsset: 'prism-aoe-md',
		pfx: 'aoe-explosion-highlandsgolem-md',
		pfxScale: 1,
		pfxSecondsOffset: 1.4, // how long before the PFX is created?
		projectileSecondsOffset: 1.4, // how long before it creates the projectile
		quantity: 1,
		limit: 2,
	},
}
const magicalAoe3Ability: Ability = {
	debugName: 'magicalAoeAbility',
	abilityType: AbilityType.SPAWN_GROUND_HAZARD,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	durationInGameTicks: 10,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		colliderRadius: 250,
		speed: 0,
		lifespanInSeconds: 0.2, // how long the projectile lasts on the ground
		baseFireDamage: 60,
		baseIceDamage: 60,
		color: Colors.green,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		pierceCount: 999,
	},
	groundHazardRules: {
		spineAsset: 'prism-aoe-lg',
		pfx: 'aoe-explosion-highlandsgolem-lg',
		pfxScale: 1,
		pfxSecondsOffset: 1.4, // how long before the PFX is created?
		projectileSecondsOffset: 1.4, // how long before it creates the projectile
		quantity: 1,
		limit: 2,
	},
}

const sweepingAbility: Ability = {
	debugName: 'sweepingAbility',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		projectileCount: 1,
		spreadAngle: 0,
		speed: 200,
		colliderRadius: 20,
		lifespanInSeconds: 5,
		modifiers: [{ modType: ModType.ACCELERATION, value: { modValueType: ModValueType.LIFETIME, min: 0, max: 8 } }],
		burstCount: 5,
		burstDelay: 0.15,
		burstMode: BurstFireModes.SWEEPING,
		burstAngle: degToRad(120),
		color: Colors.purple,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_BOSS,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_LIGHTNING_TRAIL,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_FIRE_SHOOT,
	},
}

const railGunStyleShotAbility: Ability = {
	debugName: 'railGunStyleShotAbility',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		projectileCount: 1,
		speed: 300,
		colliderRadius: 40,
		lifespanInSeconds: 1.5,
		modifiers: [{ modType: ModType.ACCELERATION, value: { modValueType: ModValueType.LIFETIME, min: -2, max: 12 } }],
		basePhysicalDamage: 100,
		baseIceDamage: 100,
		burstCount: 0,
		pierceCount: 5,
		color: Colors.purple,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_DRAGONSKULL,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_ICE_TRAIL,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
	},

	attackOffset: new Vector(150, -300),
	ignoreAngleOnWeaponOffset: true,
}

const animationTimes = {
	[AnimationTrack.SHOOT]: 0.3, // PLACEHOLDER
}

const animationTimesBombard = {
	[AnimationTrack.BOMBARD]: 0.5, // Timed but unused, running the other brain pattern instead.
}

const golemHighlands: EnemyAI = {
	name: ENEMY_NAMES.GOLEM_HIGHLANDS,
	type: EnemyType.BASIC,
	appearance: {
		asset: SpineDataName.GOLEM_HIGHLANDS,
		skin: EnemyBipedSkinType.DEFAULT,
		mixSettings: [],
	},
	general: {
		enemyLevel: 1,
		experienceWhenKilled: 1,
	},
	baseAttributes: {
		...EnemyDefaults.baseAttributes,
		physicalDamage: 0,
		fireDamage: 0,
		iceDamage: 0,
		lightningDamage: 0,
		poisonDamage: 0,
		attackRate: 700,
		critChance: 0,
		critDamage: 1.0,
		shockPotency: 3.0,
		shockChance: 0.25,

		maxHealth: (ENEMY_DEFAULT_HEALTH * 4) / 3,
		defense: 0,
		fireResist: 0,
		poisonResist: 0,
		iceResist: 0,
		lightningResist: 0,

		lootDropProbability: 1,
		lootDropQuantity: 1,
		lootDropRarity: 1.2,
		colliders: [
			{
				type: ColliderType.Ellipse,
				rX: 45,
				rY: 65,
				position: [0, -45],
				traits: ColliderTraits.BlockMovementAndProjectile,
			},
		],
	},
	soundEffects: {
		attack: 'Projectile_Blip',
		impact: 'SFX_Impact_Enemy_Generic',
	},
	states: {
		idling: {
			behaviour: IdleBehaviours.STATIONARY,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			transitionToFighting: {
				trigger: FightingTriggers.AGGRESSIVE_PROXIMITY,
				radius: 700,
			},
			aggroOnHit: true,
		},
		fighting: {
			behaviour: FightingBehaviours.CHASE_AND_ATTACK,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 5,
			attackType: AttackTypes.PROJECTILE,
			engagementMaxDistance: 650,
			engagementMinDistance: 0,
			movementMaxDistance: 650,
			movementMinDistance: 0,
			brain: {
				actions: [
					{
						priority: 1,
						actionCriteria: alwaysTrueCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
							abilityOptions: [
								// [5, playAnimationAbility(AnimationTrack.SHOOT, 3), 0, magicalAoeAbility, 60],
								// [5, longRangeAbility, 20],
								// [1, sweepingAbility, 40],
								[2, playAnimationAbility(AnimationTrack.BOMBARD, 10), magicalAoeAbility, 15, playAnimationAbility(AnimationTrack.BOMBARD, 10), magicalAoe2Ability, 15, playAnimationAbility(AnimationTrack.BOMBARD, 10), magicalAoe3Ability, 20],
								// attackRatedAbilityList(5, animationTimes, AnimationTrack.SHOOT, longRangeAbility, 20),
								attackRatedAbilityList(2, animationTimes, AnimationTrack.SHOOT, railGunStyleShotAbility, 40),
							],
						},
					},
				],
			},
			attackOptions: [],
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 1,
			transitionToLeashing: {
				trigger: LeashTriggers.DISTANCE_TRAVELLED_OR_RADIUS_EXCEEDED,
				radius: 2000,
				distanceTravelled: 4000,
			},
			targetType: ProjectileTargetType.PLAYER
		},
		leashing: {
			behaviour: LeashingBehaviours.MOVE,
			speedMultiplier: 4,
		},
		fleeing: {},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 0.2,
		},
	},
}

export default golemHighlands
