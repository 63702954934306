/** Enum of buff identifiers and their l18n display strings */
export enum BuffIdentifier {
	// Shops & Outposts
	StartRunMovementSpeed = 'buffStartRunMovementSpeed',
	OutpostLuckyBoon = 'buffOutpostLuckyBoon',
	OutpostHunterHunted = 'buffOutpostHunterHunted',
	OutpostGottaGoFast = 'buffOutpostGottaGoFast',
	OutpostPoisonApple = 'buffOutpostPoisonApple',
	OutpostIronShield = 'buffOutpostIronShield',
	OutpostMagmaShield = 'buffOutpostMagmaShield',
	OutpostFrostShield = 'buffOutpostFrostShield',
	OutpostToxicShield = 'buffOutpostToxicShield',
	OutpostElectricShield = 'buffOutpostElectricShield',

	// Account persistent buffs
	StartRunEnchantment = 'buffStartRunEnchantment',
	LifeBloom = 'buffLifeBloom',
	LifeBloomRegen = 'buffLifeBloomRegen',
	RegenerativeHealing = 'buffRegenerativeHealing',
	QueensRansom = 'buffQueensRansom',
	QueensRansomLegendary = 'buffQueensRansomLegendary',
	FinalCountdown = 'buffFinalCountdown',
	IronMan = 'buffIronMan',
	Pauper = 'buffPauper',
	RagsToRiches = 'buffRagsToRiches',

	// Players
	FlagDoubleTap = 'buffFlagDoubleTap',
	FlagTripleTap = 'buffFlagTripleTap',
	SuperBeam = 'buffSuperBeam',
	OnKillMovementSpeed = 'buffOnKillMovementSpeed',
	OnShieldBreakMovementSpeed = 'buffOnShieldBreakMovementSpeed',

	// Pressure buffs
	PressureThirstyVampire = 'buffPressureThirstyVampire',
	PressureCreepingWeakness = 'buffPressureCreepingWeakness',

	// Factions
	FactionAurumPersist = 'buffAurumPersistent',
	FactionIronPersist = 'buffIronPersistent',
	FactionDawnPersist = 'buffDawnPersistent',

	// Shared
	Invulnerable = 'buffInvulnerable',
	NoCollideWithProjectile = 'buffNoCollideWithProjectile',
	Confusion = 'buffConfusion',
	OnFire = 'buffOnFire',

	// Elemental status ailments
	Poison = 'buffPoison',
	Ignite = 'buffIgnite',
	Bleed = 'buffBleed',
	BleedStackImmune = 'buffBleedStackImmune',
	Chill = 'buffChill',
	ChilledResistPlayer = 'buffChilledResistPlayer',
	ChilledResistEnemy = 'buffChilledResistEnemy',
	Chilled = 'buffChilled',
	Shock = 'buffShock',
	StunResistPlayer = 'buffStunResistPlayer',
	StunResistEnemy = 'buffStunResistEnemy',
	Stun = 'buffStun',

	// Skills
	SkillMovementSpeed = 'buffSkillMovementSpeed',
	SkillBerserk = 'buffSkillBerserk',
	SkillTumbleRoll = 'buffSkillTumbleRoll',
	SkillHeavyArmor = 'buffSkillHeavyArmor',
	SkillOvershield = 'buffSkillOvershield',
	SkillPhaseOut = 'buffSkillPhaseOut',
	SkillStoneForm = 'buffSkillStoneForm',
	SkillOverchargedShot = 'buffSkillOverchargedShot',
	SkillBattleCry = 'buffSkillBattleCry',
	SkillSickeningNova = 'buffSkillSickeningNova',
	SkillBattery = 'buffSkillBattery',

	// Enemies
	KnockbackResist = 'buffKnockbackResist',
	DelayedKnockbackResist = 'buffDelayedKnockbackResist',
	//EnemySporeKidHealing = 'buffEnemySporeKidHealing',
	EnemyOscillatingMovementSpeed = 'buffEnemyOscillatingMovementSpeed',
	EnemyAutoBerserkAtLowHealth = 'buffEnemyBerserkAtLowHealth',
	BeachBossBlock = 'buffBeachBossBlock',
	HermitCrabBlock = 'buffHermitCrabBlock',
	BoneSpiritHealing = 'buffBoneSpiritHealing',
	SporeKidHealing = 'buffSporeKidHealing',
	EnemySporeKidWeakness = 'buffEnemySporeKidWeakness',
	EnemyCrabMovementSpeedSlow = 'buffEnemyCrabMovementSpeedSlow',
	EnemyCrabMovementSpeedSlowDifficult = 'buffEnemyCrabMovementSpeedSlowDifficult',
	EnemyShriekerBeShrieking = 'buffEnemyShriekerBeShrieking',
	EnemyShriekerBeShriekingT3 = 'buffEnemyShriekerBeShriekingT3',
	EnemyMosquitoMovementSpeedBoost = 'buffEnemyMosquitoMovementSpeedBoost',
	EnemyPrismBossGustWeakness = 'buffEnemyPrismBossGustWeakness',
	EnemyPrismBossCocoon = 'buffPrismBossCocoon',
	EnemyShardGuard = 'buffEnemyShardGuard',
	EnemyHighlandsBossOnyxShield = 'buffHighlandsBossOnyxShield',
	EnemyHighlandsBossOnyxShieldExplode = 'buffHighlandsBossOnyxShieldLow',
	EnemyHighlandsBossInvulnerable = 'buffHighlandsBossInvulnerable',
	EnemyHighlandsBossPetrifyingWave = 'buffEnemyHighlandsBossPetrifyingWave',
	EnemyForestBossHeal = 'buffForestBossHeal',
	EnemyForestBossHealBrutal = 'buffForestBossHealBrutal',
	EnemyPrismflyMovementSlow = 'buffEnemyPrismflyMovementSlow',
	CrystalSpiderDebuff = 'buffCrystalSpiderDebuff',
	CrystalSpiderBlock = 'buffCrystalSpiderBlock',
	// Generic Timer Cooldown buffs, listed in millseconds.
	EnemyCooldown15000 = 'buffEnemyCooldown15000',
	EnemyCooldown10000 = 'buffEnemyCooldown10000',
	EnemyCooldown5000 = 'buffEnemyCooldown5000',
	EnemyCooldown3000 = 'buffEnemyCooldown3000',
	EnemyCooldown1000PerStack = 'buffEnemyCooldown1000PerStack',
}

export const MAX_VISIBLE_BUFFS = 6

const buffOrDebuffMap: { [k in BuffIdentifier]: 'buff' | 'debuff' } = {
	buffStartRunMovementSpeed: 'buff',
	buffLifeBloom: 'buff',
	buffLifeBloomRegen: 'buff',
	buffRegenerativeHealing: 'buff',
	buffStartRunEnchantment: 'buff',
	buffOutpostLuckyBoon: 'buff',
	buffOutpostHunterHunted: 'buff',
	buffOutpostGottaGoFast: 'buff',
	buffOutpostPoisonApple: 'buff',
	buffOutpostIronShield: 'buff',
	buffOutpostMagmaShield: 'buff',
	buffOutpostFrostShield: 'buff',
	buffOutpostToxicShield: 'buff',
	buffOutpostElectricShield: 'buff',
	buffOnShieldBreakMovementSpeed: 'buff',
	buffFlagDoubleTap: 'buff',
	buffFlagTripleTap: 'buff',
	buffSuperBeam: 'buff',
	buffBeachBossBlock: 'buff',
	buffChill: 'debuff',
	buffConfusion: 'debuff',
	buffCrystalSpiderBlock: 'buff',
	buffCrystalSpiderDebuff: 'debuff',
	buffEnemyBerserkAtLowHealth: 'buff',
	buffEnemyCrabMovementSpeedSlow: 'debuff',
	buffEnemyCrabMovementSpeedSlowDifficult: 'debuff',
	buffEnemyMosquitoMovementSpeedBoost: 'buff',
	buffEnemyOscillatingMovementSpeed: 'buff',
	buffEnemyShriekerBeShrieking: 'buff',
	buffEnemyShriekerBeShriekingT3: 'buff',
	// buffEnemySporeKidHealing: 'buff',
	buffHermitCrabBlock: 'buff',
	buffInvulnerable: 'buff',
	buffNoCollideWithProjectile: 'buff',
	buffOnFire: 'debuff',
	buffPoison: 'debuff',
	buffShock: 'debuff',
	buffSkillBerserk: 'buff',
	buffSkillHeavyArmor: 'buff',
	buffSkillTumbleRoll: 'buff',
	buffSkillMovementSpeed: 'buff',
	buffSkillOvershield: 'buff',
	buffSkillPhaseOut: 'buff',
	buffSkillStoneForm: 'buff',
	buffSkillOverchargedShot: 'buff',
	buffSkillBattleCry: 'buff',
	buffSkillSickeningNova: 'debuff',
	buffSkillBattery: 'buff',
	buffBleed: 'debuff',
	buffBleedStackImmune: 'debuff',
	buffChilled: 'debuff',
	buffChilledResistPlayer: 'buff',
	buffChilledResistEnemy: 'buff',
	buffEnemyPrismBossGustWeakness: 'debuff',
	buffIgnite: 'debuff',
	buffPrismBossCocoon: 'buff',
	buffStun: 'debuff',
	buffOnKillMovementSpeed: 'buff',
	buffStunResistPlayer: 'buff',
	buffStunResistEnemy: 'buff',
	buffHighlandsBossOnyxShield: 'buff',
	buffHighlandsBossOnyxShieldLow: 'buff',
	buffHighlandsBossInvulnerable: 'buff',
	buffEnemyHighlandsBossPetrifyingWave: 'debuff',
	buffEnemyCooldown15000: 'debuff',
	buffEnemyCooldown10000: 'debuff',
	buffEnemyCooldown5000: 'debuff',
	buffEnemyCooldown3000: 'debuff',
	buffEnemyCooldown1000PerStack: 'debuff',
	buffForestBossHeal: 'buff',
	buffForestBossHealBrutal: 'buff',
	buffEnemyPrismflyMovementSlow: 'debuff',
	buffEnemyShardGuard: 'buff',
	buffBoneSpiritHealing: 'buff',
	buffSporeKidHealing: 'buff',
	buffEnemySporeKidWeakness: 'debuff',
	buffKnockbackResist: 'buff',
	buffDelayedKnockbackResist: 'buff',
	buffPressureCreepingWeakness: 'debuff',
	buffPressureThirstyVampire: 'debuff',
	buffAurumPersistent: 'buff',
	buffDawnPersistent: 'buff',
	buffIronPersistent: 'buff',
	buffQueensRansom: 'buff',
	buffQueensRansomLegendary: 'buff',
	buffFinalCountdown: 'buff',
	buffIronMan: 'buff',
	buffPauper: 'buff',
	buffRagsToRiches: 'buff',
}

export function buffType(buffIdentifier: '' | BuffIdentifier): 'buff' | 'debuff' | 'empty' {
	if (buffIdentifier === '') {
		return 'empty'
	} else {
		return buffOrDebuffMap[buffIdentifier]
	}
}

export function getLocalizedBuffText(buffIdentifier): LocalizedBuffText {
	return buffTextByLanguage.en[buffIdentifier]
}

const uiIgnoredBuffs = ['buffChill', 'buffShock', 'buffChilledResist', 'buffStunResist']
export function shouldIgnoreBuffUI(buffIdentifier): boolean {
	// TODO3: this should use the BuffDefinition's `showToClient` property... or not
	return uiIgnoredBuffs.includes(buffIdentifier)
}

type Languages = 'en'

interface LocalizedBuffText {
	name: string
	effect: string
}

type BuffLanguageMap = { [l in Languages]: { [k in BuffIdentifier]: LocalizedBuffText } }

const buffIcons: { [k in BuffIdentifier]?: string } = {
	buffStartRunMovementSpeed: 'speed-boost',
	buffOnShieldBreakMovementSpeed: 'speed-boost',
	buffFlagDoubleTap: 'overcharged-shot',
	buffFlagTripleTap: 'overcharged-shot',
	buffSuperBeam: 'enrage',
	buffChill: 'freezing',
	buffChilled: 'freezing',
	buffChilledResistPlayer: 'freezing',
	buffChilledResistEnemy: 'freezing',
	buffConfusion: 'stunned',
	buffInvulnerable: 'defense',
	buffNoCollideWithProjectile: 'long-range-dodge-roll',
	buffOnFire: 'burning',
	buffPoison: 'poison',
	buffSkillBerserk: 'enrage',
	buffSkillHeavyArmor: 'defense',
	buffSkillMovementSpeed: 'speed-boost',
	buffSkillOvershield: 'bulwark',
	buffSkillPhaseOut: 'blink',
	buffSkillStoneForm: 'petrified',
	buffSkillOverchargedShot: 'overcharged-shot',
	buffSkillBattleCry: 'battle-cry',
	buffSkillSickeningNova: 'sickening-nova',
	buffSkillTumbleRoll: 'short-range-tumble-roll',
	buffSkillBattery: 'battery',

	buffOutpostLuckyBoon: 'luck',
	buffOutpostHunterHunted: 'outpost-hunter-hunted',
	buffOutpostGottaGoFast: 'outpost-go-fast',
	buffOutpostPoisonApple: 'outpost-poison-apple',
	buffOutpostIronShield: 'outpost-physical-armor',
	buffOutpostMagmaShield: 'outpost-fire-armor',
	buffOutpostFrostShield: 'outpost-ice-armor',
	buffOutpostToxicShield: 'outpost-poison-armor',
	buffOutpostElectricShield: 'outpost-lightning-armor',

	buffEnemySporeKidWeakness: 'stunned', // Do we not have a weakness one?
	buffEnemyCrabMovementSpeedSlow: 'speed-slowed',
	buffEnemyPrismBossGustWeakness: 'stunned',
	buffEnemyPrismflyMovementSlow: 'speed-slowed',
	// TODO2 ask dan about this
	buffCrystalSpiderDebuff: 'petrified',
	buffBleed: 'bleeding',
	buffBleedStackImmune: 'bleeding',
	buffIgnite: 'burning',
	buffStun: 'stunned',
	buffOnKillMovementSpeed: 'speed-boost',
	buffEnemyCrabMovementSpeedSlowDifficult: 'speed-slowed',
	buffEnemyHighlandsBossPetrifyingWave: 'petrified',
	buffPressureCreepingWeakness: 'heat-creeping-weakness',
	buffPressureThirstyVampire: 'heat-thirsty-vampire',

	buffStartRunEnchantment: 'enchanting',
	buffLifeBloom: 'life-bloom',
	buffLifeBloomRegen: 'life-bloom',
	buffRegenerativeHealing: 'regenerative-healing',
	buffQueensRansom: 'queens-ransom',
	buffQueensRansomLegendary: 'queens-ransom',
	buffFinalCountdown: 'final-countdown',
	buffIronMan: 'ironman',
	buffPauper: 'pauper',
	buffRagsToRiches: 'rags-to-riches',

	buffAurumPersistent: 'aurum-persist',
	buffIronPersistent: 'iron-persist',
	buffDawnPersistent: 'dawn-persist',

	// TODO2 Get art assets for these
	buffShock: 'blink',
	buffStunResistPlayer: 'bulwark',
	buffStunResistEnemy: 'bulwark',
}

export function getBuffIconByIdentifier(identifier: string) {
	return buffIcons[identifier] || ''
}

// Right now this includes buffs that the player shouldn't end up having, but if we end
// up showing buffs or debuffs on enemies we'd want to localize them as well.
const buffTextByLanguage: BuffLanguageMap = {
	en: {
		buffStartRunMovementSpeed: { name: 'Movement Speed', effect: 'You\'re a little speedier at the start of the run!' },
		buffStartRunEnchantment: { name: 'Enchantment Potion', effect: `You'll be able to pick an enchantment at the start of your runs!` },
		buffLifeBloom: { name: 'Lifebloom Potion', effect: 'Picking up hearts heals an additional 5% of your health over 10 seconds!' },
		buffLifeBloomRegen: { name: 'Lifebloom Regen', effect: 'Regenerating health from a heart pickup' },
		buffRegenerativeHealing: { name: 'Regenerative Healing', effect: 'Receive 33% more healing from all sources' },
		buffOutpostLuckyBoon: { name: '"Lucky" Boon', effect: `Probably helps... in some way!` },
		buffOutpostHunterHunted: { name: 'Hunter Hunted', effect: `Deal 25% additional damage, but your shield delay and regen twice as slow.` },
		buffOutpostGottaGoFast: { name: 'Gotta Go Fast', effect: `Gain 40% movement speed. WARNING: Wears off when you kill an enemy or enter a boss arena.` },
		buffOutpostPoisonApple: { name: 'Poison Apple', effect: `All hearts heal for 300% more health, but you slowly take damage-over-time.` },
		buffOutpostIronShield: { name: 'Iron Shield', effect: `You take 50% less physical damage. You take 25% more non-physical damage.` },
		buffOutpostMagmaShield: { name: 'Magma Shield', effect: `You take 50% less fire damage. You take 25% more non-fire damage.` },
		buffOutpostFrostShield: { name: 'Frost Shield', effect: `You take 50% less ice damage. You take 25% more non-ice damage.` },
		buffOutpostToxicShield: { name: 'Toxic Shield', effect: `You take 50% less poison damage. You take 25% more non-poison damage.` },
		buffOutpostElectricShield: { name: 'Electric Shield', effect: `You take 50% less lightning damage. You take 25% more non-lightning damage.` },
		buffOnShieldBreakMovementSpeed: { name: 'Shield Panic', effect: `Your shields broke and you're zoomin'!` },
		buffFlagDoubleTap: { name: 'Double Tap', effect: 'You gotta be sure.' },
		buffFlagTripleTap: { name: 'Triple Tap', effect: 'Just in case.' },
		buffSuperBeam: { name: 'Super Beam', effect: 'Your beam is blisteringly strong! ' },
		buffBeachBossBlock: { name: '', effect: '' },
		buffBleed: { name: 'Bleed', effect: 'If you take any more hits, you might bleed out!' },
		buffBleedStackImmune: { name: 'Bleed Immune', effect: 'Briefly immune to receiving Bleeds' },
		buffChill: { name: 'Chill', effect: `You're getting colder, and could be chilled. No, this is too chill.` },
		buffChilled: { name: 'Chilled', effect: 'Your movement speed and attack rate are slowed. So chill, dude.' },
		buffChilledResistPlayer: { name: 'Chill', effect: 'Protected from Chilled.' },
		buffChilledResistEnemy: { name: 'Chill', effect: 'Protected from Chilled.' },
		buffConfusion: { name: 'Confusion', effect: 'Where am I, why am I slow?' },
		buffCrystalSpiderBlock: { name: '', effect: '' },
		buffCrystalSpiderDebuff: { name: 'Crystal Spider Debuff', effect: 'Sounds Real Bad' },
		buffEnemyBerserkAtLowHealth: { name: '', effect: '' },
		buffEnemyCrabMovementSpeedSlow: { name: 'Crab Slow', effect: 'Your movement speed is slowed. <br /> This crab snipped your toes!' },
		buffEnemyCrabMovementSpeedSlowDifficult: { name: 'Crab Slower', effect: 'This crab snipped your toes clean off!' },
		buffEnemyMosquitoMovementSpeedBoost: { name: '', effect: '' },
		buffEnemyOscillatingMovementSpeed: { name: '', effect: '' },
		buffEnemyShriekerBeShrieking: { name: '', effect: '' },
		buffEnemyShriekerBeShriekingT3: { name: '', effect: '' },
		buffSporeKidHealing: { name: 'Healing Spore', effect: 'The Healing Spore of a Spore Kid' },
		buffHermitCrabBlock: { name: '', effect: '' },
		buffInvulnerable: { name: 'Invulnerable', effect: 'You can\'t be harmed.' },
		buffNoCollideWithProjectile: { name: 'Dodge Roll', effect: `You can roll right through projectiles` },
		buffOnFire: { name: 'Fire', effect: 'You\'re on fire! In a bad way!' },
		buffIgnite: { name: 'Ignite', effect: 'You are taking fire damage-over-time. You\'re on fire! In a bad way!' },
		buffPoison: { name: 'Poison', effect: 'You are taking poison damage-over-time. Oof, ouch, owie. Damage over time.' },
		buffShock: { name: 'Shock', effect: `You're building electricity, and could be shocked. Electrifying!` },
		buffStun: { name: 'Stun', effect: 'You are stunned and unable to act. I\'m stunned this has happened.' },
		buffOnKillMovementSpeed: { name: 'Killer Speed', effect: 'You\'re really moving, killer!' },
		buffStunResistPlayer: { name: 'Stun Resist', effect: 'Stun no longer has a negative effect on me' },
		buffStunResistEnemy: { name: 'Stun Resist', effect: 'Stun no longer has a negative effect on me' },
		buffSkillBerserk: { name: 'Berserk', effect: 'You\'re filled with rage, faster attacks! Hopefully you can still aim!' },
		buffSkillHeavyArmor: { name: 'Heavy Armour', effect: 'The best defense is thick plate, actually.' },
		buffSkillTumbleRoll: { name: 'Tumble Roll', effect: 'Tumbling lets you shoot faster.' },
		buffSkillMovementSpeed: { name: 'Movement Speed', effect: 'Gotta go fast' },
		buffSkillOvershield: { name: 'Overshield', effect: 'When it rains magic, get a magic umbrella' },
		buffSkillPhaseOut: { name: '', effect: '' },
		buffSkillStoneForm: { name: 'Stone Form', effect: 'Rock and Stone, Bretheren' },
		buffSkillOverchargedShot: { name: 'Overcharged Shot', effect: 'Your next shot will deal EXTREME damage!' },
		buffSkillBattleCry: { name: 'Battle Cry', effect: 'A player has boosted your morale, FIGHT!' },
		buffSkillSickeningNova: { name: 'Sickening Nova', effect: 'A nasty toxin has weakened you!' },
		buffEnemyPrismBossGustWeakness: { name: 'Weakened', effect: 'The wing gust disoriented you!' },
		buffPrismBossCocoon: { name: 'Crystal Cocoon', effect: 'Invulnerable and healing! Defeat the minions!' },
		buffHighlandsBossOnyxShield: { name: 'Onyx Shield', effect: 'Absorbing damage, watch out!' },
		buffHighlandsBossOnyxShieldLow: { name: 'Onyx Shield', effect: 'Absorbing damage, watch out!' },
		buffHighlandsBossInvulnerable: { name: 'Invulnerable', effect: 'Invulnerable!' },
		buffEnemyHighlandsBossPetrifyingWave: { name: 'Petrified', effect: 'You look rather... stoned :)' },
		buffEnemyCooldown15000: { name: '', effect: '' },
		buffEnemyCooldown10000: { name: '', effect: '' },
		buffEnemyCooldown5000: { name: '', effect: '' },
		buffEnemyCooldown3000: { name: '', effect: '' },
		buffEnemyCooldown1000PerStack: { name: '', effect: '' },
		buffForestBossHeal: { name: 'Healing Spring', effect: 'Healing! Quick, knock her out of the spring!' },
		buffForestBossHealBrutal: { name: 'Healing Spring', effect: 'Healing Fast! Quick, knock her out of the spring!' },
		buffEnemyPrismflyMovementSlow: { name: 'Prismfly Slow', effect: 'Slowed! Big oof!' },
		buffEnemyShardGuard: { name: '', effect: '' },
		buffBoneSpiritHealing: { name: 'Healing Spirit', effect: 'The monsters are healing.' },
		buffEnemySporeKidWeakness: { name: 'Weakness', effect: 'Strength sapping spore suckers' },
		buffKnockbackResist: { name: 'Knockback Resistant', effect: 'Resists the effects of knockback and vacuum.' },
		buffDelayedKnockbackResist: { name: '', effect: '' },
		buffSkillBattery: { name: 'Battery', effect: `I'm a turret!` },
		buffPressureThirstyVampire: { name: 'Thirsty Vampire', effect: `You're losing health, but more hearts drop!` },
		buffPressureCreepingWeakness: { name: 'Creeping Weakness', effect: `Your damage is steadily dropping!` },
		buffQueensRansom: {name: 'Queen\'s Ransom', effect: 'Find more loot!'},
		buffQueensRansomLegendary: {name: 'Queen\'s Ransom', effect: 'Find much more loot!'},
		buffFinalCountdown: {name: 'Final Countdown', effect: 'Find more loot and coins, but take damage over time!'},
		buffIronMan: {name: 'Ironman', effect: 'Find much more loot but lose all shields!'},
		buffPauper: {name: 'Pauper', effect: 'Gain increased damage, attack rate, all damage resistance and an extra shield when only Common or Uncommon items and augments are equipped.'},
		buffRagsToRiches: {name: 'Rags to Riches', effect: 'Find additional loot based on the rarity of your equipped items.'},
		buffAurumPersistent: { name: 'Aurum Alliance Faction Affiliation', effect: 'As a member of Aurum Alliance, you crit harder and a little more often!' },
		buffIronPersistent: { name: 'Order of Iron Faction Affiliation', effect: 'As a member of Order Of Iron, you do a little more physical damage and drop a few more hearts!' },
		buffDawnPersistent: { name: 'Scions of Dawn Faction Affiliation', effect: 'As a member of Scions of Dawn, your ignites are a little stronger and your shields recharge a bit faster!' },
	},
}

// this exists so that we can have a pfx per buff, and not require 2 buffs per skill
export const InvulnerableBuffs = [BuffIdentifier.SkillPhaseOut, BuffIdentifier.Invulnerable, BuffIdentifier.EnemyPrismBossCocoon, BuffIdentifier.EnemyHighlandsBossInvulnerable]
