









import { mapMutations } from 'vuex'
import { debounce } from 'lodash'

export default {
	name: 'TutorialTooltip',
	props: {
		title: {
			type: String,
			default: 'Give Me A Title',
		},
		target: {
			type: String,
			default: 'shields',
		},
		text: {
			type: String,
			default: 'Give Me A Description',
		},
		position: {
			type: String,
			default: 'bottom',
		},
	},
	data() {
		return {
			topOffset: 0,
			leftOffset: 0,
			resizeDebounced: null,
		}
	},
	created() {
		this.rebuildOffsets()
		this.resizeDebounced = debounce(this.rebuildOffsets, 300, { trailing: true })
		window.addEventListener('resize', this.resizeDebounced)
	},
	destroyed() {
		window.removeEventListener('resize', this.resizeDebounced)
	},
	methods: {
		...mapMutations('hud', ['clearTutorialTooltip']),
		clearThisTutorialTooltip() {
			return this.clearTutorialTooltip(this.target)
		},
		rebuildOffsets() {
			const elementIdsByTarget = {
				weaponsAndGear: 'active-weapons-tutorial-tooltip-target',
				health: 'health-bar-tutorial-tooltip-target',
				shields: 'shields-tutorial-tooltip-target',
				firstWeapon: 'first-weapon-tutorial-tooltip-target',
			}

			const STATIC_X_OFFSET = 50
			const STATIC_Y_OFFSET_FOR_BOTTOM_POSITIONED_TOOLTIPS = 100
			const STATIC_Y_OFFSET_FOR_TOP_POSITIONED_TOOLTIPS = -100

			const elementId = elementIdsByTarget[this.target]
			if (elementId === undefined) {
				console.error('Unknown mapping from tutorial tooltip target to DOM element. Did you forget to add it to "elementIdsByTarget" mapping?')
				this.topOffset = 0
				this.leftOffset = 0
				return
			}

			const attachedElement = document.querySelector(`#${elementId}`)

			const bodyRect = document.body.getBoundingClientRect(),
				elemRect = attachedElement.getBoundingClientRect(),
				topOffset = elemRect.top - bodyRect.top,
				leftOffset = elemRect.left - bodyRect.left

			if (this.position === 'top') {
				this.topOffset = `${topOffset + STATIC_Y_OFFSET_FOR_TOP_POSITIONED_TOOLTIPS}px`
				this.leftOffset = `${leftOffset + STATIC_X_OFFSET}px`
			} else {
				this.topOffset = `${topOffset + STATIC_Y_OFFSET_FOR_BOTTOM_POSITIONED_TOOLTIPS}px`
				this.leftOffset = `${leftOffset + STATIC_X_OFFSET}px`
			}
		},
	},
}
