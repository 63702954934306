import { NengiClient } from '../../engine/client/nengi-client'
import { endGame, startGame } from '../../engine/client/start-stop'
import DestroyItemFromInventoryCommand from '../../items/shared/destroy-item-from-inventory-command'
import RemoveItemFromWormMailCommand from '../../items/shared/remove-item-from-worm-mail-command'
import SendItemToWormMailCommand from '../../items/shared/send-item-to-worm-mail-command'
import SendWormAwayCommand from '../../items/shared/send-worm-away-command'
import logger from '../../utils/client-logger'
import { getClientPOIFromLocalPlayer } from '../../world/client/poi.client'
import { UI } from '../ui'
import { showGenericYesNoUI } from './generic-yes-no.ui-state'
import { analyticsEventWormMailSent } from './google-analyitics'
import { WormHornSubtype } from '../../loot/shared/worm-horn-sub-type'
import UseWormHornCommand from '../../items/shared/use-worm-horn-command'
import { inFurthestBiome } from '../../engine/shared/game-data/progression'
import { WorldTier } from '../../utils/primitive-types'
import DismissWormHornCommand from '../../items/shared/dismiss-worm-horn-command'
import { GameClient } from '../../engine/client/game-client'
import PartyExitGameCommand from '../../party-play/shared/party-exit-game-command'

interface OutpostWormMailUIState {
	wormSendInProgress: boolean
	interactingWithWormId: number
	selectedOutpostWormMailItem: any
	wormHornPromptVisible: boolean
	wormHornTypeSelected: WormHornSubtype
	wormsSentFrom: any
}

export const outpostWormMail = () => {
	logger.debug('Initializing Outpost Worm Mail UI module')
	return {
		namespaced: true,
		state: {
			selectedOutpostWormMailItem: null,
			wormSendInProgress: false,
			interactingWithWormId: null,
			wormHornPromptVisible: false,
			wormHornTypeSelected: null,
			wormHornItems: [],
			wormsSentFrom:[],
		} as OutpostWormMailUIState,
		mutations: {
			interactingWithWormId(state: OutpostWormMailUIState, wormId: number) {
				state.interactingWithWormId = wormId
			},
			wormDoneSending(state: OutpostWormMailUIState, wormIdAndBiomeIndex:{wormId: number, biomeIndex: number}) {
				const {wormId, biomeIndex} = wormIdAndBiomeIndex
				if (state.interactingWithWormId !== wormId) {
					console.warn(`Got worm done sending msg for worm ${wormId} but expected matching worm ${state.interactingWithWormId} - possible client/server desync?`)
				}
				state.interactingWithWormId = null
				state.wormSendInProgress = false
				state.wormsSentFrom.push({biomeIdx: biomeIndex})
				
				const uiInst = UI.getInstance()
				if (uiInst.store.state.inGame.activePanel === 'outpostWormMail') {
					UI.getInstance().emitAsyncEvent('inGame/closeActivePanel')
				}
			},
			wormDoneSendingNoCheck(state) {
				state.wormSendInProgress = false
			},
			toggleWormHornPrompt(state: OutpostWormMailUIState) {
				state.wormHornPromptVisible = !state.wormHornPromptVisible
			},
			setWormHornType(state: OutpostWormMailUIState, hornType: number) {
				state.wormHornTypeSelected = hornType
			},
			addItemToWormHornCargo(state, itemId) {
				const itemDetails = UI.getInstance().store.getters['itemContainers/itemDetails']
				const item = itemDetails('inventory', itemId)
				if (state.wormHornItems.includes(item)) {
					return
				}
				state.wormHornItems.push(item)
			},
			removeItemFromWormHornCargo(state, itemId) {
				state.wormHornItems.remove(itemId)
			},
			resetWormSentFrom(state){
				state.wormsSentFrom = []
			}
		},
		getters: {
			selectedOutpostWormMailItem(state: OutpostWormMailUIState) {
				return state.selectedOutpostWormMailItem
			},
			wormCurrentlyBeingInteractedWith(state: OutpostWormMailUIState) {
				return state.interactingWithWormId
			},
			wormMailSendInProgress(state: OutpostWormMailUIState) {
				return state.wormSendInProgress
			},
			getWormHornPrompt(state: OutpostWormMailUIState) {
				return state.wormHornPromptVisible
			},
			getWormHornTypeSelected(state: OutpostWormMailUIState) {
				return state.wormHornTypeSelected
			},
		},
		actions: {
			toggleSelectOutpostWormMailItem(context, selectedItem?: any) {
				const { state, commit } = context
				if (selectedItem && selectedItem !== state.selectedOutpostWormMailItem) {
					commit(
						'itemComparison/selectItem',
						{
							itemId: selectedItem,
							container: 'inventory',
						},
						{ root: true },
					)
					state.selectedOutpostWormMailItem = selectedItem
				} else {
					commit('itemComparison/deselectItem', {}, { root: true })
					state.selectedOutpostWormMailItem = null
				}
			},
			sendItemToWormMail(context, itemId) {
				const { state, commit } = context
				commit('itemLocks/lockItem', itemId, { root: true })
				NengiClient.getInstance().sendCommand(new SendItemToWormMailCommand(itemId, state.interactingWithWormId))
			},
			removeItemFromWormMail(context, itemId) {
				const { state, commit } = context
				commit('itemLocks/lockItem', itemId, { root: true })
				NengiClient.getInstance().sendCommand(new RemoveItemFromWormMailCommand(itemId.id, state.interactingWithWormId))
			},
			sendWormAway({ commit, rootState, state, rootGetters }) {
				const username = UI.getInstance().store.getters['user/username']
				const userId = UI.getInstance().store.getters['user/userId']
				const partyId = UI.getInstance().store.getters['party/getPartyId']
				const currentBiome = usersCurrentOutpostBiome()

				state.wormSendInProgress = true

				NengiClient.getInstance().sendCommand(new SendWormAwayCommand(state.interactingWithWormId))

				analyticsEventWormMailSent(currentBiome, username, userId)

				const progressionLevel = rootState.hud.progressionLevel
				if (inFurthestBiomeOutpost(progressionLevel, rootState.hud.aci)) {
					const doneFirstTut = rootGetters['ftueManager/getFlag']('startedSecondRun')
					if (doneFirstTut) {
						showGenericYesNoUI(
							'Head To Town?',
							'Stay adventuring or return to town?',
							'',
							'Return To Town',
							'Stay',
							// left, right
							() => {
								if (partyId) {
									GameClient.getInstance().exitGameFunction = (() => {
										endGame()
										// @ts-expect-error TODO2: store this not on the window
										startGame('hub', window.originalConnectionPayload, partyId)
									})

									NengiClient.getInstance().sendCommand(new PartyExitGameCommand(partyId))
								} else {
									endGame()
									// @ts-expect-error TODO2: store this not on the window
									startGame('hub', window.originalConnectionPayload, partyId)
								}
							},
							() => {
								commit('hud/setCurrentArrowSearch', 'boss', { root: true })
							},
							'destroy',
							'default',
							1.5,
							0,
						)
					}
				} else {
					const inventory = rootState.itemContainers.inventory
					const inventoryLength = inventory.length
					if (inventoryLength > 0) {
						showGenericYesNoUI(
							'Clear Inventory',
							`You've got some leftover loot you didn't send in the worm. Would you like to delete the loot gained this run from your inventory?`,
							'',
							`Toss All Loot!`,
							'Keep Loot',
							() => {
								for (let i = 0; i < inventory.length; i++) {
									const element = inventory[i]
									const itemId = element.id
									commit('itemLocks/lockItem', itemId, { root: true })
									NengiClient.getInstance().sendCommand(new DestroyItemFromInventoryCommand(itemId))
								}
							},
							undefined,
							'destroy',
							'default',
						)
					}
				}
			},
			useWormHorn({ commit, state }, payload) {
				const wormHornSubtype = payload
				commit('toggleWormHornPrompt')
				commit('setWormHornType', wormHornSubtype)
			},
			dismissWormHornPrompt({ commit, state, rootState }) {
				commit('toggleWormHornPrompt')
				commit('inGame/setPanelSwitchable', true, { root: true })
				commit('inGame/setActivePanel', 'gameOver', { root: true })
				NengiClient.getInstance().sendCommand(new DismissWormHornCommand())
			},
			resetAll({ state }) {
				state.wormHornPromptVisible = false
				state.wormHornTypeSelected = null
				state.wormHornItems.length = 0
			},
			sendWormHorn({ commit, state }) {
				state.wormSendInProgress = true
				const sendingIds = state.wormHornItems.map((item) => item.id)
				NengiClient.getInstance().sendCommand(new UseWormHornCommand(state.wormHornTypeSelected === WormHornSubtype.Standard, sendingIds))
			},
		},
	}
}

function inFurthestBiomeOutpost(progressionLevel: number, activeWorldTier: WorldTier) {
	const poiPlayerIsAt = getClientPOIFromLocalPlayer()
	const currentBiome = poiPlayerIsAt.biomeIndex

	return inFurthestBiome(progressionLevel, currentBiome, activeWorldTier)
}

function usersCurrentOutpostBiome() {
	const poiPlayerIsAt = getClientPOIFromLocalPlayer()
	return poiPlayerIsAt.biomeIndex
}
