import { EnemyAI, FightingTriggers, IdleBehaviours, FightingBehaviours, LeashTriggers, LeashingBehaviours, AttackTypes, DeadBehaviours, ShotLeadPrecision, EnemyType, EnemyTargetingStyles } from '../ai-types'
import { ModType, ModValueType, BurstFireModes } from '../../../projectiles/shared/projectile-types'
import { SpineDataName, EnemyBipedSkinType } from '../../../models-animations/shared/spine-config'
import { degToRad } from '../../../utils/math'
import { EnemyDefaults } from './enemy-defaults'
import { Colors } from '../../../utils/colors'
import { ParticleEffectType } from '../../../engine/shared/game-data/particle-config'
import { Vector } from 'sat'
import { ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { ENEMY_DEFAULT_HEALTH } from '../../../engine/shared/game-data/enemy-formulas'
import { Ability, AbilitySelectionStyles, AbilityTargets, AbilityTargetSelectionStyle, AbilityType, ActionCriteria, CriteriaSatisfactionQuantity, CriteriaTarget, CriteriaType } from '../action-types'
import { attackRatedAbilityList } from '../action-criteria/ability-helpers'
import { AnimationTrack } from '../../../models-animations/shared/animation-track'
import { alwaysTrueCriteria } from './abilities/common-abilities'
import { ENEMY_NAMES } from './enemy-names'
import { worldDifficultyHardCriterion , worldDifficultyBrutalCriterion } from '../action-criteria/action-criteria-helpers'
import { deepClone } from '../abilities.test'

const playerIsInMeleeRangeCriteria: ActionCriteria = {
	satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
	criterias: [
		{
			criteriaTargets: CriteriaTarget.PLAYERS,
			criteriaType: CriteriaType.WITHIN_RANGE,
			criteriaValue: 350,
			minimumTargetsThatSatisfy: 1,
		},
	],
}

const animationTimes = {
	[AnimationTrack.SHOOT]: 0.3,
}

const wolfNovaAbility: Ability = {
	debugName: 'wolf-nova',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		speed: 300,
		colliderRadius: 40,
		basePhysicalDamage: 80,
		lifespanInSeconds: 1.5,
		projectileCount: 8,
		spreadAngle: degToRad(320),
		burstMode: BurstFireModes.STRAIGHT,
		modifiers: [{ modType: ModType.ACCELERATION, value: { modValueType: ModValueType.LIFETIME, min: -0.5, max: -0.5 } }],
		color: Colors.darkRed,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_THORNWOLF,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_TRAIL,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
	},
	attackOffset: new Vector(200, 0),
	ignoreAngleOnWeaponOffset: true,
}

const wolfHighPoisonShot: Ability = {
	debugName: 'wolf-highshot',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		speed: 450,
		colliderRadius: 40,
		basePoisonDamage: 80,
		lifespanInSeconds: 2.5,
		burstMode: BurstFireModes.STRAIGHT,
		modifiers: [{ modType: ModType.ACCELERATION, value: { modValueType: ModValueType.LIFETIME, min: 0.5, max: 1.25 } }],
		color: Colors.darkRed,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_THORNWOLF,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_POISON_TRAIL,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_POISON_SHOOT,
	},

	attackOffset: new Vector(375, -100),
	ignoreAngleOnWeaponOffset: true,
}


export const wolfBrutalPoisonShot = deepClone(wolfHighPoisonShot)
wolfBrutalPoisonShot.projectileConfig.speed = 575
wolfBrutalPoisonShot.projectileConfig.burstCount = 2 
wolfBrutalPoisonShot.projectileConfig.burstDelay = 0.4

const wolfLowPoisonShot: Ability = {
	debugName: 'wolf-lowshot',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		speed: 450,
		colliderRadius: 30,
		basePoisonDamage: 60,
		lifespanInSeconds: 2,
		burstMode: BurstFireModes.STRAIGHT,
		modifiers: [{ modType: ModType.ACCELERATION, value: { modValueType: ModValueType.LIFETIME, min: 0.5, max: 1.25 } }],
		color: Colors.darkRed,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_THORNWOLF,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_POISON_TRAIL,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_POISON_SHOOT,
	},
	attackOffset: new Vector(375, -100),
	ignoreAngleOnWeaponOffset: true,
}

const fungiWolf: EnemyAI = {
	name: ENEMY_NAMES.FUNGI_WOLF,
	type: EnemyType.BASIC,
	appearance: {
		asset: SpineDataName.FUNGI_WOLF,
		skin: EnemyBipedSkinType.DEFAULT,
		mixSettings: [],
	},
	general: {
		enemyLevel: 1,
		experienceWhenKilled: 1.25,
	},
	baseAttributes: {
		...EnemyDefaults.baseAttributes,
		visibilityRadius: 1,
		colliders: [
			{
				type: ColliderType.Circle,
				radius: 75,
				traits: ColliderTraits.BlockMovementAndProjectile,
				position: [0, -45],
			},
		],
		movementSpeed: 200,
		decelerationRate: 10,
		turningRatePerSecondInDegrees: 300,
		attackOffset: new Vector(0, -20),
		lootDropOffset: new Vector(0, 0),
		damageConfig: null,

		physicalDamage: 0,
		fireDamage: 0,
		iceDamage: 0,
		lightningDamage: 0,
		poisonDamage: 0,
		attackRate: 700,
		critChance: 0,
		critDamage: 1.0,
		poisonChance: 2.0,

		maxHealth: (ENEMY_DEFAULT_HEALTH * 3) / 2,
		defense: 0,
		fireResist: 0,
		poisonResist: 0,
		iceResist: 0,
		lightningResist: 0,

		lootDropProbability: 1,
		lootDropQuantity: 1,
		lootDropRarity: 1,
	},
	soundEffects: {
		attack: 'Projectile_Zap',
		impact: 'SFX_Impact_Enemy_Mush',
	},
	states: {
		idling: {
			behaviour: IdleBehaviours.MEANDER,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 2,
			transitionToFighting: {
				trigger: FightingTriggers.AGGRESSIVE_PROXIMITY,
				radius: 1100,
			},
			meander: {
				radius: 500,
				speedMultiplier: 0.8,
				minRestTime: 0.5,
				maxRestTime: 10,
			},
			aggroOnHit: true,
		},
		fighting: {
			behaviour: FightingBehaviours.CHASE_WHILE_ATTACKING,
			targetingStyle: EnemyTargetingStyles.HIGH_HEALTH,
			targetingCheckSeconds: 0,
			attackType: AttackTypes.PROJECTILE,
			engagementMaxDistance: 900,
			engagementMinDistance: 0,
			modelCenterOffset: 0,
			movementMaxDistance: 400,
			movementMinDistance: 0,
			brain: {
				actions: [
					{
						// BLOCK/ATTACK
						priority: 0,
						actionCriteria: playerIsInMeleeRangeCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							abilityOptions: [attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, wolfNovaAbility, 30)],
						},
					},
					{
						// Poison projectile at Brutal
						priority: 2,
						actionCriteria: {
							satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
							criterias: [worldDifficultyBrutalCriterion],
						},
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SEQUENCE,
							abilityOptions: [attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, wolfBrutalPoisonShot, 40)],
						},
					},
					{
						// Poison projectile at Hard
						priority: 3,
						actionCriteria: {
							satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
							criterias: [worldDifficultyHardCriterion],
						},
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SEQUENCE,
							abilityOptions: [attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, wolfHighPoisonShot, 40)],
						},
					},
					{
						// Poison projectile for lower world tiers
						priority: 5,
						actionCriteria: alwaysTrueCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							abilityOptions: [attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, wolfLowPoisonShot, 40)],
						},
					},
				],
			},
			attackOptions: [],
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 1,
			transitionToLeashing: {
				trigger: LeashTriggers.DISTANCE_TRAVELLED_OR_RADIUS_EXCEEDED,
				radius: 2000,
				distanceTravelled: 4000,
			},
		},
		leashing: {
			behaviour: LeashingBehaviours.MOVE,
			speedMultiplier: 4,
		},
		fleeing: {},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 1,
		},
	},
}

export default fungiWolf
