















































import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import MtxListItemContainer from '../reusable-components/item-containers/mtx-list-item-container.vue'
import TextButton from '../reusable-components/buttons/text-button.vue'
import FancySkinPreview from '../reusable-components/fancy-skin-preview/fancy-skin-preview.vue'
import { FilterType } from '../state/cosmetics-ui-state'
import { playerPanelTabs } from '../state/in-game.ui-state'

export default {
	name: 'PremiumStore',
	components: {
		MainPanel,
		MtxListItemContainer,
		TextButton,
		FancySkinPreview,
	},
	data() {
		return {
			activeItemTypeFilter: 'skins',

			cosmeticDropDownNames: [
				{ id: 'featured', name: 'Featured' },
				{ id: 'bundle', name: 'Bundles' },
				{ id: 'skin', name: 'Skins' },
				{ id: 'attachment', name: 'Attachments' },
				{ id: 'pet', name: 'Pets' },
				{ id: 'weapon', name: 'Weapons' },
				{ id: 'account', name: 'Account' },
				// { id: 'emotes', name: 'Emotes' },
			],
		}
	},
	computed: {
		...mapState('user', ['userType']),
		...mapGetters('mtxStore', ['selectedMtxItem']),
		...mapState('mtxStore', ['mtxOffers']),
		...mapGetters('cosmetics', ['mainSkin']),
		...mapGetters('inGame', ['activePanel']),
	},
	methods: {
		...mapActions('inGame', ['closeActivePanel']),
		...mapMutations('inGame', ['setActivePanel', 'updateShowNFTMap', 'updatedPlayerTab']),
		...mapActions('mtxStore', ['purchaseMtx', 'openForteExchange']),
		...mapMutations('itemContainers', ['updateMtxItemFilter']),
		...mapMutations('mtxStore', ['setSelectedMtxItem', 'showItemDetails']),
		...mapMutations('cosmetics', ['setSelectedMainSkin', 'setSelectedPet', 'setSelectedBack', 'setSelectedPfx', 'setSelectedWandSkin', 'setSelectedStaffSkin', 'setSelectedSwordSkin', 'setSelectedArcaneSkin', 'setSelectedCrossbowSkin', 'setSelectedScytheSkin']),
		openCustomizePanel() {
			this.setActivePanel('playerProfile')
			this.updatedPlayerTab(playerPanelTabs.CUSTOMIZE)
		},
		itemIsSelected(itemId) {
			if (itemId) {
				return itemId === this.selectedMtxItem
			}
			return false
		},
		openCryptoMap() {
			this.updateShowNFTMap(true)
		},
		openForteExchangeClicked() {
			if (this.userType === 'registered') {
				this.openForteExchange()
			} else {
				this.setActivePanel('notLoggedIn')
			}
		},
		previewSkinCosmetic(selectedItem) {
			this.setSelectedMtxItem(selectedItem.mtxId)
			switch (selectedItem.cosmeticType) {
				case FilterType.PLAYER_SKIN:
					this.setSelectedMainSkin(selectedItem)
					return
				case FilterType.PET_SKIN:
					this.setSelectedPet(selectedItem)
					return
				case FilterType.BACK:
					this.setSelectedBack(selectedItem)
					return
				case FilterType.PFX:
					this.setSelectedPfx(selectedItem)
					return
				case FilterType.WAND:
					this.setSelectedWandSkin(selectedItem)
					return
				case FilterType.STAFF:
					this.setSelectedStaffSkin(selectedItem)
					return
				case FilterType.SWORD:
					this.setSelectedSwordSkin(selectedItem)
					return
				case FilterType.ARCANE:
					this.setSelectedArcaneSkin(selectedItem)
					return
				case FilterType.CROSSBOW:
					this.setSelectedCrossbowSkin(selectedItem)
					return
				case FilterType.SCYTHE:
					this.setSelectedScytheSkin(selectedItem)
					return
			}
		},
		updateDropDownSelection(selectedOption) {
			this.updateMtxItemFilter(selectedOption)
		},
	},
}
