import { EnemyAI, FightingTriggers, IdleBehaviours, FightingBehaviours, LeashTriggers, LeashingBehaviours, AttackTypes, DeadBehaviours, ShotLeadPrecision, EnemyType, EnemyTargetingStyles } from '../../shared/ai-types'
import { ModType } from '../../../projectiles/shared/projectile-types'
import { SpineDataName, EnemyBipedSkinType } from '../../../models-animations/shared/spine-config'
import { degToRad } from '../../../utils/math'
import { EnemyDefaults } from './enemy-defaults'
import { Colors } from '../../../utils/colors'
import { ParticleEffectType } from '../../../engine/shared/game-data/particle-config'
import { Vector } from 'sat'
import { ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { AnimationTrack } from '../../../models-animations/shared/animation-track'
import { ENEMY_DEFAULT_HEALTH } from '../../../engine/shared/game-data/enemy-formulas'
import { ENEMY_NAMES } from './enemy-names'

const ent: EnemyAI = {
	name: ENEMY_NAMES.ENT,
	type: EnemyType.BASIC,
	appearance: {
		asset: SpineDataName.ENT,
		skin: EnemyBipedSkinType.DEFAULT,
		mixSettings: [
			{ from: AnimationTrack.IDLE, to: AnimationTrack.MOVEMENT, duration: 0.25 },
			{ from: AnimationTrack.MOVEMENT, to: AnimationTrack.IDLE, duration: 0.25 },
			{ from: AnimationTrack.IDLE, to: AnimationTrack.SHOOT, duration: 0.25 },
			{ from: AnimationTrack.SHOOT, to: AnimationTrack.MOVEMENT, duration: 0.25 },
		],
	},
	general: {
		enemyLevel: 1,
		experienceWhenKilled: 3,
	},
	baseAttributes: {
		...EnemyDefaults.baseAttributes,
		colliders: [
			{
				type: ColliderType.Circle,
				radius: 100,
				position: [0, -60],
				traits: ColliderTraits.BlockProjectile,
			},
			{
				type: ColliderType.Circle,
				radius: 100,
				position: [0, -140],
				traits: ColliderTraits.BlockProjectile,
			},
			{
				type: ColliderType.Ellipse,
				rX: 100,
				rY: 60,
				position: [0, -15],
				traits: ColliderTraits.BlockMovement,
			},
		],
		movementSpeed: 200,
		decelerationRate: 1.2,
		turningRatePerSecondInDegrees: 90,

		attackRate: 500,
		physicalDamage: 0,

		maxHealth: ENEMY_DEFAULT_HEALTH * 3,
		attackOffset: new Vector(450, 0),

		lootDropProbability: 2,
		lootDropQuantity: 2,
	},
	soundEffects: {
		attack: 'Projectile_Zap',
		impact: 'SFX_Impact_Enemy_Generic',
	},
	states: {
		idling: {
			behaviour: IdleBehaviours.MEANDER,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			transitionToFighting: {
				trigger: FightingTriggers.AGGRESSIVE_PROXIMITY,
				radius: 900,
			},
			meander: {
				radius: 500,
				speedMultiplier: 1,
				minRestTime: 0.5,
				maxRestTime: 10,
			},
			aggroOnHit: true,
		},
		fighting: {
			behaviour: FightingBehaviours.CHASE_WHILE_ATTACKING,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			attackType: AttackTypes.PROJECTILE,
			engagementMaxDistance: 260,
			engagementMinDistance: 0,
			modelCenterOffset: 100,
			movementMaxDistance: 260,
			movementMinDistance: 0,
			attackOptions: [
				{
					...EnemyDefaults.projectileConfig,
					projectileCount: 1,
					spreadAngle: degToRad(90),
					speed: 0.1,
					// the melee animation of the Ent doesn't work well with targets above or below itself
					// needs either another animation, or a design configuration that leaves it vulnerable
					// in the Y axis
					colliderRadius: 210,
					lifespanInSeconds: 0.5,
					modifiers: [{ modType: ModType.STRAIGHT }],
					basePhysicalDamage: 300,
					burstCount: 0,
					color: Colors.purple,
					bulletParticleEffect: ParticleEffectType.PROJECTILE_NONE,
					bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
					muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_NONE,
				},
			],
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 0.5,
			transitionToLeashing: {
				trigger: LeashTriggers.DISTANCE_TRAVELLED_OR_RADIUS_EXCEEDED,
				radius: 2000,
				distanceTravelled: 6000,
			},
		},
		leashing: {
			behaviour: LeashingBehaviours.MOVE,
			speedMultiplier: 3,
		},
		fleeing: {},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 1,
		},
	},
}

export default ent
