import nengi from 'nengi'
import { jsonString } from '../../utils/primitive-types'

class MarketplaceItemCountMessage {
	static protocol = {
		marketplacePageCount: { type: nengi.String },
	}

	marketplacePageCount: jsonString

	constructor(count) {
		this.marketplacePageCount = JSON.stringify(count)
	}
}

export default MarketplaceItemCountMessage
