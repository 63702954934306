import { Renderer as PIXIRenderer } from 'pixi.js'
import { InstancedSpriteBatcher } from './pfx/instanced-sprite-batcher'
import { IParticleRendererCamera } from './pfx/sprite-particle-renderer'
import { ObjectPool, PoolableJsObject } from '../../../third-party/object-pool'

export enum RenderQueueElementType {
	InstancedSprite,
	InstancedSpriteGroup,
	DisplayObject,
}

interface RenderQueueElement {
	elType: RenderQueueElementType
	zIndex: number
	element: any
	index: number
}

export class RenderQueue {
	// TODO2 Mike need to figure out best initial size
	//  this seemed fine at 32 but I'm putting up to 128 to be extra safe
	private elementPool: ObjectPool = new ObjectPool(() => new PoolableJsObject(), undefined, 512, 128, 'render-element')
	private elements: RenderQueueElement[] = []
	private pixiRenderer: PIXIRenderer
	private instancedSpriteBatcher: InstancedSpriteBatcher
	private elementCounter: number = 0

	constructor(pixiRenderer: PIXIRenderer, instancedSpriteBatcher: InstancedSpriteBatcher) {
		this.pixiRenderer = pixiRenderer
		this.instancedSpriteBatcher = instancedSpriteBatcher
	}

	clear() {
		// TODO2 Mike pool could track objects and do this themselves
		this.elements.forEach((element) => {
			// @ts-expect-error there's no non-ridiculous way to extend PoolableObject to this, wontfix
			this.elementPool.free(element)
		})
		this.elements = []
		this.elementCounter = 0
	}

	addElement(element: any, elType: RenderQueueElementType, zIndex: number) {
		const elem = this.elementPool.alloc()
		elem.elType = elType
		elem.zIndex = zIndex
		elem.element = element
		elem.index = this.elementCounter++
		this.elements.push(elem)
	}

	flush(camera: IParticleRendererCamera) {
		this._sort()
		this._render(camera)
	}

	private _sort() {
		this.elements.sort((a, b) => (a.zIndex !== b.zIndex ? a.zIndex - b.zIndex : a.index - b.index))
	}

	private _switchToPixiRendering() {
		this.instancedSpriteBatcher.flush()
		this.pixiRenderer.batch.currentRenderer.start()
	}

	private _switchToInstancedRendering(camera: IParticleRendererCamera) {
		this.pixiRenderer.batch.currentRenderer.flush()
		this.pixiRenderer.batch.flush()
		this.instancedSpriteBatcher.beginRender(camera)
	}

	private _render(camera: IParticleRendererCamera) {
		let renderingInstances = false
		this.instancedSpriteBatcher.reset()

		this.elements.forEach((el) => {
			switch (el.elType) {
				case RenderQueueElementType.InstancedSprite:
					{
						const element = el.element
						if (!renderingInstances) {
							this._switchToInstancedRendering(camera)
							renderingInstances = true
						}
						this.instancedSpriteBatcher.addInstancedSprite(element)
					}
					break

				case RenderQueueElementType.InstancedSpriteGroup:
					{
						const element = el.element
						if (!renderingInstances) {
							this._switchToInstancedRendering(camera)
							renderingInstances = true
						}
						this.instancedSpriteBatcher.addInstancedSprites(element.particles, element.numParticles)
					}
					break

				case RenderQueueElementType.DisplayObject:
					{
						if (renderingInstances) {
							this._switchToPixiRendering()
							renderingInstances = false
						}
						const element = el.element as PIXI.DisplayObject
						element.updateTransform()
						// @ts-expect-error we break contract with pixi here by using a protected function. wontfix
						element.render(this.pixiRenderer)
					}
					break
			}
		})
		if (renderingInstances) {
			this.instancedSpriteBatcher.flush()
		} else {
			this.pixiRenderer.batch.currentRenderer.flush()
		}
	}
}
