import { AnimationTrack } from '../../../models-animations/shared/animation-track'
import { timeInServerTicks } from '../../../utils/primitive-types'
import { Ability, AbilityOptionallyWithWeightOrTiming, AbilitySelectionStyles, AbilityTargets, AbilityTargetSelectionStyle, AbilityType, Action } from '../action-types'
import { IAnimationTimes } from '../ai-types'
import { alwaysTrueCriteria, playAnimationAbility, waitAbility } from '../enemies/abilities/common-abilities'

export const doNothingAction: Action = {
	// DO NOTHING
	priority: 0,
	actionCriteria: alwaysTrueCriteria,
	actionAbilities: {
		abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
		abilityOptions: [[0, waitAbility(20)]],
	},
}

export function attackRatedAbilityList(weight: number, animationTimes: IAnimationTimes, animation: AnimationTrack, attackAbility: Ability, baseDuration: timeInServerTicks): AbilityOptionallyWithWeightOrTiming {
	let frame = 0.7
	if (animationTimes) {
		if (animationTimes[animation]) {
			frame = animationTimes[animation]
		}
	}

	return [
		weight,
		playAnimationAbility(animation, frame * 20),
		attackAbility,
		{
			debugName: `waitForCooldown`,
			abilityType: AbilityType.WAIT_FOR_COOLDOWN,
			durationInGameTicks: 0,
			validTargets: AbilityTargets.MYSELF,
			validTargetSelection: AbilityTargetSelectionStyle.ALL,
			timeAlready: frame,
			baseDuration,
		},
		//waitAbility(baseDuration)
	]
}
