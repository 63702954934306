import { EnemyAI, FightingTriggers, IdleBehaviours, FightingBehaviours, LeashTriggers, LeashingBehaviours, AttackTypes, DeadBehaviours, ShotLeadPrecision, EnemyType, EnemyTargetingStyles } from '../../shared/ai-types'
import { SpineDataName, EnemyBipedSkinType } from '../../../models-animations/shared/spine-config'
import { EnemyDefaults } from './enemy-defaults'
import { ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { ParticleEffectType } from '../../../engine/shared/game-data/particle-config'
import { Colors } from '../../../utils/colors'
import { ModType } from '../../../projectiles/shared/projectile-types'
import { ENEMY_DEFAULT_HEALTH } from '../../../engine/shared/game-data/enemy-formulas'
import { BuffIdentifier } from '../../../buffs/shared/buff.shared'
import { ActionCriteria, CriteriaSatisfactionQuantity, CriteriaTarget, CriteriaType, Ability, AbilityType, AbilityTargets, AbilityTargetSelectionStyle, AbilitySelectionStyles } from '../action-types'
import { alwaysTrueCriteria } from './abilities/common-abilities'
import { AnimationTrack } from '../../../models-animations/shared/animation-track'
import { attackRatedAbilityList } from '../action-criteria/ability-helpers'
import { ENEMY_NAMES } from './enemy-names'
import { worldDifficultyHardCriterion } from '../action-criteria/action-criteria-helpers'
import { WorldDifficulty } from '../../../engine/shared/game-data/world-difficulty'

const doesNotHaveBuffCriteriaT3: ActionCriteria = {
	satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
	criterias: [
		{
			criteriaTargets: CriteriaTarget.SELF,
			criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
			criteriaValue: BuffIdentifier.EnemyShriekerBeShriekingT3,
			minimumTargetsThatSatisfy: 1,
		},
		worldDifficultyHardCriterion,
	],
}
const doesNotHaveBuffCriteria: ActionCriteria = {
	satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
	criterias: [
		{
			criteriaTargets: CriteriaTarget.SELF,
			criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
			criteriaValue: BuffIdentifier.EnemyShriekerBeShrieking,
			minimumTargetsThatSatisfy: 1,
		},
		{
			criteriaTargets: CriteriaTarget.SELF,
			criteriaType: CriteriaType.WORLD_DIFFICULTY_AT_OR_BELOW_THRESHOLD,
			criteriaValue: WorldDifficulty.Normal,
			minimumTargetsThatSatisfy: 1,
		},
	],
}

const shriekerShrieksAbilityT3: Ability = {
	debugName: 'shriek',
	abilityType: AbilityType.INSTANTLY_APPLY_EFFECT,
	buffToApply: BuffIdentifier.EnemyShriekerBeShriekingT3,
	durationInGameTicks: 20,
	validTargets: AbilityTargets.ALL_NEARBY_ENEMIES_AND_MYSELF,
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
}

const shriekerShrieksAbility: Ability = {
	debugName: 'shriek',
	abilityType: AbilityType.INSTANTLY_APPLY_EFFECT,
	buffToApply: BuffIdentifier.EnemyShriekerBeShrieking,
	durationInGameTicks: 20,
	validTargets: AbilityTargets.ALL_NEARBY_ENEMIES_AND_MYSELF,
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
}

const animationTimes = {
	[AnimationTrack.SHOOT]: 0.3, // PLACEHOLDER
}

const shriekerAttack: Ability = {
	debugName: 'attack',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.CLOSEST,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		projectileCount: 1,
		speed: 0.1,
		colliderRadius: 200,
		lifespanInSeconds: 0.5,
		modifiers: [{ modType: ModType.STRAIGHT }],
		baseLightningDamage: 65,
		basePoisonDamage: 35,
		burstCount: 0,
		pierceCount: 5,
		color: Colors.yellow,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_SHRIEKER,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_LIGHTNING_TRAIL,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_LIGHTNING_SHOOT,
	},
}

const shrieker: EnemyAI = {
	name: ENEMY_NAMES.SHRIEKER,
	type: EnemyType.BASIC,
	appearance: {
		asset: SpineDataName.SHRIEKER,
		skin: EnemyBipedSkinType.DEFAULT,
	},
	general: {
		enemyLevel: 1,
		experienceWhenKilled: 1,
	},
	baseAttributes: {
		...EnemyDefaults.baseAttributes,
		colliders: [
			{
				type: ColliderType.Ellipse,
				rX: 20,
				rY: 5,
				position: [0, 0],
				traits: ColliderTraits.BlockMovementAndProjectile,
			},
			{
				type: ColliderType.Ellipse,
				rX: 15,
				rY: 35,
				position: [0, -25],
				traits: ColliderTraits.BlockProjectile,
			},
			{
				type: ColliderType.Circle,
				radius: 40,
				position: [0, -85],
				traits: ColliderTraits.BlockMovementAndProjectile,
			},
		],
		hasMovableCollider: true,
		defaultMovableColliderPosition: [0, -85],
		idleMovableColliderPosition: [0, -35],
		movementSpeed: 525,
		decelerationRate: 2.5,
		turningRatePerSecondInDegrees: 720,
		maxHealth: ENEMY_DEFAULT_HEALTH,
	},
	soundEffects: {
		attack: 'Projectile_Blip',
		impact: 'SFX_Impact_Enemy_Mush',
	},
	states: {
		idling: {
			behaviour: IdleBehaviours.STATIONARY,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			transitionToFighting: {
				trigger: FightingTriggers.AGGRESSIVE_PROXIMITY,
				radius: 300,
			},
			aggroOnHit: true,
		},
		fighting: {
			behaviour: FightingBehaviours.STRAFE_AND_ATTACK,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			attackType: AttackTypes.PROJECTILE,
			engagementMaxDistance: 500,
			engagementMinDistance: 0,
			movementMaxDistance: 190,
			movementMinDistance: 150,
			brain: {
				actions: [
					{
						// BLOCK/ATTACK
						priority: 0,
						actionCriteria: doesNotHaveBuffCriteriaT3,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							abilityOptions: [[1, shriekerShrieksAbilityT3]],
						},
					},
					{
						// BLOCK/ATTACK
						priority: 1,
						actionCriteria: doesNotHaveBuffCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							abilityOptions: [[1, shriekerShrieksAbility]],
						},
					},
					{
						// BLOCK/ATTACK
						priority: 2,
						actionCriteria: alwaysTrueCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							//abilityOptions: [[1, shriekerAttack]],
							abilityOptions: [attackRatedAbilityList(5, animationTimes, AnimationTrack.SHOOT, shriekerAttack, 20)],
						},
					},
				],
			},
			attackOptions: [],
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 0.5,
			transitionToLeashing: {
				trigger: LeashTriggers.RADIUS_EXCEEDED,
				radius: 2000,
				distanceTravelled: 6000,
			},
			idleAnimation: 'attacking_idle',
		},
		leashing: {
			behaviour: LeashingBehaviours.MOVE,
			speedMultiplier: 4,
		},
		fleeing: {},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 0.2,
		},
	},
}

export default shrieker
