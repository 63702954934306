







import { debugConfig } from '../../../engine/client/debug-config'
import { autoDetectRenderer, Container, Renderer as pixiRenderer } from 'pixi.js'
import { RiggedSpineModel } from '../../../models-animations/client/spine-model'
import { timeInSeconds } from '../../../utils/primitive-types'
import { MtxTypes } from '../../state/cosmetics-ui-state'
import { AssetManager } from '../../../asset-manager/client/asset-manager'
import playAnimation from '../../../models-animations/client/play-animation'
import { AnimationTrack } from '../../../models-animations/shared/animation-track'
import { PetSkinSpineNames, PetSpineNames, petSubTypeToBaseType } from '../../../loot/shared/prize-sub-type'
import { SKIN_SLOT, SKIN_SLOT_TO_PREFIX } from '../../../player/shared/set-skin.shared'
import Time from '../../../engine/shared/time'

let renderer:MtxRenderer //If this is stored in data(), vue enters an infinite loop and freezes your browser 

export default {
    name: 'AnimatedMtxItem',
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            id: 'animatedMtxItem',
            intervalID: 0,
        }
    },
    created() {
        this.id = this._uid
    },
    mounted() {
        this.createMtxPreview()
    },
    beforeDestroy() {
        window.clearInterval(this.intervalID)
        renderer?.destroy()
    },
    methods: {
        createMtxPreview() {
            const mtxId = this.item.mtxId
            const mtxType = this.item.mtxType

            renderer = new MtxRenderer('ui-canvas' + this.id, {mtxId, mtxType})

			let lastTick = Time.timestampOfCurrentFrameStartInMs

            this.intervalID = window.setInterval(() => {
                const currentTick = Time.timestampOfCurrentFrameStartInMs
				const delta = ((currentTick - lastTick) / 1000) * debugConfig.timeScale
				lastTick = currentTick

                if(renderer) {
                    renderer.update(delta)
                }
            }, 33)
        }
    }
}

interface MtxItem {
    mtxId: string,
    mtxType: string,
}

class MtxRenderer {
    private pixiRenderer: pixiRenderer
	private stage: Container

	private riggedModel: RiggedSpineModel

    constructor(canvasId: string, mtxItem: MtxItem) {
        const canvas: HTMLCanvasElement = document.getElementById(canvasId) as HTMLCanvasElement
		this.pixiRenderer = autoDetectRenderer({
			width: 165,
			height: 203,
			view: canvas,
			antialias: false,
			transparent: false,
			resolution: 1,
			backgroundColor: 0x0a1f4b,
		})

		this.stage = new Container()
		this.stage.name = 'Mtx Preview'
		this.stage.position.set(0, 0)

        this.makeModel(mtxItem)
    }

    destroy() {
        this.riggedModel?.destroy({children: true})
        this.stage?.destroy({children: true})
        this.pixiRenderer?.destroy()
    }

    update(delta: timeInSeconds) {
        if (this.stage && this.pixiRenderer && this.riggedModel) {
			this.pixiRenderer.render(this.stage)
			this.riggedModel.update(delta)
		}
    }

    makeModel(mtxItem: MtxItem) {
        switch(mtxItem.mtxType) {
            case MtxTypes.PLAYER:
                return this.makePlayerSkin(mtxItem)
            case MtxTypes.PET:
                return this.makePetSkin(mtxItem)
        }
    }

    makePlayerSkin(mtxItem: MtxItem) {
        const asset = AssetManager.getInstance().getAssetByName('player-skins')
		this.riggedModel = new RiggedSpineModel(asset.spineData)

        this.riggedModel.skeleton.setSkinByName(mtxItem.mtxId)
		this.riggedModel.skeleton.setToSetupPose()
		
        this.riggedModel.position.x = 100
		this.riggedModel.position.y = 200
		

		playAnimation(this.riggedModel, AnimationTrack.IDLE_NO_WEAPON)
		
        this.stage.addChild(this.riggedModel)
    }

    makePetSkin(mtxItem: MtxItem) {
        const petEnum = Number.parseInt(mtxItem.mtxId.substring(SKIN_SLOT_TO_PREFIX[SKIN_SLOT.PET_MAIN].length), 10)

        const petBase = petSubTypeToBaseType(petEnum)
		const skinName = PetSkinSpineNames.get(petEnum)

		if (!skinName) {
			console.error('No skin name for ' + petEnum)
			return
		}

		AssetManager.getInstance().getAssetByNameAsync(PetSpineNames.get(petBase), (asset) => {
            this.riggedModel = new RiggedSpineModel(asset.spineData)

            this.riggedModel.skeleton.setSkinByName(skinName)
            this.riggedModel.skeleton.setToSetupPose()

            this.riggedModel.position.x = 77
		    this.riggedModel.position.y = 280

            this.riggedModel.scale.set(1.25, 1.25)

            this.riggedModel.update(0)

            playAnimation(this.riggedModel, AnimationTrack.IDLE, null, 1)

            this.stage.addChild(this.riggedModel)
		})
    }
}

