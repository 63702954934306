import nengi from 'nengi'

export class PostCampaignDetailsCommand {
	static protocol = {
		utm_source: nengi.String,
		utm_medium: nengi.String,
		utm_campaign: nengi.String,
		utm_term: nengi.String,
		utm_content: nengi.String,
	}

	utm_source: string
	utm_medium: string
	utm_campaign: string
	utm_term: string
	utm_content: string

	constructor(metrics: PostCampaignDetailsCommand) {
		Object.assign(this, metrics)
	}
}

export default PostCampaignDetailsCommand