import NPC, { BossCreditState } from '../shared/npc.shared'
import { Text } from 'pixi.js'
import { RiggedSpineModel } from '../../models-animations/client/spine-model'
import { SpineDataName } from '../../models-animations/shared/spine-config'
import { AnimationTrack } from '../../models-animations/shared/animation-track'
import { playAnimation, playOneOffAnimation } from '../../models-animations/client/play-animation'
import Renderer from '../../engine/client/graphics/renderer'
import { gameUnits } from '../../utils/primitive-types'
import { createModel } from '../../models-animations/client/model-setup'
import { NPCConfigs } from './npc-model-configs'
import { InteractionPromptManager } from '../../ftue/client/interaction-prompt-manager'
import { GameClient } from '../../engine/client/game-client'
import { NPCEmoteType } from '../../chat/shared/emote-enums'
import { NPCClientEmote } from './npc.client.emote'

const NO_MODEL_MODEL_HEIGHT = 200
const EMOTE_OFFSET = -180

export class ClientNPC extends NPC {
	model: RiggedSpineModel
	x: gameUnits
	y: gameUnits
	nameplateText: Text
	renderCharacter: boolean
	exemptFromModelCheck: boolean
	isInteractive: boolean
	isPet: boolean
	currentEmote: NPCEmoteType
	petGreeting: NPCClientEmote

	nid: number
	modelHeight: number = 0

	bossCredit: BossCreditState

	constructor(entityData: any) {
		super()

		this.x = entityData.x
		this.y = entityData.y
		this.nid = entityData.nid
		this.renderCharacter = entityData.renderCharacter
		this.exemptFromModelCheck = !this.renderCharacter
		this.isInteractive = entityData.isInteractive
		this.bossCredit = entityData.bossCredit
		this.currentEmote = entityData.currentEmote

		if (this.renderCharacter) {
			const isPlaceholder = entityData.skin === ''
			const skinName = isPlaceholder ? SpineDataName.NPC_DEFAULT : entityData.skin
			const config = NPCConfigs.get(skinName)
			this.model = createModel(config, 'npc-', this.y)
			this.model.name = entityData.name

			if (GameClient.getInstance().hiddenWormIDs.includes(entityData.nid)) {
				playAnimation(this.model, AnimationTrack.WORM_GONE_IDLE)
			} else {
				playAnimation(this.model, AnimationTrack.IDLE)
				this.drawNameplate()
				if (this.isInteractive) {
					InteractionPromptManager.getInstance().addInteractable(this)
				}
			}

			this.modelHeight = this.model.height + (config.modelHeightOffset ? config.modelHeightOffset : 0)
			if (this.isPet){
				this.model.scale.x = this.facingDirection
			}

			Renderer.getInstance().mgRenderer.addDisplayObjectToScene(this.model)
		} else {
			this.modelHeight = NO_MODEL_MODEL_HEIGHT
			if (this.isInteractive) {
				InteractionPromptManager.getInstance().addInteractable(this)
			}
		}
	}

	getEmote(): NPCClientEmote {
		const emote = NPCClientEmote.pool.alloc()

		const container = emote.getContainer()
		container.y = EMOTE_OFFSET
		container.visible = true
		emote.setParentContainer(this.model)

		return emote
	}

	greeting(): void {
		if (this.renderCharacter) {		
			// TODO2 - Add proper gretting animation when we have one
			// NPCs don't have one atm
		}
	}

	valediction(): void {
		if (this.renderCharacter) {
			// TODO2: Add proper farwell animation when we have one
			// NPCs don't have one atm
		}
	}

	drawNameplate() {
		if (this.renderCharacter) {
			this.nameplateText = new Text(this.model.name, {
				fontFamily: 'Yanone Kaffeesatz',
				fontSize: 28,
				fontWeight: 900,
				letterSpacing: 1,
				align: 'center',
				fill: 'white',
				dropShadow: true,
				dropShadowAngle: 2,
				dropShadowColor: '#2a313e',
				dropShadowDistance: 4,
				lineJoin: 'bevel',
				miterLimit: 5,
				padding: 8,
				stroke: '#2a313e',
				strokeThickness: 2,
			})
			this.updateNameplateText(this.model.name)
			this.model.addChild(this.nameplateText)
		}
	}

	hideWorm(): void {
		playOneOffAnimation(this.model, AnimationTrack.HIDE_WORM)
		if (this.isInteractive) {
			InteractionPromptManager.getInstance().removeInteractable(this)
		}
		this.nameplateText.visible = false
	}

	updateNameplateText(newName) {
		if (this.renderCharacter) {
			this.nameplateText.text = newName
			this.nameplateText.updateText(true)
			this.nameplateText.x = -this.nameplateText.width / 2
			this.nameplateText.y = 18
		}
	}

	onDelete() {
		if (this.isInteractive) {
			InteractionPromptManager.getInstance().removeInteractable(this)
		}

		if (this.model) {
			if (this.nameplateText) {
				this.model.removeChild(this.nameplateText)
			}
			Renderer.getInstance().mgRenderer.removeFromScene(this.model)
		}

		if (this.petGreeting) {
			this.petGreeting.returnToPool()
			this.petGreeting = null
		}
	}
}

export const clientNPCHooks = {
	facingDirection: (entity: ClientNPC, newValue: number, isMyEntity: boolean) => {
		if (entity.isPet) {
			entity.model.scale.x = newValue
		}
	},
}
