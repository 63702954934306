






























import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import PanelDivider from '../reusable-components/panel-divider/panel-divider.vue'
import TextButton from '../reusable-components/buttons/text-button.vue'
import { AURUM_ALLIANCE_PRETTY_NAME, FactionShortName, FactionShortNameToFullName } from '../../factions/shared/faction-data'
import { claimFactionRewards } from '../state/factions-ui-state'
import { showGenericInfoPromptUI } from '../state/generic-information-prompt.ui-state'
import { debugConfig } from '../../engine/client/debug-config'

const flagImages = require('./assets/*flag.png')

export default {
    name: 'FactionWinner',
    components: {
        MainPanel,
        TextButton,
        PanelDivider,
    },
    computed: {
        ...mapState('factions', ['partOfLastWinningFaction', 'lastWinningFaction']),
        ...mapGetters('factions', ['expectedRewards']),
        winningFlagURL() {
             switch(this.lastWinningFaction) {
				case FactionShortName.ORDER_OF_IRON:
					return flagImages['iron-']
				case FactionShortName.AURUM_ALLIANCE:
					return flagImages['aurum-']
				case FactionShortName.SCIONS_OF_DAWN:
					return flagImages['scion-']
			}

			return flagImages['aurum-']
        },
        winningFactionColor() {
           switch(this.lastWinningFaction) {
				case FactionShortName.ORDER_OF_IRON:
					return '#ff0000'
				case FactionShortName.AURUM_ALLIANCE:
					return '#9170ac'
				case FactionShortName.SCIONS_OF_DAWN:
					return '#5cbbff'
			}

			return '#9170ac'
        },
        winningFactionFullName() {
            return FactionShortNameToFullName.get(this.lastWinningFaction) || AURUM_ALLIANCE_PRETTY_NAME
        }
    },
    methods: {
        ...mapActions('inGame', ['closeActivePanel']),
        ...mapMutations('inGame', ['setActivePanel']),
        ...mapMutations('genericRewards', ['showGenericRewardsModule']),
        ...mapMutations('factions', ['setShowFactionRewardIntro']),
        nextClicked() {
            this.setShowFactionRewardIntro(false)
            if((this.partOfLastWinningFaction && !debugConfig.factions.noServerCheckFactionPrize) || (debugConfig.factions.noServerCheckFactionPrize && debugConfig.factions.forcedFactionWin)) {
                if(this.expectedRewards.length > 0) {
                    let claimButtonText = 'OK'
                    switch(this.lastWinningFaction) { //@todo word good
                        case FactionShortName.AURUM_ALLIANCE:
                            claimButtonText = 'A FINE ADDITION'
                            break
                        case FactionShortName.ORDER_OF_IRON:
                            claimButtonText = 'CHARGE!'
                            break
                        case FactionShortName.SCIONS_OF_DAWN:
                            claimButtonText = 'TO DAWN!'
                            break
                    }

                    const topText = `Congratulations! You have earned the following rewards for aligning with this week's faction winner: <span style="color: ${this.winningFactionColor}">${this.winningFactionFullName}</span>`
                    const bottomText = `All items will be sent to the stash`
                    this.showGenericRewardsModule({ claimButtonText, claimClickedCallback: claimFactionRewards, topText, bottomText, rewardItems: this.expectedRewards, panelIdentifier: 'factionRewards' })
                } else {
                    // did not do enough to earn a reward
                    showGenericInfoPromptUI('FACTIONS REWARDS', ['<span style="color: #e9e5ad;font-weight:normal;">Your faction won, but you did not contribute enough points to earn a prize!.</span>', 
                        '<span style="color:#e9e5ad;font-weight:normal;"><br>Remember, gain points to help your faction achieve victory by completing both <span style="color:#ff8e42">generic</span> and <span style="color:#ff8e42">faction specific</span> actions.</span>',
                        `<span style="color:#e9e5ad;font-weight:normal;"><br>Details on actions can be found by talking to each faction's recruitment member.</span>`], 'DISMISS', 'factionRewards', undefined, true)
                }
            } else {
                showGenericInfoPromptUI('FACTIONS REWARDS', ['<span style="color: #e9e5ad;font-weight:normal;">Your faction did not win.</span>', 
                    '<span style="color:#e9e5ad;font-weight:normal;"><br>Remember, gain points to help your faction achieve victory by completing both <span style="color:#ff8e42">generic</span> and <span style="color:#ff8e42">faction specific</span> actions.</span>',
                    `<span style="color:#e9e5ad;font-weight:normal;"><br>Details on actions can be found by talking to each faction's recruitment member.</span>`], 'DISMISS', 'factionRewards', undefined, true)
            }

        }
    }
}
