<template>
	<!-- <div class="pit-notifier" :style="{ transform: `perspective(1px) translateZ(0) scale(${currentScale})` }" style="transform-origin: top left"> -->
	<!-- <img id="pit-bg-image" src="../hud/assets/pit-notif-bg.png" /> -->
	<div id="pit-rewards-border-image">
		<div id="pit-rewards-notifier"  class="notification-body" @click="setActivePanel('pitStorage')">
			<h3>Items in Your Pit! &emsp; &emsp; {{ numberOfItemsInContainer('pitStorage') }} Items</h3>
		</div>
	</div>
	<!-- </div> -->
</template>

<script lang="js">
import { mapGetters, mapMutations } from 'vuex'
export default {
	name: 'PitRewardsNotifier',
	computed: {
		...mapGetters('itemContainers', ['numberOfItemsInContainer']),
		...mapGetters('UIScale', ['currentScale']),
	},
	methods:{
		...mapMutations('inGame', ['setActivePanel']),
	}
}
</script>

<style lang="less">
@import url('../global/fonts/text.less');
#pit-rewards-notifier {
	display: flex;
	pointer-events: all;
	cursor: pointer;

	h3 {
		color: rgb(233, 229, 173);
		margin: 0;
		padding: 0;
		
	}

	.notif-hud-text();

}


#pit-rewards-border-image {
	height: 65px;
	justify-content: center;
	align-items: center;
	perspective: 1px;
	border-image-slice: 4 38 9 6 fill;
	border-image-width: 4px 38px 9px 6px;
	border-image-outset: 0px 0px 0px 0px;
	border-image-repeat: repeat stretch;
	border-image-source: url('../hud/assets/toast-generic.png');
}
</style>
