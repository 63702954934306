import { ItemRarityPrettyName } from '../../loot/shared/item-rarity'
import { invalidAugmentForWeaponType } from '../../items/shared/augment-constraints'
import { chain } from 'lodash'
import { UI } from '../ui'

export function filterUIModule() {
	return {
		namespaced: true,
		state: {
			level: null,
			rarity: 0,
			weaponType: 0,
			augmentType: 0,
			filteredItems: [],
			augmentFilterStatus: false,
		},
		getters: {
			getAugmentType(state) {
				return state.augmentType
			},
			getWeaponType(state) {
				return state.weaponType
			},
			getRarity(state) {
				return state.rarity
			},
			getLevel(state) {
				return state.level
			},
			getFilteredItems(state) {
				return state.filteredItems
			},
			getAugmentFilterStatus(state) {
				return state.augmentFilterStatus
			},
		},
		mutations: {
			updateAugmentType(state, aug) {
				state.augmentType = Number(aug)
			},
			updateWeaponType(state, weap) {
				state.weaponType = Number(weap)
			},
			updateRarity(state, rarity) {
				state.rarity = Number(rarity)
			},
			updateLevel(state, level) {
				state.level = Number(level)
			},
			resetFilters(state) {
				state.level = null
				state.rarity = 0
				state.weaponType = 0
				state.augmentType = 0
				state.filteredItems = []
				state.augmentFilterStatus = false
			},
			updateFilteredItems(state, filteredItems) {
				state.augmentFilterStatus = true
				state.filteredItems = filteredItems
			},
			setFiltering(state, filtering) {
				state.augmentFilterStatus = filtering
			}
		},
		actions: {
			applyFilters({ state, commit }, items) {
				const selectedItem = UI.getInstance().store.getters['augmentationStation/getSelectedItemId']
				const itemDetails = UI.getInstance().store.getters['itemContainers/itemDetails']('equippedWeapon', selectedItem)

				let filteredItems = items

				if (state.rarity !== 0) {
					filteredItems = filterItemsByRarity(state.rarity, filteredItems)
				}
				if (state.weaponType !== 0) {
					filteredItems = filterAugByWeaponType(itemDetails, filteredItems)
				}
				if (state.augmentType !== 0) {
					filteredItems = filterItemsByAugType(state.augmentType, filteredItems)
				}
				commit('updateFilteredItems', filteredItems)
			},
		},
	}
}

export function filterItemsByRarity(rarity, items) {
	const rarityString = ItemRarityPrettyName.get(rarity)
	const itemsFilterByRarity = chain(items)
		.filter((item) => item.rarity.toLowerCase() === rarityString)
		.value()
	return itemsFilterByRarity
}

export function filterAugByWeaponType(weaponType, items) {
	const augsFilterByValidity = chain(items)
		.filter((item) => !invalidAugmentForWeaponType(item, weaponType))
		.value()
	return augsFilterByValidity
}

export function filterItemsByAugType(augType, items) {
	const itemsFilterByAugType = chain(items)
		.filter((item) => item.itemSubType === augType)
		.value()
	return itemsFilterByAugType
}
export function filterItemsByLevel(level, items) {
	const itemsFilterByLevel = chain(items).filter((item) => item.level === level)
	return itemsFilterByLevel
}
