






























import { orderBy, map} from 'lodash'
import SidePanel from '../reusable-components/panels/side-panel.vue'
import DropDown from '../reusable-components/inputs/dropdown.vue'
import ItemRarity from '../../loot/shared/item-rarity'
import WeaponSubType from '../../loot/shared/weapon-sub-type'
import { AllWeaponAugmentSubTypes, WeaponAugmentConfigData } from '../../loot/shared/weapon-augment-sub-type'
import { WeaponSubTypePrettyName } from '../../loot/shared/weapon-sub-type'
import { ItemRarityPrettyName } from '../../loot/shared/item-rarity'
import { parse } from 'path'
import TextButton from '../reusable-components/buttons/text-button.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
	name: 'FlyoutAugmentationStation',
	components: {
		SidePanel,
		DropDown,
		TextButton,
	},
	props: {},
	data() {
		return {
			level: 1,
			rarity: '',
		}
	},
	computed: {
		...mapGetters('filter', ['getAugmentType', 'getWeaponType', 'getRarity', 'getLevel']),
		...mapGetters('itemContainers', ['itemsByItemTypeInContainer']),
		...mapState('augmentationStation', ['alreadyEquippedAugments']),
		rarityList() {
			const unorderedRarityTypes = map([...ItemRarityPrettyName], (subType) => ({ name: subType[1], id: subType[0] }))
			unorderedRarityTypes.push({ name: 'All', id: 0 })
			let removeChaosRarity = unorderedRarityTypes.filter((rarity) => rarity.id !== 7)
			return orderBy(removeChaosRarity, 'id', 'asc')
		},
		weaponAugmentList() {
			const unordedAugments = map([...WeaponAugmentConfigData], ([subType, value]) => ({ name: value.prettyName, id: subType }))
			unordedAugments.push({ name: 'All', id: 0 })
			return orderBy(unordedAugments, 'name', 'asc').filter((aug) => AllWeaponAugmentSubTypes.includes(aug.id))
		},
		weaponList() {
			const unorderedWeaponTypes = map([...WeaponSubTypePrettyName], (subType) => ({ name: subType[1], id: subType[0] }))
			unorderedWeaponTypes.push({ name: 'All', id: 0 })
			return orderBy(unorderedWeaponTypes, 'id', 'asc')
		},
	},
	methods: {
		...mapMutations('filter', ['updateAugmentType', 'updateWeaponType', 'updateRarity', 'updateLevel', 'resetFilters']),
		...mapActions('filter', ['applyFilters']),

		updateRarityFilter(val) {
			const selectedRarity = val
			this.updateRarity(selectedRarity)
		},
		updateSubTypeFilter(val) {
			const selectedAugType = val
			this.updateAugmentType(selectedAugType)
		},
		// updateLevelFilter() {
		// 	const inputVal = Math.round(parseInt(this.level))

		// 	if (inputVal > 150) {
		// 		this.level = 150
		// 	}
		// 	if (inputVal < 1) {
		// 		this.level = 1
		// 	}
		// 	this.updateLevel(this.level)
		// },
		updateWeaponTypeFilter(val) {
			const selectedWeapType = val
			this.updateWeaponType(selectedWeapType)
		},
		applyBtnClick() {
			const items = this.itemsByItemTypeInContainer('stash', 'Weapon Augment').concat(this.alreadyEquippedAugments)

			this.applyFilters(items)
		},
	},
}
