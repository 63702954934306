import logger from '../../utils/client-logger'

export enum PanelTabs {
	OVERVIEW = 'overview',
	DIFFICULTY = 'difficulty',
	FACTIONS = 'factions'
}


export const departureUIState = () => {
	logger.debug('Initializing Departure Launch UI module')
	return {
		namespaced: true,
		state: {
			departureTab: `overview`
		},
		getters: {
			getActiveDepartureTab(state){
				return state.departureTab
			}
		},
		mutations: {
			updatedDepartureTab(state, tab){
				state.departureTab = tab
			}
		}
	}
}
