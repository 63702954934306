import nengi from 'nengi'

class NengiViewUpdatedCommand {
	static protocol = {
		x: nengi.Float32,
		y: nengi.Float32,
		newWidth: nengi.Float32,
		newHeight: nengi.Float32,
	}

	x: number
	y: number
	newWidth: number
	newHeight: number

	constructor(x: number, y: number, newWidth: number, newHeight: number) {
		this.x = x
		this.y = y
		this.newWidth = newWidth
		this.newHeight = newHeight
	}
}

export default NengiViewUpdatedCommand
