import ItemType from "../../../loot/shared/item-type";

export const ITEM_STACK_SIZES = { // Update this in Plutus repo `item-info` if you change it here
    [ItemType.BiomeKey]: 99,
    [ItemType.LuckyShard]: 999,
    [ItemType.RarityShard]: 999,
    [ItemType.WormHorn]: 99,
    [ItemType.CurrencyBag]: Number.MAX_SAFE_INTEGER,
    [ItemType.PersistentBuff]: 99,
}

export const ADVENTURE_ITEM_STACK_SIZES = { // No need to update in Plutus, Adventure inventory stacks only exist in adventure server
    [ItemType.BiomeKey]: 3,
    [ItemType.LuckyShard]: 5,
    [ItemType.RarityShard]: 5,
    [ItemType.WormHorn]: 1,
    [ItemType.CurrencyBag]: Number.MAX_SAFE_INTEGER,
    [ItemType.PersistentBuff]: 3,
}