import { Vector } from 'sat'
import PlayerInput from '../shared/player-input'
import SwitchWeapon from '../../player/shared/switch-weapon.shared'
import { NengiClient } from '../../engine/client/nengi-client'
import Renderer from '../../engine/client/graphics/renderer'
import { UI } from '../../ui/ui'
import { GameClient } from '../../engine/client/game-client'
import DevToolsManager from '../../ui/dev-tools/dev-tools-manager'
import { debugConfig } from '../../engine/client/debug-config'
import { throttle } from 'lodash'
import { ClientPlayer } from '../../player/client/player.client'
import { timeInSeconds } from '../../utils/primitive-types'
import Time from '../../engine/shared/time'
import { PanelTabs} from '../../ui/state/departure.ui-state'

// https://gcctech.org/csc/javascript/javascript_keycodes.htm
const KEY_Q = 81
const KEY_E = 69
const KEY_P = 80
const KEY_I = 73
const KEY_M = 77
const KEY_T = 84
const KEY_U = 85
const KEY_L = 76
const KEY_B = 66
const KEY_H = 72
const KEY_C = 67
const KEY_G = 71
const KEY_ESC = 27
const KEY_W = 87
const KEY_UP_ARROW = 38
const KEY_A = 65
const KEY_LEFT_ARROW = 37
const KEY_S = 83
const KEY_DOWN_ARROW = 40
const KEY_D = 68
const KEY_RIGHT_ARROW = 39
const KEY_R = 82
const KEY_F = 70
const KEY_1 = 49
const KEY_2 = 50
const KEY_3 = 51
const KEY_4 = 52
const KEY_5 = 53
const KEY_TAB = 9
const KEY_ENTER = 13
const KEY_SHIFT = 16
const KEY_SPACEBAR = 32
const KEY_F8_AI_BRAIN = 119
const KEY_F9_DEV_TOOLS = 120
const KEY_X = 88
const KEY_BACKTICK_ITEM_ROLLER = 192
const KEY_V = 86

export default class ClientPlayerInput {
	static getInstance(params?: any): ClientPlayerInput {
		if (!ClientPlayerInput.instance) {
			if (params === undefined) {
				throw new Error('No game client state params given to ClientPlayerInput getInstance(); aborting startup')
			} else {
				ClientPlayerInput.instance = new ClientPlayerInput(params)
			}
		}
		return this.instance
	}

	static shutdown() {
		for (const [eventName, handler] of ClientPlayerInput.getInstance().eventHandlers) {
			document.removeEventListener(eventName, handler)
		}
		ClientPlayerInput.instance = null
	}

	eventHandlers: Map<string, (event: KeyboardEvent | MouseEvent) => void> = new Map()

	currentState: {
		up: boolean
		down: boolean
		left: boolean
		right: boolean
		aux1: boolean
		aux2: boolean
		interact: boolean
		skill1: boolean
		skill2: boolean
		skill3: boolean
		skill4: boolean
		skill5: boolean
		mouseX: number
		mouseY: number
		mouseLeftDown: boolean
		mouseRightDown: boolean
		mouse3Down: boolean
		mouse4Down: boolean
		mouse5Down: boolean
	}
	frameState: {
		up: boolean
		down: boolean
		left: boolean
		right: boolean
		aux1: boolean
		aux2: boolean
		interact: boolean
		skill1: boolean
		skill2: boolean
		skill3: boolean
		skill4: boolean
		skill5: boolean
		mouseLeftDown: boolean
		mouseRightDown: boolean
		mouse3Down: boolean
		mouse4Down: boolean
		mouse5Down: boolean
	}
	lastKnownMouseWorldX: number = 0
	lastKnownMouseWorldY: number = 0
	gameClientState: { myEntity: ClientPlayer }
	aimVector: Vector
	private static instance: ClientPlayerInput
	constructor(gameClientState) {
		this.aimVector = new Vector(0, 0)
		this.gameClientState = gameClientState
		this.currentState = {
			up: false,
			down: false,
			left: false,
			right: false,
			aux1: false,
			aux2: false,
			interact: false,
			skill1: false,
			skill2: false,
			skill3: false,
			skill4: false,
			skill5: false,
			mouseX: 0,
			mouseY: 0,
			mouseLeftDown: false,
			mouseRightDown: false,
			mouse3Down: false,
			mouse4Down: false,
			mouse5Down: false,
		}

		// keys that count as pressed for this frame
		this.frameState = {
			up: false,
			down: false,
			left: false,
			right: false,
			aux1: false,
			aux2: false,
			interact: false,
			skill1: false,
			skill2: false,
			skill3: false,
			skill4: false,
			skill5: false,
			mouseLeftDown: false,
			mouseRightDown: false,
			mouse3Down: false,
			mouse4Down: false,
			mouse5Down: false,
		}

		this.setupDOMListeners()
	}

	releaseKeys() {
		this.frameState.up = this.currentState.up
		this.frameState.left = this.currentState.left
		this.frameState.down = this.currentState.down
		this.frameState.right = this.currentState.right
		this.frameState.aux1 = this.currentState.aux1
		this.frameState.aux2 = this.currentState.aux2
		this.frameState.interact = this.currentState.interact
		this.frameState.skill1 = this.currentState.skill1
		this.frameState.skill2 = this.currentState.skill2
		this.frameState.skill3 = this.currentState.skill3
		this.frameState.skill4 = this.currentState.skill4
		this.frameState.skill5 = this.currentState.skill5
		this.frameState.mouseLeftDown = this.currentState.mouseLeftDown
		this.frameState.mouseRightDown = this.currentState.mouseRightDown
		this.frameState.mouse3Down = this.currentState.mouse3Down
		this.frameState.mouse4Down = this.currentState.mouse4Down
		this.frameState.mouse5Down = this.currentState.mouse5Down
	}

	sendInputCommand(delta: timeInSeconds) {
		const worldCoords = Renderer.getInstance().mouseCoordinatesToWorldCoordinates(this.currentState.mouseX, this.currentState.mouseY)
		const player = this.gameClientState.myEntity
		this.lastKnownMouseWorldX = worldCoords.x - player.visualPos.x
		this.lastKnownMouseWorldY = worldCoords.y - player.visualPos.y

		const input = new PlayerInput(
			this.frameState.up,
			this.frameState.down,
			this.frameState.left,
			this.frameState.right,
			this.frameState.aux1,
			this.frameState.aux2,
			this.frameState.interact,
			this.frameState.skill1,
			this.frameState.skill2,
			this.frameState.skill3,
			this.frameState.skill4,
			this.frameState.skill5,
			this.frameState.mouseLeftDown,
			this.frameState.mouseRightDown,
			this.frameState.mouse3Down,
			this.frameState.mouse4Down,
			this.frameState.mouse5Down,
			this.lastKnownMouseWorldX,
			this.lastKnownMouseWorldY,
			delta,
			Time.currentGameFrameNumber,
			Time.timeElapsedSinceModeStartInSeconds,
			debugConfig.csp
		)

		player.handleInput(input, delta)

		NengiClient.getInstance().sendCommand(input)
	}

	sendEmptyInputCommand(delta: number) {
		const player = this.gameClientState.myEntity
		NengiClient.getInstance().sendCommand(new PlayerInput(false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, this.lastKnownMouseWorldX, this.lastKnownMouseWorldX, delta, 0, Time.timeElapsedSinceModeStartInSeconds, debugConfig.csp))
	}

	update(delta: number) {
		if (UI.getInstance().userInputShouldBeDisabled()) {
			this.sendEmptyInputCommand(delta)
		} else {
			this.sendInputCommand(delta)
		}
	}

	addEventListener(eventName: string, handler: (event: KeyboardEvent | MouseEvent) => void) {
		this.eventHandlers.set(eventName, handler)
		document.addEventListener(eventName, handler)
	}

	private setupDOMListeners() {
		this.addEventListener('contextmenu', (event: MouseEvent) =>
			// prevents right click from showing the context menu
			event.preventDefault(),
		)

		this.addEventListener(
			'wheel',
			throttle(
				() => {
					const getters = UI.getInstance().store.getters
					const currentPanel = getters['inGame/activePanel']
					const isAdventure = getters['inGame/currentGameMode'] === 'adventure'
					if (!currentPanel && (isAdventure || !getters['inGame/menuDropdownVisible'])) {
						NengiClient.getInstance().sendCommand(new SwitchWeapon('swap'))
					}
				},
				333,
				{ trailing: false },
			),
		)

		this.addEventListener('keydown', (event: KeyboardEvent) => {
			const chatCurrentlyFocused = UI.getInstance().chatCurrentlyFocused()
			if (event.keyCode === KEY_TAB) {
				// prevents tab highlighting chat, see: https://sculpin.atlassian.net/browse/SOTI-3915
				event.preventDefault()

				if (chatCurrentlyFocused) {
					const isPartied = UI.getInstance().store.getters['party/getCurrentlyPartiedStatus']
					if (isPartied) {
						const partyChatActive = UI.getInstance().store.getters['chat/partyTabActive']
						UI.getInstance().emitEvent('chat/setPartyTabActive', !partyChatActive)
					}
				}
			}

			if (event.keyCode === KEY_X && !event.repeat) {
				const ourPlayer =  GameClient.getInstance().state.myEntity
				if(ourPlayer) {
					if(ourPlayer.partyId) {
						const clientEntities = GameClient.getInstance().entities
						clientEntities.forEach((clientEntity) => {
							if (clientEntity instanceof ClientPlayer && clientEntity.partyId === ourPlayer.partyId) {
								clientEntity.showWhereAmIHighlight()
							}
						})
					} else {
						ourPlayer.showWhereAmIHighlight()
					}
				}

				// Uncomment this to spawn a fungi boss on your location when you press 'X'
				// NengiClient.getInstance().sendCommand(new SpawnEnemyOnMeCommand())
			}

			if (event.keyCode === KEY_ENTER) {
				const yesNoVisible = UI.getInstance().store.getters['genericYesNo/getYesNoPromptVisible']
				if (yesNoVisible) {
					return
				}

				UI.getInstance().emitEvent('chat/sendMessage')
			}

			if (event.keyCode === KEY_ESC && !event.repeat) {
				const currentPanel = UI.getInstance().store.getters['inGame/activePanel']
				const currentGameMode = GameClient.getInstance().gameMode
				const yesNoVisible = UI.getInstance().store.getters['genericYesNo/getYesNoPromptVisible']
				if (yesNoVisible) {
					return
				}

				const stackableTransferVisible = UI.getInstance().store.getters['stackableSelection/isStackableSelectionVisible']
				if (stackableTransferVisible) {
					UI.getInstance().emitEvent('stackableSelection/cancelPopup')
					return
				}

				if (currentGameMode === 'hub' || currentGameMode === 'adventure') {
					if (currentPanel) {
						return UI.getInstance().emitAsyncEvent('inGame/closeActivePanel')
					}
				}

				UI.getInstance().emitEvent('inGame/toggleMenuDropdownVisible')
				UI.getInstance().emitEvent('chat/defocusChat')
			}

			const showDevTools = UI.getInstance().showDevTools()

			if (event.keyCode === KEY_F9_DEV_TOOLS && (chatCurrentlyFocused === false || showDevTools)) {
				if (!DevToolsManager.getInstance().getDebugObject()) {
					DevToolsManager.getInstance().setDebugObject(debugConfig)
				}
				UI.getInstance().toggleDevTools()
			}

			if (event.keyCode === KEY_F8_AI_BRAIN && (chatCurrentlyFocused === false || showDevTools)) {
				UI.getInstance().toggleAIBrainDebugger()
			}

			if (event.keyCode === KEY_BACKTICK_ITEM_ROLLER) {
				if (showDevTools) {
					UI.getInstance().toggleDevTools()
				}
				UI.getInstance().emitEvent('chat/defocusChat')
				UI.getInstance().toggleItemTools()
			}

			if (document.activeElement.tagName === 'INPUT') {
				return
			}

			if (event.keyCode === KEY_Q && !event.repeat && chatCurrentlyFocused === false) {
				NengiClient.getInstance().sendCommand(new SwitchWeapon('swap'))
			}

			if (event.keyCode === KEY_P && !event.repeat && chatCurrentlyFocused === false) {
				const currentGameMode = GameClient.getInstance().gameMode
				if (currentGameMode === 'hub') {
					UI.getInstance().emitEvent('inGame/setActivePanel', 'playerProfile')
					UI.getInstance().emitEvent('inGame/updatedPlayerTab', 'paperdoll')
				} else {
					UI.getInstance().emitEvent('inGame/setActivePanel', 'paperDoll')
				}
			}

			if (event.keyCode === KEY_C && !event.repeat && chatCurrentlyFocused === false) {
				const currentGameMode = GameClient.getInstance().gameMode

				if (currentGameMode === 'hub') {
					UI.getInstance().emitEvent('inGame/setActivePanel', 'playerProfile')
					UI.getInstance().emitEvent('inGame/updatedPlayerTab', 'customize')
				}
			}

			if (event.keyCode === KEY_I && !event.repeat && chatCurrentlyFocused === false) {
				const currentGameMode = GameClient.getInstance().gameMode
				if (currentGameMode === 'hub') {
					return UI.getInstance().emitEvent('inGame/setActivePanel', 'stash')
				}
				if (currentGameMode === 'adventure') {
					return UI.getInstance().emitEvent('inGame/setActivePanel', 'inventory')
				}
			}

			if (event.keyCode === KEY_U && !event.repeat && chatCurrentlyFocused === false) {
				const currentGameMode = GameClient.getInstance().gameMode
				if (currentGameMode === 'hub') {
					return UI.getInstance().emitEvent('inGame/setActivePanel', 'furnaceUpdate')
				}
			}

			if (event.keyCode === KEY_L && !event.repeat && chatCurrentlyFocused === false) {
				const currentGameMode = GameClient.getInstance().gameMode
				if (currentGameMode === 'hub') {
					return UI.getInstance().emitEvent('inGame/setActivePanel', 'wormDelivery')
				}
			}

			if (event.keyCode === KEY_B && !event.repeat && chatCurrentlyFocused === false) {
				const currentGameMode = GameClient.getInstance().gameMode
				if (currentGameMode === 'hub') {
					return UI.getInstance().emitEvent('inGame/setActivePanel', 'departure')
				}
			}

			if (event.keyCode === KEY_G && !event.repeat && chatCurrentlyFocused === false) {
				const currentGameMode = GameClient.getInstance().gameMode
				const userPartiedStatus = UI.getInstance().store.getters['party/getCurrentlyPartiedStatus']

				if (currentGameMode === 'hub') {
					UI.getInstance().emitEvent('departure/updatedDepartureTab', PanelTabs.OVERVIEW)
					return UI.getInstance().emitEvent('inGame/setActivePanel', 'departure')
				} else {
					return UI.getInstance().emitEvent('inGame/setActivePanel', 'party')
				}
				
			}

			if (event.keyCode === KEY_H && !event.repeat && chatCurrentlyFocused === false) {
				const currentGameMode = GameClient.getInstance().gameMode
				const playerIsDead = UI.getInstance().store.getters['hud/health'] <= 0
				if (currentGameMode === 'adventure' && playerIsDead) {
					return UI.getInstance().emitEvent('inGame/setActivePanel', 'gameOver')
				}
			}

			if (event.keyCode === KEY_M && !event.repeat && chatCurrentlyFocused === false) {
				const currentGameMode = GameClient.getInstance().gameMode
				if (currentGameMode === 'hub') {
					return UI.getInstance().emitEvent('inGame/setActivePanel', 'marketplaceUpdated')
				}
				if (currentGameMode === 'adventure') {
					return UI.getInstance().emitEvent('hud/toggleNavigationMenuVisible')
				}
			}

			if (event.keyCode === KEY_T && !event.repeat && chatCurrentlyFocused === false) {
				UI.getInstance().emitEvent('chat/toggleChat')
			}

			if (event.keyCode === KEY_V && !event.repeat && chatCurrentlyFocused === false) {
				UI.getInstance().emitEvent('emotes/openWheel')
			}

			if (event.keyCode === KEY_W || event.keyCode === KEY_UP_ARROW) {
				this.currentState.up = true
				this.frameState.up = true
			}

			if (event.keyCode === KEY_A || event.keyCode === KEY_LEFT_ARROW) {
				this.currentState.left = true
				this.frameState.left = true
			}

			if (event.keyCode === KEY_S || event.keyCode === KEY_DOWN_ARROW) {
				this.currentState.down = true
				this.frameState.down = true
			}

			if (event.keyCode === KEY_D || event.keyCode === KEY_RIGHT_ARROW) {
				this.currentState.right = true
				this.frameState.right = true
			}

			if (event.keyCode === KEY_Q) {
				this.currentState.aux1 = true
				this.frameState.aux1 = true
			}

			if (event.keyCode === KEY_F) {
				this.currentState.interact = true
				this.frameState.interact = true
			}

			if (event.keyCode === KEY_1 || event.keyCode === KEY_SPACEBAR) {
				this.currentState.skill1 = true
				this.frameState.skill1 = true
			}

			if (event.keyCode === KEY_2 || event.keyCode === KEY_E) {
				this.currentState.skill2 = true
				this.frameState.skill2 = true
			}

			if (event.keyCode === KEY_3 || event.keyCode === KEY_R) {
				this.currentState.skill3 = true
				this.frameState.skill3 = true
			}

			if (event.keyCode === KEY_4) {
				this.currentState.skill4 = true
				this.frameState.skill4 = true
			}

			if (event.keyCode === KEY_5) {
				this.currentState.skill5 = true
				this.frameState.skill5 = true
			}
		})

		this.addEventListener('keyup', (event: KeyboardEvent) => {
			if (event.keyCode === KEY_V && !event.repeat) {
				UI.getInstance().emitEvent('emotes/closeWheel')
			}

			if (event.keyCode === KEY_W || event.keyCode === KEY_UP_ARROW) {
				this.currentState.up = false
			}

			if (event.keyCode === KEY_A || event.keyCode === KEY_LEFT_ARROW) {
				this.currentState.left = false
			}

			if (event.keyCode === KEY_S || event.keyCode === KEY_DOWN_ARROW) {
				this.currentState.down = false
			}

			if (event.keyCode === KEY_D || event.keyCode === KEY_RIGHT_ARROW) {
				this.currentState.right = false
			}

			if (event.keyCode === KEY_Q) {
				this.currentState.aux1 = false
			}

			if (event.keyCode === KEY_F) {
				this.currentState.interact = false
			}

			if (event.keyCode === KEY_1 || event.keyCode === KEY_SPACEBAR) {
				this.currentState.skill1 = false
			}

			if (event.keyCode === KEY_2 || event.keyCode === KEY_SHIFT || event.keyCode === KEY_E) {
				this.currentState.skill2 = false
			}

			if (event.keyCode === KEY_3 || event.keyCode === KEY_R) {
				this.currentState.skill3 = false
			}

			if (event.keyCode === KEY_4) {
				this.currentState.skill4 = false
			}

			if (event.keyCode === KEY_5) {
				this.currentState.skill5 = false
			}

			if (document.activeElement.tagName === 'INPUT') {
				return
			}
			// events that don't result in keyUp behavior go below here

			if(event.keyCode === KEY_X) {
				const ourPlayer =  GameClient.getInstance().state.myEntity
				if(ourPlayer) {
					if(ourPlayer.partyId) {
						const clientEntities = GameClient.getInstance().entities
						clientEntities.forEach((clientEntity) => {
							if (clientEntity instanceof ClientPlayer && clientEntity.partyId === ourPlayer.partyId) {
								clientEntity.hideWhereAmIHighlight(false)
							}
						})
					} else {
						ourPlayer.hideWhereAmIHighlight(false)
					}
				}
			}
		})

		this.addEventListener('visibilitychange', () => {
			if (document.visibilityState !== 'visible' || document.hidden) {
				for (const key in this.currentState) {
					if (key) {
						this.currentState[key] = false
					}
				}
			}
		})

		//WINDOW intentional
		window.addEventListener('blur', () => {
			for (const key in this.currentState) {
				if (key) {
					this.currentState[key] = false
				}
			}
		})

		this.addEventListener('mousemove', (event: MouseEvent) => {
			this.currentState.mouseX = event.clientX
			this.currentState.mouseY = event.clientY
		})

		this.addEventListener('pointerdown', (event: MouseEvent) => {
			if (event.button === 0) {
				this.currentState.mouseLeftDown = true
				this.frameState.mouseLeftDown = true
			}
			if (event.button === 2) {
				this.currentState.mouseRightDown = true
				this.frameState.mouseRightDown = true
			}
			if (event.button === 1) { // this is MIDDLE MOUSE, typically considered mouse3
				this.currentState.mouse3Down = true
				this.frameState.mouse3Down = true
			}
			if (event.button === 4) {
				this.currentState.mouse4Down = true
				this.frameState.mouse4Down = true
			}
			if (event.button === 5) {
				this.currentState.mouse5Down = true
				this.frameState.mouse5Down = true
			}
		})

		this.addEventListener('mouseup', (event: MouseEvent) => {
			if (event.button === 0 || event.button === 1) {
				this.currentState.mouseLeftDown = false
			}
			if (event.button === 2) {
				this.currentState.mouseRightDown = false
			}
			if (event.button === 1) {
				this.currentState.mouse3Down = false
			}
			if (event.button === 4) {
				this.currentState.mouse4Down = false
			}
			if (event.button === 5) {
				this.currentState.mouse5Down = false
			}
		})
	}
}
