


















































































import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import PanelDivider from '../reusable-components/panel-divider/panel-divider.vue'
import ArrowButton from '../reusable-components/buttons/arrow-button.vue'
// import InventoryItem from '../reusable-components/inventory-item/inventory-item.vue'
// import GridItemContainer from '../reusable-components/item-containers/grid-item-container.vue'
import WorldTierCompleteList from '../reusable-components/world-map/world-tier-complete-list.vue'
import CategoryFilter from '../reusable-components/filtering/category-filter.vue'
import SelectedSoulBonds from '../reusable-components/soul-bonds/SelectedSoulBonds.vue'
import TableData from '../reusable-components/detailed-item-view/table-data.vue'
import PlayerProgressIcon from '../reusable-components/icons/player-progress-icon.vue'
import { startGame, endGame } from '../../engine/client/start-stop'
import { GameClient } from '../../engine/client/game-client'
import { NengiClient } from '../../engine/client/nengi-client'
import PartyExitGameCommand from '../../party-play/shared/party-exit-game-command'
import { PRESSURE_MENU_UNLOCK_TIERS_DEFEATED } from '../../engine/shared/game-data/pressure-stat-info.shared'
import { getWorldDifficultyPrettyString } from '../../engine/shared/game-data/world-difficulty'
import { UI } from '../ui'
import { PROGRESSION_MAX_TIER_FOR_BETA } from '../../engine/shared/game-data/progression'
import { WorldTier } from '../../utils/primitive-types'
import PlayerStatIcons from '../reusable-components/icons/icons.vue'
import FactionTableView from '../reusable-components/faction/faction-table-data.vue'
import factionProgressBar from '../reusable-components/faction/faction-progress-bar.vue'

function nextTierIsLocked(worldTier: WorldTier) {
	return worldTier >= PROGRESSION_MAX_TIER_FOR_BETA
}

export default {
	name: 'WorldTierComplete',
	components: {
		MainPanel,
		PanelDivider,
		ArrowButton,
		//InventoryItem,
		//GridItemContainer,
		WorldTierCompleteList,
		TableData,
		CategoryFilter,
		SelectedSoulBonds,
		PlayerProgressIcon,
		PlayerStatIcons,
		FactionTableView,
		factionProgressBar
	},
	data() {
		return {}
	},
	computed: {
		...mapState('factions', ['playerFactionPoints', 'affiliatedFaction']),
		...mapState('gameOver', ['runStats']),
		...mapGetters('hud', ['activeWorldTier']),
		...mapGetters('inGame', ['activePanel']),
		...mapGetters('gameOver', ['getRunStats']),
		...mapState('gameOver', ['activeGameOverFilter']),
		...mapGetters('party', ['getPartyId']),
		...mapGetters('boatLaunch', ['hasReachedBiomeOnTier', 'maxAllowedTier', 'getPressureRank', 'hasUnlockedSoul']),
		worldTier() {
			// using worldTier instead of activeWorldTier directly, to facilitate testing
			return this.activeWorldTier
		},
		nextTierUnlocked() {
			return !nextTierIsLocked(this.worldTier)
		},
		panelTitle() {
			if (this.worldTier <= PRESSURE_MENU_UNLOCK_TIERS_DEFEATED) {
				const difficulty = getWorldDifficultyPrettyString(this.worldTier)
				return `You completed ${difficulty}`
			} else {
				const soulCycle = this.worldTier - PRESSURE_MENU_UNLOCK_TIERS_DEFEATED
				return `You completed Soul Cycle ${soulCycle}`
			}
		},

		tierCompletedHeader() {
			if (this.worldTier <= PRESSURE_MENU_UNLOCK_TIERS_DEFEATED) {
				return `Difficulty Completed`
			} else {
				return `Soul Cycle Completed`
			}
		},
		tierCompleted() {
			if (this.worldTier <= PRESSURE_MENU_UNLOCK_TIERS_DEFEATED) {
				return getWorldDifficultyPrettyString(this.worldTier)
			} else {
				return `Soul Cycle ${this.worldTier - PRESSURE_MENU_UNLOCK_TIERS_DEFEATED}`
			}
		},

		tierUnlockedHeader() {
			if (nextTierIsLocked(this.worldTier)) {
				return 'Coming Soon'
			} else if (this.worldTier < PRESSURE_MENU_UNLOCK_TIERS_DEFEATED) {
				return `Difficulty Unlocked`
			} else {
				return `Soul Cycle Unlocked`
			}
		},

		tierUnlocked() {
			const tier = this.worldTier + 1
			if (nextTierIsLocked(this.worldTier)) {
				return `Soul Cycle ${tier - PRESSURE_MENU_UNLOCK_TIERS_DEFEATED} (LOCKED)`
			} else if (tier <= PRESSURE_MENU_UNLOCK_TIERS_DEFEATED) {
				return getWorldDifficultyPrettyString(tier)
			} else {
				return `Soul Cycle ${tier - PRESSURE_MENU_UNLOCK_TIERS_DEFEATED}`
			}
		},
	},
	methods: {
		...mapActions('inGame', ['closeActivePanel', 'setPanelSwitchable']),
		...mapMutations('itemContainers', ['emptyPlayerEnchantments']),
		worldTierIcon(tier) {
			if (tier <= PRESSURE_MENU_UNLOCK_TIERS_DEFEATED) {
				return 'worldTier1Image'
			} else {
				return `soul`
			}
		},
		goToHub: function() {
			this.setPanelSwitchable(true)
			endGame()
			// @ts-expect-error TODO2: store this not on the window
			startGame('hub', window.originalConnectionPayload, this.getPartyId)
		},
	},
}
