














import { mapGetters } from 'vuex'

export default {
	name: 'DebugStats',
	components: {},
	computed: {
		...mapGetters('hud', ['fps', 'ping', 'fpsSpike', 'buildHash', 'serverYoureOn', 'serverWorldDifficulty', 'activeWorldTier', 'coinBalance', 'provider', 'gcPause', 'showDebugText']),
	},
}
