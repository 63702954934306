import { find } from 'lodash'
import { GlowFilter } from 'pixi-filters'
import { AssetManager } from '../../asset-manager/client/asset-manager'
import { Effect } from '../../engine/client/graphics/pfx/effect'
import { EffectConfig } from '../../engine/client/graphics/pfx/effectConfig'
import Renderer from '../../engine/client/graphics/renderer'
import DevToolsManager from '../../ui/dev-tools/dev-tools-manager'
import { attachments_addAttachment } from '../../utils/attachments-system'
import { ColorUtil } from '../../utils/colors'
import { simpleAnimation_addAnimation } from '../../utils/simple-animation-system'
import { ENEMY_NAMES } from '../shared/enemies/enemy-names'
import { ClientEnemy } from './enemy.client'

const glowConfig = {
	color: ColorUtil.toRGB(0x880992),
	pulseFreq: 2,
	innerStrength: 0,
	outerStrength: 2,
}

const championFilter = new GlowFilter()

type ClientEnemyWithPFX = ClientEnemy & { pfx: Effect[] }

function init() {
	const filter = championFilter
	// adding this to the filter so it never gets removed
	simpleAnimation_addAnimation(filter, (t) => {
		// setting color here is just for inspector, could be removed
		const c = glowConfig.color
		championFilter.color = ColorUtil.toHex(c.r, c.g, c.b)

		const freq = glowConfig.pulseFreq
		const mag = (Math.sin(t * freq) + 0.8) * 0.5
		filter.innerStrength = mag * glowConfig.innerStrength
		filter.outerStrength = mag * glowConfig.outerStrength
		return 0
	})
}

init()

export function championFilterUpdate(entity: ClientEnemy, isChampion: boolean) {
	if (entity.model) {
		const entityFilters = entity.model.filters

		if (isChampion) {
			if (!entityFilters.includes(championFilter)) {
				entityFilters.push(championFilter)
				addFollowingPfx(entity as ClientEnemyWithPFX, 'champion-pfx')
			}
		} else {
			entityFilters.remove(championFilter)
		}
	}
}

const pfxScale = {
	[ENEMY_NAMES.MOSQUITO]: 0.5,
	[ENEMY_NAMES.WASP]: 0.5,
	[ENEMY_NAMES.BONE_SPIRIT]: 1.5,
	[ENEMY_NAMES.CRAB]: 0.8,
	[ENEMY_NAMES.SPIDER]: 2.5,
	[ENEMY_NAMES.STRANGLEVINE]: 1.5,
	[ENEMY_NAMES.THORN_WOLF]: 1.5,
	[ENEMY_NAMES.DRAGON_SKULL]: 1.5,
	[ENEMY_NAMES.SKELETON_BEACH]: 1.5,
	[ENEMY_NAMES.SKELETAL_WHELP]: 1.5,
	[ENEMY_NAMES.DEATH_DRAKE]: 4.5,
	[ENEMY_NAMES.GOLEM_PRISM]: 2.5,
	[ENEMY_NAMES.GOLEM_HIGHLANDS]: 2.5,
	[ENEMY_NAMES.ENT]: 3.5,
	[ENEMY_NAMES.SPORE_KID]: 0.7,
	[ENEMY_NAMES.SHRIEKER]: 1.5,
	[ENEMY_NAMES.SHAMBLING_MOUND]: 1.5,
}

export function addFollowingPfx(entity: ClientEnemyWithPFX, pfxAssetName: string) {
	if (!entity.pfx) {
		entity.pfx = []
	}

	const effectConfig = AssetManager.getInstance().getAssetByName(pfxAssetName).data as EffectConfig

	const test: Effect[] = entity.pfx
	if (!find(test, (pfx) => pfx.name === effectConfig.name)) {
		const pfx = Renderer.getInstance().addEffectToScene(pfxAssetName, 0, 0)
		const s = pfxScale[entity.config.name]
		if (s) {
			pfx.scale *= s
		}
		attachments_addAttachment(pfx, entity, undefined, true, () => {
			Renderer.getInstance().removeEffectFromScene(pfx)
		})
		entity.pfx.push(pfx)
	}
}

if (process.env.NODE_ENV !== 'beta' && process.env.NODE_ENV !== 'loot-prod') {
	DevToolsManager.getInstance().addObjectByName('glow', glowConfig)
	DevToolsManager.getInstance().addObjectByName('pfxScale', pfxScale)
}
