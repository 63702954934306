

























import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import ArrowButton from '../../reusable-components/buttons/arrow-button.vue'
import SkinGridItemContainer from '../../reusable-components/item-containers/skins-grid-item-container.vue'
import { PlayerSkinValues } from '../../../models-animations/shared/spine-config'
import CategoryFilter from '../../reusable-components/filtering/category-filter.vue'
import { FilterType } from '../../state/cosmetics-ui-state'
import { PetSubTypeValues } from '../../../loot/shared/prize-sub-type'
import { SKIN_SLOT } from '../../../player/shared/set-skin.shared'
import DropDown from '../../reusable-components/inputs/dropdown.vue'
import { filterClassName } from '../../reusable-components/inventory-item/inventory-item-helpers'
import { getCustomizeSkinIconHexCode } from '../../reusable-components/inputs/drop-down-icon-helper'

export default {
	name: 'PlayerCustomize',
	components: {
		ArrowButton,
		SkinGridItemContainer,
		CategoryFilter,
		DropDown,
	},
	data() {
		return {
			env: process.env.NODE_ENV,
			cosmeticDropDownNames: [
				{ id: 'Player Skin', name: 'Player Skin' },
				{ id: 'Pet Skin', name: 'Pet Skin' },
				{ id: 'Weapon Skins', name: 'Weapon Skins' },
				{ id: 'Trail', name: 'Trail' },
				{ id: 'Back', name: 'Back' },
				{ id: 'Face', name: 'Face' },
			],
		}
	},
	computed: {
		...mapGetters('cosmetics', ['mainSkin']),
		...mapState('cosmetics', [
			'ownedCosmetics',
			'equippedCosmetics',
			'ownedPets',
			'selectedMainSkin',
			'selectedPetSkin',
			'selectedBack',
			'selectedFace',
			'selectedPfx',
			'selectedWand',
			'selectedStaff',
			'selectedSword',
			'selectedArcane',
			'selectedCrossbow',
			'selectedScythe',
			'selectedWeaponsNames',
			'weaponsNames',
		]),
		...mapState('itemContainers', ['activeSkinFilter', 'activeWeaponSkinSlot']),
		selectedSkinEquipable() {
			const mainSkin = this.selectedMainSkin
			const petSkin = this.selectedPetSkin
			const backSkin = this.selectedBack
			const faceSkin = this.selectedFace
			const pfxSkin = this.selectedPfx

			const mainSkinEquipable = mainSkin !== this.equippedCosmetics.mainSkin && PlayerSkinValues.includes(this.selectedMainSkin)
			const petSkinEquipable = petSkin !== this.equippedCosmetics.petSkin && (PetSubTypeValues.includes(this.selectedPetSkin) || this.selectedPetSkin === 0)
			const backEquipable = backSkin !== this.equippedCosmetics.back
			const faceEquipable = faceSkin !== this.equippedCosmetics.face
			const pfxEquipable = pfxSkin !== this.equippedCosmetics.pfx

			let anyWeaponEquippable = false
			for (let i = 0; i < this.selectedWeaponsNames.length; ++i) {
				const selectedName = this.selectedWeaponsNames[i]
				const selected = this[selectedName]
				const equippedName = this.weaponsNames[i]
				const equipped = this.equippedCosmetics[equippedName]

				if (selected.primary && selected.primary.identifier.toString() !== equipped.primary) {
					anyWeaponEquippable = true
					break
				} else if (!selected.primary && equipped.primary !== '') {
					anyWeaponEquippable = true
					break
				}

				if (selected.secondary && selected.secondary.identifier.toString() !== equipped.secondary) {
					anyWeaponEquippable = true
					break
				} else if (!selected.secondary && equipped.secondary !== '') {
					anyWeaponEquippable = true
					break
				}
			}

			return mainSkinEquipable || petSkinEquipable || backEquipable || faceEquipable || pfxEquipable || anyWeaponEquippable
		},
		filteredItems() {
			return this.ownedCosmetics.filter((c) => c.cosmeticType === this.activeSkinFilter)
		},
		activeFilterClass() {
			return filterClassName(this.activeSkinFilter)
		},
	},
	methods: {
		...mapActions('inGame', ['closeActivePanel']),
		...mapActions('cosmetics', ['changeEquippedSkin']),
		...mapMutations('cosmetics', ['setSelectedMainSkin', 'setSelectedPet', 'setSelectedBack', 'setSelectedFace', 'setSelectedPfx', 'setSelectedWandSkin', 'setSelectedStaffSkin', 'setSelectedSwordSkin', 'setSelectedArcaneSkin', 'setSelectedCrossbowSkin', 'setSelectedScytheSkin']),
		...mapMutations('itemContainers', ['updateSkinTypeFilter']),

		getCustomizeSkinIconHexCode,

		itemIsSelected(item) {
			const itemId = item.identifier
			switch (this.activeSkinFilter) {
				case FilterType.PLAYER_SKIN:
					return itemId === this.selectedMainSkin
				case FilterType.PET_SKIN:
					return itemId === this.selectedPetSkin
				case FilterType.BACK:
					return itemId === this.selectedBack
				case FilterType.FACE:
					return itemId === this.selectedFace
				case FilterType.PFX:
					return itemId === this.selectedPfx
				case FilterType.ALL_WEAPONS:
					let selectedWeapon
					switch (item.skinSlot) {
						case SKIN_SLOT.WEAPON_WAND:
							selectedWeapon = this.selectedWand
						case SKIN_SLOT.WEAPON_STAFF:
							selectedWeapon = this.selectedStaff
						case SKIN_SLOT.WEAPON_SWORD:
							selectedWeapon = this.selectedSword
						case SKIN_SLOT.WEAPON_ARCANE:
							selectedWeapon = this.selectedArcane
						case SKIN_SLOT.WEAPON_CROSSBOW:
							selectedWeapon = this.selectedCrossbow
						case SKIN_SLOT.WEAPON_SCYTHE:
							selectedWeapon = this.selectedScythe
					}
					return selectedWeapon[this.activeWeaponSkinSlot]?.identifier === itemId
			}
		},

		setMainSkin() {
			this.changeEquippedSkin({ type: this.activeSkinFilter })
		},

		updateSkinCosmetic(selectedItem) {
			switch (this.activeSkinFilter) {
				case FilterType.PLAYER_SKIN:
					this.setSelectedMainSkin(selectedItem)
					return
				case FilterType.PET_SKIN:
					this.setSelectedPet(selectedItem)
					return
				case FilterType.BACK:
					this.setSelectedBack(selectedItem)
					return
				case FilterType.FACE:
					this.setSelectedFace(selectedItem)
					return
				case FilterType.PFX:
					this.setSelectedPfx(selectedItem)
					return
				case FilterType.ALL_WEAPONS:
					switch (selectedItem.skinSlot) {
						case SKIN_SLOT.WEAPON_WAND:
							return this.setSelectedWandSkin(selectedItem)
						case SKIN_SLOT.WEAPON_STAFF:
							return this.setSelectedStaffSkin(selectedItem)
						case SKIN_SLOT.WEAPON_SWORD:
							return this.setSelectedSwordSkin(selectedItem)
						case SKIN_SLOT.WEAPON_ARCANE:
							return this.setSelectedArcaneSkin(selectedItem)
						case SKIN_SLOT.WEAPON_CROSSBOW:
							return this.setSelectedCrossbowSkin(selectedItem)
						case SKIN_SLOT.WEAPON_SCYTHE:
							return this.setSelectedScytheSkin(selectedItem)
					}
			}
		},
		showtooltipBasedOnCritria(item) {
			return true
		},
		updateDropDownSelection(selectedOption) {
			this.updateSkinTypeFilter(selectedOption)
		},
	},
}
