








import { mapMutations } from 'vuex'
export default {
	name: 'GenericEnchantmentSlot',
	props: {
		iconName: {
			type: String,
			default: 'null',
			required: true,
		},
		biomeNumber: {
			type: Number,
			required: true,
		},
		furthestBiome: {
			type: Number,
			required: true,
		},
	},
}
