<template>
	<div id="difficulty">
		<div class="column-one soul-cycle">
			<PanelDivider :divider-text="soulCycleHeaderText"></PanelDivider>
			<div v-if="!showSoulCycleContainer" class="locked-soul-cycle">
				<p><span>Dock Master:</span> What's that you've got there...?</p>
				<p>Let me take a closer look…</p>
				<div class="broken-soul-badge">
					<img src="../assets/soul-cycle-icon-broken-bg.png" alt="soul-cycle" class="soul-cycle-broken-bg"/>
					<img src="../assets/soul-cycle-icon-broken.png" alt="soul-cycle" class="soul-cycle-broken-fg" />
				</div>

				<p>Well I'll be… you've got yourself a Soul Incarnum! Looks to be in pretty rough shape though.</p>
				<p>These are rare, powerful artifacts, shrouded in mystery. Some say that a Soul Incarnum has the power to alter the properties of the Fortuna herself.</p>
				<p>I don't know much about that, but legend tells of a great Sage at the End of the World with knowledge of such things. Perhaps the artifact can be restored…</p>
				<p>Speaking of, we're ready to set sail when you are!</p>
			</div>
			<div v-else class="soul-cycle-text">
				<SoulCycleOptions></SoulCycleOptions>
			</div>
		</div>
		<div class="column-two">
			<!-- <div class="biome-key-container">
				<PanelDivider :divider-text="'Use a biome key'"></PanelDivider>
				<div :class="{ 'active-soul-cycle': showSoulCycleContainer }" class="biome-key">
					<div class="biome-key-general-text">
						Biome Keys will only bring you to the last biome you unlocked <br />
						on your <span>current difficulty</span>
					</div>

					<div class="background-border">
						<div class="biome-key-buttons">
							<ArrowCheckbox
								v-tooltip="{
									content: departureWithInvalidPressureTooltip(!isValidPressureLoadout, totalPointsForPreviousWorldTier) || departureWithPartyTooltip(0),
									placement: 'bottom-center',
									offset: 0,
									delay: {
										show: 500,
										hide: 300,
									},
								}"
								button-text="Isle Beach"
								:button-text-middle="worldTierHasRecommendedDPS(currentlySelectedWorldTier) ? `Recommended DPS: ${getRecommendedDPSforBiomeAndWorldTier(currentlySelectedWorldTier, 0)}` : ''"
								:gray-text-middle="true"
								:disabled="disableDepartButtonIfPartied(0) || ftueMode || !isValidPressureLoadout"
								:loading="false"
								:label="'Default'"
								:hide-checkbox="true"
								:checkbox-index="0"
								:selected-index="getSelectedBiomeKey"
								@updateSelected="updateSelected"
							></ArrowCheckbox>
							<ArrowCheckbox
								v-tooltip="{
									content: departureWithBiomeKeyTooltip(numberOfBiomeKeysInStashByBiomeIndex(1) === 0 || hasReachedBiomeOnTier(1, currentlySelectedWorldTier) === false) || departureWithInvalidPressureTooltip(!isValidPressureLoadout, totalPointsForPreviousWorldTier) || departureWithPartyTooltip(1),
									placement: 'bottom-center',
									offset: 0,
									delay: {
										show: 500,
										hide: 300,
									},
								}"
								button-text="Terila Forest"
								:button-text-middle="worldTierHasRecommendedDPS(currentlySelectedWorldTier) ? `Recommended DPS: ${getRecommendedDPSforBiomeAndWorldTier(currentlySelectedWorldTier, 1)}` : ''"
								:gray-text-middle="true"
								:button-text-two="`x${numberOfBiomeKeysInStashByBiomeIndex(1)}`"
								:label="'use'"
								:disabled="numberOfBiomeKeysInStashByBiomeIndex(1) === 0 || hasReachedBiomeOnTier(1, currentlySelectedWorldTier) === false || disableDepartButtonIfPartied(1) || ftueMode || !isValidPressureLoadout"
								:checkbox-index="1"
								:selected-index="getSelectedBiomeKey"
								@updateSelected="updateSelected"
							></ArrowCheckbox>
							<ArrowCheckbox
								v-tooltip="{
									content: departureWithBiomeKeyTooltip(numberOfBiomeKeysInStashByBiomeIndex(2) === 0 || hasReachedBiomeOnTier(2, currentlySelectedWorldTier) === false) || departureWithInvalidPressureTooltip(!isValidPressureLoadout, totalPointsForPreviousWorldTier) || departureWithPartyTooltip(2),
									placement: 'bottom-center',
									offset: 0,
									delay: {
										show: 500,
										hide: 300,
									},
								}"
								button-text="Onyx Highlands"
								:button-text-middle="worldTierHasRecommendedDPS(currentlySelectedWorldTier) ? `Recommended DPS: ${getRecommendedDPSforBiomeAndWorldTier(currentlySelectedWorldTier, 2)}` : ''"
								:gray-text-middle="true"
								:button-text-two="`x${numberOfBiomeKeysInStashByBiomeIndex(2)}`"
								:label="'use'"
								:disabled="numberOfBiomeKeysInStashByBiomeIndex(2) === 0 || hasReachedBiomeOnTier(2, currentlySelectedWorldTier) === false || disableDepartButtonIfPartied(2) || ftueMode || !isValidPressureLoadout"
								:checkbox-index="2"
								:selected-index="getSelectedBiomeKey"
								@updateSelected="updateSelected"
							></ArrowCheckbox>
							<ArrowCheckbox
								v-tooltip="{
									content: departureWithBiomeKeyTooltip(numberOfBiomeKeysInStashByBiomeIndex(3) === 0 || hasReachedBiomeOnTier(3, currentlySelectedWorldTier) === false) || departureWithInvalidPressureTooltip(!isValidPressureLoadout, totalPointsForPreviousWorldTier) || departureWithPartyTooltip(3),
									placement: 'bottom-center',
									offset: 0,
									delay: {
										show: 500,
										hide: 300,
									},
								}"
								button-text="Fungi Steppes"
								:button-text-middle="worldTierHasRecommendedDPS(currentlySelectedWorldTier) ? `Recommended DPS: ${getRecommendedDPSforBiomeAndWorldTier(currentlySelectedWorldTier, 3)}` : ''"
								:gray-text-middle="true"
								:button-text-two="`x${numberOfBiomeKeysInStashByBiomeIndex(3)}`"
								:label="'use'"
								:disabled="numberOfBiomeKeysInStashByBiomeIndex(3) === 0 || hasReachedBiomeOnTier(3, currentlySelectedWorldTier) === false || disableDepartButtonIfPartied(3) || ftueMode || !isValidPressureLoadout"
								:checkbox-index="3"
								:selected-index="getSelectedBiomeKey"
								@updateSelected="updateSelected"
							></ArrowCheckbox>
							<ArrowCheckbox
								v-tooltip="{
									content: departureWithBiomeKeyTooltip(numberOfBiomeKeysInStashByBiomeIndex(4) === 0 || hasReachedBiomeOnTier(4, currentlySelectedWorldTier) === false) || departureWithInvalidPressureTooltip(!isValidPressureLoadout, totalPointsForPreviousWorldTier) || departureWithPartyTooltip(4),
									placement: 'bottom-center',
									offset: 0,
									delay: {
										show: 500,
										hide: 300,
									},
								}"
								button-text="Prism Sunset Cliffs"
								:button-text-middle="worldTierHasRecommendedDPS(currentlySelectedWorldTier) ? `Recommended DPS: ${getRecommendedDPSforBiomeAndWorldTier(currentlySelectedWorldTier, 4)}` : ''"
								:gray-text-middle="true"
								:button-text-two="`x${numberOfBiomeKeysInStashByBiomeIndex(4)}`"
								:label="'use'"
								:disabled="numberOfBiomeKeysInStashByBiomeIndex(4) === 0 || hasReachedBiomeOnTier(4, currentlySelectedWorldTier) === false || disableDepartButtonIfPartied(4) || ftueMode || !isValidPressureLoadout"
								:checkbox-index="4"
								:selected-index="getSelectedBiomeKey"
								@updateSelected="updateSelected"
							></ArrowCheckbox>
						</div>
					</div>
				</div>
			</div> -->
			<div class="difficulty-container">
				<PanelDivider :divider-text="'Choose a Difficulty'"></PanelDivider>
				<div class="grid-world-tiers">
					<div
						v-for="n in 4"
						:key="n"
						v-tooltip="{
							content: departureToWorldDifficultyTooltip(maxAllowedTier < n, n),
							placement: 'bottom-center',
							offset: 0,
							delay: {
								show: 500,
								hide: 300,
							},
						}"
						class="world-tier-icon"
						:class="{ selected: currentlySelectedWorldDifficulty === n, disabled: maxUnlockedDifficulty < n }"
						@click="selectNewWorldDifficulty(n)"
					>
						<div class="item-selected-indicator"></div>
						<div class="item-hovered-indicator"></div>
						<img :src="worldTierImages[(n - 1) * 3]" :alt="'world-tier-' + n" />
						<div v-show="maxUnlockedDifficulty >= n" class="progression-image">
							<img :src="progressionImageForDifficulty(n, maxAllowedTier)" />
						</div>
					</div>
				</div>
			</div>
			<div class="loadout-container">
				<PanelDivider :divider-text="'Loadout'"></PanelDivider>
				<div class="loadout-graphics">
					<div class="current-dps">
						<PlayerStatIcons :largeIcons="true" :icon-name="'dps'" :textOverlay="getMaxDps"></PlayerStatIcons>
						<div class="dps-label">DPS</div>
					</div>
					<div class="current-level">
						<div class="background-current-level">
							<div class="container-current-level"></div>
							<PlayerStatIcons v-if="showSoulCycleContainer" :largeIcons="true" :icon-name="'currentLevel'" :textOverlay="`${nextWorldTierMinLevel - 15} - ${nextWorldTierMinLevel - 1}`"></PlayerStatIcons>
							<PlayerStatIcons v-else :largeIcons="true" :icon-name="'currentLevel'" :textOverlay="'1 - 15'"></PlayerStatIcons>
						</div>
						<div class="current-label">Current Level</div>
					</div>
					<div class="recommended-level">
						<div class="background-recommended-level">
							<PlayerStatIcons v-if="showSoulCycleContainer" :largeIcons="true" :icon-name="'recommendedLevel'" :textOverlay="`${nextWorldTierMaxLevel - 14} - ${nextWorldTierMaxLevel}`"></PlayerStatIcons>
							<PlayerStatIcons v-else :largeIcons="true" :icon-name="'brokenRecommendedLevel'" :textOverlay="''"></PlayerStatIcons>
						</div>
						<div v-if="showSoulCycleContainer" class="recommended-label">Recommended lvl to progress</div>
						<div v-else class="recommended-label"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PanelDivider from '../../reusable-components/panel-divider/panel-divider.vue'
import SoulCycleOptions from './soul-cycle-options.vue'
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import ArrowCheckbox from '../../reusable-components/buttons/arrow-checkbox.vue'
import { departureToWorldDifficultyTooltip, departureWithBiomeKeyTooltip, departureWithPartyTooltip, departureWithInvalidPressureTooltip } from '../../reusable-components/v-tooltip/v-tooltip-functions'
import { isOnSufficientDifficultyToProgress, PROGRESSION_MAX_TIER_FOR_BETA, maxAllowedWorldDifficultyFromMaxAllowedTier } from '../../../engine/shared/game-data/progression'
import { MAX_ENABLED_DIFFICULTY, getWorldDifficultyPrettyString } from '../../../engine/shared/game-data/world-difficulty'
import worldTier1Image from '../assets/world-tier-1.png'
import worldTier2Image from '../assets/world-tier-2.png'
import worldTier3Image from '../assets/world-tier-3.png'
import worldTier4Image from '../assets/world-tier-4.png'
import worldTier5Image from '../assets/world-tier-5.png'
import worldTier6Image from '../assets/world-tier-6.png'
import worldTier7Image from '../assets/world-tier-7.png'
import worldTier8Image from '../assets/world-tier-8.png'
import worldTier9Image from '../assets/world-tier-9.png'
import worldTier10Image from '../assets/world-tier-10.png'
import progressionImage from '../assets/difficulty-progression.png'
import noProgressionImage from '../assets/difficulty-no-progression.png'
import PlayerStatIcons from '../../reusable-components/icons/icons.vue'

export default {
	name: 'Difficulty',
	components: {
		PanelDivider,
		SoulCycleOptions,
		ArrowCheckbox,
		PlayerStatIcons,
	},
	data() {
		return {
			worldTierImages: [worldTier1Image, worldTier2Image, worldTier3Image, worldTier4Image, worldTier5Image, worldTier6Image, worldTier7Image, worldTier8Image, worldTier9Image, worldTier10Image],
			dpsTable: {
				1: [20, 35, 45, 55, 65],
				2: [100, 120, 160, 250, 500],
				3: [600, 950, 1275, 1500, 2200],
			},
		}
	},
	props: {},
	computed: {
		...mapState('boatLaunch', ['ftueMode']),
		...mapGetters('boatLaunch', [
			'biomeIndex',
			'currentlySelectedWorldDifficulty',
			'hasReachedBiomeOnTier',
			'maxAllowedTier',
			'getPressureRank',
			'difficultyModifiers',
			'totalPointsSelected',
			'totalPointsForNextWorldTier',
			'totalPointsForPreviousWorldTier',
			'isValidPressureLoadout',
			'showSoulCycleContainer',
			'getSelectedBiomeKey',
			'currentlySelectedWorldTier',
		]),
		...mapGetters('miniPaperdoll', ['getMaxDps']),

		...mapGetters('party', ['getLeaderStatus', 'getPartyMembers', 'getCurrentlyPartiedStatus', 'isPartyLeader', 'getAllPartyMembersReady', 'getHasAdventureServer']),
		...mapGetters('itemContainers', ['hasBiomeKey', 'containerIsEmpty', 'itemsInContainer', 'numberOfBiomeKeysInStashByBiomeIndex']),

		soulCycleHeaderText() {
			const showSoulCycle = this.showSoulCycleContainer
			if (showSoulCycle) {
				return 'Soul Cycle ' + (this.maxAllowedTier - 1)
			} else {
				return '???'
			}
		},
		maxUnlockedDifficulty() {
			return Math.min(MAX_ENABLED_DIFFICULTY, maxAllowedWorldDifficultyFromMaxAllowedTier(this.maxAllowedTier))
		},
		nextWorldTierMinLevel: function() {
			return Math.max(0, this.maxAllowedTier - 1) * 15 + 1
		},
		nextWorldTierMaxLevel: function() {
			return Math.max(0, this.maxAllowedTier - 1) * 15 + 15
		},
	},
	methods: {
		departureWithBiomeKeyTooltip,
		departureToWorldDifficultyTooltip,
		departureWithPartyTooltip,
		departureWithInvalidPressureTooltip,

		...mapMutations('boatLaunch', ['selectNextBiome', 'selectPreviousBiome', 'goToAdventure', 'selectNewWorldDifficulty', 'updateSelectedBiomeKey']),
		worldTierHasRecommendedDPS(currentlySelectedWorldTier) {
			if (this.dpsTable[currentlySelectedWorldTier] === undefined) {
				return false
			}
			return this.dpsTable[currentlySelectedWorldTier][0] !== undefined
		},
		getRecommendedDPSforBiomeAndWorldTier(currentlySelectedWorldTier, biomeIndex) {
			return this.dpsTable[currentlySelectedWorldTier][biomeIndex] || 0
		},
		progressionImageForDifficulty(difficulty, currentlySelectedWorldTier) {
			if (this.maxAllowedTier >= PROGRESSION_MAX_TIER_FOR_BETA) {
				return noProgressionImage
			}

			if (isOnSufficientDifficultyToProgress(difficulty, currentlySelectedWorldTier)) {
				return progressionImage
			}

			return noProgressionImage
		},
		isDifficultySufficientToProgress(difficulty) {
			return isOnSufficientDifficultyToProgress(difficulty, this.maxAllowedTier)
		},
		disableDepartButtonIfPartied(biomeButton) {
			const isUserLeader = this.getPartyMembers.find((user) => user.playerId === this.userId && user.leader === true)
			if (this.getCurrentlyPartiedStatus) {
				if (!isUserLeader) {
					return true
				}
				if (isUserLeader && biomeButton === 0) {
					if (this.getAllPartyMembersReady) {
						return false
					} else {
						return true
					}
				} else {
					return true
				}
			}
			return false
		},
		updateSelected(value) {
			this.updateSelectedBiomeKey(value)
		},
	},
}
</script>

<style lang="less" scoped>
@import '../../global/fonts/text.less';
@import url('../../global/bg-gradients.less');

#difficulty {
	display: flex;
	flex-direction: row;
	height: 880px;
	.column-one {
		width: 647px;
		display: flex;
		flex-direction: column;
		text-align: center;
		.locked-soul-cycle {
			padding: 0px 10px;
			p {
				.soul-cycle-text-text();
				span {
					color: #e9e5ad;
				}
			}
			.broken-soul-badge {
				position: relative;
				width: 300px;
				height: 300px;
				margin-left:25px;
				.soul-cycle-broken-bg {
					position: absolute;
					transform: scale(0.12);
					transform-origin: top left;
				}
				.soul-cycle-broken-fg {
					position: absolute;
					left: 137px;
					top: -4px;
					transform: scale(0.12);
					transform-origin: top left;
				}
			}
		}
		.soul-cycle-text {
			margin-right: 5px;
			margin-top: 10px;
		}
	}
	.column-two {
		width: 647px;
		.biome-key-container {
			.biome-key {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				padding: 9px 0px;

				&.active-soul-cycle {
					background-color: #1e5987;
				}

				.biome-key-general-text {
					width: 600px;
					text-align: center;

					.biome-key-text();

					span {
						color: #e9e5ad;
					}
				}
				.background-border {
					border-image: url('../assets/soul-cycle-menu-border.png') 6 6 6 6 fill / 7px 7px 7px 7px;
					width: 613px;
					height: 312px;
					overflow-y: hidden;
					padding: 10px;
					.biome-key-buttons {
						width: 592px;
						overflow-y: scroll;
						height: 293px;
					}
				}
			}
		}
		.difficulty-container {
			margin-left: 5px;
			display: flex;
			flex-direction: column;
			align-items: center;
			paneldivider {
			}
			.grid-world-tiers {
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
				width: 100%;
				padding-bottom: 10px;
				margin-top: 15px;
				.world-tier-icon {
					height: 104px;
					width: 109px;
					display: inline-block;
					position: relative;
					margin: 3px;
					background-image: url('../../reusable-components/inventory-item/assets/item-slot-a.png');
					.item-selected-indicator {
						animation: shrink-grow 1s ease-in-out infinite alternate;
					}
					&:hover {
						&:not(.selected, .disabled) {
							.item-hovered-indicator {
								display: block;
							}
						}
					}
					.item-selected-indicator,
					.item-hovered-indicator {
						display: none;
						position: absolute;
						width: 113px;
						height: 108px;
						background-repeat: no-repeat;
					}
					.item-selected-indicator {
						background-image: url('../../reusable-components/inventory-item/assets/highlight-selected.png');
						top: -3px;
						left: -2px;
					}
					.item-hovered-indicator:not(&.disabled) {
						background-image: url('../../reusable-components/inventory-item/assets/highlight-hover.png');
						left: 0px;
						top: 0px;
					}
					.progression-image {
						position: absolute;
						left: 75px;
						top: 68px;

						img {
							width: 30px;
							vertical-align: middle;
						}
					}
					&.selected {
						.item-selected-indicator {
							display: block;
						}
					}
					&.disabled {
						opacity: 0.5;
						img {
							filter: grayscale(100%);
						}
					}
					img {
						position: relative;
						top: -4px;
						left: -4px;
					}
				}
			}
		}
		.loadout-container {
			margin-left: 5px;
			.loadout-graphics {
				display: flex;
				flex-direction: row;
				justify-content: space-evenly;
				margin-top: 15px;
				.current-dps {
					.dps-label {
						text-align: center;
						.difficulty-dps-label();
					}
				}
				.current-level {
					.current-label {
						text-align: center;
						.difficulty-current-label();
					}
				}
				.recommended-level {
					.recommended-label {
						width: 174px;
						text-align: center;
						.difficulty-recommended-label();
					}
				}
			}
		}
	}
}
</style>
