













import PlayerStatIcons from '../../reusable-components/icons/icons.vue'

export default {
	name: 'PlayerProgressIcon',
	props: {
		isBigger: {
			type: Boolean,
			default: false,
		},
		worldTier: {
			type: Number,
			required: true,
		},
	},
	components: {
		PlayerStatIcons,
	},
}
