import nengi from 'nengi'

class CreateChunk {
	static protocol = {
		x: nengi.Int32,
		y: nengi.Int32,
		data: nengi.String, // { type: nengi.UInt8, indexType: nengi.UInt16 }
	}
	x: number
	y: number
	data: string

	constructor(x, y, data) {
		this.x = x
		this.y = y
		this.data = data
	}
}

export default CreateChunk
