










import { onPropertyChangedHandler } from './helpers'

export default {
	name: 'SelectProperty',
	props: {
		rootObject: {
			type: Object,
			required: true,
		},
		propertySeekDotNotation: {
			type: String,
			required: true,
		},
		propertyKey: {
			type: String,
			required: true,
		},
		propertyValue: {
			required: true,
		},
		propertyOptions: {
			type: Object,
			required: true,
		},
		propertyChangedFn: {
			type: Function,
			default: undefined,
		},
	},
	data() {
		return {
			liveValue: this.propertyValue,
		}
	},
	methods: {
		onChange() {
			const enumValue = Number.parseInt(this.liveValue)
			let valueToUse = this.liveValue
			if (enumValue !== NaN) {
				valueToUse = enumValue
			}
			onPropertyChangedHandler(this.rootObject, this.propertySeekDotNotation, valueToUse)
			this.propertyChangedFn(this.rootObject, this.propertySeekDotNotation, valueToUse)
		},
	},
}
