import nengi from 'nengi'
import { uuid } from '../../utils/primitive-types'

class SendItemToWormMailCommand {
	static protocol = {
		itemId: nengi.String,
		npcId: nengi.Number,
	}

	itemId: uuid
	npcId: number

	constructor(itemId: uuid, npcId: number) {
		this.itemId = itemId
		this.npcId = npcId
	}
}

export default SendItemToWormMailCommand
