import WeaponAugmentSubType from './weapon-augment-sub-type'
import WeaponSubType from './weapon-sub-type'

enum GearSubType {
	Trinket = 1001,
	Belt = 1002,
	Pendant = 1003,
	Ring = 1004,
}

const GearSubTypePrettyName = new Map<GearSubType, string>()
GearSubTypePrettyName.set(GearSubType.Trinket, 'trinket')
GearSubTypePrettyName.set(GearSubType.Belt, 'belt')
GearSubTypePrettyName.set(GearSubType.Pendant, 'pendant')
GearSubTypePrettyName.set(GearSubType.Ring, 'ring')

assertUniqueEnumValues(WeaponSubType, WeaponAugmentSubType, GearSubType)

function assertUniqueEnumValues(...args) {
	const allValues = new Set<string>()
	args.forEach((arg) => {
		for (const value in arg) {
			if (!isNaN(+value)) {
				console.assert(!allValues.has(value), `value of ${value} in ${arg} exists in another enum`)
				allValues.add(value)
			}
		}
	})
}

export const GearSubTypes = [GearSubType.Belt, GearSubType.Pendant, GearSubType.Ring, GearSubType.Trinket]

export default GearSubType
export { GearSubType, GearSubTypePrettyName }
