import nengi from 'nengi'

export default class GCPause {
	static protocol = {
		type: nengi.String,
		pause: nengi.Boolean,
		duration: nengi.Float32
	}

	type: string
	pause: boolean
	duration: number

	constructor(type: string, duration: number) {
		this.type = type
		this.pause = true
		this.duration = duration
	}
}
