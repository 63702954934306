import nengi from 'nengi'
import { DrawShapeBase } from './draw-shape-base'

class DrawRectangle extends DrawShapeBase {
	static protocol = {
		...DrawShapeBase.protocol,
		topLeftX: nengi.Number,
		topLeftY: nengi.Number,
		width: nengi.Number,
		height: nengi.Number,
		rotation: nengi.Number,
		lineWidth: nengi.Number,
	}

	topLeftX: number
	topLeftY: number
	width: number
	height: number
	rotation: number
	lineWidth: number

	constructor(topLeftX: number, topLeftY: number, width: number, height: number, color: number, permanent: boolean, destroyAfterSeconds: number, scale: number, rotation: number = 0, lineWidth: number = 2) {
		super(color, permanent, destroyAfterSeconds, scale)
		this.topLeftX = topLeftX
		this.topLeftY = topLeftY
		this.width = width
		this.height = height
		this.rotation = rotation
		this.lineWidth = lineWidth
	}
}

export default DrawRectangle
