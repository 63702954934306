import BiomeKeyType from './biome-key'
import { CurrencyBagSubType, CurrencySubTypePrettyName } from './currency-bag-sub-type'
import GearSubType, { GearSubTypePrettyName } from './gear-sub-type'
import { PersistentBuffSubType, PersistentBuffSubtypePrettyName } from './persistent-buff-sub-type'
import { PowerUpSubType, PowerUpSubTypePrettyName } from './power-up-sub-type'
import { PrizeSubType, PrizeSubTypePrettyName } from './prize-sub-type'
import WeaponAugmentSubType, { WeaponAugmentConfigData } from './weapon-augment-sub-type'
import { WeaponEnchantmentConfigData, WeaponEnchantmentSubType } from './weapon-enchantment-sub-type'
import WeaponSubType, { WeaponSubTypePrettyName } from './weapon-sub-type'
import { WormHornSubtype, WormHornSubTypePrettyName } from './worm-horn-sub-type'

enum ItemType {
	Weapon = 100,
	Gear = 200,
	WeaponAugment = 101,
	GearAugment = 201,
	WeaponEnchantment = 102,
	GearEnchantment = 202,
	BiomeKey = 1000,
	LuckyShard = 1001,
	RarityShard = 1002,
	PowerUp = 2000,
	Prize = 3000,
	WormHorn = 4000,
	CurrencyBag = 5000,
	PersistentBuff = 6000,
}

//TODO: this violates our "shared" rules and pulls in server-side logic in some of these subtypes
type ItemSubType = WeaponSubType | WeaponAugmentSubType | WeaponEnchantmentSubType | GearSubType | PowerUpSubType | PrizeSubType | BiomeKeyType | WormHornSubtype | CurrencyBagSubType | PersistentBuffSubType | -1

const ItemTypePrettyName = new Map<ItemType, string>()
ItemTypePrettyName.set(ItemType.Weapon, 'weapon')
ItemTypePrettyName.set(ItemType.Gear, 'gear')
ItemTypePrettyName.set(ItemType.WeaponAugment, 'weapon augment')
ItemTypePrettyName.set(ItemType.GearAugment, 'gear augment')
ItemTypePrettyName.set(ItemType.WeaponEnchantment, 'weapon enchantment')
ItemTypePrettyName.set(ItemType.GearEnchantment, 'gear enchantment')
ItemTypePrettyName.set(ItemType.BiomeKey, 'biome key')
ItemTypePrettyName.set(ItemType.LuckyShard, 'lucky shard')
ItemTypePrettyName.set(ItemType.RarityShard, 'rarity shard')
ItemTypePrettyName.set(ItemType.WormHorn, 'worm horn')
ItemTypePrettyName.set(ItemType.CurrencyBag, 'currency bag')
ItemTypePrettyName.set(ItemType.PersistentBuff, 'persistent buff')

ItemTypePrettyName.set(ItemType.PowerUp, 'Power UP!') //shouldn't be client-facing
ItemTypePrettyName.set(ItemType.Prize, 'prize') //  """"

export function getItemSubTypeByType(itemType: ItemType) {
	switch (itemType) {
		case ItemType.Weapon:
			return WeaponSubType
		case ItemType.WeaponAugment:
			return WeaponAugmentSubType
		case ItemType.WeaponEnchantment:
			return WeaponEnchantmentSubType
		case ItemType.Gear:
			return GearSubType
		case ItemType.PowerUp:
			return PowerUpSubType
		case ItemType.Prize:
			return PrizeSubType
		case ItemType.BiomeKey:
			return BiomeKeyType
		case ItemType.WormHorn:
			return WormHornSubtype
		case ItemType.CurrencyBag:
			return CurrencyBagSubType
		case ItemType.PersistentBuff:
			return PersistentBuffSubType
		default:
			console.error(`Could not get SubType enum for itemType ${ItemType[itemType]} (${itemType})`)
			return undefined
	}
}

export function getItemSubTypePrettyName(itemType: ItemType, itemSubType: ItemSubType) {
	switch (itemType) {
		case ItemType.Weapon:
			return WeaponSubTypePrettyName.get(itemSubType as WeaponSubType)
		case ItemType.WeaponAugment:
			return WeaponAugmentConfigData.get(itemSubType as WeaponAugmentSubType).prettyName
		case ItemType.WeaponEnchantment:
			return WeaponEnchantmentConfigData.get(itemSubType as WeaponEnchantmentSubType).prettyName
		case ItemType.Gear:
			return GearSubTypePrettyName.get(itemSubType as GearSubType)
		case ItemType.PowerUp:
			return PowerUpSubTypePrettyName.get(itemSubType as PowerUpSubType)
		case ItemType.Prize:
			return PrizeSubTypePrettyName.get(itemSubType as PrizeSubType)
		case ItemType.WormHorn:
			return WormHornSubTypePrettyName.get(itemSubType as WormHornSubtype)
		case ItemType.CurrencyBag:
			return CurrencySubTypePrettyName.get(itemSubType as CurrencyBagSubType)
		case ItemType.PersistentBuff:
			return PersistentBuffSubtypePrettyName.get(itemSubType as PersistentBuffSubType)
		default:
			return itemSubType
	}
}

export default ItemType
export { ItemType, ItemTypePrettyName, ItemSubType }
