import nengi from 'nengi'

class ClientResizedCommand {
	static protocol = {
		newWidth: nengi.Number,
		newHeight: nengi.Number,
	}
	newWidth: number
	newHeight: number
	constructor(newWidth: number, newHeight: number) {
		this.newWidth = newWidth
		this.newHeight = newHeight
	}
}

export default ClientResizedCommand
