import logger from '../../utils/client-logger'
import { getBiomeList } from '../../biome/shared/biome-list'
import { countdownToDeparture, endGame, startGame } from '../../engine/client/start-stop'
import { GameModeType } from '../../engine/shared/game-mode-type'
import { UI } from '../ui'
import { analyticsEventStartAdventure } from './google-analyitics'
import { ConnectionPayload } from '../../engine/shared/connection-payload'
import { WorldTier, biomeIndex, pressureRank } from '../../utils/primitive-types'
import { isDifficultyEnabled, throwIfInvalidWorldDifficulty, WorldDifficulty } from '../../engine/shared/game-data/world-difficulty'
import { IPressureStatInfo, PressureLoadout, PressureStat, pressureStatInfos, PRESSURE_MENU_UNLOCK_TIERS_DEFEATED, PRESSURE_POINTS_PER_TIER } from '../../engine/shared/game-data/pressure-stat-info.shared'
import { BiomeData } from '../../biome/shared/biome-data/biome-data-structures'
import { getEmptyLoadout, savePressureLoadoutToLocalStorage } from '../../engine/client/pressure.client'
import { GameClient } from '../../engine/client/game-client'
import { NengiClient } from '../../engine/client/nengi-client'
import PartyExitGameCommand from '../../party-play/shared/party-exit-game-command'
import { maxAllowedWorldDifficultyFromMaxAllowedTier, PROGRESSION_MAX_TIER_FOR_BETA, isOnSufficientDifficultyToProgress } from '../../engine/shared/game-data/progression'
import  PlayerMovingToBiomeCommand  from '../../player/shared/player-moving-to-biome-command'

interface BoatLaunchState {
	biomes: BiomeData[]
	biomeIndex: biomeIndex
	selectedWorldDifficulty: WorldDifficulty
	selectedWorldTier: WorldTier
	maxAllowedBiomeIndex: biomeIndex
	maxAllowedTier: WorldTier
	highestCompletedTier: WorldTier
	pressureLoadout: PressureLoadout
	pressurePointsSelected: number
	ftueMode: boolean
	defaultBeachBiome: number
	disableDifficultyOptions: boolean
}

export interface UIPressureStatInfo extends IPressureStatInfo {
	ranksSelected: number
	statType: PressureStat
}

export const boatLaunchModule = () => {
	logger.debug('Initializing boat launch module')
	return {
		namespaced: true,
		state: {
			biomes: getBiomeList(GameModeType.Adventure),
			biomeIndex: 0,
			selectedWorldDifficulty: 1,
			selectedWorldTier: 1,
			maxAllowedBiomeIndex: 0,
			maxAllowedTier: WorldDifficulty.Normal,
			highestCompletedTier: WorldDifficulty.Normal,
			pressureLoadout: getEmptyLoadout(),
			pressurePointsSelected: 0,
			ftueMode: false,
			defaultBeachBiome: 0,
			disableDifficultyOptions: true,
			biomeKey: -1
		},
		getters: {
			pressureLoadout(state: BoatLaunchState) {
				return state.pressureLoadout
			},
			selectedBiome(state: BoatLaunchState) {
				return state.biomes[state.biomeIndex].name
			},
			biomeIndex(state: BoatLaunchState) {
				return state.biomeIndex
			},
			currentlySelectedWorldDifficulty(state: BoatLaunchState) {
				return state.selectedWorldDifficulty
			},
			currentlySelectedWorldTier(state: BoatLaunchState) {
				return state.selectedWorldTier
			},
			maxAllowedBiomeIndex(state: BoatLaunchState) {
				return state.maxAllowedBiomeIndex
			},
			maxAllowedTier(state: BoatLaunchState) {
				return state.maxAllowedTier
			},
			hasUnlockedSoul(state: BoatLaunchState) {
				return state.maxAllowedTier > PRESSURE_MENU_UNLOCK_TIERS_DEFEATED
			},
			hasReachedBiomeOnTier(state: BoatLaunchState) {
				return (biomeIdx, worldTier) => {
					if (state.highestCompletedTier >= worldTier) {
						return true
					}
					return biomeIdx <= state.maxAllowedBiomeIndex
				}
			},
			getPressureRank: (state: BoatLaunchState) => (rank: pressureRank) => {
				return state.pressureLoadout[rank] || 0
			},
			difficultyModifiers(state: BoatLaunchState, getters) {
				const difficultyModifiers = []
				for (const pressureStat in pressureStatInfos) {
					if (pressureStatInfos.hasOwnProperty(pressureStat)) {
						difficultyModifiers.push({
							...pressureStatInfos[pressureStat],
							ranksSelected: getters.getPressureRank(pressureStat),
							statType: pressureStat,
						})
					}
				}
				return difficultyModifiers
			},
			totalPointsSelected(state: BoatLaunchState, getters) {
				const points = getters.difficultyModifiers.reduce((prev, modifier: UIPressureStatInfo) => {
					return prev + modifier.ranksSelected * modifier.pointsPerRank
				}, 0)
				state.pressurePointsSelected = points
				return points
			},
			totalPointsForNextWorldTier(state: BoatLaunchState) {
				// will be 1 when heat is first unlocked (`maxAllowedTier` is 4 at this point)
				// will be 2 after betting a tier on heat
				// etc...
				const tierRelativeToHeatStart = state.maxAllowedTier - PRESSURE_MENU_UNLOCK_TIERS_DEFEATED
				return Math.max(1, Math.max(0, tierRelativeToHeatStart) * PRESSURE_POINTS_PER_TIER)
			},
			totalPointsForPreviousWorldTier(state: BoatLaunchState) {
				const tierRelativeToHeatStart = state.maxAllowedTier - 1 - PRESSURE_MENU_UNLOCK_TIERS_DEFEATED
				return Math.max(0, tierRelativeToHeatStart) * PRESSURE_POINTS_PER_TIER
			},
			isValidPressureLoadout(state: BoatLaunchState, getters) {
				return getters.totalPointsSelected >= getters.totalPointsForPreviousWorldTier
			},
			showSoulCycleContainer(state: BoatLaunchState) {
				const maxWorldTier = UI.getInstance().store.getters['boatLaunch/maxAllowedTier']
				if (maxWorldTier > PRESSURE_MENU_UNLOCK_TIERS_DEFEATED) {
					return true
				} else {
					return false
				}
			},
			getSelectedBiomeKey(state) {
				return state.defaultBeachBiome
			},
			isMeterFull(state: BoatLaunchState, getters) {
				return getters.totalPointsSelected >= getters.totalPointsForNextWorldTier
			},
		},
		actions: {
			openDepartureFlyoutPanelIfValid({ state, commit }) {
				const maxWorldTier = UI.getInstance().store.getters['boatLaunch/maxAllowedTier']
				if (maxWorldTier > PRESSURE_MENU_UNLOCK_TIERS_DEFEATED) {
					setTimeout(() => {
						const flyoutPanelName = 'depart'
						commit('inGame/updateFlyoutPanel', flyoutPanelName, { root: true })
						UI.getInstance().emitEvent('UIScale/updatePanelWidth', flyoutPanelName)
					}, 250)
				}
			},
			updateReadyStateByPressurePoints({ state, dispatch, getters }) {
				const partyId = UI.getInstance().store.getters['party/getPartyId']

				if (partyId && !getters.isValidPressureLoadout) {
					dispatch('party/setUnready', {}, { root: true })
				}
			},
			goToAdventure(context, biomeIdxAndCanProgress: { biomeIdx: biomeIndex; canProgress: boolean }) {
				const { biomeIdx, canProgress } = biomeIdxAndCanProgress
				const { state, getters } = context

				const username = UI.getInstance().store.getters['user/username']
				const userId = UI.getInstance().store.getters['user/userId']
				const partyId = UI.getInstance().store.getters['party/getPartyId']
				const hasAdventureServer = UI.getInstance().store.getters['party/getHasAdventureServer']

				const description = getDepartureDescription(canProgress)

				const currentlyPartiedStatus = UI.getInstance().store.getters['party/getCurrentlyPartiedStatus']

				async function depart() {
					UI.getInstance().emitEvent('party/setReadyToDepart', true)

					if (partyId && !hasAdventureServer) {
						UI.getInstance().emitAsyncEvent('party/triggerDepartureCountdown')
						await countdownToDeparture()
					}

					const readyToDepart = UI.getInstance().store.getters['party/getReadyToDepart']
					if (!readyToDepart) {
						logger.info(`Departure cancelled due to party state change`)
						return
					}

					analyticsEventStartAdventure(state.selectedWorldTier, biomeIdx, username, userId)

					logger.info(`Attempting to travel to world tier: ${state.selectedWorldTier}`)
					if (state.selectedWorldDifficulty > state.maxAllowedTier) {
						logger.error(`Attempting to travel to an invalid world difficulty ${state.selectedWorldDifficulty}!`)
						return
					}
					if (state.selectedWorldTier > state.maxAllowedTier) {
						state.selectedWorldTier = state.maxAllowedTier
					}

					console.log(`GOING TO ADVENTURE WITH DETAILS: world tier ${state.selectedWorldTier}, biome index ${biomeIdx}`)

					if (partyId) {
						GameClient.getInstance().exitGameFunction = () => {
							endGame()

							// @ts-ignore TODO2: better connection payload storage in upcoming PR
							// @ts-ignore TODO2: store this not on the window
							const connectionPayload: ConnectionPayload = window.originalConnectionPayload

							connectionPayload.biomeIndex = biomeIdx

							connectionPayload.worldDifficulty = state.selectedWorldDifficulty

							connectionPayload.pressureLoadout = state.pressureLoadout

							startGame(GameModeType.Adventure, connectionPayload, partyId)
						}

						NengiClient.getInstance().sendCommand(new PartyExitGameCommand(partyId))
					} else {
						endGame()

						// @ts-ignore TODO2: better connection payload storage in upcoming PR
						// @ts-ignore TODO2: store this not on the window
						const connectionPayload: ConnectionPayload = window.originalConnectionPayload

						connectionPayload.biomeIndex = biomeIdx

						connectionPayload.worldDifficulty = state.selectedWorldDifficulty

						connectionPayload.pressureLoadout = state.pressureLoadout

						startGame(GameModeType.Adventure, connectionPayload, partyId)
					}
				}

				if (hasAdventureServer || !currentlyPartiedStatus) {
					UI.getInstance().emitAsyncEvent('genericYesNo/showMenu', {
						title: 'Ready for adventure?',
						description: description,
						noButtonText: 'Stay',
						yesButtonText: 'Depart',
						yesCallback: async () => {
							depart()
						},
					})
				} else {
					UI.getInstance().emitAsyncEvent('genericYesNo/showMenu', {
						title: 'Ready for adventure?',
						description: `Are you ready to set sail on your adventure?`,
						noButtonText: 'Stay',
						yesButtonText: 'Depart',
						yesCallback: async () => {
							depart()
						},
					})
				}

				// set back to 0 so we can default back to going to the beach after you depart.
				state.defaultBeachBiome = 0
			},
			departToBiome({state, dispatch, commit}){
				dispatch('inGame/updateTransitionOverlay', true, {root: true})
				NengiClient.getInstance().sendCommand(new PlayerMovingToBiomeCommand(state.biomeKey))
				commit('resetBiomeKey')
			}
		},
		mutations: {
			updateBiomeKey(state: any, key: number){
				if(state.biomeKey === key){
					state.biomeKey = -1
				}
				state.biomeKey = key
			},
			setInitialLoadout(state: BoatLaunchState, pressureLoadout: PressureLoadout) {
				state.pressureLoadout = pressureLoadout
			},
			selectNewWorldDifficulty(state: BoatLaunchState, newWorldDifficulty: WorldDifficulty) {
				throwIfInvalidWorldDifficulty(newWorldDifficulty)

				if (state.disableDifficultyOptions) {
					return
				}

				if (!isDifficultyEnabled(newWorldDifficulty)) {
					return
				}

				if (newWorldDifficulty > maxAllowedWorldDifficultyFromMaxAllowedTier(state.maxAllowedTier)) {
					throw new Error(`attempting to select world difficulty ${newWorldDifficulty}, but the highest allowed for this player is ${state.maxAllowedTier}`)
				} else {
					state.selectedWorldDifficulty = newWorldDifficulty
				}

				if (state.maxAllowedTier <= PRESSURE_MENU_UNLOCK_TIERS_DEFEATED) {
					state.selectedWorldTier = newWorldDifficulty
				}
				state.defaultBeachBiome = 0
			},
			selectNewWorldTier(state: BoatLaunchState, newWorldTier: WorldTier) {
				state.selectedWorldTier = newWorldTier
			},
			updateMaxAllowedBiomeIndex(state: BoatLaunchState, maxAllowedBiomeIndex: biomeIndex) {
				state.maxAllowedBiomeIndex = maxAllowedBiomeIndex
			},
			updateHighestCompletedTier(state: BoatLaunchState, highestCompletedTier: WorldTier) {
				state.highestCompletedTier = highestCompletedTier
			},
			updateMaxAllowedTier(state: BoatLaunchState, maxAllowedTier: WorldTier) {
				state.maxAllowedTier = maxAllowedTier
			},
			updatePressurePointsSelected(state: BoatLaunchState, newPoints: number) {
				state.pressurePointsSelected = newPoints
			},
			setPressureRank(state: BoatLaunchState, pressureStatAndRank: { stat: PressureStat; rank: pressureRank }) {
				state.pressureLoadout[pressureStatAndRank.stat] = pressureStatAndRank.rank
				const userId = UI.getInstance().store.getters['user/userId']
				savePressureLoadoutToLocalStorage(userId, state.pressureLoadout)
			},
			setFtueMode(state: BoatLaunchState, isFtueMode) {
				state.ftueMode = isFtueMode
			},
			disableDifficultyOptions(state: BoatLaunchState) {
				state.disableDifficultyOptions = true
			},
			enableSoulCycleOptions(state: BoatLaunchState) {
				state.disableDifficultyOptions = false
			},
			resetBiomeKey(state){
				state.biomeKey = -1
			}
		},
	}
}

export function getDepartureDescription(canProgress) {
	let description = ''
	const uiInst = UI.getInstance()

	const maxAllowedTier = uiInst.store.state.boatLaunch.maxAllowedTier

	const selectedWorldDifficulty = uiInst.store.state.boatLaunch.selectedWorldDifficulty

	const isMeterFull = uiInst.store.getters['boatLaunch/isMeterFull']

	if (maxAllowedTier <= 1 || maxAllowedTier >= PROGRESSION_MAX_TIER_FOR_BETA || canProgress) {
		description = `Are all of your affairs in order? You will not be able to modify your gear or loadout until you're back in town.`
	} else if ((!isMeterFull && !isOnSufficientDifficultyToProgress(selectedWorldDifficulty, maxAllowedTier)) || !isMeterFull) {
		description = `Warning: The <span class="depart-prompt gold">Soul Cycle options</span> you've selected are <span class="depart-prompt strong">too easy</span> for someone of your renown. You will not be able to advance further in your Soul Cycles without raising the stakes.<br>
					<br>
					Add more <span class="depart-prompt gold">Soul Cycle points</span> in order to progress.<br>
					<br>
					Depart anyway?`
	} else if (!isOnSufficientDifficultyToProgress(selectedWorldDifficulty, maxAllowedTier)) {
		description = `Warning: The <span class="depart-prompt gold">World Difficulty</span> you selected is <span class="depart-prompt strong">too easy</span> for someone of your renown. You will not be able to advance further in your Soul Cycles without visiting a more challenging world.<br>
					<br>
					Increase your <span class="depart-prompt gold">World Difficulty</span> in order to progress.<br>
					<br>
					Depart anyway?`
	}
	return description
}
