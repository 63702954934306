


























import { mapMutations, mapState, mapActions } from 'vuex'
import ArrowButton from '../reusable-components/buttons/arrow-button.vue'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import PanelDivider from '../reusable-components/panel-divider/panel-divider.vue'
import SkinGridItemContainer from '../reusable-components/item-containers/skins-grid-item-container.vue'
import { SKIN_SLOT } from '../../player/shared/set-skin.shared'

export default {
	name: 'CharacterSelection',
	components: {
		MainPanel,
		ArrowButton,
		PanelDivider,
		SkinGridItemContainer,
	},
	props: {},
	data() {
		return {
			selectableCharacter: [
				{
					source: 'free',
					identifier: 'wizard',
					cosmeticType: 'Player Skin',
					skinSlot: SKIN_SLOT.PLAYER_MAIN
				},
				{
					source: 'free',
					identifier: 'ranger',
					cosmeticType: 'Player Skin',
					skinSlot: SKIN_SLOT.PLAYER_MAIN
				},
				{
					source: 'free',
					identifier: 'human-knight',
					cosmeticType: 'Player Skin',
					skinSlot: SKIN_SLOT.PLAYER_MAIN
				},
				{
					source: 'free',
					identifier: 'dwarf',
					cosmeticType: 'Player Skin',
					skinSlot: SKIN_SLOT.PLAYER_MAIN
				},
			],
		}
	},
	computed: {
		...mapState('cosmetics', ['selectedMainSkin']),
	},
	methods: {
		...mapMutations('cosmetics', ['setSelectedMainSkin']),
		...mapActions('cosmetics', ['changeEquippedSkin', 'setInitialPlayerSkin']),

		itemIsSelected(item) {
			return item.identifier === this.selectedMainSkin
		},
		setMainSkin() {
			this.setInitialPlayerSkin()
		},
	},
}
