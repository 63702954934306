import '../../utils/standard-library-additions'
import { GameModeType } from '../../engine/shared/game-mode-type'
import EnemyDensity, { EnemySpawnOverlapHorizontal, EnemySpawnOverlapVertical, EnemySpawnPackRadius } from './enemy-density'
import { WorldData } from './biome-data/biome-data-structures'
import { defaultStamps, highlandsStamps, townStamps } from './biome-data/stamp-data'
import { beachPropClumps, forestPropClumps, fungiPropClumps, highlandsPropClumps, prismPropClumps } from './biome-data/prop-data'
import { beachPOIs, forestPOIs, fungiPOIs, highlandsPOIs, prismPOIs, testRealmPois, townPOIs } from './biome-data/poi-data'
import { LEVELS_PER_BIOME, LEVEL_STEP_BETWEEN_BIOMES } from './biome-constants'
import { biomeIndex } from '../../utils/primitive-types'
import DestructiblePropDensity from './destructible-prop-density'
import { BeachDestructibleProps, ForestDestructibleProps, FungiDestructibleProps, HighlandsDestructibleProps, PrismDestructibleProps } from '../../ai/shared/enemies/weighted-destructible-props'

export enum BiomeIdentifier {
	// Hub
	TOWN = 'town',

	// Adventure
	BEACH = 'beach',
	FOREST = 'forest',
	HIGHLANDS = 'highlands',
	FUNGI = 'fungi',
	PRISM = 'prism',
}

const worlds: Map<GameModeType, WorldData> = new Map<GameModeType, WorldData>()

worlds.set(GameModeType.Hub, {
	mapChunkSize: 100,
	heightMapScale: 0.3,
	xWater: 0.0,
	yWater: 0.3,
	stampChunkSize: 512,
	stampScaleMin: 1,
	stampScaleMax: 1,
	stampRotationMin: 0,
	stampRotationMax: 0,
	propChunkSize: 512,
	godRayOffsetX: 1000,
	godRayOffsetY: -160,
	biomeFuzziness: 0.2,
	biomeMapScale: 1.0,
	biomeList: [
		{
			identifier: BiomeIdentifier.TOWN,
			name: 'Hub Town',
			bgm: 'MUS_HUB_LP',
			enemyDensity: EnemyDensity.NONE,
			enemySpawnPackRadius: EnemySpawnPackRadius.NORMAL,
			enemySpawnOverlapHorizontal: EnemySpawnOverlapHorizontal.NORMAL,
			enemySpawnOverlapVertical: EnemySpawnOverlapVertical.NORMAL,
			destructibleProps: [],
			destructiblePropDensity: DestructiblePropDensity.NONE,
			ambianceEffect: 'town-ambience',
			groundAtlas: 'townGround',
			cliffAtlas: 'beachCliffs',
			waterColor: [91, 198, 187, 255],
			gradientColor: [26, 68, 141],
			folderName: 'town',
			stamps: [...townStamps],
			stampDensity: 1.1,
			propClumps: [],
			propDensity: 0.0,
			lightChance: 0.15,
			godRaysChance: 0.3,
			lightScaleMin: 13.0,
			lightScaleMax: 16.0,
			lightColors: [
				{ r: 38, g: 37, b: 0, weight: 100 },
				{ r: 85, g: 31, b: 117, weight: 100 },
			],
			pointsOfInterest: townPOIs,
			safeZones: [
				{
					x: -0.01, //left safe zone
					y: 0,
					width: 3906,
					height: 10240,
					noShoot: true,
				},
				{
					x: 0,
					y: 0,
					width: 10240,
					height: 5283,
					noShoot: true,
				},
				{
					x: 0.355,
					y: 0,
					width: 5120,
					height: 10240,
					noShoot: true,
				},
				{
					x: 0,
					y: 0.655,
					width: 10240,
					height: 5120,
					noShoot: true,
				},
				{
					x: 0.238, // Stash position.x / WORLD_WIDTH
					y: 0.551, // Stash position.y / WORLD_HEIGHT
					radius: 200,
					noShoot: true,
				}
			],
			minRandomPois: 0,
			maxRandomPois: 0
		},
	],
})

worlds.set(GameModeType.Adventure, {
	mapChunkSize: 128,
	heightMapScale: 0.8,
	xWater: 0.0,
	yWater: 0.2916666667, // (WORLD_HEIGHT/CHUNKSIZE)*yWater must be a whole number or we get holes in the land near cliffs
	stampChunkSize: 512,
	stampScaleMin: 0.85,
	stampScaleMax: 1.15,
	stampRotationMin: -0.1,
	stampRotationMax: 0.1,
	propChunkSize: 1024,
	godRayOffsetX: 1000,
	godRayOffsetY: -160,
	biomeFuzziness: 0.2,
	biomeMapScale: 1.0,
	biomeList: [
		{
			identifier: BiomeIdentifier.BEACH,
			name: 'Isle Beach',
			bgm: 'MUS_BEACH_LP',
			enemyDensity: EnemyDensity.LIGHT,
			enemySpawnPackRadius: EnemySpawnPackRadius.NORMAL,
			enemySpawnOverlapHorizontal: EnemySpawnOverlapHorizontal.NORMAL,
			enemySpawnOverlapVertical: EnemySpawnOverlapVertical.NORMAL,
			destructibleProps: BeachDestructibleProps,
			destructiblePropDensity: DestructiblePropDensity.LIGHT,
			ambianceEffect: 'beach-ambience',
			groundAtlas: 'beachGround',
			cliffAtlas: 'beachCliffs',
			waterColor: [91, 198, 187, 255],
			gradientColor: [255, 214, 105],
			folderName: 'beach',
			stamps: [...defaultStamps],
			stampDensity: 0.8,
			propClumps: [...beachPropClumps],
			propDensity: 1.0,
			lightChance: 0.5,
			godRaysChance: 0.41,
			lightScaleMin: 14.0,
			lightScaleMax: 24.0,
			lightColors: [
				{ r: 38, g: 37, b: 0, weight: 100 },
				{ r: 85, g: 31, b: 117, weight: 100 },
			],
			pointsOfInterest: beachPOIs,
			safeZones: [],
			minRandomPois: 2,
			maxRandomPois: 2
		},
		{
			identifier: BiomeIdentifier.FOREST,
			name: 'Terila Forest',
			bgm: 'MUS_FOREST_LP',
			enemyDensity: EnemyDensity.LIGHT,
			enemySpawnPackRadius: EnemySpawnPackRadius.NORMAL,
			enemySpawnOverlapHorizontal: EnemySpawnOverlapHorizontal.NORMAL,
			enemySpawnOverlapVertical: EnemySpawnOverlapVertical.NORMAL,
			destructibleProps: ForestDestructibleProps,
			destructiblePropDensity: DestructiblePropDensity.LIGHT,
			ambianceEffect: 'forest-ambience',
			groundAtlas: 'forestGround',
			cliffAtlas: 'forestCliffs',
			waterColor: [55, 77, 90, 255],
			gradientColor: [26, 68, 141],
			folderName: 'forest',
			stamps: [...defaultStamps],
			stampDensity: 0.8,
			propClumps: [...forestPropClumps],
			propDensity: 1.0,
			lightChance: 0.5,
			godRaysChance: 0.4,
			lightScaleMin: 14.0,
			lightScaleMax: 24.0,
			lightColors: [
				{ r: 38, g: 37, b: 0, weight: 100 },
				{ r: 85, g: 31, b: 117, weight: 100 },
			],
			pointsOfInterest: forestPOIs,
			safeZones: [],
			minRandomPois: 2,
			maxRandomPois: 2
		},
		{
			identifier: BiomeIdentifier.HIGHLANDS,
			name: 'Onyx Highlands',
			bgm: 'MUS_HIGHLANDS_LP',
			enemyDensity: EnemyDensity.LIGHT,
			enemySpawnPackRadius: EnemySpawnPackRadius.NORMAL,
			enemySpawnOverlapHorizontal: EnemySpawnOverlapHorizontal.OVERLAPPING,
			enemySpawnOverlapVertical: EnemySpawnOverlapVertical.NORMAL,
			destructibleProps: HighlandsDestructibleProps,
			destructiblePropDensity: DestructiblePropDensity.LIGHT,
			ambianceEffect: 'highlands-ambience',
			groundAtlas: 'highlandsGround',
			cliffAtlas: 'highlandsCliffs',
			waterColor: [23, 31, 38, 255],
			gradientColor: [34, 40, 46],
			folderName: 'highlands',
			stamps: [...highlandsStamps],
			stampDensity: 0.8,
			propClumps: [...highlandsPropClumps],
			propDensity: 1.0,
			lightChance: 0.5,
			godRaysChance: 0.2,
			lightScaleMin: 14.0,
			lightScaleMax: 24.0,
			lightColors: [
				{ r: 38, g: 37, b: 0, weight: 100 },
				{ r: 85, g: 31, b: 117, weight: 100 },
			],
			pointsOfInterest: highlandsPOIs,
			safeZones: [],
			minRandomPois: 2,
			maxRandomPois: 2
		},
		{
			identifier: BiomeIdentifier.FUNGI,
			name: 'Carmine Fungi Steppes',
			bgm: 'MUS_FUNGI_LP',
			enemyDensity: EnemyDensity.LIGHT,
			enemySpawnPackRadius: EnemySpawnPackRadius.WIDE,
			enemySpawnOverlapHorizontal: EnemySpawnOverlapHorizontal.OVERLAPPING,
			enemySpawnOverlapVertical: EnemySpawnOverlapVertical.OVERLAPPING,
			destructibleProps: FungiDestructibleProps,
			destructiblePropDensity: DestructiblePropDensity.LIGHT,
			ambianceEffect: 'fungi-ambience',
			groundAtlas: 'fungiGround',
			cliffAtlas: 'fungiCliffs',
			waterColor: [136, 113, 37, 255],
			gradientColor: [84, 18, 18],
			folderName: 'fungi',
			stamps: [...defaultStamps],
			stampDensity: 0.8,
			propClumps: [...fungiPropClumps],
			propDensity: 1.0,
			lightChance: 0.5,
			godRaysChance: 0.4,
			lightScaleMin: 14.0,
			lightScaleMax: 24.0,
			lightColors: [
				{ r: 38, g: 37, b: 0, weight: 100 },
				{ r: 85, g: 31, b: 117, weight: 100 },
			],
			pointsOfInterest: fungiPOIs,
			safeZones: [],
			minRandomPois: 2,
			maxRandomPois: 2
		},
		{
			identifier: BiomeIdentifier.PRISM,
			name: 'Prism Sunset Cliffs',
			bgm: 'MUS_PRISM_LP',
			enemyDensity: EnemyDensity.LIGHT,
			enemySpawnPackRadius: EnemySpawnPackRadius.TIGHT,
			enemySpawnOverlapHorizontal: EnemySpawnOverlapHorizontal.OVERLAPPING,
			enemySpawnOverlapVertical: EnemySpawnOverlapVertical.NORMAL,
			destructibleProps: PrismDestructibleProps,
			destructiblePropDensity: DestructiblePropDensity.LIGHT,
			ambianceEffect: 'prism-ambience',
			groundAtlas: 'prismGround',
			cliffAtlas: 'prismCliffs',
			waterColor: [18, 80, 157, 255],
			gradientColor: [255, 133, 231],
			folderName: 'prism',
			stamps: [...defaultStamps],
			stampDensity: 0.8,
			propClumps: [...prismPropClumps],
			propDensity: 1.0,
			lightChance: 0.5,
			godRaysChance: 0.4,
			lightScaleMin: 14.0,
			lightScaleMax: 24.0,
			lightColors: [
				{ r: 38, g: 37, b: 0, weight: 100 },
				{ r: 85, g: 31, b: 117, weight: 100 },
			],
			pointsOfInterest: prismPOIs,
			safeZones: [],
			minRandomPois: 2,
			maxRandomPois: 2
		},
	],
})

worlds.set(GameModeType.TestRealm, {
	mapChunkSize: 100,
	heightMapScale: 0.3,
	xWater: 0.0,
	yWater: 0.3,
	stampChunkSize: 512,
	stampScaleMin: 1,
	stampScaleMax: 1,
	stampRotationMin: 0,
	stampRotationMax: 0,
	propChunkSize: 512,
	godRayOffsetX: 1000,
	godRayOffsetY: -160,
	biomeFuzziness: 0.2,
	biomeMapScale: 1.0,
	biomeList: [
		{
			identifier: BiomeIdentifier.TOWN,
			name: 'Test Realm Town',
			bgm: 'MUS_HUB_LP',
			enemyDensity: EnemyDensity.NONE,
			enemySpawnPackRadius: EnemySpawnPackRadius.NORMAL,
			enemySpawnOverlapHorizontal: EnemySpawnOverlapHorizontal.NORMAL,
			enemySpawnOverlapVertical: EnemySpawnOverlapVertical.NORMAL,
			destructibleProps: [],
			destructiblePropDensity: DestructiblePropDensity.NONE,
			ambianceEffect: 'town-ambience',
			groundAtlas: 'townGround',
			cliffAtlas: 'beachCliffs',
			waterColor: [91, 198, 187, 255],
			gradientColor: [26, 68, 141],
			folderName: 'town',
			stamps: [...townStamps],
			stampDensity: 1.1,
			propClumps: [],
			propDensity: 0.0,
			lightChance: 0.15,
			godRaysChance: 0.3,
			lightScaleMin: 13.0,
			lightScaleMax: 16.0,
			lightColors: [
				{ r: 38, g: 37, b: 0, weight: 100 },
				{ r: 85, g: 31, b: 117, weight: 100 },
			],
			pointsOfInterest: testRealmPois,
			safeZones: [],
			minRandomPois: 0,
			maxRandomPois: 0
		},
	],
})

// designers, don't worry about the following stuff

worlds.forEach((worldData) => {
	worldData.biomeCount = worldData.biomeList.length
	worldData.levelWorldTier1 = 1
	worldData.levelWorldTier1_5 = 1 + LEVELS_PER_BIOME * Math.ceil(worldData.biomeCount / 2)
	worldData.levelWorldTier2 = 1 + LEVELS_PER_BIOME * worldData.biomeCount

	worldData.biomeList.forEach((biome, index) => {
		biome.enemyMinLevel = getBiomeMinLevel(index)
		biome.enemyMaxLevel = getBiomeMaxLevel(index)
	})
})

export function getWorld(gameModeType: GameModeType) {
	return worlds.get(gameModeType)
}
export function getBiomeList(gameModeType: GameModeType) {
	return worlds.get(gameModeType).biomeList
}
export function getArrayOfAllBiomeIdentifiers() {
	const result: BiomeIdentifier[] = []

	worlds.forEach((worldData) => {
		worldData.biomeList.forEach((biome) => {
			if (!result.includes(biome.identifier)) {
				result.push(biome.identifier)
			}
		})
	})

	return result
}
export function getBiomeCount(gameModeType: GameModeType) {
	return worlds.get(gameModeType).biomeCount
}
export function getLevelWorldTier1(gameModeType: GameModeType) {
	return worlds.get(gameModeType).levelWorldTier1
}
export function getLevelWorldTier1_5(gameModeType: GameModeType) {
	return worlds.get(gameModeType).levelWorldTier1_5
}
export function getLevelWorldTier2(gameModeType: GameModeType) {
	return worlds.get(gameModeType).levelWorldTier2
}
export function getBiomeMinLevel(index: biomeIndex) {
	return index * LEVEL_STEP_BETWEEN_BIOMES + 1
}
export function getBiomeMaxLevel(index: biomeIndex) {
	return index * LEVEL_STEP_BETWEEN_BIOMES + LEVELS_PER_BIOME
}
export function getBiomeRandomLevelOffset(index: biomeIndex) {
	assertBiomeIndex(index, `getBiomeRandomLevelOffset`)
	return Math.getRandomInt(getBiomeMinLevel(index), getBiomeMaxLevel(index))
}
export function getBiomePrettyName(biomeIdx: number, gameModeType: GameModeType) {
	if (gameModeType === GameModeType.Adventure) {
		const biomeCount = getBiomeCount(gameModeType)
		if (biomeIdx === biomeCount) {
			return 'End Island'
		}
		assertBiomeIndex(biomeIdx, `getBiomePrettyName`)
		return worlds.get(gameModeType).biomeList[biomeIdx].name
	}
}

export function assertBiomeIndex(biomeIdx: biomeIndex, message: string) {
	const biomeCount = getBiomeCount(GameModeType.Adventure)
	console.assert(biomeIdx >= 0 && biomeIdx < biomeCount, message + ` biomeIdx:${biomeIdx}`)
}
