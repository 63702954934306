









import { mapGetters, mapMutations } from 'vuex'
import { getIconClassNameFromItem, ImARealitem } from './inventory-item-helpers'
import { highResolutionTimestamp } from '../../../utils/debug'
import { ModCategory } from '../../../engine/shared/game-data/stat-type-mod-category'

export default {
	name: 'GearSkill',
	components: {},
	props: {
		item: {
			type: Object,
			required: true,
			validator(item: ImARealitem) {
				return item.id !== undefined
			},
		},
		gearSkillType: {
			type: Number,
			required: true,
		},
		cooldownStartTime: {
			type: Number,
			default: 0,
			required: false,
		},
		cooldownDuration: {
			type: Number,
			default: 0,
			required: false,
		},
	},
	data() {
		return {
			cooldownElapsed: 0,
			interval: null,
		}
	},
	computed: {
		...mapGetters('UIScale', ['currentScale']),
		itemIconClass() {
			return getIconClassNameFromItem(this.item)
		},
		gearSkillIconClass() {
			switch (this.gearSkillType as ModCategory) {
				case ModCategory.SKILL_DODGE_ROLL:
					return 'long-range-dodge-roll'
				case ModCategory.SKILL_MOVEMENT_SPEED_BOOST:
					return 'speed-boost'
				case ModCategory.SKILL_ATTACK_SPEED_BOOST:
					return 'enrage'
				case ModCategory.SKILL_TUMBLE_ROLL:
					return 'short-range-tumble-roll'
				case ModCategory.SKILL_OVERSHIELD:
					return 'bulwark'
				case ModCategory.SKILL_STONE_FORM:
					return 'stoneform'
				case ModCategory.SKILL_OVERCHARGED_SHOT:
					return 'overcharged-shot'
				case ModCategory.SKILL_BATTLE_CRY:
					return 'battle-cry'
				case ModCategory.SKILL_SICKENING_NOVA:
					return 'sickening-nova'
				case ModCategory.SKILL_GRAVITY_WELL:
					return 'gravity-well'
				case ModCategory.SKILL_BATTERY:
					return 'battery'
				case ModCategory.SKILL_BULWARK:
					return 'defensive-bulwark'
				default:
					console.error(`Invalid gear skill type could not be mapped to a gearSkillIcon: ${this.gearSkillType}`)
					return null
			}
		},
		cooldownRemainingInSeconds() {
			const elapsed = this.cooldownElapsed
			const remaining = Math.ceil((this.cooldownDuration - elapsed) / 1000)
			return remaining
		},
	},
	mounted() {
		if(!this.inverval) {
			this.interval = setInterval(() => {
				const now = highResolutionTimestamp()
				this.cooldownElapsed = now - this.cooldownStartTime
			}, 50)
		}
	},
	beforeDestroy() {
		if(this.interval) {
			clearInterval(this.interval)
			this.interval = null
		}
	},
	methods: {
		...mapMutations('UIScale', ['showTooltip', 'showTooltipDebug', 'updateToolTipPosition', 'currentComponentHovered']),
		onMouseEnter(event, component) {
			if (this.tooltipable === false) return
			this.updateToolTipPosition(event)
			this.showTooltip(this.item)
			this.currentComponentHovered(component)
		},
		onMouseEnterCtrl(event) {
			if (this.tooltipable === false) return
			this.updateToolTipPosition(event)
			this.showTooltipDebug(this.item)
		},
		onMouseLeave() {
			if (this.tooltipable === false) return
			this.currentComponentHovered(null)
			this.showTooltip(null)
		},
		onMouseMove(event) {
			if (this.tooltipable === false) return
			this.moveTooltip(event)
		},
	},
}
