import { EnemyAI, FightingTriggers, IdleBehaviours, FightingBehaviours, LeashTriggers, LeashingBehaviours, AttackTypes, DeadBehaviours, ShotLeadPrecision, EnemyType, EnemyTargetingStyles } from '../../shared/ai-types'
import { ModType, ModValueType } from '../../../projectiles/shared/projectile-types'
import { SpineDataName, EnemyBipedSkinType } from '../../../models-animations/shared/spine-config'
import { degToRad } from '../../../utils/math'
import { EnemyDefaults } from './enemy-defaults'
import { Colors } from '../../../utils/colors'
import { ParticleEffectType } from '../../../engine/shared/game-data/particle-config'
import { Vector } from 'sat'
import { ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { ENEMY_DEFAULT_HEALTH } from '../../../engine/shared/game-data/enemy-formulas'
import { Ability, AbilitySelectionStyles, AbilityTargets, AbilityTargetSelectionStyle, AbilityType } from '../action-types'
import { attackRatedAbilityList } from '../action-criteria/ability-helpers'
import { AnimationTrack } from '../../../models-animations/shared/animation-track'
import { alwaysTrueCriteria } from './abilities/common-abilities'
import { ENEMY_NAMES } from './enemy-names'

const animationTimes = {
	[AnimationTrack.SHOOT]: 0.4,
}

const goblinjesterpin: Ability = {
	debugName: 'goblinjesterpin',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.CLOSEST,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		projectileCount: 3,
		spreadAngle: degToRad(35),
		speed: 400,
		colliderRadius: 30,
		lifespanInSeconds: 1.25,
		modifiers: [{ modType: ModType.TURN, value: { modValueType: ModValueType.VALUE, value: -0.9 } }],
		baseIceDamage: 75,
		burstCount: 0,
		color: Colors.silver,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_GOBLINJESTER,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_ICE_TRAIL,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_ICE_SHOOT,
	},
	attackOffset: new Vector(150, 0),
	ignoreAngleOnWeaponOffset: true,
}

const goblinJester: EnemyAI = {
	name: ENEMY_NAMES.GOBLIN_JESTER,
	type: EnemyType.BASIC,
	appearance: {
		asset: SpineDataName.GOBLIN_JESTER,
		skin: EnemyBipedSkinType.DEFAULT,
		mixSettings: [],
	},
	general: {
		enemyLevel: 1,
		experienceWhenKilled: 1,
	},
	baseAttributes: {
		...EnemyDefaults.baseAttributes,
		physicalDamage: 0,
		movementSpeed: 250,
		attackOffset: new Vector(0, 0),
		maxHealth: ENEMY_DEFAULT_HEALTH * 5.25,
		decelerationRate: 4.5,
		colliders: [
			{
				type: ColliderType.Ellipse,
				rX: 40,
				rY: 55,
				position: [0, -40],
				traits: ColliderTraits.BlockMovementAndProjectile,
			},
		],
		lootDropProbability: 100,
		lootDropQuantity: 3.5,
		lootDropRarity: 3,
	},
	soundEffects: {
		attack: 'Projectile_Blip',
		impact: 'SFX_Impact_Enemy_Generic',
	},
	states: {
		idling: {
			behaviour: IdleBehaviours.STATIONARY,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			transitionToFighting: {
				trigger: FightingTriggers.AGGRESSIVE_PROXIMITY,
				radius: 900,
			},
			aggroOnHit: true,
		},
		fighting: {
			behaviour: FightingBehaviours.CHASE_WHILE_ATTACKING,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 5,
			attackType: AttackTypes.PROJECTILE,
			engagementMaxDistance: 600,
			engagementMinDistance: 0,
			movementMaxDistance: 500,
			movementMinDistance: 0,
			brain: {
				actions: [
					{
						// attack
						priority: 0,
						actionCriteria: alwaysTrueCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							abilityOptions: [attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, goblinjesterpin, 25)],
						},
					},
				],
			},
			attackOptions: [
				{
					...EnemyDefaults.projectileConfig,
					projectileCount: 1,
					speed: 700,
					colliderRadius: 30,
					lifespanInSeconds: 1.4,
					modifiers: [{ modType: ModType.ACCELERATION, value: { modValueType: ModValueType.VALUE, value: -0.9 } }],
					baseIceDamage: 125,
					burstCount: 0,
					color: Colors.silver,
					bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_GOBLINJESTER,
					bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_ICE_TRAIL,
					muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_ICE_SHOOT,
				},
			],
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 0,
			transitionToLeashing: {
				trigger: LeashTriggers.DISTANCE_TRAVELLED_OR_RADIUS_EXCEEDED,
				radius: 2000,
				distanceTravelled: 4000,
			},
		},
		leashing: {
			behaviour: LeashingBehaviours.MOVE,
			speedMultiplier: 4,
		},
		fleeing: {},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 0.2,
		},
	},
}

export default goblinJester
