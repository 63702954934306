const storagePrefix = 'soti_alpha_v1_1:'

export const LAST_LOGGED_IN_CLIENT_KEY = 'last-logged-in-'

export const saveToLocalStorage = (k: string, v: string) => {
	return localStorage.setItem(storagePrefix + k, v)
}
export const loadFromLocalStorage = (k: string) => {
	const valueFoundInLocalStorage = localStorage.getItem(storagePrefix + k)
	return valueFoundInLocalStorage === null ? undefined : valueFoundInLocalStorage
}
export const removeFromLocalStorage = (k: string) => {
	return localStorage.removeItem(storagePrefix + k)
}
