import { EnemyAI, FightingTriggers, IdleBehaviours, FightingBehaviours, LeashTriggers, LeashingBehaviours, AttackTypes, DeadBehaviours, ShotLeadPrecision, EnemyType, EnemyTargetingStyles } from '../../shared/ai-types'
import { ModType, BurstFireModes, ModValueType } from '../../../projectiles/shared/projectile-types'
import { SpineDataName, EnemyBipedSkinType } from '../../../models-animations/shared/spine-config'
import { EnemyDefaults } from './enemy-defaults'
import { Colors } from '../../../utils/colors'
import { ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { ParticleEffectType } from '../../../engine/shared/game-data/particle-config'
import { ENEMY_DEFAULT_HEALTH } from '../../../engine/shared/game-data/enemy-formulas'
import { ENEMY_NAMES } from './enemy-names'
import { Ability, AbilitySelectionStyles, AbilityTargets, AbilityTargetSelectionStyle, AbilityType } from '../action-types'
import { attackRatedAbilityList } from '../action-criteria/ability-helpers'
import { AnimationTrack } from '../../../models-animations/shared/animation-track'
import { alwaysTrueCriteria } from './abilities/common-abilities'
import { deepClone } from '../abilities.test'
import { worldDifficultyBrutalCriteria, worldDifficultyHardCriteria } from '../action-criteria/action-criteria-helpers'

const animationTimes = {
	[AnimationTrack.SHOOT]: 0.6,
}

const wispShotAbility: Ability = {
	debugName: 'wispShotAbility',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		projectileCount: 3,
		colliderRadius: 10,
		spreadAngle: 1.5,
		speed: 680,
		lifespanInSeconds: 2.5,
		modifiers: [
			{ modType: ModType.WAVE, amplitude: { modValueType: ModValueType.VALUE, value: 0.8 }, period: { modValueType: ModValueType.VALUE, value: 1.6 } },
			{ modType: ModType.ACCELERATION, value: { modValueType: ModValueType.RANDOM, min: -0.4, max: 0.0 } },
		],
		baseIceDamage: 20,
		baseLightningDamage: 20,
		burstCount: 3,
		burstMode: BurstFireModes.STRAIGHT,
		burstDelay: 0.6,
		color: Colors.blue,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_WISP,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_LIGHTNING_TRAIL,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_ICE_SHOOT,
	},
}

export const wispShotAbilityT1 = deepClone(wispShotAbility)
wispShotAbilityT1.projectileConfig.modifiers = [
	{ modType: ModType.WAVE, amplitude: { modValueType: ModValueType.VALUE, value: 0.6 }, period: { modValueType: ModValueType.VALUE, value: 2.0 } },
	{ modType: ModType.ACCELERATION, value: { modValueType: ModValueType.RANDOM, min: -0.3, max: 0.0 } },
]

export const wispShotAbilityT2 = deepClone(wispShotAbility)

export const wispShotAbilityT4 = deepClone(wispShotAbility)
wispShotAbilityT4.projectileConfig.projectileCount = 4
wispShotAbilityT4.projectileConfig.burstCount = 4

const wisp: EnemyAI = {
	name: ENEMY_NAMES.WISP,
	type: EnemyType.BASIC,
	appearance: {
		asset: SpineDataName.WISP,
		skin: EnemyBipedSkinType.DEFAULT,
		flying: true,
	},
	general: {
		enemyLevel: 1,
		experienceWhenKilled: 1,
	},
	baseAttributes: {
		...EnemyDefaults.baseAttributes,
		colliders: [
			{
				type: ColliderType.Ellipse,
				rX: 44,
				rY: 70,
				position: [0, -80],
				traits: ColliderTraits.BlockMovementAndProjectile,
			},
			/*{
				type: ColliderType.Circle,
				radius: 40,
				traits: ColliderTraits.BlockMovement,
				position: [0, 0],
			},*/
		],
		decelerationRate: 2.5,
		turningRatePerSecondInDegrees: 360,

		movementSpeed: 300,
		attackRate: 600,

		damageConfig: null,

		physicalDamage: 0,
		fireDamage: 0,
		iceDamage: 0,
		lightningDamage: 0,
		poisonDamage: 0,
		critChance: 0,
		critDamage: 1.0,

		maxHealth: (ENEMY_DEFAULT_HEALTH * 2) / 3,
	},
	soundEffects: {
		attack: 'Projectile_Blip',
		impact: 'SFX_Impact_Enemy_Flesh',
	},
	states: {
		idling: {
			behaviour: IdleBehaviours.MEANDER,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 0.8,
			transitionToFighting: {
				trigger: FightingTriggers.AGGRESSIVE_PROXIMITY,
				radius: 700,
			},
			meander: {
				radius: 500,
				speedMultiplier: 1,
				minRestTime: 0.5,
				maxRestTime: 10,
			},
			aggroOnHit: true,
		},
		fighting: {
			behaviour: FightingBehaviours.STRAFE_AND_ATTACK,
			targetingStyle: EnemyTargetingStyles.LOW_HEALTH,
			targetingCheckSeconds: 1,
			attackType: AttackTypes.PROJECTILE,
			engagementMaxDistance: 900,
			engagementMinDistance: 0,
			modelCenterOffset: 45,
			movementMaxDistance: 600,
			movementMinDistance: 100,
			brain: {
				actions: [
					{
						//  BLOCK/ATTACK
						priority: 0,
						actionCriteria: worldDifficultyBrutalCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							//abilityOptions: [[1, shortRangeAbility, 30]],
							abilityOptions: [attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, wispShotAbilityT4, 15)],
						},
					},
					{
						//  BLOCK/ATTACK
						priority: 1,
						actionCriteria: worldDifficultyHardCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							//abilityOptions: [[1, shortRangeAbility, 30]],
							abilityOptions: [attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, wispShotAbilityT2, 20)],
						},
					},
					{
						//  BLOCK/ATTACK
						priority: 2,
						actionCriteria: alwaysTrueCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							//abilityOptions: [[1, shortRangeAbility, 30]],
							abilityOptions: [attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, wispShotAbilityT1, 25)],
						},
					},
				],
			},
			attackOptions: [
				{
					...EnemyDefaults.projectileConfig,
					projectileCount: 3,
					colliderRadius: 10,
					spreadAngle: 1.5,
					speed: 540,
					lifespanInSeconds: 2.5,
					modifiers: [
						{ modType: ModType.WAVE, amplitude: { modValueType: ModValueType.VALUE, value: 5.5 }, period: { modValueType: ModValueType.VALUE, value: 0.75 } },
						{ modType: ModType.ACCELERATION, value: { modValueType: ModValueType.RANDOM, min: -0.4, max: 0.0 } },
					],
					baseIceDamage: 20,
					baseLightningDamage: 20,
					burstCount: 3,
					burstMode: BurstFireModes.STRAIGHT,
					color: Colors.blue,
					bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_WISP,
					bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_LIGHTNING_TRAIL,
					muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_ICE_SHOOT,
				},
			],
			shotLeadPrecision: ShotLeadPrecision.AVERAGE,
			visualAimLockSeconds: 0.5,
			transitionToLeashing: {
				trigger: LeashTriggers.RADIUS_EXCEEDED,
				radius: 2000,
				distanceTravelled: 4500,
			},
		},
		leashing: {
			behaviour: LeashingBehaviours.MOVE,
			speedMultiplier: 4,
		},
		fleeing: {},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 0.2,
		},
	},
}

export default wisp
