import nengi from 'nengi'

class SetIsTypingCommand {
	static protocol = {
		isTyping: nengi.Boolean,
	}

	isTyping: boolean

	constructor(isTyping) {
		this.isTyping = isTyping
	}
}

export default SetIsTypingCommand
