import nengi from 'nengi'
import { ProjectileConfig } from './projectile-types'

export class ProjectileConfigSlotMessage {
	static protocol = {
		config: { type: nengi.String },
		weaponSlot: { type: nengi.String }
	}

	config: string
	weaponSlot: string

	constructor(config: ProjectileConfig, weaponSlot: string) {
		this.config = JSON.stringify(config)
		this.weaponSlot = weaponSlot
		//console.log(weaponSlot, this.config)
	}
}
