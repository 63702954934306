<template>
	<div id="menu-swapper">
		<div class="menu-btn-container" @click="toggleMenuDropdownVisible()">
			<div class="menu-btn" :class="{ active: menuDropdownVisible }"></div>
		</div>
		<div v-if="this.currentGameMode === 'hub'" class="menu-list-container" :class="{ active: menuDropdownVisible }">
			<div class="menu-list">
				<div
					v-tooltip="{
						content: () => {
							return `<div class='tooltip-title'>Game News</div>`
						},
						placement: 'bottom',
						offset: 0,
						delay: {
							show: 500,
							hide: 300,
						},
					}"
					class="menu-item game-news"
					@click="
						toggleMenuDropdownVisible()
						setActivePanel('gameNews')
					"
				>
					<div class="new-news" :class="{ active: checkIfLatestNewsRead }"></div>
				</div>
				<div
					v-tooltip="{
						content: () => {
							return `<div class='tooltip-title'>[B] Departure</div>`
						},
						placement: 'bottom',
						offset: 0,
						delay: {
							show: 500,
							hide: 300,
						},
					}"
					class="menu-item depart"
					@click="
						toggleMenuDropdownVisible()
						setActivePanel('departure')
					"
				></div>
				<div
					v-if="currentGameMode === 'adventure'"
					v-tooltip="{
						content: () => {
							return `<div class='tooltip-title'>[P] Paper Doll</div>`
						},
						placement: 'bottom',
						offset: 0,
						delay: {
							show: 500,
							hide: 300,
						},
					}"
					class="menu-item paperdoll"
					@click="
						toggleMenuDropdownVisible()
						setActivePanel('paperDoll')
					"
				></div>
				<div
					v-tooltip="{
						content: () => {
							return `<div class='tooltip-title'>Player Profile</div>`
						},
						placement: 'bottom',
						offset: 0,
						delay: {
							show: 500,
							hide: 300,
						},
					}"
					class="menu-item player-profile"
					@click="
						toggleMenuDropdownVisible()
						setActivePanel('playerProfile')
					"
				></div>
				<div
					v-tooltip="{
						content: () => {
							return `<div class='tooltip-title'>[I] Stash</div>`
						},
						placement: 'bottom',
						offset: 0,
						delay: {
							show: 500,
							hide: 300,
						},
					}"
					class="menu-item stash"
					@click="
						toggleMenuDropdownVisible()
						setActivePanel('stash')
					"
				></div>
				<div
					v-tooltip="{
						content: () => {
							return `<div class='tooltip-title'>Leaderboard</div>`
						},
						placement: 'bottom',
						offset: 0,
						delay: {
							show: 500,
							hide: 300,
						},
					}"
					class="menu-item leaderboards"
					@click="
						toggleMenuDropdownVisible()
						setActivePanel('leaderboardUpdated')
					"
				></div>
				<div v-if="currentGameMode === 'adventure'"
					v-tooltip="{
						content: () => {
							return `<div class='tooltip-title'>[G] Party</div>`
						},
						placement: 'bottom',
						offset: 0,
						delay: {
							show: 500,
							hide: 300,
						},
					}"
					class="menu-item party"
					@click="
						toggleMenuDropdownVisible()
						setActivePanel('party')
					"
				></div>

				<div
					v-tooltip="{
						content: () => {
							return `<div class='tooltip-title'>Daily Login Rewards</div>`
						},
						placement: 'bottom',
						offset: 0,
						delay: {
							show: 500,
							hide: 300,
						},
					}"
					class="menu-item daily-rewards"
					@click="dailyRewardsClicked()"
				>
					<div class="new-reward" :class="{ active: !getClaimedToday }"></div>
				</div>
				<div
					v-tooltip="{
						content: () => {
							return `<div class='tooltip-title'>Settings</div>`
						},
						placement: 'bottom',
						offset: 0,
						delay: {
							show: 500,
							hide: 300,
						},
					}"
					class="menu-item settings"
					@click="
						toggleMenuDropdownVisible()
						setActivePanel('gameSettings')
					"
				></div>
			</div>
		</div>
		<div v-else class="menu-list-container" :class="{ active: menuDropdownVisible }">
			<div class="menu-list">
				<div
					v-tooltip="{
						content: () => {
							return `<div class='tooltip-title'>[I] Inventory</div>`
						},
						placement: 'bottom',
						offset: 0,
						delay: {
							show: 500,
							hide: 300,
						},
					}"
					class="menu-item inventory"
					@click="
						toggleMenuDropdownVisible()
						setActivePanel('inventory')
					"
				></div>
				<div
					v-tooltip="{
						content: () => {
							return `<div class='tooltip-title'>[P] Paper Doll</div>`
						},
						placement: 'bottom',
						offset: 0,
						delay: {
							show: 500,
							hide: 300,
						},
					}"
					class="menu-item paperdoll"
					@click="
						toggleMenuDropdownVisible()
						setActivePanel('paperDoll')
					"
				></div>
				<div
					v-tooltip="{
						content: () => {
							return `<div class='tooltip-title'>[G] Party</div>`
						},
						placement: 'bottom',
						offset: 0,
						delay: {
							show: 500,
							hide: 300,
						},
					}"
					class="menu-item party"
					@click="
						toggleMenuDropdownVisible()
						setActivePanel('party')
					"
				></div>

				<div
					v-show="ghostMode"
					v-tooltip="{
						content: () => {
							return `<div class='tooltip-title'>[H] Game Over</div>`
						},
						placement: 'bottom',
						offset: 0,
						delay: {
							show: 500,
							hide: 300,
						},
					}"
					class="menu-item game-over"
					@click="
						toggleMenuDropdownVisible()
						setActivePanel('gameOver')
					"
				></div>

				<div
					v-tooltip="{
						content: () => {
							return `<div class='tooltip-title'>Settings</div>`
						},
						placement: 'bottom',
						offset: 0,
						delay: {
							show: 500,
							hide: 300,
						},
					}"
					class="menu-item settings"
					@click="
						toggleMenuDropdownVisible()
						setActivePanel('gameSettings')
					"
				></div>
			</div>
		</div>
	</div>
</template>

<script lang="js" scoped>
import { mapMutations, mapGetters, mapState } from 'vuex'

export default {
	name: 'MenuSwapper',
	computed: {
		...mapState('user', ['userType']),
		...mapGetters('inGame', ['menuDropdownVisible', 'currentGameMode', 'healthLow', 'ghostMode']),
		...mapGetters('dailyRewards', ['getClaimedToday']),
		...mapGetters('newsArticles', ['getLatestArticleId', 'getLastReadArticleId']),
		checkIfLatestNewsRead() {
			let isRead = false
			if (this.getLastReadArticleId < this.getLatestArticleId) {
				isRead = true
			} else if (this.getLastReadArticleId >= this.getLatestArticleId) {
				isRead = false
			}
			return isRead
		},
		showPremiumStore() {
			return process.env.NODE_ENV !== 'beta' 
		}
	},
	methods: {
		...mapMutations('inGame', ['toggleMenuDropdownVisible', 'setActivePanel', 'toggleSound']),
		dailyRewardsClicked() {
			this.toggleMenuDropdownVisible()

			if(this.userType === 'registered') {
				this.setActivePanel('dailyRewards')
			} else {
				this.setActivePanel('notLoggedIn')
			}
		}
	},
}
</script>

<style lang="less" scoped>
@import url('../global/fonts/text.less');

#menu-swapper {
	position: absolute;
	top: 0px;
	right: 0px;
	transform-origin: top right !important;
	.menu-btn-container {
		background-color: rgba(0, 0, 0, 0.7);
		padding: 18px 25px;
		position: relative;
		top: 0px;
		right: 0px;
		pointer-events: all;
		z-index: 5;
		.menu-btn {
			background-image: url('../hud/assets/hub-menu-open.png');
			width: 24px;
			height: 22px;
			background-size: contain;
			&.active {
				background-image: url('../hud/assets/hub-menu-close.png');
			}
			&:hover {
				filter: drop-shadow(0 0 4px #e6e6e6);
			}
		}
	}
	.menu-list-container {
		height: 90vh;
		width: 74px;
		overflow-y: auto;
		pointer-events: all;
		position: absolute;
		right: -300px;
		transition: right 150ms ease;
		&.active {
			right: 0px;
		}
		.menu-list {
			background-color: rgba(0, 0, 0, 0.7);
			display: flex;
			flex-direction: column;
			align-items: center;
			position: relative;
			pointer-events: all;
			padding: 8px 8px;
			overflow-x: hidden;
			.menu-item {
				width: 49px;
				height: 52px;
				background-size: contain;
				pointer-events: all;
				margin: 5px 0px;
				&.depart {
					background-image: url('./assets/menu-icons/menu-icon-depart.png');

					&:hover {
						background-image: url('./assets/menu-icons/menu-icon-depart-hover.png');
					}
					&:hover .hidden {
						display: block;
					}
				}
				&.stash {
					background-image: url('./assets/menu-icons/menu-icon-stash.png');

					&:hover {
						background-image: url('./assets/menu-icons/menu-icon-stash-hover.png');
					}
					&:hover .hidden {
						display: block;
					}
				}
				&.worm-mail {
					background-image: url('./assets/menu-icons/menu-icon-wormmail.png');

					&:hover {
						background-image: url('./assets/menu-icons/menu-icon-wormmail-hover.png');
					}
					&:hover .hidden {
						display: block;
					}
				}
				&.identify {
					background-image: url('./assets/menu-icons/menu-icon-identify.png');

					&:hover {
						background-image: url('./assets/menu-icons/menu-icon-identify-hover.png');
					}
					&:hover .hidden {
						display: block;
					}
				}
				&.furnace {
					background-image: url('./assets/menu-icons/menu-icon-furnace.png');

					&:hover {
						background-image: url('./assets/menu-icons/menu-icon-furnace-hover.png');
					}
					&:hover .hidden {
						display: block;
					}
				}
				&.shop {
					background-image: url('./assets/menu-icons/menu-icon-shop.png');

					&:hover {
						background-image: url('./assets/menu-icons/menu-icon-shop-hover.png');
					}
					&:hover .hidden {
						display: block;
					}
				}
				&.pit-of-chances {
					background-image: url('./assets/menu-icons/menu-icon-pitofchances.png');

					&:hover {
						background-image: url('./assets/menu-icons/menu-icon-pitofchances-hover.png');
					}
					&:hover .hidden {
						display: block;
					}
				}
				&.marketplace {
					background-image: url('./assets/menu-icons/menu-icon-marketplace.png');

					&:hover {
						background-image: url('./assets/menu-icons/menu-icon-marketplace-hover.png');
					}
					&:hover .hidden {
						display: block;
					}
				}
				&.player-profile {
					background-image: url('./assets/menu-icons/menu-icon-playerprofile.png');

					&:hover {
						background-image: url('./assets/menu-icons/menu-icon-playerprofile-hover.png');
					}
					&:hover .hidden {
						display: block;
					}
				}
				&.leaderboards {
					background-image: url('./assets/menu-icons/menu-icon-leaderboards.png');

					&:hover {
						background-image: url('./assets/menu-icons/menu-icon-leaderboards-hover.png');
					}
					&:hover .hidden {
						display: block;
					}
				}
				&.mtx {
					background-image: url('./assets/menu-icons/menu-icon-mtx.png');

					&:hover {
						background-image: url('./assets/menu-icons/menu-icon-mtx-hover.png');
					}
					&:hover .hidden {
						display: block;
					}
				}
				&.daily-rewards {
					position: relative;
					background-image: url('./assets/menu-icons/menu-icon-dailyreward.png');

					&:hover {
						background-image: url('./assets/menu-icons/menu-icon-dailyreward-hover.png');
					}
					&:hover .hidden {
						display: block;
					}
					.new-reward {
						background-image: url('../hud/assets/notification-badge.png');
						width: 47px;
						height: 47px;
						position: absolute;
						top: -14px;
						transform-origin: center;
						left: 23px;
						display: none;
						pointer-events: none;
						background-size: cover;
						&.active {
							display: block;
						}
					}
				}
				&.settings {
					background-image: url('./assets/menu-icons/menu-icon-settings.png');

					&:hover {
						background-image: url('./assets/menu-icons/menu-icon-settings-hover.png');
					}
					&:hover .hidden {
						display: block;
					}
				}
				&.party {
					background-image: url('./assets/menu-icons/menu-icon-party.png');

					&:hover {
						background-image: url('./assets/menu-icons/menu-icon-party-hover.png');
					}
					&:hover .hidden {
						display: block;
					}
				}
				&.paperdoll {
					background-image: url('./assets/menu-icons/menu-icon-paperdoll.png');

					&:hover {
						background-image: url('./assets/menu-icons/menu-icon-paperdoll-hover.png');
					}
					&:hover .hidden {
						display: block;
					}
				}
				&.customize-player {
					background-image: url('./assets/menu-icons/menu-icon-customize.png');

					&:hover {
						background-image: url('./assets/menu-icons/menu-icon-customize-hover.png');
					}
					&:hover .hidden {
						display: block;
					}
				}
				&.game-over {
					background-image: url('./assets/menu-icons/menu-icon-force-death.png');

					&:hover {
						background-image: url('./assets/menu-icons/menu-icon-force-death-hover.png');
					}

					&:hover .hidden {
						display: block;
					}
				}
				&.game-news {
					position: relative;
					background-image: url('./assets/menu-icons/menu-icon-gamenews.png');

					&:hover {
						background-image: url('./assets/menu-icons/menu-icon-gamenews-hover.png');
					}
					&:hover .hidden {
						display: block;
					}

					.new-news {
						background-image: url('../hud/assets/notification-badge.png');
						width: 47px;
						height: 47px;
						position: absolute;
						top: -14px;
						transform-origin: center;
						left: 23px;
						display: none;
						pointer-events: none;
						background-size: cover;
						&.active {
							display: block;
						}
					}
				}
				&.inventory {
					background-image: url('./assets/menu-icons/menu-icon-inventory.png');

					&:hover {
						background-image: url('./assets/menu-icons/menu-icon-inventory-hover.png');
					}
					&:hover .hidden {
						display: block;
					}
				}
				.menu-item-label {
					height: 48px;
					background-image: linear-gradient(to left, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.05));
					min-width: 320px;
					position: relative;
					right: 314px;
					text-align: right;
					padding-right: 25px;
					top: 50%;
					transform: translate(0, -50%);
					.item-name();
					&.hidden {
						display: none;
					}
				}
			}
		}
	}
}
</style>
