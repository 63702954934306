import { costToOpenAugmentSlot } from '../../utils/economy-data'

export function getAugmentLimitBasedOnItem(item) {
	if (item.level > 90) {
		return 6
	} else if (item.level > 60) {
		return  5
	} else if (item.level > 30) {
		return 4
	} else if (item.level > 15) {
		return 3
	}
	return 0
}

export function getAugmentUnlockCost(item) {
	const augmentLimit = getAugmentLimitBasedOnItem(item)

	if (item.augmentLimit <= augmentLimit) {
		const augmentUnlockCost = []

		for (let limit = item.augmentLimit; limit < augmentLimit; limit++) {

			const playerWeapon = item
			playerWeapon['augmentLimit'] = limit

			const coinCost = costToOpenAugmentSlot(playerWeapon)
			const rarityShardCost = rarityShardCostToOpenAugmentSlot(item)

			augmentUnlockCost.push({
				coinCost: coinCost,
				rarityShardCost: rarityShardCost,
				augmentSlot: limit,
			})
		}

		return augmentUnlockCost
	}
	return null
}

export function getSingleAugmentUnlockCost(item) {
	const coinCost = costToOpenAugmentSlot(item)
	const rarityShardCost = rarityShardCostToOpenAugmentSlot(item)

	return { coinCost: coinCost, rarityShardCost: rarityShardCost }
}

function rarityShardCostToOpenAugmentSlot(item) {
	const currentItemAugmentLimit = item.augmentLimit
	
	switch (currentItemAugmentLimit) {
		case 1:
			return 1
		case 2:
			return 3
		case 3:
			return 5
		case 4:
			return 8
		case 5:
			return 20
	}
}

export function canUnlockAugmentSlots(item){
	const maxSlots = getAugmentLimitBasedOnItem(item)
	if(item.augmentLimit < maxSlots && item.level > 15){
		return true
	}
	return false
}
