

















































































import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import EmptyInventorySlot from '../inventory-item/empty-inventory-slot.vue'
import InventoryItem from '../inventory-item/inventory-item.vue'
import TextButton from '../buttons/text-button.vue'
import { WaitCondition } from '../../../ui/state/WaitCondition'

export default {
	name: 'PaperdollQuickViewRow',
	components: {
		InventoryItem,
		EmptyInventorySlot,
		TextButton,
	},
	props: {
		items: {
			type: Array,
			required: true,
			default: () => [],
		},
		selectable: {
			type: Boolean,
			required: false,
			default: true,
		},
		selectableEmpties: {
			type: Boolean,
			required: false,
			default: true,
		},
		selectableGear: {
			type: Boolean,
			required: false,
			default: true,
		},
		showUnequipButtons: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	computed: {
		...mapGetters('itemContainers', ['equippedGear']),
		...mapGetters('miniPaperdoll', ['selectedMiniPaperdollIndex']),
		...mapGetters('inGame', ['activePanel']),
		...mapGetters('ftueManager', ['getFlag']),
		...mapState('stash', ['ftueMode']),
	},
	methods: {
		...mapMutations('inGame', ['setActivePanel']),
		...mapMutations('tutTooltip', ['postWaitForCondition']),
		...mapActions('miniPaperdoll', ['selectMiniPaperdollIndex', 'unequipItemFromSlot']),
		...mapActions('augmentationStation', ['selectedWeaponToAugment']),
		selectIndex(index: number) {
			if (index >= 2 && this.selectableGear === false) return
			if (this.getFlag('openedWormMailNPC') && !this.getFlag('openedStashNPC')) {
				if (index != 1) {
					return
				} else if (this.selectedMiniPaperdollIndex === 1) {
					return
				}
			}

			this.selectMiniPaperdollIndex(index)
		},
		onUnequip(itemId: string) {
			this.unequipItemFromSlot(itemId)
		},
		selectedItemToAugment(itemId) {
			this.selectedWeaponToAugment(itemId)
		},
	},
}
