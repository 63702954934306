import englishStrings from '../i18n/english.json'
import portugueseStrings from '../i18n/portuguese.json'
import spanishStrings from '../i18n/spanish.json'
import frenchStrings from '../i18n/french.json'
import russianStrings from '../i18n/russian.json'
import chineseStrings from '../i18n/chinese.json'
import koreanStrings from '../i18n/korean.json'

const strings = {
	en: englishStrings,
	pt: portugueseStrings,
	es: spanishStrings,
	fr: frenchStrings,
	ru: russianStrings,
	zh: chineseStrings,
	ko: koreanStrings,
}

export const t = (currentLocale, stringCode) => {
	const localeStrings = strings[currentLocale]

	if (localeStrings === undefined) {
		return `UNKNOWN LOCALE ${currentLocale}`
	}

	const translatedString = localeStrings[stringCode]

	if (translatedString === undefined) {
		return `MISSING TRANSLATION FOR KEY: ${stringCode}`
	}

	return translatedString
}
