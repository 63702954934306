enum MtxSubTypes {
	SPELLSWORD = 'spellsword',
	SCYTHE = 'scythe',
	STAFF = 'staff',
	WAND = 'wand',
	ARCANE_FOCUS = 'arcane-focus',
	CROSSBOW = 'crossbow',
	BIOME_KEY = 'biome-key',
	PLAYER_SKIN = 'player-skin',
	PLAYER_BACK = 'player-back',
	PLAYER_FACE = 'player-face',
	PLAYER_TRAIL = 'player-trail',
	PLAYER_AURA = 'player-aura',
	PLAYER_ACCOUNT = 'player-account',
	BUNDLE = 'bundle',
	PLAYER_EMOTE = 'player-emote',
	PET_SKIN = 'pet-skin',
}

const MtxSubTypeCategoryPrettyName = new Map<MtxSubTypes, string>()
MtxSubTypeCategoryPrettyName.set(MtxSubTypes.SPELLSWORD, 'Spellswords')
MtxSubTypeCategoryPrettyName.set(MtxSubTypes.SCYTHE, 'Scythes')
MtxSubTypeCategoryPrettyName.set(MtxSubTypes.STAFF, 'Staffs')
MtxSubTypeCategoryPrettyName.set(MtxSubTypes.WAND, 'Wands')
MtxSubTypeCategoryPrettyName.set(MtxSubTypes.ARCANE_FOCUS, 'Arcane Focus')
MtxSubTypeCategoryPrettyName.set(MtxSubTypes.CROSSBOW, 'Crossbows')
MtxSubTypeCategoryPrettyName.set(MtxSubTypes.BIOME_KEY, 'Biome Keys')
MtxSubTypeCategoryPrettyName.set(MtxSubTypes.PLAYER_SKIN, 'Player Skins')
MtxSubTypeCategoryPrettyName.set(MtxSubTypes.PLAYER_BACK, 'Back Cosmetics')
MtxSubTypeCategoryPrettyName.set(MtxSubTypes.PLAYER_FACE, 'Face Cosmetics')
MtxSubTypeCategoryPrettyName.set(MtxSubTypes.PLAYER_TRAIL, 'Particle Trails')
MtxSubTypeCategoryPrettyName.set(MtxSubTypes.PLAYER_AURA, 'Auras')
MtxSubTypeCategoryPrettyName.set(MtxSubTypes.PLAYER_ACCOUNT, 'Player Account')
MtxSubTypeCategoryPrettyName.set(MtxSubTypes.BUNDLE, 'Bundles')
MtxSubTypeCategoryPrettyName.set(MtxSubTypes.PLAYER_EMOTE, 'Emotes')
MtxSubTypeCategoryPrettyName.set(MtxSubTypes.PET_SKIN, 'Pets')

export { MtxSubTypes, MtxSubTypeCategoryPrettyName }