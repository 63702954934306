import logger from '../../utils/client-logger'
import PlayerStatsMessage from '../../player/shared/player-stats-message'
import DetachAugmentFromWeaponCommand from '../../items/shared/detach-augment-from-weapon-command'
import { NengiClient } from '../../engine/client/nengi-client'
import UnequipGearFromSlotCommand from '../../items/shared/unequip-gear-from-slot-command'

export const paperdollModule = () => {
	logger.debug('Initializing Paperdoll store module...')
	return {
		namespaced: true,
		state: {
			playerStats: {
				// Set through playerStatsUpdated
				movementSpeed: 0,
				cooldownSpeed: 0,
				buffDuration: 0,
				debuffPotency: 0,

				physicalResist: 0,
				fireResist: 0,
				lightningResist: 0,
				iceResist: 0,
				poisonResist: 0,

				shieldRegenDelay: 0,
				shieldRegenRate: 0,

				// set some other way
				maxHealth: 0,
				currentHealth: 0,
			},
			paperdollSlotData: [],
		},
		getters: {
			statsText(state) {
				return JSON.stringify(state.playerStats)
			},
			playerStats(state) {
				return state.playerStats
			},
		},
		actions: {
			detachAugmentFromWeapon({ commit }, { augmentId, weaponId }) {
				NengiClient.getInstance().sendCommand(new DetachAugmentFromWeaponCommand(augmentId, weaponId))
			},
			unequipGearInSlot({ commit }, itemData) {
				NengiClient.getInstance().sendCommand(new UnequipGearFromSlotCommand(itemData.id))
			},
		},
		mutations: {
			paperdollUpdated(state, updatedPaperdollItemList) {
				//console.log('PAPERDOLL::', updatedPaperdollItemList)
				state.paperdollSlotData = [...updatedPaperdollItemList]
			},
			playerStatsUpdated(state, newMessage: PlayerStatsMessage) {
				for (const s in state.playerStats) {
					if (s in newMessage) {
						state.playerStats[s] = newMessage[s]
					}
				}
			},
			maxHealthUpdated(state, newMaxHealth) {
				state.playerStats.maxHealth = newMaxHealth
			},
			currentHealthUpdated(state, newCurrentHealth) {
				state.playerStats.currentHealth = newCurrentHealth
			},
		},
	}
}
