import { ClientEnemy } from "../../ai/client/enemy.client";
import { nengiId } from "../../utils/primitive-types";
import { ClientColliderEntity } from "../../world/client/collider-entity.client";
import { ClientTerrainProp } from "../../world/client/terrain-prop.client";
import { AnyCollider } from "../shared/colliders";

export interface ICollidableEntity {
	nid: nengiId
	x: number
	y: number
	colliders: AnyCollider[]
}
const clientPropColliderEntities: ICollidableEntity[] = []
const clientEnemyColliderEntities: ICollidableEntity[] = []
const clientColliderEntityColliderEntities: ICollidableEntity[] = []

export function addClientCollidable(collidable: ICollidableEntity) {
	getList(collidable)?.push(collidable)
}

export function removeClientCollidable(collidable: ICollidableEntity) {
	getList(collidable)?.remove(collidable)
}

function getList(collidable: ICollidableEntity) {
	if (collidable instanceof ClientTerrainProp) {
		return clientPropColliderEntities
	}
	if (collidable instanceof ClientEnemy) {
		return clientEnemyColliderEntities
	}
	if (collidable instanceof ClientColliderEntity) {
		return clientColliderEntityColliderEntities
	}
}

export function removeAllClientCollidables() {
	clientPropColliderEntities.length = 0
	clientEnemyColliderEntities.length = 0
	clientColliderEntityColliderEntities.length = 0
}

export function getClientPropCollidables() {
	return clientPropColliderEntities
}
export function getClientEnemyCollidables() {
	return clientEnemyColliderEntities
}
export function getClientColliderEntityCollidables() {
	return clientColliderEntityColliderEntities
}

/** warning: only prints circle and box colliders */
// export function clientColliderDebug() {
// 	getClientColliderEntityCollidables().forEach(prop => {
// 		// todo3: client side debugDrawShape
// 		prop.colliders.forEach((c) => {
// 			if (c instanceof CircleCollider) {
// 				clientDebug.drawCircle(c.pos, c.r, 0x000000, false, 0)
// 			}
// 			if (c instanceof BoxCollider) {
// 				debugDrawBox2(c, 0x000000)
// 				clientDebug.drawRectangle(c.pos.x, c.pos.y, c.w, c.h, 0x000000, false, 0)
// 			}
// 		})
// 	})

// 	// todo: move this/cleanup
// 	// this doesn't seem to be handling angles correctly for some reason
// 	function debugDrawBox2(box: BoxCollider, color: number, permanent = false, destroyAfterSeconds = 0.1, scale = 1) {
// 		const polygon = new Polygon()
// 		setPolygonFromBox(box, polygon, [new Vector(), new Vector(), new Vector(), new Vector()])
// 		clientDebug.drawPolygon(polygon, color, permanent, destroyAfterSeconds, scale)
// 	}
// }
