import nengi from 'nengi'

class PartyInvitationCommand {
	static protocol = {
		partyId: nengi.String,
	}

	partyId: string

	constructor(partyId) {
		this.partyId = partyId
	}
}

export default PartyInvitationCommand
