import { ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { ParticleEffectType } from '../../../engine/shared/game-data/particle-config'
import { EnemyBipedSkinType, SpineDataName } from '../../../models-animations/shared/spine-config'
import { ModType } from '../../../projectiles/shared/projectile-types'
import { Colors } from '../../../utils/colors'
import { degToRad } from '../../../utils/math'
import { AttackTypes, DeadBehaviours, EnemyAI, EnemyTargetingStyles, EnemyType, FightingBehaviours, FightingTriggers, IdleBehaviours, LeashingBehaviours, LeashTriggers, ShotLeadPrecision } from '../../shared/ai-types'
import { EnemyDefaults } from './enemy-defaults'
import { ENEMY_NAMES } from './enemy-names'

const basic: EnemyAI = {
	name: ENEMY_NAMES.BASIC,
	type: EnemyType.BASIC,
	appearance: {
		asset: SpineDataName.BIPED_ENEMY,
		skin: EnemyBipedSkinType.SKELEMAGE,
	},
	general: {
		enemyLevel: 1,
		experienceWhenKilled: 1,
	},
	baseAttributes: {
		...EnemyDefaults.baseAttributes,
		colliders: [
			{
				type: ColliderType.Circle,
				radius: 40,
				position: [0, -40],
				traits: ColliderTraits.BlockMovementAndProjectile,
			},
			{
				type: ColliderType.Circle,
				radius: 40,
				position: [0, -80],
				traits: ColliderTraits.BlockProjectile,
			},
		],
	},
	soundEffects: {
		attack: 'Projectile_Blip',
		impact: 'SFX_Impact_Enemy_Generic',
	},
	states: {
		idling: {
			behaviour: IdleBehaviours.STATIONARY,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			transitionToFighting: {
				trigger: FightingTriggers.AGGRESSIVE_PROXIMITY,
				radius: 700,
			},
			aggroOnHit: true,
		},
		fighting: {
			behaviour: FightingBehaviours.CHASE_AND_ATTACK,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 5,
			attackType: AttackTypes.PROJECTILE,
			engagementMaxDistance: 500,
			engagementMinDistance: 0,
			movementMaxDistance: 500,
			movementMinDistance: 0,
			attackOptions: [
				{
					...EnemyDefaults.projectileConfig,
					projectileCount: 8,
					spreadAngle: degToRad(360),
					speed: 350,
					colliderRadius: 15,
					lifespanInSeconds: 5,
					modifiers: [{ modType: ModType.STRAIGHT }],
					burstCount: 0,
					color: Colors.purple,
					bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_SKELEMAGE,
					bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_TRAIL_SKELEMAGE,
					muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
				},
			],
			shotLeadPrecision: ShotLeadPrecision.PERFECT,
			visualAimLockSeconds: 0.5,
			transitionToLeashing: {
				trigger: LeashTriggers.DISTANCE_TRAVELLED_OR_RADIUS_EXCEEDED,
				radius: 2000,
				distanceTravelled: 4000,
			},
		},
		leashing: {
			behaviour: LeashingBehaviours.MOVE,
			speedMultiplier: 4,
		},
		fleeing: {},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 0.2,
		},
	},
}

export default basic
