import { Container } from 'pixi.js'
import { EmoteType, NPCEmoteSkin, NPCEmoteType } from '../../chat/shared/emote-enums'
import playAnimation from '../../models-animations/client/play-animation'
import { RiggedSpineModel } from '../../models-animations/client/spine-model'
import { AnimationTrack } from '../../models-animations/shared/animation-track'
import { SpineDataName } from '../../models-animations/shared/spine-config'
import { ObjectPool, PoolableObject } from '../../third-party/object-pool'


const EMOTE_TO_ANIMATION_TRACK = {
	[EmoteType.None]: AnimationTrack.EMOTE_NONE,
	[EmoteType.Typing]: AnimationTrack.EMOTE_TYPING,
}

const SCALE_IN_TIME = 0.3
const MAX_EMOTE_SCALE = 0.5

export class NPCClientEmote implements PoolableObject {
	static pool: ObjectPool

	parentContainer: Container

	private _emoteContainer: Container
	private _riggedEmote: RiggedSpineModel

	private _isScalingIn: Boolean = false
	private _scaleInTime: number = 0

	constructor(defaultValues?: any, overrideValues?: any) {
		this._emoteContainer = new Container()
		this._emoteContainer.name = 'npc-emote'

		this._riggedEmote = RiggedSpineModel.allocFromPool(SpineDataName.PET_EMOTE, 'smiley-face')

		this._riggedEmote.scale.x = 0
		this._riggedEmote.scale.y = 0

		this._emoteContainer.addChild(this._riggedEmote)

		this._emoteContainer.visible = false
	}

	setDefaultValues(defaultValues: any, overrideValues?: any) {
		this._riggedEmote.skeleton.setToSetupPose()
		this._emoteContainer.visible = false

		this._riggedEmote.scale.x = 0
		this._riggedEmote.scale.y = 0
		this._scaleInTime = 0
	}

	cleanup() {}

	setEmote(emote: NPCEmoteType, emoteSkin: NPCEmoteSkin) {
		this._riggedEmote.skeleton.setSkinByName(emoteSkin)
		playAnimation(this._riggedEmote, EMOTE_TO_ANIMATION_TRACK[emote])
		this._isScalingIn = true
	}

	getRandomEmoteSkin(): NPCEmoteSkin {
		return Math.random() >= 0.5 ? NPCEmoteSkin.SmileyFace : NPCEmoteSkin.Heart
	}

	getContainer(): Container {
		return this._emoteContainer
	}

	setParentContainer(container: Container) {
		this.parentContainer = container
		container.addChild(this._emoteContainer)
	}

	update(delta: number) {
		this._riggedEmote.update(delta)

		if(this._isScalingIn) {
			this._scaleInTime += delta
			const scale = Math.lerp(0, MAX_EMOTE_SCALE, Math.clamp((this._scaleInTime/SCALE_IN_TIME), 0, 1))
			this._riggedEmote.scale.x = scale
			this._riggedEmote.scale.y = scale

			if(this._scaleInTime > SCALE_IN_TIME) {
				this._scaleInTime = 0
				this._isScalingIn = false
			}
		}
	}

	returnToPool() {
		this.parentContainer.removeChild(this._emoteContainer)
		NPCClientEmote.pool.free(this)
	}
}