import { deepClone } from '../../ai/shared/abilities.test'
import { AssetManager } from '../../asset-manager/client/asset-manager'
import { DamageNumberStyle } from '../../combat/shared/damage.shared'
import DevToolsManager from '../../ui/dev-tools/dev-tools-manager'
import { callbacks_addCallback } from '../../utils/callback-system'
import { Colors, ColorUtil } from '../../utils/colors'
import { VectorXY } from '../../utils/math'
import { gameUnits } from '../../utils/primitive-types'
import { AnimatedNumber, simpleAnimation_addAnimation, simpleAnimation_removeAnimations } from '../../utils/simple-animation-system'
import { linear10_2s } from '../shared/game-data/buffs/easing-functions'
import { EffectConfig } from './graphics/pfx/effectConfig'
import Renderer from './graphics/renderer'

interface DamageNumberConfig {
	damageColor: number
	offset: VectorXY
	scale: AnimatedNumber
	velocity: VectorXY
	randX?: gameUnits[]
	anim?: VectorXY[]
	prepend?: string
	append?: string
	font?: PIXI.TextStyle | any
}

const playerDamageAnim = JSON.parse(
	'[{"x":2,"y":0},{"x":4,"y":1},{"x":3,"y":1},{"x":3,"y":1},{"x":1,"y":0},{"x":0,"y":2},{"x":-2,"y":3},{"x":-4,"y":2},{"x":-4,"y":2},{"x":-4,"y":1},{"x":-3,"y":2},{"x":-2,"y":2},{"x":-2,"y":1},{"x":-1,"y":1},{"x":0,"y":1},{"x":1,"y":2},{"x":7,"y":4},{"x":7,"y":3},{"x":3,"y":1},{"x":-4,"y":3},{"x":-6,"y":5},{"x":-5,"y":3},{"x":-2,"y":3},{"x":-1,"y":1},{"x":1,"y":0},{"x":1,"y":1},{"x":-2,"y":3},{"x":-4,"y":4},{"x":-1,"y":1},{"x":0,"y":1},{"x":1,"y":0},{"x":1,"y":0}]',
)

const playerDamageConfig: DamageNumberConfig = {
	damageColor: 0xe92525,
	offset: { x: 0, y: 0 },
	velocity: { x: 0, y: 100 },
	anim: playerDamageAnim,
	scale: linear10_2s,
}

const enemyDamageConfig: DamageNumberConfig = {
	damageColor: 0xffdd3e,
	offset: { x: 0, y: -100 },
	velocity: { x: 0, y: -100 },
	randX: [-150, 150],
	scale: linear10_2s,
}

const enemyCriticalDamageConfig: DamageNumberConfig = {
	damageColor: 0xff6600,
	offset: { x: 0, y: -100 },
	velocity: { x: 0, y: -100 },
	randX: [-150, 150],
	scale: linear10_2s,
}

const criticalDamageConfig: DamageNumberConfig = {
	damageColor: Colors.orange,
	offset: { x: 0, y: -100 },
	velocity: { x: 0, y: -100 },
	randX: [-150, 150],
	scale: linear10_2s,
	font: {
		fontFamily: 'Yanone Kaffeesatz',
		fontSize: 52,
		fontWeight: '900',
		letterSpacing: 1,
		align: 'center',
		fill: Colors.orange,
		dropShadow: true,
		dropShadowAngle: 2,
		dropShadowColor: '#2a313e',
		dropShadowDistance: 4,
		lineJoin: 'bevel',
		miterLimit: 5,
		padding: 8,
		stroke: '#2a313e',
		strokeThickness: 2,
	},
}

const healDamageConfig: DamageNumberConfig = {
	damageColor: 0x74d836,
	offset: { x: -100, y: -50 },
	velocity: { x: 0, y: -80 },
	randX: [-100, 100],
	scale: (t) => linear10_2s(t) * 1.2,
	prepend: '+',
	font: {
		fontFamily: 'Yanone Kaffeesatz',
		fontSize: 52,
		fontWeight: '900',
		letterSpacing: 1,
		align: 'center',
		fill: 0x74d836,
		dropShadow: true,
		dropShadowAngle: 2,
		dropShadowColor: '#2a313e',
		dropShadowDistance: 4,
		lineJoin: 'bevel',
		miterLimit: 5,
		padding: 8,
		stroke: '#2a313e',
		strokeThickness: 2,
	},
}

const shieldDamageConfig: DamageNumberConfig = {
	damageColor: 0xcfcfff,
	offset: { x: 0, y: -100 },
	velocity: { x: 0, y: -50 },
	scale: (t) => 1.2,
	prepend: '-',
	append: 's',
}

const damageNumbersConfig = {
	style: DamageNumberStyle.Player,
	fire,
	s: '------------------------------',
	asset: 'damage-numbers',
	damage: 9999,
	duration: 0.95,
	animSpeed: 40,
	critical: criticalDamageConfig,
	player: playerDamageConfig,
	enemy: enemyDamageConfig,
	heal: healDamageConfig,
	charWidths: {
		['0']: 28,
		['1']: 20,
		['2']: 25,
		['3']: 25,
		['4']: 25,
		['5']: 25,
		['6']: 25,
		['7']: 25,
		['8']: 25,
		['9']: 25,
		['!']: 20,
		['.']: 15,
		['m']: 30,
		['s']: 50,
	},
}
const charWidths = damageNumbersConfig.charWidths
function charWidth(c) {
	return charWidths[c] || 25
}

const charToAtlasIdx = {
	['.']: 'period',
	['s']: 'shield-crystal',
}

const damageStyleConfigMap = {
	[DamageNumberStyle.Enemy]: enemyDamageConfig,
	[DamageNumberStyle.Player]: playerDamageConfig,
	[DamageNumberStyle.Heal]: healDamageConfig,
	[DamageNumberStyle.Shield]: shieldDamageConfig,
	[DamageNumberStyle.Critical]: criticalDamageConfig,
}

//-------------------------------------------------------------

const numberEffectConfigs = {}

function fire() {
	const renderer = Renderer.getInstance()
	const pos = renderer.getCameraCenterWorldPos()
	const config = damageStyleConfigMap[damageNumbersConfig.style as any]
	spawnDamageNumber2(damageNumbersConfig.damage, pos.x, pos.y, config)
}

function init() {
	const pfxAsset = AssetManager.getInstance().getAssetByName(damageNumbersConfig.asset).data as EffectConfig

	// do an effect config for all 10 digits
	for (let index = 0; index < 10; index++) {
		const config = deepClone(pfxAsset)
		config.emitters.forEach((e) => (e.frames = [index.toString()]))
		numberEffectConfigs[index.toString()] = config
	}

	const specialChars = ['!', '.', 'k', 'm', '+', '-', 's']

	// do an effect for each special character
	specialChars.forEach((c) => {
		const config = deepClone(pfxAsset)
		const frameChar = charToAtlasIdx[c] ?? c
		config.emitters.forEach((e) => (e.frames = [frameChar]))
		numberEffectConfigs[c] = config
	})

	const configs = [enemyDamageConfig, playerDamageConfig, healDamageConfig, shieldDamageConfig]
	configs.forEach((c) => {
		if (!c.prepend) {
			c.prepend = ''
		}
		if (!c.append) {
			c.append = ''
		}
	})
}
function checkAndInit() {
	if (!numberEffectConfigs['0']) {
		init()
	}
}

function stringWidth(damageString: string) {
	const width = Array.from(damageString)
		.map(charWidth)
		.reduce((a, b) => a + b, 0)
	return width
}

export function spawnDamageNumber(damage: number, x: gameUnits, y: gameUnits, style: DamageNumberStyle) {
	const config: DamageNumberConfig = damageStyleConfigMap[style]
	damage = Math.ceil(damage)
	spawnDamageNumber2(damage, x, y, config)
}

export function spawnFloatingText(text: string, x: gameUnits, y: gameUnits, style: DamageNumberStyle) {
	const config: DamageNumberConfig = damageStyleConfigMap[style]
	checkAndInit()
	if (!text || text === '') {
		return
	}
	spawnWord(text, x, y, config)
}

function spawnDamageNumber2(damage: number, x: gameUnits, y: gameUnits, config: DamageNumberConfig) {
	checkAndInit()

	if (damage <= 0) {
		return
	}

	const renderer = Renderer.getInstance()
	const damageString = config.prepend + damageNumberToString(damage) + config.append

	x += config.offset.x
	y += config.offset.y

	if (config === criticalDamageConfig) {
		spawnWord('CRITICAL', x, y, config)
		return
	} else if(config === healDamageConfig) {
		spawnWord('+ HEALING', x, y, config)
		return
	}

	const width = stringWidth(damageString)

	const centerX = x
	const centerY = y

	x -= width * 0.5

	let randXVel = 0
	if (config.randX) {
		randXVel = Math.getRandomFloat(config.randX[0], config.randX[1])
	}

	for (let i = 0; i < damageString.length; i++) {
		const c = damageString.charAt(i)
		const effectConfig = numberEffectConfigs[c] as EffectConfig
		const xSpread = charWidth(c)
		const z = y + 500
		const duration = damageNumbersConfig.duration
		x += xSpread * 0.5
		const ex = x - centerX
		const effect = renderer.addOneOffEffectByConfig(effectConfig, x, y, z, 1, duration, true, true)
		x += xSpread * 0.5
		effect.emitters.forEach((e) => (e.startColor = ColorUtil.toRGB(config.damageColor)))

		simpleAnimation_addAnimation(effect, (t, dt) => {
			const scale = config.scale(t, dt)
			const xvel = config.velocity.x + randXVel
			const yvel = config.velocity.y
			effect.x = centerX + ex * scale + xvel * t
			effect.y = centerY + yvel * t
			effect.scale = scale

			const anim = config.anim
			if (anim) {
				const ai = Math.floor(t * damageNumbersConfig.animSpeed)
				if (ai < anim.length) {
					effect.x += anim[ai].x
					effect.y += anim[ai].y
				}
			}
			return 0
		})
		callbacks_addCallback(effect, () => simpleAnimation_removeAnimations(effect), 2)
	}
}

function spawnWord(wordText: string, x: gameUnits, y: gameUnits, config: DamageNumberConfig) {
	const text = new PIXI.Text(wordText, config.font)
	text.name = 'dn-word'

	let randXVel = 0
	if (config.randX) {
		randXVel = Math.getRandomFloat(config.randX[0], config.randX[1])
	}

	Renderer.getInstance().stage.addChild(text)

	simpleAnimation_addAnimation(text, (t, dt) => {
		const scale = config.scale(t, dt)
		const xvel = config.velocity.x + randXVel
		const yvel = config.velocity.y
		text.x = x + xvel * t
		text.y = y + yvel * t
		text.scale.set(scale)
		text.alpha = scale

		const anim = config.anim
		if (anim) {
			const ai = Math.floor(t * damageNumbersConfig.animSpeed)
			if (ai < anim.length) {
				text.x += anim[ai].x
				text.y += anim[ai].y
			}
		}
		return 0
	})

	callbacks_addCallback(
		text,
		() => {
			Renderer.getInstance().stage.removeChild(text)
			simpleAnimation_removeAnimations(text)
		},
		2,
	)
}

function damageNumberToString(damage: number): string {
	if (damage > 1000) {
		damage *= 0.001
		if (damage > 1000) {
			damage *= 0.001
			return fixupDecimal(damage) + 'm'
		}
		return fixupDecimal(damage) + 'k'
	}
	if (damage < 1) {
		return damage.toFixed(1) // TODO2:  not all like this, change it?
	} else {
		return damage.toFixed(0)
	}
}

function fixupDecimal(damage: number) {
	let damageString = damage.toFixed(decimalPlaces(damage))
	if (damageString.endsWith('.00')) {
		damageString = damageString.slice(0, damageString.length - 1)
	}
	return damageString
}

/*
4 = 4
43 = 43
431 = 431
4319 = 4.31k
43198 = 43.1k
431987 = 431k
4319872 = 4.31m
43198724 = 43.1m
*/
function decimalPlaces(n: number) {
	if (n < 10) {
		return 2
	}
	if (n < 100) {
		return 1
	}
	return 0
}

if (process.env.NODE_ENV !== 'beta' && process.env.NODE_ENV !== 'loot-prod') {
	DevToolsManager.getInstance().addObjectByName('damageNumbers', damageNumbersConfig)
	//DevToolsManager.getInstance().setDebugObject(damageNumbersConfig)
}
