import { ColliderTraits } from '../../../../collision/shared/colliders'
import { WorldDifficulty } from '../../../../engine/shared/game-data/world-difficulty'
import { AnimationTrack } from '../../../../models-animations/shared/animation-track'
import { Ability, AbilityType, AbilityTargets, AbilityTargetSelectionStyle, AbilitySetColliders, AbilityWait, CriteriaSatisfactionQuantity, CriteriaTarget, CriteriaType } from '../../action-types'
import { EnemyType } from '../../ai-types'

export const alwaysTrueCriteria = {
	satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
	criterias: [],
}

export const alwaysFalseCriteria = {
	satisfactionQuantity: CriteriaSatisfactionQuantity.AT_LEAST_ONE,
	criterias: [],
}

export const enemyIsChampionCriteria = {
	satisfactionQuantity: CriteriaSatisfactionQuantity.AT_LEAST_ONE,
	criterias: [
		{
			criteriaTargets: CriteriaTarget.SELF,
			criteriaType: CriteriaType.IS_CHAMPION_ENEMY,
			criteriaValue: EnemyType.CHAMPION,
			minimumTargetsThatSatisfy: 1,
		},
		{
			criteriaTargets: CriteriaTarget.SELF,
			criteriaType: CriteriaType.IS_CHAMPION_ENEMY,
			criteriaValue: EnemyType.LOOTLESS_CHAMPION,
			minimumTargetsThatSatisfy: 1,
		},
	],
}

export function setPropertyAbility(propertyName: string, value: any): Ability {
	return {
		debugName: `setProperty:${propertyName}=${value}`,
		abilityType: AbilityType.WAIT,
		durationInGameTicks: 0,
		validTargets: AbilityTargets.MYSELF,
		validTargetSelection: AbilityTargetSelectionStyle.ALL,
		setPropertyName: propertyName,
		setPropertyValue: value,
	}
}

export function playAnimationAbility(animationName: AnimationTrack, durationTicks = 20): Ability {
	return {
		debugName: `playAnim:${animationName}`,
		abilityType: AbilityType.WAIT,
		durationInGameTicks: durationTicks,
		validTargets: AbilityTargets.MYSELF,
		validTargetSelection: AbilityTargetSelectionStyle.ALL,
		playsAnimation: animationName,
	}
}

export function shakeCameraAbility(trauma: number, durationTicks = 20): Ability {
	return {
		debugName: `shakeCamera:${trauma}`,
		abilityType: AbilityType.WAIT,
		durationInGameTicks: durationTicks,
		validTargets: AbilityTargets.MYSELF,
		validTargetSelection: AbilityTargetSelectionStyle.ALL,
		shakeCamera: trauma,
	}
}

export function waitAbility(waitTicks: number | (() => number)): AbilityWait {
	return {
		debugName: `wait:${waitTicks}`,
		abilityType: AbilityType.WAIT,
		durationInGameTicks: waitTicks,
		validTargets: AbilityTargets.MYSELF,
		validTargetSelection: AbilityTargetSelectionStyle.ALL,
	}
}

export function setColliderTraitsAbility(traits: ColliderTraits): AbilitySetColliders {
	return {
		debugName: `setColliderTraits:${traits}`,
		durationInGameTicks: 20,
		abilityType: AbilityType.SET_COLLIDERS,
		validTargets: AbilityTargets.MYSELF,
		validTargetSelection: AbilityTargetSelectionStyle.ALL,
		colliderInfo: {
			traits,
		},
	}
}

export const NormalDifficultyCriteria = {
	criteriaTargets: CriteriaTarget.SELF,
	criteriaType: CriteriaType.WORLD_DIFFICULTY_AT_OR_ABOVE_THRESHOLD,
	criteriaValue:  WorldDifficulty.Normal,  
	minimumTargetsThatSatisfy: 1,
}

export const HardDifficultyCriteria = {
	criteriaTargets: CriteriaTarget.SELF,
	criteriaType: CriteriaType.WORLD_DIFFICULTY_AT_OR_ABOVE_THRESHOLD,
	criteriaValue:  WorldDifficulty.Hard,  
	minimumTargetsThatSatisfy: 1,
}

export const BrutalDifficultyCriteria = {
	criteriaTargets: CriteriaTarget.SELF,
	criteriaType: CriteriaType.WORLD_DIFFICULTY_AT_OR_ABOVE_THRESHOLD,
	criteriaValue:  WorldDifficulty.Brutal,  
	minimumTargetsThatSatisfy: 1,
}

export const NastyDifficultyCriteria = {
	criteriaTargets: CriteriaTarget.SELF,
	criteriaType: CriteriaType.WORLD_DIFFICULTY_AT_OR_ABOVE_THRESHOLD,
	criteriaValue:  WorldDifficulty.Nasty,  
	minimumTargetsThatSatisfy: 1,
}
