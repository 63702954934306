import nengi from 'nengi'

export default class PartyReadyCommand {
	static protocol = {
		readyStatus: nengi.Boolean,
	}

	readyStatus: boolean

	constructor(readyStatus: boolean) {
		this.readyStatus = readyStatus
	}
}
