import nengi from 'nengi'
import { uuid } from '../../utils/primitive-types'

class StartSmeltingItemCommand {
	static protocol = {
		itemId: nengi.String,
		fromContainer: nengi.String,
	}
	itemId: uuid
	fromContainer: string
	/**
	 * Creates an instance of StartSmeltingItemCommand. This will scan the Stash to find a matching item.
	 * @param {uuid} itemId Item ID
	 * @memberof StartSmeltingItemCommand
	 */
	constructor(itemId: uuid, fromContainer: string) {
		this.itemId = itemId
		this.fromContainer = fromContainer
	}
}

export default StartSmeltingItemCommand
