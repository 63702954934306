import nengi from 'nengi'

interface Player {
	partyId: string
	playerId: string
	playerName: string
	status: string
	leader: boolean
}

export default class PartyMessage {
	static protocol = {
		partyId: nengi.String,
		playerId: nengi.String,
		players: nengi.String,
		adventureServer: nengi.String,
		error: nengi.String,
	}

	partyId: string
	playerId: string
	players: string
	adventureServer: string
	error: string

	constructor(partyId: string, playerId: string, players: Player[], adventureServer: string, error?: string) {
		this.partyId = partyId
		this.playerId = playerId
		this.players = JSON.stringify(players)
		this.adventureServer = adventureServer
		this.error = error || ''
	}
}
