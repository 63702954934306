import nengi from 'nengi'

export class RegisterAnonymousPlayerCommand {
	static protocol = {
		username: nengi.String,
		password: nengi.String,
		passwordConfirmation: nengi.String,
		emailAddress: nengi.String,
		emailAddressConfirmation: nengi.String,
		agreedToTerms: nengi.Boolean,
		agreedToPromotion: nengi.Boolean,
	}

	username: string
	password: string
	passwordConfirmation: string
	emailAddress: string
	emailAddressConfirmation: string
	agreedToTerms: boolean
	agreedToPromotion: boolean

	recaptchaToken: string
	recaptchaTag: string

	constructor(registerFormValues, recaptcha) {
		Object.assign(this, registerFormValues)

		this.recaptchaToken = recaptcha.recaptchaToken
		this.recaptchaTag = recaptcha.tag
	}
}
