import { EnemyAI, FightingTriggers, IdleBehaviours, FightingBehaviours, LeashTriggers, LeashingBehaviours, AttackTypes, DeadBehaviours, ShotLeadPrecision, EnemyType, EnemyTargetingStyles } from '../../shared/ai-types'
import { BurstFireModes, ModType, ModValueType } from '../../../projectiles/shared/projectile-types'
import { SpineDataName, EnemyBipedSkinType } from '../../../models-animations/shared/spine-config'
import { degToRad } from '../../../utils/math'
import { EnemyDefaults } from './enemy-defaults'
import { Colors } from '../../../utils/colors'
import { AnimationTrack } from '../../../models-animations/shared/animation-track'
import { ParticleEffectType } from '../../../engine/shared/game-data/particle-config'
import { Vector } from 'sat'
import { ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { ENEMY_DEFAULT_HEALTH } from '../../../engine/shared/game-data/enemy-formulas'
import { Ability, AbilitySelectionStyles, AbilityTargets, AbilityTargetSelectionStyle, AbilityType } from '../action-types'
import { alwaysTrueCriteria, waitAbility } from './abilities/common-abilities'
import { ActionCriteriaHelpers } from '../action-criteria/action-criteria-helpers'
import { BuffIdentifier } from '../../../buffs/shared/buff.shared'
import { attackRatedAbilityList } from '../action-criteria/ability-helpers'
import { ENEMY_NAMES } from './enemy-names'
import { deepClone } from '../abilities.test'
import { worldDifficultyBrutalCriteria } from '../action-criteria/action-criteria-helpers'

const longRangeAbility: Ability = {
	debugName: 'longRangeAbility',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		projectileCount: 8,
		spreadAngle: degToRad(5),
		speed: 800,
		colliderRadius: 15,
		lifespanInSeconds: 2,
		damageMultiplier: 1,
		modifiers: [{ modType: ModType.STRAIGHT }, { modType: ModType.ACCELERATION, value: { modValueType: ModValueType.RANDOM, min: -1.8, max: -0.3 } }],
		baseLightningDamage: 35,
		burstMode: BurstFireModes.STRAIGHT,
		color: Colors.red,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_ASP,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_LIGHTNING_TRAIL,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_LIGHTNING_SHOOT,
	},
	ignoreAngleOnWeaponOffset: true,
	attackOffset: new Vector(350, -240),
}

export const longRangeAbilityBrutal = deepClone(longRangeAbility)
longRangeAbilityBrutal.projectileConfig.projectileCount = 13
longRangeAbilityBrutal.projectileConfig.spreadAngle = degToRad(15)
longRangeAbilityBrutal.projectileConfig.pierceCount = 1
longRangeAbilityBrutal.projectileConfig.lifespanInSeconds = 2.4


const shortRangeAbility: Ability = {
	debugName: 'shortRangeAbility',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.CLOSEST,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		speed: 1200,
		colliderRadius: 30,
		baseLightningDamage: 100,
		lifespanInSeconds: 0.3,
		damageMultiplier: 1,
		burstMode: BurstFireModes.STRAIGHT,
		color: Colors.darkRed,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_LONG_SPELL_SMALL,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_LIGHTNING_TRAIL,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_LIGHTNING_SHOOT,
		applyBuffsOnHit: [BuffIdentifier.EnemyCrabMovementSpeedSlow],
	},
	ignoreAngleOnWeaponOffset: true,
	attackOffset: new Vector(250, -70),
}

const animationTimes = {
	[AnimationTrack.SHORT_RANGE_ATTACK]: 0.3, // PLACEHOLDER
	[AnimationTrack.LONG_RANGE_ATTACK]: 0.3, // PLACEHOLDER
}

const stranglevine: EnemyAI = {
	name: ENEMY_NAMES.STRANGLEVINE,
	type: EnemyType.BASIC,
	appearance: {
		asset: SpineDataName.STRANGLEVINE,
		skin: EnemyBipedSkinType.DEFAULT,
		mixSettings: [
			{ from: AnimationTrack.IDLE, to: AnimationTrack.LONG_RANGE_ATTACK, duration: 0.1 },
			{ from: AnimationTrack.IDLE, to: AnimationTrack.SHORT_RANGE_ATTACK, duration: 0.1 },
			{ from: AnimationTrack.LONG_RANGE_ATTACK, to: AnimationTrack.IDLE, duration: 0.2 },
			{ from: AnimationTrack.SHORT_RANGE_ATTACK, to: AnimationTrack.IDLE, duration: 0.2 },
		],
	},
	general: {
		enemyLevel: 1,
		experienceWhenKilled: 0.75,
	},
	baseAttributes: {
		...EnemyDefaults.baseAttributes,
		colliders: [
			{
				type: ColliderType.Circle,
				radius: 30,
				position: [0, -10],
				traits: ColliderTraits.BlockMovement,
			},
			{
				type: ColliderType.Ellipse,
				rX: 33,
				rY: 140,
				position: [0, -140],
				traits: ColliderTraits.BlockProjectile,
			},
		],
		maxHealth: ENEMY_DEFAULT_HEALTH * 2.25,
		//attackOffset: new Vector(500, -140),
		movementSpeed: 0,
		colliderIsKinematic: true,
		lootDropProbability: 1.4,
	},
	soundEffects: {
		attack: 'Projectile_Fireball',
		impact: 'SFX_Impact_Enemy_Mush',
	},
	states: {
		idling: {
			behaviour: IdleBehaviours.STATIONARY,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			transitionToFighting: {
				trigger: FightingTriggers.AGGRESSIVE_PROXIMITY,
				radius: 900,
			},
			aggroOnHit: true,
		},
		fighting: {
			behaviour: FightingBehaviours.CHASE_AND_ATTACK,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			attackType: AttackTypes.PROJECTILE,
			engagementMaxDistance: 1200,
			engagementMinDistance: 0,
			movementMaxDistance: 1200,
			movementMinDistance: 0,
			brain: {
				engagementColliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 9999, traits: ColliderTraits.Trigger }],
				unengagedActions: [
					{
						// DO NOTHING
						priority: 0,
						actionCriteria: alwaysTrueCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							abilityOptions: [[0, waitAbility(20)]],
						},
					},
				],
				actions: [
					{
						//  BLOCK/ATTACK
						priority: 0,
						actionCriteria: ActionCriteriaHelpers.generatePlayerIsInMeleeRangeCriteria(350),
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							// abilityOptions: [[1, playAnimationAbility(AnimationTrack.SHORT_RANGE_ATTACK, 0), 15, shortRangeAbility, 25]],
							abilityOptions: [attackRatedAbilityList(5, animationTimes, AnimationTrack.SHORT_RANGE_ATTACK, shortRangeAbility, 25)],
						},
					},
					{
						// BLOCK/ATTACK
						priority: 2,
						actionCriteria: worldDifficultyBrutalCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							// abilityOptions: [[1, playAnimationAbility(AnimationTrack.LONG_RANGE_ATTACK, 0), 10, longRangeAbility, 50]],
							abilityOptions: [attackRatedAbilityList(5, animationTimes, AnimationTrack.LONG_RANGE_ATTACK, longRangeAbilityBrutal, 50)],
						},
					},
					{
						// BLOCK/ATTACK
						priority: 4,
						actionCriteria: alwaysTrueCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							// abilityOptions: [[1, playAnimationAbility(AnimationTrack.LONG_RANGE_ATTACK, 0), 10, longRangeAbility, 50]],
							abilityOptions: [attackRatedAbilityList(5, animationTimes, AnimationTrack.LONG_RANGE_ATTACK, longRangeAbility, 50)],
						},
					},
				],
			},
			attackOptions: [],
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 1,
			transitionToLeashing: {
				trigger: LeashTriggers.RANGE_TO_TARGET_EXCEEDED,
				range: 1500,
			},
		},
		leashing: {
			behaviour: LeashingBehaviours.MOVE,
			speedMultiplier: 4,
		},
		fleeing: {},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 1,
		},
	},
}

export default stranglevine
