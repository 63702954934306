import ClientPlayerInput from "../../input/client/client-player-input"
import DevToolsManager from "../../ui/dev-tools/dev-tools-manager"

let DEBUG_CANVAS_CTX

const controllerConfig = {
	refresh: destroyDebugCanvasIf,
	width: 10 + 1 / 4,
	height: 4 + 3 / 8,
	alpha: 0.7,
	scale: 50,
	pos: {
		x: 0.5,
		y: 0.5
	},
	panel: {
		x: 1 / 4,
		y: 3 / 4,
	},
	greyHighlight: {
		x: 3 + 1 / 8,
		w: 3,
		h: 1 / 2,
		y: 5 / 8
	},
	dpad: {
		s: 25,
		x: 10,
		y: 50,
	}
}
DevToolsManager.getInstance().addObjectByName('controllerConfig', controllerConfig)

function destroyDebugCanvasIf() {
	const ctx = DEBUG_CANVAS_CTX
	if (ctx) {
		ctx.canvas.remove()
		DEBUG_CANVAS_CTX = null
	}
}

function createDebugCanvas() {
	const canvas = document.createElement('canvas')
	canvas.style.position = 'absolute'
	const w = controllerConfig.width * controllerConfig.scale
	const h = controllerConfig.height * controllerConfig.scale
	canvas.width = w
	canvas.height = h
	canvas.onmousedown = (e) => { DevToolsManager.getInstance().setDebugObject(controllerConfig) }
	canvas.style.left = (window.innerWidth * 0.5 - w * controllerConfig.pos.x) + 'px'
	canvas.style.top = (window.innerHeight * 0.5 + h * controllerConfig.pos.y) + 'px'
	const ctx = canvas.getContext('2d')
	document.body.appendChild(canvas)
	return ctx
}

export function debugInputUpdateCanvas(alive: boolean) {
	if (!DEBUG_CANVAS_CTX && alive) {
		DEBUG_CANVAS_CTX = createDebugCanvas()
	} else if (DEBUG_CANVAS_CTX && !alive) {
		DEBUG_CANVAS_CTX.canvas.remove()
		DEBUG_CANVAS_CTX = undefined
	}
}

// draws nes gamepad, source: https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fimg3.wikia.nocookie.net%2F__cb20110527164010%2Fkongregate%2Fimages%2Fd%2Fde%2FReg-NES-COntroller.jpg&f=1&nofb=1
export function debugDrawInput(input: ClientPlayerInput) {
	const frameState = input.frameState

	debugInputUpdateCanvas(true)
	const ctx = DEBUG_CANVAS_CTX
	ctx.globalAlpha = controllerConfig.alpha
	ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)

	const grey = '#dddddd'
	const black = '#292929'
	const darkGrey = '#7a7a7a'
	const red = '#f02020'
	ctx.fillStyle = grey
	ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height)

	ctx.fillStyle = black

	const o = controllerConfig.panel.x * controllerConfig.scale
	const t = controllerConfig.panel.y * controllerConfig.scale
	ctx.fillRect(o, t, ctx.canvas.width - 2 * o, ctx.canvas.height - t - o)

	const dirs = [frameState.up, frameState.down, frameState.left, frameState.right, frameState.mouseLeftDown]
	const s = controllerConfig.scale
	const ps = s * 0.75
	const pos = [[2, 1], [2, 3], [1, 2], [3, 2], [10, 2]]
	const dpad = controllerConfig.dpad
	const dpadx = dpad.x + controllerConfig.panel.x
	const dpady = dpad.y + controllerConfig.panel.y
	for (let i = 0; i < dirs.length; i++) {
		const dir = dirs[i]
		ctx.fillStyle = dir ? '#ff0000' : '#000000'
		ctx.beginPath()
		ctx.arc(pos[i][0] * ps + dpadx, pos[i][1] * ps + dpady, ps * 0.5, 0, Math.PI * 2, false)
		ctx.fill()
	}

	{
		const h = controllerConfig.greyHighlight.h * s
		const x = controllerConfig.greyHighlight.x * s
		const yd = controllerConfig.greyHighlight.y * s
		const w = controllerConfig.greyHighlight.w * s
		ctx.fillStyle = darkGrey
		for (let i = 0; i < 3; i++) {
			const y = controllerConfig.panel.y * s + (i * yd)
			ctx.fillRect(x, y, w, h)
		}
		ctx.fillRect(x, 3.6 * s, w, h)

		ctx.fillStyle = grey
		ctx.fillRect(x, 2.6 * s, w, h * 1.8)
	}
	//draw rectangle
	ctx.fillStyle = grey
	//ctx.fillRect(frameState.mouseX - s * 0.5, frameState.mouseY - s * 0.5, s, s)
}

export function debugDrawText(s: string, c: string = 'white') {
	if (!DEBUG_CANVAS_CTX) {
		DEBUG_CANVAS_CTX = createDebugCanvas()
	}
	const ctx = DEBUG_CANVAS_CTX
	ctx.fillStyle = c
	ctx.font = '50px Arial'
	ctx.fillText(s, 0, ctx.canvas.height - 30)
	ctx.strokeText(s, 0, ctx.canvas.height - 30)
}