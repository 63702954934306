import nengi from 'nengi'

export default class ShakeCameraMessage {
	static protocol = {
		x: { type: nengi.Float32 },
		y: { type: nengi.Float32 },
		trauma: { type: nengi.Float32 },
	}

	x: number
	y: number
	trauma: number

	constructor(params) {
		this.x = params.x
		this.y = params.y
		this.trauma = params.trauma
	}
}
