import ModelConfig from '../shared/model-config'
import configureAnimationTracks from './configure-animation-tracks'
import { RiggedSpineModel } from './spine-model'

function createModel(modelConfig: ModelConfig, namePrefix: string = '', zOffset: number = 0, rotation: number = 0) {
	const model = RiggedSpineModel.allocFromPool(modelConfig.asset, modelConfig.skin)
	model.name = namePrefix + modelConfig.asset

	if (modelConfig.color) {
		model.tintRgb = modelConfig.color
	}
	if (modelConfig.scale) {
		model.scale.x = modelConfig.scale
		model.scale.y = modelConfig.scale
	}

	if (modelConfig.defaultMix) {
		model.stateData.defaultMix = modelConfig.defaultMix
	}

	if (modelConfig.mixSettings) {
		configureAnimationTracks(model, modelConfig.mixSettings)
	}

	model.zIndex = model.y + (modelConfig.zOffset ? modelConfig.zOffset : 0) + zOffset

	if (modelConfig.onCreatedFunction) {
		modelConfig.onCreatedFunction(model, rotation)
	}

	return model
}

export { createModel }
