





























import { mapMutations } from 'vuex'
import { getEmoteIconStyle } from '../../state/emotes-ui-state'
import TextButton from '../buttons/text-button.vue'

export default {
	name: 'EmoteInventoryItem',
	components: {
		TextButton,
	},
	props: {
		emote: {
			type: Number,
			required: true,
		},
		showSlot: {
			type: Boolean,
			required: false,
			default: true,
		},
		onClickFn: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default on-click handler for the <EmoteIventoryItem /> component has been called. Did you forget to pass an :on-click handler to the component?')
			},
		},
		selected: {
			type: Boolean,
			required: false,
			default: false,
		},
		selectable: {
			type: Boolean,
			required: false,
			default: true,
		},
		equipButton: {
			type: Boolean,
			required: false,
			default: false,
		},
		onEquipFn: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default on-equip handler for the <EmoteInventoryItem /> component has been called. Did you forget to pass an :on-equip handler to the component?')
			},
		},
	},
	computed: {
		getStyle() {
			return getEmoteIconStyle(this.emote, 153, 189)
		},
	},
	methods: {
		...mapMutations('UIScale', ['showTooltip', 'updateToolTipPosition']),

		onClick(emoteId) {
			if (this.selectable) {
				this.onClickFn(emoteId)
			}
		},
		onMouseEnter(event) {
			if (this.tooltipable === false) return
			this.updateToolTipPosition(event)
			this.showTooltip(this.item)
		},
		onMouseLeave() {
			if (this.tooltipable === false) return
			this.showTooltip(null)
		},
		onEquip(emoteId) {
			this.onEquipFn(emoteId)
		},
	},
}
