import { propConfigs, PropConfig, getPropConfig, makePropId, BiomeId, PropName } from '../../world/shared/prop-configs'
import { cloneDeep, forIn } from 'lodash'
import { Vector } from 'sat'
import { debugConfig } from '../../engine/client/debug-config'
import logger from '../../utils/client-logger'
import { ColliderType } from '../../collision/shared/colliders'

function logIfEnabled(message?: any, ...optionalParams: any[]): void {
	if (debugConfig.props.logging) {
		console.log(message, optionalParams)
	}
}

export const PropModelData: Map<string, any> = new Map()

// this could use a better name, as it's not as generic and all-encompasing as it sounds
interface FileSystem {
	existsSync
	readFileSync
}

function loadPropModelData(fs: FileSystem) {
	forIn(propConfigs, (biome, biomeId) => {
		forIn(biome, (propConfig, propId) => {
			// example path: ./assets/biomes/highlands/props/small-uncommon-03.json
			const path = `./assets/biomes/${biomeId}/props/${propId}.json`
			logIfEnabled(`loading: ${path}`)
			// example id: forest/small-uncommon-03
			if (fs.existsSync(path)) {
				PropModelData.set(`${biomeId}/${propId}`, loadJson(path, fs))
			}
		})
	})
}

const loadJson = function(path: string, fs: FileSystem) {
	const data = fs.readFileSync(path, 'utf8')
	if (data) {
		return JSON.parse(data)
	} else {
		return null
	}
}

export function addColliderConfigsToLookupColliders(fs: FileSystem) {
	loadPropModelData(fs)
	logIfEnabled(`########### addColliderConfigsToLookupColliders ###########`)
	forIn(propConfigs, (biome, biomeId: string) => {
		logIfEnabled(`### addColliderConfigsToLookupColliders ${biomeId} ###`)
		forIn(biome, (propConfig: PropConfig, propName: string) => {
			logIfEnabled(`# addColliderConfigsToLookupColliders ${biomeId}/${propName} #`)
			propConfig.colliders?.forEach((colliderConfig) => {
				if (colliderConfig.type === ColliderType.Lookup) {
					propConfig.colliders.remove(colliderConfig)
					addColliderConfigsByRef(biomeId, propName, propConfig)
				}
			})
		})
	})
	logIfEnabled(`################################################`)
}

// Looks in the model/spine data (json) named 'biomeId/propName' and finds all attachment names.
// These attachment names are then used to lookup the same named ColliderConfigs in PropConfigs
// ie: addColliderConfigsByRef('forest', 'high-blocker-01-var01')
//  biomes/forest/props/high-blocker-01-var01.json
//   has attachment: high-blocker-01
//  so we:
//	 getPropConfig('forest', 'high-blocker-01')
//   clone all colliderConfigs of high-blocker-01
//   and add those clones to the colliderConfigs of 'forest/high-blocker-01-var01'
function addColliderConfigsByRef(biomeId: BiomeId, propName: PropName, propConfig: PropConfig) {
	const model = PropModelData.get(makePropId(biomeId, propName))
	const attachments = model.skins[0].attachments

	model.slots.forEach((slot) => {
		const name = slot.name
		const attachment = attachments[slot.name][slot.attachment]
		logIfEnabled(`${propName} has attachment: ${name}`, attachment)
		const offset = new Vector(attachment.x, -attachment.y) // note: pixi and world have y flipped
		const otherPropConfig = getPropConfig(biomeId, slot.attachment)
		if (!otherPropConfig) {
			logger.warn(`prop ${makePropId(biomeId, propName)} requires ${makePropId(biomeId, slot.attachment)} propConfig which does not exist`)
			return
		}
		otherPropConfig.colliders?.forEach((colliderConfig) => {
			const clone = cloneDeep(colliderConfig)
			clone.position[0] += offset.x
			clone.position[1] += offset.y
			propConfig.colliders.push(clone)
		})
	})
}

export function getVisibilityRadiusFromSubProps(biomeId: BiomeId, propName: PropName) {
	const model = PropModelData.get(makePropId(biomeId, propName))
	const attachments = model.skins[0].attachments

	let maxVisibility = 0
	model.slots.forEach((slot) => {
		const name = slot.name
		const attachment = attachments[slot.name][slot.attachment]
		logIfEnabled(`${propName} has attachment: ${name}`, attachment)
		const offset = new Vector(attachment.x, -attachment.y) // note: pixi and world have y flipped
		const offsetLen = offset.len()
		const subPropConfig = getPropConfig(biomeId, slot.attachment)
		if (!subPropConfig) {
			logger.warn(`prop ${makePropId(biomeId, propName)} requires ${makePropId(biomeId, slot.attachment)} propConfig which does not exist`)
			// console.log(`'${slot.attachment}': {\n\tdontLoadAsset: true\n},`)
			return
		}

		maxVisibility = Math.max(maxVisibility, offsetLen + subPropConfig.visibilityRadius)
	})

	return maxVisibility
}

export function debugPrintMisconfiguredPropAssets(fs: FileSystem) {
	forIn(propConfigs, (biome, biomeId) => {
		forIn(biome, (propConfig, propName) => {
			// example path: ./assets/biomes/highlands/props/small-uncommon-03.json
			const path = `./assets/biomes/${biomeId}/props/${propName}.json`
			logIfEnabled(`loading: ${path}`)
			// example id: forest/small-uncommon-03
			const fileExists = fs.existsSync(path)
			//logger.debug(path, fileExists, propConfig.dontLoad)
			if (fileExists === (propConfig.dontLoadAsset ? true : false)) {
				if (fileExists) {
					logger.error(`file exists but we're not loading it? ${biomeId}/${propName}`, path)
				} else {
					logger.error(`file doesn't exist but there's we're loading it ${biomeId}/${propName}\n(consider using dontLoadAsset on prop config)`, path)
				}
			}
		})
	})
}
