import { NengiClient } from '../../engine/client/nengi-client'
import SendItemToStashCommand from '../../items/shared/send-item-to-stash-command'
import logger from '../../utils/client-logger'

export const pitStorageUIState = () => {
	logger.debug('Initializing Pit Storage UI Module')
	return {
		namespaced: true,
		state: {
			selectedItem: null,
		},
		mutations: {},
		getters: {
			selectedItem(state) {
				return state.selectedItem
			},
		},
		actions: {
			toggleSelectedItem(context, selectedItem?: any) {
				const { state, commit, rootState } = context

				if (selectedItem && selectedItem !== state.selectedItem) {
					if (rootState.itemLocks?.lockedItems.includes(selectedItem)) {
						return
					}

					commit(
						'itemComparison/selectItem',
						{
							itemId: selectedItem,
							container: 'pitStorage',
						},
						{ root: true },
					)
					state.selectedItem = selectedItem
				} else {
					commit('itemComparison/deselectItem', {}, { root: true })
					state.selectedItem = null
				}
			},
			sendItemToStashFromPitStorage(context, itemId) {
				const msg = new SendItemToStashCommand(itemId, 'pit_rewards') //pit_rewards = Storage; there is no container for the reward screen
				NengiClient.getInstance().sendCommand(msg)
			},
		},
	}
}
