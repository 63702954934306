import nengi from 'nengi'
import { uuid } from '../../utils/primitive-types'


export default class UseConsumableItemCommand {
    static protocol = {
        itemId: nengi.String
    }
    itemId: uuid
    constructor(itemId: uuid) {
        this.itemId = itemId
    }
}