import nengi from 'nengi'

export const SERVER_CHAT_NAME = '[SERVER]'

class ChatMessage {
	static protocol = {
		authorsName: nengi.String,
		message: nengi.String,
		party: nengi.Boolean,
	}

	authorsName: string
	message: string
	party: boolean
	authorColor: string // this is set on client after receiving the message

	constructor(authorsName: string, message: string, party: boolean = false) {
		this.authorsName = authorsName
		this.message = message
		this.party = party
		this.authorColor = '#ffffff'
	}
}

export default ChatMessage
