import nengi from 'nengi'

class BroadcastMessage {
	static protocol = {
		bannerSize: nengi.String,
		message: nengi.String,
		color: nengi.String,
	}

	bannerSize: string
	message: string
	color: string

	constructor(bannerSize: string, message: string, color?: string) {
		this.bannerSize = bannerSize
		this.message = message
		this.color = color || '#FFFFFF'
	}
}

export default BroadcastMessage
