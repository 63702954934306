import ItemRarity from '../../../loot/shared/item-rarity'
import ItemType from '../../../loot/shared/item-type'
import { PersistentBuffSubType } from '../../../loot/shared/persistent-buff-sub-type'
import WeaponAugmentSubType from '../../../loot/shared/weapon-augment-sub-type'
import { WeaponSubType } from '../../../loot/shared/weapon-sub-type'
import { percentage, gameUnits } from '../../../utils/primitive-types'
import WeightedList from '../../../utils/weighted-list'

//TODO2: Ensure these values aren't showing up in the client build/move them to server files
// These are what determine an item's rarity VALUE. I.e. Is this an epic?
// For drop rate, see loot-manager.ts (server side ONLY)
// WARNING: These values also determine *how good* the item actually is! Giving astros a higher
// weight, for example, will actually make the "lowest end astros" worse as a result.
const COMMON_WEIGHT = 50000
const UNCOMMON_WEIGHT = 28500
const RARE_WEIGHT = 16200
const EPIC_WEIGHT = 4600
const LEGENDARY_WEIGHT = 600
const ASTRONOMICAL_WEIGHT = 100

export const LOOT_DROP_SETTINGS = {
	dropChances: new WeightedList([
		[0, 7660],
		[1, 1900],
		[2, 400],
		[3, 30],
		[4, 10],
	]),
	itemTypeWeightedList: new WeightedList([
		[ItemType.Weapon, 3000],
		[ItemType.WeaponAugment, 4610],
		[ItemType.Gear, 2050],
		[ItemType.BiomeKey, 150],
		[ItemType.LuckyShard, 50],
		[ItemType.RarityShard, 100],
		[ItemType.PersistentBuff, 40],
	]),
	spawnModifiers: {
		minVelocity: -3.5,
		maxVelocity: 3.5,
		minMomentum: 20,
		maxMomentum: 100,
	},
	propDropChances: new WeightedList([
		[2, 7000],
		[3, 2000],
        [4, 900],
        [5, 100],
	]),
	propItemTypeWeightedList: new WeightedList([
        [ItemType.WeaponAugment, 5630],
        [ItemType.Gear, 3400],
        [ItemType.BiomeKey, 400],
        [ItemType.LuckyShard, 150],
        [ItemType.RarityShard, 300],
        [ItemType.PersistentBuff, 120],
    ]),
}

console.assert(LOOT_DROP_SETTINGS.dropChances.totalWeightSum === 10000, `LOOT_DROP_SETTINGS.dropChances does not sum up to 10,000, actual: ${LOOT_DROP_SETTINGS.dropChances.totalWeightSum}`)
console.assert(LOOT_DROP_SETTINGS.itemTypeWeightedList.totalWeightSum === 10000, `LOOT_DROP_SETTINGS.itemTypeWeightedList does not sum up to 10,000, actual: ${LOOT_DROP_SETTINGS.itemTypeWeightedList.totalWeightSum}`)
console.assert(LOOT_DROP_SETTINGS.propDropChances.totalWeightSum === 10000, `LOOT_DROP_SETTINGS.propDropChances does not sum up to 10,000, actual: ${LOOT_DROP_SETTINGS.dropChances.totalWeightSum}`)
console.assert(LOOT_DROP_SETTINGS.propItemTypeWeightedList.totalWeightSum === 10000, `LOOT_DROP_SETTINGS.propItemTypeWeightedList does not sum up to 10,000, actual: ${LOOT_DROP_SETTINGS.itemTypeWeightedList.totalWeightSum}`)

export const RARITY_SHARD_WEIGHTED_LIST = new WeightedList([
	[ItemRarity.EPIC, EPIC_WEIGHT],
	[ItemRarity.LEGENDARY, LEGENDARY_WEIGHT],
	[ItemRarity.ASTRONOMICAL, ASTRONOMICAL_WEIGHT],
])

export const PERSISTED_BUFF_SUBTYPE_WEIGHTED_LIST = new WeightedList([
	[PersistentBuffSubType.Enchanting, 488],
	[PersistentBuffSubType.LegendaryEnchanting, 31],
	[PersistentBuffSubType.LifeBloom, 333],
	[PersistentBuffSubType.RegenerativeHealing, 148],
	[PersistentBuffSubType.QueensRansom, 148],
	[PersistentBuffSubType.LegendaryQueensRansom, 31],
	[PersistentBuffSubType.FinalCountdown, 148],
	[PersistentBuffSubType.IronMan, 31],
	[PersistentBuffSubType.Pauper, 148],
	[PersistentBuffSubType.RagsToRiches, 31],
])

const WEIGHT_SUM = COMMON_WEIGHT + UNCOMMON_WEIGHT + RARE_WEIGHT + EPIC_WEIGHT + LEGENDARY_WEIGHT + ASTRONOMICAL_WEIGHT

export const EXPECTED_WEIGHT_SUM = 100_000
if (WEIGHT_SUM !== EXPECTED_WEIGHT_SUM) {
	console.trace(`Weight sum does not add up to 100000, instead it is ${WEIGHT_SUM} - check your weights!`)
	process.exit(1)
}

export const COMMON_THRESHOLD = 1 - (COMMON_WEIGHT + UNCOMMON_WEIGHT + RARE_WEIGHT + EPIC_WEIGHT + LEGENDARY_WEIGHT + ASTRONOMICAL_WEIGHT) / WEIGHT_SUM
export const UNCOMMON_THRESHOLD = 1 - (UNCOMMON_WEIGHT + RARE_WEIGHT + EPIC_WEIGHT + LEGENDARY_WEIGHT + ASTRONOMICAL_WEIGHT) / WEIGHT_SUM
export const RARE_THRESHOLD = 1 - (RARE_WEIGHT + EPIC_WEIGHT + LEGENDARY_WEIGHT + ASTRONOMICAL_WEIGHT) / WEIGHT_SUM
export const EPIC_THRESHOLD = 1 - (EPIC_WEIGHT + LEGENDARY_WEIGHT + ASTRONOMICAL_WEIGHT) / WEIGHT_SUM
export const LEGENDARY_THRESHOLD = 1 - (LEGENDARY_WEIGHT + ASTRONOMICAL_WEIGHT) / WEIGHT_SUM
export const ASTRONOMICAL_THRESHOLD = 1 - ASTRONOMICAL_WEIGHT / WEIGHT_SUM

export function getRarityThresholdFromRarity(rarity: ItemRarity) {
	switch (rarity) {
		case ItemRarity.COMMON:
			return COMMON_THRESHOLD
		case ItemRarity.UNCOMMON:
			return UNCOMMON_THRESHOLD
		case ItemRarity.RARE:
			return RARE_THRESHOLD
		case ItemRarity.EPIC:
			return EPIC_THRESHOLD
		case ItemRarity.LEGENDARY:
			return LEGENDARY_THRESHOLD
		case ItemRarity.ASTRONOMICAL:
			return ASTRONOMICAL_THRESHOLD
	}
}

function getBias(chance) {
	return Math.log(chance) / Math.log(0.5)
}

export const ROLLER_BIAS_FNS = {
	[ItemRarity.COMMON]: (random: number): number => random ** 6,
	[ItemRarity.UNCOMMON]: (random: number): number => random ** getBias(UNCOMMON_THRESHOLD),
	[ItemRarity.RARE]: (random: number): number => random ** getBias(RARE_THRESHOLD),
	[ItemRarity.EPIC]: (random: number): number => random ** getBias(EPIC_THRESHOLD),
	[ItemRarity.LEGENDARY]: (random: number): number => random ** getBias(LEGENDARY_THRESHOLD),
	[ItemRarity.ASTRONOMICAL]: (random: number): number => random ** getBias(ASTRONOMICAL_THRESHOLD),
}

//TODO3: Can these values be somehow linked to the ROLLER_BIAS stuff? Mathy?
export const ITEM_LEVEL_OFFSET_BY_RARITY = {
	[ItemRarity.COMMON]: (v) => v ** 0.5,
	[ItemRarity.UNCOMMON]: (v) => v ** 0.6,
	[ItemRarity.RARE]: (v) => v ** 0.99,
	[ItemRarity.EPIC]: (v) => v,
	[ItemRarity.LEGENDARY]: (v) => v ** 1.01,
	[ItemRarity.ASTRONOMICAL]: (v) => v ** 1.02,
}

export const ITEM_COLLIDER_RADIUS: gameUnits = 40
export const ITEM_WEAPON_AUGMENT_TRAJECTORY_CHANCE: percentage = 0.25
export const ITEM_WEAPON_ENCHANTMENT_TRAJECTORY_CHANCE: percentage = 0.05

export const ITEM_WEAPON_ELEMENTAL_CHANCE = {
	[WeaponSubType.ArcaneFocus]: 0.5,
	[WeaponSubType.Crossbow]: 0.25,
	[WeaponSubType.Scythe]: 0.25,
	[WeaponSubType.Spellsword]: 0.4,
	[WeaponSubType.Staff]: 0.25,
	[WeaponSubType.Wand]: 0.25,
}

export const ITEM_WEAPON_TRAJECTORY_CHANCE = {
	[WeaponSubType.ArcaneFocus]: 0.70,
	[WeaponSubType.Crossbow]: 0.15,
	[WeaponSubType.Scythe]: 0.30,
	[WeaponSubType.Spellsword]: 0,
	[WeaponSubType.Staff]: 0.25,
	[WeaponSubType.Wand]: 0.25,
}

export const ITEM_WEAPON_AUGMENTS_THAT_ROLL_ANY_DAMAGE_TYPE: WeaponAugmentSubType[] = [
	WeaponAugmentSubType.BasicStatUp,
	WeaponAugmentSubType.ClusterShot,
	WeaponAugmentSubType.CreepingDeath,
	WeaponAugmentSubType.CritSwitch,
	WeaponAugmentSubType.DiffusionBeam,
	WeaponAugmentSubType.FastAndWeak,
	WeaponAugmentSubType.FocusedBeam,
	WeaponAugmentSubType.NarrowSpreadExtraProjectiles,
	WeaponAugmentSubType.Pierce,
	WeaponAugmentSubType.QuickCharge,
	WeaponAugmentSubType.RangeAdjustingAccelerate,
	WeaponAugmentSubType.SlowAndStrong,
	WeaponAugmentSubType.Sniper, //TODO: should be physical only, but requires migration to fix
	WeaponAugmentSubType.SplashDamage,
	WeaponAugmentSubType.StarLaser,
	WeaponAugmentSubType.StraightBoomerang,
	WeaponAugmentSubType.TightenSpread,
	WeaponAugmentSubType.WideSpreadExtraProjectiles,

	WeaponAugmentSubType.Accelerate,
	WeaponAugmentSubType.Deccelerate,
	WeaponAugmentSubType.Circular,
	WeaponAugmentSubType.Orbit,
	WeaponAugmentSubType.Spiral,
	WeaponAugmentSubType.Looping,
	WeaponAugmentSubType.RangeAdjustingCircular,
	WeaponAugmentSubType.RangeAdjustingOrbit,
	WeaponAugmentSubType.Sine,
	WeaponAugmentSubType.Zigzag,
]

export const BASE_WEAPON_MOD_COUNT = 1
export const MAX_WEAPON_MOD_COUNT = 6

export const BASE_WEAPON_AUGMENT_MOD_COUNT = 1
export const MAX_WEAPON_AUGMENT_MOD_COUNT = 3

export const BASE_GEAR_MOD_COUNT = 1
export const MAX_GEAR_MOD_COUNT = 3

export const LEVELS_PER_ADDITIONAL_MOD = 15

export const AUGMENT_WEIGHTING_PER_RARITY_WEIGHTED_LIST: Map<ItemRarity, WeightedList<any>> = new Map()
{
	const AUGMENT_WEIGHTING_PER_RARITY: Map<ItemRarity, number[]> = new Map()
	AUGMENT_WEIGHTING_PER_RARITY.set(ItemRarity.COMMON, [100, 25, 0, 0, 0, 0])
	AUGMENT_WEIGHTING_PER_RARITY.set(ItemRarity.UNCOMMON, [25, 100, 0, 0, 0, 0])
	AUGMENT_WEIGHTING_PER_RARITY.set(ItemRarity.RARE, [12, 100, 50, 25, 0, 0])
	AUGMENT_WEIGHTING_PER_RARITY.set(ItemRarity.EPIC, [0, 50, 100, 25, 0, 0])
	AUGMENT_WEIGHTING_PER_RARITY.set(ItemRarity.LEGENDARY, [0, 12, 25, 100, 50, 6])
	AUGMENT_WEIGHTING_PER_RARITY.set(ItemRarity.ASTRONOMICAL, [0, 0, 12, 100, 75, 25])

	const rarities = [ItemRarity.COMMON, ItemRarity.UNCOMMON, ItemRarity.RARE, ItemRarity.EPIC, ItemRarity.LEGENDARY, ItemRarity.ASTRONOMICAL]
	rarities.forEach((rarity) => {
		const weights = AUGMENT_WEIGHTING_PER_RARITY.get(rarity)
		const weightSlotTuples: Array<[number, number]> = []
		weights.forEach((weight, index) => {
			weightSlotTuples.push([index + 1, weight])
		})
		AUGMENT_WEIGHTING_PER_RARITY_WEIGHTED_LIST.set(rarity, new WeightedList(weightSlotTuples))
		// console.log(`Built rarity list for ${ItemRarity[rarity]}`, AUGMENT_WEIGHTING_PER_RARITY_WEIGHTED_LIST.get(rarity))
	})
}
