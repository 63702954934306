import { getBiomeList } from '../../biome/shared/biome-list'
import { GameModeType } from '../../engine/shared/game-mode-type'
import { event } from 'vue-gtag'
import { biomeIndex, WorldTier } from '../../utils/primitive-types'

export function analyticsEventStartAdventure(worldTier: WorldTier, biomeIdx: biomeIndex, username: string, userId: string) {
	event('start_adventure', {
		selected_world_tier: worldTier,
		biome_index: biomeIdx,
		biome_name: getBiomeName(biomeIdx),
		user_name: username,
		user_id_num: userId,
	})
}

export function analyticsEventGeneralStorePurchase(productDetails, username, userId) {
	event('general_store_purchase', {
		item_purchased: productDetails[0].name,
		item_subtype: productDetails[0].itemSubType,
		item_type: productDetails[0].itemType,
		user_name: username,
		user_id_num: userId,
	})
}

export function analyticsEventPanelOpen(panelName, username, userId) {
	event('open_panel', {
		panel_opened: `open ${panelName}  panel`,
		user_name: username,
		user_id_num: userId,
	})
}

export function analyticsEventPanelClosed(panelName, username, userId) {
	event('close_panel', {
		panel_closed: `closed ${panelName} panel`,
		user_name: username,
		user_id_num: userId,
	})
}

export function analyticsEventStartAugment() {
	event('augment_tutorial_start', {})
}

export function analyticsEventFinishAugment() {
	event('augment_tutorial_finish', {})
}

export function analyticsEventAugmentTutorialStepCompleted(stepNum, stepName) {
	event(`augment_tutorial_step__complete`, {
		augment_tutorial_step_num: stepNum,
		augment_tutorial_step_name: stepName,
	})
}

export function analyticsEventWormMailSent(biome: biomeIndex, username: string, userId: string) {
	event(`worm_mail_sent`, {
		worm_biome: biome,
		user_name: username,
		user_id_num: userId,
	})
}

function getBiomeName(biomeIdx: biomeIndex) {
	const biomeList = getBiomeList(GameModeType.Adventure)
	return biomeList[biomeIdx].name
}

let webglContextLostCount = 0
export function analyticsWebglContextLost_SendOnce() {
	if (webglContextLostCount === 0) {
		webglContextLostCount++
		event('system_webgl_context_lost', {})
	}
}
