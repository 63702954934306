import logger from '../../utils/client-logger'
import { loadFromLocalStorage, saveToLocalStorage } from '../../utils/local-storage'
import { maxBy, map, property } from 'lodash'

export function getUsersLatestNewsViewed() {
	const userLatestNewsViewed = loadFromLocalStorage('last-viewed-article-id')

	if (userLatestNewsViewed === undefined) {
		saveToLocalStorage('last-viewed-article-id', '0')
		return '0'
	} else {
		return userLatestNewsViewed
	}
}

export function updateUsersLatestNewsViewed(articleId) {
	saveToLocalStorage('last-viewed-article-id', articleId)
}

interface GameNewsUIState {
	selectedGameNewsArticle: number
	gameNews: object
	latestArticleId: any
	lastReadArticleId: any
}

export const gameNewsUIState = () => {
	logger.debug('Initializing Game News UI module')

	return {
		namespaced: true,
		state: {
			selectedGameNewsArticle: null,
			gameNews: [],
			latestArticleId: null,
			lastReadArticleId: null,
		},
		getters: {
			getSelectedGameNewsArticle(state: GameNewsUIState) {
				return state.selectedGameNewsArticle
			},
			getGameNewsArticles(state) {
				return state.gameNews
			},
			getLatestArticleId(state) {
				return state.latestArticleId
			},
			getLastReadArticleId(state) {
				return state.lastReadArticleId
			},
		},
		mutations: {
			selectedArticleById(state: GameNewsUIState, articleId: number) {
				state.selectedGameNewsArticle = articleId
			},
			setGameNewsArticles(state, articles) {
				state.gameNews = articles
			},
			setLatestArticleId(state, id) {
				state.latestArticleId = id
			},
			setLastReadArticleId(state, id) {
				state.lastReadArticleId = id
			},
		},
		actions: {
			fetchGameNews({ commit }, url) {
				fetch(url)
					.then(async (response) => {
						const data = await response.json()
						const reverseData = [...data].reverse()
						const articleIds = map(reverseData, property('id'))
						const latestId = maxBy(articleIds).toString()
						const latestReadArticleId = getUsersLatestNewsViewed().toString()

						commit('setGameNewsArticles', reverseData)
						commit('setLatestArticleId', latestId)
						commit('setLastReadArticleId', latestReadArticleId)
					})
					.catch((error) => {
						console.log('Error processing data: ' + error)
					})
			},
			updatedLatestNewsRead({ commit, state }, articlePayload) {
				const currentlySelectedArticle = state.selectedGameNewsArticle
				const latestArticleId = state.latestArticleId
				const payLoadArticleId = articlePayload.toString()

				if (currentlySelectedArticle === articlePayload) {
					commit('selectedArticleById', null)
				} else {
					commit('selectedArticleById', articlePayload)
					if (payLoadArticleId === latestArticleId) {
						updateUsersLatestNewsViewed(latestArticleId)
						commit('setLastReadArticleId', latestArticleId)
					}
				}
			},
		},
	}
}
