import { NengiClient } from '../../engine/client/nengi-client'
import UnequipGearFromSlotCommand from '../../items/shared/unequip-gear-from-slot-command'
import logger from '../../utils/client-logger'
import { UI } from '../../ui/ui'

interface MiniPaperdollUIState {
	selectedMiniPaperdollIndex: number
	maxDPS: number
}

export const miniPaperdollUIState = () => {
	logger.debug('Initializing Mini Paperdoll UI module')
	return {
		namespaced: true,
		state: {
			selectedMiniPaperdollIndex: null,
			maxDPS: 0,
		},
		mutations: {
			updateMaxDPS(state:MiniPaperdollUIState, dps: number){
				state.maxDPS = dps
			}
		},
		getters: {
			selectedMiniPaperdollIndex(state: MiniPaperdollUIState) {
				return state.selectedMiniPaperdollIndex
			},
			miniPaperdollHasASelection(state: MiniPaperdollUIState) {
				return state.selectedMiniPaperdollIndex !== null
			},
			getMaxDps(){
				const weaponOne = UI.getInstance().store.getters['itemContainers/equippedWeapons'].one.statsWithAugments.maxDps
				const weaponTwo = UI.getInstance().store.getters['itemContainers/equippedWeapons'].two.statsWithAugments.maxDps
				return Math.max(weaponOne, weaponTwo).toString()
			}
		},
		actions: {
			selectMiniPaperdollIndex(context, index?: number) {
				const { state, commit, rootState } = context
				if ((index || index === 0) && index !== state.selectedMiniPaperdollIndex) {
					const itemsInSlots = [null, null, null, null, null]

					rootState.itemContainers?.paperdoll.forEach((i) => {
						itemsInSlots[i.paperdollSlotIndex] = i
					})

					const item = itemsInSlots[index]
					const itemId = itemsInSlots[index]?.id

					if (itemId && item && (item.itemType === 'Weapon' || item.itemType === 'Gear')) {
						commit('itemComparison/selectEquippedItem', itemId, { root: true })
						state.selectedMiniPaperdollIndex = index
					} else {
						commit('itemComparison/selectEquippedItem', null, { root: true })
						state.selectedMiniPaperdollIndex = index
					}
				} else {
					commit('itemComparison/deselectEquippedItem', {}, { root: true })
					state.selectedMiniPaperdollIndex = null
				}
			},
			unequipItemFromSlot(context, item) {
				NengiClient.getInstance().sendCommand(new UnequipGearFromSlotCommand(item.id))
			},
			resetMiniPaperdollIndex({ dispatch }) {
				dispatch('selectMiniPaperdollIndex', null)
			},
		},
	}
}
