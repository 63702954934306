















import TextButton from './text-button.vue'

export default {
    name: 'AmountSelector',
    components: {
        TextButton
    },
    props: {
        itemId:{ 
            type: String,
            required: false,
            default: ''
        },
        maxAmount: {
            type: Number,
            required: false,
            default: 999
        },
        minAmount: {
            type: Number,
            required: false,
            default: 1
        },
        onAmountChanged: {
            type: Function,
            required: true,
            default: (amount) => {
                console.log("Amount changed: " + amount)
            }
        },
        useHorizontalLayout: {
            type: Boolean,
            required: false,
            default: false,
        },
        emitChanges:{
            type:Boolean,
            required: false,
            default: false,
        }
    }, 
    data() {
        return {
            selectedAmount: 1,
            displayMode: (this.useHorizontalLayout ? undefined : 'flex')
        }
    },
    watch:{
        maxAmount: function(newValue){
            this.selectedAmount = this.minAmount
        }
    },
    methods: {
        onUpClicked() {
            if(this.selectedAmount < this.maxAmount) {
                this.selectedAmount++
                if(this.emitChanges){
                    this.$emit('stack-amount-change', {itemId: this.itemId, selectedAmount: this.selectedAmount})
                } else {
                    this.onAmountChanged(this.selectedAmount)
                }
            }
        },
        onDownClicked() {
            if(this.selectedAmount > this.minAmount) {
                this.selectedAmount--

                if(this.emitChanges){
                    this.$emit('stack-amount-change', {itemId: this.itemId, selectedAmount: this.selectedAmount})
                } else {
                    this.onAmountChanged(this.selectedAmount)
                }
            }
        },
        onAllClicked() {
            if(this.selectedAmount !== this.maxAmount) {
                this.selectedAmount = this.maxAmount

                if(this.emitChanges){
                    this.$emit('stack-amount-change', {itemId: this.itemId, selectedAmount: this.selectedAmount})
                } else {
                    this.onAmountChanged(this.selectedAmount)
                }
            }
        },
        onTextChange() {
            this.selectedAmount = parseInt(this.selectedAmount)
            
            if(isNaN(this.selectedAmount) || this.selectedAmount === "" || this.selectedAmount < this.minAmount) {
                this.selectedAmount = this.minAmount
                this.onAmountChanged(this.selectedAmount)
                return
            }

            if(this.selectedAmount > this.maxAmount) {
                this.selectedAmount = this.maxAmount
                this.onAmountChanged(this.selectedAmount)
                return
            }

            if(this.emitChanges){
                    this.$emit('stack-amount-change', {itemId: this.itemId, selectedAmount: this.selectedAmount})
            } else {
                    this.onAmountChanged(this.selectedAmount)
            }
        }
    },
}
