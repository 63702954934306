import nengi from 'nengi'

class PlayerRunEndDamageTakenMessage {
	static protocol = {
		killedBy: { type: nengi.String },

		physicalDamage: { type: nengi.Number },
		fireDamage: { type: nengi.Number },
		iceDamage: { type: nengi.Number },
		lightningDamage: { type: nengi.Number },
		poisonDamage: { type: nengi.Number },

		igniteDamage: { type: nengi.Number },
		poisonedDamage: { type: nengi.Number },

		shieldHitsTaken: { type: nengi.Number },
		totalHits: { type: nengi.Number },

		skill1Used: { type: nengi.Number },
		skill2Used: { type: nengi.Number },
		skill3Used: { type: nengi.Number },
	}

	killedBy: string

	physicalDamage: number
	fireDamage: number
	iceDamage: number
	lightningDamage: number
	poisonDamage: number

	igniteDamage: number
	poisonedDamage: number

	shieldHitsTaken: number
	totalHits: number

	skill1Used: number
	skill2Used: number
	skill3Used: number

	constructor(killedBy, physicalDamage, fireDamage, iceDamage, lightningDamage, poisonDamage, igniteDamage, poisonedDamage, shieldHitsTaken, totalHits, skill1, skill2, skill3) {
		this.killedBy = killedBy
		this.physicalDamage = Math.round(physicalDamage)
		this.fireDamage = Math.round(fireDamage)
		this.iceDamage = Math.round(iceDamage)
		this.lightningDamage = Math.round(lightningDamage)
		this.poisonDamage = Math.round(poisonDamage)
		this.igniteDamage = Math.round(igniteDamage)
		this.poisonedDamage = Math.round(poisonedDamage)
		this.shieldHitsTaken = shieldHitsTaken
		this.totalHits = totalHits
		this.skill1Used = skill1 ?? 0
		this.skill2Used = skill2 ?? 0
		this.skill3Used = skill3 ?? 0
	}
}

export default PlayerRunEndDamageTakenMessage
