import nengi from 'nengi'

class PlayerRunEndStatsMessage {
	static protocol = {
		runDuration: { type: nengi.Number },
		enemiesKilled: { type: nengi.Number },
		projectilesFired: { type: nengi.Number },
		beamDamageTicks: {type: nengi.Number },
		beamFiringTime: {type: nengi.Number },
		damageDealt: { type: nengi.Number },
		itemsLooted: { type: nengi.Number },
		wormItemsSent: { type: nengi.Number },
		damageTaken: { type: nengi.Number },
		bossesKilled: { type: nengi.Number },
		sumOfDamageDealt: { type: nengi.Number },
	}

	runDuration: number
	enemiesKilled: number
	projectilesFired: number
	beamDamageTicks: number
	beamFiringTime: number
	damageDealt: number
	itemsLooted: number
	wormItemsSent: number
	damageTaken: number
	bossesKilled: number
	sumOfDamageDealt: number

	constructor(runDuration, enemiesKilled, projectilesFired, beamDamageTicks, beamFiringTime, damageDealt, itemsLooted, wormItemsSent, damageTaken, bossesKilled, sumOfDamageDealt) {
		this.runDuration = runDuration
		this.enemiesKilled = enemiesKilled
		this.projectilesFired = projectilesFired
		this.beamDamageTicks = beamDamageTicks
		this.beamFiringTime = beamFiringTime
		this.damageDealt = damageDealt
		this.itemsLooted = itemsLooted
		this.wormItemsSent = wormItemsSent
		this.damageTaken = damageTaken
		this.bossesKilled = bossesKilled
		this.sumOfDamageDealt = sumOfDamageDealt
	}
}

export default PlayerRunEndStatsMessage
