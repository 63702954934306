import nengi from 'nengi'
import { gameUnits, nengiId, radians, timeInSeconds } from '../../utils/primitive-types'

export type BeamType = Omit<{ [K in keyof Beam]: Beam[K] }, 'nid'>
export class Beam {
	static protocol = {
		projectileId: { type: nengi.UInt32 },
		owningEntityId: { type: nengi.UInt16 },
		x: { type: nengi.Float32, interp: true },
		y: { type: nengi.Float32, interp: true },
		offsetX: { type: nengi.Float32, interp: true },
		offsetY: { type: nengi.Float32, interp: true },
		angle: { type: nengi.RotationFloat32, interp: true },
		length: { type: nengi.Float32, interp: true },
		width: { type: nengi.Float32 },
		maxLength: { type: nengi.Float32 },
		color: { type: nengi.UInt32 },
		timeSinceDamage: { type: nengi.Float32, interp: false },
	}

	nid: nengiId
	owningEntityId: nengiId
	projectileId: number
	x: gameUnits
	y: gameUnits
	offsetX: gameUnits
	offsetY: gameUnits
	angle: radians
	width: gameUnits
	length: gameUnits
	maxLength: gameUnits
	color: number
	timeSinceDamage: timeInSeconds
}
