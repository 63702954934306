import nengi from "nengi"
import { FactionShortName } from "./faction-data"

export default class SetFactionAffiliationCommand {
	static protocol = {
		faction: nengi.String
	}

	faction: FactionShortName

	constructor(faction: FactionShortName) {
		this.faction = faction
	}
}
