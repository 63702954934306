










































import { mapMutations, mapGetters } from 'vuex'
import ArrowButton from '../reusable-components/buttons/arrow-button.vue'
import MainPanel from '../reusable-components/panels/main-panel.vue'

export default {
	name: 'EmailVerification',
	components: {
		MainPanel,
		ArrowButton,
	},
	computed: {
		...mapGetters('emailVerification', ['verificationInProgress', 'verificationFailed', 'verificationSuccessful']),
	},
	methods: {
		...mapMutations('mainMenu', ['changeMainMenuScreen']),
		reloadToMainMenu() {
			window.location.href = '/'
		},
	},
}
