<template>
	<div id="health-bar">
		<div class="bar">
			<div id="health-bar-tutorial-tooltip-target" :style="{ position: 'relative', left: '150px' }"></div>
			<div class="background"></div>
			<div class="health-fill">
				<div class="fill" :style="{ width: `${health}%` }"></div>
				<div class="fill-flash" :class="{ flashing: isHealthFlashing, notflashing: !isHealthFlashing }" :style="{ width: `${health}%` }"></div>
			</div>
			<div class="trim">
				<div class="gold-trim-right"></div>
			</div>
		</div>
		<div class="heart"></div>
	</div>
</template>

<script lang="js">
import { mapGetters } from 'vuex'

export default {
	name: 'HealthBar',

	computed: {
		...mapGetters('hud', ['health', 'isHealthFlashing'])
	},
}
</script>

<style lang="less" scoped>
#health-bar {
	position: absolute;
	top: 30px;
	left: 30px;

	.heart {
		position: absolute;
		background-image: url('./assets/health-bar-heart.png');
		width: 117px;
		height: 105px;
		top: 0px;
		left: 0px;
	}

	.bar {
		position: absolute;
		top: 5px;
		left: 90px;

		.background {
			position: absolute;
			background-image: url('./assets/health-bar-bg.png');
			width: 381px;
			height: 50px;
			top: 5px;
			left: 0px;
		}

		.health-fill {
			position: relative;
			width: 362px;
			top: 9px;
			left: 5px;
			.fill {
				position: absolute;
				background: linear-gradient(to right, #e32222ff, #ff5151ff);
				box-shadow: 0px 0px 20px 0px rgb(255, 0, 85);
				transition: width 50ms linear;
				height: 42px;
			}
			.fill-flash {
				position: absolute;
				opacity: 0;
				background-image: linear-gradient(to right, #ffffff, #ffffff);
				box-shadow: 0px 0px 20px 0px rgb(255, 255, 255);
				transition: width 50ms linear;
				height: 42px;

				&.notflashing {
					transition: opacity 100ms;
					opacity: 0;
				}
				&.flashing {
					transition: opacity 100ms;
					opacity: 1;
				}
			}
		}

		.trim {
			position: absolute;
			background-image: url('./assets/health-bar-trim.png');
			width: 400px;
			height: 59px;

			.gold-trim-right {
				position: absolute;
				background-image: url('./assets/health-bar-embellishment.png');
				width: 45px;
				height: 62px;
				//transform: scaleX(-1);
				top: -1px;
				right: 17px;
			}
		}
	}
}
</style>
