










import { mapActions, mapGetters } from 'vuex'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import GameNewsItem from '../reusable-components/game-news/game-news-item.vue'

export default {
	name: 'GameNews',
	components: {
		MainPanel,
		GameNewsItem,
	},

	data() {
		return {
			//TODO2: update with proper URLS when ready
			gameNewsURL: 'https://raw.githubusercontent.com/BitfoxGames/Loot.io-news-release-notes/main/game-news.json',
		}
	},
	created() {
		this.fetchGameNews(this.gameNewsURL)
	},
	computed: {
		...mapGetters('newsArticles', ['getSelectedGameNewsArticle', 'getGameNewsArticles']),
	},
	methods: {
		...mapActions('inGame', ['closeActivePanel']),
		...mapActions('newsArticles', ['fetchGameNews', 'updatedLatestNewsRead']),
	},
}
