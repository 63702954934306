import logger from '../../utils/client-logger'
import nengi, { Client } from 'nengi'
import { UI } from '../../ui/ui'

export class NengiClient {
	static shutdown() {
		clearInterval(NengiClient.instance.pingBroadcastInterval)
		// @ts-ignore TODO2: this function does exist, fix nengi types
		NengiClient.instance.client.disconnect()
		// @ts-ignore TODO2: this function does exist, fix nengi types
		NengiClient.instance.client.removeAllListeners()
		NengiClient.instance = null
	}

	static getInstance(params?: any): NengiClient {
		if (!NengiClient.instance) {
			if (params === undefined) {
				throw new Error('No nengi params given to NengiClient getInstance(); aborting startup')
			} else {
				NengiClient.instance = new NengiClient(params.nengiConfig, params.interpDelay)
			}
		}

		return NengiClient.instance
	}
	private static instance: NengiClient
	private client: Client
	private pingBroadcastInterval: any

	private constructor(nengiConfig: any, interpDelay: number) {
		logger.debug('Initializing Nengi Client singleton...')
		this.client = new nengi.Client(nengiConfig, interpDelay)
	}

	getClient() {
		return this.client
	}

	listen(eventName: string, handler: (msg: any) => void) {
		this.client.on(eventName, handler)
	}

	onClose(closeHandler: any) {
		this.client.onClose(closeHandler)
	}

	onConnect(connectHandler: any) {
		NengiClient.instance.pingBroadcastInterval = setInterval(this.broadcastPing.bind(this), 10000)
		this.client.onConnect(connectHandler)
	}

	sendCommand(command: any) {
		this.client.addCommand(command)
	}

	broadcastPing() {
		// @ts-ignore
		UI.getInstance().emitEvent('hud/latestPing', this.client.averagePing)
	}

	connect(serverAddress: string, connectionPayload: any) {
		this.client.connect(serverAddress, connectionPayload)
		//@ts-expect-error
		this.client.websocket.onclose = (closeReason: CloseEvent) => {
			console.log(`CLOSED code: ${closeReason.code} reason: ${closeReason.reason} wasClean: ${closeReason.wasClean} eventPhase: ${closeReason.eventPhase}`)
			//@ts-expect-error
			if (this.client.connectionClose) {
				//@ts-expect-error
				this.client.connectionClose(closeReason)
			}
		}
	}

	readNetworkAndEmit() {
		this.client.readNetworkAndEmit()
	}

	disconnect() {
		// @ts-ignore TODO2: add this type to nengi, it does exist
		this.client.disconnect()
	}

	update() {
		this.client.update()
	}
}
