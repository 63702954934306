
























import { mapGetters } from 'vuex'
import CloseButton from '../buttons/close-button.vue'
import { maxEquipableLevel } from '../../../engine/shared/game-data/player'

export default {
	name: 'PaperdollPanel',
	components: {
		CloseButton,
	},
	props: {
		onCloseBtnClick: {
			type: Function,
			required: true,
			default: () => {
				console.warn('The default on-close-btn-click handler for the <PaperdollPanel /> component has been called. Did you forget to pass an :on-click handler to the component?')
			},
		},
	},
	computed: {
		...mapGetters('UIScale', ['currentScale']),
		...mapGetters('user', ['username']),
		...mapGetters('hud', ['progressionLevel']),
		level() {
			return maxEquipableLevel(this.progressionLevel)
		},
	},
}
