import nengi from 'nengi'

class ChatCommand {
	static protocol = {
		message: nengi.String,
		party: nengi.Boolean,
	}

	message: string
	party: boolean

	constructor(message, party = false) {
		this.message = message
		this.party = party
	}
}

export default ChatCommand
