import nengi from 'nengi'
import { EmoteType } from '../../chat/shared/emote-enums'

class PlayerEmoteCommand {
	static protocol = {
		emoteId: nengi.Number
	}
	emoteId: number

	constructor(emoteId: EmoteType) {
		this.emoteId = emoteId
	}
}

export default PlayerEmoteCommand
