import nengi from 'nengi'

class UpdatePartyPositionsMessage {
	static protocol = {
		xPositions: { type: nengi.Number, indexType: nengi.UInt16 },
		yPositions: { type: nengi.Number, indexType: nengi.UInt16 },
	}

	xPositions: number[]
	yPositions: number[]

	constructor(xPositions: number[], yPositions: number[]) {
		this.xPositions = xPositions
		this.yPositions = yPositions
	}
}

export default UpdatePartyPositionsMessage
