





























import { mapActions, mapGetters } from 'vuex'
import { gameModeConfig } from '../../engine/shared/game-mode-configs'
import { GameModeType } from '../../engine/shared/game-mode-type'
import { readyWithInvalidPressureTooltip } from '../reusable-components/v-tooltip/v-tooltip-functions'
import { chatSize } from '../state/chat.ui-state'
import { isOnSufficientDifficultyToProgress } from '../../engine/shared/game-data/progression'

export default {
	name: 'PartyMemberList',
	components: {},
	props: {},
	data() {
		return {
			chatSize: chatSize,
		}
	},
	computed: {
		...mapGetters('user', ['userId']),
		...mapGetters('party', ['getCurrentlyPartiedStatus', 'getPartyMembers']),
		...mapGetters('inGame', ['currentGameMode']),
		...mapGetters('UIScale', ['currentScale']),
		...mapGetters('boatLaunch', ['totalPointsForPreviousWorldTier', 'isValidPressureLoadout']),
		...mapGetters('chat', ['chatVisible']),
		...mapGetters('boatLaunch', ['isValidPressureLoadout', 'maxAllowedTier', 'currentlySelectedWorldDifficulty', 'isMeterFull']),
		sortPartyByRole() {
			return [...this.getPartyMembers.filter(({ leader }) => leader), ...this.getPartyMembers.filter(({ leader }) => !leader)]
		},
	},
	methods: {
		...mapActions('party', ['setReady', 'setUnready']),
		readyWithInvalidPressureTooltip,
		toggleReady(playerId, currentReadyState) {
			if (playerId === this.userId) {
				if (currentReadyState) {
					this.setUnready()
				} else {
					if (this.isValidPressureLoadout) {
						this.setReady({canProgress: this.isMeterFull && isOnSufficientDifficultyToProgress(this.currentlySelectedWorldDifficulty, this.maxAllowedTier) })
					}
				}
			}
		},
		getPlayerClass(player) {
			if(player.status === 'hub' && gameModeConfig.type === GameModeType.Adventure) {
				return 'hub'
			} else {
				return player.disconnected ? 'disconnected' : player.dead ? 'dead' : player.leader ? 'leader' : 'member'
			}
		}
	},
}
