










































































































































































import { mapGetters, mapState } from 'vuex'
import PanelDivider from '../../reusable-components/panel-divider/panel-divider.vue'
import { FactionShortName, factionActions, factionBonus, FactionShortNameToFullName, factionMembers, factionFlagUrl } from '../../../factions/shared/faction-data'

const flagImages = require('../assets/*faction-flags.png')
const factionBtm = require('../assets/*faction-bottom-detail.png')

export default {
	name: 'Factions',
	data() {
		return {
			FactionShortName,
			flagImages,
			factionBtm,
		}
	},
	components: {
		PanelDivider,
	},
	props: {},
	computed: {
		...mapState('factions', ['currentRecruitPanel', 'affiliatedFaction']),
		...mapGetters('factions', ['factionTotalPoints', 'factionMemberCounts', 'currentlyWinningFaction', 'factionMemberCountsPercentage']),
		...mapGetters('i18n', ['t']),
		factionFullName() {
			return FactionShortNameToFullName.get(this.affiliatedFaction)
		},
		factionTextColor() {
			switch (this.affiliatedFaction) {
				case FactionShortName.ORDER_OF_IRON:
					return `#ff0000`
				case FactionShortName.AURUM_ALLIANCE:
					return `#9170ac`
				case FactionShortName.SCIONS_OF_DAWN:
					return `#5cbbff`
			}
		},
		factionDepartureBlurb() {
			switch (this.affiliatedFaction) {
				case FactionShortName.ORDER_OF_IRON:
					return this.t('ironRecruitDepartureBlurb')
				case FactionShortName.AURUM_ALLIANCE:
					return this.t('aurumRecruitDepartureBlurb')
				case FactionShortName.SCIONS_OF_DAWN:
					return this.t('dawnRecruitDepartureBlurb')
			}

			return 'Something went wrong'
		},
		factionLeaderName() {
			switch (this.affiliatedFaction) {
				case FactionShortName.ORDER_OF_IRON:
					return "Re'hkosh"
				case FactionShortName.AURUM_ALLIANCE:
					return "Blackmark"
				case FactionShortName.SCIONS_OF_DAWN:
					return "Solara"
			}
		},
	},
	methods: {
		factionActions,
		factionBonus,
		factionMembers,
		factionFlagUrl,
		factionPoints(factionName) {
			return this.factionTotalPoints[factionName]
		},
	},
}
