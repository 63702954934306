import nengi from 'nengi'
import ItemType, { ItemTypePrettyName } from '../../loot/shared/item-type'
import ItemRarity, { ItemRarityPrettyName } from '../../loot/shared/item-rarity'
import { jsonString } from '../../utils/primitive-types'
// TODO2 push cost calculation out of the shared module
import { getIdentifyCost } from './calculate-identify-cost'
// TODO2 push cost calculation out of the shared module
import { costToAdjustAugment } from '../../utils/economy-data'
import moment from 'moment'

export type ContainerType = 'pit_rewards' | 'stash' | 'inventory' | 'wormMail' | 'worm_delivery' | 'wormDelivery' | 'wormMailInFlight' | 'furnace' | 'embedded' | 'paperdoll' | 'pitStorage' | 'pitRewards' | 'rewards' | 'enchantments' | 'itemListings' | 'inventoryBadgeList' | 'usersListings' | 'pendingMarketplace'

class SendItemContainerMessage {
	static protocol = {
		containerType: nengi.String,
		containerId: nengi.Number,
		containerMessage: nengi.String,

		items_biome: { type: nengi.Number, indexType: nengi.UInt16 },
		items_biome_tier: { type: nengi.Number, indexType: nengi.UInt16 },

		items_id: { type: nengi.String, indexType: nengi.UInt16 },
		items_level: { type: nengi.Number, indexType: nengi.UInt16 },
		items_unidentified: { type: nengi.Boolean, indexType: nengi.UInt16 },
		items_identify_cost: { type: nengi.Number, indexType: nengi.UInt16 },
		items_augment_cost: { type: nengi.Number, indexType: nengi.UInt16 },

		items_slotIndex: { type: nengi.Number, indexType: nengi.UInt16 },
		items_paperdollSlotIndex: { type: nengi.Number, indexType: nengi.UInt16 },
		items_itemType: { type: nengi.UInt16, indexType: nengi.UInt16 },
		items_itemSubType: { type: nengi.UInt16, indexType: nengi.UInt16 },
		items_name: { type: nengi.String, indexType: nengi.UInt16 },
		items_rarity: { type: nengi.UInt16, indexType: nengi.UInt16 },
		//items_tooltip: { type: nengi.String, indexType: nengi.UInt16 },
		items_stats: { type: nengi.String, indexType: nengi.UInt16 },
		items_statsWithAugments: { type: nengi.String, indexType: nengi.UInt16 },
		items_attuned: { type: nengi.Boolean, indexType: nengi.UInt16 },

		items_component1: { type: nengi.UInt16, indexType: nengi.UInt16 },
		items_component2: { type: nengi.UInt16, indexType: nengi.UInt16 },
		items_component3: { type: nengi.UInt16, indexType: nengi.UInt16 },

		items_augmentLimit: { type: nengi.UInt16, indexType: nengi.UInt16 },
		items_equippedAugmentIds: { type: nengi.String, indexType: nengi.UInt16 },

		items_extraData: { type: nengi.String, indexType: nengi.UInt16 },

		items_createdAt: { type: nengi.String, indexType: nengi.UInt16 },

		items_stackSize: { type: nengi.UInt16, indexType: nengi.UInt16 },
	}

	containerType: string
	containerId: number
	containerMessage: string
	items_id: string[]
	items_level: number[]
	items_unidentified: boolean[]
	items_identify_cost: number[]

	items_biome: number[]
	items_biome_tier: number[]

	items_slotIndex: number[]
	items_paperdollSlotIndex: number[]
	items_itemType: ItemType[]
	items_itemSubType: number[]
	items_name: string[]
	items_rarity: ItemRarity[]
	//items_tooltip: string[]
	items_stats: string[]
	items_statsWithAugments: string[]
	items_attuned: boolean[]

	items_component1: number[]
	items_component2: number[]
	items_component3: number[]

	items_augmentLimit: number[]
	items_augment_cost: number[]
	items_equippedAugmentIds: jsonString[]

	items_extraData: jsonString[]

	items_createdAt: jsonString[]

	items_stackSize: number[]

	// can change this to | items: Item[] | while coding, but don't import server item into shared message
	constructor(containerType: ContainerType, items: any[], containerId?: number, containerMessage?: string) {
		this.containerType = containerType
		this.containerId = containerId || -1
		this.containerMessage = containerMessage || ''
		let slotIndex = 0
		this.items_slotIndex = []
		this.items_paperdollSlotIndex = []
		this.items_id = []
		this.items_level = []
		this.items_unidentified = []
		this.items_identify_cost = []
		this.items_augment_cost = []
		this.items_biome = []
		this.items_biome_tier = []
		this.items_itemType = []
		this.items_itemSubType = []
		this.items_name = []
		this.items_rarity = []
		this.items_stats = []
		this.items_statsWithAugments = []
		this.items_attuned = []
		this.items_component1 = []
		this.items_component2 = []
		this.items_component3 = []
		this.items_augmentLimit = []
		this.items_equippedAugmentIds = []
		this.items_extraData = []
		this.items_createdAt = []
		this.items_stackSize = []

		for (const item of items) {
			this.items_slotIndex.push(slotIndex++)
			this.items_paperdollSlotIndex.push(item.getPaperdollSlot())
			this.items_id.push(item.id)
			this.items_level.push(item.level)
			this.items_unidentified.push(item.unidentified)
			this.items_identify_cost.push(getIdentifyCost(item.rarity, item.level))
			this.items_augment_cost.push(costToAdjustAugment(item))

			this.items_biome.push(item.biome)
			this.items_biome_tier.push(item.biomeTier)

			this.items_itemType.push(item.itemType)
			this.items_itemSubType.push(item.itemSubType)
			this.items_name.push(item.getPrettyName())

			this.items_rarity.push(item.rarity)
			//this.items_tooltip.push(item.prettyPrint())
			if (item.unidentified) {
				this.items_stats.push('{}')
			} else {
				this.items_stats.push(JSON.stringify(item.getStatsJson(false)))
			}

			if (item.unidentified) {
				this.items_statsWithAugments.push('{}')
			} else {
				this.items_statsWithAugments.push(JSON.stringify(item.getStatsJson(true)))
			}

			this.items_attuned.push(item.attuned)

			this.items_component1.push(item.component1)
			this.items_component2.push(item.component2)
			this.items_component3.push(item.component3)

			// We can redact this for unidentified weapons when augment slot addition is in
			this.items_augmentLimit.push(item.augmentLimit)

			const augIds = item.equippedAugments.map((aug) => aug.id)
			this.items_equippedAugmentIds.push(JSON.stringify(augIds))
			if (item.itemType === ItemType.Prize) {
				this.items_extraData.push(JSON.stringify(item.prizeData))
			} else {
				this.items_extraData.push('')
			}

			this.items_createdAt.push(item.createdAt?.toISOString() || '')

			this.items_stackSize.push(item.stackAmount || 0)
		}
	}
}

export function assembleItemFromMessageIndex(message: SendItemContainerMessage, index) {
	return {
		id: message.items_id[index],
		level: message.items_level[index],
		unidentified: message.items_unidentified[index],
		identifyCost: message.items_identify_cost[index],
		slotIndex: message.items_slotIndex[index],
		paperdollSlotIndex: message.items_paperdollSlotIndex[index],
		itemType: ItemTypePrettyName.get(message.items_itemType[index]).toProperCase(),
		itemTypeEnum: message.items_itemType[index],
		itemSubType: message.items_itemSubType[index],
		name: message.items_name[index],
		rarity: ItemRarityPrettyName.get(message.items_rarity[index]).toProperCase(),
		rarityEnum: message.items_rarity[index],
		//tooltip: message.items_tooltip[index].replace(/\n/g, '<br>'),
		stats: JSON.parse(message.items_stats[index]),
		statsWithAugments: JSON.parse(message.items_statsWithAugments[index]),
		biome: message.items_biome[index],
		biomeTier: message.items_biome_tier[index],
		augmentCost: message.items_augment_cost[index],
		augmentLimit: message.items_augmentLimit[index],
		equippedAugmentIds: JSON.parse(message.items_equippedAugmentIds[index]),
		//equippedEnchantmentIds: JSON.parse(message.items_equippedEnchantmentIds[index]),
		component1: message.items_component1[index],
		component2: message.items_component2[index],
		component3: message.items_component3[index],
		attuned: message.items_attuned[index],
		extraData: message.items_extraData[index].length > 0 ? JSON.parse(message.items_extraData[index]) : null,
		createdAt: message.items_createdAt[index].length > 0 ? moment(message.items_createdAt[index]).utc() : null,
		stackAmount: message.items_stackSize[index] > 0 ? message.items_stackSize[index] : undefined
	}
}

export default SendItemContainerMessage
