import nengi from 'nengi'
import { ModCategory } from '../../engine/shared/game-data/stat-type-mod-category'
import StatModPoolType from '../../loot/shared/stat-mod-pool-type'

class SendItemModsMessage {
	static protocol = {
        poolType: nengi.Number,
		itemModSet: { type: nengi.Number, indexType: nengi.UInt16 },
	}
	poolType: number
    itemModSet: number[]
	
	constructor(poolType: StatModPoolType, itemMods: Set<ModCategory>) {
        this.poolType = poolType
		this.itemModSet = Array.from(itemMods)
	}
}
export function assembleModSetFromMessageIndex(message: SendItemModsMessage, index) {
	return {
		itemMods: message.itemModSet[index],
	}
}

export default SendItemModsMessage
