<template>
	<div class="free-market-container">
		<div class="free-market-border-image">
			<div id="free-market-notifier"  class="notification-body" @click="setActivePanel('marketplaceUpdated')">
				<h3>1 free marketplace listing!</h3>
			</div>
		</div>
	</div>
</template>

<script lang="js">
import { mapGetters, mapMutations } from 'vuex'
export default {
	name: 'FreeMarketplaceNotifier',
	computed: {
		...mapGetters('itemContainers', ['numberOfItemsInContainer']),
		...mapGetters('UIScale', ['currentScale']),
	},
	methods:{
		...mapMutations('inGame', ['setActivePanel']),
	}
}
</script>

<style lang="less">
@import url('../global/fonts/text.less');
#free-market-notifier {
	display: flex;
	pointer-events: all;
	cursor: pointer;
	perspective: 1px;
	h3 {
		color: rgb(233, 229, 173);
		margin: 0;
		padding: 0;
	}

	.notif-hud-text();
}
#market-image {
}

.free-market-container {

	
}

.free-market-border-image {
		height: 65px;
		justify-content: center;
		align-items: center;
		perspective: 1px;
		border-image-slice: 4 38 9 6 fill;
		border-image-width: 4px 38px 9px 6px;
		border-image-outset: 0px 0px 0px 0px;
		border-image-repeat: repeat stretch;
		border-image-source: url('../hud/assets/toast-generic.png');
	}

</style>
