import { EnemyAI, FightingTriggers, IdleBehaviours, FightingBehaviours, LeashTriggers, LeashingBehaviours, AttackTypes, DeadBehaviours, ShotLeadPrecision, EnemyType, EnemyTargetingStyles } from '../../shared/ai-types'
import { SpineDataName, EnemyBipedSkinType } from '../../../models-animations/shared/spine-config'
import { EnemyDefaults } from './enemy-defaults'
import { ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { bossEnemyDamageConfig, BOSS_HEALTH_MULTIPLIER, BOSS_LOOT_DROP_QUANTITY, BOSS_LOOT_DROP_RARITY, ENEMY_DEFAULT_HEALTH } from '../../../engine/shared/game-data/enemy-formulas'
import { alwaysTrueCriteria, NormalDifficultyCriteria, HardDifficultyCriteria, BrutalDifficultyCriteria, playAnimationAbility } from './abilities/common-abilities'
import { AbilityOptionallyWithWeightOrTiming, AbilitySelectionStyles, CriteriaSatisfactionQuantity, CriteriaTarget, CriteriaType } from '../action-types'
import {
	cooldownEnemyCooldown15000,
	idlyMoveToPositionAbility,
	healingSpringAbilityEasy,
	moveToPondPositionAbilityEasy,
	moveToRandomPositionAbilityEasy,
	moveToRandomPositionFastAbilityEasy,
	novaAttack360AbilityEasy,
	novaAttack360AbilityV2Easy,
	novaAttack360AbilityV3Easy,
	novaAttackSpreadAbilityEasy,
	novaAttackSpreadAbilityV2Easy,
	novaAttackSpreadAbilityV3Easy,
	novaAttackSweepAbilityEasy,
	novaAttackSweepAbilityV2Easy,
	novaAttackSweepAbilityV3Easy,
	shotSprayAttackAbilityEasy,
	shotSprayAttackAbilityV2Easy,
	shotSprayAttackAbilityV3Easy,
	// line break for ease of reading.
	healingSpringAbilityMedium,
	moveToPondPositionAbilityMedium,
	moveToRandomPositionAbilityMedium,
	moveToRandomPositionFastAbilityMedium,
	novaAttack360AbilityMedium,
	novaAttack360AbilityV2Medium,
	novaAttack360AbilityV3Medium,
	novaAttackSpreadAbilityMedium,
	novaAttackSpreadAbilityV2Medium,
	novaAttackSpreadAbilityV3Medium,
	novaAttackSweepAbilityMedium,
	novaAttackSweepAbilityV2Medium,
	novaAttackSweepAbilityV3Medium,
	shotSprayAttackAbilityMedium,
	shotSprayAttackAbilityV2Medium,
	shotSprayAttackAbilityV3Medium,
	// line break for ease of reading
	healingSpringAbilityBrutal,
	moveToPondPositionAbilityBrutal,
	moveToRandomPositionAbilityBrutal,
	moveToRandomPositionFastAbilityBrutal,
	novaAttack360AbilityBrutal,
	novaAttack360AbilityV2Brutal,
	novaAttack360AbilityV3Brutal,
	novaAttackSpreadAbilityBrutal,
	novaAttackSpreadAbilityV2Brutal,
	novaAttackSpreadAbilityV3Brutal,
	novaAttackSweepAbilityBrutal,
	novaAttackSweepAbilityV2Brutal,
	novaAttackSweepAbilityV3Brutal,
	shotSprayAttackAbilityBrutal,
	shotSprayAttackAbilityV2Brutal,
	shotSprayAttackAbilityV3Brutal,
	// line break for ease of reading
	// healingSpringAbilityNasty,
	// moveToRandomPositionAbilityNasty,
	// moveToRandomPositionFastAbilityNasty,
	// moveToPondPositionAbilityNasty,
	// novaAttack360AbilityNasty,
	// novaAttack360AbilityV2Nasty,
	// novaAttack360AbilityV3Nasty,
	// novaAttackSpreadAbilityNasty,
	// novaAttackSpreadAbilityV2Nasty,
	// novaAttackSpreadAbilityV3Nasty,
	// novaAttackSweepAbilityNasty,
	// novaAttackSweepAbilityV2Nasty,
	// novaAttackSweepAbilityV3Nasty,
	// shotSprayAttackAbilityNasty,
	// shotSprayAttackAbilityV2Nasty,
	// shotSprayAttackAbilityV3Nasty,
	//
} from './abilities/forest-boss-abilities'
import { AnimationTrack } from '../../../models-animations/shared/animation-track'
import { Vector } from 'sat'
import { BuffIdentifier } from '../../../buffs/shared/buff.shared'
import { ENEMY_NAMES } from './enemy-names'

const PHASE_1_2_HEALTH_BOUNDARY = 60
const PHASE_2_3_HEALTH_BOUNDARY = 30
const EASY_TIME_MULTI = (weight): number => { return Math.ceil(weight * 1.8) }
const EASY_TIME_COOLDOWN_MULTI = (weight): number => { return Math.ceil(weight * 2.5) }

// create some reusable ability sets so we have less copy-paste below



const BRUTAL_PROJECTILE_ATTACKS_V1: AbilityOptionallyWithWeightOrTiming[] = [
	[2, moveToRandomPositionAbilityBrutal, playAnimationAbility(AnimationTrack.ATTACK_360), novaAttack360AbilityBrutal, 16, novaAttackSpreadAbilityBrutal, 16, novaAttackSpreadAbilityBrutal, 16],
	[2, moveToRandomPositionAbilityBrutal, playAnimationAbility(AnimationTrack.ATTACK_360), novaAttack360AbilityBrutal, 8, novaAttackSpreadAbilityBrutal, 8, novaAttackSweepAbilityBrutal, 40],
	[2, moveToRandomPositionAbilityBrutal, playAnimationAbility(AnimationTrack.ATTACK_360), novaAttack360AbilityBrutal, novaAttackSweepAbilityBrutal, 32, novaAttackSweepAbilityBrutal, 32],
	[6, moveToRandomPositionFastAbilityBrutal, playAnimationAbility(AnimationTrack.POINT_ATTACK), shotSprayAttackAbilityBrutal, 4, shotSprayAttackAbilityBrutal, 4, shotSprayAttackAbilityBrutal, 24],
]
const BRUTAL_PROJECTILE_ATTACKS_V2: AbilityOptionallyWithWeightOrTiming[] = [
	[2, moveToRandomPositionAbilityBrutal, playAnimationAbility(AnimationTrack.ATTACK_360), novaAttack360AbilityV2Brutal, 16, novaAttackSpreadAbilityV2Brutal, 16, novaAttackSpreadAbilityV2Brutal, 16],
	[2, moveToRandomPositionAbilityBrutal, playAnimationAbility(AnimationTrack.ATTACK_360), novaAttack360AbilityV2Brutal, 8, novaAttackSpreadAbilityV2Brutal, 8, novaAttackSweepAbilityV2Brutal, 40],
	[2, moveToRandomPositionAbilityBrutal, playAnimationAbility(AnimationTrack.ATTACK_360), novaAttack360AbilityV2Brutal, novaAttackSweepAbilityV2Brutal, 32, novaAttackSweepAbilityV2Brutal, 32],
	[6, moveToRandomPositionFastAbilityBrutal, playAnimationAbility(AnimationTrack.POINT_ATTACK), shotSprayAttackAbilityBrutal, 4, shotSprayAttackAbilityBrutal, 4, shotSprayAttackAbilityV2Brutal, 24],
]
const BRUTAL_PROJECTILE_ATTACKS_V3: AbilityOptionallyWithWeightOrTiming[] = [
	[2, moveToRandomPositionAbilityBrutal, playAnimationAbility(AnimationTrack.ATTACK_360), novaAttack360AbilityV3Brutal, 16, novaAttackSpreadAbilityV3Brutal, 16, novaAttackSpreadAbilityV3Brutal, 16],
	[2, moveToRandomPositionAbilityBrutal, playAnimationAbility(AnimationTrack.ATTACK_360), novaAttack360AbilityV3Brutal, 8, novaAttackSpreadAbilityV3Brutal, 8, novaAttackSweepAbilityV3Brutal, 40],
	[2, moveToRandomPositionAbilityBrutal, playAnimationAbility(AnimationTrack.ATTACK_360), novaAttack360AbilityV3Brutal, novaAttackSweepAbilityV3Brutal, 32, novaAttackSweepAbilityV3Brutal, 32],
	[6, moveToRandomPositionFastAbilityBrutal, playAnimationAbility(AnimationTrack.POINT_ATTACK), shotSprayAttackAbilityBrutal, 4, shotSprayAttackAbilityBrutal, 4, shotSprayAttackAbilityV3Brutal, 24],
]


const MEDIUM_PROJECTILE_ATTACKS_V1: AbilityOptionallyWithWeightOrTiming[] = [
	[2, moveToRandomPositionAbilityMedium, playAnimationAbility(AnimationTrack.ATTACK_360), novaAttack360AbilityMedium, 16, novaAttackSpreadAbilityMedium, 16, novaAttackSpreadAbilityMedium, 16],
	[2, moveToRandomPositionAbilityMedium, playAnimationAbility(AnimationTrack.ATTACK_360), novaAttack360AbilityMedium, 8, novaAttackSpreadAbilityMedium, 8, novaAttackSweepAbilityMedium, 40],
	[2, moveToRandomPositionAbilityMedium, playAnimationAbility(AnimationTrack.ATTACK_360), novaAttack360AbilityMedium, novaAttackSweepAbilityMedium, 32, novaAttackSweepAbilityMedium, 32],
	[6, moveToRandomPositionFastAbilityMedium, playAnimationAbility(AnimationTrack.POINT_ATTACK), shotSprayAttackAbilityMedium, 4, shotSprayAttackAbilityMedium, 4, shotSprayAttackAbilityMedium, 24],
]
const MEDIUM_PROJECTILE_ATTACKS_V2: AbilityOptionallyWithWeightOrTiming[] = [
	[2, moveToRandomPositionAbilityMedium, playAnimationAbility(AnimationTrack.ATTACK_360), novaAttack360AbilityV2Medium, 16, novaAttackSpreadAbilityV2Medium, 16, novaAttackSpreadAbilityV2Medium, 16],
	[2, moveToRandomPositionAbilityMedium, playAnimationAbility(AnimationTrack.ATTACK_360), novaAttack360AbilityV2Medium, 8, novaAttackSpreadAbilityV2Medium, 8, novaAttackSweepAbilityV2Medium, 40],
	[2, moveToRandomPositionAbilityMedium, playAnimationAbility(AnimationTrack.ATTACK_360), novaAttack360AbilityV2Medium, novaAttackSweepAbilityV2Medium, 32, novaAttackSweepAbilityV2Medium, 32],
	[6, moveToRandomPositionFastAbilityMedium, playAnimationAbility(AnimationTrack.POINT_ATTACK), shotSprayAttackAbilityMedium, 4, shotSprayAttackAbilityMedium, 4, shotSprayAttackAbilityV2Medium, 24],
]
const MEDIUM_PROJECTILE_ATTACKS_V3: AbilityOptionallyWithWeightOrTiming[] = [
	[2, moveToRandomPositionAbilityMedium, playAnimationAbility(AnimationTrack.ATTACK_360), novaAttack360AbilityV3Medium, 16, novaAttackSpreadAbilityV3Medium, 16, novaAttackSpreadAbilityV3Medium, 16],
	[2, moveToRandomPositionAbilityMedium, playAnimationAbility(AnimationTrack.ATTACK_360), novaAttack360AbilityV3Medium, 8, novaAttackSpreadAbilityV3Medium, 8, novaAttackSweepAbilityV3Medium, 40],
	[2, moveToRandomPositionAbilityMedium, playAnimationAbility(AnimationTrack.ATTACK_360), novaAttack360AbilityV3Medium, novaAttackSweepAbilityV3Medium, 32, novaAttackSweepAbilityV3Medium, 32],
	[6, moveToRandomPositionFastAbilityMedium, playAnimationAbility(AnimationTrack.POINT_ATTACK), shotSprayAttackAbilityMedium, 4, shotSprayAttackAbilityMedium, 4, shotSprayAttackAbilityV3Medium, 24],
]

const EASY_PROJECTILE_ATTACKS_V1: AbilityOptionallyWithWeightOrTiming[] = [
	[2, moveToRandomPositionAbilityEasy, playAnimationAbility(AnimationTrack.ATTACK_360), novaAttack360AbilityEasy, EASY_TIME_MULTI(16), novaAttackSpreadAbilityEasy, EASY_TIME_MULTI(16), novaAttackSpreadAbilityEasy, EASY_TIME_COOLDOWN_MULTI(16)],
	[2, moveToRandomPositionAbilityEasy, playAnimationAbility(AnimationTrack.ATTACK_360), novaAttack360AbilityEasy, EASY_TIME_MULTI(8), novaAttackSpreadAbilityEasy, EASY_TIME_MULTI(8), novaAttackSweepAbilityEasy, EASY_TIME_COOLDOWN_MULTI(40)],
	[2, moveToRandomPositionAbilityEasy, playAnimationAbility(AnimationTrack.ATTACK_360), novaAttack360AbilityEasy, novaAttackSweepAbilityEasy, EASY_TIME_MULTI(32), novaAttackSweepAbilityEasy, EASY_TIME_COOLDOWN_MULTI(32)],
	[6, moveToRandomPositionFastAbilityEasy, playAnimationAbility(AnimationTrack.POINT_ATTACK), shotSprayAttackAbilityEasy, 10, shotSprayAttackAbilityEasy, 10, shotSprayAttackAbilityEasy, EASY_TIME_COOLDOWN_MULTI(24)],
]
const EASY_PROJECTILE_ATTACKS_V2: AbilityOptionallyWithWeightOrTiming[] = [
	[2, moveToRandomPositionAbilityEasy, playAnimationAbility(AnimationTrack.ATTACK_360), novaAttack360AbilityV2Easy, EASY_TIME_MULTI(16), novaAttackSpreadAbilityV2Easy, EASY_TIME_MULTI(16), novaAttackSpreadAbilityV2Easy, EASY_TIME_COOLDOWN_MULTI(16)],
	[2, moveToRandomPositionAbilityEasy, playAnimationAbility(AnimationTrack.ATTACK_360), novaAttack360AbilityV2Easy, EASY_TIME_MULTI(8), novaAttackSpreadAbilityV2Easy, EASY_TIME_MULTI(8), novaAttackSweepAbilityV2Easy, EASY_TIME_COOLDOWN_MULTI(40)],
	[2, moveToRandomPositionAbilityEasy, playAnimationAbility(AnimationTrack.ATTACK_360), novaAttack360AbilityV2Easy, novaAttackSweepAbilityV2Easy, EASY_TIME_MULTI(32), novaAttackSweepAbilityV2Easy, EASY_TIME_COOLDOWN_MULTI(32)],
	[6, moveToRandomPositionFastAbilityEasy, playAnimationAbility(AnimationTrack.POINT_ATTACK), shotSprayAttackAbilityEasy, 8, shotSprayAttackAbilityEasy, 8, shotSprayAttackAbilityV2Easy, EASY_TIME_COOLDOWN_MULTI(24)],
]
const EASY_PROJECTILE_ATTACKS_V3: AbilityOptionallyWithWeightOrTiming[] = [
	[2, moveToRandomPositionAbilityEasy, playAnimationAbility(AnimationTrack.ATTACK_360), novaAttack360AbilityV3Easy, EASY_TIME_MULTI(16), novaAttackSpreadAbilityV3Easy, EASY_TIME_MULTI(16), novaAttackSpreadAbilityV3Easy, EASY_TIME_COOLDOWN_MULTI(16)],
	[2, moveToRandomPositionAbilityEasy, playAnimationAbility(AnimationTrack.ATTACK_360), novaAttack360AbilityV3Easy, EASY_TIME_MULTI(8), novaAttackSpreadAbilityV3Easy, EASY_TIME_MULTI(8), novaAttackSweepAbilityV3Easy, EASY_TIME_COOLDOWN_MULTI(40)],
	[2, moveToRandomPositionAbilityEasy, playAnimationAbility(AnimationTrack.ATTACK_360), novaAttack360AbilityV3Easy, novaAttackSweepAbilityV3Easy, EASY_TIME_MULTI(32), novaAttackSweepAbilityV3Easy, EASY_TIME_COOLDOWN_MULTI(32)],
	[6, moveToRandomPositionFastAbilityEasy, playAnimationAbility(AnimationTrack.POINT_ATTACK), shotSprayAttackAbilityEasy, EASY_TIME_MULTI(4), shotSprayAttackAbilityEasy, EASY_TIME_MULTI(4), shotSprayAttackAbilityV3Easy, EASY_TIME_COOLDOWN_MULTI(24)],
]



const boss: EnemyAI = {
	actionDrivenEnemyData: {
		targetColliders: [
			{ name: 'pond', colliders: [{ type: ColliderType.Circle, traits: ColliderTraits.Trigger, position: [-24, -382], radius: 10 }] },
			{ name: 'center', colliders: [{ type: ColliderType.Circle, traits: ColliderTraits.Trigger, position: [25, 243], radius: 10 }] },
			{ name: 'northwest', colliders: [{ type: ColliderType.Circle, traits: ColliderTraits.Trigger, position: [-680, 38], radius: 10 }] },
			{ name: 'southwest', colliders: [{ type: ColliderType.Circle, traits: ColliderTraits.Trigger, position: [-495, 535], radius: 10 }] },
			{ name: 'south', colliders: [{ type: ColliderType.Circle, traits: ColliderTraits.Trigger, position: [282, 645], radius: 10 }] },
			{ name: 'east', colliders: [{ type: ColliderType.Circle, traits: ColliderTraits.Trigger, position: [639, 251], radius: 10 }] },
			{ name: 'northeast', colliders: [{ type: ColliderType.Circle, traits: ColliderTraits.Trigger, position: [651, -105], radius: 10 }] },
		],

		engagementColliders: [{ type: ColliderType.Ellipse, traits: ColliderTraits.Trigger, position: [-23, 57], rX: 1461, rY: 1146 }],
		resetOnDisengage: true,

		unengagedActions: [
			// just sort of fly around aimlessly
			{
				priority: 0,
				actionCriteria: alwaysTrueCriteria,
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
					abilityOptions: [[1, idlyMoveToPositionAbility, 10]],
				},
			},
		],
		actions: [
			// phase 1 - BRUTAL
			{
				priority: 21,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						BrutalDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_1_2_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						...BRUTAL_PROJECTILE_ATTACKS_V1,
						[2, moveToRandomPositionFastAbilityBrutal],
					],
				},
			},
			// phase 2 with available Heal - BRUTAL
			{
				priority: 22,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						BrutalDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_2_3_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown15000,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						...BRUTAL_PROJECTILE_ATTACKS_V2,
						[2, moveToRandomPositionFastAbilityBrutal],
						[4, moveToPondPositionAbilityBrutal, playAnimationAbility(AnimationTrack.HEALING_SPRING_START), 8, healingSpringAbilityBrutal, playAnimationAbility(AnimationTrack.HEALING_SPRING_END), cooldownEnemyCooldown15000, 10],
					],
				},
			},
			// phase 2 with No Heal - BRUTAL
			{
				priority: 23,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						BrutalDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_2_3_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						...BRUTAL_PROJECTILE_ATTACKS_V2,
						[2, moveToRandomPositionFastAbilityBrutal],
						/*[4, moveToPondPositionAbility, playAnimationAbility(AnimationTrack.HEALING_SPRING_START), 10, healingSpringAbility, playAnimationAbility(AnimationTrack.HEALING_SPRING_END), cooldownEnemyCooldown15000 , 10],*/
						// 2nd phase, with the Pond removed due to the debuff.
					],
				},
			},
			// phase 3 with heal available - BRUTAL
			{
				priority: 24,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						BrutalDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown15000,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						...BRUTAL_PROJECTILE_ATTACKS_V3,
						[2, moveToRandomPositionFastAbilityBrutal],
						[4, moveToPondPositionAbilityBrutal, playAnimationAbility(AnimationTrack.HEALING_SPRING_START), 8, healingSpringAbilityBrutal, playAnimationAbility(AnimationTrack.HEALING_SPRING_END), cooldownEnemyCooldown15000, 10],
					],
				},
			},
			// phase 3 with no heal - BRUTAL
			{
				priority: 25,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						BrutalDifficultyCriteria,
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						...BRUTAL_PROJECTILE_ATTACKS_V3,
						[2, moveToRandomPositionFastAbilityBrutal],
						/*[4, moveToPondPositionAbility, playAnimationAbility(AnimationTrack.HEALING_SPRING_START), 10, healingSpringAbility, playAnimationAbility(AnimationTrack.HEALING_SPRING_END), 10],*/
						// We only want pond triggering on the priority commands so we can force it to obey the no-cooldown condition.
					],
				},
			},
			// phase 1 - MEDIUM
			{
				priority: 31,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						HardDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_1_2_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						...MEDIUM_PROJECTILE_ATTACKS_V1,
						[2, moveToRandomPositionFastAbilityMedium],
					],
				},
			},
			// phase 2 with available Heal - MEDIUM
			{
				priority: 32,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						HardDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_2_3_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown15000,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						...MEDIUM_PROJECTILE_ATTACKS_V2,
						[2, moveToRandomPositionFastAbilityMedium],
						[4, moveToPondPositionAbilityMedium, playAnimationAbility(AnimationTrack.HEALING_SPRING_START), 8, healingSpringAbilityMedium, playAnimationAbility(AnimationTrack.HEALING_SPRING_END), cooldownEnemyCooldown15000, 10],
					],
				},
			},
			// phase 2 with No Heal - MEDIUM
			{
				priority: 33,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						HardDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_2_3_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						...MEDIUM_PROJECTILE_ATTACKS_V2,
						[2, moveToRandomPositionFastAbilityMedium],
						/*[4, moveToPondPositionAbility, playAnimationAbility(AnimationTrack.HEALING_SPRING_START), 10, healingSpringAbility, playAnimationAbility(AnimationTrack.HEALING_SPRING_END), cooldownEnemyCooldown15000 , 10],*/
						// 2nd phase, with the Pond removed due to the debuff.
					],
				},
			},
			// phase 3 with heal available - MEDIUM
			{
				priority: 34,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						HardDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown15000,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						...MEDIUM_PROJECTILE_ATTACKS_V3,
						[2, moveToRandomPositionFastAbilityMedium],
						[4, moveToPondPositionAbilityMedium, playAnimationAbility(AnimationTrack.HEALING_SPRING_START), 8, healingSpringAbilityMedium, playAnimationAbility(AnimationTrack.HEALING_SPRING_END), cooldownEnemyCooldown15000, 10],
					],
				},
			},
			// phase 3 with no heal - MEDIUM
			{
				priority: 35,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						HardDifficultyCriteria,
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						...MEDIUM_PROJECTILE_ATTACKS_V3,
						[2, moveToRandomPositionFastAbilityMedium],
						/*[4, moveToPondPositionAbility, playAnimationAbility(AnimationTrack.HEALING_SPRING_START), 10, healingSpringAbility, playAnimationAbility(AnimationTrack.HEALING_SPRING_END), 10],*/
						// We only want pond triggering on the priority commands so we can force it to obey the no-cooldown condition.
					],
				},
			},
			// phase 1 - EASY
			{
				priority: 41,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						NormalDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_1_2_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						...EASY_PROJECTILE_ATTACKS_V1,
						[2, moveToRandomPositionFastAbilityEasy],
					],
				},
			},
			// phase 2 with available Heal - EASY
			{
				priority: 42,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						NormalDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_2_3_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown15000,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						...EASY_PROJECTILE_ATTACKS_V2,
						[2, moveToRandomPositionFastAbilityEasy],
						[4, moveToPondPositionAbilityEasy, playAnimationAbility(AnimationTrack.HEALING_SPRING_START), 10, healingSpringAbilityEasy, playAnimationAbility(AnimationTrack.HEALING_SPRING_END), cooldownEnemyCooldown15000, 10],
					],
				},
			},
			// phase 2 with No Heal - EASY
			{
				priority: 43,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						NormalDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_2_3_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						...EASY_PROJECTILE_ATTACKS_V2,
						[2, moveToRandomPositionFastAbilityEasy],
						/*[4, moveToPondPositionAbility, playAnimationAbility(AnimationTrack.HEALING_SPRING_START), 10, healingSpringAbility, playAnimationAbility(AnimationTrack.HEALING_SPRING_END), cooldownEnemyCooldown15000 , 10],*/
						// 2nd phase, with the Pond removed due to the debuff.
					],
				},
			},
			// phase 3 with heal available - EASY
			{
				priority: 44,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						NormalDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown15000,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						...EASY_PROJECTILE_ATTACKS_V3,
						[2, moveToRandomPositionFastAbilityEasy],
						[4, moveToPondPositionAbilityEasy, playAnimationAbility(AnimationTrack.HEALING_SPRING_START), 10, healingSpringAbilityEasy, playAnimationAbility(AnimationTrack.HEALING_SPRING_END), cooldownEnemyCooldown15000, 10],
					],
				},
			},
			// phase 3 with no heal - EASY
			{
				priority: 45,
				actionCriteria: alwaysTrueCriteria,
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						...EASY_PROJECTILE_ATTACKS_V3,
						[2, moveToRandomPositionFastAbilityEasy],
						/*[4, moveToPondPositionAbility, playAnimationAbility(AnimationTrack.HEALING_SPRING_START), 10, healingSpringAbility, playAnimationAbility(AnimationTrack.HEALING_SPRING_END), 10],*/
						// We only want pond triggering on the priority commands so we can force it to obey the no-cooldown condition.
					],
				},
			},
		],
	},

	name: ENEMY_NAMES.FOREST_BOSS,
	type: EnemyType.BOSS,
	appearance: {
		asset: SpineDataName.FOREST_BOSS,
		skin: EnemyBipedSkinType.DEFAULT,
		defaultMix: 0.3,
		mixSettings: [],
		zOffset: 100,
	},
	general: {
		enemyLevel: 1,
		experienceWhenKilled: 20,
	},
	baseAttributes: {
		awarenessCollisionRadiusInGrids: 6,
		colliderIsKinematic: true,
		...EnemyDefaults.baseAttributes,
		colliders: [
			{
				type: ColliderType.Circle,
				radius: 80,
				position: [0, -80],
				traits: ColliderTraits.BlockMovementAndProjectile,
			},
			{
				type: ColliderType.Circle,
				radius: 90,
				position: [0, -200],
				traits: ColliderTraits.BlockProjectile,
			},
		],
		attackOffset: new Vector(0, 0),
		lootDropOffset: new Vector(0, 0),
		damageConfig: bossEnemyDamageConfig,
		movementSpeed: 100,
		visibilityRadius: 1500,
		decelerationRate: 0,
		attackRate: 750,
		turningRatePerSecondInDegrees: 200,
		physicalDamage: 20,
		fireDamage: 0,
		iceDamage: 0,
		lightningDamage: 0,
		poisonDamage: 0,
		critChance: 0.05,
		critDamage: 1.5,
		cooldownSpeed: 300,
		debuffPotency: 1.0,
		maxHealth: ENEMY_DEFAULT_HEALTH * BOSS_HEALTH_MULTIPLIER, // Boss health
		maxShields: 0,
		shieldRegenDelay: 0,
		shieldRegenRate: 0,
		healthRegen: 0,
		healthRegenDelay: 0,
		maxEnergy: 100,
		energyRegen: 100,
		primaryFireEnergyCost: 0,
		defense: 0,
		fireResist: 0,
		poisonResist: 0,
		iceResist: 0,
		lightningResist: 0,
		buffDuration: 0,
		skillUsageSpeed: 0,

		lootDropProbability: 100,
		lootDropQuantity: BOSS_LOOT_DROP_QUANTITY,
		lootDropRarity: BOSS_LOOT_DROP_RARITY,
	},
	soundEffects: {
		attack: 'Projectile_Fireball',
		impact: 'SFX_Impact_Enemy_Flesh',
	},
	states: {
		idling: {
			behaviour: IdleBehaviours.STATIONARY,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			transitionToFighting: {
				trigger: FightingTriggers.AGGRESSIVE_PROXIMITY,
				radius: 2500,
			},
			aggroOnHit: true,
		},
		fighting: {
			behaviour: FightingBehaviours.CHASE_AND_ATTACK,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			attackType: AttackTypes.PROJECTILE,
			engagementMaxDistance: 1600,
			engagementMinDistance: 0,
			movementMaxDistance: 1600,
			movementMinDistance: 0,
			attackOptions: [],
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 0.5,
			transitionToLeashing: {
				trigger: LeashTriggers.NONE,
			},
		},
		leashing: {
			behaviour: LeashingBehaviours.MOVE,
			speedMultiplier: 4,
			decelerateMultiplier: 0,
		},
		fleeing: {},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 4,
		},
	},
}

export default boss
