import nengi from 'nengi'

export default class PlayAnimationMessage {
	static protocol = {
		x: { type: nengi.Float32 },
		y: { type: nengi.Float32 },
		entityId: { type: nengi.Number },
		animation: { type: nengi.String },
	}

	x: number
	y: number
	entityId: number
	animation: string

	constructor(params) {
		this.x = params.x
		this.y = params.y
		this.entityId = params.entityId
		this.animation = params.animation
	}
}
