
























const rarityShardImages = require('./assets/rarity-shard-*.png')
import { to4DigitTruncatedString } from '../../../utils/math'

export default {
	name: 'EmptyInventorySlot',
	props: {
		premiumSlot: {
			type: Boolean,
			required: false,
			default: false,
		},
		showUnknown: {
			type: Boolean,
			required: false,
			default: false,
		},
		showLocked: {
			type: Boolean,
			required: false,
			default: false,
		},
		itemRarity: {
			type: String,
			required: false,
		},
		coinCost: {
			type: Number,
			required: false,
		},
		rarityShardCost: {
			type: Number,
			required: false,
		},
	},
	data() {
		return {
			slotVariant: `slot-variant-${Math.floor(Math.random() * 5) + 1}`,
		}
	},
	computed: {
		selectedItemRarity() {
			return rarityShardImages[this.itemRarity]
		},
	},
	methods: {
		to4DigitTruncatedString
	}
}
