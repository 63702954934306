































































import { mapMutations, mapState } from 'vuex'

export default {
	name: 'NavigationHelper',
	components: {},
	computed: {
		...mapState('hud', ['navigationMenuVisible', 'currentArrowSearch']),
	},
	methods: {
		...mapMutations('hud', ['toggleNavigationMenuVisible', 'setCurrentArrowSearch']),
	},
}
