import nengi from 'nengi'
import ItemRarity from '../../loot/shared/item-rarity'
import ItemType, { ItemSubType } from '../../loot/shared/item-type'
import { ModCategory } from './game-data/stat-type-mod-category'

export class RollItemCommand {
	static protocol = {
		type: nengi.Number,
		subType: nengi.Number,
		rarity: nengi.Number,
		element: nengi.String,
		gearSkill: nengi.Number,
		level: nengi.Number,
		identified: nengi.Boolean,
		rollOneOfEachSubType: nengi.Boolean,
		listOnMarketplace: nengi.Boolean,
		weaponMods: { type: nengi.Number, indexType: nengi.UInt16 }
	}

	type: ItemType
	subType: ItemSubType
	rarity: ItemRarity
	gearSkill: ModCategory
	element: string
	level: number
	identified: boolean
	rollOneOfEachSubType?: boolean = false
	listOnMarketplace?: boolean = false
	weaponMods?: number[]

	constructor(params: RollItemCommand) {
		Object.assign(this, params)
	}
}

export function assembleWeaponModsFromCommandIndex(command: RollItemCommand, index) {
	return {
		weaponMod: command.weaponMods[index],
	}
}
