








































































































import { getRankDescriptionString, IPressureCategory, getPressureCategoryPrettyName, PRESSURE_MENU_UNLOCK_TIERS_DEFEATED, PRESSURE_POINTS_PER_TIER } from '../../engine/shared/game-data/pressure-stat-info.shared'
import SidePanel from '../reusable-components/panels/side-panel.vue'
import ValueButtons from '../reusable-components/buttons/value-buttons.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { UIPressureStatInfo } from '../state/boat-launch.ui-state'
import { pressureRank } from '../../utils/primitive-types'

export default {
	name: 'FlyoutDeparture',
	components: {
		SidePanel,
		ValueButtons,
	},
	props: {},
	computed: {
		...mapGetters('boatLaunch', ['hasReachedBiomeOnTier', 'maxAllowedTier', 'getPressureRank', 'difficultyModifiers', 'totalPointsSelected', 'totalPointsForNextWorldTier', 'totalPointsForPreviousWorldTier', 'isValidPressureLoadout']),
		...mapGetters('party', ['getHasAdventureServer']),
		...mapGetters('user', ['userId']),
		pressureCategories: function () {
			const categories = []
			for (let category in IPressureCategory) {
				if (!isNaN(Number(category))) {
					categories.push({ key: Number(category), value: getPressureCategoryPrettyName(Number(category)) })
				}
			}
			return categories
		},
		nextWorldTierMinLevel: function () {
			return Math.max(0, this.maxAllowedTier - 1) * 15 + 1
		},
		nextWorldTierMaxLevel: function () {
			return Math.max(0, this.maxAllowedTier - 1) * 15 + 15
		},
		progressBarWidth: function () {
			return Math.clamp((this.totalPointsSelected / this.totalPointsForNextWorldTier) * 100, 0, 100)
		},
		modifiersSectionHeight() {
			return (this.difficultyModifiers.length + 2) * 90
		},
	},
	mounted() {
		this.shimSetNewWorldTier()
	},
	methods: {
		...mapMutations('boatLaunch', ['setPressureRank', 'selectNewWorldTier', 'updatePressurePointsSelected', 'setInitialLoadout']),
		...mapActions('boatLaunch', ['updateReadyStateByPressurePoints']),
		shimSetNewWorldTier() {
			const tier = Math.floor(this.totalPointsSelected / PRESSURE_POINTS_PER_TIER) + PRESSURE_MENU_UNLOCK_TIERS_DEFEATED
			this.selectNewWorldTier(tier)
		},
		selectRankPip(modifier: UIPressureStatInfo, value: number) {
			if (modifier.ranksSelected == value) {
				modifier.ranksSelected--
			} else {
				modifier.ranksSelected = value
			}
			this.setPressureRank({ stat: modifier.statType, rank: modifier.ranksSelected })
			this.shimSetNewWorldTier()
			this.updatePressurePointsSelected(this.totalPointsSelected)
			this.updateReadyStateByPressurePoints()
		},
		getRankString(modifier: UIPressureStatInfo) {
			return getRankDescriptionString(modifier, modifier.ranksSelected)
		},
		decreaseModifierRank(modifier: UIPressureStatInfo) {
			if (modifier.ranksSelected > 0) {
				modifier.ranksSelected--
				this.setPressureRank({ stat: modifier.statType, rank: modifier.ranksSelected })
				this.shimSetNewWorldTier()
				this.updatePressurePointsSelected(this.totalPointsSelected)
				this.updateReadyStateByPressurePoints()
			}
		},
		increaseModifierRank(modifier: UIPressureStatInfo) {
			if (modifier.ranksSelected < this.getModifierRank(modifier.ranks)) {
				modifier.ranksSelected++
				this.setPressureRank({ stat: modifier.statType, rank: modifier.ranksSelected })
				this.shimSetNewWorldTier()
				this.updatePressurePointsSelected(this.totalPointsSelected)
				this.updateReadyStateByPressurePoints()
			}
		},
		getDifficultyModifiersByCategory(category: IPressureCategory) {
			category = Number(category)
			return this.difficultyModifiers.filter((mod) => mod.category === category && this.getModifierRank(mod.ranks) > 0)
		},
		getModifierRank(ranks: pressureRank[]) {
			return ranks[Math.min(this.maxAllowedTier - 2, ranks.length - 1)]
		},
		isDifficultyModifierCategoryNotEmpty(category: IPressureCategory) {
			category = Number(category)
			return this.difficultyModifiers.filter((mod) => mod.category === category && this.getModifierRank(mod.ranks) > 0).length > 0
		},
	},
}
