export enum PowerUpSubType {
	SmallHeart = 101,
	LargeHeart = 102,
}

const PowerUpSubTypePrettyName = new Map<PowerUpSubType, string>()
PowerUpSubTypePrettyName.set(PowerUpSubType.SmallHeart, 'Small Heart')
PowerUpSubTypePrettyName.set(PowerUpSubType.LargeHeart, 'Large Heart')

export { PowerUpSubTypePrettyName }
