







































































import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'
import { FactionShortNameToFullName, factionTextColor, FactionShortName } from '../../../factions/shared/faction-data'
import Tally from './tally.vue'
import { FactionAnimations } from '../../state/factions-ui-state'

export default {
	name: 'FactionTableView',
	components: {
		Tally,
	},
	data() {
		return {
			previousSum: 0,
			nextSum: 0,
			FactionAnimations,
		}
	},
	props: {
		tableData: {
			type: Object,
			required: true,
		},
	},
	computed: {
		...mapState('factions', ['currentRecruitPanel', 'affiliatedFaction', 'tallyAnimationsPlayed']),

		factionFullName() {
			return FactionShortNameToFullName.get(this.affiliatedFaction)
		},
		calculateTotalPoints() {
			let sum = 0
			sum += this.tableData.kills
			sum += this.tableData.champions
			sum += this.tableData.bosses
			sum += this.tableData.pois
			sum += this.tableData.rareItems

			if (this.affiliatedFaction === FactionShortName.ORDER_OF_IRON) {
				sum += this.tableData.damageTaken
			}

			if (this.affiliatedFaction === FactionShortName.AURUM_ALLIANCE) {
				sum += this.tableData.epicItems
			}

			if (this.affiliatedFaction === FactionShortName.SCIONS_OF_DAWN) {
				sum += this.tableData.enemiesIgnited
			}
			return sum
		},
		calculateGenericPoints(){
			let sum = 0
			sum += this.tableData.kills
			sum += this.tableData.champions
			sum += this.tableData.bosses
			sum += this.tableData.pois
			sum += this.tableData.rareItems
			return sum
		}
	},
	methods: {
		...mapMutations('factions', ['updateTallyTotal']),
		...mapActions('factions', ['updateFactionBadge', 'updateGenericFactionBadge', 'updateFinalScoreBadge']),

		factionTextColor,

		animationComplete(playNext, sum) {
			switch (playNext) {
				case this.FactionAnimations.KILLS:
					if ((this.affiliatedFaction === FactionShortName.ORDER_OF_IRON && this.tableData.damageTaken > 0) || (this.affiliatedFaction === FactionShortName.AURUM_ALLIANCE && this.tableData.epicItems > 0) || (this.affiliatedFaction === FactionShortName.SCIONS_OF_DAWN && this.tableData.enemiesIgnited > 0)) {
						this.updateFactionBadge()
					}
					this.previousSum += sum
					this.nextSum += this.tableData.kills
					this.$refs.kills.playAnimation()
					break
				case FactionAnimations.CHAMPIONS:
					this.previousSum += sum
					this.nextSum += this.tableData.champions
					this.$refs.champions.playAnimation()
					break
				case FactionAnimations.BOSSES:
					this.previousSum += sum
					this.nextSum += this.tableData.bosses
					this.$refs.bosses.playAnimation()
					break
				case FactionAnimations.POIS:
					this.previousSum += sum
					this.nextSum += this.tableData.pois
					this.$refs.pois.playAnimation()
					break
				case FactionAnimations.RARE_ITEMS:
					this.previousSum += sum
					this.nextSum += this.tableData.rareItems
					this.$refs.rareItems.playAnimation()
					break
				case FactionAnimations.GENERIC_ACTIONS:
					if(this.calculateGenericPoints > 0){
						this.updateGenericFactionBadge()
					}
					
					setTimeout(() => {
						this.updateFinalScoreBadge()
					}, 1000)
					break
			}
		},
		animationUpdated(value) {},
	},
	mounted() {

		this.updateTallyTotal(this.calculateTotalPoints)

		if (!this.tallyAnimationsPlayed) {
			switch (this.affiliatedFaction) {
				case FactionShortName.SCIONS_OF_DAWN:
					this.nextSum = this.tableData.enemiesIgnited
					this.$refs.enemiesIgnited.playAnimation()
					this.$refs.total.playAnimation()
					break
				case FactionShortName.AURUM_ALLIANCE:
					this.nextSum = this.tableData.epicItems
					this.$refs.epicItems.playAnimation()
					this.$refs.total.playAnimation()
					break
				case FactionShortName.ORDER_OF_IRON:
					this.nextSum = this.tableData.damageTaken
					this.$refs.damageTaken.playAnimation()
					this.$refs.total.playAnimation()
					break
			}
		}
	},
}
