import { ImARealitem } from "../reusable-components/inventory-item/inventory-item-helpers"
import { UI } from "../ui"

export function showStackableSelection(item: ImARealitem, callback: Function) {
    UI.getInstance().emitEvent('stackableSelection/showPopUp', {item, callback})
}

export const stackableSelectionModule = () => {
    return {
        namespaced: true,
        state: {
            isStackableSelectionVisible: false,
            stackAmount: 1,
            item: null,
            doneCallback: null
        },
        getters: {
            isStackableSelectionVisible(state) {
                return state.isStackableSelectionVisible
            }
        },
        mutations: {
            updateStacks(state, amount) {
                state.stackAmount = amount
            },
            showPopUp(state, {item, callback}) {
                state.isStackableSelectionVisible = true
                state.item = item
                state.doneCallback = callback
                state.stackAmount = 1
            },
            cancelPopup(state) {
                if(state.doneCallback) {
                    const callback = state.doneCallback
                    state.doneCallback = null
                    callback(false)
                }

                state.isStackableSelectionVisible = false
                state.item = null
                state.stackAmount = 1
            },
            doneSelection(state) {
                if(state.doneCallback) {
                    const callback = state.doneCallback
                    state.doneCallback = null
                    callback(state.stackAmount)
                }

                state.isStackableSelectionVisible = false
                state.item = null
                state.stackAmount = 1
            }
        }
    }
}