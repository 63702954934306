import nengi from 'nengi'
import { DrawShapeBase } from './draw-shape-base'

class DrawEllipse extends DrawShapeBase {
	static protocol = {
		...DrawShapeBase.protocol,
		x: nengi.Number,
		y: nengi.Number,
		rX: nengi.Number,
		rY: nengi.Number,
	}

	x: number
	y: number
	rX: number
	rY: number

	constructor(x: number, y: number, rX: number, rY: number, color: number, permanent: boolean, destroyAfterSeconds: number, scale) {
		super(color, permanent, destroyAfterSeconds, scale)
		this.x = x
		this.y = y
		this.rX = rX
		this.rY = rY
	}
}

export default DrawEllipse
