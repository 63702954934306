import nengi from 'nengi'
import { uuid } from '../../utils/primitive-types'

class ClaimRewardCommand {
	static protocol = {
		augmentId: nengi.String,
	}
	augmentId: uuid
	/**
	 * Creates an instance of ClaimRewardCommand.
	 * Claims an Enchantment Reward Upon Defeating a Boss
	 * @param {uuid} augmentId Augment ID
	 * @memberof ClaimRewardCommand
	 */
	constructor(augmentId: uuid) {
		this.augmentId = augmentId
	}
}

export default ClaimRewardCommand
