import { showGenericInfoPromptUI } from '../../ui/state/generic-information-prompt.ui-state'
import { UI } from '../../ui/ui'
import ClearPartyMessage, { ClearPartyReason } from '../shared/clear-party-message'
import PartyMessage from '../shared/party-message'
import PartyFlagsMessage from '../shared/party-flags-message'

export function handlePartyMessage(msg: PartyMessage) {
	const players = JSON.parse(msg.players)
	console.log('party message')
	console.log(msg)
	if (msg.error) {
		UI.getInstance().emitEvent('party/updateErrors', msg.error)
	} else {
		UI.getInstance().emitEvent('party/updateParty', { playerId: msg.playerId, partyId: msg.partyId, partyMembers: players, adventureServer: msg.adventureServer })
	}
}

export function handlePartyFlagsMessage(msg: PartyFlagsMessage) {
	const players = JSON.parse(msg.players)
	UI.getInstance().emitEvent('party/updatePartyFlags', players)
}

export function handleClearPartyMessage(msg: ClearPartyMessage) {
	console.log('clear party message')
	UI.getInstance().emitEvent('party/clearParty')
	UI.getInstance().emitEvent('party/updatePartyId', '')

	const clearMessage = msg.reason === ClearPartyReason.KICKED ? 'You have been kicked from the party' : 'You have been desynced from the party'
	showGenericInfoPromptUI('Party', [clearMessage])
}
