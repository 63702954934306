<template>
	<div class="furnace-border-image" >
		<div class="furnace-notifier notification-body"  @click="setActivePanel('furnaceUpdate')">
			<h3 class="notif-hud-text">You have unused furnaces!</h3>
		</div>
	</div>
</template>

<script lang="js">
import { mapGetters, mapMutations } from 'vuex'
export default {
	name: 'FurnaceNotifier',
	computed: {
		...mapGetters('furnace', ['totalItemsInSmelter']),
		...mapGetters('UIScale', ['currentScale']),
		...mapGetters('itemContainers', ['itemsInContainer'])
	},
	methods:{
		...mapMutations('inGame', ['setActivePanel']),
	}
}
</script>

<style lang="less" scoped>
@import url('../global/fonts/text.less');

.furnace-border-image {
	 position: relative;
	// top: 35%;
	// left: 0.5%;

	.furnace-notifier {
		display: flex;
		pointer-events: all;
		cursor: pointer;
		height: 65px;
		justify-content: flex-end;
		align-items: center;
		perspective: 1px;
		border-image-slice: 4 38 9 6 fill;
		border-image-width: 4px 38px 9px 6px;
		border-image-outset: 0px 0px 0px 0px;
		border-image-repeat: repeat stretch;
		border-image-source: url('../hud/assets/toast-generic.png');
		z-index: 2;

		.notif-hud-text {
			color: rgb(233, 229, 173);
			margin: 0;
			padding: 0;
			.notif-hud-text();
			font-size: 26px;
		}
	}
}
</style>
