import { ProjectileConfig, ProjectileTargetType } from '../../projectiles/shared/projectile-types'
import { percentage, level, timeInMilliseconds, gameUnits } from '../../utils/primitive-types'
import ModelConfig from '../../models-animations/shared/model-config'
import { Vector } from 'sat'
import { AnyColliderConfig } from '../../collision/shared/colliders'
import { ActionDrivenEnemyData } from './action-types'
import { DamageConfig } from '../../engine/shared/game-data/enemy-formulas'

export enum AIStates {
	IDLING = 'idling',
	FIGHTING = 'fighting',
	LEASHING = 'leashing',
	FLEEING = 'fleeing',
	DEAD = 'dead',
}

export enum FightingSubstates {
	CHASING = 'chasing',
	ATTACKING = 'attacking',
}

export enum IdleBehaviours {
	// Doesn't move while idle
	STATIONARY = 'stationary',
	// Randomly jitters around in all directions
	RANDOM_JITTER = 'randomJitter',
	// Mosey around, moving to a random point and pausing occasionally
	MEANDER = 'meander',
	// Patrol along a preset path relative to its spawn position
	// TODO3 Sam: PATROL = 'patrol
}

export enum DeadBehaviours {
	// Do nothing. Sit there. Be a corpse.
	BE_A_CORPSE = 'beACorpse',
}

export enum FightingBehaviours {
	// Chases towards the target using steering behaviour and shoots when within shooting range (engagement distance)
	CHASE_AND_ATTACK = 'chaseAndAttack',
	CHASE_WHILE_ATTACKING = 'chaseWhileAttacking',
	STRAFE_AND_ATTACK = 'strafeAndAttack',
	STRAFE_WHILE_ATTACKING = 'strafeWhileAttacking',
	CHARGE_MELEE = 'chargeMelee',
	SPAWNER = 'spawner',
}

export enum LeashingBehaviours {
	// Moves back to pre-aggro position via steering vectors in a straight line
	MOVE = 'move',
	// Moves back to pre-aggro position via steering vectors in a straight line, heals to full health each tick
	MOVE_AND_FULL_HEAL = 'moveAndFullHeal',
	// Teleports back to pre-aggro position instantly
	TELEPORT = 'teleport',
}

export enum LeashRecoveryBehaviours { }
// Recover NO resources while leashing
// TODO3 Sam: NONE = 'none',
// Recover to full resources while leashing
// TODO3 Sam: FULL = 'full',

export enum EnemyTargetingStyles {
	// Retargets to nearest target, keeping 1st
	NEAREST,
	// Retargets to farthest target
	FARTHEST,
	// Retargets to nearby weakest target as % of total health
	LOW_HEALTH_RATIO,
	// Retargets to nearby weakest target as healthRemaining
	LOW_HEALTH,
	// Retargets to nearby weakest target as % of total health
	HIGH_HEALTH_RATIO,
	// Retargets to near by weakest target as healthRemaining
	HIGH_HEALTH,
	// No retargeting while having valid target
	NONE,
}

export enum AttackTypes {
	// Fires a projectile adhering to the projectile system
	PROJECTILE = 'projectile',
	// Performs a melee attack? Or maybe these are also "projectiles"?
	// MELEE = 'melee'
	// Make no attacks
	NONE = 'none',
}

export enum FightingTriggers {
	// Aggros players by proximity
	AGGRESSIVE_PROXIMITY = 'aggressiveProximity',
	// Aggros NEVER
	PACIFIST = 'pacifist',
	// Aggros when player engages with any damage
	// TODO3 Sam: DEFENSIVE_DAMAGE_ANY = 'defensiveAnyDamage',
	// Aggros when damage dealt by player exceeds a threshold
	// TODO3 Sam: DEFENSIVE_DAMAGE_THRESHOLD = 'defensiveDamageThreshold',
}

export enum LeashTriggers {
	// Never leash. Chase player forever until player ceases to exist.
	NONE = 'none',
	// Leash when the distance from pre-aggro location exceeds a radius outside of that location
	RADIUS_EXCEEDED = 'radiusExceeded',
	// Leash when the AI has travelled a specified distance
	DISTANCE_TRAVELLED = 'distanceTravelled',
	// Leash *either* when a radius is exceeded, OR when the agent has travelled a specified distance
	DISTANCE_TRAVELLED_OR_RADIUS_EXCEEDED = 'distanceTravelledOrRadiusExceeded',
	// Leash when the target is too far away
	RANGE_TO_TARGET_EXCEEDED = 'rangeToTargetExceeded',
}

export enum FleeTriggers { }
// Flees NEVER
// TODO3 Sam: NONE = 'none',
// Flees when health drops below a certain %
// TODO3 Sam:" HEALTH_PERCENTAGE_BELOW_THRESHOLD = 'healthPercentageBelowThreshold',

export enum ShotLeadPrecision {
	NONE,
	AVERAGE,
	PERFECT,
}

export enum EnemyType {
	BASIC = 1,
	CHAMPION = 2,
	BOSS = 3,
	DUMMY = 4,
	LOOTLESS_CHAMPION = 5, //TODO2: this should be a flag on an enemy, not a separate type, or we'll bleed "if type = champion OR lootless" checks everywhere
	DESTRICTIBLE_PROP = 6,
}

export interface AITargetingState {
	behaviour: any
	targetingStyle: EnemyTargetingStyles
	targetingCheckSeconds: number // if targetingStyle is set and this is not, then defaults to 1s
}

/**
 * **quantity**: (optional) number to spawn in each spawn iteration, capped at spawnLimit. Treated as 1 if omitted
 * **spawnLimit**: max to spawn for a given spawner/owner
 * **spawnEnemy**: EnemyId of thing to spawn
 */
export interface SpawningRules {
	quantity?: number
	spawnLimit: number
	spawnEnemies: any[]
	spawnRate?: number
	spawnInTime?: number
	spawnRadius: number
	spawnlingsGiveXP: boolean
	spawnlingsGiveLoot: boolean
	killSpawnlingsWhenIDie: boolean
}

export interface EnemyAIBaseAttributes {
	/** how many grid units this enemy searches for players */
	awarenessCollisionRadiusInGrids?: number
	noFlip?: boolean
	visibilityRadius: number
	/**
	 * if true, this enemy will be unmovable
	 */
	colliderIsKinematic?: boolean
	/**
	 * **type**: circle/box/ellipse/polygon/lookup
	 */
	colliders: AnyColliderConfig[]
	hasMovableCollider?: boolean
	defaultMovableColliderPosition?: number[]
	idleMovableColliderPosition?: number[]
	/** Acceleration rate in pixels * delta */
	movementSpeed: gameUnits
	/** Deceleration rate in pixels * delta */
	decelerationRate: gameUnits
	knockbackDecelerationRate: gameUnits
	turningRatePerSecondInDegrees: number

	allDamage: percentage
	projectileDamage: percentage
	physicalDamage: number
	fireDamage: number
	iceDamage: number
	lightningDamage: number
	poisonDamage: number

	/** Attack rate, in milliseconds */
	attackRate: timeInMilliseconds
	/** Crit chance, 0.0 to 1.0 */
	critChance: percentage
	/** Crit damage, as a multiplier */
	critDamage: number
	cooldownDuration: timeInMilliseconds
	cooldownSpeed: percentage
	//TODO2 update this if debuffs are added
	/** Not used at the time of this writing */
	debuffPotency: percentage

	maxHealth: number
	/** Amount of health to regenerate every healthRegenDelay */
	healthRegen: number
	healthRegenDelay: number
	maxShields: number
	shieldRegenRate: number
	shieldRegenDelay: number
	maxEnergy: number
	energyRegen: number
	primaryFireEnergyCost: number
	defense: number
	fireResist: number
	iceResist: number
	lightningResist: number
	poisonResist: number
	buffDuration: percentage
	skillUsageSpeed: percentage
	attackOffset: Vector
	lootDropOffset: Vector
	damageConfig: DamageConfig

	lootDropProbability: percentage
	lootDropQuantity: percentage
	lootDropRarity: percentage

	damageTaken: percentage
	damageTakenFromPhysical: percentage
	damageTakenFromFire: percentage
	damageTakenFromIce: percentage
	damageTakenFromLightning: percentage
	damageTakenFromPoison: percentage

	healingReceived: percentage

	bleedChance: percentage
	igniteChance: percentage
	chillChance: percentage
	shockChance: percentage
	poisonChance: percentage

	bleedPotency: number
	ignitePotency: number
	chillPotency: number
	shockPotency: number
	poisonPotency: number
}

export interface IAnimationTimes {
	[key: string]: number
}

export interface EnemyAI {
	actionDrivenEnemyData?: ActionDrivenEnemyData

	/** the enemy name, needs to be the same as the key in enemy definitions */
	name: string
	type: EnemyType
	/** Asset location and parameters to render enemy with Spine */
	appearance: ModelConfig
	general: {
		enemyLevel: level
		experienceWhenKilled: number
	}
	baseAttributes: EnemyAIBaseAttributes
	soundEffects: {
		/** Sound effect group name for attack */
		attack: string
		impact: string
	}
	states: {
		idling: {
			behaviour: IdleBehaviours
			targetingStyle: EnemyTargetingStyles
			targetingCheckSeconds: number
			transitionToFighting: {
				trigger: FightingTriggers
				radius?: number
			}
			meander?: {
				radius: number
				speedMultiplier: number
				minRestTime: number
				maxRestTime: number
			}
			aggroOnHit: boolean
		}
		fighting: {
			behaviour: FightingBehaviours
			brain?: ActionDrivenEnemyData
			targetingStyle: EnemyTargetingStyles
			targetingCheckSeconds: number
			attackType: AttackTypes
			engagementMaxDistance: number
			engagementMinDistance: number
			modelCenterOffset?: number
			movementMaxDistance: number
			movementMinDistance: number
			attackOptions: ProjectileConfig[]
			shotLeadPrecision: ShotLeadPrecision
			targetType?: ProjectileTargetType
			visualAimLockSeconds: number
			transitionToLeashing: {
				trigger: LeashTriggers
				radius?: number
				distanceTravelled?: number
				range?: number
			}
			spawningRules?: SpawningRules
			idleAnimation?: string
		}
		leashing: {
			behaviour: LeashingBehaviours
			speedMultiplier?: number
			decelerateMultiplier?: number
		}
		fleeing: {}
		dead: {
			behaviour: DeadBehaviours
			corpseTimeoutInSeconds: number
			persistentCorpse?: boolean
		}
	}
}

export function getMainAppearance(config: EnemyAI) {
	return Array.isArray(config.appearance) ? config.appearance[0] : config.appearance
}
