import nengi from 'nengi'
import { AnyCollider } from '../../collision/shared/colliders'
import { ColliderEntityType } from './collider-entity-config'

class ColliderEntity {
	static protocol = {
		x: { type: nengi.Number },
		y: { type: nengi.Number },

		colliderEntityType: { type: nengi.UInt3 },

		visuals: { type: nengi.String },
		isPfx: { type: nengi.Boolean },
		rotation: { type: nengi.Number },

		bulwarkHP: { type: nengi.Number },
	}

	nid: number

	x: number = 0
	y: number = 0

	colliderEntityType: ColliderEntityType

	colliders: AnyCollider[]

	visuals: string
	isPfx: boolean
	rotation: number = 0

	bulwarkHP: number = 0
}

export default ColliderEntity
