



















import EmoteInventoryItem from '../inventory-item/emote-grid-item.vue'
import { mapActions, mapMutations } from 'vuex'

export default {
	name: 'EmotesGridItemContainer',
	components: {
		EmoteInventoryItem,
	},
	props: {
		emotes: {
			type: Array,
			required: true,
			default: () => {
				console.warn('Currently using the default array. Ensure the <EmotesGridItemContainer /> component has a custom attribute items')
				return []
			},
		},
		showSlot: {
			type: Boolean,
			required: false,
			default: true,
		},
		maxSlots: {
			type: Number,
			required: true,
			default: () => {
				console.warn('Currently using the default max skin item slots. Ensure the <EmotesGridItemContainer /> component has a custom attribute :max-slots')
				return 20
			},
		},
		selectable: {
			type: Boolean,
			required: false,
			default: true,
		},
		onSelectFn: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default on-select handler for the <EmotesGridItemContainer /> component has been called. Did you forget to pass an :on-click handler to the component?')
			},
		},
		selectedFn: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default selected fn for the <EmotesGridItemContainer /> component has been called. Did you forget to pass an :selected-fn handler to the component?')
			},
		},
	},
	data() {
		return {}
	},
	methods: {
		...mapMutations('emotes', ['setEquippedEmote']),
		...mapActions('emotes', ['updateEquippedEmote']),
		
		onEquip(emoteId) {
			this.setEquippedEmote(emoteId)
			this.updateEquippedEmote()
		},
	},
}
