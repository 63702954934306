





















//import {} from ''
import { mapActions, mapState, mapGetters } from 'vuex'
import { FactionShortName } from '../../../factions/shared/faction-data'
export default {
	name: 'factionProgressBar',
	components: {},
	data() {
		return {
		}
	},
	props: {},
	computed: {
		...mapState('factions', ['tallyAnimationsPlayed', 'tallyTotal', 'affiliatedFaction', 'showFactionBadge', 'showGenericFactionActionBadge', 'showFinalScoreBadge']),
		...mapState('gameOver', ['runStats', 'activeGameOverFilter']),

		getLetterGrade() {
			if ( this.tallyTotal < 900) {
				return 'C'
			} else if (this.tallyTotal >= 900 && this.tallyTotal < 1800) {
				return 'B'
			} else if (this.tallyTotal >= 1800 && this.tallyTotal < 3600) {
				return 'A'
			} else if (this.tallyTotal >= 3600) {
				return 'S'
			}
		},

		factionClassName() {
			if (this.showFactionBadge) {
				switch (this.affiliatedFaction) {
					case FactionShortName.ORDER_OF_IRON:
						return `iron-unlock`
					case FactionShortName.AURUM_ALLIANCE:
						return `aurum-unlock`
					case FactionShortName.SCIONS_OF_DAWN:
						return `scions-unlock`
				}
			}
		},
	},
	methods: {},
}
