import { Vector } from 'sat'
import { gameUnits, radians, degrees } from './primitive-types'
import { radToDeg } from './math'

export const vectorLerp = (firstVector: Vector, secondVector: Vector, t: number) => {
	return new Vector(firstVector.x * (1.0 - t) + secondVector.x * t, firstVector.y * (1.0 - t) + secondVector.y * t)
}

export const angleInRadsFromVector = (v: Vector): radians => {
	return Math.atan2(v.y, v.x)
}

export const angleInDegreesFromVector = (v: Vector): degrees => {
	return radToDeg(angleInRadsFromVector(v))
}

export const vectorFromAngleInRads = (angle: radians): Vector => {
	const x = Math.cos(angle)
	const y = Math.sin(angle)
	return new Vector(x, y)
}

export const getXDirection = (x: gameUnits, targetX: gameUnits) => {
	return x > targetX ? 'west' : 'east'
}
export const getYDirection = (y: gameUnits, targetY: gameUnits) => {
	return y > targetY ? 'north' : 'south'
}

//{ x: -0.6804624352278276, y: 0.7327829653067917 }
export function toFixed(v: Vector, fractionDigits?: number) {
	return `{ x:${v.x.toFixed(fractionDigits)}, y: ${v.y.toFixed(fractionDigits)} }`
}
