import logger from '../../utils/client-logger'

export const errorUIModule = () => {
	logger.debug('Initializing Error store module...')
	return {
		namespaced: true,
		state: {
			errorTitle: '',
			errorDescription: [],
			errorPromptVisible: false,
		},
		getters: {
			getErrorTitle(state: any) {
				return state.errorTitle
			},
			getErrorDescription(state: any) {
				return state.errorDescription
			},
			getErrorPromptVisible(state: any) {
				return state.errorPromptVisible
			},
		},
		mutations: {
			setActiveError(state: any) {
				if (state.errorPromptVisible !== true) {
					state.errorPromptVisible = 'error'
				}
			},
			setErrorDescriptions(state, { title, description }) {
				state.errorDescription = description
				state.errorTitle = title
			},
		},
		actions: {
			closeActiveErrorPanel({ state }, reloadPage: boolean = false) {
				if (reloadPage) {
					window.location.reload()
				} else {
					state.errorPromptVisible = false
				}
			},
		},
	}
}
