import { Container, Sprite, Texture, DisplayObject } from 'pixi.js'
import { LayerRenderer } from './layer-renderer'
import { AssetManager } from '../../asset-manager/client/asset-manager'
import Renderer, { getVisibleWorldWidth, getVisibleWorldHeight } from '../../engine/client/graphics/renderer'
import { RenderQueue } from '../../engine/client/graphics/render-queue'
import { IParticleRendererCamera } from '../../engine/client/graphics/pfx/sprite-particle-renderer'

interface Vignette {
	container: Container
	texture: Texture
	width: number
	height: number
}

export default class ForegroundRenderer extends LayerRenderer {
	private pixiObjects: Container = new Container()
	private vignette: Vignette = {
		container: new Container(),
		texture: undefined,
		width: undefined,
		height: undefined,
	}

	constructor(renderQueue: RenderQueue, cameraState: IParticleRendererCamera) {
		super(renderQueue, cameraState)
		this.pixiObjects.name = 'pixiObjects'
		this.addChild(this.pixiObjects)
		this.vignette.container.name = 'vignette'
		this.vignette.width = getVisibleWorldWidth()
		this.vignette.height = getVisibleWorldHeight()
		this.addChild(this.vignette.container)
	}

	loadAssets(): void {
		this.vignette.texture = AssetManager.getInstance().getAssetByName('vignetteTopLeftCorner').texture
		this.setupVignette(1)
	}

	update(delta: number): void {
		super.update(delta)

		const cameraPos = Renderer.getInstance().getCameraCenterWorldPos()
		this.vignette.container.position.set(cameraPos.x - this.vignette.width / 2, cameraPos.y - this.vignette.height / 2)
	}

	resizeVignette(zoomLevel): void {
		this.vignette.width = getVisibleWorldWidth(zoomLevel) // 0.8// / (zoomLevel / 0.8)
		this.vignette.height = getVisibleWorldHeight(zoomLevel) // 0.8// / (zoomLevel / 0.8)
		this.vignette.container.removeChildren()
		if (this.vignette.texture) {
			this.setupVignette(zoomLevel)
		}
	}

	addPixiObjectToScene(disObj: DisplayObject): void {
		this.pixiObjects.addChild(disObj)
		this.pixiObjects.sortChildren()
	}

	removePixiObjectFromScene(disObj: DisplayObject): void {
		this.pixiObjects.removeChild(disObj)
	}

	private setupVignette(dddLevel): void {
		for (let i = 0; i < 4; ++i) {
			const sprite: Sprite = new Sprite(this.vignette.texture)
			sprite.name = 'vignette-tex'
			this.vignette.container.addChild(sprite)
			if (i === 0) {
				sprite.position.set(0, 0)
				sprite.rotation = 0
			} else if (i === 1) {
				sprite.position.set(this.vignette.width, 0)
				sprite.rotation = Math.PI / 2
			} else if (i === 2) {
				sprite.position.set(this.vignette.width, this.vignette.height)
				sprite.rotation = Math.PI
			} else if (i === 3) {
				sprite.position.set(0, this.vignette.height)
				sprite.rotation = (3 * Math.PI) / 2
			}
		}
	}
}
