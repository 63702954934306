import { BuffIdentifier } from './buff.shared'
import { BuffDefinition } from './buff-definition'
import { StackStyle } from './buff-enums'
import { ClientBuff } from '../client/buff.client'
import StatOperatorType from '../../loot/shared/stat-operator-type'

export const LIFEBLOOM_REGEN_TICK_INTERVAL = 2500
export const LIFEBLOOM_REGEN_DURATION = 10000
export const FINAL_COUNTDOWN_TICK_INTERVAL = 3000

export const PlayerBuffs: BuffDefinition[] = [
	new BuffDefinition({
		identifier: BuffIdentifier.SkillMovementSpeed,
		duration: 6000,
		startingStacks: 1,
		stackStyle: StackStyle.RollingStackDurationSeparately,
		reapplyStacks: 1,
		reapplyDuration: 6000,
		showToClient: true,
		cspEnabled: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.OnKillMovementSpeed,
		duration: 4000,
		startingStacks: 1,
		stackStyle: StackStyle.IncreaseDuration,
		reapplyStacks: 0,
		reapplyDuration: 3000,
		capDuration: 20000,
		showToClient: true
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.OnShieldBreakMovementSpeed,
		duration: 5000,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyStacks: 0,
		reapplyDuration: 5000,
		showToClient: true,
		tickInterval: 250,	
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.SkillBerserk,
		duration: 4000,
		startingStacks: 1,
		reapplyStacks: 1,
		reapplyDuration: 4000,
		stackStyle: StackStyle.RefreshDuration,
		showToClient: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.SkillTumbleRoll,
		duration: 750,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		showToClient: true,
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.SkillOverchargedShot,
		duration: 10000,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyDuration: 10000,
		reapplyStacks: 1,
		showToClient: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.SkillBattleCry,
		duration: 6000,
		startingStacks: 1,
		stackStyle: StackStyle.RollingStackDurationSeparately,
		reapplyStacks: 1,
		reapplyDuration: 6000,
		showToClient: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.SuperBeam,
		duration: 2000,
		startingStacks: 1,
		stackStyle: StackStyle.RollingStackDurationSeparately,
		reapplyStacks: 1,
		reapplyDuration: 2000,
		showToClient: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.SkillSickeningNova,
		duration: 3000,
		startingStacks: 1,
		reapplyStacks: 1,
		reapplyDuration: 3000,
		stackStyle: StackStyle.RefreshDuration,
		showToClient: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.StartRunMovementSpeed,
		duration: 5 * 60 * 1000,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		showToClient: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.StartRunEnchantment,
		duration: Number.MAX_SAFE_INTEGER,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		showToClient: false,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.LifeBloom,
		duration: Number.MAX_SAFE_INTEGER,
		startingStacks: 0,
		stackStyle: StackStyle.None,
		showToClient: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.LifeBloomRegen,
		duration: LIFEBLOOM_REGEN_DURATION,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyStacks: 1,
		reapplyDuration: LIFEBLOOM_REGEN_DURATION,
		showToClient: true,
		tickInterval: LIFEBLOOM_REGEN_TICK_INTERVAL,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.RegenerativeHealing,
		duration: Number.MAX_SAFE_INTEGER,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		showToClient: true,	
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.QueensRansom,
		duration: Number.MAX_SAFE_INTEGER,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyStacks: 1,
		reapplyDuration: Number.MAX_SAFE_INTEGER,
		showToClient: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.QueensRansomLegendary,
		duration: Number.MAX_SAFE_INTEGER,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyStacks: 1,
		reapplyDuration: Number.MAX_SAFE_INTEGER,
		showToClient: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.FinalCountdown,
		duration: Number.MAX_SAFE_INTEGER,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyStacks: 1,
		reapplyDuration: Number.MAX_SAFE_INTEGER,
		showToClient: true,
		tickInterval: FINAL_COUNTDOWN_TICK_INTERVAL,
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.IronMan,
		duration: Number.MAX_SAFE_INTEGER,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyStacks: 1,
		reapplyDuration: Number.MAX_SAFE_INTEGER,
		showToClient: true,
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.Pauper,
		duration: Number.MAX_SAFE_INTEGER,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyStacks: 1,
		reapplyDuration: Number.MAX_SAFE_INTEGER,
		showToClient: true,
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.RagsToRiches,
		duration: Number.MAX_SAFE_INTEGER,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyStacks: 1,
		reapplyDuration: Number.MAX_SAFE_INTEGER,
		showToClient: true,
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.OutpostLuckyBoon,
		duration: 15 * 60 * 1000,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyStacks: 1,
		reapplyDuration: 15 * 60 * 1000,
		showToClient: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.OutpostHunterHunted,
		duration: 4 * 60 * 1000,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		showToClient: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.OutpostGottaGoFast,
		duration: 3 * 60 * 1000,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		showToClient: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.OutpostPoisonApple,
		duration: 5 * 60 * 1000,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		reapplyStacks: 1,
		reapplyDuration: 0,
		tickInterval: 5000, // 60 ticks in 5 minutes
		showToClient: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.OutpostIronShield,
		duration: 5 * 60 * 1000,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		showToClient: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.OutpostMagmaShield,
		duration: 5 * 60 * 1000,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		showToClient: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.OutpostFrostShield,
		duration: 5 * 60 * 1000,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		showToClient: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.OutpostToxicShield,
		duration: 5 * 60 * 1000,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		showToClient: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.OutpostElectricShield,
		duration: 5 * 60 * 1000,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		showToClient: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.SkillOvershield,
		duration: 3500,
		startingStacks: 1,
		reapplyStacks: 1,
		reapplyDuration: 3500,
		stackStyle: StackStyle.RefreshDuration,
		showToClient: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.SkillStoneForm,
		duration: 4000,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyStacks: 1,
		reapplyDuration: 4000,
		showToClient: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.SkillBattery,
		duration: 6000,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		showToClient: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.FlagDoubleTap,
		duration: 2000,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		showToClient: false,
		cspEnabled: true,
		cspApplyFn(buff: ClientBuff) {
			buff.state = {
				bonus1: null
			}
		},
		cspUpdateStacksFn(buff: ClientBuff, oldStacks: number, newStacks: number) {
			if(!buff.state.bonus1) {
				buff.state.bonus1 = buff.appliedTo.myPlayerData.stats.addStatBonus('attackRate', StatOperatorType.MULTIPLY, 1 * newStacks)
			}
		},
		cspWearOffFn(buff: ClientBuff) {
			buff.state.bonus1.remove()
		}
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.FlagTripleTap,
		duration: 2000,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		showToClient: false,
		cspEnabled: true,
		cspApplyFn(buff: ClientBuff) {
			buff.state = {
				bonus1: null,
			}
		},
		cspUpdateStacksFn(buff: ClientBuff, oldStacks: number, newStacks: number) {
			if(!buff.state.bonus1) {
				buff.state.bonus1 = buff.appliedTo.myPlayerData.stats.addStatBonus('attackRate', StatOperatorType.MULTIPLY, 1 * newStacks)
			}
		},
		cspWearOffFn(buff: ClientBuff) {
			buff.state.bonus1.remove()
		}
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.PressureCreepingWeakness,
		duration: Number.MAX_SAFE_INTEGER,
		lastsForever: true,
		tickInterval: 60000, //60 seconds
		startingStacks: 1,
		stackStyle: StackStyle.None,
		showToClient: false,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.PressureThirstyVampire,
		duration: Number.MAX_SAFE_INTEGER,
		lastsForever: true,
		tickInterval: 5000, //5 seconds
		startingStacks: 1,
		stackStyle: StackStyle.None,
		showToClient: false,
	}),

	// Faction Buffs
	// persistent (regardless of win/lose)
	// @TODO design: Give these stuff 
	new BuffDefinition({
		identifier: BuffIdentifier.FactionAurumPersist,
		duration: Number.MAX_SAFE_INTEGER,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		showToClient: false,
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.FactionDawnPersist,
		duration: Number.MAX_SAFE_INTEGER,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		showToClient: false,
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.FactionIronPersist,
		duration: Number.MAX_SAFE_INTEGER,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		showToClient: false,
	}),
]

Object.freeze(PlayerBuffs)

