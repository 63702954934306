import { NengiClient } from '../../engine/client/nengi-client'
import EquipGearToSlotCommand from '../../items/shared/equip-gear-to-slot-command'
import EquipWeaponToSlotCommand from '../../items/shared/equip-weapon-to-slot-command'
import logger from '../../utils/client-logger'
import { findGap } from '../../utils/array-util'
import { uuid } from '../../utils/primitive-types'

interface StashUIState {
	selectedStashItem: uuid
	ftueMode: boolean
}

export const stashUIState = () => {
	logger.debug('Initializing Outpost Worm Mail UI module')
	return {
		namespaced: true,
		state: {
			selectedStashItem: null,
			ftueMode: false,
		},
		mutations: {
			setFtueMode(state: StashUIState, enabled) {
				state.ftueMode = enabled
			},
		},
		getters: {
			selectedStashItem(state: StashUIState) {
				return state.selectedStashItem
			},
		},
		actions: {
			showSelectedStashItem(context, selectedItem?: uuid) {
				const { state, commit, dispatch } = context as { state: StashUIState; commit: any; dispatch }
				if (selectedItem && selectedItem !== state.selectedStashItem) {
					dispatch('toggleSelectStashItem', selectedItem)
				}
			},
			toggleSelectStashItem(context, selectedItem?: uuid) {
				const { state, commit } = context as { state: StashUIState; commit: any }
				if (selectedItem && selectedItem !== state.selectedStashItem) {
					commit(
						'itemComparison/selectItem',
						{
							itemId: selectedItem,
							container: 'stash',
						},
						{ root: true },
					)
					state.selectedStashItem = selectedItem
				} else {
					commit('itemComparison/deselectItem', {}, { root: true })
					state.selectedStashItem = null
				}
			},
			equipItemIntoSlot({ commit, rootState, state, dispatch }, itemId) {
				const selectedPaperdollItemIndex = rootState.miniPaperdoll?.selectedMiniPaperdollIndex
				const numberOfItemsEquipped = rootState.itemContainers?.paperdoll.length
				const itemsInStash = rootState.itemContainers?.stash
				const itemDetails = itemsInStash.filter((item) => item.id === itemId)

				if (selectedPaperdollItemIndex === null || selectedPaperdollItemIndex === undefined || (numberOfItemsEquipped >= 2 && numberOfItemsEquipped <= 4 && itemDetails.itemType === 'Gear')) {
					const paperdollIndices = rootState.itemContainers?.paperdoll.map((item) => item.paperdollSlotIndex)
					let firstFreeGearSlot = findGap(paperdollIndices)
					if (firstFreeGearSlot === -1) {
						firstFreeGearSlot = numberOfItemsEquipped
					}

					const badSlot = firstFreeGearSlot < 2 // weapon slot?
					if (badSlot) {
						logger.error(`bad slot:${firstFreeGearSlot} auto-finding slot for gear equip`)
						return
					}

					commit('itemLocks/lockItem', itemId, { root: true })
					dispatch('toggleSelectStashItem')
					dispatch('miniPaperdoll/selectMiniPaperdollIndex', null, { root: true })
					NengiClient.getInstance().sendCommand(new EquipGearToSlotCommand(itemId, firstFreeGearSlot))
				}

				if (selectedPaperdollItemIndex === 0 || selectedPaperdollItemIndex === 1) {
					// equipping a weapon
					commit('itemLocks/lockItem', itemId, { root: true })
					dispatch('toggleSelectStashItem')
					dispatch('miniPaperdoll/selectMiniPaperdollIndex', null, { root: true })
					NengiClient.getInstance().sendCommand(new EquipWeaponToSlotCommand(itemId, selectedPaperdollItemIndex))
				} else if (selectedPaperdollItemIndex >= 2) {
					// equipping gear
					// equipping a weapon
					commit('itemLocks/lockItem', itemId, { root: true })
					dispatch('toggleSelectStashItem')
					dispatch('miniPaperdoll/selectMiniPaperdollIndex', null, { root: true })
					NengiClient.getInstance().sendCommand(new EquipGearToSlotCommand(itemId, selectedPaperdollItemIndex))
				}
			},
			embedAugmentIntoWeapon() {},
			removeAugmentFromWeapon() {},
		},
	}
}
