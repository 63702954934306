import { reduce } from 'lodash'
import { throwIfFalsy } from '../../../utils/math'
import { pressureRank, timeInMilliseconds, WorldTier } from '../../../utils/primitive-types'
import Time from '../time'
import { MAXIMUM_WORM_MAIL_BOX_SIZE } from './inventory'
import {
	PressureLoadout,
	pressureStatInfos,
	IPressureStatInfo,
	PressureStat,
	PRESSURE_HARSH_ADDITIONAL_DAMAGE_PER_RANK,
	PRESSURE_SAVAGERY_ADDITIONAL_DAMAGE_PER_RANK,
	PRESSURE_BRUTALITY_ADDITIONAL_DAMAGE_PER_RANK,
	PRESSURE_POINTS_PER_TIER,
	PRESSURE_TINY_WORM_ITEMS_ARRAY,
	PRESSURE_SUPREME_BARRIER_ADDITIONAL_HEALTH_PER_RANK,
	PRESSURE_ARMORED_ADDITIONAL_HEALTH_PER_RANK,
	PRESSURE_TANKY_ADDITIONAL_HEALTH_PER_RANK,
	PRESSURE_LOST_WANDERER_SECONDS_TO_DECREASE,
	PRESSURE_LOST_WANDERER_PERCENT_DECREASE,
} from './pressure-stat-info.shared'

export function debugPrintPressureLoadOut(loadout: PressureLoadout) {
	let s = 'Player Loadout:\n'
	for (const stat in loadout) {
		if (Object.prototype.hasOwnProperty.call(loadout, stat)) {
			const ranks = loadout[stat] as pressureRank
			const pressureInfo = pressureStatInfos[stat] as IPressureStatInfo
			const rankString = ranks > 0 ? pressureInfo.rankStrings[ranks - 1] : ''
			s += ` ${pressureInfo.prettyName} rands:${ranks} ${rankString} (points:${ranks * pressureInfo.pointsPerRank})\n`
		}
	}
	console.log(s)
}

function checkPressureStatRankStringsValid() {
	for (const key in pressureStatInfos) {
		if (Object.prototype.hasOwnProperty.call(pressureStatInfos, key)) {
			const element = pressureStatInfos[key] as IPressureStatInfo
			if (Array.isArray(element.rankStrings)) {
				throwIfFalsy(element.rankStrings.length === element.ranks[element.ranks.length - 1], `${element.prettyName} has incorrect ranks or rankStrings count`)
			}
		}
	}
}
checkPressureStatRankStringsValid()

export function pressureFillOutPressureLoadout(pressureLoadout: PressureLoadout) {
	for (const pressureStat in pressureStatInfos) {
		if (pressureStatInfos.hasOwnProperty(pressureStat)) {
			if (!pressureLoadout.hasOwnProperty(pressureStat)) {
				pressureLoadout[pressureStat] = 0
			}
		}
	}
}

export function pressureGetNumPointsFromLoadout(loadout: PressureLoadout) {
	const result = reduce(
		loadout,
		(prev, ranks, stat) => {
			const info = pressureStatInfos[stat] as IPressureStatInfo
			return prev + ranks * info.pointsPerRank
		},
		0,
	)
	if (isFinite(result)) {
		// reduce will return NaN if loadout is empty
		return result
	} else {
		return 0
	}
}

export function pressureAdditionalWorldTiersFromLoadout(loadout: PressureLoadout): WorldTier {
	const points = pressureGetNumPointsFromLoadout(loadout)
	const extraTiers = Math.floor(points / PRESSURE_POINTS_PER_TIER)
	return extraTiers
}

export function pressureGetWormMailSize(loadout: PressureLoadout) {
	const tinyWormRanks = getRanks(loadout, PressureStat.TinyWorm)
	if (tinyWormRanks > 0) {
		return MAXIMUM_WORM_MAIL_BOX_SIZE - PRESSURE_TINY_WORM_ITEMS_ARRAY[tinyWormRanks - 1]
	}
	return MAXIMUM_WORM_MAIL_BOX_SIZE
}

export function pressureGetZoomModifier(loadout: PressureLoadout) {
	const badEyesRanks = getRanks(loadout, PressureStat.BadEyes)
	if (badEyesRanks > 0) {
		const t = Time.timeElapsedSinceModeStartInSeconds
		const zoomPer60SecondsSecond = [0.05][badEyesRanks - 1]
		const zoomDecrease = Math.floor(t / 60) * zoomPer60SecondsSecond
		//console.log({ t, zoomDecrease })
		return 1 + zoomDecrease
	}
	return 1
}

function getRanks(loadout: PressureLoadout, stat: PressureStat) {
	return loadout[stat] || 0
}

export function pressureGetNormalEnemyDamageMod(loadout: PressureLoadout) {
	const harshRanks = getRanks(loadout, PressureStat.Harsh)
	return 1 + harshRanks * PRESSURE_HARSH_ADDITIONAL_DAMAGE_PER_RANK
}

export function pressureGetChampionDamageMod(loadout: PressureLoadout) {
	const savageryRanks = getRanks(loadout, PressureStat.Savagery)
	return 1 + savageryRanks * PRESSURE_SAVAGERY_ADDITIONAL_DAMAGE_PER_RANK
}

export function pressureGetBossDamageMod(loadout: PressureLoadout) {
	const brutalityRanks = getRanks(loadout, PressureStat.Brutality)
	return 1 + brutalityRanks * PRESSURE_BRUTALITY_ADDITIONAL_DAMAGE_PER_RANK
}

export function pressureGetSkillUsageDamage(loadout: PressureLoadout, cooldown: timeInMilliseconds) {
	const cursedArtifactsRanks = getRanks(loadout, PressureStat.CursedArtifacts)
	if (cursedArtifactsRanks > 0) {
		const cooldownSeconds = cooldown * 0.001
		return cooldownSeconds * 0.5
	}
	return 0
}

export function pressureGetBossHealthBonus(loadout: PressureLoadout) {
	const ranks = getRanks(loadout, PressureStat.SupremeBarrier)
	return ranks * PRESSURE_SUPREME_BARRIER_ADDITIONAL_HEALTH_PER_RANK
}

export function pressureGetChampionHealthBonus(loadout: PressureLoadout) {
	const ranks = getRanks(loadout, PressureStat.Armored)
	return ranks * PRESSURE_ARMORED_ADDITIONAL_HEALTH_PER_RANK
}

export function pressureGetAllEnemyHealthBonus(loadout: PressureLoadout) {
	const ranks = getRanks(loadout, PressureStat.Tanky)
	return ranks * PRESSURE_TANKY_ADDITIONAL_HEALTH_PER_RANK
}

export function pressureGetLostWandererItems(loadout: PressureLoadout, playerTimeJoined: number) {
	const ranks = getRanks(loadout, PressureStat.LostWanderer)
	if (ranks > 0) {
		const passedForPlayer = Math.floor((Time.timeElapsedSinceStartupInSeconds - playerTimeJoined) / PRESSURE_LOST_WANDERER_SECONDS_TO_DECREASE)
		const percentDecrease = PRESSURE_LOST_WANDERER_PERCENT_DECREASE[ranks - 1]
		return Math.clamp(passedForPlayer * percentDecrease, 0, 1)
	}

	return 0
}
