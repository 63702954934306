import logger from '../../utils/client-logger'
import { UI } from '../ui'
import { Audio } from '../../audio/client/audio'
import { PromptMessage } from './prompt-message'
import { debounce } from 'lodash'
import { WaitCondition } from './WaitCondition'

export const clearTutTooltipMessages = () => {
	UI.getInstance().emitEvent('tutTooltip/clearAllMessages')
}

export const showTutTooltipPrompt = (title: string, description: string[], target: string, position: string, okButtonText?: string, dangleStyle?: string, callback?: () => void) => {
	const prompt = {
		title,
		description,
		okButtonText,
	}
	UI.getInstance().emitAsyncEvent('tutTooltip/showDismissableMessageSequence', { prompts: [prompt], callback, target, position, dangle: dangleStyle })
}

export const showTutTooltipSequence = (prompts: PromptMessage[], target: string, position: string, dangleStyle?: string, callback?: () => void) => {
	UI.getInstance().emitAsyncEvent('tutTooltip/showDismissableMessageSequence', { prompts, callback, target, position, dangle: dangleStyle })
}

export const showTutTooltipWaitForMessage = (title: string, description: string[], target: string, position: string, waitForCondition: WaitCondition, dangleStyle?: string, callback?: () => void) => {
	UI.getInstance().emitAsyncEvent('tutTooltip/showWaitForMessage', { title, description, waitForCondition, callback, target, position, dangle: dangleStyle })
}

export const postWaitForCondition = (condition: WaitCondition) => {
	// make event manager ...?
	UI.getInstance().emitEvent('tutTooltip/postWaitForCondition', condition)
}

const resizedDebounce = debounce(() => {
	UI.getInstance().emitEvent('tutTooltip/rebuildOffsets')
}, 300)

window.addEventListener('resize', resizedDebounce)

const ELEMENT_IDS_BY_TARGET = {
	weaponsAndGear: 'active-weapons-tutorial-tooltip-target',
	health: 'health-bar-tutorial-tooltip-target',
	shields: 'shields-tutorial-tooltip-target',
	firstWeapon: 'first-weapon-tutorial-tooltip-target',
	stashSlot: 'stash-first-row-arrow-target',
	weapon2: 'mini-paperdoll-weapon-2-arrow-target',
	weapon2AugmentButton: 'mini-paperdoll-weapon-2-augment-arrow-target',
	augmentRow: 'augment-row-arrow-target',
	newbieAugment: 'augment-station-aug1-arrow-target',
	addAugments: 'augment-station-add-augments-arrow-target',
	wormDeliveryStash: 'worm-delivery-stash-arrow-target',
	wormDeliveryFurnace: 'worm-delivery-furnace-arrow-target',
	wormDeliveryMarket: 'worm-delivery-market-arrow-target',
	wormDeliveryPit: 'worm-delivery-pit-arrow-target',
	lootLevelMeter: 'loot-level-meter-arrow-target',
	baneOptions: 'bane-options-arrow-target',
	mtxCustomizeButton: 'mtx-customize-btn',
	mtxNFTButton: 'mtx-nft-btn',
	mtxFilterDropDown: 'mtx-drop-down'
}

const STATIC_X_OFFSET = 0
const STATIC_X_OFFSET_FOR_LEFT_POSITIONED_TOOLTIPS = 0
const STATIC_Y_OFFSET_FOR_BOTTOM_POSITIONED_TOOLTIPS = 100
const STATIC_Y_OFFSET_FOR_TOP_POSITIONED_TOOLTIPS = -100

export const tutorialTooltipState = () => {
	logger.debug('Initializing tutorial tooltip module...')
	return {
		namespaced: true,
		state: {
			promptVisible: false,
			waitForMessageTitle: 'wait title',
			waitForMessageDescription: 'wait description',
			waitForMessageVisible: false,
			waitForCondition: null,
			waitCallback: () => {
				console.log('default wait callback')
			},
			prompts: [],
			callback: () => {
				console.log(`default prompt handler`)
			},
			target: '',
			position: 'bottom',
			topOffset: '0',
			botOffset: '0',
			leftOffset: '0',
			rightOffset: '0',
			customDangle: false,
			dangle: 'right: 52px;',
		},
		getters: {
			getTitle(state: any) {
				if (state.promptVisible) {
					return state.prompts[0].title
				}

				return state.waitForMessageTitle
			},
			getDescription(state: any) {
				if (state.promptVisible) {
					return state.prompts[0].description
				}

				return state.waitForMessageDescription
			},
			getAdvancedTooltipVisible(state: any) {
				return state.promptVisible || state.waitForMessageVisible
			},
			getOkButtonText(state: any) {
				if (state.prompts.length > 0) {
					const text = state.prompts[0].okButtonText
					if (text && text.length > 0) {
						return text
					}
				}
				return 'OKAY'
			},
			getShowButton(state: any) {
				return state.prompts.length > 0
			},
			getDangleStyle(state: any) {
				if (state.customDangle) {
					return state.dangle
				}
				return ''
			},
		},
		mutations: {
			clearAllMessages(state) {
				state.proximityMessageVisible = false
				state.promptVisible = false
				state.prompts.length = 0
			},
			postWaitForCondition(state: any, condition: WaitCondition) {
				if (state.waitForCondition === condition) {
					state.waitForMessageVisible = false
					state.waitForCondition = null
					const callback = state.waitCallback
					state.waitCallback = null
					if (callback) {
						callback()
					}
				}
			},
			rebuildOffsets(state: any) {
				if (!state.promptVisible && !state.waitForMessageVisible) {
					return
				}

				const elementId = ELEMENT_IDS_BY_TARGET[state.target]
				if (elementId === undefined) {
					console.error(`Unknown mapping from tutorial tooltip target ${state.target} to DOM element. Did you forget to add it to "elementIdsByTarget" mapping?`)
					this.topOffset = 0
					this.leftOffset = 0
					return
				}

				const attachedElement = document.querySelector(`#${elementId}`)

				const bodyRect = document.body.getBoundingClientRect()
				const elemRect = attachedElement.getBoundingClientRect()
				const leftOffset = elemRect.left - bodyRect.left

				const scale = UI.getInstance().store.getters['UIScale/currentScale'] + 0.15

				if (state.position === 'top') {
					const botOffset = bodyRect.bottom - elemRect.bottom
					const rightOffset = bodyRect.right - elemRect.right
					state.botOffset = `${botOffset + STATIC_Y_OFFSET_FOR_BOTTOM_POSITIONED_TOOLTIPS * scale}px`
					state.rightOffset = `${rightOffset + STATIC_X_OFFSET * scale}px`
				} else if (state.position === 'bottom') {
					const topOffset = elemRect.top - bodyRect.top
					state.topOffset = `${topOffset + STATIC_Y_OFFSET_FOR_BOTTOM_POSITIONED_TOOLTIPS * scale}px`
					state.leftOffset = `${leftOffset + STATIC_X_OFFSET * scale}px`
				} else {
					//?
					const topOffset = elemRect.top - bodyRect.top
					state.topOffset = `${topOffset}px`
					state.leftOffset = `${leftOffset}px`
				}
			},
		},
		actions: {
			closeTooltipPrompt({ state }) {
				state.prompts.shift()

				state.promptVisible = state.prompts.length > 0
				if (!state.promptVisible) {
					const oldCallback = state.callback
					state.callback = null
					if (oldCallback) {
						oldCallback()
					}
				}
			},
			showDismissableMessageSequence({ state, commit }, param: any) {
				Audio.getInstance().playSfx('UI_Dialog_Minor_Open')
				state.prompts = param.prompts
				state.callback = param.callback
				state.target = param.target
				state.position = param.position

				state.promptVisible = true

				if (param.dangle) {
					state.customDangle = true
					state.dangle = param.dangle
				} else {
					state.customDangle = false
				}

				commit('rebuildOffsets')
			},
			showWaitForMessage({ state, commit }, param: any) {
				const { waitForCondition, title, description, callback, target, position, dangle } = param

				state.waitForCondition = waitForCondition
				state.waitForMessageTitle = title
				state.waitForMessageDescription = description
				state.waitCallback = callback
				state.waitForMessageVisible = true
				state.target = target
				state.position = position

				if (dangle) {
					state.customDangle = true
					state.dangle = dangle
				} else {
					state.customDangle = false
				}

				commit('rebuildOffsets')
			},
		},
	}
}
