import nengi from 'nengi'

class WorldBiomeGridCommand {
	static protocol = {
		gridSize: nengi.Float32,
	}

	gridSize: number

	constructor(gridSize) {
		this.gridSize = gridSize
	}
}

export default WorldBiomeGridCommand
