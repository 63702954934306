import { FTUESequence } from "./ftue.shared";

export const TUTORIAL_CONFIG: Record<FTUESequence, any> = {
	[FTUESequence.FirstAdventureRunIntro]: {
		name: 'startedFirstRunControls',
		completeAfter: 'wormMailSend',
	},
	//2,3 cut
	[FTUESequence.LootDropped]: {
		name: 'firstMonsterDroppedLoot',
		completeAfter: 'wormMailSend',
	},
	[FTUESequence.LootInInventoryGoToOutpost]: {
		name: 'firstLootAcquired',
		completeAfter: 'wormMailSend',
	},
	[FTUESequence.FullInventory]: {
		name: 'firstFullInventory',
		completeAfter: 'lootTossed',
	},
	[FTUESequence.NearOutpost]: {
		name: 'outpostProximity',
		completeAfter: 'wormMailSend',
	},
	[FTUESequence.InsideOutpost]: {
		name: 'firstEnteredOutpost',
		completeAfter: 'wormMailSend',
	},
	//9 cut
	[FTUESequence.FirstWormMail]: {
		name: 'openedWormMailNPC',
		completeAfter: 'wormMailSend',
	},
	[FTUESequence.OutpostShop]: {
		name: 'openedOutpostShopNPC',
		completeAfter: 'outpostShopOpened',
	},
	[FTUESequence.EndRunGuyPriorToWormMail]: {
		name: 'openedEndRunNPC',
		completeAfter: 'endRunUsed',
	},
	[FTUESequence.BossFogWall]: {
		name: 'fogWallProximity',
		completeAfter: 'fogWallSeen',
	},
	[FTUESequence.ClearedFogWall]: {
		name: 'transistionedBetweenBiomes',
		completeAfter: 'wentToNextBiome',
	},
	[FTUESequence.BossIntro]: {
		name: 'bossArenaProximity',
		completeAfter: 'defeatedAnyBoss',
	},
	//16 cut
	[FTUESequence.POIIntro]: {
		name: 'poiProximity',
		completeAfter: 'defeatedPOI',
	},
	// == Town ==
	[FTUESequence.HubWorldIntro]: {
		name: 'enteredHubWorld',
		completeAfter: 'hasEquippedAnAugment',
	},
	[FTUESequence.NearStash]: {
		name: 'stashProximity',
		completeAfter: 'hasEquippedAnAugment',
	},
	[FTUESequence.AugmentWeapon]: {
		name: 'openedStashNPC',
		completeAfter: 'hasEquippedAnAugment',
	},
	[FTUESequence.WormDeliveryNPCProximity]: {
		name: 'wormDeliveryProximity',
		completeAfter: 'openedWormDelivery',
	},
	[FTUESequence.WormDeliveryIntro]: {
		name: 'openedWormDeliveryNPC',
		completeAfter: 'openedWormDelivery',
	},
	[FTUESequence.BoatLaunchIntro]: {
		name: 'openedDepartureNPC',
		completeAfter: 'hasDepartedOnARun',
	},
	[FTUESequence.SmeltingFurnaceIntro]: {
		name: 'openedSmeltingFurnaceNPC',
		completeAfter: 'hasSmeltedAnyItem',
	},
	[FTUESequence.MarketplaceIntro]: {
		name: 'openedMarketplaceNPC',
		completeAfter: 'hasOpenedMarketplace',
	},
	[FTUESequence.MTXShopIntro]: {
		name: 'openedMTXStoreNPC',
		completeAfter: 'hasOpenedMtxStore',
	},
	[FTUESequence.GeneralStoreIntro]: {
		name: 'openedGeneralStoreNPC',
		completeAfter: 'hasOpenedGeneralStore',
	},
	[FTUESequence.IndentificationIntro]: {
		name: 'openedIdentificationNPC',
		completeAfter: 'hasIdentifiedAnItem',
	},
	[FTUESequence.TrashPitIntro]: {
		name: 'openedPitOfChancesNPC',
		completeAfter: 'hasThrownAnItemIntoThePit',
	},
	[FTUESequence.CosmeticIntro]: {
		name: 'openedCosmeticsNPC',
		completeAfter: 'hasOpenedCosmeticsUI',
	},
	[FTUESequence.LeaderboardIntro]: {
		name: 'openedLeaderboardNPC',
		completeAfter: 'hasOpenedLeaderboardUI',
	},
	// == Run 2 ==
	[FTUESequence.SecondAdventureRunIntro]: {
		name: 'startedSecondRun',
		completeAfter: 'hasEnteredSecondRun',
	},
	//33,34,35 cut
	[FTUESequence.EndWorldInaccessibleProximity]: {
		name: 'worldEdgeBridgeEntranceProximity',
		completeAfter: 'worldEndUsed',
	},
	[FTUESequence.EndWorldAccessibleProximity]: {
		name: 'worldEdgeBridgeProximity',
		completeAfter: 'worldEndUsed',
	},
	[FTUESequence.PlayerLowHealth]: {
		name: 'lowHealthInOutpost',
		completeAfter: 'boughtHealing',
	},
	// == Hub 2 ==
	[FTUESequence.PartyPlayIntro]: {
		name: 'partyPlay',
		completeAfter: 'partyPlayDone',
	},
	[FTUESequence.DifficultyIntro]: {
		name: 'prePPD',
		completeAfter: 'prePPDDone',
	},
	[FTUESequence.SoulCyclesUnlocked]: {
		name: 'ppdExplained',
		completeAfter: 'ppdExplainedDone',
	},
	[FTUESequence.HubWorldServersOfflineIntro]: {
		name: 'hubWorldServersOfflineIntro',
		completeAfter: 'hasDepartedOnARun',
	},
}
