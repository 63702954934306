














import { mapGetters, mapState } from 'vuex'
import moment from 'moment'
import { toCountdownString } from '../../utils/math'
import { Moment } from 'moment-timezone'

export default {
	name: 'StormTimer',
    data() {
        return {

        }
    },
	computed: {
		...mapGetters('UIScale', ['currentScale']),
		...mapGetters('activeGameWindows', ['isInAGW', 'isAGWEnabled', 'nextAGWStartOrEnd']),
        ...mapState('activeGameWindows', ['activeGameWindowStart', 'activeGameWindowEnd', 'nextActiveGameWindowStart', 'nextActiveGameWindowEnd']),
        ...mapState('time', ['nowMoment']),
        countdown() {
            // now - nextStart or End
            const nextAGWMoment: Moment = this.nextAGWStartOrEnd(this.nowMoment)
            if(nextAGWMoment) {
                const diff = nextAGWMoment.diff(this.nowMoment, 'seconds')
                return toCountdownString(diff, false)
            }
            return '00:00:00'
        },
        stormTextClass() {
            const inAGW = this.isInAGW(this.nowMoment)
            if(inAGW) {
                return 'storm-timer-inactive-text'
            } else {
                return 'storm-timer-active-text'
            }
        },
        stormBadgeClass() {
            const inAGW = this.isInAGW(this.nowMoment)
            if(inAGW) {
                return 'badge-nostorm'
            } else {
                return 'badge-storm'
            }
        }
	},
	methods:{
        
	}
}
