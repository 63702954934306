import GroundItemEntity from '../shared/ground-item-entity.shared'
import { Graphics, Text } from 'pixi.js'
import ItemRarity from '../../loot/shared/item-rarity'
import { ITEM_COLLIDER_RADIUS } from '../../engine/shared/game-data/item'
import { clientConfig } from '../../engine/client/client-config'
import ItemType from '../../loot/shared/item-type'
import WeaponSubType from '../../loot/shared/weapon-sub-type'
import { RiggedSpineModel } from '../../models-animations/client/spine-model'
import playAnimation from '../../models-animations/client/play-animation'
import GearSubType from '../../loot/shared/gear-sub-type'
import BiomeKeyType from '../../loot/shared/biome-key'
import { AnimationTrack } from '../../models-animations/shared/animation-track'
import { AssetManager } from '../../asset-manager/client/asset-manager'
import logger from '../../utils/client-logger'
import Renderer from '../../engine/client/graphics/renderer'
import { tryShowTutorial } from '../../ui/state/ftue-manager.state'
import { simpleAnimation_addAnimation, simpleAnimation_removeAnimations } from '../../utils/simple-animation-system'
import { NengiClient } from '../../engine/client/nengi-client'
import ChatCommand from '../../chat/shared/chat-command'
import { Audio } from '../../audio/client/audio'
import { PowerUpSubType } from '../../loot/shared/power-up-sub-type'
import { getAnimTrackString } from '../../debug/client/debug-client'
import { PrizeSubType } from '../../loot/shared/prize-sub-type'
import { WormHornSubtype } from '../../loot/shared/worm-horn-sub-type'
import { CurrencyBagSubType } from '../../loot/shared/currency-bag-sub-type'
import { FTUESequence } from '../../ftue/shared/ftue.shared'
import { PersistentBuffSubType } from '../../loot/shared/persistent-buff-sub-type'

const pickupConfig = {
	speed: 20,
	mag: 0.5,
	weapon: () => NengiClient.getInstance().sendCommand(new ChatCommand('/is 5 weapon nearby')),
	gear: () => NengiClient.getInstance().sendCommand(new ChatCommand('/is 5 gear nearby')),
}
//DevToolsManager.getInstance().setDebugObject(pickupConfig)

const rarityColors = {}
rarityColors[ItemRarity.COMMON] = { color: 0xababab, colorGradient: [0xababab], dropShadowBlur: 0, dropShadowColor: 0x2a313e, strokeColor: 0x2a313e }
rarityColors[ItemRarity.UNCOMMON] = { color: 0x1eff00, colorGradient: [0x1eff00], dropShadowBlur: 0, dropShadowColor: 0x0a5600, strokeColor: 0x0a5600 }
rarityColors[ItemRarity.RARE] = { color: 0x54dafd, colorGradient: [0x54dafd], dropShadowBlur: 0, dropShadowColor: 0x0f4a59, strokeColor: 0x0f4a59 }
rarityColors[ItemRarity.EPIC] = { color: 0xbd5cff, colorGradient: [0xbd5cff], dropShadowBlur: 0, dropShadowColor: 0x46126a, strokeColor: 0x46126a }
rarityColors[ItemRarity.LEGENDARY] = { color: 0xff8000, colorGradient: [0xff8000, 0xef6500], dropShadowBlur: 0, dropShadowColor: 0x462504, strokeColor: 0x462504 }
rarityColors[ItemRarity.ASTRONOMICAL] = { color: 0xff9eb7, colorGradient: [0xff9eb7, 0x9fe2fa], dropShadowBlur: 0, dropShadowColor: 0x430b19, strokeColor: 0x430b19 }
rarityColors[ItemRarity.CHAOS] = { color: 0x000000, colorGradient: [0x000000, 0x7c0606], dropShadowBlur: 0, dropShadowColor: 0xff346d, strokeColor: 0xff346d }

const raritySkins = {}
raritySkins[ItemRarity.COMMON] = 'common'
raritySkins[ItemRarity.UNCOMMON] = 'uncommon'
raritySkins[ItemRarity.RARE] = 'rare'
raritySkins[ItemRarity.EPIC] = 'epic'
raritySkins[ItemRarity.LEGENDARY] = 'legendary'
raritySkins[ItemRarity.ASTRONOMICAL] = 'astronomical'
raritySkins[ItemRarity.CHAOS] = 'chaos'

const weaponSubTypeSkinNames = {}
weaponSubTypeSkinNames[WeaponSubType.Spellsword] = 'sword'
weaponSubTypeSkinNames[WeaponSubType.Scythe] = 'scythe'
weaponSubTypeSkinNames[WeaponSubType.Staff] = 'staff'
weaponSubTypeSkinNames[WeaponSubType.Wand] = 'wand'
weaponSubTypeSkinNames[WeaponSubType.ArcaneFocus] = 'arcane'
weaponSubTypeSkinNames[WeaponSubType.Crossbow] = 'crossbow'

const rarityPickupPfx = {}
rarityPickupPfx[ItemRarity.COMMON] = 'pickup-pfx-common'
rarityPickupPfx[ItemRarity.UNCOMMON] = 'pickup-pfx-uncommon'
rarityPickupPfx[ItemRarity.RARE] = 'pickup-pfx-rare'
rarityPickupPfx[ItemRarity.EPIC] = 'pickup-pfx-epic'
rarityPickupPfx[ItemRarity.LEGENDARY] = 'pickup-pfx-legendary'
rarityPickupPfx[ItemRarity.ASTRONOMICAL] = 'pickup-pfx-astronomical'
rarityPickupPfx[ItemRarity.CHAOS] = 'pickup-pfx-chaos'

export class ClientGroundItemEntity extends GroundItemEntity {
	model: RiggedSpineModel
	colliderVisual: Graphics
	textLabel: Text
	item: GroundItemEntity
	zIndex: number
	owned: boolean
	pickedUpHandled = false

	x: number
	y: number

	fadeIncrement: number = 0
	fadeSpeed: number = 2
	fadeRange: number = 0.2

	flashThreshold: number = 3
	flashSpeed: number = 0.2

	debugStringText: Text
	lifeRemaining: number = 0

	constructor(groundItemEntity: GroundItemEntity) {
		super()

		this.item = groundItemEntity

		const renderer: Renderer = Renderer.getInstance()

		const spriteId = 'itemIcons'

		if (renderer.gameClientState.myId === this.item.owningEntityId || this.item.owningEntityId === -1) {
			this.owned = true
		} else {
			this.owned = false
		}

		const asset = AssetManager.getInstance().getAssetByName(spriteId)
		if (asset == null) {
			logger.warn(`no asset named ${spriteId}`)
			return
		}

		const spineData: PIXI.spine.core.SkeletonData = asset.spineData
		if (spineData === null) {
			logger.error(`problem with asset ${spriteId}`)
			logger.debug(asset)
			return
		}

		this.x = groundItemEntity.x
		this.y = groundItemEntity.y
		this.name = groundItemEntity.name
		this.zIndex = this.y
		this.lifeRemaining = groundItemEntity.remainingLifespan

		if (!this.owned) {
			return
		}

		let skinName
		if (this.item.itemType === ItemType.Weapon) {
			skinName = raritySkins[this.item.rarity] + '-' + weaponSubTypeSkinNames[this.item.itemSubType]
		} else if (this.item.itemType === ItemType.Gear) {
			const rarity = raritySkins[this.item.rarity] + '-'
			switch (this.item.itemSubType) {
				case GearSubType.Belt: {
					skinName = rarity + 'belt'
					break
				}
				case GearSubType.Pendant: {
					skinName = rarity + 'pendant'
					break
				}
				case GearSubType.Ring: {
					skinName = rarity + 'ring'
					break
				}
				case GearSubType.Trinket: {
					skinName = rarity + 'trinket'
					break
				}
			}
		} else if (this.item.itemType === ItemType.WeaponAugment || this.item.itemType === ItemType.GearAugment) {
			skinName = raritySkins[this.item.rarity] + '-augment'
		} else if (this.item.itemType === ItemType.WeaponEnchantment || this.item.itemType === ItemType.GearEnchantment) {
			skinName = 'enchantment'
		} else if (this.item.itemType === ItemType.BiomeKey) {
			switch (this.item.biome) {
				case BiomeKeyType.BEACH: {
					skinName = 'biomekey-beach'
					break
				}
				case BiomeKeyType.FOREST: {
					skinName = 'biomekey-forest'
					break
				}
				case BiomeKeyType.FUNGI_STEEPS: {
					skinName = 'biomekey-fungisteppes'
					break
				}
				case BiomeKeyType.ONYX_HIGHLANDS: {
					skinName = 'biomekey-onyxhighlands'
					break
				}
				case BiomeKeyType.SUNSET_PRISM: {
					skinName = 'biomekey-sunsetprism'
					break
				}
			}
		} else if (this.item.itemType === ItemType.LuckyShard) {
			skinName = 'lucky-shard'
		} else if (this.item.itemType === ItemType.RarityShard) {
			switch (this.item.rarity) {
				case ItemRarity.EPIC:
					skinName = 'rarityshard-epic'
					break
				case ItemRarity.LEGENDARY:
					skinName = 'rarityshard-legendary'
					break
				case ItemRarity.ASTRONOMICAL:
					skinName = 'rarityshard-astronomical'
					break
			}
		} else if (this.item.itemType === ItemType.PowerUp) {
			switch (this.item.itemSubType) {
				case PowerUpSubType.SmallHeart:
					skinName = 'health-drop'
					break
				case PowerUpSubType.LargeHeart:
					skinName = 'health-drop-large'
					break
			}
		} else if (this.item.itemType === ItemType.Prize) {
			let rarity
			if(this.item.rarity < ItemRarity.LEGENDARY) {
				rarity = raritySkins[this.item.rarity] + '-'
			} else { //@TODO there are no legendary+ skins in the art asset for pet eggs
				rarity = raritySkins[ItemRarity.EPIC] + '-'
			}
			
			switch (this.item.itemSubType) {
				case PrizeSubType.PetEgg:
					skinName = rarity + 'pet-egg'
					break
				case PrizeSubType.WeaponSkin:
					skinName = rarity + 'pet-egg'
					break
				case PrizeSubType.PlayerSkin:
					skinName = getItemIconSkinFromPlayerSkin(this.item.name)
					break
			}
		} else if (this.item.itemType === ItemType.WormHorn) {
			switch (this.item.itemSubType) {
				case WormHornSubtype.Standard:
					skinName = 'wormhorn-basic'
					break
				case WormHornSubtype.Premium:
					skinName = 'wormhorn-premium'
					break
			}
		} else if (this.item.itemType === ItemType.CurrencyBag) {
			switch (this.item.itemSubType) {
				case CurrencyBagSubType.Coins:
					skinName = 'coin'
					break
			}
		} else if (this.item.itemType === ItemType.PersistentBuff) {
			switch (this.item.itemSubType) {
				case PersistentBuffSubType.Enchanting:
					skinName = `potion-rare-enchanting`
					break
				case PersistentBuffSubType.LegendaryEnchanting:
					skinName = `potion-legendary-enchanting`
					break
				case PersistentBuffSubType.LifeBloom:
					skinName = `potion-rare-life-bloom`
					break
				case PersistentBuffSubType.RegenerativeHealing:
					skinName = `potion-epic-regenerative-healing`
					break
				case PersistentBuffSubType.QueensRansom:
					skinName = `scroll-epic-queens-ransom`
					break
				case PersistentBuffSubType.LegendaryQueensRansom:
					skinName = `scroll-legendary-queens-ransom`
					break
				case PersistentBuffSubType.FinalCountdown:
					skinName = `potion-epic-final-countdown`
					break
				case PersistentBuffSubType.IronMan:
					skinName = `potion-epic-ironman`
					break
				case PersistentBuffSubType.Pauper:
					skinName = `scroll-epic-pauper`
					break
				case PersistentBuffSubType.RagsToRiches:
					skinName = `scroll-legendary-rags-to-riches`
			}
		}

		this.model = RiggedSpineModel.allocFromPool(spriteId, skinName)
		this.model.name = skinName
		this.model.zIndex = this.y
		const trackEntry = playAnimation(this.model, AnimationTrack.IDLE)
		// play with random start time so all the bobbing items aren't in sync
		trackEntry.trackTime = Math.random()

		if (clientConfig.renderDebugVisuals) {
			this.colliderVisual = new Graphics()
			this.model.addChild(this.colliderVisual)
		}

		if (this.item.itemType !== ItemType.PowerUp && this.item.itemType !== ItemType.CurrencyBag) {
			const rarityColorVals = rarityColors[this.item.rarity]
			this.textLabel = new Text(this.name, {
				fontFamily: 'Yanone Kaffeesatz',
				fontSize: 26,
				fontWeight: '600',
				align: 'center',
				fill: rarityColorVals.colorGradient,
				letterSpacing: 1,
				dropShadow: true,
				dropShadowAngle: 2,
				dropShadowColor: rarityColorVals.dropShadowColor,
				dropShadowDistance: 4,
				dropShadowBlur: rarityColorVals.dropShadowBlur,
				stroke: rarityColorVals.strokeColor,
				strokeThickness: 3,
				miterLimit: 4,
				padding: 6,
				resolution: 2,
			})
			this.textLabel.y = 15
			this.textLabel.x -= this.textLabel.width / 2
			this.model.addChild(this.textLabel)

			tryShowTutorial(FTUESequence.LootDropped) //first time monster drops loot that you own
		}
		this.redraw()

		renderer.mgRenderer.addDisplayObjectToScene(this.model)

		if (clientConfig.renderAnimDebugVisuals) {
			this.drawGenericDebugVisuals()
		}
	}

	redraw() {
		if (this.colliderVisual) {
			this.colliderVisual.clear()
			this.colliderVisual.lineStyle(4, rarityColors[this.item.rarity].color)
			this.colliderVisual.drawCircle(0, 0, ITEM_COLLIDER_RADIUS)
			this.colliderVisual.endFill()
		}
	}

	update(delta: number) {
		this.lifeRemaining -= delta

		if (this.owned && this.pickedUp && !this.pickedUpHandled) {
			this.pickedUpHandled = true
			const model = this.model

			if (this.itemType !== ItemType.PowerUp) {
				const pfxName = rarityPickupPfx[this.item.rarity]
				Renderer.getInstance().addOneOffEffect(pfxName, this.x, this.y)
				Audio.getInstance().playSfx('SFX_Loot_Pickup')
			} else {
				//@TODO2: powerup sound /pfx
				Audio.getInstance().playSfx('SFX_Loot_Pickup')
			}

			simpleAnimation_addAnimation(this, (t) => {
				const s = 1 + Math.clamp(Math.sin(t * pickupConfig.speed), 0, 1) * pickupConfig.mag
				if (t * pickupConfig.speed > Math.PI) {
					model.visible = false
				}
				model.scale.x = s
				model.scale.y = s
				return s
			})
		}

		if (!this.owned && this.model) {
			this.fadeIncrement += this.fadeSpeed * delta
			this.model.alpha = 0.3 + Math.sin(this.fadeIncrement) * this.fadeRange
		}

		if (this.lifeRemaining <= this.flashThreshold && this.model) {
			if (this.lifeRemaining % (this.flashSpeed * 2) < this.flashSpeed) {
				this.model.visible = true
			} else {
				this.model.visible = false
			}
		}

		if (clientConfig.renderAnimDebugVisuals) {
			const animString = getAnimTrackString(this.model)
			this.debugStringText.text = animString
		}
	}

	onDelete() {
		simpleAnimation_removeAnimations(this)
		if (this.textLabel) {
			this.model.removeChild(this.textLabel)
		}
		if (this.debugStringText) {
			this.model.removeChild(this.debugStringText)
		}
		if (this.colliderVisual) {
			this.model.removeChild(this.colliderVisual)
		}
	}

	private drawGenericDebugVisuals() {
		this.debugStringText = new PIXI.Text('', {
			fontFamily: 'Arial',
			fontSize: 16,
			fill: 0xffffff,
			align: 'left',
		})
		this.debugStringText.x = 60
		this.debugStringText.y = -65
		this.model.addChild(this.debugStringText)
	}
}

export const clientGroundItemEntityHooks = {
	x(entity, newValue): void {
		if (entity.model) {
			entity.model.x = newValue
		}
	},
	y(entity, newValue): void {
		if (entity.model) {
			entity.model.y = newValue
		}
	},
}

function getItemIconSkinFromPlayerSkin(name: string) {
	name = name.toLowerCase()

	// @TODO this is probably going to break some time,
	// but all we have in ground-item-entity is basic info until the item is picked up
	// and adding stuff just for this could add a lot of data later
	if(name.includes('aurum')) {
		return 'gift-box-aurum'
	} else if(name.includes('iron')) {
		return 'gift-box-iron'
	} else if(name.includes('scion')) {
		return 'gift-box-scion'
	}

	return 'epic-pet-egg'
}