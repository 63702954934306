import nengi from 'nengi'

export default class PartyPromoteCommand {
	static protocol = {
		partyId: nengi.String,
		playerId: nengi.String,
	}

	partyId: string
	playerId: string

	constructor(partyId: string, playerId: string) {
		this.partyId = partyId
		this.playerId = playerId
	}
}
