import nengi from 'nengi'
import { timeInMilliseconds } from '../../utils/primitive-types'

class AlertMessage {
	static protocol = {
		message: nengi.String,
		duration: nengi.Number,
	}

	message: string
	duration: number
	color: string // used internally (not from server-> client yet)

	constructor(message: string, duration: timeInMilliseconds = 5000) {
		this.message = message
		this.duration = duration
	}
}

export default AlertMessage
