import nengi from 'nengi'

/** This message is emitted slightly *before* an actual disconnect occurs, because
 *  our current version of nengi does not support an application-level error code,
 *  only a websocket-level error code. The client must read this message and do
 *  something with it (pop-up, etc.).
 */
export default class DisconnectReasonMessage {
	static protocol = {
		message: { type: nengi.String },
	}
	message: string

	constructor(message: string) {
		this.message = message
	}
}
