import nengi from 'nengi'
import { WorldDifficulty } from '../../engine/shared/game-data/world-difficulty'

export default class PartyDepartureMessage {
	static protocol = {
		dns: nengi.String,
		isNewAllocation: nengi.Boolean,
		worldDifficulty: nengi.Int16,
	}

	dns: string
	isNewAllocation: boolean
	worldDifficulty: WorldDifficulty

	constructor(dns: string, isNewAllocation: boolean, worldDifficulty: WorldDifficulty) {
		this.dns = dns
		this.isNewAllocation = isNewAllocation
		this.worldDifficulty = worldDifficulty
	}
}
