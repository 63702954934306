//-------------------------------------------------------
// test abilities

import { BuffIdentifier } from '../../buffs/shared/buff.shared'
import { AnimationTrack } from '../../models-animations/shared/animation-track'
import { Colors } from '../../utils/colors'
import { Ability, AbilitySelectionStyles, AbilityTargets, AbilityTargetSelectionStyle, AbilityType, ActionAbilities } from './action-types'
import { EnemyDefaults } from './enemies/enemy-defaults'
import { ParticleEffectType } from '../../engine/shared/game-data/particle-config'
import { playAnimationAbility } from './enemies/abilities/common-abilities'
import { CameraShakeIntensities } from '../../engine/shared/camera.shared'

export function deepClone<T>(o: T): T {
	return JSON.parse(JSON.stringify(o))
}

const testBuffAbility: Ability = {
	debugName: 'testBuffAbility',
	abilityType: AbilityType.INSTANTLY_APPLY_EFFECT,
	durationInGameTicks: 5,
	validTargets: AbilityTargets.MYSELF,
	validTargetSelection: AbilityTargetSelectionStyle.FIRST,
	buffToApply: BuffIdentifier.Invulnerable,
}

const testWaitAbility: Ability = {
	debugName: 'testWaitAbility',
	abilityType: AbilityType.WAIT,
	durationInGameTicks: 5,
	validTargets: AbilityTargets.MYSELF,
	validTargetSelection: AbilityTargetSelectionStyle.FIRST,
}

const testFireProjectileAbility: Ability = {
	debugName: 'fireProj',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 20,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		colliderRadius: 110,
		speed: 700,
		lifespanInSeconds: 6,
		color: Colors.green,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_FUNGIBOSS,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_TRAIL_FUNGIBOSS,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
	},
	playsAnimation: AnimationTrack.SPRAY_ATTACK,
}

const testSpawnAbility: Ability = {
	debugName: 'spawnBlimp',
	abilityType: AbilityType.SPAWN_ENEMY,
	durationInGameTicks: 50,
	validTargets: AbilityTargets.RANDOM_POSITIONS_NEARBY,
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
	spawningRules: {
		identifier: 'blimpie',
		quantity: 2,
		limit: 10,
		dropXP: false,
		dropLoot: false,
	},
	shakeCamera: CameraShakeIntensities.VERY_INTENSE,
	//playsAnimation: AnimationTrack.SUMMON_MINIONS,
}

//-------------------------------------------------------
// test ActionAbilities

export const testSingleActionAbilities: ActionAbilities = {
	abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
	abilityOptions: [testFireProjectileAbility],
}

export const testSequenceActionAbilities: ActionAbilities = {
	abilitySelectionStyle: AbilitySelectionStyles.SEQUENCE,
	abilityOptions: [
		[5, testBuffAbility],
		[15, testWaitAbility],
	],
}

export const testSequenceActionAbilities2: ActionAbilities = {
	abilitySelectionStyle: AbilitySelectionStyles.SEQUENCE,
	abilityOptions: [playAnimationAbility(AnimationTrack.SUMMON_MINIONS, 15), testSpawnAbility],
}

export const testWeightedRandomActionAbilities: ActionAbilities = {
	abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
	abilityOptions: [
		[5, playAnimationAbility(AnimationTrack.EMERGE), 5, testBuffAbility],
		[5, playAnimationAbility(AnimationTrack.EMERGE), 5, testWaitAbility],
	],
}
