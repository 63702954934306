import { IPixiBone } from '../ai/shared/enemy-model-data'

export function getBoneByName(bones: IPixiBone[], name: string): IPixiBone {
	for (let i = 0; i < bones.length; ++i) {
		const bone = bones[i]
		if (bone.name === name) {
			return bone
		}
	}
}
