import nengi from 'nengi'

class DumpFileMessage {
	static protocol = {
		data: nengi.String,
	}	

    data: string

	constructor(data) {
		this.data = data
	}
}

export default DumpFileMessage