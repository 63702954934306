import { gridUnits, timeInMilliseconds } from '../../utils/primitive-types'

export const MINIMAP_GRID_SEARCH_RANGE: gridUnits = 9
export const HALF_MINIMAP_GRID_SEARCH_RANGE: gridUnits = MINIMAP_GRID_SEARCH_RANGE / 2

export const MINIMAP_WIDTH = 200
export const MINIMAP_HEIGHT = 200
export const MINIMAP_PIP_SIZE_SELF = 7
export const MINIMAP_PIP_SIZE_OTHERS = 5

export const MINIMAP_UPDATE_RATE: timeInMilliseconds = 100
