import { biomeIndex, uuid } from '../../utils/primitive-types'
import { PressureLoadout } from './game-data/pressure-stat-info.shared'
import { WorldDifficulty } from './game-data/world-difficulty'

export type UserType = 'anonymous' | 'registered'

export enum SpawnStyle {
	Default = 'default',
	Random = 'random',
	OnPlayer = 'onplayer',
}

export interface ConnectionPayload {
	visibleWorldWidth: number
	visibleWorldHeight: number
	userType: UserType
	identifier: uuid
	spawnStyle: SpawnStyle
	biomeIndex?: biomeIndex
	worldDifficulty: WorldDifficulty
	pressureLoadout: PressureLoadout
	playToken: string
}
