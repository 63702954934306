import ForegroundProp from '../shared/foreground-prop.shared'
import { clientConfig } from '../../engine/client/client-config'
import { addDebugModel } from '../../debug/client/debug-client'
import { ObjectPool, PoolableObject } from '../../third-party/object-pool'
import Renderer from '../../engine/client/graphics/renderer'
import { RiggedSpineModel } from '../../models-animations/client/spine-model'
import playAnimation from '../../models-animations/client/play-animation'
import { AnimationTrack } from '../../models-animations/shared/animation-track'
import { AssetManager } from '../../asset-manager/client/asset-manager'
import logger from '../../utils/client-logger'

export class ClientForegroundProp extends ForegroundProp implements PoolableObject {
	static pool: ObjectPool
	exemptFromModelCheck: boolean = true
	private model: RiggedSpineModel

	constructor() {
		super()
	}

	setDefaultValues(defaultValues: any, entityData?: any) {
		const renderer: Renderer = Renderer.getInstance()

		if (entityData) {
			this.x = entityData.x
			this.y = entityData.y
			this.propId = entityData.propId
			this.r = entityData.r
			this.g = entityData.g
			this.b = entityData.b
			this.isGodRay = entityData.isGodRay
			const asset = AssetManager.getInstance().getAssetByName(entityData.propId)
			if (asset == null) {
				logger.warn(`no asset named ${entityData.propId}`)
				return
			}
			const spineData: PIXI.spine.core.SkeletonData = asset.spineData
			const animCount = spineData.animations.length

			this.model = new RiggedSpineModel(spineData)
			this.model.skeleton.setSkinByName('default')
			this.model.skeleton.setToSetupPose()
			this.model.name = entityData.propId
			this.model.scale.set(entityData.scale, entityData.scale)

			if (animCount > 0) {
				playAnimation(this.model, AnimationTrack.IDLE)
			}
			renderer.fgRenderer.addDisplayObjectToScene(this.model)
			if (this.isGodRay) {
				this.model.zIndex = this.y
			} else {
				// -10 is so fog is on top of foreground props
				this.model.zIndex = Number.MAX_SAFE_INTEGER - 10
			}
			if (clientConfig.debug) {
				const y = entityData.y + (this.isGodRay ? 100 : 0)
				addDebugModel(this, `fg-prop:${entityData.propId}\n` + `${entityData.x},${y}\n` + `${spineData.imagesPath}\n` + `${asset.url}\n`, 1)
			}
		}
	}

	cleanup() {
		this.nid = -1
		// this.sprite
		// debugModel?
	}
}

export const clientForegroundPropHooks = {}
