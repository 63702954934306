






import { VALID_BUTTON_STYLES, VALID_BUTTON_TEXT_STYLES } from './button-constants'

export default {
	name: 'TextButton',
	props: {
		buttonLabel: {
			type: String,
			required: true,
			default: function() {
				console.warn('Currently using default button label. Ensure <TextButton /> component has a custom attribute button-label')
				return 'DEFAULT LABEL'
			},
			validator: function(value) {
				return value.trim().length !== 0
			},
		},
		disabled: {
			type: Boolean,
			default: false,
			required: false,
		},
		buttonStyle: {
			type: String,
			default: 'default',
			validator: function(value) {
				return VALID_BUTTON_STYLES.includes(value)
			},
		},
		highlightButton: {
			type: Boolean,
			required: false,
			default: false,
		},
		onClick: {
			type: Function,
			required: true,
			default: () => {
				console.warn('The default on-click handler for the <TextButton /> component has been called. Did you forget to pass an :on-click handler to the component?')
			},
		},
		textStyle:{
			type: String,
			default: 'default',
			required: false,
			validator: function(value) {
				return VALID_BUTTON_TEXT_STYLES.includes(value)
			},
		}
	},
	methods: {
		clicked(param) {
			if (!this.disabled) {
				this.onClick(param)
			}
		},
	},
}
