import { highResolutionTimestamp } from '../../utils/debug'
import { timeInMilliseconds, timeInSeconds } from '../../utils/primitive-types'

export const FRAME_TIMES: number[] = []
export let STORE_FRAME_TIMES: boolean = false

export function setStoreFrameTimes(store: boolean) {
	STORE_FRAME_TIMES = store
}

export default class Time {
	static currentGameFrameNumber: number = 0
	static clampedDeltaTimeInSeconds: timeInSeconds = 0
	static unclampedDeltaTimeInSeconds: timeInSeconds = 0
	/** clampedDeltaTimeInSeconds and unclampedDeltaTimeInSeconds on client use previous frames delta, this is the actual *real* delta *this* frame */
	static realUnclampedDeltaTimeInSeconds: timeInSeconds = 0
	static timestampOfCurrentFrameStartInMs: timeInMilliseconds = highResolutionTimestamp()
	static timestampOfPreviousFrameStartInMs: timeInMilliseconds = highResolutionTimestamp()
	static timeElapsedSinceStartupInSeconds: timeInSeconds = 0
	static timeElapsedSinceStartupInMs: timeInMilliseconds = 0
	static timeElapsedSinceModeStartInSeconds: timeInSeconds = 0
	static timeElapsedSinceModeStartInMs: timeInMilliseconds = 0
}

export const SERVER_DELTA_TIME = 0.05 // 20 updates per second
