

























import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'

import MainPanel from '../../reusable-components/panels/main-panel.vue'
import PlainTextButton from '../../reusable-components/buttons/plain-text-button.vue'
import { playerPanelTabs } from '../../state/in-game.ui-state'

import PlayerProfile from './player-profile.vue'
import PlayerPaperdoll from './player-paperdoll.vue'
import PlayerCustomize from './player-customize.vue'

export default {
	name: 'Player',
	components: {
		MainPanel,
		PlainTextButton,
		PlayerProfile,
		PlayerPaperdoll,
		PlayerCustomize,
	},
	data() {
		return {
			playerPanelTabs,
		}
	},
	props: {},
	computed: {
		...mapState('inGame', ['playerTab']),
		panelBanner(){
			switch (this.playerTab) {
				case this.playerPanelTabs.PROFILE:
					return 'playerprofile'
				case this.playerPanelTabs.PAPERDOLL:
					return 'paperdoll'
				case this.playerPanelTabs.CUSTOMIZE:
					return 'customize'
			}
		}
	},
	methods: {
		...mapActions('inGame', ['closeActivePanel', 'openFlyoutPanel', 'closeFlyoutPanel']),
		...mapMutations('inGame', ['updatedPlayerTab']),
		changeTab(tab) {
			this.updatedPlayerTab(tab)
		},
	},
}
