import { Vector } from 'sat'
import { ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { CameraShakeIntensities } from '../../../engine/shared/camera.shared'
import { bossEnemyDamageConfig, BOSS_HEALTH_MULTIPLIER, BOSS_LOOT_DROP_QUANTITY, BOSS_LOOT_DROP_RARITY, ENEMY_DEFAULT_HEALTH } from '../../../engine/shared/game-data/enemy-formulas'
import { AnimationTrack } from '../../../models-animations/shared/animation-track'
import { EnemyBipedSkinType, SpineDataName } from '../../../models-animations/shared/spine-config'
import { distanceVV } from '../../../utils/math'
import { AttackTypes, DeadBehaviours, EnemyAI, EnemyTargetingStyles, EnemyType, FightingBehaviours, FightingTriggers, IdleBehaviours, LeashingBehaviours, LeashTriggers, ShotLeadPrecision } from '../../shared/ai-types'
import { AbilitySelectionStyles, CriteriaSatisfactionQuantity, CriteriaTarget, CriteriaType, NamedColliders } from '../action-types'
import {
	crabBlockAbility,
	crabClawSlamEasyAbility,
	crabClawSlamEnragedEasyAbility,
	crabMoveTo5or7oClockAbility,
	crabMoveToPillarAbility,
	crabMoveToPositionAbility,
	crabMoveToPositionEnragedAbility,
	crabSkeletonShortShootPlayerEasyAbility,
	crabSkeletonShortShootPlayerEnragedEasyAbility,
	crabSkeletonLongShootPlayerEasyAbility,
	crabSkeletonLongShootPlayerEnragedEasyAbility,
	crabSlowMoveToPositionAbility,
	crabSkeletonShootWhileDefendingEasy,
	crabSkeletonShootWhileDefendingMedium,
	crabSkeletonShortShootPlayerMediumAbility,
	crabSkeletonShortShootPlayerEnragedMediumAbility,
	crabSkeletonLongShootPlayerMediumAbility,
	crabSkeletonLongShootPlayerEnragedMediumAbility,
	crabClawSlamMediumAbility,
	crabClawSlamEnragedMediumAbility,
	
	crabClawSlamEnragedBrutalAbility,
	crabClawSlamEnragedNastyAbility,
	crabClawSlamNastyEnragedAbilityReverse,
	crabSkeletonShootWhileDefendingBrutal,
	crabSkeletonShootWhileDefendingNasty,
	crabClawSlamBrutalAbility,
	crabClawSlamNastyAbility,
	crabClawSlamNastyAbilityReverse,
	crabSkeletonLongShootPlayerEnragedBrutalAbility,
	crabSkeletonLongShootPlayerEnragedNastyAbility,
	crabSkeletonLongShootPlayerBrutalAbility,
	crabSkeletonLongShootPlayerNastyAbility,
	crabSkeletonShortShootPlayerEnragedBrutalAbility,
	crabSkeletonShortShootPlayerEnragedNastyAbility,
	crabSkeletonShortShootPlayerBrutalAbility,
	crabSkeletonShortShootPlayerNastyAbility,
	crabMoveToPositionAbilityBrutal,
	crabMoveToPositionAbilityEnragedBrutal,
	crabMoveToPositionAbilityNasty,
	crabMoveToPositionAbilityEnragedNasty,
	crabSkeletonGrandFireballBrutal,
	crabSkeletonGrandFireballNasty
   


} from './abilities/beach-boss-abilities'
import { alwaysTrueCriteria, NormalDifficultyCriteria, HardDifficultyCriteria, BrutalDifficultyCriteria, NastyDifficultyCriteria, playAnimationAbility, setPropertyAbility, shakeCameraAbility } from './abilities/common-abilities'
import { EnemyDefaults } from './enemy-defaults'
import { ENEMY_NAMES } from './enemy-names'

const PHASE_1_2_HEALTH_BOUNDARY = 50
// const PHASE_2_3_HEALTH_BOUNDARY = 25

const danceActionAbilities = {
	abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
	abilityOptions: [playAnimationAbility(AnimationTrack.DANCE)],
}

const selfFullHealthCriteria = {
	criteriaTargets: CriteriaTarget.SELF,
	criteriaType: CriteriaType.HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
	criteriaValue: 99.999999999, // 🐕 such accuracy
	minimumTargetsThatSatisfy: 1,
}

const notInsidePillarCriteria = {
	criteriaTargets: CriteriaTarget.SELF,
	criteriaType: CriteriaType.INSIDE_SHAPES,
	criteriaValue: ['pillar'],
	minimumTargetsThatSatisfy: 1,
	negateCriteria: true,
}
const insidePillarCriteria = {
	criteriaTargets: CriteriaTarget.SELF,
	criteriaType: CriteriaType.INSIDE_SHAPES,
	criteriaValue: ['pillar'],
	minimumTargetsThatSatisfy: 1,
}
const playersTooCloseCriteria = {
	criteriaTargets: CriteriaTarget.PLAYERS,
	criteriaType: CriteriaType.WITHIN_RANGE,
	criteriaValue: 500,
	minimumTargetsThatSatisfy: 1,
}

const beachBoss: EnemyAI = {
	actionDrivenEnemyData: {
		targetColliders: [
			{ name: 'pillar', colliders: [{ type: ColliderType.Circle, traits: ColliderTraits.Trigger, position: [0, 250], radius: 10 }] },
			{ name: '1oclock', colliders: [{ type: ColliderType.Circle, traits: ColliderTraits.Trigger, position: [500, -620], radius: 10 }] },
			{ name: '3oclock', colliders: [{ type: ColliderType.Circle, traits: ColliderTraits.Trigger, position: [1320, 70], radius: 10 }] },
			{ name: '5oclock', colliders: [{ type: ColliderType.Circle, traits: ColliderTraits.Trigger, position: [549, 716], radius: 10 }] },
			{ name: '7oclock', colliders: [{ type: ColliderType.Circle, traits: ColliderTraits.Trigger, position: [-667, 733], radius: 10 }] },
			{ name: '9oclock', colliders: [{ type: ColliderType.Circle, traits: ColliderTraits.Trigger, position: [-1420, 84], radius: 10 }] },
			{ name: '11oclock', colliders: [{ type: ColliderType.Circle, traits: ColliderTraits.Trigger, position: [-440, -560], radius: 10 }] },
		],

		engagementColliders: [{ type: ColliderType.Ellipse, traits: ColliderTraits.Trigger, position: [-6, -27], rX: 2204, rY: 1339 }],
		resetOnDisengage: true,

		unengagedActions: [
			//DANCE!
			{
				priority: 0,
				actionCriteria: alwaysTrueCriteria,
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
					abilityOptions: [crabMoveToPillarAbility, setPropertyAbility('idleAnimation', AnimationTrack.DANCE)],
				},
			},
		],
		actions: [
			//MOVE TO PILLAR (if not in pillar and full health)
			{
				priority: -3,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [selfFullHealthCriteria, notInsidePillarCriteria],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
					abilityOptions: [[1, crabMoveToPillarAbility]],
				},
			},
			// // DANCE! (if full health)
			{
				priority: 0,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [selfFullHealthCriteria],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
					abilityOptions: [[1, setPropertyAbility('idleAnimation', AnimationTrack.DANCE), 20]],
				},
				exitActionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
					abilityOptions: [[1, setPropertyAbility('idleAnimation', AnimationTrack.IDLE)]],
				},
			},
			// WALK DOWN (if not full health, implied by above DANCE actionf)
			{
				priority: 0.1,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [insidePillarCriteria],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
					abilityOptions: [[1, crabMoveTo5or7oClockAbility]],
				},
			},
		// PHASE 1 - player too close - Nasty Difficulty - Not Set Yet
			{
				priority: 11.1,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						playersTooCloseCriteria,
						NastyDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_1_2_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[50, playAnimationAbility(AnimationTrack.GROUND_ATTACK, 10), shakeCameraAbility(CameraShakeIntensities.INTENSE, 0), crabClawSlamNastyAbility, 5, crabClawSlamNastyAbilityReverse, 15],
						[20, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 5), crabSkeletonShortShootPlayerNastyAbility, 20],
						[20, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 5), crabSkeletonGrandFireballNasty, 20],
						[10, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 5), crabSkeletonLongShootPlayerNastyAbility, 20],
						[10, crabBlockAbility, crabSkeletonShootWhileDefendingNasty, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 28), crabSkeletonShootWhileDefendingNasty, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 28), crabSlowMoveToPositionAbility],
						[10, crabMoveToPositionAbilityNasty],
					],
				},
			},
			// PHASE 1 (regular move/attack) - Nasty Difficulty - Not Set Yet
			{
				priority: 11.2,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						NastyDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_1_2_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[10, playAnimationAbility(AnimationTrack.GROUND_ATTACK, 10), shakeCameraAbility(CameraShakeIntensities.INTENSE, 0), crabClawSlamNastyAbility, 5, crabClawSlamNastyAbilityReverse, 15],
						[25, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 5), crabSkeletonShortShootPlayerNastyAbility, 20],						
						[20, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 5), crabSkeletonGrandFireballNasty, 20],
						[35, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 5), crabSkeletonLongShootPlayerNastyAbility, 30],
						[25, crabBlockAbility, crabSkeletonShootWhileDefendingNasty, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 28), crabSkeletonShootWhileDefendingNasty, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 28), crabSlowMoveToPositionAbility],
						[15, crabMoveToPositionAbilityNasty],
					],
				},
			},
			// PHASE 2 - Enraged (Close range) - Nasty Difficulty - Not Set Yet
			{
				priority: 12.1,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						playersTooCloseCriteria,
						NastyDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_BELOW_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_1_2_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[50, playAnimationAbility(AnimationTrack.GROUND_ATTACK, 10), shakeCameraAbility(CameraShakeIntensities.INTENSE, 0), crabClawSlamEnragedNastyAbility, 4, crabClawSlamNastyEnragedAbilityReverse, 10],
						[20, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 5), crabSkeletonShortShootPlayerEnragedNastyAbility, 20],
						[20, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 5), crabSkeletonGrandFireballNasty, 10],
						[10, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 5), crabSkeletonLongShootPlayerEnragedNastyAbility, 20],
						[5, crabBlockAbility, crabSkeletonShootWhileDefendingNasty, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 28), crabSkeletonShootWhileDefendingNasty, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 28), crabSlowMoveToPositionAbility],
						[15, crabMoveToPositionAbilityEnragedNasty],
					],
				},
			},
			// Phase 2 - Enraged (Faster Attack range) - Nasty Difficulty - Not Set Yet
			{
				priority: 12.2,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [

						NastyDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_BELOW_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_1_2_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[10, playAnimationAbility(AnimationTrack.GROUND_ATTACK, 10), shakeCameraAbility(CameraShakeIntensities.INTENSE, 0), crabClawSlamEnragedNastyAbility, 4, crabClawSlamNastyEnragedAbilityReverse, 10],
						[20, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 5), crabSkeletonShortShootPlayerEnragedNastyAbility, 20],
						[20, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 5), crabSkeletonGrandFireballNasty, 10],
						[40, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 5), crabSkeletonLongShootPlayerEnragedNastyAbility, 20],
						[5, crabBlockAbility, crabSkeletonShootWhileDefendingNasty, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 28), crabSkeletonShootWhileDefendingNasty, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 28), crabSlowMoveToPositionAbility],
						[15, crabMoveToPositionAbilityEnragedNasty],
					],
				},
			},
			



				// PHASE 1 - player too close - Brutal Difficulty
			{
				priority: 21.1,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						playersTooCloseCriteria,
						BrutalDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_1_2_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[50, playAnimationAbility(AnimationTrack.GROUND_ATTACK, 10), shakeCameraAbility(CameraShakeIntensities.INTENSE, 0), crabClawSlamBrutalAbility, 5, crabClawSlamBrutalAbility, 20],
						[20, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 5), crabSkeletonShortShootPlayerBrutalAbility, 20],
						[20, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 5), crabSkeletonGrandFireballBrutal, 20],
						[10, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 5), crabSkeletonLongShootPlayerBrutalAbility, 20],
						[10, crabBlockAbility, crabSkeletonShootWhileDefendingBrutal, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 28), crabSkeletonShootWhileDefendingBrutal, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 28), crabSlowMoveToPositionAbility],
						[10, crabMoveToPositionAbilityBrutal],
					],
				},
			},
			// PHASE 1 (regular move/attack) - Brutal Difficulty
			{
				priority: 21.2,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						BrutalDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_1_2_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[10, playAnimationAbility(AnimationTrack.GROUND_ATTACK, 10), shakeCameraAbility(CameraShakeIntensities.INTENSE, 0), crabClawSlamBrutalAbility, 5, crabClawSlamBrutalAbility, 20],
						[25, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 5), crabSkeletonShortShootPlayerBrutalAbility, 20],
						[20, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 5), crabSkeletonGrandFireballBrutal, 20],
						[35, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 5), crabSkeletonLongShootPlayerBrutalAbility, 30],
						[25, crabBlockAbility, crabSkeletonShootWhileDefendingBrutal, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 28), crabSkeletonShootWhileDefendingBrutal, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 28), crabSlowMoveToPositionAbility],
						[15, crabMoveToPositionAbilityBrutal],
					],
				},
			},
			// PHASE 2 - Enraged (Close range) - Brutal Difficulty
			{
				priority: 22.1,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						playersTooCloseCriteria,
						BrutalDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_BELOW_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_1_2_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[50, playAnimationAbility(AnimationTrack.GROUND_ATTACK, 10), shakeCameraAbility(CameraShakeIntensities.INTENSE, 0), crabClawSlamEnragedBrutalAbility, 5, crabClawSlamEnragedBrutalAbility, 12],
						[20, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 5), crabSkeletonShortShootPlayerEnragedBrutalAbility, 20],
						[20, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 5), crabSkeletonGrandFireballBrutal, 20],
						[10, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 5), crabSkeletonLongShootPlayerEnragedBrutalAbility, 20],
						[5, crabBlockAbility, crabSkeletonShootWhileDefendingBrutal, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 28), crabSkeletonShootWhileDefendingBrutal, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 28), crabSlowMoveToPositionAbility],
						[15, crabMoveToPositionAbilityEnragedBrutal],
					],
				},
			},
			// Phase 2 - Enraged (Faster Attack range) - Brutal Difficulty
			{
				priority: 22.2,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [

						BrutalDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_BELOW_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_1_2_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[10, playAnimationAbility(AnimationTrack.GROUND_ATTACK, 10), shakeCameraAbility(CameraShakeIntensities.INTENSE, 0), crabClawSlamEnragedBrutalAbility, 5, crabClawSlamEnragedBrutalAbility, 12],
						[20, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 5), crabSkeletonShortShootPlayerEnragedBrutalAbility, 20],
						[20, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 5), crabSkeletonGrandFireballBrutal, 20],
						[40, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 5), crabSkeletonLongShootPlayerEnragedBrutalAbility, 20],
						[5, crabBlockAbility, crabSkeletonShootWhileDefendingBrutal, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 28), crabSkeletonShootWhileDefendingBrutal, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 28), crabSlowMoveToPositionAbility],
						[15, crabMoveToPositionAbilityEnragedBrutal],
					],
				},
			},
			
			// PHASE 1 - player too close - Hard Difficulty
			{
				priority: 31.0,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						playersTooCloseCriteria,
						HardDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_1_2_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[50, playAnimationAbility(AnimationTrack.GROUND_ATTACK, 10), shakeCameraAbility(CameraShakeIntensities.INTENSE, 0), crabClawSlamMediumAbility, 20],
						[20, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 5), crabSkeletonShortShootPlayerMediumAbility, 20],
						[10, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 5), crabSkeletonLongShootPlayerMediumAbility, 20],
						[10, crabBlockAbility, crabSkeletonShootWhileDefendingMedium, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 28), crabSkeletonShootWhileDefendingMedium, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 28), crabSlowMoveToPositionAbility],
						[10, crabMoveToPositionAbility],
					],
				},
			},
			// PHASE 1 (regular move/attack) - Hard Difficulty
			{
				priority: 31.1,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						HardDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_1_2_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[10, playAnimationAbility(AnimationTrack.GROUND_ATTACK, 10), shakeCameraAbility(CameraShakeIntensities.INTENSE, 0), crabClawSlamMediumAbility, 20],
						[25, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 5), crabSkeletonShortShootPlayerMediumAbility, 20],
						[35, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 5), crabSkeletonLongShootPlayerMediumAbility, 30],
						[25, crabBlockAbility, crabSkeletonShootWhileDefendingMedium, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 28), crabSkeletonShootWhileDefendingMedium, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 28), crabSlowMoveToPositionAbility],
						[15, crabMoveToPositionAbility],
					],
				},
			},
			// PHASE 2 - Enraged (Close range) - Hard Difficulty
			{
				priority: 32.1,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						playersTooCloseCriteria,
						HardDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_BELOW_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_1_2_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[50, playAnimationAbility(AnimationTrack.GROUND_ATTACK, 10), shakeCameraAbility(CameraShakeIntensities.INTENSE, 0), crabClawSlamEnragedMediumAbility, 10],
						[20, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 5), crabSkeletonShortShootPlayerEnragedMediumAbility, 20],
						[10, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 5), crabSkeletonLongShootPlayerEnragedMediumAbility, 20],
						[5, crabBlockAbility, crabSkeletonShootWhileDefendingMedium, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 28), crabSkeletonShootWhileDefendingMedium, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 28), crabSlowMoveToPositionAbility],
						[15, crabMoveToPositionEnragedAbility],
					],
				},
			},
			// Phase 2 - Enraged (Faster Attack range) - Hard Difficulty
			{
				priority: 32.2,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [

						HardDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_BELOW_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_1_2_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[10, playAnimationAbility(AnimationTrack.GROUND_ATTACK, 10), shakeCameraAbility(CameraShakeIntensities.INTENSE, 0), crabClawSlamEnragedMediumAbility, 10],
						[20, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 5), crabSkeletonShortShootPlayerEnragedMediumAbility, 20],
						[40, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 5), crabSkeletonLongShootPlayerEnragedMediumAbility, 20],
						[5, crabBlockAbility, crabSkeletonShootWhileDefendingMedium, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 28), crabSkeletonShootWhileDefendingMedium, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 28), crabSlowMoveToPositionAbility],
						[15, crabMoveToPositionEnragedAbility],
					],
				},
			},
			// PHASE 1 - player too close - Normal Difficulty
			{
				priority: 41.0,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						playersTooCloseCriteria,
						NormalDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_1_2_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[50, playAnimationAbility(AnimationTrack.GROUND_ATTACK, 10), shakeCameraAbility(CameraShakeIntensities.INTENSE, 0), crabClawSlamEasyAbility, 20],
						[20, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 5), crabSkeletonShortShootPlayerEasyAbility, 20],
						[10, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 5), crabSkeletonLongShootPlayerEasyAbility, 20],
						[10, crabBlockAbility, crabSkeletonShootWhileDefendingEasy, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 28), crabSkeletonShootWhileDefendingEasy, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 28), crabSlowMoveToPositionAbility],
						[10, crabMoveToPositionAbility],
					],
				},
			},
			// PHASE 1 (regular move/attack)  - Normal Difficulty
			{
				priority: 41.1,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						NormalDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_1_2_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[10, playAnimationAbility(AnimationTrack.GROUND_ATTACK, 10), shakeCameraAbility(CameraShakeIntensities.INTENSE, 0), crabClawSlamEasyAbility, 20],
						[25, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 5), crabSkeletonShortShootPlayerEasyAbility, 20],
						[35, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 5), crabSkeletonLongShootPlayerEasyAbility, 30],
						[25, crabBlockAbility, crabSkeletonShootWhileDefendingEasy, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 28), crabSkeletonShootWhileDefendingEasy, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 28), crabSlowMoveToPositionAbility],
						[15, crabMoveToPositionAbility],
					],
				},
			},
			// PHASE 2 - Enraged (Close range) - Normal Difficulty
			{
				priority: 42.1,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						playersTooCloseCriteria,
						NormalDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_BELOW_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_1_2_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[50, playAnimationAbility(AnimationTrack.GROUND_ATTACK, 10), shakeCameraAbility(CameraShakeIntensities.INTENSE, 0), crabClawSlamEnragedEasyAbility, 10],
						[20, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 5), crabSkeletonShortShootPlayerEnragedEasyAbility, 20],
						[10, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 5), crabSkeletonLongShootPlayerEnragedEasyAbility, 20],
						[5, crabBlockAbility, crabSkeletonShootWhileDefendingEasy, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 28), crabSkeletonShootWhileDefendingEasy, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 28), crabSlowMoveToPositionAbility],
						[15, crabMoveToPositionEnragedAbility],
					],
				},
			},
			// Phase 2 - Enraged (Faster Attack range)  - Normal Difficulty
			{
				priority: 42.2,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						NormalDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_BELOW_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_1_2_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[10, playAnimationAbility(AnimationTrack.GROUND_ATTACK, 10), shakeCameraAbility(CameraShakeIntensities.INTENSE, 0), crabClawSlamEnragedEasyAbility, 10],
						[20, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 5), crabSkeletonShortShootPlayerEnragedEasyAbility, 20],
						[40, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 5), crabSkeletonLongShootPlayerEnragedEasyAbility, 20],
						[5, crabBlockAbility, crabSkeletonShootWhileDefendingEasy, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 28), crabSkeletonShootWhileDefendingEasy, playAnimationAbility(AnimationTrack.SKELETON_SHOOT, 28), crabSlowMoveToPositionAbility],
						[15, crabMoveToPositionEnragedAbility],
					],
				},
			},
		],
	},

	name: ENEMY_NAMES.BEACH_BOSS,
	type: EnemyType.BOSS,
	appearance: {
		asset: SpineDataName.BEACH_BOSS,
		skin: EnemyBipedSkinType.DEFAULT,
		defaultMix: 0.3,
		mixSettings: [],
		zOffset: 100,
	},
	general: {
		enemyLevel: 1,
		experienceWhenKilled: 20,
	},
	baseAttributes: {
		...EnemyDefaults.baseAttributes,
		awarenessCollisionRadiusInGrids: 6,
		colliderIsKinematic: true,
		colliders: [
			{
				type: ColliderType.Circle,
				radius: 200,
				position: [-50, -100],
				traits: ColliderTraits.BlockMovementAndProjectile,
			},
			{
				type: ColliderType.Circle,
				radius: 200,
				position: [100, -100],
				traits: ColliderTraits.BlockMovementAndProjectile,
			},
		],
		attackOffset: new Vector(0, 0),
		lootDropOffset: new Vector(0, 0),
		damageConfig: bossEnemyDamageConfig,
		movementSpeed: 100,
		visibilityRadius: 1500,
		decelerationRate: 0,
		turningRatePerSecondInDegrees: 200,
		physicalDamage: 0,
		iceDamage: 0,
		chillChance: 0,
		cooldownSpeed: 300,

		maxHealth: ENEMY_DEFAULT_HEALTH * BOSS_HEALTH_MULTIPLIER, // Boss health
		defense: 0,
		fireResist: 0,
		poisonResist: 0,
		iceResist: 0,
		lightningResist: 0,

		lootDropProbability: 100,
		lootDropQuantity: BOSS_LOOT_DROP_QUANTITY,
		lootDropRarity: BOSS_LOOT_DROP_RARITY,
	},
	soundEffects: {
		attack: 'Projectile_Blip',
		impact: 'SFX_Impact_Enemy_Bone',
	},
	states: {
		idling: {
			behaviour: IdleBehaviours.STATIONARY,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			transitionToFighting: {
				trigger: FightingTriggers.AGGRESSIVE_PROXIMITY,
				radius: 2500, // todo3 - boss uses this for getting *close* players... sheesh
			},
			aggroOnHit: true,
		},
		fighting: {
			behaviour: FightingBehaviours.CHASE_AND_ATTACK,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			attackType: AttackTypes.PROJECTILE,
			engagementMaxDistance: 1600,
			engagementMinDistance: 0,
			movementMaxDistance: 1600,
			movementMinDistance: 0,
			attackOptions: [],
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 0.5,
			transitionToLeashing: {
				trigger: LeashTriggers.NONE,
			},
		},
		leashing: {
			behaviour: LeashingBehaviours.MOVE,
			speedMultiplier: 0,
			decelerateMultiplier: 0,
		},
		fleeing: {},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 3,
		},
	},
}

// movement to nodes is flakey... to prevent moving through pillar, we need to ensure that the line between any 2 nodes that goes through the pillar,
//  isn't so short that the crab decides to take that path
const maxMoveDist = 1300
const beachBossRimPositions = ['1oclock', '3oclock', '5oclock', '7oclock', '9oclock', '11oclock']
beachBossRimPositions.forEach((colName1) => {
	beachBossRimPositions.forEach((colName2) => {
		const i1 = beachBossRimPositions.indexOf(colName1)
		const i2 = beachBossRimPositions.indexOf(colName2)
		const c1 = beachBoss.actionDrivenEnemyData.targetColliders.find((nc) => nc.name === colName1) as NamedColliders
		const c2 = beachBoss.actionDrivenEnemyData.targetColliders.find((nc) => nc.name === colName2) as NamedColliders
		const v1 = new Vector(c1.colliders[0].position[0], c1.colliders[0].position[1])
		const v2 = new Vector(c2.colliders[0].position[0], c2.colliders[0].position[1])
		const dist = distanceVV(v1, v2)
		if (Math.abs(i1 - i2) === 1 || (i1 === 0 && i2 === 5) || (i1 === 5 && i2 === 0)) {
			console.assert(dist < maxMoveDist, `distance:${dist} between ${colName1} and ${colName2} is too big, should be less than ${maxMoveDist}`)
		} else if (i1 !== i2) {
			console.assert(dist > maxMoveDist, `distance:${dist} between ${colName1} and ${colName2} is too small, should be greater than ${maxMoveDist}`)
		}
	})
})

export default beachBoss
