


















import { mapActions, mapMutations, mapState } from 'vuex'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import TextButton from '../reusable-components/buttons/text-button.vue'
import PanelDivider from '../reusable-components/panel-divider/panel-divider.vue'

export default {
	name: 'VideoPlayer',
	components: {
		MainPanel,
		TextButton,
		PanelDivider,
	},
	computed: {
		...mapState('ftueManager', ['videoFinished', 'couldntLoadVideo']),
	},
	methods: {
		...mapMutations('inGame', ['setActivePanel']),
		...mapActions('ftueManager', ['closeVideoPlayer']),
	},
}
