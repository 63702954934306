import { EmbossFilter } from '@pixi/filter-emboss'
import { AdvancedBloomFilter, BulgePinchFilter, ColorOverlayFilter, GlowFilter, RGBSplitFilter, TwistFilter } from 'pixi-filters'
import { Container, Geometry, Buffer, TYPES, Program, Shader, State, Mesh, MeshMaterial } from 'pixi.js'
import BackgroundRenderer from '../../../world/client/background-renderer'

/**
 * Preload all known shaders we use by doing a dummy render call with them.
 * This prevents compileProgram hitches during gameplay upon first use of shader.
 * @param renderer
 */
export function preloadShaders(renderer: PIXI.Renderer) {
	const displacementSprite = PIXI.Sprite.from('displacement_map')

	const rt: PIXI.RenderTexture = PIXI.RenderTexture.create({
		width: 1,
		height: 1,
		scaleMode: PIXI.SCALE_MODES.LINEAR,
		resolution: 1,
	})

	const geom = new Geometry()

	const FLOATS_PER_VERT = 4
	const VERTS_PER_QUAD = 4
	const QUAD_INDICES: number[] = [0, 1, 2, 0, 2, 3]
	const VERT_POSITIONS: number[] = [0, 0, 1, 0, 1, 1, 0, 1]
	const VERT_UVS: number[] = [0, 0, 1, 0, 1, 1, 0, 1]

	const floats = new Float32Array(FLOATS_PER_VERT * VERTS_PER_QUAD)

	const indexData = new Uint16Array(QUAD_INDICES)

	let ofs = 0
	for (let i = 0; i < 4; ++i) {
		floats[ofs++] = VERT_POSITIONS[i * 2 + 0]
		floats[ofs++] = VERT_POSITIONS[i * 2 + 1]
		floats[ofs++] = VERT_UVS[i * 2 + 0]
		floats[ofs++] = VERT_UVS[i * 2 + 1]
	}

	const vbo = new Buffer(floats, true, false)
	const indexBuffer = new Buffer(indexData, true, true)

	geom.addAttribute('aPosition', vbo, 2, false, TYPES.FLOAT, FLOATS_PER_VERT * 4, 0)
	geom.addAttribute('aUV', vbo, 2, false, TYPES.FLOAT, FLOATS_PER_VERT * 4, 8)
	geom.addIndex(indexBuffer)

	const container = new Container()
	container.filters = [
		new PIXI.filters.DisplacementFilter(displacementSprite),
		new RGBSplitFilter(),
		new PIXI.filters.ColorMatrixFilter(),
		new PIXI.SpriteMaskFilter(displacementSprite),
		new BulgePinchFilter(),
		new AdvancedBloomFilter(),
		new TwistFilter(),
		new ColorOverlayFilter(),
		new EmbossFilter(),
		new GlowFilter(),
	]

	renderer.render(container, rt)

	const shaders = [BackgroundRenderer.groundShaders, BackgroundRenderer.groundMixShaders, BackgroundRenderer.cliffShaders]

	// force compile of default vert/frag shaders by creating a dummy material
	const material = new MeshMaterial(displacementSprite.texture)

	for (let i = 0; i < shaders.length; i++) {
		const shaderSrc = shaders[i]
		if (shaderSrc) {
			const program = Program.from(shaderSrc[0], shaderSrc[1], `shader-${i}`)
			const shader = new Shader(program, {})
			const state = new State()

			// @ts-expect-error breaks contract with pixi, wontfix
			const mesh = new Mesh(geom, shader, state)
			renderer.render(mesh, rt)
		}
	}

	rt.destroy(true)
}

export function createFilter(vertexSrc, fragmentSrc, name) {
	const simpleShader = new PIXI.Filter(vertexSrc, fragmentSrc)
	return simpleShader
}
