
























export default {
	name: 'Links',
}
