import { Moment } from "moment-timezone"

export function sleepFor(milliseconds) {
	return new Promise((resolve) => setTimeout(resolve, milliseconds))
}

export function setIntervalUntil(intervalCb, endCb, intervalDuration, totalDuration) {
	let acc = 0
	const interval = setInterval(function() {
		acc += intervalDuration
		intervalCb()
		if (acc >= totalDuration) {
			if (endCb) {
				endCb()
			}
			clearInterval(interval)
		}
	}, intervalDuration)
	return interval
}

export function beginningOfWeekFromMoment(moment: Moment): Moment {
	return (moment.clone()).isoWeekday(1).hour(0).minute(0).second(0)
}

export function beginningOfDayFromMoment(moment: Moment): Moment {
	return (moment.clone()).hour(0).minute(0).second(0)
}