import { PLAYER_MIX_SETTINGS, WORM_MAIL_MIX_SETTINGS } from '../../models-animations/client/configure-animation-tracks'
import playAnimation from '../../models-animations/client/play-animation'
import { RiggedSpineModel } from '../../models-animations/client/spine-model'
import { AnimationTrack } from '../../models-animations/shared/animation-track'
import ModelConfig from '../../models-animations/shared/model-config'
import { EnemyBipedSkinType, NPCSkinType, SpineDataName } from '../../models-animations/shared/spine-config'

const NPCConfigs = new Map<string, ModelConfig>()
const configs: ModelConfig[] = [
	{
		asset: SpineDataName.NPC_DEFAULT,
		skin: NPCSkinType.DEBUG_NPC,
		mixSettings: PLAYER_MIX_SETTINGS,
	},
	{
		asset: SpineDataName.BUBBA,
		skin: EnemyBipedSkinType.DEFAULT,
		zOffset: 10,
		modelHeightOffset: -60, // offset smoke from pipe
	},
	{
		asset: SpineDataName.FURNACE_NPC_A,
		skin: EnemyBipedSkinType.DEFAULT,
	},
	{
		asset: SpineDataName.FURNACE_NPC_B,
		skin: EnemyBipedSkinType.DEFAULT,
	},
	{
		asset: SpineDataName.MERCHANT_A,
		skin: EnemyBipedSkinType.DEFAULT,
	},
	{
		asset: SpineDataName.MERCHANT_B,
		skin: EnemyBipedSkinType.DEFAULT,
	},
	{
		asset: SpineDataName.MERCHANT_C,
		skin: EnemyBipedSkinType.DEFAULT,
	},
	{
		asset: SpineDataName.STASH_NPC,
		skin: EnemyBipedSkinType.DEFAULT,
	},
	{
		asset: SpineDataName.WISP_NPC,
		skin: EnemyBipedSkinType.DEFAULT,
	},
	{
		asset: SpineDataName.WORM,
		skin: EnemyBipedSkinType.DEFAULT,
		mixSettings: WORM_MAIL_MIX_SETTINGS,
		zOffset: 25,
	},
	{
		asset: SpineDataName.MTX_NPC,
		skin: EnemyBipedSkinType.DEFAULT,
	},
	{
		asset: SpineDataName.DOCKS_NPC,
		skin: EnemyBipedSkinType.DEFAULT,
	},
	{
		asset: SpineDataName.IDENTIFICATION_NPC,
		skin: EnemyBipedSkinType.DEFAULT,
	},
	{
		asset: SpineDataName.ANT_NPC_A,
		skin: EnemyBipedSkinType.DEFAULT,
	},
	{
		asset: SpineDataName.ANT_NPC_B,
		skin: EnemyBipedSkinType.DEFAULT,
		zOffset: 4900,
	},
	{
		asset: SpineDataName.ISLAND_NPC,
		skin: EnemyBipedSkinType.DEFAULT,
	},
	{
		asset: SpineDataName.BALLOON_NPC,
		skin: EnemyBipedSkinType.DEFAULT,
	},
	{
		asset: SpineDataName.WEATHERMAN_NPC,
		skin: EnemyBipedSkinType.DEFAULT,
	},
	{
		asset: SpineDataName.PIT_OF_CHANCES_NPC,
		skin: EnemyBipedSkinType.DEFAULT,
		zOffset: 10,
	},
	{
		asset: SpineDataName.PET_BEAR,
		skin: NPCSkinType.BEAR_BROWN,
		zOffset: 10,
		modelHeightOffset: 150 
	},
	{
		asset: SpineDataName.PET_RACCOON,
		skin: NPCSkinType.RACCOON_GREY,
		zOffset: 10,
		modelHeightOffset: 150 
	},
	{
		asset: SpineDataName.PET_DOG,
		skin: NPCSkinType.DOG_GOLDEN_RETREIVER,
		zOffset: 10,
		modelHeightOffset: 150 
	},
	{
		asset: SpineDataName.PET_CAT,
		skin: NPCSkinType.CAT_CALICO,
		zOffset: 10,
		modelHeightOffset: 150 
	},
	{
		asset: SpineDataName.PET_WHALE,
		skin: NPCSkinType.WHALE_KILLER,
		zOffset: 10,
		modelHeightOffset: 150 
	},
	{
		asset: SpineDataName.PET_BIRD,
		skin: NPCSkinType.BIRD_CHICKEN,
		zOffset: 10,
		modelHeightOffset: 150 
	},
	{
		asset: SpineDataName.BULWARK,
		skin: EnemyBipedSkinType.DEFAULT,
		zOffset: Number.MAX_SAFE_INTEGER,
		onCreatedFunction: (model: RiggedSpineModel, rotation: number) => {
			// how many 45 deg rots is it? % 3 possibilities (horizontal, 45deg, vertical)
			const rotatedTimes = Math.abs(rotation) / (Math.PI / 4)
			const rotIndex = rotatedTimes % 4
			const introAnimation = BULWARK_INTRO_ANIMS[rotIndex]
			playAnimation(model, introAnimation)

			if ((rotatedTimes === 1 && rotation > 0) || (rotatedTimes === 3 && rotation < 0)) {
				model.scale.set(-1, 1)
			}
		},
	},
	{
		asset: SpineDataName.AURUM_GENERIC_NPC,
		skin: EnemyBipedSkinType.DEFAULT
	},
	{
		asset: SpineDataName.IRON_GENERIC_NPC,
		skin: EnemyBipedSkinType.DEFAULT
	},
	{
		asset: SpineDataName.DAWN_GENERIC_NPC,
		skin: EnemyBipedSkinType.DEFAULT
	},
	{
		asset: SpineDataName.AURUM_RECRUITMENT_NPC,
		skin: EnemyBipedSkinType.DEFAULT
	},
	{
		asset: SpineDataName.IRON_RECRUITMENT_NPC,
		skin: EnemyBipedSkinType.DEFAULT,
		zOffset: 150
	},
	{
		asset: SpineDataName.DAWN_RECRUITMENT_NPC,
		skin: EnemyBipedSkinType.DEFAULT
	},
]

configs.forEach((config) => {
	NPCConfigs.set(config.asset, config)
})

const BULWARK_INTRO_ANIMS = [AnimationTrack.INTRO_VERTICAL, AnimationTrack.INTRO_45, AnimationTrack.INTRO_HORIZONTAL, AnimationTrack.INTRO_45]

export { NPCConfigs }
