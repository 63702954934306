enum DestructiblePropDensity {
	NONE,
	LIGHT,
	MEDIUM,
	HEAVY,
	ULTRA_HEAVY,
}

const PropSpawnPointDensityCounts = {}
PropSpawnPointDensityCounts[DestructiblePropDensity.NONE] = 0
PropSpawnPointDensityCounts[DestructiblePropDensity.LIGHT] = 1
PropSpawnPointDensityCounts[DestructiblePropDensity.MEDIUM] = 2
PropSpawnPointDensityCounts[DestructiblePropDensity.HEAVY] = 3
PropSpawnPointDensityCounts[DestructiblePropDensity.ULTRA_HEAVY] = 4

const NUMBER_OF_SUBDIVISIONS_HORIZONTAL = 2
const NUMBER_OF_SUBDIVISIONS_VERTICAL = 2

export default DestructiblePropDensity
export { DestructiblePropDensity, PropSpawnPointDensityCounts, NUMBER_OF_SUBDIVISIONS_HORIZONTAL, NUMBER_OF_SUBDIVISIONS_VERTICAL }
