import nengi from 'nengi'

class PlayerIsPurchasingMessage {
	static protocol = {
		purchasing: nengi.Boolean,
	}

	purchasing: boolean

	constructor(purchasing) {
		this.purchasing = purchasing
	}
}

export default PlayerIsPurchasingMessage
