import logger from '../../utils/client-logger'
import { NengiClient } from '../../engine/client/nengi-client'
import IdentifyItemCommand from '../../items/shared/identify-item-command'
import { uuid } from '../../utils/primitive-types'
import { UI } from '../ui'

interface IdentifyUIState {
	identificationInProgress: boolean
	identifiedItem: uuid
	showingIdentifiedItem: boolean
	pendingIdentifications: number
	pendingIdentifyingItems: Set<uuid>
}

export const identifyModule = () => {
	logger.debug('Initializing Identify store module...')
	return {
		namespaced: true,
		state: {
			identificationInProgress: false,
			identifiedItem: null,
			showingIdentifiedItem: false,
			pendingIdentifications: 0,
			pendingIdentifyingItems: new Set(),
		} as IdentifyUIState,
		getters: {
			identifiedItem(state: IdentifyUIState) {
				return state.identifiedItem
			},
			showingIdentifiedItem(state: IdentifyUIState) {
				return state.showingIdentifiedItem
			},
			identificationInProgress(state) {
				return state.identificationInProgress
			},
			isIdentifying(state: IdentifyUIState) {
				return (itemId) => {
					return state.pendingIdentifyingItems.has(itemId)
				}
			},
		},
		actions: {
			identifyItem(context, selectedItem?: { id: uuid }) {
				const { state, commit } = context as { state: IdentifyUIState; commit: any }
				commit('identificationStarted')
				state.pendingIdentifications += 1
				state.pendingIdentifyingItems.add(selectedItem.id)
				NengiClient.getInstance().sendCommand(new IdentifyItemCommand(selectedItem.id))

				this.commit(
					'itemComparison/selectItem',
					{
						itemId: selectedItem.id,
						container: 'stash',
					},
					{ root: true },
				)
			},
			identifyDeliveryItem(context, selectedItem?: { id: uuid }) {
				const { state, commit } = context as { state: IdentifyUIState; commit: any }
				commit('identificationStarted')
				state.pendingIdentifications += 1
				state.pendingIdentifyingItems.add(selectedItem.id)
				NengiClient.getInstance().sendCommand(new IdentifyItemCommand(selectedItem.id))

				this.commit(
					'itemComparison/selectItem',
					{
						itemId: selectedItem.id,
						container: 'wormDelivery',
					},
					{ root: true },
				)
			},
			deselectIdentifiedItem(context) {
				const { state, commit } = context as { state: IdentifyUIState; commit: any }

				state.identifiedItem = null
				state.showingIdentifiedItem = false

				commit('itemComparison/deselectItem', {}, { root: true })
			},
		},
		mutations: {
			identificationComplete(state: IdentifyUIState) {
				state.identificationInProgress = false
			},
			identificationStarted(state: IdentifyUIState) {
				state.identificationInProgress = true
			},
			resetState(state: IdentifyUIState) {
				state.identifiedItem = null
				state.showingIdentifiedItem = false
				state.pendingIdentifications = 0
			},
			setIdentifiedItem(state: IdentifyUIState, { itemId, identifiedFromDelivery }) {
				state.pendingIdentifications -= 1
				state.pendingIdentifyingItems.delete(itemId)
				if (state.pendingIdentifications <= 0) {
					state.pendingIdentifications = 0

					state.identifiedItem = itemId
					const activePanel = UI.getInstance().store.getters['inGame/activePanel']

					if (activePanel === 'wormDelivery' || activePanel === 'identify' || activePanel === 'stash') {
						if (activePanel === 'wormDelivery') {
							this.commit('hubWormDelivery/selectHubWormDeliveryItem', state.identifiedItem, { root: true })
						} else if (activePanel === 'identify') {
							state.showingIdentifiedItem = true
						}

						this.commit(
							'itemComparison/selectItem',
							{
								itemId: state.identifiedItem,
								container: identifiedFromDelivery ? 'wormDelivery' : 'stash',
							},
							{ root: true },
						)
					}
				}
			},
		},
	}
}
