import { radians, gameUnits, percentage } from '../../utils/primitive-types'
import { ParticleEffectType } from '../../engine/shared/game-data/particle-config'
import { BuffIdentifier } from '../../buffs/shared/buff.shared'
import { Vector } from 'sat'

export const PLAYER_PROJECTILE_COLOR = 0x00d8ff
export enum ModType {
	STRAIGHT = 'straight',
	ACCELERATION = 'acceleration',
	ABS_ACCELERATION = 'absAcceleration',
	TURN = 'turn',
	HARD_TURN = 'hardTurn',
	WAVE = 'wave',
	ZIGZAG = 'zigzag',
	CIRCLE_POINT = 'circlePoint',
	CIRCLE_SELF = 'circleSelf',
	SIZE = 'size',
}

export enum ProjectileTargetType {
	PLAYER = 'player',
	ENEMY = 'enemy',
}

export enum ProjectileEffectType {
	Damage,
	Heal,
}

export enum BurstFireModes {
	// Subsequent shots fire at the same angle as the initial shot
	STRAIGHT = 'straight',
	// Update the target tracking between shots, so that each one is aimed
	// 'burstAngle' indicates how far the aim can change between shots
	TRACKING = 'tracking',
	// Steadily shift the angle over the course of the burst
	// 'burstAngle' indicates how far the sweep will cover
	SWEEPING = 'sweeping',
}

export interface TrajectoryMod {
	modType: ModType
	value?: TrajectoryModValue
	amplitude?: TrajectoryModValue
	period?: TrajectoryModValue
	radius?: TrajectoryModValue
	range?: TrajectoryModValue
	computed?: number
}

export enum ModValueType {
	VALUE = 'value',
	RANDOM = 'random',
	DISTANCE = 'distance',
	LIFETIME = 'lifetime',
	LIFETIME_SQUARED = 'lifetimeSquared',
}

export interface RollMapping {
	rollId: number
	min: number
	max: number
}

export interface TrajectoryModValue {
	modValueType: ModValueType
	value?: number | RollMapping
	min?: number | RollMapping
	max?: number | RollMapping
}

/** Projectile configuration specific to beam weapons */
export interface BeamConfig {
	length: number
	applyBuffsOnHit?: BuffIdentifier[]
	onHitFn?: (projectile, owner, target) => void
}

export interface ProjectileConfig {
	projectileCount: number
	maxRange: gameUnits
	spreadAngle: radians
	spreadVariance: radians
	speed: number
	colliderRadius: number
	lifespanInSeconds: number
	modifiers: TrajectoryMod[]
	bulletParticleEffect: ParticleEffectType
	muzzleFlairParticleEffect?: ParticleEffectType
	bulletTrailParticleEffect?: ParticleEffectType
	color?: number
	pierceCount?: number
	/** Override the normal damage behavior by providing this pre-calculated base damage */
	basePhysicalDamage?: number
	baseFireDamage?: number
	baseIceDamage?: number
	baseLightningDamage?: number
	basePoisonDamage?: number
	/** Add a one-off multiplier to the normal damage */
	damageMultiplier?: percentage
	noDamage?: boolean
	splashDamage?: percentage
	splashRadius?: gameUnits
	chargedWeaponDamage?: number
	knockback?: gameUnits
	refreshTickRateInSeconds?: number
	creatorItemLevel?: number
	damageBypassesShield?: boolean
	energyCost?: number
	targetType?: ProjectileTargetType
	effectType: ProjectileEffectType
	burstCount?: number
	burstDelay?: number
	burstMode?: BurstFireModes
	burstAngle?: radians
	/** Apply these buffs when the projectile strikes a target */
	applyBuffsOnHit?: BuffIdentifier[]
	/** Run this perfectly harmless function when the projectile strikes a target */
	onHitFn?: (projectile, owner, target) => void
}


export interface IProjectileShooter {
	//statList: any
	fixedAimVector: Vector
	aimVector: any
	nid: number
	currentAttackCooldown: number
	useFixedAimVector: boolean
	ignoreAngleOnWeaponOffset?: boolean
	x: number
	y: number
	position: Vector
	isPlayer: boolean
	flipNextShot?: boolean
	getStat(statName: string): number
	isInSafeZone(): boolean
	reduceEnergy(amount: number): void
	isDead(): boolean
	madeProjectile(proj: any): void
	ownedProjectileDied(proj: any): void
}

export interface EntityHit {
	nid: number
	projectileHitBy: number[]
	piercedBy: number[]
}
