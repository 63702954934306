<template>
	<div id="banner-alerts" :class="{ visible: alertVisible }">
		<div class="banner-alerts-countdown">
			<div class="banner-alerts-text" :style="`color=${alertColor};`">{{ alert }}</div>
		</div>
	</div>
</template>

<script lang="js">
import { mapGetters } from 'vuex'
export default {
    name: 'BannerAlerts',
    computed: {
        ...mapGetters('hud', ['alert', 'alertDuration', 'alertVisible', 'alertColor'])
    }
}
</script>

<style lang="less" scoped>
@import url('../global/fonts/text.less');
#banner-alerts {
	position: fixed;
	display: flex;
	justify-content: center;
	width: 500px;
    left: 50%;
	margin-left: -250px;
    top: 50%;
    margin-top: 150px;
	background-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 20%, rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 0.7) 80%, rgba(0, 0, 0, 0) 100%);

	opacity: 0;
	transition: margin-top 500ms ease, opacity 500ms ease;

	&.visible {
		opacity: 1;
		margin-top: 100px;
	}

	.banner-alerts-countdown {
		display: flex;
		flex-direction: column;
		text-align: center;
		padding: 15px 0px;
		margin: 0px 0px;
		.banner-alerts-text {
			flex-wrap: nowrap;
			.banner-alerts-text();
		}
	}
}
</style>
