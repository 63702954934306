

























































































































































































import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import ArrowButton from '../reusable-components/buttons/arrow-button.vue'
import PanelDivider from '../reusable-components/panel-divider/panel-divider.vue'
import ListErrors from '../reusable-components/inputs/list-errors.vue'
import TextInput from '../reusable-components/inputs/text-input.vue'
import Checkbox from '../reusable-components/inputs/checkbox.vue'
import VueRecaptcha from 'vue-recaptcha'

export default {
	name: 'NotLoggedIn',
	components: {
		MainPanel,
		ArrowButton,
		PanelDivider,
		ListErrors,
		TextInput,
		Checkbox,
		VueRecaptcha,
	},
	data() {
		return {
			register: {
				agreeToAge: false,
			},
		}
	},
	computed: {
		...mapGetters('user', ['username', 'getRecaptchaV3Failed', 'getRecaptchaCheckboxFlag', 'getRecaptchaV2Failed']),
		...mapState('notLoggedIn', ['registering']),
		...mapGetters('inGame', ['activePanel']),
	},
	methods: {
		...mapGetters('notLoggedIn', ['isMain', 'isLogin', 'isRegister', 'getUserNameError', 'getPasswordError', 'getAgreeToTermsError', 'getEmailError', 'getRegistrationError', 'getAgeError']),
		...mapActions('inGame', ['closeActivePanel']),
		...mapActions('notLoggedIn', ['registerPlayer']),
		...mapMutations('notLoggedIn', ['changeMenuState', 'updateRegistrationField', 'updateAgreeToTerms', 'updateAgreeToPromotion', 'updateAgreeToPromotion', 'updateAgeCheckError', 'clearAgeCheckError', 'clearAllErrors']),
		...mapActions('user', ['submitLogin']),
		...mapMutations('user', ['updateUserNameError', 'updateEmailError', 'updatePasswordError', 'updateAgreeToTermsError', 'resetUserErrors']),
		updateEmail(textInputValue) {
			this.login.email = textInputValue
		},
		updatePassword(textInputValue) {
			this.login.password = textInputValue
		},
		backClicked() {
			this.changeMenuState('main')
			this.clearAllErrors()
		},
		async validateAge() {
			this.clearAgeCheckError()
			if (this.register.agreeToAge !== true) {
				this.updateAgeCheckError(`You need to confirm that you're over the age of 16 or have consent`)
			} else {
				await this.$recaptchaLoaded()
				let recaptchaToken = await this.$recaptcha('REGISTER_ACCOUNT')
				let tag = 'v3'
				if (this.getRecaptchaV3Failed) {
					//The checkbox flag should only return true serverside validation for v3 token has returned a low score
					recaptchaToken = this.recaptchaV2Token
					tag = 'v2'
				}

				this.registerPlayer({recaptchaToken, tag})
			}
		},
		async getToken(result) {
			this.recaptchaV2Token = result
			this.updateRecaptchaCheckboxFlag(false)
		},
	},
}
