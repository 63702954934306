import { map } from 'lodash'
import { NengiClient } from '../../engine/client/nengi-client'
import { GearSkillStats, StatToPrettyName } from '../../engine/shared/game-data/stat-type-mod-category'
import { RollItemCommand } from '../../engine/shared/roll-item-command'
import { GearSubType } from '../../loot/shared/gear-sub-type'
import ItemRarity from '../../loot/shared/item-rarity'
import ItemType from '../../loot/shared/item-type'
import { PrizeSubType } from '../../loot/shared/prize-sub-type'
import { WeaponAugmentConfigData } from '../../loot/shared/weapon-augment-sub-type'
import WeaponSubType from '../../loot/shared/weapon-sub-type'
import StatModPoolType from '../../loot/shared/stat-mod-pool-type'
import { debugConfig } from '../../engine/client/debug-config'
import { PersistentBuffSubType } from '../../loot/shared/persistent-buff-sub-type'

export function itemTools() {
	return {
		namespaced: true,
		state: {
			itemTypes: [
				{ label: 'Biome Key', value: ItemType.BiomeKey },
				{ label: 'Gear', value: ItemType.Gear },
				{ label: 'Lucky Shard', value: ItemType.LuckyShard },
				{ label: 'Rarity Shard', value: ItemType.RarityShard },
				{ label: 'Weapon', value: ItemType.Weapon },
				{ label: 'Weapon Augment', value: ItemType.WeaponAugment },
				{ label: 'Persistent Buff', value: ItemType.PersistentBuff },
			],
			selectedItemType: null,
			selectedItemSubType: null,
			itemRarities: [
				{ label: 'Common', value: ItemRarity.COMMON },
				{ label: 'Uncommon', value: ItemRarity.UNCOMMON },
				{ label: 'Rare', value: ItemRarity.RARE },
				{ label: 'Epic', value: ItemRarity.EPIC },
				{ label: 'Legendary', value: ItemRarity.LEGENDARY },
				{ label: 'Astronomical', value: ItemRarity.ASTRONOMICAL },
			],
			selectedRarity: ItemRarity.RARE,
			weaponElements: [
				{ label: 'Physical', value: 'physical' },
				{ label: 'Fire', value: 'fire' },
				{ label: 'Ice', value: 'ice' },
				{ label: 'Lightning', value: 'lightning' },
				{ label: 'Poison', value: 'poison' },
			],
			selectedWeaponElement: null,
			gearSkills: GearSkillStats.map((v) => { return { label: StatToPrettyName[v], value: v } } ),
			weaponMods: null,
			selectedGearSkill: null,
			selectedWeaponMods: [],
			itemLevel: 15,
			rollIdentified: true,
			listOnMarketplace: false,
			allowBulkMarketplaceListing: debugConfig.items.allowBulkMarketplaceListing
		},
		getters: {
			getItemSubTypes(state) {
				return (itemType) => {
					switch (itemType) {
						// Anything that doesn't have a subtype returns an empty list
						case ItemType.BiomeKey:
						case ItemType.LuckyShard:
						case ItemType.RarityShard:
							return []
						case ItemType.Gear:
							return [
								{ label: 'Trinket', value: GearSubType.Trinket },
								{ label: 'Ring', value: GearSubType.Ring },
								{ label: 'Belt', value: GearSubType.Belt },
								{ label: 'Pendant', value: GearSubType.Pendant },
							]
						case ItemType.Weapon:
							return [
								{ label: 'Arcane Focus', value: WeaponSubType.ArcaneFocus },
								{ label: 'Crossbow', value: WeaponSubType.Crossbow },
								{ label: 'Scythe', value: WeaponSubType.Scythe },
								{ label: 'Spellsword', value: WeaponSubType.Spellsword },
								{ label: 'Staff', value: WeaponSubType.Staff },
								{ label: 'Wand', value: WeaponSubType.Wand },
							]
						case ItemType.WeaponAugment:
							return map([...WeaponAugmentConfigData], ([subType, value]) => ({ label: value.prettyName, value: subType }))
						case ItemType.PersistentBuff:
							return [
								{ label: 'Enchanting', value:  PersistentBuffSubType.Enchanting },
								{ label: 'Legendary Enchanting', value:  PersistentBuffSubType.LegendaryEnchanting },
								{ label: 'Life Bloom', value:  PersistentBuffSubType.LifeBloom },
								{ label: 'Regenerative Healing', value:  PersistentBuffSubType.RegenerativeHealing },
							]
					}
				}
			},
			getWeaponMods(state) {
				return () => {
					return state.weaponMods
						.map((mod) => ({ label: StatToPrettyName[mod], value: mod }))
				}
			},
		},
		mutations: {
			setActiveItemType(state, type) {
				state.selectedItemType = type.value
			},
			setActiveRarity(state, rarity) {
				state.selectedRarity = rarity.value
			},
			setActiveElement(state, element) {
				state.selectedElement = element.value
			},
			setActiveItemSubType(state, subType) {
				state.selectedItemSubType = subType.value
			},
			setActiveGearSkill(state, skill) {
				state.selectedGearSkill = skill.value
			},
			setItemLevel(state, message) {
				state.itemLevel = Math.clamp(message.target.value, 1, 450)
			},
			setRollIdentified(state, message) {
				state.rollIdentified = !state.rollIdentified
			},
			setListOnMarketplace(state, message) {
				state.listOnMarketplace = !state.listOnMarketplace
				console.log('setListOnMarketplace:', state.listOnMarketplace)
			},
			setSelectedWeaponMods(state, { mod, index }) {
				state.selectedWeaponMods[index] = mod.value
			},
			setWeaponMods(state, message) {

				if (message.poolType === StatModPoolType.ALL_WEAPONS) {
					state.weaponMods = message.itemModSet
				}
			}
		},
		actions: {
			rollItem({ commit, state }) {
				const type = state.selectedItemType || -1
				const subType = state.selectedItemSubType || -1
				const rarity = state.selectedRarity || -1
				const element = state.selectedWeaponElement || -1
				const gearSkill = state.selectedGearSkill || -1
				const level = state.itemLevel
				const identified = state.rollIdentified
				const listOnMarketplace = state.listOnMarketplace
				const weaponMods = state.selectedWeaponMods
				NengiClient.getInstance().sendCommand(new RollItemCommand({ type, subType, rarity, element, gearSkill, level, identified, weaponMods, listOnMarketplace }))
			},
			rollOneOfEachSubType({ commit, state }) {
				const type = state.selectedItemType || -1
				const subType = state.selectedItemSubType || -1
				const rarity = state.selectedRarity || -1
				const element = state.selectedWeaponElement || -1
				const gearSkill = state.selectedGearSkill || -1
				const level = state.itemLevel
				const identified = state.rollIdentified
				const listOnMarketplace = state.listOnMarketplace
				const weaponMods = state.selectedWeaponMods
				NengiClient.getInstance().sendCommand(new RollItemCommand({ type, subType, rarity, element, gearSkill, level, identified, rollOneOfEachSubType: true, listOnMarketplace, weaponMods }))
			},
			rollPetEggs({ commit, state }) {
				const type = ItemType.Prize
				const subType = PrizeSubType.PetEgg
				const rarity = -1
				const element = ''
				const gearSkill = -1
				const level = 0
				const identified = true
				const listOnMarketplace = false
				const weaponMods = []
				const rollOneOfEachSubType = false
				NengiClient.getInstance().sendCommand(new RollItemCommand({ type, subType, rarity, element, gearSkill, level, identified, listOnMarketplace, weaponMods, rollOneOfEachSubType }))
			},
			rollCosmeticAttachments({ commit, state }) {
				const type = ItemType.Prize
				const subType = PrizeSubType.PlayerSkin
				const rarity = -1
				const element = ''
				const gearSkill = -1
				const level = 0
				const identified = true
				const listOnMarketplace = false
				const weaponMods = []
				const rollOneOfEachSubType = false
				NengiClient.getInstance().sendCommand(new RollItemCommand({ type, subType, rarity, element, gearSkill, level, identified, listOnMarketplace, weaponMods, rollOneOfEachSubType }))
			},
			rollFactionCosmetics({ commit, state }) {
				const type = ItemType.Prize
				const subType = PrizeSubType.FactionCosmetic
				const rarity = -1
				const element = ''
				const gearSkill = -1
				const level = 0
				const identified = true
				const listOnMarketplace = false
				const weaponMods = []
				const rollOneOfEachSubType = false
				NengiClient.getInstance().sendCommand(new RollItemCommand({ type, subType, rarity, element, gearSkill, level, identified, listOnMarketplace, weaponMods, rollOneOfEachSubType }))
			},
		},
	}
}
//TODO: Ben - Add options for rolling a specified number of augment sockets and special trajectories