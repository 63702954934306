import moment from 'moment-timezone'
import ClaimDailyLoginCommand from '../../daily-login/shared/claim-daily-login-command'
import DailyLoginClaimResultMessage from '../../daily-login/shared/daily-login-claim-result-message'
import DailyLoginInfoMessage from '../../daily-login/shared/daily-login-info-message'
import { getDailyLoginConfigForDay, getDailyLoginRewardForDay, getNextDailyLoginMilestone } from '../../daily-login/shared/daily-login-reward-data'
import { NengiClient } from '../../engine/client/nengi-client'
import { anyRewardToGridItems } from '../../items/shared/reward-types'
import { Audio } from '../../audio/client/audio'
import logger from '../../utils/client-logger'
import { UI } from '../ui'
import { showGenericInfoPromptUI } from './generic-information-prompt.ui-state'

export const dailyRewardsModule = () => {
	logger.debug('Initializing Daily Rewards store module...')
	return {
		namespaced: true,
		state: {
			lastClaimedLoginBonus: null,
			dailyLoginsClaimed: 0,
			nextLoginReward: 0,
			lastLoginReward: 0,
			unixOffset: 0,
			isClaiming: true,
			errorClaiming: false,
		},
		getters: {
			getTodayRewards(state, getters) {
				let reward
				if (getters.getClaimedToday) {
					reward = getDailyLoginRewardForDay(state.dailyLoginsClaimed - 1, state.lastLoginReward)
				} else {
					// use nextLoginReward
					reward = getDailyLoginRewardForDay(state.dailyLoginsClaimed, state.nextLoginReward)
				}

				return anyRewardToGridItems(reward)
			},
			getTomorrowRewards(state, getters) {
				if (getters.getClaimedToday) {
					// nextLoginReward
					const reward = getDailyLoginRewardForDay(state.dailyLoginsClaimed, state.nextLoginReward)
					return anyRewardToGridItems(reward)
				} else {
					//mystery until you claim
					return []
				}
			},
			getNextMilestoneRewards(state, getters) {
				const nextReward = getNextDailyLoginMilestone(state.dailyLoginsClaimed)
				if (nextReward) {
					return anyRewardToGridItems(nextReward.reward)
				} else {
					return []
				}
			},
			getNumDaysToNextMilestone(state, getters) {
				const nextReward = getNextDailyLoginMilestone(state.dailyLoginsClaimed)
				if (nextReward) {
					return nextReward.day - state.dailyLoginsClaimed + (getters.getClaimedToday ? 1 : 0)
				}

				return 0
			},
			getClaimedToday(state) {
				const now = moment().add(state.unixOffset, 'seconds').utc()
				return now.diff(state.lastClaimedLoginBonus, 'd') < 1
			},
			todayIsMilestone(state, getters) {
				let config
				if (getters.getClaimedToday) {
					config = getDailyLoginConfigForDay(state.dailyLoginsClaimed - 1)
				} else {
					config = getDailyLoginConfigForDay(state.dailyLoginsClaimed)
				}
				return config.isMilestone
			},
			tomorrowIsMilestone(state, getters) {
				if (getters.getClaimedToday) {
					const config = getDailyLoginConfigForDay(state.dailyLoginsClaimed) as any
					return config.isMilestone
				}

				return false
			},
		},
		actions: {},
		mutations: {
			setDailyLoginRewards(state, message: DailyLoginInfoMessage) {
				const now = moment().utc().unix()
				state.unixOffset = now - message.nowUnix

				state.lastClaimedLoginBonus = moment(message.lastClaimedLoginBonus).utc()
				state.dailyLoginsClaimed = message.dailyLoginsClaimed
				state.nextLoginReward = message.nextLoginReward
				state.lastLoginReward = message.lastLoginReward
				state.isClaiming = false

				const adjustedNow = moment().subtract(state.unixOffset, 'seconds').utc()
				const claimedToday = adjustedNow.diff(state.lastClaimedLoginBonus, 'd') < 1

				const isAnon = UI.getInstance().store.getters['user/userType'] === 'anonymous'

				if (!claimedToday && !isAnon) {
					if (UI.getInstance().store.getters['inGame/activePanel'] !== 'dailyRewards') {
						UI.getInstance().emitEvent('inGame/setActivePanel', 'dailyRewards')
					}

					UI.getInstance().emitEvent('inGame/setPanelSwitchable', false)
				}
			},
			updateDailyRewardCollected(state, message: DailyLoginClaimResultMessage) {
				const now = moment().utc().unix()
				state.unixOffset = now - message.nowUnix

				state.lastClaimedLoginBonus = moment(message.lastClaimedLoginBonus).utc()
				state.dailyLoginsClaimed = message.dailyLoginsClaimed
				state.lastLoginReward = state.nextLoginReward
				state.nextLoginReward = message.nextLoginReward
				state.isClaiming = false

				UI.getInstance().emitEvent('inGame/setPanelSwitchable', true)
				if (!message.granted) {
					state.errorClaiming = true
					showGenericInfoPromptUI('Error', ['An error occured while trying to claim the daily rewards.', 'Please try again later'])
				}
			},
			claimDailyReward(state) {
				state.isClaiming = true
				NengiClient.getInstance().sendCommand(new ClaimDailyLoginCommand())
				Audio.getInstance().playSfx('UI_Click_Daily_Reward')
			},
		},
	}
}