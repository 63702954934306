








import { mapState, mapActions } from 'vuex'
import { v4 } from 'uuid'
import { autoDetectRenderer, Container, Renderer as pixiRenderer } from 'pixi.js'
import { AssetManager } from '../../../asset-manager/client/asset-manager'
import { RiggedSpineModel } from '../../../models-animations/client/spine-model'
import { SLOT_PFX, Position } from '../../state/slot-animation.ui-state'
import Time from '../../../engine/shared/time'
import { debugConfig } from '../../../engine/client/debug-config'
import { timeInSeconds } from '../../../utils/primitive-types'
import playAnimation from '../../../models-animations/client/play-animation'
import { AnimationTrack } from '../../../models-animations/shared/animation-track'
import { debounce } from 'lodash'
import { SpineAssetNames } from '../../state/slot-animation.ui-state'

export default {
	name: 'SlotAnimation',
	data() {
		return {
			CanvasId: '',
			resizeDebounced: null,
		}
	},
	props: {},
	computed: {
		...mapState('slotAnimation', ['positioning', 'targets', 'triggerOutro', 'padding', 'assetName', 'SLOT_PFX']),
		position(){
			return `top: ${this.positioning[0].top - this.padding}px; left: ${this.positioning[0].left - this.padding}px;`
		}
	},
	methods: {
		...mapActions('slotAnimation', ['rebuildPositioning']),

		renderPFX() {
			SLOT_PFX.instance = new SlotPfx('ui-canvas-' + this.CanvasId, this.positioning, this.padding, this.assetName)

			let lastTick = Time.timestampOfCurrentFrameStartInMs

			this.intervalID = window.setInterval(() => {
				const currentTick = Time.timestampOfCurrentFrameStartInMs
				const delta = ((currentTick - lastTick) / 1000) * debugConfig.timeScale
				if (SLOT_PFX.instance) {
					SLOT_PFX.instance.update(delta, this.triggerOutro)
				}
			}, 33)
		},
	},
	created(){
		this.CanvasId = v4()
		// IF position after resize becomes a issue. either in ui-scale state lower
		// the debounce OR change up completely how this component is rendered.
		// Maybe have the component inside the item container component?
		this.resizeDebounce = debounce(this.rebuildPositioning, 300)
		window.addEventListener('resize', this.resizeDebounce)
		
	},
	mounted(){
		this.renderPFX()
	},
	beforeDestroy() {
		window.clearInterval(this.intervalID)
		SLOT_PFX.instance.destroy()
		SLOT_PFX.instance = null
	}
}

class SlotPfx {
	private pixiRenderer: pixiRenderer
	private stage: Container
	private width: number
	private height: number
	private reveal: RiggedSpineModel
	private padding: number
	private assetName: string

	constructor(canvasId: string, positioning:Position, padding: number, assetName:string){
		const canvas: HTMLCanvasElement = document.getElementById(canvasId) as HTMLCanvasElement
		this.padding = padding
		this.width = positioning[0].width
		this.height = positioning[0].height
		this.assetName = assetName

		this.pixiRenderer = autoDetectRenderer({
			width: this.width,
			height: this.height,
			view: canvas,
			antialias: false,
			transparent: true,
			resolution: 1,
		})

		this.stage = new Container()
		this.stage.name = canvasId
	}

	update(delta: timeInSeconds, triggerOutro): void {
		if (this.stage && this.pixiRenderer) {
			this.pixiRenderer.render(this.stage)
			this.updateReveal(triggerOutro)
			this.reveal.update(delta)
		}
	}

	updateReveal(triggerOutro){
		if(!triggerOutro) {
			this.revealIntroVisuals()
		} else {
			this.playOutro()
		}
	}

	revealIntroVisuals(){
		this.reveal?.destroy()
		let asset = AssetManager.getInstance().getAssetByName(this.assetName)		
		const spineData = asset.spineData as PIXI.spine.core.SkeletonData
		this.reveal = new RiggedSpineModel(spineData)
		this.reveal.scale.x = 0.5
		this.reveal.scale.y = 0.5
		this.reveal.name = 'reveal'
		this.reveal.visible = true
		this.reveal.skeleton.setSkinByName('default')
		this.reveal.skeleton.setToSetupPose()
		this.stage.addChild(this.reveal)
		this.reveal.position.set((this.width / 2), (this.height / 2)) 
		this.playIntro()
	}

	playIntro(){
		if(this.assetName === SpineAssetNames.AUGMENT_UNLOCK) {
			playAnimation(this.reveal, AnimationTrack.INTRO)
		} else if(this.assetName === SpineAssetNames.GENERIC_UNVEIL) {
			playAnimation(this.reveal, AnimationTrack.GENERIC_UNVEIL)
		}
	}

	playOutro(){
		if(this.assetName === SpineAssetNames.AUGMENT_UNLOCK){
			playAnimation(this.reveal, AnimationTrack.OUTRO)
		}
	}

	destroy(){
		this.reveal?.destroy()
		this.stage?.destroy()
		this.pixiRenderer?.destroy()
	}
}
