import logger from '../../utils/client-logger'
import { NengiClient } from '../../engine/client/nengi-client'
import StartSmeltingItemCommand from '../../items/shared/start-smelting-item-command'
import ClaimSmeltedItemRewards from '../../items/shared/claim-smelted-item-rewards-command'
import { Audio } from '../../audio/client/audio'
import moment from 'moment-timezone'
import { reduce, some, orderBy } from 'lodash'
import { uuid } from '../../utils/primitive-types'
import { showGenericYesNoUI } from './generic-yes-no.ui-state'
import { isHighRarityItem, rarityPromptText } from './rarity-helper'
export const FURNACE_SLOT_COUNT = 3

export function isFurnaceDoneSmelting(furnaceData, now): boolean {
	return now.isAfter(furnaceData.completionTime) || furnaceData.isFinished
}

export const furnaceModule = () => {
	logger.debug('Initializing Furnace store module...')
	return {
		namespaced: true,
		state: {
			slotTotalCount: FURNACE_SLOT_COUNT,
			slotData: [],
			totalItemsInSmelter: [],
			unixDiff: 0,
			selectedFurnaceItem: null,
			couldntLoadVideo: false,
			coinsClaimable: 0,
			canCollect: false,
			isCollecting: false,
			hasFurnaceNotInUse: false,
		},
		getters: {
			totalItemsInSmelter(state) {
				return state.totalItemsInSmelter
			},
			countOfOccupiedFurnaceSlots(state) {
				return reduce(
					state.slotData,
					(acc, v) => {
						if (v.smeltingItemId !== null) {
							return (acc += 1)
						} else {
							return acc
						}
					},
					0,
				)
			},
			furnacesAreCurrentlyFull(state) {
				const occupiedSlotsCount = reduce(
					state.slotData,
					(acc, v) => {
						if (v.smeltingItemId !== null) {
							return (acc += 1)
						} else {
							return acc
						}
					},
					0,
				)
				return occupiedSlotsCount >= FURNACE_SLOT_COUNT
			},
			hasNonSmeltingSlot(state) {
				const hasNonSmeltingSlot = some(state.slotData, (v) => {
					return !isFurnaceDoneSmelting(
						v.smeltingDetails,
						moment()
							.utc()
							.subtract(state.unixDiff, 'seconds'),
					)
				})
				return hasNonSmeltingSlot
			},
			slotOwnedCount(state) {
				return state.slotTotalCount
			},
			hasFreeFurnace(state) {
				const occupiedCount = reduce(
					state.slotData,
					(acc, v) => {
						if (v.smeltingItemId !== null) {
							return (acc += 1)
						} else {
							return acc
						}
					},
					0,
				)

				return occupiedCount < state.slotTotalCount
			},
			itemsInFurnace(state) {
				return state.slotData
			},
			unixDiff(state) {
				return state.unixDiff
			},
		},
		actions: {
			async collectCurrencyRewards({ commit, state }) {
				state.isCollecting = true
				Audio.getInstance().playSfx('UI_Smelting_Reward')
				NengiClient.getInstance().sendCommand(new ClaimSmeltedItemRewards())
			},
			startSmeltingItem({ commit, state, rootGetters }, { itemId, fromContainer }) {
				const itemDetails = rootGetters['itemContainers/itemDetails'](fromContainer, itemId)

				// I don't like this either
				if (fromContainer === 'wormDelivery') {
					fromContainer = 'worm_delivery'
				}

				if (isHighRarityItem(itemDetails)) {
					const questionText = rarityPromptText(itemDetails, 'furnace')

					showGenericYesNoUI(
						'Smelt this item?',
						questionText,
						'',
						'Smelt',
						'Cancel',
						() => {
							Audio.getInstance().playSfx('UI_Click_Smelting')
							if (itemId === state.selectedFurnaceItem) {
								commit('itemComparison/deselectItem', {}, { root: true })
								state.selectedFurnaceItem = null
							}
							NengiClient.getInstance().sendCommand(new StartSmeltingItemCommand(itemId, fromContainer))
						},
						() => {
							commit('itemLocks/unlockItem', itemId, { root: true })
						},
					)
				} else {
					Audio.getInstance().playSfx('UI_Click_Smelting')
					if (itemId === state.selectedFurnaceItem) {
						commit('itemComparison/deselectItem', {}, { root: true })
						state.selectedFurnaceItem = null
					}
					NengiClient.getInstance().sendCommand(new StartSmeltingItemCommand(itemId, fromContainer))
				}
			},
			relinkFurnaceData({ state }, newFurnaceItems) {
				for (let i = 0; i < state.slotData.length; ++i) {
					const slot = state.slotData[i]
					if (slot.noItem) {
						const details = slot.smeltingDetails

						const matchingFurnaceItem = newFurnaceItems.find((f) => f.id === details.itemId)
						if (matchingFurnaceItem) {
							matchingFurnaceItem.smeltingDetails = details
							state.slotData[i] = matchingFurnaceItem
						}
					}
				}
				state.isCollecting = false
			},
			getCollectableCoins({ state }) {
				const now = moment().utc()
				now.subtract(state.unixDiff, 'seconds')

				let canCollect = false
				let totalCoinsClaimable = 0
				state.hasFurnaceNotInUse = false
				for (let i = 0; i < state.slotData.length; ++i) {
					const smeltingDetail = state.slotData[i].smeltingDetails
					if (smeltingDetail) {
						const totalTime = smeltingDetail.completionTime.diff(smeltingDetail.createdAt)
						let percentDone
						if (totalTime > 0) {
							const elapsed = now.diff(smeltingDetail.createdAt)
							percentDone = elapsed / totalTime
						} else {
							//can go below 0 from ad watches
							percentDone = 1
						}
						const itemValue = smeltingDetail.returnValue + smeltingDetail.bonusReturnValue
						const coinsClaimable = Math.max(Math.min(itemValue, Math.floor(itemValue * percentDone)), 0)
						const coinsToClaim = coinsClaimable - smeltingDetail.claimedCoins
						if (coinsToClaim > 0) {
							totalCoinsClaimable += coinsToClaim

							if (coinsClaimable >= itemValue) {
								state.hasFurnaceNotInUse = true
							}
							canCollect = true
						} else if (percentDone >= 1) {
							canCollect = true
						}
					}
				}

				state.canCollect = canCollect
				state.coinsClaimable = totalCoinsClaimable
			},
			updatedFurnaceStateFromServer({ state, commit, rootGetters }, { newFurnaceState, unixTime }) {
				const newSlotData = []
				const oldSlotData = state.slotData
				const parsedFurnaceState = JSON.parse(newFurnaceState)
				const furnaceItems = rootGetters['itemContainers/itemsInContainer']('furnace')
				state.unixDiff = moment().utc().unix() - unixTime
				commit('time/setUnixDiff', state.unixDiff, {root: true}) //@TODO unify

				for (let i = 0; i < FURNACE_SLOT_COUNT; i++) {
					if (parsedFurnaceState.length > i) {
						const furnaceDetail = parsedFurnaceState[i]
						// arbitrarily add a 10 second buffer to account for potential race conditions

						const doneSmeltingAtMoment = moment
							.unix(furnaceDetail.doneSmeltingAt)
							.utc()
							.add(10, 'seconds')
						const nowMoment = moment(unixTime)
							.utc()
							.subtract(state.unixDiff, 'seconds')
						const isSmeltingInProgress = doneSmeltingAtMoment.isAfter(nowMoment)
						const isSmeltingCompleted = doneSmeltingAtMoment.isBefore(nowMoment)

						const oldDetails = oldSlotData.find((oldData) => oldData.smeltingDetails.smeltingItemId === furnaceDetail.id)

						const smeltingDetails = {
							smeltingItemId: furnaceDetail.id,
							itemSelected: {
								icon: furnaceDetail.itemDescriptionString,
							},
							returnValue: furnaceDetail.itemValue,
							bonusReturnValue: furnaceDetail.itemBonusValue,
							completionTime: doneSmeltingAtMoment,
							isInProgress: isSmeltingInProgress,
							isFinished: isSmeltingCompleted,
							isCollecting: oldDetails && oldDetails.smeltingDetails.isCollecting,
							isPickingItem: false,
							itemId: furnaceDetail.itemId,
							createdAt: moment.unix(furnaceDetail.createdAt).utc(),
							claimedCoins: furnaceDetail.claimedCoins,
						}

						const matchingFurnaceItem = furnaceItems.find((f) => f.id === smeltingDetails.itemId)
						if (matchingFurnaceItem) {
							matchingFurnaceItem.smeltingDetails = smeltingDetails
							newSlotData.push(matchingFurnaceItem)
						} else {
							newSlotData.push({ smeltingDetails, noItem: true })
						}
					}
				}

				commit('setFurnaceSlotData', newSlotData)
				state.isCollecting = false
			},
			selectFurnaceItem({ state, commit }, selectedItem?: uuid) {
				if (selectedItem && selectedItem !== state.selectedFurnaceItem) {
					commit(
						'itemComparison/selectItem',
						{
							itemId: selectedItem,
							container: 'stash',
						},
						{ root: true },
					)
					state.selectedFurnaceItem = selectedItem
				} else {
					commit('itemComparison/deselectItem', {}, { root: true })
					state.selectedFurnaceItem = null
				}
			},
		},
		mutations: {
			setFurnaceSlotData(state, newSlotData) {
				state.slotData = orderBy(newSlotData, [
					function(o) {
						return o.smeltingDetails.itemId
					},
				])
			},
			isCollectingRewards(state, furnaceIndex) {
				state.slotData[furnaceIndex].smeltingDetails.isCollecting = true
			},
			finishedCollectingRewards(state, furnaceIndex) {
				state.slotData[furnaceIndex].smeltingDetails.isCollecting = false
			},
			setIsCollecting(state, collecting) {
				state.isCollecting = collecting
			},
			addedItemToTotalSmelting(state, furnaceItem) {
				state.totalItemsInSmelter.push(furnaceItem)
			},
			removeItemFromTotalSmelting(state, furnaceItem) {
				state.totalItemsInSmelter.splice(state.totalItemsInSmelter.indexOf(furnaceItem), 1)
			},
		},
	}
}
