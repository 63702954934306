

















import { mapMutations, mapGetters } from 'vuex'
export default {
	name: 'SideMenu',
	computed: {
		...mapGetters('newsArticles', ['getLatestArticleId', 'getLastReadArticleId']),

		checkIfLatestNewsRead() {
			let isRead = false
			if (this.getLastReadArticleId < this.getLatestArticleId) {
				isRead = true
			} else if (this.getLastReadArticleId >= this.getLatestArticleId) {
				isRead = false
			}
			return isRead
		},
	},
	methods: {
		...mapMutations('inGame', ['setActivePanel']),
	},
}
