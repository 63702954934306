<template>
	<div class="panel-divider">
		<div class="rune-icon left" :class="`${panelDividerStyle}-ruin-icon`"></div>
		<div v-if="showEmbellishments" class="embellish-left"></div>
		<div class="text-container">
			<div
				v-if="activePanel === 'party' && getCurrentlyPartiedStatus === true && getYesNoPromptVisible === false && getPromptVisible === false"
				v-tooltip="{
					content: copyPartyIdTooltip(),
					placement: 'bottom-center',
					offset: 0,
					delay: {
						show: 500,
						hide: 300,
					},
				}"
				class="divider-text"
				@click="dividerClicked()"
			>
				{{ dividerText }} <img src="../buttons/assets/copy-icon.png" alt="" />
			</div>
			<div v-else class="divider-text" v-html="dividerText">
			</div>
		</div>
		<div v-if="showEmbellishments" class="embellish-right"></div>
		<div class="rune-icon right" :class="`${panelDividerStyle}-ruin-icon`"></div>
	</div>
</template>

<script>
const VALID_PANEL_DIVIDER_STYLES = ['main', 'paper-doll']
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { copyPartyIdTooltip } from '../v-tooltip/v-tooltip-functions'

export default {
	name: 'PanelDivider',
	props: {
		dividerText: {
			type: String,
			required: true,
			default: function() {
				console.warn('Currently using default divider text. Ensure <PanelDivider /> component has a custom attribute divider-text')
				return 'Using Default Divider Text'
			},
		},
		panelDividerStyle: {
			type: String,
			default: 'main',
			validator: function(value) {
				return VALID_PANEL_DIVIDER_STYLES.includes(value)
			},
		},
		clickFn: {
			type: Function,
			required: false,
			default: function() {
				console.warn('Currently using the default click function. Ensure <PanelDividier /> component has the custom attribute :click-fn')
			},
		},
		showEmbellishments: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	computed: {
		...mapGetters('inGame', ['activePanel']),
		...mapGetters('party', ['getPartyId', 'getCurrentlyPartiedStatus']),
		...mapGetters('genericYesNo', ['getYesNoPromptVisible']),
		...mapGetters('genericPrompt', ['getPromptVisible']),
	},
	methods: {
		copyPartyIdTooltip,
		dividerClicked() {
			this.clickFn()
		},
	},
}
</script>

<style lang="less" scoped>
@import '../../global/fonts/text.less';

.panel-divider {
	display: table;
	justify-content: space-around;
	width: 100%;
	position: relative;
	height: 34px;
	.rune-icon {
		display: table-cell;
		vertical-align: middle;
		background-position: top;
		background-repeat: no-repeat;
		width: 50px;
		height: 33px;
		align-self: center;
		&.main-ruin-icon {
			background-image: url('./assets/rune-icon-main.png');
		}
		&.paper-doll-ruin-icon {
			background-image: url('./assets/rune-icon-paper-doll.png');
		}
		&.right {
			transform: scaleX(-1);
		}
	}
	.text-container {
		display: table-cell;
		vertical-align: middle;
		.divider-text {
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			.item-names-and-subheaders();
			img {
				width: 46px;
				height: 30px;
			}
		}
	}
}

.embellish-left {
	background-image: url('./assets/faction_page_embellishment_l.png');
	height: 48px;
	width: 84px;
	position: absolute;
	left: 75px;
	bottom: -5px;
}

.embellish-right {
	background-image: url('./assets/faction_page_embellishment_r.png');
	height: 48px;
	width: 84px;
	position: absolute;
	bottom: -5px;
	right: 75px;
}
</style>
