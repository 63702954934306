import { EnemyAI } from '../ai-types'

import { SpineDataName, EnemyBipedSkinType } from '../../../models-animations/shared/spine-config'
import { AnimationTrack } from '../../../models-animations/shared/animation-track'
import crabNest from './crab-nest-a'
import { ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { ENEMY_NAMES } from './enemy-names'

const crabNestE: EnemyAI = {
	...crabNest,
	name: ENEMY_NAMES.CRAB_NEST_E,
	appearance: {
		asset: SpineDataName.CRAB_NEST_E,
		skin: EnemyBipedSkinType.DEFAULT,
		color: [1, 0.75, 0.5],
		mixSettings: [
			{ from: AnimationTrack.IDLE, to: AnimationTrack.IDLE, duration: 0.0 },
			{ from: AnimationTrack.IDLE, to: AnimationTrack.DEATH, duration: 0.5333 },
			{ from: AnimationTrack.SPAWN, to: AnimationTrack.IDLE, duration: 0.2 },
		],
		spawnAnim: true,
		showHealthBarWhenFull: true,
	},
	baseAttributes: {
		...crabNest.baseAttributes,
		colliders: [
			{
				type: ColliderType.Ellipse,
				rX: 180,
				rY: 80,
				position: [0, -30],
				traits: ColliderTraits.BlockMovementAndProjectileAndItem,
			},
		],
	},
}

export default crabNestE
