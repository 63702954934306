<template>
	<div id="buffs-and-debuffs">
		<div class="buff-container">
			<div v-for="item in getBuffs" :key="item.id">
				<BuffsAndDebuffsItem v-show="item.id" :item="item" :duration="item.initialDuration" :applied-at-time="item.appliedAtTime" :status-effect-type="'buffs'" :expires-at-time="item.expiresAtTime" :stacks="item.stacks" :tooltip="item.effect"></BuffsAndDebuffsItem>
			</div>
		</div>
		<div class="debuff-container">
			<div v-for="item in getDebuffs" :key="item.id">
				<BuffsAndDebuffsItem v-show="item.id" :item="item" :duration="item.initialDuration" :applied-at-time="item.appliedAtTime" :status-effect-type="'debuffs'" :expires-at-time="item.expiresAtTime" :stacks="item.stacks" :tooltip="item.effect"></BuffsAndDebuffsItem>
			</div>
		</div>
	</div>
</template>

<script lang="js">
import { highResolutionTimestamp } from '../../utils/debug'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import BuffsAndDebuffsItem from '../reusable-components/inventory-item/buff-debuff-item.vue'
import {getBuffHeightAndScaling} from '../state/ui-scale.ui-state'

export default {
	name: 'BuffsAndDebuffs',
	components:{
		BuffsAndDebuffsItem,
	},
	data() {
		return {
			buff: [{"name":"Tumble Roll","effect":"Tumbling lets you shoot faster.","iconName":"short-range-tumble-roll","iconBackgroundName":"buff","appliedAtTime":1626916062710,"expiresAtTime":1626916063460,"stacks":1,"id":"8b59a60c-aa25-4179-b8ea-1e241d03f9e1"}]
		}
	},
	computed:{
		...mapGetters('buffAndDebuff', ['getBuffs', 'getDebuffs']),
		...mapGetters('UIScale', ['currentScale']),
	},
	methods: {
	},
	mounted() {
	}
}
</script>

<style lang="less" scoped>
#buffs-and-debuffs {
	position: absolute;
	left: 29px;
	top: 154px;
	display: flex;
	flex-direction: column;
	pointer-events: all;
	transform-origin: left;
	button {
		width: 40px;
		height: 40px;
	}

	.buff-container,
	.debuff-container {
		margin: 0px;
		display: flex;
		height: 96px;
	}

	.buff-container,
	.debuff-container {
		.progressbar-container {
			width: 62px;
			height: 13px;
			position: relative;
			margin-left: auto;
			margin-right: auto;
			top: -3px;
			.progressbar-background {
				position: absolute;
				top: 0;
				left: 0;
				background-color: #0a1f4c;
				width: 62px;
				height: 13px;
			}
			.progressbar-buff {
				position: absolute;
				top: 0;
				left: 0;
				background-color: #26ffce;
				height: 13px;
			}
			.progressbar-debuff {
				position: absolute;
				top: 0;
				left: 0;
				background-color: #a126ff;
				height: 13px;
			}
		}
	}
	.debuff-container {
	}
}
</style>
