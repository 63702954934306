<template>
	<div id="boss-ui" :class="{ visible: poiActive, hidden: !poiActive }">
		<div v-if="bossActive" class="boss-health-container">
			<div class="boss-text">{{ poiText }}</div>
			<div v-show="bossActive" class="boss-healthbar">
				<div class="embellishment left"></div>
				<div class="healthbar-background"></div>
				<div class="healthbar">
					<div class="fill" :style="{ width: `${bossHealth}%` }"></div>
				</div>
				<div class="embellishment right"></div>
			</div>
		</div>
		<div v-else-if="poiRespawn && poiRespawnTimer && poiRespawnTimer !== '0'" class="boss-respawn-container">
			<div class="boss-text">{{ poiRespawnText }}</div>
			<div class="boss-timer">{{ poiRespawnTimer }}</div>
		</div>
		<div v-else>
			<div class="boss-text">{{ poiText }}</div>
		</div>
	</div>
</template>

<script lang="js">
import { mapGetters } from 'vuex'

export default {
    name: 'Boss',
    computed:{
        ...mapGetters('hud', ['poiRespawnTimer', 'poiActive', 'poiEventActive', 'poiText', 'poiRespawnText', 'bossHealth', 'bossActive', 'poiRespawn'])
    }
}
</script>

<style lang="less" scoped>
@import url('../global/fonts/text.less');
#boss-ui {
	position: absolute;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	top:20% !important;
	display: flex;
	justify-content: center;
	width: 500px;
	background-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 20%, rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 0.7) 80%, rgba(0, 0, 0, 0) 100%);
	&.hidden {
		transition: height 300ms ease, opacity 300ms ease;
		opacity: 0;
	}
	&.visible {
		transition: height 300ms ease, opacity 300ms ease;
		opacity: 1;
	}
	.boss-text {
		flex-wrap: nowrap;
		.boss-ui-text();
	}
	.boss-respawn-container {
		display: flex;
		flex-direction: column;
		text-align: center;
		padding: 15px 0px;
		margin: 0px 0px;
		.boss-text {
			flex-wrap: nowrap;
			.boss-ui-text();
		}
		.boss-timer {
			flex-wrap: nowrap;
			.boss-ui-timer();
		}
	}
	.boss-health-container {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		position: relative;
		padding: 2px 0px 5px 0px;
		.boss-text {
			.boss-ui-text();
			text-align: center;
			padding-bottom: 2px;
		}
		.boss-healthbar {
			flex-basis: 100%;
			position: relative;
			display: flex;
			justify-content: center;
			.healthbar {
				width: 350px;
				height: 20px;
				background-color: black;
				.fill {
					position: relative;
					background-color: red;
					height: 20px;
				}
			}

			.embellishment {
				background-image: url('./assets/boss-health-bar-embellishment.png');
				width: 26px;
				height: 27px;
				position: relative;
				z-index: 1;
				&.left {
					position: relative;
					right: -11px;
					top: -4px;
					transform: scale(-1, 1);
				}
				&.right {
					position: relative;
					left: -11px;
					top: -4px;
					transform: scale(1, 1);
				}
			}
		}
	}
}
</style>
