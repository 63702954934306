




























export default {
	name: 'WorldTierCompleteList',
	props: {},
	data() {
		return {
			isleBeachBiome: true,
			fungiSteppesBiome: true,
			terilaForestBiome: true,
			onyxHighlandsBiome: true,
			prismSunsetCliffsBiome: true,
		}
	},
}
