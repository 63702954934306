import nengi from 'nengi'

class PitStatsMessage {
	static protocol = {
        itemsWithCoins: nengi.Number,
	}

    itemsWithCoins: number

	constructor(itemsWithCoins: number) {
        this.itemsWithCoins = itemsWithCoins
    }
}

export default PitStatsMessage
