export function updateEntityHealthVisuals(entity: { healthbar }, currentHealth, maxHealth, alwaysShow) {
	if (!entity.healthbar) {
		return
	}

	const percentageOfMaxHealth = Math.max(0, currentHealth / maxHealth)

	if (percentageOfMaxHealth >= 1 && !alwaysShow) {
		entity.healthbar.getContainer().visible = false
		return
	} else {
		entity.healthbar.getContainer().visible = true
	}

	entity.healthbar.setFillPercent(percentageOfMaxHealth)
}
