













































































import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import ArrowButton from '../reusable-components/buttons/arrow-button.vue'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import PlayerPortrait from '../reusable-components/player-profile/player-portrait.vue'
import TableData from '../reusable-components/detailed-item-view/table-data.vue'
import { PRESSURE_MENU_UNLOCK_TIERS_DEFEATED } from '../../engine/shared/game-data/pressure-stat-info.shared'
import { getWorldDifficultyPrettyString } from '../../engine/shared/game-data/world-difficulty'
import FancySkinPreview from '../reusable-components/fancy-skin-preview/fancy-skin-preview.vue'

export default {
	name: 'LoggedIn',
	components: {
		MainPanel,
		ArrowButton,
		PlayerPortrait,
		TableData,
		FancySkinPreview,
	},
	mounted() {
		this.checkIsBrave()
	},
	data() {
		return {
			nodeEnv: process.env.NODE_ENV
		}
	},
	computed: {
		...mapGetters('mainMenu', ['loadingCurrentlyInProgress', 'loadedPercentage', 'isJoiningParty']),
		...mapGetters('user', ['metrics']),
		...mapGetters('cosmetics', ['mainSkin']),
		...mapState('mainMenu', ['isChrome']),
		hardCodedTableData() {
			const metrics = this.metrics
			const worldTierCompleted = metrics.maxWorldTierCompleted

			let progressionString
			let progressionValue
			if (!worldTierCompleted) {
				progressionString = 'Highest world difficulty cleared:'
			} else if (worldTierCompleted <= PRESSURE_MENU_UNLOCK_TIERS_DEFEATED) {
				progressionString = 'Highest world difficulty cleared:'
				progressionValue = getWorldDifficultyPrettyString(worldTierCompleted)
			} else {
				progressionString = 'Highest Soul Cycle cleared:'
				progressionValue = worldTierCompleted - PRESSURE_MENU_UNLOCK_TIERS_DEFEATED
			}

			if (!progressionValue) {
				progressionValue = 'None'
			}

			return [
				{ metricName: 'Items Sent to Worm:', value: metrics.sumOfWormItemsDeposited || 0 },
				{ metricName: 'Enemies Killed:', value: metrics.sumOfEnemiesKilled || 0 },
				{ metricName: 'Bosses Killed:', value: metrics.sumOfBossesKilled || 0 },
				{ metricName: progressionString, value: progressionValue },
				{ metricName: 'Seconds Adventuring:', value: metrics.totalRunsDurationInSeconds ? Math.floor(metrics.totalRunsDurationInSeconds) : 0 },
			]
		},
		getPanelHeight() {
			if (this.isChrome) {
				return 780
			} else {
				return 825
			}
		},
		startGameText() {
			if(this.isJoiningParty) {
				return 'Join Party!'
			} else {
				return 'Start Your Adventure!'
			}
		}
	},
	methods: {
		...mapActions('loginQueue', ['enterQueue']),
		...mapActions('user', ['logout']),
		...mapActions('mainMenu', ['checkIsBrave']),
		...mapMutations(['enterGame']),
	},
}
