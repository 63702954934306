import nengi from 'nengi'

export default class LogEntityMessage {
	static protocol = {
		entity: nengi.String,
		nid: nengi.UInt16,
		history: nengi.String,
	}

	entity: string
	nid: number
	history: string

	constructor(nid: number, entity: string, history: string) {
		this.entity = entity
		this.nid = nid
		this.history = history
	}
}
