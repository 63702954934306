import nengi from 'nengi'
import { uuid } from '../../utils/primitive-types'
import { BuffIdentifier } from './buff.shared'

type BuffUpdates = 'create' | 'remove' | 'update'

export class BuffUpdateMessage {
	static protocol = {
		updateType: nengi.String,
		buffIdentifier: nengi.String,
		id: nengi.String,
		appliedAtTime: nengi.String,
		expiresAtTime: nengi.String,
		stacks: nengi.UInt10,
	}

	updateType: BuffUpdates
	buffIdentifier: BuffIdentifier | ''
	id: uuid
	appliedAtTime: string
	expiresAtTime: string
	stacks: number

	constructor(params: BuffUpdateMessage) {
		Object.assign(this, params)
	}
}
