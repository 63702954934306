import { forgotPasswordRequest, resetPasswordRequest } from '../../utils/api/atlas-requests.client'
import logger from '../../utils/client-logger'

export const mainMenuModule = () => {
	logger.debug('Initializing Main Menu store module:')

	let startingMenuScreen = 'login'
	let forgotPasswordToken = ''
	let joinPartyId = null
	let isJoiningParty = false
	const searchParams = new URLSearchParams(window.location.href.split('?')[1])
	if (searchParams.has('verify-email')) {
		startingMenuScreen = 'verify-email'
	} else if (searchParams.has('reset-password')) {
		startingMenuScreen = 'resetPassword'
		forgotPasswordToken = searchParams.get('forgotPasswordToken')
	} else if (searchParams.has('partyId')) {
		joinPartyId = searchParams.get('partyId')
		isJoiningParty = true
	}
	
	// is this browser chrome?
	// https://stackoverflow.com/questions/4565112/javascript-how-to-find-out-if-the-user-browser-is-chrome/13348618#13348618
	// this seems to change every month

	let isActuallyChrome = false
	//@ts-expect-error
	const isChromium = window.chrome;
	const winNav = window.navigator;
	const vendorName = winNav.vendor;
	//@ts-expect-error
	const isOpera = typeof window.opr !== "undefined";
	const isIEedge = winNav.userAgent.indexOf("Edg") > -1;
	const isIOSChrome = winNav.userAgent.match("CriOS");


	if (isIOSChrome) {
		// Google Chrome on IOS
		isActuallyChrome = true
	} else if (
		isChromium !== null &&
		typeof isChromium !== "undefined" &&
		vendorName === "Google Inc." &&
		isOpera === false &&
		isIEedge === false
	) {
		isActuallyChrome = true
	}

	return {
		namespaced: true,
		state: {
			loadedPercentage: 0,
			mainMenuScreen: startingMenuScreen,
			showStartupError: false,
			startUpError: '',
			forgotPasswordToken,

			sendingReset: false,
			sentResetPassword: false,

			sendingSetPassword: false,
			passwordWasReset: false,

			joinPartyId: joinPartyId,
			isJoiningParty: isJoiningParty,

			forgotPasswordError: '',
			resetPasswordError: '',
			isChrome: isActuallyChrome,
		},
		getters: {
			loadedPercentage(state: any): number {
				return state.loadedPercentage
			},
			currentMainMenuScreen(state: any): string {
				return state.mainMenuScreen
			},
			loadingCurrentlyInProgress(state: any): boolean {
				return state.loadedPercentage !== 100
			},
			isJoiningParty(state): boolean {
				return state.isJoiningParty
			},
			joiningPartyId(state): string {
				return state.joinPartyId
			}
		},
		mutations: {
			loadingProgressPercentageUpdated(state: any, newPercentage: number) {
				state.loadedPercentage = newPercentage
			},
			changeMainMenuScreen(state, newScreen) {
				state.mainMenuScreen = newScreen
			},
			showStartupError(state, error) {
				state.startUpError = error
				state.showStartupError = true
			},
			resetPasswordBools(state) {
				state.sendingReset = false
				state.sentResetPassword = false
				state.sendingSetPassword = false
				state.passwordWasReset = false

				state.forgotPasswordError = ''
				state.resetPasswordError = ''
			},
			setNotJoiningParty(state) {
				state.isJoiningParty = false
				state.joinPartyId = null
			}
		},
		actions: {
			async sendForgotPassword({ state }, emailAddress) {
				try {
					state.forgotPasswordError = ''
					state.sendingReset = true
					const sendResult = await forgotPasswordRequest({ emailAddress, isSoti: true })
					state.sentResetPassword = true
				} catch (err) {
					if (err.isAxiosError && err.response.status === 400) {
						switch (err.response.data.errorCode) {
							case 'missingRequiredFields':
								state.forgotPasswordError = 'You must enter an e-mail address.'
								break
							case 'invalidEmailFormat':
								state.forgotPasswordError = 'E-mail address is not in correct format.'
								break
							case 'noMatchingUserFoundForProvidedEmailAddress':
								state.forgotPasswordError = 'There is no account matching that e-mail address.'
								break
							default:
								state.forgotPasswordError = 'Unknown validation error: check your e-mail address is correct.'
						}
					} else {
						state.forgotPasswordError = 'Unknown error: try again later or contact support.'
						console.error(err)
					}
				}

				state.sendingReset = false
			},
			async sendResetPassword({ state }, { newPassword, newPasswordConfirmation }) {
				const token = state.forgotPasswordToken
				try {
					state.sendingSetPassword = true
					const sendResult = await resetPasswordRequest({ newPassword, newPasswordConfirmation, forgotPasswordToken: token })
					state.passwordWasReset = true
				} catch (err) {
					if (err.isAxiosError && err.response.status === 400) {
						switch (err.response.data.errorCode) {
							case 'missingRequiredFields':
								state.resetPasswordError = 'Missing required fields.'
								break
							case 'invalidPasswordLength':
								state.resetPasswordError = 'Your password must be between 10 and 64 characters in length.'
								break
							case 'passwordAndPasswordConfirmationDontMatch':
								state.resetPasswordError = 'Passwords do not match.'
								break
							case 'noUserFoundWithThatToken':
								state.resetPasswordError = 'Your password reset token is invalid. Try sending a new reset e-mail.'
								break
							default:
								state.resetPasswordError = 'Unknown validation error: check your inputs or try sending a new reset e-mail.'
						}
					} else {
						state.resetPasswordError = 'Unknown error: try again later or contact support.'
						console.error(err)
					}
				}

				state.sendingSetPassword = false
			},
			async checkIsBrave({ state }) {
				//@ts-expect-error
				const isBrave = (winNav.brave && await winNav.brave.isBrave() || false)

				if (state.isChrome && isBrave) {
					state.isChrome = false
				}
			}
		},
	}
}
