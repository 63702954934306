import * as PIXI from 'pixi.js'
import { RenderQueue, RenderQueueElementType } from '../../engine/client/graphics/render-queue'
import { InstancedSpriteRenderable } from '../../engine/client/graphics/pfx/instanced-sprite-batcher'

export class InstancedSprite {
	renderable: InstancedSpriteRenderable

	get zIndex() {
		return this.z
	}

	set zIndex(newZ) {
		this.z = newZ + this.offset
	}

	get zOffset() {
		return this.offset
	}

	set zOffset(newOffset) {
		this.offset = newOffset
		this.z = this.bottom + this.offset
	}

	get bottom() {
		return this.y + 0.5 * this.h
	}

	get x() {
		return this.position.x
	}

	set x(newX) {
		this.position.x = newX
		this.renderable.pos[0] = newX
	}

	get y() {
		return this.position.y
	}

	set y(newY) {
		this.position.y = newY
		this.renderable.pos[1] = newY
	}

	get pos() {
		return this.position
	}

	get scale() {
		return this.spriteScale
	}

	set scale(newScale) {
		this.spriteScale = newScale
		this.w = this.texWidth * newScale
		this.h = this.texHeight * newScale
		this.renderable.scale = [this.w, this.h]
	}

	get rot() {
		return this.rotation
	}

	set rot(newRot) {
		this.rotation = newRot
		this.renderable.rot = newRot
	}

	get width() {
		return this.w
	}

	get height() {
		return this.h
	}

	debugName: string
	private z: number = 0
	private offset: number = 0
	private position: PIXI.Point = new PIXI.Point(0, 0)
	private spriteScale: number = 1
	private rotation: number = 0
	private w: number = 0
	private h: number = 0
	private texWidth: number = 0
	private texHeight: number = 0

	constructor(tex: PIXI.Texture, x: number, y: number, zIndex: number, scaleX: number = 1, scaleY: number = 1, rot: number = 0, zOffset: number = 0, color: number[] = [1, 1, 1, 1], blendMode: PIXI.BLEND_MODES = PIXI.BLEND_MODES.NORMAL, debugName = '') {
		this.position.set(x, y)
		this.debugName = debugName
		this.z = zIndex
		this.offset = zOffset
		this.spriteScale = scaleX
		this.rotation = rot
		this.texWidth = tex.width
		this.texHeight = tex.height
		this.w = tex.width * scaleX
		this.h = tex.height * scaleY
		this.offset = this.z - (this.y + 0.5 * this.h)

		// @ts-expect-error protected member access in pixi, wontfix
		const uvs = tex._uvs.uvsFloat32
		const uvExtents = [uvs[0], uvs[1], uvs[2] - uvs[0], uvs[5] - uvs[1]]

		this.renderable = {
			blendMode,
			color,
			pos: [x, y],
			rot,
			scale: [this.w, this.h],
			texture: tex.baseTexture,
			uvExtents,
		}
	}

	update(dt: number) {}

	render(renderQueue: RenderQueue) {
		renderQueue.addElement(this.renderable, RenderQueueElementType.InstancedSprite, this.zIndex)
	}
}

export class InstancedProjectile extends InstancedSprite {
	get color() {
		return this.colour
	}

	set color(newColor: number[]) {
		this.colour = newColor
		this.renderable.color = newColor
	}
	added: boolean = false
	private colour: number[] // Hacky name until I come up with better one

	constructor(tex: PIXI.Texture, x: number, y: number, zIndex: number, scale: number, rot: number, zOffset: number = 0, color: number[] = [1, 1, 1, 1]) {
		super(tex, x, y, zIndex, scale, rot, zOffset)
		this.colour = color
		this.renderable.color = color
	}

	setAlpha(alpha: number): void {
		this.colour[3] = alpha
		this.renderable.color[3] = alpha
	}
}
