import nengi from 'nengi'

class ItemSlotNotificationMessage {
	static protocol = {
		toggleOutro: { type: nengi.Boolean },
		disableSlotAnimation: { type: nengi.Boolean },
		enableSlotAnimation: { type: nengi.Boolean },
	}

	toggleOutro: boolean
	disableSlotAnimation: boolean
	enableSlotAnimation: boolean

	constructor(toggleOutro, disableSlotAnimation, enableSlotAnimation) {
		this.toggleOutro = toggleOutro
		this.disableSlotAnimation = disableSlotAnimation
		this.enableSlotAnimation = enableSlotAnimation
	}
}

export default ItemSlotNotificationMessage
