




export default {
	name: 'EmptyAugmentSlot',
	props: {
		rarity: {
			type: String,
			required: true,
		},
	},
}
