import axios from 'axios'
import { debugConfig } from '../../engine/client/debug-config'
import { FactionShortName } from '../../factions/shared/faction-data'
import { PlayerSettings } from '../../player/shared/player-settings'
import { nonSensitiveEnvVarsMapping } from '../env'

export const createAnonymousUserRequest = async () => {
	const apiResults = await axios.post(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].ATLAS_API_ADDRESS}/soti/register`, {
		accountFormPayload: {
			sourceGame: 'loot.io',
			isAnonymous: true,
			agreedToTerms: true
		}
	})
	return apiResults.data
}

export const anonymousUserDetailsRequest = async (anonymousUserId) => {
	if (debugConfig.offline) {
		return { id: '000-000', username: 'Fargus "offline" MacGee' }
	}

	const apiResults = await axios.get(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].ATLAS_API_ADDRESS}/soti/anonymous-user?includePreferences=true&includeWallet=true&includeTierProgression=true&includeTutorialFlags=true&includeUserBuffs=true&includeSkinOwnership=true&includeEquippedSkin=true&includeDailyLogin=true&includeStashIncreases=true&includeAdventureCoins=true&includeActiveGameWindow=true&includeNotifications=true&includeFreeMarketplaceUse=true&includeFaction=true&includeEquippedEmotes=true`, {
		headers: {
			'anonymous-user-id': anonymousUserId,
		},
	})
	return apiResults.data
}

export const meRequest = async (authToken) => {
	const apiResults = await axios.get(
		`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].ATLAS_API_ADDRESS}/soti/me?includePreferences=true&includeAuthToken=true&includeWallet=true&includeTierProgression=true&includeTutorialFlags=true&includeUserBuffs=true&includeSkinOwnership=true&includeEquippedSkin=true&includeDailyLogin=true&includeStashIncreases=true&includeAdventureCoins=true&includeActiveGameWindow=true&includeNotifications=true&includeFreeMarketplaceUse=true&includeFaction=true&includeEquippedEmotes=true`,
		{
			headers: { 'auth-token': authToken },
		},
	)
	return apiResults.data
}

export const loginRequest = async (loginBody) => {
	const apiResults = await axios.post(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].ATLAS_API_ADDRESS}/soti/login`, loginBody)
	return apiResults.data
}

export const heartbeatRequest = async (authToken) => {
	const apiResults = await axios.put(
		`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].ATLAS_API_ADDRESS}/shared/heartbeat`,
		{},
		{
			headers: { 'auth-token': authToken },
		},
	)
	return apiResults.data
}

export const registerRequest = async (registerBody) => {
	const apiResults = await axios.post(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].ATLAS_API_ADDRESS}/soti/register`, registerBody)
	return apiResults.data
}

export const updateUsernameRequest = async (authToken, newUsernameBody) => {
	const apiResults = await axios.put(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].ATLAS_API_ADDRESS}/shared/change-username`, newUsernameBody, {
		headers: {
			'auth-token': authToken,
		},
	})
	return apiResults.data
}

export const getFriendsListRequest = async (authToken) => {
	const apiResults = await axios.get(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].ATLAS_API_ADDRESS}/shared/friends-list`, {
		headers: {
			'auth-token': authToken,
		},
	})
	return apiResults.data
}

export const searchUsersByUsernameRequest = async (authToken, username) => {
	const apiResults = await axios.post(
		`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].ATLAS_API_ADDRESS}/shared/search-users-by-username`,
		{ username },
		{
			headers: {
				'auth-token': authToken,
			},
		},
	)
	return apiResults.data
}

export const addFriendRequest = async (authToken, receiverId) => {
	const apiResults = await axios.post(
		`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].ATLAS_API_ADDRESS}/shared/add-friend`,
		{ receiverId },
		{
			headers: {
				'auth-token': authToken,
			},
		},
	)
	return apiResults.data
}

export const acceptFriendRequest = async (authToken, requesterId) => {
	const apiResults = await axios.post(
		`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].ATLAS_API_ADDRESS}/shared/accept-friend`,
		{ requesterId },
		{
			headers: {
				'auth-token': authToken,
			},
		},
	)
	return apiResults.data
}
export const cancelOutgoingFriendRequest = async (authToken, receiverId) => {
	const apiResults = await axios.post(
		`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].ATLAS_API_ADDRESS}/shared/cancel-outgoing-friend-request`,
		{ receiverId },
		{
			headers: {
				'auth-token': authToken,
			},
		},
	)
	return apiResults.data
}
export const rejectIncomingFriendRequest = async (authToken, requesterId) => {
	const apiResults = await axios.post(
		`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].ATLAS_API_ADDRESS}/shared/reject-incoming-friend-request`,
		{ requesterId },
		{
			headers: {
				'auth-token': authToken,
			},
		},
	)
	return apiResults.data
}

export const verifyEmailRequest = async (verificationBody) => {
	const apiResults = await axios.post(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].ATLAS_API_ADDRESS}/shared/verify-email`, verificationBody)
	return apiResults.data
}

export const resetPasswordRequest = async (resetPasswordBody) => {
	const apiResults = await axios.post(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].ATLAS_API_ADDRESS}/shared/reset-password`, resetPasswordBody)
	return apiResults.data
}

export const forgotPasswordRequest = async (forgotPasswordBody) => {
	const apiResults = await axios.post(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].ATLAS_API_ADDRESS}/shared/forgot-password`, forgotPasswordBody)
	return apiResults.data
}

export const updatePasswordRequest = async (authToken, newPasswordBody) => {
	const apiResults = await axios.put(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].ATLAS_API_ADDRESS}/shared/change-password`, newPasswordBody, {
		headers: {
			'auth-token': authToken,
		},
	})
	return apiResults.data
}

export const getTop50UsersByMetricRequest = async (metric?: string, timeSlice?: string) => {
	metric = metric || 'enemies-killed'
	timeSlice = timeSlice || 'all-time'
	const apiResults = await axios.get(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].ATLAS_API_ADDRESS}/soti/top-50-by-metric?metric=${metric}&timeSlice=${timeSlice}`)
	return apiResults.data
}

export const getXsollaTokenForItemSKURequest = async (authToken, tokenRequestBody) => {
	const apiResults = await axios.post(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].ATLAS_API_ADDRESS}/soti/xsolla/token-for-item-sku`, tokenRequestBody, {
		headers: {
			'auth-token': authToken,
		},
	})
	return apiResults.data
}

export const getXsollaTokenForSubscriptionSKURequest = async (authToken, tokenRequestBody) => {
	const apiResults = await axios.post(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].ATLAS_API_ADDRESS}/soti/xsolla/token-for-subscription-sku`, tokenRequestBody, {
		headers: {
			'auth-token': authToken,
		},
	})
	return apiResults.data
}

export const getUserMetricsRequest = async (authToken, timeSlice) => {
	const apiResults = await axios.get(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].ATLAS_API_ADDRESS}/soti/user-metrics?timeSlice=${timeSlice}`, {
		headers: { 'auth-token': authToken },
	})
	return apiResults.data
}

export const getAnonymousUserMetricsRequest = async (userId, timeSlice) => {
	const apiResults = await axios.get(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].ATLAS_API_ADDRESS}/soti/user-metrics?timeSlice=${timeSlice}`, {
		headers: { 'anonymous-user-id': userId },
	})
	return apiResults.data
}

export const getMtxRequest = async (authToken) => {
	const apiResults = await axios.get(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].ATLAS_API_ADDRESS}/soti/mtx`, {
		headers: { 'auth-token': authToken },
	})
	return apiResults.data
}

export const getAnonymousMtxOffersRequest = async (userId) => {
	const apiResults = await axios.get(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].ATLAS_API_ADDRESS}/soti/mtx-offers`, {
		headers: { 'anonymous-user-id': userId },
	})
	return apiResults.data
}

export const getMtxOffersRequest = async (authToken) => {
	const apiResults = await axios.get(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].ATLAS_API_ADDRESS}/soti/mtx-offers`, {
		headers: {'auth-token': authToken },
	})
	return apiResults.data
}

export const setSkinsRequest = async (skinSlot, skinIdentifier, authToken) => {
	const apiResults = await axios.post(
		`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].ATLAS_API_ADDRESS}/soti/post-set-user-skins`,
		{ skinIdentifier, skinSlot },
		{
			headers: {
				'auth-token': authToken,
			},
		},
	)
	return apiResults.data
}

export const anonymousSetSkinsRequest = async (skinSlot, skinIdentifier, userId) => {
	const apiResults = await axios.post(
		`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].ATLAS_API_ADDRESS}/soti/post-set-user-skins`,
		{ skinIdentifier, skinSlot },
		{
			headers: {
				'anonymous-user-id': userId,
			},
		},
	)
	return apiResults.data
}

export const clearNotificationsRequest = async (authToken) => {
	const apiResults = await axios.post(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].ATLAS_API_ADDRESS}/soti/clear-notifications`, {}, {
		headers: {
			'auth-token': authToken,
		},
	})
	return apiResults.data
}

export async function updateSettingsRequest(authToken, settings: PlayerSettings) {
	const apiResults = await axios.post(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].ATLAS_API_ADDRESS}/soti/update-user-settings`,
		settings,
		{
			headers: {
				'auth-token': authToken,
			},
		})
	return apiResults.data
}

export async function updateAnonymousSettingsRequest(userId, settings: PlayerSettings) {
	const apiResults = await axios.post(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].ATLAS_API_ADDRESS}/soti/update-user-settings`,
		settings,
		{
			headers: {
				'anonymous-user-id': userId,
			},
		})
	return apiResults.data
}

export const maintenanceModeStatusRequest = async () => {
	const apiResults = await axios.get(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].ATLAS_API_ADDRESS}/soti/maintenance-mode-status`)
	return apiResults.data
}

export async function getFactionMemberCount(faction: FactionShortName) {
	const apiResults = await axios.get(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].ATLAS_API_ADDRESS}/soti/get-faction-member-count?faction=${faction}`)
	return apiResults.data
}

export async function getAllFactionMembersCounts() {
	const apiResults = await axios.get(`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].ATLAS_API_ADDRESS}/soti/get-all-factions-member-counts`)
	return apiResults.data
}

export const setEquippedEmotesRequest = async (equippedEmotes: number[], authToken) => {
	const apiResults = await axios.post(
		`${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].ATLAS_API_ADDRESS}/soti/post-set-equipped-emotes`,
		{ equippedEmotes },
		{
			headers: {
				'auth-token': authToken,
			},
		},
	)
	return apiResults.data
}