<template>
	<div id="map">
		<div class="map-textbox-1 textbox">
			<div>Items in LOOT.io are unique NFTS – you loot it, you own it.</div>
		</div>
		<div class="map-textbox-2 textbox">
			<div>Every account has an associated wallet. What you do with your wallet is up to you. You are not required to spend any money to play the game.</div>
		</div>
		<div class="map-textbox-3 textbox">
			<div>LOOT has two currencies. Coins are found in-game and are used to boost your character. Prism Scales can be purchased from Forte Exchange for use in the marketplace and cosmetics shop.</div>
		</div>
		<div class="map-textbox-4 textbox">
			<div>
				Your account comes with a 'custodial wallet'. If you decide to purchase Prism Scales, it will be converted to a 'player wallet'.
			</div>
		</div>
		<div class="map-textbox-5 textbox">
			<div>
				By Listing valuable loot on the marketplace, you can acquire Prism Scales without spending any money. In this way, you can trade NFTS for free.
			</div>
		</div>
		<div class="map-textbox-6 textbox">
			<div>
				LOOT makes money by collecting small fees on marketplace transactions. Our goal is to provide an exciting, evolving world with a meaningful and vibrant economy.
			</div>
		</div>
		<div class="wisp-faq-gfx" @click="openFAQPanel">
			<div class="text-container">
				<div>More questions?</div>

				<div>Check our <span>FAQ!</span></div>
			</div>
		</div>
		<div class="close-btn-container" @click="closeMap">
			<div class="close-btn"></div>
		</div>
	</div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'

export default {
	name: 'NFTMap',
	props: {},
	computed: {
		...mapGetters(['currentUIMode']),
		...mapGetters('inGame', ['activePanel']),
	},
	methods: {
		...mapMutations('inGame', ['setActivePanel', 'updateShowNFTMap', 'currentGameMode']),
		...mapMutations('mainMenu', ['changeMainMenuScreen']),

		closeMap() {
			if (this.currentUIMode === 'main-menu') {
				this.changeMainMenuScreen('login');
			} else {
				this.updateShowNFTMap(false);

				if (this.activePanel === 'premiumStore') return;
				
				this.setActivePanel('premiumStore');
			}
		},
		openFAQPanel() {
			if (this.currentUIMode === 'in-game') {
				this.updateShowNFTMap(false);
			}
			this.setActivePanel('faq');
		},
	},
}
</script>

<style lang="less" scoped>
@import '../global/fonts/text.less';
@import url('../global/bg-gradients.less');

#map {
	width: 922px;
	height: 949px;
	background-image: url('../reusable-components/assets/NFT-guide-map.png');
	position: relative;
	pointer-events: all;

	.map-textbox-1,
	.map-textbox-2,
	.map-textbox-3,
	.map-textbox-4,
	.map-textbox-5,
	.map-textbox-6 {
		&.textbox {
			position: absolute;
			.nft-map-text();
			text-align: center;
		}
	}

	.map-textbox-1 {
		width: 223px;
		height: 74px;
		top: 171px;
		left: 367px;
	}
	.map-textbox-2 {
		width: 274px;
		height: 92px;
		top: 287px;
		left: 37px;
	}
	.map-textbox-3 {
		width: 321px;
		height: 106px;
		top: 374px;
		left: 604px;
	}
	.map-textbox-4 {
		width: 286px;
		height: 86px;
		top: 543px;
		left: 105px;
	}
	.map-textbox-5 {
		width: 290px;
		height: 81px;
		top: 535px;
		left: 614px;
	}
	.map-textbox-6 {
		width: 376px;
		height: 94px;
		top: 693px;
		left: 290px;
	}
	.wisp-faq-gfx {
		position: absolute;
		background-image: url('../main-menu/assets/professor-wisp-thank-you.png');
		background-size: cover;
		width: 350px;
		height: 163px;
		bottom: 20px;
		right: -170px;
		.text-container {
			text-align: center;
			position: absolute;
			top: 50px;
			left: 210px;
			.nft-map-wisp-text();
			span {
				color: #d22c91;
			}
			&:hover {
				text-decoration: underline;
			}
			&:hover span {
				text-decoration: underline;
			}
		}
	}
	.close-btn-container {
		position: absolute;
		width: 91px;
		height: 87px;
		background-image: url('../reusable-components/assets/map-x-button.png');
		top: 40px;
		right: 45px;
		&:hover {
			background-image: url('../reusable-components/assets/map-x-button-hover.png');
		}
		&:active {
			background-image: url('../reusable-components/assets/map-x-button-pressed.png');
		}
	}
}
</style>
