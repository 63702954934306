import logger from '../../utils/client-logger'
import { UI } from '../ui'
import { NengiClient } from '../../engine/client/nengi-client'
import CreatePartyCommand from '../../party-play/shared/create-party-command'
import LeavePartyCommand from '../../party-play/shared/leave-party-command'
import JoinPartyCommand from '../../party-play/shared/join-party-command'
import PartyPromoteCommand from '../../party-play/shared/party-promote-command'
import PartyKickCommand from '../../party-play/shared/party-kick-command'
import PartyReadyCommand from '../../party-play/shared/party-ready-command'
import TriggerPartyDepartureCountdownCommand from '../../party-play/shared/trigger-party-departure-countdown-command'
import PartyInvitationCommand from '../../chat/shared/party-invitation-command'
import { tryShowTutorial } from './ftue-manager.state'
import { FTUESequence } from '../../ftue/shared/ftue.shared'
import { GameClient } from '../../engine/client/game-client'
import {PanelTabs} from './departure.ui-state'
import {getDepartureDescription} from './boat-launch.ui-state'
import { nonSensitiveEnvVarsMapping } from '../../utils/env'
import { gameModeConfig } from '../../engine/shared/game-mode-configs'
import { GameModeType } from '../../engine/shared/game-mode-type'

interface PartyUIState {
	playerId: string
	partyId: string
	error: string[]
	currentlyPartied: boolean
	partyMembers: PartyMembers[]
	hasAdventureServer: boolean
	readyToDepart: boolean
	deepLink: string
	failedToJoinDeepLink: boolean
	alreadyPartied: boolean
}

interface PartyMembers{
	dead: boolean
	discovered: boolean
	leader: boolean
	partyId: string
	playerId: string
	playerName: string
	ready: boolean
	status: string
}

export const partyUIState = () => {
	logger.debug('Initializing Party Module')
	return {
		namespaced: true,
		state: {
			playerId: '',
			partyId: '',
			error: [],
			currentlyPartied: false,
			partyMembers: [],
			hasAdventureServer: false,
			readyToDepart: false,
			failedToJoinDeepLink: false,
			alreadyPartied: false
		},
		getters: {
			getPartyMembers(state: PartyUIState) {
				return state.partyMembers
			},
			getPartyId(state: PartyUIState) {
				return state.partyId
			},
			getPartyDeepLinkURL(state: PartyUIState) {
				return nonSensitiveEnvVarsMapping[process.env.NODE_ENV].GAME_URL + `/?partyId=${state.partyId}`
			},
			getErrors(state: PartyUIState) {
				return state.error[0]
			},
			getCurrentlyPartiedStatus(state: PartyUIState) {
				return state.currentlyPartied
			},
			getAllPartyMembersReady(state: PartyUIState) {
				let result = true
				state.partyMembers.forEach((player) => {
					if (!(player as any).ready) {
						result = false
					}
				})
				return result
			},
			getHasAdventureServer(state: PartyUIState) {
				return state.hasAdventureServer
			},
			getFormattedErrorString(state: PartyUIState) {
				const formattedErrorString = UI.getInstance().store.getters['i18n/t'](state.error[0])
				return formattedErrorString
			},
			getLeaderStatus(state: PartyUIState) {
				const leaderStatus = state.partyMembers.filter(member => member.leader === true && member.playerId === state.playerId)
				if(leaderStatus.length > 0) {
					return true
				}
				return false
			},
			getReadyToDepart(state: PartyUIState) {
				return state.readyToDepart
			},
			failedToDeepLinkToParty(state: PartyUIState) {
				return state.failedToJoinDeepLink
			},
			failedDeepLinkBecauseAlreadyPartied(state: PartyUIState) {
				return state.alreadyPartied
			}
		},

		mutations: {
			updatePartyId(state: PartyUIState, id: string) {
				state.partyId = id
			},
			updateErrors(state: PartyUIState, error: string) {
				state.error.push(error)
			},
			updatePartyStatus(state: PartyUIState) {
				state.currentlyPartied = !state.currentlyPartied
			},
			resetErrors(state: PartyUIState) {
				state.error = []
			},
			removeAllMembers(state: PartyUIState) {
				state.partyMembers = []
			},
			clearHasAdventureServer(state: PartyUIState) {
				state.hasAdventureServer = false
			},
			setPlayerId(state: PartyUIState, id: string) {
				state.playerId = id
			},
			setReadyToDepart(state: PartyUIState, ready: boolean) {
				state.readyToDepart = ready

				if (!ready) {
					UI.getInstance().emitEvent('hud/hidePartyDepartureCountdown')
				}
			},
			setFailedToJoinDeepLink(state: PartyUIState, { failed, alreadyPartied }) {
				state.failedToJoinDeepLink = failed
				state.alreadyPartied = Boolean(alreadyPartied)
			},
			updateParty(state: PartyUIState, newState: { playerId: string; partyId: string; partyMembers, adventureServer: string }) {
				if (state.partyMembers.length !== newState.partyMembers.length) {
					UI.getInstance().emitEvent('party/setReadyToDepart', false)
				}

				state.playerId = newState.playerId
				state.partyId = newState.partyId
				state.currentlyPartied = true

				const myEntity = GameClient.getInstance().state?.myEntity
				if(myEntity) {
					myEntity.updateNameplateText(myEntity.name)
				}

				if (newState.adventureServer && newState.adventureServer.length > 0) {
					state.hasAdventureServer = true
				} else {
					state.hasAdventureServer = false
				}

				let showNewPartyMemberToast = false
				newState.partyMembers.forEach((playerString) => {
					const player = playerString as any

					let existingPlayer = null
					state.partyMembers.forEach((existingPlayerString) => {
						if ((existingPlayerString as any).playerId === player.playerId) {
							existingPlayer = existingPlayerString as any
						}
					})

					if (existingPlayer) {
						player.ready = existingPlayer.ready
						player.dead = existingPlayer.dead
						player.disconnected = existingPlayer.disconnected
					} else {
						if (!player.ready) {
							player.ready = false
						}
						if (!player.dead) {
							player.dead = false
						}
						if (!player.disconnected) {
							player.disconnected = false
						}

						if(player.status !== 'adventure' && gameModeConfig.type === GameModeType.Adventure) {
							showNewPartyMemberToast = true
						}
					}
					
					playerString = player
				})
				
				state.partyMembers = newState.partyMembers

				if(showNewPartyMemberToast) {
					UI.getInstance().emitEvent('toast/queueToast', 'a new party member is waiting for you in town!')
				}
			},
			updatePartyFlags(state: PartyUIState, partyMembers) {
				partyMembers.forEach((player) => {
					const playerId = player.playerId
					state.partyMembers.forEach((partyPlayerString) => {
						const partyPlayer = partyPlayerString as any
						if (partyPlayer.playerId === playerId) {
							partyPlayer.ready = player.ready
							partyPlayer.dead = player.dead
							partyPlayer.disconnected = player.disconnected
							partyPlayerString = partyPlayer
						}
					})
				})

				// This garbage is apparently necessary to propogate the changes to the UI
				const tempPartyMembers = state.partyMembers
				state.partyMembers = []
				state.partyMembers = tempPartyMembers
			},
			clearParty(state: PartyUIState) {
				state.error = []
				state.partyMembers = []
				state.currentlyPartied = false
				state.hasAdventureServer = false
			},
		},

		actions: {
			triggerDepartureCountdown({ state }) {
				const partyId = state.partyId
				const targetDifficulty = UI.getInstance().store.getters['boatLaunch/currentlySelectedWorldDifficulty']
				NengiClient.getInstance().sendCommand(new TriggerPartyDepartureCountdownCommand(partyId, targetDifficulty))
			},
			joinParty(context) {
				const { state, commit } = context
				commit('resetErrors')
				const startedSecondRun = UI.getInstance().store.getters['ftueManager/startedSecondRun']
				if (startedSecondRun) {
					const partyId = state.partyId
					NengiClient.getInstance().sendCommand(new JoinPartyCommand(partyId))
				} else {
					tryShowTutorial(FTUESequence.PartyPlayIntro)
				}
			},
			joinPartyDirect(context, partyId) {
				const { state, commit } = context
				const isAdventure = UI.getInstance().store.getters['inGame/currentGameMode'] === 'adventure'
				commit('resetErrors')
				const startedSecondRun = UI.getInstance().store.getters['ftueManager/startedSecondRun']
				if (startedSecondRun) {
					NengiClient.getInstance().sendCommand(new JoinPartyCommand(partyId))
					
					const activePanel = UI.getInstance().store.getters['inGame/activePanel']
					if (activePanel !== 'departure') {
						if(isAdventure){
							UI.getInstance().emitEvent('inGame/setActivePanel', 'party')
						} else{
							UI.getInstance().emitEvent('departure/updatedDepartureTab', PanelTabs.OVERVIEW)
							UI.getInstance().emitEvent('inGame/setActivePanel', 'departure')
						}
					}
				} else {
					tryShowTutorial(FTUESequence.PartyPlayIntro)
				}
			},
			createParty(context) {
				const { state, commit } = context
				commit('resetErrors')
				const startedSecondRun = UI.getInstance().store.getters['ftueManager/startedSecondRun']
				if (startedSecondRun) {
					NengiClient.getInstance().sendCommand(new CreatePartyCommand())
				} else {
					tryShowTutorial(FTUESequence.PartyPlayIntro)
				}
			},
			leaveParty(context) {
				const { state, commit, rootState } = context
				commit('resetErrors')
				commit('removeAllMembers')
				commit('clearHasAdventureServer')
				commit('updatePartyStatus')
				NengiClient.getInstance().sendCommand(new LeavePartyCommand(state.partyId))
				commit('updatePartyId', '')
				UI.getInstance().emitEvent('chat/setPartyTabActive', false)
				UI.getInstance().emitEvent('chat/clearPartyMessages')
				UI.getInstance().emitEvent('party/setReadyToDepart', false)
			},
			promoteSelectedUser(context, user) {
				const { state, commit, rootState } = context

				NengiClient.getInstance().sendCommand(new PartyPromoteCommand(state.partyId, user.playerId))
			},
			kickSelectedUser(context, user) {
				const { state, commit, rootState } = context

				NengiClient.getInstance().sendCommand(new PartyKickCommand(state.partyId, user.playerId))
			},
			setReady(context, progression) {
				const { state, commit, rootState } = context
				
				const description = getDepartureDescription(progression.canProgress)

				UI.getInstance().emitAsyncEvent('genericYesNo/showMenu', {
					title: 'Ready for adventure?',
					description: description,
					noButtonText: 'Not Ready',
					yesButtonText: 'Ready Up',
					yesCallback: async () => {
						NengiClient.getInstance().sendCommand(new PartyReadyCommand(true))
					}
				})
			},
			setUnready(context, user) {
				const { state, commit, rootState } = context

				NengiClient.getInstance().sendCommand(new PartyReadyCommand(false))
			},
			sendInvitation(context) {
				const { state, commit } = context

				NengiClient.getInstance().sendCommand(new PartyInvitationCommand(state.partyId))
			}
		},
	}
}
