import nengi from 'nengi'
import { debugConfig } from '../../engine/client/debug-config'

class PlayerInput {
	static protocol = {
		up: nengi.Boolean,
		down: nengi.Boolean,
		left: nengi.Boolean,
		right: nengi.Boolean,
		aux1: nengi.Boolean,
		aux2: nengi.Boolean,
		interact: nengi.Boolean,
		skill1: nengi.Boolean,
		skill2: nengi.Boolean,
		skill3: nengi.Boolean,
		skill4: nengi.Boolean,
		skill5: nengi.Boolean,
		mouseLeftDown: nengi.Boolean,
		mouseRightDown: nengi.Boolean,
		mouse3Down: nengi.Boolean,
		mouse4Down: nengi.Boolean,
		mouse5Down: nengi.Boolean,
		aimX: nengi.Float32,
		aimY: nengi.Float32,
		delta: nengi.Number, // TODO remove?
		frame: nengi.UInt32,
		time: nengi.Number,
		csp: nengi.Boolean
	}
	up: boolean
	down: boolean
	left: boolean
	right: boolean
	aux1: boolean
	aux2: boolean
	interact: boolean
	skill1: boolean
	skill2: boolean
	skill3: boolean
	skill4: boolean
	skill5: boolean
	mouseLeftDown: boolean
	mouseRightDown: boolean
	mouse3Down: boolean
	mouse4Down: boolean
	mouse5Down: boolean
	aimX: number
	aimY: number
	delta: number
	frame: number
	time: number
	csp: boolean

	constructor(up, down, left, right, aux1, aux2, interact, skill1, skill2, skill3, skill4, skill5, mouseLeftDown, mouseRightDown, mouse3Down, mouse4Down, mouse5Down: boolean, aimX: number, aimY: number, delta: number, frame: number, time: number, csp: boolean) {
		this.up = up
		this.down = down
		this.left = left
		this.right = right
		this.aux1 = aux1
		this.aux2 = aux2
		this.interact = interact
		this.skill1 = skill1
		this.skill2 = skill2
		this.skill3 = skill3
		this.skill4 = skill4
		this.skill5 = skill5
		this.mouseLeftDown = mouseLeftDown
		this.mouseRightDown = mouseRightDown
		this.mouse3Down = mouse3Down
		this.mouse4Down = mouse4Down
		this.mouse5Down = mouse5Down
		this.aimX = aimX
		this.aimY = aimY
		this.delta = delta
		this.frame = frame
		this.time = time
		if (process.env.NODE_ENV !== 'beta' && process.env.NODE_ENV !== 'loot-prod') {
			this.time = this.time * debugConfig.cheating.speedHack
		}
		this.csp = csp
	}
}

export default PlayerInput
