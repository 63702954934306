import { EnemyAI, FightingTriggers, IdleBehaviours, FightingBehaviours, LeashTriggers, LeashingBehaviours, AttackTypes, DeadBehaviours, ShotLeadPrecision, EnemyType, EnemyTargetingStyles } from '../../shared/ai-types'
import { SpineDataName, EnemyBipedSkinType } from '../../../models-animations/shared/spine-config'
import { EnemyDefaults } from './enemy-defaults'
import { ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { bossEnemyDamageConfig, BOSS_HEALTH_MULTIPLIER, BOSS_LOOT_DROP_QUANTITY, BOSS_LOOT_DROP_RARITY, ENEMY_DEFAULT_HEALTH } from '../../../engine/shared/game-data/enemy-formulas'
import { alwaysTrueCriteria, NormalDifficultyCriteria, HardDifficultyCriteria, BrutalDifficultyCriteria, playAnimationAbility } from './abilities/common-abilities'
import { AbilitySelectionStyles, CriteriaSatisfactionQuantity, CriteriaTarget, CriteriaType } from '../action-types'
import { BuffIdentifier } from '../../../buffs/shared/buff.shared'

import { AnimationTrack } from '../../../models-animations/shared/animation-track'
import { Vector } from 'sat'
import { ENEMY_NAMES } from './enemy-names'

const PHASE_1_2_HEALTH_BOUNDARY = 60
const PHASE_2_3_HEALTH_BOUNDARY = 30

import { 
	moveToPositionAbility, 
	explosiveShardsAbilityBrutal, 
	moveToPositionAbilityBrutal, 
	moveToRunePositionAbilityBrutal, 
	onyxShieldAbilityBrutal, 
	petrifyingWaveAbilityBrutal, 
	runeInvulnerabilityBrutal, 
	stoneRainAbilityBrutal,
	explosiveShardsAbilityMedium, 
	moveToPositionAbilityMedium, 
	moveToRunePositionAbilityMedium, 
	onyxShieldAbilityMedium, 
	petrifyingWaveAbilityMedium, 
	runeInvulnerabilityMedium, 
	stoneRainAbilityMedium,
	explosiveShardsAbilityEasy, 
	moveToPositionAbilityEasy, 
	moveToRunePositionAbilityEasy, 
	onyxShieldAbilityEasy, 
	petrifyingWaveAbilityEasy, 
	runeInvulnerabilityEasy, 
	stoneRainAbilityEasy, 
	cooldownEnemyCooldown15000
			} from './abilities/highlands-boss-abilities'


const boss: EnemyAI = {
	actionDrivenEnemyData: {
		targetColliders: [
			{ name: '1oclock', colliders: [{ type: ColliderType.Circle, traits: ColliderTraits.Trigger, position: [277, -368], radius: 10 }] },
			{ name: '2oclockRune', colliders: [{ type: ColliderType.Circle, traits: ColliderTraits.Trigger, position: [757, -351], radius: 10 }] },
			{ name: '3oclock', colliders: [{ type: ColliderType.Circle, traits: ColliderTraits.Trigger, position: [588, 3], radius: 10 }] },
			{ name: '4oclockRune', colliders: [{ type: ColliderType.Circle, traits: ColliderTraits.Trigger, position: [943, 444], radius: 10 }] },
			{ name: '5oclock', colliders: [{ type: ColliderType.Circle, traits: ColliderTraits.Trigger, position: [339, 459], radius: 10 }] },
			{ name: '6oclockRune', colliders: [{ type: ColliderType.Circle, traits: ColliderTraits.Trigger, position: [-0, 951], radius: 10 }] },
			{ name: '7oclock', colliders: [{ type: ColliderType.Circle, traits: ColliderTraits.Trigger, position: [-381, 464], radius: 10 }] },
			{ name: '8oclockRune', colliders: [{ type: ColliderType.Circle, traits: ColliderTraits.Trigger, position: [-949, 416], radius: 10 }] },
			{ name: '9oclock', colliders: [{ type: ColliderType.Circle, traits: ColliderTraits.Trigger, position: [-652, -18], radius: 10 }] },
			{ name: '10oclockRune', colliders: [{ type: ColliderType.Circle, traits: ColliderTraits.Trigger, position: [-780, -337], radius: 10 }] },
			{ name: '11oclock', colliders: [{ type: ColliderType.Circle, traits: ColliderTraits.Trigger, position: [-267, -366], radius: 10 }] },
			{ name: '12oclockRune', colliders: [{ type: ColliderType.Circle, traits: ColliderTraits.Trigger, position: [-2, -652], radius: 10 }] },
		],

		engagementColliders: [{ type: ColliderType.Ellipse, traits: ColliderTraits.Trigger, position: [28, 83], rX: 2199, rY: 1627 }],
		resetOnDisengage: true,

		unengagedActions: [
			// wander around
			{
				priority: 0,
				actionCriteria: alwaysTrueCriteria,
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
					abilityOptions: [[1, moveToPositionAbility, 10]],
				},
			},
		],
		actions: [
			{ // Brutal Difficulty, all available
				priority: 21,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						BrutalDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
							criteriaValue: BuffIdentifier.EnemyCooldown15000,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[1, moveToPositionAbilityBrutal, 5],
						[2, playAnimationAbility(AnimationTrack.EXPLOSIVE_SHARDS, 10), explosiveShardsAbilityBrutal, 10],
						[1, playAnimationAbility(AnimationTrack.EXPLOSIVE_SHARDS, 10), explosiveShardsAbilityMedium, 5, stoneRainAbilityMedium, 10, explosiveShardsAbilityMedium, 5, 20],
						[3, playAnimationAbility(AnimationTrack.STONE_RAIN, 2), stoneRainAbilityBrutal, 20],
						[2, playAnimationAbility(AnimationTrack.ONYX_SHIELD, 5), onyxShieldAbilityBrutal, cooldownEnemyCooldown15000, 25],
						[1, moveToRunePositionAbilityBrutal, runeInvulnerabilityBrutal, 45, playAnimationAbility(AnimationTrack.PETRIFYING_WAVE, 10), petrifyingWaveAbilityBrutal, 5, stoneRainAbilityBrutal, 15, moveToPositionAbilityBrutal, 10],
						[1, moveToRunePositionAbilityBrutal, runeInvulnerabilityBrutal, 45, playAnimationAbility(AnimationTrack.PETRIFYING_WAVE, 10), petrifyingWaveAbilityBrutal, 15, moveToPositionAbilityBrutal, 10],
					],
				},
				
			},
			{ // Brutal Difficulty, No Shield Available
				priority: 22,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						BrutalDifficultyCriteria,
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[1, moveToPositionAbilityBrutal, 5],
						[2, playAnimationAbility(AnimationTrack.EXPLOSIVE_SHARDS, 10), explosiveShardsAbilityBrutal, 10],
						[1, playAnimationAbility(AnimationTrack.EXPLOSIVE_SHARDS, 10), explosiveShardsAbilityMedium, 5, stoneRainAbilityMedium, 10, explosiveShardsAbilityMedium, 5, 20],
						[3, playAnimationAbility(AnimationTrack.STONE_RAIN, 2), stoneRainAbilityBrutal, 20],
						[1, moveToRunePositionAbilityBrutal, runeInvulnerabilityBrutal, 45, playAnimationAbility(AnimationTrack.PETRIFYING_WAVE, 10), petrifyingWaveAbilityBrutal, 5, stoneRainAbilityBrutal, 15, moveToPositionAbilityBrutal, 10],
						[1, moveToRunePositionAbilityBrutal, runeInvulnerabilityBrutal, 45, playAnimationAbility(AnimationTrack.PETRIFYING_WAVE, 10), petrifyingWaveAbilityBrutal, 15, moveToPositionAbilityBrutal, 10],
					],
				},
				
			},
			{
				priority: 31,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						HardDifficultyCriteria,
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[1, moveToPositionAbilityMedium, 10],
						[3, playAnimationAbility(AnimationTrack.EXPLOSIVE_SHARDS, 10), explosiveShardsAbilityMedium, 15],
						[3, playAnimationAbility(AnimationTrack.STONE_RAIN, 2), stoneRainAbilityMedium, 25],
						[2, playAnimationAbility(AnimationTrack.ONYX_SHIELD, 5), onyxShieldAbilityMedium, 25],
						[2, moveToRunePositionAbilityMedium, runeInvulnerabilityMedium, 45, playAnimationAbility(AnimationTrack.PETRIFYING_WAVE, 10), petrifyingWaveAbilityMedium, 15, moveToPositionAbilityMedium, 10],
					],
				},
				
			},
			{
				priority: 41,
				actionCriteria:{
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						NormalDifficultyCriteria,
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[1, moveToPositionAbilityEasy, 15],
						[2, playAnimationAbility(AnimationTrack.EXPLOSIVE_SHARDS, 10), explosiveShardsAbilityEasy, 30],
						[2, playAnimationAbility(AnimationTrack.STONE_RAIN, 2), stoneRainAbilityEasy, 40],
						[1, playAnimationAbility(AnimationTrack.ONYX_SHIELD, 5), onyxShieldAbilityEasy, 25],
						[1, moveToRunePositionAbilityEasy, runeInvulnerabilityEasy, 60, playAnimationAbility(AnimationTrack.PETRIFYING_WAVE, 10), petrifyingWaveAbilityEasy, 20, moveToPositionAbilityEasy, 15],
					],
				},
				
			},
		],
	},

	name: ENEMY_NAMES.HIGHLANDS_BOSS,
	type: EnemyType.BOSS,
	appearance: {
		asset: SpineDataName.HIGHLANDS_BOSS,
		skin: EnemyBipedSkinType.DEFAULT,
		defaultMix: 0.3,
		mixSettings: [],
		zOffset: 10,
	},
	general: {
		enemyLevel: 1,
		experienceWhenKilled: 20,
	},
	baseAttributes: {
		awarenessCollisionRadiusInGrids: 10,
		colliderIsKinematic: true,
		...EnemyDefaults.baseAttributes,
		colliders: [
			{
				type: ColliderType.Circle,
				radius: 120,
				position: [0, -60],
				traits: ColliderTraits.BlockMovementAndProjectile,
			},
			{
				type: ColliderType.Circle,
				radius: 90,
				position: [0, -200],
				traits: ColliderTraits.BlockProjectile,
			},
		],
		attackOffset: new Vector(0, 0),
		lootDropOffset: new Vector(0, 0),
		damageConfig: bossEnemyDamageConfig,
		movementSpeed: 100,
		visibilityRadius: 1500,
		decelerationRate: 0,
		attackRate: 750,
		turningRatePerSecondInDegrees: 200,
		physicalDamage: 0,
		fireDamage: 0,
		iceDamage: 0,
		lightningDamage: 0,
		poisonDamage: 0,
		critChance: 0.05,
		critDamage: 1.5,
		cooldownSpeed: 300,
		debuffPotency: 1.0,
		maxHealth: ENEMY_DEFAULT_HEALTH * BOSS_HEALTH_MULTIPLIER, // Boss health
		maxShields: 0,
		shieldRegenDelay: 0,
		shieldRegenRate: 0,
		healthRegen: 0,
		healthRegenDelay: 0,
		maxEnergy: 100,
		energyRegen: 100,
		primaryFireEnergyCost: 0,
		defense: 0,
		fireResist: 0,
		poisonResist: 0,
		iceResist: 0,
		lightningResist: 0,
		buffDuration: 0,
		skillUsageSpeed: 0,

		lootDropProbability: 100,
		lootDropQuantity: BOSS_LOOT_DROP_QUANTITY + 1,
		lootDropRarity: BOSS_LOOT_DROP_RARITY + 1,
	},
	soundEffects: {
		attack: 'Projectile_Fireball',
		impact: 'SFX_Impact_Enemy_Flesh',
	},
	states: {
		idling: {
			behaviour: IdleBehaviours.STATIONARY,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			transitionToFighting: {
				trigger: FightingTriggers.AGGRESSIVE_PROXIMITY,
				radius: 2500,
			},
			aggroOnHit: true,
		},
		fighting: {
			behaviour: FightingBehaviours.CHASE_AND_ATTACK,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			attackType: AttackTypes.PROJECTILE,
			engagementMaxDistance: 1600,
			engagementMinDistance: 0,
			movementMaxDistance: 1600,
			movementMinDistance: 0,
			attackOptions: [],
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 0.5,
			transitionToLeashing: {
				trigger: LeashTriggers.NONE,
			},
		},
		leashing: {
			behaviour: LeashingBehaviours.MOVE,
			speedMultiplier: 4,
			decelerateMultiplier: 0,
		},
		fleeing: {},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 4,
		},
	},
}

export default boss
