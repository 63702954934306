import logger from '../../utils/client-logger'

const TOAST_ACTIVE_TIME = 2_500
const TOAST_HIDE_TIME = 500

export const toastUIState = () => {
    logger.debug('Initializing Toast Module')
    return {
        namespaced: true,
        state: {
            toasts: [],
            visible: false,
            animating: false,
        },
        getters: {
            toastText(state) {
                if (state.toasts.length > 0) {
                    return state.toasts[0]
                }
                return null
            },
            isToastActive(state) {
                return state.visible
            }
        },
        mutations: {
            nextToast(state) {
                state.visible = false
            
                setTimeout(() => {
                    state.toasts.shift()
                    if (state.toasts.length > 0) {
                        state.visible = true
                        setTimeout(() => {
                            this.commit('toast/nextToast')
                        }, TOAST_ACTIVE_TIME)
                    } else {
                        state.animating = false
                    }
                }, TOAST_HIDE_TIME)
            },
            queueToast(state, text) {
                state.toasts.push(text)

                if (!state.animating) {
                    state.visible = true
                    state.animating = true
                    setTimeout(() => {
                        this.commit('toast/nextToast')
                    }, TOAST_ACTIVE_TIME)
                }
            }
        }
    }        
}