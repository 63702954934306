import nengi from 'nengi'
import { DamageNumberStyle } from '../../combat/shared/damage.shared'

export class DamageNumberMessage {
	static protocol = {
		x: { type: nengi.Number },
		y: { type: nengi.Number },
		hitEntityId: { type: nengi.UInt16 },
		effectAmount: { type: nengi.Float32 }, // damage, healing amount, shields lost, etc...
		damageNumberStyle: { type: nengi.UInt4 },
	}

	x: number
	y: number
	hitEntityId: number
	effectAmount: number
	damageNumberStyle: DamageNumberStyle

	constructor(params: DamageNumberMessage) {
		this.x = params.x
		this.y = params.y
		this.hitEntityId = params.hitEntityId
		this.effectAmount = params.effectAmount
		this.damageNumberStyle = params.damageNumberStyle
	}
}
