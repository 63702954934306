
































import { mapActions, mapGetters } from 'vuex'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import TextButton from '../reusable-components/buttons/text-button.vue'
import PanelDivider from '../reusable-components/panel-divider/panel-divider.vue'

export default {
	name: 'GenericYesNo',
	components: {
		MainPanel,
		TextButton,
		PanelDivider,
	},
	computed: {
		...mapGetters('genericYesNo', ['getDescription', 'getTitle', 'getNoButtonText', 'getYesButtonText', 'getYesButtonColor', 'getNoButtonColor', 'noDisabled', 'yesDisabled', 'getOptionalItem', 'getPanelIdentifier']),
	},
	methods: {
		...mapActions('genericYesNo', ['closeActiveYesNoPanel', 'callNoCallbackAndCloseMenu', 'callYesCallbackAndCloseMenu']),
	},
}
