import nengi from 'nengi'
import { uuid } from '../../utils/primitive-types'

class BuyItemCommand {
	static protocol = {
		listingId: nengi.String,
		itemId: nengi.String,
		userId: nengi.String,
		page: nengi.Number,
		sortBy: nengi.String,
		orderBy: nengi.String,
		rarity: nengi.Number
	}
	listingId: uuid
	itemId: uuid
	userId: uuid
	page: number
	sortBy: string
	orderBy: string
	rarity: number
	/**
	 * Creates an instance of BuyItemCommand. This will scan the Stash to find a matching item.
	 * @param {uuid} listingId Item ID
	 * @param {uuid} Item ID
	 * @memberof BuyItemCommand
	 */
	constructor(listingId: uuid, itemId: uuid, userId: uuid, page: number, sortBy: string, orderBy: string, rarity: number) {
		this.listingId = listingId
		this.itemId = itemId
		this.userId = userId
		this.page = page
		this.sortBy = sortBy
		this.orderBy = orderBy
		this.rarity = rarity
	}
}

export default BuyItemCommand
