import nengi from 'nengi'
import ItemRarity from '../../loot/shared/item-rarity'
import ItemType from '../../loot/shared/item-type'
import { timeInSeconds } from '../../utils/primitive-types'

class GroundItemEntity {
	static protocol = {
		x: { type: nengi.Float32, interp: true },
		y: { type: nengi.Float32, interp: true },
		name: { type: nengi.String },
		itemType: { type: nengi.Number },
		itemSubType: { type: nengi.Number },
		biome: { type: nengi.Number },
		biomeTier: { type: nengi.Number },
		rarity: { type: nengi.Number },
		owningEntityId: { type: nengi.Number },
		remainingLifespan: { type: nengi.Number },
		pickedUp: { type: nengi.Boolean },
	}
	nid: number = -1
	x: number
	y: number
	name: string
	itemType: ItemType = -1
	itemSubType: number = -1
	biome: number = -1
	biomeTier: number = -1
	rarity: ItemRarity = ItemRarity.COMMON
	owningEntityId: number
	remainingLifespan: timeInSeconds
	pickedUp: boolean
}

export default GroundItemEntity
