import { ClientBeam } from '../../../beams/client/beam.client'
import { clientDebug } from '../../../debug/client/debug-client'
import { Colors, ColorUtil } from '../../../utils/colors'
import { timeInSeconds } from '../../../utils/primitive-types'
import { Effect } from './pfx/effect'
import { AffectorConfig, ScaleAffectorMode } from './pfx/emitterConfig'
import Renderer from './renderer'

export const BEAM_WIDTH_INVERSE = 1 / 66

const beamConfig = {
	beamOutTime: 0.1,
	mildPulseMag: 0.1,
	mildPulseFreq: 10,
	majorPulseTimeSinceDamage: 0.1,
	majorPulseMag: 1.5,
	startScaleAdjust: 0.8,
	zOffset: 270, // needs to be on top of sword
	debugDrawing: false,
}
export class BeamGraphics {
	private effectStart: Effect
	private effectCenter: Effect
	private effectEnd: Effect
	private timeAlive: number = 0
	private renderer: Renderer

	constructor(beam: ClientBeam, renderer: Renderer) {
		this.renderer = renderer

		this.effectStart = renderer.addEffectToScene('beam-start', beam.x, beam.y)
		this.effectCenter = renderer.addEffectToScene('beam-center', beam.x, beam.y)
		this.effectEnd = renderer.addEffectToScene('beam-end', beam.x, beam.y)
		this.effectStart.prewarm()
		this.effectCenter.prewarm()
		this.effectEnd.prewarm()

		this.effectCenter.emitters[0].startColor = ColorUtil.toRGB(beam.color)

		this.update(beam, 0)

		const affector: AffectorConfig = {
			id: 'scale',
			cfg: { mode: ScaleAffectorMode.InheritEmitter },
		}

		this.effectEnd.emitters.forEach((e) => {
			e.addAffector(affector)
		})
	}

	update(beam: ClientBeam, dt: timeInSeconds) {
		const angle = beam.angle
		const length = beam.length * Math.min(1, this.timeAlive / beamConfig.beamOutTime)

		const offsetx = Math.cos(angle) * length
		const offsety = Math.sin(angle) * length

		const width = beam.width
		const beamWidth = beam.width * (1 + Math.sin(this.timeAlive * beamConfig.mildPulseFreq * Math.PI * 2) * beamConfig.mildPulseMag)

		this.effectStart.x = beam.x
		this.effectStart.y = beam.y
		this.effectStart.scale = beam.width * BEAM_WIDTH_INVERSE * beamConfig.startScaleAdjust
		this.effectEnd.x = beam.x + offsetx
		this.effectEnd.y = beam.y + offsety

		if (beamConfig.debugDrawing) {
			const c = Colors.blue
			clientDebug.drawCircle(this.effectStart, 10, c)
			clientDebug.drawCircle(this.effectEnd, 10, c)
			clientDebug.drawLine(this.effectStart, this.effectEnd, c)
		}

		const hitScale = beam.timeSinceDamage < beamConfig.majorPulseTimeSinceDamage ? beamConfig.majorPulseMag : 1
		this.effectEnd.scale = Math.clamp(width * BEAM_WIDTH_INVERSE * hitScale, 0.1, 1.25)

		this.effectCenter.x = beam.x + offsetx * 0.5
		this.effectCenter.y = beam.y + offsety * 0.5
		this.effectCenter.scaleX = length * BEAM_WIDTH_INVERSE
		this.effectCenter.scaleY = beamWidth * BEAM_WIDTH_INVERSE
		this.effectCenter.rot = beam.angle

		const minZ = Math.max(this.effectCenter.y, this.effectStart.y, this.effectEnd.y) + beamConfig.zOffset

		this.effectStart.zIndex = minZ
		this.effectCenter.zIndex = minZ - 1 // -1 so end caps are always on top of beam
		this.effectEnd.zIndex = minZ

		this.timeAlive += dt
	}

	cleanup() {
		this.renderer.removeEffectFromScene(this.effectStart)
		this.renderer.removeEffectFromScene(this.effectCenter)
		this.renderer.removeEffectFromScene(this.effectEnd)
	}
}
