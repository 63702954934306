import { forIn } from 'lodash'
import { Vector } from 'sat'
import { BOSS_ENEMY_NAMES, ENEMY_NAMES } from '../../ai/shared/enemies/enemy-names'
import EnemyDefinitions from '../../ai/shared/enemy-definitions'
import { allPOIs } from '../../biome/shared/biome-data/poi-data'
import ChatCommand from '../../chat/shared/chat-command'
import { debugConfig } from '../../engine/client/debug-config'
import { NengiClient } from '../../engine/client/nengi-client'
import DevToolsManager from '../../ui/dev-tools/dev-tools-manager'
import { POIData } from '../../world/shared/poi-data-types'
import { propConfigs } from '../../world/shared/prop-configs'

if (process.env.NODE_ENV !== 'loot-prod' && process.env.NODE_ENV !== 'beta' && process.env.NODE_ENV !== 'staging') {
	/*
	Constructs an object containing functions that will spawn a given prop from the game's various biomes.
	The propSpawner object is passed to DevToolsManager so that it may  be used in the inspector with /inspectc propSpawner
	*/
	const propSpawner = {}

	forIn(propConfigs, (biome, biomeID) => {
		propSpawner[biomeID] = {}
		forIn(biome, (propConfig, propName) => {
			if (!propConfig.dontLoadAsset) {
				const propID = `${biomeID}/${propName}`
				propSpawner[biomeID][propName] = () => NengiClient.getInstance().sendCommand(new ChatCommand(`/prop-spawn ${propID}`))
			}
		})
	})

	DevToolsManager.getInstance().addObjectByName('propSpawner', propSpawner)
	//Uncomment the line below to bring propSpawn into the inspector's focus by default
	//DevToolsManager.getInstance().setDebugObject(propSpawner)

	const teleportMenu = { direction: {}, boss: {}, poi: {} }

	const directions = [new Vector(0, -500), new Vector(0, 500)]
	directions.forEach((direction) => {
		teleportMenu.direction[`x:${direction.x} y:${direction.y}`] = () => NengiClient.getInstance().sendCommand(new ChatCommand(`/tr ${direction.x} ${direction.y}`))
	})

	BOSS_ENEMY_NAMES.forEach((enemy) => {
		teleportMenu.boss[enemy] = () => NengiClient.getInstance().sendCommand(new ChatCommand(`/tt ${enemy.replace('The ', '')}`))
	})

	allPOIs.forEach((poiData: POIData) => {
		teleportMenu.poi[poiData.debugName] = () => NengiClient.getInstance().sendCommand(new ChatCommand(`/tt ${poiData.debugName}`))
	})

	DevToolsManager.getInstance().addObjectByName('teleportMenu', teleportMenu)

	const enemySpawner = {
		killAll: () => NengiClient.getInstance().sendCommand(new ChatCommand(`/killenemies`)),
	}
	EnemyDefinitions.forEach((enemy, key) => {
		enemySpawner[enemy.name] = () => NengiClient.getInstance().sendCommand(new ChatCommand(`/es 1 ${enemy.name} nearby`))
	})
	DevToolsManager.getInstance().addObjectByName(`enemySpawner`, enemySpawner)

	const encyclopedia = () => {
		const insp = {}
		const names = DevToolsManager.getInstance().getMatchingNames('')

		const bosses = [ENEMY_NAMES.BEACH_BOSS, ENEMY_NAMES.FOREST_BOSS, ENEMY_NAMES.HIGHLANDS_BOSS, ENEMY_NAMES.FUNGI_BOSS, ENEMY_NAMES.PRISM_BOSS]
		for (let i = 0; i < bosses.length; i++) {
			const name = bosses[i];
			const nameNoSpace = name.replaceAllSLOW(' ', '.')
			insp[name] = () => NengiClient.getInstance().sendCommand(new ChatCommand(`/inspect ${nameNoSpace}`))
		}

		insp['---------------------'] = () => { }

		names.forEach(name => {
			// bit of a hack because our chat console doesn't support spaces in names, replace all spaces with regex match any character
			const nameNoSpace = name.replaceAllSLOW(' ', '.')
			insp[name] = () => NengiClient.getInstance().sendCommand(new ChatCommand(`/inspect ${nameNoSpace}`))
		})
		DevToolsManager.getInstance().setDebugObject({ encyclopedia: insp })
	}

	const debugConfigFunc = () => {
		DevToolsManager.getInstance().setDebugObject({ debugConfig })
	}
	const serverConfigFunc = () => {
		NengiClient.getInstance().sendCommand(new ChatCommand(`/inspect debugConfig`))
	}

	const inspectorTools = {
		clientDebugConfig: debugConfigFunc,
		serverDebugConfig: serverConfigFunc,
		encyclopedia,
		teleportMenu,
		propSpawner,
		enemySpawner
	}
	DevToolsManager.getInstance().addObjectByName(`inspectorTools`, inspectorTools)
	DevToolsManager.getInstance().setDebugObject(inspectorTools, undefined, undefined, false)
}

//Dummy function that simply allows the tools in this module to be integrated into the client
export function initClientTools() { }
