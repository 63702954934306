import { autoDetectRenderer, Container, Graphics, Renderer as PIXIRenderer, SCALE_MODES, settings } from 'pixi.js'
import { Vector } from 'sat'
import { AssetIdentifier, AssetManager, debugIterateAssetDefinitionList } from '../../../asset-manager/client/asset-manager'
import { ClientBeam } from '../../../beams/client/beam.client'
import { AnyColliderConfig } from '../../../collision/shared/colliders'
import BackgroundGridVisual from '../../../debug/client/background-grid-visual'
import { ColliderTweaker } from '../../../debug/client/collider-tweaker'
import { DebugScreenPrintBeginFrame } from '../../../debug/client/debug-client'
import SpatialGridVisual from '../../../debug/client/spatial-grid-visual'
import DrawShapesMessage from '../../../debug/shared/draw-shapes-message'
import { ClientProjectile } from '../../../projectiles/client/projectile.client'
import WeaponFiredMessage from '../../../projectiles/shared/weapon-fired-message'
import { simpleAnimation_removeAnimations } from '../../../utils/simple-animation-system'
import { attachments_removeAttachments } from '../../../utils/attachments-system'
import logger from '../../../utils/client-logger'
import { highResolutionTimestamp, printTree } from '../../../utils/debug'
import { basicNumberLerp, mapToRange, throwIfNotFinite, VectorXY } from '../../../utils/math'
import { biomeIndex, gameUnits, nengiId, timeInSeconds } from '../../../utils/primitive-types'
import BackgroundRenderer from '../../../world/client/background-renderer'
import ForegroundRenderer from '../../../world/client/foreground-renderer'
import { MiddlegroundRenderer } from '../../../world/client/middleground-renderer'
import WaterRenderer from '../../../world/client/water-renderer'
import { addColliderConfigsToLookupColliders } from '../../../world/shared/prop-model-data'
import { getProjectileAssetFromEnum } from '../../shared/game-data/particle-config'
import { clientConfig } from '../client-config'
import { debugConfig, DebugLogLevel } from '../debug-config'
import { NengiClient } from '../nengi-client'
import { BeamManager } from './beam-manager'
import { filterFromString } from './filters'
import { Effect } from './pfx/effect'
import { EffectConfig } from './pfx/effectConfig'
import { InstancedSpriteBatcher, PFX_SHADER_NAME } from './pfx/instanced-sprite-batcher'
import { IParticleRendererCamera } from './pfx/sprite-particle-renderer'
import ProjectileEffectManager from './projectile-effect-manager'
import { RenderQueue } from './render-queue'
import { WeaponSubTypeAssetName } from '../../../loot/shared/weapon-sub-type'
import { getSpineTexture } from '../../../utils/pixi-util'
import { TextureUpdater } from './texture-updater'
import Time from '../../shared/time'
import { gameModeConfig } from '../../shared/game-mode-configs'
import { GameModeType } from '../../../engine/shared/game-mode-type'
import clientLogger from '../../../utils/client-logger'
import { InstancedSprite } from '../../../world/client/instanced-sprite'
import { preloadShaders } from './preload-programs'
import { clearObjectRefs } from '../../../utils/object-util'
import { analyticsWebglContextLost_SendOnce } from '../../../ui/state/google-analyitics'
import DrawRectangle from '../../../debug/shared/draw-rectangle'
import DrawText from '../../../debug/shared/draw-text'
import { OldFilmFilter } from 'pixi-filters'

settings.SCALE_MODE = SCALE_MODES.LINEAR

const NORMAL_ZOOM_LEVEL = clientConfig.zoomLevel
const MAX_CAM_DIST_X = 75
const MAX_CAM_DIST_Y = 50

export const zoomLevels = {
	maxRes: 4000,
	normalRes: 1920,
	minRes: 960,
	minZoom: 0.35,
	normalZoom: 0.7,
	maxZoom: 1.05,
}

export const NENGI_VIEW_X_PADDING = 165
export const NENGI_VIEW_Y_PADDING = 185
export const MAX_X_RESOLUTION = 3840
export const MAX_Y_RESOLUTION = 2160

const debugConfigCamera = debugConfig.camera

export function getGameScreenWidth() {
	return debugConfigCamera.enableFakeResolution ? debugConfigCamera.fakeResolutionWidth : window.innerWidth
}

export function getGameScreenHeight() {
	return debugConfigCamera.enableFakeResolution ? debugConfigCamera.fakeResolutionHeight : window.innerHeight
}

export const getVisibleWorldWidth = (zoom?) => {
	return Math.floor(getGameScreenWidth() / (zoom || getZoomLevelFromWindowWidth()))
}

export const getVisibleWorldHeight = (zoom?) => {
	return Math.floor(getGameScreenHeight() / (zoom || getZoomLevelFromWindowWidth()))
}

export const getMaxVisibleWorldWidth = (zoom?) => {
	return Math.floor(MAX_X_RESOLUTION / (zoom || getZoomLevelFromWindowWidth()))
}

export const getMaxVisibleWorldHeight = (zoom?) => {
	return Math.floor(MAX_Y_RESOLUTION / (zoom || getZoomLevelFromWindowWidth()))
}

export default class Renderer {
	static logGraphics(g: Graphics) {
		if (false) {
			// throwing here so I can call printTree in debugger
			printTree(null)
		}

		logger.debug(Renderer.getContainerDebugPath(g) + ' = ' + Renderer.getShapes(g))
	}

	static getShapes(g: Graphics) {
		const geometry: any = g.geometry
		if (g.geometry == null) {
			return `${g.constructor.name} p:${g.x},${g.y}`
		}
		const graphicsData: PIXI.GraphicsData[] = geometry.graphicsData
		const shapes = graphicsData.map((gd) => JSON.stringify(gd.shape)).join(' - ')
		return shapes
	}

	static getContainerDebugPath(container: Container) {
		let path = ''
		let cur: Container = container
		while (cur) {
			if (cur.name) {
				path = cur.name + '.' + path
			} else {
				path = cur.constructor.name + '.' + path
			}
			cur = cur.parent
		}
		return path
	}

	static getInstance(params?: any) {
		if (!Renderer.instance) {
			if (params === undefined) {
				throw new Error('Insufficient constructor parameters for Renderer getInstance(); aborting startup')
			} else {
				Renderer.instance = new Renderer(params)
			}
		}

		return Renderer.instance
	}

	static instance: Renderer
	pixiRenderer: PIXIRenderer
	waterRenderer: WaterRenderer
	bgRenderer: BackgroundRenderer
	mgRenderer: MiddlegroundRenderer
	fgRenderer: ForegroundRenderer
	instancedSpriteBatcher: InstancedSpriteBatcher
	debugMiddleground: Container
	debugBackground: Container
	renderQueue: RenderQueue
	nengiClient: any
	stage: Container
	camera: Container
	zoomLevel: number
	debugZoomLevel: number = 1

	gameClientState: any
	cameraState: IParticleRendererCamera

	biomeBounds: Array<{ min: number; max: number }>
	waterBounds: { north: number; south: number }

	private effectManager: ProjectileEffectManager
	private beamManager: BeamManager
	private debugColliderTweaker: ColliderTweaker
	private debugGraphicsCache: Map<number, Graphics> = new Map()
	private textureUpdater

	constructor(params) {
		if (debugConfig.render.logShaderCreates !== DebugLogLevel.Off) {
			setupLogShaderCreates()
		}

		try {
			const canvas: HTMLCanvasElement = document.getElementById('main-canvas') as HTMLCanvasElement
			this.gameClientState = params.gameClientState

			this.pixiRenderer = autoDetectRenderer({
				width: window.innerWidth,
				height: window.innerHeight,
				view: canvas,
				antialias: false,
				transparent: false,
				resolution: 1,
			})

			canvas.addEventListener('webglcontextlost', analyticsWebglContextLost_SendOnce)

			// enable this line to turn textureGC to manual mode
			// this.pixiRenderer.textureGC.mode = PIXI.GC_MODES.MANUAL

			const MAX_TEXTURES = this.pixiRenderer.plugins.batch.MAX_TEXTURES

			logger.debug('[RENDER] create InstancedSpriteBatcher and RenderQueue')

			const preloadTextures = getAllTextures()
			this.textureUpdater = new TextureUpdater(preloadTextures)

			setTimeout(() => {
				const startMs = highResolutionTimestamp()
				logger.debug(`Preloading ${preloadTextures.length} textures`)
				preloadTextures.forEach((texture) => {
					this.pixiRenderer.texture.bind(texture.baseTexture)
				})
				const secondsTaken = (highResolutionTimestamp() - startMs) * 0.001
				logger.debug(`Preloading ${preloadTextures.length} textures took ${secondsTaken.toFixed(3)} seconds`)
			}, 0) // deley this one frame to get to loading screen (this takes 2-3s)

			this.instancedSpriteBatcher = new InstancedSpriteBatcher(this.pixiRenderer, MAX_TEXTURES)
			this.renderQueue = new RenderQueue(this.pixiRenderer, this.instancedSpriteBatcher)
			this.zoomLevel = getZoomLevelFromWindowWidth()
			this.stage = new Container()
			this.stage.name = 'stage'
			this.camera = new Container()
			this.camera.name = 'camera'
			this.debugBackground = new Container()
			this.debugBackground.name = 'background'
			this.debugMiddleground = new Container()
			this.debugMiddleground.name = 'debug-visual-layer'

			this.cameraState = {
				x: 0,
				y: 0,
				zoom: 1.0,
				halfWidth: window.innerWidth / 2.0,
				halfHeight: window.innerHeight / 2.0,
			}

			if (!debugConfig.render.disableBackground) {
				this.bgRenderer = new BackgroundRenderer(params.gameClientState)
				this.bgRenderer.name = 'background'
			}
			this.mgRenderer = new MiddlegroundRenderer(this.renderQueue, this.cameraState)
			this.mgRenderer.name = 'middleground'
			this.fgRenderer = new ForegroundRenderer(this.renderQueue, this.cameraState)
			this.fgRenderer.name = 'foreground'

			if (gameModeConfig.type === GameModeType.Adventure) {
				if (!debugConfig.render.disableWater) {
					this.waterRenderer = new WaterRenderer()
					this.waterRenderer.name = 'water'
					this.stage.addChild(this.waterRenderer)
				}
			}

			if (this.bgRenderer) {
				this.stage.addChild(this.bgRenderer)
			}
			this.stage.addChild(this.debugBackground)
			this.stage.addChild(this.mgRenderer)
			this.stage.addChild(this.debugMiddleground)
			this.stage.addChild(this.fgRenderer)
			this.camera.addChild(this.stage)

			this.camera.scale.set(this.zoomLevel)

			this.effectManager = new ProjectileEffectManager(this.mgRenderer, this.cameraState)

			this.beamManager = new BeamManager(this)

			if (debugConfig.camera.allowScrollWheelZoom) {
				//DEBUG: remove for production
				this.addScrollWheelZoomHandler()
			}

			if (debugConfig.camera.enableFakeResolution) {
				this.updateZoomLevel(debugConfig.camera.fakeResolutionZoomLevel)
			} else {
				this.updateZoomLevel(getZoomLevelFromWindowWidth())
			}

			this.checkAddTestFilter([clientConfig.filter1, clientConfig.filter2], clientConfig.filterParams)

			// @ts-ignore let me
			window.updateZoomLevel = this.updateZoomLevel.bind(this)
			// @ts-ignore let me
			window.onGameWindowResize = this.onGameWindowResize.bind(this)

			// @ts-ignore let me
			window.addEventListener('resize', window.onGameWindowResize)
			NengiClient.getInstance().listen('message::DrawText', this.drawText.bind(this))
			NengiClient.getInstance().listen('message::DrawCircle', this.drawCircle.bind(this))
			NengiClient.getInstance().listen('message::DrawRectangle', this.drawRectangle.bind(this))
			NengiClient.getInstance().listen('message::DrawLine', this.drawLine.bind(this))
			NengiClient.getInstance().listen('message::DrawEllipse', this.drawEllipse.bind(this))
			NengiClient.getInstance().listen('message::DrawPolygon', this.drawPolygon.bind(this))
			NengiClient.getInstance().listen('message::DrawShapes', this.drawShapes.bind(this))

			if (debugConfig.props.tweaker) {
				this.initColliderTweaker()
			}

			this.bgRenderer?.loadAtlases()
			this.mgRenderer.loadAtlases()
			this.fgRenderer.loadAssets()
			this.effectManager.onAssetsLoaded()

			shaderLogState = ShaderLogState.Disabled
			preloadShaders(this.pixiRenderer)
			shaderLogState = ShaderLogState.Enabled

			logger.debug('[RENDER] construction complete')
		} catch (e) {
			console.log('error during renderer startup', e)
			throw e
		}
	}

	initColliderTweaker() {
		this.debugProcessPropsForTweaker()
		this.debugColliderTweaker = new ColliderTweaker(this.stage)
	}

	addScrollWheelZoomHandler() {
		window.addEventListener('wheel', this.handleWheelEvent.bind(this))
	}

	handleWheelEvent(event: WheelEvent) {
		// wheel events on document/window are "passive" and cannot preventDefault()
		// we only want this event to be triggered when we're over top of the main-canvas
		const eventTargetDomELement = event.target as Element
		if (eventTargetDomELement?.id !== 'main-canvas') {
			return
		}
		event.stopPropagation()

		// scaleAmount is calculated here so a -ve change undoes a +ve change, and vice versa
		let scaleAmount = 1 + Math.abs(event.deltaY) * -0.002
		if (event.deltaY < 0) {
			scaleAmount = 1 / scaleAmount
		}

		let scaleFactor = (this.debugZoomLevel = this.debugZoomLevel * scaleAmount)

		scaleFactor = Math.clamp(scaleFactor, 0.04, 4)
		if (scaleFactor >= NORMAL_ZOOM_LEVEL / 1.1 && scaleFactor <= NORMAL_ZOOM_LEVEL * 1.1) {
			scaleFactor = NORMAL_ZOOM_LEVEL
		}

		this.updateZoomLevel(this.zoomLevel)
	}

	/** CURRENTLY UNUSED */
	bindAllTextures() {
		const resources = AssetManager.getInstance().loadedAssets
		const renderer = this
		resources.forEach((resource) => {
			const texture = resource.texture as PIXI.Texture
			if (texture) {
				//console.log(`${resource.name}, ${texture.baseTexture.width}, ${texture.baseTexture.height}`)
				renderer.pixiRenderer.texture.bind(texture.baseTexture)
			}
		})
	}

	onFileChange(assetName: AssetIdentifier, contents: any) {
		this.effectManager.onFileChange(assetName, contents)
	}

	// normally client doesn't need to know about propconfigs, but tweaker does
	debugProcessPropsForTweaker() {
		const assetMan = AssetManager.getInstance()

		// example path: './assets/biomes/highlands/props/large-rare-01-var01.json'
		const getClientPath = function(path) {
			const words = path.split('/')
			const clientPath = `${words[words.length - 3]}/${words[words.length - 1].replace('.json', '')}`
			return clientPath // example clientPath: 'highlands/large-rare-01-var01'
		}

		addColliderConfigsToLookupColliders({
			existsSync: (path: string) => {
				const clientPath = getClientPath(path)
				return assetMan.hasAssetByName(clientPath)
			},
			readFileSync: (path) => {
				const clientPath = getClientPath(path)
				const contents = assetMan.getAssetByName(clientPath)
				return contents.xhr.response
			},
		})
	}

	setWorldSize(worldWidth: gameUnits, minWorldWidth: gameUnits, worldHeight: gameUnits, chunkSize: gameUnits, chunksPerRow: number, chunksPerColumn: number) {
		if (clientConfig.renderDebugVisuals) {
			this.drawDebugGrids(worldWidth, worldHeight, this.debugBackground)
		} else if (debugConfig.drawGrid) {
			this.drawDebugGrids(worldWidth, worldHeight, this.debugMiddleground)
		}

		this.bgRenderer?.setWorldSize(worldWidth, minWorldWidth, worldHeight, chunkSize, chunksPerRow, chunksPerColumn)
		if (this.waterRenderer) {
			this.waterRenderer.setWorldSize(worldWidth, worldHeight, chunksPerRow, chunksPerColumn)
		}
	}

	getBiomeCurrentBiome(x: number): biomeIndex {
		if (this.biomeBounds) {
			for (let i = this.biomeBounds.length - 1; i > 0; --i) {
				if (x > this.biomeBounds[i].min && x < this.biomeBounds[i].max) {
					return i
				}
			}
		}
		// TODO2: If we get here it's because were are in the hub so for now just return the first biome
		return 0
	}

	setBiomeBounds(bounds: Array<{ min: number; max: number }>): void {
		this.biomeBounds = bounds
	}

	setWaterBounds(north: number, south: number): void {
		clientLogger.debug('North', north, 'South', south)
		this.waterBounds = { north, south }
	}

	updateZoomLevel(zoomLevel: number, forceGraphicResize: boolean = false) {
		// if (this.zoomLevel.toFixed(3) !== (zoomLevel * this.debugZoomLevel).toFixed(3)) {
		// 	console.log({ zoomLevel })
		// }
		if (this.pixiRenderer) {
			if (this.zoomLevel !== zoomLevel || forceGraphicResize) {
				this.zoomLevel = zoomLevel * this.debugZoomLevel
				this.bgRenderer?.resizeGradient(zoomLevel)
				this.fgRenderer?.resizeVignette(zoomLevel)
			}
		}
		this.camera.scale.set(this.zoomLevel)
		this.pixiRenderer.resize(window.innerWidth, window.innerHeight)
	}

	getCameraCenterWorldPos() {
		return new Vector(-(this.camera.x - 0.5 * window.innerWidth) / this.zoomLevel, -(this.camera.y - 0.5 * window.innerHeight) / this.zoomLevel)
	}

	centerCameraOnPoint(x, y, lerpT: number): void {
		//below clamps the camera to the world bounds
		///x = Math.clamp(x, this.cameraMinX, this.cameraMaxX)
		//y = Math.clamp(y, this.cameraMinY, this.cameraMaxY)
		x = -x
		y = -y

		const newXTarget = x * this.zoomLevel + 0.5 * window.innerWidth
		const newYTarget = y * this.zoomLevel + 0.5 * window.innerHeight

		const noZoomXTarget = x + 0.5 * window.innerWidth
		const noZoomYTarget = y + 0.5 * window.innerHeight

		let lerpedNewX = basicNumberLerp(this.camera.x, newXTarget, lerpT)
		let lerpedNewY = basicNumberLerp(this.camera.y, newYTarget, lerpT)
		let lerpedNewXWithoutZoom = basicNumberLerp(this.cameraState.x, noZoomXTarget, lerpT)
		let lerpednewYWithoutZoom = basicNumberLerp(this.cameraState.y, noZoomYTarget, lerpT)

		const xDistance = lerpedNewX - newXTarget
		const yDistance = lerpedNewY - newYTarget

		const noZoomXDist = lerpedNewXWithoutZoom - noZoomXTarget
		const noZoomYDist = lerpednewYWithoutZoom - noZoomYTarget

		if (xDistance > MAX_CAM_DIST_X) {
			lerpedNewX = newXTarget + MAX_CAM_DIST_X
		} else if (xDistance < -MAX_CAM_DIST_X) {
			lerpedNewX = newXTarget - MAX_CAM_DIST_X
		}

		if (yDistance > MAX_CAM_DIST_Y) {
			lerpedNewY = newYTarget + MAX_CAM_DIST_Y
		} else if (yDistance < -MAX_CAM_DIST_Y) {
			lerpedNewY = newYTarget - MAX_CAM_DIST_Y
		}

		if (noZoomXDist > MAX_CAM_DIST_X) {
			lerpedNewXWithoutZoom = noZoomXTarget + MAX_CAM_DIST_X
		} else if (noZoomXDist < -MAX_CAM_DIST_X) {
			lerpedNewXWithoutZoom = noZoomXTarget - MAX_CAM_DIST_X
		}

		if (noZoomYDist > MAX_CAM_DIST_Y) {
			lerpednewYWithoutZoom = noZoomYTarget + MAX_CAM_DIST_Y
		} else if (noZoomYDist < -MAX_CAM_DIST_Y) {
			lerpednewYWithoutZoom = noZoomYTarget - MAX_CAM_DIST_Y
		}

		this.camera.x = lerpedNewX
		this.camera.y = lerpedNewY
		this.cameraState.zoom = this.zoomLevel
		this.cameraState.x = lerpedNewXWithoutZoom
		this.cameraState.y = lerpednewYWithoutZoom
		this.cameraState.halfWidth = window.innerWidth * 0.5
		this.cameraState.halfHeight = window.innerHeight * 0.5
	}

	mouseCoordinatesToWorldCoordinates(mouseX, mouseY): { x: gameUnits; y: gameUnits } {
		const camLeft = -(this.camera.x / this.zoomLevel)
		const camTop = -(this.camera.y / this.zoomLevel)

		return {
			x: camLeft + mouseX / this.zoomLevel,
			y: camTop + mouseY / this.zoomLevel,
		}
	}

	worldCoordsToScreenCoords(worldX, worldY) {
		const camLeft = -(this.camera.x / this.zoomLevel)
		const camTop = -(this.camera.y / this.zoomLevel)

		return {
			x: (worldX - camLeft) * this.zoomLevel,
			y: (worldY - camTop) * this.zoomLevel,
		}
	}

	addGhostFilter(): void {
		const ghostFilter = filterFromString('ghost', '')
		const existingGhostFilter = this.stage.filters.find((filter) => filter instanceof OldFilmFilter)
		if (!existingGhostFilter) {
			this.stage.filters.push(ghostFilter)
		}
	}

	removeGhostFilter(): void {
		for (const filter of this.stage.filters) {
			if (filter instanceof OldFilmFilter) {
				this.stage.filters.remove(filter)
				return
			}
		}
	}

	update(delta: timeInSeconds): void {
		const textureSystem = this.pixiRenderer.texture
		//console.log(`textures:${textureSystem.managedTextures.length}`)

		if (this.waterRenderer) {
			this.waterRenderer.visible = !clientConfig.renderDebugVisuals && !debugConfig.render.disableWater
		}
		if (this.bgRenderer) {
			this.bgRenderer.visible = !clientConfig.renderDebugVisuals && !debugConfig.render.disableBackground
		}
		this.mgRenderer.visible = !clientConfig.renderDebugVisuals && !debugConfig.render.disableMiddleground
		this.fgRenderer.visible = !clientConfig.renderDebugVisuals && !debugConfig.render.disableForeground
		this.debugMiddleground.visible = debugConfig.enableDebugVisualLayer || clientConfig.renderDebugVisuals
		this.debugBackground.visible = clientConfig.renderDebugVisuals

		this.effectManager.update(delta)
		this.beamManager.update(delta)
		this.waterRenderer?.update(delta)
		if (!debugConfig.render.disableBackground) {
			this.bgRenderer?.update(delta)
		}
		this.mgRenderer.update(delta)
		this.fgRenderer.update(delta)
		this.pixiRenderer.render(this.camera)
		this.textureUpdater.update()

		this.debugColliderTweaker?.update()

		DebugScreenPrintBeginFrame()
	}

	checkAddTestFilter(filterNames: string[], filterParams: string) {
		const filters = filterNames.map((filterName) => filterFromString(filterName, filterParams))

		const renderers: Container[] = [/*this.bgRenderer,this.mgRenderer, this.fgRenderer*/ this.stage]
		renderers.forEach((renderer) => {
			renderer.filters = filters.filter((f) => f)
		})

		//DevToolsManager.getInstance().setDebugObject({ filter: this.stage.filters[0] })
		//DevToolsManager.getInstance().setDebugObject(this.stage.filters)
	}

	registerProjectile(projectile: ClientProjectile): void {
		this.effectManager.registerProjectile(projectile)
	}

	unregisterProjectile(nid: number): void {
		this.effectManager.unregisterProjectile(nid)
	}

	registerBeam(beam: ClientBeam): void {
		Renderer.getInstance().beamManager.registerBeam(beam)
	}

	unregisterBeam(nid: nengiId): void {
		this.beamManager.unregisterBeam(nid)
	}

	handleWeaponFiredMessage(message: WeaponFiredMessage, ownerEntity) {
		if (message.muzzleFlairParticleEffect === 0) {
			// a particle effect of id 0 is a no-op
			return
		}

		const pfx = message.success ? getProjectileAssetFromEnum(message.muzzleFlairParticleEffect) : 'projectile-fizzle'

		// draw muzzle flair on top of owner
		let zIndex = message.muzzleFlairY
		if (ownerEntity) {
			zIndex = ownerEntity.y + 1
		}

		if (pfx) {
			this.addOneOffEffect(pfx, message.muzzleFlairX, message.muzzleFlairY, zIndex)
		} else {
			logger.warn(`no particle with id:${message.muzzleFlairParticleEffect}`)
		}
	}

	addEffectToScene(particleEffect: string, x: number, y: number, z?: number, scale: number = 1) {
		if (debugConfig.pfx.logNames) {
			logger.debug(`pfx:${particleEffect} scale:${scale}`)
		}
		const pfxAsset = AssetManager.getInstance().getAssetByName(particleEffect).data as EffectConfig
		const pfx = new Effect(pfxAsset, this.cameraState)
		pfx.x = x
		pfx.y = y
		pfx.zIndex = z ?? y
		pfx.scale = scale
		this.mgRenderer.addEffectToScene(pfx)
		return pfx
	}

	removeEffectFromScene(effect: Effect) {
		attachments_removeAttachments(effect)
		simpleAnimation_removeAnimations(effect)
		this.mgRenderer.removeFromScene(effect)
	}

	addOneOffEffect(particleEffect: string, x: number, y: number, z?: number, scale: number = 1, duration: number = 1, prewarm: boolean = false, foreground: boolean = false, alpha: number = 1) {
		const pfxAsset = AssetManager.getInstance().getAssetByName(particleEffect).data as EffectConfig
		return this.addOneOffEffectByConfig(pfxAsset, x, y, z, scale, duration, prewarm, foreground, alpha)
	}

	addOneOffEffectByConfig(pfxAsset: EffectConfig, x: number, y: number, z?: number, scale: number = 1, duration: number = 1, prewarm: boolean = false, foreground: boolean = false, alpha: number = 1) {
		throwIfNotFinite(x)
		throwIfNotFinite(y)

		if (debugConfig.pfx.logNames) {
			logger.debug(`pfx:${pfxAsset.name} scale:${scale}`)
		}

		const pfx = new Effect(pfxAsset, this.cameraState)
		pfx.x = x
		pfx.y = y
		pfx.zIndex = z ? z : y + 1
		pfx.scale = scale

		pfx.emitters.forEach((e) => {
			e.alpha = alpha
		})

		const renderer = foreground ? this.fgRenderer : this.mgRenderer
		renderer.addOneOffEffectToScene(pfx, duration, prewarm)

		return pfx
	}

	removeOneOffEffect(pfx: Effect) {
		this.mgRenderer.removeOneOffEffect(pfx)
	}

	addTweakerCollidersIfEnabled(id: string, ownerPos: Vector, colliders: AnyColliderConfig[], zOffsets?: InstancedSprite[]) {
		if (debugConfig.props.tweaker) {
			this.debugColliderTweaker.addObjectChildren(id, ownerPos, colliders)
			zOffsets?.forEach((obj) => {
				this.debugColliderTweaker.addProperty(id, obj, 'zOffset')
			})
		}
	}

	debugSpawnAllPfx(position: VectorXY) {
		debugIterateAssetDefinitionList((assetDef) => {
			const asset = AssetManager.getInstance().getAssetByName(assetDef.name)
			if (assetDef.path.includes('/pfx/')) {
				const effect = asset.data as EffectConfig
				Renderer.getInstance().addOneOffEffect(asset.name, position.x, position.y, position.y + 1)
			}
		})
	}

	drawRectangle(
		{ topLeftX, topLeftY, width, height, color, permanent, destroyAfterSeconds, scale, rotation, lineWidth }: DrawRectangle /*{ topLeftX: number; topLeftY: number; width: number; height: number; color: number; permanent: boolean; destroyAfterSeconds: number; scale: number; rotation?: number }*/,
	) {
		if (!clientConfig.debug && !debugConfig.disableExpensiveDrawCallWarnings) {
			logger.warn('WARNING: Calling very expensive (i.e. hitch inducing) drawRectangle in renderer! This should not happen frequently.')
		}
		if (clientConfig.debug) {
			const container = new Container()
			const graphics = new Graphics()
			graphics.name = 'rect'
			graphics.scale.x = scale
			graphics.scale.y = scale
			graphics.lineStyle(lineWidth, color)
			graphics.drawRect(-width / 2, -height / 2, width, height)
			container.position.x = topLeftX
			container.position.y = topLeftY
			if (rotation) {
				container.rotation = rotation
			}

			container.addChild(graphics)
			this.debugMiddleground.addChild(container)

			if (!permanent) {
				setTimeout(() => {
					this.debugMiddleground?.removeChild(container)
					container.destroy()
				}, destroyAfterSeconds * 1000)
			}
		}
	}

	drawEllipse({ x, y, rX, rY, color, permanent, destroyAfterSeconds, scale }) {
		if (!clientConfig.debug && !debugConfig.disableExpensiveDrawCallWarnings) {
			logger.warn('WARNING: Calling very expensive (i.e. hitch inducing) drawEllipse in renderer! This should not happen frequently.')
		}
		if (clientConfig.debug) {
			const graphics = new Graphics()
			graphics.name = 'ellipse'
			graphics.scale.x = scale
			graphics.scale.y = scale
			graphics.lineStyle(2, color)
			graphics.drawEllipse(x, y, rX, rY)
			this.debugMiddleground.addChild(graphics)

			if (!permanent) {
				setTimeout(() => {
					this.debugMiddleground?.removeChild(graphics)
					graphics.destroy()
				}, destroyAfterSeconds * 1000)
			}
		}
	}

	drawPolygon({ points, color, permanent, destroyAfterSeconds, scale }) {
		if (!clientConfig.debug && !debugConfig.disableExpensiveDrawCallWarnings) {
			logger.warn('WARNING: Calling very expensive (i.e. hitch inducing) drawPolygon in renderer! This should not happen frequently.')
		}
		if (clientConfig.debug) {
			const graphics = new Graphics()
			graphics.name = 'polygon'
			graphics.scale.x = scale
			graphics.scale.y = scale
			graphics.lineStyle(2, color)
			graphics.drawPolygon(points)
			this.debugMiddleground.addChild(graphics)

			if (!permanent) {
				setTimeout(() => {
					this.debugMiddleground?.removeChild(graphics)
					graphics.destroy()
				}, destroyAfterSeconds * 1000)
			}
		}
	}

	drawCircle({ x, y, radius, color, permanent, destroyAfterSeconds, scale }) {
		if (!debugConfig.debug && !debugConfig.disableExpensiveDrawCallWarnings) {
			logger.warn('WARNING: Calling very expensive (i.e. hitch inducing) drawCircle in renderer! This should not happen frequently.')
		}
		if (clientConfig.debug) {
			const circleGraphics = this.getDebugGraphics(destroyAfterSeconds, permanent)
			circleGraphics.lineStyle(3, color)
			circleGraphics.drawCircle(x, y, radius * scale)
		}
	}

	drawLine({ sourceX, sourceY, destX, destY, color, permanent, destroyAfterSeconds }) {
		if (!debugConfig.debug && !debugConfig.disableExpensiveDrawCallWarnings) {
			logger.warn('WARNING: Calling very expensive (i.e. hitch inducing) drawLine in renderer! This should not happen frequently.')
		}
		if (clientConfig.debug) {
			const debugGraphics = this.getDebugGraphics(destroyAfterSeconds, permanent)
			debugGraphics.lineStyle(2, color)
			debugGraphics.moveTo(sourceX, sourceY)
			debugGraphics.lineTo(destX, destY)
		}
	}

	// TODO2: ignoring destroyAfterSeconds and using DebugScreenPrint
	//  some combination of lots of text prints + "new Graphics" + setTimeout was causing *extreme* slow-down
	drawText({ text, x, y, color, permanent, destroyAfterSeconds, scale }: DrawText) {
		if (!debugConfig.debug && !debugConfig.disableExpensiveDrawCallWarnings) {
			logger.warn('WARNING: Calling very expensive (i.e. hitch inducing) drawText in renderer! This should not happen frequently.')
		}
		if (clientConfig.debug) {
			this.drawTextDirect(text, x, y, color, permanent, destroyAfterSeconds, this.debugMiddleground, scale)
		}
	}

	drawTextDirect(text, x, y, color, permanent, destroyAfterSeconds, parentContainer, scale = 1) {
		if (!debugConfig.debug && !debugConfig.disableExpensiveDrawCallWarnings) {
			logger.warn('WARNING: Calling very expensive (i.e. hitch inducing) drawTextDirect in renderer! This should not happen frequently.')
		}
		const pixiText = new PIXI.Text(text, {
			fontFamily: 'Arial',
			fontSize: 20 * scale,
			fill: [color, color],
			stroke: 0x000000,
			strokeThickness: 3,
			align: 'left',
		})

		pixiText.name = 'text'
		pixiText.position.x = x
		pixiText.position.y = y
		parentContainer.addChild(pixiText)

		if (!permanent) {
			setTimeout(() => {
				parentContainer.removeChild(pixiText)
				// while leaving game, this text can already be destroyed and throw exception on null `_texture`
				if (pixiText.texture) {
					pixiText.destroy()
				}
			}, destroyAfterSeconds * 1000)
		}
	}

	/** returns a debug pixi Graphics object to use for drawing, that will be destroyed in destroyAfterSeconds, unless permanent */
	private getDebugGraphics(destroyAfterSeconds, permanent) {
		const cacheKey = destroyAfterSeconds + Time.currentGameFrameNumber * 1000000
		let graphics = this.debugGraphicsCache.get(cacheKey)
		if (!graphics) {
			graphics = new Graphics()

			this.debugMiddleground.addChild(graphics)
			this.debugGraphicsCache.set(cacheKey, graphics)

			if (!permanent) {
				setTimeout(() => {
					this.debugMiddleground?.removeChild(graphics)
					graphics.destroy()
					this.debugGraphicsCache.delete(cacheKey)
				}, destroyAfterSeconds * 1000)
			}
		}
		return graphics
	}

	static shutdown() {
		const renderer = Renderer.instance

		if (renderer) {
			renderer._shutdown()
		}

		Renderer.instance = null
	}

	private _shutdown() {
		const canvas: HTMLCanvasElement = document.getElementById('main-canvas') as HTMLCanvasElement
		canvas.removeEventListener('webglcontextlost', analyticsWebglContextLost_SendOnce)

		this.stage.destroy({ children: true, texture: false, baseTexture: false })

		window.removeEventListener('wheel', this.handleWheelEvent.bind(this))
		//@ts-ignore
		window.removeEventListener('resize', window.onGameWindowResize)

		this.bgRenderer?.shutdown()

		this.pixiRenderer.destroy(true)

		const cache = PIXI.utils.BaseTextureCache

		logger.info(`BaseTextureCache:`, Object.keys(cache).length)

		this.mgRenderer.shutdown()

		this.beamManager.shutdown()

		clearObjectRefs(this)
	}

	private drawShapes(param: DrawShapesMessage) {
		const { commands, n1, n2, n3, n4, color, permanent, destroyAfterSeconds } = param

		if (!debugConfig.debug && !debugConfig.disableExpensiveDrawCallWarnings) {
			logger.warn('WARNING: Calling very expensive (i.e. hitch inducing) drawLine in renderer! This should not happen frequently.')
		}
		if (clientConfig.debug) {
			const graphics = new Graphics()
			graphics.name = 'shapes'

			let commandStart = 0
			for (let ci = 0; ci < commands.length; ci++) {
				const numcommands = commands[ci]
				for (let i = commandStart; i < +commandStart + numcommands; i++) {
					graphics.lineStyle(2, color[i])
					if (ci === 0) {
						graphics.moveTo(n1[i], n2[i])
						graphics.lineTo(n3[i], n4[i])
					} else if (ci === 1) {
						graphics.drawCircle(n1[i], n2[i], n3[i])
					} else if (ci === 2) {
						graphics.drawRect(n1[i], n2[i], n3[i], n4[i])
					}
				}
				commandStart += numcommands
			}
			this.debugMiddleground.addChild(graphics)

			if (!permanent[0]) {
				setTimeout(() => {
					this.debugMiddleground?.removeChild(graphics)
					graphics.destroy()
				}, destroyAfterSeconds[0] * 1000)
			}
		}
	}

	private drawDebugGrids(worldWidth: gameUnits, worldHeight: gameUnits, background: Container) {
		background.addChild(new BackgroundGridVisual(worldWidth, worldHeight))
		background.addChild(new SpatialGridVisual(worldWidth, worldHeight))
	}

	private onGameWindowResize(): void {
		this.updateZoomLevel(getZoomLevelFromWindowWidth(), true)
	}
}

/**
 * monkey patch Program.from to log out the shader source
 */
function setupLogShaderCreates() {
	if (shaderLogState === ShaderLogState.Uninitialized) {
		shaderLogState = ShaderLogState.Disabled
		const programCache = PIXI.utils.ProgramCache
		const old = PIXI.Program.from
		PIXI.Program.from = (vertexSrc, fragmentSrc, name) => {
			// PFX_SHADER_NAME gets loaded immediately upon load, so ignore that one
			if (shaderLogState !== ShaderLogState.Disabled && name !== PFX_SHADER_NAME) {
				const key = vertexSrc + fragmentSrc
				const program = programCache[key]
				if (!program) {
					if (debugConfig.render.logShaderCreates === DebugLogLevel.Verbose) {
						console.warn(`name:${name}\n--vertex-start--${vertexSrc}--vertex-end--\n--fragment-start--${fragmentSrc}--fragment-end--`)
					} else {
						console.warn(`compiling shader during gameplay, name:${name}`)
					}
				}
			}
			return old(vertexSrc, fragmentSrc, name)
		}
	}
}
enum ShaderLogState {
	Uninitialized,
	Disabled,
	Enabled,
}
let shaderLogState: ShaderLogState = ShaderLogState.Uninitialized

function getAllWeaponTextures() {
	const textures = Array.from(WeaponSubTypeAssetName.values())
		.map((weapon) => AssetManager.getInstance().getAssetByName(weapon))
		.map(getSpineTexture)
	return textures
}

function getAllTextures() {
	const textures: Set<PIXI.Texture> = new Set()
	debugIterateAssetDefinitionList((assetInfo) => {
		const asset = AssetManager.getInstance().getAssetByName(assetInfo.name)
		let texture = asset.texture
		if (texture) {
			textures.add(texture)
		} else if (asset.spineData) {
			texture = getSpineTexture(asset)
			textures.add(texture)
		}
	})
	return Array.from(textures)
}

export function getZoomLevelFromWindowWidth() {
	if (debugConfig.enableBigBiomeView) {
		return (1 / debugConfig.bigBiomeViewMultiplier) || 0.25
	}
	const w = window.innerWidth

	const { maxRes, normalRes, minRes, minZoom, normalZoom, maxZoom } = zoomLevels

	let zoom = 0
	if (w >= 1920) {
		zoom = mapToRange(w, normalRes, maxRes, normalZoom, maxZoom, true)
	} else {
		zoom = mapToRange(w, minRes, normalRes, minZoom, normalZoom, true)
	}

	return zoom
}
