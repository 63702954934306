import nengi from 'nengi'
import { uuid } from '../../utils/primitive-types'

class PreviewAugmentsDataMessage {
	static protocol = {
		itemId: { type: nengi.String },
		tooltipData: { type: nengi.String },
	}

	itemId: uuid
	tooltipData: string

	constructor(itemId: uuid, tooltipData: string) {
		this.itemId = itemId
		this.tooltipData = tooltipData
	}
}

export default PreviewAugmentsDataMessage
