import nengi from 'nengi'

class SwitchWeapon {
	static protocol = {
		newSlot: nengi.String,
	}
	newSlot: string
	constructor(newSlot: string) {
		this.newSlot = newSlot
	}
}

export default SwitchWeapon
