import { BuffIdentifier } from './buff.shared'
import { PlayerBuffs } from './player-buffs'
import { EnemyBuffs } from './enemy-buffs'
import { BuffDefinition } from './buff-definition'
import { StackStyle } from './buff-enums'
import { some } from 'lodash'
import { isNullOrUndefined } from 'util'
import { GenericBuffs } from './buff-definitions'

class BuffData {
	static map = new Map<BuffIdentifier, BuffDefinition>()

	static initializeBuffMapData() {
		GenericBuffs.forEach(setBuff)
		PlayerBuffs.forEach(setBuff)
		EnemyBuffs.forEach(setBuff)

		Object.freeze(this.map)

		this.map.forEach(validateBuffDefinition)
	}
}

function validateBuffDefinition(buffDef: BuffDefinition) {
	if (buffDef.stackStyle === StackStyle.None) {
		if (some([buffDef.reapplyStacks, buffDef.reapplyDuration], !isNullOrUndefined)) {
			throw new Error(`Invalid buff definition for ${buffDef.identifier}: superfluous reapplyX fields on a non-stacking buff.`)
		}
	}
	if (buffDef.stackStyle === StackStyle.IncreaseDuration || buffDef.stackStyle === StackStyle.RefreshDuration || buffDef.stackStyle === StackStyle.RollingStackDurationSeparately) {
		if (some([buffDef.reapplyStacks, buffDef.reapplyDuration], isNullOrUndefined)) {
			throw new Error(`Invalid buff definition for ${buffDef.identifier}, missing reapplyX fields on a stacking buff.`)
		}
	}
	//TODO2: consider adding checks for if we have an applyFn() but not a wearOffFn() and vice versa
	//TODO2: can we add checks to make sure we're properly removing any added statList bonuses?
}

function setBuff(buffDef: BuffDefinition) {
	if (BuffData.map.get(buffDef.identifier)) {
		throw new Error(`buff with identifier:${buffDef.identifier} has already been added`)
	}
	BuffData.map.set(buffDef.identifier, buffDef)
}

export default BuffData
