import nengi from 'nengi'

export default class BiomeTransitionMessage {
	static protocol = {
		oldBiomeIndex: nengi.Number,
		newBiomeIndex: nengi.Number,
	}

	oldBiomeIndex: number
	newBiomeIndex: number

	constructor(oldBiomeIndex, newBiomeIndex) {
		this.oldBiomeIndex = oldBiomeIndex
		this.newBiomeIndex = newBiomeIndex
	}
}
