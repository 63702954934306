/**
 * NOTE: this file is a very close copy of projectile-system. This was copied to reduce and time and risk for csp.
 * TODOCSP: see if we can use projectile-system on both client and server
 */
import { Vector } from 'sat'
import { ProjectileConfig, ProjectileTargetType, BurstFireModes, BeamConfig, IProjectileShooter, EntityHit } from '../shared/projectile-types'
import { angleInRadsFromVector } from '../../utils/vector'
import { timeInMilliseconds, radians, timeInSeconds, nengiId, gameUnits } from '../../utils/primitive-types'
import WeaponSubType, { WeaponSubTypeChargeData, WeaponSubTypeToResourceMap } from '../../loot/shared/weapon-sub-type'
import { ObjectPool } from '../../third-party/object-pool'
import { IPixiBone } from '../../ai/shared/enemy-model-data'
import { Colors } from '../../utils/colors'
import { debugConfig } from '../../engine/client/debug-config'
import { Vectors, add, sub, VectorXY, vecMoveTowards } from '../../utils/math'
import { BuffIdentifier } from '../../buffs/shared/buff.shared'
import { BinaryFlag } from '../../engine/shared/game-data/stat-type-mod-category'
import { ClientProjectile } from './projectile.client'
import { ClientPlayerProjectileShooter } from "../../player/client/client-player-projectile-shooter"
import { clientDebug } from '../../debug/client/debug-client'
import { ResourceBarMode } from '../../loot/shared/resource-bar-mode'
import playerModelData from '../../../assets/sprites/player-skins/player.json'
import { ClientProjectilesCsp } from '../../player/client/client-projectiles-csp'
import { NengiClient } from '../../engine/client/nengi-client'
import { ProjectileCreateCommand } from '../shared/projectile-create-command'
import { getReleasePosition, getWeaponOffset } from '../shared/projectile-functions'
import p5 from '../../collision/shared/p5.collide2d'
import WeaponFiredMessage from '../shared/weapon-fired-message'
import Renderer from '../../engine/client/graphics/renderer'
import { ParticleEffectType } from '../../engine/shared/game-data/particle-config'
import { elementToMuzzlePfxMap, getBeamColorFromDamageType } from '../../combat/shared/damage.shared'
import { entries, map } from 'lodash'
import { ClientBeam } from '../../beams/client/beam.client'
import { Beam } from '../../beams/shared/beam.shared'
import { BeamCreateUpdateCommand } from '../shared/beam-create-update-command'
import { CircleCollider, ColliderTraits } from '../../collision/shared/colliders'
import { ClientBuff } from '../../buffs/client/buff.client'
import { ProjectileConfigId } from '../shared/projectile-config-ids'

interface ActiveBurst {
	shotCount: number
	initialAimAngle: Vector
	burstSign: number
}

interface ActiveBeam {
	// this differs from server ActiveBeam, direct ref
	beam: ClientBeam
	timeActive: timeInMilliseconds
}

class ClientProjectileSystem {
	//temporaryProjectileQueue: Array<{ owningEntity: IProjectileShooter; targetType: ProjectileTargetType; spawnPos: Vector; spawnTrajectory: Vector; baseTrajectory: Vector; projectileConfig: ProjectileConfig; damageScalar: percentage; entitiesHit: EntityHit[]; ignoreCollided: number[] }> = []
	private players: Map<number, ClientPlayerProjectileShooter>
	//private enemies: Map<number, ClientEnemy>
	private projectiles: Map<number, ClientProjectile>
	private beams: Map<number, ClientBeam>
	//private spatialProjectiles: ProjectileSpatialGrid
	private activeBursts: Map<number, ActiveBurst>
	private activeBeams: Map<nengiId, ActiveBeam>
	private projectilePool: ObjectPool
	private lastProjectileId = 0

	constructor(player: ClientPlayerProjectileShooter, projectiles: Map<number, ClientProjectile>, beams: Map<number, ClientBeam>) {
		this.players = new Map()
		this.players.set(player.nid, player)
		this.projectiles = projectiles
		this.beams = beams
		this.projectilePool = new ObjectPool(() => new ClientProjectile(), undefined, 8192, 128, 'projectile', debugConfig.pooling.checkSomeCanonicals)

		this.activeBursts = new Map<number, ActiveBurst>()
		this.activeBeams = new Map<nengiId, ActiveBeam>()
	}

	update(delta: timeInSeconds): void {
		this.players.forEach((player: ClientPlayerProjectileShooter) => {
			if (player.currentAttackCooldown > 0) {
				player.currentAttackCooldown -= delta
			}

			const curWeapon = player.getCurrentWeapon()

			if (debugConfig.projectiles.drawReleasePoint && curWeapon.itemSubType) {
				const weaponOffset = getWeaponOffset(playerModelData.bones, curWeapon)
				const aimAngle = angleInRadsFromVector(player.useFixedAimVector ? player.fixedAimVector : player.aimVector)
				const pos = this.getReleasePosition(player, playerModelData.bones as any, weaponOffset, aimAngle, false)
				clientDebug.drawCircle(pos, 10, Colors.green)
			}

			const weaponSubType = curWeapon.itemSubType

			const playerBarMode = WeaponSubTypeToResourceMap.get(weaponSubType)
			if ((player.shooting && playerBarMode === ResourceBarMode.ENERGY_COST_SHOT) || (player.hasMouseUp && playerBarMode === ResourceBarMode.CHARGE_UP_SHOT)) {
				if (player.canShoot()) {
					this.burstPlayerProjectile(player)
				} else {
					if (player.hasMouseUp && !player.inSafeZone && !player.isGhost) {
						this.assembleAndSendPlayerWeaponFiredMessage(player, false)
					}
				}

				player.hasMouseUp = false //jank because input updates faster than projectile-system
			}
		})

		// this.enemies.forEach((enemy: ClientEnemy) => {
		// 	enemy.currentAttackCooldown = Math.max(0, enemy.currentAttackCooldown - delta)

		// 	if (debugConfig.projectiles.drawReleasePoint && enemy.playersNearby.length > 0) {
		// 		const weaponOffset = enemy.attackOffset
		// 		const aimAngle = angleInRadsFromVector(enemy.useFixedAimVector ? enemy.fixedAimVector : enemy.aimVector)
		// 		// WARNING: this is not quite accurate for all attackOffset modes... needs rethink
		// 		const pos = this.getReleasePosition(enemy, enemy.modelData.bones, weaponOffset, aimAngle, false)
		// 		debugDrawCircle(pos, 15, Colors.red)
		// 		debugDrawLine(enemy, add(enemy, scale(enemy.aimVector, 100)), 0xff0000)
		// 	}

		// 	if (enemy.shooting) {
		// 		this.burstEnemyProjectile(enemy)
		// 	} else {
		// 		enemy.useFixedAimVector = false
		// 		if (this.activeBursts.has(enemy.nid)) {
		// 			this.activeBursts.delete(enemy.nid)
		// 		}
		// 	}
		// })

		this.projectiles.forEach((projectile) => {
			if (!projectile.skipNextUpdate) {
				projectile.update(delta, true)
			}
			projectile.skipNextUpdate = false

			if (projectile.toBeDeleted) {
				this.removeProjectile(projectile)
			}
		})

		this.getActiveBeams().forEach((beam) => {
			beam.timeSinceDamage += delta
		})

		// this.temporaryProjectileQueue.forEach((queuedProjectile) => {
		// 	const { owningEntity, targetType, spawnPos, spawnTrajectory, baseTrajectory, projectileConfig, damageScalar, entitiesHit, ignoreCollided } = queuedProjectile
		// 	if (!owningEntity) {
		// 		return
		// 	}
		// 	const projectile = this.addProjectile(owningEntity, targetType, spawnPos, spawnTrajectory, baseTrajectory, projectileConfig, entitiesHit)
		// 	projectile.damageInstance = projectile.getDamageInstance()
		// 	projectile.damageInstance.multiplyDamage(damageScalar)
		// 	projectile.ignoreCollided = ignoreCollided

		// 	CollisionSystem.getInstance().addOneFrameLargeProjectile(projectile)
		// })
		// this.temporaryProjectileQueue.length = 0
	}

	removeProjectile(projectile: ClientProjectile) {
		this.projectiles.delete(projectile.nid)
		//this.spatialProjectiles.removeEntity(projectile)
		//removeNengiEntity(projectile)
		this.projectilePool.free(projectile)
	}

	assembleAndSendPlayerWeaponFiredMessage(player: ClientPlayerProjectileShooter, success: boolean) {
		//console.log('assembleAndHandlePlayerWeaponFiredMessage', success)
		const bones = playerModelData.bones as IPixiBone[]
		const projectileConfig: ProjectileConfig = player.getCurrentActiveWeaponSlot()
		const weaponOffset = getWeaponOffset(bones, player.getCurrentWeapon())
		const aimAngle = angleInRadsFromVector(player.useFixedAimVector ? player.fixedAimVector : player.aimVector)
		const initialPos = this.getReleasePosition(player, bones, weaponOffset, aimAngle, (player as IProjectileShooter).ignoreAngleOnWeaponOffset)

		this.handleWeaponFired(player, projectileConfig, initialPos, aimAngle, success)
	}

	handleWeaponFired(shooter: IProjectileShooter, projectileConfig: ProjectileConfig, initialPos: Vector, aimAngle: number, success: boolean) {
		const muzzleFlairPfx = shooter instanceof ClientPlayerProjectileShooter ? getMuzzleFlairPfx(shooter, projectileConfig) : projectileConfig.muzzleFlairParticleEffect

		const message = new WeaponFiredMessage({
			x: shooter.x,
			y: shooter.y,
			aimAngle,
			owningEntityId: shooter.nid,
			attackCooldown: shooter.currentAttackCooldown,
			muzzleFlairX: initialPos.x,
			muzzleFlairY: initialPos.y,
			muzzleFlairParticleEffect: muzzleFlairPfx,
			success,
		})

		if (shooter != null && shooter instanceof ClientPlayerProjectileShooter) {
			shooter.handleWeaponFiredMessage(message)
		}
		Renderer.getInstance().handleWeaponFiredMessage(message, true)
	}

	getActiveBeams(): ClientBeam[] {
		return map(entries(this.activeBeams), ([_, beam]) => beam.beam)
	}

	// TODOCSP addGroundHazard
	// addGroundHazard(owningEntity: IProjectileShooter, targetType: ProjectileTargetType, spawnPos: Vector, spawnTrajectory: Vector, projectileConfig: ProjectileConfig, damageScalar: percentage, entitiesHit: EntityHit[], ignoreCollided: number[], setDefaultValues?: boolean) {
	// 	if (setDefaultValues) {
	// 		projectileConfig.lifespanInSeconds = PROJECTILE_SPLASH_DAMAGE_CHILD_DURATION / 1000
	// 		projectileConfig.speed = 0
	// 		projectileConfig.splashDamage = 0
	// 		projectileConfig.splashRadius = 0
	// 		projectileConfig.pierceCount = 9999
	// 	}

	// 	spawnPos = spawnPos.clone()
	// 	spawnTrajectory = spawnTrajectory.clone()

	// 	//debugDrawLabelledCircle(spawnPos, projectileConfig.colliderRadius, 'hazard', 0x880000, true, 1)
	// 	this.temporaryProjectileQueue.push({ owningEntity, targetType, spawnPos, spawnTrajectory, baseTrajectory: spawnTrajectory, projectileConfig, damageScalar, entitiesHit, ignoreCollided })
	// }

	projectileCount() {
		return this.projectiles.size
	}

	addProjectile(owningEntity: IProjectileShooter, targetType: ProjectileTargetType, spawnPos: Vector, spawnTrajectory: Vector, baseTrajectory: Vector, projectileConfig: ProjectileConfig, entitiesHit?: EntityHit[], chargePercent?: number, weaponType?: WeaponSubType, flip: boolean = false) {
		// TODOCSP spatialProjectiles
		//this.spatialProjectiles.clampToWorldBounds(spawnPos)

		if (debugConfig.cspConfig.disableClientAdd) {
			return
		}

		if (debugConfig.cheating.shotDistOffset) {
			const cheatOffset = new Vector(spawnTrajectory.x, spawnTrajectory.y).normalize().scale(debugConfig.cheating.shotDistOffset)
			spawnPos = spawnPos.clone().add(cheatOffset)
		}

		const clientProjectile = this.makeProjectile(owningEntity, targetType, spawnPos, spawnTrajectory, baseTrajectory, projectileConfig, chargePercent, weaponType, flip)
		clientProjectile.projectileId = ++this.lastProjectileId

		if (!debugConfig.cspConfig.disableClientAddMessage) {
			clientProjectile.speed *= debugConfig.cspConfig.clientSpeedAdjust
			NengiClient.getInstance().sendCommand(new ProjectileCreateCommand(clientProjectile.projectileId, targetType, spawnPos, spawnTrajectory, baseTrajectory, chargePercent, weaponType, flip))
		}

		clientProjectile.speed *= debugConfig.cspConfig.clientSpeedAdjust

		if (entitiesHit !== undefined) {
			clientProjectile.entitiesHit = entitiesHit
		}

		// //start the 1st projectile collider capsule at the players body
		const intersection = this.getInitialProjectilePreviousPos(owningEntity, spawnPos, spawnTrajectory)
		const collider = clientProjectile.collider
		// //move the collider slightly forward so that large radii colliders are not hitting behind the player
		collider.prevPosition = vecMoveTowards(intersection, spawnPos, collider.r)

		// ignorePropBehindThisFrame: fix for https://sculpin.atlassian.net/browse/SOTI-2435
		// on 2nd though, I'm setting this to false to fix: https://sculpin.atlassian.net/browse/SOTI-3656
		// 2345 is not as bad after the 50% player-proj vs prop change we made
		// TODOCSP ignorePropThisFrame
		// collider.ignorePropThisFrame = false

		clientProjectile.skipNextUpdate = true

		return clientProjectile
	}

	makeProjectile(owningEntity: IProjectileShooter, targetType: ProjectileTargetType, spawnPos: Vector, spawnTrajectory: Vector, baseTrajectory: Vector, projectileConfig: ProjectileConfig, chargePercent?: number, weaponType?: WeaponSubType, flip: boolean = false) {
		const clientProjectile: ClientProjectile = this.projectilePool.alloc({
			nid: ClientProjectilesCsp.fakeNidForRender--,
			projectileConfigId: ProjectileConfigId.NONE,
			x: spawnPos.x,
			y: spawnPos.y,
			speed: projectileConfig.speed,
			owningEntityId: owningEntity.nid,
			playerOwned: true,
			aimAngleInRads: angleInRadsFromVector(spawnTrajectory),
			colliderRadius: projectileConfig.colliderRadius,
			particleEffect: projectileConfig.bulletParticleEffect,
			bulletTrailParticleEffect: 10001, // TODO
			travelTimeElapsedInSeconds: 0,
			randomMod: 0,
			distanceMod: Math.clamp(spawnTrajectory.len() / 600, 0.05, 1.0), // between 5% and 100% of normal
			initialSpeed: 0,
			reversedTrajectory: false,
			leftRightFlip: flip,
			travelTimeElapsedForWaveFunctions: 0,
			travelTimeElapsedForWaveFunctionsPrev: 0,
			startPos: new Vector(spawnPos.x, spawnPos.y),
			baseTrajectory,
			lifespan: projectileConfig.lifespanInSeconds,
			willReverseTrajectory: false,
			baseColliderRadius: 0,
			mods: projectileConfig.modifiers,

			owningEntity,
			owningEntityType: targetType,
			initialTrajectory: spawnTrajectory,
			projectileConfig,
			chargePercent,
			weaponType,
		})

		clientProjectile.playerOwned = true
		clientProjectile.maxRange = projectileConfig.maxRange
		clientProjectile.startPos = spawnPos

		if (chargePercent > 0 && weaponType !== undefined) {
			const projectileCount = projectileConfig.projectileCount
			const weaponChargeData = WeaponSubTypeChargeData.get(weaponType)
			const sizePenaltyFactor = Math.pow(1 - weaponChargeData.SIZE_PROJECTILE_PENALTY, projectileCount - 1)
			const sizeScale = Math.lerp(weaponChargeData.MIN_SIZE, weaponChargeData.MAX_SIZE * sizePenaltyFactor, chargePercent)
			const speedScale = Math.lerp(weaponChargeData.MIN_SPEED, weaponChargeData.MAX_SPEED, chargePercent)

			clientProjectile.speed = projectileConfig.speed * speedScale
			clientProjectile.colliderRadius = projectileConfig.colliderRadius * sizeScale
		} else {
			clientProjectile.speed = projectileConfig.speed
			clientProjectile.colliderRadius = projectileConfig.colliderRadius
		}

		clientProjectile.collider = new CircleCollider(ColliderTraits.BlockProjectile, clientProjectile.position, clientProjectile.colliderRadius)

		clientProjectile.baseTrajectory = baseTrajectory.clone()

		this.projectiles.set(clientProjectile.nid, clientProjectile)
		// TODOCSP spatialProjectiles
		//this.spatialProjectiles.addEntity(clientProjectile)

		clientProjectile.collider.prevPosition = clientProjectile.position

		owningEntity.madeProjectile(clientProjectile)

		return clientProjectile
	}

	/** From the spawnPos and spawnTrajectory, creates a line and determines where this crosses the players Y axis
	 */
	getInitialProjectilePreviousPos(owningEntity: IProjectileShooter, spawnPos: Vector, spawnTrajectory: Vector) {
		const LINE_EXTENSION = 1000
		const up = Vectors.Up.clone().scale(LINE_EXTENSION)
		const playerAxisP1 = add(owningEntity.position, up)
		const playerAxisP2 = sub(owningEntity.position, up)

		// if pointed directly up or down, it will never cross the y-axis, so give it a small offset
		spawnTrajectory = spawnTrajectory.clone()
		if (spawnTrajectory.x === 0) {
			spawnTrajectory.x = 1
		}

		const spawnLine1 = spawnPos
		const spawnLine2 = sub(spawnPos, spawnTrajectory.normalize().scale(LINE_EXTENSION))

		const intersection = p5.collideLineLineVector(playerAxisP1, playerAxisP2, spawnLine1, spawnLine2, true)
		if (intersection.x === false) {
			return spawnPos
		}
		return intersection
	}

	fireBeam(player: ClientPlayerProjectileShooter, bones: IPixiBone[], beamConfig: BeamConfig, weaponOffset: Vector) {
		// If player is in a safe zone, don't shoot
		if (player.isInSafeZone()) {
			// beam is removed in player.server when entering safezone
			return
		}

		const aimAngle = player.useFixedAimVector ? angleInRadsFromVector(player.fixedAimVector) : -player.aimAngle
		const beamOrigin = this.getReleasePosition(player, bones, weaponOffset, aimAngle, false)

		const width = player.getStat('projectileBeamWidth') as gameUnits

		if (debugConfig.cspConfig.disableClientAdd) {
			return
		}

		let beam: ClientBeam

		// If player was firing last frame
		if (this.activeBeams.has(player.nid)) {
			//   Update beam
			// If player wasn't firing last frame
			const activeBeam = this.activeBeams.get(player.nid)
			beam = activeBeam.beam
			beam.x = beamOrigin.x
			beam.y = beamOrigin.y
			beam.angle = aimAngle
			beam.width = width
			//beam.length = beamConfig.length
			beam.maxLength = beamConfig.length

		} else {
			const color = getBeamColorFromDamageType(player.mainDamageType)

			const baseBeam: Beam = {
				nid: -1,
				owningEntityId: player.nid,
				x: player.x,
				y: player.y,
				offsetX: beamConfig.length * Math.cos(aimAngle),
				offsetY: beamConfig.length * Math.sin(aimAngle),
				angle: aimAngle,
				width: width,
				length: beamConfig.length,
				maxLength: beamConfig.length,
				color: color,
				timeSinceDamage: 1000,
				projectileId: ++this.lastProjectileId
			}
			beam = new ClientBeam(baseBeam)

			/*const damageInst = getDamageFromStatList(player.statList, { roll: true, rollCritical: true, isProjectileDamage: true })
			const color = getBeamColorFromDamageType(damageInst.maxDamageTypeBeforeRoll)
			const beam = new ServerBeam({
				owningEntityId: player.nid,
				x: beamOrigin.x,
				y: beamOrigin.y,
				isStaticEntity: false,
				offsetX: beamConfig.length * Math.cos(aimAngle),
				offsetY: beamConfig.length * Math.sin(aimAngle),
				angle: aimAngle,
				length: beamConfig.length,
				maxLength: beamConfig.length,
				width,
				color,
				damageInst,
				applyBuffsOnHit: undefined,
				onHitFn: undefined,
				timeSinceDamage: 1000,
				creatorItemLevel: player.getCurrentWeapon().level,
				knockback: player.getStat('projectileKnockback'),
				player
			})
			addNengiEntity(beam)*/

			// Register beam
			this.activeBeams.set(player.nid, {
				timeActive: 0,
				beam,
			})

			this.beams.set(beam.nid, beam)

			Renderer.getInstance().registerBeam(beam)

			// TODO2 Weapon fire message
		}

		if (!debugConfig.cspConfig.disableClientAddMessage) {
			NengiClient.getInstance().sendCommand(new BeamCreateUpdateCommand(beam.projectileId, beamOrigin, aimAngle, beamConfig, width))
		}
	}

	removeBeam(playerNid: nengiId) {
		const activeBeam = this.activeBeams.get(playerNid)
		if (activeBeam) {
			Renderer.getInstance().unregisterBeam(activeBeam.beam.nid)
			this.activeBeams.delete(playerNid)
		}
	}

	// TODOCSP burstEnemyProjectile
	// burstEnemyProjectile(enemy: ClientEnemy) {
	// 	const targetType = enemy.projectileConfig?.targetType ?? ProjectileTargetType.PLAYER
	// 	this.burstProjectile(enemy, enemy.modelData.bones, targetType, enemy.projectileConfig, enemy.attackOffset)
	// }

	burstPlayerProjectile(player: ClientPlayerProjectileShooter) {
		const projectileConfig: ProjectileConfig = player.getCurrentActiveWeaponSlot()
		const currentWeapon = player.getCurrentWeapon()
		const weaponSubType = currentWeapon.itemSubType

		// Needed to prevent an issue with referenced values changing on existing projectiles when user switches weapon
		const cloneProjectileConfig = { ...projectileConfig }
		for (let i = 0; i < cloneProjectileConfig.modifiers.length; ++i) {
			cloneProjectileConfig.modifiers[i].value = { ...projectileConfig.modifiers[i].value }
			cloneProjectileConfig.modifiers[i].amplitude = { ...projectileConfig.modifiers[i].amplitude }
			cloneProjectileConfig.modifiers[i].period = { ...projectileConfig.modifiers[i].period }
			cloneProjectileConfig.modifiers[i].radius = { ...projectileConfig.modifiers[i].radius }
			cloneProjectileConfig.modifiers[i].range = { ...projectileConfig.modifiers[i].range }
		}

		const weaponOffset = player.weaponOffset //getWeaponOffset(playerModelData.bones, player.getCurrentWeapon())

		const resourceBarMode = WeaponSubTypeToResourceMap.get(weaponSubType)
		const isEnergyWeapon = resourceBarMode === ResourceBarMode.ENERGY_COST_SHOT
		const chargePercent = isEnergyWeapon ? 0 : player.currentEnergy / player.maxEnergy

		if (currentWeapon.itemSubType === WeaponSubType.Spellsword) {
			const beamLength = player.getStat('projectileBeamLength')
			this.fireBeam(player, playerModelData.bones as any, { length: beamLength }, weaponOffset)
		} else {
			const shotSuccess = this.burstProjectile(player, playerModelData.bones as any, ProjectileTargetType.ENEMY, cloneProjectileConfig, weaponOffset, chargePercent, weaponSubType)
			player.onShot(shotSuccess)

			if (player.wasShooting === false && !shotSuccess && !player.inSafeZone) {
				this.assembleAndSendPlayerWeaponFiredMessage(player, false)
			}
		}

		const buff = player.hasBuff(BuffIdentifier.SkillOverchargedShot)
		if (buff) {
			buff.wearOff()
		}
	}

	burstProjectile(shooter: IProjectileShooter, bones: IPixiBone[], targetType: ProjectileTargetType, projectileConfig: ProjectileConfig, weaponOffset: Vector, chargePercent?: number, weaponType?: WeaponSubType) {
		if (shooter.isInSafeZone()) {
			return false
		}

		if (shooter.currentAttackCooldown > debugConfig.cheating.cooldownReduction) {
			return false
		}

		//logger.debug(`burstProjectile:${shooter.nid} frame:${Time.currentGameFrameNumber}`)

		let ongoingBurst = false
		let finalShotOfBurst = false

		// Set burst info
		if (projectileConfig.burstCount > 1) {
			ongoingBurst = true
			if (this.activeBursts.has(shooter.nid)) {
				const burst = this.activeBursts.get(shooter.nid)
				burst.shotCount++
				//logger.debug(` burst.shotCount:${burst.shotCount}`)

				if (burst.shotCount >= projectileConfig.burstCount - 1) {
					finalShotOfBurst = true
				}
			} else {
				this.activeBursts.set(shooter.nid, {
					shotCount: 0,
					initialAimAngle: shooter.aimVector.clone(),
					burstSign: Math.random() < 0.5 ? 1 : -1,
				})
			}
		}

		// Adjust aim
		if (ongoingBurst) {
			shooter.fixedAimVector = this.modifyAimForBurst(shooter.nid, shooter.aimVector, projectileConfig.burstMode, projectileConfig.burstAngle, projectileConfig.burstCount)

			shooter.useFixedAimVector = true
		} else {
			if (shooter.isPlayer) {
				const player = shooter as ClientPlayerProjectileShooter
				const tres = player.hasBuff(BuffIdentifier.FlagTripleTap)
				const dos = player.hasBuff(BuffIdentifier.FlagDoubleTap)
				if (tres) {
					tres.wearOff()
					if (player.binaryFlagMap.has(BinaryFlag.ON_SHOOT_TRIPLE_TAP)) {
						const clientPlayer = player.getPlayer()
						ClientBuff.apply(BuffIdentifier.FlagDoubleTap, clientPlayer, clientPlayer, 8)
					} else {
						const clientPlayer = player.getPlayer()
						ClientBuff.apply(BuffIdentifier.FlagDoubleTap, clientPlayer, clientPlayer, 5)
					}
				} else if (dos) {
					dos.wearOff()
				} else {
					if (player.binaryFlagMap.has(BinaryFlag.ON_SHOOT_TRIPLE_TAP)) {
						const clientPlayer = player.getPlayer()
						ClientBuff.apply(BuffIdentifier.FlagTripleTap, clientPlayer, clientPlayer, 8)
					} else if (player.binaryFlagMap.has(BinaryFlag.ON_SHOOT_DOUBLE_TAP)) {
						const clientPlayer = player.getPlayer()
						ClientBuff.apply(BuffIdentifier.FlagDoubleTap, clientPlayer, clientPlayer, 5)
					}
				}
			}
		}

		// Fire the projectile
		const angle = angleInRadsFromVector(shooter.useFixedAimVector ? shooter.fixedAimVector : shooter.aimVector)
		const position = this.fireProjectiles(shooter, bones, targetType, projectileConfig, weaponOffset, angle, chargePercent, weaponType)

		// Cleanup
		if (finalShotOfBurst) {
			this.activeBursts.delete(shooter.nid)
		}

		if (!ongoingBurst || finalShotOfBurst) {
			const attackRate: timeInMilliseconds = shooter.getStat('attackRate')
			const attackCooldown = 1000 / attackRate
			shooter.currentAttackCooldown += attackCooldown
			if (projectileConfig.energyCost) {
				shooter.reduceEnergy(projectileConfig.energyCost)
			}

			// if (shooter instanceof ClientEnemy) {
			// 	shooter.onFiredProjectile()
			// }

			shooter.useFixedAimVector = false
		} else {
			shooter.currentAttackCooldown += projectileConfig.burstDelay
		}

		this.handleWeaponFired(shooter, projectileConfig, position, angle, true)

		return true
	}

	// this is required to be member function as it's overwritten in the brain, TODO3: this isn't great
	getReleasePosition(shooter: { position: Vector, aimVector: VectorXY }, bones: IPixiBone[], weaponOffset: Vector, aimAngle: number, ignoreAngleOnWeaponOffset: boolean) {
		return getReleasePosition(shooter, bones, weaponOffset, aimAngle, ignoreAngleOnWeaponOffset)
	}

	fireProjectiles(shooter: IProjectileShooter, bones: IPixiBone[], targetType: ProjectileTargetType, projectileConfig: ProjectileConfig, weaponOffset: Vector, aimAngle: radians, chargePercent?: number, weaponType?: WeaponSubType) {
		const initialTrajectory = shooter.useFixedAimVector ? shooter.fixedAimVector : shooter.aimVector
		const initialPos = this.getReleasePosition(shooter, bones, weaponOffset, aimAngle, shooter.ignoreAngleOnWeaponOffset)

		let spreadScale
		if (chargePercent > 0 && weaponType !== undefined) {
			const chargeData = WeaponSubTypeChargeData.get(weaponType)
			spreadScale = Math.lerp(chargeData.MIN_SPREAD, chargeData.MAX_SPREAD, chargePercent)
		} else {
			spreadScale = 1
		}

		const variance = projectileConfig.spreadVariance
		const angle = Math.clamp(projectileConfig.spreadAngle * spreadScale, 0, Math.PI * 2)

		// if (shooter.isPlayer) {
		// 	PlayerMetricsSystem.getInstance().trackMetric('PROJECTILE_FIRED', shooter.nid, Math.ceil(projectileConfig.projectileCount))
		// }

		const flip = shooter.flipNextShot
		shooter.flipNextShot = !flip

		if (projectileConfig.projectileCount > 1) {
			//TODO2: projectileCount is clamped between 1-9
			let count
			let varySpeed = false
			if (shooter.isPlayer) {
				const player = shooter as ClientPlayerProjectileShooter
				count = Math.clamp(projectileConfig.projectileCount, 1, 9) + debugConfig.cheating.extraProjectiles
				varySpeed = player.binaryFlagMap.has(BinaryFlag.ON_SHOOT_VARY_PROJECTILE_SPEED)
			} else {
				count = projectileConfig.projectileCount
			}
			const interval = angle / (count - (angle === Math.PI * 2 ? 0 : 1))
			const spreadStartTrajectory = initialTrajectory.clone().rotate(-interval * Math.floor(count / 2))

			count--
			let randomVariance = Math.random() * variance - variance / 2
			let spreadTrajectory = spreadStartTrajectory.clone().rotate(interval * count + randomVariance)
			const firstProjectile = this.addProjectile(shooter, targetType, initialPos, spreadTrajectory, initialTrajectory, projectileConfig, undefined, chargePercent, weaponType, flip)
			while (count > 0) {
				count--
				randomVariance = Math.random() * variance - variance / 2
				spreadTrajectory = spreadStartTrajectory.clone().rotate(interval * count + randomVariance)
				const newProj = this.addProjectile(shooter, targetType, initialPos, spreadTrajectory, initialTrajectory, projectileConfig, undefined, chargePercent, weaponType, flip)
				if (varySpeed) {
					newProj.speed = Math.getRandomFloat(newProj.speed * 0.7, newProj.speed * 1.0)
				}
				newProj.entityHitGroupCount = firstProjectile.entityHitGroupCount
			}
		} else {
			const randomVariance = Math.random() * variance - variance / 2
			const spreadTrajectory = initialTrajectory.clone().rotate(randomVariance)
			const proj = this.addProjectile(shooter, targetType, initialPos, spreadTrajectory, initialTrajectory, projectileConfig, undefined, chargePercent, weaponType, flip)
		}

		return initialPos
	}

	modifyAimForBurst(nid: number, aim: Vector, burstMode: BurstFireModes, burstAngle: radians, burstMaxCount: number): Vector {
		if (!this.activeBursts.has(nid)) {
			return aim
		}

		const burst = this.activeBursts.get(nid)

		if (burstMode === BurstFireModes.TRACKING) {
			const target = Math.atan2(aim.y, aim.x)
			const base = Math.atan2(burst.initialAimAngle.y, burst.initialAimAngle.x)
			let angle = target - base

			// M A T H
			const a1 = ((angle + Math.PI) % (Math.PI * 2)) - Math.PI
			const a2 = ((angle - Math.PI) % (Math.PI * 2)) + Math.PI
			angle = Math.abs(a1) < Math.abs(a2) ? a1 : a2
			angle = Math.clamp(angle, -burstAngle, burstAngle)

			burst.initialAimAngle.rotate(angle)
			return burst.initialAimAngle
		} else if (burstMode === BurstFireModes.STRAIGHT) {
			return burst.initialAimAngle
		} else {
			// SWEEPING
			aim.copy(burst.initialAimAngle)
			aim.rotate(burstAngle * -0.5 * burst.burstSign)
			aim.rotate(burstAngle * (burst.shotCount / (burstMaxCount - 1)) * burst.burstSign)
			return aim
		}
	}
}

export function getWeaponReleasePos(player: ClientPlayerProjectileShooter) {
	const curWeapon = player.getCurrentWeapon()

	if (curWeapon.itemSubType) {
		const weaponOffset = getWeaponOffset(playerModelData.bones, curWeapon)
		const aimAngle = angleInRadsFromVector(player.useFixedAimVector ? player.fixedAimVector : player.aimVector)
		const pos = getReleasePosition(player, playerModelData.bones as any, weaponOffset, aimAngle, false)
		return pos
	} else {
		return undefined
	}
}

function getMuzzleFlairPfx(shooter: IProjectileShooter, projectileConfig): ParticleEffectType {
	const player = shooter as ClientPlayerProjectileShooter
	return elementToMuzzlePfxMap[player.mainDamageType]
}

export default ClientProjectileSystem
