import nengi from 'nengi'

class PurchaseMtxResultMessage {
	static protocol = {
		sku: nengi.String,
		success: nengi.Boolean,
		message: nengi.String,
	}

	sku: string
	success: boolean
	message: string

	constructor(sku: string, success: boolean, message: string) {
		this.sku = sku
		this.success = success
		this.message = message
	}
}

export default PurchaseMtxResultMessage
