































































import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import InventoryItem from '../reusable-components/inventory-item/inventory-item.vue'
import DropDown from '../reusable-components/inputs/dropdown.vue'
import TextInput from '../reusable-components/inputs/text-input.vue'
import TextButton from '../reusable-components/buttons/text-button.vue'
import { LISTING_FEE, MAX_LISTING_PRICE, PROFIT_FEE } from '../state/marketplace.ui-state'
import { marketplaceListTooltipText } from '../reusable-components/v-tooltip/v-tooltip-functions'
import { LISTING_FEE_PERCENT } from '../../utils/economy-data'
import AmountSelector from '../reusable-components/buttons/amount-selector.vue'

export default {
	name: 'ListItemPopUp',
	components: {
		MainPanel,
		InventoryItem,
		DropDown,
		TextInput,
		TextButton,
		AmountSelector,
	},
	data() {
		return {
			expiry: '168',
			price: null,
			expiryHours: [
				{ id: '1', name: '1 hour' },
				{ id: '8', name: '8 hours' },
				{ id: '24', name: '1 day' },
				{ id: '72', name: '3 days' },
				{ id: '168', name: '7 days' },
			],
			listingFee: LISTING_FEE,
			profitFee: PROFIT_FEE,
			maxListPrice: MAX_LISTING_PRICE
		}
	},
	computed: {
		...mapState('marketplaceUpdated', ['listItemPopId', 'listItemPopContainer', 'listItemPopDetails','itemPrice', 'numberOfStacks']),
		...mapGetters('hud', ['coinBalance', 'scalesBalance']),

		canUserPayListingFee() {
			let totalPrice = this.selectedItemIsStackable ? this.itemPrice * this.numberOfStacks : this.itemPrice
			const price = parseInt(totalPrice, 10)
			const listingFee = this.getListingFee

			if (price <= 0) {
				return false
			}

			return parseInt(this.scalesBalance, 10) >= listingFee
		},
		getListingFee() {
			if (this.itemPrice <= 0) {
				return 0
			}

			let totalPrice = this.selectedItemIsStackable ? this.itemPrice * this.numberOfStacks : this.itemPrice
			let listingFee = Math.max(1, Math.round(totalPrice * LISTING_FEE_PERCENT))			

			return listingFee
		},
		getListTooltip() {
			if (this.$data.price) {
				const listingFee = this.getListingFee
				const canPayFee = parseInt(this.scalesBalance, 10) >= listingFee
				return marketplaceListTooltipText(canPayFee, listingFee)
			}
			return undefined
		},
		selectedItemIsStackable() {
			return this.listItemPopDetails.stackAmount > 0
		},
		listItemContainerWidth(){
			return this.selectedItemIsStackable ? 'width: 578px;' : 'width: 550px;'
		},
		getTotalPrice() {
			if(this.itemPrice) {
				return this.itemPrice * this.numberOfStacks
			}
			return 0
		}
	},
	methods: {
		...mapActions('marketplaceUpdated', ['submitItemListing']),
		...mapMutations('marketplaceUpdated', ['closeListingPopUp', 'setItemPrice', 'setNumberOfStacks']),
		updatedPrice(newPrice) {
			let price = Math.round(parseInt(newPrice))

			if (isNaN(price)) {
				this.setItemPrice(0)
				return
			}

			if(price > MAX_LISTING_PRICE) {
				this.setItemPrice(MAX_LISTING_PRICE)
				return
			}

			this.setItemPrice(price)
		},
		updateExpiry(event) {
			const newExpiry = event.target.value || '168'
			this.$data.expiry = newExpiry
		},
		listItem() {
			const price = this.itemPrice
			const expiry = this.$data.expiry || '168'
			const stacks = this.selectedItemIsStackable ? this.numberOfStacks : undefined
			this.submitItemListing({ price, expiry, stacks })
			this.$data.expiry = '168'
			this.closeListingPopUp()
		},
		updatedStackAmount(stacks) {
			this.setNumberOfStacks(stacks)
		},

		
	},
}
