import { Vector } from "sat"
import { ResourceBarMode } from "../../loot/shared/resource-bar-mode"
import { rotateV, vecAngle } from "../../utils/math"
import { timeInSeconds } from "../../utils/primitive-types"

export type WeaponSlotKey = 'one' | 'two'

export interface IEnergyUser {
	activeWeaponSlot: WeaponSlotKey
	shooting: boolean
	maxEnergy: number
	maxEnergyOne: number
	maxEnergyTwo: number
	currentAttackCooldown: number
	isCharging: boolean
	inSafeZone: boolean
	weaponOut: boolean
	energyRegenDelay: number
	weaponSlots: {
		one: IWeaponSlotAdapter,
		two: IWeaponSlotAdapter
	}
	getStat(statName: string): number
}

export interface ITurningAimer {
	turnLimit: any
	aimAngle: number
	aimOffset: number
	aimVector: any
}

interface IWeaponSlotAdapter {
	statList: {
		isOnCooldown: boolean
		currentEnergy: number
	}
	item: {
		resourceBarMode: ResourceBarMode
	}
}

export function updateEnergy(player: IEnergyUser, delta: number) {
	const isOnCooldown = player.currentAttackCooldown > 0.05
	const currentSlot = player.weaponSlots[player.activeWeaponSlot] //as StatListAndItem
	if (!isOnCooldown && currentSlot.item.resourceBarMode === ResourceBarMode.CHARGE_UP_SHOT) {
		if (player.shooting && !player.inSafeZone && player.weaponOut) {
			const curWepStat = currentSlot.statList
			// increase the energy of the weapon by our charge rate
			const chargePercentGained = (delta * player.getStat('chargeRate')) / 100
			curWepStat.currentEnergy = Math.clamp(curWepStat.currentEnergy + chargePercentGained * player.maxEnergy, 1, player.maxEnergy)
			player.isCharging = true
		} else {
			player.isCharging = false
		}
	} else {
		player.isCharging = false
	}

	//energy
	const wepOneStat = player.weaponSlots.one.statList

	if (!isOnCooldown) {
		wepOneStat.isOnCooldown = false
		if (player.weaponSlots.two) {
			player.weaponSlots.two.statList.isOnCooldown = false
		}
	}

	const energyAdded = player.getStat('energyRegen') * delta

	if (player.energyRegenDelay > 0) {
		player.energyRegenDelay -= delta
	}

	const canEnergyRegen = player.energyRegenDelay <= 0 && !player.shooting

	if (canEnergyRegen) {
		if (!wepOneStat.isOnCooldown && player.weaponSlots.one.item.resourceBarMode !== ResourceBarMode.CHARGE_UP_SHOT) {
			wepOneStat.currentEnergy = Math.clamp(wepOneStat.currentEnergy + energyAdded, 0, player.maxEnergyOne)
		}

		if (player.weaponSlots.two) {
			const wepTwoStat = player.weaponSlots.two.statList
			if (!wepTwoStat.isOnCooldown && player.weaponSlots.two.item.resourceBarMode !== ResourceBarMode.CHARGE_UP_SHOT) {
				wepTwoStat.currentEnergy = Math.clamp(wepTwoStat.currentEnergy + energyAdded, 0, player.maxEnergyTwo)
			}
		}
	}
	//\energy
}

export function updateAimAngle(player: ITurningAimer, aimX: number, aimY: number, delta: timeInSeconds) {
	aimY += player.aimOffset
	const oldAimVector = player.aimVector
	player.aimVector = new Vector(aimX, aimY)

	if (player.turnLimit) {
		const turned = vecAngle(oldAimVector, player.aimVector)
		//@TODO2: anti-cheat this;
		//could be possible that someone sending more input commands than
		//necessary turns more
		if (Math.abs(turned) > player.turnLimit * delta) {
			if (turned < 0) {
				player.aimVector = rotateV(oldAimVector, -player.turnLimit * delta)
			} else {
				player.aimVector = rotateV(oldAimVector, player.turnLimit * delta)
			}
		}
	}

	player.aimAngle = -Math.atan2(player.aimVector.y, player.aimVector.x)
	return aimY
}