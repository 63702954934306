export const CameraShakeIntensities = {
	VERY_MILD: 0.5, // because SHAKE_EXPONENT is 3, this is extremly mild
	MILD: 0.6,
	AVERAGE: 0.7,
	INTENSE: 0.8,
	VERY_INTENSE: 0.9,
	DEAR_LORD: 1,
}

export interface NengiView {
	x: number
	y: number
	halfWidth: number
	halfHeight: number
}
