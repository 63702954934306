import { EnemyAI, FightingTriggers, IdleBehaviours, FightingBehaviours, LeashTriggers, LeashingBehaviours, AttackTypes, DeadBehaviours, ShotLeadPrecision, EnemyType, EnemyTargetingStyles } from '../../shared/ai-types'
import { BurstFireModes } from '../../../projectiles/shared/projectile-types'
import { SpineDataName, EnemyBipedSkinType } from '../../../models-animations/shared/spine-config'
import { EnemyDefaults } from './enemy-defaults'
import { Colors } from '../../../utils/colors'
import { ParticleEffectType } from '../../../engine/shared/game-data/particle-config'
import { Vector } from 'sat'
import { ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { ENEMY_DEFAULT_HEALTH } from '../../../engine/shared/game-data/enemy-formulas'
import { alwaysTrueCriteria } from './abilities/common-abilities'
import { Ability, AbilitySelectionStyles, AbilityTargets, AbilityTargetSelectionStyle, AbilityType } from '../action-types'
import { attackRatedAbilityList } from '../action-criteria/ability-helpers'
import { AnimationTrack } from '../../../models-animations/shared/animation-track'
import { ENEMY_NAMES } from './enemy-names'

const weakCrabAttackAbility: Ability = {
	debugName: 'weakCrabAttackAbility',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.CLOSEST,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		speed: 600,
		colliderRadius: 10,
		basePhysicalDamage: 25,
		lifespanInSeconds: 1.5,
		burstMode: BurstFireModes.STRAIGHT,
		color: Colors.darkRed,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_LONG_SPELL_SMALL,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
	},
}

const animationTimes = {
	[AnimationTrack.SHOOT]: 0.3,
}

const weakTinyCrab: EnemyAI = {
	name: ENEMY_NAMES.WEAK_TINY_CRAB,
	type: EnemyType.BASIC,
	appearance: {
		asset: SpineDataName.CRAB,
		skin: EnemyBipedSkinType.DEFAULT,
		mixSettings: [],
		scale: 0.75,
	},
	general: {
		enemyLevel: 1,
		experienceWhenKilled: 0.35,
	},
	baseAttributes: {
		...EnemyDefaults.baseAttributes,
		visibilityRadius: 1,
		colliders: [
			{
				type: ColliderType.Circle,
				radius: 32,
				traits: ColliderTraits.BlockMovement,
				position: [0, 0],
			},
			{
				type: ColliderType.Circle,
				radius: 48,
				traits: ColliderTraits.BlockProjectile,
				position: [0, -10],
			},
		],
		movementSpeed: 200,
		decelerationRate: 10,
		turningRatePerSecondInDegrees: 300,
		attackOffset: new Vector(0, 0),
		lootDropOffset: new Vector(0, 0),
		damageConfig: null,

		physicalDamage: 8,
		fireDamage: 0,
		iceDamage: 0,
		lightningDamage: 0,
		poisonDamage: 0,
		critChance: 0,
		critDamage: 1.0,

		maxHealth: (ENEMY_DEFAULT_HEALTH * 3) / 4,
		defense: 0,
		fireResist: 0,
		poisonResist: 0,
		iceResist: 0,
		lightningResist: 0,

		lootDropProbability: 1,
		lootDropQuantity: 1,
		lootDropRarity: 1,
	},
	soundEffects: {
		attack: 'Projectile_Zap',
		impact: 'SFX_Impact_Enemy_Mush',
	},
	states: {
		idling: {
			behaviour: IdleBehaviours.MEANDER,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 2,
			transitionToFighting: {
				trigger: FightingTriggers.AGGRESSIVE_PROXIMITY,
				radius: 900,
			},
			meander: {
				radius: 500,
				speedMultiplier: 1,
				minRestTime: 0.5,
				maxRestTime: 10,
			},
			aggroOnHit: true,
		},
		fighting: {
			behaviour: FightingBehaviours.CHASE_WHILE_ATTACKING,
			targetingStyle: EnemyTargetingStyles.HIGH_HEALTH,
			targetingCheckSeconds: 0,
			attackType: AttackTypes.PROJECTILE,
			engagementMaxDistance: 700,
			engagementMinDistance: 0,
			modelCenterOffset: 0,
			movementMaxDistance: 200,
			movementMinDistance: 0,
			brain: {
				actions: [
					{
						// BLOCK/ATTACK
						priority: 0,
						actionCriteria: alwaysTrueCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							//abilityOptions: [[1,  crabMeleeAttackAbility, 15]],
							abilityOptions: [attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, weakCrabAttackAbility, 30)],
						},
					},
				],
			},
			attackOptions: [],
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 0.1,
			transitionToLeashing: {
				trigger: LeashTriggers.RADIUS_EXCEEDED,
				radius: 2000,
				distanceTravelled: 4000,
			},
		},
		leashing: {
			behaviour: LeashingBehaviours.MOVE,
			speedMultiplier: 4,
		},
		fleeing: {},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 1,
		},
	},
}

export default weakTinyCrab
