<template>
	<div class="sub-category-filter">
		<div class="filters">
			<div v-show="subCategoryType == 'disable'" class="filter-set"></div>
			<div v-show="subCategoryType == 'rarity'" class="filter-set rarity-filter">
				<div class="squiggle"></div>
				<div :class="{ activeRarity: activeRarityFilter === 'all', disabled: isMarketplaceLoading }" class="filter all" @click="updateRarityFilter('all')">
					<span class="filter-icon-rarity filter-panel-rarity">All</span>
				</div>
				<div :class="{ activeRarity: activeRarityFilter === 'common', disabled: isMarketplaceLoading }" class="filter" @click="updateRarityFilter('common')">
					<span class="filter-icon-rarity common"></span>
				</div>
				<div :class="{ activeRarity: activeRarityFilter === 'uncommon', disabled: isMarketplaceLoading }" class="filter" @click="updateRarityFilter('uncommon')">
					<span class="filter-icon-rarity uncommon"></span>
				</div>
				<div :class="{ activeRarity: activeRarityFilter === 'rare', disabled: isMarketplaceLoading }" class="filter" @click="updateRarityFilter('rare')">
					<span class="filter-icon-rarity rare"></span>
				</div>
				<div :class="{ activeRarity: activeRarityFilter === 'epic', disabled: isMarketplaceLoading }" class="filter" @click="updateRarityFilter('epic')">
					<span class="filter-icon-rarity epic"></span>
				</div>
				<div :class="{ activeRarity: activeRarityFilter === 'legendary', disabled: isMarketplaceLoading }" class="filter" @click="updateRarityFilter('legendary')">
					<span class="filter-icon-rarity legendary"></span>
				</div>
				<div :class="{ activeRarity: activeRarityFilter === 'astronomical', disabled: isMarketplaceLoading }" class="filter" @click="updateRarityFilter('astronomical')">
					<span class="filter-icon-rarity astronomical"></span>
				</div>
				<div class="squiggle right-squiggle"></div>
			</div>
			<div v-show="subCategoryType == 'panel-divider-text'" class="filter-set">
				<PanelDivider :divider-text="'Buy awesome things to help you out!'"></PanelDivider>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import PanelDivider from '../panel-divider/panel-divider.vue'
export default {
	name: 'SubCateoryFilter',
	components: {
		PanelDivider,
	},
	props: {
		subCategoryType: {
			type: String,
			required: true,
			default: 'disable',
		},
	},
	computed: {
		...mapState('itemContainers', ['activeRarityFilter']),
		...mapGetters('inGame', ['activePanel']),
		...mapGetters('marketplaceUpdated', ['getLoadingInProgress']),
		isMarketplaceLoading() {
			if (this.activePanel === 'marketplaceUpdated') {
				return this.getLoadingInProgress
			}
		},
	},
	methods: {
		...mapMutations('itemContainers', ['updateRarityFilter']),
	},
}
</script>

<style lang="less" scoped>
@import '../../global/fonts/text.less';
@import '../../global/bg-gradients.less';

.sub-category-filter {
	.filters {
		display: flex;
		flex-direction: column;
		.filter-set {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			align-items: center;
			.squiggle {
				background-image: url('../panel-divider/assets/rune-icon-main.png');
				height: 33px;
				width: 50px;
				transform: scale(0.75);
				&.right-squiggle {
					transform: scale(-0.75, 0.75);
				}
			}
			.filter {
				padding: 0 5px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 1px;
				flex: 1;
				color: #446ef6;
				.button-bg-gradient();

				//default
				.filter-icon-rarity {
					height: 30px;
					width: 29px;
					background-size: cover;
					background-repeat: no-repeat;
					display: flex;
					align-items: center;
					justify-content: center;

					&.weapon {
						background-image: url('../item-containers/assets/weapons-icon.png');
					}
					&.common {
						background-image: url('../inventory-item/assets/item-bg-common.png');
					}
					&.uncommon {
						background-image: url('../inventory-item/assets/item-bg-uncommon.png');
					}
					&.rare {
						background-image: url('../inventory-item/assets/item-bg-rare.png');
					}
					&.epic {
						background-image: url('../inventory-item/assets/item-bg-epic.png');
					}
					&.legendary {
						background-image: url('../inventory-item/assets/item-bg-legendary.png');
					}
					&.astronomical {
						background-image: url('../inventory-item/assets/item-bg-astronomical.png');
					}
					&.chaos {
						background-image: url('../inventory-item/assets/item-bg-chaos.png');
					}
				}
				&.activeRarity {
					color: white;
					background-color: rgba(0, 0, 0, 0);
					background: rgba(0, 0, 0, 0);
					.filter-name {
						display: flex;
						.filter-panel-rarity();
					}
				}
				&.disabled {
					opacity: 0.5;
				}
			}
		}
	}
}
</style>
