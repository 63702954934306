











































































import { mapActions, mapState, mapMutations } from 'vuex'
import ArrowButton from '../reusable-components/buttons/arrow-button.vue'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import TextInput from '../reusable-components/inputs/text-input.vue'

export default {
	name: 'ForgotPassword',
	components: {
		MainPanel,
		ArrowButton,
		TextInput,
	},
	data() {
		return {
			formError: false,
			email: '',
		}
	},
	computed: {
		...mapState('mainMenu', ['sentResetPassword', 'sendingReset', 'forgotPasswordError']),
	},
	methods: {
		...mapActions('mainMenu', ['sendForgotPassword']),
		...mapMutations('mainMenu', ['changeMainMenuScreen', 'resetPasswordBools']),
	},
}
