import nengi from "nengi"

export enum ClearPartyReason {
	KICKED = 0,
	DESYNC = 1,
}

export default class ClearPartyMessage {
	static protocol = {
		reason: nengi.UInt2
	}

	reason: ClearPartyReason

	constructor(reason: ClearPartyReason) {
		this.reason = reason	
	}
}
