



































import IconButton, { validIconNames } from '../buttons/icon-button.vue'

export default {
	name: 'ArrowButton',
	components: {
		IconButton,
	},
	props: {
		buttonIconName: {
			type: String,
			required: true,
			validator: function(value) {
				return validIconNames.includes(value)
			},
		},
		buttonText: {
			type: String,
			required: true,
			default: function() {
				console.warn('Currently using default arrow Text. Ensure <ArrowLabel /> component has a custom attribute arrow-label-text')
				return 'DEFAULT ARROW LABEL'
			},
		},
		buttonTextTwo: {
			type: String,
			default: '',
			required: false,
		},
		strikethroughTextTwo: {
			type: Boolean,
			default: false,
			required: false,
		},
		buttonTextMiddle: {
			type: String,
			default: '',
			required: false,
		},
		grayTextMiddle: {
			type: Boolean,
			default: false,
			required: false,
		},
		iconName: {
			type: String,
			default: '',
			required: false,
		},
		disabled: {
			type: Boolean,
			required: true,
			default: false,
		},
		loading: {
			type: Boolean,
			required: false,
			default: false,
		},
		onClick: {
			type: Function,
			required: true,
			default: () => {
				console.warn('The default on-click handler for the <ArrowButton /> component has been called. Did you forget to pass an :on-click handler to the component?')
			},
		},
		tooltipTarget: {
			type: String,
			required: false,
			default: '',
		},
		showIcon: {
			type: Boolean,
			default: false,
			required: false,
		},
		useInlineIcon: {
			type: Boolean,
			default: false,
			required: false,
		},
		hoverHighlight: {
			type: Boolean,
			default: false,
			required: false,
		},
	},
	data() {
		return {
			hovered: false,
			active: false,
			slotVariant: `item-variant-${Math.floor(Math.random() * 4) + 1}`,
		}
	},
	methods: {
		onMouseEnter() {
			this.$data.hovered = true
		},
		onMouseLeave() {
			this.$data.hovered = false
		},
		onMouseDown() {
			if (this.disabled) return

			this.$data.active = true

			setTimeout(() => {
				this.$data.active = false
			}, 300)

			this.onClick()
		},
	},
}
