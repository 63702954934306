import nengi from 'nengi'
import { uuid } from '../../utils/primitive-types'

class DestroyItemFromInventoryCommand {
	static protocol = {
		itemId: nengi.String,
	}
	itemId: uuid
	constructor(itemId: uuid) {
		this.itemId = itemId
	}
}

export default DestroyItemFromInventoryCommand
