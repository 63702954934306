import nengi from 'nengi'
import { uuid } from '../../utils/primitive-types'

class BuyAugmentSlotCommand {
	static protocol = {
		weaponId: nengi.String,
	}
	weaponId: uuid
	

	/**
	 * Creates an instance of AttachAugmentSlotToWeaponCommand.
	 * @param {uuid} weaponId Weapon ID
	 * @memberof AttachAugmentSlotToWeaponCommand
	 */

	 constructor(weaponId: uuid) {
		this.weaponId = weaponId
	}
}

export default BuyAugmentSlotCommand