import nengi from 'nengi'
import { ProjectileEffectType } from './projectile-types'

export class ProjectileHitMessage {
	static protocol = {
		x: { type: nengi.Number },
		y: { type: nengi.Number },
		effectType: { type: nengi.Number }, // damage, heal, etc...
		effectAmount: { type: nengi.Number }, // amount of damage, or healing, etc...
		owningEntityId: { type: nengi.Number },
		severity: { type: nengi.Number },
		hitEntityId: { type: nengi.Number },
		particleEffect: { type: nengi.Number },
		damageType: { type: nengi.Number },
	}

	x: number
	y: number
	effectType: ProjectileEffectType
	effectAmount: number
	owningEntityId: number
	hitEntityId: number
	particleEffect: number
	severity: number
	damageType: number

	constructor(params: ProjectileHitMessage) {
		this.x = params.x
		this.y = params.y
		this.owningEntityId = params.owningEntityId
		this.hitEntityId = params.hitEntityId
		this.effectAmount = params.effectAmount
		this.particleEffect = params.particleEffect
		this.effectType = params.effectType
		this.severity = params.severity
		this.damageType = params.damageType
	}
}
