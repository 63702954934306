import Identity from './identity'
import nengi from 'nengi'
import Enemy from '../../ai/shared/enemy.shared'
import Player from '../../player/shared/player.shared'
import PlayerInput from '../../input/shared/player-input'
import ChatCommand from '../../chat/shared/chat-command'
import ChatMessage from '../../chat/shared/chat-message'
import CreateChunk from '../../world/shared/create-chunk'
import DeleteChunk from '../../world/shared/delete-chunk'
import DrawLine from '../../debug/shared/draw-line'
import DrawCircle from '../../debug/shared/draw-circle'
import DrawRectangle from '../../debug/shared/draw-rectangle'
import DrawText from '../../debug/shared/draw-text'
import DrawPolygon from '../../debug/shared/draw-polygon'
import DrawEllipse from '../../debug/shared/draw-ellipse'
import Projectile from '../../projectiles/shared/projectile.shared'
import SwitchWeapon from '../../player/shared/switch-weapon.shared'
import { WorldDataMessage } from '../../world/shared/world-data-response-message'
import GroundItemEntity from '../../items/shared/ground-item-entity.shared'
import EquippedNewGearMessage from '../../items/shared/equipped-new-gear-message'
import HideEntityMessage from './hide-entity-message'
import WeaponFiredMessage from '../../projectiles/shared/weapon-fired-message'
import SkillUsedMessage from '../../gear-skills/shared/skill-used-message'
import TerrainProp from '../../world/shared/terrain-prop.shared'
import ForegroundProp from '../../world/shared/foreground-prop.shared'
import PlayerDiedMessage from '../../player/shared/player-died-message'
import { ProjectileHitMessage } from '../../projectiles/shared/projectile-hit-message'
import EnemyDiedMessage from '../../ai/shared/enemy-died-message'
import GenericEntity from '../../ai/shared/generic-entity.shared'
import POI from '../../world/shared/poi.shared'
import PlayerStatsMessage from '../../player/shared/player-stats-message'
import PopUIScreen from '../../player/shared/pop-ui-screen'
import NPC from '../../ai/shared/npc.shared'
import PlayerStartShieldRegenMessage from '../../player/shared/player-start-shield-regen-message'
import PlayerUpdateShieldMessage from '../../player/shared/player-update-shield-message'
import SendItemContainerMessage from '../../items/shared/send-item-container-message'
import EquipWeaponToSlotCommand from '../../items/shared/equip-weapon-to-slot-command'
import AttachAugmentToWeaponCommand from '../../items/shared/attach-augment-to-weapon-command'
import DetachAugmentFromWeaponCommand from '../../items/shared/detach-augment-from-weapon-command'
import StartSmeltingItemCommand from '../../items/shared/start-smelting-item-command'
import FurnaceStateMessage from '../../items/shared/furnace-state-message'
import ClaimSmeltedItemRewardsCommand from '../../items/shared/claim-smelted-item-rewards-command'
import ColliderEntity from '../../world/shared/collider-entity.shared'
import UpdateDebugOnloadCommandsMessage from '../../debug/shared/update-debug-onload-commands-message'
import BroadcastMessage from '../../chat/shared/broadcast-message'
import BiomeTransitionMessage from '../../biome/shared/biome-transition-message'
import SendItemToWormMailCommand from '../../items/shared/send-item-to-worm-mail-command'
import RemoveItemFromWormMailCommand from '../../items/shared/remove-item-from-worm-mail-command'
import SendWormAwayCommand from '../../items/shared/send-worm-away-command'
import GameModeMessage from './game-mode-message'
import ClientResizedCommand from './client-resized-command'
import NengiViewSizeUpdatedMessage from './nengi-view-size-updated-message'
import PlayFanfareMessage from '../../audio/shared/play-fanfare-message'
import PlayAnimationMessage from '../../ai/shared/play-animation-message'
import ShakeCameraMessage from '../../ai/shared/shake-camera-message'
import DrawShapesMessage from '../../debug/shared/draw-shapes-message'
import SendItemToPitAndActivateCommand from '../../items/shared/send-item-to-pit-and-activate-command'
import ClaimRewardCommand from '../../items/shared/claim-reward-command'
import SubmitItemListingCommand from '../../items/shared/submit-item-listing-command'
import BuyItemCommand from '../../items/shared/buy-item-command'
import RequestStashUpdateCommand from '../../items/shared/request-stash-update-command'
import CancelListingCommand from '../../items/shared/cancel-listing-command'
import ItemListedSuccessfullyMessage from '../../items/shared/item-listed-successfully-message'
import ItemListingsMetadataMessage from '../../items/shared/item-listings-message'
import UserListingsMetadataMessage from '../../items/shared/user-listings-message'
import FetchItemListingsCommand from '../../items/shared/fetch-item-listings-command'
import FetchUserListingsCommand from '../../items/shared/fetch-user-listings-command'
import FailedToBuyItemFromMarketplaceMessage from '../../items/shared/failed-to-buy-item-from-marketplace-message'
import ItemFailedToListMessage from '../../items/shared/item-failed-to-list-message'
import ItemSuccessfullyPurchasedMessage from '../../items/shared/item-successfully-purchased-message'
import BiomeBoundsMessage from '../../biome/shared/biome-bounds-message'
import DestroyItemFromInventoryCommand from '../../items/shared/destroy-item-from-inventory-command'
import SendMinimapUpdateMessage from '../../player/shared/send-minimap-update-message'
import FileChangedMessage from './file-changed-message'
import { CreatePfxOneOffMessage } from './create-pfx-one-off-message'
import EquipGearToSlotCommand from '../../items/shared/equip-gear-to-slot-command'
import UnequipGearFromSlotCommand from '../../items/shared/unequip-gear-from-slot-command'

import TutorialFlagsMessage from '../../ftue/shared/tutorial-flags-message'

import SendStoreContentsMessage from '../../items/shared/send-store-contents-message'
import AttemptStorePurchaseCommand from '../../items/shared/attempt-store-purchase-command'
import { TestMessage } from './test-message'
import LookupEntityByNidCommand from '../../debug/shared/lookup-entity-by-nid'
import LogEntityMessage from '../../debug/shared/log-entity'
import ItemLockedMessage from '../../items/shared/item-locked'
import ItemUnlockedMessage from '../../items/shared/item-unlocked'
import UnhideEntityMessage from './unhide-entity-message'
import WormDoneSendingMessage from './worm-done-sending-message'
import HideWormMessage from './hide-worm-message'
import { Beam } from '../../beams/shared/beam.shared'
import { SetServerValueCommand } from './set-server-value-command'
import InspectObjectMessage from '../../debug/shared/inspect-object-message'
import DismissUIScreen from '../../player/shared/dismiss-ui-screen'
import DismissNPCUI from '../../player/shared/dismiss-npc-ui'
import InspectNearestEnemyCommand from '../../debug/shared/inspect-nearest-entity-command'
import ClientLogCommand from './client-log-command'
import SetSkinCommand from '../../player/shared/set-skin.shared'
import WaterBoundsMessage from '../../world/shared/water-bounds-message'
import IdentifyItemCommand from '../../items/shared/identify-item-command'
import SendItemToStashCommand from '../../items/shared/send-item-to-stash-command'
import ArenaTeleportCommand from '../../world/shared/arena-teleport-command'
import TeleportVisualsMessage from '../../world/shared/teleport-visual-message'
import { RegisterAnonymousPlayerCommand } from '../../player/shared/register-anonymous-player-command'
import { AnonymousRegistrationResponseMessage } from '../../player/shared/anonymous-registration-response'
import PlayerIsPurchasingMessage from '../../items/shared/player-is-purchasing-message'
import { UpdatedPlayerSettingsMessage, UpdatePlayerSettingsCommand } from '../../player/shared/player-settings'
import ItemIdentifiedMessage from '../../items/shared/item-identified'
import { BuffUpdateMessage } from '../../buffs/shared/buff-update-message'
import PlayerRunEndStatsMessage from '../../player/shared/player-run-end-stats-message'
import { PlayerEndRunCommand } from '../../player/shared/player-end-run-command'
import { PlayerSetCspCommand } from '../../player/shared/player-set-csp-command'
import UpdateNavArrowDestination from '../../player/shared/update-nav-arrow-destination-message'
import UpdateNavArrowVisibility from '../../player/shared/update-nav-arrow-visibility-message'
import { GroundItemDropMessage } from '../../items/shared/ground-item-drop-message'
import ShowGenericPromptUIMessage from '../../world/shared/show-generic-prompt-ui-message'
import UpdateFTUEMessageIndexMessage from '../../player/shared/update-ftue-message-index.message'
import { DamageNumberMessage } from '../../projectiles/shared/damage-dealt-message'
import GenericErrorMessage from './generic-error-message'
import FinishedPOIMessage from '../../world/shared/finished-poi-message'
import WorldBiomeGridMessage from '../../biome/shared/world-biome-grid-message'
import WorldBiomeGridCommand from '../../biome/shared/world-biome-grid-command'
import SpawnEnemyOnMeCommand from './spawn-enemy-on-me-command'
import PlayerBeatFinalBossMessage from './player-beat-final-boss-message'
import { RollItemCommand } from './roll-item-command'
import RequestArrowPositionCommand from '../../ftue/shared/request-arrow-position-command'
import UpdateTutorialFlagsCommand from '../../ftue/shared/update-tutorial-flags-command'
import FurnaceNotCollectingMessage from '../../items/shared/furnace-not-collecting-message'
import WorldDifficultyChangedMessage from '../../world/shared/world-difficulty-changed-message'
import PreviewAugmentsCommand from '../../items/shared/preview-augments-command'
import PreviewAugmentsDataMessage from '../../items/shared/preview-augments-data-message'
import PlayerBoughtHealingMessage from '../../items/shared/player-bought-healing-message'
import GCPause from '../../debug/shared/gc-pause'
import ManyItemsUnlockedMessage from '../../items/shared/many-items-unlocked-message'
import AddItemToContainerMessage from '../../items/shared/add-item-to-container-message'
import RemoveItemsFromContainerMessage from '../../items/shared/remove-items-from-container-message'
import RequestWalletUpdate from '../../items/shared/request-wallet-update-command'
import OpenPrizeCommand from '../../items/shared/open-prize-command'
import UnlockedMtxMessage from '../../items/shared/unlocked-mtx-message'
import PlayerRunEndDamageTakenMessage from '../../player/shared/player-run-end-damage-taken-message'
import DailyLoginInfoMessage from '../../daily-login/shared/daily-login-info-message'
import ClaimDailyLoginCommand from '../../daily-login/shared/claim-daily-login-command'
import DailyLoginClaimResultMessage from '../../daily-login/shared/daily-login-claim-result-message'
import NengiViewUpdatedCommand from './nengi-view-updated-command'
import CreatePartyCommand from '../../party-play/shared/create-party-command'
import PartyMessage from '../../party-play/shared/party-message'
import LeavePartyCommand from '../../party-play/shared/leave-party-command'
import JoinPartyCommand from '../../party-play/shared/join-party-command'
import PartyPromoteCommand from '../../party-play/shared/party-promote-command'
import PartyKickCommand from '../../party-play/shared/party-kick-command'
import ClearPartyMessage from '../../party-play/shared/clear-party-message'
import PartyDepartureMessage from '../../party-play/shared/party-departure-message'
import UseWormHornCommand from '../../items/shared/use-worm-horn-command'
import WormHornResultMessage from '../../items/shared/worm-horn-result-message'
import GotExchangeURLMessage from '../../mtx/shared/got-exchange-url-message'
import RequestForteExchangeUrl from '../../mtx/shared/request-forte-exchange-url-command'
import PurchaseMtx from '../../mtx/shared/purchase-mtx-command'
import PurchaseMtxResultMessage from '../../mtx/shared/purchase-mtx-result-message'
import PartyReadyCommand from '../../party-play/shared/party-ready-command'
import PartyFlagsMessage from '../../party-play/shared/party-flags-message'
import UpdatePartyPositionsMessage from '../../player/shared/update-party-positions-message'
import PlayerResurrected from '../../player/shared/player-resurrected-message'
import DismissWormHornCommand from '../../items/shared/dismiss-worm-horn-command'
import TriggerPartyDepartureCountdownCommand from '../../party-play/shared/trigger-party-departure-countdown-command'
import TriggerPartyDepartureCountdownMessage from '../../party-play/shared/trigger-party-departure-countdown-message'
import PartyExitGameMessage from '../../party-play/shared/party-exit-game-message'
import PartyExitGameCommand from '../../party-play/shared/party-exit-game-command'
import PingAtLocationMessage from '../../player/shared/ping-at-location-message'
import ProfileMessage from '../../debug/shared/profile-message'
import SaveFileMessage from './save-file-message'
import AlertMessage from '../../chat/shared/alert-message'
import { debugConfig } from '../client/debug-config'
import TryShowTutorialMessage from '../../player/shared/try-show-tutorial-message'
import FactionSetResultMessage from '../../factions/shared/faction-set-result-message'
import SetFactionAffiliationCommand from '../../factions/shared/set-faction-affiliation-command'
import LockedIntoOutpostCommand from '../../ftue/shared/locked-into-outpost-command'
import SendItemModsMessage from '../../items/shared/send-item-mods-message'
import PartyInvitationMessage from '../../chat/shared/party-invitation-message'
import PartyInvitationCommand from '../../chat/shared/party-invitation-command'
import UpdateCurrencyMessage from '../../items/shared/update-currency-message'
import StashIncreasesMessage from '../../items/shared/stash-increases-message'
import PitStatsMessage from '../../items/shared/pit-stats-message'
import OpenCurrencyBagCommand from '../../items/shared/open-currency-bag-command'
import MetricMessage from '../client/metrics-message'
import PlayerStatChangeMessage from '../../player/shared/player-stat-change-message'
import PlayerGearStatChangeMessage from '../../player/shared/player-gear-stat-change-message'
import ItemStackSizeChangedMessage from '../../items/shared/item-stack-size-changed-message'
import MarketplaceItemCountMessage from '../../items/shared/marketplace-item-count-message'
import DumpFileMessage from '../../world/shared/dump-file-message'
import { DebugLoadLoadoutCommand } from '../../player/shared/debug-load-loadout-command'
import MergeStackablesCommand from '../../items/shared/merge-stackables-command'
import FakeRegisterMessage from '../../debug/shared/fake-register-message'
import SetIsTypingCommand from '../../chat/shared/set-is-typing-command'
import { ProjectileConfigSlotMessage } from '../../projectiles/shared/projectile-config-message'
import { ProjectileCreateCommand } from '../../projectiles/shared/projectile-create-command'
import { BeamCreateUpdateCommand } from '../../projectiles/shared/beam-create-update-command'
import PlayerIgnoreColliderMessage from '../../player/shared/player-ignore-collider-message'
import PlayerNotificationMessage from './player-notification-message'
import SetFreeListingDateMessage from '../../marketplace/shared/set-free-listing-date-message'
import { ProjectileConfigIdMessage } from '../../projectiles/shared/projectile-config-id-message'
import { ProjectileSplashMessage } from '../../projectiles/shared/projectile-splash-message'
import UseConsumableItemCommand from '../../items/shared/use-consumable-item-command'
import ClaimFactionPrizeCommand from '../../factions/shared/claim-faction-prize-command'
import FactionPrizeClaimResultMessage from '../../factions/shared/faction-prize-claim-result-message'
import AttachAugmentSlotToWeaponCommand from '../../items/shared/buy-augment-slot-command'
import ItemSlotNotificationMessage from '../../items/shared/item-slot-notification-message'
import weaponAugmentSlotUpdateMessage from '../../items/shared/weapon-augment-slot-update-message'
import PlayerEmoteCommand from '../../input/shared/player-emote-command'
import SkillCooldownUpdatedMessage from '../../gear-skills/shared/skill-updated-message'
import PostCampaignDetailsCommand from '../../analytics/shared/post-campaign-metrics-command'
import PlayerReputationCommand from '../../player/shared/player-reputation-command'
import SendPlayerReputationPointsMessage from '../../player/shared/player-reputation-points-message'
import  PlayerMovingToBiomeCommand  from '../../player/shared/player-moving-to-biome-command'
import PlayerMovedToBiomeMessage from '../../player/shared/player-moved-to-biome-message'
import DisconnectReasonMessage from './disconnect-reason-message'
import AIDebugStringMessage from '../../world/shared/ai-debug-string-message'
import PlayerBinaryFlagChangedMessage from '../../player/shared/player-binary-flag-change-message'

const config = {
	USE_GRID_BASED_INTEREST_MANAGEMENT: true,
	// enabling this on local/alpha/staging only for testing purposes
	//USE_GRID_BASED_INTEREST_MANAGEMENT: process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'alpha',

	UPDATE_RATE: debugConfig.enableSlowGameLoop ? 5 : 20,

	ID_BINARY_TYPE: nengi.UInt16,
	TYPE_BINARY_TYPE: nengi.UInt8,

	ID_PROPERTY_NAME: 'nid',
	TYPE_PROPERTY_NAME: 'ntype',

	USE_HISTORIAN: false,
	HISTORIAN_TICKS: 10,

	LOGGING: true,

	USE_SSL: false,
	KEY_FILE_NAME: '/srv/certs/privkey.pem', 
	CERT_FILE_NAME: '/srv/certs/fullchain.pem',

	protocols: {
		entities: [
			['Player', Player],
			['Enemy', Enemy],
			['Projectile', Projectile],
			['Beam', Beam],
			['NPC', NPC],
			['GroundItemEntity', GroundItemEntity],
			['TerrainProp', TerrainProp],
			['ForegroundProp', ForegroundProp],
			['Generic', GenericEntity],
			['POI', POI],
			['ColliderEntity', ColliderEntity],
		],
		localMessages: [
			['WeaponFired', WeaponFiredMessage],
			['PingAtLocation', PingAtLocationMessage],
			['ProjectileHit', ProjectileHitMessage],
			['ProjectileSplash', ProjectileSplashMessage],
			['SkillUsed', SkillUsedMessage],
			['DamageNumber', DamageNumberMessage],
		],
		messages: [
			['DisconnectReason', DisconnectReasonMessage],
			['ProjectileConfigSlot', ProjectileConfigSlotMessage],
			['ProjectileConfigId', ProjectileConfigIdMessage],
			['ItemSuccessfullyPurchased', ItemSuccessfullyPurchasedMessage],
			['ItemFailedToList', ItemFailedToListMessage],
			['FailedToBuyItemFromMarketplace', FailedToBuyItemFromMarketplaceMessage],
			['ItemListingsMetadata', ItemListingsMetadataMessage],
			['UserListingsMetadata', UserListingsMetadataMessage],
			['MarketplaceItemCount', MarketplaceItemCountMessage],
			['ItemListedSuccessfully', ItemListedSuccessfullyMessage],
			['HideEntity', HideEntityMessage],
			['GCPause', GCPause],
			['TeleportVisuals', TeleportVisualsMessage],
			['FileChanged', FileChangedMessage],
			['SaveFile', SaveFileMessage],
			['Metrics', MetricMessage],
			['CreatePfx', CreatePfxOneOffMessage],
			['Test', TestMessage],
			['InspectObject', InspectObjectMessage],
			['GameMode', GameModeMessage],
			['PopUIScreen', PopUIScreen],
			['DismissUIScreen', DismissUIScreen],
			['Identity', Identity],
			['CreateChunk', CreateChunk],
			['DeleteChunk', DeleteChunk],
			['ChatMessage', ChatMessage],
			['TriggerPartyDepartureCountdownMessage', TriggerPartyDepartureCountdownMessage],
			['NengiViewSizeUpdated', NengiViewSizeUpdatedMessage],
			['BroadcastMessage', BroadcastMessage],
			['BiomeTransition', BiomeTransitionMessage],
			['PlayFanfare', PlayFanfareMessage],
			['UpdateDebugOnloadCommandsMessage', UpdateDebugOnloadCommandsMessage],
			['LogEntity', LogEntityMessage],
			['DrawLine', DrawLine],
			['DrawRectangle', DrawRectangle],
			['DrawCircle', DrawCircle],
			['DrawEllipse', DrawEllipse],
			['DrawText', DrawText],
			['DrawPolygon', DrawPolygon],
			['DrawShapes', DrawShapesMessage],
			['EquippedNewGear', EquippedNewGearMessage],
			['GroundItemDropped', GroundItemDropMessage],
			['PlayerDied', PlayerDiedMessage],
			['PlayerIgnoreCollider', PlayerIgnoreColliderMessage],
			['PlayerStatChange', PlayerStatChangeMessage],
			['PlayerGearStatChange', PlayerGearStatChangeMessage],
			['PlayerBinaryFlagChange', PlayerBinaryFlagChangedMessage],
			['EnemyDied', EnemyDiedMessage],
			['PlayAnimation', PlayAnimationMessage],
			['ShakeCamera', ShakeCameraMessage],
			['WorldData', WorldDataMessage],
			['BiomeBounds', BiomeBoundsMessage],
			['WorldDifficultyChanged', WorldDifficultyChangedMessage],
			['PlayerResurrected', PlayerResurrected],
			['PlayerStatsMessage', PlayerStatsMessage],
			['PlayerStartShieldRegenMessage', PlayerStartShieldRegenMessage],
			['PlayerUpdateShieldMessage', PlayerUpdateShieldMessage],
			['SendItemContainerMessage', SendItemContainerMessage],
			['AddItemToContainerMessage', AddItemToContainerMessage],
			['RemoveItemsFromContainerMessage', RemoveItemsFromContainerMessage],
			['ItemStackSizeChangedMessage', ItemStackSizeChangedMessage],
			['FurnaceState', FurnaceStateMessage],
			['FurnaceNotCollectingMessage', FurnaceNotCollectingMessage],
			['GenericError', GenericErrorMessage],
			['SendMinimapUpdateMessage', SendMinimapUpdateMessage],
			['TutorialFlagsMessage', TutorialFlagsMessage],
			['SendStoreContentsMessage', SendStoreContentsMessage],
			['ItemLocked', ItemLockedMessage],
			['ItemUnlocked', ItemUnlockedMessage],
			['ManyItemsUnlocked', ManyItemsUnlockedMessage],
			['UnhideEntity', UnhideEntityMessage],
			['WormDoneSending', WormDoneSendingMessage],
			['WormHornResult', WormHornResultMessage],
			['HideWormMessage', HideWormMessage],
			['WaterBounds', WaterBoundsMessage],
			['WorldBiomeGrid', WorldBiomeGridMessage],
			['AnonymousRegistrationResponse', AnonymousRegistrationResponseMessage],
			['PlayerIsPurchasing', PlayerIsPurchasingMessage],
			['UpdatedPlayerSettingsMessage', UpdatedPlayerSettingsMessage],
			['ItemIdentified', ItemIdentifiedMessage],
			['BuffUpdate', BuffUpdateMessage],
			['PlayerRunEndStatsMessage', PlayerRunEndStatsMessage],
			['PlayerRunEndDamageTakenMessage', PlayerRunEndDamageTakenMessage],
			['UpdateNavArrowDestination', UpdateNavArrowDestination],
			['UpdateNavArrowVisibility', UpdateNavArrowVisibility],
			['ShowGenericPromptUI', ShowGenericPromptUIMessage],
			['UpdateFTUEMessageIndexMessage', UpdateFTUEMessageIndexMessage],
			['TryShowTutorialMessage', TryShowTutorialMessage],
			['FinishedPOIMessage', FinishedPOIMessage],
			['PlayerBeatFinalBossMessage', PlayerBeatFinalBossMessage],
			['PreviewAugmentsDataMessage', PreviewAugmentsDataMessage],
			['PlayerBoughtHealingMessage', PlayerBoughtHealingMessage],
			['UnlockedMtxMessage', UnlockedMtxMessage],
			['PurchaseMtxResultMessage', PurchaseMtxResultMessage],
			['DailyLoginInfoMessage', DailyLoginInfoMessage],
			['DailyLoginClaimResultMessage', DailyLoginClaimResultMessage],
			['PartyMessage', PartyMessage],
			['ClearPartyMessage', ClearPartyMessage],
			['PartyDepartureMessage', PartyDepartureMessage],
			['GotExchangeURLMessage', GotExchangeURLMessage],
			['PartyFlagsMessage', PartyFlagsMessage],
			['UpdatePartyPositionsMessage', UpdatePartyPositionsMessage],
			['PartyExitGameMessage', PartyExitGameMessage],
			['ProfileMessage', ProfileMessage],
			['AlertMessage', AlertMessage],
			['FactionSetResultMessage', FactionSetResultMessage],
			['SendItemModsMessage', SendItemModsMessage],
			['PartyInvitationMessage', PartyInvitationMessage],
			['UpdateCurrencyMessage', UpdateCurrencyMessage],
			['StashIncreasesMessage', StashIncreasesMessage],
			['PitStatsMessage', PitStatsMessage],
			['DumpFileMessage', DumpFileMessage],
			['FakeRegisterMessage', FakeRegisterMessage],
			['PlayerNotificationMessage', PlayerNotificationMessage],
			['SetFreeListingDateMessage', SetFreeListingDateMessage],
			['FactionPrizeClaimResultMessage', FactionPrizeClaimResultMessage],
			['ItemSlotNotification', ItemSlotNotificationMessage],
			['weaponAugmentSlotUpdateMessage', weaponAugmentSlotUpdateMessage],
			['SkillCooldownUpdated', SkillCooldownUpdatedMessage],
			['SendPlayerReputationPointsMessage', SendPlayerReputationPointsMessage],
			['PlayerMovedToBiomeMessage', PlayerMovedToBiomeMessage],
			['AIDebugStringMessage', AIDebugStringMessage],
		],
		commands: [
			['WorldBiomeGrid', WorldBiomeGridCommand],
			['SpawnEnemyOnMe', SpawnEnemyOnMeCommand],
			['RequestStashUpdate', RequestStashUpdateCommand],
			['RequestWalletUpdate', RequestWalletUpdate],
			['FetchItemListings', FetchItemListingsCommand],
			['FetchUserListings', FetchUserListingsCommand],
			['CancelListing', CancelListingCommand],
			['SubmitItemListing', SubmitItemListingCommand],
			['BuyItem', BuyItemCommand],
			['PlayerInput', PlayerInput],
			['ChatCommand', ChatCommand],
			['SetServerValue', SetServerValueCommand],
			['InspectNearestEntity', InspectNearestEnemyCommand],
			['ClientResized', ClientResizedCommand],
			['NengiViewUpdated', NengiViewUpdatedCommand],
			['SwitchWeapon', SwitchWeapon],
			['RollItem', RollItemCommand],
			['EquipWeaponToSlot', EquipWeaponToSlotCommand],
			['EquipGearToSlot', EquipGearToSlotCommand],
			['LookupEntityByNid', LookupEntityByNidCommand],
			['UnequipGearFromSlot', UnequipGearFromSlotCommand],
			['SendItemToWormMail', SendItemToWormMailCommand],
			['RemoveItemFromWormMail', RemoveItemFromWormMailCommand],
			['AttachAugmentToWeapon', AttachAugmentToWeaponCommand],
			['DetachAugmentFromWeapon', DetachAugmentFromWeaponCommand],
			['SendItemToStash', SendItemToStashCommand],
			['SendWormAway', SendWormAwayCommand],
			['UseWormHorn', UseWormHornCommand],
			['StartSmeltingItem', StartSmeltingItemCommand],
			['ClaimSmeltedItemRewards', ClaimSmeltedItemRewardsCommand],
			['SendItemToPitAndActivate', SendItemToPitAndActivateCommand],
			['ClaimReward', ClaimRewardCommand],
			['DestroyItemFromInventory', DestroyItemFromInventoryCommand],
			['UpdateTutorialFlags', UpdateTutorialFlagsCommand],
			['DismissNPCUI', DismissNPCUI],
			['AttemptStorePurchase', AttemptStorePurchaseCommand],
			['ClientLog', ClientLogCommand],
			['SetSkin', SetSkinCommand],
			['IdentifyItem', IdentifyItemCommand],
			['ArenaTeleportCommand', ArenaTeleportCommand],
			['RegisterAnonymousPlayer', RegisterAnonymousPlayerCommand],
			['UpdatePlayerSettings', UpdatePlayerSettingsCommand],
			['PlayerEndRun', PlayerEndRunCommand],
			['RequestArrowPosition', RequestArrowPositionCommand],
			['PreviewAugmentsCommand', PreviewAugmentsCommand],
			['OpenPrize', OpenPrizeCommand],
			['ClaimDailyLogin', ClaimDailyLoginCommand],
			['CreateParty', CreatePartyCommand],
			['LeaveParty', LeavePartyCommand],
			['JoinParty', JoinPartyCommand],
			['TriggerPartyDepartureCountdown', TriggerPartyDepartureCountdownCommand],
			['PartyPromote', PartyPromoteCommand],
			['PartyKick', PartyKickCommand],
			['RequestForteExchangeURL', RequestForteExchangeUrl],
			['PurchaseMtx', PurchaseMtx],
			['PartyReady', PartyReadyCommand],
			['DismissWormHorn', DismissWormHornCommand],
			['PartyExitGameCommand', PartyExitGameCommand],
			['SetFactionAffiliation', SetFactionAffiliationCommand],
			['LockedIntoOutpostCommand', LockedIntoOutpostCommand],
			['PartyInvitationCommand', PartyInvitationCommand],
			['DebugLoadLoadout', DebugLoadLoadoutCommand],
			['OpenCurrencyBag', OpenCurrencyBagCommand],
			['MergeStackablesCommand', MergeStackablesCommand],
			['SetIsTyping', SetIsTypingCommand],
			['ProjectileCreate', ProjectileCreateCommand],
			['PlayerSetCsp', PlayerSetCspCommand],
			['BeamCreateUpdate', BeamCreateUpdateCommand],
			['UseConsumableItem', UseConsumableItemCommand],
			['ClaimFactionPrize', ClaimFactionPrizeCommand],
			['AttachAugmentSlotToWeapon', AttachAugmentSlotToWeaponCommand],
			['PlayerEmoteCommand', PlayerEmoteCommand],
			['PostCampaignDetailsCommand', PostCampaignDetailsCommand],
			['PlayerReputationCommand', PlayerReputationCommand],
			['PlayerMovingToBiomeCommand', PlayerMovingToBiomeCommand],
		],
		basics: [],
	},
}

export default config
