import { AnyColliderConfig, ColliderTraits, ColliderType } from '../../collision/shared/colliders'
import { degToRad } from '../../utils/math'
import { makeBlockPropConfig, PropConfigs } from './prop-configs'

// NOTE: most of this file was copy/pasted from the beach

const largeUncommonColliders: [AnyColliderConfig] = [{ type: ColliderType.Ellipse, position: [13, 10], rX: 240, rY: 160, traits: ColliderTraits.BlockMovementAndItem }]
const largeRareColliders: [AnyColliderConfig] = [{ type: ColliderType.Ellipse, position: [-37, 54], rX: 377, rY: 200, traits: ColliderTraits.BlockAll }]
const largeRareVar02Colliders: [AnyColliderConfig] = [{ type: ColliderType.Ellipse, position: [-70, 50], rX: 350, rY: 200, traits: ColliderTraits.BlockMovementAndItem }]

export const prismPropConfigs: PropConfigs = {
	'boss-arena': {
		disableFileOrdering: true,
		colliders: [
			{ type: ColliderType.Box, position: [-1834, -1074], width: 194, height: 1958, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [1644, -1088], width: 240, height: 1999, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-1558, -1309], width: 3205, height: 202, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-1558, -1107], width: 3205, height: 50, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-1559, 979], width: 1399, height: 171, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-1559, 1150], width: 1399, height: 50, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [170, 990], width: 1425, height: 159, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [170, 1149], width: 1425, height: 50, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-690, -1212], width: 1261, height: 570, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-690, -642], width: 1261, height: 50, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [353, -808], width: 347, height: 156, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [353, -652], width: 347, height: 50, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-1214, -445], width: 186, height: 1057, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-1200, 19], width: 469, height: 580, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-1241, 570], width: 771, height: 94, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-1241, 664], width: 771, height: 50, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [947, -402], width: 166, height: 1011, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [632, 313], width: 309, height: 274, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [473, 522], width: 709, height: 94, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [473, 616], width: 709, height: 50, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [-258, 642], radius: 73, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [274, 635], radius: 67, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [-1567, -315], radius: 145, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [-2037, -721], radius: 62, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [-968, 1336], radius: 47, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [-548, 1428], radius: 47, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [930, 1337], radius: 164, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [1867, 881], radius: 58, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [2098, 519], radius: 54, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [1621, -339], radius: 106, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [920, -986], radius: 109, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [826, -1135], radius: 109, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [-37, -131], radius: 82, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [-1584, -1105], radius: 217, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [-1584, -1105], radius: 146, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [-2225, 907], radius: 152, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [1686, -1083], radius: 220, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [1679, -1083], radius: 126, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [1647, 1003], radius: 201, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-3888, 1441], width: 439, height: 116, angle: -0.8, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-248, 1078], width: 538, height: 139, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-248, 1217], width: 538, height: 50, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [-1635, 1003], radius: 201, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-7111, 1140], width: 439, height: 116, angle: 0.8, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [2058, -1260], radius: 126, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [1173, 82], radius: 127, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [-1306, 307], radius: 117, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [1650, 520], radius: 100, traits: ColliderTraits.BlockAll },
		],
		visibilityRadius: 1900,
	},
	'ground-boss': {
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},
	'b-ground-tile-01': {
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},
	'b-ground-tile-02': {
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},
	'b-ground-tile-03': {
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},
	'a-ground-tile-01': {
		zOffset: -8000,
		dontLoadAsset: true,
	},
	'a-ground-tile-02': {
		zOffset: -8000,
		dontLoadAsset: true,
	},
	'a-ground-tile-03': {
		zOffset: -8000,
		dontLoadAsset: true,
	},
	'boss-patch-straigh-hori': {
		zOffset: -5000,
		dontLoadAsset: true,
	},
	'boss-path-straight-vert': {
		zOffset: -5000,
		dontLoadAsset: true,
	},
	'boss-path-circle-right': {
		zOffset: -5000,
		dontLoadAsset: true,
	},
	'boss-path-circle-left': {
		zOffset: -5000,
		dontLoadAsset: true,
	},
	'boss-ground-crack-01': {
		zOffset: -5000,
		dontLoadAsset: true,
	},
	'boss-ground-crack-02': {
		zOffset: -5000,
		dontLoadAsset: true,
	},
	'boss-02': {
		zOffset: -50,
		dontLoadAsset: true,
	},
	'boss-03': {
		zOffset: -50,
		dontLoadAsset: true,
	},
	'boss-04': {
		zOffset: -50,
		dontLoadAsset: true,
	},
	'boss-05': {
		zOffset: -50,
		dontLoadAsset: true,
	},
	'boss-07-a': {
		zOffset: -50,
		dontLoadAsset: true,
	},
	'boss-07-b': {
		zOffset: -50,
		dontLoadAsset: true,
	},
	'boss-08-a': {
		zOffset: -50,
		dontLoadAsset: true,
	},
	'boss-08-b': {
		zOffset: -50,
		dontLoadAsset: true,
	},
	'boss-10': {
		zOffset: -50,
		dontLoadAsset: true,
	},
	'boss-11': {
		zOffset: -50,
		dontLoadAsset: true,
	},
	'boss-12': {
		zOffset: -50,
		dontLoadAsset: true,
	},
	'boss-13': {
		zOffset: -50,
		dontLoadAsset: true,
	},
	'boss-14': {
		zOffset: -50,
		dontLoadAsset: true,
	},
	'boss-15': {
		zOffset: -50,
		dontLoadAsset: true,
	},
	'boss-16': {
		zOffset: -50,
		dontLoadAsset: true,
	},
	'boss-17': {
		zOffset: -50,
		dontLoadAsset: true,
	},
	'boss-18': {
		zOffset: -50,
		dontLoadAsset: true,
	},
	'boss-arena-front': {
		visibilityRadius: 1800,
	},
	'boss-arena-glints': {
		visibilityRadius: 1800,
	},
	'boss-arena-lanterns': {
		visibilityRadius: 1800,
		rigged: true,
		zOffset: 650,
	},
	'boss-arena-main-hut': {
		visibilityRadius: 1800,
	},
	'boss-01': {
		zOffset: -50,
		dontLoadAsset: true,
	},
	'boss-arena-side-pieces': {
		visibilityRadius: 1800,
	},
	'poi-02-base': {
		disableFileOrdering: true,
		colliders:[
			{ type: ColliderType.Box, position: [-421,52], width: 868, height: 116, traits: ColliderTraits.BlockMovementAndItem,},
			{ type: ColliderType.Box, position: [244,567], width: 720, height: 104, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Box, position: [518,-221], width: 646, height: 94, traits: ColliderTraits.BlockMovementAndItem,},
			{ type: ColliderType.Box, position: [1130,-923], width: 373, height: 1511, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Box, position: [-678,-1181], width: 1689, height: 173, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Box, position: [-1071,-307], width: 683, height: 85, traits: ColliderTraits.BlockMovementAndItem,},
			{ type: ColliderType.Box, position: [-1607,-427], width: 556, height: 940, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Box, position: [-1287,490], width: 566, height: 422, angle: 0.00, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Box, position: [-442,-655], width: 856, height: 177, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Circle, position: [-468,-240], radius: 83, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Circle, position: [-866,-576], radius: 83, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Circle, position: [-363,-504], radius: 112, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Circle, position: [412,-543], radius: 128, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Circle, position: [223,886], radius: 28, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Circle, position: [564,1109], radius: 40, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Circle, position: [-1379,1054], radius: 36, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Circle, position: [-1571,1033], radius: 65, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Circle, position: [-1309,533], radius: 111, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Box, position: [-1357,-1009], width: 2567, height: 411, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Circle, position: [-493,589], radius: 36, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Circle, position: [310,633], radius: 107, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Circle, position: [611,668], radius: 100, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Circle, position: [1024,813], radius: 357, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Circle, position: [1242,717], radius: 352, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Circle, position: [257,159], radius: 97, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Circle, position: [-1089,382], radius: 175, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Box, position: [-1020,492], width: 677, height: 116, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Box, position: [1429,-423], width: 174, height: 720, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Circle, position: [1014,-450], radius: 83, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Circle, position: [1039,-647], radius: 222, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Circle, position: [-1128,-642], radius: 281, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Circle, position: [-613,-794], radius: 281, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Circle, position: [-608,-275], radius: 83, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Circle, position: [-1042,-334], radius: 117, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Circle, position: [-1452,-468], radius: 152, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Circle, position: [1058,-87], radius: 133, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Box, position: [790,1034], width: 165, height: 280, traits: ColliderTraits.BlockAll,}
			],
		rigged: false,
		visibilityRadius: 1400,
	},
	'poi-01-b': {
		rigged: true,
		zOffset: -500,
		dontLoadAsset: true,
	},
	'poi-01-c': {
		rigged: true,
		zOffset: -300,
		dontLoadAsset: true,
	},
	'poi-03': {
		rigged: true,
		zOffset: -300,
		dontLoadAsset: true,
	},
	'poi-ground-01': {
		rigged: true,
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},
	'poi-ground-02': {
		rigged: true,
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},
	'poi-ground-03': {
		rigged: true,
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},
	'poi-ground-straight': {
		rigged: true,
		zOffset: -1500,
		dontLoadAsset: true,
	},
	'poi-ground-tile-01': {
		rigged: true,
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},
	'poi-ground-tile-02': {
		rigged: true,
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},
	'poi-ground-tile-03': {
		rigged: true,
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},
	'poi-02-crystal-glow': {
		disableFileOrdering: true,
		rigged: false,
		visibilityRadius: 1400,
	},
	'poi-02-glints': {
		disableFileOrdering: true,
		rigged: false,
		visibilityRadius: 1400,
	},
	'poi-02-lanterns': {
		disableFileOrdering: true,
		rigged: false,
		visibilityRadius: 1400,
	},
	'poi-03-ruins': {
		disableFileOrdering: true,
		colliders: [
			{ type: ColliderType.Ellipse, position: [ -838, 277], rX: 120, rY: 77, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [395, -80], width: 443, height: 397, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [ -360, 330], rX: 164, rY: 77, traits: ColliderTraits.BlockAll, angle: degToRad(-10)  },
			{ type: ColliderType.Circle, position: [1088, 153], radius: 52, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [1174, -30], radius: 52, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [1020, -567], radius: 67, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [1087, -645], radius: 55, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [552, -998], radius: 130, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-725, -970], width: 491, height: 311, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-160, -1112], width: 832, height: 116, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [-1036, -838], radius: 53, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-1009, 77], width: 832, height: 116, traits: ColliderTraits.BlockAll, angle: degToRad(10)  },
			{ type: ColliderType.Box, position: [-260,-802], width: 163, height: 90, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Circle, position: [-896,-581], radius: 53, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Box, position: [675,-978], width: 115, height: 94, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Box, position: [233,197], width: 163, height: 90, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Box, position: [853,263], width: 180, height: 78, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Box, position: [-859,-729], width: 136, height: 93, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Circle, position: [881,127], radius: 52, traits: ColliderTraits.BlockAll,},
		],
		rigged: false,
		visibilityRadius: 1000,
	},
	'poi-03-glints': {
		zOffset: -500,
		rigged: true,
		visibilityRadius: 1000,
	},
	'poi-03-lantern': {
		zOffset: -500,
		rigged: true,
		visibilityRadius: 1000,
	},
	'poi-04-geode': {
		colliders: [
			{ type: ColliderType.Circle, position: [-611, -284], radius: 48, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [-592, -132], rX: 120, rY: 77, traits: ColliderTraits.BlockMovementAndItem },
			// { type: ColliderType.Box, position: [-1015, -535], width: 1475, height: 80, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [-657, -13], rX: 160, rY: 95, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [638, 246], radius: 90, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [262, -623], radius: 118, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [291, -546], rX: 222, rY: 82, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [106, -135], rX: 321, rY: 232, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [619, -510], radius: 48, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [856, -238], radius: 48, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [782, 321], radius: 48, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [-238, 594], radius: 48, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [-338, 501], radius: 48, traits: ColliderTraits.BlockAll },



		],
		zOffset: -500,
		rigged: true,
		visibilityRadius: 1000,
	},
	'poi-04-glints': {
		zOffset: -500,
		rigged: true,
		visibilityRadius: 1000,
	},
	'poi-04-crystal-glow': {
		zOffset: -500,
		rigged: true,
		visibilityRadius: 1000,
	},
	'outpost-base': {
		disableFileOrdering: true,
		colliders: [
			{ type: ColliderType.Box, position: [-946, -615], width: 117, height: 1050, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [657, -671], width: 127, height: 1135, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-789, -746], width: 1455, height: 100, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [486, -648], width: 181, height: 114, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-819, -723], width: 140, height: 182, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-815, 361], width: 396, height: 169, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-817, 262], width: 206, height: 101, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [236, 381], width: 420, height: 154, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-103, -240], width: 550, height: 230, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [1135, -351], radius: 58, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-647, -144], width: 160, height: 62, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-826, 750], width: 60, height: 101, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-597, 526], width: 218, height: 165, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [142, 464], width: 96, height: 123, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [256, 653], width: 96, height: 123, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [533, 599], width: 207, height: 155, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [686, 281], width: 207, height: 155, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [794, -85], width: 415, height: 155, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-1084, -218], width: 204, height: 133, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-1311, -693], width: 213, height: 228, traits: ColliderTraits.BlockAll },
		],
		rigged: false,
		visibilityRadius: 1400,
	},
	'outpost-lanterns': {
		rigged: true,
		visibilityRadius: 1400,
		zOffset: 575,
	},
	'outpost-fence': {
		rigged: true,
		visibilityRadius: 1400,
		zOffset: -750,
	},
	sign: {
		rigged: true,
		dontLoadAsset: true,
		zOffset: -75,
	},
	'outpost-props': {
		visibilityRadius: 1400,
	},
	'outpost-bubba': {
		rigged: true,
		visibilityRadius: 1400,
		zOffset: -1000,
	},
	'med-uncommon-2': {
		rigged: true,
		dontLoadAsset: true,
		zOffset: -2000,
	},
	// START blocker pieces //

	'low-blocker-01': {
		colliders: [{ type: ColliderType.Ellipse, position: [0, 0], rX: 150, rY: 80, traits: ColliderTraits.BlockMovementAndItem }],
		dontLoadAsset: true,
		visibilityRadius: 150,
	},
	'low-blocker-02': {
		colliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 90, traits: ColliderTraits.BlockMovementAndItem }],
		dontLoadAsset: true,
		visibilityRadius: 125,
	},
	'low-blocker-03': {
		colliders: [{ type: ColliderType.Ellipse, position: [0, 0], rX: 150, rY: 80, traits: ColliderTraits.BlockMovementAndItem }],
		dontLoadAsset: true,
		visibilityRadius: 150,
	},
	'mid-blocker-01': {
		colliders: [{ type: ColliderType.Box, angle: degToRad(30), position: [100, -80], height: 150, width: 80, traits: ColliderTraits.BlockAll }],
		dontLoadAsset: true,
		zOffset: -150,
		visibilityRadius: 170,
	},
	'mid-blocker-02': {
		zOffset: -150,
		colliders: [{ type: ColliderType.Box, angle: 0, position: [72, -100], height: 250, width: 100, traits: ColliderTraits.BlockAll }],
		dontLoadAsset: true,
		visibilityRadius: 170,
	},
	'mid-blocker-03': {
		zOffset: -160,
		colliders: [{ type: ColliderType.Box, angle: 1.0, position: [74, -122], height: 103, width: 243, traits: ColliderTraits.BlockAll }],
		dontLoadAsset: true,
		visibilityRadius: 150,
	},
	'high-blocker-01': {
		colliders: [{ type: ColliderType.Circle, position: [41, 7], radius: 80, traits: ColliderTraits.BlockAll }],
		dontLoadAsset: true,
		visibilityRadius: 110,
	},
	'high-blocker-02': {
		colliders: [{ type: ColliderType.Circle, position: [31, 0], radius: 80, traits: ColliderTraits.BlockAll }],
		dontLoadAsset: true,
		visibilityRadius: 110,
	},
	// END blocker pieces //
	'high-blocker-01-var01': makeBlockPropConfig(),
	'high-blocker-01-var02': makeBlockPropConfig(),
	'high-blocker-01-var03': makeBlockPropConfig(),
	'high-blocker-01-var04': makeBlockPropConfig(),
	'mid-blocker-01-var01': makeBlockPropConfig(),
	'mid-blocker-01-var02': makeBlockPropConfig(),
	'mid-blocker-01-var03': makeBlockPropConfig(),
	'mid-blocker-01-var04': makeBlockPropConfig(),
	'mid-blocker-01-var05': makeBlockPropConfig(),
	'mid-blocker-01-var06': makeBlockPropConfig(),
	'low-blocker-01-var01': makeBlockPropConfig(),
	'low-blocker-01-var02': makeBlockPropConfig(),
	'low-blocker-01-var03': makeBlockPropConfig(),
	'low-blocker-01-var04': makeBlockPropConfig(),
	'low-blocker-01-var05': makeBlockPropConfig(),
	'low-blocker-01-var06': makeBlockPropConfig(),

	'small-common-01': {
		visibilityRadius: 137,
		zOffset: -60,
	},
	'small-common-02': {
		visibilityRadius: 140,
		zOffset: -60,
	},
	'small-common-03': {
		visibilityRadius: 130,
		zOffset: -10,
	},

	'small-uncommon-01': {
		colliders: [
			{ type: ColliderType.Circle, position: [48, -1], radius: 72, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [-57, -26], radius: 66, traits: ColliderTraits.BlockMovementAndItem },
		],
		zOffset: -20,
		visibilityRadius: 200,
	},
	'small-uncommon-02': {
		zOffset: -80,
		visibilityRadius: 155,
	},
	'small-uncommon-03': {
		colliders: [{ type: ColliderType.Circle, position: [14, -5], radius: 78, traits: ColliderTraits.BlockMovementAndItem }],
		visibilityRadius: 160,
		zOffset: -80,
	},
	'small-uncommon-04': {
		colliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 20, traits: ColliderTraits.BlockMovementAndItem }],
		visibilityRadius: 175,
		zOffset: -10,
	},

	'small-rare-01': {
		visibilityRadius: 300,
		zOffset: -30,
	},
	'small-rare-02': {
		colliders: [{ type: ColliderType.Circle, position: [32, -18], radius: 33, traits: ColliderTraits.BlockMovementAndItem }],
		visibilityRadius: 150,
	},
	'small-rare-03': {
		colliders: [{ type: ColliderType.Circle, position: [39, -2], radius: 49, traits: ColliderTraits.BlockMovementAndItem }],
		visibilityRadius: 150,
	},

	'med-common-01': {
		colliders: [{ type: ColliderType.Circle, position: [10, 20], radius: 50, traits: ColliderTraits.BlockAll }],
		visibilityRadius: 480,
		zOffset: -50,
	},
	'med-common-02': {
		colliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 40, traits: ColliderTraits.BlockMovementAndItem }],
		visibilityRadius: 455,
		zOffset: -50,
	},
	'med-common-03': {
		colliders: [{ type: ColliderType.Circle, position: [5, -19], radius: 40, traits: ColliderTraits.BlockAll }],
		visibilityRadius: 390,
		zOffset: -50,
	},
	'med-common-04': {
		colliders: [{ type: ColliderType.Circle, position: [-20, -37], radius: 79, traits: ColliderTraits.BlockAll }],
		visibilityRadius: 385,
		zOffset: -50,
	},

	'med-uncommon-01': {
		colliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 50, traits: ColliderTraits.BlockAll }], // placeholder
		visibilityRadius: 380,
	},
	'med-uncommon-02': {
		colliders: [{ type: ColliderType.Circle, position: [-1, -32], radius: 65, traits: ColliderTraits.BlockAll }],
		visibilityRadius: 480,
		zOffset: 0,
	},
	'med-uncommon-03': {
		colliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 50, traits: ColliderTraits.BlockAll }], // placeholder
		visibilityRadius: 435,
	},

	'large-uncommon-01-var01': {
		colliders: [{ type: ColliderType.Ellipse, position: [189, 99], rX: 378, rY: 160, traits: ColliderTraits.BlockMovementAndItem }],
		zOffset: 0,
		visibilityRadius: 605,
	},
	'large-uncommon-01-var02': {
		colliders: [{ type: ColliderType.Ellipse, position: [189, 99], rX: 378, rY: 160, traits: ColliderTraits.BlockMovementAndItem }],
		zOffset: 40,
		visibilityRadius: 605,
	},
	'large-uncommon-01-var03': {
		colliders: [{ type: ColliderType.Ellipse, position: [17, 6], rX: 288, rY: 160, traits: ColliderTraits.BlockMovementAndItem }],
		zOffset: -20,
		visibilityRadius: 605,
	},
	'large-rare-01-var01': {
		colliders: [{ type: ColliderType.Ellipse, position: [21, -35], rX: 377, rY: 200, traits: ColliderTraits.BlockAll }],
		visibilityRadius: 750,
	},
	'large-rare-01-var02': {
		colliders: [{ type: ColliderType.Ellipse, position: [13, 22], rX: 471, rY: 241, traits: ColliderTraits.BlockAll }],
		visibilityRadius: 710,
	},
	'large-rare-01-var03': {
		colliders: [{ type: ColliderType.Ellipse, position: [-34, -27], rX: 377, rY: 167, traits: ColliderTraits.BlockAll }],
		visibilityRadius: 700,
	},
	'large-rare-02-var01': {
		colliders: [{ type: ColliderType.Ellipse, position: [-70, 50], rX: 350, rY: 200, traits: ColliderTraits.BlockMovementAndItem }],
		zOffset: -700,
		visibilityRadius: 680,
	},
	'large-rare-02-var02': {
		colliders: [
			{ type: ColliderType.Ellipse, position: [-16, 15], rX: 399, rY: 272, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [264, 236], rX: 57, rY: 148, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-365, -189], rX: 57, rY: 148, traits: ColliderTraits.BlockMovementAndItem },
		],
		zOffset: -400,
		visibilityRadius: 675,
	},
	'large-rare-02-var03': {
		colliders: [
			{ type: ColliderType.Ellipse, position: [-13, 18], rX: 415, rY: 292, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-435, -138], rX: 69, rY: 98, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-198, 257], rX: 69, rY: 98, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-158, -319], rX: 69, rY: 62, traits: ColliderTraits.BlockMovementAndItem },
		],
		zOffset: -400,
		visibilityRadius: 575,
	},
	outpost: {
		zOffset: -250,
		dontLoadAsset: true,
	},
	'outpost-window': {
		zOffset: -5000,
		dontLoadAsset: true,
	},
	ground_patch: {
		zOffset: -50000,
		dontLoadAsset: true,
	},
	worm_sign: {
		zOffset: -25,
		dontLoadAsset: true,
	},
	campfire: {
		zOffset: -25,
		dontLoadAsset: true,
	},
	'prism-alter': {
		overrideAtlas: 'prism-alter',
		colliders: [
			{ type: ColliderType.Ellipse, position: [-18,-315], rX: 95, rY: 99, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [-181,-274], rX: 72, rY: 79, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [129,-276], rX: 72, rY: 79, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [-276,-178], rX: 51, rY: 48, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [260,-183], rX: 51, rY: 56, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [-249,-50], rX: 24, rY: 21, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [224,-34], rX: 24, rY: 21, traits: ColliderTraits.BlockMovementAndItem },
		],
		rigged: false,
		zOffset: -350,
		visibilityRadius: 1400,
	},
	'party-alter-animated': {
		overrideAtlas: 'prism-alter',
		zOffset: -200,
		visibilityRadius: 1400,
	},
	'poi-04': {
		rigged: true,
		zOffset: -100,
		dontLoadAsset: true,
	},
}
