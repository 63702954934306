


































import { mapGetters, mapMutations } from 'vuex'
import { debounce } from 'lodash'
import InventoryItem from '../reusable-components/inventory-item/inventory-item.vue'
import GenericEnchantmentSlot from '../reusable-components/inventory-item/enchantment-bar-slot.vue'
import { enchantmentBarTooltip } from '../reusable-components/v-tooltip/v-tooltip-functions'

export default {
	name: 'EnchantmentBar',
	components: {
		InventoryItem,
		GenericEnchantmentSlot,
	},
	computed: {
		...mapGetters('hud', ['furthestBiome', 'currentMaxBiome', 'showEnchantments']),
		...mapGetters('itemContainers', ['playerEnchantments']),
	},
	data() {
		return {
			enchantmentSlotSettings: [
				{ slotId: 1, iconName: 'null', slotIndex: 1, biome: 0 },
				{ slotId: 2, iconName: 'null', slotIndex: 2, biome: 0 },
				{ slotId: 3, iconName: 'beach', slotIndex: 3, biome: 1 },
				{ slotId: 4, iconName: 'forest', slotIndex: 4, biome: 2 },
				{ slotId: 5, iconName: 'highlands', slotIndex: 5, biome: 3 },
				{ slotId: 6, iconName: 'fungi', slotIndex: 6, biome: 4 },
				{ slotId: 7, iconName: 'prism', slotIndex: 7, biome: 5 },
			],
		}
	},
	methods: {
		...mapMutations('UIScale', ['showEnchantmentTooltip', 'updateToolTipPosition']),
		enchantmentBarTooltip,
	},
}
