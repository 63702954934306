import moment, { Moment } from 'moment'
import logger from '../../utils/client-logger'

export const activeGameWindowUIStore = () => {
	logger.debug('Initializing AGW store module...')
    return {
        namespaced: true,
        state: {
            activeGameWindowStart: null,
            activeGameWindowEnd: null,
            nextActiveGameWindowStart: null,
            nextActiveGameWindowEnd: null,
        },
        getters: {
            isInAGW(state, getters, rootState, rootGetters) {
                return (now: Moment) => {
                    if(state.activeGameWindowStart) {    
                        if(now.isAfter(state.activeGameWindowStart)) {
                            if(now.isBefore(state.activeGameWindowEnd)) {
                                return true
                            }
    
                            if(state.nextActiveGameWindowStart) {
                                return now.isAfter(state.nextActiveGameWindowStart) && now.isBefore(state.nextActiveGameWindowEnd)
                            }
                        }

                        return false
                    }
    
                    return true
                }
            },
            isAGWEnabled(state, getters, rootState, rootGetters) {
                return (now: Moment) => {
                    if(state.activeGameWindowStart) {
                        if(now.isAfter(state.activeGameWindowStart)) {
                            return Boolean(state.nextActiveGameWindowStart)
                        }

                        return true
                    }

                    return false
                }
            },
            nextAGWStartOrEnd(state, getters, rootState, rootGetters) {
                return (now: Moment) => {
                    if(state.activeGameWindowStart) {
                        if(now.isBefore(state.activeGameWindowStart)) {
                            return state.activeGameWindowStart
                        }

                        if(now.isBefore(state.activeGameWindowEnd)) {
                            return state.activeGameWindowEnd
                        }

                        if(state.nextActiveGameWindowStart) {
                            if(now.isBefore(state.nextActiveGameWindowStart)) {
                                return state.nextActiveGameWindowStart
                            }

                            if(now.isBefore(state.nextActiveGameWindowEnd)) {
                                return state.nextActiveGameWindowEnd
                            }
                        }
                    }

                    return null
                }
            }
        },
        mutations: {
            setActiveGameWindows(state, activeGameWindows: any[]) {
                if(activeGameWindows && activeGameWindows.length > 0) {
                    const nextOrActiveWindow = activeGameWindows[0]
                    state.activeGameWindowStart = moment(nextOrActiveWindow.startTime).utc()
                    state.activeGameWindowEnd = moment(nextOrActiveWindow.endTime).utc()

                    if(activeGameWindows.length > 1) {
                        const nextWindow = activeGameWindows[1]
                        state.nextActiveGameWindowStart = moment(nextWindow.startTime).utc()
                        state.nextActiveGameWindowEnd = moment(nextWindow.endTime).utc()
                    }
                } else {
                    state.activeGameWindowStart = null
                    state.activeGameWindowEnd = null
                    state.nextActiveGameWindowStart = null
                    state.nextActiveGameWindowEnd = null
                }
            }
        },
        actions: {

        }
    }
}