<template>
	<div id="active-weapons">
		<div class="duo-slot-wrapper">
			<div class="weapon-wrapper weapon-one" :class="activeWeapon === equippedWeapons.one ? 'active' : 'inactive'" @mouseenter.exact="onMouseEnter($event, 'weapon', equippedWeapons.one)" @mouseenter.ctrl="onMouseEnterCtrl($event)" @mouseleave="onMouseLeave">
				<InventoryItem class="weapon" :class="activeWeapon === equippedWeapons.one ? 'active' : 'inactive'" :item="equippedWeapons.one" :show-slot="false" :selectable="false" :tooltipable="false"></InventoryItem>
			</div>
			<div class="weapon-wrapper weapon-two" :class="activeWeapon === equippedWeapons.two ? 'active' : 'inactive'" @mouseenter.exact="onMouseEnter($event, 'weapon', equippedWeapons.two)" @mouseenter.ctrl="onMouseEnterCtrl($event)" @mouseleave="onMouseLeave">
				<InventoryItem class="weapon" :class="activeWeapon === equippedWeapons.two ? 'active' : 'inactive'" :item="equippedWeapons.two" :show-slot="false" :selectable="false" :tooltipable="false"></InventoryItem>
			</div>
			<div class="hotkey-text">Q</div>
			<div id="active-weapons-tutorial-tooltip-target"></div>
		</div>
	</div>
</template>

<script lang="js">
import { mapGetters, mapMutations } from 'vuex'
import InventoryItem from '../reusable-components/inventory-item/inventory-item.vue'

export default {
	name: 'ActiveWeapons',
	components: {
		InventoryItem,
	},
	computed: {
		...mapGetters('itemContainers', ['activeWeapon', 'inactiveWeapon', 'equippedWeapons']),
	},
	methods: {
		...mapMutations('UIScale', ['showTooltip', 'showTooltipDebug', 'updateToolTipPosition', 'currentComponentHovered']),
		onMouseEnter(event, component, weaponDetails) {
			if (this.tooltipable === false) return
			if(this.activeWeapon !== weaponDetails) return
			this.updateToolTipPosition(event)
			this.showTooltip(weaponDetails)
			this.currentComponentHovered(component)
		},
		onMouseEnterCtrl(event, weaponDetails) {
			if (this.tooltipable === false) return
			this.updateToolTipPosition(event)
			this.showTooltipDebug(weaponDetails)
		},
		onMouseLeave() {
			if (this.tooltipable === false) return
			this.currentComponentHovered(null)
			this.showTooltip(null)
		},
		onMouseMove(event) {
			if (this.tooltipable === false) return
			this.moveTooltip(event)
		},
	},
}
</script>

<style lang="less" scoped>
#active-weapons {
	position: absolute;
	bottom: 0px;
	left: 0px;
	pointer-events: all;
	background-image: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1));
	width: 432px;
	height: 150px;

	.hotkey-text {
		position: absolute;
		bottom: 0px;
		left: 30px;
		text-align: center;
		font-family: 'Do Hyeon', sans-serif;
		font-weight: 1000;
		color: white;
	}

	.duo-slot-wrapper {
		position: absolute;
		bottom: 10px;
		left: 20px;

		background-image: url('../hud/assets/equipped-items-slots.png');
		width: 153px;
		height: 130px;
	}

	.weapon-wrapper {
		position: absolute;

		&.weapon-one {
			bottom: 16px;
			left: 1px;
		}
		&.weapon-two {
			bottom: -13px;
			left: 47px;
			transform: scale(0.85);
		}
		&.active {
			z-index: 100;
			pointer-events: all;
			filter: drop-shadow(0 0 13px #e6e6e6);
		}

		&.inactive {
			z-index: 99;
			opacity: 0.4;
		}
	}
}
</style>
