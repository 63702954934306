import { NengiClient } from '../../engine/client/nengi-client'
import logger from '../../utils/client-logger'
import { uuid } from '../../utils/primitive-types'
import { UI } from '../../ui/ui'
import { clone, filter, find, map, reduce, uniq } from 'lodash'
import ApplyAugmentsToWeaponCommand from '../../items/shared/attach-augment-to-weapon-command'
import { Audio } from '../../audio/client/audio'
import { invalidAugmentForWeaponType } from '../../items/shared/augment-constraints'
import { canEquipItemOfLevel } from '../../engine/shared/game-data/player'
import { postWaitForCondition } from './advanced-tutorial-tooltip.ui-state'
import { WaitCondition } from './WaitCondition'
import PreviewAugmentsCommand from '../../items/shared/preview-augments-command'
import BuyAugmentSlotCommand from '../../items/shared/buy-augment-slot-command'
import { getAutoAugments } from '../../items/shared/auto-augment-functions'
import ItemType from '../../loot/shared/item-type'
import { getAugmentUnlockCost } from '../../items/shared/unlock-augment-slot-functions'

export const augmentationStation = () => {
	logger.debug('Initializing Augmentation Station UI module')
	return {
		namespaced: true,
		state: {
			selectedItemId: '',
			hasSelectedItem: false,
			augmentsToApply: [],
			alreadyEquippedAugments: [],
			previewItem: {},
			ftueMode: false,
			isAutoComputing: false,
			unlockAugmentCosts: [],
		},
		mutations: {
			addAugmentToApply(state, item) {
				Audio.getInstance().playSfx('SFX_Item_Augment')
				const itemDetails = UI.getInstance().store.getters['itemContainers/itemDetails']('equippedWeapon', state.selectedItemId)
				if (state.augmentsToApply.length < itemDetails.augmentLimit) {
					state.augmentsToApply.push(item)
					state.augmentsToApply = uniq(state.augmentsToApply)
					postWaitForCondition(WaitCondition.PLAYER_CLICKED_ATTACH_AUG)

					state.previewItem.equippedAugmentIds.push(item.id)
					const augmentIds = map(state.augmentsToApply, (augment) => augment.id)
					NengiClient.getInstance().sendCommand(new PreviewAugmentsCommand(augmentIds, state.previewItem.id))
				}
			},
			removeAugmentToApply(state, item) {
				Audio.getInstance().playSfx('SFX_Item_Augment')
				state.augmentsToApply = filter(state.augmentsToApply, (augment) => item.id !== augment.id)

				state.previewItem.equippedAugmentIds = filter(state.previewItem.equippedAugmentIds, (id) => {
					if (item.id === id) {
						return false
					}
					return true
				})

				const augmentIds = map(state.augmentsToApply, (augment) => augment.id)
				NengiClient.getInstance().sendCommand(new PreviewAugmentsCommand(augmentIds, state.previewItem.id))
			},
			clearAugmentsToApply(state) {
				state.augmentsToApply = []
				const itemDetails = UI.getInstance().store.getters['itemContainers/itemDetails']
				state.previewItem.equippedAugmentIds = []

				NengiClient.getInstance().sendCommand(new PreviewAugmentsCommand([], state.previewItem.id))
			},
			setFTUEMode(state, enabled) {
				state.ftueMode = enabled
			},
			setPreviewDetails(state, payload) {
				const { itemId, tooltipData } = payload

				if (itemId === state.previewItem.id) {
					const data = JSON.parse(tooltipData)
					state.previewItem.statsWithAugments = data

					this.commit(
						'itemComparison/setBothItemsDirectly',
						{
							item1Id: state.selectedItemId,
							item2: state.previewItem,
						},
						{ root: true },
					)
				}
			},
			embeddedUpdated(state) {
				if (state.previewItem && state.previewItem.equippedAugmentIds) {
					const itemDetailsFunc = UI.getInstance().store.getters['itemContainers/itemDetails']

					state.alreadyEquippedAugments = []

					for (let i = 0; i < state.previewItem.equippedAugmentIds.length; ++i) {
						const found = itemDetailsFunc('embedded', state.previewItem.equippedAugmentIds[i])
						if (found) {
							state.alreadyEquippedAugments.push(found)
						}
					}
				}
			},
			updateUnlockAugmentCosts(state: any) {
				const itemDetails = UI.getInstance().store.getters['itemContainers/itemDetails']('equippedWeapon', state.selectedItemId)
				if (itemDetails) {
					const augmentCosts = getAugmentUnlockCost({level: itemDetails.level, rarity: itemDetails.rarityEnum, augmentLimit: itemDetails.augmentLimit})
						if(augmentCosts){
							state.unlockAugmentCosts = augmentCosts
						}
				}
			},
		},
		getters: {
			getRarityShardsBasedOnItem(state){
				const itemsInStash = UI.getInstance().store.getters['itemContainers/itemsInContainer']('stash')
				const weapon = UI.getInstance().store.getters['itemContainers/itemDetails']('equippedWeapon', state.selectedItemId)
				const rarityShards = itemsInStash.filter(item => item.itemTypeEnum === ItemType.RarityShard && item.rarityEnum === weapon.rarityEnum).reduce((acc, item) => acc += item.stackAmount, 0)
				return rarityShards
			},
			currentSelectedItem(state) {
				const itemDetails = UI.getInstance().store.getters['itemContainers/itemDetails']('equippedWeapon', state.selectedItemId)
				return itemDetails
			},
			hasSelectedItem(state) {
				return state.hasSelectedItem
			},
			augmentCannotAttach(state, getters, rootState, rootGetters) {
				return (item) => {
					if (item.unidentified) {
						return true
					}
					const progressionLevel = rootGetters['hud/progressionLevel']
					if (!canEquipItemOfLevel(progressionLevel, item.level)) {
						return true
					}
					const itemDetails = UI.getInstance().store.getters['itemContainers/itemDetails']('equippedWeapon', state.selectedItemId)
					if (invalidAugmentForWeaponType(item, itemDetails)) {
						// TODO2: there is no tooltip for this case, user may not know why it can't be attached
						return true
					}
					if (!!find(state.augmentsToApply, (augment) => augment.id === item.id)) {
						return true
					}

					return state.ftueMode && !item.name.toUpperCase().includes('NEWBIE')
				}
			},
			totalAugmentCost(state) {
				const updatedWeapon = UI.getInstance().store.getters['itemContainers/itemDetails']('equippedWeapon', state.selectedItemId)
				const newAugments = filter(state.augmentsToApply, (augment) => !updatedWeapon.equippedAugmentIds.includes(augment.id))
				return reduce(newAugments, (acc, augment) => acc + augment.augmentCost, 0).toString()
			},
			getSelectedItemId(state) {
				return state.selectedItemId
			},
			getUnlockAugmentCosts(state) {
				return state.unlockAugmentCosts
			}
		},
		actions: {
			commitPurchaseAugmentSlot({ state, commit, dispatch }) {
				const weaponId = state.selectedItemId
				NengiClient.getInstance().sendCommand(new BuyAugmentSlotCommand(weaponId))
			},
			commitAugmentsToWeapon({ state, commit, dispatch }) {
				console.log('Committing augment state')
				const weaponId = state.selectedItemId
				const augmentIds = map(state.augmentsToApply, (augment) => augment.id)
				// Write a command to commit the augments
				NengiClient.getInstance().sendCommand(new ApplyAugmentsToWeaponCommand(augmentIds, weaponId))
				postWaitForCondition(WaitCondition.PLAYER_ATTACHED_AUGMENT)
			},
			backToStash({ state, commit, dispatch }) {
				UI.getInstance().emitEvent('inGame/setActivePanel', 'stash')
				UI.getInstance().emitEvent('itemComparison/deselectEquippedItem')
			},
			attachAugmentToWeapon({ state, commit, dispatch }, { augmentId, weaponId }: { augmentId: uuid; weaponId: uuid }) {
				console.log('State:', state)
				console.log({ augmentId, weaponId })
				// NengiClient.getInstance().sendCommand(new AttachAugmentToWeaponCommand(augmentId, weaponId))
			},
			removeAugmentFromWeapon({ state, commit, dispatch }, { augmentId, weaponId }: { augmentId: uuid; weaponId: uuid }) {
				console.log('State:', state)
				console.log({ augmentId, weaponId })
				// NengiClient.getInstance().sendCommand(new DetachAugmentFromWeaponCommand(augmentId, weaponId))
			},
			selectedWeaponToAugment({ rootGetters, state }, itemId) {
				const itemDetailsFunc = rootGetters['itemContainers/itemDetails']
				const itemDetails = itemDetailsFunc('equippedWeapon', itemId)
				state.selectedItemId = itemId
				state.augmentsToApply = map(itemDetails.equippedAugmentIds, (id) => rootGetters['itemContainers/itemDetails']('embedded', id))
				state.hasSelectedItem = true
				state.previewItem = clone(itemDetails)
				state.previewItem.equippedAugmentIds = clone(itemDetails.equippedAugmentIds)

				state.alreadyEquippedAugments = []
				for (let i = 0; i < state.previewItem.equippedAugmentIds.length; ++i) {
					state.alreadyEquippedAugments.push(itemDetailsFunc('embedded', state.previewItem.equippedAugmentIds[i]))
				}

				this.commit(
					'itemComparison/setBothItemsDirectly',
					{
						item1Id: itemId,
						item2: state.previewItem,
					},
					{ root: true },
				)

				UI.getInstance().emitEvent('inGame/setActivePanel', 'augmentationStation')
			},
			async applyAutoAugments({ state, dispatch, commit, getters, rootGetters }) {
				state.isAutoComputing = true
				try {
					const weapon = getters.currentSelectedItem
					const cantAttachFn = getters.augmentCannotAttach
					const augsInStash = rootGetters['itemContainers/itemsInContainer']('stash')
						.concat(state.alreadyEquippedAugments)
						.filter((item) => item.itemTypeEnum === ItemType.WeaponAugment && !cantAttachFn(item))
					const augments = await getAutoAugments(weapon, state.augmentsToApply, augsInStash)

					for (let i = state.augmentsToApply.length; i < augments.length; ++i) {
						const item = augments[i]
						state.augmentsToApply.push(item)
						state.augmentsToApply = uniq(state.augmentsToApply)
						postWaitForCondition(WaitCondition.PLAYER_CLICKED_ATTACH_AUG)

						state.previewItem.equippedAugmentIds.push(item.id)
					}

					const augmentIds = map(state.augmentsToApply, (augment) => augment.id)
					NengiClient.getInstance().sendCommand(new PreviewAugmentsCommand(augmentIds, state.previewItem.id))
				} catch (err) {
					console.error(err)
				} finally {
					state.isAutoComputing = false
				}
			},
		},
	}
}
