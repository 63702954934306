import nengi from 'nengi'
import { uuid } from '../../utils/primitive-types'

class ManyItemsUnlockedMessage {
	static protocol = {
		itemIds: { type: nengi.String, indexType: nengi.UInt16 },
	}

	itemIds: uuid[]

	constructor(itemIds) {
		this.itemIds = itemIds
	}
}

export default ManyItemsUnlockedMessage
