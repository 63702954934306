<template>
	<MainPanel id="faq" :on-close-btn-click="closePanel" panel-identifier="faq" :height="1037" :show-gold-currency="false" :show-scale-currency="false" :show-faq-icon="true">
		<template #panel-content>
			<div class="faq">
				<div v-for="(item, index) in faqs" :key="index">
					<AccordionButton :accordion-item="item"></AccordionButton>
				</div>
			</div>
		</template>
	</MainPanel>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import AccordionButton from '../reusable-components/buttons/accordion-button.vue'

export default {
	name: 'Faq',
	components: {
		MainPanel,
		AccordionButton,
	},
	data() {
		return {
			faqs: [
				{
					id: 1,
					title: `Who can play LOOT.io?`,
					body: `We recommend that players under 16 years of age request permission from a caregiver before creating an account.`,
				},
				{
					id: 2,
					title: `Do I need to spend money to play the game or use the marketplace?`,
					body: `LOOT is free to play. You do not need to spend any money to obtain items or participate in the marketplace. While marketplace transactions have an associated fee, you may list a certain number of items for sale each day for free. Any items you find in-game are yours to keep at no cost!`,
				},
				{
					id: 3,
					title: `If LOOT is free to play, how does Other Ocean make money?`,
					body: `Other Ocean continues to develop and support LOOT by collecting modest fees on marketplace transactions, supplemented by the sale of cosmetic items in the store.`,
				},
				{
					id: 4,
					title: `What are “custodial” and “player” wallets? Why do I need them?`,
					body: `Under the hood, items are stored in wallets. Each account is linked to a wallet address. A custodial wallet, in our case, means the wallet address and contents are managed by Forte, so the player does not need to worry about any technical details. </br></br>If a player purchases Prism Scales or elects to interact with their wallet contents through the Forte exchange, their wallet will then be considered a 'Player Wallet'.</br></br>Whether it is a custodial- or player-wallet, the assets are always owned by the player.`,
				},
				{
					id: 5,
					title: `Will LOOT support secondary marketplaces outside of the game?`,
					body: `Not at this time. All marketplace transactions will occur in-game for safety and simplicity. Any third party marketplaces claiming to offer LOOT items are unauthorized and likely to be a fraud or scam.`,
				},
				{
					id: 6,
					title: `What's the difference between Coins and Prism Scales?`,
					body: `Coins and Prism Scales are both used in LOOT, but for different purposes. Coins are found in-game, they're for building up/boosting your own character. Prism Scales are purchased through Forte Exchange to spend in the marketplace and cosmetics shop. While Prism Scales can be purchased, they maintain a stable value and can not be directly traded on the open market at this time.`,
				},
				{
					id: 7,
					title: `What is play-to-own? How is loot generated?`,
					body: `As you adventure through the world of LOOT, items ranging from weapons and armor to augments and pet eggs will drop from enemies you defeat. Send them back to town before your character is killed or you exit the game, and those items will be added to your stash!`,
				},
				{
					id: 8,
					title: `Which regions do you currently support?`,
					body: `LOOT game servers are currently located in North America (New York). A ‘Client Side Character Prediction’ feature is available to reduce the latency you may experience if you are far from our data centers.</br></br>Please note that Forte Exchange, and the purchase of Prism Scales, is temporarily limited to certain US states at this time. Please check with Forte Exchange to see the most up-to-date list of supported regions.`,
				},
				{
					id: 9,
					title: `What is Forte Exchange?`,
					body: `Forte Exchange allows you to manage game currency balances that are hosted on Forte's service. </br></br>You can learn more at: <a href="https://exchange.forte.io/portal/faq" target="_blank">https://exchange.forte.io/portal/faq</a> (requires linked account)
`,
				},
			],
		}
	},
	computed: {
		...mapGetters(['currentUIMode']),
	},
	methods: {
		...mapActions('inGame', ['closeActivePanel']),
		...mapMutations('inGame', ['setActivePanel', 'updateShowNFTMap']),

		closePanel() {
			if (this.currentUIMode === 'in-game') {
				this.closeActivePanel()
				this.updateShowNFTMap(true)
				this.setActivePanel('premiumStore')
			} else {
				this.closeActivePanel()
			}
		},
	},
}
</script>

<style lang="less" scoped>
@import '../global/fonts/text.less';
@import url('../global/bg-gradients.less');

#faq {
	.faq {
		height: 837px;
		width: 1050px;
		overflow-y: scroll;
		div {
			margin: 40px 0px;
		}
	}
}
</style>
