<template>
	<div class="economy-container">
		<div class="economy-text-wrapper">
			<div>Begin your quest to collect loot! The more you play, the more you own. Trade with other players to procure the finest items. In Loot, the economy is directly in your hands!</div>
		</div>
		<div class="wisp"></div>
	</div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
	name: 'EconomyText',
	methods: {
		...mapMutations('mainMenu', ['changeMainMenuScreen']),
	},
}
</script>

<style lang="less">
@import '../global/fonts/text.less';

.economy-container {
	display: flex;
	position: relative;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	.item-names-and-subheaders();
	font-size: 30px;
	.economy-text-wrapper {
		background-image: url('./assets/text-bg.png');
		width: 780px;
		height: 144px;
		background-size: cover;
		text-align: center;
		div {
			width: inherit;
			height: inherit;
			display: flex;
			align-items: center;
			padding: 0px 13px;
		}
	}
	.wisp {
		position: absolute;
		right: -155px;
		background-image: url('./assets/professor-wisp-thinking.png');
		width: 141px;
		height: 152px;
		background-size: cover;
		&:hover {
			background-image: url('./assets/professor-wisp-thinking-hover.png');
		}
	}
}

@media only screen and (min-height: 768px) {
	.economy-container {
		top: 25px;
	}
}
</style>
