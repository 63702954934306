













import { mapGetters } from 'vuex'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import PanelDivider from '../reusable-components/panel-divider/panel-divider.vue'

export default {
	name: 'Enqueued',
	components: {
		MainPanel,
		PanelDivider,
	},
	computed: {
		...mapGetters('loginQueue', ['queueMessage']),
	},
	methods: {
		abortQueue() {
			window.location.reload(true)
		},
	},
}
