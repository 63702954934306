




// More Easing types can be found here: https://greensock.com/get-started/#easing

export const EASING_TYPES = ['none', 'power1.out', 'power2.out', 'power3.out', 'power4.out', 'circ.out']
import gasp from 'gsap'

export default {
	name: 'Tally',
	components: {},
	data() {
		return {
			fromValue: this.from,
		}
	},
	props: {
		to: {
			type: Number,
			required: true,
			default: () => {
				console.log('Using the default :to prop value. Ensure you pass it a value.')
				return 0
			},
		},
		from: {
			type: Number,
			required: true,
			default: () => {
				console.log('Using the default :from prop value. Ensure you pass it a value.')
				return 1000
			},
		},
		duration: {
			type: Number,
			required: true,
			default: () => {
				console.log('Using the default :duration prop value. Ensure you pass it a value in seconds.')
				return 1
			},
		},
		easing: {
			type: String,
			required: false,
			default: 'power1.out',
			validator: function(value) {
				return EASING_TYPES.includes(value)
			},
		},
		nextAnimation: {
			type: String,
			required: false,
			default: () => {
				console.log('Using the default :nextAnimation prop value. Ensure you pass it a value.')
				return 'default'
			},
		}
	},
	computed: {
		formatNumber(){
			return this.fromValue.toFixed()
		}
	},
	watch: {
		to(newValue) {
			this.animate(newValue)
		},
	},
	methods: {
		animate(value) {
			const context = this
			const tweenAnimation = gasp.to(context.$data, {
				duration: context.duration,
				fromValue: value,
				ease: context.easing,
				onComplete: () => context.$emit('animationComplete', this.nextAnimation, this.fromValue ),
				onUpdate: () => context.$emit('animationUpdated', this.fromValue),
			})
			this.animate.tweenAnimation = tweenAnimation
		},
		playAnimation(){
			this.animate(this.to)
			this.animate.tweenAnimation.play()
		}
	},
}
