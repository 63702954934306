import nengi from 'nengi'
import { uuid } from '../../utils/primitive-types'

class UnequipGearFromSlotCommand {
	static protocol = {
		gearId: nengi.String,
	}
	gearId: uuid
	/**
	 * Creates an instance of EquipWeaponToSlotCommand. This will scan the Stash to find a matching item.
	 * @param {uuid} gearId Weapon ID
	 * @memberof UnequipGearFromSlotCommand
	 */
	constructor(gearId: uuid) {
		this.gearId = gearId
	}
}

export default UnequipGearFromSlotCommand
