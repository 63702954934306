






































import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import PanelDivider from '../reusable-components/panel-divider/panel-divider.vue'
import ArrowButton from '../reusable-components/buttons/arrow-button.vue'
import CategoryFilter from '../reusable-components/filtering/category-filter.vue'
import TableData from '../reusable-components/detailed-item-view/table-data.vue'
import { startGame, endGame } from '../../engine/client/start-stop'
import { GameClient } from '../../engine/client/game-client'
import { NengiClient } from '../../engine/client/nengi-client'
import PartyExitGameCommand from '../../party-play/shared/party-exit-game-command'
import SelectedSoulBonds from '../reusable-components/soul-bonds/SelectedSoulBonds.vue'
import FactionTableView from '../reusable-components/faction/faction-table-data.vue'
import { pressureStatInfos } from '../../engine/shared/game-data/pressure-stat-info.shared'
import { getHeatImageFile } from '../../engine/client/pressure.client'
import factionProgressBar from '../reusable-components/faction/faction-progress-bar.vue'


import { UI } from '../ui'

const heatImages = require('../reusable-components/inventory-item/assets/heat-*.png')

export default {
	name: 'EndRunUpdated',
	components: {
		MainPanel,
		PanelDivider,
		ArrowButton,
		CategoryFilter,
		TableData,
		SelectedSoulBonds,
		FactionTableView,
		factionProgressBar
	},
	data() {
		return {
			heatImages: heatImages,

			image1: '../reusable-components/inventory-item/assets/heat-wilting.png',
		}
	},
	computed: {
		...mapState('gameOver', ['runStats', 'activeGameOverFilter']),
		...mapState('factions', ['playerFactionPoints', 'affiliatedFaction']),
		...mapGetters('boatLaunch', ['hasReachedBiomeOnTier', 'maxAllowedTier', 'getPressureRank', 'hasUnlockedSoul']),
		...mapGetters('inGame', ['activePanel']),
		...mapGetters('gameOver', ['getRunStats', 'getDamageTakenStats']),
		...mapGetters('party', ['getPartyId']),
		difficultyModifiers: function () {
			const difficultyModifiers = []
			for (let pressureStat in pressureStatInfos) {
				if (pressureStatInfos.hasOwnProperty(pressureStat)) {
					difficultyModifiers.push({
						...pressureStatInfos[pressureStat],
						ranksSelected: this.getPressureRank(pressureStat),
						statType: pressureStat,
					})
				}
			}
			return difficultyModifiers
		},
		modifiersSectionHeight() {
			return (this.difficultyModifiers.length + 2) * 60
		},
	},
	methods: {
		...mapActions('inGame', ['closeActivePanel', 'setPanelSwitchable']),
		...mapMutations('itemContainers', ['emptyPlayerEnchantments']),
		goToHub: function () {
			const partyId = this.getPartyId
			if (partyId) {
				GameClient.getInstance().exitGameFunction = () => {
					this.setPanelSwitchable(true)
					endGame()
					// @ts-expect-error TODO2: store this not on the window
					startGame('hub', window.originalConnectionPayload, partyId)
				}

				NengiClient.getInstance().sendCommand(new PartyExitGameCommand(partyId))
			} else {
				this.setPanelSwitchable(true)
				endGame()
				// @ts-expect-error TODO2: store this not on the window
				startGame('hub', window.originalConnectionPayload)
			}
		},
		getHeatImage(stat) {
			return heatImages[getHeatImageFile(stat)]
		},
	},
}
