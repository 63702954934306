import nengi from 'nengi'
import { biomeIndex, nengiId, percentage } from '../../utils/primitive-types'
import { POIType } from './poi-data-types'

class POI {
	static protocol = {
		x: { type: nengi.Float32 },
		y: { type: nengi.Float32 },

		type: { type: nengi.UInt10 },
		poiIndex: { type: nengi.Int10 },
		biomeIndex: { type: nengi.Int8 },
		eventActive: { type: nengi.Boolean },
		text: { type: nengi.String },
		progress: { type: nengi.Float32 },
		eventResetCountdown: { type: nengi.Int10 }, // an int as that's all the client cares about, and it reduces vue updates on client

		shapeString: { type: nengi.String },
		radius: { type: nengi.UInt16 },
		w: { type: nengi.Number },
		h: { type: nengi.Number },
		
		arenaShapeString: {type: nengi.String },

		wormMailId: { type: nengi.Int32 },
		bossId: { type: nengi.Int32 },
		departId: { type: nengi.Int32 },
	}

	nid: number
	x: number = 0
	y: number = 0

	type: POIType
	poiIndex: number
	biomeIndex: biomeIndex
	eventActive: boolean
	text: string
	progress: percentage
	eventResetCountdown: number

	shapeString: string = ''
	radius: number = 0
	w: number = 0
	h: number = 0

	arenaShapeString: string

	wormMailId: nengiId = -1
	bossId: nengiId = -1
	departId: nengiId = -1
}

export default POI
