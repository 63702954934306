import nengi from 'nengi'
import { ProjectileEffectType } from './projectile-types'

export class ProjectileSplashMessage {
	static protocol = {
		x: { type: nengi.Number },
		y: { type: nengi.Number },
		effectType: { type: nengi.Number }, // damage, heal, etc...
		owningEntityId: { type: nengi.Number },
		damageType: { type: nengi.Number },
	}

	x: number
	y: number
	effectType: ProjectileEffectType
	owningEntityId: number
	damageType: number

	constructor(params: ProjectileSplashMessage) {
		this.x = params.x
		this.y = params.y
		this.owningEntityId = params.owningEntityId
		this.effectType = params.effectType
		this.damageType = params.damageType
	}
}
