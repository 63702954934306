import nengi from "nengi"
import { FactionShortName } from "./faction-data"

export default class ClaimFactionPrizeCommand {
	static protocol = {
		debugNoCheckPrizeState: nengi.Boolean,
		debugForcedFaction: nengi.String,
		debugForcedRep: nengi.Int32,
		debugForcedTopPercent: nengi.UInt8
	}

	debugNoCheckPrizeState: boolean
	debugForcedFaction: FactionShortName
	debugForcedRep: number
	debugForcedTopPercent: number

	constructor(noCheckPrizeState: boolean, forcedFaction: FactionShortName, forcedRep: number, forcedPercent: number) {
		this.debugNoCheckPrizeState = noCheckPrizeState
		this.debugForcedFaction = forcedFaction
		this.debugForcedRep = forcedRep
		this.debugForcedTopPercent = forcedPercent
	}
}
