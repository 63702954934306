import nengi from 'nengi'

class Identity {
	static protocol = {
		entityId: nengi.UInt16,
	}
	entityId: number
	constructor(entityId) {
		this.entityId = entityId
	}
}

export default Identity
