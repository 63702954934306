import nengi from 'nengi'
import { uuid } from '../../utils/primitive-types'

class weaponAugmentSlotUpdateMessage {
	static protocol = {
		weaponId: nengi.String,
	}
	weaponId: uuid
	constructor(weaponId: uuid) {
		this.weaponId = weaponId
	}
}

export default weaponAugmentSlotUpdateMessage
