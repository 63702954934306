import nengi from 'nengi'
import { FactionShortName } from './faction-data'

export default class FactionSetResultMessage {
	static protocol = {
		factionSet: nengi.Boolean,
		faction: nengi.String,
		gotPrize: nengi.Boolean
	}

	factionSet: boolean
	faction: FactionShortName
	gotPrize: boolean

	constructor(factionSet: boolean, faction: FactionShortName, gotPrize: boolean) {
		this.factionSet = factionSet
		this.faction = faction
		this.gotPrize = gotPrize
	}
}
