import { EnemyAI, FightingTriggers, IdleBehaviours, FightingBehaviours, LeashTriggers, LeashingBehaviours, AttackTypes, DeadBehaviours, ShotLeadPrecision, EnemyType, EnemyTargetingStyles } from '../../shared/ai-types'
import { ModType, ModValueType } from '../../../projectiles/shared/projectile-types'
import { SpineDataName, EnemyBipedSkinType } from '../../../models-animations/shared/spine-config'
import { EnemyDefaults } from './enemy-defaults'
import { Colors } from '../../../utils/colors'
import { Vector } from 'sat'
import { ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { ParticleEffectType } from '../../../engine/shared/game-data/particle-config'
import { ENEMY_DEFAULT_HEALTH } from '../../../engine/shared/game-data/enemy-formulas'
import { Ability, AbilitySelectionStyles, AbilityTargets, AbilityTargetSelectionStyle, AbilityType, ActionCriteria, CriteriaSatisfactionQuantity, CriteriaTarget, CriteriaType } from '../action-types'
import { BuffIdentifier } from '../../../buffs/shared/buff.shared'
import { AnimationTrack } from '../../../models-animations/shared/animation-track'
import { attackRatedAbilityList } from '../action-criteria/ability-helpers'
import { alwaysTrueCriteria, waitAbility } from './abilities/common-abilities'
import { ENEMY_NAMES } from './enemy-names'
import { WorldDifficulty } from '../../../engine/shared/game-data/world-difficulty'
import { deepClone } from '../abilities.test'
import { worldDifficultyBrutalCriterion,  } from '../action-criteria/action-criteria-helpers'

const playerIsInAttackRangeCriteria: ActionCriteria = {
	satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
	criterias: [
		{
			criteriaTargets: CriteriaTarget.PLAYERS,
			criteriaType: CriteriaType.WITHIN_RANGE,
			criteriaValue: 650,
			minimumTargetsThatSatisfy: 1,
		},
	],
}

const playerIsInAttackRangeBrutalCriteria: ActionCriteria = {
	satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
	criterias: [
		worldDifficultyBrutalCriterion,
		{
			criteriaTargets: CriteriaTarget.PLAYERS,
			criteriaType: CriteriaType.WITHIN_RANGE,
			criteriaValue: 800,
			minimumTargetsThatSatisfy: 1,
		},
	],
}

export const cooldownEnemyCooldown3000: Ability = {
	debugName: 'cooldown3000',
	abilityType: AbilityType.INSTANTLY_APPLY_EFFECT,
	buffToApply: BuffIdentifier.EnemyCooldown3000,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.MYSELF,
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
}

const animationTimes = {
	[AnimationTrack.SHOOT]: 0.3,
}

const sporeKidRangedAttackAbility: Ability = {
	debugName: 'sporeWeakShot',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.CLOSEST,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		projectileCount: 1,
		spreadAngle: 0,
		speed: 450,
		colliderRadius: 12,
		lifespanInSeconds: 5,
		modifiers: [{ modType: ModType.WAVE, amplitude: { modValueType: ModValueType.VALUE, value: 0.65 }, period: { modValueType: ModValueType.VALUE, value: 1.2 } }],
		basePhysicalDamage: 25,
		burstCount: 1,
		color: Colors.green,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_SPOREKID,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_TRAIL,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
		applyBuffsOnHit: [BuffIdentifier.EnemySporeKidWeakness],
	},
}

export const sporeKidRangedAttackAbilityBrutal = deepClone(sporeKidRangedAttackAbility)
sporeKidRangedAttackAbilityBrutal.projectileConfig.speed = 550
sporeKidRangedAttackAbilityBrutal.projectileConfig.lifespanInSeconds = 6.5
sporeKidRangedAttackAbilityBrutal.projectileConfig.basePhysicalDamage = 20
sporeKidRangedAttackAbilityBrutal.projectileConfig.basePoisonDamage = 20

export const sporeKidHealAbility: Ability = {
	debugName: 'sporeHeal',
	abilityType: AbilityType.INSTANTLY_APPLY_EFFECT,
	buffToApply: BuffIdentifier.SporeKidHealing,
	durationInGameTicks: 20,
	validTargets: AbilityTargets.ALL_NEARBY_ENEMIES,
	validTargetSelection: AbilityTargetSelectionStyle.LOWEST_HEALTH,
}

const monsterInRangeIsDamagedLowTierCriteria: ActionCriteria = {
	satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
	criterias: [
		{
			criteriaTargets: CriteriaTarget.SELF,
			criteriaType: CriteriaType.WORLD_DIFFICULTY_AT_OR_ABOVE_THRESHOLD,
			criteriaValue: WorldDifficulty.Normal,
			minimumTargetsThatSatisfy: 1,
		},
		{
			criteriaTargets: CriteriaTarget.AI_UNITS,
			criteriaType: CriteriaType.WITHIN_RANGE,
			criteriaValue: 800,
			minimumTargetsThatSatisfy: 1,
		},
		{
			criteriaTargets: CriteriaTarget.AI_UNITS,
			criteriaType: CriteriaType.HEALTH_BELOW_PERCENTAGE_THRESHOLD,
			criteriaValue: 90,
			minimumTargetsThatSatisfy: 1,
		},
		{
			criteriaTargets: CriteriaTarget.SELF,
			criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
			criteriaValue: BuffIdentifier.EnemyCooldown3000,
			minimumTargetsThatSatisfy: 1,
		},
		{
			criteriaTargets: CriteriaTarget.AI_UNITS,
			criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
			criteriaValue: BuffIdentifier.SporeKidHealing,
			minimumTargetsThatSatisfy: 1,
		},
	],
}
const monsterInRangeIsDamagedHighTierCriteria: ActionCriteria = {
	satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
	criterias: [
		{
			criteriaTargets: CriteriaTarget.SELF,
			criteriaType: CriteriaType.WORLD_DIFFICULTY_AT_OR_ABOVE_THRESHOLD,
			criteriaValue: WorldDifficulty.Brutal,
			minimumTargetsThatSatisfy: 1,
		},
		{
			criteriaTargets: CriteriaTarget.AI_UNITS,
			criteriaType: CriteriaType.WITHIN_RANGE,
			criteriaValue: 800,
			minimumTargetsThatSatisfy: 1,
		},
		{
			criteriaTargets: CriteriaTarget.AI_UNITS,
			criteriaType: CriteriaType.HEALTH_BELOW_PERCENTAGE_THRESHOLD,
			criteriaValue: 75,
			minimumTargetsThatSatisfy: 1,
		},
		{
			criteriaTargets: CriteriaTarget.AI_UNITS,
			criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
			criteriaValue: BuffIdentifier.SporeKidHealing,
			minimumTargetsThatSatisfy: 1,
		},
	],
}

export const sporekid: EnemyAI = {
	name: ENEMY_NAMES.SPORE_KID,
	type: EnemyType.BASIC,
	appearance: {
		asset: SpineDataName.SPOREKID,
		skin: EnemyBipedSkinType.DEFAULT,
		flying: true,
		mixSettings: [],
	},
	general: {
		enemyLevel: 1,
		experienceWhenKilled: 3,
	},
	baseAttributes: {
		...EnemyDefaults.baseAttributes,
		colliders: [
			{
				type: ColliderType.Ellipse,
				rX: 12,
				rY: 40,
				position: [0, -100],
				traits: ColliderTraits.BlockMovementAndProjectile,
			},
		],
		movementSpeed: 200,
		decelerationRate: 1.2,
		turningRatePerSecondInDegrees: 90,
		attackRate: 1500,

		physicalDamage: 0,

		maxHealth: (ENEMY_DEFAULT_HEALTH * 4) / 6,
		defense: 0,
		fireResist: 0,
		poisonResist: 0,
		iceResist: 0,
		lightningResist: 0,
		attackOffset: new Vector(0, 0),

		lootDropProbability: 1,
		lootDropQuantity: 1,
	},
	soundEffects: {
		attack: 'Projectile_Zap',
		impact: 'SFX_Impact_Enemy_Mush',
	},
	states: {
		idling: {
			behaviour: IdleBehaviours.MEANDER,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			transitionToFighting: {
				trigger: FightingTriggers.AGGRESSIVE_PROXIMITY,
				radius: 700,
			},
			meander: {
				radius: 500,
				speedMultiplier: 1,
				minRestTime: 0.5,
				maxRestTime: 10,
			},
			aggroOnHit: true,
		},
		fighting: {
			behaviour: FightingBehaviours.STRAFE_AND_ATTACK,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 5,
			attackType: AttackTypes.PROJECTILE,
			engagementMaxDistance: 800,
			engagementMinDistance: 50,
			movementMaxDistance: 600,
			movementMinDistance: 50,
			brain: {
				actions: [
					{
						// Healing projectile at brutal+
						priority: 0,
						actionCriteria: monsterInRangeIsDamagedHighTierCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							abilityOptions: [attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, sporeKidHealAbility, 30)],
						},
					},
					{
						// Healing projectile at normal/hard
						priority: 1,
						actionCriteria: monsterInRangeIsDamagedLowTierCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SEQUENCE,
							abilityOptions: [
								//[1, crabRangedSlowingAbility, 40]
								attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, sporeKidHealAbility, 0),
								attackRatedAbilityList(1, animationTimes, AnimationTrack.IDLE, cooldownEnemyCooldown3000, 30),
							],
						},
					},
					{
						// BLOCK/ATTACK
						priority: 3,
						actionCriteria: playerIsInAttackRangeBrutalCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							//abilityOptions: [[1,  crabMeleeAttackAbility, 15]],
							abilityOptions: [attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, sporeKidRangedAttackAbilityBrutal, 10)],
						},
					},
					{
						// BLOCK/ATTACK
						priority: 4,
						actionCriteria: playerIsInAttackRangeCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							//abilityOptions: [[1,  crabMeleeAttackAbility, 15]],
							abilityOptions: [attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, sporeKidRangedAttackAbility, 30)],
						},
					},
					{
						// Wait
						priority: 10,
						actionCriteria: alwaysTrueCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							//abilityOptions: [[1, longRangeAbility, 50]],
							abilityOptions: [[0, waitAbility(10)]],
						},
					},
				],
			},

			attackOptions: [
				{
					...EnemyDefaults.projectileConfig,
					projectileCount: 1,
					spreadAngle: 0,
					speed: 350,
					colliderRadius: 12,
					lifespanInSeconds: 5,
					modifiers: [{ modType: ModType.WAVE, amplitude: { modValueType: ModValueType.VALUE, value: 4 }, period: { modValueType: ModValueType.VALUE, value: 0.5 } }],
					baseIceDamage: 25,
					burstCount: 0,
					color: Colors.green,
					bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_SPOREKID,
					bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_TRAIL,
					muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
				},
			],
			shotLeadPrecision: ShotLeadPrecision.PERFECT,
			visualAimLockSeconds: 0.5,
			transitionToLeashing: {
				trigger: LeashTriggers.RADIUS_EXCEEDED,
				radius: 2000,
				distanceTravelled: 4000,
			},
		},
		leashing: {
			behaviour: LeashingBehaviours.MOVE,
			speedMultiplier: 4,
			decelerateMultiplier: 0,
		},
		fleeing: {},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 0.5,
		},
	},
}

export default sporekid
