/** List of valid operators for how a given StatMod will influence an entity's stats */
enum StatOperatorType {
	/** Additively stacking */
	SUM = 1,
	/** Additively sum all multis, then multiply by that value */
	SUM_THEN_MULTIPLY = 2,
	/** Multiplicatively stacking */
	MULTIPLY = 3,
	/** Indicates a value that is non-numeric */
	NOOP = 4,
}

export default StatOperatorType
