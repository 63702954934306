// Update this in plutus item-info if you upddate it here
export enum PersistentBuffSubType {
    Enchanting = 6000,
    LegendaryEnchanting = 6001,
    LifeBloom = 6002,
    RegenerativeHealing = 6003,
    QueensRansom = 6004,
    LegendaryQueensRansom = 6005,
    FinalCountdown = 6006,
    IronMan = 6007,
    Pauper = 6008,
    RagsToRiches = 6009,
}

export const PersistentBuffSubtypePrettyName = new Map()

export const PersistedBuffSubtypeToDesc = new Map()

export const PotionSubTypeToAccountBuff = new Map()

PersistentBuffSubtypePrettyName.set(PersistentBuffSubType.Enchanting, 'Enchanting Potion')
PersistentBuffSubtypePrettyName.set(PersistentBuffSubType.LegendaryEnchanting, 'Legendary Enchanting Potion')
PersistentBuffSubtypePrettyName.set(PersistentBuffSubType.LifeBloom, 'Lifebloom Potion')
PersistentBuffSubtypePrettyName.set(PersistentBuffSubType.RegenerativeHealing, 'Regenerative Healing Potion')
PersistentBuffSubtypePrettyName.set(PersistentBuffSubType.QueensRansom, 'Epic Queen\'s Ransom Scroll')
PersistentBuffSubtypePrettyName.set(PersistentBuffSubType.LegendaryQueensRansom, 'Legendary Queen\'s Ransom Scroll')
PersistentBuffSubtypePrettyName.set(PersistentBuffSubType.FinalCountdown, 'Final Countdown Potion')
PersistentBuffSubtypePrettyName.set(PersistentBuffSubType.IronMan, 'Ironman Potion')
PersistentBuffSubtypePrettyName.set(PersistentBuffSubType.Pauper, 'Pauper Scroll')
PersistentBuffSubtypePrettyName.set(PersistentBuffSubType.RagsToRiches, 'Rags to Riches Scroll')

PersistedBuffSubtypeToDesc.set(PersistentBuffSubType.Enchanting, `For the next 20 minutes, you'll be able to pick an enchantment at the start of your runs!`)
PersistedBuffSubtypeToDesc.set(PersistentBuffSubType.LegendaryEnchanting, `For the next 24 hours, you'll be able to pick an enchantment at the start of your runs.\n\nRequires Soul Cycle 1 to be unlocked.`)
PersistedBuffSubtypeToDesc.set(PersistentBuffSubType.LifeBloom, `For the next 20 minutes, hearts will also heal 5% of your health over 10 seconds!`)
PersistedBuffSubtypeToDesc.set(PersistentBuffSubType.RegenerativeHealing, `For the next 2 hours, all healing you receive increased by 33%!`)
PersistedBuffSubtypeToDesc.set(PersistentBuffSubType.QueensRansom, `For the next 20 minutes, find 25% more loot!`)
PersistedBuffSubtypeToDesc.set(PersistentBuffSubType.LegendaryQueensRansom, `For the next 20 minutes, find 50% more loot!\n\nRequires Soul Cycle 1 to be unlocked.`)
PersistedBuffSubtypeToDesc.set(PersistentBuffSubType.FinalCountdown, `For the next 20 minutes, find 25% more loot and 33% more coins, but take 3 damage every 5 seconds!\n\nRequires Soul Cycle 1 to be unlocked.`)
PersistedBuffSubtypeToDesc.set(PersistentBuffSubType.IronMan, `For the next 20 minutes, find 50% more loot, but you cannot use shields!\n\nRequires Soul Cycle 1 to be unlocked.`)
PersistedBuffSubtypeToDesc.set(PersistentBuffSubType.Pauper, `For the next 2 hours, gain 250% more damage, 50% increased attack rate, 100% increase in all resist, and 1 extra shield\n\n All equipment and augments must be Common or Uncommon\n\nRequires Soul Cycle 1 to be unlocked.`)
PersistedBuffSubtypeToDesc.set(PersistentBuffSubType.RagsToRiches, `For the next 2 hours, find more loot based on equipment rarity.\n\n+ 10% for each Common\n\n+ 6% for each Uncommon\n\n+ 2% for each Rare`)