import { Vector } from 'sat'
import { BuffIdentifier } from '../../../../buffs/shared/buff.shared'
import { ParticleEffectType } from '../../../../engine/shared/game-data/particle-config'
import { BurstFireModes, ModType, ModValueType } from '../../../../projectiles/shared/projectile-types'
import { Colors } from '../../../../utils/colors'
import { degToRad, wrapAngle } from '../../../../utils/math'
import { Ability, AbilityTargets, AbilityTargetSelectionStyle, AbilityType } from '../../action-types'
import { EnemyDefaults } from '../enemy-defaults'
import { ENEMY_NAMES } from '../enemy-names'
import { deepClone } from '../../abilities.test'
import { AnimationTrack } from '../../../../models-animations/shared/animation-track'

export const walkToPositionAbility: Ability = {
	debugName: 'moveToPosition',
	abilityType: AbilityType.MOVE_TOWARDS_LOCATION,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.RANDOM_POSITIONS_IN_SHAPE,
	shapeNames: ['centerTop', 'centerTopLeft', 'centerTopRight', 'centerRight', 'centerLeft', 'centerMiddle', 'centerBottom'],
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
	moveRules: {
		movementSpeed: 150,
		minDistance: 100,
		maxDistance: 600,
	},
}

export const flyToPositionAbility: Ability = {
	debugName: 'moveToPosition',
	abilityType: AbilityType.MOVE_TOWARDS_LOCATION,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.RANDOM_POSITIONS_IN_SHAPE,
	shapeNames: ['centerTop', 'centerTopLeft', 'centerTopRight', 'centerRight', 'centerLeft', 'centerMiddle', 'centerBottom'],
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
	moveRules: {
		movementSpeed: 2000,
		minDistance: 300,
		maxDistance: 10000,
	},
}

export const flyToPositionIncludingEdgesAbility: Ability = {
	debugName: 'moveToPosition',
	abilityType: AbilityType.MOVE_TOWARDS_LOCATION,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.RANDOM_POSITIONS_IN_SHAPE,
	shapeNames: ['centerTop', 'centerTopLeft', 'centerTopRight', 'centerRight', 'centerLeft', 'centerMiddle', 'centerBottom', 'rightTop', 'rightBottom', 'leftTop', 'leftBottom'],
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
	moveRules: {
		movementSpeed: 3000,
		minDistance: 300,
		maxDistance: 10000,
	},
}

export const flyToCenterAbility: Ability = {
	debugName: 'moveToPosition',
	abilityType: AbilityType.MOVE_TOWARDS_LOCATION,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.RANDOM_POSITIONS_IN_SHAPE,
	shapeNames: ['centerHighTop'],
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
	moveRules: {
		movementSpeed: 3000,
		minDistance: 0,
		maxDistance: 10000,
	},
}

export const setInvisibleAbility: Ability = {
	debugName: 'setVisibility',
	abilityType: AbilityType.TOGGLE_STATE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.MYSELF,
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
	toggleStateRules: {
		visible: false,
	},
}

export const setVisibleAbility: Ability = {
	debugName: 'setVisibility',
	abilityType: AbilityType.TOGGLE_STATE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.MYSELF,
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
	toggleStateRules: {
		visible: true,
	},
}

export const disableCollidersAbility: Ability = {
	debugName: 'setColliders',
	abilityType: AbilityType.SET_COLLIDERS,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.MYSELF,
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
	colliderInfo: {
		enabled: false,
	},
}

export const enableCollidersAbility: Ability = {
	debugName: 'setColliders',
	abilityType: AbilityType.SET_COLLIDERS,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.MYSELF,
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
	colliderInfo: {
		enabled: true,
	},
}

export const crystalBreathAbility: Ability = {
	debugName: 'crystalBreath',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		speed: 850,
		lifespanInSeconds: 4,
		projectileCount: 5,
		spreadAngle: degToRad(20),
		baseFireDamage: 70,
		basePhysicalDamage: 0,
		burstCount: 2,
		burstDelay: 0.15,
		burstMode: BurstFireModes.STRAIGHT,
		burstAngle: degToRad(10),
		modifiers: [
			{ modType: ModType.STRAIGHT },
			{ modType: ModType.TURN, value: { modValueType: ModValueType.RANDOM, min: -0.2, max: 0.2 } },
			{ modType: ModType.WAVE, amplitude: { modValueType: ModValueType.VALUE, value: 0.6 }, period: { modValueType: ModValueType.RANDOM, min: 1.75, max: 2.5 } },
			{ modType: ModType.ACCELERATION, value: { modValueType: ModValueType.RANDOM, min: -0.2, max: 0.2 } },
		],
		color: Colors.red,
		colliderRadius: 35,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_PRISMBOSS,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_TRAIL_PRISMBOSS,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
	},
	attackOffset: new Vector(0, -600),
	ignoreAngleOnWeaponOffset: true,
}

function crystalBreathSpawningOnHitFnNormal(projectile, owner, target) {
	if (Math.random() < 0.15) {
		owner.spawnMinionBasic(ENEMY_NAMES.SLIVER_PRISM_BOSS_MINION, projectile.x, projectile.y, 0)
	}
}
function crystalBreathSpawningOnHitFnHard(projectile, owner, target) {
	if (Math.random() < 0.185) {
		owner.spawnMinionBasic(ENEMY_NAMES.SLIVER_PRISM_BOSS_MINION, projectile.x, projectile.y, 0)
	}
}
function crystalBreathSpawningOnHitFnBrutalPlus(projectile, owner, target) {
	if (Math.random() < 0.220) {
		owner.spawnMinionBasic(ENEMY_NAMES.SLIVER_PRISM_BOSS_MINION, projectile.x, projectile.y, 0)
	}
}

export const crystalBreathAbilitySpawning: Ability = {
	...crystalBreathAbility,
	debugName: 'crystalBreathSpawning',
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		...crystalBreathAbility.projectileConfig,
		burstCount: 2,
		modifiers: [...crystalBreathAbility.projectileConfig.modifiers],
		onHitFn: crystalBreathSpawningOnHitFnNormal,
	},
}

export const wingGustAbility: Ability = {
	debugName: 'wingGust',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		speed: 800,
		lifespanInSeconds: 1,
		projectileCount: 5,
		spreadAngle: degToRad(30),
		baseFireDamage: 25,
		basePhysicalDamage: 25,
		burstCount: 2,
		burstDelay: 0.1,
		burstMode: BurstFireModes.STRAIGHT,
		burstAngle: degToRad(10),
		pierceCount: 10,
		modifiers: [{ modType: ModType.STRAIGHT }, { modType: ModType.ACCELERATION, value: { modValueType: ModValueType.RANDOM, min: -0.5, max: 1.2 } }],
		color: Colors.red,
		colliderRadius: 43,
		bulletParticleEffect: ParticleEffectType.BOSS_PRISM_WIND,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		applyBuffsOnHit: [BuffIdentifier.EnemyPrismBossGustWeakness],
	},
	attackOffset: new Vector(0, -600),
	ignoreAngleOnWeaponOffset: true,
}

export const skyRaidAbility: Ability = {
	debugName: 'skyRaid',
	abilityType: AbilityType.SPAWN_GROUND_HAZARD_MULTITARGET,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
	durationInGameTicks: 60,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		colliderRadius: 225,
		speed: 0,
		lifespanInSeconds: 0.2, // how long the projectile lasts on the ground
		color: Colors.red,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		pierceCount: 999,
		baseFireDamage: 30,
		basePhysicalDamage: 60,
	},
	groundHazardMultitargetRules: {
		spineAsset: 'prism-aoe',
		pfx: 'boss-prism-explosion',
		pfxScale: 1.5,
		pfxSecondsOffset: 1.4, // how long before the PFX is created?
		projectileSecondsOffset: 1.55, // how long before it creates the projectile
		baseQuantity: 12,
		perTargetQuantity: 8,
		totalTime: 6,
		accuratePercent: 0.35,
		maxStray: 600,
	},
}

export const skyRaidAbilityV2: Ability = {
	debugName: 'skyRaidV2',
	abilityType: AbilityType.SPAWN_GROUND_HAZARD_MULTITARGET,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
	durationInGameTicks: 60,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		colliderRadius: 225,
		speed: 0,
		lifespanInSeconds: 0.2, // how long the projectile lasts on the ground
		color: Colors.red,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		baseFireDamage: 35,
		basePhysicalDamage: 65,
		pierceCount: 999,
	},
	groundHazardMultitargetRules: {
		spineAsset: 'prism-aoe',
		pfx: 'boss-prism-explosion',
		pfxScale: 1.5,
		pfxSecondsOffset: 1.4, // how long before the PFX is created?
		projectileSecondsOffset: 1.55, // how long before it creates the projectile
		baseQuantity: 18,
		perTargetQuantity: 12,
		totalTime: 6,
		accuratePercent: 0.35,
		maxStray: 600,
	},
}

// this is 45 degree arc directly left or right of the Dragon
function crystalCocoonAngleFormula() { 
    return degToRad(wrapAngle(Math.getRandomInt(1,90) + 45))
}


export const crystalCocoonAbility: Ability = {
	debugName: 'crystalCocoon',
	abilityType: AbilityType.SPAWN_ENEMIES_WHILE_BUFFED,
	validTargets: AbilityTargets.MYSELF,
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
	durationInGameTicks: 60,
	cancelAnimation: AnimationTrack.CRYSTAL_COCOON_OUT,
	spawnEnemiesWhileBuffedRules: {
		identifiers: [ENEMY_NAMES.DREAMER_PRISM, ENEMY_NAMES.GOLEM_PRISM],
		quantity: 10,
		dropXP: false,
		dropLoot: false,
		// form a semi-circle below around the Dragon
		minSpawnRadius: 350,
		maxSpawnRadius: 850,
		delayBetweenSpawns: 1,
		spawnTime: 0.3,
		buffToApply: BuffIdentifier.EnemyPrismBossCocoon,
	},
}

export const cooldownEnemyCooldown60000: Ability = {
	debugName: 'cooldown60000',
	abilityType: AbilityType.INSTANTLY_APPLY_EFFECT,
	buffToApply: BuffIdentifier.EnemyCooldown1000PerStack,
	buffStacksToApply: 60,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.MYSELF,
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
}

export const cooldownEnemyCooldown10000: Ability = {
	debugName: 'cooldown10000',
	abilityType: AbilityType.INSTANTLY_APPLY_EFFECT,
	buffToApply: BuffIdentifier.EnemyCooldown10000,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.MYSELF,
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
}


export const crystalBreathAbilityEasy = deepClone(crystalBreathAbility)
export const crystalBreathAbilityMedium = deepClone(crystalBreathAbilityEasy)
	crystalBreathAbilityMedium.projectileConfig.burstCount = 3
	crystalBreathAbilityMedium.projectileConfig.projectileCount = 6
	crystalBreathAbilityMedium.projectileConfig.speed = 1000
	crystalBreathAbilityMedium.projectileConfig.burstDelay = 0.1
	crystalBreathAbilityMedium.projectileConfig.spreadAngle = degToRad(24)
	crystalBreathAbilityMedium.projectileConfig.modifiers = [
		{ modType: ModType.STRAIGHT },
		{ modType: ModType.TURN, value: { modValueType: ModValueType.RANDOM, min: -0.5, max: 0.5 } },
		{ modType: ModType.WAVE, amplitude: { modValueType: ModValueType.VALUE, value: 1.5 }, period: { modValueType: ModValueType.RANDOM, min: 1.75, max: 2.5 } },
		{ modType: ModType.ACCELERATION, value: { modValueType: ModValueType.RANDOM, min: -0.2, max: 0.2 } },
	]
export const crystalBreathAbilityBrutal = deepClone(crystalBreathAbilityMedium)
	crystalBreathAbilityBrutal.projectileConfig.modifiers = [
		{ modType: ModType.STRAIGHT },
		{ modType: ModType.TURN, value: { modValueType: ModValueType.RANDOM, min: -0.5, max: 0.5 } },
		{ modType: ModType.WAVE, amplitude: { modValueType: ModValueType.VALUE, value: 1.5 }, period: { modValueType: ModValueType.RANDOM, min: 1.75, max: 2.5 } },
		{ modType: ModType.ACCELERATION, value: { modValueType: ModValueType.RANDOM, min: -0.2, max: 0.2 } },
	]
export const crystalBreathAbilityNasty = deepClone(crystalBreathAbilityBrutal)



export const crystalBreathAbilitySpawningEasy = deepClone(crystalBreathAbilitySpawning)
	crystalBreathAbilitySpawningEasy.projectileConfig.onHitFn = crystalBreathSpawningOnHitFnNormal
export const crystalBreathAbilitySpawningMedium = deepClone(crystalBreathAbilitySpawningEasy)
	crystalBreathAbilitySpawningMedium.projectileConfig.burstCount = 3
	crystalBreathAbilitySpawningMedium.projectileConfig.projectileCount = 5
	crystalBreathAbilitySpawningMedium.projectileConfig.speed = 900
	crystalBreathAbilitySpawningMedium.projectileConfig.burstDelay = 0.1
	crystalBreathAbilitySpawningMedium.projectileConfig.spreadAngle = degToRad(22)
	crystalBreathAbilitySpawningMedium.projectileConfig.onHitFn = crystalBreathSpawningOnHitFnHard
	crystalBreathAbilitySpawningMedium.projectileConfig.modifiers = [
		{ modType: ModType.STRAIGHT },
		{ modType: ModType.TURN, value: { modValueType: ModValueType.RANDOM, min: -0.5, max: 0.5 } },
		{ modType: ModType.WAVE, amplitude: { modValueType: ModValueType.VALUE, value: 1.5 }, period: { modValueType: ModValueType.RANDOM, min: 1.75, max: 2.5 } },
		{ modType: ModType.ACCELERATION, value: { modValueType: ModValueType.RANDOM, min: -0.2, max: 0.2 } },
	]
export const crystalBreathAbilitySpawningBrutal = deepClone(crystalBreathAbilitySpawningMedium)
	crystalBreathAbilitySpawningBrutal.projectileConfig.projectileCount = 6
	crystalBreathAbilitySpawningBrutal.projectileConfig.speed = 950
	crystalBreathAbilitySpawningBrutal.projectileConfig.spreadAngle = degToRad(24)
	crystalBreathAbilitySpawningBrutal.projectileConfig.onHitFn = crystalBreathSpawningOnHitFnBrutalPlus
	crystalBreathAbilitySpawningBrutal.projectileConfig.modifiers = [
		{ modType: ModType.STRAIGHT },
		{ modType: ModType.TURN, value: { modValueType: ModValueType.RANDOM, min: -0.5, max: 0.5 } },
		{ modType: ModType.WAVE, amplitude: { modValueType: ModValueType.VALUE, value: 1.5 }, period: { modValueType: ModValueType.RANDOM, min: 1.75, max: 2.5 } },
		{ modType: ModType.ACCELERATION, value: { modValueType: ModValueType.RANDOM, min: -0.2, max: 0.2 } },
	]
export const crystalBreathAbilitySpawningNasty = deepClone(crystalBreathAbilitySpawningBrutal)
	crystalBreathAbilitySpawningNasty.projectileConfig.speed = 1000
	crystalBreathAbilitySpawningNasty.projectileConfig.onHitFn = crystalBreathSpawningOnHitFnBrutalPlus

export const wingGustAbilityEasy = deepClone(wingGustAbility)
export const wingGustAbilityMedium = deepClone(wingGustAbility)
export const wingGustAbilityBrutal = deepClone(wingGustAbility)
export const wingGustAbilityNasty = deepClone(wingGustAbility)

export const skyRaidAbilityEasy = deepClone(skyRaidAbility)
	skyRaidAbilityEasy.projectileConfig.colliderRadius = 175
	skyRaidAbilityEasy.projectileConfig.baseFireDamage = 25
	skyRaidAbilityEasy.projectileConfig.basePhysicalDamage = 45
	skyRaidAbilityEasy.groundHazardMultitargetRules.baseQuantity = 10
	skyRaidAbilityEasy.groundHazardMultitargetRules.perTargetQuantity = 5
	skyRaidAbilityEasy.groundHazardMultitargetRules.spineAsset = 'prism-boss-aoe-easy'
export const skyRaidAbilityMedium = deepClone(skyRaidAbilityEasy)
	skyRaidAbilityMedium.projectileConfig.colliderRadius = 200
	skyRaidAbilityMedium.projectileConfig.baseFireDamage = 25
	skyRaidAbilityMedium.projectileConfig.basePhysicalDamage = 45
	skyRaidAbilityMedium.groundHazardMultitargetRules.baseQuantity = 12
	skyRaidAbilityMedium.groundHazardMultitargetRules.perTargetQuantity = 7
	skyRaidAbilityMedium.groundHazardMultitargetRules.spineAsset = 'prism-boss-aoe-medium'
export const skyRaidAbilityBrutal = deepClone(skyRaidAbilityMedium)
	skyRaidAbilityBrutal.projectileConfig.colliderRadius = 225
	skyRaidAbilityBrutal.projectileConfig.baseFireDamage = 25
	skyRaidAbilityBrutal.projectileConfig.basePhysicalDamage = 45
	skyRaidAbilityBrutal.groundHazardMultitargetRules.baseQuantity = 18
	skyRaidAbilityBrutal.groundHazardMultitargetRules.perTargetQuantity = 12
	skyRaidAbilityBrutal.groundHazardMultitargetRules.spineAsset = 'prism-boss-aoe-hard'
export const skyRaidAbilityNasty = deepClone(skyRaidAbilityBrutal)

export const skyRaidAbilityV2Easy = deepClone(skyRaidAbilityV2)
	skyRaidAbilityV2Easy.projectileConfig.colliderRadius = 175
	skyRaidAbilityV2Easy.projectileConfig.baseFireDamage = 50
	skyRaidAbilityV2Easy.projectileConfig.basePhysicalDamage = 30
	skyRaidAbilityV2Easy.groundHazardMultitargetRules.baseQuantity = 12
	skyRaidAbilityV2Easy.groundHazardMultitargetRules.perTargetQuantity = 7
	skyRaidAbilityV2Easy.groundHazardMultitargetRules.spineAsset = 'prism-boss-aoe-easy'
export const skyRaidAbilityV2Medium = deepClone(skyRaidAbilityV2Easy)
	skyRaidAbilityV2Medium.projectileConfig.colliderRadius = 200
	skyRaidAbilityV2Medium.projectileConfig.baseFireDamage = 50
	skyRaidAbilityV2Medium.projectileConfig.basePhysicalDamage = 30
	skyRaidAbilityV2Medium.groundHazardMultitargetRules.baseQuantity = 13
	skyRaidAbilityV2Medium.groundHazardMultitargetRules.perTargetQuantity = 10
	skyRaidAbilityV2Medium.groundHazardMultitargetRules.spineAsset = 'prism-boss-aoe-medium'
export const skyRaidAbilityV2Brutal = deepClone(skyRaidAbilityV2Medium)
	skyRaidAbilityV2Brutal.projectileConfig.colliderRadius = 225
	skyRaidAbilityV2Brutal.projectileConfig.baseFireDamage = 50
	skyRaidAbilityV2Brutal.projectileConfig.basePhysicalDamage = 30
	skyRaidAbilityV2Brutal.groundHazardMultitargetRules.baseQuantity = 18
	skyRaidAbilityV2Brutal.groundHazardMultitargetRules.perTargetQuantity = 12
	skyRaidAbilityV2Brutal.groundHazardMultitargetRules.spineAsset = 'prism-boss-aoe-hard'
export const skyRaidAbilityV2Nasty = deepClone(skyRaidAbilityV2Brutal)

export const crystalCocoonAbilityEasy = deepClone(crystalCocoonAbility)
	crystalCocoonAbilityEasy.spawnEnemiesWhileBuffedRules.quantity = 5
	crystalCocoonAbilityEasy.spawnEnemiesWhileBuffedRules.delayBetweenSpawns = 1.25
	crystalCocoonAbilityEasy.spawnEnemiesWhileBuffedRules.customAngleFormula = crystalCocoonAngleFormula
export const crystalCocoonAbilityMedium = deepClone(crystalCocoonAbility)
	crystalCocoonAbilityMedium.spawnEnemiesWhileBuffedRules.quantity = 8
	crystalCocoonAbilityMedium.spawnEnemiesWhileBuffedRules.customAngleFormula = crystalCocoonAngleFormula
export const crystalCocoonAbilityBrutal = deepClone(crystalCocoonAbility)
	crystalCocoonAbilityBrutal.spawnEnemiesWhileBuffedRules.quantity = 10
	crystalCocoonAbilityBrutal.spawnEnemiesWhileBuffedRules.customAngleFormula = crystalCocoonAngleFormula
export const crystalCocoonAbilityNasty = deepClone(crystalCocoonAbility)
	crystalCocoonAbilityNasty.spawnEnemiesWhileBuffedRules.quantity = 10
	crystalCocoonAbilityNasty.spawnEnemiesWhileBuffedRules.customAngleFormula = crystalCocoonAngleFormula
