import { Mesh, Shader, Geometry, State, Program, Buffer, TYPES, BLEND_MODES, Container, Texture } from 'pixi.js'

const FLOATS_PER_VERT = 4
const VERTS_PER_QUAD = 4
const GROUND_WIDTH = 512
const GROUND_HEIGHT = 512
const VERT_POSITIONS: number[] = [0, 0, GROUND_WIDTH, 0, GROUND_WIDTH, GROUND_HEIGHT, 0, GROUND_HEIGHT]
const VERT_UVS: number[] = [0, 0, 1, 0, 1, 1, 0, 1]
const QUAD_INDICES: number[] = [0, 1, 2, 0, 2, 3]

export class BaseGround {
	private static geom: Geometry
	private static vbo: Buffer
	private static indexBuffer: Buffer

	protected vertSrc: string
	protected fragSrc: string
	protected textures: Texture[]
	protected blendRotation: number
	protected shader: Shader
	protected state: State
	protected mesh: Mesh

	constructor(vertSrc: string, fragSrc: string, textures: Texture[], blendRotation: number = 0) {
		this.vertSrc = vertSrc
		this.fragSrc = fragSrc
		this.textures = textures
		this.blendRotation = blendRotation
	}

	addToContainer(container: Container) {
		container.addChild(this.mesh)
	}

	setPosition(x: number, y: number) {
		this.mesh.position.set(x, y)
	}

	setRotation(rot: number) {
		this.mesh.rotation = rot
	}

	init(): void {
		const program = Program.from(this.vertSrc, this.fragSrc, 'baseGround')

		if (!BaseGround.vbo) {
			BaseGround.geom = new Geometry()

			const floats = new Float32Array(FLOATS_PER_VERT * VERTS_PER_QUAD)

			const indexData = new Uint16Array(QUAD_INDICES)

			let ofs = 0
			for (let i = 0; i < 4; ++i) {
				floats[ofs++] = VERT_POSITIONS[i * 2 + 0]
				floats[ofs++] = VERT_POSITIONS[i * 2 + 1]
				floats[ofs++] = VERT_UVS[i * 2 + 0]
				floats[ofs++] = VERT_UVS[i * 2 + 1]
			}

			BaseGround.vbo = new Buffer(floats, true, false)
			BaseGround.indexBuffer = new Buffer(indexData, true, true)

			BaseGround.geom.addAttribute('aPosition', BaseGround.vbo, 2, false, TYPES.FLOAT, FLOATS_PER_VERT * 4, 0)
			BaseGround.geom.addAttribute('aUV', BaseGround.vbo, 2, false, TYPES.FLOAT, FLOATS_PER_VERT * 4, 8)
			BaseGround.geom.addIndex(BaseGround.indexBuffer)
		}

		const uniforms = this.setupUniforms()

		this.shader = new Shader(program, uniforms)
		this.state = new State()

		this.state.blend = false

		// @ts-expect-error breaks contract with pixi, wontfix
		this.mesh = new Mesh(BaseGround.geom, this.shader, this.state)
		this.mesh.blendMode = BLEND_MODES.NONE
	}

	protected setupUniforms(): any {
		let uniforms = null
		const uvTransformA: number[] = [
			this.textures[0]._frame.x / this.textures[0].baseTexture.width,
			this.textures[0]._frame.y / this.textures[0].baseTexture.height,
			this.textures[0]._frame.width / this.textures[0].baseTexture.width,
			this.textures[0]._frame.height / this.textures[0].baseTexture.height,
		]

		if (this.textures.length === 3) {
			const uvTransformB: number[] = [
				this.textures[1]._frame.x / this.textures[1].baseTexture.width,
				this.textures[1]._frame.y / this.textures[1].baseTexture.height,
				this.textures[1]._frame.width / this.textures[1].baseTexture.width,
				this.textures[1]._frame.height / this.textures[1].baseTexture.height,
			]
			uniforms = {
				u_biomeA: this.textures[0],
				u_biomeB: this.textures[1],
				u_biomeMix: this.textures[2],
				u_uvTransformA: uvTransformA,
				u_uvTransformB: uvTransformB,
				u_blendRot: this.blendRotation,
			}
		} else {
			uniforms = {
				u_biomeA: this.textures[0],
				u_uvTransform: uvTransformA,
			}
		}
		return uniforms
	}
}
