import { Container, Program, Shader, Geometry, State, Buffer, Mesh, BLEND_MODES, TYPES, Texture, Point } from 'pixi.js'
import { getBiomeList } from '../../biome/shared/biome-list'
import { gameModeConfig } from '../../engine/shared/game-mode-configs'
import { add } from '../../utils/math'
import WaterTileBase from './water-base'
import WaterRenderer from './water-renderer'

interface Uniforms {
	currentTex: Texture
	waveTex?: Texture
	blendTex: Texture
	currentOffsetTop: number[]
	currentOffsetBottom: number[]
	waveOffsetTop?: number
	waveOffsetBottom?: number
	waveAlpha?: number
	waterColor: number[]
	nextWaterColor: number[]
}

export default class WaterBlendTile extends WaterTileBase {
	private readonly TILE_WIDTH = 512 * 4
	private readonly TILE_HEIGHT = 512
	private readonly VERT_POSITIONS: number[] = [0, 0, this.TILE_WIDTH, 0, this.TILE_WIDTH, this.TILE_HEIGHT, 0, this.TILE_HEIGHT]
	private readonly UVS: number[] = [0, 0, 4, 0, 4, 1, 0, 1]
	private readonly BLEND_UVS: number[] = [0, 0, 1, 0, 1, 1, 0, 1]
	private readonly QUAD_INDICES: number[] = [0, 1, 2, 0, 2, 3]
	private readonly VERTEX_SIZE: number = 28 // 6 Floats (x, y and 2 sets of u,v) and 4 unsigned bytes (rgba)

	protected uniforms: Uniforms = null

	constructor(vertSrc: string, fragSrc: string, waveTile: boolean = false) {
		super()
		this.vertSrc = vertSrc
		this.fragSrc = fragSrc
		this.isWaveTile = waveTile

		const biomeList = getBiomeList(gameModeConfig.type)
		biomeList.forEach((biome) => {
			this.waterColors.push(biome.waterColor)
		})

		this.setupUniforms()

		this.createTile()
	}

	update(delta: number): void {
		this.currentOffsetTimer += this.CURRENT_SPEED
		this.waveOffsetTimer += this.WAVES_SPEED

		const tileLocalPos = this.getPosition()
		const parentWorldPos = WaterRenderer.Instance.waterContainer.position
		const tileWorldPos = add(tileLocalPos, parentWorldPos)
		const y = tileWorldPos.y - this.NORTH_SOUTH_BOUNDARY > 0 ? 1 : -1

		this.uniforms.currentOffsetTop[0] = this.currentOffsetTimer
		this.uniforms.currentOffsetBottom[0] = this.currentOffsetTimer

		this.uniforms.currentOffsetTop[1] = this.currentOffsetTimer * y
		this.uniforms.currentOffsetBottom[1] = this.currentOffsetTimer * y

		if (this.isWaveTile) {
			if (this.waveTimer <= 0) {
				this.uniforms.waveOffsetTop = this.waveOffsetTimer * y
				this.uniforms.waveOffsetBottom = this.waveOffsetTimer * y
				if (this.fadeInWave) {
					this.uniforms.waveAlpha += this.WAVES_FADE_RATE
					if (this.uniforms.waveAlpha >= 1) {
						this.fadeInWave = false
					}
				} else {
					this.uniforms.waveAlpha -= this.WAVES_FADE_RATE
					if (this.uniforms.waveAlpha <= 0) {
						this.waveTimer = this.TIME_BETWEEN_WAVES
						this.fadeInWave = true
						this.uniforms.waveOffsetTop = 0
					}
				}
			} else {
				this.waveTimer -= delta
			}
		}
	}

	setBlendColors(currentBiome: number, nextBiome: number): void {
		this.uniforms.waterColor = this.waterColors[currentBiome]
		this.uniforms.nextWaterColor = this.waterColors[nextBiome]
	}

	addToContainer(container: Container): void {
		container.addChild(this.mesh)
	}

	isVisible(): boolean {
		return this.mesh.visible
	}

	setVisible(visible: boolean): void {
		this.mesh.visible = visible
	}

	setPosition(x: number, y: number): void {
		this.mesh.position.set(x, y)
	}

	getPosition(): Point {
		return this.mesh.position
	}

	getWidth(): number {
		return this.mesh.width
	}

	private createTile(): void {
		const program: Program = new Program(this.vertSrc, this.fragSrc, this.isWaveTile ? 'waveShader' : 'waterShader')

		if (!WaterBlendTile.vbo) {
			WaterBlendTile.geometry = new Geometry()
			const bufferSize = this.VERT_POSITIONS.length * 4 + this.UVS.length * 4 + this.BLEND_UVS.length * 4 + 16
			const vertexData = new ArrayBuffer(bufferSize)
			const view = new DataView(vertexData, 0, vertexData.byteLength)
			let bufferOffset = 0
			for (let i = 0; i < 4; ++i) {
				view.setFloat32(bufferOffset, this.VERT_POSITIONS[i * 2], true)
				view.setFloat32(bufferOffset + 4, this.VERT_POSITIONS[i * 2 + 1], true)
				view.setFloat32(bufferOffset + 8, this.UVS[i * 2], true)
				view.setFloat32(bufferOffset + 12, this.UVS[i * 2 + 1], true)
				view.setFloat32(bufferOffset + 16, this.BLEND_UVS[i * 2], true)
				view.setFloat32(bufferOffset + 20, this.BLEND_UVS[i * 2 + 1], true)
				bufferOffset += 24
				view.setUint8(bufferOffset, 1)
				view.setUint8(bufferOffset + 1, 1)
				view.setUint8(bufferOffset + 2, 1)
				view.setUint8(bufferOffset + 3, 1)
				bufferOffset += 4
			}

			const indexData = new Uint16Array(this.QUAD_INDICES)

			WaterBlendTile.vbo = new Buffer(vertexData, true, false)
			WaterBlendTile.indexBuffer = new Buffer(indexData, true, true)

			WaterBlendTile.geometry.addAttribute('aPosition', WaterBlendTile.vbo, 2, false, TYPES.FLOAT, this.VERTEX_SIZE, 0)
			WaterBlendTile.geometry.addAttribute('aUV', WaterBlendTile.vbo, 2, false, PIXI.TYPES.FLOAT, this.VERTEX_SIZE, 8)
			WaterBlendTile.geometry.addAttribute('aBlendUV', WaterBlendTile.vbo, 2, false, PIXI.TYPES.FLOAT, this.VERTEX_SIZE, 16)
			WaterBlendTile.geometry.addAttribute('aColor', WaterBlendTile.vbo, 4, true, TYPES.UNSIGNED_BYTE, this.VERTEX_SIZE, 24)
			WaterBlendTile.geometry.addIndex(WaterBlendTile.indexBuffer)
		}

		const shader = new Shader(program, this.uniforms)
		const state = new State()

		state.blend = false
		// @ts-expect-error breaks contract with pix, wontfix
		this.mesh = new Mesh(WaterBlendTile.geometry, shader, state)
		this.mesh.blendMode = BLEND_MODES.NONE
	}

	private setupUniforms(): void {
		if (this.isWaveTile) {
			this.uniforms = {
				currentTex: WaterRenderer.waterTexture,
				waveTex: WaterRenderer.waveTexture,
				blendTex: WaterRenderer.blendTexture,
				currentOffsetTop: [0, 0],
				currentOffsetBottom: [0, 0],
				waveOffsetTop: 0,
				waveAlpha: 0,
				waterColor: this.waterColors[0],
				nextWaterColor: this.waterColors[1],
			}
		} else {
			this.uniforms = {
				currentTex: WaterRenderer.waterTexture,
				blendTex: WaterRenderer.blendTexture,
				currentOffsetTop: [0, 0],
				currentOffsetBottom: [0, 0],
				waterColor: this.waterColors[0],
				nextWaterColor: this.waterColors[1],
			}
		}
	}
}
