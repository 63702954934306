import { CooldownSlot } from "../../engine/shared/game-data/gear-skill-config";
import { playerStatListResetFn } from "../../engine/shared/game-data/player-formulas";
import { BinaryFlag, PropNameToStatType } from "../../engine/shared/game-data/stat-type-mod-category";
import { ProjectileConfig } from "../../projectiles/shared/projectile-types";
import EntityStatList from "../../stat-system/shared/entity-stat-list";
import { throwIfNotFinite } from "../../utils/math";
import { nengiId } from "../../utils/primitive-types";

/**
 * This class holds **your** (myEntity) local player data that could be stored in ClientPlayer.  
 * However, some of it comes before we have a ClientPlayer.  
 *  (we get `EquippedNewGearMessage` before create player message)
 */
export class LocalPlayerData {
	weaponSlots: { one: ProjectileConfig, two: ProjectileConfig }
	stats: EntityStatList
	gearSlotstats: {}
	gearSlots: { one: number, two: number, three: number }
	binaryFlagMap: Set<BinaryFlag>
	ignoreColliders: nengiId[]

	constructor() {
		this.weaponSlots = { one: undefined, two: undefined }
		this.stats = EntityStatList.alloc(playerStatListResetFn)
		this.gearSlotstats = {}
		this.gearSlots = { one: 0, two: 0, three: 0 }
		this.binaryFlagMap = new Set()
		this.ignoreColliders = []
	}

	getStat(statName: string): number {
		// const statType = PropNameToStatType[statName]
		// const value = localData.stats[statType]
		// throwIfNotFinite(value, `stat:${statName} is undefined or NaN: ${value}. This might need to be sent to client via updateCspStats`)
		return this.stats.getStat(statName)
	}

	getGearSlotPlusBaseStat(cooldownType: CooldownSlot, statName: string): number {
		const statType = PropNameToStatType[statName]
		const value = this.gearSlotstats[cooldownType][statType]
		throwIfNotFinite(value, `slot:${cooldownType} stat:${statName} is undefined or NaN: ${value}. This might need to be sent to client via updateCspStats`)
		return value
	}
}
