import GenericEntity from '../shared/generic-entity.shared'
import { InstancedSprite } from '../../world/client/instanced-sprite'
import { clientConfig } from '../../engine/client/client-config'
import { addDebugModel } from '../../debug/client/debug-client'
import { AssetManager } from '../../asset-manager/client/asset-manager'
import { getEntityConfig } from '../shared/generic-entity-config'
import Renderer from '../../engine/client/graphics/renderer'
import { RiggedSpineModel } from '../../models-animations/client/spine-model'
import { playAnimationList } from '../../models-animations/client/play-animation'
import { getSpineAsset } from '../../utils/pixi-util'
import { LayerRenderer } from '../../world/client/layer-renderer'

export class ClientGenericEntity extends GenericEntity {
	instancedSprite: InstancedSprite
	zIndex: number
	exemptFromModelCheck: boolean = true
	model: RiggedSpineModel[]
	timeouts: any[] = []

	constructor(entityData: any) {
		super()
		this.config = getEntityConfig(entityData.name)
		this.x = entityData.x
		this.y = entityData.y
		this.zIndex = entityData.y
		this.scale = entityData.scale
		this.lifetime = entityData.lifetime

		if (!clientConfig.renderDebugVisuals) {
			this._createTextureIf()
			this._createSpineIf()
			this._createPfxIf()

			this.exemptFromModelCheck = true
		}

		if (clientConfig.debug) {
			addDebugModel(this, `generic-${this.config.name}`, 20)
		}
	}

	onDelete() {
		if (this.model) {
			this.model.forEach((model) => {
				const parent = model.parent as LayerRenderer
				parent.removeChild(model)
				parent.removeFromScene(model)
				model.returnToPoolIfPooled()
			})
		}

		for(let i=0; i < this.timeouts.length; ++i) {
			clearTimeout(this.timeouts[i])
		}
		this.timeouts.length = 0

		this.model = null
	}

	private _createTextureIf() {
		if (this.config.texture !== undefined) {
			const tex = AssetManager.getInstance().getAssetByName(this.config.texture).texture
			this.instancedSprite = new InstancedSprite(tex, this.x, this.y, this.zIndex, this.scale, this.scale)
			Renderer.getInstance().mgRenderer.addPropToScene(this.instancedSprite)
		}
	}

	private _createSpineIf() {
		let spineConfigs = this.config.spine
		if (spineConfigs !== undefined) {
			if (!(spineConfigs instanceof Array)) {
				spineConfigs = [spineConfigs]
			}
			this.model = []

			spineConfigs.forEach((spineConfig) => {
				const assetName = spineConfig.asset
				const asset = getSpineAsset(assetName)

				const model = RiggedSpineModel.allocFromPool(assetName, 'default')
				this.model.push(model)
				model.name = assetName
				model.zIndex = this.y + spineConfig.zOffset
				model.scale.x = spineConfig.scale || 1
				model.scale.y = spineConfig.scale || 1

				let animations = spineConfig.animation
				if (!Array.isArray(animations)) {
					animations = [animations]
				}

				this.timeouts.concat(playAnimationList(model, animations, spineConfig.animationSpeed))
				Renderer.getInstance().mgRenderer.addDisplayObjectToScene(model)
			})
		}
	}

	private _createPfxIf() {
		const pfxName = this.config.pfx
		if (pfxName !== undefined) {
			Renderer.getInstance().addOneOffEffect(pfxName, this.x, this.y, this.y + 1, 1, this.lifetime)
		}
	}
}

export const clientGenericHooks = {}
