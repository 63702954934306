
















export default {
	name: 'TableData',
	props: {
		tableData: {
			type: Array,
			required: true,
			default: () => [],
		},
		hasStatIncreaseDecreaseColumn: {
			type: Boolean,
			required: true,
			default: false,
		},
		tableLabel: {
			type: String,
			required: false,
			default: 'Default Table Label',
		},
		hasTableLabel: {
			type: Boolean,
			required: true,
			default: false,
		},
	},
}
