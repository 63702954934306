import { getBiomeMinLevel, getBiomeMaxLevel } from '../../../biome/shared/biome-list'
import { biomeIndex, healthPercentage, level, levelOffset, percentage, timeInSeconds } from '../../../utils/primitive-types'
import { mapToRange, throwIfNotFinite } from '../../../utils/math'
import { gameModeConfig } from '../game-mode-configs'
import { EnemyType } from '../../../ai/shared/ai-types'
import { PressureLoadout } from './pressure-stat-info.shared'
import { pressureGetAllEnemyHealthBonus, pressureGetBossHealthBonus, pressureGetChampionHealthBonus } from './pressure-stat-functions.shared'
import { LEVELS_PER_BIOME } from '../../../biome/shared/biome-constants'
import { WorldDifficulty } from './world-difficulty'

// NOTE: setting this lower than 0.2 makes the red not go away fully. Probably could be investigated more,but for now I'm just clamping it.
const MIN_DAMAGE_ALPHA = 0.25
const ENABLE_INTERMEDIATE_LEVELS = true // Enemies can spawn as level 1-3*y instead of 3*y. Default for production: true

export const MAX_STAT_LERP: timeInSeconds = 1.5
// Which metric to use for player proximity scaling
// True: enemy.playersNearby
// False: enemy.damagedBy
// Default for production: true
export const ENEMY_MULTIPLAYER_SCALING_USE_PLAYERS_NEARBY: boolean = true
export const ENEMY_MULTIPLAYER_SCALING_MAX_DISTANCE: number = 900

export const BOSS_LOOT_DROP_QUANTITY = 6
export const BOSS_LOOT_DROP_RARITY = 5

//TODO: Make this constant 1, 100 is just confusing
export const ENEMY_DEFAULT_HEALTH = 100
//TODO: Delete this constant, all enemies should have "normal" health with a range of 0-100 (or 0-1 if the above is changed)
export const BOSS_HEALTH_MULTIPLIER = 60

export const ENEMY_LOOT_DROP_PARTICIPATION_CONSTANT = 0.10
export const BOSS_LOOT_DROP_PARTICIPATION_CONSTANT = 0.10

/** When enemy hits a player, what is considered a "small hit"? Used for screen shake and other VFX. */
export const ENEMY_SMALL_HIT_ON_PLAYER_DAMAGE = 8
/** When enemy hits a player, what is considered a "big hit"? Used for screen shake and other VFX. */
export const ENEMY_BIG_HIT_ON_PLAYER_DAMAGE = 51

export const ENEMY_SPAWN_TIME = 2

// For Brutal health scaling
const ENEMY_MIN_TTK: timeInSeconds = 2.3
const ENEMY_MAX_TTK: timeInSeconds = 4.0
const NUM_BIOMES_BEFORE_MAX_TTK = 44
const TTK_FACTOR_PER_BIOME = (ENEMY_MAX_TTK - ENEMY_MIN_TTK) / NUM_BIOMES_BEFORE_MAX_TTK

export function getEnemyLevelOffsetFromBiome(biomeIdx: biomeIndex, biomeDepth: number): levelOffset {
	console.assert(biomeDepth > -0.1 && biomeDepth < 1.1, `bad biomeDepth: ${biomeDepth}`)
	biomeDepth = Math.clamp(biomeDepth, 0, 0.99)
	const originalLevel = Math.floor(Math.lerp(getBiomeMinLevel(biomeIdx), getBiomeMaxLevel(biomeIdx) + 1, biomeDepth))
	const enemyLevelOffset = originalLevel
	console.assert(enemyLevelOffset > 0 && enemyLevelOffset < 20, `bad enemy level:${enemyLevelOffset}, biomeLevel:${originalLevel} enemy.sourceBiome:${biomeIdx} gameModeConfig.type:${gameModeConfig.type}`)
	return enemyLevelOffset
}

/**
 * Assuming every enemy has 100(ish) health, we need to figure out, based on our player's current personalDifficulty,
 * and the damage that they *actually do* based on their weapons/augments, how much damage we should do to the enemy's
 * 100 health.
 *
 * We use the difficultyLevel to pull the "real health" for this enemy from the ENEMY_HEALTH_SCALING_TABLE, and compare
 * that against the damage. This gives us a percentage, which we then deal *that percentage* of damage to the enemy.
 *
 * Our enemy starts with 100 health, a run of the mill enemy.
 *
 * So if we have difficultyLevel of 15, this maps to the 5th entry in ENEMY_HEALTH_SCALING_TABLE = 525 (each entry is 3
 * levels). If the damage is 150, that is 28.571% damage. So we deal 28.571 damage to the enemy, leaving the enemy with 71.428 health.
 *
 * If another player that is difficultyLevel 3 hits the enemy, this is the 1st entry = 90. If the damage is 30, that's
 * 33.333% damage, or 33.333 actual damage, leaving the previously damaged enemy with 38.095 health remaining.
 *
 * If the enemy was near other players (proximity scaling), then they have already received a health boost out-of-band
 * via the proximity update, and their health is 100 * (1 + 0.6 * (numPlayers - 1)). So if 3 players are nearby, the enemy's
 * health has already been boosted to 220.
 */
export function getEnemyDifficultyLevelModifiedDamage(enemy: { levelOffset: levelOffset, baseHealth: healthPercentage; enemyType: EnemyType; maxHealth: healthPercentage }, attackerDifficultyLevel: number, damage: number, pressureLoadout: PressureLoadout, worldDifficulty: WorldDifficulty): healthPercentage {
	// this guard is in case the owner of damage is ever not found. This was the case when a user exited the game while causing damage.
	// This case was fixed, but adding this extra safeguard.
	pressureLoadout = pressureLoadout || {}

	const biomeIdx = getTableIndexByRawLevel(attackerDifficultyLevel)
	const difficultyModifiedDamage = getDifficultyLevelModifiedDamage(biomeIdx, damage, enemy.levelOffset, enemy.enemyType, pressureLoadout, worldDifficulty)
	// console.log(`    getEnemyDiffDam() ${(enemy as any)?.name} baseHP ${enemy.baseHealth} maxHP ${enemy.maxHealth}, atkDiff: ${attackerDifficultyLevel}, biomeIdx: ${biomeIdx} dam: ${damage}, modDam: ${difficultyModifiedDamage}`)

	const baseHealth = enemy.baseHealth
	throwIfNotFinite(baseHealth)

	// console.log({
	// 	difficultyHealth,
	// 	differenceFromBaseHealthMultiplier,
	// 	damage,
	// 	difficultyModifiedDamage,
	// 	ret: difficultyModifiedDamage * differenceFromBaseHealthMultiplier,
	// })
	let healthMultiplier = ENEMY_DEFAULT_HEALTH

	if (enemy.enemyType === EnemyType.BOSS) {
		healthMultiplier *= getBossDamageRatio(biomeIdx, enemy.levelOffset)
	}

	const finalDamage = difficultyModifiedDamage * healthMultiplier
	// console.log(`getEnemyDifficultyLevelModifiedDamage(enemy health ${enemy.baseHealth}, ${attackerDifficultyLevel}, ${damage}),  final ${finalDamage} = ${difficultyModifiedDamage} * ${differenceFromBaseHealthMultiplier} * ${enemy.baseHealth}`)
	throwIfNotFinite(finalDamage)
	return finalDamage
}

//TODO: This may be able to be removed along with BOSS_HEALTH_MULTIPLIER changes. Needs investigation.
export function getBossDamageRatio(biomeIdx, enemyLevelOffset): number {
	const biomeIdxOffset = getTableIndexByRawLevel(enemyLevelOffset)
	const clampedIdx = biomeIdx = Math.clamp(biomeIdx + biomeIdxOffset, 0, BOSS_HEALTH_TABLE.length - 1)
	return BOSS_HEALTH_TABLE[clampedIdx] / ENEMY_HEALTH_SCALING_TABLE[clampedIdx]
}

/** This returns a percentage representing the amount of damage dealt, relative to the difficulty the player is playing on,
 *  assuming an average max enemy health of 100.
 */
export function getDifficultyLevelModifiedDamage(biomeIdx: number, damage: number, enemyLevelOffset: number, enemyType: EnemyType, pressureLoadout: PressureLoadout, worldDifficulty: WorldDifficulty): healthPercentage {
	const levelScaledMaxHealth = getMaximumHealth(biomeIdx, enemyLevelOffset, enemyType, pressureLoadout, worldDifficulty)

	// console.log(`  getDifficultyLevelModifiedDamage(${biomeIdx}, ${damage}) = ${damage / levelScaledMaxHealth}`)
	return damage / levelScaledMaxHealth
}

/**
 * This table generated by a polynomial trendline,used because the bottom bound of this poly is poop.
 * https://docs.google.com/spreadsheets/d/1TnEr7iGZu84M6as_Q_l5l3q3ncl2urAkkiTncol--N4/edit#gid=336729253
 */

/** Accepts a player, enemy, or item level and returns a biome level (where 1-3 levels = 1 biome level) */
function getBiomeLevelByRawLevel(level: level): level {
	return Math.floor((level - 1) / LEVELS_PER_BIOME) + 1
}

/** Accepts a player, enemy, or item level (where 1-3 levels = 1 biome level) and returns the table index for tables like ENEMY_HEALTH_SCALING_TABLE */
function getTableIndexByRawLevel(level: level): number {
	return getTableIndexByBiomeLevel(getBiomeLevelByRawLevel(level))
}

/** Accepts a biome level and returns the table index for tables like ENEMY_HEALTH_SCALING_TABLE (Beach biome = level 1, Forest biome = level 2, etc.) */
function getTableIndexByBiomeLevel(biomeLevel: level): number {
	return Math.max(0, biomeLevel - 1)
}

const ENEMY_HEALTH_SCALING_TABLE = [
	// imported from CSV on 2022/03/08
	67, //3
	113, //6
	153, //9
	207, //12
	280, //15
	216, //18
	293, //21
	553, //24
	820, //27
	1_209, //30
	1_122, //33
	1_783, //36
	2_674, //39
	4_637, //42
	7_017, //45
	5_573, //48
	7_864, //51
	14_885, //54
	20_700, //57
	29_705, //60
	20_770, //63
	25_710, //66
	33_914, //69
	41_705, //72
	50_504, //75
	44_542, //78
	59_390, //81
	82_986, //84
	129_613, //87
	157_321, //90
	116_330, //93
	140_136, //96
	189_754, //99
	255_169, //102
	341_035, //105
	282_089, //108
	376_461, //111
	447_127, //114
	513_654, //117
	640_924, //120
	432_046, //123
	492_007, //126
	558_928, //129
	658_914, //132
	748_328, //135
	499_192, //138
	561_409, //141
	632_742, //144
	735_167, //147
	860_571, //150
	553_908, //153
	624_119, //156
	701_715, //159
	839_482, //162
	943_784, //165
	608_610, //168
	684_178, //171
	770_670, //174
	916_763, //177
	1_032_560, //180
	664_370, //183
	748_217, //186
	840_946, //189
	999_121, //192
	1_122_873, //195
	723_762, //198
	819_084, //201
	968_443, //204
	1_090_286, //207
	1_225_102, //210
	789_456, //213
	887_021, //216
	1_047_968, //219
	1_177_415, //222
	1_325_230, //225
	852_262, //228
	959_183, //231
	1_128_450, //234
	1_269_926, //237
	1_426_554, //240
	918_920, //243
	1_032_201, //246
	1_213_838, //249
	1_363_406, //252
	1_534_010, //255
	986_270, //258
	1_109_606, //261
	1_300_000, //264
	1_462_472, //267
	1_642_434, //270
	1_057_617, //273
	1_187_705, //276
	1_391_250, //279
	1_562_302, //282
	1_757_218, //285
	1_129_512, //288
	1_270_353, //291
	1_483_092, //294
	1_667_922, //297
	1_872_742, //300
	1_205_549, //303
	1_353_532, //306
	1_580_204, //309
	1_774_103, //312
	1_994_853, //315
	1_281_987, //318
	1_441_424, //321
	1_677_726, //324
	1_886_277, //327
	2_117_477, //330
	1_362_714, //333
	1_529_683, //336
	1_780_701, //339
	1_998_808, //342
	2_246_917, //345
	1_443_697, //348
	1_622_818, //351
	1_883_903, //354
	2_117_536, //357
	2_376_641, //360
	1_529_113, //363
	1_716_157, //366
	1_992_740, //369
	2_236_418, //372
	2_513_408, //375
	1_614_640, //378
	1_814_536, //381
	2_101_622, //384
	2_361_701, //387
	2_650_232, //390
	1_704_746, //393
	1_912_956, //396
	2_216_321, //399
	2_486_932, //402
	2_794_327, //405
	1_794_817, //408
	2_016_578, //411
	2_330_883, //414
	2_618_769, //417
	2_938_251, //420
	1_889_613, //423
	2_120_078, //426
	2_451_444, //429
	2_750_352, //432
	3_089_674, //435
	1_984_228, //438
	2_228_943, //441
	2_571_686, //444
	2_888_743, //447
	3_240_698, //450
]

/**
 * 
 * @param biomeIdx the 0-index of the biome the enemy is living in
 * @param levelOffset a level offset to apply, this comes from either a player's personalDifficulty settings on the server, or an enemy's level offset on the client
 * @param enemyType 
 * @param pressureLoadout 
 * @returns 
 */
export function getMaximumHealth(biomeIdx, levelOffset: number, enemyType: EnemyType, pressureLoadout: PressureLoadout, worldDifficulty: WorldDifficulty) {
	let table = ENEMY_HEALTH_SCALING_TABLE
	const biomeIdxOffset = getTableIndexByRawLevel(levelOffset)
	biomeIdx = Math.clamp(biomeIdx + biomeIdxOffset, 0, table.length - 1)

	let bonusHPFromPressure = 1.0
	if (enemyType === EnemyType.BOSS) {
		table = BOSS_HEALTH_TABLE

		bonusHPFromPressure += pressureGetBossHealthBonus(pressureLoadout)
	} else if (enemyType === EnemyType.CHAMPION || enemyType === EnemyType.LOOTLESS_CHAMPION) {
		bonusHPFromPressure += pressureGetChampionHealthBonus(pressureLoadout)
	}

	bonusHPFromPressure += pressureGetAllEnemyHealthBonus(pressureLoadout)

	let bonusHPFromWorldDifficulty = 1.0
	if (worldDifficulty === WorldDifficulty.Brutal) {
		bonusHPFromWorldDifficulty = Math.max(ENEMY_MAX_TTK / (ENEMY_MIN_TTK + (biomeIdx * TTK_FACTOR_PER_BIOME)), 1.0)
	}

	// console.log(`getMaximumHealth(level: ${levelOffset}, levelOffset: ${levelOffset}, biomeIdx: ${biomeIdx}, biomeOffset: ${biomeIdxOffset}) = table at index ${biomeIdx} = ${table[biomeIdx]}`)
	return table[biomeIdx] * bonusHPFromPressure * bonusHPFromWorldDifficulty
}

export interface DamageConfig {
	// these are ratios (0->1),not percentages
	hitAnimationDamageRatios: percentage[]
}

export const basicEnemyDamageConfig: DamageConfig = {
	hitAnimationDamageRatios: [0.0, 0.3],
}
export const bossEnemyDamageConfig: DamageConfig = {
	hitAnimationDamageRatios: [0.0, 0.0075],
}

export function getHitAnimAlpha(damageConfig: DamageConfig, maxHealth: number, damage: number): number {
	const damageRatio = damage / maxHealth
	const hitRatios = damageConfig.hitAnimationDamageRatios

	//console.log(`getHitAnimAlpha maxHealth:${maxHealth} :damage:${damage}`)
	const hitAlpha = mapToRange(damageRatio, hitRatios[0], hitRatios[1], MIN_DAMAGE_ALPHA, 1, true)
	//console.log(`damageRatio:${damageRatio} hitAlpha:${hitAlpha}`)

	return hitAlpha
}

const BOSS_HEALTH_TABLE = [
	// imported from CSV on 2022/03/08
	2_641, //3
	5_713, //6
	9_707, //9
	17_361, //12
	27_771, //15
	7_894, //18
	13_660, //21
	32_496, //24
	63_762, //27
	111_269, //30
	37_976, //33
	77_355, //36
	146_027, //39
	335_602, //42
	602_016, //45
	175_984, //48
	318_598, //51
	759_852, //54
	1_401_561, //57
	2_386_444, //60
	614_695, //63
	976_970, //66
	1_625_029, //69
	2_652_713, //72
	3_814_484, //75
	1_736_350, //78
	2_597_093, //81
	4_281_860, //84
	8_349_074, //87
	12_612_478, //90
	4_281_556, //93
	5_789_627, //96
	9_256_007, //99
	15_548_693, //102
	25_879_218, //105
	9_833_192, //108
	14_739_161, //111
	20_680_654, //114
	29_694_809, //117
	46_168_052, //120
	14_303_874, //123
	18_304_893, //126
	24_578_583, //129
	36_234_745, //132
	51_301_378, //135
	15_888_331, //138
	20_281_744, //141
	27_287_014, //144
	40_037_540, //147
	58_996_162, //150
	17_629_855, //153
	22_547_231, //156
	30_261_463, //159
	45_718_615, //162
	64_700_822, //165
	19_370_927, //168
	24_716_942, //171
	33_235_147, //174
	49_927_366, //177
	70_786_805, //180
	21_145_642, //183
	27_030_439, //186
	36_265_813, //189
	54_412_611, //192
	76_978_205, //195
	23_035_983, //198
	29_590_613, //201
	41_764_106, //204
	59_377_482, //207
	83_986_477, //210
	25_126_893, //213
	32_044_952, //216
	45_193_635, //219
	64_122_576, //222
	90_850_723, //225
	27_125_897, //228
	34_651_909, //231
	48_664_426, //234
	69_160_810, //237
	97_796_960, //240
	29_247_487, //243
	37_289_784, //246
	52_346_765, //249
	74_251_752, //252
	105_163_563, //255
	31_391_118, //258
	40_086_166, //261
	56_062_507, //264
	79_646_931, //267
	112_596_540, //270
	33_661_976, //273
	42_907_575, //276
	59_997_656, //279
	85_083_723, //282
	120_465_500, //285
	35_950_235, //288
	45_893_382, //291
	63_958_349, //294
	90_835_847, //297
	128_385_217, //300
	38_370_360, //303
	48_898_327, //306
	68_146_308, //309
	96_618_487, //312
	136_756_534, //315
	40_803_246, //318
	52_073_558, //321
	72_351_952, //324
	102_727_557, //327
	145_162_991, //330
	43_372_639, //333
	55_262_039, //336
	76_792_721, //339
	108_856_046, //342
	154_036_665, //345
	45_950_153, //348
	58_626_694, //351
	81_243_315, //354
	115_322_061, //357
	162_929_861, //360
	48_668_814, //363
	61_998_710, //366
	85_936_895, //369
	121_796_399, //372
	172_305_893, //375
	51_390_955, //378
	65_552_790, //381
	90_632_440, //384
	128_619_360, //387
	181_685_829, //390
	54_258_884, //393
	69_108_342, //396
	95_578_830, //399
	135_439_546, //402
	191_564_218, //405
	57_125_653, //408
	72_851_846, //411
	100_519_325, //414
	142_619_452, //417
	201_430_894, //420
	60_142_850, //423
	76_590_934, //426
	105_718_525, //429
	149_785_488, //432
	211_811_640, //435
	63_154_246, //438
	80_523_862, //441
	110_903_972, //444
	157_322_339, //447
	222_165_055, //450
]

