import nengi from 'nengi'
import { Vector } from 'sat'
import { CircleCollider } from '../../collision/shared/colliders'
import { angleDiff, distance, throwIfNotFinite, VectorXY } from '../../utils/math'
import { gameUnits, gameUnitsPerSecond, nengiId, percentage, radians, timeInSeconds } from '../../utils/primitive-types'
import { angleInRadsFromVector } from '../../utils/vector'
import { ProjectileConfigId } from './projectile-config-ids'
import { ModType, ModValueType, TrajectoryMod, TrajectoryModValue } from './projectile-types'

export const MAX_COLLIDER_RADIUS = 1000
export const ANGLE_MULT_OPT = 100
// this is required for pooling
export const MAX_PROJECTILE_AOE_DELAY_SECONDS = 3

const RADIUS_SPREAD_FACTOR = 0.7

/**
 * All the state of a projectile that is concerned with movement and changes over projectile lifetime.  
 * All of these must be backed up in {@link copyProjectileState} for prediction purposes.
 */
export interface IProjectileMovementState {
	x: gameUnits
	y: gameUnitsPerSecond
	speed: number
	aimAngleInRads: radians
	travelTimeElapsedInSeconds: timeInSeconds
	travelTimeElapsedForWaveFunctions: timeInSeconds
	travelTimeElapsedForWaveFunctionsPrev: timeInSeconds
	willReverseTrajectory: boolean
	reversedTrajectory: boolean
	leftRightFlip: boolean
	reachedMaxRange: boolean
	angleToPeakOfArc: radians
	toBeDeleted: boolean
}

class Projectile {
	static protocol = {
		// TODO2: set these back to Float32 assuming they don't cause csp drift
		x: { type: nengi.Number, interp: true },
		y: { type: nengi.Number, interp: true },
		toBeDeleted: { type: nengi.Boolean },
		projectileId: { type: nengi.UInt32 },
		projectileConfigId: { type: nengi.UInt10 },
		speed: { type: nengi.Float32, interp: true },
		owningEntityId: { type: nengi.UInt16 },
		playerOwned: { type: nengi.Boolean },
		aimAngleInRads: { type: nengi.Number, },
		colliderRadius: { type: nengi.UInt10 }, // max radius 1000, see MAX_COLLIDER_RADIUS
		particleEffect: { type: nengi.UInt16 }, // max id 65535, see MAX_PROJECTILE_ID
		bulletTrailParticleEffect: { type: nengi.UInt16 }, // max id 65535, see MAX_PROJECTILE_ID

		lifespan: { type: nengi.Float32, interp: false },
		distanceMod: { type: nengi.Float32, interp: false },
		leftRightFlip: { type: nengi.Boolean },
		randomMod: { type: nengi.Number, interp: false },
	}
	nid: number
	projectileId: number
	/** for enemies with csp enabled, sends the projectile config  */
	projectileConfigId: ProjectileConfigId
	x: number
	y: number

	get position() {
		this._position.x = this.x
		this._position.y = this.y
		return this._position
	}
	set position(newValue) {
		this._position.x = newValue.x
		this._position.y = newValue.y
		this.x = newValue.x
		this.y = newValue.y
	}
	_position: Vector = new Vector(0, 0)

	toBeDeleted: boolean

	get speed(): number {
		return this._speed
	}
	set speed(value: number) {
		//throwIfNotFinite(value)
		this._speed = value
	}
	_speed: number

	owningEntityId: nengiId
	owningEntity: any = undefined
	playerOwned: boolean
	get aimAngleInRads(): number {
		return this._aimAngleInRads
	}
	set aimAngleInRads(value) {
		//throwIfNotFinite(value)
		this._aimAngleInRads = value
	}
	_aimAngleInRads: number
	collider: CircleCollider
	colliderRadius: number
	particleEffect: number
	bulletTrailParticleEffect: number
	travelTimeElapsedInSeconds: number = 0
	maxRange: gameUnits
	randomMod: number
	distanceMod: percentage
	initialSpeed: number
	reversedTrajectory: boolean = false
	leftRightFlip: boolean = false
	travelTimeElapsedForWaveFunctions: number
	travelTimeElapsedForWaveFunctionsPrev: number
	startPos: Vector
	radiansTravelled: number
	static radiansForCircularTargetReset: number = 4.7123925 // 75% of a full revolution
	baseTrajectory: Vector
	lifespan: number
	willReverseTrajectory: boolean = false
	baseColliderRadius: number
	mods: TrajectoryMod[]
	spreadModifiersSet: boolean = false
	radiusSpreadModifier: number = 1
	waveSpreadOffset: number = 0

	parseModValue(modValue: TrajectoryModValue): number {
		const r = this.parseModValueNoTry(modValue)
		if (!isFinite(r)) {
			this.parseModValueNoTry(modValue)
		}
		// TODO: this is throwing for gorgon, fixing soon
		//throwIfNotFinite(r, `bad trajectory mod value from: ${JSON.stringify(modValue)}`)
		return r
	}

	parseModValueNoTry(modValue: TrajectoryModValue): number {
		switch (modValue.modValueType) {
			case ModValueType.VALUE:
				return modValue.value as number
			case ModValueType.RANDOM:
				return (modValue.min as number) + ((modValue.max as number) - (modValue.min as number)) * this.randomMod
			case ModValueType.DISTANCE:
				return (modValue.min as number) + ((modValue.max as number) - (modValue.min as number)) * this.distanceMod
			case ModValueType.LIFETIME:
				return (modValue.min as number) + ((modValue.max as number) - (modValue.min as number)) * (this.travelTimeElapsedInSeconds / this.lifespan)
			case ModValueType.LIFETIME_SQUARED:
				return (modValue.min as number) + ((modValue.max as number) - (modValue.min as number)) * (Math.pow(this.travelTimeElapsedInSeconds, 2) / Math.pow(this.lifespan, 2))
		}
	}

	applyTrajectoryModInitialState(mod: TrajectoryMod): void {
		if (!this.spreadModifiersSet) {
			if(!this.baseTrajectory) {
				// console.error("no base trajectory set, setting a fake one to stop crashes")
				this.baseTrajectory = new Vector(0,0)
			}
			const baseAngle = angleInRadsFromVector(this.baseTrajectory)
			const spread = (baseAngle + Math.PI) - (this.aimAngleInRads + Math.PI)
			this.radiusSpreadModifier = 1 + (spread * RADIUS_SPREAD_FACTOR)
			// TODO: Experiment with wave spread offset if you want wave trajectories to start at a different point in the arc
			//       The only piece that's missing is making sure zigzags start at the correct spot
			this.waveSpreadOffset = 0//spread

			this.spreadModifiersSet = true
		}

		switch (mod.modType) {
			case ModType.HARD_TURN:
				this.aimAngleInRads += this.parseModValue(mod.value) * (this.leftRightFlip ? -1 : 1)
				break
			case ModType.WAVE:
				const wavePeriod = this.parseModValue(mod.period)
				const waveAmplitude = (this.parseModValue(mod.amplitude) / wavePeriod) * (this.leftRightFlip ? -1 : 1)
				// Store this value to save having to recompute it every frame
				// It is our arc length factor, based on an approximation of the arc length of a typical sine wave
				mod.computed = Math.pow(1.216, Math.abs(waveAmplitude))
				const waveFrequency = (Math.PI * 2) / (wavePeriod * mod.computed)
				this.aimAngleInRads += Math.atan(waveAmplitude * waveFrequency * Math.cos(waveFrequency * (this.travelTimeElapsedForWaveFunctions + this.waveSpreadOffset)))
				this.speed *= mod.computed
				break
			case ModType.ZIGZAG:
				const zigzagPeriod = this.parseModValue(mod.period)
				const zigzagAmplitude = this.parseModValue(mod.amplitude) / 4 / zigzagPeriod
				this.aimAngleInRads += Math.atan(zigzagAmplitude / (zigzagPeriod / 4)) * (this.leftRightFlip ? -1 : 1)
				break
			case ModType.CIRCLE_SELF:
			case ModType.CIRCLE_POINT:
				this.limitCircularSpeed(this.parseModValue(mod.radius))
				break
		}
	}

	applyTrajectoryMod(mod: TrajectoryMod, delta: number): void {
		const turnSpeedMod = 0.02 // Used for circular mods and is somewhat arbitrary, I just picked a number that I felt gave decent looking results

		switch (mod.modType) {
			case ModType.ACCELERATION:
				this.speed += this.speed * this.parseModValue(mod.value) * delta
				break
			case ModType.ABS_ACCELERATION:
				this.speed += this.initialSpeed * this.parseModValue(mod.value) * delta * (this.reversedTrajectory ? -1 : 1)
				if (this.speed < 0) {
					this.reversedTrajectory = true
					this.willReverseTrajectory = false
				}
				break
			case ModType.TURN:
				this.aimAngleInRads += this.parseModValue(mod.value) * delta * (this.leftRightFlip ? -1 : 1)
				break
			case ModType.WAVE:
				const wavePeriod = this.parseModValue(mod.period)
				throwIfNotFinite(wavePeriod, 'wavePeriod')
				const waveAmplitude = (this.parseModValue(mod.amplitude) / wavePeriod) * (this.leftRightFlip ? -1 : 1)
				throwIfNotFinite(waveAmplitude, 'waveAmplitude')
				// applyTrajectoryModInitialState isn't executed client side during csp for enemy projectiles
				//  as it's already done on server and values like aimAngleInRads below are already at their correct value
				if (mod.computed === undefined) {
					mod.computed = Math.pow(1.216, Math.abs(waveAmplitude))
				}
				const waveFrequency = (Math.PI * 2) / (wavePeriod * mod.computed)
				throwIfNotFinite(waveFrequency, 'waveFrequency')
				const currAngle = Math.atan(waveAmplitude * waveFrequency * Math.cos(waveFrequency * (this.travelTimeElapsedForWaveFunctions + this.waveSpreadOffset)))
				throwIfNotFinite(currAngle, 'currAngle')
				const prevAngle = Math.atan(waveAmplitude * waveFrequency * Math.cos(waveFrequency * (this.travelTimeElapsedForWaveFunctionsPrev + this.waveSpreadOffset)))
				throwIfNotFinite(prevAngle, 'prevAngle')
				this.aimAngleInRads += (currAngle - prevAngle) * (this.reversedTrajectory ? -1 : 1)

				throwIfNotFinite(this.aimAngleInRads, 'this.aimAngleInRads')
				break
			case ModType.ZIGZAG:
				const zigzagPeriod = this.parseModValue(mod.period)
				const zigzagAmplitude = this.parseModValue(mod.amplitude) / 4
				for (let t = 1; t < ((this.lifespan * (this.speed / 500)) / zigzagPeriod) * 2; ++t) {
					if (!this.reversedTrajectory) {
						if ((this.travelTimeElapsedForWaveFunctionsPrev + this.waveSpreadOffset) < t * zigzagPeriod * 0.5 - zigzagPeriod * 0.25 && (this.travelTimeElapsedForWaveFunctions + this.waveSpreadOffset) >= t * zigzagPeriod * 0.5 - zigzagPeriod * 0.25) {
							this.aimAngleInRads += 2 * Math.atan(zigzagAmplitude / (zigzagPeriod / 4)) * (t % 2 === 0 ? 1 : -1) * (this.leftRightFlip ? -1 : 1)
							break
						}
					} else {
						if ((this.travelTimeElapsedForWaveFunctionsPrev + this.waveSpreadOffset) > t * zigzagPeriod * 0.5 - zigzagPeriod * 0.25 && (this.travelTimeElapsedForWaveFunctions + this.waveSpreadOffset) <= t * zigzagPeriod * 0.5 - zigzagPeriod * 0.25) {
							this.aimAngleInRads += 2 * Math.atan(zigzagAmplitude / (zigzagPeriod / 4)) * (t % 2 === 0 ? 1 : -1) * (this.leftRightFlip ? -1 : 1)
							break
						}
					}
				}
				break
			case ModType.CIRCLE_POINT:
				const targetA = angleInRadsFromVector(this.baseTrajectory)
				const targetDistance = this.parseModValue(mod.range)

				this.applyCircularMod(this.parseModValue(mod.radius) * this.radiusSpreadModifier, this.speed * turnSpeedMod, this.startPos.x + Math.cos(targetA) * targetDistance, this.startPos.y + Math.sin(targetA) * targetDistance, delta)
				break
			case ModType.CIRCLE_SELF:
				// TODO1 this won't work for csping of player projectiles, as we don't send owningEntity of projectiles to client
				const owningEntity = (this as any).owningEntity as VectorXY
				if (owningEntity) {
					this.startPos.x = owningEntity.x
					this.startPos.y = owningEntity.y

					this.applyCircularMod(this.parseModValue(mod.radius) * this.radiusSpreadModifier, this.speed * turnSpeedMod, owningEntity.x, owningEntity.y, delta)
				}
				break
			case ModType.SIZE:
				this.colliderRadius = this.baseColliderRadius * this.parseModValue(mod.value)
				const collider = this.collider
				if (collider) {
					collider.r = this.colliderRadius
				}
				break
		}
	}

	applyCircularMod(radius: number, turnSpeed: number, centerX: number, centerY: number, delta: number) {
		const distanceFromTarget = distance(this.x, this.y, centerX, centerY)
		const distanceFromOrbit = distanceFromTarget - radius
		const angleToTarget = Math.atan2(this.y - centerY, this.x - centerX)
		const diffAngle = angleDiff(angleToTarget, this.aimAngleInRads)
		const clockwise = diffAngle <= 0 ? 1 : -1
		const orbitAngle = angleToTarget + Math.PI * 0.5 * clockwise
		const idealAngle = orbitAngle + Math.atan(distanceFromOrbit / radius - 1) * clockwise
		const diff = angleDiff(idealAngle, this.aimAngleInRads) * Math.min(turnSpeed * delta, 1)
		this.radiansTravelled += Math.abs(diff)
		this.aimAngleInRads += diff

		this.limitCircularSpeed(radius)
	}

	limitCircularSpeed(radius: number) {
		const circumference = 2 * radius * Math.PI
		this.speed = Math.clamp(this.speed, 0, circumference * 4) // the *4 is just based on look-and-feel testing
	}
}

export function copyProjectileState(from: IProjectileMovementState, to: IProjectileMovementState) {
	to.x = from.x
	to.y = from.y
	to.speed = from.speed
	to.aimAngleInRads = from.aimAngleInRads
	to.travelTimeElapsedInSeconds = from.travelTimeElapsedInSeconds
	to.travelTimeElapsedForWaveFunctions = from.travelTimeElapsedForWaveFunctions
	to.travelTimeElapsedForWaveFunctionsPrev = from.travelTimeElapsedForWaveFunctionsPrev
	to.willReverseTrajectory = from.willReverseTrajectory
	to.reversedTrajectory = from.reversedTrajectory
	to.leftRightFlip = from.leftRightFlip
	to.reachedMaxRange = from.reachedMaxRange
	to.angleToPeakOfArc = from.angleToPeakOfArc
	to.toBeDeleted = from.toBeDeleted
}

export default Projectile
