import ItemRarity from "../../loot/shared/item-rarity"
import { PrizeData } from "../../loot/shared/prize-sub-type"

export enum EmoteType {
    None = 0,
    Typing = 1,
    Angry,
    AyyRanger,
    CardinalCry,
    ChaosCrab,
    CorgiThink,
    Cry,
    Excited,
    Happy,
    IGetThatBubba,
    Love,
    MadToTheBone,
    NervousRanger,
    Neutral,
    PalmExcited,
    RaccoonLove,
    RageKnight,
    RottweilerPopcorn,
    Sad,
    Scared,
    SoSugoiSorceress,
    SquintElf,
    Surprised,
    SusWizard,
    ThisIsFineDwarf,
    Thumbsup,
    Easter_DissapointBun,
    Easter_LaughBun,
    Easter_LoveBun,
    Easter_MuscleEgg,
    Easter_SusEgg,
    Easter_WinkChick,
}

// Update these when adding Emote bundles to mtx.js in Atlas
export enum MtxBundleIds {
    PETS = 'bundle-emotes-pets',
    SPECIAL = 'bundle-emotes-special',
    SPECIAL2 = 'bundle-emotes-special2',
    EASTER = 'bundle-emotes-easter',
}

export const EmoteTypeValues = getEnumKeys(EmoteType)

export enum NPCEmoteType {
    None = 0,
    PetGreeting = 1,
}

export enum NPCEmoteSkin {
    Heart = 'heart',
    SmileyFace = 'smiley-face',
}

const EmoteIcons = new Map<EmoteType, string>()
EmoteIcons.set(EmoteType.Angry, 'emote-angry')
EmoteIcons.set(EmoteType.AyyRanger, 'emote-ayy-ranger')
EmoteIcons.set(EmoteType.CardinalCry, 'emote-cardinal-cry')
EmoteIcons.set(EmoteType.ChaosCrab, 'emote-chaos-crab')
EmoteIcons.set(EmoteType.CorgiThink, 'emote-corgi-think')
EmoteIcons.set(EmoteType.Cry, 'emote-cry')
EmoteIcons.set(EmoteType.Excited, 'emote-excited')
EmoteIcons.set(EmoteType.Happy, 'emote-happy')
EmoteIcons.set(EmoteType.IGetThatBubba, 'emote-i-get-that-bubba')
EmoteIcons.set(EmoteType.Love, 'emote-love')
EmoteIcons.set(EmoteType.MadToTheBone, 'emote-mad-to-the-bone')
EmoteIcons.set(EmoteType.NervousRanger, 'emote-nervous-ranger')
EmoteIcons.set(EmoteType.Neutral, 'emote-neutral')
EmoteIcons.set(EmoteType.PalmExcited, 'emote-palm-excited')
EmoteIcons.set(EmoteType.RaccoonLove, 'emote-raccoon-love')
EmoteIcons.set(EmoteType.RageKnight, 'emote-rage-knight')
EmoteIcons.set(EmoteType.RottweilerPopcorn, 'emote-rottweiler-popcorn')
EmoteIcons.set(EmoteType.Sad, 'emote-sad')
EmoteIcons.set(EmoteType.Scared, 'emote-scared')
EmoteIcons.set(EmoteType.SoSugoiSorceress, 'emote-so-sugoi-sorceress')
EmoteIcons.set(EmoteType.SquintElf, 'emote-squint-elf')
EmoteIcons.set(EmoteType.Surprised, 'emote-surprised')
EmoteIcons.set(EmoteType.SusWizard, 'emote-sus-wizard')
EmoteIcons.set(EmoteType.ThisIsFineDwarf, 'emote-this-is-fine-dwarf')
EmoteIcons.set(EmoteType.Thumbsup, 'emote-thumbsup')
EmoteIcons.set(EmoteType.Easter_DissapointBun, 'emote-easter-disappoint-bun')
EmoteIcons.set(EmoteType.Easter_LaughBun, 'emote-easter-laugh-bun')
EmoteIcons.set(EmoteType.Easter_LoveBun, 'emote-easter-love-bun')
EmoteIcons.set(EmoteType.Easter_MuscleEgg, 'emote-easter-muscle-egg')
EmoteIcons.set(EmoteType.Easter_SusEgg, 'emote-easter-sus-egg')
EmoteIcons.set(EmoteType.Easter_WinkChick, 'emote-easter-wink-chick')
export {EmoteIcons}

const EmoteAssets = new Map<EmoteType, string>()
EmoteAssets.set(EmoteType.Angry, 'emote-angry')
EmoteAssets.set(EmoteType.AyyRanger, 'emote-ayy-ranger')
EmoteAssets.set(EmoteType.CardinalCry, 'emote-cardinal-cry')
EmoteAssets.set(EmoteType.ChaosCrab, 'emote-chaos-crab')
EmoteAssets.set(EmoteType.CorgiThink, 'emote-corgi-think')
EmoteAssets.set(EmoteType.Cry, 'emote-cry')
EmoteAssets.set(EmoteType.Excited, 'emote-excited')
EmoteAssets.set(EmoteType.Happy, 'emote-happy')
EmoteAssets.set(EmoteType.IGetThatBubba, 'emote-i-get-that-bubba')
EmoteAssets.set(EmoteType.Love, 'emote-love')
EmoteAssets.set(EmoteType.MadToTheBone, 'emote-mad-to-the-bone')
EmoteAssets.set(EmoteType.NervousRanger, 'emote-nervous-ranger')
EmoteAssets.set(EmoteType.Neutral, 'emote-neutral')
EmoteAssets.set(EmoteType.PalmExcited, 'emote-palm-excited')
EmoteAssets.set(EmoteType.RaccoonLove, 'emote-raccoon-love')
EmoteAssets.set(EmoteType.RageKnight, 'emote-rage-knight')
EmoteAssets.set(EmoteType.RottweilerPopcorn, 'emote-rottweiler-popcorn')
EmoteAssets.set(EmoteType.Sad, 'emote-sad')
EmoteAssets.set(EmoteType.Scared, 'emote-scared')
EmoteAssets.set(EmoteType.SoSugoiSorceress, 'emote-so-sugoi-sorceress')
EmoteAssets.set(EmoteType.SquintElf, 'emote-squint-elf')
EmoteAssets.set(EmoteType.Surprised, 'emote-surprised')
EmoteAssets.set(EmoteType.SusWizard, 'emote-sus-wizard')
EmoteAssets.set(EmoteType.ThisIsFineDwarf, 'emote-this-is-fine-dwarf')
EmoteAssets.set(EmoteType.Thumbsup, 'emote-thumbsup')
EmoteAssets.set(EmoteType.Easter_DissapointBun, 'easter-dissapoint-bun')
EmoteAssets.set(EmoteType.Easter_LaughBun, 'easter-laugh-bun')
EmoteAssets.set(EmoteType.Easter_LoveBun, 'easter-love-bun')
EmoteAssets.set(EmoteType.Easter_MuscleEgg, 'easter-muscle-egg')
EmoteAssets.set(EmoteType.Easter_SusEgg, 'easter-sus-egg')
EmoteAssets.set(EmoteType.Easter_WinkChick, 'easter-wink-chick')
export {EmoteAssets}

const EmotePrettyNames = new Map<EmoteType, string>()
EmotePrettyNames.set(EmoteType.Angry, 'Angry')
EmotePrettyNames.set(EmoteType.AyyRanger, 'Ayy Ranger')
EmotePrettyNames.set(EmoteType.CardinalCry, 'Cry Cardinal')
EmotePrettyNames.set(EmoteType.ChaosCrab, 'Chaos Crab')
EmotePrettyNames.set(EmoteType.CorgiThink, 'Think Corgi')
EmotePrettyNames.set(EmoteType.Cry, 'Cry')
EmotePrettyNames.set(EmoteType.Excited, 'Excited')
EmotePrettyNames.set(EmoteType.Happy, 'Happy')
EmotePrettyNames.set(EmoteType.IGetThatBubba, 'I Get That Bubba')
EmotePrettyNames.set(EmoteType.Love, 'Love')
EmotePrettyNames.set(EmoteType.MadToTheBone, 'Mad to the Bone')
EmotePrettyNames.set(EmoteType.NervousRanger, 'Nervous Ranger')
EmotePrettyNames.set(EmoteType.Neutral, 'Neutral')
EmotePrettyNames.set(EmoteType.PalmExcited, 'Excited Palm')
EmotePrettyNames.set(EmoteType.RaccoonLove, 'Love Raccoon')
EmotePrettyNames.set(EmoteType.RageKnight, 'Rage Knight')
EmotePrettyNames.set(EmoteType.RottweilerPopcorn, 'Popcorn Rottweiler')
EmotePrettyNames.set(EmoteType.Sad, 'Sad')
EmotePrettyNames.set(EmoteType.Scared, 'Scared')
EmotePrettyNames.set(EmoteType.SoSugoiSorceress, 'So Sugoi Sorceress')
EmotePrettyNames.set(EmoteType.SquintElf, 'Squint Elf')
EmotePrettyNames.set(EmoteType.Surprised, 'Surprised')
EmotePrettyNames.set(EmoteType.SusWizard, 'Sus Wizard')
EmotePrettyNames.set(EmoteType.ThisIsFineDwarf, 'This is Fine Dwarf')
EmotePrettyNames.set(EmoteType.Thumbsup, 'Thumbsup')
EmotePrettyNames.set(EmoteType.Easter_DissapointBun, 'Disappoint Bunny')
EmotePrettyNames.set(EmoteType.Easter_LaughBun, 'Laugh Bunny')
EmotePrettyNames.set(EmoteType.Easter_LoveBun, 'Love Bunny')
EmotePrettyNames.set(EmoteType.Easter_MuscleEgg, 'Muscle Egg')
EmotePrettyNames.set(EmoteType.Easter_SusEgg, 'Sus Egg')
EmotePrettyNames.set(EmoteType.Easter_WinkChick, 'Wink Chick')
export {EmotePrettyNames}

const EmoteRarities = new Map<EmoteType, ItemRarity>()
EmoteRarities.set(EmoteType.Angry, ItemRarity.COMMON)
EmoteRarities.set(EmoteType.AyyRanger, ItemRarity.EPIC)
EmoteRarities.set(EmoteType.CardinalCry, ItemRarity.UNCOMMON)
EmoteRarities.set(EmoteType.ChaosCrab, ItemRarity.EPIC)
EmoteRarities.set(EmoteType.CorgiThink, ItemRarity.UNCOMMON)
EmoteRarities.set(EmoteType.Cry, ItemRarity.COMMON)
EmoteRarities.set(EmoteType.Excited, ItemRarity.COMMON)
EmoteRarities.set(EmoteType.Happy, ItemRarity.COMMON)
EmoteRarities.set(EmoteType.IGetThatBubba, ItemRarity.EPIC)
EmoteRarities.set(EmoteType.Love, ItemRarity.COMMON)
EmoteRarities.set(EmoteType.MadToTheBone, ItemRarity.EPIC)
EmoteRarities.set(EmoteType.NervousRanger, ItemRarity.RARE)
EmoteRarities.set(EmoteType.Neutral, ItemRarity.COMMON)
EmoteRarities.set(EmoteType.PalmExcited, ItemRarity.UNCOMMON)
EmoteRarities.set(EmoteType.RaccoonLove, ItemRarity.UNCOMMON)
EmoteRarities.set(EmoteType.RageKnight, ItemRarity.RARE)
EmoteRarities.set(EmoteType.RottweilerPopcorn, ItemRarity.UNCOMMON)
EmoteRarities.set(EmoteType.Sad, ItemRarity.COMMON)
EmoteRarities.set(EmoteType.Scared, ItemRarity.COMMON)
EmoteRarities.set(EmoteType.SoSugoiSorceress, ItemRarity.RARE)
EmoteRarities.set(EmoteType.SquintElf, ItemRarity.RARE)
EmoteRarities.set(EmoteType.Surprised, ItemRarity.COMMON)
EmoteRarities.set(EmoteType.SusWizard, ItemRarity.RARE)
EmoteRarities.set(EmoteType.ThisIsFineDwarf, ItemRarity.EPIC)
EmoteRarities.set(EmoteType.Thumbsup, ItemRarity.COMMON)
EmoteRarities.set(EmoteType.Easter_DissapointBun, ItemRarity.RARE)
EmoteRarities.set(EmoteType.Easter_LaughBun, ItemRarity.RARE)
EmoteRarities.set(EmoteType.Easter_LoveBun, ItemRarity.RARE)
EmoteRarities.set(EmoteType.Easter_MuscleEgg, ItemRarity.RARE)
EmoteRarities.set(EmoteType.Easter_SusEgg, ItemRarity.RARE)
EmoteRarities.set(EmoteType.Easter_WinkChick, ItemRarity.RARE)
export {EmoteRarities}

// Update these when adding Emote bundles to mtx.js in Atlas
const EmoteMtxBundles = new Map<MtxBundleIds, EmoteType[]>()
// Pet Bundle
EmoteMtxBundles.set(MtxBundleIds.PETS,
    [
        EmoteType.RaccoonLove,
        EmoteType.RottweilerPopcorn,
        EmoteType.CardinalCry,
        EmoteType.PalmExcited,
        EmoteType.CorgiThink,
    ]
)

// Special Bundle
EmoteMtxBundles.set(MtxBundleIds.SPECIAL, 
    [
        EmoteType.IGetThatBubba,
        EmoteType.MadToTheBone,
        EmoteType.ThisIsFineDwarf,
        EmoteType.AyyRanger,
        EmoteType.ChaosCrab,
    ]

)
// Special2 Bundle
EmoteMtxBundles.set(MtxBundleIds.SPECIAL2, 
    [
        EmoteType.SoSugoiSorceress,
        EmoteType.SquintElf,
        EmoteType.SusWizard,
        EmoteType.RageKnight,
        EmoteType.NervousRanger,
    ]
)

// Easter Bundle
EmoteMtxBundles.set(MtxBundleIds.EASTER,
    [
        EmoteType.Easter_LaughBun,
        EmoteType.Easter_LoveBun,
        EmoteType.Easter_DissapointBun,
        EmoteType.Easter_SusEgg,
        EmoteType.Easter_MuscleEgg,
        EmoteType.Easter_WinkChick,
    ]
)

export {EmoteMtxBundles}

export class EmotePrizeData implements PrizeData {
	emoteId: EmoteType

	constructor(emoteId: EmoteType) {
        this.emoteId = emoteId
	}
}

function getEnumKeys(enumType) {
	return Object.keys(enumType)
		.map(function(t) {
			return enumType[t]
		})
		.filter((val) => !isNaN(val))
}
