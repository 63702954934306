import { ClientProjectile } from '../../../projectiles/client/projectile.client'
import { MiddlegroundRenderer } from '../../../world/client/middleground-renderer'
import { getProjectileAssetFromEnum, ParticleEffectType, PARTICLE_EFFECT_SCALES } from '../../shared/game-data/particle-config'
import { Effect } from './pfx/effect'
import { IParticleRendererCamera } from './pfx/sprite-particle-renderer'
import { ObjectPool } from '../../../third-party/object-pool'
import { AssetIdentifier, AssetManager } from '../../../asset-manager/client/asset-manager'
import { nengiId } from '../../../utils/primitive-types'
import { GameClient } from '../game-client'
import { ClientPlayer } from '../../../player/client/player.client'
import { ClientEnemy } from '../../../ai/client/enemy.client'
import logger from '../../../utils/client-logger'
import { forEach } from 'lodash'
import { debugConfig } from '../debug-config'

const MAX_EFFECTS = 50
const PROJECTILE_Z_OFFSET = 200

type ProjectileEffect = Effect & { isTrail: boolean }

//TODO2: each effect needs a default size mapping, then scale based on colliderRadius / defaultEffectSize.get(my effect)
const DEFAULT_COLLIDER_RADIUS = 20

export const NONLOCAL_PLAYER_ALPHA = 0.3
const ENEMY_Z_OFFSET = 200
const ENEMY_BATCH_ZINDEX = 131137 // making unique so another object doesn't batch the same

export default class ProjectileEffectManager {
	private projectiles: Map<nengiId, ClientProjectile>
	/** nengiId => [head, trail] */
	private projectileEffects: Map<nengiId, ProjectileEffect[]>
	private effectPools: Map<AssetIdentifier, ObjectPool>
	private effectNameMap: Map<Effect, AssetIdentifier>
	private mgRenderer: MiddlegroundRenderer
	private cameraState: IParticleRendererCamera

	constructor(mgRenderer: MiddlegroundRenderer, cameraState: IParticleRendererCamera) {
		this.projectiles = new Map<number, ClientProjectile>()
		this.effectPools = new Map<string, ObjectPool>()
		this.projectileEffects = new Map<nengiId, ProjectileEffect[]>()
		this.mgRenderer = mgRenderer
		this.cameraState = cameraState
		this.effectNameMap = new Map()

		const that = this
		// document.addEventListener('keydown', (event: KeyboardEvent) => {
		// 	that.printEffectPools()
		// })
	}

	registerProjectile(projectile: ClientProjectile): void {
		this.projectiles.set(projectile.nid, projectile)

		this.projectileEffects.set(projectile.nid, [])

		// NOTE: these need to be in effect->effectTrail order, see zOrder-- below
		const effectIds: number[] = [projectile.particleEffect, projectile.bulletTrailParticleEffect]
		const scale = PARTICLE_EFFECT_SCALES.get(projectile.particleEffect)

		for (let i = 0; i < effectIds.length; i++) {
			const effectId = effectIds[i]
			if (effectId === 0) {
				continue
			}
			const effectName = getProjectileAssetFromEnum(effectId)
			this.getOrCreateEffectPool(effectName, false)
			const pool = this.getEffectPool(effectName)
			const effect: ProjectileEffect = pool.alloc()
			effect.emitters.forEach((e) => {
				e.reset()
				e.ensureParticleEmitted()
			})
			effect.isTrail = i === 1
			//discussed with Ben on Jan 14th, elemental trails look bad scaled
			effect.scale = effect.isTrail ? 1 : scale * (projectile.colliderRadius / DEFAULT_COLLIDER_RADIUS)
			effect.effectId = effectId
			this.projectileEffects.get(projectile.nid).push(effect)
			this.mgRenderer.addEffectToScene(effect)
		}
	}

	unregisterProjectile(nid: number): void {
		if (this.projectiles.has(nid)) {
			this.projectileEffects.get(nid).forEach((e) => {
				if (!e.isTrail) {
					const name = this.effectNameMap.get(e)
					const pool = this.getEffectPool(name)
					pool.free(e)
					this.mgRenderer.removeFromScene(e)
				} else {
					e.enabled = false
					setTimeout(() => {
						e.enabled = true
						const name = this.effectNameMap.get(e)
						const pool = this.getEffectPool(name)
						pool.free(e)
						this.mgRenderer.removeFromScene(e)
					}, 200)
				}
			})
			this.projectiles.delete(nid)
		} else {
			// TODO2: unregisterProjectile is happening twice
		}
	}

	onAssetsLoaded() {
		forEach(initialPfxPools, (size, assetName) => {
			this.getOrCreateEffectPool(assetName, true, size)
		})
	}

	onFileChange(assetName: AssetIdentifier, contents: any) {
		this.effectPools.delete(assetName)
	}

	getEffectPool(effectName: string): ObjectPool {
		const pool = this.effectPools.get(effectName)
		return pool
	}

	createEffectPool(effectName: AssetIdentifier, initialSize: number = MAX_EFFECTS) {
		//console.log(`creating:${effectName} size:${initialSize}`)
		const pfxAsset = AssetManager.getInstance().getAssetByName(effectName)
		const effects = new ObjectPool(
			() => {
				const effect = new Effect(pfxAsset.data, this.cameraState, this.mgRenderer)
				this.effectNameMap.set(effect, effectName)
				return effect
			},
			null,
			initialSize,
			10,
			`effect-${effectName}`,
		)
		this.effectPools.set(effectName, effects)
	}

	update(delta: number) {
		if (this.effectPools.size === 0) {
			return
		}

		const entities = GameClient.getInstance().entities

		const batchEnemyProjectiles = debugConfig.pfx.batchEnemyProjectiles

		this.projectiles.forEach((projectile: ClientProjectile, nid: number) => {
			let isNonlocalPlayer = true
			if (projectile.playerOwned) {
				const owner = entities.get(projectile.owningEntityId) as ClientPlayer | ClientEnemy
				if (owner) {
					isNonlocalPlayer = owner instanceof ClientPlayer && !owner.isLocalPlayer
				}
			} else {
				isNonlocalPlayer = false
			}

			const isBatching = batchEnemyProjectiles && !projectile.playerOwned

			let zOffset = PROJECTILE_Z_OFFSET + (!projectile.playerOwned ? ENEMY_Z_OFFSET : 0)
			// slight hack, but in order to batch, set all the z indices to the same value so
			//  when the render queue sorts them, they're all at the same sort order
			// tails will be 1 sort order earlier (see zOffset-- below)
			if (isBatching) {
				zOffset = ENEMY_BATCH_ZINDEX
			}

			const effects = this.projectileEffects.get(nid)

			// test to lower nonlocal-players projectiles alphas
			const alpha = isNonlocalPlayer && NONLOCAL_PLAYER_ALPHA ? NONLOCAL_PLAYER_ALPHA : 1

			for (let effectIdx = 0; effectIdx < effects.length; effectIdx++) {
				const effect: ProjectileEffect = effects[effectIdx]
				effect.x = projectile.visualPos.x
				effect.y = projectile.visualPos.y
				effect.zIndex = isBatching ? zOffset : effect.y + zOffset
				effect.rot = projectile.aimAngleInRads
				if (!effect.isTrail) {
					const scale = PARTICLE_EFFECT_SCALES.get(effect.effectId)
					effect.scale = scale * (projectile.colliderRadius / DEFAULT_COLLIDER_RADIUS)
				} else {
					//discussed with Ben on Jan 14th, elemental trails look bad scaled
					effect.scale = 1
				}

				effect.emitters.forEach((e) => {
					e.alpha = alpha
				})

				zOffset-- // render tail under head
			}
		})
	}

	// private printEffectPools() {
	// 	let s = ''
	// 	this.effectPools.forEach((pool, assetName) => {
	// 		const size = pool.status.totalAllocated
	// 		const maxSize = size - pool.status.minTotalFreeReached
	// 		const poolSize = Math.ceil(maxSize / 10) * 10
	// 		s += `['${assetName}']: ${poolSize})\n`
	// 	})
	// 	console.log(s)
	// }

	private getOrCreateEffectPool(effect: ParticleEffectType | string, isLoadTime: boolean, initialSize?: number) {
		let pfx

		if (typeof effect === 'string') {
			pfx = effect
		} else {
			pfx = getProjectileAssetFromEnum(effect as ParticleEffectType)
		}

		if (this.effectPools.get(pfx) == null) {
			if (!isLoadTime && debugConfig.pooling.debug) {
				logger.warn(`creating pool not during load time for:${effect}`)
			}
			this.createEffectPool(pfx, initialSize)
		}
	}
}

const initialPfxPools = {
	['projectile-mushie-head']: 30,
	['projectile-mushie-trail']: 30,
	['projectile-asp-head']: 40,
	['projectile-asp-trail']: 50,
	['projectile-fire-medium']: 10,
	['fire-projectile-trail']: 10,
	['projectile-sporekid-head']: 10,
	['ice-projectile-trail']: 10,
	['projectile-distancer-head']: 10,
	['projectile-distancer-trail']: 10,
	['projectile-axe']: 10,
	['physical-projectile-trail']: 30,
	['projectile-thornwolf-head']: 20,
	['poison-projectile-trail']: 60,
	['projectile-skelemage-head']: 40,
	['projectile-skelemage-trail']: 30,
	['projectile-strafer-head']: 10,
	['projectile-strafer-trail']: 10,
	['projectile-wisp-head']: 40,
	['lightning-projectile-trail']: 20,
	['projectile-shamblingmound-head']: 30,
	['projectile-shoot-physical']: 10,
	['projectile-deathdrake-head']: 60,
	['projectile-bonespirit-head']: 10,
	['projectile-bonespirit-trail']: 10,
	['projectile-long-spell-small']: 20,
	['projectile-boss-head']: 10,
	['projectile-blimpie-gas']: 10,
	['projectile-skeletalwhelp-head']: 10,
	['projectile-beachcrabboss-head']: 10,
	['projectile-beachskelemageboss-head']: 40,
	['projectile-forestboss-head']: 40,
	['projectile-forestboss-shotspray']: 20,
	['projectile-boss-highlands-shard']: 10,
	['projectile-wisp-trail']: 40,
	['boss-prism-wind']: 10,
	['projectile-prism-head']: 20,
	['projectile-prism-trail']: 20,
	['scythe-projectile-02']: 10,
}
