import { BLEND_MODES, LoaderResource } from 'pixi.js'
import { AssetManager } from '../asset-manager/client/asset-manager'
import { addDebugModel } from '../debug/client/debug-client'
import { debugConfig } from '../engine/client/debug-config'
import { InstancedSprite } from '../world/client/instanced-sprite'
import { getBiomeFromPropId, getPropConfig } from '../world/shared/prop-configs'
import logger from './client-logger'
import { degToRad } from './math'

export function getSpineTexture(asset: LoaderResource): PIXI.Texture {
	const spineData: PIXI.spine.core.SkeletonData = asset.spineData
	const spineAtlas: PIXI.spine.core.TextureAtlas = asset.spineAtlas
	for (let i = 0; i < spineAtlas.regions.length; i++) {
		const region = spineAtlas.regions[i]
		if (region) {
			return region.texture
		}
	}
}

export const createInstancedSprites = function(asset: LoaderResource, px: number, py: number, zeroHeights: string[], attachmentZOffset: number[], disableFileOrdering: boolean, disableSubPropZOffs, skin?: string): InstancedSprite[] {
	const instancedSprites = []
	
	const spineData: PIXI.spine.core.SkeletonData = asset.spineData
	const spineAtlas: PIXI.spine.core.TextureAtlas = asset.spineAtlas
	
	let lastZIndex = 0

	if(skin.length > 0) {
		const foundSkin = spineData.skins.find((s) => s.name === skin)
		if(foundSkin) {
			for(const attachmentIndex in foundSkin.attachments) {
				if(foundSkin.attachments.hasOwnProperty(attachmentIndex)) {
					const originalSlot = spineData.slots[attachmentIndex]
					const attachName = originalSlot.attachmentName
					const attachment = foundSkin.attachments[attachmentIndex][attachName] as any
					const region = attachment.region
					const bone = originalSlot.boneData
					const { instancedSprite, usedZOffSet, zIndex } = makeInstancedSpriteFromRegion(attachment, originalSlot, region, px, py, zeroHeights, attachmentZOffset, Number.parseInt(attachmentIndex), disableFileOrdering, lastZIndex, disableSubPropZOffs, asset.name)
					instancedSprites.push(instancedSprite)
					if(!usedZOffSet) {
						lastZIndex = zIndex
					}
				}
			}

			// return instancedSprites
		}
	}
	
	// Not sure if this is the case for _every_ prop / skin setup
	// but for the one I have, only using the above loop / sprites results in only rendering stuff that is _only_ visible in that skin
	// e.g. stuff that should be visible in both default skin and [other] skin gets left out

	for (let i = 0; i < spineData.slots.length; i++) {
		const slot = spineData.slots[i]
		const region = spineAtlas.findRegion(slot.attachmentName)
		if (region) {
			const attachment: PIXI.spine.core.RegionAttachment = getAttachment(spineData, slot, skin)
			const { instancedSprite, usedZOffSet, zIndex } = makeInstancedSpriteFromRegion(attachment, slot, region, px, py, zeroHeights, attachmentZOffset, i, disableFileOrdering, lastZIndex, disableSubPropZOffs, asset.name)
			instancedSprite.rot = -degToRad(attachment.rotation)
			instancedSprites.push(instancedSprite)
			if(!usedZOffSet) {
				lastZIndex = zIndex
			}
		}
	}

	return instancedSprites
}

function makeInstancedSpriteFromRegion(attachment: PIXI.spine.core.RegionAttachment, slot: PIXI.spine.core.SlotData, region: PIXI.spine.core.TextureAtlasRegion, px: number, py: number, zeroHeights: string[], attachmentZOffset: number[], slotIndex: number, disableFileOrdering: boolean, lastZIndex: number, disableSubPropZOffs: boolean, name: string) {
	const bone = slot.boneData
	const zeroHeight = zeroHeights?.includes(slot.attachmentName)

	const tex = region.texture
	let x = px
	let y = py //- tex.height / 2 // server assumes bottom center, ISB assumes center
	const attachScaleX = attachment.scaleX ?? 1
	const attachScaleY = attachment.scaleY ?? 1

	const scaleX = attachScaleX * (attachment.width / region.originalWidth)
	const scaleY = attachScaleY * (attachment.height / region.originalHeight)

	if (bone) {
		x += bone.x //* scaleX
		y += -bone.y //* scaleY
	}

	if (attachment) {
		const a = attachment
		const ax = a.x ?? 0
		const ay = a.y ?? 0
		x += ax
		y += -ay
	}

	//clientDebug.drawText(`[${i}] s:${slot.name} a:${attachment.name}`, { x, y }, 0xffffff, true)
	//clientDebug.drawText(`[${i}]`, { x, y }, 0xffffff, true)
	//clientDebug.drawCircle({ x, y }, 10, 0x0000ff, true)

	let zIndex = zeroHeight ? 0 : y + tex.height * 0.5 * scaleY
	let usedZOffSet = false
	if (attachmentZOffset && attachmentZOffset[slotIndex] && !disableSubPropZOffs) {
		usedZOffSet = true
		zIndex += attachmentZOffset[slotIndex]
	} else if (zIndex <= lastZIndex) {
		if (!disableFileOrdering) {
			// slots are rendered in order, ensure that order
			zIndex = lastZIndex + 1
		}
	}

	//zIndex = lastZIndex + 1

	//console.log(` ${attachment.name} zIndex: ${zIndex} py:${y} y:${y}`)

	if (!zIndex) {
		console.error('NaN / Null zIndex! This will cause weird things! \n slot: ' + slot.name + ' asset: ' + name)
	} else if (!attachment.x && debugConfig.debug) {
		console.error('Mesh being used in non-animated prop! The slot will be set to 0,0. slot: ' + slot.name + ' asset: ' + name)
	}

	let blendMode: BLEND_MODES = BLEND_MODES.NORMAL
	switch (slot.blendMode) {
		case 1:
			blendMode = BLEND_MODES.ADD
			break
		case 2:
			blendMode = BLEND_MODES.MULTIPLY
			break
		case 3:
			blendMode = BLEND_MODES.SCREEN
			break
		default:
			blendMode = BLEND_MODES.NORMAL
			break
	}

	const color: number[] = [slot.color.r, slot.color.g, slot.color.b, slot.color.a]

	const instancedSprite = new InstancedSprite(tex, x, y, zIndex, scaleX, scaleY, 0, 0, color, blendMode, attachment.name)
	return { instancedSprite, usedZOffSet, zIndex}
}

export const getAttachment = function(spineData: PIXI.spine.core.SkeletonData, slot: PIXI.spine.core.SlotData, skin?: string): PIXI.spine.core.RegionAttachment {
	const slotIndex = spineData.findSlotIndex(slot.name)
	
	if(skin && skin.length > 0) {
		const foundSkin = spineData.skins.find((s) => s.name === skin) 
		if(foundSkin) {
			const attachment = foundSkin.getAttachment(slotIndex, slot.attachmentName)
			if(attachment) {
				return attachment as PIXI.spine.core.RegionAttachment
			}
		}
	}

	const attachment = spineData.defaultSkin.getAttachment(slotIndex, slot.attachmentName)
	return attachment as PIXI.spine.core.RegionAttachment
}

const getAttachmentNames = function(skeletonData: PIXI.spine.core.SkeletonData) {
	return skeletonData.slots.map((slot) => slot.attachmentName)
}

export const getAttachmentOffsets = function(spineData: PIXI.spine.core.SkeletonData, parentPropId: string) {
	const attachmentNames = getAttachmentNames(spineData)

	const zOffsets = attachmentNames.map((name) => {
		if (!name) {
			throw new Error('Prop ' + parentPropId + ' has a slot/attachment with a null/empty name! Check the slots, look at their attachments in the json')
		} else {
			const biome = getBiomeFromPropId(parentPropId)
			const propConfig = getPropConfig(biome, name)
			return propConfig?.zOffset
		}
	})
	return zOffsets
}

export function getSpineAsset(assetName: string): LoaderResource {
	if (!AssetManager.getInstance().hasAssetByName(assetName)) {
		return null
	}

	const asset = AssetManager.getInstance().getAssetByName(assetName)

	if (!asset) {
		// TODO3: currently we're using the fungi-swamp prop in 2 biomes: beach and fungi
		//  our props are setup so they need to be in each biomes directory, so to do this normally we'd have this in:
		//   ./biomes/beach/props/fungi-boss.json and
		//   ./biomes/fungi/props/fungi-boss.json
		// instead, for now, I'm keeping it in a non-biome directory
		if (assetName.includes('/')) {
			const subAssetName = assetName.split('/')[1]
			return getSpineAsset(subAssetName)
		}

		console.warn(`no asset named ${assetName}`)
		addDebugModel(this, `missing prop:${assetName}\n`, 10, false)
		return
	}

	const spineData: PIXI.spine.core.SkeletonData = asset.spineData
	if (!spineData) {
		// set note above ^
		if (assetName.includes('/')) {
			const subAssetName = assetName.split('/')[1]
			return getSpineAsset(subAssetName)
		}

		logger.error(`no spine data for propId:${assetName}`)
		logger.error(asset)
		return
	}
	return asset
}
