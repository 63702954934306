






import { onPropertyChangedHandler } from './helpers'

export default {
	name: 'BooleanProperty',
	props: {
		rootObject: {
			type: Object,
			required: true,
		},
		propertySeekDotNotation: {
			type: String,
			required: true,
		},
		propertyKey: {
			type: String,
			required: true,
		},
		propertyValue: {
			type: Boolean,
			required: true,
		},
		propertyChangedFn: {
			type: Function,
			default: undefined,
		},
	},
	data() {
		return {
			liveValue: this.propertyValue,
		}
	},
	methods: {
		onChange() {
			onPropertyChangedHandler(this.rootObject, this.propertySeekDotNotation, this.liveValue)
			this.propertyChangedFn(this.rootObject, this.propertySeekDotNotation, this.liveValue)
		},
	},
}
