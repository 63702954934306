import { nonSensitiveEnvVarsMapping } from '../../utils/env'
import logger from '../../utils/client-logger'
import { UI } from '../ui'
import { requestPlayToken, playTokenHeartbeat } from '../../utils/api/polo-requests.client'

const queuePositionToFriendlyString = (positionInQueue: number) => {
	if (positionInQueue === -1) {
		return 'It\'s your turn! Entering game...'
	} else if (positionInQueue === 0) {
		return 'You\'re next in line to join the game, sit tight!'
	} else {
		return `Your position in the queue is #${positionInQueue}, you'll be in soon!`
	}
}

export const loginQueueModule = () => {
	logger.debug('Initializing login queue module')
	return {
		namespaced: true,
		state: {
			enteredQueue: false,
			playToken: null,
			playTokenLastUpdatedAt: null,
			playTokenActive: false,
			queueMessage: 'Determining queue position...',
		},
		getters: {
			currentlyInQueue(state) {
				return state.enteredQueue
			},
			queueMessage(state) {
				return state.queueMessage
			},
		},
		mutations: {},
		actions: {
			async enterQueue({ state }) {
				state.enteredQueue = true
				if (nonSensitiveEnvVarsMapping[process.env.NODE_ENV].USE_MATCHMAKER === true) {
					const playerId = UI.getInstance().store.getters['user/userId']
					const playTokenResult = await requestPlayToken(playerId)
					state.playToken = playTokenResult.tokenId
					state.playTokenLastUpdatedAt = playTokenResult.lastHeartbeatAt
					state.playTokenActive = playTokenResult.active
					UI.getInstance().emitAsyncEvent('loginQueue/playTokenHeartbeat')
				} else {
					UI.getInstance().emitEvent('enterGame', 'hub')
				}
			},
			async playTokenHeartbeat({ state }) {
				const heartbeatResult = await playTokenHeartbeat(state.playToken)
				state.playTokenLastUpdatedAt = heartbeatResult.lastUpdatedAt
				if (state.playTokenActive === false && heartbeatResult.active === true) {
					UI.getInstance().emitEvent('enterGame', 'hub')
					state.playTokenActive = heartbeatResult.active
				} else {
					state.queueMessage = queuePositionToFriendlyString(heartbeatResult.positionInQueue)
				}
				setTimeout(() => {
					UI.getInstance().emitAsyncEvent('loginQueue/playTokenHeartbeat')
				}, 5000)
			},
		},
	}
}
