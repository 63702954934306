































import { mapGetters, mapState } from 'vuex'
import DetailedItemView from '../reusable-components/detailed-item-view/detailed-item-view.vue'
import {playerPanelTabs} from '../state/in-game.ui-state'

export default {
	name: 'ItemComparisonPanel',
	components: {
		DetailedItemView,
	},
	props: {
		removableAugments: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data() {
		return {
			numberSelectedClass: 'none',
			defaultRightPx: 650,
			comparingOffsetPx: 45,
			paperdollLeft: '40px',
			containerLeft: '485px',
		}
	},

	computed: {
		...mapGetters('itemComparison', ['selectedEquippedItem', 'selectedItem', 'selectedItemContainer', 'showEquippedItemComparison', 'showSelectedItemComparison', 'shouldShowComparisonPanel']),
		...mapGetters('itemContainers', ['itemDetails']),
		...mapGetters('UIScale', ['currentScale']),
		...mapGetters('inGame', ['activePanel', 'flyoutPanel']),
		...mapState('inGame', ['playerTab']),
		rightPx() {
			if(this.activePanel === 'augmentationStation' || (this.showSelectedItemComparison && this.showEquippedItemComparison)) {
				return this.defaultRightPx + this.comparingOffsetPx
			}
			return this.defaultRightPx
		},
		getRight() {
			if(this.activePanel === 'playerProfile' && this.playerTab === playerPanelTabs.PAPERDOLL){
				return this.rightPx * this.currentScale + 40
			}
			return this.rightPx * this.currentScale
		},
	},
	beforeUpdate() {
		let numberSelectedClass = 'none'
		let paperdollLeft = '40px'
		let containerLeft = '485px'
		const paperdollItemSelectedAndValidForFullPreview = this.showEquippedItemComparison
		const containerItemSelectedAndValidForFullPreview = this.showSelectedItemComparison
		if ((paperdollItemSelectedAndValidForFullPreview && containerItemSelectedAndValidForFullPreview) || this.activePanel === 'augmentationStation') {
			numberSelectedClass = 'both'
			paperdollLeft = '40px'
			containerLeft = '485px'
		} else if (paperdollItemSelectedAndValidForFullPreview) {
			numberSelectedClass = 'one'
			paperdollLeft = '40px'
			containerLeft = '485px'
		} else if (containerItemSelectedAndValidForFullPreview) {
			numberSelectedClass = 'one'
			containerLeft = '40px'
		}

		this.$data.paperdollLeft = this.$data.containerLeft = this.$data.numberSelectedClass = numberSelectedClass
	},

	methods: {},
}
