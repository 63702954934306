<template>
	<div id="icons" class="icons">
		<div v-if="iconName === 'dps'" class="current-dps">
			<div :class="{ small: !largeIcons }" class="background-dps">
				<div :class="{ small: !largeIcons }" class="container-dps"></div>
				<div :class="{ small: !largeIcons }" v-if="textOverlay" class="dps loadout-text">{{ to3DigitTruncatedString(parseFloat(textOverlay)) }}</div>
			</div>
		</div>
		<div v-else-if="iconName === 'currentLevel'" class="current-level">
			<div :class="{ small: !largeIcons }" class="background-current-level">
				<div :class="{ small: !largeIcons }" class="container-current-level"></div>
				<div :class="{ small: !largeIcons }" v-if="textOverlay" class="current loadout-text">{{ textOverlay }}</div>
			</div>
		</div>
		<div v-else-if="iconName === 'recommendedLevel'" class="recommended-level">
			<div :class="{ small: !largeIcons }" class="background-recommended-level">
				<div :class="{ small: !largeIcons }" class="container-recommended-level"></div>
				<div :class="{ small: !largeIcons }" class="recommended loadout-text">{{ textOverlay }}</div>
			</div>
		</div>
		<div v-else-if="iconName === 'brokenRecommendedLevel'" class="recommended-level">
			<div :class="{ small: !largeIcons }" class="background-recommended-level">
				<div :class="{ small: !largeIcons }" class="container-recommended-level-broken"></div>
				<div :class="{ small: !largeIcons }" class="recommended loadout-text">{{ textOverlay }}</div>
			</div>
		</div>
		<div v-else-if="iconName === 'soul'" class="soul">
			<div :class="{ small: !largeIcons }" class="background-soul">
				<div :class="{ small: !largeIcons }" class="container-soul"></div>
				<div :class="{ small: !largeIcons }" class="soul-text loadout-text">{{ textOverlay }}</div>
			</div>
		</div>
		<div v-else-if="iconName === 'brokenSoul'" class="soul">
			<div :class="{ small: !largeIcons }" class="background-soul">
				<div :class="{ small: !largeIcons }" class="container-broken-soul"></div>
				<div :class="{ small: !largeIcons }" class="broken-soul loadout-text">{{ textOverlay }}</div>
			</div>
		</div>
		<div v-else class="world-tier">
			<div :class="{ small: !largeIcons }" class="background-square">
				<img :class="{ small: !largeIcons }" :src="getWorldTierImageIndex" class="world-tier-imgs" />
			</div>
		</div>
	</div>
</template>

<script>
const VALID_ICON_NAMES = ['default', 'dps', 'recommendedLevel', 'brokenRecommendedLevel', 'currentLevel', 'soul', 'brokenSoul', 'worldTier1Image', 'worldTier2Image', 'worldTier3Image', 'worldTier4Image', 'worldTier5Image', 'worldTier6Image', 'worldTier7Image', 'worldTier8Image', 'worldTier9Image', 'worldTier10Image']
import worldTier1Image from '../../panels/assets/world-tier-1.png'
import worldTier2Image from '../../panels/assets/world-tier-2.png'
import worldTier3Image from '../../panels/assets/world-tier-3.png'
import worldTier4Image from '../../panels/assets/world-tier-4.png'
import worldTier5Image from '../../panels/assets/world-tier-5.png'
import worldTier6Image from '../../panels/assets/world-tier-6.png'
import worldTier7Image from '../../panels/assets/world-tier-7.png'
import worldTier8Image from '../../panels/assets/world-tier-8.png'
import worldTier9Image from '../../panels/assets/world-tier-9.png'
import worldTier10Image from '../../panels/assets/world-tier-10.png'
import { to3DigitTruncatedString } from '../../../utils/math'

export default {
	name: 'PlayerStatIcons',
	components: {},
	data() {
		return {
			worldTierImages: {
				"worldTier1Image": {imgUrl: worldTier1Image},
				"worldTier2Image": {imgUrl: worldTier2Image},
				"worldTier3Image": {imgUrl: worldTier3Image},
				"worldTier4Image": {imgUrl: worldTier4Image},
				"worldTier5Image": {imgUrl: worldTier5Image},
				"worldTier6Image": {imgUrl: worldTier6Image},
				"worldTier7Image": {imgUrl: worldTier7Image},
				"worldTier8Image": {imgUrl: worldTier8Image},
				"worldTier9Image": {imgUrl: worldTier9Image},
				"worldTier10Image": {imgUrl: worldTier10Image},
			},
		}
	},
	props: {
		largeIcons: {
			type: Boolean,
			required: false,
			default: true,
		},
		iconName: {
			type: String,
			required: false,
			default: 'default',
			validator: function(value) {
				return VALID_ICON_NAMES.includes(value)
			},
		},
		textOverlay: {
			type: String,
			required: false,
			default: null,
		},
		scale: {
			type: Number,
			required: false,
			default: 1,
		},
	},
	computed: {
		getWorldTierImageIndex() {
			return this.worldTierImages[this.iconName].imgUrl
		},
	},
	methods: {
		to3DigitTruncatedString,
	},
}
</script>

<style lang="less" scoped>
@import url('../../global/fonts/text.less');
@import url('../../global/bg-gradients.less');

@scale: 0.69;
.icons {
	.current-dps {
		.background-dps {
			position: relative;
			background-image: url('../../panels/assets/dps-icon-container.png');
			width: 113px;
			height: 122px;
			background-size: cover;
			&.small {
				width: calc(113px * @scale);
				height: calc(122px * @scale);
			}
			.container-dps {
				right: -10px;
				top: 3px;
				position: absolute;
				background-image: url('../../panels/assets/dps-icon.png');
				width: 136px;
				height: 117px;
				background-size: cover;
				&.small {
					width: calc(136px * @scale);
					height: calc(117px * @scale);
				}
			}
			.dps {
				width: 113px;
				height: 122px;
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				margin-top: 5px;
				.difficulty-loadout-dps-text();
				&.small {
					font-size: 24px;
					width: calc(113px * @scale);
					height: calc(122px * @scale);
				}
			}
		}
	}
	.current-level {
		.background-current-level {
			position: relative;
			background-image: url('../../panels/assets/player-level-icon-container.png');
			width: 174px;
			height: 122px;
			background-size: cover;
			&.small {
				width: calc(174px * @scale);
				height: calc(122px * @scale);
			}
			.container-current-level {
				right: 4px;
				top: 3px;
				position: absolute;
				background-image: url('../../panels/assets/player-level-icon.png');
				width: 166px;
				height: 126px;
				background-size: cover;
				&.small {
					width: calc(166px * @scale);
					height: calc(126px * @scale);
				}
			}
			.current {
				width: 174px;
				height: 122px;
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				margin-top: 5px;
				.difficulty-loadout-current-lvl-text();
				&.small {
					font-size: 24px;
					width: calc(174px * @scale);
					height: calc(122px * @scale);
				}
			}
		}
	}
	.recommended-level {
		.background-recommended-level {
			position: relative;
			background-image: url('../../panels/assets/player-level-icon-container.png');
			width: 174px;
			height: 122px;
			background-size: cover;
			&.small {
				width: calc(174px * @scale);
				height: calc(122px * @scale);
			}
			.container-recommended-level {
				right: 4px;
				top: -1px;
				position: absolute;
				background-image: url('../../panels/assets/player-level-icon-unlocked.png');
				width: 166px;
				height: 126px;
				background-size: cover;
				&.small {
					width: calc(166px * @scale);
					height: calc(126px * @scale);
				}
			}
			.recommended {
				width: 174px;
				height: 122px;
				position: absolute;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 5px;
				.difficulty-loadout-recommended-lvl-text();
				&.small {
					font-size: 24px;
					width: calc(174px * @scale);
					height: calc(122px * @scale);
				}
			}
			.container-recommended-level-broken {
				right: 4px;
				top: -7px;
				position: absolute;
				background-image: url('../../panels/assets/player-level-icon-unlocked-broken.png');
				width: 166px;
				height: 126px;
				background-size: cover;
				&.small {
					width: calc(166px * @scale);
					height: calc(126px * @scale);
				}
			}
		}
	}
	.soul {
		.background-soul {
			position: relative;
			background-image: url('../../panels/assets/soul-cycle-icon-container.png');
			width: 145px;
			height: 145px;
			background-size: cover;
			&.small {
				font-size: 24px;
				width: calc(145px * @scale);
				height: calc(145px * @scale);
			}

			.container-soul {
				right: 0px;
				top: 0px;
				position: absolute;
				background-image: url('../../panels/assets/soul-cycle-icon.png');
				width: 145px;
				height: 145px;
				background-size: cover;
				&.small {
					font-size: 24px;
					width: calc(145px * @scale);
					height: calc(145px * @scale);
				}
			}
			.container-broken-soul {
				right: 0px;
				top: 0px;
				position: absolute;
				background-image: url('../../panels/assets/soul-cycle-icon-broken.png');
				width: 145px;
				height: 145px;
				background-size: cover;
				&.small {
					font-size: 24px;
					width: calc(145px * @scale);
					height: calc(145px * @scale);
				}
			}
			.soul-text {
				width: 145px;
				height: 145px;
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				margin-top: 5px;
				.difficulty-soul-cycle-text();
				&.small {
					font-size: 24px;
					width: calc(145px * @scale);
					height: calc(145px * @scale);
				}
			}
		}
	}

	.world-tier {
		.background-square {
			position: relative;
			width: 135px;
			height: 130px;
			background-image: url('../../reusable-components/inventory-item/assets/item-slot-a.png');
			background-size: cover;
			&.small {
				font-size: 24px;
				width: calc(135px * (@scale + .1));
				height: calc(130px *  (@scale + .1));
			}
			.world-tier-imgs {
				width: 140px;
				height: 135px;
				position: absolute;
				right: 0px;
				top: -3px;
				&.small {
					width: calc(140px * (@scale + .1)) !important;
					height: calc(135px * (@scale + .1)) !important;
				}
			}
		}
	}
}
</style>
