/**
 * Finds first gap in array or incrementing numbers
 * @param array array of generally incrementing numbers, (ie [0,1,2,4,5])
 * @returns array index of 1st gap in count discovered (ie: 3 in above example), -1 if no gaps
 */
export function findGap(array: number[]) {
	for (let i = 0; i < array.length; i++) {
		if (array[i] > i) {
			return i
		}
	}
	return -1
}
