export const enum Environments {
	LOCAL = 'local',
	ALPHA = 'alpha',
	QA = 'qa',
	LOOTPROD = 'loot-prod',
	BETA = 'beta',
	STAGING = 'staging',
}

type NonSensitiveEnvVarMapping = {
	[environment in Environments]: {
		ITEM_SCHEMA_VERSION: string
		LOG_LEVEL: string
		ATLAS_API_ADDRESS: string
		PLUTUS_API_ADDRESS: string
		POLO_ADDRESS: string
		USE_MATCHMAKER: boolean
		GAME_ANALYTICS_GAME_API_KEY: string
		GAME_URL: string
	}
}

export const nonSensitiveEnvVarsMapping: NonSensitiveEnvVarMapping = {
	[Environments.LOCAL]: {
		ITEM_SCHEMA_VERSION: 'local-v1.1.2',
		LOG_LEVEL: 'debug',
		ATLAS_API_ADDRESS: 'https://alpha-atlas.bitfox.dev',
		// ATLAS_API_ADDRESS: 'http://localhost:10000',
		PLUTUS_API_ADDRESS: 'https://alpha-plutus.bitfox.dev',
		// PLUTUS_API_ADDRESS: 'http://localhost:10000',
		POLO_ADDRESS: 'localhost',
		USE_MATCHMAKER: false,
		GAME_ANALYTICS_GAME_API_KEY: 'f11af7d3005223bed7ce464d97b36a5f',
		GAME_URL: 'http://localhost:1234',
	},
	[Environments.ALPHA]: {
		ITEM_SCHEMA_VERSION: 'v1.1.2',
		LOG_LEVEL: 'debug',
		ATLAS_API_ADDRESS: 'https://alpha-atlas.bitfox.dev',
		PLUTUS_API_ADDRESS: 'https://alpha-plutus.bitfox.dev',
		POLO_ADDRESS: 'alpha-polo.bitfox.dev',
		USE_MATCHMAKER: true,
		GAME_ANALYTICS_GAME_API_KEY: 'f11af7d3005223bed7ce464d97b36a5f',
		GAME_URL: 'http://dev.loot.io',
	},
	[Environments.QA]: {
		ITEM_SCHEMA_VERSION: 'v1.1.2',
		LOG_LEVEL: 'debug',
		ATLAS_API_ADDRESS: 'https://qa-atlas.bitfox.dev',
		PLUTUS_API_ADDRESS: 'https://qa-plutus.bitfox.dev',
		POLO_ADDRESS: 'qa-polo.bitfox.dev',
		USE_MATCHMAKER: true,
		GAME_ANALYTICS_GAME_API_KEY: 'f11af7d3005223bed7ce464d97b36a5f',
		GAME_URL: 'http://qa.loot.io',
	},
	[Environments.LOOTPROD]: {
		ITEM_SCHEMA_VERSION: 'v1.1.2',
		LOG_LEVEL: 'info',
		ATLAS_API_ADDRESS: 'https://prod-atlas.bitfox.dev',
		PLUTUS_API_ADDRESS: 'https://prod-plutus.bitfox.dev',
		POLO_ADDRESS: 'prod-polo.bitfox.dev',
		USE_MATCHMAKER: true,
		GAME_ANALYTICS_GAME_API_KEY: '6b28914f5ced3a1d450be42ef28910fd',
		GAME_URL: 'http://play.loot.io',
	},
	[Environments.BETA]: {
		ITEM_SCHEMA_VERSION: 'v1.1.2',
		LOG_LEVEL: 'debug',
		ATLAS_API_ADDRESS: 'https://beta-atlas.bitfox.dev',
		PLUTUS_API_ADDRESS: 'https://beta-plutus.bitfox.dev',
		POLO_ADDRESS: 'beta-polo.bitfox.dev',
		USE_MATCHMAKER: true,
		GAME_ANALYTICS_GAME_API_KEY: 'd61f26a2045a58bab195eba055d12f4c',
		GAME_URL: 'http://beta.loot.io',
	},
	[Environments.STAGING]: {
		ITEM_SCHEMA_VERSION: 'v1.1.2',
		LOG_LEVEL: 'debug',
		ATLAS_API_ADDRESS: 'https://staging-atlas.bitfox.dev',
		PLUTUS_API_ADDRESS: 'https://staging-plutus.bitfox.dev',
		POLO_ADDRESS: 'staging-polo.bitfox.dev',
		USE_MATCHMAKER: true,
		GAME_ANALYTICS_GAME_API_KEY: 'f11af7d3005223bed7ce464d97b36a5f',
		GAME_URL: 'http://staging.loot.io',
	},
}
