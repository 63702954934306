














































































import { mapActions, mapMutations, mapState } from 'vuex'
import ArrowButton from '../reusable-components/buttons/arrow-button.vue'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import TextInput from '../reusable-components/inputs/text-input.vue'

export default {
	name: 'ResetPassword',
	components: {
		MainPanel,
		ArrowButton,
		TextInput,
	},
	data() {
		return {
			formError: false,
			password: '',
			confirmPassword: '',
		}
	},
	computed: {
		...mapState('mainMenu', ['passwordWasReset', 'sendingSetPassword', 'resetPasswordError']),
	},
	methods: {
		...mapActions('mainMenu', ['sendResetPassword']),
		...mapMutations('mainMenu', ['changeMainMenuScreen', 'resetPasswordBools']),
		reloadPage() {
			window.location.replace(location.pathname)
		},
	},
}
