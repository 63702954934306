import nengi from 'nengi'

type registrationError =
	| 'missingRequiredFields'
	| 'invalidUsernameLength'
	| 'passwordAndPasswordConfirmationDontMatch'
	| 'emailAndEmailConfirmationDontMatch'
	| 'mustAgreeToTermsOfService'
	| 'invalidEmailFormat'
	| 'emailAddressAlreadyTaken'
	| 'usernameAlreadyTaken'
	| 'emailDoesntSeemReal'
	| 'noUserReturnedAfterCreation'
	| 'itemPersistenceFailure'
	| 'invalidPasswordLength'
	| ''

export class AnonymousRegistrationResponseMessage {
	static protocol = {
		status: nengi.String,
		errorCode: nengi.String,
		authToken: nengi.String,
		userId: nengi.String,
		userName: nengi.String,
	}

	status: 'ok' | 'error'
	errorCode: registrationError
	authToken: string
	userId: string
	userName: string

	constructor(params: AnonymousRegistrationResponseMessage) {
		Object.assign(this, params)
	}
}
