





































































































































import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import ArrowButton from '../reusable-components/buttons/arrow-button.vue'
import PanelDivider from '../reusable-components/panel-divider/panel-divider.vue'
import TextButton from '../reusable-components/buttons/text-button.vue'
import { POIType } from '../../world/shared/poi-data-types'

export default {
	name: 'BiomeKey',
	components: {
		MainPanel,
		ArrowButton,
		PanelDivider,
		TextButton,
	},
	data() {
		return {}
	},
	props: {},
	computed: {
		...mapGetters('itemContainers', ['numberOfBiomeKeysInStashByBiomeIndex']),
		...mapState('outpostWormMail', ['wormsSentFrom']),
		...mapState('hud', ['currentBiome', 'currentMaxBiome']),
		...mapState('boatLaunch', ['biomeKey']),
		...mapGetters('hud', ['getPoiType']),
	},
	methods: {
		...mapActions('inGame', ['closeActivePanel']),
		...mapActions('boatLaunch', ['departToBiome']),
		...mapMutations('boatLaunch', ['updateBiomeKey']),
		...mapActions('endRun', ['endRun']),
		triggerEndRun() {
			this.endRun()
		},
		isWormUsed(biomeIndex) {
			return this.wormsSentFrom.some((biome) => biome.biomeIdx === biomeIndex)
		},
		disableBaseOnCurrentBiome(biomeIndex) {
			if (this.currentBiome === biomeIndex && this.getPoiType !== POIType.WorldEdge) return true
			return false
		},
		disableBaseOnMaxBiome(biomeIndex) {
			if (this.currentMaxBiome < biomeIndex) return true
			return false
		},
		buttonIcon(idx) {
			if (this.disableBaseOnMaxBiome(idx)) {
				return 'lock'
			}
			if (this.disableBaseOnCurrentBiome(idx)) {
				return 'hub'
			}
			return 'key'
		},
		selectedKey(key){
			if(this.biomeKey === key) return true

			return false
		}
	},
}
