import nengi from 'nengi'

class TryShowTutorialMessage {
	static protocol = {
		index: nengi.Int10,
	}

	index: number

	constructor(index: number) {
		this.index = index
	}
}

export default TryShowTutorialMessage
