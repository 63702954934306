















import { mapGetters } from 'vuex'
import { highResolutionTimestamp } from '../../utils/debug'
import GearSkill from '../reusable-components/inventory-item/gear-skill.vue'

export default {
	name: 'GearAbilities',
	components: {
		GearSkill,
	},
	computed: {
		...mapGetters('itemContainers', ['equippedGear']),
		...mapGetters('hud', ['gearSkillCooldownsBySlot', 'gearSkillTypesBySlot']),
		...mapGetters('time', ['sharedHighResolutionTimestamp']),
	},
	methods: {
		slotIsOnCooldown(slot) {
			return (this.gearSkillCooldownsBySlot[slot].cooldownStartTime + this.gearSkillCooldownsBySlot[slot].cooldownDuration) > this.sharedHighResolutionTimestamp
		},
		getSlotCooldownPercentStyleObject(slot) {
			const percent = Math.clamp(1 - (this.sharedHighResolutionTimestamp - this.gearSkillCooldownsBySlot[slot].cooldownStartTime) / this.gearSkillCooldownsBySlot[slot].cooldownDuration, 0, 1)
			return {
				"box-shadow": `inset 0px -${Math.floor(percent * 55)}px 0px rgba(27, 165, 154, 0.8) !important`,
			}
		},
	},
}
