import nengi from 'nengi'

export enum CommandOp {
	Update,
	Append,
	Print,
}

class UpdateDebugOnloadCommandsMessage {
	static protocol = {
		commandString: nengi.String,
		commandOp: nengi.Int4,
	}

	commandString: string
	commandOp: CommandOp

	constructor(commandString: string, commandOp: CommandOp) {
		this.commandString = commandString
		this.commandOp = commandOp
	}
}

export default UpdateDebugOnloadCommandsMessage
