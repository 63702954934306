import axios from 'axios'
import { WorldDifficulty } from '../../engine/shared/game-data/world-difficulty'
import { GameModeType } from '../../engine/shared/game-mode-type'
import { nonSensitiveEnvVarsMapping } from '../env'
import { uuid } from '../primitive-types'

enum BitfoxUserType {
	REGISTERED = 'registered',
	ANONYMOUS = 'anonymous',
}

type BitfoxAuthToken = string

type BitfoxUserIdentifier = uuid | BitfoxAuthToken

const getPoloBaseAddress = () => {
	const baseAddress = `${process.env.NODE_ENV === 'local' ? 'http' : 'https'}://${nonSensitiveEnvVarsMapping[process.env.NODE_ENV].POLO_ADDRESS}${process.env.NODE_ENV === 'local' ? ':10000' : ''}`
	return baseAddress
}

export const requestPlayToken = async (playerId: uuid) => {
	const poloResult = await axios.post(`${getPoloBaseAddress()}/request-play-token`, { playerId })
	return poloResult.data
}
export const playTokenHeartbeat = async (playToken: uuid) => {
	const poloResult = await axios.get(`${getPoloBaseAddress()}/play-token-heartbeat?playToken=${playToken}`)
	return poloResult.data
}

//export const findGameServer = async (gameMode: GameModeType, userType: BitfoxUserType, userIdentifier: BitfoxUserIdentifier, worldDifficulty: WorldDifficulty, partyId: string, predeterminedGameAddress: string = '') => {
export const findGameServer = async (gameMode: GameModeType, userType: BitfoxUserType, userIdentifier: BitfoxUserIdentifier, worldDifficulty: WorldDifficulty, partyId: string, region: string, predeterminedGameAddress = '') => {
	let serverUrl
	let serverWorldDifficulty

	const headers = {
		[userType === BitfoxUserType.REGISTERED ? 'auth-token' : 'anonymous-user-id']: userIdentifier,
	}

	if (nonSensitiveEnvVarsMapping[process.env.NODE_ENV].USE_MATCHMAKER) {
		if (predeterminedGameAddress === '') {
			try {
				const matchmakerResponse = (
					await axios.post(
						`${getPoloBaseAddress()}/find-game`,
						{
							gameMode,
							worldTier: worldDifficulty, // TODO2: ppd: rename on polo
							partyId,
							region: process.env.NODE_ENV === 'local' ? 'local' : region,
						},
						{
							headers,
						},
					)
				).data
				serverUrl = `${process.env.NODE_ENV === 'local' ? 'ws' : 'wss'}://${matchmakerResponse.dns}${process.env.NODE_ENV === 'local' ? '' : '/1'}`
				serverWorldDifficulty = matchmakerResponse.serverWorldDifficulty
			} catch (e) {
				console.error('Failed to get server from matchmaker')
				throw e
			}
		} else {
			serverUrl = `${process.env.NODE_ENV === 'local' ? 'ws' : 'wss'}://${predeterminedGameAddress}${process.env.NODE_ENV === 'local' ? '' : '/1'}`
			// You will never get a bad difficulty error as a non-leader party member, but the leader getting the error should be enough
			serverWorldDifficulty = worldDifficulty
		}
	} else {
		switch(gameMode) {
			case GameModeType.Hub:
				serverUrl = 'ws://localhost:8000'
				break
			case GameModeType.Adventure:
				serverUrl = 'ws://localhost:8001'
				break
			case GameModeType.TestRealm:
				serverUrl = 'ws://localhost:8002'
				break
		}
		serverWorldDifficulty = worldDifficulty

		// if(window.location.href.includes('localhost')) {
		// 	serverUrl = gameMode === 'hub' ? 'ws://localhost:8000' : 'ws://localhost:8001'
		// } else {
		//replace ip with your public ip
		// 	serverUrl = gameMode === 'hub' ? 'ws://142.167.3.150:8000' : 'ws://142.167.3.150:8001'
		// }
	}

	return {serverUrl, serverWorldDifficulty}
}

export async function joinParty(authToken: string, partyId: string, region: string) {
	if(process.env.NODE_ENV === 'local') {
		region = 'local'
	}

	const poloResult = await axios.post(`${getPoloBaseAddress()}/join-party`, 
		{ partyId, region }, {
		headers: {
			'auth-token': authToken
		}
	})

	return poloResult.data
}

export async function anonymousJoinParty(playerId: string, partyId: string, region: string) {
	if(process.env.NODE_ENV === 'local') {
		region = 'local'
	}
	
	const poloResult = await axios.post(`${getPoloBaseAddress()}/join-party`, 
		{ partyId, region }, {
		headers: {
			'anonymous-user-id': playerId
		}
	})

	return poloResult.data
}
