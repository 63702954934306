import nengi from 'nengi'

class PopUIScreen {
	static protocol = {
		screen: nengi.String,
		npcId: nengi.Number,
		poiId: nengi.Number,
		param: nengi.String,
	}

	screen: string
	npcId: number
	poiId: number
	param: string

	constructor(screen: string, npcId: number, poiId: number, param: string) {
		this.screen = screen
		this.npcId = npcId
		this.poiId = poiId
		this.param = param
	}
}

export default PopUIScreen
