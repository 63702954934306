import { getBiomeCount } from '../../../biome/shared/biome-list'
import { throwIfFalsy } from '../../../utils/math'
import { biomeIndex, level, WorldTier } from '../../../utils/primitive-types'
import { GameModeType } from '../game-mode-type'
import { pressureAdditionalWorldTiersFromLoadout } from './pressure-stat-functions.shared'
import { PressureLoadout, PRESSURE_MENU_UNLOCK_TIERS_DEFEATED } from './pressure-stat-info.shared'
import { getWorldDifficultyPrettyString, WorldDifficulty } from './world-difficulty'

// Keeping this at 6 for now in prod
export const PROGRESSION_MAX_TIER_FOR_BETA = (process.env.NODE_ENV === 'loot-prod' || process.env.NODE_ENV === 'staging') ? 6 : 11

export function isValidWorldTier(worldTier: WorldTier) {
	return worldTier > 0
}

export function throwIfInvalidWorldTier(worldTier: WorldTier) {
	throwIfFalsy(isValidWorldTier(worldTier), `bad WorldTier of ${worldTier}`)
}

export function maxPlayableTier(maxWorldTierCompleted: WorldTier) {
	const maxPlayableTier = Math.min(maxWorldTierCompleted + 1, PROGRESSION_MAX_TIER_FOR_BETA)
	return maxPlayableTier
}

export function getWorldTierFromProgression(userProgression, selectedWorldDifficulty: WorldDifficulty, pressureLoadout: PressureLoadout) {
	const maxWorldTierCompleted: number = userProgression && userProgression.maxWorldTierCompleted ? parseInt(userProgression.maxWorldTierCompleted, 10) : 0
	if (maxWorldTierCompleted < PRESSURE_MENU_UNLOCK_TIERS_DEFEATED) {
		// pre-heat
		return selectedWorldDifficulty as WorldTier
	} else {
		// heat
		const tiersFromLoadout = pressureAdditionalWorldTiersFromLoadout(pressureLoadout)
		const tiers = tiersFromLoadout + PRESSURE_MENU_UNLOCK_TIERS_DEFEATED
		const maxPlayable = maxPlayableTier(maxWorldTierCompleted)
		const tiersClampedToMax = Math.clamp(tiers, maxPlayable - 1, maxPlayable)
		return tiersClampedToMax
	}
}

/**
 * tier 1 = progression levels 0-4
 * tier 2 = progression levels 5-9
 * tier 3 = progression levels 10-14
 * etc.
 * @param progressionLevel
 * @returns worldTier for the given progressionLevel
 */
export function getWorldTierFromProgressionLevel(progressionLevel: level) {
	const biomeCount = getBiomeCount(GameModeType.Adventure)
	const worldTier = Math.floor(progressionLevel / biomeCount) + 1
	return worldTier
}

export function getMaxBiome(progressionLevel: level, activeWorldTier: WorldTier) {
	const biomeCount = getBiomeCount(GameModeType.Adventure)
	const highestAllowedTier = getWorldTierFromProgressionLevel(progressionLevel)

	const furthestBiome = progressionLevel % biomeCount

	let currentMaxBiome = 0

	if (activeWorldTier === highestAllowedTier) {
		currentMaxBiome = furthestBiome
	} else {
		currentMaxBiome = 5
	}
	return currentMaxBiome
}

export function inFurthestBiome(progressionLevel: level, currentBiomeIdx: biomeIndex, activeWorldTier: WorldTier) {
	const biomeCount = getBiomeCount(GameModeType.Adventure)
	const reachedWorldTier = Math.floor(progressionLevel / biomeCount) + 1
	const reachedBiomeIndex = progressionLevel % biomeCount
	if (reachedBiomeIndex === currentBiomeIdx && activeWorldTier === reachedWorldTier) {
		return true
	}
	return false
}

// TODO2: ppd: rename
export function getDifficultyLevelFromWorldTier(worldTier: WorldTier): level {
	const difficultyLevel = (worldTier - 1) * 15
	return difficultyLevel
}

export function maxAllowedWorldDifficultyFromMaxAllowedTier(maxAllowedTier: number) {
	maxAllowedTier -= PRESSURE_MENU_UNLOCK_TIERS_DEFEATED

	if (maxAllowedTier <= 0) {
		return WorldDifficulty.Normal
	}

	// Flagging Brutal out of prod
	if (process.env.NODE_ENV === 'loot-prod') {
		return WorldDifficulty.Hard
	}

	if (maxAllowedTier <= 3) {
		return WorldDifficulty.Hard
	}

	// Max difficulty is brutal currently, nasty not yet implemented
	return WorldDifficulty.Brutal

	// if (maxAllowedTier <= 4) {
	// 	return WorldDifficulty.Brutal
	// }

	// return WorldDifficulty.Nasty
}

export function isOnSufficientDifficultyToProgress(worldDifficulty: WorldDifficulty, worldTier: WorldTier, logging: boolean = false) {
	if (logging) console.log(`--- Checking if difficulty ${getWorldDifficultyPrettyString(worldDifficulty)} (${worldDifficulty}) is sufficient to progress in SC ${worldTier}...`)

	if (worldDifficulty === WorldDifficulty.Normal && worldTier <= 2) {
		if (logging) console.log(`--- Normal is good for SC <= 2, progression enabled!`)
		return true
	}

	if (worldDifficulty === WorldDifficulty.Hard && worldTier <= 7) {
		if (logging) console.log(`--- Hard is good for SC <= 7, progression enabled!`)
		return true
	}

	if (worldDifficulty === WorldDifficulty.Brutal || worldDifficulty === WorldDifficulty.Nasty) {
		if (logging) console.log(`--- Brutal and Nasty are always good, progression enabled!`)
		return true
	}

	if (logging) console.log(`--- Difficulty too low, progression disabled! :(`)
	return false
}
