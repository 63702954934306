











export const validIconNames = ['stash', 'furnace', 'market', 'pit-of-chance', 'delete', 'loading', 'boat', 'key', 'augment', 'timer', 'page', 'add', 'worm', 'login', 'logout', 'party', 'no', 'worm', 'yes', 'copy', 'refresh', 'back', 'forward', 'createaccount', 'hub', 'invite', 'coin', 'scale', 'gift', 'faction-standing', 'leave-faction', 'join-faction', 'wave', 'lock'] 
import { VALID_BUTTON_STYLES } from './button-constants'

export default {
	name: 'IconButton',
	props: {
		iconName: {
			type: String,
			required: true,
			validator: function(value) {
				return validIconNames.includes(value)
			},
		},
		buttonStyle: {
			type: String,
			default: 'default',
			validator: function(value) {
				return VALID_BUTTON_STYLES.includes(value)
			},
		},
		disabled: {
			type: Boolean,
			default: false,
			required: false,
		},
		hovered: {
			type: Boolean,
			default: false,
			required: false,
		},
		onClickProp: {
			type: Function,
			required: true,
			default: () => {
				console.warn('The default on-click handler for the <IconButton /> component has been called. Did you forget to pass an :on-click handler to the component?')
			},
		},
		loading: {
			type: Boolean,
			default: false,
			required: false,
		},
	},
	methods: {
		onClick() {
			if (this.disabled) {
				return
			} else {
				this.onClickProp()
			}
		},
	},
}
