import { BuffIdentifier } from '../../../buffs/shared/buff.shared'
import { ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { ENEMY_DEFAULT_HEALTH } from '../../../engine/shared/game-data/enemy-formulas'
import { ParticleEffectType } from '../../../engine/shared/game-data/particle-config'
import { AnimationTrack } from '../../../models-animations/shared/animation-track'
import { EnemyBipedSkinType, SpineDataName } from '../../../models-animations/shared/spine-config'
import { BurstFireModes, ModType, ModValueType } from '../../../projectiles/shared/projectile-types'
import { Colors } from '../../../utils/colors'
import { degToRad } from '../../../utils/math'
import { attackRatedAbilityList } from '../action-criteria/ability-helpers'
import { ActionCriteriaHelpers, worldDifficultyHardCriterion, worldDifficultyBrutalCriterion } from '../action-criteria/action-criteria-helpers'
import { AttackTypes, DeadBehaviours, EnemyAI, EnemyTargetingStyles, EnemyType, FightingBehaviours, FightingTriggers, IdleBehaviours, LeashingBehaviours, LeashTriggers, ShotLeadPrecision } from '../../shared/ai-types'
import { Ability, AbilitySelectionStyles, AbilityTargets, AbilityTargetSelectionStyle, AbilityType, CriteriaSatisfactionQuantity, CriteriaTarget, CriteriaType } from '../action-types'
import { alwaysTrueCriteria, waitAbility } from './abilities/common-abilities'
import { EnemyDefaults } from './enemy-defaults'
import { Vector } from 'sat'
import { ENEMY_NAMES } from './enemy-names'
import { WorldDifficulty } from '../../../engine/shared/game-data/world-difficulty'
import { deepClone } from '../abilities.test'

const longRangeAbility: Ability = {
	debugName: 'longRangeAbility',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		projectileCount: 1,
		spreadAngle: 0,
		speed: 500,
		colliderRadius: 15,
		lifespanInSeconds: 5,
		modifiers: [{ modType: ModType.STRAIGHT }],
		baseLightningDamage: 50,
		basePhysicalDamage: 25,
		burstCount: 5,
		burstDelay: 0.15,
		burstMode: BurstFireModes.SWEEPING,
		burstAngle: degToRad(75),
		color: Colors.red,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_ASP,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_LIGHTNING_TRAIL,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
	},
	attackOffset: new Vector(0, -185),
	ignoreAngleOnWeaponOffset: true,
}

export const longRangeAbilityBrutal = deepClone(longRangeAbility)
	longRangeAbilityBrutal.projectileConfig.speed = 650

const shortRangeAbility: Ability = {
	debugName: 'shortRangeAbility',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.CLOSEST,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		projectileCount: 3,
		spreadAngle: degToRad(45),
		speed: 600,
		colliderRadius: 10,
		lifespanInSeconds: 0.6,
		modifiers: [{ modType: ModType.ACCELERATION, value: { modValueType: ModValueType.LIFETIME, min: 3, max: -4 } }],
		baseLightningDamage: 40,
		burstCount: 2,
		burstDelay: 0.3,
		burstMode: BurstFireModes.STRAIGHT,
		color: Colors.red,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_ASP,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_LIGHTNING_TRAIL,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_LIGHTNING_SHOOT,
	},
	attackOffset: new Vector(0, -185),
	ignoreAngleOnWeaponOffset: true,
}

export const cooldownEnemyCooldown3000: Ability = {
	debugName: 'cooldown3000',
	abilityType: AbilityType.INSTANTLY_APPLY_EFFECT,
	buffToApply: BuffIdentifier.EnemyCooldown3000,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.MYSELF,
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
}

export const cooldownEnemyCooldown5000: Ability = {
	debugName: 'cooldown5000',
	abilityType: AbilityType.INSTANTLY_APPLY_EFFECT,
	buffToApply: BuffIdentifier.EnemyCooldown5000,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.MYSELF,
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
}

const animationTimes = {
	[AnimationTrack.SHOOT]: 1,
}

const asp: EnemyAI = {
	name: ENEMY_NAMES.ASP,
	type: EnemyType.BASIC,
	appearance: {
		asset: SpineDataName.ASP,
		skin: EnemyBipedSkinType.DEFAULT,
		mixSettings: [
			{ from: AnimationTrack.SHOOT, to: AnimationTrack.SHOOT, duration: 0.2 },
			{ from: AnimationTrack.SHOOT, to: AnimationTrack.IDLE, duration: 0.2 },
		],
	},
	general: {
		enemyLevel: 1,
		experienceWhenKilled: 0.75,
	},
	baseAttributes: {
		...EnemyDefaults.baseAttributes,
		colliders: [
			{
				type: ColliderType.Ellipse,
				rX: 33,
				rY: 50,
				position: [0, -40],
				traits: ColliderTraits.BlockMovementAndProjectile,
			},
		],
		maxHealth: (ENEMY_DEFAULT_HEALTH * 2.75) / 4,
		movementSpeed: 0,
		colliderIsKinematic: true,
	},
	soundEffects: {
		attack: 'Projectile_Zap',
		impact: 'SFX_Impact_Enemy_Mush',
	},
	states: {
		idling: {
			behaviour: IdleBehaviours.STATIONARY,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			transitionToFighting: {
				trigger: FightingTriggers.AGGRESSIVE_PROXIMITY,
				radius: 900,
			},
			aggroOnHit: true,
		},
		fighting: {
			behaviour: FightingBehaviours.CHASE_AND_ATTACK,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			attackType: AttackTypes.PROJECTILE,
			engagementMaxDistance: 1200,
			engagementMinDistance: 0,
			movementMaxDistance: 1200,
			movementMinDistance: 0,
			brain: {
				actions: [
					{
						//  BLOCK/ATTACK
						priority: 0,
						actionCriteria: ActionCriteriaHelpers.generatePlayerIsInMeleeRangeCriteria(350),
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							//abilityOptions: [[1, shortRangeAbility, 30]],
							abilityOptions: [attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, shortRangeAbility, 30)],
						},
					},
					
					{
						// Ranged spray for Brutal, no delay
						priority: 3,
						actionCriteria: {
							satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
							criterias: [
								{
									criteriaTargets: CriteriaTarget.PLAYERS,
									criteriaType: CriteriaType.WITHIN_RANGE,
									criteriaValue: 1200,
									minimumTargetsThatSatisfy: 1,
								},
								worldDifficultyBrutalCriterion,
							],
						},
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SEQUENCE,
							//abilityOptions: [[1, longRangeAbility, 50]],
							abilityOptions: [attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, longRangeAbilityBrutal, 60)],
						},
					},
					{
						// Ranged spray for Hard, with a delay.
						priority: 4,
						actionCriteria: {
							satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
							criterias: [
								{
									criteriaTargets: CriteriaTarget.PLAYERS,
									criteriaType: CriteriaType.WITHIN_RANGE,
									criteriaValue: 1200,
									minimumTargetsThatSatisfy: 1,
								},
								{
									criteriaTargets: CriteriaTarget.SELF,
									criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
									criteriaValue: BuffIdentifier.EnemyCooldown3000,
									minimumTargetsThatSatisfy: 1,
								},
								worldDifficultyHardCriterion,
							],
						},
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SEQUENCE,
							//abilityOptions: [[1, longRangeAbility, 50]],
							abilityOptions: [attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, longRangeAbility, 0), attackRatedAbilityList(1, animationTimes, AnimationTrack.IDLE, cooldownEnemyCooldown3000, 50)],
						},
					},
					{
						// Ranged spray for Normal, with a delay.
						priority: 5,
						actionCriteria: {
							satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
							criterias: [
								{
									criteriaTargets: CriteriaTarget.PLAYERS,
									criteriaType: CriteriaType.WITHIN_RANGE,
									criteriaValue: 1200,
									minimumTargetsThatSatisfy: 1,
								},
								{
									criteriaTargets: CriteriaTarget.SELF,
									criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
									criteriaValue: BuffIdentifier.EnemyCooldown5000,
									minimumTargetsThatSatisfy: 1,
								},
								{
									criteriaTargets: CriteriaTarget.SELF,
									criteriaType: CriteriaType.WORLD_DIFFICULTY_AT_OR_BELOW_THRESHOLD,
									criteriaValue: WorldDifficulty.Normal,
									minimumTargetsThatSatisfy: 1,
								},
							],
						},
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SEQUENCE,
							//abilityOptions: [[1, longRangeAbility, 50]],
							abilityOptions: [attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, longRangeAbility, 0), attackRatedAbilityList(1, animationTimes, AnimationTrack.IDLE, cooldownEnemyCooldown5000, 50)],
						},
					},
					{
						// Wait
						priority: 6,
						actionCriteria: alwaysTrueCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							//abilityOptions: [[1, longRangeAbility, 50]],
							abilityOptions: [[0, waitAbility(20)]],
						},
					},
				],
			},
			attackOptions: [],
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 1,
			transitionToLeashing: {
				trigger: LeashTriggers.RANGE_TO_TARGET_EXCEEDED,
				range: 1500,
			},
		},
		leashing: {
			behaviour: LeashingBehaviours.MOVE,
			speedMultiplier: 4,
		},
		fleeing: {},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 1,
		},
	},
}

export default asp
