







































import { mapGetters, mapActions, mapState } from 'vuex'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import ListItemContainer from '../reusable-components/item-containers/list-item-container.vue'
import PaperdollQuickViewRow from '../reusable-components/item-containers/mini-paperdoll.vue'
import { canEquipItemOfLevel, maxEquipableLevel } from '../../engine/shared/game-data/player'
import { some, isEmpty } from 'lodash'
import GearSubType, { GearSubTypePrettyName } from '../../loot/shared/gear-sub-type'
import { equipToolTipText } from '../reusable-components/v-tooltip/v-tooltip-functions'
import { getStashLimit, STASH_DEFAULT_MAX_SIZE } from '../../engine/shared/game-data/player-formulas'

export default {
	name: 'Stash',
	components: {
		MainPanel,
		ListItemContainer,
		PaperdollQuickViewRow,
	},
	computed: {
		...mapState('hud', ['progressionLevel']),
		...mapGetters('itemContainers', ['itemsInContainer', 'containerIsFull', 'itemDetails', 'itemByContainerIndex', 'paperdoll', 'stashMtxIncreases', 'stashLoginIncreases', 'stashFactionIncreases']),
		...mapGetters('stash', ['selectedStashItem', 'stashCapacity']),
		...mapGetters('hud', ['coinBalance']),
		...mapGetters('miniPaperdoll', ['selectedMiniPaperdollIndex']),
		...mapState('stash', ['ftueMode']),
		maxStashSize() {
			return getStashLimit(this.stashMtxIncreases + this.stashLoginIncreases + this.stashFactionIncreases)
		},
	},
	methods: {
		equipToolTipText,

		itemIsSelected(itemId) {
			return itemId === this.selectedStashItem
		},
		...mapActions('inGame', ['closeActivePanel']),
		...mapActions('stash', ['toggleSelectStashItem', 'equipItemIntoSlot', 'showSelectedStashItem']),
		...mapActions('identify', ['identifyItem', 'deselectIdentifiedItem']),

		buttonShouldBeDisabledBasedOnCurrentSelections(itemId) {
			const paperdollItem = this.itemByContainerIndex('paperdoll', this.selectedMiniPaperdollIndex)
			const stashItem = this.itemDetails('stash', itemId)
			const allitemsEquippedOnPlayer = this.itemsInContainer('paperdoll')

			if (stashItem.unidentified) return true

			if (!canEquipItemOfLevel(this.progressionLevel, stashItem.level)) return true

			if ((stashItem.itemType === 'Gear' && this.selectedMiniPaperdollIndex === null) || (this.selectedMiniPaperdollIndex >= 2 && stashItem.itemType === 'Gear')) {
				if (allitemsEquippedOnPlayer.length === 5 && this.selectedMiniPaperdollIndex === null) {
					return true
				}
				if (paperdollItem?.itemSubType === stashItem.itemSubType) {
					return false
				}
				return !this.canEquipGearInSlot(itemId)
			}
			return paperdollItem?.itemType !== stashItem.itemType
		},
		buttonShouldBeHiddenBasedOnCriteria(item) {
			return item.itemType === 'Weapon Augment' || item.itemType === 'Biome Key' || item.itemType === 'Rarity Shard' || item.itemType === 'Lucky Shard' || item.itemType === 'Prize' || item.itemType === 'Worm Horn' || item.itemType === 'Persistent Buff'
		},
		buttonShouldBeHighlightedBasedOnCriteria(item) {
			const canGearBeEquipped = this.canEquipGearInSlot(item.id)
			const paperdollItem = this.itemByContainerIndex('paperdoll', this.selectedMiniPaperdollIndex)
			const stashItem = this.itemDetails('stash', item.id)

			if (item.itemType === 'Gear' && this.selectedMiniPaperdollIndex >= 2) {
				if (canGearBeEquipped) {
					return true
				}
			}
			return paperdollItem?.itemType === stashItem.itemType
		},

		canEquipGearInSlot(itemId) {
			const stashItem = this.itemDetails('stash', itemId)
			if (stashItem.itemSubType === GearSubType.Trinket) {
				return true
			}

			return !this.hasSameGearTypeEquipped(stashItem.itemSubType)
		},
		hasSameGearTypeEquipped(gearItemSubType) {
			const allGear = [2, 3, 4].map((slot) => this.itemByContainerIndex('paperdoll', slot))
			return some(allGear, (gear) => {
				return gear?.itemSubType === gearItemSubType
			})
		},
	},
}
