import Filter from 'bad-words'
import { profanityRegexEn } from './profanity-regex-en'

const filter = new Filter()
// (mostly) borrowed from bruh
filter.addWords(...['foesio', 'foes.io', 'foes', 'survivio', 'surviv.io', 'surviv', 'hitler', 'stalin', 'Rape', 'Faggot', 'Hitler', 'Nigger', 'Nigga', 'Slut', 'Whore', 'Spic', 'rape', 'nig', 'nigr', 'quim', 'beaner', 'wog', 'fvck'])

const regex = new RegExp(profanityRegexEn, 'giu')

export function filterString(input: string): string {
	let output = filter.clean(input)

	output = output.replace(regex, '****')

	return output
}

export function validateString(input: string): boolean {
	let result = !filter.isProfane(input)

	if (result) {
		result = !regex.test(input)
	}

	return result
}

const sillyStrings = [
	'This game is so much fun!',
	'I\'m having the time of my life!',
	'I respect and appreciate every one of you!',
	'Shoutout to the devs for this amazing game!',
	'Oops, I need to watch my language',
	'I\'m sorry, I almost said something rude :(',
	'You guys are my best friends!',
	'I have to go soon, it\'s almost my bedtime',
	'Darn, that crab boss sure is challenging! But I know I can do it if I keep trying, so wish me luck!',
	':)',
	'I sure wish I was as good as you at this game',
	'I need to step away for a moment and take a deep breath, I\'m being irrational. Don\'t wait up!',
	'After much self-reflection, I have realized that I am the problem. Sorry everyone!',
	'You guys really inspire me to try harder, thank you!',
	'I\'m doing my best, but sometimes I cannot find ways to express my negative feelings in a healthy and constructive way. I apologize, and I love you all!',
	'Can somebody, please, give me the attention I crave?',
	'Sorry, my cat walked over my keyboard',
	'Everyone named Sam is handsome, beautiful, and wonderfully talented',
]

export function filterChatString(input: string): string {
	try {
		const output = filterString(input)
		if (Math.random() < 0.9 || input === output) {
			return output
		} else {
			return sillyStrings.pickRandom()
		}
	} catch (e) {
		console.error('Error from chat message: ' + input + '\n' + e)
		return '>:|' //Who knows what eldritch horror they entered? now they get this face
	}
}
