import GearSubType, { GearSubTypePrettyName } from '../../../loot/shared/gear-sub-type'
import WeaponSubType, { WeaponSubTypePrettyName } from '../../../loot/shared/weapon-sub-type'
import WeaponAugmentSubType from '../../../loot/shared/weapon-augment-sub-type'
import WeaponEnchantmentSubType from '../../../loot/shared/weapon-enchantment-sub-type'
import { PrizeSubType } from '../../../loot/shared/prize-sub-type'
import { SKIN_SLOT } from '../../../player/shared/set-skin.shared'
import { WormHornSubtype } from '../../../loot/shared/worm-horn-sub-type'
import { BeachWeaponType, GoofyWeaponType, HalloweenWeaponType, PrehistoricWeaponType, weaponSkinSubTypeToWeaponSubType } from '../../../loot/shared/weapon-skin-sub-type'
import ItemType from '../../../loot/shared/item-type'
import ItemRarity from '../../../loot/shared/item-rarity'
import { GeneralStoreItemsSubType } from '../../../loot/shared/general-store-items-sub-type'
import { PersistentBuffSubType } from '../../../loot/shared/persistent-buff-sub-type'

export const getBackdropClassNameFromItem = (item: ImARealitem) => {
	if (item.itemType === 'Weapon Augment') {
		return 'weapon-augment'
	} else if (item.itemType === 'Weapon Enchantment') {
		return 'weapon-enchantment'
	} else if (item.itemType === 'biome key' || item.itemType === 'Coin' || item.itemType === 'Mtx' || item.itemType === 'Weapon Skin') {
		return ''
	} else {
		return 'gear-or-weapon'
	}
}

export const getClassNameFromItem = (item: any) => {
	if (item.itemType === 'Player Skin') {
		return 'player-skin'
	} else if (item.itemType === 'Pet Skin') {
		return 'pet-skin'
	} else if (item.itemType === 'Trail') {
		return 'trail'
	} else if (item.itemType === 'Wing') {
		return 'wings'
	} else if (item.itemType === 'Wand') {
		return 'wand'
	} else if (item.itemType === 'Staff') {
		return 'staff'
	} else if (item.itemType === 'Sword') {
		return 'sword'
	} else if (item.itemType === 'Arcane') {
		return 'arcane'
	} else if (item.itemType === 'Crossbow') {
		return 'crossbow'
	} else if (item.itemType === 'Scythe') {
		return 'scythe'
	} else if (item.itemType === 'Weapon Skin') {
		return 'weapon-skin'
	} else if (item.itemType === 'Emote') {
		return 'emote'
	}
}

export const getRarityClassNameFromItem = (item: ImARealitem) => {
	const itemTypeString = item.itemType
	if (itemTypeString === 'Biome Key' || itemTypeString === 'Lucky Shard' || itemTypeString === 'Coin' || itemTypeString === 'Currency Bag' || itemTypeString === 'Mtx' || itemTypeString === 'Worm Horn' || item.itemType === 'Weapon Skin' || item.itemType === 'Persistent Buff') {
		return ''
	}
	if (itemTypeString === 'Consumable' && item.itemSubType !== GeneralStoreItemsSubType.Health) {
		return ''
	}
	if (itemTypeString === 'Weapon Enchantment') {
		return 'generic'
	}
	return item.rarity.toString().toLowerCase()
}

export const getItemNameByItemType = (item: ImARealitem) => {
	const itemSubType = item.itemSubType
	const itemTypeString = item.itemType

	if (itemTypeString === 'Weapon' || itemTypeString === 'Gear') {
		if (WeaponSubType[itemSubType]) {
			return WeaponSubTypePrettyName.get(itemSubType as WeaponSubType)
				.toLowerCase()
				.replace(' ', '')
		} else if (GearSubType[itemSubType]) {
			return GearSubTypePrettyName.get(itemSubType as GearSubType)
				.toLowerCase()
				.replace(' ', '')
		}
	} else {
		return ''
	}
}

export const getIconClassNameFromItem = (item: ImARealitem) => {
	const itemSubType = item.itemSubType
	const itemTypeString = item.itemType
	const biome = item.biome

	if (itemTypeString === 'Weapon Enchantment') {
		switch (itemSubType) {
			case WeaponEnchantmentSubType.ExtraProjectiles:
				return 'weapon-enchantment projectiles'

			case WeaponEnchantmentSubType.FasterAttacks:
				return 'weapon-enchantment speed'

			case WeaponEnchantmentSubType.StrongerAttacks:
				return 'weapon-enchantment extra-damage'

			case WeaponEnchantmentSubType.Pierce:
				return 'weapon-enchantment extra-damage'

			case WeaponEnchantmentSubType.TightenSpread:
				return 'weapon-enchantment aim'

			case WeaponEnchantmentSubType.SplashDamage:
				return 'weapon-enchantment extra-damage'

			case WeaponEnchantmentSubType.CritStrike:
				return 'weapon-enchantment extra-damage'

			case WeaponEnchantmentSubType.BigAndBad:
				return 'weapon-enchantment beam'

			case WeaponEnchantmentSubType.WildShots:
				return 'weapon-enchantment trajectory'

			case WeaponEnchantmentSubType.ChargeOverload:
				return 'weapon-enchantment extra-damage'

			case WeaponEnchantmentSubType.PointBlankBoom:
				return 'weapon-enchantment extra-damage'

			case WeaponEnchantmentSubType.NoFinesse:
				return 'weapon-enchantment extra-damage'

			case WeaponEnchantmentSubType.CritDamage:
				return 'weapon-enchantment extra-damage'

			case WeaponEnchantmentSubType.PhysicalUp:
				return 'weapon-enchantment physical'

			case WeaponEnchantmentSubType.FireUp:
				return 'weapon-enchantment fire'

			case WeaponEnchantmentSubType.IceUp:
				return 'weapon-enchantment ice'

			case WeaponEnchantmentSubType.LightningUp:
				return 'weapon-enchantment lightning'

			case WeaponEnchantmentSubType.PoisonUp:
				return 'weapon-enchantment poison'

			case WeaponEnchantmentSubType.AilmentPotency:
				return 'weapon-enchantment extra-damage'

			case WeaponEnchantmentSubType.KnockBack:
				return 'weapon-enchantment aim'

			case WeaponEnchantmentSubType.Vacuum:
				return 'weapon-enchantment aim'

			case WeaponEnchantmentSubType.LongRange:
				return 'weapon-enchantment longrange'

			case WeaponEnchantmentSubType.BurstShot:
				return 'weapon-enchantment burstshot'

			case WeaponEnchantmentSubType.ShockingFirestorm:
				return 'weapon-enchantment shockingfirestorm'

			case WeaponEnchantmentSubType.FastShields:
				return 'weapon-enchantment fastshields'

			case WeaponEnchantmentSubType.Shielded:
				return 'weapon-enchantment shielded'

			case WeaponEnchantmentSubType.QuickSkills:
				return 'weapon-enchantment quickskills'

			case WeaponEnchantmentSubType.FissionEnergy:
				return 'weapon-enchantment fissionenergy'

			case WeaponEnchantmentSubType.GreviousWounds:
				return 'weapon-enchantment poison'

			case WeaponEnchantmentSubType.CatastropheBlast:
				return 'weapon-enchantment catastropheBlast'

			case WeaponEnchantmentSubType.GreaterElements:
				return 'weapon-enchantment greaterElements'
			
			case WeaponEnchantmentSubType.HeavyShielding:
				return 'weapon-enchantment heavyShielding'

			case WeaponEnchantmentSubType.HordeBreaker:
				return 'weapon-enchantment extra-damage'

			case WeaponEnchantmentSubType.BloodiedGear:
				return 'weapon-enchantment quickskills'

			case WeaponEnchantmentSubType.FastFeet:
				return 'weapon-enchantment speed'

			case WeaponEnchantmentSubType.GearOvercharge:
				return 'weapon-enchantment fissionenergy'

			default:
				return ''
		}
	} else if (itemTypeString === 'Weapon Augment') {
		switch (itemSubType) {
			case WeaponAugmentSubType.CreepingDeath:
				return 'weapon-augment creepingdeath'

			case WeaponAugmentSubType.NarrowSpreadExtraProjectiles:
				return 'weapon-augment narrowspreadextraprojectiles'

			case WeaponAugmentSubType.Pierce:
				return 'weapon-augment pierce'

			case WeaponAugmentSubType.TightenSpread:
				return 'weapon-augment tightenspread'

			case WeaponAugmentSubType.Sine:
				return 'weapon-augment sine'

			case WeaponAugmentSubType.Zigzag:
				return 'weapon-augment trajectory'

			case WeaponAugmentSubType.FastAndWeak:
				return 'weapon-augment fastandweak'

			case WeaponAugmentSubType.SlowAndStrong:
				return 'weapon-augment slowandstrong'

			case WeaponAugmentSubType.SplashDamage:
				return 'weapon-augment splashdamage'

			case WeaponAugmentSubType.LightningBurst:
				return 'weapon-augment lightningburst'

			case WeaponAugmentSubType.CritSwitch:
				return 'weapon-augment critswitch'

			case WeaponAugmentSubType.BasicStatUp:
				return 'weapon-augment extra-damage'

			case WeaponAugmentSubType.ClusterShot:
				return 'weapon-augment projectiles'

			case WeaponAugmentSubType.Sniper:
				return 'weapon-augment aim'

			case WeaponAugmentSubType.Flamethrower:
				return 'weapon-augment fire'

			case WeaponAugmentSubType.Icebound:
				return 'weapon-augment ice'

			case WeaponAugmentSubType.Taser:
				return 'weapon-augment lightning'

			case WeaponAugmentSubType.StraightBoomerang:
				return 'weapon-augment straightboomerang'

			case WeaponAugmentSubType.Debilitator:
				return 'weapon-augment poison'

			case WeaponAugmentSubType.DiffusionBeam:
				return 'weapon-augment diffusionbeam'

			case WeaponAugmentSubType.FocusedBeam:
				return 'weapon-augment beam'

			case WeaponAugmentSubType.StarLaser:
				return 'weapon-augment starlaser'

			case WeaponAugmentSubType.QuickCharge:
				return 'weapon-augment quickcharge'

			case WeaponAugmentSubType.PhysicalUp:
				return 'weapon-augment physicalup'

			case WeaponAugmentSubType.FireUp:
				return 'weapon-augment fireup'

			case WeaponAugmentSubType.IceUp:
				return 'weapon-augment iceup'

			case WeaponAugmentSubType.LightningUp:
				return 'weapon-augment lightningup'

			case WeaponAugmentSubType.PoisonUp:
				return 'weapon-augment poisonup'

			case WeaponAugmentSubType.FieryOvercharge:
				return 'weapon-augment fire'

			case WeaponAugmentSubType.WideSpreadExtraProjectiles:
				return 'weapon-augment widespreadextraprojectiles'

			// case WeaponAugmentSubType.ChainLightning:
			// 	return 'weapon-augment lightning'

			// case WeaponAugmentSubType.PhysicalConversion:
			// 	return 'weapon-augment physicalconversion'

			// case WeaponAugmentSubType.FireConversion:
			// 	return 'weapon-augment fireconversion'

			// case WeaponAugmentSubType.IceConversion:
			// 	return 'weapon-augment iceconversion'

			// case WeaponAugmentSubType.LightningConversion:
			// 	return 'weapon-augment lightningconversion'

			// case WeaponAugmentSubType.PoisonConversion:
			// 	return 'weapon-augment poisonconversion'

			case WeaponAugmentSubType.Accelerate:
				return 'weapon-augment speed'

			case WeaponAugmentSubType.Deccelerate:
				return 'weapon-augment deccelerate'

			case WeaponAugmentSubType.RangeAdjustingAccelerate:
				return 'weapon-augment rangeadjustingaccelerate'

			case WeaponAugmentSubType.EnergyBooster:
				return 'weapon-augment energy-boost'

			case WeaponAugmentSubType.ElementalDevotion:
				return 'weapon-augment elemental-devotion'

			default:
				return ''
		}
	} else if (itemTypeString === 'Biome Key' && biome !== undefined) {
		switch (biome) {
			case 0:
				return 'biome-key biome-key-beach'

			case 1:
				return 'biome-key biome-key-forest'

			case 2:
				return 'biome-key biome-key-onyx'

			case 3:
				return 'biome-key biome-key-fungi'

			case 4:
				return 'biome-key biome-key-prism'

			default:
				return ''
		}
	} else if (itemTypeString === 'Persistent Buff') {
		switch (itemSubType) {
			case PersistentBuffSubType.Enchanting:
				return 'potion potion-rare-enchanting'
			case PersistentBuffSubType.LegendaryEnchanting:
				return 'potion potion-legendary-enchanting'
			case PersistentBuffSubType.LifeBloom:
				return 'potion potion-rare-life-bloom'
			case PersistentBuffSubType.RegenerativeHealing:
				return 'potion potion-epic-regenerative-healing'
			case PersistentBuffSubType.QueensRansom:
				return 'scroll scroll-epic-queens-ransom'
			case PersistentBuffSubType.LegendaryQueensRansom:
				return 'scroll scroll-legendary-queens-ransom'
			case PersistentBuffSubType.FinalCountdown:
				return 'potion potion-epic-final-countdown'
			case PersistentBuffSubType.IronMan:
				return 'potion potion-epic-ironman'
			case PersistentBuffSubType.Pauper:
				return 'scroll scroll-epic-pauper'
			case PersistentBuffSubType.RagsToRiches:
				return 'scroll scroll-legendary-rags-to-riches'
		}
	} else if (itemTypeString === 'Weapon' || itemTypeString === 'Gear') {
		if (WeaponSubType[itemSubType]) {
			return WeaponSubTypePrettyName.get(itemSubType as WeaponSubType)
				.toLowerCase()
				.replace(' ', '')
		} else if (GearSubType[itemSubType]) {
			return GearSubTypePrettyName.get(itemSubType as GearSubType)
				.toLowerCase()
				.replace(' ', '')
		}
	} else if (itemTypeString === 'Consumable') {
		switch (itemSubType) {
			case GeneralStoreItemsSubType.Health:
				return 'health'
			case GeneralStoreItemsSubType.HunterHunted:
				return 'outpost-hunter-hunted'
			case GeneralStoreItemsSubType.GoFast:
				return 'outpost-go-fast'
			case GeneralStoreItemsSubType.PoisonApple:
				return 'outpost-poison-apple'
			case GeneralStoreItemsSubType.PhysicalArmor:
				return 'outpost-physical-armor'
			case GeneralStoreItemsSubType.FireArmor:
				return 'outpost-fire-armor'
			case GeneralStoreItemsSubType.IceArmor:
				return 'outpost-ice-armor'
			case GeneralStoreItemsSubType.PoisonArmor:
				return 'outpost-poison-armor'
			case GeneralStoreItemsSubType.LightningArmor:
				return 'outpost-lightning-armor'
			default:
				return ''
		}
	} else if (itemTypeString === 'Rarity Shard') {
		return 'rarity-shard'
	} else if (itemTypeString === 'Lucky Shard') {
		return 'lucky-shard'
	} else if (itemTypeString === 'Prize') {
		switch (itemSubType) {
			case PrizeSubType.PetEgg:
				return 'pet-egg'
			case PrizeSubType.WeaponSkin:
				{
					const weaponSkinType = item.extraData.weaponSkinType
					const weaponSubType = weaponSkinSubTypeToWeaponSubType(weaponSkinType)

					switch (weaponSubType) {
						case WeaponSubType.ArcaneFocus:
							return 'weapon-skin-arcane'
						case WeaponSubType.Crossbow:
							return 'weapon-skin-crossbow'
						case WeaponSubType.Staff:
							return 'weapon-skin-staff'
						case WeaponSubType.Spellsword:
							return 'weapon-skin-sword'
						case WeaponSubType.Wand:
							return 'weapon-skin-wand'
						case WeaponSubType.Scythe:
							return 'weapon-skin-scythe'
						default:
							return 'player-skin'
					}

				}
			case PrizeSubType.PlayerSkin:
				{
					switch (item.extraData.playerSkinSlot) {
						case SKIN_SLOT.PLAYER_MAIN:
							return 'player-skin'
						case SKIN_SLOT.PLAYER_BACK:
							return 'player-back'
						case SKIN_SLOT.PLAYER_FACE:
							return 'player-face'
						case SKIN_SLOT.PLAYER_FOOTPRINT:
							return 'player-footprint'
					}
					return 'player-skin'
				}
			case PrizeSubType.Emote:
				return 'emote'
		}
	} else if (itemTypeString === 'Coin' || itemTypeString === 'Currency Bag') {
		return 'coin'
	} else if (itemTypeString === 'Mtx') {
		if (item.isSkin) {
			switch (item.slot) {
				case SKIN_SLOT.PET_MAIN:
					return ''
			}

			return ''
		}
		return ''
	} else if (itemTypeString === 'Worm Horn') {
		switch (itemSubType) {
			case WormHornSubtype.Standard:
				return 'worm-horn worm-horn-standard'
			case WormHornSubtype.Premium:
				return 'worm-horn worm-horn-premium'
		}
	} else if (itemTypeString === 'Weapon Skin') {
		switch (itemSubType) {
			case BeachWeaponType.Arcane:
				return 'weapon-skin beach-arcane'
			case BeachWeaponType.Crossbow:
				return 'weapon-skin beach-crossbow'
			case BeachWeaponType.Staff:
				return 'weapon-skin beach-staff'
			case BeachWeaponType.Sword:
				return 'weapon-skin beach-sword'
			case BeachWeaponType.Wand:
				return 'weapon-skin beach-wand'
			case BeachWeaponType.Scythe:
				return 'weapon-skin beach-scythe'

			case GoofyWeaponType.Arcane:
				return 'weapon-skin goofy-arcane'
			case GoofyWeaponType.Crossbow:
				return 'weapon-skin goofy-crossbow'
			case GoofyWeaponType.Staff:
				return 'weapon-skin goofy-staff'
			case GoofyWeaponType.Sword:
				return 'weapon-skin goofy-sword'
			case GoofyWeaponType.Wand:
				return 'weapon-skin goofy-wand'
			case GoofyWeaponType.Scythe:
				return 'weapon-skin goofy-scythe'

			case HalloweenWeaponType.Arcane:
				return 'weapon-skin halloween-arcane'
			case HalloweenWeaponType.Crossbow:
				return 'weapon-skin halloween-crossbow'
			case HalloweenWeaponType.Staff:
				return 'weapon-skin halloween-staff'
			case HalloweenWeaponType.Sword:
				return 'weapon-skin halloween-sword'
			case HalloweenWeaponType.Wand:
				return 'weapon-skin halloween-wand'
			case HalloweenWeaponType.Scythe:
				return 'weapon-skin halloween-scythe'

			case PrehistoricWeaponType.Arcane:
				return 'weapon-skin prehistoric-arcane'
			case PrehistoricWeaponType.Crossbow:
				return 'weapon-skin prehistoric-crossbow'
			case PrehistoricWeaponType.Staff:
				return 'weapon-skin prehistoric-staff'
			case PrehistoricWeaponType.Sword:
				return 'weapon-skin prehistoric-sword'
			case PrehistoricWeaponType.Wand:
				return 'weapon-skin prehistoric-wand'
			case PrehistoricWeaponType.Scythe:
				return 'weapon-skin prehistoric-scythe'
		}
	} else {
		return ''
	}
}

// Temp while we investigate turning filter icons to a font format.
export const filterClassName = (filterName: string) => {
	if (filterName === 'Player Skin') {
		return 'player-skins'
	} else if (filterName === 'Pet Skin') {
		return 'pet-skins'
	} else if (filterName === 'Weapon Skins') {
		return 'weapons'
	} else if (filterName === 'Trail') {
		return 'trails'
	} else if (filterName === 'Back') {
		return 'wings'
	} else if (filterName === 'Face') {
		return 'face'
	}
}

export interface ImARealitem {
	id: string
	level: number
	unidentified: boolean
	identifyCost: number
	slotIndex: number
	paperdollSLotIndex: number
	itemType: string
	itemTypeEnum: ItemType
	augmentLimit: number
	equippedAugmentIds: string[]
	rarity: string
	rarityEnum: ItemRarity
	itemSubType: number
	biome: number
	isSkin?: boolean
	slot?: string
	stackAmount?: number
	extraData?: any
}
