enum ItemRarity {
	COMMON = 1,
	UNCOMMON = 2,
	RARE = 3,
	EPIC = 4,
	LEGENDARY = 5,
	ASTRONOMICAL = 6,
	CHAOS = 7,
}

const ItemRarityPrettyName = new Map<ItemRarity, string>()
ItemRarityPrettyName.set(ItemRarity.COMMON, 'common')
ItemRarityPrettyName.set(ItemRarity.UNCOMMON, 'uncommon')
ItemRarityPrettyName.set(ItemRarity.RARE, 'rare')
ItemRarityPrettyName.set(ItemRarity.EPIC, 'epic')
ItemRarityPrettyName.set(ItemRarity.LEGENDARY, 'legendary')
ItemRarityPrettyName.set(ItemRarity.ASTRONOMICAL, 'astronomical')
ItemRarityPrettyName.set(ItemRarity.CHAOS, 'chaos')

export default ItemRarity
export { ItemRarity, ItemRarityPrettyName }
