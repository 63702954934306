




























































import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import ListItemContainer from '../reusable-components/item-containers/list-item-container.vue'
import ArrowButton from '../reusable-components/buttons/arrow-button.vue'
import { UI } from '../ui'
import { MAX_ITEM_LISTINGS } from '../state/marketplace.ui-state'
import { listingTooltipText } from '../reusable-components/v-tooltip/v-tooltip-functions'

export default {
	name: 'UserMarketListing',
	components: {
		MainPanel,
		ListItemContainer,
		ArrowButton,
	},
	data() {
		return {
			MAX_LISTINGS: MAX_ITEM_LISTINGS,
		}
	},
	computed: {
		...mapGetters('time', ['nowMoment']),
		...mapGetters('itemContainers', ['itemsInContainer', 'itemDetails']),
		...mapGetters('marketplaceUpdated', ['listingsMetadata', 'cancelledListings', 'selectedListingItem', 'getMyListings', 'getLoadingInProgress']),
		...mapState('marketplaceUpdated', ['isListingItem', 'userListingsMetadata', 'lastFreeMarketplaceDate']),
		showFreeListingButton() {
			if(this.lastFreeMarketplaceDate) {
				return !this.nowMoment.isSame(this.lastFreeMarketplaceDate, 'day')
			}
			return true
		},
		listContainerHeight() {
			if(this.showFreeListingButton) {
				return '675px'
			}
			return '775px'
		}
	},
	methods: {
		listingTooltipText,

		itemIsSelected(itemId) {
			return itemId === this.selectedListingItem
		},
		...mapActions('inGame', ['closeActivePanel']),
		...mapMutations('inGame', ['setActivePanel']),
		...mapMutations('marketplaceUpdated', ['setUseFreeMarketplaceListing']),
		...mapActions('marketplaceUpdated', ['toggleSelectMarketplaceListingItem']),
		openAddListing() {
			this.setUseFreeMarketplaceListing(false)
			this.setActivePanel('addMarketplaceListing')
		},
		openFreeAddListing() {
			this.setUseFreeMarketplaceListing(true)
			this.setActivePanel('addMarketplaceListing')
		},
		backToMarketplace() {
			UI.getInstance().emitEvent('inGame/setActivePanel', 'marketplaceUpdated')
		},
		cancelListing(itemId) {
			const listingId = this.userListingsMetadata.find((metadata) => {
				return metadata.itemId === itemId
			}).listingId
			UI.getInstance().emitAsyncEvent('marketplaceUpdated/cancelItemListing', { listingId, itemId })
		},
		disableCancelButtonForId(id) {
			return Boolean(this.itemDetails('pendingMarketplace', id))
		},
		selectItem(itemId) {
			this.toggleSelectMarketplaceListingItem({itemId, myListing: true})
		}
	},
}
