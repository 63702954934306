import nengi from 'nengi'
import ItemRarity from '../../loot/shared/item-rarity'
import { uuid } from '../../utils/primitive-types'

export class GroundItemDropMessage {
	static protocol = {
		id: nengi.String,
		rarity: nengi.UInt4,
	}

	id: uuid
	rarity: ItemRarity

	constructor(params: GroundItemDropMessage) {
		Object.assign(this, params)
	}
}
