import { getMultiplicativeReduction } from '../../../utils/math'
import { percentage, pressureRank } from '../../../utils/primitive-types'

export const PRESSURE_HARSH_ADDITIONAL_DAMAGE_PER_RANK: percentage = 0.5
export const PRESSURE_SAVAGERY_ADDITIONAL_DAMAGE_PER_RANK: percentage = 0.5
export const PRESSURE_BRUTALITY_ADDITIONAL_DAMAGE_PER_RANK: percentage = 0.5
export const PRESSURE_TANKY_ADDITIONAL_HEALTH_PER_RANK: percentage = 0.08
export const PRESSURE_SUPREME_BARRIER_ADDITIONAL_HEALTH_PER_RANK: percentage = 0.25
export const PRESSURE_ARMORED_ADDITIONAL_HEALTH_PER_RANK: percentage = 0.5
export const PRESSURE_FOAM_WEAPONS_LESS_DAMAGE_PER_RANK: percentage = 0.10
export const PRESSURE_WEAK_POINT_LESS_HEALTH_PER_RANK: percentage = 0.125
export const PRESSURE_TINY_WORM_ITEMS_ARRAY: number[] = [3, 5, 7, 9]
export const PRESSURE_PAUPER_ITEMS_ARRAY: number[] = [0.125, 0.25, 0.375, 0.50]
export const PRESSURE_CREEPING_WEAKNESS_DAMAGE_REDUCTION_PER_RANK: number[] = [0.05, 0.1]
export const PRESSURE_FRAILTY_DAMAGE_PER_RANK: percentage = 0.2

export const PRESSURE_ENRAGE_PHASE_TICK_INCREASE_SECONDS: number = 60
export const PRESSURE_ENRAGE_PHASE_DAMAGE_INCREASE: percentage = 0.2

export const PRESSURE_LOST_WANDERER_SECONDS_TO_DECREASE: number = 60
export const PRESSURE_LOST_WANDERER_PERCENT_DECREASE: number[] = [0.07, 0.14]

export const PRESSURE_MENU_UNLOCK_TIERS_DEFEATED = 1
export const PRESSURE_POINTS_PER_TIER = 10 // how many points needed to increase a tier

// Order of enums is also the order categories appear in the UI
export enum IPressureCategory {
	EnemyDamage,
	EnemyTankiness,
	Sustain,
	Loot,
	GearSkills,
	Speedrunning,
	Miscellaneous,
}

export function getPressureCategoryPrettyName(category: IPressureCategory) {
	switch (category) {
		case IPressureCategory.EnemyDamage:
			return "Enemy Damage"
			break
		case IPressureCategory.EnemyTankiness:
			return "Enemy Tankiness"
			break
		case IPressureCategory.Sustain:
			return "Sustain"
			break
		case IPressureCategory.Loot:
			return "Loot-less"
			break
		case IPressureCategory.GearSkills:
			return "Gear Skills"
			break
		case IPressureCategory.Speedrunning:
			return "Speedrunning"
			break
		case IPressureCategory.Miscellaneous:
			return "Miscellaneous"
			break
		default:
			throw new Error("Pretty name was not defined for all possible IPressureCategory values.")
	}
}
export interface IPressureStatInfo {
	category: IPressureCategory
	prettyName: string
	pointsPerRank: number
	ranks: pressureRank[] // # of ranks that are available at SC1, SC2, SC3, etc.
	rankStrings: string[] | ((rank: pressureRank) => string)
}

export function getRankDescriptionString(modifier: IPressureStatInfo, ranks: pressureRank) {
	let descriptionString: string
	if (Array.isArray(modifier.rankStrings)) {
		descriptionString = modifier.rankStrings[Math.clamp(ranks, 1, modifier.ranks[modifier.ranks.length - 1]) - 1]
	} else {
		const rankStringFunc = modifier.rankStrings as (rank: pressureRank) => string
		descriptionString = rankStringFunc(Math.max(1, ranks))
	}

	if (ranks === 0) {
		descriptionString = descriptionString.replace(`<span class="ftue color gold">`, '')
		descriptionString = descriptionString.replace(`</span>`, '')
	}

	return descriptionString
}

export enum PressureStat {
	Harsh = 0, // Normal enemies deal 100/200/300/400% more damage.
	Savagery = 1, // Champions deal 50/100/150/200% more damage.
	Brutality = 2, // Bosses deal 50/100% more damage.
	Tanky = 3, // All enemies have 20/40/60/80/100/etc.% more health.
	Armored = 4, // Champions have 50/100% more health.
	SupremeBarrier = 5, // Bosses have 25/50/75/100% more health.
	WeakPoint = 6, // Player has 12/25/37/50% less health.
	FoamWeapons = 7, // Player deals 25/50% less damage.
	HotGear = 8, // Player has 25/50% longer cooldowns.
	WiltedVitality = 9, //Player healing is reduced by 33/66/100%.
	Heartless = 10, // 33/55/70/80% decreased heart drop rate.
	Frailty = 11, // Each time you take health damage you take 20% more damage
	ShieldsDown = 12, //Player has 1/2/3 less shields.
	TinyWorm = 13, //Player can Worm Mail 3/6/9 less items.
	Pauper = 14, // Player finds 25/50% less loot
	CursedArtifacts = 15, //	Using gear skills damages the player proportional to the cooldown.
	FinalDestination = 16, // You have no gear skills
	BadEyes = 17, // Your viewport shrinks by 5% every 60 seconds.
	ThirstyVampire = 18, // You lose health every 5 seconds, but 50% increased heart drops
	EnragePhase = 19, // Enemies deal 20% more damage every 50 seconds
	LostWanderer = 20, // Player finds 7/14% less loot every 60 seconds
	CreepingWeakness = 21, // Your damage dealt drops 5/10% every 60 seconds
}

// PressureStat -> ranks chosen by player for this stat
export type PressureLoadout = Partial<Record<PressureStat, number>>

// Order of these records *within* each category is also the order they will appear in the UI
export const pressureStatInfos: Record<PressureStat, IPressureStatInfo> = {
	[PressureStat.Harsh]: {
		// Done
		category: IPressureCategory.EnemyDamage,
		prettyName: 'Harsh',
		pointsPerRank: 1,
		ranks: [4, 5, 6, 7, 8, 9, 10],
		rankStrings: getHarshString,
	},
	[PressureStat.Savagery]: {
		// Done
		category: IPressureCategory.EnemyDamage,
		prettyName: 'Savagery',
		pointsPerRank: 3,
		ranks: [3, 3, 4, 4, 5, 5, 6],
		rankStrings: getSavageryString,
	},
	[PressureStat.Brutality]: {
		// Done
		category: IPressureCategory.EnemyDamage,
		prettyName: 'Brutality',
		pointsPerRank: 3,
		ranks: [0, 0, 0, 0, 0, 1, 1, 1, 2],
		rankStrings: ['Bosses deal <span class="ftue color gold">50%</span> more damage', 'Bosses deal <span class="ftue color gold">100%</span> more damage'],
	},
	[PressureStat.Tanky]: {
		// Done
		category: IPressureCategory.EnemyTankiness,
		prettyName: 'Tanky',
		pointsPerRank: 1,
		ranks: [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60], // etc.
		rankStrings: getTankyString,
	},
	[PressureStat.Armored]: {
		// Done
		category: IPressureCategory.EnemyTankiness,
		prettyName: 'Armored',
		pointsPerRank: 2,
		ranks: [0, 1, 1, 2, 2, 3, 3, 4, 4, 5],
		rankStrings: getArmoredString,
	},
	[PressureStat.SupremeBarrier]: {
		// Done
		category: IPressureCategory.EnemyTankiness,
		prettyName: 'Supreme Barrier',
		pointsPerRank: 3,
		ranks: [0, 0, 0, 0, 2, 2, 4, 4, 6],
		rankStrings: getSupremeBarrierString,
	},
	[PressureStat.WeakPoint]: {
		// Done
		category: IPressureCategory.EnemyDamage,
		prettyName: 'Weak Point',
		pointsPerRank: 2,
		ranks: [0, 0, 0, 2, 4, 6, 8, 10, 12],
		rankStrings: getWeakPointString,
	},
	[PressureStat.FoamWeapons]: {
		// Done
		category: IPressureCategory.EnemyTankiness,
		prettyName: 'Foam Weapons',
		pointsPerRank: 3,
		ranks: [0, 0, 0, 4, 4, 8, 8, 12, 12, 16, 16, 20, 20, 24, 24, 28, 28, 32, 32, 36, 36, 40, 40, 44, 44, 48, 48, 52, 52, 56, 58], // etc.
		rankStrings: getFoamWeaponsString,
	},
	[PressureStat.HotGear]: {
		// Done
		category: IPressureCategory.GearSkills,
		prettyName: 'Hot Gear',
		pointsPerRank: 3,
		ranks: [1, 1, 1, 1, 1, 2],
		rankStrings: ['Player has <span class="ftue color gold">25%</span> longer cooldowns.', 'Player has <span class="ftue color gold">50%</span> longer cooldowns.'],
	},
	[PressureStat.WiltedVitality]: {
		// Done
		category: IPressureCategory.Sustain,
		prettyName: 'Wilted Vitality',
		pointsPerRank: 3,
		ranks: [1, 1, 1, 2, 2, 2, 3],
		rankStrings: ['Player healing is reduced by <span class="ftue color gold">33%</span>.', 'Player healing is reduced by <span class="ftue color gold">66%</span>.', 'Player healing is reduced by <span class="ftue color gold">100%</span>.'],
	},
	[PressureStat.Heartless]: {
		// Done
		category: IPressureCategory.Sustain,
		prettyName: 'Heartless',
		pointsPerRank: 2,
		ranks: [0, 1, 1, 2, 2, 3, 3, 4],
		rankStrings: ['<span class="ftue color gold">33%</span> decreased heart drop rate.', '<span class="ftue color gold">55%</span> decreased heart drop rate.', '<span class="ftue color gold">70%</span> decreased heart drop rate.', '<span class="ftue color gold">80%</span> decreased heart drop rate.'],
	},
	[PressureStat.Frailty]: {
		// Done
		category: IPressureCategory.EnemyDamage,
		prettyName: 'Frailty',
		pointsPerRank: 7,
		ranks: [0, 0, 0, 0, 1],
		rankStrings: ['Each time you take health damage, you take 20% more damage.'],
	},
	[PressureStat.ShieldsDown]: {
		// Done
		category: IPressureCategory.Sustain,
		prettyName: 'Shields Down!',
		pointsPerRank: 3,
		ranks: [1, 1, 1, 1, 2, 2, 2, 2, 3],
		rankStrings: ['Player has <span class="ftue color gold">1</span> less shields.', 'Player has <span class="ftue color gold">2</span> less shields.', 'Player has <span class="ftue color gold">3</span> less shields.'],
	},
	[PressureStat.TinyWorm]: {
		// Done
		category: IPressureCategory.Loot,
		prettyName: 'Tiny Worm',
		pointsPerRank: 3,
		ranks: [1, 1, 1, 2, 2, 2, 3, 3, 3, 4],
		rankStrings: getTinyWormString,
	},
	[PressureStat.Pauper]: {
		// Done
		category: IPressureCategory.Loot,
		prettyName: 'Pauper',
		pointsPerRank: 3,
		ranks: [0, 1, 1, 2, 2, 3, 3, 4],
		rankStrings: ['Player finds <span class="ftue color gold">12%</span> less loot.', 'Player finds <span class="ftue color gold">25%</span> less loot.', 'Player finds <span class="ftue color gold">37%</span> less loot.', 'Player finds <span class="ftue color gold">50%</span> less loot.'],
	},
	[PressureStat.CursedArtifacts]: {
		// Done
		category: IPressureCategory.GearSkills,
		prettyName: 'Cursed Artifacts',
		pointsPerRank: 5,
		ranks: [0, 0, 1],
		rankStrings: ['Using gear skills damages the player proportional to the cooldown.'],
	},
	[PressureStat.FinalDestination]: {
		// Done
		category: IPressureCategory.GearSkills,
		prettyName: 'Final Destination',
		pointsPerRank: 15,
		ranks: [0, 0, 0, 0, 0, 0, 0, 0, 1],
		rankStrings: ['You have no gear skills.'],
	},
	[PressureStat.BadEyes]: {
		// Done
		category: IPressureCategory.Speedrunning,
		prettyName: 'Bad Eyes',
		pointsPerRank: 6,
		ranks: [1],
		rankStrings: ['Your viewport shrinks by 5% every 60 seconds.'],
	},
	[PressureStat.ThirstyVampire]: {
		// Done
		category: IPressureCategory.Sustain,
		prettyName: 'Thirsty Vampire',
		pointsPerRank: 8,
		ranks: [0, 0, 1, 1, 1, 2],
		rankStrings: ['You lose 3 health every 5 seconds, and have 50% increased heart drop rate.', 'You lose 6 health every 5 seconds, and have 100% increased heart drop rate.'],
	},
	[PressureStat.EnragePhase]: {
		// Done
		category: IPressureCategory.Speedrunning,
		prettyName: 'Enrage Phase',
		pointsPerRank: 7,
		ranks: [0, 1],
		rankStrings: ['Enemies deal 20% more damage every 60 seconds.'],
	},
	[PressureStat.LostWanderer]: {
		// Done
		category: IPressureCategory.Speedrunning,
		prettyName: 'Lost Wanderer',
		pointsPerRank: 6,
		ranks: [0, 0, 0, 1, 1, 1, 1, 2],
		rankStrings: ['Player finds <span class="ftue color gold">7%</span> less loot every 60 seconds.', 'Player finds <span class="ftue color gold">14%</span> less loot every 60 seconds.'],
	},
	[PressureStat.CreepingWeakness]: {
		// Done
		category: IPressureCategory.Speedrunning,
		prettyName: 'Creeping Weakness',
		pointsPerRank: 10,
		ranks: [0, 0, 0, 0, 0, 1, 1, 1, 1, 2],
		rankStrings: ['Your damage drops <span class="ftue color gold">5%<span> every 60 seconds.', 'Your damage drops <span class="ftue color gold">10%<span> every 60 seconds.'],
	},
}

export function pressureGetPrettyName(pressureStat: PressureStat) {
	return pressureStatInfos[pressureStat].prettyName
}

/**
 * Converts percentage in 0->1 format to 0->100 format, rounded to nearest whole number.
 * @param percent01 percentage(number) in 0->1 format
 * @returns percentage(number) in 0->100 rounded to nearest whole number
 */
function getPrettyRoundedPercent(percent01: percentage) {
	return Math.round(percent01 * 100)
}

function getHarshString(rank: pressureRank) {
	return `Normal enemies deal <span class="ftue color gold">${getPrettyRoundedPercent(rank * PRESSURE_HARSH_ADDITIONAL_DAMAGE_PER_RANK)}%</span> more damage`
}

function getSavageryString(rank: pressureRank) {
	return `Champions deal <span class="ftue color gold">${getPrettyRoundedPercent(rank * PRESSURE_SAVAGERY_ADDITIONAL_DAMAGE_PER_RANK)}%</span> more damage`
}

function getTankyString(rank: pressureRank) {
	return `All enemies have <span class="ftue color gold">${getPrettyRoundedPercent(rank * PRESSURE_TANKY_ADDITIONAL_HEALTH_PER_RANK)}%</span> more health.`
}

function getArmoredString(rank: pressureRank) {
	return `Champions have <span class="ftue color gold">${getPrettyRoundedPercent(rank * 0.5)}%</span> more health.`
}

function getSupremeBarrierString(rank: pressureRank) {
	return `Bosses have <span class="ftue color gold">${getPrettyRoundedPercent(rank * 0.25)}%</span> more health.`
}

function getWeakPointString(rank: pressureRank) {
	const playerHealth = getMultiplicativeReduction(100, PRESSURE_WEAK_POINT_LESS_HEALTH_PER_RANK, rank)

	return `Player has <span class="ftue color gold">${playerHealth.toFixed(2)}%</span> max health.`
}

function getFoamWeaponsString(rank: pressureRank) {
	const reducedDamage = getMultiplicativeReduction(100, PRESSURE_FOAM_WEAPONS_LESS_DAMAGE_PER_RANK, rank)

	let places = 1
	let reducedString = reducedDamage.toFixed(places)
	while (Number.parseFloat(reducedString) === 0) {
		reducedString = reducedDamage.toFixed(++places)
	}

	if (reducedString.length < 8) {
		return `Player deals <span class="ftue color gold">${reducedDamage.toFixed(places + 1)}%</span> damage.`
	} else {
		return `Player deals <span class="ftue color gold">${reducedDamage.toExponential(2)}%</span> damage.`
	}
}

function getTinyWormString(rank: pressureRank) {
	const items = rank > 0 ? PRESSURE_TINY_WORM_ITEMS_ARRAY[rank - 1] : 0
	return `Player can Worm Mail <span class="ftue color gold">${items}</span> less items.`
}
