import { each, keys, values } from 'lodash'

// @ts-ignore
// var spector = new SPECTOR.Spector();
// spector.displayUI();
// spector.spyCanvases();

const requiredEnvVars = {
	NODE_ENV: process.env.NODE_ENV,
}

console.log('Starting app with...')

each(requiredEnvVars, (v, k) => {
	console.log(`${k}`, v)
})

if (values(requiredEnvVars).includes(undefined)) {
	console.error(`Missing required environment variables, unable to start app. Required: ${keys(requiredEnvVars)}`)
	process.exit(1)
}

import { AssetManager } from '../asset-manager/client/asset-manager'
import { UI } from './ui'

const init = async () => {
	// Initial UI creation, needs to be the first use of UI.getInstance()
	UI.getInstance().mount()

	// Initial Asset Loader creation, needs to be the first use of AssetManager.getInstance()
	AssetManager.getInstance()

	// Grab the initial user state immediately
	UI.getInstance().emitAsyncEvent('user/fetchInitialUserState')
}

init()
