import nengi from 'nengi'

class StashIncreasesMessage {
	static protocol = {
		stashMtxIncreases: nengi.Number,
		stashLoginIncreases: nengi.Number,
		stashFactionIncreases: nengi.Number,
	}

	stashMtxIncreases: number
	stashLoginIncreases: number
	stashFactionIncreases: number

	constructor(stashMtxIncreases: number, stashLoginIncreases: number, stashFactionIncreases: number) {
		this.stashMtxIncreases = stashMtxIncreases
		this.stashLoginIncreases = stashLoginIncreases
		this.stashFactionIncreases = stashFactionIncreases
	}
}

export default StashIncreasesMessage
