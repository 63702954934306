import nengi from 'nengi'

class PlayerNotificationMessage {
	static protocol = {
		subject: { type: nengi.String },
		id: { type: nengi.String },
	}

	subject: string
	id: string

	constructor(subject: string, id: string) {
		this.subject = subject
		this.id = id
	}
}

export default PlayerNotificationMessage
