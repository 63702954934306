import { getLevelWorldTier1, getLevelWorldTier1_5, getLevelWorldTier2 } from '../../biome/shared/biome-list'
import { GameModeType } from '../../engine/shared/game-mode-type'
import StatModPoolType from './stat-mod-pool-type'

const LEVEL_WORLD_TIER_1 = getLevelWorldTier1(GameModeType.Adventure)
const LEVEL_WORLD_TIER_1_5 = getLevelWorldTier1_5(GameModeType.Adventure)
const LEVEL_WORLD_TIER_2 = getLevelWorldTier2(GameModeType.Adventure)

export enum WeaponAugmentSubType {
	NarrowSpreadExtraProjectiles = 101,
	FastAndWeak = 104,
	SlowAndStrong = 105,
	WideSpreadExtraProjectiles = 106,
	Pierce = 107,
	TightenSpread = 110,
	SplashDamage = 112,
	CreepingDeath = 113,
	CritSwitch = 114,
	BasicStatUp = 115,
	ClusterShot = 116,
	Sniper = 117,
	QuickCharge = 118,

	DiffusionBeam = 119,
	FocusedBeam = 120,
	StarLaser = 121,

	LightningBurst = 122,
	Flamethrower = 123,
	Icebound = 124,
	Taser = 125,
	// ChainLightning = 126,
	Debilitator = 127,

	PhysicalUp = 128,
	FireUp = 129,
	IceUp = 130,
	LightningUp = 131,
	PoisonUp = 132,

	// PhysicalConversion = 133,
	// FireConversion = 134,
	// IceConversion = 135,
	// LightningConversion = 136,
	// PoisonConversion = 137,

	FieryOvercharge = 138,

	Accelerate = 200,
	Deccelerate = 201,
	RangeAdjustingAccelerate = 202,
	Sine = 206,
	Zigzag = 207,
	StraightBoomerang = 208,
	Circular = 209,
	Orbit = 210,
	Spiral = 211,
	Looping = 212,
	RangeAdjustingCircular = 213,
	RangeAdjustingOrbit = 214,
	
	EnergyBooster = 215,
	ElementalDevotion = 216,
}

export const WeaponAugmentConfigData: Map<
	WeaponAugmentSubType,
	{
		prettyName: string
		description: string
		availableLevel: number
		modPool: StatModPoolType
	}
> = new Map()
{
	//WT1
	WeaponAugmentConfigData.set(WeaponAugmentSubType.CreepingDeath, {
		prettyName: 'Creeping Death',
		description: 'Slow moving projectiles that deal massive damage.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.AUGMENT_CREEPING_DEATH,
	})
	WeaponAugmentConfigData.set(WeaponAugmentSubType.NarrowSpreadExtraProjectiles, {
		prettyName: '3 Piece Shoot',
		description: 'Add 2 projectiles, but all projectiles deal a bit less damage.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.AUGMENT_NARROW_SPREAD_EXTRA_PROJECTILES_BASE,
	})
	WeaponAugmentConfigData.set(WeaponAugmentSubType.Sniper, {
		prettyName: 'Sniper',
		description: 'Gain damage at long range and piercing, but lose extra projectiles.',
		availableLevel: LEVEL_WORLD_TIER_2,
		modPool: StatModPoolType.AUGMENT_SNIPER,
	})
	WeaponAugmentConfigData.set(WeaponAugmentSubType.BasicStatUp, {
		prettyName: 'Stat Up+',
		description: 'Improve some stats, nothing too fancy.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.AUGMENT_BASIC_STAT_UP,
	})
	WeaponAugmentConfigData.set(WeaponAugmentSubType.ClusterShot, {
		prettyName: 'Cluster Shot',
		description: 'Add 4 projectiles, but increase variance and spread and lose piercing.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.AUGMENT_CLUSTER_SHOT,
	})
	WeaponAugmentConfigData.set(WeaponAugmentSubType.DiffusionBeam, {
		prettyName: 'Diffusion Beam',
		description: 'Wider, shorter beam with increased damage.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.AUGMENT_DIFFUSION_BEAM,
	})
	WeaponAugmentConfigData.set(WeaponAugmentSubType.FocusedBeam, {
		prettyName: 'Focused Beam',
		description: 'Narrow beam with greatly increased damage.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.AUGMENT_FOCUSED_BEAM,
	})

	WeaponAugmentConfigData.set(WeaponAugmentSubType.LightningBurst, {
		prettyName: 'Lightning Burst',
		description: 'Explosive, fast lightning damage.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.AUGMENT_LIGHTNING_BURST,
	})
	WeaponAugmentConfigData.set(WeaponAugmentSubType.Flamethrower, {
		prettyName: 'Flamethrower',
		description: 'Burning. Lots of burning.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.AUGMENT_FLAMETHROWER,
	})
	WeaponAugmentConfigData.set(WeaponAugmentSubType.Icebound, {
		prettyName: 'Icebound',
		description: 'Launch ONE huge projectile that is sure to chill enemies.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.AUGMENT_ICEBOUND,
	})
	WeaponAugmentConfigData.set(WeaponAugmentSubType.Taser, {
		prettyName: 'Taser',
		description: 'Zap enemies with close-range lightning damage.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.AUGMENT_TASER,
	})
	// WeaponAugmentConfigData.set(WeaponAugmentSubType.ChainLightning, {
	// 	prettyName: 'Chain Lightning',
	// 	description: 'Arc between enemies for widespread lightning damage.',
	// 	availableLevel: LEVEL_WORLD_TIER_2,
	// 	modPool: StatModPoolType.AUGMENT_CHAIN_LIGHTNING,
	// })
	WeaponAugmentConfigData.set(WeaponAugmentSubType.Debilitator, {
		prettyName: 'Debilitator',
		description: 'Debilitate enemies with potent poison damage.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.AUGMENT_DEBILITATOR,
	})

	WeaponAugmentConfigData.set(WeaponAugmentSubType.PhysicalUp, {
		prettyName: 'Physical Damage+',
		description: 'Greatly increase your Physical damage.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.AUGMENT_PHYSICAL_UP,
	})
	WeaponAugmentConfigData.set(WeaponAugmentSubType.FireUp, {
		prettyName: 'Fire Damage+',
		description: 'Greatly increase your Fire damage.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.AUGMENT_FIRE_UP,
	})
	WeaponAugmentConfigData.set(WeaponAugmentSubType.IceUp, {
		prettyName: 'Ice Damage+',
		description: 'Greatly increase your Ice damage.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.AUGMENT_ICE_UP,
	})
	WeaponAugmentConfigData.set(WeaponAugmentSubType.LightningUp, {
		prettyName: 'Lightning Damage+',
		description: 'Greatly increase your Lightning damage.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.AUGMENT_LIGHTNING_UP,
	})
	WeaponAugmentConfigData.set(WeaponAugmentSubType.PoisonUp, {
		prettyName: 'Poison Damage+',
		description: 'Greatly increase your Poison damage.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.AUGMENT_POISON_UP,
	})

	//WT1.5
	WeaponAugmentConfigData.set(WeaponAugmentSubType.TightenSpread, {
		prettyName: 'Tighten Spread',
		description: 'Tighten the spread and reduce variance.',
		availableLevel: LEVEL_WORLD_TIER_1_5,
		modPool: StatModPoolType.AUGMENT_TIGHTEN_SPREAD_BASE,
	})
	WeaponAugmentConfigData.set(WeaponAugmentSubType.FastAndWeak, {
		prettyName: 'Rapid Fire',
		description: 'Shoot MUCH faster at the cost of some damage.',
		availableLevel: LEVEL_WORLD_TIER_1_5,
		modPool: StatModPoolType.AUGMENT_FAST_AND_WEAK_BASE,
	})
	WeaponAugmentConfigData.set(WeaponAugmentSubType.QuickCharge, {
		prettyName: 'Quick Charge',
		description: 'Charge up your weapon much faster.',
		availableLevel: LEVEL_WORLD_TIER_1_5,
		modPool: StatModPoolType.AUGMENT_QUICK_CHARGE_BASE,
	})
	WeaponAugmentConfigData.set(WeaponAugmentSubType.SlowAndStrong, {
		prettyName: 'Slowshooty',
		description: 'Shoot POWERFUL shots at the cost of attack rate.',
		availableLevel: LEVEL_WORLD_TIER_1_5,
		modPool: StatModPoolType.AUGMENT_SLOW_AND_STRONG_BASE,
	})
	WeaponAugmentConfigData.set(WeaponAugmentSubType.WideSpreadExtraProjectiles, {
		prettyName: 'Volley',
		description: 'Add 4 projectiles, but all projectiles deal much less damage.',
		availableLevel: LEVEL_WORLD_TIER_1_5,
		modPool: StatModPoolType.AUGMENT_WIDE_SPREAD_EXTRA_PROJECTILES_BASE,
	})
	WeaponAugmentConfigData.set(WeaponAugmentSubType.Pierce, {
		prettyName: 'Pierce',
		//TODO2: move sniper's bleed chance to pierce; requires wipe or migration
		description: 'Add piercing and tighten spread, but deal a bit less damage.',
		availableLevel: LEVEL_WORLD_TIER_1_5,
		modPool: StatModPoolType.AUGMENT_PIERCE_BASE,
	})
	WeaponAugmentConfigData.set(WeaponAugmentSubType.StarLaser, {
		prettyName: 'Star Laser',
		description: 'Greatly increase beam length, with a small damage boost.',
		availableLevel: LEVEL_WORLD_TIER_1_5,
		modPool: StatModPoolType.AUGMENT_STAR_LASER,
	})

	//WT2
	WeaponAugmentConfigData.set(WeaponAugmentSubType.SplashDamage, {
		prettyName: 'Explosive',
		description: 'Causes your projectiles to explode, dealing area damage.',
		availableLevel: LEVEL_WORLD_TIER_1_5,
		modPool: StatModPoolType.AUGMENT_SPLASH_DAMAGE_BASE,
	}) // temporarily 1.5, not 2
	WeaponAugmentConfigData.set(WeaponAugmentSubType.CritSwitch, {
		prettyName: 'Crit Switch',
		description: 'Sacrifice base damage in exchange for stronger, more frequent critical strikes. Adds Rangefinder.',
		availableLevel: LEVEL_WORLD_TIER_1_5,
		modPool: StatModPoolType.AUGMENT_CRIT_SWITCH,
	}) // temporarily 1.5, not 2

	WeaponAugmentConfigData.set(WeaponAugmentSubType.FieryOvercharge, {
		prettyName: 'Fiery Overcharge',
		description: 'Massive fire damage and ignite potency, but slower charge speed.',
		availableLevel: LEVEL_WORLD_TIER_2,
		modPool: StatModPoolType.AUGMENT_FIERY_OVERCHARGE,
	})

	// WeaponAugmentConfigData.set(WeaponAugmentSubType.PhysicalConversion, {
	// 	prettyName: 'Physical Conversion+',
	// 	description: 'Converts your other damage to Physical damage. (not implemented)',
	// 	availableLevel: LEVEL_WORLD_TIER_2,
	// 	modPool: StatModPoolType.AUGMENT_PHYSICAL_CONVERSION,
	// })
	// WeaponAugmentConfigData.set(WeaponAugmentSubType.FireConversion, {
	// 	prettyName: 'Fire Conversion+',
	// 	description: 'Converts your other damage to Fire damage. (not implemented)',
	// 	availableLevel: LEVEL_WORLD_TIER_2,
	// 	modPool: StatModPoolType.AUGMENT_FIRE_CONVERSION,
	// })
	// WeaponAugmentConfigData.set(WeaponAugmentSubType.IceConversion, {
	// 	prettyName: 'Ice Conversion+',
	// 	description: 'Converts your other damage to Ice damage. (not implemented)',
	// 	availableLevel: LEVEL_WORLD_TIER_2,
	// 	modPool: StatModPoolType.AUGMENT_ICE_CONVERSION,
	// })
	// WeaponAugmentConfigData.set(WeaponAugmentSubType.LightningConversion, {
	// 	prettyName: 'Lightning Conversion+',
	// 	description: 'Converts your other damage to Lightning damage. (not implemented)',
	// 	availableLevel: LEVEL_WORLD_TIER_2,
	// 	modPool: StatModPoolType.AUGMENT_LIGHTNING_CONVERSION,
	// })
	// WeaponAugmentConfigData.set(WeaponAugmentSubType.PoisonConversion, {
	// 	prettyName: 'Poison Conversion+',
	// 	description: 'Converts your other damage to Poison damage. (not implemented)',
	// 	availableLevel: LEVEL_WORLD_TIER_2,
	// 	modPool: StatModPoolType.AUGMENT_POISON_CONVERSION,
	// })

	// trajectory augments below

	//WT1
	WeaponAugmentConfigData.set(WeaponAugmentSubType.Accelerate, {
		prettyName: 'Accelerator',
		description: 'Projectiles gradually gain speed.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.AUGMENT_ACCELERATE_BASE,
	})
	WeaponAugmentConfigData.set(WeaponAugmentSubType.Deccelerate, {
		prettyName: 'Deccelerator',
		description: 'Projectiles gradually lose speed.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.AUGMENT_DECELLERATE_BASE,
	})
	WeaponAugmentConfigData.set(WeaponAugmentSubType.Sine, {
		prettyName: 'Sine',
		description: 'Projectiles move in a sine wave.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.AUGMENT_SINE_BASE,
	})
	WeaponAugmentConfigData.set(WeaponAugmentSubType.Zigzag, {
		prettyName: 'ZigToTheZag',
		description: 'Projectiles zig-zag back and forth.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.AUGMENT_ZIGZAG_BASE,
	})
	WeaponAugmentConfigData.set(WeaponAugmentSubType.StraightBoomerang, {
		prettyName: 'Boomerang',
		description: 'Projectiles go out and come back.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.AUGMENT_STRAIGHT_BOOMERANG_BASE,
	})

	//WT2
	WeaponAugmentConfigData.set(WeaponAugmentSubType.RangeAdjustingAccelerate, {
		prettyName: 'RangeFinder',
		description: 'Projectile speed varies based on how close or far away you aim.',
		availableLevel: LEVEL_WORLD_TIER_1_5,
		modPool: StatModPoolType.AUGMENT_RANGE_ADJUSTING_ACCELERATE_BASE,
	})

	//WT1
	WeaponAugmentConfigData.set(WeaponAugmentSubType.EnergyBooster, {
		prettyName: 'Energetic Reinforcement',
		description: 'A lot more energy, and a bit more damage.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.AUGMENT_ENERGY_BOOSTER,
	})
	WeaponAugmentConfigData.set(WeaponAugmentSubType.ElementalDevotion, {
		prettyName: 'Elemental Devotion',
		description: 'Sacrifice criticals for lots of elemental power.',
		availableLevel: LEVEL_WORLD_TIER_1,
		modPool: StatModPoolType.AUGMENT_ELEMENTAL_DEVOTION_MAJOR,
	})
}

/** All the non-trajectory weapon augments. Available as enchantments. */
export const WeaponAugmentStatSubTypes = [
	WeaponAugmentSubType.CreepingDeath,
	WeaponAugmentSubType.NarrowSpreadExtraProjectiles,
	WeaponAugmentSubType.FastAndWeak,
	WeaponAugmentSubType.SlowAndStrong,
	WeaponAugmentSubType.WideSpreadExtraProjectiles,
	WeaponAugmentSubType.Pierce,
	WeaponAugmentSubType.TightenSpread,
	WeaponAugmentSubType.SplashDamage,
	WeaponAugmentSubType.CritSwitch,
	WeaponAugmentSubType.BasicStatUp,
	WeaponAugmentSubType.ClusterShot,
	WeaponAugmentSubType.QuickCharge,
	WeaponAugmentSubType.DiffusionBeam,
	WeaponAugmentSubType.FocusedBeam,
	WeaponAugmentSubType.StarLaser,
	WeaponAugmentSubType.EnergyBooster,

	// elemental
	WeaponAugmentSubType.Sniper,
	WeaponAugmentSubType.LightningBurst,
	WeaponAugmentSubType.Flamethrower,
	WeaponAugmentSubType.Icebound,
	WeaponAugmentSubType.Taser,
	WeaponAugmentSubType.Debilitator,
	WeaponAugmentSubType.PhysicalUp,
	WeaponAugmentSubType.FireUp,
	WeaponAugmentSubType.IceUp,
	WeaponAugmentSubType.LightningUp,
	WeaponAugmentSubType.PoisonUp,
	WeaponAugmentSubType.FieryOvercharge,
	WeaponAugmentSubType.ElementalDevotion,

	// disabled due to no valid implementation:
	// WeaponAugmentSubType.ChainLightning,
	// WeaponAugmentSubType.PhysicalConversion,
	// WeaponAugmentSubType.FireConversion,
	// WeaponAugmentSubType.IceConversion,
	// WeaponAugmentSubType.LightningConversion,
	// WeaponAugmentSubType.PoisonConversion,
]

/** All the trajectory weapon augments. Unavailable as enchantments. */
export const WeaponAugmentTrajectorySubTypes = [
	WeaponAugmentSubType.RangeAdjustingAccelerate,
	// WeaponAugmentSubType.Sine,
	// WeaponAugmentSubType.Zigzag,
	WeaponAugmentSubType.StraightBoomerang,
]

export const AllWeaponAugmentSubTypes = [...WeaponAugmentTrajectorySubTypes, ...WeaponAugmentStatSubTypes]

export default WeaponAugmentSubType
