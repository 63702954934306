<template>
	<div class="accordion-group">
		<button :class="`accordion-btn-${accordionItem.id}`" class="accordion-btn" @click="accordionClickFn()">
			<div class="accordion-text">
				{{ accordionItem.title }}
				<img src="./assets/faq-drop-down-arrow.png" alt="" :class="{ active: activeAccordion }" />
			</div>
		</button>
		<div :id="`accordion-panel-${accordionItem.id}`" class="accordion-panel">
			<p>
				<span v-html="accordionItem.body"></span>
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'AccordionButton',
	data() {
		return {
			activeAccordion: false,
		}
	},
	props: {
		accordionItem: {
			type: Object,
			required: true,
		},
	},
	methods: {
		accordionClickFn() {
			this.activeAccordion = !this.activeAccordion
			let accordionPanel = document.getElementById(`accordion-panel-${this.accordionItem.id}`)

			if (accordionPanel.style.maxHeight) {
				accordionPanel.style.maxHeight = null
			} else {
				accordionPanel.style.maxHeight = accordionPanel.scrollHeight + 'px'
			}
		},
	},
}
</script>

<style lang="less" scoped>
@import '../../global/fonts/text.less';
@import url('../../global/bg-gradients.less');

.accordion-group {
	width: 100%;
	.accordion-btn {
		display: flex;
		border-image: url('./assets/accordion-btn-background.png') 11 29 11 29 fill / 11px 29px 11px 29px;
		width: 98%;
		padding: 20px 0px;
		margin: 10px 0px 0px 0px;
		text-align: left;
		transition: 250ms;
		cursor: pointer;
		.item-names-and-subheaders();
		font-size: 28px;
		.accordion-text {
			cursor: pointer;
			display: flex;
			width: 100%;
			justify-content: space-between;
			align-items: center;
			padding: 0px 25px;

			img {
				transition: transform 250ms ease-in-out;
				&.active {
					transform: rotate(90deg);
				}
			}
		}
	}
	.accordion-panel {
		.faq-blurb-text();
		font-size: 24px;
		width: 98%;
		overflow: hidden;
		max-height: 0;
		transition: max-height 250ms ease-in-out;
		padding: 0px 25px;
		border-image: url('../game-news/assets/patch-notes-panel-bg.png') 7 7 7 7 fill / 7px 7px 7px 7px;
		
	}
}
</style>
