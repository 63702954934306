import { percentage } from '../../utils/primitive-types'
import { ResourceBarMode } from './resource-bar-mode'
import StatModPoolType from './stat-mod-pool-type'

enum WeaponSubType {
	Spellsword = 1,
	Scythe = 2,
	Staff = 3,
	Wand = 4,
	ArcaneFocus = 5,
	Crossbow = 6,
	// HEY LOOK AT THIS ; DO YOU HAVE TO CHANGE IT?
	MIN_VALUE = Spellsword,
	MAX_VALUE = Crossbow,
}

export const WeaponConfigData: Map<
	WeaponSubType,
	{
		prettyName: string
		modPool: StatModPoolType
	}
> = new Map()
{
	WeaponConfigData.set(WeaponSubType.Spellsword, {
		prettyName: 'Spellsword',
		modPool: StatModPoolType.WEAPON_SPELLSWORD_BASE,
	})
	WeaponConfigData.set(WeaponSubType.Scythe, {
		prettyName: 'Scythe',
		modPool: StatModPoolType.WEAPON_SCYTHE_BASE,
	})
	WeaponConfigData.set(WeaponSubType.Staff, {
		prettyName: 'Staff',
		modPool: StatModPoolType.WEAPON_STAFF_BASE,
	})
	WeaponConfigData.set(WeaponSubType.Wand, {
		prettyName: 'Wand',
		modPool: StatModPoolType.WEAPON_WAND_BASE,
	})
	WeaponConfigData.set(WeaponSubType.ArcaneFocus, {
		prettyName: 'Arcane Focus',
		modPool: StatModPoolType.WEAPON_ARCANE_FOCUS_BASE,
	})
	WeaponConfigData.set(WeaponSubType.Crossbow, {
		prettyName: 'Crossbow',
		modPool: StatModPoolType.WEAPON_CROSSBOW_BASE,
	})
}

const WeaponSubTypePrettyName = new Map<WeaponSubType, string>()
WeaponSubTypePrettyName.set(WeaponSubType.Spellsword, 'spellsword')
WeaponSubTypePrettyName.set(WeaponSubType.Scythe, 'scythe')
WeaponSubTypePrettyName.set(WeaponSubType.Staff, 'staff')
WeaponSubTypePrettyName.set(WeaponSubType.Wand, 'wand')
WeaponSubTypePrettyName.set(WeaponSubType.ArcaneFocus, 'arcane focus')
WeaponSubTypePrettyName.set(WeaponSubType.Crossbow, 'crossbow')

const WeaponSubTypeAssetName = new Map<WeaponSubType, string>()
WeaponSubTypeAssetName.set(WeaponSubType.Spellsword, 'weapon-spellsword')
WeaponSubTypeAssetName.set(WeaponSubType.Scythe, 'weapon-scythe')
WeaponSubTypeAssetName.set(WeaponSubType.Staff, 'weapon-staff')
WeaponSubTypeAssetName.set(WeaponSubType.Wand, 'weapon-wand')
WeaponSubTypeAssetName.set(WeaponSubType.ArcaneFocus, 'weapon-arcane-focus')
WeaponSubTypeAssetName.set(WeaponSubType.Crossbow, 'weapon-crossbow')

const WeaponSubTypeToResourceMap = new Map<WeaponSubType, ResourceBarMode>()
WeaponSubTypeToResourceMap.set(WeaponSubType.Spellsword, ResourceBarMode.ENERGY_COST_SHOT)
WeaponSubTypeToResourceMap.set(WeaponSubType.Scythe, ResourceBarMode.ENERGY_COST_SHOT)
WeaponSubTypeToResourceMap.set(WeaponSubType.Staff, ResourceBarMode.CHARGE_UP_SHOT)
WeaponSubTypeToResourceMap.set(WeaponSubType.Wand, ResourceBarMode.ENERGY_COST_SHOT)
WeaponSubTypeToResourceMap.set(WeaponSubType.ArcaneFocus, ResourceBarMode.ENERGY_COST_SHOT)
WeaponSubTypeToResourceMap.set(WeaponSubType.Crossbow, ResourceBarMode.CHARGE_UP_SHOT)

interface ChargeData {
	MIN_SIZE: percentage
	MAX_SIZE: percentage
	SIZE_PROJECTILE_PENALTY: percentage

	MIN_DAMAGE: percentage
	MAX_DAMAGE: percentage

	MIN_SPREAD: percentage
	MAX_SPREAD: percentage

	MIN_SPEED: percentage
	MAX_SPEED: percentage

	MIN_SPLASH_RADIUS: percentage
	MAX_SPLASH_RADIUS: percentage

	MIN_SPLASH_DAMAGE: percentage
	MAX_SPLASH_DAMAGE: percentage

	MIN_KNOCKBACK: percentage
	MAX_KNOCKBACK: percentage
}

const WeaponSubTypeChargeData = new Map<WeaponSubType, ChargeData>()

const StaffChargeData: ChargeData = {
	MIN_SIZE: 0.5,
	MAX_SIZE: 2,
	SIZE_PROJECTILE_PENALTY: 0.1,

	MIN_DAMAGE: 0.2,
	MAX_DAMAGE: 2.0,

	MIN_SPREAD: 1,
	MAX_SPREAD: 1,

	MIN_SPEED: 1,
	MAX_SPEED: 1.3,

	MIN_SPLASH_RADIUS: 0.5,
	MAX_SPLASH_RADIUS: 1.5,

	MIN_SPLASH_DAMAGE: 0.2,
	MAX_SPLASH_DAMAGE: 2.0,

	MIN_KNOCKBACK: 1,
	MAX_KNOCKBACK: 3,
}

const CrossbowChargeData: ChargeData = {
	MIN_SIZE: 0.5,
	MAX_SIZE: 1.6,
	SIZE_PROJECTILE_PENALTY: 0.09,

	MIN_DAMAGE: 0.2,
	MAX_DAMAGE: 2.25,

	MIN_SPREAD: 1,
	MAX_SPREAD: 0.2,

	MIN_SPEED: 0.7,
	MAX_SPEED: 1.3,

	MIN_SPLASH_RADIUS: 1,
	MAX_SPLASH_RADIUS: 1,

	MIN_SPLASH_DAMAGE: 0.2,
	MAX_SPLASH_DAMAGE: 2.25,

	MIN_KNOCKBACK: 1,
	MAX_KNOCKBACK: 4,
}

WeaponSubTypeChargeData.set(WeaponSubType.Staff, StaffChargeData)
WeaponSubTypeChargeData.set(WeaponSubType.Crossbow, CrossbowChargeData)

export default WeaponSubType
export { WeaponSubType, WeaponSubTypePrettyName, WeaponSubTypeChargeData, WeaponSubTypeAssetName, WeaponSubTypeToResourceMap }
