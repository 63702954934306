import { BuffIdentifier } from '../shared/buff.shared'
import { timeInMilliseconds } from '../../utils/primitive-types'
import { InstancedPer, StackStyle } from './buff-enums'
import { ClientBuff } from '../client/buff.client'
import { ClientPlayer } from '../../player/client/player.client'

/** Immutable data definition class describing the properties of a given buff, used by actual Buff instances. */
export class BuffDefinition {
	identifier: BuffIdentifier

	/** Length of duration when buff is first applied. Can be overridden by apply(). */
	duration: timeInMilliseconds
	/** Should buff last forever or expire? */
	lastsForever?: boolean = false
	/** Should this buff create an instance for every different owner, or a single instance? */
	instancedPerOwner?: InstancedPer = InstancedPer.None
	/** Number of stacks to apply when buff is first applied. Can be overridden by apply(). */
	startingStacks: number = 1

	/** Stacking behavior for the buff. */
	stackStyle: StackStyle
	/** Number of stacks to apply when buff is reapplied. Can be overridden by apply(). */
	reapplyStacks?: number
	/** Length of duration to add/emplace when buff is reapplied. Can be overridden by apply(). */
	reapplyDuration?: timeInMilliseconds
	/** Maximum length that duration can get to if applied numerous times. (only functions/makes sense for StackStyle.IncreaseDuration) */
	capDuration?: timeInMilliseconds

	/** Whether the buff should be communicated to the player's client. */
	showToClient: boolean

	/** Time between firing the tickFn. */
	tickInterval?: timeInMilliseconds

	/** Whether or not the buff should be predicted by the client. */
	cspEnabled?: boolean = false

	// These are server-side functions
	// They are set only on the server, 'server-buff-functions.ts'
	/** Called before the buff is applied, checks if this buff can be applied to the entity (currently not called for reapply) */
	canApplyFn?: (entity: any) => boolean
	// /** Called at the tickInterval, or not at all. */
	tickFn?: (buff: any) => void
	// /** Called right after the buff is successfully applied. Useful for stateful buffs who need initialization. */
	applyFn?: (buff: any) => void
	// /** Called when the buff wears off entirely. */
	wearOffFn?: (buff: any) => void
	// /** Called when the buff's stacks are updated, either through a re-apply(), or from rolling stacks wearing off. */
	updateStacksFn?: (buff: any, oldStacks: number, newStacks: number) => void

	cspCanApplyFn?: (entity: ClientPlayer) => boolean
	cspTickFn?: (buff: ClientBuff) => void
	cspApplyFn?: (buff: ClientBuff) => void
	cspWearOffFn?: (buff: ClientBuff) => void
	cspUpdateStacksFn?: (buff: ClientBuff, oldStacks: number, newStacks: number) => void

	constructor(config: BuffDefinition) {
		this.identifier = config.identifier
		this.duration = config.duration
		this.lastsForever = config.lastsForever
		this.instancedPerOwner = config.instancedPerOwner
		this.stackStyle = config.stackStyle
		this.startingStacks = config.startingStacks
		this.reapplyStacks = config.reapplyStacks
		this.reapplyDuration = config.reapplyDuration
		this.capDuration = config.capDuration
		this.showToClient = config.showToClient
		this.tickInterval = config.tickInterval
		this.cspEnabled = config.cspEnabled
		// this.tickFn = config.tickFn
		// this.applyFn = config.applyFn
		// this.wearOffFn = config.wearOffFn
		// this.updateStacksFn = config.updateStacksFn
		// this.canApplyFn = config.canApplyFn
		this.cspTickFn = config.cspTickFn
		this.cspApplyFn = config.cspApplyFn
		this.cspWearOffFn = config.cspWearOffFn
		this.cspUpdateStacksFn = config.cspUpdateStacksFn
		this.cspCanApplyFn = config.cspCanApplyFn
	}
}
