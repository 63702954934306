import { Container, Sprite } from 'pixi.js'
import Renderer from '../../engine/client/graphics/renderer'

const ARROW_TO_PLAYER_X_DIST = 350 // the default distance from the player to the arrow
const ARROW_TO_PLAYER_X_DIST_MAX = 550 // the default distance from the player to the arrow
const ARROW_TO_PLAYER_X_DIST_SCALE = 0.25 // the multiplier for the arrow distance relative to the actual distance
const ARROW_SHRINK_DIST = 100 // the distance the arrow has to be from the target before the X dist starts shrinking
const ONSCREEN_DIST = 800 // the distance at which the arrow is no longer necessary

const ARROW_TO_PLAYER_Y_OFFSET = -75 // moves the arrow up so if it's pointing right, the arrow is the same height as the player

const HALF_PI = Math.PI / 2

export default class PartyArrows {
	static getInstance() {
		return PartyArrows.instance
	}

	static createInstance() {
		PartyArrows.instance = new PartyArrows()
	}

	parentContainer: Container
	arrowSprites: Sprite[] = []

	xDestinations: number[] = []
	yDestinations: number[] = []

	isShowing = false
	private static instance: PartyArrows

	private constructor() {
		this.parentContainer = new Container()

		if (!this.isShowing) {
			this.parentContainer.visible = false
		}

		Renderer.getInstance().fgRenderer.addChild(this.parentContainer)
	}

	updateDestinationPositions(xDestinations: number[], yDestinations: number[]) {
		if (xDestinations.length !== yDestinations.length) {
			console.error(`Mismatch between X and Y values when setting party arrow destinations`)
			return
		}

		const prevNumber = this.xDestinations.length
		const newNumber = xDestinations.length

		// Add new arrow sprites if necessary
		for (let i = this.arrowSprites.length; i < newNumber; ++i) {
			this.arrowSprites.push(Sprite.from('party_arrow'))

			console.log(this.arrowSprites[i])

			this.arrowSprites[i].width = 30
			this.arrowSprites[i].height = 52

			this.arrowSprites[i].anchor.x = 0.5
			this.arrowSprites[i].anchor.y = 0.5

			this.parentContainer.addChild(this.arrowSprites[i])
			this.arrowSprites[i].x = ARROW_TO_PLAYER_X_DIST
			this.arrowSprites[i].rotation = HALF_PI
		}

		for (let i = 0; i < newNumber; ++i) {
			this.arrowSprites[i].visible = true
		}
		for (let i = newNumber; i < prevNumber; ++i) {
			this.arrowSprites[i].visible = false
		}

		this.xDestinations = xDestinations
		this.yDestinations = yDestinations
	}

	updatePosition(xPos: number, yPos: number) {
		this.parentContainer.x = xPos
		this.parentContainer.y = yPos

		if (this.isShowing) {
			this.updateArrows()
		}
	}

	updateArrows() {
		for (let i = 0; i < Math.min(this.xDestinations.length, this.arrowSprites.length); ++i) {
			const xDiff = this.xDestinations[i] - this.parentContainer.x
			const yDiff = this.yDestinations[i] - (this.parentContainer.y - ARROW_TO_PLAYER_Y_OFFSET)

			const angle = Math.atan2(yDiff, xDiff)

			this.arrowSprites[i].rotation = angle + HALF_PI

			const distToTarget = Math.sqrt(xDiff * xDiff + yDiff * yDiff)
			const newArrowDist = Math.clamp(distToTarget - ARROW_SHRINK_DIST + Math.sqrt(Math.max(distToTarget - ARROW_SHRINK_DIST, 0)) * ARROW_TO_PLAYER_X_DIST_SCALE, ARROW_TO_PLAYER_X_DIST, ARROW_TO_PLAYER_X_DIST_MAX)

			if (distToTarget >= ONSCREEN_DIST) {
				this.arrowSprites[i].visible = true
				this.arrowSprites[i].x = newArrowDist * Math.cos(angle)
				this.arrowSprites[i].y = newArrowDist * Math.sin(angle)
			} else {
				this.arrowSprites[i].visible = false
			}
		}
	}

	setIsShowing(isShowing) {
		if (!this.isShowing && isShowing) {
			this.updateArrows()
		}

		this.isShowing = isShowing
		this.parentContainer.visible = isShowing
	}
}
