import logger from '../../utils/client-logger'
import { highResolutionTimestamp } from '../../utils/debug'
import { filter, map } from 'lodash'

// TODO2: Is this file necessary? I guess keep it in case we add more functionality to the module
export const buffAndDebuffUIModule = () => {
	logger.debug('Initializing Buff and Debuffstore module...')
	return {
		namespaced: true,
		state: {
			buffs: [],
			debuffs: [],
		},
		getters: {
			getBuffs(state) {
				return state.buffs
			},
			getDebuffs(state) {
				return state.debuffs
			},
		},
		actions: {
			addDebuffToState(context, payload) {
				const { state } = context
				const statusEffect = payload
				const statusEffectId = payload.id
				const debuffs = state.debuffs
				const indexValue = debuffs.findIndex((debuff) => debuff.id === statusEffectId)

				if (debuffs.some((debuff) => debuff.id === statusEffectId)) {
					const startTime = highResolutionTimestamp()
					state.debuffs[indexValue].appliedAtTime = startTime
				} else {
					state.debuffs.push(statusEffect)
				}
			},
		},
		mutations: {
			emptyBuffStates(state) {
				state.buffs = []
				state.debuffs = []
			},
			addStatusEffectToState(state, payload) {
				const statusEffect = payload.statusEffect
				statusEffect.id = payload.statusEffectId
				if (payload.type === 'buff') {
					const now = Date.now()
					const timeOffset = now - statusEffect.appliedAtTime
					statusEffect.appliedAtTime += timeOffset
					statusEffect.expiresAtTime += timeOffset
					state.buffs.push(statusEffect)
				} else {
					state.debuffs.push(statusEffect)
				}
			},
			removeStatusEffect(state, { id, type }) {
				const filterById = (effect) => effect.id !== id
				if (type === 'buff') {
					state.buffs = filter(state.buffs, filterById)
				} else if (type === 'debuff') {
					state.debuffs = filter(state.debuffs, filterById)
				}
			},
			updateStatusEffect(state, { statusEffectId, statusEffect, type }) {
				// If the element matches the id passed in, replace it, otherwise
				// return the original element.
				const now = Date.now()
				const timeOffset = now - statusEffect.appliedAtTime
				// console.log(`  [${statusEffect.buffIdentifier}]`)
				// console.log(`BEFORE applied: ${statusEffect.appliedAtTime}, expires: ${statusEffect.expiresAtTime}, now: ${now}`)
				statusEffect.appliedAtTime += timeOffset
				statusEffect.expiresAtTime += timeOffset
				// console.log(`AFTER  applied: ${statusEffect.appliedAtTime}, expires: ${statusEffect.expiresAtTime}, now: ${now}`)

				const replaceElem = (elem) => {
					if (elem.id === statusEffectId) {
						statusEffect.id = statusEffectId
						statusEffect.appliedAtTime = elem.appliedAtTime // take the existing buff's applied time
						statusEffect.appliedAtTime += timeOffset
						return statusEffect
					} else {
						return elem
					}
				}

				if (type === 'buff') {
					state.buffs = map(state.buffs, replaceElem)
				} else {
					state.debuffs = map(state.debuffs, replaceElem)
				}
			},
		},
	}
}
