import { ColorOverlayFilter, TwistFilter } from 'pixi-filters'
import { ClientEnemy } from '../../ai/client/enemy.client'
import Renderer from '../../engine/client/graphics/renderer'
import DevToolsManager from '../../ui/dev-tools/dev-tools-manager'
import { mapToRange } from '../../utils/math'
import { simpleAnimation_addAnimation, simpleAnimation_removeAnimations } from '../../utils/simple-animation-system'
import { ClientPlayer } from './player.client'

let testPlayer: ClientPlayer = null

export function setTestFilterPlayer(player) {
	testPlayer = player
}

const teleportFilterConfig = {
	blinkDuration: 1,
	whiteFlashTime: 0.5,
	blinkInTime: 1.5,
	whiteOffTime: 2.3,
	restoreTime: 2.5,
	radiusSpeed: 200,
	angleSpeed: 10,
	shrinkStartTime: 0.5,
	test: () => {
		createTeleportFilter(testPlayer)
	},
}

export function createTeleportFilter(entity: ClientPlayer | ClientEnemy) {
	const twistScreenFilter = new TwistFilter()
	const whitePlayerFilter = new ColorOverlayFilter()

	const { radiusSpeed, angleSpeed, blinkDuration, shrinkStartTime, restoreTime, whiteFlashTime, blinkInTime, whiteOffTime } = teleportFilterConfig
	if (entity.model) {
		entity.model.scale.x = 1
		entity.model.scale.y = 1
	}

	const twistFollowPlayer = () => {
		simpleAnimation_addAnimation(twistScreenFilter, (t) => {
			const screenCoords = Renderer.getInstance().worldCoordsToScreenCoords(entity.x, entity.y)
			twistScreenFilter.offset.x = screenCoords.x
			twistScreenFilter.offset.y = screenCoords.y - 50
			return 0
		})
	}

	twistFollowPlayer()
	simpleAnimation_addAnimation(twistScreenFilter, (t) => {
		if (entity.model) {
			twistScreenFilter.radius = t * radiusSpeed * inOutCurve(t, blinkDuration)
			twistScreenFilter.angle = t * angleSpeed * inOutCurve(t, blinkDuration)

			entity.model.scale.x = mapToRange(t, shrinkStartTime, blinkDuration, 1, 0, true)
			entity.model.scale.y = mapToRange(t, shrinkStartTime, blinkDuration, 1, 0, true)
			return 0
		}
	})

	// start twist - time 0
	Renderer.getInstance().stage.filters.push(twistScreenFilter)

	// white flash - time 0.5
	setTimeout(() => {
		if (entity.model) {
			whitePlayerFilter.color = 0xffffff
			entity.model.filters.push(whitePlayerFilter)
		}
	}, whiteFlashTime * 1000)

	// teleport in - time 1
	setTimeout(() => {
		simpleAnimation_removeAnimations(twistScreenFilter)

		if (entity.model) {
			twistFollowPlayer()
			simpleAnimation_addAnimation(twistScreenFilter, (t) => {
				if (entity.model) {
					twistScreenFilter.radius = t * radiusSpeed * inOutCurve(t, blinkDuration)
					twistScreenFilter.angle = t * angleSpeed * inOutCurve(t, blinkDuration)
					entity.model.scale.x = mapToRange(t, shrinkStartTime, blinkDuration, 0, 1, true)
					entity.model.scale.y = mapToRange(t, shrinkStartTime, blinkDuration, 0, 1, true)
				}
				return 0
			})
		}
	}, blinkInTime * 1000)

	// white off - time 2.3
	setTimeout(() => {
		if (entity.model) {
			entity.model.filters.remove(whitePlayerFilter)
		}
	}, whiteOffTime * 1000)

	// end clean up - time 2.5
	setTimeout(() => {
		simpleAnimation_removeAnimations(twistScreenFilter)
		Renderer.getInstance().stage.filters.remove(twistScreenFilter)
		if (entity.model) {
			entity.model.filters.remove(whitePlayerFilter)
			entity.model.scale.x = 1
			entity.model.scale.y = 1
		}
	}, restoreTime * 1000)
}

function inOutCurve(t: number, duration: number) {
	return Math.sin((t * Math.PI) / duration)
}

if (process.env.NODE_ENV !== 'beta' && process.env.NODE_ENV !== 'loot-prod') {
	DevToolsManager.getInstance().addObjectByName('teleportFilterConfig', teleportFilterConfig)
	//DevToolsManager.getInstance().setDebugObject(teleportFilterConfig)
}
