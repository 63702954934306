import logger from '../../utils/client-logger'
import { PlayerSkinType, PlayerSkinValues } from '../../models-animations/shared/spine-config'
import { NengiClient } from '../../engine/client/nengi-client'
import OpenPrizeCommand from '../../items/shared/open-prize-command'
import SetSkinCommand, { SKIN_SLOT, SKIN_SLOT_TO_PREFIX, WEAPON_SKIN_PREFIXES } from '../../player/shared/set-skin.shared'
import { UI } from '../ui'
import { showGenericYesNoUI } from '../state/generic-yes-no.ui-state'
import { petBaseTypeToName, PetSkinSpineNames, PetSubTypePrettyNames, petSubTypeToBaseType, PetSubTypeValues, PetTypeName } from '../../loot/shared/prize-sub-type'
import { anonymousSetSkinsRequest, setSkinsRequest } from '../../utils/api/atlas-requests.client'
import { WeaponSkinPrettyNames, WeaponSkinSubTypeValues } from '../../loot/shared/weapon-skin-sub-type'
import { showGenericInfoPromptUI } from './generic-information-prompt.ui-state'
import { SkinInformationByIdentifier } from '../../loot/shared/player-skin-sub-type'

import petPortraitsJson from '../reusable-components/assets/pet-portraits.json'
import faceCosmeticsJson from '../reusable-components/assets/face-cosmetics-atlas.json'
import backCosmeticsJson from '../reusable-components/assets/back-cosmetics-atlas.json'
import playerPortraitsJson from '../reusable-components/assets/player-skins-atlas.json'
import pfxTrailsJson from '../reusable-components/assets/pfx-trails-atlas.json'
import weaponSkinsJson from '../reusable-components/assets/weapon-skins-atlas.json'
import { debugConfig } from '../../engine/client/debug-config'
import { EmotePrettyNames, EmoteMtxBundles } from '../../chat/shared/emote-enums'

export const FANCY_SKIN_PREVIEW = { instance: null }

export enum FilterType {
	PFX = 'Trail',
	PLAYER_SKIN = 'Player Skin',
	ATTACHMENT = 'Attachment',
	PET_SKIN = 'Pet Skin',
	BACK = 'Back',
	FACE = 'Face',
	WAND = 'Wand',
	STAFF = 'Staff',
	SWORD = 'Sword',
	ARCANE = 'Arcane',
	CROSSBOW = 'Crossbow',
	SCYTHE = 'Scythe',
	ALL_WEAPONS = 'Weapon Skins',
	EMOTE = 'Emote',
}

export const SKIN_SLOT_TO_FILTER = {
	[SKIN_SLOT.PLAYER_MAIN]: FilterType.PLAYER_SKIN,
	[SKIN_SLOT.PLAYER_BACK]: FilterType.BACK,
	[SKIN_SLOT.PLAYER_FACE]: FilterType.FACE,
	[SKIN_SLOT.PLAYER_FOOTPRINT]: FilterType.PFX,
	[SKIN_SLOT.PET_MAIN]: FilterType.PET_SKIN,
	[SKIN_SLOT.WEAPON_ARCANE]: FilterType.ARCANE,
	[SKIN_SLOT.WEAPON_CROSSBOW]: FilterType.CROSSBOW,
	[SKIN_SLOT.WEAPON_SCYTHE]: FilterType.SCYTHE,
	[SKIN_SLOT.WEAPON_STAFF]: FilterType.STAFF,
	[SKIN_SLOT.WEAPON_SWORD]: FilterType.SWORD,
	[SKIN_SLOT.WEAPON_WAND]: FilterType.WAND,
}

export enum MtxTypes {
	PLAYER = 'skin',
	PET = 'pet',
	WEAPON = 'weapon',
	CONSUMABLE = 'consumable',
	BUNDLE ='bundle',
}

export const cosmeticsModule = () => {
	logger.debug('Initializing cosmetics module')
	return {
		namespaced: true,
		state: {
			ownedCosmetics: [],
			equippedCosmetics: {
				mainSkin: '',
				petSkin: '',
				back: '',
				face: '',
				pfx: '',
				wand: {
					primary: '',
					secondary: '',
				},
				staff: {
					primary: '',
					secondary: '',
				},
				sword: {
					primary: '',
					secondary: '',
				},
				arcane: {
					primary: '',
					secondary: '',
				},
				crossbow: {
					primary: '',
					secondary: '',
				},
				scythe: {
					primary: '',
					secondary: '',
				},
			},
			selectedMainSkin: '',
			selectedPetSkin: 0,
			selectedBack: '',
			selectedFace: '',
			selectedPfx: '',
			selectedWand: {
				primary: null,
				secondary: null,
			},
			selectedStaff: {
				primary: null,
				secondary: null,
			},
			selectedSword: {
				primary: null,
				secondary: null,
			},
			selectedArcane: {
				primary: null,
				secondary: null,
			},
			selectedCrossbow: {
				primary: null,
				secondary: null,
			},
			selectedScythe: {
				primary: null,
				secondary: null,
			},
			selectedWeaponsNames: ['selectedWand', 'selectedStaff', 'selectedCrossbow', 'selectedArcane', 'selectedSword', 'selectedScythe'],
			weaponsNames: ['wand', 'staff', 'crossbow', 'arcane', 'sword', 'scythe'],
			weaponSkinSlots: [SKIN_SLOT.WEAPON_WAND, SKIN_SLOT.WEAPON_STAFF, SKIN_SLOT.WEAPON_CROSSBOW, SKIN_SLOT.WEAPON_ARCANE, SKIN_SLOT.WEAPON_SWORD, SKIN_SLOT.WEAPON_SCYTHE]
		},
		getters: {
			mainSkin(state) {
				if (state.equippedCosmetics.mainSkin) {
					return state.equippedCosmetics.mainSkin
				}

				return PlayerSkinType.DWARF
			},
			getSelectedMainSkin(state) {
				return state.selectedMainSkin
			},
			getSelectedPetSkin(state) {
				return state.selectedPetSkin
			},
			getSelectedBack(state) {
				return state.selectedBack
			},
			getSelectedFace(state) {
				return state.selectedFace
			},
			getSelectedPfx(state) {
				return state.selectedPfx
			},
		},
		mutations: {
			setOwnedCosmetics(state, owned) {
				const purchases = []

				if (owned) {
					const ownedCosmetics = Array.isArray(owned) ? owned : JSON.parse(owned)

					for (const c of ownedCosmetics) {
						const skinInfo = SkinInformationByIdentifier.get(c)
						if (skinInfo) {
							const filter = SKIN_SLOT_TO_FILTER[skinInfo.skinSlot]
							purchases.push({ source: 'owned', identifier: c, cosmeticType: filter, skinSlot: skinInfo.skinSlot })
							continue
						}

						if (PlayerSkinValues.includes(c)) {
							purchases.push({ source: 'owned', identifier: c, cosmeticType: FilterType.PLAYER_SKIN, skinSlot: SKIN_SLOT.PLAYER_MAIN })
						} else {
							const split = c.split('-')
							let prefix = c
							if (split.length > 0) {
								prefix = split[0] + '-'
							}

							if (prefix === SKIN_SLOT_TO_PREFIX[SKIN_SLOT.PET_MAIN]) {
								const petSubType = Number.parseInt(c.substring(SKIN_SLOT_TO_PREFIX[SKIN_SLOT.PET_MAIN].length), 10)
								if (PetSubTypeValues.includes(petSubType)) {
									purchases.push({ source: 'owned', identifier: petSubType, cosmeticType: FilterType.PET_SKIN, skinSlot: SKIN_SLOT.PET_MAIN })
								}
							} else if (prefix === `emote-`) {
								const emoteType = Number.parseInt(c.substring(6), 10)
								purchases.push({source: 'owned', identifier: emoteType, cosmeticType: FilterType.EMOTE, skinSlot: ''})

								this.commit(`emotes/addOwnedEmote`, emoteType)
							} else if (WEAPON_SKIN_PREFIXES.includes(prefix)) {
								let skinSlot
								for (const key in SKIN_SLOT_TO_PREFIX) {
									const value = SKIN_SLOT_TO_PREFIX[key]
									if (value === prefix) {
										skinSlot = key
										break
									}
								}

								if (skinSlot) {
									const weaponSkinType = Number.parseInt(c.substring(prefix.length), 10)
									if (WeaponSkinSubTypeValues.includes(weaponSkinType)) {
										purchases.push({ source: 'owned', identifier: weaponSkinType, cosmeticType: FilterType.ALL_WEAPONS, skinSlot })
									}
								} else {
									console.error("No skin slot match for prefix?? " + prefix)
								}
							}
						}
					}
				}

				// Default skins
				purchases.push({ source: 'free', identifier: PlayerSkinType.DWARF, cosmeticType: FilterType.PLAYER_SKIN, skinSlot: SKIN_SLOT.PLAYER_MAIN })
				purchases.push({ source: 'free', identifier: PlayerSkinType.RANGER, cosmeticType: FilterType.PLAYER_SKIN, skinSlot: SKIN_SLOT.PLAYER_MAIN })
				purchases.push({ source: 'free', identifier: PlayerSkinType.WIZARD, cosmeticType: FilterType.PLAYER_SKIN, skinSlot: SKIN_SLOT.PLAYER_MAIN })
				purchases.push({ source: 'free', identifier: PlayerSkinType.KNIGHT, cosmeticType: FilterType.PLAYER_SKIN, skinSlot: SKIN_SLOT.PLAYER_MAIN })

				// hacky for empty/unequip
				const hackyShitForUnequip = [SKIN_SLOT.PET_MAIN, SKIN_SLOT.PLAYER_BACK, SKIN_SLOT.PLAYER_FACE, SKIN_SLOT.PLAYER_FOOTPRINT]
				for (const type of hackyShitForUnequip) {
					purchases.push({ source: 'free', identifier: 0, cosmeticType: SKIN_SLOT_TO_FILTER[type], skinSlot: type })
				}

				state.ownedCosmetics = purchases

				this.commit('cosmetics/sortOwnedCosmetics')

			},
			setEquippedCosmetics(state, equippedCosmetics) {
				for (const skin of equippedCosmetics) {
					switch (skin.slotId) {
						case SKIN_SLOT.PLAYER_MAIN:
							state.equippedCosmetics.mainSkin = skin.selectedSkin
							break
						case SKIN_SLOT.PET_MAIN:
							state.equippedCosmetics.petSkin = Number.parseInt(skin.selectedSkin, 10)
							state.selectedPetSkin = state.equippedCosmetics.petSkin
							break
						case SKIN_SLOT.PLAYER_BACK:
							state.equippedCosmetics.back = skin.selectedSkin
							state.selectedBack = skin.selectedSkin
							break
						case SKIN_SLOT.PLAYER_FACE:
							state.equippedCosmetics.face = skin.selectedSkin
							state.selectedFace = skin.selectedSkin
							break
						case SKIN_SLOT.PLAYER_FOOTPRINT:
							state.equippedCosmetics.pfx = skin.selectedSkin
							state.selectedPfx = skin.selectedSkin
							break
						default:
							if (skin.selectedSkin === '' || skin.selectedSkin === '0') {
								break
							}
							// I'm zonked please end my suffering
							const skinObj = { source: 'owned', identifier: Number.parseInt(skin.selectedSkin, 10), cosmeticType: FilterType.ALL_WEAPONS, skinSlot: skin.slotId }
							switch (skin.slotId) {
								case SKIN_SLOT.WEAPON_ARCANE:
									state.equippedCosmetics.arcane.primary = skin.selectedSkin
									state.selectedArcane.primary = skinObj
									break
								case SKIN_SLOT.WEAPON_ARCANE_SECONDARY:
									state.equippedCosmetics.arcane.secondary = skin.selectedSkin
									skinObj.skinSlot = SKIN_SLOT.WEAPON_ARCANE
									state.selectedArcane.secondary = skinObj
									break
								case SKIN_SLOT.WEAPON_STAFF:
									state.equippedCosmetics.staff.primary = skin.selectedSkin
									state.selectedStaff.primary = skinObj
									break
								case SKIN_SLOT.WEAPON_STAFF_SECONDARY:
									state.equippedCosmetics.staff.secondary = skin.selectedSkin
									skinObj.skinSlot = SKIN_SLOT.WEAPON_STAFF
									state.selectedStaff.secondary = skinObj
									break
								case SKIN_SLOT.WEAPON_SWORD:
									state.equippedCosmetics.sword.primary = skin.selectedSkin
									state.selectedSword.primary = skinObj
									break
								case SKIN_SLOT.WEAPON_SWORD_SECONDARY:
									state.equippedCosmetics.sword.secondary = skin.selectedSkin
									skinObj.skinSlot = SKIN_SLOT.WEAPON_SWORD
									state.selectedSword.secondary = skinObj
									break
								case SKIN_SLOT.WEAPON_CROSSBOW:
									state.equippedCosmetics.crossbow.primary = skin.selectedSkin
									state.selectedCrossbow.primary = skinObj
									break
								case SKIN_SLOT.WEAPON_CROSSBOW_SECONDARY:
									state.equippedCosmetics.crossbow.secondary = skin.selectedSkin
									skinObj.skinSlot = SKIN_SLOT.WEAPON_CROSSBOW
									state.selectedCrossbow.secondary = skinObj
									break
								case SKIN_SLOT.WEAPON_SCYTHE:
									state.equippedCosmetics.scythe.primary = skin.selectedSkin
									state.selectedScythe.primary = skinObj
									break
								case SKIN_SLOT.WEAPON_SCYTHE_SECONDARY:
									state.equippedCosmetics.scythe.secondary = skin.selectedSkin
									skinObj.skinSlot = SKIN_SLOT.WEAPON_SCYTHE
									state.selectedScythe.secondary = skinObj
									break
								case SKIN_SLOT.WEAPON_WAND:
									state.equippedCosmetics.wand.primary = skin.selectedSkin
									state.selectedWand.primary = skinObj
									break
								case SKIN_SLOT.WEAPON_WAND_SECONDARY:
									state.equippedCosmetics.wand.secondary = skin.selectedSkin
									skinObj.skinSlot = SKIN_SLOT.WEAPON_WAND
									state.selectedWand.secondary = skinObj
									break
							}
							break
					}
				}
			},
			setEquippedMainSkin(state, mainSkin) {
				state.equippedCosmetics.mainSkin = mainSkin
				const ihatevue = state.equippedCosmetics
				state.equippedCosmetics = null
				state.equippedCosmetics = ihatevue
			},
			setEquippedPetSkin(state, petSkin) {
				state.equippedCosmetics.petSkin = petSkin
				const ihatevue = state.equippedCosmetics
				state.equippedCosmetics = null
				state.equippedCosmetics = ihatevue
			},
			setEquippedBack(state, back) {
				state.equippedCosmetics.back = back
				const ihatevue = state.equippedCosmetics
				state.equippedCosmetics = null
				state.equippedCosmetics = ihatevue
			},
			setEquippedFace(state, face) {
				state.equippedCosmetics.face = face
				const ihatevue = state.equippedCosmetics
				state.equippedCosmetics = null
				state.equippedCosmetics = ihatevue
			},
			setEquippedPfx(state, pfx) {
				state.equippedCosmetics.pfx = pfx
				const ihatevue = state.equippedCosmetics
				state.equippedCosmetics = null
				state.equippedCosmetics = ihatevue
			},
			setEquippedWand(state, { wand, isSecondary }) {
				const ihatevue = state.equippedCosmetics

				if (isSecondary) {
					state.equippedCosmetics.wand.primary = wand
				} else {
					state.equippedCosmetics.wand.secondary = wand
				}

				state.equippedCosmetics = null
				state.equippedCosmetics = ihatevue
			},
			setEquippedStaff(state, { staff, isSecondary }) {
				const ihatevue = state.equippedCosmetics

				if (isSecondary) {
					state.equippedCosmetics.staff.primary = staff
				} else {
					state.equippedCosmetics.staff.secondary = staff
				}

				state.equippedCosmetics = null
				state.equippedCosmetics = ihatevue
			},
			setEquippedSword(state, { sword, isSecondary }) {
				const ihatevue = state.equippedCosmetics

				if (isSecondary) {
					state.equippedCosmetics.sword.primary = sword
				} else {
					state.equippedCosmetics.sword.secondary = sword
				}

				state.equippedCosmetics = null
				state.equippedCosmetics = ihatevue
			},
			setEquippedArcane(state, { arcane, isSecondary }) {
				const ihatevue = state.equippedCosmetics

				if (isSecondary) {
					state.equippedCosmetics.arcane.primary = arcane
				} else {
					state.equippedCosmetics.arcane.secondary = arcane
				}

				state.equippedCosmetics = null
				state.equippedCosmetics = ihatevue
			},
			setEquippedCrossbow(state, { crossbow, isSecondary }) {
				const ihatevue = state.equippedCosmetics

				if (isSecondary) {
					state.equippedCosmetics.crossbow.primary = crossbow
				} else {
					state.equippedCosmetics.crossbow.secondary = crossbow
				}

				state.equippedCosmetics = null
				state.equippedCosmetics = ihatevue
			},
			setEquippedScythe(state, { scythe, isSecondary }) {
				const ihatevue = state.equippedCosmetics

				if (isSecondary) {
					state.equippedCosmetics.scythe.primary = scythe
				} else {
					state.equippedCosmetics.scythe.secondary = scythe
				}

				state.equippedCosmetics = null
				state.equippedCosmetics = ihatevue
			},
			setEquippedCosmetic(state, { cosmeticName, skinSlot }) {
				if (cosmeticName !== '' && cosmeticName !== '0') {
					const cosmeticInfo = SkinInformationByIdentifier.get(cosmeticName)
					if (cosmeticInfo) {
						skinSlot = cosmeticInfo.skinSlot
					}
				}

				switch (skinSlot) {
					case SKIN_SLOT.PLAYER_BACK:
						this.commit('cosmetics/setEquippedBack', cosmeticName)
						break
					case SKIN_SLOT.PLAYER_FACE:
						this.commit('cosmetics/setEquippedFace', cosmeticName)
						break
					case SKIN_SLOT.PLAYER_FOOTPRINT:
						this.commit('cosmetics/setEquippedPfx', cosmeticName)
						break
					case SKIN_SLOT.WEAPON_ARCANE:
						this.commit('cosmetics/setEquippedArcane', { arcane: cosmeticName })
						break
					case SKIN_SLOT.WEAPON_ARCANE_SECONDARY:
						this.commit('cosmetics/setEquippedArcane', { arcane: cosmeticName, isSecondary: true })
						break
					case SKIN_SLOT.WEAPON_CROSSBOW:
						this.commit('cosmetics/setEquippedCrossbow', { crossbow: cosmeticName })
						break
					case SKIN_SLOT.WEAPON_CROSSBOW_SECONDARY:
						this.commit('cosmetics/setEquippedCrossbow', { crossbow: cosmeticName, isSecondary: true })
						break
					case SKIN_SLOT.WEAPON_SCYTHE:
						this.commit('cosmetics/setEquippedScythe', { scythe: cosmeticName })
						break
					case SKIN_SLOT.WEAPON_SCYTHE_SECONDARY:
						this.commit('cosmetics/setEquippedScythe', { scythe: cosmeticName, isSecondary: true })
						break
					case SKIN_SLOT.WEAPON_STAFF:
						this.commit('cosmetics/setEquippedStaff', { staff: cosmeticName })
						break
					case SKIN_SLOT.WEAPON_STAFF_SECONDARY:
						this.commit('cosmetics/setEquippedStaff', { staff: cosmeticName, isSecondary: true })
						break
					case SKIN_SLOT.WEAPON_SWORD:
						this.commit('cosmetics/setEquippedSword', { sword: cosmeticName })
						break
					case SKIN_SLOT.WEAPON_SWORD_SECONDARY:
						this.commit('cosmetics/setEquippedSword', { sword: cosmeticName, isSecondary: true })
						break
					case SKIN_SLOT.WEAPON_WAND:
						this.commit('cosmetics/setEquippedWand', { wand: cosmeticName })
						break
					case SKIN_SLOT.WEAPON_WAND_SECONDARY:
						this.commit('cosmetics/setEquippedWand', { wand: cosmeticName, isSecondary: true })
						break
				}
			},
			setSelectedMainSkin(state, skin) {
				if (skin.identifier !== undefined) {
					skin = skin.identifier
				}

				state.selectedMainSkin = skin
			},
			setSelectedPet(state, pet) {
				if (pet.identifier !== undefined) {
					pet = pet.identifier
				}

				state.selectedPetSkin = pet
				if (FANCY_SKIN_PREVIEW.instance) {
					FANCY_SKIN_PREVIEW.instance.setPetModel(pet)
				}
			},
			setSelectedBack(state, back) {
				if (back.identifier !== undefined) {
					back = back.identifier
				}

				state.selectedBack = back
				if (FANCY_SKIN_PREVIEW.instance) {
					FANCY_SKIN_PREVIEW.instance.setPlayerCosmetic(back, SKIN_SLOT.PLAYER_BACK)
				}
			},
			setSelectedFace(state, face) {
				if (face.identifier !== undefined) {
					face = face.identifier
				}

				state.selectedFace = face
				if (FANCY_SKIN_PREVIEW.instance) {
					FANCY_SKIN_PREVIEW.instance.setPlayerCosmetic(face, SKIN_SLOT.PLAYER_FACE)
				}
			},
			setSelectedPfx(state, pfx) {
				if (pfx.identifier !== undefined) {
					pfx = pfx.identifier
				}

				state.selectedPfx = pfx
				if (FANCY_SKIN_PREVIEW.instance) {
					FANCY_SKIN_PREVIEW.instance.setPlayerCosmetic(pfx, SKIN_SLOT.PLAYER_FOOTPRINT)
				}
			},
			setSelectedWandSkin(state, skin) {
				const activeWeaponSlot = UI.getInstance().store.getters['itemContainers/getActiveWeaponSlot']
				if (state.selectedWand[activeWeaponSlot] && state.selectedWand[activeWeaponSlot].identifier === skin.identifier) {
					state.selectedWand[activeWeaponSlot] = null
				} else {
					state.selectedWand[activeWeaponSlot] = skin
				}
			},
			setSelectedStaffSkin(state, skin) {
				const activeWeaponSlot = UI.getInstance().store.getters['itemContainers/getActiveWeaponSlot']
				if (state.selectedStaff[activeWeaponSlot] && state.selectedStaff[activeWeaponSlot].identifier === skin.identifier) {
					state.selectedStaff[activeWeaponSlot] = null
				} else {
					state.selectedStaff[activeWeaponSlot] = skin
				}
			},
			setSelectedSwordSkin(state, skin) {
				const activeWeaponSlot = UI.getInstance().store.getters['itemContainers/getActiveWeaponSlot']
				if (state.selectedSword[activeWeaponSlot] && state.selectedSword[activeWeaponSlot].identifier === skin.identifier) {
					state.selectedSword[activeWeaponSlot] = null
				} else {
					state.selectedSword[activeWeaponSlot] = skin
				}
			},
			setSelectedArcaneSkin(state, skin) {
				const activeWeaponSlot = UI.getInstance().store.getters['itemContainers/getActiveWeaponSlot']
				if (state.selectedArcane[activeWeaponSlot] && state.selectedArcane[activeWeaponSlot].identifier === skin.identifier) {
					state.selectedArcane[activeWeaponSlot] = null
				} else {
					state.selectedArcane[activeWeaponSlot] = skin
				}
			},
			setSelectedCrossbowSkin(state, skin) {
				const activeWeaponSlot = UI.getInstance().store.getters['itemContainers/getActiveWeaponSlot']
				if (state.selectedCrossbow[activeWeaponSlot] && state.selectedCrossbow[activeWeaponSlot].identifier === skin.identifier) {
					state.selectedCrossbow[activeWeaponSlot] = null
				} else {
					state.selectedCrossbow[activeWeaponSlot] = skin
				}
			},
			setSelectedScytheSkin(state, skin) {
				const activeWeaponSlot = UI.getInstance().store.getters['itemContainers/getActiveWeaponSlot']
				if (state.selectedScythe[activeWeaponSlot] && state.selectedScythe[activeWeaponSlot].identifier === skin.identifier) {
					state.selectedScythe[activeWeaponSlot] = null
				} else {
					state.selectedScythe[activeWeaponSlot] = skin
				}
			},
			setFancySkinPreview(state, preview) {
				state.fancySkinPreview = preview
			},
			unlockedMtx(state, mtx) {
				const info = SkinInformationByIdentifier.get(mtx)
				if (info) {
					state.ownedCosmetics.push({ source: 'owned', identifier: mtx, cosmeticType: SKIN_SLOT_TO_FILTER[info.skinSlot], skinSlot: info.skinSlot })
					if (debugConfig.showUnlockedMessage) {
						showGenericInfoPromptUI('Congratulations!', ['You unlocked ' + info.prettyName + '!'])
					}

					this.commit('cosmetics/sortOwnedCosmetics')
					return
				}

				const split = mtx.split('-')
				let prefix = mtx
				if (split.length > 0) {
					prefix = split[0] + '-'
				}

				if (prefix === SKIN_SLOT_TO_PREFIX[SKIN_SLOT.PET_MAIN]) {
					const petSubType = Number.parseInt(mtx.substring(4), 10)
					const petThumbnail = getPetStyleForSpriteSheet(petSubType, 106, 128)

					if (PetSubTypeValues.includes(petSubType)) {
						state.ownedCosmetics.push({ source: 'owned', identifier: petSubType, cosmeticType: FilterType.PET_SKIN, skinSlot: SKIN_SLOT.PET_MAIN })
						if (debugConfig.showUnlockedMessage) {
							showGenericYesNoUI(
								'Pet Unlocked!',
								['You unlocked ' + PetSubTypePrettyNames.get(petSubType) + '!', 'You can go to your profile to change your skins or select the equip button to equip it now!'],
								petThumbnail,
								'Equip',
								'OK',
								() => {
									//Equip
									NengiClient.getInstance().sendCommand(new SetSkinCommand(petSubType.toString(), SKIN_SLOT.PET_MAIN))
								},
								() => { },
								'default',
								'default',
							)
						}
					}
				} else if (WEAPON_SKIN_PREFIXES.includes(prefix)) {
					let skinSlot
					for (const key in SKIN_SLOT_TO_PREFIX) {
						const value = SKIN_SLOT_TO_PREFIX[key]
						if (value === prefix) {
							skinSlot = key
							break
						}
					}

					if (skinSlot) {
						const weaponSkinType = Number.parseInt(mtx.substring(prefix.length), 10)
						if (WeaponSkinSubTypeValues.includes(weaponSkinType)) {
							state.ownedCosmetics.push({ source: 'owned', identifier: weaponSkinType, cosmeticType: FilterType.ALL_WEAPONS, skinSlot })
							if (debugConfig.showUnlockedMessage) {
								showGenericInfoPromptUI('Congratulations!', ['You unlocked ' + WeaponSkinPrettyNames.get(weaponSkinType) + '!'])
							}
						}
					} else {
						console.error("No skin slot match for prefix?? " + prefix)
					}
				} else if (prefix === `emote-`) {
					const emoteType = Number.parseInt(mtx.substring(6), 10)
					state.ownedCosmetics.push({source: 'owned', identifier: emoteType, cosmeticType: FilterType.EMOTE, skinSlot: ''})
					this.commit(`emotes/addOwnedEmote`, emoteType)
					
					if (debugConfig.showUnlockedMessage) {
						showGenericInfoPromptUI('Congratulations!', ['You unlocked ' + EmotePrettyNames.get(emoteType) + '!'])
					}
				}

				this.commit('cosmetics/sortOwnedCosmetics')
			},
			sortOwnedCosmetics(state) {
				// sort by skin slot first
				state.ownedCosmetics.sort((a, b) => {
					return (a.skinSlot > b.skinSlot ? -1 : 1)
				}
				)

				// then group similar cosmetics together, with unequip at the end
				state.ownedCosmetics.sort((a, b) => {
					if (a.skinSlot !== b.skinSlot && (a.cosmeticType !== FilterType.ALL_WEAPONS && b.cosmeticType !== FilterType.ALL_WEAPONS)) {
						return 0
					}

					if (a.identifier === 0 && b.identifier === 0) {
						return 0
					}
					if (a.identifier === 0) {
						return 1
					}
					if (b.identifier === 0) {
						return -1
					}


					if (a.cosmeticType === FilterType.PLAYER_SKIN || a.cosmeticType === FilterType.PET_SKIN || a.cosmeticType === FilterType.ALL_WEAPONS) {
						return (a.identifier > b.identifier ? -1 : 1)
					}

					// attachments vs attachments, same skin slot
					const aInfo = SkinInformationByIdentifier.get(a.identifier)
					const bInfo = SkinInformationByIdentifier.get(b.identifier)

					if (aInfo && bInfo) {
						if (aInfo.skinSlot === SKIN_SLOT.PLAYER_FACE) {
							return (aInfo.spineSkin > bInfo.spineSkin ? -1 : 1)

						}
						return (aInfo.spineJsonName > bInfo.spineJsonName ? -1 : 1)

					}

					return (a.identifier > b.identifier ? -1 : 1)
				}
				)

			}
		},
		actions: {
			changeEquippedSkin({ state }, { type }) {
				if (state.selectedMainSkin && state.selectedMainSkin !== state.equippedCosmetics.mainSkin) {
					NengiClient.getInstance().sendCommand(new SetSkinCommand(state.selectedMainSkin, SKIN_SLOT.PLAYER_MAIN))
				}

				if (state.selectedPetSkin !== state.equippedCosmetics.petSkin) {
					NengiClient.getInstance().sendCommand(new SetSkinCommand(state.selectedPetSkin.toString(), SKIN_SLOT.PET_MAIN))
				}

				if (state.selectedBack !== state.equippedCosmetics.back) {
					NengiClient.getInstance().sendCommand(new SetSkinCommand(state.selectedBack.toString(), SKIN_SLOT.PLAYER_BACK))
				}

				if (state.selectedFace !== state.equippedCosmetics.face) {
					NengiClient.getInstance().sendCommand(new SetSkinCommand(state.selectedFace.toString(), SKIN_SLOT.PLAYER_FACE))
				}

				if (state.selectedPfx !== state.equippedCosmetics.pfx) {
					NengiClient.getInstance().sendCommand(new SetSkinCommand(state.selectedPfx.toString(), SKIN_SLOT.PLAYER_FOOTPRINT))
				}

				for (let i = 0; i < state.selectedWeaponsNames.length; ++i) {
					const selectedName = state.selectedWeaponsNames[i]
					const selected = state[selectedName]
					const equippedName = state.weaponsNames[i]
					const equipped = state.equippedCosmetics[equippedName]

					if (selected.primary && selected.primary.identifier.toString() !== equipped.primary) {
						NengiClient.getInstance().sendCommand(new SetSkinCommand(selected.primary.identifier.toString(), state.weaponSkinSlots[i]))

						// Updating equipped state here and not with other skins
						// because the way equipped weapons is serialzed back to the client is different than other skins
						equipped.primary = selected.primary.identifier.toString()
					} else if (!selected.primary && equipped.primary !== '') {
						NengiClient.getInstance().sendCommand(new SetSkinCommand('', state.weaponSkinSlots[i]))

						equipped.primary = ''
					}

					if (selected.secondary && selected.secondary.identifier.toString() !== equipped.secondary) {
						const slot = (state.weaponSkinSlots[i] + "_secondary") as SKIN_SLOT
						NengiClient.getInstance().sendCommand(new SetSkinCommand(selected.secondary.identifier.toString(), slot))

						equipped.secondary = selected.secondary.identifier.toString()
					} else if (!selected.secondary && equipped.secondary !== '') {
						const slot = (state.weaponSkinSlots[i] + "_secondary") as SKIN_SLOT
						NengiClient.getInstance().sendCommand(new SetSkinCommand('', slot))

						equipped.secondary = ''
					}
				}
			},
			async setInitialPlayerSkin({ state, commit, rootState }) {
				const userId = UI.getInstance().store.getters['user/userId']
				const userType = UI.getInstance().store.getters['user/userType']
				try {
					if(userType === 'anonymous') {
						const results = await anonymousSetSkinsRequest(SKIN_SLOT.PLAYER_MAIN, state.selectedMainSkin, userId)
					} else {
						const results = await setSkinsRequest(SKIN_SLOT.PLAYER_MAIN, state.selectedMainSkin, rootState.user.authentication.token)
					}
					UI.getInstance().emitEvent('user/updateFirstTimeLogIn')
					commit('setEquippedCosmetics', [{ slotId: 'player_main', selectedSkin: state.selectedMainSkin }])
				} catch (e) {
					console.error('Unable to update player skin ' + e)
				}
			},
			resetSelectedSkins({ state }) {
				state.selectedMainSkin = state.equippedCosmetics.mainSkin
				state.selectedPetSkin = state.equippedCosmetics.petSkin
				state.selectedBack = state.equippedCosmetics.back
				state.selectedFace = state.equippedCosmetics.face
				state.selectedPfx = state.equippedCosmetics.pfx

				for (let i = 0; i < state.selectedWeaponsNames.length; ++i) {
					const selectName = state.selectedWeaponsNames[i]
					const equippedName = state.weaponsNames[i]

					if (state.equippedCosmetics[equippedName].primary !== '') {
						const skinObj = { source: 'owned', identifier: state.equippedCosmetics[equippedName].primary, cosmeticType: FilterType.ALL_WEAPONS, skinSlot: state.weaponSkinSlots[i] }
						state[selectName].primary = skinObj
					} else {
						state[selectName].primary = null
					}

					if (state.equippedCosmetics[equippedName].secondary !== '') {
						const skinObj = { source: 'owned', identifier: state.equippedCosmetics[equippedName].secondary, cosmeticType: FilterType.ALL_WEAPONS, skinSlot: state.weaponSkinSlots[i] }
						state[selectName].secondary = skinObj
					} else {
						state[selectName].secondary = null
					}
				}
			},
			hatchDeliveryEgg({ state }, selectedItem) {
				NengiClient.getInstance().sendCommand(new OpenPrizeCommand(selectedItem.id, 'wormDelivery'))

				this.commit(
					'itemComparison/selectItem',
					{
						itemId: selectedItem.id,
						container: 'wormDelivery',
					},
					{ root: true },
				)
			},
		},
	}
}

export function getStyleForSpriteSheet(spriteSheetName, intendedWidth: number, intendedHeight: number, skinSlot: SKIN_SLOT) {
	let spriteSheet
	switch (skinSlot) {
		case SKIN_SLOT.PET_MAIN:
			spriteSheet = petPortraitsJson
			break
		case SKIN_SLOT.PLAYER_MAIN:
			spriteSheet = playerPortraitsJson
			break
		case SKIN_SLOT.PLAYER_BACK:
			spriteSheet = backCosmeticsJson
			break
		case SKIN_SLOT.PLAYER_FACE:
			spriteSheet = faceCosmeticsJson
			break
		case SKIN_SLOT.PLAYER_FOOTPRINT:
			spriteSheet = pfxTrailsJson
			break
		case SKIN_SLOT.WEAPON_ARCANE:
		case SKIN_SLOT.WEAPON_CROSSBOW:
		case SKIN_SLOT.WEAPON_SCYTHE:
		case SKIN_SLOT.WEAPON_STAFF:
		case SKIN_SLOT.WEAPON_SWORD:
		case SKIN_SLOT.WEAPON_WAND:
			spriteSheet = weaponSkinsJson
			break
	}

	const spriteData = spriteSheet.frames[spriteSheetName]
	if (spriteData) {
		const frame = spriteData.frame

		const rotated = spriteData.rotated
		const originX = spriteData.pivot.x * 100
		const originY = spriteData.pivot.y * 100

		const width = rotated ? frame.h : frame.w
		const height = rotated ? frame.w : frame.h
		const xOffset = frame.x
		const yOffset = frame.y

		//const realWidth = 108
		//const realHeight = 124

		const finalScale = Math.min(intendedWidth / frame.w, intendedHeight / frame.h, 1)

		//align bottom
		//let yOff = 0 //rotated ? ((width - height)*finalScale / 2) : (realHeight - (realHeight * finalScale))/2

		//align vertically
		//yOff += ((realHeight - (frame.h*finalScale))/2) * (rotated ? 1 : -1)

		return {
			'background-position': `-${xOffset}px -${yOffset}px`,
			width: `${width}px`,
			height: `${height}px`,
			'transform-origin': `${originX}% ${originY}%`,
			transform: `scale(${finalScale}) rotate(${rotated ? -90 : 0}deg)`, //translateX(${rotated ? yOff : 0}px) translateY(${rotated ? 0 : yOff}px)
			//'background-color' : rotated ? 'red' : 'blue',
		}
	} else {
		console.error('Could not find spritesheet data for subtype: ' + spriteSheetName)
		return {}
	}
}

export function getPetStyleForSpriteSheet(petIdentifer, intendedWidth: number, intendedHeight: number) {
	const petBaseType = petSubTypeToBaseType(petIdentifer)
	let typeName = petBaseTypeToName(petBaseType)
	const spineName = PetSkinSpineNames.get(petIdentifer)
	// unique (hopefully) exception, griffins are located in the dragon spine
	if (typeName === PetTypeName.Griffin) {
		typeName = PetTypeName.Dragon
	}

	let spriteSheetName = typeName + '-' + spineName + '.png'
	
	// another special case for boss pets since they are each a unique spine
	if (typeName === PetTypeName.BabyCrab || typeName === PetTypeName.FairyHand || typeName === PetTypeName.BabyGorgon || typeName === PetTypeName.RotSon || typeName === PetTypeName.BabyDragon) {
		spriteSheetName = typeName + '.png'
	} 

	return getStyleForSpriteSheet(spriteSheetName, intendedWidth, intendedHeight, SKIN_SLOT.PET_MAIN)
}

export function itemIsInOwnedCosmetics(item, ownedCosmetics): boolean {
	if (!item.mtxId) {
		return true //Placeholder blocky thing for spacing
	}

	if (item.mtxType === MtxTypes.CONSUMABLE) {
		return false
	}
	// Special case for Bundles since the emotes exist in stash as loose items
	if (item.mtxType === MtxTypes.BUNDLE) {

		const emoteList = EmoteMtxBundles.get(item.mtxId)
		if (emoteList) {
			for(let i = 0; i < emoteList.length; ++i) {
				
				if (!ownedCosmetics.some((c) => c.identifier === emoteList[i] && c.cosmeticType === FilterType.EMOTE)) {
					return false
				}
			}
			return true
		}
		else
		{
			return false
		}
	}

	// @TODO remove dupe code

	// We're a skin
	if (item.mtxType === MtxTypes.PET) {
		const mtxPrizeSubType = Number.parseInt(item.mtxId.substring(SKIN_SLOT_TO_PREFIX[SKIN_SLOT.PET_MAIN].length), 10)
		return ownedCosmetics.some((c) => c.identifier === mtxPrizeSubType && c.cosmeticType === FilterType.PET_SKIN)
	}
	else if (item.mtxType === MtxTypes.WEAPON) {
		if (item.mtxId.startsWith(SKIN_SLOT_TO_PREFIX[SKIN_SLOT.WEAPON_ARCANE])) {
			const mtxPrizeSubType = Number.parseInt(item.mtxId.substring(SKIN_SLOT_TO_PREFIX[SKIN_SLOT.WEAPON_ARCANE].length), 10)
			return ownedCosmetics.some((c) => c.identifier === mtxPrizeSubType && c.cosmeticType === FilterType.ALL_WEAPONS)
		}
		else if (item.mtxId.startsWith(SKIN_SLOT_TO_PREFIX[SKIN_SLOT.WEAPON_CROSSBOW])) {
			const mtxPrizeSubType = Number.parseInt(item.mtxId.substring(SKIN_SLOT_TO_PREFIX[SKIN_SLOT.WEAPON_CROSSBOW].length), 10)
			return ownedCosmetics.some((c) => c.identifier === mtxPrizeSubType && c.cosmeticType === FilterType.ALL_WEAPONS)
		}
		else if (item.mtxId.startsWith(SKIN_SLOT_TO_PREFIX[SKIN_SLOT.WEAPON_STAFF])) {
			const mtxPrizeSubType = Number.parseInt(item.mtxId.substring(SKIN_SLOT_TO_PREFIX[SKIN_SLOT.WEAPON_STAFF].length), 10)
			return ownedCosmetics.some((c) => c.identifier === mtxPrizeSubType && c.cosmeticType === FilterType.ALL_WEAPONS)
		}
		else if (item.mtxId.startsWith(SKIN_SLOT_TO_PREFIX[SKIN_SLOT.WEAPON_SWORD])) {
			const mtxPrizeSubType = Number.parseInt(item.mtxId.substring(SKIN_SLOT_TO_PREFIX[SKIN_SLOT.WEAPON_SWORD].length), 10)
			return ownedCosmetics.some((c) => c.identifier === mtxPrizeSubType && c.cosmeticType === FilterType.ALL_WEAPONS)
		}
		else if (item.mtxId.startsWith(SKIN_SLOT_TO_PREFIX[SKIN_SLOT.WEAPON_WAND])) {
			const mtxPrizeSubType = Number.parseInt(item.mtxId.substring(SKIN_SLOT_TO_PREFIX[SKIN_SLOT.WEAPON_WAND].length), 10)
			return ownedCosmetics.some((c) => c.identifier === mtxPrizeSubType && c.cosmeticType === FilterType.ALL_WEAPONS)
		}
		else if (item.mtxId.startsWith(SKIN_SLOT_TO_PREFIX[SKIN_SLOT.WEAPON_SCYTHE])) {
			const mtxPrizeSubType = Number.parseInt(item.mtxId.substring(SKIN_SLOT_TO_PREFIX[SKIN_SLOT.WEAPON_SCYTHE].length), 10)
			return ownedCosmetics.some((c) => c.identifier === mtxPrizeSubType && c.cosmeticType === FilterType.ALL_WEAPONS)
		}
	}
	else {
		return ownedCosmetics.some((c) => c.identifier === item.mtxId)
	}
}
