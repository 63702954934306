import { EnemyAI, FightingTriggers, IdleBehaviours, FightingBehaviours, LeashTriggers, LeashingBehaviours, AttackTypes, DeadBehaviours, ShotLeadPrecision, EnemyType, EnemyTargetingStyles } from '../ai-types'

import { SpineDataName, EnemyBipedSkinType } from '../../../models-animations/shared/spine-config'
import { EnemyDefaults } from './enemy-defaults'
import { ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { AnimationTrack } from '../../../models-animations/shared/animation-track'
import { ENEMY_NAMES } from './enemy-names'

const dummyH: EnemyAI = {
	name: ENEMY_NAMES.DUMMY_H,
	type: EnemyType.DUMMY,
	appearance: {
		asset: SpineDataName.DUMMY_H,
		skin: EnemyBipedSkinType.DEFAULT,
		mixSettings: [{ from: AnimationTrack.IDLE, to: AnimationTrack.IDLE, duration: 0.0 }],
	},
	general: {
		enemyLevel: 1,
		experienceWhenKilled: 0,
	},
	baseAttributes: {
		...EnemyDefaults.baseAttributes,
		colliders: [
			{
				type: ColliderType.Ellipse,
				rX: 40,
				rY: 140,
				traits: ColliderTraits.BlockProjectile,
				position: [0, -180],
			},
			{
				type: ColliderType.Circle,
				radius: 40,
				traits: ColliderTraits.BlockMovement,
				position: [0, -30],
			},
		],
		visibilityRadius: 750,
		maxHealth: 99999,
		movementSpeed: 0,
		attackRate: 0,
		noFlip: true,
		colliderIsKinematic: true,

		lootDropProbability: 100,
		lootDropQuantity: 0,
	},
	soundEffects: {
		attack: 'Projectile_Blip',
		impact: 'SFX_Impact_Enemy_Rock',
	},
	states: {
		idling: {
			behaviour: IdleBehaviours.STATIONARY,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			transitionToFighting: {
				trigger: FightingTriggers.AGGRESSIVE_PROXIMITY,
				radius: 1200,
			},
			aggroOnHit: true,
		},
		fighting: {
			behaviour: FightingBehaviours.CHASE_AND_ATTACK,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			attackType: AttackTypes.NONE,
			engagementMaxDistance: 1400,
			engagementMinDistance: 0,
			movementMaxDistance: 1400,
			movementMinDistance: 0,
			attackOptions: [],
			shotLeadPrecision: ShotLeadPrecision.AVERAGE,
			visualAimLockSeconds: 0.5,
			transitionToLeashing: {
				trigger: LeashTriggers.NONE,
			},
		},
		leashing: {
			behaviour: LeashingBehaviours.MOVE,
			speedMultiplier: 4,
			decelerateMultiplier: 0,
		},
		fleeing: {},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 0,
			persistentCorpse: true,
		},
	},
}

export default dummyH
