import nengi from 'nengi'
import { uuid } from '../../utils/primitive-types'

class PreviewAugmentsCommand {
	static protocol = {
		augmentIds: { type: nengi.String, indexType: nengi.UInt16 },
		weaponId: nengi.String,
	}
	augmentIds: uuid[]
	weaponId: uuid
	/**
	 * Creates an instance of PreviewAugmentsCommand.
	 * @param {uuid} augmentIds Augment IDs
	 * @param {uuid} weaponId Weapon ID
	 */
	constructor(augmentIds: uuid[], weaponId: uuid) {
		this.augmentIds = augmentIds
		this.weaponId = weaponId
	}
}

export default PreviewAugmentsCommand
