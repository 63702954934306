import { ensureEnumUnique } from '../../utils/debug'
import logger from '../../utils/client-logger'

/** enum of all StatModPools */
enum StatModPoolType {
	ALL_WEAPONS = 1,
	ALL_UNIQUE_SLOT_GEAR = 2,
	ALL_GEAR_INCLUDING_TRINKETS = 5,
	ALL_AUGMENTS = 3,
	ALL_GEAR_SKILLS = 4,

	WEAPON_SPELLSWORD = 100,
	WEAPON_SCYTHE = 101,
	WEAPON_WAND = 102,
	WEAPON_STAFF = 103,
	WEAPON_ARCANE_FOCUS = 104,
	WEAPON_CROSSBOW = 106,

	WEAPON_SPELLSWORD_BASE = 150,
	WEAPON_SPELLSWORD_THICC_BASE = 170,
	WEAPON_SPELLSWORD_LONG_BASE = 171,
	WEAPON_SCYTHE_BASE = 151,
	WEAPON_WAND_BASE = 152,
	WEAPON_STAFF_BASE = 153,
	WEAPON_ARCANE_FOCUS_BASE = 154,
	WEAPON_CROSSBOW_BASE = 156,
	WEAPON_BASE_PHYSICAL_DAMAGE = 198,
	WEAPON_BASE_ELEMENTAL_DAMAGE = 199,

	GEAR_SLOTLESS = 200,
	GEAR_TRINKET = 201,
	GEAR_BELT = 202,
	GEAR_PENDANT = 203,
	GEAR_DEFENSE_ROLLS = 204,

	AUGMENT_BASE_ANY_DAMAGE_TYPE = 1000,
	AUGMENT_NARROW_SPREAD_EXTRA_PROJECTILES_BASE = 1001,
	AUGMENT_PIERCE_BASE = 1003,

	AUGMENT_FAST_AND_WEAK_BASE = 1004,
	AUGMENT_SLOW_AND_STRONG_BASE = 1005,
	AUGMENT_WIDE_SPREAD_EXTRA_PROJECTILES_BASE = 1006,

	AUGMENT_TIGHTEN_SPREAD_BASE = 1010,
	AUGMENT_SPLASH_DAMAGE_BASE = 1012,

	AUGMENT_CREEPING_DEATH = 1013,
	AUGMENT_CRIT_SWITCH = 1014,
	AUGMENT_BASIC_STAT_UP = 1015,
	AUGMENT_CLUSTER_SHOT = 1016,
	AUGMENT_SNIPER = 1017,
	AUGMENT_QUICK_CHARGE_BASE = 1018,
	AUGMENT_DIFFUSION_BEAM = 1019,
	AUGMENT_FOCUSED_BEAM = 1020,
	AUGMENT_STAR_LASER = 1021,

	AUGMENT_LIGHTNING_BURST = 1022,
	AUGMENT_FLAMETHROWER = 1023,
	AUGMENT_ICEBOUND = 1024,
	AUGMENT_TASER = 1025,
	AUGMENT_CHAIN_LIGHTNING = 1026,
	AUGMENT_DEBILITATOR = 1027,

	AUGMENT_PHYSICAL_UP = 1028,
	AUGMENT_FIRE_UP = 1029,
	AUGMENT_ICE_UP = 1030,
	AUGMENT_LIGHTNING_UP = 1031,
	AUGMENT_POISON_UP = 1032,

	AUGMENT_PHYSICAL_CONVERSION = 1033,
	AUGMENT_FIRE_CONVERSION = 1034,
	AUGMENT_ICE_CONVERSION = 1035,
	AUGMENT_LIGHTNING_CONVERSION = 1036,
	AUGMENT_POISON_CONVERSION = 1037,

	AUGMENT_FIERY_OVERCHARGE = 1038,

	AUGMENT_ACCELERATE_BASE = 2000,
	AUGMENT_DECELLERATE_BASE = 2001,
	AUGMENT_RANGE_ADJUSTING_ACCELERATE_BASE = 2002,
	AUGMENT_SINE_BASE = 2006,
	AUGMENT_ZIGZAG_BASE = 2007,
	AUGMENT_STRAIGHT_BOOMERANG_BASE = 2008,
	AUGMENT_CIRCULAR_BASE = 2009,
	AUGMENT_ORBIT_BASE = 2010,
	AUGMENT_SPIRAL_BASE = 2011,
	AUGMENT_LOOPING_BASE = 2012,
	AUGMENT_RANGE_CIRCULAR_BASE = 2013,
	AUGMENT_RANGE_ORBIT_BASE = 2014,
	AUGMENT_ENERGY_BOOSTER = 2015,
	AUGMENT_ELEMENTAL_DEVOTION_MAJOR = 2016,
	AUGMENT_ELEMENTAL_DEVOTION_MINOR = 2017,

	ENCHANTMENT_EXTRA_PROJECTILES = 3001,
	ENCHANTMENT_FASTER_ATTACKS = 3002,
	ENCHANTMENT_STRONGER_ATTACKS = 3003,
	ENCHANTMENT_PIERCE = 3004,
	ENCHANTMENT_SPLASH_DAMAGE = 3005,
	ENCHANTMENT_CRIT_STRIKE = 3006,
	ENCHANTMENT_BIG_AND_BAD = 3007,
	ENCHANTMENT_PHYSICAL_UP = 3008,
	ENCHANTMENT_FIRE_UP = 3009,
	ENCHANTMENT_ICE_UP = 3010,
	ENCHANTMENT_LIGHTNING_UP = 3011,
	ENCHANTMENT_POISON_UP = 3012,
	ENCHANTMENT_AILMENT_POTENCY = 3013,
	ENCHANTMENT_TIGHTEN_SPREAD = 3014,
	ENCHANTMENT_WILD_SHOTS = 3015,
	ENCHANTMENT_CHARGE_OVERLOAD = 3016,
	ENCHANTMENT_POINT_BLANK_BOOM = 3017,
	ENCHANTMENT_NO_FINESSE = 3018,
	ENCHANTMENT_CRIT_DAMAGE = 3019,
	ENCHANTMENT_KNOCKBACK = 3020,
	ENCHANTMENT_VACUUM = 3021,
	ENCHANTMENT_SINE = 3022,
	ENCHANTMENT_ZIGZAG = 3023,
	ENCHANTMENT_CIRCULAR = 3024,
	ENCHANTMENT_ORBIT = 3025,
	ENCHANTMENT_SPIRAL = 3026,
	ENCHANTMENT_LOOPING = 3027,
	ENCHANTMENT_GROW = 3028,
	ENCHANTMENT_LONG_RANGE = 3029,
	ENCHANTMENT_BURST_SHOT = 3030,
	ENCHANTMENT_SHOCKING_FIRESTORM = 3031,
	ENCHANTMENT_FAST_SHIELDS = 3032,
	ENCHANTMENT_SHIELDED = 3033,
	ENCHANTMENT_QUICK_SKILLS = 3034,
	ENCHANTMENT_FISSION_ENERGY = 3035,
	ENCHANTMENT_GREVIOUS_WOUNDS = 3036,
	ENCHANTMENT_CATASTROPHE_BLAST = 3037,
	ENCHANTMENT_GREATER_ELEMENTS = 3038,
	ENCHANTMENT_HEAVY_SHIELDING = 3039,
	ENCHANTMENT_HORDE_BREAKER = 3040,
	ENCHANTMENT_BLOODIED_GEAR = 3041,
	ENCHANTMENT_FAST_FEET = 3042,
	ENCHANTMENT_GEAR_OVERCHARGE = 3043,
}

export default StatModPoolType

ensureEnumUnique(StatModPoolType, logger)
