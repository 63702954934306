import nengi from 'nengi'

class SendPlayerReputationPointsMessage {
	static protocol = {
		kills: nengi.Number,
		pois: nengi.Number,
		damageTaken: nengi.Number,
		enemiesIgnited: nengi.Number,
		champions: nengi.Number,
		bosses: nengi.Number,
		rareItems: nengi.Number,
		epicItems: nengi.Number,
	}

	kills: number
	pois: number
	damageTaken: number
	enemiesIgnited: number
	champions: number
	bosses: number
	rareItems: number
	epicItems: number

	constructor(params: SendPlayerReputationPointsMessage) {
		Object.assign(this, params)
	}
}

export default SendPlayerReputationPointsMessage