







export default {
	name: 'UndefinedOrNullProperty',
	props: {
		rootObject: {
			type: Object,
			required: true,
		},
		propertySeekDotNotation: {
			type: String,
			required: true,
		},
		propertyKey: {
			type: String,
			required: true,
		},
		propertyValue: {
			type: null,
			required: true,
		}
	},
}
