import { differenceBy, find, some } from 'lodash'
import { debugConfig } from '../../engine/client/debug-config'
import { NengiClient } from '../../engine/client/nengi-client'
import RequestStashUpdate from '../../items/shared/request-stash-update-command'
import RequestWalletUpdate from '../../items/shared/request-wallet-update-command'
import ItemType from '../../loot/shared/item-type'
import { WormHornSubtype } from '../../loot/shared/worm-horn-sub-type'
import logger from '../../utils/client-logger'
import { UI } from '../ui'
import { completeFlagsFromCriteria, tryShowTutorial } from './ftue-manager.state'
import { getStashLimit, PIT_STORAGE_DEFAULT_MAX_SIZE, PREMIUM_WORM_HORN_ITEMS, STANDARD_WORM_HORN_ITEMS } from '../../engine/shared/game-data/player-formulas'
import { getPressureLoadoutOnClient } from '../../engine/client/pressure.client'
import { pressureGetWormMailSize } from '../../engine/shared/game-data/pressure-stat-functions.shared'
import { Panel } from './in-game.ui-state'

export const enum CategoryFilterTypes {
	NAME = 'name',
	LEVEL = 'level',
	PRICE = 'price',
	ALL = 'all',
	WEAPON = 'Weapon',
	GEAR = 'Gear',
	WEAPON_AUGMENT = 'Weapon Augment',
	PRIZE = 'Prize',
	TOP_STATS = 'Top Stats',
	DAMAGE_TAKEN = 'Damage Taken',
	SOUL_BONDS = 'Soul Bonds',
	PLAYER_SKIN = 'Player Skin',
	PET_SKIN = 'Pet Skin',
	FEATURED = 'featured',
	BUNDLE = 'bundle',
	SKIN = 'skin',
	PET = 'pet',
	CONSUMABLE = 'consumable',
	ACCOUNT = 'account',
}

export const enum SortBy {
	ASC = 'asc',
	DESC = 'desc',
}

import { ITEM_STACK_SIZES } from '../../engine/shared/game-data/stackable-config'
import { ImARealitem } from '../reusable-components/inventory-item/inventory-item-helpers'
import { FTUESequence } from '../../ftue/shared/ftue.shared'
import { FANCY_SKIN_PREVIEW } from './cosmetics-ui-state'
import { playerCanUseItem } from '../../engine/shared/game-data/player'

interface ItemContainerUIState {
	inventory: any[]
	newItemsBadgeList: any[]
	embedded: any[]
	paperdoll: any[]
	stash: any[]
	activeWeaponSlot: string
	equippedWeapon: {
		one: any
		two: any
	}
	equippedGear: {
		one: any
		two: any
		three: any
	}
	marketplaceItemListings: any[]
	wormDelivery: any[]
	wormMailInFlight: any[]
	worms: {
		[wormId: number]: any[]
	}
	pitRewards: any[]
	pitStorage: any[]
	furnace: any[]
	pendingMarketplace: ImARealitem[]
	activeItemTypeFilter: string
	activeRarityFilter: string
	activeFilterCategory: string
	activeSkinFilter: string
	activeSearch: boolean
	itemSortOrder: string
	paperDollEquippedWeapons: any[]
	playerEnchantments: any[]
	activeWeaponSkinSlot: string
	activeMtxFilter: string
	stashMtxIncreases: number
	stashLoginIncreases: number
	stashFactionIncreases: number
	categorySortOrderName: string
	categorySortOrderLevel: string
	categorySortOrderPrice: string
	userMarketplaceListings: any[]
	usedWormHorn: boolean
}

interface WormContentsUpdatedParams {
	wormId: number
	items: any[]
}

export const itemContainers = () => {
	logger.debug('Initializing item container UI module')
	return {
		namespaced: true,
		state: {
			inventory: [],
			newItemsBadgeList: [],
			embedded: [],
			paperdoll: [],
			equippedWeapon: {
				one: null,
				two: null,
			},
			equippedGear: {
				one: null,
				two: null,
				three: null,
			},
			marketplaceItemListings: [],
			userMarketplaceListings: [],
			stash: [],
			worms: {},
			wormDelivery: [],
			wormMailInFlight: [],
			pitRewards: [],
			pitStorage: [],
			furnace: [],
			pendingMarketplace: [],
			activeItemTypeFilter: 'all',
			activeRarityFilter: 'all',
			activeFilterCategory: 'level',
			activeSearch: false,
			activeWeaponSkinSlot: 'primary',
			itemSortOrder: 'desc',
			paperDollEquippedWeapons: [],
			playerEnchantments: [],
			activeSkinFilter: 'Player Skin',
			activeMtxFilter: 'featured',
			stashMtxIncreases: 0,
			stashLoginIncreases: 0,
			stashFactionIncreases: 0,
			categorySortOrderName: 'asc',
			categorySortOrderLevel: 'asc',
			categorySortOrderPrice: 'asc',
			usedWormHorn: false,
		} as ItemContainerUIState,
		mutations: {
			inventoryUpdated(state: ItemContainerUIState, items: any) {
				//console.log('Got new inventory deets:', items)
				state.inventory = [...items]
			},
			addItemtoNewBadgeList(state: ItemContainerUIState, items: any) {
				state.newItemsBadgeList.push(...items)
			},
			updateInventoryBadgeList(state) {
				state.newItemsBadgeList = []
			},
			paperdollUpdated(state: ItemContainerUIState, items: any) {
				//console.log('Got new paperdoll deets:', items)
				const weapons = [items[0], items[1]]

				state.equippedWeapon.one = items[0]
				state.equippedWeapon.two = items[1]
				state.paperdoll = [...items]
				state.paperDollEquippedWeapons = [...weapons]

				// GEAR
				{
					// put all paperdoll items in itemsInSlots according to their actual slot
					const itemsInSlots = [null, null, null, null, null]
					items.forEach((item) => {
						itemsInSlots[item.paperdollSlotIndex] = item
					})

					for (const index of [2, 3, 4]) {
						if (index === 2) {
							state.equippedGear.one = itemsInSlots[index]
						} else if (index === 3) {
							state.equippedGear.two = itemsInSlots[index]
						} else if (index === 4) {
							state.equippedGear.three = itemsInSlots[index]
						}
					}
				}
			},
			activeWeaponChanged(state: ItemContainerUIState, newActiveSlot: string) {
				state.activeWeaponSlot = newActiveSlot
			},
			embeddedUpdated(state: ItemContainerUIState, items: any) {
				//console.log('Got new embedded deets:', items)
				state.embedded = [...items]
				this.commit('augmentationStation/embeddedUpdated', undefined, {root: true})
			},
			stashUpdated(state: ItemContainerUIState, items: any) {
				//console.log('Got new stash deets:', items)
				state.stash = [...items]
			},
			wormDeliveryUpdated(state: ItemContainerUIState, items: any) {
				state.wormDelivery = [...items]
			},
			wormMailInFlightUpdated(state: ItemContainerUIState, items: any) {
				state.wormMailInFlight = [...items]
			},
			outpostWormMailUpdated(state: ItemContainerUIState, params: WormContentsUpdatedParams) {
				const { wormId, items } = params
				state.worms[wormId] = [...items]
			},
			pitRewardsUpdated(state: ItemContainerUIState, items: any) {
				state.pitRewards = [...items]
			},
			pitStorageUpdated(state: ItemContainerUIState, items: any) {
				state.pitStorage = [...items]
			},
			furnaceUpdated(state: ItemContainerUIState, items: any) {
				state.furnace = [...items]
			},
			pendingMarketplaceUpdated(state: ItemContainerUIState, items: any) {
				const toSet = []
				for (let i = 0; i < items.length; ++i) {
					items[i].isPendingMarketplace = true
					toSet.push(items[i])
				}

				if (toSet.length < state.pendingMarketplace.length) {
					// did the items we lose actually go to the marketplace?
					let needToRefreshStash = false
					for (let i = 0; i < state.pendingMarketplace.length; ++i) {
						const oldItem = state.pendingMarketplace[i]
						const found = toSet.find((i) => i.id === oldItem.id)
						if (!found) {
							needToRefreshStash = true
							break
						}
					}

					if (needToRefreshStash) {
						NengiClient.getInstance().sendCommand(new RequestStashUpdate())
						NengiClient.getInstance().sendCommand(new RequestWalletUpdate()) // to see our refund
					}
				}

				state.pendingMarketplace = toSet
			},
			addItemToContainer(state: ItemContainerUIState, { item, containerName }) {
				const container = state[containerName]
				if (container) {
					const found = container.findIndex((i) => i.id === item.id)
					if (found > -1) {
						container[found] = item
					} else {
						container.push(item)
					}
					state[containerName] = [...container] //This is needed because vue is a punk

					if (containerName === 'furnace') {
						UI.getInstance().emitAsyncEvent('furnace/relinkFurnaceData', container)
						completeFlagsFromCriteria('hasSmeltedAnyItem')
					} else if (containerName === 'inventory') {
						tryShowTutorial(FTUESequence.LootInInventoryGoToOutpost) //first time player picks up loot
					}
				} else {
					console.error('[addItemToContainer] No container found for name: ' + containerName)
				}
			},
			removeItemsFromContainer(state: ItemContainerUIState, { itemIds, containerName }) {
				const container = state[containerName]
				if (container) {
					state[containerName] = container.filter((item) => !itemIds.includes(item.id))
					if (container === 'furnace') {
						UI.getInstance().emitAsyncEvent('furnace/relinkFurnaceData', container)
					}
				} else {
					console.error('[removeItemsFromContainer] No container for name: ' + containerName)
				}
			},
			clearContainers(state: ItemContainerUIState) {
				state.inventory = []
				state.newItemsBadgeList = []
				state.embedded = []
				state.paperdoll = []
				state.equippedWeapon = {
					one: null,
					two: null,
				}
				state.equippedGear = {
					one: null,
					two: null,
					three: null,
				}
				state.marketplaceItemListings = []
				state.userMarketplaceListings = []
				state.stash = []
				state.worms = {}
				state.wormDelivery = []
				state.wormMailInFlight = []
				state.pendingMarketplace = []
			},
			updateItemTypeFilter(state, newFilter: string) {
				state.activeItemTypeFilter = newFilter
			},
			updateSkinTypeFilter(state, newFilter: string) {
				state.activeSkinFilter = newFilter

				FANCY_SKIN_PREVIEW.instance?.setShowPfxTrail(newFilter === 'Trail')
			},
			updateMtxItemFilter(state, newFilter: string) {
				state.activeMtxFilter = newFilter
			},
			updateRarityFilter(state, newFilter: string) {
				const activePanel = (state.listItemPopDetails = UI.getInstance().store.getters['inGame/activePanel'])

				state.activeRarityFilter = newFilter

				if (activePanel === Panel.MARKETPLACE_UPDATE) {
					UI.getInstance().emitAsyncEvent('marketplaceUpdated/fetchListings', null)
				}
			},
			updateSortOrder(state, newFilter: string) {
				const activePanel = (state.listItemPopDetails = UI.getInstance().store.getters['inGame/activePanel'])

				if (state.activeFilterCategory === newFilter) {
					if (newFilter === 'name') {
						state.categorySortOrderName === SortBy.ASC ? (state.categorySortOrderName = SortBy.DESC) : (state.categorySortOrderName = SortBy.ASC)
					}
					if (newFilter === 'level') {
						state.categorySortOrderLevel === SortBy.ASC ? (state.categorySortOrderLevel = SortBy.DESC) : (state.categorySortOrderLevel = SortBy.ASC)
					}
					if (newFilter === 'price') {
						state.categorySortOrderPrice === SortBy.ASC ? (state.categorySortOrderPrice = SortBy.DESC) : (state.categorySortOrderPrice = SortBy.ASC)
					}
					state.itemSortOrder === SortBy.ASC ? (state.itemSortOrder = SortBy.DESC) : (state.itemSortOrder = SortBy.ASC)
				}

				state.activeFilterCategory = newFilter

				if (activePanel === Panel.MARKETPLACE_UPDATE) {
					UI.getInstance().emitAsyncEvent('marketplaceUpdated/fetchListings', null)
				}
			},
			customSearchFilters(state) {
				state.activeSearch = !state.activeSearch
			},
			playerEnchantmentUpdate(state: ItemContainerUIState, items: any) {
				const currentPlayerEnchantments = state.playerEnchantments
				const newEnchantment = differenceBy(items, currentPlayerEnchantments, 'id')
				state.playerEnchantments.push(newEnchantment[0])
			},
			emptyPlayerEnchantments(state: ItemContainerUIState) {
				state.playerEnchantments = []
			},
			updateWeaponSkinSlot(state, slotType) {
				if (state.activeWeaponSkinSlot !== slotType) {
					state.activeWeaponSkinSlot = slotType
				}
			},
			updateStashIncreases(state, { mtxIncreases, loginIncreases, factionIncreases }) {
				state.stashMtxIncreases = mtxIncreases
				state.stashLoginIncreases = loginIncreases
				state.stashFactionIncreases = factionIncreases
			},
			updateItemStackSize(state, { itemId, container, stackSize }) {
				if (container === 'worm_delivery') {
					container = 'wormDelivery'
				} else if (container === 'pit_rewards') {
					container = 'pitRewards'
				}

				const containerArray = state[container]
				const item = containerArray.find((item) => itemId === item.id)
				if (item) {
					item.stackAmount = stackSize
				} else {
					console.error("Could not find item to update stack size. " + container + " " + itemId)
				}
			},
			setUsedWormHorn(state, used) {
				state.usedWormHorn = used
			}
		},
		getters: {
			itemByContainerIndex(state: ItemContainerUIState) {
				return (containerName: string, paperdollIndex: number) => {
					const itemsInSlots = [null, null, null, null, null]
					state.paperdoll.forEach((item) => {
						itemsInSlots[item.paperdollSlotIndex] = item
					})
					// console.log(`GRABBING ITEM INDEX ${paperdollIndex} FROM CONTAINER:`, containerName, state[containerName])
					return itemsInSlots[paperdollIndex]
				}
			},
			firstItemInContainer(state: ItemContainerUIState) {
				return (containerName: string) => {
					return state[containerName][0]
				}
			},
			itemDetails(state: ItemContainerUIState) {
				return (containerName: string, itemId: string) => {
					const foundItem = find(state[containerName], (item) => {
						return item.id === itemId
					})
					return foundItem
				}
			},
			equippedWeapons(state: ItemContainerUIState) {
				return state.equippedWeapon
			},
			equippedGear(state: ItemContainerUIState) {
				return state.equippedGear
			},
			activeWeapon(state: ItemContainerUIState, _, rootState) {
				const slot = rootState.hud.activeWeaponSlot
				return state.equippedWeapon[slot] //TODO4: real value
			},
			inactiveWeapon(state: ItemContainerUIState, _, rootState) {
				const slot = rootState.hud.activeWeaponSlot
				return state.equippedWeapon[slot === 'one' ? 'two' : 'one'] //TODO4: real value
			},
			equippedWeaponOne(state: ItemContainerUIState) {
				return state.equippedWeapon.one
			},
			equippedWeaponTwo(state: ItemContainerUIState) {
				return state.equippedWeapon.two
			},
			equippedGearOne(state: ItemContainerUIState) {
				return state.equippedGear.one
			},
			equippedGearTwo(state: ItemContainerUIState) {
				return state.equippedGear.two
			},
			equippedGearThree(state: ItemContainerUIState) {
				return state.equippedGear.three
			},
			embedded(state: ItemContainerUIState) {
				return state.embedded
			},
			itemsInContainer(state: ItemContainerUIState) {
				return (containerName: string, wormId?: number) => {
					if (containerName === 'outpostWormMail' && wormId !== undefined) {
						return state.worms[wormId] || []
					} else {
						if (state[containerName] === undefined) {
							console.error('Unknown container specified!!')
						}
						return state[containerName]
					}
				}
			},
			equippedItemAugmentsAreFull(state: ItemContainerUIState) {
				return (itemId: string) => {
					//console.log(state.paperdoll, itemId)
					const itemInPaperdoll = state.paperdoll.find((item) => {
						return item.id === itemId
					})
					//console.log('SELECTED PAPERDOLL ITEM::', itemInPaperdoll)
					return false
				}
			},
			itemsByItemTypeInContainer(state: ItemContainerUIState) {
				return (containerName: string, itemType: string, wormId?: number) => {
					if (containerName === 'outpostWormMail' && wormId !== undefined) {
						return (
							state.worms[wormId].filter((item: any) => {
								return item.itemType === itemType
							}) || []
						)
					} else {
						if (state[containerName] === undefined) {
							console.error('Unknown container specified!!')
						}
						return state[containerName].filter((item: any) => {
							return item.itemType === itemType
						})
					}
				}
			},
			unidentifiedItemsInContainer(state: ItemContainerUIState) {
				return (containerName: string, wormId?: number) => {
					if (containerName === 'outpostWormMail' && wormId !== undefined) {
						return (
							state.worms[wormId].filter((item: any) => {
								return item.unidentified
							}) || []
						)
					} else {
						if (state[containerName] === undefined) {
							console.error('Unknown container specified!!')
						}
						return state[containerName].filter((item: any) => {
							return item.unidentified
						})
					}
				}
			},
			numberOfItemsInContainer(state: ItemContainerUIState) {
				return (containerName: string, wormId?: number) => {
					if (containerName === 'outpostWormMail' && wormId !== undefined) {
						return state.worms[wormId]?.length || 0
					} else {
						if (state[containerName] === undefined) {
							console.error('Unknown container specified!!')
						}
						return state[containerName].length
					}
				}
			},
			numberOfUnidentifiedItemsInContainer(state: ItemContainerUIState) {
				return (containerName: string, wormId?: number) => {
					if (containerName === 'outpostWormMail' && wormId !== undefined) {
						return (
							state.worms[wormId].filter((item: any) => {
								return item.unidentified
							})?.length || 0
						)
					} else {
						if (state[containerName] === undefined) {
							console.error('Unknown container specified!!')
						}
						return state[containerName].filter((item: any) => {
							return item.unidentified
						}).length
					}
				}
			},
			containerIsFull(state, getters, rootState, rootGetters) {
				return (containerName: string, wormId?: number) => {
					if (containerName === 'outpostWormMail' && wormId !== undefined) {
						const wormMailBoxSize = maxWormMailItems(rootGetters['outpostWormMail/getWormHornTypeSelected'])
						return state.worms[wormId]?.length >= wormMailBoxSize
					} else {
						return false
					}
				}
			},
			containerIsEmpty(state: ItemContainerUIState) {
				return (containerName: string, wormId?: number) => {
					if (containerName === 'outpostWormMail' && wormId !== undefined) {
						const worm = state.worms[wormId]
						if (!worm) {
							return true
						}
						return worm.length === 0
					} else {
						const container = state[containerName]
						if (!container) {
							return true
						}
						return container.length === 0
					}
				}
			},
			hasBiomeKey(state: ItemContainerUIState) {
				return (biomeIndex: number) => {
					if (debugConfig.items.fakeBiomeKeys) {
						return true
					}
					// First biome is always accessible, otherwise require a key
					return biomeIndex === 0 || some(state.stash, (elem) => elem.itemType === 'Biome Key' && elem.biome === biomeIndex)
				}
			},
			numberOfBiomeKeysInStashByBiomeIndex(state: ItemContainerUIState) {
				return (biomeIndex: number) => {
					if (debugConfig.items.fakeBiomeKeys) {
						return 99
					}

					let keyAmount = 0
					for (let i = 0; i < state.stash.length; ++i) {
						const item = state.stash[i]
						if (item.itemTypeEnum === ItemType.BiomeKey && item.biome === biomeIndex) {
							if (item.stackAmount) {
								keyAmount += item.stackAmount
							} else {
								keyAmount++
							}
						}
					}

					return keyAmount
				}
			},
			playerCanUseItem(state: ItemContainerUIState, getters, rootState, rootGetters) { 
				return (item) => {
					const activeSoulCycle = rootGetters['hud/activeSoulCycle']
					const furthestSoulCycle = rootGetters['hud/furthestSoulCycle']

					const ret = playerCanUseItem(item.itemTypeEnum, item.itemSubType, {
						activeSoulCycle,
						furthestSoulCycleEver: furthestSoulCycle,
					})
					// console.log(`    playerCanUseItem() subfn `, { item, activeSoulCycle, furthestSoulCycle, ret })
					return ret
				}
			},
			playerEnchantments(state: ItemContainerUIState) {
				return state.playerEnchantments
			},
			getNewItemIdentifierList(state: ItemContainerUIState) {
				return state.newItemsBadgeList
			},
			getActiveWeaponSlot(state) {
				return state.activeWeaponSkinSlot
			},
			hasWormHorn(state) {
				for (let i = 0; i < state.stash.length; ++i) {
					const itemType = state.stash[i].itemTypeEnum
					if (itemType === ItemType.WormHorn) {
						return true
					}
				}
				return false
			},
			standardWormHornCount(state) {
				let count = 0
				for (let i = 0; i < state.stash.length; ++i) {
					const item = state.stash[i]
					if (item.itemTypeEnum === ItemType.WormHorn) {
						if (item.itemSubType === WormHornSubtype.Standard) {
							count++
						}
					}
				}

				return count
			},
			premiumWormHornCount(state) {
				let count = 0
				for (let i = 0; i < state.stash.length; ++i) {
					const item = state.stash[i]
					if (item.itemTypeEnum === ItemType.WormHorn) {
						if (item.itemSubType === WormHornSubtype.Premium) {
							count++
						}
					}
				}

				return count
			},
			isStashFull(state: ItemContainerUIState) {
				return state.stash.length >= getStashLimit(state.stashMtxIncreases + state.stashLoginIncreases + state.stashFactionIncreases)
			},
			isPitStorageFull(state: ItemContainerUIState) {
				return state.pitStorage.length >= PIT_STORAGE_DEFAULT_MAX_SIZE
			},
			stashMtxIncreases(state: ItemContainerUIState) {
				return state.stashMtxIncreases
			},
			stashLoginIncreases(state: ItemContainerUIState) {
				return state.stashLoginIncreases
			},
			stashFactionIncreases(state: ItemContainerUIState) {
				return state.stashFactionIncreases
			},
			usedWormHorn(state: ItemContainerUIState) {
				return state.usedWormHorn
			}
		},
		actions: {
			resetFilters({ state }) {
				state.activeItemTypeFilter = 'all'
				state.activeRarityFilter = 'all'
				state.activeSearch = false
				state.activeFilterCategory = 'level'
				state.itemSortOrder = 'desc'
			},
			marketplaceListingsUpdated({ state, rootGetters }, items: any) {
				const oldMarketPlaceListingLength = state.marketplaceItemListings.length
				state.marketplaceItemListings = [...items]
				const newMarketPlaceListingLength = state.marketplaceItemListings.length
				if (newMarketPlaceListingLength < oldMarketPlaceListingLength) {
					//We sold an item (maybe)
					NengiClient.getInstance().sendCommand(new RequestWalletUpdate())
					// or a listing expired
					NengiClient.getInstance().sendCommand(new RequestStashUpdate())
				}
			},
			userMarketplaceListingsUpdated({ state }, items: any) {
				const oldUserListingsLen = state.userMarketplaceListings.length
				state.userMarketplaceListings = [...items]
				const newUserListingsLen = state.userMarketplaceListings.length

				if (newUserListingsLen < oldUserListingsLen) {
					//We sold an item (maybe)
					NengiClient.getInstance().sendCommand(new RequestWalletUpdate())
					// or a listing expired
					NengiClient.getInstance().sendCommand(new RequestStashUpdate())
				}
			}
		},
	}
}

export function maxWormMailItems(wormHornType) {
	if (wormHornType !== null) {
		switch (wormHornType) {
			case WormHornSubtype.Standard:
				return STANDARD_WORM_HORN_ITEMS
			case WormHornSubtype.Premium:
				return PREMIUM_WORM_HORN_ITEMS
		}
	}
	return pressureGetWormMailSize(getPressureLoadoutOnClient())
}

export function containerWillStackItems(itemContainer, item): boolean {
	let stacksRemaining = item.stackAmount
	const maxStackSize = ITEM_STACK_SIZES[item.itemTypeEnum]

	for (let i = 0; i < itemContainer.length; ++i) {
		const thisStashItem = itemContainer[i]
		if (thisStashItem.stackAmount &&
			thisStashItem.itemTypeEnum === item.itemTypeEnum &&
			thisStashItem.itemSubType === item.itemSubType &&
			thisStashItem.rarityEnum === item.rarityEnum) {

			const thisItemStacksRemaining = (maxStackSize - thisStashItem.stackAmount)
			if (thisItemStacksRemaining > 0) {
				stacksRemaining -= thisItemStacksRemaining
				if (stacksRemaining <= 0) {
					return true
				}
			}
		}
	}

	return false
}