


































export default {
	name: 'ArrowCheckbox',
	components: {},
	props: {
		buttonText: {
			type: String,
			required: true,
			default: function() {
				console.warn('Currently using default arrow Text. Ensure <ArrowLabel /> component has a custom attribute arrow-label-text')
				return 'DEFAULT ARROW LABEL'
			},
		},
		buttonTextTwo: {
			type: String,
			default: '',
			required: false,
		},
		strikethroughTextTwo: {
			type: Boolean,
			default: false,
			required: false,
		},
		buttonTextMiddle: {
			type: String,
			default: '',
			required: false,
		},
		grayTextMiddle: {
			type: Boolean,
			default: false,
			required: false,
		},
		disabled: {
			type: Boolean,
			required: true,
			default: false,
		},
		loading: {
			type: Boolean,
			required: false,
			default: false,
		},
		tooltipTarget: {
			type: String,
			required: false,
			default: '',
		},
		label: {
			type: String,
			default: '',
			required: false,
		},
		hideCheckbox: {
			type: Boolean,
			default: false,
			required: false,
		},
		checkboxIndex: {
			type: Number,
			default: 0,
			required: false,
		},
		selectedIndex:{
			type: Number,
			default: false,
			required: false,
		}

	},
	data() {
		return {
			hovered: false,
			active: false,
			slotVariant: `item-variant-${Math.floor(Math.random() * 4) + 1}`,
		}
	},
	watch:{
		disabled: function(newValue) {
			if (newValue) {
				this.$emit('updateSelected', 0)
			}
		},
	},
	computed: {
		selected: {
			get() {
				if(this.checkboxIndex === this.selectedIndex) {
					return true
				} else {
					return false
				}
			},
			set(value) {
				this.$emit('updateSelected', this.checkboxIndex)
			},
		},
	},
	methods: {},
}
