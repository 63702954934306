import { Graphics } from 'pixi.js'
import POI from '../shared/poi.shared'
import { UpdatableContainer } from '../../world/client/middleground-renderer'
import { clientConfig } from '../../engine/client/client-config'
import Renderer from '../../engine/client/graphics/renderer'
import { UI } from '../../ui/ui'
import { GameClient } from '../../engine/client/game-client'
import { debounce } from 'lodash'
import { ColliderType } from '../../collision/shared/colliders'

export class ClientPOI extends POI {
	model: UpdatableContainer

	constructor(poi: POI) {
		super()

		this.model = new UpdatableContainer()
		this.model.name = 'poi'
		this.x = poi.x
		this.y = poi.y

		this.wormMailId = poi.wormMailId

		if (clientConfig.debug) {
			if (poi.shapeString === 'circle') {
				const circle = new Graphics()
				circle.beginFill(0xffffff, 0.1)
				circle.lineStyle(8, 0xffffff, 0.5)
				circle.drawCircle(0, 0, poi.radius)
				circle.endFill()

				this.model.addChild(circle)
			} else {
				const rect = new Graphics()
				rect.beginFill(0xffffff, 0.1)
				rect.lineStyle(8, 0xffffff, 0.5)
				rect.drawRect(-poi.w / 2, -poi.h / 2, poi.w, poi.h)
				rect.endFill()

				this.model.addChild(rect)
			}

			if(poi.arenaShapeString !== '') {
				const arenaShapes = JSON.parse(poi.arenaShapeString)

				for(const arenaShape of arenaShapes) {
					const shape = new Graphics()
					shape.beginFill(0x0ff0f, 0.1)
					shape.lineStyle(8, 0xffffff, 0.5)

					switch(arenaShape.type) {
						case ColliderType.Circle:
							shape.drawCircle(arenaShape.position[0], arenaShape.position[1], arenaShape.radius)
							break
						case ColliderType.Box:
							// not right, probably just don't take position into account
							// and then apply it to the model
							// shape.drawRect((-arenaShape.width / 2) - (arenaShape.position[0]/2), (-arenaShape.height / 2) + (arenaShape.position[1]/2), arenaShape.width, arenaShape.height)
							break
						case ColliderType.Ellipse:
							shape.drawEllipse(arenaShape.position[0], arenaShape.position[1], arenaShape.rX, arenaShape.rY)
							break
					}

					shape.endFill()
					this.model.addChild(shape)
				}
			}
		}

		Renderer.getInstance().mgRenderer.addDisplayObjectToScene(this.model)
	}
}

export function getClientPOIFromLocalPlayer() {
	const player = getLocalPlayer()
	if (player && player.poiIndex >= 0) {
		const poi = getClientPOIFromIndex(player.poiIndex)
		return poi
	}
	return null
}

export function getClientPOIFromIndex(poiIndex: number): ClientPOI {
	const entities = GameClient.getInstance().entities
	for (const entity of entities.values()) {
		if (entity instanceof ClientPOI) {
			if (entity.poiIndex === poiIndex) {
				return entity
			}
		}
	}
	return undefined
}

export function getLocalPlayer() {
	return GameClient.getInstance().state.myEntity
}

function isLocalPlayerInPOI(poi: ClientPOI) {
	const localPlayer = getLocalPlayer()
	if (localPlayer && localPlayer.poiIndex !== -1) {
		return poi.poiIndex === localPlayer.poiIndex
	}
	return false
}

const updateBossHealthDebounced = debounce(function(health) {
	UI.getInstance().emitEvent('hud/bossHealth', health)
}, 100)

export const clientPOIHooks = {
	eventActive(entity: ClientPOI, newValue: number, isMyEntity: boolean): void {
		if (isLocalPlayerInPOI(entity)) {
			UI.getInstance().emitEvent('hud/poiEventActive', newValue)
			UI.getInstance().emitEvent('genericYesNo/closeActiveYesNoPanel', 'leaveArena')
		}
	},
	progress(entity: ClientPOI, newValue: number, isMyEntity: boolean): void {
		if (isLocalPlayerInPOI(entity)) {
			updateBossHealthDebounced(newValue)
		}
	},
	eventResetCountdown(entity: ClientPOI, newValue: number, isMyEntity: boolean): void {
		if (isLocalPlayerInPOI(entity)) {
			UI.getInstance().emitEvent('hud/poiRespawnTimer', newValue)
		}
	},
	type(entity: ClientPOI, newValue: number, isMyEntity: boolean): void {
		if (isLocalPlayerInPOI(entity)) {
			UI.getInstance().emitEvent('hud/poiType', newValue)
		}
	},
}
