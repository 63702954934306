import nengi from 'nengi'

export default class LeavePartyCommand {
	static protocol = {
		partyId: nengi.String,
	}

	partyId: string

	constructor(partyId: string) {
		this.partyId = partyId
		// 1-bit command
	}
}
