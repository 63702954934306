import { Vector } from "sat";
import { VectorXY, smoothDampV } from "../../utils/math";
import { timeInSeconds } from "../../utils/primitive-types";
import { movementConfig } from "./client-player-movement";
import { ClientPlayer } from "./player.client";

export class PlayerPositionSmoother {
	readonly clientPosSmoothed: Vector = new Vector();
	private readonly player: ClientPlayer;
	private readonly smoothVel: Vector = new Vector();

	constructor(player: ClientPlayer) {
		this.player = player
		this.clientPosSmoothed.x = player.x;
		this.clientPosSmoothed.y = player.y;
	}

	update(newPos: VectorXY, delta: timeInSeconds) {
		const prevClientX = this.clientPosSmoothed.x
		const prevClientY = this.clientPosSmoothed.y

		if (movementConfig.useLerp) {
			this.clientPosSmoothed.x = Math.lerp(this.clientPosSmoothed.x, newPos.x, movementConfig.lerp);
			this.clientPosSmoothed.y = Math.lerp(this.clientPosSmoothed.y, newPos.y, movementConfig.lerp);
		} else {
			movementConfig.smoothTime = Math.clamp(movementConfig.smoothTime, 0.001, 1)
			smoothDampV(this.clientPosSmoothed, this.smoothVel, newPos, delta, movementConfig.smoothTime);
		}

		this.player.model.x = this.clientPosSmoothed.x;
		this.player.model.y = this.clientPosSmoothed.y;

		const xdiff = prevClientX - this.clientPosSmoothed.x
		const ydiff = prevClientY - this.clientPosSmoothed.y
		if (Math.abs(xdiff) > 1 || Math.abs(ydiff) > 1) {
			return true
		}
		return false
	}
}