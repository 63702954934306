



































const TIER_DETAILS = [
	{ tierId: 'silver', dividerText: 'Silver Tier', price: '$4.99' },
	{ tierId: 'gold', dividerText: 'Gold Tier', price: '$7.99' },
	{ tierId: 'diamond', dividerText: 'Diamond Tier', price: '$14.99' },
]

import { mapActions, mapGetters } from 'vuex'
import PanelDivider from '../panel-divider/panel-divider.vue'
import TextButton from '../buttons/text-button.vue'

export default {
	name: 'SubscriptionItemContainer',
	components: {
		PanelDivider,
		TextButton,
	},
	props: {
		tierIdentifier: {
			type: String,
			required: true,
			validator: function(value) {
				return TIER_DETAILS.some(({ tierId }) => tierId === value)
			},
		},
		subTier: {
			type: String,
			required: true,
			default: 'Default',
		},
		// Prop to use in for loop of tier options
		tierOptions: {
			type: [Array, Object],
			required: true,
			default: () => {
				console.warn('The default tierOptions handler for the <subscriptionItemContainer /> component has been called. Did you forget to pass an :tier-options handler to the component with some data about this tier?')
				return []
			},
		},
	},

	data() {
		return {
			tier: TIER_DETAILS.find(({ tierId }) => tierId === this.tierIdentifier),
			hoverActive: false,
		}
	},
	methods: {
		isMouseHoveringDetailsContainer() {
			this.hoverActive = !this.hoverActive
		},
	},
}
