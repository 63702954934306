









import { mapGetters } from 'vuex'
import { UI } from '../../ui'

export default {
	name: 'StoredStateCheckbox',
	props: {
		labelId: {
			type: String,
			required: false,
			default: 'default',
		},
		error: {
			type: [Boolean, String],
			required: false,
			default: false,
		},
	},
	data() {
		return {
			checkboxInput: false,
		}
	},
	computed: {
		...mapGetters('itemComparison', ['showingAugments']),
		augChecked: {
			set(val) {
				UI.getInstance().emitEvent('itemComparison/setShowingAugments', val)
			},
			get() {
				return this.showingAugments
			},
		},
	},
	watch: {
		checkboxInput(newValue) {
			UI.getInstance().emitEvent('itemComparison/setShowingAugments', newValue)
		},
	},
	methods: {},
}
