import nengi from 'nengi'

export default class PlayerResurrected {
	static protocol = {
		entityId: { type: nengi.Number },
	}

	entityId: number

	constructor(entityId: number) {
		this.entityId = entityId
	}
}
