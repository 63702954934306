import nengi from 'nengi'
import { uuid } from '../../utils/primitive-types'

class ItemIdentifiedMessage {
	static protocol = {
		itemId: { type: nengi.String },
		identifiedFromDelivery: { type: nengi.Boolean },
	}

	itemId: uuid
	identifiedFromDelivery: boolean

	constructor(itemId: uuid, identifiedFromDelivery: boolean) {
		this.itemId = itemId
		this.identifiedFromDelivery = identifiedFromDelivery
	}
}

export default ItemIdentifiedMessage
