import nengi from 'nengi'
export interface PlayerSettings {
	preferredLanguage: string
	sfxEnabled: boolean
	musicEnabled: boolean
	//fullScreen: boolean
	sfxVolume: number
	bgmVolume: number
	screenShakePercentage: number
	particleDensityPercentage: number
	projectileTrail: boolean
	projectileOnHit: boolean
	ambientParticles: boolean
	graphicsQuality: 'low' | 'medium' | 'high'
	keymapUp: number
	keymapDown: number
	keymapLeft: number
	keymapRight: number
	keymapWeaponOne: number
	keymapWeaponTwo: number
	keymapAbilityOne: number
	keymapAbilityTwo: number
	keymapAbilityThree: number
	region: string
}

export class UpdatePlayerSettingsCommand {
	static protocol = {
		preferredLanguage: nengi.String,
		sfxEnabled: nengi.Boolean,
		musicEnabled: nengi.Boolean,
		//fullScreen: nengi.Boolean,
		sfxVolume: nengi.Int8,
		bgmVolume: nengi.Int8,
		screenShakePercentage: nengi.Int8,
		particleDensityPercentage: nengi.Int8,
		projectileTrail: nengi.Boolean,
		ambientParticles: nengi.Boolean,
		graphicsQuality: nengi.String,
		keymapUp: nengi.Int10,
		keymapDown: nengi.Int10,
		keymapLeft: nengi.Int10,
		keymapRight: nengi.Int10,
		keymapWeaponOne: nengi.Int10,
		keymapWeaponTwo: nengi.Int10,
		keymapAbilityThree: nengi.Int10,
		region: nengi.String,
	}

	constructor(settings: PlayerSettings) {
		Object.assign(this, settings)
	}
}

export class UpdatedPlayerSettingsMessage {
	static protocol = {
		updateStatus: nengi.String,
		errorMessage: nengi.String,
	}
	updateStatus: 'ok' | 'error'
	errorMessage: string

	constructor(settingsMessage: UpdatedPlayerSettingsMessage) {
		Object.assign(this, settingsMessage)
	}
}
