



























import { mapGetters } from 'vuex'
import { progressionTooltip } from '../reusable-components/v-tooltip/v-tooltip-functions'
import { getBiomePrettyName } from '../../biome/shared/biome-list'
import { getWorldDifficultyPrettyString } from '../../engine/shared/game-data/world-difficulty'
import { PRESSURE_MENU_UNLOCK_TIERS_DEFEATED } from '../../engine/shared/game-data/pressure-stat-info.shared'
import { GameModeType } from '../../engine/shared/game-mode-type'
import { PROGRESSION_MAX_TIER_FOR_BETA } from '../../engine/shared/game-data/progression'
import PlayerProgressIcon from '../reusable-components/icons/player-progress-icon.vue'

export default {
	name: 'ProgressionHUD',
	components: {
		PlayerProgressIcon,
	},
	computed: {
		...mapGetters('hud', ['furthestBiome', 'furthestWorldTier']),
		worldTierImgSrc() {
			return './assets/world-tier-' + this.furthestWorldTier + '.png'
		},
		incompletedBiomeName() {
			return getBiomePrettyName(this.furthestBiome, GameModeType.Adventure)
		},
		worldTierString() {
			const clampedTier = Math.min(this.worldTier, PROGRESSION_MAX_TIER_FOR_BETA)
			if (clampedTier <= PRESSURE_MENU_UNLOCK_TIERS_DEFEATED) {
				const difficulty = getWorldDifficultyPrettyString(clampedTier)
				return `Difficulty ${difficulty}`
			} else {
				return `Soul Cycle ${clampedTier - PRESSURE_MENU_UNLOCK_TIERS_DEFEATED}`
			}
		},
		worldTier() {
			//return 1 // for testing
			return this.furthestWorldTier
		},
	},
	methods: {
		progressionTooltip,
	},
}
