import { Vector } from 'sat'
import { BuffIdentifier } from '../../../../buffs/shared/buff.shared'
import { ParticleEffectType } from '../../../../engine/shared/game-data/particle-config'
import { BurstFireModes, ModType, ModValueType } from '../../../../projectiles/shared/projectile-types'
import { Colors } from '../../../../utils/colors'
import { degToRad } from '../../../../utils/math'
import { Ability, AbilityTargets, AbilityTargetSelectionStyle, AbilityType } from '../../action-types'
import { EnemyDefaults } from '../enemy-defaults'
import { deepClone } from '../../abilities.test'

const novaSweepAmplitudeEasy = [{ modType: ModType.STRAIGHT }, { modType: ModType.WAVE, amplitude: { modValueType: ModValueType.VALUE, value: 0.2 }, period: { modValueType: ModValueType.VALUE, value: 1.5 } }]
const novaSweepAmplitudeMediumPlus = [{ modType: ModType.STRAIGHT }, { modType: ModType.WAVE, amplitude: { modValueType: ModValueType.VALUE, value: 0.5 }, period: { modValueType: ModValueType.VALUE, value: 1.5 } }]

export const idlyMoveToPositionAbility: Ability = {
	debugName: 'moveToPosition',
	abilityType: AbilityType.MOVE_TOWARDS_LOCATION,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.RANDOM_POSITIONS_IN_SHAPE,
	shapeNames: ['pond', 'center', 'northwest', 'southwest', 'south', 'east', 'northeast'],
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
	moveRules: {
		movementSpeed: 200,
		minDistance: 100,
		maxDistance: 800,
	},
}


export const cooldownEnemyCooldown15000: Ability = {
	debugName: 'cooldown15000',
	abilityType: AbilityType.INSTANTLY_APPLY_EFFECT,
	buffToApply: BuffIdentifier.EnemyCooldown15000,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.MYSELF,
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
}

export const moveToRandomPositionAbility: Ability = {
	debugName: 'moveToPosition',
	abilityType: AbilityType.MOVE_TOWARDS_LOCATION,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.RANDOM_POSITIONS_IN_SHAPE,
	shapeNames: ['center', 'northwest', 'southwest', 'south', 'east', 'northeast'],
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
	moveRules: {
		movementSpeed: 600,
		minDistance: 100,
		maxDistance: 1500,
	},
}

export const moveToRandomPositionFastAbility: Ability = {
	debugName: 'moveToPosition',
	abilityType: AbilityType.MOVE_TOWARDS_LOCATION,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.RANDOM_POSITIONS_IN_SHAPE,
	shapeNames: ['center', 'northwest', 'southwest', 'south', 'east', 'northeast'],
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
	moveRules: {
		movementSpeed: 1000,
		minDistance: 100,
		maxDistance: 1500,
	},
}

export const moveToPondPositionAbility: Ability = {
	debugName: 'moveToPosition',
	abilityType: AbilityType.MOVE_TOWARDS_LOCATION,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.RANDOM_POSITIONS_IN_SHAPE,
	shapeNames: ['pond'],
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
	moveRules: {
		movementSpeed: 600,
		minDistance: 100,
		maxDistance: 1500,
	},
}

export const novaAttack360Ability: Ability = {
	debugName: 'novaAttack360',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.MYSELF,
	validTargetSelection: AbilityTargetSelectionStyle.FIRST,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		speed: 700,
		lifespanInSeconds: 2.5,
		projectileCount: 24,
		colliderRadius: 20,
		spreadAngle: degToRad(360),
		basePhysicalDamage: 60,
		baseLightningDamage: 0,
		burstCount: 1,
		modifiers: [{ modType: ModType.STRAIGHT }, { modType: ModType.ACCELERATION, value: { modValueType: ModValueType.RANDOM, min: -0.8, max: -0.4 } }],
		color: Colors.red,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_FORESTBOSS,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
	},
	attackOffset: new Vector(0, -2000),
	ignoreAngleOnWeaponOffset: true,
}

export const novaAttack360AbilityV2: Ability = {
	debugName: 'novaAttack360',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.MYSELF,
	validTargetSelection: AbilityTargetSelectionStyle.FIRST,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		speed: 700,
		lifespanInSeconds: 2.5,
		projectileCount: 36,
		colliderRadius: 20,
		spreadAngle: degToRad(360),
		burstCount: 1,
		basePhysicalDamage: 80,
		baseLightningDamage: 0,
		modifiers: [{ modType: ModType.STRAIGHT }, { modType: ModType.ACCELERATION, value: { modValueType: ModValueType.RANDOM, min: -0.9, max: -0.5 } }],
		color: Colors.red,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_FORESTBOSS,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
	},
	attackOffset: new Vector(0, -2000),
	ignoreAngleOnWeaponOffset: true,
}

export const novaAttack360AbilityV3: Ability = {
	debugName: 'novaAttack360',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.MYSELF,
	validTargetSelection: AbilityTargetSelectionStyle.FIRST,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		speed: 700,
		lifespanInSeconds: 2.5,
		projectileCount: 48,
		colliderRadius: 20,
		spreadAngle: degToRad(360),
		burstCount: 1,
		basePhysicalDamage: 100,
		baseLightningDamage: 0,
		modifiers: [{ modType: ModType.STRAIGHT }, { modType: ModType.ACCELERATION, value: { modValueType: ModValueType.RANDOM, min: -1.0, max: -0.6 } }],
		color: Colors.red,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_FORESTBOSS,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
	},
	attackOffset: new Vector(0, -2000),
	ignoreAngleOnWeaponOffset: true,
}

export const novaAttackSpreadAbility: Ability = {
	debugName: 'novaAttackSpread',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.CLOSEST,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		speed: 200,
		lifespanInSeconds: 2,
		projectileCount: 10,
		colliderRadius: 20,
		spreadAngle: degToRad(80),
		burstCount: 1,
		basePhysicalDamage: 25,
		baseLightningDamage: 20,
		modifiers: [{ modType: ModType.STRAIGHT }, { modType: ModType.ACCELERATION, value: { modValueType: ModValueType.RANDOM, min: 1.2, max: 2.0 } }],
		color: Colors.red,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_FORESTBOSS,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
	},
	attackOffset: new Vector(0, -2000),
	ignoreAngleOnWeaponOffset: true,
}

export const novaAttackSpreadAbilityV2: Ability = {
	debugName: 'novaAttackSpread',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.CLOSEST,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		speed: 200,
		lifespanInSeconds: 2,
		projectileCount: 12,
		colliderRadius: 20,
		spreadAngle: degToRad(80),
		basePhysicalDamage: 25,
		baseLightningDamage: 25,
		burstCount: 1,
		modifiers: [{ modType: ModType.STRAIGHT }, { modType: ModType.ACCELERATION, value: { modValueType: ModValueType.RANDOM, min: 1.5, max: 2.6 } }],
		color: Colors.red,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_FORESTBOSS,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
	},
	attackOffset: new Vector(0, -2000),
	ignoreAngleOnWeaponOffset: true,
}

export const novaAttackSpreadAbilityV3: Ability = {
	debugName: 'novaAttackSpread',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.CLOSEST,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		speed: 200,
		lifespanInSeconds: 2,
		projectileCount: 14,
		colliderRadius: 20,
		spreadAngle: degToRad(80),
		basePhysicalDamage: 20,
		baseLightningDamage: 30,
		burstCount: 1,
		modifiers: [{ modType: ModType.STRAIGHT }, { modType: ModType.ACCELERATION, value: { modValueType: ModValueType.RANDOM, min: 1.8, max: 3.2 } }],
		color: Colors.red,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_FORESTBOSS,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
	},
	attackOffset: new Vector(0, -2000),
	ignoreAngleOnWeaponOffset: true,
}

export const novaAttackSweepAbility: Ability = {
	debugName: 'novaAttackSweep',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		speed: 800,
		lifespanInSeconds: 3,
		projectileCount: 1,
		colliderRadius: 20,
		spreadAngle: degToRad(80),
		basePhysicalDamage: 25,
		baseLightningDamage: 20,
		burstCount: 8,
		burstDelay: 0.1,
		burstMode: BurstFireModes.SWEEPING,
		burstAngle: degToRad(100),
		modifiers: novaSweepAmplitudeEasy,
		color: Colors.red,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_FORESTBOSS,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
	},
	attackOffset: new Vector(0, -2000),
	ignoreAngleOnWeaponOffset: true,
}

export const novaAttackSweepAbilityV2: Ability = {
	debugName: 'novaAttackSweep',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		speed: 850,
		lifespanInSeconds: 3,
		projectileCount: 1,
		colliderRadius: 20,
		spreadAngle: degToRad(80),
		basePhysicalDamage: 25,
		baseLightningDamage: 25,
		burstCount: 12,
		burstDelay: 0.1,
		burstMode: BurstFireModes.SWEEPING,
		burstAngle: degToRad(100),
		modifiers: novaSweepAmplitudeEasy,
		color: Colors.red,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_FORESTBOSS,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
	},
	attackOffset: new Vector(0, -2000),
	ignoreAngleOnWeaponOffset: true,
}

export const novaAttackSweepAbilityV3: Ability = {
	debugName: 'novaAttackSweep',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		speed: 900,
		lifespanInSeconds: 3,
		projectileCount: 1,
		colliderRadius: 20,
		spreadAngle: degToRad(80),
		basePhysicalDamage: 35,
		baseLightningDamage: 45,
		burstCount: 16,
		burstDelay: 0.1,
		burstMode: BurstFireModes.SWEEPING,
		burstAngle: degToRad(100),
		modifiers: novaSweepAmplitudeEasy,
		color: Colors.red,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_FORESTBOSS,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
	},
	attackOffset: new Vector(0, -2000),
	ignoreAngleOnWeaponOffset: true,
}

export const shotSprayAttackAbility: Ability = {
	debugName: 'shotSprayAttack',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		speed: 300,
		lifespanInSeconds: 3,
		projectileCount: 5,
		colliderRadius: 20,
		spreadAngle: degToRad(15),
		basePhysicalDamage: 0,
		baseLightningDamage: 15,
		burstCount: 1,
		modifiers: [{ modType: ModType.STRAIGHT }, { modType: ModType.ACCELERATION, value: { modValueType: ModValueType.RANDOM, min: 1.8, max: 3.2 } }],
		color: Colors.red,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_SPRAYFORESTBOSS,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
	},
	attackOffset: new Vector(700, -1100),
	ignoreAngleOnWeaponOffset: true,
}

export const shotSprayAttackAbilityV2: Ability = {
	debugName: 'shotSprayAttack',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		speed: 350,
		lifespanInSeconds: 3,
		projectileCount: 8,
		colliderRadius: 20,
		spreadAngle: degToRad(20),
		basePhysicalDamage: 0,
		baseLightningDamage: 20,
		burstCount: 1,
		modifiers: [{ modType: ModType.STRAIGHT }, { modType: ModType.ACCELERATION, value: { modValueType: ModValueType.RANDOM, min: 1.8, max: 3.2 } }],
		color: Colors.red,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_SPRAYFORESTBOSS,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
	},
	attackOffset: new Vector(700, -1100),
	ignoreAngleOnWeaponOffset: true,
}

export const shotSprayAttackAbilityV3: Ability = {
	debugName: 'shotSprayAttack',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		speed: 400,
		lifespanInSeconds: 3,
		projectileCount: 11,
		colliderRadius: 20,
		spreadAngle: degToRad(25),
		basePhysicalDamage: 0,
		baseLightningDamage: 25,
		burstCount: 1,
		modifiers: [{ modType: ModType.STRAIGHT }, { modType: ModType.ACCELERATION, value: { modValueType: ModValueType.RANDOM, min: 1.8, max: 3.2 } }],
		color: Colors.red,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_SPRAYFORESTBOSS,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
	},
	attackOffset: new Vector(700, -1100),
	ignoreAngleOnWeaponOffset: true,
}

export const healingSpringAbility: Ability = {
	debugName: 'healingSpring',
	abilityType: AbilityType.BUFF_UNTIL_DAMAGE_RECEIVED_THRESHOLD,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.MYSELF,
	validTargetSelection: AbilityTargetSelectionStyle.FIRST,
	healRules: {
		damageThresholdAsPercentOfMax: 0.15,
	},
	buffToApply: BuffIdentifier.EnemyForestBossHeal,
}


export const healingSpringAbilityEasy = deepClone(healingSpringAbility)
export const healingSpringAbilityMedium = deepClone(healingSpringAbility)
	healingSpringAbilityMedium.healRules.damageThresholdAsPercentOfMax = 0.20
export const healingSpringAbilityBrutal = deepClone(healingSpringAbility)
	healingSpringAbilityBrutal.buffToApply = BuffIdentifier.EnemyForestBossHealBrutal
export const healingSpringAbilityNasty = deepClone(healingSpringAbility)


export const moveToRandomPositionAbilityEasy = deepClone(moveToRandomPositionAbility)
export const moveToRandomPositionAbilityMedium = deepClone(moveToRandomPositionAbility)
	moveToRandomPositionAbilityMedium.moveRules.movementSpeed = 800
export const moveToRandomPositionAbilityBrutal = deepClone(moveToRandomPositionAbility)
	moveToRandomPositionAbilityBrutal.moveRules.movementSpeed = 900
export const moveToRandomPositionAbilityNasty = deepClone(moveToRandomPositionAbility)

export const moveToRandomPositionFastAbilityEasy = deepClone(moveToRandomPositionFastAbility)
export const moveToRandomPositionFastAbilityMedium = deepClone(moveToRandomPositionFastAbility)
	moveToRandomPositionFastAbilityMedium.moveRules.movementSpeed = 1200
export const moveToRandomPositionFastAbilityBrutal = deepClone(moveToRandomPositionFastAbility)
	moveToRandomPositionFastAbilityBrutal.moveRules.movementSpeed = 1300
export const moveToRandomPositionFastAbilityNasty = deepClone(moveToRandomPositionFastAbility)

export const moveToPondPositionAbilityEasy = deepClone(moveToPondPositionAbility)
export const moveToPondPositionAbilityMedium = deepClone(moveToPondPositionAbility)
	moveToPondPositionAbilityMedium.moveRules.movementSpeed = 1200
export const moveToPondPositionAbilityBrutal = deepClone(moveToPondPositionAbility)
	moveToPondPositionAbilityBrutal.moveRules.movementSpeed = 1300
export const moveToPondPositionAbilityNasty = deepClone(moveToPondPositionAbility)

export const novaAttack360AbilityEasy = deepClone(novaAttack360Ability)
export const novaAttack360AbilityMedium = deepClone(novaAttack360Ability)
	novaAttack360AbilityMedium.projectileConfig.modifiers = [{ modType: ModType.STRAIGHT }, { modType: ModType.ACCELERATION, value: { modValueType: ModValueType.RANDOM, min: -0.6, max: -0.2 } }]
export const novaAttack360AbilityBrutal = deepClone(novaAttack360Ability)
	novaAttack360AbilityBrutal.projectileConfig.lifespanInSeconds = 3.5
	novaAttack360AbilityBrutal.projectileConfig.modifiers = [{ modType: ModType.STRAIGHT }, { modType: ModType.ACCELERATION, value: { modValueType: ModValueType.RANDOM, min: -0.6, max: -0.2 } }]
export const novaAttack360AbilityNasty = deepClone(novaAttack360Ability)

export const novaAttack360AbilityV2Easy = deepClone(novaAttack360AbilityV2)
export const novaAttack360AbilityV2Medium = deepClone(novaAttack360AbilityV2)
	novaAttack360AbilityV2Medium.projectileConfig.modifiers = [{ modType: ModType.STRAIGHT }, { modType: ModType.ACCELERATION, value: { modValueType: ModValueType.RANDOM, min: -0.5, max: -0.1 } }]
export const novaAttack360AbilityV2Brutal = deepClone(novaAttack360AbilityV2)
	novaAttack360AbilityV2Brutal.projectileConfig.lifespanInSeconds = 3.5
	novaAttack360AbilityV2Brutal.projectileConfig.modifiers = [{ modType: ModType.STRAIGHT }, { modType: ModType.ACCELERATION, value: { modValueType: ModValueType.RANDOM, min: -0.5, max: -0.1 } }]
export const novaAttack360AbilityV2Nasty = deepClone(novaAttack360AbilityV2)

export const novaAttack360AbilityV3Easy = deepClone(novaAttack360AbilityV3)
export const novaAttack360AbilityV3Medium = deepClone(novaAttack360AbilityV3)
	novaAttack360AbilityV3Medium.projectileConfig.modifiers = [{ modType: ModType.STRAIGHT }, { modType: ModType.ACCELERATION, value: { modValueType: ModValueType.RANDOM, min: -0.4, max: 0 } }]
export const novaAttack360AbilityV3Brutal = deepClone(novaAttack360AbilityV3)
	novaAttack360AbilityV3Brutal.projectileConfig.lifespanInSeconds = 3.5
	novaAttack360AbilityV3Brutal.projectileConfig.modifiers = [{ modType: ModType.STRAIGHT }, { modType: ModType.ACCELERATION, value: { modValueType: ModValueType.RANDOM, min: -0.4, max: 0 } }]
export const novaAttack360AbilityV3Nasty = deepClone(novaAttack360AbilityV3)

export const novaAttackSpreadAbilityEasy = deepClone(novaAttackSpreadAbility)
export const novaAttackSpreadAbilityMedium = deepClone(novaAttackSpreadAbility)
export const novaAttackSpreadAbilityBrutal = deepClone(novaAttackSpreadAbility)
export const novaAttackSpreadAbilityNasty = deepClone(novaAttackSpreadAbility)

export const novaAttackSpreadAbilityV2Easy = deepClone(novaAttackSpreadAbilityV2)
export const novaAttackSpreadAbilityV2Medium = deepClone(novaAttackSpreadAbilityV2)
export const novaAttackSpreadAbilityV2Brutal = deepClone(novaAttackSpreadAbilityV2)
export const novaAttackSpreadAbilityV2Nasty = deepClone(novaAttackSpreadAbilityV2)

export const novaAttackSpreadAbilityV3Easy = deepClone(novaAttackSpreadAbilityV3)
export const novaAttackSpreadAbilityV3Medium = deepClone(novaAttackSpreadAbilityV3)
export const novaAttackSpreadAbilityV3Brutal = deepClone(novaAttackSpreadAbilityV3)
export const novaAttackSpreadAbilityV3Nasty = deepClone(novaAttackSpreadAbilityV3)

export const novaAttackSweepAbilityEasy = deepClone(novaAttackSweepAbility)
export const novaAttackSweepAbilityMedium = deepClone(novaAttackSweepAbility)
	novaAttackSweepAbilityMedium.projectileConfig.modifiers = novaSweepAmplitudeMediumPlus
export const novaAttackSweepAbilityBrutal = deepClone(novaAttackSweepAbility)
export const novaAttackSweepAbilityNasty = deepClone(novaAttackSweepAbility)

export const novaAttackSweepAbilityV2Easy = deepClone(novaAttackSweepAbilityV2)
export const novaAttackSweepAbilityV2Medium = deepClone(novaAttackSweepAbilityV2)
	novaAttackSweepAbilityV2Medium.projectileConfig.modifiers = novaSweepAmplitudeMediumPlus
export const novaAttackSweepAbilityV2Brutal = deepClone(novaAttackSweepAbilityV2)
export const novaAttackSweepAbilityV2Nasty = deepClone(novaAttackSweepAbilityV2)

export const novaAttackSweepAbilityV3Easy = deepClone(novaAttackSweepAbilityV3)
export const novaAttackSweepAbilityV3Medium = deepClone(novaAttackSweepAbilityV3)
	novaAttackSweepAbilityV3Medium.projectileConfig.modifiers = novaSweepAmplitudeMediumPlus
export const novaAttackSweepAbilityV3Brutal = deepClone(novaAttackSweepAbilityV3)
export const novaAttackSweepAbilityV3Nasty = deepClone(novaAttackSweepAbilityV3)

export const shotSprayAttackAbilityEasy = deepClone(shotSprayAttackAbility)
export const shotSprayAttackAbilityMedium = deepClone(shotSprayAttackAbility)
shotSprayAttackAbilityMedium.projectileConfig.spreadAngle *= 1.20
export const shotSprayAttackAbilityBrutal = deepClone(shotSprayAttackAbilityMedium)
shotSprayAttackAbilityBrutal.projectileConfig.spreadAngle *= 1.25
shotSprayAttackAbilityBrutal.projectileConfig.baseLightningDamage = 25
export const shotSprayAttackAbilityNasty = deepClone(shotSprayAttackAbilityMedium)

export const shotSprayAttackAbilityV2Easy = deepClone(shotSprayAttackAbilityV2)
export const shotSprayAttackAbilityV2Medium = deepClone(shotSprayAttackAbilityV2)
shotSprayAttackAbilityV2Medium.projectileConfig.spreadAngle *= 1.15
export const shotSprayAttackAbilityV2Brutal = deepClone(shotSprayAttackAbilityV2Medium)
shotSprayAttackAbilityV2Brutal.projectileConfig.spreadAngle *= 1.20
shotSprayAttackAbilityV2Brutal.projectileConfig.baseLightningDamage = 30
export const shotSprayAttackAbilityV2Nasty = deepClone(shotSprayAttackAbilityV2Medium)

export const shotSprayAttackAbilityV3Easy = deepClone(shotSprayAttackAbilityV3)
export const shotSprayAttackAbilityV3Medium = deepClone(shotSprayAttackAbilityV3)
	shotSprayAttackAbilityV3Medium.projectileConfig.spreadAngle *= 1.10
export const shotSprayAttackAbilityV3Brutal = deepClone(shotSprayAttackAbilityV3Medium)
shotSprayAttackAbilityV3Brutal.projectileConfig.spreadAngle *= 1.15
shotSprayAttackAbilityV3Brutal.projectileConfig.baseLightningDamage = 35
export const shotSprayAttackAbilityV3Nasty = deepClone(shotSprayAttackAbilityV3Medium)















