






































import { mapGetters } from 'vuex'
import { getHeatImageFile } from '../../../engine/client/pressure.client'
import { pressureStatInfos } from '../../../engine/shared/game-data/pressure-stat-info.shared'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const heatImages = require('../inventory-item/assets/heat-*.png')


export default {
    name: 'SelectedSoulBonds',
    components: {},
    data() {
        return {
            heatImages: heatImages,
        }
    },
    computed: {
		...mapGetters('boatLaunch', ['hasReachedBiomeOnTier', 'maxAllowedTier', 'getPressureRank', 'hasUnlockedSoul']),
        difficultyModifiers: function () {
			const difficultyModifiers = []
			for (let pressureStat in pressureStatInfos) {
				if (pressureStatInfos.hasOwnProperty(pressureStat)) {
					difficultyModifiers.push({
						...pressureStatInfos[pressureStat],
						ranksSelected: this.getPressureRank(pressureStat),
						statType: pressureStat,
					})
				}
			}
			return difficultyModifiers
		},
        modifiersSectionHeight() {
			return (this.difficultyModifiers.length + 2) * 60
		},
    },
    methods: {
        getHeatImage(stat) {
			return heatImages[getHeatImageFile(stat)]
		},
    },
}
