import nengi from 'nengi'
import WeaponSubType from '../../loot/shared/weapon-sub-type'

export enum SKIN_SLOT {
	PLAYER_MAIN = 'player_main',
	PLAYER_TOP = 'player_top',
	PLAYER_FACE = 'player_face',
	PLAYER_BACK = 'player_back',
	PLAYER_MIDDLE = 'player_middle',
	PLAYER_LEGS = 'player_legs',
	PLAYER_FEET = 'player_feet',
	PLAYER_FOOTPRINT = 'player_footprint',
	PLAYER_AURA = 'player_aura',
	PET_MAIN = 'pet_main',
	PET_HAT = 'pet_hat',
	PET_NECK = 'pet_neck',
	PET_FACE = 'pet_face',
	WEAPON_WAND = 'weapon_wand',
	WEAPON_STAFF = 'weapon_staff',
	WEAPON_SWORD = 'weapon_sword',
	WEAPON_ARCANE = 'weapon_arcane',
	WEAPON_CROSSBOW = 'weapon_crossbow',
	WEAPON_SCYTHE = 'weapon_scythe',

	WEAPON_WAND_SECONDARY = 'weapon_wand_secondary',
	WEAPON_STAFF_SECONDARY = 'weapon_staff_secondary',
	WEAPON_SWORD_SECONDARY = 'weapon_sword_secondary',
	WEAPON_ARCANE_SECONDARY = 'weapon_arcane_secondary',
	WEAPON_CROSSBOW_SECONDARY = 'weapon_crossbow_secondary',
	WEAPON_SCYTHE_SECONDARY = 'weapon_scythe_secondary',
}

export const SKIN_SLOT_TO_PREFIX = {
	[SKIN_SLOT.PET_MAIN]: 'pet-',
	[SKIN_SLOT.WEAPON_WAND]: 'wand-',
	[SKIN_SLOT.WEAPON_STAFF]: 'staff-',
	[SKIN_SLOT.WEAPON_SWORD]: 'sword-',
	[SKIN_SLOT.WEAPON_ARCANE]: 'arcane-',
	[SKIN_SLOT.WEAPON_CROSSBOW]: 'crossbow-',
	[SKIN_SLOT.WEAPON_SCYTHE]: 'scythe-',
	[SKIN_SLOT.PLAYER_BACK]: 'back-',
	[SKIN_SLOT.PLAYER_FACE]: 'face-',
}

export const WEAPON_SKIN_PREFIXES = [
	SKIN_SLOT_TO_PREFIX[SKIN_SLOT.WEAPON_WAND],
	SKIN_SLOT_TO_PREFIX[SKIN_SLOT.WEAPON_STAFF],
	SKIN_SLOT_TO_PREFIX[SKIN_SLOT.WEAPON_SWORD],
	SKIN_SLOT_TO_PREFIX[SKIN_SLOT.WEAPON_ARCANE],
	SKIN_SLOT_TO_PREFIX[SKIN_SLOT.WEAPON_CROSSBOW],
	SKIN_SLOT_TO_PREFIX[SKIN_SLOT.WEAPON_SCYTHE],
]

export const SKIN_SLOT_TO_PRIZE_SUFFIX = {
	[SKIN_SLOT.PLAYER_MAIN] : ' Skin'
}

export const WEAPON_SUBTYPE_TO_SKIN_SLOT = {
	[WeaponSubType.ArcaneFocus] : SKIN_SLOT.WEAPON_ARCANE,
	[WeaponSubType.Crossbow] : SKIN_SLOT.WEAPON_CROSSBOW,
	[WeaponSubType.Scythe] : SKIN_SLOT.WEAPON_SCYTHE,
	[WeaponSubType.Spellsword] : SKIN_SLOT.WEAPON_SWORD,
	[WeaponSubType.Staff] : SKIN_SLOT.WEAPON_STAFF,
	[WeaponSubType.Wand] : SKIN_SLOT.WEAPON_WAND,	
}

export function isSkinSlotWeapon(skinSlot: SKIN_SLOT) {
	return skinSlot === SKIN_SLOT.WEAPON_ARCANE || skinSlot === SKIN_SLOT.WEAPON_ARCANE_SECONDARY ||  
		skinSlot === SKIN_SLOT.WEAPON_WAND || skinSlot === SKIN_SLOT.WEAPON_WAND_SECONDARY ||  
		skinSlot === SKIN_SLOT.WEAPON_STAFF || skinSlot === SKIN_SLOT.WEAPON_STAFF_SECONDARY ||  
		skinSlot === SKIN_SLOT.WEAPON_SWORD || skinSlot === SKIN_SLOT.WEAPON_SWORD_SECONDARY ||  
		skinSlot === SKIN_SLOT.WEAPON_CROSSBOW || skinSlot === SKIN_SLOT.WEAPON_CROSSBOW_SECONDARY ||
		skinSlot === SKIN_SLOT.WEAPON_SCYTHE || skinSlot === SKIN_SLOT.WEAPON_SCYTHE_SECONDARY
}

class SetSkinCommand {
	static protocol = {
		skinId: nengi.String,
		skinSlot: nengi.String,
	}

	skinId: string
	skinSlot: string

	constructor(skinId: string, skinSlot: SKIN_SLOT) {
		this.skinId = skinId
		this.skinSlot = skinSlot
	}
}

export default SetSkinCommand
