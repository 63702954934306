import { Loader, LoaderResource } from 'pixi.js'
import logger from '../../utils/client-logger'
import { reduce } from 'lodash'
import { propConfigs } from '../../world/shared/prop-configs'
import { ClientModelPaths, OnDemandClientModelPaths } from '../../models-animations/shared/model-paths'
import { BiomeIdentifier, getArrayOfAllBiomeIdentifiers } from '../../biome/shared/biome-list'
import { Effect } from '../../engine/client/graphics/pfx/effect'
import { EffectConfig } from '../../engine/client/graphics/pfx/effectConfig'
import { UI } from '../../ui/ui'
import { clientConfig } from '../../engine/client/client-config'
import { sharedPropConfigs } from '../../world/shared/prop-configs-shared'
import { debugConfig } from '../../engine/client/debug-config'
import { nonSensitiveEnvVarsMapping } from '../../utils/env'
import { addColliderConfigsToLookupCollidersClient } from '../../world/client/prop-setup-lookup-colliders'
import { EmoteAssets } from '../../chat/shared/emote-enums'

type AssetLoadingPhase = 1 | 2
export type AssetIdentifier = string
type AssetPath = string

interface AssetDefinition {
	name: AssetIdentifier
	path: AssetPath
	numberOfTransitiveAssets: number
	phase: AssetLoadingPhase
	dependsOnSpineAtlas?: AssetIdentifier
}

type WeaponPFXIdentifier = 'sword' | 'staff' | 'wand' | 'crossbow' | 'arcane-focus' | 'scythe'
type ElementalIdentifier = 'physical' | 'fire' | 'ice' | 'lightning' | 'poison'

const MAX_RETRY_ATTEMPTS = 10

export const LOADABLE_BIOMES: BiomeIdentifier[] = getArrayOfAllBiomeIdentifiers()
export const LOADABLE_WEAPON_PFX: WeaponPFXIdentifier[] = ['sword', 'staff', 'wand', 'crossbow', 'arcane-focus', 'scythe']
export const LOADABLE_ELEMENTS: ElementalIdentifier[] = ['physical', 'fire', 'ice', 'lightning', 'poison']
export const RARITIES = ['uncommon', 'common', 'rare', 'epic', 'legendary', 'astronomical']
export const AUGMENT_MODS = [
	'aim',
	'beam',
	'extra-damage',
	'fire',
	'ice',
	'lightning',
	'physical',
	'poison',
	'projectiles',
	'speed',
	'trajectory',
	'creepingdeath',
	'critswitch',
	'deccelerate',
	'diffusionbeam',
	'fastandweak',
	'fireconversion',
	'fireup',
	'iceconversion',
	'iceup',
	'lightningburst',
	'lightningconversion',
	'lightningup',
	'narrowspreadextraprojectiles',
	'physicalconversion',
	'physicalup',
	'pierce',
	'poisonconversion',
	'poisonup',
	'quickcharge',
	'rangeadjustingaccelerate',
	'sine',
	'slowandstrong',
	'splashdamage',
	'starlaser',
	'straightboomerang',
	'tightenspread',
	'widespreadextraprojectiles',
	'energetic-reinforcement',
	'elemental-devotion'
]

// Static assets
const assetList: AssetDefinition[] = [
	{
		name: 'water_shader_vert',
		path: './shaders/water.vert',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'wave_shader_frag',
		path: './shaders/waves.frag',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'water_blend_shader_vert',
		path: './shaders/water-blend.vert',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'wave_blend_shader_frag',
		path: './shaders/waves-blend.frag',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'ambient_water',
		path: './images/ambient_water.png',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'displacement_map',
		path: './images/displacement_map.png',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'navigation_arrow',
		path: './sprites/misc/tutorial-arrow-2.png',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'party_arrow',
		path: './sprites/misc/party-arrow.png',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'letter_bubble',
		path: './sprites/misc/letter-bubble.png',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'boss_credit_icon',
		path: './sprites/misc/boss-credit-icon.png',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'boss_no_credit_icon',
		path: './sprites/misc/boss-no-credit-icon.png',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'enemy_hp_bar',
		path: './ui-assets/hud/enemy-health-bar-bg.png',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'enemy_hp_bar_mask',
		path: './ui-assets/hud/enemy-health-bar-mask.png',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'enemy_hp_bar_cap',
		path: './ui-assets/hud/enemy-health-bar-embellishment.png',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'enemy_hp_gradient',
		path: './ui-assets/hud/enemy-health-gradient.png',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'waves_water',
		path: './images/waves_water.png',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'water_blend',
		path: './images/water-blend-texture.png',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'skele_mage_death_splat',
		path: './images/enemy_death_sprite_skelemage.png',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'itemIcons',
		path: './sprites/weapons/item_icons/item_icons.json',
		numberOfTransitiveAssets: 2,
		phase: 2,
	},
	{
		name: 'card-unveil',
		path: './sprites/ui/card-unveil.json',
		numberOfTransitiveAssets: 2,
		phase: 2,
	},
	{
		name: 'cardeffect-unveiling',
		path: './pfx/uieffects/cardeffect-unveiling-loot.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'champion-pfx',
		path: './pfx/champion.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'beam-start',
		path: './pfx/beam/beam-start.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'beam-center',
		path: './pfx/beam/beam-center.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'beam-end',
		path: './pfx/beam/beam-end.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},

	{
		name: 'pickup-pfx-common',
		path: './pfx/pickup-common.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'pickup-pfx-uncommon',
		path: './pfx/pickup-uncommon.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'pickup-pfx-rare',
		path: './pfx/pickup-rare.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'pickup-pfx-epic',
		path: './pfx/pickup-epic.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'pickup-pfx-legendary',
		path: './pfx/pickup-legendary.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'pickup-pfx-astronomical',
		path: './pfx/pickup-astronomical.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'pickup-pfx-chaos',
		path: './pfx/pickup-chaos.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},

	{
		name: 'damage-numbers',
		path: './pfx/damage-numbers/damage-numbers.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'boss-highlands-sheild',
		path: './pfx/bosseffects/boss-highlands-sheild.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'boss-highlands-sheildexplosive',
		path: './pfx/bosseffects/boss-highlands-sheildexplosive.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'boss-highlands-sheild-stutter',
		path: './pfx/bosseffects/boss-highlands-sheild-stutter.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'explosion-blood',
		path: './pfx/explosion_blood/explosion_blood.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'death-explosion',
		path: './pfx/death-explosion.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'enemy-projectile-01',
		path: './pfx/enemy-projectiles/enemy-projectile-01.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'enemy-projectile-trail',
		path: './pfx/enemy-projectiles/enemy-projectile-trail.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-fire-medium',
		path: './pfx/enemy-projectiles/projectile-eyebat-head.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-spell-small',
		path: './pfx/projectile/spell100.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-spell-medium',
		path: './pfx/projectile/spell200.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-spell-large',
		path: './pfx/projectile/spell300.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-long-spell-small',
		path: './pfx/projectile/longSpell100.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-long-spell-medium',
		path: './pfx/projectile/longSpell200.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-long-spell-large',
		path: './pfx/projectile/longSpell300.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-axe',
		path: './pfx/projectile/projectile-axe.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},

	{
		name: 'projectile-sporekid-head',
		path: './pfx/enemy-projectiles/projectile-sporekid-head.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-sporekid-trail',
		path: './pfx/enemy-projectiles/projectile-sporekid-trail.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-shamblingmound-head',
		path: './pfx/enemy-projectiles/projectile-shamblingmound-head.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-shamblingmound-trail',
		path: './pfx/enemy-projectiles/projectile-shamblingmound-trail.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-shrieker-head',
		path: './pfx/enemy-projectiles/projectile-shrieker-head.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-shrieker-extraarms-head',
		path: './pfx/enemy-projectiles/projectile-shrieker-extraarms-head.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-shrieker-trail',
		path: './pfx/enemy-projectiles/projectile-shrieker-trail.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-mushie-head',
		path: './pfx/enemy-projectiles/projectile-mushie-head.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-mushie-trail',
		path: './pfx/enemy-projectiles/projectile-mushie-trail.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-forestboss-shotspray',
		path: './pfx/enemy-projectiles/projectile-forestboss-shotspray.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'boss-forest-healingspring',
		path: './pfx/bosseffects/boss-forest-healingspring.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-forestboss-head',
		path: './pfx/enemy-projectiles/projectile-forestboss-head.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-fungiboss-head',
		path: './pfx/enemy-projectiles/projectile-fungiboss-head.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-fungiboss-trail',
		path: './pfx/enemy-projectiles/projectile-fungiboss-trail.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-beachskelemageboss-head',
		path: './pfx/enemy-projectiles/projectile-beachskelemageboss-head.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-beachcrabboss-head',
		path: './pfx/enemy-projectiles/projectile-beachcrabboss-head.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'boss-crab-dust',
		path: './pfx/bosseffects/boss-crab-dust.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-crabmonocle-head',
		path: './pfx/enemy-projectiles/projectile-crabmonocle-head.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-boss-highlands-shard',
		path: './pfx/enemy-projectiles/projectile-boss-highlands-shard.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'boss-highlands-stone',
		path: './pfx/bosseffects/boss-highlands-stone.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'boss-highlands-petrifyingwave',
		path: './pfx/bosseffects/boss-highlands-petrifyingwave.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'boss-gorgon-rune-glow',
		path: './pfx/bosseffects/boss-gorgon-rune-glow.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-boss-head',
		path: './pfx/enemy-projectiles/projectile-boss-head.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-boss-trail',
		path: './pfx/enemy-projectiles/projectile-boss-trail.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-distancer-head',
		path: './pfx/enemy-projectiles/projectile-distancer-head.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-distancer-trail',
		path: './pfx/enemy-projectiles/projectile-distancer-trail.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-blimpie-gas',
		path: './pfx/enemy-projectiles/projectile-blimpie-gas.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-blimpie-head',
		path: './pfx/enemy-projectiles/projectile-blimpie-head.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-blimpie-trail',
		path: './pfx/enemy-projectiles/projectile-blimpie-trail.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-skelemage-head',
		path: './pfx/enemy-projectiles/projectile-skelemage-head.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-skelemage-trail',
		path: './pfx/enemy-projectiles/projectile-skelemage-trail.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-spawner-head',
		path: './pfx/enemy-projectiles/projectile-spawner-head.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-spawner-trail',
		path: './pfx/enemy-projectiles/projectile-spawner-trail.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-strafer-head',
		path: './pfx/enemy-projectiles/projectile-strafer-head.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-strafer-trail',
		path: './pfx/enemy-projectiles/projectile-strafer-trail.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-asp-head',
		path: './pfx/enemy-projectiles/projectile-asp-head.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-asp-trail',
		path: './pfx/enemy-projectiles/projectile-asp-trail.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-wisp-head',
		path: './pfx/enemy-projectiles/projectile-wisp-head.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-wisp-trail',
		path: './pfx/enemy-projectiles/projectile-wisp-trail.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-skeletonbeach-head',
		path: './pfx/enemy-projectiles/projectile-skeletonbeach-head.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-skeletonbeach-trail',
		path: './pfx/enemy-projectiles/projectile-skeletonbeach-trail.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-goblin-trail',
		path: './pfx/enemy-projectiles/projectile-goblin-trail.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-goblin-jester-head',
		path: './pfx/enemy-projectiles/projectile-goblin-jester-head.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-bee-head',
		path: './pfx/enemy-projectiles/projectile-bee-head.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-bonespirit-head',
		path: './pfx/enemy-projectiles/projectile-bonespirit-head.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-bonespirit-trail',
		path: './pfx/enemy-projectiles/projectile-bonespirit-trail.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-dreamer-fancier-head',
		path: './pfx/enemy-projectiles/projectile-dreamer-fancier-head.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-deathdrake-head',
		path: './pfx/enemy-projectiles/projectile-deathdrake-head.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-deathdrake-trail',
		path: './pfx/enemy-projectiles/projectile-deathdrake-trail.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-skeleton-knight-balloonlance-head',
		path: './pfx/enemy-projectiles/projectile-skeleton-knight-balloonlance-head.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-skeletalwhelp-eggshell-head',
		path: './pfx/enemy-projectiles/projectile-skeletalwhelp-eggshell-head.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-sliver-cracked-head',
		path: './pfx/enemy-projectiles/projectile-sliver-cracked-head.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-skeletalwhelp-head',
		path: './pfx/enemy-projectiles/projectile-skeletalwhelp-head.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-skeletalwhelp-trail',
		path: './pfx/enemy-projectiles/projectile-skeletalwhelp-trail.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-thornwolf-cone-head',
		path: './pfx/enemy-projectiles/projectile-thornwolf-cone-head.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-thornwolf-head',
		path: './pfx/enemy-projectiles/projectile-thornwolf-head.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-thornwolf-trail',
		path: './pfx/enemy-projectiles/projectile-thornwolf-trail.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-prism-head',
		path: './pfx/enemy-projectiles/projectile-prism-head.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-prism-trail',
		path: './pfx/enemy-projectiles/projectile-prism-trail.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'boss-prism-wind',
		path: './pfx/bosseffects/boss-prism-wind.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'boss-prism-crystalcocoon',
		path: './pfx/bosseffects/boss-prism-crystalcocoon.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'boss-prism-explosion',
		path: './pfx/bosseffects/boss-prism-explosion.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'smoke-explosion',
		path: './pfx/smoke-explosion.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'smoke-explosion-loop',
		path: './pfx/smoke-explosion-loop.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'charge',
		path: './pfx/charge/charge.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'projectile-fizzle',
		path: './pfx/projectile-fizzle.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'mixTextureCheckers',
		path: './images/checkers.png',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'mixTextureCorner',
		path: './images/corner.png',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'mixTextureHalf',
		path: './images/half.png',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'mixTextureInvertedCorner',
		path: './images/invertedCorner.png',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'baseGroundShaderVert',
		path: './shaders/base-ground.vert',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'baseGroundShaderFrag',
		path: './shaders/base-ground.frag',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'baseGroundMixShaderVert',
		path: './shaders/base-ground-mix.vert',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'baseGroundMixShaderFrag',
		path: './shaders/base-ground-mix.frag',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'cliffShaderVert',
		path: './shaders/cliff.vert',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'cliffShaderFrag',
		path: './shaders/cliff.frag',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'bwGradient',
		path: './images/bw_gradient.png',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'vignetteTopLeftCorner',
		path: './images/vignette_top_left_corner.png',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'experienceTrail',
		path: './pfx/green-projectile-trail.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: `beachPropAtlas`,
		path: `./biomes/beach/props/beach-props.json`,
		numberOfTransitiveAssets: 2,
		phase: 1,
	},
	{
		name: `forestPropAtlas`,
		path: `./biomes/forest/props/forest-props.json`,
		numberOfTransitiveAssets: 2,
		phase: 1,
	},
	{
		name: `fungiPropAtlas`,
		path: `./biomes/fungi/props/fungi-props.json`,
		numberOfTransitiveAssets: 2,
		phase: 1,
	},
	{
		name: `highlandsPropAtlas`,
		path: `./biomes/highlands/props/highlands-props.json`,
		numberOfTransitiveAssets: 2,
		phase: 1,
	},
	{
		name: `prismPropAtlas`,
		path: `./biomes/prism/props/prism-props.json`,
		numberOfTransitiveAssets: 2,
		phase: 1,
	},
	{
		name: `townPropAtlas`,
		path: `./biomes/town/props/hub-props.json`,
		numberOfTransitiveAssets: 2,
		phase: 1,
	},
	{
		name: `fog`,
		path: `./sprites/fog/fog.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'hub-props-factions',
		path: './biomes/town/props/hub-props-factions.json',
		numberOfTransitiveAssets: 2,
		phase: 1
	},
	{
		name: 'hub-props-factions-decorations',
		path: './biomes/town/props/hub-props-factions-decorations.json',
		numberOfTransitiveAssets: 2,
		phase: 1
	},
	{
		name: 'plaza',
		path: './biomes/town/props/plaza.json',
		numberOfTransitiveAssets: 2,
		phase: 1
	},
	{
		name: 'old-bg',
		path: './biomes/town/props/old-bg.json',
		numberOfTransitiveAssets: 2,
		phase: 1
	},
	{
		name: 'east-west-edges',
		path: './biomes/shared/props/east-west-edges.json',
		numberOfTransitiveAssets: 2,
		phase: 1,
	},
	{
		name: 'east-west-edges-faction-decorations',
		path: './biomes/shared/props/east-west-edges-faction-decorations.json',
		numberOfTransitiveAssets: 2,
		phase: 1,
	},
	{
		name: 'end-island-props',
		path: './biomes/shared/props/end-island-props.json',
		numberOfTransitiveAssets: 2,
		phase: 1,
	},
	{
		name: 'party_member',
		path: './ui-assets/hud/party-member.png',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'aurum-hud-icon',
		path: './ui-assets/hud/factions-aurum-icon.png',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'iron-hud-icon',
		path: './ui-assets/hud/factions-iron-icon.png',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'dawn-hud-icon',
		path: './ui-assets/hud/factions-scions-icon.png',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'hud-nameplate-decal',
		path: './ui-assets/hud/nameplate-party-decal.png',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'hud-highlight-arrow-solo',
		path: './ui-assets/hud/highlight-arrow-solo.png',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'hud-highlight-arrow-party-member',
		path: './ui-assets/hud/highlight-arrow-party-member.png',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	{
		name: 'hud-highlight-arrow-party-leader',
		path: './ui-assets/hud/highlight-arrow-party-leader.png',
		numberOfTransitiveAssets: 0,
		phase: 1,
	},
	// {
	// 	name: 'party-alter',
	// 	path: './biomes/shared/props/party-alter.json',
	// 	numberOfTransitiveAssets: 2,
	// 	phase: 1,
	// },
	{
		name: 'beach-alter',
		path: './biomes/beach/props/beach-alter.json',
		numberOfTransitiveAssets: 2,
		phase: 1,
	},
	{
		name: 'forest-alter',
		path: './biomes/forest/props/forest-alter.json',
		numberOfTransitiveAssets: 2,
		phase: 1,
	},
	{
		name: 'highlands-alter',
		path: './biomes/highlands/props/highlands-alter.json',
		numberOfTransitiveAssets: 2,
		phase: 1,
	},
	{
		name: 'fungi-alter',
		path: './biomes/fungi/props/fungi-alter.json',
		numberOfTransitiveAssets: 2,
		phase: 1,
	},
	{
		name: 'prism-alter',
		path: './biomes/prism/props/prism-alter.json',
		numberOfTransitiveAssets: 2,
		phase: 1,
	},
	//cards
	{
		name: 'weapon-card-common',
		path: './ui-assets/weapon-cards/weapon-card-common.json',
		numberOfTransitiveAssets: 2,
		phase: 2,
	},
	{
		name: 'weapon-card-uncommon',
		path: './ui-assets/weapon-cards/weapon-card-uncommon.json',
		numberOfTransitiveAssets: 2,
		phase: 2,
	},
	{
		name: 'weapon-card-rare',
		path: './ui-assets/weapon-cards/weapon-card-rare.json',
		numberOfTransitiveAssets: 2,
		phase: 2,
	},
	{
		name: 'weapon-card-epic',
		path: './ui-assets/weapon-cards/weapon-card-epic.json',
		numberOfTransitiveAssets: 2,
		phase: 2,
	},
	{
		name: 'weapon-card-legendary',
		path: './ui-assets/weapon-cards/weapon-legendary.json',
		numberOfTransitiveAssets: 2,
		phase: 2,
	},
	{
		name: 'weapon-card-astronomical',
		path: './ui-assets/weapon-cards/weapon-astronomical.json',
		numberOfTransitiveAssets: 2,
		phase: 2,
	},
	{
		name: 'gear-card-common',
		path: './ui-assets/weapon-cards/gear-common.json',
		numberOfTransitiveAssets: 2,
		phase: 2,
	},
	{
		name: 'gear-card-uncommon',
		path: './ui-assets/weapon-cards/gear-uncommon.json',
		numberOfTransitiveAssets: 2,
		phase: 2,
	},
	{
		name: 'gear-card-rare',
		path: './ui-assets/weapon-cards/gear-rare.json',
		numberOfTransitiveAssets: 2,
		phase: 2,
	},
	{
		name: 'gear-card-epic',
		path: './ui-assets/weapon-cards/gear-epic.json',
		numberOfTransitiveAssets: 2,
		phase: 2,
	},
	{
		name: 'gear-card-legendary',
		path: './ui-assets/weapon-cards/gear-legendary.json',
		numberOfTransitiveAssets: 2,
		phase: 2,
	},
	{
		name: 'gear-card-astronomical',
		path: './ui-assets/weapon-cards/gear-astronomical.json',
		numberOfTransitiveAssets: 2,
		phase: 2,
	},
	{
		name: 'card-pfx-epic',
		path: './pfx/uieffects/cardeffect-epic.json',
		numberOfTransitiveAssets: 2,
		phase: 2,
	},
	{
		name: 'card-pfx-legendary',
		path: './pfx/uieffects/cardeffect-legendary.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'card-pfx-astronomical',
		path: './pfx/uieffects/cardeffect-astronomical.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: `gear-belt`,
		path: `./sprites/weapon-card-gear/gear-belt.png`,
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: `gear-pendant`,
		path: `./sprites/weapon-card-gear/gear-pendant.png`,
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: `gear-ring`,
		path: `./sprites/weapon-card-gear/gear-ring.png`,
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: `gear-trinket`,
		path: `./sprites/weapon-card-gear/gear-trinket.png`,
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'augment-unlock-pfx',
		path: './sprites/slot-unlock/augment-unlock-pfx/augment-unlock-pfx.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'generic-ui-unveil',
		path: './sprites/ui/generic-ui-unveil/generic-ui-unveil.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	// AOE effects
	{
		name: 'aoe-spores',
		path: './pfx/aoe-spores.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'aoe-explosion-mound',
		path: './pfx/aoe-explosion/aoe-explosion-mound.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'aoe-explosion-highlandsgolem',
		path: './pfx/aoe-explosion/aoe-explosion-highlandsgolem.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'aoe-explosion-highlandsgolem-md',
		path: './pfx/aoe-explosion/aoe-explosion-highlandsgolem-md.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'aoe-explosion-highlandsgolem-lg',
		path: './pfx/aoe-explosion/aoe-explosion-highlandsgolem-lg.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'aoe-explosion-prismgolem',
		path: './pfx/aoe-explosion/aoe-explosion-prismgolem.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	// trails
	{
		name: 'dust',
		path: './pfx/dust/dust.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'player-trail-notes-01',
		path: './pfx/trails/player-trail-notes-01.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'player-trail-greenflames-01',
		path: './pfx/trails/player-trail-greenflames-01.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'player-trail-hearts-01',
		path: './pfx/trails/player-trail-hearts-01.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'player-trail-peace-01',
		path: './pfx/trails/player-trail-peace-01.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'player-trail-butterfly-01',
		path: './pfx/trails/player-trail-butterfly-01.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'player-trail-fireworks-01',
		path: './pfx/trails/player-trail-fireworks-01.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'player-trail-breakfastcandy-01',
		path: './pfx/trails/player-trail-breakfastcandy-01.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'player-trail-bubbles-01',
		path: './pfx/trails/player-trail-bubbles-01.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'player-trail-candy-01',
		path: './pfx/trails/player-trail-candy-01.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'player-trail-cuteskulls-01',
		path: './pfx/trails/player-trail-cuteskulls-01.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'player-trail-ghosts-01',
		path: './pfx/trails/player-trail-ghosts-01.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'player-trail-mapleleaf-01',
		path: './pfx/trails/player-trail-mapleleaf-01.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'player-trail-pixels-01',
		path: './pfx/trails/player-trail-pixels-01.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'player-trail-rainbow-01',
		path: './pfx/trails/player-trail-rainbow-01.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'player-trail-rainbow-02',
		path: './pfx/trails/player-trail-rainbow-02.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'player-trail-spiders-01',
		path: './pfx/trails/player-trail-spiders-01.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'player-trail-stars-01',
		path: './pfx/trails/player-trail-stars-01.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'player-trail-sushi-01',
		path: './pfx/trails/player-trail-sushi-01.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'player-trail-watermellon-01',
		path: './pfx/trails/player-trail-watermellon-01.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'player-trail-flowers-01',
		path: './pfx/trails/player-trail-flowers-01.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'player-trail-basic-01',
		path: './pfx/trails/player-trail-basic-01.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'player-trail-basic-04',
		path: './pfx/trails/player-trail-basic-04.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'player-trail-basic-07',
		path: './pfx/trails/player-trail-basic-07.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'player-trail-basic-08',
		path: './pfx/trails/player-trail-basic-08.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'player-trail-basic-11',
		path: './pfx/trails/player-trail-basic-11.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'player-trail-basic-12',
		path: './pfx/trails/player-trail-basic-12.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'quick-feet',
		path: './pfx/dust/quick-feet.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	// Status effects
	{
		name: 'status-armorbreak',
		path: './pfx/status/status-armorbreak.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'status-battle-cry',
		path: './pfx/status/status-battle-cry.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'status-berserking',
		path: './pfx/status/status-berserking.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'status-bleed',
		path: './pfx/bleed-status.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'status-chill',
		path: './pfx/ice_status.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'status-healing',
		path: './pfx/status/status-healing.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'status-healingburst',
		path: './pfx/status/status-healingburst.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'status-ignite',
		path: './pfx/fire_status.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'status-overshield',
		path: './pfx/status/status-overshield.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'status-overcharged-shot',
		path: './pfx/status/status-overcharged-shot.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'status-poison',
		path: './pfx/poison/poison.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'status-sickening-nova',
		path: './pfx/status/status-sickening-nova.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'status-shock',
		path: './pfx/lightning_status_subtle.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'status-stun',
		path: './pfx/lightning_status.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'status-shardarmorup',
		path: './pfx/status/status-shardarmorup.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'shield',
		path: './pfx/status/shield.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'status-stoneform',
		path: './pfx/status/status-stoneform.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'status-weakened',
		path: './pfx/status/status-weakened.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	// skill shoots
	{
		name: 'skill-battle-cry',
		path: './pfx/skills/skill-battle-cry.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'skill-overcharged-shot',
		path: './pfx/skills/skill-overcharged-shot.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'skill-sickening-nova',
		path: './pfx/skills/skill-sickening-nova.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'skill-gravity-well',
		path: './pfx/skills/skill-gravity-well.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'skill-battery',
		path: './pfx/skills/skill-battery.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'skill-bulwark',
		path: './pfx/skills/skill-defensive-bulwark.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'skill-bulwark-model',
		path: './sprites/misc/bulwark/bulwark.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'emote-typing',
		path: './sprites/misc/emote-typing-indicator/emote-typing-indicator.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'poison-apple-status',
		path: './pfx/poison-apple-status.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'final-countdown',
		path: './pfx/final-countdown.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'emote-pet',
		path: './sprites/misc/emote-wandering-pet/emote-wandering-pet.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	// emotes
	{
		name: 'emote-icon-atlas',
		path: './sprites/misc/emotes/emote-icon-atlas.json',
		numberOfTransitiveAssets: 0,
		phase: 2,
	},
	{
		name: 'emote-container',
		path: './sprites/misc/emotes/emote-container.png',
		numberOfTransitiveAssets: 0,
		phase: 2,
	}
]

ClientModelPaths.forEach((path, name) => {
	assetList.push({
		name,
		path,
		numberOfTransitiveAssets: 2,
		phase: 2,
	})
})

RARITIES.forEach((rarity) => {
	assetList.push({
		name: `augment-bg-${rarity}`,
		path: `./sprites/weapon-card-augments/augment-bg-${rarity}.png`,
		numberOfTransitiveAssets: 0,
		phase: 2 as AssetLoadingPhase,
	})
})

AUGMENT_MODS.forEach((mod) => {
	RARITIES.forEach((rarity) => {
		assetList.push({
			name: `mod-icon-${mod}-${rarity}`,
			path: `./sprites/weapon-card-augments/mod-icon-${mod}-${rarity}.png`,
			numberOfTransitiveAssets: 0,
			phase: 2 as AssetLoadingPhase,
		})
	})
})

EmoteAssets.forEach((element: string) => {
	assetList.push({
		name: `${element}`,
		path: `./sprites/misc/emotes/${element}.json`,
		numberOfTransitiveAssets: 2,
		phase: 2,
	})
})

// Dynamic assets: biome data
LOADABLE_BIOMES.forEach((biome: BiomeIdentifier) => {
	const biomePropConfigs = propConfigs[biome]

	assetList.push({
		name: `${biome}Ground`,
		path: `./biomes/${biome}/ground/ground.json`,
		numberOfTransitiveAssets: 2,
		phase: 2,
	})

	assetList.push({
		name: `${biome}Cliffs`,
		path: `./biomes/${biome}/cliffs/${biome}-edge.json`,
		numberOfTransitiveAssets: 2,
		phase: 2,
	})

	assetList.push({
		name: `${biome}LightAtlas`,
		path: `./biomes/${biome}/light/${biome}-light.json`,
		numberOfTransitiveAssets: 2,
		phase: 1,
	})

	assetList.push({
		name: `${biome}-ambience`,
		path: `./pfx/biome-ambience/${biome}.json`,
		numberOfTransitiveAssets: 0,
		phase: 1,
	})

	const propDir = `./biomes/${biome}/props`

	for (const propId in biomePropConfigs) {
		if (biomePropConfigs.hasOwnProperty(propId)) {
			const propConfig = biomePropConfigs[propId]
			if (!propConfig.dontLoadAsset) {
				let atlas
				if (propConfig.overrideAtlas) {
					atlas = propConfig.overrideAtlas
				} else {
					atlas = propConfig.ignoreSharedAtlas ? undefined : `${biome}PropAtlas`
				}
				const numberOfTransitiveAssets = propConfig.ignoreSharedAtlas ? 2 : 0
				assetList.push({
					name: `${biome}/${propId}`,
					path: `${propDir}/${propId}.json`,
					numberOfTransitiveAssets,
					phase: 2,
					dependsOnSpineAtlas: atlas,
				})
			}
		}
	}

	const lightDir = `./biomes/${biome}/light`

	const lights = {
		glowv1: 'glow-var1.json',
		glowv2: 'glow-var2.json',
		glowv3: 'glow-var3.json',
		godrayv1: 'godray-var1.json',
		godrayv2: 'godray-var2.json',
		godrayv3: 'godray-var3.json',
	}

	for (const lightIdentifier in lights) {
		if (lights.hasOwnProperty(lightIdentifier)) {
			assetList.push({
				name: `${biome}/${lightIdentifier}`,
				path: `${lightDir}/${lights[lightIdentifier]}`,
				numberOfTransitiveAssets: 0,
				phase: 2,
				dependsOnSpineAtlas: `${biome}LightAtlas`,
			})
		}
	}
})

// shared
const sharedPropDir = `./biomes/shared/props`

for (const propId in sharedPropConfigs) {
	if (sharedPropConfigs.hasOwnProperty(propId)) {
		const propConfig = sharedPropConfigs[propId]
		if (!propConfig.dontLoadAsset) {
			let atlas
			if (propConfig.overrideAtlas) {
				atlas = propConfig.overrideAtlas
			} else {
				atlas = propConfig.ignoreSharedAtlas ? undefined : `sharedPropAtlas`
			}
			const numberOfTransitiveAssets = propConfig.ignoreSharedAtlas ? 2 : 0
			assetList.push({
				name: `shared/${propId}`,
				path: `${sharedPropDir}/${propId}.json`,
				numberOfTransitiveAssets,
				phase: 2,
				dependsOnSpineAtlas: atlas,
			})
		}
	}
}

LOADABLE_WEAPON_PFX.forEach((weapon: WeaponPFXIdentifier) => {
	assetList.push({
		name: `${weapon}-projectile-01`,
		path: `./pfx/weapon-projectiles/${weapon}/projectile-01.json`,
		numberOfTransitiveAssets: 0,
		phase: 2,
	})
	assetList.push({
		name: `${weapon}-projectile-02`,
		path: `./pfx/weapon-projectiles/${weapon}/projectile-02.json`,
		numberOfTransitiveAssets: 0,
		phase: 2,
	})
	assetList.push({
		name: `${weapon}-projectile-03`,
		path: `./pfx/weapon-projectiles/${weapon}/projectile-03.json`,
		numberOfTransitiveAssets: 0,
		phase: 2,
	})
	assetList.push({
		name: `${weapon}-projectile-04`,
		path: `./pfx/weapon-projectiles/${weapon}/projectile-04.json`,
		numberOfTransitiveAssets: 0,
		phase: 2,
	})
})

LOADABLE_ELEMENTS.forEach((element: ElementalIdentifier) => {
	assetList.push({
		name: `${element}-projectile-trail`,
		path: `./pfx/trails/player-projectile-trail-${element}.json`,
		numberOfTransitiveAssets: 0,
		phase: 2,
	})

	assetList.push({
		name: `explosion-${element}`,
		path: `./pfx/explosion_${element}/explosion_${element}.json`,
		numberOfTransitiveAssets: 0,
		phase: 2,
	})
	assetList.push({
		name: `hit-${element}`,
		path: `./pfx/hit_${element}/hit_${element}.json`,
		numberOfTransitiveAssets: 0,
		phase: 2,
	})
	assetList.push({
		name: `aoe-explosion-${element}`,
		path: `./pfx/aoe-explosion/aoe-explosion-${element}.json`,
		numberOfTransitiveAssets: 0,
		phase: 2,
	})

	assetList.push({
		name: `projectile-shoot-${element}`,
		path: `./pfx/shoot/projectile-shoot-${element}.json`,
		numberOfTransitiveAssets: 0,
		phase: 2,
	})
})

export function getAllPfxDefinitions() {
	return assetList.filter(isPfxAssetDefinition)
}

const allPfxAssets = getAllPfxDefinitions()

export class AssetManager {
	static getInstance(): AssetManager {
		if (!AssetManager.instance) {
			AssetManager.instance = new AssetManager()
		}

		return AssetManager.instance
	}
	assets: AssetDefinition[] = assetList
	cacheAssetLoader: Loader
	firstPhaseAssetLoader: Loader
	secondPhaseAssetLoader: Loader
	loadedAssets: Map<string, LoaderResource>
	failedAssets: LoaderResource[]
	debugAssetAliases: Map<string, string> // map from alias->real
	assetsLoaded: number = 0
	numberOfAssetsToBeLoaded: number = 100
	percentageOfAssetsLoaded: number = 0
	retryAttempts: number = 0
	jsonCache
	private static instance: AssetManager

	private constructor() {
		logger.info('Starting asset loading')

		this.cacheAssetLoader = new Loader()
		this.firstPhaseAssetLoader = new Loader()
		this.secondPhaseAssetLoader = new Loader()

		this.addCallbacksToLoader(this.cacheAssetLoader)
		this.addCallbacksToLoader(this.firstPhaseAssetLoader)
		this.addCallbacksToLoader(this.secondPhaseAssetLoader)

		console.log(this.secondPhaseAssetLoader)

		this.loadedAssets = new Map<AssetIdentifier, LoaderResource>()
		this.failedAssets = []
		this.debugAssetAliases = new Map<string, string>()

		this.numberOfAssetsToBeLoaded = reduce(
			assetList,
			(tally, asset) => {
				return tally + asset.numberOfTransitiveAssets + 1
			},
			0,
		)

		this.numberOfAssetsToBeLoaded += allPfxAssets.length

		logger.info(`Done counting assets, total # of assets to be loaded: ${this.numberOfAssetsToBeLoaded}`)

		if (process.env.NODE_ENV === 'alpha' || process.env.NODE_ENV === 'local') {
			if (clientConfig.skipToGame && nonSensitiveEnvVarsMapping[process.env.NODE_ENV].USE_MATCHMAKER === false) {
				if (window.location.href.includes('connectTo=adventure')) {
					UI.getInstance().emitEvent('enterGame', 'adventure')
				} else if(window.location.href.includes('connectTo=testRealm')){
					UI.getInstance().emitEvent('enterGame', 'testRealm')
				} else {
					UI.getInstance().emitEvent('enterGame', 'hub')
				}
			}
		}

		this.populateJSONCache()
		this.loadFirstPhaseAssets(assetList)
		this.cacheAssetLoader.load(this.onCacheLoadCompletion.bind(this))
	}

	hasAssetByName(assetName: AssetIdentifier): boolean {
		const foundAsset = this.loadedAssets.get(assetName)
		return foundAsset !== undefined
	}

	getAssetByName(assetName: AssetIdentifier): LoaderResource {
		const foundAsset = this.loadedAssets.get(assetName)
		if (foundAsset === undefined) {
			throw new Error(`No loaded asset found by that identifier! ${assetName}`)
		} else {
			return foundAsset
		}
	}

	getAssetByNameAsync(assetName: AssetIdentifier, onLoaded: (asset: LoaderResource) => void) {
		const foundAsset = this.loadedAssets.get(assetName)

		if (foundAsset) {
			onLoaded(foundAsset)
		} else {
			const assetPath = OnDemandClientModelPaths.get(assetName)

			if (assetPath) {
				const onDemandLoader = new Loader()
				onDemandLoader.add(assetName, assetPath)
				onDemandLoader.load((_loader: Loader, resources: Partial<Record<string, LoaderResource>>) => {
					checkResourses(resources, console.error)
					this.addResources(resources)
					const foundAsset2 = this.loadedAssets.get(assetName)
					onLoaded(foundAsset2)
				})
			} else if (onLoaded) {
				throw new Error(`asset is being loaded on demand but there is no path defined in OnDemandClientModelPaths`)
			} else {
				throw new Error(`No loaded asset found by that identifier! ${assetName}`)
			}
		}
	}

	replaceAssetByNameOrAlias(assetName: AssetIdentifier, contents: any) {
		if (!this.hasAssetByName(assetName) && this.debugAssetAliases.has(assetName)) {
			assetName = this.debugAssetAliases.get(assetName)
		}
		const asset = this.getAssetByName(assetName)
		asset.data = contents
	}

	private cacheLookupCallback(resource: LoaderResource, next) {
		if (resource.name === 'jsonCache') {
			return next()
		}
		if (resource.extension === 'json' && this.jsonCache.hasOwnProperty(resource.url)) {
			resource.data = this.jsonCache[resource.url]
			resource.type = LoaderResource.TYPE.JSON
			resource.complete()
		}
		return next()
	}

	private addCallbacksToLoader(loader: Loader) {
		loader.onProgress.add(this.onLoadProgress.bind(this))
		loader.onError.add(this.onErrorCallback.bind(this))
		loader.onLoad.add(this.onLoadedCallback.bind(this))
		loader.pre(this.cacheLookupCallback.bind(this))
	}

	private populateJSONCache() {
		this.cacheAssetLoader.add('jsonCache', 'compiled.json')
	}

	private onCacheLoadCompletion(loader: Loader, resources: Partial<Record<string, LoaderResource>>): void {
		logger.info('Cache loading phase complete!')
		this.jsonCache = resources.jsonCache.data
		this.firstPhaseAssetLoader.load(this.onFirstPhaseLoadCompletion.bind(this))
	}

	private loadFirstPhaseAssets(assets: any): void {
		for (const asset in assets) {
			if (assets.hasOwnProperty(asset)) {
				const { name, path, phase, dependsOnSpineAtlas }: AssetDefinition = assets[asset]
				if (phase === 1) {
					if (debugConfig.assets.logEachAsset) {
						logger.debug('loading', name, path)
					}
					if (dependsOnSpineAtlas) {
						throw new Error('First phase assets cannot have dependencies')
					}
					this.firstPhaseAssetLoader.add(name, path)
				}
			}
		}
	}

	private loadSecondPhaseAssets(assets: any): void {
		for (const asset in assets) {
			if (assets.hasOwnProperty(asset)) {
				const { name, path, phase, dependsOnSpineAtlas }: AssetDefinition = assets[asset]
				if (phase === 2) {
					if (debugConfig.assets.logEachAsset) {
						logger.debug('loading', name, path)
					}
					if (dependsOnSpineAtlas) {
						const spineAtlas = this.loadedAssets.get(dependsOnSpineAtlas).spineAtlas
						const options = {
							metadata: {
								spineAtlas,
							},
						}
						this.secondPhaseAssetLoader.add(name, path, options)
					} else {
						this.secondPhaseAssetLoader.add(name, path)
					}
				}
			}
		}
	}

	private onLoadProgress(_, resource: LoaderResource) {
		if (resource !== null) {
			checkResource(resource, console.warn)
		}

		this.assetsLoaded++
		const newPercentage = Math.floor((this.assetsLoaded / this.numberOfAssetsToBeLoaded) * 100)
		if (newPercentage > this.percentageOfAssetsLoaded) {
			this.percentageOfAssetsLoaded = newPercentage
		}
		UI.getInstance().emitEvent('mainMenu/loadingProgressPercentageUpdated', this.percentageOfAssetsLoaded)
	}

	private onFirstPhaseLoadCompletion(loader: Loader, resources: Partial<Record<string, LoaderResource>>): void {
		if (debugConfig.assets.logging) {
			logger.info('onFirstPhaseLoadCompletion')
		}
		this.addResources(resources)
		if (this.failedAssets.length > 0) {
			this.retryAttempts++
			if (debugConfig.assets.logging) {
				logger.info('retryAttempts', this.retryAttempts)
			}
			if (this.retryAttempts > MAX_RETRY_ATTEMPTS) {
				logger.info(`Loading failed after ${MAX_RETRY_ATTEMPTS} attempts. Aborting.`)
				UI.getInstance().emitEvent('errors/setErrorDescriptions', {
					title: `Asset Loading Failed`,
					description: `Unable to load all required assets. Sorry, try again later. If this is a recurring issue contact support.`
				}, { root: true })
				UI.getInstance().emitEvent('errors/setActiveError', undefined, { root: true })
				return
			}

			logger.info(`First loading phase complete, but ${this.failedAssets.length} failed. Retrying...`)
			this.firstPhaseAssetLoader.reset()
			for (let i = 0; i < this.failedAssets.length; ++i) {
				const failedAsset = this.failedAssets[i]
				this.reloadOrReloadParent(failedAsset)
			}
			this.failedAssets = []
			this.firstPhaseAssetLoader.load(this.onFirstPhaseLoadCompletion.bind(this))
		} else {
			logger.info('First loading phase complete!')
			this.loadSecondPhaseAssets(assetList)
			this.secondPhaseAssetLoader.load(this.onSecondPhaseLoadCompletion.bind(this))
		}
	}

	private onSecondPhaseLoadCompletion(loader: Loader, resources: Partial<Record<string, LoaderResource>>): void {
		if (debugConfig.assets.logging) {
			logger.info('onSecondPhaseLoadCompletion', Object.keys(resources).length)
		}
		this.addResources(resources)
		if (this.failedAssets.length > 0) {
			this.retryAttempts++
			if (debugConfig.assets.logging) {
				logger.info('retryAttempts', this.retryAttempts)
			}
			if (this.retryAttempts > MAX_RETRY_ATTEMPTS) {
				logger.info(`Loading failed after ${MAX_RETRY_ATTEMPTS} attempts. Aborting.`)
				UI.getInstance().emitEvent('errors/setErrorDescriptions', {
					title: `Asset Loading Failed`,
					description: `Unable to load all required assets. Sorry, try again later. If this is a recurring issue contact support.`
				}, { root: true })
				UI.getInstance().emitEvent('errors/setActiveError', undefined, { root: true })
				return
			}

			logger.info(`Second loading phase complete, but ${this.failedAssets.length} failed. Retrying...`)
			this.secondPhaseAssetLoader.reset()
			for (let i = 0; i < this.failedAssets.length; ++i) {
				const failedAsset = this.failedAssets[i]
				const assetDefinition = this.tryGetAssetDefinitionFromResource(failedAsset)

				if (assetDefinition?.dependsOnSpineAtlas) {
					const spineAtlas = this.loadedAssets.get(assetDefinition.dependsOnSpineAtlas).spineAtlas
					const options = {
						metadata: {
							spineAtlas,
						},
					}
					this.secondPhaseAssetLoader.add(failedAsset.name, failedAsset.url, options)
				} else {
					this.reloadOrReloadParent(failedAsset)
				}
			}
			this.failedAssets = []
			this.secondPhaseAssetLoader.load(this.onSecondPhaseLoadCompletion.bind(this))
		} else {
			logger.info('Second loading phase complete!')

			addColliderConfigsToLookupCollidersClient()

			allPfxAssets
				.map((def) => this.getEffectConfigByName(def.name))
				.forEach((effectConfig) => {
					Effect.LoadRequiredAtlases(effectConfig, () => {
						this.onLoadProgress(undefined, null)
					})
				})
		}
	}

	private addResources(resources: Partial<Record<string, LoaderResource>>) {
		if (debugConfig.assets.logEachAsset) {
			logger.info('addResources:', Object.keys(resources).length)
		}
		let added = 0
		for (const resourceIdentifier in resources) {
			if (resources.hasOwnProperty(resourceIdentifier)) {
				const resource = resources[resourceIdentifier]
				if (debugConfig.assets.logEachAsset) {
					logger.info('adding:', resourceIdentifier)
				}
				if (resource.data?.name !== undefined) {
					this.debugAssetAliases.set(resource.data.name, resourceIdentifier)
				}
				if (!resource.error) {
					this.loadedAssets.set(resourceIdentifier, resource)
					added++
				}
			}
		}
		if (debugConfig.assets.logEachAsset) {
			logger.info(` added ${added} out of ${Object.keys(resources).length}`)
		}
	}

	/**
	 * This reloads `failedAsset` OR reloads the failed asset's *parent* asset.
	 * What I mean by parent here is and asset that causes other transitive loads, 
	 * like a spine .json that causes a transitive load of a .atlas and .png file.
	 * If we try and reload the .atlas or .png without reloading the parent .json, the parent will
	 * not be notified of the spine data (asset.spineData will not be set)
	 * @param failedAsset an asset that has failed loading that we want to retry
	 */
	private reloadOrReloadParent(failedAsset: LoaderResource) {
		const parentAssetDefinition = this.tryGetParentAssetDefinition(failedAsset)
		if (parentAssetDefinition) {
			if (failedAsset.url.includes('.png')) {
				this.numberOfAssetsToBeLoaded += 2
			} else if (failedAsset.url.includes('.atlas')) {
				this.numberOfAssetsToBeLoaded += 1
			}
			this.secondPhaseAssetLoader.add(parentAssetDefinition.name, parentAssetDefinition.path)
		} else {
			this.secondPhaseAssetLoader.add(failedAsset.name, failedAsset.url)
		}
	}

	private tryGetParentAssetDefinition(resource: LoaderResource) {
		// currently only spine sprites (.json) have transitive assets, which are always .png or .atlas
		const parentUrl = resource.url
			.replace('.png', '.json')
			.replace('.atlas', '.json')
		const parentAssetDefinition = assetList.find((asset) => { return asset.path === parentUrl })
		return parentAssetDefinition
	}

	private tryGetAssetDefinitionFromResource(resource: LoaderResource) {
		const assetDefinition = assetList.find((asset) => { return asset.name === resource.name && asset.path === resource.url })
		return assetDefinition
	}

	private onErrorCallback(error: Error, loader: Loader, resource: LoaderResource) {
		console.error(error.message, resource.url)

		this.assetsLoaded--
		this.failedAssets.push(resource)
	}

	private onLoadedCallback(loader: Loader, resource: LoaderResource) {
		if (debugConfig.assets.logEachAsset) {
			logger.info(`loaded ${resource.url}`)
		}
	}

	private getEffectConfigByName(effectConfigName: string) {
		const pfxAsset = AssetManager.getInstance().getAssetByName(effectConfigName).data as EffectConfig
		if (!pfxAsset.emitters) {
			return undefined
		}
		return pfxAsset
	}
}

function checkResourses(resources: Partial<Record<string, LoaderResource>>, logFunc: (message: string) => void) {
	for (const resourceIdentifier in resources) {
		if (Object.prototype.hasOwnProperty.call(resources, resourceIdentifier)) {
			const resource = resources[resourceIdentifier]
			checkResource(resource, logFunc)
		}
	}
}

function checkResource(resource: LoaderResource, logFunc: (message: string) => void) {
	if (resource !== null) {
		if (resource.extension === 'png') {
			const size = resource.data.height * resource.data.width
			const oneM = 1024 * 1024
			if (size > oneM) {
				logFunc(`Asset: ${resource.name} exceeds maximum texture size of 1Mpx (its ${resource.data.height} X ${resource.data.width} = ${(size / oneM).toFixed(1)}Mpx). May cause client hitches as this texture is loaded/unloaded on GPU.`)
			}
		}
	}
}

export function debugIterateAssetDefinitionList(callback: (assetDef: AssetDefinition) => void) {
	assetList.forEach(callback)
}

function isPfxAssetDefinition(assetDef: AssetDefinition) {
	return assetDef.path.includes('/pfx/')
}
