import { WeightedRandom } from '../../../world/shared/weightedRandom'
import { ENEMY_NAMES } from './enemy-names'

export interface WeightedDestructibleProp extends WeightedRandom {
	propName: ENEMY_NAMES
	weight: number
}

export const BeachDestructibleProps: WeightedDestructibleProp[] = 
[
    {
        propName: ENEMY_NAMES.CRAB_BARREL,
        weight: 50
    },
    {
        propName: ENEMY_NAMES.MERMAID_MAST,
        weight: 50
    }
]

export const ForestDestructibleProps: WeightedDestructibleProp[] = 
[
    {
        propName: ENEMY_NAMES.LANTERN_POST,
        weight: 50
    },
    {
        propName: ENEMY_NAMES.TANNING_RACK,
        weight: 50
    }
]

export const HighlandsDestructibleProps: WeightedDestructibleProp[] = 
[
    {
        propName: ENEMY_NAMES.MASSIVE_SKULL_PILE,
        weight: 50
    },
    {
        propName: ENEMY_NAMES.MYSTERIOUS_GEM,
        weight: 50
    }
]

export const FungiDestructibleProps: WeightedDestructibleProp[] = 
[
    {
        propName: ENEMY_NAMES.SPORE_SACK,
        weight: 50
    },
    {
        propName: ENEMY_NAMES.FUNGI_SKELETON,
        weight: 50
    }
]

export const PrismDestructibleProps: WeightedDestructibleProp[] = 
[
    {
        propName: ENEMY_NAMES.CRYSTAL_HAND,
        weight: 50
    },
    {
        propName: ENEMY_NAMES.CORRUPTED_LAMP,
        weight: 50
    }
]
