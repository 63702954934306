
































import EmptyInventorySlot from '../inventory-item/empty-inventory-slot.vue'
import InventoryItem from '../inventory-item/inventory-item.vue'
import TextButton from '../buttons/text-button.vue'
import { mapGetters, mapMutations } from 'vuex'
import { Moment } from 'moment-timezone'
import moment from 'moment'
import { HOURS_FOR_WORM_DELIVERY_TO_EXPIRE, MS_FOR_WORM_DELIVERY_TO_EXPIRE } from '../../../utils/economy-data'

export default {
	name: 'ConditionalButtonedGridItemContainer',
	components: {
		InventoryItem,
		EmptyInventorySlot,
		TextButton,
	},
	props: {
		items: {
			type: Array,
			required: true,
			default: () => {
				console.warn('Currently using the default array. Ensure the <ConditionalButtonedGridItemContainer /> component has a custom attribute items')
				return []
			},
		},
		showSlot: {
			type: Boolean,
			required: false,
			default: true,
		},
		selectable: {
			type: Boolean,
			required: false,
			default: true,
		},
		onSelectFn: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default on-select handler for the <ListItemContainer /> component has been called. Did you forget to pass an :on-click handler to the component?')
			},
		},
		selectedFn: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default selected fn for the <ListItemContainer /> component has been called. Did you forget to pass an :selected-fn handler to the component?')
			},
		},
		tooltipable: {
			type: Boolean,
			required: false,
			default: true,
		},
		maxItemCount: {
			type: Number,
			required: true,
			default: () => {
				console.warn('Currently using the default max item slots. Ensure the <ConditionalButtonedGridItemContainer /> component has a custom attribute max-item-count')
				return 10
			},
		},
		showButtons: {
			type: Boolean,
			required: false,
			default: false,
		},
		onButtonClickFn: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default on-button-click-fn handler for the <ConditionalButtonedGridItemContainer /> component has been called. Did you forget to pass an :on-click handler to the component?')
			},
		},
		disabledFn: {
			type: Function,
			required: false,
			default: () => {
				return false
			},
		},
		overlayFn: {
			type: Function,
			required: false,
			default: () => {
				return 'default'
			},
		},
		labelFn: {
			type: Function,
			required: false,
			default: () => {
				return 'default'
			},
		},
		hideButtonAndLabelFn: {
			type: Function,
			required: false,
			default: () => {
				return false
			},
		},
		hideItemCost: {
			type: Function,
			required: false,
			default: () => {
				return false
			},
		},
		showExpiry: {
			type: Boolean,
			required: false,
			default: false
		},
		containerName: {
			type: String,
			required: false,
			default: 'wormDelivery'
		}
	},
	data() {
		return {
			intervalId: null,
		}
	},
	computed: {
		...mapGetters('itemLocks', ['itemIsLocked']),
		...mapGetters('furnace', ['unixDiff']),
		...mapGetters('time', ['nowMoment']),
	},
	methods: {
		...mapMutations('itemContainers', ['removeItemsFromContainer']),
		getExpiryTime(item) {
			if(this.showExpiry && item.createdAt) {
				const createdMoment: Moment = item.createdAt
				const diffMs: number = this.nowMoment.diff(createdMoment)
				
				return Math.max(MS_FOR_WORM_DELIVERY_TO_EXPIRE - diffMs, 0)
			}

			return 0
		},
		itemIsExpired(item) {
			if(this.showExpiry && item.createdAt) {
				const createdMoment: Moment = item.createdAt
				const diffMs: number = this.nowMoment.diff(createdMoment)
				
				if(MS_FOR_WORM_DELIVERY_TO_EXPIRE - diffMs <= 0) {
					// kinda jank, needed to update some numbers on the UI
					this.removeItemsFromContainer({itemIds: [item.id], containerName: this.containerName})
					return true
				}
			}

			return false
		}
	},
}
