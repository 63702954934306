import nengi from 'nengi'
import { gameUnits } from '../../utils/primitive-types'

export default class SendMinimapUpdateMessage {
	static protocol = {
		posX: nengi.Int32,
		posY: nengi.Int32,
		nearby_nid: { type: nengi.Number, indexType: nengi.UInt16 },
		nearby_type: { type: nengi.Number, indexType: nengi.UInt16 },
		nearby_x: { type: nengi.Number, indexType: nengi.UInt16 },
		nearby_y: { type: nengi.Number, indexType: nengi.UInt16 },
	}

	posX: number
	posY: number
	nearby_nid: number[]
	nearby_type: number[]
	nearby_x: number[]
	nearby_y: number[]

	constructor(posX: gameUnits, posY: gameUnits, nids: number[], types: number[], xs: number[], ys: number[]) {
		this.posX = posX
		this.posY = posY
		this.nearby_nid = nids
		this.nearby_type = types
		this.nearby_x = xs
		this.nearby_y = ys
	}
}
