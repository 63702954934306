import nengi from 'nengi'
import { VectorXY } from '../../utils/math'

export class CreatePfxOneOffMessage {
	static protocol = {
		pfxName: { type: nengi.String },
		x: { type: nengi.Float32 },
		y: { type: nengi.Float32 },
		scale: { type: nengi.Float32 },
		duration: { type: nengi.Float32 },
		zOffset: { type: nengi.Int16 },
		foreground: { type: nengi.Boolean },
		ownerNid: { type: nengi.Number },
	}

	pfxName: string
	x: number
	y: number
	scale: number
	duration: number
	zOffset: number
	foreground: boolean
	ownerNid: number

	constructor(pfxName: string, position: VectorXY, scale?: number, duration?: number, zOffset?: number, ownerNid?: number, foreground?: boolean) {
		this.pfxName = pfxName
		this.x = position.x
		this.y = position.y
		this.scale = scale === undefined ? 1 : scale
		this.duration = duration === undefined ? 1 : duration
		this.zOffset = zOffset === undefined ? 0 : zOffset
		this.ownerNid = ownerNid === undefined ? -1 : ownerNid
		this.foreground = foreground === undefined ? true : foreground
	}
}
