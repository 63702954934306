import { ClientBeam } from '../../../beams/client/beam.client'
import { clearObjectRefs } from '../../../utils/object-util'
import { nengiId, timeInSeconds } from '../../../utils/primitive-types'
import { BeamGraphics } from './beam-graphics'
import Renderer from './renderer'

export class BeamManager {
	private beams: Map<nengiId, ClientBeam>
	private beamGraphics: Map<nengiId, BeamGraphics>
	private renderer: Renderer

	constructor(renderer: Renderer) {
		this.beams = new Map<nengiId, ClientBeam>()
		this.beamGraphics = new Map<nengiId, BeamGraphics>()
		this.renderer = renderer
	}

	update(delta: timeInSeconds) {
		const nids = this.beams.keys()
		for (const nid of nids) {
			const beam = this.beams.get(nid)
			const beamRay = this.beamGraphics.get(nid)

			console.assert(beam && beamRay, 'The beam registry should contain both the beam entity and the beam graphic')

			beamRay.update(beam, delta)
		}
	}

	registerBeam(beam: ClientBeam) {
		const { nid } = beam
		this.beams.set(nid, beam)
		console.assert(!this.beamGraphics.get(nid), `beam with nid ${nid} already registered!`)
		this.beamGraphics.set(nid, new BeamGraphics(beam, this.renderer))
	}

	unregisterBeam(nid: nengiId) {
		if (this.beams.has(nid)) {
			const beamRay = this.beamGraphics.get(nid)
			beamRay.cleanup()
			this.beams.delete(nid)
			this.beamGraphics.delete(nid)
		}
	}

	shutdown() {
		clearObjectRefs(this)
	}
}
