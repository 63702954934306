import { clientDebug } from '../../debug/client/debug-client';
import { timeInSeconds } from '../../utils/primitive-types';
import { Vector } from 'sat';
import PlayerInput from '../../input/shared/player-input';
import { ClientPlayer, updateVisualsAfterMovement } from './player.client';
import DevToolsManager from '../../ui/dev-tools/dev-tools-manager';
import { playerCollision } from '../../collision/shared/player-collision';
import { ExternalPhysicsForce, updatePhysics } from '../shared/physics';
import { clientPerformSkill } from './gear-skills.client';
import { deepClone } from '../../ai/shared/abilities.test';
import Time from '../../engine/shared/time';
import SkillUsedMessage from '../../gear-skills/shared/skill-used-message';
import { PressureLoadout, PressureStat } from '../../engine/shared/game-data/pressure-stat-info.shared';
import { getPressureLoadoutOnClient } from '../../engine/client/pressure.client';
import { CooldownSlot, getSkillDurationMs } from '../../engine/shared/game-data/gear-skill-config';
import { ModCategory } from '../../engine/shared/game-data/stat-type-mod-category';
import { PlayerPositionSmoother } from './player-position-smoother';

export const movementConfig = {
	debug: false,
	useLerp: false,
	lerp: 0.1,
	smoothTime: 0.1
}
if (process.env.NODE_ENV !== 'beta' && process.env.NODE_ENV !== 'loot-prod') {
	DevToolsManager.getInstance().addObjectByName('movementConfig', movementConfig)
}

export function noPlayerInputPrevented(player: ClientPlayer) {
	if (isNaN(player.inputMovementPrevented)) {
		return true
	}
	return player.inputMovementPrevented <= 0
}

const canUseSkillMap: Map<ModCategory, { (player: ClientPlayer) }> = new Map()
canUseSkillMap.set(ModCategory.SKILL_DODGE_ROLL, noPlayerInputPrevented) //Can't roll during other rolls or battery
canUseSkillMap.set(ModCategory.SKILL_TUMBLE_ROLL, noPlayerInputPrevented)

interface IPlayerInputHistoryEntry {
	input: PlayerInput
	externalForces: ExternalPhysicsForce[]
	delta: timeInSeconds
}

/** handles client side **"my local player only"** CSP movement */
export class ClientPlayerMovement {
	readonly previousPosition: Vector = new Vector();
	readonly predictedPos: Vector = new Vector();
	readonly externalForces: ExternalPhysicsForce[] = []

	private readonly player: ClientPlayer;
	private readonly smoother: PlayerPositionSmoother
	private inputHistory: IPlayerInputHistoryEntry[] = [];
	private readonly lastSkillTime: number[] = [-100, -100, -100]
	private readonly pressureLoadout: PressureLoadout;

	get clientPosSmoothed() {
		return this.smoother.clientPosSmoothed
	}

	get lastServerInputFrame() {
		return this.player.lastServerInputFrame;
	}

	constructor(player: ClientPlayer) {
		if (player.myPlayerData)
			this.player = player;
		this.smoother = new PlayerPositionSmoother(player)
		this.previousPosition.x = player.x;
		this.previousPosition.y = player.y;
		this.predictedPos.x = player.x;
		this.predictedPos.y = player.y;

		this.pressureLoadout = getPressureLoadoutOnClient()
	}

	handleInput(curInput: PlayerInput, delta: timeInSeconds) {
		// push input and forces to history so they can be reapplied after server correction
		this.inputHistory.push({ input: curInput, externalForces: deepClone(this.externalForces), delta })

		const player = this.player;

		// assign server position to client
		this.predictedPos.x = player.x_nointerp;
		this.predictedPos.y = player.y_nointerp;

		this.previousPosition.x = this.predictedPos.x;
		this.previousPosition.y = this.predictedPos.y;

		this.checkGearSkillUse(player, curInput);

		// remove any input commands that the server has already processed
		this.inputHistory = this.inputHistory.filter(inputEntry => inputEntry.input.frame > this.lastServerInputFrame)

		// apply all remaining local inputs onto the "last known good server position" to get predictedPos
		this.inputHistory.forEach(inputEntry => {
			this.handleInputEntry(inputEntry);
		});

		// here is where we decay the force (true), passing dummy vector
		updatePhysics(this, { x: 0, y: 0 }, delta, true)
	}

	private checkGearSkillUse(player: ClientPlayer, curInput: PlayerInput) {
		const localPlayerData = player.myPlayerData
		const gearSlots = localPlayerData.gearSlots;

		if (this.pressureLoadout[PressureStat.FinalDestination] || player.isGhost) {
			return
		}

		const buttons = [curInput.skill1, curInput.skill2, curInput.skill3];
		const slots = ['one', 'two', 'three'];
		const cooldownSlots = [CooldownSlot.SKILL_SLOT_1, CooldownSlot.SKILL_SLOT_2, CooldownSlot.SKILL_SLOT_3]
		for (let i = 0; i < buttons.length; i++) {
			const skillSlotUsed = buttons[i];

			if (skillSlotUsed) {
				const slotName = slots[i];
				const skillId = gearSlots[slotName] as ModCategory;

				const usableFunc = canUseSkillMap.get(skillId)
				if (usableFunc && !usableFunc(player)) {
					continue
				}

				const cooldownSlot = cooldownSlots[i]
				const timeSinceLastUse = Time.timeElapsedSinceModeStartInSeconds - this.lastSkillTime[i];

				let cooldownDuration: timeInSeconds = localPlayerData.getGearSlotPlusBaseStat(cooldownSlot, 'cooldownDuration')
				const cdr = localPlayerData.getStat('cooldownSpeed')
				cooldownDuration = Math.floor(cooldownDuration * 1 / cdr)

				if (timeSinceLastUse > cooldownDuration * 0.001) {
					this.lastSkillTime[i] = Time.timeElapsedSinceModeStartInSeconds;

					const cooldownSlot = i + 1;
					const movement = this.inputToDir(curInput);
					clientPerformSkill(this, localPlayerData, cooldownSlot, skillId, movement, this.player.movementSpeed);
					const skillDuration = getSkillDurationMs(localPlayerData, cooldownSlot, 1.0)
					const skillUsed = new SkillUsedMessage({ skillId, skillDuration, cooldownDuration, skillSlot: cooldownSlot } as any);
					player.handleSkillUsed(skillUsed);
				}
			}
		}
	}

	inputToDir(input: PlayerInput) {
		const movement = new Vector();
		if (input.up) {
			movement.y -= 1
		}
		if (input.down) {
			movement.y += 1
		}
		if (input.left) {
			movement.x -= 1
		}
		if (input.right) {
			movement.x += 1
		}

		movement.normalize()

		return movement
	}

	handleInputEntry(inputEntry: IPlayerInputHistoryEntry) {
		const { input, delta } = inputEntry
		const acceleration = this.player.movementSpeed;

		updatePhysics(inputEntry, this.predictedPos, delta, false)

		const movement = this.inputToDir(input)

		if (movement.len() > 0) {
			const velocity = new Vector()
			velocity.x = movement.x * acceleration
			velocity.y = movement.y * acceleration

			if (this.player.inputMovementPrevented > 0) {
				velocity.x = 0
				velocity.y = 0
			}

			this.predictedPos.x += velocity.x * delta
			this.predictedPos.y += velocity.y * delta

			playerCollision(this.player)
		}
	}

	update(delta: timeInSeconds) {
		const moved = this.smoother.update(this.predictedPos, delta)

		this.player.isMoving = moved
		if (moved) {
			updateVisualsAfterMovement(this.player, true)
		}

		if (movementConfig.debug) {
			this.updateDebug(delta)
		}
	}

	updateDebug(delta: timeInSeconds) {
		clientDebug.drawCircle(this.predictedPos, 20, 0x0000ff, false, 0)
		clientDebug.drawCircle(this.clientPosSmoothed, 20, 0x00ff00, false, 0)
		clientDebug.drawCircle(this.player, 20, 0xff0000, false, 0)
	}
}
