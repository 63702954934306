import '../../third-party/pixi-spine-debug'
import { clientConfig } from '../../engine/client/client-config'
import { Audio } from '../../audio/client/audio'
import { ObjectPool, PoolableObject } from '../../third-party/object-pool'
import { AssetManager } from '../../asset-manager/client/asset-manager'
import logger from '../../utils/client-logger'
import { SpineDataName } from '../shared/spine-config'
import { forIn } from 'lodash'
import { propConfigs } from '../../world/shared/prop-configs'
import { getSpineAsset } from '../../utils/pixi-util'
import { highResolutionTimestamp } from '../../utils/debug'
import { doNotPool, spinePoolConfig } from '../../third-party/pool-config'
import { debugConfig } from '../../engine/client/debug-config'

type PoolKey = string //key format: "${asset},${skin}"

const PROP_POOL_SIZE = 1
const ITEM_POOL_SIZE = 4
const MODEL_POOL_SIZE = 8
const DEFAULT_SKIN_NAME = 'default'

// TODO2: spinePoolConfig has a similar list, but only has the entries I encounted while running through the game world a few times
//  this list has *all* props (some of which might not actually show up in game). We could combine these lists and be slightly more
//  efficient if we didn't pool things we never view.
const propPools = [
	'beach/outpost-campfire',
	'beach/outpost-torches',
	'beach/outpost-bubba',
	'beach/poi-02-lighthouse',
	'beach/small-rare-01',
	'beach/small-rare-02',
	'beach/large-uncommon-01-var01',
	'beach/large-uncommon-01-var02',
	'beach/large-uncommon-01-var03',
	'beach/large-rare-01-var01',
	'beach/large-rare-01-var02',
	'beach/large-rare-01-var03',
	'beach/beach-alter',
	'forest/outpost-campfire',
	'forest/outpost-smoke',
	'forest/outpost-lanterns',
	'forest/outpost-bubba',
	'forest/outpost-bat',
	'forest/poi-01-cocoon-01',
	'forest/poi-01-cocoon-02',
	'forest/poi-01-cocoon-03',
	'forest/boss-arena-glow-particle',
	'forest/boss-arena-tree-animations',
	'forest/boss-arena-mushroom-a',
	'forest/boss-arena-mushroom-b',
	'forest/boss-arena-mushroom-c',
	'forest/small-rare-01',
	'forest/large-rare-01-var01',
	'forest/large-rare-01-var02',
	'forest/large-rare-01-var03',
	'forest/large-rare-02-var01',
	'forest/large-rare-02-var02',
	'forest/forest-alter',
	'fungi/boss-arena-spores',
	'fungi/boss-arena-spores-02',
	'fungi/outpost-campfire',
	'fungi/outpost-smoke',
	'fungi/outpost-lanterns',
	'fungi/outpost-bubba',
	//'fungi/fungi-swamp',
	'fungi/small-rare-01',
	'fungi/large-rare-01-var01',
	'fungi/large-rare-01-var02',
	'fungi/large-rare-01-var03',
	'fungi/large-rare-02-var02',
	'fungi/fungi-alter',
	'prism/boss-arena-glints',
	'prism/boss-arena-lanterns',
	'prism/poi-02-crystal-glow',
	'prism/poi-02-glints',
	'prism/poi-02-lanterns',
	'prism/outpost-base',
	'prism/outpost-lanterns',
	'prism/outpost-fence',
	'prism/outpost-props',
	'prism/outpost-bubba',
	'prism/small-rare-01',
	'prism/small-rare-02',
	'prism/small-rare-03',
	'prism/large-uncommon-01-var01',
	'prism/large-uncommon-01-var02',
	'prism/large-uncommon-01-var03',
	'prism/large-rare-01-var01',
	'prism/large-rare-01-var02',
	'prism/large-rare-01-var03',
	'prism/large-rare-02-var01',
	'prism/large-rare-02-var02',
	'prism/large-rare-02-var03',
	'prism/prism-alter',
	'highlands/boss-arena-animated',
	'highlands/boss-arena-glow-01',
	'highlands/boss-arena-glow-02',
	'highlands/boss-arena-glow-03',
	'highlands/boss-arena-glow-04',
	'highlands/boss-arena-glow-05',
	'highlands/boss-arena-glow-06',
	'highlands/boss-arena-glow-07',
	'highlands/boss-arena-flag',
	'highlands/outpost-lanterns',
	'highlands/outpost-campfire',
	'highlands/outpost-bubba',
	'highlands/outpost-sign',
	'highlands/outpost-bottom-rock-glow',
	'highlands/outpost-top-rock-glow',
	'highlands/small-rare-01',
	'highlands/med-uncommon-01',
	'highlands/med-uncommon-02',
	'highlands/med-uncommon-03',
	'highlands/large-uncommon-01-var01',
	'highlands/large-uncommon-01-var02',
	'highlands/large-uncommon-01-var03',
	'highlands/large-rare-01-var02',
	'highlands/large-rare-01-var03',
	'highlands/highlands-alter',
	'town/bg-animated',
	'town/docks-animated',
	'town/furnace-animated',
	'town/pit-of-chances-animated',
	'town/plaza-animated',
	'town/test-range-animated',
	'test-range-animated2',
	'test-range-animated3',
	'town/test-range-animated4',
	'test-range-animated5',
	'test-range-animated6',
	'test-range-animated7',
	'test-range-animated8',
	'test-range-animated9',
	'test-range-animated10',
	'town/water',
	'shared/water',
	'shared/seagull',
	'shared/boat',
	'shared/lights',
	'shared/Connection-Road',
	'shared/Waterfall-animated',
	'shared/End-Island-Tree',
]
const itemIconPools = [
	'itemIcons,health-drop-large',
	'itemIcons,health-drop',
	'itemIcons,common-augment',
	'itemIcons,common-sword',
	'itemIcons,common-scythe',
	'itemIcons,common-staff',
	'itemIcons,common-wand',
	'itemIcons,common-arcane',
	'itemIcons,common-crossbow',
	'itemIcons,common-ring',
	'itemIcons,common-trinket',
	'itemIcons,common-pendant',
	'itemIcons,common-belt',
	'itemIcons,uncommon-augment',
	'itemIcons,uncommon-sword',
	'itemIcons,uncommon-scythe',
	'itemIcons,uncommon-staff',
	'itemIcons,uncommon-wand',
	'itemIcons,uncommon-arcane',
	'itemIcons,uncommon-crossbow',
	'itemIcons,uncommon-ring',
	'itemIcons,uncommon-trinket',
	'itemIcons,uncommon-pendant',
	'itemIcons,uncommon-belt',
	'itemIcons,rare-augment',
	'itemIcons,rare-sword',
	'itemIcons,rare-scythe',
	'itemIcons,rare-staff',
	'itemIcons,rare-wand',
	'itemIcons,rare-arcane',
	'itemIcons,rare-crossbow',
	'itemIcons,rare-ring',
	'itemIcons,rare-trinket',
	'itemIcons,rare-pendant',
	'itemIcons,rare-belt',
	'itemIcons,epic-augment',
	'itemIcons,epic-sword',
	'itemIcons,epic-scythe',
	'itemIcons,epic-staff',
	'itemIcons,epic-wand',
	'itemIcons,epic-arcane',
	'itemIcons,epic-crossbow',
	'itemIcons,epic-ring',
	'itemIcons,epic-trinket',
	'itemIcons,epic-pendant',
	'itemIcons,epic-belt',
	'itemIcons,legendary-augment',
	'itemIcons,legendary-sword',
	'itemIcons,legendary-scythe',
	'itemIcons,legendary-staff',
	'itemIcons,legendary-wand',
	'itemIcons,legendary-arcane',
	'itemIcons,legendary-crossbow',
	'itemIcons,legendary-ring',
	'itemIcons,legendary-trinket',
	'itemIcons,legendary-pendant',
	'itemIcons,legendary-belt',
	'itemIcons,astronomical-augment',
	'itemIcons,astronomical-sword',
	'itemIcons,astronomical-scythe',
	'itemIcons,astronomical-staff',
	'itemIcons,astronomical-wand',
	'itemIcons,astronomical-arcane',
	'itemIcons,astronomical-crossbow',
	'itemIcons,astronomical-ring',
	'itemIcons,astronomical-trinket',
	'itemIcons,astronomical-pendant',
	'itemIcons,astronomical-belt',
	'itemIcons,biomekey-forest',
	'itemIcons,biomekey-fungisteppes',
	'itemIcons,biomekey-onyxhighlands',
	'itemIcons,biomekey-sunsetprism',
	'itemIcons,lucky-shard',
	'itemIcons,rarityshard-epic',
	'itemIcons,rarityshard-legendary',
	'itemIcons,rarityshard-astronomical',
]

export class RiggedSpineModel extends PIXI.spine.Spine implements PoolableObject {
	static pools: Map<PoolKey, ObjectPool> = null

	static allocFromPool(assetName: string, skin: string) {
		if (debugConfig.pooling.disableSpinePools) {
			const asset = AssetManager.getInstance().getAssetByName(assetName)
			const model = new RiggedSpineModel(asset.spineData)
			model.skeleton.setSkinByName(skin)
			model.skeleton.setToSetupPose()
			return model
		}

		if (!RiggedSpineModel.pools) {
			RiggedSpineModel.pools = initPools()
		}

		// see if there's a pool available for this model, and return a model from there if so
		const key: PoolKey = assetName + ',' + skin
		const pool = RiggedSpineModel.pools.get(key)
		if (!pool) {
			const asset = AssetManager.getInstance().getAssetByName(assetName)
			//logger.error(`no pool for key ${key}, add to list`)
			const model = new RiggedSpineModel(asset.spineData)
			model.skeleton.setSkinByName(skin)
			model.skeleton.setToSetupPose()
			return model
		}

		const pooledModel = pool.alloc() as RiggedSpineModel

		return pooledModel
	}
	entity: any

	poolKey: PoolKey

	constructor(spriteData: PIXI.spine.core.SkeletonData) {
		super(spriteData)

		this.skeleton.setToSetupPose()
		this.update(0)
		this.autoUpdate = false

		this.state.addListener({
			event(entry: PIXI.spine.core.TrackEntry, event: PIXI.spine.core.Event) {
				if (event.data.audioPath) {
					Audio.getInstance().playSfx(event.data.audioPath)
				}
			},
		})

		if (clientConfig.renderSpineDebugVisuals) {
			this.enableSpineDebug()
		}
	}

	cleanup() {
		// clear out old animation poses. If we don't do this then some of the death animation is still being applied to the pose
		for (let i = 0; i < this.state.tracks.length; i++) {
			this.state.setEmptyAnimation(i, 0)
		}
		// NOTE: I want to call model.state.clearTracks here, however this stops the empty anim from being applied above
		//  Upshot is recycled dead enemies have 6 tracks upon spawn (instead of usual 3)

		this.x = 0
		this.y = 0

		this.scale.x = 1
		this.scale.y = 1

		this.skeleton.scaleX = 1

		this.visible = true
		this.alpha = 1
	}

	static create(spriteData: PIXI.spine.core.SkeletonData) {
		return new RiggedSpineModel(spriteData)
	}

	returnToPoolIfPooled() {
		if (!RiggedSpineModel.pools) {
			return
		}
		const pool = RiggedSpineModel.pools.get(this.poolKey)
		if (pool) {
			pool.free(this)
		}
	}

	setDefaultValues(defaultValues: any, overrideValues?: any) { }

	attachSpineSprite(slotName: string, sprite: RiggedSpineModel) {
		sprite.scale.set(1, -1)
		super.attachSpineSprite(slotName, sprite)
	}

	detachSpineSprite(slotName: string) {
		super.detachSpineSprite(slotName)
	}

	private enableSpineDebug() {
		const that = this as any
		that.drawDebug = true
		that.drawBones = true
		that.drawRegionAttachments = false
		that.drawClipping = false
		that.drawMeshHull = false
		that.drawMeshTriangles = false
		that.drawPaths = false
		that.drawBoundingBoxes = false
	}
}

function initPools(): Map<PoolKey, ObjectPool> {
	const start = highResolutionTimestamp()

	const pools: Map<PoolKey, ObjectPool> = new Map()

	propPools.forEach((assetName) => {
		const key = `${assetName},${DEFAULT_SKIN_NAME}`
		let poolSize = PROP_POOL_SIZE
		if (spinePoolConfig[key] !== undefined) {
			poolSize = spinePoolConfig[key]
		}
		pools.set(key, createPool(key, poolSize))
	})

	itemIconPools.forEach((key) => {
		let poolSize = ITEM_POOL_SIZE
		if (spinePoolConfig[key] !== undefined) {
			poolSize = spinePoolConfig[key]
		}
		pools.set(key, createPool(key, poolSize))
	})

	for (const value in SpineDataName) {
		if (Object.prototype.hasOwnProperty.call(SpineDataName, value)) {
			const element = SpineDataName[value]
			const key = `${element},${DEFAULT_SKIN_NAME}`

			if (doNotPool.includes(key)) {
				continue
			}

			try {
				let poolSize = MODEL_POOL_SIZE
				if (spinePoolConfig[key] !== undefined) {
					poolSize = spinePoolConfig[key]
				} else if (debugConfig.pooling.debug) {
					console.warn(`no pool config for ${key}, using default of ${MODEL_POOL_SIZE}`)
				}
				pools.set(key, createPool(key, poolSize))
			} catch {
				logger.error(`error creating pool for:${key}`)
			}
		}
	}

	//debugPrintAnimatedProps()
	const secondsTaken = (highResolutionTimestamp() - start) * 0.001
	logger.debug(`Initializing ${pools.size} pools took ${secondsTaken.toFixed(3)} seconds`)

	return pools
}

function createPool(poolKey: PoolKey, poolSize: number) {
	if (debugConfig.pooling.debug) {
		console.log(`creating pool:${poolKey} size:${poolSize}`)
	}

	const words = poolKey.split(',')
	const asset = words[0]
	const skin = words[1]
	const modelAsset = AssetManager.getInstance().getAssetByName(asset)
	const pool = new ObjectPool(
		() => {
			const model = RiggedSpineModel.create(modelAsset.spineData)
			model.skeleton.setSkinByName(skin)
			model.skeleton.setToSetupPose()
			model.poolKey = poolKey
			return model
		},
		undefined,
		poolSize,
		1,
		`${poolKey}`,
	)
	return pool
}

export function debugPrintAnimatedProps() {
	forIn(propConfigs, (biome, biomeId) => {
		forIn(biome, (propConfig, propName) => {
			const propId = `${biomeId}/${propName}`
			const asset = getSpineAsset(propId)
			if (asset) {
				const spineData = asset.spineData as PIXI.spine.core.SkeletonData

				const animCount = spineData.animations.length

				if (animCount > 0 || propConfig.rigged) {
					console.log(`'${propId}',`)
				}
			} else {
				//console.error(`no asset for ${propId}`)
			}
		})
	})
}
