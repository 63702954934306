

























































import PanelDivider from '../reusable-components/panel-divider/panel-divider.vue'
import SidePanel from '../reusable-components/panels/side-panel.vue'
import ButtonedGridItemContainer from '../reusable-components/item-containers/buttoned-grid-item-container.vue'
import { mapGetters, mapState, mapActions } from 'vuex'
import { HALF_PIT_REWARD_COIN_SEND, MAX_PIT_REWARD_COIN_SEND } from '../../engine/shared/game-data/player-formulas'

export default {
	name: 'FlyoutPitOfChances',
	components: {
		SidePanel,
        ButtonedGridItemContainer,
        PanelDivider,
	},
	data() {
		return {
			maxPitCoinItems: MAX_PIT_REWARD_COIN_SEND,
			halfPitCoinItems: HALF_PIT_REWARD_COIN_SEND,
		}
	},
	props: {},
	computed: {
		...mapGetters('itemContainers', ['itemsInContainer', 'itemDetails', 'isPitStorageFull']),
		...mapGetters('pitOfChances', ['itemIsInPit', 'getItemsInPit', 'maxItemsInPit', 'pitIsFull']),
		...mapState('pitOfChances', ['selectedPitItem', 'itemsTossedForCoins']),
	},
	methods: {
		...mapActions('pitOfChances', ['removeItemFromPit', 'activatePit', 'selectPitItem']),
	},
}
