import nengi from 'nengi'
import { uuid } from '../../utils/primitive-types'

class FetchItemListingsCommand {
	static protocol = {
		userId: nengi.String,
		page: nengi.Number,
		sortBy: nengi.String,
		orderBy: nengi.String,
		rarity: nengi.Number
	}
	userId: uuid
	page: number
	sortBy: string
	orderBy: string
	rarity: number
	/**
	 * Creates an instance of FetchItemListingsCommand.
	 * @param {uuid} userId Item ID
	 * @memberof FetchItemListingsCommand
	 */
	constructor(userId: uuid, page: number, sortBy: string, orderBy: string, rarity: number) {
		this.userId = userId
		this.page = page
		this.sortBy = sortBy
		this.orderBy = orderBy
		this.rarity = rarity
	}
}

export default FetchItemListingsCommand
