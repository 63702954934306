import nengi from 'nengi'

/**
 * This is a string representation of the game world, to draw as an ascii map
 * Used by bots only
 */
class WorldBiomeGridMessage {
	static protocol = {
		grid: nengi.String,
	}

	grid: string

	constructor(grid: string) {
		this.grid = grid
	}
}

export default WorldBiomeGridMessage
