import nengi from 'nengi'
import { WeaponSubType } from '../../loot/shared/weapon-sub-type'
import { VectorXY } from '../../utils/math'
import { percentage } from '../../utils/primitive-types'
import { ProjectileTargetType } from './projectile-types'

export class ProjectileCreateCommand {
	static protocol = {
		projectileId: { type: nengi.UInt32 },
		targetType: { type: nengi.String },
		spawnPosX: { type: nengi.Float32 },
		spawnPosY: { type: nengi.Float32 },
		spawnTrajectoryX: { type: nengi.Float32 },
		spawnTrajectoryY: { type: nengi.Float32 },
		baseTrajectoryX: { type: nengi.Float32 },
		baseTrajectoryY: { type: nengi.Float32 },
		chargePercent: { type: nengi.Float32 },
		weaponType: { type: nengi.Int4 },
		flip: { type: nengi.Boolean },
	}

	projectileId: number
	targetType: ProjectileTargetType
	spawnPosX: number
	spawnPosY: number
	spawnTrajectoryX: number
	spawnTrajectoryY: number
	baseTrajectoryX: number
	baseTrajectoryY: number
	//entitiesHit?: EntityHit[], //TODOCSP
	chargePercent: number
	weaponType: WeaponSubType
	flip: boolean

	constructor(projectileId: number, targetType: ProjectileTargetType, spawnPos: VectorXY, spawnTrajectory: VectorXY, baseTrajectory: VectorXY, chargePercent: percentage, weaponType: WeaponSubType, flip: boolean) {
		this.projectileId = projectileId
		this.targetType = targetType
		this.spawnPosX = spawnPos.x
		this.spawnPosY = spawnPos.y
		this.spawnTrajectoryX = spawnTrajectory.x
		this.spawnTrajectoryY = spawnTrajectory.y
		this.baseTrajectoryX = baseTrajectory.x
		this.baseTrajectoryY = baseTrajectory.y
		this.chargePercent = chargePercent
		this.weaponType = weaponType
		this.flip = flip
	}
}
