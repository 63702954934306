import nengi from 'nengi'
import { uuid } from '../../utils/primitive-types'

class EquipGearToSlotCommand {
	static protocol = {
		gearId: nengi.String,
		slotIndex: nengi.Number,
	}
	gearId: uuid
	slotIndex: number
	/**
	 * Creates an instance of EquipWeaponToSlotCommand. This will scan the Stash to find a matching item.
	 * @param {uuid} gearId Weapon ID
	 * @param {number} slotIndex Slot index
	 * @memberof EquipGearToSlotCommand
	 */
	constructor(gearId: uuid, slotIndex: number) {
		this.gearId = gearId
		this.slotIndex = slotIndex
	}
}

export default EquipGearToSlotCommand
