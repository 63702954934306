




































































import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
	name: 'ItemTools',
	components: {
		vSelect,
	},
	data(){
		return {
			itemRarity: 'Rare'
		}
	},
	computed: {
		...mapState(['showItemTools']),
		...mapState('itemTools', ['itemTypes', 'selectedItemType', 'selectedRarity', 'itemRarities', 'weaponElements', 'gearSkills', 'itemLevel', 'rollIdentified', 'listOnMarketplace', 'selectedItemSubType, selectedWeaponMods', 'allowBulkMarketplaceListing']),
		...mapGetters('itemTools', ['getItemSubTypes', 'getWeaponMods']),
	},
	methods: {
		...mapMutations('itemTools', ['setActiveItemType', 'setActiveRarity', 'setActiveElement', 'setActiveGearSkill', 'setActiveItemSubType', 'setItemLevel', 'setRollIdentified', 'setSelectedWeaponMods', 'setListOnMarketplace']),
		...mapActions('itemTools', ['rollItem', 'rollPetEggs', 'rollCosmeticAttachments', 'rollOneOfEachSubType', 'rollFactionCosmetics']),
		getWorldTierInfoFromLevel(level) {
			const biomes = [null, 'Beach', 'Forest', 'Onyx', 'Fungi', 'Prism']
			const biome = Math.ceil(Math.ceil((level + 1) % 15) / 3)
			const worldTier = Math.ceil(level / 15)
			console.log('getWorldTierInfoFromLevel:', { biome, worldTier })
			return `WT${worldTier} ${biomes[biome]}`
		},
	},
}
