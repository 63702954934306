import nengi from 'nengi'

export default class DailyLoginInfoMessage {
	static protocol = {
		lastClaimedLoginBonus: { type: nengi.String },
		dailyLoginsClaimed: { type: nengi.Number },
		nextLoginReward: { type: nengi.Number },
		lastLoginReward: { type: nengi.Number },
		nowUnix: { type: nengi.Number },
	}

	lastClaimedLoginBonus: string
	dailyLoginsClaimed: number
	nextLoginReward: number
	lastLoginReward: number
	nowUnix: number

	constructor(lastClaimedLoginBonus, dailyLoginsClaimed, nextLoginReward, lastLoginReward, nowUnix) {
		this.lastClaimedLoginBonus = lastClaimedLoginBonus
		this.dailyLoginsClaimed = dailyLoginsClaimed
		this.nextLoginReward = nextLoginReward
		this.lastLoginReward = lastLoginReward
		this.nowUnix = nowUnix
	}
}
