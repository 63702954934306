




















import { mapState } from 'vuex'

export default {
	name: 'DropDown',
	props: {
		labelId: {
			type: String,
			required: false,
			default: 'default',
		},
		dropdownOptions: {
			type: Array,
			required: true,
			default: null,
		},
		labelText: {
			type: String,
			required: false,
			default: 'default',
		},
		defaultSelected: {
			type: String,
			required: false,
		},
		name: {
			type: String,
			required: false,
			default: 'default',
		},
		selectedItem: {
			type: [String, Number],
			required: false,
			default: 'default',
		},
		inlineIcons:{
			type: Boolean,
			required: false,
		},
		getIconHexCode:{
			type: Function,
			required: false,
		}
		
	},
	data() {
		return {}
	},
	computed: {
		...mapState('mainMenu', ['isChrome']),
		
		option: {
			get() {
				return this.selectedItem
			},
			set(value) {
				this.$emit('update-value', value)
			},
		},
	},
	methods: {
		onChange(value) {
			this.$emit('update-value', value)
		},
		dropDownText(optionName, optionId){
			
			if(this.inlineIcons && this.isChrome ) {
				const hexIcon = this.getIconHexCode(optionId)
				return `${hexIcon} ${optionName}`
			} else {
				return `${optionName}`
			}
		}
	},
}
