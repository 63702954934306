















export default {
	name: 'LoadingTransition',
	components: {},
	data() {
		return {}
	},
	props: {},
	computed: {},
	methods: {},
}
