






















































































































































































































































































































































































































































































import { mapGetters, mapMutations, mapState } from 'vuex'
import { getSpreadDescriptorForTooltip, getVarianceDescriptorForTooltip, getSplashRadiusDescriptorForAugments, getProjectileSpeedDescriptor, getConverterDescriptor, getClampDescriptor, getBinaryFlagDescriptor, getStatStringForTooltip } from '../../../loot/shared/item-stat-descriptors'
import { PetSubTypePrettyNames, PrizeSubType } from '../../../loot/shared/prize-sub-type'
import { isSkinSlotWeapon, SKIN_SLOT } from '../../../player/shared/set-skin.shared'
import { replace } from 'lodash'
import { toCountdownString } from '../../../utils/math'
import moment from 'moment-timezone'
import { MS_FOR_WORM_DELIVERY_TO_EXPIRE } from '../../../utils/economy-data'
import { SkinInformationByIdentifier } from '../../../loot/shared/player-skin-sub-type'
import { WeaponSkinPrettyNames } from '../../../loot/shared/weapon-skin-sub-type'
import { PersistedBuffSubtypeToDesc } from '../../../loot/shared/persistent-buff-sub-type'
import { FactionAugmentBonuses, FactionShortName, augmentFactionIcon, factionTextColor } from '../../../factions/shared/faction-data'
import { StatToTooltipName } from '../../../items/shared/tool-tip-info'
import { ItemType } from '../../../loot/shared/item-type'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-var-requires
const elementalImages = require('./assets/elemental-*.png')


export default {
	name: 'ToolTip',
	props: {},
	data() {
		return {
			randomCount: Math.floor(Math.random() * 25),
			quadrantString: 'top-left',
			verticalProp: 'top',
			horizontalProp: 'left',
			verticalValue: 0,
			horizontalValue: 0,
			nowTime: moment().utc(),
			timeUpdateInterval: null,
		}
	},
	computed: {
		...mapGetters('UIScale', ['currentHoveredItem', 'currentHorizontalValue', 'currentVerticalValue', 'currentHorizontalProp', 'currentVerticalProp', 'currentQuadrantString', 'currentTooltipDebug', 'currentHoveredComponent', 'currentScale']),
		...mapState('inGame', ['playerTab']),
		...mapGetters('inGame', ['activePanel']),
		...mapGetters('furnace', ['unixDiff']),
		...mapState('factions', ['lastWinningFaction']),
		augmentFactionBonuses() {
			if(this.currentHoveredItem.itemTypeEnum === ItemType.WeaponAugment) {
				const bonuses = FactionAugmentBonuses.get(this.lastWinningFaction)
				if(bonuses) {
					for(let i =0; i < bonuses.length; ++i) {
						const bonus = bonuses[i]
						if(bonus.augmentType === this.currentHoveredItem.itemSubType) {
							const toReturn = {}
							
							for(let o =0; o < bonus.stats.length; ++o) {
								let statName = bonus.stats[o]
								if(statName.endsWith('Damage')) {
									statName += 'Boost'
								}
								toReturn[statName] = 1 +  bonus.bonusAmounts[o]
							}

							return toReturn
						}
					}
				}
			}

			return false
		},
		
		transformOriginQuadrant() {
			return replace(this.currentQuadrantString, '-', ' ')
		},
		getHotkeyButtonName() {
			const slotIndex = this.currentHoveredItem.slotIndex
			switch (slotIndex) {
				case 2:
					return 'Space'
				case 3:
					return 'E'
				case 4:
					return 'R'
				default:
					return ''
			}
		},
		getWeaponSubtypeName() {
			const itemSubType = this.currentHoveredItem.itemSubType
			switch (itemSubType) {
				case 1:
					return 'Spellsword'
				case 2:
					return 'Scythe'
				case 3:
					return 'Staff'
				case 4:
					return 'Wand'
				case 5:
					return 'Arcane Focus'
				case 6:
					return 'Crossbow'
				default:
					return ''
			}
		},
		getWeaponDescription() {
			const itemSubType = this.currentHoveredItem.itemSubType
			switch (itemSubType) {
				case 2:
				case 4:
				case 5:
					return `Click to fire your ${this.getWeaponSubtypeName}. Press [Q] to switch Weapons`
				case 1:
					return `Click and hold to fire your ${this.getWeaponSubtypeName}. Press [Q] to switch Weapons`
				case 3:
				case 6:
					return `Click and hold to charge up your ${this.getWeaponSubtypeName}, release to fire. Press [Q] to switch Weapons`
				default:
					return ''
			}
		},
		getMtxName() {
			if (this.currentHoveredItem.isSkin) {
				switch (this.currentHoveredItem.slot) {
					case SKIN_SLOT.PET_MAIN:
						return PetSubTypePrettyNames.get(this.currentHoveredItem.name)
				}
			}
			return ''
		},
		getPrizeTooltip() {
			const subType = this.currentHoveredItem.itemSubType
			if (subType === PrizeSubType.PetEgg) {
				return 'Hatch it to unlock this pet!'
			} else if (subType === PrizeSubType.Emote) {
				return 'Open it to unlock this emote!'
			} else {
				return 'Open it to unlock this skin!'
			}
		},
	},
	mounted() {
		window.addEventListener('mousemove', this.moveTooltip)
	},
	destroyed() {
		window.removeEventListener('mousemove', this.moveTooltip)
		if (this.timeUpdateInterval) {
			clearInterval(this.timeUpdateInterval)
		}
	},
	methods: {
		...mapMutations('UIScale', ['showTooltip', 'updateToolTipPosition']),

		getSpreadDescriptorForTooltip,
		getVarianceDescriptorForTooltip,
		getSplashRadiusDescriptorForAugments,
		getProjectileSpeedDescriptor,
		getConverterDescriptor,
		getClampDescriptor,
		getBinaryFlagDescriptor,
		augmentFactionIcon,
		factionTextColor,

		moveTooltip(event) {
			if (this.currentHoveredItem === null) return
			this.updateToolTipPosition(event)
		},
		currentHoveredItemDescription(item) {
			if (item.identifier === 0) return 'Hide cosmetic'

			if (item.skinSlot === SKIN_SLOT.PET_MAIN) {
				return PetSubTypePrettyNames.get(item.identifier)
			}

			if (isSkinSlotWeapon(item.skinSlot)) {
				return WeaponSkinPrettyNames.get(item.identifier)
			}

			const skinInfo = SkinInformationByIdentifier.get(item.identifier)
			if (skinInfo) {
				return skinInfo.prettyName
			}
		},
		currentHoveredItemExpiryTimeString(item) {
			if (this.currentHoveredComponent === 'expiry' && !this.timeUpdateInterval) {
				//jank but like idk
				this.timeUpdateInterval = setInterval(this.setNowTime, 1000)
			}

			if (item.createdAt) {
				const diffMs = this.nowTime.diff(item.createdAt)

				const expiryMs = Math.max(MS_FOR_WORM_DELIVERY_TO_EXPIRE - diffMs, 0)

				return toCountdownString(Math.floor(expiryMs / 1000), false)
			}

			return '00:00:00'
		},
		currentHoveredItemName(item) {
			if (item.identifier === 0) return 'Disabled'
			if (item.cosmeticType === 'Pet Skin') return 'Pet'
			return item.cosmeticType
		},
		showLevel(item) {
			if (item.itemType == 'Biome Key' || item.itemType == 'Lucky Shard' || item.itemType == 'Rarity Shard' || item.simpleTooltip || item.itemType === 'Weapon Enchantment' || item.itemType === 'Persistent Buff') {
				return false
			} else {
				return true
			}
		},
		showRarity(item) {
			if (item.itemType == 'Biome Key' || item.itemType == 'Lucky Shard' || item.simpleTooltip || item.itemType === 'Weapon Enchantment' || item.itemType === 'Persistent Buff') {
				return false
			} else {
				return true
			}
		},
		isSimpleDescription(item) {
			if (item.itemType == 'Biome Key' || item.itemType == 'Lucky Shard' || item.itemType == 'Rarity Shard' || item.itemType === 'Persistent Buff' || item.simpleTooltip) {
				return true
			} else {
				return false
			}
		},
		simpleDescription(item) {
			if (item.itemType == 'Biome Key') {
				return "Use when departing from town to jump straight to a specific area! (But only if you've already unlocked it)"
			}
			if (item.itemType == 'Lucky Shard') {
				return 'Toss into the pit of chances to help raise the odds of getting a high-level reward!'
			}
			if (item.itemType == 'Rarity Shard') {
				return 'Collect Rarity shards to unlock additional augment slots for weapons specific to their rarity. Can also be tossed into the pit of chances to help lean the reward towards a specific rarity!'
			}
			if (item.itemType == 'Persistent Buff') {
				return PersistedBuffSubtypeToDesc.get(item.itemSubType)
			}
			if (item.simpleTooltip) {
				return item.tooltip
			}
		},
		subtitle(item) {
			if (item.itemType == 'Weapon Augment') {
				return 'AUGMENT'
			}
			// must be gear then
			switch (item.itemSubType) {
				case 1001:
					return 'TRINKET'
				case 1002:
					return 'BELT'
				case 1003:
					return 'PENDANT'
				case 1004:
					return 'RING'
			}
		},
		setNowTime() {
			this.nowTime = moment().utc().subtract(this.unixDiff, 's')
		},
		getStatPrettyName(stat) {
			const pretty = StatToTooltipName[stat]
			return pretty
		},
		getStatString(stat, value) {
			return getStatStringForTooltip(stat, value)
		},
		getImage(stat) {
			if (stat === 'iceDamage') {
				return elementalImages.ice
			} else if (stat === 'fireDamage') {
				return elementalImages.fire
			} else if (stat === 'physicalDamage') {
				return elementalImages.physical
			} else if (stat === 'poisonDamage') {
				return elementalImages.poison
			} else if (stat === 'lightningDamage') {
				return elementalImages.lightning
			}
			return undefined
		},

		getFactionIconAndStatPrettyName(stat){
			return this.augmentFactionIcon(this.lastWinningFaction) + " " + this.getStatPrettyName(stat)
		}
	},
}
