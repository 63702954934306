export default class TutorialFlags {
	startedFirstRunControls: boolean = false //1
	//2, 3 cut
	firstMonsterDroppedLoot: boolean = false //4
	firstLootAcquired: boolean = false //5
	firstFullInventory: boolean = false //6
	outpostProximity: boolean = false //7
	firstEnteredOutpost: boolean = false //8
	//9 cut
	openedWormMailNPC: boolean = false //10
	openedOutpostShopNPC: boolean = false //11
	openedEndRunNPC: boolean = false //12
	fogWallProximity: boolean = false //13
	transistionedBetweenBiomes: boolean = false //14
	bossArenaProximity: boolean = false //15
	//16 cut
	poiProximity: boolean = false //17

	// == Town ==
	enteredHubWorld: boolean = false //18
	stashProximity: boolean = false //19
	openedStashNPC: boolean = false //20 -> when this is done the player has equipped an augment
	wormDeliveryProximity: boolean = false //21
	openedWormDeliveryNPC: boolean = false //22
	openedDepartureNPC: boolean = false //23
	openedSmeltingFurnaceNPC: boolean = false //24
	openedMarketplaceNPC: boolean = false //25
	openedMTXStoreNPC: boolean = false //26
	openedGeneralStoreNPC: boolean = false //27
	openedIdentificationNPC: boolean = false //28
	openedPitOfChancesNPC: boolean = false //29
	openedCosmeticsNPC: boolean = false //30
	openedLeaderboardNPC: boolean = false //31

	// == Run 2 ==
	startedSecondRun: boolean = false //32
	//33, 34, 35 cut
	worldEdgeBridgeEntranceProximity: boolean = false //36
	worldEdgeBridgeProximity: boolean = false //37
	lowHealthInOutpost: boolean = false //38

	// Other higher-level random tutorials
	partyPlay: boolean = false //39
	prePPD: boolean = false //40
	ppdExplained: boolean = false //41

	hubWorldServersOfflineIntro: boolean = false

	constructor(incomingFlags?: any) {
		if (incomingFlags) {
			this.loadFromJson(incomingFlags)
		}
	}

	loadFromJson(incomingFlags: any) {
		const keys = Object.keys(this)
		for (let i = 0; i < keys.length; ++i) {
			const key = keys[i]
			if (incomingFlags[key] !== null && incomingFlags[key] !== undefined) {
				if(!this[key]) {
					this[key] = incomingFlags[key]
				}
			}
		}
	}
}
