import nengi from 'nengi'

class UpdateNavArrowDestination {
	static protocol = {
		x: nengi.Number,
		y: nengi.Number,
	}

	x: number
	y: number

	constructor(x: number, y: number) {
		this.x = x
		this.y = y
	}
}

export default UpdateNavArrowDestination
