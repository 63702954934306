import nengi from 'nengi'

class ClientLogCommand {
	static protocol = {
		log: nengi.String,
	}

	log: string

	constructor(log: string) {
		this.log = log
	}
}

export default ClientLogCommand
