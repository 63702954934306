import nengi from 'nengi'

export enum BossCreditState {
	NO_BOSS = 0,
	BOSS_CREDIT = 1,
	NO_CREDIT = 2,
}

class NPC {
	static protocol = {
		x: { type: nengi.Float32, interp: true },
		y: { type: nengi.Float32, interp: true },
		name: { type: nengi.String },
		colliderRadiusSquared: { type: nengi.Float32 },
		renderCharacter: { type: nengi.Boolean },
		skin: { type: nengi.String },
		bossCredit: { type: nengi.UInt2 },
		isInteractive: { type: nengi.Boolean },
		isPet: {type: nengi.Boolean },
		currentEmote: { type: nengi.UInt8 },
		facingDirection: { type: nengi.Int4, interp: true},
	}

	nid: number
	colliderRadiusSquared: number
	facingDirection: number = -1
}

export default NPC
