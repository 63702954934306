import nengi from 'nengi'

export class PlayerSetCspCommand {
	static protocol = {
		csp: { type: nengi.Boolean },
	}
	csp: boolean

	constructor(csp: boolean) {
		this.csp = csp
	}
}
