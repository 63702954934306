






export default {
	name: 'EmptySkinGridItem',
	props: {
		silhouetteName: {
			type: String,
			required: false,
			default: 'default',
		},
		enableSilhouette: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
}
