import nengi from 'nengi'

export default class SaveFileMessage {
	static protocol = {
		filename: { type: nengi.String },
		contents: { type: nengi.String },
	}
	filename: string
	contents: string

	constructor(filename: string, contents: string) {
		this.filename = filename
		this.contents = contents
	}
}
