import nengi from 'nengi'

export default class PlayerStartShieldRegenMessage {
	static protocol = {
		entityId: { type: nengi.Number },
		currentShields: { type: nengi.Number },
		maxShields: { type: nengi.Number },
		shieldRegenRate: { type: nengi.Number },
	}

	entityId: number
	currentShields: number
	maxShields: number
	shieldRegenRate: number

	constructor(entityId: number, currentShields: number, maxShields: number, shieldRegenRate: number) {
		this.entityId = entityId
		this.currentShields = currentShields
		this.maxShields = maxShields
		this.shieldRegenRate = shieldRegenRate
	}
}
