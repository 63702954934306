













import MainPanel from '../reusable-components/panels/main-panel.vue'
import PanelDivider from '../reusable-components/panel-divider/panel-divider.vue'

export default {
	name: 'MaintenanceMode',
	components: {
		MainPanel,
		PanelDivider,
	},
}
