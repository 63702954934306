


























import { mapGetters, mapActions } from 'vuex'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import ListItemContainer from '../reusable-components/item-containers/list-item-container.vue'
import PaperdollQuickViewRow from '../reusable-components/item-containers/mini-paperdoll.vue'
import { MAXIMUM_INVENTORY_WEIGHT, MAXIMUM_WORM_MAIL_BOX_SIZE } from '../../engine/shared/game-data/inventory'

export default {
	name: 'Inventory',
	components: {
		MainPanel,
		ListItemContainer,
		PaperdollQuickViewRow,
	},
	data() {
		return {
			maxItems: MAXIMUM_WORM_MAIL_BOX_SIZE,
			maxInventorySize: MAXIMUM_INVENTORY_WEIGHT
		}
	},
	computed: {
		...mapGetters('outpostWormMail', ['wormCurrentlyBeingInteractedWith', 'wormMailSendInProgress']),
		...mapGetters('itemContainers', ['itemsInContainer', 'containerIsFull', 'itemDetails', 'itemByContainerIndex']),
		...mapGetters('inventory', ['selectedInventoryItem']),
		...mapGetters('paperdoll', ['selectedMiniPaperdollIndex']),
	},
	methods: {
		itemIsSelected(itemId) {
			return itemId === this.selectedInventoryItem
		},
		...mapActions('inGame', ['closeActivePanel']),
		...mapActions('inventory', ['tossInventoryItem', 'toggleSelectInventoryItem']),
		...mapActions('outpostWormMail', ['sendItemToWormMail', 'sendWormAway', 'removeItemFromWormMail']),
	},
}
