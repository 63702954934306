









export default {
	name: 'PlainTextButton',
	props: {
		buttonLabel: {
			type: String,
			required: true,
			default: function() {
				console.warn('Currently using default button label. Ensure <PlainTextButton /> component has a custom attribute button-label')
				return 'DEFAULT LABEL'
			},
		},
		isButtonActive: {
			type: Boolean,
			default: false,
			required: true,
		},
		hasSmallText: {
			type: Boolean,
			required: true,
			default: function() {
				console.warn('Currently using default text size. Ensure <PlainTextButton /> component has a custom attribute :large-or-small-text')
				return true
			},
		},
		onClickProp: {
			type: Function,
			required: true,
			default: () => {
				console.warn('The default on-click handler for the <PlainTextButton /> component has been called. Did you forget to pass an :on-click handler to the component?')
			},
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
		showExclamation:{
			type: Boolean,
			required: false,
			default: false,
		}
	},
}
