









































import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import ListItemContainer from '../reusable-components/item-containers/list-item-container.vue'
import ButtonedGridItemContainer from '../reusable-components/item-containers/buttoned-grid-item-container.vue'
import PanelDivider from '../reusable-components/panel-divider/panel-divider.vue'
import ArrowButton from '../reusable-components/buttons/arrow-button.vue'
import { WormHornSubtype } from '../../loot/shared/worm-horn-sub-type'
import { showGenericYesNoUI } from '../state/generic-yes-no.ui-state'
import { PREMIUM_WORM_HORN_ITEMS, STANDARD_WORM_HORN_ITEMS } from '../../engine/shared/game-data/player-formulas'
import { getPressureLoadoutOnClient } from '../../engine/client/pressure.client'
import { pressureGetWormMailSize } from '../../engine/shared/game-data/pressure-stat-functions.shared'

export default {
	name: 'OutpostWormMail',
	components: {
		MainPanel,
		ListItemContainer,
		PanelDivider,
		ButtonedGridItemContainer,
		ArrowButton,
	},
	data() {
		return {}
	},
	computed: {
		...mapGetters('outpostWormMail', ['wormCurrentlyBeingInteractedWith', 'wormMailSendInProgress', 'selectedOutpostWormMailItem', 'getWormHornPrompt', 'getWormHornTypeSelected', 'getWormMailSlotSize']),
		...mapGetters('itemContainers', ['itemsInContainer', 'containerIsEmpty', 'containerIsFull', 'numberOfItemsInContainer', 'standardWormHornCount', 'premiumWormHornCount', 'hasWormHorn']),
		...mapState('outpostWormMail', ['wormHornItems']),

		wormListItemHeight() {
			if (this.getWormHornPrompt) {
				return '940px'
			}
			return '505px'
		},
		maxWormMailSlotSize() {
			if (this.getWormHornTypeSelected !== null) {
				switch (this.getWormHornTypeSelected) {
					case WormHornSubtype.Standard:
						return STANDARD_WORM_HORN_ITEMS
					case WormHornSubtype.Premium:
						return PREMIUM_WORM_HORN_ITEMS
				}
			}
			return pressureGetWormMailSize(getPressureLoadoutOnClient())
		},
		getItemsInInventory() {
			const inventoryItems = this.itemsInContainer('inventory')
			if (this.getWormHornTypeSelected) {
				return inventoryItems.filter((item) => !this.wormHornItems.includes(item))
			}

			return inventoryItems
		},
	},
	methods: {
		...mapActions('inGame', ['closeActivePanel']),
		...mapMutations('inGame', ['setPanelSwitchable', 'setActivePanel']),
		...mapActions('outpostWormMail', ['sendItemToWormMail', 'sendWormAway', 'removeItemFromWormMail', 'toggleSelectOutpostWormMailItem', 'sendWormHorn']),
		...mapMutations('outpostWormMail', ['addItemToWormHornCargo', 'removeItemFromWormHornCargo']),
		itemIsSelected(itemId) {
			return itemId === this.selectedOutpostWormMailItem
		},
		addButtonClicked(itemId) {
			if (this.getWormHornTypeSelected) {
				this.addItemToWormHornCargo(itemId)
			} else {
				this.sendItemToWormMail(itemId)
			}
		},
		removeButtonClicked(itemId) {
			if (this.getWormHornTypeSelected) {
				this.removeItemFromWormHornCargo(itemId)
			} else {
				this.removeItemFromWormMail(itemId)
			}
		},
		sendWorm() {
			if (this.getWormHornTypeSelected) {
				this.sendWormHorn()
			} else {
				this.sendWormAway()
			}
		},
		disableBasedOnContainer() {
			if (this.getWormHornTypeSelected !== null) {
				return this.wormHornItems.length === 0
			} else {
				return this.containerIsEmpty('outpostWormMail', this.wormCurrentlyBeingInteractedWith)
			}
		},
		wormContainerItems() {
			if (this.getWormHornTypeSelected !== null) {
				return this.wormHornItems
			} else {
				return this.itemsInContainer('outpostWormMail', this.wormCurrentlyBeingInteractedWith)
			}
		},
		closeButtonClicked() {
			if (this.getWormHornTypeSelected !== null) {
				showGenericYesNoUI('Worm Horn', 'Cancel Worm Horn?', undefined, 'Cancel', 'Use', () => {
					this.setPanelSwitchable(true)
					this.setActivePanel('gameOver')
				})
			} else {
				this.closeActivePanel()
			}
		},
	},
}
