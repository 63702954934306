import { AnyColliderConfig, ColliderTraits, ColliderType } from '../../collision/shared/colliders'
import { degToRad } from '../../utils/math'
import { makeBlockPropConfig, PropConfigs } from './prop-configs'

// NOTE: most of this file was copy/pasted from the beach

const largeUncommonColliders: [AnyColliderConfig] = [{ type: ColliderType.Ellipse, position: [-10, 74], rX: 268, rY: 146, traits: ColliderTraits.BlockMovementAndItem }]
const largeRareColliders: [AnyColliderConfig] = [{ type: ColliderType.Ellipse, position: [-15, 30], rX: 724, rY: 508, traits: ColliderTraits.BlockMovementAndItem }]
const largeRareVar02Colliders: [AnyColliderConfig] = [{ type: ColliderType.Ellipse, position: [-70, 50], rX: 350, rY: 200, traits: ColliderTraits.BlockMovementAndItem }]

export const highlandsPropConfigs: PropConfigs = {
	'boss-arena': {
		disableFileOrdering: true,
		colliders: [
			{ type: ColliderType.Ellipse, position: [0, -12], rX: 189, rY: 108, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [-467, -650], rX: 211, rY: 105, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [487, -637], rX: 181, rY: 100, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [1130, 25], rX: 160, rY: 100, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [662, 985], rX: 146, rY: 87, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [-709, 971], rX: 169, rY: 106, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [-1105, 29], rX: 102, rY: 78, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-822, -1787], width: 1692, height: 268, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-822, -1519], width: 1692, height: 50, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-728, 1515], width: 1475, height: 202, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-728, 1717], width: 1475, height: 50, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [1933, 300], width: 344, height: 320, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [1994, -226], width: 309, height: 637, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [1424, -1110], width: 268, height: 1240, traits: ColliderTraits.BlockAll, angle: -0.52 },
			{ type: ColliderType.Box, position: [1382, -1085], width: 50, height: 1240, traits: ColliderTraits.BlockMovementAndItem, angle: -0.52 },
			{ type: ColliderType.Box, position: [1982, 499], width: 333, height: 888, traits: ColliderTraits.BlockAll, angle: 0.44 },
			{ type: ColliderType.Box, position: [2236, 618], width: 50, height: 938, traits: ColliderTraits.BlockMovementAndItem, angle: 0.44 },
			{ type: ColliderType.Box, position: [685, 1456], width: 1163, height: 240, traits: ColliderTraits.BlockAll, angle: -0.17 },
			{ type: ColliderType.Box, position: [726, 1693], width: 1163, height: 50, traits: ColliderTraits.BlockMovementAndItem, angle: -0.17 },
			{ type: ColliderType.Box, position: [905, -1764], width: 973, height: 328, traits: ColliderTraits.BlockAll, angle: 0.58 },
			{ type: ColliderType.Box, position: [721, -1491], width: 973, height: 50, traits: ColliderTraits.BlockMovementAndItem, angle: 0.58 },
			{ type: ColliderType.Box, position: [-1639, -1257], width: 999, height: 246, traits: ColliderTraits.BlockAll, angle: -0.58 },
			{ type: ColliderType.Box, position: [-1511, -1059], width: 999, height: 50, traits: ColliderTraits.BlockMovementAndItem, angle: -0.58 },
			{ type: ColliderType.Box, position: [-1354, -1413], width: 343, height: 103, traits: ColliderTraits.BlockAll, angle: -0.58 },
			{ type: ColliderType.Box, position: [-2288, -291], width: 1173, height: 232, traits: ColliderTraits.BlockAll, angle: -0.98 },
			{ type: ColliderType.Box, position: [-2094, -163], width: 1173, height: 50, traits: ColliderTraits.BlockMovementAndItem, angle: -0.98 },
			{ type: ColliderType.Box, position: [-1889, -883], width: 200, height: 63, traits: ColliderTraits.BlockAll, angle: -0.98 },
			{ type: ColliderType.Box, position: [-2285, -289], width: 280, height: 1066, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-2063, 591], width: 1078, height: 258, traits: ColliderTraits.BlockAll, angle: 0.79 },
			{ type: ColliderType.Box, position: [-2247, 773], width: 1078, height: 50, traits: ColliderTraits.BlockMovementAndItem, angle: 0.79 },
			{ type: ColliderType.Box, position: [-1451, 1267], width: 811, height: 251, traits: ColliderTraits.BlockAll, angle: 0.26 },
			{ type: ColliderType.Box, position: [-1516, 1507], width: 811, height: 50, traits: ColliderTraits.BlockMovementAndItem, angle: 0.26 },
			{ type: ColliderType.Box, position: [-946, 1348], width: 500, height: 172, traits: ColliderTraits.BlockAll, angle: 0.26 },
		],
		rigged: false,
		visibilityRadius: 2500,
	},
	'boss-arena-animated': {
		visibilityRadius: 2500,
		zOffset: -Number.MAX_SAFE_INTEGER + 3000,
	},
	'boss-arena-glow-01': {
		visibilityRadius: 2500,
		zOffset: 3000,
	},
	'boss-arena-glow-02': {
		visibilityRadius: 2500,
		zOffset: 3000,
	},
	'boss-arena-glow-03': {
		visibilityRadius: 2500,
		zOffset: 3000,
	},
	'boss-arena-glow-04': {
		visibilityRadius: 2500,
		zOffset: 3000,
	},
	'boss-arena-glow-05': {
		visibilityRadius: 2500,
		zOffset: 3000,
	},
	'boss-arena-glow-06': {
		visibilityRadius: 2500,
		zOffset: 3000,
	},
	'boss-arena-glow-07': {
		visibilityRadius: 2500,
		zOffset: 3000,
	},
	'med-uncommon-glow': {
		dontLoadAsset: true,
	},
	'boss-arena-flag': {
		visibilityRadius: 2500,
		zOffset: 1800,
	},
	'boss-ground-01': {
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},
	'boss-ground-02': {
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},
	'boss-ground-03': {
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},
	'boss-ground-04': {
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},
	'boss-ground-05': {
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},
	'boss-08': {
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},
	'boss-09': {
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},
	'boss-10': {
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},
	'boss-14': {
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},
	'poi-02': {
		disableFileOrdering: true,
		colliders: [
			{ type: ColliderType.Ellipse, position: [-35, -260], rX: 286, rY: 108, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-666, 570], width: 556, height: 138, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [164, 587], width: 476, height: 118, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-1112, -361], width: 180, height: 931, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [835, -387], width: 218, height: 946, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-799, -652], width: 1608, height: 156, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-774, -321], width: 60, height: 32, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [445, 398], width: 60, height: 33, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-573, -320], width: 60, height: 32, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-397, -306], width: 60, height: 32, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-761, -158], width: 60, height: 35, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-573, -142], width: 60, height: 32, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-399, -131], width: 60, height: 32, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-760, 31], width: 60, height: 32, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-564, 38], width: 60, height: 32, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-387, 47], width: 60, height: 32, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-747, 218], width: 60, height: 32, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-576, 208], width: 60, height: 32, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-396, 204], width: 60, height: 32, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-753, 369], width: 60, height: 32, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-552, 372], width: 60, height: 32, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-383, 390], width: 60, height: 32, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [260, -305], width: 60, height: 32, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [451, -305], width: 60, height: 32, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [624, -310], width: 60, height: 32, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [257, -112], width: 60, height: 32, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [453, -102], width: 60, height: 32, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [624, -126], width: 60, height: 32, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [254, 49], width: 60, height: 32, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [453, 40], width: 60, height: 32, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [622, 40], width: 60, height: 32, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [262, 228], width: 60, height: 32, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [443, 222], width: 60, height: 32, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [628, 405], width: 60, height: 33, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [624, 218], width: 60, height: 32, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [260, 386], width: 60, height: 32, traits: ColliderTraits.BlockMovementAndItem },
		],
		rigged: false,
		visibilityRadius: 1330,
	},
	'poi-02-dirt-01': {
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},
	'poi-02-dirt-02': {
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},
	'poi-02-dirt-03': {
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},
	'poi-02-dirt-04': {
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},
	'poi-02-dirt-grave': {
		zOffset: -2000,
		dontLoadAsset: true,
	},
	'poi-02-a': {
		zOffset: -100,
		dontLoadAsset: true,
	},
	'poi-02-b': {
		zOffset: -900,
		dontLoadAsset: true,
	},
	'poi-02-c': {
		zOffset: -200,
		dontLoadAsset: true,
	},
	'poi-02-d': {
		zOffset: -850,
		dontLoadAsset: true,
	},
	'poi-02-e': {
		zOffset: -100,
		dontLoadAsset: true,
	},
	'poi-02-h': {
		zOffset: -100,
		dontLoadAsset: true,
	},
	'poi-02-i': {
		zOffset: -10,
		dontLoadAsset: true,
	},
	'poi-02-j': {
		zOffset: -10,
		dontLoadAsset: true,
	},
	'poi-02-k': {
		zOffset: -10,
		dontLoadAsset: true,
	},
	'poi-02-l': {
		zOffset: -10,
		dontLoadAsset: true,
	},
	'poi-02-m': {
		zOffset: -10,
		dontLoadAsset: true,
	},
	'poi-03-long-dead-dragon': {
		colliders: [
			{ type: ColliderType.Circle, position: [-16, -450], radius: 60, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [-695, 146], rX: 152, rY: 84, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [138, -446], width: 746, height: 162, traits: ColliderTraits.BlockAll, angle: degToRad(15) },
			{ type: ColliderType.Box, position: [228, 137], width: 821, height: 189, traits: ColliderTraits.BlockAll, angle: degToRad(23) },
			{ type: ColliderType.Circle, position: [-336, -411], radius: 128, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [867, 243], radius: 65, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [-907, -66], radius: 50, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [-355, 422], radius: 50, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [1,-2], radius: 24, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Circle, position: [-234,-344], radius: 50, traits: ColliderTraits.BlockAll,},
			{ type: ColliderType.Ellipse, position: [-623,-261], rX: 127, rY: 99, traits: ColliderTraits.BlockAll,},
		],
		zOffset: -275,
		rigged: false,
		visibilityRadius: 1200,
	},
	'sand-01': {
        dontLoadAsset: true,
        zOffset: -Number.MAX_SAFE_INTEGER,
    },
    'sand-02': {
        dontLoadAsset: true,
        zOffset: -Number.MAX_SAFE_INTEGER,
    },
    'sand-03': {
        dontLoadAsset: true,
        zOffset: -Number.MAX_SAFE_INTEGER,
    },
    'poi-ground-03': {
        dontLoadAsset: true,
        zOffset: -Number.MAX_SAFE_INTEGER,    
    },
	'poi-ground-06': {
        dontLoadAsset: true,
        zOffset: -Number.MAX_SAFE_INTEGER,    
    },
	'poi-03-claw':{
		dontLoadAsset: true,
        zOffset: 100 
	},
	'poi-03-head-spike':{
		dontLoadAsset: true,
        zOffset: 250 
	},
	'poi-01-k':{
		dontLoadAsset: true,
        zOffset: 100 
	},
	'poi-01-d':{
		dontLoadAsset: true,
        zOffset: 75 
	},
	'poi-03-spine':{
		dontLoadAsset: true,
        zOffset: 200 
	},
	'poi-03-tail-01':{
		dontLoadAsset: true,
        zOffset: 200 
	},
	'poi-03-tail-02':{
		dontLoadAsset: true,
        zOffset: 200 
	},

	'outpost-base': {
		disableFileOrdering: true,
		colliders: [
			{ type: ColliderType.Box, position: [-1015, -535], width: 70, height: 1035, traits: ColliderTraits.BlockAll }, // west wall
			{ type: ColliderType.Box, position: [410, -535], width: 70, height: 1005, traits: ColliderTraits.BlockAll }, // east wall
			{ type: ColliderType.Box, position: [-1015, -535], width: 1475, height: 80, traits: ColliderTraits.BlockAll }, // north wall
			{ type: ColliderType.Box, position: [-1015, 400], width: 225, height: 100, traits: ColliderTraits.BlockAll }, // south-west wall
			{ type: ColliderType.Box, position: [50, 370], width: 410, height: 100, traits: ColliderTraits.BlockAll }, // south-east wall
			{ type: ColliderType.Box, position: [-358, -299], width: 600, height: 200, traits: ColliderTraits.BlockAll }, // main building top
			{ type: ColliderType.Box, position: [-386, -100], width: 800, height: 150, traits: ColliderTraits.BlockAll }, // main building bottom
			{ type: ColliderType.Box, position: [-914, 157], width: 300, height: 200, traits: ColliderTraits.BlockMovementAndItem }, // campfire
			{ type: ColliderType.Box, position: [-846, -171], width: 160, height: 75, traits: ColliderTraits.BlockAll }, // worm sign
			{ type: ColliderType.Circle, position: [285, -320], radius: 40, traits: ColliderTraits.BlockAll }, // tree 1
			{ type: ColliderType.Circle, position: [360, -260], radius: 35, traits: ColliderTraits.BlockAll }, // tree 2
			{ type: ColliderType.Box, position: [-782, 473], width: 90, height: 60, traits: ColliderTraits.BlockAll }, // left lantern
			{ type: ColliderType.Box, position: [-42, 462], width: 90, height: 60, traits: ColliderTraits.BlockAll }, // right lantern
			{ type: ColliderType.Circle, position: [-393, -588], radius: 32, traits: ColliderTraits.BlockAll }, // tree north
			{ type: ColliderType.Circle, position: [461, -567], radius: 32, traits: ColliderTraits.BlockAll }, // tree northeast
			{ type: ColliderType.Ellipse, position: [-1013, -559], rX: 243, rY: 160, traits: ColliderTraits.BlockAll }, // rocks northwest
			{ type: ColliderType.Circle, position: [-1151, -278], radius: 75, traits: ColliderTraits.BlockAll }, // rocks wnw 1
			{ type: ColliderType.Circle, position: [-1255, -332], radius: 40, traits: ColliderTraits.BlockAll }, // rocks wnw 2
			{ type: ColliderType.Ellipse, position: [547, -503], rX: 52, rY: 40, traits: ColliderTraits.BlockAll }, // rocks NE
			{ type: ColliderType.Ellipse, position: [709, -109], rX: 52, rY: 40, traits: ColliderTraits.BlockAll }, // rocks E 1
			{ type: ColliderType.Circle, position: [610, -44], radius: 35, traits: ColliderTraits.BlockAll }, // rocks E 2
			{ type: ColliderType.Ellipse, position: [713, 15], rX: 52, rY: 40, traits: ColliderTraits.BlockAll }, // rocks E 3
			{ type: ColliderType.Ellipse, position: [545, 96], rX: 52, rY: 40, traits: ColliderTraits.BlockAll }, // rocks E 4
			{ type: ColliderType.Circle, position: [458, 518], radius: 165, traits: ColliderTraits.BlockAll }, // rocks SE
			{ type: ColliderType.Circle, position: [303, 638], radius: 32, traits: ColliderTraits.BlockAll }, // rocks SE small
			{ type: ColliderType.Circle, position: [683, 568], radius: 32, traits: ColliderTraits.BlockAll }, // Sword-hand SE
			{ type: ColliderType.Circle, position: [-879, 626], radius: 36, traits: ColliderTraits.BlockAll }, // Tree SW
			{ type: ColliderType.Circle, position: [-1068, 570], radius: 36, traits: ColliderTraits.BlockAll }, // Sword-hand SE
			{ type: ColliderType.Circle, position: [-1122, 684], radius: 36, traits: ColliderTraits.BlockAll }, // Sword-hand SE
		],
		rigged: false,
		visibilityRadius: 1400,
		zOffset: -25,
	},
	'outpost-lanterns': {
		rigged: true,
		visibilityRadius: 1400,
		zOffset: 500,
	},
	'health-sign': {
		zOffset: -10,
		dontLoadAsset: true,
	},
	'outpost-campfire': {
		rigged: true,
		visibilityRadius: 1400,
		zOffset: 500,
	},
	'grass-01': {
		dontLoadAsset: true,
		zOffset: -5000,
	},
	'grass-02': {
		dontLoadAsset: true,
		zOffset: -5000,
	},
	'grass-2': {
		dontLoadAsset: true,
		zOffset: -5000,
	},
	'outpost-bubba': {
		rigged: true,
		visibilityRadius: 1400,
		zOffset: 100,
	},
	'outpost-sign': {
		rigged: true,
		visibilityRadius: 1400,
		zOffset: 1000,
	},
	'outpost-bottom-rock-glow': {
		rigged: true,
		visibilityRadius: 1400,
		zOffset: 100,
	},
	'outpost-top-rock-glow': {
		rigged: true,
		visibilityRadius: 1400,
		zOffset: 100,
	},

	// START blocker pieces //
	'low-blocker-01': {
		colliders: [{ type: ColliderType.Ellipse, position: [0, 0], rX: 150, rY: 80, traits: ColliderTraits.BlockMovementAndItem }],
		dontLoadAsset: true,
		visibilityRadius: 150,
		zOffset: -150,
	},
	'low-blocker-02': {
		colliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 90, traits: ColliderTraits.BlockMovementAndItem }],
		dontLoadAsset: true,
		visibilityRadius: 125,
		zOffset: -150,
	},
	'low-blocker-03': {
		colliders: [{ type: ColliderType.Ellipse, position: [0, 0], rX: 150, rY: 100, traits: ColliderTraits.BlockMovementAndItem }],
		dontLoadAsset: true,
		visibilityRadius: 150,
		zOffset: -150,
	},
	'mid-blocker-01': {
		colliders: [{ type: ColliderType.Box, angle: degToRad(30), position: [52, -34], height: 150, width: 130, traits: ColliderTraits.BlockAll }],
		dontLoadAsset: true,
		visibilityRadius: 170,
		zOffset: -150,
	},
	'mid-blocker-02': {
		zOffset: -150,
		colliders: [{ type: ColliderType.Box, position: [10, -100], height: 250, width: 130, traits: ColliderTraits.BlockAll }],
		dontLoadAsset: true,
		visibilityRadius: 170,
	},
	'mid-blocker-03': {
		zOffset: -100,
		colliders: [{ type: ColliderType.Box, angle: degToRad(-30), position: [-50, -30], height: 150, width: 130, traits: ColliderTraits.BlockAll }],
		dontLoadAsset: true,
		visibilityRadius: -150,
	},
	'high-blocker-01': {
		colliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 72, traits: ColliderTraits.BlockAll }],
		dontLoadAsset: true,
		visibilityRadius: 110,
	},
	'high-blocker-02': {
		colliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 60, traits: ColliderTraits.BlockAll }],
		dontLoadAsset: true,
		visibilityRadius: 110,
	},
	// END blocker pieces //
	'high-blocker-01-var01': makeBlockPropConfig(),
	'high-blocker-01-var02': makeBlockPropConfig(),
	'high-blocker-01-var03': makeBlockPropConfig(),
	'high-blocker-01-var04': makeBlockPropConfig(),
	'mid-blocker-01-var01': makeBlockPropConfig(),
	'mid-blocker-01-var02': makeBlockPropConfig(),
	'mid-blocker-01-var03': makeBlockPropConfig(),
	'mid-blocker-01-var04': makeBlockPropConfig(),
	'mid-blocker-01-var05': makeBlockPropConfig(),
	'mid-blocker-01-var06': makeBlockPropConfig(),
	'low-blocker-01-var01': makeBlockPropConfig(),
	'low-blocker-01-var02': makeBlockPropConfig(),
	'low-blocker-01-var03': makeBlockPropConfig(),
	'low-blocker-01-var04': makeBlockPropConfig(),
	'low-blocker-01-var05': makeBlockPropConfig(),
	'low-blocker-01-var06': makeBlockPropConfig(),
	'small-common-01': {
		visibilityRadius: 60,
		zOffset: -60,
	},
	'small-common-02': {
		visibilityRadius: 75,
		zOffset: -60,
	},
	'small-common-03': {
		visibilityRadius: 50,
		zOffset: -60,
	},

	'small-uncommon-01': {
		colliders: [{ type: ColliderType.Circle, position: [45, -20], radius: 70, traits: ColliderTraits.BlockMovementAndItem }],
		zOffset: 100,
		visibilityRadius: 170,
	},
	'small-uncommon-02': {
		colliders: [{ type: ColliderType.Circle, position: [79, -5], radius: 75, traits: ColliderTraits.BlockMovementAndItem }],
		visibilityRadius: 160,
		zOffset: 200,
	},
	'small-uncommon-03': {
		colliders: [{ type: ColliderType.Circle, position: [65, -4], radius: 93, traits: ColliderTraits.BlockMovementAndItem }],
		visibilityRadius: 180,
	},
	'small-uncommon-04': {
		visibilityRadius: 155,
		zOffset: -60,
	},

	'small-rare-01': {
		visibilityRadius: 250,
	},
	'small-rare-02': {
		colliders: [{ type: ColliderType.Circle, position: [72, -34], radius: 88, traits: ColliderTraits.BlockAll }],
		zOffset: -60,
		visibilityRadius: 180,
	},
	'small-rare-03': {
		colliders: [{ type: ColliderType.Circle, position: [50, -30], radius: 32, traits: ColliderTraits.BlockAll }],
		visibilityRadius: 130,
	},

	'med-common-01': {
		colliders: [{ type: ColliderType.Circle, position: [55, 17], radius: 48, traits: ColliderTraits.BlockAll }],
		visibilityRadius: 385,
		zOffset: 200,
	},
	'med-common-02': {
		colliders: [{ type: ColliderType.Circle, position: [59, -23], radius: 35, traits: ColliderTraits.BlockMovementAndItem }],
		visibilityRadius: 330,
		zOffset: 200,
	},
	'med-common-03': {
		colliders: [{ type: ColliderType.Circle, position: [75, -19], radius: 40, traits: ColliderTraits.BlockAll }],
		visibilityRadius: 335,
		zOffset: 200,
	},
	'med-common-04': {
		colliders: [{ type: ColliderType.Circle, position: [8, -109], radius: 45, traits: ColliderTraits.BlockAll }],
		visibilityRadius: 375,
	},

	'med-uncommon-01': {
		colliders: [{ type: ColliderType.Ellipse, position: [-15, -93], rX: 130, rY: 75, traits: ColliderTraits.BlockAll }],
		visibilityRadius: 400,
	},
	'med-uncommon-02': {
		colliders: [{ type: ColliderType.Ellipse, position: [-16, -52], rX: 191, rY: 88, traits: ColliderTraits.BlockAll }],
		visibilityRadius: 435,
		
	},
	'med-uncommon-03': {
		colliders: [{ type: ColliderType.Ellipse, position: [4, -4], rX: 145, rY: 55, traits: ColliderTraits.BlockAll }],
		visibilityRadius: 480,
	},
	'large-uncommon-01-var01': {
		colliders: [
			...largeUncommonColliders,
			{ type: ColliderType.Circle, position: [368, 32], radius: 26, traits: ColliderTraits.BlockMovementAndItem }, // Rock E
		],
		zeroHeight: ['large-uncommon-01'],
		visibilityRadius: 520,
	},
	'large-uncommon-01-var02': {
		colliders: [
			...largeUncommonColliders,
			{ type: ColliderType.Circle, position: [256, 157], radius: 32, traits: ColliderTraits.BlockAll }, // Tree SE 1
			{ type: ColliderType.Circle, position: [346, 179], radius: 32, traits: ColliderTraits.BlockAll }, // Tree SE 2
			{ type: ColliderType.Circle, position: [368, 32], radius: 26, traits: ColliderTraits.BlockMovementAndItem }, // Rock E
		],
		zeroHeight: ['large-uncommon-01'],
		visibilityRadius: 520,
	},
	'large-uncommon-01-var03': {
		colliders: [
			...largeUncommonColliders,
			{ type: ColliderType.Circle, position: [147, -64], radius: 32, traits: ColliderTraits.BlockAll }, // Tree NE
			{ type: ColliderType.Circle, position: [-322, -8], radius: 32, traits: ColliderTraits.BlockAll }, // Tree NW
			{ type: ColliderType.Circle, position: [368, 32], radius: 26, traits: ColliderTraits.BlockMovementAndItem }, // Rock E
		],
		zeroHeight: ['large-uncommon-01'],
		visibilityRadius: 520,
	},
	'large-rare-01-var01': {
		colliders: [
			{ type: ColliderType.Ellipse, position: [-41, 57], rX: 724, rY: 548, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [115, 543], rX: 263, rY: 120, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-595, 286], rX: 199, rY: 62, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-652, -67], rX: 249, rY: 388, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [-483, -369], rX: 319, rY: 139, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [111, -539], rX: 363, rY: 69, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [652, -199], rX: 107, rY: 101, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [670, 65], rX: 153, rY: 183, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [378, -485], rX: 273, rY: 102, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-212, -484], rX: 124, rY: 74, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [593, -332], radius: 132, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [-291, 496], radius: 63, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [-736, 151], radius: 59, traits: ColliderTraits.BlockAll },
		],
		zOffset: -600,
		visibilityRadius: 660,
	},
	'large-rare-01-var02': {
		colliders: [
			{ type: ColliderType.Ellipse, position: [1, -200], rX: 357, rY: 257, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [154, 15], rX: 197, rY: 272, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [257, 246], rX: 271, rY: 124, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [-354, 248], rX: 195, rY: 95, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [-417, -37], radius: 30, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [-133, 126], radius: 34, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [-417, -37], radius: 30, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [-368, 117], rX: 112, rY: 104, traits: ColliderTraits.BlockAll },
		],
		visibilityRadius: 615,
	},
	'large-rare-01-var03': {
		colliders: [
			{ type: ColliderType.Ellipse, position: [440, 274], rX: 265, rY: 151, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [345, 141], rX: 206, rY: 248, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [4, -214], rX: 331, rY: 264, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [109, 258], rX: 249, rY: 237, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-596, 105], width: 823, height: 117, angle: 0.42, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [-288, 61], rX: 249, rY: 237, traits: ColliderTraits.BlockAll },
		],
		visibilityRadius: 570,
	},
	'large-rare-02-var01': {
		colliders: [
			{ type: ColliderType.Ellipse, position: [9, -13], rX: 445, rY: 269, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [179, 207], radius: 77, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [281, 174], radius: 74, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [391, 107], radius: 72, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [-235, 119], radius: 131, traits: ColliderTraits.BlockAll },
		],
		visibilityRadius: 565,
	},
	'large-rare-02-var02': {
		colliders: [
			{ type: ColliderType.Ellipse, position: [-27, -41], rX: 248, rY: 260, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Circle, position: [120, 91], radius: 185, traits: ColliderTraits.BlockAll },
		],
		zOffset: -300,
		visibilityRadius: 545,
	},
	'large-rare-02-var03': {
		colliders: [
			{ type: ColliderType.Ellipse, position: [-283, 92], rX: 128, rY: 87, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [37, 59], rX: 427, rY: 122, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [355, 76], rX: 138, rY: 105, traits: ColliderTraits.BlockAll },
		],
		visibilityRadius: 390,
	},
	outpost: {
		zOffset: -150,
		dontLoadAsset: true,
	},
	'outpost-window': {
		zOffset: -5000,
		dontLoadAsset: true,
	},
	ground_patch: {
		zOffset: -50000,
		dontLoadAsset: true,
	},
	worm_sign: {
		zOffset: -25,
		dontLoadAsset: true,
	},
	campfire: {
		zOffset: -25,
		dontLoadAsset: true,
	},
	'highlands-alter': {
		overrideAtlas: 'highlands-alter',
		colliders: [
			{ type: ColliderType.Ellipse, position: [-18,-315], rX: 95, rY: 99, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [-181,-274], rX: 72, rY: 79, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [129,-276], rX: 72, rY: 79, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [-276,-178], rX: 51, rY: 48, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [260,-183], rX: 51, rY: 56, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Ellipse, position: [-249,-50], rX: 24, rY: 21, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [224,-34], rX: 24, rY: 21, traits: ColliderTraits.BlockMovementAndItem },
		],
		rigged: false,
		zOffset: -350,
		visibilityRadius: 1400,
	},
	'party-alter-animated': {
		overrideAtlas: 'highlands-alter',
		zOffset: -200,
		visibilityRadius: 1400,
	},

}
