import { Container, Sprite } from 'pixi.js'
import { degToRad } from '../../utils/math'
import Renderer from '../../engine/client/graphics/renderer'

const ARROW_TO_PLAYER_X_DIST = 400 // the default distance from the player to the arrow
const ARROW_SHRINK_DIST = 100 // the distance the arrow has to be from the target before the X dist starts shrinking

const HALF_ARROW_PLAYER_X_DIST = ARROW_TO_PLAYER_X_DIST / 2
const ARROW_TO_PLAYER_Y_OFFSET = -75 // moves the arrow up so if it's pointing right, the arrow is the same height as the player

export default class NavigationArrow {
	static getInstance() {
		return NavigationArrow.instance
	}

	static createInstance() {
		NavigationArrow.instance = new NavigationArrow()
	}

	parentContainer: Container
	arrowSprite: Sprite

	destinationX = 3859
	destinationY = 5644

	isShowing = false
	private static instance: NavigationArrow

	private constructor() {
		this.parentContainer = new Container()
		this.arrowSprite = Sprite.from('navigation_arrow')

		this.arrowSprite.width = 68
		this.arrowSprite.height = 73

		this.arrowSprite.anchor.x = 0.5
		this.arrowSprite.anchor.y = 0.5

		this.parentContainer.addChild(this.arrowSprite)
		this.arrowSprite.x = ARROW_TO_PLAYER_X_DIST
		this.arrowSprite.rotation = degToRad(90)

		if (!this.isShowing) {
			this.parentContainer.visible = false
		}

		Renderer.getInstance().fgRenderer.addChild(this.parentContainer)
	}

	setXPos(newVal) {
		this.parentContainer.x = newVal

		if (this.isShowing) {
			this.updateRotation()
		}
	}

	setYPos(newVal) {
		this.parentContainer.y = newVal + ARROW_TO_PLAYER_Y_OFFSET

		if (this.isShowing) {
			this.updateRotation()
		}
	}

	setDestination(x, y) {
		this.destinationX = x
		this.destinationY = y

		if (!this.isShowing) {
			this.setIsShowing(true)
		} else {
			this.updateRotation()
		}
	}

	updateRotation() {
		const yDiff = this.destinationY - this.parentContainer.y
		const xDiff = this.destinationX - this.parentContainer.x

		const angle = Math.atan2(yDiff, xDiff)

		this.parentContainer.rotation = angle

		const distToTarget = Math.sqrt(xDiff * xDiff + yDiff * yDiff)
		const newArrowDist = Math.min(distToTarget - ARROW_SHRINK_DIST, ARROW_TO_PLAYER_X_DIST)
		this.parentContainer.alpha = Math.clamp((distToTarget - HALF_ARROW_PLAYER_X_DIST) / ARROW_TO_PLAYER_X_DIST, 0, 1)

		this.arrowSprite.x = newArrowDist
	}

	setIsShowing(isShowing) {
		if (!this.isShowing && isShowing) {
			this.updateRotation()
		}

		this.isShowing = isShowing
		this.parentContainer.visible = isShowing
	}
}
