























import { mapMutations } from 'vuex'
import moment from 'moment'

export default {
	name: 'BuffsAndDebuffsItem',
	props: {
		item: {
			type: Object,
			required: true,
		},
		tooltipable: {
			type: Boolean,
			required: false,
			default: true,
		},
		appliedAtTime: {
			type: Number,
			required: false,
			default: 0,
		},
		expiresAtTime: {
			type: Number,
			required: false,
			default: 0,
		},
		duration: {
			type: Number,
			default: 0,
			required: false,
		},
		stacks: {
			type: Number,
			default: 1,
			required: false,
		},
		statusEffectType: {
			type: String,
			default: 'Default String',
			required: false,
		},
		tooltip: {
			type: String,
			default: 'Default String',
			required: false,
		},
	},
	data() {
		return {
			progressbar: 100,
			counter: null,
			durationText: '',
		}
	},
	beforeDestroy() {
		if (this.counter) {
			clearInterval(this.counter)
		}
	},
	computed: {
		slotVariantClass() {
			return `slot-variant-${Math.floor(Math.random() * 5) + 1}`
		},

		progressbarStyle() {
			return 'width: ' + this.progressbar + '%;'
		},
	},
	mounted() {
		this.counter = setInterval(() => {
			const now = Date.now()
			this.progressbar = Math.clamp(Math.lerp(100, 0, (now - this.appliedAtTime) / (this.expiresAtTime - this.appliedAtTime)), 0, 100)
			// console.log(`PROC   applied: ${this.appliedAtTime}, expires: ${this.expiresAtTime}, now: ${now}, progress: ${this.progressbar}`)

			const totalDuration = (this.expiresAtTime - this.appliedAtTime)
			const durationMs = moment.duration(this.progressbar * totalDuration / 100)
			if (durationMs.asHours() >= 73) {
				this.durationText = ``
			} else if (durationMs.asHours() >= 3) {
				this.durationText = `${Math.round(durationMs.asHours())}h`
			} else if (durationMs.asHours() >= 1) {
				this.durationText = `${durationMs.hours()}h ${durationMs.minutes()}m`
			} else if (durationMs.asMinutes() >= 2) {
				this.durationText = `${durationMs.minutes()}m`
			} else {
				this.durationText = `${Math.round(durationMs.asSeconds())}s`
			}
		}, 50)
	},
	methods: {
		...mapMutations('UIScale', ['showTooltip']),
		...mapMutations('buffAndDebuff', ['removeStatusEffect']),

		onMouseEnter() {
			if (this.tooltipable === false) return
			this.showTooltip({ name: this.item.name, tooltip: this.item.effect, simpleTooltip: true })
		},
		onMouseLeave() {
			if (this.tooltipable === false) return
			this.showTooltip(null)
		},
		onMouseMove(event) {
			if (this.tooltipable === false) return
			this.moveTooltip(event)
		},
	},
	destroyed() {
		this.showTooltip(null)
	},
}
