import nengi from 'nengi'
import { CooldownSlot } from '../../engine/shared/game-data/gear-skill-config'
import { StatType } from '../../engine/shared/game-data/stat-type-mod-category'

export default class PlayerGearStatChangeMessage {
	static protocol = {
		statType: { type: nengi.UInt16 },
		value: { type: nengi.Float32 },
		slot: { type: nengi.UInt4 },
	}

	statType: StatType
	value: number
	slot: CooldownSlot

	constructor(param: PlayerGearStatChangeMessage) {
		this.statType = param.statType
		this.value = param.value
		this.slot = param.slot
	}
}
