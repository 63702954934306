import { ensureEnumUnique } from '../../../utils/debug'
import WeaponSubType from '../../../loot/shared/weapon-sub-type'
import logger from '../../../utils/client-logger'
import { ItemRarity } from '../../../loot/shared/item-rarity'

const NUM_VISUAL_VARIANTS = 2

/** An enum of all the stats in the game */
enum StatType {
	NONE = 0,
	HP = 1,
	MAX_HP = 2,
	PHYSICAL_DAMAGE = 400,
	FIRE_DAMAGE = 401,
	ICE_DAMAGE = 402,
	LIGHTNING_DAMAGE = 403,
	POISON_DAMAGE = 404,

	ATTACK_RATE = 5,

	CRIT_CHANCE = 6,
	CRIT_DAMAGE = 7,

	PROJECTILE_SPEED = 8,
	PROJECTILE_COUNT = 9,
	PROJECTILE_DURATION = 10,
	PROJECTILE_MAX_RANGE = 49,
	PROJECTILE_SPREAD_ANGLE = 11,
	PROJECTILE_SPREAD_VARIANCE = 38,

	PROJECTILE_BEAM_WIDTH = 39,
	PROJECTILE_BEAM_LENGTH = 40,

	PROJECTILE_POINT_BLANK_DAMAGE = 12,
	PROJECTILE_FAR_SHOT_DAMAGE = 13,

	PROJECTILE_PIERCE_COUNT = 14,
	PROJECTILE_CHAIN_COUNT = 15,
	PROJECTILE_SPLASH_RADIUS = 16,
	PROJECTILE_SPLASH_DAMAGE = 37,
	PROJECTILE_POOL_RADIUS = 17,
	PROJECTILE_KNOCKBACK = 18,
	PROJECTILE_RICOCHET_COUNT = 19,

	COOLDOWN_SPEED = 20,
	DEBUFF_POTENCY = 21,
	HEALTH_REGEN = 22,
	DEFENSE = 23,
	FIRE_RESIST = 24,
	ICE_RESIST = 25,
	LIGHTNING_RESIST = 26,
	POISON_RESIST = 27,
	MOVEMENT_SPEED = 28,
	BUFF_DURATION = 29,
	SKILL_USAGE_SPEED = 30,

	PROJECTILE_SIZE = 31,
	ALL_DAMAGE = 32,

	HEALTH_REGEN_DELAY = 33,
	SHIELD_REGEN_RATE = 34,
	SHIELD_REGEN_DELAY = 35,
	MAX_SHIELDS = 36,
	ENERGY_REGEN = 41,
	PRIMARY_FIRE_ENERGY_COST = 42,
	MAX_ENERGY = 43,
	SKILL_SPEED = 44,
	COOLDOWN_DURATION = 45,
	SKILL_DURATION = 46,
	CHARGE_RATE = 47,

	PROJECTILE_DAMAGE = 48,

	HEALING_RECEIVED = 50,

	CHARGED_WEAPON_DAMAGE = 51,
	BEAM_RAMP_UP_DAMAGE = 52,

	IGNITE_CHANCE = 100,
	CHILL_CHANCE = 101,
	SHOCK_CHANCE = 102,
	POISON_CHANCE = 103,
	BLEED_CHANCE = 104,

	IGNITE_POTENCY = 200,
	CHILL_POTENCY = 201,
	SHOCK_POTENCY = 202,
	POISON_POTENCY = 203,
	BLEED_POTENCY = 204,

	ALL_AILMENT_POTENCY = 250,
	DAMAGING_AILMENT_POTENCY = 251,
	STATUS_AILMENT_POTENCY = 252,

	DAMAGE_TAKEN = 305,
	DAMAGE_TAKEN_FROM_PHYSICAL = 300,
	DAMAGE_TAKEN_FROM_FIRE = 301,
	DAMAGE_TAKEN_FROM_ICE = 302,
	DAMAGE_TAKEN_FROM_LIGHTNING = 303,
	DAMAGE_TAKEN_FROM_POISON = 304,

	LOOT_DROP_QUANTITY = 600,
	HEART_DROP_RATE = 601,
	LOOT_DROP_RARITY = 602,
	COIN_DROP_QUANTITY = 603,

	SKILL_DODGE_ROLL = 1000,
	SKILL_MOVEMENT_SPEED_BOOST = 1001,
	SKILL_ATTACK_SPEED_BOOST = 1002,
	SKILL_TUMBLE_ROLL = 1005,
	SKILL_OVERSHIELD = 1007,
	SKILL_STONE_FORM = 1009,
	SKILL_OVERCHARGED_SHOT = 1010,
	SKILL_BATTLE_CRY = 1011,
	SKILL_SICKENING_NOVA = 1012,
	SKILL_BULWARK = 1014,
	SKILL_GRAVITY_WELL = 1015,
	SKILL_BATTERY = 1016,

	PROJECTILE_TRAJECTORY_RADIUS = 20001,
	PROJECTILE_TRAJECTORY_AMPLITUDE = 20004,
	PROJECTILE_TRAJECTORY_PERIOD = 20005,
	PROJECTILE_TRAJECTORY_VALUE = 20008,
	PROJECTILE_TRAJECTORY_RANGE = 20009,

	PROJECTILE_TRAJECTORY_VALUE_MIN = 20100,
	PROJECTILE_TRAJECTORY_AMPLITUDE_MIN = 20101,
	PROJECTILE_TRAJECTORY_PERIOD_MIN = 20102,
	PROJECTILE_TRAJECTORY_RADIUS_MIN = 20103,
	PROJECTILE_TRAJECTORY_RANGE_MIN = 20104,

	PROJECTILE_TRAJECTORY_VALUE_MAX = 20200,
	PROJECTILE_TRAJECTORY_AMPLITUDE_MAX = 20201,
	PROJECTILE_TRAJECTORY_PERIOD_MAX = 20202,
	PROJECTILE_TRAJECTORY_RADIUS_MAX = 20203,
	PROJECTILE_TRAJECTORY_RANGE_MAX = 20204,
}
ensureEnumUnique(StatType, logger)

/** Stats where lower values (closer to zero) are better, from most players' perspective. */
export const StatTypesWithInvertedValues = [
	StatType.PROJECTILE_SPREAD_ANGLE,
	StatType.PROJECTILE_SPREAD_VARIANCE,
	StatType.SHIELD_REGEN_DELAY,
	StatType.SHIELD_REGEN_RATE,
	StatType.PRIMARY_FIRE_ENERGY_COST,
]

const AliasStatTypeToPropNames = {
	[StatType.ALL_AILMENT_POTENCY]: 'allAilmentPotency',
	[StatType.DAMAGING_AILMENT_POTENCY]: 'damagingAilmentPotency',
	[StatType.STATUS_AILMENT_POTENCY]: 'statusAilmentPotency',
} as const

const NormalStatTypeToPropName = {
	[StatType.NONE]: 'none',
	[StatType.HP]: 'health',
	[StatType.MAX_HP]: 'maxHealth',
	[StatType.PHYSICAL_DAMAGE]: 'physicalDamage',
	[StatType.FIRE_DAMAGE]: 'fireDamage',
	[StatType.ICE_DAMAGE]: 'iceDamage',
	[StatType.LIGHTNING_DAMAGE]: 'lightningDamage',
	[StatType.POISON_DAMAGE]: 'poisonDamage',
	[StatType.ATTACK_RATE]: 'attackRate',
	[StatType.CRIT_CHANCE]: 'critChance',
	[StatType.CRIT_DAMAGE]: 'critDamage',
	[StatType.COOLDOWN_SPEED]: 'cooldownSpeed',
	[StatType.DEBUFF_POTENCY]: 'debuffPotency',
	[StatType.HEALTH_REGEN]: 'healthRegen',
	[StatType.HEALTH_REGEN_DELAY]: 'healthRegenDelay',
	[StatType.MAX_SHIELDS]: 'maxShields',
	[StatType.SHIELD_REGEN_RATE]: 'shieldRegenRate',
	[StatType.SHIELD_REGEN_DELAY]: 'shieldRegenDelay',
	[StatType.MAX_ENERGY]: 'maxEnergy',
	[StatType.ENERGY_REGEN]: 'energyRegen',
	[StatType.PRIMARY_FIRE_ENERGY_COST]: 'primaryFireEnergyCost',
	[StatType.DEFENSE]: 'defense',
	[StatType.FIRE_RESIST]: 'fireResist',
	[StatType.ICE_RESIST]: 'iceResist',
	[StatType.LIGHTNING_RESIST]: 'lightningResist',
	[StatType.POISON_RESIST]: 'poisonResist',
	[StatType.DAMAGE_TAKEN]: 'damageTaken',
	[StatType.DAMAGE_TAKEN_FROM_PHYSICAL]: 'damageTakenFromPhysical',
	[StatType.DAMAGE_TAKEN_FROM_FIRE]: 'damageTakenFromFire',
	[StatType.DAMAGE_TAKEN_FROM_ICE]: 'damageTakenFromIce',
	[StatType.DAMAGE_TAKEN_FROM_LIGHTNING]: 'damageTakenFromLightning',
	[StatType.DAMAGE_TAKEN_FROM_POISON]: 'damageTakenFromPoison',
	[StatType.HEALING_RECEIVED]: 'healingReceived',
	[StatType.MOVEMENT_SPEED]: 'movementSpeed',
	[StatType.BUFF_DURATION]: 'buffDuration',
	[StatType.SKILL_USAGE_SPEED]: 'skillUsageSpeed',
	[StatType.SKILL_SPEED]: 'skillSpeed',
	[StatType.SKILL_DURATION]: 'skillDuration',
	[StatType.COOLDOWN_DURATION]: 'cooldownDuration',
	[StatType.PROJECTILE_SPEED]: 'projectileSpeed',
	[StatType.PROJECTILE_COUNT]: 'projectileCount',
	[StatType.PROJECTILE_SIZE]: 'projectileSize',
	[StatType.PROJECTILE_DURATION]: 'projectileDuration',
	[StatType.PROJECTILE_MAX_RANGE]: 'projectileMaxRange',
	[StatType.PROJECTILE_SPREAD_ANGLE]: 'projectileSpreadAngle',
	[StatType.PROJECTILE_SPREAD_VARIANCE]: 'projectileSpreadVariance',
	[StatType.PROJECTILE_BEAM_WIDTH]: 'projectileBeamWidth',
	[StatType.PROJECTILE_BEAM_LENGTH]: 'projectileBeamLength',
	[StatType.PROJECTILE_POINT_BLANK_DAMAGE]: 'projectilePointBlankDamage',
	[StatType.PROJECTILE_FAR_SHOT_DAMAGE]: 'projectileFarShotDamage',
	[StatType.PROJECTILE_PIERCE_COUNT]: 'projectilePierceCount',
	[StatType.PROJECTILE_CHAIN_COUNT]: 'projectileChainCount',
	[StatType.PROJECTILE_SPLASH_RADIUS]: 'projectileSplashRadius',
	[StatType.PROJECTILE_SPLASH_DAMAGE]: 'projectileSplashDamage',
	[StatType.PROJECTILE_POOL_RADIUS]: 'projectilePoolRadius',
	[StatType.PROJECTILE_KNOCKBACK]: 'projectileKnockback',
	[StatType.PROJECTILE_RICOCHET_COUNT]: 'projectileRicochetCount',
	[StatType.ALL_DAMAGE]: 'allDamage',
	[StatType.CHARGE_RATE]: 'chargeRate',
	[StatType.PROJECTILE_DAMAGE]: 'projectileDamage',
	[StatType.CHARGED_WEAPON_DAMAGE]: 'chargedWeaponDamage',
	[StatType.BEAM_RAMP_UP_DAMAGE]: 'beamRampUpDamage',

	[StatType.IGNITE_CHANCE]: 'igniteChance',
	[StatType.CHILL_CHANCE]: 'chillChance',
	[StatType.SHOCK_CHANCE]: 'shockChance',
	[StatType.POISON_CHANCE]: 'poisonChance',
	[StatType.BLEED_CHANCE]: 'bleedChance',

	[StatType.IGNITE_POTENCY]: 'ignitePotency',
	[StatType.CHILL_POTENCY]: 'chillPotency',
	[StatType.SHOCK_POTENCY]: 'shockPotency',
	[StatType.POISON_POTENCY]: 'poisonPotency',
	[StatType.BLEED_POTENCY]: 'bleedPotency',

	[StatType.SKILL_DODGE_ROLL]: 'skillDodgeRoll',
	[StatType.SKILL_MOVEMENT_SPEED_BOOST]: 'skillMovementSpeed',
	[StatType.SKILL_ATTACK_SPEED_BOOST]: 'skillAttackSpeedBoost',
	[StatType.SKILL_TUMBLE_ROLL]: 'skillTumbleRoll',
	[StatType.SKILL_OVERSHIELD]: 'skillOvershield',
	[StatType.SKILL_STONE_FORM]: 'skillStoneForm',
	[StatType.SKILL_OVERCHARGED_SHOT]: 'skillOverchargedShot',
	[StatType.SKILL_BATTLE_CRY]: 'skillBattleCry',
	[StatType.SKILL_SICKENING_NOVA]: 'skillSickeningNova',
	[StatType.SKILL_GRAVITY_WELL]: 'skillGravityWell',
	[StatType.SKILL_BATTERY]: 'skillBattery',
	[StatType.SKILL_BULWARK]: 'skillBulwark',
	[StatType.PROJECTILE_TRAJECTORY_VALUE]: 'projectileTrajectoryValue',
	[StatType.PROJECTILE_TRAJECTORY_AMPLITUDE]: 'projectileTrajectoryAmplitude',
	[StatType.PROJECTILE_TRAJECTORY_PERIOD]: 'projectileTrajectoryPeriod',
	[StatType.PROJECTILE_TRAJECTORY_RADIUS]: 'projectileTrajectoryRadius',
	[StatType.PROJECTILE_TRAJECTORY_RANGE]: 'projectileTrajectoryRange',
	[StatType.PROJECTILE_TRAJECTORY_VALUE_MIN]: 'projectileTrajectoryValueMin',
	[StatType.PROJECTILE_TRAJECTORY_AMPLITUDE_MIN]: 'projectileTrajectoryAmplitudeMin',
	[StatType.PROJECTILE_TRAJECTORY_PERIOD_MIN]: 'projectileTrajectoryPeriodMin',
	[StatType.PROJECTILE_TRAJECTORY_RADIUS_MIN]: 'projectileTrajectoryRadiusMin',
	[StatType.PROJECTILE_TRAJECTORY_RANGE_MIN]: 'projectileTrajectoryRangeMin',
	[StatType.PROJECTILE_TRAJECTORY_VALUE_MAX]: 'projectileTrajectoryValueMax',
	[StatType.PROJECTILE_TRAJECTORY_AMPLITUDE_MAX]: 'projectileTrajectoryAmplitudeMax',
	[StatType.PROJECTILE_TRAJECTORY_PERIOD_MAX]: 'projectileTrajectoryPeriodMax',
	[StatType.PROJECTILE_TRAJECTORY_RADIUS_MAX]: 'projectileTrajectoryRadiusMax',
	[StatType.PROJECTILE_TRAJECTORY_RANGE_MAX]: 'projectileTrajectoryRangeMax',
	[StatType.LOOT_DROP_QUANTITY]: 'lootDropQuantity',
	[StatType.COIN_DROP_QUANTITY]: 'coinDropQuantity',
	[StatType.LOOT_DROP_RARITY]: 'lootDropRarity',
	[StatType.HEART_DROP_RATE]: 'heartDropRate'
} as const

const StatTypeToPropName = {
	...NormalStatTypeToPropName,
	...AliasStatTypeToPropNames
} as const

export type StatName = typeof StatTypeToPropName[StatType]
export type NormalStatName = typeof NormalStatTypeToPropName[keyof typeof NormalStatTypeToPropName]
export type AliasStatName = typeof AliasStatTypeToPropNames[keyof typeof AliasStatTypeToPropNames]

const PropNameToStatType = {}
for (const statType of Object.keys(StatTypeToPropName)) {
	const propName = StatTypeToPropName[statType]
	PropNameToStatType[propName] = statType
}

/** All kinds of binary flags. Enum range from 15000-19999. */
export enum BinaryFlag {
	CULL_ENEMIES_AT_HEALTH_THRESHOLD_1 = 15000,
	CULL_ENEMIES_AT_HEALTH_THRESHOLD_2 = 15001,
	MOVEMENT_SPEED_1_ON_KILL = 15002,
	MOVEMENT_SPEED_2_ON_KILL = 15003,
	IF_SHIELDS_THEN_IMMUNE_TO_ELEMENTAL_DOTS = 15004,
	IF_SHIELDS_THEN_IMMUNE_TO_ELEMENTAL_AILMENTS = 15005,
	SHIELD_CONDITIONAL_CHAOS_DAMAGE_1 = 15006,
	ON_SHIELD_BREAK_GAIN_MOVEMENT_SPEED_1 = 15007,
	SPELLSWORD_SUPER_BEAM_RAMP_UP = 15008,
	ON_SHOOT_VARY_PROJECTILE_SPEED = 15009,
	// consider applying damage penalties if using Double Tap
	ON_SHOOT_DOUBLE_TAP = 15010,
	// consider applying damage penalties if using Triple Tap
	ON_SHOOT_TRIPLE_TAP = 15011,
	CHAMPION_SLAYER = 15012,
	BOSS_KILLER = 15013,
	AGILE_ROLLS = 15014,
	HEART_DROPPER_1 = 15015,
	HEART_DROPPER_2 = 15016,
	ENEMY_KILLS_REDUCE_COOLDOWN_1 = 15017,
	GEAR_SKILL_OVERCHARGE_1 = 15018,

	TRAJECTORY_ACCELERATE = 16000,
	TRAJECTORY_DECCELERATE = 16001,
	TRAJECTORY_RANGE_ACCELERATE = 16002,
	TRAJECTORY_BOOMERANG = 16003,
	TRAJECTORY_SINE = 16004,
	TRAJECTORY_ZIGZAG = 16005,
	TRAJECTORY_CIRCULAR = 16006,
	TRAJECTORY_ORBIT = 16007,
	TRAJECTORY_SPIRAL = 16008,
	TRAJECTORY_LOOPING = 16009,
	TRAJECTORY_RANGE_CIRCULAR = 16010,
	TRAJECTORY_RANGE_ORBIT = 16011,
	TRAJECTORY_FLIP_EVERY_OTHER_SHOT = 16012,
}

/** An enum of all the mod categories in the game. This is a superset of StatType, with some additional categories >= enum #10000 */
enum ModCategory {
	// Stats, range 1-999, 2000-9999
	HP = StatType.HP,
	MAX_HP = StatType.MAX_HP,
	PHYSICAL_DAMAGE = StatType.PHYSICAL_DAMAGE,
	FIRE_DAMAGE = StatType.FIRE_DAMAGE,
	ICE_DAMAGE = StatType.ICE_DAMAGE,
	LIGHTNING_DAMAGE = StatType.LIGHTNING_DAMAGE,
	POISON_DAMAGE = StatType.POISON_DAMAGE,

	ATTACK_RATE = StatType.ATTACK_RATE,

	CRIT_CHANCE = StatType.CRIT_CHANCE,
	CRIT_DAMAGE = StatType.CRIT_DAMAGE,

	PROJECTILE_SPEED = StatType.PROJECTILE_SPEED,
	PROJECTILE_COUNT = StatType.PROJECTILE_COUNT,
	PROJECTILE_SIZE = StatType.PROJECTILE_SIZE,
	PROJECTILE_DURATION = StatType.PROJECTILE_DURATION,
	PROJECTILE_MAX_RANGE = StatType.PROJECTILE_MAX_RANGE,
	PROJECTILE_SPREAD_ANGLE = StatType.PROJECTILE_SPREAD_ANGLE,
	PROJECTILE_SPREAD_VARIANCE = StatType.PROJECTILE_SPREAD_VARIANCE,
	PROJECTILE_BEAM_WIDTH = StatType.PROJECTILE_BEAM_WIDTH,
	PROJECTILE_BEAM_LENGTH = StatType.PROJECTILE_BEAM_LENGTH,

	PROJECTILE_POINT_BLANK_DAMAGE = StatType.PROJECTILE_POINT_BLANK_DAMAGE,
	PROJECTILE_FAR_SHOT_DAMAGE = StatType.PROJECTILE_FAR_SHOT_DAMAGE,

	PROJECTILE_PIERCE_COUNT = StatType.PROJECTILE_PIERCE_COUNT,
	PROJECTILE_CHAIN_COUNT = StatType.PROJECTILE_CHAIN_COUNT,
	PROJECTILE_SPLASH_RADIUS = StatType.PROJECTILE_SPLASH_RADIUS,
	PROJECTILE_SPLASH_DAMAGE = StatType.PROJECTILE_SPLASH_DAMAGE,
	PROJECTILE_POOL_RADIUS = StatType.PROJECTILE_POOL_RADIUS,
	PROJECTILE_KNOCKBACK = StatType.PROJECTILE_KNOCKBACK,
	PROJECTILE_RICOCHET_COUNT = StatType.PROJECTILE_RICOCHET_COUNT,

	ALL_DAMAGE = StatType.ALL_DAMAGE,
	PROJECTILE_DAMAGE = StatType.PROJECTILE_DAMAGE,

	COOLDOWN_SPEED = StatType.COOLDOWN_SPEED,
	DEBUFF_POTENCY = StatType.DEBUFF_POTENCY,
	HEALTH_REGEN = StatType.HEALTH_REGEN,
	HEALTH_REGEN_DELAY = StatType.HEALTH_REGEN_DELAY,
	MAX_SHIELDS = StatType.MAX_SHIELDS,
	SHIELD_REGEN_RATE = StatType.SHIELD_REGEN_RATE,
	SHIELD_REGEN_DELAY = StatType.SHIELD_REGEN_DELAY,
	MAX_ENERGY = StatType.MAX_ENERGY,
	ENERGY_REGEN = StatType.ENERGY_REGEN,
	PRIMARY_FIRE_ENERGY_COST = StatType.PRIMARY_FIRE_ENERGY_COST,

	DEFENSE = StatType.DEFENSE,
	FIRE_RESIST = StatType.FIRE_RESIST,
	ICE_RESIST = StatType.ICE_RESIST,
	LIGHTNING_RESIST = StatType.LIGHTNING_RESIST,
	POISON_RESIST = StatType.POISON_RESIST,
	MOVEMENT_SPEED = StatType.MOVEMENT_SPEED,
	BUFF_DURATION = StatType.BUFF_DURATION,
	SKILL_USAGE_SPEED = StatType.SKILL_USAGE_SPEED,
	COOLDOWN_DURATION = StatType.COOLDOWN_DURATION,
	SKILL_SPEED = StatType.SKILL_SPEED,
	SKILL_DURATION = StatType.SKILL_DURATION,
	CHARGE_RATE = StatType.CHARGE_RATE,

	IGNITE_CHANCE = StatType.IGNITE_CHANCE,
	CHILL_CHANCE = StatType.CHILL_CHANCE,
	SHOCK_CHANCE = StatType.SHOCK_CHANCE,
	POISON_CHANCE = StatType.POISON_CHANCE,
	BLEED_CHANCE = StatType.BLEED_CHANCE,
	LOOT_DROP_QUANTITY = StatType.LOOT_DROP_QUANTITY,
	LOOT_DROP_RARITY = StatType.LOOT_DROP_RARITY,
	COIN_DROP_QUANTITY = StatType.COIN_DROP_QUANTITY,


	IGNITE_POTENCY = StatType.IGNITE_POTENCY,
	CHILL_POTENCY = StatType.CHILL_POTENCY,
	SHOCK_POTENCY = StatType.SHOCK_POTENCY,
	POISON_POTENCY = StatType.POISON_POTENCY,
	BLEED_POTENCY = StatType.BLEED_POTENCY,

	ALL_AILMENT_POTENCY = StatType.ALL_AILMENT_POTENCY,

	// End Stats

	// Skills, range 1000-1999
	SKILL_DODGE_ROLL = StatType.SKILL_DODGE_ROLL,
	SKILL_MOVEMENT_SPEED_BOOST = StatType.SKILL_MOVEMENT_SPEED_BOOST,
	SKILL_ATTACK_SPEED_BOOST = StatType.SKILL_ATTACK_SPEED_BOOST,
	SKILL_TUMBLE_ROLL = StatType.SKILL_TUMBLE_ROLL,
	SKILL_OVERSHIELD = StatType.SKILL_OVERSHIELD,
	SKILL_STONE_FORM = StatType.SKILL_STONE_FORM,
	SKILL_OVERCHARGED_SHOT = StatType.SKILL_OVERCHARGED_SHOT,
	SKILL_BATTLE_CRY = StatType.SKILL_BATTLE_CRY,
	SKILL_SICKENING_NOVA = StatType.SKILL_SICKENING_NOVA,
	SKILL_GRAVITY_WELL = StatType.SKILL_GRAVITY_WELL,
	SKILL_BATTERY = StatType.SKILL_BATTERY,
	SKILL_BULWARK = StatType.SKILL_BULWARK,

	// End Skills

	// Custom Categories, range 10000-12999
	PROJECTILE_TRAJECTORY = 10000,

	HYBRID_DEFENSE_FIRE_RESIST = 11000,
	HYBRID_DEFENSE_ICE_RESIST = 11001,
	HYBRID_DEFENSE_POISON_RESIST = 11002,
	HYBRID_DEFENSE_LIGHTNING_RESIST = 11003,
	HYBRID_FIRE_ICE_RESIST = 11004,
	HYBRID_FIRE_LIGHTNING_RESIST = 11005,
	HYBRID_FIRE_POISON_RESIST = 11006,
	HYBRID_ICE_LIGHTNING_RESIST = 11007,
	HYBRID_ICE_POISON_RESIST = 11008,
	HYBRID_LIGHTNING_POISON_RESIST = 11009,
	DEFENSE_RESIST_ALL = 11020,

	ELEMENTAL_DAMAGE = 12000,
	HYBRID_ELEMENTAL_DAMAGE = 12001,

	// End Custom Categories

	// Converters, range 13000-13999
	CONVERT_ALL_DAMAGE_TO_PHYSICAL = 13000,
	CONVERT_ALL_DAMAGE_TO_FIRE = 13001,
	CONVERT_ALL_DAMAGE_TO_ICE = 13002,
	CONVERT_ALL_DAMAGE_TO_LIGHTNING = 13003,
	CONVERT_ALL_DAMAGE_TO_POISON = 13004,

	CONVERT_PROJECTILE_COUNT_TO_ATTACK_RATE = 13005,
	CONVERT_ATTACK_RATE_TO_PROJECTILE_COUNT = 13006,

	// End Converters

	// Binary flags, range 15000-19999
	CULL_ENEMIES_AT_HEALTH_THRESHOLD_1 = BinaryFlag.CULL_ENEMIES_AT_HEALTH_THRESHOLD_1,
	CULL_ENEMIES_AT_HEALTH_THRESHOLD_2 = BinaryFlag.CULL_ENEMIES_AT_HEALTH_THRESHOLD_2,
	MOVEMENT_SPEED_1_ON_KILL = BinaryFlag.MOVEMENT_SPEED_1_ON_KILL,
	MOVEMENT_SPEED_2_ON_KILL = BinaryFlag.MOVEMENT_SPEED_2_ON_KILL,
	IF_SHIELDS_THEN_IMMUNE_TO_ELEMENTAL_DOTS = BinaryFlag.IF_SHIELDS_THEN_IMMUNE_TO_ELEMENTAL_DOTS,
	IF_SHIELDS_THEN_IMMUNE_TO_ELEMENTAL_AILMENTS = BinaryFlag.IF_SHIELDS_THEN_IMMUNE_TO_ELEMENTAL_AILMENTS,
	SHIELD_CONDITIONAL_CHAOS_DAMAGE_1 = BinaryFlag.SHIELD_CONDITIONAL_CHAOS_DAMAGE_1,
	ON_SHIELD_BREAK_GAIN_MOVEMENT_SPEED_1 = BinaryFlag.ON_SHIELD_BREAK_GAIN_MOVEMENT_SPEED_1,
	ON_SHOOT_VARY_PROJECTILE_SPEED = BinaryFlag.ON_SHOOT_VARY_PROJECTILE_SPEED,
	ON_SHOOT_DOUBLE_TAP = BinaryFlag.ON_SHOOT_DOUBLE_TAP,
	ON_SHOOT_TRIPLE_TAP = BinaryFlag.ON_SHOOT_TRIPLE_TAP,
	SPELLSWORD_SUPER_BEAM_RAMP_UP = BinaryFlag.SPELLSWORD_SUPER_BEAM_RAMP_UP,
	CHAMPION_SLAYER = BinaryFlag.CHAMPION_SLAYER,
	BOSS_KILLER = BinaryFlag.BOSS_KILLER,
	AGILE_ROLLS = BinaryFlag.AGILE_ROLLS,
	HEART_DROPPER_1 = BinaryFlag.HEART_DROPPER_1,
	HEART_DROPPER_2 = BinaryFlag.HEART_DROPPER_2,
	ENEMY_KILLS_REDUCE_COOLDOWN_1 = BinaryFlag.ENEMY_KILLS_REDUCE_COOLDOWN_1,
	GEAR_SKILL_OVERCHARGE_1 = BinaryFlag.GEAR_SKILL_OVERCHARGE_1,

	// End Binary Flags
}
ensureEnumUnique(ModCategory, logger)

export const DamageTypeModCategories = [ModCategory.PHYSICAL_DAMAGE, ModCategory.FIRE_DAMAGE, ModCategory.ICE_DAMAGE, ModCategory.LIGHTNING_DAMAGE, ModCategory.POISON_DAMAGE]
export const DamageTypeDefenseModCategories = [ModCategory.DEFENSE, ModCategory.FIRE_RESIST, ModCategory.ICE_RESIST, ModCategory.LIGHTNING_RESIST, ModCategory.POISON_RESIST]
export const AestheticModCategories = [
	ModCategory.PROJECTILE_MAX_RANGE,
	ModCategory.PROJECTILE_PIERCE_COUNT,
	ModCategory.PROJECTILE_CHAIN_COUNT,
	ModCategory.PROJECTILE_SPLASH_DAMAGE,
	ModCategory.PROJECTILE_POOL_RADIUS,
	ModCategory.PROJECTILE_KNOCKBACK,
	ModCategory.PROJECTILE_RICOCHET_COUNT,
	...DamageTypeModCategories,
	...DamageTypeDefenseModCategories,
	ModCategory.MOVEMENT_SPEED,
	ModCategory.COOLDOWN_SPEED,
]

const StatToPrettyName = {}
StatToPrettyName[ModCategory.HP] = 'Health'
StatToPrettyName[ModCategory.MAX_HP] = 'Health'
StatToPrettyName[ModCategory.PHYSICAL_DAMAGE] = 'Physical Damage'
StatToPrettyName[ModCategory.FIRE_DAMAGE] = 'Fire Damage'
StatToPrettyName[ModCategory.ICE_DAMAGE] = 'Ice Damage'
StatToPrettyName[ModCategory.LIGHTNING_DAMAGE] = 'Lightning Damage'
StatToPrettyName[ModCategory.POISON_DAMAGE] = 'Poison Damage'
StatToPrettyName[ModCategory.ATTACK_RATE] = 'Attack Rate'
StatToPrettyName[ModCategory.CRIT_CHANCE] = 'Critical Chance'
StatToPrettyName[ModCategory.CRIT_DAMAGE] = 'Critical Damage'
StatToPrettyName[ModCategory.COOLDOWN_SPEED] = 'Cooldown Speed'
StatToPrettyName[ModCategory.DEBUFF_POTENCY] = 'Debuff Potency'
StatToPrettyName[ModCategory.HEALTH_REGEN] = 'Health Regen'
StatToPrettyName[ModCategory.HEALTH_REGEN_DELAY] = 'Health Regen Delay'
StatToPrettyName[ModCategory.MAX_SHIELDS] = 'Shields'
StatToPrettyName[ModCategory.SHIELD_REGEN_RATE] = 'Shield Regen Rate'
StatToPrettyName[ModCategory.SHIELD_REGEN_DELAY] = 'Shield Regen Delay'
StatToPrettyName[ModCategory.MAX_ENERGY] = 'Energy'
StatToPrettyName[ModCategory.ENERGY_REGEN] = 'Energy Regen'
StatToPrettyName[ModCategory.PRIMARY_FIRE_ENERGY_COST] = 'Energy Cost'
StatToPrettyName[ModCategory.DEFENSE] = 'Defense'
StatToPrettyName[ModCategory.FIRE_RESIST] = 'Fire Resist'
StatToPrettyName[ModCategory.ICE_RESIST] = 'Ice Resist'
StatToPrettyName[ModCategory.LIGHTNING_RESIST] = 'Lightning Resist'
StatToPrettyName[ModCategory.POISON_RESIST] = 'Poison Resist'
StatToPrettyName[ModCategory.MOVEMENT_SPEED] = 'Movement Speed'
StatToPrettyName[ModCategory.BUFF_DURATION] = 'Buff Duration'
StatToPrettyName[ModCategory.SKILL_USAGE_SPEED] = 'Skill Usage Speed'
StatToPrettyName[ModCategory.PROJECTILE_SPEED] = 'Velocity'
StatToPrettyName[ModCategory.PROJECTILE_COUNT] = 'Projectiles'
StatToPrettyName[ModCategory.PROJECTILE_SIZE] = 'Size'
StatToPrettyName[ModCategory.PROJECTILE_DURATION] = 'Duration'
StatToPrettyName[ModCategory.PROJECTILE_MAX_RANGE] = 'Range'
StatToPrettyName[ModCategory.PROJECTILE_SPREAD_ANGLE] = 'Volley Spread'
StatToPrettyName[ModCategory.PROJECTILE_SPREAD_VARIANCE] = 'Spread'
StatToPrettyName[ModCategory.PROJECTILE_BEAM_WIDTH] = 'Beam Width'
StatToPrettyName[ModCategory.PROJECTILE_BEAM_LENGTH] = 'Beam Length'
StatToPrettyName[ModCategory.PROJECTILE_POINT_BLANK_DAMAGE] = 'Point Blank Damage'
StatToPrettyName[ModCategory.PROJECTILE_FAR_SHOT_DAMAGE] = 'Far Shot Damage'
StatToPrettyName[ModCategory.PROJECTILE_PIERCE_COUNT] = 'Piercing'
StatToPrettyName[ModCategory.PROJECTILE_CHAIN_COUNT] = 'Chaining (🛑)'
StatToPrettyName[ModCategory.PROJECTILE_SPLASH_RADIUS] = 'Splash Radius'
StatToPrettyName[ModCategory.PROJECTILE_SPLASH_DAMAGE] = 'Splash Damage'
StatToPrettyName[ModCategory.PROJECTILE_POOL_RADIUS] = 'Impact Pool (🛑)'
StatToPrettyName[ModCategory.PROJECTILE_KNOCKBACK] = 'Knockback (🛑)'
StatToPrettyName[ModCategory.PROJECTILE_RICOCHET_COUNT] = 'Ricochet (🛑)'
StatToPrettyName[ModCategory.PROJECTILE_TRAJECTORY] = 'PROJECTILE_TRAJECTORY'
StatToPrettyName[ModCategory.ALL_DAMAGE] = 'Damage'
StatToPrettyName[ModCategory.PROJECTILE_DAMAGE] = 'Proj. Damage'
StatToPrettyName[ModCategory.CHARGE_RATE] = 'Charge Rate'

StatToPrettyName[ModCategory.IGNITE_CHANCE] = 'Ignite Chance'
StatToPrettyName[ModCategory.CHILL_CHANCE] = 'Chill Chance'
StatToPrettyName[ModCategory.SHOCK_CHANCE] = 'Shock Chance'
StatToPrettyName[ModCategory.POISON_CHANCE] = 'Poison Chance'
StatToPrettyName[ModCategory.BLEED_CHANCE] = 'Bleed Chance'
StatToPrettyName[ModCategory.LOOT_DROP_QUANTITY] = 'Loot Drop Quantity'
StatToPrettyName[ModCategory.LOOT_DROP_RARITY] = 'Loot Drop Rarity'
StatToPrettyName[ModCategory.COIN_DROP_QUANTITY] = 'Coin Drop Quantity'

StatToPrettyName[ModCategory.IGNITE_POTENCY] = 'Ignite Potency'
StatToPrettyName[ModCategory.CHILL_POTENCY] = 'Chill Potency'
StatToPrettyName[ModCategory.SHOCK_POTENCY] = 'Shock Potency'
StatToPrettyName[ModCategory.POISON_POTENCY] = 'Poison Potency'
StatToPrettyName[ModCategory.BLEED_POTENCY] = 'Bleed Potency'

StatToPrettyName[ModCategory.CONVERT_ALL_DAMAGE_TO_PHYSICAL] = 'Convert Damage to Physical'
StatToPrettyName[ModCategory.CONVERT_ALL_DAMAGE_TO_FIRE] = 'Convert Damage to Fire'
StatToPrettyName[ModCategory.CONVERT_ALL_DAMAGE_TO_ICE] = 'Convert Damage to Ice'
StatToPrettyName[ModCategory.CONVERT_ALL_DAMAGE_TO_LIGHTNING] = 'Convert Damage to Lightning'
StatToPrettyName[ModCategory.CONVERT_ALL_DAMAGE_TO_POISON] = 'Convert Damage to Poison'
StatToPrettyName[ModCategory.CONVERT_PROJECTILE_COUNT_TO_ATTACK_RATE] = 'Convert Projectile Count to Attack Rate'
StatToPrettyName[ModCategory.CONVERT_ATTACK_RATE_TO_PROJECTILE_COUNT] = 'Convert Attack Rate to Projectile Count'
StatToPrettyName[ModCategory.CULL_ENEMIES_AT_HEALTH_THRESHOLD_1] = 'Cull Enemies #1'
StatToPrettyName[ModCategory.CULL_ENEMIES_AT_HEALTH_THRESHOLD_2] = 'Cull Enemies #2'
StatToPrettyName[ModCategory.MOVEMENT_SPEED_1_ON_KILL] = 'Gain Movement Speed on Kill #1'
StatToPrettyName[ModCategory.MOVEMENT_SPEED_2_ON_KILL] = 'Gain Movement Speed on Kill #2'

StatToPrettyName[ModCategory.HYBRID_DEFENSE_FIRE_RESIST] = 'Hybrid Defense'
StatToPrettyName[ModCategory.HYBRID_DEFENSE_ICE_RESIST] = 'Hybrid Defense'
StatToPrettyName[ModCategory.HYBRID_DEFENSE_POISON_RESIST] = 'Hybrid Defense'
StatToPrettyName[ModCategory.HYBRID_DEFENSE_LIGHTNING_RESIST] = 'Hybrid Defense'
StatToPrettyName[ModCategory.HYBRID_FIRE_ICE_RESIST] = 'Hybrid Defense'
StatToPrettyName[ModCategory.HYBRID_FIRE_LIGHTNING_RESIST] = 'Hybrid Defense'
StatToPrettyName[ModCategory.HYBRID_FIRE_POISON_RESIST] = 'Hybrid Defense'
StatToPrettyName[ModCategory.HYBRID_ICE_LIGHTNING_RESIST] = 'Hybrid Defense'
StatToPrettyName[ModCategory.HYBRID_ICE_POISON_RESIST] = 'Hybrid Defense'
StatToPrettyName[ModCategory.HYBRID_LIGHTNING_POISON_RESIST] = 'Hybrid Defense'
StatToPrettyName[ModCategory.DEFENSE_RESIST_ALL] = 'Defense Against All'
StatToPrettyName[StatType.NONE] = 'No Stat'
StatToPrettyName[StatType.PROJECTILE_TRAJECTORY_RADIUS] = 'Trajectory Radius'
StatToPrettyName[StatType.PROJECTILE_TRAJECTORY_AMPLITUDE] = 'Shot Amplitude'
StatToPrettyName[StatType.PROJECTILE_TRAJECTORY_PERIOD] = 'PROJECTILE_TRAJECTORY_PERIOD'
StatToPrettyName[StatType.COOLDOWN_SPEED] = 'Cooldown Rate'
StatToPrettyName[StatType.COOLDOWN_DURATION] = 'Cooldown Time'

StatToPrettyName[StatType.SKILL_SPEED] = 'Speed'
StatToPrettyName[StatType.SKILL_DURATION] = 'Duration'
StatToPrettyName[StatType.SKILL_DODGE_ROLL] = 'Dodge Roll'
StatToPrettyName[StatType.SKILL_MOVEMENT_SPEED_BOOST] = 'Running Man'
StatToPrettyName[StatType.SKILL_ATTACK_SPEED_BOOST] = 'Berserk'
StatToPrettyName[StatType.SKILL_TUMBLE_ROLL] = 'Tumble Roll'
StatToPrettyName[StatType.SKILL_OVERSHIELD] = 'Overshield'
StatToPrettyName[StatType.SKILL_STONE_FORM] = 'Stone Form'
StatToPrettyName[StatType.SKILL_OVERCHARGED_SHOT] = 'Overcharged Shot'
StatToPrettyName[StatType.SKILL_BATTLE_CRY] = 'Battle Cry'
StatToPrettyName[StatType.SKILL_SICKENING_NOVA] = 'Sickening Nova'
StatToPrettyName[StatType.SKILL_GRAVITY_WELL] = 'Gravity Well'
StatToPrettyName[StatType.SKILL_BATTERY] = 'Battery'
StatToPrettyName[StatType.SKILL_BULWARK] = 'Defensive Bulwark'

export const GearSkillStats = [
	StatType.SKILL_DODGE_ROLL,
	StatType.SKILL_MOVEMENT_SPEED_BOOST,
	StatType.SKILL_ATTACK_SPEED_BOOST,
	StatType.SKILL_TUMBLE_ROLL,
	StatType.SKILL_OVERSHIELD,
	StatType.SKILL_STONE_FORM,
	StatType.SKILL_OVERCHARGED_SHOT,
	StatType.SKILL_BATTLE_CRY,
	StatType.SKILL_SICKENING_NOVA,
	StatType.SKILL_GRAVITY_WELL,
	StatType.SKILL_BATTERY,
	StatType.SKILL_BULWARK
]

for (const stat in StatType) {
	if (!(stat in StatToPrettyName)) {
		StatToPrettyName[stat] = stat
	}
}

// order in which mod categories get displayed to the user, to keep consistent descriptions across disparate items
const ModCategorySortOrder = {}
ModCategorySortOrder[ModCategory.HP] = 10001
ModCategorySortOrder[ModCategory.MAX_HP] = 10000
ModCategorySortOrder[ModCategory.PHYSICAL_DAMAGE] = 1002
ModCategorySortOrder[ModCategory.FIRE_DAMAGE] = 1003
ModCategorySortOrder[ModCategory.ICE_DAMAGE] = 1004
ModCategorySortOrder[ModCategory.LIGHTNING_DAMAGE] = 1005
ModCategorySortOrder[ModCategory.POISON_DAMAGE] = 1006
ModCategorySortOrder[ModCategory.ATTACK_RATE] = 1010
ModCategorySortOrder[ModCategory.CHARGE_RATE] = 1011
ModCategorySortOrder[ModCategory.PRIMARY_FIRE_ENERGY_COST] = 1012
ModCategorySortOrder[ModCategory.CRIT_CHANCE] = 1300
ModCategorySortOrder[ModCategory.CRIT_DAMAGE] = 1301
ModCategorySortOrder[ModCategory.COOLDOWN_SPEED] = 10302
ModCategorySortOrder[ModCategory.DEBUFF_POTENCY] = 10401
ModCategorySortOrder[ModCategory.HEALTH_REGEN] = 10100
ModCategorySortOrder[ModCategory.HEALTH_REGEN_DELAY] = 10101
ModCategorySortOrder[ModCategory.MAX_SHIELDS] = 10102
ModCategorySortOrder[ModCategory.SHIELD_REGEN_RATE] = 10103
ModCategorySortOrder[ModCategory.SHIELD_REGEN_DELAY] = 10104
ModCategorySortOrder[ModCategory.MAX_ENERGY] = 10105
ModCategorySortOrder[ModCategory.ENERGY_REGEN] = 10106
ModCategorySortOrder[ModCategory.DEFENSE] = 10200
ModCategorySortOrder[ModCategory.FIRE_RESIST] = 10201
ModCategorySortOrder[ModCategory.ICE_RESIST] = 10202
ModCategorySortOrder[ModCategory.LIGHTNING_RESIST] = 10203
ModCategorySortOrder[ModCategory.POISON_RESIST] = 10204
ModCategorySortOrder[ModCategory.MOVEMENT_SPEED] = 10300
ModCategorySortOrder[ModCategory.BUFF_DURATION] = 10400
ModCategorySortOrder[ModCategory.SKILL_USAGE_SPEED] = 10301
ModCategorySortOrder[ModCategory.PROJECTILE_SPEED] = 1201
ModCategorySortOrder[ModCategory.PROJECTILE_COUNT] = 1100
ModCategorySortOrder[ModCategory.PROJECTILE_SIZE] = 1200
ModCategorySortOrder[ModCategory.PROJECTILE_DURATION] = 1202
ModCategorySortOrder[ModCategory.PROJECTILE_MAX_RANGE] = 1050
ModCategorySortOrder[ModCategory.PROJECTILE_SPREAD_ANGLE] = 1101
ModCategorySortOrder[ModCategory.PROJECTILE_POINT_BLANK_DAMAGE] = 1302
ModCategorySortOrder[ModCategory.PROJECTILE_FAR_SHOT_DAMAGE] = 1303
ModCategorySortOrder[ModCategory.PROJECTILE_PIERCE_COUNT] = 1400
ModCategorySortOrder[ModCategory.PROJECTILE_CHAIN_COUNT] = 1401
ModCategorySortOrder[ModCategory.PROJECTILE_SPLASH_RADIUS] = 1403
ModCategorySortOrder[ModCategory.PROJECTILE_SPLASH_DAMAGE] = 1404
ModCategorySortOrder[ModCategory.PROJECTILE_POOL_RADIUS] = 1405
ModCategorySortOrder[ModCategory.PROJECTILE_KNOCKBACK] = 1406
ModCategorySortOrder[ModCategory.PROJECTILE_RICOCHET_COUNT] = 1402
ModCategorySortOrder[ModCategory.PROJECTILE_TRAJECTORY] = 99999
ModCategorySortOrder[ModCategory.ALL_DAMAGE] = 1050
ModCategorySortOrder[ModCategory.PROJECTILE_DAMAGE] = 1051
ModCategorySortOrder[ModCategory.LOOT_DROP_QUANTITY] = 1052
ModCategorySortOrder[ModCategory.LOOT_DROP_RARITY] = 1054
ModCategorySortOrder[ModCategory.COIN_DROP_QUANTITY] = 1055


//TODO2: move all this weapon/component/visual stuff to some other file... these are unrelated to stats or mod categories entirely
export enum WeaponComponentIndentifier {
	HEAD = 'head',
	HANDLE = 'handle',
	ORNAMENT = 'ornament',
}

/*
// THIS GENERATES VisualComponent enum
const weaponTypes = ['Spellsword', 'Staff', 'Scythe', 'Wand', 'ArcaneFocus', 'Crossbow']
const weaponParts = ['HANDLE', 'HEAD', 'ORNAMENT']
const weaponTiers = ['COMMON', 'UNCOMMON', 'RARE', 'ASTRONOMICAL', 'EPIC', 'LEGENDARY', 'CHAOS']

let s = ''

weaponTypes.forEach(type => {
	const typeIndex = weaponTypes.indexOf(type)
	s += `\t// ${type}\n`
	for (let i = 0; i < numVariants; i++) {
		weaponParts.forEach(part => {
			const partIndex = weaponParts.indexOf(part)
			weaponTiers.forEach(tier => {
				const tierIndex = weaponTiers.indexOf(tier)
				const typeId = typeIndex * 2000 + 1000
				const partId = partIndex * 100
				const tierId = tierIndex
				const styleId = i * 300
				s += `\t${type.toUpperCase()}_${part}_${tier}_0${i + 1} = ${typeId + partId + tierId + styleId},\n`
			})
			s += '\n'
		})
	}
})
console.log(s)

process.exit(0)
/* */

enum VisualComponent {
	// Spellsword
	SPELLSWORD_HANDLE_ASTRONOMICAL_01 = 1000,
	SPELLSWORD_HANDLE_CHAOS_01 = 1001,
	SPELLSWORD_HANDLE_COMMON_01 = 1002,
	SPELLSWORD_HANDLE_EPIC_01 = 1003,
	SPELLSWORD_HANDLE_LEGENDARY_01 = 1004,
	SPELLSWORD_HANDLE_RARE_01 = 1005,
	SPELLSWORD_HANDLE_UNCOMMON_01 = 1006,

	SPELLSWORD_HEAD_ASTRONOMICAL_01 = 1100,
	SPELLSWORD_HEAD_CHAOS_01 = 1101,
	SPELLSWORD_HEAD_COMMON_01 = 1102,
	SPELLSWORD_HEAD_EPIC_01 = 1103,
	SPELLSWORD_HEAD_LEGENDARY_01 = 1104,
	SPELLSWORD_HEAD_RARE_01 = 1105,
	SPELLSWORD_HEAD_UNCOMMON_01 = 1106,

	SPELLSWORD_ORNAMENT_ASTRONOMICAL_01 = 1200,
	SPELLSWORD_ORNAMENT_CHAOS_01 = 1201,
	SPELLSWORD_ORNAMENT_COMMON_01 = 1202,
	SPELLSWORD_ORNAMENT_EPIC_01 = 1203,
	SPELLSWORD_ORNAMENT_LEGENDARY_01 = 1204,
	SPELLSWORD_ORNAMENT_RARE_01 = 1205,
	SPELLSWORD_ORNAMENT_UNCOMMON_01 = 1206,

	SPELLSWORD_HANDLE_ASTRONOMICAL_02 = 1300,
	SPELLSWORD_HANDLE_CHAOS_02 = 1301,
	SPELLSWORD_HANDLE_COMMON_02 = 1302,
	SPELLSWORD_HANDLE_EPIC_02 = 1303,
	SPELLSWORD_HANDLE_LEGENDARY_02 = 1304,
	SPELLSWORD_HANDLE_RARE_02 = 1305,
	SPELLSWORD_HANDLE_UNCOMMON_02 = 1306,

	SPELLSWORD_HEAD_ASTRONOMICAL_02 = 1400,
	SPELLSWORD_HEAD_CHAOS_02 = 1401,
	SPELLSWORD_HEAD_COMMON_02 = 1402,
	SPELLSWORD_HEAD_EPIC_02 = 1403,
	SPELLSWORD_HEAD_LEGENDARY_02 = 1404,
	SPELLSWORD_HEAD_RARE_02 = 1405,
	SPELLSWORD_HEAD_UNCOMMON_02 = 1406,

	SPELLSWORD_ORNAMENT_ASTRONOMICAL_02 = 1500,
	SPELLSWORD_ORNAMENT_CHAOS_02 = 1501,
	SPELLSWORD_ORNAMENT_COMMON_02 = 1502,
	SPELLSWORD_ORNAMENT_EPIC_02 = 1503,
	SPELLSWORD_ORNAMENT_LEGENDARY_02 = 1504,
	SPELLSWORD_ORNAMENT_RARE_02 = 1505,
	SPELLSWORD_ORNAMENT_UNCOMMON_02 = 1506,

	// Staff
	STAFF_HANDLE_ASTRONOMICAL_01 = 3000,
	STAFF_HANDLE_CHAOS_01 = 3001,
	STAFF_HANDLE_COMMON_01 = 3002,
	STAFF_HANDLE_EPIC_01 = 3003,
	STAFF_HANDLE_LEGENDARY_01 = 3004,
	STAFF_HANDLE_RARE_01 = 3005,
	STAFF_HANDLE_UNCOMMON_01 = 3006,

	STAFF_HEAD_ASTRONOMICAL_01 = 3100,
	STAFF_HEAD_CHAOS_01 = 3101,
	STAFF_HEAD_COMMON_01 = 3102,
	STAFF_HEAD_EPIC_01 = 3103,
	STAFF_HEAD_LEGENDARY_01 = 3104,
	STAFF_HEAD_RARE_01 = 3105,
	STAFF_HEAD_UNCOMMON_01 = 3106,

	STAFF_ORNAMENT_ASTRONOMICAL_01 = 3200,
	STAFF_ORNAMENT_CHAOS_01 = 3201,
	STAFF_ORNAMENT_COMMON_01 = 3202,
	STAFF_ORNAMENT_EPIC_01 = 3203,
	STAFF_ORNAMENT_LEGENDARY_01 = 3204,
	STAFF_ORNAMENT_RARE_01 = 3205,
	STAFF_ORNAMENT_UNCOMMON_01 = 3206,

	STAFF_HANDLE_ASTRONOMICAL_02 = 3300,
	STAFF_HANDLE_CHAOS_02 = 3301,
	STAFF_HANDLE_COMMON_02 = 3302,
	STAFF_HANDLE_EPIC_02 = 3303,
	STAFF_HANDLE_LEGENDARY_02 = 3304,
	STAFF_HANDLE_RARE_02 = 3305,
	STAFF_HANDLE_UNCOMMON_02 = 3306,

	STAFF_HEAD_ASTRONOMICAL_02 = 3400,
	STAFF_HEAD_CHAOS_02 = 3401,
	STAFF_HEAD_COMMON_02 = 3402,
	STAFF_HEAD_EPIC_02 = 3403,
	STAFF_HEAD_LEGENDARY_02 = 3404,
	STAFF_HEAD_RARE_02 = 3405,
	STAFF_HEAD_UNCOMMON_02 = 3406,

	STAFF_ORNAMENT_ASTRONOMICAL_02 = 3500,
	STAFF_ORNAMENT_CHAOS_02 = 3501,
	STAFF_ORNAMENT_COMMON_02 = 3502,
	STAFF_ORNAMENT_EPIC_02 = 3503,
	STAFF_ORNAMENT_LEGENDARY_02 = 3504,
	STAFF_ORNAMENT_RARE_02 = 3505,
	STAFF_ORNAMENT_UNCOMMON_02 = 3506,

	// Scythe
	SCYTHE_HANDLE_ASTRONOMICAL_01 = 5000,
	SCYTHE_HANDLE_CHAOS_01 = 5001,
	SCYTHE_HANDLE_COMMON_01 = 5002,
	SCYTHE_HANDLE_EPIC_01 = 5003,
	SCYTHE_HANDLE_LEGENDARY_01 = 5004,
	SCYTHE_HANDLE_RARE_01 = 5005,
	SCYTHE_HANDLE_UNCOMMON_01 = 5006,

	SCYTHE_HEAD_ASTRONOMICAL_01 = 5100,
	SCYTHE_HEAD_CHAOS_01 = 5101,
	SCYTHE_HEAD_COMMON_01 = 5102,
	SCYTHE_HEAD_EPIC_01 = 5103,
	SCYTHE_HEAD_LEGENDARY_01 = 5104,
	SCYTHE_HEAD_RARE_01 = 5105,
	SCYTHE_HEAD_UNCOMMON_01 = 5106,

	SCYTHE_ORNAMENT_ASTRONOMICAL_01 = 5200,
	SCYTHE_ORNAMENT_CHAOS_01 = 5201,
	SCYTHE_ORNAMENT_COMMON_01 = 5202,
	SCYTHE_ORNAMENT_EPIC_01 = 5203,
	SCYTHE_ORNAMENT_LEGENDARY_01 = 5204,
	SCYTHE_ORNAMENT_RARE_01 = 5205,
	SCYTHE_ORNAMENT_UNCOMMON_01 = 5206,

	SCYTHE_HANDLE_ASTRONOMICAL_02 = 5300,
	SCYTHE_HANDLE_CHAOS_02 = 5301,
	SCYTHE_HANDLE_COMMON_02 = 5302,
	SCYTHE_HANDLE_EPIC_02 = 5303,
	SCYTHE_HANDLE_LEGENDARY_02 = 5304,
	SCYTHE_HANDLE_RARE_02 = 5305,
	SCYTHE_HANDLE_UNCOMMON_02 = 5306,

	SCYTHE_HEAD_ASTRONOMICAL_02 = 5400,
	SCYTHE_HEAD_CHAOS_02 = 5401,
	SCYTHE_HEAD_COMMON_02 = 5402,
	SCYTHE_HEAD_EPIC_02 = 5403,
	SCYTHE_HEAD_LEGENDARY_02 = 5404,
	SCYTHE_HEAD_RARE_02 = 5405,
	SCYTHE_HEAD_UNCOMMON_02 = 5406,

	SCYTHE_ORNAMENT_ASTRONOMICAL_02 = 5500,
	SCYTHE_ORNAMENT_CHAOS_02 = 5501,
	SCYTHE_ORNAMENT_COMMON_02 = 5502,
	SCYTHE_ORNAMENT_EPIC_02 = 5503,
	SCYTHE_ORNAMENT_LEGENDARY_02 = 5504,
	SCYTHE_ORNAMENT_RARE_02 = 5505,
	SCYTHE_ORNAMENT_UNCOMMON_02 = 5506,

	// Wand
	WAND_HANDLE_ASTRONOMICAL_01 = 7000,
	WAND_HANDLE_CHAOS_01 = 7001,
	WAND_HANDLE_COMMON_01 = 7002,
	WAND_HANDLE_EPIC_01 = 7003,
	WAND_HANDLE_LEGENDARY_01 = 7004,
	WAND_HANDLE_RARE_01 = 7005,
	WAND_HANDLE_UNCOMMON_01 = 7006,

	WAND_HEAD_ASTRONOMICAL_01 = 7100,
	WAND_HEAD_CHAOS_01 = 7101,
	WAND_HEAD_COMMON_01 = 7102,
	WAND_HEAD_EPIC_01 = 7103,
	WAND_HEAD_LEGENDARY_01 = 7104,
	WAND_HEAD_RARE_01 = 7105,
	WAND_HEAD_UNCOMMON_01 = 7106,

	WAND_ORNAMENT_ASTRONOMICAL_01 = 7200,
	WAND_ORNAMENT_CHAOS_01 = 7201,
	WAND_ORNAMENT_COMMON_01 = 7202,
	WAND_ORNAMENT_EPIC_01 = 7203,
	WAND_ORNAMENT_LEGENDARY_01 = 7204,
	WAND_ORNAMENT_RARE_01 = 7205,
	WAND_ORNAMENT_UNCOMMON_01 = 7206,

	WAND_HANDLE_ASTRONOMICAL_02 = 7300,
	WAND_HANDLE_CHAOS_02 = 7301,
	WAND_HANDLE_COMMON_02 = 7302,
	WAND_HANDLE_EPIC_02 = 7303,
	WAND_HANDLE_LEGENDARY_02 = 7304,
	WAND_HANDLE_RARE_02 = 7305,
	WAND_HANDLE_UNCOMMON_02 = 7306,

	WAND_HEAD_ASTRONOMICAL_02 = 7400,
	WAND_HEAD_CHAOS_02 = 7401,
	WAND_HEAD_COMMON_02 = 7402,
	WAND_HEAD_EPIC_02 = 7403,
	WAND_HEAD_LEGENDARY_02 = 7404,
	WAND_HEAD_RARE_02 = 7405,
	WAND_HEAD_UNCOMMON_02 = 7406,

	WAND_ORNAMENT_ASTRONOMICAL_02 = 7500,
	WAND_ORNAMENT_CHAOS_02 = 7501,
	WAND_ORNAMENT_COMMON_02 = 7502,
	WAND_ORNAMENT_EPIC_02 = 7503,
	WAND_ORNAMENT_LEGENDARY_02 = 7504,
	WAND_ORNAMENT_RARE_02 = 7505,
	WAND_ORNAMENT_UNCOMMON_02 = 7506,

	// ArcaneFocus
	ARCANEFOCUS_HANDLE_ASTRONOMICAL_01 = 9000,
	ARCANEFOCUS_HANDLE_CHAOS_01 = 9001,
	ARCANEFOCUS_HANDLE_COMMON_01 = 9002,
	ARCANEFOCUS_HANDLE_EPIC_01 = 9003,
	ARCANEFOCUS_HANDLE_LEGENDARY_01 = 9004,
	ARCANEFOCUS_HANDLE_RARE_01 = 9005,
	ARCANEFOCUS_HANDLE_UNCOMMON_01 = 9006,

	ARCANEFOCUS_HEAD_ASTRONOMICAL_01 = 9100,
	ARCANEFOCUS_HEAD_CHAOS_01 = 9101,
	ARCANEFOCUS_HEAD_COMMON_01 = 9102,
	ARCANEFOCUS_HEAD_EPIC_01 = 9103,
	ARCANEFOCUS_HEAD_LEGENDARY_01 = 9104,
	ARCANEFOCUS_HEAD_RARE_01 = 9105,
	ARCANEFOCUS_HEAD_UNCOMMON_01 = 9106,

	ARCANEFOCUS_ORNAMENT_ASTRONOMICAL_01 = 9200,
	ARCANEFOCUS_ORNAMENT_CHAOS_01 = 9201,
	ARCANEFOCUS_ORNAMENT_COMMON_01 = 9202,
	ARCANEFOCUS_ORNAMENT_EPIC_01 = 9203,
	ARCANEFOCUS_ORNAMENT_LEGENDARY_01 = 9204,
	ARCANEFOCUS_ORNAMENT_RARE_01 = 9205,
	ARCANEFOCUS_ORNAMENT_UNCOMMON_01 = 9206,

	ARCANEFOCUS_HANDLE_ASTRONOMICAL_02 = 9300,
	ARCANEFOCUS_HANDLE_CHAOS_02 = 9301,
	ARCANEFOCUS_HANDLE_COMMON_02 = 9302,
	ARCANEFOCUS_HANDLE_EPIC_02 = 9303,
	ARCANEFOCUS_HANDLE_LEGENDARY_02 = 9304,
	ARCANEFOCUS_HANDLE_RARE_02 = 9305,
	ARCANEFOCUS_HANDLE_UNCOMMON_02 = 9306,

	ARCANEFOCUS_HEAD_ASTRONOMICAL_02 = 9400,
	ARCANEFOCUS_HEAD_CHAOS_02 = 9401,
	ARCANEFOCUS_HEAD_COMMON_02 = 9402,
	ARCANEFOCUS_HEAD_EPIC_02 = 9403,
	ARCANEFOCUS_HEAD_LEGENDARY_02 = 9404,
	ARCANEFOCUS_HEAD_RARE_02 = 9405,
	ARCANEFOCUS_HEAD_UNCOMMON_02 = 9406,

	ARCANEFOCUS_ORNAMENT_ASTRONOMICAL_02 = 9500,
	ARCANEFOCUS_ORNAMENT_CHAOS_02 = 9501,
	ARCANEFOCUS_ORNAMENT_COMMON_02 = 9502,
	ARCANEFOCUS_ORNAMENT_EPIC_02 = 9503,
	ARCANEFOCUS_ORNAMENT_LEGENDARY_02 = 9504,
	ARCANEFOCUS_ORNAMENT_RARE_02 = 9505,
	ARCANEFOCUS_ORNAMENT_UNCOMMON_02 = 9506,

	// Crossbow
	CROSSBOW_HANDLE_ASTRONOMICAL_01 = 11000,
	CROSSBOW_HANDLE_CHAOS_01 = 11001,
	CROSSBOW_HANDLE_COMMON_01 = 11002,
	CROSSBOW_HANDLE_EPIC_01 = 11003,
	CROSSBOW_HANDLE_LEGENDARY_01 = 11004,
	CROSSBOW_HANDLE_RARE_01 = 11005,
	CROSSBOW_HANDLE_UNCOMMON_01 = 11006,

	CROSSBOW_HEAD_ASTRONOMICAL_01 = 11100,
	CROSSBOW_HEAD_CHAOS_01 = 11101,
	CROSSBOW_HEAD_COMMON_01 = 11102,
	CROSSBOW_HEAD_EPIC_01 = 11103,
	CROSSBOW_HEAD_LEGENDARY_01 = 11104,
	CROSSBOW_HEAD_RARE_01 = 11105,
	CROSSBOW_HEAD_UNCOMMON_01 = 11106,

	CROSSBOW_ORNAMENT_ASTRONOMICAL_01 = 11200,
	CROSSBOW_ORNAMENT_CHAOS_01 = 11201,
	CROSSBOW_ORNAMENT_COMMON_01 = 11202,
	CROSSBOW_ORNAMENT_EPIC_01 = 11203,
	CROSSBOW_ORNAMENT_LEGENDARY_01 = 11204,
	CROSSBOW_ORNAMENT_RARE_01 = 11205,
	CROSSBOW_ORNAMENT_UNCOMMON_01 = 11206,

	CROSSBOW_HANDLE_ASTRONOMICAL_02 = 11300,
	CROSSBOW_HANDLE_CHAOS_02 = 11301,
	CROSSBOW_HANDLE_COMMON_02 = 11302,
	CROSSBOW_HANDLE_EPIC_02 = 11303,
	CROSSBOW_HANDLE_LEGENDARY_02 = 11304,
	CROSSBOW_HANDLE_RARE_02 = 11305,
	CROSSBOW_HANDLE_UNCOMMON_02 = 11306,

	CROSSBOW_HEAD_ASTRONOMICAL_02 = 11400,
	CROSSBOW_HEAD_CHAOS_02 = 11401,
	CROSSBOW_HEAD_COMMON_02 = 11402,
	CROSSBOW_HEAD_EPIC_02 = 11403,
	CROSSBOW_HEAD_LEGENDARY_02 = 11404,
	CROSSBOW_HEAD_RARE_02 = 11405,
	CROSSBOW_HEAD_UNCOMMON_02 = 11406,

	CROSSBOW_ORNAMENT_ASTRONOMICAL_02 = 11500,
	CROSSBOW_ORNAMENT_CHAOS_02 = 11501,
	CROSSBOW_ORNAMENT_COMMON_02 = 11502,
	CROSSBOW_ORNAMENT_EPIC_02 = 11503,
	CROSSBOW_ORNAMENT_LEGENDARY_02 = 11504,
	CROSSBOW_ORNAMENT_RARE_02 = 11505,
	CROSSBOW_ORNAMENT_UNCOMMON_02 = 11506,
}
ensureEnumUnique(VisualComponent, logger)

const WeaponSubTypeToString = {
	[WeaponSubType.Spellsword]: 'SPELLSWORD',
	[WeaponSubType.Scythe]: 'SCYTHE',
	[WeaponSubType.Staff]: 'STAFF',
	[WeaponSubType.Wand]: 'WAND',
	[WeaponSubType.ArcaneFocus]: 'ARCANEFOCUS',
	[WeaponSubType.Crossbow]: 'CROSSBOW',
}

function getRandomVisualComponent(weaponSubType: WeaponSubType, componentSlot: WeaponComponentIndentifier, tier: ItemRarity): VisualComponent {
	// visualComponentString: STAFF_HEAD_EPIC_02
	let visualComponentString = `${WeaponSubTypeToString[weaponSubType]}_${componentSlot.toUpperCase()}_${ItemRarity[tier].toUpperCase()}_0`
	const rand = Math.random()
	const idx = Math.floor(rand * NUM_VISUAL_VARIANTS) + 1
	visualComponentString += idx
	const component = VisualComponent[visualComponentString]
	return component
}

function getDataStringFromVisualComponent(component: VisualComponent) {
	// component: STAFF_HEAD_EPIC_02
	const words = VisualComponent[component].split('_') // ['STAFF', 'HEAD', 'EPIC', '02']
	const dataString = words[1].toLowerCase() + '-' + words[2].toLowerCase() + '-' + words[3] // 'head-epic-02'
	return dataString
}

export { getRandomVisualComponent, StatType, ModCategory, VisualComponent, StatTypeToPropName, PropNameToStatType, StatToPrettyName, ModCategorySortOrder, getDataStringFromVisualComponent }
