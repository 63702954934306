import logger from '../../utils/client-logger'
import { getAnonymousMtxOffersRequest, getMtxOffersRequest, getMtxRequest, getXsollaTokenForItemSKURequest, getXsollaTokenForSubscriptionSKURequest } from '../../utils/api/atlas-requests.client'
import { NengiClient } from '../../engine/client/nengi-client'
import RequestStashUpdate from '../../items/shared/request-stash-update-command'
import moment from 'moment'
import RequestForteExchangeUrl from '../../mtx/shared/request-forte-exchange-url-command'
import GotExchangeURLMessage from '../../mtx/shared/got-exchange-url-message'
import { showGenericInfoPromptUI } from './generic-information-prompt.ui-state'
import PurchaseMtx from '../../mtx/shared/purchase-mtx-command'
import { showGenericYesNoUI } from './generic-yes-no.ui-state'
import { UI } from '../ui'
import { MAX_MTX_INCREASES } from '../../engine/shared/game-data/player-formulas'

export const mtxStoreUIState = () => {
	logger.debug('Initializing MTX store module...')
	return {
		namespaced: true,
		state: {
			mtxOffers: [],
			itemSkuToken: '',
			subscriptionSkuToken: '',
			itemDetailsPromptVisible: false,
			selectedItem: null,
			selectedMtxItem: null,
			featuredSalesVisible: false,
			usingStore: false,
		},
		getters: {
			mtxOffers(state: any) {
				return state.mtxOffers
			},
			itemSkuToken(state: any) {
				return state.itemSkuToken
			},
			subscriptionSkuToken(state: any) {
				return state.subscriptionSkuToken
			},
			selectedMtxItem(state: any) {
				return state.selectedMtxItem
			},
			getItemDetailsPrompt(state: any) {
				return state.itemDetailsPromptVisible
			},
			getSelectedItem(state: any) {
				return state.selectedItem
			},
			getFeaturedSalesVisible(state: any) {
				return state.featuredSalesVisible
			},
		},
		actions: {
			async requestTokenForItemSku({ commit, rootState, dispatch }, id: string) {
				try {
					const results = await getXsollaTokenForItemSKURequest(rootState.user.authentication.token, { id })
					logger.info(`[Xsolla] Token associated with item id [${id}] was successfully retrieved: ${results.token}`)
					commit('itemSkuTokenUpdated', results.token)
					dispatch('popXsollaStore')
				} catch (err) {
					if (err.isAxiosError) {
						const code = err.response.data.errorCode
						commit('errors/setErrorDescriptions', { title: code, description: code + ': try refreshing the page' }, { root: true })
					}
					commit('errors/setActiveError', undefined, { root: true })
				}
			},
			async requestTokenForSubscriptionSku({ commit, rootState, dispatch }, sku: string) {
				const results = await getXsollaTokenForSubscriptionSKURequest(rootState.user.authentication.token, { sku })
				logger.info(`[Xsolla] Token associated with subscription SKU [${sku}] was successfully retrieved: ${results.token}`)
				commit('subscriptionSkuTokenUpdated', results.token)
				dispatch('popXsollaStore', true)
			},
			async popXsollaStore({ state, dispatch }, isSubscription: boolean) {
				let initOptions

				if (isSubscription) {
					initOptions = {
						access_token: state.subscriptionSkuToken,
						sandbox: true,
					}
				} else {
					initOptions = {
						access_token: state.itemSkuToken,
						sandbox: true,
					}
				}

				//TODO2: convert these to only the appropriate events
				//TODO2: this leaks event handlers every time the store is popped, .off() on event handlers, or only register these once on module-init
				const eventTypes = ['init', 'open', 'load', 'close', 'status', 'status-invoice', 'status-delivering', 'status-troubled', 'user-country']
				for (const eventType of eventTypes) {
					// @ts-ignore
					XPayStationWidget.on(eventType, function (event, data) {
						console.log(eventType, { event, data })
					})
				}

				// @ts-ignore
				XPayStationWidget.on('status-done', (_: any, __: any) => {
					NengiClient.getInstance().sendCommand(new RequestStashUpdate())
					dispatch('getMtx')
				})

				// @ts-ignore
				XPayStationWidget.init(initOptions)
				// @ts-ignore
				XPayStationWidget.open()
			},
			async purchaseMtx({ state, dispatch }, mtxDetails) {
				if (mtxDetails.sku) {
					// Special case handling for stash expansions when at max capacity
					if (mtxDetails.sku === 'more-stash-slots') {
						const currentIncreases = UI.getInstance().store.getters['itemContainers/stashMtxIncreases']
						if (currentIncreases >= MAX_MTX_INCREASES) {
							showGenericInfoPromptUI('Max capacity', ['You have already purchased the maximum amount of stash extensions'])
							return
						}
					}

					let costString
					if (mtxDetails.prismScales) {
						costString = `${mtxDetails.prismScales.toLocaleString()} prism scales`
					} else if (mtxDetails.coins) {
						costString = `${mtxDetails.coins.toLocaleString()} coins`
					}

					showGenericYesNoUI('Are you sure?', `Spend ${costString}?`, undefined, 'Purchase', 'Cancel', () => {
						state.usingStore = true
						NengiClient.getInstance().sendCommand(new PurchaseMtx(mtxDetails.sku))
					})
				}

				// if (isSubscription) {
				// 	console.log('requesting subscription token...')
				// 	await this.requestTokenForSubscriptionSku(mtxDetails.sku)
				// }
			},
			async getMtx({ state, rootState, commit }) {
				const newMtx = await getMtxRequest(rootState.user.authentication.token)
				commit('cosmetics/setOwnedCosmetics', newMtx, { root: true })
			},
			async getMtxOffers({ state, rootState, commit }) {
				let offers
				if(rootState.user.userType === 'registered') {
					offers = await getMtxOffersRequest(rootState.user.authentication.token)
				} else {
					offers = await getAnonymousMtxOffersRequest(rootState.user.id)
				}

				commit('setMtxOffers', offers)
			},
			async openForteExchange({ state, rootState }) {
				const accountLinked = rootState.user.isForteExchangeLinked
				if (accountLinked) {
					let loginUrl
					if (process.env.NODE_ENV === 'loot-prod') {
						loginUrl = 'https://exchange.forte.io/portal/login'
					} else {
						loginUrl = 'https://gamma.playerexchange.io/portal/login'
					}
					window.open(loginUrl)
				} else {
					NengiClient.getInstance().sendCommand(new RequestForteExchangeUrl())
				}
			},
			async gotForteExchangeURL({ state }, message: GotExchangeURLMessage) {
				if (message.success) {
					window.open(message.url)
				} else {
					showGenericInfoPromptUI('Error', ['Something went wrong creating a link to the exchange, try again later.'])
				}
			},
		},
		mutations: {
			closeItemDetailsPrompt(state: any) {
				state.itemDetailsPromptVisible = false
				state.selectedItem = null
				state.selectedMtxItem = null
			},
			itemSkuTokenUpdated(state: any, token) {
				state.itemSkuToken = token
			},
			subscriptionSkuTokenUpdated(state: any, token) {
				state.subscriptionSkuToken = token
			},
			setSelectedMtxItem(state, value?: any) {
				state.selectedMtxItem = value
			},
			showItemDetails(state, param) {
				state.selectedItem = param
				if (state.itemDetailsPromptVisible === false) {
					state.itemDetailsPromptVisible = true
				}
			},
			setMtxOffers(state, offers) {
				for (let i = 0; i < offers.length; ++i) {
					const item = offers[i]
					if (item.offerEnd) {
						item.endMoment = moment(item.offerEnd).utc()
					}
				}
				state.mtxOffers = offers
			},
			setUsingStore(state, using) {
				state.usingStore = using
			},
		},
	}
}
