import { EnemyAI, FightingTriggers, IdleBehaviours, FightingBehaviours, LeashTriggers, LeashingBehaviours, AttackTypes, DeadBehaviours, ShotLeadPrecision, EnemyType, EnemyTargetingStyles } from '../../shared/ai-types'
import { ModType, BurstFireModes } from '../../../projectiles/shared/projectile-types'
import { SpineDataName, EnemyBipedSkinType } from '../../../models-animations/shared/spine-config'
import { degToRad } from '../../../utils/math'
import { Colors } from '../../../utils/colors'
import { EnemyDefaults } from './enemy-defaults'
import { ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { ParticleEffectType } from '../../../engine/shared/game-data/particle-config'
import { BOSS_HEALTH_MULTIPLIER, ENEMY_DEFAULT_HEALTH } from '../../../engine/shared/game-data/enemy-formulas'
import { ENEMY_NAMES } from './enemy-names'

const boss: EnemyAI = {
	name: ENEMY_NAMES.BOSS,
	type: EnemyType.BOSS,
	appearance: {
		asset: SpineDataName.BIPED_ENEMY,
		skin: EnemyBipedSkinType.SKELEMAGE,
		color: [0.5, 0.5, 0.5],
		scale: 2,
	},
	general: {
		enemyLevel: 1,
		experienceWhenKilled: 20,
	},
	baseAttributes: {
		...EnemyDefaults.baseAttributes,
		colliders: [
			{
				type: ColliderType.Circle,
				radius: 80,
				position: [0, -80],
				traits: ColliderTraits.BlockMovementAndProjectile,
			},
			{
				type: ColliderType.Circle,
				radius: 80,
				position: [0, -160],
				traits: ColliderTraits.BlockProjectile,
			},
		],
		maxHealth: ENEMY_DEFAULT_HEALTH * BOSS_HEALTH_MULTIPLIER * 1.25, // Boss health
		attackRate: 550,
		physicalDamage: 12,
		healthRegen: 1,
		movementSpeed: 180,

		lootDropProbability: 100,
		lootDropQuantity: 7,
		lootDropRarity: 8,
	},
	soundEffects: {
		attack: 'Projectile_Fireball',
		impact: 'SFX_Impact_Enemy_Flesh',
	},
	states: {
		idling: {
			behaviour: IdleBehaviours.STATIONARY,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			transitionToFighting: {
				trigger: FightingTriggers.AGGRESSIVE_PROXIMITY,
				radius: 1100,
			},
			aggroOnHit: true,
		},
		fighting: {
			behaviour: FightingBehaviours.STRAFE_AND_ATTACK,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			attackType: AttackTypes.PROJECTILE,
			engagementMaxDistance: 1600,
			engagementMinDistance: 0,
			movementMaxDistance: 600,
			movementMinDistance: 0,
			attackOptions: [
				{
					...EnemyDefaults.projectileConfig,
					projectileCount: 6,
					spreadAngle: degToRad(360),
					speed: 200,
					colliderRadius: 15,
					lifespanInSeconds: 5,
					modifiers: [{ modType: ModType.STRAIGHT }],
					burstCount: 8,
					burstDelay: 0.1,
					burstMode: BurstFireModes.SWEEPING,
					burstAngle: degToRad(90),
					color: Colors.pink,
					bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_BOSS,
					bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_TRAIL_BOSS,
					muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
				},
				{
					...EnemyDefaults.projectileConfig,
					speed: 700,
					lifespanInSeconds: 2,
					burstCount: 12,
					burstDelay: 0.1,
					burstMode: BurstFireModes.TRACKING,
					burstAngle: degToRad(10),
					color: Colors.green,
					bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_BOSS,
					bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_TRAIL_BOSS,
					muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
				},
			],
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 0.1,
			transitionToLeashing: {
				trigger: LeashTriggers.RADIUS_EXCEEDED,
				radius: 1500,
			},
		},
		leashing: {
			behaviour: LeashingBehaviours.MOVE,
			speedMultiplier: 4,
			decelerateMultiplier: 0,
		},
		fleeing: {},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 0.2,
		},
	},
}

export default boss
