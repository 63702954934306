























import { mapGetters, mapActions, mapState } from 'vuex'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import ListItemContainer from '../reusable-components/item-containers/list-item-container.vue'

export default {
	name: 'GeneralStoreUpdated',
	components: {
		MainPanel,
		ListItemContainer,
	},
	props: {
		panelIdentifier: {
			type: String,
			required: false,
			default: 'generalStore',
		},
	},
	computed: {
		...mapState('generalStore', ['currentlyInteractingWithPOIId', 'isPurchasing']),
		...mapGetters('hud', ['coinBalance']),
		...mapGetters('inGame', ['activePanel']),
		...mapGetters('generalStore', ['getStoreContents', 'getStoreContentsRaw']),
	},
	methods: {
		...mapActions('inGame', ['closeActivePanel']),
		...mapActions('generalStore', ['attemptPurchaseProduct']),
		disablePurchase(itemIndex) {
			const item = this.getStoreContentsRaw[itemIndex]
			if (!item) {
				return true
			}
			return !item.available || (item.price > 0 && item.price > this.coinBalance) || item.stocks[0] < 1
		},
		purchaseProduct(itemIndex) {
			this.attemptPurchaseProduct({ productId: itemIndex, poiId: this.currentlyInteractingWithPOIId })
		},
	},
}
