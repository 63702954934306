enum EnemyDensity {
	NONE,
	LIGHT,
	MEDIUM,
	HEAVY,
	ULTRA_HEAVY,
}

const BasicEnemyDensityCounts = {}
BasicEnemyDensityCounts[EnemyDensity.NONE] = 0
BasicEnemyDensityCounts[EnemyDensity.LIGHT] = 100
BasicEnemyDensityCounts[EnemyDensity.MEDIUM] = 120
BasicEnemyDensityCounts[EnemyDensity.HEAVY] = 160
BasicEnemyDensityCounts[EnemyDensity.ULTRA_HEAVY] = 200

const ChampionEnemyDensityCounts = {}
ChampionEnemyDensityCounts[EnemyDensity.NONE] = 0
ChampionEnemyDensityCounts[EnemyDensity.LIGHT] = 4
ChampionEnemyDensityCounts[EnemyDensity.MEDIUM] = 6
ChampionEnemyDensityCounts[EnemyDensity.HEAVY] = 8
ChampionEnemyDensityCounts[EnemyDensity.ULTRA_HEAVY] = 10

const SpawnPointDensityCounts = {}
SpawnPointDensityCounts[EnemyDensity.NONE] = 0
SpawnPointDensityCounts[EnemyDensity.LIGHT] = 1
SpawnPointDensityCounts[EnemyDensity.MEDIUM] = 2
SpawnPointDensityCounts[EnemyDensity.HEAVY] = 3
SpawnPointDensityCounts[EnemyDensity.ULTRA_HEAVY] = 4

const CHUNK_SIZE = 500

export enum EnemySpawnPackRadius {
	TIGHT = CHUNK_SIZE * 3.2,
	NORMAL = CHUNK_SIZE * 3.6,
	WIDE = CHUNK_SIZE * 4.0,
}

const ENEMY_SPAWN_OVERLAP_HORIZONTAL = 1.85
const ENEMY_SPAWN_OVERLAP_VERTICAL = ENEMY_SPAWN_OVERLAP_HORIZONTAL * 0.62162162

export enum EnemySpawnOverlapHorizontal {
	SUPER_OVERLAPPING = 0.8 * ENEMY_SPAWN_OVERLAP_HORIZONTAL,
	OVERLAPPING = 0.85 * ENEMY_SPAWN_OVERLAP_HORIZONTAL,
	TIGHT = 0.9 * ENEMY_SPAWN_OVERLAP_HORIZONTAL,
	NORMAL = ENEMY_SPAWN_OVERLAP_HORIZONTAL,
	SPACED = 1.1 * ENEMY_SPAWN_OVERLAP_HORIZONTAL,
}

export enum EnemySpawnOverlapVertical {
	SUPER_OVERLAPPING = 0.8 * ENEMY_SPAWN_OVERLAP_VERTICAL,
	OVERLAPPING = 0.85 * ENEMY_SPAWN_OVERLAP_VERTICAL,
	TIGHT = 0.9 * ENEMY_SPAWN_OVERLAP_VERTICAL,
	NORMAL = ENEMY_SPAWN_OVERLAP_VERTICAL,
	SPACED = 1.1 * ENEMY_SPAWN_OVERLAP_VERTICAL,
}

export default EnemyDensity
export { EnemyDensity, BasicEnemyDensityCounts, ChampionEnemyDensityCounts, SpawnPointDensityCounts }
