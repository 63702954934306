import { EnemyAI, FightingTriggers, IdleBehaviours, FightingBehaviours, LeashTriggers, LeashingBehaviours, AttackTypes, DeadBehaviours, ShotLeadPrecision, EnemyType, EnemyTargetingStyles } from '../../shared/ai-types'
import { ModType, ModValueType } from '../../../projectiles/shared/projectile-types'

import { SpineDataName, EnemyBipedSkinType } from '../../../models-animations/shared/spine-config'
import { degToRad } from '../../../utils/math'
import { Colors } from '../../../utils/colors'
import { EnemyDefaults } from './enemy-defaults'
import { ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { ParticleEffectType } from '../../../engine/shared/game-data/particle-config'
import { ENEMY_DEFAULT_HEALTH } from '../../../engine/shared/game-data/enemy-formulas'
import { ENEMY_NAMES } from './enemy-names'

const spawner: EnemyAI = {
	name: ENEMY_NAMES.SPAWNER,
	type: EnemyType.BASIC,
	appearance: {
		asset: SpineDataName.BIPED_ENEMY,
		skin: EnemyBipedSkinType.SKELEMAGE,
		color: [1, 0.75, 0.5],
	},
	general: {
		enemyLevel: 1,
		experienceWhenKilled: 3,
	},
	baseAttributes: {
		...EnemyDefaults.baseAttributes,
		colliders: [
			{
				type: ColliderType.Circle,
				radius: 50,
				position: [0, -30],
				traits: ColliderTraits.BlockMovementAndProjectile,
			},
		],
		maxHealth: ENEMY_DEFAULT_HEALTH * 2,
		movementSpeed: 0,
		attackRate: 200,
		lootDropProbability: 2,
	},
	soundEffects: {
		attack: 'Projectile_Blip',
		impact: 'SFX_Impact_Enemy_Bone',
	},
	states: {
		idling: {
			behaviour: IdleBehaviours.STATIONARY,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			transitionToFighting: {
				trigger: FightingTriggers.AGGRESSIVE_PROXIMITY,
				radius: 1200,
			},
			aggroOnHit: true,
		},
		fighting: {
			behaviour: FightingBehaviours.SPAWNER,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			attackType: AttackTypes.PROJECTILE,
			engagementMaxDistance: 1200,
			engagementMinDistance: 0,
			movementMaxDistance: 1200,
			movementMinDistance: 0,
			attackOptions: [
				{
					...EnemyDefaults.projectileConfig,
					projectileCount: 1,
					spreadAngle: degToRad(90),
					speed: 350,
					colliderRadius: 15,
					lifespanInSeconds: 5,
					modifiers: [{ modType: ModType.WAVE, amplitude: { modValueType: ModValueType.VALUE, value: 10 }, period: { modValueType: ModValueType.VALUE, value: 0.8 } }],
					burstCount: 0,
					color: Colors.pink,
					bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_SPAWNER,
					bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_TRAIL,
					muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
				},
			],
			shotLeadPrecision: ShotLeadPrecision.AVERAGE,
			visualAimLockSeconds: 0.5,
			transitionToLeashing: {
				trigger: LeashTriggers.NONE,
			},
			spawningRules: {
				...EnemyDefaults.spawningRules,
				spawnLimit: 3,
				spawnEnemies: [
					{ name: 'wasp', weight: 70 },
					{ name: 'mosquito', weight: 30 },
				],
				spawnRate: 6.5,
				spawnRadius: 160,
				killSpawnlingsWhenIDie: true,
			},
		},
		leashing: {
			behaviour: LeashingBehaviours.MOVE,
			speedMultiplier: 4,
			decelerateMultiplier: 0,
		},
		fleeing: {},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 0.2,
		},
	},
}

export default spawner
