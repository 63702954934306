import nengi from 'nengi'

class WormHornResultMessage {
	static protocol = {
		wormSentSuccesfully: nengi.Boolean,
	}

	wormSentSuccesfully: boolean

	constructor(wormSentSuccesfully: boolean) {
		this.wormSentSuccesfully = wormSentSuccesfully
	}
}

export default WormHornResultMessage
