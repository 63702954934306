import { AnimationTrack } from '../../models-animations/shared/animation-track'
import { SpineDataName } from '../../models-animations/shared/spine-config'
import { AnimationList } from '../../models-animations/client/play-animation'

export interface SpineConfig {
	asset: string
	animation: AnimationTrack | AnimationList
	animationSpeed?: number
	zOffset?: number
	scale?: number
}

export interface GenericEntityConfig {
	name: string
	texture?: string
	spine?: SpineConfig | SpineConfig[]
	pfx?: string
	lifetime?: number
	maxEntities?: number
}

const entityConfigMap = new Map()

function addByName(entityConfig: GenericEntityConfig) {
	entityConfigMap.set(entityConfig.name, entityConfig)
}

addByName({
	name: 'corpse',
	// TODO2: We'll probably want all splatters to be in an atlas but for we'll just use a hard coded splatter
	texture: 'skele_mage_death_splat',
	lifetime: 5,
	maxEntities: 50,
})


addByName({
	name: 'fungi-boss-goop',
	spine: [
		{
			asset: SpineDataName.PRISM_AOE,
			animation: [AnimationTrack.SPAWN, 1, AnimationTrack.EXPLODE, AnimationTrack.DAMAGE_IDLE],
			animationSpeed: 1,
			zOffset: -10000,
		},
		{
			asset: SpineDataName.FUNGI_AOE,
			animation: [AnimationTrack.SPAWN, 1, AnimationTrack.EXPLODE, AnimationTrack.DAMAGE_IDLE],
			animationSpeed: 1,
			zOffset: 10,
		},
	],
	lifetime: 2,
	maxEntities: 50,
})

addByName({
	name: 'shambling-mound-aoe',
	spine: [
		{
			asset: SpineDataName.PRISM_AOE,
			animation: [AnimationTrack.SPAWN, 1, AnimationTrack.EXPLODE, AnimationTrack.DAMAGE_IDLE],
			animationSpeed: 1,
			zOffset: -10000,
		},
		{
			asset: SpineDataName.SHAMBLING_MOUND_AOE,
			animation: [AnimationTrack.SPAWN, 1, AnimationTrack.EXPLODE, AnimationTrack.DAMAGE_IDLE],
			animationSpeed: 1,
			zOffset: -10000,
		},
	],
	lifetime: 2,
	maxEntities: 50,
})

addByName({
	name: 'beach-skele-aoe',
	spine: [
		{
			asset: SpineDataName.PRISM_AOE,
			animation: [AnimationTrack.SPAWN, 1, AnimationTrack.EXPLODE, AnimationTrack.DAMAGE_IDLE],
			animationSpeed: 1,
			zOffset: -10000,
		},
		{
			asset: SpineDataName.SKELE_AOE,
			animation: [AnimationTrack.SPAWN, 1, AnimationTrack.EXPLODE, AnimationTrack.DAMAGE_IDLE],
			animationSpeed: 1,
			zOffset: -10000,
		},
	],
	lifetime: 2,
	maxEntities: 50,
})
addByName({
	name: 'prism-golem-aoe',
	spine: [
		{
			asset: SpineDataName.PRISM_AOE,
			animation: [AnimationTrack.SPAWN, 1, AnimationTrack.EXPLODE, AnimationTrack.DAMAGE_IDLE],
			animationSpeed: 1,
			zOffset: -50,
		},
		{
			asset: SpineDataName.GOLEM_PRISM_AOE,
			animation: [AnimationTrack.SPAWN, 1, AnimationTrack.EXPLODE, AnimationTrack.DAMAGE_IDLE],
			animationSpeed: 1,
			zOffset: 0,
		},
	],
	lifetime: 2,
	maxEntities: 50,
})
addByName({
	name: 'prism-aoe-md',
	spine: [
		{
			asset: SpineDataName.PRISM_AOE_MD,
			animation: [AnimationTrack.SPAWN, 1, AnimationTrack.EXPLODE, AnimationTrack.DAMAGE_IDLE],
			animationSpeed: 1,
			zOffset: -100,
		},
		{
			asset: SpineDataName.GOLEM_PRISM_AOE,
			animation: [AnimationTrack.SPAWN, 1, AnimationTrack.EXPLODE, AnimationTrack.DAMAGE_IDLE],
			animationSpeed: 1,
			zOffset: 0,
		},
	],
	lifetime: 2,
	maxEntities: 50,
})
addByName({
	name: 'prism-aoe-lg',
	spine: [
		{
			asset: SpineDataName.PRISM_AOE_LG,
			animation: [AnimationTrack.SPAWN, 1, AnimationTrack.EXPLODE, AnimationTrack.DAMAGE_IDLE],
			animationSpeed: 1,
			zOffset: -200,
		},
		{
			asset: SpineDataName.GOLEM_PRISM_AOE,
			animation: [AnimationTrack.SPAWN, 1, AnimationTrack.EXPLODE, AnimationTrack.DAMAGE_IDLE],
			animationSpeed: 1,
			zOffset: 0,
		},
	],
	lifetime: 2,
	maxEntities: 50,
})
addByName({
	name: 'highlands-golem-aoe',
	spine: [
		{
			asset: SpineDataName.PRISM_AOE,
			animation: [AnimationTrack.SPAWN, 1, AnimationTrack.EXPLODE, AnimationTrack.DAMAGE_IDLE],
			animationSpeed: 1,
			zOffset: -10000,
		},
		{
			asset: SpineDataName.GOLEM_HIGHLANDS_AOE,
			animation: [AnimationTrack.SPAWN, 1, AnimationTrack.EXPLODE, AnimationTrack.DAMAGE_IDLE],
			animationSpeed: 1,
			zOffset: -10000,
		},
	],
	lifetime: 2,
	maxEntities: 50,
})

addByName({
	name: 'prism-aoe',
	spine: [
		{
			asset: SpineDataName.PRISM_AOE,
			animation: [AnimationTrack.SPAWN, 1, AnimationTrack.EXPLODE, AnimationTrack.DAMAGE_IDLE],
			animationSpeed: 1,
			zOffset: -100,
		},
	],
	lifetime: 5,
	maxEntities: 50,
})

addByName({
	name: 'prism-boss-aoe-easy',
	spine: [
		{
			asset: SpineDataName.PRISM_AOE,
			animation: [AnimationTrack.SPAWN, 0.5, AnimationTrack.IDLE],
			animationSpeed: 1,
			zOffset: -200,
			scale: 1.15
		},
	],
	lifetime: 1.4,
	maxEntities: 50,
})
addByName({
	name: 'prism-boss-aoe-medium',
	spine: [
		{
			asset: SpineDataName.PRISM_AOE,
			animation: [AnimationTrack.SPAWN, 0.5, AnimationTrack.IDLE],
			animationSpeed: 1,
			zOffset: -200,
			scale: 1.3
		},
	],
	lifetime: 1.4,
	maxEntities: 50,
})
addByName({
	name: 'prism-boss-aoe-hard',
	spine: [
		{
			asset: SpineDataName.PRISM_AOE,
			animation: [AnimationTrack.SPAWN, 0.5, AnimationTrack.IDLE],
			animationSpeed: 1,
			zOffset: -200,
			scale: 1.45
		},
	],
	lifetime: 1.4,
	maxEntities: 50,
})

addByName({
	name: 'highlands-aoe',
	spine: [
		{
			asset: SpineDataName.HIGHLANDS_AOE,
			animation: [AnimationTrack.SPAWN, 1, AnimationTrack.EXPLODE, AnimationTrack.DAMAGE_IDLE],
			animationSpeed: 1,
			zOffset: -10000,
		},
	],
	lifetime: 5,
	maxEntities: 50,
})

addByName({
	name: 'highlands-aoe-telegraph',
	spine: [
		{
			asset: SpineDataName.PRISM_AOE,
			animation: [AnimationTrack.SPAWN, 1, AnimationTrack.EXPLODE, AnimationTrack.DAMAGE_IDLE],
			animationSpeed: 1,
			zOffset: -10000,
		},
	],
	lifetime: 5,
	maxEntities: 50,
})

addByName({
	name: 'alter-spawn-animation',
	spine: [
		{
			asset: SpineDataName.ALTER_SPAWN,
			animation: AnimationTrack.SPAWN,
			animationSpeed: 1,
			zOffset: -200,
		}
	],
	lifetime: 5,
	maxEntities: 50,
})

export function getEntityConfig(name: string) {
	const config = entityConfigMap.get(name)
	console.assert(config, `no entity config named: ${name}`)
	return config
}
