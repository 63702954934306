import nengi from 'nengi'
import { radians } from '../../utils/primitive-types'

class WeaponFiredMessage {
	static protocol = {
		x: { type: nengi.Float32 },
		y: { type: nengi.Float32 },
		aimAngle: { type: nengi.Float32 },
		owningEntityId: { type: nengi.Number },
		attackCooldown: { type: nengi.Number },
		muzzleFlairX: { type: nengi.Float32 },
		muzzleFlairY: { type: nengi.Float32 },
		muzzleFlairParticleEffect: { type: nengi.Number },
		success: { type: nengi.Boolean },
	}

	x: number
	y: number
	aimAngle: radians
	owningEntityId: number
	attackCooldown: number
	muzzleFlairParticleEffect: number
	muzzleFlairX: number
	muzzleFlairY: number
	success: boolean

	constructor(params) {
		this.x = params.x
		this.y = params.y

		this.aimAngle = params.aimAngle

		this.owningEntityId = params.owningEntityId
		this.attackCooldown = params.attackCooldown

		this.muzzleFlairX = params.muzzleFlairX
		this.muzzleFlairY = params.muzzleFlairY
		this.muzzleFlairParticleEffect = params.muzzleFlairParticleEffect

		this.success = params.success
	}
}

export default WeaponFiredMessage
