import { removeAllClientCollidables } from '../../collision/client/client-collisions'
import moment from 'moment'
import { UI } from '../../ui/ui'
import logger from '../../utils/client-logger'
import { sleepFor } from '../../utils/timing'
import { ConnectionPayload } from '../shared/connection-payload'
import { WorldDifficulty } from '../shared/game-data/world-difficulty'
import { GameModeType } from '../shared/game-mode-type'
import Time from '../shared/time'
import { GameClient } from './game-client'
import { anonymousJoinParty, joinParty } from '../../utils/api/polo-requests.client'

export let modeStartCount = 0
const gameClientRegistry: Map<number, GameClient> = new Map()

export const endGame = () => {
	const originalCanvasElement = document.querySelector('#main-canvas') as HTMLCanvasElement
	forceLoseWebGLContext(originalCanvasElement)

	removeAllClientCollidables()

	UI.getInstance().emitEvent('inGame/setPanelSwitchable', true)
	UI.getInstance().emitAsyncEvent('inGame/closeActivePanel')
	for (const [gameClientId, gameClient] of gameClientRegistry.entries()) {
		gameClient.shutdown()
		gameClientRegistry.delete(gameClientId)
	}

	originalCanvasElement?.remove()
	const canvasParent = document.querySelector('#game')
	const newCanvasElement = document.createElement('canvas')
	newCanvasElement.id = 'main-canvas'
	canvasParent.appendChild(newCanvasElement)
}

export const countdownToDeparture = async () => {
	UI.getInstance().emitAsyncEvent('inGame/closeActivePanel')
	UI.getInstance().emitEvent('hud/showPartyDepartureCountdown')
	UI.getInstance().emitEvent('boatLaunch/disableDifficultyOptions')
	UI.getInstance().emitEvent('party/setReadyToDepart', true)
	UI.getInstance().emitEvent('hud/updatePartyDepartureCountdown', 5)
	setTimeout(() => {
		UI.getInstance().emitEvent('hud/updatePartyDepartureCountdown', 4)
	}, 1000)
	setTimeout(() => {
		UI.getInstance().emitEvent('hud/updatePartyDepartureCountdown', 3)
	}, 2000)
	setTimeout(() => {
		UI.getInstance().emitEvent('hud/updatePartyDepartureCountdown', 2)
	}, 3000)
	setTimeout(() => {
		UI.getInstance().emitEvent('hud/updatePartyDepartureCountdown', 1)
	}, 4000)
	setTimeout(() => {
		UI.getInstance().emitEvent('hud/updatePartyDepartureCountdown', 0)
	}, 5000)

	await sleepFor(6000)

	return true
}

export const startGame = async (gameMode: GameModeType, connectionPayload: ConnectionPayload, partyId: string, predeterminedGameAddress: string = '') => {
	logger.info(`starting mode count: `, ++modeStartCount)

	const params = new URLSearchParams(location.search)
	if (params.has('partyId')) {
		params.delete('partyId')
		window.history.replaceState({}, document.title, `?${params}`)
	}

	//printEventCounts()

	Time.timeElapsedSinceModeStartInMs = 0
	Time.timeElapsedSinceModeStartInSeconds = 0

	const uiInst = UI.getInstance()

	uiInst.setUIMode('loading')
	const region = uiInst.store.getters['user/region']

	let partyJoinedResponse = null
	if(uiInst.store.getters['mainMenu/isJoiningParty']) {
		const joiningPartyId = uiInst.store.getters['mainMenu/joiningPartyId']
		if(uiInst.store.getters['user/userType'] === 'registered') {
			const authToken = uiInst.store.getters['user/authToken']
			partyJoinedResponse = await joinParty(authToken.token, joiningPartyId, region)
		} else {
			const userId = uiInst.store.getters['user/userId']
			partyJoinedResponse = await anonymousJoinParty(userId, joiningPartyId, region)
		}
		
		if(partyJoinedResponse.joined) {
			predeterminedGameAddress = partyJoinedResponse.server.dns
			uiInst.emitAsyncEvent('ftueManager/completeFlagsFromDeepLinkParty')
		} else {
			uiInst.emitEvent('party/setFailedToJoinDeepLink', { failed: true, alreadyPartied: partyJoinedResponse.alreadyPartied })
		}

		uiInst.emitEvent('mainMenu/setNotJoiningParty')
	}

	if (gameMode === GameModeType.Hub && !(partyJoinedResponse && partyJoinedResponse.joined)) {
		const doneFirstTutorialPhase = uiInst.store.getters['ftueManager/getFlag']('openedWormMailNPC')
		const inAGW = uiInst.store.getters['activeGameWindows/isInAGW'](moment().utc())
		if (!doneFirstTutorialPhase && inAGW) {
			gameMode = GameModeType.Adventure
			//Safety setting world difficulty b/c QA had a bug they could not repo of getting put in world difficulty 2 in ftue
			connectionPayload.worldDifficulty = WorldDifficulty.Normal
		}
		connectionPayload.pressureLoadout = {}
	}

	if (gameMode === GameModeType.Hub) {
		// fix for: https://sculpin.atlassian.net/browse/SOTI-1843
		//  biomeIndex was set to non-zero if using a key. Hub should always be biomeIndex 0.
		connectionPayload.biomeIndex = 0
	}

	const gameClientId = Math.getRandomInt(1, 10000)
	try {
		gameClientRegistry.set(gameClientId, new GameClient(gameMode, connectionPayload, partyId, region, predeterminedGameAddress))
		uiInst.emitEvent('boatLaunch/enableSoulCycleOptions')
		uiInst.emitEvent('hud/hidePartyDepartureCountdown')
		uiInst.emitEvent('emptyStatesOnLoad')
	} catch (err) {
		console.error(err)
		if (err.message.includes('WebGL')) {
			uiInst.emitEvent('mainMenu/showStartupError', 'Your browser does not support WebGL. WebGL is needed to run this game. We recommend Google Chrome for the best experience.')
		} else {
			uiInst.emitEvent('mainMenu/showStartupError', 'Something went wrong when starting the game. Sorry, try again later. If this is a recurring issue contact support.')
		}
	}
}

function forceLoseWebGLContext(canvas: HTMLCanvasElement) {
	if (canvas) {
		const context = canvas.getContext('webgl2')

		if (context) {
			const extension = context.getExtension('WEBGL_lose_context')

			if (extension) {
				extension.loseContext()
			} else {
				logger.error('no WEBGL_lose_context extension during forceLoseWebGLContext')
			}
		} else {
			logger.error('no context during forceLoseWebGLContext')
		}
	} else {
		logger.error('no canvas during forceLoseWebGLContext')
	}
}
