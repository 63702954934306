import { Vector } from 'sat'
import { BuffIdentifier } from '../../../../buffs/shared/buff.shared'
import { simpleAnimation_addAnimation, simpleAnimation_addPropertyAnimation } from '../../../../utils/simple-animation-system'
import { EmbossFilter } from '@pixi/filter-emboss'
import { AdvancedBloomFilter, BulgePinchFilter, RGBSplitFilter, AdjustmentFilter, MotionBlurFilter, GlowFilter } from 'pixi-filters'
import { AssetIdentifier } from '../../../../asset-manager/client/asset-manager'
import DevToolsManager from '../../../../ui/dev-tools/dev-tools-manager'
import { NengiClient } from '../../../client/nengi-client'
import ChatCommand from '../../../../chat/shared/chat-command'
import { mapToRange } from '../../../../utils/math'

type FilterFunc = (duration) => PIXI.Filter

const buffConfig = {
	stone: {
		embossStrength: 0.5,
		stone: () => NengiClient.getInstance().sendCommand(new ChatCommand('/buffme buffSkillStoneForm')),
	},
	berserk: {
		berserk: () => NengiClient.getInstance().sendCommand(new ChatCommand('/buffme buffSkillBerserk')),
	},
	overshield: {
		overshield: () => NengiClient.getInstance().sendCommand(new ChatCommand('/buffme buffSkillOvershield')),
	},
	movementSpeed: {
		movementSpeed: () => NengiClient.getInstance().sendCommand(new ChatCommand('/buffme buffSkillMovementSpeed')),
	},
	poison: {
		wobblyMag: 5,
		rgbSplitMag: 5,
		poison: () => NengiClient.getInstance().sendCommand(new ChatCommand('/buffme buffPoison')),
	},
	shock: {
		shock: () => NengiClient.getInstance().sendCommand(new ChatCommand('/buffme buffShock')),
	},
	stun: {
		bloom: {
			threshold: 0.9,
			bloomScale: 0.8,
			brightness: 0.7,
			blur: 11,
			quality: 4,
			pixelSize: 1,
			resolution: PIXI.settings.FILTER_RESOLUTION,
		},
		stun: () => NengiClient.getInstance().sendCommand(new ChatCommand('/buffme buffStun')),
	},
	ignite: {
		red: 1.5,
		green: 0.8,
		blue: 0.8,
		pulseMag: 0.3,
		pulseFreq: 1,
		ignite: () => NengiClient.getInstance().sendCommand(new ChatCommand('/buffme buffIgnite')),
	},
	berserking: {
		red: 1.5,
		green: 0.8,
		blue: 0.8,
		pulseMag: 0,
		pulseFreq: 0,
	},
	chill: {
		chill: () => NengiClient.getInstance().sendCommand(new ChatCommand('/buffme buffChill')),
	},
	cold: {
		screen: {
			red: 0.8,
			green: 1,
			blue: 2,
			pulseMag: 0.3,
			pulseFreq: 1,
		},
		entity: {
			red: 2,
			green: 2,
			blue: 2,
			pulseMag: 0.3,
			pulseFreq: 1,
		},
		freeze: () => NengiClient.getInstance().sendCommand(new ChatCommand('/buffme buffCold')),
	},
	bleed: {
		screen: {
			red: 1.5,
			green: 0.5,
			blue: 0.5,
			pulseMag: 0.3,
			pulseFreq: 1,
		},
		entity: {
			red: 0.5,
			green: 0,
			blue: 0,
			pulseMag: 0.3,
			pulseFreq: 1,
		},
		bulgeStrength: 0.1,
		bleed: () => NengiClient.getInstance().sendCommand(new ChatCommand('/buffme buffBleed')),
	},
	confusion: {
		wobblyMag: 20,
		rgbSplitMag: 10,
		confuse: () => NengiClient.getInstance().sendCommand(new ChatCommand('/buffme buffConfusion')),
	},
}
if (process.env.NODE_ENV !== 'beta' && process.env.NODE_ENV !== 'loot-prod') {
	DevToolsManager.getInstance().addObjectByName('buffConfig', buffConfig)
	//DevToolsManager.getInstance().setDebugObject(buffConfig)
}

export class BuffClientDefinition {
	identifier: BuffIdentifier

	pfxConfig?: PfxConfig | PfxConfig[]
	screenFilters?: FilterFunc[]
	entityFilters?: FilterFunc[]
	replaceFeetPfx?: boolean
	duration?: number
}

export const BuffClientData: Map<BuffIdentifier, BuffClientDefinition> = new Map()

export interface PfxConfig {
	pfx: AssetIdentifier
	boneWIP?: string // name of bone to attach to (doesn't work, WIP)
	scale?: (time: number) => number
	offset?: (time: number) => Vector
	renderBehind?: boolean
}

export interface FilterConfig {
	filter: string
}

const ClientBuffs: BuffClientDefinition[] = [
	{
		identifier: BuffIdentifier.HermitCrabBlock,
		pfxConfig: {
			pfx: 'shield',
			scale: () => 0.5,
			offset: () => new Vector(0, -25),
		},
	},
	// TODO2 - Update this to be whatever the crystal spiders buff should be
	{
		identifier: BuffIdentifier.CrystalSpiderBlock,
		pfxConfig: {
			pfx: 'shield',
			scale: () => 0.5,
			offset: () => new Vector(0, -25),
		},
	},
	{
		identifier: BuffIdentifier.SkillBerserk,
		pfxConfig: [
			{
				pfx: 'status-berserking',
				offset: () => new Vector(0, 0),
				renderBehind: true,
			},
		],
		entityFilters: [entityBerserkingFilter, glowFilter],
	},
	{
		identifier: BuffIdentifier.SkillOverchargedShot,
		pfxConfig: [
			{
				pfx: 'status-overcharged-shot',
				offset: () => new Vector(0, 0),
				renderBehind: true,
			},
		],
		entityFilters: [overchargeFilter],
	},
	{
		identifier: BuffIdentifier.SkillBattleCry,
		pfxConfig: [
			{
				pfx: 'status-battle-cry',
				offset: () => new Vector(0, 0),
				renderBehind: true,
			},
		],
		entityFilters: [battlecryFilter],
	},
	{
		identifier: BuffIdentifier.SkillSickeningNova,
		pfxConfig: [
			{
				pfx: 'status-sickening-nova',
				offset: () => new Vector(0, 0),
				renderBehind: true,
			},
		],
		entityFilters: [weakenedFilter],
	},
	{
		identifier: BuffIdentifier.SkillOvershield,
		pfxConfig: [
			{
				pfx: 'status-overshield',
				offset: () => new Vector(0, -80),
				renderBehind: false,
			},
		],
	},
	{
		identifier: BuffIdentifier.SkillMovementSpeed,
		pfxConfig: [
			{
				pfx: 'quick-feet',
				offset: () => new Vector(0, 0),
				renderBehind: true,
			},
		],
		entityFilters: [highSpeedFilter],
		replaceFeetPfx: true,
	},
	{
		identifier: BuffIdentifier.OnKillMovementSpeed,
		pfxConfig: [
			{
				pfx: 'quick-feet',
				offset: () => new Vector(0, 0),
				renderBehind: true,
			},
		],
		entityFilters: [lowSpeedFilter],
		replaceFeetPfx: true,
	},
	{
		identifier: BuffIdentifier.OnShieldBreakMovementSpeed,
		pfxConfig: [
			{
				pfx: 'quick-feet',
				offset: () => new Vector(0, 0),
				renderBehind: true,
			},
		],
		entityFilters: [lowSpeedFilter],
		replaceFeetPfx: true,
	},
	{
		identifier: BuffIdentifier.SkillStoneForm,
		pfxConfig: {
			pfx: 'status-stoneform',
			offset: () => new Vector(0, -50),
		},
		entityFilters: [stoneFilter],
	},
	{
		identifier: BuffIdentifier.OutpostPoisonApple,
		pfxConfig: [
			{
				pfx: 'poison-apple-status',
				offset: () => new Vector(0, -120),
			},
		],
	},
	// {
	// 	identifier: BuffIdentifier.Shardarmorup,
	// 	pfxConfig: {
	// 		pfx: 'status-shardarmorup',
	// 		offset: () => new Vector(0, -50),
	// 	},
	// 	entityFilters: [shardarmorupFilter],
	// },
	// {
	// 	identifier: BuffIdentifier.HealingBurst,
	// 	pfxConfig: {
	// 		pfx: 'status-healingburst',
	// 		offset: () => new Vector(0, -50),
	// 	},
	//// I'd really like these healingGlowFilter to fade over duration but have no idea how
	// 	entityFilters: [healingGlowFilter],
	// },
	// {
	// 	identifier: BuffIdentifier.Healing,
	// 	pfxConfig: {
	// 		pfx: 'status-healing',
	// 		offset: () => new Vector(0, -50),
	// 	},
	// 	entityFilters: [healingGlowFilter],
	// },
	// {
	// 	identifier: BuffIdentifier.Armorbreak,
	// 	pfxConfig: {
	// 		pfx: 'status-armorbreak',
	// 		offset: () => new Vector(0, -50),
	// 	},
	// 	entityFilters: [armorbreakFilter],
	// },
	// {
	// 	identifier: BuffIdentifier.Weakened,
	// 	pfxConfig: {
	// 		pfx: 'status-weakened',
	// 		offset: () => new Vector(0, -50),
	// 	},
	// 	entityFilters: [weakenedFilter, confusionWobblyFilter],
	// },
	{
		identifier: BuffIdentifier.Confusion,
		screenFilters: [confusionSplitRgbFilter, confusionWobblyFilter],
	},
	{
		identifier: BuffIdentifier.Poison,
		pfxConfig: {
			pfx: 'status-poison',
			offset: () => new Vector(0, -50),
		},
		screenFilters: [poisonSplitRgbFilter, poisonWobblyFilter],
		entityFilters: [greenFilter],
	},
	{
		identifier: BuffIdentifier.Shock,
		pfxConfig: {
			pfx: 'status-shock',
			offset: () => new Vector(0, -50),
		},
	},
	{
		identifier: BuffIdentifier.Stun,
		pfxConfig: {
			pfx: 'status-stun',
			offset: () => new Vector(0, -50),
		},
		screenFilters: [stunAdvancedBloomFilter],
		entityFilters: [blueFilter],
	},
	{
		identifier: BuffIdentifier.Ignite,
		pfxConfig: {
			pfx: 'status-ignite',
			offset: () => new Vector(0, -50),
		},
		screenFilters: [screenFireFilter, bulgePinchFilter],
		entityFilters: [entityFireFilter],
		duration: 2,
	},
	{
		identifier: BuffIdentifier.Chill,
		pfxConfig: {
			pfx: 'status-chill',
			offset: () => new Vector(0, -50),
		},
	},
	{
		identifier: BuffIdentifier.Chilled,
		pfxConfig: {
			pfx: 'status-chill',
			offset: () => new Vector(0, -50),
		},
		screenFilters: [screenIceFilter],
		entityFilters: [entityIceFilter],
		duration: 2,
	},
	{
		identifier: BuffIdentifier.Bleed,
		pfxConfig: {
			pfx: 'status-bleed',
			offset: () => new Vector(0, -50),
		},
		duration: 6,
	},
	{
		identifier: BuffIdentifier.EnemyPrismBossGustWeakness,
		screenFilters: [confusionWobblyFilter], // placeholder?
	},
	{
		identifier: BuffIdentifier.EnemyPrismBossCocoon,
		pfxConfig: {
			pfx: 'boss-prism-crystalcocoon',
			offset: () => new Vector(0, -50),
		},
	},
	{
		identifier: BuffIdentifier.EnemyHighlandsBossOnyxShield,
		pfxConfig: {
			pfx: 'boss-highlands-sheild',
			scale: () => 1,
			offset: () => new Vector(0, -200),
		},
	},
	{
		identifier: BuffIdentifier.EnemyHighlandsBossOnyxShieldExplode,
		pfxConfig: {
			pfx: 'boss-highlands-sheild-stutter',
			scale: () => 1, // placeholder to show that it's about to pop
			offset: () => new Vector(0, -200),
		},
	},
	{
		identifier: BuffIdentifier.EnemyHighlandsBossInvulnerable,
		pfxConfig: {
			pfx: 'boss-highlands-sheild',
			offset: () => new Vector(0, -200),
		},
	},
	{
		identifier: BuffIdentifier.EnemyHighlandsBossPetrifyingWave,
		entityFilters: [petrifyFilter],
		screenFilters: [stunAdvancedBloomFilter],
	},
	{
		identifier: BuffIdentifier.EnemyForestBossHeal,
		pfxConfig: {
			pfx: 'boss-forest-healingspring',
			offset: () => new Vector(0, -50),
		},
	},
	{
		identifier: BuffIdentifier.EnemyForestBossHealBrutal,
		pfxConfig: {
			pfx: 'boss-forest-healingspring',
			offset: () => new Vector(0, -50),
		},
	},
	{
		identifier: BuffIdentifier.EnemyShardGuard,
		pfxConfig: {
			pfx: 'status-overshield',
			offset: () => new Vector(0, -20),
		},
	},
	{
		identifier: BuffIdentifier.BoneSpiritHealing,
		pfxConfig: {
			pfx: 'boss-forest-healingspring',
			offset: () => new Vector(0, -50),
		},
	},
]

// for tweaking of these, play with:
// https://pixijs.io/pixi-filters/tools/demo/

function stoneFilter() {
	const filter = new AdjustmentFilter()
	filter.saturation = 0
	return filter
}
function armorbreakFilter() {
	const colorMatrix = new PIXI.filters.ColorMatrixFilter()
	const matrix = [0.6, 0, 0, 0, 0, 0, 0.6, 0, 0, 0, 0, 0, 0.8, 0, 0, 0, 0, 0, 1, 0]
	colorMatrix._loadMatrix(matrix, false)
	return colorMatrix
}
function shardarmorupFilter() {
	const filter = new GlowFilter()
	filter.color = 0x16b7ff
	filter.outerStrength = 3
	return filter
}
function weakenedFilter() {
	const colorMatrix = new PIXI.filters.ColorMatrixFilter()
	const matrix = [0.2, 0, 0, 0, 0, 0, 0.2, 0, 0, 0, 0, 0, 0.8, 0, 0, 0, 0, 0, 1, 0]
	colorMatrix._loadMatrix(matrix, false)
	return colorMatrix
}

function petrifyFilter() {
	const filter = new EmbossFilter(buffConfig.stone.embossStrength)
	return filter
}

function highSpeedFilter() {
	const filter = new MotionBlurFilter([5, 0], 5, 5)
	filter.velocity.x = 15
	return filter
}

function lowSpeedFilter() {
	const filter = new MotionBlurFilter([5, 0], 5, 5)
	filter.velocity.x = 5
	return filter
}

function glowFilter() {
	const filter = new GlowFilter()
	filter.color = 0xe60026
	filter.outerStrength = 3
	return filter
}
function battlecryFilter() {
	const filter = new GlowFilter()
	filter.color = 0xffea00
	filter.outerStrength = 3
	return filter
}
function overchargeFilter() {
	const filter = new GlowFilter()
	filter.color = 0x00f6ff
	filter.outerStrength = 3
	return filter
}
function healingGlowFilter() {
	const filter = new GlowFilter()
	filter.color = 0x5fffbd
	filter.outerStrength = 3
	return filter
}
function confusionWobblyFilter() {
	return wobblyFilter(buffConfig.confusion.wobblyMag)
}

function confusionSplitRgbFilter() {
	return rgbSplitFilter(buffConfig.confusion.rgbSplitMag)
}

function poisonWobblyFilter() {
	return wobblyFilter(buffConfig.poison.wobblyMag)
}

function poisonSplitRgbFilter() {
	return rgbSplitFilter(buffConfig.poison.rgbSplitMag)
}

function stunAdvancedBloomFilter() {
	return new AdvancedBloomFilter(buffConfig.stun.bloom)
	//http://localhost:1234/?connectTo=adventure&skipToGame=true&filter1=advanced-bloom&filterParams=%7B%22threshold%22:0.9,%20%22blur%22:11,%20%22brightness%22:0.7,%20%22bloomScale%22:0.8%7D
}

function bulgePinchFilter(duration: number) {
	const filter = new BulgePinchFilter()
	filter.radius = 500
	simpleAnimation_addAnimation(filter, (t) => {
		const pulse = inPulseOut(t, duration, 0, 1)
		filter.strength = (Math.sin(t) + 1) * pulse * 0.5 * buffConfig.bleed.bulgeStrength
		return 0
	})
	return filter
}

function rgbSplitFilter(magnitude: number) {
	const filter = new RGBSplitFilter()
	simpleAnimation_addAnimation(filter, (t) => {
		const f = filter as any

		const uniforms = f.uniformGroup.uniforms
		uniforms.red[0] = Math.sin(t) * magnitude
		uniforms.red[1] = Math.sin(t * 1.1) * magnitude
		uniforms.green[0] = Math.sin(t * 1.3) * magnitude
		uniforms.green[1] = Math.sin(t * 1.4) * magnitude
		uniforms.blue[0] = Math.sin(t * 1.5) * magnitude
		uniforms.blue[1] = Math.sin(t * 1.6) * magnitude
		return 1
	})
	return filter
}

function wobblyFilter(magnitude: number) {
	const displacementSprite = PIXI.Sprite.from('displacement_map')
	const filter = new PIXI.filters.DisplacementFilter(displacementSprite, magnitude)
	// not a fan of this, as removal of filter.scale is done elsewhere (handleBuffChange in buff.client.ts)
	simpleAnimation_addPropertyAnimation(filter.scale, 'x', (t) => Math.sin(t) * magnitude)
	simpleAnimation_addPropertyAnimation(filter.scale, 'y', (t) => Math.sin(t * 1.1 + 0.2) * magnitude)
	return filter
}

function screenFireFilter(duration: number) {
	return colorPulseFilter(buffConfig.ignite, duration)
}
function entityFireFilter(duration: number = 10) {
	return colorPulseFilter(buffConfig.ignite, duration)
}
function entityBerserkingFilter(duration: number = 10) {
	return colorPulseFilter(buffConfig.berserking, duration)
}

function screenIceFilter(duration: number) {
	return colorPulseFilter(buffConfig.cold.screen, duration)
}
function entityIceFilter(duration: number) {
	return colorPulseFilter(buffConfig.cold.entity, duration)
}

function screenBleedFilter(duration: number) {
	return colorPulseFilter(buffConfig.bleed.screen, duration)
}
function entityBleedFilter(duration: number) {
	return colorPulseFilter(buffConfig.bleed.entity, duration)
}

function colorPulseFilter(colorTarget: { red: number; green: number; blue: number; pulseMag; pulseFreq }, duration: number) {
	const filter = new PIXI.filters.ColorMatrixFilter()

	const matrix = [1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0]

	simpleAnimation_addAnimation(filter, (t) => {
		const pulse = inPulseOut(t, duration, colorTarget.pulseMag, colorTarget.pulseFreq)
		matrix[0] = mapToRange(pulse, 0, 1, 1, colorTarget.red)
		matrix[6] = mapToRange(pulse, 0, 1, 1, colorTarget.green)
		matrix[12] = mapToRange(pulse, 0, 1, 1, colorTarget.blue)
		//console.log(`pulse: ${matrix[0].toFixed(1)}, ${matrix[6].toFixed(1)}, ${matrix[12].toFixed(1)} `)
		return 0
	})

	filter._loadMatrix(matrix, false)
	return filter
}

function greenFilter() {
	const colorMatrix = new PIXI.filters.ColorMatrixFilter()

	const matrix = [0.5, 0, 0, 0, 0, 0, 1.5, 0, 0, 0, 0, 0, 0.5, 0, 0, 0, 0, 0, 1, 0]
	colorMatrix._loadMatrix(matrix, false)
	return colorMatrix
}

function blueFilter() {
	const colorMatrix = new PIXI.filters.ColorMatrixFilter()

	const matrix = [0.5, 0, 0, 0, 0, 0, 0.5, 0, 0, 0, 0, 0, 1.5, 0, 0, 0, 0, 0, 1, 0]
	colorMatrix._loadMatrix(matrix, false)
	return colorMatrix
}

ClientBuffs.forEach((buff) => {
	console.assert(!BuffClientData.get(buff.identifier), `duplicated identifier:${buff.identifier} in client side buff visual definitions`)
	BuffClientData.set(buff.identifier, buff)
})

function inPulseOut(t, duration, pulseMag, pulseFreq) {
	const s = mapToRange(t, 0, 0.5, 0, 1, true) * mapToRange(t, duration - 0.5, duration, 1, 0, true)
	return s + Math.sin(t * Math.PI * 2 * pulseFreq) * pulseMag * s
}
