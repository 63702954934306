import nengi from 'nengi'

export default class WormDoneSendingMessage {
	static protocol = {
		wormId: { type: nengi.Number },
		biomeIndex: { type: nengi.Number },
	}

	wormId: number
	biomeIndex: number

	constructor(wormId: number, biomeIndex: number) {
		this.wormId = wormId
		this.biomeIndex = biomeIndex
	}
}
