import { PLAYER_MOVEMENT_COLLIDER_INDEX } from "../../engine/shared/game-data/player"
import { ClientPlayer } from "../../player/client/player.client"
import { distanceVV } from "../../utils/math"
import { getClientColliderEntityCollidables, getClientPropCollidables } from "../client/client-collisions"
import { CircleCollider, ColliderTraits } from "../shared/colliders"
import { resolveCollidersColliders } from "./collision-routines"

export function playerCollision(player: ClientPlayer) {
	player.updateColliderPositions()

	const playerMovementCollider = player.colliders[PLAYER_MOVEMENT_COLLIDER_INDEX] as CircleCollider

	const prevPos = player.cspMovement.previousPosition.clone()
	const requestedPos = player.cspMovement.predictedPos.clone()

	// to prevent very fast players from clipping through thin props, iterate based on how far they've travelled
	const distanceThisFrame = distanceVV(prevPos, requestedPos)
	const iter = Math.ceil(distanceThisFrame / playerMovementCollider.r)

	if (player.noClip) {
		return
	}

	let propCollision = false
	const playerClientPos = player.cspMovement.predictedPos
	for (let i = 0; i < iter && !propCollision; i++) {
		const t = (i + 1) / iter
		playerClientPos.x = Math.lerp(prevPos.x, requestedPos.x, t)
		playerClientPos.y = Math.lerp(prevPos.y, requestedPos.y, t)
		player.updateColliderPositions()

		getClientPropCollidables().forEach((prop) => {
			if (!prop.colliders || prop.colliders.length === 0 || !prop.colliders[0]) return

			this.collisionsCheckedThisFrame++

			const offset = resolveCollidersColliders(player.colliders, prop.colliders, ColliderTraits.BlockMovement)

			if (offset) {
				playerClientPos.x = playerClientPos.x + offset.x
				playerClientPos.y = playerClientPos.y + offset.y
				if (offset.len2() > 1) {
					propCollision = true
				}
			}
		})

		// TODO1-ccd need to handle collider entities/enemies, perhaps projectiles, csp WIP
		// Collide players with misc colliders
		getClientColliderEntityCollidables().forEach((colliderEntity) => {
			if (player.ignoreColliders.includes(colliderEntity.nid)) {
				//this is going to be length 0 for 99.9% of the time, so shouldn't slow anything down
				return
			}

			this.collisionsCheckedThisFrame++

			const offset = resolveCollidersColliders(player.colliders, colliderEntity.colliders, ColliderTraits.BlockMovement)
			if (offset) {
				playerClientPos.x = playerClientPos.x + offset.x
				playerClientPos.y = playerClientPos.y + offset.y
				if (offset.len2() > 1) {
					propCollision = true
				}
			}
		})
	}

	player.cspMovement.previousPosition.x = player.cspMovement.predictedPos.x
	player.cspMovement.previousPosition.y = player.cspMovement.predictedPos.y
}