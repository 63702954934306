import nengi from 'nengi'

export default class TutorialFlagsMessage {
	static protocol = {
		flagsJson: { type: nengi.String },
	}

	flagsJson: string

	constructor(flagsJson: string) {
		this.flagsJson = flagsJson
	}
}
