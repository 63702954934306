import WeaponAugmentSubType from '../../loot/shared/weapon-augment-sub-type'
import WeaponSubType from '../../loot/shared/weapon-sub-type'

const CHARGE_BAR_AUGMENTS = [WeaponAugmentSubType.QuickCharge, WeaponAugmentSubType.FieryOvercharge]
const SPELLSWORD_ONLY_AUGMENTS = [WeaponAugmentSubType.DiffusionBeam, WeaponAugmentSubType.FocusedBeam, WeaponAugmentSubType.StarLaser]
const ATTACK_RATE_AUGMENTS = [WeaponAugmentSubType.FastAndWeak, WeaponAugmentSubType.SlowAndStrong]
const WAND_ONLY_AUGMENTS = [WeaponAugmentSubType.EnergyBooster]
const TRAJECTORY_AUGMENTS = [
	WeaponAugmentSubType.Accelerate,
	WeaponAugmentSubType.Deccelerate,
	WeaponAugmentSubType.RangeAdjustingAccelerate,
	WeaponAugmentSubType.Sine,
	WeaponAugmentSubType.Zigzag,
	WeaponAugmentSubType.StraightBoomerang,
	WeaponAugmentSubType.Circular,
	WeaponAugmentSubType.Orbit,
	WeaponAugmentSubType.Spiral,
	WeaponAugmentSubType.Looping,
	WeaponAugmentSubType.RangeAdjustingCircular,
	WeaponAugmentSubType.RangeAdjustingOrbit,
]
const PROJECTILE_AUGMENTS = [
	...TRAJECTORY_AUGMENTS,
	WeaponAugmentSubType.CreepingDeath,
	WeaponAugmentSubType.Pierce,
	WeaponAugmentSubType.TightenSpread,
	WeaponAugmentSubType.Sine,
	WeaponAugmentSubType.Zigzag,
	WeaponAugmentSubType.NarrowSpreadExtraProjectiles,
	WeaponAugmentSubType.WideSpreadExtraProjectiles,
	WeaponAugmentSubType.ClusterShot,
]

const forbiddenAugmentsByWeapon: { [k in WeaponSubType]: Set<WeaponAugmentSubType> } = {
	[WeaponSubType.Crossbow]: new Set([WeaponAugmentSubType.Flamethrower, WeaponAugmentSubType.StraightBoomerang, ...ATTACK_RATE_AUGMENTS, ...SPELLSWORD_ONLY_AUGMENTS, ...WAND_ONLY_AUGMENTS]),
	[WeaponSubType.Staff]: new Set([WeaponAugmentSubType.Flamethrower, ...ATTACK_RATE_AUGMENTS, ...SPELLSWORD_ONLY_AUGMENTS, ...WAND_ONLY_AUGMENTS]),
	[WeaponSubType.Scythe]: new Set([...CHARGE_BAR_AUGMENTS, ...SPELLSWORD_ONLY_AUGMENTS, ...WAND_ONLY_AUGMENTS]),
	[WeaponSubType.ArcaneFocus]: new Set([...CHARGE_BAR_AUGMENTS, ...SPELLSWORD_ONLY_AUGMENTS, ...WAND_ONLY_AUGMENTS]),
	[WeaponSubType.Wand]: new Set([...CHARGE_BAR_AUGMENTS, ...SPELLSWORD_ONLY_AUGMENTS]),
	[WeaponSubType.Spellsword]: new Set([WeaponAugmentSubType.SplashDamage, WeaponAugmentSubType.LightningBurst, ...PROJECTILE_AUGMENTS, ...CHARGE_BAR_AUGMENTS, ...WAND_ONLY_AUGMENTS]),
}

export function invalidAugmentForWeaponType(newAugment, weapon) {
	if ('itemSubType' in weapon) {
		return forbiddenAugmentsByWeapon[weapon.itemSubType]?.has(newAugment.itemSubType)
	} else {
		return true
	}
}
