import { EnemyAI, FightingTriggers, IdleBehaviours, FightingBehaviours, LeashTriggers, LeashingBehaviours, AttackTypes, DeadBehaviours, ShotLeadPrecision, EnemyType, EnemyTargetingStyles } from '../../shared/ai-types'
import { BurstFireModes, ModType, ModValueType, ProjectileTargetType } from '../../../projectiles/shared/projectile-types'
import { SpineDataName, EnemyBipedSkinType } from '../../../models-animations/shared/spine-config'
import { degToRad } from '../../../utils/math'
import { EnemyDefaults } from './enemy-defaults'
import { Colors } from '../../../utils/colors'
import { ParticleEffectType } from '../../../engine/shared/game-data/particle-config'
import { Vector } from 'sat'
import { ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { ENEMY_DEFAULT_HEALTH } from '../../../engine/shared/game-data/enemy-formulas'
import { Ability, AbilityType, AbilityTargets, AbilityTargetSelectionStyle, AbilitySelectionStyles } from '../action-types'
import { alwaysTrueCriteria } from './abilities/common-abilities'
import { AnimationTrack } from '../../../models-animations/shared/animation-track'
import { attackRatedAbilityList } from '../action-criteria/ability-helpers'
import { ENEMY_NAMES } from './enemy-names'
import { deepClone } from '../abilities.test'
import { worldDifficultyBrutalCriteria,  } from '../action-criteria/action-criteria-helpers'

const magicalAoeAbility: Ability = {
	debugName: 'magicalAoeAbility',
	abilityType: AbilityType.SPAWN_GROUND_HAZARD,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	durationInGameTicks: 40,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		colliderRadius: 100,
		speed: 0,
		lifespanInSeconds: 0.2, // how long the projectile lasts on the ground
		basePhysicalDamage: 125,
		damageMultiplier: 1.0,
		color: Colors.green,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		pierceCount: 999,
	},
	groundHazardRules: {
		spineAsset: 'beach-skele-aoe',
		pfx: 'death-explosion',
		pfxScale: 1.03,
		pfxSecondsOffset: 1.2, // how long before the PFX is created?
		projectileSecondsOffset: 1.3, // how long before it creates the projectile
		quantity: 1,
		limit: 2,
	},
}

export const magicalAoeAbilityBrutal = deepClone(magicalAoeAbility)
	magicalAoeAbilityBrutal.projectileConfig.colliderRadius = 120
	magicalAoeAbilityBrutal.groundHazardRules.pfxScale = 1.24


const longRangeAbility: Ability = {
	debugName: 'longRangeAbility',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		projectileCount: 1,
		speed: 300,
		colliderRadius: 40,
		lifespanInSeconds: 2,
		modifiers: [{ modType: ModType.ACCELERATION, value: { modValueType: ModValueType.LIFETIME, min: -2, max: 7 } }],
		burstCount: 0,
		color: Colors.purple,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_SKELETONBEACH,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_TRAIL,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
	},
	attackOffset: new Vector(0, 0),
	ignoreAngleOnWeaponOffset: true,
}

const sweepingAbility: Ability = {
	debugName: 'sweepingAbility',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		projectileCount: 1,
		spreadAngle: 0,
		speed: 200,
		colliderRadius: 20,
		lifespanInSeconds: 5,
		modifiers: [{ modType: ModType.ACCELERATION, value: { modValueType: ModValueType.LIFETIME, min: 0, max: 8 } }],
		baseIceDamage: 100,
		burstCount: 5,
		burstDelay: 0.15,
		burstMode: BurstFireModes.SWEEPING,
		burstAngle: degToRad(120),
		damageMultiplier: 0.8,
		color: Colors.purple,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_SKELEMAGE,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_ICE_TRAIL,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_ICE_SHOOT,
	},
}

const animationTimes = {
	[AnimationTrack.SHOOT]: 0.7,
}

const skeletonBeach: EnemyAI = {
	name: ENEMY_NAMES.SKELETON_BEACH,
	type: EnemyType.BASIC,
	appearance: {
		asset: SpineDataName.SKELE_BEACH,
		skin: EnemyBipedSkinType.DEFAULT,
		mixSettings: [],
	},
	general: {
		enemyLevel: 1,
		experienceWhenKilled: 1,
	},
	baseAttributes: {
		...EnemyDefaults.baseAttributes,
		physicalDamage: 0,
		chillChance: 0,
		maxHealth: ENEMY_DEFAULT_HEALTH,
		colliders: [
			{
				type: ColliderType.Ellipse,
				rX: 45,
				rY: 65,
				position: [0, -45],
				traits: ColliderTraits.BlockMovementAndProjectile,
			},
		],
	},
	soundEffects: {
		attack: 'Projectile_Blip',
		impact: 'SFX_Impact_Enemy_Generic',
	},
	states: {
		idling: {
			behaviour: IdleBehaviours.STATIONARY,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			transitionToFighting: {
				trigger: FightingTriggers.AGGRESSIVE_PROXIMITY,
				radius: 700,
			},
			aggroOnHit: true,
		},
		fighting: {
			behaviour: FightingBehaviours.CHASE_AND_ATTACK,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 5,
			attackType: AttackTypes.PROJECTILE,
			engagementMaxDistance: 650,
			engagementMinDistance: 0,
			movementMaxDistance: 650,
			movementMinDistance: 0,
			brain: {
				actions: [
					// Brutal patterns
					{
						priority: 1,
						actionCriteria: worldDifficultyBrutalCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
							abilityOptions: [
								//[5, playAnimationAbility(AnimationTrack.SHOOT, 3), 0, magicalAoeAbility, 60],
								attackRatedAbilityList(2, animationTimes, AnimationTrack.SHOOT, magicalAoeAbilityBrutal, 50),
								// attackRatedAbilityList(4, animationTimes, AnimationTrack.SHOOT, longRangeAbility, 40),
								attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, sweepingAbility, 40),
							],
						},
					},
					{
						priority: 2,
						actionCriteria: alwaysTrueCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
							abilityOptions: [
								//[5, playAnimationAbility(AnimationTrack.SHOOT, 3), 0, magicalAoeAbility, 60],
								attackRatedAbilityList(2, animationTimes, AnimationTrack.SHOOT, magicalAoeAbility, 60),
								// attackRatedAbilityList(4, animationTimes, AnimationTrack.SHOOT, longRangeAbility, 40),
								attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, sweepingAbility, 80),
							],
						},
					},
				],
			},
			attackOptions: [],
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 1,
			transitionToLeashing: {
				trigger: LeashTriggers.DISTANCE_TRAVELLED_OR_RADIUS_EXCEEDED,
				radius: 2000,
				distanceTravelled: 4000,
			},
			targetType: ProjectileTargetType.PLAYER
		},
		leashing: {
			behaviour: LeashingBehaviours.MOVE,
			speedMultiplier: 4,
		},
		fleeing: {},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 0.2,
		},
	},
}

export default skeletonBeach
