import nengi from 'nengi'
import { Bounds } from 'pixi.js'

export class WorldDataMessage {
	static protocol = {
		boundsMinX: nengi.Number,
		boundsMinY: nengi.Number,
		boundsMaxX: nengi.Number,
		boundsMaxY: nengi.Number,

		stampSprites_biome: { type: nengi.UInt3, indexType: nengi.UInt16 },
		stampSprites_x: { type: nengi.Int32, indexType: nengi.UInt16 },
		stampSprites_y: { type: nengi.Int16, indexType: nengi.UInt16 },
		stampSprites_index: { type: nengi.UInt12, indexType: nengi.UInt16 },
		stampSprites_rotation: { type: nengi.RotationFloat32, indexType: nengi.UInt16 },
		stampSprites_scale: { type: nengi.Float32, indexType: nengi.UInt16 },
		stampSprites_alwaysOnTop: { type: nengi.Boolean, indexType: nengi.UInt16 },
		stampSprites_id: { type: nengi.UInt16, indexType: nengi.UInt16 },

		blendSprites_biome1: { type: nengi.Int4, indexType: nengi.UInt16 },
		blendSprites_biome2: { type: nengi.Int4, indexType: nengi.UInt16 },
		blendSprites_idx: { type: nengi.Int8, indexType: nengi.UInt16 },
		blendSprites_x: { type: nengi.Int32, indexType: nengi.UInt16 },
		blendSprites_y: { type: nengi.Int16, indexType: nengi.UInt16 },
		blendSprites_rot: { type: nengi.RotationFloat32, indexType: nengi.UInt16 },

		cliffSprites: nengi.String,
		minX: nengi.Number,
		minY: nengi.Number,
		maxX: nengi.Number,
		maxY: nengi.Number,
	}

	boundsMinX: number
	boundsMinY: number
	boundsMaxX: number
	boundsMaxY: number

	/** if this is all the same value, we make it an array of 1 value for compression purposes */
	stampSprites_biome: number[]
	stampSprites_x: number[]
	stampSprites_y: number[]
	stampSprites_index: number[]
	stampSprites_rotation: number[]
	stampSprites_scale: number[]
	stampSprites_alwaysOnTop: boolean[]
	stampSprites_id: number[]

	blendSprites_biome1: number[]
	blendSprites_biome2: number[]
	blendSprites_idx: number[]
	blendSprites_x: number[]
	blendSprites_y: number[]
	blendSprites_rot: number[]

	cliffSprites: string

	minX: number
	minY: number
	maxX: number
	maxY: number

	constructor(bounds: Bounds, cliffSprites: string, minX: number, minY: number, maxX: number, maxY: number) {
		this.boundsMinX = bounds.minX
		this.boundsMinY = bounds.minY
		this.boundsMaxX = bounds.maxX
		this.boundsMaxY = bounds.maxY
		this.cliffSprites = cliffSprites
		this.minX = minX
		this.minY = minY
		this.maxX = maxX
		this.maxY = maxY
	}
}
