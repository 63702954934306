import nengi from 'nengi'

class PlayerStatsMessage {
	static protocol = {
		movementSpeed: { type: nengi.Number },
		cooldownSpeed: { type: nengi.Number },
		buffDuration: { type: nengi.Number },
		debuffPotency: { type: nengi.Number },

		physicalResist: { type: nengi.Number },
		fireResist: { type: nengi.Number },
		lightningResist: { type: nengi.Number },
		iceResist: { type: nengi.Number },
		poisonResist: { type: nengi.Number },

		shieldRegenDelay: { type: nengi.Number },
		shieldRegenRate: { type: nengi.Number },
	}

	movementSpeed: number
	cooldownSpeed: number
	buffDuration: number
	debuffPotency: number

	physicalResist: number
	fireResist: number
	lightningResist: number
	iceResist: number
	poisonResist: number

	shieldRegenDelay: number
	shieldRegenRate: number

	constructor(params) {
		for (const p in params) {
			if (p) {
				// if required by husky
				this[p] = params[p]
			}
		}
	}
}

export default PlayerStatsMessage
