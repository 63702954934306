import nengi from 'nengi'

class WaterBoundsMessage {
	static protocol = {
		north: nengi.Int32,
		south: nengi.Int32,
	}
	north: number
	south: number

	constructor(north, south) {
		this.north = north
		this.south = south
	}
}

export default WaterBoundsMessage
