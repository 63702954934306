import nengi from 'nengi'

export default class PingAtLocationMessage {
	static protocol = {
		pingerEntityId: { type: nengi.Number },
		pingerName: { type: nengi.String },
		x: { type: nengi.Number },
		y: { type: nengi.Number },
	}

	pingerEntityId: number
	pingerName: string
	x: number
	y: number

	constructor(pingerEntityId: number, pingerName: string, x: number, y: number) {
		this.pingerEntityId = pingerEntityId
		this.pingerName = pingerName
		this.x = x
		this.y = y
	}
}
