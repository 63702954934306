import logger from '../../utils/client-logger'
import { handleFatalError } from '../../utils/error'
import { loginRequest, registerRequest, heartbeatRequest, updateSettingsRequest, maintenanceModeStatusRequest, getAnonymousUserMetricsRequest, updateAnonymousSettingsRequest } from '../../utils/api/atlas-requests.client'
import { loadFromLocalStorage, saveToLocalStorage, removeFromLocalStorage } from '../../utils/local-storage'
import { createAnonymousUserRequest, anonymousUserDetailsRequest, meRequest, getUserMetricsRequest } from '../../utils/api/atlas-requests.client'
import Vue from 'vue'
import { uuid } from '../../utils/primitive-types'
import { debugConfig } from '../../engine/client/debug-config'
import { UI } from '../../ui/ui'
import { Audio } from '../../audio/client/audio'
import { loadPressureLoadoutFromLocalStorage } from '../../engine/client/pressure.client'
import { FactionShortName } from '../../factions/shared/faction-data'
import { GameClient } from '../../engine/client/game-client'
import { ClientPlayer } from '../../player/client/player.client'
import { NengiClient } from '../../engine/client/nengi-client'
import { PostCampaignDetailsCommand } from '../../analytics/shared/post-campaign-metrics-command'
import { getFactionScores } from '../../utils/api/plutus-requests.client'

const getInitialUserState = async (makeAnonIfNoAnon) => {
	const existingAuthToken = loadFromLocalStorage('auth-token')
	const existingAnonymousUserId = loadFromLocalStorage('anonymous-user-id')

	if (existingAuthToken === undefined) {
		logger.debug('User isn\'t signed in; check for existing anonymous user identifier... ')

		if (debugConfig.offline) {
			return {
				userType: 'anonymous',
				userDataFetching: false,
				id: '000-000',
				username: 'Fargus "offline" MacGee',
				preferences: {},
			}
		}

		if (existingAnonymousUserId === undefined) {
			logger.debug('No anonymous user identifier found')

			if(makeAnonIfNoAnon) {
				logger.debug('Making anonymous user')
				const createAnonymousUserResults = await createAnonymousUserRequest()

				const { id } = createAnonymousUserResults
				const metrics = await getAnonymousUserMetricsRequest(id, 'all-time')

				saveToLocalStorage('anonymous-user-id', id)

				logger.debug(`Created new anonymous user: ${id}`)

				return {
					userType: 'anonymous',
					...createAnonymousUserResults,
					metrics
				}
			} else {
				return {
					userType: 'none',
				}
			}
		} else {
			logger.debug('Existing anonymous user identifier found, dispatching anon user info call...')

			const anonymousUserDetailsResults = await anonymousUserDetailsRequest(existingAnonymousUserId)
			const metrics = await getAnonymousUserMetricsRequest(anonymousUserDetailsResults.id, 'all-time')

			logger.debug(`Got details for anonymous user: ${anonymousUserDetailsResults.id}`)

			return {
				userType: 'anonymous',
				...anonymousUserDetailsResults,
				metrics
			}
		}
	} else {
		logger.debug('User is signed in, dispatching login call...')
		const metrics = await getUserMetricsRequest(existingAuthToken, 'all-time')
		const meResults = await meRequest(existingAuthToken)

		const { token } = meResults.authentication

		saveToLocalStorage('auth-token', token)

		return {
			userType: 'registered',
			...meResults,
			metrics,
		}
	}
}

type UserType = 'registered' | 'anonymous' | 'none'
interface UserPreferences {
	bgmVolume: number
	sfxVolume: number
	showNameplates: boolean
	preferredLanguage: string
	showItemNames: boolean
	fullScreen: boolean
	region: string
	csp: boolean
	timestamps: boolean
}

interface UserEquippedSkin {
	slotId: string
	selectedSkin: string
}

interface UserProgression {
	maxBiomeDefeated: number
	maxWorldTierCompleted: number
}

interface RegisteredUserState {
	userType: UserType
	userDataFetching: boolean
	id: uuid
	username: string
	emailAddress: string
	authentication: {
		token: string
	}
	preferences: UserPreferences
	progression: UserProgression
	metrics: object
	emailError: string[]
	passwordError: string[]
	agreeToTermsError: string[]
	currentAPIError: string[]
	userNameError: string[]
	tutorialFlags: any
	equippedSkins: UserEquippedSkin[]
	firstTimeLogIn: boolean
	isForteExchangeLinked: boolean
	recaptchaV3Failed: boolean
	enableRecaptchaCheckbox: boolean
	activeGameWindows: any
	lastFreeMarketplaceDate: string
	faction: any
	factionScores: any
	lastWinningFaction: FactionShortName
	lastFactionWarScore: any
	equippedEmotes: any
	utmCampaign: string
	makingAnonUser: boolean
}

interface AnonymousUserState {
	userType: UserType
	userDataFetching: boolean
	id: uuid
	username: string
	preferences: UserPreferences
	emailError: string[]
	passwordError: string[]
	agreeToTermsError: string[]
	currentAPIError: string[]
	userNameError: string[]
	ageCheckError: string[]
	firstTimeLogIn: boolean
	isForteExchangeLinked: boolean
	recaptchaV3Failed: boolean
	enableRecaptchaCheckbox: boolean
	recaptchaV2Failed: boolean
	makingAnonUser: boolean
}

type UserModuleState = RegisteredUserState | AnonymousUserState

export const userUIState = () => {
	logger.debug('Initializing User store module')

	return {
		namespaced: true,
		state: {
			emailError: [],
			passwordError: [],
			agreeToTermsError: [],
			currentAPIError: [],
			userNameError: [],
			ageCheckError: [],
			userDataFetching: false,
			firstTimeLogIn: null,
			isForteExchangeLinked: false,
			recaptchaV3Failed: false,
			enableRecaptchaCheckbox: false,
			recaptchaV2Failed: false,
			makingAnonUser: false,
		} as UserModuleState,
		mutations: {
			userDataFetching: (state) => {
				state.userDataFetching = true
			},
			userDataNoLongerFetching: (state) => {
				state.userDataFetching = false
			},
			setText: (state) => {
				state.text = false
			},
			updateUserNameError(state, error) {
				state.userNameError.push(error)
			},
			updateEmailError(state, error) {
				state.emailError.push(error)
			},
			updatePasswordError(state, error) {
				state.passwordError.push(error)
			},
			updateAgreeToTermsError(state, error) {
				state.agreeToTermsError.push(error)
			},
			updateAgeCheckError(state, error) {
				state.ageCheckError.push(error)
			},
			resetUserErrors(state) {
				state.userNameError.splice(0, state.userNameError.length)
				state.emailError.splice(0, state.emailError.length)
				state.passwordError.splice(0, state.passwordError.length)
				state.agreeToTermsError.splice(0, state.agreeToTermsError.length)
				state.ageCheckError.splice(0, state.ageCheckError.length)
			},
			updateFirstTimeLogIn(state) {
				state.firstTimeLogIn = false
			},
			updateErrorsFromAtlas(state, errorStatusCode) {
				const atlasErrors = errorStatusCode.response.data.errorCode
				if (atlasErrors === 'missingRequiredFields') {
					state.missingRequiredField = 'Missing Required Fields'
				}
				if (atlasErrors === 'invalidUsernameLength') {
					state.userNameError.push('Your username must be between 3 and 14 characters in length.')
				}
				if (atlasErrors === 'invalidPasswordLength') {
					state.passwordError.push('Your Password must be between 10 and 64 characters in length.')
				}
				if (atlasErrors === 'passwordAndPasswordConfirmationDontMatch') {
					state.passwordError.push('Your password fields do not match.')
				}
				if (atlasErrors === 'emailAndEmailConfirmationDontMatch') {
					state.emailError.push('Your email fields do not match.')
				}
				if (atlasErrors === 'mustAgreeToTermsOfService') {
					state.agreeToTermsError.push('You must agree to the terms and conditions in order to sign up.')
				}
				if (atlasErrors === 'invalidEmailFormat') {
					state.emailError.push('Incorrect Email Format')
				}
				if (atlasErrors === 'emailAddressAlreadyTaken') {
					state.emailError.push('This email address is already taken')
				}
				if (atlasErrors === 'usernameAlreadyTaken') {
					state.userNameError.push('This username is already taken')
				}
				if (atlasErrors === 'emailDoesntSeemReal') {
					state.emailError.push('Error with email address')
				}
				if (atlasErrors === 'noMatchingUserFoundForProvidedEmailAddress') {
					state.emailError.push('No user with this Email Address Found')
				}
				if (atlasErrors === 'invalidPassword') {
					state.passwordError.push('Invalid Password')
				}
				if (atlasErrors === 'lowRecaptchaV3Score') {
					state.recaptchaV3Failed = true
					state.enableRecaptchaCheckbox = true
				}
				if (atlasErrors === 'recaptchaV2TestFailed') {
					state.recaptchaV2Failed = true
					state.enableRecaptchaCheckbox = true
				}
			},
			updateBGMVolume(state: UserModuleState, newVal) {
				state.preferences.bgmVolume = newVal
				Audio.getInstance().setMasterBGMVolume(newVal / 100)
			},
			changeShowNameplates(state: UserModuleState) {
				state.preferences.showNameplates = !state.preferences.showNameplates
				const clientEntities = GameClient.getInstance().entities
				clientEntities.forEach((clientEntity) => {
					if (clientEntity instanceof ClientPlayer) {
						clientEntity.updateNameplateText(clientEntity.name)
					}
				})
			},
			updateSFXVolume(state: UserModuleState, newVal) {
				state.preferences.sfxVolume = newVal
				Audio.getInstance().setMasterSFXVolume(newVal / 100)
			},
			updateFullScreen(state: UserModuleState, newVal) {
				state.preferences.fullScreen = newVal
			},
			updateRegion(state: UserModuleState, newVal) {
				state.preferences.region = newVal
			},
			updateCsp(state: UserModuleState, newVal: boolean) {
				if (state.preferences) {
					state.preferences.csp = newVal
					debugConfig.csp = newVal
				}
			},
			updateTimestamps(state: UserModuleState, newVal) {
				if (state.preferences) {
					state.preferences.timestamps = newVal
				}

			},
			setUserRegistered(state, { username, userId, authentication }) {
				Vue.set(state, 'userType', 'registered')
				Vue.set(state, 'username', username)
				Vue.set(state, 'id', userId)
				Vue.set(state, 'authentication', authentication)
				Vue.set(state, 'metrics', {})
				Vue.set(state, 'seasonMetrics', {})
			},
			updateRecaptchaCheckboxFlag(state, newVal) {
				state.enableRecaptchaCheckbox = newVal
			},
			setUserFakeRegistered(state) {
				Vue.set(state, 'userType', 'registered')
			},
		},
		getters: {
			userId: (state) => {
				return state.id
			},
			userType: (state) => {
				return state.userType
			},
			userDataFetching: (state) => {
				return state.userDataFetching
			},
			username: (state) => {
				return state.username
			},
			preferences(state) {
				return state.preferences
			},
			bgmVolume(state) {
				if (state.preferences) {
					return state.preferences.bgmVolume
				}
				return 1
			},
			sfxVolume(state) {
				if (state.preferences) {
					return state.preferences.sfxVolume
				}
				return 1
			},
			showNameplates(state) {
				if (state.preferences) {
					return state.preferences.showNameplates
				}
				return true
			},
			region(state) {
				if (state.preferences) {
					return state.preferences.region
				}
				return 'na'
			},
			csp(state: UserModuleState) {
				if (state.preferences) {
					return state.preferences.csp
				}
				return false
			},
			getUserNameError(state) {
				return state.userNameError
			},
			getEmailError(state) {
				return state.emailError
			},
			getPasswordError(state) {
				return state.passwordError
			},
			getAgreeToTermsError(state) {
				return state.agreeToTermsError
			},
			getAgeErrors(state) {
				return state.ageCheckError
			},
			metrics(state) {
				return state.metrics
			},
			seasonMetrics(state) {
				return state.seasonMetrics
			},
			firstTimeLogIn(state) {
				return state.firstTimeLogIn
			},
			isCspEnabled(state: UserModuleState) {
				if (state.preferences) {
					return state.preferences.csp
				}
				return false
			},
			timestamps(state: UserModuleState) {
				if (state.preferences) {
					return state.preferences.timestamps
				}
				return false
			},
			getRecaptchaV3Failed(state) {
				return state.recaptchaV3Failed
			},
			getRecaptchaCheckboxFlag(state) {
				return state.enableRecaptchaCheckbox
			},
			getRecaptchaV2Failed(state) {
				return state.recaptchaV2Failed
			},
			authToken(state) {
				return state.authentication
			}
		},
		actions: {
			async fetchInitialUserState({ commit, state, rootState, dispatch, rootGetters }, makeAnonIfNoAnon) {
				const urlParams = window.location.search

				if (urlParams !== '') {
					const paramsCheck = metricParameters.some((param) => urlParams.includes(param))
					if (paramsCheck) {
						saveToLocalStorage('campaign-data', urlParams)
					}
				}

				try {
					commit('userDataFetching')

					const maintenanceStatusApi = await maintenanceModeStatusRequest()
					UI.getInstance().emitEvent('updateMaintenanceModeStatus', maintenanceStatusApi.maintenanceStatus)

					const maintenanceMode = rootState.maintenanceMode

					if (maintenanceMode === false) {
						const initialUserState: UserModuleState = await getInitialUserState(makeAnonIfNoAnon)
						logger.info('Fetched initial user state:')
						if (process.env.NODE_ENV !== 'beta' && process.env.NODE_ENV !== 'loot-prod') {
							logger.info(JSON.stringify(initialUserState, null, 2))
						}

						const searchParams = new URLSearchParams(window.location.href.split('?')[1])

						const { username, userType, preferences, equippedSkins, id, authentication, metrics,
							tutorialFlags, isForteExchangeLinked, activeGameWindows, lastFreeMarketplaceDate,
							faction, factionScores, lastFactionWarScore, lastWinningFaction, equippedEmotes, utmCampaign } = initialUserState as RegisteredUserState
						
						Vue.set(state, 'userType', userType)

						if (searchParams.has('verify-email')) {
							UI.getInstance().emitAsyncEvent('emailVerification/verifyEmail')
							UI.getInstance().emitEvent('mainMenu/changeMainMenuScreen', 'verify-email')
						} else if (!searchParams.has('reset-password')  && userType === 'registered') {
							UI.getInstance().emitEvent('mainMenu/changeMainMenuScreen', 'loggedIn')
						}

						if(userType === 'none') {
							commit('userDataNoLongerFetching')
							return
						}

						const userPref: UserPreferences = preferences
						Vue.set(state, 'username', username)
						Vue.set(state, 'preferences', preferences)
						Vue.set(state, 'id', id)
						Vue.set(state, 'authentication', authentication)
						Vue.set(state, 'metrics', metrics)
						Vue.set(state, 'seasonMetrics', {})

						const scores = await getFactionScores()

						commit('factions/setInitialState', { affiliated: faction, lastWinningFaction, factionScores: scores, lastFactionWarScore }, { root: true })

						const pressureLoadout = loadPressureLoadoutFromLocalStorage(id)
						UI.getInstance().store.commit('boatLaunch/setInitialLoadout', pressureLoadout, { root: true })

						if ((!equippedSkins || equippedSkins.length === 0) && userType === 'registered') {
							Vue.set(state, 'firstTimeLogIn', true)
						}

						state.isForteExchangeLinked = isForteExchangeLinked
						commit('ftueManager/loadAllFlags', tutorialFlags.sotiTutorialFlags, { root: true })
						if(equippedSkins) {
							commit('cosmetics/setEquippedCosmetics', equippedSkins, { root: true })
						}
						commit('activeGameWindows/setActiveGameWindows', activeGameWindows, { root: true })
						commit('marketplaceUpdated/setLastFreeMarketplaceDate', lastFreeMarketplaceDate, { root: true })

						if (equippedEmotes && equippedEmotes.equipped_emotes) {
							UI.getInstance().emitEvent('emotes/setEquippedEmotes', equippedEmotes.equipped_emotes)
						}						

						if(utmCampaign && userType === 'registered'){
							removeFromLocalStorage('campaign-data')
						}

						if (userPref) {
							//set preferences
							const audioInst = Audio.getInstance()
							audioInst.setMasterBGMVolume(userPref.bgmVolume / 100)
							audioInst.setMasterSFXVolume(userPref.sfxVolume / 100)

							// if(userPref.fullScreen) {
							// 	commit('toggleFullScreen', undefined, {root: true})
							// }
							debugConfig.csp = debugConfig.csp || userPref.csp
						}
					}

					commit('userDataNoLongerFetching')
				} catch (e) {
					logger.error('Caught error in fetchInitialUserState', e)
					removeFromLocalStorage('auth-token')
					removeFromLocalStorage('anonymous-user-id')
					window.location.reload()
				}
			},
			async createAnonymousUserThenEnterQueue({state, dispatch, commit}) {
				if(state.userType === 'none') {
					state.makingAnonUser = true
					try {
						await dispatch('fetchInitialUserState', true)
						dispatch('loginQueue/enterQueue', undefined, {root: true})
					} catch(err) {
						console.error("Error when making anonymous user " + err)
					}
					state.makingAnonUser = false
				}
			},
			async initClientHeartbeat({ dispatch, state }) {
				try {
					await heartbeatRequest(state.authentication.token)
					setTimeout(async () => {
						await heartbeatRequest(state.authentication.token)
						dispatch('initClientHeartbeat')
					}, 60000)
				} catch (e) {
					logger.error('Error occurred when attempting to run heartbeat', e)
					handleFatalError(e)
				}
			},
			async logout({ commit }) {
				try {
					removeFromLocalStorage('auth-token')
					window.location.href = '/'
				} catch (e) {
					commit('userDataNoLongerFetching')
					logger.error('Error occurred when attempting to log out', e)
					handleFatalError(e)
				}
			},
			async submitLogin({ commit }, loginPayload) {
				try {
					commit('userDataFetching')
					const apiResults = await loginRequest(loginPayload)
					saveToLocalStorage('auth-token', apiResults.authentication.token)
					removeFromLocalStorage('anonymous-user-id')
					window.location.reload(true)
				} catch (e) {
					commit('userDataNoLongerFetching')
					logger.error('Error occurred when attempting to submit login', e)
					commit('updateErrorsFromAtlas', e)
					handleFatalError(e)
				}
			},
			async submitRegister({ commit }, registerPayload) {
				try {
					commit('userDataFetching')
					const apiResults = await registerRequest(registerPayload)
					logger.debug(apiResults)
					saveToLocalStorage('auth-token', apiResults.authentication.token)
					removeFromLocalStorage('anonymous-user-id')
					window.location.reload(true)
				} catch (e) {
					commit('userDataNoLongerFetching')
					commit('updateErrorsFromAtlas', e)
					logger.error('Error occurred when attempting to submit registration', e)
					handleFatalError(e)
				}
			},
			async updateUserPreferences({ state }) {
				if(state.userType === 'registered') {
					const auth = state.authentication.token
					const result = await updateSettingsRequest(auth, state.preferences)
				} else {
					const result = await updateAnonymousSettingsRequest(state.id, state.preferences)
				}
			},
			async getAllTimeMetrics({ state }) {
				if (state.userType === 'anonymous') {
					return
				}

				const auth = state.authentication.token
				const allTimeMetrics = await getUserMetricsRequest(auth, `all-time`)
				state.metrics = allTimeMetrics
			},
			async getSeasonMetrics({ state }) {
				if (state.userType === 'anonymous') {
					return
				}

				const auth = state.authentication.token
				const seasonMetrics = await getUserMetricsRequest(auth, `season-1`)
				state.seasonMetrics = seasonMetrics
			},
			async submitCampaignMetrics({ state }) {
				const savedCampaignData = loadFromLocalStorage('campaign-data')
				const campaignParams = new URLSearchParams(savedCampaignData)

				if (savedCampaignData) {
					const formattedCampaignData = {
						utm_source: '',
						utm_medium: '',
						utm_campaign: '',
						utm_term: '',
						utm_content: '',
					}

					metricParameters.forEach((param, index) => {
						const paramKey = param
						if (campaignParams.has(paramKey)) {
							formattedCampaignData[paramKey] = campaignParams.get(paramKey)
						}
					})

					NengiClient.getInstance().sendCommand(new PostCampaignDetailsCommand(formattedCampaignData))
				}
			},
		},
	}
}

export const metricParameters = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']
