<template>
	<div id="energy-bar">
		<div class="background" :class="{ 'dark-red-highlight': energy <= 0 }"></div>
		<div class="background" :class="{ 'red-highlight': energy <= 0 }"></div>
		<div class="energy-fill">
			<div class="fill" :style="{ width: `${energy}%` }"></div>
		</div>
		<div class="trim">
			<div class="gold-trim-left"></div>
			<div class="gold-trim-right"></div>
		</div>
	</div>
</template>

<script lang="js">
import { mapGetters } from 'vuex'

export default {
	name: 'EnergyBar',
	computed: {
		...mapGetters('hud', ['energy'])
	}
}
</script>

<style lang="less" scoped>
@keyframes red-dark-red-oscillate {
	0% {
		background-image: url('./assets/mana-bar-bg-red.png');
	}
	50% {
		background-image: url('./assets/mana-bar-bg-dark-red.png');
	}
}
#energy-bar {
	position: absolute;
	width: 376px;
	height: 40px;
	bottom: 50px;
	left: 50%;
	right: 50%;
	transform: translate(-50%, 0);

	@keyframes on-off {
		0% {
			opacity: 1;
		}
		50% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	.background {
		position: absolute;
		background-image: url('./assets/mana-bar-bg.png');
		width: 340px;
		height: 30px;
		top: 5px;
		left: 17px;

		&.red-highlight {
			animation: on-off 0.5s infinite linear;
			background-image: url('./assets/mana-bar-bg-red.png');
		}

		&.dark-red-highlight {
			background-image: url('./assets/mana-bar-bg-dark-red.png');
		}
	}

	.energy-fill {
		position: absolute;
		width: 306px;
		top: 7px;
		left: 33px;

		.fill {
			background-image: linear-gradient(to right, #29f8ff, #29ffe2);
			box-shadow: 0px 0px 20px 0px rgb(0, 228, 255);
			transition: width 100ms linear;
			height: 27px;
		}
	}

	.trim {
		position: absolute;
		background-image: url('./assets/mana-bar-trim.png');
		width: 376px;
		height: 40px;

		.gold-trim-left {
			position: absolute;
			background-image: url('./assets/mana-bar-embellishment.png');
			width: 44px;
			height: 30px;
			top: 5px;
			left: 15px;
		}

		.gold-trim-right {
			position: absolute;
			background-image: url('./assets/mana-bar-embellishment.png');
			width: 44px;
			height: 30px;
			transform: scaleX(-1);
			top: 5px;
			right: 18px;
		}
	}
}
</style>
