import nengi from 'nengi'

export default class JoinPartyCommand {
	static protocol = {
		partyId: nengi.String,
	}

	partyId: string

	constructor(partyId) {
		this.partyId = partyId
	}
}
