import { CameraShakeIntensities } from "../../../../engine/shared/camera.shared"
import { ParticleEffectType } from "../../../../engine/shared/game-data/particle-config"
import { AnimationTrack } from "../../../../models-animations/shared/animation-track"
import { ModType, BurstFireModes } from "../../../../projectiles/shared/projectile-types"
import { Colors } from "../../../../utils/colors"
import { degToRad } from "../../../../utils/math"
import { deepClone } from "../../abilities.test"
import { Ability, AbilityType, AbilityTargets, AbilityTargetSelectionStyle } from "../../action-types"
import { EnemyDefaults } from "../enemy-defaults"
import { ENEMY_NAMES } from "../enemy-names"
import { playAnimationAbility } from "./common-abilities"

export const submergeAbility: Ability = {
	debugName: 'submerge',
	abilityType: AbilityType.WAIT,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.MYSELF,
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
	playsAnimation: AnimationTrack.SUBMERGE,
}

export const submergeAbilityLong = deepClone(submergeAbility)
submergeAbilityLong.durationInGameTicks = 200

export const emergeAbility = playAnimationAbility(AnimationTrack.EMERGE, 10)

export const spawnTwoBlimpiesAbility: Ability = {
	debugName: 'spawnBlimp',
	abilityType: AbilityType.SPAWN_ENEMY,
	durationInGameTicks: 50,
	validTargets: AbilityTargets.RANDOM_POSITIONS_IN_SHAPE,
	shapeNames: 'middleground',
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
	spawningRules: {
		identifier: ENEMY_NAMES.BLIMPIE,
		quantity: 2,
		limit: 10,
		dropXP: false,
		dropLoot: false,
		spawnInTime: 1.5,
	},
	shakeCamera: CameraShakeIntensities.VERY_INTENSE,
}

export const spawnFourBlimpiesAbility: Ability = {
	debugName: 'spawnBlimp',
	abilityType: AbilityType.SPAWN_ENEMY,
	durationInGameTicks: 50,
	validTargets: AbilityTargets.RANDOM_POSITIONS_IN_SHAPE,
	shapeNames: 'middleground',
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
	spawningRules: {
		identifier: ENEMY_NAMES.BLIMPIE,
		//quantity: '1 + playerCount',
		spawnInTime: 1.5,
		quantity: 4,
		limit: 10,
		dropXP: false,
		dropLoot: false,
	},
	playsAnimation: AnimationTrack.SUMMON_MINIONS,
}

export const spawnEightBlimpiesAbility: Ability = {
	debugName: 'spawnBlimp',
	abilityType: AbilityType.SPAWN_ENEMY,
	durationInGameTicks: 50,
	validTargets: AbilityTargets.RANDOM_POSITIONS_IN_SHAPE,
	shapeNames: 'middleground',
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
	spawningRules: {
		identifier: ENEMY_NAMES.BLIMPIE,
		spawnInTime: 1.5,
		quantity: 8,
		limit: 15,
		dropXP: false,
		dropLoot: false,
	},
	playsAnimation: AnimationTrack.SUMMON_MINIONS,
}

export const fireHighHealthPlayerAbility: Ability = {
	debugName: 'fireProj-hh',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 20,
	validTargets: AbilityTargets.RESULTS_OF_THE_CRITERIA_CHECK,
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		colliderRadius: 110,
		speed: 700,
		lifespanInSeconds: 6,
		color: Colors.green,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_FUNGIBOSS,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_TRAIL_FUNGIBOSS,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
	},
	playsAnimation: AnimationTrack.SPRAY_ATTACK,
}

export const fireLargeMushroomAtRandomNearbyPlayerAbility: Ability = {
	debugName: 'fireProj-large',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 50,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		projectileCount: 8,
		spreadAngle: degToRad(135),
		speed: 400,
		colliderRadius: 15,
		lifespanInSeconds: 8,
		modifiers: [{ modType: ModType.STRAIGHT }],
		basePhysicalDamage: 10,
		basePoisonDamage: 20,
		burstCount: 4,
		pierceCount: 30,
		burstDelay: 0.30,
		burstMode: BurstFireModes.SWEEPING,
		burstAngle: degToRad(45),
		color: Colors.pink,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_FUNGIBOSS,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_TRAIL_FUNGIBOSS,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
	},
	shakeCamera: CameraShakeIntensities.AVERAGE,
}

export const fireSmallMushroomAtRandomNearbyPlayerAbility: Ability = {
	debugName: 'fireProj-small',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 34,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		projectileCount: 4,
		spreadAngle: degToRad(30),
		pierceCount: 5,
		speed: 400,
		lifespanInSeconds: 3,
		basePhysicalDamage: 15,
		basePoisonDamage: 30,
		burstCount: 3,
		burstDelay: 0.27,
		burstMode: BurstFireModes.TRACKING,
		burstAngle: degToRad(5),
		color: Colors.green,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_FUNGIBOSS,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_TRAIL_FUNGIBOSS,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
	},
	shakeCamera: CameraShakeIntensities.MILD,
	//playsAnimation: AnimationTrack.SPRAY_ATTACK,
}

export const fireBasicProjectileAtRandomNearbyPlayerAbility: Ability = {
	debugName: 'fireProj-basic',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 20,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		colliderRadius: 110,
		speed: 700,
		lifespanInSeconds: 6,
		color: Colors.green,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_FUNGIBOSS,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_TRAIL_FUNGIBOSS,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
	},
	playsAnimation: AnimationTrack.SPRAY_ATTACK,
}

export const sporeFieldAbilityPlayer: Ability = {
	debugName: 'sporeFieldAbilityPlayer',
	abilityType: AbilityType.SPAWN_GROUND_HAZARD_MULTITARGET,
	durationInGameTicks: 40,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		colliderRadius: 165,
		speed: 0,
		lifespanInSeconds: 2,
		basePoisonDamage: 90,
		color: Colors.green,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		pierceCount: 999,
	},
	groundHazardMultitargetRules: {
		spineAsset: 'fungi-boss-goop',
		pfx: 'aoe-spores',
		pfxScale: 1.2,
		pfxSecondsOffset: 1.2,
		projectileSecondsOffset: 1.3,
		baseQuantity: 6,
		perTargetQuantity: 2,
		totalTime: 2,
		accuratePercent: 0.1,
		maxStray: 250,
	},
}

export const sporeFieldAbilityMiddle: Ability = {
	debugName: 'sporeFieldAbilityZone',
	abilityType: AbilityType.SPAWN_GROUND_HAZARD,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.RANDOM_POSITIONS_IN_SHAPE,
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
	shapeNames: 'middleground',
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		colliderRadius: 165,
		speed: 0,
		lifespanInSeconds: 2,
		basePoisonDamage: 90,
		color: Colors.green,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		pierceCount: 999,
	},
	groundHazardRules: {
		spineAsset: 'fungi-boss-goop',
		pfx: 'aoe-spores',
		pfxScale: 1.2,
		pfxSecondsOffset: 1.2,
		projectileSecondsOffset: 1.3,
		quantity: 6,
		limit: 7,
	},
}

export const sporeFieldAbilityUpper = deepClone(sporeFieldAbilityMiddle)
sporeFieldAbilityUpper.shapeNames = 'upperzone'

export const sporeFieldAbilityFar = deepClone(sporeFieldAbilityMiddle)
sporeFieldAbilityFar.shapeNames = 'farzone'

export const sporeFieldAbilityLower = deepClone(sporeFieldAbilityMiddle)
sporeFieldAbilityLower.shapeNames = 'lowerzone'

export const spawnAbility: Ability = {
	debugName: 'spawn',
	abilityType: AbilityType.WAIT,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.MYSELF,
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
	playsAnimation: AnimationTrack.SPAWN,
}

export const sporeFieldAbilityPlayerEasy = deepClone(sporeFieldAbilityPlayer)
	sporeFieldAbilityPlayerEasy.groundHazardMultitargetRules.baseQuantity = 4
	sporeFieldAbilityPlayerEasy.groundHazardMultitargetRules.perTargetQuantity = 1
	sporeFieldAbilityPlayerEasy.projectileConfig.basePoisonDamage = 70
export const sporeFieldAbilityPlayerMedium = deepClone(sporeFieldAbilityPlayerEasy)
	sporeFieldAbilityPlayerMedium.groundHazardMultitargetRules.baseQuantity = 5
	sporeFieldAbilityPlayerMedium.groundHazardMultitargetRules.perTargetQuantity = 1
export const sporeFieldAbilityPlayerBrutal = deepClone(sporeFieldAbilityPlayerMedium)
	sporeFieldAbilityPlayerBrutal.groundHazardMultitargetRules.baseQuantity = 6
	sporeFieldAbilityPlayerBrutal.groundHazardMultitargetRules.perTargetQuantity = 2
export const sporeFieldAbilityPlayerNasty = deepClone(sporeFieldAbilityPlayerBrutal)

export const fireSmallMushroomAtRandomNearbyPlayerAbilityEasy = deepClone(fireSmallMushroomAtRandomNearbyPlayerAbility)
export const fireSmallMushroomAtRandomNearbyPlayerAbilityMedium = deepClone(fireSmallMushroomAtRandomNearbyPlayerAbilityEasy)
	fireSmallMushroomAtRandomNearbyPlayerAbilityMedium.projectileConfig.projectileCount = 5
	fireSmallMushroomAtRandomNearbyPlayerAbilityMedium.projectileConfig.burstCount = 4
	fireSmallMushroomAtRandomNearbyPlayerAbilityMedium.projectileConfig.burstDelay = 0.2 
	fireSmallMushroomAtRandomNearbyPlayerAbilityMedium.projectileConfig.speed = 480
	fireSmallMushroomAtRandomNearbyPlayerAbilityMedium.durationInGameTicks = 27
export const fireSmallMushroomAtRandomNearbyPlayerAbilityBrutal = deepClone(fireSmallMushroomAtRandomNearbyPlayerAbilityMedium)
	fireSmallMushroomAtRandomNearbyPlayerAbilityBrutal.projectileConfig.projectileCount = 5
	fireSmallMushroomAtRandomNearbyPlayerAbilityBrutal.projectileConfig.burstCount = 4
	fireSmallMushroomAtRandomNearbyPlayerAbilityBrutal.projectileConfig.burstDelay = 0.2 
	fireSmallMushroomAtRandomNearbyPlayerAbilityBrutal.projectileConfig.speed = 600
	fireSmallMushroomAtRandomNearbyPlayerAbilityBrutal.durationInGameTicks = 20
export const fireSmallMushroomAtRandomNearbyPlayerAbilityNasty = deepClone(fireSmallMushroomAtRandomNearbyPlayerAbilityBrutal)

export const fireLargeMushroomAtRandomNearbyPlayerAbilityEasy = deepClone(fireLargeMushroomAtRandomNearbyPlayerAbility)
export const fireLargeMushroomAtRandomNearbyPlayerAbilityMedium = deepClone(fireLargeMushroomAtRandomNearbyPlayerAbilityEasy)
	fireLargeMushroomAtRandomNearbyPlayerAbilityMedium.projectileConfig.projectileCount = 11
	fireLargeMushroomAtRandomNearbyPlayerAbilityMedium.projectileConfig.burstCount = 5
	fireLargeMushroomAtRandomNearbyPlayerAbilityMedium.projectileConfig.burstDelay = 0.25
	fireLargeMushroomAtRandomNearbyPlayerAbilityMedium.durationInGameTicks = 40
export const fireLargeMushroomAtRandomNearbyPlayerAbilityBrutal = deepClone(fireLargeMushroomAtRandomNearbyPlayerAbilityMedium)
	fireLargeMushroomAtRandomNearbyPlayerAbilityBrutal.projectileConfig.projectileCount = 11
	fireLargeMushroomAtRandomNearbyPlayerAbilityBrutal.projectileConfig.burstCount = 5
	fireLargeMushroomAtRandomNearbyPlayerAbilityBrutal.projectileConfig.burstDelay = 0.25
	fireLargeMushroomAtRandomNearbyPlayerAbilityBrutal.durationInGameTicks = 30
export const fireLargeMushroomAtRandomNearbyPlayerAbilityNasty = deepClone(fireLargeMushroomAtRandomNearbyPlayerAbilityBrutal)
