import { EnemyAI, FightingTriggers, IdleBehaviours, FightingBehaviours, LeashTriggers, LeashingBehaviours, AttackTypes, DeadBehaviours, ShotLeadPrecision, EnemyType, EnemyTargetingStyles } from '../../shared/ai-types'
import { BurstFireModes, ModType, ModValueType } from '../../../projectiles/shared/projectile-types'
import { SpineDataName, EnemyBipedSkinType } from '../../../models-animations/shared/spine-config'
import { EnemyDefaults } from './enemy-defaults'
import { Colors } from '../../../utils/colors'
import { ParticleEffectType } from '../../../engine/shared/game-data/particle-config'
import { Vector } from 'sat'
import { ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { ENEMY_DEFAULT_HEALTH } from '../../../engine/shared/game-data/enemy-formulas'
import { alwaysTrueCriteria, waitAbility } from './abilities/common-abilities'
import { Ability, AbilitySelectionStyles, AbilityTargets, AbilityTargetSelectionStyle, AbilityType, ActionCriteria, CriteriaSatisfactionQuantity, CriteriaTarget, CriteriaType } from '../action-types'
import { BuffIdentifier } from '../../../buffs/shared/buff.shared'
import { cloneDeep } from 'lodash'
import { attackRatedAbilityList } from '../action-criteria/ability-helpers'
import { AnimationTrack } from '../../../models-animations/shared/animation-track'
import { ENEMY_NAMES } from './enemy-names'
import { worldDifficultyHardCriterion, worldDifficultyBrutalCriterion } from '../action-criteria/action-criteria-helpers'
import { WorldDifficulty } from '../../../engine/shared/game-data/world-difficulty'

const playerIsInMeleeRangeCriteria: ActionCriteria = {
	satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
	criterias: [
		{
			criteriaTargets: CriteriaTarget.PLAYERS,
			criteriaType: CriteriaType.WITHIN_RANGE,
			criteriaValue: 350,
			minimumTargetsThatSatisfy: 1,
		},
	],
}

const crabMeleeAttackAbility: Ability = {
	debugName: 'melee',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.CLOSEST,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		speed: 600,
		colliderRadius: 20,
		baseIceDamage: 100,
		lifespanInSeconds: 1.5,
		burstMode: BurstFireModes.STRAIGHT,
		color: Colors.darkRed,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_LONG_SPELL_SMALL,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_NONE,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_ICE_SHOOT,
	},
}

const crabRangedSlowingAbility: Ability = {
	debugName: 'ranged-slow',
	abilityType: AbilityType.FIRE_PROJECTILE,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.ALL_NEARBY_ALIVE_PLAYERS,
	validTargetSelection: AbilityTargetSelectionStyle.RANDOM,
	projectileConfig: {
		...EnemyDefaults.projectileConfig,
		speed: 600,
		colliderRadius: 40,
		baseIceDamage: 60,
		lifespanInSeconds: 1.5,
		burstMode: BurstFireModes.STRAIGHT,
		modifiers: [{ modType: ModType.ACCELERATION, value: { modValueType: ModValueType.LIFETIME, min: 0, max: 2 } }],
		color: Colors.darkRed,
		bulletParticleEffect: ParticleEffectType.PROJECTILE_HEAD_SHAMBLINGMOUND,
		bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_ICE_TRAIL,
		muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_ICE_SHOOT,
		applyBuffsOnHit: [BuffIdentifier.EnemyCrabMovementSpeedSlow],
	},
}

const crabRangedSlowingAbilityDifficult: Ability = cloneDeep(crabRangedSlowingAbility)
crabRangedSlowingAbilityDifficult.projectileConfig.applyBuffsOnHit = [BuffIdentifier.EnemyCrabMovementSpeedSlowDifficult]

export const cooldownEnemyCooldown5000: Ability = {
	debugName: 'cooldown5000',
	abilityType: AbilityType.INSTANTLY_APPLY_EFFECT,
	buffToApply: BuffIdentifier.EnemyCooldown5000,
	durationInGameTicks: 0,
	validTargets: AbilityTargets.MYSELF,
	validTargetSelection: AbilityTargetSelectionStyle.ALL,
}

const animationTimes = {
	[AnimationTrack.SHOOT]: 0.3,
}

const crab: EnemyAI = {
	name: ENEMY_NAMES.CRAB,
	type: EnemyType.BASIC,
	appearance: {
		asset: SpineDataName.CRAB,
		skin: EnemyBipedSkinType.DEFAULT,
		mixSettings: [],
	},
	general: {
		enemyLevel: 1,
		experienceWhenKilled: 1,
	},
	baseAttributes: {
		...EnemyDefaults.baseAttributes,
		visibilityRadius: 1,
		colliders: [
			{
				type: ColliderType.Circle,
				radius: 40,
				traits: ColliderTraits.BlockMovement,
				position: [0, 0],
			},
			{
				type: ColliderType.Circle,
				radius: 60,
				traits: ColliderTraits.BlockProjectile,
				position: [0, -10],
			},
		],
		movementSpeed: 240,
		decelerationRate: 10,
		turningRatePerSecondInDegrees: 300,
		attackOffset: new Vector(0, 0),
		lootDropOffset: new Vector(0, 0),
		damageConfig: null,

		physicalDamage: 0,
		fireDamage: 0,
		iceDamage: 0,
		lightningDamage: 0,
		poisonDamage: 0,
		critChance: 0,
		critDamage: 1.0,

		maxHealth: (ENEMY_DEFAULT_HEALTH * 4.25) / 4,
		defense: 0,
		fireResist: 0,
		poisonResist: 0,
		iceResist: 0,
		lightningResist: 0,

		lootDropProbability: 1,
		lootDropQuantity: 1,
		lootDropRarity: 1,
	},
	soundEffects: {
		attack: 'Projectile_Zap',
		impact: 'SFX_Impact_Enemy_Mush',
	},
	states: {
		idling: {
			behaviour: IdleBehaviours.MEANDER,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 2,
			transitionToFighting: {
				trigger: FightingTriggers.AGGRESSIVE_PROXIMITY,
				radius: 900,
			},
			meander: {
				radius: 500,
				speedMultiplier: 1,
				minRestTime: 0.5,
				maxRestTime: 10,
			},
			aggroOnHit: true,
		},
		fighting: {
			behaviour: FightingBehaviours.CHASE_WHILE_ATTACKING,
			targetingStyle: EnemyTargetingStyles.HIGH_HEALTH,
			targetingCheckSeconds: 0,
			attackType: AttackTypes.PROJECTILE,
			engagementMaxDistance: 900,
			engagementMinDistance: 0,
			modelCenterOffset: 0,
			movementMaxDistance: 200,
			movementMinDistance: 0,
			brain: {
				actions: [
					{
						// BLOCK/ATTACK
						name: 'block/attack',
						priority: 0,
						actionCriteria: playerIsInMeleeRangeCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							//abilityOptions: [[1,  crabMeleeAttackAbility, 15]],
							abilityOptions: [attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, crabMeleeAttackAbility, 15)],
						},
					},
					{
						// Slowing projectile at tier 3+
						name: 'tier 3 slow proj',
						priority: 1,
						actionCriteria: {
							satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
							criterias: [worldDifficultyBrutalCriterion],
						},
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SEQUENCE,
							abilityOptions: [attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, crabRangedSlowingAbility, 40)],
						},
					},
					{
						// Slowing projectile at tier 2
						name: 'tier 2 slow proj',
						priority: 2,
						actionCriteria: {
							satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
							criterias: [
								worldDifficultyHardCriterion,
								{
									criteriaTargets: CriteriaTarget.SELF,
									criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
									criteriaValue: BuffIdentifier.EnemyCooldown5000,
									minimumTargetsThatSatisfy: 1,
								},
							],
						},
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SEQUENCE,
							abilityOptions: [
								//[1, crabRangedSlowingAbility, 40]
								attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, crabRangedSlowingAbility, 0),
								attackRatedAbilityList(1, animationTimes, AnimationTrack.IDLE, cooldownEnemyCooldown5000, 40),
							],
						},
					},
					{
						// Slowing projectile for lower world tiers
						name: 'slowing proj',
						priority: 3,
						actionCriteria: {
							satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
							criterias: [
								{
									criteriaTargets: CriteriaTarget.SELF,
									criteriaType: CriteriaType.WORLD_DIFFICULTY_AT_OR_BELOW_THRESHOLD,
									criteriaValue: WorldDifficulty.Hard,
									minimumTargetsThatSatisfy: 1,
								},
								{
									criteriaTargets: CriteriaTarget.SELF,
									criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
									criteriaValue: BuffIdentifier.EnemyCooldown5000,
									minimumTargetsThatSatisfy: 1,
								},
							],
						},
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SEQUENCE,
							abilityOptions: [
								//[1, crabRangedSlowingAbility, 40]
								attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, crabRangedSlowingAbility, 0),
								attackRatedAbilityList(1, animationTimes, AnimationTrack.SHOOT, cooldownEnemyCooldown5000, 40),
							],
						},
					},
					{
						// Wait
						name: 'wait',
						priority: 4,
						actionCriteria: alwaysTrueCriteria,
						actionAbilities: {
							abilitySelectionStyle: AbilitySelectionStyles.SINGLE,
							//abilityOptions: [[1, longRangeAbility, 50]],
							abilityOptions: [[0, waitAbility(10)]],
						},
					},
				],
			},
			attackOptions: [],
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 0.1,
			transitionToLeashing: {
				trigger: LeashTriggers.RADIUS_EXCEEDED,
				radius: 2000,
				distanceTravelled: 4000,
			},
		},
		leashing: {
			behaviour: LeashingBehaviours.MOVE,
			speedMultiplier: 4,
		},
		fleeing: {},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 1,
		},
	},
}

export default crab
