import { isArray } from "lodash";
import { AnyRewardData, RewardType } from "../../items/shared/reward-types";
import ItemType from "../../loot/shared/item-type";
import { SkinInformationByIdentifier } from "../../loot/shared/player-skin-sub-type";
import { BatType, HorseType, PigType, PrizeSubType } from "../../loot/shared/prize-sub-type";
import { FactionWeaponType } from "../../loot/shared/weapon-skin-sub-type";
import { FactionShortName } from "./faction-data";

interface FactionMinContributionPrize {
    contribution: number
    prize: AnyRewardData | AnyRewardData[]
}

interface FactionMinContributionPrizes {
    [FactionShortName.AURUM_ALLIANCE]: FactionMinContributionPrize[]
    [FactionShortName.ORDER_OF_IRON]: FactionMinContributionPrize[]
    [FactionShortName.SCIONS_OF_DAWN]: FactionMinContributionPrize[]
}

interface FactionTopContributorPrize {
    topPercent: Number
    prize: AnyRewardData | AnyRewardData[]
}

interface FactionTopContributorsPrizes {
    [FactionShortName.AURUM_ALLIANCE]: FactionTopContributorPrize[]
    [FactionShortName.ORDER_OF_IRON]: FactionTopContributorPrize[]
    [FactionShortName.SCIONS_OF_DAWN]: FactionTopContributorPrize[]
}

// @TODO get real prizes and numbers

// The prizes here work the same as the daily rewards system;
// so you could put buffs, coins, etc. here as well

// If you get 5,000, you also get 500
export const FACTION_MIN_CONTRIBUTION_REWARDS: FactionMinContributionPrizes = {
    [FactionShortName.AURUM_ALLIANCE]: [],

    [FactionShortName.ORDER_OF_IRON]: [],

    [FactionShortName.SCIONS_OF_DAWN]: [],
}

// The top 5% are included in top 10% 
export const FACTION_TOP_CONTRIBUTORS_REWARDS: FactionTopContributorsPrizes = {
    [FactionShortName.AURUM_ALLIANCE]: [
        {
            topPercent: 15,
            prize: {
                rewardType: RewardType.ITEM_BUNDLE,
                rewards: [
                    {
                        itemType: ItemType.Prize,
                        itemSubType: PrizeSubType.PlayerSkin,
                        playerSkinInfo: SkinInformationByIdentifier.get('gilded-aurum-alliance-mask')
                    }
                ],
                isPremium: true
            },
        },
        {
            topPercent: 5,
            prize: {
                rewardType: RewardType.ITEM_BUNDLE,
                rewards: [
                    {
                        itemType: ItemType.Prize,
                        itemSubType: PrizeSubType.WeaponSkin,
                        weaponSkinType: FactionWeaponType.Scythe
                    }
                ],
                isPremium: true
            },
        },
        {
            topPercent: 0, //only 1 player gets this
            prize: {
                rewardType: RewardType.ITEM_BUNDLE,
                rewards: [
                    {
                        itemType: ItemType.Prize,
                        itemSubType: PrizeSubType.PetEgg,
                        petSubType: BatType.BatGildedAurum
                    }
                ],
                isPremium: true
            },
        }
    ],

    [FactionShortName.ORDER_OF_IRON]: [
        {
            topPercent: 15,
            prize: {
                rewardType: RewardType.ITEM_BUNDLE,
                rewards: [
                    {
                        itemType: ItemType.Prize,
                        itemSubType: PrizeSubType.PlayerSkin,
                        playerSkinInfo: SkinInformationByIdentifier.get('gilded-order-of-iron-mask')
                    }
                ],
                isPremium: true
            }
        },
        {
            topPercent: 5,
            prize: {
                rewardType: RewardType.ITEM_BUNDLE,
                rewards: [
                    {
                        itemType: ItemType.Prize,
                        itemSubType: PrizeSubType.WeaponSkin,
                        weaponSkinType: FactionWeaponType.Sword
                    }
                ],
                isPremium: true
            },
        },
        {
            topPercent: 0, //only 1 player gets this
            prize: {
                rewardType: RewardType.ITEM_BUNDLE,
                rewards: [
                    {
                        itemType: ItemType.Prize,
                        itemSubType: PrizeSubType.PetEgg,
                        petSubType: PigType.PigGuildedBoarIron
                    }
                ],
                isPremium: true
            },
        }
    ],

    [FactionShortName.SCIONS_OF_DAWN]: [
        {
            topPercent: 15,
            prize: {
                rewardType: RewardType.ITEM_BUNDLE,
                rewards: [
                    {
                        itemType: ItemType.Prize,
                        itemSubType: PrizeSubType.PlayerSkin,
                        playerSkinInfo: SkinInformationByIdentifier.get('gilded-scions-of-dawn-mask')
                    }
                ],
                isPremium: true
            }
        },
        {
            topPercent: 5,
            prize: {
                rewardType: RewardType.ITEM_BUNDLE,
                rewards: [
                    {
                        itemType: ItemType.Prize,
                        itemSubType: PrizeSubType.WeaponSkin,
                        weaponSkinType: FactionWeaponType.Staff
                    }
                ],
                isPremium: true
            },
        },
        {
            topPercent: 0, //only 1 player gets this
            prize: {
                rewardType: RewardType.ITEM_BUNDLE,
                rewards: [
                    {
                        itemType: ItemType.Prize,
                        itemSubType: PrizeSubType.PetEgg,
                        petSubType: HorseType.HorseGildedPegasusScion
                    }
                ],
                isPremium: true
            },
        }
    ]   
}

export function getAllRewardsForContribution(earnedRep: number, topPercent: number, lastWinningFaction: FactionShortName): AnyRewardData[] {
    let allRewards: AnyRewardData[] = []

    if(lastWinningFaction) {
        const minRewards = FACTION_MIN_CONTRIBUTION_REWARDS[lastWinningFaction]
        const topPercentRewards = FACTION_TOP_CONTRIBUTORS_REWARDS[lastWinningFaction]
    
        for(let i=0; i < minRewards.length; ++i) {
            const reward = minRewards[i]
            if(reward.contribution <= earnedRep) {
                if(isArray(reward.prize)) {
                    allRewards = allRewards.concat(reward.prize)
                } else {
                    allRewards.push(reward.prize)
                }
            }
        }
    
        for(let i=0; i < topPercentRewards.length; ++i) {
            const reward = topPercentRewards[i]
            if(reward.topPercent >= topPercent) {
                if(isArray(reward.prize)) {
                    allRewards = allRewards.concat(reward.prize)
                } else {
                    allRewards.push(reward.prize)
                }
            }
        }
    
        return allRewards
    }
    
    return []
}