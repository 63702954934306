














































import { mapMutations, mapGetters, mapActions, mapState } from 'vuex'
import { chatSize } from '../state/chat.ui-state'

export default {
	name: 'Chat',
	data() {
		return {
			messageText: '',
			chatSize: chatSize,
			largeChatHeight: '',
		}
	},
	computed: {
		...mapGetters('chat', [
			'chatVisible',
			'chatFocused',
			'partyTabActive',
			'chatMessages',
			'partyMessages',
			'showPartyMessageNotification'
		]),
		...mapGetters('party', ['getCurrentlyPartiedStatus']),
		...mapState('chat', ['chatInputFocused', 'chatTimeStamps', 'partyTimeStamps']),
		...mapGetters('user', ['timestamps']),
		...mapState('UIScale', ['scale']),

		message: {
			get () {
				return this.$store.state.chat.chatMessage
			},
			set (value) {
				this.updateMessage(value)
			}
		},
	},
	mounted() {
		this.getLargeChatHeight()
		this.setChatScrollbar(this.$refs.chat)
		this.setPartyChatScrollbar(this.$refs.partyChat)
		this.scrollToBottom()
	},
	updated() {
		this.onChatUpdated()
	},
	methods:{
		...mapMutations('chat', ['focusChat', 'defocusChat', 'toggleChat', 'sendMessage', 'updateMessage', 'setPartyTabActive', 'setChatScrollbar', 'setPartyChatScrollbar', 'setChatInputFocused']),
		...mapActions('chat', ['onChatUpdated']),
		...mapActions('party', ['joinPartyDirect']),
		getLargeChatHeight(){
			const windowHeight = window.innerHeight
			const activeWeaponHeight = document.getElementById('active-weapons').getBoundingClientRect().height
			const chatTextInputHeight = document.getElementById('chat-input-text-el').getBoundingClientRect().height
			const chatWindowHeight = windowHeight - activeWeaponHeight

			const chatElement = document.getElementById('chat')
			const chatScaling = parseFloat((1 - chatElement.getBoundingClientRect().height / chatElement.offsetHeight ).toFixed(2))

			this.largeChatHeight = (chatWindowHeight * chatScaling) + chatWindowHeight
			return this.largeChatHeight
		},
		scrollToBottom() {
			this.$refs.chat.scrollTop = this.$refs.chat.scrollHeight
			this.$refs.partyChat.scrollTop = this.$refs.partyChat.scrollTop
		}
	},
}
