<template>
	<div class="shield-container">
		<div class="background"></div>
		<div class="shield-icon"></div>
		<div class="shield-text">{{ currentShields }} / {{ maxShields }}</div>
		<div class="sheild-stats">
			<div class="shield-delay">{{ (playerStats.shieldRegenDelay / 1000).toPrecision(2) }}s Delay</div>
			<div class="shield-recharge">{{ (playerStats.shieldRegenRate / 1000).toPrecision(2) }}s / Shield</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'PaperDollShield',
	computed: {
		...mapGetters('hud', ['maxShields', 'currentShields']),
		...mapGetters('paperdoll', ['playerStats']),
	},
}
</script>

<style lang="less" scoped>
@import '../../global/fonts/text.less';

.shield-container {
	width: 243px;
	height: 52px;
	display: flex;
	align-items: center;
	position: relative;

	.background {
		background-image: url('./assets/paperdoll-health-shield-bg.png');
		background-repeat: no-repeat;
		background-size: cover;
		width: 243px;
		height: 52px;
		position: absolute;
	}

	.shield-icon {
		background-image: url('./assets/paperdoll-shield-crystal.png');
		background-repeat: no-repeat;
		background-size: cover;
		width: 52px;
		height: 49px;
		position: absolute;
		top: 2px;
		left: -18px;
	}

	.shield-text {
		position: absolute;
		left: 55px;
		.base-stats-and-addition-stats-title ();
	}
	.sheild-stats {
		display: flex;
		flex-flow: column;
		position: absolute;
		left: 134px;
		.shield-delay {
			.paper-doll-stats();
		}
		.shield-recharge {
			.paper-doll-stats();
		}
	}
}
</style>
