import logger from '../../utils/client-logger'
import ChatMessage, { SERVER_CHAT_NAME } from '../../chat/shared/chat-message'
import ChatCommand from '../../chat/shared/chat-command'
import { NengiClient } from '../../engine/client/nengi-client'
import BroadcastMessage from '../../chat/shared/broadcast-message'
import DevToolsManager from '../dev-tools/dev-tools-manager'
import { debugConfig } from '../../engine/client/debug-config'
import Renderer from '../../engine/client/graphics/renderer'
import { clientConfig } from '../../engine/client/client-config'
import PartyInvitationMessage from '../../chat/shared/party-invitation-message'
import { setObjectPropWithString } from '../../utils/debug'
import { UI } from '../ui'
import { getLocalPlayer } from '../../world/client/poi.client'
import { saveFileInBrowser } from '../../utils/file-util'
import { modeStartCount } from '../../engine/client/start-stop'
import { DebugLoadLoadoutCommand } from '../../player/shared/debug-load-loadout-command'
import SetIsTypingCommand from '../../chat/shared/set-is-typing-command'
import moment from 'moment'
import { FactionShortName } from '../../factions/shared/faction-data'


const MAX_MESSAGES = 200

export enum chatSize {
	hidden = 0,
	normal = 1,
	large = 2,
}

interface ChatState {
	chatMessage: string
	chatFocused: boolean
	chatVisible: number
	partyTabActive: boolean
	chatMessages: Array<ChatMessage | PartyInvitationMessage>
	broadcasts: BroadcastMessage[]
	partyMessages: ChatMessage[]
	showPartyMessageNotification: boolean
	scrollbar: any
	partyChatScrollbar: any
	scrollToBottomOnUpdate: boolean
	scrollToBottomOnPartyUpdate: boolean
	chatTimeStamps: string[]
	partyTimeStamps: string[]
}

export const chatModule = () => {
	logger.debug('Initializing Chat store module...')
	return {
		namespaced: true,
		state: {
			chatMessage: '',
			chatFocused: false,
			chatVisible: 1,
			partyTabActive: false,
			chatMessages: [new ChatMessage('Mr. Loot', 'Kill monsters to earn Loot! Send your loot safely back home using Worm Mail.')],
			broadcasts: [],
			partyMessages: [],
			showPartyMessageNotification: false,
			scrollbar: null,
			partyChatScrollbar: null,
			scrollToBottomOnUpdate: false,
			scrollToBottomOnPartyUpdate: false,
			chatTimeStamps: [getTime()],
			partyTimeStamps: [],
		},
		getters: {
			chatVisible(state: ChatState) {
				return state.chatVisible
			},
			chatMessages(state: ChatState) {
				return state.chatMessages
			},
			chatFocused(state: ChatState) {
				return state.chatFocused
			},
			partyTabActive(state: ChatState) {
				return state.partyTabActive
			},
			showPartyMessageNotification(state: ChatState) {
				return state.showPartyMessageNotification
			},
			broadcasts(state: ChatState) {
				return state.broadcasts
			},
			currentBroadcast(state: ChatState) {
				return state.broadcasts.length ? state.broadcasts[0] : null
			},
			partyMessages(state: ChatState) {
				return state.partyMessages
			},
		},
		mutations: {
			toggleChat(state: ChatState) {
				if (state.chatFocused) {
					return
				}

				if (state.chatVisible === chatSize.hidden) {
					state.chatVisible = chatSize.normal
				} else if (state.chatVisible === chatSize.normal) {
					state.chatVisible = chatSize.large
				} else {
					state.chatVisible = chatSize.hidden
				}
			},
			focusChat(state: ChatState) {
				if (state.chatVisible === chatSize.hidden) {
					return
				}
				const element = document.querySelector('#chat-input-text-el') as HTMLElement
				state.chatFocused = true
				if (element) {
					element.focus()
				}

				NengiClient.getInstance().sendCommand(new SetIsTypingCommand(true))
			},
			defocusChat(state: ChatState) {
				if (state.chatVisible === chatSize.hidden) {
					return
				}
				const element = document.querySelector('#chat-input-text-el') as HTMLElement
				state.chatFocused = false
				if (element) {
					element.blur()
				}

				NengiClient.getInstance().sendCommand(new SetIsTypingCommand(false))
			},
			setPartyTabActive(state: ChatState, active: boolean) {
				state.partyTabActive = active
				if (active) {
					state.showPartyMessageNotification = false
				}
			},
			updateMessage(state: ChatState, newString: string) {
				state.chatMessage = newString
			},
			sendMessage(state: ChatState) {
				if (state.chatFocused === false) {
					this.commit('chat/focusChat')
				} else {
					const chatMessage = state.chatMessage
					if (chatMessage.length > 0) {
						if (chatMessage.startsWith('/csp')) {
							debugConfig.csp = !debugConfig.csp
							const s = debugConfig.csp ? 'enabled' : 'disabled'
							NengiClient.getInstance().sendCommand(new ChatCommand(`csp now ${s}`))
						} else if (chatMessage.startsWith('/tweaker')) {
							setupTweaker()
						} else if (chatMessage.startsWith('/bind')) {
							KeyBindings.addBinding(chatMessage)
						} else if (chatMessage.startsWith('/set-debugc')) {
							const words = chatMessage.split(' ')
							const propertyPath = words[1]
							const value = words[2]
							setObjectPropWithString(debugConfig, propertyPath, value)
						} else if (chatMessage.startsWith('/inspectc')) {
							const objName = chatMessage.split(' ')[1]
							const debugObject = DevToolsManager.getInstance().getObjectByName(objName)
							if (!debugObject) {
								logger.debug(`no object named ${objName}, try:`, DevToolsManager.getInstance().getMatchingNames(''))
							} else {
								DevToolsManager.getInstance().setDebugObject(debugObject)
							}
							//UI.getInstance().emitEvent('toggleDevToolsPanel')
						} else if (chatMessage.startsWith('/dump') || chatMessage.startsWith('/damnit')) {
							const isConnectedToServer = UI.getInstance().store.getters['hud/isConnectedToServer']
							if (isConnectedToServer) {
								NengiClient.getInstance().sendCommand(new ChatCommand(chatMessage, state.partyTabActive))
							} else {
								makeLocalDumpFile()
							}
						} else if (chatMessage.startsWith('/equip-dumped')) {
							tryEquipDumpedLoadout()
						} else if(chatMessage.startsWith('/set-faction-prize-override')) {
							const params = chatMessage.split(' ')
							if(params.length > 4) {
								const factionDebug = debugConfig.factions
								const forcedWinner = params[1] as any
								if(!(Object.values(FactionShortName).includes(forcedWinner))) {
									console.error('Bad faction winner name, defaulting to aurum or last set')
								} else {
									factionDebug.forcedFactionWinner = forcedWinner
									this.commit('factions/debugJustSetLastWinningFaction', forcedWinner, { root: true })
								}

								factionDebug.forcedFactionWin = params[2].toUpperCase() === 'TRUE'
								factionDebug.forcedFactionRep = Number.parseInt(params[3])
								factionDebug.forcedFactionTopPercent = Number.parseFloat(params[4])

								factionDebug.noServerCheckFactionPrize = true

								console.log(`Set faction override to ${JSON.stringify(factionDebug, undefined, 4)}`)
								this.commit('factions/setShowFactionRewardIntro', true, { root: true })
							} else {
								console.error("the format for this command is: /set-faction-prize-override {factionWinner (aurum|iron|dawn)} {didWeWin (true|false)} {howMuchRepDidWeGain (number >= 0)} {whatTopPercentAreWe (number 100 to 0)}")
							}
						} else {
							NengiClient.getInstance().sendCommand(new ChatCommand(chatMessage, state.partyTabActive))
						}
						state.chatMessage = ''
						this.commit('chat/defocusChat')
					} else {
						this.commit('chat/defocusChat')
					}
				}
			},
			receivedNewMessage(state: ChatState, newMessage: ChatMessage) {
				let scrollbar
				const time = getTime()

				if (!newMessage.party) {
					scrollbar = state.scrollbar

					state.chatMessages.push(newMessage)
					state.chatTimeStamps.push(time)

					newMessage.authorColor = getAuthorColorCSSFriendly(newMessage.authorsName)

					if (state.chatMessages.length > MAX_MESSAGES) {
						state.chatMessages.slice(MAX_MESSAGES)
						state.chatTimeStamps.slice(MAX_MESSAGES)
					}
				} else {
					scrollbar = state.partyChatScrollbar

					state.partyMessages.push(newMessage)
					state.partyTimeStamps.push(time)

					if (!state.partyTabActive) {
						state.showPartyMessageNotification = true
					}

					newMessage.authorColor = '#a6ff68'

					if (state.partyMessages.length > MAX_MESSAGES) {
						state.partyMessages.slice(MAX_MESSAGES)
						state.partyTimeStamps.slice(MAX_MESSAGES)
					}
				}

				let scrollToBottom
				if (scrollbar) {
					if (scrollbar.offsetHeight === 0) {
						scrollToBottom = true
					} else {
						const diff = Math.abs(scrollbar.scrollTop - (scrollbar.scrollHeight - scrollbar.offsetHeight))
						scrollToBottom = diff < 5
					}
				} else {
					scrollToBottom = false
				}

				if (newMessage.party) {
					state.scrollToBottomOnPartyUpdate = scrollToBottom
				} else {
					state.scrollToBottomOnUpdate = scrollToBottom
				}
			},
			receivedPartyInvitation(state: ChatState, invitation: PartyInvitationMessage) {
				const scrollbar = state.scrollbar
				const time = getTime()

				state.chatMessages.push(invitation)
				state.chatTimeStamps.push(time)

				invitation.authorColor = getAuthorColorCSSFriendly(invitation.authorsName)

				if (state.chatMessages.length > MAX_MESSAGES) {
					state.chatMessages.slice(MAX_MESSAGES)
					state.chatTimeStamps.slice(MAX_MESSAGES)
				}

				let scrollToBottom
				if (scrollbar) {
					if (scrollbar.offsetHeight === 0) {
						scrollToBottom = true
					} else {
						const diff = Math.abs(scrollbar.scrollTop - (scrollbar.scrollHeight - scrollbar.offsetHeight))
						scrollToBottom = diff < 5
					}
				} else {
					scrollToBottom = false
				}

				state.scrollToBottomOnUpdate = scrollToBottom
			},
			clearPartyMessages(state: ChatState) {
				state.showPartyMessageNotification = false
				state.partyMessages = []
			},
			receivedNewBroadcast(state: ChatState, message: BroadcastMessage) {
				state.broadcasts.push(message)
			},
			removeCurrentBroadcast(state: ChatState) {
				state.broadcasts.shift()
			},
			setChatScrollbar(state: ChatState, bar) {
				state.scrollbar = bar
			},
			setPartyChatScrollbar(state: ChatState, bar) {
				state.partyChatScrollbar = bar
			},
		},
		actions: {
			onChatUpdated({ state }: { state: ChatState }) {
				if (state.scrollToBottomOnPartyUpdate) {
					state.partyChatScrollbar.scrollTop = state.partyChatScrollbar.scrollHeight
					state.scrollToBottomOnPartyUpdate = false
				}

				if (state.scrollToBottomOnUpdate) {
					state.scrollbar.scrollTop = state.scrollbar.scrollHeight
					state.scrollToBottomOnUpdate = false
				}
			},
		},
	}
}

function getTime(){
	return moment().format('hh:mm').toString()
}

function setupTweaker() {
	const renderer = Renderer.getInstance()
	debugConfig.props.tweaker = true
	renderer.initColliderTweaker()

	// disable camera/weapon recoil
	clientConfig.disableCameraShake = debugConfig.camera.disableShake = true

	// turn on scroll wheel zoom
	if (!debugConfig.camera.allowScrollWheelZoom) {
		renderer.addScrollWheelZoomHandler()
	}
	debugConfig.camera.allowScrollWheelZoom = true
}

function makeLocalDumpFile() {
	const getters = UI.getInstance().store.getters

	const toSend: any = {}
	const localPlayer = getLocalPlayer()

	toSend.meta = {
		playerName: localPlayer.name,
		isFromServer: false,
		isAdventure: getters['inGame/currentGameMode'] === 'adventure',
		worldSeed: getters['hud/worldSeed'],
		worldDifficulty: getters['hud/serverWorldDifficulty'],
		url: getters['hud/serverYoureOn'],
		modeStartCount,
	}

	toSend.location = {
		position: localPlayer.position,
		poiIndex: localPlayer.poiIndex,
		biome: localPlayer.currentBiome,
		isInSafeZone: undefined,
	}

	toSend.progression = {
		furthestWorldTier: getters['hud/furthestWorldTier'],
		furthestBiome: getters['hud/furthestBiome'],
		activeWorldTier: getters['hud/activeWorldTier'],
	}

	toSend.pressure = {
		pressureLoadout: getters['boatLaunch/pressureLoadout'],
	}

	toSend.equipment = {
		weaponOne: getters['itemContainers/equippedWeapons'].one,
		weaponTwo: getters['itemContainers/equippedWeapons'].two,
		augments: getters['itemContainers/embedded'],
		gear: getters['itemContainers/equippedGear'],
		enchantments: getters['itemContainers/playerEnchantments'],
	}

	const data = JSON.stringify(toSend, undefined, 4)
	saveFileInBrowser({ filename: 'debugDumpFile.txt', contents: data })
}

async function tryEquipDumpedLoadout() {
	try {
		const clipboardContents = await navigator.clipboard.readText()
		const allData = JSON.parse(clipboardContents)
		if (allData.meta.isFromServer) {
			NengiClient.getInstance().sendCommand(new DebugLoadLoadoutCommand(JSON.stringify(allData.equipment)))
		} else {
			console.error('This is not a server dump')
		}
	} catch (err) {
		console.error('error equipping loadout\n' + err)
	}
}

// TODO2: these are the hard-coded color values for chat in the ui-toolkit slide.
// There are only 7 colors defined but we have up to 40 players on a server, + server and Deckard.
// https://docs.google.com/presentation/d/1qsWRM4tqmmNZmRn_-zAMwCp-Dcwy0nQ2Ja8MJZQibVY/present?token=AC4w5ViEzVc906Cp3czU2dzs4bQ1VI9Q8Q%3A1605204842806&includes_info_params=1&eisi=CKWHyoPO_ewCFRFryAodnlsJ8g#slide=id.ga4a3adaa23_0_5
const authorColors = [
	{ chatColor: '#ffbbbb', strokeColor: '#4c2a2a', shadowColor: '#4c2a2a' },
	{ chatColor: '#ffbf94', strokeColor: '#562707', shadowColor: '#562707' },
	{ chatColor: '#fff190', strokeColor: '#534104', shadowColor: '#534104' },
	{ chatColor: '#c6ff9f', strokeColor: '#35551e', shadowColor: '#35551e' },
	{ chatColor: '#9cffec', strokeColor: '#1f5248', shadowColor: '#1f5248' },
	{ chatColor: '#d5b6ff', strokeColor: '#403057', shadowColor: '#403057' },
	{ chatColor: '#ffaad4', strokeColor: '#442735', shadowColor: '#442735' },
]

const partyColor = { chatColor: '#a6ff68', strokeColor: '#35551e', shadowColor: '#35551e' }

const chatAuthorColors: Map<string, any> = new Map() // name => color
const serverNameColor = { chatColor: '#ffffff', strokeColor: '#000000', shadowColor: '#000000' }

export function getAuthorColor(colorKey: string, inParty: boolean = false): any {
	if (colorKey === SERVER_CHAT_NAME) {
		return serverNameColor
	}

	if (inParty) {
		return partyColor
	}

	if (!chatAuthorColors.get(colorKey)) {
		const randIdx = Math.floor(Math.random() * authorColors.length)
		const color = authorColors[randIdx]
		chatAuthorColors.set(colorKey, color)
	}

	return chatAuthorColors.get(colorKey)
}

function getAuthorColorCSSFriendly(colorKey: string) {
	return getAuthorColor(colorKey).chatColor
}

class KeyBindings {
	static addBinding(fullCommand: string) {
		const words = fullCommand.split(' ')
		const key = words[1]
		words.shift() // remove /bind
		words.shift() // remove key
		const command = words.join(' ')
		document.addEventListener('keydown', (event) => {
			if (event.key === key) {
				NengiClient.getInstance().sendCommand(new ChatCommand(command))
			}
		})
	}

	static bindings: Map<string, string> = new Map()
}
