import nengi from 'nengi'

export class DrawShapeBase {
	static protocol = {
		color: nengi.Number,
		permanent: nengi.Boolean,
		destroyAfterSeconds: nengi.Number,
		scale: nengi.Number,
	}

	color: number
	permanent: boolean
	destroyAfterSeconds: number
	scale: number

	constructor(color: number, permanent: boolean, destroyAfterSeconds: number, scale: number) {
		this.color = color
		this.permanent = permanent
		this.destroyAfterSeconds = destroyAfterSeconds
		this.scale = scale
	}
}
