import { gameUnits, percentage } from '../../../utils/primitive-types'
import { mapToRange, throwIfNotFinite } from '../../../utils/math'
import EntityStatList from '../../../stat-system/shared/entity-stat-list'

export const PROJECTILE_SPLASH_DAMAGE_CHILD_DURATION = 100
const POINT_BLANK_FALLOFF_DISTANCE = 300
const POINT_BLANK_ZERO_DISTANCE = 1000
const FAR_SHOT_MAX_DISTANCE = 800
const SHOTGUN_DAMAGE_PENALTY_FLAT = 0.2
const SHOTGUN_DAMAGE_PENALTY_SCALAR = 0.87

export const TICK_OFF_SERVER_FRAME_MULTIPLIER = 1000 / 1050
export const TICK_OFF_CLIENT_FRAME_MULTIPLIER = 1000 / 1016.6666666

export const POISON_MAX_STACKS_FOR_BONUS_DAMAGE = 100

export const ENEMY_ARMOR_PENETRATION_MULTIPLIER = 0.925
const MAX_ARMOR_REDUCTION = 0.1

export function getPointBlankDamage(pointBlankDamage: number, distance: gameUnits) {
	return mapToRange(distance, POINT_BLANK_FALLOFF_DISTANCE, POINT_BLANK_ZERO_DISTANCE, pointBlankDamage, 0, true)
}

export function getFarShotDamage(farShotDamage: number, distance: gameUnits) {
	return mapToRange(distance, 0, FAR_SHOT_MAX_DISTANCE, 0, farShotDamage, true)
}

export function getDefenseMod(statList: EntityStatList, statName: string, enemyArmorPen: number): percentage {
	const defense = statList.getStat(statName)
	throwIfNotFinite(defense, statName)

	return getDamageReduction(defense, enemyArmorPen)
}

export function getDamageReduction(defense: number, enemyArmorPen: number) {
	const effectiveArmor = defense * enemyArmorPen

	const reduced = Math.max(100 / (100 + 10 * effectiveArmor), MAX_ARMOR_REDUCTION)
	return reduced
}

export function getShotgunMultipleCollisionDamagePenalty(timesHitBefore: number): number {
	throwIfNotFinite(timesHitBefore, 'timesHitBefore')
	return Math.clamp(timesHitBefore === 0 ? 1 : SHOTGUN_DAMAGE_PENALTY_FLAT * Math.pow(SHOTGUN_DAMAGE_PENALTY_SCALAR, timesHitBefore), 0, 1)
}

const shotgunAllProjectilesHitMultiplierTable = []
{
	let cumul = 0
	for (let i = 0; i < 200; i++) {
		const curr = getShotgunMultipleCollisionDamagePenalty(i)
		cumul += curr
		shotgunAllProjectilesHitMultiplierTable[i] = cumul
	}
}

export function getShotgunAllProjectilesHitMultiplier(projectileCount: number) {
	if (projectileCount > shotgunAllProjectilesHitMultiplierTable.length) {
		console.log('getShotgunAllProjectilesHitMultiplier() passed more projectiles than the table includes records for')
		return shotgunAllProjectilesHitMultiplierTable[shotgunAllProjectilesHitMultiplierTable.length - 1]
	}
	return shotgunAllProjectilesHitMultiplierTable[projectileCount]
}
