import { NengiClient } from '../../engine/client/nengi-client'
import TutorialFlags from '../../ftue/shared/tutorial-flags'
import logger from '../../utils/client-logger'
import { FTUEVideo, PROXIMITY_MESSAGES_CONFIG, RESET_SHOWN_ONCE_ON_RELOAD, setPlayerVisitedOutpost } from '../../ftue/client/ftue-message-configs'
import { UI } from '../ui'
import UpdateTutorialFlagsCommand from '../../ftue/shared/update-tutorial-flags-command'
import { showGenericInfoPromptUI } from './generic-information-prompt.ui-state'
import { TUTORIAL_CONFIG } from '../../ftue/shared/ftue-completion-config'

export const tryShowTutorial = (tutorialNumber) => {
	UI.getInstance().emitAsyncEvent('ftueManager/showTutorialMessage', tutorialNumber)
}

export const completeFlagsFromCriteria = (criteria) => {
	UI.getInstance().emitAsyncEvent('ftueManager/completeFlagsFrom', criteria)
}

const FLAGS_COMPLETED_FROM_PARTY_LINK = ['startedFirstRunControls', 'firstMonsterDroppedLoot', 'firstLootAcquired', 
	'outpostProximity', 'firstEnteredOutpost', 'openedWormMailNPC', 'openedEndRunNPC', 'enteredHubWorld', 
	'stashProximity', 'openedStashNPC', 'wormDeliveryProximity', 'openedWormDeliveryNPC', 'openedDepartureNPC', 'startedSecondRun',
	'partyPlay']

export const ftueManagerModule = () => {
	logger.debug('Initializing FTUE Manager store module...')
	return {
		namespaced: true,
		state: {
			errorTitle: '',
			activeTutorialMessage: -1,
			finalBossDefeated: false,
			flags: new TutorialFlags(),
			videoPlayer: null,
			videoFinished: false,
			couldntLoadVideo: false,
			deepLinkNoFtue: false,
			saveFtueFlagsWhenConnected: false,
		},
		getters: {
			getErrorTitle(state: any) {
				return state.errorTitle
			},
			getFlag: (state: any) => (flag) => {
				if (flag in state.flags) {
					return state.flags[flag]
				} else {
					logger.warn(`Attempting to access an unrecognized tutorial flag (${flag})! Did you make a typo?`)
				}
			},
			isFinalBossDefeated(state: any) {
				return state.finalBossDefeated
			},
			startedSecondRun(state: any) {
				return state.flags.startedSecondRun
			},
		},
		mutations: {
			loadAllFlags(state, flags) {
				if (flags) {
					state.flags.loadFromJson(flags)
				}
			},
			setFinalBossDefeated(state, defeated) {
				state.finalBossDefeated = defeated
			}
		},
		actions: {
			completeFlagsFrom({ state }, criteria) {
				let clearedAFlag = false
				for (const flag in TUTORIAL_CONFIG) {
					if (flag) {
						const flagConfig = TUTORIAL_CONFIG[flag]
						if (flagConfig.completeAfter === criteria) {
							if (flagConfig.name in state.flags && !state.flags[flagConfig.name]) {
								state.flags[flagConfig.name] = true
								clearedAFlag = true
							}
						}
					}
				}

				if(clearedAFlag) {
					NengiClient.getInstance().sendCommand(new UpdateTutorialFlagsCommand(JSON.stringify(state.flags)))
				}
			},
			completeFlagsFromDeepLinkParty({state}) {
				let clearedAFlag = false
				for (const flag of FLAGS_COMPLETED_FROM_PARTY_LINK) {
					if(flag in state.flags && !state.flags[flag]) {
						state.flags[flag] = true
						clearedAFlag = true
					}
				}

				if(clearedAFlag) {
					state.saveFtueFlagsWhenConnected = true
				}
			},
			showTutorialMessage({ state }, messageIndex) {
				if (messageIndex > 0) {
					// are we already done of this flag?
					const stringName = TUTORIAL_CONFIG[messageIndex].name
					const flag = state.flags[stringName]
					if (!flag) {
						//change to flag.complete?
						PROXIMITY_MESSAGES_CONFIG.get(messageIndex).showMessage()
					}
				}
			},
			onReload({state}) {
				for (const tut of RESET_SHOWN_ONCE_ON_RELOAD) {
					if (tut) {
						//needed by husky -_-
						tut.shownOnce = false
					}
				}
				setPlayerVisitedOutpost(false)

				if(state.saveFtueFlagsWhenConnected) {
					NengiClient.getInstance().sendCommand(new UpdateTutorialFlagsCommand(JSON.stringify(state.flags)))
					state.saveFtueFlagsWhenConnected = false
				}
			},
			clearAllFlags({ state }) {
				let clearedAFlag = false
				for (const flag in TUTORIAL_CONFIG) {
					if (flag) {
						const flagConfig = TUTORIAL_CONFIG[flag]
						if (flagConfig.name in state.flags && !state.flags[flagConfig.name]) {
							state.flags[flagConfig.name] = true
							clearedAFlag = true
						}
					}
				}

				if (clearedAFlag) {
					NengiClient.getInstance().sendCommand(new UpdateTutorialFlagsCommand(JSON.stringify(state.flags)))
				}

				showGenericInfoPromptUI('Tutorial', [`You've completed the tutorial!`], 'I did it!', 'tutorial')
			},
			async showFTUEVideo({ state, commit }, videoId: FTUEVideo) {
				//@ts-expect-error
				console.log('showFTUEVideo ready: ', window.ytIFrameAPIReady)
				//@ts-expect-error
				if (window.ytIFrameAPIReady) {
					console.log('state.videoPlayer', state.videoPlayer)
					if (!state.videoPlayer) {
						console.log('videoId', videoId)

						//https://developers.google.com/youtube/iframe_api_reference
						//@ts-expect-error
						state.videoPlayer = new YT.Player(
							'video-player-placeholder', //div id
							{
								height: '450',
								width: '800',
								videoId: videoId,
								playerVars: {
									playsinline: 1,
									controls: 0,
									disablekb: 1,
									iv_load_policy: 3, //disables video annotations (this api uses wack numbers: 3=off, 1=on.  there are no other numbers)
									modestbranding: 1,
									rel: 0,

									origin: 'loot.io',
								},
								events: {
									onReady: onPlayerReadyToWatchVideo,
									onStateChange: onPlayerStateChange,
								},
							},
						)
					}
					state.couldntLoadVideo = false
					state.videoFinished = false
				} else {
					state.couldntLoadVideo = true
					state.videoFinished = true
				}

				commit('inGame/setPanelSwitchable', true, { root: true })
				commit('inGame/setActivePanel', 'videoPlayer', { root: true })
			},
			closeVideoPlayer({ state, commit }) {
				if (state.videoPlayer && state.videoPlayer.getPlayerState) {
					const playerState = state.videoPlayer.getPlayerState()
					if (playerState !== -1) {
						state.videoPlayer.seekTo(0, true)
						state.videoPlayer.pauseVideo()
					}
				}

				UI.getInstance().emitAsyncEvent('inGame/closeActivePanel')
			},
		},
	}
}

function onPlayerReadyToWatchVideo(event) {
	event.target.playVideo()
}

function onPlayerStateChange(event) {
	const uiInst = UI.getInstance()
	switch (event.data) {
		case -1: //unstarted
			break
		case 0: //ended
			//TODO: Analytics event and FTUE completion
			break
		case 1: //playing
			break
		case 2: //paused
			break
		case 3: //buffering
			break
		//There is no 4. I do not know why.
		case 5: //video queued
			break
	}
}
