















































































































































































import { mapGetters, mapMutations, mapState, mapActions } from 'vuex'
import { sortBy, chain, orderBy } from 'lodash'
import InventoryItem from '../inventory-item/inventory-item.vue'
import TextButton from '../buttons/text-button.vue'
import { canEquipItemOfLevel } from '../../../engine/shared/game-data/player'
import TextInput from '../inputs/text-input.vue'
import CategoryFilter from '../filtering/category-filter.vue'
import SubCategoryFilter from '../filtering/sub-category-filter.vue'
import { PrizeSubType } from '../../../loot/shared/prize-sub-type'
import { NengiClient } from '../../../engine/client/nengi-client'
import OpenPrizeCommand from '../../../items/shared/open-prize-command'
import { combineStackablesTooltipText, OWNED_PET_TOOLTIP_TEXT } from '../v-tooltip/v-tooltip-functions'
import { FilterType } from '../../state/cosmetics-ui-state'
import { CategoryFilterTypes } from '../../state/item-containers.ui-state'
import { Panel } from '../../state/in-game.ui-state'
import { ITEM_STACK_SIZES } from '../../../engine/shared/game-data/stackable-config'
import { ImARealitem } from '../inventory-item/inventory-item-helpers'
import MergeStackablesCommand from '../../../items/shared/merge-stackables-command'
import { scale } from 'src/utils/math'
import UseConsumableItemCommand from '../../../items/shared/use-consumable-item-command'
import AmountSelector from '../buttons/amount-selector.vue'


const TEMPLATE_TYPE = [
	{ templateId: 'marketplace', categoryFilterType: 'sortOrder', subCategoryFilterType: 'rarity', countDown: true, iconLeftOfButton: true, showCurrencyCost: true, prismScaleCost: true, itemLevel: true, itemDescription: false, showEnchants: false, rewardSubHeaderText: false },
	{ templateId: 'marketplace-my-listing', categoryFilterType: 'sortOrder', subCategoryFilterType: 'rarity', countDown: true, iconLeftOfButton: true, showCurrencyCost: true, prismScaleCost: true, itemLevel: true, itemDescription: false, showEnchants: false, rewardSubHeaderText: false },
	{ templateId: 'addMarketplaceListing', categoryFilterType: 'item', subCategoryFilterType: 'rarity', countDown: false, iconLeftOfButton: false, showCurrencyCost: false, prismScaleCost: true, itemLevel: true, itemDescription: false, showEnchants: false, rewardSubHeaderText: false },
	{ templateId: 'generalstore', categoryFilterType: 'disable', subCategoryFilterType: 'text', stockLevels: true, iconLeftOfButton: false, showCurrencyCost: true, itemLevel: false, itemDescription: true, showEnchants: false, rewardSubHeaderText: false },
	{ templateId: 'outpost-wormmail', categoryFilterType: 'item', subCategoryFilterType: 'rarity', showCurrencyCost: false, itemLevel: true, itemDescription: false, showEnchants: false, rewardSubHeaderText: false },
	{ templateId: 'pitofchances', categoryFilterType: 'item', subCategoryFilterType: 'rarity', howCurrencyCost: false, itemLevel: true, itemDescription: false, showEnchants: false, rewardSubHeaderText: false },
	{ templateId: 'augmentstation', categoryFilterType: 'disable', subCategoryFilterType: 'rarity', showCurrencyCost: false, itemLevel: true, itemDescription: false, showEnchants: false, rewardSubHeaderText: false },
	{ templateId: 'inventory', categoryFilterType: 'item', subCategoryFilterType: 'rarity', showCurrencyCost: false, showNewItem: true, itemLevel: true, itemDescription: false, showEnchants: false, rewardSubHeaderText: false },
	{ templateId: 'mtxstore', categoryFilterType: 'disable', subCategoryFilterType: 'disable', showCurrencyCost: false, itemLevel: false, itemDescription: true, showEnchants: false },
	{ templateId: 'stash', categoryFilterType: 'item', subCategoryFilterType: 'rarity', showCurrencyCost: false, itemLevel: true, itemDescription: false, showEnchants: false, rewardSubHeaderText: false },
	{ templateId: 'furnace', categoryFilterType: 'item', subCategoryFilterType: 'rarity', showCurrencyCost: false, itemLevel: true, itemDescription: false, showEnchants: false, rewardSubHeaderText: false },
	//{ templateId: 'rewardWeaponList', categoryFilterType: 'disable', subCategoryFilterType: 'disable', showCurrencyCost: false, itemLevel: true, itemDescription: false, showEnchants: true, rewardSubHeaderText: false },
	{ templateId: 'rewardEnchantmentList', categoryFilterType: 'disable', subCategoryFilterType: 'disable', showCurrencyCost: false, itemLevel: false, itemDescription: true, showEnchants: false, rewardSubHeaderText: true },
	{ templateId: 'identifiedItem', categoryFilterType: 'disable', subCategoryFilterType: 'disable', showCurrencyCost: false, itemLevel: true, itemDescription: false, showEnchants: false, rewardSubHeaderText: false },
]

export default {
	name: 'ListItemContainer',
	components: {
		InventoryItem,
		TextButton,
		TextInput,
		CategoryFilter,
		SubCategoryFilter,
		AmountSelector
	},
	props: {
		items: {
			type: Array,
			required: true,
			default: () => [],
		},
		disableTopFilterRow: {
			type: Boolean,
			required: false,
			default: false,
		},

		tooltipable: {
			type: Boolean,
			required: false,
			default: true,
		},
		showButton: {
			type: Boolean,
			required: false,
			default: false,
		},
		isMarketplaceList: {
			type: Boolean,
			required: false,
			default: false,
		},
		highlightButtonFn: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default highlight-button-fn handler for the <ListItemContainer /> component has been called. Did you forget to pass an :highlight-button-fn handler to the component?')
				return false
			},
		},
		buttonText: {
			type: String,
			required: false,
			default: 'Button Text',
		},
		buttonTextFn: {
			type: Function,
			required: false,
			default: null,
		},
		buttonToolTipTextFn: {
			type: Function,
			required: false,
			default: () => {
				return ''
			},
		},
		selectable: {
			type: Boolean,
			required: false,
			default: true,
		},
		templateIdentifier: {
			type: String,
			required: true,
			validator: function(value) {
				return TEMPLATE_TYPE.some(({ templateId }) => templateId === value)
			},
		},
		onSelectFn: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default on-select handler for the <ListItemContainer /> component has been called. Did you forget to pass an :on-click handler to the component?')
			},
		},
		selectedFn: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default selected fn for the <ListItemContainer /> component has been called. Did you forget to pass an :selected-fn handler to the component?')
			},
		},
		disableAllButtons: {
			type: Boolean,
			required: false,
			default: false,
		},
		disableIndividualBtnFn: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default disableIndividualBtnFn for the <ListItemContainer /> component has been called. Did you forget to pass an :selected-fn handler to the component?')
				return false
			},
		},
		shouldConditionallyHideIndividualBtnFn: {
			type: Function,
			required: false,
			default: () => {
				//console.warn('The default shouldConditionallyHideIndividualBtnFn for the <ListItemContainer /> component has been called. Did you forget to pass an :selected-fn handler to the component?')
				return false
			},
		},
		hideIndividualBtnFn: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default hideIndividualBtnFn for the <ListItemContainer /> component has been called. Did you forget to pass an :selected-fn handler to the component?')
				return false
			},
		},
		onRowButtonClickProp: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default on-row-button-click-prop handler for the <ListItemContainer /> component has been called. Did you forget to pass an :on-click handler to the component?')
			},
		},
		message: {
			type: String,
			required: false,
			default: 'Your Reward Awaits.',
		},
		allowIdentifying: {
			type: Boolean,
			required: false,
			default: false,
		},
		identifyFn: {
			type: Function,
			required: false,
			default: () => {
				return 'default'
			},
		},
		newItemsIdentifierList: {
			type: Array,
			required: false,
			default: () => [],
		},
		openPrizes: {
			type: Boolean,
			required: false,
			default: false,
		},
		combineStacksButton: {
			type: Boolean,
			required: false,
			default: false,
		},
		usableItemButton: {
			type: Boolean,
			required: false,
			default: false,
		},
		onAmountChangedFn: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default on-amount-changed for the <ListItemContainer /> component has been called. Did you forget to pass an :on-amount-changed-fn handler to the component?')
				return false
			},
		},
	},
	data() {
		return {
			template: TEMPLATE_TYPE.find(({ templateId }) => templateId === this.templateIdentifier),
			sortOrder: true,
			filterTableBySearch: '',
			rarityOrder: { Common: 1, Uncommon: 2, Rare: 3, Epic: 4, Legendary: 5, Astronomical: 6 },
			pageNumInput: 1,
			singleContainerHeight: 104,
		}
	},
	computed: {
		...mapState('hud', ['progressionLevel']),
		...mapGetters('itemLocks', ['itemIsLocked']),
		...mapState('marketplaceUpdated', ['marketplacePage', 'totalMarketplacePages']),
		...mapGetters('marketplaceUpdated', ['getItemExpirationFromMetadataByItemId', 'getItemPriceFromMetadataByItemId', 'listingsMetadata', 'getLoadingInProgress', 'getUserItemExpirationFromMetadataByItemId', 'getUserItemPriceFromMetadataByItemId']),
		...mapGetters('hud', ['coinBalance']),
		...mapState('itemContainers', ['activeItemTypeFilter', 'activeRarityFilter', 'activeFilterCategory', 'activeSearch', 'itemSortOrder', 'categorySortOrderName', 'categorySortOrderLevel', 'categorySortOrderPrice']),
		...mapGetters('itemContainers', ['playerCanUseItem']),
		...mapGetters('inGame', ['activePanel']),
		...mapState('cosmetics', ['ownedCosmetics']),
		...mapGetters('UIScale', ['currentScale']),
		combineStackablesTooltipText,

		hasActiveFilters() {
			return this.activeItemTypeFilter !== 'all' || this.activeRarityFilter !== 'all'
		},
		sortItemsByLevel() {
			if (this.activePanel !== 'generalStoreUpdated') {
				return sortBy(this.items, 'level').reverse()
			}
			return this.items
		},
		searchFilterPanelCheck() {
			const panelsWithoutSearch = ['generalStoreUpdated', 'outpostStore', 'rewardSelection', 'identify', 'premiumStore', 'mtxStore', 'hubRewards']
			return !panelsWithoutSearch.includes(this.activePanel)
		},
		disablePreviousBtn() {
			if (this.marketplacePage === 1) return true
		},
		disableNextBtn() {
			if (this.marketplacePage >= this.totalMarketplacePages) return true
		},
		pageNum: {
			set(pageNum: string) {
				const pageNumber = parseInt(pageNum)
				if (pageNumber > this.totalMarketplacePages || pageNumber <= 0) return
				this.changeToSpecificPage(pageNumber)
			},
			get() {
				return this.marketplacePage
			},
		},
	},
	methods: {
		...mapMutations('itemContainers', ['updateItemTypeFilter', 'updateRarityFilter', 'updateSortOrder', 'customSearchFilters']),
		...mapMutations('marketplaceUpdated', ['increaseMarketPage', 'decreaseMarketPage', 'changeToSpecificPage']),
		...mapActions('marketplaceUpdated', ['fetchListings']),
		showNewItemLabel(itemId) {
			return this.newItemsIdentifierList.some((item) => {
				return item.id === itemId
			})
		},

		getItemDescriptionWrapper(item) {
			return item.description || item.stats?.description
		},

		updateItemSearchCriteria(FilterText) {
			this.filterTableBySearch = FilterText.toLowerCase()
		},

		getButtonText(itemId) {
			if (!this.buttonTextFn) {
				return this.buttonText
			}
			return this.buttonTextFn(itemId)
		},

		getPrizeButtonText(item) {
			if (item.itemSubType === PrizeSubType.PetEgg) {
				return 'Hatch'
			}
			if (item.itemSubType === PrizeSubType.WeaponSkin) {
				return 'Open'
			}
			return 'Open'
		},

		openPrize(item) {
			NengiClient.getInstance().sendCommand(new OpenPrizeCommand(item.id, 'stash'))
		},

		shouldDisableOpenPrizeForItem(item) {
			if (item.itemSubType === PrizeSubType.PetEgg) {
				const petType = item.extraData.petSubType
				return this.ownedCosmetics.some((c) => c.cosmeticType === FilterType.PET_SKIN && c.identifier === petType)
			}

			if (item.itemSubType === PrizeSubType.WeaponSkin) {
				const weaponSkinType = item.extraData.weaponSkinType
				return this.ownedCosmetics.some((c) => c.cosmeticType === FilterType.ALL_WEAPONS && c.identifier === weaponSkinType)
			}

			if (item.itemSubType === PrizeSubType.PlayerSkin) {
				const skin = item.extraData.playerSkinIdentifier
				return this.ownedCosmetics.some((c) => c.identifier === skin)
			}

			return false
		},

		getPrizeTooltipText(item) {
			if (item.itemSubType === PrizeSubType.PetEgg) {
				const petType = item.extraData.petSubType
				if (this.ownedCosmetics.some((c) => c.cosmeticType === FilterType.PET_SKIN && c.identifier === petType)) {
					return OWNED_PET_TOOLTIP_TEXT
				}
			}

			return null
		},

		getExpirationAtByItemId(itemId) {
			let expirationFromMetadata = ''

			if (this.templateIdentifier === 'marketplace-my-listing') {
				expirationFromMetadata = this.getUserItemExpirationFromMetadataByItemId(itemId)
			}
			if (this.templateIdentifier === 'marketplace') {
				expirationFromMetadata = this.getItemExpirationFromMetadataByItemId(itemId)
			}

			return expirationFromMetadata
		},

		findItemPriceFromMetadata(itemId) {
			let itemPriceFromMetadata = ''

			if (this.templateIdentifier === 'marketplace-my-listing') {
				itemPriceFromMetadata = this.getUserItemPriceFromMetadataByItemId(itemId)
			}
			if (this.templateIdentifier === 'marketplace') {
				itemPriceFromMetadata = this.getItemPriceFromMetadataByItemId(itemId)
			}
			return itemPriceFromMetadata
		},

		filterItemsByItemTypeAndRarity(items, rarityFilter, itemTypeFilter) {
			let itemsFilteredByTypeAndRarity = chain(items).filter((item) => item.rarity.toLowerCase() === rarityFilter && item.itemType === itemTypeFilter)
			return this.filterItemsByLevelOrSearch(itemsFilteredByTypeAndRarity, this.filterTableBySearch)
		},

		filterItemByItemType(items, itemTypeFilter) {
			let itemsFilteredByType = chain(items).filter((item) => item.itemType === itemTypeFilter)
			return this.filterItemsByLevelOrSearch(itemsFilteredByType, this.filterTableBySearch)
		},

		filterItemByItemRarity(items, rarityFilter) {
			let itemsFilteredByTypeAndRarity = chain(items).filter((item) => item.rarity.toLowerCase() === rarityFilter)
			return this.filterItemsByLevelOrSearch(itemsFilteredByTypeAndRarity, this.filterTableBySearch)
		},

		filterItemsByLevelOrSearch(items, search) {
			if (this.activePanel === 'inventory') {
				return chain(items)
					.filter((item) => item.name.toLowerCase().includes(search) || item.level.toString().includes(search))
					.sortBy('level')
					.orderBy((item) => [this.rarityOrder[item.rarity]])
			}

			return chain(items)
				.filter((item) => item.name.toLowerCase().includes(search) || item.level.toString().includes(search))
				.sortBy('level')
				.reverse()
		},

		sortItemByPrice(items, userSearchCriteria, rarityFilter) {
			const priceFunc = this.findItemPriceFromMetadata
			let sortedItems

			if (rarityFilter) {
				sortedItems = this.filterItemByItemRarity(items, rarityFilter).sortBy((item) => priceFunc(item.id))
			} else {
				sortedItems = this.filterItemsByLevelOrSearch(items, userSearchCriteria).sortBy((item) => priceFunc(item.id))
			}

			if (this.itemSortOrder === 'desc') {
				return sortedItems.reverse()
			}
			return sortedItems
		},

		sortMarketplaceItems(items) {
			if (this.activeFilterCategory === CategoryFilterTypes.NAME) {
				return orderBy(items, this.activeFilterCategory, this.categorySortOrderName)
			}

			if (this.activeFilterCategory === CategoryFilterTypes.PRICE) {
				const priceFunc = this.findItemPriceFromMetadata
				const sortedItem = sortBy(items, (item) => priceFunc(item.id))

				if (this.categorySortOrderPrice === 'desc') {
					return sortedItem.reverse()
				}

				return sortedItem
			}

			if (this.activeFilterCategory === CategoryFilterTypes.LEVEL) {
				return orderBy(items, 'level', this.categorySortOrderLevel)
			}
		},

		itemListFiltered(items, userSearchCriteria) {
			const itemTypeFilter = this.activeItemTypeFilter
			const rarityFilter = this.activeRarityFilter
			const hasItemTypeFilter = itemTypeFilter !== 'all'
			const hasRarityFilter = rarityFilter !== 'all'

			if (this.templateIdentifier === 'marketplace') {
				// We can assume the order from the server is correctly sorted
				// but we can't assume by the time they reach the client the order
				// is still correct hence this function to make sure the order is correct
				return this.sortMarketplaceItems(items)
			} else {
				if (hasItemTypeFilter && hasRarityFilter) {
					return this.filterItemsByItemTypeAndRarity(items, rarityFilter, itemTypeFilter)
				} else if (hasItemTypeFilter) {
					return this.filterItemByItemType(items, itemTypeFilter)
				} else if (hasRarityFilter) {
					// needed to keep this logic around for the my listings page.
					// It can be paginated to therefore removing these condition checks for level, price, name
					if (this.activeFilterCategory === 'level' || this.activeFilterCategory === 'name') {
						return this.filterItemByItemRarity(items, rarityFilter).orderBy([this.activeFilterCategory], [this.itemSortOrder])
					}
					if (this.activeFilterCategory === 'price') {
						return this.sortItemByPrice(items, userSearchCriteria, rarityFilter)
					}
					return this.filterItemByItemRarity(items, rarityFilter).sortBy('levels')
				} else {
					if (this.activeFilterCategory === 'level' || this.activeFilterCategory === 'name') {
						return this.filterItemsByLevelOrSearch(items, userSearchCriteria).orderBy([this.activeFilterCategory], [this.itemSortOrder])
					}
					if (this.activeFilterCategory === 'price') {
						return this.sortItemByPrice(items, userSearchCriteria)
					}
					return this.filterItemsByLevelOrSearch(items, userSearchCriteria)
				}
			}
		},
		previousPage() {
			if (this.marketplacePage === 1) return
			this.decreaseMarketPage()
			this.fetchListings()
		},
		nextPage() {
			if (this.marketplacePage >= this.totalMarketplacePages) return
			this.increaseMarketPage()
			this.fetchListings()
		},
		changePageNum() {
			this.fetchListings()
		},
		firstPage() {
			this.changeToSpecificPage(1)
			this.fetchListings()
		},
		lastPage() {
			this.changeToSpecificPage(this.totalMarketplacePages)
			this.fetchListings()
		},
		onRowButtonClick(itemId) {
			if (this.disableAllButtons || this.itemIsLocked(itemId) || this.disableIndividualBtnFn(itemId)) return

			this.onRowButtonClickProp(itemId)
		},
		canEquipByLevel(item) {
			const can = canEquipItemOfLevel(this.progressionLevel, item.level)
			return can
		},
		shouldDisplayLevel(item) {
			switch (item.itemType) {
				case 'Prize':
				case 'Rarity Shard':
				case 'Lucky Shard':
				case 'Biome Key':
				case 'Worm Horn':
				case 'Weapon Skin':
				case 'Persistent Buff':
				case 'Currency Bag':
				case 'Scroll':
					return false
				default:
					return true
			}
		},
		canAffordIdentification(item) {
			return item.identifyCost <= this.coinBalance
		},
		itemIsStackable(item) {
			return Boolean(item.stackAmount)
		},
		itemCanBeStacked(item: ImARealitem) {
			if (!this.itemIsStackable(item)) {
				return false
			}

			const maxStacks = ITEM_STACK_SIZES[item.itemTypeEnum]
			if (item.stackAmount >= maxStacks) {
				return false
			}

			for (let i = 0; i < this.items.length; ++i) {
				const otherItem = this.items[i] as ImARealitem
				if (otherItem.stackAmount && otherItem.stackAmount < maxStacks) {
					if (otherItem.itemTypeEnum === item.itemTypeEnum && otherItem.itemSubType === item.itemSubType && otherItem.rarityEnum === item.rarityEnum && otherItem.id !== item.id) {
						return true
					}
				}
			}

			return false
		},
		combineStacks(item: ImARealitem) {
			const maxStacks = ITEM_STACK_SIZES[item.itemTypeEnum]

			for (let i = 0; i < this.items.length; ++i) {
				const otherItem = this.items[i] as ImARealitem
				if (otherItem.stackAmount && otherItem.stackAmount < maxStacks) {
					if (otherItem.itemTypeEnum === item.itemTypeEnum && otherItem.itemSubType === item.itemSubType && otherItem.rarityEnum === item.rarityEnum && otherItem.id !== item.id) {
						NengiClient.getInstance().sendCommand(new MergeStackablesCommand(item.id, otherItem.id))
						return
					}
				}
			}
		},
		itemCanBeUsed(item) {
			return item.itemType === 'Persistent Buff'
		},
		useItem(item: ImARealitem) {
			NengiClient.getInstance().sendCommand(new UseConsumableItemCommand(item.id))
			return
		},
		itemIsInPendingMarketplace(item) {
			return item.isPendingMarketplace
		},
	},
}
