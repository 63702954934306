



















































import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'
import PanelDivider from '../../reusable-components/panel-divider/panel-divider.vue'
import TextButton from '../../reusable-components/buttons/text-button.vue'
import PlayerPortrait from '../../reusable-components/player-profile/player-portrait.vue'
import FancySkinPreview from '../../reusable-components/fancy-skin-preview/fancy-skin-preview.vue'
const flagImages = require('../assets/*flag-large.png')
import { factionFlagUrl } from '../../../factions/shared/faction-data'
import  moment  from 'moment-timezone'
import {playerPanelTabs} from '../../state/in-game.ui-state'

export default {
	name: 'PlayerProfile',
	components: {
		PanelDivider,
		TextButton,
		PlayerPortrait,
		FancySkinPreview,
	},
	data() {
		return {
			activeFilterTable: '',
			flagImages,
		}
	},
	props: {},
	computed: {
		...mapState('factions', ['currentRecruitPanel', 'affiliatedFaction']),
		...mapGetters('cosmetics', ['mainSkin']),
		...mapGetters('user', ['metrics', 'seasonMetrics', 'userType']),
		currentMetrics() {
			let metrics
			if (this.activeFilterTable === 'seasonal') {
				metrics = this.seasonMetrics
			} else {
				metrics = this.metrics
			}

			return [
				{ name: 'Enemies Killed', value: metrics.sumOfEnemiesKilled },
				{ name: 'Bosses Killed', value: metrics.sumOfBossesKilled },
				{ name: 'Projectiles Fired', value: metrics.sumOfProjectilesFired },
				//{ name: 'Damage Dealt', value: metrics.sumOfDamageDealt }, //broken for some reason
				{ name: 'Damage Taken', value: metrics.sumOfDamageTaken },
				{ name: 'Worm Items Deposited', value: metrics.sumOfWormItemsDeposited },
				{ name: 'Biggest Critical Strike', value: metrics.maxCriticalStrikeHit },
			]
		},
		formattedDate(){
			return moment.utc(this.metrics.createdAt).format('MM-DD-YYYY')
		}
	},
	methods: {
		...mapActions('inGame', ['closeActivePanel']),
		factionFlagUrl,

		updateTable(value) {
			this.activeFilterTable = value
		},
		...mapMutations('inGame', ['setActivePanel']),
		...mapMutations('errors', ['setActiveError']),
		openCustomizePanel() {
			this.setActivePanel('playerProfile')
			this.updatedPlayerTab(playerPanelTabs.CUSTOMIZE)
		},
		openError() {
			this.setActiveError()
		},
	},
}
