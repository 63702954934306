import { rollWeightedRandom, WeightedRandom } from '../../world/shared/weightedRandom'
import { Ability, AbilityOptionallyWithWeightOrTiming, AbilitySelectionStyles } from '../shared/action-types'
import { flatten } from 'lodash'
import { waitAbility } from '../shared/enemies/abilities/common-abilities'

const WEIGHT_INDEX = 0

export interface AbilitySelector {
	chooseAbility(): Ability
}

export function abilitySelectionFactory(abilityType: AbilitySelectionStyles, data: AbilityOptionallyWithWeightOrTiming[]): AbilitySelector {
	switch (abilityType) {
		case AbilitySelectionStyles.SINGLE:
			const flattened = flattenAndPreFakeWeight(data)
			return new WeightedRandomAbilitySelector(flattened)
		case AbilitySelectionStyles.WEIGHTED_RANDOM:
			return new WeightedRandomAbilitySelector(data)
		case AbilitySelectionStyles.SEQUENCE:
			const flattened2 = flattenAndPreFakeWeight(data)
			return new WeightedRandomAbilitySelector(flattened2)
	}
}

function flattenAndPreFakeWeight(data: AbilityOptionallyWithWeightOrTiming[]): AbilityOptionallyWithWeightOrTiming[] {
	const flattened = flatten(data)
	flattened.unshift(100) // add 1 fake weight of 100. Value of 100 doesn't matter, there's only 1 weight
	return [flattened as any] // TODO2: fix ts error here properly!!
}

interface WeightedAbility extends WeightedRandom {
	abilities: Ability[]
	preDelay: number
}

// TODO2: currently SingleAbility and SequenceAbility are both handled by WeightedRandomAbility

// class SingleAbilitySelector implements AbilitySelector {
// 	private _ability: AbilityOptionallyWithWeightOrTiming
// 	private _done = false

// 	constructor(data: AbilityOptionallyWithWeightOrTiming[]) {
// 		this._ability = data[0] as AbilityOptionallyWithWeightOrTiming
// 	}
// 	chooseAbility(): Ability {
// 		if (this._done) {
// 			return null
// 		} else {
// 			this._done = true
// 			return getAbilityFromArrayOrAbility(this._ability)
// 		}
// 	}
// }

// export class SequenceAbilitySelector implements AbilitySelector {
// 	private _abilities: Ability[]
// 	private _curAbilityIdx = 0

// 	constructor(data: AbilityOptionallyWithWeightOrTiming) {
// 		this._abilities = getAbilityList(data, false)
// 		deepFreeze(this._abilities)
// 	}
// 	chooseAbility(): Ability {
// 		if (this._curAbilityIdx < this._abilities.length) {
// 			const ability = this._abilities[this._curAbilityIdx]
// 			console.assert(ability !== undefined)
// 			this._curAbilityIdx++
// 			return ability
// 		} else {
// 			return null
// 		}
// 	}
// }

export class WeightedRandomAbilitySelector implements AbilitySelector {
	private _abilities: WeightedAbility[]
	private _done = false
	private _currentList: Ability[] = null
	private _curListIdx = -1

	// example data:
	//  [10, playAnimationAbility(AnimationTrack.SUMMON_MINIONS, 15), 0, spawnFourBlimpiesAbility],

	constructor(abilityDataList: AbilityOptionallyWithWeightOrTiming[]) {
		this._abilities = abilityDataList.map((element) => {
			const abilityList = getAbilityList(element, true)
			return { weight: element[WEIGHT_INDEX], abilities: abilityList, preDelay: element[2] }
		})
	}

	chooseAbility(): Ability {
		if (!this._done) {
			if (this._currentList == null) {
				const choice = rollWeightedRandom(this._abilities)
				this._currentList = choice.abilities
				this._curListIdx = -1
			}

			this._curListIdx++

			this._done = this._curListIdx === this._currentList.length

			if (!this._done) {
				return this._currentList[this._curListIdx]
			}
		}

		return null
	}
}

export function getAbilityList(abilityData: AbilityOptionallyWithWeightOrTiming, weightedList: boolean): Ability[] {
	if (!Array.isArray(abilityData)) {
		return [abilityData]
	}

	const abilityList: Ability[] = []

	const startIdx = weightedList ? 1 : 0

	for (let i = startIdx; i < abilityData.length; i++) {
		const element = abilityData[i]
		if (typeof element === 'number') {
			abilityList.push(waitAbility(element))
		} else {
			abilityList.push(abilityData[i] as Ability)
		}
	}

	return abilityList
}
