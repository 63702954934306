




export default {
	name: 'CloseButton',
	props: {
		onClick: {
			type: Function,
			required: true,
			default: () => {
				console.warn('The default on-click handler for the <CloseButton /> component has been called. Did you forget to pass an :on-click handler to the component?')
			},
		},
	},
}
