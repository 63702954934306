import nengi from 'nengi'

export default class MetricMessage {
	static protocol = {
		metricNames: { type: nengi.String, indexType: nengi.UInt8 },
		metricValues: { type: nengi.Number, indexType: nengi.UInt8 },
	}

	metricNames: string[]
	metricValues: number[]

	constructor(metricNames: string[], metricValues: number[]) {
		this.metricNames = metricNames
		this.metricValues = metricValues
	}
}
