














import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
	name: 'SoulCycleProgressBar',
	data() {
		return {}
	},
	props: {},
	computed: {
		...mapGetters('boatLaunch', ['hasReachedBiomeOnTier', 'maxAllowedTier', 'getPressureRank', 'difficultyModifiers', 'totalPointsSelected', 'totalPointsForNextWorldTier', 'totalPointsForPreviousWorldTier', 'isValidPressureLoadout']),
		progressBarWidth: function() {
			return Math.clamp((this.totalPointsSelected / this.totalPointsForNextWorldTier) * 100, 0, 100)
		},
	},
	methods: {},
}
