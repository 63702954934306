import { BuffIdentifier } from './buff.shared'
import { BuffDefinition } from './buff-definition'
import { StackStyle } from './buff-enums'

export const EnemyBuffs: BuffDefinition[] = [
	new BuffDefinition({
		identifier: BuffIdentifier.BeachBossBlock,
		duration: 3000,
		startingStacks: 1,
		stackStyle: StackStyle.RollingStackDurationSeparately,
		reapplyStacks: 1,
		reapplyDuration: 3000,
		showToClient: true,
	}),

	new BuffDefinition({
		/* Example a buff that recursively reapplies itself, oscillating the movement speed bonus
		   between a positive and negative penalty */
		identifier: BuffIdentifier.EnemyOscillatingMovementSpeed,
		duration: 5000,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		showToClient: true,
	}),

	new BuffDefinition({
		/* This is a buff that can be applied to an enemy when they spawn, and it will automatically cause
		 * them to go berserk at low health (<30%).

		 * This same principal could be used for chaining boss phases together, e.g. phase 1 from 100% -> 60%
		 * health, then enters phase 2 until 20% health, then enters phase 3
		*/
		identifier: BuffIdentifier.EnemyAutoBerserkAtLowHealth,
		duration: 5000,
		lastsForever: true,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		showToClient: true,
		tickInterval: 100,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.HermitCrabBlock,
		duration: 3000,
		startingStacks: 1,
		stackStyle: StackStyle.RollingStackDurationSeparately,
		reapplyStacks: 1,
		reapplyDuration: 3000,
		showToClient: true,
	}),

	/*
		This is an example of a buff with a "falling off" bonus, where the bonus tapers off as the buff duration elapses
		Use tickInterval and the tickFn, calculating the expiresAtTime vs now vs duration (see below) to figure out how
		much time has elapsed. Just consider the stacking characteristic of the buff.
	*/
	new BuffDefinition({
		identifier: BuffIdentifier.EnemyCrabMovementSpeedSlowDifficult,
		duration: 2000,
		tickInterval: 250,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyStacks: 0,
		reapplyDuration: 2000,
		showToClient: true,
	}),
	new BuffDefinition({
		identifier: BuffIdentifier.EnemyCrabMovementSpeedSlow,
		duration: 2000,
		tickInterval: 250,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyStacks: 0,
		reapplyDuration: 2000,
		showToClient: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.EnemyShriekerBeShrieking,
		duration: 20000,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		showToClient: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.EnemyShriekerBeShriekingT3,
		duration: 20000,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		showToClient: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.EnemyMosquitoMovementSpeedBoost,
		duration: 10000, // effectively the cooldown
		tickInterval: 4500,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyStacks: 0,
		reapplyDuration: 5000,
		showToClient: true,
	}),

	// TODO2 - Update these buffs to be whatever the crystal spiders buff should be
	new BuffDefinition({
		identifier: BuffIdentifier.CrystalSpiderBlock,
		duration: 3000,
		startingStacks: 1,
		stackStyle: StackStyle.RollingStackDurationSeparately,
		reapplyStacks: 1,
		reapplyDuration: 3000,
		showToClient: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.CrystalSpiderDebuff,
		duration: 2000,
		tickInterval: 250,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyStacks: 0,
		reapplyDuration: 2000,
		showToClient: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.EnemyPrismBossGustWeakness,
		duration: 6000,
		tickInterval: 250,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyStacks: 0,
		reapplyDuration: 2000,
		showToClient: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.EnemyPrismBossCocoon,
		duration: 100,
		lastsForever: true,
		tickInterval: 500,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyStacks: 0,
		reapplyDuration: 100,
		showToClient: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.BoneSpiritHealing,
		duration: 300,
		lastsForever: false,
		tickInterval: 250,
		startingStacks: 1,
		stackStyle: StackStyle.RollingStackDurationSeparately,
		reapplyStacks: 1,
		reapplyDuration: 250,
		showToClient: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.SporeKidHealing,
		duration: 8100,
		lastsForever: false,
		tickInterval: 500,
		startingStacks: 1,
		stackStyle: StackStyle.RollingStackDurationSeparately,
		reapplyStacks: 1,
		reapplyDuration: 500,
		showToClient: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.EnemySporeKidWeakness,
		duration: 3000,
		tickInterval: 250,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyStacks: 0,
		reapplyDuration: 1000,
		showToClient: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.EnemyHighlandsBossOnyxShield,
		duration: 8000,
		lastsForever: false,
		tickInterval: 250,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		showToClient: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.EnemyHighlandsBossOnyxShieldExplode,
		duration: 2000,
		lastsForever: false,
		tickInterval: 250,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		showToClient: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.EnemyHighlandsBossPetrifyingWave,
		duration: 11000,
		tickInterval: 250,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyStacks: 0,
		reapplyDuration: 9000,
		showToClient: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.EnemyCooldown1000PerStack,
		duration: 1000,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		showToClient: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.EnemyCooldown15000,
		duration: 15000,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		showToClient: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.EnemyCooldown10000,
		duration: 10000,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		showToClient: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.EnemyCooldown5000,
		duration: 5000,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		showToClient: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.EnemyCooldown3000,
		duration: 3000,
		startingStacks: 1,
		stackStyle: StackStyle.None,
		showToClient: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.EnemyForestBossHeal,
		duration: 100,
		lastsForever: true,
		tickInterval: 250,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyStacks: 0,
		reapplyDuration: 100,
		showToClient: true,
	}),

	
	new BuffDefinition({
		identifier: BuffIdentifier.EnemyForestBossHealBrutal,
		duration: 100,
		lastsForever: true,
		tickInterval: 250,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyStacks: 0,
		reapplyDuration: 100,
		showToClient: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.EnemyPrismflyMovementSlow,
		duration: 5000,
		tickInterval: 2000,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyStacks: 1,
		reapplyDuration: 5000,
		showToClient: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.EnemyShardGuard,
		duration: 5000,
		startingStacks: 1,
		reapplyStacks: 1,
		reapplyDuration: 5000,
		stackStyle: StackStyle.RollingStackDurationSeparately,
		showToClient: true,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.KnockbackResist,
		duration: 5000,
		startingStacks: 1,
		stackStyle: StackStyle.RefreshDuration,
		reapplyStacks: 1,
		reapplyDuration: 5000,
		showToClient: false,
	}),

	new BuffDefinition({
		identifier: BuffIdentifier.DelayedKnockbackResist,
		duration: 5000,
		startingStacks: 1,
		stackStyle: StackStyle.RollingStackDurationSeparately,
		reapplyStacks: 1,
		reapplyDuration: 5000,
		showToClient: false,
	}),
]
