





























































































import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import ListItemContainer from '../reusable-components/item-containers/list-item-container.vue'
import ArrowButton from '../reusable-components/buttons/arrow-button.vue'
import AmountSelector from '../reusable-components/buttons/amount-selector.vue'
import InventoryItem from '../reusable-components/inventory-item/inventory-item.vue'
import TextButton from '../reusable-components//buttons/text-button.vue'
import DropDown from '../reusable-components/inputs/dropdown.vue'
import TextInput from '../reusable-components/inputs/text-input.vue'
import PanelDivider from '../reusable-components/panel-divider/panel-divider.vue'
import { UI } from '../ui'
import { LISTING_FEE, MAX_LISTING_PRICE, PROFIT_FEE } from '../state/marketplace.ui-state'
import { marketplaceListTooltipText } from '../reusable-components/v-tooltip/v-tooltip-functions'
import { LISTING_FEE_PERCENT } from '../../utils/economy-data'

export default {
	name: 'AddMarketplaceListing',
	components: {
		MainPanel,
		ListItemContainer,
		ArrowButton,
		TextButton,
		InventoryItem,
		DropDown,
		TextInput,
		PanelDivider,
		AmountSelector,
	},
	data() {
		return {
			expiry: '168',
			price: 0,
			expiryHours: [
				{ id: '1', name: '1 hour' },
				{ id: '8', name: '8 hours' },
				{ id: '24', name: '1 day' },
				{ id: '72', name: '3 days' },
				{ id: '168', name: '7 days' },
			],
			listingFee: LISTING_FEE,
			profitFee: PROFIT_FEE,
			maxListPrice: MAX_LISTING_PRICE
		}
	},
	computed: {
		...mapGetters('stash', ['selectedStashItem']),
		...mapGetters('itemContainers', ['itemsInContainer']),
		...mapState('marketplaceUpdated', ['itemPrice', 'numberOfStacks']),
		...mapGetters('marketplaceUpdated', ['currentlySelectedItem', 'currentlySelectedItemID']),
		...mapGetters('hud', ['coinBalance', 'scalesBalance']),
		...mapState('marketplaceUpdated', ['useFreeMarketplaceListing']),
		listableItems() {
			function filterFunc(item) {
				return (!item.equippedAugmentIds || item.equippedAugmentIds.length === 0)
			}
			return this.itemsInContainer('stash').filter(filterFunc)
		},
		canUserPayListingFee() {
			let totalPrice = this.selectedItemIsStackable ? this.itemPrice * this.numberOfStacks : this.itemPrice
			const price = parseInt(totalPrice, 10)
			const listingFee = this.getListingFee

			if (price <= 0) {
				return false
			}

			return parseInt(this.scalesBalance, 10) >= listingFee
		},
		getListingFee() {
			if (this.itemPrice <= 0) {
				return 0
			}

			let totalPrice = this.selectedItemIsStackable ? this.itemPrice * this.numberOfStacks : this.itemPrice
			let listingFee = Math.max(1, Math.round(totalPrice * LISTING_FEE_PERCENT))			

			return listingFee
		},
		getListTooltip() {
			if (this.$data.price) {
				const listingFee = this.getListingFee
				const canPayFee = parseInt(this.scalesBalance, 10) >= listingFee
				return marketplaceListTooltipText(canPayFee, listingFee)
			}
			return undefined
		},
		selectedItemIsStackable() {
			return this.currentlySelectedItem[0].stackAmount > 0
		},
		getTotalPrice() {
			if(this.itemPrice) {
				return this.itemPrice * this.numberOfStacks
			}
			return 0
		}
	},
	methods: {
		itemIsSelected(itemId) {
			return itemId === this.selectedStashItem
		},
		...mapActions('stash', ['toggleSelectStashItem']),
		...mapActions('inGame', ['closeActivePanel']),
		...mapMutations('inGame', ['setActivePanel']),
		...mapActions('marketplaceUpdated', ['selectInventoryItemToSell', 'submitItemListing']),
		...mapMutations('marketplaceUpdated', ['deselectItemToSell', 'setItemPrice', 'setNumberOfStacks']),
		marketplaceListTooltipText,
		backToMarketplace() {
			UI.getInstance().emitEvent('inGame/setActivePanel', 'marketplaceUpdated')
			UI.getInstance().emitAsyncEvent('marketplaceUpdated/fetchListings')
		},
		updatedPrice(newPrice) {
			let price = Math.round(parseInt(newPrice))

			if (isNaN(price)) {
				this.setItemPrice(0)
				return
			}

			if(price > MAX_LISTING_PRICE) {
				this.setItemPrice(MAX_LISTING_PRICE)
				return
			}

			this.setItemPrice(price)
		},
		updateExpiry(event) {
			const newExpiry = event.target.value || '168'
			this.$data.expiry = newExpiry
		},
		updatedStackAmount(stacks) {
			this.setNumberOfStacks(stacks)
		},
		listItem() {
			const price = this.itemPrice
			const expiry = this.$data.expiry || '168'
			const stacks = this.selectedItemIsStackable ? this.numberOfStacks : undefined
			this.submitItemListing({ price, expiry, stacks })
			this.$data.expiry = '168'
			this.setActivePanel('userMarketListing') //go back to 'my listings'
		},
	},
}
