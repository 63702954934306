import { clearNotificationsRequest } from '../../utils/api/atlas-requests.client'
import logger from '../../utils/client-logger'
import { UI } from '../ui'

export const notificationsUIModule = () => {
	logger.debug('Initializing Notifications store module...')
	return {
		namespaced: true,
		state: {
			notifications: [],
		},
		getters: {
			getNotificationSubjects(state: any) {
				return state.notifications.map((o) => o.subject)
			},
			getFirstNotificationSubject(state: any) {
				if (state.notifications.length > 0) {
					return state.notifications[0].subject
				}

				return null
			},
			getNotificationCount(state: any) {
				return state.notifications.length
			},
		},
		mutations: {
			addNotification(state: any, {subject, id}) {
				const found = state.notifications.find((o) => o.id === id)
				if(!found) {
					state.notifications.push({subject, id})
				}
			},
			clearNotifications(state: any) {
				state.notifications = []
			},
		},
		actions: {
			async followNotification({ state, rootState }) {
				if (state.notifications.length > 0) {
					const subject = state.notifications[0].subject


					if (subject === "sold_item") {
						UI.getInstance().emitEvent('inGame/setActivePanel', 'userMarketListing')
					}

					// Clear out all pending notifications
					// This works now when we just have the one type, but will need to be changed if we add more

					state.notifications = []

					const clearResults = await clearNotificationsRequest(rootState.user.authentication.token)
				}
			},
		},
	}
}
