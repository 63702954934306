import logger from '../../utils/client-logger'
import { UI } from '../ui'
import { NengiClient } from '../../engine/client/nengi-client'
import AttemptStorePurchaseCommand from '../../items/shared/attempt-store-purchase-command'
import { analyticsEventGeneralStorePurchase } from './google-analyitics'

export const generalStoreModule = () => {
	logger.debug('Initializing Store store module...')
	return {
		namespaced: true,
		state: {
			storeContents: [],
			currentlyInteractingWithPOIId: false,
			isPurchasing: false,
			resetIsPurchasingCounter: 0,
		},
		getters: {
			getStoreContents(state) {
				if (state.currentlyInteractingWithPOIId) {
					const filteredContents = []
					for (let i = 0; i < state.storeContents.length; ++i) {
						if (state.storeContents[i].availablePois.length === 0 ||
							state.storeContents[i].availablePois.includes(state.currentlyInteractingWithPOIId)) {
							filteredContents.push(state.storeContents[i])
						}
					}
					return filteredContents
				} else {
					return state.storeContents
				}
			},
			getStoreContentsRaw(state) {
				return state.storeContents
			}
		},
		actions: {
			attemptPurchaseProduct({ commit, state }, { productId, poiId }) {
				const username = UI.getInstance().store.getters['user/username']
				const userId = UI.getInstance().store.getters['user/userId']
				const productDetails = state.storeContents.filter((item) => item.id === productId)

				analyticsEventGeneralStorePurchase(productDetails, username, userId)
				commit('updatePlayerPurchasing', true)
				logger.debug('attempting purchase of product #', productId, 'from poi', poiId)
				NengiClient.getInstance().sendCommand(new AttemptStorePurchaseCommand(productId, poiId))
				const purchaseId = state.resetIsPurchasingCounter
				setTimeout(() => {
					if (state.resetIsPurchasingCounter === purchaseId) {
						commit('updatePlayerPurchasing', false)
					}
				}, 15000)
			},
		},
		mutations: {
			updateStoreContents(state, updatedStoreContents) {
				state.storeContents = [...updatedStoreContents]
			},
			interactingWithPOIId(state: any, poiId: number) {
				state.currentlyInteractingWithPOIId = poiId
			},
			updatePlayerPurchasing(state, isPurchasing) {
				state.isPurchasing = isPurchasing
				state.resetIsPurchasingCounter++
			},
		},
	}
}
