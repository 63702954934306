import ItemType, { getItemSubTypePrettyName } from '../../loot/shared/item-type'

export function isHighRarityItem(itemDetails) {
	const rarityUpper = itemDetails.rarity.toUpperCase()

	if (rarityUpper === 'EPIC' || rarityUpper === 'LEGENDARY' || rarityUpper === 'ASTRONOMICAL') {
		return true
	}
	return false
}

export function isBiomeKey(itemDetails) {
	const itemTypeEnum = itemDetails.itemTypeEnum
	if (itemTypeEnum === ItemType.BiomeKey) {
		return true
	}
	return false
}

export function isPrize(itemDetails) {
	const itemTypeEnum = itemDetails.itemTypeEnum
	if (itemTypeEnum === ItemType.Prize) {
		return true
	}
	return false
}

export function isShard(itemDetails) {
	const itemTypeEnum = itemDetails.itemTypeEnum
	if (itemTypeEnum === ItemType.LuckyShard || itemTypeEnum === ItemType.RarityShard) {
		return true
	}
	return false
}

export function rarityPromptText(itemDetails, panelOperationName: 'pitOfChances' | 'inventory' | 'furnace') {
	const itemTypeEnum = itemDetails.itemTypeEnum
	const itemSubType = itemDetails.itemSubType
	const itemName = itemDetails.name
	const rarityUpper = itemDetails.rarity.toUpperCase()
	const level = itemDetails.level

	if (panelOperationName === 'pitOfChances' || panelOperationName === 'inventory') {
		if (itemTypeEnum === ItemType.Prize) {
			return `Are you sure you want to toss this ${itemName}?`
		} else if (itemTypeEnum === ItemType.WormHorn) {
			return `Are you sure you want to toss this ${getItemSubTypePrettyName(itemTypeEnum, itemSubType)}?`
		} else {
			return `Are you sure you want to toss this ${rarityUpper} item?`
		}
	}
	if (panelOperationName === 'furnace') {
		if (itemTypeEnum === ItemType.WeaponAugment) {
			return `Are you sure you want to smelt ${rarityUpper} level ${level} ${getItemSubTypePrettyName(itemTypeEnum, itemSubType)} Augment?`
		} else if (itemTypeEnum === ItemType.RarityShard || itemTypeEnum === ItemType.LuckyShard) {
			return `Are you sure you want to smelt ${rarityUpper} shard?`
		} else if (itemTypeEnum === ItemType.WormHorn) {
			return `Are you sure you want to smelt ${getItemSubTypePrettyName(itemTypeEnum, itemSubType)}?`
		} else {
			return `Are you sure you want to smelt ${rarityUpper} level ${itemDetails.level} ${getItemSubTypePrettyName(itemTypeEnum, itemSubType)}?`
		}
	}
}
