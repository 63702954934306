





















import MainPanel from '../reusable-components/panels/main-panel.vue'
import ArrowButton from '../reusable-components/buttons/arrow-button.vue'
import ButtonedSkinGridItemContainer from '../reusable-components/item-containers/button-skin-grid-item-container.vue'
import { PlayerSkinType } from '../../models-animations/shared/spine-config'
import { mapActions } from 'vuex'

export default {
	name: 'FeaturedSales',
	components: {
		MainPanel,
		ArrowButton,
		ButtonedSkinGridItemContainer,
	},
	props: {},
	data() {
		return {
			hardCodeditems: [
				//don't need sku really
				{ id: PlayerSkinType.BARBARIAN, sku: 'skins-chars-animal-barbarian', type: 'Player Skin', itemCost: '000', cosmeticType: 'Player Skin', identifier: 'animal-barbarian', source: 'free?' },
				{ id: PlayerSkinType.RANGER_02, sku: 'skins-chars-animal-ranger-02', type: 'Player Skin', itemCost: '000', cosmeticType: 'Player Skin', identifier: 'ranger-02', source: 'free?' },
				{ id: 'biome-key-forest', sku: 'terila-forest-biome-key', type: 'consumable', itemCost: '00' },
				{ id: 'biome-key-onyx', sku: 'onyx-highlands-biome-key', type: 'consumable', itemCost: '0' },
				{ id: 'biome-key-fungi', sku: 'carmine-fungi-steppes-biome-key', type: 'consumable', itemCost: '000' },
				{ id: 'biome-key-prism', sku: 'prism-sunset-cliffs-biome-key', type: 'consumable', itemCost: '00' },
			],
		}
	},
	computed: {},
	methods: {
		...mapActions('inGame', ['setActivePanel']),
		getAllFeaturedItems(){
			let items = []

			items.push(items.filter((offer) => offer.sku.includes('faction-iron') || offer.sku.includes('faction-aurum') || offer.sku.includes('faction-scion')))
			items.push(items = items.filter((offer) => offer.offerStart !== null))
			items.push(items.filter((offer) => offer.offerStart === null && offer.quantityRemaining !== null))

			return items
		}
	},
}
