import logger from '../../utils/client-logger'
import PlayerStartShieldRegenMessage from '../../player/shared/player-start-shield-regen-message'
import { setIntervalUntil } from '../../utils/timing'
import { highResolutionTimestamp } from '../../utils/debug'
import PlayerUpdateShieldMessage from '../../player/shared/player-update-shield-message'
import { FRAME_DURATION } from '../../engine/client/client-constants'
import SendMinimapUpdateMessage from '../../player/shared/send-minimap-update-message'
import { mapToRange } from '../../utils/math'
import { MINIMAP_PIP_SIZE_OTHERS, MINIMAP_WIDTH, MINIMAP_HEIGHT, HALF_MINIMAP_GRID_SEARCH_RANGE } from '../../player/shared/minimap-settings'
import SkillUsedMessage from '../../gear-skills/shared/skill-used-message'
import EquippedNewGearMessage from '../../items/shared/equipped-new-gear-message'
import { POIType } from '../../world/shared/poi-data-types'
import { UI } from '../ui'
import { NengiClient } from '../../engine/client/nengi-client'
import RequestArrowPositionCommand from '../../ftue/shared/request-arrow-position-command'
import { ArrowPositionRequestValue } from '../../ftue/shared/arrow-position-request-values'
import { getWorldDifficultyPrettyString, WorldDifficulty } from '../../engine/shared/game-data/world-difficulty'
import AlertMessage from '../../chat/shared/alert-message'
import SkillCooldownUpdatedMessage from '../../gear-skills/shared/skill-updated-message'

const CHUNK_SIZE = 512
const DEFAULT_ALERT_COLOR = '#e9e5ad'

export const showTutorialTooltip = (params) => {
	UI.getInstance().emitEvent('hud/showTutorialTooltip', params)
}

export const clearAllTutorialTooltips = () => {
	UI.getInstance().emitEvent('hud/clearAllTutorialTooltips')
}

export const hudModule = () => {
	logger.debug('Initializing HUD store module...')
	return {
		namespaced: true,
		state: {
			gcPause: false,
			ping: 0,
			fps: 60,
			fpsSpike: 203,
			buildHash: process.env.BUILD_HASH || process.env.COMMIT_REF,
			provider: process.env.CLIENT_SERVED_BY,
			serverYoureOn: false,
			serverWorldDifficulty: false,
			health: 100,
			isHealthFlashing: false,
			energy: 100,
			currentShields: 0,
			maxShields: 0,
			shieldRegenRate: 0,
			shieldRegenPercent: 0,
			shieldIndicatorTimer: null,
			level: 1,
			experienceProgressPercentage: 0,
			activeWeaponSlot: 'one',
			playerWeaponOut: true,
			inventoryWeight: 0,
			minimapEntities: [],
			shotCooldown: 0,
			isOnShotCooldown: 0,
			shouldDisplayShotCooldownIndicator: true,
			playerX: 0,
			playerY: 0,
			gearSkillTypes: {
				one: null,
				two: null,
				three: null,
			},
			gearSkillCooldowns: {
				one: { cooldownStartTime: 0, cooldownDuration: 0 },
				two: { cooldownStartTime: 0, cooldownDuration: 0 },
				three: { cooldownStartTime: 0, cooldownDuration: 0 },
			},
			coinBalance: 0,
			scalesBalance: '0',
			furthestBiome: 1,
			furthestWorldTier: 1,
			activeWorldTier: 1,
			currentMaxBiome: 0,
			currentBiome: 0,
			progressionLevel: 0,
			poi: {
				active: false,
				poiType: POIType.Outpost,
				bossHealth: 100,
				respawnTimer: '24',
				eventActive: false,
				text: 'crab boss',
				respawnText: 'respawn text',
			},
			tutorialTooltips: [
				// { target: 'firstWeapon', text: 'Clicking on your weapon will show its stats.', title: 'Select Your Weapon', position: 'bottom' },
				// { target: 'shields', text: 'Each shield soaks 1 hit.', title: 'Shields', position: 'bottom' },
				// { target: 'health', text: '', title: 'Health Bar', position: 'bottom' },
				// { target: 'weaponsAndGear', text: '', title: 'Equipped Weapons & Gear', position: 'top' }
			],
			tutorialArrows: [
				//{target: 'weapon2', direction: 'up'},
			],
			showEnchantments: false,
			navigationMenuVisible: false,
			previousArrowSearch: 'none',
			currentArrowSearch: 'none',
			partyDepartureCountdownValue: 'Soon...',
			partyDepartureCountdownVisible: false,
			alert: '',
			alertVisible: false,
			alertColor: DEFAULT_ALERT_COLOR,
			isConnectingToServer: true,
			isConnectedToServer: false,
			worldSeed: null,
		},
		getters: {
			partyDepartureTimeRemaining(state) {
				return state.partyDepartureCountdownValue
			},
			partyDepartureCountdownVisible(state) {
				return state.partyDepartureCountdownVisible
			},
			tutorialTooltips(state) {
				return state.tutorialTooltips
			},
			buildHash(state) {
				return state.buildHash
			},
			serverYoureOn(state) {
				return state.serverYoureOn
			},
			serverWorldDifficulty(state) {
				return state.serverWorldDifficulty
			},
			provider(state) {
				return state.provider
			},
			health(state) {
				return state.health
			},
			isHealthFlashing(state) {
				return state.isHealthFlashing
			},
			energy(state) {
				return state.energy
			},
			inventoryWeight(state) {
				return state.inventoryWeight
			},
			minimapEntities(state) {
				return state.minimapEntities
			},
			ping(state) {
				return state.ping
			},
			fps(state) {
				return state.fps
			},
			fpsSpike(state) {
				return state.fpsSpike
			},
			gearSkillCooldown1(state): { cooldownStartTime: number; cooldownDuration: number } {
				return state.gearSkillCooldowns.one
			},
			gearSkillCooldown2(state): { cooldownStartTime: number; cooldownDuration: number } {
				return state.gearSkillCooldowns.two
			},
			gearSkillCooldown3(state): { cooldownStartTime: number; cooldownDuration: number } {
				return state.gearSkillCooldowns.three
			},
			gearSkillCooldownsBySlot(state) {
				return state.gearSkillCooldowns
			},
			gearSkillTypesBySlot(state) {
				return state.gearSkillTypes
			},
			shotCooldown(state) {
				return state.shotCooldown
			},
			isOnShotCooldown(state) {
				return state.isOnShotCooldown
			},
			shouldDisplayShotCooldownIndicator(state) {
				return state.shouldDisplayShotCooldownIndicator
			},
			playerX(state) {
				return state.playerX
			},
			playerY(state) {
				return state.playerY
			},
			progressionLevel(state) {
				return state.progressionLevel
			},
			currentShields(state) {
				return state.currentShields
			},
			maxShields(state) {
				return state.maxShields
			},
			playerWeaponOut(state) {
				return state.playerWeaponOut
			},
			coinBalance(state) {
				return state.coinBalance
			},
			scalesBalance(state) {
				return state.scalesBalance
			},
			furthestBiome(state) {
				return state.furthestBiome
			},
			furthestWorldTier(state) {
				return state.furthestWorldTier
			},
			furthestSoulCycle(state) {
				return state.furthestWorldTier - 1
			},
			activeWorldTier(state) {
				return state.activeWorldTier
			},
			activeSoulCycle(state) {
				return state.activeWorldTier - 1
			},
			bossHealth(state) {
				return state.poi.bossHealth
			},
			poiRespawnTimer(state) {
				return state.poi.respawnTimer
			},
			poiActive(state) {
				return state.poi.active
			},
			poiEventActive(state) {
				return state.poi.eventActive
			},
			bossActive(state) {
				return state.poi.eventActive && state.poi.poiType === POIType.Boss
			},
			poiRespawn(state) {
				return !state.poi.eventActive && (state.poi.poiType === POIType.Boss || state.poi.poiType === POIType.Conquer || state.poi.poiType === POIType.MonsterRaid)
			},
			poiText(state) {
				return state.poi.text
			},
			poiRespawnText(state) {
				return state.poi.respawnText
			},
			level(state) {
				return state.level
			},
			currentMaxBiome(state) {
				return state.currentMaxBiome
			},
			showEnchantments(state) {
				return state.showEnchantments
			},
			gcPause(state) {
				return state.gcPause
			},
			showDebugText(state) {
				return process.env.NODE_ENV !== 'loot-prod' && process.env.NODE_ENV !== 'beta'
			},
			alert(state: any) {
				return state.alert
			},
			alertVisible(state: any) {
				return state.alertVisible
			},
			alertColor(state) {
				return state.alertColor
			},
			isConnectingToServer(state) {
				return state.isConnectingToServer
			},
			isConnectedToServer(state) {
				return state.isConnectedToServer
			},
			worldSeed(state) {
				return state.worldSeed
			},
			getPoiType(state){
				return state.poi.poiType
			}
		},
		actions: {
			startShieldRegen({ state, commit }, shieldState: PlayerStartShieldRegenMessage) {
				state.currentShields = shieldState.currentShields
				state.maxShields = shieldState.maxShields
				state.shieldRegenRate = shieldState.shieldRegenRate
				state.shieldRegenPercent = 0

				if (state.shieldIndicatorTimer) {
					commit('clearShieldIndicatorTimer')
				}
				const now = highResolutionTimestamp()
				const interval = setIntervalUntil(
					() => {
						const later = highResolutionTimestamp()
						const percent = ((later - now) / state.shieldRegenRate) * 0.7
						commit('updatedShieldRegenPercent', { percent, interval })
					},
					() => {
						commit('shieldRegenAnimationFinished')
					},
					FRAME_DURATION,
					state.shieldRegenRate,
				)
				commit('updatedShieldRegenPercent', { percent: 0, interval })
			},
			flashHealth({ state }) {
				state.isHealthFlashing = true
				setTimeout(() => {
					state.isHealthFlashing = false
				}, 100)
			},
			updateShowEnchantments({ state }, showEnchantments) {
				state.showEnchantments = showEnchantments
			},
			fetchNewArrowPosition({ state }) {
				if (state.currentArrowSearch === 'outpost') {
					NengiClient.getInstance().sendCommand(new RequestArrowPositionCommand(ArrowPositionRequestValue.OUTPOST))
				} else if (state.currentArrowSearch === 'poi') {
					NengiClient.getInstance().sendCommand(new RequestArrowPositionCommand(ArrowPositionRequestValue.POI))
				} else if (state.currentArrowSearch === 'boss') {
					NengiClient.getInstance().sendCommand(new RequestArrowPositionCommand(ArrowPositionRequestValue.BOSS))
				} else if (state.currentArrowSearch === 'altar') {
					NengiClient.getInstance().sendCommand(new RequestArrowPositionCommand(ArrowPositionRequestValue.ALTAR))
				} else if (state.currentArrowSearch === 'none') {
					NengiClient.getInstance().sendCommand(new RequestArrowPositionCommand(ArrowPositionRequestValue.NONE))
				} else {
					console.error('Unknown arrow requested!')
				}
			},
		},
		mutations: {
			updatePartyDepartureCountdown(state, newValue) {
				let str
				if (newValue === 0) {
					str = 'Now!'
				} else if (newValue >= 1) {
					str = `In ${newValue} seconds...`
				} else {
					str = 'Soon...'
				}
				state.partyDepartureCountdownValue = str
			},
			showPartyDepartureCountdown(state) {
				state.partyDepartureCountdownVisible = true
			},
			hidePartyDepartureCountdown(state) {
				state.partyDepartureCountdownVisible = false
			},
			setGCPause(state) {
				state.gcPause = true
				setTimeout(() => {
					state.gcPause = false
				}, 1000)
			},
			clearTutorialTooltip(state, tooltipTarget) {
				const updatedTooltips = state.tutorialTooltips.filter((tooltip: any) => {
					return tooltip.target !== tooltipTarget
				})
				state.tutorialTooltips = [...updatedTooltips]
			},
			clearAllTutorialTooltips(state) {
				state.tutorialTooltips = []
			},
			showTutorialTooltip(state, params) {
				const { target, text, title, position } = params
				const existingTooltip = state.tutorialTooltips.find((tooltip: any) => {
					return tooltip.target === target
				})
				if (existingTooltip) {
					return
				}
				state.tutorialTooltips = [...state.tutorialTooltips, { target, text, title, position }]
			},
			showTutorialArrow(state, params) {
				const { target, direction } = params
				const existingTooltip = state.tutorialArrows.find((tooltip: any) => {
					return tooltip.target === target
				})
				if (existingTooltip) {
					return
				}
				state.tutorialArrows.push({ target, direction })
			},
			clearTutorialArrow(state, target) {
				const updatedTooltips = state.tutorialArrows.filter((tooltip: any) => {
					return tooltip.target !== target
				})
				state.tutorialArrows = updatedTooltips
			},
			clearAllTutorialArrows(state) {
				state.tutorialArrows = []
			},
			joinedServer(state, newServer) {
				state.serverYoureOn = newServer
				state.currentArrowSearch = 'none'
				state.previousArrowSearch = 'none'
			},
			changedServerWorldDifficulty(state, newDifficulty: WorldDifficulty) {
				state.serverWorldDifficulty = getWorldDifficultyPrettyString(newDifficulty)
			},
			latestAvgFPS(state, newFps) {
				state.fps = newFps
			},
			latestPing(state, newPing) {
				state.ping = newPing
			},
			fpsSpiked(state, newSpikeDuration) {
				state.fpsSpike = newSpikeDuration
			},
			updatedActiveWeaponSlot(state, newSlot: string) {
				state.activeWeaponSlot = newSlot
			},
			updatedLevel(state, newLevel: number) {
				state.level = newLevel
			},
			updatedCurrentExperiencePercentage(state, newExperiencePercentage: number) {
				state.experienceProgressPercentage = newExperiencePercentage
			},
			updatedHealthPercentage(state, newHealthPercentage: number) {
				state.health = Math.floor(Math.clamp(newHealthPercentage, 0, 100))
			},
			updatedEnergyPercentage(state, newEnergyPercentage: number) {
				state.energy = Math.floor(Math.clamp(newEnergyPercentage, 0, 100))
			},
			updatedShotCooldownPercentage(state, newShotCooldownPercentage: number) {
				state.shotCooldown = Math.floor(Math.clamp(newShotCooldownPercentage, 0, 100))
			},
			updateIsOnShotCooldown(state, params: { newIsOnShotCooldown: boolean; shouldDisplayShotCooldownIndicator?: boolean }) {
				state.isOnShotCooldown = params.newIsOnShotCooldown ? 1 : 0
				if (params.shouldDisplayShotCooldownIndicator !== undefined) {
					state.shouldDisplayShotCooldownIndicator = params.shouldDisplayShotCooldownIndicator
				}
			},
			updatePlayerXPosition(state, newX: number) {
				state.playerX = newX
			},
			updatePlayerYPosition(state, newY: number) {
				state.playerY = newY
			},
			updatedShieldRegenPercent(state, payload) {
				const { percent, interval } = payload
				state.shieldRegenPercent = percent
				state.shieldIndicatorTimer = interval
			},
			shieldRegenAnimationFinished(state) {
				state.shieldRegenPercent = 0
				state.shieldRegenRate = 0
				state.currentShields += 1
				if (state.shieldIndicatorTimer) {
					clearInterval(state.shieldIndicatorTimer)
					state.shieldIndicatorTimer = null
				}
			},
			clearShieldIndicatorTimer(state) {
				if (state.shieldIndicatorTimer) {
					clearInterval(state.shieldIndicatorTimer)
					state.shieldIndicatorTimer = null
				}
			},
			updatedShields(state, message: PlayerUpdateShieldMessage) {
				state.currentShields = message.currentShields
				state.maxShields = message.maxShields
			},
			updateInventoryWeight(state, newInventoryWeight: number) {
				state.inventoryWeight = newInventoryWeight
			},
			updateCurrentBiome(state, newBiome) {
				state.currentBiome = newBiome
			},
			updatedMinimap(state, message: SendMinimapUpdateMessage) {
				state.minimapEntities.length = 0
				for (let i = 0; i < message.nearby_nid.length; i++) {
					state.minimapEntities.push({
						nid: message.nearby_nid[i],
						type: message.nearby_type[i],
						x: message.nearby_x[i],
						y: message.nearby_y[i],
					})
				}

				// remap to pixel range
				const minX = message.posX - CHUNK_SIZE * HALF_MINIMAP_GRID_SEARCH_RANGE
				const maxX = message.posX + CHUNK_SIZE * HALF_MINIMAP_GRID_SEARCH_RANGE
				const minY = message.posY - CHUNK_SIZE * HALF_MINIMAP_GRID_SEARCH_RANGE
				const maxY = message.posY + CHUNK_SIZE * HALF_MINIMAP_GRID_SEARCH_RANGE

				for (const entity of state.minimapEntities) {
					entity.x = mapToRange(entity.x, minX, maxX, 0 + MINIMAP_PIP_SIZE_OTHERS, MINIMAP_WIDTH - MINIMAP_PIP_SIZE_OTHERS - 1, true)
					entity.y = mapToRange(entity.y, minY, maxY, 0 + MINIMAP_PIP_SIZE_OTHERS, MINIMAP_HEIGHT - MINIMAP_PIP_SIZE_OTHERS, true)
				}
			},
			gearSkillUsed(state, message: SkillUsedMessage) {
				const now = highResolutionTimestamp()
				switch (message.skillSlot) {
					case 1:
						state.gearSkillCooldowns.one.cooldownStartTime = now
						state.gearSkillCooldowns.one.cooldownDuration = message.cooldownDuration
						break
					case 2:
						state.gearSkillCooldowns.two.cooldownStartTime = now
						state.gearSkillCooldowns.two.cooldownDuration = message.cooldownDuration
						break
					case 3:
						state.gearSkillCooldowns.three.cooldownStartTime = now
						state.gearSkillCooldowns.three.cooldownDuration = message.cooldownDuration
						break
				}
			},
			gearSkillCooldownUpdated(state, message: SkillCooldownUpdatedMessage) {
				switch (message.skillSlot) {
					case 1:
						state.gearSkillCooldowns.one.cooldownDuration = message.cooldownDuration
						break
					case 2:
						state.gearSkillCooldowns.two.cooldownDuration = message.cooldownDuration
						break
					case 3:
						state.gearSkillCooldowns.three.cooldownDuration = message.cooldownDuration
						break
				}
			},
			gearSkillCooldownElapsed(state, data: { entityId: number; skillId: number; slot: string }) {
				//TODO2: cool visual effect
			},
			updatedGearSkill(state, message: EquippedNewGearMessage) {
				state.gearSkillTypes[message.slot] = message.skillType
			},
			updatePlayerWeaponOut(state, isOut) {
				state.playerWeaponOut = isOut
			},
			updateCoinBalance(state, numCoins) {
				state.coinBalance = numCoins
			},
			updateScalesBalance(state, numScales) {
				state.scalesBalance = numScales
			},
			updateProgression(state, { furthestBiome, furthestWorldTier, currentMaxBiome, progressionLevel }) {
				state.furthestBiome = furthestBiome
				state.furthestWorldTier = furthestWorldTier
				state.currentMaxBiome = currentMaxBiome
				state.progressionLevel = progressionLevel
			},
			updateActiveWorldTier(state, worldTier) {
				state.activeWorldTier = worldTier
			},
			poiType(state, poiType) {
				state.poi.poiType = poiType
			},
			poiActive(state, active) {
				state.poi.active = active
			},
			poiEventActive(state, eventActive) {
				if (!eventActive) {
					state.poi.respawnTimer = null
				}
				state.poi.eventActive = eventActive
			},
			poiText(state, text) {
				state.poi.text = text
			},
			bossHealth(state, health) {
				state.poi.bossHealth = health * 100
			},
			poiRespawnTimer(state, timer) {
				state.poi.respawnTimer = timer.toFixed(0)
			},
			poiRespawnText(state, respawnText) {
				state.poi.respawnText = respawnText
			},
			toggleNavigationMenuVisible(state) {
				state.navigationMenuVisible = !state.navigationMenuVisible
			},
			setNavigationMenuVisible(state, visible) {
				state.navigationMenuVisible = visible
			},
			setCurrentArrowSearch(state, newSearch) {
				if (newSearch !== 'poi' && newSearch === this.state.currentArrowSearch) {
					return
				}

				if (newSearch) {
					state.currentArrowSearch = newSearch
					state.previousArrowSearch = newSearch
				} else {
					state.currentArrowSearch = 'none'
					state.previousArrowSearch = 'none'
				}

				if (newSearch === 'outpost') {
					NengiClient.getInstance().sendCommand(new RequestArrowPositionCommand(ArrowPositionRequestValue.OUTPOST))
				} else if (newSearch === 'poi') {
					NengiClient.getInstance().sendCommand(new RequestArrowPositionCommand(ArrowPositionRequestValue.POI))
				} else if (newSearch === 'boss') {
					NengiClient.getInstance().sendCommand(new RequestArrowPositionCommand(ArrowPositionRequestValue.BOSS))
				} else if (newSearch === 'altar') {
					NengiClient.getInstance().sendCommand(new RequestArrowPositionCommand(ArrowPositionRequestValue.ALTAR))
				} else if (newSearch === 'none') {
					NengiClient.getInstance().sendCommand(new RequestArrowPositionCommand(ArrowPositionRequestValue.NONE))
				} else {
					console.error('Unknown arrow requested!')
				}
			},
			receivedAlert(state: any, message: AlertMessage) {
				state.alert = message.message
				state.alertVisible = true

				if(message.color) {
					state.alertColor = message.color
				} else {
					state.alertColor = DEFAULT_ALERT_COLOR
				}

				setTimeout(() => {
					state.alertVisible = false
				}, message.duration)
			},
			setIsConnectingToServer(state, connecting) {
				state.isConnectingToServer = connecting
				state.isConnectedToServer = false
			},
			setIsConnectedToServer(state, isConnected) {
				state.isConnectingToServer = false
				state.isConnectedToServer = isConnected
			},
			setWorldSeed(state, worldSeed) {
				state.worldSeed = worldSeed
			},
			setArrowToAltarTemporarily(state) {
				state.previousArrowSearch = state.currentArrowSearch
				state.currentArrowSearch = 'altar'

				NengiClient.getInstance().sendCommand(new RequestArrowPositionCommand(ArrowPositionRequestValue.ALTAR))
			},
			returnArrowToPrevious(state) {
				state.currentArrowSearch = state.previousArrowSearch

				if (state.currentArrowSearch === 'outpost') {
					NengiClient.getInstance().sendCommand(new RequestArrowPositionCommand(ArrowPositionRequestValue.OUTPOST))
				} else if (state.currentArrowSearch === 'poi') {
					NengiClient.getInstance().sendCommand(new RequestArrowPositionCommand(ArrowPositionRequestValue.POI))
				} else if (state.currentArrowSearch === 'boss') {
					NengiClient.getInstance().sendCommand(new RequestArrowPositionCommand(ArrowPositionRequestValue.BOSS))
				} else if (state.currentArrowSearch === 'altar') {
					NengiClient.getInstance().sendCommand(new RequestArrowPositionCommand(ArrowPositionRequestValue.ALTAR))
				} else if (state.currentArrowSearch === 'none') {
					NengiClient.getInstance().sendCommand(new RequestArrowPositionCommand(ArrowPositionRequestValue.NONE))
				}
			},
			resetHudBiome(state){
				state.currentBiome = 0
			}
		},
	}
}
