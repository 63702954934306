import nengi from 'nengi'

export default class HideWormMessage {
	static protocol = {
		wormId: { type: nengi.Number },
	}

	wormId: number

	constructor(wormId: number) {
		this.wormId = wormId
	}
}
