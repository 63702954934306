import nengi from 'nengi'

class AIDebugStringMessage {
	static protocol = {
		info: { type: nengi.String },
	}

	info: string

	constructor(info) {
		this.info = info
	}
}

export default AIDebugStringMessage
