import { AnyRewardData, RewardType } from '../../items/shared/reward-types'
import BiomeKeyType from '../../loot/shared/biome-key'
import { GeneralStoreItemsSubType } from '../../loot/shared/general-store-items-sub-type'
import ItemType from '../../loot/shared/item-type'
import { DogType, HamsterType } from '../../loot/shared/prize-sub-type'
import { WormHornSubtype } from '../../loot/shared/worm-horn-sub-type'
import { AccountBuffIdentifier } from '../../player/shared/account-persisted-buff-types'
import { SKIN_SLOT } from '../../player/shared/set-skin.shared'
import { PersistentBuffSubType } from '../../loot/shared/persistent-buff-sub-type'


export interface DefinedLoginReward {
	day: number
	isMilestone?: boolean
	reward: AnyRewardData | AnyRewardData[]
}

const DEFINED_DAILY_LOGIN_REWARDS: DefinedLoginReward[] = [
	{
		day: 0, // <<example of multiple rewards in a defined day>>
		reward: [
			// <- array instead of object
			{
				rewardType: RewardType.ACCOUNT_BUFF,
				buff: {
					identifier: AccountBuffIdentifier.MoveSpeed,
					uses: 3,
					createdAt: '',
					expiresAt: '',
				},
				name: 'Zoomies 3-Pack',
				hoverTooltip: '+15% move speed for 5 mins on your next run.  Automatically applied to your account',
				itemSubType: GeneralStoreItemsSubType.Health,
				rarity: 'uncommon',
				// <<not this stuff>>
				//	rewardType: RewardType.COIN,
				//	amount: 300,

				//
				// rewardType: RewardType.SKIN,
				// isSkin: true,
				// skinSlot: SKIN_SLOT.PLAYER_MAIN
				// mtxIdentifier: Add all Base skins OTHER than the dwarf skin in this reward,
			},
			{
				rewardType: RewardType.ITEM_BUNDLE,
				rewards: [	{ itemType: ItemType.PersistentBuff, itemSubType: PersistentBuffSubType.LifeBloom },
					],
			},
			// <<this stuff>>
			// {
			// 	rewardType: RewardType.ITEM_BUNDLE,
			// 	rewards: [
			// 		{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.FUNGI_STEEPS },
			// 		{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.ONYX_HIGHLANDS },
			// 	],
			// 	//	rewardType: RewardType.COIN,
			// 	//	amount: 300,
			// },
		],
	},
	{
		day: 1,
		isMilestone: true,
		reward: [
			// {
			// 	rewardType: RewardType.ITEM_BUNDLE,
			// 	rewards: [
			// 		{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.FUNGI_STEEPS },
			// 		{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.ONYX_HIGHLANDS },
			// 	],
			// },
			{
				rewardType: RewardType.ITEM_BUNDLE,
				rewards: [	
						{ itemType: ItemType.PersistentBuff, itemSubType: PersistentBuffSubType.RegenerativeHealing, stackSize: 2 }
					],
			},
			{
				rewardType: RewardType.COIN,
				amount: 1000,
			},
		],
	},
	{
		day: 2,
		isMilestone: true,
		reward: [
			{
				rewardType: RewardType.ITEM_BUNDLE,
				rewards: [{ itemType: ItemType.WormHorn, itemSubType: WormHornSubtype.Premium }],
			},

			{ rewardType: RewardType.COIN, amount: 1000  },

			// { rewardType: RewardType.ACCOUNT_BUFF,
			// buff: {
			// 	identifier: AccountBuffIdentifier. SPECIAL BUFF GOES HERE,
			// 	uses: 2,
			// },
			// },
		],
	},
	{
		day: 4,
		isMilestone: true,
		reward: [
			{
				rewardType: RewardType.SKIN,
				isSkin: true,
				skinSlot: SKIN_SLOT.PET_MAIN,
				mtxIdentifier: DogType.Corgi,
			},
			{
				rewardType: RewardType.ITEM_BUNDLE,
				rewards: [{ itemType: ItemType.WormHorn, itemSubType: WormHornSubtype.Premium }],
			},
		],
	},
	{
		day: 6,
		isMilestone: true,
		reward: [
			{
				rewardType: RewardType.ITEM_BUNDLE,
				rewards: [	{ itemType: ItemType.PersistentBuff, itemSubType: PersistentBuffSubType.Enchanting , stackSize:3},
					],
			},
			{
				rewardType: RewardType.COIN,
				amount: 1500,
			},

			// { rewardType: RewardType.ACCOUNT_BUFF,
			// buff: {
			// 	identifier: AccountBuffIdentifier. Free Enchant goes here,
			// 	uses: 3,
			// },
			// },

			// { rewardType: RewardType.SKIN,
			// isSkin: true,
			// skinSlot: SKIN_SLOT.PLAYER_MAIN
			// mtxIdentifier: Add a skin variant here.
			// },
		],
	},
	{
		day: 9,
		isMilestone: true,
		reward: [
			
			{
				rewardType: RewardType.ITEM_BUNDLE,
				rewards: [
					{ itemType: ItemType.WormHorn, itemSubType: WormHornSubtype.Standard, stackSize: 5 },
				],
			},
			{
				rewardType: RewardType.ITEM_BUNDLE,
				rewards: [	{ itemType: ItemType.PersistentBuff, itemSubType: PersistentBuffSubType.Enchanting , stackSize:3},
					],
			},
			{
				rewardType: RewardType.COIN,
				amount: 3000,
			},
		],
	},
	{
		day: 14,
		isMilestone: true,
		reward: [
			{
				rewardType: RewardType.ITEM_BUNDLE,
				rewards: [	{ itemType: ItemType.PersistentBuff, itemSubType: PersistentBuffSubType.LegendaryEnchanting },
					],
			},

			{ rewardType: RewardType.ITEM_BUNDLE, rewards: [{ itemType: ItemType.WormHorn, itemSubType: WormHornSubtype.Premium }] },
			// { rewardType: RewardType.ACCOUNT_BUFF,
			// buff: {
			// 	identifier: AccountBuffIdentifier. Free Enchant goes here,
			// 	uses: 5,
			// },
			//},
		],
	},
	{
		day: 19,
		isMilestone: true,
		reward: [
			{
				rewardType: RewardType.SKIN,
				isSkin: true,
				skinSlot: SKIN_SLOT.PET_MAIN,
				mtxIdentifier: HamsterType.Mint,
			},
			// { rewardType: RewardType.ACCOUNT_BUFF,
			// buff: {
			// 	identifier: AccountBuffIdentifier. SPECIAL BUFF GOES HERE,
			// 	uses: 2,
			// },
			// },
		],
	},
	{
		day: 24,
		isMilestone: true,
		reward: [
			{
				rewardType: RewardType.ITEM_BUNDLE,
				rewards: [	{ itemType: ItemType.PersistentBuff, itemSubType: PersistentBuffSubType.RegenerativeHealing , stackSize:3 },
					],
			},
			{
				rewardType: RewardType.ITEM_BUNDLE,
				rewards: [	{ itemType: ItemType.PersistentBuff, itemSubType: PersistentBuffSubType.Enchanting, stackSize:3 },

					],
			},

			{
				rewardType: RewardType.ITEM_BUNDLE,
				rewards: [
					{ itemType: ItemType.WormHorn, itemSubType: WormHornSubtype.Standard, stackSize: 10 },
				],
			},
		],
	},
	{
		day: 29,
		isMilestone: true,
		reward: [
						{
				rewardType: RewardType.ITEM_BUNDLE,
				rewards: [	{ itemType: ItemType.PersistentBuff, itemSubType: PersistentBuffSubType.Enchanting, stackSize:5},

					],
			},

			{
				rewardType: RewardType.ITEM_BUNDLE,
				rewards: [
					{ itemType: ItemType.WormHorn, itemSubType: WormHornSubtype.Premium, stackSize: 3 },
				],
			},
			{
				rewardType: RewardType.COIN,
				amount: 6000,
			},
		],
	},
	{
		day: 39,
		isMilestone: true,
		reward: [
			{
				rewardType: RewardType.ITEM_BUNDLE,
				rewards: [
					{ itemType: ItemType.PersistentBuff, itemSubType: PersistentBuffSubType.LifeBloom, stackSize:5 },
				],
			},
			{
				rewardType: RewardType.ITEM_BUNDLE,
				rewards: [
					{ itemType: ItemType.WormHorn, itemSubType: WormHornSubtype.Standard, stackSize: 10 },
				],
			},
		],
	},
	{
		day: 49,
		isMilestone: true,
		reward: [{
			rewardType: RewardType.COIN,
			amount: 15000,

			// rewardType: RewardType.ACCOUNT_BUFF,
			// buff: {
			// 	identifier: AccountBuffIdentifier. SPECIAL BUFF GOES HERE,
			// 	uses: 3,
			// },
		},
		
		{
			rewardType: RewardType.ITEM_BUNDLE,
			rewards: [	{ itemType: ItemType.PersistentBuff, itemSubType: PersistentBuffSubType.LegendaryEnchanting, stackSize:3 },
				],
		},
	]
	},
	{
		day: 74,
		isMilestone: true,
		reward: [
			{
				rewardType: RewardType.ITEM_BUNDLE,
				rewards: [
					{ itemType: ItemType.WormHorn, itemSubType: WormHornSubtype.Premium, stackSize: 3 },
				],
			},
						{
				rewardType: RewardType.ITEM_BUNDLE,
				rewards: [	{ itemType: ItemType.PersistentBuff, itemSubType: PersistentBuffSubType.LifeBloom, stackSize:5 },
						],
			},

			{
				rewardType: RewardType.ITEM_BUNDLE,
				rewards: [	{ itemType: ItemType.PersistentBuff, itemSubType: PersistentBuffSubType.Enchanting, stackSize:5 },

					],
			},

			
		],
	},
	{
		day: 99,
		isMilestone: true,
		reward: [
			
			{
				rewardType: RewardType.ITEM_BUNDLE,
				rewards: [	{ itemType: ItemType.PersistentBuff, itemSubType: PersistentBuffSubType.LegendaryEnchanting, stackSize:3 },
					],
			},
			{
			rewardType: RewardType.COIN,
			amount: 15000,
			// rewardType: RewardType.ACCOUNT_BUFF,
			// buff: {
			// 	identifier: AccountBuffIdentifier. SPECIAL BUFF GOES HERE,
			// 	uses: 5,
			// },
		},
		
	]
	},
	{
		day: 124,
		isMilestone: true,
		reward: [
			{
				rewardType: RewardType.ITEM_BUNDLE,
				rewards: [	{ itemType: ItemType.PersistentBuff, itemSubType: PersistentBuffSubType.LegendaryEnchanting , stackSize: 3 },
					],
			},
			{
				rewardType: RewardType.ITEM_BUNDLE,
				rewards: [
					{ itemType: ItemType.WormHorn, itemSubType: WormHornSubtype.Standard, stackSize: 10 },
				],
			},
		],
	},
	{
		day: 149,
		isMilestone: true,
		reward: [{
			rewardType: RewardType.ITEM_BUNDLE,
			rewards: [
				{ itemType: ItemType.LuckyShard, itemSubType: null  },
				// Swap this to Rarity shards.
				
			],
		},
			{
				rewardType: RewardType.ITEM_BUNDLE,
				rewards: [	{ itemType: ItemType.PersistentBuff, itemSubType: PersistentBuffSubType.LegendaryEnchanting, stackSize: 3 },
					],
			},
			// rewardType: RewardType.ACCOUNT_BUFF,
			// buff: {
			// 	identifier: AccountBuffIdentifier. SPECIAL BUFF GOES HERE,
			// 	uses: 3,
			// },
		
	]
	},
	{
		day: 174,
		isMilestone: true,
		reward: [
			{
				rewardType: RewardType.ITEM_BUNDLE,
				rewards: [
					{ itemType: ItemType.WormHorn, itemSubType: WormHornSubtype.Premium, stackSize: 3 },
				],
			},
			{
				rewardType: RewardType.ITEM_BUNDLE,
				rewards: [	{ itemType: ItemType.PersistentBuff, itemSubType: PersistentBuffSubType.LegendaryEnchanting, stackSize: 3 },
					],
			},
		],
	},

	{
		day: 199,
		isMilestone: true,
		reward: [
			{
				rewardType: RewardType.ITEM_BUNDLE,
				rewards: [
					{ itemType: ItemType.WormHorn, itemSubType: WormHornSubtype.Standard, stackSize: 10 },
				],
			},
			{
				rewardType: RewardType.ITEM_BUNDLE,
				rewards: [	{ itemType: ItemType.PersistentBuff, itemSubType: PersistentBuffSubType.LegendaryEnchanting, stackSize: 3 },
					],
			},

			// rewardType: RewardType.ACCOUNT_BUFF,
			// buff: {
			// 	identifier: AccountBuffIdentifier. SPECIAL BUFF GOES HERE,
			// 	uses: 5,
			// },
		],
	},

	{
		day: 249,
		isMilestone: true,
		reward: [
			{
				rewardType: RewardType.ITEM_BUNDLE,
				rewards: [
					{ itemType: ItemType.WormHorn, itemSubType: WormHornSubtype.Premium, stackSize: 3 },
				],
			},

			{
				rewardType: RewardType.ITEM_BUNDLE,
				rewards: [	{ itemType: ItemType.PersistentBuff, itemSubType: PersistentBuffSubType.LegendaryEnchanting, stackSize: 3 },
					],
			},
		],
	},
	{
		day: 299,
		isMilestone: true,
		reward: [
			{
				rewardType: RewardType.ITEM_BUNDLE,
				rewards: [
					{ itemType: ItemType.WormHorn, itemSubType: WormHornSubtype.Premium, stackSize: 10 },
				],
			},
		],

		// rewardType: RewardType.ACCOUNT_BUFF,
		// buff: {
		// 	identifier: AccountBuffIdentifier. SPECIAL BUFF GOES HERE,
		// 	uses: 5,
		// },
	},
]

export interface RewardPool {
	weight: number
	reward: AnyRewardData | AnyRewardData[]
}

export interface GeneratedLoginReward {
	minDay: number
	maxDay: number
	totalWeight?: number //this is set programatically
	rewardsPool: RewardPool[]
}

const GENERATED_DAILY_LOGIN_REWARDS: GeneratedLoginReward[] = [
	{
		minDay: 1,
		maxDay: 9,
		rewardsPool: [
			{
				weight: 100, //example of how to do multiple rewards in a generated reward
				reward: [
					{
						rewardType: RewardType.COIN,
						amount: 500,
					},
					// {
					// 	rewardType: RewardType.COIN,
					// 	amount: 200,
					// },
					// {
					// 	rewardType: RewardType.COIN,
					// 	amount: 200,
					// },
				],
			},
			{
				weight: 100,
				reward: {
					rewardType: RewardType.ACCOUNT_BUFF,
					buff: {
						identifier: AccountBuffIdentifier.MoveSpeed,
						uses: 3,
						createdAt: '',
						expiresAt: '',
					},
					name: 'Zoomies!',
					hoverTooltip: '+15% move speed for 5 mins on your next run.  Automatically applied to your account',
					rarity: 'common',
					itemSubType: GeneralStoreItemsSubType.Health,
				},
			},
			{
				weight: 50,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.FOREST }],
				},
			},
			{
				weight: 40,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.ONYX_HIGHLANDS }],
				},
			},
			{
				weight: 30,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.FUNGI_STEEPS }],
				},
			},
			{
				weight: 40,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [{ itemType: ItemType.LuckyShard, itemSubType: null }],
				},
			},

			{
				weight: 80,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [{ itemType: ItemType.WormHorn, itemSubType: WormHornSubtype.Standard }],
				},
			},
			{
				weight: 80,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [	{ itemType: ItemType.PersistentBuff, itemSubType: PersistentBuffSubType.LifeBloom },],
				}
			},
			{
				weight: 60,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [	{ itemType: ItemType.PersistentBuff, itemSubType: PersistentBuffSubType.RegenerativeHealing },],
				}
			},
			{
				weight: 60,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [	{ itemType: ItemType.PersistentBuff, itemSubType: PersistentBuffSubType.Enchanting },],
				}
			},
			// {
			// 	rewardType: RewardType.ACCOUNT_BUFF,
			// 	buff: {
			// 		identifier: AccountBuffIdentifier.Free Enchantment ,
			// 		uses: 2,
			// 	},
			// 	name: 'Enchant',
			// 	hoverTooltip: 'Start your next run with an enchantment available instantly.',
			// 	rarity: 'uncommon',
			// },

			// {
			// 	rewardType: RewardType.ACCOUNT_BUFF,
			// 	buff: {
			// 		identifier: AccountBuffIdentifier. Super Buff ,
			// 		uses: 1,
			// 	},
			// 	name: 'Prism Blessing',
			// 	hoverTooltip: 'Start your next run with an increase to your power for the first 15 minutes of the run.',
			// 	rarity: 'rare',
			// },
		],
	},
	{
		minDay: 10,
		maxDay: 20,
		rewardsPool: [
			{
				weight: 100,
				reward: {
					rewardType: RewardType.COIN,
					amount: 1200,
				},
			},
			{
				weight: 100,
				reward: {
					rewardType: RewardType.ACCOUNT_BUFF,
					buff: {
						identifier: AccountBuffIdentifier.MoveSpeed,
						uses: 3,
						createdAt: '',
						expiresAt: '',
					},
					name: 'Zoomies!',
					hoverTooltip: '+15% move speed for 5 mins on your next run.  Automatically applied to your account',
					rarity: 'common',
					itemSubType: GeneralStoreItemsSubType.Health,
				},
			},
			{
				weight: 50,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.FUNGI_STEEPS },
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.ONYX_HIGHLANDS },
					],
				},
			},
			{
				weight: 50,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.FOREST, stackSize: 2 },
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.ONYX_HIGHLANDS },
					],
				},
			},
			{
				weight: 50,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.FOREST, stackSize: 2 },
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.FUNGI_STEEPS },
					],
				},
			},
			{
				weight: 50,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.FOREST },
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.SUNSET_PRISM },
					],
				},
			},

			{
				weight: 100,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [
						{ itemType: ItemType.LuckyShard, itemSubType: null, stackSize: 4 },
					],
				},
			},

			{
				weight: 40,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [
						{ itemType: ItemType.WormHorn, itemSubType: WormHornSubtype.Standard, stackSize: 2 },
					],
				},
			},

			{
				weight: 40,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [{ itemType: ItemType.WormHorn, itemSubType: WormHornSubtype.Premium }],
				},
			},

			
			{
				weight: 80,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [	{ itemType: ItemType.PersistentBuff, itemSubType: PersistentBuffSubType.LifeBloom, stackSize:2 },],
				}
			},
			{
				weight: 60,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [	{ itemType: ItemType.PersistentBuff, itemSubType: PersistentBuffSubType.RegenerativeHealing, stackSize:2 },],
				}
			},
			{
				weight: 60,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [	{ itemType: ItemType.PersistentBuff, itemSubType: PersistentBuffSubType.Enchanting, stackSize:2 },],
				}
			},

			// {
			// 	rewardType: RewardType.ACCOUNT_BUFF,
			// 	buff: {
			// 		identifier: AccountBuffIdentifier.Free Enchantment ,
			// 		uses: 3,
			// 	},
			// 	name: 'Enchant',
			// 	hoverTooltip: 'Start your next run with an enchantment available instantly.',
			// 	rarity: 'uncommon',
			// },

			// {
			// 	rewardType: RewardType.ACCOUNT_BUFF,
			// 	buff: {
			// 		identifier: AccountBuffIdentifier. Super Buff ,
			// 		uses: 1,
			// 	},
			// 	name: 'Prism Blessing',
			// 	hoverTooltip: 'Start your next run with an increase to your power for the first 15 minutes of the run.',
			// 	rarity: 'rare',
			// },
		],
	},
	{
		minDay: 21,
		maxDay: 40,
		rewardsPool: [
			{
				weight: 100,
				reward: {
					rewardType: RewardType.COIN,
					amount: 5000,
				},
			},
			{
				weight: 100,
				reward: {
					rewardType: RewardType.ACCOUNT_BUFF,
					buff: {
						identifier: AccountBuffIdentifier.MoveSpeed,
						uses: 5,
						createdAt: '',
						expiresAt: '',
					},
					name: 'Zoomies!',
					hoverTooltip: '+15% move speed for 5 mins on your next run.  Automatically applied to your account',
					rarity: 'common',
					itemSubType: GeneralStoreItemsSubType.Health,
				},
			},
			{
				weight: 50,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.FUNGI_STEEPS },
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.ONYX_HIGHLANDS },
					],
				},
			},
			{
				weight: 50,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.FUNGI_STEEPS },
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.FOREST },
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.ONYX_HIGHLANDS },
					],
				},
			},
			{
				weight: 50,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.FOREST, stackSize: 2 },
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.SUNSET_PRISM },
					],
				},
			},
			{
				weight: 50,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.ONYX_HIGHLANDS },
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.SUNSET_PRISM },
					],
				},
			},

			{
				weight: 50,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.FUNGI_STEEPS },
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.SUNSET_PRISM },
					],
				},
			},

			{
				weight: 100,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [
						{ itemType: ItemType.LuckyShard, itemSubType: null, stackSize: 3 },
					],
				},
			},

			{
				weight: 40,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [
						{ itemType: ItemType.WormHorn, itemSubType: WormHornSubtype.Standard, stackSize: 2 },
					],
				},
			},

			{
				weight: 40,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [{ itemType: ItemType.WormHorn, itemSubType: WormHornSubtype.Premium }],
				},
			},
			
			
			{
				weight: 80,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [	{ itemType: ItemType.PersistentBuff, itemSubType: PersistentBuffSubType.LifeBloom, stackSize:4 },],
				}
			},
			{
				weight: 60,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [	{ itemType: ItemType.PersistentBuff, itemSubType: PersistentBuffSubType.RegenerativeHealing, stackSize:3 },],
				}
			},
			{
				weight: 60,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [	{ itemType: ItemType.PersistentBuff, itemSubType: PersistentBuffSubType.Enchanting, stackSize:3 },],
				}
			},

			// {
			// 	rewardType: RewardType.ACCOUNT_BUFF,
			// 	buff: {
			// 		identifier: AccountBuffIdentifier.Free Enchantment ,
			// 		uses: 3,
			// 	},
			// 	name: 'Enchant',
			// 	hoverTooltip: 'Start your next run with an enchantment available instantly.',
			// 	rarity: 'uncommon',
			// },

			// {
			// 	rewardType: RewardType.ACCOUNT_BUFF,
			// 	buff: {
			// 		identifier: AccountBuffIdentifier. Super Buff ,
			// 		uses: 1,
			// 	},
			// 	name: 'Prism Blessing',
			// 	hoverTooltip: 'Start your next run with an increase to your power for the first 15 minutes of the run.',
			// 	rarity: 'rare',
			// },
		],
	},
	{
		minDay: 41,
		maxDay: 100,
		rewardsPool: [
			{
				weight: 100,
				reward: {
					rewardType: RewardType.COIN,
					amount: 10000,
				},
			},
			{
				weight: 100,
				reward: {
					rewardType: RewardType.ACCOUNT_BUFF,
					buff: {
						identifier: AccountBuffIdentifier.MoveSpeed,
						uses: 5,
						createdAt: '',
						expiresAt: '',
					},
					name: 'Zoomies!',
					hoverTooltip: '+15% move speed for 5 mins on your next run.  Automatically applied to your account',
					rarity: 'common',
					itemSubType: GeneralStoreItemsSubType.Health,
				},
			},
			{
				weight: 50,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.FUNGI_STEEPS },
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.ONYX_HIGHLANDS, stackSize: 2 },
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.FOREST },
					],
				},
			},
			{
				weight: 50,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.FUNGI_STEEPS, stackSize: 2 },
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.FOREST },
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.ONYX_HIGHLANDS },
					],
				},
			},
			{
				weight: 50,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.FOREST, stackSize: 2 },
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.ONYX_HIGHLANDS },
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.SUNSET_PRISM },
					],
				},
			},
			{
				weight: 50,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.ONYX_HIGHLANDS, stackSize: 2 },
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.SUNSET_PRISM },
					],
				},
			},

			{
				weight: 50,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.FUNGI_STEEPS },
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.FOREST },
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.SUNSET_PRISM },
					],
				},
			},

			{
				weight: 100,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [
						{ itemType: ItemType.LuckyShard, itemSubType: null, stackSize: 5 },
					],
				},
			},

			{
				weight: 40,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [
						{ itemType: ItemType.WormHorn, itemSubType: WormHornSubtype.Standard, stackSize: 3 },
					],
				},
			},

			{
				weight: 40,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [{ itemType: ItemType.WormHorn, itemSubType: WormHornSubtype.Premium }],
				},
			},
			
			
			{
				weight: 80,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [	{ itemType: ItemType.PersistentBuff, itemSubType: PersistentBuffSubType.LifeBloom, stackSize:5 },],
				}
			},
			{
				weight: 60,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [	{ itemType: ItemType.PersistentBuff, itemSubType: PersistentBuffSubType.RegenerativeHealing, stackSize:4 },],
				}
			},
			{
				weight: 60,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [	{ itemType: ItemType.PersistentBuff, itemSubType: PersistentBuffSubType.Enchanting, stackSize:3 },],
				}
			},
			{
				weight: 20,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [	{ itemType: ItemType.PersistentBuff, itemSubType: PersistentBuffSubType.LegendaryEnchanting },],
				}
			},
			// {
			// 	rewardType: RewardType.ACCOUNT_BUFF,
			// 	buff: {
			// 		identifier: AccountBuffIdentifier.Free Enchantment ,
			// 		uses: 3,
			// 	},
			// 	name: 'Enchant',
			// 	hoverTooltip: 'Start your next run with an enchantment available instantly.',
			// 	rarity: 'uncommon',
			// },

			// {
			// 	rewardType: RewardType.ACCOUNT_BUFF,
			// 	buff: {
			// 		identifier: AccountBuffIdentifier. Super Buff ,
			// 		uses: 1,
			// 	},
			// 	name: 'Prism Blessing',
			// 	hoverTooltip: 'Start your next run with an increase to your power for the first 15 minutes of the run.',
			// 	rarity: 'rare',
			// },
		],
	},
	{
		minDay: 101,
		maxDay: 999999,
		rewardsPool: [
			{
				weight: 100,
				reward: {
					rewardType: RewardType.COIN,
					amount: 15000,
				},
			},
			{
				weight: 50,
				reward: {
					rewardType: RewardType.ACCOUNT_BUFF,
					buff: {
						identifier: AccountBuffIdentifier.MoveSpeed,
						uses: 5,
						createdAt: '',
						expiresAt: '',
					},
					name: 'Zoomies!',
					hoverTooltip: '+15% move speed for 5 mins on your next run.  Automatically applied to your account',
					rarity: 'common',
					itemSubType: GeneralStoreItemsSubType.Health,
				},
			},
			{
				weight: 50,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.FUNGI_STEEPS },
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.ONYX_HIGHLANDS, stackSize: 2 },
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.FOREST },
					],
				},
			},
			{
				weight: 50,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.FUNGI_STEEPS, stackSize: 2 },
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.FOREST },
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.ONYX_HIGHLANDS },
					],
				},
			},
			{
				weight: 50,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.FOREST, stackSize: 2 },
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.ONYX_HIGHLANDS },
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.SUNSET_PRISM },
					],
				},
			},
			{
				weight: 50,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.ONYX_HIGHLANDS, stackSize: 2 },
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.SUNSET_PRISM },
					],
				},
			},

			{
				weight: 50,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.FUNGI_STEEPS },
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.FOREST },
						{ itemType: ItemType.BiomeKey, itemSubType: BiomeKeyType.SUNSET_PRISM },
					],
				},
			},

			{
				weight: 100,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [
						{ itemType: ItemType.LuckyShard, itemSubType: null, stackSize: 10 },
					],
				},
			},

			{
				weight: 40,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [
						{ itemType: ItemType.WormHorn, itemSubType: WormHornSubtype.Standard, stackSize: 3 },
					],
				},
			},

			{
				weight: 40,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [{ itemType: ItemType.WormHorn, itemSubType: WormHornSubtype.Premium }],
				},
			},
			
			{
				weight: 80,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [	{ itemType: ItemType.PersistentBuff, itemSubType: PersistentBuffSubType.LifeBloom, stackSize:5 },],
				}
			},
			{
				weight: 60,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [	{ itemType: ItemType.PersistentBuff, itemSubType: PersistentBuffSubType.RegenerativeHealing, stackSize:4 },],
				}
			},
			{
				weight: 60,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [	{ itemType: ItemType.PersistentBuff, itemSubType: PersistentBuffSubType.Enchanting, stackSize:3 },],
				}
			},
			{
				weight: 20,
				reward: {
					rewardType: RewardType.ITEM_BUNDLE,
					rewards: [	{ itemType: ItemType.PersistentBuff, itemSubType: PersistentBuffSubType.LegendaryEnchanting },],
				}
			},

			// {
			// 	rewardType: RewardType.ACCOUNT_BUFF,
			// 	buff: {
			// 		identifier: AccountBuffIdentifier.Free Enchantment ,
			// 		uses: 3,
			// 	},
			// 	name: 'Enchant',
			// 	hoverTooltip: 'Start your next run with an enchantment available instantly.',
			// 	rarity: 'uncommon',
			// },

			// {
			// 	rewardType: RewardType.ACCOUNT_BUFF,
			// 	buff: {
			// 		identifier: AccountBuffIdentifier. Super Buff ,
			// 		uses: 1,
			// 	},
			// 	name: 'Prism Blessing',
			// 	hoverTooltip: 'Start your next run with an increase to your power for the first 15 minutes of the run.',
			// 	rarity: 'rare',
			// },
		],
	},
]

for (let i = 0; i < GENERATED_DAILY_LOGIN_REWARDS.length; ++i) {
	const generatedReward = GENERATED_DAILY_LOGIN_REWARDS[i]
	let totalWeight = 0

	for (let wi = 0; wi < generatedReward.rewardsPool.length; ++wi) {
		totalWeight += generatedReward.rewardsPool[wi].weight
	}

	generatedReward.totalWeight = totalWeight
}

export function getDailyLoginRewardForDay(day: number, nextLoginReward: number): AnyRewardData | AnyRewardData[] {
	const definedDay = DEFINED_DAILY_LOGIN_REWARDS.find((reward) => reward.day === day)

	if (definedDay) {
		return definedDay.reward
	}

	for (let i = 0; i < GENERATED_DAILY_LOGIN_REWARDS.length; ++i) {
		const generatedReward = GENERATED_DAILY_LOGIN_REWARDS[i]

		if (day >= generatedReward.minDay && day <= generatedReward.maxDay) {
			return generatedReward.rewardsPool[nextLoginReward].reward
		}
	}

	throw Error('could not get daily login reward for day ' + day)
}

export function getDailyLoginConfigForDay(day: number) {
	const definedDay = DEFINED_DAILY_LOGIN_REWARDS.find((reward) => reward.day === day)

	if (definedDay) {
		return definedDay
	}

	for (let i = 0; i < GENERATED_DAILY_LOGIN_REWARDS.length; ++i) {
		const generatedReward = GENERATED_DAILY_LOGIN_REWARDS[i]

		if (day >= generatedReward.minDay && day <= generatedReward.maxDay) {
			return generatedReward
		}
	}

	throw Error('could not get daily login reward for day ' + day)
}

export function getNextDailyLoginRewardIndex(day: number) {
	const definedDay = DEFINED_DAILY_LOGIN_REWARDS.find((reward) => reward.day === day)

	if (definedDay) {
		return 0
	}

	for (let i = 0; i < GENERATED_DAILY_LOGIN_REWARDS.length; ++i) {
		const generatedReward = GENERATED_DAILY_LOGIN_REWARDS[i]

		if (day >= generatedReward.minDay && day <= generatedReward.maxDay) {
			const roll = Math.getRandomInt(0, generatedReward.totalWeight)
			let currentWeight = 0
			for (let wi = 0; wi < generatedReward.rewardsPool.length; ++wi) {
				const reward = generatedReward.rewardsPool[wi]
				currentWeight += reward.weight
				if (roll <= currentWeight) {
					return wi
				}
			}
		}
	}

	throw Error('could not get daily login reward for day ' + day)
}

export function getNextDailyLoginMilestone(day: number) {
	return DEFINED_DAILY_LOGIN_REWARDS.find((reward) => reward.isMilestone && reward.day >= day)
}
