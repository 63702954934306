






























































import { mapGetters, mapMutations, mapActions } from 'vuex'
import { readyWithInvalidPressureTooltip } from '../v-tooltip/v-tooltip-functions'
import TextButton from './text-button.vue'

export default {
	name: 'PartyCommandsButton',
	components: {
		TextButton,
	},
	props: {
		isClientPlayer: {
			type: Boolean,
			required: true,
			default: false,
		},
		isReadyDisabled: {
			type: Boolean,
			required: true,
			default: false,
		},
		promoteUserBtnClick: {
			type: Function,
			required: true,
			default: () => {
				console.warn('The default promote-user-btn-click handler for the <PartyCommandsButton /> component has been called. Did you forget to pass a handler to the component?')
			},
		},
		kickUserBtnClick: {
			type: Function,
			required: true,
			default: () => {
				console.warn('The default kick-user-btn-click handler for the <PartyCommandsButton /> component has been called. Did you forget to pass a handler to the component?')
			},
		},
		readyBtnClick: {
			type: Function,
			required: true,
			default: () => {
				console.warn('The default ready-btn-click handler for the <PartyCommandsButton /> component has been called. Did you forget to pass a handler to the component?')
			},
		},
		unreadyBtnClick: {
			type: Function,
			required: true,
			default: () => {
				console.warn('The default unready-btn-click handler for the <PartyCommandsButton /> component has been called. Did you forget to pass a handler to the component?')
			},
		},
		partyMemberDetails: {
			type: Object,
			required: false,
			default: null,
		},
	},
	data() {
		return {}
	},
	computed: {

		...mapGetters('user', ['username', 'userId']),
		...mapGetters('party', ['getPartyMembers', 'getHasAdventureServer', 'getLeaderStatus']),
		...mapGetters('boatLaunch', ['totalPointsForPreviousWorldTier', 'isValidPressureLoadout']),
		...mapGetters('inGame', [ 'currentGameMode']),

		showLeaderButtons() {
			var partyLeaderObj = this.getPartyMembers.find(({ leader }) => leader === true)
			if (partyLeaderObj && this.userId === partyLeaderObj.playerId) {
				return true
			} else {
				return false
			}
		},
		showLocationText(){
			return this.partyMemberDetails.playerId === this.userId
		}
	},
	methods: {
		readyWithInvalidPressureTooltip,
	},
}
