
























import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import TextButton from '../../reusable-components/buttons/text-button.vue'

export default {
	name: 'AdvancedTutorialTooltip',
	components: {
		TextButton,
	},
	computed: {
		...mapGetters('tutTooltip', ['getTitle', 'getDescription', 'getOkButtonText', 'getPromptVisible', 'getShowButton', 'getDangleStyle']),
		...mapState('tutTooltip', ['target', 'position', 'topOffset', 'leftOffset', 'botOffset', 'rightOffset']),
		...mapGetters('UIScale', ['currentScale']),
	},
	methods: {
		...mapActions('tutTooltip', ['closeTooltipPrompt']),
	},
}
