import logger from '../../utils/client-logger'
import { UI } from '../ui'
import { Audio } from '../../audio/client/audio'
import { PromptMessage } from './prompt-message'

export const showGenericInfoPromptUI = (title: string, description: string[], okButtonText?: string, overridePanelIdentifier?: string, callback?: () => void, dimBackground?: boolean) => {
	const buttonText = okButtonText || 'ok'
	const panelIdentifier = overridePanelIdentifier || 'genericPrompt'
	UI.getInstance().emitEvent('genericPrompt/showMenu', { title, description, callback, okButtonText: buttonText, panelIdentifier: overridePanelIdentifier, dimBackground: dimBackground ?? true })
}

export const showGenericInfoPromptUISequence = (prompts: PromptMessage[], overridePanelIdentifier?: string, callback?: () => void) => {
	UI.getInstance().emitEvent('genericPrompt/showPromptSequence', { prompts, panelIdentifier: overridePanelIdentifier, callback })
}

export const genericInfoPromptUIModule = () => {
	logger.debug('Initializing Generic Info Prompt module...')
	return {
		namespaced: true,
		state: {
			prompts: [],
			promptVisible: false,
			panelIdentifier: 'genericPrompt',
			callback: () => {
				console.log(`default prompt handler`)
			},
		},
		getters: {
			getTitle(state: any) {
				if (state.prompts.length > 0) {
					return state.prompts[0].title
				}
				return '404'
			},
			getDescription(state: any) {
				if (state.prompts.length > 0) {
					return state.prompts[0].description
				}
				return ['404']
			},
			getPromptVisible(state: any) {
				return state.promptVisible
			},
			getOkButtonText(state: any) {
				if (state.prompts.length > 0) {
					const text = state.prompts[0].okButtonText
					if (text && text.length > 0) {
						return text
					}
				}
				return 'OKAY'
			},
			getPanelIdentifier(state: any) {
				return state.panelIdentifier
			},
			getPromptDimBackground(state: any) {
				if (state.prompts.length > 0) {
					return state.prompts[0].dimBackground ?? true
				}
				return true
			},
		},
		mutations: {
			showMenu(state: any, param: any) {
				Audio.getInstance().playSfx('UI_Dialog_Minor_Open')
				const newPrompt = { title: param.title, description: param.description, okButtonText: param.okButtonText ?? 'OKAY', dimBackground: param.dimBackground }
				state.panelIdentifier = param.panelIdentifier ?? 'genericPrompt'
				state.prompts = [newPrompt]

				state.callback = param.callback
				state.promptVisible = true
			},
			showPromptSequence(state: any, param: any) {
				Audio.getInstance().playSfx('UI_Dialog_Minor_Open')
				state.panelIdentifier = param.panelIdentifier ?? 'genericPrompt'
				state.prompts = param.prompts
				state.callback = param.callback

				state.promptVisible = true
			},
		},
		actions: {
			closePromptPanel({ state }) {
				state.prompts.shift()

				state.promptVisible = state.prompts.length > 0
				if (!state.promptVisible) {
					const oldCallback = state.callback
					state.callback = null
					if (oldCallback) {
						oldCallback()
					}
				}
			},
		},
	}
}
