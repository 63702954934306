













































































import { mapActions, mapMutations, mapGetters } from 'vuex'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import PanelDivider from '../reusable-components/panel-divider/panel-divider.vue'
import TextButton from '../reusable-components/buttons/text-button.vue'
import PlayerPortrait from '../reusable-components/player-profile/player-portrait.vue'

export default {
	name: 'PlayerProfile',
	components: {
		MainPanel,
		PanelDivider,
		TextButton,
		PlayerPortrait,
	},
	data() {
		return {
			activeFilterTable: '',
		}
	},
	computed: {
		...mapGetters('cosmetics', ['mainSkin']),
		...mapGetters('user', ['metrics', 'seasonMetrics', 'userType']),
		currentMetrics() {
			let metrics
			if (this.activeFilterTable === 'seasonal') {
				metrics = this.seasonMetrics
			} else {
				metrics = this.metrics
			}

			return [
				{ name: 'Enemies Killed', value: metrics.sumOfEnemiesKilled },
				{ name: 'Bosses Killed', value: metrics.sumOfBossesKilled },
				{ name: 'Projectiles Fired', value: metrics.sumOfProjectilesFired },
				//{ name: 'Damage Dealt', value: metrics.sumOfDamageDealt }, //broken for some reason
				{ name: 'Damage Taken', value: metrics.sumOfDamageTaken },
				{ name: 'Worm Items Deposited', value: metrics.sumOfWormItemsDeposited },
				{ name: 'Biggest Critical Strike', value: metrics.maxCriticalStrikeHit },
			]
		},
	},
	methods: {
		...mapActions('inGame', ['closeActivePanel']),
		updateTable(value) {
			this.activeFilterTable = value
		},
		...mapMutations('inGame', ['setActivePanel']),
		...mapMutations('errors', ['setActiveError']),
		openCustomizePanel() {
			this.setActivePanel('cosmetics')
		},
		openError() {
			this.setActiveError()
		},
	},
}
