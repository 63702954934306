import { ClientEnemy } from '../../ai/client/enemy.client'
import { Effect } from '../../engine/client/graphics/pfx/effect'
import { AffectorConfig, ScaleAffectorMode } from '../../engine/client/graphics/pfx/emitterConfig'
import Renderer from '../../engine/client/graphics/renderer'
import { BuffClientData } from '../../engine/shared/game-data/buffs/buff-definitions.client'
import { easeOutBounceReversed } from '../../engine/shared/game-data/buffs/easing-functions'
import { ClientPlayer } from '../../player/client/player.client'
import { AnimatedNumber, simpleAnimation_addScaleAnimation, simpleAnimation_removeAnimations } from '../../utils/simple-animation-system'
import { attachments_addAttachment } from '../../utils/attachments-system'
import { callbacks_addCallback } from '../../utils/callback-system'
import { BuffIdentifier } from '../shared/buff.shared'
import { chain } from 'lodash'
import { debugConfig } from '../../engine/client/debug-config'
import { sub, add, throwIfNotFinite } from '../../utils/math'
import { timeInMilliseconds, timeInSeconds, uuid } from '../../utils/primitive-types'
import { BuffDefinition } from '../shared/buff-definition'
import BuffData from '../shared/buff-map'
import { ObjectPool, PoolableObject } from '../../third-party/object-pool'
import { InstancedPer, StackStyle } from '../shared/buff-enums'
import Time from '../../engine/shared/time'
import { highResolutionTimestamp } from '../../utils/debug'
import { ClientBuffSystem } from './buff-system.client'
import { v4 as uuidv4 } from 'uuid'

const FOREVER = 9999999999999999999
export class ClientBuff implements PoolableObject {
	static pool: ObjectPool
	/** The identifier of a type of buff */
	get identifier() {
		return this.definition.identifier
	}
	/** The identifier of an instance of a buff. Used to update the user client */
	buffId: uuid
	definition: BuffDefinition

	owner: ClientPlayer
	appliedTo: ClientPlayer

	/** has this been allocated from the pool (and not freed) */
	allocated: boolean

	appliedAtTime: timeInMilliseconds
	expiresAtTime: timeInMilliseconds
	nextTickAtTime: timeInMilliseconds

	appliedAtTimeAbsolute: timeInMilliseconds
	expiresAtTimeAbsolute: timeInMilliseconds

	stacks: number = 0
	rollingStacksApplied: Array<[number, number]>
	get rollingStackApplicationCount() {
		return this.rollingStacksApplied?.length || 1
	}

	state: any

	constructor() { }

	static apply(buffId: BuffIdentifier, owner: ClientPlayer, target: ClientPlayer, stacks?: number, duration?: timeInMilliseconds): ClientBuff {
		const existingBuff = ClientBuff.getBuff(target, buffId, owner)
		if (existingBuff) {
			existingBuff._reapply(stacks, duration)
			// Send update message
			// if ('client' in target && !shouldIgnoreBuffUI(existingBuff.definition.identifier)) {
			// 	global.nengiInstance.message(
			// 		new BuffUpdateMessage({
			// 			updateType: 'update',
			// 			buffIdentifier: existingBuff.definition.identifier,
			// 			id: existingBuff.buffId,
			// 			// owner: owner.nid,
			// 			appliedAtTime: Date.now().toString(),
			// 			expiresAtTime: existingBuff.expiresAtTimeAbsolute.toString(),
			// 			stacks: existingBuff.stacks,
			// 		}),
			// 		target.client,
			// 	)
			// }
			return existingBuff
		}

		const definition = BuffData.map.get(buffId)
		if (!definition) {
			throw new Error(`buff:${buffId} has no entry in BuffData`)
		}

		if (definition.cspCanApplyFn) {
			if (!definition.cspCanApplyFn(target)) {
				return undefined
			}
		}

		const buff = ClientBuff.pool.alloc(definition) as ClientBuff
		buff.allocated = true
		buff.buffId = uuidv4()
		buff.definition = definition
		buff._apply(owner, target, stacks, duration)

		// Send create message
		// if ('client' in target && !shouldIgnoreBuffUI(buff.definition.identifier)) {
		// 	const userId = target.client?.userId
		// 	if (userId) {
		// 		global.nengiInstance.message(
		// 			new BuffUpdateMessage({
		// 				updateType: 'create',
		// 				buffIdentifier: buff.definition.identifier,
		// 				id: buff.buffId,
		// 				// owner: owner.nid,
		// 				appliedAtTime: buff.appliedAtTimeAbsolute.toString(),
		// 				expiresAtTime: buff.expiresAtTimeAbsolute.toString(),
		// 				stacks: buff.stacks,
		// 			}),
		// 			target.client,
		// 		)
		// 	}
		// }
		return buff
	}

	/**
	 * @return buff if buffId exists, undefined otherwise
	 */
	static getBuff(target: ClientPlayer, buffId: BuffIdentifier, owner?: ClientPlayer): ClientBuff | null {
		const ownerRequired = ClientBuff.checkIfOwnerRequired(buffId, owner)
		return target.cspBuffs.find((buff: ClientBuff) => {
			return buff.identifier === buffId && (ownerRequired ? buff.owner === owner : true)
		})
	}

	static checkIfOwnerRequired(buffId: BuffIdentifier, owner?: ClientPlayer): boolean {
		const instancedPer = BuffData.map.get(buffId).instancedPerOwner
		switch (instancedPer) {
			case undefined:
			case InstancedPer.None:
				return false
			case InstancedPer.Player:
				if (owner && /*owner.isPlayer*/true) {
					return true
				}
				break
			case InstancedPer.Enemy:
				if (owner && /*!owner.isPlayer*/false) {
					return true
				}
				break
			case InstancedPer.Everything:
				return !!owner
		}
	}

	/**
	 * @return count of stacks of buff
	 */
	static getBuffStacks(target: ClientPlayer, buffId: BuffIdentifier, owner?: ClientPlayer): number {
		const buff = ClientBuff.getBuff(target, buffId, owner)
		return buff ? buff.stacks : 0
	}

	static remove(target: ClientPlayer, buffId: BuffIdentifier, owner?: ClientPlayer) {
		const buff = this.getBuff(target, buffId, owner)
		if (buff) {
			buff.wearOff()
		}
	}

	static removeAll(target: ClientPlayer) {
		for (let i = target.cspBuffs.length - 1; i >= 0; i--) {
			const buff = target.cspBuffs[i]
			buff.wearOff()
		}
	}

	static removeAllNonPressure(target: ClientPlayer) {
		for (let i = target.cspBuffs.length - 1; i >= 0; i--) {
			const buff = target.cspBuffs[i]
			// note if we add pressure buffs we need to track them here
			if (buff.identifier !== BuffIdentifier.PressureThirstyVampire && buff.identifier !== BuffIdentifier.PressureCreepingWeakness) {
				buff.wearOff()
			}
		}
	}

	static removeAllPlayerApplied(target: ClientPlayer) {
		for (let i = target.cspBuffs.length - 1; i >= 0; i--) {
			const buff = target.cspBuffs[i]
			if (buff.identifier === BuffIdentifier.Poison ||
				buff.identifier === BuffIdentifier.Ignite ||
				buff.identifier === BuffIdentifier.Bleed ||
				buff.identifier === BuffIdentifier.Chill ||
				buff.identifier === BuffIdentifier.Chilled ||
				buff.identifier === BuffIdentifier.Shock ||
				buff.identifier === BuffIdentifier.Stun) {

				buff.wearOff()
			}
		}
	}

	/** Called to check counters and wearOff as necessary. Also checks the tickInterval (if specified) and triggers tickFn (if specified). */
	update(delta: timeInSeconds, now: timeInMilliseconds): void {
		// console.log(`${this.identifier} (${this.expiresAtTime} < ${now}), ${this.stacks} st, ${this.rollingStacksApplied?.length}`)	
		if (!this.appliedTo) {
			// logger.warn('Updating a buff without an appliedTo')
			// if (this.definition) {
			// 	logger.warn(this.definition.identifier)
			// } else {
			// 	logger.warn('No buff definition')
			// }
			return
		}

		if (this.definition.cspTickFn && this.definition.tickInterval && this.appliedTo && this.nextTickAtTime < now) {
			this.definition.cspTickFn(this)
			if (!this.allocated) {
				return // tickFn can remove this buff and give back to pool
			}
			this.nextTickAtTime += this.definition.tickInterval
		}

		if (this.expiresAtTime < now) {
			return this.wearOff()
		}

		if (this.rollingStacksApplied?.length) {
			let updated = false
			for (let i = this.rollingStacksApplied.length - 1; i >= 0; i--) {
				// console.log(`rolling ${i}`, this.rollingStacksApplied[i])
				// wearOffStacks can clear this list, so abort here if so
				if (!this.rollingStacksApplied[i]) {
					return
				}

				const [expiresAtTime, stackCount] = this.rollingStacksApplied[i]
				if (expiresAtTime < now) {
					// wearOffStacks can collect this buff, which will result in a crash, return if this buff was collected
					const woreOff = this.wearOffStacks(stackCount)
					if (woreOff) {
						return
					}
					this.rollingStacksApplied.splice(i, 1)
					updated = true
				}
			}

			if (updated) {
				// if ('client' in this.appliedTo && !shouldIgnoreBuffUI(this.definition.identifier)) {
				// 	global.nengiInstance.message(
				// 		new BuffUpdateMessage({
				// 			updateType: 'update',
				// 			buffIdentifier: this.definition.identifier,
				// 			id: this.buffId,
				// 			appliedAtTime: Date.now().toString(),
				// 			expiresAtTime: this.expiresAtTimeAbsolute.toString(),
				// 			stacks: this.stacks,
				// 		}),
				// 		this.appliedTo.client,
				// 	)
				// }
			}
		}
	}

	/** Wear off the buff, removing all bonuses it provides. Will trigger wearOffFn. */
	wearOff(): void {
		if (this.appliedTo) {
			this.appliedTo.cspBuffs.remove(this)
		}

		ClientBuffSystem.buffs.remove(this)

		if (this.appliedTo) {
			if (this.definition.cspWearOffFn) {
				this.definition.cspWearOffFn(this)
			}

			// this needs to be after `this.appliedTo.buffs.remove`, which smells
			//this.appliedTo.onBuffWearOff(this.identifier)

			// Send remove message
			// if ('client' in this.appliedTo && !shouldIgnoreBuffUI(this.definition.identifier)) {
			// 	// I don't like this but it's probably better than registering 3 types of message
			// 	global.nengiInstance.message(
			// 		new BuffUpdateMessage({
			// 			updateType: 'remove',
			// 			id: this.buffId,
			// 			buffIdentifier: this.definition.identifier,
			// 			appliedAtTime: '',
			// 			expiresAtTime: '',
			// 			stacks: 0,
			// 		}),
			// 		this.appliedTo.client,
			// 	)
			// }
			this.appliedTo = null
		}

		this.owner = null

		ClientBuff.pool.free(this)
	}

	/** Wear off a certain number of stacks and modify the bonuses accordingly. Will trigger updateStacksFn.
	 * @returns true if this buff completely wore off, false if not
	 */
	wearOffStacks(stacks: number): boolean {
		throwIfNotFinite(stacks)
		const oldStacks = this.stacks
		this.stacks -= stacks

		if (this.stacks <= 0) {
			this.wearOff()
			return true
		}

		if (this.definition.cspUpdateStacksFn && oldStacks !== this.stacks) {
			this.definition.cspUpdateStacksFn(this, oldStacks, this.stacks)
		}
		return false
	}

	/** removes stacks without calling any wearoff or update functions */
	removeStacksDirect(stacks: number) {
		throwIfNotFinite(stacks)
		this.stacks -= stacks
		if (this.rollingStacksApplied) {
			for (let i = 0; i < this.rollingStacksApplied?.length; i++) {
				const entry = this.rollingStacksApplied[i]
				const [expiresAtTime, stackCount] = entry

				if (stackCount < stacks) {
					stacks -= stackCount
					entry[1] = 0
				} else {
					entry[1] -= stacks
					break
				}
			}
		}
	}

	setExpirationTimeRelativeToApplied(relativeTime: timeInMilliseconds) {
		this.expiresAtTime = this.appliedAtTime + relativeTime
		this.expiresAtTimeAbsolute = this.appliedAtTimeAbsolute + relativeTime
		if (this.definition.lastsForever) {
			//logger.info(`tried to set expiration for lastsForever buff: ${BuffIdentifier[this.definition.identifier]}`)
			this.expiresAtTime = FOREVER
			this.expiresAtTimeAbsolute = FOREVER
		}
	}

	setExpirationTimeAbsolutely(absoluteTime: timeInMilliseconds) {
		this.expiresAtTimeAbsolute = absoluteTime
		this.expiresAtTime = absoluteTime - this.appliedAtTimeAbsolute
		if (this.definition.lastsForever) {
			//logger.info(`tried to set expiration for lastsForever buff: ${BuffIdentifier[this.definition.identifier]}`)
			this.expiresAtTime = FOREVER
			this.expiresAtTimeAbsolute = FOREVER
		}
	}

	setDefaultValues(defaultValues: any, definition?: any): void {
		if (definition) {
			this.definition = definition
		}
	}

	cleanup(): void {
		this.state = undefined
		this.allocated = false
		this.definition = undefined
		if (this.rollingStacksApplied) {
			this.rollingStacksApplied.length = 0
		}
	}

	/** Apply a buff, optionally overriding the stacks and/or duration. */
	private _apply(owner: ClientPlayer, target: ClientPlayer, stacks?: number, duration?: timeInMilliseconds): void {
		//console.log(`_apply() ${this.buffId}`)
		stacks = stacks || this.definition.startingStacks
		this.owner = owner
		this.appliedTo = target
		this.stacks = stacks
		throwIfNotFinite(this.stacks)
		const now = Time.timestampOfCurrentFrameStartInMs
		this.appliedAtTime = now
		this.appliedAtTimeAbsolute = Date.now()
		this.expiresAtTime = now + (duration || this.definition.duration)
		this.expiresAtTimeAbsolute = this.appliedAtTimeAbsolute + (duration || this.definition.duration)
		if (this.definition.stackStyle === StackStyle.RollingStackDurationSeparately) {
			throwIfNotFinite(stacks)
			this.rollingStacksApplied = [[this.expiresAtTime, stacks]]
		}
		if (this.definition.lastsForever) {
			this.expiresAtTime = FOREVER
			this.expiresAtTimeAbsolute = FOREVER
		}
		if (this.definition.cspTickFn && this.definition.tickInterval) {
			this.nextTickAtTime = now + this.definition.tickInterval
		}
		if (this.appliedTo) {
			this.appliedTo.cspBuffs.push(this)
		} else {
			//logger.error(`Attempted to apply a buff (${this.identifier}) to a null entity!!! This should never happen!!! Likely bug!`)
		}
		ClientBuffSystem.buffs.push(this)
		if (this.definition.cspApplyFn) {
			this.definition.cspApplyFn(this)
		}
		if (this.definition.cspUpdateStacksFn) {
			this.definition.cspUpdateStacksFn(this, 0, this.stacks)
		}
		if (this.appliedTo) {
			//this.appliedTo.onBuffApplied(this.identifier)
		} else {
			//logger.error(`Attempted to apply a buff (${this.identifier}) to a null entity! This should never happen!`)
		}
	}

	/** Handles all re-application behavior. */
	private _reapply(stacks?: number, duration?: number) {
		// console.log(`_REapply() ${this.expiresAtTime}`)
		if (this.definition.stackStyle === StackStyle.None) {
			return
		}

		stacks = stacks || this.definition.reapplyStacks
		duration = duration || this.definition.reapplyDuration

		const now = highResolutionTimestamp()
		const oldStacks = this.stacks

		throwIfNotFinite(this.stacks)
		throwIfNotFinite(stacks)
		if (this.definition.stackStyle === StackStyle.IncreaseDuration) {
			this.stacks += stacks
			this.expiresAtTime += duration
			if (this.definition.capDuration) {
				const frameNow = Time.timestampOfCurrentFrameStartInMs
				this.expiresAtTime = Math.min(frameNow + this.definition.capDuration, this.expiresAtTime)
			}
		} else if (this.definition.stackStyle === StackStyle.RefreshDuration) {
			this.stacks += stacks
			this.expiresAtTime = Math.max(this.expiresAtTime, now + duration)
		} else if (this.definition.stackStyle === StackStyle.RollingStackDurationSeparately) {
			this.stacks += stacks
			this.rollingStacksApplied.push([now + duration, stacks])
			this.expiresAtTime = Math.max(this.expiresAtTime, now + duration)
		}

		this.expiresAtTimeAbsolute = this.appliedAtTimeAbsolute + (this.expiresAtTime - this.appliedAtTime)

		if (this.definition.cspUpdateStacksFn) {
			this.definition.cspUpdateStacksFn(this, oldStacks, this.stacks)
		}
	}
}

export interface AppliedBuffVisuals {
	buffId: BuffIdentifier
	effects: Effect[]
	filters: PIXI.Filter[]
}

export type AppliedBuffVisualsArray = [AppliedBuffVisuals, AppliedBuffVisuals, AppliedBuffVisuals]
export type BuffableClientEntity = ClientEnemy | ClientPlayer

const DEFAULT_SCALE_OUT_TIME = 0.5

export function handleBuffChange(entity: BuffableClientEntity, buffId: BuffIdentifier, idx: number) {
	const visualBeingRemoved = entity.attachedBuffEffects[idx]

	_log(`\n------------------------\nhandleBuffChange add:<${buffId}> replace:<${visualBeingRemoved?.buffId}> idx:${idx}`)

	const renderer = Renderer.getInstance()

	if (entity.attachedBuffEffects[idx]) {
		entity.attachedBuffEffects[idx] = undefined

		// remove existing effect animations and add scale out
		visualBeingRemoved.effects.forEach((effect) => {
			simpleAnimation_removeAnimations(effect)
			simpleAnimation_addScaleAnimation(effect, (t) => easeOutBounceReversed(t, DEFAULT_SCALE_OUT_TIME))

			callbacks_addCallback(
				entity,
				() => {
					renderer.removeEffectFromScene(effect)
				},
				DEFAULT_SCALE_OUT_TIME,
			)
		})

		visualBeingRemoved.filters.forEach((filter) => {
			simpleAnimation_removeAnimations(filter)
			if ((filter as any).scale) {
				simpleAnimation_removeAnimations((filter as any).scale)
			}
			Renderer.getInstance().stage.filters.remove(filter)
			entity.riggedModel.filters.remove(filter)
		})

		const hasFeetPfxOverride = entity.attachedBuffEffects.find((ae) => {
			return ae && BuffClientData.get(ae.buffId).replaceFeetPfx
		})

		if (!hasFeetPfxOverride) {
			if (entity instanceof ClientPlayer) {
				entity.footDustPfx.enabled = true
			}
		}
	}

	if (buffId) {
		const clientBuffDef = BuffClientData.get(buffId)
		console.assert(`no client-side visuals configured for ${buffId}, see BuffClientData`)
		if (clientBuffDef) {
			let pfxConfigs = clientBuffDef?.pfxConfig
			if (!pfxConfigs) {
				pfxConfigs = []
			} else if (!Array.isArray(pfxConfigs)) {
				pfxConfigs = [pfxConfigs]
			}

			const appliedVisuals: AppliedBuffVisuals = { buffId, effects: [], filters: [] }

			entity.attachedBuffEffects[idx] = appliedVisuals

			pfxConfigs.forEach((pfxConfig) => {
				if (pfxConfig) {
					if (entity instanceof ClientPlayer) {
						entity.footDustPfx.enabled = false
					}

					const effect = Renderer.getInstance().addEffectToScene(pfxConfig.pfx, entity.x, entity.y)

					if (pfxConfig.boneWIP) {
						// WIP: this doesn't work, bone is not updated and in wrong position
						const bone = entity.riggedModel.skeleton.findBone(pfxConfig.boneWIP)
						if (bone) {
							attachments_addAttachment(effect, entity, () => bone, pfxConfig.renderBehind)
						}
					} else {
						attachments_addAttachment(effect, entity, (t) => {
							const visualServerPosDiff = sub(entity.visualPos, entity)
							return add(visualServerPosDiff, pfxConfig.offset(t))
						}, pfxConfig.renderBehind)
					}

					if (pfxConfig.scale !== undefined) {
						addEffectScaleAnim(effect, pfxConfig.scale)
					}

					effect.prewarm()

					entity.attachedBuffEffects[idx].effects.push(effect)
				}
			})

			// apply any screen filters for this buff
			let screenFilters = clientBuffDef.screenFilters
			if (debugConfig.render.disableScreenFilters) {
				screenFilters = null
			}
			if (screenFilters && (entity as any).isPlayersEntity) {
				screenFilters.forEach((filter) => {
					const filterInstance = filter(clientBuffDef.duration)
					appliedVisuals.filters.push(filterInstance)
					Renderer.getInstance().stage.filters.push(filterInstance)
				})
			}

			// apply any entity filters for this buff
			const entityFilters = clientBuffDef.entityFilters
			if (entityFilters) {
				entityFilters.forEach((filter) => {
					const filterInstance = filter(clientBuffDef.duration)
					appliedVisuals.filters.push(filterInstance)
					entity.riggedModel.filters.push(filterInstance)
				})
			}
		}
	}
}

export function addEffectScaleAnim(effect: Effect, scale: AnimatedNumber) {
	// how this works:
	//  1) emitters, depending on mode, inherit the scale of their parent effect
	//  2) so here we add an effect scale anim to scale the effect, which in turn scales the emitters
	//  3) we add a scale affector so the pfx inherit the emitter scale

	simpleAnimation_addScaleAnimation(effect, scale)

	const affector: AffectorConfig = {
		id: 'scale',
		cfg: { mode: ScaleAffectorMode.InheritEmitter },
	}

	effect.emitters.forEach((e) => {
		e.addAffector(affector)
	})
}

export function clearAttachedPfx(entity: BuffableClientEntity) {
	const renderer = Renderer.getInstance()

	chain(entity.attachedBuffEffects)
		.compact()
		.map((e) => e.effects)
		.flatten()
		.forEach((e) => renderer.removeEffectFromScene(e))
		.value()

	entity.attachedBuffEffects = [undefined, undefined, undefined]
}

//const _log = logger.debug
const _log = (...args) => { }
