import { Container, Graphics, Text } from 'pixi.js'
import { SPATIAL_GRID_CELL_SIZE } from '../../collision/shared/collision-properties'

class SpatialGridVisual extends Container {
	constructor(worldWidth: number, worldHeight: number) {
		super()

		// Draw vertical lines
		for (let i = 0; i < worldWidth / SPATIAL_GRID_CELL_SIZE + 1; i++) {
			const line = new Graphics()
			line.lineStyle(2, 0x779ecb)
			line.moveTo(i * SPATIAL_GRID_CELL_SIZE, 0)
			line.lineTo(i * SPATIAL_GRID_CELL_SIZE, worldHeight)
			this.addChild(line)
		}

		// Draw horizontal
		for (let i = 0; i < worldHeight / SPATIAL_GRID_CELL_SIZE + 1; i++) {
			const line = new Graphics()
			line.lineStyle(2, 0x779ecb)
			line.moveTo(0, i * SPATIAL_GRID_CELL_SIZE)
			line.lineTo(worldWidth, i * SPATIAL_GRID_CELL_SIZE)
			this.addChild(line)
		}

		for (let x = 0; x < worldWidth / SPATIAL_GRID_CELL_SIZE; x++) {
			for (let y = 0; y < worldHeight / SPATIAL_GRID_CELL_SIZE; y++) {
				const gridCellText = new Text(`SPATIAL GRID CELL:\n x${x} y${y}  (${x * 512}, ${y * 512}) \n`, {
					fontSize: 12,
					fill: '#ffffff',
					align: 'left',
				})
				gridCellText.name = 'cellText'
				gridCellText.position.x = x * SPATIAL_GRID_CELL_SIZE + 10
				gridCellText.position.y = y * SPATIAL_GRID_CELL_SIZE + 5
				this.addChild(gridCellText)
			}
		}
	}
}

export default SpatialGridVisual
