import nengi from 'nengi'
import { uuid } from '../../utils/primitive-types'

class ItemLockedMessage {
	static protocol = {
		itemId: { type: nengi.String },
	}

	itemId: uuid

	constructor(itemId: uuid) {
		this.itemId = itemId
	}
}

export default ItemLockedMessage
