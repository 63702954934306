import { NengiClient } from '../../engine/client/nengi-client'
import DestroyItemFromInventoryCommand from '../../items/shared/destroy-item-from-inventory-command'
import logger from '../../utils/client-logger'
import { showGenericYesNoUI } from './generic-yes-no.ui-state'
import { isHighRarityItem, rarityPromptText, isPrize } from './rarity-helper'

interface InventoryUIState {
	selectedInventoryItem: any
}

export const inventoryUIState = () => {
	logger.debug('Initializing Outpost Worm Mail UI module')
	return {
		namespaced: true,
		state: {
			selectedInventoryItem: null,
		},
		mutations: {},
		getters: {
			selectedInventoryItem(state: InventoryUIState) {
				return state.selectedInventoryItem
			},
		},
		actions: {
			toggleSelectInventoryItem(context, selectedItem?: any) {
				const { state, commit } = context
				if (selectedItem && selectedItem !== state.selectedInventoryItem) {
					commit(
						'itemComparison/selectItem',
						{
							itemId: selectedItem,
							container: 'inventory',
						},
						{ root: true },
					)
					state.selectedInventoryItem = selectedItem
				} else {
					commit('itemComparison/deselectItem', {}, { root: true })
					state.selectedInventoryItem = null
				}
			},
			tossInventoryItem(context, itemId) {
				const { state, commit, dispatch, rootState, rootGetters } = context
				const inventoryContainerItems = rootState.itemContainers.inventory
				const item = inventoryContainerItems.find((x) => x.id === itemId)

				const selectedDetails = rootGetters['itemContainers/itemDetails']('inventory', itemId)

				if (isPrize(selectedDetails) || isHighRarityItem(selectedDetails)) {
					const questionText = rarityPromptText(selectedDetails, 'inventory')

					showGenericYesNoUI('Toss this item?', questionText, '', 'Toss', 'Cancel', () => {
						commit('itemLocks/lockItem', itemId, { root: true })
						NengiClient.getInstance().sendCommand(new DestroyItemFromInventoryCommand(itemId))
						dispatch('ftueManager/completeFlagsFrom', 'lootTossed', { root: true })
					})
				} else {
					commit('itemLocks/lockItem', itemId, { root: true })
					NengiClient.getInstance().sendCommand(new DestroyItemFromInventoryCommand(itemId))
					dispatch('ftueManager/completeFlagsFrom', 'lootTossed', { root: true })
				}
			},
		},
	}
}
