



















import MainPanel from '../reusable-components/panels/main-panel.vue'
import InventoryItem from '../reusable-components/inventory-item/inventory-item.vue'
import AmountSelector from '../reusable-components/buttons/amount-selector.vue'
import IconButton from '../reusable-components/buttons/icon-button.vue'
import { mapMutations, mapState } from 'vuex'

export default {
    name: 'StackableAmountPopUp',
    components: {
        MainPanel,
        InventoryItem,
        AmountSelector,
        IconButton,
    },
    data() {
        return {

        }
    },
    computed: {
        ...mapState('stackableSelection', ['item', 'stackAmount'])
    },
    methods: {
        ...mapMutations('stackableSelection', ['cancelPopup', 'doneSelection', 'updateStacks'])
    }
}
