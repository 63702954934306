import nengi from 'nengi'
import { ModCategory } from '../../engine/shared/game-data/stat-type-mod-category'
import { timeInMilliseconds } from '../../utils/primitive-types'

class SkillUsedMessage {
	static protocol = {
		entityId: { type: nengi.Number },
		x: { type: nengi.Number },
		y: { type: nengi.Number },
		skillSlot: { type: nengi.Int8 },
		skillId: { type: nengi.Number },
		destX: { type: nengi.Number },
		destY: { type: nengi.Number },
		cooldownStartTime: { type: nengi.Number },
		cooldownDuration: { type: nengi.Number },
		skillDuration: { type: nengi.Number },
	}

	entityId: number
	x: number
	y: number
	skillSlot: number
	skillId: ModCategory
	destX: number
	destY: number
	cooldownStartTime: timeInMilliseconds
	cooldownDuration: timeInMilliseconds
	skillDuration: timeInMilliseconds

	constructor(params: SkillUsedMessage) {
		this.entityId = params.entityId
		this.x = params.x
		this.y = params.y
		this.skillSlot = params.skillSlot
		this.skillId = params.skillId
		this.destX = params.destX
		this.destY = params.destY
		this.cooldownStartTime = params.cooldownStartTime
		this.cooldownDuration = params.cooldownDuration
		this.skillDuration = params.skillDuration
	}
}

export default SkillUsedMessage
