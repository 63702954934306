
































































import { mapGetters, mapActions, mapMutations } from 'vuex'
import MainPanel from '../../reusable-components/panels/main-panel.vue'
import PanelDivider from '../../reusable-components/panel-divider/panel-divider.vue'
import ArrowButton from '../../reusable-components/buttons/arrow-button.vue'
import PartyCommandsButton from '../../reusable-components/buttons/party-commands-button.vue'
import { isOnSufficientDifficultyToProgress } from '../../../engine/shared/game-data/progression'

export default {
	name: 'PartyList',
	data() {
		return {
			partyDividerText: '',
			inlineCopyBtnClicked: false,
			MAX_PARTY_SLOTS: 6,
			showLinkCopied: false,
			linkCopiedTimeoutId: null,
		}
	},
	components: {
		MainPanel,
		PanelDivider,
		ArrowButton,
		PartyCommandsButton,
	},
	props: {},
	computed: {
		...mapGetters('user', ['username', 'userId']),
		...mapGetters('party', ['getPartyId', 'getPartyMembers', 'getPartyDeepLinkURL']),
		...mapGetters('boatLaunch', ['isValidPressureLoadout', 'maxAllowedTier', 'currentlySelectedWorldDifficulty', 'isMeterFull']),
		
		sortPartyByRole() {
			return [...this.getPartyMembers.filter(({ leader }) => leader), ...this.getPartyMembers.filter(({ leader }) => !leader)]
		},
		myUser() {
			return this.getPartyMembers.find(({ playerId }) => playerId === this.userId)
		},
		availableSlots() {
			return this.MAX_PARTY_SLOTS - this.getPartyMembers.length
		},
	},
	methods: {
		...mapActions('inGame', ['closeActivePanel']),
		...mapActions('party', ['leaveParty', 'kickSelectedUser', 'promoteSelectedUser', 'setReady', 'setUnready', 'sendInvitation']),
		...mapMutations('chat', ['receivedNewMessage']),
		isOnSufficientDifficultyToProgress,
		
		panelDividerCopyIdButton() {
			this.copyPartyId()
			this.inlineCopyBtnClicked = true

			setTimeout(
				function() {
					this.inlineCopyBtnClicked = false
				}.bind(this),
				2000,
			)
		},
		copyPartyId() {
			let commandExists = document.queryCommandSupported('copy')

			if (commandExists === true) {
				let copyText = document.createElement('input')
				document.body.appendChild(copyText)
				copyText.value = this.getPartyId
				copyText.select()
				document.execCommand('copy', false)
				copyText.remove()
			} else {
				console.warn(`Copy Command doesn't exist`)
			}
		},
		copyPartyLinkURL() {
			let commandExists = document.queryCommandSupported('copy')

			if (commandExists === true) {
				let copyText = document.createElement('input')
				document.body.appendChild(copyText)
				copyText.value = this.getPartyDeepLinkURL
				copyText.select()
				document.execCommand('copy', false)
				copyText.remove()

				this.receivedNewMessage({authorsName: 'Mr. Loot', message: 'Party link copied to cipboard! Send the link to your friends so they can join your party!', party: true})
				this.showLinkCopied = true

				if(this.linkCopiedTimeoutId) {
					clearTimeout(this.linkCopiedTimeoutId)
				}

				this.linkCopiedTimeoutId = setTimeout(
					function() {
						this.showLinkCopied = false
					}.bind(this),
					1500,
				)
			} else {
				console.warn(`Copy Command doesn't exist`)
			}
		},
	},
}
