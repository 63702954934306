import nengi from 'nengi'

class AttemptStorePurchaseCommand {
	static protocol = {
		itemId: nengi.Int32,
		poiId: nengi.Int32,
	}

	itemId: number
	poiId: number

	constructor(itemId: number, poiId: number) {
		this.itemId = itemId
		this.poiId = poiId
	}
}

export default AttemptStorePurchaseCommand
