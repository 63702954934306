


































import { mapActions, mapGetters, mapState } from 'vuex'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import ListItemContainer from '../reusable-components/item-containers/list-item-container.vue'
import PanelDivider from '../reusable-components/panel-divider/panel-divider.vue'
import ArrowButton from '../reusable-components/buttons/arrow-button.vue'

export default {
	name: 'Rewards',
	components: {
		MainPanel,
		ListItemContainer,
		PanelDivider,
		ArrowButton,
	},

	data() {
		return {}
	},
	computed: {
		//TODO2: mapState('rewards') using original backend rewards state
		...mapState('rewards', ['rewards']),
		...mapState('rewards', ['message']),
		...mapGetters('rewards', ['applyRewardButton', 'selectedWeapon', 'selectedReward']),
		...mapGetters('itemContainers', ['itemsInContainer']),
	},
	methods: {
		...mapActions('inGame', ['closeActivePanel']),
		...mapActions('rewards', ['toggleSelectedReward', 'toggleSelectedWeapon', 'applyEnchantment']),
		weaponIsSelected(weapondId) {
			return weapondId === this.selectedWeapon
		},
		rewardIsSelected(rewardId) {
			return rewardId === this.selectedReward
		},
		selectWeapon(itemId) {
			if (this.selectedWeapon) {
				return this.selectedWeapon !== itemId
			}
		},
		selectReward(itemId) {
			if (this.selectedReward) {
				return this.selectedReward !== itemId
			}
		},
		buttonTextFn(itemId) {
			if (this.selectedReward === itemId) {
				return 'Remove'
			}
			return 'Select'
		},
	},
}
