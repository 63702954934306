import nengi from 'nengi'

class UnlockedMtxMessage {
	static protocol = {
		id: nengi.String,
	}

	id: string

	constructor(id: string) {
		this.id = id
	}
}

export default UnlockedMtxMessage
