import nengi from 'nengi'
import { Vector } from 'sat'
import { EmoteType } from '../../chat/shared/emote-enums'
import { AnyCollider } from '../../collision/shared/colliders'
import { DamageType } from '../../combat/shared/damage.shared'
import { FactionShortName } from '../../factions/shared/faction-data'
import { PlayerSkinType } from '../../models-animations/shared/spine-config'
import { nengiId, timeInSeconds, WorldTier } from '../../utils/primitive-types'
import { WeaponSlotKey } from './player-functions'

// Shared class should contain ONLY properties and functions to
// deterministically handle both server-side simulation and client-side
// prediction

// In other words,
// - if your logic results in PURELY server-side mutations **whose changes to the entity will be automatically networked via nengi**, it should live in ServerPlayer
// - if your logic results in PURELY client-side mutations (e.g. perhaps visual things etc that the server needn't care about), it should live in ClientPlayer
// - only if your logic is some pure function whose intention is to be client-side predicted, it would live here in SharedPlayer
class Player {
	static protocol = {
		x: { type: nengi.Float32, interp: true },
		y: { type: nengi.Float32, interp: true },
		x_nointerp: { type: nengi.Float32, interp: false },
		y_nointerp: { type: nengi.Float32, interp: false },
		name: { type: nengi.String },
		movementSpeed: { type: nengi.Float32 },
		lastServerInputFrame: { type: nengi.UInt32 },
		inputMovementPrevented: { type: nengi.Float32 },
		skin: { type: nengi.String },
		factionAffiliation: { type: nengi.String },
		petSkin: { type: nengi.Number },
		currentHealth: { type: nengi.Number },
		maxHealth: { type: nengi.Number },
		currentEnergy: { type: nengi.Number },
		maxEnergyOne: { type: nengi.Number },
		maxEnergyTwo: { type: nengi.Number },
		facingDirection: { type: nengi.Int4, interp: false },
		turnLimit: { type: nengi.Number, interp: true },
		aimAngle: { type: nengi.RotationFloat32, interp: true },
		aimPositionX: { type: nengi.Float32, interp: true },
		aimPositionY: { type: nengi.Float32, interp: true },
		currentLevel: { type: nengi.Number },
		currentExperiencePercentage: { type: nengi.Number },
		activeWeaponSlot: { type: nengi.String },
		gearCooldownSlotOne: { type: nengi.Float32, interp: true },
		gearCooldownSlotTwo: { type: nengi.Float32, interp: true },
		gearCooldownSlotThree: { type: nengi.Float32, interp: true },
		progressionLevel: { type: nengi.Number },
		activeWorldTier: { type: nengi.UInt16 },
		furthestWorldTierEver: { type: nengi.UInt16 },
		inventoryWeightLimit: { type: nengi.Number },
		inventoryWeight: { type: nengi.Number },
		currentWeaponVisuals: { type: nengi.String },
		weaponOut: { type: nengi.Boolean },
		inSafeZone: { type: nengi.Boolean },
		isCharging: { type: nengi.Boolean },
		isMoving: { type: nengi.Boolean },
		isGhost: { type: nengi.Boolean },
		poiIndex: { type: nengi.Int10 },
		buff1: { type: nengi.String },
		buff2: { type: nengi.String },
		buff3: { type: nengi.String },
		buff4: { type: nengi.String },
		buff5: { type: nengi.String },
		buff6: { type: nengi.String },
		partyId: { type: nengi.String },
		mainDamageType: { type: nengi.UInt4 },
		currentEmote: { type: nengi.UInt8 },
		primaryWeaponSkin: { type: nengi.UInt16 },
		secondaryWeaponSkin: { type: nengi.UInt16 },
		cosmetic_player_back: { type: nengi.String },
		cosmetic_player_face: { type: nengi.String },
		cosmetic_player_footprint: { type: nengi.String },
		noClip: { type: nengi.Boolean }
	}

	nid: number
	x: number
	y: number
	/** TEMP: for csp test */
	x_nointerp: number
	/** TEMP: for csp test */
	y_nointerp: number
	lastServerInputFrame: number
	inputMovementPrevented: timeInSeconds = 0
	name: string
	factionAffiliation: FactionShortName
	skin: PlayerSkinType
	turnLimit: number = 0
	petSkin: number = 0
	movementSpeed: number
	activeWorldTier: WorldTier
	furthestWorldTierEver: WorldTier = 1
	facingDirection: number = 1 // 1 (facing right) or -1 (facing left)
	inSafeZone: boolean = false
	isCharging: boolean = false
	isMoving: boolean = false
	isGhost: boolean
	poiIndex: number = -1 // index of current poi in allPOIs, -1 if none
	aimOffset: number = 0
	aimAngle: number = 0
	aimPositionX = 0
	aimPositionY = 0
	aimVector: Vector = new Vector(0, 0)
	activeWeaponSlot: WeaponSlotKey

	buff1: string = ''
	buff2: string = ''
	buff3: string = ''
	buff4: string = ''
	buff5: string = ''
	buff6: string = ''

	mainDamageType = DamageType.PHYSICAL

	primaryWeaponSkin: number = 0
	secondaryWeaponSkin: number = 0

	//jank because nengi does not support arrays in entities
	//scroll down to the array and add there too when adding a new cosmetic type
	cosmetic_player_back: string = ''
	cosmetic_player_face: string = ''
	cosmetic_player_footprint: string = ''

	partyId: string = ''

	colliders: AnyCollider[]

	currentEmote: EmoteType

	get ignoreColliders(): nengiId[] {
		return this._ignoreColliders
	}
	_ignoreColliders = []
	noClip: boolean = false

	get position() {
		this._position.x = this.x
		this._position.y = this.y
		return this._position
	}
	set position(newValue) {
		this._position.x = newValue.x
		this._position.y = newValue.y
		this.x = newValue.x
		this.y = newValue.y
		this.x_nointerp = newValue.x
		this.y_nointerp = newValue.y
	}

	_position = new Vector()
}

export default Player

export const PLAYER_COSMETIC_SLOT_NAMES = ['cosmetic_player_back', 'cosmetic_player_face', 'cosmetic_player_footprint']