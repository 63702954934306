







import { mapGetters, mapMutations, mapState } from 'vuex'
import { autoDetectRenderer, Container, Renderer as pixiRenderer } from 'pixi.js'
import { AssetManager } from '../../../asset-manager/client/asset-manager'
import { RiggedSpineModel } from '../../../models-animations/client/spine-model'
import { debugConfig } from '../../../engine/client/debug-config'
import { timeInSeconds } from '../../../utils/primitive-types'
import playAnimation from '../../../models-animations/client/play-animation'
import { AnimationTrack } from '../../../models-animations/shared/animation-track'
import { PetBaseType, petBaseTypeToName, PetSkinSpineNames, PetSpineNames, PetSubTypePrettyNames, petSubTypeToBaseType } from '../../../loot/shared/prize-sub-type'
import { FANCY_SKIN_PREVIEW } from '../../state/cosmetics-ui-state'
import Time from '../../../engine/shared/time'
import { SKIN_SLOT } from '../../../player/shared/set-skin.shared'
import { SkinInformationByIdentifier, SKIN_SLOT_TO_SPINE_BONE } from '../../../loot/shared/player-skin-sub-type'
import { Effect } from '../../../engine/client/graphics/pfx/effect'
import { IParticleRendererCamera } from '../../../engine/client/graphics/pfx/sprite-particle-renderer'
import { EffectConfig } from '../../../engine/client/graphics/pfx/effectConfig'
import { RenderQueue } from '../../../engine/client/graphics/render-queue'
import { InstancedSpriteBatcher } from '../../../engine/client/graphics/pfx/instanced-sprite-batcher'
import configureAnimationTracks, { PLAYER_MIX_SETTINGS } from '../../../models-animations/client/configure-animation-tracks'

export default {
	name: 'FancySkinPreview',
	components: {},
	props: {},
	data() {
		return {
			id: '',
		}
	},
	computed: {
		...mapGetters('cosmetics', ['getSelectedMainSkin', 'mainSkin', 'getSelectedPetSkin', 'getSelectedBack', 'getSelectedFace', 'getSelectedPfx']),
		...mapState('cosmetics', ['fancySkinPreview']),
		...mapState('itemContainers', ['activeSkinFilter']),
		...mapGetters('inGame', ['activePanel']),

		fancyCanvasBackgroundClass(){
			if(this.activePanel === 'premiumStore'){
				return 'premium'
			} else {
				return 'default'
			}
		}
	},
	created() {
		this.id = this._uid
	},
	mounted() {
		this.renderSkinPreview()
	},

	beforeDestroy() {
		window.clearInterval(this.intervalID)
		this.skinPreview?.destroy()
	},

	methods: {
		...mapMutations('cosmetics', ['setFancySkinPreview']),
		renderSkinPreview() {
			FANCY_SKIN_PREVIEW.instance = new SkinPreview('ui-canvas' + this.id, this.getSelectedMainSkin, this.mainSkin, this.activeSkinFilter === 'Trail')

			FANCY_SKIN_PREVIEW.instance.setPetModel(this.getSelectedPetSkin)
			FANCY_SKIN_PREVIEW.instance.setPlayerCosmetic(this.getSelectedBack, SKIN_SLOT.PLAYER_BACK)
			FANCY_SKIN_PREVIEW.instance.setPlayerCosmetic(this.getSelectedFace, SKIN_SLOT.PLAYER_FACE)
			FANCY_SKIN_PREVIEW.instance.setPlayerCosmetic(this.getSelectedPfx, SKIN_SLOT.PLAYER_FOOTPRINT)

			let lastTick = Time.timestampOfCurrentFrameStartInMs

			this.intervalID = window.setInterval(() => {
				const currentTick = Time.timestampOfCurrentFrameStartInMs
				const delta = ((currentTick - lastTick) / 1000) * debugConfig.timeScale
				lastTick = currentTick
				if (FANCY_SKIN_PREVIEW.instance) {
					FANCY_SKIN_PREVIEW.instance.update(delta, this.getSelectedMainSkin)
				}
			}, 33) //33ms ~= 30FPS
		},
	},
}

class SkinPreview {
	private pixiRenderer: pixiRenderer
	private pfxRenderQueue: RenderQueue
	private stage: Container

	private riggedModel: RiggedSpineModel
	private equippedCosmetics = new Map<SKIN_SLOT, RiggedSpineModel>()
	private riggedPetModel: RiggedSpineModel

	private selectedPlayerSkin: string
	private selectedPlayerBack: string
	private selectedPlayerFace: string
	private selectedPetSkin: string
	private footDustPfx: Effect

	private playerModelMoveTrack: any

	private particleCameraState: IParticleRendererCamera = {
		x: 0,
		y: 0,
		zoom: 1,
		halfWidth: 128,
		halfHeight: 128
  	}

	private static CAM_LERP_SPEED = -400

	private currentX = 0

	private toSetVisible: RiggedSpineModel[] = []

	private isShowingTrailPfx: boolean = false

	constructor(canvasId: string, selectedPlayerSkin: string, mainSkin: string, showFootPfx: boolean) {
		const canvas: HTMLCanvasElement = document.getElementById(canvasId) as HTMLCanvasElement
		this.selectedPlayerSkin = selectedPlayerSkin
		this.pixiRenderer = autoDetectRenderer({
			
			width: 391,
			height: 383,
			view: canvas,
			antialias: false,
			transparent: true,
			resolution: 1,
		})

		const instancedSpriteBatcher = new InstancedSpriteBatcher(this.pixiRenderer, this.pixiRenderer.plugins.batch.MAX_TEXTURES)
		this.pfxRenderQueue = new RenderQueue(this.pixiRenderer, instancedSpriteBatcher)

		this.stage = new Container()
		this.stage.name = 'Player Skins'
		this.stage.position.set(0, 0)


		this.makePlayerModel(selectedPlayerSkin, mainSkin)
		this.setDefaultFootPfx()
		this.setShowPfxTrail(showFootPfx)
	}

	destroy() {
		this.riggedModel?.destroy({ children: true })
		this.stage?.destroy({ children: true })
		this.riggedPetModel?.destroy({ children: true })
		this.pixiRenderer?.destroy()
	}

	update(delta: timeInSeconds, selectedSkin: string): void {
		if (this.selectedPlayerSkin !== selectedSkin) {
			this.selectedPlayerSkin = selectedSkin
			this.stage.removeChild(this.riggedModel)
			this.makePlayerModel(selectedSkin, '')
		}

		if (this.stage && this.pixiRenderer && this.riggedModel) {
			this.pixiRenderer.render(this.stage)
			this.pfxRenderQueue.clear()
			this.footDustPfx.render(this.pfxRenderQueue)
			this.pfxRenderQueue.flush(this.particleCameraState)

			this.riggedModel.update(delta)

			if (this.riggedPetModel) {
				this.riggedPetModel.update(delta)
			}

			this.equippedCosmetics.forEach((model) => {
				model.update(delta)
			})

			this.footDustPfx.update(delta)
			if(this.playerModelMoveTrack) {
				this.playerModelMoveTrack.trackEnd = this.playerModelMoveTrack.trackEnd + delta
			}

			this.stage.x += delta * SkinPreview.CAM_LERP_SPEED

			this.riggedModel.x = -this.stage.x + 275 
			if(this.riggedPetModel) {
				this.riggedPetModel.x = -this.stage.x + 100 
			}
			if(this.footDustPfx) {
				this.footDustPfx.x = -this.stage.x + 275 
			}
			this.particleCameraState.x = this.stage.x
			
			for(let i=0; i < this.toSetVisible.length; ++i) {
				this.toSetVisible[i].visible = true
			}
			this.toSetVisible.length = 0 
		}
	}

	makePlayerModel(selectedPlayerSkin, mainSkin) {
		const asset = AssetManager.getInstance().getAssetByName('player-skins')
		for (const [slot, model] of this.equippedCosmetics) {
			const spineAttachPoint = SKIN_SLOT_TO_SPINE_BONE[slot]
			this.riggedModel.detachSpineSprite(spineAttachPoint)
		}

		this.riggedModel = new RiggedSpineModel(asset.spineData)

		if (selectedPlayerSkin === '') {
			this.riggedModel.skeleton.setSkinByName(mainSkin)
		} else {
			this.riggedModel.skeleton.setSkinByName(selectedPlayerSkin)
		}

		this.riggedModel.skeleton.setToSetupPose()
		// this.riggedModel.position.x = 275
		this.riggedModel.position.y = 320
		this.riggedModel.scale.x = 1.2
		this.riggedModel.scale.y = 1.2
		playAnimation(this.riggedModel, AnimationTrack.IDLE_NO_WEAPON)
		this.stage.addChild(this.riggedModel)

		for (const [slot, model] of this.equippedCosmetics) {
			const spineAttachPoint = SKIN_SLOT_TO_SPINE_BONE[slot]
			this.riggedModel.attachSpineSprite(spineAttachPoint, model)
		}
	}

	setPlayerCosmetic(cosmeticName, cosmeticSlot?: SKIN_SLOT) {
		if (!cosmeticName && cosmeticSlot) {
			//unequip
			if(cosmeticSlot === SKIN_SLOT.PLAYER_FOOTPRINT) {
				this.setDefaultFootPfx()
			} else {
				const equipped = this.equippedCosmetics.get(cosmeticSlot)
				if(equipped) {
					const spineAttachPoint = SKIN_SLOT_TO_SPINE_BONE[cosmeticSlot]
					this.riggedModel.detachSpineSprite(spineAttachPoint)
					this.equippedCosmetics.delete(cosmeticSlot)
				}
			}
			return
		}

		const cosmeticInfo = SkinInformationByIdentifier.get(cosmeticName)
		if (cosmeticInfo) {
			if(cosmeticInfo.skinSlot === SKIN_SLOT.PLAYER_FOOTPRINT) {
				const pfxAssetName = cosmeticInfo.spineJsonName

				const pfxAsset = AssetManager.getInstance().getAssetByName(pfxAssetName).data as EffectConfig
				this.footDustPfx = new Effect(pfxAsset, this.particleCameraState)
				this.footDustPfx.enabled = this.isShowingTrailPfx
				this.footDustPfx.x = this.currentX
				this.footDustPfx.y = 320
			} else {
				AssetManager.getInstance().getAssetByNameAsync(cosmeticInfo.spineJsonName, (asset) => {
					const cosmeticModel = new RiggedSpineModel(asset.spineData)
					cosmeticModel.visible = false
					cosmeticModel.skeleton.setSkinByName(cosmeticInfo.spineSkin)
					cosmeticModel.skeleton.setToSetupPose()
					configureAnimationTracks(this.riggedModel, PLAYER_MIX_SETTINGS)

					playAnimation(cosmeticModel, AnimationTrack.IDLE)

					const alreadyEquipped = this.equippedCosmetics.get(cosmeticInfo.skinSlot)

					const spineAttachPoint = SKIN_SLOT_TO_SPINE_BONE[cosmeticInfo.skinSlot]

					if (alreadyEquipped) {
						this.riggedModel.detachSpineSprite(spineAttachPoint)
					}

					this.riggedModel.attachSpineSprite(spineAttachPoint, cosmeticModel)

					this.equippedCosmetics.set(cosmeticInfo.skinSlot, cosmeticModel)

					cosmeticModel.update(0)
					this.toSetVisible.push(cosmeticModel)
				})
			}
		} else {
			console.error('Could not find cosmetic info for ' + cosmeticName)
		}
	}

	setPetModel(petSkin) {
		if (this.selectedPetSkin !== petSkin) {
			this.selectedPetSkin = petSkin
			this.stage.removeChild(this.riggedPetModel)

			if (!petSkin) {
				return
			}

			this.makePetModel(petSkin)
		}
	}

	makePetModel(petSkin) {
		const petBase = petSubTypeToBaseType(petSkin)
		const skinName = PetSkinSpineNames.get(petSkin)
		const fancyPetName = PetSubTypePrettyNames.get(petSkin)

		if (!skinName) {
			console.error('No skin name for ' + petSkin)
			return
		}

		AssetManager.getInstance().getAssetByNameAsync(PetSpineNames.get(petBase), (asset) => {
			if (this.selectedPetSkin === petSkin) {
				this.riggedPetModel = new RiggedSpineModel(asset.spineData)

				this.riggedPetModel.skeleton.setSkinByName(skinName)
				this.riggedPetModel.skeleton.setToSetupPose()

				this.riggedPetModel.position.x = -170
				this.riggedPetModel.position.y = 350

				this.riggedPetModel.scale.set(1.2, 1.2)

				this.riggedPetModel.update(0)

				playAnimation(this.riggedPetModel, AnimationTrack.IDLE, null, 1)

				this.stage.addChild(this.riggedPetModel)
			}
		})
	}

	setShowPfxTrail(showTrail: boolean) {
		if(showTrail !== this.isShowingTrailPfx) {
			if(showTrail) {
				this.playerModelMoveTrack = playAnimation(this.riggedModel, AnimationTrack.MOVEMENT_NO_WEAPON, undefined, 1)
				this.playerModelMoveTrack.trackEnd = this.playerModelMoveTrack.trackTime + 0.15
				this.stage.x = 0
			} else {
				if(this.playerModelMoveTrack) {
					this.playerModelMoveTrack = null
				}

				playAnimation(this.riggedModel, AnimationTrack.IDLE_NO_WEAPON)
			}

			this.isShowingTrailPfx = showTrail
			this.footDustPfx.enabled = showTrail
		}
	}

	private setDefaultFootPfx() {
		const pfxAsset = AssetManager.getInstance().getAssetByName('dust').data as EffectConfig
		this.footDustPfx = new Effect(pfxAsset, this.particleCameraState)
		this.footDustPfx.x = this.currentX
		this.footDustPfx.y = 320
		this.footDustPfx.enabled = this.isShowingTrailPfx
	}
}
