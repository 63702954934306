






import { mapMutations } from 'vuex'
import { debounce } from 'lodash'

export default {
	name: 'TutorialTooltip',
	props: {
		target: {
			type: String,
			default: 'shields',
		},
		direction: {
			type: String,
			default: 'up',
		},
	},
	data() {
		return {
			topOffset: 0,
			leftOffset: 0,
			resizeDebounced: null,
		}
	},
	created() {
		this.resizeDebounced = debounce(this.rebuildOffsets, 300, { trailing: true })
		window.addEventListener('resize', this.resizeDebounced)
	},
	destroyed() {
		window.removeEventListener('resize', this.resizeDebounced)
	},
	methods: {
		...mapMutations('hud', ['clearTutorialTooltip']),
	},
}
