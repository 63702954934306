import { Vector } from 'sat'
import { EnemyType } from '../../ai/shared/ai-types'
import { AnyColliderConfig } from '../../collision/shared/colliders'
import { timeInSeconds } from '../../utils/primitive-types'
import { WeightedRandom } from './weightedRandom'

export enum POIShape {
	Circle,
	Rectangle,
}

export enum POIType {
	Outpost,
	Boss,
	Conquer,
	MonsterRaid,
	EndOfWorld,
	TownPOI,
	WorldEdge,
	Dock,
}

export enum ParticipationMetric {
	DamageDealt,
	KillsTagged,
	PlayersParticipatingScaledDamage
}

export interface POIData {
	/** The text to display on the ground at the POI (just for testing) */
	text: string

	respawnText?: string

	/** text debug system can use to identify this poi: /tt outpost, etc... */
	debugName: string

	/** The shape of the POI's area */
	shape: AnyColliderConfig

	/** The shape of the POI's area (for determining position of POIs and props) */
	positioningShape: AnyColliderConfig

	/** per-player blocker that gets removed when the player beats the end of world boss */
	endOfRunBlocker?: AnyColliderConfig

	/** optional colliders that will active/deactivate the poi based on players being inside this shape for deactivationSeconds seconds */
	activationColliders?: AnyColliderConfig[]

	/** optional colliders that will rez dead players within their collision */
	rezAreaColliders?: AnyColliderConfig[]

	/** optional, used in conjunction with activationColliders, amount of time players need to be outside activationColliders to deactivate */
	deactivationSeconds?: timeInSeconds

	/** How many instances of this POI should spawn in the biome */
	count: number

	/** Whether to place this POI with an absolute location (true) or randomly within bounds (as percentages of biome area) (false) */
	absoluteLocation: boolean

	/** X location (only if using absolute placement) */
	x?: number

	/** Y location (only if using absolute placement) */
	y?: number

	/** Bounds for possible placement location on the x-axis, as a percentage of biome width (only if not using absolute placement) */
	xPlacement?: number[]

	/** Bounds for possible placement location on the y-axis, as a percentage of biome height (only if not using absolute placement) */
	yPlacement?: number[]

	/** Flags for the POI in its normal, active state */
	activeState: POIState

	/** Flags for the POI while it's resetting its event (only for Boss/Conquer/Monster Raid POIs) */
	resettingState?: POIState

	/** The general function of the POI */
	type: POIType

	/** Any props that should be specifically placed for this POI */
	props: POIPropData[]

	/** How close a player needs to be before the first wave begins (only for Monster Raid POIs) */
	triggerRadius?: number

	/** What percent of spawned enemies need to be defeated before the POI goes inactive (only for Monster Raid POIs) */
	completionPercent?: number

	/** The message to broadcast when the POI is sucessfully completed (only for Monster Raid POIs) */
	completionMessage?: string

	/** Which metric for player participation to use (only for Conquer/Monster Raid POIs) */
	participationMetric?: ParticipationMetric

	/** How much participation (as defined by the metric) is required for a player to recieve loot (only for Conquer/Monster Raid POIs) */
	participationThreshold?: number

	/** What level the reward loot should have (only for Conquer/Monster Raid POIs) */
	lootLevel?: number

	/** How long to wait (in seconds) after completion before restarting the POI event (only for Boss/Conquer/Monster Raid POIs) */
	eventResetTime?: number

	/** What enemies to spawn at the start of the POI event (only for Boss/Conquer POIs) */
	enemySpawns?: EnemySpawnData[]

	/** Details of the POI's waves (only for Monster Raid POIs) */
	waves?: WaveData[]

	/** TODO2 document */
	arenaGates?: ArenaGatesData

	/** Pre-configured X offset from the origin point of the POI where loot should be dropped */
	bossDroppedLootOriginX?: number

	/** Pre-configured Y offset from the origin point of the POI where loot should be dropped */
	bossDroppedLootOriginY?: number

	/** Whether or not this POI should pulse a rez when in between active states */
	shouldResurrectDeadPlayersInBoundsWhenBetweenEvents?: boolean

	/** Whether or not this POI should not award an enchantment to participants */
	noEnchantmentReward?: boolean
}

export interface WeightedPOIData extends POIData, WeightedRandom {
}

export interface ArenaGatesData {
	insideArenaShape: AnyColliderConfig[]
	gates: ArenaGateData[]
}

export interface ArenaGateData {
	/** position offset from arena/poi in gameUnits */
	npcOffset: Vector

	/** interaction radius of npc */
	npcRadius: number

	/** shapes to teleport into inside the arena, finds a random point within these shapes */
	teleportInDestination: AnyColliderConfig[]
	/** shapes to teleport into outside the arena, finds a random point within these shapes */
	teleportOutDestination: AnyColliderConfig[] //(shape, find random point)
}

export interface EnemySpawnData {
	x: number
	y: number
	enemy: string
	enemyType?: EnemyType
}

export interface EnemySpawn {
	x: number
	y: number
	sourceBiome: number
	enemyIdentifier: string
	currentlyAlive: boolean
	entityId: number
	enemyType?: EnemyType
}

export interface POIPropData {
	name: string
	x: number
	y: number
	shared?: boolean
}

export interface POIProp {
	name: string
	biomeFolderName: string
	biomeIndex: number
	x: number
	y: number
}

export interface WaveData {
	delayInSeconds?: number
	completionForNextWave?: number
	spawns: WaveSpawnData[]
	message?: string
}

export interface WaveSpawnData {
	enemy: string
	count: number
	enemyType?: EnemyType
}

export interface POIState {
	/** Whether or not to prevent projectiles from crossing the POI via a collider */
	allowProjectiles: boolean

	/** Whether or not to allow standard enemy spawns within the biome. Deliberate spawns (e.g. from a boss spawning minions) will be allowed regardless */
	allowRandomSpawns: boolean

	/** Whether or not players within the POI are dropped from enemy aggro */
	safeFromEnemies: boolean
}
