import nengi from 'nengi'
import { uuid } from '../../utils/primitive-types'
import { ContainerType } from './send-item-container-message'

class OpenCurrencyBagCommand {
	static protocol = {
		bagId: nengi.String,
		fromContainer: nengi.String,
	}

	bagId: uuid
	fromContainer: ContainerType

	constructor(bagId: uuid, fromContainer: ContainerType) {
		this.bagId = bagId
		this.fromContainer = fromContainer
	}
}

export default OpenCurrencyBagCommand
