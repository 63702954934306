export enum FTUESequence {
	FirstAdventureRunIntro = 1,
	LootDropped = 4,
	LootInInventoryGoToOutpost = 5,
	FullInventory = 6,
	NearOutpost = 7,
	InsideOutpost = 8,
	FirstWormMail = 10,
	OutpostShop = 11,
	EndRunGuyPriorToWormMail = 12,
	BossFogWall = 13,
	ClearedFogWall = 14,
	BossIntro = 15,
	POIIntro = 17,
	HubWorldIntro = 18,
	NearStash = 19,
	AugmentWeapon = 20,
	WormDeliveryNPCProximity = 21,
	WormDeliveryIntro = 22,
	BoatLaunchIntro = 23,
	SmeltingFurnaceIntro = 24,
	MarketplaceIntro = 25,
	MTXShopIntro = 26,
	GeneralStoreIntro = 27,
	IndentificationIntro = 28,
	TrashPitIntro = 29,
	CosmeticIntro = 30,
	LeaderboardIntro = 31,
	SecondAdventureRunIntro = 32,
	EndWorldInaccessibleProximity = 36,
	EndWorldAccessibleProximity = 37,
	PlayerLowHealth = 38,
	PartyPlayIntro = 39,
	DifficultyIntro = 40,
	SoulCyclesUnlocked = 41,
	HubWorldServersOfflineIntro = 42,
}

export const FTUE_ADVENTURE_FIRST_RUN = [
	FTUESequence.FirstAdventureRunIntro,
	FTUESequence.LootDropped,
	FTUESequence.LootInInventoryGoToOutpost,
	FTUESequence.FullInventory,
	FTUESequence.NearOutpost,
	FTUESequence.InsideOutpost,
	FTUESequence.FirstWormMail,
	FTUESequence.OutpostShop,
	FTUESequence.EndRunGuyPriorToWormMail,
	FTUESequence.BossFogWall,
	FTUESequence.ClearedFogWall,
	FTUESequence.BossIntro,
	FTUESequence.POIIntro,
	FTUESequence.PlayerLowHealth,
	FTUESequence.PartyPlayIntro,
]

export const FTUE_ADVENTURE_SECOND_RUN = [
	FTUESequence.SecondAdventureRunIntro,
]

export const FTUE_ADVENTURE_FUTURE_RUNS = [
	FTUESequence.EndWorldInaccessibleProximity,
	FTUESequence.EndWorldAccessibleProximity,
	FTUESequence.DifficultyIntro,
	FTUESequence.SoulCyclesUnlocked,
]

export const FTUE_HUB_FIRST_RUN = [
	FTUESequence.HubWorldIntro,
	FTUESequence.HubWorldServersOfflineIntro,
	FTUESequence.NearStash,
	FTUESequence.AugmentWeapon,
	FTUESequence.WormDeliveryNPCProximity,
	FTUESequence.WormDeliveryIntro,
	FTUESequence.BoatLaunchIntro,
	FTUESequence.SmeltingFurnaceIntro,
	FTUESequence.MarketplaceIntro,
	FTUESequence.MTXShopIntro,
	FTUESequence.GeneralStoreIntro,
	FTUESequence.IndentificationIntro,
	FTUESequence.TrashPitIntro,
	FTUESequence.CosmeticIntro,
	FTUESequence.LeaderboardIntro,
]


