


































































































































































































import { mapActions, mapGetters, mapMutations } from 'vuex'
import ArrowButton from '../reusable-components/buttons/arrow-button.vue'
import MainPanel from '../reusable-components/panels/main-panel.vue'
import TextInput from '../reusable-components/inputs/text-input.vue'
import Checkbox from '../reusable-components/inputs/checkbox.vue'
import ListErrors from '../reusable-components/inputs/list-errors.vue'
import { some, values } from 'lodash'
import { validateString } from '../../utils/profanity-filter'
import VueRecaptcha from 'vue-recaptcha'

export default {
	name: 'CreateAccount',
	components: {
		MainPanel,
		ArrowButton,
		TextInput,
		Checkbox,
		ListErrors,
		VueRecaptcha,
	},
	data() {
		return {
			register: {
				formError: false,
				username: '',
				email: '',
				confirmEmail: '',
				password: '',
				confirmPassword: '',
				agreeToTerms: false,
				agreeToContact: false,
				agreeToAge: false,
			},
			recaptchaV3Passed: false,
			enableRecaptchaV2: true,
			recaptchaV2Passed: false,
			recaptchaV2Token: '',
		}
	},
	computed: {
		...mapGetters('user', ['username', 'getUserNameError', 'getPasswordError', 'getEmailError', 'getAgreeToTermsError', 'getAgeErrors', 'getRecaptchaV3Failed', 'getRecaptchaCheckboxFlag', 'getRecaptchaV2Failed']),
		panelHeightWith() {
			let height = 900
			if (this.getUserNameError.length > 0) {
				height += 90
			}
			if (this.getPasswordError.length > 0) {
				height += 90
			}
			if (this.getEmailError.length > 0) {
				height += 35
			}
			if (this.getAgreeToTermsError.length > 0) {
				height += 35
			}
			if (this.getAgeErrors.length > 0) {
				height += 35
			}
			if (this.getRecaptchaV3Failed) {
				height += 125
			}
			if (this.getRecaptchaV2Failed) {
				height += 35
			}
			return height
		},
	},

	methods: {
		...mapActions('user', ['submitRegister']),
		...mapMutations('user', ['updateUserNameError', 'updateEmailError', 'updatePasswordError', 'updateAgreeToTermsError', 'resetUserErrors', 'updateAgeCheckError', 'updateRecaptchaCheckboxFlag']),
		...mapMutations('mainMenu', ['changeMainMenuScreen']),
		...mapMutations('inGame', ['setActivePanel']),

		openFAQPanel() {
			this.setActivePanel('faq')
		},

		async registerFormSubmit() {
			this.resetUserErrors()
			//Make sure recaptcha is ready
			await this.$recaptchaLoaded()
			//Inform recaptcha v3 of the action being taken and get a token
			let recaptchaToken = await this.$recaptcha('REGISTER_ACCOUNT')
			//Tag it so our serverside handler knows which recaptcha endpoint to use
			let tag = 'v3'
			if (this.getRecaptchaV3Failed) {
				//The checkbox flag should only return true serverside validation for v3 token has returned a low score
				recaptchaToken = this.recaptchaV2Token
				tag = 'v2'
			}

			let normalizedFormValues = {
				username: this.register.username,
				emailAddress: this.register.email.toLowerCase(),
				emailAddressConfirmation: this.register.confirmEmail.toLowerCase(),
				password: this.register.password,
				passwordConfirmation: this.register.confirmPassword,
				agreedToTerms: this.register.agreeToTerms,
				agreedToPromotion: this.register.agreeToContact,
				agreeToAge: this.register.agreeToAge,
				sourceGame: 'loot.io',
			}

			if (normalizedFormValues.username.length > 18 || normalizedFormValues.username.length < 3) {
				this.updateUserNameError('Your username must be between 3 and 18 characters in length.')
			}
			if (normalizedFormValues.emailAddress !== normalizedFormValues.emailAddressConfirmation) {
				this.updateEmailError("Your email fields don't match.")
			}
			if (normalizedFormValues.password !== normalizedFormValues.passwordConfirmation) {
				this.updatePasswordError("Your password fields don't match.")
			}
			if (normalizedFormValues.password.length > 64 || normalizedFormValues.password.length < 10) {
				this.updatePasswordError('Your Password must be between 10 and 64 characters in length.')
			}
			if (normalizedFormValues.agreedToTerms !== true) {
				this.updateAgreeToTermsError('You must agree to the Terms and Conditions in order to sign up.')
			}
			if (normalizedFormValues.agreeToAge !== true) {
				this.updateAgeCheckError(`You need to confirm that you're over the age of 16 or have consent`)
			}
			if (normalizedFormValues.username === undefined || normalizedFormValues.username === '') {
				this.updateUserNameError('You need to fill out the username field')
			}
			if (normalizedFormValues.emailAddress === undefined || normalizedFormValues.emailAddress === '') {
				this.updateEmailError('You need to fill out the email field')
			}
			if (normalizedFormValues.password === undefined || normalizedFormValues.password === '') {
				this.updatePasswordError('You need to fill out the password field')
			}
			if (!validateString(normalizedFormValues.username)) {
				this.updateUserNameError('Your username must not contain any obscene language.')
			}

			let normailizedErrorValues = {
				userNameError: this.getUserNameError,
				passwordError: this.getPasswordError,
				agreeToTermsError: this.getAgreeToTermsError,
				emailError: this.getEmailError,
				ageCheckError: this.getAgeErrors,
			}
			if (
				some(values(normailizedErrorValues), (v) => {
					return v.length !== 0
				})
			) {
				return
			}
			//Pack the recaptcha payload in with the account creation payload for server side validation
			this.submitRegister({ accountFormPayload: normalizedFormValues, recaptchaPayload: { tag: tag, token: recaptchaToken } })
		},
		async getToken(result) {
			this.recaptchaV2Token = result
			this.updateRecaptchaCheckboxFlag(false)
		},
	},
}
