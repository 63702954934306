import logger from '../utils/client-logger'
import Vue from 'vue'
import Vuex, { CommitOptions, DispatchOptions, Store } from 'vuex'
import Toasted from 'vue-toasted'
import VueNonreactive from 'vue-nonreactive'
import VueTooltip from 'v-tooltip'
import { getVisibleWorldWidth, getVisibleWorldHeight, NENGI_VIEW_X_PADDING, NENGI_VIEW_Y_PADDING } from '../engine/client/graphics/renderer'
import { debugConfig } from '../engine/client/debug-config'
import { VueReCaptcha } from 'vue-recaptcha-v3'

// Converted
import { itemLocks } from './state/item-locks.ui-state'
import { outpostWormMail } from './state/outpost-worm-mail.ui-state'
import { itemContainers } from './state/item-containers.ui-state'
import { boatLaunchModule } from './state/boat-launch.ui-state'
import { UIScale } from './state/ui-scale.ui-state'
import { inventoryUIState } from './state/inventory.ui-state'
import { chatModule } from './state/chat.ui-state'
import { itemComparisonUIState } from './state/item-comparison.ui-state'
import { stashUIState } from './state/stash.ui-state'
import { miniPaperdollUIState } from './state/mini-paperdoll.ui-state'
import { hubWormDeliveryUIState } from './state/hub-worm-delivery.ui-state'
import { augmentationStation } from './state/augmentation-station.ui-state'
import { departureUIState } from './state/departure.ui-state'
import { leaderboardUIState } from './state/leaderboard.ui-state'
import { mtxStoreUIState } from './state/mtx-store.ui-state'
import { userUIState } from './state/user-ui.ui-state'
import { errorUIModule } from './state/error.ui-state'
import { emailVerificationModule } from './state/email-verification.ui-state'
import { pitStorageUIState } from './state/pit-storage.ui-state'
import { genericYesNoUIModule } from './state/generic-yes-no.ui-state'
import { poiBossRewardsUIState } from './state/poi-boss-rewards.ui.state'
import { loginQueueModule } from './state/login-queue.ui-state'
import { marketplaceUIState } from './state/marketplace.ui-state'
import { notLoggedInUiState } from './state/not-logged-in.ui-state'
import { buffAndDebuffUIModule } from './state/buff-debuff.ui.state'
import { subscriptionUIState } from './state/subscription.ui.state'
import { genericInfoPromptUIModule } from './state/generic-information-prompt.ui-state'
import { gameOverModule } from './state/gameover-ui.state'
import { clearAllProximityAndFTUEMessages, proximityMessageUIModule } from './state/proximity-message.ui-state'
import { ftueManagerModule } from './state/ftue-manager.state'
import { tutorialTooltipState } from './state/advanced-tutorial-tooltip.ui-state'
import { inGameModule } from './state/in-game.ui-state'
import { hudModule } from './state/hud.ui-state'
import { gameNewsUIState } from './state/game-news.ui.state'
import { dailyRewardsModule } from './state/daily-rewards.ui-state'
import { partyUIState } from './state/party.ui-state'
import { filterUIModule } from './state/filter-ui.state'
import VueGtag from 'vue-gtag'
import VueMarkdown from 'vue-markdown'

import { internationalizationModule } from './state/i18n-ui-state'
import { paperdollModule } from './state/paperdoll-ui-state'
import { furnaceModule } from './state/furnace-ui-state'
import { debugModule } from './state/debug-ui-state'
import { pitOfChancesModule } from './state/pit-of-chances-ui-state'
import { endRunModule } from './state/end-run-ui-state'
import { generalStoreModule } from './state/general-store-ui-state'
import { mainMenuModule } from './state/main-menu-ui-state'
import { endOfWorldModule } from './state/end-of-world-ui-state'
import { cosmeticsModule } from './state/cosmetics-ui-state'
import { identifyModule } from './state/identify-ui-state'
import { stackableSelectionModule } from './state/stackable-amount-selection.ui-state'
import { slotAnimationState } from './state/slot-animation.ui-state'

// Top of component tree
import UIOverlay from './ui-overlay.vue'
import { clientConfig } from '../engine/client/client-config'
import { startGame } from '../engine/client/start-stop'
import { GameModeType } from '../engine/shared/game-mode-type'
import { itemTools } from './state/item-tools.ui-state'
import { ConnectionPayload, SpawnStyle } from '../engine/shared/connection-payload'
import { WorldDifficulty } from '../engine/shared/game-data/world-difficulty'
import { activeGameWindowUIStore } from './state/active-game-window.ui-state'
import { startTimeUpdateInterval, timeUIStore } from './state/time.ui.state'
import { notificationsUIModule } from './state/notifications.ui-state'
import { factionsModule } from './state/factions-ui-state'

// CSS Imports
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import { genericRewardsUIModule } from './state/generic-rewards.ui-state'
import { emotesModule } from './state/emotes-ui-state'
import { toastUIState } from './state/toast.ui-state'

// Vue bootstrap stuff
Vue.config.productionTip = false
Vue.use(Vuex)
Vue.use(Toasted)
Vue.use(VueNonreactive)
Vue.use(VueTooltip)
Vue.use(VueGtag, {
	config: { id: 'G-77TWG5GRV0' },
})
Vue.use(VueMarkdown)
// Full list of reCaptcha loaderOptions found here: https://github.com/AurityLab/recaptcha-v3/#load-options-usage
Vue.use(VueReCaptcha, {siteKey: '6LdZpC0dAAAAANbrZzEJLp_Z2x9mwRcCrSbwPAsZ', loaderOptions: {autoHideBadge: true}})

function userFetched(user): boolean {
	return user.userType === 'anonymous' || user.authentication !== undefined
}

const waitUntilLoadingCompleteToStartGameClient = (gameMode: any) => {
	const loadedPercentage = UI.getInstance().store.getters['mainMenu/loadedPercentage']

	logger.debug('Still loading... current progress:', loadedPercentage)

	//TODO2 replace hack with long-term solution
	if (loadedPercentage >= 100 && userFetched(UI.getInstance().store.state.user)) {
		const connectionPayload: ConnectionPayload = {
			visibleWorldWidth: getVisibleWorldWidth() + NENGI_VIEW_X_PADDING,
			visibleWorldHeight: getVisibleWorldHeight() + NENGI_VIEW_Y_PADDING,
			userType: UI.getInstance().store.state.user.userType,
			identifier: UI.getInstance().store.state.user.userType === 'anonymous' ? UI.getInstance().store.state.user.id : UI.getInstance().store.state.user.authentication.token,
			spawnStyle: SpawnStyle.Default,
			biomeIndex: clientConfig.biomeIndex,
			worldDifficulty: WorldDifficulty.Normal,
			pressureLoadout: {},
			playToken: UI.getInstance().store.state.loginQueue.playToken || 'test',
		}

		const skipToAdventure = clientConfig.skipToGame && (clientConfig.connectTo === GameModeType.Adventure || clientConfig.connectTo === GameModeType.TestRealm) 
		// For this particular load (the initial one done via 'waitUntilLoadingCompleteToStartGameClient') hardcode world tier 0 since they're going to hub
		const worldDifficulty = skipToAdventure ? WorldDifficulty.Normal : WorldDifficulty.Hub

		const partyId = ''

		// @ts-ignore TODO2: better connection payload storage in upcoming PR
		window.originalConnectionPayload = connectionPayload
		startGame(gameMode, connectionPayload, partyId)
	} else {
		UI.getInstance().emitEvent('updateLoadedPercentage', loadedPercentage)
		setTimeout(waitUntilLoadingCompleteToStartGameClient.bind(this, gameMode), 100)
	}
}

// Add a globally available truncate filter to Vue template engine
Vue.filter('truncate', function(value: any, amount: number) {
	if (!value) {
		return ''
	}
	value = value.toString()
	if (value.length <= amount) {
		return value
	}
	return value.substring(0, amount) + '...'
})

if (!document.documentElement.onfullscreenchange) {
	document.documentElement.onfullscreenchange = (event) => {
		UI.getInstance().emitEvent('setIsFullscreen', document.fullscreenElement !== null)
	}
}

export class UI {
	static getInstance(): UI {
		if (!UI.instance) {
			UI.instance = new UI()
			startTimeUpdateInterval(UI.instance)
		}

		return UI.instance
	}

	static shutdown() {
		UI.getInstance().setUIMode('loading')
		UI.getInstance().emitAsyncEvent('inGame/closeActivePanel')
	}

	store: Store<any>
	vueInstance: Vue
	private static instance: UI

	private constructor() {
		logger.debug('Initializing UI module...')

		this.store = new Vuex.Store({
			modules: {
				// Converted
				itemLocks: itemLocks(),
				itemTools: itemTools(),
				outpostWormMail: outpostWormMail(),
				itemContainers: itemContainers(),
				UIScale: UIScale(),
				inventory: inventoryUIState(),
				itemComparison: itemComparisonUIState(),
				stash: stashUIState(),
				miniPaperdoll: miniPaperdollUIState(),
				hubWormDelivery: hubWormDeliveryUIState(),
				augmentationStation: augmentationStation(),
				departure: departureUIState(),
				leaderboardUpdated: leaderboardUIState(),
				mtxStore: mtxStoreUIState(),
				user: userUIState(),
				errors: errorUIModule(),
				emailVerification: emailVerificationModule(),
				pitStorage: pitStorageUIState(),
				genericYesNo: genericYesNoUIModule(),
				rewards: poiBossRewardsUIState(),
				marketplaceUpdated: marketplaceUIState(),
				notLoggedIn: notLoggedInUiState(),
				buffAndDebuff: buffAndDebuffUIModule(),
				gameOver: gameOverModule(),
				subscription: subscriptionUIState(),
				genericPrompt: genericInfoPromptUIModule(),
				proximityMessage: proximityMessageUIModule(),
				ftueManager: ftueManagerModule(),
				tutTooltip: tutorialTooltipState(),
				hud: hudModule(),
				chat: chatModule(),
				inGame: inGameModule(),
				newsArticles: gameNewsUIState(),
				dailyRewards: dailyRewardsModule(),
				party: partyUIState(),
				filter: filterUIModule(),
				loginQueue: loginQueueModule(),
				stackableSelection: stackableSelectionModule(),
				activeGameWindows: activeGameWindowUIStore(),
				time: timeUIStore(),
				notifications: notificationsUIModule(),
				factions: factionsModule(),
				genericRewards: genericRewardsUIModule(),
				slotAnimation: slotAnimationState(),
				emotes: emotesModule(),
				toast: toastUIState(),

				// Old / in need of conversion
				boatLaunch: boatLaunchModule(),
				mainMenu: mainMenuModule(),
				paperdoll: paperdollModule(),
				furnace: furnaceModule(),
				debug: debugModule(),
				pitOfChances: pitOfChancesModule(),
				i18n: internationalizationModule(),
				endRun: endRunModule(),
				generalStore: generalStoreModule(),
				endOfWorld: endOfWorldModule(),
				cosmetics: cosmeticsModule(),
				identify: identifyModule(),
			},
			state: {
				hideUI: debugConfig.UI.hideUI,
				showMinimap: debugConfig.UI.showMinimap,
				UIMode: 'main-menu',
				isFullScreen: false,
				loadedPercentage: 0,
				showDevTools: debugConfig.UI.showDevTools,
				showAIDebugger: debugConfig.enemy.enableBrainDebugger,
				aiDebugString: '',
				showItemTools: false,
				maintenanceMode: false,
			},
			getters: {
				hideUI(state) {
					return state.hideUI
				},
				showMinimap(state) {
					return state.showMinimap
				},
				currentUIMode(state) {
					return state.UIMode
				},
				isFullScreen(state) {
					return state.isFullScreen
				},
				loadedPercentage(state) {
					return state.loadedPercentage
				},
				showDevTools(state) {
					return state.showDevTools
				},
				showAIDebugger(state) {
					return state.showAIDebugger
				},
				maintenanceMode(state) {
					return state.maintenanceMode
				},
			},
			mutations: {
				updateLoadedPercentage(state: any, newPercentage: number) {
					state.loadedPercentage = newPercentage
				},
				setUIMode(state: any, newMode: string) {
					state.UIMode = newMode
				},
				enterGame(state: any, gameMode: any) {
					//gameMode gets overwritten to adventure if this is a new user in start-stop.ts
					setTimeout(waitUntilLoadingCompleteToStartGameClient.bind(state, gameMode), 100)
				},
				toggleFullScreen(state) {
					if (!document.fullscreenElement) {
						document.documentElement.requestFullscreen()
					} else {
						if (document.exitFullscreen) {
							document.exitFullscreen()
						}
					}
				},
				toggleDevToolsPanel(state) {
					state.showDevTools = !state.showDevTools
				},
				toggleAIDebuggerPanel(state) {
					state.showAIDebugger = !state.showAIDebugger
				},
				setAIDebugString(state, string) {
					state.aiDebugString = string
				},
				toggleItemTools(state) {
					state.showItemTools = !state.showItemTools
				},
				setIsFullscreen(state, val) {
					state.isFullScreen = val
				},
				emptyStatesOnLoad(state) {
					UI.getInstance().emitEvent('buffAndDebuff/emptyBuffStates')
					UI.getInstance().emitEvent('itemContainers/emptyPlayerEnchantments')
				},
				updateMaintenanceModeStatus(state: any, status:boolean){
					state.maintenanceMode = status
				}
			},
		})

		this.vueInstance = new Vue({
			store: this.store,
			mounted() {
				this.$nextTick(function() { })
			},
			render: (h) => h(UIOverlay),
		})
	}

	setUIMode(newMode: string) {
		this.store.commit('setUIMode', newMode)
		clearAllProximityAndFTUEMessages()
	}

	mount() {
		this.vueInstance.$mount('#ui-overlay')
	}

	userInputShouldBeDisabled(): boolean {
		const UIPaneCurrentlyActive = this.store?.state?.inGame?.UIPaneIsCurrentlyActive
		const chatCurrentlyFocused = this.store?.state?.chat?.chatFocused
		const userHasEndedRun = this.store?.state?.inGame?.endRun

		return /*UIPaneCurrentlyActive ||*/ chatCurrentlyFocused || userHasEndedRun
	}

	chatCurrentlyFocused(): boolean {
		return this.store?.state?.chat?.chatFocused
	}

	chatCurrentlyVisible(): boolean {
		return this.store?.state?.chat?.chatVisible
	}

	showDevTools(): boolean {
		return this.store?.state?.showDevTools
	}

	showAIDebugger(): boolean {
		return this.store?.state?.showAIDebugger
	}

	toggleDevTools() {
		if (process.env.NODE_ENV === 'loot-prod' || process.env.NODE_ENV === 'beta') {
			return
		}

		if (!this.showDevTools && this.chatCurrentlyVisible) {
			this.emitEvent('chat/toggleChat')
		}
		this.emitEvent('toggleDevToolsPanel')
	}

	toggleAIBrainDebugger() {
		if (process.env.NODE_ENV === 'loot-prod' || process.env.NODE_ENV === 'beta') {
			return
		}

		this.emitEvent('toggleAIDebuggerPanel')
	}

	toggleItemTools() {
		if (process.env.NODE_ENV === 'loot-prod' || process.env.NODE_ENV === 'beta') {
			return
		}

		this.emitEvent('toggleItemTools')
	}

	/** For MUTATIONS; actions use emitAsyncEvent */
	emitEvent(type: string, payload?: any, options?: CommitOptions) {
		this.store.commit(type, payload, options)
	}

	/** For ACTIONS; mutations use emitEvent */
	emitAsyncEvent(type: string, payload?: any, options?: DispatchOptions) {
		this.store.dispatch(type, payload, options)
	}
}
