import { Container, Graphics, Sprite, Text } from 'pixi.js'
import { getFacingDirection, updateAimFacing, updateAimRotation } from '../../ai/shared/anim-util'
import { getAimOffsetFromSkin, PLAYER_COLLIDER_CONFIG, PLAYER_OVER_AIM_DEGREES } from '../../engine/shared/game-data/player'
import configureAnimationTracks, { PLAYER_MIX_SETTINGS } from '../../models-animations/client/configure-animation-tracks'
import { playAnimation, playDeathAnimation } from '../../models-animations/client/play-animation'
import { RiggedSpineModel } from '../../models-animations/client/spine-model'
import { AnimationTrack } from '../../models-animations/shared/animation-track'
import WeaponFiredMessage from '../../projectiles/shared/weapon-fired-message'
import Player, { PLAYER_COSMETIC_SLOT_NAMES } from '../shared/player.shared'
import { SpineDataName, PlayerSkinValues } from '../../models-animations/shared/spine-config'
import { getDataStringFromVisualComponent, ModCategory, VisualComponent } from '../../engine/shared/game-data/stat-type-mod-category'
import WeaponSubType, { WeaponSubTypeAssetName } from '../../loot/shared/weapon-sub-type'
import { clientConfig } from '../../engine/client/client-config'
import { drawColliderVisuals, getAnimTrackString } from '../../debug/client/debug-client'
import { Audio, fizzleSoundGroupByWeaponSubtype, soundGroupByWeaponSubtype } from '../../audio/client/audio'
import { UI } from '../../ui/ui'
import Renderer, { getVisibleWorldHeight, getVisibleWorldWidth } from '../../engine/client/graphics/renderer'
import { AssetManager } from '../../asset-manager/client/asset-manager'
import logger from '../../utils/client-logger'
import { Camera } from '../../engine/client/graphics/camera-logic'
import { throttle } from 'lodash'
import SkillUsedMessage from '../../gear-skills/shared/skill-used-message'
import { biomeIndex, level, radians, timeInMilliseconds, timeInSeconds, WorldTier } from '../../utils/primitive-types'
import { Effect } from '../../engine/client/graphics/pfx/effect'
import { EffectConfig } from '../../engine/client/graphics/pfx/effectConfig'
import { debugConfig } from '../../engine/client/debug-config'
import { damp, mapToRange, sub } from '../../utils/math'
import { getBiomeCount, getBiomeList } from '../../biome/shared/biome-list'
import { gameModeConfig } from '../../engine/shared/game-mode-configs'
import { BuffIdentifier } from '../../buffs/shared/buff.shared'
import { AppliedBuffVisualsArray, clearAttachedPfx, ClientBuff, handleBuffChange } from '../../buffs/client/buff.client'
import { filterFromString } from '../../engine/client/graphics/filters'
import { attachments_addAttachment, attachments_removeAttachments } from '../../utils/attachments-system'
import { updateFogBank } from '../../world/client/fog-bank'
import { GameModeType } from '../../engine/shared/game-mode-type'
import { allPOIs } from '../../biome/shared/biome-data/poi-data'
import { GameClient } from '../../engine/client/game-client'
import { ClientPOI, getClientPOIFromIndex } from '../../world/client/poi.client'
import { POIType } from '../../world/shared/poi-data-types'
import NavigationArrow from '../../world/client/navigation-arrow'
import { getAuthorColor } from '../../ui/state/chat.ui-state'
import { MAXIMUM_INVENTORY_WEIGHT } from '../../engine/shared/game-data/inventory'
import { tryShowTutorial } from '../../ui/state/ftue-manager.state'
import { HealthBar } from '../../ai/client/health-bar'
import { updateEntityHealthVisuals } from '../../utils/entity-util'
import { InteractionPromptManager } from '../../ftue/client/interaction-prompt-manager'
import { PetSkinSpineNames, PetSpineNames, petSubTypeToBaseType, PetSubTypeValues } from '../../loot/shared/prize-sub-type'
import { getMaxBiome, PROGRESSION_MAX_TIER_FOR_BETA } from '../../engine/shared/game-data/progression'
import PartyArrows from '../../world/client/party-arrows'
import PlayerResurrected from '../shared/player-resurrected-message'
import ClientPlayerInput from '../../input/client/client-player-input'
import { createColliders, updateColliderPositions } from '../../collision/shared/colliders'
import { debugDrawInput, debugDrawText, debugInputUpdateCanvas } from '../../debug/client/debug-draw-controller'
import { ClientPlayerMovement } from './client-player-movement'
import { NengiClient } from '../../engine/client/nengi-client'
import { simpleAnimation_addAnimation, simpleAnimation_removeAnimations } from '../../utils/simple-animation-system'
import { easeOutElasticReversed } from '../../engine/shared/game-data/buffs/easing-functions'
import { LocalPlayerData } from './local-player-data'
import { EmoteType } from '../../chat/shared/emote-enums'
import { ClientEmote } from '../../chat/client/emote.client'
import { Vector } from 'sat'
import { logData } from '../../debug/client/debug-graph'
import { PlayerPositionSmoother } from './player-position-smoother'
import PlayerInput from '../../input/shared/player-input'
import { ClientProjectilesCsp } from './client-projectiles-csp'
import { ProjectileConfigSlotMessage } from '../../projectiles/shared/projectile-config-message'
import { ClientProjectile } from '../../projectiles/client/projectile.client'
import { SkinInformationByIdentifier, SKIN_SLOT_TO_SPINE_BONE } from '../../loot/shared/player-skin-sub-type'
import { SKIN_SLOT } from '../shared/set-skin.shared'
import { updateAimAngle, WeaponSlotKey } from '../shared/player-functions'
import { PlayerSetCspCommand } from '../shared/player-set-csp-command'
import { ClientBeam } from '../../beams/client/beam.client'
import { weaponSkinSubTypeToThemeSpineString } from '../../loot/shared/weapon-skin-sub-type'
import { FTUESequence } from '../../ftue/shared/ftue.shared'
import { FactionIconDimensions, FactionShortName, getFactionHudIconAssetName } from '../../factions/shared/faction-data'
import SkillCooldownUpdatedMessage from '../../gear-skills/shared/skill-updated-message'

const PET_OFFSET = 125
const PET_LERP_SPEED = 0.05
const PET_BOUNCE_IN_DURATION = 1
const EMOTE_OFFSET = -180

const WHERE_AM_I_Y_OFFSET = -300

const STARTING_DOCK_X = 900

export class ClientPlayer extends Player {
	isLocalPlayer: boolean
	myPlayerData: LocalPlayerData
	model: Container
	riggedModel: RiggedSpineModel
	weapon: RiggedSpineModel
	weaponSubType: WeaponSubType
	petModel: RiggedSpineModel
	x: number
	y: number
	petX: number
	petY: number
	ping: timeInMilliseconds
	maxHealth: number
	currentHealth: number
	isGhost: boolean
	actualPlayersViewportRectangle: Graphics
	actualPlayersViewportText: Text
	colliderVisual: Graphics
	debugModel: Container
	debugStringText: Text
	assetPreloadViewBoxVisual: Graphics
	assetPreloadViewBoxText: Text
	healthbar: HealthBar
	healthbarBackground: Graphics
	healthbarForeground: Graphics
	
	nameplateText: Text
	nameplateBackground: Graphics
	nameplateFactionIcon: Sprite
	nameplatePartyDecals: Sprite[] = []

	whereAmIHighlight: Sprite
	isShowingWhereAmIHighlight: boolean = false

	weaponOut: boolean = true

	isOnCooldown: boolean = false
	attackCooldown: number
	currentAttackCooldown: number = 0
	currentEnergy: number

	maxEnergyOne: number
	maxEnergyTwo: number

	get maxEnergy() {
		return this.activeWeaponSlot === 'one' ? this.maxEnergyOne : this.maxEnergyTwo
	}

	soundEffects: any

	debugHealthbarVisualWidth: number = 40

	currentAmbience: Effect

	attachedBuffEffects: AppliedBuffVisualsArray = [null, null, null]
	footDustPfx: Effect

	currentBiome: biomeIndex = 0
	lastFrameX: number = 0

	progressionLevel: level

	chargePfx: Effect
	isUsingBattery = null
	batteryPfx: Effect

	poi: ClientPOI
	
	atStartingDock: boolean = false
	fightingAtAPOI: boolean = false

	died: boolean = false

	cspMovement: ClientPlayerMovement
	cspRemotePlayerSmoother: PlayerPositionSmoother
	cspProjectiles: ClientProjectilesCsp
	cspBuffs: ClientBuff[] = []

	emojiEmote: ClientEmote
	typingEmote: ClientEmote

	attachedCosmetics = new Map<SKIN_SLOT, RiggedSpineModel>()
	currentWeaponVisuals: string

	private biomeAmbienceMap: Array<{
		effect: Effect
		isAddedToScene: boolean
	}> = [] // ordered by biome index

	private ambienceTransitionDistance: number = getVisibleWorldWidth() / 2
	private ambienceCleanUpDistance: number = getVisibleWorldWidth() + this.ambienceTransitionDistance
	private readonly AMBIENCE_Z_OFFSET = 125

	private recentDeltas: number[] = []

	get ignoreColliders(): number[] {
		return this.myPlayerData.ignoreColliders
	}

	get visualPos(): Vector {
		if (this.cspMovement) {
			return this.cspMovement.clientPosSmoothed
		}
		if (this.cspRemotePlayerSmoother) {
			return this.cspRemotePlayerSmoother.clientPosSmoothed
		}
		return this.position
	}

	get zIndex(): number {
		return this.model.zIndex
	}

	constructor(entityData) {
		super()

		this.isLocalPlayer = entityData.isMyEntity
		if (this.isLocalPlayer) {
			this.myPlayerData = entityData.localPlayerData
		}

		if (clientConfig.debug) {
			this.debugModel = new Container()
			this.debugModel.name = 'debug-player'
			if (clientConfig.renderAnimDebugVisuals) {
				this.drawGenericDebugVisuals()
			}
			this.drawDebugClientViewBoxVisual(debugConfig.camera.fakeResolutionWidth, debugConfig.camera.fakeResolutionHeight)
		}

		this.x = entityData.x
		this.y = entityData.y
		this.x_nointerp = entityData.x_nointerp
		this.y_nointerp = entityData.y_nointerp
		this.factionAffiliation = entityData.factionAffiliation
		this.name = entityData.name

		this.createCspMovementIfEnabled()

		this.colliders = createColliders(this.position, PLAYER_COLLIDER_CONFIG)

		const curBiome = this.getCurrentBiome()
		if (curBiome > 0) {
			const renderer = Renderer.getInstance()
			renderer.bgRenderer?.getGradientRenderer().setBiome(curBiome, renderer.zoomLevel)
		}

		this.soundEffects = {
			impact: 'SFX_Player_Hit_Health',
			impactShield: 'SFX_Player_Shield_Block',
			impactCritical: 'SFX_Player_Hit_Critical',
			impactInstadeath: 'SFX_Player_Instadeath',
			teleport: 'SFX_Player_Teleport',
			footfall: 'SFX_Player_Footfall_Dirt',
		}

		const asset = AssetManager.getInstance().getAssetByName(SpineDataName.PLAYER_SKINS)
		this.model = new Container()
		this.model.name = 'player'
		this.model.filters = []
		// tslint:disable-next-line
		this.model['update'] = (dt) => {
			this.riggedModel.update(dt)
			if (this.petModel) {
				this.petModel.update(dt)
			}

			this.attachedCosmetics.forEach((model) => {
				model.update(dt)
			})
		}

		this.isGhost = entityData.isGhost

		this.riggedModel = new RiggedSpineModel(asset.spineData)
		this.model.addChild(this.riggedModel)
		this.riggedModel.skeleton.setSkinByName(entityData.skin)
		this.riggedModel.skeleton.setToSetupPose()
		this.riggedModel.filters = []

		this.partyId = entityData.partyId
		const ourPlayer = GameClient.getInstance().state.myEntity
		if(ourPlayer && ourPlayer.isShowingWhereAmIHighlight && ourPlayer.partyId === entityData.partyId && entityData.partyId !== '') {
			this.showWhereAmIHighlight()
		}

		if (clientConfig.playerFilter) {
			this.riggedModel.filters = [filterFromString(clientConfig.playerFilter, clientConfig.filterParams)]
		}

		if (this.debugModel) {
			this.drawDebugColliderVisual()
			this.drawHealthVisuals()
		}

		this.drawNameplate()

		configureAnimationTracks(this.riggedModel, PLAYER_MIX_SETTINGS)

		this.weaponOut = entityData.weaponOut
		playAnimation(this.riggedModel, this.weaponOut ? AnimationTrack.IDLE : AnimationTrack.IDLE_NO_WEAPON)

		this.model.zIndex = this.model.y

		Renderer.getInstance().mgRenderer.addDisplayObjectToScene(this.model)

		if (this.debugModel) {
			Renderer.getInstance().mgRenderer.addChild(this.debugModel)
		}

		this.footDustPfx = Renderer.getInstance().addEffectToScene('dust', 0, 0)

		const alreadyDead = entityData.currentHealth <= 0
		if (alreadyDead) {
			this.footDustPfx.enabled = false
			// this.model.visible = false
		} else {
			attachments_addAttachment(this.footDustPfx, this, () => {
				return sub(this.visualPos, this)
			}, true)

			if (!this.healthbar) {
				this.getHealthbar(this.getHealthbarYOffset())
			}
		}

		this.getHealthbar(this.getHealthbarYOffset())
		this.model.setChildIndex(this.healthbar.getContainer(), this.model.children.length - 1)

		for (const varName of PLAYER_COSMETIC_SLOT_NAMES) {
			const skin = entityData[varName]
			if (skin !== '') {
				this.setPlayerCosmetic(skin)
			}
		}

		this.currentWeaponVisuals = entityData.currentWeaponVisuals
		this.primaryWeaponSkin = entityData.primaryWeaponSkin
		this.secondaryWeaponSkin = entityData.secondaryWeaponSkin

		updateVisualsAfterMovement(this, entityData.isMyEntity)
	}

	isDead(): boolean {
		return this.currentHealth <= 0
	}

	getHealthbarYOffset() {
		return 55
	}

	getHealthbar(yOffset) {
		this.healthbar = HealthBar.pool.alloc()

		const container = this.healthbar.getContainer()
		container.visible = false

		container.y = yOffset

		this.healthbar.setParentContainer(this.model)
	}

	getEmote(): ClientEmote {
		const emote = ClientEmote.pool.alloc()

		const container = emote.getContainer()
		container.y = EMOTE_OFFSET
		container.visible = true
		emote.setParentContainer(this.model)

		return emote
	}

	createAmbience(): void {
		const renderer = Renderer.getInstance()
		const biomeList = getBiomeList(gameModeConfig.type)

		for (let i = 0; i < biomeList.length; i++) {
			const pfxAsset = AssetManager.getInstance().getAssetByName(biomeList[i].ambianceEffect).data as EffectConfig
			const effect = new Effect(pfxAsset, renderer.cameraState)
			effect.x = this.x
			effect.y = this.y
			effect.zIndex = this.y
			effect.zOffset = this.AMBIENCE_Z_OFFSET
			this.biomeAmbienceMap[i] = { effect, isAddedToScene: false }
		}

		this.currentBiome = this.getCurrentBiome()
		if (this.currentBiome !== -1) {
			this.setCurrentAmbienceAndAddToScene(this.currentBiome)
			this.updateAmbience()
		}
	}

	onDelete() {
		this.healthbar?.returnToPool()

		attachments_removeAttachments(this)
		Renderer.getInstance().removeEffectFromScene(this.footDustPfx)
		Renderer.getInstance().removeEffectFromScene(this.chargePfx)
		clearAttachedPfx(this)
	}

	die(isMyEntity: boolean) {
		if (this.died) {
			//This is called once from a nengi message 'handlePlayerDied' and again when the currentHealth updates to 0 (for dead players staying dead when entering screen)
			return
		}

		this.died = true

		if (isMyEntity) {
			const startedSecondRun = UI.getInstance().store.getters['ftueManager/getFlag']('startedSecondRun')
			if (startedSecondRun) {
				this.model.alpha = 0.6
			} else {
				playDeathAnimation(this.riggedModel)
			}

			UI.getInstance().emitEvent('hud/setArrowToAltarTemporarily')
		} else {
			this.model.alpha = 0.6
		}

		this.healthbar?.fadeOut()
		Audio.getInstance().playSfx('Player_Die')
		Renderer.getInstance().removeEffectFromScene(this.footDustPfx)

	}

	changeWeaponOut(isOut: boolean) {
		if (this.weapon) {
			this.weapon.visible = isOut
		}

		if (isOut) {
			playAnimation(this.riggedModel, AnimationTrack.IDLE)
		} else {
			this.riggedModel.state.clearTracks()
			playAnimation(this.riggedModel, AnimationTrack.IDLE_NO_WEAPON)
			this.riggedModel.skeleton.setToSetupPose()
		}
	}

	equipWeapon(weaponType: WeaponSubType, component1: VisualComponent, component2: VisualComponent, component3: VisualComponent) {
		const spineAssetName = WeaponSubTypeAssetName.get(weaponType)

		AssetManager.getInstance().getAssetByNameAsync(spineAssetName, (asset) => {
			if (this.weapon) {
				this.riggedModel.detachSpineSprite('weapon_placeholder')
			}
			const weaponAsset = asset.spineData
			this.weapon = new RiggedSpineModel(weaponAsset)


			let handle = getDataStringFromVisualComponent(component1)
			let ornament = getDataStringFromVisualComponent(component2)
			let head = getDataStringFromVisualComponent(component3)

			if (this.activeWeaponSlot === 'one' && this.primaryWeaponSkin !== 0) {
				const theme = weaponSkinSubTypeToThemeSpineString(this.primaryWeaponSkin)
				handle = `handle-${theme}`
				ornament = `ornament-${theme}`
				head = `head-${theme}`
			} else if (this.activeWeaponSlot === 'two' && this.secondaryWeaponSkin !== 0) {
				const theme = weaponSkinSubTypeToThemeSpineString(this.secondaryWeaponSkin)
				handle = `handle-${theme}`
				ornament = `ornament-${theme}`
				head = `head-${theme}`
			} else {
				handle = getDataStringFromVisualComponent(component1)
				ornament = getDataStringFromVisualComponent(component2)
				head = getDataStringFromVisualComponent(component3)
			}

			this.weaponSubType = weaponType

			this.weapon.name = 'weapon'

			const skin = new PIXI.spine.core.Skin('myWeapon')
			const handleSkin = this.weapon.skeleton.data.findSkin(handle)
			const ornamentSkin = this.weapon.skeleton.data.findSkin(ornament)
			const headSkin = this.weapon.skeleton.data.findSkin(head)

			if (handleSkin) {
				skin.addSkin(handleSkin)
			} else {
				logger.error(`Couldn't find handle skin ${handle}! Something probably wasn't hooked up right!`)
			}

			if (ornamentSkin) {
				skin.addSkin(ornamentSkin)
			} else {
				logger.error(`Couldn't find ornament skin ${ornament}! Something probably wasn't hooked up right!`)
			}

			if (headSkin) {
				skin.addSkin(headSkin)
			} else {
				logger.error(`Couldn't find head skin ${head}! Something probably wasn't hooked up right!`)
			}

			this.setWeaponSkin(skin)
		})
	}

	setWeaponSkin(skin: PIXI.spine.core.Skin) {
		this.weapon.skeleton.setSkin(skin)
		this.reattachWeapon()
		this.weapon.visible = this.weaponOut
	}

	reattachWeapon() {
		this.riggedModel.attachSpineSprite('weapon_placeholder', this.weapon)

		// TODO3: This is gross
		for (let i = 0; i < this.riggedModel.spineData.defaultSkin.attachments.length; ++i) {
			const attachment = this.riggedModel.spineData.defaultSkin.attachments[i]
			if (attachment && attachment.weapon_placeholder) {
				// @ts-expect-error we don't narrow the attachments to something that has rotation,
				// i don't know what kind of attachments skins use and at this point i'm afraid to ask
				this.weapon.rotation = attachment.weapon_placeholder.rotation
				break
			}
		}

		this.weapon.update(0) //Update the weapon after setting it as a child of the player so it renders properly
		//update is disabled by default so manual call here
	}

	handlePlayerResurrectedMessage(message: PlayerResurrected) {
		this.died = false

		this.model.alpha = 1.0
		this.healthbar?.removeFade()
		this.healthbar.getContainer().visible = false

		UI.getInstance().emitEvent('hud/returnArrowToPrevious')

		if (this.isLocalPlayer) {
			// let's go!
			UI.getInstance().emitEvent('emotes/performSpawnEmote')
		}
	}

	handleWeaponFiredMessage(message: WeaponFiredMessage, isOurPlayer: boolean) {
		const timeScale = Math.clamp(1 / message.attackCooldown, 0.4, 2.5)

		if (isOurPlayer) {
			const recoilMagnitude = mapToRange(timeScale, 0.4, 2.5, 15, 3)
			Camera.getInstance().triggerRecoil(message.aimAngle, recoilMagnitude)

			if (!this.isOnCooldown && !debugConfig.csp) {
				this.isOnCooldown = true
				this.attackCooldown = message.attackCooldown
				this.currentAttackCooldown = message.attackCooldown
				UI.getInstance().emitEvent('hud/updatedShotCooldownPercentage', 100)
				UI.getInstance().emitEvent('hud/updateIsOnShotCooldown', { newIsOnShotCooldown: true, shouldDisplayShotCooldownIndicator: this.attackCooldown > 0.7 })
			}
		}

		if (this.model == null) {
			return
		}

		let tracks: Array<{
			track: AnimationTrack
			timeScale: number
		}>
		if (timeScale <= 1.5) {
			tracks = [
				{
					track: AnimationTrack.SHOOT_SLOW_RECOIL,
					timeScale: 1,
				},
				{
					track: AnimationTrack.SHOOT_SLOW_RETURN,
					timeScale: Math.clamp(1 / message.attackCooldown, 0.15, 2),
				},
			]
			// console.log(`SLOW_RECOIL, attackCooldown ${message.attackCooldown}, timeScale ${tracks[1].timeScale}`)
		} else {
			tracks = [
				{
					track: AnimationTrack.SHOOT_FAST_RECOIL,
					timeScale: 1,
				},
				{
					track: AnimationTrack.SHOOT_FAST_RETURN,
					timeScale: Math.clamp(8 / 30 / message.attackCooldown, 0.15, 1),
				},
			]
			// console.log(`FAST_RECOIL, attackCooldown ${message.attackCooldown}, timeScale ${tracks[1].timeScale}`)
		}

		playAnimation(this.riggedModel, tracks[0].track, null, tracks[0].timeScale)
		playAnimation(this.riggedModel, tracks[1].track, null, tracks[1].timeScale)

		if (this.weaponSubType) {
			const track = message.success ? soundGroupByWeaponSubtype(this.weaponSubType) : fizzleSoundGroupByWeaponSubtype(this.weaponSubType)
			Audio.getInstance().playSfx(track)
		}
	}

	handleSkillUsed(skillUsed: SkillUsedMessage) {
		const pos = this.visualPos

		GameClient.playSkillUsedSound(skillUsed.skillId)

		if (this.isLocalPlayer) {
			UI.getInstance().emitEvent('hud/gearSkillUsed', skillUsed)
			setTimeout(() => {
				UI.getInstance().emitEvent('hud/gearSkillCooldownElapsed', {
					entityId: skillUsed.entityId,
					skillId: skillUsed.skillId,
					slot: skillUsed.skillSlot,
				})
			}, skillUsed.cooldownDuration)
		}

		switch (skillUsed.skillId) {
			case ModCategory.SKILL_DODGE_ROLL:
				playAnimation(this.riggedModel, AnimationTrack.DODGE_ROLL_INVULN, null, 0.9)
				this.playAnimationOnCosmetics(AnimationTrack.DODGE_ROLL_INVULN, 0.9)
				Renderer.getInstance().addOneOffEffect('smoke-explosion', pos.x, pos.y, pos.y + 1, 0.5, 1, true)
				break
			case ModCategory.SKILL_TUMBLE_ROLL:
				playAnimation(this.riggedModel, AnimationTrack.DODGE_ROLL, null, 1.2)
				this.playAnimationOnCosmetics(AnimationTrack.DODGE_ROLL, 1.2)
				Renderer.getInstance().addOneOffEffect('smoke-explosion', pos.x, pos.y, pos.y + 1, 0.5, 1, true)
				break
			case ModCategory.SKILL_STONE_FORM:
				playAnimation(this.riggedModel, AnimationTrack.SPECIAL_ABILITY_AOE, null, 2.0)
				Audio.getInstance().playSfx('SFX_Gear_Stone_Start')
				break
			case ModCategory.SKILL_OVERSHIELD:
				playAnimation(this.riggedModel, AnimationTrack.SPECIAL_ABILITY_AOE, null, 2.0)
				Audio.getInstance().playSfx('SFX_Gear_Overshield_Start')
				break
			case ModCategory.SKILL_MOVEMENT_SPEED_BOOST:
			case ModCategory.SKILL_ATTACK_SPEED_BOOST:
				playAnimation(this.riggedModel, AnimationTrack.HIT)
				Renderer.getInstance().addOneOffEffect('smoke-explosion', pos.x, pos.y, pos.y + 1, 1, 1, true)
				break
			case ModCategory.SKILL_OVERCHARGED_SHOT:
				playAnimation(this.riggedModel, AnimationTrack.HIT)
				Renderer.getInstance().addOneOffEffect('skill-overcharged-shot', pos.x, pos.y, pos.y + 1, 1, 1, true)
				Audio.getInstance().playSfx('SFX_Gear_Overcharged_Start')
				break
			case ModCategory.SKILL_BATTLE_CRY:
				playAnimation(this.riggedModel, AnimationTrack.SPECIAL_ABILITY_AOE, null, 1.5)
				Renderer.getInstance().addOneOffEffect('skill-battle-cry', pos.x, pos.y, pos.y + 1, 1, 1, true)
				Audio.getInstance().playSfx('SFX_Gear_Battle_Cry')
				break
			case ModCategory.SKILL_SICKENING_NOVA:
				playAnimation(this.riggedModel, AnimationTrack.SPECIAL_ABILITY_AOE, null, 1.8)
				Renderer.getInstance().addOneOffEffect('skill-sickening-nova', pos.x, pos.y, pos.y + 1, 1.4, 1, true)
				Audio.getInstance().playSfx('SFX_Gear_Sickening_Nova')
				break
			case ModCategory.SKILL_GRAVITY_WELL:
				playAnimation(this.riggedModel, AnimationTrack.SPECIAL_ABILITY_AOE, null, 2.0)
				Audio.getInstance().playSfx('SFX_Gear_Gravity_Start')
				break
			case ModCategory.SKILL_BATTERY:
				if (!this.isUsingBattery) {
					playAnimation(this.riggedModel, AnimationTrack.DODGE_ROLL, null, 0.75)
					this.batteryPfx = Renderer.getInstance().addOneOffEffect('skill-battery', pos.x, pos.y, pos.y - 1, 1, skillUsed.skillDuration / 1000, true)
					Audio.getInstance().playSfx('SFX_Gear_Battery')
					this.isUsingBattery = setTimeout(() => {
						clearTimeout(this.isUsingBattery)
						this.isUsingBattery = null
					}, skillUsed.skillDuration)

				} else {
					playAnimation(this.riggedModel, AnimationTrack.DODGE_ROLL, null, 0.75)
					setTimeout(() => {
						clearTimeout(this.isUsingBattery)
						this.isUsingBattery = null
						if (this.batteryPfx) {
							Renderer.getInstance().removeEffectFromScene(this.batteryPfx) //TODO: would be nice to disable emitters and let it fade out naturally
							this.batteryPfx = null
						}
					}, 400)
				}
				break
			case ModCategory.SKILL_BULWARK:
				playAnimation(this.riggedModel, AnimationTrack.SPECIAL_ABILITY_AOE, null, 2.0)
				// Renderer.getInstance().addOneOffEffect('skill-bulwark', pos.x, pos.y, pos.y + 1, 1, skillUsed.skillDuration / 1000, true)
				break
			default:
				logger.error(`handleSkillUsed was passed a skill with no handler: ${skillUsed.skillId}`)
				break
		}
	}

	handleSkillCooldownUpdated(cooldown: SkillCooldownUpdatedMessage) {
		if (this.isLocalPlayer) {
			UI.getInstance().emitEvent('hud/gearSkillCooldownUpdated', cooldown)
		}
	}

	playAnimationOnCosmetics(animationTrack: AnimationTrack, timeScale: number) {
		this.attachedCosmetics.forEach((model) => {
			playAnimation(model, animationTrack, null, timeScale)
		})
	}

	drawDebugClientViewBoxVisual(newWidth, newHeight) {
		const visibleGameWorldWidthAdjustedForZoomLevel = getVisibleWorldWidth()
		const visibleGameWorldHeightAdjustedForZoomLevel = getVisibleWorldHeight()

		if (this.debugModel && clientConfig.debug) {
			if (this.actualPlayersViewportText) {
				this.debugModel.removeChild(this.actualPlayersViewportText)
			}
			if (this.actualPlayersViewportRectangle) {
				this.debugModel.removeChild(this.actualPlayersViewportRectangle)
			}

			this.actualPlayersViewportRectangle = new Graphics()
			this.actualPlayersViewportRectangle.name = 'actual-viewport'
			this.actualPlayersViewportRectangle.lineStyle(10, 0xccffff)
			this.actualPlayersViewportRectangle.drawRect(-visibleGameWorldWidthAdjustedForZoomLevel / 2, -visibleGameWorldHeightAdjustedForZoomLevel / 2, visibleGameWorldWidthAdjustedForZoomLevel, visibleGameWorldHeightAdjustedForZoomLevel)
			this.actualPlayersViewportText = new Text(`How much the player can see @ default zoom in game units) w${visibleGameWorldWidthAdjustedForZoomLevel}, h${visibleGameWorldHeightAdjustedForZoomLevel} (actual:${window.innerWidth}x${window.innerHeight}`, {
				fontSize: 40,
				fill: '#ffffff',
				align: 'left',
			})
			this.actualPlayersViewportText.name = 'actual-text'
			this.actualPlayersViewportText.position.x = -visibleGameWorldWidthAdjustedForZoomLevel / 2 + 10
			this.actualPlayersViewportText.position.y = -visibleGameWorldHeightAdjustedForZoomLevel / 2 + 5

			this.debugModel.addChild(this.actualPlayersViewportText)
			this.debugModel.addChild(this.actualPlayersViewportRectangle)
		}
	}

	drawDebugColliderVisual() {
		this.colliderVisual = drawColliderVisuals(PLAYER_COLLIDER_CONFIG)
		this.debugModel.addChild(this.colliderVisual)
	}

	drawHealthVisuals() {
		this.healthbarBackground = new Graphics()
		this.healthbarBackground.beginFill(0xffffff)
		const rad = PLAYER_COLLIDER_CONFIG[0].radius // TODO3 undo
		this.healthbarBackground.drawRect(-this.debugHealthbarVisualWidth / 2, -rad - 10, this.debugHealthbarVisualWidth, 8)

		this.healthbarForeground = new Graphics()
		this.healthbarForeground.beginFill(0x00ff00)
		this.healthbarForeground.drawRect(-this.debugHealthbarVisualWidth / 2, -rad - 10, this.debugHealthbarVisualWidth, 8)

		const model = this.debugModel || this.model
		model.addChild(this.healthbarBackground)
		model.addChild(this.healthbarForeground)
	}

	drawNameplate() {
		this.nameplateText = new Text(this.name, {
			fontFamily: 'Yanone Kaffeesatz',
			fontSize: 28,
			fontWeight: 900,
			letterSpacing: 1,
			align: 'center',
			fill: 0,
			dropShadow: true,
			dropShadowAngle: 2,
			dropShadowColor: '#2a313e',
			dropShadowDistance: 4,
			lineJoin: 'bevel',
			miterLimit: 5,
			padding: 8,
			stroke: '#2a313e',
			strokeThickness: 2,
		})
		this.nameplateText.name = 'nameplate'
		this.updateNameplateText(this.name)
		const model = this.model
		model.addChild(this.nameplateBackground)
		model.addChild(this.nameplateText)

	}

	updateNameplateText(newName) {
		const isPartyBox = this.isInMyEntitysPartyButIsNotMyEntity()

		this.nameplateText.text = newName
		this.nameplateText.updateText(true)
		this.updateFactionIcon(this.factionAffiliation)
		// x set in updateFactionIcon
		this.nameplateText.y = 18

		if (newName) {
			const authorColorObj = getAuthorColor(newName, isPartyBox)
			const colorStr = authorColorObj.chatColor

			this.nameplateText.style.fill = colorStr
			this.nameplateText.style.stroke = authorColorObj.strokeColor
			this.nameplateText.style.dropShadowColor = authorColorObj.shadowColor
		} else {
			this.nameplateText.style.fill = 0
			this.nameplateText.style.stroke = 0
			this.nameplateText.style.dropShadowColor = 0
		}

		this.updateNameplatePartyStatus()
	}

	updateFactionIcon(faction: FactionShortName)  {
		if(this.nameplateFactionIcon && this.nameplateFactionIcon.name !== faction) {
			this.model.removeChild(this.nameplateFactionIcon)
			this.nameplateFactionIcon = null
		}

		if(!this.nameplateFactionIcon && faction) {
			this.nameplateFactionIcon = Sprite.from(getFactionHudIconAssetName(faction))
			this.model.addChild(this.nameplateFactionIcon)
			this.nameplateFactionIcon.name = faction
		}

		if(this.nameplateFactionIcon) {
			this.nameplateFactionIcon.anchor.x = 0.5
			this.nameplateFactionIcon.anchor.y = 0.5

			const dimensions = FactionIconDimensions[faction]
			this.nameplateFactionIcon.width = dimensions.width
			this.nameplateFactionIcon.height = dimensions.height
			this.nameplateFactionIcon.x = (this.nameplateText.width / 2)
			this.nameplateFactionIcon.y = 30

			this.nameplateFactionIcon.visible = true
		}

		this.nameplateText.x = (-this.nameplateText.width / 2) + (this.nameplateFactionIcon ? -(this.nameplateFactionIcon.width / 2) : 0)
	}

	updateNameplatePartyStatus() {
		if (!this.nameplateBackground) {
			this.nameplateBackground = new Graphics()
		}		

		const useNameplate = this.isInMyEntitysParty() || UI.getInstance().store.getters['user/showNameplates']
		if (useNameplate) {
			this.nameplateBackground.clear()

			const textWidthForBox = Math.max(this.nameplateText.width, 120)

			this.nameplateBackground.beginFill(0x000000, 0.4)
			this.nameplateBackground.drawRoundedRect(-textWidthForBox / 2 - 35, 5, textWidthForBox + 50, this.nameplateText.height + 30, 10)

			if(this.isInMyEntitysParty() && this.nameplatePartyDecals.length === 0) {
				// add border things
				for(let i=0; i < 4; ++i) {
					this.nameplatePartyDecals[i] = Sprite.from('hud-nameplate-decal')
					this.model.addChild(this.nameplatePartyDecals[i])

					this.nameplatePartyDecals[i].width = 24
					this.nameplatePartyDecals[i].height = 23
					this.nameplatePartyDecals[i].anchor.x = 0.5
					this.nameplatePartyDecals[i].anchor.y = 0
				}

				const halfBgWidth = this.nameplateBackground.width / 2
				const bgHeight = this.nameplateBackground.height

				const decalLineXWidth = 2
				const decalLineYWidth = 5

				// Left
				//top
				this.nameplatePartyDecals[0].x = -halfBgWidth + decalLineXWidth
				this.nameplatePartyDecals[0].y = decalLineYWidth
				//bot
				this.nameplatePartyDecals[2].x = -halfBgWidth + decalLineXWidth
				this.nameplatePartyDecals[2].y = bgHeight + decalLineYWidth
				this.nameplatePartyDecals[2].scale.y = - 1  
				
				// Right
				//top
				this.nameplatePartyDecals[1].x = halfBgWidth + decalLineXWidth - 24 //minus width
				this.nameplatePartyDecals[1].scale.x = -1
				this.nameplatePartyDecals[1].y = decalLineYWidth
				//bot
				this.nameplatePartyDecals[3].x = halfBgWidth + decalLineXWidth - 24 //minus width
				this.nameplatePartyDecals[3].scale.x = -1
				this.nameplatePartyDecals[3].y = bgHeight + decalLineYWidth
				this.nameplatePartyDecals[3].scale.y = - 1  
			} else if (!this.isInMyEntitysParty()) {
				// remove border things
				for(let i =0; i < this.nameplatePartyDecals.length; ++i) {
					this.model.removeChild(this.nameplatePartyDecals[i])
				}

				this.nameplatePartyDecals.length = 0
			}

			if (!this.healthbar) {
				this.getHealthbar(this.getHealthbarYOffset())
			} else {
				const container = this.healthbar.getContainer()

				container.y = this.getHealthbarYOffset()
			}
			updateEntityHealthVisuals(this, this.currentHealth, this.maxHealth, true)
		} else {
			this.nameplateBackground.clear()

			for(let i =0; i < this.nameplatePartyDecals.length; ++i) {
				this.model.removeChild(this.nameplatePartyDecals[i])
			}

			this.nameplatePartyDecals.length = 0
		}
	}

	drawInputAndPing() {
		const input = ClientPlayerInput.getInstance()
		debugDrawInput(input)
		const c = debugConfig.csp ? '#77ff77' : '#ff7777'
		debugDrawText(`Ping: ${this.ping}`, c);
	}

	update(delta: timeInSeconds) {
		this.createCspMovementIfEnabled()

		//@ts-ignore
		const ping = NengiClient.getInstance().getClient().averagePing
		this.ping = ping

		//clientColliderDebug()

		debugInputUpdateCanvas(debugConfig.drawInput)
		if (debugConfig.drawInput) {
			this.drawInputAndPing()
		}

		if (!this.isDead() || this.isGhost) {
			this.cspMovement?.update(delta)
		}

		this.cspRemotePlayerSmoother?.update(this, delta)
		this.cspProjectiles?.update(delta)

		if (clientConfig.debug && clientConfig.renderAnimDebugVisuals) {
			updateDebugString(this)
		}

		if (this.currentAmbience) {
			this.updateAmbience()
		}

		this.footDustPfx.enabled = this.isMoving

		if (this.isOnCooldown) {
			if (!debugConfig.csp) {
				this.currentAttackCooldown -= delta
			}
			if (this.currentAttackCooldown < 0) {
				this.isOnCooldown = false
				UI.getInstance().emitEvent('hud/updateIsOnShotCooldown', { newIsOnShotCooldown: false })
			}
			const pos = Renderer.getInstance().worldCoordsToScreenCoords(this.visualPos.x, this.visualPos.y)
			UI.getInstance().emitEvent('hud/updatePlayerXPosition', pos.x)
			UI.getInstance().emitEvent('hud/updatePlayerYPosition', pos.y)
			UI.getInstance().emitEvent('hud/updatedShotCooldownPercentage', (this.currentAttackCooldown / this.attackCooldown) * 100)
		}

		if (this.isLocalPlayer) {
			updateFogBank(this.progressionLevel, this.activeWorldTier)
		}

		this.healthbar?.update(delta)
		this.typingEmote?.update(delta)
		this.emojiEmote?.update(delta)

		if (this.petModel) {
			if (this.recentDeltas.length >= 10) {
				this.recentDeltas.shift()
			}
			this.recentDeltas.push(delta)

			let avgDelta = 0
			for (let i = 0; i < this.recentDeltas.length; ++i) {
				avgDelta += this.recentDeltas[i]
			}
			avgDelta /= this.recentDeltas.length

			const facingMod = Math.sign(this.riggedModel.skeleton.scaleX)
			const targetPetX = this.visualPos.x - PET_OFFSET * facingMod
			const targetPetY = this.visualPos.y
			this.petX = damp(this.petX, targetPetX, PET_LERP_SPEED, avgDelta)
			this.petY = damp(this.petY, targetPetY, PET_LERP_SPEED, avgDelta)
			this.petModel.x = this.petX - this.visualPos.x
			this.petModel.y = this.petY - this.visualPos.y

			this.petModel.scale.x = facingMod
		}

		if (this.isLocalPlayer) {
			PartyArrows.getInstance().updatePosition(this.x, this.y)

			if (this.poi) {
				if (this.poi.eventActive) {
					this.fightingAtAPOI = true
				} else {
					if (this.fightingAtAPOI) {
						// hooray!
						UI.getInstance().emitEvent('emotes/performVictoryEmote')
					}
					this.fightingAtAPOI = false
				}
			} else {
				this.fightingAtAPOI = false
			}
		}
	}

	private createCspMovementIfEnabled() {
		if (debugConfig.csp) {
			if (this.isLocalPlayer && !this.cspProjectiles) {
				this.cspProjectiles = new ClientProjectilesCsp(this)
				NengiClient.getInstance().sendCommand(new PlayerSetCspCommand(true))
			}
			if (this.isLocalPlayer && !this.cspMovement) {
				this.cspMovement = new ClientPlayerMovement(this)
			} else if (!this.isLocalPlayer && !this.cspRemotePlayerSmoother) {
				this.cspRemotePlayerSmoother = new PlayerPositionSmoother(this)
			}
		} else if (!debugConfig.csp) {
			if (this.cspProjectiles) {
				NengiClient.getInstance().sendCommand(new PlayerSetCspCommand(false))
				this.cspProjectiles.cleanup()
			}
			this.cspMovement = null
			this.cspRemotePlayerSmoother = null
			this.cspProjectiles = null
		}
	}

	updateColliderPositions() {
		updateColliderPositions(PLAYER_COLLIDER_CONFIG, this.colliders, { x: this.cspMovement.predictedPos.x, y: this.cspMovement.predictedPos.y }, this.facingDirection)
	}

	handleInput(curInput: PlayerInput, delta: timeInSeconds) {
		if (this.isDead() && !this.isGhost) {
			return
		}

		this.cspMovement?.handleInput(curInput, delta)
		this.cspProjectiles?.handleInput(curInput, delta)

		if (debugConfig.csp && debugConfig.cspConfig.clientSideAim) {
			updateAimAngle(this, curInput.aimX, curInput.aimY, delta)

			this.facingDirection = getFacingDirection(this.aimAngle, this.facingDirection, PLAYER_OVER_AIM_DEGREES)
			updateAimAngleVisuals(this, this.aimAngle)
		}
	}

	handleProjectileConfig(projectileConfig: ProjectileConfigSlotMessage) {
		this.cspProjectiles?.handleProjectileConfig(projectileConfig)
	}

	handleEntityCreate(entity) {
		if (entity instanceof ClientProjectile && this.cspProjectiles) {
			if (entity.owningEntityId === this.nid) {
				this.cspProjectiles.handleMyProjectileCreate(entity)
			}
		}
		if (entity instanceof ClientBeam && this.cspProjectiles) {
			if (entity.owningEntityId === this.nid) {
				this.cspProjectiles.handleMyBeamCreate(entity)
			}
		}
	}
	handleEntityDestroy(entity) {
		if (entity instanceof ClientProjectile && this.cspProjectiles) {
			if (entity.owningEntityId === this.nid) {
				this.cspProjectiles.handleMyProjectileDestroy(entity)
			}
		}
		if (entity instanceof ClientBeam && this.cspProjectiles) {
			if (entity.owningEntityId === this.nid) {
				this.cspProjectiles.handleMyBeamCreate(entity)
			}
		}
	}

	getCurrentBiome(renderer?: Renderer): biomeIndex {
		if (gameModeConfig.type === GameModeType.Hub) {
			return 0
		}

		renderer = renderer || Renderer.getInstance()
		if (renderer.biomeBounds) {
			return renderer.getBiomeCurrentBiome(this.x)
		}
		return -1
	}

	setCurrentAmbienceAndAddToScene(i: biomeIndex, renderer?: Renderer) {
		const effectData = this.biomeAmbienceMap[i]
		this.currentAmbience = effectData.effect
		if (effectData.isAddedToScene) {
			return
		}
		effectData.isAddedToScene = true
		renderer = renderer || Renderer.getInstance()
		renderer.mgRenderer.addEffectToScene(effectData.effect)
	}

	removeAmbienceFromScene(i: biomeIndex, renderer?: Renderer) {
		renderer = renderer || Renderer.getInstance()
		const effectData = this.biomeAmbienceMap[i]
		if (!effectData.isAddedToScene) {
			return
		}
		effectData.isAddedToScene = false
		renderer.mgRenderer.removeFromScene(effectData.effect)
	}

	setModelSkin(skin) {
		if (skin && PlayerSkinValues.includes(skin)) {
			const oldFilters = this.riggedModel.filters
			this.model.removeChild(this.riggedModel)


			const asset = AssetManager.getInstance().getAssetByName(SpineDataName.PLAYER_SKINS)
			this.riggedModel = new RiggedSpineModel(asset.spineData)

			configureAnimationTracks(this.riggedModel, PLAYER_MIX_SETTINGS)
			playAnimation(this.riggedModel, this.weaponOut ? AnimationTrack.IDLE : AnimationTrack.IDLE_NO_WEAPON)

			this.riggedModel.skeleton.setSkinByName(skin)
			this.riggedModel.skeleton.setToSetupPose()
			this.riggedModel.filters = oldFilters

			this.reattachCosmetics()
			if(this.weapon) {
				this.reattachWeapon()
			}
			
			this.riggedModel.update(0)

			this.model.addChild(this.riggedModel)

			this.aimOffset = getAimOffsetFromSkin(skin)

			//update healthbar height
			if (this.healthbar) {
				const container = this.healthbar.getContainer()
				container.y = this.getHealthbarYOffset()
			}

			if (this.isLocalPlayer) {
				UI.getInstance().emitEvent('cosmetics/setEquippedMainSkin', skin)
			}
		}
	}

	reattachCosmetics() {
		this.attachedCosmetics.forEach((cosmetic, skinSlot) => {
			const spineAttachPoint = SKIN_SLOT_TO_SPINE_BONE[skinSlot]
			this.riggedModel.attachSpineSprite(spineAttachPoint, cosmetic)
			cosmetic.update(0)
		})
	}

	setPlayerCosmetic(cosmeticName: string, skinSlot?: SKIN_SLOT) {
		if (this.isLocalPlayer) {
			UI.getInstance().emitEvent('cosmetics/setEquippedCosmetic', { cosmeticName, skinSlot })
		}

		if (cosmeticName === '' || cosmeticName === '0') {
			if (skinSlot) {
				if (skinSlot === SKIN_SLOT.PLAYER_FOOTPRINT) {
					// Special case, as "no" pfx trail should still give the default dust pfx
					Renderer.getInstance().removeEffectFromScene(this.footDustPfx)
					this.footDustPfx = Renderer.getInstance().addEffectToScene('dust', 0, 0)

					attachments_addAttachment(this.footDustPfx, this, () => {
						return sub(this.visualPos, this)
					}, true)
				} else {
					const attached = this.attachedCosmetics.get(skinSlot)
					if (attached) {
						const spineAttachPoint = SKIN_SLOT_TO_SPINE_BONE[skinSlot]
						this.riggedModel.detachSpineSprite(spineAttachPoint)
						this.attachedCosmetics.delete(skinSlot)
					}
				}
			}

			return
		}

		const cosmeticInfo = SkinInformationByIdentifier.get(cosmeticName)

		if (cosmeticInfo) {
			const alreadyAttached = this.attachedCosmetics.get(cosmeticInfo.skinSlot)
			if (alreadyAttached && alreadyAttached.name === cosmeticName) {
				return
			}

			if (cosmeticInfo.skinSlot === SKIN_SLOT.PLAYER_FOOTPRINT) {
				// Foot Dust PFX
				const pfxAssetName = cosmeticInfo.spineJsonName

				Renderer.getInstance().removeEffectFromScene(this.footDustPfx)
				this.footDustPfx = Renderer.getInstance().addEffectToScene(pfxAssetName, 0, 0)

				attachments_addAttachment(this.footDustPfx, this, () => {
					return sub(this.visualPos, this)
				}, true)
			} else {
				// Spine model
				AssetManager.getInstance().getAssetByNameAsync(cosmeticInfo.spineJsonName, (asset) => {
					const cosmeticModel = new RiggedSpineModel(asset.spineData)
					cosmeticModel.name = cosmeticName
					cosmeticModel.skeleton.setSkinByName(cosmeticInfo.spineSkin)
					cosmeticModel.skeleton.setToSetupPose()
					playAnimation(cosmeticModel, AnimationTrack.IDLE)

					const spineAttachPoint = SKIN_SLOT_TO_SPINE_BONE[cosmeticInfo.skinSlot]

					//get again because this is async and it could have changed somehow
					const alreadyEquipped = this.attachedCosmetics.get(cosmeticInfo.skinSlot)
					if (alreadyEquipped) {
						this.riggedModel.detachSpineSprite(spineAttachPoint)
					}

					this.riggedModel.attachSpineSprite(spineAttachPoint, cosmeticModel)

					this.attachedCosmetics.set(cosmeticInfo.skinSlot, cosmeticModel)

					cosmeticModel.update(0)
				})
			}

		} else {
			console.error("Could not find cosmetic info for " + cosmeticName)
		}
	}

	setPetSkin(petSkin) {
		if (this.petModel) {
			this.model.removeChild(this.petModel)
			simpleAnimation_removeAnimations(this.petModel)
		}
		this.petModel = null

		if (this.isLocalPlayer) {
			UI.getInstance().emitEvent('cosmetics/setEquippedPetSkin', petSkin)
		}

		if (petSkin && PetSubTypeValues.includes(petSkin)) {
			const petBaseType = petSubTypeToBaseType(petSkin)

			AssetManager.getInstance().getAssetByNameAsync(PetSpineNames.get(petBaseType), (petAsset) => {
				const petModel = new RiggedSpineModel(petAsset.spineData)
				this.petModel = petModel
				this.model.addChild(petModel)
				this.model.setChildIndex(petModel, 0)
				petModel.skeleton.setSkinByName(PetSkinSpineNames.get(petSkin))
				petModel.skeleton.setToSetupPose()
				petModel.filters = []

				if (clientConfig.playerFilter) {
					petModel.filters = [filterFromString(clientConfig.playerFilter, clientConfig.filterParams)]
				}

				simpleAnimation_addAnimation(this.petModel, (t) => {
					const scale = easeOutElasticReversed(t, PET_BOUNCE_IN_DURATION)
					petModel.scale.x = scale
					petModel.scale.y = scale
					return scale
				}, PET_BOUNCE_IN_DURATION)

				playAnimation(this.petModel, AnimationTrack.IDLE, null, 1)

				this.petX = this.x - PET_OFFSET
				this.petY = this.y
				this.petModel.x = this.petX - this.x
				this.petModel.y = this.petY - this.y
			})
		}
	}

	isInMyEntitysParty(): boolean {
		const inParty = UI.getInstance().store.getters['party/getCurrentlyPartiedStatus']
		if(inParty) {
			const myEntity = GameClient.getInstance().state?.myEntity
			if (myEntity && myEntity.partyId) {
				return this.partyId === myEntity.partyId
			}
		}
		return false
	}

	isInMyEntitysPartyButIsNotMyEntity(): boolean {
		if (this.isLocalPlayer) {
			return false
		}

		return this.isInMyEntitysParty()
	}

	showWhereAmIHighlight() {
		if(!this.whereAmIHighlight) {
			if(this.isInMyEntitysParty()) {
				const partyMembers = UI.getInstance().store.getters['party/getPartyMembers']
				let isLeader = false
				for(let i=0; i < partyMembers.length; ++i) {
					const member = partyMembers[i]
					if(member.playerName === this.name) {
						isLeader = member.leader
						break
					}
				}

				if(isLeader) {
					this.whereAmIHighlight = Sprite.from('hud-highlight-arrow-party-leader')
				} else {
					this.whereAmIHighlight = Sprite.from('hud-highlight-arrow-party-member')
				}
			} else {
				this.whereAmIHighlight = Sprite.from('hud-highlight-arrow-solo')
			}

			this.whereAmIHighlight.anchor.x = 0.5
			this.whereAmIHighlight.y = WHERE_AM_I_Y_OFFSET
			this.model.addChild(this.whereAmIHighlight)
		}

		this.whereAmIHighlight.visible = true
		this.isShowingWhereAmIHighlight = true
	}

	hideWhereAmIHighlight(destroyHighlight: boolean) {
		if(destroyHighlight) {
			this.model.removeChild(this.whereAmIHighlight)
			this.whereAmIHighlight = null
		} else {
			this.whereAmIHighlight.visible = false
		}

		this.isShowingWhereAmIHighlight = false
	}

	handleWindowResize(newWidth: number, newHeight: number): void {
		this.ambienceTransitionDistance = newWidth / 2
		this.ambienceCleanUpDistance = newWidth + this.ambienceTransitionDistance
	}

	hasVisualBuff(buffId: BuffIdentifier) {
		return this.buff1 === buffId || this.buff2 === buffId || this.buff3 === buffId
	}

	private updateAmbience(): void {
		const renderer = Renderer.getInstance()
		if (renderer.biomeBounds) {
			for (let i = 0; i < this.biomeAmbienceMap.length; i++) {
				const effectData = this.biomeAmbienceMap[i]
				if (effectData.isAddedToScene) {
					if (i !== this.currentBiome && Math.abs(this.x - effectData.effect.x) > this.ambienceCleanUpDistance) {
						this.removeAmbienceFromScene(i, renderer)
					}
				}
			}
		}

		const oldBiome = this.currentBiome
		this.currentBiome = this.getCurrentBiome()
		if (this.currentBiome !== -1) {
			this.setCurrentAmbienceAndAddToScene(this.currentBiome)
			if (this.currentAmbience) {
				this.currentAmbience.x = this.x
			}
		}

		if (this.currentAmbience) {
			this.currentAmbience.y = this.y
			this.currentAmbience.zIndex = this.currentAmbience.y
		}

		if (oldBiome !== this.currentBiome) {
			//kinda weird, but the hud state is what knows what we are searching for
			//(server doesn't care -- just sends position when we ask)
			UI.getInstance().emitAsyncEvent('hud/fetchNewArrowPosition')
			UI.getInstance().emitEvent('hud/updateCurrentBiome', this.currentBiome)
		}
	}

	private drawGenericDebugVisuals() {
		this.debugStringText = new PIXI.Text('', {
			fontFamily: 'Arial',
			fontSize: 16,
			fill: 0xffffff,
			align: 'left',
		})
		this.debugStringText.x = 60
		this.debugStringText.y = -65

		this.debugModel.addChild(this.debugStringText)
	}
}

const throttledEnergyPercentageEventEmitter = throttle((percentageOfMaxEnergy) => {
	UI.getInstance().emitEvent('hud/updatedEnergyPercentage', percentageOfMaxEnergy * 100)
}, 100)

export function updateVisualsAfterMovement(entity: ClientPlayer, isMyEntity) {
	const x = entity.x_nointerp
	const y = entity.y_nointerp

	const vx = entity.visualPos.x
	const vy = entity.visualPos.y

	if (entity.cspMovement && debugConfig.graph.movement) {
		logData('client.player.x', x)
		logData('client.player.clientPos', entity.cspMovement.predictedPos.x)
		logData('client.player.visiblePos', vx)
		logData('client.player.sx', entity.x)
	}

	//TODO2: consider piping movement speed to the client, so we can just dynamically adjust the timescale
	let timescale = 1.0
	if (entity.hasVisualBuff(BuffIdentifier.SkillStoneForm)) {
		timescale *= 0.5
	}
	if (entity.hasVisualBuff(BuffIdentifier.SkillMovementSpeed)) {
		timescale *= 1.5
	}

	if (!entity.weaponOut) {
		const diff = vx - entity.lastFrameX
		if (Math.abs(diff) > 1) {
			//console.log('facing t:', Time.currentGameFrameNumber, { x, vx, diff })
			if (vx < entity.lastFrameX) {
				updateAimFacing(entity.riggedModel, -1, false)
			} else if (vx > entity.lastFrameX) {
				updateAimFacing(entity.riggedModel, 1, false)
			}
		}
	}

	const trackEntry = playAnimation(entity.riggedModel, entity.weaponOut ? AnimationTrack.MOVEMENT : AnimationTrack.MOVEMENT_NO_WEAPON, undefined, timescale)
	trackEntry.trackEnd = trackEntry.trackTime + 0.15

	entity.model.zIndex = vy

	if (isMyEntity) {
		NavigationArrow.getInstance().setXPos(vx)
		NavigationArrow.getInstance().setYPos(vy)
		InteractionPromptManager.getInstance().updatePlayerPosition(vx, vy)
	}

	entity.lastFrameX = vx

	updateChargePfxLocation(entity)
}

export const clientPlayerUpdateHooks = {
	x: (entity: ClientPlayer, newValue: number, isMyEntity: boolean) => {
		if (!debugConfig.csp || !isMyEntity) {
			updateVisualsAfterMovement(entity, isMyEntity)
		}

		if (isMyEntity) {
			if (newValue < STARTING_DOCK_X) {
				entity.atStartingDock = true
			} else {
				if (entity.atStartingDock) {
					// let's go!
					UI.getInstance().emitEvent('emotes/performSpawnEmote')
				}
				entity.atStartingDock = false
			}

			const oldBiome = entity.currentBiome
			entity.currentBiome = entity.getCurrentBiome()
			if (entity.currentBiome !== -1) {
				entity.setCurrentAmbienceAndAddToScene(entity.currentBiome)
				if (entity.currentAmbience) {
					entity.currentAmbience.x = newValue
				}
			}

			if (oldBiome !== entity.currentBiome) {
				//kinda weird, but the hud state is what knows what we are searching for
				//(server doesn't care -- just sends position when we ask)
				UI.getInstance().emitAsyncEvent('hud/fetchNewArrowPosition')
				UI.getInstance().emitEvent('hud/updateCurrentBiome', entity.currentBiome)
			}

		}

	},
	y: (entity: ClientPlayer, newValue: number, isMyEntity: boolean) => {
		if (!debugConfig.csp || !isMyEntity) {
			updateVisualsAfterMovement(entity, isMyEntity)
		}
	},
	aimAngle: (entity: ClientPlayer, newValue: radians, isMyEntity) => {
		if (!debugConfig.csp || !debugConfig.cspConfig.clientSideAim || !isMyEntity) {
			updateAimAngleVisuals(entity, newValue)
		}
	},
	// TODO2: there is no user levels anymore, remove this
	currentLevel(entity: ClientPlayer, newValue, isMyEntity): void {
		if (isMyEntity) {
			UI.getInstance().emitEvent('hud/updatedLevel', newValue)
		}
	},
	currentExperiencePercentage(entity: ClientPlayer, newValue, isMyEntity): void {
		if (isMyEntity) {
			UI.getInstance().emitEvent('hud/updatedCurrentExperiencePercentage', newValue)
		}
	},
	activeWeaponSlot(entity: ClientPlayer, newValue: WeaponSlotKey, isMyEntity): void {
		if (isMyEntity) {
			const percentageOfMaxEnergy = entity.currentEnergy / entity.maxEnergy
			throttledEnergyPercentageEventEmitter(percentageOfMaxEnergy)

			UI.getInstance().emitEvent('hud/updatedActiveWeaponSlot', newValue)
		}
	},
	gearCooldownSlotOne(entity: ClientPlayer, newValue: timeInSeconds, isMyEntity): void {
		if (isMyEntity) {
			//logger.debug('cooldownSlot1', newValue)
			// TODO2: update UI cooldowns using timeLeftSeconds
		}
	},
	gearCooldownSlotTwo(entity: ClientPlayer, newValue: timeInSeconds, isMyEntity): void {
		if (isMyEntity) {
			//logger.debug('cooldownSlot2', newValue)
			// TODO2: update UI cooldowns using timeLeftSeconds
		}
	},
	gearCooldownSlotThree(entity: ClientPlayer, newValue: timeInSeconds, isMyEntity): void {
		if (isMyEntity) {
			//logger.debug('cooldownSlot3', newValue)
			// TODO2: update UI cooldowns using timeLeftSeconds
		}
	},
	furthestWorldTierEver(entity: ClientPlayer, newValue: level, isMyEntity): void {
		if (isMyEntity) {
			updateProgression(entity, entity.progressionLevel, entity.furthestWorldTierEver)
		}
	},
	progressionLevel(entity: ClientPlayer, newValue: level, isMyEntity): void {
		if (isMyEntity) {
			updateProgression(entity, entity.progressionLevel, entity.furthestWorldTierEver)
		}
	},
	inventoryWeight(entity: ClientPlayer, newValue, isMyEntity): void {
		if (isMyEntity) {
			UI.getInstance().emitEvent('hud/updateInventoryWeight', newValue)
			if (newValue >= MAXIMUM_INVENTORY_WEIGHT) {
				tryShowTutorial(FTUESequence.FullInventory) //first time the player's inventory is full
			}
		}
	},
	currentHealth(entity: ClientPlayer, newValue, isMyEntity): void {
		updateEntityHealthVisuals(entity, newValue, entity.maxHealth, true)
		if (isMyEntity) {
			const percentageOfMaxHealth = newValue / entity.maxHealth
			UI.getInstance().emitEvent('hud/updatedHealthPercentage', percentageOfMaxHealth * 100)
			UI.getInstance().emitAsyncEvent('hud/flashHealth')
			UI.getInstance().emitEvent('paperdoll/currentHealthUpdated', newValue)
			if (percentageOfMaxHealth < 0.2) {
				UI.getInstance().emitEvent('inGame/triggerHealthLow')
			} else {
				UI.getInstance().emitEvent('inGame/triggerHealthStable')
			}

			if (this.debugModel) {
				const newWidth = percentageOfMaxHealth * entity.debugHealthbarVisualWidth

				const newHealthbarForeground = new Graphics()
				newHealthbarForeground.beginFill(0x00ff00)
				newHealthbarForeground.drawRect(-entity.debugHealthbarVisualWidth / 2, -20 - 10, newWidth, 8)

				// Remove the old one
				entity.debugModel.removeChild(entity.healthbarForeground)
				delete entity.healthbarForeground

				// Add the new one
				entity.healthbarForeground = newHealthbarForeground
				entity.debugModel.addChild(entity.healthbarForeground)
			}
		}
		if (newValue <= 0) {
			entity.die(false) // false *might* not be accurate, but this passed to handle tutorial stuff which should already be handled from other death call
		}
	},
	maxHealth(entity: ClientPlayer, newValue, isMyEntity): void {
		updateEntityHealthVisuals(entity, newValue, entity.maxHealth, true)

		if (isMyEntity) {

			const percentageOfMaxHealth = newValue / entity.maxHealth
			UI.getInstance().emitEvent('hud/updatedHealthPercentage', percentageOfMaxHealth * 100)
			UI.getInstance().emitEvent('paperdoll/maxHealthUpdated', newValue)
		}
	},
	factionAffiliation(entity: ClientPlayer, newValue: FactionShortName, isMyEntity: boolean): void {
		entity.updateFactionIcon(newValue)
	},
	currentEnergy(entity: ClientPlayer, newValue, isMyEntity): void {
		//console.log('currentEnergy', newValue)
		if (isMyEntity && !debugConfig.csp) {
			const percentageOfMaxEnergy = newValue / entity.maxEnergy
			// console.log({ percentageOfMaxEnergy, newValue, max: entity.maxEnergy })
			throttledEnergyPercentageEventEmitter(percentageOfMaxEnergy)
		}
	},
	maxEnergyOne(entity: ClientPlayer, newValue, isMyEntity): void {
		if(isMyEntity && !debugConfig.csp && entity.activeWeaponSlot === 'one') {
			const percentageOfMaxEnergy = entity.currentEnergy / newValue
			throttledEnergyPercentageEventEmitter(percentageOfMaxEnergy)
		}
	},
	maxEnergyTwo(entity: ClientPlayer, newValue, isMyEntity): void {
		if(isMyEntity && !debugConfig.csp && entity.activeWeaponSlot === 'two') {
			const percentageOfMaxEnergy = entity.currentEnergy / newValue
			throttledEnergyPercentageEventEmitter(percentageOfMaxEnergy)
		}
	},
	name(entity: ClientPlayer, newName: string, isMyEntity) {
		entity.updateNameplateText(newName)
	},
	skin(entity: ClientPlayer, skin: string) {
		entity.setModelSkin(skin)
	},
	petSkin(entity: ClientPlayer, petSkin: number) {
		entity.setPetSkin(petSkin)
	},
	currentWeaponVisuals(entity: ClientPlayer, newValue: string, isMyEntity: boolean): void {
		const [weaponSubType, component1, component2, component3] = newValue.split(',')
		entity.equipWeapon(parseInt(weaponSubType, 10), parseInt(component1, 10), parseInt(component2, 10), parseInt(component3, 10))
	},
	weaponOut(entity: ClientPlayer, newVal: boolean, isMyEntity: boolean) {
		entity.changeWeaponOut(newVal)
		if (isMyEntity) {
			UI.getInstance().emitEvent('hud/updatePlayerWeaponOut', newVal)
		}
	},
	buff1(entity: ClientPlayer, newValue: BuffIdentifier) {
		handleBuffChange(entity, newValue, 0)
	},
	buff2(entity: ClientPlayer, newValue: BuffIdentifier) {
		handleBuffChange(entity, newValue, 1)
	},
	buff3(entity: ClientPlayer, newValue: BuffIdentifier) {
		handleBuffChange(entity, newValue, 2)
	},
	buff4(entity: ClientPlayer, newValue: BuffIdentifier) {
		handleBuffChange(entity, newValue, 3)
	},
	buff5(entity: ClientPlayer, newValue: BuffIdentifier) {
		handleBuffChange(entity, newValue, 4)
	},
	buff6(entity: ClientPlayer, newValue: BuffIdentifier) {
		handleBuffChange(entity, newValue, 5)
	},
	isCharging(entity: ClientPlayer, newValue: boolean, isMyEntity: boolean): void {
		if (newValue) {
			// show PFX
			// start charge-up SFX and ramp volume to 100% over time
			if (!entity.chargePfx) {
				entity.chargePfx = Renderer.getInstance().addEffectToScene('charge', entity.aimPositionX, entity.aimPositionY)
			}
		} else {
			// hide PFX
			// end charge-up SFX
			if (entity.chargePfx) {
				Renderer.getInstance().removeEffectFromScene(entity.chargePfx)
				entity.chargePfx = null
			}
		}
	},
	poiIndex(entity: ClientPlayer, newValue: number, isMyEntity: boolean): void {
		if (isMyEntity) {
			// Spawn emote, if leaving an outpost
			if (entity.poi) {
				const prevType = entity.poi.type
				if (prevType === POIType.Outpost) {
					// let's go!
					UI.getInstance().emitEvent('emotes/performSpawnEmote')
				}
			}

			let visiblePOI = newValue !== -1

			if (newValue !== -1) {
				const poi = allPOIs[newValue]
				if (poi.text) {
					UI.getInstance().emitEvent('hud/poiText', poi.text)
					if (!poi.text) {
						visiblePOI = false
					}
				} else {
					visiblePOI = false
				}
				if (poi.respawnText) {
					UI.getInstance().emitEvent('hud/poiRespawnText', poi.respawnText)
				}
			}

			UI.getInstance().emitEvent('hud/poiActive', visiblePOI)
			entity.poi = getClientPOIFromIndex(newValue)

			if (entity.poi && visiblePOI) {
				UI.getInstance().emitEvent('hud/bossHealth', entity.poi.progress)
				UI.getInstance().emitEvent('hud/poiType', entity.poi.type)
				UI.getInstance().emitEvent('hud/poiEventActive', entity.poi.eventActive)
			} else {
				UI.getInstance().emitEvent('hud/poiType', POIType.WorldEdge)
				UI.getInstance().emitEvent('hud/poiEventActive', false)
			}
		}
	},
	aimPositionX(entity: ClientPlayer, newValue: number, isMyEntity: boolean) {
		updateChargePfxLocation(entity)
	},
	aimPositionY(entity: ClientPlayer, newValue: number, isMyEntity: boolean) {
		updateChargePfxLocation(entity)
	},
	partyId(entity: ClientPlayer, newValue: string, isMyEntity: boolean) {
		if (isMyEntity) {
			// have to update other entities too, as we may now share a party
			const clientEntities = GameClient.getInstance().entities
			clientEntities.forEach((clientEntity) => {
				if (clientEntity instanceof ClientPlayer) {
					clientEntity.updateNameplateText(clientEntity.name)
					clientEntity.hideWhereAmIHighlight(true)
				}
			})
		} else {
			entity.updateNameplateText(entity.name)
		}

		if (entity.isLocalPlayer) {
			if (newValue === '') {
				PartyArrows.getInstance().setIsShowing(false)
			} else {
				PartyArrows.getInstance().setIsShowing(true)
			}
		}
	},
	currentEmote(entity: ClientPlayer, newValue: EmoteType, isMyEntity: boolean) {
		if (newValue === EmoteType.Typing) {
			if (!entity.typingEmote) {
				entity.typingEmote = entity.getEmote()
				entity.typingEmote.setEmote(EmoteType.Typing)
			}
		} else if (newValue === EmoteType.None) {
			if (entity.typingEmote) {
				entity.typingEmote.returnToPool()
				entity.typingEmote = null
			}
			if (entity.emojiEmote) {
				entity.emojiEmote.returnToPool()
				entity.emojiEmote = null
			}
		} else {
			if (entity.emojiEmote) {
				entity.emojiEmote.returnToPool()
			}

			entity.emojiEmote = entity.getEmote()
			entity.emojiEmote.setEmote(newValue)
		}
	},
	primaryWeaponSkin(entity: ClientPlayer, newValue: number, isMyEntity: boolean) {
		const [weaponSubType, component1, component2, component3] = entity.currentWeaponVisuals.split(',')
		entity.equipWeapon(parseInt(weaponSubType, 10), parseInt(component1, 10), parseInt(component2, 10), parseInt(component3, 10))
	},
	secondaryWeaponSkin(entity: ClientPlayer, newValue: number, isMyEntity: boolean) {
		const [weaponSubType, component1, component2, component3] = entity.currentWeaponVisuals.split(',')
		entity.equipWeapon(parseInt(weaponSubType, 10), parseInt(component1, 10), parseInt(component2, 10), parseInt(component3, 10))
	},
	cosmetic_player_back(entity: ClientPlayer, newValue: string, isMyEntity: boolean) {
		entity.setPlayerCosmetic(newValue, SKIN_SLOT.PLAYER_BACK)
	},
	cosmetic_player_face(entity: ClientPlayer, newValue: string, isMyEntity: boolean) {
		entity.setPlayerCosmetic(newValue, SKIN_SLOT.PLAYER_FACE)
	},
	cosmetic_player_footprint(entity: ClientPlayer, newValue: string, isMyEntity: boolean) {
		entity.setPlayerCosmetic(newValue, SKIN_SLOT.PLAYER_FOOTPRINT)
	}
}

function updateAimAngleVisuals(player: ClientPlayer, angle: radians) {
	const skel = player.riggedModel.skeleton

	if (skel) {
		const rotBone: PIXI.spine.core.Bone = skel.findBone('root-aim')
		if (rotBone && player.weaponOut) {
			updateAimFacing(player.riggedModel, player.facingDirection, false)
			updateAimRotation(rotBone, angle, player.facingDirection)
		}
	}
}

function updateChargePfxLocation(entity: ClientPlayer) {
	if (entity.chargePfx) {
		entity.chargePfx.zIndex = entity.model.zIndex + 1 //draw in front of player

		// aimPosition[X/Y] gets sent based off of server position
		const visualServerPosDiff = sub(entity.visualPos, entity)
		entity.chargePfx.x = entity.aimPositionX + visualServerPosDiff.x
		entity.chargePfx.y = entity.aimPositionY + visualServerPosDiff.y

		if (debugConfig.csp) {
			const pos = entity.cspProjectiles?.getWeaponReleasePos()
			if (pos) {
				entity.chargePfx.x = pos.x
				entity.chargePfx.y = pos.y
			}
		}
	}
}

function updateProgression(entity: ClientPlayer, progressionLevel: level, furthestWorldTierEver: WorldTier) {
	const biomeCount = getBiomeCount(GameModeType.Adventure)
	// tier 1 = progression levels 0-4
	// tier 2 = progression levels 5-9
	// tier 3 = progression levels 10-14
	// etc.
	let highestAllowedTier = furthestWorldTierEver// getWorldTierFromProgressionLevel(newValue)
	const highestCompletedTier = furthestWorldTierEver - 1//getWorldTierFromProgressionLevel(newValue) - 1

	highestAllowedTier = Math.min(highestAllowedTier, PROGRESSION_MAX_TIER_FOR_BETA)

	const furthestBiome = progressionLevel - (furthestWorldTierEver - 1) * biomeCount
	const activeWorldTier = entity.activeWorldTier

	const currentMaxBiome = getMaxBiome(progressionLevel, activeWorldTier)

	const uiInstance = UI.getInstance()
	uiInstance.emitEvent('boatLaunch/updateMaxAllowedBiomeIndex', furthestBiome)
	uiInstance.emitEvent('boatLaunch/updateHighestCompletedTier', highestCompletedTier)
	uiInstance.emitEvent('boatLaunch/updateMaxAllowedTier', highestAllowedTier)

	uiInstance.emitEvent('hud/updateProgression', { furthestBiome, furthestWorldTier: highestAllowedTier, currentMaxBiome, progressionLevel: progressionLevel })
	uiInstance.emitEvent('hud/updateActiveWorldTier', activeWorldTier)

	if (furthestBiome > 0) {
		uiInstance.emitAsyncEvent('ftueManager/completeFlagsFrom', 'defeatedAnyBoss')
	}
}

function updateDebugString(entity: ClientPlayer) {
	if (entity.model == null) {
		return
	}
	entity.debugStringText.text = getAnimTrackString(entity.riggedModel)
}
