import { BuffIdentifier } from '../../../buffs/shared/buff.shared'
import { WorldDifficulty } from '../../../engine/shared/game-data/world-difficulty'
import { throwIfNotFinite } from '../../../utils/math'
import { ActionCriteria, ActionCriterion, CriteriaSatisfactionQuantity, CriteriaTarget, CriteriaType } from '../action-types'

export namespace ActionCriteriaHelpers {
	export function generatePlayerIsInMeleeRangeCriteria(range: number): ActionCriteria {
		throwIfNotFinite(range)
		return {
			satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
			criterias: [
				{
					criteriaTargets: CriteriaTarget.PLAYERS,
					criteriaType: CriteriaType.WITHIN_RANGE,
					criteriaValue: range,
					minimumTargetsThatSatisfy: 1,
				},
			],
		}
	}

	export function generateDoesNotHaveBuffCriteria(target: CriteriaTarget, buffId: BuffIdentifier): ActionCriteria {
		return {
			satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
			criterias: [
				{
					criteriaTargets: target,
					criteriaType: CriteriaType.NO_PRESENCE_OF_BUFF_OR_DEBUFF,
					criteriaValue: buffId,
					minimumTargetsThatSatisfy: 1,
				},
			],
		}
	}
}

export const worldDifficultyNormalCriterion: ActionCriterion = {
	criteriaTargets: CriteriaTarget.SELF,
	criteriaType: CriteriaType.WORLD_DIFFICULTY_AT_OR_ABOVE_THRESHOLD,
	criteriaValue: WorldDifficulty.Normal,
	minimumTargetsThatSatisfy: 1,
}

export const worldDifficultyHardCriterion: ActionCriterion = {
	criteriaTargets: CriteriaTarget.SELF,
	criteriaType: CriteriaType.WORLD_DIFFICULTY_AT_OR_ABOVE_THRESHOLD,
	criteriaValue: WorldDifficulty.Hard,
	minimumTargetsThatSatisfy: 1,
}

export const worldDifficultyBrutalCriterion: ActionCriterion = {
	criteriaTargets: CriteriaTarget.SELF,
	criteriaType: CriteriaType.WORLD_DIFFICULTY_AT_OR_ABOVE_THRESHOLD,
	criteriaValue: WorldDifficulty.Brutal,
	minimumTargetsThatSatisfy: 1,
}

export const worldDifficultyHardCriteria: ActionCriteria = {
	satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
	criterias: [worldDifficultyHardCriterion],
}

export const worldDifficultyBrutalCriteria: ActionCriteria = {
	satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
	criterias: [worldDifficultyBrutalCriterion],
}
