import nengi from 'nengi'
import { uuid } from '../../utils/primitive-types'
import { ContainerType } from './send-item-container-message'

class SubmitItemListingCommand {
	static protocol = {
		itemId: nengi.String,
		container: nengi.String,
		price: nengi.Number,
		listingDurationInHours: nengi.Number,
		stackAmount: nengi.Number,
		useFreeListing: nengi.Boolean
	}
	
	itemId: uuid
	container: ContainerType
	price: number
	listingDurationInHours: number
	stackAmount: number
	useFreeListing: boolean

	/**
	 * Creates an instance of SubmitItemListingCommand. This will scan the Stash to find a matching item.
	 * @param {uuid} itemId Item ID
	 * @param {price} price number
	 * @param {listingDurationInHours} listingDurationInHours number
	 * @memberof SubmitItemListingCommand
	 */
	constructor(itemId: uuid, price: number, listingDurationInHours: number, container: ContainerType, stackAmount?: number, useFreeListing?: boolean) {
		this.itemId = itemId
		this.price = price
		this.listingDurationInHours = listingDurationInHours
		this.container = container
		this.stackAmount = stackAmount ?? 0
		this.useFreeListing = useFreeListing ?? false
	}
}

export default SubmitItemListingCommand
