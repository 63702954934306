import { verifyEmailRequest } from '../../utils/api/atlas-requests.client'
import logger from '../../utils/client-logger'

export const emailVerificationModule = () => {
	logger.debug('Initializing email verification module')
	return {
		namespaced: true,
		state: {
			verificationInProgress: true,
			verificationSuccessful: false,
			verificationFailed: false,
		},
		getters: {
			verificationInProgress(state) {
				return state.verificationInProgress
			},
			verificationFailed(state) {
				return state.verificationFailed
			},
			verificationSuccessful(state) {
				return state.verificationSuccessful
			},
		},
		mutations: {},
		actions: {
			async verifyEmail({ commit, state, rootState }) {
				try {
					const urlParams = new URLSearchParams(window.location.search)
					const emailAddress = urlParams.get('emailAddress')
					const emailVerificationToken = urlParams.get('emailVerificationToken')
					const results = await verifyEmailRequest({
						emailVerificationToken,
						emailAddress,
					})
					const { emailVerified } = results
					if (emailVerified) {
						state.verificationInProgress = false
						state.verificationSuccessful = true
						state.verificationFailed = false
					} else {
						state.verificationInProgress = false
						state.verificationSuccessful = false
						state.verificationFailed = true
					}
				} catch (e) {
					state.verificationInProgress = false
					state.verificationSuccessful = false
					state.verificationFailed = true
				}
			},
		},
	}
}
