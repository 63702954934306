
































































import { mapGetters, mapMutations } from 'vuex'
import { ItemSubType } from '../../../loot/shared/item-type'
import WeaponSubType, { WeaponSubTypePrettyName } from '../../../loot/shared/weapon-sub-type'
import GearSubType, { GearSubTypePrettyName } from '../../../loot/shared/gear-sub-type'
import WeaponAugmentSubType from '../../../loot/shared/weapon-augment-sub-type'
import { debounce } from 'lodash'
import Vue from 'vue'
import { getBackdropClassNameFromItem, getRarityClassNameFromItem, getIconClassNameFromItem, ImARealitem } from './inventory-item-helpers'
import { getPetStyleForSpriteSheet } from '../../state/cosmetics-ui-state'
import { SKIN_SLOT } from '../../../player/shared/set-skin.shared'

export default {
	name: 'InventoryItem',
	components: {},
	props: {
		item: {
			type: Object,
			required: true,
			validator(item: ImARealitem) {
				return item.id !== undefined
			},
		},
		showSlot: {
			type: Boolean,
			required: false,
			default: true,
		},
		showBackdrop: {
			type: Boolean,
			required: false,
			default: true,
		},
		shrinkForWeaponCard: {
			type: Boolean,
			required: false,
			default: false,
		},
		selected: {
			type: Boolean,
			required: false,
			default: false,
		},
		selectable: {
			type: Boolean,
			required: false,
			default: true,
		},
		tooltipable: {
			type: Boolean,
			required: false,
			default: true,
		},
		onClickFn: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default on-click handler for the <InventoryItem /> component has been called. Did you forget to pass an :on-click handler to the component?')
			},
		},
		showSmeltingProgressBar: {
			type: Boolean,
			required: false,
			default: false,
		},
		furnaceReturnCurrency: {
			type: String,
			required: false,
			default: '0',
		},
		furnaceBonusReturnCurrency: {
			type: Number,
			required: false,
			default: 0,
		},
		inverseSmeltProgress: {
			type: Number,
			required: false,
			default: 100,
		},
		showCostOverlay: {
			type: Boolean,
			required: false,
			default: false,
		},
		costOverlayAmount: {
			type: Number,
			required: false,
			default: 0,
		},
		milestoneDay: {
			type: Boolean,
			required: false,
			default: false,
		},
		dailyRewardsCollecting: {
			type: Boolean,
			required: false,
			default: false,
		},
		dailyRewardCollected: {
			type: Boolean,
			required: false,
			default: false,
		},
		expiryTimeDiff: {
			type: Number,
			required: false,
			default: 0,
		},
	},
	data() {
		return {
			SKIN_SLOT: SKIN_SLOT,
		}
	},
	computed: {
		...mapGetters('itemLocks', ['itemIsLocked']),
		...mapGetters('UIScale', ['currentScale']),
		...mapGetters('inGame', ['activePanel']),
		...mapGetters('itemContainers', ['standardWormHornCount', 'premiumWormHornCount']),

		slotVariantClass() {
			if (this.milestoneDay) {
				return `slot-variant-milestone-${Math.floor(Math.random() * 3) + 1}`
			} else {
				return `slot-variant-${Math.floor(Math.random() * 3) + 1}`
			}
		},
		backdropClass() {
			return getBackdropClassNameFromItem(this.item)
		},
		rarityClass() {
			return getRarityClassNameFromItem(this.item)
		},
		itemIconClass() {
			return getIconClassNameFromItem(this.item)
		},
		progressBarStyle() {
			return 'height: 100%;'
			//return 'height: ' + this.inverseSmeltProgress + '%;'
		},
		petSkinStyle() {
			if (this.item.isSkin) {
				return getPetStyleForSpriteSheet(this.item.name, 104, 109)
			}
			return {}
		},
		expiryTimeString() {
			const hours = Math.floor(this.expiryTimeDiff / (1000 * 60 * 60))

			if (hours > 0) {
				return hours + 'h'
			}

			const minutes = Math.floor(this.expiryTimeDiff / (1000 * 60))
			return minutes + 'm'
		},
		expiryTimeStyle() {
			const hours = this.expiryTimeDiff / (1000 * 60 * 60)

			if (hours >= 2) {
				return 'item-top-left-standard-text'
			}

			return 'item-top-left-critical-text'
		},
	},
	destroyed() {
		this.showTooltip(null)
	},
	methods: {
		...mapMutations('UIScale', ['showTooltip', 'showTooltipDebug', 'updateToolTipPosition', 'currentComponentHovered']),
		onClick(itemId: string) {
			if (this.selectable) {
				this.onClickFn(itemId)
			}
		},
		onMouseEnter(event, component) {
			if (this.tooltipable === false) return
			this.updateToolTipPosition(event)
			this.currentComponentHovered(component)
			this.showTooltip(this.item)
		},
		onMouseEnterCtrl(event) {
			if (this.tooltipable === false) return
			this.updateToolTipPosition(event)
			this.showTooltipDebug(this.item)
		},
		onMouseLeave() {
			if (this.tooltipable === false) return
			this.currentComponentHovered(null)
			this.showTooltip(null)
		},
		onMouseMove(event) {
			if (this.tooltipable === false) return
			this.moveTooltip(event)
		},
	},
}
