import nengi from 'nengi'

class UseWormHornCommand {
	static protocol = {
		isStandardHorn: nengi.Boolean,
		itemIds: { type: nengi.String, indexType: nengi.UInt16 },
	}

	isStandardHorn: boolean
	itemIds: string[]

	constructor(isStandardHorn: boolean, itemIds: string[]) {
		this.isStandardHorn = isStandardHorn
		this.itemIds = itemIds
	}
}

export default UseWormHornCommand
