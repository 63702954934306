import { EmoteIcons, EmoteType } from '../../chat/shared/emote-enums'
import ClientPlayerInput from '../../input/client/client-player-input'
import logger from '../../utils/client-logger'
import emoteIcons from '../../../assets/sprites/misc/emotes/emote-icon-atlas.json'
import { NengiClient } from '../../engine/client/nengi-client'
import PlayerEmoteCommand from '../../input/shared/player-emote-command'
import { UI } from '../ui'
import { setEquippedEmotesRequest } from '../../utils/api/atlas-requests.client'

const USE_MOUSE_POSITION_FOR_WHEEL = false

interface EmotesState {
    wheelActive: boolean,
    wheelXPos: number,
    wheelYPos: number,
    selectedWheelEmote: number,
    selectedCustomizationEmote: number,
    equipedEmotes: number[],
    ownedEmotes: number[],
}

export const emotesModule = () => {
	logger.debug('Initializing Emotes store module...')
	return {
		namespaced: true,
		state: {
			wheelActive: false,
            wheelXPos: 0,
            wheelYPos: 0,
            selectedWheelEmote: -1,
            selectedCustomizationEmote: -1,
            equipedEmotes: [
                // Wheel
                EmoteType.Angry,
                EmoteType.Cry,
                EmoteType.Happy,
                EmoteType.Love,
                EmoteType.Scared,
                EmoteType.Surprised,
                // Spawn Emote
                EmoteType.Excited,
                // Victory Emote
                EmoteType.Thumbsup
            ], 
            ownedEmotes: [
                EmoteType.Angry,
                EmoteType.Cry,
                EmoteType.Excited,
                EmoteType.Happy,
                EmoteType.Love,
                EmoteType.Neutral,
                EmoteType.Sad,
                EmoteType.Scared,
                EmoteType.Surprised,
                EmoteType.Thumbsup
            ],
        },
		getters: {
			wheelActive(state: EmotesState) {
				return state.wheelActive
			},
			wheelXPos(state: EmotesState) {
				return state.wheelXPos
			},
			wheelYPos(state: EmotesState) {
				return state.wheelYPos
			},
			equipedEmotes(state: EmotesState) {
				return state.equipedEmotes
			},
			ownedEmotes(state: EmotesState) {
				return state.ownedEmotes
			},
			selectedCustomizationEmote(state: EmotesState) {
				return state.selectedCustomizationEmote
			},
		},
		mutations: {
			openWheel(state: EmotesState) {
				state.wheelActive = true

                if (USE_MOUSE_POSITION_FOR_WHEEL) {
                    const input = ClientPlayerInput.getInstance()
                    state.wheelXPos = input.currentState.mouseX
                    state.wheelYPos = input.currentState.mouseY
                } else {
                    state.wheelXPos = window.innerWidth / 2
                    state.wheelYPos = window.innerHeight / 2
                }

                state.selectedWheelEmote = -1
			},
			closeWheel(state: EmotesState) {
                if (state.wheelActive) {
                    state.wheelActive = false

                    if (state.selectedWheelEmote >= 0 && state.selectedWheelEmote < 6) {
                        NengiClient.getInstance().sendCommand(new PlayerEmoteCommand(state.equipedEmotes[state.selectedWheelEmote]))
                    }
                }
			},
            setSelectedWheelEmote(state: EmotesState, selectedWheelEmote) {
                state.selectedWheelEmote = selectedWheelEmote
            },
            setSelectedCustomizationEmote(state: EmotesState, selectedCustomizationEmote) {
                state.selectedCustomizationEmote = selectedCustomizationEmote
            },
            setEquippedEmote(state: EmotesState, newEmote) {
                const slot = state.selectedCustomizationEmote
                if (slot >= 0 && slot < 8) {
                    if (state.ownedEmotes.find((value) => {return value === newEmote})) {
                        state.equipedEmotes[slot] = newEmote
                    }
                }

                // vue nonsense
                const temp = state.equipedEmotes
                state.equipedEmotes = []
                state.equipedEmotes = temp

                
            },
            setEquippedEmotes(state: EmotesState, equippedEmotes: number[]) {
                if (equippedEmotes.length === 8) {
                    state.equipedEmotes = []
                    state.equipedEmotes = equippedEmotes
                } else {
                    console.error(`Attempting to set equipped emotes with improper data!`)
                }
            },
			performSpawnEmote(state: EmotesState) {
                NengiClient.getInstance().sendCommand(new PlayerEmoteCommand(state.equipedEmotes[6]))
			},
			performVictoryEmote(state: EmotesState) {
                NengiClient.getInstance().sendCommand(new PlayerEmoteCommand(state.equipedEmotes[7]))
			},
            addOwnedEmote(state: EmotesState, newEmote) {
                let isNew = true
                if (state.ownedEmotes.find((value) => {return value === newEmote})) {
                    isNew = false
                }

                if (isNew) {
                    state.ownedEmotes.push(newEmote)

                    // vue nonsense
                    const temp = state.ownedEmotes
                    state.ownedEmotes = []
                    state.ownedEmotes = temp
                }
            }
		},
		actions: {
            openCustomizationPanel({ state }) {
                state.selectedWheelEmote = -1
                state.wheelActive = false

                state.selectedCustomizationEmote = -1

                UI.getInstance().emitEvent('inGame/setActivePanel', 'emoteCustomization')
            },
            async updateEquippedEmote({ state, rootState }) {
				try {
					await setEquippedEmotesRequest(state.equipedEmotes, rootState.user.authentication.token)
				} catch (e) {
					console.error('Unable to update player emotes on atlas ' + e)
				}
            }
		}
	}
}

export function getEmoteIconStyle(emoteIdentifier: EmoteType, intendedWidth: number, intendedHeight: number) {
	const assetName = EmoteIcons.get(emoteIdentifier)
    if (assetName) {
        const spriteData = emoteIcons.frames[assetName]
        if (spriteData) {
            const frame = spriteData.frame

            const rotated = spriteData.rotated
            const originX = spriteData.pivot.x * 100
            const originY = spriteData.pivot.y * 100

            const width = rotated ? frame.h : frame.w
            const height = rotated ? frame.w : frame.h
            const xOffset = frame.x
            const yOffset = frame.y

            const finalScale = Math.min(intendedWidth / frame.w, intendedHeight / frame.h, 1)

            return {
                'background-position': `-${xOffset}px -${yOffset}px`,
                left: `-${width/2}px`,
                top: `-${height/2}px`,
                width: `${width}px`,
                height: `${height}px`,
                'transform-origin': `${originX}% ${originY}%`,
                transform: `scale(${finalScale}) rotate(${rotated ? -90 : 0}deg)`,
            }
        }
    }

    console.error('Could not find spritesheet data for emote: ' + emoteIdentifier)
    return {}
}
