import nengi from 'nengi'

/**
 * A message to run arbitrary tests based on `testName`.
 * Tests:
 *  spawn-all-pfx
 */
export class TestMessage {
	static protocol = {
		testName: { type: nengi.String },
	}
	testName: string

	constructor(testName: string) {
		this.testName = testName
	}
}
