import { maxBy } from 'lodash'
import { Container, Graphics } from 'pixi.js'
import { Vector } from 'sat'
import { BoxColliderConfig, CircleColliderConfig, EllipseColliderConfig, ColliderTraits, PolygonColliderConfig, AnyColliderConfig, ColliderType } from '../../collision/shared/colliders'
import Renderer from '../../engine/client/graphics/renderer'
import { Colors } from '../../utils/colors'
import { add, distanceVV, inEllipse, radToDeg, sub, withinDistanceVV, VectorXY, getVertices, distanceSquaredVV } from '../../utils/math'
import DevToolsManager from '../../ui/dev-tools/dev-tools-manager'
import { UI } from '../../ui/ui'
import { clientDebug, debugKeyDown } from './debug-client'
import { InstancedSprite } from '../../world/client/instanced-sprite'

const AXIS_LINE_WIDTH = 4
const AXIS_LINE_LENGTH = 30
const MOUSE_TOUCH_DIST = 15
const HANDLE_SIZE = 15
const SELECT_MATT = 10

const tweakerSettings = {
	zOffsetNames: false,
}

export class ColliderTweaker {
	static graphics: Graphics
	stage: Container
	objects: ShapeInfo[] = []
	mouseScreenPos: Vector = new Vector(0, 0)
	mouseDown: boolean
	mouseDownThisFrame: boolean
	mouseDownOffsetRotated: Vector
	mouseDownOffset: Vector
	activeObject: ShapeInfo = null
	selectedObject: ShapeInfo = null
	moveMode = false
	mouseDownEvent: MouseEvent
	mouseMoveEvent: MouseEvent

	animation: VectorXY[] = []

	constructor(stage) {
		this.stage = stage

		ColliderTweaker.graphics = new Graphics()
		this.stage.addChild(ColliderTweaker.graphics)

		document.addEventListener('wheel', (event: WheelEvent) => {
			const selectedObject = this.selectedObject?.collider
			if (debugKeyDown('AltLeft') || debugKeyDown('AltRight')) {
				if (selectedObject && selectedObject.type === ColliderType.Box) {
					const change = event.deltaY * -0.001
					if (!selectedObject.angle) {
						selectedObject.angle = 0
					}
					selectedObject.angle += change
				}
			}
		})
		document.addEventListener('keydown', (event) => {
			console.log(event.code)
			if (event.code === 'KeyG') {
				this.duplicateSelected()
			}
			if (event.code === 'KeyB') {
				this.addNewShapeToSelected(ColliderType.Box)
			}
			if (event.code === 'KeyC') {
				this.addNewShapeToSelected(ColliderType.Circle)
			}
			if (event.code === 'KeyE') {
				this.addNewShapeToSelected(ColliderType.Ellipse)
			}
			if (event.code === 'Delete') {
				this.deleteSelected()
			}
		})
		document.addEventListener('mousemove', (event) => {
			this.mouseScreenPos.x = event.clientX
			this.mouseScreenPos.y = event.clientY

			if (this.mouseMoveEvent) {
				const x = event.clientX - this.mouseMoveEvent.x
				const y = event.clientY - this.mouseMoveEvent.y
				const diff = { x, y }
				this.animation.push(diff)
			}

			if (event.altKey) {
				if (this.activeObject) {
					const collider = this.activeObject.collider
					if (collider.angle !== undefined) {
						const diff = event.clientY - this.mouseMoveEvent.clientY
						collider.angle += diff * 0.01
					}
				}
			}

			this.mouseMoveEvent = event
		})
		document.addEventListener('mousedown', (event) => {
			this.mouseDown = true
			this.mouseDownThisFrame = true
			this.mouseDownEvent = event
			this.animation = []
		})
		document.addEventListener('mouseup', (event) => {
			console.log(JSON.stringify(this.animation))
			this.animation = []
			this.mouseDown = false

			if (this.activeObject) {
				const devTools = DevToolsManager.getInstance()

				//const line = '------------------------------'
				//const exportStringWithName = `${line}\n${this.activeObject.name}\n${line}\n${exportString}\n${line}\n`

				const debugStruct = {
					settings: tweakerSettings,
					name: this.activeObject.name,
					collider: this.activeObject.collider,
					['copy to clipboard']: () => {
						const s = this.selectedObject.parent.map(colliderToString).join(',\n')
						const exportString = `colliders:[\n${s}\n],`
						console.log(exportString)
						copyToClipboard(exportString, this.stage)
					},
					//['copy to clipboard (with name)']: () => copyToClipboard(exportStringWithName, this.stage), // WIP
				}

				if (!devTools.getDebugObject() || devTools.getDebugObject().collider !== debugStruct.collider) {
					devTools.setDebugObject(debugStruct)
				}
				if (!UI.getInstance().showDevTools()) {
					UI.getInstance().toggleDevTools()
				}

				this.activeObject = null
			}
		})
	}

	addObjectChildren(name: string, position: Vector, colliders: AnyColliderConfig[]) {
		colliders.forEach((collider) => {
			const newInfo = this.addObject(name, position, collider)
			newInfo.parent = colliders
		})
	}

	addObject(name: string, position: Vector, collider: AnyColliderConfig) {
		const objInfo = this.incompleteShapeInfoFactory(collider)
		objInfo.position = position
		objInfo.collider = collider
		objInfo.name = name

		this.stage.addChild((objInfo.graphics = new Graphics()))

		this.objects.push(objInfo)

		return objInfo
	}

	addProperty(name: string, propertyOwner: InstancedSprite, propName: string) {
		const obj = this.objects.find((o) => o.name === name)
		obj.zOffsets.push(propertyOwner[propName] as number)
		obj.propertyOwners.push(propertyOwner)
	}

	update() {
		ColliderTweaker.graphics.clear()

		if (this.mouseDownThisFrame) {
			this.pickTarget()
		}

		this.objects.forEach((c) => {
			this.updateObj(c)
		})

		this.mouseDownThisFrame = false
	}

	// this creates the correctly ShapeInfo type, but doesn't fill in any members
	private incompleteShapeInfoFactory(collider: AnyColliderConfig) {
		switch (collider.type) {
			case ColliderType.Circle:
				return new CircleShapeInfo()
			case ColliderType.Box:
				return new BoxShapeInfo()
			case ColliderType.Ellipse:
				return new EllipseShapeInfo()
			case ColliderType.Polygon:
				return new PolygonShapeInfo()
			default:
				break
		}
	}

	private updateObj(objInfo: ShapeInfo) {
		objInfo.graphics.clear()

		const rotatedWorldPos = this.getRotatedWorldMousePos(objInfo)

		objInfo.graphics.lineStyle(2, Colors.white)
		objInfo.graphics.angle = radToDeg(objInfo.collider.angle ?? 0)
		objInfo.graphics.x = objInfo.position.x + objInfo.collider.position[0]
		objInfo.graphics.y = objInfo.position.y + objInfo.collider.position[1]

		objInfo.draw()
		if (objInfo === this.selectedObject) {
			objInfo.drawSelected()
		}

		const nearObject = objInfo.inside(MOUSE_TOUCH_DIST, rotatedWorldPos)
		const insideObject = objInfo.inside(-MOUSE_TOUCH_DIST, rotatedWorldPos)
		const isActiveObject = this.activeObject === objInfo

		if ((nearObject && !insideObject && !this.activeObject) || (isActiveObject && !this.moveMode)) {
			objInfo.getHandles().forEach((handle) => this.drawHandle(objInfo.graphics, handle.x, handle.y))
		} else if ((nearObject && insideObject && !this.activeObject) || (isActiveObject && this.moveMode)) {
			const localCenter = objInfo.localCenter
			this.drawAxis(objInfo.graphics, 0, 0)
		}

		for (let i = 0; i < objInfo.propertyOwners.length; i++) {
			const propOwner = objInfo.propertyOwners[i]
			if (propOwner) {
				const x = propOwner.x
				const y = propOwner.bottom + objInfo.zOffsets[i]
				//clientDebug.drawLine()
				//Renderer.getInstance().drawCircle({ x, y, radius: 10, color: Colors.purple, permanent: false, destroyAfterSeconds: 0.1 })
				ColliderTweaker.graphics.lineStyle(2, Colors.blue)
				ColliderTweaker.graphics.moveTo(x - 100, y)
				ColliderTweaker.graphics.lineTo(x + 100, y)

				if (tweakerSettings.zOffsetNames) {
					clientDebug.drawTextDirect(`${propOwner.debugName} - zOffset: ${objInfo.zOffsets[i]}`, { x, y }, 0xffffff, false, 0.1, this.stage)
					//clientDebug.drawTextDirect(`${propOwner.debugName} - zIndex: ${propOwner.zIndex.toFixed(0)}`, { x, y }, 0xffffff, false, 0.1, this.stage)
				}
				// if (Math.abs(y - mouseWorldPos.y) < 10 && mouseWorldPos.x > x - 100 && mouseWorldPos.x < x + 100) {
				// 	this.drawHandle(ColliderTweaker.graphics, x - 100, y)
				// 	this.drawHandle(ColliderTweaker.graphics, x + 100, y)
				// }
			}
		}

		if (this.activeObject === objInfo && this.moveMode && !this.mouseMoveEvent?.altKey) {
			const rwp = this.getMouseWorldCoords()
			const mdo = this.mouseDownOffset
			const pos = sub(rwp, mdo)
			objInfo.collider.position[0] = pos.x - objInfo.position.x
			objInfo.collider.position[1] = pos.y - objInfo.position.y
		}

		if (this.activeObject === objInfo && !this.moveMode) {
			objInfo.scale(rotatedWorldPos)
		}
	}

	private duplicateSelected() {
		if (this.selectedObject) {
			this.selectedObject = this.cloneShapeInfo(this.selectedObject)
			//this.activeObject = this.selectedObject
		}
	}

	private addNewShapeToSelected(type: ColliderType) {
		if (this.selectedObject) {
			this.addNewShape(type, this.selectedObject.name, this.selectedObject.position, this.selectedObject.parent)
		}
	}

	private addNewShape(type: ColliderType, name: string, position: Vector, parent: AnyColliderConfig[]) {
		const collider = this.getDefaultColliderConfig(type)
		const newObj = this.addObject(name, position.clone(), collider)
		newObj.parent = parent
		parent.push(newObj.collider)
	}

	private getDefaultColliderConfig(type: ColliderType): AnyColliderConfig {
		switch (type) {
			case ColliderType.Circle:
				return { type: ColliderType.Circle, position: [0, 0], radius: 100, traits: ColliderTraits.BlockAll }
			case ColliderType.Ellipse:
				return { type: ColliderType.Ellipse, position: [0, 0], rX: 100, rY: 50, traits: ColliderTraits.BlockAll }
			case ColliderType.Box:
				return { type: ColliderType.Box, position: [0, 0], width: 100, height: 100, angle: 0, traits: ColliderTraits.BlockAll }
			// case ColliderType.Polygon: break // NOT SUPPORTED
			// case ColliderType.Capsule: break
			// case ColliderType.Lookup: break
			// default: break
		}

		return { type: ColliderType.Box, position: [0, 0], width: 100, height: 100, traits: ColliderTraits.BlockAll }
	}

	private deleteSelected() {
		if (this.selectedObject) {
			if (this.selectedObject.parent.length >= 2) {
				// don't delete last object
				this.removeShapeInfo(this.selectedObject)
			}
		}
	}

	private cloneShapeInfo(info: ShapeInfo) {
		const collider = Object.assign({}, info.collider)
		collider.position = [collider.position[0] + 50, collider.position[1] + 50]
		const newObj = this.addObject(info.name, info.position.clone(), collider)
		newObj.parent = info.parent
		newObj.parent.push(newObj.collider)
		return newObj
	}

	private removeShapeInfo(info: ShapeInfo) {
		info.parent.remove(info.collider)
		this.stage.removeChild(info.graphics)
		this.objects.remove(info)
	}

	private pickTarget() {
		const pickedObjInfo = maxBy(this.objects, (objInfo) => {
			const rotatedMousePos = this.getRotatedWorldMousePos(objInfo)
			const nearObject = objInfo.inside(MOUSE_TOUCH_DIST, rotatedMousePos)
			if (nearObject) {
				const distWeight = 1 / (1 + distanceSquaredVV(rotatedMousePos, objInfo.objPos))
				return distWeight
			}

			return 0
		})

		if (pickedObjInfo) {
			const rotatedMousePos = this.getRotatedWorldMousePos(pickedObjInfo)
			const nearObject = pickedObjInfo.inside(MOUSE_TOUCH_DIST, rotatedMousePos)
			if (nearObject) {
				const insideObject = pickedObjInfo.inside(-MOUSE_TOUCH_DIST, rotatedMousePos)
				this.activeObject = pickedObjInfo
				this.selectedObject = pickedObjInfo
				this.moveMode = insideObject
				this.mouseDownOffset = this.getMouseWorldCoords()
					.clone()
					.sub(pickedObjInfo.objPos)
				this.mouseDownOffsetRotated = new Vector(rotatedMousePos.x, rotatedMousePos.y).sub(pickedObjInfo.objPos)

				//const devTools = DevToolsManager.getInstance()
				//const debugStruct = { name: pickedObjInfo.name, collider: pickedObjInfo.collider }
				//inspectorObject.name = pickedObjInfo.name
				//inspectorObject.collider = pickedObjInfo.collider
				//devTools.setDebugObject(debugStruct)
				// if (!UI.getInstance().showDevTools()) {
				// 	UI.getInstance().toggleDevTools()
				// }
			}
		}
	}

	private getRotatedWorldMousePos(objInfo: ShapeInfo) {
		let rotatedWorldPos = this.getMouseWorldCoords()
		rotatedWorldPos = sub(rotatedWorldPos, objInfo.objPos)
		if (objInfo.collider.angle) {
			rotatedWorldPos.rotate(-objInfo.collider.angle)
		}
		rotatedWorldPos.add(objInfo.objPos)
		return rotatedWorldPos
	}

	private getMouseWorldCoords() {
		const renderer = Renderer.getInstance()
		const coords = renderer.mouseCoordinatesToWorldCoordinates(this.mouseScreenPos.x, this.mouseScreenPos.y)
		return new Vector(coords.x, coords.y)
	}

	private drawCircle(graphics: Graphics, x: number, y: number, r: number) {
		graphics.lineStyle(1, Colors.red).drawCircle(x, y, r)
	}

	private drawHandle(graphics: Graphics, x: number, y: number) {
		const s = HANDLE_SIZE
		graphics.drawRect(x - s * 0.5, y - s * 0.5, s, s)
	}

	private drawAxis(graphics: Graphics, x: number, y: number) {
		graphics
			.lineStyle(AXIS_LINE_WIDTH, Colors.red)
			.moveTo(x, y)
			.lineTo(x + AXIS_LINE_LENGTH, y)

		graphics
			.lineStyle(AXIS_LINE_WIDTH, Colors.green)
			.moveTo(x, y)
			.lineTo(x, y + AXIS_LINE_LENGTH)
	}
}

class ShapeInfo {
	name: string
	position: Vector
	parent: AnyColliderConfig[]
	graphics: Graphics
	collider: AnyColliderConfig
	zOffsets: number[] = []
	propertyOwners: InstancedSprite[] = []

	get objPos() {
		return add(this.position, new Vector(this.collider.position[0], this.collider.position[1]))
	}

	get localCenter() {
		return new Vector(0, 0)
	}

	draw() { }
	drawSelected() { }

	inside(matt: number, p: VectorXY): boolean {
		return false
	}

	getHandles(): Vector[] {
		return null
	}

	scale(mouseWorldPos: VectorXY) { }
}

// print out the collider as js source data (not json)
//  { type: ColliderType.Box, position: [100, 0], width: 40, height: 80, traits: ColliderTraits.BlockAll }
function colliderToString(collider) {
	let s = '{'
	for (const property in collider) {
		if (Object.hasOwnProperty.call(collider, property)) {
			let value = collider[property]

			let pre = ''
			let post = ''
			if (property === 'type') {
				value = `ColliderType.${ColliderType[value]}`
			} else if (property === 'traits') {
				value = `ColliderTraits.${ColliderTraits[value]}`
			} else if (typeof value === 'string') {
				pre += '\''
				post += '\''
			} else if (Array.isArray(value)) {
				pre += '['
				post += ']'

				value = value.map((n) => n.toFixed(0))
			} else if (typeof value === 'number') {
				if (value < 10) {
					value = value.toFixed(2) // angle needs more digits
				} else {
					value = value.toFixed(0)
				}
			}

			s += ` ${property}: ${pre}${value}${post},`
		}
	}
	s += '}'
	//logger.debug(this.name, s)
	return s
}

class CircleShapeInfo extends ShapeInfo {
	draw() {
		const circleCollider = this.collider as CircleColliderConfig
		this.graphics.drawCircle(0, 0, circleCollider.radius)
	}

	drawSelected() {
		const circleCollider = this.collider as CircleColliderConfig
		const r = SELECT_MATT
		this.graphics.lineStyle(2, Colors.blue).drawCircle(0, 0, circleCollider.radius + r)
	}

	inside(matt: number, p: VectorXY) {
		const circleCollider = this.collider as CircleColliderConfig
		return withinDistanceVV(p, this.objPos, circleCollider.radius + matt)
	}

	getHandles() {
		const circleCollider = this.collider as CircleColliderConfig
		return [new Vector(circleCollider.radius, 0), new Vector(-circleCollider.radius, 0), new Vector(0, circleCollider.radius), new Vector(0, -circleCollider.radius)]
	}

	scale(mouseWorldPos: VectorXY) {
		const circleCollider = this.collider as CircleColliderConfig
		circleCollider.radius = distanceVV(mouseWorldPos, this.objPos)
	}
}

class EllipseShapeInfo extends ShapeInfo {
	draw() {
		const ellipseCollider = this.collider as EllipseColliderConfig
		this.graphics.drawEllipse(0, 0, ellipseCollider.rX, ellipseCollider.rY)
	}

	drawSelected() {
		const ellipseCollider = this.collider as EllipseColliderConfig
		const r = SELECT_MATT
		this.graphics.lineStyle(2, Colors.blue).drawEllipse(0, 0, ellipseCollider.rX + r, ellipseCollider.rY + r)
	}

	inside(matt: number, p: VectorXY) {
		const ellipseCollider = this.collider as EllipseColliderConfig
		return inEllipse({ rX: ellipseCollider.rX + matt, rY: ellipseCollider.rY + matt }, sub(this.objPos, p))
	}

	getHandles() {
		const ellipseCollider = this.collider as EllipseColliderConfig
		return [new Vector(ellipseCollider.rX, 0), new Vector(-ellipseCollider.rX, 0), new Vector(0, ellipseCollider.rY), new Vector(0, -ellipseCollider.rY)]
	}

	scale(mouseWorldPos: VectorXY) {
		const ellipseCollider = this.collider as EllipseColliderConfig
		const halfSize = new Vector(ellipseCollider.rX, ellipseCollider.rY)
		const isSide = isSideOfBox(this.objPos.clone().sub(halfSize), ellipseCollider.rX * 2, ellipseCollider.rY * 2, mouseWorldPos)
		if (isSide) {
			ellipseCollider.rX = distanceVV(mouseWorldPos, this.objPos)
		} else {
			ellipseCollider.rY = distanceVV(mouseWorldPos, this.objPos)
		}
	}
}

class BoxShapeInfo extends ShapeInfo {
	draw() {
		const boxCollider = this.collider as BoxColliderConfig
		this.graphics.lineStyle(2, Colors.white).drawRect(0, 0, boxCollider.width, boxCollider.height)

		// ColliderTweaker.graphics
		// 	.lineStyle(2, Colors.red)
		// 	.drawCircle(this.center.x, this.center.y, 10)
		// 	.lineStyle(2, Colors.green)
		// 	.drawCircle(this.objPos.x, this.objPos.y, 10)
	}

	drawSelected() {
		const boxCollider = this.collider as BoxColliderConfig
		const r = SELECT_MATT
		this.graphics.lineStyle(2, Colors.blue).drawRect(-r, -r, boxCollider.width + r * 2, boxCollider.height + r * 2)
		this.graphics.lineStyle(2, Colors.white)
	}

	inside(matt: number, p: VectorXY) {
		const boxCollider = this.collider as BoxColliderConfig
		const objPos = this.objPos
		const result = p.x > objPos.x - matt && p.y > objPos.y - matt && Math.abs(p.x - objPos.x) < boxCollider.width + matt && Math.abs(p.y - objPos.y) < boxCollider.height + matt
		return result
	}

	getHandles() {
		const boxCollider = this.collider as BoxColliderConfig
		const h = boxCollider.height
		const hh = h * 0.5
		const w = boxCollider.width
		const hw = w * 0.5
		return [new Vector(0, hh), new Vector(w, hh), new Vector(hw, 0), new Vector(hw, h)]
	}

	scale(mouseWorldPos: VectorXY) {
		const boxCollider = this.collider as BoxColliderConfig
		const objPos = this.objPos

		const isSide = isSideOfBox(this.objPos, boxCollider.width, boxCollider.height, mouseWorldPos)
		if (isSide && mouseWorldPos.x > this.center.x) {
			boxCollider.width = Math.abs(mouseWorldPos.x - objPos.x)
		} else if (isSide) {
			const newOffset = mouseWorldPos.x - this.position.x
			boxCollider.width += boxCollider.position[0] - newOffset
			boxCollider.position[0] = newOffset
		} else if (mouseWorldPos.y > this.center.y) {
			boxCollider.height = Math.abs(mouseWorldPos.y - objPos.y)
		} else {
			const newOffset = mouseWorldPos.y - this.position.y
			boxCollider.height += boxCollider.position[1] - newOffset
			boxCollider.position[1] = newOffset
		}
	}

	get center() {
		return add(this.localCenter, this.objPos)
	}

	get localCenter() {
		const boxCollider = this.collider as BoxColliderConfig
		return new Vector(boxCollider.width * 0.5, boxCollider.height * 0.5)
	}
}

class PolygonShapeInfo extends ShapeInfo {
	draw() {
		const polygonCollider = this.collider as PolygonColliderConfig
		const vertices = getVertices(polygonCollider.vertices, true)
		this.graphics.moveTo(vertices[0].x, vertices[0].y)
		for (let i = 1; i < vertices.length; i++) {
			const v = vertices[i]
			this.graphics.lineTo(v.x, v.y)
		}
	}

	inside(matt: number, p: VectorXY) {
		return false
	}

	getHandles() {
		const polygonCollider = this.collider as PolygonColliderConfig
		const vertices = getVertices(polygonCollider.vertices, true)
		return getVertices(polygonCollider.vertices)
	}

	scale(mouseWorldPos: VectorXY) { }
}

function isSideOfBox(boxPos: Vector, width: number, height: number, p: VectorXY) {
	const left = boxPos.x - p.x
	const right = p.x - boxPos.x - width
	const bottom = p.y - boxPos.y - height
	const top = boxPos.y - p.y

	const dirs = [left, right, bottom, top]
	const max: number = maxBy(dirs)
	const maxIndex = dirs.indexOf(max)
	return maxIndex === 0 || maxIndex === 1 // on left or right
}

const copyToClipboard = (str, container) => {
	console.log(`\ncopyToClipbard:${str}`)
	const el = document.createElement('textarea')
	el.value = str
	document.body.appendChild(el)
	el.select()
	document.execCommand('copy')
	document.body.removeChild(el)

	clientDebug.drawTextDirect(str, Renderer.getInstance().getCameraCenterWorldPos(), 0xffffff, false, 1, container)
	//debugDrawText('is this working?', Renderer.getInstance().getCameraCenterWorldPos(), 0xffffff, false, 5)
}
