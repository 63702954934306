








export default {
	name: 'ListErrors',
	props: {
		errors: {
			type: Array,
			required: true,
			default: () => [],
		},
	},
}
