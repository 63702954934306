import { Container, DisplayObject } from 'pixi.js'
import { debugConfig } from '../../engine/client/debug-config'
import { Effect } from '../../engine/client/graphics/pfx/effect'
import { IParticleRendererCamera } from '../../engine/client/graphics/pfx/sprite-particle-renderer'
import { RenderQueue, RenderQueueElementType } from '../../engine/client/graphics/render-queue'
import Renderer from '../../engine/client/graphics/renderer'
//import Renderer from '../../engine/client/graphics/renderer'
import { InstancedProjectile, InstancedSprite } from './instanced-sprite'

export enum RenderableObjectType {
	Effect,
	DisplayObject,
	Prop,
	Projectile,
}

interface OneOffEffect {
	effect: Effect
	timer: number
}

export interface RenderableObject {
	renderableType: RenderableObjectType
	obj: any
}

export class LayerRenderer extends Container {
	private topLevelObjects: RenderableObject[] = []
	private oneOffEffects: OneOffEffect[] = []
	private renderQueue: RenderQueue
	private cameraState: IParticleRendererCamera

	constructor(renderQueue: RenderQueue, cameraState: IParticleRendererCamera) {
		super()
		this.renderQueue = renderQueue
		this.cameraState = cameraState
	}

	shutdown() {
		this.topLevelObjects.forEach((o) => {
			if (o.obj.destroy) {
				o.obj.destroy(false)
			}
		})
		this.topLevelObjects = []
	}

	update(delta: number): void {
		this.topLevelObjects.forEach((renderableObject) => {
			renderableObject.obj.update(delta)
		})

		if (debugConfig.pfx.drawNames) {
			this.topLevelObjects.forEach((renderableObject) => {
				const obj = renderableObject.obj
				if (obj instanceof Effect) {
					Renderer.getInstance().drawText({ text: obj.name, x: obj.x, y: obj.y, permanent: false, destroyAfterSeconds: 0.01, color: 0xffffff, scale: 1 })
				}
			})
		}

		for (const oneOff of this.oneOffEffects) {
			oneOff.timer -= delta
			if (oneOff.timer <= 0) {
				this.removeFromScene(oneOff.effect)
				this.oneOffEffects.remove(oneOff)
			}
		}
	}

	addEffectToScene(pfx: Effect): void {
		console.assert(pfx)
		this.topLevelObjects.push({ renderableType: RenderableObjectType.Effect, obj: pfx })
	}

	addOneOffEffectToScene(pfx: Effect, lifetime: number, prewarm?: boolean) {
		console.assert(pfx)
		//TODO2: this is technically a hack to fast forward the emitter to prevent the delay-of-1-second bug
		if (prewarm) {
			pfx.prewarm()
		}
		this.topLevelObjects.push({ renderableType: RenderableObjectType.Effect, obj: pfx })
		this.oneOffEffects.push({ effect: pfx, timer: lifetime })
	}

	removeOneOffEffect(pfx: Effect) {
		const found = this.oneOffEffects.find((e) => e.effect === pfx)
		if (found) {
			found.timer = 0
		}
	}

	addPropToScene(prop: InstancedSprite): void {
		console.assert(prop)
		this.topLevelObjects.push({ renderableType: RenderableObjectType.Prop, obj: prop })
	}

	addProjectileToScene(projectile: InstancedProjectile): void {
		console.assert(projectile)
		this.topLevelObjects.push({ renderableType: RenderableObjectType.Projectile, obj: projectile })
	}

	_render(_renderer: PIXI.Renderer): void {
		if (this.renderQueue) {
			this.renderQueue.clear()
			this.topLevelObjects.forEach((renderableObject) => {
				switch (renderableObject.renderableType) {
					case RenderableObjectType.Effect:
						// The effect will add particles to the render queue
						renderableObject.obj.render(this.renderQueue)
						break
					case RenderableObjectType.Prop:
						renderableObject.obj.render(this.renderQueue)
						break
					case RenderableObjectType.Projectile:
						renderableObject.obj.render(this.renderQueue)
						break
					case RenderableObjectType.DisplayObject:
						this.renderQueue.addElement(renderableObject.obj, RenderQueueElementType.DisplayObject, renderableObject.obj.zIndex)
						break
				}
			})
			this.renderQueue.flush(this.cameraState)
		}
	}

	addDisplayObjectToScene(dispObj: DisplayObject): void {
		if (dispObj.parent === null) {
			//this.addChild(dispObj)
			// if we don't set parent disObj won't render,
			//  but we don't want it as child of this so we can control order of render
			dispObj.parent = this
		}
		this.topLevelObjects.push({ renderableType: RenderableObjectType.DisplayObject, obj: dispObj })
	}

	removeFromScene(obj: any): void {
		if (obj && obj.emitters) {
			obj.emitters.forEach((emitter) => emitter.reset())
		}

		if (obj && obj.children && obj.children.length > 0) {
			for (let i = 0, len = obj.children.length; i < len; ++i) {
				this.removeFromScene(obj.children[i])
			}
		}

		let idx = -1
		for (let i = 0, len = this.topLevelObjects.length; i < len; ++i) {
			if (this.topLevelObjects[i].obj === obj) {
				idx = i
				break
			}
		}
		if (idx >= 0) {
			this.topLevelObjects.splice(idx, 1)
		}
	}
}
