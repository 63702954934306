import nengi from 'nengi'

class UpdateCurrencyMessage {
	static protocol = {
		amount: nengi.String,
        isScales: nengi.Boolean
	}

	amount: string
    isScales: boolean

	constructor(amount: string, isScales: boolean) {
		this.amount = amount
        this.isScales = isScales
	}
}

export default UpdateCurrencyMessage
