import nengi from 'nengi'

export default class PlayFanfareMessage {
	static protocol = {
		bgm: nengi.String,
	}

	bgm: string

	constructor(bgm) {
		this.bgm = bgm
	}
}
