import nengi from 'nengi'

export default class TriggerPartyDepartureCountdownCommand {
	static protocol = {
		partyId: nengi.String,
		targetDifficulty: nengi.UInt16,
	}

	partyId: string
	targetDifficulty: number

	constructor(partyId, targetDifficulty) {
		this.partyId = partyId
		this.targetDifficulty = targetDifficulty
	}
}
