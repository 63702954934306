import { isArray } from "lodash";
import { GeneralStoreItemsSubType } from "../../loot/shared/general-store-items-sub-type";
import ItemType, { ItemSubType, ItemTypePrettyName } from "../../loot/shared/item-type";
import { PlayerSkinInformation } from "../../loot/shared/player-skin-sub-type";
import { PetSubType } from "../../loot/shared/prize-sub-type";
import { weaponSkinSubTypeToWeaponSubType, WeaponSkinType } from "../../loot/shared/weapon-skin-sub-type";
import WeaponSubType from "../../loot/shared/weapon-sub-type";
import { AccountPersistedBuff } from "../../player/shared/account-persisted-buff-types";
import { SKIN_SLOT } from "../../player/shared/set-skin.shared";

export enum RewardType {
	COIN,
	ITEM_BUNDLE,
	ACCOUNT_BUFF,
	SKIN,
}

export interface RewardData {
	rewardType: RewardType
	isPremium?: boolean
}

// Coin reward
export interface CoinRewardData extends RewardData {
	rewardType: RewardType.COIN
	amount: number
}

// Item reward
export interface ItemReward {
	itemType: ItemType
	itemSubType: ItemSubType
	level?: number
	petSubType?: PetSubType
	weaponSkinType?: WeaponSkinType
	playerSkinInfo?: PlayerSkinInformation
	stackSize?: number
}

export interface ItemBundleRewardData extends RewardData {
	rewardType: RewardType.ITEM_BUNDLE
	rewards: ItemReward[]
}

// Account buff reward
export interface AccountBuffRewardData extends RewardData {
	rewardType: RewardType.ACCOUNT_BUFF
	buff: AccountPersistedBuff
	name: string
	hoverTooltip: string
	rarity: string
	itemSubType: GeneralStoreItemsSubType
}

// Skin / mtx
export interface MtxRewardData extends RewardData {
	rewardType: RewardType.SKIN
	isSkin: boolean
	skinSlot?: SKIN_SLOT
	mtxIdentifier: any
}

export type AnyRewardData = CoinRewardData | ItemBundleRewardData | AccountBuffRewardData | MtxRewardData

export function anyRewardToGridItems(rewards: AnyRewardData | AnyRewardData[]) {
	const toReturn = []
	let rewardArray
	if (isArray(rewards)) {
		rewardArray = rewards
	} else {
		rewardArray = [rewards]
	}

	for (const reward of rewardArray) {
		switch (reward.rewardType) {
			case RewardType.COIN:
				const coin = reward as CoinRewardData
				toReturn.push({
					id: 'n/a',
					level: 0,
					itemType: 'Coin',
					amount: coin.amount,
				})
				break
			case RewardType.ITEM_BUNDLE:
				const itemBundle = reward as ItemBundleRewardData
				for (let i = 0; i < itemBundle.rewards.length; ++i) {
					const item = itemBundle.rewards[i]
					if(item.petSubType || item.weaponSkinType || item.playerSkinInfo) {
						const skinSlot = item.petSubType ? SKIN_SLOT.PET_MAIN : (item.weaponSkinType ? '@TODO reward-types.ts get this' : item.playerSkinInfo.skinSlot)
						toReturn.push({
							id: 'n/a',
							level: 0,
							itemType: 'Mtx',
							rarity: 'epic',
							name: item.petSubType || item.weaponSkinType || item.playerSkinInfo.skinIdentifier,
							isSkin: true,
							slot: skinSlot,
						})
					} else {
						toReturn.push({
							id: 'n/a',
							level: item.level,
							itemType: ItemTypePrettyName.get(item.itemType).toProperCase(),
							itemTypeEnum: item.itemType,
							itemSubType: item.itemSubType,
							biome: item.itemSubType,
							stackAmount: item.stackSize
						})
					}
				}
				break
			case RewardType.ACCOUNT_BUFF:
				const buff = reward as AccountBuffRewardData
				toReturn.push({
					id: 'n/a',
					level: 0,
					itemType: 'Consumable',
					itemSubType: buff.itemSubType,
					rarity: buff.rarity,
					name: buff.name,
					hoverTooltip: buff.hoverTooltip,
				})
				break
			case RewardType.SKIN:
				const skin = reward as MtxRewardData
				toReturn.push({
					id: 'n/a',
					level: 0,
					itemType: 'Mtx',
					rarity: 'epic',
					name: skin.mtxIdentifier,
					isSkin: skin.isSkin,
					slot: skin.skinSlot,
				})
				break
		}
	}

	return toReturn
}

export function anyRewardToMtxItems(rewards: AnyRewardData | AnyRewardData[]) {
	const toReturn = []
	let rewardArray
	if (isArray(rewards)) {
		rewardArray = rewards
	} else {
		rewardArray = [rewards]
	}

	for (const reward of rewardArray) {
		switch (reward.rewardType) {
			case RewardType.COIN:
				// not supported in skin grid item
				break
			case RewardType.ITEM_BUNDLE:
				const itemBundle = reward as ItemBundleRewardData
				for (let i = 0; i < itemBundle.rewards.length; ++i) {
					const item = itemBundle.rewards[i]
					if(item.playerSkinInfo) {
						toReturn.push({
							mtxId: item.playerSkinInfo.skinIdentifier,
							isPremium: itemBundle.isPremium
						})
					} else if(item.petSubType) {
						toReturn.push({
							mtxId: 'pet-' + item.petSubType,
							isPremium: itemBundle.isPremium
						})
					} else if(item.weaponSkinType) {
						const weaponType = weaponSkinSubTypeToWeaponSubType(item.weaponSkinType)
						let weaponString

						switch(weaponType) {
							case WeaponSubType.ArcaneFocus:
								weaponString = 'arcane-'
								break
							case WeaponSubType.Crossbow:
								weaponString = 'crossbow-'
								break
							case WeaponSubType.Scythe:
								weaponString = 'scythe-'
								break
							case WeaponSubType.Spellsword:
								weaponString = 'sword-'
								break
							case WeaponSubType.Staff:
								weaponString = 'staff-'
								break
							case WeaponSubType.Wand:
								weaponString = 'wand-'
								break
						}

						toReturn.push({
							mtxId: weaponString + item.weaponSkinType,
							isPremium: itemBundle.isPremium
						})
					} else {
						// not supported in skin grid item
					}
				}
				break
			case RewardType.ACCOUNT_BUFF:
				// not supported in skin grid item
				break
			case RewardType.SKIN:
				const skin = reward as MtxRewardData
				toReturn.push({
					mtxId: skin.mtxIdentifier,
					isPremium: reward.isPremium
				})
				break
		}
	}

	return toReturn
}