import { EnemyAI, FightingTriggers, IdleBehaviours, FightingBehaviours, LeashTriggers, LeashingBehaviours, AttackTypes, DeadBehaviours, EnemyType, ShotLeadPrecision, EnemyTargetingStyles } from '../../shared/ai-types'
import { EnemyDefaults } from './enemy-defaults'
import { SpineDataName, EnemyBipedSkinType } from '../../../models-animations/shared/spine-config'
import { ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { Colors } from '../../../utils/colors'
import { ModType, ProjectileEffectType, ProjectileTargetType } from '../../../projectiles/shared/projectile-types'
import { ParticleEffectType } from '../../../engine/shared/game-data/particle-config'
import { ENEMY_DEFAULT_HEALTH } from '../../../engine/shared/game-data/enemy-formulas'
import { ENEMY_NAMES } from './enemy-names'

const trainingDummy: EnemyAI = {
	name: ENEMY_NAMES.TRAINING_DUMMY,
	type: EnemyType.BASIC,
	general: {
		enemyLevel: 1,
		experienceWhenKilled: 1,
	},
	appearance: {
		asset: SpineDataName.BLIMPIE,
		skin: EnemyBipedSkinType.DEFAULT,
		flying: true,
		scale: 2,
		// color: [1, 1, 1],
	},
	baseAttributes: {
		...EnemyDefaults.baseAttributes,
		colliders: [
			{
				type: ColliderType.Circle,
				radius: 150,
				traits: ColliderTraits.BlockProjectile,
				position: [0, -300],
			},
			{
				type: ColliderType.Circle,
				radius: 10,
				traits: ColliderTraits.BlockMovement,
				position: [0, 0],
			},
		],
		maxHealth: ENEMY_DEFAULT_HEALTH * 9999,
		movementSpeed: 0,
		attackRate: 1,
		lootDropProbability: 0,
		lootDropQuantity: 0,
	},
	soundEffects: {
		attack: 'Projectile_Fireball',
		impact: 'SFX_Impact_Enemy_Mush',
	},
	states: {
		idling: {
			behaviour: IdleBehaviours.STATIONARY,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			transitionToFighting: {
				trigger: FightingTriggers.AGGRESSIVE_PROXIMITY,
				radius: 900,
			},
			aggroOnHit: true,
		},
		fighting: {
			behaviour: FightingBehaviours.CHASE_AND_ATTACK,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			attackType: AttackTypes.PROJECTILE,
			engagementMaxDistance: 1500,
			engagementMinDistance: 0,
			movementMaxDistance: 1500,
			movementMinDistance: 0,
			attackOptions: [
				{
					...EnemyDefaults.projectileConfig,
					projectileCount: 1,
					spreadAngle: 0,
					speed: 1000,
					colliderRadius: 5,
					lifespanInSeconds: 0.2,
					modifiers: [{ modType: ModType.STRAIGHT }],
					targetType: ProjectileTargetType.ENEMY,
					effectType: ProjectileEffectType.Heal,
					burstCount: 0,
					color: Colors.red,
					bulletParticleEffect: ParticleEffectType.PROJECTILE_ENEMY0,
					bulletTrailParticleEffect: ParticleEffectType.PROJECTILE_ENEMY_TRAIL,
					muzzleFlairParticleEffect: ParticleEffectType.PROJECTILE_PHYSICAL_SHOOT,
				},
			],
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 0.1,
			transitionToLeashing: {
				trigger: LeashTriggers.RANGE_TO_TARGET_EXCEEDED,
				range: 500,
			},
		},
		leashing: {
			behaviour: LeashingBehaviours.MOVE,
			speedMultiplier: 4,
		},
		fleeing: {},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 0.5,
		},
	},
}

export default trainingDummy
