import { EventEmitter } from 'events'
import { findInObject } from '../../utils/object-util'

export const devToolsSettings = {
	nonreactive: true,
	autoRefresh: false,
}

export default class DevToolsManager {
	static getInstance() {
		if (!DevToolsManager.instance) {
			DevToolsManager.instance = new DevToolsManager()
		}
		return DevToolsManager.instance
	}
	static instance: DevToolsManager

	eventEmitter: EventEmitter

	private _debugObject
	private _enumData
	private _serverDebugObjectName
	private _propertyEnumMap: Map<string, any> = new Map()
	private nameObjectMap: Map<string, object> = new Map()
	private lowercaseNameObjectMap: Map<string, object> = new Map()

	get debugObject() {
		return this._debugObject
	}
	get enumData() {
		return this._enumData
	}
	get propertyEnumMap() {
		return this._propertyEnumMap
	}
	get serverDebugObjectName() {
		return this._serverDebugObjectName
	}

	private constructor() {
		this.eventEmitter = new EventEmitter()
	}

	getDebugObject() {
		if (process.env.NODE_ENV !== 'beta' && process.env.NODE_ENV !== 'loot-prod') {
			return this._debugObject
		}
	}

	/**
	 * Sets the current debug object for use in the inspector, or other debug tool
	 * @param debugObject any object you wish to inspect or debug
	 * @param optionalServerDebugObjectName not required for client side vars. For the client setting server objects though, a name is required
	 */
	setDebugObject(debugObject: any, optionalServerDebugObjectName?: string, enumData?: object, setIfSet: boolean = true) {
		if (process.env.NODE_ENV !== 'beta' && process.env.NODE_ENV !== 'loot-prod') {
			if (!setIfSet && this._debugObject) {
				return
			}

			console.log(`DevToolManager:setDebugObject: ${debugObject.name ?? debugObject.constructor.name}`)
			this._debugObject = debugObject
			this._enumData = enumData
			for (const key in this._enumData) {
				if (enumData.hasOwnProperty(key)) {
					const value = enumData[key]
					const pos = key.indexOf('__enum_')
					if (pos !== -1) {
						const propName = key.substring('__enum_'.length)
						this._propertyEnumMap.set(propName, value)
					}
				}
			}
			this._serverDebugObjectName = optionalServerDebugObjectName
			this.eventEmitter.emit('WATCH_OBJECT', debugObject)
		}
	}

	addNamedObject(object: { name: string }) {
		if (process.env.NODE_ENV !== 'beta' && process.env.NODE_ENV !== 'loot-prod') {
			this.addObjectByName(object.name, object)
		}
	}

	/** Adds a property name which will get mapped to a specific enum in the inspector
	 *  This converts the value changer into a dropdown menu where you can select an
	 *  enum value.
	 * @param propertyName the property name to map
	 * @param passedEnumStruct the specific enum type to register
	 */
	addPropertyEnumMapping(propertyName: string, passedEnumStruct: any) {
		if (this._propertyEnumMap.has(propertyName)) {
			console.warn(`DevToolsManager: Property '${propertyName}' already exists in the property enum map and is being overwritten. This could result in the property not having the inspected enum values in the inspector.`)
		}
		const enumStruct = {}
		// number enums are a bidirectional map object with key:value and value:key
		// string enums are a unidirectional map object with key:value
		// clean up number enums to also be key:value, as that's what we'll use in the inspector UI
		for (const key in passedEnumStruct) {
			if (passedEnumStruct.hasOwnProperty(key)) {
				const value = passedEnumStruct[key]
				if (!Number(value)) {
					enumStruct[key] = value
				}
			}
		}
		this._propertyEnumMap.set(propertyName, enumStruct)
	}

	/**
	 * @param name what to refer to this object by... ie: `/inspect asp-shot`
	 * @param object any object you wish to inspect by `name`
	 * @example DevToolsManager.getInstance().addObjectByName('asp-shot', longRangeAbility)
	 */
	addObjectByName(name: string, object: object) {
		if (process.env.NODE_ENV !== 'beta' && process.env.NODE_ENV !== 'loot-prod') {
			//console.log(`addObjectByName: ${name}`)
			this.nameObjectMap.set(name, object)
			this.lowercaseNameObjectMap.set(name.toLowerCase(), object)
		}
	}

	// adds all module exports as named objects of name-form: $module.$varname
	addModuleExports(module: { id: string; exports: any }) {
		if (process.env.NODE_ENV !== 'beta' && process.env.NODE_ENV !== 'loot-prod') {
			let moduleIdWords = module.id.split('/') // required on client
			if (moduleIdWords.length === 1) {
				moduleIdWords = module.id.split('\\') // required on server
			}
			const moduleId = moduleIdWords[moduleIdWords.length - 1]
			for (const key in module.exports) {
				if (Object.prototype.hasOwnProperty.call(module.exports, key)) {
					const _export = module.exports[key]
					this.addObjectByName(moduleId + '.' + key, _export)
				}
			}
		}
	}

	getObjectByName(name: string): any {
		if (process.env.NODE_ENV !== 'beta' && process.env.NODE_ENV !== 'loot-prod') {
			// look for an exact match
			let obj = this.nameObjectMap.get(name)
			// look for an exact lowercase match
			if (!obj) {
				obj = this.lowercaseNameObjectMap.get(name.toLowerCase())
			}

			// look for an exact regex match
			if (!obj) {
				this.nameObjectMap.forEach((value, key) => {
					const match = key.match(name)
					if (!obj && match && match[0].length === name.length) {
						obj = value
					}
				})
			}

			// break down into words (. delimited) and look for match in the words
			if (!obj) {
				this.lowercaseNameObjectMap.forEach((value, key) => {
					if (!obj && _matchWords(name.toLowerCase(), key)) {
						obj = value
					}
				})
			}

			// look for an any regex match
			if (!obj) {
				this.lowercaseNameObjectMap.forEach((value, key) => {
					if (!obj && key.match(name)) {
						obj = value
					}
				})
			}
			return obj
		}
		return undefined
	}

	getMatchingNames(name: string): string[] {
		if (process.env.NODE_ENV !== 'beta' && process.env.NODE_ENV !== 'loot-prod') {
			const matchingNames: string[] = []
			this.lowercaseNameObjectMap.forEach((value, key) => {
				if (key === name.toLowerCase()) {
					matchingNames.push(key)
				} else if (_matchWords(name.toLowerCase(), key)) {
					matchingNames.push(key)
				}
			})
			return matchingNames
		}
		return undefined
	}

	/** This will iterate recurisvely through the object detecting all instances of
	 * registered `propertyName`s that have been mapped to enums. Each one it finds,
	 * it will add to an enum payload to send to the client (enumData), as well as
	 * record the presence of that enum on the object.
	 */
	annotateEnumsRecursively(obj: object) {
		const enumData = {}
		findInObject(obj, (name: string, o: any, parentObject: object) => {
			let found = false
			this._propertyEnumMap.forEach((value, key) => {
				if (name === key) {
					parentObject[`__enum_${name}`] = true
					enumData[`__enum_${name}`] = value
					found = true
				}
			})
			return found
		})
		return enumData
	}
}

// matches asp.long to asp.longRangeAbility
function _matchWords(attempt, key) {
	if (process.env.NODE_ENV !== 'beta' && process.env.NODE_ENV !== 'loot-prod') {
		const keyWords = key.split('.')
		const attemptWords = attempt.split('.')
		for (let i = 0; i < attemptWords.length; i++) {
			if (!keyWords.find((w) => w.startsWith(attemptWords[i]))) {
				return false
			}
		}
		return true
	}
	return false
}
