import nengi from 'nengi'
import { ProjectileConfigId } from './projectile-config-ids'
import { ProjectileConfig } from './projectile-types'

export class ProjectileConfigIdMessage {
	static protocol = {
		config: { type: nengi.String },
		id: { type: nengi.UInt16 }
	}

	config: string
	id: ProjectileConfigId

	constructor(config: ProjectileConfig, id: ProjectileConfigId) {
		this.config = JSON.stringify(config)
		this.id = id
	}
}
