import logger from './client-logger'
import { highResolutionTimestamp } from './debug'
import { timeInMilliseconds, timeInSeconds } from './primitive-types'

type callbackSystem_cb = () => void

const DEBUG = false
interface DelayedCallback {
	owner: any // this is just used for context... so we can cancel any applied callbacks on this owner
	callbackTimeInSeconds: timeInSeconds
	callbackFn: callbackSystem_cb
}

export function callbacks_addCallback(owner: any, callbackFn: callbackSystem_cb, delay: timeInSeconds) {
	const now: timeInSeconds = highResolutionTimestamp() * 0.001

	//logger.debug(`callbackSystem_add now:${now} delay:${delay} callbackTimeInSeconds:${now + delay}`)

	const newDelayedCallback: DelayedCallback = {
		owner,
		callbackTimeInSeconds: now + delay,
		callbackFn,
	}

	callbacks.push(newDelayedCallback)

	return newDelayedCallback
}

export function callbacks_removeCallbacks(owner: any, callbackFn: callbackSystem_cb) {
	callbacks = callbacks.filter((ao) => ao.owner !== owner)
	if (DEBUG) {
		logger.debug(`removed anim, count:${callbacks.length}`)
	}
}

export function callbacks_update(delta: timeInSeconds, now: timeInMilliseconds) {
	const nowInSeconds = now * 0.001

	callbacks.forEach((cb) => {
		if (cb.callbackTimeInSeconds <= nowInSeconds) {
			//logger.debug(`callbackSystem_update calling, now:${nowInSeconds}`)
			cb.callbackFn()
		}
	})

	callbacks = callbacks.filter((cb) => cb.callbackTimeInSeconds > nowInSeconds)
}

export function callbacks_shutdown() {
	callbacks = []
}

export function callbacks_numCallbacks() {
	return callbacks.length
}

let callbacks: DelayedCallback[] = []
