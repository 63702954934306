import nengi from 'nengi'
import { gameUnits } from '../../utils/primitive-types'

export default class InspectNearestEnemyCommand {
	static protocol = {
		x: { type: nengi.Float32 },
		y: { type: nengi.Float32 },
	}
	x: gameUnits
	y: gameUnits

	constructor(worldX: gameUnits, worldY: gameUnits) {
		this.x = worldX
		this.y = worldY
	}
}
