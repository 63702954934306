






















































import moment from 'moment'
import { mapMutations } from 'vuex'
import { tooltipText } from '../../reusable-components/v-tooltip/v-tooltip-functions'
import { toCountdownString } from '../../../utils/math'
import { getPetStyleForSpriteSheet, getStyleForSpriteSheet } from '../../state/cosmetics-ui-state'
import { SKIN_SLOT, SKIN_SLOT_TO_PREFIX } from '../../../player/shared/set-skin.shared'
import { weaponSkinSubTypeToThemeString } from '../../../loot/shared/weapon-skin-sub-type'
import { SkinInformationByIdentifier } from '../../../loot/shared/player-skin-sub-type'

export default {
	name: 'MtxIventoryItem',
	props: {
		item: {
			type: Object,
			required: true,
		},
		showSlot: {
			type: Boolean,
			required: false,
			default: true,
		},
		onClickFn: {
			type: Function,
			required: false,
			default: () => {
				console.warn('The default on-click handler for the <MtxIventoryItem /> component has been called. Did you forget to pass an :on-click handler to the component?')
			},
		},
		selected: {
			type: Boolean,
			required: false,
			default: false,
		},
		selectable: {
			type: Boolean,
			required: false,
			default: true,
		},
		showDetailsButton: {
			type: Boolean,
			required: false,
			default: true,
		},
		showOwned: {
			type: Boolean,
			required: false,
			default: false,
		},
		inStorageCount: {
			type: Number,
			required: false,
			default: 0,
		},
		showPremiumFlag: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data() {
		return {
			remainingTime: '',
			interval: null,
			skinToPrefix: SKIN_SLOT_TO_PREFIX,
			SKIN_SLOT: SKIN_SLOT,
		}
	},
	computed: {
		hasLimitedElement() {
			return this.item.offerEnd || this.item.initialQuantity
		},
		getStyle() {
			const split = this.item.mtxId.split('-')
			const prefix = split[0] + '-'

			let skinSlot = this.skinSlotFromMtxId
			console.assert(skinSlot)

			// chop off prefix
			const id = Number.parseInt(this.item.mtxId.substring(prefix.length))
			switch(skinSlot) {
				case SKIN_SLOT.PET_MAIN:
					return getPetStyleForSpriteSheet(id, 279, 240)
				case SKIN_SLOT.PLAYER_MAIN:
					return getStyleForSpriteSheet(this.item.mtxId, 218, 179, skinSlot)
				case SKIN_SLOT.PLAYER_BACK:
					return getStyleForSpriteSheet(this.item.mtxId, 279, 240, skinSlot)
				case SKIN_SLOT.PLAYER_FACE:
					return getStyleForSpriteSheet(this.item.mtxId, 279, 240, skinSlot)
				case SKIN_SLOT.PLAYER_FOOTPRINT:
					return getStyleForSpriteSheet(this.item.mtxId, 279, 240, skinSlot)
				case SKIN_SLOT.WEAPON_ARCANE:
				case SKIN_SLOT.WEAPON_CROSSBOW:
				case SKIN_SLOT.WEAPON_SCYTHE:
				case SKIN_SLOT.WEAPON_STAFF:
				case SKIN_SLOT.WEAPON_SWORD:
				case SKIN_SLOT.WEAPON_WAND:
					const weaponTheme = weaponSkinSubTypeToThemeString(id)
					let weaponName = skinSlot === SKIN_SLOT.WEAPON_ARCANE ? 'arcane-focus-' : prefix
					const name = `${weaponName}${weaponTheme}`
					return getStyleForSpriteSheet(name, 279, 240, skinSlot)
			}

			return ''
		},
		getSheetClass() {
			switch(this.skinSlotFromMtxId) {
				case SKIN_SLOT.PET_MAIN:
					return 'pet-skin-sheet'
				case SKIN_SLOT.PLAYER_MAIN:
					return 'player-main-sheet'
				case SKIN_SLOT.PLAYER_FACE:
					return 'face-cosmetics-sheet'
				case SKIN_SLOT.PLAYER_BACK:
					return 'back-cosmetics-sheet'
				case SKIN_SLOT.PLAYER_FOOTPRINT:
					return 'pfx-trails-sheet'
				case SKIN_SLOT.WEAPON_ARCANE:
				case SKIN_SLOT.WEAPON_CROSSBOW:
				case SKIN_SLOT.WEAPON_SCYTHE:
				case SKIN_SLOT.WEAPON_STAFF:
				case SKIN_SLOT.WEAPON_SWORD:
				case SKIN_SLOT.WEAPON_WAND:
					return 'weapon-skins-sheet'
			}

			return ''
		},
		isItemAtlased() {
			const slot = this.skinSlotFromMtxId
			return Boolean(slot)
		},
		skinSlotFromMtxId() {
			const split = this.item.mtxId.split('-')
			const prefix = split[0] + '-'

			for(const key in this.skinToPrefix) {
				if(this.skinToPrefix[key] === prefix) {
					return key
				}
			}

			const foundInfo = SkinInformationByIdentifier.get(this.item.mtxId)
			if(foundInfo) {
				return foundInfo.skinSlot
			}

			return null
		}
	},
	mounted() {
		if (this.item.offerEnd) {
			this.setRemainingTimeInterval()
		}
	},
	destroyed() {
		if (this.interval) {
			clearInterval(this.interval)
		}
	},
	beforeUpdate() {
		if (this.item.offerEnd) {
			this.setRemainingTimeInterval()
		} else {
			if (this.interval) {
				clearInterval(this.interval)
				this.interval = null
			}
		}
	},
	methods: {
		...mapMutations('mtxStore', ['showItemDetails']),
		tooltipText,
		onClick(itemId) {
			if (this.selectable) {
				this.onClickFn(itemId)
			}
		},
		setRemainingTimeInterval() {
			this.updateRemainingTimeText()

			if (!this.interval) {
				this.interval = setInterval(() => {
					this.updateRemainingTimeText()
				}, 1000)
			}
		},
		updateRemainingTimeText() {
			let nowMoment = moment(new Date()).utc()

			let totalSecs = this.item.endMoment.diff(nowMoment, 's')
			// 10d 12:52:30
			this.remainingTime = toCountdownString(totalSecs, true)
		},
	},
}
