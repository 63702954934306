export function clearObjectRefs(o) {
	for (const key in o) {
		if (Object.prototype.hasOwnProperty.call(o, key)) {
			const element = o[key]
			if (typeof element === 'object') {
				//console.log(`${o.constructor.name}: nulling ${key}`)
				o[key] = null
			}
		}
	}
}

export const findInObject = function(obj: object, predicate: (name: string, object: object, parentObject?: object, path?: string) => boolean, rootName = 'root'): Array<{ obj: any; path: string }> {
	const foundObjects: Array<{ obj: object; path: string }> = []
	const visited: Set<object> = new Set()

	const _getName = function(o: object) {
		return o.constructor.name
	}

	const _visitObjectInner = function(o: object, name: string, depth: number, path: string, parentObject: object) {
		if (name == null) {
			name = _getName(o)
		}

		path = path + '.' + name

		if (o && visited.has(o)) {
			return
		}
		visited.add(o)

		if (predicate(name, o, parentObject, path)) {
			foundObjects.push({ path, obj: o })
		}

		if (o instanceof Map) {
			o.forEach((value, key) => {
				console.log(` Map ${name} -> ${value} (${depth})`)
			})
		} else if (o === null) {
			// because typeof null === 'object'
			return
		} else if (typeof o === 'object') {
			Object.keys(o).forEach((element) => {
				const p = o[element]
				_visitObjectInner(p, element, depth + 1, path, o)
			})
		}
	}

	_visitObjectInner(obj, rootName, 0, rootName, null)
	return foundObjects
}
