import { PlayerSkinType } from '../../../models-animations/shared/spine-config'
import { canEquipItemOfLevel, maxEquipableLevel } from '../../../engine/shared/game-data/player'
import GearSubType, { GearSubTypePrettyName } from '../../../loot/shared/gear-sub-type'
import { UI } from '../../ui'
import { invalidAugmentForWeaponType } from '../../../items/shared/augment-constraints'
import { getWorldDifficultyPrettyString, isDifficultyEnabled } from '../../../engine/shared/game-data/world-difficulty'
import { FactionShortNameToFullName } from '../../../factions/shared/faction-data'

export function copyPartyIdTooltip() {
	if (getCurrentPartyStatus()) {
		return 'Click to copy the Party ID'
	}
	return ''
}

export function getCurrentPartyStatus() {
	return UI.getInstance().store.getters['party/getCurrentlyPartiedStatus']
}

export function enchantmentBarTooltip(biome, slotIndex, playerEnchantments, currentMaxBiome) {
	if (currentMaxBiome >= biome) {
		if (slotIndex <= playerEnchantments.length) {
			return
		} else {
			return 'Complete a Point of Interest or defeat a boss to earn an Enchantment and power ups!'
		}
	} else {
		switch (slotIndex) {
			case 1:
				return 'Complete a Point of Interest or defeat a boss to earn an Enchantment and power ups!'
			case 2:
				return 'Complete a Point of Interest or defeat a boss to earn an Enchantment and power ups!'
			case 3:
				return 'Unlock this Enchantment slot by defeating the B.F.C in the Isle Beach!'
			case 4:
				return 'Unlock this Enchantment slot by defeating the the Fearie Queen in the Terila Forest!'
			case 5:
				return 'Unlock this Enchantment slot by defeating the Gorgon in the Onyx Highland biome!'
			case 6:
				return 'Unlock this Enchantment slot by defeating the Rotfather in the Carmine Fungi Steppes!'
			case 7:
				return 'Unlock this Enchantment slot by defeating the Mirrored Dragon in the Prism Sunset Cliffs!'
		}
	}
}

export function departureWithPartyTooltip(biome) {
	const partyMembers = UI.getInstance().store.getters['party/getPartyMembers']
	const userId = UI.getInstance().store.getters['user/userId']
	const isUserLeader = partyMembers.find((user) => user.playerId === userId && user.leader)

	if (getCurrentPartyStatus() && !isUserLeader) {
		return `Only the party leader can set sail!`
	}
	if (isUserLeader && biome > 0) {
		return `While partied, you can only depart to the beach biome`
	}
	const partyReady = UI.getInstance().store.getters['party/getAllPartyMembersReady']
	if (getCurrentPartyStatus() && !partyReady) {
		return `Cannot leave until every party member is ready`
	}
	return ``
}

export function genericTooltip(message: string) {
	return {
		content: message,
		placement: 'bottom-center',
		offset: 0,
		delay: {
			show: 500,
			hide: 300,
		},
		style: 'text-align:center;',
	}
}

export function pingTooltip() {
	return 'Ping:' + this.ping
}

export function currencyTooltip() {
	return parseInt(this.coinBalance).toLocaleString() + ' coins'
}

export function prismScalesTooltip() {
	return parseInt(this.scalesBalance).toLocaleString() + ' prism scales'
}

export function pitBarHudTooltipText() {
	return `Gold received from the Trash Pit of Chances today.`
}

export function departureToWorldDifficultyTooltip(display, worldDifficulty) {
	if (!isDifficultyEnabled(worldDifficulty)) {
		return `Coming Soon!`
	}
	if (display) {
		return `Continue progressing through Soul Cycles to unlock <b>${getWorldDifficultyPrettyString(worldDifficulty)}</b> difficulty!`
	} else {
		return `${getWorldDifficultyPrettyString(worldDifficulty)}`
	}
}

export function departureWithBiomeKeyTooltip(display) {
	if (!getCurrentPartyStatus()) {
		if (display) {
			return `You need a Biome Key to sail directly to this biome. Find one from defeating an enemy or buy another player's key from the marketplace!`
		}
	}
}

export function departureWithInvalidPressureTooltip(display, minPressure) {
	if (display) {
		return `You cannot depart until you have at least ${minPressure} Soul Bond points allocated!`
	}
}

export function readyWithInvalidPressureTooltip(display, minPressure) {
	if (display) {
		return `You cannot ready up until you have at least ${minPressure} Soul Bond points allocated!`
	}
	return ``
}

export function furnaceTooltipText() {
	if (!this.hasFreeFurnace) {
		return 'All of your furnaces are in use!'
	}
}

export function stashFullTooltipText() {
	if (this.isStashFull) {
		return 'Your stash is full! Sell some items on the marketplace, put them in the furnace, or toss them in the trash pit to free up some space!'
	}
}

export function stashFullMtxItemTooltipText() {
	if (!this.isAccountRelated && this.isStashFull) {
		return 'Your stash is full! Sell some items on the marketplace, put them in the furnace, or toss them in the trash pit to free up some space!'
	}
}

export function pitStorageFullTooltipText() {
	if (this.isPitStorageFull) {
		return `Your pit storage is full! Move some items into your stash, put them in the furnace, or sell them on the marketplace to free up some space!`
	}
}

export function marketTooltipText() {
	if (this.getMyListings.length === this.MAX_LISTINGS) {
		return 'You can only list 5 items on the marketplace at once. Wait for someone to buy one of your items, or head to the marketplace to cancel a listing!'
	}
}

export function listingTooltipText(myListing, max_listing) {
	if (myListing.length === max_listing) {
		return 'You can only list 5 items on the marketplace at once. Wait for someone to buy one of your items, or head to the marketplace to cancel a listing!'
	}
}

export function progressionTooltip() {
	return `Defeat bosses on an adventure to conquer areas.<br><br>Beat the Mirrored Dragon to conquer an entire world tier, unlocking a harder difficulty world tier!`
}

export function marketplaceListTooltipText(canPayFee, fee) {
	if (!canPayFee) {
		return 'You cannot afford to pay the listing fee of ' + fee + ' prism scales.'
	}
}

export function autoAugmentButtonTooltipText() {
	return `<div class="tooltip-title">Auto Augment</div>Auto select high-damage augments for your weapon!`
}

export function combineStackablesTooltipText() {
	return `You can put the stacks from this item automatically onto another item`
}

export const OWNED_PET_TOOLTIP_TEXT = `You already own this pet!`

export function tooltipText(item) {
	if (item.id) {
		const addTitle = (title) => `<div class="tooltip-title">${title}</div>`

		switch (this.item.id) {
			case PlayerSkinType.BARBARIAN:
				return `${addTitle('Player Skin')}A fluffy warrior.`
			case 'biome-key-forest':
				return `${addTitle('Biome Key')}Use this key to travel to the Terilla Forest. (If you've been there before).`
			case 'biome-key-onyx':
				return `${addTitle('Biome Key')}Use this key to travel to the Onyx Highlands. (If you've been there before).`
			case 'biome-key-fungi':
				return `${addTitle('Biome Key')}Use this key to travel to the Carmine Fungi Steppes. (If you've been there before).`
			case 'biome-key-prism':
				return `${addTitle('Biome Key')}Use this key to travel to the Prism Sunset Cliffs. (If you've been there before).`
		}
	}
	return undefined
}

export function attachToolTipText(item) {
	const progressionLevel = UI.getInstance().store.getters['hud/progressionLevel']
	const selectedItem = UI.getInstance().store.getters['augmentationStation/getSelectedItemId']
	const itemDetails = UI.getInstance().store.getters['itemContainers/itemDetails']('equippedWeapon', selectedItem)

	const addTitle = (title) => `<div class="tooltip-title">${title}</div>`

	if (item) {
		const tooltips = []

		if (!canEquipItemOfLevel(progressionLevel, item.level)) {
			const maxEqLevel = maxEquipableLevel(progressionLevel)
			tooltips.push(`${addTitle('Loot Level Locked')}You must unlock level ${item.level} to attach this loot. You have currently unlocked level ${maxEqLevel}.<br>Kill new bosses to unlock more loot.`)
		}

		if (invalidAugmentForWeaponType(item, itemDetails)) {
			tooltips.push(`${addTitle('Loot Type Conflict')}This augment is not compatible with your currently selected weapon.`)
		}

		if (item.unidentified) {
			tooltips.push(`${addTitle('Loot Unidentified')}This loot is unidentified. Identify loot at the identification entity to equip this loot.`)
		}

		if (tooltips.length > 0) {
			return tooltips.join('<br><br>')
		}
	}
	return false
}

export function pitOfChancesTooltipText(item) {
	if (item.itemType === 'Biome Key') {
		return 'Biome Keys thrown into the pit will be lost!'
	} else if (item.itemType === 'Weapon' && item.equippedAugmentIds.length > 0) {
		return 'This weapon has augments on it, remove them first before throwing it into the pit!'
	}

	return ''
}

export function equipToolTipText(item) {
	const progressionLevel = UI.getInstance().store.getters['hud/progressionLevel']

	if (item) {
		const tooltips = []

		const addTitle = (title) => `<div class="tooltip-title">${title}</div>`

		if (item.unidentified) {
			tooltips.push(`${addTitle('Loot Unidentified')}This loot is unidentified. Identify loot at the identification entity to equip this loot.`)
		}

		if (!canEquipItemOfLevel(progressionLevel, item.level)) {
			const maxEqLevel = maxEquipableLevel(this.progressionLevel)
			tooltips.push(`${addTitle('Loot Level Locked')}You must unlock level ${item.level} to equip this loot. You have currently unlocked level ${maxEqLevel}.<br>Kill new bosses to unlock more loot.`)
		}

		if (item.itemType === 'Weapon') {
			const paperdollItem = this.itemByContainerIndex('paperdoll', this.selectedMiniPaperdollIndex)
			if (!paperdollItem || item.itemType !== paperdollItem.itemType) {
				tooltips.push(`${addTitle('Swap Weapons')}Select a weapon slot to equip this weapon.`)
			}
		}

		if (item.itemType === 'Gear') {
			const prettyName = GearSubTypePrettyName.get(item.itemSubType)
			const paperdollItem = this.itemByContainerIndex('paperdoll', this.selectedMiniPaperdollIndex)
			if (paperdollItem?.itemSubType !== item.itemSubType) {
				if (item.itemSubType === GearSubType.Trinket) {
				} else if (this.hasSameGearTypeEquipped(item.itemSubType)) {
					tooltips.push(`${addTitle('Loot Type Conflict')}You have gear of type ${prettyName} equipped already. Unequip the other ${prettyName} to equip this one.`)
				}
			}
		}

		if (tooltips.length > 0) {
			return tooltips.join('<br><br>')
		}
	}
	return false
}

export function factionAugmentBonusText(faction) {
	return `This is a bonus from the ${FactionShortNameToFullName.get(faction)} winning faction wars last week!`
}

export function joinFactionText() {
	if(this.userType === 'anonymous') {
		return `You need to create an account before you can join a faction!`
	} else {
		if(this.isOurFaction) {
			return `You are already aligned with the ${this.factionFullName}!`	
		} else if (this.affiliationMoment && this.affiliationMoment.isAfter(this.lastFactionWarEndMoment)) {
			return `You can only join one faction per week!`
		}
	}
}


export function getDepartureText(isMeterFull, isCurrentSelectedWorldDifficultySufficient, maxUnlockedDifficulty, isMaxUnlockedDifficultySufficient) {
	const uiInst = UI.getInstance()
	const maxAllowedTier = uiInst.store.getters['boatLaunch/maxAllowedTier']
	const minPressure = uiInst.store.getters['boatLaunch/totalPointsForPreviousWorldTier']
	const isValidPressureLoadout = uiInst.store.getters['boatLaunch/isValidPressureLoadout']

	if(!isValidPressureLoadout){
		return `You cannot depart until you have at least ${minPressure} Soul Bond points allocated!`
	} else if(!isMeterFull && isCurrentSelectedWorldDifficultySufficient){
		return `You can advance to Soul Cycle ${maxAllowedTier} by filling the Soul Cycle meter.`
	} else if (isMeterFull && !isCurrentSelectedWorldDifficultySufficient && !isMaxUnlockedDifficultySufficient){
		return `You can advance to Soul Cycle ${maxAllowedTier} by embarking on difficulty ${getWorldDifficultyPrettyString(maxUnlockedDifficulty)}.`
	}
}
