import nengi from 'nengi'
import { BinaryFlag } from '../../engine/shared/game-data/stat-type-mod-category'

export default class PlayerBinaryFlagChangedMessage {
	static protocol = {
		flags: {type: nengi.Number, indexType: nengi.UInt16}
	}

    flags: BinaryFlag[]

	constructor(map: Map<BinaryFlag, number>) {
        const keys = map.keys()
        this.flags = Array.from(keys)
	}
}
