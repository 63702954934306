import Renderer from './renderer'

export class TextureUpdater {
	textures: PIXI.Texture[]

	constructor(textures: PIXI.Texture[]) {
		this.textures = textures
	}

	update() {
		const textureGC = Renderer.getInstance().pixiRenderer.textureGC
		// set the textures touched frame to the current GC frame, so it never gets collected
		this.textures.forEach((texture) => ((texture as any).touched = textureGC.count))
	}
}
