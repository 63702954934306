import { AnyColliderConfig, ColliderTraits, ColliderType } from '../../collision/shared/colliders'
import { degToRad } from '../../utils/math'
import { PropConfigs } from './prop-configs'

const townLargeUncommonColliders: AnyColliderConfig[] = [
	{ type: ColliderType.Ellipse, position: [-31, 8], rX: 378, rY: 160, traits: ColliderTraits.BlockMovementAndItem },
	{ type: ColliderType.Box, position: [-335, -144], width: 634, height: 150, traits: ColliderTraits.BlockProjectile },
	{ type: ColliderType.Circle, position: [360, 97], radius: 58, traits: ColliderTraits.BlockMovementAndItem },
]
const townLargeRare: [AnyColliderConfig] = [{ type: ColliderType.Ellipse, position: [-37, 54], rX: 377, rY: 200, traits: ColliderTraits.BlockAll }]

export const townPropConfigs: PropConfigs = {
	augmentation: {
		colliders: [{ type: ColliderType.Ellipse, position: [1498, 604], rX: 307, rY: 109, traits: ColliderTraits.BlockMovementAndItem }],
		rigged: false,
		visibilityRadius: 5000,
	},
	'augmentation/augmentation-base': {
		zOffset: -200,
		dontLoadAsset: true,
	},
	bg: {
		colliders: [
			{ type: ColliderType.Box, position: [63, -2215], width: 2656, height: 437, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Box, position: [-2295, -2190], width: 1371, height: 450, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Box, position: [-3411, -1226], width: 1577, height: 451, angle: -0.61, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Box, position: [-4276, -1401], width: 1163, height: 1519, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Box, position: [-4040, -53], width: 424, height: 516, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Box, position: [-3931, 405], width: 1383, height: 336, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Box, position: [-2391, 1827], width: 5098, height: 516, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Circle, position: [1745, 313], radius: 30, traits: ColliderTraits.BlockMovementAndItem, },
			{ type: ColliderType.Circle, position: [1911, 430], radius: 30, traits: ColliderTraits.BlockMovementAndItem, },
			{ type: ColliderType.Circle, position: [1578, 1827], radius: 30, traits: ColliderTraits.BlockMovementAndItem, },
			{ type: ColliderType.Circle, position: [2081, 325], radius: 30, traits: ColliderTraits.BlockMovementAndItem, },
			{ type: ColliderType.Circle, position: [2046, 689], radius: 30, traits: ColliderTraits.BlockMovementAndItem, },
			{ type: ColliderType.Circle, position: [2901, 1192], radius: 30, traits: ColliderTraits.BlockMovementAndItem, },
			{ type: ColliderType.Circle, position: [1134, 1657], radius: 30, traits: ColliderTraits.BlockMovementAndItem, },
			{ type: ColliderType.Box, position: [2186, 1670], width: 854, height: 293, traits: ColliderTraits.BlockMovementAndItem, },
			{ type: ColliderType.Circle, position: [-424, -872], radius: 30, traits: ColliderTraits.BlockMovementAndItem, },
			{ type: ColliderType.Circle, position: [-521, -899], radius: 30, traits: ColliderTraits.BlockMovementAndItem, },
			{ type: ColliderType.Circle, position: [-361, -1136], radius: 30, traits: ColliderTraits.BlockMovementAndItem, },
			{ type: ColliderType.Circle, position: [-678, -1076], radius: 30, traits: ColliderTraits.BlockMovementAndItem, },
			{ type: ColliderType.Circle, position: [-588, -1274], radius: 30, traits: ColliderTraits.BlockMovementAndItem, },
			{ type: ColliderType.Circle, position: [-804, -1360], radius: 30, traits: ColliderTraits.BlockMovementAndItem, },
			{ type: ColliderType.Ellipse, position: [-128, -1052], rX: 161, rY: 93, traits: ColliderTraits.BlockMovementAndItem, },
			{ type: ColliderType.Ellipse, position: [-2813, -899], rX: 161, rY: 93, traits: ColliderTraits.BlockMovementAndItem, },
			{ type: ColliderType.Ellipse, position: [-2688, -989], rX: 105, rY: 79, traits: ColliderTraits.BlockMovementAndItem, },
			{ type: ColliderType.Circle, position: [-1388, 1237], radius: 30, traits: ColliderTraits.BlockMovementAndItem, },
			{ type: ColliderType.Box, position: [-1753, 697], width: 275, height: 329, traits: ColliderTraits.BlockMovementAndItem, },
			{ type: ColliderType.Box, position: [-3145, 1298], width: 1040, height: 597, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Box, position: [-2549, 1116], width: 460, height: 278, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Box, position: [-2566, 663], width: 460, height: 278, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Circle, position: [-2536, 959], radius: 52, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Box, position: [-3861, 684], width: 455, height: 697, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Box, position: [-3335, 726], width: 230, height: 157, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Circle, position: [-3089, 765], radius: 52, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Circle, position: [-2912, 768], radius: 52, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Circle, position: [-2806, 773], radius: 52, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Circle, position: [-2632, 795], radius: 82, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Circle, position: [-2720, 815], radius: 45, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Circle, position: [-3319, 1093], radius: 45, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Circle, position: [2348, -1645], radius: 49, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Circle, position: [2437, -1651], radius: 63, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Box, position: [1652, -1536], width: 100, height: 63, angle: 0.00, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Box, position: [1937, -1825], width: 100, height: 66, angle: 0.00, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Box, position: [2032, -1816], width: 100, height: 73, angle: 0.00, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Box, position: [2126, -1790], width: 100, height: 71, angle: 0.00, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Box, position: [2468, -1620], width: 84, height: 260, angle: -0.18, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Circle, position: [2275, -1752], radius: 65, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Circle, position: [1787, -1392], radius: 28, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Circle, position: [1578, -1477], radius: 28, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Box, position: [1399, -1820], width: 100, height: 69, angle: 0.00, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Circle, position: [1058, -1772], radius: 17, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Circle, position: [-1810, -1710], radius: 28, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Box, position: [-1102, -1767], width: 100, height: 63, angle: 0.00, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Box, position: [-989, -1843], width: 199, height: 101, angle: -0.59, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Box, position: [-1431, -1765], width: 110, height: 92, angle: 0.00, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Box, position: [-1368, -1768], width: 296, height: 106, angle: 0.00, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Box, position: [-779, -1947], width: 74, height: 65, angle: -0.72, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Box, position: [-3366, 973], width: 165, height: 80, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Box, position: [-3322, 1432], width: 211, height: 76, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Box, position: [-3438, 1036], width: 194, height: 559, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Box, position: [2275, -1724], width: 84, height: 260, angle: 0.00, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Box, position: [-1688, -348], width: 60, height: 39, traits: ColliderTraits.BlockMovementAndItem, },
			{ type: ColliderType.Box, position: [-2900, -443], width: 124, height: 32, traits: ColliderTraits.BlockMovementAndItem, },
			{ type: ColliderType.Box, position: [-125, -1818], width: 160, height: 26, traits: ColliderTraits.BlockMovementAndItem, },
		],
		rigged: false,
		visibilityRadius: 5000,
	},
	'bg-animated': {
		visibilityRadius: 5000,
		zOffset: 5902,
	},
	'old-bg': {
		colliders: [
			// boundaries
			{ type: ColliderType.Box, position: [48, -2227], width: 2656, height: 434, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-2295, -2190], width: 1371, height: 434, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-3412, -1226], width: 1577, height: 432, angle: degToRad(-35), traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-4276, -1401], width: 1163, height: 1519, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-4040, -53], width: 424, height: 516, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-3922, 415], width: 1841, height: 1751, traits: ColliderTraits.BlockAll },
			{ type: ColliderType.Box, position: [-2391, 1827], width: 5098, height: 516, traits: ColliderTraits.BlockAll },

			// SE tree area
			{ type: ColliderType.Circle, position: [1498, 332], radius: 30, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [1747, 420], radius: 30, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [1578, 1827], radius: 30, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [1860, 306], radius: 30, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [2046, 689], radius: 30, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [2901, 1192], radius: 30, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [1134, 1657], radius: 30, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [2186, 1670], width: 632, height: 325, traits: ColliderTraits.BlockMovementAndItem },

			// N tree area
			{ type: ColliderType.Circle, position: [-424, -872], radius: 30, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [-521, -899], radius: 30, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [-361, -1136], radius: 30, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [-678, -1076], radius: 30, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [-588, -1274], radius: 30, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [-804, -1360], radius: 30, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-128, -1052], rX: 161, rY: 93, traits: ColliderTraits.BlockMovementAndItem },

			// west pond
			{ type: ColliderType.Ellipse, position: [-2813, -899], rX: 161, rY: 93, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-2688, -989], rX: 105, rY: 79, traits: ColliderTraits.BlockMovementAndItem },

			// next to firing range
			{ type: ColliderType.Circle, position: [-1388, 1237], radius: 30, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-1753, 697], width: 275, height: 329, traits: ColliderTraits.BlockMovementAndItem },
		],
		rigged: false,
		visibilityRadius: 5000,
		overrideAtlas: 'old-bg'
	},
	'old-bg-animated': {
		visibilityRadius: 5000,
		zOffset: 5902,
		overrideAtlas: 'old-bg'
	},
	'bg/hub-world-terrace-01': {
		zOffset: -60000,
		dontLoadAsset: true,
	},
	'bg/hub-world-terrace-02': {
		zOffset: -845,
		dontLoadAsset: true,
	},
	'bg/road-horizontal': {
		zOffset: -50000,
		dontLoadAsset: true,
	},
	'bg/road-vertical': {
		zOffset: -50000,
		dontLoadAsset: true,
	},
	'bg/house-01': {
		dontLoadAsset: true,
		zOffset: -80,
	},
	'bg/house-02': {
		dontLoadAsset: true,
		zOffset: -350,
	},
	'bg/house-03': {
		dontLoadAsset: true,
		zOffset: -80,
	},
	'bg/house-04': {
		dontLoadAsset: true,
		zOffset: -80,
	},
	'bg/house-05': {
		dontLoadAsset: true,
		zOffset: -140,
	},
	'bg/house-06': {
		dontLoadAsset: true,
		zOffset: -80,
	},
	'bg/tile': {
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},
	'bg/hub-world-edge-bottom': {
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},
	'bg/hub-world-edge-center': {
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},
	'bg/hub-world-edge-top': {
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},
	'bg/house_window_light': {
		dontLoadAsset: true,
	},
	'bg/house_window_light_circle': {
		dontLoadAsset: true,
	},
	'bg/house_window_light_full': {
		dontLoadAsset: true,
	},
	'bg/house_window_glow': {
		dontLoadAsset: true,
	},
	'bg/smoke-puff-01': {
		dontLoadAsset: true,
	},
	'bg/smoke-puff-02': {
		dontLoadAsset: true,
	},
	docks: {
		colliders: [
			// water
			{ type: ColliderType.Box, position: [2703, -2531], width: 621, height: 1708, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [3318, -1894], width: 621, height: 916, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [3504, -1164], width: 829, height: 345, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [3693, -819], width: 815, height: 190, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [2674, -637], width: 406, height: 536, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [3075, -638], width: 924, height: 374, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [3257, -261], width: 924, height: 161, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [3451, -97], width: 924, height: 180, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [2334, 695], width: 169, height: 28, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [3256, 75], width: 844, height: 503, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [3436, 576], width: 844, height: 154, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [3630, 728], width: 844, height: 183, traits: ColliderTraits.BlockMovementAndItem, },
			{ type: ColliderType.Box, position: [2747, 915], width: 1297, height: 1214, traits: ColliderTraits.BlockMovementAndItem, },
			{ type: ColliderType.Box, position: [2575, 59], width: 240, height: 31, traits: ColliderTraits.BlockMovementAndItem, },

			// boxes
			{ type: ColliderType.Box, position: [2373, -1140], width: 413, height: 239, traits: ColliderTraits.BlockMovementAndItem, },
			{ type: ColliderType.Box, position: [2533, -1262], width: 221, height: 134, traits: ColliderTraits.BlockMovementAndItem, },
			{ type: ColliderType.Box, position: [2422, -297], width: 107, height: 110, traits: ColliderTraits.BlockMovementAndItem, },
			{ type: ColliderType.Box, position: [2514, -557], width: 102, height: 105, traits: ColliderTraits.BlockMovementAndItem, },
			{ type: ColliderType.Box, position: [2587, -125], width: 228, height: 31, traits: ColliderTraits.BlockMovementAndItem, },
			{ type: ColliderType.Box, position: [2803, 69], width: 465, height: 656, traits: ColliderTraits.BlockMovementAndItem, },
			{ type: ColliderType.Box, position: [2582, 890], width: 263, height: 36, traits: ColliderTraits.BlockMovementAndItem, },
			{ type: ColliderType.Box, position: [2285, -296], width: 107, height: 62, traits: ColliderTraits.BlockMovementAndItem, },
			{ type: ColliderType.Box, position: [2489, -596], width: 77, height: 45, traits: ColliderTraits.BlockMovementAndItem, },
			{ type: ColliderType.Box, position: [2288, -1285], width: 31, height: 183, angle: -1.19, traits: ColliderTraits.BlockAll, }
		],
		rigged: false,
		visibilityRadius: 5000,
	},
	'docks-animated': {
		visibilityRadius: 5000,
		zOffset: -3000,
	},
	'docks/Dock-FrontPosts': {
		zOffset: -100,
		dontLoadAsset: true,
	},
	'docks/Dock-Nest': {
		zOffset: -80,
		dontLoadAsset: true,
	},
	'docks/Dock-SackShell': {
		zOffset: -80,
		dontLoadAsset: true,
	},
	'docks/dock-barrelcrystals': {
		zOffset: -80,
		dontLoadAsset: true,
	},
	'docks/dock-barrelempty': {
		zOffset: -80,
		dontLoadAsset: true,
	},
	'docks/dock-barrelfungi': {
		zOffset: -80,
		dontLoadAsset: true,
	},
	'docks/dock-cratebottle': {
		zOffset: -80,
		dontLoadAsset: true,
	},
	'docks/dock-cratecoconut': {
		zOffset: -80,
		dontLoadAsset: true,
	},
	'docks/dock-crateempty': {
		zOffset: -80,
		dontLoadAsset: true,
	},
	'docks/dock-lamp': {
		zOffset: -80,
		dontLoadAsset: true,
	},
	'docks/dock-mop': {
		zOffset: -80,
		dontLoadAsset: true,
	},
	'docks/dock-boat-a': {
		zOffset: -1000,
		dontLoadAsset: true,
	},
	'docks/dock-planks-a': {
		zOffset: -2000,
		dontLoadAsset: true,
	},
	'docks/dock-boat-b': {
		zOffset: -1000,
		dontLoadAsset: true,
	},
	'docks/dock-planks-b': {
		zOffset: -1000,
		dontLoadAsset: true,
	},
	'docks/dock-boat-c': {
		zOffset: -1000,
		dontLoadAsset: true,
	},
	'docks/dock-planks-c': {
		zOffset: -1000,
		dontLoadAsset: true,
	},
	'docks/dock-rope': {
		zOffset: -80,
		dontLoadAsset: true,
	},
	'docks/dock-sack': {
		zOffset: -80,
		dontLoadAsset: true,
	},
	'docks/dock-bulletin-c': {
		zOffset: -20,
		dontLoadAsset: true,
	},
	'docks/dock-bulletin-b': {
		zOffset: -20,
		dontLoadAsset: true,
	},
	'docks/dock-bulletin-a': {
		zOffset: -20,
		dontLoadAsset: true,
	},
	furnace: {
		colliders: [
			{ type: ColliderType.Box, position: [-2054, -998], width: 174, height: 121, traits: ColliderTraits.BlockMovementAndItem }, // west anvil
			{ type: ColliderType.Box, position: [-1925, -1133], width: 946, height: 300, traits: ColliderTraits.BlockMovementAndItem }, // building
			{ type: ColliderType.Box, position: [-1034, -963], width: 246, height: 107, traits: ColliderTraits.BlockMovementAndItem }, // east pile
		],
		rigged: false,
		visibilityRadius: 5000,
	},
	'furnace-animated': {
		visibilityRadius: 5000,
	},
	'furnace/furnace-building': {
		zOffset: -100,
		dontLoadAsset: true,
	},
	'furnace/furnace-smoke-effect': {
		dontLoadAsset: true,
	},
	'furnace/furnace-puff-effect': {
		dontLoadAsset: true,
	},
	'furnace/furnace-glow': {
		dontLoadAsset: true,
	},
	'furnace/furnace-embers-01': {
		dontLoadAsset: true,
	},
	'furnace/furnace-embers-02': {
		dontLoadAsset: true,
	},
	'furnace/furnace-puff-effect-01': {
		dontLoadAsset: true,
	},
	marketplace: {
		colliders: [
			{ type: ColliderType.Box, position: [238, -1028], width: 1130, height: 207, traits: ColliderTraits.BlockMovementAndItem }, // south tent
			{ type: ColliderType.Box, position: [1182, -875], width: 381, height: 151, traits: ColliderTraits.BlockMovementAndItem }, // south boxes
			{ type: ColliderType.Box, position: [-76, -1464], width: 381, height: 151, traits: ColliderTraits.BlockMovementAndItem }, // west table
			{ type: ColliderType.Box, position: [79, -1387], width: 88, height: 151, traits: ColliderTraits.BlockMovementAndItem }, // west box
			{ type: ColliderType.Box, position: [247, -1550], width: 1091, height: 296, traits: ColliderTraits.BlockMovementAndItem }, // north tents
			{ type: ColliderType.Box, position: [1337, -1383], width: 413, height: 153, traits: ColliderTraits.BlockMovementAndItem }, // east table
			{ type: ColliderType.Box, position: [1580, -1283], width: 95, height: 153, traits: ColliderTraits.BlockMovementAndItem }, // east box
		],
		rigged: false,
		visibilityRadius: 5000,
	},
	'marketplace/market-01': {
		zOffset: -200,
		dontLoadAsset: true,
	},
	'marketplace/market-02': {
		zOffset: -100,
		dontLoadAsset: true,
	},
	'marketplace/market-flags': {
		zOffset: 200,
		dontLoadAsset: true,
	},
	'pit-of-chances': {
		colliders: [{ type: ColliderType.Ellipse, position: [-434, -557], rX: 376, rY: 177, traits: ColliderTraits.BlockMovementAndItem }],
		rigged: false,
		zOffset: -200,
		visibilityRadius: 5000,
	},
	'pit-of-chances-animated': {
		visibilityRadius: 5000,
	},
	'pit-of-chances/pit-glow': {
		dontLoadAsset: true,
	},
	'pit-of-chances/pit-centre-glow-red': {
		dontLoadAsset: true,
	},
	'pit-of-chances/pit-centre-glow-blue': {
		dontLoadAsset: true,
	},
	'pit-of-chances/pitofchance-base': {
		zOffset: -5000,
		dontLoadAsset: true,
	},
	'pit-of-chances/pitofchance-center': {
		zOffset: -50,
		dontLoadAsset: true,
	},
	plaza: {
		colliders: [
			{ type: ColliderType.Ellipse, position: [780, -100], rX: 250, rY: 150, traits: ColliderTraits.BlockMovementAndItem, },
			{ type: ColliderType.Box, position: [329, -655], width: 149, height: 58, traits: ColliderTraits.BlockMovementAndItem, },
			{ type: ColliderType.Box, position: [1069, -638], width: 129, height: 61, traits: ColliderTraits.BlockMovementAndItem, },
			{ type: ColliderType.Box, position: [332, -381], width: 120, height: 95, traits: ColliderTraits.BlockMovementAndItem, },
			{ type: ColliderType.Ellipse, position: [1209, 286], rX: 178, rY: 78, traits: ColliderTraits.BlockMovementAndItem, },
			{ type: ColliderType.Circle, position: [1138, -259], radius: 15, traits: ColliderTraits.BlockMovementAndItem, },
			{ type: ColliderType.Box, position: [1250, -541], width: 52, height: 137, traits: ColliderTraits.BlockMovementAndItem, angle: -0.23, },
			{ type: ColliderType.Box, position: [1410, -398], width: 435, height: 69, traits: ColliderTraits.BlockMovementAndItem, angle: -0.02, },
			{ type: ColliderType.Box, position: [1359, -466], width: 112, height: 113, traits: ColliderTraits.BlockMovementAndItem, angle: -0.10, },
			{ type: ColliderType.Box, position: [1721, -470], width: 103, height: 83, traits: ColliderTraits.BlockMovementAndItem, angle: -0.10, },
			{ type: ColliderType.Circle, position: [994, -371], radius: 31, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Circle, position: [612, -362], radius: 31, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Box, position: [124, -599], width: 100, height: 249, angle: -0.15, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Box, position: [1310, -433], width: 171, height: 117, angle: 0.52, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Circle, position: [1043, 241], radius: 31, traits: ColliderTraits.BlockAll, },
			{ type: ColliderType.Circle, position: [588, 245], radius: 31, traits: ColliderTraits.BlockAll, }
		],
		rigged: false,
		visibilityRadius: 5000,
		overrideAtlas: 'plaza'
	},
	'plaza-animated': {
		visibilityRadius: 5000,
		zOffset: 265,
		overrideAtlas: 'plaza'
	},
	'old-plaza': {
		colliders: [
			{ type: ColliderType.Ellipse, position: [780, -100], rX: 250, rY: 150, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [329, -655], width: 149, height: 58, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [1069, -638], width: 129, height: 61, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [332, -381], width: 120, height: 95, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [1245, 309], rX: 117, rY: 67, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [1070, -249], radius: 10, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [1250, -541], width: 52, height: 137, traits: ColliderTraits.BlockMovementAndItem, angle: -0.23 },
			{ type: ColliderType.Box, position: [1410, -398], width: 435, height: 69, traits: ColliderTraits.BlockMovementAndItem, angle: -0.02 },
			{ type: ColliderType.Box, position: [1359, -466], width: 112, height: 113, traits: ColliderTraits.BlockMovementAndItem, angle: -0.1 },
			{ type: ColliderType.Box, position: [1721, -470], width: 103, height: 83, traits: ColliderTraits.BlockMovementAndItem, angle: -0.1 },
		],
		rigged: false,
		visibilityRadius: 5000,
		overrideAtlas: 'old-bg'
	},
	'old-plaza-animated': {
		visibilityRadius: 5000,
		zOffset: 100,
		overrideAtlas: 'old-bg'
	},
	'plaza/plaza-fountain': {
		zOffset: 0,
		dontLoadAsset: true,
	},
	'plaza/plaza-fountain-base': {
		zOffset: -250,
		dontLoadAsset: true,
	},
	'plaza/plaza-bench': {
		zOffset: -100,
		dontLoadAsset: true,
	},
	'plaza/plaza-bench2': {
		zOffset: -100,
		dontLoadAsset: true,
	},
	'plaza/plaza-cratestack': {
		zOffset: -30,
		dontLoadAsset: true,
	},
	'plaza/plaza-flowers': {
		zOffset: -10,
		dontLoadAsset: true,
	},
	'plaza/plaza-flowers2': {
		zOffset: -10,
		dontLoadAsset: true,
	},
	'plaza/bubba-hut': {
		zOffset: -9,
		dontLoadAsset: true,
	},
	'plaza/plaza-garbagecan': {
		zOffset: -10,
		dontLoadAsset: true,
	},
	'plaza/plaza-grasslarge': {
		zOffset: -10,
		dontLoadAsset: true,
	},
	'plaza/plaza-grasssmall': {
		zOffset: -10,
		dontLoadAsset: true,
	},
	'plaza/plaza-lamp01-a': {
		zOffset: -0,
		dontLoadAsset: true,
	},
	'plaza/plaza-lamp01-b': {
		zOffset: -0,
		dontLoadAsset: true,
	},
	'plaza/plaza-planterflowers': {
		zOffset: -40,
		dontLoadAsset: true,
	},
	'plaza/plaza-plantergrass': {
		zOffset: -40,
		dontLoadAsset: true,
	},
	'plaza/plaza-plantervegetable': {
		zOffset: -40,
		dontLoadAsset: true,
	},
	'plaza/plaza-sign': {
		zOffset: -20,
		dontLoadAsset: true,
	},
	'plaza/plaza-weedslarge': {
		zOffset: -10,
		dontLoadAsset: true,
	},
	'plaza/plaza-weedssmall': {
		zOffset: -10,
		dontLoadAsset: true,
	},
	'plaza/plaza-woodpile': {
		zOffset: -10,
		dontLoadAsset: true,
	},
	'plaza/plaza-woodpile2': {
		zOffset: -10,
		dontLoadAsset: true,
	},
	'plaza/plaza-fence': {
		zOffset: -50,
		dontLoadAsset: true,
	},
	'plaza/water-ripple-01': {
		dontLoadAsset: true,
	},
	'plaza/water-ripple-02': {
		dontLoadAsset: true,
	},
	stash: {
		colliders: [
			{ type: ColliderType.Box, position: [-1651, 182], width: 492, height: 206, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-1410, 379], rX: 236, rY: 142, traits: ColliderTraits.BlockMovementAndItem },
		],
		rigged: false,
		visibilityRadius: 5000,
	},
	'stash/stash-ground': {
		zOffset: -99999999,
		dontLoadAsset: true,
	},
	'stash/stash-hut': {
		zOffset: -200,
		dontLoadAsset: true,
	},
	'test-range': {
		colliders: [
			// entry arch
			{ type: ColliderType.Circle, position: [-1059, 1356], radius: 99, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [-707, 1478], radius: 78, traits: ColliderTraits.BlockMovementAndItem },

			// W barrel
			{ type: ColliderType.Circle, position: [-828, 587], radius: 31, traits: ColliderTraits.BlockMovementAndItem },

			// NW arch
			{ type: ColliderType.Circle, position: [-884, 558], radius: 77, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-525, 394], rX: 117, rY: 67, traits: ColliderTraits.BlockMovementAndItem },

			// NE corner
			{ type: ColliderType.Circle, position: [-370, 211], radius: 77, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [-15, 111], width: 571, height: 206, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Box, position: [304, 140], width: 222, height: 358, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Circle, position: [-200, 295], radius: 82, traits: ColliderTraits.BlockMovementAndItem },
			{ type: ColliderType.Ellipse, position: [-73, 198], rX: 187, rY: 91, traits: ColliderTraits.BlockMovementAndItem },

			// E barrel
			{ type: ColliderType.Circle, position: [498, 619], radius: 40, traits: ColliderTraits.BlockMovementAndItem },

			// SE rock
			{ type: ColliderType.Ellipse, position: [288, 1408], rX: 320, rY: 97, traits: ColliderTraits.BlockMovementAndItem },

			// S weapon rack
			{ type: ColliderType.Ellipse, position: [-220, 1420], rX: 160, rY: 74, traits: ColliderTraits.BlockMovementAndItem },

			// projectile blockers
			{ type: ColliderType.Box, position: [-1541, -327], width: 2682, height: 150, traits: ColliderTraits.BlockProjectile },
			{ type: ColliderType.Box, position: [-1589, 1628], width: 2737, height: 150, traits: ColliderTraits.BlockProjectile },
			{ type: ColliderType.Box, position: [-1592, -316], width: 150, height: 2321, traits: ColliderTraits.BlockProjectile },
			{ type: ColliderType.Box, position: [715, -198], width: 150, height: 2394, traits: ColliderTraits.BlockProjectile }
		],

		rigged: false,
		visibilityRadius: 5000,
	},
	'test-range-animated': {
		zOffset: 5000,
		visibilityRadius: 5000,
	},
	'test-range-animated4': {
		zOffset: 5000,
		visibilityRadius: 5000,
	},
	'test-range/testrange-ground': {
		zOffset: -10000,
		dontLoadAsset: true,
	},
	'test-range/testrange-entrance-b': {
		zOffset: -50,
		dontLoadAsset: true,
	},
	'test-range/testrange-arrowbucket': {
		zOffset: -50,
		dontLoadAsset: true,
	},
	'test-range/testrange-cocohanging-left': {
		zOffset: -120,
		dontLoadAsset: true,
	},
	'test-range/testrange-cocohanging-right': {
		zOffset: -120,
		dontLoadAsset: true,
	},
	'test-range/testrange-entrance-a': {
		zOffset: -50,
		dontLoadAsset: true,
	},
	'test-range/testrange-hay01': {
		zOffset: -50,
		dontLoadAsset: true,
	},
	'test-range/testrange-hay02': {
		zOffset: -50,
		dontLoadAsset: true,
	},
	'test-range/testrange-haypile': {
		zOffset: -50,
		dontLoadAsset: true,
	},
	'test-range/testrange-wall01': {
		zOffset: -50,
		dontLoadAsset: true,
	},
	'test-range/testrange-wall02': {
		zOffset: -150,
		dontLoadAsset: true,
	},
	'test-range/testrange-targetwall': {
		zOffset: -150,
		dontLoadAsset: true,
	},
	'test-range/testrange-weaponbucket': {
		zOffset: -50,
		dontLoadAsset: true,
	},
	'test-range/testrange-weaponrack': {
		zOffset: -50,
		dontLoadAsset: true,
	},
	vip: {
		colliders: [
			{ type: ColliderType.Box, position: [-1059, -2817], width: 1197, height: 391, traits: ColliderTraits.BlockMovementAndItem, },
			{ type: ColliderType.Box, position: [-1067, -2426], width: 476, height: 414, traits: ColliderTraits.BlockMovementAndItem, },
			{ type: ColliderType.Box, position: [-358, -2426], width: 476, height: 414, traits: ColliderTraits.BlockMovementAndItem, }
		],
		rigged: false,
		visibilityRadius: 5000,
	},
	'vip/vip-ground': {
		zOffset: -100000,
		dontLoadAsset: true,
	},
	'vip/vip-gate': {
		zOffset: -50,
		dontLoadAsset: true,
	},
	'vip/vip-pedastal': {
		zOffset: -50,
		dontLoadAsset: true,
	},
	'vip/vip-ropeL': {
		zOffset: -250,
		dontLoadAsset: true,
	},
	'vip/vip-ropeR': {
		zOffset: -250,
		dontLoadAsset: true,
	},
	'vip/vip-rosebush01': {
		zOffset: -20,
		dontLoadAsset: true,
	},
	'vip/vip-rosebush02': {
		zOffset: -20,
		dontLoadAsset: true,
	},
	'vip/vip-treespiral': {
		zOffset: -20,
		dontLoadAsset: true,
	},
	'vip/vip-treetall': {
		zOffset: -20,
		dontLoadAsset: true,
	},
	water: {
		colliders: [],
		rigged: false,
		visibilityRadius: 5000,
		zOffset: -Number.MAX_SAFE_INTEGER,
	},
	'water-clipping': {
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},
	'bg/tile-water': {
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},
	'bg/ambient_water': {
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},
	'bg/waves_water': {
		zOffset: -Number.MAX_SAFE_INTEGER,
		dontLoadAsset: true,
	},
	'worm-mail': {
		colliders: [
			{ type: ColliderType.Box, position: [-2809, -380], width: 679, height: 346, traits: ColliderTraits.BlockMovementAndItem }, // west building
			{ type: ColliderType.Box, position: [-2450, -502], width: 611, height: 257, traits: ColliderTraits.BlockMovementAndItem }, // east building
			{ type: ColliderType.Box, position: [-2840, -225], width: 100, height: 100, traits: ColliderTraits.BlockMovementAndItem }, // west building
			{ type: ColliderType.Box, position: [-2175, -313], width: 107, height: 183, traits: ColliderTraits.BlockMovementAndItem }, // Ant NPC
		],
		rigged: false,
		visibilityRadius: 5000,
	},
	'wormmail/wormmail-base': {
		zOffset: -250,
		dontLoadAsset: true,
	},

	'med-common-01': {
		colliders: [{ type: ColliderType.Circle, position: [4, -30], radius: 35, traits: ColliderTraits.BlockAll }],
		dontLoadAsset: true,
		visibilityRadius: 330,
		zOffset: -20,
	},
	'med-common-02': {
		colliders: [{ type: ColliderType.Circle, position: [-2, -22], radius: 33, traits: ColliderTraits.BlockMovementAndItem }],
		dontLoadAsset: true,
		visibilityRadius: 350,
		zOffset: -20,
	},
	'med-common-03': {
		colliders: [{ type: ColliderType.Circle, position: [15, -26], radius: 40, traits: ColliderTraits.BlockAll }],
		dontLoadAsset: true,
		visibilityRadius: 355,
		zOffset: -20,
	},
	'med-common-04': {
		colliders: [{ type: ColliderType.Circle, position: [0, -25], radius: 40, traits: ColliderTraits.BlockAll }],
		dontLoadAsset: true,
		visibilityRadius: 510,
		zOffset: -20,
	},
	'med-uncommon-01': {
		colliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 50, traits: ColliderTraits.BlockAll }], // placeholder
		dontLoadAsset: true,
		zOffset: -20,
	},
	'med-uncommon-02': {
		colliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 50, traits: ColliderTraits.BlockAll }], // placeholder
		dontLoadAsset: true,
		zOffset: -20,
	},
	'med-uncommon-03': {
		colliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 50, traits: ColliderTraits.BlockAll }], // placeholder
		dontLoadAsset: true,
		zOffset: -20,
	},
	'small-common-01': {
		dontLoadAsset: true,
		visibilityRadius: 55,
		zOffset: -10,
	},
	'small-common-02': {
		dontLoadAsset: true,
		visibilityRadius: 55,
		zOffset: -10,
	},
	'small-rare-01': {
		colliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 34, traits: ColliderTraits.BlockMovementAndItem }],
		dontLoadAsset: true,
		visibilityRadius: 310,
		zOffset: -100,
	},
	'low-blocker-01': {
		colliders: [{ type: ColliderType.Ellipse, position: [0, 0], rX: 150, rY: 80, traits: ColliderTraits.BlockMovementAndItem }],
		dontLoadAsset: true,
		visibilityRadius: 150,
		zOffset: -1000,
	},
	'low-blocker-02': {
		colliders: [{ type: ColliderType.Circle, position: [0, 0], radius: 90, traits: ColliderTraits.BlockMovementAndItem }],
		dontLoadAsset: true,
		visibilityRadius: 125,
		zOffset: -800,
	},
	'low-blocker-03': {
		colliders: [{ type: ColliderType.Ellipse, position: [0, 0], rX: 150, rY: 80, traits: ColliderTraits.BlockMovementAndItem }],
		dontLoadAsset: true,
		visibilityRadius: 150,
		zOffset: -1000,
	},
	'small-uncommon-04': {
		colliders: [{ type: ColliderType.Circle, position: [4, -13], radius: 31, traits: ColliderTraits.BlockMovementAndItem }],
		dontLoadAsset: true,
		visibilityRadius: 75, // not sure on this one
		zOffset: -10,
	},
	'Aurum-Alliance-base': {
		visibilityRadius: 5000,
		zOffset: -380,
	},
	'Aurum-Alliance-base-animated': {
		visibilityRadius: 5000,
		zOffset: 6000
	},
	'Scions-of-Dawn-base': {
		visibilityRadius: 5000,
	},
	'Scions-of-Dawn-animated': {
		visibilityRadius: 5000,
	},
	'Order-of-Iron-base': {
		visibilityRadius: 5000,
	},
	'Order-of-Iron-animated': {
		visibilityRadius: 5000,
	},
	'faction-assets/house-02-aurum': {
		dontLoadAsset: true,
		zOffset: 0
	},
	'faction-assets/house-06-aurum': {
		dontLoadAsset: true,
		zOffset: 280
	},
	'faction-assets/house-06-aurum-door': {
		dontLoadAsset: true,
		zOffset: 300
	},
	'faction-assets/barrel-bands-aurum': {
		dontLoadAsset: true,
		zOffset: 300
	},
	'faction-assets/barrel-bench-aurum': {
		dontLoadAsset: true,
		zOffset: -50
	},
	'faction-assets/barrel-flags-aurum': {
		dontLoadAsset: true,
		zOffset: 350
	},
	'faction-assets/house-02-red-roof': {
		dontLoadAsset: true,
		zOffset: -100
	},
	'faction-assets/barrel-bands-iron': {
		dontLoadAsset: true
	},
	'faction-assets/above-street-flag-rope-iron': {
		dontLoadAsset: true,
		zOffset: 80
	},
	'faction-assets/house-pole-hanging-iron': {
		dontLoadAsset: true,
		zOffset: 800
	},
	'faction-assets/flag-hanging-iron': {
		dontLoadAsset: true
	},
	'faction-assets/red-chimney-flags': {
		dontLoadAsset: true
	},
	'faction-assets/red-flag-roof': {
		dontLoadAsset: true
	},
	'faction-assets/barrel-flags-iron': {
		dontLoadAsset: true,
		zOffset: -1
	},
	'faction-assets/sign-and-weapon-iron': {
		dontLoadAsset: true,
		zOffset: -1
	},
	'faction-assets/flag-standing-iron': {
		dontLoadAsset: true,
		zOffset: -1
	},
	'faction-assets/flag-pole-small': {
		dontLoadAsset: true,
		zOffset: -1
	},
	'faction-assets/flag-lamp-right-iron': {
		dontLoadAsset: true
	},
	'faction-assets/standing-rock-iron': {
		dontLoadAsset: true,
		zOffset: -90
	},
	'faction-assets/barrel-bench-iron': {
		dontLoadAsset: true,
		zOffset: -150
	},
	'faction-assets/flag-lamp-left-iron': {
		dontLoadAsset: true,
		zOffset: -20
	},
	'faction-assets/Iron-roof-paint': {
		dontLoadAsset: true,
		zOffset: 200,
	},
	'faction-assets/standing-rock-aurum': {
		dontLoadAsset: true,
		zOffset: 350
	},
	'faction-assets/flag-lamp-right-aurum': {
		dontLoadAsset: true,
		zOffset: 500
	},
	'faction-assets/aurum-berries-box': {
		dontLoadAsset: true,
		zOffset: 40000000,
	},
	'faction-assets/aurum-nightshade-berries': {
		dontLoadAsset: true,
		zOffset: 420,
	},
	'faction-assets/aurum-nightshade-flower': {
		dontLoadAsset: true,
		zOffset: 300,
	},
	'faction-assets/boxes-and-flag-aurum': {
		dontLoadAsset: true,
		zOffset: 200,
	},
	'faction-assets/aurum-poison-bottles': {
		dontLoadAsset: true,
		zOffset: 250,
	},
	'faction-assets/woodpile': {
		dontLoadAsset: true,
		zOffset: -400,
	},
	'faction-assets/flag-01-aurum': {
		dontLoadAsset: true
	},
	'faction-assets/flag-hanging-aurum': {
		dontLoadAsset: true
	},
	'faction-assets/campfire': {
		dontLoadAsset: true,
		zOffset: -80000000
	},
	'glow-light': {
		dontLoadAsset: true
	},
	'flame_01': {
		dontLoadAsset: true
	},
	'flame_02': {
		dontLoadAsset: true
	},
	'flame-bits-01': {
		dontLoadAsset: true
	},
	'flame-bits-02': {
		dontLoadAsset: true
	},
	'flame-bits-03': {
		dontLoadAsset: true
	},
	'plaza/light': {
		dontLoadAsset: true
	},
	'faction-assets/house-05-blue-roof': {
		dontLoadAsset: true,
		zOffset: -500
	},
	'faction-assets/house-02-blue-roof': {
		dontLoadAsset: true,
		zOffset: -200
	},
	'faction-assets/boxes-and-flag-scion': {
		dontLoadAsset: true,
		zOffset: -500
	},
	'faction-assets/house-04-blue': {
		dontLoadAsset: true
	},
	'faction-assets/blue-house-ribbon': {
		dontLoadAsset: true,
		zOffset: 250
	},
	'faction-assets/barrel-flags-scion': {
		dontLoadAsset: true,
		zOffset: -10
	},
	'faction-assets/sign-and-weapon-scion': {
		dontLoadAsset: true
	},
	'faction-assets/barrel-bands-scion': {
		dontLoadAsset: true,
		zOffset: -10
	},
	'faction-assets/flag-lamp-left-scion': {
		dontLoadAsset: true
	},
	'faction-assets/flag-hanging-scion': {
		dontLoadAsset: true,
		zOffset: -10
	},
	'faction-assets/flag-standing-scion': {
		dontLoadAsset: true
	},
	'faction-assets/house-roof-flags-blue': {
		dontLoadAsset: true
	},
	'faction-assets/flag-01-scion': {
		dontLoadAsset: true
	},
	'faction-assets/flag-03-scion': {
		dontLoadAsset: true
	},
	'faction-assets/flag-01-iron': {
		dontLoadAsset: true
	},
	'faction-assets/flag-03-iron': {
		dontLoadAsset: true
	},
	'faction-assets/hanging-flag-pole-right-default': {
		dontLoadAsset: true,
		zOffset: -20
	},
	'faction-assets/hanging-flag-pole-left-default': {
		dontLoadAsset: true,
		zOffset: -20
	},
	'faction-assets/above-street-flag-default': {
		dontLoadAsset: true,
		zOffset: -20
	},
	'faction-assets/boxes-and-flag-default': {
		dontLoadAsset: true,
		zOffset: -80
	},
	'faction-assets/barrel-bench-default': {
		dontLoadAsset: true,
		zOffset: -183
	},
	'faction-assets/barrel-bands-default': {
		dontLoadAsset: true,
		zOffset: -50
	},
	'faction-assets/faction-house-02-bunting': {
		dontLoadAsset: true,
		zOffset: 500
	},
	'faction-assets/faction-house-03-bunting-01': {
		dontLoadAsset: true,
		zOffset: 200
	},
	'faction-assets/faction-house-03-bunting-02': {
		dontLoadAsset: true,
		zOffset: 500
	},
	'faction-assets/faction-little-tapestry-all': {
		dontLoadAsset: true,
		zOffset: 90
	},
	'faction-assets/faction-little-tapestry-aurum': {
		dontLoadAsset: true,
		zOffset: 200
	},
	'faction-assets/faction-little-tapestry-iron': {
		dontLoadAsset: true,
		zOffset: 200
	},
	'faction-assets/faction-little-tapestry-scion': {
		dontLoadAsset: true,
		zOffset: 200
	},
	'faction-assets/faction-plant-aurum-01': {
		dontLoadAsset: true,
		zOffset: -50
	},
	'faction-assets/faction-plant-aurum-02': {
		dontLoadAsset: true,
		zOffset: -50
	},
	'faction-assets/faction-plant-iron-01': {
		dontLoadAsset: true,
		zOffset: -50
	},
	'faction-assets/faction-plant-iron-02': {
		dontLoadAsset: true,
		zOffset: -50
	},
	'faction-assets/faction-plant-iron-03': {
		dontLoadAsset: true,
		zOffset: -40
	},
	'faction-assets/faction-plant-scion-01': {
		dontLoadAsset: true,
		zOffset: -50
	},
	'faction-assets/faction-plant-scion-02': {
		dontLoadAsset: true,
		zOffset: 100
	},
	'faction-assets/faction-plant-scion-03': {
		dontLoadAsset: true,
		zOffset: -50
	},
	'faction-assets/faction-poster-01': {
		dontLoadAsset: true,
		zOffset: 200
	},
	'faction-assets/faction-poster-02': {
		dontLoadAsset: true,
		zOffset: 200
	},
	'faction-assets/faction-poster-scion-01': {
		dontLoadAsset: true,
		zOffset: 200
	},
	'faction-assets/faction-poster-scion-02': {
		dontLoadAsset: true,
		zOffset: 200
	},
	'faction-assets/faction-poster-aurum-01': {
		dontLoadAsset: true,
		zOffset: 200
	},
	'faction-assets/faction-poster-aurum-02': {
		dontLoadAsset: true,
		zOffset: 200
	},
	'faction-assets/faction-poster-iron-02': {
		dontLoadAsset: true,
		zOffset: 200
	},
	'faction-assets/faction-poster-iron-01': {
		dontLoadAsset: true,
		zOffset: 90
	},
	'faction-assets/faction-side-tapestry': {
		dontLoadAsset: true,
		zOffset: 200
	},
	'faction-assets/faction-side-tapestry-iron': {
		dontLoadAsset: true,
		zOffset: 90
	},
	'faction-assets/faction-side-tapestry-scion': {
		dontLoadAsset: true,
		zOffset: 10
	},
	'faction-assets/faction-side-tapestry-aurum': {
		dontLoadAsset: true,
		zOffset: -50
	},
	'faction-assets/faction-side-tapestry-right': {
		dontLoadAsset: true,
		zOffset: 60
	},
	'faction-assets/faction-side-tapestry-left': {
		dontLoadAsset: true,
		zOffset: 100
	},
	'faction-assets/faction-tapestry-all': {
		dontLoadAsset: true,
		zOffset: 200
	},
	'faction-assets/faction-tapestry-base-aurum': {
		dontLoadAsset: true,
		zOffset: 200
	},
	'faction-assets/faction-tapestry-base-iron': {
		dontLoadAsset: true,
		zOffset: 200
	},
	'faction-assets/faction-tapestry-base-scion': {
		dontLoadAsset: true,
		zOffset: 200
	},
	'faction-assets/faction-billboard': {
		zOffset: -20,
		dontLoadAsset: true,
	},
	'faction-assets/faction-card-stand': {
		zOffset: -20,
		dontLoadAsset: true,
	},
	'faction-assets/faction-fold-board': {
		zOffset: -20,
		dontLoadAsset: true,
	},
	'faction-assets/faction-short-bunting': {
		zOffset: 200,
		dontLoadAsset: true,
	},
	'faction-assets/iron-sign-02': {
		zOffset: -25,
		dontLoadAsset: true,
	},
	'faction-assets/scions-sign-02': {
		zOffset: -10,
		dontLoadAsset: true,
	},
	'faction-assets/solara-flair': {
		dontLoadAsset: true,
		zOffset: -50
	},
	'faction-assets/aurum-paint-01': {
		zOffset: 500,
		dontLoadAsset: true,
	},
	'faction-assets/iron-sign-touch-up': {
		zOffset: 100,
		dontLoadAsset: true,
	},
	'faction-assets/aurum-flag': {
		zOffset: 100,
		dontLoadAsset: true,
	},
	'faction-assets/scions-sign-01': {
		zOffset: -10,
		dontLoadAsset: true,
	},
	'faction-assets/re\'hkosh-flair': {
		dontLoadAsset: true,
		zOffset: -20
	},
	'faction-assets/iron-sign-01': {
		zOffset: -10,
		dontLoadAsset: true,
	},
}
