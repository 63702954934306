








import { UI } from '../ui'
import { GameClient } from '../../engine/client/game-client'
import { showGenericInfoPromptUI } from '../state/generic-information-prompt.ui-state'

const webps = require('./assets/*.webp')
const gifs = require('./assets/*.gif')

export default {
	name: 'FactionsNotifier',
	components: {},
	data() {
		return {}
	},
	props: {},
	computed: {},
	methods: {
		openFactionsPrompt() {
			UI.getInstance().emitAsyncEvent('genericYesNo/showMenu', {
				title: 'Join a Faction!',
				description: ` <i class="loot-factions-neutral-icon"></i> Faction Recruiters have come to town! Do you have what it takes to join their ranks? <br/><br/> <img src="${webps['three-faction-themes']}" align="center"/>`,
				noButtonText: 'No',
				yesButtonText: 'Yes',
				yesCallback: () => {
 					showGenericInfoPromptUI(`Visit a Recruiter!`, [`Pay them a visit – the battle for Fortuna is already underway! <br/><br/> <img src="${webps['join-faction']}" align="center"/>`], 'OKAY', 'factionJoined')
				},
				noCallback: () => {
					showGenericInfoPromptUI(`No LOOT for you!`, [`Faction rewards are distributed each week. If you change your mind, approach one of the Faction Recruiters! <br/><br/> <img src="${gifs['factions-win-prizes']}" align="center"/>`], 'OKAY', 'factionJoined')
				},
				panelIdentifier: 'factionJoined'
			})
		},
	},
}
