import { Vector } from 'sat'
import { ColliderTraits, ColliderType } from '../../../collision/shared/colliders'
import { CameraShakeIntensities } from '../../../engine/shared/camera.shared'
import { bossEnemyDamageConfig, BOSS_HEALTH_MULTIPLIER, BOSS_LOOT_DROP_QUANTITY, BOSS_LOOT_DROP_RARITY, ENEMY_DEFAULT_HEALTH } from '../../../engine/shared/game-data/enemy-formulas'
import { AnimationTrack } from '../../../models-animations/shared/animation-track'
import { EnemyBipedSkinType, SpineDataName } from '../../../models-animations/shared/spine-config'
import { AttackTypes, DeadBehaviours, EnemyAI, EnemyTargetingStyles, EnemyType, FightingBehaviours, FightingTriggers, IdleBehaviours, LeashingBehaviours, LeashTriggers, ShotLeadPrecision } from '../../shared/ai-types'
import { AbilitySelectionStyles, ActionCriterion, CriteriaSatisfactionQuantity, CriteriaTarget, CriteriaType } from '../action-types'
import { NormalDifficultyCriteria, HardDifficultyCriteria, BrutalDifficultyCriteria, NastyDifficultyCriteria, playAnimationAbility, setColliderTraitsAbility, shakeCameraAbility } from './abilities/common-abilities'
import { 
	submergeAbility, 
	spawnAbility, 
	sporeFieldAbilityPlayerMedium, 
	fireSmallMushroomAtRandomNearbyPlayerAbilityMedium, 
	fireLargeMushroomAtRandomNearbyPlayerAbilityMedium, 
	spawnFourBlimpiesAbility, 
	sporeFieldAbilityPlayerEasy, 
	fireSmallMushroomAtRandomNearbyPlayerAbilityEasy, 
	fireLargeMushroomAtRandomNearbyPlayerAbilityEasy, 
	spawnTwoBlimpiesAbility, 
	spawnEightBlimpiesAbility, 
	sporeFieldAbilityPlayer, 
	submergeAbilityLong, 
	emergeAbility, 
	sporeFieldAbilityMiddle, 
	sporeFieldAbilityUpper, 
	sporeFieldAbilityFar, 
	sporeFieldAbilityLower, 
	fireLargeMushroomAtRandomNearbyPlayerAbility,
	
	sporeFieldAbilityPlayerBrutal, 
	fireSmallMushroomAtRandomNearbyPlayerAbilityBrutal, 
	fireLargeMushroomAtRandomNearbyPlayerAbilityBrutal, 
	
	// sporeFieldAbilityPlayerNasty, 
	// fireSmallMushroomAtRandomNearbyPlayerAbilityNasty, 
	// fireLargeMushroomAtRandomNearbyPlayerAbilityNasty, 

} from './abilities/fungi-boss-abilities'
import { EnemyDefaults } from './enemy-defaults'
import { ENEMY_NAMES } from './enemy-names'

const PHASE_1_2_HEALTH_BOUNDARY = 60
const PHASE_2_3_HEALTH_BOUNDARY = 30

const collidersOff = setColliderTraitsAbility(ColliderTraits.BlockMovement)
const collidersOn = setColliderTraitsAbility(ColliderTraits.BlockMovementAndProjectile)

const playersEngagedCriteria: ActionCriterion = {
	criteriaTargets: CriteriaTarget.PLAYERS,
	criteriaType: CriteriaType.WITHIN_RANGE,
	criteriaValue: 9999, // we're using the engagement shape now
	minimumTargetsThatSatisfy: 1,
}



const fungiBoss: EnemyAI = {
	actionDrivenEnemyData: {
		targetColliders: [
			{
				name: 'upperzone',
				colliders: [
					{
						type: ColliderType.Circle,
						traits: ColliderTraits.Trigger,
						position: [-700, -700],
						radius: 500,
					},
				],
			},
			{
				name: 'middleground',
				colliders: [
					{
						type: ColliderType.Ellipse,
						traits: ColliderTraits.Trigger,
						position: [-700, -100],
						rX: 450,
						rY: 640,
					},
				],
			},
			{
				name: 'farzone',
				colliders: [
					{
						type: ColliderType.Circle,
						traits: ColliderTraits.Trigger,
						position: [-1200, 0],
						radius: 500,
					},
				],
			},
			{
				name: 'lowerzone',
				colliders: [
					{
						type: ColliderType.Circle,
						traits: ColliderTraits.Trigger,
						position: [-700, 500],
						radius: 350,
					},
				],
			},
		],

		engagementColliders: [
			{
				type: ColliderType.Ellipse,
				traits: ColliderTraits.Trigger,
				position: [-496, -181],
				rX: 1351,
				rY: 908,
			},
		],
		resetOnDisengage: true,

		unengagedActions: [
			{
				priority: 0,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.SEQUENCE,
					abilityOptions: [collidersOff, playAnimationAbility(AnimationTrack.DESPAWN_IDLE)],
				},
			},
		],
		actions: [
			{
				// IDLE
				priority: -2,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.NONE,
					criterias: [playersEngagedCriteria],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.SEQUENCE,
					abilityOptions: [collidersOff, submergeAbility],
				},
			},
			{
				// IDLE -> PHASE 1 (SPAWN)
				priority: -1,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.AT_LEAST_ONE,
					criterias: [playersEngagedCriteria],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.SEQUENCE,
					abilityOptions: [[1, collidersOff, spawnAbility, 3 * 20, shakeCameraAbility(CameraShakeIntensities.VERY_INTENSE), 20, collidersOn]],
				},
				shouldOccurOnlyOnce: true,
			},
			{
				// PHASE 1 - Nasty Difficulty
				priority: 0.1,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						NastyDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.PLAYERS,
							criteriaType: CriteriaType.WITHIN_RANGE,
							criteriaValue: 1800,
							minimumTargetsThatSatisfy: 1,
							criteriaId: 'players',
						},
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_1_2_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[10, playAnimationAbility(AnimationTrack.SPREAD_SPORES, 10), sporeFieldAbilityPlayerMedium],
						[60, playAnimationAbility(AnimationTrack.SPRAY_ATTACK, 10), fireSmallMushroomAtRandomNearbyPlayerAbilityMedium],
						[20, playAnimationAbility(AnimationTrack.SPRAY_ATTACK, 10), fireLargeMushroomAtRandomNearbyPlayerAbilityMedium],
						[10, playAnimationAbility(AnimationTrack.SUMMON_MINIONS_SHORT, 8), 0, spawnFourBlimpiesAbility],
					],
				},
			},
			{
				// PHASE 1 - Brutal Difficulty
				priority: 0.2,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						BrutalDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.PLAYERS,
							criteriaType: CriteriaType.WITHIN_RANGE,
							criteriaValue: 1800,
							minimumTargetsThatSatisfy: 1,
							criteriaId: 'players',
						},
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_1_2_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[10, playAnimationAbility(AnimationTrack.SPREAD_SPORES, 10), sporeFieldAbilityPlayerBrutal],
						[60, playAnimationAbility(AnimationTrack.SPRAY_ATTACK, 10), fireSmallMushroomAtRandomNearbyPlayerAbilityBrutal],
						[20, playAnimationAbility(AnimationTrack.SPRAY_ATTACK, 10), fireLargeMushroomAtRandomNearbyPlayerAbilityBrutal],
						[10, playAnimationAbility(AnimationTrack.SUMMON_MINIONS_SHORT, 8), 0, spawnFourBlimpiesAbility],
						[10, playAnimationAbility(AnimationTrack.SUMMON_MINIONS_SHORT, 8), 0, spawnFourBlimpiesAbility, 5, fireSmallMushroomAtRandomNearbyPlayerAbilityMedium],
					],
				},
			},
			{
				// PHASE 1 - Medium Difficulty
				priority: 0.3,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						HardDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.PLAYERS,
							criteriaType: CriteriaType.WITHIN_RANGE,
							criteriaValue: 1800,
							minimumTargetsThatSatisfy: 1,
							criteriaId: 'players',
						},
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_1_2_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[10, playAnimationAbility(AnimationTrack.SPREAD_SPORES, 10), sporeFieldAbilityPlayerMedium],
						[60, playAnimationAbility(AnimationTrack.SPRAY_ATTACK, 10), fireSmallMushroomAtRandomNearbyPlayerAbilityMedium],
						[20, playAnimationAbility(AnimationTrack.SPRAY_ATTACK, 10), fireLargeMushroomAtRandomNearbyPlayerAbilityMedium],
						[10, playAnimationAbility(AnimationTrack.SUMMON_MINIONS_SHORT, 8), 0, spawnFourBlimpiesAbility],
					],
				},
			},
			{
				// PHASE 1 - Easy Difficulty
				priority: 0.4,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						NormalDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.PLAYERS,
							criteriaType: CriteriaType.WITHIN_RANGE,
							criteriaValue: 1800,
							minimumTargetsThatSatisfy: 1,
							criteriaId: 'players',
						},
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_1_2_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[10, playAnimationAbility(AnimationTrack.SPREAD_SPORES, 10), sporeFieldAbilityPlayerEasy],
						[60, playAnimationAbility(AnimationTrack.SPRAY_ATTACK, 10), fireSmallMushroomAtRandomNearbyPlayerAbilityEasy],
						[20, playAnimationAbility(AnimationTrack.SPRAY_ATTACK, 10), fireLargeMushroomAtRandomNearbyPlayerAbilityEasy],
						[10, playAnimationAbility(AnimationTrack.SUMMON_MINIONS, 15), 0, spawnTwoBlimpiesAbility],
					],
				},
			},
			{
				// PHASE 1 -> PHASE 2 transition - Nasty Difficulty
				priority: 1.1,
				shouldOccurOnlyOnce: true,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						NastyDifficultyCriteria,
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.SEQUENCE,
					abilityOptions: [[1, sporeFieldAbilityPlayer, spawnEightBlimpiesAbility, collidersOff, submergeAbilityLong, spawnEightBlimpiesAbility, emergeAbility, collidersOn, 10]],
				},
			},
			{
				// PHASE 1 -> PHASE 2 transition - Brutal Difficulty
				priority: 1.2,
				shouldOccurOnlyOnce: true,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						BrutalDifficultyCriteria,
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.SEQUENCE,
					abilityOptions: [[1, sporeFieldAbilityPlayer, spawnEightBlimpiesAbility, collidersOff, submergeAbilityLong, emergeAbility, collidersOn, 10]],
				},
			},
			{
				// PHASE 1 -> PHASE 2 transition - Medium Difficulty
				priority: 1.3,
				shouldOccurOnlyOnce: true,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						HardDifficultyCriteria,
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.SEQUENCE,
					abilityOptions: [[1, sporeFieldAbilityPlayer, spawnEightBlimpiesAbility, collidersOff, submergeAbilityLong, emergeAbility, collidersOn, 20]],
				},
			},
			{
				// PHASE 1 -> PHASE 2 transition - Easy Difficulty
				priority: 1.4,
				shouldOccurOnlyOnce: true,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						NormalDifficultyCriteria,
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.SEQUENCE,
					abilityOptions: [[1, sporeFieldAbilityPlayer, spawnFourBlimpiesAbility, collidersOff, submergeAbilityLong, emergeAbility, collidersOn, 40]],
				},
			},
			{
				// PHASE 2 - Nasty Difficulty
				priority: 2.1,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						NastyDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_2_3_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[40, playAnimationAbility(AnimationTrack.SPREAD_SPORES, 10), sporeFieldAbilityPlayerMedium],
						[10, playAnimationAbility(AnimationTrack.SPRAY_ATTACK, 10), fireSmallMushroomAtRandomNearbyPlayerAbilityMedium],
						[10, playAnimationAbility(AnimationTrack.SPRAY_ATTACK, 10), fireLargeMushroomAtRandomNearbyPlayerAbilityMedium],
						[25, playAnimationAbility(AnimationTrack.SUMMON_MINIONS_SHORT, 8), 0, spawnFourBlimpiesAbility],
						[15, collidersOff, submergeAbility, 40, emergeAbility, collidersOn],
					],
				},
			},
			{
				// PHASE 2 - Brutal Difficulty
				priority: 2.2,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						BrutalDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_2_3_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[40, playAnimationAbility(AnimationTrack.SPREAD_SPORES, 10), sporeFieldAbilityPlayerBrutal],
						[10, playAnimationAbility(AnimationTrack.SPRAY_ATTACK, 10), fireSmallMushroomAtRandomNearbyPlayerAbilityBrutal],
						[10, playAnimationAbility(AnimationTrack.SPRAY_ATTACK, 10), fireLargeMushroomAtRandomNearbyPlayerAbilityBrutal],
						[15, playAnimationAbility(AnimationTrack.SUMMON_MINIONS_SHORT, 8), 0, spawnFourBlimpiesAbility],
						[10, playAnimationAbility(AnimationTrack.SUMMON_MINIONS_SHORT, 8), 0, spawnEightBlimpiesAbility],
						[15, collidersOff, submergeAbility, 40, emergeAbility, collidersOn],
					],
				},
			},
			{
				// PHASE 2 - Medium Difficulty
				priority: 2.3,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						HardDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_2_3_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[40, playAnimationAbility(AnimationTrack.SPREAD_SPORES, 10), sporeFieldAbilityPlayerMedium],
						[10, playAnimationAbility(AnimationTrack.SPRAY_ATTACK, 10), fireSmallMushroomAtRandomNearbyPlayerAbilityMedium],
						[10, playAnimationAbility(AnimationTrack.SPRAY_ATTACK, 10), fireLargeMushroomAtRandomNearbyPlayerAbilityMedium],
						[25, playAnimationAbility(AnimationTrack.SUMMON_MINIONS_SHORT, 8), 0, spawnFourBlimpiesAbility],
						[15, collidersOff, submergeAbility, 40, emergeAbility, collidersOn],
					],
				},
			},
			{
				// PHASE 2 - Easy Difficulty
				priority: 2.4,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						NormalDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_ABOVE_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_2_3_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[40, playAnimationAbility(AnimationTrack.SPREAD_SPORES, 10), sporeFieldAbilityPlayerEasy],
						[10, playAnimationAbility(AnimationTrack.SPRAY_ATTACK, 10), fireSmallMushroomAtRandomNearbyPlayerAbilityEasy],
						[10, playAnimationAbility(AnimationTrack.SPRAY_ATTACK, 10), fireLargeMushroomAtRandomNearbyPlayerAbilityEasy],
						[25, playAnimationAbility(AnimationTrack.SUMMON_MINIONS, 15), 0, spawnTwoBlimpiesAbility],
						[15, collidersOff, submergeAbility, 40, emergeAbility, collidersOn],
					],
				},
			},
			{
				// PHASE 2 to 3 transition - Nasty Difficulty
				priority: 3.1,
				shouldOccurOnlyOnce: true,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						NastyDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_BELOW_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_2_3_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [[1, sporeFieldAbilityMiddle, sporeFieldAbilityUpper, sporeFieldAbilityFar, sporeFieldAbilityLower, 15, fireLargeMushroomAtRandomNearbyPlayerAbility,sporeFieldAbilityMiddle, fireLargeMushroomAtRandomNearbyPlayerAbility,sporeFieldAbilityUpper, fireLargeMushroomAtRandomNearbyPlayerAbility,sporeFieldAbilityLower, 10]],
				},
			},
			{
				// PHASE 2 to 3 transition - Brutal Difficulty
				priority: 3.2,
				shouldOccurOnlyOnce: true,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						BrutalDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_BELOW_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_2_3_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [[1, sporeFieldAbilityMiddle, sporeFieldAbilityUpper, sporeFieldAbilityFar, sporeFieldAbilityLower, 15, fireLargeMushroomAtRandomNearbyPlayerAbility, fireLargeMushroomAtRandomNearbyPlayerAbility, sporeFieldAbilityMiddle, fireLargeMushroomAtRandomNearbyPlayerAbility, 10]],
				},
			},
			{
				// PHASE 2 to 3 transition - Medium Difficulty
				priority: 3.3,
				shouldOccurOnlyOnce: true,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						HardDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_BELOW_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_2_3_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [[1, sporeFieldAbilityMiddle, sporeFieldAbilityUpper, sporeFieldAbilityFar, sporeFieldAbilityLower, 15, fireLargeMushroomAtRandomNearbyPlayerAbility, fireLargeMushroomAtRandomNearbyPlayerAbility, fireLargeMushroomAtRandomNearbyPlayerAbility, 10]],
				},
			},
			{
				// PHASE 2 to 3 transition - Easy Difficulty
				priority: 3.4,
				shouldOccurOnlyOnce: true,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						NormalDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_BELOW_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_2_3_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [[1, sporeFieldAbilityMiddle, sporeFieldAbilityUpper, sporeFieldAbilityFar, sporeFieldAbilityLower, 40, fireLargeMushroomAtRandomNearbyPlayerAbility, fireLargeMushroomAtRandomNearbyPlayerAbility, fireLargeMushroomAtRandomNearbyPlayerAbility, 30]],
				},
			},
			{
				// PHASE 3 - Nasty Difficulty
				priority: 4.1,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						NastyDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.PLAYERS,
							criteriaType: CriteriaType.WITHIN_RANGE,
							criteriaValue: 1800,
							minimumTargetsThatSatisfy: 1,
							criteriaId: 'players',
						},
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_BELOW_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_2_3_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[20, playAnimationAbility(AnimationTrack.SPREAD_SPORES, 10), sporeFieldAbilityPlayerMedium, 10, sporeFieldAbilityPlayerMedium],
						[10, playAnimationAbility(AnimationTrack.SPRAY_ATTACK, 10), fireLargeMushroomAtRandomNearbyPlayerAbilityMedium],
						[30, playAnimationAbility(AnimationTrack.SUMMON_MINIONS_SHORT, 8), 0, spawnFourBlimpiesAbility],
						[10, playAnimationAbility(AnimationTrack.SUMMON_MINIONS, 15), 0, spawnEightBlimpiesAbility, collidersOff, submergeAbility, 60, emergeAbility, collidersOn],
						// [30, collidersOff, submergeAbility, 40, emergeAbility, collidersOn],
					],
				},
			},	{
				// PHASE 3 - Brutal Difficulty
				priority: 4.2,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						BrutalDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.PLAYERS,
							criteriaType: CriteriaType.WITHIN_RANGE,
							criteriaValue: 1800,
							minimumTargetsThatSatisfy: 1,
							criteriaId: 'players',
						},
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_BELOW_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_2_3_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[20, playAnimationAbility(AnimationTrack.SPREAD_SPORES, 10), sporeFieldAbilityPlayerBrutal, 10, sporeFieldAbilityPlayerBrutal],
						[10, playAnimationAbility(AnimationTrack.SPRAY_ATTACK, 10), fireLargeMushroomAtRandomNearbyPlayerAbilityBrutal],
						[20, playAnimationAbility(AnimationTrack.SUMMON_MINIONS_SHORT, 8), 0, spawnFourBlimpiesAbility],
						[10, playAnimationAbility(AnimationTrack.SUMMON_MINIONS_SHORT, 8), 0, spawnFourBlimpiesAbility, 10, fireSmallMushroomAtRandomNearbyPlayerAbilityBrutal],
						[10, playAnimationAbility(AnimationTrack.SUMMON_MINIONS_SHORT, 8), 0, spawnFourBlimpiesAbility, 10, sporeFieldAbilityPlayerBrutal],
						[20, playAnimationAbility(AnimationTrack.SUMMON_MINIONS, 15), 0, spawnEightBlimpiesAbility, collidersOff, submergeAbility, 60, emergeAbility, collidersOn],
						// [30, collidersOff, submergeAbility, 40, emergeAbility, collidersOn],
					],
				},
			},
			{
				// PHASE 3 - Medium Difficulty
				priority: 4.3,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						HardDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.PLAYERS,
							criteriaType: CriteriaType.WITHIN_RANGE,
							criteriaValue: 1800,
							minimumTargetsThatSatisfy: 1,
							criteriaId: 'players',
						},
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_BELOW_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_2_3_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[20, playAnimationAbility(AnimationTrack.SPREAD_SPORES, 10), sporeFieldAbilityPlayerMedium, 10, sporeFieldAbilityPlayerMedium],
						[10, playAnimationAbility(AnimationTrack.SPRAY_ATTACK, 10), fireLargeMushroomAtRandomNearbyPlayerAbilityMedium],
						[30, playAnimationAbility(AnimationTrack.SUMMON_MINIONS_SHORT, 8), 0, spawnFourBlimpiesAbility],
						[10, playAnimationAbility(AnimationTrack.SUMMON_MINIONS, 15), 0, spawnEightBlimpiesAbility, collidersOff, submergeAbility, 60, emergeAbility, collidersOn],
						// [30, collidersOff, submergeAbility, 40, emergeAbility, collidersOn],
					],
				},
			},
			{
				// PHASE 3 - Easy difficulty.
				priority: 4.4,
				actionCriteria: {
					satisfactionQuantity: CriteriaSatisfactionQuantity.ALL,
					criterias: [
						NormalDifficultyCriteria,
						{
							criteriaTargets: CriteriaTarget.PLAYERS,
							criteriaType: CriteriaType.WITHIN_RANGE,
							criteriaValue: 1800,
							minimumTargetsThatSatisfy: 1,
							criteriaId: 'players',
						},
						{
							criteriaTargets: CriteriaTarget.SELF,
							criteriaType: CriteriaType.HEALTH_BELOW_PERCENTAGE_THRESHOLD,
							criteriaValue: PHASE_2_3_HEALTH_BOUNDARY,
							minimumTargetsThatSatisfy: 1,
						},
					],
				},
				actionAbilities: {
					abilitySelectionStyle: AbilitySelectionStyles.WEIGHTED_RANDOM,
					abilityOptions: [
						[20, playAnimationAbility(AnimationTrack.SPREAD_SPORES, 10), sporeFieldAbilityPlayerEasy, 10, sporeFieldAbilityPlayerEasy],
						[10, playAnimationAbility(AnimationTrack.SPRAY_ATTACK, 10), fireLargeMushroomAtRandomNearbyPlayerAbilityEasy],
						[30, playAnimationAbility(AnimationTrack.SUMMON_MINIONS_SHORT, 8), 0, spawnTwoBlimpiesAbility],
						[10, playAnimationAbility(AnimationTrack.SUMMON_MINIONS, 15), 0, spawnFourBlimpiesAbility, collidersOff, submergeAbility, 60, emergeAbility, collidersOn],
						// [30, collidersOff, submergeAbility, 40, emergeAbility, collidersOn],
					],
				},
			},
		],
	},

	name: ENEMY_NAMES.FUNGI_BOSS,
	type: EnemyType.BOSS,
	appearance: {
		asset: SpineDataName.FUNGI_BOSS,
		skin: EnemyBipedSkinType.DEFAULT,
		defaultMix: 0.3,
		mixSettings: [
			{ from: AnimationTrack.SPAWN, to: AnimationTrack.IDLE, duration: 0.25 },
			{ from: AnimationTrack.IDLE, to: AnimationTrack.SPREAD_SPORES, duration: 0.25 },
		],
	},
	general: {
		enemyLevel: 1,
		experienceWhenKilled: 20,
	},
	baseAttributes: {
		...EnemyDefaults.baseAttributes,
		awarenessCollisionRadiusInGrids: 4,
		noFlip: true,
		colliderIsKinematic: true,
		colliders: [
			{
				type: ColliderType.Circle,
				radius: 200,
				position: [-50, -100],
				traits: ColliderTraits.BlockProjectile,
			},
			{
				type: ColliderType.Circle,
				radius: 200,
				position: [100, -100],
				traits: ColliderTraits.BlockProjectile,
			},
		],
		attackOffset: new Vector(500, -300),
		lootDropOffset: new Vector(-600, -300),
		damageConfig: bossEnemyDamageConfig,
		movementSpeed: 0,
		visibilityRadius: 1500,
		decelerationRate: 0,
		turningRatePerSecondInDegrees: 200,

		physicalDamage: 0,
		fireDamage: 0,
		iceDamage: 0,
		lightningDamage: 0,
		poisonDamage: 0,
		attackRate: 750,
		critChance: 0.05,
		critDamage: 1.5,

		maxHealth: ENEMY_DEFAULT_HEALTH * BOSS_HEALTH_MULTIPLIER, // Boss health
		defense: 0,
		fireResist: 0,
		poisonResist: 0,
		iceResist: 0,
		lightningResist: 0,

		lootDropProbability: 100,
		lootDropQuantity: BOSS_LOOT_DROP_QUANTITY + 2,
		lootDropRarity: BOSS_LOOT_DROP_RARITY + 2,
	},
	soundEffects: {
		attack: 'Projectile_Blip',
		impact: 'SFX_Impact_Enemy_Mush',
	},
	states: {
		idling: {
			behaviour: IdleBehaviours.STATIONARY,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			transitionToFighting: {
				trigger: FightingTriggers.AGGRESSIVE_PROXIMITY,
				radius: 1100,
			},
			aggroOnHit: true,
		},
		fighting: {
			behaviour: FightingBehaviours.CHASE_AND_ATTACK,
			targetingStyle: EnemyTargetingStyles.NEAREST,
			targetingCheckSeconds: 1,
			attackType: AttackTypes.PROJECTILE,
			engagementMaxDistance: 1600,
			engagementMinDistance: 0,
			movementMaxDistance: 1600,
			movementMinDistance: 0,
			attackOptions: [],
			shotLeadPrecision: ShotLeadPrecision.NONE,
			visualAimLockSeconds: 0.5,
			transitionToLeashing: {
				trigger: LeashTriggers.NONE,
			},
		},
		leashing: {
			behaviour: LeashingBehaviours.MOVE,
			speedMultiplier: 0,
			decelerateMultiplier: 0,
		},
		fleeing: {},
		dead: {
			behaviour: DeadBehaviours.BE_A_CORPSE,
			corpseTimeoutInSeconds: 3,
		},
	},
}

export default fungiBoss
