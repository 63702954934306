import nengi from 'nengi'

class ShowGenericPromptUIMessage {
	static protocol = {
		title: { type: nengi.String },
		description: { type: nengi.String },
		okButtonText: { type: nengi.String },
		overridePanelId: { type: nengi.String },
	}

	title: string
	description: string
	okButtonText: string
	overridePanelId: string

	constructor(params: ShowGenericPromptUIMessage) {
		Object.assign(this, params)
	}
}

export default ShowGenericPromptUIMessage
