import nengi from 'nengi'

export default class DailyLoginClaimResultMessage {
	static protocol = {
		lastClaimedLoginBonus: { type: nengi.String },
		dailyLoginsClaimed: { type: nengi.Number },
		nextLoginReward: { type: nengi.Number },
		nowUnix: { type: nengi.Number },
		granted: { type: nengi.Boolean },
	}

	lastClaimedLoginBonus: string
	dailyLoginsClaimed: number
	nextLoginReward: number
	nowUnix: number
	granted: boolean

	constructor(lastClaimedLoginBonus, dailyLoginsClaimed, nextLoginReward, nowUnix, granted) {
		this.lastClaimedLoginBonus = lastClaimedLoginBonus
		this.dailyLoginsClaimed = dailyLoginsClaimed
		this.nextLoginReward = nextLoginReward
		this.nowUnix = nowUnix
		this.granted = granted
	}
}
